piecewiseManageCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','TableCom','$translate','tdsqlSrv','$routeParams']
addPieceModalCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','context','$uibModalInstance','$routeParams']
dilatationSliceCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','context','$uibModalInstance','$routeParams']
function piecewiseManageCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,TableCom,$translate,tdsqlSrv,$routeParams) {
    var self = $scope;
    self.piece_search={};
    self.pieceCheckbox={
        checked:false,
        items:{}
    };
    self.handleBtn = {
    	canInit:true,
    	delInit:true
    }
    //设置项的初始化
    self.titleName="tdsqlPiecewiseManage";
    if(sessionStorage["tdsqlPiecewiseManage"]){
       self.titleData=JSON.parse(sessionStorage["tdsqlPiecewiseManage"]);
    }else{
       self.titleData=[
          {name:'tdsql.pieceId',value:true,disable:true,search:'pid'},
          {name:'tdsql.sqlTransmissionId',value:true,disable:false,search:'id'},
          {name:'tdsql.monitor',value:true,disable:false,search:'monitor'},
          {name:'tdsql.status',value:true,disable:false,search:'status'}, 
          {name:'tdsql.pieceVersion',value:true,disable:false,search:'version'},
        //   {name:'tdsql.insType',value:true,disable:false,search:'machine'},
          {name:'tdsql.specifications',value:true,disable:false,search:'attribute'},
          {name:'tdsql.diskUsageRate',value:true,disable:false,search:'space'}
       ];
    }
  
    self.pieceSearchTearm=function(obj){
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function(item){
           item.searchTerm="";
           titleData.forEach(function(showTitle){
                 if(showTitle.value){
                     item.searchTerm+=item[showTitle.search]+"\b";
                 }
           });
        });
    };

    self.applyGlobalSearch=function(searchTerm){
        self.piece_search.searchTearm="";
        self.pieceTable.filter({
          searchTerm: searchTerm
        });
    };

    self.refreshPiece=function(){
    	getPiece()
    };
    function getPiece(){
        self.piece_search.searchTearm="";
        self.loadPieceData=false;
        self.pieceTable = new NgTableParams({
           count: GLOBAL_CONFIG.PAGESIZE
        }, {
           counts: [],
           dataset: []
        });
		//获取分片列表
		tdsqlSrv.querygwinstance($routeParams.clusterKey,$routeParams.id).then(function(res){
			self.tableData = res.data.returnData.groups[0].instances;
			self.tableData.key = $routeParams.id;
			self.tableData.TbInstance = res.data.TbInstance;
			successFunc(self.tableData); 
		});
		
    }
	//封装页面数据
    function successFunc(data){
    	//获取实例详情
        self.pieceData=data;
        self.newnum = 0;
        self.pieceData.forEach(function(item){
            if(item.status == '0'){
                item.status= '运行中';
            }else if(item.status == '1'){
                item.status= '隔离';
            }else{
                item.status= '未知';
            }
            var arrs = item.id.match(/\d+/g);
            item.pid = arrs[0]+arrs[1];
        	item.machine = item.machine;
        	item.attribute = "cpu"+item.cpu/100+"核/内存"+Number(item.memory/1024).toFixed(0)+"G/存储空间"+Number(item.data_disk/1024+item.log_disk/1024).toFixed(0)+"G";
        	item.data_disk = item.data_disk;
        	//拼装空间磁盘使用率
	        tdsqlSrv.querydiskspace(self.pieceData.key,item.id).then(function(res){	
                if(res && res.data && res.data.space){
                    if(Number(res.data.space).toFixed(2) < 0){
                        item.space = "0.00%";
                    }else if(Number(res.data.space).toFixed(2) > 100){
                        item.space = "100%";
                    }else{
                        item.space = Number(res.data.space).toFixed(2)+"%";
                    }
                }else{
                    item.space = "0.00%";
                }
			});
			item.shard = "1",
			item.version=$translate.instant("aws.tdsql.version."+item.nodeNum);
			item.nodeNum = item.nodeNum;
			item.machine = item.machine;
			if(item.status != '隔离'){
        		self.newnum += 1;
        	}
        });
        if(self.newnum >= 8){
			self.handleBtn.addInit = true;
		}else{
			self.handleBtn.addInit = false;
		}
        
        self.pieceSearchTearm({tableData:self.pieceData,titleData:self.titleData});
        TableCom.init(self,'pieceTable',self.pieceData,'id',10,'pieceCheckbox');
    }
    //初始化加载数据
    getPiece();

    //监听页面勾选框变化
    self.$watch(function() {
        return self.pieceCheckbox.items;//监控checkbox
    }, function(val) {
        self.pieceCheckedItems = [];
        var arr=[];
        for(var i in self.pieceCheckbox.items){
            arr.push(self.pieceCheckbox.items[i]);
        }
        if(val && arr.length>=0){
            for(var key in val){
                if(val[key]){
                    self.pieceData.forEach(item=>{
                        if(item.id==key){
                            self.pieceCheckedItems.push(item);
                        }
                    });
                }
            }
        }
        if(self.pieceCheckedItems.length==1){
        	if(self.pieceCheckedItems[0].status == '运行中'){
        		self.handleBtn.canInit = false;
        		self.handleBtn.delInit = true;	
        	}else if(self.pieceCheckedItems[0].status == '隔离'){
        		self.handleBtn.delInit = false;	
        		self.handleBtn.canInit = true;	
        	}else if(self.pieceCheckedItems[0].status == '未知'){
        		self.handleBtn.canInit = true;
        		self.handleBtn.delInit = true;	
        	}else{
        		self.handleBtn.canInit = true;
        		self.handleBtn.delInit = true;	
        	}
        }else{
           self.handleBtn.canInit = true;
           self.handleBtn.delInit = true;	
        }
          
    },true);
    
    self.alarmChart=function(item){
        self.pieceItem = item;
        self.fromPiece = true;
        self.addPieceModal=$uibModal.open({
            animation: true,
            templateUrl: "js/paas/tdsql/tmpl/sliceMonitorTmpl.html",
            controller: "insMonitorCtrl",
            scope:self
        });
    };
    self.addPiece=function(){
        self.addPieceModal=$uibModal.open({
            animation: true,
            templateUrl: "addPieceModal.html",
            controller: "addPieceModalCtrl",
            resolve: {
                context: function() {
                    return self;
                }
            }
        });
    };

    self.dilatationSlice=function(){
        self.addPieceModal=$uibModal.open({
            animation: true,
            templateUrl: "dilatationSlice.html",
            controller: "dilatationSliceCtrl",
            resolve: {
                context: function() {
                    return self;
                }
            }
        });
    };
    
    var progessModal = null;
    
    self.deleteSlice=function(){
    	var check_val = self.pieceCheckedItems[0];
        tdsqlSrv.deleteexpandinstance(check_val.id,$routeParams.id,$routeParams.clusterKey).then(function(result){
			if(result&&result.data){
                var paramsData = {
                    taskid:result.data.returnData.taskid,
                    groupid:$routeParams.id,
                    keys:$routeParams.clusterKey
                }
                self.progess = {
                    number:'0%',
                    status:true
                }
                self.progressTitle = '删除分片中';
                progessModal = $uibModal.open({
                    animation: true,
                    templateUrl: "toprogress.html",
                    scope:self
                })
                pollingProgress(paramsData);
            }
	    });
    };
    
    //进度条模态框
    var pollingProgress = function (data){
        let progessData = {};
        $timeout(function(){
            tdsqlSrv.querydelexpandins(data).then(function(result){
                if(result&&result.data){
                    progessData.step = result.data.returnData.process;        
                    progessData.status = result.data.returnData.status;
                    self.progess={
                        tips:"失败！",
                        number:progessData.step+'%',
                        status:progessData.status==1?false:true
                    }
                }
            }).finally(function(){
                if(progessData.step!=100&&progessData.status!=1){
                    pollingProgress(data);
                }
                if(progessData.step==100&&progessData.status!=1){
                    $timeout(function(){
                        progessModal.close();
                        self.refreshPiece();
                    },1000)
                }
            })
        },5000)
    }

}
function addPieceModalCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,context,$uibModalInstance,$routeParams) {
    var self = $scope;
    //获取实例规格
    // function getQueryInstanceSpecList(){
    //     tdsqlSrv.getQueryInstanceSpecList().then(function(res){
    //         if(res&&res.data){
    //             self.InstanceSpecList = angular.copy(res.data);
    //             self.formData.instances = self.InstanceSpecList[0];
    //         }
    //     })  
    // }
    // getQueryInstanceSpecList();
    //获取实例详情
	tdsqlSrv.queryexampleeetails($routeParams.clusterKey,$routeParams.id).then(function(result){
        //获取机型规格  
	    tdsqlSrv.getSpecificationList($routeParams.clusterKey).then(function(res){
            if(res&&res.data){
                let listData = angular.copy(res.data.returnData.spec);
                self.pieceSpecList = [];
                listData.forEach(item=>{
                    let str = item.machine+" （cpu:"+item.cpu/100+"核 内存:"+(item.mem/1024).toFixed(2)+"GB)";
                   	//新增分片规格属性
		    		self.pieceSpecList.push({
                        name:str,
                        cpu:item.cpu/100,
                        mem:(item.mem/1024).toFixed(2),
                        value:item.machine,
                        data_disk:(item.data_disk/1024).toFixed(2),
                        log_disk:(item.log_disk/1024).toFixed(2)
                    });
				    self.formData.newPieceSpec = self.pieceSpecList[0];
                })
            }
        })
        
        //获取实例详情
        tdsqlSrv.getQueryInstanceSpecList().then(function(res){
            if(res&&res.data){
                self.InstanceSpecList = angular.copy(res.data);
                self.formData.instances = self.InstanceSpecList[0];
            }
        }); 
	     
	    self.formData={
		        "newPieceDisk":30,
		        "balancePercent":0,
		        "machine":result.data.TbInstance.machine,
		        "nodeNum":result.data.TbInstance.nodeNum,
		        "insName":result.data.TbInstance.instanceId,
		    	"insVersion":$translate.instant("aws.tdsql.version."+result.data.TbInstance.nodeNum)
		   };
		   //初始化分片硬盘滑块得值
		    $timeout(function(){
		        $("#newPieceDiskBar").ionRangeSlider({
		            min: 30,
		            max: 3000,
		            type: 'single',//设置类型
		            from:self.formData.newPieceDisk,
		            step: 5,
		            prefix: "",//设置数值前缀
		            postfix: "GB",//设置数值后缀
		            prettify: true,
		            hasGrid: true,
		            grid:true,
		            onChange:function(data){
		                self.formData.newPieceDisk=data.from;
		                self.$apply();
		            }
		        });
		    },500);
	});   
	
    self.newPieceDiskStep=5;
    self.newPieceDiskUnit='GB';
    self.balanceUnit='%';
    self.balanceStep=1;
    self.pieceSubmintted=false;
    self.interactedAddPiece = function(field) {
        if (field) {
            return self.pieceSubmintted || field.$dirty;
        }
    };
    var progessModal = null;

    self.addPieceConfirm=function(addPieceForm){
        self.degrade = 0;
        if(self.formData.nodeNum == '2'){
            self.degrade = 1;
        }else{
            self.degrade = 0;
        }
    	//创建分片数据	    
	    self.instogroup = {
			"groupid":$routeParams.id,
			"shard":1,
			"nodeNum":self.formData.nodeNum,
			"machineIdc":0,
			"buy_new_set":false,
			"sync_type":1,
			"fenceid":"none",
			"manual":false,
			"assign_ip":"",
			"degrade":self.degrade,
			"degrade_time":45,
			"manual_sw":0,
			"starttime":"0",
			"endtime":"0",
			"machine":self.formData.machine,
			"cpu":self.formData.instances.cpu+"",
			"memory":self.formData.instances.memory+"",
			"data_disk":parseInt(self.formData.newPieceDisk*1024*0.9)+"",
			"log_disk":parseInt(self.formData.newPieceDisk*1024*0.1)+"",
			"src_set":"",
			"src_range":"",
			"dst_range":"",
			"lessidc":false,
			"master_zone":"",
			"slave_zones":""
        }

        if(addPieceForm.$valid){
			tdsqlSrv.createSet(self.instogroup,$routeParams.clusterKey).then(function(result){
//				进度条
				if(result&&result.data){
	                var paramsData = {
	                    taskid:result.data.returnData.taskid,
	                    groupid:$routeParams.id,
	                    keys:$routeParams.clusterKey
	                }
	                context.progess = {
	                    number:'0%',
	                    status:true
	                }
	                context.progressTitle = '新增分片中';
	                progessModal = $uibModal.open({
	                    animation: true,
	                    templateUrl: "toprogress.html",
	                    scope:context
	                })
	                pollingProgress(paramsData);
	            }
		    });
		    $uibModalInstance.close();
        }else{
            self.pieceSubmintted=true;
        }
    };
    
    
    //进度条模态框
    var pollingProgress = function (data){
        let progessData = {};
        $timeout(function(){
            tdsqlSrv.queryaddinstogroup(data).then(function(result){
                if(result&&result.data){
                    progessData.step = result.data.returnData.process;        
                    progessData.status = result.data.returnData.status;
                    context.progess={
                        tips:"新增失败！请检查资源是否足够！",
                        number:progessData.step+'%',
                        status:progessData.status==1?false:true
                    }
                }
            }).finally(function(){
                if(progessData.step!=100&&progessData.status!=1){
                    pollingProgress(data);
                }
                if(progessData.step==100&&progessData.status!=1){
                    $timeout(function(){
                        progessModal.close();
                        context.refreshPiece();
                    },1000)
                }
            })
        },5000)
    }
    
    //input框改变带宽的值
    self.changePieceDisk=function(value){
        var bindBar=$("#newPieceDiskBar").data("ionRangeSlider");
        bindBar.update({
            min: 30,
            max: 3000,
            type: 'single',//设置类型
            from:value,
            step: 5,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
        });
    };
    
}
	function dilatationSliceCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,context,$uibModalInstance,$routeParams) {
	    var self = $scope;
	    //获取当前选中的
	    var check_val = context.pieceCheckedItems[0];
	    tdsqlSrv.getSpecificationList($routeParams.clusterKey).then(function(res){
            if(res&&res.data){
                let listData = angular.copy(res.data.returnData.spec);
                //当前分片规格
                self.oldpieceSpecList = [];
                let str = "CPU:"+check_val.cpu/100+"核 内存:"+(check_val.memory/1024).toFixed(0)+"GB";
                self.oldpieceSpecList.push({
                    name:str,
                    cpu:check_val.cpu/100,
                    mem:(check_val.memory/1024).toFixed(2),
                    value:check_val.machine,
                    data_disk:(check_val.data_disk/1024).toFixed(2),
                    log_disk:(check_val.log_disk/1024).toFixed(2)
                });	
                //扩容分片规格
                listData.forEach(item=>{
                    let str = "CPU:"+item.cpu/100+"核 内存:"+(item.mem/1024).toFixed(0)+"GB";
                    //当前分片规格属性
                    self.oldpieceSpecList.push({
                        name:str,
                        cpu:item.cpu/100,
                        mem:(item.mem/1024).toFixed(2),
                        value:item.machine,
                        data_disk:(item.data_disk/1024).toFixed(2),
                        log_disk:(item.log_disk/1024).toFixed(2)
                    });			    
                })
                self.formData.oldPieceSpec = self.oldpieceSpecList[0];
            }

            //获取实例详情
            tdsqlSrv.getQueryInstanceSpecList().then(function(res){
                self.pieceSpecList = [];
                if(res&&res.data){
                    res.data.forEach(function(showvalue){
                        if(showvalue.cpu/100 >= self.formData.oldPieceSpec.cpu && showvalue.memory/1024 >= self.formData.oldPieceSpec.mem){
                            self.pieceSpecList.push(showvalue);
                        }
                    });
                    self.formData.newPieceSpec = self.pieceSpecList[0];
                }
            }); 
        })

	    self.formData={
	        "newPieceDisk":(check_val.data_disk/1024+check_val.log_disk/1024).toFixed(0),
    		"insName":check_val.id,
	    	"insVersion":check_val.version,
	    	"pieceId":check_val.id,
	    	"shard":"1",
	    	"nodeNum":check_val.nodeNum,
            "machine":check_val.machine
	    };
	    
	    self.newPieceDiskStep=5;
	    self.newPieceDiskUnit='GB';
	    self.pieceSubmintted=false;
	    self.interactedDilatationSlice = function(field) {
	        if (field) {
	            return self.pieceSubmintted || field.$dirty;
	        }
    	};
   
    	var progessModal = null;
    
    	self.dilatationSliceFormConfirm=function(addPieceForm){
    	 //扩容分片
	    self.dilatation = {
			"groupid":$routeParams.id,
			"id":self.formData.pieceId,
			"shard":self.formData.shard,
			"nodeNum":self.formData.nodeNum,
			"buy_new_set":false,
			"manual":false,
			"assign_ip":"",
			"manual_sw":0,
			"starttime":"0",
			"endtime":"0",
			"machine":self.formData.machine,
			"cpu":self.formData.newPieceSpec.cpu+"",
			"memory":self.formData.newPieceSpec.memory+"",
			"data_disk":parseInt(self.formData.newPieceDisk*1024*0.9)+"",
			"log_disk":parseInt(self.formData.newPieceDisk*1024*0.1)+"",
			"lessidc":false
		}
        if(addPieceForm.$valid){
            if(self.formData.oldPieceSpec.cpu*100+"" == self.dilatation.cpu+"" 
                && self.formData.oldPieceSpec.mem*1024+"" == self.dilatation.memory+""
                && (self.formData.newPieceDisk*0.9).toFixed(2)+"" == self.formData.oldPieceSpec.data_disk){
                    self.sameGradeTips = true;
            }else{
                self.sameGradeTips = false;
                tdsqlSrv.expandgroupinstance(self.dilatation,$routeParams.clusterKey).then(function(result){
                    if(result&&result.data){
                        var paramsData = {
                            taskid:result.data.returnData.taskid,
                            groupid:$routeParams.id,
                            keys:$routeParams.clusterKey
                        }
                        context.progess = {
                            number:'0%',
                            status:true
                        }
                        context.progressTitle = '扩容分片中';
                        progessModal = $uibModal.open({
                            animation: true,
                            templateUrl: "toprogress.html",
                            scope:context
                        })
                        pollingProgress(paramsData);
                    }
                    
                    
                });
                $uibModalInstance.close();
            }        	
        }else{
			self.pieceSubmintted=true;
        }
    };

    //滑块手动输入框默认值
    self.min_disk = (check_val.data_disk/1024+check_val.log_disk/1024).toFixed(0);

    //如果滑块的值大于等于3千，隐藏滑块
    if((check_val.data_disk/1024+check_val.log_disk/1024).toFixed(0) >= '3000'){

    }

    //input框改变磁盘的值
    self.changeDiskSize=function(value){
        var bindBar=$("#dilatation").data("ionRangeSlider");
        bindBar.update({
            min: (check_val.data_disk/1024+check_val.log_disk/1024).toFixed(0),
            max: 3000,
            type: 'single',//设置类型
            from:value,
            step: 5,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
        })
    };

    self.changeSize = function(type){
        if(type=='add'){
            if(self.formData.newPieceDisk>2995){return;}
            self.formData.newPieceDisk = self.formData.newPieceDisk*1 + 5;
            self.changeDiskSize(self.formData.newPieceDisk);
        }else{
            if(self.formData.newPieceDisk<(parseInt(check_val.data_disk/1024+check_val.log_disk/1024)+5)){return;}
            self.formData.newPieceDisk = self.formData.newPieceDisk*1 - 5;
            self.changeDiskSize(self.formData.newPieceDisk);
        }
    }
    
    //进度条模态框
    var pollingProgress = function (data){
        let progessData = {};
        $timeout(function(){
            tdsqlSrv.queryexpandgroupins(data).then(function(result){
            	if(result&&result.data){
                    progessData.step = result.data.returnData.process;        
                    progessData.status = result.data.returnData.status;
                    context.progess={
                        tips:"扩容失败！请检查资源是否足够！",
                        number:progessData.step+'%',
                        status:progessData.status==1?false:true
                    }
                }
            }).finally(function(){
                if(progessData.step!=100&&progessData.status!=1){
                    pollingProgress(data);
                }
                if(progessData.step==100&&progessData.status!=1){
                    $timeout(function(){
                        progessModal.close();
                        context.refreshPiece();
                    },1000)
                }
            })
        },5000)
    }
    
    
    //input框改变带宽的值
    self.changePieceDisk=function(value){
        var bindBar=$("#dilatation").data("ionRangeSlider");
        bindBar.update({
            min: 30,
            max: 3000,
            type: 'single',//设置类型
            from:value,
            step: 5,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
        });
    };
    
    //初始化分片硬盘滑块得值
    $timeout(function(){
        $("#dilatation").ionRangeSlider({
            min: (check_val.data_disk/1024+check_val.log_disk/1024).toFixed(0),
            max: 3000,
            type: 'single',//设置类型
            from:(check_val.data_disk/1024+check_val.log_disk/1024).toFixed(0),
            step: 5,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
            onChange:function(data){
                self.formData.newPieceDisk=data.from;
                self.$apply();
            }
        });
    },500);
    
}
export{piecewiseManageCtrl,addPieceModalCtrl,dilatationSliceCtrl}