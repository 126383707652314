import "./instancesSrv";
import "./labelSrv"
import "../../system/storage/storageSrv";
import "../../overview/overviewSrv";
import "../volumes/volumesSrv";
import "../volumes/regularSnapSrv";
import "../../system/limitband/bandWidthModule";
import "../../common/services/commonFuncSrv";
import {createInstanceCtrl as instancesModelCtrl}  from "./createInstanceCtrl";
import "../../monitor/resource/vmHostSrv";
// import "../../system/vgpu/services/vgpuSrv";
import { PiePanelDefault } from "../../chartpanel";
import { AreaPanelDefault } from "../../chartpanel";
import { vmInsDetailChartDefault } from "../../chartpanel";
import { hostAreaChartSqlParams } from "../../chartpanel";
import { detailConfigInfoTpl } from './directive/detail-config-info-tpl';
import { detailMonitorInfoTpl } from './directive/detail-monitor-info-tpl';
import { detailMigrationRecordTpl } from './directive/detail-migration-record-tpl';
import { detailLogTpl } from './directive/detail-log-tpl';
angular.module("instancesModule", ["ngSanitize", "ngTable", "ui.bootstrap.tpls", "instancesSrv", "ui.select", "aggregatesSrvModule","regularSnapSrvModule","bandWidthSrv","commonFuncModule","labelMSrv"])
    .controller("InstancesCtrl", ["$routeParams","$scope", "$rootScope", "NgTableParams", "$uibModal", "instancesSrv","vmhostSrv","userDataSrv", "$translate", "checkedSrv", "cvmViewSrv", 
    "$timeout", "GLOBAL_CONFIG", "depviewsrv", "alertSrv", "alarmSettingSrv", "alarmTemplateSrv", "contactGroupSrv",
     "$filter","networksSrv","regularSnapSrv","bandWidthSrv","$window","commonFuncSrv","floatipsSrv","TableCom",
     "modalCom","$sce","vmFuncSrv","dataclusterSrv","nodeSrv","$location","utilTool","vgpuSrv","labelMSrv",
     function($routeParams,$scope, $rootScope, NgTableParams, $uibModal, instancesSrv,vmhostSrv,userDataSrv, $translate,
         checkedSrv, cvmViewSrv, $timeout, GLOBAL_CONFIG, depviewsrv, alert, alarmSettingSrv, alarmTemplateSrv, 
         contactGroupSrv, $filter,networksSrv,regularSnapSrv,bandWidthSrv,$window,commonFuncSrv,floatipsSrv,
         TableCom,modalCom,$sce,vmFuncSrv,dataclusterSrv,nodeSrv,$location,utilTool,vgpuSrv,labelMSrv) {
        var self = $scope;
        self.context = self;
        self.activeEnable = true;
        self.stopEnable = true;
        self.errorEnable = true;
        self.delisDisabled = true;
        self.maxSizeLimit = false;
        self.pageSize = 10;
        self.currentPage = 1;
        self.pageInstan = {
            currentPage:1
        }
        self.isClickLocalDiskConfirm = false;
        self.model = {};
        self.headers = {
            name:$translate.instant("aws.instances.cloudInstanceName"),
            domain:$translate.instant("aws.common.domain"),
            project:$translate.instant("aws.common.project"),
            status:$translate.instant('aws.instances.status'),
            imgName:$translate.instant('aws.instances.imgName'),
            IPaddress:$translate.instant('aws.instances.IPaddress'),
            privateIP:$translate.instant('aws.instances.privateIP'),
            publicIP:$translate.instant('aws.instances.publicIP'),
            create_at:$translate.instant('aws.instances.create_at'),
            hostName:$translate.instant('aws.instances.hostName'),
            snapshotName: $translate.instant("aws.snapshots.snapshotName"),
            size: $translate.instant("aws.snapshots.size"),
            created_at: $translate.instant("aws.snapshots.created_at"),
            label:$translate.instant("aws.instances.label"),
            hostBind:$translate.instant("aws.instances.hostBind"),
        }
        self.translate = {
            "name": $translate.instant("aws.backups.table.name"),
            "vm": $translate.instant("aws.backups.table.vm"),
            "createAt": $translate.instant("aws.backups.table.createAt"),
            "size": $translate.instant("aws.backups.table.size"),
            "backupStatus": $translate.instant("aws.backups.table.backupStatus"),
            "backupType": $translate.instant("aws.backups.table.backupType"),
        }
        
        self.searchList = [
            {name:$translate.instant('aws.instances.table.status.all'),id:'',key:""},
            {name:$translate.instant('aws.instances.table.status.active'),id:"active",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori"},
            {name:$translate.instant('aws.instances.table.status.shutoff'),id:"shutoff",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
            {name:$translate.instant('aws.instances.table.status.error'),id:"error",value:$translate.instant('aws.instances.table.status.error'),key:"status_ori"},
            {name:$translate.instant('aws.instances.table.status.paused'),id:"paused",value:$translate.instant('aws.instances.table.status.paused'),key:"status_ori"},
            {name:$translate.instant('aws.instances.table.status.suspended'),id:"suspended",value:$translate.instant('aws.instances.table.status.suspended'),key:"status_ori"},
            {name:$translate.instant('aws.instances.table.status.shelved_offloaded'),id:"shelved_offloaded",value:$translate.instant('aws.instances.table.status.shelved_offloaded'),key:"status_ori"},
        ]
        
        self.titleName="instances";
        if(sessionStorage["instances"]){
            self.tableCols = JSON.parse(sessionStorage["instances"]);
            self.tableCols = self.tableCols.filter(item => item.field!="prettyName")
        } else {
            self.tableCols = [
                { field: "check", title: "",headerTemplateURL:"headerCheckbox.html",show: true ,width:"6%"},
                { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true,width:"12%"},
                { field: "status_ori", title: self.headers.status,sortable: "status",show: true,disable:false,width:"9%"},
                { field: "imageName", title: self.headers.imgName,sortable: "imageName",show: true,disable:false,width:"9%"},
                { field: "fixedIps", title: self.L3?self.headers.privateIP:self.headers.IPaddress,sortable: "fixedIps_long",show: true,disable:false,width:"12%"},
                { field: "floatingIps", title: self.headers.publicIP,sortable: "floatingIps_long",show: true,disable:false,width:"13%"},
                { field: "createtime", title: self.headers.create_at,sortable: "createtime",show: true,disable:false,width:"9%"},
                { field: "hostBind", title: self.headers.hostBind,sortable: "hostBind",show: true,disable:false,width:"9%"},
            ];
        }
        
        const isLabelList = self.tableCols.filter(item => item.field == 'labelList');
        if(isLabelList.length == 0){
            self.tableCols.push({field: "labelList", title: self.headers.label, sortable: "labelList", show: true, disable:false,width:"12%"})
        }
        
        if(self.ADMIN) {
            var prettyName = { field: "prettyName", title: self.headers.hostName,sortable: "prettyName",show: true,disable:false,width:"9%"}
            self.tableCols.splice(7,0,prettyName)
        }

         self.configSearch = function(data,tableCols){
            var tableData =  data;
            tableData.map(item => {
                editSearch(item,tableCols)
            })
            return tableData;
        }
        
        self.$watch(function(){
            return localStorage.selectedAllDomain
        },function(ne){
            utilTool.projectDomainTableCol(ne,2,self)
        })
        
        function paastip(){
            instancesSrv.checkNetwork().then(function(res){
                //true为已存在paas网络，隐藏提示
                if(res&&res.data){
                    self.showpaastip=true;
                }else{
                    self.showpaastip=false;
                }
            })
        }
        paastip();
        function editSearch(item,tableCols){
            var searchTerm = []
            tableCols.map(search => {
                if(search.title && search.show){
                    searchTerm.push(item[search.field])
                }
            })
            item.searchTerm =searchTerm.join("\b") ;
            return item;
        }
     

         //处理数据
        function operateData(data) {
            var buidingis = [];
            _.forEach(data, function(item) {
                //item.fixedIps = item.publicIpAddress?item.privateIpAddress.split(","):[];
                //item.floatingIps = item.publicIpAddress?item.publicIpAddress.split(","):[];
                if(item.domainName=="default"){
                    item.domainName_TR = $translate.instant("aws.common.defaultDepar")
                }else{
                    item.domainName_TR = item.domainName
                }
                if(item.projectName=="admin"){
                    item.projectName_TR = $translate.instant("aws.common.defaultProject")
                }else{
                    item.projectName_TR = item.projectName
                }

                if(!item.floatingIps){
                    item.floatingIps = []
                }

                if(!item.fixedIps){
                    item.fixedIps = []
                }
                
                item.createtime = $filter("date")(item.createtime, "yyyy-MM-dd HH:mm:ss");
                item.status = item.status.toLowerCase();
                item.status = item.task_state?item.task_state:item.status;
                item.status_ori = $translate.instant("aws.instances.table.status." + item.status);
                item.searchTerm = [item.name, item.prettyName ,item.imageName ,item.fixedIps.join("\b"), item.floatingIps , item.status_ori , item.createtime].join("\b");
                
                item.fixedIps_long = item.fixedIps.map(ip => _IP.toLong(ip)) ;
                item.floatingIps_long = item.floatingIps?item.floatingIps.map(ip => _IP.toLong(ip)):"";
                if(item.status == "build" || item.status == "building" || item.status == "block_device_mapping"){
                    buidingis.push(item.uid);
                }
                if(item.hostBind === null) {
                    item.hostBind = false;
                }
            });
            //获取列表中所有创建中的虚拟机id
            checkedSrv.setChkIds(buidingis,"create")
            return data;
        }
 
        function successFunc(data) {
            data.forEach(item => {
                item.labelList = []
                if(item.label){
                    for (const key in item.label) {
                        item.labelList.push(item.label[key])
                    }
                }
            })
            //初始化表格
            self.tabledata = self.configSearch(data, self.tableCols);
            self.tableParams = new NgTableParams({ count: 9999 }, { counts: [], dataset: self.tabledata });
            var tableId = "uid";
            
            checkedSrv.checkDo(self, data, tableId);
        }
        //切换页码
        self.changePage=function(options){
            options.pattern = self.globalSearchTerm?self.globalSearchTerm:null
            options.status = self.statusSelected.searchItem?self.statusSelected.searchItem.id:null
            getInstance(options,'changePage');
        };
        //后端搜索
        self.searchBackend = function(options){
            self.pageInstan.currentPage = 1;
            var pageOption = {
                pageSize: self.pageSize,
                pageNum:1,
                pattern:self.globalSearchTerm?self.globalSearchTerm:null,
                status:self.statusSelected.searchItem?self.statusSelected.searchItem.id:null
            };
            if(options.type=='refresh'){
                self.globalSearchTerm = null;
                pageOption.pattern = null;
                pageOption.status = null;
                self.statusSelected.searchItem = self.searchList[0] 
            }
            getInstance(pageOption);
        }
        //详情事件
        self.linkTo = function(item){
            let insStatus = item.status.toLowerCase();
            utilTool.setLocalStorage([item]);
            $location.url(`/cvm/instances?id=${item.uid}&insStatus=${insStatus}`);
        }
        self.linkClose = function(){
            self.checkboxes.items = {};
            $location.url('/cvm/instances');
            self.tab = {};// 解决再次打开详情时 详情的directive不会刷新的问题
            self.tabCacheObj = utilTool.tabsShow(5,'close');
        }
        //导出
        self.exportResource = function(){
            var status = self.statusSelected.searchItem?self.statusSelected.searchItem.id:''
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/instances?regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm}&status=${status}`
            utilTool.downloadFile(url);
        }
        //控制导出按钮是否显示，当选中全部部门时显示
        self.showExport = utilTool.isAllDomainSelect;
        // 获取云主机列表
        function getInstance(options,type) {
            if(!type){
                self.pageInstan.currentPage = 1;
            }
            var pageDefaultOption = {
                pageSize:self.pageSize,
                pageNum:1,
                pattern:null,
                status:null
            };
            //self.tabledata = [];
            var pageOption = angular.extend(pageDefaultOption,options)
            instancesSrv.getInstanList(pageOption).then(function(result) {
                if (result && result.data) {
                    var deliverData = result.data;
                    self.projectsData=result.data;
                    // deliverData = deliverData.filter(inst => inst.name != "fixedImageInstanceName");
                    deliverData = operateData(deliverData);
                    successFunc(deliverData);
                    tableResize(deliverData);
                    self.totalNum = result.total;
                    self.setLLL = 2;
                }
                result ? self.loadData = true : "";
            });
        }
        // 刷新间隔
        self.refreshIns = function() {
            self.globalSearchTerm = ""
            self.statusSelected.searchItem = self.searchList[0];
            getInstance();
        };
        
        function tableResize(data){
            var resizing = [];
            data.map(x => {
                if(x.status == "verify_resize" || x.status == "resized"){
                    resizing.push(x.uid)
                }
            })
            if(resizing.length){
                resizing.map(id => {$window.IntervalResize(id)})
            }
        }

        bandWidthSrv.getLimitData().then(function(res) {
            if (res && res.data && res.data.length != 0) {
                self.limitData = JSON.parse(res.data[0].paramValue);
            }
            getInstance();
        });

        function canDelServerFunc(checkedItems){
            let insIdArr=[],canDelete=false;
            self.canNotDelServerTips="";
            _.forEach(checkedItems, function(item) {
                insIdArr.push(item.uid);
            });
            instancesSrv.checkServerHasBackupJob({'instanceId':insIdArr}).then(function(res){
                if(res&&res.data&&angular.isArray(res.data)){
                    for(var i=0;i<res.data.length;i++){
                        //云主机有备份任务存在，请先解绑备份任务，再进行删除操作
                        if(_.include(insIdArr,res.data[i].instanceId)){
                           self.canNotDelServerTips=$translate.instant("aws.instances.tip.insHasBackupJob")
                           canDelete=true; 
                           break;
                        }
                    } 
                    if(canDelete==false){
                        self.delServerDisabled=false;
                    }
                }
            });
        }
        
        // 选中的数据项
        self.activeItem = []
        //不同状态下某些按钮的可操作性
        self.$watch(function() {
            return self.checkedItems;
        }, function(value,old) {
            if(value&&old&&value.length!=old.length){
                utilTool.setLocalStorage(value);
            }

            self.activeItem = value

            self.conIsEnable = true;
            self.activeEnable = true;
            self.stopEnable = true;
            self.errorEnable = true;
            self.clockSyncEnable=true;//时钟同步
            self.closeClockSyncEnable=true;//关闭时钟同步
            self.activeIsEnable = true;
            self.addfixIp = true;
            self.removefixIpDis = true;
            self.modifyFixdIpBtn = true;
            self.bindfloatingIps = true;
            self.removefloatIpDis = true;
            self.migrate = true;//设备透传时，热迁移不可操作
            self.suspend = true;
            self.upSuspend = true;
            self.hang = true;
            self.upHang = true;
            self.upgrade = true;
            self.detackDisk = true;
            self.unloadDisk = true;
            self.canBak = true;
            self.loading_Disk = true;
            self.loadIptable = true;
            self.change_Mac = true;
            self.edit_Sever = true;
            self.evaDisabled = true;
            self.alarmDisabled = true;
            self.CDdisabled = true;
            self.mountISODis = true;
            self.unmountISODis = true;
            self.oshotgrade = true;
            self.vmSnap = true;
            self.canBackups = true;
            self.vmdiskIdget = true;
            self.vmSnapRollBack = true;
            self.setNICBandDisabled = true;
            self.showSnapRollBack = false;
            self.resetDisabled = true;
            self.canEditPsw = true;
            self.canRebuild = true;
            self.canStockTransfer = true;
            self.coldMigrateBtn = true;//设备透传时，冷迁移不可操作
            self.canDoItAnyTime = true ;
            self.canBootMenu = true;
            self.canBackupRestore = true;
            self.canttEquiment = true;
            self.errorStartDisabled = true;
            self.volumeStatusDis = true;
            self.storageLimitImage = true;
            self.limitImageSize = true;
            self.storageLimitMigrate = true;
            self.storageLimitColdMig = true;
            self.canDelSysDistRedio = true;    //只要有一个是本地盘，删除页面单选按钮不可勾选
            self.unhotgrade = true;
            self.canStockTransfer = true;
            self.notFpgaCanMakeImg=false;
            self.delServerDisabled=true;//是否可以删除云主机
            self.labelStatus = true;
            // vgpu
            self.bindVgpuEnable = true;
            self.unbindVgpuEnable = true;

            // 云主机克隆
            self.canVmClone = true;

            //高可用
            self.settingHaEnable = true;

            // 加载本地磁盘
            self.canLoadingLocalDisk = true;
            // 卸载本地磁盘
            self.canDetachLocalDisk = true;
            // 搁置
            self.shelveServerEnable = true;
            // 取消搁置
            self.unShelveServerEnable = true;

            self.snapshotRollbackTip = $translate.instant("aws.instances.tip.snapshot_rollback_tip");//快照回滚需要在关机状态
            self.migrateTip = $translate.instant("aws.instances.tip.migrateTip2");
            self.coldMigrateTip = $translate.instant("aws.instances.tip.coldMigrateTip2");
            self.upgradeTip = $translate.instant("aws.instances.tip.resize_tip2");
            self.rebuildTipTip = $translate.instant("aws.instances.tip.rebuildTip");
            self.forceStopTip = $translate.instant("aws.instances.tip.forceStopTip");
            self.errorStartTip = $translate.instant("aws.instances.tip.shutdownTip");
            self.loading_Disk_Tip = $translate.instant("aws.instances.tip.loading_Disk_Tip");
            self.stock_transferTip = $translate.instant("aws.instances.tip.stock_transferTip");
            self.bindVgpuTips = $translate.instant("aws.instances.tip.bindVgpu");
            self.mainHostClone = $translate.instant("aws.instances.tip.mainHostClone");
            self.canNotDelServerTips="";
            self.serverDetail="";
            var activeChk = 0,
                stopChk = 0,
                errorChk = 0,
                paushChk = 0,
                suspendChk = 0,
                rebootChk = 0,//重启
                resizeChk = 0,//调整配置
                restoreChk = 0,
                shelveChk = 0;//搁置
            if(!value) return;

            //只要有一个是本地盘，删除页面单选按钮不可勾选
            self.canDelSysDistRedio = value.some(function(item){
                return item.startStyle == "Local"
            })

            self.selectedItemCount = value.length;

            value.map(function(item) {
                //开机状态的个数
                activeChk += (item.status === "active") || 0;
                //关机状态的个数
                stopChk += (item.status === "shutoff" || item.status === "stopped") || 0;
                //错误状态的个数
                errorChk += (item.status === "error") || 0;
                //暂停状态的个数
                paushChk += (item.status === "paused") || 0;
                //挂起状态的个数
                suspendChk += (item.status === "suspended") || 0;
                //重启状态个数
                rebootChk += (item.status === "reboot_pending" || item.status === "reboot") || 0;
                //调整配置中个数
                resizeChk += (item.status === "resize" || item.status === "resize_prep" || item.status === "resize_migrating" || item.status === "resize_finish" || item.status === "verify_resize" || item.status === "resized") || 0;
                //搁置状态的个数
                shelveChk += (item.status === "shelved_offloaded");
            });
            var values = [activeChk, stopChk, errorChk, paushChk, suspendChk,resizeChk,shelveChk];
            if (value.length == 1) {
                self.labelStatus = false;

                //重建云主机操作
                if(((values[0] == 1) || (values[1] == 1)) && value[0].imageName !="" && value[0].task_state == null){
                    self.canRebuild = false;
                } 
                //修改密码操作
                if((values[0] == 1)){
                    self.canEditPsw = false ;
                }
                //关机和开机有关的网络操作
                if ((values[0] == 1) || (values[1] == 1)  || (values[3] == 1)  || (values[4] == 1)) {
                    self.canDoItAnyTime = false ;
                    // self.canttEquiment = false;
                    
                    //卷迁移只能在云主机是关机或者开机的状态下进行
                    if((values[0] == 1) || (values[1] == 1)){
                        self.canStockTransfer=false;
                        //判断当前是否只有一个存储池，若只有一个，云硬盘迁移不可点击
                        judgeNumberOfVolumePool();
                    }
                    if (value[0].floatingIps) {
                        value[0].floatingIps.length > 0 ? self.removefloatIpDis = false : self.removefloatIpDis = true; //有公网ip时允许操作
                    }
                    if (value[0].fixedIps) {
                        if(value[0].fixedIps.length>0){
                           self.modifyFixdIpBtn = false;
                        }
                        self.setNICBandDisabled = false;

                        value[0].fixedIps.length > 1 ? self.removefixIpDis = false : self.removefixIpDis = true; //私网ip数为1时不允许删除操作。
                        value[0].fixedIps.length <8 ? self.addfixIp = false : self.addfixIp = true; //私网ip数小于8才可进行添加网卡操作
                        value[0].fixedIps.length != value[0].floatingIps.length ? self.bindfloatingIps = false : self.bindfloatingIps = true; //私网ip不等于公网IP数时可以天剑公网ip
                    }
                    //获取详情中云硬盘的各个，判断按钮的状态
                    instancesSrv.getServerDetail(value[0].uid).then(function(result) {
                        self.serverDetail=result;
                         //没有获取到status状态时，不能进行点击透传设   关机状态下云主机才可以克隆
                        if(result.data&&result.data.status){
                            if ( self.selectedItemCount == 1){
                                self.canttEquiment=false;
                                let status = result.data.status.toLowerCase();
                                if(status == "shutoff") {
                                    self.canVmClone = false;
                                }else{
                                    self.canVmClone = true;
                                }
                                // 开机、关机、暂停、挂起状态下云主机才可以搁置
                                if(status == "active" || status == "shutoff" || status == "stopped" || status == "paused" || status == 'suspended'){
                                    self.shelveServerEnable = false;
                                }else{
                                    self.shelveServerEnable = true;
                                }
                            }
                        }else{
                            self.canttEquiment=true;
                            self.canVmClone = true;
                            self.shelveServerEnable = true;
                        }
                        if(result && result.data && angular.isArray(result.data.diskInfo)){
                            //企业版系统盘不能卸载
                            if(self.editData.uid ==  result.data.uid){
                                self.editDataCopy = result.data;
                                self.vmdiskInfo = result.data.diskInfo;
                                //限制开机状态磁盘为hd*不能卸载云硬盘
                                if(value[0].status=='active'){
                                    for(var i=0;i<self.vmdiskInfo.length;i++){
                                        if(self.vmdiskInfo[i].device.indexOf('/dev/hd')>-1){
                                            self.unloadDisk = true;
                                            break;
                                        }else{
                                            self.unloadDisk = false;
                                        }
                                    }
                                }else{
                                    self.unloadDisk = false;
                                }
                                self.vmdiskIdget = false;
                                self.showSnapRollBack = false;
                                
                                if(values[0] == 1){
                                    // 设备透传的时候，热迁移不可操作
                                   if((!result.data.passthroughDevices)||(result.data.passthroughDevices&&result.data.passthroughDevices.length==0)){
                                       self.migrate = false;
                                       if(value[0].vgpuId){
                                            self.migrate = true;
                                            self.migrateTip = $translate.instant("aws.instances.tip.migrateTip3");
                                        }else{
                                            self.migrate = false;
                                        }
                                   }else{
                                        self.migrateTip = $translate.instant("aws.instances.tip.migrateTip");
                                        self.canVmClone = true;
                                        self.shelveServerEnable = true;
                                   }
                               }
                               if(values[1] == 1){
                                   // 设备透传的时候，冷迁移、调整配置不可操作
                                   if((!result.data.passthroughDevices)||(result.data.passthroughDevices&&result.data.passthroughDevices.length==0)){
                                        // 绑定vgpu的情况下 不可调整配置 冷迁移
                                        if(value[0].vgpuId){
                                            self.upgrade = true;
                                            self.upgradeTip = $translate.instant("aws.instances.tip.resize_tip3");
                                            self.coldMigrateBtn = true;
                                            self.coldMigrateTip = $translate.instant("aws.instances.tip.coldMigrate1");
                                        }else{
                                            self.upgrade = false;
                                            self.coldMigrateBtn = false;
                                        }
                                       
                                   }else{
                                        self.coldMigrateTip = $translate.instant("aws.instances.tip.coldMigrate");
                                        self.upgradeTip = $translate.instant("aws.instances.tip.resize_tip");
                                        self.canVmClone = true;
                                        self.shelveServerEnable = true;
                                   }
                               }
                               if(result.data.startStyle == "Local"){
                                    self.upgradeTip = $translate.instant("aws.instances.tip.localTip");
                                    self.migrateTip = $translate.instant("aws.instances.tip.localTip"); //2.6.2放开本地盘可以进行热迁移，6.1版本禁止本地盘可以进行热迁移
                                    self.makImageTip = $translate.instant("aws.instances.tip.localTip");
                                    self.coldMigrateTip = $translate.instant("aws.instances.tip.localTip");
                                    self.canVmClone = true;
                                    self.shelveServerEnable = true;
                               }
                                if((result.data.startStyle == "EBS" && result.data.diskInfo.length-1) || result.data.startStyle == "Local" ){
                                    self.detackDisk = false;
                                }
                               chktip( result.data);
                               chkRetyping(self.vmdiskInfo);
                               chkTransferRetyping(self.vmdiskInfo);
                                
                            }
                            
                            let lowerStatus = result.data.status.toLowerCase();
                            if(result.data&&result.data.passthroughDevices.length>0){
                                for(var i=0;i<result.data.passthroughDevices.length;i++){
                                    let passthroughDevices = '';
                                    if(result.data.passthroughDevices[i].type) {
                                        passthroughDevices = result.data.passthroughDevices[i].type.toLowerCase();
                                    }
                                    // 云主机挂载fpga或gpu，不可挂起
                                    if(passthroughDevices=="fpga" || passthroughDevices=='gpu'){    
                                        self.hang=true;
                                        self.hangUp = $translate.instant("aws.instances.tip.hangUpTip");
                                    }
                                    // 开机状态且有加载fpga卡时，不允许
                                    if(passthroughDevices == "fpga" && lowerStatus == "active") {
                                        self.notFpgaCanMakeImg=true;
                                        self.makImageTip= $translate.instant("aws.instances.tip.normalStartLoadFpga");
                                    }
                                }
                            }
                            
                            result.data.diskInfo.map(item=> {
                                if(item.root_device) {
                                    // iso方式启动不能克隆
                                    if(item.bootable == "true" && item.root_device == "true" && item.diskFormat =="iso" ) {
                                        self.canVmClone = true;
                                    }
                                    // nfs存储的云主机不允许克隆(volumes_volumes有一个为false，则不允许云主机克隆)
                                    if(!item.storageLimit.volumes_volumes) {
                                        self.canVmClone = true;
                                    }
                                }
                            } )

                            // 加载了本地磁盘的云主机不能克隆、搁置
                            if(result.data.localDiskInfo.length>0) {
                                self.canVmClone = true;
                                self.shelveServerEnable = true;
                            }
                           
                            // 加载了vgpu设备的云主机不能克隆、搁置
                            if(result.data.tbvgpuCmp && Object.keys(result.data.tbvgpuCmp).length>0) {
                                self.canVmClone = true;
                                self.shelveServerEnable = true;
                            }

                            // 设置了大页内存、numa拓扑的云主机不能克隆、搁置
                            if(result.data.metaData && Object.keys(result.data.metaData).length>0) {
                                if(result.data.metaData.hugePage || result.data.metaData.numaMempolicy) {
                                    self.canVmClone = true;
                                    self.shelveServerEnable = true;
                                }
                            }
                            // 设置了sr-iov网络的云主机不能克隆、搁置
                            if(result.data.flavorTag == 1) {
                                self.canVmClone = true;
                                self.shelveServerEnable = true;
                            }
                            // 设置了dpdk的云主机不能搁置
                            if(result.data.dpdk == true) {
                                self.shelveServerEnable = true;
                            }
                        }else{
                            self.detackDisk = true;
                        }
                });
                }
                //获取vnc地址
                if (value[0].status == "active") {
                    getConsole(value[0].uid)
                    if(value[0].startStyle != "Local"){
                        self.oshotgradeTip = $translate.instant("aws.instances.tip.not_get_info");
                        getoshotupgrade(value[0].uid)
                    }else{
                        self.oshotgradeTip = $translate.instant("aws.instances.tip.localTip");
                    }
                }
                //备份和制作镜像
                if (value[0].status == "active" 
                || value[0].status == "stopped" 
                || value[0].status == "shutoff" 
                || value[0].status == "paused" 
                || value[0].status == "suspended") {
                    self.canBak = false;
                    self.vmSnap = false;
                    self.canBackups = false;
                    self.makImageTip = $translate.instant("aws.instances.tip.not_get_vmVolume");
                }else{
                    self.makImageTip = $translate.instant("aws.instances.tip.normalStatusTip");
                }
                if(value[0].status == "shutoff" || value[0].status == "stopped"){
                    self.bindVgpuEnable = false;
                }
                // 只有错误状态不可操作
                if(((values[0] == 1) || (values[1] == 1) || (values[3] == 1) || (values[4] == 1)|| (values[5] == 1))){
                    self.canDoItAnyTime = false;
                }
                // vgpu解绑动作 绑定过可解绑  不能做创建中 错误的状态进行
                if(value[0].vgpuId){
                    if(value[0].status != "error" && value[0].status != "build"){
                        self.unbindVpuEnable = false;
                        
                    }else{
                        self.unbindVpuEnable = true;
                        self.unbindVgpuTips = $translate.instant("aws.instances.tip.unbindVgpu");
                    }
                    self.bindVgpuEnable = true;
                    self.bindVgpuTips = $translate.instant("aws.instances.tip.bindVgpu1");
                }else{
                    self.unbindVpuEnable = true;
                    self.unbindVgpuTips = $translate.instant("aws.instances.tip.unbindVgpu1");
                }
                // 取消搁置按钮只能在搁置状态才能操作
                if(value[0].status == 'shelved_offloaded') {
                    self.unShelveServerEnable = false;
                }
                
                // 搁置状态单选允许的操作
                if ((values[6] == 1)) {
                    // 搁置状态下允许快照
                    self.vmSnap = false;
                    // 搁置状态下不允许云硬盘迁移
                    self.canStockTransfer=true;
                    // 判断当前是否只有一个存储池，若只有一个，云硬盘迁移不可点击
                    judgeNumberOfVolumePool();
                    // 处理云主机搁置状态时磁盘中那些按钮可点击操作
                    getShelveDetail(value[0]);
                }
            }

            if (value.length != 0) { 
                if (values[0] == value.length) { //开机可操作
                    self.activeEnable = false;
                    self.activeIsEnable = false;
                    self.suspend = false;
                    self.hang = false;
                    self.errorEnable = false;
                    self.shelveServerEnable = false;
                    if (values[0] == 1) { //单选操作
                        self.loading_Disk = false;
                        self.loadIptable = false;
                        self.edit_Sever = false;
                        self.alarmDisabled = false;
                        self.oshotgrade = false;
                        self.migrateTip = $translate.instant("aws.instances.tip.migrateTip");
                        self.loading_Disk_Tip  = $translate.instant("aws.instances.tip.not_get_vmVolume");
                        self.settingHaEnable = false;
                        self.canLoadingLocalDisk = false;
                        self.canDetachLocalDisk = false;
                    }
                } else if (values[1] == value.length) {
                    self.stopEnable = false; //关机允许的操作
                    self.evaDisabled = false;
                    self.shelveServerEnable = false;
                    if (values[1] == 1) {
                        self.loading_Disk = false;
                        self.edit_Sever = false;
                        self.alarmDisabled = false;
                        self.CDdisabled = false;
                        self.mountISODis = false;
                        self.unmountISODis = false;
                        self.change_Mac = false;
                        self.vmSnapRollBack = false;
                        self.canBootMenu = false;
                        self.canBackupRestore = false;
                        self.errorStartDisabled = false;
                        self.canVmClone = false;// 有bug注释了,云主机克隆页面需要先获取到详情中的存储数据后，克隆按钮才能点击 
                        self.canLoadingLocalDisk = false;
                        self.canDetachLocalDisk = false;
                        self.settingHaEnable = false;
                        self.snapshotRollbackTip = $translate.instant("aws.instances.tip.not_get_vmVolume");//快照回滚需要云主机下云硬盘
                        self.coldMigrateTip = $translate.instant("aws.instances.tip.not_get_vmVolume");
                        self.upgradeTip = $translate.instant("aws.instances.tip.not_get_vmVolume");
                        self.loading_Disk_Tip  = $translate.instant("aws.instances.tip.not_get_vmVolume");
                    }
                } else if (values[2] == value.length) {
                    self.hashost = true;
                    self.checkedItems.map(function(item) {
                        if (!item.hostId) {
                            self.errorEnable = true; 
                            self.hashost = false;
                        }
                    });
                    if (self.hashost) {
                        self.errorEnable = false; //
                    }
                } else if (values[3] == value.length) {
                    self.upSuspend = false; //暂停状态时允许操作
                    self.shelveServerEnable = false;
                    if(values[3] == 1) {
                        self.settingHaEnable = false;
                    }
                } else if (values[4] == value.length) {
                    self.upHang = false; //挂起状态时允许操作
                    self.shelveServerEnable = false;
                    if(values[4] == 1) {
                        self.settingHaEnable = false;
                    }
                } else if (values[5] > 0){
                    //self.delsDisabled = true;
                }else if(values[6] == value.length){
                    // 搁置状态下
                    if(values[6] == 1 ){// 单选操作
                        // 云硬盘加载按钮不可点击
                        self.loading_Disk = true;
                        // 快照回滚按钮可点击
                        self.vmSnapRollBack = false;
                        // 云硬盘卸载按钮不可点击
                        self.unloadDisk = true;
                    }
                }
                if(value.length==errorChk){
                    self.resetDisabled = false;
                }
                //判断是否可以删除
                if (rebootChk==0&&values[5]==0) {
                    canDelServerFunc(value);
                }
            }
            // 云主机批量搁置时 搁置按钮是否可点击的判断
            if(value.length>1){
                for (let i = 0; i < value.length; i++) {
                    if(!self.shelveServerEnable){
                        getShelveDetail(value[i]);       
                    }
                }
            }  
        });

        // 处理云主机搁置状态时磁盘中那些按钮可点击操作
        function getShelveDetail(value){
            instancesSrv.getServerDetail(value.uid).then(function(result) {
                if(result && result.data && angular.isArray(result.data.diskInfo)){
                    if(value.uid ==  result.data.uid){
                        self.vmdiskIdget = false;
                        self.vmdiskInfo = result.data.diskInfo;
                        if((result.data.startStyle == "EBS" && result.data.diskInfo.length-1) || result.data.startStyle == "Local" ){
                            // 云硬盘卸载可点击
                            self.detackDisk = false;
                        }
                        // 设备透传的云主机不能搁置
                        if(result.data.passthroughDevices.length>0) {
                            self.shelveServerEnable = true;
                        }

                        // 加载了本地磁盘云主机不能搁置
                        if(result.data.localDiskInfo.length>0) {
                            self.shelveServerEnable = true;
                        }
                       
                        // 加载了vgpu设备的云主机不能搁置
                        if(result.data.tbvgpuCmp && Object.keys(result.data.tbvgpuCmp).length>0) {
                            self.shelveServerEnable = true;
                        }

                        // 设置了大页内存、numa拓扑的云主机不能搁置
                        if(result.data.metaData && Object.keys(result.data.metaData).length>0) {
                            if(result.data.metaData.hugePage || result.data.metaData.numaMempolicy) {
                                self.shelveServerEnable = true;
                            }
                        }
                        // 设置了sr-iov网络的云主机不能搁置
                        if(result.data.flavorTag == 1) {
                            self.shelveServerEnable = true;
                        }
                        // 设置了dpdk的云主机不能搁置
                        if(result.data.dpdk == true) {
                            self.shelveServerEnable = true;
                        }
                    }
                }else{
                    // 云硬盘卸载可点击
                    self.detackDisk = false;
                }
            })
        };
            
        function getConsole(uid){
            instancesSrv.os_console(uid).then(function(result) {
                self.consoleUrl = encodeURI(result.data);
                var hostname = result.data.split("&");
                var conwatch=self.$watch(function(){
                    return self.checkedItems;
                },function(value){
                    if(value&&value.length == 1 && value[0].name== hostname[1].split("=")[1]){
                        self.conIsEnable = false;
                    }else{
                        self.conIsEnable = true;
                    }
                    conwatch();
                });
            });
        }

        function getoshotupgrade(uid){
            instancesSrv.getHotgrade(uid).then(function(res) {
                if (res && res.data) {
                    self.unhotgrade = !res.data.qga_support;
                    self.hotgradeData = res.data;
                }
                if(self.unhotgrade){
                    self.oshotgradeTip = $translate.instant("aws.instances.tip.oshotgradeTip1");
                } 
            });
        }

        function chkVolSize(){
            var regionUid=JSON.parse(localStorage.$LOGINDATA).regionUid;
            dataclusterSrv.getAllNode(regionUid).then(function(res){
                if(res && res.data && angular.isArray(res.data)){
                    res.data.map(item => {
                        item.hostInfo = JSON.parse(item.hostInfo)
                        if(item.hostInfo && item.hostInfo.var_data_size<300 ){
                            self.maxSizeLimit = true;
                        }
                    })
                }
            })
        }
        chkVolSize()
        
        //云主机正在进行卷迁移不可以再进行卷迁移操作
        function chkTransferRetyping(vmdisk){
            if(self.canStockTransfer) return ;
            vmdisk.map(item => {
                if(item.status == "retyping"){
                    self.canStockTransfer = true;
                    self.stock_transferTip = $translate.instant("aws.instances.tipMsg.stock_transferTip1");
                }
            })
        }
        
        //判断当前是否只有一个存储池，若只有一个，云硬盘迁移不可点击
        function judgeNumberOfVolumePool(){
            if(self.canStockTransfer) return ;
            instancesSrv.getStorage().then(function(res){
                if(res&&res.data&&angular.isArray(res.data)){
                    if(res.data.length==1){
                        self.canStockTransfer=true;
                        self.stock_transferTip = $translate.instant("aws.instances.tipMsg.oneStoragePoolCanNotTransfer");
                    }
                }   
            });    
        }

        function chkRetyping(vmdisk){
            if(self.loading_Disk) return ;
            vmdisk.map(item => {
                if(item.status == "retyping"){
                    self.loading_Disk = true;
                    self.loading_Disk_Tip = $translate.instant("aws.instances.tip.loading_Disk_Tip1")
                }
            })
        }

        function chktip(editData){
            //本地盘创建的云主机
            if(editData.startStyle == "Local"){
                self.volumeStatusDis = false;
                self.storageLimitImage = false;
                self.limitImageSize = false;
                // self.storageLimitMigrate=false;//2.6.2本地盘云主机热迁移放开 ,6.1版本禁止本地盘云主机热迁移
                // self.storageLimitColdMig = false;//2.6.2本地盘云主机冷迁移放开,6.1版本禁止本地盘云主机冷迁移
                return

            }
            //云硬盘创建的云主机
            self.vmdiskInfo.map(x => {
                if(x.bootable=="true" && x.root_device == "true" && x.diskFormat !="iso" &&
                 x.associatedHost.indexOf('toyou')>-1 ){
                   self.showSnapRollBack = true;
                }
                if(x.root_device == "true" && x.diskFormat !="iso"){
                    if(x.status == "in-use"){
                        self.volumeStatusDis = false;
                        if(!self.canBackups && x.storageLimit && !x.storageLimit["os-volume_upload_image"]){
                            self.makImageTip = $translate.instant("aws.instances.tip.storageTypeTip");
                        }else{
                            self.storageLimitImage = false;
                            if(self.maxSizeLimit){
                                if(x.size > 50){
                                    self.makImageTip = $translate.instant("aws.instances.tip.maxSizeTip");
                                }else{
                                    self.limitImageSize = false;
                                }
                            }else{
                                self.limitImageSize = false;
                            }
                        }
                        if(!self.migrate && x.storageLimit && !x.storageLimit["os_hot_migrate"]){
                            self.migrateTip = $translate.instant("aws.instances.tip.storageTypeTip");
                        }else{
                            self.storageLimitMigrate = false;
                        }
                        if(!self.coldMigrateBtn && x.storageLimit && !x.storageLimit["os_cold_migrate"]){
                            self.coldMigrateTip = $translate.instant("aws.instances.tip.storageTypeTip");
                        }else{
                            self.storageLimitColdMig = false;
                        } 
                    }else{
                        if(!self.canBackups && x.status != "in-use"){
                            self.makImageTip = $translate.instant("aws.instances.tip.makImgTip");
                        }
                        if(!self.canRebuild && x.status != "in-use" ){
                            self.rebuildTipTip = $translate.instant("aws.instances.tip.rebuildTip1");
                        }
                        if(!self.migrate && x.status != "in-use"){
                            self.migrateTip = $translate.instant("aws.instances.tip.migrateTip1");
                        }
                        if(!self.coldMigrateBtn && x.status != "in-use"){
                            self.coldMigrateTip = $translate.instant("aws.instances.tip.coldMigrateTip1");
                        }
    
                    }
                }
                
                
            })
           
            self.vmdiskInfoRollBack = self.vmdiskInfo.filter(item => {
                // 加了本地磁盘后数据没有associatedHost
                if(item.associatedHost) {
                    item.associatedHost.indexOf('toyou') == -1
                }
             });
        }
        //对云主机热迁移返回来的主机别名进行处理
        function migrationHandleNodeName(obj,nodePrettyNameList,data){
             obj.prettyName=nodePrettyNameList[data.eventMata.host]
             obj.hostName = data.eventMata.host;
             return obj
        }
        //server socket
        self.$on("serverSocket", function(e, data) {
            if (self.tabledata && self.tabledata.length) {
                self.tabledata.map(function(obj) {
                    if (data.eventMata.instance_id) {
                        if (obj.uid === data.eventMata.instance_id) {
                            obj.task_state = data.eventMata.status;
                            obj.status = obj.task_state?obj.task_state:obj.status;
                            if(data.eventMata.host && (data.eventType == "compute.instance.create.end" || data.eventType == "compute.instance.resize.confirm.end")){
                                obj=commonFuncSrv.showPrettyNameOfNode(obj,migrationHandleNodeName,data);
                            }
                            if (data.eventType == "compute.instance.create.end") {
                                // data.eventMata.fixed_ips = angular.fromJson(data.eventMata.fixed_ips);
                                // var addfixed_ip = data.eventMata.fixed_ips[0].address;
                                // obj.fixedIps[0] ? "" : obj.fixedIps.push(addfixed_ip);
                                
                                if(self.cancelInstanFun){
                                    $timeout.cancel(self.cancelInstanFun);
                                }
                                self.cancelInstanFun = $timeout(()=>{
                                    getInstance()
                                },200)

                            }
                            if (data.eventType == "compute.instance.soft_delete.end") { //删除
                                //getInstance()
                                if(self.pageInstan.currentPage==1){
                                    getInstance();
                                    return;
                                }
                                _.remove(self.tabledata, function(val) {
                                    return ((val.status == "soft-delete" || val.status == "error" || val.status == "deleted") && val.uid === data.eventMata.instance_id);
                                });
                                if(self.tabledata.length===0){
                                    self.pageInstan.currentPage = (self.pageInstan.currentPage - 1)<2?1:(self.pageInstan.currentPage - 1)
                                    var pageDefaultOption = {
                                        pageNum:self.pageInstan.currentPage
                                    };
                                    getInstance(pageDefaultOption,true);
                                }
                            }
                            if (data.eventType == "compute.instance.delete.end") { //删除
                                //getInstance()
                                if(self.pageInstan.currentPage==1){
                                    getInstance();
                                    return;
                                }
                                _.remove(self.tabledata, function(val) {
                                    return ((val.status == "error" || val.status == "deleted") && val.uid === data.eventMata.instance_id);
                                });
                                if(self.tabledata.length===0){
                                    self.pageInstan.currentPage = (self.pageInstan.currentPage - 1)<2?1:(self.pageInstan.currentPage - 1)
                                    var pageDefaultOption = {
                                        pageNum:self.pageInstan.currentPage
                                    };
                                    getInstance(pageDefaultOption,true);
                                }
                            }
                            if (data.eventType == "start_instance") { 
                                //透传失败
                                if(data.eventMata&&data.eventMata.message&&data.eventMata.message.indexOf('could not access /sys/bus/pci/devices/')>-1){
                                }else if(data.result=="error"){//开机失败
                                   obj.status = 'SHUTOFF'; 
                                }
                            }
                            //透传失败
                            if (data.eventType == "compute.instance.resume.error") { 
                                if(data.eventMata&&data.eventMata.message&&data.eventMata.message.indexOf('could not access /sys/bus/pci/devices/')>-1){
                                }
                            }
                            //热迁移
                            if (data.eventType == "compute.instance.live_migration.post.dest.end") { //确认热迁移
                                obj=commonFuncSrv.showPrettyNameOfNode(obj,migrationHandleNodeName,data);
                            }
                            if(data.eventType == "compute.instance.finish_resize.end" && !self.ADMIN){
                                $window.IntervalResize(data.eventMata.instance_id)
                            }
                            // 云主机搁置和取消搁置 取消搁置失败
                            let tipMessage = $translate.instant("aws.sockets.opLog." + data.eventType );
                            if(data.eventType == "compute.instance.shelve_offload.start" || data.eventType == "compute.instance.unshelve.start") {
                                alert.set(data.requestId, tipMessage, "building", 5000);
                            }else if(data.eventType == "compute.instance.shelve_offload.end" || data.eventType == "compute.instance.unshelve.end"){
                                alert.set(data.requestId, tipMessage, "success", 5000);
                                getInstance();
                            }else if(data.eventType == "compute.instance.unshelve.error"){
                                if(data.eventMata&&data.eventMata.message&&data.eventMata.message.indexOf("No valid host was found") > -1){
                                    alert.set(data.requestId, tipMessage, "error", 5000);
                                }
                                getInstance();  
                            }
                        }
                    }

                    //更新公网IP
                    if (data.eventMata.floatingip_id) {
                        if (self.relieveIp && obj.uid == self.relieveIp_uid) {
                            obj.floatingIps = obj.floatingIps.filter(function(item) {
                                return item != self.relieveIp;
                            });
                            self.relieveIp = "";
                            self.relieveIp_uid = "";
                        } else if (self.bindFip && obj.uid == self.bindFip_uid) {
                            obj.floatingIps.push(self.bindFip);
                            self.bindFip = "";
                            self.bindFip_uid = "";
                        }
                    }
                    // //更新网卡
                    // if (self.removefixedIp && obj.uid == self.removefixedIp_uid) {
                    //     let tipMsg = $translate.instant("aws.sockets.opLog." + "port.delete.end");
                    //     alert.set(data.requestId, tipMsg, "success", 5000);
                        
                    //     //去除掉被移除的网卡
                    //     let removeAddr=self.removefixedIp.mac_addr;
                    //     let removeArr=[];
                    //     // obj.netAddresses.forEach(function(item){
                    //     //     item.novaAddressList.forEach(function(addr){
                    //     //         if(addr['OS-EXT-IPS-MAC:mac_addr']==removeAddr){
                    //     //            removeArr.push(addr.addr);
                    //     //         }
                    //     //     });
                    //     // });
                    //     obj.ports.forEach(item=>{
                    //         if(item.macAddr==removeAddr){
                    //             removeArr.push(item.ipAddress);
                    //         }
                    //     })
                    //     //找到obj.fixedIps与removeArr的不同
                    //     obj.fixedIps=_.difference(obj.fixedIps,removeArr);
                    //     obj.floatingIps = obj.floatingIps.filter(function(item) {
                    //         return item != self.rmfip;
                    //     });
                    //     //如果网卡绑定了公网IP，则同时要移除列表中与之对应的公网ip localStorage.rmfip
                    //     self.removefixedIp = "";
                    //     self.removefixedIp_uid = "";
                    //     self.rmfip = "";
                    // } else if (self.addFixed_Ip && obj.uid == self.addFixed_uid) {
                    //     let tipMsg_a = $translate.instant("aws.sockets.opLog." + "port.create.end");
                    //     alert.set(data.requestId, tipMsg_a, "success", 5000);
                    //     self.addFixed_Ip = "";
                    //     self.addFixed_uid = "";
                    // } else if (self.updateFixedIp && obj.uid == self.updateFixedIp_uid) {
                    //     let tipMsg_a = $translate.instant("aws.sockets.opLog." + "fixedip.update.end");
                    //     alert.set(data.requestId, tipMsg_a, "success", 5000);
                    //     let ipIndex = _.findIndex(obj.fixedIps,function(ip){
                    //         return ip == self.updateFixedIp_data.oldIp;
                    //     });
                    //     obj.fixedIps.splice(ipIndex,1); //移除修改前的IP（云主机会有不同网络下相同的IP）
                    //     obj.fixedIps.push(self.updateFixedIp_data.newIp); //底层反应延迟，手动更新
                    //     self.updateFixedIp = "";
                    //     self.updateFixedIp_uid = "";
                    // }
                });
                
                
                self.tabledata = operateData(self.tabledata);
                self.tableParams.reload();
                self.checkboxes.items = {};
            }
            if(data.eventType == "compute.instance.create.start" 
            || data.eventType == "compute.instance.create.error" 
            || data.eventType == "compute_task.build_instances"    //重建完成后刷新列表
            || data.eventType == "compute.instance.rebuild.end"
            ){  //解决api响应慢，websocket相应快。导致创建失败的时候有个别创建中的状态没有及时更新
                    $timeout(function(){
                        getInstance();
                    },2000)
                }

            //云硬盘迁移重启开机刷新状态
            if(data.eventType == "compute.instance.reboot.start" || data.eventType == "compute.instance.reboot.end") {
                getInstance()
            }
        });

        self.$on("createPorts",function(){
            let tipMsg_a = $translate.instant("aws.sockets.opLog.port.create.end");
            alert.set('', tipMsg_a, "success", 5000);
            $timeout(function(){
                getInstance();
            },500)
            
        })

        self.$on("updatePorts",function(){
            let tipMsg_a = $translate.instant("aws.sockets.opLog.port.update.end");
            alert.set('', tipMsg_a, "success", 5000);
            $timeout(function(){
                getInstance();
            },500)
        })

        self.$on("deletePorts",function(){
            let tipMsg_a = $translate.instant("aws.sockets.opLog.port.delete.end");
            alert.set('', tipMsg_a, "success", 5000);
            $timeout(function(){
                getInstance();
            },500)
        })

        self.$on("backupSocket", function(e, data){
            if (self.tabledata && self.tabledata.length){
                self.checkboxes.items = {};
            }
        })
        
        self.$on("refreshSuccess",function(e,data){
            let tipMsg = data.msg || $translate.instant("aws.statusCode.0");
            let {showMsg} = data;
            if(showMsg) {
                alert.set('', tipMsg, "success", 5000);
            }
            $timeout(function(){
                getInstance();
            }, 500)
        })

        self.$on("vmCloneSocket",function(){
            $timeout(function(){
                getInstance();
            },500)
        })

        $rootScope.$on("loadingLocalDisk",function(event,data) {
            self.isClickLocalDiskConfirm = true;
        })

        $rootScope.$on("detachLocalDisk",function(event,data) {
            self.isClickLocalDiskConfirm = true;
        })
        
        // 本地磁盘挂载和卸载的推送消息
        self.$on("localDiskSocket",function(e,data){
            if(self.isClickLocalDiskConfirm) {
                let tipMessage = $translate.instant("aws.sockets.opLog." + data.eventType + ".localDisk");
                switch(data.eventType) {
                    case "compute.instance.volume.attach":
                    case "compute.instance.volume.detach":
                        alert.set(data.requestId, tipMessage, "success", 5000);
                        self.isClickLocalDiskConfirm = false;
                    break
                    case "compute.instance.volume.attach.error":
                    case "compute.instance.volume.detach.error":
                        alert.set(data.requestId, tipMessage, "error", 5000);
                        self.isClickLocalDiskConfirm = false;
                        break    
                }
            }
        })

        $window.IntervalResize = function(id){
            var flag=false;
            var timer = $timeout(function(){
                instancesSrv.getServerDetail(id).then(function(result){
                    if (result && result.data){
                        result.data.status = result.data.status.toLowerCase();
                        if(result.data.status == "shutoff"){
                            flag = true;
                        }
                        if (self.tabledata && self.tabledata.length){
                            self.tabledata.map(function(obj){
                                if(obj.uid == id){
                                    obj.status = result.data.status;
                                }
                            })
                        }
                    }
                }).finally(function(){
                    if(!flag){
                        $window.IntervalResize(id);
                    }else{
                        $timeout.canel(timer);
                    }
                })
            },5000)
        }

        // 修改标签
        self.updatetag = function(){
            self.createItemModal = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/change-label-alert.html",
                controller: "changeLabelAlertCtrl",
                resolve: {
                    context: function() {
                        return self;
                    },
                    activeItem:function(){
                        return self.activeItem;
                    },
                    refreshIns:function(){
                        return self.refreshIns
                    }
                }
            })
        }

        //获取磁盘详情，暂时未放开
        function getDiskDetail(value){
            var detailData = {};
            instancesSrv.getDiskDetail(value).then(function(result) {
                if(result && result.data && angular.isArray(result.data)){
                    if($routeParams.diskId!=value){return;}
                    detailData = result.data[0];
                    detailData.description = detailData.description == "auto-created_from_restore_from_backup"?detailData.description = $translate.instant("aws.instances.fromBackupRecover"):detailData.description;
                    detailData.ori_type = angular.copy(detailData.type);
                    detailData.created = moment(new Date(detailData.created)).format('YYYY-MM-DD HH:mm:ss');
                    self.detailvalue = "" ;
                    if(detailData.storageInfo.name.indexOf("toyou")>-1){
                        var postData = {
                            "volume_type_name": detailData.ori_type,
                        }
                        detailData.feature = [];
                        storageSrv.volume_type_analysis(postData).then(function(result){
                            if(result && result.data && angular.isObject(result.data)){
                                detailData.type = result.data.Storage_name;
                                detailData.pool = result.data.Pool_name
                                for(let key in result.data.Character_message){
                                    result.data.Character_message[key]>0 ? detailData.feature.push(key):"";
                                }
                            }

                        })
                    }
                }
                self.diskDetailData=detailData;
            });
        }
        $scope.$watch(function() {
            return $routeParams;
        }, function(value) {
            $scope.detailIn = value ? true : false;
            self.diskDetailData={};//磁盘信息
            if($routeParams.diskId){
                $scope.diskAnimation='animateIn';//控制磁盘详情页展示
                getDiskDetail($routeParams.diskId);
                instancesSrv.getVolumeSnapshots({volumeid:$routeParams.diskId}).then(function(res){
                    if(res && res.data && angular.isArray(res.data)){
                        self.snapshotTable = new NgTableParams({ count: res.data.length }, { counts: [], dataset:res.data});
                    }
                })
                instancesSrv.getVolumeBackupsTable($routeParams.diskId).then(function(res){
                    if(res && res.data && angular.isArray(res.data)){
                        self.backupTable = new NgTableParams({ count: res.data.length }, { counts: [], dataset:res.data});
                    }
                })
            }else{
                $scope.diskAnimation='animateOut';
                self.diskDetailData={};
                self.snapshotTable = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset:[]});
                self.backupTable = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset:[]});
            }
        },true);
        // 详情加载的内容
        self.$on("getDetail", function(event, insUid) {
            self.panels = {};
            self.tab = {};
            self.tab = {
                active : 0
            }
            self.tabCacheObj = utilTool.tabsShow(5,'open');
            self.chartDisplay = function(i) {
                self.tab.active = i
                self.tabCacheObj[`tabCache${i}`] = true
            }
            self.insUid = insUid;
        })
        
        $scope.$on("refreshInstance", function() {
            getInstance();
        });

        //新建云主机
        self.insAnimation = "animateOut";
        self.newInstance = function() {
            utilTool.newResSetLocalStorage(self,'checkboxes','items')
            self.insAnimation = "animateIn";
            $("body").addClass("animate-open")
        };
        self.closeNewIns = function(){
            self.insAnimation = "animateOut";
            $("body").removeClass("animate-open")
        }
        self.$on("newIns",function(){
            self.insAnimation = "animateOut";
            $("body").removeClass("animate-open");
            self.globalSearchTerm = null;
            self.statusSelected.searchItem = self.searchList[0]
            getInstance();
        })
        //云主机创建快照
        self.createSnap = function(editData) {
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/createSnap.html",
                controller: "createSnapCtrl",
                resolve: {
                    editData: function() {
                        return editData
                    }
				}

            });
        };
         //云主机快照回滚
        self.snapRollBack = function() {
            if(!self.vmSnapRollBack && !self.vmdiskIdget){
                var vmdiskInfo = self.vmdiskInfo; 
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "js/cvm/instances/tmpl/os-snap-rollback.html",
                    controller: "snapRollBackCtrl",
                    resolve: {
                        vmdiskInfo: function(){
                            return vmdiskInfo
                        }
                    }

                });
            }
            
        };
        //获取项目中的总配额
        function getproQuotas() {
            var insQuotapost = {
                type: "project_quota",
                targetId: localStorage.projectUid,
                enterpriseUid: localStorage.enterpriseUid
            };
            cvmViewSrv.getproQuotas(insQuotapost).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "quota"].total = item.defaultQuota || item.hardLimit;
                        }
                    });
                }
            });
        }
        //获取项目中配额的使用量
        function getProused() {
            self.instancesquota = {};
            self.coresquota = {};
            self.ramquota = {};
            var postData = {
                type: "project_quota",
                domainUid: localStorage.domainUid,
                projectUid: localStorage.projectUid,
                enterpriseUid: localStorage.enterpriseUid
            };
            cvmViewSrv.getProused(postData).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "quota"].used = item.inUse;
                        }
                    });
                }
                getproQuotas();
            });
            
        }
        //获取部门中的总配额
        function getDomQuotas() {
            depviewsrv.getQuotaTotal(localStorage.domainUid).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "Domquota"].total = item.defaultQuota || item.hardLimit;
                        }
                    });
                }
            });
        }
        //获取部门中配额的使用量
        function getDomused() {
            self.instancesDomquota = {};
            self.coresDomquota = {};
            self.ramDomquota = {};
            depviewsrv.getQuotaUsed(localStorage.domainUid).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "Domquota"].used = item.inUse;
                        }
                    });
                }
                getDomQuotas();
            });
           
        }

        //选中多个
        self.chkSome = function() {
            var chkGroup = [];
            var itemGroup = [];
            var names = [];
            var postData = {
                id: { ids: chkGroup },
                items: itemGroup,
                names:names
            };
            _.forEach(self.tableParams.data, function(group) {
                if (self.checkboxes.items[group.uid]) {
                    chkGroup.push(group.uid);
                    itemGroup.push(group);
                    names.push(group.name);
                }
            });
            return postData;
        };
        //开启虚拟机
        self.startServer = function(checkedItems) {
            var post = [];
            var content = {
                target: "startServer",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.startServer") + "</span>",
                type: "info",
                btnType: "btn-primary"
            };
            checkedItems.map(item=>{
                post.push({uid:item.uid,name:item.name})
            })
            instancesSrv.getRestoringVolServer(post).then(function(res){
                if(res && res.data && angular.isArray(res.data)){
                    if(res.data.length>0){
                        content.msg = "<span>"+ res.data.toString() +"虚拟机存在正在备份还原或快照回滚的云硬盘，无法开机"+"</span>";
                        content.notDel = true;
                    }
                    self.$emit("delete", content);
                }
                
            })
            
        };
        self.$on("startServer", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"power_on")
            instancesSrv.startServer(self.chkSome().id).then(function(result) {
                self.checkboxes.items = {};
            });
        });
        //关闭虚拟机
        self.stopServer = function() {
            var content = {
                target: "stopServer",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.stopServer") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("stopServer", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"power_off")
            instancesSrv.stopServer(self.chkSome().id).then(function() {
                self.checkboxes.items = {};

            });
        });
        //删除虚拟机
        self.delSever = function() {
            if(!self.delServerDisabled){
                commonFuncSrv.deleteInstance(self,self.chkSome().id.ids,self.checkedItems);
            }
        };
        //删除虚拟机
        self.forceDelSever = function() {
            var data = {
                uid:self.chkSome().id.ids
            };
            //判断该主机下是否有定时快照
            checkScheduleJob(self,data,"","instance-force-delete")
        };
       
        function emitDel(show_del_job_tip,data,type){
            var scope = $rootScope.$new();
            var modal_os_delete= $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "os-delete.html",
                scope: scope
            });
            scope.canDelSysDistRedio = self.canDelSysDistRedio;
            scope.show_del_job_tip = show_del_job_tip;
            scope.del = {
                "job":data,
                "flag":true
            }
            scope.confirmDel = function(){
                var  ids = self.chkSome().id.ids;
                checkedSrv.setChkIds(ids,"delete")
                if(type == "instance-soft-delete"){
                    if(show_del_job_tip){
                        delTaskBatch(scope.del.job)
                    }
                    instancesSrv.delServer(self.chkSome().id,scope.del.flag).then(function() {
                    });
                }else if(type == "instance-force-delete"){
                    if(show_del_job_tip){
                        delTaskBatch(scope.del.job)
                    }
                    instancesSrv.osForceDel(self.chkSome().id).then(function() {
                    });
                }
                modal_os_delete.close()
            }
        }
        function delTaskBatch(data){
            var jobids = data;
            if(data && data.length){
                regularSnapSrv.delTaskBatch({ids:data}).then(function(){
                })
            }
        }
        
        //重置虚拟机
        self.reset = function() {
            if(self.resetDisabled) return;
            var content = {
                target: "resetIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.opsOperation") +"</span>"
            };
            self.$emit("delete", content);
        };
        self.$on("resetIns", function() {
            var  ids = self.chkSome().id.ids;
            var data = {
                ids:ids,
                names:self.chkSome().names
            };
            instancesSrv.resetVm(data).then(function() {
                getInstance();
            });
        });
        //重启虚拟机
        self.reboot = function() {
            var content = {
                target: "rebootIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.reboot") + "</span>",
                type: "info",
                btnType: "btn-primary"
            };
            self.$emit("delete", content);
        };
        self.$on("rebootIns", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"reboot")
            instancesSrv.rebootServer(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });
        //强制关机
        self.shutdown = function() {
            if(self.errorEnable) return;
            var content = {
                target: "shutdownIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.shutdown") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("shutdownIns", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"power_off");
            instancesSrv.shutdownServer(self.chkSome().id).then(function() {
                localStorage.shutdown_handle = true;
                self.checkboxes.items = {};
            });
        });
        //时钟同步closeClockSync
        self.clockSync = function() {
            var content = {
                target: "clockSync",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.clockSync") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("clockSync", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"clockSync");
            instancesSrv.clockSync(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });
        //关闭时钟同步
        self.closeClockSync = function() { 
            var content = {
                target: "closeClockSync",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.closeClockSync") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("closeClockSync", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"closeClockSync");
            instancesSrv.closeClockSync(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });
        //暂停云主机
        self.osSuspend = function() {
            var content = {
                target: "suspendIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.osSuspend") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("suspendIns", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"pause");
            instancesSrv.suspendServer(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });
        //暂停恢复云主机
        self.osUpPause = function() {
            var content = {
                target: "unPauseIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.osUpPause") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("unPauseIns", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"unpause");
            instancesSrv.unPauseServer(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });
        //挂起云主机
        self.osHang = function() {
            if(self.hang){
                return;
            }
            var content = {
                target: "hangIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.osHang") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("hangIns", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"suspend");
            instancesSrv.hangServer(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });
        //恢复挂起云主机
        self.osUpHang = function() {
            var content = {
                target: "unhangIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.osUpHang") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("unhangIns", function() {
            var  ids = self.chkSome().id.ids;
            checkedSrv.setChkIds(ids,"resume");
            instancesSrv.upHangServer(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });
        //编辑主机名
        self.editSever = function(editData) {
            self.editSeverModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/edit-hostname.html",
                controller: "editSeverCtrl",
                resolve: {
                    context: function() {
                        return self
                    },
                    editData: function() {
                        return editData
                    },
                    refresh: function(){
                        return getInstance
                    }
                }
            })
        };
        //绑定VGPU
        self.bindVgpu = function(editData) {
            var scope = $rootScope.$new();
            var modal_os_vgpu = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "update-vGpu.html",
                scope: scope
            });
            let params = {
                node:self.chkSome().items[0].hostName
            }
            vgpuSrv.getVGpuListTobind(params).then(function(result) {
                if (result && result.data) {
                    scope.vgpuList = result.data;
                    scope.bindvgpuData = {
                        chooseVgpu:scope.vgpuList[0]
                    }
                    scope.vgpuList.map(i =>{
                        if(i.id == self.chkSome().items[0].vgpuId){
                            scope.bindvgpuData = {
                                chooseVgpu:scope.vgpuList[i]
                            }
                        }
                    })
                    
                }
            })
            scope.addBindVgpu = function(){
                if(self.bindVgpuEnable){return;}
                if(scope.bindvgpuData.chooseVgpu){
                    let bindData = {
                        instanceId:self.chkSome().id.ids[0],
                        vgpuId:scope.bindvgpuData.chooseVgpu.id
                    }
    
                    instancesSrv.bindVgpuInstance(bindData).then(function(res){
                        modal_os_vgpu.close()
                        getInstance();
                    })
                }
                
            }
        };
        self.unbindVgpu = function() {
            if(self.unbindVpuEnable){return;}
            var content = {
                target: "unbindVgpu",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.unbindVgpu") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("unbindVgpu", function() {
            var  ids = self.chkSome().id.ids;
            let unbindData = {
                instanceId:self.chkSome().id.ids[0],
            }
            instancesSrv.unbindVgpuInstance(unbindData).then(function() {
                getInstance();
                self.checkboxes.items = {};
            });
        });
        //添加网卡
        self.addPortCard = function(editData){
            self.modalCardInstan = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/add-port-card.html",
                controller:'addPortCardCtrl',
                resolve:{
                    refresh:function(){
                        return getInstance
                    },
                    editData:function(){
                        return editData
                    },
                    context:self
                }
            })
        }

        //编辑网卡
        self.editPortCard = function(editData){
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/edit-port-card.html",
                controller:'editPortCardCtrl',
                resolve:{
                    refresh:function(){
                        return getInstance
                    },
                    editData:function(){
                        return editData
                    },
                    context:self
                }
            })
        }
        //添加网卡和移除网卡-已废除
        self.updateNetwork = function(type, editData) {

            var scope = $rootScope.$new();
            var modal_os_net = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: type=="remove"?"update-os-net-another.html":"update-os-net-add.html",
                scope: scope
            });
            scope.type = type
            scope.netWorkCard = {};
            scope.network = {
                assignIP: false,
                init_cidr: {
                    ip_0: "",
                    ip_1: "",
                    ip_2: "",
                    ip_3: ""
                }
            };
            scope.submitInValid = false;
            var eventMata = { eventMata: "" };
            scope.field_form = {};
            scope.interacted = function(field) {
                scope.field_form.netForm = field;
                return scope.submitInValid || field.ip_0.$dirty || field.ip_1.$dirty || field.ip_2.$dirty || field.ip_3.$dirty;
            };
            var chkPortPolicyFun = function(){
                if(scope.network.selected.subnets[scope.network.selected.sub_key].tenantId == localStorage.projectUid || localStorage.managementRole == "2"){
                    scope.portPolicyForbidden = false;
                    scope.rebootInsTip = true;
                }else{
                    scope.portPolicyForbidden = true;
                    scope.rebootInsTip = false;
                }
            };
            scope.isPaasNet = false;
            scope.changeNet = function(net) {
                if (type == "remove") {
                    return;
                }
                //paas网络判断
                if(net.net_sub_name.indexOf($translate.instant("aws.instances.paasNet"))>-1&&net.net_sub_name.indexOf($translate.instant("aws.instances.paasSubNet")>-1)){
                    scope.isPaasNet = true;
                    scope.network.secSelected = {name:'PaaS security pair'}
                }else{
                    scope.isPaasNet = false;
                }
                scope.repeatIp = false;
                scope.portPolicyForbidden = false;
                scope.cannot_Confirm = false;
                scope.validForm = false;
                scope.subSegmentList = [];
                if(scope.networkList_extend){
                    _.each(scope.networkList_extend,function(item){
                        if(item.id == net.id){
                            item.sub_name = item.net_sub_name.split("---")[1];
                            scope.subSegmentList.push(item);
                        }
                    });
                    scope.network.subSegment = scope.subSegmentList[0];
                    scope.changeSubSegment(scope.network.subSegment);
                }else{
                    scope.changeSubSegment(net);
                }
               
            };
            scope.changeSubSegment = function(net){
                scope.network = {
                    selected: scope.network.selected,
                    assignIP: scope.network.assignIP,
                    init_cidr: {
                        ip_0: "",
                        ip_1: "",
                        ip_2: "",
                        ip_3: ""
                    },
                    subSegment:scope.network.subSegment,
                    secSelected:scope.network.secSelected
                };
                
                if(type == "update"){
                    chkPortPolicyFun();
                }
                let startIp_list = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].start.split(".");
                let endIp_list = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].end.split(".");
                scope.compareIpFun(startIp_list,endIp_list);
                
            };
            scope.mouseNet = function(network,type){
                scope.net_subSegDetail = angular.copy(scope.networkList_extend);
                _.map(scope.net_subSegDetail,function(item){
                    if(item.id == network.id){
                        item.sub_name = item.net_sub_name.split("---")[1];
                        if(type == "over"){
                             item.showSubSegDetail = true;
                         }else{
                             item.showSubSegDetail = false;
                         }
                        return item 
                    }
                });
            };
            scope.focusInput = function() {
                scope.repeatIp = false;
                scope.cannot_Confirm = false;
                scope.validForm = false;
            };
            scope.addNetCard = false;
            scope.modifyFixdIp = false;
            avoidDoubleClick(scope,false)
            switch (type) {
                case "add":
                    scope.netTitle = $translate.instant("aws.instances.net.addNet");
                    scope.ipLabel = $translate.instant("aws.instances.addinstances.ipAddr");
                    scope.addNetCard = true;
                    scope = commonFuncSrv.settingIpFunc(scope,"network","netForm");
                    scope.setCheckValueFunc = function(){
                        let net = scope.network.subSegment;
                        let startIp = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].start;
                        let endIp = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].end;
                        scope.checkValue(startIp,endIp);
                    };
                    //获取安全组列表
                    getServerSecGroup(scope,"",editData,true)
                    //获取该项目下的网卡
                    instancesSrv.getProjectNetworks().then(function(result) {
                        if (result && result.data && angular.isArray(result.data) ) {
                            result.data.forEach(function(item){
                                // item.subnets=item.subnets.filter(function(subnet){
                                //     return subnet.ipVersion=='4';
                                // });
                            });
                            scope.networkList = result.data.filter(function(item) {
                                return item.subnets.length; //没有绑定子网的交换机创建云主机时不能使用
                            });
                            //定制开发，如果为非admin用户，添加网卡过滤掉公网
                            if(!$rootScope.ADMIN){
                                scope.networkList = result.data.filter(function(item) {
                                    return !item.external; 
                                });
                            }
                            scope.networkList_extend = [];
                            _.each(scope.networkList, function(item) {
                                //创建网络时一个交换机对应一个子网的限制放开，一个交换机可以有多个子网，一个子网又可以有多个IP地址池
                                for(let i=0;i<item.subnets.length;i++){
                                    for(let j=0;j<item.subnets[i].allocationPools.length;j++){
                                        item.net_sub_name = item.name + "---" + item.subnets[i].name + "(" + item.subnets[i].allocationPools[j].start + "~" + item.subnets[i].allocationPools[j].end + ")";
                                        item.displayName = item.name;
                                        item.sub_key = i;
                                        item.allocationPool_key = j; 
                                        item.subnet_id=item.subnets[i].id;
                                        scope.networkList_extend.push(angular.copy(item));
                                    }
                                }
                            });

                            scope.network.selected = scope.networkList[0];
                            scope.subSegmentList = [];
                            _.each(scope.networkList_extend,function(item){
                                if(item.id == scope.network.selected.id){
                                    item.sub_name = item.net_sub_name.split("---")[1];
                                    scope.subSegmentList.push(item);
                                }
                            });
                            scope.network.subSegment = scope.subSegmentList[0];
                            let net = scope.network.subSegment;
                            let startIp_list = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].start.split(".");
                            let endIp_list = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].end.split(".");
                            scope.compareIpFun(startIp_list,endIp_list);
                            if(scope.network.selected.net_sub_name.indexOf($translate.instant("aws.instances.paasNet"))>-1&&scope.network.selected.net_sub_name.indexOf($translate.instant("aws.instances.paasSubNet")>-1)){
                                scope.isPaasNet = true;
                                scope.network.secSelected = {name:'PaaS security pair'}
                            }else{
                                scope.isPaasNet = false;
                            }
                        }
                    });
                    scope.confirm = function(upNet, netForm) {
                        var postData = {};
                        var addNetCard = function(postData) {
                            self.addfixIp=true;
                            self.addFixed_Ip = true;
                            self.addFixed_uid = editData.uid;
                            instancesSrv.addNetwork(editData.uid, postData.fixdIps, postData.networkid).then(function(result) {
                                if(result.status == 0){
                                    var postData = {
                                        id:result.data.port_id,
                                        secGroup:[scope.network.secSelected.id]
                                    }
                                    instancesSrv.updatePortSecurity(postData).then(function(result){
                                    })
                                }
                                var sec = scope.network.secSelected;
                                // $rootScope.$broadcast("serverSocket", eventMata);
                                // getInstance();
                            }).finally(function(){
                                self.addfixIp=false;
                            });
                            scope.addNetMsgModal.close();
                        }
                        scope.addNetMsgConfirm=function(scope,addNetCard){
                            scope.addNetMsgModal = $uibModal.open({
                                animation: $scope.animationsEnabled,
                                templateUrl: "addNetMsg.html",
                                scope: scope,
                            });
                            scope.MsgConfirm=addNetCard;
                        }
                        if (netForm.$valid) {
                            postData = {
                                "networkid": {
                                    "networkid": upNet.id
                                },
                                "fixdIps": []
                            };
                            avoidDoubleClick(scope,true)
                            scope.validForm = true;
                            //勾选配置ip
                            if (scope.network.assignIP) {
                                let ip_address = scope.network.init_cidr.ip_0 + "." + scope.network.init_cidr.ip_1 + "." + scope.network.init_cidr.ip_2 + "." + scope.network.init_cidr.ip_3;
                                postData.fixdIps = [{
                                    "ip_address": ip_address
                                }];
                                scope.setCheckValueFunc();
                                if (scope.field_form.netForm.$valid) {
                                    let existedIps = [];
                                    networksSrv.getNetworksDetail(scope.network.selected.id).then(function(res) {
                                        if (res && res.data) {
                                            _.each(res.data, function(item) {
                                                _.each(item.subnetIps, function(sub) {
                                                    existedIps.push(sub.ip_address);
                                                })
                                            })
                                            if (!_.include(existedIps, ip_address)) {
                                                //网关ip没有被占用添加网卡（判断子网是否有网关ip）
                                                scope.postData=angular.copy(postData);
                                                networksSrv.getSubnetDetail(scope.network.subSegment.subnet_id).then(function(res){
                                                     if(res&&res.data&&angular.isObject(res.data)){
                                                        if(res.data.gateway_ip){
                                                           scope.hasGateway=true;
                                                        }else{
                                                           scope.hasGateway=false;
                                                        }
                                                        modal_os_net.close();
                                                        scope.addNetMsgConfirm(scope,addNetCard)
                                                     }
                                                });
                                            } else {
                                                scope.repeatIp = true;
                                                scope.validForm = false;
                                            }
                                        }
                                    });
                                } else {
                                    scope.submitInValid = true;
                                    scope.validForm = false;
                                }
                            }else{
                            //没有勾选配置ip,判断所选网络下的子网是否有子网存在网管ip
                                scope.postData=angular.copy(postData);
                                scope.hasGateway=false;
                                scope.network.selected.subnets.forEach(function(subnet){
                                     if(subnet.gatewayIp){
                                        scope.hasGateway=true;
                                        return;
                                     }
                                });
                                modal_os_net.close();
                                scope.addNetMsgConfirm(scope,addNetCard)
                            }
                        } else {
                            scope.submitInValid = true;
                            scope.validForm = false;
                        }

                    };
                    break;
                case "remove":
                    scope.netTitle = $translate.instant("aws.instances.net.removeNet");
                    var floatingIpList = [];
                    floatipsSrv.getFloatipsTableData().then(function(res) {
                        if (res && res.data) {
                            floatingIpList = res.data;
                        }
                    }).then(function() {
                        instancesSrv.getServerNetwork(editData.uid).then(function(result) {
                            scope.networkList = [];
                            if (result && result.data && result.data.length) {
                                result.data.map(function(item) {
                                    // let ip_address=item.fixed_ips.filter(function(ip){
                                    //     return ip.ip_address.indexOf(":")<0;
                                    // });
                                    item.fixed_ips.forEach(ii=>{
                                        scope.networkList.push({
                                            id: item.port_id,
                                            net_sub_name: ii.ip_address,
                                            displayName: ii.ip_address,
                                            mac_addr:item.mac_addr
                                        });
                                    })
                                });
                                scope.network.selected = scope.networkList[0];
                            }
                        });
                    });
                    scope.confirm = function(upNet, netForm) {
                        if (netForm.netName.$valid) {
                            var postData = {
                                portid: upNet.id
                            };
                            // editData.fixedIps.map(function(item, index) {
                            //     if (item == upNet.net_sub_name) {
                            //         self.rmfip = editData.floatingIps[index];
                            //     }
                            // });
                            floatingIpList.forEach(item=>{
                                if(item.fixedIP == upNet.net_sub_name){
                                    self.rmfip = item.ip;
                                }
                            });
                            modal_os_net.close();
                            instancesSrv.removeNetwork(editData.uid, postData).then(function() {
                                //self.removefixedIp = upNet.net_sub_name;
                                self.removefixedIp = upNet;
                                self.removefixedIp_uid = editData.uid;
                                // $rootScope.$broadcast("serverSocket", eventMata);
                            });
                        } else {
                            scope.submitInValid = true;
                        }
                    };
                    break;
                case "update":
                    scope.netTitle = $translate.instant("aws.instances.modifyFixedIp");
                    scope.ipLabel = $translate.instant("aws.instances.addinstances.modifyAs");
                    scope.modifyFixdIp = true;
                    scope = commonFuncSrv.settingIpFunc(scope,"network","netForm");
                    scope.setCheckValueFunc = function(){
                        let net = scope.network.selected;
                        let startIp = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].start;
                        let endIp = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].end;
                        scope.checkValue(startIp,endIp);
                    };
                    var fixedIP_floatingIp = {};
                    instancesSrv.osInterfaceFips(editData.uid).then(function(result) {
                        if (result && result.data) {
                            _.each(result.data, function(item) {
                                fixedIP_floatingIp[(item.name.split("_")[0]).toString()] = item.name.split("_")[1];
                            })
                        }
                    });
                    // var permission = localStorage.permission;
                    // if (permission == "enterprise") {
                    //     instancesSrv.osInterfaceFips(editData.uid).then(function(result) {
                    //         if (result && result.data) {
                    //             _.each(result.data, function(item) {
                    //                 fixedIP_floatingIp[(item.name.split("_")[0]).toString()] = item.name.split("_")[1];
                    //             })
                    //         }
                    //     });
                    // }

                    // var getContinueNum = function(min,max){
                    //     let numlist = [];
                    //     for(let i = min ; i <= max ; i++){
                    //         numlist.push(i);
                    //     }
                    //     return numlist;
                    // };

                    // var getIpPools = function(allocationPools){
                    //     let pools = {};
                    //     _.each(allocationPools,function(item,i){
                    //         pools[i] = getContinueNum(_IP.toLong(item.start),_IP.toLong(item.end));
                            
                    //     });
                    //     return pools; //返回IP pool
                    // };
                     
                    var getIpPoolsZone = function(allocationPools) {
                        let pools = {};
                        _.each(allocationPools, function(item, i) {
                            pools[i] = [_IP.toLong(item.start), _IP.toLong(item.end)];
                        });
                        return pools; //返回所有IP池的IP范围
                    };

                    instancesSrv.getServerNetwork(editData.uid).then(function(res) {
                        if (res && res.data) {
                            return res.data;
                        }
                    }).then(function(osinterface) {
                        instancesSrv.getProjectNetwork().then(function(result) {
                            if (result && result.data && result.data.length) {
                                var tmpList = []
                                _.each(osinterface, function(insNet) {
                                    _.each(result.data, function(item) {
                                        if (item.subnets.length && insNet.net_id && insNet.net_id == item.id) { //没有绑定子网的交换机创建云主机时不能使用
                                            item = angular.copy(item);
                                            //item.fixed_ips = insNet.fixed_ips;
                                            item.fixed_ips=angular.copy(insNet.fixed_ips).filter(function(fixed_ip){
                                                return fixed_ip.ip_address.indexOf(":")<0;
                                            });
                                            item.net_sub_name = item.fixed_ips[0].ip_address;
                                            item.displayName = item.fixed_ips[0].ip_address;
                                            item.port_id = insNet.port_id;
                                            //判断该私网IP在哪一个子网中
                                            for(let i=0;i<item.subnets.length;i++){
                                                if(item.fixed_ips[0].subnet_id == item.subnets[i].id){
                                                    item.sub_key = i;
                                                    break;
                                                }
                                            }
                                            //判断该私网IP在子网的哪一个IP地址池中
                                            // let pools = getIpPools(item.subnets[item.sub_key].allocationPools);
                                            // for(let key in pools){
                                            //     if(_.include(pools[key],_IP.toLong(item.fixed_ips[0].ip_address))){ //ip 地址池是不可能交叉重叠的，所以一个IP只能是在一个IP地址池中
                                            //         item.allocationPool_key = key;
                                            //         break;
                                            //     }
                                            // }
                                            let pools = getIpPoolsZone(item.subnets[item.sub_key].allocationPools);
                                            let fixedIpAddr = _IP.toLong(item.fixed_ips[0].ip_address);
                                            for (let key in pools) {
                                                if (fixedIpAddr >= pools[key][0] && fixedIpAddr <= pools[key][1]) {
                                                    item.allocationPool_key = key;
                                                    break;
                                                }
                                            }
                                            tmpList.push(item);
                                        }
                                    })
                                });
                                scope.networkList = tmpList;
                                scope.network.selected = scope.networkList[0];
                                chkPortPolicyFun();
                                let net = scope.network.selected;
                                let startIp_list = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].start.split(".");
                                let endIp_list = net.subnets[net.sub_key].allocationPools[net.allocationPool_key].end.split(".");
                                scope.compareIpFun(startIp_list,endIp_list);
                            }
                        });
                    });
                    scope.confirm = function(formdata, form) {
                        scope.setCheckValueFunc();
                        if (form.$valid) {
                            scope.validForm = true;
                            let postData = {
                                "port_id": formdata.port_id,
                                "floating_ip": fixedIP_floatingIp[formdata.net_sub_name] ? fixedIP_floatingIp[formdata.net_sub_name] : "" //获取内网IP所关联的外网IP
                            };
                            let ip_address = scope.network.init_cidr.ip_0 + "." + scope.network.init_cidr.ip_1 + "." + scope.network.init_cidr.ip_2 + "." + scope.network.init_cidr.ip_3;
                            postData.ip_address = ip_address;

                            let existedIps = [];
                            networksSrv.getNetworksDetail(scope.network.selected.id).then(function(res) {
                                if (res && res.data) {
                                    _.each(res.data, function(item) {
                                        _.each(item.subnetIps,function(sub){
                                            existedIps.push(sub.ip_address);
                                        })
                                    })
                                    if (!_.include(existedIps, ip_address)) {
                                        instancesSrv.modifyInterface(postData).then(function(res) {
                                            if (res && !res.data) {
                                                self.updateFixedIp = true;
                                                self.updateFixedIp_uid = editData.uid;
                                                self.updateFixedIp_data = {
                                                    "newIp": ip_address,
                                                    "oldIp": formdata.fixed_ips[0].ip_address
                                                };
                                                // $rootScope.$broadcast("serverSocket", eventMata);
                                                //getInstance();
                                            }
                                        });
                                        modal_os_net.close();
                                    } else {
                                        scope.repeatIp = true;
                                        scope.validForm = false;
                                    }
                                }
                            });
                        } else {
                            scope.submitInValid = true;
                            scope.validForm = false;
                        }
                    };
            }
        };

        //带宽调整
        self.modifyBandwidth = function(editData) {
            var scope = self.$new();
            var modifyBandwidthModal = $uibModal.open({
                animation: true,
                templateUrl: "modifyBandwidthModal.html",
                scope: scope
            });
            modifyBandwidthModal.rendered.then(function(){
                scope.submitInValid = false;
                scope.network = {
                    limit:false
                };
                scope.networkList = [];
                if(editData.tags && editData.tags.length){
                    _.each(editData.tags,function(tag){
                        if(tag.indexOf("qos_limit")>-1){
                            let networkBandwidth_Mbps = Number(tag.split(":")[1])/128;
                            scope.network.networkBandwidth =Math.ceil(networkBandwidth_Mbps) == networkBandwidth_Mbps?networkBandwidth_Mbps:networkBandwidth_Mbps.toFixed(2);
                        }
                    })
                }
                //获取云主机网卡
                instancesSrv.getServerNetwork(editData.uid).then(function(result) {
                    if (result && result.data && result.data.length) {
                        result.data.map(function(item) {
                            scope.networkList.push({
                                id: item.port_id,
                                displayName :item.fixed_ips[0].ip_address,
                                mac_addr:item.mac_addr
                            });
                        });
                        scope.network.selected = scope.networkList[0];
                        //获取网卡带宽信息
                        instancesSrv.getNetcardBandwidth(editData.uid).then(function(res){
                            if(res && res.data){
                                scope.NICs_bandwidth = res.data;
                                for(let key in scope.NICs_bandwidth){
                                    if(key.indexOf(scope.network.selected.mac_addr) > -1){
                                        scope.network.networkBandwidth = Number(scope.NICs_bandwidth[key])/128;
                                    }
                                }
                                if(scope.network.networkBandwidth == 0 || !scope.network.networkBandwidth){
                                    scope.network.limit = true;  //无限制
                                    scope.network.networkBandwidth = "";
                                }else{
                                    scope.network.limit = false;
                                }
                            }
                        })
                    }
                });
                
                scope.changeNet = function(net,modifyBandwidthForm){
                    scope.modifyBandwidthForm = modifyBandwidthForm;
                    scope.submitInValid = false;
                    scope.network.limit = true;  //无限制
                    scope.network.networkBandwidth = "";
                    scope.modifyBandwidthForm.bandwidth.$dirty = true;
                    scope.modifyBandwidthForm.bandwidth.$valid = false;
                    for(let key in scope.NICs_bandwidth){
                        if(key.indexOf(net.mac_addr) > -1){
                            scope.network.networkBandwidth = Number(scope.NICs_bandwidth[key])/128;
                            scope.modifyBandwidthForm.bandwidth.$dirty = false;
                            scope.modifyBandwidthForm.bandwidth.$valid = true;
                            break;
                        }else{
                            scope.network.networkBandwidth = "";
                        }
                    }
                    if(scope.network.networkBandwidth == 0 || !scope.network.networkBandwidth){
                        scope.network.limit = true;  //无限制
                        scope.network.networkBandwidth = "";
                    }else{
                        scope.network.limit = false;
                    }
                };
                
                scope.cfmBandwidth = function(modifyBandwidthForm,limitType){
                    if(modifyBandwidthForm.$valid || (scope.network.networkBandwidth && !scope.modifyBandwidthForm.bandwidth.$dirty) || scope.network.limit ){
                        if(scope.network.limit){ //无限制
                            scope.network.networkBandwidth = 0;
                        }
                        let params = {
                            serverId:editData.uid,
                            serverName:editData.name,
                            data:{
                              "mac_addr":scope.network.selected.mac_addr,
                              "inbound_average": (Number(scope.network.networkBandwidth)*128).toString(),
                              "outbound_average": (Number(scope.network.networkBandwidth)*128).toString()
                            }
                        };
                        instancesSrv.editNICBandwidth(params).then(function(res){
                            getInstance();
                        });
                        modifyBandwidthModal.close();
                    }else{

                        scope.submitInValid = true;
                    }
                };
            });
        };

        //绑定公网IP和解除公网IP绑定
        self.bindFloatingIp = function(type, editData) {
            self.bindFloatingIpModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/update-os-floatingip.html",
                controller: "bindUnbindFloatingIpCtrl",
                resolve: {
                    context: function(){
                        return self
                    },
                    type: function() {
                        return type
                    },
                    editData: function() {
                        return editData
                    },
                    refresh: function(){
                        return getInstance
                    }
                }
            })
        };
        //修改mac地址
        self.changeMac = function(editData) {
            if (!self.change_Mac) {
                var scope = $rootScope.$new();
                var modal_os_mac = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "update-os-macAddr.html",
                    scope: scope
                });
                scope.osPort = {};
                scope.submitInValid = false;
                instancesSrv.getOsNet(editData.uid).then(function(result) {
                    scope.osPortList = [];
                    if(result && result.data && angular.isArray(result.data)){
                        result.data.map(function(item) {
                            scope.osPortList.push({
                                id: item.port_id,
                                ip: item.fixed_ips[0].ip_address,
                                mac_addr: item.mac_addr
                            });
                        });
                        scope.osPort.selected = scope.osPortList[0];
                        scope.osPort.mac_address = angular.copy(scope.osPort.selected.mac_addr);
                    }
                    if(editData.floatingIps.length >0){
                        instancesSrv.osInterfaceFips(editData.uid).then(function(result) {
                            if(result && result.data && angular.isArray(result.data)){
                                scope.osNetFipList = result.data;
                            }
                        });
                    }
                    // if(localStorage.permission == "enterprise" && editData.floatingIps.length >0){
                    //     instancesSrv.osInterfaceFips(editData.uid).then(function(result) {
                    //         if(result && result.data && angular.isArray(result.data)){
                    //             scope.osNetFipList = result.data;
                    //         }
                    //     });
                    // }
                });

                scope.changeFixedIp = function(data){
                    scope.osPort.mac_address = data.mac_addr;
                };

                scope.confirm = function(osPort, fieldForm) {
                    if (fieldForm.$valid) {
                        var postData = {
                            "portId": osPort.selected.id,
                            "mac_address": osPort.mac_address
                        };
                        if(scope.osNetFipList){
                            scope.osNetFipList.map(item =>{
                                if(item.portId == osPort.selected.id){
                                    postData.floatingIp_id = item.id;
                                }
                            })
                        }
                        // if(scope.osNetFipList && localStorage.permission == "enterprise"){
                        //     scope.osNetFipList.map(item =>{
                        //         if(item.portId == osPort.selected.id){
                        //             postData.floatingIp_id = item.id;
                        //         }
                        //     })
                        // }
                        instancesSrv.os_mac(editData.uid, postData);
                        modal_os_mac.close();
                    } else {
                        scope.submitInValid = true;
                    }
                };
            }

        };

        //同一块云硬盘云主机不能挂载两次
        function filterVolume(data,obj){
            return data.instance.some(item=>{
                return item==obj.name 
            });
        }
        
        //加载硬盘
        self.loadingDisk = function(editData) {
            if(self.loading_Disk || self.vmdiskIdget)return;
            var scope = $rootScope.$new();
            var modal_os_loadindDisk = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "update-load-disk.html",
                controller:"loadingDiskCtrl",
                resolve:{
                    context:self,
                    editData:editData
                },
                scope: scope
            });
        };
        function checkScheduleJob(scope,data,volume_id,type){ //scope作用域，data云主机id数组，volume_id云硬盘id，type操作类型
            scope.show_del_job_tip = false;
            scope.getJobs = false;
            scope.jobIds =[];
            var checkData = {
                serverIds:data.uid
            }
            if(type == "volume"){
                checkData.volumeId = volume_id;
                checkData.serverIds = [data.uid];
            }
            instancesSrv.checkSheduleJob(checkData).then(function(result){
                if(result && result.data){
                    scope.jobIds = result.data;
                    scope.getJobs = true;
                    scope.show_del_job_tip = Boolean(result.data.length);
                    if(type == "instance-soft-delete" || type == "instance-force-delete"){
                        emitDel(scope.show_del_job_tip,result.data,type)
                    }
                }
                
            })
        }
        

        function isVolumeHasBackupJob(volumeId,scope,editData){
            let volumeIdArr=[volumeId];
            let insArr=[editData.uid];
            let params={
                'volumeId':volumeIdArr,
                'instanceId':insArr
            }
            instancesSrv.checkServerHasBackupJob(params).then(function(res){
                if(res&&res.data&&angular.isArray(res.data)){
                    for(var i=0;i<res.data.length;i++){
                        //云主机有备份任务存在，请先解绑备份任务，再进行删除操作
                        if(volumeId==res.data[i].volumeId){
                           scope.volumeHasBackupJob=true; 
                           break;
                        }
                    } 
                }
            });
        }
        //卸载硬盘
        self.detachDisk = function(editData,diskInfo) {
            var scope = $rootScope.$new();
            scope.volumeHasBackupJob=false;
            var modal_os_detachDisk = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "update-detach-disk.html",
                scope: scope
            });
            scope.submitInValid = false;
            scope.disk = {};
            scope.changeVmDisk = function(volume){
                scope.volumeHasBackupJob=false;
                //判断该云硬盘下是否有定时快照
                checkScheduleJob(scope,editData,volume,"volume")
                //判断该云硬盘下面是否具有定时备份任务
                isVolumeHasBackupJob(volume,scope,editData);
            }
             //只能卸载非/dev/vda且bootable为true的盘
            scope.attachDiskList = diskInfo.filter(item => !(item.root_device == "true"&& item.bootable == "true"));
            scope.attachDiskList = scope.attachDiskList.filter(item => !(item.diskFormat == "iso") && item.status == "in-use");
            scope.disk.volumeId = scope.attachDiskList[0].id;
            checkScheduleJob(scope,editData,scope.disk.volumeId,"volume")
            isVolumeHasBackupJob(scope.disk.volumeId,scope,editData);
            scope.confirm = function(disk, diskForm) {
                if (diskForm.$valid) {
                    var postData = {
                        serverId: editData.uid,
                        volumeId: scope.disk.volumeId
                    };
                    modal_os_detachDisk.close();
                    instancesSrv.os_detach_disk(postData).then(function(){
                        self.detachDiskUid = editData.uid;
                    });
                    if(scope.jobIds.length){
                        var post = {
                            ids:scope.jobIds,
                            volumeId:scope.disk.volumeId
                        }
                        regularSnapSrv.updateTaskBack(post).then(function(){
                           
                        })
                    }
                    
                } else {
                    scope.submitInValid = true;
                }
            };
        };

        //卷迁移
        self.stockTransfer = function(editData){
            if(self.canStockTransfer || self.vmdiskIdget) return;
            self.stockTransferModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/stock-transfer.html",
                controller: "stockTransferCtrl",
                resolve: {
                    context:self,
                    editData:editData,
                    refresh: function(){
                        return getInstance
                    }
                }
            })
        }

        // 磁盘QoS
        self.volumesQoS = function(editData){
            self.volumesQoSModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/volumes-qoS.html",
                controller: "volumesQoSCtrl",
                resolve: {
                    context: function() {
                        return self
                    },
                    editData: function() {
                        return editData
                    },
                    refresh: function(){
                        return getInstance
                    }
                }
            })
        }

        //指定高可用
        self.settingHaFn = function(editData) {
            if(self.settingHaEnable){
                return;
            }
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/setting-ha.html",
                controller:"settingHaCtrl",
                resolve:{
                    context:self,
                    editData:editData,
                    refresh:function(){
                        return getInstance;
                    }
                }
            });
        };
        //打开控制台
        self.openConsole = function(editData) {
            /*var insvnc = */
            window.open(self.consoleUrl, editData.uid, "height=1000,width=1100,top=20,left=20,toolbar=yes,resizable=yes,menubar=no,scroll");

             // localStorage.setItem("consoleUrl",self.consoleUrl)
            // window.open("/#/console", "", "height=1000,width=2000,top=20,left=20,toolbar=yes,resizable=yes,menubar=no,scroll");

            
            //insvnc.document.getElementsByTagName("body")[0].innerHTML = "<iframe src = "+ self.consoleUrl +" style='width:100% ;height:100%;'></iframe><style>*{margin:0;padding:0;}</style>";
        };
        self.noCinderModal = function(dis){
            if(dis) return;
            var modal_os = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/volumes/tmpl/addVolumeTip.html",
            });
        }

        self.noBackupsModal = function(dis){
            if(dis) return;
            var modal_os = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/volumes/tmpl/addBackupTip.html",
            });
        }

        //制作镜像
        self.mkImg = function(editData) {
            // if(self.notFpgaCanMakeImg){
            //     return;
            // }
            
            if(self.notFpgaCanMakeImg||self.canBak || self.volumeStatusDis || self.vmdiskIdget || self.storageLimitImage || self.limitImageSize||editData.startStyle =='Local') return 
            
            //let tempUrl = editData.startStyle =='Local' ? "js/cvm/volumes/tmpl/addVolumeTip.html":"js/cvm/instances/tmpl/mkimg.html";
            let tempUrl = "js/cvm/instances/tmpl/mkimg.html";

            $uibModal.open({
                animation: true,
                templateUrl: tempUrl,
                controller: "instanMkImgCtrl",
                resolve: {
                    refresh: function() {
                        return getInstance;
                    },
                    editData:function(){
                        return editData
                    },
                    context:function(){
                        return self;
                    }
                }
            });
            return;


            let templateUrl = (editData.startStyle =='Local' || (editData.startStyle =='EBS' && localStorage.cinderService)) ? "mkImg.html":"js/cvm/volumes/tmpl/addVolumeTip.html";
            if(self.canBak || self.volumeStatusDis || self.vmdiskIdget || self.storageLimitImage || self.limitImageSize) return 
            var scope = $rootScope.$new();
            var modal_os_mk = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: templateUrl,
                scope: scope
            });
            scope.submitInValid = false;
            scope.img = {};
            if(editData.startStyle=="Local"){
                scope.img.use_snapshot=true;
            }else{
                scope.img.use_snapshot=false;
            }
            scope.confirm = function(img, imgform) {

                if (imgform.$valid) {
                     modal_os_mk.close();
                     if(img.use_snapshot){
                        //使用快照方式制作镜像
                        instancesSrv.mkImg(editData.uid, img).then(function() {
                            localStorage.mkImg_hand = true;
                        });
                    }else{
                        //标准制作镜像方式
                        self.vmdiskInfo.forEach((x)=>{
                            if(x.bootable=='true'&& x.root_device == "true" && x.diskFormat!="iso"){
                                scope.img.volumeId=x.id;
                                img.volumeId=x.id
                            }
                        })
                        scope.img.instanceId=editData.uid;
                        instancesSrv.makeImage(img).then(function(result) {
                            self.checkboxes.items={};
                        });
                    }
                } else {
                    scope.submitInValid = true;
                }
            };
        };
        //备份
        self.osbackup = function(editData) {
            var scope = $rootScope.$new();
            var modal_os_backup = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "os-backup.html",
                scope: scope
            });
            scope.back = "";
            scope.submitInValid = false;
            //scope.cycleList = [{ id: "daily", name: $translate.instant("aws.instances.backups.day") }, { id: "weekly", name: $translate.instant("aws.instances.backups.week") }];
            scope.confirm = function(back, osbackForm) {
                back.backupType = "daily";
                back.rotation = 10;
                if (osbackForm.$valid) {
                    modal_os_backup.close();
                    instancesSrv.os_backup(editData.uid, back).then(function() {

                    });
                } else {
                    scope.submitInValid = true;
                }

            };
        };
        //热迁移
        self.osMigrate = function(editData) {
            //let templateUrl = editData.startStyle == 'Local' ? "localFunTip.html":"os-migrate.html";
            if(self.migrate || self.volumeStatusDis || self.vmdiskIdget || self.storageLimitMigrate) return;
            //var scope = $rootScope.$new();
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/os-migrate.html",
                controller:"osMigrateCtrl",
                resolve:{
                    refresh:function(){
                        return getInstance;
                    },
                    editDataCopy:function(){
                        return self.editDataCopy;
                    }
                }
            });
           
        };
        //冷迁移
        self.coldMigrate = function(editData){
            //let templateUrl = editData.startStyle == 'Local' ? "js/cvm/instances/tmpl/coldMigrate.html":"localFunTip.html";
            let coldMigrateCtrl ="coldMigrateCtrl";  
            if(!self.coldMigrateBtn && !self.volumeStatusDis && !self.vmdiskIdget && !self.storageLimitColdMig){
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "js/cvm/instances/tmpl/coldMigrate.html",
                    controller: coldMigrateCtrl,
                    resolve: {
                        editData: function(){
                            return editData
                        }
                    }

                });
            }
        }
        //调整配置
        self.osUpgrade = function(editData) {
            if (!self.upgrade) {
                self.osUpgradeModule = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "js/cvm/instances/tmpl/os-upgrade.html",
                    controller: "osUpgradeCtrl",
                    resolve: {
                        context: function(){
                            return self
                        },
                        editData:function(){
                            return editData
                        },
                        refresh: function(){
                            return getInstance
                        },
                        getProused: function(){
                            return getProused
                        },
                        getDomused: function(){
                            return getDomused
                        }
                    }
                })
            }
        };
        //热升级
        self.osHotgrade = function(edit) {
            if(self.unhotgrade || self.oshotgrade || self.vmdiskIdget) return
            self.osHotgradeModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/os-hotgrade.html",
                controller: "osHotgradeCtrl",
                resolve: {
                    context: function(){
                        return self
                    },
                    edit: function(){
                        return edit
                    },
                    refresh: function(){
                        return getInstance
                    },
                    getProused: function(){
                        return getProused
                    },
                    getDomused: function(){
                        return getDomused
                    }
                }
            })
        };
        //创建快照
        self.osSnapshot = function() {
            var scope = $rootScope.$new();
            var modal_os_backup = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "os-snapshot.html",
                scope: scope
            });
            instancesSrv.getServerDetail(self.editData.uid).then(function(result) {
                if (result && result.data) {
                    result.data.diskInfo.map(item => {
                        if (item.root_device == "true") {
                            scope.rootVolId = item.id;
                        }
                    });
                }
            });
            scope.snapshot = {};
            scope.confirm = function() {
                var snapData = {
                    volumeId: scope.rootVolId,
                    name: scope.snapshot.name
                };
                modal_os_backup.close();
                instancesSrv.os_snap(snapData).then(function() {

                });
            };
        };
        //调用api获取所有绑定给云主机的port
        var getServerNet = function(scope,editData){
            instancesSrv.getOsNet(editData.uid).then(function(result) {
                scope.osNetList = [];
                if ( result && result.data && result.data.length) {
                    result.data.map(function(item) {
                        scope.osNetList.push({
                            id: item.port_id,
                            name: editData.name + ":" + item.fixed_ips[0].ip_address,
                            net_name:item.net_name
                        });
                    });
                    scope.sec.port_id  = scope.osNetList[0];
                    self.getPortDetail(scope,scope.sec.port_id.id,editData);
                }
            });
        };
        //获取某一个port的详情
        self.getPortDetail = function(scope,port_id,editData){
            var postData = {
                id:port_id
            };
            var sec;
            instancesSrv.getPortDetail(postData).then(function(result){
                if(result && result.data && result.data.security_groups){
                    sec = result.data.security_groups[0];
                    getServerSecGroup(scope,sec,editData)
                }
            })
        }
        //调用api获取所有的防火墙，并将已经选中的防火墙过滤出来
        var getServerSecGroup = function(scope,used,editData,flag){
            instancesSrv.listServerSecGroup(editData.uid).then(function(result) {
                if(result && result.data && angular.isArray(result.data)){
                    scope.iptablesList = result.data;
                    scope.netWorkCard.name = result.data[0]
                    _.forEach(scope.iptablesList, function(item) {
                        if (item.id == used ) {
                            scope.sec.iptables = item.id;
                        }
                        if(!used && item.name == "default"){
                            scope.network.secSelected = item;
                        }
                    });
                    if(flag){
                        if(scope.network.selected&&scope.network.selected.net_sub_name.indexOf('PaaS网络')>-1&&scope.network.selected.net_sub_name.indexOf('PaaS子网'>-1)){
                            scope.isPaasNet = true;
                            scope.network.secSelected = {name:'PaaS security pair'}
                        }else{
                            scope.isPaasNet = false;
                        }
                    }
                    if(scope.sec&&scope.sec.port_id&&scope.sec.port_id.net_name=='PaaS网络'){
                        scope.cannot_Confirm = true;
                    }else{
                        scope.cannot_Confirm = false;
                    }
                }
                
            });
        };
        
        function avoidDoubleClick(scope,bol){
            scope.cannot_Confirm = bol;
        }

        //加载防火墙
        self.loadIptables = function(editData) {
            var scope = $rootScope.$new();
            var modal_os_iptables = $uibModal.open({
                animation: $scope.animationsEnabled,
                backdrop: "static",
                templateUrl: "load-os-iptables.html",
                scope: scope
            });
            scope.submitInValid = false;
            scope.sec = {};
            scope.netWorkCard = {};
            avoidDoubleClick(scope,true)
            getServerNet(scope,editData)
            scope.changePort = function(port){
                scope.iptablesList = [];
                avoidDoubleClick(scope,true)
                self.getPortDetail(scope,port.id,editData);
                if(port.net_name&&port.net_name=='PaaS网络'){
                    scope.cannot_Confirm = true;
                }else{
                    scope.cannot_Confirm = false;
                }
            }
            scope.confirmSec = function(iptables,feild){
                scope.cannot_Confirm = true;
                var newSecName = "";
                if(feild.$valid){
                     avoidDoubleClick(scope,true)
                     var postData = {
                         id:scope.sec.port_id.id,
                         secGroup:[scope.sec.iptables]
                     }
                     instancesSrv.updatePortSecurity(postData).then(function(result){
                     })
                     modal_os_iptables.close()
                }else{
                   scope.submitInValid = true; 
                }
                scope.iptablesList.map(item => {
                    if (item.id == iptables) {
                        newSecName = item.name;
                    }
                });
                if (scope.initIptables != iptables) {
                    var postData = {
                        oldSecGroup: scope.initIptables,
                        newSecGroup: newSecName
                    };
                    //调用api,iptables为最新选中的
                    instancesSrv.updateServerSecGroup(editData.uid, postData).then(function() {
                        getInstance();
                    });
                }
            };
        };

        // 网卡安全配置
        self.newNetworkCardConfig = function(editData) {
            self.networkCardConfigModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/networkCardSecConfig.html",
                controller: "networkCardSecConfigCtrl",
                resolve: {
                    editData: function(){
                        return editData
                    },
                    refresh: function() {
                        return getInstance
                    }
                }
            });
        }

        //添加告警
        self.addAlarm = function(data) {
            self.addAlarmModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/alarmSetting.html",
                controller: "addAlarmCtrl",
                resolve: {
                    context: function(){
                        return self
                    },
                    data: function(){
                        return data
                    }
                }
            });
        };

        //修改密码
        self.editPsw = function(data){
            if(self.canEditPsw){
                return ;
            }
            self.editPswModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/edit-psw.html",
                controller: "editPswCtrl",
                resolve: {
                    context: function(){
                        return self
                    },
                    data: function() {
                        return data
                    }
                }
            });
        }

        // 透传设备
        self.ttEquipment = function(data){
            if(!self.canttEquiment){
                var instan =  $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "tt-equipment.html",
                    controller: "ttEquipmentCtrl",
                    resolve: {
                        refresh:function(){
                            return getInstance;
                        },
                        context:function(){
                            return self;
                        },
                        editData:function(){
                            return data;
                        }
                    }
                });
            }
        }

        //重建云主机
         self.rebuild = function(data,vmdiskInfo) {
            if(self.canRebuild || self.vmdiskIdget){
                return ;
            }
            var storageFunc = vmFuncSrv.storageFunc(self,"","");
            var inPoolsize = true; 
            var msg;
            vmdiskInfo.map(item =>{
                if(item.bootable == "true" && item.root_device == "true" && item.diskFormat !="iso"){
                    storageFunc.poolInfo(item);
                    inPoolsize = storageFunc.checkVolumFun(data.imageSize);
                }
            })
            self.rebuildId = data.uid ;
            if(inPoolsize){
                msg = "<span class='mailServer-tips'>" + "<i class='icon-aw-prompt2'></i>" + $translate.instant("aws.instances.tip.sure_rebuild") + "</span>"
            }else{
                msg = "<span class='mailServer-tips'>" +  $translate.instant("aws.instances.tip.poolSize_rebuild") + "</span>"
            }
            var content = {
                target: "rebuild",
                msg: msg,
                type: "warning",
                showDelBtn:false,
                btnType: "btn-warning",
                notDel:!inPoolsize
            };
        
            self.$emit("delete", content);
        };
        self.$on("rebuild", function() {
            instancesSrv.getServerDetail(self.rebuildId).then(function(data){
                if(data && data.data){
                    var datadevce_data = data.data.diskInfo.filter(volume => (volume.bootable==="true" && volume.root_device==="true") );
                    if(datadevce_data && datadevce_data.length ){
                        var volumeId = [datadevce_data[0].id,];
                        instancesSrv. getVolumeSnapshots({volumeid:volumeId}).then(function(data){
                            if(data.data &&data.data.length){
                                var rebuildTipModal = $uibModal.open({
                                        animation: $scope.animationsEnabled,
                                        backdrop: "static",
                                        templateUrl: "rebuild-tip.html",
                                        scope: self   
                                     });
                                
                            }else{
                                instancesSrv.rebuildHost(self.rebuildId)
                            }
                        })
                    }else{
                        instancesSrv.rebuildHost(self.rebuildId)
                    }
                    
                  
                   
                }
            })
            // instancesSrv.rebuildHost(self.rebuildId).then(function(data){
            // })
        });
        // self.$on("rebuild", function() {
        //     instancesSrv.rebuildHost(self.rebuildId).then(function(data){
        //     })
        // });
        function initAlarmForm() {
            return {
                name: "",
                labelList: "",
                alarmtemps: [],
                contactlists: [],
                normalToAlarm: "",
                alarmToNormal: "",
                alarmAction: "email",
                enabled: true
            };
        }

        //光盘管理
        self.manageCD = function(data) {
            if(self.CDdisabled) return;
            $uibModal.open({
                animation: $scope.animationsEnabled,
                backdrop: "static",
                templateUrl: "manageCD.html",
                controller: "manageCDController",
                resolve: {
                    editData: function() {
                        return data;
                    },
                    context: function() {
                        return self;
                    }
                }
            });
        };

        //异常重启
        self.errorStart = function(data){
            if(self.errorStartDisabled) return;
            $uibModal.open({
                animation: $scope.animationsEnabled,
                backdrop: "static",
                templateUrl: "js/cvm/instances/tmpl/error-start.html",
                controller: "errorStartCtrl",
                resolve: {
                    editData: function() {
                        return data;
                    },
                    context: function() {
                        return self;
                    },
                    refresh: function() {
                        return getInstance
                    }
                }
            });
        }

        //云主机救援
        self.vm_evacuate = function() {
            var content = {
                target: "evacuate",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.evacuate") + "</span>",
                type: "info",
                btnType: "btn-primary"
            };
            self.$emit("delete", content);
        };
        self.$on("evacuate", function() {
            instancesSrv.evacuateServer(self.chkSome().id).then(function() {
                self.checkboxes.items = {};
            });
        });                             
        //挂载ISO
        self.mountISO = function() {
            var scope = $rootScope.$new();
            var modal_os_mount = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "os-mountISO.html",
                scope: scope
            });
            scope.mount = {};
            instancesSrv.getImage().then(function(result) {
                if (result && result.data && result.data.length) {
                    scope.imageList = result.data
                    scope.mount.image = result.data[0];
                }
            });
            scope.confirm = function(field) {
                if (field.$valid) {
                    var mountData = {
                        imageId: scope.mount.image.imageUid,
                        imageName: scope.mount.image.name,
                        size: scope.mount.size,
                        serverId: self.editData.uid
                    };
                    modal_os_mount.close();
                    instancesSrv.mountISO(mountData).then(function() {

                    });
                } else {
                    scope.submitInValid = true;
                }

            };
        };
        //卸载ISO
        self.unmountISO = function() {
            var scope = $rootScope.$new();
            var modal_os_unmount = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "os-unmountISO.html",
                scope: scope
            });
            instancesSrv.getServerDetail(self.editData.uid).then(function() {
                /*if (result && result.data) {
                }*/
            });
            scope.unmount = {};
            scope.confirm = function(field) {
                if (field.$valid) {
                    var postData = {
                        serverId: self.editData.uid,
                        volumeId: scope.disk.volumeId
                    };
                    modal_os_unmount.close();
                    instancesSrv.unmountISO(postData).then(function() {
                        self.detachDiskUid = self.editData.uid;
                    });
                } else {
                    scope.submitInValid = true;
                }

            };
        };
        //云硬盘备份
        self.volumeBackups = function(editData) {
            if(!self.canBackups) {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "js/cvm/instances/tmpl/volumeBackups.html",
                    controller: "volumeBackupsCtrl",
                    resolve: {
                        editData: function(){
                            return editData
                        }
                    }

                });
            }
            
        };
        //启动顺序管理
        self.bootMenu = function(editData){
            if(!self.canBootMenu){
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "js/cvm/instances/tmpl/bootMenu.html",
                    controller: "bootMenuCtrl",
                    resolve: {
                        editData: function(){
                            return editData
                        }
                    }

                });
            }
        }
        //getInstance();

        //云主机备份还原
        self.backupRestore = function(editData){
            if(self.canBackupRestore) return;
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/backupRestore.html",
                controller: "backupRestoreCtrl",
                resolve: {
                    editData: function(){
                        return editData
                    }
                }

            });
        }

        // 云主机克隆
        self.vmClone = function(editData) {
            if(self.canVmClone) return;
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/vmClone.html",
                controller: "vmCloneCtrl",
                resolve: {
                    editData: function(){
                        return editData
                    },
                    cloneDiskInfo: function(){
                        return self.vmdiskInfo
                    }
                }
            });
        }

        // 云主机加载本地磁盘
        self.loadingLocalDisk = function(editData) {
            if(self.canLoadingLocalDisk) return;
            self.loadingLocalDiskModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/loadingLocalDisk.html",
                controller: "loadingLocalDiskCtrl",
                resolve: {
                    editData: function(){
                        return editData
                    },
                    refresh: function() {
                        return getInstance
                    },
                    context: self
                }
            })
        }

        // 云主机卸载本地磁盘
        self.detachLocalDisk = function(editData) {
            if(self.canDetachLocalDisk) return;
            self.detachLocalDiskModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/detachLocalDisk.html",
                controller: "detachLocalDiskCtrl",
                resolve: {
                    editData: function(){
                        return editData
                    },
                    refresh: function() {
                        return getInstance
                    },
                    context: self
                }
            })
            
        }

        // 云主机搁置
        self.shelveServer = function() {
            if(self.shelveServerEnable) return;
            let content = {
                target: "shelveIns",
                msg: "<span>" + $translate.instant("aws.instances.tipMsg.shelveServer") + "</span>",
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit('delete',content)
        }
        self.$on("shelveIns",function() {
            let uidList = [];
            for (const key in self.checkboxes.items) {
                // {f5eded9f-4898-4d0a-a6eb-8b8563a582c6: true, 5f36c48e-5a15-4cca-ab3a-c3c8ce47b4d7: false}
                // 勾选的为true
                if(self.checkboxes.items[key]) {
                    uidList.push(key);
                }
            }
            let uids = uidList.join(',');
            let params = {
                serverIds: uids
            };
            instancesSrv.shelveServer(params).then(function() {
                self.checkboxes.items = {};
            });
        })

        // 云主机取消搁置
        self.unShelveServer = function() {
            if(self.unShelveServerEnable) return;
            let content = {
                target: "unShelveIns",
                msg: "<span>" + $translate.instant('aws.instances.tipMsg.unShelveServer') + "</span>",
                type: "warning",
                btnType: "btn-warning"
            }
            self.$emit('delete',content)
        }
        self.$on("unShelveIns",function(){
            let uid = Object.keys(self.checkboxes.items)[0];
            let params = {
                serverId: uid
            }
            instancesSrv.unShelveServer(params).then(function() {
                self.checkboxes.items = {};
            });
        })
    }])
    .controller("instancesModelCtrl", instancesModelCtrl)

    .controller("manageCDController", ["$scope", "$rootScope", "$uibModal", "$translate", "$timeout", "NgTableParams", "GLOBAL_CONFIG", "checkedSrv",
        "editData", "context", "instancesSrv", function($scope, $rootScope, $uibModal, $translate, $timeout, NgTableParams, GLOBAL_CONFIG, checkedSrv,
        editData, context, instancesSrv) {
        var self = $scope;
        if($rootScope.test){
            $rootScope.test();
            $rootScope.test = null;
        }
        self.delisDisabled = true;
        self.loadingCD = false;
        self.deleteCD = deleteCD;
        self.imageId = angular.copy(editData).uid;
        self.imgIdArr = [];
        self.canLoadCD=false;
        self.canLoadCDInfo="当前平台中没有可加载的ISO镜像";
        self.openManageCD = function() {
            if(self.canLoadCD){
                $uibModal.open({
                    animation: $scope.animationsEnabled,
                    backdrop: "static",
                    templateUrl: "infoCD.html",
                    controller: "infoCDController",
                    resolve: {
                        initCDTabData: function() {
                            return initCDTab;
                        },
                        imageId: function() {
                            return self.imageId;
                        },
                        imgIdArr: function() {
                            return self.imgIdArr;
                        },
                        showLoading: function() {
                            return showLoading;
                        },
                        context: function() {
                            return context;
                        },
                        manageContext:function(){
                            return self
                        }
                    }
                });
            }
        };
        initCDTab();

        function initCDTab() {
            self.loadingCD = false;
            self.canLoadCD=false;
            self.canLoadCDInfo="当前平台中没有可加载的ISO镜像";
            instancesSrv.getCDTableData(self.imageId).then(function(result) {
                result.data?self.loadData = true:"";
                if(result && result.data) {
                    self.CD_data = result.data;
                    self.CD_data.map(item => {
                        item.status = $translate.instant("aws.instances.cdStatus");
                    });
                    successFunc('tableCDParams');
                    self.imgIdArr = [];
                    _.forEach(result.data, function(item) {
                        self.imgIdArr.push(item.imageId);
                    });
                    //进来需要判断是否有可加载的光盘文件
                    var params = {
                        imageIds: self.imgIdArr.length?self.imgIdArr:""
                    }
                    instancesSrv.getISOList(params).then(function(result) {
                        if(result && result.data) {
                            self.deviceList = result.data;
                            if(self.deviceList.length>0){
                                self.canLoadCD=true;
                                self.canLoadCDInfo="";
                            }
                        }
                    });
                }
            });
        }

        function successFunc(tableType) {
            self.tableCDParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: self.CD_data });
            var tableId = "id";
            checkedSrv.checkDo(self, self.CD_data, tableId, tableType);
        }

        function showLoading(bool) {
            self.loadingCD = bool;
        }

        function deleteCD(checkedItems) {
            var content = {
                target: "delCD",
                msg: "<span>" + $translate.instant("aws.instances.cdConfrimISO") + "</span>",
                data: checkedItems
            };
            context.$emit("delete", content);
        };
        $rootScope.test = context.$on("delCD", function(e,data) {
            var volumeIdArr = [];
            _.forEach(data,function(item){
                volumeIdArr.push(item.id);
            });
            showLoading(true);
            instancesSrv.uninstallISO(self.imageId, volumeIdArr).then(function(result) {
                // $timeout(function() {
                //     initCDTab();
                // },2000)
                // 改为接收推送消息刷新列表
            })
        });
        self.$on("syncVloumeImageSuccess", function(e, data) {
            if(JSON.parse(data).eventType=='sync.vloume.image') {
                initCDTab();
            }
        });
    }])

    .controller("infoCDController", ["$scope", "$rootScope", "$uibModalInstance", "$timeout", "initCDTabData", "showLoading", "imageId", "imgIdArr", "instancesSrv","manageContext",
        "context", function($scope, $rootScope, $uibModalInstance, $timeout, initCDTabData, showLoading, imageId, imgIdArr, instancesSrv, context,manageContext) {
        var self = $scope;
        self.devinfo = { value : "" };
        if($rootScope.createIsoVolumeSuccess){
            $rootScope.createIsoVolumeSuccess();
            $rootScope.createIsoVolumeSuccess = null;
        }
        var params = {
            imageIds: imgIdArr.length?imgIdArr:""
        }
        instancesSrv.getISOList(params).then(function(result) {
            if(result && result.data) {
                self.deviceList = result.data;
            }
        });
        $rootScope.createIsoVolumeSuccess = context.$on("createIsoVolumeSuccess", function(e, data) {
            if(self.selectData && data.resourceName == self.selectData.name) {
                initCDTabData();
            }
        });
        self.devConfirm = function(data) {
            if(data) {
                showLoading(true);
                self.deviceList.forEach(function(item, index) {
                    if(item.id == data) {
                        self.selectData = self.deviceList[index];
                    }
                });
                var params = {
                    imageRef: self.selectData.id,
                    name: self.selectData.name,
                    size: self.selectData.vol_size,
                    serverId: imageId
                };
                //先将ISO镜像制作成卷, 制作成功后挂载ISO卷
                instancesSrv.makeVolume(params).then(function(result) {
                    if(result && result.status == "0") {
                        // $timeout(function() {
                        //     initCDTabData();
                        // },5000)
                        // 通过接收挂载成功的推送消息刷新列表
                    } else {
                        showLoading(false);
                    }
                });
            }
            $uibModalInstance.close();
        };
    }])
    .controller("ttEquipmentCtrl", function($scope, $uibModalInstance, instancesSrv,context, editData,refresh,modalCom,NgTableParams,TableCom,$translate,GLOBAL_CONFIG,$rootScope) {
        let self = $scope;
        let serverId = editData.uid;
        instancesSrv.equipmentScope=self;
        self.canLoadAndUnload={"canAdd":"","canUnload":""};
        // self.checkedItems = [];
        self.checkSecond = {
            checked: false,
            items: {}
        };
        self.editData = editData;
        context.haSwitchHostInfo = {};

        self.$watch(function() {
            return self.checkSecond.items;//监控checkbox
        }, function(val) {
            self.checkedItems = [];
            var arr=[];
            for(var i in self.checkSecond.items){
                arr.push(self.checkSecond.items[i])
            }
            if(val && arr.length>=0){
                for(var key in val){
                    if(val[key]){
                        self.ttEquipmentData.forEach(item=>{
                            if(item.id==key){
                                self.checkedItems.push(item);
                            }
                        })
                    }
                }
            }
            //只有关机和开机能查询可加载的透传设备列表
            let status = editData.status.toLowerCase();
            if(status == 'active' || status == 'shutoff') {
                if(self.checkedItems.length==1){ 
                    self.checkedOneId=self.checkedItems[0].id;
                    self.canLoadAndUnload.canAdd = true;
                    self.canLoadAndUnload.canUnload = true;
                }else if(self.checkedItems.length>1){
                    self.canLoadAndUnload.canAdd = true;
                    self.canLoadAndUnload.canUnload = false;
                }else if(self.checkedItems.length==0){          
                    self.canLoadAndUnload.canAdd = true;
                    self.canLoadAndUnload.canUnload = false;
                }
            }else{
                self.canLoadAndUnload.canAdd = false;
                self.canLoadAndUnload.canUnload = false;
            }
        },true);
        //关闭弹出层
        self.close = function(){
            context.checkboxes.items = {};
            $uibModalInstance.close();
        }
        // 获取已加载的数据
        self.hasTtEquipmentList=function(id){
            
            // instancesSrv.hasTtEquipmentList(serverId).then(function(res){
            //     if(res&&res.data&&res.data.length){
            //         self.ttEquipmentData = res.data
            //     }else{
            //         self.ttEquipmentData = []
            //     }
            // }).then(function(){
                 self.ttEquipmentData = []
                 TableCom.init(self,'ttEquipmentTable',[],'id',GLOBAL_CONFIG.PAGESIZE,'checkSecond');
                 instancesSrv.allHasTtEquipmentList(id || serverId).then(function(result){
                    if(result&&result.data&&result.data.length){
                        result.data.forEach(function(val){
                            self.ttEquipmentData.push(val)
                        })
                        self.ttEquipmentData.forEach(function(item,index){
                            item.uid = index+1
                        })
                    }else{
                        self.ttEquipmentData = []
                    }
                    self.checkSecond = { 
                        checked: false,//全选按钮
                        items: {}//所有当前页数据的checkbox选中状态
                    };                
                    TableCom.init(self,'ttEquipmentTable',self.ttEquipmentData,'id',GLOBAL_CONFIG.PAGESIZE,'checkSecond');
                    self.ttEquipmentTable.reload();
                })
                // self.ttEquipmentData.forEach(function(item,index){
                //     item.id = index+1
                // })
                
            // })
        }
        self.hasTtEquipmentList()

        // 加载
        self.addTtEquipment = function(data){
            var instan = modalCom.init(
                "add-tt-equipment.html",
                "addTtEquipmentCtrl",
                {
                    refreshInstanceData:function(){
                        return refresh;
                    },
                    refresh:function(){
                        return self.hasTtEquipmentList;
                    },
                    context:function(){
                        return self;
                    },
                    editData:function(){
                        return self.editData;
                    }})
        }
        function getInstance() {
            instancesSrv.getData().then(function(result) {
                self.globalSearchTerm = "";
                if (result && result.data) {
                    context.projectsData=angular.copy(result.data);
                }
                result ? self.loadData = true : "";
            });
        }
        getInstance();

        // 透传设备卸载
        self.unloadTtEquipment = function(data){
            var unloadInstanceModal = modalCom.init(
                "js/cvm/instances/tmpl/unloadTtEquipment.html",
                "unloadTtEquipmentCtrl",
            {
                contextINfo:function(){
                    return context;
                },
                refreshInstanceData:function(){
                    return refresh;
                },
                refresh:function(){
                    return self.hasTtEquipmentList;
                },
                context:function(){
                    return self;
                },
                editData:function(){
                    return self.editData;
                }
            })
        }

        // 卸载  废弃 by guozh 2022/1/20/17:52
        self.discardunloadTtEquipment = function() {
            var uninstallMsg="<span>" + $translate.instant("aws.instances.tipMsg.unloadTtEquipment") + "</span>";
            var content = {
                target: "unloadTtEquipment",
                msg: uninstallMsg,
                type: "warning",
                btnType: "btn-warning"
            };
            instancesSrv.equipmentScope.emitEquipmentFlag = false;
            //选中的透传设备的vm只有是关机状态才能卸载、加载
            for(var i=0;i<context.projectsData.length;i++){
                if(context.projectsData[i].uid==self.checkedItems[0].serverId){
                    if((context.projectsData[i].status!="shutoff"&&context.projectsData[i].status!="SHUTOFF"&&context.projectsData[i].status!="stopped")&&(self.checkedItems[0].deviceType=="FPGA"||self.checkedItems[0].deviceType=="GPU")){
                        content.msg = $translate.instant("aws.instances.tipMsg.fpgaOperate");
                        content.notDel = true;
                    }
                }
            }
            context.$emit("delete", content);
        };
        context.$on("unloadTtEquipment", function() { 
            self=instancesSrv.equipmentScope;
            if(!instancesSrv.equipmentScope.emitEquipmentFlag&&instancesSrv.equipmentScope.checkedItems.length>0){
                instancesSrv.equipmentScope.emitEquipmentFlag = true;
                self.checkedItems = instancesSrv.equipmentScope.checkedItems;
                if(self.checkedItems[0].deviceType == "USB"){
                    let id = self.checkedItems[0].serverId
                    let postData = {
                        usbPort:self.checkedItems[0].usbPort,
                        usbBus:self.checkedItems[0].usbBus,
                        addressBus:self.checkedItems[0].addressBus,
                        addressDevice:self.checkedItems[0].addressDevice,
                    }
                    instancesSrv.unloadTtEquipment(id,postData).then(function(res){
                        self.hasTtEquipmentList(id)
                    })
                }else if(self.checkedItems[0].deviceType == "GPU"){
                    let id = self.checkedItems[0].serverId;
                    let postData = {
                        bus:self.checkedItems[0].gpuId.split(":")[0],
                        slot:self.checkedItems[0].gpuId.split(":")[1].split(".")[0],
                        function:self.checkedItems[0].gpuId.split(":")[1].split(".")[1],
                        gpuId:self.checkedItems[0].gpuId
                    }
                    instancesSrv.unloadGpuTtEquipment(id,postData).then(function(res){
                        self.hasTtEquipmentList(id)
                    })
                }else if(self.checkedItems[0].deviceType == "FPGA"){
                    let id = self.checkedItems[0].serverId;
                    let postData = {
                        bus:self.checkedItems[0].gpuId.split(":")[0],
                        slot:self.checkedItems[0].gpuId.split(":")[1].split(".")[0],
                        function:self.checkedItems[0].gpuId.split(":")[1].split(".")[1],
                        gpuId:self.checkedItems[0].gpuId
                    }
                    instancesSrv.unloadFpgaTtEquipment(id,postData).then(function(res){
                        self.hasTtEquipmentList(id)
                    })
                }
            }
        });
    })
    .controller("unloadTtEquipmentCtrl",function($scope, $uibModalInstance, instancesSrv, editData ,refresh,context,$translate,refreshInstanceData,contextINfo,$timeout){
        var self = $scope;
        self.haSwitch = true;
        self.switchText = $translate.instant('aws.localDisk.open');
        self.checkedItems = instancesSrv.equipmentScope.checkedItems;
        self.haSwitchDisabled = false;
        self.instanceStatus = true;
      
        // 设置高可用
        function setHostBind() {
            let uid = editData.uid;
            let params = {
                hostBind: !self.haSwitch
            }
            instancesSrv.setHighAvailability(uid,params).then((res)=>{
                editData.hostBind = params.hostBind;
                context.haSwitchHostInfo = editData;
                refreshInstanceData();
            }).finally(()=>{})
        }
    
        // 处理USB提交的数据
        function usbPostData() {
            let postData = {
                usbPort:self.checkedItems[0].usbPort,
                usbBus:self.checkedItems[0].usbBus,
                addressBus:self.checkedItems[0].addressBus,
                addressDevice:self.checkedItems[0].addressDevice,
            }
            return postData;
        }
    
        // 处理gpu和fpga提交的数据
        function gpuOrFpgaPostData() {
            let postData = {
                bus:self.checkedItems[0].gpuId.split(":")[0],
                slot:self.checkedItems[0].gpuId.split(":")[1].split(".")[0],
                function:self.checkedItems[0].gpuId.split(":")[1].split(".")[1],
                gpuId:self.checkedItems[0].gpuId
            }
            return postData;
        }

        // 云主机开机状态下 不能进行卸载FPGA和GPU操作
        function checkFgpuOrGpu() {
            for(var i=0;i<contextINfo.projectsData.length;i++){
                if(contextINfo.projectsData[i].uid==self.checkedItems[0].serverId){
                    let status = contextINfo.projectsData[i].status.toLowerCase();
                    self.instanceStatus = status == "active" ? false : true;
                }
            }
            return self.instanceStatus;
        }

        // 透传设备初始化数据
        function initRefreshData() {
            self.haSwitchDisabled = false;
            $uibModalInstance.close();
            refresh();
        }

        // USB提交请求
        async function unloadTtEquipment(id,postData) {
            let result = await instancesSrv.unloadTtEquipment(id,postData).then(function(res){
                initRefreshData();
                return res;
            })
            return result;
        }

        // gpu的请求
        async function unloadGpuTtEquipment(id,postData) {
            let result = await instancesSrv.unloadGpuTtEquipment(id,postData).then(function(res){
                initRefreshData();
                return res;
            })
            return result;
        }

        // fpga的请求
        async function unloadFpgaTtEquipment(id,postData) {
            let result = await instancesSrv.unloadFpgaTtEquipment(id,postData).then(function(res){
                initRefreshData();
                return res;
            })
            return result;
        }

        // Promise处理的请求
        function promiseConfirm(func) {
            Promise.allSettled([Promise.resolve(func)]).then(data=>{
                if(data[0].value && data[0].value.status == 0) {
                    setHostBind();
                }
            });
        }

        // 透传设备卸载和设置高可用提交
        self.confirm = function(){
            self.haSwitchDisabled = true;
            let id = self.checkedItems[0].serverId;
            let deviceType = self.checkedItems[0].deviceType.toLowerCase();
            if(deviceType == "usb"){
                let postData = usbPostData();
                promiseConfirm(unloadTtEquipment(id,postData));
            }else if(deviceType == "gpu" && checkFgpuOrGpu()){
                let postData = gpuOrFpgaPostData();
                promiseConfirm(unloadGpuTtEquipment(id,postData));
            }else if(deviceType == "fpga" && checkFgpuOrGpu()){
                let postData = gpuOrFpgaPostData();
                promiseConfirm(unloadFpgaTtEquipment(id,postData));
            }else{
                $timeout(()=>{
                    self.haSwitchDisabled = false; 
                },2000)
            }
        }

    })
    .controller("addTtEquipmentCtrl", function($scope, $uibModalInstance, instancesSrv, editData ,refresh,context,$translate,refreshInstanceData) {
        var self = $scope;
        let serverId = editData.uid  
        let count = context.ttEquipmentData
        self.noCanLoadusbEquipment = false   
        self.noCanLoadgpuEquipment = false
        self.noCanLoadFpgaEquipment=false
        self.limitUsbDriveType = false
        self.limitGpuDriveType = false
        self.limitFpgaDriveType = false
        self.fpga=true
        self.showFlag=true
        self.hasAddEquipmentList = [
            {name:$translate.instant("aws.instances.equipmentList.usb"),deviceType:"USB"},
            {name:$translate.instant("aws.instances.equipmentList.gpu"),deviceType:"GPU"},
            {name:$translate.instant("aws.instances.equipmentList.fpga"),deviceType:"FPGA"}
        ]
        self.compatibilityList = [
            {name:"USB2.0",compatibility:"0"},
            {name:"USB3.0",compatibility:"1"}
        ]
        self.deviceType = {
            name:self.hasAddEquipmentList[0]
        }
        
        self.compatibility = {
            name:self.compatibilityList[0]
        }
        self.equipmentList = []
        // 点击加载之后，加载usb设备
        initUsbEquipment()
        
        // 选择USB兼容性
        // self.choiceCompatibility = function(Compatibility){
        // }
         // 初始化usb设备
        function initUsbEquipment(){
            instancesSrv.getTtequipmentList(serverId).then(function(res){
            	self.canSubmit=true
            	self.showFlag=true
                self.equipmentList = []
               	self.userFlag=true
               	self.noCanLoadgpuEquipment=false
               	self.noCanLoadFpgaEquipment=false
                if(res&&res.data&&res.data.length>0){
                    res.data.forEach(function(item){
                        if(!item.loading){
                            self.equipmentList.push(item)
                        }
                    })
                    if(self.equipmentList.length>0){
                        self.ttEquipmentDetails = self.equipmentList[0]
                        self.noCanLoadusbEquipment = false
                        if(self.limitUsbDriveType&&self.userFlag){
                        	self.canSubmit=false
                        }
                    }else{
                        self.noCanLoadusbEquipment = true
                        self.canSubmit=false
                    }  
                }else{
                    self.noCanLoadusbEquipment=true
                    self.canSubmit=false
                }
            })
        }
         // 初始化gpu设备
        function initGpuEquipment(res){
        	self.noCanLoadFpgaEquipment=false
            instancesSrv.getGpuTtequipmentList(serverId).then(function(res){
            	self.canSubmit=true
            	self.showFlag=true
                self.userFlag=false
                self.noCanLoadusbEquipment=false
                self.noCanLoadFpgaEquipment=false//关闭usb，fpga提示
                self.equipmentList = []
                if(editData.status=="shutoff"||editData.status=="stopped"){
                if(res&&res.data&&res.data.length>0){
                    res.data.forEach(function(item){
                        if(!item.loading){
                            self.equipmentList.push(item)
                        }
                    })
                    if(self.equipmentList.length>0){
                        self.ttEquipmentDetails = self.equipmentList[0]
                        self.noCanLoadgpuEquipment = false
                        self.limitUsbDriveType=false
                    }else{
                    	self.canSubmit=false
                        self.noCanLoadgpuEquipment=true
                    }
                }else{
                    self.noCanLoadgpuEquipment=true
                    self.canSubmit=false
                }
            }
            else{
                //开机状态不能加载
                self.canShowCloudStartLimitGpu=true
                self.canSubmit=false
                if(res&&res.data&&res.data.length>0){
                    res.data.forEach(function(item){
                        if(!item.loading){
                            self.equipmentList.push(item)
                        }
                    })
                    if(self.equipmentList.length>0){
                        self.ttEquipmentDetails = self.equipmentList[0]
                        self.limitUsbDriveType=false
                    }else{
                        self.noCanLoadgpuEquipment=true
                    }
                }else{
                    //提示没有
                    self.noCanLoadgpuEquipment=true
                    self.canShowCloudStartLimitGpu=false
                }
                } 
            })
        }
        
        //初始化FPGA设备
         function initFpgaEquipment(){
         	self.noCanLoadgpuEquipment=false
            instancesSrv.getFpgaTtequipmentList(serverId).then(function(res){
				            	self.showFlag=true
				            	self.userFlag=false
                                self.equipmentList = []
                                self.noCanLoadusbEquipment=false
                                self.noCanLoadgpuEquipment=false//关闭usb，fpga提示
            	if(editData.status=="shutoff"||editData.status=="stopped"){
            					self.canSubmit=true
				                if(res&&res.data&&res.data.length>0){
				                    res.data.forEach(function(item){
				                        if(!item.loading){
				                            self.equipmentList.push(item)
				                        }
				                    })
				                    if(self.equipmentList.length>0){
				                        self.ttEquipmentDetails = self.equipmentList[0]
				                        self.limitUsbDriveType=false
				                        self.noCanLoadFpgaEquipment = false
				                    }else{
				                    	self.canSubmit=false
				                    	self.noCanLoadFpgaEquipment=true
				                    }
				                }else{
                                    self.noCanLoadFpgaEquipment=true
                                    self.canSubmit=false
                                }
            	}else{
                    //开机状态下不能进行加载
                    self.vimStartOrShutOffStatus=true
				                if(res&&res.data&&res.data.length>0){
				                    res.data.forEach(function(item){
				                        if(!item.loading){
				                            self.equipmentList.push(item)
				                        }
				                    })
				                    if(self.equipmentList.length>0){
				                        self.ttEquipmentDetails = self.equipmentList[0]
				                        self.limitUsbDriveType=false
				                        self.noCanLoadFpgaEquipment = false
				                    }else{
				                    	self.noCanLoadFpgaEquipment=true
				                    }
				                }else{
                                    //提示没有
                                    self.noCanLoadFpgaEquipment=true
                                    self.canSubmit=false
                                }
            	}
            })
        }
        // 单选按钮，切换设备类型
        self.getChoiceOne=function(item){
            self.ttEquipmentDetails = item
        }
        let loginData = angular.fromJson(localStorage.$LOGINDATA);
        let regionUid = loginData.regionUid;
        // 第一次进入，检验USB设备是否可加载
        // USB最多加载3条
        // GPU最多加载1条
        //FPGA最多加载1条
        function limitDriveType(){
            if(self.deviceType.name.deviceType=="USB"){
                self.limitGpuDriveType = false
                self.noCanLoadgpuEquipment = false
                let num = 0
                count.forEach(function(item){
                    if(item.deviceType=="USB"){
                        num++
                    }
                })
                if(num<3){
                    self.limitUsbDriveType = false
                }else{
                    self.limitUsbDriveType = true
                }
            }
        }
        limitDriveType()

        self.showCompatibilitySelect = true
        self.choiceDriveType = function(item){
            self.vimStartOrShutOffStatus=false
            self.canShowCloudStartLimitGpu=false
            if(item.deviceType=="USB"){
                self.equipmentList = []
                initUsbEquipment()
                limitDriveType()
                self.showCompatibilitySelect = true
            }else if(item.deviceType=="GPU"){
                self.equipmentList = []                
                initGpuEquipment()
                limitDriveType()
                self.showCompatibilitySelect = false
                if(editData.status=="shutoff"||!editData.status=="stopped"){
                	self.canSubmit=true
                }else{
                    self.canSubmit=false
                }
            }else if(item.deviceType=="FPGA"){
            	self.equipmentList = []                
                initFpgaEquipment()
                limitDriveType()
                self.showCompatibilitySelect = false
                if(editData.status=="shutoff"||editData.status=="stopped"){
                	self.canSubmit=true
                }else{
                    self.canSubmit=false
                }
                
            }
        }

        // 初始化高可用开关的值
        function initHaSwitch(value) {
            let haSwitchHostInfo = context.haSwitchHostInfo;
            if(haSwitchHostInfo && Object.keys(haSwitchHostInfo).length>0) {
                self.haSwitch = haSwitchHostInfo.hostBind ? false : true;
            }else {
                self.haSwitch = value.hostBind ? false : true;
            }
            self.switchText = switchText(self.haSwitch);
        }
        initHaSwitch(editData);

        // 开启高可用开关
        self.switchChange = (value)=> {
            self.haSwitch = value;
            self.switchText = switchText(self.haSwitch);
        }

        // 处理高可用开关显示的文字
        function switchText(value){
            let haSwitch = value ? $translate.instant('aws.localDisk.open') : $translate.instant('aws.localDisk.close');
            return haSwitch;
        }

        // 设置高可用
        function setHostBind() {
            let uid = editData.uid;
            let params = {
                hostBind: !self.haSwitch
            }
            instancesSrv.setHighAvailability(uid,params).then((res)=>{
                editData.hostBind = params.hostBind;
                context.haSwitchHostInfo = editData;
                refreshInstanceData();
            }).finally(()=>{})
        }

        // 处理USB提交的数据
        function usbPostData() {
            let postData = {
                serverId : editData.uid,
                enterpriseUid : localStorage.enterpriseUid,
                regionUid : regionUid,
                regionKey : localStorage.regionKey,
                serverName : editData.name,
                hostName : self.ttEquipmentDetails.host,
                deviceType : self.deviceType.name.deviceType,
                description : self.ttEquipmentDetails.description,
                addressBus : self.ttEquipmentDetails.address_bus,
                addressDevice : self.ttEquipmentDetails.address_device,
                usbBus : self.compatibility.name.compatibility
            }
            return postData;
        }

        // 处理gpu和fpga提交的数据
        function gpuOrFpgaPostData() {
            let postData = {
                serverId:editData.uid,
                enterpriseUid:localStorage.enterpriseUid,
                regionUid:regionUid,
                regionKey:localStorage.regionKey,
                bus:self.ttEquipmentDetails.id.split(":")[0],
                slot:self.ttEquipmentDetails.id.split(":")[1].split(".")[0],
                function:self.ttEquipmentDetails.id.split(":")[1].split(".")[1],
                gpuId:self.ttEquipmentDetails.id,
                serverName: editData.name,
                deviceType:self.deviceType.name.deviceType,
                description:self.ttEquipmentDetails.desc,
                host:self.ttEquipmentDetails.host
            }
            return postData;
        }

        // 透传设备初始化数据
        function initRefreshData() {
            $uibModalInstance.close();
            self.btnSwitch = true;
            refresh();
        }

        // USB提交请求
        async function addTtEquipment(serverId,postData) {
            let result = await instancesSrv.addTtEquipment(serverId,postData).then(function(res){
                initRefreshData();
                return res;
            })
            return result;
        }

        // gpu的请求
        async function addGpuTtEquipment(serverId,postData) {
            let result = await instancesSrv.addGpuTtEquipment(serverId,postData).then(function(res){
                initRefreshData();
                return res;
            })
            return result;
        }

        // fpga的请求
        async function addFpgaTtEquipment(serverId,postData) {
            let result = await instancesSrv.addFpgaTtEquipment(serverId,postData).then(function(res){
                initRefreshData();
                return res;
            })
            return result;
        }

        // Promise处理的请求
        function promiseConfirm(func) {
            Promise.allSettled([Promise.resolve(func)]).then(data=>{
                if(data[0].value && data[0].value.status == 0) {
                    setHostBind();
                }
            });
        }

        // 按钮点击 控制
        self.btnSwitch = true;
        self.addTtEquipmentConFirm = function(){
            self.canSubmit=false;
            if(self.btnSwitch){
                self.btnSwitch = false;
                let deviceType = self.deviceType.name.deviceType.toLowerCase();
                if(deviceType == "usb"){
                    let postData = usbPostData();
                    promiseConfirm(addTtEquipment(serverId,postData));
                }else if(deviceType == "gpu"){
                    let postData = gpuOrFpgaPostData();
                    promiseConfirm(addGpuTtEquipment(serverId,postData));
                }else if(deviceType == "fpga"){
                    let postData = gpuOrFpgaPostData();
                    promiseConfirm(addFpgaTtEquipment(serverId,postData));
                }
            }
        }
    })
    .controller("loadingDiskCtrl",["$scope","$translate","editData","context","instancesSrv","volumesDataSrv","NgTableParams","$uibModalInstance","TableCom",function($scope,$translate,editData,context,instancesSrv,volumesDataSrv,NgTableParams,$uibModalInstance,TableCom){
        var scope = $scope;
            scope.pageSize = 8;
            scope.globalSearchTerm = "";
            scope.changePage=function(options){
                options.pattern = scope.globalSearchTerm?scope.globalSearchTerm:null;
                options.status = null;
                options.type = 0;
                getVolumes(options);
            };
            //后端搜索
            scope.searchBackend = function(){
                var pageOption = {
                    pageSize: scope.pageSize,
                    pageNum:1,
                    pattern:scope.globalSearchTerm?scope.globalSearchTerm:null,
                    status:null,
                    type: 0
                };
                scope.currentPage = 1;
                getVolumes(pageOption);
            }

            function getVolumes(options) {
                if(!localStorage.cinderService) return;
                volumesDataSrv.getDataVolumList(editData.uid,options).then(function(result) {
                    if(result && result.data && angular.isArray(result.data)){
                        result.data=result.data.filter(vol=>{
                            if(vol.name){
                                // return vol.name.split("-")[0]!="fixedImageInstanceName"
                                return true;
                            }else{
                                return true;
                            }
                        });
                        result.data.map(item => {
                            item.statusCopy= $translate.instant("aws.volumes.table.status."+item.status);
                            item.multiattachCopy = $translate.instant('aws.volumes.table.multiattach.'+item.multiattach);
                            item.bootableCopy = $translate.instant('aws.volumes.table.bootable.'+item.bootable);
                            item.voltype =  item.storageInfo?item.storageInfo.disPlayName:"";
                            item._type=angular.copy(item.type);
                            if (item.description == null) {
                               item.description = '';
                            }else if(item.description == "auto-created_from_restore_from_backup"){
                                item.description = $translate.instant("aws.volumes.fromBackup");
                            }
                            if(angular.isArray(item.attachments) && item.attachments.length == 1 && item.attachments[0].device && item.attachments[0].device.slice(-2) == "da"){
                                item.root_device = true;
                            }
                        });
                        scope.data_data = result.data.filter(volume => !(volume.bootable && volume.root_device) || (volume.imageMetadata&&volume.imageMetadata.disk_format == "iso"));
                        scope.data_data= scope.data_data.filter(item => !( item.metaData && (item.metaData.image_cache_name || item.metaData.glance_image_id)) || (item.imageMetadata&&item.imageMetadata.disk_format == "iso"));
                        // scope.data_data= scope.data_data.filter(item => !(item.name&&item.name.indexOf("backup-vol") > -1));
                        successFunc(scope.data_data);
                    }
                    scope.totalNum = result.total;   
                });
            }

            function successFunc(data){
                // scope.disk.volumeId = scope.data_data[0]?scope.data_data[0].id:null;
                TableCom.init(scope, 'dataTableParams',data,'id');
            };

            var options = {
                "pageNum": 1,
                "pageSize": 8,
                "type": 0
            }
            scope.currentPage = 1;
            getVolumes(options);

            //与后台交互
            scope.confirm = function() {
                let postDatas = [];
                _.forEach(scope.dataTableParams.data, function(item) {
                    if (scope.checkboxes.items[item.id]){
                        postDatas.push({
                            serverId: editData.uid,
                            volumeId: item.id,
                            device: "/dev/vdn" //暂时写死
                        });
                    }
                });
                $uibModalInstance.close();
                instancesSrv.os_loading_disk_batch(postDatas).then(function() {
                    scope.checkboxes.items = {};
                });                
            };
    }])
    .controller("instanMkImgCtrl",["$scope","refresh","editData","$uibModalInstance","instancesSrv","context",function($scope,refresh,editData,$uibModalInstance,instancesSrv,context){
        let self = $scope;
        self.submitInValid = false;
        self.img = {
            type:{
                selected:""
            },
            imageName:""
        };
        self.formatList = [
            {
                id:1,
                value:"raw"
            },
            {
                id:2,
                value:"qcow2"
            },
            // {
            //     id:3,
            //     value:"vmdk"
            // }
        ];
        self.instanDisabled = false;
        self.typeList = [
            {
                id:2,
                value:"image"
            }
        ]
        
        self.changeFormat = function(item){
            self.curFormat = item.value;
        };
        self.changeFormat(self.formatList[0]);
    
        // if(editData.startStyle=="Local"){
        //     self.img.use_snapshot=true;
        // }else{
        //     self.img.use_snapshot=false;
        // }
        self.initData = function(){
            instancesSrv.getServerDetail(editData.uid).then(res=>{
                if(res&&res.data){
                    self.diskInfo = res.data.diskInfo;
                    for(let i=0;i<self.diskInfo.length;i++){
                        if(self.diskInfo[i].capabilities.volume_backend_name.indexOf("ceph")>-1&&self.diskInfo[i].bootable=="true"){
                            //判断云主机系统盘是否是ceph盘：是，则显示两种镜像类型“snapshot”和“image”，如果不是ceph盘，则只显示一种镜像类型“image”
                            self.typeList.unshift({
                                id:1,
                                value:"snapshot"
                            })
                            break;
                        }
                    }
                }
            }).finally(()=>{
                self.img.type.selected = self.typeList[0];
            })
        }
        self.confirm = function(imgform) {
            if (imgform.$valid) {
                self.instanDisabled = true;
                let data = {
                    "imageName": self.img.imageName,
                    "volumeId":"", 
                    "instanceId": editData.uid,
                    "imageFormat":self.curFormat,
                    "type":self.img.type.selected.value
                }
                self.diskInfo.forEach((x)=>{
                    if(x.bootable=='true'&& x.root_device == "true" && x.diskFormat!="iso"){
                        data.volumeId=x.id;
                    }
                })
                instancesSrv.makeImage(data).then(()=>{
                    context.checkboxes.items={};
                    self.instanDisabled = false;
                }).finally(()=>{
                    self.instanDisabled = false;
                    $uibModalInstance.close();
                })
            } else {
                self.submitInValid = true;
            }
        };
        self.initData();
    }])
    // 标签是否重复
    .directive('labelrepetition', function(){
        return {
            require:'ngModel',
            link: function(scope,elm,attrs,ctrl){
                ctrl.$parsers.unshift(function(value){
                    const labelList = JSON.parse(attrs.labelrepetition)
                    const nowvalue = labelList.findIndex(item => item.value == value);
                    // const nowvalue = scope.$parent.$parent.$data.findIndex(item=>item.value == value);
                    if(nowvalue != -1){
                        ctrl.$setValidity('labelrepetition', false);
                        return undefined;
                    }
                    ctrl.$setValidity('labelrepetition', true);
                    return value
                })
            }
        }
    })
    .controller("settingHaCtrl",["$scope","refresh","editData","$uibModalInstance","instancesSrv","context","$translate",
        function($scope,refresh,editData,$uibModalInstance,instancesSrv,context,$translate){
            let self = $scope;
            // hostBind-- true关闭高可用   false或者null开启高可用
            self.hostBind = !editData.hostBind;
            self.submitInValid = false;
            self.hostBindDisabled = true;
            self.switchFn = function(){
                self.text = (self.hostBind)?$translate.instant("aws.instances.settingHaModal.on"):$translate.instant("aws.instances.settingHaModal.off");
            };
            self.text = (self.hostBind)?$translate.instant("aws.instances.settingHaModal.on"):$translate.instant("aws.instances.settingHaModal.off");
            
            self.$watch(function(){
                return self.hostBind
            },function(value){
                self.hostBindDisabled = (!value === editData.hostBind) ? true : false;
            })

            self.confirm = function(haform) {
                if (haform.$valid) {
                    let uid = editData.uid;
                    let params = {
                        hostBind:!self.hostBind
                    }
                    instancesSrv.setHighAvailability(uid,params).then((res)=>{
                        context.checkboxes.items={};
                        self.hostBindDisabled = false;
                        refresh();
                    }).finally(()=>{
                        self.hostBindDisabled = false;
                        $uibModalInstance.close();
                    })
                } else {
                    self.submitInValid = true;
                }
            };
        }
    ])
    .controller("addPortCardCtrl",["$scope","$rootScope","refresh","editData","$uibModalInstance","instancesSrv","context","$translate","$uibModal","$timeout",
        function(scope,$rootScope,refresh,editData,$uibModalInstance,instancesSrv,context,$translate,$uibModal,$timeout){
            let self = scope;
            //scope.type = type
            scope.netWorkCard = {};
            scope.network = {
                assignIP: false,
                ipVersion:"4",
                ipDisabled:false,
                v6List:{},
                v4List:{}
            };
            scope.submitInValid = false;
            scope.isPaasNet = false;
            scope.netTitle = $translate.instant("aws.instances.net.addNet");
            scope.ipLabel = $translate.instant("aws.instances.addinstances.ipAddr");
            function getAllsubPool(data){
                let arr = [];
                data.forEach(item=>{
                    for(let i=0;i<item.subnets.length;i++){
                        for(let j=0;j<item.subnets[i].allocationPools.length;j++){
                            item.net_sub_name = item.name + "---" + item.subnets[i].name + "(" + item.subnets[i].allocationPools[j].start + "~" + item.subnets[i].allocationPools[j].end + ")";
                            item.displayName = item.name;
                            item.sub_key = i;
                            item.allocationPool_key = j; 
                            item.subnet_id=item.subnets[i].id;
                            arr.push(angular.copy(item));
                        }
                    }
                })
               return arr;
            }
            function getServerSecGroup(scope,used,editData,flag){
                instancesSrv.listServerSecGroup(editData.uid).then(function(result) {
                    if(result && result.data && angular.isArray(result.data)){
                        scope.iptablesList = result.data;
                        scope.netWorkCard.name = result.data[0]
                        _.forEach(scope.iptablesList, function(item) {
                            if (item.id == used ) {
                                scope.sec.iptables = item.id;
                            }
                            if(!used && item.name == "default"){
                                scope.network.secSelected = item;
                            }
                        });
                        if(flag){
                            if(scope.network.selected&&scope.network.selected.net_sub_name.indexOf('PaaS网络')>-1&&scope.network.selected.net_sub_name.indexOf('PaaS子网'>-1)){
                                scope.isPaasNet = true;
                                scope.network.secSelected = {name:'PaaS security pair'}
                            }else{
                                scope.isPaasNet = false;
                            }
                        }
                        if(scope.sec&&scope.sec.port_id&&scope.sec.port_id.net_name=='PaaS网络'){
                            scope.cannot_Confirm = true;
                        }else{
                            scope.cannot_Confirm = false;
                        }
                    }
                    
                });
            };
            scope.changeNet = function(net) {
                //paas网络判断
                if(net.net_sub_name.indexOf($translate.instant("aws.instances.paasNet"))>-1&&net.net_sub_name.indexOf($translate.instant("aws.instances.paasSubNet")>-1)){
                    scope.isPaasNet = true;
                    scope.network.secSelected = {name:'PaaS security pair'}
                }else{
                    scope.isPaasNet = false;
                }
                scope.repeatIp = false;
                scope.portPolicyForbidden = false;
                scope.cannot_Confirm = false;
                scope.validForm = false;
                scope.subSegmentList = [];
                scope.selectedSubPoolList = [];
                net.subnets.forEach(item=>{
                    if(item.allocationPools.length>0){
                        let ipVersion = item.ipVersion;
                        let mode=item.ipv6AddressMode=="dhcpv6-stateful"&&ipVersion=="6"
                        if(ipVersion==4||mode){
                            item.allocationPools.forEach(ii=>{
                                let obj = {};
                                obj.sub_pool = item.name + " : " + ii.start + " ~ " + ii.end;
                                obj.start = ii.start;
                                obj.end = ii.end;
                                obj.cidr = item.cidr;
                                obj.ipVersion = item.ipVersion;
                                scope.subSegmentList.push(obj);
                            })
                        }
                    }
                })
                scope.network.subSegment = scope.subSegmentList[0];
                scope.changeSubSegment(scope.network.subSegment)
            };
            
            scope.changeSubSegment = function(net){
                scope.network.ipVersion = net.ipVersion
                scope.loading = false;
                $timeout(function(){
                    scope.loading = true;
                },0)
            };
            scope.mouseNet = function(network,type){
                scope.net_subSegDetail = angular.copy(scope.networkList_extend);
                _.map(scope.net_subSegDetail,function(item){
                    if(item.id == network.id){
                        item.sub_name = item.net_sub_name.split("---")[1];
                        if(type == "over"){
                             item.showSubSegDetail = true;
                         }else{
                             item.showSubSegDetail = false;
                         }
                        return item 
                    }
                });
            };
            //获取安全组列表
            getServerSecGroup(scope,"",editData,true)
            //获取该项目下的网卡
            instancesSrv.getProjectNetworks().then(function(result) {
                if (result && result.data && angular.isArray(result.data) ) {
                    scope.networkList = result.data;
                    scope.networkList.forEach(item=>{
                        item.subnets = item.subnets.filter(ii=>{
                            let ipVersion = ii.ipVersion;
                            let mode=ii.ipv6AddressMode=="dhcpv6-stateful"&&ipVersion=="6"
                            return ipVersion==4||mode
                        })
                    })
                    scope.networkList = result.data.filter(function(item) {
                        return item.subnets.length; //没有绑定子网的交换机创建云主机时不能使用
                    });
                    //定制开发，如果为非admin用户，添加网卡过滤掉公网
                    if(!$rootScope.ADMIN){
                        scope.networkList = result.data.filter(function(item) {
                            return !item.external; 
                        });
                    }
                    scope.networkList_extend = getAllsubPool(scope.networkList);
                    scope.network.selected = scope.networkList[0];
                    scope.changeNet(scope.network.selected)
                    
                    if(scope.network.selected.net_sub_name.indexOf($translate.instant("aws.instances.paasNet"))>-1&&scope.network.selected.net_sub_name.indexOf($translate.instant("aws.instances.paasSubNet")>-1)){
                        scope.isPaasNet = true;
                        scope.network.secSelected = {name:'PaaS security pair'}
                    }else{
                        scope.isPaasNet = false;
                    }
                }
            });
            scope.confirm = function(upNet, netForm) {
                if(netForm.$valid){
                    let postData = {
                        "networkid": {
                            "networkid": upNet.id
                        },
                        "fixdIps": []
                    };
                    if(scope.network.assignIP){
                        if(scope.network.ipVersion==4){
                            postData.fixdIps = [{
                                "ip_address": self.network.v4List.fixedIp
                            }];
                        }else{
                            postData.fixdIps = [{
                                "ip_address": self.network.v6List.fixedIp
                            }];
                        }
                        
                    }
                    $uibModalInstance.close('123');
                    $uibModalInstance.result.then((res)=>{
                        addNetMsgModal();
                    })
                    function addNetMsgModal(){
                        return $uibModal.open({
                            animation: scope.animationsEnabled,
                            templateUrl: "js/cvm/instances/tmpl/add-net-msg.html",
                            controller:"addNetMsgCtrl",
                            resolve:{
                                postData:function(){
                                    return postData
                                },
                                editData:function(){
                                    return {
                                        uid:editData.uid,
                                        secGroup:[scope.network.secSelected.id]
                                    }
                                },
                                refresh:function(){
                                    return refresh;
                                }
                            }
                        });
                    }

                }else {
                    scope.submitInValid = true;
                }
            }
    }])
    .controller("addNetMsgCtrl",["$scope","editData","$uibModalInstance","instancesSrv","refresh","postData",
        function(scope,editData,$uibModalInstance,instancesSrv,refresh,postData){
            scope.disabledFlag=false;
            scope.MsgConfirm = function(){
                scope.addFixed_uid = editData.uid;
                scope.disabledFlag=true;
                instancesSrv.addNetwork(editData.uid, postData.fixdIps, postData.networkid).then(function(result) {
                    if(result.status == 0){
                        var data = {
                            id:result.data.port_id,
                            secGroup:editData.secGroup
                        }
                        instancesSrv.updatePortSecurity(data)
                    }
                }).finally(function(){
                    refresh();
                    $uibModalInstance.close()
                    scope.disabledFlag=false;
                });
            }
            
        }
    ])
    .directive('insDetailConfigInfoTpl',detailConfigInfoTpl)
    .directive('insDetailMonitorInfoTpl',detailMonitorInfoTpl)
    .directive('insDetailMigrationRecordTpl',detailMigrationRecordTpl)
    .directive('insDetailLogTpl',detailLogTpl)