import "./objectStorageUserSrv";

var objectStorageUserModule = angular.module("objectStorageUserModule",["ngMessages","objectStorageUserSrvModule"]);
objectStorageUserModule.controller('objectStorageUserCtrl',['$scope','objectStorageUserSrv','TableCom','$uibModal','$translate','$routeParams',
	function(self,objectStorageUserSrv,TableCom,$uibModal,$translate,$routeParams){
		self.checkboxes = {
            checked: false,
            items: {}
        };
		function initTableData() {
			objectStorageUserSrv.getUserList().then(function(res){
				res ? self.loadData = true : "";
				if(res && res.data){
                    self.dataList = res.data;
					TableCom.init(self, "tableParamsUser", self.dataList, "userId", 10);
					
					
                }
			});
		}
		self.$watch(function() {
			return self.checkboxes.items;
		}, function(val) {
			if(val){
				self.checkedItems = [];
				var arr = [];
				for (var i in self.checkboxes.items) {
					arr.push(self.checkboxes.items[i]);
				}
				if (val && arr.length >= 0) {
					for (var key in val) {
						if (val[key]) {
							self.dataList.forEach(item => {
								if (item.userId == key) {
									self.checkedItems.push(item);
								}
							});
						}
					}
				}
				self.manageDisabled = true;
				if (self.checkedItems.length == 1) {
					self.isDisabled = false;
					self.delisDisabled = self.checkedItems[0].userId == 'admin' ? true : false;
					// self.manageDisabled = self.checkedItems[0].userId == 'admin' ? true : false;
					// admin账号可以进行密钥管理
					self.manageDisabled = false;
				} else if (self.checkedItems.length > 1) {
					self.isDisabled = true;
					self.delisDisabled = self.checkedItems.some(item => item.userId == 'admin') ? true : false;
					self.manageDisabled = true;
				}else{
					self.isDisabled = true;
					self.delisDisabled = true;
					self.manageDisabled = true;
				}
			}
			
		}, true); 

		self.applyGlobalSearch = function() {
            self.tableParamsUser.filter({
                displayName: self.globalSearchTerm
            });
        };

		// 刷新列表
        self.refresh = function() {
            self.globalSearchTerm = '';
            initTableData();
		}

		// 新建或编辑弹窗
		self.createItem = function(type) {
            $uibModal.open({
                animation: true,
                templateUrl: "createUser.html",
				//scope: scope,
				controller:"updateObjectUser",
				resolve:{
					refresh:function(){
						return self.refresh
					},
					editData:function(){
						return self.checkedItems[0]
					},
					type:function(){
						return type;
					}
				}
            });
			
		};
		// 私钥管理
		self.manageKeyItem = function(type) {
            $uibModal.open({
                animation: true,
                templateUrl: "updateAccessKey.html",
				//scope: scope,
				controller:"objectAccessKeyCtrl",
				resolve:{
					refresh:function(){
						return self.refresh
					},
					editData:function(){
						return self.checkedItems[0]
					}
				}
            });
			
		};
		
		// 删除
		self.delItem = function(delisDisabled) {
			if(delisDisabled) return;
            const content = {
                target: "deluser",
                msg: "<span>" + $translate.instant("aws.system.objectUser.deleteTip") + "</span>",
                data: self.checkedItems
            };
            self.$emit("delete", content);
        };
        self.$on("deluser", function(e, data) {
            const ids = data.map(item => item.userId);
            objectStorageUserSrv.deleteItem({userId:ids}).then(function() {
                initTableData();
            });
        });
		
		initTableData();
		function initDetailData(value) {
			objectStorageUserSrv.getDetailData(value).then(function(res) {
				res ? self.detailLoadData = true : '';
				if(res && res.data){
					self.detailData = res.data;
					TableCom.init(self,'detailTableParams',self.detailData.rgwCredential,"userId",9999,'detailCheckboxes');
				}
			});
		}
		// self.$on("getDetail", function(event,value) {
		// 	self.detailUserId = value;
		// 	if($routeParams.id != value) return;
		// 	initDetailData(value);
		// });

		self.refreshDetail = function() {
			initDetailData(self.detailUserId);
		}
		// 新建
		self.createAccessKey = function() {
			objectStorageUserSrv.createKey(self.detailUserId).then(function(){
				initDetailData(self.detailUserId);
			})
		}
		// 删除
		self.delFun = function(item) {
			var content = {
				target: "delete-storage-user-secretKey",
				msg: "<span>" + $translate.instant("aws.system.objectUser.deleteTip") + "</span>",
				data: item
			};
			self.$emit("delete", content);
		}
		self.$on("delete-storage-user-secretKey", function (e, item) {
			objectStorageUserSrv.deleteKey(item.userId, item.accessKey).then(function(){
				initDetailData(self.detailUserId);
			})
		});
	}
]).controller("updateObjectUser",["$scope","objectStorageUserSrv","editData","$translate","refresh","type","$uibModalInstance",function(scope,objectStorageUserSrv,editData,$translate,refresh,type,$uibModalInstance){
	scope.submitInValid = false;
	let service = '';
	if(type == 'new'){
		service = 'addItem';
		scope.modalTitle = $translate.instant("aws.system.objectUser.createUser");
		scope.formData = {
			userId: "",
			maxSizeKb: "-1",
			maxBuckets: "",
			maxObjects: "-1"
		};
	}else{
		service = 'editItem';
		scope.modalTitle = $translate.instant("aws.system.objectUser.editUser");
		scope.editOperation = true;
		scope.formData = {
			userId: editData.userId,
			maxSizeKb: editData.maxSizeKb,
			maxBuckets: editData.maxBuckets,
			maxObjects: editData.maxObjects
		};
	}
	scope.createConfirm = function(form_field) {
		if (form_field.$valid) {
			objectStorageUserSrv[service](scope.formData).then(function() {
				refresh();
			});
			$uibModalInstance.close();
		} else {
			scope.submitInValid = true;
		}
	};
}])
.controller("objectAccessKeyCtrl",["$scope","$rootScope","objectStorageUserSrv","editData","$translate","$uibModalInstance","TableCom","uiAlertModal",function(scope,$rootScope,objectStorageUserSrv,editData,$translate,$uibModalInstance,TableCom,uiAlertModal){
	// 新建
	let self = scope;
	self.globalSearchTerm = '';
	self.totalNum = 0;
	self.pageSize = 10;
	self.pageNum = 1;
	self.detailUserId = editData.userId;
	self.createAccessKey = function() {
		objectStorageUserSrv.createKey(self.detailUserId).then(function(){
			initDetailData(self.detailUserId);
		})
	}
	// 删除
	self.delFun = function(item) {
		uiAlertModal.show({
			message:"<span>" + $translate.instant("aws.system.objectUser.deleteTip") + "</span>",
			callback:deleteItemCb,
			data: item
		})
	}
	function deleteItemCb(item){
		objectStorageUserSrv.deleteKey(item.userId, item.accessKey).then(function(){
			self.pageNum = 1
			initDetailData(item.userId);
		})
	}
	self.refreshDetail = function() {
		self.pageNum = 1
		initDetailData(self.detailUserId);
	}
	
	function initDetailData(value) {
		const config = {
			pageNum:self.pageNum
		}
		objectStorageUserSrv.getDetailData(value,config).then(function(res) {
			res ? self.detailLoadData = true : '';
			if(res && res.data){
				self.totalNum = res.data.total;
				self.detailData = res.data;
				TableCom.init(self,'detailTableParams',self.detailData.rgwCredential,"userId",9999,'detailCheckboxes');
			}
		});
	}
	// 改变当前页
	self.changePage = function ({pageNum,pageSize}) {
		self.pageNum = pageNum;
		initDetailData(self.detailUserId);
	}
	initDetailData(self.detailUserId);
}]);