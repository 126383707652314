
backupJobCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","TableCom","$location","$routeParams","$filter","utilTool"]
function backupJobCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,TableCom,$location,$routeParams,$filter,utilTool) {
	var self = $scope;
	self.dataPageSize=10;
    self.dataCurrentPage=1;
    self.dataLoadData = [];
    self.backupServiceConfig = true;
    self.desc = localStorage.managementRole == 2 ? $translate.instant("aws.backupService.tip.desc0") : $translate.instant("aws.backupService.tip.desc1");
    self.btn = {
        canEdit: false,
        canDelete: false,
    }
	//设置项的初始化
	self.headers={
        jobName:$translate.instant("aws.backupService.backupJob.jobName"),
        jobStatus:$translate.instant("aws.backupService.backupJob.jobStatus"),
        bindInst:$translate.instant("aws.backupService.backupJob.bindInst"),
		strategyInfo:$translate.instant("aws.backupService.backupJob.strategyInfo"),
		lastLaunchTime:$translate.instant("aws.backupService.backupJob.lastLaunchTime"),
		createDate:$translate.instant("aws.backupService.backupJob.createDate"),
		nextLaunchTime:$translate.instant("aws.backupService.backupJob.nextLaunchTime"),
		project:$translate.instant("aws.backupService.variable.table.projectName"),
		domain:$translate.instant("aws.backupService.variable.table.domainName"),
    };
	self.backupJobtitleName="backupService_backupJob";
	if(sessionStorage["backupService_backupJob"]){
		self.tableCols = JSON.parse(sessionStorage["backupService_backupJob"]);
	}else {
		self.tableCols=[
			{ field: "check", title: "",headerTemplateURL:"backupJobManageCheckbox.html",show: true ,width:"5%"},
			{ field: "jobName", title: self.headers.jobName, show: true,disable:true ,width:"15%"},//任务名称
			{ field: "bindInst", title: self.headers.bindInst, show: true,disable:false ,width:"10%"},//关联云主机
			{ field: "strategyInfo", title: self.headers.strategyInfo, show: true,disable:false ,width:"40%"},//策略
			{ field: "createDate", title: self.headers.createDate, show: true,disable:false ,width:"15%"},//创建时间
			{ field: "nextLaunchTime", title: self.headers.nextLaunchTime, show: true,disable:false ,width:"15%"}//下次发起时间
		];
	}
	// 帮助按钮
	self.backupJobSearchTearm = function(obj){
		var tableData = obj.tableData;
		var tableCols = obj.tableCols;
		tableData.map(function(item){
			item.searchTerm = "";
			tableCols.forEach(function(showTitle){
				if(showTitle.value){
					item.searchTerm += item[showTitle.search] + "\b";
				}
			});
		});
	};
    // 监听是否选中全部部门
    self.$watch(function(){
        return localStorage.selectedAllDomain
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self)
    })
	// 初始化列表
	function initTabData() {
        initBackupJobTable();
    };
    self.refres = function(){
        self.globalSearchTerm = "";
        self.dataTotalNum = 0;
        self.dataPageSize=10;
        self.dataCurrentPage=1;
        initBackupJobTable();
    }
	initBackupJobTable();
	function initBackupJobTable() {
        if(!self.backupServiceConfig){
            return;
        }
		self.backupJobTableData=[];
        self.dataLoadData  = [];
        let params = {
			pageNum: self.dataCurrentPage,
			pageSize: self.dataPageSize
		}
		if (self.globalSearchTerm) {
			params.search = self.globalSearchTerm.item;
		}
        TableCom.init(self, "tableParams", [], "id", GLOBAL_CONFIG.PAGESIZE, 'checkBackupJobToCount');
		backupJobSrv.getBackupJobList(params).then(function (result) {
			if (result && result.data) {
                self.dataTotalNum = result.total;
		        self.dataLoadData = result.data;
		        self.dataLoadData = operateData(self.dataLoadData);
                TableCom.init(self, "tableParams", self.dataLoadData, "id", GLOBAL_CONFIG.PAGESIZE, 'checkBackupJobToCount');
			}
		});
        
	}
    self.applyGlobalSearch = function() {
        initBackupJobTable();
    };
    function operateData(data) {
        data.forEach(x => {
            // 创建时间
            x.createDate = $filter("date")(x.created, "yyyy-MM-dd HH:mm:ss");
            // 下次触发时间
            x.nextLaunchTime!=null ? x.nextLaunchTime = $filter("date")(x.nextLaunchTime, "yyyy-MM-dd HH:mm:ss"):"-";
            // 策略详情
            if(x.strategyAdvParam == "" || x.strategyAdvParam == null) {
                return;
            }
            x.strategyAdvParam = JSON.parse(x.strategyAdvParam);
            switch(x.strategyAdvParam.type) {
                case 2://天  每几天
                    x.strategyInfo = $translate.instant("aws.backupService.variable.every") + x.strategyAdvParam.params[0] + $translate.instant("aws.backupService.variable.day");
                    break;
        
                case 3://周
                    var info="";
                    x.strategyAdvParam.params.forEach((y, index)=> {
                        info += $translate.instant("aws.backupService.variable.week." + y);
                        if((index+1) < x.strategyAdvParam.params.length) {
                            info += ","
                        }
                    });
                    x.strategyInfo = $translate.instant("aws.backupService.variable.every") + info;
                    break;
                case 4://月
                    var info="";
                    x.strategyAdvParam.params.forEach((y,index) => {
                        if(y==32) {
                            info += "（" + $translate.instant("aws.backupService.backupJob.dialog.days") + "）";
                        }else{
                            info += y + $translate.instant("aws.backupService.variable.days");
                            if((index+1) < x.strategyAdvParam.params.length) {
                                info += ","
                            }
                        }
                        
                    });
                    x.strategyInfo = $translate.instant("aws.backupService.variable.every") + $translate.instant("aws.backupService.variable.month") + info;
                    break;
                case 5://年
                    x.strategyInfo = $translate.instant("aws.backupService.variable.every") + $translate.instant("aws.backupService.variable.year")  
                        + x.strategyAdvParam.params[0] + $translate.instant("aws.backupService.variable.month")  + x.strategyAdvParam.params[1] + $translate.instant("aws.backupService.variable.days") ;
                    break;
            }
            // 执行时间
            var runTimeDate = new Date(x.runTime);
            var hours = runTimeDate.getHours();
            var minutes = runTimeDate.getMinutes();
            var hour_a = hours < 10 ? "0" + hours : hours;
            var minute_a = minutes < 10 ? "0" + minutes : minutes;
            x.strategyInfo += "，" + $translate.instant("aws.backupService.backupJob.dialog.yu") + hour_a + ":" + minute_a + "，" 
                + $translate.instant("aws.backupService.backupJob.dialog.zdcj") + $translate.instant("aws.backupService.backupJob.backupType." + x.backupType); 
        });
        return data;
    }
	self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
        initBackupJobTable();
    };
	//监听按钮
    self.$watch(function() {
        return self.checkBackupJobToCount;
    }, function(val) {
        if(val==undefined){
            return;
        }
        self.checkedItems = [];
		var arr=[];
        for(var i in self.checkBackupJobToCount.items){
            arr.push(self.checkBackupJobToCount.items[i])
        }
		if(val && arr.length>=0){
            for(var key in val.items){
                if(val.items[key]){
                    self.dataLoadData.forEach(item=>{
                        if(item.id==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
        self.btn = {
            canEdit: false,
            canDelete: false,
        }
        if(self.checkedItems.length==1) {  
            self.btn = {
                canEdit: true,
                canDelete: true,
            }
		}else if(self.checkedItems.length > 1) {
            self.btn.canDelete = true;
        }
    },true);

	self.openBackupJobModel = openBackupJobModel;
	// 创建和编辑 弹出框
	function openBackupJobModel(type) {
        var editData = angular.copy(self.checkedItems[0]);
        $uibModal.open({
            animation: true,
            templateUrl: "js/advanced-function/backup/backup-job/tmpl/add-backup-job.html",
            controller: "addBackupJobCtrl",
            resolve: {
                type: function() {
                    return type;
                },
                editData: function() {
                    return editData;
                },
                initBackupJobTable: function() {
                    //刷新table;
                    return initTabData;
                },
            }
        });
    }
    self.bindInstanceModel = bindInstanceModel;
    // 绑定实例弹出框
    function bindInstanceModel(data){
        var editData = data;
        var type = "";
        $uibModal.open({
            animation: true,
            templateUrl: "js/advanced-function/backup/backup-job/tmpl/bind-instance.html",
            controller: "bindInstanceJobCtrl",
            resolve: {
                type: function() {
                    return type;
                },
                editData: function() {
                    return editData;
                },
                initBackupJobTable: function() {
                    //刷新table;
                    return initTabData;
                },
            }
        });
    }
    //删除操作
    self.delBackupJob = delBackupJob;
    function delBackupJob(checkedItems) {
        if(!self.btn.canDelete) {
            return;
        }
        var content = {
            target: "delBackupJob",
            msg: "<span>" + $translate.instant("aws.system.storagement.deleteTip") + "</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delBackupJob", function(e, data) {
        // 因为需要全部 选项。所以删除只能是轮训删除
        data.forEach(x => {
            let ids = [];
            ids.push(x.id);
            var params = {
                ids: ids
            }
            // headers
            var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                const {projectUid,domainUid} = x;
                headers = {
                    project_id: projectUid,
                    domain_id: domainUid,
                }
            }
            backupJobSrv.deleteBackupJob(params, headers).then(function () {
                initBackupJobTable();
            });
        });
    })
    self.$on("backupServiceConfig", function(e, data) {
        // 展示为配置列表
        self.backupServiceConfig = data;
    });
    //server socket
    self.$on("backupJob", function(e, data) {
        // 监听关闭详情页 ，刷新table,因为需要更新关联云主机数量，
        initBackupJobTable();
    });
    self.linkTo = function(item){
        utilTool.setLocalStorage([item]);
        // ng-href="#/advanced/backup-job?id={{item.id}}&tab=job"
        $location.url('/advanced/backup-job?id='+item.id + '&tab=job');
    }


};
export {backupJobCtrl}