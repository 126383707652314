angular.module("memSrv", [])
    .service("memsrv", ["$http",function ($http) {
        return {
            getNodeList: function () {
                return $http({
                    method: "GET",
                    url: `awstack-user/v1/enterprises/${localStorage.enterpriseUid}/regions/${localStorage.regionUid}/usable/nodes/list`
                });
            },
            createMemApi:function(data){
                return $http({
                    method:"POST",
                    url:`/awstack-user/v1/hugepage/config`,
                    data:data
                })
            },
            getMemApi:function(){
                return $http({
                    method:"GET",
                    url:`/awstack-user/v1/hugepage`
                })
            },
            restartNode:function(data,params){
                return $http({
                    method:"PUT",
                    url:"awstack-user/v1/hugepage/reboot",
                    data:data,
                    params:params
                })
            }
        };
    }]);
