import * as controllers from "./controllers";
import * as services from "./service-config-srv";

let serviceConfigModule = angular.module("serviceConfigModule", ["rzModule"]);

serviceConfigModule.config(["$controllerProvider", function($controllerProvider){
	$controllerProvider.register(controllers);
}]);

serviceConfigModule.config(["$provide", function($provide){
	$provide.service(services);
}]);