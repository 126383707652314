import { AreaPanelDefault } from "../../../chartpanel";
import {  hostAreaChartSqlParams } from "../../../chartpanel";
detailMonitorInfoTpl.$inject = [];
function detailMonitorInfoTpl () {
    return {
        restrict:"E",
        replace:true,
        scope:{
            insUid: "="
        },
        templateUrl: "js/cvm/instances/tmpl/detail-monitor-info-tpl.html",
        link:function(self, elem, attrs, $ngModel) {

        },
        controller: function($scope,$translate,vmFuncSrv,vmhostSrv,userDataSrv) {
            let self = $scope;
            self.insDataFlag = false;
            self.filterData = {
                insUid:self.insUid,
                timeStep: "",
                from: null,
                to: null,
                // precision: self.collectCycleList[0].value,
                definedTimeText: $translate.instant('aws.bill.chooseDate'),
                rangeHours:""
            };
            self.timeType = {
                options: [{
                    "name": $translate.instant('aws.bill.timeType.realTime'),
                    "value": ""
                }, {
                    "name": $translate.instant('aws.bill.timeType.sixHours'),
                    "value": "6h"
                }, {
                    "name": $translate.instant('aws.bill.timeType.oneDay'),
                    "value": "1d"
                }, {
                    "name": $translate.instant('aws.bill.timeType.threeDays'),
                    "value": "3d"
                }, {
                    "name": $translate.instant('aws.bill.timeType.oneWeek'),
                    "value": "7d"
                }]
            };
            self.fliterCard = function(data) {
                if(data && data.length) {
                    var netCard = data.filter(item => item.priority != "c0");
                    return netCard;
                }
            };
            function getRegionData(){
                $("#vmDetailLoadingChart").html("");
                vmFuncSrv.getHostAreaChartFunc(self, hostAreaChartSqlParams, AreaPanelDefault); //cpu、内存、磁盘各指标的监控
                userDataSrv.getRegionData().then(function(res) {
                    if (res && res.data) {
                        self.insDataFlag = true;
                        self.filterData.regionOptions = res.data.filter(item => {
                            return item.status == 3;
                        });
                        self.filterData.region_key = self.filterData.regionOptions[0].regionKey;
                        _.each(self.filterData.regionOptions, item => {
                            if (item.regionKey == localStorage.regionKey) {
                                self.filterData.region_key = item.regionKey;
                            }
                        });
                        // libvirt监控没有mem这个字段
                        vmhostSrv.sqlQuery({
                            "sql": "select * from mem where time > now() - 1m AND code = '" + self.filterData.region_key + "' AND host_type = 'virtual' AND \
                                        vm_id = '" + self.insUid + "'"
                        }).then(function(res) {
                            res ? self.loadedQueryData = true : false;
                            if (res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                                self.queryMemNull = false;
                                vmhostSrv.sqlQuery({
                                    "sql": "SELECT MAX(used_percent) AS used_percent FROM swap WHERE time > now() - 30m AND code = '" + self.filterData.region_key + "' \
                                                AND host_type = 'virtual' AND vm_id = '" + self.insUid + "' GROUP BY time(10s)"
                                }).then(function(res) {
                                    //先查看Linux的是否有数据
                                    if (res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                                        //有数据就用Linux数据渲染
                                        self.linuxSysVmchart = true;
                                        self.winSysVmchart = false;
                                        self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData);
                                    } else {
                                        self.linuxSysVmchart = false;
                                        self.winSysVmchart = true;
                                        self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData, "win");
                                    }
                                    console.log("linuxSysVmchart:", self.linuxSysVmchart, "winSysVmchart:", self.winSysVmchart);
                                });
                            } else {
                                self.queryMemNull = true;
                            }
                        })
                    }
                });
            }
    
            self.$on('cloud_main_type',function(e,value){
                self.defaultType = value;
            })
            self.query = function() {
                const dimensionality = self.defaultType == 'average';
                if (self.linuxSysVmchart) {
                    self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData,'',dimensionality);
                } else {
                    self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData, "win");
                }
            };
            getRegionData();
        }
    }
}

export {detailMonitorInfoTpl};