/**
 * 数据备份-任务实例详情(监控和历史数据)
 */
dragBackupJobInstanceInfoCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","$location","$routeParams","TableCom","$filter"]
function dragBackupJobInstanceInfoCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,$location,$routeParams,TableCom,$filter) {
	var self = $scope;
    self.detailData = {};
    self.detailData = {
        jobName:"jobName",
        createDate:"2022-01-01 01:01:01",
    };
    self.dataPageSize_progress=10;
    self.dataCurrentPage_progress=1;
	//设置执行进度项的初始化
	self.headers_progress={
        instanceName:$translate.instant("aws.backupService.backupJobInstancer.instanceName"),
        instStatus:$translate.instant("aws.backupService.backupJobInstancer.instStatus"),
        progress_i:$translate.instant("aws.backupService.backupJobInstancer.progress_i"),
    };
    self.tableCols_progress=[
        { field: "instanceName", title: self.headers_progress.instanceName, show: true,disable:true },//实例名称
        { field: "instStatus", title: self.headers_progress.instStatus, show: true,disable:false },//状态
        { field: "progress_i", title: self.headers_progress.progress_i, show: true,disable:false },//进度
        { field: "ID", title: "ID", show: true,disable:false },//ID
    ];
    
    initTables_projress();
	function initTables_projress() {
        
        self.dataLoadData_progress  = [];
        var params = {
            id: $routeParams.id,
            pageNum: self.dataCurrentPage_progress,
            pageSize: self.dataPageSize_progress
        }
        // 赋值 headers
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            headers = {
                project_id: localStorage.projectUid,
                domain_id: localStorage.domainUid,
            }
        }
        TableCom.init(self, "tableParams_progress", [], "id", GLOBAL_CONFIG.PAGESIZE, '');
        backupJobSrv.getBackupJobInfoTargetsList(params, headers).then(function (result) {
			if (result && result.data) {
                self.dataTotalNum_progress = result.data.totalNum;
		        self.dataLoadData_progress = result.data.data;
		        self.dataLoadData_progress = operateData(self.dataLoadData_progress);
                TableCom.init(self, "tableParams_progress", self.dataLoadData_progress, "id", GLOBAL_CONFIG.PAGESIZE, '');
			}
		});
	}
	self.dataChangePage_progress=function(options){
        self.dataCurrentPage_progress=options.pageNum;
        self.dataPageSize_progress=options.pageSize;
        initTables_projress();
    };
    self.refresh_projess = refresh_projess;
    function refresh_projess(){
        self.dataPageSize_progress=10;
        self.dataCurrentPage_progress=1;
        initTables_projress();
    }
    function operateData(data){
        data.forEach(x => {
			// 已完成数据量/总数据量 
            x.progress_a = "0%";
			if(x.completeSize == 0 || x.totalSize == 0) {
				x.progress_a = "0%";
			}else{
                // 如果为历史记录详情
                if( $routeParams.tab == 'instance' && x.status == 32 ) {
                    x.progress_a = ((x.completeSize/x.completeSize)*100) > 1 ? "100%":"100%";
                    x.instTotalSize_unit = x.instCompletedData_unit;
                }else {
                    if(x.status == 32 ){
                        //  完成状态直接置100% 
                        x.progress_a = "100%";
                    } else {
                        x.progress_a = ((x.completeSize/x.totalSize)*100) > 100 ? "100%": Math.abs(((x.completeSize/x.totalSize)*100).toFixed(2)) + "%";
                    }
                }
			}
			// completeSize 已完成数据量
			x.instCompletedData_unit = unitData(x.completeSize);
            x.instTotalSize_unit = unitData(x.totalSize);
            if(x.status == 32 ){
                // 完成状态直接置100% 
                x.instCompletedData_unit = unitData(x.totalSize);
            }
		});
        return data;
    }
    // 转换数据量单位
	function unitData(data){
		if(data == 0) {
			return 0 + "B";
		}
		var kb = data / 1024;
		if( kb > 1 || kb == 1){
			var mb = kb / 1024;
			if(mb > 1 || mb == 1) {
                var gb = mb / 1024;
                if(gb > 1 || gb == 1) {
                    return gb.toFixed(2) + " GB";
                }else{
                    return mb.toFixed(2) + " MB";
                }
			}else {
				return kb.toFixed(2) + " KB";
			}
		}
	}
    
    self.changTables = changTables;
    function changTables(data){
        self.dataLoadData_progress.forEach(x => {
            x.checked = false;
            if(x.id == data.id) {
                data.checked = true;
                x.checked = true;
                self.changTablesData = data;
                self.dataPageSize_logInfo=10;
                self.dataCurrentPage_logInfo=1;
                initTables_logInfo(data);
            }
        });
    }

    
    /************************************************************ */
    self.refresh_logs = refresh_logs;
    function refresh_logs(){
        self.dataPageSize_logInfo=10;
        self.dataCurrentPage_logInfo=1;
        initTables_logInfo(self.changTablesData);
    }
    self.dataPageSize_logInfo=10;
    self.dataCurrentPage_logInfo=1;
	//设置执行进度项的初始化
	self.headers_logInfo={
        createTime:$translate.instant("aws.backupService.backupJobInstancer.time"),
        level:$translate.instant("aws.backupService.backupJobInstancer.level"),
        logInfo:$translate.instant("aws.backupService.backupJobInstancer.logInfo"),
    };
    self.tableCols_logInfo=[
        { field: "createTime", title: self.headers_logInfo.createTime, show: true,disable:true , width:"20%" },//时间
        { field: "level", title: self.headers_logInfo.level, show: true,disable:false , width:"10%" },//级别
        { field: "logInfo", title: self.headers_logInfo.logInfo, show: true,disable:false , width:"70%" },//日志
    ];
 
	function initTables_logInfo(data) {
        self.dataLoadData_logInfo  = [];
        TableCom.init(self, "tableParams_logInfo", [], "logId", GLOBAL_CONFIG.PAGESIZE, '');
        var params = {
            id: $routeParams.id,
            targetId: data.id,
            pageNum: self.dataCurrentPage_logInfo,
            pageSize: self.dataPageSize_logInfo
        }
        // 赋值 headers
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            headers = {
                project_id: localStorage.projectUid,
                domain_id: localStorage.domainUid,
            }
        }
        TableCom.init(self, "tableParams_logInfo", [], "logId", GLOBAL_CONFIG.PAGESIZE, '');
        backupJobSrv.getBackupJobInfoLogList(params, headers).then(function (result) {
			if (result && result.data) {
                self.dataTotalNum_logInfo = result.data.totalNum;
		        self.dataLoadData_logInfo = result.data.data;
		        self.dataLoadData_logInfo = operateData_logInfo(self.dataLoadData_logInfo);
                TableCom.init(self, "tableParams_logInfo", self.dataLoadData_logInfo, "logId", GLOBAL_CONFIG.PAGESIZE, '');
			}
		});
	}
    function operateData_logInfo(data){
        data.forEach(x => {
            // 创建时间
            x.logCreateTime = $filter("date")(x.logCreateTime, "yyyy-MM-dd HH:mm:ss");
		});
        return data;
    }
	self.dataChangePage_logInfo=function(options){
        self.dataCurrentPage_logInfo=options.pageNum;
        self.dataPageSize_logInfo=options.pageSize;
        initTables_logInfo(self.changTablesData);
    };

    //server socket
    self.$on("backupJobMonitorTargets", function(e, data) {
        if(data.jobServiceType == "backup-job-monitor-targets"
        ){   
            // 更新列表
            // 判断一下instId; data.exampleId
            if(data.exampleId == $routeParams.instId) {
                self.dataLoadData_progress.forEach(x => {
                    data.resultData.forEach(y => {
                        if(x.id == y.id) {
                            x.status = y.status;
                            // 已完成数据量/总数据量
                            if(y.completeSize == 0 || y.totalSize == 0) {
                                x.progress_a = "0%";
                            }else{
                                if(y.status == 32 ){
                                    //  完成状态直接置100% 
                                    x.progress_a = "100%";
                                }else{
                                    // 如果为历史记录详情
                                    if( $routeParams.tab == 'instance' && y.status == 32) {
                                        x.progress_a = ((y.completeSize/y.completeSize)*100) > 100 ? "100%":"100%";
                                        x.instTotalSize_unit = x.instCompletedData_unit;
                                    }else {
                                        x.progress_a = ((y.completeSize/y.totalSize)*100) > 100 ? "100%": Math.abs(((y.completeSize/y.totalSize)*100).toFixed(2)) + "%";
                                    }
                                }
                            }
                            // completeSize 已完成数据量
                            x.instCompletedData_unit = unitData(y.completeSize);
                            x.instTotalSize_unit = unitData(y.totalSize);
                            if(y.status == 32 ){
                                // 完成状态直接置100% 
                                x.instCompletedData_unit = unitData(y.totalSize);
                            }
                            // instTotalSize 总数据量
                            // if( $routeParams.tab == 'instance') {
                            //     // 历史记录就100%
                            //     x.instTotalSize_unit = x.instCompletedData_unit;
                            // }
                        } 
                    });
                    
                });
            }
        }
    });
};
export {dragBackupJobInstanceInfoCtrl}