
var backupRecoverJobSrvModule = angular.module("backupRecoverJobSrvModule",[]);

backupRecoverJobSrvModule.service("backupRecoverJobSrv",["$http", function($http){
    return{
        // 任务恢复列表
        getBackupRecoverList:function(params){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-recover-job/list",
                params: params,
            });           
        },
        // 创建--任务下拉
        getBackupJobList:function(params, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-recover-job/jobs/list",
                params: params,
                headers
            });           
        },
        // 创建--时间节点 下拉
        getBackupJobTimeList:function(params, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-recover-job/time/points/list",
                params: params,
                headers
            });           
        },
        // 创建--云主机列表
        getInstList:function(params, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-recover-job/time/point/list",
                params: params,
                headers
            });           
        },
        // 创建--数据备份
        createRecoverJob:function(data, headers){
            return $http({
                method: "POST",
                url: "/awstack-backup/v1/backup/backup-recover-job/create",
                data: data,
                headers
            });           
        },
        // 数据恢复详情 -- 执行列表
        getRecoverRunInfo:function(params, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-recover-job/instance/list",
                params: params,
                headers
            });           
        },
        // 数据恢复详情 -- 日志列表
        getRecoverLogInfo:function(params, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-recover-job/instance/log/list",
                params: params,
                headers
            });           
        },
        // 停止数据恢复任务
        stopRecoverJob:function(data, headers){
            return $http({
                method: "POST",
                url: "/awstack-backup/v1/backup/backup-recover-job/stop",
                data: data,
                headers
            });           
        },
        // 删除数据恢复任务
        deleteRecoverJob:function(data, headers){
            return $http({
                method: "delete",
                url: "/awstack-backup/v1/backup/backup-recover-job/delete",
                params: data,
                headers
            });           
        },
    };
}]);