angular.module('fileStorageSrvModel',[]).service('fileStorageSrv',function($http){
    return {
        // 挂载文件存储
        getFileList: function(data) {
            return $http({
                url:'/awstack-resource/v1/fileStorage',
                method: "post",
                data
            })
        },
        // 修改文件存储
        updateFileStorage(data){
            return $http({
                url:'/awstack-resource/v1/fileStorage',
                method:'put',
                data
            })
        },
        // 删除文件存储
        deleteFileStorage(idStr){
            return $http({
                url:'/awstack-resource/v1/fileStorage?ids='+`${idStr}`,
                method:'delete',
            })
        },
        // 新增文件存储配额
        addFileStorage(data){
            return $http({
                url:'/awstack-resource/v1/fileStorageQuota',
                method:'post',
                data
            })
        },
        // 修改文件存储配额
        addFileStorageQuota(data){
            return $http({
                url:'/awstack-resource/v1/fileStorageQuota',
                method:'put',
                data
            })
        },
        // 查询文件存储
        getFileStorageQuota(params){
            return $http({
                url:'/awstack-resource/v1/fileStorage',
                method:'get',
                params
            })
        },
        // 查询文件存储dss
        getFileStorageDSS(){
            return $http({
                url:'/awstack-resource/v1/fileStorage/infos',
                method:'get'
            })
        },
        // 文件存储协议
        getFileAgreement(){
            return $http({
                url:'/awstack-resource/v1/fileStorage/protocols',
                method:'get'
            })
        },
        downCertificate(){
            return $http({
                url:'/awstack-user/v2/csp/storage/downFuseKeyringFile',
                method:'get',
                headers:{
                    project_id:localStorage.getItem('projectUid')
                }
            })
        }
    }
})