
var CertificateService = angular.module("loadbalancingcertificateModuleSrv", []);
CertificateService.service("loadbalancingcertificateModuleSrv", function($rootScope, $http) {
    return {
        // 新建证书
        addCertificate:function(data){
            return $http({
                method: "post", 
                url: "/awstack-manage/v2/manage/uploadSecrets",
                data,
            });
        },
        // 获取证书列表
        getCertificateList:function(params,type){
            const obj = {
                project_id:localStorage.getItem('projectUid'),
                domain_name:localStorage.getItem('domainName'),
                project_name:localStorage.getItem('projectName'),
                domain_id:localStorage.getItem('domainUid')
            }
            return $http({
                method: "get", 
                url: "/awstack-manage/v2/system/secrets",
                params,
                headers: !type && obj
            });
        },
        // 删除证书
        deleteCertificateList:function(data){
            return $http({
                method:'delete',
                url:`/awstack-manage/v2/manage/deleteSecrets?${data}`,
            })
        },
        // 编辑证书
        updateCertificateList:function(data){
            return $http({
                method:'put',
                url:`/awstack-manage/v2/manage/updateSecrets`,
                data
            })
        },
        // 获取容器证书
        getVesselCertificate:function(data){
            return $http({
                method:'get',
                url:`/awstack-manage/v2/system/certificate/${data}`,
            })
        }
    };
});