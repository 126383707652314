storageInitList.$inject = ["$translate"];
function storageInitList($translate){
    function sortNum(a,b){
        return a.sort - b.sort;
    }
    this.init = function(){
        //license 控制 =20
        let objPool = { 
            id:"2",
            sort:2,
            type:"object",
            name:$translate.instant("aws.system.storagement.storageTranslate.poolStatusMap.2")
        }
        //license 控制 =22
        let filePool = { 
            id:"3",
            sort:3,
            type:"file",
            name:$translate.instant("aws.system.storagement.storageTranslate.poolStatusMap.3")
        }
        //license 控制 =25
        let csp = { 
            id:"object_CSP",
            sort:2,
            value:2,
            name:"CSP"
        };
        let data = {
            //存储用途
            "storageTypeList":[
                { 
                    id:"1",
                    sort:0,
                    type:"backup",
                    name:$translate.instant("aws.system.storagement.storageTranslate.poolStatusMap.1")
                }, 
                {  
                    id:"0",
                    sort:1,
                    type:"block",
                    name:$translate.instant("aws.system.storagement.storageTranslate.poolStatusMap.0")
                }, 
                { 
                    id:"4",
                    sort:4,
                    type:"local",
                    name:$translate.instant("aws.system.storagement.storageTranslate.poolStatusMap.4")
                }
            ],
            //存储厂商
            "storageVendor":{
                "backup":[],
                "block":[
                    {
                        id:"insideCeph",
                        name:$translate.instant("aws.system.storagement.storageEnumeration.insideCeph")
                    }, {
                        id:"outsideCeph",
                        name:$translate.instant("aws.system.storagement.storageEnumeration.outsideCeph")
                    }, {
                        id:"toyou",
                        name:$translate.instant("aws.system.storagement.storageEnumeration.toyou")
                    }, {
                        id:"ruijie",
                        name:$translate.instant("aws.system.storagement.storageEnumeration.ruijie")
                    }, {
                        id:"iscsi",
                        name:$translate.instant("aws.system.storagement.storageEnumeration.iscsi")
                    }, {
                        id:"fc",
                        name:$translate.instant("aws.system.storagement.storageEnumeration.fc")
                    }, {
                        id:"nfs",
                        name:"NFS"
                    }
                ],
                "object":[],
                "file":[],
                "local":[]
            },
            "storageDeviceList":{
                "backup":[
                    { 
                        id:"NFS",
                        name:"NFS"
                    }, { 
                        id:"ceph",
                        name:"ceph"
                    }
                ],
                "block":{
                    "insideCeph":[]
                },
                "object":[
                    { 
                        id:"object_ceph",
                        sort:1,
                        value:1,
                        name:$translate.instant("aws.system.storagement.storageTranslate.object_ceph")
                    },
                    { 
                        id:"object_swift",
                        sort:4,
                        value:4,
                        name:"swift"
                    }
                ],
                "file":[],
                "local":[]
            }

        }
        if(JSON.parse(localStorage.regionBusiAuth).indexOf('20')>-1){
            data.storageTypeList.push(objPool);
            if(JSON.parse(localStorage.regionBusiAuth).indexOf('25')>-1){
                data.storageDeviceList.object.push(csp);
            }
            data.storageDeviceList.object.sort(sortNum);
        }
        if(JSON.parse(localStorage.regionBusiAuth).indexOf('22')>-1){
            //文件存储，当前只支持csp的页面对接 当license有文件存储和csp时，才允许对接csp的文件存储
            if(JSON.parse(localStorage.regionBusiAuth).indexOf('25')>-1){
                data.storageTypeList.push(filePool);
            }
        }
        data.storageTypeList.sort(sortNum);
        return data;
    }
}
export {storageInitList}
