deleteConfigCtrl.$inject = ['$scope','$uibModal','initConfig','serviceConfigSrv','$translate','$rootScope','$timeout','$http','$route']
function deleteConfigCtrl($scope,$uibModal,initConfig,serviceConfigSrv,$translate,$rootScope,$timeout,$http,route) {

    var self = $scope;

    //删除配置方法
    self.backupConfigDelete = function(){
        var id = self.backupData.id
        // 请求保存配置接口
        serviceConfigSrv.deleteConfigWay(id).then(function (result){
            if(result && result.status == 0 && result.data!=null){
                self.$dismiss();
                initConfig()
            }else{
                self.$dismiss();
                initConfig()
            }
        })
    }
}

export{deleteConfigCtrl};