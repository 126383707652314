import "./vmHostSrv";
import "../../cvm/instances/instancesSrv";
import { PiePanelDefault } from "../../chartpanel";
import { AreaPanelDefault } from "../../chartpanel";
import { vmInsDetailChartDefault } from "../../chartpanel";
import { hostAreaChartSqlParams } from "../../chartpanel";
import { vmStatusColor } from "../../chartpanel";

angular.module("vmHostModule", ["ngSanitize", "ngTable", "instancesSrv", "usersrv", "vmHostSrv"])
    .controller("vmHostCtrl", ["$scope", "$rootScope", "$routeParams", "NgTableParams", "$uibModal", "instancesSrv", "$translate", "GLOBAL_CONFIG","vmhostSrv","commonFuncSrv","$location",
        function($scope, $rootScope, $routeParams, NgTableParams, $uibModal, instancesSrv, $translate, GLOBAL_CONFIG,vmhostSrv,commonFuncSrv,$location) {
            var self = $scope;
            var otherCount = 0;
            self.pageSize = 10;

            //获取饼图虚机统计信息
            function getProStatus(){
                vmhostSrv.getProStatus().then(function(result) {
                    if (result && result.data) {
                        self.vmTotal = 0;
                        self.hostTotal = 0;
                        if(result.data.vmStatus){
                            var vmStatus = result.data.vmStatus;
                            self.vmTotal = vmStatus.total;
                            self.hostTotal = vmStatus.total;
                            self.vmStatusChart = new PiePanelDefault();
                            self.vmStatusChart.panels.pieType = "category";
                            self.vmStatusChart.panels.data = [];
                            self.vmStatusChart.panels.colors = [];
                            var statusType = ['active','shutoff','other'];
                            for(var i=0;i<statusType.length;i++){
                                self.vmStatusChart.panels.data.push({
                                    name: $translate.instant("aws.monitor.virtual.vmhostStatus." + statusType[i].toUpperCase()),
                                    value: vmStatus[statusType[i]],
                                    status: statusType[i]
                                });
                            }
                            let vmStatusColors = vmStatusColor();
                            _.each(self.vmStatusChart.panels.data,item=>{
                                self.vmStatusChart.panels.colors.push(vmStatusColors[item.status]);
                            });
                        }
                        
                        //获取主机分布
                        if(result.data.distribution){
                            var hostData = result.data.distribution;
                            if(localStorage.nodePrettyNameList){
                                self.vmHostChart = new PiePanelDefault();
                                self.vmHostChart.panels.pieType = "category";
                                self.vmHostChart.panels.data = commonFuncSrv.showPrettyNameOfNode(hostData,handleNodeName);
                            }else{
                                commonFuncSrv.showPrettyNameOfNode(hostData,handleNodeName).then(data => {
                                    self.vmHostChart = new PiePanelDefault();
                                    self.vmHostChart.panels.pieType = "category";
                                    self.vmHostChart.panels.data = data;
                                })
                            }
                        }
                    }

                });
            }
            getProStatus();

            self.isShow = true;
            self.linkTo = function(item){
                self.isShow = false;
                Promise.resolve(true).then(res=>{
                    self.isShow = res
                })
                $location.url(`/monitor/vmhost?id=${item.uid}`)
            }

            // 判断是否是promise对象
            function isPromise(obj) {
                return !!obj  //有实际含义的变量才执行方法，变量null，undefined和''空串都为false
                    && (typeof obj === 'object' || typeof obj === 'function') // 初始promise 或 promise.then返回的
                    && typeof obj.then === 'function';
            }

            //获取云主机列表采用真分页
            function getInstance(options,type) {
                if(!type){
                    self.currentPage = 1;
                }
                var pageDefaultOption = {
                    pageSize:self.pageSize,
                    pageNum:1,
                    pattern:null,
                    status:null
                };
                //self.tabledata = [];
                var pageOption = angular.extend(pageDefaultOption,options)
                pageOption.instanceType = "all";// 传all查询云主机和云数据库的数据
                vmhostSrv.getInstanList(pageOption).then(function(result) {
                    if (result && result.data) {
                        //获取状态分布
                        result.data = result.data.filter(item => {
                            item.status = item.status.toLowerCase();
                            return item.projectUid == localStorage.projectUid && item.name != "fixedImageInstanceName";
                        })
                        vmhostSrv.instanceDataList  = result.data;
                        //获取表格数据
                        self.tableParams = new NgTableParams({ count: 9999 }, { counts: [], dataset: result.data });
                        self.totalNum = result.total;
                    }
                    result ? self.loadData = true : "";
                });
            }
            getInstance();
            //切换页码
            self.changePage=function(options){
                // options.pattern = self.globalSearchTerm?self.globalSearchTerm:null
                // options.status = self.statusSelected.searchItem?self.statusSelected.searchItem.id:null
                getInstance(options,'changePage');
            };
            function handleNodeName(data,nodePrettyNameList){
                let list = [];
                for (var vmInfo in data) {
                    list.push({ name: vmInfo, value: data[vmInfo],prettyName:vmInfo});
                }
                list=_.chain(list).sortBy("nameSortNum").value();
                return list;
            }

            $scope.$watch(function() {
                return $routeParams.id;
            }, function(value) {
                $scope.animation = value ? "animateIn" : "animateOut";
                $scope.detailIn = value ? true : false;
                if(value){
                    $scope.$broadcast("vmhostDetail", value);
                    $("body").addClass("animate-open")
                    $(window).scrollTop(0);// 每次打开详情的modal 将滚动条回到顶部； by heliang
                }else{
                    $("body").removeClass("animate-open")
                }
            });
        }
    ])
    .controller("vmhostdetailCtrl", ["$scope", "userDataSrv", "instancesSrv", "vmhostSrv", "$translate", "$rootScope","vmFuncSrv",
        function($scope, userDataSrv, instancesSrv, vmhostSrv, $translate, $rootScope,vmFuncSrv) {
            var self = $scope;
            //获取虚拟机详情的数据
            self.$on("vmhostDetail", function(event, insUid) {
                self.insConfigData = {};
                self.panels = {};
                // self.collectCycleList = [
                //     {name: "5分钟", value: 300},
                //     {name: "1小时", value: 3600},
                //     {name: "1天", value: 86400}
                // ];
                self.timeType = {
                    options: [{
                        "name": $translate.instant('aws.bill.timeType.realTime'),
                        "value": ""
                    }, {
                        "name": $translate.instant('aws.bill.timeType.sixHours'),
                        "value": "6h"
                    }, {
                        "name": $translate.instant('aws.bill.timeType.oneDay'),
                        "value": "1d"
                    }, {
                        "name": $translate.instant('aws.bill.timeType.threeDays'),
                        "value": "3d"
                    }, {
                        "name": $translate.instant('aws.bill.timeType.oneWeek'),
                        "value": "7d"
                    }]
                };
                self.fliterCard = function(data) {
                    if(data && data.length) {
                        var netCard = data.filter(item => item.priority != "c0");
                        return netCard;
                    }
                }
                self.filterData = {
                    insUid:insUid,
                    timeStep: "",
                    from: null,
                    to: null,
                    // precision: self.collectCycleList[0].value,
                    definedTimeText: $translate.instant('aws.bill.chooseDate'),
                    rangeHours:""
                };
                $("#vmDetailLoadingChart").html("");
                vmFuncSrv.getHostAreaChartFunc(self,hostAreaChartSqlParams,AreaPanelDefault); //cpu、内存、磁盘各指标的监控

                if(vmhostSrv.instanceDataList){
                    _.each(vmhostSrv.instanceDataList,function(item){
                        if(item.uid == insUid){
                            self.insConfigData = item;
                        }
                    })
                }else{
                    instancesSrv.getServerDetail(insUid).then(function(result) {
                        if (result && result.data) {
                            self.insConfigData = result.data;
                        }
                    });
                }

                userDataSrv.getRegionData().then(function(res) {
                    if (res && res.data) {
                        self.filterData.regionOptions =  res.data.filter(item => {
                            return item.status == 3;
                        });
                        self.filterData.region_key = self.filterData.regionOptions[0].regionKey;
                        _.each(self.filterData.regionOptions, item => {
                            if (item.regionKey == localStorage.regionKey) {
                                self.filterData.region_key = item.regionKey;
                            }
                        });
                        // libvirt监控没有mem这个字段
                        vmhostSrv.sqlQuery({
                            "sql": "select * from mem where time > now() - 1m AND code = '" + self.filterData.region_key + "' AND host_type = 'virtual' AND \
                                    vm_id = '" + insUid + "'"
                        }).then(function(res) {
                            res?self.loadedQueryData = true:false;
                            if (res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                                self.queryMemNull = false;
                                vmhostSrv.sqlQuery({
                                    "sql": "SELECT MAX(used_percent) AS used_percent FROM swap WHERE time > now() - 30m AND code = '" + self.filterData.region_key + "' \
                                            AND host_type = 'virtual' AND vm_id = '" + insUid + "' GROUP BY time(10s)"
                                }).then(function(res) {
                                    //先查看Linux的是否有数据
                                    if (res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                                        //有数据就用Linux数据渲染
                                        self.linuxSysVmchart = true;
                                        self.winSysVmchart = false;
                                        self.getHostAreaChart(self.filterData.region_key,insUid,self.filterData);
                                    } else {
                                        self.linuxSysVmchart = false;
                                        self.winSysVmchart = true;
                                        self.getHostAreaChart(self.filterData.region_key,insUid,self.filterData,"win");
                                    }
                                });
                            } else {
                                self.queryMemNull = true;
                            }
                        })
                    }
                });
                
                self.$on('cloud_main_type', function(e, value){
                    console.log(value)
                    self.defaultType = value;
                })

                self.query = function(){
                    const dimensionality = self.defaultType == 'average';
                    if(self.linuxSysVmchart) {
                        self.getHostAreaChart(self.filterData.region_key, insUid, self.filterData, '', dimensionality);
                    } else {
                        self.getHostAreaChart(self.filterData.region_key, insUid, self.filterData, "win", dimensionality);
                    }
                };

            });
        }
    ])
