var storageUtilModule = angular.module("storageUtilModule", []);
storageUtilModule.service('storageUtil', [function () {
    let obj = {}
    //object_ceph,处理数据
    obj.compDiskMap = function (cur) {
        /* 
        只做数据处理，保证提交数据格式正确性
        */
        let data = [];
        let nodeUidMap = cur.disks.nodeUidMap;
        let tableData = cur.tableData;
        let type = cur.type;
        let kernelList = [];
        tableData.forEach(item => {
            var org = {
                nodeid: item.nodeUid,
                disk_config: [],
                hostname: item.hostName
            };
            var kernel = {
                nodeid: item.nodeUid,
                hostName: item.hostName,
                kernel: item.kernel
                // kernel: "3.xxx"
            }
            var nn = 0;
            if (nodeUidMap[item.nodeUid]) {
                let diskObj = nodeUidMap[item.nodeUid];
                diskObj.diskMap.forEach(item => {
                    let arr = {
                        ceph_osd: [],
                        ceph_ssd: [],
                        mode: "journal_collocation",
                        root: ""
                    }
                    arr.ceph_ssd = item.sourceList.map(it => {
                        let ssd = ''
                        if (type == 'path') {
                            ssd = "/dev/" + it.name
                        } else {
                            ssd = it.byId?it.byId:''
                        }
                        return ssd;
                    }).join(",");
                    arr.ceph_osd = item.dataList.map(it => {
                        let osd = ''
                        if (type == 'path') {
                            osd = "/dev/" + it.name
                        } else {
                            osd = it.byId?it.byId:''
                        }
                        return osd;
                    })
                    // if(type == 'path'){
                    //     arr.ceph_osd = arr.ceph_osd.join(",")
                    // }
                    if(arr.ceph_osd.length>0||arr.ceph_ssd){
                        nn++
                    }
                    org.disk_config.push(arr);
                })
                if(nn){
                    kernelList.push(kernel);
                }
                
            } else {
                let arr = {
                    "ceph_osd": [],
                    "ceph_ssd": "",
                    "mode": "journal_collocation",
                    "root": ""
                }
                // if(type == 'path'){
                //     arr.ceph_osd = "";
                // }
                org.disk_config.push(arr);
            }
            data.push(org);
        })
        return {data,kernelList};
    }

    //object_ceph 校验数据
    obj.checkDiskMsg = function (data, diskData) {
        /* 
        1.计算配置了ceph_osd字段的节点数，必须大于副本数，强校验，不允许强制提交
        2.判断不同节点间配置的元数据盘和数据盘的映射关系是否一致，如果不一致，抛错提示，可以强制提交
        3.判断节点配置的磁盘是否符合规则，如果不符合，抛错提示，可以强制提交
        */
        let num = 0,arr = [],flag,setMap = {};
        data.forEach(item => {
            let diskConfig = item.disk_config[0];
            //保存元数据盘和数据盘的映射关系:0-0,1-0,0-1,1-1
            let curSet = (diskConfig.ceph_ssd ? 1 : 0) + "-" + (diskConfig.ceph_osd.length>0 ? 1 : 0);
            if (diskConfig && diskConfig.ceph_osd.length > 0) {
                num++
            }
            if (curSet != "0-0" && arr.indexOf(curSet) < 0) {
                arr.push(curSet);
            }
            if(!setMap[curSet]){
                setMap[curSet] = [item.hostname]
            }else{
                setMap[curSet].push(item.hostname);
            }
        })
        flag = diskData.valid;
        return {
            num,
            arr,
            flag,
            setMap
        };
    }
    //校验磁盘是否可以批量配置
    obj.checkDiskConfig = function (checkedItems) {
        var checkDiskData = [],isDiskChecked = false;
        checkedItems.forEach(item => {
            let arr = [];
            arr = item.diskAttributesList.map(it => {
                return {
                    "capacity": it.capacity,
                    "name": it.name,
                    "ssd": it.ssd
                }
            })
            checkDiskData.push(arr);
        })
        for (var i = 0; i < checkDiskData.length - 1; i++) {
            var firstDisk = JSON.stringify(checkDiskData[i]);
            var elseDisk = JSON.stringify(checkDiskData[i + 1]);
            if (firstDisk != elseDisk) {
                isDiskChecked = true;
                break;
            }
        }
        return isDiskChecked;
    }
    //磁盘单位转化,传入值为(xx xB),将磁盘大小转化为GB类型并去掉单位
    obj.changeUnit = function (v) {
        if (v.search(/TB$/i) > -1) {
            return Number(v.replace(/TB$/i, "")) * 1024;
        } else if (v.search(/PB$/i) > -1) {
            return Number(v.replace(/PB$/i, "")) * 1024 * 1024;
        } else if (v.search(/GB$/i) > -1) {
            return Number(v.replace(/GB$/, ""));
        } else if (v.search(/MB$/i) > -1) {
            return parseInt(Number(v.replace(/MB$/i, "")) / 1024);
        } else if (v.search(/KB$/i) > -1) {
            return parseInt(Number(v.replace(/KB$/i, "")) / 1024 / 1024);
        }
        return 0;
    }
    //单位转化，传入值为数字，GB单位
    obj.setUnit = function (v) {
        if (parseInt(v / (1024 * 1024)) > 0) {
            return {
                total: (v / (1024 * 1024)).toFixed(2),
                unit: "PB"
            };
        } else if (parseInt(v / 1024) > 0) {
            return {
                total: (v / 1024).toFixed(2),
                unit: "TB"
            };
        }
        return {
            total: v.toFixed(2),
            unit: "GB"
        };
    }
    //按ip排序
    obj.sortNumber = function(a,b){
        return _IP.toLong(a.hostInfoMap.ip) - _IP.toLong(b.hostInfoMap.ip);
    }

    //判断节点内核版本
    /* 
    @ checkedItem
    @ return{
        errorNode, 节点名称
        flag, true:所选节点内核版本不匹配
    }
    */
    obj.checkKernel = function(checkedItem){
        let reg = /^3\./;//3.xx 版本不支持bcache
        let kernelText = [];
        checkedItem.forEach(item=>{
            let kernel = item.kernel||4;
            let num = Number(kernel[0]);
            if(num<4){
                kernelText.push(item.hostName)
            }
        })
        return {
            errorNode:kernelText.join(","),
            flag:kernelText.length>0?true:false
        }
    }
	return obj;
}])