viewSecRuleCtrl.$inject = ["$scope", "$translate","$uibModalInstance","$location","context","securityGroup","NgTableParams","securityGroupSrv"];
function viewSecRuleCtrl($scope, $translate, $uibModalInstance, $location, context, securityGroup, NgTableParams,securityGroupSrv){
    let self = $scope;
    function initSecurityGroupRuleTable(options,changePage){
        if(!changePage) self.curPage = 1;
        let pageDefaultOption = {
            pageSize: 10,
            pageNum: 1,
            likes:'',
            direction:''
        }
        let pageOption = angular.extend(pageDefaultOption,options);
        securityGroupSrv.getFirewallRuleDetail(pageOption,securityGroup.id).then(function(result) {
            result ? self.loadSecRuleData = true : "";
            if (result && result.data) {
                var data = _.map(result.data, function(item) {
                    if (item.direction == "ingress") {
                        item.direc = $translate.instant("aws.security.ingress");
                    }
                    if (item.direction == "egress") {
                        item.direc = $translate.instant("aws.security.egress");
                    }
                    item.protocol = item.protocol ? item.protocol : $translate.instant("aws.security.any");
                    item.typeCode = item.protocol == "icmp"&&(item.portRangeMin||item.portRangeMin==0)&&(item.portRangeMax||item.portRangeMax==0) ? item.portRangeMin + " / " + item.portRangeMax : " - ";
                    item.sourceType = item.remoteIpPrefix || item.remoteGroupName || $translate.instant("aws.security.any");
                    return item;
                });
                self.secRuleTable = new NgTableParams({
                    count: 10
                }, {
                    counts: [],
                    dataset: data
                });
                self.detailTotalNum = result.total;
            }
        });
    };
    initSecurityGroupRuleTable();
    self.changeDetailPage = function(options){
        initSecurityGroupRuleTable(options,'changePage');
    };
}

export { viewSecRuleCtrl };