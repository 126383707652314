import * as controllers from "./controllers";
import * as services from "./services";

let logoSettingModule = angular.module("logoSettingModule", ["rzModule"]);

logoSettingModule.config(["$controllerProvider", function($controllerProvider){
	$controllerProvider.register(controllers);
}]);

logoSettingModule.config(["$provide", function($provide){
	$provide.service(services);
}]);