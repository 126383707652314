recoveryCtrl.$inject=["$scope", "$rootScope", "$translate","$uibModal","$location","recoverySrv","TableCom","$filter","$routeParams","GLOBAL_CONFIG","utilTool","$timeout","newCheckedSrv"];
recoveryBackupCtrl.$inject = ["$scope", "$rootScope", "$translate","$uibModal","$location","recoverySrv","TableCom","$filter","GLOBAL_CONFIG","newCheckedSrv"];
rBackupDetailCtrl.$inject = ["$scope", "$rootScope", "$translate","$uibModal","$location","recoverySrv","TableCom","$filter","GLOBAL_CONFIG"];
rBackupRecordDetailCtrl.$inject = ["$scope", "$rootScope", "$translate","$uibModal","$location","recoverySrv","TableCom","$filter","GLOBAL_CONFIG"];
function recoveryCtrl($scope, $rootScope, $translate,$uibModal,$location,Srv,TableCom,$filter,$routeParams,GLOBAL_CONFIG,utilTool,$timeout,newCheckedSrv){
    var self = $scope;
    self.showBackupsType = 'system';
    self.systemPageSize=10;
    self.systemCurrentPage=1;
    self.dataPageSize=10;
    self.dataCurrentPage=1;
    self.search={};
    self.systemRecoveryCheckboxes={
        items:{},
        checked:false
    };
    self.dataRecoveryCheckboxes={
        items:{},
        checked:false
    };
    self.handleBtn = {
        dataRecovery:false,
        systemRecovery:false
    };
    
    switch($routeParams.type){
        case "system":
            self.showBackupsType = "system";
            break;
        case "data":
            self.showBackupsType="data";
            break;
    }
    if(localStorage.permission == 'enterprise'){
        self.serviceMenus = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')]
    }else{
        self.serviceMenus = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_ConfigManagement'),$translate.instant('aws.menu.System_Storage')]
    }
    Srv.backupType = self.showBackupsType;

    self.headers={
        name:$translate.instant("aws.recovery.header.name"),
        backupType:$translate.instant("aws.recovery.header.backupMode"),
        instanceName:$translate.instant("aws.recovery.header.instanceName"),
        ipsArr:$translate.instant("aws.recovery.header.ipaddress"),
        volumeName:$translate.instant("aws.recovery.header.diskName"),
        volumeSize:$translate.instant("aws.recovery.header.diskSize"),
        backupMode:$translate.instant("aws.recovery.header.backupMode"),
        status_tr:$translate.instant("aws.recovery.header.backupStatus"),
        previousFireTime:$translate.instant("aws.recovery.header.currentBackupTime"),
        nextFireTime:$translate.instant("aws.recovery.header.nextBackupTime"),
        systemDiskSize:$translate.instant("aws.recovery.header.systemDiskSize"),
        createTime:$translate.instant("aws.recovery.header.backupTime"),
        domain:'部门',
        project:'项目'
    };

    //资源导出
    self.exportResource = function(){
        if (self.search.systemGlobalSearchTerm) {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&likes=${encodeURI(self.search.systemGlobalSearchTerm)}&job=false&system=true`
            };
        } else {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&job=false&system=true`
            };
        }
        Srv.getExportUrl(option).then(res => {
            if (res && res.data) {
                var url = res.data;
                utilTool.downloadFile(url);
            }
        });
    };
    //数据盘备份资源导出
    self.dataExportResource = function(){
        if (self.search.dataGlobalSearchTerm) {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&likes=${encodeURI(self.search.dataGlobalSearchTerm)}&job=false&system=false`
            };
        } else {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&job=false&system=false`
            };
        }
        Srv.getExportUrl(option).then(res => {
            if (res && res.data) {
                var url = res.data;
                utilTool.downloadFile(url);
            }
        });
    };
    self.showExport = utilTool.isAllDomainSelect;

    //选择全部时，列表中部门和项目的处理
    self.$watch(function(){
        return localStorage.selectedAllDomain;
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self);
    });

    self.$on("backupSocket", function(e, data) {
        if(self.showBackupsType=='system'){
            //主列表的刷新
            if(self.systemRecoveryData&&self.systemRecoveryData.length){
                self.systemRecoveryData.map(function(obj) {
                    if (data.eventMata&&data.eventMata.backup_job_id) {
                        if (obj.id === data.eventMata.backup_job_id) {
                            if (data.eventType == "backup.delete.end") {
                                self.refreshSystemTable();
                            }
                        }
                        if(data.eventType == "backup.create.end"){
                            $timeout(function(){
                                self.refreshSystemTable();
                            },1000);
                        }
                        if(data.eventType == "backup.restore.end"
                        ||data.eventType == "backup.reset_status.end"
                        ||data.eventType == "backup.restore.start"
                         ){
                            self.refreshSystemTable();
                        }
                    }
                });
            }
        }else{
            if(self.dataRecoveryData&&self.dataRecoveryData.length){
                self.dataRecoveryData.map(function(obj) {
                    if (data.eventMata&&data.eventMata.backup_job_id) {
                        if (obj.id === data.eventMata.backup_job_id) {
                            if (data.eventType == "backup.delete.end") {
                                self.refreshDataTable();
                            }
                        }
                        if(data.eventType == "backup.create.end"){
                            $timeout(function(){
                                self.refreshDataTable();
                            },1000);
                        }
                        if(data.eventType == "backup.restore.end"
                        ||data.eventType == "backup.reset_status.end"
                        ||data.eventType == "backup.restore.start"
                         ){
                            self.refreshDataTable();
                        }
                    }
                });
            }
        }
        
    });

    self.getBackupsType = function(type){
        self.showBackupsType = type;
        Srv.backupType = type;
        if(type=='data'){
            self.search.dataGlobalSearchTerm="";
            self.titleName="backupsRecoveryData";
            if(sessionStorage["backupsRecoveryData"]){
               self.tableCols=JSON.parse(sessionStorage["backupsRecoveryData"]);
            }else{
               self.tableCols=[
                     { field: "check", title: "",headerTemplateURL:"dataRecoveryHeaderCheckbox.html",show: true },
                     { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                     { field: "backupType", title: self.headers.backupType,sortable: "backupType",show: true,disable:false},
                     { field: "instanceName", title: self.headers.instanceName,sortable: "instanceName",show: true,disable:false},
                     { field: "ipsArr", title: self.headers.ipsArr,sortable: "ipsArr",show: true,disable:false},
                     { field: "volumeName", title: self.headers.volumeName,sortable: "volumeName",show: true,disable:false},
                     { field: "volumeSize", title: self.headers.volumeSize,sortable: "volumeSize",show: true,disable:false},
                     { field: "status_tr", title: self.headers.status_tr,sortable: "status_tr",show: true,disable:false},
                     { field: "createTime", title: self.headers.createTime,sortable: "createTime",show: true,disable:false},
               ];
            }
            initDataTable();
        }else if(type=='system'){
            self.search.systemGlobalSearchTerm="";
            self.titleName="backupsRecoverySystem";
            if(sessionStorage["backupsRecoverySystem"]){
               self.tableCols=JSON.parse(sessionStorage["backupsRecoverySystem"]);
            }else{
                self.tableCols=[
                     { field: "check", title: "",headerTemplateURL:"systemRecoveryHeaderCheckbox.html",show: true },
                     { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                     { field: "backupType", title: self.headers.backupType,sortable: "backupType",show: true,disable:false},
                     { field: "instanceName", title: self.headers.instanceName,sortable: "instanceName",show: true,disable:false},
                     { field: "volumeSize", title: self.headers.systemDiskSize,sortable: "volumeSize",show: true,disable:false},
                     { field: "ipsArr", title: self.headers.ipsArr,sortable: "ipsArr",show: true,disable:false},
                     { field: "status_tr", title: self.headers.status_tr,sortable: "status_tr",show: true,disable:false},
                     { field: "createTime", title: self.headers.createTime,sortable: "createTime",show: true,disable:false},
               ];
            }
            initSystemTable();
        }
        utilTool.projectDomainTableCol(localStorage.selectedAllDomain,2,self);
    };

    //数据盘备份
    var initDataTable=function(options){
        if(!localStorage.cinderService ||  !localStorage.backupsService) return;
        TableCom.init(self,'dataRecoveryTable',[],"id",GLOBAL_CONFIG.PAGESIZE,'dataRecoveryCheckboxes');
        let defaultParams = {
            likes:self.search.dataGlobalSearchTerm,
            pageNum:1,
            pageSize:self.dataPageSize
        };
        if(!options){
            self.dataCurrentPage=1;
        }
        var params=options?options:defaultParams;
        params.system=false;
        self.dataLoadData  = '';
        Srv.getBackupsList(params).then(function(result){
            if(result&&result.data&&angular.isArray(result.data)){
                self.dataTotalNum=result.total;
                self.dataLoadData= result.data;
                successFunc(result.data);
            }
        });
    };

    self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
        initDataTable(options);
    };

    self.systemChangePage=function(options){
        self.systemCurrentPage=options.pageNum;
        self.systemPageSize=options.pageSize;
        initSystemTable(options);
    };

    //系统盘备份
    var initSystemTable=function(options){
        if(!localStorage.cinderService ||  !localStorage.backupsService) return;
        TableCom.init(self,'systemRecoveryTable',[],"id",GLOBAL_CONFIG.PAGESIZE,'systemRecoveryCheckboxes');
        let defaultParams = {
            likes:self.search.systemGlobalSearchTerm,
            pageNum:1,
            pageSize:self.systemPageSize
        };
        if(!options){
            self.systemCurrentPage=1;
        }
        var params=options?options:defaultParams;
        params.system=true;
        self.systemLoadData = '';
        Srv.getBackupsList(params).then(function(result){
            if(result&&result.data&&angular.isArray(result.data)){
                self.systemTotalNum=result.total;
                self.systemLoadData= result.data;
                successFunc(result.data);
            }
        });
    };
    self.getBackupsType('system');
    
    //数据处理
    function successFunc(data){
        self[self.showBackupsType+'RecoveryData']=data.map(function(item){
            item.backupMode=item.incremental?$translate.instant("aws.backups.table.isIncremental.true"):$translate.instant("aws.backups.table.isIncremental.false");
            item.backupType=item.jobType?$translate.instant("aws.backups.timingBackup"):$translate.instant("aws.backups.timingTaskBackup");
            item.status_tr=$translate.instant("aws.backups.table.status_new_mainRecovery_table."+item.status);
            item.createTime=$filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
            item.previousFireTime=$filter("date")(item.previousFireTime, "yyyy-MM-dd HH:mm:ss");
            item.nextFireTime=$filter("date")(item.nextFireTime, "yyyy-MM-dd HH:mm:ss");
            item.ipsArr=item.ips?item.ips.split(","):[];
            if(item.domainName=="default"){
                item.domainName_TR = $translate.instant("aws.common.defaultDepar");
            }else{
                item.domainName_TR = item.domainName;
            }
            if(item.projectName=="admin"){
                item.projectName_TR = $translate.instant("aws.common.defaultProject");
            }else{
                item.projectName_TR = item.projectName;
            }
            if(!item.jobType&&angular.isObject(item.backupPolicy)){
                item.backupPolicy.createTime = $filter("date")(item.backupPolicy.createTime, "yyyy-MM-dd HH:mm:ss");
                if(item.backupPolicy.unit=='once'){
                    item.backupPolicy.frequency_des="1"+$translate.instant("aws.backupStrategy."+item.backupPolicy.unit);
                }else{
                    item.backupPolicy.frequency_des="按"+$translate.instant("aws.backupStrategy."+item.backupPolicy.unit)+",每隔"+item.backupPolicy.frequency+$translate.instant("aws.backupStrategy."+item.backupPolicy.unit)+"执行一次";   
                }
            }
            return item;
        });
        if(self.showBackupsType=='system'){
            TableCom.init(self,'systemRecoveryTable',self[self.showBackupsType+'RecoveryData'],"id",GLOBAL_CONFIG.PAGESIZE,'systemRecoveryCheckboxes');
        }else{
            TableCom.init(self,'dataRecoveryTable',self[self.showBackupsType+'RecoveryData'],"id",GLOBAL_CONFIG.PAGESIZE,'dataRecoveryCheckboxes');
        }
    }

    //系统盘备份刷新
    self.refreshSystemTable=function(){
       self.search.systemGlobalSearchTerm="";
       self.systemPageSize=10;
       self.systemCurrentPage=1;
       initSystemTable();
    };
    
    //数据盘备份刷新
    self.refreshDataTable=function(){
       self.search.dataGlobalSearchTerm="";
       self.dataPageSize=10;
       self.dataCurrentPage=1;
       initDataTable();
    };

    //系统盘后端搜索
    self.systemSearchBackend = function(options){
        self.systemCurrentPage = 1;
        var pageOption = {
          pageSize:self.systemPageSize,
          pageNum:1,
          likes:self.search.systemGlobalSearchTerm?self.search.systemGlobalSearchTerm:""
        };
        if(options.type=='refresh'){
          self.search.systemGlobalSearchTerm = "";
          pageOption.likes = "";
        }
        initSystemTable(pageOption);
    };

    //数据盘后端搜索
    self.dataSearchBackend = function(options){
        self.dataCurrentPage = 1;
        var pageOption = {
          pageSize:self.dataPageSize,
          pageNum:1,
          likes:self.search.dataGlobalSearchTerm?self.search.dataGlobalSearchTerm:""
        };
        if(options.type=='refresh'){
          self.search.dataGlobalSearchTerm = "";
          pageOption.likes = "";
        }
        initDataTable(pageOption);
    };

    //数据盘备份监控
    self.dataWatched = '';
    function dataWatchCheck(self) {
        var watch = self.$watch(function() {
            return self.dataRecoveryCheckboxes.items;
        }, function(val) {
            self.handleBtn.dataRecovery= false;
            self.dataCheckedItems = [];
            var arr = [];
            for (var i in self.dataRecoveryCheckboxes.items) {
                arr.push(self.dataRecoveryCheckboxes.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.dataRecoveryData.forEach(item => {
                            if (item.id == key) {
                                self.dataCheckedItems.push(item);
                            }
                        });
                    }
                }
            }
            utilTool.setLocalStorage(self.dataCheckedItems);
            if (self.dataCheckedItems.length == 1&&self.dataCheckedItems[0].restore) {
                self.handleBtn.dataRecovery= true;
            } 
        }, true);  
        return watch;
    }
    if (self.dataWatched) {
        self.dataWatched();
    }
    self.dataWatched = dataWatchCheck(self);

    //数据盘备份监控
    self.systemWatched = '';
    function systemWatchCheck(self) {
        var watch = self.$watch(function() {
            return self.systemRecoveryCheckboxes.items;
        }, function(val) {
            self.handleBtn.systemRecovery= false;
            self.systemCheckedItems = [];
            var arr = [];
            for (var i in self.systemRecoveryCheckboxes.items) {
                arr.push(self.systemRecoveryCheckboxes.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.systemRecoveryData.forEach(item => {
                            if (item.id == key) {
                                self.systemCheckedItems.push(item);
                            }
                        });
                    }
                }
            }
            utilTool.setLocalStorage(self.systemCheckedItems);
            if (self.systemCheckedItems.length == 1&&self.systemCheckedItems[0].restore) {
                self.handleBtn.systemRecovery= true;
            }
        }, true);  
        return watch;
    }
    if (self.systemWatched) {
        self.systemWatchCheck();
    }
    self.systemWatched = systemWatchCheck(self);

    //恢复备份弹出
    self.recovery = function(type) {
        self.recoveryAnimation = "animateIn";
        $("body").addClass("animate-open")
    };
    self.recoveryAnimation = "animateOut";

    //关闭备份恢复弹出框
    self.closeRecovery = function(){
        self[self.showBackupsType+'RecoveryCheckboxes'].items={};
        self.recoveryAnimation = "animateOut";
        $("body").removeClass("animate-open");
    };
    
    //备份详情中的tab切换
    self.changeDetailTab = function(type){
        self.detailType = type;
    };

    $scope.$on("getDetail", function(event, value) {
        self.changeDetailTab('backup');
    });
    
    //备份详情关闭
    self.returnFromBackupRecoveryDetail=function(){
        $location.url('/cvm/recovery');
        self.detailType="";
    };

    self.$on('closeBackupRecovery',function(v,data){
        self.closeRecovery();    
    });
}
function recoveryBackupCtrl($scope, $rootScope, $translate,$uibModal,$location,Srv,TableCom,$filter,GLOBAL_CONFIG,newCheckedSrv){
    let self = $scope;
    self.pageSize=10;
    self.currentPage=1;
    self.checkedBackupJob=self[self.showBackupsType+'CheckedItems'][0];
    self.recoveryBackupTableCheckboxes={
        items:{},
        checked:false
    };
    self.btn={};
    var initTable=function(options){
        var pageDefaultOption = {
            pageSize: self.pageSize,
            pageNum:1,
            likes:''
        };
        if(!options){
            self.currentPage=1;
        }
        var pageOption = angular.extend(pageDefaultOption,options);
        TableCom.init(self,'recoveryBackupTable',[],"id",self.pageSize,'recoveryBackupTableCheckboxes');
        self.backupsDetailTableData  = '';
        Srv.getBackupsContainList(self.checkedBackupJob.id,pageOption).then(function(result){
            if(result&&result.data){
                self.totalNum = result.total;
                self.recoveryBackupTableData= result.data;
                successFunc(result.data);
            }
        });
    };
    initTable();

    function successFunc(data){
        let increArr=[],allArr=[];
        self.recoveryBackupTableData=data.map(function(item,index){
            item.backupMode=item.isIncremental?$translate.instant("aws.backups.table.isIncremental.true"):$translate.instant("aws.backups.table.isIncremental.false");
            item.status_tr=$translate.instant("aws.backups.table.status_new."+item.status);
            item.createTime=$filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
            if(index==0){
                item.isFirst=true;
            }
            if(index==self.recoveryBackupTableData.length-1){
                item.isLast=true;
            }
            return item;
        });
        TableCom.init(self,'recoveryBackupTable',self.recoveryBackupTableData,"id",self.pageSize,'recoveryBackupTableCheckboxes');
    }

    self.changePage=function(options){
        self.currentPage=options.pageNum;
        self.pageSize=options.pageSize;
        initTable(options);
    };

    //数据盘备份监控
    self.recoveryBackupWatched = '';
    function recoveryBackupWatchCheck(self) {
        var watch = self.$watch(function() {
            return self.recoveryBackupTableCheckboxes.items;
        }, function(val) {
            self.btn.canRecovery=false;
            self.recoveryBackupCheckedItems = [];
            var arr = [];
            for (var i in self.recoveryBackupTableCheckboxes.items) {
                arr.push(self.recoveryBackupTableCheckboxes.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.recoveryBackupTableData.forEach(item => {
                            if (item.id == key) {
                                self.recoveryBackupCheckedItems.push(item);
                            }
                        });
                    }
                }
            }
            if (self.recoveryBackupCheckedItems.length == 1&&self.recoveryBackupCheckedItems[0].status=='available') {
                self.btn.canRecovery=true;
            } 
        }, true);  
        return watch;
    }
    if (self.recoveryBackupWatched) {
        self.recoveryBackupWatched();
    }
    self.recoveryBackupWatched = recoveryBackupWatchCheck(self);
    //取消备份恢复弹出框
    self.closeBackupRecovery=function(){
        self.$emit('closeBackupRecovery',self.recoveryBackupCheckedItems);
    };
    self.backupRecoveryConfirm=function(){
        newCheckedSrv.setChkIds([self.recoveryBackupCheckedItems[0].jobId],"backuprestore");
        Srv.backupRecovery(self.recoveryBackupCheckedItems[0].uid).then(function(){
            self.closeBackupRecovery();
        });
    };

    self.$on("backupSocket", function(e, data) {
        //若点击详情，详情也要更新状态
        if(self.recoveryAnimation=='animateIn'&&self.recoveryBackupTableData&&angular.isArray(self.recoveryBackupTableData)&&self.recoveryBackupTableData.length){
           self.recoveryBackupTableData.map(function(obj){
                if(data.eventMata&&data.eventMata.backup_job_id&&data.eventMata.backup_job_id==obj.jobId&&data.eventMata.backup_id==obj.uid){
                    if(data.eventType == "backup.restore.end"
                    ||data.eventType == "backup.restore.start"
                    ||data.eventType == "backup.delete.start"
                    ||data.eventType == "backup.delete.end"
                    ||data.eventType == "backup.create.start"
                    ||data.eventType == "backup.create.end"
                     ){  
                            initTable();
                    }
                }
           });
        }
    });

}
function rBackupDetailCtrl($scope, $rootScope, $translate,$uibModal,$location,Srv,TableCom,$filter,GLOBAL_CONFIG){
    let self = $scope;
    self.pageSize=10;
    self.currentPage=1;
    var initTable=function(options){
        var pageDefaultOption = {
            pageSize: self.pageSize,
            pageNum:1,
            likes:''
        };
        if(!options){
            self.currentPage=1;
        }
        var pageOption = angular.extend(pageDefaultOption,options);
        TableCom.init(self,'backupsDetailTable',[],"id",self.pageSize);
        self.backupsDetailTableData  = '';
        Srv.backupsDetailTableData='';
        Srv.getBackupsContainList($location.search().id,pageOption).then(function(result){
            if(result&&result.data){
                self.totalNum = result.total;
                self.backupsDetailTableData= result.data;
                successFunc(result.data);
            }
        });
    };
    initTable();

    function successFunc(data){
        let increArr=[],allArr=[];
        self.backupsDetailTableData=data.map(function(item,index){
            item.backupMode=item.isIncremental?$translate.instant("aws.backups.table.isIncremental.true"):$translate.instant("aws.backups.table.isIncremental.false");
            item.status_tr=$translate.instant("aws.backups.table.status_new."+item.status);
            item.createTime=$filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
            if(index==0){
                item.isFirst=true;
            }
            if(index==self.backupsDetailTableData.length-1){
                item.isLast=true;
            }
            return item;
        });
        Srv.backupsDetailTableData=self.backupsDetailTableData;
        TableCom.init(self,'backupsDetailTable',self.backupsDetailTableData,"id",self.pageSize);
    }

    self.changePage=function(options){
        self.currentPage=options.pageNum;
        self.pageSize=options.pageSize;
        initTable(options);
    };

    self.$on("backupSocket", function(e, data) {
        //若点击详情，详情也要更新状态
        if($location.search().id&&self.backupsDetailTableData&&angular.isArray(self.backupsDetailTableData)&&self.backupsDetailTableData.length){
           self.backupsDetailTableData.map(function(obj){
                if(data.eventMata&&data.eventMata.backup_job_id&&data.eventMata.backup_job_id==obj.jobId&&data.eventMata.backup_id==obj.uid){
                    if(data.eventType == "backup.restore.end"
                    ||data.eventType == "backup.restore.start"
                    ||data.eventType == "backup.delete.start"
                    ||data.eventType == "backup.delete.end"
                    ||data.eventType == "backup.create.start"
                    ||data.eventType == "backup.create.end"
                     ){  
                        if(self.detailType=='backup'){
                            initTable();
                        }
                    }
                }
           });
        }
    });
}
function rBackupRecordDetailCtrl($scope, $rootScope, $translate,$uibModal,$location,Srv,TableCom,$filter,GLOBAL_CONFIG){
    let self = $scope;
    self.pageSize=10;
    self.currentPage=1;
    var initResultTable=function(options){
         var pageDefaultOption = {
            pageSize: self.pageSize,
            pageNum:1,
            likes:''
        };
        if(!options){
            self.currentPage=1;
        }
        var pageOption = angular.extend(pageDefaultOption,options);
        TableCom.init(self,'recordDetailTable',[],"id",self.pageSize);
        self.recordTableData  = '';
        Srv.getBackupsExecuteRecord($location.search().id,pageOption).then(function(result){
            if(result&&result.data){
                self.recordTableData= result.data;
                successFunc(result.data);
                self.totalNum=result.total;
            }
        });
    };

    var initRecordTable=function(options){
         var pageDefaultOption = {
            pageSize: self.pageSize,
            pageNum:1,
            likes:''
        };
        if(!options){
            self.currentPage=1;
        }
        var pageOption = angular.extend(pageDefaultOption,options);
        TableCom.init(self,'recordDetailTable',[],"id",self.pageSize);
        self.recordTableData  = '';
        Srv.getBackupsJobRecord($location.search().id,pageOption).then(function(result){
            if(result&&result.data){
                self.recordTableData= result.data;
                successFunc(result.data);
                self.totalNum=result.total;
            }
        });
    };

    //备份页面
    if($location.path()=="/cvm/rbackups"){
        initResultTable();
    //备份恢复页面
    }else if($location.path()=="/cvm/recovery"){
        initRecordTable();
    }

    function successFunc(data){
        self.recordTableData=data.map(function(item){
            item.createTime=$filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
            item.resultCode_ts=$translate.instant("aws.statusCode."+item.resultCode);
            return item;
        });
        TableCom.init(self,'recordDetailTable',self.recordTableData,"id",self.pageSize);
    }

    self.changePage=function(options){
        self.currentPage=options.pageNum;
        self.pageSize=options.pageSize;
        if($location.path()=="/cvm/rbackups"){
            initResultTable(options);
        }else if($location.path()=="/cvm/recovery"){
            initRecordTable(options);
        }
    };
}
export{recoveryCtrl,recoveryBackupCtrl,rBackupDetailCtrl,rBackupRecordDetailCtrl};
