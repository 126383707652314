createSnapCtrl.$inject = ["$scope","$rootScope","editData","instancesSrv","volumesDataSrv","$translate","$uibModalInstance","checkedSrv","regularSnapSrv","storageSrv","checkQuotaSrv","vmSrv","$filter"];
export function createSnapCtrl($scope,$rootScope,editData,instancesSrv,volumesDataSrv,$translate,$uibModalInstance,checkedSrv,regularSnapSrv,storageSrv,checkQuotaSrv,vmSrv,$filter){
    var self=$scope;
    var proTotalSnap,proUsedSnap,proRemainderSnap;
    var depTotalSnap,depUsedSnap,depRemainderSnap;
    self.canConfirm = true;
    self.newObj={};
    self.submitInValid = false;
    self.showPriceTip = $rootScope.billingActive;
    self.priceHour = 0;
    self.priceHourAbout = 0;
    self.priceMonth = 0;
    self.newObj.hasElastic = false;
    self.newObj.snap_number_list=[{name:1},{name:2},{name:3},{name:4},{name:5}];//快照保留份数选项1-5
    self.newObj.holdNumber=self.newObj.snap_number_list[0];
    self.newObj.unit_list = [
        {name:$translate.instant("aws.instances.unit_list.onice")},
        {name:$translate.instant("aws.instances.unit_list.hour"),unit:"hour"},
        {name:$translate.instant("aws.instances.unit_list.day"),unit:"day"},
        {name:$translate.instant("aws.instances.unit_list.week"),unit:"week"},
        {name:$translate.instant("aws.instances.unit_list.month"),unit:"month"}
    ];
    self.newObj.unit = self.newObj.unit_list[0];
    self.newObj.systemDisk = "";
    self.newObj.dataDisk = [];
    self.system_disk_list=[];
    self.data_disk_list=[];
    self.errorSnap={
        "depSnapNum":$translate.instant("aws.volumes.errorMessage.depSnapNum"),
        "proSnapNum":$translate.instant("aws.volumes.errorMessage.proSnapNum")
    };
    if(editData && editData.description &&  editData.description.indexOf("elastic expansion created")>-1 ){
        self.newObj.hasElastic = true;
    }
    self.changeTime=function(){
        self.chkCurrentTimeTip = false;
    }
    function chkCurrentTime(val){
        var curTime = new Date().getTime();
        var chkTime = new Date(val).getTime()
        if(chkTime<curTime){
            return false;
        }else{
            return true;
        }
    }
    self.snapFun=function(type){
        if(type=="week"||type=="month"){
            self.snapMax="99"
            self.snapMin="1"
        }else if(type=="day"){
            self.snapMax="31"
            self.snapMin="1"
        }else if(type=="hour"){
            self.snapMax="168"
            self.snapMin="1"
        }else if(type=="minute"){
            self.snapMax="59"
            self.snapMin="1"
        }
    }

    //计费信息
    getSnapAmount();
    function getSnapAmount() {
        if(!self.showPriceTip) {
            return;
        }
        var option = {
            snapshotCount: (self.watchVolumeChoose && self.watchVolumeChoose.length) || 1,
            region: localStorage.regionName || "default"
        }
        instancesSrv.getSnapAmount(option).then(function(result) {
            if(result && result.data && !isNaN(result.data)) {
                self.priceHour = result.data;
                self.priceHourAbout = result.data.toFixed(2);
                self.priceMonth = (result.data * 24 * 30).toFixed(2);
            }
        });
    }

    self.$watch(function() {
        return (self.watchVolumeChoose && self.watchVolumeChoose.length);
    }, function(val) {
        val !== undefined && getSnapAmount();
    });        

    function avoidDoubleClick(bol){
        self.cannot_Confirm = bol;
    }
    function getVolumeIds(){
        var volumeIds = [],volumeNames=[];
        if(self.system_disk_list.length&&self.newObj.systemDisk.id){
            volumeIds.push(self.newObj.systemDisk.id);
            volumeNames.push(self.newObj.systemDisk.name);
        }
        if(self.newObj.dataDisk.length){
            self.newObj.dataDisk.map(item => {
                volumeIds.push(item.id)
                volumeNames.push(item.name)
            })
        }
        return {volumeIds,volumeNames}
    }
    var pushDisk = function(x){
        //bootable为true且root_device为true时是系统盘，为false是数据盘
        if(x.bootable=="true" && x.root_device == "true"){
            self.system_disk_list.push(x);
        }else{
            self.data_disk_list.push(x);
        }
    }
    var getServerDetail = function(){
        vmSrv.getVmDisks(editData.uid,{jobType:0}).then(function(result) {
            if (result && result.data) {
                var diskInfo = result.data.diskInfo.filter(item => (item.diskFormat != "iso" && (item.status == "in-use"||item.status == "available")));
                diskInfo = diskInfo.filter(item => !item.magrationing);
                //diskInfo = diskInfo.filter(item => item.associatedHost.indexOf("nfs")==-1) //.nfs存储不支持快照功能
                diskInfo = diskInfo.filter(item => (item.storageLimit && item.storageLimit["snapshots"]));
                diskInfo = diskInfo.filter(item => !(item.storageLimit && !item.storageLimit["share_volumes_snapshots"]&&item.attachments.length>1));
                diskInfo.forEach((x)=>{
                    //同有双活特性云硬盘不支持快照功能
                    if(x.associatedHost.indexOf("toyou") > -1){
                        var postData = {
                            "volume_type_name": x.type,
                        }
                        var posthost = x.associatedHost;
                        storageSrv.volume_type_analysis(postData).then(function(result){
                            if(result && result.data && angular.isObject(result.data)){
                                if(result.data.Character_message){
                                    if(result.data.Character_message.hyperswap==1){
                                        return ;
                                    }
                                }
                            }
                            pushDisk(x)
                        })
                    }else{
                        pushDisk(x)
                    }
                })
                if(self.system_disk_list.length==0 && editData.startStyle == 'Local' ){
                    self.systemDiskTip = $translate.instant('aws.instances.snapshot.localsystemDiskPlace')
                }else{
                    self.systemDiskTip = $translate.instant('aws.instances.snapshot.nosystemDiskPlace')
                }
            }
        });
    }
    
    self.change_snap_number=function(obj){
        self.newObj.holdNumber=obj;
        self.watchVolumeChoose = getVolumeIds().volumeIds;
        checkQuotaSrv.checkQuota(self, "snapshots","","",self.watchVolumeChoose.length*self.newObj.holdNumber.name);
    }
    self.changeDisk = function(){
        self.chooseDiskTipShow = false;
        self.watchVolumeChoose = getVolumeIds().volumeIds;
        checkQuotaSrv.checkQuota(self, "snapshots","","",self.watchVolumeChoose.length*self.newObj.holdNumber.name);
    }
    self.createInsSnap = function(field,editdata){
        var volumeIds = getVolumeIds().volumeIds,
        volumeNames= getVolumeIds().volumeNames;
        // if(!volumeIds.length){
        //     self.chooseDiskTipShow = true
        // }
        if(chkCurrentTime(self.newObj.startTime)){
            if(field.$valid){
                if(volumeIds.length){
                    //checkedSrv.setChkIds(volumeIds,"snapshotcreate")
                    if(self.timedSnap){ //定时快照
                        var postData = {
                            "projectId": localStorage.projectUid,//项目id
                            "regionKey": localStorage.regionKey,//regionKey 
                            "serverId":  editData.uid,
                            "startTime": self.newObj.startTime,
                            "userId":localStorage.userUid
                        }
                        if(self.newObj.unit.unit){
                            postData.holdNumber = self.newObj.holdNumber.name,//快照保存份数 
                            postData.frequency = Number(self.newObj.frequency);//任务触发频率
                            postData.unit = self.newObj.unit.unit;//任务触发频率单位
                        }
                        regularSnapSrv.createTask(postData,{volumeIds:volumeIds}).then(function(result){
                        })
                    }else{ //非定时快照
                        let date=$filter("date")(new Date(), "yyyy-MM-dd HH:mm:ss").split(" ").join("").split("-").join("").split(":").join("");
                        volumeNames=volumeNames.map(function(item){
                            item=item+"_"+date;
                            return item
                        })
                        var post = {
                            "volumeId": volumeIds.join("#"),
                            "name":volumeNames.join("#"),
                        }
                        checkedSrv.setChkIds(volumeIds,"snapshotcreate")
                        volumesDataSrv.createSnapshotAction(post).then(function() {
                        });
                    }
                    avoidDoubleClick(true)  //避免双击
                    $uibModalInstance.close();
                }else{
                    self.chooseDiskTipShow = true; //提示至少选择一块盘
                }
            }else{
                self.submitInValid = true;
            }
        }else{
            self.chkCurrentTimeTip = true;
        }
        
    }
    avoidDoubleClick(false)
    //proSnapFunc()
    checkQuotaSrv.checkQuota(self, "snapshots","","",0);
    getServerDetail() 
}
