
import "./data-clean-srv";

var dataCleanModule = angular.module("dataCleanModule",["ngMessages","dataCleanSrvModule"]);
dataCleanModule.controller('backupDataCleanCtrl', ["$scope", "$uibModal", "$translate",
						"dataCleanSrv", 'NgTableParams', 'GLOBAL_CONFIG', "$location",
						
	function($scope, $uibModal, $translate, dataCleanSrv, NgTableParams, GLOBAL_CONFIG, $location) {
	var self = $scope;

	self.dataCleanTotal = null;
	self.dataCleanList = [];
	self.dataLoadData = [];
	self.backupServiceConfig = true;
	self.desc = localStorage.managementRole == 2 ? $translate.instant("aws.backupService.tip.desc0") : $translate.instant("aws.backupService.tip.desc1");
	self.headers = {
        jobName:$translate.instant("aws.backupService.backupDataClean.table.jobName"),
        jobStatus:$translate.instant("aws.backupService.backupDataClean.table.jobStatus"),
        jobDesc:$translate.instant("aws.backupService.backupDataClean.table.jobDesc"),
		jobStartTime:$translate.instant("aws.backupService.backupDataClean.table.jobStartTime"),
		domain:$translate.instant("aws.backupService.variable.table.domainName"),
		project:$translate.instant("aws.backupService.variable.table.projectName")
    };

	self.tableCols = [
		{field: "jobName", title: self.headers.jobName, show: true, disable: true, width: "30%"},
		{field: "jobStatus", title: self.headers.jobStatus, show: true, disable: false},
		{field: "jobDesc", title: self.headers.jobDesc, show: true, disable: false, width: "30%"},
		{field: "jobStartTime", title: self.headers.jobStartTime, show: true, disable: false}
	];

	initPage();
	initData();

	// 数据清理列表
	function initData() {
		if(!self.backupServiceConfig){
            return;
        }
		let params = {
			pageNum: self.dataCurrentPage,
			pageSize: self.dataPageSize
		}
		if (self.globalSearchTerm) {
			params.search = self.globalSearchTerm;
		}
		self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: [] });
		dataCleanSrv.getBackupDataCleanList(params).then(function (result) {
			if (result && result.data.length > 0) {
				self.dataCleanList = result.data;
				self.dataCleanTotal = result.total;
				successFunc(result.data, result.total);
			}
		});
	}

	// 处理列表数据
	function successFunc(data, total) {   
		data.forEach(item => {
			item.status = handleStatus(item);
		});
		self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: data });
		self.dataLoadData = data;
		self.dataTotalNum = total;
	}

	// 处理数据清理列表状态
	function handleStatus(item) {
		if (item.jobStatus == 32) {
			item.status = $translate.instant("aws.backupService.backupDataClean.table.success");
		}else if (item.jobStatus == 64) {
			item.status = $translate.instant("aws.backupService.backupDataClean.table.failed");
		}else if (item.jobStatus == 256) {
			item.status = $translate.instant("aws.backupService.backupDataClean.table.error");
		}else if (item.jobStatus == 128) {
			item.status = $translate.instant("aws.backupService.backupDataClean.table.partSuccess");
		}else if (item.jobStatus == 129) {
			item.status = $translate.instant("aws.backupService.backupDataClean.table.successAndWarning");
		}else if (item.jobStatus == 16) {
			item.status = $translate.instant("aws.backupService.backupDataClean.table.stopped");
		}else if (item.jobStatus == 4) {
			item.status = $translate.instant("aws.backupService.backupDataClean.table.running");
		}
		return item.status;
	}

	// 分页查询
	self.dataChangePage = function(options) {
        self.dataCurrentPage = options.pageNum;
        self.dataPageSize = options.pageSize;
		initData();
    };

	// 初始化分页查询参数
	function initPage() {
		self.dataCurrentPage = 1;
		self.dataPageSize = GLOBAL_CONFIG.PAGESIZE;
		self.globalSearchTerm = "";
	}

	// 刷新
	self.refresh = function() {
		initPage();
		initData();
	}

	// 模糊查询数据清理列表
	self.applyGlobalSearch = function() {
		initData();
	};

	// 跳转详情页
	self.linkTo = function(id){
		$location.url('/advanced/backup-clean-job?id=' + id);
		getDetails(id);
	}

	// 关闭详情页
	self.closeDetailModal = function() {
        $location.url('/advanced/backup-clean-job');
    };

	// 数据清理详情
	function getDetails (id) {
		self.detailsLoadData = [];
		self.detailTableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: [] });
		dataCleanSrv.getBackupDataClean({id: id}).then(function (result) {
			if (result && result.data.length > 0) {
				self.detailTableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: result.data });
				self.detailsLoadData = result.data;
			}
		});
	}

	// 创建数据清理
	self.createDataClean = function() {
		$uibModal.open({
			animation: true,
			templateUrl: "js/advanced-function/backup/data-clean/tmpl/create-data-clean.html",
			controller: "createBackupDataCleanCtrl"
		});
	}

	// websocket
    self.$on("backupDataClean", function(e, data) {
        if(data.jobServiceType == "backup-data-clean"){  
			self.dataCleanList.forEach(item => {
				if (item.jobId == data.jobId) {
					item.jobStartTime = data.jobStartTime;
					item.jobStatus = data.jobStatus;
					item.status = handleStatus(item);
				}
			});
			self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: self.dataCleanList });
			self.dataLoadData = self.dataCleanList;
			self.dataTotalNum = self.dataCleanTotal;
        }
    });
	self.$on("backupServiceConfig", function(e, data) {
        // 展示为配置列表
        self.backupServiceConfig = data;
    });
	// 监听是否选中全部部门
    self.$watch(function() {
        return localStorage.selectedAllDomain;
    },function(ne) {
		if (ne == "1") {
			self.tableCols = [
				{field: "jobName", title: self.headers.jobName, show: true, disable: true, width: "30%"},
				{field: "jobStatus", title: self.headers.jobStatus, show: true, disable: false},
				{field: "jobDesc", title: self.headers.jobDesc, show: true, disable: false, width: "20%"},
				{field: "jobStartTime", title: self.headers.jobStartTime, show: true, disable: false, width: "15%"},
				{field: "domain", title: self.headers.domain, show: true, disable: false, width: "10%"},
				{field: "project", title: self.headers.project, show: true, disable: false, width: "10%"}
			];
		}
    });

}]);
