import "./recycleDbSrv";
import { detailConfigInfoTpl } from '../instances/directive/detail-config-info-tpl';
import { detailLogTpl } from '../instances/directive/detail-log-tpl';
var recycleDbModule = angular.module("recycleDbModule",["recycleDbSrvModule"]);
recycleDbModule.controller("recycleDbCtrl",["$rootScope","$scope","$translate","$filter","TableCom","utilTool","checkedSrv","instancesSrv","generalSrv","bandWidthSrv","recycleSrv","recycleDbSrv","cvmViewSrv","depviewsrv","$timeout","$location",
    function($rootScope, $scope, $translate, $filter, TableCom, utilTool, checkedSrv, instancesSrv, generalSrv, bandWidthSrv, recycleSrv, recycleDbSrv, cvmViewSrv, depviewsrv,$timeout, $location){
        var self = $scope;
        self.showalerttip = true;
        self.pageNum = 1;
        self.pageSize = 10;
        self.currentPage = 1;
        self.statusSelected = {};
        self.globalSearchTerm = "";
        self.checkboxes = {
            checked: false,
            items: {}
        }
        self.processArr = ["restoring","deleting"];
        self.searchList = [
            { name:$translate.instant('aws.instances.table.status.all'),id:null,key:"" },
            { name:$translate.instant('aws.recycle.table.status.soft-delete'),id:"soft_deleted",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori" },
            { name:$translate.instant('aws.instances.table.status.restoring'),id:"restoring",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori" },
        ]
        self.headers = {
            name:$translate.instant('aws.cloudDatabase.databaseName'),
            instanceTypeCategory:$translate.instant('aws.cloudDatabase.databaseType'),
            status:$translate.instant('aws.cloudDatabase.status'),
            imgName:$translate.instant('aws.cloudDatabase.imageName'),
            privateIP:$translate.instant('aws.cloudDatabase.privateIp'),
            os:$translate.instant('aws.cloudDatabase.operateSystem'),
            create_at:$translate.instant('aws.instances.create_at'),
            updatetime:$translate.instant('aws.instances.deletetime'),
            label:$translate.instant("aws.instances.label"),
            domain:$translate.instant("aws.common.domain"),
            project:$translate.instant("aws.common.project")
        }

        self.titleName ="recycleDb";
        if(sessionStorage["recycleDb"]) {
            self.tableCols = JSON.parse(sessionStorage["recycleDb"]);
        }else {
            self.tableCols = [
                { field: "check", title: "",headerTemplateURL:"checkboxes.html",show: true },
                { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                { field: "instanceTypeCategory", title: self.headers.instanceTypeCategory,sortable: "instanceTypeCategory",show: true,disable:false},
                { field: "status_ori", title: self.headers.status,sortable: "status",show: true,disable:false},
                { field: "imageName", title: self.headers.imgName,sortable: "imageName",show: true,disable:false},
                { field: "fixedIps", title: self.headers.privateIP,sortable: "fixedIps_long",show: true,disable:false},
                { field: "os", title: self.headers.os,sortable: "os",show: true,disable:false },
                { field: "createtime", title: self.headers.create_at,sortable: "createtime",show: true,disable:false},
                { field: "updatetime", title: self.headers.updatetime,sortable: "updatetime",show: true,disable:false},
                { field: "labelList", title: self.headers.label,sortable: "labelList",show: true,disable:false},
            ]
        }

        // 查询过期时间
        function getRecycleTime() {
            generalSrv.getRecycleTimeData(localStorage.regionKey).then(res=> {
                if(res && res.data) {
                    var time = parseInt(res.data.reclaim_instance_interval / 3600);
                    self.recycle = {"time":time};
                }
            });
        }

        getRecycleTime();

        bandWidthSrv.getLimitData().then(res=> {
            if(res && res.data && res.data.length != 0) {
                self.limitData = JSON.parse(res.data[0].paramValue);
            }
        })

        // 关闭alert提示
        self.close = () => {
            self.showalerttip = false;
        }

        // 选择全部时 列表中部门和项目的处理
        self.$watch(()=>{
            return localStorage.selectedAllDomain;
        },(val)=>{
            utilTool.projectDomainTableCol(val, 2, self);
        })

        // 获取云数据库回收站表格数据
        self.getList = (option) => {
            TableCom.init(self, "tableParams", [], "uid", self.pageSize, "checkboxes");
            var defaultParams = {
                pageNum: self.pageNum,
                pageSize: self.pageSize,
                pattern: self.globalSearchTerm,
                status: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : ""
            }
            var params = option ? option : defaultParams;
            params.instanceType = 'db_inst';// 云数据库必须传instanceType 用来区分查询的云主机数据
            self.tableData = [];
            recycleDbSrv.getGcList(params).then(result=>{
                if(result && result.data && angular.isArray(result.data)){
                    self.successFunc(result);
                }
            })
        }
        self.getList();

        // 处理表格数据
        self.successFunc = (result) => {
            self.totalNum = result.total;
            self.tableData = result.data;
            self.tableData.forEach(item=>{
                var domainNameList = ['default'];
                var projectNameList = ['admin'];
                item.domainName = domainNameList.includes(item.domainName) ? $translate.instant("aws.common.defaultDepar") : item.domainName;
                item.projectName = projectNameList.includes(item.projectName) ? $translate.instant("aws.common.defaultProject") : item.projectName;
                item.updatetime = $filter("date")(item.updatetime,"yyyy-MM-dd HH:mm:ss");
                item.createtime = $filter("date")(item.createtime,"yyyy-MM-dd HH:mm:ss");
                item.status = item.status.toLowerCase();
                item.status_ori = $translate.instant("aws.recycle.table.status."+item.status);
                if(item.fixedIps) {
                    item.fixedIps_long = item.fixedIps.map(ip => _IP.toLong(ip)) ;
                }
                item.labelList = []
                if(item.label){
                    for (const key in item.label) {
                        item.labelList.push(item.label[key])
                    }
                }
            })
            TableCom.init(self, "tableParams", self.tableData, "uid", self.pageSize, "checkboxes");
        }

        // 切换页码
        self.changePage = (option) => {
            self.currentPage = option.pageNum;
            self.pageSize = option.pageSize;
            self.pattern = self.globalSearchTerm;
            self.status = self.statusSelected.searchItem.id
            self.getList(option);
        }

        // 搜索
        self.searchBackend = (options) => {
            self.currentPage = 1;
            var pageOption = {
                pageNum: self.pageNum,
                pageSize: self.pageSize,
                pattern: self.globalSearchTerm ? self.globalSearchTerm : "",
                status: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : ""
            }
            if(options.type == "refresh") {
                self.globalSearchTerm = "";
                self.statusSelected.searchItem = self.searchList[0];
                pageOption.pattern = "";
                pageOption.status = "";
            }
            self.getList(pageOption);
        }

        // 打开详情
        self.linkTo = function(item){
            let insStatus = item.status.toLowerCase();
            utilTool.setLocalStorage([item]);
            $location.url(`/cvm/recycleDb?id=${item.uid}&insStatus=${insStatus}`);
        }

        // 关闭详情
        self.linkClose = function(){
            self.checkboxes.items = {};
            $location.url('/cvm/recycleDb');
            self.tab = {};// 解决再次打开详情时 详情的directive不会刷新的问题
            self.tabCacheObj = utilTool.tabsShow(2,'close');
        }

        // 详情加载的内容
        self.$on("getDetail", function(event, insUid) {
            self.panels = {};
            self.tab = {};
            self.tab = {
                active : 0
            }
            self.tabCacheObj = utilTool.tabsShow(2,'open');
            self.chartDisplay = function(i) {
                self.tab.active = i
                self.tabCacheObj[`tabCache${i}`] = true
            }
            self.insUid = insUid;
            self.fromCloudDatabase = true;// 用于详情页配置详情页面区分云数据库和云主机的字段展示
        })

        // 资源导出
        self.exportResource = () => {
            var status = self.statusSelected.searchItem ? self.statusSelected.searchItem.id : '';
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/instances_gc?regionKey=${localStorage.regionKey}&type=2&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm?self.globalSearchTerm:""}&status=${self.statusSelected.searchItem.id?self.statusSelected.searchItem.id:""}&instanceType=db_inst`
            utilTool.downloadFile(url);
        }
        self.showExport = utilTool.isAllDomainSelect;

        // 监听勾选的表格数据
        self.$watch(()=>{
            return self.checkboxes.items
        },(val,old)=>{
            if(val&&old&&val.length!=old.length){
                utilTool.setLocalStorage(val);
            }
            self.canClear = false;
            self.canRecovery = false;
            self.forceDel = false;
            if(Object.keys(val).length == 0) return;
            self.checkedItems = [];
            var arr = [];
            for (const i in self.checkboxes.items) {
                arr.push(self.checkboxes.items[i]);
            }
            if(val && arr.length>=0) {
                for (const key in val) {
                    if(val[key]) {
                        self.tableData.forEach(item=>{
                            if(item.uid == key) {
                                self.checkedItems.push(item);
                            }
                        })
                    }
                }
            }
            if(self.checkedItems.length == 1) {
                if(!self.processArr.includes(self.checkedItems[0].status)){
                    self.canClear = true;
                    self.canRecovery = true;
                    self.forceDel = true;
                }
            }else if(self.checkedItems.length > 1) {
                self.canClear = true;
                for(var i= 0;i<self.checkedItems.length;i++) {
                    if(self.processArr.includes(self.checkedItems[i].status)) {
                        self.canClear = false;
                        break;
                    }
                }
            }
        },true)
        
        // 清除云数据库
        self.forceDelRes = () => {
            var content = {
                target: "forceDelRes",
                msg:`<span>${$translate.instant("aws.cloudDatabase.tipMsg.forcedelSever")}</span>`
            }
            self.$emit("delete", content);
        }
        self.$on("forceDelRes",()=>{
            var del_ids = [];
            self.checkedItems.map(item => {del_ids.push(item.uid);});
            checkedSrv.setChkIds(del_ids,"delete")
            recycleSrv.osForceDel({ids:del_ids}).then(()=> {});
        })

        // 恢复云数据库
        self.restoreRes = () => {
            self.sartTest = false;
            var value = self.checkedItems[0].uid
            instancesSrv.getServerDetail(value).then(result=>{
                if(result && result.data){
                    // 校验配额
                    getProused(result.data);
                }
            }) 
        }

        self.$watch(()=>{
            return self.sartTest
        },value=>{
            if(value){
                var ramtip="",coretip="",instip="",text;
                if(self.coreProText){
                    coretip = self.coreProText;
                }else if(self.coreDomText){
                    coretip = self.coreDomText
                }
                if(self.ramProText){
                    ramtip = self.ramProText;
                }else if(self.ramDomText){
                    ramtip = self.ramDomText;
                }
                if(self.insProText){
                    instip = self.insProText;
                }else if(self.insDomText){
                    instip = self.insDomText;
                }
                if(coretip || ramtip || instip ){
                    text = instip + "</br>" + coretip + "</br>"+ramtip;
                    var content = {
                        target: "restoreRes",
                        msg:"<span>"+ text + "</span>",
                        type:"danger" ,
                        btnType: "btn-primary"
                    };
                    content.notDel = true;
                }else{
                    text = $translate.instant("aws.cloudDatabase.tipMsg.restoreSever")
                    var content = {
                        target: "restoreRes",
                        msg:"<span>"+ text + "</span>",
                        type: "info",
                        btnType: "btn-primary"
                    };
                }
                
                self.$emit("delete", content);
            }
        })

        self.$on("restoreRes", ()=> {
            var del_ids = [];
            self.notDel = true;
            self.checkedItems.map(item => {del_ids.push(item.uid);});
            checkedSrv.setChkIds(del_ids,"restore")
            recycleSrv.osRestore({ids:del_ids}).then(function() {
                self.searchBackend({type:"refresh"});
            });
        });

        // 强制删除云数据库
        self.forceDelVm = () => {
            var content = {
                target: "forceDelVm",
                msg:`<span>${$translate.instant("aws.cloudDatabase.tipMsg.forceDel")}</span>`
            };
            self.$emit("delete", content);
        }

        self.$on("forceDelVm", ()=> {
            var del_ids = [];
            self.checkedItems.map(item => {del_ids.push(item.uid);});
            checkedSrv.setChkIds(del_ids,"delete")
            recycleSrv.osForceDelVm(del_ids[0],{serverName:self.checkedItems[0].name}).then(function() {
                self.searchBackend({type:"refresh"});
            });
        });

        $rootScope.$on("deleteVmSocket",function(e,data){
            if(data.eventType == "compute.instance.delete.end"){ //删除
                $timeout(function(){
                    self.searchBackend({type:"refresh"});
                },500)
            }
        })
        //server socket
        $rootScope.$on("serverSocket",function(e,data){
            if(self.tableData && self.tableData.length){
                self.tableData.map(function(obj){
                    if(data.eventMata.instance_id){
                        if(obj.uid === data.eventMata.instance_id){
                            obj.status = data.eventMata.status;
                            if(data.eventType == "compute.instance.restore.end" ){ //恢复
                                $timeout(function(){
                                    self.searchBackend({type:"refresh"});
                                },500)
                            }
                        }
                    }
                });
                self.tableParams.reload();
                self.checkboxes.items={};
            }
        });

        //获取项目中的总配额
        function getproQuotas(data) {
            var insQuotapost = {
                type: "project_quota",
                targetId: localStorage.projectUid,
                enterpriseUid: localStorage.enterpriseUid
            };
            cvmViewSrv.getproQuotas(insQuotapost).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "quota"].total = item.defaultQuota || item.hardLimit;
                        }
                    });
                    (data.vcpus> self.coresquota.total - self.coresquota.used) ? self.coreProText = $translate.instant("aws.instances.quota.proCpu"): self.coreProText = "";
                    (data.ram > self.ramquota.total - self.ramquota.used) ? self.ramProText = $translate.instant("aws.instances.quota.proRam"): self.ramProText = "";
                    (1 > self.instancesquota.total - self.instancesquota.used) ? self.insProText = $translate.instant("aws.instances.quota.proIns"): self.insProText = "";
            }
                getDomused(data);
            });
        }

        //获取项目中配额的使用量
        function getProused(data) {
            self.instancesquota = {};
            self.coresquota = {};
            self.ramquota = {};
            var postData = {
                type: "project_quota",
                domainUid: localStorage.domainUid,
                projectUid: localStorage.projectUid,
                enterpriseUid: localStorage.enterpriseUid
            };
            cvmViewSrv.getProused(postData).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "quota"].used = item.inUse;
                        }
                    });
                }
                getproQuotas(data);
            });
            
        }

        //获取部门中的总配额
        function getDomQuotas(data) {
            depviewsrv.getQuotaTotal(localStorage.domainUid).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "Domquota"].total = item.defaultQuota || item.hardLimit;
                        }
                    });
                    (data.vcpus > self.coresDomquota.total - self.coresDomquota.used) ? self.coreDomText = $translate.instant("aws.instances.quota.domCpu"): self.coreDomText = "";
                    (data.ram> self.ramDomquota.total - self.ramDomquota.used) ? self.ramDomText = $translate.instant("aws.instances.quota.domRam"): self.ramDomText = "";
                    (1 > self.instancesDomquota.total - self.instancesDomquota.used) ? self.insDomText = $translate.instant("aws.instances.quota.domIns"): self.insDomText = "";
                    self.sartTest = true;
                 }
            });
        }

        //获取部门中配额的使用量
        function getDomused(data) {
            self.instancesDomquota = {};
            self.coresDomquota = {};
            self.ramDomquota = {};
            depviewsrv.getQuotaUsed(localStorage.domainUid).then(function(result) {
                if (result && result.data && result.data.length) {
                    _.forEach(result.data, function(item) {
                        if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                            self[item.name + "Domquota"].used = item.inUse;
                        }
                    });
                }
                getDomQuotas(data);
            });
            
        }
    }])
    .directive('recycleDbDetailConfigInfoTpl',detailConfigInfoTpl)
    .directive('recycleDbDetailLogTpl',detailLogTpl)