storageincidr.$inject = [];
function storageincidr() {
    return {
        require: "ngModel",
        link: function(scope, elem, attrs, $ngModel) {
            var cidr = "#" + attrs.storageincidr;
            $ngModel.$parsers.push(function(value){
                var min = _IP.cidrSubnet($(cidr).val()).networkAddress;
                var max = _IP.cidrSubnet($(cidr).val()).broadcastAddress;
                if(_IP.isV4Format(value)){
                    if(!_IP.cidrSubnet($(cidr).val()).contains(value) || (_IP.cidrSubnet($(cidr).val()).contains(value) && (_IP.toLong(min) >= _IP.toLong(value) || _IP.toLong(max)<= _IP.toLong(value)))){
                        $ngModel.$setValidity("storageincidr", false);
                        return value;
                    }
                }
                $ngModel.$setValidity("storageincidr", true);
                return value;
            });
            $ngModel.$formatters.push(function(value){
                var min = _IP.cidrSubnet($(cidr).val()).networkAddress;
                var max = _IP.cidrSubnet($(cidr).val()).broadcastAddress;
                if(_IP.isV4Format(value)){
                    if(!_IP.cidrSubnet($(cidr).val()).contains(value) || (_IP.cidrSubnet($(cidr).val()).contains(value) && (_IP.toLong(min) >= _IP.toLong(value) || _IP.toLong(max)<= _IP.toLong(value)))){
                        $ngModel.$setValidity("storageincidr", false);
                        return value;
                    }
                }
                $ngModel.$setValidity("storageincidr", true);
                return value;
            });
            scope.$watch(function(){
                return $(cidr).val();
            },function(val){
                if(_IP.cidrSubnet(val)){
                    var min = _IP.cidrSubnet(val).networkAddress;
                    var max = _IP.cidrSubnet(val).broadcastAddress;
                    if(!_IP.cidrSubnet(val).contains($ngModel.$viewValue) || (_IP.cidrSubnet(val).contains($ngModel.$viewValue) && (_IP.toLong(min) >= _IP.toLong($ngModel.$viewValue) || _IP.toLong(max)<= _IP.toLong($ngModel.$viewValue)))){
                        $ngModel.$setValidity("storageincidr", false);
                        return;
                    }
                    $ngModel.$setValidity("storageincidr", true);
                }
            });
        }
    };
}
export {storageincidr}
