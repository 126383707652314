bindUnbindFloatingIpCtrl.$inject = ["$scope", "$translate","$uibModalInstance","$location","context","type","editData","refresh","NgTableParams",'instancesSrv'];
export function bindUnbindFloatingIpCtrl($scope, $translate, $uibModalInstance, $location, context, type, editData, refresh, NgTableParams, instancesSrv){
    let self = $scope;
    self.floatingIp = {};
    self.submitInValid = false;
    self.disabledFlag = false;
    switch (type) {
        case "bind":
            self.floatTitle = $translate.instant("aws.instances.FloatingIp.bindIP");
            self.osNetList = [];
            self.hideips = false;
            self.noDataFlag = false;
            self.ipLabelText = $translate.instant("aws.instances.privateIP");
            //获取所有的公网IP
            instancesSrv.getAllfloalingIp().then(function(result) {
                if (result && result.data) {
                    self.IpsList = result.data.filter(function(item) {
                        return !(item.portId);
                    });
                    if(self.IpsList.length == 0){
                         self.noDataFlag = true ;
                    }else{
                        self.noDataFlag = false ;
                    }
                    self.floatingIp.floatingip_id = self.IpsList[0];
                    return result.data;
                }
            }).then(function(res) {
                //获取选中云主机下的网卡
                instancesSrv.getOsNet(editData.uid).then(function(result) {
                    let net_filter = [];
                    if (res && result && result.data.length) {
                        result.data.map(function(item) {
                            let ipv4_address=item.fixed_ips.filter(function(port){
                                return port.ip_address.indexOf(":")==-1;
                            });
                            self.osNetList.push({
                                id: item.port_id,
                                name: ipv4_address[0].ip_address
                            });
                        });
                        //获取已经绑定过公网IP的网卡
                        self.osNetList.map(item => {
                            res.map(val => {
                                if (val.instanceName && val.portId == item.id) {
                                    net_filter.push(item);
                                }
                            });
                        });
                        //过滤获取已经绑定过公网IP的网卡
                        net_filter.map(item => {
                            self.osNetList = self.osNetList.filter(val => item != val);
                        });
                        self.floatingIp.port_id = self.osNetList[0];
                    }
                });
            });

            self.confirm = function(fip, floatForm) {
                //与后台交互
                if (floatForm.$valid) {
                    var postData = {
                        floatingip_id: fip.floatingip_id.id,
                        port_id: fip.port_id.id
                    };
                    self.bindFip = fip.floatingip_id.ip;
                    self.bindFip_uid = editData.uid;
                    self.disabledFlag = true;
                    instancesSrv.bind_floatingip(postData).then(function() {
                        $uibModalInstance.close();
                        refresh();
                    }).finally(()=>{
                        self.disabledFlag = false;
                    });
                } else {
                    self.submitInValid = true;
                }
            };
            break;
        case "relieve":
            self.floatTitle = $translate.instant("aws.instances.FloatingIp.unbindIP");
            self.ipLabelText = $translate.instant("aws.instances.chooseIP");
            self.hideips = true;
            self.noDataFlag = false ;
            //获取选中云主机下绑定的公网IP
            instancesSrv.osInterfaceFips(editData.uid).then(function(result) {
                self.osNetList = result.data;
                self.floatingIp.port_id = result.data[0];
            });
            self.confirm = function(fip, floatForm) {
                if (floatForm.$valid) {
                    var postData = {
                        floatingip_id: fip.port_id.id
                    };
                    self.relieveIp = fip.port_id.name.split("_")[1];
                    self.relieveIp_uid = editData.uid;
                    self.disabledFlag = true;
                    instancesSrv.relieve_floatingip(postData).then(function() {
                        $uibModalInstance.close();
                        refresh();
                    }).finally(()=>{
                        self.disabledFlag = false;
                    });
                } else {
                    self.submitInValid = true;
                }
            };
            break;
    }
}