strategylSrv.$inject = ["$rootScope", "$http"]
function strategylSrv($rootScope, $http) {
    var static_url="awstack-resource/v2/";
    return {
        getlist:function(params) {
            return $http({
                method: "GET",
                url: static_url + "backup/policys",
                params:params
            });
        },
        getBackupPolicyDetail:function(policyId) {
            return $http({
                method: "GET",
                url: static_url + "backup/policys/"+policyId
            });
        },
        deletes:function(params) {
            return $http({
                method: "delete",
                url: static_url + "backup/policys",
                params:params
            });
        },
        create:function(params) {
            return $http({
                method: "POST",
                url: static_url + "backup/policys",
                data:params
            });
        },
        getExportUrl: function (data) {
            return $http({
                method: "POST",
                url: static_url + "backup/policys/export/url",
                data:data
            });
        }
    }
}
export {strategylSrv}