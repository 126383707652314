
import "../volumesSrv";
import "../../instances/instancesSrv";
dataVolumesCtrl.$inject=['$scope', "$rootScope", "volumesDataSrv", "$translate","NgTableParams","newCheckedSrv","$uibModal","$location","instancesSrv","regularSnapSrv","volumesSrv","storageManagementSrv","utilTool"];
loadingVolumeCtrl.$inject=['$scope','$timeout' , 'volumesDataSrv', '$uibModalInstance', '$translate', 'initDataVolumesTable', 'editData','NgTableParams'];
expandVolumeSizeCtrl.$inject=['$scope','$rootScope', 'volumesDataSrv', '$translate', '$uibModalInstance', 'initDataVolumesTable', 'editData'];
createSnapshotCtrl.$inject=['$scope', 'volumesDataSrv', '$translate', '$uibModalInstance', 'initDataVolumesTable', 'editData','checkedSrv',];

function dataVolumesCtrl($scope, $rootScope, volumesDataSrv,$translate,NgTableParams,newCheckedSrv,$uibModal,$location,instancesSrv,regularSnapSrv,volumesSrv,storageManagementSrv,utilTool){
    let self = $scope;
    self.context = self;
    self.pageSize = 10;
    self.pageCount = 10;

    self.table = {};
    // self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')];
    if(localStorage.permission == 'enterprise'){
        self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')]
    }else{
        self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_ConfigManagement'),$translate.instant('aws.menu.System_Storage')]
    }
    self.titleName="dataVolumes";
    if(sessionStorage["dataVolumes"]){
       self.tableCols=JSON.parse(sessionStorage["dataVolumes"]);
    }else{
        self.tableCols = [
            { field: "check", title: "",headerTemplateURL:"dataheaderCheckbox.html",show: true },
            { field: "name", title: self.headers.dataDiskName,sortable: "name",show: true,disable:true},
            { field: "size", title: self.headers.config,sortable: "size",show: true,disable:false},
            { field: "statusCopy", title: self.headers.status,sortable: "status",show: true,disable:true },
            { field: "voltype", title: self.headers.voltype,sortable: "voltype",show: true,disable:false },
            { field: "bootableCopy", title: self.headers.bootable,sortable: "bootable",show: true,disable:false },
            { field: "multiattachCopy", title: self.headers.sharedVolume,sortable: "multiattach",show: true,disable:false },
            { field: "description", title: self.headers.dataDiskDescription,sortable: "description",show: true,disable:false },
        ];
    }
    self.searchList = [
        {name:$translate.instant('aws.volumes.table.status.all'),id:null,key:""},
        {name:$translate.instant('aws.volumes.table.status.available'),id:"available",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.inavailable'),id:"inavailable",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.creating'),id:"creating",value:$translate.instant('aws.instances.table.status.error'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.deleting'),id:"deleting",value:$translate.instant('aws.instances.table.status.paused'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.in-use'),id:"in-use",value:$translate.instant('aws.instances.table.status.suspended'),key:"status_ori"}
    ];
    // storageManagementSrv.getStorageTableData().then(function(res){
    //     if(res && res.data && angular.isArray(res.data)){
    //         res.data.map(item => {
    //             if(item.use == "1" && item.type.toLowerCase().indexOf("nfs")>-1){
    //                 self.volume.nfsBakTonfs = true;
    //             }
    //         })
    //     }
    // })
    //资源导出
    self.exportResource = function(){
        var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/volumes?regionKey=${localStorage.regionKey}&type=0&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm?self.globalSearchTerm:''}&status=${self.statusSelected.searchItem.id?self.statusSelected.searchItem.id:''}`
        utilTool.downloadFile(url);
    }
    self.showExport = utilTool.isAllDomainSelect;
    //项目切换的监听函数
    self.$watch(function(){
        return localStorage.selectedAllDomain;
    },function(ne){
        
        utilTool.projectDomainTableCol(ne,2,self)
    })

    //切换页码
    self.changePage=function(options){
        options.pattern = self.globalSearchTerm?self.globalSearchTerm:null;
        options.status = self.statusSelected.searchItem?self.statusSelected.searchItem.id:null;
        options.type = 0;
        self.pageCount = options.pageSize
        getVolumes(options);
    };
    //后端搜索
    self.searchBackend = function(options){
        
        var pageOption = {
            pageSize: self.pageSize,
            pageNum:1,
            pattern:self.globalSearchTerm?self.globalSearchTerm:null,
            status:self.statusSelected.searchItem?self.statusSelected.searchItem.id:null,
            type: 0
        };
        if(options.type=='refresh'){
            self.currentPage = 1;
            self.globalSearchTerm = null;
            pageOption.pattern = null;
            pageOption.status = null;
            self.statusSelected.searchItem = self.searchList[0]
        }
        getVolumes(pageOption);
    }

    function getVolumes(options) {
        if(!localStorage.cinderService) return;
        volumesDataSrv.getVolumeList(options).then(function(result) {
            if(result && result.data && angular.isArray(result.data)){
                //过滤掉result.data中vol.instance="fixedImageInstanceName"的元素
                //result.data=result.data.filter(vol=>vol.instance[0]!="fixedImageInstanceName");
                result.data=result.data.filter(vol=>{
                    if(vol.name){
                        // return vol.name.split("-")[0]!="fixedImageInstanceName"
                        return true;
                    }else{
                        return true;
                    }
                });
                result.data.map(item => {
                    item.statusCopy= $translate.instant("aws.volumes.table.status."+item.status);
                    item.multiattachCopy = $translate.instant('aws.volumes.table.multiattach.'+item.multiattach);
                    item.bootableCopy = $translate.instant('aws.volumes.table.bootable.'+item.bootable);
                    item.voltype =  item.storageInfo?item.storageInfo.disPlayName:"";
                    item._type=angular.copy(item.type);
                    if(item.domainName=="default"){
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    }else{
                        item.domainName = item.domainName;
                    }
                    if(item.projectName=="admin"){
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    }else{
                        item.projectName = item.projectName;
                    }
                    if (item.description == null) {
                       item.description = '';
                    }else if(item.description == "auto-created_from_restore_from_backup"){
                        item.description = $translate.instant("aws.volumes.fromBackup");
                    }
                    if(angular.isArray(item.attachments) && item.attachments.length == 1 && item.attachments[0].device && item.attachments[0].device.slice(-2) == "da"){
                        item.root_device = true;
                    }
                }            );
                self.data_data = result.data.filter(volume => !(volume.bootable && volume.root_device) || (volume.imageMetadata&&volume.imageMetadata.disk_format == "iso"));
                self.data_data= self.data_data.filter(item => !( item.metaData && (item.metaData.image_cache_name || item.metaData.glance_image_id)) || (item.imageMetadata&&item.imageMetadata.disk_format == "iso"));
                // self.data_data= self.data_data.filter(item => !(item.name&&item.name.indexOf("backup-vol") > -1));
                successFunc(self.data_data);
            }
            self.totalNum = result.total;	
        });
    }
    function successFunc(res){
		self.data_data = self.configSearch(self.data_data,self.tableCols);
        self.dataTableParams = new NgTableParams({ count: self.pageCount }, { counts: [], dataset: self.data_data });
        newCheckedSrv.checkDo(self,"","id","dataTableParams");
    };

    self.resetVolumeStatus = function(obj) {
        if(!self.btn.resetVol) return;
        var info='迁移中的云硬盘可以把状态修改为正常状态(可以或已使用,和迁移前的状态相同),但执行这个操作时,需要确认当前云硬盘的迁移操作已经中断(即实际的迁移操作已经失败,但云硬盘状态没有恢复),否则会导致当前的迁移操作失败';
        var content = {
            target: "resetVolumeStatus",
            msg: "<div class='resetVolume-info'>" + info + "</div>",
            data: obj
        };
        self.$emit("delete", content);
    };
    self.$on("resetVolumeStatus", function(e,data) {
        volumesSrv.resetStatus(data.id).then(function(){
            self.getData();
        })
    });

    self.noBackupsModal = function(dis){
        if(dis) return;
        var modal_os = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/volumes/tmpl/addBackupTip.html",
        });
    }
    self.lineDetailIn = function (item) {
        
        utilTool.setLocalStorage([item]);
        $location.url(`/cvm/volumes?id=${item.id}`)
    }
    self.$watch(function () {
        return volumesDataSrv.clear;
    }, function(value, old) {
        if (value !== old) {
            self.checkboxesdataTableParams.items = {};
        }
    },true)
    
    self.getData = function(){
        var options = {
            "pageNum": 1,
            "pageSize": 10,
            "type": 0
        }
        self.currentPage = 1;
        self.globalSearchTerm = "";
        if(!localStorage.cinderService) return;
        volumesDataSrv.getVolumeList(options).then(function(result) {
            if(result && result.data && angular.isArray(result.data)){
                //过滤掉result.data中vol.instance="fixedImageInstanceName"的元素
                //result.data=result.data.filter(vol=>vol.instance[0]!="fixedImageInstanceName");
                // result.data=result.data.filter(vol=>{
                //     if(vol.name){
                //         return vol.name.split("-")[0]!="fixedImageInstanceName"
                //     }else{
                //         return true;
                //     }
                // });
                result.data.map(item => {
                    item.statusCopy= $translate.instant("aws.volumes.table.status."+item.status);
                    item.multiattachCopy = $translate.instant('aws.volumes.table.multiattach.'+item.multiattach);
                    item.bootableCopy = $translate.instant('aws.volumes.table.bootable.'+item.bootable);
                    item.voltype =  item.storageInfo?item.storageInfo.disPlayName:"";
                    item._type=angular.copy(item.type);
                    if(item.domainName=="default"){
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    }else{
                        item.domainName = item.domainName;
                    }
                    if(item.projectName=="admin"){
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    }else{
                        item.projectName = item.projectName;
                    }
                    if (item.description == null) {
                       item.description = '';
                    }else if(item.description == "auto-created_from_restore_from_backup"){
                        item.description = $translate.instant("aws.volumes.fromBackup");
                    }
                    if(angular.isArray(item.attachments) && item.attachments.length == 1 && item.attachments[0].device && item.attachments[0].device.slice(-2) == "da"){
                        item.root_device = true;
                    }
                }            );
                self.data_data = result.data.filter(volume => !(volume.bootable && volume.root_device) || (volume.imageMetadata&&volume.imageMetadata.disk_format == "iso"));
                self.data_data= self.data_data.filter(item => !( item.metaData && (item.metaData.image_cache_name || item.metaData.glance_image_id)) || (item.imageMetadata&&item.imageMetadata.disk_format == "iso"));
                // self.data_data= self.data_data.filter(item => !(item.name&&item.name.indexOf("backup-vol") > -1));
                successFunc(self.data_data);
            }
            self.totalNum = result.total;
        });
    }
    self.getData()

    self.refreshData = function() {
        if(self.searchList && self.searchList.length){
            self.statusSelected.searchItem = self.searchList[0];
        }else{
            self.statusSelected.searchItem = null;
        }
    }

    self.volume.initTablefunc = function(){
        self.getData()
    }
    $scope.$on("backupSocket",function(e,data){
        if( self.data_data.length){
            self.data_data.map(function(obj){
                if(obj.id === data.eventMata.volume_id){
                    obj.status = data.eventMata.status;
                }
            });
            if(data.eventType=='backup.restore.end'){
                self.getData()
            }
        }
        if(self.dataTableParams){
            self.dataTableParams.reload();
            self["checkboxes"+ "dataTableParams"].items={};
        }
    });

    $scope.$on("volumeSocket",function(e,data){
        if( self.data_data.length){
            self.data_data.map(function(obj){
                if(obj.id === data.eventMata.volume_id){
                    obj.status = data.eventMata.status;
                    if(obj.status == "deleted"){
                        _.remove(self.data_data,function(val){
                            return val.status =="deleted";
                        });
                    }
                    if(self.volumeSize && data.eventMata.status =="available"){         //更新扩容值
                        obj.size = self.volumeSize;
                        self.volumeSize = "";
                    }
                }
            });
        }
        if(self.dataTableParams){
            self.dataTableParams.reload();
            self["checkboxes"+ "dataTableParams"].items={};
        }
        
    });

    // 表格checkbox选中触发
    self.$watch(function(){
        return self.checkedItemsdataTableParams;
    },function(values,old){
        if(values&&old&&values.length!=old.length){
            utilTool.setLocalStorage(values);
        }
        self.tablchk(values)
    })

     //新建云硬盘
    self.addVolume = function(type){
        if (type === false) {
            utilTool.newResSetLocalStorage(self, 'checkboxesdataTableParams', 'items')
        } 
        // 滞空选中的项目
        $rootScope.depart_pro = '';
        $rootScope.project_pro = '';
        let templateUrl = localStorage.cinderService ? "js/cvm/volumes/tmpl/addVolume.html": "js/cvm/volumes/tmpl/addVolumeTip.html";
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: templateUrl,
            controller: localStorage.cinderService ? "addVolumeCtrl" : "",
            resolve: {
                initTable: function() {
                    return self.refreshData;
                },
                singleway:function(){
                    return type;
                }
            }
        });
    }

    self.insAnimation = "animateOut";
    self.newInstance = function(editData){
        if(!self.btn.canNewIns) return;
        var path = "/cvm/volumes?from=volume&volumeId="+editData.id
        $location.url(path);
        self.insAnimation = "animateIn";
        $("body").addClass("animate-open")
        // var path = "/quickconfig/createins?from=volume&volumeId="+editData.id
        // $location.url(path);
    }
    self.closeNewIns = function(){
        self.insAnimation = "animateOut";
        $("body").removeClass("animate-open");
        $location.url("/cvm/volumes");
    }

     //下拉框中的强制删除
     self.forceDelVolumes = function(checkedItems){
        if(!self.btn.canForeDel || self.btn.delisDisabled) return;
        var content = {
                target: "forceDeleteVolumes",
                msg: "<span>" + $translate.instant("aws.volumes.dv.confirmForce") + "</span>",
                data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("forceDeleteVolumes", function(e,data) {
        if (data.length == 1) {
            volumesDataSrv.cpDelOneVolumeAction(data[0].id).then(function() {
                //initDataVolumesTable();
            });
        } else if (data.length > 1) {
            //获取删除的卷id数组
            var volume_ids=[];
            _.forEach(data,function(item){
                volume_ids.push(item.id);
            });
            var delParams = {
                ids: volume_ids
            };
            volumesDataSrv.cpDelVolumes(delParams).then(function(){
                //initDataVolumesTable();
            });
        }
    });

    self.loadingVolume = function(editData,init) {
        if(!self.btn.canAttach) return;
        
        var modalInstance =  $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "loadingVolumeModel.html",
            controller: "loadingVolumeCtrl",
            resolve: {
                initDataVolumesTable: function() {
                    return init;
                },
                editData: function() {
                    return editData;
                }
            }
        });
        return modalInstance.result.then(function(post){
            if(post){
                self.data_data.map(item=>{
                    if(item.id == post.id){
                        item.status = "attaching"
                    }
                })
            }
        });
    };
    function checkScheduleJob(scope,data,volume_id,type){
        scope.show_del_job_tip = false;
        scope.getJobs = false;
        scope.jobIds =[];
        var checkData = {
            serverIds:[data.id],
            volumeId:volume_id
        }
        instancesSrv.checkSheduleJob(checkData).then(function(result){
            if(result && result.data){
                scope.jobIds = result.data;
                scope.getJobs = true;
                scope.show_del_job_tip = Boolean(result.data.length);
            }
            
        })
    }


    function isVolumeHasBackupJob(volumeId,scope,editData){
        let volumeIdArr=[volumeId];
        let insArr=[editData.id];
        let params={
            'volumeId':volumeIdArr,
            'instanceId':insArr
        }
        instancesSrv.checkServerHasBackupJob(params).then(function(res){
            if(res&&res.data&&angular.isArray(res.data)){
                for(var i=0;i<res.data.length;i++){
                    //云主机有备份任务存在，请先解绑备份任务，再进行删除操作
                    if(volumeId==res.data[i].volumeId){
                       scope.volumeHasBackupJob=true; 
                       break;
                    }
                } 
            }
        });
    }
    self.uninstallVolume = function(editData) { 
        if(!self.btn.canUnstall||self.btn.canUnstall2) return;
        let options = {};
        let scope = self.$new();
        scope.volumeHasBackupJob=false;
        let delInsName="";
        options.volumeId= editData.id;
        //调取详情列表，获取云硬盘加载的云主机
        volumesDataSrv.detailVolumeData(editData.id).then(function(result) {
            if(result && result.data){
                scope.volume_ins_id_list=result.data[0].insnameAndId;
                scope.volume_host=scope.volume_ins_id_list[0];
                options.serverId=scope.volume_host.id;
                delInsName=scope.volume_host.name;
                // 获取当前要卸载的云主机的状态，当状态为搁置shelved_offloaded时不允许卸载
                let insStatus = scope.volume_host.status.toLowerCase();
                self.unInsDisabled = insStatus == 'shelved_offloaded' ? true : false;
                checkScheduleJob(scope,scope.volume_host,editData.id)
                isVolumeHasBackupJob(editData.id,scope,scope.volume_host);
            }
        });
       
        scope.change_host=function(obj){
            scope.volumeHasBackupJob=false;
            options.serverId=obj.id;
            delInsName=obj.name;
            let insStatus = obj.status.toLowerCase();
            self.unInsDisabled = insStatus == 'shelved_offloaded' ? true : false;
            checkScheduleJob(scope,obj,editData.id);
            isVolumeHasBackupJob(editData.id,scope,obj);
        };


        let modalInstance2 =  $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "uninstallVolume.html",
            scope:scope
        });
        scope.confirm_unstall=function(){
            modalInstance2.close();
            self.refreshData();
            volumesDataSrv.uninstallVolumeAction(options).then(function() {
            });
            if(scope.jobIds.length){
                var post = {
                    ids:scope.jobIds,
                    volumeId:editData.id
                }
                regularSnapSrv.updateTaskBack(post).then(function(){
                })
            }
        };
        return modalInstance2.result.then(function(){
        });
    };

    //下拉框中的扩容
    self.expandVolumeSize = function(editData,init) {
        if(!self.btn.canExtend) return;
        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "expandVolumeSizeModel.html",
            controller: "expandVolumeSizeCtrl",
            resolve: {
                initDataVolumesTable: function() {
                    return init;
                },
                editData: function() {
                    return editData;
                }
            }
        });
        return modalInstance.result.then(function(post){
            self.volumeSize = post;
        });
    };

    //备份
    self.volumeBackup = function(editData,init){
        if(!self.btn.canBackup || self.btn.hasTaskbackup) return;
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/volumes/tmpl/volumeBackup.html",
            controller: "volumeBackupCtrl",
            resolve: {
                initTable: function() {
                    return init;
                },
                editData: function() {
                    return editData;
                }
            }
        });
    }

    //下拉框中的创建快照
    self.createSnapshot = function(editData,init) {
        if(!self.btn.canCS || self.btn.hasTasksnapshot) return;
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "createSnapshotModel.html",
            controller: "createSnapshotCtrl",
            resolve: {
                initDataVolumesTable: function() {
                    return init;
                },
                editData: function() {
                    return editData;
                }
            }
        });
    };
}
function loadingVolumeCtrl($scope, $timeout,volumesDataSrv, $uibModalInstance, $translate, initDataVolumesTable, editData,NgTableParams){
    var self = $scope;
    self.selected = {
        oneInstance:''
    }
    self.pageSize = 8;
    self.globalSearchTerm = "";
    self.changePage=function(options){
        options.pattern = self.globalSearchTerm?self.globalSearchTerm:"";
        var pageOption = angular.extend(self.options,options)
        initCanLoadIns(pageOption);
    };
    //后端搜索
    self.searchBackend = function(){
        var pageOption = {
            pageSize: self.pageSize,
            pageNum:1,
            pattern:self.globalSearchTerm?self.globalSearchTerm:"",
        };
        self.currentPage = 1;
        var pageOption1 = angular.extend(self.options,pageOption);
        //initCanLoadIns(pageOption1);
        $timeout.cancel(self.sendSearch);
        self.sendSearch = $timeout(()=>{
            initCanLoadIns(pageOption1);
        },1000)
    }
    
    
    volumesDataSrv.detailVolumeData(editData.id).then(function(res) {
        if(res && res.data){
            self.options = { instance : res.data[0].instance.length?res.data[0].instance.join(","):""};
            var options = {
                "pageNum": 1,
                "pageSize": 8,
                "pattern":""
            }
            self.currentPage = 1;
            var pageOption = angular.extend(self.options,options)
            initCanLoadIns(pageOption)
        }
    })
    
    function initCanLoadIns(options){        
        volumesDataSrv.getHostActionList(options).then((res)=>{
            if(res&&res.data){
                self.HostActionList = res.data;
                self.totalNum = res.total;
                self.selected.oneInstance = JSON.stringify(self.HostActionList[0]) 
                self.hostActionListTable = new NgTableParams({ count: 999 }, { counts: [], dataset: self.HostActionList });
            }
        })
    }

    self.volumeConfirm = function() {
        
        var options = {};
        options["volumeId"] = editData.id;
        options["serverId"] = JSON.parse(self.selected.oneInstance).uid;
        volumesDataSrv.loadingVolumeAction(options).then(function(result) {
            if(result && !result.status){
                $uibModalInstance.close(editData);
            }else{
                $uibModalInstance.close();
            }
            initDataVolumesTable();
        });
    };
}
function expandVolumeSizeCtrl($scope,$rootScope ,volumesDataSrv, $translate, $uibModalInstance, initDataVolumesTable, editData){
    var self = $scope;
    var proTotalGig,proUsedGig,proRemainderGig;
    var depTotalGig,depUsedGig,depRemainderGig;	
    const key = $rootScope.g_volume=="data"?"tip6":"tip11";
    self.nowItem = $translate.instant(`aws.helpTip.volumes.${key}`)
    self.isShowDep=false;
    self.isShowPro=false;
    self.canConfirm=true;
    self.submitted = false;
    self.interacted = function(field) {
        return self.submitted || field.$dirty;
    };
    self.volumeForm =angular.copy(editData) ;
    var oldSize=editData.size;
    self.errorExpand={
        "cantExpandDep": $translate.instant("aws.volumes.errorMessage.cantExpandDep"),
        "cantExpandPro": $translate.instant("aws.volumes.errorMessage.cantExpandPro")
    };
    //判断项目总配额减去部门已使用的配额的函数
    var proGigFunc=function(value){
        volumesDataSrv.getProHave("gigabytes").then(function(result){
            if(result && result.data && result.data.length){
                proTotalGig=result.data[0].hardLimit;
            }
            volumesDataSrv.getProUsed("gigabytes").then(function(result){
                if(result && result.data && result.data.length){
                    proUsedGig=result.data[0].inUse;
                }else{
                    proUsedGig=0;
                }
                return proUsedGig;
            }).then(function(proUsedGig){
                proRemainderGig=parseInt(proTotalGig)-parseInt(proUsedGig);
                if(proRemainderGig<value){
                    self.isShowPro=true;
                    self.errorMessagePro=self.errorExpand.cantExpandPro;
                    self.canConfirm=false;	
                }
                self.project_data={
                    title:$translate.instant("aws.project.quota"),
                    inUsed:parseInt(proUsedGig),
                    beAdded:parseInt(value),
                    total:proTotalGig
                };	
            });
        });
    };

    //判断部门总配额减去部门已使用的配额的函数
    var depGigFunc=function(value){
        volumesDataSrv.getQuotaTotal("gigabytes").then(function(result){
            if(result && result.data && result.data.length){
                depTotalGig=result.data[0].hardLimit;
            }else{
                proGigFunc(value);
            }
            volumesDataSrv.getQuotaUsed("gigabytes").then(function(result){
                if(result && result.data && result.data.length){
                    depUsedGig=result.data[0].inUse;
                }else{
                    depUsedGig=0;
                }
                return depUsedGig;
            }).then(function(depUsedGig){
                depRemainderGig=parseInt(depTotalGig)-parseInt(depUsedGig);
                if(depRemainderGig<value){
                    self.isShowDep=true;
                    self.errorMessageDep=self.errorExpand.cantExpandDep;
                    self.canConfirm=false;
                    /*self.project_data={
                        title:$translate.instant("aws.project.quota"),
                        inUsed:parseInt(self.project_data.inUsed)+parseInt(value),
                        total:proTotalGig
                    };*/
                    proGigFunc(value);
                }else{
                    proGigFunc(value);
                }
                self.domain_data={
                    title:$translate.instant("aws.depart.quota"),
                    inUsed:parseInt(depUsedGig),
                    beAdded:parseInt(value),
                    total:depTotalGig
                };	
            });
        });
    };
    depGigFunc(1);
    // if(localStorage.permission=="enterprise"){
    //     depGigFunc(1);
    // }

    self.isShowError=function(value){
        self.isShowDep=false;
        self.isShowPro=false;
        self.canConfirm=true;
        if(value!=undefined){
            var expandSize=parseInt(value)-parseInt(oldSize);
            depGigFunc(expandSize);
        }else{
            depGigFunc(1);
        }
    };
    self.volumeConfirm = function() {
        const now_active = $rootScope.g_volume
        var postVolumeParams = {
            id: editData.id,
            size: parseInt(self.volumeForm.size)
        };
        
        var expandVolume = async function(){
            $uibModalInstance.close(parseInt(self.volumeForm.size));
            self.canConfirm=true;
            // await volumesDataSrv.expandVolumeSizeAction(postVolumeParams)
            // return
            if(now_active == "data"){
                await volumesDataSrv.expandVolumeSizeAction(postVolumeParams)
            }else if(now_active == "system"){
                await volumesDataSrv.sysExpandVolumeSizeAction(postVolumeParams)
                
            }
        };
        
        if (self.expandVolumeSize.$valid) {
            self.canConfirm=false;
            expandVolume();
        } else {
            self.submitted = true;
        }
    };
    self.cancel = function() {
        $uibModalInstance.dismiss("cancel");
        initDataVolumesTable();
    };
}
function createSnapshotCtrl($scope, volumesDataSrv, $translate, $uibModalInstance, initDataVolumesTable, editData,checkedSrv){
    var self = $scope;
    var proTotalSnap,proUsedSnap,proRemainderSnap;
    var depTotalSnap,depUsedSnap,depRemainderSnap;
    self.canConfirm=true;
    self.isShowDepSnapNum=false;
    self.isShowProSnapNum=false;
    self.submitted = false;
    self.interacted = function(field) {
        return self.submitted || field.$dirty;
    };
    self.snapShot = {
        "volumeId": editData.id,
        "name": "",
        "description": ""
    };
    self.errorSnap={
        "depSnapGig": $translate.instant("aws.volumes.errorMessage.depSnapGig"),
        "proSanpGig":$translate.instant("aws.volumes.errorMessage.proSanpGig"),
        "depSnapNum":$translate.instant("aws.volumes.errorMessage.depSnapNum"),
        "proSnapNum":$translate.instant("aws.volumes.errorMessage.proSnapNum")
    };
    //判断项目快照的总配额数与已使用数的函数
    var proSnapFunc=function(){
        volumesDataSrv.getProHave("snapshots").then(function(result){
            if(result && result.data && result.data.length){
                proTotalSnap=result.data[0].hardLimit;
            }
            volumesDataSrv.getProUsed("snapshots").then(function(result){
                if(result && result.data && result.data.length){
                    proUsedSnap=result.data[0].inUse;
                }else{
                    proUsedSnap=0;
                }
                return proUsedSnap;
            }).then(function(proUsedSnap){
                proRemainderSnap=parseInt(proTotalSnap)-parseInt(proUsedSnap);
                if(proRemainderSnap<1){
                    self.isShowProSnapNum=true;
                    self.errorMessagePro=self.errorSnap.proSnapNum;
                    self.canConfirm=false;
                }
                self.project_data_snap={
                    title:$translate.instant("aws.project.quota"),
                    inUsed:proUsedSnap,
                    beAdded:1,
                    total:proTotalSnap
                };
            });
        });	
    };
    //判断部门快照的总配额数与已使用数的函数
    var depSnapFunc=function(){
        volumesDataSrv.getQuotaTotal("snapshots").then(function(result){
            if(result && result.data && result.data.length){
                depTotalSnap=result.data[0].hardLimit;
            }else{
                proSnapFunc();
            }
            volumesDataSrv.getQuotaUsed("snapshots").then(function(result){
                if(result && result.data && result.data.length){
                    depUsedSnap=result.data[0].inUse;
                }else{
                    depUsedSnap=0;
                }	
                return depUsedSnap;
            }).then(function(depUsedSnap){
                depRemainderSnap=parseInt(depTotalSnap)-parseInt(depUsedSnap);
                if(depRemainderSnap<1){
                    self.isShowDepSnapNum=true;
                    self.errorMessageDep=self.errorSnap.depSnapNum;
                    self.canConfirm=false;
                    proSnapFunc();
                }else{
                    proSnapFunc();
                }
                self.domain_data_snap={
                    title:$translate.instant("aws.depart.quota"),
                    inUsed:depUsedSnap,
                    beAdded:1,
                    total:depTotalSnap
                };
            });
        });
    };

    depSnapFunc();
    
    self.volumeConfirm = function() {
        self.postParams={
            "volumeId": editData.id,
            "name":self.snapShot.name,
            "description":self.snapShot.description
        };
        var createSnap=function(){
            $uibModalInstance.close();
            self.canConfirm=true;
            checkedSrv.setChkIds([editData.id],"snapshotcreate")
            volumesDataSrv.createSnapshotAction(self.postParams).then(function() {
                initDataVolumesTable();
            });
        };

        if (self.createVolumeForm.$valid) {
            self.canConfirm=false;
            createSnap();
        } else {
            self.submitted = true;
        }
    };
    self.cancel = function() {
        $uibModalInstance.dismiss("cancel");
    };
}

export {dataVolumesCtrl,loadingVolumeCtrl,expandVolumeSizeCtrl,createSnapshotCtrl}
