backupRecoverJobManage.$inject = [];
function backupRecoverJobManage() {
    return {
        restrict:"E",
        scope:{
            data:"=",
            draggable:"=",
            index:"="
        },
        replace:true,
        templateUrl:"js/advanced-function/backup/backup-job/tmpl/backup-recover-manage.html",
        controller:'backupRecoverJobCtrl',
        link: function(self, elem, attrs, $ngModel) {
            let elema = $(elem)[0];
            elema.ondragstart = function(event){
            }
            elema.ondragenter = function(event){
                event.preventDefault();
            }
            elema.ondragover = function(event){
                event.preventDefault();
            }
        }
    };
}
export {backupRecoverJobManage}