 nodeListTpl.$inject = ["storageManagementSrv"]
 function nodeListTpl(storageManagementSrv) {
    return {
        restrict:"EA",
        replace:true,
        scope:{
            checkedItems:"=",
            nodeData:"="
        },
        templateUrl:"js/system/storageManagement/tmpl/node-list-tpl.html",
        link: function(self, elem, attrs, $ngModel) {
            
        },
        controller:function($scope,storageManagementSrv,TableCom,storageUtil){
            let self = $scope;
            self.checkboxes = {
                items:{}
            };
            self.$on("clear-checkedbox",function(e,data){
                self.checkboxes.items = {};
                self.checkedItems = [];
            })
            function initDataFn(data){
                data.forEach(v=>{
                    v.hostName = v.nodeName;
                    v.hostInfoMap.ssdAll = new Number();
                    v.hostInfoMap.sataAll = new Number();
                    v.hostInfoMap.disks = v.diskAttributesList||[];
                    let data = v.hostInfoMap.disks;
                    var ssdAll=0;
                    var sataAll=0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].ssd) {
                            ssdAll += storageUtil.changeUnit(data[i].capacity);
                        } else {
                            sataAll += storageUtil.changeUnit(data[i].capacity);
                        }
                    }
                    if(ssdAll>0){
                        v.hostInfoMap.ssdAll = storageUtil.setUnit(ssdAll).total;
                        v.hostInfoMap.ssdUnit = storageUtil.setUnit(ssdAll).unit;
                    }
                    if(sataAll>0){
                        v.hostInfoMap.sataAll = storageUtil.setUnit(sataAll).total;
                        v.hostInfoMap.sataUnit = storageUtil.setUnit(sataAll).unit;
                    }
                })
                data = data.sort(storageUtil.sortNumber); 
                TableCom.init(self,'nodetable',data,"nodeUid",{page:5,counts:[5,20,50]},'checkboxes');
                return data;
            }
            storageManagementSrv.getAvailableDisks(localStorage.regionKey).then(res=>{
                if(res&&res.data){
                    //self.nodeData = initDataFn(res.data);
                    let disk = [
                        {
                            "name": "sdb",
                            "capacity": "5.46 TB",
                            "rotational": "1",
                            "ssd": false,
                            "byId": "/dev/disk/by-id/wwn-0x5000c5008c035eb4"
                        },
                        {
                            "name": "sdc",
                            "capacity": "5.46 TB",
                            "rotational": "1",
                            "ssd": false,
                            "byId": "/dev/disk/by-id/wwn-0x5000c5008c035eb5"
                        },
                        {
                            "name": "sdd",
                            "capacity": "5.46 TB",
                            "rotational": "1",
                            "ssd": true,
                            "byId": "/dev/disk/by-id/wwn-0x5000c5008c035eb6"
                        },
                        {
                            "name": "sdf",
                            "capacity": "5.46 TB",
                            "rotational": "1",
                            "ssd": true,
                            "byId": "/dev/disk/by-id/wwn-0x5000c5008c035eb7"
                        }
                    ]
                    let data = [];
                    for(let i=0;i<9;i++){
                        let obj = {};
                        obj.nodeName = "node-"+i;
                        obj.nodeUid = "node-"+i;
                        obj.hostInfoMap = res.data[0].hostInfoMap;
                        obj.diskAttributesList = disk;
                        data.push(obj)
                    }
                    self.nodeData = initDataFn(res.data);
                }
            })
            
            self.$watch(function() {
                return self.checkboxes.items;
            }, function(val) {
                if(val){
                    self.checkedItems = [];
                    var arr = [];
                    for (var i in self.checkboxes.items) {
                        arr.push(self.checkboxes.items[i]);
                    }
                    if (val && arr.length >= 0) {
                        for (var key in val) {
                            if (val[key]) {
                                self.nodeData.forEach(item => {
                                    if (item.nodeUid == key) {
                                        self.checkedItems.push(item);
                                    }
                                });
                            }
                        }
                    }
                }
            }, true); 
        }
    };
};
export {nodeListTpl}