logoSettingSrv.$inject = ["$rootScope", "$http"]
function logoSettingSrv($rootScope, $http) {
    var static_url2="awstack-user/v1/";
    return {
        modifyLogoInfo:function(oData) {
            return $http({
                url: static_url2+"logo/set",
                method: "post",
                data: oData,
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            })
        },
        
    }
}
export {logoSettingSrv}