storageFileTpl.$inject = [];
function storageFileTpl() {
    return {
        restrict:"E",
        replace:true,
        scope:{
            storageUseList:"=",
            initStorageTable:"="
        },
        templateUrl:"js/system/storageManagement/tmpl/storage-file-tpl.html",
        link: function(self, elem, attrs, $ngModel) {
            
            //self.currentRadio = self.data.current;
        },
        controller:function($scope,storageInitList,$translate,storageManagementSrv){
            let self = $scope;
            self.fileInit = init();
            self.objectInit = init();
            let storageInitInstan = storageInitList.init();
            self.form = {
                name:'',
                storage: self.fileInit.use.type,
                defaultRadio:'0',
                agreement: {
                    FUSE: true,
                    NFS: false,
                },
                URL:'',
                port:'',
                // login:'',
                // pwd:'',
                apiSecretKey:'',
                apiPrivateKey:'',
                describe:''
            }
            self.checkboxChange = function(value){
                self.form.agreement.push(value)
            }
            // self.agreement = [
            //     {name:'nfs',value:'nfs'},
            //     {name:'smb',value:'smb'},
            // ]
            // self.selectValue = self.agreement[0].value;
            // self.changeValue = function(value){
            //     self.selectValue = value
            // }

            self.isSelectAgreement = false
            self.checkboxChange = function(value, key){
                if(Object.values(self.form.agreement).filter(item=>item).length == 0) {
                    self.isSelectAgreement = true
                } else {
                    self.isSelectAgreement = false
                }
            }

            self.modalTitle = $translate.instant("aws.system.storagement.addTitle");
            self.storageObjectInit = {
                deviceList:angular.copy(storageInitInstan.storageDeviceList.file),
                // current:angular.copy(storageInitList.storageDeviceList.object)[0].id,
            }
            self.submitValid = false;
            function init(){
                let curUse = self.storageUseList.filter(item=>item.id==3);
                return {
                    use:curUse[0],
                    description:"",
                    name:"",
                }
            }
            
            //切换存储用途
            self.changeUse = function(obj) {
                self.$emit('storage-use-change',obj)
            }
            //
            self.storageConfirm = function(objectForm){
                if(objectForm.$valid && !self.isSelectAgreement){
                    const {name,describe,login,pwd,port,URL,apiSecretKey,apiPrivateKey,agreement} = self.form
                    let str = [];
                    for (const key in agreement) {
                        if(agreement[key]){
                            str.push(key)
                        }
                    }
                    const config = {
                        name,
                        description:describe,
                        enterpriseUid: localStorage.getItem('enterpriseUid'),
                        regionKey: localStorage.getItem('regionKey'),
                        regionUid: localStorage.getItem('regionUid'),
                        storageConfiguration: JSON.stringify({
                            ApiAccessKey: apiSecretKey,
                            ApiSecretKey: apiPrivateKey,
                            // password:pwd,
                            port,
                            url: URL,
                            // username: login,
                            storageAgreement:str.join(','),
                            protocol: str
                        }),
                        storageFirm: 'file_CSP',
                        storageType: self.objectInit.use.id,
                        storageUse: self.objectInit.use.id,
                    }
                    storageManagementSrv.addCspStorage(config).then(function(res){
                        if(res){
                            self.initStorageTable();
                            self.$emit('storage-modal-close',{})

                            // 修改文件存储路由
                            const menuList = localStorage.getItem('menuList')
                            const menuListObj = JSON.parse(menuList)

                            // 跳出最外层
                            for (let index = 0; index < menuListObj.length; index++) {
                                const item = menuListObj[index];
                                if(item.keywords == 'Resource'){
                                    for (let i = 0; i < item.child.length; i++) {
                                        const child_item = item.child[i]
                                        if(child_item.keywords == 'Resource_Storage'){
                                            for (let j = 0; j < child_item.child.length; j++) {
                                                const child_item_last = child_item.child[j]
                                                if(child_item_last.keywords == 'Resource_Storage_Shares'){
                                                    child_item_last.active= "fileStorage";
                                                    child_item_last.href= "/cvm/fileStorage";
                                                    child_item_last.keywords= "Resource_Storage_CSP";
                                                    break 
                                                }
                                            }
                                            break
                                        }
                                    }
                                    break
                                }
                            }

                            localStorage.setItem('menuList', JSON.stringify(menuListObj))
                        }
                    })
                }else{
                    self.submitValid = true;
                }
            };
            
            self.$dismiss = function(){
                self.$emit('storage-modal-close',{})
            }
        }
    };
};
export {storageFileTpl}