angular.module("aboutModule", ["ngSanitize", "ngRoute", "ngTable", "ngAnimate", "ngMessages", "ui.bootstrap", "ui.select"])
    .controller("aboutCtrl", ["$http","$scope", "$rootScope","$translate","utilTool",
        function ($http,$scope, $rootScope, $translate,utilTool) {
    	let rootScope = $rootScope;
        $http({
            url: "/awstack-user/v1/platform/version",
            method:"GET"
        }).then(function(res){
            if(res&&res.data){
                let version = utilTool.changePlatformV(res.data.curVersion)
                res.data.description =JSON.parse(res.data.description); 
                rootScope.versionType=$translate.instant("aws.aboutVersion.versionEnterprise");
                // rootScope.versionType=localStorage.permission=="stand"?$translate.instant("aws.aboutVersion.versionStand"):$translate.instant("aws.aboutVersion.versionEnterprise");
                rootScope.systemVersion = $translate.instant("aws.aboutVersion.systemVersion")+":"+version;
                rootScope.systemVersionCon = res.data.curVersion;
                // rootScope.desc= res.data.description.desc;
                rootScope.siteTitle = res.data.description.title;
                // rootScope.copyright = res.data.description.copyright;
            }
        });
        
    }]);
