angular.module("cloudDatabaseSrvModule",[])
    .service("cloudDatabaseSrv",($http)=> {
        return {
            // 查询云数据库表格
            getCloudDatabaseList: function(params){
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/instance",
                    params: params
                })
            },

            // 查询数据库类型
            getServerType: function(params) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/serverType",
                    params: params
                })
            },

            // 查询操作系统和默认配置项
            getSystemConfig: function(params) {
                return $http({
                    method: "GET",
                    url: "awstack-user/v1/params",
                    params: params
                })
            },

            // 查询镜像
            getImageList: function(params) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/images",
                    params: params
                })
            },

            // 查询镜像
            getImageByOs: function(option,params) {
                return $http({
                    method: "GET",
                    url: `/awstack-manage/v2/imagesByOs/${option}`,
                    params: params
                })
            }
        }
    })