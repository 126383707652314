var sysBackupSrvModule = angular.module("sysBackupSrvModule", []);

sysBackupSrvModule.service("sysBackupSrv", ["$http", function($http) {
    return {
        getDataList:function(){
			return $http({
				method:"GET",
				url:"awstack-user/v1/backupTask"
			})
		},
        onekeyBackup:function(){
			return $http({
				method:"post",
				url:"awstack-user/v1/backupTask"
			})
		}
    };
}]);