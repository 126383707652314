var localInitModule = angular.module("localInitModule", []);

localInitModule.service("localInit", ["$rootScope", "commonFuncSrv","$http", "$location","alertSrv",function(rootscope, commonFuncSrv,$http,$location,alert) {
    var static_url = "http://192.168.138.134:8080/awstack-user/v1";
    var static_url_code="http://192.168.140.126:9080/awstack-user/v1";
    this.clearLocalstorage = function(){
        let localList = ['pwdLastUpdatedTime','pwdExpiredDate','$AUTH_TOKEN','enterpriseUid',
            'managementRole','userName','regionName','regionKey','domainUid','domainName','projectName',
            'projectUid','firstLogin','firstInitSetting','platformInitialized','canPlatformInit','userUid',
            '$USERID','permission','$LOGINDATA','defaultdomainUid','defaultdomainName','defaultProjectUid',
            'defaultProjectName','enterpriseLoginName','backupsService',,'cinderService','isCustom','isEnabledArbiter',
            'cephService','supportOtherClouds','supportPaas','installK8s','installIronic','TotalListName','$AWLOGINED',
            'LicenseList','menuList','loginUrl','vmware_flag','regionNum','createTime','pwdForceModify','regionBusiAuth',"selectedAllDomain","nodePrettyNameList",
            "noProject","isLocalization","localDiskService"
        ];
        for(let i=0;i<localList.length;i++){
            localStorage.removeItem(localList[i]);
        }
        
    }
    
    this.localstorageInit = function(data) {
        if(data.token){
            //localStorage.$AUTH_TOKEN =  result.data.data.token;
            localStorage.$AUTH_TOKEN =  data.authToken;
        };
        
        localStorage.pwdLastUpdatedTime = data.pwdLastUpdatedTime; //密码上次修改的时间
        localStorage.pwdExpiredDate = data.pwdExpiredDate; //密码过期时间
        localStorage.pwdForceModify= data.pwdForceModify?1:2; //新用户强制修改密码开关
        localStorage.createTime= data.createTime;//用户创建时间
        localStorage.enterpriseUid = data.enterpriseUid;
        localStorage.managementRole = data.managementRole;
        localStorage.userName = data.userName;
        // self.$emit('region-refresh',{type:'changeRegion',data:result.data.data});
        localStorage.regionName = data.regionName;
        localStorage.regionKey = data.regionKey;
        localStorage.regionUid = data.regionUid;
        localStorage.regionNum = data.regionList.length;
        localStorage.domainUid = data.domainUid; //部门Uid
        localStorage.domainName = data.domainName; //部门Name
        localStorage.projectName = data.defaultProjectName; //项目Name
        localStorage.projectUid = data.defaultProjectUid; //项目Uid
        localStorage.firstLogin = data.firstLogin; //首次登录
        rootscope.firstInitSetting = data.firstLogin;
        localStorage.platformInitialized = data.platformInitialized; //初始化设置是否完成
        if (data.managementRole == 3 && data.defaultProjectName == "admin_default" && data.projectList && data.projectList.length > 0) {
            localStorage.projectName = data.projectList[0].projectName; //项目Name
            localStorage.projectUid = data.projectList[0].projectId; //项目Uid
        }
        //如果部门管理员的默认项目删掉了，云管初始化不显示
        if (data.defaultProjectName == "admin_default") {
            localStorage.canPlatformInit = 1;
        }

        localStorage.userUid = data.userUid;
        //localStorage.rolename = ""; 当给项目管理员重新分配为member角色时，云端未同步，所以不以这里的rolename为准
        localStorage.$USERID = data.id;
        //localStorage.permission = data.enterprise;
        localStorage.permission = data.version == "2" ? 'enterprise' : "stand";
        localStorage.setItem("$LOGINDATA", JSON.stringify(data));
        localStorage.defaultdomainUid = data.domainUid; //默认部门Uid
        localStorage.defaultdomainName = data.domainName; //默认部门Name
        localStorage.defaultProjectUid = data.defaultProjectUid; //默认项目Uid
        localStorage.defaultProjectName = data.defaultProjectName; //默认项目Name
        localStorage.enterpriseLoginName = data.enterpriseLoginName;
        localStorage.backupsService = data.backupService ? "backups" : ""; //true为超融合模式默认部署了ceph。
        localStorage.cinderService = (data.cinderService || data.isCustom) ? "cinder" : "";
        localStorage.isCustom = data.isCustom; //true为超融合模式，false为软件交付模式
        localStorage.isEnabledArbiter = data.isEnabledArbiter; // true 为两节点
        localStorage.cephService = data.enabledCeph ? "ceph" : ""; //true为对接了超融合ceph
        localStorage.outcephService = data.enableOutCeph; //true为对接了outceph
        localStorage.regionBusiAuth= data.regionBusiAuth?JSON.stringify(data.regionBusiAuth):2;//license初始化数组
        localStorage.isLocalization = data.isLocalization?1:2;//1:arm架构,2:x86架构
        
        localStorage.supportOtherClouds = `openStack,PaaS,`;
        localStorage.localDiskService = data.localDiskService ? "localDisk" : ""; // true为对接了本地磁盘存储池
        if(data.supportOtherClouds){
            localStorage.supportOtherClouds = localStorage.supportOtherClouds+data.supportOtherClouds+","
        }
        if(JSON.parse(data.supportPaas).maas){
            localStorage.supportOtherClouds = localStorage.supportOtherClouds+"MAAS_API_KEY,"
        }
        if(JSON.parse(data.supportPaas).TCS_API_KEY){
            localStorage.supportOtherClouds = localStorage.supportOtherClouds+"TCS_API_KEY,"
        }
        let tsafeList = ["yujie","tianmu","waf","yujian","sec","fm","ngfw"];
        let tsafeLinked = tsafeList.filter(item=>JSON.parse(data.supportPaas)[item]);
        
        if(tsafeLinked.length>0){
            localStorage.supportOtherClouds = localStorage.supportOtherClouds+"Tsafe_API_KEY,"
        }
        /*PAAS对接*/
        var supportPaas = {
            "Resource":{isLinked:true},
            "PhysicalResource":{isLinked:true},
            "K8s":{isLinked:true},
            "Monitoring":{isLinked:true},
            "List":{isLinked:true},
            "Log":{isLinked:true},
            "User":{isLinked:true},
            "System":{isLinked:true},
            "AdvFunction":{isLinked:true}
        }
        if(data.supportPaas){
            var  supportPaasLinked = JSON.parse(data.supportPaas);
            for(var i in supportPaasLinked){
                supportPaas[i] = supportPaasLinked[i];
                supportPaas[i].isLinked = true;
            }
        }
        localStorage.supportPaas = JSON.stringify(supportPaas);
        localStorage.installK8s = data.installK8s ? 1 : 2;
        localStorage.installIronic = data.installIronic ? 1 : 2;
        localStorage.isTopNonTrivial = data.isTopNonTrivial;
    }

    this.getVerificationCode = function(self, loginForm) {
        self.login.verificationCode = "";
        var url = static_url_code + "/verifycode";
        $http({
            url: url,
            method: 'GET',
            responseType: 'blob'
        }).then(function(res) {
            if (res && res.data) {
                self.imgUrl = window.URL.createObjectURL(res.data);
            }
            if (res && res.headers() && res.headers("x-verification-key")) {
                self.verifyKey = res.headers("x-verification-key");
            }
            var img = document.getElementById("codeImg");
            //加载完成以后释放blob
            img && (img.onload = function() {
                window.URL.revokeObjectURL(img.src);
            })
        });
    }
    this.getLicenseList = function(result) {
        let userId = result.data.data.id;
        let regionKey = result.data.data.regionKey;
        $http({
            method: "GET",
            url: window.GLOBALCONFIG.APIHOST.BASE + "/v1/region/" + regionKey + "/menus/btn/" + userId
        }).then(function(res) {
            // 2：登录状态
            localStorage.$AWLOGINED = 2;
            if (res && res.status == 0) {
                localStorage.setItem("LicenseList", JSON.stringify(res.data));
                var urlParams = $location.search();
                let link = urlParams.jumpUrl?urlParams.jumpUrl:urlParams.link;
                if(urlParams.from&&link){
                    $location.path(link).replace();
                }else{
                    commonFuncSrv.setLoginViewPage(result.data.data)
                }
                
            }else {
                if(result.data.data.managementRole==2){
                   $location.path("/system/license"); 
                }else{
                    rootscope.$broadcast("loginerror", "licenseError");
                }
            }
        });
    }
    this.loginError = function(self, v) {
        if (v == "error") {
            self.loginError = 1;
        }
        if (v == "servererror") {
            self.loginError = 2;
        }
        if (v == "ipOut") {
            self.loginError = 3;
        }
        if (v == "noProject") {
            self.loginError = 5;
        }
        if (v == "overTimes") {
            self.loginError = 6;
        }
        if (v == "repeatLogin") {
            self.loginError = 7;
        }
        if (v == "sysUpdate") {
            self.loginError = 8;
        }
        // 验证码失效
        if (v == "codeInvalid") {
            self.loginError = 9;
        }
        //验证码错误
        if (v == "codeError") {
            self.loginError = 10;
        }
        //用户锁定
        if (v == "userLocked") {
            self.loginError = 11;
        }
        //邮箱不存在
        if (v == "enterpriseNameOrEmailError") {
            self.loginError = 12;
        }
        //邮箱验证码错误
        if (v == "emailCodeError") {
            self.loginError = 13;
        }
        //用户不存在或未绑定角色
        if (v == "userNoRole") {
            self.loginError = 14;
        }
        //用户被禁用
        if (v == "userForbidden") {
            self.loginError = 15;
        }
        //授权过期
        if (v == "licenseError") {
            self.loginError = 22;
        }
    }
    this.menuInit = function(self, result) {
        var menuList = result.menuList;
        var managementRole = result.managementRole;
        
        //保密员审计员 展示tcs菜单 top-menu
        if(managementRole == 8 || managementRole == 9){
            var tcsMenu =null;
            menuList.forEach(function(item,index) {
                if ( item.keywords == 'TCS'){
                    tcsMenu = item ;
                    menuList.splice(index,1);
                }
            })
            if(tcsMenu!=null) {
                menuList.push(tcsMenu);
            }
        }
        if(managementRole == 5){
            let paasKeyList = ['TSF','CMQ','TCS','Tsafe'];
            let paasKeyMenu = [];
            let menuCache = [];
            menuCache = menuList.filter(function(item,index) {
                let flag = paasKeyList.indexOf(item.keywords)>-1;
                if(flag){
                    paasKeyMenu.push(item);
                }
                return !flag;
            })
            menuList = [
                {
                    "id":1,
                    "text":"普通用户",
                    //"keywords":"Resource,List,System,Log,User,Monitoring,Flowing,PhysicalResource,Bill_Management,K8s,Sky_entrance_tenant,DatabaseManage",
                    "keywords":"Resource",
                    "version":"2",
                    "href":"/cvm/cvmview",
                    "icon":"icon-aw-storage1",
                    "active":"depart,cvm",
                    "child":menuCache
                }
            ]
            menuList.push(...paasKeyMenu);

        }

        localStorage.menuList = angular.toJson(menuList);

        // if (result.data.data.menuList.length == 0) {
        //     self.loginError = 4;
        // }
    }
    this.getDefaultUrl = function(res){
        let menu = res;
        let link;
        if(menu[0].child&&menu[0].child.length>0){
            if(menu[0].child[0].child&&menu[0].child[0].child.length>0){
                link = menu[0].child[0].child[0].href
            }else{
                link= menu[0].child[0].href;
            }
        }else{
            link = menu[0].href;
        }
        return link;
    }
}])
