export function addListenSrv($http){
    return {
        getPropAgreement(){
            return $http({
                method: "get",
                url: "/awstack-manage/v2/elb/loadbalancers/listeners/protocol",
            })
        },
        addSoundMonitor(data,headers){
            return $http({
                method:"post",
                url:"/awstack-manage/v2/elb/loadbalancers/listener",
                data,
                headers
            })
        },
        getArithmetic(){
            return $http({
                method:"get",
                url:"/awstack-manage/v2/elb/loadbalancers/listeners/lbAlgorithm"
            })
        },
        updateSoundMonito(data,headers){
            return $http({
                method:"put",
                url:"/awstack-manage/v2/elb/loadbalancers/listeners",
                data,
                headers
            })
        }
    }
}
addListenSrv.$inject = ["$http"];
