angular.module("localDiskSrvModule", [])
    .service("localDiskSrv", function($http) {
        return {
            // 查询本地磁盘分页列表
            getLocalDiskList: function(params) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/vdisk",
                    params: params
                })
            },

            // 创建本地磁盘
            createLocalDisk: function(data) {
                return $http({
                    method: "POST",
                    url: "awstack-manage/v2/vdisk",
                    data: data
                })
            },

            // 编辑本地磁盘
            editLocalDisk: function(data) {
                return $http({
                    method: "PUT",
                    url: "awstack-manage/v2/vdisk",
                    data: data
                })
            },

            // 挂载云主机
            mountLocalDisk: function(data,vmId,haSwitch) {
                return $http({
                    method: "POST",
                    url: `awstack-manage/v2/vdisk/attach/${vmId}/${haSwitch}`,
                    data: data
                })
            },

            // 卸载云主机
            uninstallLocalDisk: function(vmId,volumeId,haSwitch) {
                return $http({
                    method: "DELETE",
                    url: `awstack-manage/v2/vdisk/${vmId}/detach/${volumeId}/${haSwitch}`
                })
            },

            //删除本地磁盘
            deleteLocalDisk: function(data) {
                return $http({
                    method: "POST",
                    url: "awstack-manage/v2/vdisk/delete",
                    data: data
                })
            },

            // 查询本地磁盘详情
            getLocalDiskDetail: function(uuid) {
                return $http({
                    method: "GET",
                    url: `awstack-manage/v2/vdisk/${uuid}`
                })
            },

            // 查询本地磁盘挂载的云主机
            getLocalDiskMountList: function(params,data) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/instance/query",
                    params : params,
                    headers: {
                        "domain_name":encodeURI(data.domain_name),
                        "domain_id":data.domain_id,
                        "project_name":encodeURI(data.project_name),
                        "project_id":data.project_id
                    }
                })
            },

            // 查询本地磁盘卸载的云主机
            getLocalDiskUninstallList: function(params) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/bindDisk/get",
                    params : params
                })
            },

            // 查询云主机加载的本地磁盘
            getInstancesLoadingLocalDiskList: function(params,data) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/bindDisk/query",
                    params: params,
                    headers: {
                        "domain_name":encodeURI(data.domain_name),
                        "domain_id":data.domain_id,
                        "project_name":encodeURI(data.project_name),
                        "project_id":data.project_id
                    }
                })
            },

            // 查询云主机卸载的本地磁盘
            getInstancesUninstallLocalDiskList: function(params) {
                return $http({
                    method: "GET",
                    // url: "awstack-manage/v2/volume/local",
                    url: "awstack-manage/v2/vdisk/local",
                    params: params
                })
            },

            // 查询本地磁盘资源池
            getStorage: function () {
                return $http({
                    method: "GET",
                    url: "/awstack-user/v1/storage"
                })
            }
        };
    });
