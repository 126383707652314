logoSettingCtrl.$inject = ['$scope','$rootScope','$timeout','logoSettingSrv','$http','NgTableParams','$route']
function logoSettingCtrl($scope,$rootScope,$timeout,logoSettingSrv,$http,NgTableParams,route) {
    var self = $scope;
    // 初始化定义变量
    self.copyrightLimit= true;
    self.submitted = false;
    self.formName = "";
    self.showalerttip = true;
    var logoUrl = "awstack-user/v1/back/logo/get";
    self.logoErrorStatusBroser = true;
    self.logoErrorStatusSys = true;
    // 控制输入框不同错误提示
    self.strMInlength = false;
    self.strRequire = false;
    self.strMaxlength = false;
    self.strMInlength1 = false;
    self.strRequire1 = false;
    self.strMaxlength1 = false;
    self.inputStatus = true;
    self.inputStatus1 =true;
    self.loginWidthStatus = false;
    self.loginRegStatus = false;
    self.loginSizeStatus = false;
    self.loginBgList = [];
    self.loginsrcCommon = "";
    self.loginData ={};
    $http({
        method: "GET",
        url:logoUrl
    }).then(function(result) {
        if(result&&result.data){
            const resultData = result.data;
            self.browserLabelLogoAddress = resultData.labelFilename;
            self.systemPageLogoAddress = resultData.systemFilename;
            self.formName = resultData.platformName;
            self.copyrightInfo = resultData.copyrightInfo;
            self.copyrightLimit= resultData.copyrightShow == 1?true:false;
            self.imgsrc = 'data:image/svg+xml;base64,'+resultData.systemFile;
            self.shortcut = 'data:image/jpeg;base64,'+resultData.labelFile;
            self.checkStrLength(self.copyrightInfo,2,2,128);
            self.checkStrLength(self.formName,1,2,128);
        }
    });
    $http({
        method: "GET",
        url:'awstack-user/v1/background/get'
    }).then(function(result) {
        if(result&&result.data){
            self.loginBgList = result.data;
            self.loginBgList.map(i =>{
                if(i.isUse == '0'){
                    self.selectBg = i.id
                }
                if(i.backgroundType == 'custom'){
                    self.loginData = i;
                    self.loginsrcCommon = 'data:image/jpeg;base64,'+i.backgroundImage
                }
            })
        }
    });
    self.selectLoginBg = function(item){
        self.selectBg = item.id
    }
    self.interactedName = function(field) {
        return self.submitted || field.$dirty;
    };
    //上传系统图片
    $('#uploadSysLogo').on('change',function(){
        let dom = document.getElementById("uploadSysLogo");
        self.sysFile = dom.files[0];
        const reg = /.+(.svg)$/;
        if(dom.files[0]){
            renderImg(dom.files[0],185,60,reg,30,'sys');
        }
    });
    $('#uploadBroserLogo').on('change',function(){
        let dom = document.getElementById("uploadBroserLogo");
        self.browserFile = dom.files[0];
        const reg = /.+(.ico)$/;
        if(dom.files[0]){
            renderImg(dom.files[0],16,16,reg,20,'browser');
        }
    });
    $('#uploadLoginBg').on('change',function(){
        let dom = document.getElementById("uploadLoginBg");
        self.loginFile = dom.files[0];
        const reg = /.+(.jpg|.png)$/;
        if(dom.files[0]){
            renderImg(dom.files[0],1920,1080,reg,1024,'login');
        }
    });
    // 条件判断函数
    function conditionJuge(file,reg,size,type){
        //判断类型
        if(!reg.test(file.name)){
            if(type == "browser"){
                self.browserRegStatus = true;
            }else if(type == "sys"){
                self.sysRegStatus = true;
            }else if(type == "login"){
                self.loginRegStatus = true;
            }
        }else{
            if(type == "browser"){
                self.browserRegStatus = false;
            }else if(type == "sys"){
                self.sysRegStatus = false;
            }else if(type == "login"){
                self.loginRegStatus = false;
            }
        }
        // 判断大小
        console.log(file.size);
        if(file.size/1024 > size){
            if(type == "browser"){
                self.browserSizeStatus = true;
            }else if(type == "sys"){
                self.sysSizeStatus = true;
            }else if(type == "login"){
                self.loginSizeStatus = true;
            }
        }else{
            if(type == "browser"){
                self.browserSizeStatus = false;
            }else if(type == "sys"){
                self.sysSizeStatus = false;
            }else if(type == "login"){
                self.loginSizeStatus = false;
            }
        }
    }
    function imageJudge(e,width,height,type){
        var img = new Image;
        img.src=  e.target.result;
        img.onload = function(){ 
            var width_img = img.width;
            var height_img = img.height;
            if(type == "browser"){
                if(width_img!=width || height_img!=height){
                    self.browserWidthStatus = true;
                }else{
                    self.browserWidthStatus = false;
                }
                /**
                 * 需等待图片onload加载完再做判断
                 * 若不正确 设为初始值
                 */
                if(self.browserRegStatus || self.browserSizeStatus || self.browserWidthStatus){
                    self.shortcutCommon = self.shortcut;
                }else{
                    self.shortcutCommon = e.target.result;
                }
            }
            if(type == "sys"){
                if(width_img>width || height_img>height){
                    self.sysWidthStatus = true;
                }else{
                    self.sysWidthStatus = false;
                }
                /**
                 * 需等待图片onload加载完再做判断
                 * 若不正确 设为初始值
                 */
                if(self.sysRegStatus || self.sysSizeStatus || self.sysWidthStatus){
                    self.imgsrcCommon = self.imgsrc;
                }else{
                    self.imgsrcCommon = e.target.result;
                }
            }
            if(type == "login"){
                if(width_img!=width || height_img!=height){
                    self.loginWidthStatus = true;
                }else{
                    self.loginWidthStatus = false;
                }
                /**
                 * 需等待图片onload加载完再做判断
                 * 若不正确 设为初始值
                 */
                if(self.loginWidthStatus || self.loginSizeStatus || self.loginRegStatus){
                    // self.loginsrcCommon = self.imgsrc;
                }else{
                    self.loginsrcCommon = e.target.result;
                }
            }
            self.$apply();
        };
    }
     /**
         * renderImg(file,width,height,reg,size,type)
         * file:文件
         * width:图片宽
         * height:图片高
         * reg：图片格式正则
         * size：图片大小
         * tupy:类型
         */
    // 图片渲染
    function renderImg(file,width,height,reg,size,type){
        var reader = new FileReader();
        //使用该对象读取file文件
        reader.readAsDataURL(file);
        //读取文件成功后执行的方法函数
        reader.onload=function(e){
            imageJudge(e,width,height,type);
            conditionJuge(file,reg,size,type);
            if(type == 'login'&&!self.loginSizeStatus&&!self.loginWidthStatus&&!loginRegStatus){
               // alert(1);
            }
        }
        
    };
    // 判断输入框长度
    self.strLength = function(str){
        var len = 0;  
        for (var i=0; i<str.length; i++) {  
            if (str.charCodeAt(i)>127 || str.charCodeAt(i)==94) {  
                len += 2;  
            } else {  
                len ++;  
            }  
        }  
        return len;  
    };
    self.checkStrLength = function(str,type,minsize,maxsize){
        switch(type){
            case 1:
                if(!str){
                    self.strRequire = true;
                    self.strMInlength = false;
                    self.strMaxlength = false;
                    self.inputStatus = false;
                }else{
                    self.strRequire = false;
                    if(self.strLength(str)<minsize){
                        self.strMInlength = true;
                    }else{
                        self.strMInlength = false;
                    }
                    if(self.strLength(str)>maxsize){
                        self.strMaxlength = true;
                    }else{
                        self.strMaxlength = false;
                    }
                    if(self.strMInlength || self.strMaxlength){
                        self.inputStatus = false;
                    }else{
                        self.inputStatus = true;
                    }
                }
                break;
            case 2:
                if(!str){
                    self.strRequire1 = true;
                    self.strMInlength1 = false;
                    self.strMaxlength1 = false;
                    self.inputStatus1 = false;
                }else{
                    self.strRequire1 = false;
                    if(self.strLength(str)<minsize){
                        self.strMInlength1 = true;
                    }else{
                        self.strMInlength1 = false;
                    }
                    if(self.strLength(str)>maxsize){
                        self.strMaxlength1 = true;
                    }else{
                        self.strMaxlength1 = false;
                    }
                    if(self.strMInlength1 || self.strMaxlength1){
                        self.inputStatus1 = false;
                    }else{
                        self.inputStatus1 = true;
                    }
                }
                break;
        };
    };
    self.close = function(){
        self.showalerttip = false;
    };
    // 提交信息
    self.saveLogoInfo = function(m){
        if (m.$valid&&self.inputStatus&&self.inputStatus1&& !self.browserRegStatus && !self.browserSizeStatus && !self.browserWidthStatus&& !self.sysSizeStatus&& !self.sysRegStatus&& !self.sysWidthStatus) {
            //使用FormData对象上传文件
            var form = document.forms.namedItem("logoForm");
            var oData = new FormData();
            oData.append("file",self.browserFile);
            oData.append("file",self.sysFile);
            oData.append("file",self.loginFile);
            oData.append("versionInfo",self.formName);
            if(self.selectBg){
                oData.append("bgId",self.selectBg);
            }
            
            oData.append("copyrightShow",self.copyrightLimit?1:0);
            oData.append("copyrightInfo",self.copyrightInfo);
            oData.append("enterpriseUid",localStorage.getItem('enterpriseUid'));
            logoSettingSrv.modifyLogoInfo(oData).then(function(result){
                if(result&&result.status == 0){
                    setTimeout(function(){
                        window.location.reload();
                    },500)//延迟500 保证操作成功的提示信息出现后刷新页面；
                    // window.location.reload();
                    // route.reload();
                }
                // route.reload();
            })
        }else {
            self.submitted = true;
        }
    }
}
export{logoSettingCtrl};