import "./roleDataSrv";

angular.module("rolectrl", ["ngTable", "rolesrv","ivh.treeview"])
    .controller("RoleCtrl", ["$scope","NgTableParams", "$filter","roleDataSrv", "alertSrv", "checkedSrv", "$location", "$translate","GLOBAL_CONFIG","$timeout","userDataSrv","$uibModal","$rootScope",function($scope, NgTableParams,$filter, roleDataSrv, alertSrv, checkedSrv, $location, $translate,GLOBAL_CONFIG,$timeout,userDataSrv,$uibModal,rootScope) {
        var self = $scope;
        var filter = $filter;
        self.isDisabled = true;
        self.delisDisabled = true;
        // 基础角色控制变量
        self.newPage = false;
        self.roleList = [];
        self.menuIds = [];
        self.usernameNew ="";
        self.userInfo ={
            "username":"",
            "description":"",
        }
        self.curDep = {
            selected:''
        };
        self.interactedName = function(field) {
            return self.submitted || field.$dirty;
        };
        // function getRole() {
        //     var postData ={};
        //     if(!$scope.ADMIN){
        //         postData.domainUid = localStorage.defaultdomainUid;
        //     }
        //     const data = {
        //         "pageNum":1,
        //         "pageSize":10
        //     };
        //     // 角色菜单列表获取
        //     roleDataSrv.getRoledata(data).then(function(data) {
        //         data?self.loadData = true:"";
        //         console.log(data);
        //         if(data&& data.data.list.length){
        //             successFunc(data.data.list);
        //         }
        //     });
        // }
        function successFunc(data) {
            data.map(function(item){
                item.searchTerm = [item.name, item.description, item.createTime].join('\b');
                item.createTime = filter('date')(item.createTime,'yyyy-MM-dd');
            });
            self.tabledata = data;
            self.tableParams = new NgTableParams({ count: 12 }, { counts: [], dataset: data });
            var tableId = "id";
            checkedSrv.checkDo(self, data, tableId);
        }
        /**
         * 1226 xinzeng分页
         */
        //配置参数对象
        function initFilterForm() {
            return self.filterData = {
                pageNum: "1",
                pageSize: "10"
            };
        }
        initFilterForm();
        /**
         * 
         * @param {container} container 分页 容器
         * @param {*} count 数量
         * @param {*} pageindex 分页下标
         */
        function setPage(container, count, pageindex) { //总页数少于10 全部显示,大于10 显示前3 后3 中间3 其余....
            var a = [];

            if (pageindex == 1) {
                a[a.length] = "<li><a href=\"#\" class=\"prev unclick\"><i class=\"icon-aw-angle-double-left\"></i></a></li>";
            } else {
                a[a.length] = "<li><a href=\"#\" class=\"prev\"><i class=\"icon-aw-angle-double-left\"></i></a></li>";
            }

            function setPageList() {
                if (pageindex == i) {
                    a[a.length] = "<li><a href=\"#\" class=\"on\">" + i + "</a></li>";
                } else {
                    a[a.length] = "<li><a href=\"#\">" + i + "</a></li>";
                }
            }

            if (count <= 10) { //总页数小于10
                for (var i = 1; i <= count; i++) {
                    setPageList();
                }
            } else { //总页数大于10页
                if (pageindex <= 4) {
                    for (var i = 1; i <= 5; i++) {
                        setPageList();
                    }
                    a[a.length] = "<li><span>...</span></li><li><a href=\"#\">" + count + "</a></li>";
                } else if (pageindex >= count - 3) {
                    a[a.length] = "<li><a href=\"#\">1</a></li><li><span>...</span></li>";
                    for (var i = count - 4; i <= count; i++) {
                        setPageList();
                    }
                } else { //当前页在中间部分
                    a[a.length] = "<li><a href=\"#\">1</a></li><li><span>...</span></li>";
                    for (var i = pageindex - 2; i <= pageindex + 2; i++) {
                        setPageList();
                    }
                    a[a.length] = "<li><span>...</span></li><li><a href=\"#\">" + count + "</a></li>";
                }
            }
            if (pageindex == count) {
                a[a.length] = "<li><a href=\"#\" class=\"next unclick\"><i class=\"icon-aw-angle-double-right\"></i></a></li>";
            } else {
                a[a.length] = "<li><a href=\"#\" class=\"next\"><i class=\"icon-aw-angle-double-right\"></i></a></li>";
            }
            container.innerHTML = a.join("");

            (function pageClick() { //事件点击
                var oAlink = container.getElementsByTagName("a");
                var inx = pageindex; //初始的页码
                var clickPageFunc = function(inx) {
                    self.filterData.pageNum = inx;
                    setPage(container, count, inx);
                    self.clickPageToquery = function() {
                        initLogsTable(self.filterData);
                    }();
                };
                oAlink[0].onclick = function() { //点击上一页
                    if (inx == 1) {
                        return false;
                    }
                    inx--;
                    clickPageFunc(inx);
                    return false;
                };
                for (var i = 1; i < oAlink.length - 1; i++) { //点击页码
                    oAlink[i].onclick = function() {
                        inx = parseInt(this.innerHTML);
                        clickPageFunc(inx);
                        return false;
                    };
                }
                oAlink[oAlink.length - 1].onclick = function() { //点击下一页
                    if (inx == count) {
                        return false;
                    }
                    inx++;
                    clickPageFunc(inx);
                    return false;
                };
            })();
        }
        function initLogsTable(params) {         
           
            self.loadData = false;
            self.logsTable = [];
            // 角色菜单列表获取
            roleDataSrv.getRoledata(params).then(function(data) {
                data?self.loadData = true:"";
                if(data&&data.data&&data.data.list.length){
                    init_data(data.data);
                }
            });
            
        };
        // 数据初始化设置
        function init_data(data) {
            data.list.map(function(item){
                item.searchTerm = [item.name, item.description, item.createTime].join('\b');
                item.createTime = filter('date')(item.createTime,'yyyy-MM-dd HH:mm:ss');
            });
            self.tabledata = data.list;
            self.tableParams = new NgTableParams({ count: 10 }, { counts: [], dataset: data.list });
            var tableId = "id";
            checkedSrv.checkDo(self, data.list, tableId);
            var e = $("#pageNum")[0];
            if (data.total && self.filterData.pageSize) {
                self.pages = Math.ceil(data.total / self.filterData.pageSize);
                self.showPage = true;
                setPage(e, self.pages, self.filterData.pageNum);
            } else {
                self.showPage = false;
            }
        }
        // 初始化执行
        initLogsTable(self.filterData);
        // 分页跳转
        self.goToPage = function(num) {
            self.filterData.pageNum = num;
            initLogsTable(self.filterData);
        };

        self.refreshRole = function(){
            self.globalSearchTerm="";
            // getRole();
            initFilterForm();
            initLogsTable(self.filterData);
        };

        self.applyGlobalSearch = function() {
            var term = self.globalSearchTerm;
            self.tableParams.filter({searchTerm:term});
        };
        //监听选中框
        self.$watch(function(){
            return self.checkedItems;
        },function(value){
            if(value && value.length==1){
                if(value[0].isDefault){
                    self.delisDisabled = true;
                }
            }else if(value && value.length>1){
                self.delisDisabled = true;
            }
        });
        self.tpl = [
            "<div>",
            "<span ivh-treeview-toggle>",
            "<span ivh-treeview-twistie></span>",
            "</span>",
            "<ascii-box></ascii-box>",
            "<span class='ivh-treeview-node-label' ivh-treeview-toggle>",
            "{{:: trvw.label(node)}}",
            "</span>",
            "<div ivh-treeview-children></div>",
            "</div>"
        ].join("\n");

       /* function formaterMenu(arr,pt){
            _.forEach(arr, function(item) {
                switch(item.keywords){
                    case "Resource":
                        item.selected = true; 
                        break;
                    case "Resource_Instance":
                        item.selected = true; 
                        break;
                }
                if(pt){
                    item.selected = true; 
                }
                if (item.selected > 0) {
                    formaterMenu(item.child,true);
                }

            });
            return arr
        }*/
        function initRole(res) {
            if (res&& res.length > 0) {
                self.roleList = res;
                self.curDep.selected = self.roleList[0];
                self.roleId = self.curDep.selected.id;
                self.managementRole = self.roleList[0].managementRole;
                self.treeData = self.roleList[0].privilegeMenuList;
            }
        };
        function computeCheck(data,arr){
            if(data.selected){
                arr.push(data.id);
            }
            if(data.child.length>0){
                data.child.forEach(item=>{
                    computeCheck(item,arr);
                })
            }
        }
        // 更改角色下拉
        self.changeDepart = function(item) {
            self.roleId = item.id;
            self.managementRole = item.managementRole;
            self.menuIds=[];
            JSON.parse(JSON.stringify(self.allData)).forEach(function(value){
                if(value.id == self.roleId){
                    self.treeData = value.privilegeMenuList;
                }
            })
        };
        self.updateRole = function(type) {
            self.menuIds = [];
            if(type == "new"){
                self.newPage = true;
            }else if(type == 'edit'){
                self.editPage = true;
            }
            self.role_form = {
                name:"",
                domainUid:"",
                description:"" 
            };
            self.getMemus = function(arr) {
                arr.forEach(item=>{
                    computeCheck(item,self.menuIds);
                })
                /*_.forEach(arr, function(item) { //一级循环
                    if (item.child.length > 0) {
                        let selectAmount = 0;
                        _.forEach(item.child, function(item1) {//二级循环
                            if (item1.selected) {
                                selectAmount++;
                                self.menuIds.push(item1.id);
                            }
                            if(item1.child.length > 0){
                                let selectAmount1 = 0;
                                _.forEach(item1.child, function(item2) { //三级循环
                                    if (item2.selected) {
                                        selectAmount1++;
                                        self.menuIds.push(item2.id);
                                    }
                                })
                                if(selectAmount1 > 0){
                                    selectAmount++;//20190114 子集选中 父级同样计数
                                    self.menuIds.push(item1.id);
                                }
                            }
                        })
                        //只要选中一个 父级的id传入
                        if(selectAmount > 0){
                            self.menuIds.push(item.id);
                        }
                    }else if(item.child.length == 0 && item.selected){ //一级菜单 被选中而且无child 
                        self.menuIds.push(item.id);
                    }
                });
                */
                // 数组去重
                 // array.from兼容处理
                // if(!Array.from){
                //     Array.from = function (el) {
                //         return Array.apply(this, el);
                //     }
                // }
                // self.menuIds = Array.from(new Set(self.menuIds));
                self.menuIds = [].slice.call(self.menuIds)
                var postData = {
                    name: self.role_form.name,
                    domainUid:self.role_form.domainUid,
                    menuIds: self.menuIds.toString(),
                    description: self.role_form.description || ""
                };
                return postData;

            };
            //获取部门列表
            userDataSrv.getDepartmentData().then(function(result) {
                if(result && result.data && result.data.length){
                    self.domainList =  result.data;
                    if(type == "new" && self.ADMIN){  //admin用户新建权限
                        self.role_form.domainUid =  self.domainList[0].domainUid;
                    }else if(type == "new" && self.DOMAIN_ADMIN){ //domain_admin用户新建权限
                        self.role_form.domainUid  = localStorage.domainUid;

                    }else if(type == "edit"){ //admin、domain_admin用户编辑权限
                        self.role_form.domainUid =  self.editData.domainUid;
                    }
                }
            });
            switch (type) {
            case "new":
                $scope.treeData = '';
                self.usernameNew ="";
                self.descriptionNew = "";
                self.roleRequire = false;
                self.addmenuRequire = false;
                roleDataSrv.getDepartMenu().then(function(data) {
                    if(data&&data.data){
                        self.allData = data.data;
                        initRole(data.data);
                    }
                    
                });
                self.addRole = function(m){
                    var aaa=$scope.treeData;
                    // 获取选中的权限
                    self.menuIds =[];
                    const sendData = self.getMemus(aaa);
                    sendData.menuIds?self.menuRequire =false :self.menuRequire = true;
                    sendData.managementRole = self.managementRole;
                    sendData.enterpriseUid = localStorage.enterpriseUid;
                    sendData.id = self.roleId;
                    sendData.name = self.usernameNew;
                    sendData.description = self.descriptionNew;
                    delete sendData.domainUid;
                    // 判断选中权限是否为空及输入框不为空
                    if(!self.menuRequire){
                        if(m.$valid){
                            roleDataSrv.addNewRole(sendData).then(function() {
                                self.refreshRole();
                                self.newPage = false;
                            });
                        }else{
                            self.roleRequire = true;
                        }
                    }else{
                        self.addmenuRequire = true;
                    }
                };
                break;
            case "edit":
                self.new_or_editTitle = $translate.instant("aws.role.edit_role");
                self.hide_domain = true;
                self.role_form.name = self.editData.name;
                self.role_form.description = self.editData.description;
                self.editDisable = false;
                $scope.treeData = '';
                if(self.editData.name == "admin"){
                    self.editDisable = true;
                    self.notEditTip = $translate.instant("aws.role.table.privilege_not_edit");
                }
                // 只有新建的角色会显示角色名称和角色描述输入框
                if(self.editData.isDefault){
                    self.newAddShowStatus = false;
                }else{
                    self.newAddShowStatus = true;
                }
                //获取菜单
                var postData = { privilegeId: Number(self.editData.id) };
                $timeout(
                    function(){
                        roleDataSrv.getRoleMemus(postData).then(function(data) {
                            $scope.treeData = data.data.privilegeMenu;
                            $scope.userInfo.username = data.data.name;
                            $scope.userInfo.description = data.data.description;
                            
                        });
                },3);
                //发送编辑数据
                $scope.createRole = function() {
                    self.menuIds = [];
                    var aaa=$scope.treeData;
                    var sendData = self.getMemus(aaa);
                    sendData.menuIds?self.menuRequire =false :self.menuRequire = true;
                    $timeout(function(){
                        self.menuRequire = false;
                    },5000);
                    sendData.id = self.editData.id;
                    if(!self.editData.isDefault){
                        sendData.name = self.userInfo.username;
                        sendData.description = self.userInfo.description;
                    }
                    /**
                     * 这里需要做判断 是初始化角色 还是新建角色
                     * 新建角色需要将角色描述等信息 重新赋值；
                     */
                    if(!self.menuRequire){
                        roleDataSrv.editRole(sendData).then(function() {
                             // 刷新操作
                             self.refreshRole();
                            self.editPage = false;
                        });
                    }else{
                        self.submitValid = true;
                    }
                    
                };
                break;
            }
        };
        
        self.closemenu = function(){
            self.editPage = false;
            self.newPage = false;
        };
        self.del = function(checkedItems) {
            var content = {
                target: "delRole",
                msg: "<span>" + $translate.instant("aws.role.table.role_del") + "</span>",
                data:checkedItems
            };
            self.$emit("delete", content);
        };

        self.$on("delRole", function(e,data) {
            var delGroup = [];
            var postData = data[0].id ;
            roleDataSrv.delRole(postData).then(function() {
                self.refreshRole()
            });
        });

        // getRole();

    }])
    .config(function(ivhTreeviewOptionsProvider) {
        ivhTreeviewOptionsProvider.set({
            defaultSelectedState: false,
            validate: true,
            childrenAttribute: "child"

        });
    })
    .directive("asciiBox", function(ivhTreeviewMgr) {
        return {
            restrict: "AE",
            require: "^ivhTreeview",
            template: [
                "<span class='ascii-box'>",
                "<span ng-show='node.selected' class='icon-aw-check-square'></span>",
                "<span ng-show='node.__ivhTreeviewIndeterminate' class='icon-aw-minus2'></span>",
                "<span ng-hide='node.selected || node.__ivhTreeviewIndeterminate' class='icon-aw-square'> </span>",
                "</span>"
            ].join(""),

            link: function(scope, element, attrs, ctrl) {
                element.on("click", function() {
                    ivhTreeviewMgr.select(ctrl.root(), scope.node, !scope.node.selected);
                    scope.$apply();
                });
            }
        };
    })
    // 新建角色的controller
    .controller("creatRoleCtrl", ['$scope', '$filter', '$rootScope','$translate','roleDataSrv','NgTableParams','checkedSrv',function($scope,$filter, $rootScope,$translate,roleDataSrv,NgTableParams,checkedSrv){
        var self = $scope;
        self.projectTitle = $translate.instant("aws.role.new_user");
        self.submitted = false;
        // self.interactedName = function(field) {
        //     return self.submitted || field.$dirty;
        // };
        // self.addRole = function(){
        //     var aaa=$scope.treeData;
        //     // 获取选中的权限
        //     self.menuIds =[];
        //     const sendData = self.getMemus(aaa);
        //     sendData.menuIds?self.menuRequire =false :self.menuRequire = true;
        //     sendData.managementRole = self.roleId;
        //     sendData.enterpriseUid = localStorage.enterpriseUid;
        //     sendData.id = self.roleId;
        //     sendData.name = self.usernameNew;
        //     sendData.description = self.descriptionNew;
        //     delete sendData.domainUid;

        //     console.log(sendData);
        //     // 判断选中权限是否为空及输入框不为空
        //     if(!self.menuRequire&&self.roleName1.$valid){
        //         roleDataSrv.addNewRole(sendData).then(function() {
        //             self.refreshRole();
        //             self.newPage = false;
        //         });
        //     }else{
        //         self.addmenuRequire = true;
        //     }
        // };
        // function getRole() {
        //     var postData ={};
        //     if(!$scope.ADMIN){
        //         postData.domainUid = localStorage.defaultdomainUid;
        //     }
        //     const data = {
        //         "pageNum":1,
        //         "pageSize":10
        //     };
        //     // 角色菜单列表获取
        //     roleDataSrv.getRoledata(data).then(function(data) {
        //         data?self.loadData = true:"";
        //         console.log(data);
        //         if(data&& data.data.list.length){
        //             successFunc(data.data.list);
        //         }
        //     });
        // }
        // function successFunc(data) {
        //     data.map(function(item){
        //         item.searchTerm = [item.name, item.description, item.createTime].join('\b');
        //         item.createTime = filter('date')(item.createTime,'yyyy-MM-dd');
        //     });
        //     self.tabledata = data;
        //     self.tableParams = new NgTableParams({ count: 4 }, { counts: [], dataset: data });
        //     var tableId = "id";
        //     checkedSrv.checkDo(self, data, tableId);
        // }


    }])
    .directive("repeatrole",["$timeout","$window","roleDataSrv",function($timeout,$window,roleDataSrv){
        return {
            restrict:"A",
            require:"ngModel",
            link:function(scope,ele,attrs,ngModel){
                var tempRole=angular.copy(scope.role_form.name);
                ngModel.$parsers.push(function(viewvalue){
                    scope.$watch(function(){
                        var domainUid = scope.$parent.role_form.domainUid;
                        return domainUid;
                    },function(domainUid){
                        if(!viewvalue) return;
                        $timeout.cancel($window.timer);
                        $window.timer = $timeout(function(){
                            if(tempRole){
                                if(tempRole==viewvalue){
                                    ngModel.$setValidity("repeatrole",true);
                                }else{
                                    roleDataSrv.roleIsUsed({name:viewvalue,"domainUid":domainUid}).then(function(result){
                                        if(result){
                                            ngModel.$setValidity("repeatrole",!result.data);
                                        }
                                    });    
                                }
                            }else{
                                roleDataSrv.roleIsUsed({name:viewvalue,"domainUid":domainUid}).then(function(result){
                                    if(result){
                                        ngModel.$setValidity("repeatrole",!result.data);
                                    }
                                });    
                            }
                                
                        },1000);
                    });
                    return viewvalue;
                });
                
            }
        };
    }])
    .directive("menuTree",["$timeout",function($timeout){
        return {
            restrict:"EA",
            scope:{
                menuData:"="
            },
            template:`
            <div class="checkbox choose-all">
                <label>
                    <input type="checkbox" name="menuName" ng-model="level.selectedAll" class="selected-all" ng-click="setAllCheckbox(level.selectedAll)">
                    <i class="iconfont"></i>
                </label>
                <span ng-click="toggleChk(level1.keywords)" ng-class="{'cursor':level1.child.length>0}">全选</span>
            </div>
            <ul class="menu-tree-level1">
                <li ng-repeat="level1 in menuData" class="menu-tree-level1-li">
                    <div class="checkbox clearfix level1-checkbox">
                        <label class="menu-{{level1.keywords}}">
                            <input type="checkbox" name="menuName" ng-click="setCheckbox(level1,1)" ng-model="level1.selected">
                            <i class="iconfont"></i>
                        </label>
                        <span ng-click="toggleChk(level1.keywords)" ng-class="{'cursor':level1.child.length>0}">{{"aws.menu."+level1.keywords|translate}}</span>
                    </div>
                    <ul ng-if="level1.child.length>0" class="menu-tree-level2" ng-class="{'show':menuTog[level1.keywords]}" >
                        <li ng-repeat="level2 in level1.child" class="menu-tree-level2-li">
                            <div class="checkbox clearfix level2-checkbox">
                                <label class="menu-{{level2.keywords}}">
                                    <input type="checkbox" indeterminate="level2.indeterminate" ng-click="setCheckbox(level2,2)" name="menuName" ng-model="level2.selected">
                                    <i class="iconfont"></i>
                                </label>
                                <span ng-class="{'cursor':level2.child.length>0}">{{"aws.menu."+level2.keywords|translate}}</span>
                            </div>
                            <ul ng-if="level2.child.length>0" class="menu-tree-level3" ng-class="{'show':menuTog[level1.keywords]}">
                                <li ng-repeat="level3 in level2.child" class="menu-tree-level3-li">
                                    <div class="level3-checkbox clearfix checkbox">
                                        <label>
                                            <input type="checkbox" ng-click="setCheckbox(level3,3)" name="menuName" ng-model="level3.selected">
                                            <i class="iconfont"></i>
                                        </label>
                                        <span class="">{{"aws.menu."+level3.keywords|translate}}</span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            `,
            link:function(scope,ele,attrs,ngModel){
                // scope.level.selected = [];
                // 观察到数据变化 重新初始化；
                scope.$watch(function(){
                    return scope.menuData
                },function(){
                    $timeout(function(){
                        init(scope.menuData);
                    },500)
                })

                function init(data){
                    let checkAll = 0;
                    scope.level={
                        selected:[]
                    }
                    for(let i=0;i<data.length;i++){//一级菜单 
                        if(data[i].selected){
                            checkAll++
                        }
                        if(data[i].child&&data[i].child.length>0){
                            let checkNumO = 0;
                            let checkNum = false;
                            for(let j=0;j<data[i].child.length;j++){//二级菜单集合
                                let checkNumT = 0;
                                if(data[i].child[j].child&&data[i].child[j].child.length>0){
                                    for(let k=0;k<data[i].child[j].child.length;k++){//三级菜单
                                        if(data[i].child[j].child[k].selected){
                                            checkNumT++;
                                            scope.level.selected.push(true);
                                        }else{
                                            scope.level.selected.push(false);
                                        }
                                    }
                                    if(!checkNum){
                                        checkNum = checkNumT!=0&&checkNumT!=data[i].child[j].child.length
                                    }
                                    $(".menu-"+data[i].child[j].keywords+" input").prop("indeterminate", (checkNumT!=0&&checkNumT!=data[i].child[j].child.length));
                                }
                                if(data[i].child[j].selected){
                                    checkNumO++;
                                    scope.level.selected.push(true);
                                }else{
                                    scope.level.selected.push(false);
                                }
                            }
                            //当二级菜单不全部选中时checkbox样式状态置为'-'
                            $(".menu-"+data[i].keywords+" input").prop("indeterminate", (checkNumO!=0&&checkNumO!=data[i].child.length)||checkNum);
                        }
                    }
                    if(checkAll==data.length){
                        scope.level.selectedAll = true
                    }else if(checkAll==0){
                        scope.level.selectedAll = false
                    }
                    var menuStatus = 0,
                        menuStatusFlase = 0;
                    scope.level.selected.map(function(item){
                        if(item){
                            menuStatus++
                        }else{
                            menuStatusFlase++
                        }
                    })
                    $(".selected-all").prop("indeterminate", checkAll!=0&&checkAll!=data.length || menuStatus!=0&&!scope.level.selectedAll || menuStatusFlase!=0&&scope.level.selectedAll);
                }
                function resetChk(data){
                    //一级菜单循环
                    data.forEach(item1=>{
                        if(item1.child&&item1.child.length>0){
                            let tw = 0;
                            //二级菜单循环
                            item1.child.forEach(item2=>{
                                if(item2.child&&item2.child.length>0){
                                    let thr = 0;
                                    //三级菜单循环
                                    item2.child.forEach(item3=>{
                                        if(item3.selected){
                                            thr++;
                                        }
                                    })
                                    if(thr==item2.child.length){
                                        item2.selected = true;
                                    }else if(thr==0){
                                        item2.selected = false;
                                    }
                                }
                                if(item2.selected){
                                    tw++;
                                }
                                
                            })
                            if(tw==item1.child.length){
                                item1.selected = true;
                            }else { //二级菜单未全部选中时 一级菜单 为false
                                item1.selected = false;
                            }
                        }
                    })
                }
                scope.menuTog = {};
                scope.showLevelThree = false;
                //一级 二级 三级全选操作
                scope.setCheckbox = function(item1,index){
                    if(item1.child&&item1.child.length>0){
                        item1.child.forEach(item2=>{
                            item2.selected = item1.selected;
                            if(item2.child&&item2.child.length>0){
                                item2.child.forEach(item3=>{
                                    item3.selected = item1.selected;
                                })
                            }
                        })
                    }
                    resetChk(scope.menuData);
                    init(scope.menuData);
                }
                scope.toggleChk = function(v){
                    scope.menuTog[v] = !scope.menuTog[v];
                }
                scope.setAllCheckbox=function(flag){
                    if(scope.menuData&&scope.menuData.length>0){
                        scope.menuData.forEach(function(item1){
                            item1.selected = flag
                            if(item1.child&&item1.child.length>0){
                                item1.child.forEach(function(item2){
                                    item2.selected = flag
                                    if(item2.child&&item2.child.length>0){
                                        item2.child.forEach(function(item3){
                                            item3.selected = flag
                                        })
                                    }
                                   
                                })
                            }
                            
                        })
                    }
                    init(scope.menuData);
                }
                $timeout(function(){
                    init(scope.menuData);
                },500)
            }
        };
    }])
    .directive("menuTreeNode",["$timeout",function($timeout){
        return {
            restrict:"EA",
            scope:{
                nodeData:"=",
                nodeLevel:"="
            },
            replace:true,
            templateUrl: "/tmpl/menu-tree-node.html",
            link:function(scope,ele,attrs,ngModel){
                scope.nodeLevel = scope.nodeLevel*1;
                scope.nextLevel = scope.nodeLevel + 1;
                scope.setCheckbox = function(data){
                    let emitData = {
                        id:data.parentId,
                        isBtn:data.isBtn==1?true:false
                    }
                    // if(scope.nodeData.child.length>0){
                    //     checkChild(scope.nodeData);
                    // }
                    checkChild(scope.nodeData,scope.nodeData.selected);
                    scope.$emit('menu-update',emitData);
                    linkageGroupId(data)
                }
                scope.$on("menu-update",function(e,data){
                    if(data.id&&data.id.split(",").indexOf(String(scope.nodeData.id))>-1){
                        let emitData = {
                            id:scope.nodeData.parentId,
                            isBtn:scope.nodeData.isBtn==1?true:false
                        }
                        let len = 0,indeterNum = 0,isIndeter = false;
                        scope.nodeData.child.forEach(item=>{
                            if(item.selected){
                                len++
                            }

                            if(item.indeterminate){
                                indeterNum++
                            }
                        })
                        if(data.isBtn){
                            isIndeter = (len>0&&len<scope.nodeData.child.length)||(len==0&&scope.nodeData.selected);
                        }else{
                            if(len){
                                if(len<scope.nodeData.child.length||(len==scope.nodeData.child.length&&indeterNum>0)){
                                    isIndeter = true;
                                }
                            }
                        }

                        scope.nodeData['indeterminate'] = isIndeter;
                        
                        if(data.isBtn){
                            scope.nodeData.selected = true;
                        }else{
                            if(len==0){
                                scope.nodeData.selected = false;
                            }else{
                                scope.nodeData.selected = true;
                            }
                        }
                        if(data.isBtn&&len==0){
                            $(".menu-"+scope.nodeData.keywords+" input").prop("indeterminate",false);
                        }else{
                            $(".menu-"+scope.nodeData.keywords+" input").prop("indeterminate",isIndeter)
                        }
                        
                        if(scope.nodeData&&scope.nodeData.parentId){
                            scope.$emit('menu-update',emitData);
                        }
                        if(!scope.nodeData.parentId){
                            scope.$emit('menu-update-top',emitData);
                        }
                    }
                })
                scope.menuTog = {};
                scope.toggleChk = function(data){
                    if(data.child.length==0||(data.child.length>0&&data.child[0].isBtn==1)){
                        return;
                    }
                    scope.menuTog[data.keywords] = !scope.menuTog[data.keywords];
                }
                function linkageGroupId(tree) {
                    if(tree.isBtn==1&&tree.groupId) {
                        scope.$emit('check-btn-update',tree);
                    }else if(tree.child.length>0) {
                        tree.child.forEach(it=>{
                            linkageGroupId(it);
                        });
                    }
                }
                function checkChild(data,flag){
                    if(data.child.length>0){
                        data.child.forEach(item=>{
                            if(item.child.length>0){
                                checkChild(item,flag);
                            }
                            item.selected = flag;
                            item['indeterminate'] = false;
                        })
                        data['indeterminate'] = false;
                        $(".menu-"+data.keywords+" input").prop("indeterminate",false)
                    }
                }
                
                
            }
        }
    }])
    .directive("menuTreePre",["$timeout",function($timeout){
        return {
            restrict:"EA",
            scope:{
                nodeData:"="
            },
            templateUrl: "/tmpl/menu-tree-pre.html",
            link:function(scope,ele,attrs,ngModel){
                scope.lev = 1;
                scope.level={
                    selectedAll:[]
                }
                scope.menuTog = {};
                function checkChild(data,flag){
                    if(data.child.length>0){
                        data.child.forEach(item=>{
                            if(item.child.length>0){
                                checkChild(item,flag);
                            }
                            item.selected = flag;
                            item['indeterminate'] = false;
                        })
                    }
                    data['indeterminate'] = false;
                    data.selected = flag;
                    $(".menu-"+data.keywords+" input").prop("indeterminate",false)
                }
                function checkBtnGroup(data,cur){
                    if(data.isBtn==1&&data.groupId&&data.groupId==cur.groupId){
                        data.selected = cur.selected;
                    }else if(data.child.length>0){
                        data.child.forEach(item=>{
                            checkBtnGroup(item,cur);
                        })
                    }
                }
                function checkIndeter(data){
                    if(data.child.length>0){
                        let len = 0,indeterNum = 0,isBtn = false,isIndeter = false;
                        data.child.forEach(item=>{
                            if(item.child.length>0){
                                checkIndeter(item);
                            }
                            if(item.isBtn==1){
                                isBtn = true;
                            }
                            if(item.selected){
                                len++
                            }

                            if(!isBtn&&item['indeterminate']){
                                indeterNum++;
                            }
                        })
                        if(isBtn){
                            isIndeter = (len>0&&len<data.child.length)||(len==0&&data.selected);
                        }else{
                            if(len){
                                if(len<data.child.length||(len==data.child.length&&indeterNum>0)){
                                    isIndeter = true;
                                }
                            }
                        }

                        data['indeterminate'] = isIndeter;

                        if(isBtn&&len==0&&data.selected){
                            $(".menu-"+data.keywords+" input").prop("indeterminate",false);
                        }else{
                            $(".menu-"+data.keywords+" input").prop("indeterminate",isIndeter)
                        }
                        
                    }
                }
                scope.init = function(data){
                    let len = 0,indeterNum = 0,isIndeter = false;
                    data.forEach(item=>{
                        checkIndeter(item);

                        if(item.selected){
                            len++
                        }

                        if(item['indeterminate']){
                            indeterNum++;
                        }
                    })
                    if(len){
                        scope.level.selectedAll = true;
                        if(len<data.length||(len==data.length&&indeterNum>0)){
                            isIndeter = true;
                        }
                    }else{
                        scope.level.selectedAll = false;
                    }
                    $(".choose-all.checkbox input").prop("indeterminate",isIndeter);
                }
                $timeout(function(){
                    scope.init(scope.nodeData);
                },500)
                scope.$on("menu-update-top",function(e,data){
                    if(data.id){return};
                    let len = 0,indeterNum = 0,isIndeter = false;
                    scope.nodeData.forEach(item=>{
                        if(item.selected){
                            len++
                        }
                        if(item['indeterminate']){
                            indeterNum++;
                        }
                    })
                    if(len){
                        scope.level.selectedAll = true;
                        if(len<scope.nodeData.length||(len==scope.nodeData.length&&indeterNum>0)){
                            isIndeter = true;
                        }
                    }else{
                        scope.level.selectedAll = false;
                    }
                    $(".choose-all.checkbox input").prop("indeterminate",isIndeter);
                })
                scope.$on("check-btn-update",function(e,data){
                    scope.nodeData.forEach(item=>{
                        checkBtnGroup(item,data);
                    })
                })
                scope.setAllCheckbox = function(data,flag){
                    data.forEach(item=>{
                        checkChild(item,flag);
                    })
                    $(".choose-all.checkbox input").prop("indeterminate",false);
                }
                
            }
        }
    }])
    
    
