vgpuSrv.$inject = ["$rootScope", "$http"]
function vgpuSrv($rootScope, $http) {
    var static_url="";
    return {
        // 新建VGPU
        addNewVgpu:function(oData) {
            return $http({
                url: "/awstack-resource/v1/gpu/addVGpu",
                method: "post",
                data: oData,
            })
        },
        //删除VGPU
        deleteVGpu:function(data){
            return $http({
                method: "POST",
                url: static_url + "/awstack-resource/v1/gpu/delVGpu",
                data:data
            })
        },
        //获取物理GPU列表
        getPhysicGpu:function(){
            return $http({
                method: "GET",
                url: static_url + "/awstack-resource/v1/gpu/getGpuList",
            })
        },  
        // 同步底层GPU
        getsynchGpus:function(){
            return $http({
                method: "GET",
                url: static_url + "/awstack-resource/v1/gpu/synchGpus",
            })
        }, 
        // 获取vgpu列表
        getVGpuList:function(params){
            return $http({
                method: "GET",
                url: static_url + "/awstack-resource/v1/gpu/getVGpuList",
                params:params
            })
        },
         // 获取vgpu-bing列表
         getVGpuListTobind:function(params){
            return $http({
                method: "GET",
                url: static_url + "/awstack-resource/v1/gpu/getVGpuListTobind",
                params:params
            })
        },
        // 获取vgpu详情
        getVGpuDetail:function(params){
            return $http({
                method: "GET",
                url: static_url + "/awstack-resource/v1/gpu/getGpuSpecsDetailByVgpu",
                params:params
            })
        },
        // 根据节点获取GPU
        getGpuByNode:function(params){
            return $http({
                method: "GET",
                url: static_url + "/awstack-resource/v1/gpu/getGpuByNode",
                params:params
            })
        },
        // 根据节点和卡槽获取vgpu的规格 --新建
        getGpuSpecsByGpu:function(params){
            return $http({
                method: "GET",
                url: static_url + "/awstack-resource/v1/gpu/getGpuSpecsByGpu",
                params:params
            })
        },
    }
}
export {vgpuSrv}