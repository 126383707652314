dragDiskCache.$inject = [];

function dragDiskCache(){
    return {
        //添加存储池
        POOLS:{
            nodeUidMap:{
            },
            valid:true
        },
        //存储管理
        INCEPH:{
            nodeUidMap:{
            },
            valid:true
        },
        //节点管理
        NODEMANAGE:{
            nodeUidMap:{
            },
            valid:true
        },
        //ceph管理
        CEPHMANAGE:{
            nodeUidMap:{
            },
            valid:true
        },
        //初始化弹出层
        INITPAGE:{
            nodeUidMap:{
            },
            valid:true
        },
        //存储管理-对接存储
        OBJECTCEPH:{
            nodeUidMap:{
            },
            valid:true
        }
    }
}
export {dragDiskCache}