errorStartCtrl.$inject = ["$scope","editData","context","instancesSrv","refresh","$uibModalInstance"];
export function errorStartCtrl($scope,editData,context,instancesSrv,refresh,$uibModalInstance){
    /*不支持批量，初始状态在虚拟机详情里面--->{{context.editDataCopy}}里面，
    看这里，详情里面也需要展示此字段，任家彬看这里*/
    var self = $scope;
    self.canDoIt = false
    self.isError = {
        errorFlag : context.editDataCopy.abnormalRestart
    }   
    self.changeStatus = function(item){
        if(item != context.editDataCopy.abnormalRestart){
            self.canDoIt = true
        }else{
            self.canDoIt = false
        }
    }
    self.confirmDel = function(){
        instancesSrv.errorStart(context.editDataCopy.uid,self.isError.errorFlag).then(function(res){
            $uibModalInstance.close();
            refresh()
        })
    }
}