
var createDataCleanSrvModule = angular.module("createDataCleanSrvModule",[]);

createDataCleanSrvModule.service("createDataCleanSrv",["$http", function($http){
    return {

        // 获取备份任务下拉
        getBackupJobList: function(headers) {
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-data-clean/list/job",
                headers
            });
        },
        // 获取副本列表
        getJobTimepointsList: function(params, headers) {
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-data-clean/list/timepoints",
                params: params,
                headers
            });
        },
        // 创建数据清理
        createBackupDataClean: function(data, headers) {
            return $http({
                method: "POST",
                url: "/awstack-backup/v1/backup/backup-data-clean/create",
                data: data,
                headers
            });
        },
        // 校验名称是否重复
        checkDataCleanName: function(params) {
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-data-clean/check-name",
                params: params
            });
        }
        
    };
}]);