/**
 * 数据备份-任务详情
 */
dragBackupJobInfoCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","$location","$routeParams","TableCom","$filter","$route"]
function dragBackupJobInfoCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,$location,$routeParams,TableCom, $filter,$route) {
	var self = $scope;
    self.canDel = false;
    // 主键
    self.detailData = {
        id: $routeParams.id,
        jobName:"",
        createDate:"",
        strategyInfo:"",
        nextLaunchTime:"",
        concurrentBackup:"",
        permanentIncrementalBackup:"",
        backupAutoRetry:"",
        flowControl:[]
    };
    self.dataPageSize=10;
    self.dataCurrentPage=1;
	//设置项的初始化
	self.headers={
        type:$translate.instant("aws.backupService.backupJob.type"),
        name:$translate.instant("aws.backupService.backupJob.bindInstance.instanceName"),
        status:$translate.instant("aws.backupService.backupJob.bindInstance.status"),
    };
    self.tableCols=[
        { field: "check", title: "",headerTemplateURL:"backupJobInfoCheckbox.html",show: true ,width:"5%"},
        { field: "name", title: self.headers.name, show: true,disable:false },//云主机名称
        { field: "status", title: self.headers.status, show: true,disable:true },//状态
        { field: "ip", title: "IP", show: true,disable:false },//IP
    ];
    // 获取详情接口
    initBackupJobInfo();
    function initBackupJobInfo(reload){
        if(reload!=undefined) {
            $route.reload();//重新加载当前路由  
            $location.url('/advanced/backup-job');
        }
        // 赋值 headers
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            headers = {
                project_id: localStorage.projectUid,
                domain_id: localStorage.domainUid,
            }
        }
        self.backupJobInfo = "";
        backupJobSrv.getBackupJobInfo(self.detailData.id, headers).then(function (result) {
            if (result && result.data) {
                self.backupJobInfo = result.data;
                // 任务名称
                self.detailData.jobName = self.backupJobInfo.jobName;
                // 创建时间
                self.detailData.createDate = $filter("date")(self.backupJobInfo.created, "yyyy-MM-dd HH:mm:ss");
                // 策略详情
                self.detailData.strategyInfo = strategyAdvParam(self.backupJobInfo.strategyAdvParam);
                // 下次发起时间
                self.detailData.nextLaunchTime = self.backupJobInfo.nextLaunchTime != null ? $filter("date")(self.backupJobInfo.nextLaunchTime, "yyyy-MM-dd HH:mm:ss") : "-";
                // 并发备份 格式 并发备份数：10
                var jobAdvParam = JSON.parse(self.backupJobInfo.jobAdvParam);
                jobAdvParam.forEach(x => {
                    // 并发备份数 开关
                    if(x.key == "EEE_IS_USE_THREAD") {
                        if(x.value == '1') {
                            self.detailData.concurrentBackup = $translate.instant("aws.backupService.backupJob.dialog.instThreadNum") + "："+ echoStrategyAdvParam(jobAdvParam, "EEE_USE_THREAD_NUM");
                        }else {
                            self.detailData.concurrentBackup = $translate.instant("aws.backupService.variable.down"); 
                        }
                    }
                    // 永久增量备份
                    if(x.key == "EEE_PERMANENT_INCREMENT") {
                        self.detailData.permanentIncrementalBackup = x.value == '0' ? $translate.instant("aws.backupService.variable.down") : $translate.instant("aws.backupService.variable.up");
                    }
                    // 备份自动重试 开关
                    if(x.key == "EEE_VM_AUTO_RESTART") {
                        if(x.value == '1') {
                            // $translate.instant("aws.backupService.variable.up") + "，" + 
                            self.detailData.backupAutoRetry = $translate.instant("aws.backupService.backupJob.maxNumTry") 
                                + "："+ echoStrategyAdvParam(jobAdvParam, "EEE_VM_AUTO_RESTART_COUNT") + "，" + $translate.instant("aws.backupService.backupJob.dialog.retryWaitTime") 
                                + "：" + echoStrategyAdvParam(jobAdvParam, "EEE_VM_AUTO_RESTART_INTERVAL") + $translate.instant("aws.backupService.backupJob.dialog.minute");
                        }else {
                            self.detailData.backupAutoRetry = $translate.instant("aws.backupService.variable.down"); 
                        }
                    }
                    // 流量控制 开关
                    if(x.key == "EEE_FLOW_CONTROL_ENABLE") {
                        if(x.value == '1') {
                            self.detailData.flowControl = echoStrategyAdvParam(jobAdvParam, "EEE_FLOW_CONTROL_CONFIG"); 
                        }else {
                            self.detailData.flowControl = [$translate.instant("aws.backupService.variable.down")]; 
                        }
                    }
                });
                initTables();
            }
        });
    }
    function echoStrategyAdvParam(jobAdvParam, key){
        let b = "";
        jobAdvParam.forEach(x => {
            // 周一 ~ 周二 00:00 ~ 23:59，最大速度为1024MiB/s    周三 ~ 周五 00:00 ~ 23:59，最大速度为1024MiB/s
            if (x.key == key &&  key == "EEE_FLOW_CONTROL_CONFIG") {
                b = [];
                var dataList = x.value.split(";");
                dataList.forEach(y => {
                    var data = y.split("|");
                    // 开始日~结束日
                    var week = data[1].split(",");
                    var startTime = "";
                    var endTime = "";
                    if(week.length==1) {
                        week[0] = week[0] == "0" ? "7" : week[0];
                        startTime = week[0];
                        endTime = week[0];
                    }else{
                        week[0] = week[0] == "0" ? "7" : week[0];
                        week[1] = week[1] == "0" ? "7" : week[1];
                        startTime = week[0];
                        endTime = week[1];
                    }
                    // 限速时间段
                    var speedLimitTime = data[2];
                    // 格式 开始日：周一，结束日：周五，限速时间段：00:00至12:00，最大速度：2MIB/S;
                    var info = $translate.instant("aws.backupService.backupJob.dialog.startTime") + "：" + $translate.instant("aws.backupService.variable.week." + startTime) + "，" 
                                + $translate.instant("aws.backupService.backupJob.dialog.endTime") + "：" + $translate.instant("aws.backupService.variable.week." + endTime) + "，"
                                + $translate.instant("aws.backupService.backupJob.dialog.speedLimitTime") + "：" + speedLimitTime.split("-")[0].substr(0,5) + "~" + speedLimitTime.split("-")[1].substr(0,5) + "，"
                                + $translate.instant("aws.backupService.backupJob.dialog.maxSpeed") + "：" + data[3]/1024/1024 + "MiB/s" + ";"
                    b.push(info);
                    // b.push($translate.instant("aws.backupService.variable.week." + startTime) + " ~ "  + $translate.instant("aws.backupService.variable.week." + endTime) + " "
                    //     + speedLimitTime.split("-")[0].substr(0,5) + " ~ "  + speedLimitTime.split("-")[1].substr(0,5)
                    //     + "，" + $translate.instant("aws.backupService.backupJob.maxSeed")+ "：" + data[3]/1024/1024 + "MiB/s")
                })
            }else if(x.key == key && key != "EEE_FLOW_CONTROL_CONFIG") {
                b = x.value;
            }
        });
        return b;
    }
    function strategyAdvParam(strategyAdvParam){
        strategyAdvParam = JSON.parse(strategyAdvParam);
        switch(strategyAdvParam.type) {
            case 2://天  每几天
                strategyAdvParam = $translate.instant("aws.backupService.variable.every") + strategyAdvParam.params[0] + $translate.instant("aws.backupService.variable.day");
                break;
    
            case 3://周
                var info="";
                strategyAdvParam.params.forEach((y, index) => {
                    info += $translate.instant("aws.backupService.variable.week." + y);
                    if((index+1) < strategyAdvParam.params.length) {
                        info += ","
                    }
                });
                strategyAdvParam = $translate.instant("aws.backupService.variable.every") + info;
                break;
            case 4://月
                var info="";
                strategyAdvParam.params.forEach((y, index) => {
                    if(y==32) {
                        info += "（" + $translate.instant("aws.backupService.backupJob.dialog.days") + "）";
                    }else{
                        info += y + $translate.instant("aws.backupService.variable.days");
                        if((index+1) < strategyAdvParam.params.length) {
                            info += ","
                        }
                    }
                    
                });
                strategyAdvParam = $translate.instant("aws.backupService.variable.every") + $translate.instant("aws.backupService.variable.month") + info;
                break;
            case 5://年
                strategyAdvParam = $translate.instant("aws.backupService.variable.every") + $translate.instant("aws.backupService.variable.year")  
                    + strategyAdvParam.params[0] + $translate.instant("aws.backupService.variable.month")  + strategyAdvParam.params[1] + $translate.instant("aws.backupService.variable.days") ;
                break;
        }
        // 执行时间
        var runTimeDate = new Date(self.backupJobInfo.runTime);
        var hours = runTimeDate.getHours();
        var minutes = runTimeDate.getMinutes();
        var hour_a = hours < 10 ? "0" + hours : hours;
        var minute_a = minutes < 10 ? "0" + minutes : minutes;
        strategyAdvParam += "，" + $translate.instant("aws.backupService.backupJob.dialog.yu") + hour_a + ":" + minute_a + "，" 
            + $translate.instant("aws.backupService.backupJob.dialog.zdcj") + $translate.instant("aws.backupService.backupJob.backupType." + self.backupJobInfo.backupType); 
        return strategyAdvParam;
    }
    
	function initTables() {
        self.dataLoadData  = [];
        TableCom.init(self,"tableParams_info",[],"id",GLOBAL_CONFIG.PAGESIZE,'checkBackupJobInfoToCount');
        if(self.backupJobInfo.jobDataSource != null) {
            self.dataLoadData = self.backupJobInfo.jobDataSource;
            self.dataLoadData = operateData(self.dataLoadData);
            // 查询虚拟机状态
            var ids = [];
            self.dataLoadData.forEach(x => {
                ids.push(x.uuid);
            });
            backupJobSrv.getInstanceStatusList({ids:ids}).then(function (result) {
                if (result && result.data) {
                    self.dataLoadData_b = result.data;
                    self.dataTotalNum_b = result.data.length;
                    TableCom.init(self,"tableParams_info",self.dataLoadData_b,"id",GLOBAL_CONFIG.PAGESIZE,'checkBackupJobInfoToCount');
                }
            });
        }
	}
    function operateData(data){
        data.forEach(x => {
            x.type = $translate.instant("aws.backupService.variable." + x.nodeType);
            x.name = x.dispPath;
            x.ip = x.customer.split("@")[1];
        });
        return data;
    }
	self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
        // 判断翻页
        let newList = [];
        self.dataLoadData_b.forEach((x, index) => {
            if(newList.length < 11 && (index+1) < ((options.pageNum*options.pageSize) + 1) && (index+1) > (options.pageNum-1)*options.pageSize){
                newList.push(x);
            }
        });
        TableCom.init(self,"tableParams_info",newList,"id",GLOBAL_CONFIG.PAGESIZE,'checkBackupJobInfoToCount');
    };
	//监听按钮
    self.$watch(function() {
        return self.checkBackupJobInfoToCount;
    }, function(val) {
        if(val == undefined) {
            return;
        }
        self.checkedItems = [];
		var arr=[];
        for(var i in self.checkBackupJobInfoToCount.items){
            arr.push(self.checkBackupJobInfoToCount.items[i])
        }
		if(val && arr.length>=0){
            for(var key in val.items){
                if(val.items[key]){
                    self.dataLoadData_b.forEach(item=>{
                        if(item.id==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
        self.canDel = false;
		if(self.checkedItems.length > 0){  
			self.canDel = true;
		}
    },true);


    // 绑定实例弹出框
    self.bindInstanceModel = bindInstanceModel;
    function bindInstanceModel(){
        var editData = self.backupJobInfo;
        var type = "";
        $uibModal.open({
            animation: true,
            templateUrl: "js/advanced-function/backup/backup-job/tmpl/bind-instance.html",
            controller: "bindInstanceJobCtrl",
            resolve: {
                type: function() {
                    return type;
                },
                editData: function() {
                    return editData;
                },
                initBackupJobTable: function() {
                    //刷新table;
                    return initBackupJobInfo;
                },
            }
        });
    }

    //删除操作
    self.delBackupJobInst = delBackupJobInst;
    function delBackupJobInst(checkedItems) {
        if(!self.canDel) {
            return;
        }
        var content = {
            target: "delBackupJobInst",
            msg: "<span>" + $translate.instant("aws.backupService.messageInfo.deleteInfo") + "</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delBackupJobInst", function(e, data) {
        // 封装参数
        var jobDataSource = [];
        
        // 判断是否全选了
        if(self.checkedItems.length == self.dataLoadData_b.length){
            // 直接调用删除任务接口
            let ids = [];
            ids.push(self.backupJobInfo.id);
            var params = {
                ids: ids
            }
            // headers
            var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                const {projectUid,domainUid} = self.backupJobInfo;
                headers = {
                    project_id: projectUid,
                    domain_id: domainUid,
                }
            }
            backupJobSrv.deleteBackupJob(params, headers).then(function () {
                // 跳转到任务主页面
                $route.reload();//重新加载当前路由  
                $location.url('/advanced/backup-job');
            });
            return;
        }
        // 过滤选择的数据源
        let data_Source = [];
        self.dataLoadData_b.forEach(x => {
            let b = true;
            data.forEach(y => {
                if(y.uid == x.uid) {
                    b = false;
                }
            });
            if(b) {
                data_Source.push(x);
            }
        });
        data_Source.forEach(x => {
            var customer = x.uid + "@";
            if(x.privateIpAddress != null) {
                customer += x.privateIpAddress.replace(",",";");
            }
            var data = {
                uuid: x.uid,//云主机id
                dispPath: x.name,//云主机名称
                customer: customer
            }
            jobDataSource.push(data);
        });
        var params = {
            id: $routeParams.id,
            jobDataSource: jobDataSource,
        }
        backupJobSrv.bindInstance(params).then(function () {
            //刷新页面列表
            initBackupJobInfo();
        });
    })
	 
};
export {dragBackupJobInfoCtrl}