publicNetworksconfigCtrl.$inject = ["$scope", "floatipsSrv", "checkQuotaSrv", "$rootScope", "commonFuncSrv", "$uibModalInstance", "instancesSrv",]
function publicNetworksconfigCtrl(scope, floatipsSrv, checkQuotaSrv, $rootScope, commonFuncSrv, $uibModalInstance, instancesSrv) {
    let self = scope;
    function getPrice() {
        if (!$rootScope.billingActive) {
            return;
        }
        var postData = {
            "region": localStorage.regionName ? localStorage.regionName : "default",
            "floatingIpCount": 1
        }
        floatipsSrv.getPrice(postData).then(function (data) {
            if (data && data.data && !isNaN(data.data)) {
                self.showPrice = true;
                self.price = data.data;
                self.priceFix = self.price.toFixed(2)
                self.totalPrice = (self.price * 30 * 24).toFixed(2)
            } else {
                self.showPrice = true;
                self.price = "N/A";
                self.priceFix = "N/A";
                self.totalPrice = "N/A"
            }

        })
    }
    function addFloatipFunc(params) {
        floatipsSrv.addFloatipAction(params).success(function () {
            getAllPublicIp();
        });
    };
    function getAllPublicIp() {
        instancesSrv.getAllfloalingIp().then(function (result) {
            if (result && result.data && result.data.length) {
                self.IpsList = result.data.filter(item => !(item.portId));
                self.bindIp.ip = self.IpsList[0];
                return result.data;
            }
        })
    }
    getPrice()
    checkQuotaSrv.checkQuota(scope, "floatingip");
    scope = commonFuncSrv.setAssignIpFun(scope, "floatipForm", "createfloatipForm", "external");

    scope.floatipConfirm = function (createfloatipForm) {

    };
}

export { publicNetworksconfigCtrl }