dragMessageInfo.$inject = ["$timeout"];
function dragMessageInfo($timeout) {
    return {
        restrict:"E",
        replace:true,
        scope:{
            data:"=",
            isShow:"="
        },
        templateUrl:"js/system/storageManagement/tmpl/drag-message-info.html",
        link: function(self, elem, attrs, $ngModel) {
            let type = attrs.type?attrs.type:"";
            let id = attrs.cb?attrs.cb:"";

            //磁盘未通过校验提示框-确认
            self.confirm = function(){
                self.isShow = false;
                self.$emit('drag-force-valid',{flag:true,type:type});
                if(id){
                    $timeout(function(){
                        $(`#${id}`).click();
                    },50)
                }
            }

            //磁盘未通过校验提示框-取消
            self.closeDeleteWrap = function(){
                self.isShow = false;
                self.$emit('drag-force-valid',{flag:false,type:type});
            }
        }
    };
}
export {dragMessageInfo}