import "./recycleSrv";
import "../instances/instancesSrv";
import "../overview/cvmViewSrv";
import "../../department/depviewSrv";
import { detailConfigInfoTpl } from '../instances/directive/detail-config-info-tpl';
import { detailLogTpl } from '../instances/directive/detail-log-tpl';
var recycleModule = angular.module("recycleModule", ["recycleSrvModule"]);
recycleModule.controller("recycleCtrl",["$rootScope", "$scope", "$location", "NgTableParams", "recycleSrv", "checkedSrv", "$uibModal", "$translate","GLOBAL_CONFIG","$filter","instancesSrv","cvmViewSrv","depviewsrv","generalSrv","$routeParams","TableCom","bandWidthSrv","utilTool","$timeout",
    function($rootScope, $scope, $location, NgTableParams, recycleSrv, checkedSrv, $uibModal, $translate,GLOBAL_CONFIG,$filter,instancesSrv,cvmViewSrv,depviewsrv,generalSrv,$routeParams,TableCom,bandWidthSrv,utilTool,$timeout) {
        var self = $scope;
        self.showalerttip = true;
        self.context = self;
        self.pageSize = 10;
        function getRecycleTime() {
            generalSrv.getRecycleTimeData(localStorage.regionKey).then(function(res) {
                if(res && res.data) {
                    var time = parseInt(res.data.reclaim_instance_interval / 3600);
                    self.recycle = {"time":time};
                }
            });
        }

        bandWidthSrv.getLimitData().then(function(res) {
            if (res && res.data && res.data.length != 0) {
                self.limitData = JSON.parse(res.data[0].paramValue);
            }
        });

        self.headers = {
            name:$translate.instant("aws.instances.cloudInstanceName"),
            domain:$translate.instant("aws.common.domain"),
            project:$translate.instant("aws.common.project"),
            status:$translate.instant('aws.instances.status'),
            imgName:$translate.instant('aws.instances.imgName'),
            privateIP:$translate.instant('aws.instances.privateIP'),
            create_at:$translate.instant('aws.instances.create_at'),
            updatetime:$translate.instant('aws.instances.deletetime'),
        }
        self.searchList = [
            {name:$translate.instant('aws.instances.table.status.all'),id:null,key:""},
            {name:$translate.instant('aws.recycle.table.status.soft-delete'),id:"soft_deleted",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori"},
            {name:$translate.instant('aws.instances.table.status.restoring'),id:"restoring",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
            // {name:$translate.instant('aws.instances.table.status.deleting'),id:"deleting",value:$translate.instant('aws.instances.table.status.error'),key:"status_ori"},
            // {name:$translate.instant('aws.instances.table.status.active'),id:"active",value:$translate.instant('aws.instances.table.status.paused'),key:"status_ori"},
            // {name:$translate.instant('aws.instances.table.status.deleted'),id:"deleted",value:$translate.instant('aws.instances.table.status.suspended'),key:"status_ori"},
        ]
        self.titleName="recycle";
        if(sessionStorage["recycle"]){
            self.tableCols=JSON.parse(sessionStorage["recycle"]);
        } else {
             self.tableCols = [
                 { field: "check", title: "",headerTemplateURL:"headerCheckbox.html",show: true },
                 { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                 { field: "status_ori", title: self.headers.status,sortable: "status",show: true,disable:false},
                 { field: "imageName", title: self.headers.imgName,sortable: "imageName",show: true,disable:false},
                 { field: "fixedIps", title: self.headers.privateIP,sortable: "fixedIps_long",show: true,disable:false},
                 { field: "createtime", title: self.headers.create_at,sortable: "createtime",show: true,disable:false},
                 { field: "updatetime", title: self.headers.updatetime,sortable: "updatetime",show: true,disable:false},
             ];
        }
        if(self.tableCols.findIndex(item => item.field == 'label') == -1){
            self.tableCols.push({field: "label", title: '标签',sortable: "label",show: true,disable:false})
        }

        //添加对项目名称的监听
        self.$watch(function(){
            return localStorage.selectedAllDomain
        },function(ne){
            utilTool.projectDomainTableCol(ne,2,self)
        })

        self.configSearch = function(data,tableCols){
            var tableData =  data;
            tableData.map(item => {
                editSearch(item,tableCols)
            })
            return tableData;
        }
        function editSearch(item,tableCols){
            var searchTerm = []
            tableCols.map(search => {
                if(search.title && search.show){
                    searchTerm.push(item[search.field])
                }
            })
            item.searchTerm =searchTerm.join("\b") ;
            return item;
        }
        self.formData = {
            "pageNum": "1",
            "pageSize": "10"
        }
        // self.getSoftDel = function(){
        //     self.currentPage = 1;
        //     recycleSrv.getGcList(self.formData).then(function(result){
        //         if(result && result.data && angular.isArray(result.data)){
        //             // if (localStorage.projectName == "admin" && self.ADMIN) {
        //             //     result.data = result.data.filter(function(item) {
        //             //         return item.projectUid == localStorage.projectUid;
        //             //     });
        //             // }
        //             successFunc(result.data); 
        //         }
        //         result?self.loadData = true:"";
        //         self.totalNum = result.total;
        //     });
        // };
        function successFunc(result) {
            //初始化表格
            var data = result.data;
            data.map(item => {
                item.updatetime = $filter("date")(item.updatetime,"yyyy-MM-dd HH:mm:ss");
                item.createtime = $filter("date")(item.createtime,"yyyy-MM-dd HH:mm:ss");
                item.status = item.status.toLowerCase();
                if (item.fixedIps_long) {
                    item.fixedIps_long = item.fixedIps.map(ip => _IP.toLong(ip)) ;
                }
                if(item.domainName=="default"){
                    item.domainName = $translate.instant("aws.common.defaultDepar")
                }else{
                    item.domainName = item.domainName;
                }
                if(item.projectName=="admin"){
                    item.projectName = $translate.instant("aws.common.defaultProject")
                }else{
                    item.projectName = item.projectName;
                }
                item.status_ori = $translate.instant("aws.recycle.table.status."+item.status);
                item.searchTerm = [item.name,item.status_ori,item.imageName,item.fixedIps,item.floatingIps,item.createtime,item.updatetime].join("\b");
            });

            self.tabledata = self.configSearch(data,self.tableCols);
            self.totalNum = result.total;
            self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: self.tabledata});
            var tableId = "uid";
            checkedSrv.checkDo(self, data, tableId);
        }
        //self.getSoftDel();
        getRecycleTime();

        function getrecyleList(options){
            self.loadData="";
            self.currentPage=options.pageNum;
            recycleSrv.getGcList(options).then(function(result){
                if(result && result.data && angular.isArray(result.data)){
                    // if (localStorage.projectName == "admin" && self.ADMIN) {
                    //     result.data = result.data.filter(function(item) {
                    //         return item.projectName == localStorage.projectUid;
                    //     });
                    // }
                    successFunc(result); 
                }   
                result?self.loadData = true:"";
            });
        }
        var pageOption = {
            pageSize: self.pageSize,
            pageNum:1,
            pattern:null,
            status:null
        };
        getrecyleList(pageOption)

        // 打开详情
        self.linkTo = function(item){
            let insStatus = item.status.toLowerCase();
            utilTool.setLocalStorage([item]);
            $location.url(`/cvm/recycle?id=${item.uid}&insStatus=${insStatus}`);
        }

        // 关闭详情
        self.linkClose = function(){
            self.checkboxes.items = {};
            $location.url('/cvm/recycle');
            self.tab = {};// 解决再次打开详情时 详情的directive不会刷新的问题
            self.tabCacheObj = utilTool.tabsShow(2,'close');
        }

        // 详情加载的内容
        self.$on("getDetail", function(event, insUid) {
            self.panels = {};
            self.tab = {};
            self.tab = {
                active : 0
            }
            self.tabCacheObj = utilTool.tabsShow(2,'open');
            self.chartDisplay = function(i) {
                self.tab.active = i
                self.tabCacheObj[`tabCache${i}`] = true
            }
            self.insUid = insUid;
        })
        
        //资源导出
        self.exportResource = function(){
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/instances_gc?regionKey=${localStorage.regionKey}&type=2&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm?self.globalSearchTerm:""}&status=${self.statusSelected.searchItem.id?self.statusSelected.searchItem.id:""}`
            utilTool.downloadFile(url);
        }
        self.showExport = utilTool.isAllDomainSelect;
        //切换页码
        self.changePage=function(options){
            console.log(options);
            options.pattern = self.globalSearchTerm?self.globalSearchTerm:null
            options.status = self.statusSelected.searchItem?self.statusSelected.searchItem.id:null
            getrecyleList(options);
        };
        //后端搜索
        self.searchBackend = function(options){
            var pageOption = {
                pageSize: self.pageSize,
                pageNum:1,
                pattern:self.globalSearchTerm?self.globalSearchTerm:null,
                status:self.statusSelected.searchItem?self.statusSelected.searchItem.id:null
            };
            if(options.type=='refresh'){
                self.currentPage = 1;
                self.globalSearchTerm = null;
                pageOption.pattern = null;
                pageOption.status = null;
                self.statusSelected.searchItem = self.searchList[0]
            }
            getrecyleList(pageOption);
        }

        //恢复云主机
        self.restoreRes = function() {
            self.sartTest = false;
            var value = self.checkedItems[0].uid
            instancesSrv.getServerDetail(value).then(function(result){
                if(result && result.data){
                    getProused(result.data);
                }
            }) 
            // if(localStorage.permission == "stand"){
            //     var content = {
            //         target: "restoreRes",
            //         msg:"<span>"+ $translate.instant("aws.instances.tipMsg.restoreSever") + "</span>",
            //         type: "info",
            //         btnType: "btn-primary"
            //     };
            //     self.$emit("delete", content);
            // }else{
            //     self.sartTest = false;
            //     var value = self.checkedItems[0].uid
            //     instancesSrv.getServerDetail(value).then(function(result){
            //         if(result && result.data){
            //             getProused(result.data);
            //         }
            //     }) 
            // }
            
        };
        self.processArr=["restoring","deleting"];
        self.$watch(function(){
            return self.checkedItems;
        },function(values,old){
            if(values&&old&&values.length!=old.length){
                utilTool.setLocalStorage(values);
            }
            self.canClear=false;
            self.canRecovery=false;
            self.forceDel=false;
            if(!values){
               return;
            }
            if(values.length==1){
               if(!_.include(self.processArr,values[0].status)){
                  self.canClear=true;
                  self.canRecovery=true;
                  self.forceDel=true;
               }
            }else if(values.length>1){
               self.canClear=true;
               for(var i=0;i<values.length;i++){
                   if(_.include(self.processArr,values[0].status)){
                      self.canClear=false;
                      break;
                   }
               }
            }
        },true);
    
        self.$watch(function(){
            return self.sartTest
        },function(value){
            if(value){
                var ramtip="",coretip="",instip="",text;
                if(self.coreProText){
                    coretip = self.coreProText;
                }else if(self.coreDomText){
                    coretip = self.coreDomText
                }
                if(self.ramProText){
                    ramtip = self.ramProText;
                }else if(self.ramDomText){
                    ramtip = self.ramDomText;
                }
                if(self.insProText){
                    instip = self.insProText;
                }else if(self.insDomText){
                    instip = self.insDomText;
                }
                if(coretip || ramtip || instip ){
                    text = instip + "</br>" + coretip + "</br>"+ramtip;
                    var content = {
                        target: "restoreRes",
                        msg:"<span>"+ text + "</span>",
                        type:"danger" ,
                        btnType: "btn-primary"
                    };
                    content.notDel = true;
                }else{
                    text = $translate.instant("aws.instances.tipMsg.restoreSever")
                    var content = {
                        target: "restoreRes",
                        msg:"<span>"+ text + "</span>",
                        type: "info",
                        btnType: "btn-primary"
                    };
                }
                
                self.$emit("delete", content);
            }
        })
        self.$on("restoreRes", function() {
            var del_ids = [];
            self.notDel = true;
            self.checkedItems.map(item => {del_ids.push(item.uid);});
            checkedSrv.setChkIds(del_ids,"restore")
            recycleSrv.osRestore({ids:del_ids}).then(function() {
                self.searchBackend({type:"refresh"});
            });
        });
        
        //强制删除云主机
        self.forceDelRes = function() {
            var content = {
                target: "forceDelRes",
                msg:"<span>"+ $translate.instant("aws.instances.tipMsg.forcedelSever") + "</span>"
            };
            self.$emit("delete", content);
        };
        self.$on("forceDelRes", function() {
            var del_ids = [];
            self.checkedItems.map(item => {del_ids.push(item.uid);});
            checkedSrv.setChkIds(del_ids,"delete")
            recycleSrv.osForceDel({ids:del_ids}).then(function() {
                //self.searchBackend({type:"refresh"});
            });
        });

        self.forceDelVm = function(){
            var content = {
                target: "forceDelVm",
                msg:"<span>"+ $translate.instant("aws.instances.tipMsg.forcedelvm") + "</span>"
            };
            self.$emit("delete", content);
        }
        self.$on("forceDelVm", function() {
            var del_ids = [];
            self.checkedItems.map(item => {del_ids.push(item.uid);});
            checkedSrv.setChkIds(del_ids,"delete")
            recycleSrv.osForceDelVm(del_ids[0],{serverName:self.checkedItems[0].name}).then(function() {
                self.searchBackend({type:"refresh"});
            });
        });

        self.close = function(){
            self.showalerttip = false;
        };

        $rootScope.$on("deleteVmSocket",function(e,data){
            if(data.eventType == "compute.instance.delete.end"){ //删除
                $timeout(function(){
                    self.searchBackend({type:"refresh"});
                },500)
            }
        })
        //server socket
        $rootScope.$on("serverSocket",function(e,data){
            if(self.tabledata && self.tabledata.length){
                self.tabledata.map(function(obj){
                    if(data.eventMata.instance_id){
                        if(obj.uid === data.eventMata.instance_id){
                            obj.status = data.eventMata.status;
                            if(data.eventType == "compute.instance.restore.end" ){ //恢复
                                // _.remove(self.tabledata,function(val){
                                //     return val.status =="active";
                                // });
                                $timeout(function(){
                                    self.searchBackend({type:"refresh"});
                                },500)
                            }
                        }
                    }
                });
                self.tableParams.reload();
                self.checkboxes.items={};
            }
        });

        //获取项目中的总配额
            function getproQuotas(data) {
                var insQuotapost = {
                    type: "project_quota",
                    targetId: localStorage.projectUid,
                    enterpriseUid: localStorage.enterpriseUid
                };
                cvmViewSrv.getproQuotas(insQuotapost).then(function(result) {
                    if (result && result.data && result.data.length) {
                        _.forEach(result.data, function(item) {
                            if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                                self[item.name + "quota"].total = item.defaultQuota || item.hardLimit;
                            }
                        });
                        (data.vcpus> self.coresquota.total - self.coresquota.used) ? self.coreProText = $translate.instant("aws.instances.quota.proCpu"): self.coreProText = "";
                        (data.ram > self.ramquota.total - self.ramquota.used) ? self.ramProText = $translate.instant("aws.instances.quota.proRam"): self.ramProText = "";
                        (1 > self.instancesquota.total - self.instancesquota.used) ? self.insProText = $translate.instant("aws.instances.quota.proIns"): self.insProText = "";
                }
                    getDomused(data);
                });
            }
            //获取项目中配额的使用量
            function getProused(data) {
                self.instancesquota = {};
                self.coresquota = {};
                self.ramquota = {};
                var postData = {
                    type: "project_quota",
                    domainUid: localStorage.domainUid,
                    projectUid: localStorage.projectUid,
                    enterpriseUid: localStorage.enterpriseUid
                };
                cvmViewSrv.getProused(postData).then(function(result) {
                    if (result && result.data && result.data.length) {
                        _.forEach(result.data, function(item) {
                            if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                                self[item.name + "quota"].used = item.inUse;
                            }
                        });
                    }
                    getproQuotas(data);
                });
                
            }
            //获取部门中的总配额
            function getDomQuotas(data) {
                depviewsrv.getQuotaTotal(localStorage.domainUid).then(function(result) {
                    if (result && result.data && result.data.length) {
                        _.forEach(result.data, function(item) {
                            if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                                self[item.name + "Domquota"].total = item.defaultQuota || item.hardLimit;
                            }
                        });
                        (data.vcpus > self.coresDomquota.total - self.coresDomquota.used) ? self.coreDomText = $translate.instant("aws.instances.quota.domCpu"): self.coreDomText = "";
                        (data.ram> self.ramDomquota.total - self.ramDomquota.used) ? self.ramDomText = $translate.instant("aws.instances.quota.domRam"): self.ramDomText = "";
                        (1 > self.instancesDomquota.total - self.instancesDomquota.used) ? self.insDomText = $translate.instant("aws.instances.quota.domIns"): self.insDomText = "";
                        self.sartTest = true;
                     }
                });
            }
            //获取部门中配额的使用量
            function getDomused(data) {
                self.instancesDomquota = {};
                self.coresDomquota = {};
                self.ramDomquota = {};
                depviewsrv.getQuotaUsed(localStorage.domainUid).then(function(result) {
                    if (result && result.data && result.data.length) {
                        _.forEach(result.data, function(item) {
                            if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                                self[item.name + "Domquota"].used = item.inUse;
                            }
                        });
                    }
                    getDomQuotas(data);
                });
                
            }
}])
.directive('recycleDetailConfigInfoTpl',detailConfigInfoTpl)
.directive('recycleDetailLogTpl',detailLogTpl)