var fileUploadModule = angular.module('fileUploadModule', []);

fileUploadModule.directive('onReadFile', function ($parse) {
  return {
    restrict: 'A',
    scope: false,
    link: function(scope, element, attrs) {
      var fn = $parse(attrs.onReadFile);            
      element.on('change', function(onChangeEvent) {
        // var reader = new FileReader();                
        // reader.onload = function(onLoadEvent) {
        //   scope.$apply(function() {
        //     fn(scope, {$fileContent:onLoadEvent.target.result});
        //   });
		// };
		const file = (onChangeEvent.srcElement || onChangeEvent.target).files[0];
        scope.file = {};
        scope.file.name= file ? file.name : '';
        scope.$apply();
        
        /*scope.objectName = ((onChangeEvent.srcElement || onChangeEvent.target).files[0]).name;
        scope.fileName = ((onChangeEvent.srcElement || onChangeEvent.target).files[0]).name;
        scope.objectType = ((onChangeEvent.srcElement || onChangeEvent.target).files[0]).type;*/
        //reader.readAsText((onChangeEvent.srcElement || onChangeEvent.target).files[0]);
      });
    }
  };
});


//controller for upload-object
fileUploadModule.directive('uploadForm', function(){
  return {
    restrict:'A',
    scope:false,
    link:function (scope, elm, attrs) {
      	elm.on('submit', function(){
	        if(!scope.typefile){
	        	scope.showloading = true;
	        	if(scope.file && scope.file.name){
	        		var httpUrl = scope.actionUrl + scope.file.name;
	        		if(attrs.bareurl){
	        			httpUrl = scope.actionUrl
	        		}
	        		var xhr = new XMLHttpRequest();
	        		if(attrs.fietype == "sahara"){
	        			xhr.open('PUT',httpUrl);
	        		}else{
	        			xhr.open('POST',httpUrl);
	        		}
	        		xhr.setRequestHeader("X-Auth-Token", localStorage.$AUTH_TOKEN);
	        		xhr.upload.onprogress = function(event){
	        		  scope.progress = 100*event.loaded / event.total;
	        		}
	        		xhr.upload.onerror = function(e){
	        		  console.log(e)
	        		}
	        		xhr.onload = function(e){
	        		  try {
	        		    //解析服务器返回的AWStackResponse
	        		    var result = JSON.parse(e.currentTarget.response);
	        		    //var result = e.currentTarget.response;
	        		    if ( result.code=="0" && result.data.status==0 ){
	        		      scope.showloading = false;
	        		      scope.ok(result.data);
	        		    } else {
	        		      scope.error();
	        		    }
	        		  } catch (error) {
	        		    scope.error();              
	        		  }

	        		}
	        		xhr.onerror = function(e){
	        		  scope.showloading = false;
	        		  scope.error();
	        		}
	        		xhr.send(new FormData(this));
	        	}else {
	        		scope.bin.submitValid = true
	        	}
	        }else{
	        	scope.confirmBin(this);
	        	scope.showloading = false;
	        }
	        
	        scope.$apply();
      	})

    }   
  }
});
