var utilModule = angular.module("utilModule", []);

utilModule.service("utilTool", [function() {
    this.projectDomainTableCol = function(selected,inset,self,tableCols="tableCols"){
        var arr = [
            { field: "domain", title: self.headers.domain,sortable: "domainName",show: true,disable:false},
            { field: "project", title: self.headers.project,sortable: "projectName",show: true,disable:false},
        ]
        self.hasDomainProject = false;
        for(let i=0;i<self[tableCols].length;i++){
            if(self[tableCols][i].field=='domain'){
                self.hasDomainProject = true;
                break;
            }
        }
        if(selected==1){
            if(!self.hasDomainProject){
                //self[tableCols].splice(inset,0,arr[0],arr[1]);
                self[tableCols].push(...arr);
                sessionStorage[self.titleName] = JSON.stringify(self[tableCols])
            }
        }else{
            if(self.hasDomainProject){
                self[tableCols].splice(-2,2);
                sessionStorage[self.titleName] = JSON.stringify(self[tableCols])
            }
        }
    }
    this.downloadFile = function(url){
        var link = document.createElement('a');
        link.setAttribute('href', url);
        var event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
        link.dispatchEvent(event);
    }
    this.isAllDomainSelect = function(){
        return localStorage.selectedAllDomain==1
    }
    this.setLocalStorage = function(value){
        if(localStorage.selectedAllDomain==1&&localStorage.managementRole=='2'){
            if(value&&value.length==1){
                localStorage.domainName =value[0].domainName;
                localStorage.domainUid = value[0].domainUid;
                localStorage.projectName = value[0].projectName;
                localStorage.projectUid = value[0].projectUid;
            }else{
                localStorage.domainName =localStorage.defaultdomainName;
                localStorage.domainUid = localStorage.defaultdomainUid;
                localStorage.projectName = localStorage.defaultProjectName;
                localStorage.projectUid = localStorage.defaultProjectUid;
            }
        }
    }
    this.newResSetLocalStorage = function(self,obj,child){
        if(localStorage.selectedAllDomain==1&&localStorage.managementRole=='2'){
          localStorage.domainName =localStorage.defaultdomainName;
          localStorage.domainUid = localStorage.defaultdomainUid;
          localStorage.projectName = localStorage.defaultProjectName;
          localStorage.projectUid = localStorage.defaultProjectUid;
          self[obj][child] = {};
        }
    }
    // 生成随机密码
    // 密码长度为8-16个字符且至少包含一个大写字母，一个小写字母，一个数字和一个特殊字符。特殊字符包括! - @ . ^ _ % , : /
    this.randomPwd = function(min,max) {
        let range = min,
        passwordArray = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '!-@.^_%,:/'],
        password = [],
        n = 0;
      
        if(max){
            range = Math.round(Math.random() * (max-min)) + min;
        }
        for(let i=0; i<range; i++){
            if ( password.length < (range - 4) ) {
                let arrayRandom = Math.floor(Math.random() * 4);
                let passwordItem = passwordArray[arrayRandom];
                let item = passwordItem[Math.floor(Math.random() * passwordItem.length)];
                password.push(item);
            } else {
                let newItem = passwordArray[n];
                let lastItem = newItem[Math.floor(Math.random() * newItem.length)];
                let spliceIndex = Math.floor(Math.random() * password.length);
                password.splice(spliceIndex, 0, lastItem);
                n++
            }
        }
        return password.join('');
    }
    // 复制到剪切板
    this.copyToClip = function(copyTxt,callback){
        var tag = document.createElement('input');
        tag.setAttribute('id', 'inputid');
        tag.value = copyTxt;
        document.getElementsByTagName('body')[0].appendChild(tag);
        document.getElementById('inputid').select();
        document.execCommand('copy');
        document.getElementById('inputid').remove();
        if(callback){
            callback()
        }
    }

    //校验磁盘是否可以批量配置
    this.checkDiskConfig = function(checkedItems){
        var checkDiskData = [],isDiskChecked=false;
        checkedItems.forEach(item=>{
            let arr = [];
            arr = item.diskAttributesList.map(it=>{
                return {
                    "capacity":it.capacity,
                    "name":it.name,
                    "ssd":it.ssd
                }
            })
            checkDiskData.push(arr);
            
        })
        for(var i=0;i<checkDiskData.length-1;i++){
            var firstDisk = JSON.stringify(checkDiskData[i]);
            var elseDisk = JSON.stringify(checkDiskData[i+1]);
            if(firstDisk!=elseDisk){
                isDiskChecked =true;
                break;
            }
        }
        return isDiskChecked;
    }
    //磁盘单位转化,传入值为(xx xB),将磁盘大小转化为GB类型并去掉单位
    this.changeUnit = function(v){
        if(v.search(/TB$/i)>-1){
            return Number(v.replace(/TB$/i,""))*1024;
        }else if(v.search(/PB$/i)>-1){
            return Number(v.replace(/PB$/i,""))*1024*1024;
        }else if(v.search(/GB$/i)>-1){
            return Number(v.replace(/GB$/,""));
        }else if(v.search(/MB$/i)>-1){
            return parseInt(Number(v.replace(/MB$/i,""))/1024);
        }else if(v.search(/KB$/i)>-1){
            return parseInt(Number(v.replace(/KB$/i,""))/1024/1024);
        }
        return 0;
    }
    //单位转化，传入值为数字，GB单位
    this.setUnit = function(v){
        if(parseInt(v/(1024*1024)) > 0){
            return {total:(v/(1024*1024)).toFixed(2),unit:"PB"};
        }else if(parseInt(v/1024) > 0){
            return {total:(v/1024).toFixed(2),unit:"TB"};
        }
        return {total:v.toFixed(2),unit:"GB"};
    }
    
    // 根据传入的数据类型来进行数据分类 传入数据源数组arr和分类的字段key  返回分类之后的新数组
    this.classifyArr = function(arr, key) {
        let kind = []; //存放属性标识
        let newArr = []; //返回的数据
        arr.map(item=>{
            // 判断key是否存在，不存在则添加
            if(!kind.includes(item[key])) {
                kind.push(item[key]); //kind添加新标识
                newArr.push([]); //添加数组
            }
            let index = kind.indexOf(item[key]); //返回带有标识在kind内的下标，判断加入哪个数组
            newArr[index].push(item); //将对象存入数组
        })
        return newArr;
    }

    //转化平台版本
    this.changePlatformV = function(version){
        var handData = version.split("-");
        let fullYear = handData[1];
        let val;
        if(fullYear){
            var sData = fullYear;
            var sY = sData.substring(0,4);
            var sM = sData.substring(4,6);
            var sD = sData.substring(6);
            var newData = new Date(sY,(sM-1),sD,0,0,0);
            var oldData = new Date(sY,0,1,0,0,0,0);
            var timer = (newData.getTime() - oldData.getTime())/(1000*86400)+1;
            var dataTime =sData.substring(2,4)+"-"+timer;
            val=handData[0]+"-"+dataTime;
        }else{
            val = handData[0];
        }
        return val;
    }

    // 修改supportOtherClouds
    this.updateSupportOtherClouds = function(text,action){
        let oldVal = localStorage.supportOtherClouds;
        let neVal;

        neVal = oldVal.split(",").filter(item=>{
            return item&&item!=text;
        });
        
        if(action=="add"){
            neVal.push(text);
        }
        localStorage.supportOtherClouds = neVal.join(",")+",";
    }

    /*
        *初始化详情页tabs初次加载的状态
        *num Number  tabs栏的个数 例如有5个tabs栏 就传5
        *type String open打开时默认第一个tabs页是true,其余tabs页为false;close时全部置为false
    */ 
    this.tabsShow = function(num,type){
        let tabCacheObj ={};
        for (let i = 0; i < num; i++) {
            switch(type){
                case "open":
                    tabCacheObj[`tabCache${i}`] = i == 0 ? true : false;
                    break
                case "close":
                    tabCacheObj[`tabCache${i}`] = false;
                    break
            }
        }
        return tabCacheObj;
    }
}])
