import "./generalSrv";

var generalModule = angular.module("generalModule", ["generalSrv"]);
generalModule.controller("generalCtrl", ["$scope","$rootScope", "generalSrv", "$timeout", "alertSrv", "$translate", "modalCom", function($scope, $rootScope, generalSrv, $timeout,
    alertSrv, $translate, modalCom) {
    var self = $scope;
    self.immediateSyncCount=0;//控制立即同步按钮是否可以多次点击
    self.general = {
        curTable:"saas"
    };

    self.generalConf = {};
    self.generalConf_origin = {};
    self.canModifyConfSet_0 = true;
    self.canModifyConfSet_1 = true;
    self.canModifyConfSet_2 = true;
    self.canModifyConfSet_3 = true;
    self.showModifyConfSet_0 = false;
    self.showModifyConfSet_1 = false;
    self.showModifyConfSet_2 = false;
    self.showModifyConfSet_3 = false;
    self.submitInValid = false;
    self.submitInValid1 = false;
    self.submitInValid2 = false;
    self.novaRetry = false;
    self.cinderRetry = false;
    self.upsRetry = false;
    self.platformRetry = false;
    self.currentNodeCount = 0;
    self.reclaim_instance_interval_range = {
        min:1,
        max:30
    };
    self.nova_reserved_memory_size_range = {
        min:8,
        max:32
    };
    self.cpu_allocation_ratio_range = {
        min:1,
        max:16
    };
    self.block_device_allocate_retries_range = {
        min:30,
        max:360
    };
    self.block_device_allocate_retries_interval_range = {
        min:1,
        max:10
    };
    self.scheduler_max_attempts_range = {
        min:1,
        max:10
    };
    self.action_retries_range = {
        min:1,
        max:10
    };
    self.check_interval_range  = {
        min:1,
        max:10
    }
    self.faultSelfhealing_interval_range  = {
        min:1,
        max:10
    }
    self.timeList = [
        { 
            id: "300",
            name: $translate.instant("aws.general.time.5min")
        }, { 
            id: "600",
            name: $translate.instant("aws.general.time.10min")
        }, {
            id: "900",
            name: $translate.instant("aws.general.time.15min")
        }, {
            id: "1800",
            name: $translate.instant("aws.general.time.30min")
        }, {
            id: "3600",
            name: $translate.instant("aws.general.time.1hour")
        }, {
            id: "7200",
            name: $translate.instant("aws.general.time.2hour")
        }, {
            id: "10800",
            name: $translate.instant("aws.general.time.3hour")
        }, {
            id: "21600",
            name: $translate.instant("aws.general.time.6hour")
        }];
    self.upsTypeList = [
        { 
            id: "APC",
            name: $translate.instant("aws.general.ups.apc")
        }, { 
            id: "GCCALLIANCE",
            name: $translate.instant("aws.general.ups.gcc")
        }
    ];
    self.nodeCountList = [
        { 
            id: "1",
            name: "3-8"
        }, { 
            id: "2",
            name: "9-12"
        },{ 
            id: "3",
            name: "13-24"
        },{ 
            id: "4",
            name: "25-48"
        },{ 
            id: "5",
            name: "49-60"
        }
    ];//10 15 20 25 30 35 40 45 50 55 60 
    //同步定时任务可选择的时间
    self.timeTaskList=[
        { 
            id: "10",
            name: "10分钟"
        }, { 
            id: "15",
            name: "15分钟"
        },{ 
            id: "20",
            name: "20分钟"
        },{ 
            id: "25",
            name: "25分钟"
        },{ 
            id: "30",
            name: "30分钟"
        },{ 
            id: "35",
            name: "35分钟"
        },{ 
            id: "40",
            name: "40分钟"
        },{ 
            id: "45",
            name: "45分钟"
        },{ 
            id: "50",
            name: "50分钟"
        },{ 
            id: "55",
            name: "55分钟"
        },{ 
            id: "59",
            name: "60分钟"
        }
    ];
    self.dayList = [
        { 
            id: "10",
            name: $translate.instant("aws.general.day.10day")
        }, { 
            id: "30",
            name: $translate.instant("aws.general.day.30day")
        }, {
            id: "60",
            name: $translate.instant("aws.general.day.60day")
        }, {
            id: "90",
            name: $translate.instant("aws.general.day.90day")
        }];
    self.cpuAlarmTypes = [
        { 
            name: $translate.instant("aws.general.criticalAlarm"),
            value: "CPU_USAGE_ALARM_MAX_LEVEL",
            
        }, { 
            name: $translate.instant("aws.general.normalAlarm"),
            value: "CPU_USAGE_ALARM_MIN_LEVEL"
        }];
    self.memAlarmTypes = [
        { 
            name: $translate.instant("aws.general.criticalAlarm"),
            value: "MEM_USAGE_ALARM_MAX_LEVEL",
            
        }, { 
            name: $translate.instant("aws.general.normalAlarm"),
            value: "MEM_USAGE_ALARM_MIN_LEVEL"
        }];
    self.cephAlarmTypes = [
        { 
            name: $translate.instant("aws.general.criticalAlarm"),
            value: "CEPH_USAGE_ALARM_MAX_LEVEL",
            
        }, { 
            name: $translate.instant("aws.general.normalAlarm"),
            value: "CEPH_USAGE_ALARM_MIN_LEVEL"
        }];

    self.$on("clustereError",function(e,data){
        alertSrv.set("", $translate.instant("aws.statusCode."+data), "error", 5000);
    });


    self.$on("saasServiceSuccess", function(e, data) {
        getRegionConfigs();
        getConfStatus();
    });

    self.$on("novaServiceFail", function(e, data) {
        getConfStatus();
    });

    self.$on("cinderServiceFail", function(e, data) {
        getConfStatus();
    });

    self.$on("computehaServiceFail", function(e, data) {
        getConfStatus();
    });

    self.$on("upsServiceFail", function(e, data) {
        getConfStatus();
    });

    self.$on("platformServiceFail", function(e, data) {
        getConfStatus();
    });

    self.toSaas  = function(){
        self.general.curTable = "saas";
        saasConfInfo('777'); //获取初始化云管超时时间
        saasConfInfo('724'); //获取初始化配额
        saasConfInfo('923'); //获取初始化密码
    }

    self.toSaasSevice  = function(){
        self.general.curTable = "saasSevice";
        getRegionConfigs();
        getConfStatus();
        saasConfInfo('1111',localStorage.enterpriseUid,localStorage.regionUid); //获取云硬盘回收站过期时间
    }

    self.toCloneChain = function(){
        self.general.curTable = "cloneChain";
        getCloneChainInfo(); //获取克隆链设置参数
    }

    self.chkNodeCountScale =function(id){
        self.showScaleTip = false;
        if(self.generalConf_origin.platform_scale < id){
            self.showScaleTip = true;
        }
    }

    self.confSet = function(type){
        var region = localStorage.regionUid;
        var postData = {
            "confKey": "region",
            "regionKey": localStorage.regionKey,
            "setType": type, // nova相关参数配置修改
        };
        switch(type){
            case "0":
                if(!self.novaConfForm1.$valid || !self.novaConfForm2.$valid  
                    || !self.novaConfForm3.$valid || !self.novaConfForm4.$valid || !self.novaConfForm5.$valid){
                        self.submitInValid = true;
                        return;
                }
                postData.keyValuePairs = {
                    "reclaim_instance_interval": self.generalConf.reclaim_instance_interval*86400,
                    "nova_reserved_memory_size": self.generalConf.nova_reserved_memory_size*1024,
                    "cpu_allocation_ratio": self.generalConf.cpu_allocation_ratio,
                    "ram_allocation_ratio": self.generalConf.ram_allocation_ratio,
                    "block_device_allocate_retries_interval": self.generalConf.block_device_allocate_retries_interval,
                    "block_device_allocate_retries": self.generalConf.block_device_allocate_retries
                };
                break;
            case "1":
                if(!self.cinderConfForm1.$valid){
                    self.submitInValid1 = true;
                        return;
                }
                postData.keyValuePairs = {
                    "scheduler_max_attempts": self.generalConf.scheduler_max_attempts 
                };
                break;
            case "2":
                if(!self.confSetForm1.$valid || !self.confSetForm2.$valid || !self.confSetForm3.$valid){
                    self.submitInValid2 = true;
                    return;
                }
                postData.keyValuePairs = {
                    "action_retries": self.generalConf.action_retries,
                    "check_interval": self.generalConf.check_interval,
                    "ups_type": self.generalConf.ups_type
                };
                break;
            case "3":
                postData.keyValuePairs = {
                    "platform_scale": self.generalConf.platform_scale
                };
                break;

        }
        //任意一个模块在更新配置中时，其他模块也不能操作
        
        generalSrv.confVersion(postData,region).then(function(res){
            if(res && res.status == "0"){
                self["showModifyConfSet_"+type]=true;
                self["canModifyConfSet_0"]=false;
                self["canModifyConfSet_1"]=false;
                self["canModifyConfSet_2"]=false;
                self["canModifyConfSet_3"]=false;
                getRegionConfigs();
            }
        })
    };
    
    self.saasCofSetTimeTask=function(){
        if(!self.ENT_SYNC_TIMING_TASK.paramValue){
           self.timingTaskValueRequired=true;
           $timeout(function(){
              self.timingTaskValueRequired=false;
           },300);
           return;
        }
        var options={
          "scheduleJobId":self.ENT_SYNC_TIMING_TASK.scheduleJobId?self.ENT_SYNC_TIMING_TASK.scheduleJobId:null, //新增时不传递数值为null,修改时才传递
          "jobName":"syncResourcesDataJobName", //定值，写死
          "jobGroup":"syncResourcesDataJobGroup", //定值，写死
          "cron":self.ENT_SYNC_TIMING_TASK.paramValue //页面填写的时刻，字符类型。
        };
        generalSrv.setSyncTimingTask(options).then(function(){
            //保存之后要刷新下拉框中的值,有可能失败要刷新
            self.conf_timingTask_change=false;
            initTimingTask();
        });
    };
    
    //先获取平台是否进行过定时任务，没有则返回空数组
    function initTimingTask(){
        generalSrv.getSyncTimingTask().then(function(res){
            if(res&&res.data&&angular.isArray(res.data)&&res.data.length!=0){
               let result=res.data[0];
               self.ENT_SYNC_TIMING_TASK={
                   hasSetTimeTask:true,
                   scheduleJobId:result.scheduleJobId,
                   jobName:result.jobName,
                   jobGroup:result.jobGroup,
                   paramValue:result.cron
               };
               self.ENT_SYNC_TIMING_TASK_origin=angular.copy(self.ENT_SYNC_TIMING_TASK);
            }else{
               self.ENT_SYNC_TIMING_TASK_origin=""; 
            }
        });
    }
    //立即同步
    self.nowSetTimeTask=function(){
        self.immediateSyncCount++;
        generalSrv.nowSetTimeTask().then(function(res){
        }).finally(function(){
            $timeout(function(){
                self.immediateSyncCount=0;
            },3000);
        });
    };
    // 保存gpu
    self.gpuSet=function(){
        generalSrv.getPhyGpu().then(function(res){
        }).finally(function(){
            
        });
    };
    // 保存usb
    self.usbSet=function(){
        generalSrv.getPhyGpu().then(function(res){
        }).finally(function(){
            
        });
    };
    self.saasCofSet = function(type,form){
        if(form && form.$invalid) return;
        var option = {
            enterpriseUid: localStorage.enterpriseUid,
            regionUid: "0",
            paramId:self[type].paramId,
            paramName:type,
            paramValue:self[type].paramValue,
        }
        switch(type){
            case "ENT_EXPIRE_TOKEN":
                option.parentId = "777";
                break;
            case "ENT_QUOTA_CEHCK_CONFIG":
                option.parentId = "724";
                option.paramValue = Number(self[type].paramValue);
                break;
            case "ENT_PWD_EXPIRED_DAYS":
                option.parentId = "923";
                if(!self[type].switch){
                    option.paramValue = "-1";
                }
                break;
            case "ENT_PWD_FORCE_MODIFY":
                option.parentId = "923";
                option.paramValue = Number(self[type].paramValue);
                break;
            case "USB_REDIRECT_SWITCH": //usb重定向
                option.parentId = "1104";
                option.enterpriseUid = '0';
                option.paramValue = Number(self[type].paramValue);
                break;
            case "CONF_ARGUMENTS": // ceph硬盘故障自愈
                option.parentId = "995";
                option.regionUid = localStorage.regionUid;
                option.paramValue = JSON.stringify({switch:self[type].switch,maxBackFills:Number(self[type].maxBackFills)});
                break;
            case "cpuAlarmThreshold": 
                option.parentId = "1137";
                option.paramName = self[self[type].paramName].paramName;
                option.paramId = self[self[type].paramName].paramId;
                break;
            case "memAlarmThreshold": 
                option.parentId = "1137";
                option.paramName = self[self[type].paramName].paramName;
                option.paramId = self[self[type].paramName].paramId;
                break;
            case "cephAlarmThreshold": 
                option.parentId = "1137";
                option.paramName = self[self[type].paramName].paramName;
                option.paramId = self[self[type].paramName].paramId;
                break;
            case "LOGIN_CAPTCHA_ISOPEN":
                option.parentId = "6";
                option.enterpriseUid = '0';
                option.paramValue = !self[type].switch ? '-1' : '0';
                break;
            case "LOGIN_EMAIL_ISOPEN":
                option.parentId = "6";
                option.enterpriseUid = '0';
                option.paramValue = !self[type].switch ? '-1' : '0';
                break;
            // case "LOGIN_CAS_ISOPEN":
            //     option.parentId = "6";
            //     option.enterpriseUid = '0';
            //     option.paramValue = !self[type].switch ? '-1' : '0';
            //     break;
            case "LBASS_HIGH_DISPOSE":
                option.parentId = "1124";
                // option.enterpriseUid = '0';
                option.paramValue = !self[type].switch ? '-1' : '0';
                option.regionUid = localStorage.regionUid;

                break
            case "DELETE_OVERDUE_VOLUMES_TIME":
                option.parentId = "1111";
                option.regionUid = localStorage.regionUid;
                option.paramName = self[self[type].paramName].paramName;
                option.paramId = self[self[type].paramName].paramId;
                break;
        }
        generalSrv.saasCofSet(option).then(function(res) {
            if(res && res.status == "0") {
                switch(type){
                    case "ENT_EXPIRE_TOKEN":
                        self.conf_token_change = false;
                        saasConfInfo('777');
                        break;
                    case "ENT_QUOTA_CEHCK_CONFIG":
                        saasConfInfo('724');
                        self.conf_quota_change = false;
                        break;
                    case "ENT_PWD_FORCE_MODIFY":
                        self.conf_pwdModify_change= false
                        saasConfInfo('923');
                        break;
                    case "ENT_PWD_EXPIRED_DAYS":
                        self.conf_pwdExpired_change  = false
                        saasConfInfo('923');
                        break;
                    case "USB_REDIRECT_SWITCH":
                        self.conf_usb_change  = false
                        saasConfInfo('1104','0');
                        break;
                    case "CONF_ARGUMENTS":
                        self.conf_fault_change  = false
                        cephDiskFault();
                        break;
                    case "cpuAlarmThreshold":
                        self.conf_cpuAlarm_change  = false
                        saasConfInfo('1137');
                        break;
                    case "memAlarmThreshold":
                        self.conf_memAlarm_change  = false
                        saasConfInfo('1137');
                        break;
                    case "cephAlarmThreshold":
                        self.conf_cephAlarm_change  = false
                        saasConfInfo('1137');
                        break;
                    case "LOGIN_CAPTCHA_ISOPEN":
                        self.conf_VerificationCode_change  = false
                        saasConfInfo('6','0');
                        break;
                    case "LOGIN_EMAIL_ISOPEN":
                        self.conf_email_change  = false
                        saasConfInfo('6','0');
                        break;
                    // case "LOGIN_CAS_ISOPEN":
                    //     self.conf_cas_change  = false
                    //     saasConfInfo('6','0');
                    //     break;
                    case "LBASS_HIGH_DISPOSE":
                        self.conf_nginx_change  = false
                        saasConfInfo('1124','',localStorage.regionUid);
                        break; 
                    case "DELETE_OVERDUE_VOLUMES_TIME":
                        saasConfInfo('1111',localStorage.enterpriseUid,localStorage.regionUid);
                        break;
                }
            }
        })

    };

    function getConfStatus(){
        self.canModifyConfSet_0 = true;  //当有其他模块更新时不让操作
        self.canModifyConfSet_1 = true;
        self.canModifyConfSet_2 = true;
        self.canModifyConfSet_3 = true;
        self.novaRetry = false;   //按钮是否重试
        self.cinderRetry = false;
        self.upsRetry = false;
        self.platformRetry = false;
        self.showModifyConfSet_0 = false;  //隐藏更新配置按钮
        self.showModifyConfSet_1 = false;
        self.showModifyConfSet_2 = false;
        self.showModifyConfSet_3 = false;
        self.conf_nova_change = false;     //页面是否更改
        self.conf_cinder_change = false; 
        self.conf_ups_change = false;
        self.conf_platform_change = false;
        generalSrv.getConfStatus().then(function(res){
            if(res && res.data && angular.isArray(res.data)){
                res.data.map(item => {     
                    if(item && item.indexOf("config.Update.nova.fail")>-1){
                        self.novaRetry = true;         //如果有失败的，要求重试；
                        self.conf_nova_change = true;  //重试的时候不进行是否更改的校验
                        self.canModifyConfSet_1 = false;
                        self.canModifyConfSet_2 = false;
                        self.canModifyConfSet_3 = false;
                    }else if(item && item.indexOf("config.Update.cinder.fail")>-1){
                        self.cinderRetry = true;
                        self.conf_cinder_change = true;
                        self.canModifyConfSet_0 = false;
                        self.canModifyConfSet_2 = false;
                        self.canModifyConfSet_3 = false;
                    }else if(item && item.indexOf("config.Update.computeha.fail")>-1){
                        self.upsRetry = true;
                        self.conf_ups_change = true;
                        self.canModifyConfSet_0 = false;
                        self.canModifyConfSet_1 = false;
                        self.canModifyConfSet_3 = false;
                    }else if(item && item.indexOf("config.Update.ups.fail")>-1){
                        self.upsRetry = true;
                        self.conf_ups_change = true;
                        self.canModifyConfSet_0 = false;
                        self.canModifyConfSet_1 = false;
                        self.canModifyConfSet_3 = false;
                    }
                    else if(item && item.indexOf("config.Update.platform.fail")>-1){
                        self.platformRetry = true;
                        self.conf_platform_change = true;
                        self.canModifyConfSet_0 = false;
                        self.canModifyConfSet_1 = false;
                        self.canModifyConfSet_2 = false;
                    }
                    if(item && item == "config.Update.nova.ing" ){
                        self.showModifyConfSet_0 = true;
                    }else if(item && item == "config.Update.cinder.ing" ){
                        self.showModifyConfSet_1 = true;
                    }else if(item && item == "config.Update.computeha.ing"){
                        self.showModifyConfSet_2 = true;
                    }else if(item && item == "config.Update.ups.ing" ){
                        self.showModifyConfSet_2 = true;
                    }else if(item && item == "config.Update.platform.ing" ){
                        self.showModifyConfSet_3 = true;
                    }
                    if(item && item.indexOf('ing')>-1){     //如果有更新配置，页面不让操作
                        self.canModifyConfSet_0 = false;
                        self.canModifyConfSet_1 = false;
                        self.canModifyConfSet_2 = false;
                        self.canModifyConfSet_3 = false;
    
                    }
                })
            }
        })
    }

    function getRegionConfigs() {
        generalSrv.getRegionConfigs().then(function(res) {
            if(res && res.data && angular.isArray(res.data)) {
                self.regionList = res.data;
                self.regionList.forEach(function(item, index) {
                    if(item.regionKey == localStorage.regionKey) {
                        self.generalConf = item.regionConfigScriptMap;
                        self.generalConf.nova_reserved_memory_size = self.generalConf.nova_reserved_memory_size/1024;
                        self.generalConf.reclaim_instance_interval = self.generalConf.reclaim_instance_interval/86400;
                        self.generalConf_origin = angular.copy(self.generalConf);
                        if(item.regionConfigScriptMap && item.regionConfigScriptMap.registered_hosts){
                            self.currentNodeCount = item.regionConfigScriptMap.registered_hosts.length;
                        }
                    }
                });
            }
        });
    };

    function generalConf_nova(type){
        return [self[type].reclaim_instance_interval,
            self[type].nova_reserved_memory_size,
            self[type].cpu_allocation_ratio,
            self[type].ram_allocation_ratio,
            self[type].block_device_allocate_retries_interval,
            self[type].block_device_allocate_retries].join("&");
    }
    function generalConf_cinder(type){
        return self[type].scheduler_max_attempts;
    }
    function generalConf_ups(type){
        return [self[type].action_retries,
            self[type].check_interval,
            self[type].ups_type].join("&");
    }
    function generalConf_platform(type){
        return self[type].platform_scale ;
    }
    
    function saasConfInfo(parentId,enterpriseUid,regionUid) {
        generalSrv.saasConfInfo(parentId,enterpriseUid,regionUid).then(function(res) {
            if(res && res.data && res.data.length) {
                _.forEach(res.data, function(item) {
                    if(item.paramName){
                        self[item.paramName] = item;
                        if(item.paramName=="ENT_PWD_EXPIRED_DAYS" || item.paramName=="LOGIN_CAPTCHA_ISOPEN" || item.paramName=="LOGIN_EMAIL_ISOPEN" || item.paramName=="LBASS_HIGH_DISPOSE"){//|| item.paramName=="LOGIN_CAS_ISOPEN"
                            item.switch = item.paramValue=="-1" ? false : true;
                            self[item.paramName].switch = item.switch;
                        }else if(item.paramName == 'CPU_USAGE_ALARM_MAX_LEVEL' || item.paramName == 'CPU_USAGE_ALARM_MIN_LEVEL' ||
                            item.paramName == 'MEM_USAGE_ALARM_MAX_LEVEL' || item.paramName == 'MEM_USAGE_ALARM_MIN_LEVEL' ||
                            item.paramName == 'CEPH_USAGE_ALARM_MAX_LEVEL' || item.paramName == 'CEPH_USAGE_ALARM_MIN_LEVEL'){
                            self[item.paramName].updateTime = Number(item.updateTime);
                        }else if(item.paramName != 'ENT_EXPIRE_TOKEN' && item.paramName != 'DELETE_OVERDUE_VOLUMES_TIME'){
                            self[item.paramName].paramValue = item.paramValue=="0"?false:true;
                        }else if(item.paramName == 'DELETE_OVERDUE_VOLUMES_TIME') {
                            self[item.paramName].paramValue = item.paramValue;
                            self.conf_cinder_cs_recycle_change = false;
                        }
                        self[item.paramName+"_origin"] = angular.copy(item);
                    }
                });
                if(self.CPU_USAGE_ALARM_MAX_LEVEL && self.CPU_USAGE_ALARM_MIN_LEVEL && self.CPU_USAGE_ALARM_MAX_LEVEL.paramName && self.CPU_USAGE_ALARM_MIN_LEVEL.paramName){
                    self.cpuAlarmThreshold = self.CPU_USAGE_ALARM_MAX_LEVEL.updateTime > self.CPU_USAGE_ALARM_MIN_LEVEL.updateTime ? angular.copy(self.CPU_USAGE_ALARM_MAX_LEVEL) : angular.copy(self.CPU_USAGE_ALARM_MIN_LEVEL);
                    self.cpuAlarmThreshold_origin = angular.copy(self.cpuAlarmThreshold);
                }
                if(self.MEM_USAGE_ALARM_MAX_LEVEL && self.MEM_USAGE_ALARM_MIN_LEVEL && self.MEM_USAGE_ALARM_MAX_LEVEL.paramName && self.MEM_USAGE_ALARM_MIN_LEVEL.paramName){
                    self.memAlarmThreshold = self.MEM_USAGE_ALARM_MAX_LEVEL.updateTime > self.MEM_USAGE_ALARM_MIN_LEVEL.updateTime ? angular.copy(self.MEM_USAGE_ALARM_MAX_LEVEL) : angular.copy(self.MEM_USAGE_ALARM_MIN_LEVEL);
                    self.memAlarmThreshold_origin = angular.copy(self.memAlarmThreshold);
                }
                if(self.CEPH_USAGE_ALARM_MAX_LEVEL && self.CEPH_USAGE_ALARM_MIN_LEVEL && self.CEPH_USAGE_ALARM_MAX_LEVEL.paramName && self.CEPH_USAGE_ALARM_MIN_LEVEL.paramName){
                    self.cephAlarmThreshold = self.CEPH_USAGE_ALARM_MAX_LEVEL.updateTime > self.CEPH_USAGE_ALARM_MIN_LEVEL.updateTime ? angular.copy(self.CEPH_USAGE_ALARM_MAX_LEVEL) : angular.copy(self.CEPH_USAGE_ALARM_MIN_LEVEL);
                    self.cephAlarmThreshold_origin = angular.copy(self.cephAlarmThreshold);
                }
                if(self.LBASS_HIGH_DISPOSE){
                    // 更新F5状态
                    $rootScope.isOpenF5 = self.LBASS_HIGH_DISPOSE.paramValue == 0
                }
            }
        });
    }
    function cephDiskFault(){
        generalSrv.cephDiskFault().then(function(res){
            if(res && res.data && res.data.length){
                const paramValue = JSON.parse(res.data[0].paramValue);
                self.CONF_ARGUMENTS = Object.assign({},res.data[0],paramValue);
                self.CONF_ARGUMENTS_origin = angular.copy(self.CONF_ARGUMENTS);
            }
        })
    }
    self.ENT_EXPIRE_TOKEN = {}; //系统超时时间
    self.ENT_QUOTA_CEHCK_CONFIG = {};//配额配置开关
    self.ENT_PWD_FORCE_MODIFY = {};//新用户是否强制修改密码
    self.ENT_PWD_EXPIRED_DAYS = {};//是否启用用户密码超时
    self.ENT_SYNC_TIMING_TASK = {};//同步定时任务
    self.USB_REDIRECT_SWITCH = {};//usb重定向
    self.CONF_ARGUMENTS = {};//ceph硬盘故障自愈
    self.GPU = {};//同步gpu;
    self.cpuAlarmThreshold = {};
    self.memAlarmThreshold = {};
    self.cephAlarmThreshold = {};
    self.LOGIN_CAPTCHA_ISOPEN = {};//是否启用验证码
    self.LOGIN_EMAIL_ISOPEN = {};//是否启用邮箱登录
    //self.LOGIN_CAS_ISOPEN = {};//是否启用CAS登录
    self.LBASS_HIGH_DISPOSE = {};//是否启用高级负载均衡
    self.LOAD_BALANCING = {};       // 负载均衡

    //监听每个模块的值是否改变，如果没有改变，不能点击保存。
    self.$watch(function(){
        return self.ENT_EXPIRE_TOKEN.paramValue
    },function(value){
        if(self.ENT_EXPIRE_TOKEN_origin){
            if(value == self.ENT_EXPIRE_TOKEN_origin.paramValue ){
                self.conf_token_change = false
            }else{
                self.conf_token_change = true
            }
        }
    })
    self.$watch(function(){
        return self.GPU.paramValue
    },function(value){
        if(value != undefined){
            if(value){
                self.conf_gpu_change = true;
            }else{
                self.conf_gpu_change = false;
            }
            
        }else{
           self.conf_gpu_change = false; 
        }
    })
    self.$watch(function(){
        return self.USB_REDIRECT_SWITCH.paramValue
    },function(value){
        if(self.USB_REDIRECT_SWITCH_origin){
            if(value == self.USB_REDIRECT_SWITCH_origin.paramValue ){
                self.conf_usb_change = false
            }else{
                self.conf_usb_change = true
            }
        }
    })
    self.$watch(function(){
        return self.CONF_ARGUMENTS.switch
    },function(value){
        if(self.CONF_ARGUMENTS_origin){
            if(value == self.CONF_ARGUMENTS_origin.switch && self.CONF_ARGUMENTS.maxBackFills == self.CONF_ARGUMENTS_origin.maxBackFills){
                self.conf_fault_change = false
            }else{
                self.conf_fault_change = true
            }
        }
    })
    self.$watch(function(){
        return self.CONF_ARGUMENTS.maxBackFills
    },function(value){
        if(self.CONF_ARGUMENTS_origin){
            if(value == self.CONF_ARGUMENTS_origin.maxBackFills){
                self.conf_fault_change = false
            }else{
                self.conf_fault_change = true
            }
        }
    })
    self.$watch(function(){
        return self.ENT_QUOTA_CEHCK_CONFIG.paramValue
    },function(value){
        if(self.ENT_QUOTA_CEHCK_CONFIG_origin){
            if(value == self.ENT_QUOTA_CEHCK_CONFIG_origin.paramValue ){
                self.conf_quota_change = false
            }else{
                self.conf_quota_change = true
            }
        }
    })

    self.$watch(function(){
        return self.ENT_PWD_FORCE_MODIFY.paramValue
    },function(value){
        if(self.ENT_PWD_FORCE_MODIFY_origin){
            if(value== self.ENT_PWD_FORCE_MODIFY_origin.paramValue ){
                self.conf_pwdModify_change = false
            }else{
                self.conf_pwdModify_change = true
            }
        }
    })

    self.$watch(function(){
        return self.ENT_PWD_EXPIRED_DAYS.switch
    },function(value){
        if(self.ENT_PWD_EXPIRED_DAYS_origin){
            if(self.ENT_PWD_EXPIRED_DAYS_origin.paramValue == '-1'){
                if(self.ENT_PWD_EXPIRED_DAYS.switch){
                    self.ENT_PWD_EXPIRED_DAYS.paramValue = self.dayList[0].id;
                }else{
                    self.ENT_PWD_EXPIRED_DAYS.paramValue = '-1';
                }
            }
            const val = self.ENT_PWD_EXPIRED_DAYS_origin.paramValue == '-1' ? false : true;
            if(value == val && self.ENT_PWD_EXPIRED_DAYS.paramValue == self.ENT_PWD_EXPIRED_DAYS_origin.paramValue){
                self.conf_pwdExpired_change = false
            }else{
                self.conf_pwdExpired_change = true
            }
        }
    })

    self.$watch(function(){
        return self.ENT_PWD_EXPIRED_DAYS.paramValue
    },function(value){
        if(self.ENT_PWD_EXPIRED_DAYS_origin && self.ENT_PWD_EXPIRED_DAYS_origin.paramValue!='-1'){
            if(value == self.ENT_PWD_EXPIRED_DAYS_origin.paramValue ){
                self.conf_pwdExpired_change = false
            }else{
                self.conf_pwdExpired_change = true
            }
        }
    })

    self.$watch(function(){
        return self.ENT_SYNC_TIMING_TASK
    },function(timingTask){
        //还没有进行过定时任务
        if(!timingTask.scheduleJobId){
            self.conf_timingTask_change = true; 
        }else{
            if(self.ENT_SYNC_TIMING_TASK_origin){
                if(timingTask.paramValue== self.ENT_SYNC_TIMING_TASK_origin.paramValue ){
                    self.conf_timingTask_change = false
                }else{
                    self.conf_timingTask_change = true
                }
            }
        }
    },true)


    self.$watch(function(){
        return [self['generalConf'].reclaim_instance_interval,
        self['generalConf'].nova_reserved_memory_size,
        self['generalConf'].cpu_allocation_ratio,
        self['generalConf'].ram_allocation_ratio,
        self['generalConf'].block_device_allocate_retries_interval,
        self['generalConf'].block_device_allocate_retries].join("&");
    },function(value){
        if(value){
            if(value == generalConf_nova('generalConf_origin') && !self.novaRetry){
                self.conf_nova_change = false;
            }else {
                self.conf_nova_change = true;
            }
        }

    })

    self.$watch(function(){
        if(self['DELETE_OVERDUE_VOLUMES_TIME']) {
            self.deleteOverdueVolumesTime = self['DELETE_OVERDUE_VOLUMES_TIME'].paramValue;
            return self.deleteOverdueVolumesTime;
        }
    },function(value){
        if(value){
            if(value === self.DELETE_OVERDUE_VOLUMES_TIME_origin.paramValue){
                self.conf_cinder_cs_recycle_change = false;
            }else {
                self.conf_cinder_cs_recycle_change = true;
            }
        }
    })

    self.$watch(function(){
        return self['generalConf'].scheduler_max_attempts;
    },function(value){
        if(value){
            if(value == generalConf_cinder('generalConf_origin') && !self.cinderRetry){
                self.conf_cinder_change = false;
            }else {
                self.conf_cinder_change = true;
            }
        }
    })

    self.$watch(function(){
        return [self['generalConf'].action_retries,
        self['generalConf'].check_interval,
        self['generalConf'].ups_type].join("&");
    },function(value){
        if(value){
            if(value == generalConf_ups('generalConf_origin') && !self.upsRetry){
                self.conf_ups_change = false;
            }else {
                self.conf_ups_change = true;
            }
        }

    });

    self.$watch(function(){
        return self['generalConf'].platform_scale;
    },function(value){
        if(value){
            if(value == generalConf_platform('generalConf_origin') && !self.platformRetry){
                self.conf_platform_change = false;
            }else {
                self.conf_platform_change = true;
            }
        }
    })

    self.$watch(function(){
        return self.cpuAlarmThreshold.paramValue
    },function(value){
        if(self.cpuAlarmThreshold_origin){
            if(value == self[self.cpuAlarmThreshold.paramName].paramValue && self.cpuAlarmThreshold.paramName == self[self.cpuAlarmThreshold.paramName].paramName){
                self.conf_cpuAlarm_change = false
            }else{
                self.conf_cpuAlarm_change = true
            }
        }
    })

    self.$watch(function(){
        return self.memAlarmThreshold.paramValue
    },function(value){
        if(self.memAlarmThreshold_origin){
            if(value == self[self.memAlarmThreshold.paramName].paramValue && self.memAlarmThreshold.paramName == self[self.memAlarmThreshold.paramName].paramName){
                self.conf_memAlarm_change = false
            }else{
                self.conf_memAlarm_change = true
            }
        }
    })

    self.$watch(function(){
        return self.cephAlarmThreshold.paramValue
    },function(value){
        if(self.cephAlarmThreshold_origin){
            if(value == self[self.cephAlarmThreshold.paramName].paramValue && self.cephAlarmThreshold.paramName == self[self.cephAlarmThreshold.paramName].paramName){
                self.conf_cephAlarm_change = false
            }else{
                self.conf_cephAlarm_change = true
            }
        }
    })

    self.$watch(function(){
        return self.LOGIN_CAPTCHA_ISOPEN.switch
    },function(value){
        if(self.LOGIN_CAPTCHA_ISOPEN_origin){
            if(value == self.LOGIN_CAPTCHA_ISOPEN_origin.switch){
                self.conf_VerificationCode_change = false
            }else{
                self.conf_VerificationCode_change = true
            }
        }
    })

    self.$watch(function(){
        return self.LOGIN_EMAIL_ISOPEN.switch
    },function(value){
        if(self.LOGIN_EMAIL_ISOPEN_origin){
            if(value == self.LOGIN_EMAIL_ISOPEN_origin.switch){
                self.conf_email_change = false
            }else{
                self.conf_email_change = true
            }
        }
    })

    // self.$watch(function(){
    //     return self.LOGIN_CAS_ISOPEN.switch
    // },function(value){
    //     if(self.LOGIN_CAS_ISOPEN_origin){
    //         if(value == self.LOGIN_CAS_ISOPEN_origin.switch){
    //             self.conf_cas_change = false
    //         }else{
    //             self.conf_cas_change = true
    //         }
    //     }
    // })

    self.$watch(function(){
        return self.LBASS_HIGH_DISPOSE.switch
    },function(value){
        if(self.LBASS_HIGH_DISPOSE_origin){
            if(value == self.LBASS_HIGH_DISPOSE_origin.switch){
                self.conf_nginx_change = false
            }else{
                self.conf_nginx_change = true
            }
        }
    })

    self.cpuAlarmTypeChange = function(val) {
        self.cpuAlarmThreshold.paramValue = self[val].paramValue;
    }

    self.memAlarmTypeChange = function(val) {
        self.memAlarmThreshold.paramValue = self[val].paramValue;
    }

    self.cephAlarmTypeChange = function(val) {
        self.cephAlarmThreshold.paramValue = self[val].paramValue;
    }

    //克隆链优化
    self.cycleActive = 2;
    self.cycleList = [
        {
            id:1,
            name:$translate.instant("aws.general.everyday")
        },
        {
            id:2,
            name:$translate.instant("aws.general.everyWeek")
        }
    ]
    self.timeInfo = {
        point: $translate.instant("aws.general.point"),
        minute: $translate.instant("aws.general.cloneChainMinute")
    }
    self.hourList = []
    self.minuteList = []
    self.weekList =[]
    self.dayHourInfo ={
        hourActive: "00",
        minuteActive: "00"
    }
    self.dayWeekInfo = {
        weekActive: 0,
        hourActive: "00",
        minuteActive: "00"
    }
    self.week = [
        $translate.instant("aws.general.sunday"),
        $translate.instant("aws.general.monday"),
        $translate.instant("aws.general.tuesday"),
        $translate.instant("aws.general.wednesday"),
        $translate.instant("aws.general.thursday"),
        $translate.instant("aws.general.friday"),
        $translate.instant("aws.general.saturday")
    ];
    self.dayHourShow = false;
    self.dayWeekShow = false;
    self.showTime = {
        dayOrWeek: "",
        hourAndMinute: ""
    }
    self.cloneChainSetForm = {
        status:false,
        frequence:"0 0 * * 0",
        numbers:50,
    }
    self.showModifyCloneChainSet = false;

    self.$on("flattenServiceing", function(e, data) {
        getCloneChainInfo();
    });

    self.$on("flattenServicesuccess",function(e, data) {
        getCloneChainInfo();
    })

    self.$on("flattenServicefailed",function(e,data) {
        getCloneChainInfo();
    })

    self.changeChainSwitchStatus = function(value) {
        // 开关关闭时获取初始值
        if(value) {
            getCloneChainInfo()
            
        }else{
            getInitInfo();
        }
    }

    self.changeActive = function(value,isClick) {
        // if(!self.cloneChainSetForm.status) return;
        self.cycleActive = value;
        if(value == 1) {
            if(isClick) {
                self.dayHourShow = true;
                self.dayWeekShow = false;
            }
            self.showTime.dayOrWeek = $translate.instant("aws.general.everyday");
            self.showTime.hourAndMinute = `${self.dayHourInfo.hourActive}${self.timeInfo.point}${self.dayHourInfo.minuteActive}${self.timeInfo.minute}`;
        }else {
            if(isClick) {
                self.dayWeekShow = true;
                self.dayHourShow = false;
            }
            self.dayWeekInfo.weekActive = self.dayWeekInfo.weekActive == '' ? 0 : self.dayWeekInfo.weekActive;
            self.showTime.dayOrWeek = $translate.instant("aws.general.everyWeek");
            self.showTime.hourAndMinute = `${self.week[self.dayWeekInfo.weekActive]}${self.dayWeekInfo.hourActive}${self.timeInfo.point}${self.dayWeekInfo.minuteActive}${self.timeInfo.minute}`;
        }
        getDom("#chain-day-hour");
        getDom("#chain-day-minute");
        getDom('#chain-week');
        getDom("#chain-week-hour");
        getDom("#chain-week-minute");
    }

    self.mouseleaveDayHour = function() {
       self.changeActive(self.cycleActive, false);
       self.dayHourShow = false;
    }

    self.mouseleaveDayWeek = function() {
        self.changeActive(self.cycleActive, false);
        self.dayWeekShow = false;
    }

    self.clickDaytimeHour = function(value) {
        self.dayHourInfo.hourActive = value;
    }

    self.clickDaytimeMinute = function(value) {
        self.dayHourInfo.minuteActive = value;
    }
    
    self.clickDayWeek = function(value) {
        self.dayWeekInfo.weekActive = value;
    }

    self.clickDayWeekHour = function(value) {
        self.dayWeekInfo.hourActive = value;
    } 

    self.clickDayWeekMinute = function(value) {
        self.dayWeekInfo.minuteActive = value;
    }

    self.confirmCloneChain = function(cloneChainForm) {
        if(cloneChainForm.$valid) {
            self.cloneChainInValid = false;
            let status = self.cloneChainSetForm.status == true ? 'on' : 'off';
            let frequence = dealFrequence();
            // "20 14 * * *"    每天的14:20    （20--分   14--时  *--天  *--月  *--星期）
            // "20 14 * * 6"    每周周六的14:20
            let data = {
                frequence: frequence, 
                numbers: self.cloneChainSetForm.numbers, 
                status: status
            }
            generalSrv.cloneChainSet(data).then(function(res){
                if(res.code == '1') {
                    getCloneChainInfo();
                    self.cloneChainInValid = true;
                }else {
                    getInitInfo();
                    self.cloneChainSetForm.status = false;
                }
            })
        }
    }

    function getInitInfo() {
        self.cycleActive = 2;
        self.showTime.dayOrWeek = $translate.instant("aws.general.everyWeek");
        let frequence = self.cloneChainSetForm.frequence.split(" ");
        self.cloneChainSetForm.numbers = 50;
        self.dayWeekInfo.minuteActive = getAddZero(frequence[0]);
        self.dayWeekInfo.hourActive = getAddZero(frequence[1]);
        self.dayWeekInfo.weekActive = frequence[4];
        self.showTime.hourAndMinute = `${self.week[self.dayWeekInfo.weekActive]}${self.dayWeekInfo.hourActive}${self.timeInfo.point}${self.dayWeekInfo.minuteActive}${self.timeInfo.minute}`;
    }

    function getCloneChainInfo() {
        generalSrv.getCloneChainInfo().then(function(res){
            let frequence = "";
            if(res && res.data) {
                // 对接了ceph后取接口返回数据
                // res.data.frequence  "0 15 * * *"
                frequence = res.data.frequence.split(" ");
                self.cloneChainSetForm.numbers = res.data.numbers;
                if(!self.cloneChainSetForm.status) {
                    self.cloneChainSetForm.status = res.data.status == "on" ? true : false;
                }
            }else{
                // 未对接ceph默认回显前端设置的初始值  默认值"0 0 * * 0" 每周周日00点00分
                frequence = self.cloneChainSetForm.frequence.split(" ");
                self.cloneChainSetForm.numbers = 50;
            }
            // frequence最后一位为* 就为每天执行  为0-6则为每周
            if(frequence[4] == "*") {
                self.cycleActive = 1;
                self.dayHourInfo.minuteActive = getAddZero(frequence[0]);
                self.dayHourInfo.hourActive = getAddZero(frequence[1]);
                self.dayWeekInfo.weekActive = '';
            }else {
                self.cycleActive = 2;
                self.dayWeekInfo.minuteActive = getAddZero(frequence[0]);
                self.dayWeekInfo.hourActive = getAddZero(frequence[1]);
                self.dayWeekInfo.weekActive = frequence[4];
            }
            self.changeActive(self.cycleActive, false);

            if(res && res.data && res.data.taskStatus) {
                // 2 已下发，3 已接收，4 执行中，5 执行完成，6 执行失败.
                switch(res.data.taskStatus) {
                    case 2:
                    case 3:
                    case 4:
                        self.showModifyCloneChainSet = true;
                        break;
                    case 5:
                    case 6:
                        self.showModifyCloneChainSet = false;
                        break;
                }  
            }
        })
    }

    function dealFrequence() {
        let month = '*';
        let day = '';
        let week = '';
        let hour = '';
        let minute = '';
        if(self.cycleActive == 1) {
            week = "*";
            day = "*";
            hour = getHourMinuteInfo(self.dayHourInfo.hourActive); 
            minute = getHourMinuteInfo(self.dayHourInfo.minuteActive);
        }else {
            week = String(self.dayWeekInfo.weekActive);
            day = "*";
            hour = getHourMinuteInfo(self.dayWeekInfo.hourActive);
            minute = getHourMinuteInfo(self.dayWeekInfo.minuteActive);
        }
        self.cloneChainForm.frequence = `${minute} ${hour} ${day} ${month} ${week}`
        return self.cloneChainForm.frequence;
    }

    function getHourMinuteInfo(value) {
        let hourMinute =  value.indexOf(0) == 0 ? value.substring(1) : value;
        return hourMinute;
    }
    
    function getDayTime(number,type) {
        for (let index = 0; index < number; index++) {
            index = getAddZero(index);
            if(type=='hour') {
                self.hourList.push(index);
            }else {
                self.minuteList.push(index);
            }
        }
    }

    function getAddZero(value) {
        return value = value < 10 ? '0' + String(value) : String(value);
    }

    function getWeek() {
        for (let index = 0; index < self.week.length; index++) {
            self.weekList.push({ key:self.week[index], value: index});
        }
    }

    function getDom(ele) {
        // 延迟加载获取dom节点
        setTimeout(function(){
            //获得在当前选中的li前面的节点的高度，然后减去ul高度的一半设置给ul的scrollTop，基本上可以把滚动条设置在中间位置。
            var ul_module = $(ele);
            var ul_height = ul_module.height();
            var seleted_li = ul_module.find('.active');
            if(seleted_li.length) {
                var height = seleted_li.height();
                var prevCount = seleted_li.prevAll().length;
                ul_module.scrollTop(height * prevCount - ul_height/2);
            }
        },0)
        
    }

    getDayTime(24,'hour');
    getDayTime(60,'minute');
    getWeek();

    saasConfInfo('777'); //初始化云管超时时间
    saasConfInfo('724'); //初始化云管超时时间
    saasConfInfo('923'); //初始化云管超时时间
    saasConfInfo('1104','0');//usb重定向
    saasConfInfo('1137'); // 资源监控设置
    saasConfInfo('6','0'); // 启用验证码，邮箱登录
    cephDiskFault(); //ceph硬盘故障自愈
    initTimingTask();    //获取云管进行定时数据同步的时间

    saasConfInfo('1124','',localStorage.regionUid); //高级负载均衡

}])
