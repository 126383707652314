filterSelectGroup.$inject = [];
function filterSelectGroup () {
    return {
        restrict: "E",
        scope: {
            options:"=",
            queryLimit:"=",
        },
        templateUrl:'js/system/billingManagement/tmpl/filter-select-group.html',
        link:function(scope, elem, attr,ngModel){

        }
    }
}

export { filterSelectGroup }