var tableService = angular.module("cloudSrv", []);
tableService.service("cloudSrv", function($rootScope, $http) {
    var static_url = "/awstack-user/v1/enterprises/";
    var static_dict_url = "/awstack-user/v1/";
    return {
        getClusterTableData: function() {
            return $http({
                method: "get",
                url: static_url + localStorage.enterpriseUid + "/regions"
            });
        },
        createRegion: function(options) {
            return $http({
                method: "post",
                url: static_url + localStorage.enterpriseUid + "/regions",
                data: options
            });
        },
        // getAllDict:function(){
        //  return $http({
        //      method :"get",
        //      url:static_dict_url
        //  })
        // },

        getDictData: function(options) {
            return $http({
                method: "get",
                url: static_dict_url + "dictvalues/" + options + "/datas"
            });
        },



        //查找某企业下的所有公有云配置 dict_value 固定为3
        getDictDataByEidAndDid: function() {
            return $http({
                method: "get",
                url: static_dict_url + "params",
                params:{
                    parentId:3,
                    enterpriseUid:localStorage.enterpriseUid,
                    regionUid:angular.fromJson(localStorage.$LOGINDATA).regionUid
                }

            });
        },
        addPublicCloud: function(cloudname, data) {
            return $http({
                method: "post",
                url: static_dict_url + "params/" + cloudname,
                data: data
            });
        },
        updateCloud: function(options) {
            return $http({
                method: "put",
                url: static_dict_url + "params" ,
                data: options
            });
        },
        updateEnterpriseSupportPublicClouds: function(options) {
            return $http({
                method: "put",
                url: static_url + localStorage.enterpriseUid + "/clouds/",
                data: options
            });
        },
        getSSLThumbprint: function(options) {
            return $http({
                method: "GET",
                url: "/awstack-vmware/v1/sslThumbprint",
                params: options
            })
        },
        //获取阿里云所有域
        getAliRegion:function(options) {
            return $http({
                method: "GET",
                url: "/awstack-aliyun/v2/common/region/ali/list",
                params: options

            })
        },
        //获取数据库所有域
        getLocalRegion:function(options) {
            return $http({
                method: "GET",
                url: "/awstack-aliyun/v2/common/region/list",
                params: options

            })
        },
        //同步数据库域
        changeLocalRegion:function(options) {
            return $http({
                method: "POST",
                url: "/awstack-aliyun/v2/common/region/list/update",
                data: options

            })
        },
        //获取数据校验一下有效性
        verify:function(options) {
           
            return $http({
                method: "GET",
                url: "/awstack-aliyun/v2/instances/new_verify",
                params: options

            })
        },
        //验证aws是否可用
        verifyAWS:function(options) {
            return $http({
                method: "POST",
                url: "/awstack-aws/v2/regions/authenticate",
                data: options
            })
        },
         //查找某企业下的所有公有云配置通过固定region
        getDictDataByKey: function(key) {
            return $http({
                method: "get",
                url: static_dict_url + "params",
                params:{
                    parentId:3,
                    enterpriseUid:localStorage.enterpriseUid,
                    regionUid:key
                }

            });
        },
 
    };
});
