cloudDatabaseCtrl.$inject = ["$rootScope","$scope","$translate","$filter","TableCom","utilTool","$timeout","modalCom","$location","$uibModal","instancesSrv","cloudDatabaseSrv","checkedSrv","commonFuncSrv","cvmViewSrv","depviewsrv","alertSrv"]
function cloudDatabaseCtrl($rootScope, $scope, $translate, $filter, TableCom, utilTool, $timeout, modalCom, $location, $uibModal, instancesSrv, cloudDatabaseSrv, checkedSrv, commonFuncSrv, cvmViewSrv, depviewsrv, alert){
    var self = $scope;
    self.stopEnable = true;// 启动按钮是否可点击
    self.activeEnable = true;// 关机按钮是否可点击
    self.conIsEnable = true;// 控制台按钮是否可点击
    self.modifyFixdIpBtn = true;// 修改私网ip是否可点击
    self.bindfloatingIps = true;// 绑定公网ip是否可点击
    self.removefloatIpDis = true;// 解绑公网ip是否可点击
    self.loadIptable = true;// 网卡安全配置是否可点击
    self.vmSnap = true;// 快照是否可点击
    self.vmSnapRollBack = true;// 快照回滚是否可点击
    self.canStockTransfer = true;// 云硬盘迁移的按钮是否可点击
    self.canDoItAnyTime = true;// 磁盘qos的按钮是否可点击
    self.activeIsEnable = true;// 重启按钮是否可点击
    self.delServerDisabled = true;// 删除按钮是否可点击
    self.edit_Sever = true;// 编辑按钮是否可点击
    self.resetDisabled = true;//重置状态按钮是否可点击
    self.migrate = true;// 热迁移按钮是否可点击
    self.coldMigrateBtn = true;// 冷迁移的按钮是否可以点击
    self.upgrade = true;// 调整配置的按钮是否可以点击
    self.alarmDisabled = true;// 添加告警的按钮是否可以点击
    self.canEditPsw = true;// 修改密码的按钮是否可以点击
    self.errorStartDisabled = true;// 虚拟机异常管理的按钮是否可点击
    self.labelStatus = true;// 标签的按钮是否可点击
    self.delisDisabled = true;// 更多操作的按钮是否可点击
    self.vmdiskIdget = true;// 通过云硬盘详情查询控制按钮状态
    self.activeItem = [];// 选中的数据项
    self.pageNum = 1;
    self.pageSize = 10;
    self.currentPage = 1;
    self.detailData = {};
    self.statusSelected = {};
    self.search = { globalSearchTerm: "" };
    self.checkboxes = {
        checked: false,
        items: {}
    }
    self.searchList = [
        { name:$translate.instant('aws.cloudDatabase.table.status.all'),id:'',key:"" },
        { name:$translate.instant('aws.cloudDatabase.table.status.active'),id:"active",value:$translate.instant('aws.cloudDatabase.table.status.active'),key:"status_ori" },
        { name:$translate.instant('aws.cloudDatabase.table.status.shutoff'),id:"shutoff",value:$translate.instant('aws.cloudDatabase.table.status.shutoff'),key:"status_ori" },
        { name:$translate.instant('aws.cloudDatabase.table.status.error'),id:"error",value:$translate.instant('aws.cloudDatabase.table.status.error'),key:"status_ori" },
    ]

    self.headers = {
        name: $translate.instant("aws.cloudDatabase.databaseName"),
        instanceTypeCategory: $translate.instant("aws.cloudDatabase.databaseType"),
        status: $translate.instant("aws.cloudDatabase.status"),
        imageName: $translate.instant("aws.cloudDatabase.imageName"),
        privateIp: $translate.instant("aws.cloudDatabase.privateIp"),
        publicIp: $translate.instant("aws.cloudDatabase.publicIp"),
        os: $translate.instant("aws.cloudDatabase.operateSystem"),
        hostName: $translate.instant("aws.cloudDatabase.hostName"),
        hostBind: $translate.instant("aws.cloudDatabase.hostBind"),
        label:$translate.instant("aws.instances.label"),
        createTime: $translate.instant("aws.cloudDatabase.createTime"),
        domain: $translate.instant("aws.common.domain"),
        project: $translate.instant("aws.common.project")
    }

    self.titleName = "cloudDatabase";
    if(sessionStorage["cloudDatabase"]) {
        self.tableCols = JSON.parse(sessionStorage["cloudDatabase"]);
    }else {
        self.tableCols = [
            { field: "check", title: "",headerTemplateURL:"checkboxes.html",show: true },
            { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true },
            { field: "instanceTypeCategory", title: self.headers.instanceTypeCategory,sortable: "instanceTypeCategory",show: true,disable:false },
            { field: "status_ori", title: self.headers.status,sortable: "status",show: true,disable:false },
            { field: "imageName", title: self.headers.imageName,sortable: "imageName",show: true,disable:false },
            { field: "fixedIps", title: self.headers.privateIp,sortable: "privateIp",show: true,disable:false },
            { field: "floatingIps", title: self.headers.publicIp,sortable: "floatingIps_long",show: true,disable:false},
            { field: "os", title: self.headers.os,sortable: "os",show: true,disable:false},
            { field: "hostName", title: self.headers.hostName,sortable: "hostName",show: true,disable:false},
            { field: "hostBind", title: self.headers.hostBind,sortable: "hostBind",show: true,disable:false},
            { field: "labelList", title: self.headers.label,sortable: "labelList",show: true,disable:false},
            { field: "createtime", title: self.headers.createTime,sortable: "createtime",show: true,disable:false},
        ]
    }

    // 选择全部时 列表中部门和项目的处理
    self.$watch(function(){
        return localStorage.selectedAllDomain;
    },function(val){
        utilTool.projectDomainTableCol(val, 2, self);
    })

    // 获取云数据库表格数据
    self.getList = function(option){
        TableCom.init(self, "tableParams", [], "uid", self.pageSize, "checkboxes");
        var defaultParams = {
            pageNum: self.pageNum,
            pageSize: self.pageSize,
            pattern: self.search.globalSearchTerm,
            status: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : "", 
        }
        var params = option ? option : defaultParams;
        params.instanceType = 'db_inst';// 云数据库必须传instanceType 用来区分查询的云主机数据
        self.tableData = [];
        cloudDatabaseSrv.getCloudDatabaseList(params).then(result=>{
            if(result && result.data){
                var dealData = result.data;
                dealData = operateData(dealData);
                successFunc(dealData);
                self.totalNum = result.total;
            }
        })
    } 
    self.getList();

    // 处理表格数据
    function operateData(data) {
        data.forEach(item=>{
            var domainNameList = ["default"];
            var projectNameList = ["admin"];
            item.domainName = domainNameList.includes(item.domainName) ? $translate.instant("aws.common.defaultDepar") : item.domainName;
            item.projectName = projectNameList.includes(item.projectName) ? $translate.instant("aws.common.defaultProject") : item.projectName;
            if(!item.floatingIps){
                item.floatingIps = []
            }

            if(!item.fixedIps){
                item.fixedIps = []
            }
            
            item.createtime = $filter("date")(item.createtime, "yyyy-MM-dd HH:mm:ss");
            item.status = item.status.toLowerCase();
            item.status = item.task_state?item.task_state:item.status;
            item.status_ori = $translate.instant("aws.instances.table.status." + item.status);
            item.fixedIps_long = item.fixedIps.map(ip => _IP.toLong(ip)) ;
            item.floatingIps_long = item.floatingIps?item.floatingIps.map(ip => _IP.toLong(ip)):"";
            if(item.hostBind === null) {
                item.hostBind = false;
            }
            item.labelList = []
            if(item.label){
                for (const key in item.label) {
                    item.labelList.push(item.label[key])
                }
            }
        })
        return data;
    }

    function successFunc(data) {
        self.tableData = data;
        TableCom.init(self, "tableParams", self.tableData, "uid", self.pageSize, "checkboxes");
        var tableId = "uid"; 
        checkedSrv.checkDo(self, data, tableId);
    }

    // 切换页码
    self.changePage = function(options) {
        self.currentPage = options.pageNum;
        self.pageSize = options.pageSize;
        self.pattern = self.search.globalSearchTerm;
        self.status = self.statusSelected.searchItem.id;
        self.getList(options);
    }

    // 搜索
    self.searchBackend = function(options){
        self.currentPage = 1;
        var pageOption = {
            pageNum: self.pageNum,
            pageSize: self.pageSize,
            pattern: self.search.globalSearchTerm ? self.search.globalSearchTerm : "",
            status: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : ""
        }
        if(options.type == 'refresh') {
            self.search.globalSearchTerm = "";
            self.statusSelected.searchItem = self.searchList[0];
            pageOption.pattern = "";
            pageOption.status = "";
        }
        self.getList(pageOption);
    }

    // 资源导出
    self.exportResource = function(){
        var status = self.statusSelected.searchItem ? self.statusSelected.searchItem.id : '';
        var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/instances?regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}&pattern=${self.search.globalSearchTerm}&status=${status}&instanceType=db_inst`
        utilTool.downloadFile(url);
    }
    self.showExport = utilTool.isAllDomainSelect;

    // 监听勾选的表格数据
    self.$watch(function(){
        return self.checkboxes.items;
    },function(val,old){
        if(val&&old&&val.length!=old.length){
            utilTool.setLocalStorage(value);
        }

        if(Object.keys(val).length == 0) return;
        self.checkedItems = [];
        var arr = [];
        for (const i in self.checkboxes.items) {
            arr.push(self.checkboxes.items[i]);
        }
        if(val && arr.length>=0) {
            for (const key in val) {
                if(val[key]) {
                    self.tableData.forEach(item=>{
                        if(item.uid == key) {
                            self.checkedItems.push(item);
                            self.editData = angular.copy(item);
                        }
                    })
                }
            }
        }
    },true)

    // 各个操作按钮的disabled状态
    self.$watch(function(){
        return self.checkedItems;
    },function(value){
        if(!value) return
        self.activeItem = value
        var activeChk = 0,
            stopChk = 0,
            errorChk = 0,
            paushChk = 0,
            suspendChk = 0,
            rebootChk = 0,//重启
            resizeChk = 0;//调整配置
        self.stopEnable = true;
        self.activeEnable = true;
        self.conIsEnable = true;
        self.modifyFixdIpBtn = true;
        self.bindfloatingIps = true;
        self.removefloatIpDis = true;
        self.loadIptable = true;
        self.vmSnap = true;
        self.vmSnapRollBack = true;
        self.canStockTransfer = true;
        self.canDoItAnyTime = true;
        self.activeIsEnable = true;
        self.delServerDisabled = true;
        self.edit_Sever = true;
        self.resetDisabled = true;
        self.migrate = true;
        self.coldMigrateBtn = true;
        self.upgrade = true;
        self.alarmDisabled = true;
        self.canEditPsw = true;
        self.errorStartDisabled = true;
        self.labelStatus = true;
        self.snapshotRollbackTip = $translate.instant("aws.instances.tip.snapshot_rollback_tip");//快照回滚需要在关机状态
        self.stock_transferTip = $translate.instant("aws.instances.tip.stock_transferTip");
        self.migrateTip = $translate.instant("aws.instances.tip.migrateTip2");
        self.coldMigrateTip = $translate.instant("aws.instances.tip.coldMigrateTip2");
        self.upgradeTip = $translate.instant("aws.instances.tip.resize_tip2");
        self.errorStartTip = $translate.instant("aws.instances.tip.shutdownTip");
        if (!value) return;
        value.map(function(item){
            // 开机状态的个数
            activeChk += (item.status === "active") || 0;
            // 关机状态的个数
            stopChk += (item.status === "shutoff" || item.status === "stopped") || 0;
            // 错误状态的个数
            errorChk += (item.status === "error") || 0;
            // 暂停状态的个数
            paushChk += (item.status === "paused") || 0;
            // 挂起状态的个数
            suspendChk += (item.status === "suspended") || 0;
            // 重启状态个数
            rebootChk += (item.status === "reboot_pending" || item.status === "reboot") || 0;
            // 调整配置中个数
            resizeChk += (item.status === "resize" || item.status === "resize_prep" || item.status === "resize_migrating" || item.status === "resize_finish" || item.status === "verify_resize" || item.status === "resized") || 0;
        })
        var values= [activeChk,stopChk,errorChk,paushChk,suspendChk,rebootChk,resizeChk];
        // 只能单选的操作
        if(value.length == 1) {
            self.labelStatus = false;
            // 关机和开机有关的操作
            if((values[0] == 1) || (values[1] == 1) || (values[3] == 1) || (values[4] == 1)) {
                // 开机
                if(values[0] == 1){
                    // 判断是否可以热迁移
                    self.migrate = false;
                    // 控制台 
                    getConsole(value[0].uid);
                    if(value[0].startStyle != "Local"){
                        self.oshotgradeTip = $translate.instant("aws.instances.tip.not_get_info");
                        getoshotupgrade(value[0].uid)
                    }else{
                        self.oshotgradeTip = $translate.instant("aws.instances.tip.localTip");
                    }
                    // 修改密码
                    self.canEditPsw = false;
                }
                // 关机
                if(values[1] == 1){
                    // 判断是否可以冷迁移
                    self.coldMigrateBtn = false;    
                    // 判断是否可以调整配置
                    self.upgrade = false;      
                } 
                self.canDoItAnyTime = false;// 磁盘qos按钮
                // 云硬盘迁移 只能在云主机开机或者关机的状态下进行
                if(values[0] == 1 || values[1] == 1){
                    self.canStockTransfer = false;
                    // 判断当前是否只有一个存储池，如果只有一个，那云硬盘迁移按钮不可点击
                    judgeNumberOfVolumePool();
                }
                if (value[0].fixedIps) {
                    // 修改私网ip
                    if(value[0].fixedIps.length>0){
                       self.modifyFixdIpBtn = false;
                    }
                    // 私网ip不等于公网IP数时可以绑定公网ip
                    value[0].fixedIps.length != value[0].floatingIps.length ? self.bindfloatingIps = false : self.bindfloatingIps = true; 
                }
                // 解绑私网ip
                if(value[0].floatingIps) {
                    // 有公网ip时允许操作
                    value[0].floatingIps.length > 0 ? self.removefloatIpDis = false : self.removefloatIpDis = true; 
                }
                getServerDetail(value);
            }
            
            // 快照 
            switch(value[0].status) {
                case "active":
                case "stopped":
                case "shutoff":
                case "paused":
                case "suspended":
                    self.vmSnap = false;
                    break;
            }
            // 只有错误状态不可操作 磁盘qos
            if(((values[0] == 1) || (values[1] == 1) || (values[3] == 1) || (values[4] == 1)|| (values[5] == 1))){
                self.canDoItAnyTime = false;
            }
            
        }
        // 可以多选的操作 (启动、关机)
        if(value.length != 0) {
            if(values[0] == value.length) {// 开机可操作的
                self.activeEnable = false;
                self.activeIsEnable = false;
                if(values[0] == 1) {// 单选操作
                    self.loadIptable = false;
                    self.edit_Sever = false;
                    self.alarmDisabled = false;
                }
            }else if(values[1] == value.length) {// 关机可操作的
                self.stopEnable = false;
                if(values[1] == 1) {// 单选操作
                    self.vmSnapRollBack = false;
                    self.edit_Sever = false;
                    self.alarmDisabled = false;
                    self.errorStartDisabled = false;
                    self.snapshotRollbackTip = $translate.instant("aws.instances.tip.not_get_vmVolume");//快照回滚需要云主机下云硬盘
                }
            }
            if(value.length==errorChk){
                self.resetDisabled = false;
            }
            // 判断是否可以删除
            if(rebootChk==0&&values[5]==0) {
                canDelServerFunc(value);
            }
        }
    },true)

    // 打开详情
    self.linkTo = function(item){
        let insStatus = item.status.toLowerCase();
        utilTool.setLocalStorage([item]);
        $location.url(`/cvm/cloudDatabase?id=${item.uid}&insStatus=${insStatus}`);
    }

    // 关闭详情
    self.linkClose = function(){
        self.checkboxes.items = {};
        $location.url('/cvm/cloudDatabase');
        self.tab = {};// 解决再次打开详情时 详情的directive不会刷新的问题
        self.tabCacheObj = utilTool.tabsShow(3,'close');
    }

    // 详情加载的内容
    self.$on("getDetail", function(event, insUid) {
        self.panels = {};
        self.tab = {};
        self.tab = {
            active : 0
        }
        self.tabCacheObj = utilTool.tabsShow(3,'open');
        self.chartDisplay = function(i) {
            self.tab.active = i
            self.tabCacheObj[`tabCache${i}`] = true
        }
        self.insUid = insUid;
        self.fromCloudDatabase = true;// 用于详情页配置详情页面区分云数据库和云主机的字段展示
    })

    // 新建云数据库
    self.insAnimation = "animateOut";
    self.createDatabase = function(){
        utilTool.newResSetLocalStorage(self,'checkboxes','items')
        self.insAnimation = "animateIn";
        $("body").addClass("animate-open")
    };
    self.closeDatabase = function(){
        self.insAnimation = "animateOut";
        $("body").removeClass("animate-open")
    }
    self.$on("newDatabase",function(){
        self.insAnimation = "animateOut";
        $("body").removeClass("animate-open");
    })

    //选中多个
    self.chkSome = function(){
        var chkGroup = [];
        var itemGroup = [];
        var names = [];
        var postData = {
            id: { ids: chkGroup },
            items: itemGroup,
            names:names
        };
        _.forEach(self.tableData, (group)=> {
            if (self.checkboxes.items[group.uid]) {
                chkGroup.push(group.uid);
                itemGroup.push(group);
                names.push(group.name);
            }
        });
        return postData;
    };

    // 磁盘操作中没有配置存储时提示去存储管理中去配置
    self.noCinderModal = function(dis){
        if(dis) return;
        var modal_os = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/volumes/tmpl/addVolumeTip.html",
        });
    }

    // 开机
    self.startServer = function(){
        var post = [];
        var content = {
            target: "startServer",
            msg: `<span>${$translate.instant("aws.cloudDatabase.tipMsg.start")}</span>`,
            type: "info",
            btnType: "btn-primary"
        }
        self.checkedItems.map(item=>{
            post.push( { uid: item.uid, name:item.name })
        })
        instancesSrv.getRestoringVolServer(post).then(function(result){
            if(result && result.data && angular.isArray(result.data)) {
                if(result.data.length > 0) {
                    content.msg = `<span>${res.data.toString()}${$translate.instant('aws.cloudDatabase.tipMsg.startServerTip')}</span>`;
                    content.notDel = true;                
                }
            }
        })
        self.$emit("delete",content);
    }

    // 监听开机
    self.$on("startServer",function(){
        var ids = self.chkSome().id.ids;
        checkedSrv.setChkIds(ids,"power_on")
        instancesSrv.startServer(self.chkSome().id).then(function(){});
    })

    // 关机
    self.stopDatabase = function(){
        var content = {
            target: "stopDatabase",
            msg: `<span>${$translate.instant("aws.cloudDatabase.tipMsg.stop")}</span>`,
            type: "warning",
            btnType: "btn-warning"
        }
        self.$emit("delete", content);
    }

    // 监听关机
    self.$on("stopDatabase",function(){
        var ids = self.chkSome().id.ids;
        checkedSrv.setChkIds(ids,"power_off")
        instancesSrv.stopServer(self.chkSome().id).then(function(){});
    })

    // 控制台
    self.openConsole = function(editData){
        window.open(self.consoleUrl, editData.uid, "height=1000,width=1100,top=20,left=20,toolbar=yes,resizable=yes,menubar=no,scroll");
    }

    // 编辑私有网络
    self.editPortCard = function(editData){
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/edit-port-card.html",
            controller:'editPortCardCtrl',
            resolve:{
                refresh: function(){
                    return self.getList
                },
                editData: function(){
                    return editData
                },
                context: function() {
                    return self
                }
            }
        })
    }

    // 绑定公网ip 和 解绑公网ip
    self.bindFloatingIp = function(type,editData){
        self.bindFloatingIpModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/update-os-floatingip.html",
            controller: "bindUnbindFloatingIpCtrl",
            resolve: {
                context: function(){
                    return self
                },
                type: function(){
                    return type
                },
                editData: function(){
                    return editData
                },
                refresh: function(){
                    return self.getList
                }
            }
        })
    }

    // 网卡安全配置
    self.newNetworkCardConfig = function(editData){
        self.networkCardConfigModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/networkCardSecConfig.html",
            controller: "networkCardSecConfigCtrl",
            resolve: {
                editData: function(){
                    return editData
                },
                refresh: function(){
                    return self.getList
                }
            }
        })
    }

    // 快照
    self.createSnap = function(editData){
        self.createSnapModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/createSnap.html",
            controller: "createSnapCtrl",
            resolve :{
                editData: function(){
                    return editData
                }
            }
        })
    }

    //快照回滚
    self.snapRollBack = function(){
        if(!self.vmSnapRollBack && !self.vmdiskIdget){
            self.snapRollBackModule =  $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/os-snap-rollback.html",
                controller: "snapRollBackCtrl",
                resolve: {
                    vmdiskInfo: function(){
                        return self.vmdiskInfo
                    }
                }
            })
        }
    }

    // 云硬盘迁移
    self.stockTransfer = function(editData){
        if(self.canStockTransfer || self.vmdiskIdget) return;
        self.stockTransferModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/stock-transfer.html",
            controller: "stockTransferCtrl",
            resolve: {
                context: function(){
                    return self
                },
                editData: function(){
                    return editData
                },
                refresh: function(){
                    return self.getList
                }
            }
        })
    }

    // 磁盘qos
    self.volumesQoS = function(editData){
        self.volumesQoSModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/volumes-qoS.html",
            controller: "volumesQoSCtrl",
            resolve: {
                context: function(){
                    return self
                },
                editData:editData,
                refresh: function(){
                    return self.getList
                }
            }
        })
    }

    // 重启
    self.reboot = function(){
        var content = {
            target: "reboot",
            msg:`<span>${$translate.instant("aws.cloudDatabase.tipMsg.reboot")}</span>`,
            type: "info",
            btnType: "btn-primary"
        }
        self.$emit('delete',content)
    }

    self.$on("reboot",function(){
        var ids = self.chkSome().id.ids;
        checkedSrv.setChkIds(ids,"reboot")
        instancesSrv.rebootServer(self.chkSome().id).then(function() {
            self.checkboxes.items = {};
        });
    })

    // 删除
    self.delSever = function(){
        if(!self.delServerDisabled){
            commonFuncSrv.deleteInstance(self,self.chkSome().id.ids,self.checkedItems);
        }
    }

    // 编辑主机名
    self.editSever = function(editData){
        self.editSeverModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/edit-hostname.html",
            controller: "editSeverCtrl",
            resolve: {
                context: function(){
                    return self
                },
                editData: function(){
                    return editData
                },
                refresh: function(){
                    return self.getList
                }
            }
        })
    }

    // 重置状态
    self.reset = function(){
        if(self.resetDisabled) return;
        var content = {
            target: "reset",
            msg: `<span>${$translate.instant("aws.cloudDatabase.tipMsg.reset")}<span>`
        }
        self.$emit("delete",content);
    }

    self.$on("reset",function(){
        var  ids = self.chkSome().id.ids;
        var data = {
            ids:ids,
            names:self.chkSome().names
        };
        instancesSrv.resetVm(data).then(function() {
            self.getList();
        }); 
    })

    // 热迁移
    self.osMigrate = function(){
        if(self.migrate || self.vmdiskIdget) return;
        self.osMigrateModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/os-migrate.html",
            controller:"osMigrateCtrl",
            resolve:{
                refresh:function(){
                    return self.getList;
                },
                editDataCopy:function(){
                    return self.editDataCopy;
                }
            }
        });
    }

    // 冷迁移
    self.coldMigrate = function(editData){
        if(!self.coldMigrateBtn && !self.vmdiskIdget){
            self.coldMigrateModule = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/instances/tmpl/coldMigrate.html",
                controller: "coldMigrateCtrl",
                resolve: {
                    editData: function(){
                        return editData
                    }
                }
            });
        }
    }

    // 调整配置
    self.osUpgrade = function(editData){
        self.osUpgradeModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/os-upgrade.html",
            controller: "osUpgradeCtrl",
            resolve: {
                context: function(){
                    return self
                },
                editData: function(){
                    return editData
                },
                refresh: function(){
                    return self.getList
                },
                getProused: function(){
                    return getProused
                },
                getDomused: function(){
                    return getDomused
                }
            }
        })
    }

    // 热升级
    self.osHotgrade = function(edit){
        if(self.unhotgrade || self.oshotgrade || self.vmdiskIdget) return
        self.osHotgradeModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/os-hotgrade.html",
            controller: "osHotgradeCtrl",
            resolve: {
                context: function(){
                    return self
                },
                edit: function(){
                    return edit
                },
                refresh: function(){
                    return self.getList
                },
                getProused: function(){
                    return getProused
                },
                getDomused: function(){
                    return getDomused
                }
            }
        })
    }

    // 添加告警
    self.addAlarm = function(data){
        self.addAlarmModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/alarmSetting.html",
            controller: "addAlarmCtrl",
            resolve: {
                context: function(){
                    return self
                },
                data: function(){
                    return data
                }
            }
        });
    }

    // 修改密码
    self.editPsw = function(data){
        if(self.canEditPsw){
            return ;
        }
        self.editPswModule = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/edit-psw.html",
            controller: "editPswCtrl",
            resolve: {
                context: function(){
                    return self
                },
                data: function(){
                    return data
                }
            }
        });
    }

    // 虚拟机异常管理
    self.errorStart = (data) => {
        if(self.errorStartDisabled) return;
        self.errorStartModule = $uibModal.open({
            animation: $scope.animationsEnabled, 
            backdrop: "static",
            templateUrl: "js/cvm/instances/tmpl/error-start.html",
            controller: "errorStartCtrl",
            resolve: {
                editData: function() {
                    return data;
                },
                context: function() {
                    return self;
                },
                refresh: function() {
                    return self.getList
                }
            }
        });
    }

    // 修改标签
    self.updatetag = function(){
        self.createItemModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/instances/tmpl/change-label-alert.html",
            controller: "changeLabelAlertCtrl",
            resolve: {
                context: function() {
                    return self;
                },
                activeItem:function(){
                    return self.activeItem;
                },
                refreshIns:function(){
                    return self.getList
                }
            }
        })
    }

    // 处理vnc控制台的数据
    function getConsole(uid){
        instancesSrv.os_console(uid).then(function(result) {
            self.consoleUrl = encodeURI(result.data);
            var hostname = result.data.split("&");
            var conwatch=self.$watch(function(){
                return self.checkedItems;
            },function(value){
                if(value&&value.length == 1 && value[0].name== hostname[1].split("=")[1]){
                    self.conIsEnable = false;
                }else{
                    self.conIsEnable = true;
                }
                conwatch();
            });
        });
    }

    // 热迁移、虚拟机异常管理添加无法操作的提示信息
    function getoshotupgrade(uid){
        instancesSrv.getHotgrade(uid).then(function(res) {
            if (res && res.data) {
                self.unhotgrade = !res.data.qga_support;
                self.hotgradeData = res.data;
            }
            if(self.unhotgrade){
                self.oshotgradeTip = $translate.instant("aws.instances.tip.oshotgradeTip1");
            } 
        });
    }

    //获取项目中的总配额
    function getproQuotas() {
        var insQuotapost = {
            type: "project_quota",
            targetId: localStorage.projectUid,
            enterpriseUid: localStorage.enterpriseUid
        };
        cvmViewSrv.getproQuotas(insQuotapost).then(function(result) {
            if (result && result.data && result.data.length) {
                _.forEach(result.data, function(item) {
                    if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                        self[item.name + "quota"].total = item.defaultQuota || item.hardLimit;
                    }
                });
            }
        });
    }

    //获取项目中配额的使用量
    function getProused() {
        self.instancesquota = {};
        self.coresquota = {};
        self.ramquota = {};
        var postData = {
            type: "project_quota",
            domainUid: localStorage.domainUid,
            projectUid: localStorage.projectUid,
            enterpriseUid: localStorage.enterpriseUid
        };
        cvmViewSrv.getProused(postData).then(function(result) {
            if (result && result.data && result.data.length) {
                _.forEach(result.data, function(item) {
                    if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                        self[item.name + "quota"].used = item.inUse;
                    }
                });
            }
            getproQuotas();
        });
        
    }

    //获取部门中的总配额
    function getDomQuotas() {
        depviewsrv.getQuotaTotal(localStorage.domainUid).then(function(result) {
            if (result && result.data && result.data.length) {
                _.forEach(result.data, function(item) {
                    if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                        self[item.name + "Domquota"].total = item.defaultQuota || item.hardLimit;
                    }
                });
            }
        });
    }

    //获取部门中配额的使用量
    function getDomused() {
        self.instancesDomquota = {};
        self.coresDomquota = {};
        self.ramDomquota = {};
        depviewsrv.getQuotaUsed(localStorage.domainUid).then(function(result) {
            if (result && result.data && result.data.length) {
                _.forEach(result.data, function(item) {
                    if (item.name == "instances" || item.name == "cores" || item.name == "ram") {
                        self[item.name + "Domquota"].used = item.inUse;
                    }
                });
            }
            getDomQuotas();
        });
        
    }

    // 获取详情中云硬盘的各个判断按钮的状态
    function getServerDetail(value) {
        instancesSrv.getServerDetail(value[0].uid).then(function(result) {
            if(result && result.data && angular.isArray(result.data.diskInfo)){
                if(self.editData.uid ==  result.data.uid){
                    self.editDataCopy = result.data;
                    self.vmdiskInfo = result.data.diskInfo;
                    self.vmdiskIdget = false;
                   chkTransferRetyping(self.vmdiskInfo);
                }
            }
        })
    }

    //判断当前是否只有一个存储池，若只有一个，云硬盘迁移不可点击
    function judgeNumberOfVolumePool(){
        if(self.canStockTransfer) return ;
        instancesSrv.getStorage().then(function(res){
            if(res&&res.data&&angular.isArray(res.data)){
                if(res.data.length==1){
                    self.canStockTransfer = true;
                    self.stock_transferTip = $translate.instant("aws.instances.tipMsg.oneStoragePoolCanNotTransfer");
                }
            }   
        });    
    }

    //云主机正在进行卷迁移不可以再进行卷迁移操作
    function chkTransferRetyping(vmdisk){
        if(self.canStockTransfer) return ;
        vmdisk.map(item => {
            if(item.status == "retyping"){
                self.canStockTransfer = true;
                self.stock_transferTip = $translate.instant("aws.instances.tipMsg.stock_transferTip1");
            }
        })
    }

    // 判断云数据库是否可删除
    function canDelServerFunc(checkedItems){
        let insIdArr=[],canDelete=false;
        self.canNotDelServerTips="";
        _.forEach(checkedItems, function(item) {
            insIdArr.push(item.uid);
        });
        instancesSrv.checkServerHasBackupJob({'instanceId':insIdArr}).then(function(res){
            if(res&&res.data&&angular.isArray(res.data)){
                for(var i=0;i<res.data.length;i++){
                    //云主机有备份任务存在，请先解绑备份任务，再进行删除操作
                    if(_.include(insIdArr,res.data[i].instanceId)){
                       self.canNotDelServerTips=$translate.instant("aws.instances.tip.insHasBackupJob")
                       canDelete=true; 
                       break;
                    }
                } 
                if(canDelete==false){
                    self.delServerDisabled=false;
                }
            }
        });
    }

    // websocket处理各种操作的消息
    self.$on("serverSocket", function(event,data){
        if (self.tableData && self.tableData.length) {
            self.tableData.map(function(obj) {
                if (data.eventMata.instance_id) {
                    if (obj.uid === data.eventMata.instance_id) {
                        obj.task_state = data.eventMata.status;
                        obj.status = obj.task_state?obj.task_state:obj.status;
                        let eventTypeList = ["compute.instance.soft_delete.end","compute.instance.delete.end"];
                        if (eventTypeList.includes(data.eventType)) { //删除
                            if(self.currentPage==1){
                                self.getList();
                                return;
                            }
                            _.remove(self.tableData, function(val) {
                                return ((val.status == "soft-delete" || val.status == "error" || val.status == "deleted") && val.uid === data.eventMata.instance_id);
                            });
                            if(self.tableData.length===0){
                                self.currentPage = (self.currentPage - 1)<2?1:(self.currentPage - 1)
                                var pageOption = {
                                    pageNum: self.currentPage,
                                    pageSize: self.pageSize,
                                    pattern: self.search.globalSearchTerm ? self.search.globalSearchTerm : "",
                                    status: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : ""
                                }
                               self.getList(pageOption);
                            }
                        }
                    }
                }
            })
            // 开机和关机表格中状态分别更变为开机中和关机中
            self.tableData = operateData(self.tableData);
            self.tableParams.reload();
            self.checkboxes.items = {};
            self.checkboxes.checked = false;
        }
        
        switch(data.eventType) {
            // 创建
            case "compute.instance.create.start":
            case "compute.instance.create.end":
            // 云硬盘迁移重启开机刷新状态;重启云数据库刷新状态
            case "compute.instance.reboot.start":
            case "compute.instance.reboot.end":
                $timeout(()=>{
                    self.getList();
                },1000)
                break
        }
    })

    // 修改私网ip的消息
    self.$on("updatePorts",function(){
        let tipMsg_a = $translate.instant("aws.sockets.opLog.port.update.end");
        alert.set('', tipMsg_a, "success", 5000);
        $timeout(function(){
            self.getList();
        },500)
    })

    // 绑定公网ip和解绑公网ip 
    self.$on("refreshSuccess",function(e,data){
        let tipMsg = data.msg || $translate.instant("aws.statusCode.0");
        let {showMsg} = data;
        if(showMsg) {
            alert.set('', tipMsg, "success", 5000);
        }
        $timeout(function(){
            self.getList();
        }, 500)
    })

}

export { cloudDatabaseCtrl }