serviceConfigSrv.$inject = ["$rootScope", "$http"]
function serviceConfigSrv($rootScope, $http) {
    var backup_url="/awstack-backup/v1/backup/backup-service-config/";
    var schedule_url="/awstack-schedule/v1/job/";
    return {
        connectionWay:function(data) {
            return $http({
                url: backup_url+"connection-test",
                method: "post",
                data: data,
            })
        },
        saveConfigWay:function(data) {
            return $http({
                url: backup_url+"add",
                method: "post",
                data: data,
            })
        },
        getConfigInfo:function() {
            return $http({
                url: backup_url+"info",
                method: "get",
            })
        },
        getPasswordValidity:function(data) {
            return $http({
                url: backup_url+"password-validity",
                method: "post",
                data: data,
            })
        },
        deleteConfigWay:function(data) {
            return $http({
                url: backup_url+"delete/" + data,
                method: "delete",
            })
        },
        changePasswordMethod:function(data) {
            return $http({
                url: backup_url+"update-password",
                method: "post",
                data: data,
            })
        },

        lowFrequencySync:function(data) {
            return $http({
                url: schedule_url+"low-frequency-sync/backup",
                method: "post",
                data: data,
            })
        },
        highFrequencySync:function(data) {
            return $http({
                url: schedule_url+"high-frequency-sync/backup",
                method: "post",
                data: data,
            })
        },
        detailFrequencySync:function(params) {
            return $http({
                url: schedule_url+"sync/recources",
                method: "get",
                params: params,
            })
        },
        executeSync:function(data) {
            return $http({
                url: backup_url+"execute-sync/" + data,
                method: "get",
            })
        }
        
    }
}
export {serviceConfigSrv}