import "./transmissionMagSrv";

var TransMagaModule = angular.module("TransMagaModule", ["TransMagaApi"])
.controller("TransMagaCtrl", ['$routeParams','$scope', '$rootScope', '$location', 'TransMagaSrv', '$translate','GLOBAL_CONFIG','TableCom','modalCom','commonFuncSrv',
function($routeParams,$scope, $rootScope, $location, TransMagaSrv, $translate,GLOBAL_CONFIG,TableCom,modalCom,commonFuncSrv) {
    var self = $scope ;
    self.checkFirst = {
        checked: false,
        items: {}
    };
    self.TransMaga_search={};

    function handleNodeName(data,nodePrettyNameList){
        self.TransMagaData = data.map(function(item){
            item.status_load = item.status?"已加载":"未加载";
            item.prettyName=nodePrettyNameList[item.hostName];
            item.searchTerm = [item.serverName,item.prettyName,item.deviceType,item.status_load,item.description].join("\b")
            return item;
        });
        TableCom.init(self,'TransMagaTable',self.TransMagaData,'id',GLOBAL_CONFIG.PAGESIZE,'checkFirst');
    }

    var initTransMaga = function(){
         TransMagaSrv.gitInstanceList().then(function(res){
            if(res&&res.data){
                //将列表中的物理主机名换成主机别名
                commonFuncSrv.showPrettyNameOfNode(res.data,handleNodeName);
            }
        });
    };
    initTransMaga();
    
    self.vimList=[];
    //查询所有的虚拟机，获取开机
    var getVimList=function(){
    	TransMagaSrv.getVimList().then(function(res){
    		 if(res&&res.data){
               self.vimList=angular.copy(res.data);
            }
    	});
    };
    getVimList();

    self.applyGlobalSearch = function() {
        self.TransMagaTable.filter({
            searchTerm: self.TransMaga_search.globalSearchTerm
        });
    };
    self.refreshTransMaga = function(){
        self.TransMaga_search.globalSearchTerm="";
        initTransMaga();
    };

    // 监听主页的单选框，控制操作按钮的状态
    self.$watch(function() {
        return self.checkFirst.items;//监控checkbox
    }, function(val) {
        self.checkedItems = [];
        var arr=[];
        for(var i in self.checkFirst.items){
            arr.push(self.checkFirst.items[i]);
        }
        self.checkRelation = null;
        self.checkRelieve = null;
        if(val && arr.length>=0){
            for(var key in val){
                if(val[key]){
                    self.TransMagaData.forEach(item=>{
                        if(item.id==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
        if(self.checkedItems.length==1){
            if(self.checkedItems[0].serverStatus=="shutoff"||self.checkedItems[0].serverStatus=="active"){
                self.canUnload = true;
            }else{
                self.canUnload = false;
            }    
            self.canAdd = true;
        }else if(self.checkedItems.length>1){
            self.canAdd = true;
            self.canUnload = false;
        }else if(self.checkedItems.length==0){
            self.canAdd = true;
            self.canUnload = false;
        }
    },true);

    function handleDetailNodeName(data,nodePrettyNameList){
        data.prettyName=nodePrettyNameList[data.hostName];
    }

    //详情
    self.$watch(function(){
        return $location.search().id
    },function(ne,old){
        if(!ne||ne==old) return
        let serverId = ""
        let id = $location.search().id;
        self.TransMagaData.forEach(function(item){
            if(item.id == id){
                serverId = item.serverId
            }
        })
        let enterpriseUid = localStorage.enterpriseUid
        let domainName = localStorage.domainName
        let projectName = localStorage.projectName
        TransMagaSrv.getInstancesDetails(enterpriseUid,id,serverId).then(function(res){
            if(res&&res.data){
                self.InstancesDetails = res.data
                //self.InstancesDetails.domainName = domainName
                //self.InstancesDetails.projectName = projectName
                commonFuncSrv.showPrettyNameOfNode(self.InstancesDetails,handleDetailNodeName);
            }
        })
    })

    // 加载信息
    self.loadTransMaga = function(data) {
        var createTransMaga = modalCom.init('createTransMaga.html',"createTransMagaCtrl",{refresh:function(){return initTransMaga},context:function(){return self},editData:function(){return data}})
    };

    // 透传设备卸载
    self.uninstallTransMaga = function(data){
        var unloadInstanceModal = modalCom.init(
            "js/system/transmissionMag/temp/uninstallTransMaga.html",
            "uninstallTransMagaCtrl",
        {
            refresh:function(){
                return initTransMaga;
            },
            context:function(){
                return self;
            },
            editData:function(){
                return data;
            }
        })
    }

    // 卸载  废弃 by guozh 2022/1/20/21:00
    self.discarduninstallTransMaga = function(checkedItems) {
        var uninstallMsg="<span>" + $translate.instant("aws.transmissionMag.confirm-uninstall-equipment") + "</span>";
        var content = {
            target: "uninstallTransMaga",
            msg: uninstallMsg,
            data: checkedItems
        };
        if(self.checkedItems[0].serverStatus!="shutoff"&&self.checkedItems[0].serverStatus!="stopped"&&(self.checkedItems[0].deviceType=="FPGA"||self.checkedItems[0].deviceType=="GPU")){
            content.msg = "云主机开机状态下只能查询，不能进行加载、卸载FPGA、GPU操作";
            content.notDel = true;
        }
        self.$emit("delete", content);
    };
    self.$on("uninstallTransMaga", function(e,data) {
        if(self.checkedItems.length>0){
            if(self.checkedItems[0].deviceType == "USB"){
                let id = self.checkedItems[0].serverId
                let postData = {
                    usbPort:self.checkedItems[0].usbPort,
                    usbBus:self.checkedItems[0].usbBus,
                    addressBus:self.checkedItems[0].addressBus,
                    addressDevice:self.checkedItems[0].addressDevice,
                }
                TransMagaSrv.unloadTtEquipment(id,postData).then(function(res){
                    initTransMaga();
                })
            }else if(self.checkedItems[0].deviceType == "GPU"){
                let id = self.checkedItems[0].serverId;
                let postData = {
                    bus:self.checkedItems[0].gpuId.split(":")[0],
                    slot:self.checkedItems[0].gpuId.split(":")[1].split(".")[0],
                    function:self.checkedItems[0].gpuId.split(":")[1].split(".")[1],
                    gpuId:self.checkedItems[0].gpuId
                }
                TransMagaSrv.unloadGpuTtEquipment(id,postData).then(function(res){
                    initTransMaga();
                })
            }else if(self.checkedItems[0].deviceType == "FPGA"){
                let id = self.checkedItems[0].serverId;
                let postData = {
                    bus:self.checkedItems[0].gpuId.split(":")[0],
                    slot:self.checkedItems[0].gpuId.split(":")[1].split(".")[0],
                    function:self.checkedItems[0].gpuId.split(":")[1].split(".")[1],
                    gpuId:self.checkedItems[0].gpuId
                }
                TransMagaSrv.unloadFpgaTtEquipment(id,postData).then(function(res){
                    initTransMaga();
                })
            }
        }
    });  
}])
.controller("uninstallTransMagaCtrl",["$scope", "$rootScope","$translate","GLOBAL_CONFIG","$uibModalInstance","TransMagaSrv","refresh","context","instancesSrv","$timeout",
function($scope, $rootScope, $translate, GLOBAL_CONFIG,$uibModalInstance,TransMagaSrv,refresh,context,instancesSrv,$timeout) {
    var self = $scope;
    self.haSwitch = true;
    self.switchText = $translate.instant('aws.localDisk.open');
    self.haSwitchDisabled = false;
    self.instanceStatus = true;

    // 设置高可用
    function setHostBind() {
        let uid = context.checkedItems[0].serverId;
        let params = {
            hostBind: !self.haSwitch
        }
        instancesSrv.setHighAvailability(uid,params).then((res)=>{}).finally(()=>{})
    }

    // 处理USB提交的数据
    function usbPostData() {
        let postData = {
            usbPort:context.checkedItems[0].usbPort,
            usbBus:context.checkedItems[0].usbBus,
            addressBus:context.checkedItems[0].addressBus,
            addressDevice:context.checkedItems[0].addressDevice,
        }
        return postData;
    }

    // 处理gpu和fpga提交的数据
    function gpuOrFpgaPostData() {
        let postData = {
            bus:context.checkedItems[0].gpuId.split(":")[0],
            slot:context.checkedItems[0].gpuId.split(":")[1].split(".")[0],
            function:context.checkedItems[0].gpuId.split(":")[1].split(".")[1],
            gpuId:context.checkedItems[0].gpuId
        }
        return postData;
    }

    // 云主机开机状态下 不能进行卸载FPGA和GPU操作
    function checkInstanceStatus() {
        let status = context.checkedItems[0].serverStatus.toLowerCase();
        self.instanceStatus = status == "active" ? false : true;
        return self.instanceStatus;
    }

    // 透传设备初始化数据
    function initRefreshData() {
        self.haSwitchDisabled = false;
        $uibModalInstance.close();
        refresh();
    }

    // USB提交请求
    async function unloadTtEquipment(id,postData) {
        let result = await instancesSrv.unloadTtEquipment(id,postData).then(function(res){
            initRefreshData();
            return res;
        })
        return result;
    }

    // gpu的请求
    async function unloadGpuTtEquipment(id,postData) {
        let result = await instancesSrv.unloadGpuTtEquipment(id,postData).then(function(res){
            initRefreshData();
            return res;
        })
        return result;
    }

    // fpga的请求
    async function unloadFpgaTtEquipment(id,postData) {
        let result = await instancesSrv.unloadFpgaTtEquipment(id,postData).then(function(res){
            initRefreshData();
            return res;
        })
        return result;
    }

    // Promise处理的请求
    function promiseConfirm(func) {
        Promise.allSettled([Promise.resolve(func)]).then(data=>{
            if(data[0].value && data[0].value.status == 0) {
                setHostBind();
            }
        });
    }

    // 透传设备卸载和设置高可用提交
    self.confirm = function(){
        if(context.checkedItems.length>0){
            self.haSwitchDisabled = true;
            let id = context.checkedItems[0].serverId;
            let deviceType = context.checkedItems[0].deviceType.toLowerCase();
            if(deviceType == "usb"){
                let postData = usbPostData();
                promiseConfirm(unloadTtEquipment(id,postData));
            }else if(deviceType == "gpu" && checkInstanceStatus()){
                let postData = gpuOrFpgaPostData();
                promiseConfirm(unloadGpuTtEquipment(id,postData));
            }else if(deviceType == "fpga" && checkInstanceStatus()){
                let postData = gpuOrFpgaPostData();
                promiseConfirm(unloadFpgaTtEquipment(id,postData));
            }else{
                $timeout(()=>{
                    self.haSwitchDisabled = false;
                },2000)
            }
        }
    }

}])
.controller("createTransMagaCtrl", ["$scope", "$rootScope","$translate","GLOBAL_CONFIG","$uibModalInstance","TransMagaSrv","refresh","context","instancesSrv",
function($scope, $rootScope, $translate, GLOBAL_CONFIG,$uibModalInstance,TransMagaSrv,refresh,context,instancesSrv) {
	console.log(context);
    var self = $scope;
    self.noCanLoadusbEquipment = false   
    self.noCanLoadgpuEquipment = false   
    self.noCanLoadfpgaEquipment=false
    self.limitUsbDriveType = false
    self.limitGpuDriveType = false
    self.limitFpgaDriveType=false
    self.showFlag=true
    self.loadData = false //数据加载中，不可点击透传类型选项  
    self.TransMagaMsg = {
        instanceName:""
    }       
    
    self.hasAddEquipmentList = [
        {name:"USB设备",deviceType:"USB"},
        {name:"GPU设备",deviceType:"GPU"},
        {name:"FPGA设备",deviceType:"FPGA"}
    ]
    self.deviceType = {
        name:self.hasAddEquipmentList[0]
    }

    self.compatibilityList = [
        {name:"USB2.0",compatibility:"0"},
        {name:"USB3.0",compatibility:"1"}
    ]

    self.compatibility = {
        name:self.compatibilityList[0]
    }

    // 初始化高可用开关的值
    function initHaSwitch() {
        self.haSwitch = false;
        self.switchText = switchText(self.haSwitch);
    }
    initHaSwitch();

     // 开启高可用开关
     self.switchChange = (value)=> {
        self.haSwitch = value;
        self.switchText = switchText(self.haSwitch);
    }

    // 处理高可用开关显示的文字
    function switchText(value){
        let haSwitch = value ? $translate.instant('aws.localDisk.open') : $translate.instant('aws.localDisk.close');
        return haSwitch;
    }

    // 设置高可用
    function setHostBind() {
        let uid = self.serverId;
        let params = {
            hostBind: !self.haSwitch
        }
        instancesSrv.setHighAvailability(uid,params).then((res)=>{}).finally(()=>{})
    }

    // 第一次进入，显示usb设备
    choiceInstancesByUsb()

    // usb设备可加载的云主机列表筛选
    // 开机，关机状态，只可加载3条usb设备
    function choiceInstancesByUsb(){
        // 添加过滤条件  过滤掉云数据库的数据
        let params = {
            filterType:'db_inst' 
        }
        TransMagaSrv.getInstancesList(params).then(function(res){
            self.TransMagaList = []
            if(res&&res.data&&res.data.length>0){
                res.data.forEach(function(item){
                    if(item.status == "shutoff" || item.status == "active"){
                        if(item.passthrough_devices){
                            self.TransMagaList.push(item)
                            // if(item.passthrough_devices.length>0){
                            //     let count = 0
                            //     let gpucount=0
                            //     let fpgacount=0
                            //     item.passthrough_devices.forEach(function(val){
                            //         if(val.device_type=="USB"){
                            //             count ++
                            //         }
                            //         if(val.type=="gpu"){
                            //         	gpucount ++
                            //         }
                            //         if(val.type=="fpga"){
                            //         	fpgacount ++
                            //         }
                            //     })
                            // if(count<3||gpucount!=0||fpgacount!=0){
                            //     self.TransMagaList.push(item)
                            // }
                            // }else{
                            //     self.TransMagaList.push(item)
                            // }  
                        }
                    }
                })
                if(self.TransMagaList.length>0){
                    // self.TransMagaList = res.data
                    self.TransMagaMsg.instanceName = self.TransMagaList[0]
                    self.serverId = self.TransMagaList[0].id
                    self.serverName = self.TransMagaList[0].name
                    getTtequipmentList(self.serverId)
                }
            }
        })
    }

    // usb设备可加载的云主机列表筛选
    // 开机，关机状态，加载条数3
    function choiceInstancesByGpu(){
        TransMagaSrv.getInstancesList().then(function(res){            
            self.TransMagaList = []
            if(res&&res.data&&res.data.length>0){
                res.data.forEach(function(item){
                    if(item.status == "shutoff" || item.status == "active"){
                        if(item.passthrough_devices){
                            if(item.passthrough_devices.length>0){
                                let count = 0
                                item.passthrough_devices.forEach(function(val){
                                    if(val.device_type=="GPU"){
                                        count ++
                                    }
                                })
                                if(count<3){
                                    self.TransMagaList.push(item)
                                }
                            }else{
                                self.TransMagaList.push(item)
                            }  
                        }
                    }
                })
                if(self.TransMagaList.length>0){
                    // self.TransMagaList = res.data
                    self.TransMagaMsg.instanceName = self.TransMagaList[0]
                    self.serverId = self.TransMagaList[0].id
                    self.serverName = self.TransMagaList[0].name
                    getGpuTtequipmentList(self.serverId)
                }
            }
        })
    }

    
    // 云主机下拉框显示改变事件
    self.choiceInstances = function(item){
        self.serverId = item.id
        self.serverName = item.name  
        self.loadData = false              
        if(self.deviceType.name.deviceType == "USB"){ 
            getTtequipmentList(self.serverId)
        }else if(self.deviceType.name.deviceType == "GPU"){                 
            getGpuTtequipmentList(self.serverId)
        }else if(self.deviceType.name.deviceType == "FPGA"){                 
            getFpgaTtequipmentList(self.serverId)
            if(self.currentServer){
                self.currentServer.status=angular.copy(item.status)
                self.currentServer.uid=angular.copy(item.id)
            }
        }
        
    }

    // 获取usb类型的透传设备详情列表,并关闭gpu,fpga提示信息
    function getTtequipmentList(serverId){
        self.loadData = true
        self.equipmentList = []  
        self.noCanLoadgpuEquipment=false
        self.noCanLoadfpgaEquipment=false
        self.addAttacheSubmit=false
        let usbloadcount=0
        TransMagaSrv.getTtequipmentList(serverId).then(function(res){
            if(res&&res.data&&res.data.length>0){
                res.data.forEach(function(item){
                    if(!item.loading){
                        self.equipmentList.push(item)
                    }
                    if(item.loading){
                    	usbloadcount ++
                    }
                })
                if(self.equipmentList.length>0){
                    self.ttEquipmentDetails = self.equipmentList[0]
                    self.noCanLoadusbEquipment = false
                    if(usbloadcount >=3){
	                	//不允许加载usb
	                	self.usbFlag=true
	                	self.showFlag=false
	                }else{
	                	self.usbFlag=false
	                	self.showFlag=true
	                	self.addAttacheSubmit=true
	                }
                }else{
                    self.noCanLoadusbEquipment = true
                }
            }else{
                self.noCanLoadusbEquipment=true
            }
        })
    }
    //获取gpu类型的透传设备详情列表,并关闭usb,fpga提示信息
    function getGpuTtequipmentList(serverId){
    	self.showFlag=true
        self.loadData = true
        self.equipmentList = []  
        self.usbFlag=false
        self.noCanLoadusbEquipment=false
        self.noCanLoadfpgaEquipment=false
        self.addAttacheSubmit=false
        TransMagaSrv.getGpuTtequipmentList(serverId).then(function(res){
            if(res&&res.data&&res.data.length>0){
                res.data.forEach(function(item){
                    if(!item.loading){
                        self.equipmentList.push(item)
                    }
                })
                if(self.equipmentList.length>0){
                    self.ttEquipmentDetails = self.equipmentList[0]
                    self.noCanLoadgpuEquipment = false
                    self.addAttacheSubmit=true
                }else{
                    self.noCanLoadgpuEquipment = true
                }  
                //循环遍历当前的所有项目，找到当前选择的serverid的开机状态
                for(var i=0;i<context.vimList.length;i++){
                    if(context.vimList[i].uid==self.serverId){
                        self.currentServer1=angular.copy(context.vimList[i])
                    }
                }
                 //不是关机状态就置灰确认按钮,并显示fpga在开机状态下不能操作的提示信息
                    if(self.currentServer1&&(self.currentServer1.status!='SHUTOFF'&&self.currentServer1.status!='shutoff'&&self.currentServer1.status!='stopped')&&self.deviceType.name.deviceType=="GPU"){
                        self.addAttacheSubmit=false
                        self.canShowCloudStartLimitGpu=true
                        self.noCanLoadgpuEquipment=false
                }else{
                    //是关机状态就关闭掉“云主机开机状态下只能查询，不能进行加载、卸载操作”提示
                    self.canShowCloudStartLimitGpu=false
                } 
            }else{
                self.noCanLoadgpuEquipment=true
                self.canShowCloudStartLimitGpu=false
            }
        })
    }
    
    //获取gpu类型的透传设备详情列表,并关闭usb,gpu提示信息
    function getFpgaTtequipmentList(serverId){
    	self.showFlag=true
    	self.loadData = true
        self.equipmentList = []
        self.usbFlag=false
        self.noCanLoadusbEquipment=false
        self.noCanLoadgpuEquipment=false
        self.addAttacheSubmit=false
        TransMagaSrv.getFpgaTtequipmentList(serverId).then(function(res){
            if(res&&res.data&&res.data.length>0){
                res.data.forEach(function(item){
                    if(!item.loading){
                        self.equipmentList.push(item)
                    }
                })
                if(self.equipmentList.length>0){
                    self.ttEquipmentDetails = self.equipmentList[0]
                    self.noCanLoadfpgaEquipment = false
                    self.addAttacheSubmit=true
                }else{
                    self.noCanLoadfpgaEquipment = true
                }  
                 //循环遍历当前的所有项目，找到当前选择的serverid的开机状态
                for(var i=0;i<context.vimList.length;i++){
                    if(context.vimList[i].uid==self.serverId){
                        self.currentServer1=angular.copy(context.vimList[i])
                    }
                }
                 //不是关机状态就置灰确认按钮,并显示fpga在开机状态下不能操作的提示信息
                    if(self.currentServer1&&(self.currentServer1.status!='SHUTOFF'&&self.currentServer1.status!='shutoff'&&self.currentServer1.status!='stopped')&&self.deviceType.name.deviceType=="FPGA"){
                        self.addAttacheSubmit=false
                        self.vimStartOrShutOffStatus=true
                        self.noCanLoadfpgaEquipment=false
                }else{
                    //是关机状态就关闭掉“云主机开机状态下只能查询，不能进行加载、卸载操作”提示
                    self.vimStartOrShutOffStatus=false
                } 
            }else{
                self.noCanLoadfpgaEquipment=true
            }
        })
    }
    //设备类型改变事件，影响云主机和设备描述列表
    self.showCompatibilitySelect = true                     
    
    self.choiceDeviceType = function(item){
        self.vimStartOrShutOffStatus=false
        self.canShowCloudStartLimitGpu=false
        self.loadData = false 
        self.equipmentList = []
        if(item.deviceType == "USB"){
            self.showCompatibilitySelect = true 
            self.noCanLoadgpuEquipment = false   
            self.limitUsbDriveType = false
            var serverId=self.TransMagaMsg.instanceName.id;
            getTtequipmentList(serverId);
        }else if(item.deviceType == "GPU"){
            self.showCompatibilitySelect = false
            self.noCanLoadusbEquipment = false   
            self.limitUsbDriveType = false
            var serverId=self.TransMagaMsg.instanceName.id;
            getGpuTtequipmentList(serverId);
            self.addAttacheSubmit=false;
             //遍历所有的虚拟机，如果当前选中的serverid的状态为关机状态则不让点击
             for(var i=0;i<context.vimList.length;i++){
                //如果选择的云主机是开机状态是，就打开提示句"云主机开机状态下只能查询，不能进行加载、卸载操作",并置灰确认按钮
            	if(context.vimList[i].uid==self.serverId&&(context.vimList[i].status!="SHUTOFF"&&context.vimList[i].status!="shutoff"&&context.vimList[i].status!="stopped")){
            		self.currentServer=angular.copy(context.vimList[i])
                    self.addAttacheSubmit=false
                    self.canShowCloudStartLimitGpu=true
            	}
            }
        }
        else if(item.deviceType == "FPGA"){
            self.showCompatibilitySelect = false
            self.noCanLoadusbEquipment = false   
            self.noCanLoadgpuEquipment=false
            self.limitUsbDriveType = false
            var serverId=self.TransMagaMsg.instanceName.id;
            getFpgaTtequipmentList(serverId);
            self.addAttacheSubmit=false
             //遍历所有的虚拟机，如果当前选中的serverid的状态为关机状态则不让点击
            for(var i=0;i<context.vimList.length;i++){
                //如果选择的云主机是开机状态是，就打开提示句"云主机开机状态下只能查询，不能进行加载、卸载操作",并置灰确认按钮
            	if(context.vimList[i].uid==self.serverId&&(context.vimList[i].status!="SHUTOFF"&&context.vimList[i].status!="shutoff"&&context.vimList[i].status!="stopped")){
            		self.currentServer=angular.copy(context.vimList[i])
                    self.addAttacheSubmit=false
                    self.vimStartOrShutOffStatus=true
            	}
            }
        }
    }

    self.getChoiceOne = function(item){
        self.ttEquipmentDetails = item
    }
    let loginData = angular.fromJson(localStorage.$LOGINDATA);
    let regionUid = loginData.regionUid;

    // 处理USB提交的数据
    function usbPostData() {
        let postData = {
            serverId : self.serverId ,
            enterpriseUid : localStorage.enterpriseUid,
            regionUid : regionUid,
            regionKey : localStorage.regionKey,
            serverName :  self.serverName,
            hostName : self.ttEquipmentDetails.host,
            deviceType : self.deviceType.name.deviceType,
            description : self.ttEquipmentDetails.description,
            addressBus : self.ttEquipmentDetails.address_bus,
            addressDevice : self.ttEquipmentDetails.address_device,
            usbBus : self.compatibility.name.compatibility       
        }
        return postData;
    }

    // 处理gpu和fpga提交的数据
    function gpuOrFpgaPostData() {
        let postData = {
            serverId:self.serverId,
            enterpriseUid:localStorage.enterpriseUid,
            regionUid:regionUid,
            regionKey:localStorage.regionKey,
            bus:self.ttEquipmentDetails.id.split(":")[0],
            slot:self.ttEquipmentDetails.id.split(":")[1].split(".")[0],
            function:self.ttEquipmentDetails.id.split(":")[1].split(".")[1],
            gpuId:self.ttEquipmentDetails.id,
            serverName: self.serverName,
            deviceType:self.deviceType.name.deviceType,
            description:self.ttEquipmentDetails.desc,
            host:self.ttEquipmentDetails.host
        }
        return postData;
    }

    // 透传设备初始化数据
    function initRefreshData() {
        $uibModalInstance.close();
        refresh();
    }

    // USB提交请求
    async function addTtEquipment(serverId,postData) {
        let result = await TransMagaSrv.addTtEquipment(serverId,postData).then(function(res){
            initRefreshData();
            return res;
        })
        return result;
    }

    // gpu的请求
    async function addGpuTtEquipment(serverId,postData) {
        let result = await TransMagaSrv.addGpuTtEquipment(serverId,postData).then(function(res){
            initRefreshData();
            return res;
        })
        return result;
    }

    // fpga的请求
    async function addFpgaTtEquipment(serverId,postData) {
        let result = await TransMagaSrv.addFpgaTtEquipment(serverId,postData).then(function(res){
            initRefreshData();
            return res;
        })
        return result;
    }

    // Promise处理的请求
    function promiseConfirm(func) {
        Promise.allSettled([Promise.resolve(func)]).then(data=>{
            if(data[0].value && data[0].value.status == 0) {
                setHostBind();
            }
        });
    }

    self.addTtEquipmentConFirm = function(){
        self.addAttacheSubmit=false;
        let deviceType = self.deviceType.name.deviceType.toLowerCase();
        if(deviceType == "usb"){
            let postData = usbPostData();
            promiseConfirm(addTtEquipment(self.serverId,postData));
        }else if(deviceType == "gpu"){
            let postData = gpuOrFpgaPostData();
            promiseConfirm(addGpuTtEquipment(self.serverId,postData));
        }else if(deviceType == "fpga"){
            let postData = gpuOrFpgaPostData();
            promiseConfirm(addFpgaTtEquipment(self.serverId,postData));
        }
    }
}])

