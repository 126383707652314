
var dataCleanSrvModule = angular.module("dataCleanSrvModule",[]);

dataCleanSrvModule.service("dataCleanSrv",["$http", function($http){
    return {

        // 数据清理列表
        getBackupDataCleanList: function(params){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-data-clean/list",
                params: params
            });
        },
        // 数据清理详情
        getBackupDataClean: function(params){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-data-clean/details/" + params.id
            });
        }
        
    };
}]);