editSeverCtrl.$inject = ["$scope","$rootScope","$uibModalInstance","editData","refresh",'instancesSrv'];
export function editSeverCtrl($scope, $rootScope, $uibModalInstance, editData, refresh, instancesSrv){
    let self = $scope;
    self.submitInValid = false;
    self.disabledFlag = false;
    self.hostName = editData.name;
    self.confirm = function(form) {
        if(form.$valid) {
            var postData = {
                name: self.hostName
            };
            self.disabledFlag = true;
            instancesSrv.editServer(editData.uid, postData).then(function(result) {
                if (result && result.status == 0) {
                    $rootScope.$broadcast("alert-success", 0);
                } else {
                    $rootScope.$broadcast("alert-error", 1);
                }
                $uibModalInstance.close();
                refresh();
            }).finally(()=>{
                self.disabledFlag = false;
            });
        }else {
            self.submitInValid = true;
        }
    }        
}