angular.module("dpdkSrv", [])
    .service("dpdksrv", ["$http",function ($http) {
        return {
            getListApi:function(data){
                return $http({
                    method:"GET",
                    url:`awstack-user/v1/dpdk`,
                    params:data
                })
            },
            getNodeList: function () {
                return $http({
                    method: "GET",
                    url: `awstack-user/v1/enterprises/${localStorage.enterpriseUid}/regions/${localStorage.regionUid}/usable/nodes/list`
                });
            },
            changeStatusAPi:function(data){
                return $http({
                    method:"POST",
                    url:`http://192.168.137.17:8080/awstack-user/v1/dpdk`,
                    params:data
                })
            },
            shutdownAPi: function () {
                return $http({
                    method: "GET",
                    url: `awstack-user/v1/enterprises/${localStorage.enterpriseUid}/nodes/list`
                });
            },
        };
    }]);
