createDatabase.$inject = [];
function createDatabase() {
    return {
        restrict:"E",
        replace:true,
        scope:{
        },
        templateUrl:"js/cvm/database/tmpl/create-database.html",
        link: function(self, elem, attrs, $ngModel) {

        },
        controller:function($rootScope, $scope, $uibModal, $translate, $location, NgTableParams, flavorsSrv, commonFuncSrv, vmFuncSrv, instancesSrv, labelMSrv, utilTool, alertSrv, storageSrv, cvmViewSrv, depviewsrv, cloudDatabaseSrv, createInsSrv){
            let self = $scope;
            self.submitInValid = false;
            self.showPrice = false;// 计费价格的展示   计费开启才显示
            self.storage = {
                isUseLocal: 2,// 启动源 默认是云硬盘启动
            };
            self.poolsInfo_data = {};// 存储池容量展示
            self.domainName = "";
            self.projectName = "";
            self.createInsForm = {
                type:"",
                version:{},
                name:"",
                operateSystem:{},
                image:{},
                admin_pass:"",
                admin_comfirmpass:"",
                cpu:{},
                ram:{},
                selectedFlavor:{},
                flavor:"",
                typeList:[],
                versionList:[],
                operateSystemList:[],
                imageList:[],
                cpuList:[],
                ramList:[],
                flavorList:[],
                storageDeviceSelected:"",
                dataVolumeSize:"",
                sysVolumeSize:"",
                sysVolumeMinSize:"",
                dataDiskArray:[],
                getAvalDisk:false,
                avalDisk:[],
                avalDiskCopy:[],
                noAvalDisk:false,
                systemConfigObj:{},
                paramValueObj:{},
                dataDiskSize:[]
            };
            self.insForm = {
                hostNum:1,
            };
            self.maxDataDisk = {
                default:5
            }
            self.curDataDiskMax = self.maxDataDisk.default;
            self.labelobj = {};
            self.headers = {
                name:$translate.instant('aws.common.name'),
                cpu:"CPU",
                ram:$translate.instant('aws.instances.addinstances.memory'),
                cputopu:$translate.instant('aws.instances.addinstances.cputopu')
            };
            self.tableCols = [
                { field: "radio", title: "",show: true },
                { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true },
                { field: "cpu_field", title: self.headers.cpu,sortable: "cpu",show: true,disable:false },
                { field: "ram_gb_field", title: self.headers.ram,sortable: "ram",show: true,disable:false },
                { field: "cpuTopoArr", title: self.headers.cputopu,sortable: "cputopu",show: true,disable:false }
            ]
            
            // 计费开关开启 显示计费信息
            if($rootScope.billingActive){
                self.showPrice = true;
            }

            // 公共的方法
            localStorage.managementRole != 2 ? self.roleNumber = false : self.roleNumber = true;
            //校验是否有缓存盘
            self = vmFuncSrv.cacheVolume(self,instancesSrv);
            //存储服务
            self = vmFuncSrv.storageFunc(self,instancesSrv,storageSrv);
            //安全组服务
            self = vmFuncSrv.securityFunc(self,instancesSrv);
            //配额服务
            self =  vmFuncSrv.quotaFunc(self,instancesSrv,cvmViewSrv,depviewsrv);

            // 校验项目的配额 获取insProText、coreProText、ramProText、 volumesProText、gigabytesProText 
            self.getproQuotas(self.createInsForm.selectedFlavor);

            // 校验部门的配额 获取insDomText、 coreDomText、ramDomText、volumesDomText、gigabytesDomText  
            self.getdomQuotas(self.createInsForm.selectedFlavor);

            /*----- 基础信息 -------*/
            // 处理默认的项目和默认的部门
            function dealDomainName() {
                self.domainName =  localStorage.domainName =='default' ? $translate.instant("aws.common.defaultDepar") : localStorage.domainName;
                self.projectName = localStorage.projectName == 'admin' ? $translate.instant("aws.common.defaultProject") : localStorage.projectName;
            }
            dealDomainName();

            // 生成随机密码
            // 密码长度为8-16个字符且至少包含一个大写字母，一个小写字母，一个数字和一个特殊字符。特殊字符包括! - @ . ^ _ % , : /
            self.randomPwd = function() {
                const pwd = utilTool.randomPwd(8,16);
                self.createInsForm.admin_pass = pwd;
                self.createInsForm.admin_comfirmpass = pwd;
            }

            // 复制到剪切板
            self.copyToClip = function(copyTxt){
                utilTool.copyToClip(copyTxt);
                alertSrv.set("", $translate.instant("aws.instances.addinstances.copySuccess") ,"success",3000);
            }

            // 选择数据库类型
            self.changeType = function(value){
                self.createInsForm.type = value;
                self.createInsForm.typeList.forEach(item=>{
                    if(item.value == value) {
                        self.createInsForm.versionList = item.typeVersion;
                    }
                })
                self.createInsForm.version = self.createInsForm.versionList[0];
                self.getSystemConfig('45',value);
            }

            // 选择操作系统
            self.changeOperateSystem = function(value){
                self.createInsForm.operateSystem = value;
                self.getImageList(value.name);
                self.dealDefaultFlavor();
            }

            // 处理回显默认的规格
            self.dealDefaultFlavor = function() {
                var cpuFlag = false;
                var ramFlag = false;
                var osDefaultCpu = self.createInsForm.paramValueObj.os_default_cpu*1;//获取默认的cpu
                var osDefaultMemory = parseInt(self.createInsForm.paramValueObj.os_default_memory);//获取默认的ram
                self.createInsForm.cpuList.forEach(item=>{
                    if(parseInt(item.name) == osDefaultCpu) {
                        cpuFlag = true;
                        self.createInsForm.cpu = item;
                        self.createInsForm.ramList = [];
                        self.createInsForm.ramList = self.initCpuRam(self.createInsForm.flavorList , self.createInsForm.ramList, 'ram_gb',parseInt(item.name));
                    }
                })
                self.createInsForm.ramList.forEach(item=>{
                    if(parseInt(item.name) == osDefaultMemory) {
                        ramFlag = true;
                        self.createInsForm.ram = item;
                    }
                })
                // 判断是否回显下拉框是否默认的cpu和ram
                if(cpuFlag && ramFlag) {
                    // 刷新规格
                    self.initFlavorTable(parseInt(self.createInsForm.cpu.name),parseInt(self.createInsForm.ram.name));
                }
            } 

            // 选择镜像
            self.changeImage = function(value){
                self.createInsForm.image = value;
                var systemDiskSize = parseFloat(self.createInsForm.paramValueObj.system_disk_size);// 从操作系统配置项接口中获取默认的系统盘size
                var imageSystemDiskSize = value ? value.size*1 : 0;// 获取镜像中的系统盘的size
                // 比较默认的系统盘和镜像中的系统盘的size  谁值大取谁
                if(systemDiskSize < imageSystemDiskSize) {
                    self.createInsForm.sysVolumeSize = value.size;
                    self.createInsForm.sysVolumeMinSize = value.size;
                }else {
                    self.createInsForm.sysVolumeSize = systemDiskSize;
                    self.createInsForm.sysVolumeMinSize = systemDiskSize;
                }
                // 处理回显默认的三个数据盘的值
                self.createInsForm.dataDiskArray = [];
                if(self.createInsForm.dataDiskSize.length>0) {
                    self.createInsForm.dataDiskSize.forEach(item=>{
                        item = parseInt(item);
                        self.createInsForm.dataDiskArray.push({device:self.storageDeviceListCopy[0],volume:self.createInsForm.avalDisk[0],size: item});   
                    })
                }
                self.allDataVolumeSize();
                self.checkAllVolumPoolFun();
            }

            // 选择cpu
            self.changeCpu = (value) => {
                self.createInsForm.cpu = value;
                self.createInsForm.ramList = [];
                self.initRam(parseInt(value.name));
            }

            // 选择内存
            self.changeRam = (value) => {
                self.createInsForm.ram = value;
                self.initFlavorTable(parseInt(self.createInsForm.cpu.name),parseInt(value.name));
            }

            //选择规格
            self.changeFlavor = (value) => {
                self.createInsForm.selectedFlavor = value;
                self.createInsForm.flavor = value.id;
                self.getPrice(value.vcpus,value.ram_gb);
            }

            // 查询云数据库类型
            self.getServerType = async function(instanceType,instanceTypeCategory) {
                var params = {
                    instanceType: instanceType,
                    instanceTypeCategory: instanceTypeCategory ? instanceTypeCategory : ''
                }
                self.createInsForm.typeList = [];
                self.createInsForm.versionList = [];
                await cloudDatabaseSrv.getServerType(params).then(result=>{
                    if(result && result.data && angular.isArray(result.data)) {
                        self.createInsForm.typeList = self.dealTypeAndVersion(result.data);
                        self.changeType(self.createInsForm.typeList[0].value)
                    }
                })
            }

            // 处理数据库类型和版本
            self.dealTypeAndVersion = function(list) {
                var map = {}; 
                var destList = [];
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (!map[item.instanceTypeCategory]) {
                        destList.push({
                            id: item.id,
                            name: item.instanceTypeCategory,
                            value: item.instanceTypeCategory,
                            instanceType: item.instanceType,
                            instanceTypeCategory: item.instanceTypeCategory,
                            typeVersion: [{name:item.typeVersion,value:item.typeVersion}]
                        });
                        map[item.instanceTypeCategory] = item;
                    } else {
                        for (var j = 0; j < destList.length; j++) {
                            var temp = destList[j];
                            if (temp.instanceTypeCategory == item.instanceTypeCategory) {
                                temp.typeVersion.push({name:item.typeVersion,value:item.typeVersion})
                                break;
                            }
                        }
                    }
                };
                return destList;
            }

            // 查询操作系统
            self.getSystemConfig = function(parentId,type) {
                var params = {
                    parentId: parentId
                }
                cloudDatabaseSrv.getSystemConfig(params).then(result=>{
                    self.createInsForm.systemConfigObj = {};
                    self.createInsForm.paramValueObj = {};
                    self.createInsForm.operateSystemList = [];
                    if(result && result.data && angular.isArray(result.data)) {
                        result.data.forEach(item=>{
                            if(item.paramName == type) {
                                self.createInsForm.systemConfigObj = item;
                            }
                        })
                        self.createInsForm.paramValueObj = JSON.parse(self.createInsForm.systemConfigObj.paramValue);
                        if(self.createInsForm.paramValueObj.data_disk_size) {
                            // 数据库类型为oracle时获取默认的数据盘
                            self.createInsForm.dataDiskSize = self.createInsForm.paramValueObj.data_disk_size.split(',');
                        }else {
                            // 数据库类型为sqlServer时没有默认的数据盘 并且清除的数据盘的数据  
                            self.createInsForm.dataDiskArray = [];
                            // 当数据盘的默认参数值不存在时 清除默认三块数据盘的值
                            self.createInsForm.dataDiskSize = [];
                        }
                        var systemList = self.createInsForm.paramValueObj.version.split(',');
                        systemList.forEach(item=>{
                            self.createInsForm.operateSystemList.push({name:item,value:item});
                        })
                        self.changeOperateSystem(self.createInsForm.operateSystemList[0]);
                    }
                })
            }

            // 获取镜像下拉列表的数据
            self.getImageList = function(pattern){
                self.createInsForm.imageList = []
                let params = {
                    status: 'active', // 只查询状态为active的镜像
                    pageNum: 1,
                    pageSize: 0
                }
                cloudDatabaseSrv.getImageByOs(pattern,params).then(result=>{
                    if(result && result.data && angular.isArray(result.data)) {
                        self.createInsForm.imageList = result.data;
                        self.changeImage(self.createInsForm.imageList[0]);
                        if(self.createInsForm.imageList.length==0){
                            // 没有镜像就清空镜像的值
                            self.createInsForm.image = "";
                            // 没有镜像就取系统盘的默认值
                            if(self.createInsForm.image == ""){
                                var systemDiskSize = parseFloat(self.createInsForm.paramValueObj.system_disk_size);// 从操作系统配置项接口中获取默认的系统盘size
                                self.createInsForm.sysVolumeSize = systemDiskSize;
                                self.createInsForm.sysVolumeMinSize = systemDiskSize;
                            }
                        }
                    }
                })
            }

            // 获取规格列表
            self.getFlavors = async() =>{
                await flavorsSrv.getFlavors().then(result=>{
                    self.loadFlavorData = result ? true : "";
                    if(result && result.data && angular.isArray(result.data)) {
                        self.successFunc(result.data)
                    }
                })
            }

            async function getInitData(){
                await self.getFlavors();
                await self.getServerType('db_inst');
            }
            getInitData();

            // 处理规格表格的数据
            self.successFunc = (data) =>{
                data.forEach(item=>{
                    item.ram_gb = (item.ram / 1024).toFixed(1);
                    if(item.ram_gb.split(".")[1] == "0") {
                        item.ram_gb = item.ram_gb.split(".")[0];
                    }
                    item.ram_gb = Number(item.ram_gb);
                    item.ram_gb_field = `${item.ram_gb}G`;
                    item.cpu_field = `${item.vcpus}${$translate.instant('aws.instances.conf.core')}`;
                    item.cpuTopoArr = [];
                    if(item.sockets) {
                        item.cpuTopoArr.push({ name:$translate.instant('aws.cloudDatabase.createDatabase.flavorTable.sockets'), value:item.sockets });
                    }
                    if(item.cores) {
                        item.cpuTopoArr.push({ name:$translate.instant('aws.cloudDatabase.createDatabase.flavorTable.cores'), value:item.cores });
                    }
                    if(item.threads) {
                        item.cpuTopoArr.push({ name:$translate.instant('aws.cloudDatabase.createDatabase.flavorTable.threads'), value:item.threads });
                    }
                });
                self.createInsForm.flavorList = data;
                self.initCpu(data);
            }

            // cpu下拉列表的初始数据
            self.initCpu = (data) => {
                self.createInsForm.cpuList = self.initCpuRam(data, self.createInsForm.cpuList, 'vcpus');
                self.changeCpu(self.createInsForm.cpuList[0]);
            }

            // 内存下拉列表的初始数据
            self.initRam = (core) => {
                self.createInsForm.ramList = self.initCpuRam(self.createInsForm.flavorList , self.createInsForm.ramList, 'ram_gb',core);
                self.changeRam(self.createInsForm.ramList[0]);
            }

            // 初始化规格表格
            self.initFlavorTable = (cpu,ram) => {
                let list = [];
                self.createInsForm.flavorList.forEach(item=>{
                    if(cpu == item.vcpus && ram == item.ram_gb) {
                        list.push(item);
                    }
                })
                self.tableParams = new NgTableParams({ count: 9999 }, { counts: [], dataset: list});
                self.changeFlavor(list[0]);
            }

            // 处理cpu核内存的下拉列表展示数据
            self.initCpuRam = (data,list,key,core) => {
                let arr = [];
                data.map(item=>{
                    if(key == 'vcpus') {
                        if(!list.includes(item[key])) {
                            list.push(item[key]);
                        }
                    }else {
                        if(core && core == item.vcpus) {
                            if(!list.includes(item[key])) {
                                list.push(item[key]);
                            }
                        }
                    }
                    return item
                })
                list.sort((a,b)=>{
                    return a - b;
                })
                list.forEach((item,index)=>{
                    if(key == 'vcpus') {
                        arr.push({ name: `${item}${$translate.instant('aws.instances.conf.core')}`, id: index})
                    }else {
                        arr.push({ name: `${item}GB`, id: index})
                    }
                })
                return arr;
            }

            /*----  网络设置   ----*/
            // 获取私网
            self.getNetWorkList = () => {
                self = commonFuncSrv.setAssignIpFun(self, "dataBaseNet", "databaseForm", "private");
            }
            self.getNetWorkList();
            
            // 获取创建私有网络
            self.easyNetwork= function(){
                var scope = self.$new();
                $uibModal.open({
                    animation: true,
                    templateUrl: "js/easyReform/tmpl/network.html",
                    controller: "easyNetworkCtrl",
                    scope:scope,
                    resolve:{
                        type:function(){
                            return true
                        }
                    }
                });
            };

            // 新建私有网络后，重新获取网络数据
            self.$watch(()=>{
                return commonFuncSrv.reGetNetList;
            },(reGetNetList)=>{
                if(reGetNetList) {
                    self.getNetWorkList();
                    commonFuncSrv.reGetNetList = false;
                }
            },true)

            // 安全组
            self.getSecurityList = () => {
                vmFuncSrv.securityFunc(self,instancesSrv);
            }
            self.getSecurityList();
            self.getSecurity();

            // 查看安全组规则详情
            self.viewSecRule = (securityGroup) => {
                self.viewSecRuleModal = $uibModal.open({
                    animation: self.animationsEnabled,
                    templateUrl: "js/shortcuts/instances/tmpl/secRuleDetail.html",
                    controller: "viewSecRuleCtrl",
                    resolve: {
                        context: () => self,
                        securityGroup: () => securityGroup
                    }
                })
            }

            /*---- 存储设置  ----*/
            // 获取系统盘下拉列表的数据
            self.getStorage = function(){
                instancesSrv.getStorage().then(function(res){
                    if(res && res.data && angular.isArray(res.data)){
                        self.storageDeviceList = res.data;
                        self.storageDeviceListCopy = angular.copy(self.storageDeviceList);
                        self.storageDeviceListCopy.push({"disPlayName":$translate.instant('aws.action.selectExistVol'),id:"has"}) ;
                        self.createInsForm.storageDeviceSelected = self.createInsForm.storageDeviceSelected?self.createInsForm.storageDeviceSelected:self.storageDeviceList[0];
                        self.poolInfo(self.createInsForm.storageDeviceSelected,self.pollInfoCallBackFunc);
                    }
                })
            };
            self.getStorage();

            // 获取所有加载的云硬盘
            self.getVallDisk = function(){
                self.createInsForm.avalDisk = [];
                self.createInsForm.avalDiskCopy = [];
                self.createInsForm.getAvalDisk = false;// 是否有数据盘 true是有 false是没有
                instancesSrv.getVallDisk().then(function(res){
                    if(res && res.data && angular.isArray(res.data)){
                        res.data = res.data.filter( item=> (item.status == "available" || item.status == "in-use"));
                        res.data = res.data.filter(item => !item.magrationing) //卷迁移中卷不能进行任何操作
                        self.createInsForm.getAvalDisk = true;
                        self.createInsForm.avalDisk = res.data;
                        self.createInsForm.avalDiskCopy = angular.copy(res.data);
                    }
                })
            }
            self.getVallDisk();

            // 系统盘的选择
            self.changeStorage = function(value){
                // 重新获取当前存储的容量值
                self.poolInfo(value,self.pollInfoCallBackFunc);
                // 校验系统盘 input的容量是否在当前储存的可使用范围
                self.changesysVolumeSize(self.createInsForm.sysVolumeSize);
            }
            
            // 添加数据盘
            self.addDataVolumes = function(){
                // 已添加盘的数量
                var dataDiskArrayLength = self.createInsForm.dataDiskArray.length;
                // 当已添加盘的数量大于最大值(5个)时不能再添加；
                if((dataDiskArrayLength >= self.curDataDiskMax) || !self.createInsForm.avalDiskCopy || !self.createInsForm.getAvalDisk) return;
                self.createInsForm.avalDisk = self.filterVolumeSelects();
                self.createInsForm.dataDiskArray.push({device:self.storageDeviceListCopy[0],volume:self.createInsForm.avalDisk[0]})
                self.allDataVolumeSize();
            }

            // 删除数据盘
            self.delDataVolumes = function(i){
                self.createInsForm.dataDiskArray.splice(i,1);
                self.createInsForm.avalDisk = self.filterVolumeSelects();
                self.allDataVolumeSize();
            }

            // 系统盘的input的输入 size为系统盘的大小
            self.changesysVolumeSize = function(size){
                // 校验存储资源池
                self.checkVolumFun(size * self.insForm.hostNum);
                // 校验云硬盘相关的配额
                self.testVolumes();
                // 系统盘 + 数据盘校验资源池
                self.checkAllVolumPoolFun();
            }

            // 校验新建云数据库时系统盘所占用的配额
            self.testVolumes =function(){
                var blackVolumeSize = 0;
                var bootOfVoule = 1;//系统盘默认是1个
                self.getBalkvolume().map(item =>{
                    blackVolumeSize = blackVolumeSize + Number(item.size||0);
                })
                blackVolumeSize = blackVolumeSize + Number(self.createInsForm.sysVolumeSize || 0);
                // 校验存储池的使用量
                self.testProVolQuotaFunc(self.insForm.hostNum,bootOfVoule+self.getBalkvolume().length,blackVolumeSize);
                self.testDomVolQuotaFunc(self.insForm.hostNum,bootOfVoule+self.getBalkvolume().length,blackVolumeSize);
            }

            // 获取所有添加的空白盘（数据盘）  
            self.getBalkvolume = function(){
                var getBalkvolumeArray = [];
                getBalkvolumeArray = self.createInsForm.dataDiskArray.filter(item=> item.device.id != "has");
                return getBalkvolumeArray;
            }

            // 数据盘的类型选择
            self.changeDataVolumeType = function(val,index){
                if(val.id=="has"){
                    if(self.createInsForm.avalDisk.length == 0) {
                        self.createInsForm.dataDiskArray[index]={device:self.storageDeviceListCopy[0],volume:self.createInsForm.avalDisk[0],size:self.createInsForm.dataDiskArray[index].size};
                    }else {
                        self.createInsForm.dataDiskArray[index].size = self.createInsForm.dataDiskArray[index].volume.size;
                    }
                }
                self.createInsForm.avalDisk = self.filterVolumeSelects();
                self.allDataVolumeSize();
            }

            // 已有数据盘的change事件
            self.changeDataVolume = function(val,index){
                self.createInsForm.dataDiskArray[index].size = val.size;
                self.createInsForm.avalDisk = self.filterVolumeSelects();
                self.allDataVolumeSize(self.createInsForm.sysVolumeSize);
            }

            // 切换数据盘到已有盘 更新下拉框可选数据
            self.filterVolumeSelects = function(){
                self.createInsForm.noAvalDisk = false;
                var avalDisk = angular.copy(self.createInsForm.avalDiskCopy);
                self.createInsForm.dataDiskArray.map(chk => {
                    avalDisk = avalDisk.filter(item=>{
                        return ((chk.device.id == "has" && item.id != chk.volume.id) || chk.device.id != "has")
                    })
                })
                if(avalDisk.length==0){
                    self.createInsForm.noAvalDisk = true;
                }
                self.createInsForm.dataDiskArray.map(item =>{
                    if(!item.device.id) {
                        item.volume = avalDisk[0]
                    };
                })
                return avalDisk;
            }

            // 获取所有数据盘的大小
            self.allDataVolumeSize = function(){
                var size = 0;
                self.createInsForm.dataDiskArray.map((item,index) => {
                    item.size ? size += Number(item.size) : "";
                    self.createInsForm["avalDisk"+index] = angular.copy(self.createInsForm.avalDisk);
                    var ishasvolume = false 
                    self.createInsForm["avalDisk"+index].map(vol =>{
                        if (vol.id == item.volume.id) {
                            ishasvolume = true;
                        }
                    })
                    if(!ishasvolume && item.device.id == 'has'){
                        self.createInsForm["avalDisk"+index].unshift(item.volume);
                    }
                });
                self.createInsForm.dataVolumeSize = size;
                self.testVolumes();
                self.checkAllVolumPoolFun()
            }

            // 校验存储资源池
            self.pollInfoCallBackFunc = function() {
                self.checkVolumFun(self.createInsForm.sysVolumeSize*1,self.insForm.hostNum);
            }

            // 校验存储容量
            self.checkAllVolumPoolFun = function() {
                // 更新价格
                self.getPrice(self.createInsForm.selectedFlavor.vcpus,self.createInsForm.selectedFlavor.ram_gb);
                self.exceedPools = false;
                self.exceedPoolsText = "";
                let size_name = {};
                let array = self.createInsForm.dataDiskArray.filter(item => item.device.id != "has");
                array.map(val => {
                    var data = self.poolDataTranslate(val.device);
                    if(!size_name[val.device.volumeTypeId]){
                        size_name[val.device.volumeTypeId] = {
                            size:0,
                            inUsed:Number(data[0]),
                            total:Number(data[1]),
                            name:val.device.disPlayName
                        }
                    } 
                })
                if(self.storage.isUseLocal ==2 && self.createInsForm.storageDeviceSelected && size_name[self.createInsForm.storageDeviceSelected.volumeTypeId]){
                    size_name[self.createInsForm.storageDeviceSelected.volumeTypeId].size += Number(self.createInsForm.sysVolumeSize||0)
                }
                array.map(item => {
                    size_name[item.device.volumeTypeId].size += Number(item.size || 0);
                })
                for (var value in size_name){
                    if(size_name[value].size*self.insForm.hostNum + size_name[value].inUsed >  size_name[value].total){
                        self.exceedPools = true;
                        self.exceedPoolsText = size_name[value].name
                        break;
                    }
                }
            };

            // 计算价格
            self.getPrice = function(cpuCount,memorySize){
                if(!$rootScope.billingActive || !cpuCount || !memorySize ){
                    return ;
                }
                var postData ={
                    "region":localStorage.regionName?localStorage.regionName:"default",
                    "cpuCount":cpuCount,
                    "memorySize":memorySize
                } 
                createInsSrv.getPrice(postData).then(function(data){
                    if(data && !isNaN(data.data)){
                        var price = Number(data.data) ;
                        var postdata  ={
                            "region":localStorage.regionName?localStorage.regionName:"default",
                            "volumeSize":0
                        }
                        let dataVolumeSize = self.createInsForm.dataVolumeSize ? Number(self.createInsForm.dataVolumeSize) : 0;
                        let sysVolumeSize = self.createInsForm.sysVolumeSize ? Number(self.createInsForm.sysVolumeSize) : 0; 
                        postdata.volumeSize = dataVolumeSize;
                        if(self.storage.isUseLocal==2){
                            postdata.volumeSize += sysVolumeSize;
                        }
                        if(postdata.volumeSize >0){
                            createInsSrv.getVlomesPrice(postdata).then(function(res){
                                if(res && !isNaN(res.data)){
                                    self.price = (price*10*10 + Number(res.data)*10*10)/100;
                                    self.priceFix = (self.price*10*10 * Number(self.insForm.hostNum))/100;
                                    self.totalPrice = (self.price*10*10 * 30 * 24 * Number(self.insForm.hostNum))/100;
                                    if($rootScope.billingActive){
                                        self.showPrice = true
                                    }
                                }
                            })
                        }else{
                            self.price = price;
                            self.priceFix = (self.price * Number(self.insForm.hostNum)).toFixed(2);
                            self.totalPrice = (self.price * 30 * 24 * Number(self.insForm.hostNum)).toFixed(2);
                            if($rootScope.billingActive){
                                self.showPrice = true
                            }
                        }    
                    }else{
                        self.price ="N/A";
                        self.priceFix =  "N/A";
                        self.totalPrice = "N/A";
                        if($rootScope.billingActive){
                            self.showPrice = true
                        }
                    }
                })  
            }

            // 处理ipv4和ipv6的ip地址数据
            function dealIpData() {
                let fixedIp = ''
                if(self.dataBaseNet.ipVersion == 4) {
                    if(Object.keys(self.dataBaseNet.v4List).length>0) {
                        fixedIp = self.dataBaseNet.v4List.fixedIp;
                    }
                }else {
                    if(Object.keys(self.dataBaseNet.v6List).length>0) {
                        fixedIp = self.dataBaseNet.v6List.fixedIp;
                    }
                }
                return fixedIp;
            }

            // 处理创建云数据库提交的字段
            function dealParams() {
                // 云数据库创建必填字段
                let params = {
                    instanceType:'db_inst',// inst--云主机  db_inst--数据库云主机
                    instanceTypeCategory: self.createInsForm.type,// 数据库类型
                    typeVersion: self.createInsForm.version.value,// 数据库版本
                    name: self.createInsForm.name,// 数据库名称
                    image_id: self.createInsForm.image.imageUid,// 镜像
                    os_type: self.createInsForm.image.os_type,// 操作系统
                    admin_pass: self.createInsForm.admin_pass,// 密码
                    flavor: self.createInsForm.flavor,// 规格
                    network_id: self.dataBaseNet.selectedNet.id,// 网络
                    security_id:self.securities.selected.id,// 安全组
                    volumeSize: Number(self.createInsForm.sysVolumeSize),// 系统盘的容量
                    volume_type: self.createInsForm.storageDeviceSelected.volumeTypeId//系统盘类型
                };
                params = Object.assign(params,notRequiredParams(params),defaultParams(params));
                return params;
            } 

            // 云数据库创建的非必填字段
            function notRequiredParams(params){
                // 是否勾选了指定子网
                if(self.dataBaseNet.assignSub) {
                    params.subnet_id = self.dataBaseNet.selectedSubnet.id;
                }
                // 是否勾选了指定ip地址
                if(self.dataBaseNet.assignIP) {
                    params.fixed_ip = dealIpData();
                }
                // 处理存储设置
                var dataVolumes = [];
                self.createInsForm.dataDiskArray.map(item=>{
                    if(item.device.id == 'has') {
                        dataVolumes.push({dataVolumeId:item.volume.id});
                    }else{
                        dataVolumes.push({dataVolumeType:item.device.volumeTypeId,dataVolumeSize:item.size})
                    }
                })
                params.dataVolumes = dataVolumes;
                return params
            }

            // 云数据库复用云主机的创建接口  默认字段 传初始值
            function defaultParams(params) {
                params.count = self.insForm.hostNum;// 数量
                params.keypair_id = "";// 密钥
                params.use_local = false;// 是否本地盘启动，默认值为false
                params.hostname = "";// 云主机主机名
                params.groupId = "";// 云主机组id
                params.availability_zone = "";// 可用域
                params.node = "";// 指定节点
                params.port = "";
                params.vgpuType = "";
                params.vgpuId = "";
                params.cloud_security = "";
                params.block_device_mapping = {};
                params.cloud_security = "";
                params.coc = "";
                params.arch = "";
                return params;
            }

            // 新建
            self.createConfirm = function(form){
                if(form.$valid) {
                    let params = dealParams();
                    confirmData(params);
                }else {
                    self.submitInValid = true;
                }
            }

            async function confirmData(params) {
                const result = await createServer(params);
                if(result && result.status == 0) {
                    await labelUpdate(result);
                }
                await locationBack();
            }

            async function createServer(params) {
                const res = await instancesSrv.createServer(params);
                return res;
            }

            async function labelUpdate(result) {
                let newLableList = [];
                if(self.labelobj.labelArr.length == 10){
                    newLableList = self.labelobj.labelArr
                }else {
                    newLableList = self.labelobj.labelArr.filter((item,index)=>{
                        return index != self.labelobj.labelArr.length - 1 
                    })
                }
                if(newLableList.length != 0){
                    // 标签
                    const valueArr = newLableList.map(item=>{
                        const arr = self.labelobj.labelList.find(items=>items.value == item.value);
                        arr && (item.id = arr.id)
                        return {
                            id: item.id || '',
                            name: item.value,
                            description: ''
                        }
                    })
                    const config = {}
                    result.data.forEach(key => {
                        config[key] = valueArr;
                    })
                    await labelMSrv.instancesLabelUpdate(config)
                }
            }

            async function locationBack() {
                $location.url("/cvm/cloudDatabase");
                self.$emit("newDatabase");
            }
        }
    }
}

export { createDatabase };