changeLabelAlertCtrl.$inject = ["$scope","$rootScope",'$uibModalInstance','context','NgTableParams',"activeItem","labelMSrv","refreshIns", "$translate"];
export function changeLabelAlertCtrl($scope, $rootScope ,$uibModalInstance,context,NgTableParams,activeItem,labelMSrv,refreshIns,$translate){
    let self = $scope;
    self.maxLength = false;
    self.tableList = [];
    // 验证是否通过
    self.isVerifyPss = false;
    const label = activeItem[0].label;
    if(label){
        for (const key in label) {
            self.tableList.push({
                id: key,
                value: label[key],
                isUpdate: true,
                key: key
            })
        }
        self.maxLength = self.tableList.length == 10
    }
    self.tableTitle = {
        label:$translate.instant("aws.overview.label"),
        operation:$translate.instant("aws.workflow.action")
    }
    function refreshlist(){
        self.tableParams = new NgTableParams({ count: 10 }, { counts: [], dataset: self.tableList });
    }

    refreshlist()
    self.maxLengthLabel = false
    // 改变列表
    self.changeArr = function(type, index){
        const length = self.tableList.length
        if(type == '+') {
            if(length == 10){
                self.maxLengthLabel = true
                return
            }
            let key = 0;
            if(length != 0){
                key = Number(self.tableList[length - 1].key) + 1;
            }
            self.tableList.push({id:'', value: '', isUpdate: false, key})
        } else if (type == '-') {
            self.tableList = self.tableList.filter((item, i) => index != i);
            self.maxLengthLabel = false
        }
        self.maxLength = self.tableList.length == 10;
        refreshlist();
    }
    
    // 改变状态并进行校验
    self.changeIsShow = function(status, index, value, labelForm, name) {
        self.tableList[index].status = '';
        self.tableList.forEach((item, i) => {
            if(index == i) {
                item.isUpdate = !status;
            }
        })
    }
    
    self.updateLabel = function (labelForm) {
        self.isVerifyPss = false;
        if(labelForm && labelForm.$valid){
            const labelList = self.tableList.map(item => {
                return {
                    id: item.id || '',
                    name: item.value,
                    description: ''
                }
            })

            const config = {
                [activeItem[0].uid]: labelList
            }
            
            labelMSrv.instancesLabelUpdate(config).then((res)=>{
                if(res.msg == 'OK'){
                    refreshIns()    
                }
            })
            $uibModalInstance.close();  
        } else {
            self.isVerifyPss = true;
        }
    }  
}