detailMigrationRecordTpl.$inject = [];
function detailMigrationRecordTpl () {
    return {
        restrict:"E",
        replace:true,
        scope:{
            insUid: "="
        },
        templateUrl: "js/cvm/instances/tmpl/detail-migration-record-tpl.html",
        link:function(self, elem, attrs, $ngModel) {

        },
        controller: function($scope,instancesSrv,NgTableParams) {
            let self = $scope;
            // 迁移记录分页
            self.migration_record = {
                page:1,
                size:10,
                total:0
            }
            self.changeNowPage = function({pageNum, pageSize}){
                self.migration_record.page = pageNum;
                self.migration_record.size = pageSize;
                self.getLableList()
            }
            self.getLableList = function (){
                // 迁移记录
                instancesSrv.getMigration(self.insUid, self.migration_record.page, self.migration_record.size).then(res=>{
                    if(res && res.msg == 'OK'){
                        self.migration_record.total = res.total;
                        self.migrationTable = new NgTableParams({
                            count: 999999
                        }, {
                            counts: [],
                            dataset: res.data
                        });
                    }
                })
            }
            self.getLableList()
        }
    }
}

export {detailMigrationRecordTpl};