
addBackupJobCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","$location","$routeParams","$uibModalInstance","initBackupJobTable","TableCom","editData","type", "$filter"]
function addBackupJobCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,$location,$routeParams,$uibModalInstance,initBackupJobTable,TableCom,editData,type,$filter) {
	var self = $scope;
    self.advSetting = false;
    self.FormValid = false;
    self.modelType = type;
    self.commitBtn = true;
    // 校验参数即可
    self.checkParmas = {
        weekError: false
    }
    /**
     * 初始化策略周期  type定义根据爱数规则
     * 2：天，3：周，4：月，5：年
     */
    self.strategyCycle=[2,3,4,5];
    // 页面操作校验提示集合
    self.errorMessage = [];
    self.monthDayList = [{"name":"1",value:1}, {"name":"2",value:2}, {"name":"3",value:3}, {"name":"4",value:4}, 
                        {"name":"5",value:5}, {"name":"6",value:6}, {"name":"7",value:7}, {"name":"8",value:8}, 
                        {"name":"9",value:9}, {"name":"10",value:10}, {"name":"11",value:11}, {"name":"12",value:12},
                        {"name":"13",value:13}, {"name":"14",value:14}, {"name":"15",value:15}, {"name":"16",value:16},
                        {"name":"17",value:17}, {"name":"18",value:18}, {"name":"19",value:19}, {"name":"20",value:20},
                        {"name":"21",value:21}, {"name":"22",value:22}, {"name":"23",value:23}, {"name":"24",value:24},
                        {"name":"25",value:25}, {"name":"26",value:26}, {"name":"27",value:27}, {"name":"28",value:28},
                        {"name":"20",value:29}, {"name":"30",value:30}, {"name":"31",value:31},]
    // 初始化月份
    self.monthList = [{"label":"1",value:1}, {"label":"2",value:2}, {"label":"3",value:3}, {"label":"4",value:4}, 
                        {"label":"5",value:5}, {"label":"6",value:6}, {"label":"7",value:7}, {"label":"8",value:8}, 
                        {"label":"9",value:9}, {"label":"10",value:10}, {"label":"11",value:11}, {"label":"12",value:12}]
    // 计算每月天数
    self.dayListFun = dayListFun;
    function dayListFun(type){
        var month = self.fromData.strategy.params_years.month.value;
        if(month < 7 || month == 7) {
            if(month == 2) {
                self.dayList = computeDay(28);
            }else{
                if(month%2 ==0) {
                    self.dayList = computeDay(30);
                }else{
                    self.dayList = computeDay(31);
                }
            }
        }else{
            if(month%2 ==0) {
                self.dayList = computeDay(31);
            }else{
                self.dayList = computeDay(30);
            }
        }
        if(type == 'chang') {
            self.fromData.strategy.params_years.day = self.dayList[0];
        }
    }
    //计算日期
    self.computeDay = computeDay;
    function computeDay(day){
        var  dayList = [];
        for(var i=0; i< day; i++){
            var param = {
                label: i+1,
                value: i+1
            }
            dayList.push(param);
        }
        return dayList;
    }
    /**
     * 初始化执行时间
     */
    self.hour = ["00","01","02","03","04","05","06","07","08","09","10"
                    ,"11","12","13","14","15","16","17","18","19","20","21","22","23",];
	self.minute = ["00","01","02","03","04","05","06","07","08","09","10"
                    ,"11","12","13","14","15","16","17","18","19","20"
                    ,"21","22","23","24","25","26","27","28","29","30"
                    ,"31","32","33","34","35","36","37","38","39","40"
                    ,"41","42","43","44","45","46","47","48","49","50"
                    ,"51","52","53","54","55","56","57","58","59"];
    // 初始化星期列表
    self.weekList = [
        {"label": $translate.instant("aws.backupService.variable.week." + 1),"value": 1, checked: true},//周一
        {"label": $translate.instant("aws.backupService.variable.week." + 2),"value": 2, checked: false},//周二
        {"label": $translate.instant("aws.backupService.variable.week." + 3),"value": 3, checked: false},//周三
        {"label": $translate.instant("aws.backupService.variable.week." + 4),"value": 4, checked: false},//周四
        {"label": $translate.instant("aws.backupService.variable.week." + 5),"value": 5, checked: false},//周五
        {"label": $translate.instant("aws.backupService.variable.week." + 6),"value": 6, checked: false},//周六
        {"label": $translate.instant("aws.backupService.variable.week." + 7),"value": 7, checked: false}//周日
    ]
	self.isrunTimeshow=false;
	self.focusTime = focusTime;
    self.clickTimes = clickTimes;
    //移出移入触发事件
	function focusTime(type){
		self.isrunTimeshow=type;
	}
    // 点击触发事件
    function clickTimes(position, data, type, value){
        if(position == "A"){
            if(type == 'hour_a'){
                self.fromData.runTime.hour_a = data;
            }else if(type == 'minute_a'){
                self.fromData.runTime.minute_a = data;
            }
            self.fromData.runTime.date = self.fromData.runTime.hour_a + ':' + self.fromData.runTime.minute_a;
        }else if (position == "B") {
            //流量控制 begin
            if(type == 'hour_a'){
                data.speedLimitTime.beginTime.hour_a = value;
            }else if(type == 'minute_a'){
                data.speedLimitTime.beginTime.minute_a = value;
            }
            data.speedLimitTime.beginTime.date = data.speedLimitTime.beginTime.hour_a + ':' + data.speedLimitTime.beginTime.minute_a;
        }else if (position == "C") {
            //流量控制 end
            if(type == 'hour_a'){
                data.speedLimitTime.endTime.hour_a = value;
            }else if(type == 'minute_a'){
                data.speedLimitTime.endTime.minute_a = value;
            }
            data.speedLimitTime.endTime.date = data.speedLimitTime.endTime.hour_a + ':' + data.speedLimitTime.endTime.minute_a;
        }
    }
    // 初始化 备份方式
    self.backupTypeList = [
        {
            label: $translate.instant("aws.backupService.backupJob.backupType.1"),//完全备份
            value: 1
        },
        {
            label: $translate.instant("aws.backupService.backupJob.backupType.2"),//增量备份
            value: 2
        }
    ]
    initFromData();
    function initFromData(){
        // 初始化总参数
        self.fromData = {
            // 任务名称
            jobName:"",
            // 备份方式
            backupType: self.backupTypeList[0],
            // 策略
            strategy:{
                type: self.strategyCycle[0],
                params:"",
                params_day:1,   //天  参数
                params_week:[1],
                params_month: [],//月 参数
                params_month_lastDay: false,//月 若当月无选定日期则在最后一天触发  为true  传参32
                params_years: {
                    month:self.monthList[0],
                    day: ""
                },//年   [0][1]
            },
            // 流量控制
            flowControl:{
                EEE_FLOW_CONTROL_ENABLE: 0,  //是否开启流量控制 0/1
                EEE_FLOW_CONTROL_CONFIG: "", // 流量控制参数
                data: [],//前台参数
            },
            runTime:{
                hour_a: "00",//小时
                minute_a: "00",//分钟
                date: "00:00",//页面展示 00:00
            },
            // 0/1 永久增量 
            EEE_PERMANENT_INCREMENT: false,
            // 是否开始并发备份数
            EEE_BACKUP_CHANNEL_CONFS: false,
            // 并发控制数最小为2，最大为10
            EEE_TRAVERSAL_THREAD: 2,
            // 重试开关
            EEE_VM_AUTO_RESTART: false,
            // 最大重试次数 1~5
            EEE_AUTO_RESTART_COUNT:1,
            // 重试等待时间 1~30
            EEE_AUTO_RESTART_INTERVAL: 1,
        }
        if(self.modelType == 'edit'){
            // 编辑
            // 任务名称
            self.fromData.jobName = editData.jobName;
            // 备份方式
            self.backupTypeList.forEach(x => {
                if(x.value == editData.backupType) {
                    self.fromData.backupType = x;
                }
            });
            // 策略规则
            echoStrategyAdvParam();
            // 执行时间
            var runTimeDate = new Date(editData.runTime);
            var hours = runTimeDate.getHours();
            var minutes = runTimeDate.getMinutes();
            self.fromData.runTime.hour_a = hours < 10 ? "0" + hours : hours;
            self.fromData.runTime.minute_a = minutes < 10 ? "0" + minutes : minutes;
            self.fromData.runTime.date = self.fromData.runTime.hour_a + ":" + self.fromData.runTime.minute_a;

            // 高级功能 默认打开
            self.advSetting = true;
            
            var jobAdvParam = JSON.parse(editData.jobAdvParam);
            jobAdvParam.forEach(x => {
                // 并发备份数 开关
                if(x.key == "EEE_IS_USE_THREAD") {
                    self.fromData.EEE_BACKUP_CHANNEL_CONFS = x.value == '0' ? false : true;
                }
                // 并发备份数 数量
                if(x.key == "EEE_USE_THREAD_NUM") {
                    self.fromData.EEE_TRAVERSAL_THREAD = x.value;
                }
                // 永久增量备份
                if(x.key == "EEE_PERMANENT_INCREMENT") {
                    self.fromData.EEE_PERMANENT_INCREMENT = x.value == '0' ? false : true;
                }
                // 并发备份数 开关
                if(x.key == "EEE_VM_AUTO_RESTART") {
                    self.fromData.EEE_VM_AUTO_RESTART = x.value == '0' ? false : true;
                }
                // 备份自动重试 -- 重试最大次数
                if(x.key == "EEE_VM_AUTO_RESTART_COUNT") {
                    self.fromData.EEE_AUTO_RESTART_COUNT = x.value == 0 ? "":x.value;
                }
                // 备份自动重试 -- 重试等待时间
                if(x.key == "EEE_VM_AUTO_RESTART_INTERVAL") {
                    self.fromData.EEE_AUTO_RESTART_INTERVAL = x.value == 0 ? "":x.value;
                }
                // 流量控制 开关
                if(x.key == "EEE_FLOW_CONTROL_ENABLE") {
                    if(x.value != '0') {
                        // 赋 流量控制table数据
                        echoFlowControl(jobAdvParam);
                    }
                }
            });

        }
    }
    // 回显流量控制
    function echoFlowControl(jobAdvParam){
        jobAdvParam.forEach(x => {
            if(x.key == "EEE_FLOW_CONTROL_CONFIG") {
                if(x.value != '') {
                    //  格式 流量控制 1|1,2|00:00:00-23:59:59|1073741824;1|3,4,5|00:00:00-23:59:59|1073741824
                    var dataList = x.value.split(";");
                    dataList.forEach(y => {
                        var data = y.split("|");
                        // 开始日~结束日
                        var week = data[1].split(",");
                        var week_startTime = "";
                        var week_endTime = "";
                        self.weekList.forEach(y => {
                            // 代表开始日和结束日相同
                            if(week.length == 1){
                                week[0] = week[0] == "0" ? "7" : week[0];
                                if(y.value == week[0]) {
                                    week_startTime = y;
                                    week_endTime = y;
                                }
                            }else{
                                week[0] = week[0] == "0" ? "7" : week[0];
                                week[1] = week[1] == "0" ? "7" : week[1];
                                if(y.value == week[0]) {
                                    week_startTime = y;
                                }
                                if(y.value == week[1]) {
                                    week_endTime = y;
                                }
                            }
                        });
                        // 限速时间段
                        var speedLimitTime = data[2];
                        // 最大速度

                        let params = {
                            id: guid(),
                            startTime: week_startTime,
                            endTime:  week_endTime,
                            speedLimitTime:{
                                beginTime: {
                                    hour_a: speedLimitTime.split("-")[0].substr(0,2),
                                    minute_a: speedLimitTime.split("-")[0].substr(2,5),
                                    show: false,
                                    date: speedLimitTime.split("-")[0].substr(0,5)
                                },
                                endTime: {
                                    hour_a: speedLimitTime.split("-")[1].substr(0,2),
                                    minute_a: speedLimitTime.split("-")[1].substr(2,5),
                                    show: false,
                                    date: speedLimitTime.split("-")[1].substr(0,5)
                                }
                            },
                            maxSpeed: data[3]/1024/1024,
                            error: {
                                error_date: 0,//限速时间段不允许有交叠
                                error_startDateMore:0,//开始日必须早于结束日
                                error_speedLimitTime:"",// 开始时间段必须早于结束时间段
                                error_maxSpeed:"",//1~1024
                            },
                        }
                        self.fromData.flowControl.data.push(params);
                    });
                    TableCom.init(self,"tableParams_llkz",self.fromData.flowControl.data,"id",1000,'');
                }
            }
        })
    }
    // 回显策略
    function echoStrategyAdvParam() {
        var type = editData.strategyAdvParam.type;
        self.fromData.strategy.name = editData.strategyAdvParam.name;
        self.fromData.strategy.type = type;
        var params = editData.strategyAdvParam.params;
        // 天
        if(type == 2) {
            self.fromData.strategy.params_day = params[0];
        }
        // 周
        else if(type == 3) {
            // self.weekList
            self.weekList.forEach(x => {
                x.checked = false;
            });
            params.forEach(x => {
                self.weekList.forEach(y => {
                    if(x == y.value) {
                        y.checked = true;
                    }
                });
            });
        }
        // 月
        else if(type == 4) {
            params.forEach(x => {
                if(x == 32) {
                    self.fromData.strategy.params_month_lastDay = true;
                    return;
                }
                self.monthDayList.forEach(y => {
                    if(x == y.value) {
                        self.fromData.strategy.params_month.push(y);
                    }
                });
            });
        }
        // 年
        else if(type == 5) {
            self.monthList.forEach(x => {
                if(x.value == params[0]) {
                    self.fromData.strategy.params_years.month = x;
                    dayListFun('init');
                    return;
                }
            });
            self.dayList.forEach(x => {
                if(x.value == params[1]) {
                    self.fromData.strategy.params_years.day = x;
                }
            });
        }
    }
    dayListFun('chang');
    //切换策略周期
    self.selectStrategyCycle = function(data) {
        self.fromData.strategy.type = data;
    }
    // 初始化table
    self.headers={
        startTime:$translate.instant("aws.backupService.backupJob.dialog.startTime"),
        endTime:$translate.instant("aws.backupService.backupJob.dialog.endTime"),
        speedLimitTime:$translate.instant("aws.backupService.backupJob.dialog.speedLimitTime"),
        maxSpeed:$translate.instant("aws.backupService.backupJob.dialog.maxSpeed"),
    };
    self.tableCols=[
        { field: "startTime", title: self.headers.startTime, show: true,disable:true, width:"20%"},//开始日
        { field: "endTime", title: self.headers.endTime, show: true,disable:false, width:"20%"},//结束日
        { field: "speedLimitTime", title: self.headers.speedLimitTime, show: true,disable:false, width:"25%"},//限度时间段
        { field: "maxSpeed", title: self.headers.maxSpeed, show: true,disable:false, width:"25%"},//最大速度
        { field: "option", title: "", show: true,disable:false },//操作
    ];
    //生成uid
    function S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    }
    function guid() {
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    }
    self.optionFlowControl = optionFlowControl;
    function optionFlowControl(type, value){
        if(type == 'add') {
            let param = {
                id: guid(),
                startTime: self.weekList[0],
                endTime:  self.weekList[4],
                speedLimitTime:{
                    beginTime: {
                        hour_a: "00",
                        minute_a: "00",
                        show: false,
                        date: "00:00"
                    },
                    endTime: {
                        hour_a: "23",
                        minute_a: "59",
                        show: false,
                        date: "23:59"
                    }
                },
                maxSpeed: 1024,
                error: {
                    error_date: 0,//限速时间段不允许有交叠
                    error_startDateMore:0,//开始日必须早于结束日
                    error_speedLimitTime:"",// 开始时间段必须早于结束时间段
                    error_maxSpeed:"",////1~1024
                },
            }
            self.fromData.flowControl.data.push(param);
        }else if(type == 'delete'){
            self.fromData.flowControl.data.splice(self.fromData.flowControl.data.findIndex(item => item.id === value.id), 1);
        }
        TableCom.init(self,"tableParams_llkz",self.fromData.flowControl.data,"id",1000,'');
    }
    // 校验流量控制参数
    self.verifyFlow = verifyFlow;
    function verifyFlow(){
        self.fromData.flowControl.data.forEach(item => {
            /**
             * 判断开始日是否小于结束日
             */
             item.error.error_startDateMore = 0;
            if((item.startTime.value > item.endTime.value) && (item.startTime.value != item.endTime.value)){
              
                item.error.error_startDateMore = item.error.error_startDateMore + 1;
            }else{
                item.error.error_startDateMore = item.error.error_startDateMore > 0 ? item.error.error_startDateMore -1 : 0;
            }
            /**
             * 判断 限速时间段是否重叠  大于1个得时候判断
             */
            item.error.error_date = 0;
            if(self.fromData.flowControl.data.length > 1) {
                speedLimitTimeIfRepeat(item);
            }else{
                item.error.error_date= item.error.error_date > 0 ? item.error.error_date - 1 : 0;
                item.error.error_startDateMore= item.error.error_startDateMore > 0 ? item.error.error_startDateMore -1 : 0;
            }
            /**
             * 判断 开始时间必须早于结束时间
             */
            if(item.speedLimitTime.beginTime.date == item.speedLimitTime.endTime.date) {
                item.error.error_speedLimitTime = $translate.instant("aws.backupService.backupJob.dialog.message.error3");
                return;
            }
            var speedLimitTime_beginTime = new Date('2022-01-01 '+ item.speedLimitTime.beginTime.date +':00').getTime();//时间段开始
            var speedLimitTime_endTime = new Date('2022-01-01 '+ item.speedLimitTime.endTime.date +':59').getTime();//时间段结束
            // 如果开始时间大于或等于结束时间 就提示错误
            if((speedLimitTime_beginTime > speedLimitTime_endTime) || (speedLimitTime_beginTime == speedLimitTime_endTime)) {
                item.error.error_speedLimitTime = $translate.instant("aws.backupService.backupJob.dialog.message.error3");
            }else{
                item.error.error_speedLimitTime = "";
            }
            // 判断 最大速度是否再 1~1024 之间包含1和1024
            if(parseInt(item.maxSpeed) > 1024 || parseInt(item.maxSpeed)  < 1 || item.maxSpeed == ""){
                item.error.error_maxSpeed = $translate.instant("aws.backupService.backupJob.dialog.message.error4");
            }else{
                item.error.error_maxSpeed = "";
            }
        });
        // 错误信息统一处理
        errorInfo();
    }
    // 错误统一提示
    function errorInfo(){
        self.errorMessage=[];
        self.fromData.flowControl.data.forEach(item => {
            // 限速时间段不允许有交叠！
            if(item.error.error_date > 0){
                if(self.errorMessage.indexOf($translate.instant("aws.backupService.backupJob.dialog.message.error1")) == -1){
                    self.errorMessage.push($translate.instant("aws.backupService.backupJob.dialog.message.error1"));
                }
            }
            // 开始日必须早于结束日
            if(item.error.error_speedLimitTime!=""){
                if(self.errorMessage.indexOf($translate.instant("aws.backupService.backupJob.dialog.message.error2")) == -1){
                    self.errorMessage.push($translate.instant("aws.backupService.backupJob.dialog.message.error2"));
                }
            }
            // 开始时间必须早于结束时间 error_startDateMore
            if(item.error.error_startDateMore!=""){
                if(self.errorMessage.indexOf($translate.instant("aws.backupService.backupJob.dialog.message.error3")) == -1){
                    self.errorMessage.push($translate.instant("aws.backupService.backupJob.dialog.message.error3"));
                }
            }
            // 不得大于1024MiB/s error_maxSpeed
            if(item.error.error_maxSpeed!=""){
                if(self.errorMessage.indexOf($translate.instant("aws.backupService.backupJob.dialog.message.error4")) == -1){
                    self.errorMessage.push($translate.instant("aws.backupService.backupJob.dialog.message.error4"));
                }
            }
        });
    }
    function speedLimitTimeIfRepeat(data){
        var id = data.id;//唯一标识，不判断自己
        var startTime = data.startTime.value;//开始日
        var endTime = data.endTime.value;//结束日
        var speedLimitTime_beginTime = new Date('2022-01-01 '+ data.speedLimitTime.beginTime.date +':00').getTime();//时间段开始
        var speedLimitTime_endTime = new Date('2022-01-01 '+ data.speedLimitTime.endTime.date +':59').getTime();//时间段结束
        // 如果(星期)重叠再判断(时间段)是否重叠
        self.fromData.flowControl.data.forEach(item => {
            if(id==item.id) {
                return false;
            }
            var compared_startTime = item.startTime.value;
            var compared_endTime = item.endTime.value;
            var compared_speedLimitTime_beginTime = new Date('2022-01-01 '+ item.speedLimitTime.beginTime.date +':00').getTime();//时间段开始
            var compared_speedLimitTime_endTime = new Date('2022-01-01 '+ item.speedLimitTime.endTime.date +':59').getTime();//时间段结束
            if(
                /**
                 *  两条数据判断是否重叠，，
                 *  1、初始日是否再下一条初始日和结束日之间
                 *  2、结束日是否再下一条初始日和结束日之间
                 */
                (((startTime==compared_startTime) || (startTime==compared_endTime)|| (startTime > compared_startTime && startTime < compared_endTime)) // startTime 是否在 compared_startTime和compared_endTime 之间
                || ((endTime==compared_startTime) || (endTime==compared_endTime) || (endTime > compared_startTime && endTime < compared_endTime))) 
                ||  // endTime 是否在 compared_startTime和compared_endTime 之间
                (((compared_startTime==startTime) || (compared_endTime==startTime)|| (compared_startTime > startTime && compared_startTime < endTime))
                || ((compared_startTime==endTime) || (compared_endTime==endTime) || (compared_endTime > startTime && compared_endTime < endTime)))
            ){
                // 日期有重叠，就去判断这两个的（时间段）是否有重叠,如果有重叠就提示错误
                if(
                    (((speedLimitTime_beginTime==compared_speedLimitTime_beginTime) || (speedLimitTime_beginTime==compared_speedLimitTime_endTime)|| (speedLimitTime_beginTime > compared_speedLimitTime_beginTime && speedLimitTime_beginTime < compared_speedLimitTime_endTime))
                    || ((speedLimitTime_endTime==compared_speedLimitTime_beginTime) || (speedLimitTime_endTime==compared_speedLimitTime_endTime) || (speedLimitTime_endTime > compared_speedLimitTime_beginTime && speedLimitTime_endTime < compared_speedLimitTime_endTime)))
                    ||
                    (((speedLimitTime_beginTime==compared_speedLimitTime_beginTime) || (speedLimitTime_beginTime==compared_speedLimitTime_endTime)|| (speedLimitTime_beginTime < compared_speedLimitTime_beginTime && speedLimitTime_endTime > compared_speedLimitTime_beginTime))
                    || ((speedLimitTime_endTime==compared_speedLimitTime_beginTime) || (speedLimitTime_endTime==compared_speedLimitTime_endTime) || (speedLimitTime_beginTime < compared_speedLimitTime_endTime && speedLimitTime_endTime > compared_speedLimitTime_endTime)))
                ){
                    // 提示错误信息
                    data.error.error_date = data.error.error_date + 1;
                }
            }
        });
    }


    // 数字加减
    self.setInsNum = function(type) {
        if ((self.fromData.strategy.params_day <= 1 && type == "minus") || (self.fromData.strategy.params_day  >= 365 && type == "add")) {
            return;
        } 
        if (type == "minus") {
            self.fromData.strategy.params_day --;
        } else {
            self.fromData.strategy.params_day ++;
        }
    };

    // 数字加减 b
    self.setInsNum_b = function(type) {
        if ((self.fromData.EEE_TRAVERSAL_THREAD <= 2 && type == "minus") || (self.fromData.EEE_TRAVERSAL_THREAD  >= 10 && type == "add")) {
            return;
        } 
        if (type == "minus") {
            self.fromData.EEE_TRAVERSAL_THREAD --;
        } else {
            self.fromData.EEE_TRAVERSAL_THREAD ++;
        }
    };
    // 校验参数 周
    self.checkWeek = function() {
        self.checkParmas.weekError = false;
        let checked = false;
        self.weekList.forEach(x => {
            if(x.checked){
                checked = true;
            }
        });
        if(!checked){
            self.checkParmas.weekError = true;
        }
    }

    self.$watch(function() {
        return self.fromData.flowControl.data;
    }, function(val) {
        verifyFlow();
    },true);
    //确定按钮
    self.confirmCreate = function(m,v){
        if(m.$valid){
            /**
             * 校验参数是否合理
             */
            // 检验策略规则周
            if(self.checkParmas.weekError){
                return;
            }
            // 检验流量控制参数
            if(self.errorMessage.length>0) {
                return;
            }
            // 拼后台所需参数
            var runTime = new Date('2022-01-01 '+ self.fromData.runTime.date).getTime();
            var strategyAdvParam = build_strategyAdvParam();
            var jobAdvParam = build_jobAdvParam();
            var parmas  = {
                jobName: self.fromData.jobName,//任务名称
                backupType:  self.fromData.backupType.value,//备份方式
                runTime: runTime,//执行时间
                strategyAdvParam: JSON.stringify(strategyAdvParam),//策略json
                jobAdvParam: JSON.stringify(jobAdvParam), //高级参数
            }
            var headers = {};
            self.commitBtn = false;
            if(self.modelType == 'new') {
                // 新建默认传默认项目和部门 uid
                if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                    headers = {
                        project_id: localStorage.defaultProjectUid,
                        domain_id: localStorage.defaultdomainUid,
                    }
                }
                backupJobSrv.createBackupJob(parmas, headers).then(function (res) {
                    if(res) {
                        $uibModalInstance.close();
                        initBackupJobTable();
                    }
                    self.commitBtn = true;
                });
            }else if(self.modelType == 'edit') {
                if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                    const {projectUid,domainUid} = editData;
                    headers = {
                        project_id: projectUid,
                        domain_id: domainUid,
                    }
                }
                parmas.id = editData.id;
                backupJobSrv.editBackupJob(parmas, headers).then(function () {
                    $uibModalInstance.close();
                    initBackupJobTable();
                    self.commitBtn = true;
                });
            }
        }else{
            self.FormValid = true;
        }
    }
    // 拼装策略参数  进行判断
    function build_strategyAdvParam(){
        var strategyAdvParam = {
            duration: 24,
            durationUnit: 2,
            frequency: 1,
            frequencyUnit: 2,
            name: self.fromData.strategy.name,
            needInterval: 0,
            type: self.fromData.strategy.type,
            params:[],
        };
        // 天
        if(self.fromData.strategy.type == 2) {
            strategyAdvParam.params.push(self.fromData.strategy.params_day);
        }
        // 周
        if(self.fromData.strategy.type == 3) {
            self.weekList.forEach(x => {
                if(!x.checked){
                    return;
                }
                strategyAdvParam.params.push(x.value);
            });
        }
        // 月。如果有32
        if(self.fromData.strategy.type == 4) {
            self.fromData.strategy.params_month.forEach(x => {
                strategyAdvParam.params.push(x.value);
            });
            // 若当月无选定日期则在最后一天触发
            if(self.fromData.strategy.params_month_lastDay){
                strategyAdvParam.params.push(32);
            }
            // 排序
            strategyAdvParam.params.sort((a,b) => { return a-b })
        }
        // 年
        if(self.fromData.strategy.type == 5) {
            strategyAdvParam.params.push(self.fromData.strategy.params_years.month.value);
            strategyAdvParam.params.push(self.fromData.strategy.params_years.day.value);
        }
        return strategyAdvParam;
    }
    // 拼装高级参数
    function build_jobAdvParam(){
        // 实例并发备份数
        var EEE_BACKUP_CHANNEL_CONFS = self.fromData.EEE_BACKUP_CHANNEL_CONFS == true ? 1: 0;
        var EEE_USE_THREAD_NUM = 2;
        EEE_USE_THREAD_NUM = EEE_BACKUP_CHANNEL_CONFS == 1 ? self.fromData.EEE_TRAVERSAL_THREAD: 2;
        // 永久增量备份
        var EEE_PERMANENT_INCREMENT = 0;
        EEE_PERMANENT_INCREMENT = self.fromData.EEE_PERMANENT_INCREMENT == true ? 1: 0;
        // 备份自动重试 如果有一个参数写的有问题就默认为关闭状态
        var EEE_VM_AUTO_RESTART = self.fromData.EEE_VM_AUTO_RESTART == true ? 1: 0;
        var EEE_VM_AUTO_RESTART_COUNT = 0;
        var EEE_VM_AUTO_RESTART_INTERVAL = 0;
        if(self.fromData.EEE_VM_AUTO_RESTART) {
            EEE_VM_AUTO_RESTART_COUNT = self.fromData.EEE_AUTO_RESTART_COUNT;
            EEE_VM_AUTO_RESTART_INTERVAL = self.fromData.EEE_AUTO_RESTART_INTERVAL;
        }
        // 流量控制 1|1,2|00:00:00-23:59:59|1073741824;1|3,4,5|00:00:00-23:59:59|1073741824
        // 如果相同日期 比如 周三-周三 就只传3 即可。
        var EEE_FLOW_CONTROL_ENABLE = 0;
        var EEE_FLOW_CONTROL_CONFIG = "";
        if(self.fromData.flowControl.data.length > 0) {
            EEE_FLOW_CONTROL_ENABLE = 1;
        }
        
        var jobAdvParam = [
            // 实例并发备份数
            // 0为关闭虚拟机并发，1开启虚拟机并发。
            {
                "key": "EEE_IS_USE_THREAD",
                "value": EEE_BACKUP_CHANNEL_CONFS
            },
            // 实例并发备份数
            {
                "key": "EEE_USE_THREAD_NUM",
                "value": EEE_USE_THREAD_NUM
            },
            // 永久增量备份
            {
                "key": "EEE_PERMANENT_INCREMENT",
                "value": EEE_PERMANENT_INCREMENT
            },
            // 备份自动重试
            {
                "key": "EEE_VM_AUTO_RESTART",
                "value": EEE_VM_AUTO_RESTART
            },
            // 备份自动重试的最大次数
            {
                "key": "EEE_VM_AUTO_RESTART_COUNT",
                "value": EEE_VM_AUTO_RESTART_COUNT
            },
            // 备份自动重试的等待时间
            {
                "key": "EEE_VM_AUTO_RESTART_INTERVAL",
                "value": EEE_VM_AUTO_RESTART_INTERVAL
            },
            // 流量控制 开关
            {
                "key": "EEE_FLOW_CONTROL_ENABLE",
                "value": EEE_FLOW_CONTROL_ENABLE
            },
            
            /**       其它参数    */
            // 0表示云主机备份，1表示云硬盘备份
            {
                "key": "EEE_BACKUP_GRANULARITY",
                "value":"0"
            },
            // 是否过滤具有multiattach属性云硬盘。
            {
                "key": "EEE_IS_MULTIATTACH",
                "value":"0"
            },
            // 【公共】是否开启重删选项，1表示开启，0表示不开启
            {
                "key": "EEE_DEDUP",
                "value":"0"
            },
            //
            {
                "key": "EEE_DEDUP_SLICE_THREAD_NUM",
                "value":"-1"
            },
            //
            {
                "key": "EEE_DEDUP_FINGER_THREAD_NUM",
                "value":"-1"
            },
            //
            {
                "key": "EEE_DEDUP_COMPRESS_THREAD_NUM",
                "value":"-1"
            },
            //【公共】是否开启传输和存储加密选项，1表示开启，0表示不开启
            {
                "key": "EEE_ENCRYPTION_XXXX",
                "value":"0"
            },
            //【公共】是否开启数据压缩的选项，0表示不开启，1表示开启
            {
                "key": "EEE_COMPRESS_ALGO_CHECKED",
                "value":"0"
            },
            //
            {
                "key": "EEE_FORCED_RETENTION_POLICY",
                "value":"0"
            },
            //
            {
                "key": "EEE_DEDUP_ENABLE_OPEN_THREAD",
                "value":"0"
            },
            //
            {
                "key": "EEE_FORCED_RETENTION_CYCLE",
                "value":"-1"
            },
            //【公共】任务是否要自动执行，0表示不自动执行，1表示自动执行（备份，恢复任务都有）
            {
                "key": "EEE_JOB_RUN_IMMEDIATELY",
                "value":"0"
            },
        ]
        if(self.fromData.flowControl.data.length > 0) {
            for(var i = 0; i < self.fromData.flowControl.data.length; i ++) {
                let data = self.fromData.flowControl.data[i];
                let config = "1|";
                // 开始日~结束日
                var startTime = data.startTime.value;
                var endTime = data.endTime.value;
                // 爱数侧 星期日 为0; 所以需要转换一下
                startTime = startTime == 7 ? 0 : startTime;
                endTime = endTime == 7 ? 0 : endTime;
                if(startTime == endTime) {
                    config += startTime;
                }else{
                    config += startTime + "," + endTime;
                }
                // 限速时间段
                var speedLimitTime_beginTime = data.speedLimitTime.beginTime.date + ":00";
                var speedLimitTime_endTime = data.speedLimitTime.endTime.date + ":59";
                config += "|" + speedLimitTime_beginTime + "-" + speedLimitTime_endTime;
                // 最大速度
                config += "|" + data.maxSpeed*1024*1024;
                if(self.fromData.flowControl.data.length > (i+1)){
                    config += ";";
                }
                EEE_FLOW_CONTROL_CONFIG+=config;
            }
            var p =  {
                "key": "EEE_FLOW_CONTROL_CONFIG",
                "value": EEE_FLOW_CONTROL_CONFIG
            }
            jobAdvParam.push(p);
            
        }
        return jobAdvParam;
    }
};
export {addBackupJobCtrl}