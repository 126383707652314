snapRollBackCtrl.$inject = ["$scope","$rootScope","vmdiskInfo","instancesSrv","volumesDataSrv","$translate","$uibModalInstance","checkedSrv", "$filter"];
export function snapRollBackCtrl($scope,$rootScope,vmdiskInfo,instancesSrv,volumesDataSrv,$translate,$uibModalInstance,checkedSrv,$filter){
    var self=$scope;
    self.submitInValid = false;
    self.newObj = {};
    self.system_snap_list=[];
    self.data_snap_list=[];
    self.newObj.systemDiskSnap = [];
    self.newObj.dataDiskSnap = [];
    vmdiskInfo = vmdiskInfo.filter(item=> {
        return (item.status == "in-use"||item.status == "available")
    });
    function avoidDoubleClick(bol){
        self.cannot_Confirm = bol;
    }
    function getVolumeIds(){
        var volume_snapshots = [];
        if(self.newObj.systemDiskSnap.length){
            self.newObj.systemDiskSnap.map(item => {
                volume_snapshots.push({"volumeId":item.volume_id,"snapshotId":item.id})
            })
        }
        if(self.newObj.dataDiskSnap.length){
            self.newObj.dataDiskSnap.map(item => {
                volume_snapshots.push({"volumeId":item.volume_id,"snapshotId":item.id})
            })
        }
        return volume_snapshots
    }
    function getallvmDiskId(){
        return vmdiskInfo.reduce((list, disk) => {
            disk.id !== "" && list.indexOf(disk.id) === -1 && list.push(disk.id);
            return list;
        }, []);
    }
    function getSystemDiskId(diskType){
        return vmdiskInfo.reduce((list, disk) => {
            disk.id !== "" && list.indexOf(disk.id) === -1 && disk.bootable == diskType && disk.root_device == diskType && list.push(disk.id);
            return list;
        }, []);
    }
    function getDataDiskId(diskType){
        return vmdiskInfo.reduce((list, disk) => {
            disk.id !== "" && list.indexOf(disk.id) === -1 && !(disk.bootable == diskType && disk.root_device == diskType) && list.push(disk.id);
            return list;
        }, []);
    }
    var getVolumeSnapshots = function(vmdiskInfo){
        var allvmDiskId = getallvmDiskId(vmdiskInfo);
        var systemDiskId = getSystemDiskId("true");
        var dataDiskId = getDataDiskId("true");
        var postData = {"volumeid":allvmDiskId};
        if(allvmDiskId.length == 0) return;
        instancesSrv.getVolumeSnapshots(postData).then(function(result) {
            if (result && result.data && angular.isArray(result.data)) {
                result.data = result.data.filter(item => 
                    (item.storageLimit && item.storageLimit["os-rollback"])
                )
                result.data.map(x =>{
                    if(systemDiskId.indexOf(x.volume_id)>-1){
                        self.system_snap_list.push(x);
                    }else if(dataDiskId.indexOf(x.volume_id)>-1){
                        self.data_snap_list.push(x);
                    }
                    var date = $filter("date")(x.created_at, "MMddHHmm");
                    if (x.name.indexOf('_') < 0) {
                        x.name = x.name + "  (" + date + ")";
                    }
                });
                self.system_snap_list_ori = angular.copy(self.system_snap_list);
                self.data_snap_list_ori = angular.copy(self.data_snap_list);
            }
        });
    }

    self.changeSnap = function(choosedSnap,typelist){
        self.chooseSnapTipShow = false;
        var list = angular.copy(self[typelist+"_ori"]);
        choosedSnap.map(ch => {
            list = list.filter(item => item.volume_id != ch.volume_id)
        })
        self[typelist] = list;
    }
    
    self.snapshotRollbackFunc = function(field,editdata){
        //判断共享盘创建的快照下面对应的所有云主机都必须处于关机状态
        self.sharedVolumeHasNoShutoffIns=false;
        var sharedVolumeArr=[];
        self.newObj.dataDiskSnap.forEach(function(item){
                if(item.multiattach){
                sharedVolumeArr.push(item.volume_id) 
                }    
        })
        let volume_snapshots = getVolumeIds();
        let postData = {volume_snapshots:volume_snapshots}
        const rollbackitems = new Set();
        volume_snapshots.map(item => {
            rollbackitems.add(item.snapshotId);
        })
        const rollbackarray = Array.from(rollbackitems); 
        if(sharedVolumeArr.length>0){
                instancesSrv.getInstancesOfSnapshot({ids:sharedVolumeArr}).then(function(res){
                    if(res&&res.data&&angular.isArray(res.data)){
                        for(var i=0;i<res.data.length;i++){
                            let detail=res.data[i];
                            if(detail.volume&&detail.volume.insnameAndId&&angular.isArray(detail.volume.insnameAndId)){
                                for(var j=0;j<detail.volume.insnameAndId.length;j++){
                                    let status=detail.volume.insnameAndId[j].status.toLowerCase();
                                    if(!(status=='shutoff'||status=='stopped')){
                                        self.sharedVolumeHasNoShutoffIns=true;
                                    }
                                }   
                            }
                            
                        }
                        if(field.$valid&&!self.sharedVolumeHasNoShutoffIns){
                        if(volume_snapshots.length){
                            checkedSrv.setChkIds(rollbackarray,"snapshotrollback")
                            instancesSrv.snapshotRollback(angular.toJson(postData)).then(function(result){
                            })
                            avoidDoubleClick(true)  //避免双击
                            $uibModalInstance.close();
                        }else{
                            self.chooseSnapTipShow = true; //提示至少选择一块盘
                        }
                    }else{
                        self.submitInValid = true;
                    }
                    }
                })
        }else{
                if(field.$valid){
                if(volume_snapshots.length){
                    checkedSrv.setChkIds(rollbackarray,"snapshotrollback")
                    instancesSrv.snapshotRollback(angular.toJson(postData)).then(function(result){
                    })
                    avoidDoubleClick(true)  //避免双击
                    $uibModalInstance.close();
                }else{
                    self.chooseSnapTipShow = true; //提示至少选择一块盘
                }
                }else{
                self.submitInValid = true;
                } 
        }
        // if(field.$valid){
        //     if(volume_snapshots.length){
        //         checkedSrv.setChkIds(rollbackarray,"snapshotrollback")
        //         instancesSrv.snapshotRollback(angular.toJson(postData)).then(function(result){
        //         })
        //         avoidDoubleClick(true)  //避免双击
        //         $uibModalInstance.close();
        //     }else{
        //         self.chooseSnapTipShow = true; //提示至少选择一块盘
        //     }
        // }else{
        //     self.submitInValid = true;
        // }
    }
    avoidDoubleClick(false)
    getVolumeSnapshots(vmdiskInfo)
}
