import "./floatipsSrv";
import "./routersSrv";

var floatipsModule = angular.module("floatipsModule", ["ngSanitize", "ngTable", "ngAnimate", "ui.bootstrap", "floatipsSrvModule", "routersSrvModule", "ui.select"]);

floatipsModule.controller("floatipsCtrl", ["$scope", "$rootScope", "$timeout","NgTableParams", "floatipsSrv", "routersSrv", "checkedSrv", "checkQuotaSrv", "$uibModal", "$translate", "GLOBAL_CONFIG","commonFuncSrv","utilTool","TableCom",
    function($scope, $rootScope, $timeout,NgTableParams, floatipsSrv, routersSrv, checkedSrv, checkQuotaSrv, $uibModal, $translate, GLOBAL_CONFIG,commonFuncSrv,utilTool,TableCom) {
        var self = $scope;
        self.pageSize = 10;
        // var checkedItems = [];
        self.choiceAll = localStorage.selectedAllDomain

        self.headers = {
            "ipAddr": $translate.instant("aws.floatingIps.ipAddr"),
            "instanceName": $translate.instant("aws.floatingIps.resourceName"),
            "fixedIP": $translate.instant("aws.floatingIps.fixedIP"),
            "status": $translate.instant("aws.floatingIps.status"),
            "projectName": $translate.instant("aws.floatingIps.projectName"),
            "routerId": $translate.instant("aws.floatingIps.routerId"),
            "portId": $translate.instant("aws.floatingIps.portId"),
            "resourcePool": $translate.instant("aws.floatingIps.resourcePool"),
            "resourceType": $translate.instant("aws.floatingIps.resourceType"),
            "fixedIP": $translate.instant("aws.floatingIps.fixedIP")
        };
        //公网IP数据列表初始化
        var initFloatingIpTable = function(options,changePage) {
            if(!changePage) self.currentPage = 1
            let pageDefaultOption = {
                pageSize:10,
                pageNum: 1,
                likes:self.globalSearchTerm,
            }
            var pageOption = angular.extend(pageDefaultOption,options)
            floatipsSrv.newGetFloatipsTableData(pageOption).then(function(result) {
                result ? self.loadData = true : "";
                if (result && result.data) {
                    self.totalNum = result.total;
                    self.floatipList = result.data;
                    self.init_floatip_data(result.data);
                }
            });
        };
        initFloatingIpTable();
        self.initFloatingIpTable=initFloatingIpTable;

        self.changePage=function(options){
            initFloatingIpTable(options,'changePage');
        };
        
        //资源导出
        self.exportResource = function(){
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/manage/floatingIps?pageSize=&pageNum=&pattern=${self.globalSearchTerm||""}&regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}`
            utilTool.downloadFile(url);
        }
        //控制导出按钮是否显示，当选中全部部门时显示
        self.showExport = utilTool.isAllDomainSelect;
        self.init_floatip_data = function(data) {
            var floatips_data = _.map(data, function(item) {
                //处理返回的数据
                if(item.domainName=="default"){
                    item.domainName_TR = $translate.instant("aws.common.defaultDepar")
                }else{
                    item.domainName_TR = item.domainName
                }
                if(item.projectName=="admin"){
                    item.projectName_TR = $translate.instant("aws.common.defaultProject")
                }else{
                    item.projectName_TR = item.projectName
                }
                if (item.fixedIP) {
                    item.statu = "active"; //由于底层状态改变延迟，当已成功绑定云主机时，前端先手动将ip状态置为active
                    if (item.instanceName) {
                        item.instanceName = item.instanceName == "LOADBALANCER" ? $translate.instant("aws.floatingIps.loadBalance") : item.instanceName;
                    } else {
                        item.instanceName = $translate.instant("aws.floatingIps.loadBalance");
                    }
                } else {
                    item.statu = "down";
                    item.fixedIP = "";
                }
                if (item.instanceName == null) {
                    item.instanceName = "";
                }
                item._status = $translate.instant('aws.routers.status.' + item.statu);
                return item;
            });
            self.tableParams = new NgTableParams({
                count: GLOBAL_CONFIG.PAGESIZE
            }, {
                counts: [],
                dataset: floatips_data
            });
            checkedSrv.checkDo(self, floatips_data, "id");
        };

        self.applyGlobalSearch = function(likes) {
            let searchOption = {
                likes:likes,
            }
            $timeout.cancel(self.sendSearch)
            self.sendSearch = $timeout(()=>{
                initFloatingIpTable(searchOption)
            },1000)
        };

        //刷新公网IP列表
        self.refreshFloatIp = function() {
            self.globalSearchTerm = "";
            initFloatingIpTable();
        };

        self.$watch(function() {
            return self.checkedItems;
        }, function(value,old) {

            if(value&&old&&value.length!=old.length){
                utilTool.setLocalStorage(value);
            }

            if (!value) {
                self.isDisabled = true;
                self.delisDisabled = true;
            }else if(value){
                if (value.length === 1) {
                    if (self.checkedItems[0].instanceName && self.checkedItems[0].fixedIP) {
                        self.canBind = false;
                        self.canUnbundling = true;
                    } else {
                        self.canBind = true;
                        self.canUnbundling = false;
                    }
                }else {
                    self.canBind = false;
                    self.canUnbundling = false;
                }
            }
        });

        //申请公网IP
        self.addFloatIp = function() {
            utilTool.newResSetLocalStorage(self,'checkboxes','items')

            var scope = self.$new();
            var addFloatIpModal = $uibModal.open({
                animation: true,
                templateUrl: "js/cvm/networks/tmpl/add-floatip-modal.html",
                controller:"addFloatipCtrl",
                resolve:{
                    refresh:function(){
                        return initFloatingIpTable
                    }
                }
            });
            return;
            getPrice()
            checkQuotaSrv.checkQuota(scope, "floatingip");
            
            scope = commonFuncSrv.setAssignIpFun(scope, "floatipForm","createfloatipForm","external");
            var addFloatipFunc = function(params) {
                floatipsSrv.addFloatipAction(params).success(function() {
                    initFloatingIpTable();
                });
            };

            scope.floatipConfirm = function(createfloatipForm) {
                if(createfloatipForm.$valid){
                    var postfloatipParams = {
                        "pool_id": scope.floatipForm.selectedNet.id
                    };
                    if (scope.floatipForm.assignSub) {
                        postfloatipParams.subnet_id = scope.floatipForm.selectedSubnet.id;
                        if (scope.floatipForm.assignIP) {
                            if(scope.floatipForm.ipVersion==6){
                                postfloatipParams.floating_ip_address = scope.floatipForm.v6List.fixedIp;
                            }else{
                                postfloatipParams.floating_ip_address = scope.floatipForm.v4List.fixedIp;
                                //self.setCheckvalueFunc();
                            }
                            // postfloatipParams.floating_ip_address = scope.floatipForm.init_cidr.ip_0 + "." +
                            //     scope.floatipForm.init_cidr.ip_1 + "." +
                            //     scope.floatipForm.init_cidr.ip_2 + "." +
                            //     scope.floatipForm.init_cidr.ip_3;
                            delete postfloatipParams.subnet_id;
                            //scope.setCheckValueFunc();
                        }
                    }
                    addFloatipFunc(postfloatipParams);
                    addFloatIpModal.close();
                }else {
                    scope.submitted = true;
                }
                
            };
            function getPrice(){
                if(!$rootScope.billingActive){
                    return ;
                }
                var postData ={
                    "region":localStorage.regionName?localStorage.regionName:"default",
                    "floatingIpCount":1
                }
                floatipsSrv.getPrice(postData).then(function(data){
                    if( data && data.data && !isNaN(data.data)){
                        self.showPrice = true;
                        self.price =data.data;
                        self.priceFix =  self.price.toFixed(2)
                        self.totalPrice = (self.price*30*24).toFixed(2)
                    }else{
                        self.showPrice = true;
                        self.price ="N/A";
                        self.priceFix =  "N/A";
                        self.totalPrice = "N/A"
                    }
                    
                })
            }
        };

        //绑定公网IP
        self.bindFloatingIp = function(editData) {
            var scope = self.$new();
            var bindFloatIpModal = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "bindIpModal.html",
                controller:"bindFloatingIpCtrl",
                resolve:{
                    editData:angular.copy(editData),
                    context:self
                },
                scope: scope
            });
        };

        //编辑公网IP
        self.editFloatingIp = function(editData) {
            var scope = self.$new();
            var editFloatIpModal = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "editIpModal.html",
                scope: scope
            });
            scope.submitValid = false;

            scope.confirmedit = function(formField) {
                if (formField.$valid) {
                    editFloatIpModal.close();
                } else {
                    scope.submitValid = true;
                }
            };
        };
        //解绑公网IP
        self.unBindFloatip = function(editData) {
            var content = {
                target: "unBindFloatip",
                msg: "<span>" + $translate.instant("aws.floatingIps.del.unBindFloatIpMsg") + "</span>",
                data: editData
            };
            self.$emit("delete", content);
        };
        self.$on("unBindFloatip", function(e, data) {
            var params = {
                floatingip_id: data.id
            };
            floatipsSrv.unBindFloatipAction(params).then(function() {
                initFloatingIpTable();
            });
        });
        //删除公网IP
        self.deleteFloatip = function(checkedItems) {
            var inusd = false;
            var delmsg = "";
            checkedItems.map(item => {
                if (item.portId) {
                    inusd = true;
                }
            })
            inusd ? delmsg = "<span>" + $translate.instant("aws.floatingIps.del.confirmDelFloatingIpMsg") + "</span>" + "</br>" + "<span>" + $translate.instant("aws.floatingIps.del.delFloatingIpMsg") + "</span>" :
                delmsg = "<span>" + $translate.instant("aws.floatingIps.del.delFloatingIpMsg") + "</span>";
            var content = {
                target: "delFloatingIp",
                msg: delmsg,
                data: checkedItems
            };
            self.$emit("delete", content);
        };
        self.$on("delFloatingIp", function(e, data) {
            var del_obj_ids = [];
            _.forEach(data, function(item) {
                del_obj_ids.push(item.id);
            });
            var delParams = {
                floatingips_list: del_obj_ids
            };
            floatipsSrv.delFloatipAction(delParams).then(function() {
                initFloatingIpTable();
            });
        });
    }
]).controller("bindFloatingIpCtrl",["$scope","$translate","editData","context","floatipsSrv","NgTableParams","$uibModalInstance",function($scope,$translate,editData,context,floatipsSrv,NgTableParams,$uibModalInstance){
    var scope = $scope;
    scope.checkRadio = {
        "value": ""
    }
    scope.pageSize = 8;
    scope.globalSearchTerm = "";
    scope.changePage=function(options){
        options.pattern = scope.globalSearchTerm?scope.globalSearchTerm:"";
        initFixedIPTable(options);
    };
    //后端搜索
    scope.searchBackend = function(){
        var pageOption = {
            pageSize: scope.pageSize,
            pageNum:1,
            pattern:scope.globalSearchTerm?scope.globalSearchTerm:"",
        };
        scope.currentPage = 1;
        initFixedIPTable(pageOption);
    }

    var initFixedIPTable = function(options) {
        floatipsSrv.getInstanceIPs(editData.projectId, options).then(function(result) {
            if (result && result.data) {
                if (result.data.length > 0) {
                    scope.checkRadio.value = result.data[0].fixed_ip;
                    scope.totalNum = result.total;
                    scope.fixedIPList = result.data.filter(item=>{
                        return item.fixed_ip.indexOf('.')>-1
                    });
                    init_fixedIP_data(scope.fixedIPList);
                }
                
            }
        });
    };

    var options = {
        "pageNum": 1,
        "pageSize": 8,
        "pattern": ""
    }
    scope.currentPage = 1;
    initFixedIPTable(options);

    var init_fixedIP_data = function (data) {
        data.forEach(item => {
            switch (item.instance_type) {
                case "0":
                    // instanceType  inst--云主机；db_inst--云数据库
                    if(item.instanceType == 'inst'){
                        item.instance_type_ori = "云主机";
                    }else{
                        item.instance_type_ori = "云数据库";
                    }
                    break;
                case "1":
                    item.instance_type_ori = "负载均衡";
                    break;
                case "2":
                    item.instance_type_ori = "虚拟网卡";
                    break;
                default:
                    break;
            }
        });
        scope.fixedipTableParams = new NgTableParams({
            count: 9999
        }, {
            counts: [],
            dataset: data
        });
    }

    scope.confirmBind = function(port_id) {
        let params = {
            "floatingip_id": editData.id,
            "port_id": port_id.value
        };
        floatipsSrv.bindFloatipAction(params).then(function() {
            context.initFloatingIpTable();
        });
        $uibModalInstance.close();
    };
}])
.controller("addFloatipCtrl",["$scope","floatipsSrv","checkQuotaSrv","$rootScope","commonFuncSrv","refresh","$uibModalInstance",
    function(scope,floatipsSrv,checkQuotaSrv,$rootScope,commonFuncSrv,refresh,$uibModalInstance){
        let self = scope;
        function getPrice(){
            if(!$rootScope.billingActive){
                return ;
            }
            var postData ={
                "region":localStorage.regionName?localStorage.regionName:"default",
                "floatingIpCount":1
            }
            floatipsSrv.getPrice(postData).then(function(data){
                if( data && data.data && !isNaN(data.data)){
                    self.showPrice = true;
                    self.price =data.data;
                    self.priceFix =  self.price.toFixed(2)
                    self.totalPrice = (self.price*30*24).toFixed(2)
                }else{
                    self.showPrice = true;
                    self.price ="N/A";
                    self.priceFix =  "N/A";
                    self.totalPrice = "N/A"
                }
                
            })
        }
        function addFloatipFunc(params) {
            floatipsSrv.addFloatipAction(params).success(function() {
                refresh();
            });
        };
        getPrice()
        checkQuotaSrv.checkQuota(scope, "floatingip");
        scope = commonFuncSrv.setAssignIpFun(scope, "floatipForm","createfloatipForm","external");
        

        scope.floatipConfirm = function(createfloatipForm) {
            if(createfloatipForm.$valid){
                var postfloatipParams = {
                    "pool_id": scope.floatipForm.selectedNet.id
                };
                if (scope.floatipForm.assignSub) {
                    postfloatipParams.subnet_id = scope.floatipForm.selectedSubnet.id;
                    if (scope.floatipForm.assignIP) {
                        if(scope.floatipForm.ipVersion==6){
                            postfloatipParams.floating_ip_address = scope.floatipForm.v6List.fixedIp;
                        }else{
                            postfloatipParams.floating_ip_address = scope.floatipForm.v4List.fixedIp;
                            //self.setCheckvalueFunc();
                        }
                        // postfloatipParams.floating_ip_address = scope.floatipForm.init_cidr.ip_0 + "." +
                        //     scope.floatipForm.init_cidr.ip_1 + "." +
                        //     scope.floatipForm.init_cidr.ip_2 + "." +
                        //     scope.floatipForm.init_cidr.ip_3;
                        delete postfloatipParams.subnet_id;
                        //scope.setCheckValueFunc();
                    }
                }
                addFloatipFunc(postfloatipParams);
                $uibModalInstance.close();
            }else {
                scope.submitted = true;
            }
            
        };
    }
])
