detachLocalDiskCtrl.$inject=["$scope", "$rootScope", "$translate","$timeout","GLOBAL_CONFIG","$uibModalInstance","$location","editData","NgTableParams","localDiskSrv","refresh","context"];
export function detachLocalDiskCtrl($scope, $rootScope, $translate,$timeout,GLOBAL_CONFIG,$uibModalInstance,$location,editData,NgTableParams,localDiskSrv,refresh,context){
    var self = $scope;
    self.pageSize = 8;
    self.radioSelect = {
        oneInstance:''
    }
    self.saveDisabled = false;
    self.checkNotEmptyShow = true;
    self.headers = {
        name:$translate.instant("aws.localDisk.localDiskName"),
        state:$translate.instant("aws.localDisk.status"),
        size:$translate.instant("aws.localDisk.config"),
        path:$translate.instant("aws.localDisk.location")
    };
    self.tableCols = [
        { field: "radio", title: "",show: true },
        { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
        { field: "state", title: self.headers.state,sortable: "state",show: true,disable:false},
        { field: "size", title: self.headers.size,sortable: "size",show: true,disable:false},
        { field: "path", title: self.headers.path,sortable: "path",show: true,disable:false}
    ];

    function initHaSwitch(value) {
        self.status = value.hostBind == true ? false : true;
        self.switchText = dealSwitchText(self.status);
    }
    initHaSwitch(editData);

    self.switchChange = (value)=> {
        self.status = value;
        self.switchText = dealSwitchText(value);
    }

    function dealSwitchText(value) {
        let hasSwitch = value ? $translate.instant('aws.localDisk.open') : $translate.instant('aws.localDisk.close');
        return hasSwitch;
    }

    self.confirm = function(){
        self.checkNotEmptyShow = (Object.keys(self.radioSelect.oneInstance).length>0) ? true : false;
        self.saveDisabled = true;
        if(self.checkNotEmptyShow) {
            let vmId = editData.uid;
            let volumeId = JSON.parse(self.radioSelect.oneInstance).uuid;
            let haSwitch = self.status == true ? 1 : 0;
            $rootScope.$emit("detachLocalDisk",{});
            localDiskSrv.uninstallLocalDisk(vmId,volumeId,haSwitch).then(result=>{
                if(result) {
                    refresh();
                    $uibModalInstance.close();
                }
                self.saveDisabled = false;
            })
        }else{
            $timeout(()=>{
                self.saveDisabled = false;
            },2000)
        }
    }

    // 获取加载的本地磁盘
    self.initTable = (option)=> {
        let defaultParams = {
            serverId: editData.uid,
            pageSize: self.pageSize,
            pageNum: 1
        };
        let params = option ? option : defaultParams;
        localDiskSrv.getInstancesUninstallLocalDiskList(params).then((result)=>{
            if(result && result.data){
                self.tabledata = result.data;
                self.totalNum = result.total;
                self.tableParams = new NgTableParams({ count: 9999 }, { counts: [], dataset: self.tabledata});
                if(result.data.length>0) {
                    self.radioSelect.oneInstance = JSON.stringify(self.tabledata[0]);
                }
            }
        })
    };

    // 切换页码
    self.changePage = (options)=>{
        self.currentPage = options.pageNum;
        self.pageSize = options.pageSize;
        options.serverId = editData.uid;
        self.initTable(options);
    }

    self.initTable();
}