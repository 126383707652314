secrecyRoleSrv.$inject = ["$rootScope", "$http"]
function secrecyRoleSrv($rootScope, $http) {
    var static_url2="awstack-user/v1/";
    return {
        getSystemInfos:function(){
            return $http({
                method:"GET",
                url:"/awstack-user/v1/enterprises/"+localStorage.enterpriseUid+"/system/infos"
            })
        },
         //获取项目下的user top5
         getProjectOfDomain:function(){
            return $http({
                method:"GET",
                url:"/awstack-user/v1/enterprises/"+localStorage.enterpriseUid+"/project/getUsers"
            })
        },
        //获取部门下的user top5
        getUserOfDomain:function(){
            return $http({
                method:"GET",
                url:"/awstack-user/v1/enterprises/"+localStorage.enterpriseUid+"/domains/getUsers"
            })
        },
        
    }
}
export {secrecyRoleSrv}