import  vmSrv from "../services/vmSrv"

serviceMonitorCtrl.$inject=["$scope","$rootScope","$translate","vmSrv","TableCom","$uibModal","$routeParams"];
export function serviceMonitorCtrl($scope, $rootScope, $translate,vmSrv,TableCom,$uibModal,$routeParams){
    var self = $scope;
    self.services = [];
    initTableData();
    function initTableData(){
        vmSrv.getList($routeParams.id).then(function(res) {
            res ? self.loading = true : '';
            if (res && res.data) {
                var tableData = res.data;
                TableCom.init(self,'serviceTable',tableData,'id');
                self.$watch(function() {
                    return self.checkboxes.items;
                }, function(values) {
                    var checked = 0,
                        unchecked = 0,
                        total = self.serviceTable.data.length;
                    self.checkedItems = [];
                    angular.forEach(self.serviceTable.data, function(item) {
                        checked += (self.checkboxes.items[item.id]) || 0;
                        unchecked += (!self.checkboxes.items[item.id]) || 0;
                        if (self.checkboxes.items[item.id]) {
                            self.checkedItems.push(item);
                        }
                    });
                    self.delisDisabled = checked > 0 ? false : true;
                }, true);
            }
        });
    }
    // 添加服务
    self.addServiceModal = function(){
        self.addService = {
            applicationName:'',
            applicationIp:'',
            port:'',
            userName:'',
            password:''
        };
        let addServiceModal = $uibModal.open({
            animation: true,
            templateUrl: "addService.html",
            scope: self
        });
        vmSrv.getServices().then(function(res) {
            if (res && res.data){
                self.services = res.data;
                self.addService.applicationName = self.services[0];
            }
        })
        self.submitted = false;
        self.confirm = function(form){
            if (form.$valid){
                const params = Object.assign({},self.addService,{vmId:$routeParams.id});
                if(self.addService.applicationName){
                    params.applicationName = self.addService.applicationName.appName;
                }
                vmSrv.addService(params).then(function() {
                    initTableData();
                });
                addServiceModal.close();
            }else{
                self.submitted = true;
            }
        }
    }
    // 删除
    self.delService = function() {
        var contents = {
            target: "deleteMonitorService",
            msg:  "<span>"+$translate.instant('aws.instances.serviceMonitor.delTips')+"</span>",
        };
        self.$emit("delete", contents);
    }
    self.$on("deleteMonitorService", function() {
        const names = self.checkedItems.map(item => item.appName);
        console.log(names,'names')
        vmSrv.delService($routeParams.id,names).then(function() {
            initTableData();
        })
    });
}


