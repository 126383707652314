import "./dataclusterSrv";
import {dockerNodeDetailsCtrl} from "../../kubernetes/dockerNodes/dockerNodesModule"
import "../../kubernetes/dockerNodes/dockerNodesSrv";

var dataclusterModule = angular.module("dataclusterModule", ["dataclusterSrv"]);
dataclusterModule.controller("dataclusterCtrl", ["$scope", "$rootScope", "NgTableParams", "$location", "$uibModal", "$translate", "dataclusterSrv","detailFactory" ,"newCheckedSrv", "GLOBAL_CONFIG", "$filter","dockerNodesSrv","alertSrv",
    function($scope, $rootScope, NgTableParams, $location, $uibModal, $translate, dataclusterSrv,detailFactory, newCheckedSrv, GLOBAL_CONFIG, $filter,dockerNodesSrv,alertSrv) {
    var self = $scope;
    self.headers = {
        "mapping": $translate.instant("aws.cluster.mapping"),
        "virtualNodeNum": $translate.instant("aws.cluster.virtualNodeNum"),
        "containerNodeNum": $translate.instant("aws.cluster.containerNodeNum"),
        "name": $translate.instant("aws.cluster.name"),
        "regionKey": $translate.instant("aws.cluster.regionKey"),
        "description": $translate.instant("aws.cluster.description"),
        "config": $translate.instant("aws.cluster.config"),
        "lastTime": $translate.instant("aws.cluster.lastTime"),
        "status":$translate.instant("aws.volumes.status"),
        "createTime":$translate.instant("aws.cluster.createTime")
    };
    self.canDel = false;
    self.canDeploy = false;
    self.canShutOff=false;
    self.canInitConfig=false;
    self.shutDownMsg=$translate.instant("aws.cluster.shutDownMsg");
    self.showShutDownMsg=true;
    self.regionTab = {
        isNodeShow:false,
        isdockerShow:false,
        //canDockerShow:localStorage.installK8s==1?true:false
    }

    self.$on("detailMove",function(e){
        detailFactory.moveTo = true;
        self.moveTo=true;
    });
    
    //一键关闭消息推送
    self.$on("shutDownData",function(e,data){
        if(data.eventType=='shutdown_cluster'){
           if(data.ansible_log&&angular.isObject(data.ansible_log)){
              if(data.ansible_log.task_name=='shutdown begin'){
                let regionName = JSON.parse(data.meta).regionname;
                 let tipMsg= regionName+$translate.instant("aws.datacluster.cluster")+$translate.instant("aws.node.startShutDown");
                 alertSrv.set("", tipMsg, "success", 5000);
                 self.$apply();
              }
              if(data.ansible_log.task_name=='Initiate Shutdown for Other Nodes'){
                let regionName = JSON.parse(data.meta).regionname;
                 let tipMsg= regionName+$translate.instant("aws.datacluster.cluster")+$translate.instant("aws.node.successShutDown");
                 alertSrv.set("", tipMsg, "success", 5000);
                 self.$apply();
              }
              if(data.ansible_log.status=='failed'){
                let regionName = JSON.parse(data.meta).regionname;
                 let tipMsg= regionName+$translate.instant("aws.datacluster.cluster")+$translate.instant("aws.node.failedShutDown");
                 alertSrv.set("", tipMsg, "success", 5000);
                 self.$apply();
              }
           }
        }
    });
    
    var isCanDel = function(checkedItems) {
        if (checkedItems.length === 1) {
            self.canDel = true;
        } else {
            self.canDel = false;
        }
    };
    function successFunc(data) {
        self.applyGlobalSearch = function() {
            var term = self.globalSearchTerm;
            self.centertableParams.filter({ searchTerm: term });
        };
        _.forEach(data, function(item) {
            item.status_ori = $translate.instant("aws.cluster.status." + item.status);
            item.lastTime_ori = $filter('date')(item.createTime, "yyyy-MM-dd HH:mm:ss");
            //item.searchTerm = [item.regionName, item.regionKey, item.status_ori, item.description, item.lastTime_ori].join('\b');
        })
        self.clusterTableData = data;
        self.clusterListData = data;
        self.clusterSearchTerm(({tableData:self.clusterListData,titleData:self.clusterTitleData}))
        self.centertableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: data });
        var tableId = "id";
        //checkedSrv.checkDo(self, data, tableId);
        newCheckedSrv.checkDo(self, data, tableId,"centertableParams"); 
        self.$watch(function() {
            return self.checkedItemscentertableParams;
        }, function(value) {
            isCanDel(self.checkedItemscentertableParams);
            if(value.length==1){
                self.canEdit = true;
                self.canDeploy = true;
                if(value[0].status==3){
                   self.canShutOff=true;
                }  
            }else{
                self.canEdit = false;
                self.canDeploy = false;
                self.canShutOff=false;
            }
        });
    }

    function initRegionTable() {
        dataclusterSrv.getClusterTableData().then(function(result) {
            if (result && result.data) {
                successFunc(result.data);
            }
        });
    }
    initRegionTable();

    self.refresh = function(){
        self.globalSearchTerm=""
        initRegionTable();
    }
    //数据中心设置项的初始化
    self.clusterTitleName="dataCluster";
    if(sessionStorage["dataCluster"]){
       self.clusterTitleData=JSON.parse(sessionStorage["dataCluster"]);
    }else{
       self.clusterTitleData=[
          {name:'cluster.name',value:true,disable:true,search:'regionName'},
          {name:'volumes.status',value:true,disable:false,search:'status_ori'},
          {name:'cluster.regionKey',value:true,disable:false,search:'regionKey'},
          {name:'cluster.virtualNodeNum',value:true,disable:false,search:'destination'},
          {name:'cluster.containerNodeNum',value:true,disable:false,search:'instances'},
          {name:'cluster.createTime',value:true,disable:false,search:'lastTime_ori'},
          {name:'cluster.description',value:true,disable:false,search:'description'},
       ];
    }
    self.clusterSearchTerm=function(obj){
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function(item){
            item.searchTerm="";
            titleData.forEach(function(showTitle){
                if(showTitle.value){
                    // if(showTitle.search=='source'){
                    //    item.searchTerm+=[item.source_ip_address,item._source_port].join("\b");
                    // }else if(showTitle.search=='destination'){
                    //    item.searchTerm+=[item.destination_ip_address,item._destination_port].join("\b");  
                    // }else{
                   item.searchTerm+=item[showTitle.search]+"\b";
                    // }
                 }
            });
        });     
    };


    self.$watch(function(){
        return $location.search()
    },function(val){
        if(val&&val.id){
            self.regionTab.isNodeShow = true;
            self.regionTab.isdockerShow = false;
        }else{
            self.regionTab.isNodeShow = false;
            self.regionTab.isdockerShow = false;
        }
    })

    self.$on("getDetail", function(event, value) {
        self.regionName = $location.search().name;
        self.$watch(function(){
            return  detailFactory
        },function(item){
            self.moveTo = item.moveTo; 
            self.NodedetailData = item.NodedetailData;
            self.hypervisor = item.hypervisor;
            self.ipmiInfo = item.ipmiInfo;
            if(self.hypervisor){
                self.hypervisor.hypervisorHostname=self.hypervisor&&self.hypervisor.hypervisorHostname?self.hypervisor.hypervisorHostname.split(".")[0]:"";    
            }
        },true)
    });

    self.handleDetail = function(item){ 
        if(item&&item.regionUid){
            if(item.status!=0){
                self.services.k8s = item.installK8s;
                detailFactory.moveTo =false;
                $location.search('id='+item.regionUid+'&name='+encodeURIComponent(item.regionName)+'&regionKey='+item.regionKey);
            }else{
                var scope = self.$new();
                var regionData = {
                    name:item.regionName,
                    regionKey:item.regionKey,
                    installEntrance:false
                }
                var installRegionModal = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "installRegion.html",
                    controller:"installRegionCtrl",
                    resolve:{
                        regionData:function(){
                            return regionData;
                        },
                        initRegionTable:function(){
                            return initRegionTable;
                        }
                    }
                });
            }
            
        }else{
            self.moveTo = false;
            detailFactory.moveTo =false;
            $location.url('/system/datacluster')
        }
    }
    self.toRight = function(){
        self.moveTo=false;
        detailFactory.moveTo = false;
    }
    self.createRegion = function() {
        var scope = self.$new();
        var regionModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "createRegion.html",
            controller:"createRegionCtrl",
            resolve:{
                initRegionTable:function(){
                    return initRegionTable;
                }
            }
        });
        
    };
    self.editRegion = function(checkedItems) {
        var scope = self.$new();
        var editRegionModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "editRegion.html",
            controller:"editRegionCtrl",
            resolve:{
                checkedItems: function(){
                    return checkedItems;
                },
                initRegionTable:function(){
                    return initRegionTable;
                },
                context:function(){
                    return self;
                }
            }
        });
        
    };

    self.del = function(checkedItems) {
        // dataclusterSrv.getNode(checkedItems[0].regionUid).then(function(result) {
        //     if (result && result.data && result.data.length) {
        //         self.nodes = result.data;
        //         self.errorMessage = $translate.instant("aws.cluster.delClusternode");
        //     } else {
        //         self.errorMessage = $translate.instant("aws.cluster.delCluster");
        //     }
           
            var content = {
                target: "delCluster",
                msg: "<span>" + $translate.instant("aws.cluster.delCluster") + "</span>",
                data: checkedItems
            };
            if (checkedItems[0].status=='3'&&checkedItems[0].instances>0) {
                content.notDel = true;
                content.msg = $translate.instant("aws.cluster.delClusternode");
            }
            self.$emit("delete", content);
        // });
    };
    self.$on("delCluster", function(e, data) {
        dataclusterSrv.delRegion(data[0].regionUid).then(function() {
            initRegionTable();
        });
    });
    self.deployRegion = function(editData){
        sessionStorage.setItem('curRegionEnterpriseUid',editData.enterpriseUid);
        sessionStorage.setItem('curRegionKey',editData.regionKey);
        sessionStorage.setItem('curRegionStatus',editData.status);
        sessionStorage.setItem('curRegionName',editData.regionName);
        sessionStorage.setItem('curRegionUid',editData.regionUid);
        localStorage.curRegionHOSTNAMELIST = '';
        localStorage.componentEnableCeph = editData.regionConfigScriptMap?editData.regionConfigScriptMap.enable_ceph:"";
        $location.url("/configure/cluster/stepone");

    }
    self.chooseNode = function(){
        self.regionTab.isNodeShow = true;
        self.regionTab.isdockerShow = false;
    }
    self.chooseDocker = function(){
        self.regionTab.isNodeShow = false;
        self.regionTab.isdockerShow = true;
    }

    self.shutDownFinal=function(data){
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "shutDownFinal.html",
            controller: "shutDownFinalCtrl",
            resolve: {
                data: function(){
                    return data;
                },
                initRegionTable:function(){
                    return initRegionTable;
                },
                context:function(){
                    return self;
                },
            }

        });
    };
    //一键关机
    self.shutDown=function(editData){
         if(self.canShutOff){
            let content = {
                target: "shutOffConfirmOne",
                msg: 
                `<div class="show-off-msg">
                    <p>${$translate.instant("aws.cluster.shutOffMsg1")}</p>
                    <p>${$translate.instant("aws.cluster.shutOffMsg2")}</p>
                    <p>${$translate.instant("aws.cluster.shutOffOrder")}</p>
                    <p>${$translate.instant("aws.cluster.shutOffOrderStartMsg")}</p>
                    <p>${$translate.instant("aws.cluster.orderStart1")}</p>
                    <p>${$translate.instant("aws.cluster.orderStart2")}</p>
                    <p>${$translate.instant("aws.cluster.orderStart3")}</p>
                    <p>${$translate.instant("aws.cluster.orderStart4")}</p>
                    </div>
                `,
                data: editData
             };
             self.$emit("delete", content);
         }
    };
    self.$on("shutOffConfirmOne", function(e, data) {
         let content = {
            target: "shutOffConfirmTwo",
            msg: "<span>请再次确认是否要关闭所选数据中心</span>",
            data: data
         };
         self.$emit("delete", content);
    });
    self.$on("shutOffConfirmTwo", function(e, data) {
         //弹出一个要输入用户名和密码的提示框
         self.shutDownFinal(data);
    });
    dockerNodeDetailsCtrl($scope, $translate, $rootScope, $uibModal, NgTableParams, dockerNodesSrv,newCheckedSrv);

}])
.controller("createRegionCtrl",["$scope","dataclusterSrv","initRegionTable","$uibModal","$uibModalInstance",function(scope,dataclusterSrv,initRegionTable,$uibModal,$uibModalInstance){
        scope.postParams = {
            regionName:"",
            description:""
        };
        scope.submitted = false;
        scope.interacted = function(field) {
            scope.field_form = field;
            return scope.submitted || field.name.$dirty;
        };
        scope.confirm = function() {
            if (scope.field_form.$valid) {
                dataclusterSrv.createRegion(scope.postParams).then(function(res) {
                    $uibModalInstance.dismiss("cancel");
                    if(res&&res.data){
                        var regionData = {
                            name:res.data.regionName,
                            regionKey:res.data.regionKey,
                            installEntrance:true
                        }
                        var installRegionModal = $uibModal.open({
                            animation: scope.animationsEnabled,
                            templateUrl: "installRegion.html",
                            controller:"installRegionCtrl",
                            resolve:{
                                regionData:function(){
                                    return regionData;
                                }
                            }
                        });
                        initRegionTable();
                    }
                    
                });
            } else {
                scope.submitted = true;
                scope.createRegion.$dirty = true;
            }
        };
}])
.controller("editRegionCtrl",["$scope","dataclusterSrv","initRegionTable","context","checkedItems","$uibModalInstance",function(scope,dataclusterSrv,initRegionTable,context,checkedItems,$uibModalInstance){
        scope.postParams = {
            regionName:"",
            description:""
        };
        scope.submitted = false;
        scope.postParams.regionName = checkedItems[0].regionName;
        scope.postParams.description = checkedItems[0].description;
        scope.interacted = function(field) {
            scope.field_form = field;
            return scope.submitted || field.name.$dirty || field.description.$dirty;
        };
        scope.confirm = function() {
            if (scope.field_form.$valid) {
                if(checkedItems[0].regionKey == localStorage.regionKey){
                    localStorage.regionName = scope.postParams.regionName;
                }
                dataclusterSrv.editRegion(scope.postParams,checkedItems[0]).then(function(res) {
                    $uibModalInstance.dismiss("cancel");
                    context.$emit('region-refresh',{type:"region"});
                    initRegionTable();
                });
            } else {
                scope.submitted = true;
                scope.editRegion.$dirty = true;
            }
        };
}])
.controller("installRegionCtrl",["$scope","$uibModalInstance","regionData",function(scope,$uibModalInstance,regionData){
        scope.regionRange = regionData.name;
        scope.installEntrance = regionData.installEntrance;
        scope.regionkeyCode = regionData.regionKey;
        scope.confirm = function(regionkeyCode) {
            location.replace("/datacenter/#/info/stepone/"+regionkeyCode)
            $uibModalInstance.dismiss("cancel");
        };
}])
.controller("importHardwareCtrl",["$scope","$rootScope","$uibModalInstance","editData","dataclusterSrv","physicalConductorSrv",function($scope,$rootScope,
    $uibModalInstance,editData,dataclusterSrv,physicalConductorSrv) {
        var self = $scope;
        self.IPMIsubmitValid = false;
        self.checkIPMIPass = false;
        self.IPMI_info = {
            ip_0: "",
            ip_1: "",
            ip_2: "",
            ip_3: "",
            username: "",
            password: ""
        }

        //判断节点是否有IMPI信息, 有则回显, 没有则新增
        dataclusterSrv.getIpmiInfo(editData[0].nodeUid).then(function(result) {
            if(result && result.data && result.data.ipmiAuthInfo) {
                self.hasIpmi = true;
                var ipmiAuthInfo = angular.fromJson(result.data.ipmiAuthInfo);
                var ipmi_address_arr = ipmiAuthInfo.ipmi_address.split(".");
                self.IPMI_info.ip_0 = ipmi_address_arr[0];
                self.IPMI_info.ip_1 = ipmi_address_arr[1];
                self.IPMI_info.ip_2 = ipmi_address_arr[2];
                self.IPMI_info.ip_3 = ipmi_address_arr[3];
                self.IPMI_info.username = ipmiAuthInfo.ipmi_username;
                self.IPMI_info.password = ipmiAuthInfo.ipmi_password;
            }else {
                self.hasIpmi = false;
            }
        });

        function IPMI_address(){
            return self.IPMI_info.ip_0 + "." + self.IPMI_info.ip_1 + "." + self.IPMI_info.ip_2 + "." + self.IPMI_info.ip_3;
        }

        self.changeIpAddr = function() {
            self.checkIPMIPass = false;
        }

        self.verifyIPMI = function(IPMIfiled){
            if(IPMIfiled.$valid) {
                self.checkIPMIPass = false;
                let postVerify = {
                    nodes:[
                        {   
                            "ipmi_auth_info":{
                                "ipmi_address": IPMI_address(),
                                "ipmi_username": self.IPMI_info.username, 
                                "ipmi_password": self.IPMI_info.password
                            }
                        }
                    ]
                }
                physicalConductorSrv.checkIPMI(postVerify).then(function(res) {
                    if(res && res.data){
                        var check = angular.fromJson(res.data);
                        if(check.nodes[0].check) {
                            self.checkIPMIPass = true;
                            $rootScope.$broadcast("alert-success", "010")
                        }else {
                            $rootScope.$broadcast("alert-error", "011");
                        }
                    }
                    
                })
            }else{
                self.IPMIsubmitValid = true;
            }
        }

        self.confirm = function(IPMIForm){
            if(IPMIForm.$valid) {
                var options = {
                    regionKey: localStorage.regionKey,
                    enterpriseUid: localStorage.enterpriseUid,
                    nodeId: editData[0].nodeUid,
                    nodeName: editData[0].hostName,
                    ipmiAddress: IPMI_address(),
                    ipmiUsername: self.IPMI_info.username,
                    ipmiPassword: self.IPMI_info.password,
                    type: 0
                }
                if(self.hasIpmi) {
                    dataclusterSrv.editIpmiInfo(options).then(function(result) {
                        if(result && result.status == "0"){

                        }
                    });
                }else {
                    dataclusterSrv.addIpmiInfo(options).then(function(result) {
                        if(result && result.status == "0"){
                            
                        }
                    });
                }
                $uibModalInstance.close();
            }else {
                self.IPMIsubmitValid = true;
            }
        }
}])
.controller("shutDownFinalCtrl",["$rootScope","$scope","dataclusterSrv","initRegionTable","data","$uibModalInstance","alertSrv","context","$translate",function($rootScope,scope,dataclusterSrv,initRegionTable,data,$uibModalInstance,alertSrv,context,$translate){
        scope.user = {
            username:localStorage.userName,
            password:""
        };
        scope.submitInValid = false;
        scope.confirm = function(clusterForm) {
            if (clusterForm.$valid) {
                context.shutDowning=true;
                var options={
                    enterpriseLoginName: localStorage.enterpriseLoginName,
                    userName: scope.user.username,
                    password: scope.user.password,
                }
                dataclusterSrv.checkUser(options).then(function(res){
                    //用户验证通过
                    if(res.code==='0'){
                       $uibModalInstance.close();
                       dataclusterSrv.shutDown(data.regionKey,data.regionName).then(function(){
                          initRegionTable();
                       }).finally(function(){
                          context.shutDowning=false;
                       });  
                    }else{
                        let tipMsg = $translate.instant("aws.shutDownDatacenter." + res.code);
                        alertSrv.set("", tipMsg, "error", 5000);
                    }
                });    
            } else {
                scope.submitInValid = true;
            }
        };
}])