
import "../volumesSrv";
systemVolumesCtrl.$inject=['$scope', "$rootScope", "volumesDataSrv", "$translate","NgTableParams","newCheckedSrv","volumesSrv","utilTool","$location","$uibModal"];
function systemVolumesCtrl($scope, $rootScope, volumesDataSrv,$translate,NgTableParams,newCheckedSrv,volumesSrv,utilTool,$location,$uibModal){
    let self = $scope;
    self.context = self;
    self.pageSize = 10;
    self.pageCount = 10;
    self.table = {};
    // self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')];
    if(localStorage.permission == 'enterprise'){
        self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')]
    }else{
        self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_ConfigManagement'),$translate.instant('aws.menu.System_Storage')]
    }
    self.titleName="systemVolumes";
    if(sessionStorage["systemVolumes"]){
       self.tableCols=JSON.parse(sessionStorage["systemVolumes"]);
    }else{
        self.tableCols = [
            { field: "check", title: "",headerTemplateURL:"systemheaderCheckbox.html",show: true },
            { field: "name", title: self.headers.systemDiskName,sortable: "name",show: true,disable:true},
            { field: "size", title: self.headers.config,sortable: "size",show: true,disable:false},
            { field: "statusCopy", title: self.headers.status,sortable: "status",show: true,disable:true },
            { field: "voltype", title: self.headers.voltype,sortable: "voltype",show: true,disable:false },
            { field: "description", title: self.headers.systemDiskDescription,sortable: "description",show: true,disable:false },
        ];
    }
    self.searchList = [
        {name:$translate.instant('aws.volumes.table.status.all'),id:null,key:""},
        {name:$translate.instant('aws.volumes.table.status.available'),id:"available",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.inavailable'),id:"inavailable",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.creating'),id:"creating",value:$translate.instant('aws.instances.table.status.error'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.deleting'),id:"deleting",value:$translate.instant('aws.instances.table.status.paused'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.in-use'),id:"in-use",value:$translate.instant('aws.instances.table.status.suspended'),key:"status_ori"}
    ];

    self.lineDetailIn = function (item) {
        utilTool.setLocalStorage([item]);
        $location.url(`/cvm/volumes?id=${item.id}`)
    }

    //退出详情页清空勾选
    self.$watch(function () {
        return volumesDataSrv.clear;
    }, function(value, old) {
        
        if (value !== old) {
            self.checkboxessystemTableParams.items = {};
        }
    },true)
    //资源导出
    self.exportResource = function(){
        if (self.globalSearchTerm) {
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/volumes?regionKey=${localStorage.regionKey}&type=1&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm?self.globalSearchTerm:""}&status=${self.statusSelected.searchItem.id?self.statusSelected.searchItem.id:""}`
        } else {
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/volumes?regionKey=${localStorage.regionKey}&type=1&token=${localStorage.$AUTH_TOKEN}&status=${self.statusSelected.searchItem.id?self.statusSelected.searchItem.id:""}`
        }
        utilTool.downloadFile(url);
    }
    self.showExport = utilTool.isAllDomainSelect;
    //项目切换的监听函数
    self.$watch(function(){
        return localStorage.selectedAllDomain;
    },function(ne){
        
        utilTool.projectDomainTableCol(ne,2,self)
    })

    //切换页码
    self.changePage=function(options){
        options.pattern = self.globalSearchTerm?self.globalSearchTerm:null;
        options.status = self.statusSelected.searchItem?self.statusSelected.searchItem.id:null;
        options.type = 1;
        self.pageCount = options.pageSize
        getVolumes(options);
    };
    //后端搜索
    self.searchBackend = function(options){
        console.log(options)
        var pageOption = {
            pageSize: self.pageSize,
            pageNum:1,
            pattern:self.globalSearchTerm?self.globalSearchTerm:null,
            status:self.statusSelected.searchItem?self.statusSelected.searchItem.id:null,
            type: 1
        };
        if(options.type=='refresh'){
            self.currentPage = 1;
            self.globalSearchTerm = null;
            pageOption.pattern = null;
            pageOption.status = null;
            self.statusSelected.searchItem = self.searchList[0]
        }
        getVolumes(pageOption);
    }
    // $rootScope.$on("refresh", function(){
    //     self.searchBackend({
    //         search: "",
    //         select: {name: '全部', id: null, key: '', $$hashKey: 'object:2261'},
    //         type: "refresh",
    //     })
    // })
    function getVolumes(options) {
        if(!localStorage.cinderService) return;
        volumesDataSrv.getVolumeList(options).then(function(result) {
            if(result && result.data && angular.isArray(result.data)){
                //过滤掉result.data中vol.instance="fixedImageInstanceName"的元素
                //result.data=result.data.filter(vol=>vol.instance[0]!="fixedImageInstanceName");
                result.data.map(item => {
                    item.statusCopy= $translate.instant("aws.volumes.table.status."+item.status);
                    item.voltype =  item.storageInfo?item.storageInfo.disPlayName:"";
                    item._type=angular.copy(item.type);
                    if(item.domainName=="default"){
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    }else{
                        item.domainName = item.domainName;
                    }
                    if(item.projectName=="admin"){
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    }else{
                        item.projectName = item.projectName;
                    }
                    if (item.description == null) {
                       item.description = '';
                    }else if(item.description == "auto-created_from_restore_from_backup"){
                        item.description = $translate.instant("aws.volumes.fromBackup");
                    }
                    if(angular.isArray(item.attachments) && item.attachments.length == 1 && item.attachments[0].device && item.attachments[0].device.slice(-2) == "da"){
                        item.root_device = true;
                    }
                })
                // self.system_data = result.data.filter(volume => (volume.bootable && volume.root_device));
                self.system_data = result.data;
                // self.system_data = self.system_data.filter(volume => (volume.imageMetadata.disk_format != "iso"));
                successFunc(self.system_data);
            }
            self.totalNum = result.total;
        });
    }

    function successFunc(res){
		self.system_data = self.configSearch(self.system_data,self.tableCols);
        self.systemTableParams = new NgTableParams({ count: self.pageCount }, { counts: [], dataset: self.system_data });
        newCheckedSrv.checkDo(self,"","id","systemTableParams");
    };

    self.getData = function(){
        var options = {
            "pageNum": 1,
            "pageSize": 10,
            "type": 1
        }
        self.currentPage = 1;
        self.globalSearchTerm = "";
        if(!localStorage.cinderService) return;
        volumesDataSrv.getVolumeList(options).then(function(result) {
            if(result && result.data && angular.isArray(result.data)){
                //过滤掉result.data中vol.instance="fixedImageInstanceName"的元素
                //result.data=result.data.filter(vol=>vol.instance[0]!="fixedImageInstanceName");
                result.data.map(item => {
                    item.statusCopy= $translate.instant("aws.volumes.table.status."+item.status);
                    item.voltype =  item.storageInfo?item.storageInfo.disPlayName:"";
                    item._type=angular.copy(item.type);
                    if(item.domainName=="default"){
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    }else{
                        item.domainName = item.domainName;
                    }
                    if(item.projectName=="admin"){
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    }else{
                        item.projectName = item.projectName;
                    }
                    if (item.description == null) {
                       item.description = '';
                    }else if(item.description == "auto-created_from_restore_from_backup"){
                        item.description = $translate.instant("aws.volumes.fromBackup");
                    }
                    if(angular.isArray(item.attachments) && item.attachments.length == 1 && item.attachments[0].device && item.attachments[0].device.slice(-2) == "da"){
                        item.root_device = true;
                    }
                })
                // self.system_data = result.data.filter(volume => (volume.bootable && volume.root_device));
                self.system_data = result.data;
                // self.system_data = self.system_data.filter(volume => (volume.imageMetadata.disk_format != "iso"));
                successFunc(self.system_data);
            }
            self.totalNum = result.total;		
        });
    }
    console.log(self.volume)
    //下拉框中的扩照
    self.expandVolumeSize = function(editData,init) {
        if(!self.btn.canExtends) return;
        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "expandVolumeSizeModel.html",
            controller: "expandVolumeSizeCtrl",
            resolve: {
                initDataVolumesTable: function() {
                    return init;
                },
                editData: function() {
                    return editData;
                }
            }
        });
        return modalInstance.result.then(function(post){
            self.volumeSize = post;
        });
    };

    self.resetVolumeStatus = function(obj) {
        if(!self.btn.resetVol) return;
        var info='迁移中的云硬盘可以把状态修改为正常状态(可以或已使用,和迁移前的状态相同),但执行这个操作时,需要确认当前云硬盘的迁移操作已经中断(即实际的迁移操作已经失败,但云硬盘状态没有恢复),否则会导致当前的迁移操作失败';
        var content = {
            target: "resetVolumeStatus",
            msg: "<div class='resetVolume-info'>" + info + "</div>",
            data: obj
        };
        self.$emit("delete", content);
    };
    self.$on("resetVolumeStatus", function(e,data) {
        volumesSrv.resetStatus(data.id).then(function(){
            self.getData();
        })
    });

    self.volume.initTablefunc = function(){
        self.getData()
    }
    
    $scope.$on("volumeSocket",function(e,data){
        if(self.system_data.length){
            self.system_data.map(function(obj){
                if(obj.id === data.eventMata.volume_id){
                    obj.status = data.eventMata.status;
                    if(obj.status == "deleted"){
                        _.remove(self.system_data,function(val){
                            return val.status =="deleted";
                        });
                    }
                }
            });
        }
        if(self.systemTableParams){
            self.systemTableParams.reload();
            self["checkboxes"+ "systemTableParams"].items={};
        }
        
    });

    // 表格checkbox选中触发
    self.$watch(function(){
        return self.checkedItemssystemTableParams;
    },function(values,old){
        
        if(values&&old&&values.length!=old.length){
            utilTool.setLocalStorage(values);
        }
        self.tablchk(values)    
    })
    self.getData()
    
}
export {systemVolumesCtrl}
