import "./storageManagementSrv";

import * as controllers from './controller';
import * as directives from './directive';
import * as services from './services';
var storageManagementModule = angular.module("storageManagementModule", ["storageManagementSrvModule"]);

storageManagementModule.config(['$controllerProvider', '$compileProvider',"$provide",
    function($controllerProvider,$compileProvider,$provide){
        $controllerProvider.register(controllers);
        $compileProvider.directive(directives);
        $provide.service(services);
    }
])

