var tableService = angular.module("tcsSrv", []);
tableService.service("tcsSrv", function($rootScope, $http) {
    var static_url = "/awstack-user/v1/enterprises/";
    var static_dict_url = "/awstack-user/v1/";
    return {
         //查找某企业下的所有公有云配置通过固定region
        getDictDataByKey: function(key) {
            return $http({
                method: "get",
                url: static_dict_url + "params",
                params:{
                    parentId:3,
                    enterpriseUid:localStorage.enterpriseUid,
                    regionUid:key
                }
            });
        },
    };
});
