import "./tcsSrv";
var tcsModule = angular.module("tcsModule", ["ngTable", "ngAnimate", "ui.bootstrap", "ui.select", "tcsSrv"]);
tcsModule.controller("TcsCtrl", function($scope, $rootScope, NgTableParams, $location, $uibModal, tcsSrv, $window,$sce, $timeout) {
    var self = $scope;
   
    function init(){ 
        self.iframeShow = false;
        var url = getUrl();
        //动态获取url
        $scope.tcsUrl = $sce.trustAsResourceUrl(JSON.parse(localStorage.$LOGINDATA).casLoginUrl + url);
        //$('#TCS_Package').attr('src',JSON.parse(localStorage.$LOGINDATA).casLoginUrl + url);
        //判断与localStoryurl是否相同。如果相同就不刷新
        initHtml();
        $timeout(function(){
            self.iframeShow = true;
            initHtml();
        },1000)
    }
    function getUrl(){
        var url = "";
        $location.$$path.split("/").forEach((item, i) => {
            if(i>2) {
                url = url + "/" +item
            }
        });
        return url;
    }
    function initHtml(){
        var url = getUrl();
        let oIframeWidth = 0;
        const oIframe = document.getElementById('TCS_Package');
        const deviceHeight = document.documentElement.clientHeight;
        const deviclientWidth = document.documentElement.clientWidth;
        oIframe.style.height = (Number(deviceHeight)) + 'px'; //数字是页面布局高度差
        //oIframe.style.width = (Number(deviclientWidth)-40) + 'px'; //数字是页面布局高度差
        oIframeWidth = (Number(deviclientWidth)-40);
        oIframe.style.marginTop="-123px";
        //概览页面不需要left
        if(url != "/overview/overview"){//1643px
            oIframe.style.marginLeft="-220px";
        }else{
            oIframe.style.marginLeft="0px";
            oIframe.style.width = "100%";
        }
        if (document.body.offsetWidth > 1366 && (document.body.offsetWidth < 1440 || document.body.offsetWidth == 1440)) {
            oIframe.style.marginTop="-107px";
        }else if (document.body.offsetWidth <= 1366) {
            oIframe.style.width="122.5%";
            oIframe.style.marginTop="-110px";
        }
    }
    init();
    //initHtml();
    
    window.onresize = function () {
        initHtml();
    };
});
