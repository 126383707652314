import "./instances/instancesModule";
import "./instances/affinityModule";
import "./instances/portsModule";
import "./instances/elasticExpansionModule";
import "./instances/labelModule"

import "./images/imagesModule";
import "./images/makeImageModule";
import "./networks/networksModule";
import "./networks/physicalNetworksModule";
import "./volumes/snapshotsModule";
import "./volumes/regularSnap";

import './volumes/fileStorageModel'

import "./networks/routersModule";
import "./networks/floatingIpModule";
import "./networks/bandwidthModule";
import "./networks/netfirewallModule";
import "./networks/qosModule";
import "./networks/vpnModule";
import "./security/keyPairsModule";
import "./security/securityGroupModule";
import "./security/securityTemplateModule";
import "./overview/cvmViewModule";
import "./recycle/recycleModule";
import "./recycle/recycleCsModule";
import "./recycle/recycleDbModule";
import "./extension/extensionCtrl";
import "./loadbalancers/loadBalanceModule";
import "./loadbalancersNew/loadBalanceModule";
import "./extension/scalpolicyCtrl";
import "./elasticExtension/";
import "./alarm/";
import "./volumes/volumesQoSModule";
import "./console/consoleModule";
import "./backupRecovery";
import "./volumes/bjectStorageModule";
import "./volumes/ObjectsModule";
import "./volumes/sharesModule";
import "../tcs/tcsModule";
import "./volumes/localDiskModule";

import "./networks/loadbalancingcertificateModule";
import './loadbalancersmodile/all'
import "./database/all"

angular.module("cvm", [
    "enhance-module",
    "fileStorageModel",
    "loadbalancingcertificateModule",
    "sharesModule",
    "instancesModule",
    "affinityModule",
    "portsModule",
    "LabelModule",
    "elasticExpansionModule",
    "imagesModule",
    "makeImageModule",
    "networksModule",
    "physicalNetworksModule",
    "snapshotsModule",
    "volumesQoSModule",
    "regularSnapModule",
    "routersModule",
    "floatipsModule",
    "bandwidthModule",
    "netfirewallModule",
    "qosModule",
    "vpnModule",
    "keyPairsModule",
    "securityGroupModule",
    "securityTemplateModule",
    "cvmViewModule",
    "recycleModule",
    "recycleCsModule",
    "recycleDbModule",
    "extensionModule",
    "balanceModule",
    "loadBalanceModule",
    "scalpolicyModule",
    "elasticExtension",
    "alarmModule",
    "consoleModule",
    "backupRcoveryModule",
    "ObjectsModule",
    "bjectStorageModule",
    "tcsModule",
    "localDiskModule",
    "allCloudDatabaseModule"
]);
