enhanceDetailListen.$inject = ['$translate', '$uibModal', 'TableCom', "enhanceDetailListenSrv","$location"];
function enhanceDetailListen($translate, $uibModal, TableCom, enhanceDetailListenSrv, $location) {
    return {
        restrict: "E",
        replace: true,
        scope:{},
        templateUrl: "js/cvm/loadbalancersmodile/tmpl/enhance-detail-listen.html",
        controller:function($scope) {
            const self = $scope
            // 监听器分页
            self.total = 0;
            self.pageNum = 1;
            self.pageSize = 5;

            // 云成员分页
            self.PageNum = 1;
            self.PageSize = 5;
            self.Total = 0;

            self.checkedObj = {};
            self.listener_data = [];
            self.ttEquipmentData = [];
            self.lisChecked = false;
            self.allBottonStatus = false

            self.uid = $location.search().uid
            self.hostend = true         // 禁用
            self.recover = true         // 恢复
            self.hoststart = true       // 启用
            self.hostunbundle = true    // 解绑

            self.lisUpDisabled = true;         // 启用
            self.lisEditDisabled = true;       // 编辑
            self.lisDeleteDisabled = true;     // 删除
            self.lisForbiddenDisabled = true;  // 禁用
            self.choiceAll = localStorage.selectedAllDomain;        // 是否选择全部

            self.checkboxes = {
                checked: false,
                items: {},
            };

            self.radiolisTab = {
                checked: ""
            }

            self.snapshot_search = {
                globalSearchTerm: '',
            };
            self.statusConfig = {
                "statusSelected": {}
            }

            self.host_snapshot_search = {
                globalSearchTerm: '',
            };
            self.host_statusConfig = {
                "statusSelected": {}
            }

            self.TitleName = "monitorModule";
            if (sessionStorage["monitorModule"]) {
                self.TitleData = JSON.parse(sessionStorage["monitorModule"]);
            } else {
                self.TitleData = [
                    { name: "common.name", value: true, disable: true, search: 'name' },
                    { name: "loadbalance.listenerProtocol", value: true, disable: false, search: 'protocol' },
                    { name: "loadbalance.port", value: true, disable: false, search: 'protocolPort' },
                    { name: "loadbalance.balanceAlgorithm", value: true, disable: false, search: 'lbAlgorithm' },
                    { name: "recovery.header.state", value: true, disable: false, search: 'status' },
                ];
            }

            self.titleName = "bindhostModule";
            if (sessionStorage["bindhostModule"]) {
                self.titleData = JSON.parse(sessionStorage["bindhostModule"]);
            } else {
                self.titleData = [
                    { name: "recovery.header.ipaddress", value: true, disable: true, search: 'ipAddr' },
                    { name: "recovery.header.instanceName", value: true, disable: false, search: 'name' },
                    { name: "recovery.header.state", value: true, disable: false, search: 'status' },
                    { name: "recovery.header.examine", value: true, disable: false, search: 'healthCheckState' },
                    { name: "recovery.header.port", value: true, disable: false, search: 'protocolPort' },
                ];
            }

            self.lisChangePage = function({pageNum, pageSize}){
                self.pageNum = pageNum
                self.pageSize = pageSize
                initTable();
            };
            
            // 搜索
            self.searchBackend = function({type}){
                if(type == 'refresh'){
                    self.snapshot_search.globalSearchTerm = '';
                    self.pageNum = 1;
                }
                initTable()
            }

            initTable()
            /**
             * 监听器列表
             */
            function initTable() {
                const config = {
                    pageSize: self.pageSize,
                    pageNum: self.pageNum,
                    pattern: self.snapshot_search.globalSearchTerm
                }
                enhanceDetailListenSrv.soundMonitorList(self.uid, config).then(res => {
                    if (res.data) {
                        self.total = res.total;
                        self.listener_data = res.data.map(item=>{
                            item.status = item.status.toLowerCase()
                            return item
                        });
                        successLisFunc()
                    }
                })
            }

            function successLisFunc() {
                SearchTearm({ tableData: self.listener_data, titleData: self.TitleData });
                TableCom.init(self, 'lisTable', self.listener_data, 'id', 10, '');

                self.checkedObj = {}
                self.lisChecked = false;
                self.radiolisTab.checked = ''
                self.lisEditDisabled = true;       // 编辑
                self.lisDeleteDisabled = true;     // 删除
                self.lisUpDisabled = true;         // 启用
                self.lisForbiddenDisabled = true;  // 禁用
            }

            function SearchTearm (obj) {
                var tableData = obj.tableData;
                var titleData = obj.titleData;
                tableData.map(function (item) {
                    item.searchTerm = [];
                    titleData.forEach(function (showTitle) {
                        if (showTitle.value) {
                            item.searchTerm.push(item[showTitle.search]);
                        }
                    });
                    item.searchTerm = item.searchTerm.join("\b");
                });
            }
            
            // 绑定云主机列表
            function initMemberTable(id) {
                const config = {
                    pageNum: self.PageNum,
                    pageSize: self.PageSize,
                    pattern: self.host_snapshot_search.globalSearchTerm
                }

                const headers = {}
                if(self.choiceAll == 1){
                    const { projectUid, projectName, domainUid, domainName } = $location.search()
                    headers.project_id= projectUid;
                    headers.project_name= encodeURI(projectName);
                    headers.domain_id= domainUid;
                    headers.domain_name= encodeURI(domainName);
                }

                enhanceDetailListenSrv.getMemberList(id, config, headers).then(res=>{
                    self.ttEquipmentData = res.data.map(item=>{
                        item.status = item.status.toLowerCase()
                        return item
                    })
                    self.Total = res.total
                    SearchTearm({ tableData: self.ttEquipmentData, titleData: self.titleData });
                    TableCom.init(self, 'memberTable', self.ttEquipmentData, 'id', self.PageSize, 'checkboxes');
                })
            }

            self.ChangePage = function ({pageNum,pageSize}) {
                self.PageNum = pageNum;
                self.PageSize = pageSize;
                initMemberTable(self.checkedObj.uid)
            }

            // 云主机成员搜索
            self.hostSearchBackend = function({type}){
                if(type == 'refresh'){
                    self.host_snapshot_search.globalSearchTerm = '';
                    self.PageNum = 1;
                }
                initMemberTable(self.checkedObj.uid)
            }

            //成员恢复
            self.memStartRecover = function () {
                var content = {
                    target: "recover",
                    msg: "<span>" + '是否恢复当前云主机' + "</span>",
                    data: self.checkedItems,
                    type: "info",
                    btnType: "btn-info"
                };
                self.$emit("delete", content);
            }
            self.$on("recover", function (e, data) {
                const config = data.map(item=>{
                    return {
                        listenerUid:item.listenerUid,
                        id : item.id,
                        name: item.name,
                        address : item.address,
                        protocolPort : item.protocolPort,
                    }
                })
                enhanceDetailListenSrv.recover(config).then(hostCallback);
            });
            //成员启用
            self.memStartUp = function () {
                var content = {
                    target: "startUpMem",
                    msg: "<span>" + $translate.instant("aws.loadbalance.enableLisTip") + "</span>",
                    data: self.checkedItems,
                    type: "info",
                    btnType: "btn-info"
                };
                self.$emit("delete", content);
            }
            self.$on("startUpMem", function (e, data) {
                enhanceDetailListenSrv.startforhost(data[0].id,'ENABLE').then(hostCallback);
            });
            //成员禁用
            self.memForbidden = function (item) {
                var content = {
                    target: "forbiddenMem",
                    msg: "<span>" + $translate.instant("aws.loadbalance.forbiddenTip") + "</span>",
                    data: self.checkedItems
                };
                self.$emit("delete", content);
            }
            self.$on("forbiddenMem", function (e, data) {
                enhanceDetailListenSrv.startforhost(data[0].id, 'DISABLED').then(hostCallback);
            });
            //成员解绑
            self.deleteMember = function () {
                var content = {
                    target: "delMember",
                    msg: "<span>" + $translate.instant("aws.loadbalance.deleteTip") + "</span>",
                    data: self.checkedItems
                };
                self.$emit("delete", content);
            };
            self.$on("delMember", function(e, data) {
                const config = data.map(item=>{
                    return {
                        id : item.id,
                        name: item.name,
                        address : item.address,
                        protocolPort : item.protocolPort,
                    }
                })
                enhanceDetailListenSrv.unbindHost(self.checkedObj.uid, config).then(hostCallback)
            });
            //监听器禁用
            self.lisForbidden = function () {
                var content = {
                    target: "forbiddenLis",
                    msg: "<span>" + $translate.instant("aws.loadbalance.forbiddenTip") + "</span>",
                    data: self.checkedObj
                };
                self.$emit("delete", content);
            }
            self.$on("forbiddenLis", function (e, data) {
                enhanceDetailListenSrv.startformonitor(data.uid,"DISABLED").then(ListenerCallback);
            });
            //监听器启用
            self.lisStartUp = function () {
                var content = {
                    target: "enableLis",
                    msg: "<span>" + $translate.instant("aws.loadbalance.enableLisTip") + "</span>",
                    data: self.checkedObj,
                    type: "info",
                    btnType: "btn-info"
                };
                self.$emit("delete", content);
            }
            self.$on("enableLis",function(e, data){
                enhanceDetailListenSrv.startformonitor(data.uid,"ENABLE").then(ListenerCallback);
            });
            //监听器删除
            self.deleteListener = function () {
                var content = {
                    target: "delListener",
                    msg: "<span>" + $translate.instant("aws.loadbalance.deleteTip") + "</span>",
                    data: self.checkedObj
                };
                self.$emit("delete", content);
            };
            self.$on("delListener", function (e, {uid: listenerId}) {
                enhanceDetailListenSrv.soundMonitorDelete({listenerId}).then(ListenerCallback);
            });
            // 打开云主机列表
            self.openServerModel = function () {
                $uibModal.open({
                    animation: true,
                    templateUrl: "js/cvm/loadbalancersmodile/tmpl/open-server-model.html",
                    controller: "openServerModel",
                    resolve: {
                        checked: () => self.checkedObj,
                        initMemberTable: ()=> initMemberTable,
                        ttEquipmentData: ()=> self.ttEquipmentData
                    }
                });
            }

            // 新增监听器
            self.openListenerModel = function (type) {
                $uibModal.open({
                    animation: true,
                    templateUrl: "js/cvm/loadbalancersmodile/tmpl/add-listen.html",
                    controller: "newMonitorCtrl",
                    resolve: {
                        type: () => type,
                        uid: () => self.uid,
                        editData: () => self.checkedObj,
                        initListenerTab: ()=> initTable,
                    }
                });
            }

            // 单选
            self.$watch(()=> self.radiolisTab.checked, function (value) {
                if (!value) {
                    self.lisChecked = false;
                    return;
                }
                self.checkedObj = self.listener_data.find(item => item.id == value)

                self.lisChecked = true;
                self.lisEditDisabled = false;
                self.lisDeleteDisabled = false;
                
                const whiteList = ['enable', 'true']
                if(whiteList.findIndex(item=>item == self.checkedObj.status) != -1){
                    self.lisUpDisabled = true
                    self.lisForbiddenDisabled = false
                } else {
                    self.lisUpDisabled = false
                    self.lisForbiddenDisabled = true
                }
                self.allBottonStatus = false
                if(self.checkedObj.status == "disabled"){
                    self.allBottonStatus = true
                }
                initMemberTable(self.checkedObj.uid);
            });

            // 复选
            self.$watch(() => self.checkboxes.items, function (val, old) {
                self.checkedItems = [];
                var arr = [];
                for (var i in self.checkboxes.items) {
                    arr.push(self.checkboxes.items[i])
                }
                if (val && arr.length >= 0) {
                    for (var key in val) {
                        if (val[key]) {
                            self.ttEquipmentData.forEach(item => {
                                if (item.id == key) {
                                    self.checkedItems.push(item);
                                }
                            })
                        }
                    }
                }
                selectShowBtnFn()
            }, true);

            /**
             * 选择显示按钮
             */
            function selectShowBtnFn(){
                const length = self.checkedItems.length
                if (length != 0) {
                    self.hostunbundle = false   // 解绑
                    self.recover = !(self.checkedItems.every(value => (value.status == "enable" && value.healthCheckState == "OFFLINE")))
                    if (length == 1) {
                        const {status} = self.checkedItems[0]
                        const whiteList = ['enable', 'true']
                        if(whiteList.findIndex(item=> item == status) == -1){
                            self.hoststart = false          // 启用
                            self.hostend = true             // 禁用
                        } else {
                            self.hoststart = true           // 启用
                            self.hostend = false            // 禁用
                        }
                    } else {
                        self.hoststart = true               // 启用
                        self.hostend = true                 // 禁用
                    }
                } else {
                    self.hoststart = true
                    self.hostend = true
                    self.hostunbundle = true
                    self.recover = true
                }
            }

            /**
             * 监听器操作回调函数
             * @param {*} res 响应结果
             */
             function ListenerCallback(res){
                if(res){
                    self.pageNum = 1
                    initTable()
                }
            }

            /**
             * 监听器成员回调函数
             * @param {*} res 响应结果
             */
            function hostCallback(res){
                if(res){
                    self.PageNum = 1;
                    initMemberTable(self.checkedObj.uid);
                }
            }
        }
    };
}

export { enhanceDetailListen }