import "./dpdkSrv";

angular.module("dpdkModule", ["dpdkSrv"])
    .controller("dpdkCtrl", ["$scope", "$uibModal", "$translate", "dpdksrv", "TableCom", function ($scope, $uibModal, $translate, dpdksrv, TableCom) {
        let self = $scope;
        //常量
        let __Data = {
            checkboxes: {
                items: {},
                checked: false
            },
            canStop: false,
            canStart: false,
            checkItems: [],
            node: {
                selected: ""
            },
        }
        self = _.extend(self, __Data);
        // //多选框勾选事件
        // self.handleChange = function(item){
        //     self.checkItems = [];
        //     self.dataList.forEach(it=>{
        //         if(self.checkboxes.items[it.id]){
        //             self.checkItems.push(it);
        //         }
        //     })
        // }
        // //监听选中列表
        // self.$watch(function(){return self.checkItems},function(val,old){
        //     if(val&&val.length>0){
        //         self.canDel = true;
        //     }else{
        //         self.canDel = false;
        //     }
        // },true)
        // //全选
        // self.checkAll = function(checked){

        // }
        //列表初始化
        self.initData = function (data) {
            self.checkedItems = [];
            self.checkboxes.items = {};
            let opt = {};
            if (self.node.selected.id != 'all') {
                opt.hostName = self.node.selected.hostName
            }
            dpdksrv.getListApi(opt).then(res => {
                if (res && res.data) {
                    self.dataList = res.data;
                    self.dataList.forEach(item => {
                        let s = item.usable ? 1 : 2;

                        // 启用失败 status = 3 usable = true
                        if (item.status === 3 && item.usable === true) {
                            s = 4;
                        }
                        // 禁用失败 status = 3 usable = false
                        if (item.status === 3 && item.usable === false) {
                            s = 5;
                        }
                        if (item.status === 1) {
                            s = 3;
                        }
                        item.usableCtr = $translate.instant("aws.dpdk.usable." + s);
                    })
                    TableCom.init(self, "tableParams", self.dataList, "id", 999);
                }
            })
        }
        //按钮是否可点
        self.$watch(function () {
            return self.checkboxes.items;
        }, function (val) {
            self.checkedItems = [];
            var arr = [];

            for (var i in self.checkboxes.items) {
                arr.push(self.checkboxes.items[i])
            }

            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.tableParams.data.forEach(item => {
                            if (item.id == key) {
                                self.checkedItems.push(item);
                            }
                        });
                    }
                }
            }

            if (self.checkedItems.length === 1) {
                // self.canStop = self.checkedItems[0].usable ? true : false;
                // self.canStart = self.checkedItems[0].usable ? false : true;
                //启用失败 启用按钮有效, 禁用按钮置灰 status = 3 usable = true
                if (self.checkedItems[0].status === 3 && self.checkedItems[0].usable === true) {
                    self.canStop = false;
                    self.canStart = true;
                }
                // 禁用失败 禁用按钮有效, 启用按钮置灰
                if (self.checkedItems[0].status === 3 && self.checkedItems[0].usable === false) {
                    self.canStop = true;
                    self.canStart = false;
                }
                // 启用成功 禁用按钮有效, 启用按钮置灰
                if ((self.checkedItems[0].status === 2 || self.checkedItems[0].status === 0) && self.checkedItems[0].usable === true) {
                    self.canStop = true;
                    self.canStart = false;
                }
                // 禁用成功 启用按钮有效, 禁用按钮置灰
                if ((self.checkedItems[0].status === 2 || self.checkedItems[0].status === 0) && self.checkedItems[0].usable === false) {
                    self.canStop = false;
                    self.canStart = true;
                }
            } else {
                self.canStop = false;
                self.canStart = false;
            }
        }, true)
        //节点列表
        self.getNode = function () {
            dpdksrv.getNodeList().then(res => {
                if (res && res.data && _.isArray(res.data)) {
                    self.nodeList = res.data;
                    self.nodeList.unshift({
                        id: 'all',
                        hostName: $translate.instant("aws.dpdk.msg.allData")
                    });
                    self.node.selected = self.nodeList[0];
                }
            })
        };
        //切换节点
        self.changeNode = function (item) {
            let data = {}
            // if(item.id=="all"){

            // }
            self.initData(data);
        };
        self.startItem = function () {
            var content = {
                target: "dpdkStart",
                msg: "<span>" + $translate.instant("aws.dpdk.dialog.start") + "</span>",
                data: _.cloneDeep(self.checkedItems),
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("dpdkStart", function (e, data) {
            let opt = {
                dpdkId: data[0].id,
                method: true
            }
            dpdksrv.changeStatusAPi(opt).finally(() => {
                self.initData();
            });
        });

        self.shutdownItem = function () {
            var content = {
                target: "dpdkShutdown",
                msg: "<span>" + $translate.instant("aws.dpdk.dialog.shutdown") + "</span>",
                data: _.cloneDeep(self.checkedItems),
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("dpdkShutdown", function (e, data) {
            let opt = {
                dpdkId: data[0].id,
                method: false
            }
            dpdksrv.changeStatusAPi(opt).finally(() => {
                self.initData();
            });
        });
        self.initData();
        self.getNode();
        self.refresh = function () {
            self.initData();
        }
    }])
