enhanceDetailInfo.$inject = ['$translate',"enhanceSrv","$location","$filter"];
function enhanceDetailInfo($translate,enhanceSrv,$location,$filter) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            detailData:"="
        },
        templateUrl: "js/cvm/loadbalancersmodile/tmpl/enhance-detail-info.html",
    };
}
export { enhanceDetailInfo }