import { Address6 } from "ip-address";

editSubnetCtrl.$inject = ["$scope", "$rootScope", "$location", "networksSrv", "checkQuotaSrv", "$uibModalInstance", "$translate", "$timeout", "context", "ipSrv","NgTableParams","GLOBAL_CONFIG","TableCom","commonFuncSrv","initNetworkTable"];
export function editSubnetCtrl($scope, $rootScope, $location, networksSrv, checkQuotaSrv, $uibModalInstance, $translate, $timeout, context, ipSrv,NgTableParams,GLOBAL_CONFIG,TableCom,commonFuncSrv,initNetworkTable) {
   var self=$scope;
    var editData="";
    self.editSub={
       selectedSubnet:""
    }
    let assignedIpList = [];
    self.isClickShow = false;

    function initIp(){
        self.ipList = [{id: 1, ip1:'', ip2:'', ip3:'', ip4:'', del:false, add:true}]
        self.ipSixList =  [{id: 1, ip1:'', ip2:'', ip3:'', ip4:'', ip5:'', ip6:'', ip7:'', ip8:'', del:false, add:true}]
    }
    initIp()
    function getIPInfo(arr) {
        if(self.ipVersion =='4') {
            self.ipList = arr.map((item, index) => {
                return { 
                    id: index + 1, 
                    ip1: item[0], 
                    ip2: item[1], 
                    ip3: item[2], 
                    ip4: item[3], 
                    add: arr.length >= 1, 
                    del: arr.length > 1 
                }
            })
        }else {
            self.ipSixList = arr.map((item, index) => {
                return { 
                    id: index + 1, 
                    ip1: item[0], 
                    ip2: item[1], 
                    ip3: item[2], 
                    ip4: item[3], 
                    ip5: item[4],
                    ip6: item[5],
                    ip7: item[6],
                    ip8: item[7],
                    add: arr.length >= 1, 
                    del: arr.length > 1 
                }
            })
        }
    }
    function editSubNetTable(){
        self.editSubnetTable = new NgTableParams({
            count: GLOBAL_CONFIG.PAGESIZE,
        }, {
            counts: [],
            dataset: []
        });
        //开始
        self.edit_dis = false;
        //结束
        self.loadSubnetData = false;
        networksSrv.getNetworksSubnet(context.network_id).then(function(result) {
            result ? self.loadSubnetData = true : "";
            if (result && result.data && angular.isArray(result.data)) {
                self.subnets_data = _.map(result.data, function(item) {
                    item._enableDhcp = item.enableDhcp == true ? $translate.instant("aws.common.yes") : $translate.instant("aws.common.no");
                    item._ipVersion="ipv"+item.ipVersion;
                    
                     //开始
                    if(item.name =="PaaS子网"){
                        self.edit_dis = true;
                    }else{
                        self.edit_dis = false;
                    }
                    //结束
                    return item;
                });
                TableCom.init(self,'editSubnetTable',self.subnets_data,'id',10,'editSubnetCheckbox');
                //默认编辑第一个子网
                let v4Arr=angular.copy(self.subnets_data).filter(function(item){
                    return item.ipVersion=='4' || item.ipVersion=='6';
                });
                if(v4Arr.length>0){
                   self.changeSub(v4Arr[0]);
                   self.ipVersion = v4Arr[0].ipVersion;
                }

                // dns服务器
                const dns = result.data[0].dnsNameserver;
                if(dns.length != 0){
                    let arr = []
                    if(self.ipVersion=='4') {
                        arr = dns.map(item => item.split('.'));
                    }else {
                        arr = dns.map(item => _IPAddr.parse(item).toNormalizedString().split(':'));
                    }
                    getIPInfo(arr)
                    updateBtnIsShow()
                }
            }
        });
    }
    editSubNetTable();
    self.changeSub=function(sub){
        self.ipVersion = sub.ipVersion;
        const dns = sub.dnsNameserver;
        if(dns.length != 0){
            let arr = []
            if(self.ipVersion=='4') {
                arr = dns.map(item => item.split('.'));
            }else {
                arr = dns.map(item => _IPAddr.parse(item).toNormalizedString().split(':'));
            }
            getIPInfo(arr)
        } else {
            initIp()
        }

        self.editSub.selectedSubnet=sub.id;
        editData=sub;
        self.editData=editData;
        self.subnetForm = angular.copy(editData);
       self.subnetForm.allocationPools = _.sortBy(self.subnetForm.allocationPools, "start");
       if(self.ipVersion=='4') {
            self.networkAddress=_IP.cidrSubnet(self.subnetForm.cidr).networkAddress;
            self.broadcastAddress=_IP.cidrSubnet(self.subnetForm.cidr).broadcastAddress;
        }else {
            // ipv6  abcf:abcf:abcf:abcf::1 处理成 43983
            let addr = new Address6(self.subnetForm.cidr);
            self.networkAddress = ipSrv.reduceAdd(ipSrv.parseIntIpSix(addr.parsedAddress.join(":")).parseIntIp);
            self.broadcastAddress = ipSrv.reduceAdd(ipSrv.parseIntIpSix(addr.endAddress().address).parseIntIp);
            // 单独处理ipv6的 解决 (abcf:abcf:abcf:abcf::1 和abcf:abcf:abcf:abcf:0:0:0:1) 0被省略的情况去比较两个字符串不相等的问题
            self.subnetForm.allocationPools.forEach(item=>{
                item.startIpv6 = ipSrv.reduceAdd(ipSrv.parseIntIpSix(item.start).parseIntIp);
                item.endIpv6 = ipSrv.reduceAdd(ipSrv.parseIntIpSix(item.end).parseIntIp);
            })
        }
       //作用？
       assignedIpList = [];
       if (!editData.external) {
            assignedIpList = assignedIpList.slice(assignedIpList.length, 0);
            networksSrv.getNetworksDetail(editData.parentId).then(function(result) {
                if (result && result.data) {
                    _.each(result.data, function(item) {
                        _.each(item.subnetIps, function(sub) {
                            if (sub.subnet_id == editData.id) {
                                assignedIpList.push(sub.ip_address);
                            }
                        })
                    });
                }
            });
       }
    }

    self.submitted = false;
    self.interacted = function(field) {
        if (field) {
            return self.submitted || field.$dirty;
        }
    };
    self.setIpIsOverlap = function() {
        self.ipIsOverlap = false;
        self.ipEqToGateway = false;
        self.poolNarrow = false;
    };

    self.addAllocationPools = function(){
        self.subnetForm.allocationPools.push({
            start:"",
            end:""
        })
        self.setIpIsOverlap()
    }

    self.delAllocationPools = function(index,allocationPools){
        self.subnetForm.allocationPools.splice(index,1)
        self.setIpIsOverlap()
    }
    
    self.changeDns = function() {
        self.isClickShow = false;
    }

    /**
     * 修改按钮是否显示
     */
     function updateBtnIsShow(){
        if(self.ipVersion=='4') {
            self.ipList.forEach((item,i) => {
                if((i + 1) == 5){
                    item.add = false;
                } else {
                    item.add = i == (self.ipList.length - 1);
                }
            })
        }else {
            self.ipSixList.forEach((item,i) => {
                if((i + 1) == 5){
                    item.add = false;
                } else {
                    item.add = i == (self.ipSixList.length - 1);
                }
            })
        }
    }
    
    self.changeip = function(value, index) {
        let arr = self.ipVersion=='4' ? self.ipList :self.ipSixList;
        const length = arr.length;
        if(value == "add"){
            if(length == 5){
                return
            }
            const id = arr[length -1].id;
            arr[0].del = true;
            if(self.ipVersion=='4') {
                arr.push({id: id + 1, ip1:'', ip2:'', ip3:'', ip4:'', del:true, add:true});
            }else{
                arr.push({id: id + 1, ip1:'', ip2:'', ip3:'', ip4:'', ip5:"",ip6:"",ip7:"",ip8:"", del:true, add:true});
            }
        } else if(value == "del"){
            if(self.ipVersion=='4') {
                self.ipList = arr.filter((item, i)=>index != i);
                if(self.ipList.length == 1){
                    self.ipList[0].del = false;
                }
            }else {
                self.ipSixList = arr.filter((item, i)=>index != i);
                if(self.ipSixList.length == 1){
                    self.ipSixList[0].del = false;
                }
            }
        }
        // 显示隐藏开关
        updateBtnIsShow()
    }

    // 可用地址范围
    self.changePool = function(index) {
        self.subnetForm.allocationPools.forEach((item,i)=>{
            if(index == i) {
                item.startIpv6 = ipSrv.reduceAdd(ipSrv.parseIntIpSix(item.start).parseIntIp);
                item.endIpv6 = ipSrv.reduceAdd(ipSrv.parseIntIpSix(item.end).parseIntIp);
            }
        })
    }

    /**
     * 确认按钮
     * @param {*} editSubnetForm 
     */
    self.editSubnetCfm = function(editSubnetForm) {
        if (editSubnetForm.$valid) {
            self.isClickShow = false;
            let dns_nameservers = ''
            let allocationPools = angular.copy(self.subnetForm.allocationPools);
            if(self.ipVersion=='4') {
                self.ipIsOverlap = ipSrv.chkIpOverlapFunc(self.subnetForm.allocationPools);
                //新加入启动网关功能，只有在有网关ip的时候才需要检验网关是否在地址池内
                if(editData.gatewayIp){
                    self.ipEqToGateway = ipSrv.checkIpInPool(self.subnetForm.allocationPools, editData.gatewayIp); 
                }
                if (editData.external) {
                    self.poolNarrow = ipSrv.checkPoolRange(editData.allocationPools, self.subnetForm.allocationPools);
                } else {
                    for (let i = 0; i < assignedIpList.length; i++) {
                        if (editData.gatewayIp == assignedIpList[i]) {
                            continue;
                        }
                        if (!ipSrv.checkIpInPool(self.subnetForm.allocationPools, assignedIpList[i])) { //如果修改后的IP池中不含有已经分配出去的IP，则不允许修改
                            self.poolNarrow = true;
                            break;
                        }
                    }
                }
                dns_nameservers = self.ipList.map(item=>{
                    if(item['ip1']==''||item['ip2']==''||item['ip3']==''||item['ip4']==''){
                        return ''
                    }
                    return `${item['ip1']}.${item['ip2']}.${item['ip3']}.${item['ip4']}`
                }).filter(item => item != '');
               
            }else {
                //ipv6的校验交叉重叠
                self.ipIsOverlap = ipSrv.chkIpSixOverlapFunc(self.subnetForm.allocationPools);
                if(editData.gatewayIp){
                    self.ipEqToGateway = ipSrv.checkIpSixInPool(self.subnetForm.allocationPools, editData.gatewayIp); 
                }
                if (editData.external) {
                    self.poolNarrow = ipSrv.checkPoolSixRange(editData.allocationPools, self.subnetForm.allocationPools);
                } else {
                    for (let i = 0; i < assignedIpList.length; i++) {
                        if (editData.gatewayIp == assignedIpList[i]) {
                            continue;
                        }
                        if (!ipSrv.checkIpSixInPool(self.subnetForm.allocationPools, assignedIpList[i])) { //如果修改后的IP池中不含有已经分配出去的IP，则不允许修改
                            self.poolNarrow = true;
                            break;
                        }
                    }
                }
                dns_nameservers = self.ipSixList.map(item=>{
                    if(item['ip1']==''||item['ip2']==''||item['ip3']==''||item['ip4']==''||item['ip5']==''||item['ip6']==''||item['ip7']==''||item['ip8']==''){
                        return ''
                    }
                    return `${item['ip1']}:${item['ip2']}:${item['ip3']}:${item['ip4']}:${item['ip5']}:${item['ip6']}:${item['ip7']}:${item['ip8']}`
                }).filter(item => item != '');
                // 删除ipv6多余的提交字段
                allocationPools.forEach(item=>{
                    delete item.startIpv6;
                    delete item.endIpv6;
                })
            }

            if (!self.ipIsOverlap && !self.ipEqToGateway && !self.poolNarrow) {
                self.formSubmitted = true;
                $uibModalInstance.close();
                networksSrv.editSubnetAction({
                    "subnet": {
                        "name": self.subnetForm.name,
                        "allocation_pools": allocationPools,
                        dns_nameservers
                    },
                    "subnet_id": editData.id
                }).then(function() {
                    initNetworkTable();
                });
            }
        } else {
            self.submitted = true;
            self.isClickShow = true;
        }
    };
}
