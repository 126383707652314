openServerModel.$inject = ["$scope", "$translate", "$uibModalInstance", "enhanceDetailListenSrv","TableCom","checked","initMemberTable","ttEquipmentData","$location"]
function openServerModel($scope, $translate, $uibModalInstance, enhanceDetailListenSrv,TableCom, checked,initMemberTable,ttEquipmentData,$location) {
    var self = $scope;
    self.pageNum = 1;
    self.pageSize = 5;
    self.total = 0;
    self.submitValid = false;
    self.ttEquipmentData = []
    self.snapshot_search = {
        globalSearchTerm: '',
    };
    self.statusConfig = {
        "statusSelected": {}
    }
    self.checkboxes = {
        checked: false,
        items: {},
    };
    self.modalTitle = $translate.instant("aws.loadbalance.bindIns");
    self.choiceAll = localStorage.selectedAllDomain;

    const headers = {}
    if(self.choiceAll == 1){
        const { projectUid, projectName, domainUid, domainName } = $location.search()
        headers.project_id= projectUid;
        headers.project_name= encodeURI(projectName);
        headers.domain_id= domainUid;
        headers.domain_name= encodeURI(domainName);
    }
    const config = {
        filterType: "db_inst",
    }

    enhanceDetailListenSrv.getLocalDiskMountList(config, headers).then(function (result) {
        result.data ? self.loadData = true : "";
        if (result && result.data && typeof result.data == 'object' && typeof result.data.length != 0) {
            getInstanceTableData(result.data);
        }
    })

    // 搜索
    self.searchBackend = function({search, type}){
        self.pageNum = 1;
        let tempDataList = []
        if(type == 'refresh'){
            // 刷新
            self.snapshot_search.globalSearchTerm = '';
            tempDataList = self.ttEquipmentData
        } else {
            // 搜索
            tempDataList = self.ttEquipmentData.filter(item=>{
                const { name, ipAddr, stateName } = item
                return (name.indexOf(search) != -1) || (ipAddr && ipAddr.indexOf(search) != -1) || (stateName.indexOf(search) != -1)
            })
        }
        self.total = tempDataList.length
        renderTable(tempDataList)
    }
    
    //确认提交和校验
    self.createServer = function (portWeightForm, tableParams) {
        if (self.checkedItems && self.checkedItems.length == 5) {
            if (portWeightForm.$valid) {
                bindHost()
            } else {
                self.submitValid = true;
            }
        } else {
            let isValidlock = true;
            const tableData = tableParams.data
            tableData.forEach((item, index) => {
                if (self.checkedItems.findIndex(value => value.id == item.id) != -1) {
                    if (!portWeightForm["weight_" + index].$valid) {
                        if (isValidlock) {
                            isValidlock = false;
                        }
                        tableData[index].submitValid = true;
                    }
                }
            })
            if (isValidlock) {
                bindHost()
            }
        }
    }

    self.$watch(()=>self.checkboxes.items, function (val, old) {
        self.checkedItems = [];
        var arr = [];
        for (var i in self.checkboxes.items) {
            arr.push(self.checkboxes.items[i])
        }
        if (val && arr.length >= 0) {
            for (var key in val) {
                if (val[key]) {
                    self.ttEquipmentData.forEach(item => {
                        if (item.id == key) {
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
    }, true);

    function getInstanceTableData(data) {
        // 过滤绑定过云主机
        self.ins_data = data.filter(item=> !(ttEquipmentData.find(value => value.tenantId == item.id)));
        self.ins_data.forEach(item => {
            item.ipAddrs = [];
            item.status = item.status.toLowerCase();
            item.port_info.forEach(port => item.ipAddrs.push(port.ip_address));
            item.ipAddr = item.ipAddrs[0];
            item.stateName = $translate.instant("aws.instances.table.status." + item.status);
            item.IPlist = item.ipAddrs.map((nowvalue) => {
                return {
                    name: nowvalue,
                    value: nowvalue
                }
            })
        })
        // 只展示运行和关机状态云主机
        self.ins_data = self.ins_data.filter(item=> item.status=="active"|| item.status=="shutoff");
        // 过滤无IP云主机
        self.ins_data = self.ins_data.filter(item => item.IPlist.length != 0)

        // 过滤相同内网或外网网段云主机
        const {vipAddress,netAddress} = $location.search()
        const vipArr = vipAddress.split('.')
        const netArr = netAddress.split('.')
        vipArr.pop()
        netArr.pop()
        const vipStr = vipArr.join('.');
        const netStr = netArr.join('.');
        self.ttEquipmentData = self.ins_data.filter(item => item.IPlist.filter(data => data.value.indexOf(vipStr) != -1 || data.value.indexOf(netStr) != -1) != 0)
        renderTable(self.ttEquipmentData)
    }

    function renderTable (data){
        self.total = data.length
        TableCom.init(self, 'tableParams', data, 'id', self.pageSize, 'checkboxes');
    }

    /**
     * 绑定云主机
     */
    function bindHost() {
        const config = self.checkedItems.map(item=> {
            return {
                name: item.name,
                address : item.ipAddr,
                protocolPort : item.port,
                tenantId: item.id
            }
        })
        enhanceDetailListenSrv.bindHost(checked.uid, config).then(()=>{
            initMemberTable(checked.uid)
            $uibModalInstance.close();
        })
    }
}

export { openServerModel }