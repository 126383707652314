import * as controllers from "./controllers";
import * as services from "./services";

let vgpuModule = angular.module("vgpuModule", ["rzModule"]);

vgpuModule.config(["$controllerProvider", function($controllerProvider){
	$controllerProvider.register(controllers);
}]);

vgpuModule.config(["$provide", function($provide){
	$provide.service(services);
}]);