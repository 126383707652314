osHotgradeCtrl.$inject = ["$scope","$translate","$uibModalInstance","$location","edit","refresh","NgTableParams",'instancesSrv','nodeSrv','getProused','getDomused','context'];
export function osHotgradeCtrl($scope, $translate, $uibModalInstance, $location, edit, refresh, NgTableParams, instancesSrv, nodeSrv, getProused, getDomused, context){
    let self = $scope;
    self.ram = {};
    self.vcpus = {};
    self.ram.min_max = {
        //min: self.hotgradeData.current_mem_mb/1024,
        min:edit.ram / 1024,  //热升级的当前配置从云主机的详情里面取值
        max: context.hotgradeData.maximum_mem_mb/1024
    };
    self.vcpus.min_max = {
        //min: self.hotgradeData.current_cpus, 
        min:edit.vcpus,
        max: context.hotgradeData.maximum_cpus
    };
    self.hotgrade = {
        //vcpu: self.hotgradeData.maximum_cpus,
        //ram: self.hotgradeData.maximum_mem_mb/1024
    };

    self.currentSetting = edit.vcpus + $translate.instant("aws.instances.conf.core") + (edit.ram / 1024).toFixed(1) + "G";  // fixAWSTACK261-3282
    self.submitInValid = false;

    getProused();
    getDomused();
    getHypervisors();
    function getHypervisors(){
        nodeSrv.getHypervisors().then(function(result) {
            if (result && result.data) {
                result.data.map(item=>{
                    item.name = item.hypervisorHostname.split(".")[0];
                    item.freeCpu = item.virtualCPU * 4 - item.virtualUsedCPU;
                    item.freeRam=parseInt(item.freeRam/1024);
                })
                result.data = result.data.filter(item => item.name == edit.hostName )
                self.hypervisorInfo = result.data[0];

                
            }
    
        });
    }

    self.checkCpu = function(){
        self.cpuExceed = false;
        self.cpuExceed1 = false;
        (Number(self.hotgrade.vcpu) - edit.vcpus > context.coresDomquota.total - context.coresDomquota.used) ? self.coreDomText = $translate.instant("aws.instances.quota.domCpu"): self.coreDomText = "";
        (Number(self.hotgrade.vcpu) - edit.vcpus > context.coresquota.total - context.coresquota.used) ? self.coreProText = $translate.instant("aws.instances.quota.proCpu"): self.coreProText = "";
        if(Number(self.hotgrade.vcpu)>self.hypervisorInfo.virtualCPU){
            self.cpuExceed = true;
        }else{
            if(Number(self.hypervisorInfo.virtualCPU*4 - self.hypervisorInfo.virtualUsedCPU)<self.hotgrade.vcpu){
                self.cpuExceed1 = true;
            }
        }
    }
    self.checkRam = function(){
        self.ramExceed = false;
        (Number(self.hotgrade.ram*1024) - edit.ram > context.ramDomquota.total - context.ramDomquota.used) ? self.ramDomText = $translate.instant("aws.instances.quota.domRam"): self.ramDomText = "";
        (Number(self.hotgrade.ram*1024) - edit.ram > context.ramquota.total - context.ramquota.used) ? self.ramProText = $translate.instant("aws.instances.quota.proRam"): self.ramProText = "";
        if(Number(self.hypervisorInfo.localMemory - self.hypervisorInfo.localMemoryUsed)<self.hotgrade.ram*1024){
            self.ramExceed = true;
        }
    }

    self.hotconfirm = function(form) {
        if (form.$valid) {
            if(self.ramExceed || self.cpuExceed || self.cpuExceed1) return;
            var data = {
                vcpu_max: self.hotgrade.vcpu,
                ram_max: self.hotgrade.ram*1024
            };
            $uibModalInstance.close();
            instancesSrv.postHotgrade(data, edit.uid).then(function() {

            });
        } else {
            self.submitInValid = true;
        }
    };
}