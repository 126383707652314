let helpModule = angular.module("helpModule",[]);
helpModule.service("helpSrv",helpInit)
helpInit.$inject = ['$rootScope',"$window","$timeout","$http","$location","$translate"];
function helpInit(scope,$window,$timeout,$http,$location,$translate,alert){
    this.helpInformation = {
        //云主机列表
        "instances":{
            "route":"#/cvm/instances",
            "detailRoute":"/handbook/#/vm/CC_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.instancesDescribe') 
                }
            ]
        },
        //云主机组
        "affinity":{
            "route":"#/cvm/affinity",
            "detailRoute":"/handbook/#/vm/CCG_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.affinityDescribe')
                }
            ]
        },
        //虚拟网卡
        "ports":{
            "route":"#/cvm/ports",
            "detailRoute":"/handbook/#/vm/CC_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.portsOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.portsTwoDescribe')
                }
            ]
        },
        //镜像管理
        "images":{
            "route":"#/cvm/images",
            "detailRoute":"/handbook/#/vm/MI_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.imagesOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.imagesTwoDescribe')
                }
            ]
        },
        //制作镜像
        "makeimages":{
            "route":"#/cvm/makeimages",
            "detailRoute":"/handbook/#/vm/MI_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.makeimagesDescribe')
                }
            ]
        },
        //数据盘
        "volumesData":{
            "route":"#/cvm/volumes",
            "detailRoute":"/handbook/#/csb/CHD_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesTwoDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesThreeDescribe')
                }
            ]
        },
        //系统盘
        "volumesSystem":{
            "route":"#/cvm/volumes",
            "detailRoute":"/handbook/#/csb/CHD_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesTwoDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesThreeDescribe')
                }
            ]
        },
        //缓存盘
        "volumesCaching":{
            "route":"#/cvm/volumes",
            "detailRoute":"/handbook/#/csb/CHD_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesTwoDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesThreeDescribe')
                }
            ]
        },
        // 标签提示
        "labelhint":{
            "route":"#/cvm/label",
            "detailRoute":"/handbook/#/vm/T_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.labelDescribe')
                }
            ]
        },
        // 负载均衡增强
        "enhance":{
            "route":"#/cvm/enhance",
            "detailRoute":"/handbook/#/net/ELB_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.enhanceMsg')
                }
            ]
        },
        //本地盘
        "volumesLocal":{
            "route":"#/cvm/volumes",
            "detailRoute":"/handbook/#/csb/CHD_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesTwoDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesThreeDescribe')
                }
            ]
        },
        //数据盘快照
        "snapshotsData":{
            "route":"#/cvm/snapshots",
            "detailRoute":"/handbook/#/csb/SS_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.snapshotsOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.snapshotsTwoDescribe')
                }
            ]
        },
        //系统盘快照
        "snapshotsSystem":{
            "route":"#/cvm/snapshots",
            "detailRoute":"/handbook/#/csb/SS_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.snapshotsOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.snapshotsOneDescribe')
                }
            ]
        },
        // 硬盘直通本地磁盘
        "localDisk":{
            "route":"#/cvm/localDisk",
            "detailRoute":"/handbook/#/csb/LD_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.localDiskOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.localDiskTwoDescribe')
                }
            ]
        },
        //备份
        //备份策略
        "backupStrategy":{
            "route":"#/cvm/backupStrategy",
            "detailRoute":"/handbook/#/csb/BR_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.backupStrategyDescribe')
                }
            ]
        },
         //物理主机-虚拟化物理机：
        "monitorPhysical_node":{
            "route":"#/monitor/physical",
            "detailRoute":"/handbook/#/register/MM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.physicalNodeOneDescribe')
                },
                {
                    "title":$translate.instant('aws.common.physicalNodeTitle'),
                    "describe":$translate.instant('aws.common.physicalNodeTwoDescribe')
                }
            ]
        },
         //物理主机-资源池物理机
         "monitorPhysical_resPhy":{
            "route":"#/monitor/physical",
            "detailRoute":"/handbook/#/register/MM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.physicalResPhyOneDescribe')
                },
                {
                    "title":$translate.instant('aws.common.physicalResPhyTitle'),
                    "describe":$translate.instant('aws.common.physicalResPhyTwoDescribe')
                }
            ]
        },
         //物理主机-纳管物理机：
         "monitorPhysical_ipmi":{
            "route":"#/monitor/physical",
            "detailRoute":"/handbook/#/register/MM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.physicalIpmiOneDescribe')
                },
                {
                    "title":$translate.instant('aws.common.physicalIpmiTitle'),
                    "describe":$translate.instant('aws.common.physicalIpmiTwoDescribe')
                }
            ]
        },
        "Newbackup":{
            "route":"#/cvm/rbackups",
            "detailRoute":"/handbook/#/csb/BR_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.newbackupDescribe')
                }
            ]
        },
        "Sysbackup":{
            "route":"#/cvm/rbackups",
            "detailRoute":"/handbook/#/csb/BR_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.sysbackupDescribe')
                }
            ]
        },
        "Databackup":{
            "route":"#/cvm/rbackups",
            "detailRoute":"/handbook/#/csb/BR_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.databackupDescribe')
                }
            ]
        },
        "SysbackupRecovery":{
            "route":"#/cvm/recovery",
            "detailRoute":"/handbook/#/csb/BR_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.sysbackupRecoveryDescribe')
                }
            ]
        },
        "DatabackupRecovery":{
            "route":"#/cvm/recovery",
            "detailRoute":"/handbook/#/csb/BR_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.databackupRecoveryDescribe')
                }
            ]
        },
        //云硬盘Qos
        "volumesQoS":{
            "route":"#/cvm/volumesQoS",
            "detailRoute":"/handbook/#/csb/DQOS_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.volumesQoSDescribe')
                }
            ]
        },
        //网络拓扑
        "graffNet":{
            "route":"#/cvm/netTopo",
            "detailRoute":"/handbook/#/net/NT_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.netTopoDescribe')
                }
            ]
        },
        //私有网络
        "networks":{
            "route":"#/cvm/networks",
            "detailRoute":"/handbook/#/net/PN_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.networksDescribe')
                }
            ]
        },
        //路由器
        "routers":{
            "route":"#/cvm/routers",
            "detailRoute":"/handbook/#/net/R_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.routersDescribe')
                }
            ]
        },
        //外部网路
        "physicalnetworks":{
            "route":"#/cvm/physicalnetworks",
            "detailRoute":"/handbook/#/net/EN_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.physicalnetworksDescribe')
                }
            ]
        },
        //公网ip
        "floating_ips":{
            "route":"#/cvm/floating_ips",
            "detailRoute":"/handbook/#/net/PIP_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.floatingIpsDescribe')
                }
            ]
        },
        //负载均衡
        "loadbalancing":{
            "route":"#/cvm/loadbalancing",
            "detailRoute":"/handbook/#/net/LB_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.loadbalancingDescribe')
                }
            ]
        },
        // 负载均衡证书
        "nginxcertificate":{
            "route":"#/cvm/loadbalancingcertificate",
            "detailRoute":"/handbook/#/net/LBC_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.loadbalancingcertificateOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.loadbalancingcertificateTwoDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.loadbalancingcertificateThreeDescribe')
                }
            ]
        },
        //vpn
        "vpn":{
            "route":"#/cvm/vpn",
            "detailRoute":"/handbook/#/net/VPN_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.vpnDescribe')
                }
            ]
        },
        //防火墙
        "firewall":{
            "route":"#/cvm/firewall",
            "detailRoute":"/handbook/#/security/FW_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.firewallDescribe')
                }
            ]
        },
        //密钥对
        "keypairs":{
            "route":"#/cvm/keypairs",
            "detailRoute":"/handbook/#/security/KP_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.keypairsDescribe')
                }
            ]
        },
        //安全组
        "security_groups":{
            "route":"#/cvm/security_groups",
            "detailRoute":"/handbook/#/security/SG_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.securityGroupsDescribe')
                }
            ]
        },
        //扩展策略
        "scalpolicy":{
            "route":"#/cvm/scalpolicy",
            "detailRoute":"/handbook/#/vm/EE_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.scalpolicyDescribe')
                }
            ]
        },
        //集群管理
        "extension":{
            "route":"#/cvm/extension",
            "detailRoute":"/handbook/#/vm/EE_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.extensionDescribe')
                }
            ]
        },
        //云主机回收站
        "recycle":{
            "route":"#/cvm/recycle",
            "detailRoute":"/handbook/#/vm/RS_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.recycleDescribe')
                }
            ]
        },
        //数据盘回收站
        "recyclecs":{
            "route":"#/cvm/recyclecs",
            "detailRoute":"/handbook/#/vm/RS_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.recyclecsDescribe')
                }
            ]
        },
        // 云数据库回收站
        "recycleDb":{
            "route":"#/cvm/recycleDb",
            "detailRoute":"/handbook/#/vm/RS_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.recycleDbDescribe')
                }
            ]
        },
        //云数据库
        "cloudDatabase":{
            "route":"#/cvm/cloudDatabase",
            "detailRoute":"/handbook/#/vm/CDB_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.databaseDescribe') 
                }
            ]
        },
        //物理机
        "pvminstances":{
            "route":"#/pvm/pvminstances",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.pvminstancesDescribe')
                }
            ]
        },
        //物理机镜像
        "pvmimages":{
            "route":"#/pvm/pvmimages",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.pvmimagesDescribe')
                }
            ]
        },
        //物理机网络
        "pvmnetworks":{
            "route":"#/pvm/pvmnetworks",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.pvmnetworksDescribe')
                }
            ]
        },
        //容器集群
        "k8sclusters":{
            "route":"#/k8s/clusters",
            "detailRoute":"",
            "data":[
                {
                    "title":$translate.instant('aws.common.helpInformation'),
                    "describe":$translate.instant('aws.common.k8sclustersDescribe')
                }
            ]
        },
        //容器服务
        "k8sservices":{
            "route":"#/k8s/services",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.k8sservicesDescribe')
                }
            ]
        },
        //镜像服务
        "k8sprojects":{
            "route":"#/k8s/projects",
            "detailRoute":"/handbook/#/register/ILM_OG",
            "data":[
                {
                    "title":$translate.instant('aws.common.helpInformation'),
                    "describe":$translate.instant('aws.common.k8sprojectsDescribe')
                }
            ]
        },
        //告警事件
        "alarmevent":{
            "route":"#/monitor/alarmevent",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.alarmeventDescribe')
                }
            ]
        },
        "wechatAlarmevent":{
            "route":"#/wechat/alarmEvent",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.wechatAlarmeventDescribe')
                }
            ]
        },
        //历史告警事件
        "hisAlarmevent":{
            "route":"#/monitor/alarmevent",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":$translate.instant('aws.common.helpInformation'),
                    "describe":$translate.instant('aws.common.hisAlarmeventDescribe')
                }
            ]
        },
        "wechatHisAlarmevent":{
            "route":"#/wechat/alarmSetting",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":$translate.instant('aws.common.helpInformation'),
                    "describe":$translate.instant('aws.common.wechatHisAlarmeventDescribe')
                }
            ]
        },
        //告警设置
        "alarmsetting":{
            "route":"#/monitor/alarmsetting",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.alarmsettingDescribe')
                }
            ]
        },
        "wechatAlarmsetting":{
            "route":"#/wechat/alarmSetting",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.wechatAlarmsettingDescribe')
                }
            ]
        },
        //告警模板
        "alarmtemplate":{
            "route":"#/monitor/alarmtemplate",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.alarmtemplateDescribe')
                }
            ]
        },
        "wechatAlarmtemplate":{
            "route":"#/wechat/alarmTemplate",
            "detailRoute":"/handbook/#/register/AM_QS",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.wechatAlarmtemplateDescribe')
                }
            ]
        },
        //联系人组
        "contractgroup":{
            "route":"#/monitor/contractgroup",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.contractgroupDescribe')
                }
            ]
        },
        "wechatContractgroup":{
            "route":"#/wechat/alramContactGroup",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.wechatContractgroupDescribe')
                }
            ]
        },

        //待处理工单
        "pendingTickets":{
            "route":"#/ticket/pendingTickets",
            "detailRoute":"/handbook/#/userService/WM_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.pendingTicketsDescribe')
                }
            ]
        },
        //我的申请
        "myApply":{
            "route":"#/ticket/myApply",
            "detailRoute":"/handbook/#/userService/WM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.myApplyDescribe')
                }
            ]
        },
        //我的审批
        "allTickets":{
            "route":"#/ticket/allTickets",
            "detailRoute":"/handbook/#/userService/WM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.allTicketsDescribe')
                }
            ]
        },
        //工单报表
        "ticketReports":{
            "route":"#/ticket/ticketReports",
            "detailRoute":"/handbook/#/userService/WM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.ticketReportsDescribe')
                }
            ]
        },
        //操作日志
        "operatelogs":{
            "route":"#/logs/operatelogs",
            "detailRoute":"/handbook/#/register/LM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.operatelogsDescribe')
                }
            ]
        },
        "audit":{
            "route":"#/logs/audit",
            "detailRoute":"/handbook/#/register/LM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.auditDescribe')
                }
            ]
        },
        //登录日志
        "loginlogs":{
            "route":"#/logs/loginlogs",
            "detailRoute":"/handbook/#/register/LM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.loginlogsDescribe')
                }
            ]
        },
        //部门管理
        "department":{
            "route":"#/permit/department",
            "detailRoute":"/handbook/#/userService/IM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.departmentDescribe')
                }
            ]
        },
        //项目管理
        "project":{
            "route":"#/permit/project",
            "detailRoute":"/handbook/#/userService/IM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.projectDescribe')
                }
            ]
        },
        //用户管理
        "user":{
            "route":"#/permit/user",
            "detailRoute":"/handbook/#/userService/IM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.userDescribe')
                }
            ]
        },
        //角色管理
        "role":{
            "route":"#/permit/role",
            "detailRoute":"/handbook/#/userService/IM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.roleDescribe')
                }
            ]
        },
        //主机集合
        "aggregates":{
            "route":"#/system/aggregates",
            "detailRoute":"/handbook/#/register/HS_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.aggregatesDescribe')
                }
            ]
        },
        //数据中心管理
        "datacluster":{
            "route":"#/system/datacluster",
            "detailRoute":"/handbook/#/register/DM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.dataclusterDescribe')
                }
            ]
        },
        //节点管理
        "node":{
            "route":"#/configure/node",
            "detailRoute":"/handbook/#/register/NM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.nodeDescribe')
                }
            ]
        },
        //纳管物理机
        "physicalConductor":{
            "route":"#/system/physicalConductor",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.physicalConductorDescribe')
                }
            ]
        },
        //资源池物理机
        "physicalConductor_res":{
            "route":"#/system/physicalConductor",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.physicalConductorResDescribe')
                }
            ]
        },
        //存储管理
        "storageManagement":{
            "route":"#/system/storageManagement",
            "detailRoute":"/handbook/#/register/SM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.storageManagementOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.storageManagementTwoDescribe')
                }
            ]
        },
        //镜像库管理
        "imageManagement":{
            "route":"#/system/imageManagement",
            "detailRoute":"/handbook/#/register/ILM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.imageManagementDescribe')
                }
            ]
        },
        //计划任务
        "regularSnap":{
            "route":"#/system/regularSnap",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.regularSnapDescribe')
                }
            ]
        },
        //透传设备
        "transmissionMag":{
            "route":"#/system/transmissionMag",
            "detailRoute":"/handbook/#/register/TEM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.transmissionMagDescribe')
                }
            ]
        },
        //规格
        //云主机
        "flavorsinstance":{
            "route":"#/system/flavors",
            "detailRoute":"/handbook/#/vm/S_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.flavorsinstanceDescribe')
                }
            ]
        },
        //物理机
        "flavorsphysical":{
            "route":"#/system/flavors",
            "detailRoute":"/handbook/#/vm/S_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.flavorsphysicalDescribe')
                }
            ]
        },
        //流程管理
        //工作流程
        "flowManage":{
            "route":"#/system/flowManage",
            "detailRoute":"/handbook/#/userService/FM_FI",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.flowManageDescribe')
                }
            ]
        },
        //自定义流程
        "flowManageCustom":{
            "route":"#/system/flowManage",
            "detailRoute":"/handbook/#/userService/FM_FI",
            "data":[
                 {
                    "title":"",
                    "describe":$translate.instant('aws.common.flowManageCustomDescribe')
                }
                
            ]
        },
        //软件授权
        "license":{
            "route":"#/system/license",
            "detailRoute":"/handbook/#/register/SL_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.licenseDescribe')
                }
            ]
        },
        //功能管理
        "plugin":{
            "route":"#/system/plugin",
            "detailRoute":"/handbook/#/register/EFM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.pluginDescribe')
                }
            ]
        },
        //云平台升级
        "grade":{
            "route":"#/system/grade",
            "detailRoute":"/handbook/#/register/SU_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.gradeDescribe')
                }
            ]
        },
        //安全设置
        "accesspolicy":{
            "route":"#/system/accesspolicy",
            "detailRoute":"/handbook/#/userService/SST_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.accesspolicyDescribe')
                }
            ]
        },
        //云系统服务
        "openstackService":{
            "route":"#/monitor/openstackService",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.openstackServiceDescribe')
                }
            ]
        },
        //ceph管理
        "cephManage":{
            "route":"#/system/cephView",
            "detailRoute":"/handbook/#/register/CM_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.cephManageDescribe')
                }
            ]
        },
        //关系型数据库
        "databasetdsql":{
            "route":"#/database/relationalTdsql",
            "detailRoute":"",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.databasetdsqlDescribe')
                }
            ]
        },
        //系统巡检
        "inspection":{
            "route":"#/system/systeminspection",
            "detailRoute":"/handbook/#/register/SI_OG",
            "data":[
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.inspectionOneDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.inspectionTwoDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.inspectionThreeDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.inspectionFourDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.inspectionFiveDescribe')
                },
                {
                    "title":"",
                    "describe":$translate.instant('aws.common.inspectionSixDescribe')
                }
            ]
        }

    }
}

export default helpModule.name;
