/**
 * Created by Weike on 2016/6/13.
 */
import "./flavorsModule";
import "./aggregatesModule";
import "./aboutModule";
import "./systemInspectionModule";
import "./networksManageMoudle";
import "./hypervisorsModule";
import "./accesspolicy/all";
import "./license/all";
import "./mailServer/all";
import "./storage/all";
import "./grade/gradeModule";
import "./limitband/all";
import "./switch/all";
import "./weChatAlarm/all";
import "./storageManagement/all";
import "./imageManagement/all";
import "./pluginManage/all";
import "./billingManagement/all";
import "./resMetering/all";
import "./datacluster/";
import "./cephView/";
import "./transmissionMag/all";
import "./labelManagement/all";
import "./flowManage/all";
import "./maxpcshow"
import "./bigmem/memModule"
import "./sr-iov/sriovModule"
import "./dpdk/dpdkModule"
import "./nettype/nettypeModule"
import "./multiplenet/multipleModule"

import "./logoSetting"
import "./vgpu"
import "./gpuModule"
import "./objectStorageSetting/all";
import "./objectStorageUser/all";
import "./cephpg/all";
import "./ladp/all"

import sysBackup from './sysBackup/all'
import './enhanceconfig/all'

angular.module("system", [
    "enhanceSystemConfigModel",
    "flavorsModule",
    "gradeModule",
    "aggregatesModule",
    "aboutModule",
    "systemInspectionModule",
    "networksManageMoudle",
    "hypervisorsModule",
    "accessPolicy",
    "license",
    "mailServer",
    "storage",
    "limitband",
    "switch",
    "weChatAlarm",
    "storageManagement",
    "imageManage",
    "plugin",
    "billingModule",
    "resMeteringModule",
    "datacluster",
    "cephViewModule",
    "transmissionMag",
    "labelManagement",
    "flowManageModule",
    "maxpcshowModule",
    "logoSettingModule",
    "memModule",
    "sriovModule",
    "nettypeModule",
    "vgpuModule",
    "gpuModule",
    "dpdkModule",
    "objectStorageSetting",
    "objectStorageUser",
    "multipleModule",
    "cephpg",
    sysBackup,
    "multipleModule",
    "ladpServer"
]);