angular.module("secSrvModule", [])
    .service("secSrv", function($http) {
        return {
            getDomainProject: function() {
                return $http({
                    methods: "GET",
                    url: "/awstack-user/v1/user/domains/projects"
                })
            },
            getSSOUrl: function(project_id) {
                return $http({
                    methods: "GET",
                    url: "/awstack-user/v1/plugin/paas/getSSOUrl",
                    headers: {
                        "project_id":project_id
                    }
                })
            }
        }
    })