databaseSrv.$inject = [];
function databaseSrv($http) {
    return {
        createDatabase(data) {
            return $http({
                method: "POST",
                url: "/awstack-resource/v1/backups",
                data: data
            })
        }
    }
}

export { databaseSrv };