import './localDiskSrv';
var localDiskModule = angular.module("localDiskModule", ['ngTable','ngAnimate','ui.bootstrap','localDiskSrvModule']);
localDiskModule.controller("localDiskCtrl",["$rootScope", "$scope", "NgTableParams", "localDiskSrv", "checkedSrv", "$uibModal", "$translate","GLOBAL_CONFIG","$filter","generalSrv","$routeParams","TableCom","utilTool","$timeout","modalCom","$location","alertSrv",
    function($rootScope, $scope, NgTableParams, localDiskSrv, checkedSrv, $uibModal, $translate,GLOBAL_CONFIG,$filter,generalSrv,$routeParams,TableCom,utilTool,$timeout,modalCom,$location,alert) {
        var self = $scope;
        self.context = self;
        self.canEdit = true;
        self.canLoad = true;
        self.canUnload = true;
        self.canUnload = true;
        self.canDelete = true;
        self.pageSize = 10;
        self.detailData={};
        self.searchList = [
            {name:$translate.instant('aws.localDisk.table.status.all'),id:null,key:""},
            {name:$translate.instant('aws.localDisk.table.status.available'),id:"available",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori"},
            {name:$translate.instant('aws.localDisk.table.status.attaching'),id:"attaching",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
            {name:$translate.instant('aws.localDisk.table.status.attached'),id:"attached",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
            {name:$translate.instant('aws.localDisk.table.status.detaching'),id:"detaching",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
            {name:$translate.instant('aws.localDisk.table.status.error'),id:"error",value:$translate.instant('aws.instances.table.status.error'),key:"status_ori"},
        ];
        self.statusSelected = {};
        self.search = {
            globalSearchTerm: ""
        }
        self.localDiskCheckbox = { 
            checked: false,
            items: {}
        }

        //选择全部时，列表中部门和项目的处理
        self.$watch(function(){
            return localStorage.selectedAllDomain;
        },function(ne){
            utilTool.projectDomainTableCol(ne,2,self);
        });

        self.headers = {
            name:$translate.instant("aws.localDisk.localDiskName"),
            size:$translate.instant('aws.localDisk.config'),
            state:$translate.instant('aws.localDisk.status'),
            path:$translate.instant('aws.localDisk.location'),
            description:$translate.instant('aws.localDisk.remark'),
            domain:$translate.instant("aws.common.domain"),
            project:$translate.instant("aws.common.project"),
        };

        self.titleName = "localDisk"
        if(sessionStorage["localDisk"]){
            self.tableCols=JSON.parse(sessionStorage["localDisk"]);
        }else{
            self.tableCols = [
                { field: "check", title: "",headerTemplateURL:"localDiskCheckbox.html",show: true },
                { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                { field: "size", title: self.headers.size,sortable: "size",show: true,disable:false},
                { field: "state", title: self.headers.state,sortable: "state",show: true,disable:false},
                { field: "path", title: self.headers.path,sortable: "path",show: true,disable:false},
                { field: "description", title: self.headers.description,sortable: "description",show: true,disable:false},
            ];
        }

        if(localStorage.permission == 'enterprise'){
            self.serviceMenus = [$translate.instant("aws.localDisk.systemManage"),$translate.instant("aws.localDisk.operateManage"),$translate.instant("aws.localDisk.storageManage")]
        }else{
            self.serviceMenus = [$translate.instant("aws.localDisk.systemManage"),$translate.instant("aws.localDisk.configManage"),$translate.instant("aws.localDisk.storageManage")]
        }
        self.localDiskService = $translate.instant("aws.localDisk.localDiskService")

        // 获取本地磁盘表格数据
        self.initTable= (option)=> {
            if(!localStorage.localDiskService) return;
            TableCom.init(self,"localDiskTable", [], "id", self.pageSize, "localDiskCheckbox");
            let defaultParams = {
                pageNum: 1,
                pageSize: self.pageSize,
                pattern: self.search.globalSearchTerm,
                state: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : ""
            }
            let params = option ? option : defaultParams;
            self.localDiskTableData = [];
            localDiskSrv.getLocalDiskList(params).then(function(result){
                if(result && result.data){
                    dealResultDataInfo(result);
                }
            })
        }
        self.initTable();

        // 处理表格
        function dealResultDataInfo(result) {
            self.totalNum = result.total;
            self.localDiskTableData = result.data;
            self.localDiskTableData.forEach(item=>{
                let domainNameList = ["default"];
                let projectNameList = ["admin"];
                item.domainName = domainNameList.includes(item.domainName) ? $translate.instant("aws.common.defaultDepar") : item.domainName;
                item.projectName = projectNameList.includes(item.projectName) ? $translate.instant("aws.common.defaultProject") : item.projectName;
            });
            TableCom.init(self, "localDiskTable", result.data, "id", self.pageSize, "localDiskCheckbox")
        }

        // 切换页码
        self.changePage = function(options){
            self.currentPage = options.pageNum;
            self.pageSize = options.pageSize;
            options.pattern = self.search.globalSearchTerm;
            options.state = self.statusSelected.searchItem.id;
            self.initTable(options);
        };

        // 搜索
        self.searchBackend = function(options){
            self.currentPage = 1;
            let pageOption = {
                pageSize: self.pageSize,
                pageNum: 1,
                pattern: self.search.globalSearchTerm ? self.search.globalSearchTerm : "",
                state: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : ""
            }
            if(options.type == 'refresh'){
                self.search.globalSearchTerm = "";
                self.statusSelected.searchItem = self.searchList[0];
                pageOption.pattern = "";
                pageOption.state = ""
            }
            self.initTable(pageOption);
        }

        // 资源导出
        self.exportResource = function(){
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/disks?regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}&pattern=${self.search.globalSearchTerm?self.search.globalSearchTerm:""}&state=${self.statusSelected.searchItem.id?self.statusSelected.searchItem.id:""}`
            utilTool.downloadFile(url);
        }
        self.showExport = utilTool.isAllDomainSelect;

        // 监听选择的表格数据
        self.$watch(function(){
            return self.localDiskCheckbox.items;
        },function(val){
            if(!val) return;
            self.checkedItems = [];
            var arr = [];
            for (var i in self.localDiskCheckbox.items) {
                arr.push(self.localDiskCheckbox.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.localDiskTableData.forEach(item => {
                            if (item.id == key) {
                                self.checkedItems.push(item);
                            }
                        });
                    }
                }
            }
            if(self.checkedItems.length > 0) {
                self.canDelete = false;
            }else{
                self.canDelete = true;
            }
            if(self.checkedItems.length == 1) {
                for (let i = 0; i < self.checkedItems.length; i++) {
                    if(self.checkedItems[i].state == "available") {
                        self.canEdit = false;
                        self.canLoad = false;
                        self.canUnload = true;
                        self.canDelete = false;
                    }else if(self.checkedItems[i].state == "attached"){
                        self.canEdit = false;
                        self.canLoad = true;
                        self.canUnload = false;
                        self.canDelete = true;
                        getLocalDiskDetail(self.checkedItems[i].uuid);
                    }else {
                        self.canEdit = true;
                        self.canLoad = true;
                        self.canUnload = true;
                    }
                }
            }else{
                self.canEdit = true;
                self.canLoad = true;
                self.canUnload = true;
            }
        },true);

        // 打开详情
        self.lineDetailIn = function (item) {
            getLocalDiskDetail(item.uuid);
            $location.url(`/cvm/localDisk?id=${item.id}`)
        }

        // 关闭详情
        self.lineDetailBack = function () {
            $location.url(`/cvm/localDisk`)
        }
  
        // 新建本地磁盘
        self.createLocalDisk = function() {
            self.createLocalDiskModal = modalCom.init("createLocalDisk.html","createLocalDiskCtrl",{
                context: () => self,    
                refresh: () => self.initTable,
            })
        }
        // 编辑本地磁盘
        self.editLocalDisk = function(value) {
            self.editLocalDiskModal = modalCom.init("editLocalDisk.html","editLocalDiskCtrl",{
                context: () => self,
                type: () => value,
                refresh: () => self.initTable,
                checkedItems: () => self.checkedItems
            })
        }

        // 加载云主机
        self.loadInstance = function(value) {
            self.loadInstanceModal = modalCom.init("mountInstanceVm.html","mountInstanceCtrl",{
                context: () => self,
                type: () => value,
                refresh: () => self.initTable,
                checkedItems: () => self.checkedItems
            })
        }

        // 卸载云主机
        self.unloadInstance = function(value) {
            self.unloadInstanceModal = modalCom.init("uninstallInstanceVm.html","uninstallInstanceCtrl",{
                context: () => self,
                type: () => value,
                refresh: () => self.initTable,
                checkedItems: () => self.checkedItems    
            })
        },

        // 删除本地磁盘
        self.deleteLocalDisk = function() {
            var content = {
                target: "delLocalDisk",
                msg:"<span>"+ $translate.instant("aws.localDisk.tipMsg.deleteLocalDisk") + "</span>"
            };
            self.$emit("delete", content);
        },

        // 监听本地磁盘
        self.$on("delLocalDisk", function() {
            var delList = [];
            self.checkedItems.map(item => {
                delList.push({ uuid:item.uuid, diskUuid:item.diskUuid });
            });
            let params = delList;
            localDiskSrv.deleteLocalDisk(params).then(function(res){
                self.initTable();
            })
        });

        // 获取本地磁盘详情
        function getLocalDiskDetail(uuid){
            self.localDiskInsIdList = [];
            localDiskSrv.getLocalDiskDetail(uuid).then(function(res){
                if(res && res.data) {
                    self.detailData = Object.assign({},res.data);
                    self.detailData.createTime = $filter("date")(self.detailData.createTime,"yyyy-MM-dd HH:mm:ss");
                }
            })
        }

        // 监听挂载和卸载的推送消息
        self.$on("localDiskSocket",function(e,data){
            let tipMessage = $translate.instant("aws.sockets.opLog." + data.eventType + ".localDisk");
            switch(data.eventType) {
                case "compute.instance.volume.attach":
                case "compute.instance.volume.detach":
                    alert.set(data.requestId, tipMessage, "success", 5000);
                   break
                case "compute.instance.volume.attach.error":
                case "compute.instance.volume.detach.error":
                    alert.set(data.requestId, tipMessage, "error", 5000);
                    break    
            }
            $timeout(function(){
                self.initTable();
            },500)
        })

        // 本地资源存储池创建成功后在当前页面时刷新数据
        self.$on("addStorageSuccess", function(e, data) {
            if(data == 'create.local.disk.success') {
                self.initTable();
            }
        })
    }
])
.controller("createLocalDiskCtrl", ["$scope", "$rootScope","$translate","$timeout","GLOBAL_CONFIG","$uibModalInstance","localDiskSrv","NgTableParams","checkedSrv","refresh","storageManagementSrv",
function($scope, $rootScope, $translate, $timeout, GLOBAL_CONFIG,$uibModalInstance,localDiskSrv,NgTableParams,checkedSrv,refresh,storageManagementSrv) {
    var self = $scope;
    self.context = self;
    self.createRadio = {
        radio: ""
    }
    self.localDiskInfo = {
        name:"",
        description:"",
        size:"",
        diskUuid:""
    };
    self.nodeSelectList = [];
    self.remain = {
        size: ""
    }
    self.saveDisabled = false;
    self.checkNotEmptyShow = true;
    self.headers = {
        host:$translate.instant("aws.system.storagement.nodeName"),
        name:$translate.instant("aws.localDisk.diskName"),
        type:$translate.instant('aws.localDisk.diskType'),
        capacity:$translate.instant('aws.localDisk.diskCapacity'),
    }
    self.tableCols = [
        { field: "radio", title: "",show: true },
        { field: "host", title: self.headers.host,show: true,disable: true},
        { field: "name", title: self.headers.name,show: true,disable:true},
        { field: "type", title: self.headers.type,show: true,disable:false},
        { field: "size", title: self.headers.capacity,show: true,disable:false},
    ];

    // 查询所有健康的节点
    function getNormaNodeList() {
        self.nodeSelectList = [];
        storageManagementSrv.getNormaNodeList(localStorage.enterpriseUid,localStorage.regionUid).then(function(res){
            if(res && res.data) {
                res.data.forEach((item,index)=>{
                    self.nodeSelectList.push({hostName:item,id:index});
                })
            }
        })
    }
    
    // 通过节点名称查询硬盘
    function getLocalDiskList(hostName){
        let params = {
            pattern: hostName,
            state: "available"
        }
        storageManagementSrv.getDetailLocalDiskList(params).then(function(result){
            if(result && result.data){
                result.data.forEach(item=>{
                    item.remainWidth = `${(item.remain/item.size*100).toFixed(2)}%`;
                    item.usedWidth = `${(((item.size-item.remain)/item.size)*100).toFixed(2)}%`;
                    item.useSize = `${(item.size-item.remain).toFixed(1)}`;
                    item.type = item.type.toLowerCase();
                })
                self.tableData = result.data;
                self.tableParams = new NgTableParams({ count: 99999 }, { counts: [], dataset: result.data});
                checkedSrv.checkDo(self, result.data, "uid");
                if(result.data.length>0) {
                    self.createRadio.radio = JSON.stringify(result.data[0]) 
                    self.changeTableNode(result.data[0]);
                    self.checkNotEmptyShow = true;
                }else{
                    self.checkNotEmptyShow = false;
                }
            }
        });
    }

    getLocalDiskList('');

    self.changeTableNode = (value)=> {
        self.remain.size = value ? parseInt(value.remain) : '';
    }

    self.changeSelectNode = function(data) {
        getLocalDiskList(data.hostName);
    }

    self.createLocalDiskConfirm = function(field){
        self.submitInValid = false;
        self.saveDisabled = true;
        if(field.$valid && self.checkNotEmptyShow){
            let { uuid } = JSON.parse(self.createRadio.radio);
            let postData = {
                name: self.localDiskInfo.name,
                description: self.localDiskInfo.description,
                size: self.localDiskInfo.size,
                diskUuid: uuid
            }
            localDiskSrv.createLocalDisk(postData).then(function(result){
                if(result) {
                    $uibModalInstance.dismiss('cancel');
                    refresh();
                }
                self.saveDisabled = false;
            })
        }else{
            self.submitInValid = true;
            $timeout(()=>{
                self.saveDisabled = false;
            },2000)
        }
    }
    
    getNormaNodeList();
}])
.controller("editLocalDiskCtrl", ["$scope", "$rootScope","$translate","GLOBAL_CONFIG","$uibModalInstance","localDiskSrv","type","checkedItems","refresh",
function($scope, $rootScope, $translate, GLOBAL_CONFIG,$uibModalInstance,localDiskSrv,type,checkedItems,refresh) {
    var self = $scope;
    self.editLocalDiskInfo ={
        uuid: "",
        name: "",
        description: ""
    }
    let { name, uuid, description } = checkedItems[0];
    self.editLocalDiskInfo.uuid = uuid;
    self.editLocalDiskInfo.name = name;
    self.editLocalDiskInfo.description = description;
    self.editLocalDiskConfirm = function(field){
        self.submitInValid=false
        if(field.$valid){
            let postData = Object.assign({},self.editLocalDiskInfo);
            localDiskSrv.editLocalDisk(postData).then(function(result){
                if(result) {
                    $uibModalInstance.dismiss('cancel');
                    refresh();
                }
            })
        }else{
            self.submitInValid=true;
        }
    }
}])
.controller("mountInstanceCtrl", ["$scope", "$rootScope","$translate","GLOBAL_CONFIG","$uibModalInstance","localDiskSrv","NgTableParams","checkedSrv","type","refresh","checkedItems","$timeout",
function($scope, $rootScope, $translate, GLOBAL_CONFIG,$uibModalInstance,localDiskSrv,NgTableParams,checkedSrv,type,refresh,checkedItems,$timeout) {
    var self = $scope;
    self.context = self;
    self.pageSize = 8;
    self.radioSelect = {
        oneInstance:''
    }
    self.globalSearchTerm = "";
    self.haSwitch = false;
    self.switchText = $translate.instant('aws.localDisk.close');
    self.saveDisabled = false;
    self.checkNotEmptyShow = true;
    self.domainProject = {
        domain_name: checkedItems[0].domainName,
        domain_id: checkedItems[0].domainUid,
        project_name: checkedItems[0].projectName,
        project_id: checkedItems[0].projectUid
    };
    self.headers = {
        name:$translate.instant("aws.localDisk.cloudHostName"),
        status:$translate.instant('aws.localDisk.cloudHostStatus'),
        imageName:$translate.instant('aws.localDisk.image'),
        hostName:$translate.instant('aws.localDisk.nodeName')
    }
    self.tableCols = [
        { field: "radio", title: "",show: true },
        { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true },
        { field: "status", title: self.headers.status,sortable: "status",show: true,disable:false },
        { field: "imageName", title: self.headers.imageName,sortable: "imageName",show: true,disable:false },
        { field: "hostName", title: self.headers.hostName,sortable: "hostName",show: true,disable:false }
    ];

    self.changePage = function(options) {
        options.pattern = self.globalSearchTerm?self.globalSearchTerm:"";
        options.diskUuid = checkedItems[0].diskUuid
        initCanLoadIns(options,self.domainProject)
    }

    self.changeTableNode = (value)=> {
        self.radioSelect.oneInstance = value;
        // hostBind字符为false或者为null时是开启高可用 否则就是关闭
        self.haSwitch = JSON.parse(value).hostBind == true ? false : true;
        self.switchText = dealSwitchText(self.haSwitch);
    }
    
    self.switchChange = (value)=> {
        self.haSwitch = value;
        self.switchText = dealSwitchText(value);
    }

    self.confirm = function() {
        self.checkNotEmptyShow = (Object.keys(self.radioSelect.oneInstance).length>0) ? true : false;
        self.saveDisabled = true;
        if(self.checkNotEmptyShow) {
            let list = checkedItems.map(item=>{
                return { uuid:item.uuid, diskUuid:item.diskUuid };
            });
            let vmId = JSON.parse(self.radioSelect.oneInstance).uid;
            //高可用关闭  0-关闭  1-开启
            let haSwitch = self.haSwitch == true ? 1 : 0; 
            localDiskSrv.mountLocalDisk(list,vmId,haSwitch).then(function(result) {
                if(result) {
                    $uibModalInstance.dismiss('cancel');
                    refresh();
                }
                self.saveDisabled = false;
            });
        }else{
            $timeout(()=>{
                self.saveDisabled = false;
            },2000)
        }
    };

    function dealSwitchText(value) {
        let hasSwitch = value ? $translate.instant('aws.localDisk.open') : $translate.instant('aws.localDisk.close');
        return hasSwitch;
    }
 
    function initCanLoadIns(options,headers){
        headers.domain_name = headers.domain_name == $translate.instant("aws.common.defaultDepar") ? 'default' : headers.domain_name;
        headers.project_name = headers.project_name == $translate.instant("aws.common.defaultProject") ? 'admin' : headers.project_name;
        self.currentPage = options.pageNum;
        options.filterType = 'db_inst';// 过滤掉云数据库和负载均衡型的云主机
        localDiskSrv.getLocalDiskMountList(options,headers).then(function(result){
            if(result && result.data){
                self.tabledata = result.data
                self.totalNum = result.total;
                self.tableParams = new NgTableParams({ count: 9999 }, { counts: [], dataset: self.tabledata});
                if(result.data.length>0) {
                    self.changeTableNode(JSON.stringify(self.tabledata[0])); 
                }
            }   
        });
    }

    // 搜索
    self.searchBackend = function(){
        self.currentPage = 1;
        let pageOption = {
            pageSize: self.pageSize,
            pageNum: 1,
            pattern: self.globalSearchTerm ? self.globalSearchTerm : "",
            diskUuid: checkedItems[0].diskUuid
        }
        initCanLoadIns(pageOption,self.domainProject);
    }

    var pageOption = {
        pageSize: self.pageSize,
        pageNum: 1,
        pattern: "",
        diskUuid: checkedItems[0].diskUuid
    };
    initCanLoadIns(pageOption,self.domainProject)
}]).controller("uninstallInstanceCtrl", ["$scope", "$rootScope","$translate","GLOBAL_CONFIG","$uibModalInstance","localDiskSrv","NgTableParams","checkedSrv","type","refresh","checkedItems","context",
function($scope, $rootScope, $translate, GLOBAL_CONFIG,$uibModalInstance,localDiskSrv,NgTableParams,checkedSrv,type,refresh,checkedItems,context) {
    var self = $scope;
    self.context = self;
    self.submitValid = false;
    self.uninstallInstancesForm = {
        localDiskHost: "",
        status: true
    };
    self.switchText = $translate.instant('aws.localDisk.open'); 
    self.localDiskInsIdList = [];

    self.switchChange = function(value){
        self.uninstallInstancesForm.status = value;
        self.switchText = dealSwitchText(value);
    }

    self.changeHost = (value)=>{
        self.uninstallInstancesForm.localDiskHost = value;
        // hostBind字符为false或者为null时是开启高可用 否则就是关闭
        // self.uninstallInstancesForm.status = value.hostBind == true ? false : true;
        self.uninstallInstancesForm.status = true;
        self.switchText = dealSwitchText(self.uninstallInstancesForm.status);
    }
    
    function dealSwitchText(value) {
        let hasSwitch = value ? $translate.instant('aws.localDisk.open') : $translate.instant('aws.localDisk.close');
        return hasSwitch;
    }

    self.confirm = function(filed){
        if(filed.$valid) {
            let vmId = self.uninstallInstancesForm.localDiskHost.uid;
            let volumeId = checkedItems[0].uuid;
            let haSwitch = self.uninstallInstancesForm.status == true ? 1 : 0;
            localDiskSrv.uninstallLocalDisk(vmId,volumeId,haSwitch).then(function(result){
                if(result) {
                    $uibModalInstance.dismiss('cancel');
                    refresh();
                }
            })
        }else{
            self.submitValid = true;
        }
    }

    // 查询加载的云主机
    self.mountVolumeData = function() {
        let uuid = checkedItems[0].uuid;
        localDiskSrv.getLocalDiskDetail(uuid).then(function(res){
            if(res && res.data) {
                self.localDiskInsIdList.push({name:res.data.instanceName,uid:res.data.instanceUuid});
                self.changeHost(self.localDiskInsIdList[0]);
            }
        })
    }
    self.mountVolumeData();
}])