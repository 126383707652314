import './labelSrv'
import './instancesSrv'
angular.module('LabelModule', ['ngTable', 'labelMSrv', 'instancesSrv']).controller('LabelCtrl', ["$scope", "$rootScope", "modalCom", "$translate", "NgTableParams", "labelMSrv", "instancesSrv",'TableCom',"utilTool","$filter",
    function (self, root, modalCom, $translate, NgTableParams, labelMSrv, instancesSrv,TableCom,utilTool,$filter) {
        self.pageNum = 1;
        self.pageSize = 10;
        self.totalNum = 0;
        self.globalSearchTerm = '';
        // 按钮默认值
        self.binddisabled = true;           // 绑定
        self.relievedisabled = true;        // 解绑
        self.updatedisabled = true;         // 编辑
        self.deldisabled = true;            // 删除
        self.ttEquipmentData = [];

        self.checkboxes = {
            checked: false,
            items: {},
            arr:[]
        };

        self.headers = {
            title : $translate.instant("aws.quota.labelTitle"),
            name : $translate.instant("aws.instances.cloudInstanceName"),
            describe : $translate.instant("aws.logo.des"),
            createTime : $translate.instant("aws.backupStrategy.Creationtime"),
            domain: $translate.instant("aws.common.domain"),
            project: $translate.instant("aws.common.project")
        }

        
        // 选择全部时 列表中部门和项目的处理
        self.$watch(function(){
            return localStorage.selectedAllDomain;
        },function(val){
            utilTool.projectDomainTableCol(val, 2, self);
        })

        self.$watch(function () {
            return self.checkboxes.items;//监控checkbox
        }, function (val, old) {
            self.checkedItems = [];
            var arr = [];
            for (var i in self.checkboxes.items) {
                arr.push(self.checkboxes.items[i])
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.ttEquipmentData.forEach(item => {
                            if (item.id == key) {
                                self.checkedItems.push(item);
                            }
                        })
                    }
                }
            }
            const length = self.checkedItems.length
            if (length != 0) {
                if (length == 1) {
                    self.updatedisabled = false;
                    self.relievedisabled = false;
                    self.binddisabled = false;
                } else {
                    self.binddisabled = true;
                    self.updatedisabled = true;
                    self.relievedisabled = true;
                }
                self.deldisabled = false;
                // self.binddisabled = false;
            } else {
                self.binddisabled = true;
                self.relievedisabled = true;
                self.updatedisabled = true;
                self.deldisabled = true;
            }
        }, true);

        self.snapshot_search = {
            globalSearchTerm: '',
        };
        self.statusConfig = {
            "statusSelected": {}
        }
        
        // 搜索
        self.searchBackend = function(options){
            if(options.type == 'refresh'){
                self.snapshot_search.globalSearchTerm = '';
                self.pageNum = 1;
            }
            self.getLableList(self.snapshot_search.globalSearchTerm)
        }

        /**
         * 获取标签列表
         */
        self.getLableList = (value) => {
            const config = {
                pageNum: self.pageNum,
                pageSize: self.pageSize,
                patten: value || ''
            }

            labelMSrv.getList(config).then(res => {
                if (res.data) {
                    self.totalNum = res.total;
                    var dealData = res.data;
                    dealData = operateData(dealData);
                    self.ttEquipmentData = dealData;
                    TableCom.init(self, 'tableParams', self.ttEquipmentData, 'id', self.pageSize, 'checkboxes');
                    // 关闭所有checkbox
                    self.checkboxes.items = {}
                }
            })
        }
        self.getLableList()

        // 处理表格数据
        function operateData(data){
            data.forEach(item=>{
                // 处理default为默认部门；admin为默认项目
                var domainNameList = ["default"];
                var projectNameList = ["admin"];
                item.domainName = domainNameList.includes(item.domainName) ? $translate.instant("aws.common.defaultDepar") : item.domainName;
                item.projectName = projectNameList.includes(item.projectName) ? $translate.instant("aws.common.defaultProject") : item.projectName;
                // 拼接云主机名称
                item.bindinstances = ''
                if (item.instances&&item.instances.length != 0) {
                    let arr = [];
                    for (const key in item.instances) {
                        arr.push(item.instances[key])
                    }
                    item.bindinstances = arr.join(',')
                }
                // 处理创建时间
                item.newCreateTime = $filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
            })
            return data;
        }

        // 改变当前页
        self.changePage = function ({pageNum,pageSize}) {
            self.pageNum = pageNum;
            self.pageSize = pageSize;
            self.getLableList(self.snapshot_search.globalSearchTerm)
        }

        self.operateLabel = function (value) {
            self.createItemModal = modalCom.init('updateTag.html', 'updateTagCtrl', {
                context: () => self,
                type: () => value,
                getLableList: () => self.getLableList,
                checkedItems: () => self.checkedItems
            })
        }

        // 绑定/解绑标签
        self.bindLabel = function (value) {
            self.createItemModal = modalCom.init('bindTag.html', 'bindTagCtrl', {
                context: () => self,
                type: () => value,
                NgTableParams: () => NgTableParams,
                checkedItems: () => self.checkedItems,
                getLableList: () => self.getLableList
            })
        }

        // 删除标签
        self.deleteLabel = function () {
            var content = {
                target: "delFunc",
                msg: "<span>" +  $translate.instant("aws.errors.isDelete") + "</span>",
                data: self.checkedItems
            };
            self.$emit("delete", content);
        }
        self.$on("delFunc", function (e, data) {
            const idList = data.map(item => item.id)
            const idStr = idList.join(',')
            labelMSrv.deleteLabel(idStr).then(res => {
                self.pageNum = 1;
                self.getLableList()
            })
        })
        
        self.titleName = "labelModule";
        if (sessionStorage["labelModule"]) {
            self.tableCols = JSON.parse(sessionStorage["labelModule"]);
        } else {
            self.tableCols = [
                { field: "check", title: "",headerTemplateURL:"headerCheckbox.html",show: true },
                { field: "name", title: self.headers.title,sortable: "name",show: true,disable:true },
                { field: "bindinstances", title: self.headers.name,sortable: "bindinstances",show: true,disable:false },
                { field: "description", title: self.headers.describe,sortable: "description",show: true,disable:false },
                { field: "newCreateTime", title: self.headers.createTime,sortable: "newCreateTime",show: true,disable:false }
            ];
        }
    }])
    // 标签新建编辑弹窗
    .controller("updateTagCtrl", ["$scope", "$rootScope", '$uibModalInstance', 'context', 'type', 'labelMSrv', 'getLableList', 'checkedItems','$translate', function ($scope, $rootScope, $uibModalInstance, context, type, labelMSrv, getLableList, checkedItems, $translate) {
        let self = $scope;
        self.title = type == 'create' ? $translate.instant("aws.action.create") : $translate.instant("aws.action.edit");
        self.isVerifyPss = false;

        self.labelObj = {
            title: '',
            describe: ''
        }
        if (type == 'update' && checkedItems.length == 1) {
            const { name, description } = checkedItems[0]
            self.labelObj.title = name;
            self.labelObj.describe = description;
        }
        // 新建
        self.sumit = function (labelObj, labelForm) {
            self.isVerifyPss = false;
            if(labelForm.$valid){
                const { title, describe } = labelObj;
                const config = {
                    name: title,
                    description: describe
                }
                if (type == 'create') {
                    labelMSrv.addLabel(config).then(() => {
                        refresh()
                    })
                } else {
                    labelMSrv.updateLabel({
                        ...checkedItems[0],
                        ...config
                    }).then(() => {
                        refresh()
                    })
                }
                function refresh() {
                    getLableList();
                    $uibModalInstance.close();
                }
            } else {
                self.isVerifyPss = true;
            }
        }
    }])
    // 标签绑定解绑弹窗
    .controller('bindTagCtrl', ["$scope", "$rootScope", '$uibModalInstance', 'context', 'type', 'NgTableParams', "instancesSrv", "checkedItems", "labelMSrv", "getLableList","TableCom", "$translate",
    function ($scope, $rootScope, $uibModalInstance, context, type, NgTableParams, instancesSrv, checkedItems, labelMSrv, getLableList,TableCom,$translate) {
        let self = $scope;
        self.type = type == 'bind';
        self.title = self.type ? $translate.instant("aws.action.bind") : $translate.instant("aws.action.unbind");
        self.checkedItemsList = checkedItems;
        self.pageNum = 1;
        self.pageSize = 10;
        self.total = 0;
        self.globalSearchTerm = '';
        self.snapshot_search = {
            globalSearchTerm: '',
        };
        self.statusConfig = {
            "statusSelected": {}
        }

        self.table = {
            host: $translate.instant("aws.instances.cloudInstanceName"),
            department:$translate.instant("aws.common.domain"),
            project:$translate.instant("aws.common.project")
        }

        self.checkboxes = {
            checked:false,
            items: {},
            arr:[]
        };
        self.ttEquipmentData = [];
        self.$watch(function () {
            return self.checkboxes.items; // 监控checkbox
        }, function (val) {
            self.checkedItemsArr = [];
            var arr = [];
            for (var i in self.checkboxes.items) {
                arr.push(self.checkboxes.items[i])
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.ttEquipmentData.forEach(item => {
                            if (item.uid == key) {
                                self.checkedItemsArr.push(item);
                            }
                        })
                    }
                }
            }
        }, true);
        
        // 搜索
        self.searchBackend = function(){
            self.pageNum = 1;
            if(self.type){
                self.getInstancesList()
            } else {
                const str = self.snapshot_search.globalSearchTerm;
                const tempArr = self.unbindarr.filter(item => {
                    const {name, projectName, domainName} = item
                    return name.indexOf(str) != -1 || projectName.indexOf(str) != -1 || domainName.indexOf(str) != -1;
                })
                // const listArr = tempArr.length == 0 ? self.unbindarr: tempArr;
                const listArr = tempArr;
                self.total = listArr.length;
                table(listArr.slice(0, 10));
            }
        }
        
        // 改变当前页
        self.changePage = function ({pageNum,pageSize}) {
            self.pageNum = pageNum;
            self.pageSize = pageSize;
            if(self.type){
                self.getInstancesList()
            } else {
                const tempArr = self.unbindarr.slice((self.pageNum - 1) * self.pageSize, self.pageNum * self.pageSize)
                table(tempArr)
            }
        }
        
        // 查询云主机列表
        self.getInstancesList = function(){
            const config = {
                pageNum: self.pageNum,
                pageSize: self.pageSize,
                pattern: self.snapshot_search.globalSearchTerm || ''
            }
            config.instanceType = 'all';// all-全部 inst-云主机 db_inst-云数据库
            const {projectUid,domainUid,projectName,domainName} = self.checkedItemsList[0]
            const header = {
                project_id: projectUid,
                domain_id: domainUid,
                project_name: projectName,
                domain_name: domainName
            }
            instancesSrv.getInstanList(config, header).then(res => {
                if (res.data) {
                    self.total = res.total;
                    let filterDataList = [];
                    filterDataList = res.data.filter(item=>{
                        return item.status!='error'
                    })
                    table(filterDataList)
                }
            })
        }

        self.confirmadd = function () {
            if (self.checkedItemsArr.length == 0) {
                $rootScope.$broadcast("hint", $translate.instant("aws.action.noSelectHost"));
                return 
            }
            const config = {};
            const arr = self.checkedItemsArr.map(item => item.uid);
            if(self.type) { // 绑定
                const instances = checkedItems[0].instances;
                let list = instances ? Object.keys(instances): [];
                const instancesUid = arr.filter(item => list.findIndex(value => value == item) == -1);
                requestFn(labelMSrv.bindLable, instancesUid)
            } else { // 解除绑定
                requestFn(labelMSrv.relieveLable, arr)
            }

            // 请求函数
            function requestFn(reqcallback, arrList){
                checkedItems.forEach(item => config[item.id] = arrList);
                reqcallback(config).then(()=>{
                    $uibModalInstance.close();
                    getLableList()
                })
            }
        }

        if(self.type) {
            self.getInstancesList()
        } else {
            self.unbindarr = [];
            const {domainName, projectName} = self.checkedItemsList[0];
            const instances = checkedItems[0].instances;
            for (const key in instances) {
                self.unbindarr.push({name: instances[key], uid: key,domainName, projectName})
            }
            self.total = self.unbindarr.length;
            table(self.unbindarr.slice(0, 10));
        }

        // 表格渲染
        function table(arr){
            self.ttEquipmentData = arr;
            TableCom.init(self,'tableList', arr, 'uid', self.pageSize, 'checkboxes');
        }
    }])
    // 匹配http
    .directive('http', function(){
        return {
            require:'ngModel',
            link:function(scope,elm,attrs,ctrl){
                ctrl.$parsers.unshift(function(value){
                    const reg = /(?=(http|ftp|https):\/\/)/;
                    if(reg.test(value)) {
                        ctrl.$setValidity('http', false);
                        ctrl.$setValidity('specialsymbol', true);
                        return undefined
                    }
                    ctrl.$setValidity('http', true);
                    return value
                })
            }
        }
    })
    // 匹配是否存在非指定正则
    .directive('specialsymbol', function(){
        return {
            require:'ngModel',
            link:function(scope,elm,attrs,ctrl){
                ctrl.$parsers.unshift(function(value){
                    const reg = /([^A-Za-z\d+-=_/@.:：()（）[\]【】\u4e00-\u9fa5])+/;
                    if(reg.test(value)) {
                        ctrl.$setValidity('specialsymbol', false);
                        return undefined
                    }
                    ctrl.$setValidity('specialsymbol', true);
                    return value
                })
            }
        }
    })
    // 自定义限制字符长度
    .directive('strmaxlength', function(){
        return{
            require: 'ngModel',
            link:function(scope,elm,attrs,ctrl){
                ctrl.$parsers.unshift(function(value){
                    const length = attrs['strmaxlength']
                    let b = 0;
                    for(var i = 0; i < value.length; i++) {
                        if(value.charCodeAt(i) > 255) {
                            b += 2;
                        }else {
                            b ++;
                        }
                    }
                    if(b > length){
                        ctrl.$setValidity('strmaxlength', false);
                        return undefined 
                    }
                    ctrl.$setValidity('strmaxlength', true);
                    return value
                })
            }
        }
    })
    .directive('ipaccuracy',function(){
        return {
            require:'ngModel',
            link:function(scope,elm,attrs,ctrl){
                ctrl.$parsers.unshift(function(value){
                    const id = attrs['ipaccuracy'];
                    const inputOne = scope.editSubnetForm[`ip_chunk_one${id}`];
                    const inputTwo = scope.editSubnetForm[`ip_chunk_two${id}`];
                    const inputThree = scope.editSubnetForm[`ip_chunk_three${id}`];
                    const inputFour = scope.editSubnetForm[`ip_chunk_four${id}`];

                    const ip1 = inputOne.$viewValue;
                    const ip2 = inputTwo.$viewValue;
                    const ip3 = inputThree.$viewValue;
                    const ip4 = inputFour.$viewValue;

                    if((ip1&& ip2&& ip3&& ip4) || (ip1==''&&ip2==''&&ip3==''&&ip4=='')){
                        inputOne.$setValidity('ipaccuracy', true);
                        inputTwo.$setValidity('ipaccuracy', true);
                        inputThree.$setValidity('ipaccuracy', true);
                        inputFour.$setValidity('ipaccuracy', true);
                        return value
                    }
                    inputOne.$setValidity('ipaccuracy', false);
                    inputTwo.$setValidity('ipaccuracy', false);
                    inputThree.$setValidity('ipaccuracy', false);
                    inputFour.$setValidity('ipaccuracy', false);
                    return value
                })
            }
        }
    })
    // 封装Select组件
    .directive('angSelect',function(){
        return {
            restrict: "E",
            scope: {
                defaultvalue: '=',
                datalist: '<',
                placeholder: '<',
                selectcallback:'<'
            },
            template:`
            <ui-select ng-model="defaultValue" ng-change="changeFn(defaultValue)">
                <ui-select-match placeholder="{{pla}}">{{$select.selected.name}}</ui-select-match>
                <ui-select-choices repeat="item.value as item in List | propsFilter:{name:$select.search}">
                    <div ng-bind-html="item.name | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
            `,
            link:function(scope, ele, attr, ctrl){
                scope.defaultValue = scope.defaultvalue;
                scope.List = scope.datalist;
                scope.pla = scope.placeholder;
                scope.changeFn = scope.selectcallback || null;
            }
        }
    })