
import "./gpuSrv";

angular.module("gpuModule", ["ngSanitize", "ngTable", "ngAnimate", "ngMessages", "ui.bootstrap", "ui.select", "gpusrvModule"])
    .controller("gpuCtrl", function ($scope, $rootScope, $uibModal,$translate, NgTableParams, checkedSrv,GLOBAL_CONFIG,gpuSrv,vgpuSrv,newCheckedSrv, $timeout,commonFuncSrv,$location) {
         var self = $scope;
        self.headers = {
            "type": $translate.instant("aws.gpu.type"),
            "pci":$translate.instant("aws.gpu.pci"),
            "vgpuType": $translate.instant("aws.gpu.vgpuType"),
            "videoMemory": $translate.instant("aws.gpu.videoMemory"),
            "video": $translate.instant("aws.gpu.video"),
            "dpi": $translate.instant("aws.gpu.dpi"),
            "instNum":$translate.instant("aws.gpu.instNum"),
            "node":$translate.instant("aws.gpu.node")
        };
        function initData(options){
            
            var pageDefaultOption = {
                pageSize:10,
                pageNum:1,
                pattern:null,
                status:null
            };  
            var pageOption = angular.extend(pageDefaultOption,options)
            gpuSrv.getPhyGpu(pageOption).then(function(result) {
                result.data?self.loadData = true:"";
                if (result && result.data) {
                    var deliverData = result.data;
                    successFunc(deliverData);
                    //tableResize(deliverData);
                    // self.totalNum = result.total;
                }
            })
        }
        function successFunc(data) {
            self.applyGlobalSearch = function() {
                var term = self.globalSearchTerm;
                self.centertableParams.filter({ searchTerm: term });
            };
            self.clusterTableData = data;
            self.clusterListData = data;
            self.clusterSearchTerm(({tableData:self.clusterListData,titleData:self.clusterTitleData}))
            var tableId = "id";
            self.centertableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: data });
            newCheckedSrv.checkDo(self, data, tableId,"centertableParams"); 
        }
        self.refresh = function(){
            self.globalSearchTerm="";
            initData();
        }
        self.clusterSearchTerm=function(obj){
            var tableData = obj.tableData;
            var titleData = obj.titleData;
            tableData.map(function(item){
                item.searchTerm="";
                titleData.forEach(function(showTitle){
                    if(showTitle.value){
                        // if(showTitle.search=='source'){
                        //    item.searchTerm+=[item.source_ip_address,item._source_port].join("\b");
                        // }else if(showTitle.search=='destination'){
                        //    item.searchTerm+=[item.destination_ip_address,item._destination_port].join("\b");  
                        // }else{
                    item.searchTerm+=item[showTitle.search]+"\b";
                        // }
                    }
                });
            });     
        };
        self.clusterTitleData=[
            {name:'cluster.name',value:true,disable:true,search:'name'},
            {name:'cluster.pci',value:true,disable:false,search:'pci'},
            {name:'cluster.node',value:true,disable:false,search:'node'},
        ];
        initData();

        //详情事件
        self.handleDetail = function(item){
            
            $location.url('/system/gpu?id='+item.node+'&pci='+item.pci+'&name='+item.name)
            
        }
        self.back = function(){
            history.back();
        }
        //详情页面
        $scope.$on("getDetail", function(event, value) {
            console.log(value);
            $scope.gpuName = $location.search().name;
            let oData1 = {
                node:$location.search().id,
                pci:$location.search().pci
            }
            vgpuSrv.getGpuSpecsByGpu(oData1).then(function(result) {
                console.log(result);
                if (result && result.data) {
                    self.gputableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: result.data });
                }
            })
        })
    })