
enhanceCtrl.$inject = ["$scope",  "$location", "$routeParams",  "$uibModal", "$translate", "loadBalanceSrv", "alertSrv","enhanceSrv","TableCom","utilTool","$filter"];
function enhanceCtrl($scope,  $location, $routeParams,  $uibModal, $translate,  loadBalanceSrv, alert,enhanceSrv,TableCom, utilTool,$filter) {
    var self = $scope;
    self.alert = alert
    self.pageNum = 1;
    self.pageSize = 10;
    self.ttEquipmentData = [];
    self.detailData = {};
    self.DataInfo = {}
    self.canClick = false;
    self.editDisabled= true   
    self.deleteDisabled= true
    self.choiceAll = localStorage.selectedAllDomain;
    self.monitorItemArr = [
        { text: $translate.instant('aws.loadbalance.monitorItem.item0'), value: 'activeConnections' }
    ];
    
    self.titleData = [
        { name: 'name', value: true },
        { name: 'privateIP', value: true },
        { name: 'publicIP', value: true },
        { name: 'status', value: true },
        { name: 'description', value: true }
    ];

    self.framework = [
        { text: $translate.instant("aws.config.tallConfig"), value: 0 , cpu: 16, ram: 16384}, 
        { text: $translate.instant("aws.config.centreConfig"), value: 1, cpu: 8, ram: 8192}, 
        { text: $translate.instant("aws.config.lowConfig"), value: 2, cpu: 4, ram: 4096}
    ]
    self.checkboxes = {
        checked: false,
        items: {},
        arr:[]
    };
    self.statusConfig = {
        "statusSelected": {}
    }
    self.snapshot_search = {
        globalSearchTerm: '',
    };
    
    // 搜索
    self.searchBackend = function({type}){
        self.pageNum = 1;
        if(type == 'refresh'){
            self.snapshot_search.globalSearchTerm = '';
        }
        initTabData()
    }

    initTabData();
    // 初始化列表数据
    function initTabData() {
        const config = {
            pageNum: self.pageNum,
            pageSize: self.pageSize,
            pattern: self.snapshot_search.globalSearchTerm || ''
        }
        enhanceSrv.enhanceList(config).then(result => {
            if (result && result.data && angular.isArray(result.data)) {
                self.blance_data = result.data;
                self.ttEquipmentData = result.data.map(item=>{
                    item.provisioningStatus = item.provisioningStatus.toUpperCase()
                    return item
                });
                self.blance_data.forEach(item => {
                    const textObj = self.framework.find(now => now.cpu == item.vcpus)
                    item.configMsg = textObj ? textObj.text : '' 
                })
                self.totalNum = result.total;
                TableCom.init(self, 'balanceTable', self.ttEquipmentData, 'id', self.pageSize, 'checkboxes');
            }
        })
    }

    self.changePage = function ({pageNum, pageSize}) {
        self.pageNum = pageNum
        self.pageSize = pageSize
        initTabData();
    };
    self.lineDetailIn = (balance) => {
        const { domainName, domainUid, projectName, projectUid, id, uid ,vipAddress, netAddress} = balance
        $location.url(`/cvm/enhance?id=${id}&domainName=${domainName}&domainUid=${domainUid}&projectName=${projectName}&projectUid=${projectUid}&uid=${uid}&vipAddress=${vipAddress}&netAddress=${netAddress}`)
    }

    self.lineDetailBack = () => {
        $location.url(`/cvm/enhance`)
    }
    
    //导出
    self.exportResource = function(){
        var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/elbLoadbalancer/export?regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}&pattern=${self.snapshot_search.globalSearchTerm}&project_id=${localStorage.projectUid}`
        utilTool.downloadFile(url);
    }

    /**
     * 转换默认部门默认项目
     */
    self.changeValue = function (value,department){
        if(value == 'default' && department=="admin"){
            return $translate.instant('aws.common.defaultDepar')
        }
        else if(value == "admin" && department == "default"){
            return $translate.instant('aws.common.defaultProject')
        }
        return value
    }

    
    //详情页面
    $scope.$on("getDetail", function (event, value) {
        if ($routeParams.from && $routeParams.from == "edit") {
            self.lbBasicInfoShow = false;
            self.lbListerInfoShow = true;
            self.lbMonitorEchartShow = false;
            self.detailData.id = value;
            self.canClick = false;
        } else {
            self.lbBasicInfoShow = true;
            self.lbListerInfoShow = false;
            self.lbMonitorEchartShow = false;
        }
        loadBalanceSrv.getBalanceDetail(value).then(function (result) {
            if ($routeParams.id != value) {
                return;
            }
            self.detailData = result.data;

            const {uid} = $location.search()
            enhanceSrv.details(uid).then(res=>{
                if(res && res.data){
                    self.DataInfo = res.data
                    self.DataInfo.createTime = $filter("date")(self.DataInfo.createTime, "yyyy-MM-dd HH:mm:ss");  
                    self.DataInfo.provisioningStatus = self.DataInfo.provisioningStatus.toUpperCase()
                    self.canClick = self.DataInfo.provisioningStatus == "ACTIVE"
                }
            })
            
        });
    });

    self.lbBasicTabShow = function () {
        if (self.canClick) {
            self.lbBasicInfoShow = true;
            self.lbListerInfoShow = false;
            self.lbMonitorEchartShow = false;
        }
    }
    self.lbListerTabShow = function () {
        if (self.canClick) {
            self.lbBasicInfoShow = false;
            self.lbListerInfoShow = true;
            self.lbMonitorEchartShow = false;
        }
    }
    self.lbMonitorEchartTabShow = function () {
        if (self.canClick) {
            self.lbBasicInfoShow = false;
            self.lbListerInfoShow = false;
            self.lbMonitorEchartShow = true;
        }
    };

    //新建&编辑 负载均衡
    self.openBalanceModel = function (type) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/cvm/loadbalancersmodile/tmpl/add-loadbalancing.html",
            controller: "addLoadbalancingCtrl",
            resolve: {
                type: ()=> type,
                initBalanceTable: ()=> initTabData,
                checkedItems:()=> self.checkedItems
            }
        });
    }

    //删除操作
    self.deleteBalance = function (checkedItems) {
        var content = {
            target: "delBalance",
            msg: "<div>" + $translate.instant("aws.loadbalance.deleteTip") + "</div>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delBalance", function (e, data) {
        const balanceIds = [];
        for (const key in data) {
            balanceIds.push(self.ttEquipmentData.find(item=>item.id == key))
        }
        const str = balanceIds.map(item=>`ids=${item.uid}`).join('&')
        enhanceSrv.deleteLoadbalancing(str).then(res=>{
            initTabData();
        })
    });
    // 删除成功
    self.$on("enhanceDelete", function(e, data){
        initTabData();
    })

    //监控checkbox
    self.$watch(() => self.checkboxes.items, function (val, old) {
        var arr = [];
        self.checkedItems = [];
        for (var i in self.checkboxes.items) {
            arr.push(self.checkboxes.items[i])
        }
        if (val && arr.length >= 0) {
            for (var key in val) {
                if (val[key]) {
                    self.ttEquipmentData.forEach(item => {
                        if (item.id == key) {
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
        selectShowBtn()
    }, true);

    /**
     * 选择显示按钮
     */
    function selectShowBtn(){
        const length = self.checkedItems.length
        if (length != 0) {
            if(length == 1 && (self.checkedItems[0].provisioningStatus == "ACTIVE"||self.checkedItems[0].provisioningStatus == "ERROR")){
                self.editDisabled= false
                self.deleteDisabled= false
            } else {
                if(self.checkedItems.every(item => item.provisioningStatus == "ACTIVE" || item.provisioningStatus == "ERROR")){
                    self.editDisabled= true
                    self.deleteDisabled= false
                }else{
                    self.editDisabled= true
                    self.deleteDisabled= true
                }
            }
        } else {
            self.editDisabled= true
            self.deleteDisabled= true
        }
    }
}
export { enhanceCtrl }