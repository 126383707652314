 storageObjectTabDetail.$inject = [];
 function storageObjectTabDetail() {
    return {
        restrict:"E",
        replace:true,
        scope:{},
        templateUrl:"js/system/storageManagement/tmpl/storage-object-tab-detail.html",
        link: function(self, elem, attrs, $ngModel) {
            self.tab = {
                active:0
            }
            self.tabOpen = function(cur){
                self.tab.active = cur;
            }
        }
    };
};
export {storageObjectTabDetail}