export function enhanceSrv($http){
    return {
        enhanceList(params){
            return $http({
                method: "get",
                url: "/awstack-manage/v2/elbLoadbalancer",
                params
            })
        },
        details(uid){
            return $http({
                method: "get",
                url: `/awstack-manage/v2/elbLoadbalancer/${uid}`,
            })
        },
        addLoadbalancing(data, headers){
            return $http({
                method:"post",
                url:"/awstack-resource/v1/elbServer",
                data,
                headers
            })
        },
        deleteLoadbalancing(params){
            return $http({
                method:"delete",
                url:"/awstack-resource/v1/elbServer/os-force-delete?"+params,
            })
        },
        //云主机申请 规格
        getFlavors: function(params) {
            return $http({
                method: "GET",
                url:"awstack-resource/v1/flavors",
                params
            });
        },
        //资源申请 路由器申请获取路由器公网列表
        getExtNets:function(){
            return $http({
                method:"GET",
                url:"/awstack-resource/v1/extnets"
            });
        },
        //交换机
        getNetworksData: function(params) {
            return $http({
                method: "GET",
                url: `/awstack-manage/v2/manage/listNetworks`,
                params
            });
        },
        // 编辑
        updateEnhance: function(serverId, data, headers){
            return $http({
                method:"post",
                url: `/awstack-resource/v1/elbServer/resize/${serverId}`,
                data,
                headers
            })
        },
        // 计费
        billing(data){
            return $http({
                method:"post",
                url: `/awstack-boss/newResourceCharge/queryElbChargingAmount`,
                data
            })
        },
        // 获取所有IP
        getNetworksDetailNew: function(options,headers) {
            return $http({
                method: "GET",
                url: "/awstack-resource/v1/networks/all/" + options,
                headers
            });
        },
    }
}
enhanceSrv.$inject = ["$http"];
