
storageManagementCtrl.$inject = ["$scope", "$rootScope", "$location", "$routeParams",  "$filter", "$uibModal", "$translate","NgTableParams", "checkedSrv", "alertSrv", "storageManagementSrv", "GLOBAL_CONFIG"]
function storageManagementCtrl($scope, $rootScope, $location, $routeParams, $filter,$uibModal, $translate, NgTableParams, checkedSrv, alert, storageManagementSrv, GLOBAL_CONFIG) {
    var self = $scope;
    self.canCreate = false;
    self.canEdit = false;
    self.canDel = false;
    self.showLoading = false;
    self.storageNameList = [];
    self.storageInstalled = [];
    self.backupCount = 0;
    self.openStorageModel = openStorageModel;
    self.deleteStorage = deleteStorage;
    self.refreshStorageTable = function() {
        self.globalSearchTerm = "";
        initTabData();
    }
    self.manuName = {
        ruijie: $translate.instant("aws.system.storagement.storageEnumeration.ruijie"),
        toyou: $translate.instant("aws.system.storagement.storageEnumeration.toyou"),
        nfs: $translate.instant("aws.system.storagement.storageEnumeration.nfs"),
        iscsi: $translate.instant("aws.system.storagement.storageEnumeration.iscsi"),
        fc: $translate.instant("aws.system.storagement.storageEnumeration.fc"),
        ceph: $translate.instant("aws.system.storagement.storageEnumeration.ceph"),
        out_ceph: $translate.instant("aws.system.storagement.storageEnumeration.out_ceph"),
        default: $translate.instant("aws.system.storagement.storageEnumeration.default"),
        file_CSP: $translate.instant("aws.system.storagement.storageEnumeration.file_CSP"),
        object_CSP: $translate.instant("aws.system.storagement.storageEnumeration.object_CSP"),
        object_swift: $translate.instant("aws.system.storagement.storageEnumeration.object_swift"),
        object_ceph_inner: $translate.instant("aws.system.storagement.storageEnumeration.object_ceph_inner"),
        local_disk: $translate.instant("aws.system.storagement.storageEnumeration.local_disk")
    }

    //设置项的初始化
    self.titleName="system_storagement";
    if(sessionStorage["system_storagement"]){
       self.titleData = JSON.parse(sessionStorage["system_storagement"]);
    }else {
       self.titleData=[
          {name:'system.storagement.name',value:true,disable:true,search:"name"},
          {name:'system.storagement.use',value:true,disable:false,search:"use_ori"},
          {name:'system.storagement.deviceType',value:true,disable:false,search:"type"},
          {name:'system.storagement.allotCapacity',value:true,disable:false,search:"allocatedCapacity"},
          {name:'system.storagement.usedCapacity',value:true,disable:false,search:"usedCapacity"},
          {name:'system.storagement.totalCapacity',value:true,disable:false,search:"totalCapacity"},
          {name:'system.storagement.status',value:true,disable:false,search:"state_ori"},
          {name:'system.storagement.createTime',value:true,disable:false,search:"createTime"},
          {name:'system.storagement.description',value:true,disable:false,search:"description"},
          //{name:'system.storagement.action',value:true,disable:true,search:"action"}
       ];
    }
    self.storageSearchTerm = function(obj){
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function(item){
            item.searchTerm = "";
            titleData.forEach(function(showTitle){
                if(showTitle.value){
                    item.searchTerm += item[showTitle.search] + "\b";
                }
            });
        });
    };

    initTabData();

    // 初始化列表数据
    function initTabData() {
        self.canCreate = false;
        storageManagementSrv.getStorageTableData().then(function(result) {
            result.data?self.loadData = true:"";
            if(result && result.data) {
                self.backupCount = 0;
                self.storageNameList = [];
                self.storageCephCount = 0;
                self.storage_data = result.data;
                self.storageInstalled = [];
                self.storage_data.map(item => {
                    if(item.use == "0") {
                        localStorage.cinderService = "cinder";
                        self.services.cinder = "cinder";
                        if(item.storageFirm == "ceph"){
                            localStorage.cephService = "ceph";
                            self.services.ceph = "ceph";
                        }
                        self.storageNameList.push(item.name);
                        if(item.storageFirm == "ceph" || item.storageFirm == "out_ceph") {
                            self.storageCephCount += 1;
                        }
                    }else if(item.use == "1") {
                        localStorage.backupsService = "backups";
                        self.services.backups = "backups";
                        self.backupCount += 1;
                    }else if(item.use == "4") {
                        localStorage.localDiskService = "localDisk";
                        self.services.localDisk = "localDisk";
                    }
                    
                    item.allocatedCapacity = item.allocatedCapacity || 0;
                    if(item.use != 0){
                        self.storageInstalled.push(item.use);
                    }
                    if(item.state) {
                        item.state_ori = $translate.instant('aws.system.storagement.table.state.'+ item.state);
                    }
                    //设备类型
                    if(item.type) {
                        if(item.type == "UDS-Stor 3000G2-24R2") {
                            item.type = self.manuName["ruijie"] + "（" + item.type + "）";
                        }else{
                            if(self.manuName[item.storageFirm]){
                                item.type = self.manuName[item.storageFirm] + "（" + item.type + "）";
                            }
                        }
                    }else {
                        item.type = self.manuName[item.storageFirm];
                    }
                    item.use_ori = $translate.instant("aws.system.storagement.storageTranslate.poolStatusMap."+item.use);
                    item.createTime = $filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
                    // item.searchTerm = [item.name, item.use, item.type, item.allocatedCapacity, item.totalCapacity, item.createTime, item.description].join("\b");
                });
                successFunc('tableParams');
            }           
        });
    }

    function successFunc(tableType) {
        self.canCreate = true;
        //根据session初始化searchterm
        self.storageSearchTerm({tableData: self.storage_data, titleData:self.titleData});
        self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: self.storage_data });
        self.applyGlobalSearch = function() {
            var term = self.globalSearchTerm;
            self.tableParams.filter({ searchTerm: term });
        };
        var tableId = "id";
        checkedSrv.checkDo(self, self.storage_data, tableId, tableType);
    }

    function showLoading(tip, action) {
        self.showLoading = true;
        self.loadingMsg = tip;
        self.action = action;
    }

    function hideLoading() {
        self.showLoading = false;
    }

    self.$on("addStorageSuccess", function(e, data) {
        initTabData();
        hideLoading();
        alert.set("", $translate.instant("aws.system.storagement.storageTranslate.storageSuccessEnd"), "success", 5000);
        // if(self.action == "delete") {
        //     alert.set("", $translate.instant("aws.system.storagement.storageTranslate.deleteSuccess"), "success", 5000);
        // }else {
        //     alert.set("", $translate.instant("aws.system.storagement.storageTranslate.addSuccess"), "success", 5000);
        // }
        self.$apply();
    });

    self.$on("addStorageFailed", function(e, data) {
        hideLoading();
        alert.set("", $translate.instant("aws.system.storagement.storageTranslate.storageFailEnd"), "error", 5000);
        // if(self.action == "delete") {
        //     alert.set("", $translate.instant("aws.system.storagement.storageTranslate.deleteFail"), "error", 5000);
        // }else {
        //     alert.set("", $translate.instant("aws.system.storagement.storageTranslate.addFail"), "error", 5000);
        // }
        self.$apply();
    })

    self.$watch(function() {
        return self.checkedItems;
    }, function(values) {
        if(!values){return}
        self.edit_tip = "";
        self.del_tip = "";
        self.canEdit = false;
        self.canDel = false;
        if(values.length == 1) {
            // status为true时, 表示正在使用中,不可编辑,不可删除
            if(values[0].status){
                self.canEdit = false;
                self.canDel = false;
                self.edit_tip =  $translate.instant("aws.system.storagement.storageTranslate.usingLimitEdit");
                self.del_tip = $translate.instant("aws.system.storagement.storageTranslate.usingLimitDelete");
            }else{
                self.canEdit = true;
                self.canDel = true;
            }
            if(values[0].use==0||values[0].use==1){
                if(values[0].migrateGlanceStatus == "1") {
                    self.canDel = false;
                    self.del_tip =$translate.instant("aws.system.storagement.storageTranslate.imageLimitDelete");
                }else if(values[0].migrateGlanceStatus == "2") {
                    self.canDel = false;
                    self.del_tip =$translate.instant("aws.system.storagement.storageTranslate.transferImage");
                }else if(values[0].migrateGlanceStatus == "3") {
                    self.canDel = false;
                    self.del_tip =$translate.instant("aws.system.storagement.storageTranslate.transferFailed");
                }
                if(values[0].storageFirm == "default") {
                    self.canDel = false;
                    self.del_tip = $translate.instant("aws.system.storagement.storageTranslate.customDelete");
                }
            }
        }
    });

    //创建和编辑弹出框，editData是当前被选中的值
    function openStorageModel(type) {
        var editData = angular.copy(self.editData);
        if(type == "new") {
            $uibModal.open({
                animation: true,
                templateUrl: "js/system/storageManagement/tmpl/add-storage-model.html",
                controller: "storageController",
                resolve: {
                    type: function() {
                        return type;
                    },
                    editData: function() {
                        return editData;
                    },
                    storageNameList: function() {
                        return self.storageNameList;
                    },
                    backupCount: function() {
                        return self.backupCount;
                    },
                    storageCephCount: function() {
                        return self.storageCephCount;
                    },
                    initStorageTable: function() {
                        return initTabData;
                    },
                    showLoading: function() {
                        return showLoading;
                    },
                    hideLoading: function() {
                        return hideLoading;
                    },
                    storageInstalled:function(){
                        return self.storageInstalled
                    }
                }
            });
        }else if(type == "edit") {
            if(!self.canEdit) {
                return;
            }
            $uibModal.open({
                animation: true,
                templateUrl: "updateStorageModel.html",
                controller: "editStorageCtrl",
                resolve: {
                    editData: function() {
                        return editData;
                    },
                    initStorageTable: function() {
                        return initTabData;
                    }
                }
            });
        }
    }

    //删除操作
    function deleteStorage(checkedItems) {
        if(!self.canDel) {
            return;
        }
        var content = {
            target: "delStorage",
            msg: "<span>" + $translate.instant("aws.system.storagement.deleteTip") + "</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delStorage", function(e, data) {
        var params = {
            id: data[0].id,
            storageUse: data[0].use,
            storageFirm: data[0].storageFirm,
            volumeBackendName: data[0].volumeBackendName,
            regionKey: localStorage.regionKey
        }
        var loginData = angular.fromJson(localStorage.$LOGINDATA);
        var regionUid = loginData.regionUid;
        var enterpriseUid = localStorage.enterpriseUid;
        storageManagementSrv.deleteStorageData(enterpriseUid, regionUid, params).then(function(result) {
            if(result && result.status == "0") {
                //备份存储，块存储，对象存储-超融合ceph 删除操作是异步的，根据websocket判断是否刷新列表
                if((data[0].use=="0"||data[0].use=="1")||(data[0].use=="2"&&data[0].storageFirm=="object_ceph_inner")){
                    //var tip = "删除存储需要重启存储服务，会影响云平台存储相关的操作，但不影响已有云主机正常对外提供业务，请耐心等待。。。";
                    var tip = $translate.instant("aws.system.storagement.delstorageMessage");
                    showLoading(tip, "delete");
                }
                else if(data[0].use=="3"){
                    self.refreshStorageTable();
                    
                    // 修改文件存储路由
                    const menuList = localStorage.getItem('menuList')
                    const menuListObj = JSON.parse(menuList)

                    // 跳出最外层
                    for (let index = 0; index < menuListObj.length; index++) {
                        const item = menuListObj[index];
                        if(item.keywords == 'Resource'){
                            for (let i = 0; i < item.child.length; i++) {
                                const child_item = item.child[i]
                                if(child_item.keywords == 'Resource_Storage'){
                                    for (let j = 0; j < child_item.child.length; j++) {
                                        const child_item_last = child_item.child[j]
                                        if(child_item_last.keywords == 'Resource_Storage_CSP'){
                                            child_item_last.active= "shares";
                                            child_item_last.href= "/cvm/shares";
                                            child_item_last.keywords= "Resource_Storage_Shares";
                                            break 
                                        }
                                    }
                                    break
                                }
                            }
                            break
                        }
                    }

                    localStorage.setItem('menuList', JSON.stringify(menuListObj))
                }else {
                    self.refreshStorageTable();
                }
            }
        });
    });

    /*验证开启监控*/
    self.openCephMonitor = function(m){
        self.currentCeph = m;
        self.checkRes = 'init';
        self.cephMonitorUrl = m.url;
        self.submitValid = false;
        self.cephMonitor = $uibModal.open({
            animation: true,
            templateUrl: "storageMonitorModel.html",
            scope:self
        })
    }

    self.checkCSH = function(m,v){
        self.submitValid = false;
        if(m.$valid){
            storageManagementSrv.checkCephUrl({ip:v}).then(res=>{
                if(res.data=='1'){
                    self.checkRes = 'fail';
                }else if(res.data=='0'){
                    self.checkRes = 'success';
                }
            })
        }else{
            self.submitValid = true;
        }
    }

    self.deleteMonitor = function(){
        var content = {
            target: "deleteMonitor",
            msg: "<span>你确定要删除url，关闭监控？</span>",
        };
        self.$emit("delete", content);
        self.$on("deleteMonitor",function(){
            var params = {
                "id":self.currentCeph.id,
                "urlStatus":1,
                "url":""
            }
            storageManagementSrv.editOutCephUrl(params).then(res=>{
                self.refreshStorageTable();
            })
            self.cephMonitor.close();
        })
    }

    self.storageMonitorConfirm = function(m,url){
        self.submitValid = false;
        if(m.$valid){
            var params = {
                "id":self.currentCeph.id,
                "urlStatus":0,
                "url":url
            }
            storageManagementSrv.editOutCephUrl(params).then(res=>{
                self.refreshStorageTable();
            })
            self.cephMonitor.close();
        }else{
            self.submitValid = true;
        }
    }
    /*结束*/

    //详情页面
    $scope.$on("getDetail", function(event, value) {
        self.storageDetailType = $routeParams;
        self.detailData = {};
        self.detailData.storageFlag = 3;
        self.storageDetailType = $routeParams;
        switch(self.storageDetailType.storageFirm){
            case "ceph":
            case "out_ceph":
                self.detailData.storageFlag = 1;
                break;
            case "object_ceph_inner":
            case "object_CSP":
                self.detailData.storageFlag = 2;
                break;
            case "local_disk":
                self.detailData.storageFlag = 4;
                break;
            default :
                self.detailData.storageFlag = 3;
                break;
        }
        if(self.detailData.storageFlag != 3){
            return;
        }
        storageManagementSrv.getStorageDetail(value).then(function(result) {
            if($routeParams.id != value) {
                return;
            }
            if(result && result.data) {
                if(result.data.use == "0" || result.data.use == "2") {
                    if(result.data.type) {
                        if(result.data.type == "UDS-Stor 3000G2-24R2") {
                            result.data.type = self.manuName["ruijie"] + "（" + result.data.type + "）";
                        }else {
                            result.data.type = self.manuName[result.data.storageFirm] + "（" + result.data.type + "）";
                        }
                    }else {
                        result.data.type = self.manuName[result.data.storageFirm];
                    }
                }
                // if(result.data.allocatedCapacity != null && !isNaN(result.data.allocatedCapacity)) {
                //     result.data.allocatedCapacity = result.data.allocatedCapacity.toFixed(2);
                // }
                // if(!isNaN(result.data.totalCapacity)) {
                //     result.data.totalCapacity = result.data.totalCapacity.toFixed(2);
                //     if(!isNaN(result.data.freeCapacity)) {
                //         //result.data.usedCapacity = (result.data.totalCapacity - result.data.freeCapacity).toFixed(2);
                //         result.data.usedCapacity = result.data.assignCapacity;
                //     }
                // }
                self.detailData = {
                    name: result.data.name,
                    use: $translate.instant("aws.system.storagement.storageTranslate.poolStatusMap."+result.data.use),
                    device: result.data.storageFirm =='file_CSP'?$translate.instant("aws.system.storagement.storageEnumeration.file_CSP"):result.data.storageFirm,
                    description: result.data.description,
                    assignCapacity: result.data.assignCapacity || "",
                    allocatedCapacity: result.data.allocatedCapacity,
                    totalCapacity: result.data.totalCapacity,
                    maxUsedCapacity: result.data.maxUsedCapacity,
                    Addr: result.data.diskPath || "",
                    // nfsAddr: result.data.nfsIp || "",
                    // nfsPath: result.data.dir || "",
                    createTime: result.data.createTime
                }
            }
        });
    });

    self.closeDetailModal=function(){
        $location.url('/system/storageManagement');
        initTabData();
        self.detailData = {};
    };

    //集群状态不允许时http.js反过来得信息
    self.$on("clustereError",function(e,data){
        alert.set("", $translate.instant("aws.statusCode."+data), "error", 5000);
    });

    // 本地磁盘对接状态
    self.$on("localDiskDetail", function(e,data) {
        if(data == 'create.local.disk.success') {
            alert.set("", $translate.instant("aws.localDisk.localDiskJoinSuccess"), "success", 5000);
        }else if(data.indexOf('create.local.disk.fail') > -1) {
            alert.set("", $translate.instant("aws.localDisk.localDiskJoinFail"), "error", 5000);
        }else if(data == 'create.local.disk.') {
            alert.set("", $translate.instant("aws.localDisk.localDiskJoinInitIng"), "building", 5000);
        }
    })
};
export {storageManagementCtrl}

