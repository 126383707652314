balanceSuccessCtrl.$inject = ["$scope", "$location", "$uibModalInstance", "editData", "initBalanceTable"]

function balanceSuccessCtrl($scope, $location, $uibModalInstance, editData, initBalanceTable) { 
    var self = $scope;

    self.addConfigNow = function() {
        $uibModalInstance.close();
        var path = "#/cvm/loadbalancing?id=" + editData.id + "&from=edit";
        window.location.href = path;
    }

    self.addConfigLater = function() {
        $uibModalInstance.close();
    }
}
export { balanceSuccessCtrl }