
import "./snapshotsSrv";
import "./volumesSrv";
import "./regularSnapSrv";
import "../instances/instancesSrv";
import "../../system/storage/storageSrv";
import './sharesSrv';
var sharesModule = angular.module("sharesModule", ["snapshotssrv", "regularSnapSrvModule", "volumessrv", "instancesModule", "sharesSrv"])
sharesModule.controller("sharesCtrl", ["$scope", "$rootScope", "NgTableParams", "$uibModal", "$translate", "volumesDataSrv",
  "instancesSrv", "regularSnapSrv", "GLOBAL_CONFIG", "checkedSrv", "$routeParams", "TableCom", "newCheckedSrv", "$location", "sharesSrv", "utilTool",
  function ($scope, $rootScope, NgTableParams, $uibModal, $translate, volumesDataSrv, instancesSrv, regularSnapSrv, GLOBAL_CONFIG,
    checkedSrv, $routeParams, TableCom, newCheckedSrv, $location, sharesSrv, utilTool) {
    var self = $scope;
    var filterName = $routeParams.resid;
    self.headers = {
      "name": $translate.instant("aws.shares.name"),
      "size": $translate.instant("aws.shares.size"),
      "status_c": $translate.instant("aws.shares.status"),
      "is_public": $translate.instant("aws.shares.isPublic"),
      "shareProto": $translate.instant("aws.shares.shareProto"),
      "createDate": $translate.instant("aws.shares.createDate"),

    }



    var initTableData = function () {
      sharesSrv.getSharesList().then(function (result) {
        if (result) {
          var fsdata = result.data;
          _.forEach(fsdata, function (item) {

            if (item.isPublic == 1) {
              item.is_public = $translate.instant("aws.shares.public");
            } else {
              item.is_public = $translate.instant("aws.shares.private");
            };

            if (item.statusStr) {
              item.status_c = $translate.instant("aws.shares.status_c." + item.statusStr);
            }
          })
          successFunc(fsdata);
        }
      });
    }
    function successFunc(data) {

      self.clusterTableData = data;
      self.clusterListData = data;
      self.applyGlobalSearch = function () {
        var term = self.globalSearchTerm;
        self.centertableParams.filter({ searchTerm: term });
      };
      self.clusterSearchTerm(({ tableData: self.clusterListData, titleData: self.clusterTitleData }));
      self.centertableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: data });
      var tableId = "shareId";
      newCheckedSrv.checkDo(self, data, tableId, "centertableParams");
      self.$watch(function () {
        return self.checkedItemscentertableParams;
      }, function (value) {
        var checkedItems = [];
        self.canupdate = false;
        self.canruleManager = false;
        self.canDel = false;
        if (value.length == 1) {
          self.canupdate = true;
          self.canruleManager = true;
          self.canDel = true;
          self.checkedItems = [value[0]];
        } else if (value.length > 1) {
          self.canupdate = false;
          self.canruleManager = false;
          self.canDel = true;
        }
      });
    }

    self.clusterSearchTerm = function (obj) {
      var tableData = obj.tableData;
      var titleData = obj.titleData;
      tableData.map(function (item) {
        item.searchTerm = "";
        titleData.forEach(function (showTitle) {
          if (showTitle.value) {
            item.searchTerm += item[showTitle.search] + "\b";
          }
        });
      });
    };

 

    initTableData();
    self.refreshFS = function () {
      self.globalSearchTerm = "";
      initTableData();
    };
    //新建文件存储
    $scope.createFS = function () {
      $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'createModel.html',
        controller: 'CreateSharesModelCtrl',
        backdrop: 'static',
        scope: self,
      });
    };
    self.clusterTitleName = "dataCluster";
    if (sessionStorage["dataCluster"]) {
      self.clusterTitleData = JSON.parse(sessionStorage["dataCluster"]);
    } else {
      self.clusterTitleData = [
        { name: 'shares.name', value: true, disable: true, search: 'name' },
        { name: 'shares.size', value: true, disable: false, search: 'size' },
        { name: 'shares.status', value: true, disable: false, search: 'status_c' },
        { name: 'shares.isPublic', value: true, disable: false, search: 'is_public' },
        { name: 'shares.shareProto', value: true, disable: false, search: 'shareProto' },
        { name: 'shares.createDate', value: true, disable: false, search: 'createDate' }
      ];
    }
    if ($location.search().openModal) {
      self.createFS();
    }

    //删除按钮操作
    $scope.delete = function () {
      var content = {
        target: "delFS",
        msg: "<span>" + "确定要删除选中的文件系统吗?" + "</span></p>"
      };
      self.$emit("delete", content);
    };

    //删除文件存储
    self.$on("delFS", function () {
      for (var i = self.checkedItemscentertableParams.length - 1; i >= 0; i--) {
        sharesSrv.delete(self.checkedItemscentertableParams[i].shareId).then(function (res) {
          if (res.status == 0) {
            $rootScope.$broadcast("alert-success", "删除文件存储成功");
            self.refreshFS();
          }
        });
      }
    });

    self.detailInfo = {};
    self.showBaseMsgOfDetail =  true;

    //基本信息点击事件
    self.activeBaseTab=function(){
        self.showBaseMsgOfDetail=true; 
        self.showRuleOfDetail=false;
    };
   
    ///规则信息点击事件
    self.activeRuleTab = function(){
      self.showRuleOfDetail=true;
      self.showBaseMsgOfDetail=false;
    };

    ///处理规则消息
    function successRuleFunc(data) {
      TableCom.init(self, 'tableFSParams', self.node_data, 'id', 10, 'storagePoolCheckbox');
      self.watched = '';
      function watchCheck(self) {
        var watch = self.$watch(function () {
          return self.storagePoolCheckbox.items;
        }, function (val) {
          self.checkedItems = [];
          var arr = [];
          self.canCreate = false;
          for (var i in self.storagePoolCheckbox.items) {
            arr.push(self.storagePoolCheckbox.items[i]);
          }
          if (val && arr.length >= 0) {
            for (var key in val) {
              if (val[key]) {
                data.forEach(item => {
                  if (item.id == key) {
                    self.checkedItems.push(item);
                  }

                });
              }
            }
          }
          if (self.checkedItems.length > 0 ) {
            self.canCreate = true;
          }
        }, true);
        return watch;
      }
      if (self.watched) {
        self.watched();
      }
      self.watched = watchCheck(self);
    }

    //获取规则列表
    function getFSRulesList (shareid) {
        sharesSrv.shareRuleList(shareid).then(function (result) {
          if (result && result.data) {
            result.data.forEach(item => {
              if (item.access_level =='ro')
              {
                item.access_level="只读";
              } 
              else if (item.access_level =='rw')
              {
                item.access_level="读写";
              }
              if (item.state) {
                item.state_c = $translate.instant("aws.shares.ruleStatus." + item.state);
              }
            });
           self.node_data = result.data;
           successRuleFunc (result.data);
          //  successFunc(result.data);
        };
      });
    };

    //获取详情信息
    function getFSInfo (shareid) {
      sharesSrv.getSharesInfo(shareid).then(function (result) {
        if (result && result.data) {
          self.detailInfo.name = result.data.name;
          self.detailInfo.shareId = result.data.shareId;
          self.detailInfo.statusStr = result.data.statusStr;
          self.detailInfo.exportLocation = result.data.exportLocation;
          if (result.data.isPublic == 1) {
            self.detailInfo.public = $translate.instant("aws.shares.public");
          } else {
            self.detailInfo.public = $translate.instant("aws.shares.private");
          };

          if (result.data.statusStr) {
            self.detailInfo.status_c = $translate.instant("aws.shares.status_c." + result.data.statusStr);
          }
          self.detailInfo.size = result.data.size;
          self.detailInfo.OperatingSystem = result.data.shareProto;
          self.detailInfo.shareType = result.data.shareType;
          self.detailInfo.createDate = result.data.createDate;
          self.detailInfo.host = result.data.host;
          self.detailInfo.description = result.data.description;
          self.detailInfo.metadata = result.data.metadata;
        }
      })
    };

    self.shareid = '';
    ///详情信息消息
    $scope.$on('getDetail', function (event, value) {
      self.shareid = value;
      self.showBaseMsgOfDetail=true; 
      self.showRuleOfDetail=false;
      getFSInfo (self.shareid);
      getFSRulesList (self.shareid);

    });

    //修改
    $scope.updateShare = function () {
      var scope = self.$new();
      var wbListIpsModal = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'updateShare.html',
        backdrop: 'static',
        scope: scope,
      });
      scope.ContainerFormUpdate = {};
      var data = self.checkedItemscentertableParams[0];
      scope.ContainerFormUpdate.name = data.name;
      scope.ContainerFormUpdate.description = data.description;
      if (data.isPublic == 1) {
        scope.ContainerFormUpdate.isPublic = true;
      } else {
        scope.ContainerFormUpdate.isPublic = false;
      }
      scope.updateShares = function () {
        var description = "";
        if (scope.ContainerFormUpdate.description != undefined) {
          description = scope.ContainerFormUpdate.description
        }
        var isPublic;
        if (scope.ContainerFormUpdate.isPublic == false) {
          isPublic = 0;
        } else {
          isPublic = 1;
        }
        var param = {
          description: description,
          name: scope.ContainerFormUpdate.name,
          isPublic: isPublic,
          shareId: data.shareId
        }
        sharesSrv.updateShares(param).then(function (result) {
          if (result) {
            if (result.status == "0") {
              $rootScope.$broadcast("alert-success", "操作成功");
            } else {
              $rootScope.$broadcast("alert-error", "操作失败");
            }
          } else {
            $rootScope.$broadcast("alert-error", "操作失败");
          }
          self.refreshFS();
        });

        wbListIpsModal.close();
      }
      scope.cancel = function () {
        wbListIpsModal.close();
      };
    };

    self.ruleSubmitInValid = false;
    self.interactedField = function(field) {
      if (field) {
        return self.ruleSubmitInValid || field.$dirty;
      }
    }

    //刷新规则列表
    self.refreshRule = function () {
      getFSRulesList(self.shareid);
    };

    //删除规则
    self.deleteShareRule= function () {
      var content = {
        target: "delFSRule",
        msg: "<span>" + "确定要删除选中的规则吗?" + "</span></p>"
      };
      self.$emit("delete", content);
    };
    self.$on("delFSRule", function () {
      for (var i = self.checkedItems.length - 1; i >= 0; i--) {
        sharesSrv.deleteRule(self.checkedItems[i].id, self.shareid).then(function (res) {
          if (res.status == 0) {
            $rootScope.$broadcast("alert-success", "删除规则成功");

          } else {
            $rootScope.$broadcast("alert-error", "删除规则失败");
          }
          getFSRulesList(self.shareid);
        });
      }
    });
    
    //增加规则
    self.addShareRule = function () {
      var scope = self.$new();
      var addShareRuleModal = $uibModal.open({
        animation: true,
        templateUrl: "addShareRule.html",
        backdrop: 'static',
        scope: scope,
      });
      scope.fsRuleForm = {};
      scope.accessType = [
        { id: "ip", name: "ip" },
        { id: "user", name: "user" },
        { id: "cert", name: "cert" }
      ]
      scope.accessLevel = [
        { id: "ro", name: "read-only" },
        { id: "rw", name: "read-write" }
      ]
      scope.fsIPRule = {
        "ip_1":"",
        "ip_2":"",
        "ip_3":"",
        "ip_4":""
      };

      scope.fsCidrRule = {
        "ip_1":"",
        "ip_2":"",
        "ip_3":"",
        "ip_4":"",
        "ip_5":""
      };
      

      ///规则校验
      scope.fsGroupRule = {
          way:"ip"
      };
      scope.isIPRule = true;
      scope.isCidrRule= false;
      scope.$watch(function() {
        return scope.fsGroupRule.way;
      }, function(rule) {
        if (rule == "ip") {
          scope.isIPRule = true;
          scope.isCidrRule= false;
        }
        if (rule == "cidr") {
          scope.isIPRule = false;
          scope.isCidrRule= true;
        }
       });
 

      //。
      scope.fsRuleForm.accessType = scope.accessType[0];
      scope.fsRuleForm.accessLevel = scope.accessLevel[0];

      scope.addSharesRules = function () {
        var rule ="";
        if (scope.fsGroupRule.way == "ip"){
          rule= scope.fsIPRule.ip_1 + "." + scope.fsIPRule.ip_2 +"." + scope.fsIPRule.ip_3 + "." + scope.fsIPRule.ip_4;
        }
        else {
          rule= scope.fsCidrRule.ip_1 + "." + scope.fsCidrRule.ip_2 +"." + scope.fsCidrRule.ip_3 + "." + scope.fsCidrRule.ip_4 + "/" + scope.fsCidrRule.ip_5;
        }

        var param = {
          accessType: scope.fsRuleForm.accessType.id,
          accessLevel: scope.fsRuleForm.accessLevel.id,
          accessTo: rule, // scope.fsRuleForm.accessTo,
          shareId: self.shareid
        }
        sharesSrv.addShareRules(param).then(function (result) {
          addShareRuleModal.close();
          getFSRulesList(self.shareid);
        })

      }
      scope.cancel = function () {
        addShareRuleModal.close();
      };

    }

    //详情事件
    self.linkTo = function (item) {
      utilTool.setLocalStorage([item]);
      $location.url('/cvm/shares?id=' + item.shareId)
    }
    self.linkClose = function () {
      self.checkboxes.items = {};
      $location.url('/cvm/shares');
    }

  }])
sharesModule.controller('CreateSharesModelCtrl', function ($scope, $rootScope, $translate, $uibModalInstance, sharesSrv) {
  self = $scope;
  self.ContainerForm = {};
  $scope.flag = true;
  $scope.showMetadataError = false;
  $scope.shareProto = [
    { id: "NFS", name: "NFS" },
    { id: "CIFS", name: "CIFS" }
    // { id: "GlusterFS", name: "GlusterFS" },
    // { id: "HDFS", name: "HDFS" }
  ]
  $scope.ContainerForm.shareProto = $scope.shareProto[0];
  //查询类型
  $scope.shareType = [];
  $scope.shareAvailable = [];
  function shareList() {
    sharesSrv.getSharesTypeList().then(function (result) {
      if (result) {
        result.data.forEach((x) => {
          var param = {
            id: x.id,
            name: x.name
          }
          $scope.shareType.push(param);
        })
        self.ContainerForm.shareType = result.data[0];
      }
    })
    // sharesSrv.getZone().then(function (result) {
    //   if (result) {
    //     result.data.forEach((x) => {
    //       if (x.zoneName != "internal") {
    //         var param = {
    //           id: x.zoneName,
    //           name: x.zoneName
    //         }
    //         $scope.shareAvailable.push(param);
    //       }
    //     })
    //   }
    // })
  }
  shareList();
  self.ContainerForm.isPublic = false;
  $scope.confirm = function () {
    if ($scope.flag == true) {
      $scope.flag = false;
      var description;
      if (self.ContainerForm.description != undefined) {
        description = self.ContainerForm.description
      }
      var metadata;
      if (self.ContainerForm.metadata != undefined) {
        // 判断对象value值不为中文
        let status =  Object.values(self.ContainerForm.metadata).some(function(item){
            return  /^[\u4e00-\u9fa5]+$/.test(item)
        })
        if(status){
            self.showMetadataError = true
            $scope.flag = true
            return
        }else{
          self.showMetadataError = false
          metadata = self.ContainerForm.metadata
        }
      }
      var shareAvailable;
      if (self.ContainerForm.shareAvailable != undefined) {
        shareAvailable = self.ContainerForm.shareAvailable.id
      }
      var shareProto;
      if (self.ContainerForm.shareProto != undefined) {
        shareProto = self.ContainerForm.shareProto.id
      }
      var shareType;
      if (self.ContainerForm.shareType != undefined) {
        shareType = self.ContainerForm.shareType.id
      }
      var isPublic;
      if (self.ContainerForm.isPublic == false) {
        isPublic = 0;
      } else {
        isPublic = 1;
      }
      var param = {
        description: description,
        metadata: metadata,
        name: self.ContainerForm.name,
        shareAvailable: shareAvailable,
        shareProto: shareProto,
        shareType: shareType,
        size: self.ContainerForm.size,
        isPublic: isPublic
      }
      sharesSrv.createShares(param).then(function (result) {
        if (result) {
          if (result.status == 0 ) {
            $rootScope.$broadcast("alert-success", "操作成功");
          } else {
            $rootScope.$broadcast("alert-error", "操作失败");
          }
        } else {
          $rootScope.$broadcast("alert-error", "操作失败");
        }

       // $uibModalInstance.close();
        self.refreshFS();
      });
      $uibModalInstance.close();
    }
  };
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
})
