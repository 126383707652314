import "./memcachedSrv";
import { AreaPanelDefault } from "../../chartpanel";
import { memcachedAreaChartDefault } from "../../chartpanel";

var memcachedModule = angular.module("memcachedModule", ["ngTable","ngAnimate", "ui.bootstrap","ngSanitize","memcachedSrv","usersrv"]);
memcachedModule.controller("memcachedCtrl",["$scope", "$rootScope","NgTableParams","$translate","memcachedSrv" ,"userDataSrv","commonFuncSrv",
function($scope, $rootScope,NgTableParams,$translate,memcachedSrv,userDataSrv,commonFuncSrv) {
    var self = $scope;
    self.filterData = {
        timeStep: '30m',
        queryType: '30m',
        from: null,
        to: null
    };

    function handleNodeName(data,nodePrettyNameList){
       data.forEach(function(item){
            item.prettyName=nodePrettyNameList[item.hostName];
       }); 
       self.rabbitmqBasicInfo.nodeName = data[0] && data[0].hostName;
       self.rabbitmqBasicInfo.prettyName = data[0] && data[0].prettyName;
       self.rabbitmqBasicInfo.keyTotal = data[0] && data[0].curr_items;
    }

    function memcachedBasicInfoFunc(regionKey){
        self.rabbitmqBasicInfo = {};
        memcachedSrv.getMemcachedBasicInfo(regionKey).then(function(res){
            if(res && res.data){
                commonFuncSrv.showPrettyNameOfNode(res.data,handleNodeName);
            }
        });
    }
    
    function panelsDataFunc(item,key,color){
        self.panelsData[key] = (self.panelsData[key]).slice((self.panelsData[key]).length,0);
        var areaChart = new AreaPanelDefault();
        areaChart.panels.title = $translate.instant("aws.monitor."+item.chartPerm.title);
        areaChart.panels.unit = item.chartPerm.unit;
        areaChart.panels.priority = item.chartPerm.priority;
        if(color%2 == 0){
            areaChart.panels.colors = ["#51a3ff"];
        }else{
             areaChart.panels.colors = ["#1bbc9d"];
        }
        let params = {
            "resource": 'memcached',
            "chart": item.chartPerm.title,
            "queryType": self.filterData.queryType,
            "startTimeMillis": self.filterData.from ? moment(self.filterData.from).valueOf() : null,
            "endTimeMillis": self.filterData.to ? moment(self.filterData.to).valueOf() : null
        };
        memcachedSrv.getStatistics(params).then(function(res){
            if(res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                areaChart.panels.data.push(res.data.results[0].series[0]);
            }else{
                var defaultChartData = {
                    "columns":["time",item.chartPerm.title],
                    "values":[
                        [moment().subtract(30,"m"),0],
                        [moment(),0]
                    ],
                    "default":true
                };
                areaChart.panels.data.push(defaultChartData);
            }
            self.panelsData[key].push(areaChart.panels);
            self.panels = self.panelsData;
        })
    }

    function memcachedChart(){
        self.panelsData = {};
        var areaChartPermas = new memcachedAreaChartDefault(); 
        var count = 0;
        for(let key in areaChartPermas.chartSqls){
            self.panelsData[key] = [];
            count++;
            _.each(areaChartPermas.chartSqls[key],function(item){
                panelsDataFunc(item,key,count);
            }) 
        }
    }

    userDataSrv.getRegionData().then(function(res) {
        if (res && res.data && res.data[0] && res.data[0].regionKey) { 
            res.data = res.data.filter(item => {
                return item.status == 3;
            });
            self.region_key = res.data[0].regionKey;
            _.each(res.data, item => {
                if (item.regionKey == localStorage.regionKey) {
                    self.region_key = item.regionKey;
                }
            });
            memcachedBasicInfoFunc(self.region_key);
        }    
    });
    memcachedChart();

    self.query = function() {
        memcachedChart();
    };
   
}]);

