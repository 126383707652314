import * as controllers from "./controllers";
import * as services from "./services";

let secrecyRoleModule = angular.module("secrecyRoleModule", ["rzModule"]);

secrecyRoleModule.config(["$controllerProvider", function($controllerProvider){
	$controllerProvider.register(controllers);
}]);

secrecyRoleModule.config(["$provide", function($provide){
	$provide.service(services);
}]);