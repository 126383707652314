enhanceConfigCtrl.$inject = ["$scope", 'enhanceconfigSrv',"$q"]

function enhanceConfigCtrl($scope, enhanceconfigSrv, $q) {
    const self = $scope;
    self.selectArr = []                     // 下拉框数据
    self.refresh = true                     // 刷新
    self.openObj = {
        isInitiated: true,
        isOpenSelect: true
    }

    self.changeValue = function (value) {
        const {imageId} = self.openObj.paramValue
        // 判断下拉框是否改变，是否禁用保存按钮
        self.openObj.selectIsChange = value != imageId
        if(self.openObj.selectIsChange) {
            self.openObj.isInitiated= false
        } else {
            self.openObj.isInitiated= true
        }
        self.selectValue = value
    }

    // 确认关闭
    self.query = function () {
        self.save("false")
    }

    const params = {
        parentId: 1128, 
        regionUid: localStorage.regionUid
    }
    const p1 = enhanceconfigSrv.getImage                // 获取镜像列表
    const p2 = enhanceconfigSrv.getLoginConfig          // 获取开关配置

    allRequest()
    function allRequest(){
        self.refresh = false
        $q.all([p1(), p2(params)]).then(res => {
            imageFn(res[0])
            nowState(res[1].data[0])
        })
    }
    
    // 获取镜像列表
    self.gitImageList= function(){
        p1().then(res=>{
            imageFn(res)
        })
    }

    function imageFn(res){
        if(res&&res.data){
            self.selectArr = res.data.map(item => {
                return {
                    id: item.imageUid,
                    name: item.name
                }
            })
            self.selectValue = self.selectArr.length != 0 && self.selectArr[0].id
        }
    }

    // 调用开关接口
    function getConfig(){
        self.refresh = false
        p2(params).then(res=>{
            if(res&&res.data){
                nowState(res.data[0])
            }
        })
    }

    /**
     * 修改当前开关状态
     * @param {*} data 
     */
    function nowState(data){
        if(data){
            // 重新填充当前开关状态
            const { paramId, paramName, paramValue } = data
            const pv = JSON.parse(paramValue)
            self.openObj.paramId = paramId
            self.openObj.paramName = paramName
            self.openObj.paramValue = pv
            self.openObj.isOpen = JSON.parse(pv.switch)
            const type = JSON.parse(pv.switch)
            // 修改镜像下拉框默认值
            if(type){
                self.selectValue = pv.imageId
            } else {
                self.selectValue = ''
            }
            self.openObj.isInitiated = true                     // 保存后按钮禁用
            self.openObj.selectIsChange = false                 // 保存后下拉框状态初始化
            self.openObj.isOpenSelect = !type                   // 保存后根据开关状态是否禁用
        }
        self.refresh = true
    }

    /**
     * 保存负载均衡开关状态
     */
    self.save = function () {
        const {paramId, paramName, paramValue, isOpen} = self.openObj
        paramValue.switch = isOpen
        // 开启时更新镜像ID，关闭时不更新镜像ID
        paramValue.imageId = isOpen ? self.selectValue: paramValue.imageId
        const config = {
            enterpriseUid: localStorage.enterpriseUid,
            regionUid: localStorage.regionUid,
            paramId,
            paramName,
            paramValue: JSON.stringify(paramValue),
            parentId: 1128
        }
        enhanceconfigSrv.getPropAgreement(config).then(res => {
            getConfig()
            reloadMenu()
        })
    }

    /**
     * 重新加载菜单
     */
    function reloadMenu(){
        enhanceconfigSrv.reloadMenu().then(res=>{
            const { menuList } = res.data
            localStorage.setItem("menuList", JSON.stringify(menuList))
        })
    }
}

export { enhanceConfigCtrl }