//import { $ } from "protractor";

angular.module("tsafeModule", [])
    .controller("tsafeCtrl", ["$scope","tsafeSrv","$route", function(scope,tsafeSrv,$route) {
      var self = scope;
      console.log($route.current.active);
      let param = {
        pluginName:($route.current.active).toUpperCase()
      }
      tsafeSrv.getSafeUrl(param).then(res=>{
          if(res&&res.data){
              $("#tsafe").attr('src',res.data);
              self.url = res.data;
          }
      })

    }])
    .service("tsafeSrv",["$http",function($http){
        return {
            getSafeUrl:function(param){
                return $http({
                    method: "GET",
                    url: "/awstack-user/v1/plugin/cas",
                    params:param
                });
            }
        }
    }])
    