import './loadbalancingcertificateModuleSrv'
angular.module("loadbalancingcertificateModule", ['loadbalancingcertificateModuleSrv']).controller('loadbalancingcertificateCtrl', ["$scope", "$rootScope", "NgTableParams","modalCom","TableCom","$translate",'loadbalancingcertificateModuleSrv',
    function ($scope, $rootScope,NgTableParams,modalCom,TableCom,$translate, loadbalancingcertificateModuleSrv) {
        const self = $scope;
        self.pageSize = 10;
        self.pageNum = 1;
        self.totalCount = 0;
        self.globalSearchTerm = '';
        self.ttEquipmentData = [];
        // 按钮默认值
        self.relievedisabled = true;        // 解绑
        self.updatedisabled = true;         // 编辑
        self.deldisabled = true;            // 删除
        
        self.checkboxes = {
            checked: false,
            items: {},
            arr:[]
        };
        
        self.table = {
            name: $translate.instant('aws.common.name'),
            type: $translate.instant('aws.common.certificatetype'),
            describe: $translate.instant('aws.ports.description'),
            createTime: $translate.instant('aws.ports.createtime')
        }

        self.statusConfig = {
            statusSelected: ''
        }
        self.snapshot_search = {
            globalSearchTerm: ''
        }
        // 搜索框
        self.searchBackend = function (options) {
            if(options.type == 'refresh') {
                self.snapshot_search.globalSearchTerm = '';
                self.pageNum = 1;
            }
            initTable()
        }

        // 改变当前页
        self.changePage = function ({pageNum,pageSize}) {
            self.pageNum = pageNum;
            self.pageSize = pageSize;
            initTable()
        }

        /**
         * 初始化表格
         */
        function initTable() {
            const config = {
                pageSize: self.pageSize,
                pageNum: self.pageNum,
                pattern: self.snapshot_search.globalSearchTerm
            }
            loadbalancingcertificateModuleSrv.getCertificateList(config, true).then(res => {
                if(res.data) {
                    self.totalCount = res.total;
                    self.ttEquipmentData = res.data;
                    self.labelSearchTearm({ tableData: self.ttEquipmentData, titleData: self.titleData })
                    TableCom.init(self, 'loadbalancingTable', self.ttEquipmentData, 'id', self.pageSize, 'checkboxes');
                    // 关闭所有checkbox
                    self.checkboxes.items = {}
                }
            })
        }
        initTable();

        self.$watch(function () {
            return self.checkboxes.items;//监控checkbox
        }, function (val, old) {
            self.checkedItems = [];
            var arr = [];
            for (var i in self.checkboxes.items) {
                arr.push(self.checkboxes.items[i])
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.ttEquipmentData.forEach(item => {
                            if (item.id == key) {
                                self.checkedItems.push(item);
                            }
                        })
                    }
                }
            }
            const length = self.checkedItems.length
            if (length != 0) {
                if (length == 1) {
                    self.updatedisabled = false;
                } else {
                    self.updatedisabled = true;
                    self.deldisabled = false;
                }
                self.deldisabled = false;
            } else {
                self.updatedisabled = true;
                self.deldisabled = true;
            }
        }, true);

        // 编辑\新增
        self.operateLabel = function (value) {
            self.createItemModal = modalCom.init('certificateAlert.html', 'certificateAlertCtrl', {
                context: function () {
                    return self;
                },
                title: () => value,
                checkedItems: () => self.checkedItems,
                initTable: ()=>initTable
            })
        }
        // 删除
        self.deleteLabel = function () {
            var content = {
                target: "removeFunc",
                msg: "<span>" + $translate.instant("aws.errors.isDelete") + "</span>",
                data: self.checkedItems
            };
            self.$emit("delete", content);
        }

        self.$on("removeFunc", function (e, data) {
            const config = `containersUids=${data.map(item => item.containersUid).join(',')}`;
            loadbalancingcertificateModuleSrv.deleteCertificateList(config).then(res=>{
                initTable()
            })
        })

        self.titleName = "loadbalancingcertificateModule";
        if (sessionStorage["loadbalancingcertificateModule"]) {
            self.titleData = JSON.parse(sessionStorage["loadbalancingcertificateModule"]);
        } else {
            self.titleData = [
                { name: "common.name", value: true, disable: true, search: 'name' },
                { name: "common.certificatetype", value: true, disable: false, search: 'type' },
                { name: "ports.description", value: true, disable: false, search: 'describe' },
                { name: "ports.createtime", value: true, disable: false, search: 'createTime' }
            ];
        }

        self.labelSearchTearm = function (obj) {
            var tableData = obj.tableData;
            var titleData = obj.titleData;
            tableData.map(function (item) {
                item.searchTerm = [];
                titleData.forEach(function (showTitle) {
                    if (showTitle.value) {
                        item.searchTerm.push(item[showTitle.search]);
                    }
                });
                item.searchTerm = item.searchTerm.join("\b");
            });
        }

    }]).controller("certificateAlertCtrl", ["$scope", "$rootScope", '$uibModalInstance', 'context', 'title','loadbalancingcertificateModuleSrv','checkedItems','initTable','$translate', function ($scope, $rootScope, $uibModalInstance, context, title, loadbalancingcertificateModuleSrv,checkedItems,initTable,$translate) {
        let self = $scope;
        self.certificateTitle = title == 'create';
        self.title = self.certificateTitle ? $translate.instant('aws.action.create') : $translate.instant('aws.action.edit');
        self.certificate = {
            title:'',
            content:'',
            privatekey:'',
            describe:'',
            nowAttestationIndex: 1
        }

        if($rootScope.isOpenF5){
            self.certificate.nowAttestationIndex = 0
        }

        // 编辑回显
        if(!self.certificateTitle) {
            (async function(){
                const {type, name, description,containersUid} = checkedItems[0];
                const res = await loadbalancingcertificateModuleSrv.getVesselCertificate(containersUid)
                self.certificate.nowAttestationIndex = type;
                self.certificate.title = name;
                self.certificate.describe = description;
                self.certificate.content = res.data[0].payload;
                if(res.data.length > 1){
                    self.certificate.privatekey = res.data[1].privateKey;
                }
            })()
        }

        self.fillexample = function(value){
            if(!self.certificateTitle){
                return
            }
            const nowStatus = value == 'key';
            if(nowStatus){
                self.certificate.privatekey = `-----BEGIN RSA PRIVATE KEY-----
MIICXAIBAAKBgQDM9ou8PxR8jANOl3uLHi02mvicloeh7CFtGtFq982Kbk1FBJLI
4D0stqXyS2G0v3IC9u5sLEKaPLj948IsXlBP/euzzX4=
-----END RSA PRIVATE KEY-----`;
            } else {
                self.certificate.content = `-----BEGIN CERTIFICATE-----
MIIBozCCAQwCAQEwDQYJKoZIhvcNAQELBQAwGjEYMBYGA1UEAwwPY2EtaW50QGFj
kVcl9QRZFm7BaPjvtOJeV1C9HHIXLyS733bq70P3XhPLT9dTWv5m
-----END CERTIFICATE-----`;
            }
        }

        self.changeAttestation = function (value) { 
            if($rootScope.isOpenF5 && value == '1' && self.certificateTitle){
                return
            }
            if(!self.certificateTitle){
                return
            }
            self.certificate.content = '';
            self.certificate.privatekey = '';
            self.certificate.nowAttestationIndex = value;
        }
        self.isValidPass = false
        self.confirmCreate = function (form) {
            self.isValidPass = false
            if(form.$valid){
                const {title, content, privatekey, describe, nowAttestationIndex} = self.certificate;
                if(self.certificateTitle){
                    const config = {
                        "name": title,
                        "description": describe,
                        "payload": content,
                    }
                    if(nowAttestationIndex == 0){
                        config.privateKey = privatekey;
                    }
                    loadbalancingcertificateModuleSrv.addCertificate(config).then(res=>{
                        initTable()
                    })
                } else {
                    const { containersUid } = checkedItems[0];
                    const config = {
                        uid: containersUid,
                        name: title,
                        description: describe
                    }
                    loadbalancingcertificateModuleSrv.updateCertificateList(config).then(res=>{
                        initTable()
                    })
                }
                $uibModalInstance.close();
            } else {
                self.isValidPass = true
            }
        }
    }]).directive('certificateformat', function() {
        // 验证证书格式
        return {
            require: 'ngModel',
            link: function(scope,elm,attrs,ctrl) {
                ctrl.$parsers.push(function(value) {
                    if(!value){
                        ctrl.$setValidity('certificateformat', true);
                        return value
                    }
                    const type = attrs['certificateformat'];
                    const certificatelist = value.split('\n');
                    const config = [
                        { name:'CA', way:[{start:'-----BEGIN CERTIFICATE-----', end:'-----END CERTIFICATE-----'}]},
                        { name:'key', way:[{start:'-----BEGIN RSA PRIVATE KEY-----', end:'-----END RSA PRIVATE KEY-----'}]}
                    ]
                    const nowConfig = config.find(item=>item.name == type).way;
                    const filterWay = nowConfig.filter(item => item.start == certificatelist[0]);
                    if(filterWay.length > 0 && filterWay[0].end == certificatelist[certificatelist.length - 1]){
                        let islength = true;
                        certificatelist.pop();
                        certificatelist.shift();
                        for (let index = 0; index < certificatelist.length; index++) {
                            let item = certificatelist[index];
                            // 匹配是否存在空格或者长度大于64
                            if(/\s/g.test(item) || item.length > 64) {
                                islength = false;
                                break
                            }
                        }
                        if(islength){
                            ctrl.$setValidity('certificateformat', true);
                            return value
                        }
                    }
                    ctrl.$setValidity('certificateformat', false);
                    return undefined
                })
            }
        }
    })
    