secrecyRoleCtrl.$inject = ["$scope", "$rootScope", "$translate","$location","NgTableParams","secrecyRoleSrv"]
function secrecyRoleCtrl($scope, $rootScope, $translate,$location,NgTableParams,secrecyRoleSrv) {
    var self = $scope;
    self.info = {};
    
    // 顶部数据展示
    function getSystemInfo(){
        secrecyRoleSrv.getSystemInfos().then(function(res){
            if(res && res.data){
                self.info.domain = res.data.domain;
                self.info.project = res.data.project;
                self.info.user = res.data.user;
            }
        })
    }

   
    function fillTheBar(values){
        if(values.name.length <5){
            values.name.push("");
            values.value.push("");
            fillTheBar(values)
        }else if(values.name.length == 5){
            return values;
        }
        
    }
    //获取部门下的项目top5
    self.getProjectOfDomain = function(){
        secrecyRoleSrv.getUserOfDomain().then(function(res){
            setDomainValue(res,"#51a3ff","#4e80f5","project")
        })
    }
    //获取项目下的user top5
    self.getUserOfDomain = function(){
        secrecyRoleSrv.getProjectOfDomain().then(function(res){
            setDomainValue(res,"#24B9D9","#16a085","user")
        })
    }
    // 设置echarts
    function setDomainValue(res,color1,color2,type){
        if(type == "user"){
            self.chartdata1 ={
                name:[] ,
                value:[],
                color:[color1,color2]      
            }
        }else{
            self.chartdata ={
                name:[] ,
                value:[],
                color:[color1,color2]      
            }
        }
        if(res && res.data && angular.isArray(res.data)){
            res.data.map(item =>{
                item.name = item.name.toLowerCase() == "default"?$translate.instant("aws.common.defaultDepar"):item.name; 
                item.name = item.name.toLowerCase() == "admin"?$translate.instant("aws.common.defaultProject"):item.name; 
                if(type == 'user'){
                    self.chartdata1.name.push(item.name)
                    self.chartdata1.value.push(item.count)
                }else{
                    self.chartdata.name.push(item.name)
                    self.chartdata.value.push(item.count)
                }
               
            })
        }
        if(type == 'user'){
            fillTheBar(self.chartdata1)
        }else{
            fillTheBar(self.chartdata)
        }
       
    }

    function setResDomainQuota(tdata,item,resName){
        if(item.name == resName){
            item.total = item.hardLimit || 100;
            item.inUsed = item.useQuotas || 0;
            tdata[resName] = item;
        }
        return tdata;

    }
    getSystemInfo();
    self.getProjectOfDomain();
    self.getUserOfDomain();
}
export{secrecyRoleCtrl};