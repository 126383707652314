changePasswordCtrl.$inject = ['$scope','$uibModal','initConfig','serviceConfigSrv','$translate','$rootScope','$timeout','$http','$route']
function changePasswordCtrl($scope,$uibModal,initConfig,serviceConfigSrv,$translate,$rootScope,$timeout,$http,route) {

    var self = $scope;

    self.changePasswordDis = false

    self.changePasswordData = {
        originalPassword:"",
        newPassword:"",
        confirmPassword:""
    }
    //错误判断
    self.ifOriginalPassword =false //原密码不一致

    self.submitted = false;
    self.interacted = function(field) {
        if(field){
            self.field_form = field;
            return self.submitted || field.$dirty;
        }
    };
    self.changeInput = function(n){
        self.ifOriginalPassword =false
    }
    //修改密码
    self.changePasswordWay = function(n) {
        self.ifOriginalPassword =false
        // 参数校验
        if(n.$valid){
            if(n.originalPassword.$viewValue == n.newPassword.$viewValue || n.newPassword.$viewValue != n.confirmPassword.$viewValue){
                self.submitted = true;
            }else{
                self.changePasswordDis = true
                serviceConfigSrv.getConfigInfo().then(function (result) {
                    if(result && result.status == 0 && result.data!=null){
                        var postData = {
                            serviceConfigId:result.data.id,
                            enterpriseUid:result.data.enterpriseUid,
                            regionUid:result.data.regionUid,
                            abAddress:result.data.abAddress,
                            oldUserPass:n.originalPassword.$viewValue,
                            userPass:n.newPassword.$viewValue,
                            repeatUserPass:n.confirmPassword.$viewValue,
                        }
                        if(n.changePasswordType.$viewValue=="account"){
                            // 管理员密码
                            if(n.originalPassword.$viewValue == result.data.abSystemPassword){
                                postData.userName = result.data.abSystemUserName
                                postData.roleType = 0 //0:系统管理员 1：系统安全员
                                changePassword(postData)
                            }else{
                                //原密码不一致
                                self.changePasswordDis = false
                                self.ifOriginalPassword =true
                            }
                        }else if(n.changePasswordType.$viewValue=="security"){
                            // 安全员密码
                            if(n.originalPassword.$viewValue == result.data.abSecurityPassword){
                                postData.userName = result.data.abSecurityUserName
                                postData.roleType = 1 //0:系统管理员 1：系统安全员
                                changePassword(postData)
                            }else{
                                //原密码不一致
                                self.changePasswordDis = false
                                self.ifOriginalPassword =true
                            }
                        }
    
                    }else{
                        //原密码不一致
                        self.changePasswordDis = false
                        self.ifOriginalPassword =true
                    }
                })
            } 
        }else{
            self.submitted = true;
        }
    }

    function changePassword(param) {
        self.ifOriginalPassword =false
        serviceConfigSrv.changePasswordMethod(param).then(function (result) {
            if(result && result.status == 0){
                close()
                initConfig()
            }else{
                self.changePasswordDis = false
            }
        })
    }

    function close() {
        self.$dismiss()
    }
}
export{changePasswordCtrl};