
easySecurityGroupCtrl.$inject=["$scope", "$rootScope", "$translate","$uibModal","$timeout","$uibModalInstance","securityGroupSrv","self"];
export function easySecurityGroupCtrl($scope,$rootScope,$translate,$uibModal,$timeout,$uibModalInstance,securityGroupSrv,self){
    var scope = $scope;
    scope.securityGroupForm = {
        name: "",
        type: "0",
        description: ""
    };
    scope.interactedRouter = function(field) {
        if (field) {
            return scope.routerSubmitInValid || field.firewallName.$dirty;
        }
    };
    function stepsStatus(one, two, oneBar, twoBar) {
        scope.inStepOne = one;
        scope.inStepTwo = two;
        scope.inStepOneBar = oneBar;
        scope.inStepTwoBar = twoBar;
    }
    scope.stepToOne = function() {
        stepsStatus(true, false, true, false)
    }
    scope.stepToOne()
    scope.stepToTwo = function(createrouterForm) {
        if (createrouterForm.$valid) {
            stepsStatus(false, true, true, true)
            if(scope.securityGroupForm.type == '1'){
                scope.securityGroupRules = [{
                    name: "TCP",
                    value: "tcp"
                }, {
                    name: "UDP",
                    value: "udp"
                }];
                scope.isBlackList = true;
            }else {
                scope.securityGroupRules = [{
                    name: "TCP",
                    value: "tcp"
                }, {
                    name: "UDP",
                    value: "udp"
                }, {
                    name: "ICMP",
                    value: "icmp"
                }, {
                    name: "ALL ICMP",
                    value: "icmp"
                }, {
                    name: "其他协议",
                    value: "else"
                }];
                scope.isBlackList = false;
            }
            scope.securityGroupRule.selectedRule = scope.securityGroupRules[0];
        } else {
            scope.routerSubmitInValid = true;
        }
    }
   
    scope.submitted = false;
    scope.interactedPort = function(field) {
        scope.field_form = field;
        return scope.submitted || field.port.$dirty;
    };
    scope.interactedPortRange = function(field) {
        scope.field_form = field;
        return scope.submitted || field.fromPort.$dirty || field.toPort.$dirty;
    };
    scope.interactedProtocol = function(field) {
        scope.field_form = field;
        return scope.submitted || field.protocol.$dirty;
    };
    scope.securityGroupRule = {
        cidr: "0.0.0.0/0",
        way: "cidr"
    };
    scope.allIcmpShow = true;
    scope.types = ["0", "3", "4", "5", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "30"];

    scope.directions = [{
        name: $translate.instant("aws.security.ingress"),
        value: "ingress"
    }, {
        name: $translate.instant("aws.security.egress"),
        value: "egress"
    }];
    scope.securityGroupRule.direction = scope.directions[0];

    scope.portTypes = [{
        name: $translate.instant("aws.security.assignPort"),
        value: "port"
    }, {
        name: $translate.instant("aws.security.portRange"),
        value: "portRange"
    }];
    scope.securityGroupRule.portType = scope.portTypes[0];

    securityGroupSrv.newGetFirewallTableData({pageSize:9999,pageNum: 1}).then(function(result) {
        if (result && result.data) {
            scope.firewallGroups = result.data;
            scope.securityGroupRule.firewall = scope.firewallGroups[0];
        }
    });

    scope.changeRule = function(rule,form,active) {
        scope.securityGroupRule.port = '';
        if(active){
            scope.shortCutType = '';
        }
        if (form && form.fromPort && form.toPort) {
            form.fromPort.$dirty = false;
            form.toPort.$dirty = false;
        }
        if (rule.name == "ALL ICMP" && rule.value == "icmp") {
            scope.allIcmpShow = false;
        } else {
            scope.allIcmpShow = true;
        }
        if (rule.name == "ICMP" && rule.value == "icmp") {
            scope.isIcmp = true;
            scope.isPort = false;
            scope.isPortRange = false;
            scope.securityGroupRule.from_port = scope.types[0];

            scope.codings = ["0"];
            scope.securityGroupRule.to_port = scope.codings[0];
        } else {
            scope.isIcmp = false;
            scope.isPort = true;
            scope.securityGroupRule.portType = scope.portTypes[0];
        }
        if (rule.value == "else") {
            scope.elseProtocol = true;
            scope.allIcmpShow = false;
        } else {
            scope.elseProtocol = false;
        }
    };

    scope.changeType = function(t) {
        scope.codings = [];
        switch (t) {
            case "3":
                scope.codings = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];
                break;
            case "5":
                scope.codings = ["0", "1", "2", "3"];
                break;
            case "11":
                scope.codings = ["0", "1"];
                break;
            case "12":
                scope.codings = ["0", "1", "2"];
                break;
            default:
                scope.codings = ["0"];
                break;
        }
        scope.securityGroupRule.to_port = scope.codings[0];
    };

    // scope.isPort = true;
    scope.$watch(function() {
        return scope.securityGroupRule.portType;
    }, function(portType) {
        if (portType && portType.value == "port") {
            scope.isPort = true;
            scope.isPortRange = false;
        }
        if (portType && portType.value == "portRange") {
            scope.isPort = false;
            scope.isPortRange = true;
        }
    });

    // scope.isCidrWay = true;
    scope.$watch(function() {
        return scope.securityGroupRule.way;
    }, function(value) {
        switch (value) {
            case "cidr":
                scope.isCidrWay = true;
                scope.isFirewallWay = false;
                break;
            case "firewall":
                scope.isCidrWay = false;
                scope.isFirewallWay = true;
                break;
        }
    });

    scope.$watch(function() {
        return scope.securityGroupRule.port || scope.securityGroupRule.from_port;
    }, function(value) {
        if (scope.securityGroupRule.selectedRule && scope.securityGroupRule.selectedRule.value !== "icmp") {
            if (value && (value < 1 || value > 65535)) {
                scope.input_invalid = true;
                scope.field_form.$valid = false;
            } else {
                scope.input_invalid = false;
            }
            if (!value && scope.field_form && scope.field_form.$dirty) {
                scope.input_invalid = false;
            }
        }
    });
    scope.$watch(function() {
        return scope.securityGroupRule.to_port;
    }, function(value) {
        if (scope.securityGroupRule.selectedRule && scope.securityGroupRule.selectedRule.value !== "icmp") {
            if (scope.securityGroupRule.from_port) {
                if (value && (value < Number(scope.securityGroupRule.from_port) || value > 65535)) {
                    scope.toPort_invalid = true;
                    scope.field_form.$valid = false;
                } else {
                    scope.toPort_invalid = false;
                }
                if (!value && scope.field_form && scope.field_form.$dirty) {
                    scope.toPort_invalid = false;
                }
            }
        }
    });

    /*快捷方式*/
    scope.shortCutFun = function(type) {
        scope.shortCutType = type;
        switch (scope.shortCutType) {
            case "SSH":
                scope.securityGroupRule.selectedRule = {
                    name: "TCP",
                    value: "tcp"
                }
                scope.changeRule(scope.securityGroupRule.selectedRule)
                scope.securityGroupRule.port = 22;
                break;
            case "RDP":
                scope.securityGroupRule.selectedRule = {
                    name: "TCP",
                    value: "tcp"
                }
                scope.changeRule(scope.securityGroupRule.selectedRule)
                scope.securityGroupRule.port = 3389;
                break;
            case "HTTPS":
                scope.securityGroupRule.selectedRule = {
                    name: "TCP",
                    value: "tcp"
                }
                scope.changeRule(scope.securityGroupRule.selectedRule)
                scope.securityGroupRule.port = 443;
                break;
            case "HTTP":
                scope.securityGroupRule.selectedRule = {
                    name: "TCP",
                    value: "tcp"
                }
                scope.changeRule(scope.securityGroupRule.selectedRule)
                scope.securityGroupRule.port = 80;
                break;
            case "FTP":
                scope.securityGroupRule.selectedRule = {
                    name: "TCP",
                    value: "tcp"
                }
                scope.changeRule(scope.securityGroupRule.selectedRule)
                scope.securityGroupRule.port = 21;
                break;
            default:
                scope.securityGroupRule.port = '';
                break;
        }
    }
    scope.tableRules = [];
    scope.addSecurityGroupRule = function(data,form) {
        console.log(data,'data')
        if (form && form.$valid) {
            var params = {
                //"groupId": $routeParams.id,
                "direction": data.direction.value, //入口或者出口 Ingress 或 egress
                "ethertype": "IPV4", //类型 默认传iPv4?
                "protocol": data.selectedRule.value //规则 null, tcp, udp, and icmp
            };

            if (data.selectedRule.name == "ALL ICMP") {
                params.portRangeMin = null;
                params.portRangeMax = null;
            } else {
                if (scope.isPort) {
                    params.portRangeMin = data.port;
                    params.portRangeMax = data.port;
                } else {
                    params.portRangeMin = data.from_port;
                    params.portRangeMax = data.to_port;
                }
            }
            if (data.way == "cidr") {
                params.remoteIpPrefix = data.cidr; //IP地址
            } else {
                params.remoteGroupId = data.firewall.uid;
            }

            if (data.selectedRule.value == "else") {
                params.protocol = data.protocol;
                params.portRangeMin = null;
                params.portRangeMax = null;
            }
            if (params.remoteGroupId) {
                console.log(scope.securityGroupRule.firewall,'scope.securityGroupRule.firewall')
                params.sourceType = scope.securityGroupRule.firewall.name;
            } else {
                params.sourceType = params.remoteIpPrefix;
            }
            var tableListNum = scope.tableRules.length;
            /*验证是否超过项目配额*/
            var canAdd = true;
            scope.canAddTips = false;
            function addRules() {
                if(tableListNum>5){
                    canAdd = false;
                    scope.canAddTips = true;
                    return;
                }
                if (tableListNum > 0) {
                    for (var i = 0; i < tableListNum; i++) {
                        if(!scope.isPortRange){
                            params.portRangeMin = scope.securityGroupRule.port
                            params.portRangeMax = scope.securityGroupRule.port;
                            if (scope.tableRules[i].protocol == params.protocol &&
                                scope.tableRules[i].portRangeMin == params.portRangeMin &&
                                scope.tableRules[i].portRangeMax == params.portRangeMax &&
                                scope.tableRules[i].direction == params.direction &&
                                scope.tableRules[i].sourceType == params.sourceType
                            ){
                                canAdd = false;
                            }
                        }else{
                            params.portRangeMin = scope.securityGroupRule.from_port;
                            params.portRangeMax = scope.securityGroupRule.to_port;
                            if (scope.tableRules[i].protocol == params.protocol &&
                                scope.tableRules[i].portRangeMin == params.portRangeMin &&
                                scope.tableRules[i].portRangeMax == params.portRangeMax&&
                                scope.tableRules[i].direction == params.direction &&
                                scope.tableRules[i].sourceType == params.sourceType
                            ) {
                                canAdd = false;
                            }
                        }
                    }
                }
            }
            addRules()
            if (canAdd) {
                scope.tableRules.push(params)
            }
        } else {
            scope.submitted = true;
        }
    };
    scope.deleteRulesFun = function(i) {
        scope.canAddTips = false;
        scope.tableRules.splice(i, 1)
    }
    scope.securityGroupConfirm = function() {
        let params = {
            name: scope.securityGroupForm.name,
            type: scope.securityGroupForm.type,
            description: scope.securityGroupForm.description,
            rules: scope.tableRules
        };
        securityGroupSrv.createSecurityGroups(params).then(function(res){
            if (res && res.data) {
                res.data.value = res.data.name;
                self.securityList.push(res.data);
            }
        }).finally(function() {
            $uibModalInstance.close();
        })
    }
    
}