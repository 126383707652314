import { AreaPanelDefault } from "../../../chartpanel";

auditRoleCtrl.$inject = ["$scope", "$rootScope", "$translate","$location","NgTableParams","auditRoleSrv","$http"]
function auditRoleCtrl($scope, $rootScope, $translate,$location,NgTableParams,auditRoleSrv,$http) {
    var self = $scope;
    self.infoData1 = [],
    self.infoData2 = [],
    self.userOperateData = [],
    self.indicator = [];
    self.logData = [];
    self.sourceOperateData = [];
    // 块状图颜色
    self.color = ['#3B78E7','#1ABC9C','#F39C12','#E74C3C','#B0B0B0'];
    var option = {
        tooltip : {
            trigger: 'item',
            formatter: "{b} : {c} ({d}%)"
        },
        series : [
            {
                type: 'pie',
                radius : '50%',
                center: ['50%', '50%'],
                data:self.userOperateData,
                minAngle: 10,           　　 //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
                avoidLabelOverlap: true,   //是否启用防止标签重叠策略
                hoverAnimation:false,　　
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    normal:{
                        label:{
                        }, 
                        labelLine: {
                            show: true,
                            length:0.001
                        }
                    },
                },
                label:{
                    normal:{
                        formatter(v) {
                            let text = v.name
                            return text.length < 6 
                                ? text 
                                : `${text.slice(0,6)}\n${text.slice(6)}`
                        }
                    }  
                }
            }
        ],
        color: self.color
    };
    var option1 = {
        tooltip : {
        },
        grid:{
            x:60,
            y:45,
            x2:5,
            y2:20,
            borderWidth:1
        },
        calculable : true,
        polar: [
            {
                nameGap : 5, // 图中工艺等字距离图的距离
                center:['50%','60%'], // 图的位置
                name:{
                   show: true, // 是否显示工艺等文字
                   formatter: null, // 工艺等文字的显示形式
                   textStyle: {
                     color:'#3697CB' // 工艺等文字颜色
                   },
                   formatter: (text) => {
                    text = text.replace(/\S{2}/g, function(match) {
                        return match + '\n'
                    })
                    return text
                    },
                 },
                indicator :self.indicator,
                axisLine: {            // 坐标轴线
                    show: true        // 默认显示，属性show控制显示与否
                },
                axisLabel: {           // 坐标轴文本标签，详见axis.axisLabel
                    show: false,
                    textStyle: {      
                        color: '#3697CB' // 坐标轴刻度文字的样式
                    }
                },
            }
        ],
        series : [
            {
                name:"资源操作日志TOP5",
                type: 'radar',
                itemStyle: {
                    normal: {
                        color : "#3697CB",
                        areaStyle: {
                            type: 'default',
                            color:"#8BCFF2"
                        },
                        lineStyle: {
                            color:"#3697CB" // 图表中各个图区域的边框线颜色
                        },
                    }
                },
                data : [
                    {
                        value : self.sourceOperateData
                    }
                ]
            }
        ]
    };
    var option2 = {
        tooltip : {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        grid: {
            top: '16%',   // 等价于 y: '16%'
            left: '5%', 
            right: '5%',
            bottom: '5%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['00:00','01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00','15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00','22:00','23:00']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: self.logData,
            type: 'line',
            center:['30%','50%'], // 图的位置
            itemStyle: {
                normal: {
                    color : "#3697CB",
                    lineStyle: {
                        color:"#3697CB" // 图表中各个图区域的边框线颜色
                    },
                }
            },
        }]
    };
    
    self.$watch(function() {
        return self.userOperateData;
    }, function(panel) {
        if(panel&&panel.length){
            const myUserChart = echarts.init(document.getElementById('pie-user'));
            myUserChart.setOption(option);
        }
        
    }, 5000);
    self.$watch(function() {
        return self.logData;
    }, function(panel) {
        if(panel&&panel.length){
            const myLogChart = echarts.init(document.getElementById('line-log'));
            myLogChart.setOption(option2);
        }
    }, 5000);
    self.$watch(function() {
        return self.sourceOperateData;
    }, function(panel) {
        if(panel&&panel.length){
            const myResourceChart = echarts.init(document.getElementById('radar-resource'));
            myResourceChart.setOption(option1);
        }
    }, 5000);
    window.onresize = function () {
        initChars();
    };
    self.$on('navToggleNew',function(){
        initChars();
    })
    function initChars(){
        const myResourceChart = echarts.init(document.getElementById('radar-resource'));
        const myLogChart = echarts.init(document.getElementById('line-log'));
        const myUserChart = echarts.init(document.getElementById('pie-user'));
        myResourceChart.resize("auto", "auto");
        myLogChart.resize("auto", "auto")
        myUserChart.resize("auto", "auto")
    }
     self.setUserPie = function(){
        // 调取api
        auditRoleSrv.getProjectOfTop().then(function(res){
            if(res&&res.data){
                if(res.data.operationLogs.length == 0){
                    self.userOperateData = [];
                    self.infoData1 = [];
                    self.noUser = true;
                }else{
                    self.noUser = false;
                    res.data.operationLogs.forEach(function(item){
                        self.userOperateData.push({"value":item.counts,"name":item.userName});
                        self.infoData1.push({"value":item.counts,"name":item.userName});
                        // 返回数据添加颜色字段
                        self.infoData1.forEach(function(item,index){
                            item.color = self.color[index]
                        });
                    })
                }
                var countsStatus = true;
                res.data.resourceLogs.forEach(function(item){
                    if(item.counts > 0){
                        countsStatus = false;
                    }
                    self.sourceOperateData.push(item.counts);
                    self.indicator.push({"text":item.logType,"max":item.counts+500});
                    self.infoData2.push({"value":item.counts,"name":item.logType});
                })
                self.noResource = countsStatus;
            };
        })
        
    }
    self.setUserPie();
     self.setlogLine = function(data){
        var myDate = new Date(); 
        var thisDate = myDate.getFullYear()+"-"+(myDate.getMonth()+1)+"-"+myDate.getDate();
        var data = {
            time:thisDate
        }
        // 调取api
        auditRoleSrv.getLogs(data).then(function(res){
            if(res&&res.data){
                res.data.forEach(function(item){
                    self.logData.push(item.counts);
                })
            }
        })
       
    }
    self.setlogLine();


    // 日志数量
    self.filterData = {
        from: null,
        to: null,
        definedTimeText:$translate.instant('aws.bill.chooseDate')
    };
    // 设置时间选择按钮
    self.timeType = {
        options: [{
            "name": $translate.instant('aws.role.overview.today'),
            "value": "1d"
        }, {
            "name": $translate.instant('aws.role.overview.yestoday'),
            "value": "2d"
        }]
    };
    // 查询数据
    self.resourceQuery = function() {
        /**
         * 今天： self.filterData.from = self.filterData.to
         * 昨天： self.filterData.to 多一天 上边设置值为2d;
         * 所以传值直接取self.filterData.from
         */
        var data={
            time:moment(self.filterData.from).format("YYYY-MM-DD")
        }
        // 调取api
        auditRoleSrv.getLogs(data).then(function(res){
            let myLogChart = echarts.init(document.getElementById('line-log'));
            if(res&&res.data){
                self.logData1 = [];
                res.data.forEach(function(item){
                    self.logData1.push(item.counts);
                })
                var option3 = {
                    tooltip : {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: ['00:00','01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00','15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00','22:00','23:00']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: self.logData1,
                        type: 'line',
                        center:['30%','50%'], // 图的位置
                        itemStyle: {
                            normal: {
                                color : "#3697CB",
                                lineStyle: {
                                    color:"#3697CB" // 图表中各个图区域的边框线颜色
                                },
                            }
                        },
                    }]
                };
            }
            myLogChart.setOption(option3)
        })
    }
    
    
}
export{auditRoleCtrl};