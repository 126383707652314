import * as controllers from "./controllers";
import * as services from "./services";

let backupStrategyModule = angular.module("backupStrategyModule", ["rzModule"]);

backupStrategyModule.config(["$controllerProvider", function($controllerProvider){
	$controllerProvider.register(controllers);
}]);

backupStrategyModule.config(["$provide", function($provide){
	$provide.service(services);
}]);