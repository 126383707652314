var labelService = angular.module("labelMSrv", []);
labelService.service("labelMSrv", function($rootScope, $http, $location) {
    return {
        // 新建标签
        addLabel:function(data){
            return $http({
                method: "post", 
                url: "/awstack-manage/v1/label",
                data,
                
            });
        },
        // 获取列表
        getList: function(parmas, defaultToLoadList) {
            const url = `/awstack-manage/v1/label?pattern=${parmas.patten}&pageNum=${parmas.pageNum}&pageSize=${parmas.pageSize}`
            // 选择全部时加载用户默认部门项目的标签
            let headers = {};
            if(localStorage.getItem('managementRole')=='2'&&localStorage.getItem('selectedAllDomain')==1 && defaultToLoadList){
                const search = $location.search()
                const keywords = ['snapshot','volume','image','rbackup'];
                if(search && keywords.indexOf(search.from)>-1){
                    headers = {
                        project_id: localStorage.getItem('projectUid'),
                        project_name: encodeURI(localStorage.getItem('projectName')),
                        domain_id: localStorage.getItem('domainUid'),
                        domain_name: encodeURI(localStorage.getItem('domainName'))
                    }
                } else {
                    headers = {
                        project_id: localStorage.getItem('defaultProjectUid'),
                        project_name: localStorage.getItem('defaultProjectName'),
                        domain_id: localStorage.getItem('defaultdomainUid'),
                        domain_name: localStorage.getItem('defaultdomainName')
                    }
                }
            }
            
            return $http({
                method: "get",
                url,
                headers
            });
        },
        // 编辑标签
        updateLabel:function(data){
            const url = `/awstack-manage/v1/label`
            return $http({
                method: "put",
                url,
                data
            });
        },
        // 删除标签
        deleteLabel:function(id){
            const url = `/awstack-manage/v1/label?labelIds=${id}`
            return $http({
                method: "delete",
                url,
                
            });
        },
        // 绑定标签
        bindLable:function(data) {
            return $http({
                method: "POST",
                url: '/awstack-manage/v1/label/bind',
                data,
            });
        },
        // 解除绑定
        relieveLable:function(data) {
            return $http({
                method: "POST",
                url: '/awstack-manage/v1/label/unbind',
                data,
            });
        },
        // 云主机标签修改
        instancesLabelUpdate:function(data) {
            return $http({
                method: "POST",
                url: '/awstack-manage/v1/label/edit',
                data,
            });
        },
    };
});
//export default labelService