var securityGroupModule = angular.module("securityGroupModule", ["securityGroupSrvModule"]);
securityGroupModule.controller("firewallCtrl", ["$scope", "$location","$timeout","NgTableParams", "securityGroupSrv", "checkedSrv", "newCheckedSrv","checkQuotaSrv", "$uibModal", "$translate", "GLOBAL_CONFIG", "$routeParams", "$filter","utilTool","alertSrv",
    function($scope,$location,$timeout, NgTableParams, securityGroupSrv, checkedSrv,newCheckedSrv, checkQuotaSrv, $uibModal, $translate, GLOBAL_CONFIG, $routeParams, filter,utilTool,alertSrv) {
        var self = $scope;
        self.choiceAll = localStorage.selectedAllDomain;
        self.templateTab = 'ingress';
        self.detailTab = 'ingress';

        self.headers = {
            "firewallName": $translate.instant("aws.security.securityGroupName"),
            "securityGroupType": $translate.instant("aws.security.securityGroupType"),
            "description": $translate.instant("aws.security.description"),
            "direction": $translate.instant("aws.security.direction"),
            "inputType": $translate.instant("aws.security.inputType"),
            "ip_protocol": $translate.instant("aws.security.ip_protocol"),
            "authorizationStrategy": $translate.instant("aws.security.authorizationStrategy"),
            "status": $translate.instant("aws.security.status"),
            "portRange": $translate.instant("aws.security.portRange"),
            "target": $translate.instant("aws.security.target"),
            "sourceType": $translate.instant("aws.security.sourceType"),
            "typeCodeValue": $translate.instant("aws.security.typeCodeValue")
        };

        var initSecurityGroupData = function(data) {
            self.securityGroupTable = new NgTableParams({
                page: 1,
                count: GLOBAL_CONFIG.PAGESIZE,
                sorting: {
                    firewall: "asc"
                }
            }, {
                counts: [],
                dataset: data
            });

            self.securityGroup_chkboxs = {
                checked: false,
                items: {}
            };

            self.$watch(function() {
                return self.securityGroupTable.page();
            }, function() {
                self.securityGroup_chkboxs = {
                    checked: false,
                    items: {}
                };
            });

            self.$watch(function() {
                return self.securityGroup_chkboxs.checked;
            }, function(value) {
                angular.forEach(self.securityGroupTable.data.filter(item => item.name != "default"&&item.name != 'default-all-open'&&item.name != 'default-all-close'), function(item) {
                    self.securityGroup_chkboxs.items[item.id] = value;
                });
            });

            self.$watch(function() {
                return self.securityGroup_chkboxs.items;
            }, function() {
                //if (self.securityGroupTable.data.length === 0) return;
                self.selectedItemsData = [];
                var checked = 0,
                    unchecked = 0,
                    total = self.securityGroupTable.data.length - 1;
                self.isPaasSecuritys = false;
                angular.forEach(self.securityGroupTable.data.filter(item => item.name != "default"&&item.name != 'default-all-open'&&item.name != 'default-all-close'), function(item) {
                    checked += (self.securityGroup_chkboxs.items[item.id]) || 0;
                    unchecked += (!self.securityGroup_chkboxs.items[item.id]) || 0;
                    if (self.securityGroup_chkboxs.items[item.id]) {
                        if(item.name=='PaaS security pair'){
                            self.isPaasSecuritys = true;
                        }
                        self.selectedItemsData.push(item);
                        self.editData = angular.copy(item);
                    }
                });

                // if ((unchecked == 0) || (checked == 0)) {
                //     if (total > 0) {
                //         self.securityGroup_chkboxs.checked = (checked == total);
                //     }
                // }
                self.canEdit = false;
                self.canDel = false;
                if (checked === 1) {
                    if (self.editData.name == "default"||self.editData.name == 'default-all-open'||self.editData.name == 'default-all-close') {
                        self.canEdit = false;
                        self.canDel = false;
                    } else {
                        self.canEdit = true;
                        self.canDel = true;
                    }
                } else if (checked > 1) {
                    self.canEdit = false;
                    self.canDel = true;
                } else {
                    self.canEdit = false;
                    self.canDel = false;
                }

                angular.element(".select-all-securityGroupTable").prop("indeterminate", (checked != 0 && unchecked != 0));
                if(checked>0&&unchecked==0){
                    self.securityGroup_chkboxs.checked = true;
                }else if(checked==0&&unchecked>0){
                    self.securityGroup_chkboxs.checked = false;
                }
            }, true);
            self.$watch(function(){
                return self.selectedItemsData
            },function(value,old){
                if(value&&old&&value.length!=old.length){
                    utilTool.setLocalStorage(value);
                }
            })
        };
        //详情事件
        self.linkTo = function(item){
            utilTool.setLocalStorage([item]);
            $location.url("/cvm/security_groups?id="+item.uid+"&securityType="+item.type+"&name="+item.name)
        }
        self.linkClose = function(){
            self.securityGroup_chkboxs.items = {};
            $location.url('/cvm/security_groups');
        }
        self.changePage = function(options){
            initSecurityGroupTable(options,'changePage');
        };
        //资源导出
        self.exportResource = function(){
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/security_groups?pageSize=&pageNum=&pattern=${self.globalSearchTerm||""}&regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}`
            utilTool.downloadFile(url);
        }
        //控制导出按钮是否显示，当选中全部部门时显示
        self.showExport = utilTool.isAllDomainSelect;
        
        //安全组列表
        var initSecurityGroupTable = function(options,changePage) {
            if(!changePage) self.currentPage = 1
            self.securityGroup_name = [];
            let pageDefaultOption = {
                pageSize:10,
                pageNum: 1,
                likes:self.globalSearchTerm,
            } 
            var pageOption = angular.extend(pageDefaultOption,options)
            securityGroupSrv.newGetFirewallTableData(pageOption).then(function(result){
                result ? self.loadData = true : "";
                if (result && result.data) {
                    self.securityGroupTableData = result.data;
                    self.securityGroupList = result.data
                    /*存下所有已有的安全组名*/
                    result.data.forEach(function(item) {
                        if(item.domainName=="default"){
                            item.domainName_TR = $translate.instant("aws.common.defaultDepar")
                        }else{
                            item.domainName_TR = item.domainName
                        }
                        if(item.projectName=="admin"){
                            item.projectName_TR = $translate.instant("aws.common.defaultProject")
                        }else{
                            item.projectName_TR = item.projectName
                        }
                        self.securityGroup_name.push(item.name)
                    })
                    initSecurityGroupData(result.data);
                    self.totalNum = result.total;
                }
            });
        };
        initSecurityGroupTable();

        self.refreshSecurityGroup = function() {
            self.globalSearchTerm = '';
            initSecurityGroupTable();
        };

        self.applyGlobalSearch = function(likes) {
            let searchOption = {
                likes:likes,
            }
            $timeout.cancel(self.sendSearch)
            self.sendSearch = $timeout(()=>{
                initSecurityGroupTable(searchOption)
            },1000)
        };

        //新建 、编辑 安全组
        self.updateSecurityGroup = function(type) {
            if(type=="new"){
                utilTool.newResSetLocalStorage(self,'securityGroup_chkboxs','items')
            }
            var scope = self.$new();
            var securityGroupModal = $uibModal.open({
                animation: true,
                templateUrl: "securityGroupModal.html",
                scope: scope
            });
            scope.securityGroupForm = {
                name: "",
                template: "",
                type: "0",
                description: ""
            };
            self.templateRuleTable = new NgTableParams({
                page: 1,
                count: GLOBAL_CONFIG.PAGESIZE
            }, {
                counts: [],
                dataset: []
            });
            scope.submitted = false;
            scope.interacted = function(field){
                scope.field_form = field;
                return scope.submitted || field.firewallName.$dirty || field.description.$dirty;
            };
            
            securityGroupSrv.getTemplateList().then(res => {
                if (res && res.data){
                    scope.templateList = res.data;
                    scope.templateList.push({id:null,name:$translate.instant("aws.security.null"),type:"0"});
                }
            })
            switch (type) {
                case "new":
                    checkQuotaSrv.checkQuota(scope, "security_group");
                    // if (localStorage.permission == "enterprise") {
                    //     checkQuotaSrv.checkQuota(scope, "security_group");
                    // }
                    scope.securityGroupModal_title = $translate.instant("aws.security.createSecurityGroup");
                    scope.editSecurityGroup = false;
                    scope.securityGroupConfirm = function(form) {
                        if (form.$valid){
                            let params = {
                                name: scope.securityGroupForm.name,
                                type: scope.securityGroupForm.type,
                                description: scope.securityGroupForm.description || '',
                                templateId: scope.securityGroupForm.template.id
                            };
                            securityGroupSrv.addFirewallAction(params).then(function() {
                                initSecurityGroupTable();
                            });
                            securityGroupModal.close();
                        } else {
                            scope.submitted = true;
                        }
                    };
                    break;

                case "edit":
                    /*编辑时过滤掉本身的名字*/
                    self.securityGroup_name = self.securityGroup_name.filter(item => {
                        return item != self.editData.name
                    })
                    scope.securityGroupModal_title = $translate.instant("aws.security.editSecurityGroup");
                    scope.editSecurityGroup = true;
                    scope.securityGroupForm = angular.copy(self.editData);
                    scope.securityGroupConfirm = function(form) {
                        if (form.$valid) {
                            let params = {
                                name: scope.securityGroupForm.name,
                                description: scope.securityGroupForm.description || '',
                                uid: self.editData.uid
                            };
                            securityGroupSrv.editFirewallAction(params).then(function() {
                                initSecurityGroupTable();
                            });
                            securityGroupModal.close();
                        } else {
                            scope.submitted = true;
                        }
                    };
                    break;
            }
            // 切换模板
            self.changeTemplate = function() {
                if(scope.securityGroupForm.template.id === null) { // 选择空模板
                    scope.securityGroupForm.type = scope.securityGroupForm.template.type;
                    scope.templateSelected = false;
                    self.templateRuleTable = new NgTableParams({
                        page: 1,
                        count: GLOBAL_CONFIG.PAGESIZE
                    }, {
                        counts: [],
                        dataset: []
                    });
                    return;
                }else if(!scope.securityGroupForm.template.id) {
                    scope.templateSelected = false;
                    return;
                }
                scope.securityGroupForm.type = scope.securityGroupForm.template.type;
                scope.templateSelected = true;
                self.getTemplateRule();
            }
            self.getTemplateRule = function(options) {
                let pageDefaultOption = {
                    pageSize: 10,
                    pageNum: 1,
                    direction:self.templateTab
                }
                let pageOption = angular.extend(pageDefaultOption,options);
                securityGroupSrv.getTemplateRuleDetail(pageOption,scope.securityGroupForm.template.id).then(res => {
                    if (res && res.data){
                        let data = _.map(res.data, function(item) {
                            item.object = item.remoteIpPrefix || item.remoteGroupName || $translate.instant("aws.security.any");
                            item.strategy = scope.securityGroupForm.template.type == '0'?$translate.instant("aws.security.allowed"):scope.securityGroupForm.template.type == '1'?$translate.instant("aws.security.notAllowed"):'';
                            return item;
                        });
                        self.templateRuleTable = new NgTableParams({
                            page: 1,
                            count: GLOBAL_CONFIG.PAGESIZE
                        }, {
                            counts: [],
                            dataset: data
                        });
                        self.modalTotalNum = res.total;
                    }
                })
            }
            // 切换模板tab出入口
            self.switchTab = function(val) {
                self.templateTab = val;
                self.changeTemplate();
            }

            self.changeModalPage = function(options) {
                self.getTemplateRule(options);
            }
        };

        // 克隆安全组
        self.cloneSecurityGroup = function() {
            let scope = self.$new();
            // scope.dataCentreOptions = [];
            // scope.departmentOptions = [];
            // scope.projectOptions = [];
            scope.cloneForm = {
                region:null,
                domain:null,
                project:null,
                name:'',
                description:'',
            };
            let cloneSecurityGroupModal = $uibModal.open({
                animation: true,
                templateUrl: "cloneSecurityGroup.html",
                scope: scope
            });
            // 获取数据中心
            scope.getRegionData = function() {
                securityGroupSrv.getRegionList().then(function(res) {
                    if (res && res.data && res.data.length) {
                        scope.dataCentreOptions = res.data;
                        scope.cloneForm.region = scope.dataCentreOptions[0];
                        scope.getDomainData(scope.cloneForm.region);
                    }
                });
            }

            // 获取部门
            scope.getDomainData = function(region) {
                securityGroupSrv.getDomainsList(region.regionKey).then(function(res) {
                    if (res && res.data) {
                        let data = res.data;
                        for (const item of data) {
                            item.domainName === 'default' && (item.domainName = $translate.instant("aws.common.defaultDepar"));
                        }
                        scope.departmentOptions = data;
                        scope.cloneForm.domain = data[0];
                        scope.projectOptions = scope.cloneForm.domain.projects;
                        scope.cloneForm.project = scope.projectOptions[0];
                    }
                });
            }
            scope.getRegionData();

            // 数据中心切换
            scope.changeRegion = function(item) {
                scope.getDomainData(item.regionKey);
            };

            // 部门切换
            scope.changeDomain = function(item) {
                scope.projectOptions = item.projects;
                scope.cloneForm.project = item.projects[0];
            };

            scope.cloneSubmitted = false;

            // clone安全组确认
            scope.cloneConfirm = function(form) {
                if (form.$valid){
                    let params = {
                        name: scope.cloneForm.name,
                        description: scope.cloneForm.description,
                        securityGroupId: self.editData.uid,
                        regionKey: scope.cloneForm.region.regionKey,
                        domainUid: scope.cloneForm.domain.domainUid,
                        projectUid: scope.cloneForm.project.projectId
                    }
                    securityGroupSrv.cloneSecurityGroup(params).then(function(res) {
                        initSecurityGroupTable();
                    })
                    cloneSecurityGroupModal.close();
                }else {
                    scope.cloneSubmitted = true;
                }
            }
        }

        //删除安全组
        self.deleteSecurityGroups = function() {
            let content = {
                target: "delSecurityGroup",
                msg: "<span>" + $translate.instant("aws.security.delSecurityGroup") + "</span>"
            };
            self.$emit("delete", content);
        };
        self.$on("delSecurityGroup", function() {
            let del_obj_ids = [];
            _.forEach(self.selectedItemsData, function(item) {
                if (item.name != "default") {
                    del_obj_ids.push(item.uid);
                }
            });
            securityGroupSrv.delFirewallAction({uid: del_obj_ids}).then(function() {
                initSecurityGroupTable();
            });
        });

        //安全组详情列表
        var initSecurityGroupRuleTable = function(options,changePage) {
            if(!changePage) self.curPage = 1;
            let pageDefaultOption = {
                pageSize: 10,
                pageNum: 1,
                likes:self.detailSearch.searchTerm,
                direction:self.detailTab
            }
            let pageOption = angular.extend(pageDefaultOption,options);
            securityGroupSrv.getFirewallRuleDetail(pageOption,$routeParams.id).then(function(res) {
                res ? self.securityDetailData = true : "";
                if (res && res.data) {
                    var data = _.map(res.data, function(item) {
                        item.status = item.enabled ? $translate.instant("aws.security.enabled") : $translate.instant("aws.security.disabled");
                        item.typeCode = item.protocol == "icmp"&&(item.portRangeMin||item.portRangeMin==0)&&(item.portRangeMax||item.portRangeMax==0) ? item.portRangeMin + " / " + item.portRangeMax : " - ";
                        item.sourceType = item.remoteIpPrefix || item.remoteGroupName || $translate.instant("aws.security.any");
                        item.strategy = $routeParams.securityType == '0'?$translate.instant("aws.security.allowed"):$routeParams.securityType == '1'?$translate.instant("aws.security.notAllowed"):'';
                        return item;
                    });
                    self.secDetailSearchTearm({"titleData":self.secDetailTitleData,"tableData":data});
                    self.tableParams = new NgTableParams({
                        page: 1,
                        count: GLOBAL_CONFIG.PAGESIZE
                    }, {
                        counts: [],
                        dataset: data
                    });
                    checkedSrv.checkDo(self, data, "id");
                    self.detailTotalNum = res.total;
                    self.$watch(function() {
                        return self.checkedItems;
                    }, function(values) {
                        let datas = values.filter(item => item.protocol);
                        if(datas&&datas.length){
                            self.notEnabled = datas.some(item => item.enabled);
                            self.notForbidden = datas.some(item => !item.enabled);
                        }else{
                            self.delisDisabled = true;
                        }
                    }, true);
                }
            });
        };

        $scope.$on("getDetail", function(event, securityGroup_id) {
            if($location.search().name=='PaaS security pair'){
                self.isPaasSecurity = true;
                securityGroupSrv.updateSecurityGroupRules(securityGroup_id).then(function(res){
                    self.switchDetailTab('ingress');
                })
            }else{
                self.isPaasSecurity = false;
            }
            self.detailSearch = {
                searchTerm : ''
            };
            self.securityDetailData = false;
            self.tableParams = new NgTableParams({
                count: GLOBAL_CONFIG.PAGESIZE
            }, {
                counts: [],
                dataset: []
            });
            self.securityType = $routeParams.securityType;
            
            self.secDetailTitleName = "secDetail";
            if (sessionStorage["secDetail"]) {
                self.secDetailTitleData = JSON.parse(sessionStorage["secDetail"]);
            } else {
                self.secDetailTitleData = [{
                    name: 'security.inputType',
                    value: true,
                    disable: false,
                    search: 'ethertype'
                }, {
                    name: 'security.ip_protocol',
                    value: true,
                    disable: false,
                    search: 'protocol'
                }, {
                    name: 'security.authorizationStrategy',
                    value: true,
                    disable: false,
                    search: 'strategy'
                }, {
                    name: 'security.status',
                    value: true,
                    disable: false,
                    search: 'status'
                }, {
                    name: 'security.portRange',
                    value: true,
                    disable: false,
                    search: 'portRange'
                }, {
                    name: 'security.typeCodeValue',
                    value: true,
                    disable: false,
                    search: 'typeCode'
                }, {
                    name: 'security.sourceType',
                    value: true,
                    disable: false,
                    search: 'sourceType'
                }];
                sessionStorage.setItem(self.secDetailTitleName,JSON.stringify(self.secDetailTitleData));
            };

            self.secDetailSearchTearm = function(obj) {
                var tableData = obj.tableData;
                var titleData = obj.titleData;
                tableData.map(function(item) {
                    item.searchTerm = "";
                    titleData.forEach(function(showTitle) {
                        if (showTitle.value) {
                            if (showTitle.search == 'portRange') {
                                item.searchTerm += (item.fromPort + "-" + item.toPort +"\b");
                            } else {
                                item.searchTerm += item[showTitle.search] +"\b";
                            }
                        }
                    });
                });
            };
            self.switchDetailTab('ingress');
        });

        // 切换详情规则tab出入口
        self.switchDetailTab = function(val){
            self.detailTab = val;
            initSecurityGroupRuleTable();
        }
        // 详情搜索
        self.applyDetailSearch = function(likes){
            $timeout.cancel(self.searchDetail)
            self.searchDetail = $timeout(()=>{
                initSecurityGroupRuleTable({likes});
            },1000)
        };

        //安全组详情页刷新
        self.refreshSecurityGroupDetail = function(){
            self.detailSearch.searchTerm = '';
            initSecurityGroupRuleTable();
        };

        self.changeDetailPage = function(options){
            initSecurityGroupRuleTable(options,'changePage');
        };

        self.selectItem = function(list,val,key) {
            if(list && list.length){
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    if(item[key] === val) return item;
                }
                return null;
            }else {
                return null;
            }
        }

        //安全组详情页面新建，编辑规则
        self.updateSecurityGroupRule = function(type){
            
            let scope = self.$new();
            let addSecurityGroupRuleModal = $uibModal.open({
                animation: true,
                templateUrl: "addSecurityGroupRule.html",
                scope: scope
            });
            scope.ruleSubmitted = false;
            scope.interactedPort = function(field) {
                scope.field_form = field;
                return scope.ruleSubmitted || field.port.$dirty;
            };
            scope.interactedPortRange = function(field) {
                scope.field_form = field;
                return scope.ruleSubmitted || field.fromPort.$dirty || field.toPort.$dirty;
            };
            scope.interactedProtocol = function(field) {
                scope.field_form = field;
                return scope.ruleSubmitted || field.protocol.$dirty;
            };
            scope.securityGroupRule = {
                EthernetType: 1
            };
            
            scope.MainTypeValue = [
                { name: "IPV4", value: 1 },
                { name: "IPV6", value: 2 },
            ]
            // 默认值
            scope.securityGroupRule.EthernetType = scope.MainTypeValue[0].value;
            scope.changeMainType = function(value){
                scope.securityGroupRule.EthernetType = value
            }

            // ip组
            scope.ipForm = {
                ip4:[],
                ip6:[]
            }

            addSecurityGroupRuleModal.opened.then(function() {
                scope.types = ["0", "3", "4", "5", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "30"];
                scope.allIcmpShow = true;
                if($routeParams.securityType == '1'){
                    scope.securityGroupRules = [{
                        name: "TCP",
                        value: "tcp"
                    }, {
                        name: "UDP",
                        value: "udp"
                    }];
                    scope.isBlackList = true;
                }else {
                    scope.securityGroupRules = [{
                        name: "TCP",
                        value: "tcp"
                    }, {
                        name: "UDP",
                        value: "udp"
                    }, {
                        name: "ICMP",
                        value: "icmp"
                    }, {
                        name: "ALL ICMP",
                        value: "icmp"
                    }, {
                        name: "其他协议",
                        value: "else"
                    }];
                    scope.isBlackList = false;
                }

                scope.directions = [{
                    name: $translate.instant("aws.security.ingress"),
                    value: "ingress"
                }, {
                    name: $translate.instant("aws.security.egress"),
                    value: "egress"
                }];

                scope.portTypes = [{
                    name: $translate.instant("aws.security.assignPort"),
                    value: "port"
                }, {
                    name: $translate.instant("aws.security.portRange"),
                    value: "portRange"
                }];

                scope.changeRule = function(rule,form) {
                    if (form.fromPort && form.toPort) {
                        form.fromPort.$dirty = false;
                        form.toPort.$dirty = false;
                    }
                    if (rule.name == "ALL ICMP" && rule.value == "icmp") {
                        scope.allIcmpShow = false;
                    } else {
                        scope.allIcmpShow = true;
                    }
                    if (rule.name == "ICMP" && rule.value == "icmp") {
                        scope.isIcmp = true;
                        scope.isPort = false;
                        scope.isPortRange = false;
                        
                        scope.securityGroupRule.from_port = scope.types[0];

                        scope.codings = ["0"];
                        scope.securityGroupRule.to_port = scope.codings[0];
                    } else {
                        scope.isIcmp = false;
                        scope.isPort = true;
                        scope.securityGroupRule.portType = scope.portTypes[0];
                    }
                    if (rule.value == "else") {
                        scope.elseProtocol = true;
                        scope.allIcmpShow = false;
                    } else {
                        scope.elseProtocol = false;
                    }
                };

                scope.changeType = function(t,coding) {
                    scope.codings = [];
                    switch (t) {
                        case "3":
                            scope.codings = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];
                            break;
                        case "5":
                            scope.codings = ["0", "1", "2", "3"];
                            break;
                        case "11":
                            scope.codings = ["0", "1"];
                            break;
                        case "12":
                            scope.codings = ["0", "1", "2"];
                            break;
                        default:
                            scope.codings = ["0"];
                            break;
                    }
                    scope.securityGroupRule.to_port = coding || scope.codings[0];
                };

                switch (type) {
                    case 'new':
                        scope.updateGroupRule_title = $translate.instant("aws.security.createRule");
                        scope.securityGroupRule.selectedRule = scope.securityGroupRules[0];
                        scope.securityGroupRule.direction = scope.directions.filter(item => item.value == self.detailTab)[0];
                        scope.securityGroupRule.portType = scope.portTypes[0];
                        scope.securityGroupRule.cidr = "0.0.0.0/0";
                        scope.securityGroupRule.way = "cidr";
                        securityGroupSrv.newGetFirewallTableData({pageSize:'',pageNum:''}).then(res=>{
                            if(res && res.data){
                                scope.firewallGroups = res.data;
                                scope.securityGroupRule.firewall = scope.firewallGroups[0];
                            }
                        })
                        break;
                    case 'edit':
                        
                        scope.updateGroupRule_title = $translate.instant("aws.security.editRule");
                        scope.securityGroupRule.selectedRule = scope.selectItem(scope.securityGroupRules,scope.editData.protocol,'value');
                        scope.securityGroupRule.direction = scope.selectItem(scope.directions,scope.editData.direction,'value');
                        
                        if(scope.editData.protocol == 'icmp'){
                            if(scope.editData.portRangeMin || scope.editData.portRangeMin == 0 || scope.editData.portRangeMax || scope.editData.portRangeMax == 0){
                                scope.allIcmpShow = true;
                            }else{
                                scope.allIcmpShow = false;
                                scope.securityGroupRule.selectedRule = scope.securityGroupRules[3] ? scope.securityGroupRules[3] : null;
                            }
                            scope.isIcmp = true;
                            scope.isPort = false;
                            scope.isPortRange = false;
                            scope.securityGroupRule.from_port = scope.editData.portRangeMin+'';
                            scope.changeType(scope.securityGroupRule.from_port,scope.editData.portRangeMax+'');
                        }else {
                            if(scope.securityGroupRule.selectedRule == null){
                                scope.allIcmpShow = false;
                                scope.elseProtocol = true;
                                scope.securityGroupRule.selectedRule = scope.securityGroupRules[4] ? scope.securityGroupRules[4] : null;
                                scope.securityGroupRule.protocol = scope.editData.protocol;
                            }
                            scope.securityGroupRule.portType = scope.editData.portRangeMin == scope.editData.portRangeMax ? scope.portTypes[0] : scope.portTypes[1];
                            if(scope.securityGroupRule.portType.value == 'port'){
                                scope.securityGroupRule.port = scope.editData.portRangeMin;
                            } else {
                                scope.securityGroupRule.from_port = scope.editData.portRangeMin+'';
                                scope.changeType(scope.securityGroupRule.from_port,scope.editData.portRangeMax+'');
                            }
                        }
                        
                        if(scope.editData.remoteIpPrefix){
                            scope.securityGroupRule.way = "cidr";
                            if(scope.editData.ethertype == 'IPv4'){
                                scope.securityGroupRule.cidr = scope.editData.remoteIpPrefix;
                            } else {
                                scope.ipv6Value.value = scope.editData.remoteIpPrefix;
                            }
                        }

                        if(scope.editData.ethertype == 'IPv4'){
                            scope.securityGroupRule.EthernetType = 1;
                        } else {
                            scope.securityGroupRule.EthernetType = 2;
                        }

                        securityGroupSrv.newGetFirewallTableData({pageSize:'',pageNum:''}).then(res=>{
                            if(res && res.data){
                                scope.firewallGroups = res.data;
                                if(scope.editData.remoteGroupName){
                                    scope.securityGroupRule.way = "firewall";
                                    scope.securityGroupRule.firewall = scope.selectItem(scope.firewallGroups,scope.editData.remoteGroupName,'name');
                                }else {
                                    scope.securityGroupRule.firewall = scope.firewallGroups[0];
                                }
                            }
                        })
                        break;
                    default:
                        break;
                }

                // scope.isPort = true;
                scope.$watch(function() {
                    return scope.securityGroupRule.portType;
                }, function(portType) {
                    if (portType && portType.value == "port") {
                        scope.isPort = true;
                        scope.isPortRange = false;
                    }
                    if (portType && portType.value == "portRange") {
                        scope.isPort = false;
                        scope.isPortRange = true;
                    }
                });

                // scope.isCidrWay = true;
                scope.$watch(function() {
                    return scope.securityGroupRule.way;
                }, function(value) {
                    switch (value) {
                        case "cidr":
                            scope.isCidrWay = true;
                            scope.isFirewallWay = false;
                            if(!scope.securityGroupRule.cidr){
                                scope.securityGroupRule.cidr = "0.0.0.0/0";
                            }
                            break;
                        case "firewall":
                            scope.isCidrWay = false;
                            scope.isFirewallWay = true;
                            break;
                    }
                });

                scope.$watch(function() {
                    return scope.securityGroupRule.port || scope.securityGroupRule.from_port;
                }, function(value) {
                    if (scope.securityGroupRule.selectedRule&&scope.securityGroupRule.selectedRule.value !== "icmp") {
                        if (value && (value < 1 || value > 65535)) {
                            scope.input_invalid = true;
                        } else {
                            scope.input_invalid = false;
                        }
                        if (!value && scope.field_form && scope.field_form.$dirty) {
                            scope.input_invalid = false;
                        }
                    }
                });
                scope.$watch(function() {
                    return scope.securityGroupRule.to_port;
                }, function(value) {
                    if (scope.securityGroupRule.selectedRule&&scope.securityGroupRule.selectedRule.value !== "icmp") {
                        if (scope.securityGroupRule.from_port) {
                            if (value && (value < Number(scope.securityGroupRule.from_port) || value > 65535)) {
                                scope.toPort_invalid = true;
                            } else {
                                scope.toPort_invalid = false;
                            }
                            if (!value && scope.field_form && scope.field_form.$dirty) {
                                scope.toPort_invalid = false;
                            }
                        }
                    }
                });
            });

            scope.ipv6Value = {
                value:'0:0:0:0:0:0:0:0/0'
            };
            scope.securityGroupRuleConfirm = function(data,form) {
                if (form && form.$valid) {
                    const ethertype = scope.securityGroupRule.EthernetType
                    var params = {
                        "securityGroupId": $routeParams.id,
                        "direction": data.direction.value, //入口或者出口 Ingress 或 egress
                        // "ethertype": "IPv4", //类型 默认传iPv4?
                        "ethertype": ethertype == 1? "IPv4":"IPv6", //类型 默认传iPv4?
                        "protocol": data.selectedRule.value //规则 null, tcp, udp, and icmp
                    };

                    if (data.selectedRule.name == "ALL ICMP") {
                        params.portRangeMin = null;
                        params.portRangeMax = null;
                    } else {
                        if (scope.isPort) {
                            params.portRangeMin = data.port;
                            params.portRangeMax = data.port;
                        } else {
                            params.portRangeMin = data.from_port;
                            params.portRangeMax = data.to_port;
                        }
                    }
                    
                    if (data.way == "cidr") {
                        params.remoteIpPrefix = ethertype == 1?scope.securityGroupRule.cidr:scope.ipv6Value.value;
                        // params.remoteIpPrefix = str; //IP地址

                        // params.remoteIpPrefix = data.cidr; //IP地址
                    } else {
                        // params.remoteIpPrefix = "";
                        params.remoteGroupId = data.firewall.uid;
                    }

                    if (data.selectedRule.value == "else") {
                        params.protocol = data.protocol;
                        params.portRangeMin = null;
                        params.portRangeMax = null;
                    }
                    
                    switch (type) {
                        case 'new':
                            securityGroupSrv.addFirewallRule(params).then(function() {
                                initSecurityGroupRuleTable();
                            });
                            break;
                        case 'edit':
                            params.id = self.editData.id;
                            securityGroupSrv.editFirewallRule(params).then(function() {
                                initSecurityGroupRuleTable();
                            });
                            break;
                        default:
                            break;
                    }
                    addSecurityGroupRuleModal.close();
                } else {
                    scope.ruleSubmitted = true;
                }
            };
        };

        // 安全组规则导出
        self.exportRules = function() {
            let url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/security-group/${$routeParams.id}/rules/export?direction=${self.detailTab}&token=${localStorage.$AUTH_TOKEN}`;
            utilTool.downloadFile(url);
        }

        // 安全组规则导入
        self.importRules = function() {
            self.submitInValid = false;
            self.fileCheck=false;
            var modal_import_rules = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "importRules.html",
                scope: self
            });
            self.selected_file="";
            self.fileNameChanged=function(){
                self.fileCheck=false;
                self.selected_file = document.getElementById("upload").value;
                var dom = document.getElementById("upload");
                var file = dom.files[0];
                 var fileType ="";
                file ? fileType=file.name.substr(-4,4):fileType=".xls";
                if(file&&(file.name.substr(-4,4)==".xls"||file.name.substr(-5,5)=='.xlsx')){
                    self.fileCheck=false;
                }else{
                    self.fileCheck=true; 
                }
                self.$apply();
    　　　　}
            self.confirm = function(filed){
                if(filed.$valid&&!self.fileCheck){
                    var form = document.forms.namedItem("importRulesForm");
                    var oData = new FormData(form);
                    var oReq = new XMLHttpRequest();
                    var importsuccess = $translate.instant("aws.security.importsuccess");
                    var importerror = $translate.instant("aws.security.importerror");
                    oReq.onerror = function(e) { 
                        if(e.type=="error"){
                            alertSrv.set("",importerror , "error",5000);        
                        }
                    };
                    oReq.onload = function(){
                       var responseObj = JSON.parse(oReq.responseText);
                       if(responseObj){
                            if( responseObj.code==0){
                                alertSrv.set("",importsuccess , "success",5000);
                                $timeout(function() {
                                    initSecurityGroupRuleTable();
                                },1000);
                            }else{
                                alertSrv.set("",$translate.instant("aws.statusCode."+responseObj.code) , "error",5000);
                            }
                       }
                    }
                    oReq.open("POST", `${window.GLOBALCONFIG.APIHOST.MANAGE}/v2/security-group/${$routeParams.id}/rules/${self.detailTab}/import`, true);
                    //导入文件
                    // sessionStorage.setItem("SecurityGroupRule", "SecurityGroupRule");
                    let auth_token = localStorage.$AUTH_TOKEN;
                    oReq.setRequestHeader("X-Auth-Token",auth_token); 
                    oReq.setRequestHeader("project_id",localStorage.projectUid);
                    oReq.send(oData);

                    modal_import_rules.close();
                }else{
                    self.submitInValid = true;
                }
            };
        }

        // 启用安全组规则
        self.enabled = function(data) {
            let ids = [];
            _.forEach(data.filter(item => item.protocol), function(item) {
                ids.push(item.id);
            });
            if(ids.length){
                securityGroupSrv.enabledRule({id: ids}).then(function() {
                    initSecurityGroupRuleTable();
                })
            }
        }

        // 禁用安全组规则
        self.forbidden = function(data) {
            let ids = [];
            _.forEach(data.filter(item => item.protocol), function(item) {
                ids.push(item.id);
            });
            if(ids.length){
                securityGroupSrv.forbiddenRule({id: ids}).then(function() {
                    initSecurityGroupRuleTable();
                })
            }
        }

        //删除安全组规则
        self.deleteSecurityGroupRule = function(data) {
            let content = {
                target: "deleteSecurityGroupRule",
                msg: "<span>" + $translate.instant("aws.security.deleteSecurityGroupRule") + "</span>",
                data: data
            };
            self.$emit("delete", content);
        };
        self.$on("deleteSecurityGroupRule", function(e, data) {
            let del_ids = [];
            _.forEach(data.filter(item => item.protocol), function(item) {
                del_ids.push(item.id);
            });
            if(del_ids.length){
                securityGroupSrv.firewallRuleRemove({id: del_ids}).then(function() {
                    initSecurityGroupRuleTable();
                });
            }
        });

    }
]);
