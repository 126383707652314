auditRoleSrv.$inject = ["$rootScope", "$http"]
function auditRoleSrv($rootScope, $http) {
    return {
        getLogs:function(params){
            return $http({
                method:"GET",
                url:"/awcloud-log/v1/enterprises/"+localStorage.enterpriseUid+"/logs/region/"+localStorage.regionUid+"/view/time",
                params:params
            })
        },
         //获取source top5
         getProjectOfTop:function(){
            return $http({
                method:"GET",
                url:"/awcloud-log/v1/enterprises/"+localStorage.enterpriseUid+"/logs/region/"+localStorage.regionUid+"/view"
            })
        },
        //获取user top5
        getUserOfTop:function(){
            return $http({
                method:"GET",
                url:"/awstack-user/v1/enterprises/"+localStorage.enterpriseUid+"/domains/getUsers"
            })
        },
        
    }
}
export {auditRoleSrv}