angular.module("ticketsApplyModule", ["ngTable", "ngAnimate", "ui.bootstrap.tpls", "ui.tree", "checkedsrv"])
    .controller("ticketsApplyCtrl", ["$scope", "$rootScope", "NgTableParams", "$uibModal", "ticketsSrv", "alertSrv", "$translate", "checkedSrv", "$location","GLOBAL_CONFIG", function (scope, rootScope, NgTableParams, $uibModal, ticketsSrv, alertSrv, $translate, checkedSrv, $location,GLOBAL_CONFIG) {
      console.log("工单申请");
      var self = scope ;
      getApplyTypes()
      self.apply = function(type){
        switch (type) {
            case "1":
            var $applyQuotaModal = $uibModal.open({
                animation: self.animationsEnabled,
                backdrop: 'static',
                templateUrl: "js/ticket/tmpl/apply-quota.html",
                controller:  "applyQuotaCtrl",
                resolve: {
                    closeModal:function(){
                        return function(){
                            $applyQuotaModal.close()
                        }
                    },
                    editData:function(){
                        return {}
                    }             
                }
            });
            break;
            case "2":
            var $applyInsModal = $uibModal.open({
                animation: self.animationsEnabled,
                backdrop: 'static',
                templateUrl: "js/ticket/tmpl/apply-applyIns.html",
                controller:  "applyInsCtrl",
                resolve: {
                    closeModal:function(){
                        return function(){
                            $applyInsModal.close()
                        }
                    },
                    editData:function(){
                        return {}
                    }
                }
            });
            break;
            case "3":
            var $applyVolumesModal = $uibModal.open({
                animation: self.animationsEnabled,
                backdrop: 'static',
                templateUrl: "js/ticket/tmpl/apply-volumesApply.html",
                controller:  "applyVolumesCtrl",
                resolve: {
                    closeModal:function(){
                        return function(){
                            $applyVolumesModal.close()
                        }
                    },
                    editData:function(){
                        return {}
                    }
                }
            });
            break;
            case "4":
            var $applyNetworkModal = $uibModal.open({
                animation: self.animationsEnabled,
                backdrop: 'static',
                templateUrl: "js/ticket/tmpl/apply-networkApply.html",
                controller:  "networkApplyCtrl",
                resolve: {
                    closeModal:function(){
                        return function(){
                            $applyNetworkModal.close()
                        }
                    },
                    editData:function(){
                        return {}
                    }
                }
            });
            break;
            case "5":
            var $applyRouterModal = $uibModal.open({
                animation: self.animationsEnabled,
                backdrop: 'static',
                templateUrl: "js/ticket/tmpl/apply-routerApply.html",
                controller:  "routerApplyCtrl",
                resolve: {
                    closeModal:function(){
                        return function(){
                            $applyRouterModal.close()
                        }
                    },
                    editData:function(){
                        return {}
                    }
                }
            });
            break;
            case "11":
            var $applyInfoModal = $uibModal.open({
                animation: self.animationsEnabled,
                backdrop: 'static',
                templateUrl: "js/ticket/tmpl/apply-infoApply.html",
                controller:  "infoApplyCtrl",
                resolve: {
                    closeModal:function(){
                        return function(){
                            $applyInfoModal.close()
                        }
                    }
                }
            });
            break;
        }
      }
      function getApplyTypes(){
        ticketsSrv.getApplyTypes().then(function(data){
            if(data && data.data){
                var applyTyps_res = [];
                var applyTyps_info = [];
                data.data.map(function(item){
                    if(item.type == 10 || item.type == 11 ){
                        applyTyps_info.push(item)
                    }else{
                        applyTyps_res.push(item)
                    }   
                })
                self.applyTyps_info = applyTyps_info ;
                self.applyTyps_res = applyTyps_res ;
            }
        })
      }
    }])
    .controller("applyQuotaCtrl", ["$scope", "$rootScope", "$uibModal", "ticketsSrv", "alertSrv", "$translate","closeModal","editData", 
    function (scope, rootScope, $uibModal, ticketsSrv, alertSrv, $translate,closeModal,editData) {
        console.log("配额申请",editData);
        var self = scope;
        self.canConfirm = false;
        var domainUid = localStorage.defaultdomainUid;
        if(localStorage.managementRole == "2" || localStorage.managementRole == "3"){
            self.showProjectChoose = false;
        }else{
            self.showProjectChoose = true;
        }
        var localData = localStorage.$LOGINDATA ? JSON.parse(localStorage.$LOGINDATA) : [];
        var id = JSON.parse(localStorage.$LOGINDATA).id.toString();
        var resourceData = {
            busiData: {
                applyUserId: "",
                domainUid: "",
                projectUid: "",
                domainName:localStorage.domainName,
                quota:""
            },
            key: ""
        };
        scope.tickets = {
            depart: {selected: ""},
            deparList: [],
            pro: {selected: ""},
            projectsList: []
        };
   
        //获取项目配额
        var getProQuta = function(){
            if(scope.tickets.pro.selected){
                ticketsSrv.getDomainQuotas(self.tickets.depart.selected.domainUid).then(function(data){
                    if(data && data.data){
                        var domainQuotas = data.data;
                        ticketsSrv.getProHave(scope.tickets.pro.selected.projectId).then(function(result){
                            scope.quotas=[];
                            if(result && result.data){
                                _.forEach(result.data,function(item){
                                    item.projectUid = localStorage.projectUid;
                                    if(item.name == "ram" ){
                                        item.hardLimit = item.hardLimit/1024;
                                    }
                                    domainQuotas.map(function(quota){
                                        if(item.name == quota.name){
                                            item.availquota = quota.hardLimit
                                            if(item.name == "ram" ){
                                                item.availquota = quota.hardLimit/1024;
                                            }
                                        } 
                                    })
                                    scope.quotas.push(item);
                                });
                                console.log(scope.quotas)
                                if(!rootScope.L3){
                                    scope.quotas=scope.quotas.filter(item=>{
                                        return item.name!="floatingip"
                                    })
                                }
                                if(editData.type=='edit'&&editData.data.projectUid==scope.tickets.pro.selected.projectId){
                                    let quotaMap = {};
                                    editData.data.quota.forEach(item=>{
                                        quotaMap[item.name] = item.hardLimit;
                                    })
                                    scope.quotas.forEach(item=>{
                                        item.hardLimit = quotaMap[item.name];
                                    })
                                }

                            }
                        });
                    }
                })
               
            }
            
        };
        //获取部门配额
        var getDomainHave = function(){
            if(scope.tickets.pro.selected){
                ticketsSrv.getDomainHave(self.tickets.depart.selected.domainUid).then(function(result){
                    scope.quotas=[];
                    if(result && result.data){
                        _.forEach(result.data,function(item){
                            if(item.name == "ram"){
                                item.hardLimit = item.hardLimit/1024
                            }

                            scope.quotas.push(item);
                        });
                        if(!rootScope.L3){
                            scope.quotas=scope.quotas.filter(item=>{
                                return item.name!="floatingip"
                            })
                        }
                        if(editData.type=='edit'&&editData.data.domainUid==self.tickets.depart.selected.domainUid){
                            let quotaMap = {};
                            editData.data.quota.forEach(item=>{
                                quotaMap[item.name] = item.hardLimit;
                            })
                            scope.quotas.forEach(item=>{
                                item.hardLimit = quotaMap[item.name];
                            })
                        }
                    }
                });
            }
            
        };
        scope.changedepart = function (m) {
            scope.tickets.projectsList = scope.ticketsData[m.name];
            scope.tickets.pro.selected = scope.tickets.projectsList[0];
            if(scope.tickets.quotaType){
                getProQuta();
            }
        };
        scope.changePro = function(current){
            getProQuta();
        };
        scope.resourceConfirm = function (m) {
            if (m.$valid) {
                self.canConfirm = true;
                var id = JSON.parse(localStorage.$LOGINDATA).id.toString();
                resourceData = {
                    busiData: {
                        type:"1",
                        applyUserId: id,
                        domainUid: scope.tickets.depart.selected.domainUid,
                        "domainName":localStorage.domainName,
                        quota:scope.quotas,
                        "userName":localStorage.userName ,
                        "projectName":localStorage.projectName,
                       
                    },
                };
                if(self.showProjectChoose){
                    resourceData.busiData.projectUid =scope.tickets.pro.selected.projectId ;
                }
                if(editData.type=='edit'){
                    let editResourceData = {
                        busiData: {
                            type:"1",
                            quota:scope.quotas,
                        }
                    }
                    ticketsSrv.editTicketItem(editResourceData,editData.processInstanceId).then(function(){
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    })
                }else{
                    ticketsSrv.startJob(resourceData,localStorage.enterpriseUid).then(function () {
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    });
                }
                
            } else {
                scope.submitValid = true;
            }
        };
        getProjects()
        function getProjects(){
            ticketsSrv.getProjectsList().then(function(data){
                if(data && data.data){   
                    var prolist = data.data ;
                    for(var i=0;i<prolist.length;i++){
                        if(prolist[i].domainUid=='default'){
                            prolist[i].disDomainName = '默认部门';
                        }else{
                            prolist[i].disDomainName = prolist[i].domainName;
                        }
                        if(prolist[i].projects&&prolist[i].projects.length>0){
                            for(var j=0;j<prolist[i].projects.length;j++){
                                if(prolist[i].projects[j].projectName == 'admin' && prolist[i].domainUid=='default'){
                                    prolist[i].projects[j].disProjectName = '默认项目';         
                                }else{
                                    prolist[i].projects[j].disProjectName = prolist[i].projects[j].projectName;
                                }
                            }
                        }
                    }
                    self.tickets.deparList = prolist;
                    prolist.map(function(item){
                        if(item.domainUid == domainUid){
                            self.tickets.depart.selected = item;
                            self.tickets.pro.selected = self.tickets.depart.selected.projects[0];
                        }
                    })
                    if(editData.type=='edit'){
                        //self.quotas = editData.data.quota;
                        self.tickets.depart.selected.projects.forEach(item=>{
                            if(item.projectId == editData.data.projectUid){
                                self.tickets.pro.selected = item;
                            }
                        })
                    }
                    if(self.showProjectChoose){
                        getProQuta();
                    }else{
                        getDomainHave()
                    }
                }
            })
        }
      }])
      .controller("infoApplyCtrl", ["$scope", "$rootScope", "NgTableParams", "$uibModal", "ticketsSrv", "alertSrv", "$translate", "checkedSrv", "$location","GLOBAL_CONFIG","closeModal", function (scope, rootScope, NgTableParams, $uibModal, ticketsSrv, alertSrv, $translate, checkedSrv, $location,GLOBAL_CONFIG,closeModal) {
        console.log("信息咨询");
        var self = scope ;
        var domainUid = localStorage.defaultdomainUid ;
        var userId = localStorage.$USERID ? localStorage.$USERID : "";
        self.canConfirm = false;
        scope.submitValid = false;
        ticketsSrv.getAddresseeNew().then(function(res){
            if(res&&res.data){
                self.Addressee=res.data.filter(item=>{
                    return item.name!=localStorage.rolename && item.name!='security'&&item.name!="audit";
                });
            }
        });
        scope.qa = {
            userTo: "",
            title: "",
            description: ""
        };
        scope.Addresseegroup = {};
        scope.Addresseegroup.selected = [];
        scope.ticketQaconfirm = function (m) {
            var id = JSON.parse(localStorage.$LOGINDATA).id.toString();
            if (m.$valid) {
                self.canConfirm = true;
                var usergroup=[]
                _.forEach(scope.Addresseegroup.selected,function(item){
                    usergroup.push(item.id)
                })
                scope.qa.userTo=usergroup.join(",");
                var qaData = {
                        title: scope.qa.title,
                        userTo: scope.qa.userTo,
                        description : scope.qa.description,
                        createdby:userId,
                        enterpriseUid:localStorage.enterpriseUid

                };
                ticketsSrv.addQaFun(qaData,userId).then(function (res) {
                	ticketsSrv.getMasTask(localStorage.enterpriseUid,userId).then(function(data){
        				ticketsSrv.unHandledMessage = data;
        			});
                    closeModal();
                    self.canConfirm = false;
                });
            } else {
                scope.submitValid = true;
            }
        };
       
      }])
      .controller("applyVolumesCtrl", ["$scope","ticketsSrv","closeModal","editData", 
      function (scope,ticketsSrv,closeModal,editData) {
        console.log("云硬盘申请",editData);
        var self = scope;
        var domainUid = localStorage.defaultdomainUid ;
        var userId = localStorage.$USERID ? localStorage.$USERID : "";
        self.canConfirm = false;
        self.interacted = function(field) {
            return self.submitted || field.$dirty;
        };
        getStorageDeviceList()
        self.volumeForm ={
            name:"",
            size:"",
            description:""
        }
        if(editData.type=="edit"){
            self.volumeForm.name = editData.data.disk.diskName;
            self.volumeForm.size = editData.data.disk.diskCapacity;
            self.volumeForm.description = editData.data.disk.des;
        }
        self.volumeConfirm = function(createVolumeForm){
            if(createVolumeForm.$valid){
                self.canConfirm = true;
                var resourceData ={
                    busiData: {
                    "applyUserId":userId,
                    "domainUid":domainUid,
                    "domainName":localStorage.domainName,
                    "disk":{
                        "diskName":self.volumeForm.name,
                        "diskCapacity":self.volumeForm.size,
                        "des":self.volumeForm.description?self.volumeForm.description:"",
                        "volume_type":scope.volumeForm.storageDeviceSelected.volumeTypeId,
                        "disPlayName":scope.volumeForm.storageDeviceSelected.disPlayName
                    },
                    "type":"3",
                    "userName":localStorage.userName,
                    "projectName":localStorage.projectName ,
                    "projectUid":localStorage.projectUid    
                  }
                }
                if(editData.type=='edit'){
                    let editResourceData = {
                        busiData: {
                            "disk":{
                                "diskName":self.volumeForm.name,
                                "diskCapacity":self.volumeForm.size,
                                "des":self.volumeForm.description?self.volumeForm.description:"",
                                "volume_type":scope.volumeForm.storageDeviceSelected.volumeTypeId,
                                "disPlayName":scope.volumeForm.storageDeviceSelected.disPlayName
                            },
                            "type":"3"
                        }
                    }
                    ticketsSrv.editTicketItem(editResourceData,editData.processInstanceId).then(function(){
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    })
                }else{
                    ticketsSrv.startJob(resourceData,localStorage.enterpriseUid).then(function () {
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    });
                }
                
            }else{
                self.submitInValid = true;
            }
        }
        function getStorageDeviceList(){
            ticketsSrv.getStorageDeviceList().then(function(data){
               if(data && data.data && data.data.length){
                    scope.storageDeviceList = data.data;
                    if(scope.storageDeviceList.length){
                        scope.volumeForm.storageDeviceSelected = scope.storageDeviceList[0];
                        if(editData.type=="edit"){
                            scope.storageDeviceList.forEach((item)=>{
                                if(item.disPlayName==editData.data.disk.disPlayName){
                                    scope.volumeForm.storageDeviceSelected = item;
                                }
                            })
                        }
                    }
               }
            })
        }
      }])
      .controller("networkApplyCtrl", ["$scope","ticketsSrv","closeModal","editData",
      function (scope,ticketsSrv,closeModal,editData) {
        console.log("网络申请",editData);
        var self = scope ;
        self.canConfirm = false;
        var domainUid = localStorage.defaultdomainUid ;
        var userId = localStorage.$USERID ? localStorage.$USERID : "";
        self.networkFormData = {
            init_cidr:{
                ip_1:"10",
                ip_2:"0",
                ip_3:"0",
                ip_4:"0",
                ip_5:"24"
            }
        }
        if(editData.type=="edit"){
            let ip_all = editData.data.network.cidr.split("/")[0];
            let cidr = editData.data.network.cidr.split("/")[1];
            let ip_arr = ip_all.split('.');
            self.networkFormData.init_cidr.ip_1 = ip_arr[0];
            self.networkFormData.init_cidr.ip_2 = ip_arr[1];
            self.networkFormData.init_cidr.ip_3 = ip_arr[2];
            self.networkFormData.init_cidr.ip_4 = ip_arr[3];
            self.networkFormData.init_cidr.ip_5 = cidr;
            self.networkFormData.name = editData.data.network.netName;
            self.networkFormData.description = editData.data.network.desc;
        }
        
        self.networkFormData_ipv6 = {
            ip1:'',
            ip2:'',
            ip3:'',
            ip4:'',
            ip5:'',
            ip6:'',
            ip7:'',
            ip8:'',
            ip9:'',
        }

        // 当前选择的ip类型
        self.nowIp = 4;
        self.networtTypes =[
            { showName: "网络", id: 0 },
            { showName: "子网", id: 1 }
        ]
        self.networkFormData.type = self.networtTypes[0];
        self.networkConfirm = function(form){
            if(form.$valid){
                self.canConfirm = true;
                var resourceData ={
                    busiData: {
                        "applyUserId":userId,
                        "domainUid":domainUid,
                        "domainName":localStorage.domainName,
                        "network":{
                            "netName":self.networkFormData.name,
                            "desc":self.networkFormData.description?self.networkFormData.description:"",
                            "cidr":self.networkFormData.init_cidr.ip_1+"."+self.networkFormData.init_cidr.ip_2+"."+self.networkFormData.init_cidr.ip_3+"."+self.networkFormData.init_cidr.ip_4+"/"+self.networkFormData.init_cidr.ip_5
                        },
                        "type":"4",
                        "userName":localStorage.userName,
                        "projectName":localStorage.projectName,
                        "projectUid":localStorage.projectUid
                    }
                }

                if(editData.type=='edit'){
                    let editResourceData = {
                        busiData: {
                            "network":{
                                "netName":self.networkFormData.name,
                                "desc":self.networkFormData.description?self.networkFormData.description:"",
                                "cidr":self.networkFormData.init_cidr.ip_1+"."+self.networkFormData.init_cidr.ip_2+"."+self.networkFormData.init_cidr.ip_3+"."+self.networkFormData.init_cidr.ip_4+"/"+self.networkFormData.init_cidr.ip_5
                            },
                            "type":"4"
                        }
                    }
                    ticketsSrv.editTicketItem(editResourceData,editData.processInstanceId).then(function(){
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    })
                }else{
                    ticketsSrv.startJob(resourceData,localStorage.enterpriseUid).then(function () {
                        closeModal();
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                    });
                }
            }else{
                self.submitInValid = true;
            }
        }
        getNetworks();
        function getNetworks(){
            ticketsSrv.getNetworks().then(function(data){
               if(data && data.data){
                    self.networkList = data.data;
                    self.networkFormData.TheirNet = self.networkList[0]
               }
            })
        }
      }])
   
      .controller("routerApplyCtrl", ["$scope","ticketsSrv","closeModal","editData", 
      function (scope,ticketsSrv,closeModal,editData){
        console.log("路由器申请",editData);
        var self = scope ;
        self.canConfirm = false;
        var domainUid = localStorage.defaultdomainUid ;
        var userId = localStorage.$USERID ? localStorage.$USERID : "";
        self.routerForm = {}
        if(editData.type=="edit"){
            self.routerForm.name = editData.data.router.name;
            self.routerForm.description = editData.data.router.desc;
            self.routerForm.assignSub = editData.data.router.assSubnetName?true:false;
        }
        getExtNets()
        getTenantSubs()
        self.changeExtNet = function(extNet) {
            // 外部网络相关逻辑，只有v4子网的网络 item.netFlag=1,v4和v6子网并存的网络item.netFlag=2
            // =2 时，必须指定外部子网;
            if(extNet.netFlag){
                if(extNet.netFlag==1){
                    self.routerForm.subnetBoxDisabled = false;
                }else{
                    self.routerForm.subnetBoxDisabled = true;
                    self.routerForm.assignSub = true;
                }
            }
            self.subnets = extNet.subnets;
            self.routerForm.subnet =  self.subnets[0];
        };
        self.routerConfirm = function(form){
            if(form.$valid){
                self.canConfirm = true;
                var resourceData ={
                    busiData: {
                    "applyUserId":userId,
                    "domainUid":domainUid,
                    "domainName":localStorage.domainName,
                    "router":{
                        "name":self.routerForm.name,
                        "desc":self.routerForm.description?self.routerForm.description:"",
                        "network_id":self.routerForm.selectedNet.id,
                        "network_name":self.routerForm.selectedNet.name,
                        "subnet_id":self.routerForm.selectedTenantSub.id,
                        "subnet_name":self.routerForm.selectedTenantSub.name,
                    } ,
                    "type":"5",
                    "userName":localStorage.userName ,
                    "projectName":localStorage.projectName ,
                    "projectUid":localStorage.projectUid   
                  }
                }
                if(self.routerForm.assignSub){
                    resourceData.busiData.router.external_fixed_ips =[{"subnet_id": self.routerForm.subnet.id}]
                    resourceData.busiData.router.assSubnetName =self.routerForm.subnet.name 
                }

                if(editData.type=='edit'){
                    let editResourceData = {
                        busiData:{}
                    }
                    editResourceData.busiData.router = resourceData.busiData.router;
                    editResourceData.busiData.type = resourceData.busiData.type;
                    ticketsSrv.editTicketItem(editResourceData,editData.processInstanceId).then(function(){
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    })
                }else{
                    ticketsSrv.startJob(resourceData,localStorage.enterpriseUid).then(function () {
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    });
                }
                 
            }else{
                self.submitInValid = true;
            }
        }
       //获取路由器公网网
       function getExtNets(){
            ticketsSrv.getExtNets().then(function(res) {
                if (res && res.data) {
                    self.extNets = res.data;
                    
                    
                    // self.extNets.forEach(item=>{
                    //     item.subnets = item.subnets.filter(ii=>{
                    //         return ii.ipVersion==4;
                    //     })
                    // })
                    self.extNets = self.extNets.filter(function(item){
                        let v4Num = 0,v6Num = 0;
                        item.subnets.forEach(function(subnet){
                            if(subnet.ipVersion=='4'){
                                v4Num++
                            }else{
                                v6Num++
                            }
                        });
                        //只有v4子网的网络 item.netFlag=1
                        if(v4Num&&!v6Num){
                            item.netFlag = 1;
                        }
                        //v4和v6子网并存的网络item.netFlag=2
                        if(v4Num&&v6Num){
                            item.netFlag = 2;
                        }
                        //过滤掉只有v6子网的网络
                        return v4Num;
                    });
                    scope.extNets.forEach(item=>{
                        item.subnets = item.subnets.filter(ii=>{
                            return ii.ipVersion==4
                        })
                    })
                    self.extNets = self.extNets.filter(item=>{
                        return item.subnets.length>0;
                    })
                    self.routerForm.selectedNet = self.extNets[0] ;
                    // self.subnets = self.extNets[0].subnets;
                    // self.routerForm.subnet =  self.subnets[0]
                    self.changeExtNet(self.routerForm.selectedNet);
                    if(editData.type=="edit"){
                        self.extNets.forEach(item=>{
                            if(item.id==editData.data.router.network_id){
                                self.routerForm.selectedNet = item;
                            }
                        })
                    }
                    
                    if(editData.type=="edit"&&self.routerForm.assignSub){
                        self.subnets.forEach(item=>{
                            if(item.id==editData.data.router.external_fixed_ips[0].subnet_id){
                                self.routerForm.subnet = item;
                            }
                        })
                    }
                }
            });
       }
        function getTenantSubs(){
            ticketsSrv.getTenantSubs().then(function(res) {
                if (res && res.data) {
                    var tenantSubs_data = res.data.filter(function(item) {
                        item.name = item.name + "---" + item.cidr;
                        return item.gatewayIp;
                    });
                    self.tenantSubs = tenantSubs_data;
                    self.routerForm.selectedTenantSub = self.tenantSubs[0]
                    if(editData.type=="edit"){
                        self.tenantSubs.forEach(item=>{
                            if(item.id==editData.data.router.subnet_id){
                                self.routerForm.selectedTenantSub = item;
                            }
                        })
                    }
                }
            });
        }
      }])
      .controller("applyInsCtrl", ["$scope", "NgTableParams","ticketsSrv", "$translate","closeModal","imagesSrv","editData","$timeout", 
      function (scope,NgTableParams,ticketsSrv,$translate,closeModal,imagesSrv,editData,$timeout) {
        var self = scope;
        var domainUid = localStorage.defaultdomainUid ;
        var userId = localStorage.$USERID ? localStorage.$USERID : "";
        console.log(editData);
        self.instanceForm = {}
        self.submitValid = false;
        self.canConfirm = false;
        self.tableFilterList = {
            image:{
                name:"镜像类型",
                filter:[
                    {name:"全部",type:{is_public:""}},
                    {name:"公有",type:{is_public:true}},
                    {name:"私有",type:{is_public:false}}
                ]
            },
            arch:{
                name:"系统架构",
                filter:[
                    {name:$translate.instant("aws.img.all"),type:{arch:""}},
                    // {name:"64位",type:{arch:"x86_64"}},
                    // {name:"32位",type:{arch:"i686"}}
                ]
            },
            os:{
                name:"操作系统",
                filter:[
                    {name:"全部",type:{os_type:""}},
                    {name:"Windows",type:{os_type:"windows"}},
                    {name:"Linux",type:{os_type:"linux"}}
                ]
            }
        }
        const archList =  __OPTION__CLOUD__.imageArch.map(item=>{
            return {
                name:$translate.instant("aws.img.arch."+item),
                type:{
                    arch:item
                }
            }
        })
        self.tableFilterList.arch.filter.push(...archList);
        self.tableContent = self;
        if(editData.type=="edit"){
            self.instanceForm.name = editData.data.instance.name;
            self.instanceForm.admin_pass = editData.data.instance.password;
            self.instanceForm.admin_comfirmpass = editData.data.instance.password;
            self.instanceForm.description = editData.data.instance.desc;
        }
        scope.changeImg = function(data){
            if (data&& data.imageUid) {
                self.iamgeInfo = data;
                self.instanceForm.chkImageId = data.imageUid;
            }
        }
        self.imageChange = function(data){
            self.iamgeInfo = data;
        }
        self.insConfirm = function(form){
            if(form.$valid && self.insImgTable  && self.insImgTable.data && self.insImgTable.data[0]){
                self.canConfirm = true;
                var resourceData ={
                    busiData: {
                    "applyUserId":userId,
                    "domainUid":domainUid,
                    "domainName":localStorage.domainName,
                    "instance":{
                        "name":self.instanceForm.name,
                        "desc":self.instanceForm.description?self.instanceForm.description:"",
                        "password":self.instanceForm.admin_pass,
                        "cpu":scope.instanceForm.flavor.vcpus,
                        "ram":scope.instanceForm.flavor.ram/1024,
                        "imageName":self.iamgeInfo.name,
                        "imageId":self.iamgeInfo.imageUid,
                        "network_id":self.instanceForm.selectedNet.id,
                        "network_name":self.instanceForm.selectedNet.name,
                        "subnet_id":scope.instanceForm.selectedSubnet.id,
                        "subnet_name" : scope.instanceForm.selectedSubnet.name,
                        "flavor":scope.instanceForm.flavor.id,
                        "volume_type":scope.instanceForm.storageDeviceSelected.volumeTypeId,
                        "disPlayName":scope.instanceForm.storageDeviceSelected.disPlayName =="本地盘"?"":scope.instanceForm.storageDeviceSelected.disPlayName,
                        } ,
                     "type":"2",
                     "userName":localStorage.userName,
                     "projectName":localStorage.projectName ,
                     "projectUid":localStorage.projectUid  
                  }
                }

                if(editData.type=='edit'){
                    let editResourceData = {
                        busiData:{}
                    }
                    editResourceData.busiData.instance = resourceData.busiData.instance;
                    editResourceData.busiData.type = resourceData.busiData.type;
                    ticketsSrv.editTicketItem(editResourceData,editData.processInstanceId).then(function(){
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        self.canConfirm = false;
                        closeModal();
                    })
                }else{
                    ticketsSrv.startJob(resourceData,localStorage.enterpriseUid).then(function () {
                        ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function(result) {
                            ticketsSrv.unHandledTickets = result;
                        });
                        closeModal();
                        self.canConfirm = false;
                    });
                }
                
            }else{
                self.submitInValid = true;
            }
        }
        self.changeExtNet = function(data){
            scope.instanceForm.selectedSubnet  =data.subnets[0]
        }
        getInsImages()
        getNetworks()
        getFlovars()
        getStorageDeviceList()
        function getInsImages(callbackFunc){
            self.arch = {
                selected: {}
            };
            imagesSrv.getImages().then(function(res){
                res ? self.loadImgData = true : "";
                let page = 1;
                if (res && res.data){
                    res.data.map(function(item){
                        if (item.os && (item.os).toLowerCase() == "unknown") {
                            item.os = "";
                            item.os_type = "";
                        }
            
                        if (item.os_type != null && item.size != null && item.arch != null ) {
                            if (((item.os_type).toLowerCase() == 'windows' || (item.os_type).toLowerCase() == "linux") 
                                && (item.size != 0) && item.arch 
                                && item.disk_format != 'iso' && item.status.toLowerCase() === 'active') {
                                item.canUse = true;
                            } 
                        }else{
                            item.canUse = false;
                        }
                        item.status = item.status.toLowerCase();
                        item._imageType = item.is_public ? "公有" : "私有";
                        item.size = item.size*1;
                        item.os_type = item.os_type ? item.os_type.toLowerCase() : "";
                        return item;
                    })
                    res.data = res.data.filter(item => {
                        return item.canUse == true;
                    });
                    if ( res.data.length) {
                        self.instanceForm.chkImageId = res.data[0].imageUid;
                        self.iamgeInfo = res.data[0];
                        if(editData.type=="edit"){
                            res.data.forEach((item,index)=>{
                                if(item.imageUid==editData.data.instance.imageId){
                                    self.instanceForm.chkImageId = item.imageUid;
                                    self.iamgeInfo = item;
                                    page = index+1;
                                }
                            })
                        }
                    }
                    self.insImgTable = new NgTableParams({
                        count: 3
                    }, {
                        counts: [],
                        dataset: res.data
                    });
                    $timeout(function(){
                        self.insImgTable.page(Math.ceil(page/3))
                    },500)
                    // self.createInsForm.sysVolumeMinSize = self.arch.selected.size;
                    // self.createInsForm.sysVolumeSize =self.arch.selected.size;

                }
            })
        }
        function getNetworks(){
            ticketsSrv.getProjectNetwork().then(function(result) {
                if (result && result.data && angular.isArray(result.data) ) {
                    if(!self.ADMIN){
                        scope.networkList = result.data.filter(function(item) {
                            return !item.external&&item.subnets.length!=0; //没有绑定子网的交换机创建云主机时不能使用
                        });
                    }else{
                        scope.networkList = result.data.filter(function(item) {
                            return item.subnets.length; //没有绑定子网的交换机创建云主机时不能使用
                        });
                    }
                    scope.networkList.forEach(function(network){
                        network.subnets=network.subnets.filter(function(subnet){
                            return subnet.ipVersion=='4';
                        });
                    });
                    scope.instanceForm.selectedNet = scope.networkList[0];
                    scope.instanceForm.selectedSubnet  =scope.networkList[0].subnets[0];
                    if(editData.type=="edit"){
                        scope.networkList.forEach(item=>{
                            if(item.id==editData.data.instance.network_id){
                                scope.instanceForm.selectedNet = item;
                            }
                        })
                        scope.instanceForm.selectedNet.subnets.forEach(item=>{
                            if(item.id==editData.data.instance.subnet_id){
                                scope.instanceForm.selectedSubnet = item;
                            }
                        })

                    }
                   
                }
            });
        }
        function getFlovars(){
            self.flavorNormalList =[]
            ticketsSrv.getFlavors().then(function(result) {
                if (result && result.data && angular.isArray(result.data)) {
                    _.forEach(result.data, function(val) {
                        let flavor_ram_gb = val.ram  / 1024;
                        val.ram_gb = Math.ceil(flavor_ram_gb) == flavor_ram_gb ? flavor_ram_gb : flavor_ram_gb.toFixed(1);
                        val.text = val.name + "：" + val.vcpus + $translate.instant("aws.instances.conf.memtip") + val.ram_gb + "GB ";
                        self.flavorNormalList.push(val);
                    });
                    if(editData.type=="edit"){
                        self.flavorNormalList.forEach(item=>{
                            if(item.id==editData.data.instance.flavor){
                                self.instanceForm.flavor = item;
                            }
                        })
                    }
                }
            });
        }
        function getStorageDeviceList(){
            ticketsSrv.getStorageDeviceList().then(function(data){
               if(data && data.data){
                    scope.storageDeviceList = data.data;
                    if(localStorage.isCustom =='false'){
                        var bootLocal = true;
                        var regionUid=JSON.parse(localStorage.$LOGINDATA).regionUid;
                        ticketsSrv.getAllNode(regionUid).then(function(res){
                            if(res && res.data && angular.isArray(res.data)){
                                res.data.map(item => {
                                    item.nodeConfigScript =  JSON.parse(item.nodeConfigScript);
                                    item.hostInfo = JSON.parse(item.hostInfo)
                                    if(!item.nodeConfigScript.disk_data && item.hostInfo.var_data_size<800 ){
                                        bootLocal = false;
                                    }
                                })
                                if(bootLocal){
                                    scope.storageDeviceList.unshift({
                                        "disPlayName":"本地盘"
                                    })
                                }
                            }
                        })
                    }
                    if(scope.storageDeviceList.length){
                        scope.instanceForm.storageDeviceSelected = scope.storageDeviceList[0];
                        if(editData.type=="edit"){
                            scope.storageDeviceList.forEach((item)=>{
                                if(item.disPlayName==editData.data.instance.disPlayName){
                                    scope.instanceForm.storageDeviceSelected = item;
                                }
                            })
                        }
                    } 
               }
            })
        }
      }])
      .directive("tableFilter", [function(){
        return {
            restrict: 'AE',
            scope:{
                tableName:"@",
                context:"=",
                curentFilter:"=",
                changeSelect:"="
            },
            template: `<div class="table-header-filter">
            <span>{{curentFilter.name}}</span>
            <div class="table-header-icon">
                <i class="icon-aw-down"></i>
                <ul class="table-header-dropdown" ng-class="{'open':tableHeaderOpen}">
                    <li ng-repeat="item in curentFilter.filter" ng-click="headerFilter(item)">{{item.name}}</li>
                </ul>  
            </div>
          </div>`,
            link:function(scope,ele,attr,ctrl){
                scope.headerFilter = function(item){
                    var table = scope.context.tableContent[scope.tableName]
                    table.filter(item.type);
                    var filterData = table.settings().getData(table);
                    scope.changeSelect?scope.changeSelect(filterData[0]||{}):"";
                };
                $(ele).find(".icon-aw-down").on("click",function(e){
                    $(this).siblings(".table-header-dropdown").toggleClass("open");
                    e.stopPropagation();
                    e.preventDefault();
                })
                $("html").on("click",function(){
                    $(ele).find(".table-header-dropdown").removeClass("open");
                })
            }
        }
    }])
    .directive("availquota_",function(){
        return {
            restrict:"A",
            require:"ngModel",
            link:function(scope,ele,attrs,creatProCtrl){	
                function tempVaildQuota(viewValue){
                    if(typeof(scope.quota.availquota) != undefined&&viewValue>scope.quota.availquota&&viewValue!=0){
                        creatProCtrl.$setValidity("availquota",false);
                    }else{
                        creatProCtrl.$setValidity("availquota",true);
                    }
                    return viewValue;
                }
                tempVaildQuota(scope.quota.hardLimit);
                creatProCtrl.$parsers.push(tempVaildQuota);
            }
        };
    });