let securityGroupSrvModule = angular.module("securityGroupSrvModule", []);
securityGroupSrvModule.service("securityGroupSrv", ["$http", function($http) {
    var static_v2_url = "/awstack-manage/v2/";
    var static_url = "/awstack-resource/v1";
    return {
        newGetFirewallTableData: function(data) {
            return $http({
                method: "GET",
                // headers:headers,
                url: static_v2_url + `security-groups?pageSize=${data.pageSize}&pageNum=${data.pageNum}&pattern=${data.likes||""}`
            });
        },
        getTemplateList:function(){
            return $http({
                method:"GET",
                url:static_v2_url + 'template/security-groups'
            })
        },
        //安全组改造之前的list api
        getFirewallTableData: function(headers) {
            return $http({
                method: "GET",
                headers:headers,
                url: static_url + "/security_groups"
            });
        },
        getTemplateRuleDetail:function(data,id){
            return $http({
                method:"GET",
                url: static_v2_url + 'template/security-groups/' + id,
                params:data
            })
        },
        addFirewallAction: function(options) {
            return $http({
                method: "POST",
                url: static_v2_url + "security-groups",
                data: options,
                headers:{
					"domain_id":localStorage.domainUid,
					"domain_name":encodeURI(localStorage.domainName),
					"project_id":localStorage.projectUid,
					"project_name":encodeURI(localStorage.projectName)
				}
            });
        },
        editFirewallAction: function(options) {
            return $http({
                method: "PUT",
                url: static_v2_url + "security-groups",
                data: options,
                headers:{
					"domain_id":localStorage.domainUid,
					"domain_name":encodeURI(localStorage.domainName),
					"project_id":localStorage.projectUid,
					"project_name":encodeURI(localStorage.projectName)
				}
            });
        },
        cloneSecurityGroup: function(options) {
            return $http({
                method: "POST",
                url: static_v2_url + "security-group/clone",
                data: options
            });
        },
        delFirewallAction: function(options) {
            return $http({
                method: "DELETE",
                url: static_v2_url + "security-groups",
                params: options,
                headers:{
					"domain_id":localStorage.domainUid,
					"domain_name":encodeURI(localStorage.domainName),
					"project_id":localStorage.projectUid,
					"project_name":encodeURI(localStorage.projectName)
				}
            });
        },
        getFirewallRuleDetail: function(data,id) {
            return $http({
                method: "GET",
                url: static_v2_url + `security-groups/${id}?pageSize=${data.pageSize}&pageNum=${data.pageNum}&pattern=${data.likes||""}&direction=${data.direction}`
            });
        },
        addFirewallRule: function(options) {
            return $http({
                method: "POST",
                url: static_v2_url + "security-group/rules",
                data: options
            });
        },
        editFirewallRule: function(options) {
            return $http({
                method: "PUT",
                url: static_v2_url + "security-group/rules",
                data: options
            });
        },
        enabledRule: function(options) {
            return $http({
                method: "PUT",
                url: static_v2_url + "security-group/rules/enable",
                params: options
            });
        },
        forbiddenRule: function(options) {
            return $http({
                method: "PUT",
                url: static_v2_url + "security-group/rules/disable",
                params: options
            });
        },
        firewallRuleRemove: function(options) {
            return $http({
                method: "DELETE",
                url: static_v2_url + "security-group/rules",
                params: options
            });
        },
        getDomianQuota: function(options) {
            return $http({
                method: "GET",
                url: "awstack-user/v1/quotas/",
                params: options
            });
        },
        getQuotaUsed: function(options,domainUid) {
            return $http({
                method: "GET",
                url: "awstack-user/v1/projectsofquotas/" + domainUid,
                params: options
            });
        },        
        getProQuotasUsages: function(options) {
            return $http({
                method: "GET",
                url: "awstack-user/v1/quotas_Usages/",
                params: options
            });
        },
        createSecurityGroups:function(options){
            return $http({
                method: "post",
                url: "/awstack-manage/v2/security-groups",
                data: options
            });
        },
        updateSecurityGroupRules:function(groupId){
            return $http({
                method:"PUT",
                url:static_url+"/security/"+groupId+"/rules",
            });
        },
        getRegionList:function(){
			return $http({
				method:"GET",
				url:`awstack-user/v1/enterprises/${localStorage.enterpriseUid}/regions`
			})
		},
		getDomainsList:function(regionKey){
			return $http({
				method:"GET",
				url:`awstack-user/v1/user/domains/projects`,
				headers:{
					"X-Register-Code":regionKey
				}
			})
		}
    };
}])