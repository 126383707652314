var tableService = angular.module("ObjectsSrv",[]);
tableService.service('ObjectsSrv', ['$rootScope', '$http', '$q', function($rootScope, $http, $q,backendSrv){
  var swift_url = '/developer/v1/objectstorage';
  var swiftTableAllData = [];
  var swiftSelected = [];

  var containerName = "";
  var pesudeDir = "";
  return {
	getObjectUserData:function() {
		return $http({
			method:'get',
			url:`awstack-user/v1/enterprise/${localStorage.enterpriseUid}/region/${localStorage.regionUid}/object/storage/conf?projectUid=${localStorage.projectUid}`,
			headers:{'Content-Type': 'application/json'}
		});
	},
  	getSwiftMeta:function(containerName) {
      var httpUrl =  "awstack-resource/v1/objectstorage" + '/objectmeta/' + containerName + '/g';
  		return $http({
  			method:'get',
  			url:httpUrl,
			headers:{'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
  		});
    },
    getSwiftMetaDoc:function(containerName, objectPathName) {
  		var httpUrl;
  		if ( null == containerName ) {
  		  httpUrl =  "awstack-resource/v1/objectstorage" ;
  		} else if ( null == objectPathName ) {
  		  httpUrl =  "awstack-resource/v1/objectstorage" + '/containermeta/' + containerName;
  		} else {
  		  httpUrl =  "awstack-resource/v1/objectstorage" + '/objectmeta/' + containerName + '/' + objectPathName;
  		}
  		return $http({
  			method:'get',
  			url:httpUrl,
			headers:{'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
  		});
    },
    getSwiftContainerList:function() {
      	return $http({
  			method:'get',
			url:"awstack-resource/v1/objectstorage/containerlist",
			headers:{'Content-Type': 'application/json'},
			params:{
				"storageId":$rootScope.storageId
			},
  		});
    },

    updateSwiftContainerDoc:function(containerName, bePublic) {
    	return $http({
    		method:'put',
    		url:"awstack-resource/v1/objectstorage" + '/container/' + containerName,
    		headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
			data:bePublic,
			params:{
				"storageId":$rootScope.storageId
			},
    	});
    },

    listObject:function(containerName) {
      var httpUrl = "awstack-resource/v1/objectstorage" + '/objectlist/' + containerName;
    	return $http({
    		method:'get',
    		url:httpUrl,
			headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
    	});
    },

    createSwiftPesudePath:function(containerName,subPesudeName) {
      var httpUrl = "awstack-resource/v1/objectstorage" + '/object/' + containerName + '/' + subPesudeName +"/";
    	return $http({
  			method:'post',
  			url:httpUrl,
			headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
    	});
    },

    downloadSwiftObject:function(containerName, objectName) {
      var httpUrl = "awstack-resource/v1/objectstorage" + '/objectdownlaod/' + containerName  + objectName;
      return $http({
        method:'get',
        url:httpUrl,
		headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
		params:{
			"storageId":$rootScope.storageId
		},
      });
    },

    deleteSwiftObject:function(objectPathName,objectLocation){
    	return $http({
  			method:'delete',
  			url:"awstack-resource/v1/objectstorage" + '/object/' + objectPathName +"/"+ objectLocation,
			headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
  		});
    },
    ///////////////////////////////////
    createSwiftContainer:function(newContainerName) {
    	return $http({
  			method:'post',
  			url:"awstack-resource/v1/objectstorage/" + 'container',
  			headers:{ 'Content-Type': 'application/json'},
			data:newContainerName,
			params:{
				"storageId":$rootScope.storageId
			},
    	});
    },
    deleteSwiftContainerDoc:function(containerName) {
    	return $http({
    		method:'delete',
    		url:"awstack-resource/v1/objectstorage" + '/container/' + containerName,
			headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
    	});
    },
    uploadInit:function(data,containerName,subPesudeName) {
      var httpUrl = "awstack-resource/v1/objectstorage" + '/object/multipart/init';
    	return $http({
    		method:'post',
			url:httpUrl,
			data:data,
			headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
    	});
    },
    uploadAll:function(data) {
      var httpUrl = "awstack-resource/v1/objectstorage" + '/object/multipart/upload/ALL';
    	return $http({
    		method:'post',
			url:httpUrl,
			data:data,
			headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
			params:{
				"storageId":$rootScope.storageId
			},
    	});
    },
  };
}])
