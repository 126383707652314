angular.module("recycleDbSrvModule",[])
    .service("recycleDbSrv",function($http) {
        return {
            getGcList:function (data) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/instance/gc",
                    params: data
                });
            }
        }
    })