filterTips.$inject = [];
function filterTips () {
    return {
        restrict: "E",
        scope: {
            clickFilterConfirm:"=",
            showFilteringTips:"=",
            showFilterCompleteTips:"=",
            searchTotal:"=",
            showExitFilterTips:"=",
            exitFilterInit:"&",
            query:"&"
        },
        templateUrl:'js/system/billingManagement/tmpl/filter-tips.html',
        link:function(scope, elem, attr,ngModel){
            let self = scope;
            // 退出筛选
            self.exitFilter = function(){
                // 点击退出筛选 筛选加载中提示隐藏的条件之一 
                self.showExitFilterTips = false;
                // 初始化筛选提示的条件
                self.exitFilterInit();
                // 刷新分页列表
                self.query();
            }
        }
    }
}

export { filterTips }