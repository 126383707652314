import "./cephSrv";
import { PiePanelDefault } from "../../chartpanel";
import { AreaPanelDefault } from "../../chartpanel";
import { cephAreaChartDefault} from "../../chartpanel";

angular.module("cephModule", ["ngSanitize", "phyHostSrv", "usersrv", "ui.bootstrap.tpls", "ngTable", "cephSrv"])
.controller("cephCtrl", function($scope, userDataSrv, NgTableParams, cephSrv, $translate, $routeParams, $timeout,$location) {
    //if(!localStorage.cephService) return;
    var self = $scope;
    self.outcephService =  localStorage.outcephService=='true'? "开启外部ceph监控":"对接ceph存储";
    self.menus=['系统管理','运维管理','存储管理'];
    // if(localStorage.permission == 'enterprise'){
    //     self.menus = ['系统管理','运维管理','存储管理']
    // }else{
    //     self.menus = ['系统管理','配置管理','存储管理']
    // }
    self.cephId = null;
    self.panels = {};
    self.cephList = [];
    self.dynamicPopover = {};
    self.clusterFilterData = {
        timeStep: '30m',
        queryType: '30m',
        from: null,
        to: null
    };
    self.poolFilterData = {
        timeStep: '30m',
        queryType: '30m',
        from: null,
        to: null
    };
    if(localStorage.permission == 'enterprise'){
        self.serviceMenus = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')]
    }else{
        self.serviceMenus = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_ConfigManagement'),$translate.instant('aws.menu.System_Storage')]
    }
    // 组件重新渲染
    self.isShow = true;
    self.linkTo = function(item){
        self.isShow = false;
        Promise.resolve(true).then(res=>{
            self.isShow = res
        })
        $location.url(`/monitor/cephview?id=${item.name}`)
    }

    self.changeCeph = function(v){
        self.panels = {};
        self.dynamicPopover = {};
        self.cephId =  {
            id:v.id,
            storageFirm:v.storageFirm
        };
        self.curTable = v.id;
        if(v.id != 666){
            // 获取ceph状态
            cephSrv.getCephStatus(self.cephId).then(function(result) {
                if (result && result.data) {
                    self.cephstatus = $translate.instant("aws.monitor.ceph.status." + result.data);
                }
            });
    
            cephSrv.getCephWarnig(self.cephId).then(function(result) {
                if (result && result.data) {
                    self.totalWarnings = result.total;
                    self.warnInfos = angular.fromJson(result.data);
                    self.dynamicPopover.warnTmplUrl = self.totalWarnings>0?"warningTable.html":"";
                }
            });
    
            // 获取monitor状态
            cephSrv.getMonitorStatus(self.cephId).then(function(result) {
                if (result && result.data) {
                    var monitorstatus = angular.fromJson(result.data);
                    self.monitorstatusavailable = monitorstatus.available.toString();
                    self.monitorstatustotal = monitorstatus.total.toString();
                    self.monMsgs = monitorstatus.monitors;
                    self.ifShow = self.monMsgs[0].prettyName == ""? true:false;
                    self.dynamicPopover.hostTmplUrl = self.monitorstatusavailable>0?"hostInfoTable.html":"";
                }
            });
    
            // 获取PG状态
            cephSrv.getPgStatus(self.cephId).then(function(result) {
                if (result && result.data) {
                    var item = angular.fromJson(result.data);
                    // { "pg_status": [{ "numbers": "704", "status": "active+clean" }], "pg_nums": "704" }
                    if (item) {
                        self.pg_nums = item.pg_nums;
                        var pgData = [];
                        for (var i = 0; i < item.pg_status.length; i++) {
                            pgData.push({
                                "name": item.pg_status[i].status,
                                "value": item.pg_status[i].numbers
                            });
                        }
                    }
                    self.pieshow = true;
                    self.PGStatusChart = new PiePanelDefault();
                    self.PGStatusChart.panels.data = pgData;
                    self.PGStatusChart.panels.pieType = "category";
                    self.pgCenterTex = function() {
                        var sum = 0,
                            active_clean_num = 0;
                        _.forEach(pgData, function(v) {
                            if (v.name === "active+clean") {
                                active_clean_num = v.value;
                            }
                            sum += v.value;
                        });
                        // return (active_clean_num / 1000).toFixed(1) + "K/" + (sum / 1000).toFixed(1) + "K";
                        return (active_clean_num / 1000).toFixed(1) + "K/" + (self.pg_nums / 1000).toFixed(1) + "K";
                    }();
                }
            });
    
            //磁盘存储pie
            cephSrv.getDiskUsage(self.cephId).then(function(result) {
                if (result && result.data) {
                    self.diskpieshow = true;
                    var item = angular.fromJson(result.data);
                    let total_gb = item.total_bytes/1024/1024/1024;
                    let used_gb = item.total_used_bytes/1024/1024/1024;
                    self.storageTotal = bytesToSize(total_gb);
                    storageChartFunc(used_gb, total_gb);
                }
            });
    
            userDataSrv.getRegionData().then(function(res) {
                // self.region_key = res.data[0].regionKey;
                self.region_key = localStorage.getItem('regionKey')
                if(self.cephId.storageFirm=='ceph'){
                    renderAreaChart(self.region_key,"cluster",self.clusterFilterData);
                }
            });
    
            getPoolInstance(self.cephId);
            getOsdInstance(self.cephId);
        }
    }

    function bytesToSize(bytes) {
        var k = 1024, // or 1000
            sizes = ["GB", "TB", "PB", "EB", "ZB", "YB"], // sizes = ['Bytes', 'KB', 'MB',"GB", "TB", "PB", "EB", "ZB", "YB"],
            i;
        if (bytes < 0) i = Math.floor(Math.log(-bytes) / Math.log(k));
        if (bytes >= 0 && bytes < 1) return { a: 0, b: "0 GB" };
        if (bytes >= 1)
            i = Math.floor(Math.log(bytes) / Math.log(k));
        //var gtZero = ((bytes / Math.pow(k, i)).toPrecision(3)) >= 0 ? ((bytes / Math.pow(k, i)).toPrecision(3)) : 0;
        //解决页面使用科学计算法展示数据的bug
        var gtZero = (bytes / Math.pow(k, i)).toPrecision(3) >= 0 ? (bytes / Math.pow(k, i)): 0;
        if(gtZero.toString().indexOf(".")>-1) {
            if(gtZero.toString().split(".")[1].length>2){
                gtZero = gtZero.toFixed(2)
            }
        }
        return {
            a: (bytes).toPrecision(3),
            b: gtZero + " " + sizes[i]
        };
    }

    function storageChartFunc(used, total) {
        self.storageChart = new PiePanelDefault();
        self.storageChart.panels.data = [
            { name: $translate.instant("aws.overview.inUsed"), value: bytesToSize(used).a, valueB: bytesToSize(used).b },
            { name: $translate.instant("aws.overview.unUsed"), value: bytesToSize(total - used).a, valueB: bytesToSize(total - used).b }
        ];
        self.storageChart.panels.colors = ["#1ABC9C", "#e5e5e5"];
        self.storageChart.panels.pieType = "percent";
    }

    //获取pools表格数据
    function getPoolInstance(cephId) {
        cephSrv.getData(cephId).then(function(result) {
            result ? self.loadData = true : "";
            if (result && result.data) {
                var tableData = result.data;
                _.forEach(tableData,function(item){
                    item.rate =(item.used_gb*100/(item.used_gb+item.space_gb)).toFixed(2);
                })
                self.tableParamsPools = new NgTableParams({ count: 5 }, { counts: [], dataset: tableData });
            }
        });
    }

    //获取osd表格数据
    function getOsdInstance(cephId) {
        cephSrv.getOsdData(cephId).then(function(result) {
            if (result && result.data) {
                self.tableParamsOsds = new NgTableParams({ count: 5 }, { counts: [], dataset: result.data });
            }
        });
    }

    self.arr = []; //页面
    self.alertArr = []; // 弹窗
    function renderAreaChart(region_key, type, filterData,target, nowMainType){
        self.panelsData = {};
        var cephChartPermas = new cephAreaChartDefault(region_key,target,nowMainType);
        var count = 0;
        const p = [];
        for(let key in cephChartPermas[type+"ChartSqls"]){
            self.panelsData[key] = [];
            count++;
            _.each(cephChartPermas[type+"ChartSqls"][key], function(item){
                p.push(panelsDataFunc(item,key,count,filterData))
            })
        }
        Promise.all(p).then(res=>{
            if(type == 'pool'){
                self.alertArr = res
            } else {
                self.arr = res
            }
        })
    }

    function panelsDataFunc(item,key,color,filterData,){
        return new Promise((resolve)=>{
            self.panelsData[key] = (self.panelsData[key]).slice((self.panelsData[key]).length, 0);
            var areaChart = new AreaPanelDefault();
            areaChart.panels.title = $translate.instant("aws.monitor." + item.chartPerm.title);
            areaChart.panels.unit = item.chartPerm.unit;
            areaChart.panels.priority = item.chartPerm.priority;
            if(color % 2 == 0){
                areaChart.panels.colors = ["#51a3ff"];
            } else {
                areaChart.panels.colors = ["#1bbc9d"];
            }
            
            let params = {
                "chart": item.chartPerm.title,
                "region": self.region_key,
                "queryType": filterData.queryType,
                "startTimeMillis": filterData.from ? moment(filterData.from).valueOf() : null,
                "endTimeMillis": filterData.to ? moment(filterData.to).valueOf() : null
            };
            self.poolName && (params.poolName = self.poolName);
            cephSrv.sqlQuery(params, self.cephId).then(function(res){
                if(res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                    areaChart.panels.data.push(res.data.results[0].series[0]);
                } else {
                    var defaultChartData = {
                        "columns":["time", item.chartPerm.title],
                        "values":[
                            [moment().subtract(30,"m"), 0],
                            [moment(), 0]
                        ],
                        "default":true
                    };
                    areaChart.panels.data.push(defaultChartData);
                }

                const { title, unit, data } = areaChart.panels;
                const allTime = data[0].values.map(item => item[0]);
                
                const xAxis = allTime.map(item => moment(item).format("YYYY-MM-DD HH:mm:ss"));
                const series = data[0].columns.map((item, index) => {
                    return {
                        type: "line",
                        name: item,
                        data: data[0].values.map(item => item[index])
                    }
                })
                series.splice(0, 1);
                resolve({
                    title,
                    data: {
                        legend: {
                            show: true,
                            orient: 'horizontal',
                            top: 'bottom',
                            left: 10,
                        },
                        gridBottom:'28%',
                        toolboxShow: false,
                        unit,
                        xAxis,
                        series
                    }
                })
            })
        })
    }

    self.defaultType = 'average';
    self.$on('main_type', function(e, value){
        self.defaultType = value;
    })

    self.clusterChartQuery = function() {
        renderAreaChart(self.region_key,"cluster",self.clusterFilterData, '',self.defaultType=="peak");
    };

    self.poolsTab = function() {
        getPoolInstance(self.cephId);
    };

    self.osdTab = function() {
        getOsdInstance(self.cephId);
    };
    /*获取开启监控ceph列表*/
    cephSrv.getCephList().then(res=>{
        const obj = {
            name:$translate.instant("aws.tmpl.header.cacheMonitoring"),
            id:666
        }
        // /awstack-user/v1/storage/monitor
        if(res&&res.data&&res.data.length>0){
            self.cephList = res.data;
            self.cephList.forEach(item=>{
                if(item.storageFirm=="bcache"){
                    item.name = $translate.instant("aws.tmpl.header.cacheMonitoring");
                    item.id = 666;
                }
            })
            // self.cephList.push({
            //     ...res.data[0],
            //     ...obj
            // })
            self.changeCeph(self.cephList[0]);
        }
        
    })

    self.$watch(function () {
        return $routeParams.id;
    }, function (value) {
        if(value){
            self.$broadcast('clearTimeout',self.clusterFilterData);
        }else{
            self.$broadcast('clearTimeout',self.poolFilterData);
        }
    });
    
    //获取PG详情的数据
    self.$on("getDetail", function(event, value) {
        self.poolFilterData = {
            timeStep: '30m',
            queryType: '30m',
            from: null,
            to: null,
            precision: null,
            definedTimeText:$translate.instant('aws.bill.chooseDate')
        };
        self.poolName = value;
        userDataSrv.getRegionData().then(function(res) {
            if (res && res.data && res.data[0] && res.data[0].regionKey) {
                res.data = res.data.filter(item => {
                    return item.status == 3;
                });
                self.region_key = res.data[0].regionKey;
                _.each(res.data, item => {
                    if (item.regionKey == localStorage.regionKey) {
                        self.region_key = item.regionKey;
                    }
                });
                renderAreaChart(self.region_key, "pool", self.poolFilterData, value);
            }
        });

        self.poolChartQuery = function() {
            renderAreaChart(self.region_key, "pool", self.poolFilterData, value,self.defaultType=="peak");
        };
    });
}).controller("cephmonitoring",['$scope','$rootScope','cephSrv','$translate',function($scope,$rootScope,cephSrv,$translate){
    const self = $scope;
    self.nodeList = [];
    self.aggregationList = [];

    self.thecachemonitorList = {};
    self.left = {};
    self.right = {};

    self.clusterFilterData = {
        timeStep: '30m',
        queryType: '30m',
        from: null,
        to: null
    };

    self.filterData = {
        timeStep: "",
        from: "",
        to: "",
        rangeHours: 0.5,
        // precision: self.collectCycleList[0].value,
        definedTimeText: $translate.instant('aws.bill.chooseDate'),
        selectedZonetype: {},
        selectedZonehost: {}
    };

    self.MainTypeValue = [
        { name: $translate.instant('aws.indextran.average'), value: "average" },
        { name: $translate.instant('aws.indextran.peak'), value: "peak" },
    ]
    // 默认值
    self.$on('main_type',function(e,value){
        self.wdDefaultValue = value
    })
    // 获取节点，聚合盘下拉列表
    Promise.all([cephSrv.getNodeHost(),cephSrv.getPolymerplate()]).then(res=>{
        if(res[0]){
            // 主机节点
            self.nodeList = res[0].data.map(item=>{
                return {
                    name: item.host,
                    value: item.host_id
                }
            })
            self.defaultValue = self.nodeList[0].value;
            // 聚合盘
            self.aggregationList = res[1].data.map(item=>{
                return {
                    name: item.bcache_dev,
                    value: item.bcache_dev
                }
            })
            self.aggregationValue = self.aggregationList[0].value;
            self.query()
        }
    })

    self.query = function(){
        const { queryType,from,to } = self.clusterFilterData
        const wdType = self.wdDefaultValue == 'average'
        
        const config = {
            "endTimeMillis": (+new Date(from)) * 1000,
            "queryType": queryType || '',
            "region": localStorage.regionKey,
            "startTimeMillis": (+new Date(to)) * 1000,
            "hostId": self.defaultValue,
            "bcacheDev": self.aggregationValue
        }
        
        Promise.all([cephSrv.getCephMonitoring({
            ...config,
            "chart": wdType ? "bcache_avg": 'bcache_peak'
        }),cephSrv.getCephMonitoring({
            ...config,
            "chart": wdType ? "cache_hit_ratio_avg": 'cache_hit_ratio_peak'
        }),cephSrv.getCephMonitoring({
            ...config,
            "chart": wdType ? "dirty_data_avg": 'dirty_data_peak'
        })]).then(res => {
            self.thecachemonitorList = null
            self.left = null
            self.right = null
            if(res[0]&&res[0].data){
                const res1 = res[0].data['results'][0]['series'];
                if(res1){
                    self.thecachemonitorList = chartData(res1[0],'cache')
                }
            }
            if(res[1]&&res[1].data){
                const res2 = res[1].data['results'][0]['series'];
                if(res2){
                    self.left = chartData(res2[0], 'IO')
                }
            }
            if(res[2]&&res[2].data){
                const res3 = res[2].data['results'][0]['series'];
                if(res3){
                    self.right = chartData(res3[0], 'dirty')
                }
            }
        })
    }

    function chartData(res,type){
        const {columns,name,values} = res
        const xAxis = values.map(items=>moment(items[0]).format("YYYY-MM-DD HH:mm:ss"))
        const typeStatus = type != 'cache';
        const series = columns.map((items, index)=>{
            return {
                name:items,
                type: 'line',
                // stack: $translate.instant('aws.monitor.gross'),
                data: type == 'dirty'? values.map(item => item[index] / 1024 / 1024) :values.map(item => item[index]),
                areaStyle: typeStatus ? {color:'#d8e4fe'}: null,
                lineStyle: typeStatus ? {color:'#6d9bf9'}: null,
                itemStyle: typeStatus ? {color:'#6d9bf9'}: null
            }
        })
        series.shift()
        series.forEach((item,i)=>{
            item.data.forEach((data,index)=>{
                if(type == 'cache'){
                    item.data[index] = Math.floor(data)
                }else{
                    item.data[index] = data.toFixed(2)
                }
            })
        })

        return {
            title: type == 'cache' ?$translate.instant('aws.tmpl.header.cacheMonitoring') :type == 'IO' ?$translate.instant('aws.tmpl.header.cacheIO'): $translate.instant('aws.tmpl.header.dirtydata'),
            data:{
                legend: {
                    show: true,
                    orient: 'horizontal',
                    top: 'bottom',
                    left: 10,
                },
                series,
                toolboxShow:false,
                unit:type == 'cache'? $translate.instant('aws.overview.individual'):type == 'IO' ? '': 'M',
                xAxis
            }
        }
    }

    self.changeValue = function(type,value){
        if(type == 'fz'){
            self.wdDefaultValue = value
        } else if(type == 'jhp') {
            self.aggregationValue = value
        } else if(type == 'host') {
            self.defaultValue = value
        }
    }
    

    

    self.timeType = {
        options: [{
            "name": $translate.instant('aws.bill.timeType.realTime'),
            "value": ""
        }, {
            "name": $translate.instant('aws.bill.timeType.sixHours'),
            "value": "6h"
        }, {
            "name": $translate.instant('aws.bill.timeType.oneDay'),
            "value": "1d"
        }, {
            "name": $translate.instant('aws.bill.timeType.threeDays'),
            "value": "3d"
        }, {
            "name": $translate.instant('aws.bill.timeType.oneWeek'),
            "value": "7d"
        }]
    };
}])
 