modifyHostNameCtrl.$inject = ["$scope", "$rootScope", "$uibModalInstance", "$translate", "$timeout","editData","nodeSrv","context"];
export function modifyHostNameCtrl($scope, $rootScope, $uibModalInstance, $translate, $timeout,editData,nodeSrv,context){
   var self = $scope;
   self.submitInValid=false;
   self.patternMsg= $translate.instant("aws.node.hostNamePatternMsg");
   self.prettyName = editData.prettyName;
   self.interacted= function(field) {
        if (field) {
            return self.submitInValid || field.$dirty;
        }
   };
   self.modifyConfirm=function(modifyHostNameForm){
        if(modifyHostNameForm.$valid){
            $uibModalInstance.close();
            let regionUid=JSON.parse(localStorage.$LOGINDATA).regionUid;
            var params={
               nodeUid:editData.nodeUid,
               prettyName:self.prettyName
            };
            nodeSrv.setHostPrettyName(regionUid,params).then(function(){
               context.refreshNodes();
            });
        }else{
            self.submitInValid=true;
        }
   };
}