rbackupsSrv.$inject = ["$rootScope", "$http"]
function rbackupsSrv($rootScope, $http) {
    var static_url2="awstack-resource/v2/";
    return {
        getBackupsList:function(params) {
            return $http({
                method: "get",
                url: static_url2 + "backup/jobs",
                params:params
            });
        },
        getBackupsContainList:function(jobId,params) {
            return $http({
                method: "get",
                url: static_url2+"job/"+jobId + "/backups",
                params:params
            });
        },
        createBackups:function(data,params){
            return $http({
                method: "POST",
                url: static_url2 + "backup/jobs",
                data:data,
                params:params
            });
        },
        getStrategyList:function(params) {
            return $http({
                method: "GET",
                url: static_url2 + "backup/policys",
                params:params
            });
        },
        getCreateBackupInsList:function() {
            return $http({
                method: "GET",
                url: "awstack-resource/v1/projectservers"
            });
        },
        getCreateBackupVolumeOfIns:function(insUid,params) {
            return $http({
                method: "GET",
                url: static_url2 + "server/"+insUid+"/backup/volumes",
                params:params
            });
        },
        relieveBackup:function(jobId) {
            return $http({
                method: "PUT",
                url: static_url2 + "backup/jobs/"+jobId
            });
        },
        delete:function(params) {
            return $http({
                method: "DELETE",
                url: static_url2 + "backup/jobs",
                params:params
            });
        },
        getExportUrl: function (data) {
            return $http({
                method: "POST",
                url: static_url2 + "backup/jobs/export/url",
                data:data
            });
        },

        backupType:"system"
    }
}
export {rbackupsSrv}