backupServiceConfigCtrl.$inject = ['$scope','$uibModal','serviceConfigSrv','alertSrv','$translate','$rootScope','$timeout','$http','NgTableParams','$route']
function backupServiceConfigCtrl($scope,$uibModal,serviceConfigSrv,alert,$translate,$rootScope,$timeout,$http,NgTableParams,route) {

    var self = $scope;
    //初始化定义
    function initData(){
        // 初始化定义变量
        self.showTopTitle = true;
        //定义标签显示
        self.icon = {
            //连接中
            connectionLoad:false,
            //连接成功
            connectionYes:false,
            //连接失败
            connectionWrong:false,
        };
        // 断开连接
        self.stoppedWrong = false;
        //按钮控制
        self.button = {
            saveConfig: false,
            deleteConfig: false,
            syncData: false,
            syncStrategy: false,
        };
        // 输入框控制
        self.indexDisabled = false,
        // 数据回显
        // 管理员密码有效期
        self.accountPasswordValidity = 0,
        // 安全员密码有效期
        self.securityPasswordValidity = 0,
        self.backupData = {
            id:"",
            ipAddress:"",
            accountNumber:"",
            accountPassword:"",
            securityNumber:"",
            securityPassword:"",
            status:"",
        };
        self.connectionShow = false
    }
    //关闭上侧条
    self.close = function(){
        self.showTopTitle = false;
    };

    self.submitted = false;
    self.interacted = function(field) {
        if(field){
            self.field_form = field;
            return self.submitted || field.$dirty;
        }
    };
    //测试连接
    self.connection = function(option){
        if(self.backupData.status == 5 || self.backupData.status == 6 || self.backupData.status == 7){
            return
        }
        // if(!self.indexDisabled){
            // 参数校验
            if(self.field_form.$valid){
                // 测试中
                self.icon = {
                    //连接中
                    connectionLoad:true,
                    //连接成功
                    connectionYes:false,
                    //连接失败
                    connectionWrong:false,
                };
                self.stoppedWrong = false
                // 输入框锁定
                self.indexDisabled = true
                // 封装入参
                var postData={
                    "abAddress" : option.ipAddress.$viewValue,
                    "abSystemUserName" : option.accountNumber.$viewValue,
                    "abSystemPassword" : option.accountPassword.$viewValue,
                    "abSecurityUserName" : option.securityNumber.$viewValue,
                    "abSecurityPassword" : option.securityPassword.$viewValue,
                }
                // 请求测试连接接口
                serviceConfigSrv.connectionWay(postData).then(function (result) {
                    self.icon.connectionLoad = false
                    if (result && result.status == 0 && result.data!=null) {
                        // 成功
                            //按钮控制
                            self.button = {
                                saveConfig: true,
                                deleteConfig: false,
                                syncData: false,
                                syncStrategy: false,
                            };
                            //连接成功
                            self.icon = {
                                //连接中
                                connectionLoad:false,
                                //连接成功
                                connectionYes:true,
                                //连接失败
                                connectionWrong:false,
                            };
                    }else {
                        //连接失败
                        self.icon = {
                            //连接中
                            connectionLoad:false,
                            //连接成功
                            connectionYes:false,
                            //连接失败
                            connectionWrong:true,
                        };
                        // 输入框解除锁定
                        self.indexDisabled = false
                    }
                });
            }else{
                self.submitted = true;
            }
        // } 
    }  
    //保存配置
    self.saveConfig =function(option){
        self.button.saveConfig = false
        // 封装入参
        var postData={
            "abAddress" : option.ipAddress.$viewValue,
            "abSystemUserName" : option.accountNumber.$viewValue,
            "abSystemPassword" : option.accountPassword.$viewValue,
            "abSecurityUserName" : option.securityNumber.$viewValue,
            "abSecurityPassword" : option.securityPassword.$viewValue,
        }
        // 请求保存配置接口
        serviceConfigSrv.saveConfigWay(postData).then(function (result) {
            initConfigInfo()
        })
    }
    //删除配置页面
    self.deleteConfig = function() {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/advanced-function/backup/service-config/tmpl/delete-config.html",
            controller:"deleteConfigCtrl",
            scope:self,
            resolve: {
                initConfig: function() {
                    return initConfigInfo;
                },
            }
        });
    }
    //数据同步策略页面
    self.syncStrategy = function() {
        serviceConfigSrv.getConfigInfo().then(function (result) {
            if(result && result.status == 0 && result.data!=null){
                if(result.data.status==4){
                    showTotal(result.data.status)
                }else{
                    $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: "js/advanced-function/backup/service-config/tmpl/sync-strategy.html",
                        controller:"syncStrategyCtrl",
                        scope:self,
                        resolve: {
                            initConfig: function() {
                                return self;
                            },
                        }
                    });
                }
            }
        })
        
    }
    //修改密码页面
    self.changePassword = function(data){
        self.changePasswordType =data
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/advanced-function/backup/service-config/tmpl/change-password.html",
            controller:"changePasswordCtrl",
            scope:self,
            resolve: {
                initConfig: function() {
                    return initConfigInfo;
                },
            }
        });
    }
    // 数据同步方法
    self.syncData = function(option){
        serviceConfigSrv.getConfigInfo().then(function (result) {
            if(result && result.status == 0 && result.data!=null){
                if(result.data.status ==2 || result.data.status==4){
                    showTotal(result.data.status)
                }else{
                    // 参数校验
                    if(self.field_form.$valid){
                        var id = self.backupData.id
                        serviceConfigSrv.executeSync(id).then(function (result) {
                            // initConfigInfo()
                        })
                    }else{
                        self.submitted = true;
                    }
                } 
            }
        })
    }
    function showTotal(status){
        if(status == 2){
            // 同步中
            alert.set(status, $translate.instant("aws.backupService.serviceConfig.index.syncStrategy"),"warning", 5000);
        }else if(status == 4){
            // 部署中
            alert.set(status, $translate.instant("aws.backupService.serviceConfig.index.syncData"),"warning", 5000);
        }
    }
    function passwordValidity(param) {
        var postData = {
            "abAddress": param.data.abAddress,
            "abSystemUserName":param.data.abSystemUserName,
            "abSystemPassword": param.data.abSystemPassword,
            "abSecurityUserName": param.data.abSecurityUserName,
            "abSecurityPassword": param.data.abSecurityPassword,
            "enterpriseUid":param.data.enterpriseUid,
            "regionUid":param.data.regionUid
        }
        serviceConfigSrv.getPasswordValidity(postData).then(function (result) {
            if(result && result.status == 0 && result.data!=null){
                    // 管理员密码有效期
                    self.accountPasswordValidity = result.data.adminPassAllowDays,
                    // 安全员密码有效期
                    self.securityPasswordValidity = result.data.securityPassAllowDays
            }else{
                // 管理员密码有效期
                self.accountPasswordValidity = 0,
                // 安全员密码有效期
                self.securityPasswordValidity = 0
                self.stoppedWrong = true
            }
        })
    }
    //定义主页面方法
    function initConfigInfo() {
        // 请求获取详情接口
        serviceConfigSrv.getConfigInfo().then(function (result) {
            if(result && result.status == 0 && result.data!=null){
                // 数据回显
                self.backupData = {
                    id:result.data.id,
                    ipAddress:result.data.abAddress,
                    accountNumber:result.data.abSystemUserName,
                    accountPassword:result.data.abSystemPassword,
                    securityNumber:result.data.abSecurityUserName,
                    securityPassword:result.data.abSecurityPassword,
                    status:result.data.status,
                };
                //按钮控制
                self.button = {
                    saveConfig: false,
                    deleteConfig: true,
                    syncData: true,
                    syncStrategy: true,
                };
                 //连接成功
                self.icon = {
                    //连接中
                    connectionLoad:false,
                    //连接成功
                    connectionYes:true,
                    //连接失败
                    connectionWrong:false,
                };
                 // 输入框锁定
                self.indexDisabled = true
                if(result.data.status == 5 || result.data.status == 6 || result.data.status == 7 ){
                    //判断密码状态
                    //连接失败
                    self.icon = {
                        //连接中
                        connectionLoad:false,
                        //连接成功
                        connectionYes:false,
                        //连接失败
                        connectionWrong:true,
                    };
                }else{
                    // 封装入参
                    var postData={
                        "abAddress" : result.data.abAddress,
                        "abSystemUserName" : result.data.abSystemUserName,
                        "abSystemPassword" : result.data.abSystemPassword,
                        "abSecurityUserName" : result.data.abSecurityUserName,
                        "abSecurityPassword" :result.data.abSecurityPassword,
                    }
                    // 请求测试连接接口
                    serviceConfigSrv.connectionWay(postData).then(function (res) {
                        self.icon.connectionLoad = false
                        if (res && res.status == 0 && res.data!=null) {
                            passwordValidity(result)
                        }else {
                            //连接失败
                            self.icon = {
                                //连接中
                                connectionLoad:false,
                                //连接成功
                                connectionYes:false,
                                //连接失败
                                connectionWrong:true,
                            };
                            // 输入框解除锁定
                            self.indexDisabled = false
                        }
                    })
                }
            }else{
                initData()
            }
        })
    }
    initConfigInfo()
}
export{backupServiceConfigCtrl};