recoverySrv.$inject = ["$rootScope", "$http"]
function recoverySrv($rootScope, $http) {
    var static_url="awstack-resource/v2/";
    return {
        //获取备份job列表
        getBackupsList:function(params) {
            return $http({
                method: "get",
                url: static_url + "backup/jobs",
                params:params
            });
        },
        //获取备份job里面的备份列表
        getBackupsContainList:function(jobId,params) {
            return $http({
                method: "get",
                url: static_url+"job/"+jobId + "/backups",
                params:params
            });
        },
        //获取备份恢复记录
        getBackupsJobRecord:function(jobId,params) {
            return $http({
                method: "get",
                url: static_url+"job/"+jobId + "/backups/restores",
                params:params
            });
        },
        //获取备份执行记录
        getBackupsExecuteRecord:function(jobId,params) {
            return $http({
                method: "get",
                url: static_url+"job/"+jobId + "/backup/results",
                params:params
            });
        },
        backupRecovery:function(backupId) {
            return $http({
                method: "POST",
                url: static_url+"backups/"+backupId + "/restore"
            });
        },
        getExportUrl: function (data) {
            return $http({
                method: "POST",
                url: static_url + "backup/jobs/export/url",
                data:data
            });
        },
        backupType:"system",
        backupsDetailTableData:''
    }
}
export {recoverySrv}