import "../services/vmCloneSrv"
vmCloneCtrl.$inject=["$scope", "$rootScope", "$translate","$uibModalInstance","$location","$timeout","editData","NgTableParams","vmCloneSrv","vmFuncSrv",'instancesSrv',"routersSrv","commonFuncSrv","cloneDiskInfo","utilTool"];
export function vmCloneCtrl($scope, $rootScope, $translate,$uibModalInstance,$location,$timeout,editData,NgTableParams,vmCloneSrv,vmFuncSrv,instancesSrv,routersSrv,commonFuncSrv,cloneDiskInfo,utilTool){
    var self = $scope;
    self.stepOneActive = true;
    self.stepTwoActive = false;
    self.departmentList = [];
    self.projectList = [];
    self.securityGroupList = [];
    self.specificationList = [];
    self.submitInValid = false;
    self.form = {
        diskSel: 0,
        hostName: editData.name + $translate.instant("aws.instances.addinstances.hostNameSuffix"),
        number: 1,
        securityGroup: "",
        specification: "",
        domain: {},
        project: {}
    }
    self.isProjectChange = false;
    self.isSuperAdmin = localStorage.managementRole != 2 ? false : true;
    self.volumesSizeInfo = {
        sizeShow: false,
        sizeShowTip: ""
    }
   

    // 部门的change事件
    self.changeDomain = function(value) {
        self.projectList = value.projects;
        self.form.project = value.projects[0];
        self.isProjectChange = true;
        self.changeProject(self.form.project)
    },

    // 项目的change事件
    self.changeProject = function(value) {
        let { domainName, domainUid, projectName, projectId } = self.dealDataInfo(self.form.domain,value);
        getSecurityList(domainName,domainUid,projectName,projectId);
        self.isProjectChange = true;
    }

    // 数量减
    self.reduceNum = function() {
        if(self.form.number<=1) {
            return
        }
        self.form.number--;
        checkVolumesSize(cloneDiskInfo);
    }

    // 数量加
    self.addNum = function() {
        if(self.form.number >= 10) {
            return
        }
        self.form.number++;
        checkVolumesSize(cloneDiskInfo);
    }

    // 关闭云主机克隆弹窗
    self.cancelVmCloneModal = function() {
        $uibModalInstance.close();
    }

    // 上一步 重置状态
    self.lastStepClick = function() {
        self.stepOneActive = true;
        self.stepTwoActive = false;
        self.isProjectChange = false;
    }

    // 下一步 修改状态；判断是否修改了部门和项目，是否需要调网络的接口 
    self.nextStepClick = function(filed) {
        if(filed.$valid) {
            self.stepOneActive = true;
            self.stepTwoActive = true;
            if(self.isProjectChange) {
                getNetWorkList();
            }
        }else{
            self.submitInValid = true;
        }
    }

    // 确定提交
    self.confirm = function(field){
        if(field.$valid && !self.volumesSizeInfo.sizeShow){
            let data = dealConfirmData();
            vmCloneSrv.cloneVmService(data).then(function() {

            })
            $uibModalInstance.close()
        }else{
            self.submitInValid = true;
        }  
    }
    
    getProjectList();
    getFlavorsList();

    // 获取项目和部门列表
    function getProjectList() {
        vmCloneSrv.getProject().then(function(result) {
            if(result && result.data && angular.isArray(result.data)) {
                self.departmentList = angular.copy(result.data)
                self.departmentList = getName(self.departmentList);
                let initDepartmentList = self.departmentList.filter(item=>{
                    return item.domainUid == editData.domainUid;
                })
                self.form.domain = initDepartmentList[0];
                self.changeDomain(self.form.domain);
            }
        })
    }

    // 获取安全组列表
    function getSecurityList(domainName,domainUid,projectName,projectId) {
        let data = {
            pageNum: 1,
            pageSize: 999999,
        }
        data = Object.assign(data,getData(domainName,domainUid,projectName,projectId));
        self.securityGroupLis = [];
        vmCloneSrv.getSecurityGroups(data).then(function(result){
            if (result && result.data && angular.isArray(result.data)) {
                self.securityGroupList = result.data;
                self.form.securityGroup = self.securityGroupList[0];
            }
        })
    }

    // 获取规格列表
    function getFlavorsList() {
        instancesSrv.getFlavors().then(function(result) {
            if(result && result.data && angular.isArray(result.data)) {
                for (let i = 0; i < result.data.length; i++) {
                    result.data[i].name = `${result.data[i].name}(CPU:${result.data[i].vcpus}${$translate.instant("aws.instances.addinstances.core")} ${$translate.instant("aws.instances.addinstances.memory")}:${(result.data[i].ram)/1024}G)`
                }
                self.specificationList = result.data;
                self.specificationList.forEach(item=>{
                    if(editData.flavorId == item.id) {
                        self.form.specification = item;
                    }
                })
            }
        })
    }

    // 获取私网的数据
    function getNetWorkList() {
        commonFuncSrv.setAssignIpFun(self, "cloneNet", "step2Form","vmClone");
    }

    // 处理ipv4和ipv6的ip地址数据
    function dealIpData() {
        let fixedIp = ''
        if(self.cloneNet.ipVersion == 4) {
            if(Object.keys(self.cloneNet.v4List).length>0) {
                fixedIp = self.cloneNet.v4List.fixedIp;
            }
        }else {
            if(Object.keys(self.cloneNet.v6List).length>0) {
                fixedIp = self.cloneNet.v6List.fixedIp;
            }
        }
        return fixedIp;
    }

    // 处理确定提交的数据
    function dealConfirmData() {
        let data = {
            cloneType: Number(self.form.diskSel),
            count: Number(self.form.number),
            name: self.form.hostName,
            sourceDomain: editData.domainUid,
            sourceProject: editData.projectUid,
            targetDomain: self.form.domain.domainUid,
            targetProject: self.form.project.projectId,
            securityId: self.form.securityGroup.uid,
            serverId: editData.uid,
            enterpriseUid: localStorage.enterpriseUid,
            flavor: self.form.specification.id,
            networkId: self.cloneNet.selectedNet.id,
            subnetId: self.cloneNet.selectedSubnet.id,
            fixedIp: dealIpData()

        }
        // 未指定子网时 删除subnetId和fixedIp
        if(!self.cloneNet.assignSub) {
            delete data.subnetId;
            delete data.fixedIp;
        }
        // 未指定ip地址时 删除fixedIp
        if(!self.cloneNet.assignIP) {
            delete data.fixedIp;
        }
        return data;
    }

    // 处理项目和部门   "默认部门"处理为"default"  "默认项目"处理为admin
    self.dealDataInfo = function(domain,project) {
        let domainName = domain.domainName === $translate.instant("aws.common.defaultDepar") ? 'default' : domain.domainName;
        let domainUid = domain.domainUid;
        let projectName = project.projectName === $translate.instant("aws.common.defaultProject") ? 'admin' : project.projectName;
        let projectId = project.projectId;
        return { domainName, domainUid, projectName, projectId }
    }

    // 处理数据
    function getData(domainName,domainUid,projectName,projectId) {
        let data = {
            domain_name: domainName,
            domain_id: domainUid,
            project_name: projectName,
            project_id: projectId
        }
        return data;
    }

    // 处理项目和部门  "default"处理为"默认部门"  "admin"处理为"默认项目"
    function getName (list) {
        for (let i = 0; i < list.length; i++) {
            if(list[i].domainUid === 'default') {
                list[i].domainName = $translate.instant("aws.common.defaultDepar");
            }else {
                list[i].domainName = list[i].domainName;
            }
            if(list[i].projects) {
                for (let j = 0; j < list[i].projects.length; j++) {
                    if(list[i].projects[j].projectName === 'admin' && list[i].domainUid === 'default') {
                        list[i].projects[j].projectName = $translate.instant("aws.common.defaultProject");
                    }else {
                        list[i].projects[j].projectName = list[i].projects[j].projectName;
                    }
                }
            }
        }
        return list
    }

    // 校验克隆云主机的存储容量是否充足 
    function checkVolumesSize(cloneDiskInfo) {
        // 得到一个二维数组 [[{}],[{}]]
        let cloneDiskList = utilTool.classifyArr(cloneDiskInfo,"type");
        // 两次循环取值
        for (let i = 0; i < cloneDiskList.length; i++) {
            let totalCapacityGb = 0; //存储的总容量
            let allocatedCapacityGb = 0; //存储已经分配的容量
            let size = 0; //某一类型存储的大小的总和
            let type = ""; //存储的类型
            for (let j = 0; j < cloneDiskList[i].length; j++) {
                totalCapacityGb = cloneDiskList[i][j].capabilities.total_capacity_gb;
                allocatedCapacityGb = cloneDiskList[i][j].capabilities.allocated_capacity_gb;
                size  += cloneDiskList[i][j].size*1;
                type = cloneDiskList[i][j].type;
            }
            if((size*self.form.number + allocatedCapacityGb) > totalCapacityGb) {
                self.volumesSizeInfo.sizeShow = true;
                self.volumesSizeInfo.sizeShowTip = `${type}${$translate.instant("aws.common.noCapacity")}`;
                // 跳出循环 页面提示某一类型的存储容量不足
                break;
            }else{
                self.volumesSizeInfo.sizeShow = false;
                self.volumesSizeInfo.sizeShowTip = "";
            }
        }
    }
    checkVolumesSize(cloneDiskInfo);
}


