 import "../services/cephViewSrv"

cephViewInfoCtrl.$inject = ["$rootScope", "$scope", "NgTableParams","$uibModal","cephViewSrv","$routeParams","$filter","$translate","dragDiskCache","alertSrv"];
infoBalanceDataCtrl.$inject = ["$rootScope", "$scope","cephViewSrv","$uibModalInstance","item","postFunc","getCephTasks","getCephList","diskType","bcache"];
infoAddBalanceDataCtrl.$inject = ["$rootScope", "$scope","cephViewSrv","$uibModalInstance","item","postFunc","diskType","getCephTasks","getCephList","$routeParams","bcache"];
function cephViewInfoCtrl($rootScope, $scope,NgTableParams,$uibModal,cephViewSrv,$routeParams,$filter,$translate,dragDiskCache,alertSrv){
    var self = $scope;
    self.hasTask = false;
    self.isNew = false;
    self.isDiskInvalid = false;
    self.invalidMessage = $translate.instant('aws.system.storagement.storageTranslate.invalidMessage');
    self.cephList = [];
    self.prettyName = $routeParams.prettyName
    self.nodeIp = $routeParams.nodeIp
    getCephTasks()
    function getCephTasks(){
        cephViewSrv.getCephTasks().then(function(result){
            if (result && result.data){
                result.data.map(item=>{
                    if(item.jobStatus == "EXECUTING" || item.jobStatus=="NORMAL" ){
                        self.hasTask = true;
                        console.log(new Date(),'success')
                    }
                })
            }
        })
    }
    self.diskTypeLang = localStorage.isCustom=='true'?$translate.instant('aws.cephView.cacheDisk'):$translate.instant('aws.cephView.journalDisk')
    
    function postFunc(data){
        /* 
        {
           item: 当前盘信息
           maxBackfills: 最大回填数
           action:控制delete、add，
           diskType: data-添加数据盘，journal-元数据盘，map-添加新的关系映射，
           bcache:当前池是否开启bcache
        }
         */
        let {item,maxBackfills,action,diskType,bcache} = data
        let cephSsd = [];
        let cephOsd = [];
        item.forEach(it=>{
            if(diskType=="journal"){
                cephSsd.push(it.byId)
            }
            if(diskType=="data"){
                cephOsd.push(it.byId)
            }
            if(diskType=="map"){
                if(it.attribute == "data"){
                    cephOsd.push(it.byId)
                }else{
                    cephSsd.push(it.byId)
                }
            }
        })

        var post ={
            "action": action,
            "cephOsd": cephOsd,
            "cephSsd": cephSsd,
            "diskName": item.map(it=>it.name).join(","),
            "jobType": 1,
            "maxBackfills": Number(maxBackfills),
            "nodeIp": item[0].nodeIp,
            "nodeName": item[0].nodeName,
            "regionKey":localStorage.regionKey,
            "cephUseBcache":bcache
        }
        if(action=="delete"){
            post.oldCephOsd = cephOsd;
        }
        if(action=="add"&&diskType&&diskType!='map'){
            let list = self.childContext.currentPreviewDisk.sourceList;
            if(list.length){
                if(bcache){
                    /* 
                    添加单个osd
                    对于开启缓存加速的存储池
                    1.找出所有 used 为 false 的分区
                    2.找出 recommendedForDb 值为 true 的分区做元数据盘分区
                    3.找出 recommendedForDb 值为 false 的分区做缓存盘分区，若没有为 false的分区，则随便选一个
                    4.若没有 recommendedForDb 值为 true 的分区，则随便选择分区，性能会变差
                    5.缓存盘分区在前，元数据盘分区在后
                    6.分区不够时，不允许添加osd
                     */
                    let arr = list[0].dbPartitions.filter(it=>{
                        return !it.used;
                    });
                    let dbT = arr.filter(it=>it.recommendedForDb);
                    let dbF = arr.filter(it=>!it.recommendedForDb);
                    post.cephSsd = [];
                    if(dbT.length<1){
                        post.cephSsd = [dbF[0].partuuid+"|"+dbF[1].partuuid];
                    }
                    if(dbF.length<1){
                        post.cephSsd = [dbT[0].partuuid+"|"+dbT[1].partuuid];
                    }
                    if(dbF.length>0&&dbT.length>0){
                        post.cephSsd = [dbF[0].partuuid+"|"+dbT[0].partuuid];
                    }
                }else{
                    let arr = list[0].dbPartitions.filter(it=>!it.used);
                    post.cephSsd = [arr[0].partuuid];
                }
            }
        }
        return post;
    }
    function hasTaskTip(){   //集群中有任务不允许添加和删除
        var scope = $rootScope.$new();
        var modal_os_delete= $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "add-tip.html",
            scope: scope
        });
    }

    function addDiskCheck(item,diskType,allData,bcache){      
        var scope = self.$new();
        scope.diskErrorTips = false;
        if(diskType=="add"){
            if(allData.dataList.length==0){
                /********
                    数据盘不能为空
                *********/
                scope.diskErrorTips = $translate.instant("aws.cephView.tips.tip22");
            }
        }
        if(diskType=="journal"){
            /********
            无法编辑元数据盘
            *********/
            scope.diskErrorTips = $translate.instant("aws.cephView.tips.tip19");
        }
        if(diskType=="data"){
            if(allData.sourceList.length&&!allData.sourceList[0].dbPartitions){
                /********
                    添加数据盘，元数据盘分区为空
                *********/
                scope.diskErrorTips = $translate.instant("aws.cephView.tips.tip18");
            }

            if(allData.sourceList.length&&allData.sourceList[0].dbPartitions){
                /********
                    添加数据盘，元数据盘有分区，但无可用分区
                *********/
                let nouse = [];
                let lenNum = 0;
                if(bcache){
                    nouse = allData.sourceList[0].dbPartitions.filter(it=>{
                        // return !it.used&&it.recommendedForDb;
                        return !it.used;
                    })
                    lenNum = nouse.length/2;
                }else{
                    nouse = allData.sourceList[0].dbPartitions.filter(it=>{
                        return !it.used
                    })
                    lenNum = nouse.length;
                }
                
                if(lenNum<1){
                    scope.diskErrorTips = $translate.instant("aws.cephView.tips.tip18");
                }
                
            }

            if(!scope.diskErrorTips){
                if(allData.sourceList.length&&item[0].ssd){
                    /********
                    添加数据盘，当已配置元数据盘时，数据盘只能选择HDD类型磁盘
                    *********/
                    scope.diskErrorTips = $translate.instant("aws.cephView.tips.tip20");
                }
            }
            if(!scope.diskErrorTips){
                if(!allData.sourceList.length){
                    let arr = allData.dataList.concat(...item);
                    let len = arr.filter(it=>it.ssd);
                    if(len.length&&len.length!==arr.length){
                        /********
                        添加数据盘，只能选择同一类型磁盘
                        *********/
                        scope.diskErrorTips = $translate.instant("aws.cephView.tips.tip21");
                    }
                }
            }
            
        }
        
        if(scope.diskErrorTips){
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "journal-tip.html",
                scope: scope
            });
        }
        return scope.diskErrorTips;
    }
    function hasOneDataDisk(){      //日志盘存在 且只有一个数据盘
        var scope = $rootScope.$new();
        var modal_os_delete= $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "one-dataDisk.html",
            scope: scope
        });
    }
    function changeItemDisk(item,address,nodeName){
        item.capacity = item.size/(1024*1024*1024)>1024?(item.size/(1024*1024*1024*1024)).toFixed(2)+"TB":(item.size/(1024*1024*1024)).toFixed(2)+"GB"
        item.inUsed = parseInt(item.used/(1024*1024*1024));
        item.percent = ((item.used/item.size)*100).toFixed(2)+"%";
        item.ssd = item.type==0?true:false;
        item.nodeIp = address;
        item.nodeName = nodeName;
        return item;
    }
    self.getCephList = function(){
        self.showDrag = false;
        cephViewSrv.getCephList().then(function(result){
            if(result && result.data && result.data[$routeParams.poolName]){
                let data = result.data[$routeParams.poolName];
                data.map(item => {
                    if(item.nodeName==$routeParams.nodeName){
                        self.cephList = item;
                        self.currendUid = item.nodeUid;
                        item.availableDiskInfos.forEach(tt=>{
                            tt = changeItemDisk(tt,item.address,item.nodeName);
                        })
                        item.missingDiskInfos.forEach(tt=>{
                            tt = changeItemDisk(tt,item.address,item.nodeName);
                        })
                        let availableDiskList = {
                            diskAttributesList:item.availableDiskInfos,
                            nodeName:item.nodeName,
                            nodeUid:item.nodeUid
                        }
                        
                        dragDiskCache['CEPHMANAGE'].nodeUidMap[item.nodeUid] = {};
                        dragDiskCache['CEPHMANAGE'].nodeUidMap[item.nodeUid].diskMap = [];
                        dragDiskCache['CEPHMANAGE'].nodeUidMap[item.nodeUid].missList = item.missingDiskInfos;
                        dragDiskCache['CEPHMANAGE'].nodeUidMap[item.nodeUid].nouseList = item.availableDiskInfos;
                        item.cephDiskInfos.forEach(it=>{
                            let obj = {
                                sourceList:[],
                                dataList:[]
                            };
                            it.forEach(ii=>{
                                ii = changeItemDisk(ii,item.address,item.nodeName);
                                if(ii.attribute=='data'){
                                    obj.dataList.push(ii);
                                }else{
                                    obj.sourceList.push(ii);
                                }
                            })
                            dragDiskCache['CEPHMANAGE'].nodeUidMap[item.nodeUid].diskMap.push(obj);
                        })
                        
                        self.cephProps = {
                            checkedItems:[item],
                            availableDiskList:[availableDiskList],
                            type:"CEPHMANAGE",
                            bcache:item.cephUseBcache
                        }
                        self.cephUseBcache = item.cephUseBcache;
                        
                    }
                })
                self.showDrag = true;
            }
        })
    }
    self.getCephList()
    //设置权重
    self.weightSet = function(){
        var uibModalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "js/system/cephView/tmpl/weightSet.html",
            controller: "weightSetCtrl",
            resolve: {
                initTable: function() {
                    return self.getTable;
                }
            },
        });
        return uibModalInstance.result.then(function(post){
            
        });
    }
    
    self.data_journalDrop = function(item,diskType,allData){
        if(self.hasTask){
            hasTaskTip()   //集群中有任务不允许添加和删除
        }else{
            let invalid = addDiskCheck(item,diskType,allData,self.cephUseBcache);
            if(!invalid){
                var uibModalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "js/system/cephView/tmpl/infoAddBalanceData.html",
                    controller: "infoAddBalanceDataCtrl",
                    resolve:{
                        item:function(){
                            return item;
                        },
                        postFunc:function(){
                            return postFunc;
                        },
                        diskType:function(){
                            return diskType
                        },
                        getCephTasks:function(){
                            return getCephTasks
                        },
                        getCephList:function(){
                            return self.getCephList
                        },
                        bcache:function(){
                            return self.cephUseBcache
                        }
                    }
                });
            }
            
        }
        
    }
    self.delUsedfunc = function(item,diskType,allData){
        console.log(item);
        //当数据盘存在时，元数据盘不可删除 做相关提示；
        if(diskType=="journal"&&allData.dataList.length>0){ 
            hasOneDataDisk();
            return;
        }
        if(self.hasTask){
            hasTaskTip()
        }else{
            var uibModalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/system/cephView/tmpl/infoBalanceData.html",
                controller: "infoBalanceDataCtrl",
                resolve:{
                    item:function(){
                        return item;
                    },
                    postFunc:function(){
                        return postFunc;
                    },
                    diskType:function(){
                        return diskType
                    },
                    getCephTasks:function(){
                        return getCephTasks
                    },
                    getCephList:function(){
                        return self.getCephList
                    },
                    bcache:function(){
                        return self.cephUseBcache
                    }
                }
            });
            return uibModalInstance.result.then(function(post){
                
            });
        }
        
    }

    self.delMissfunc = function(item){
        var scope = $rootScope.$new();
        var uibModalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "delete-miss.html",
            scope: scope
        });
        scope.doubleClick = false;
        scope. confirmDelMiss = function(){
           scope.doubleClick = true;
           cephViewSrv.delMissOSD(item).then(function(){
                getCephTasks();
                self.getCephList();
           })
           uibModalInstance.close()
        }
    }
    //新增或者删除当前磁盘
    self.$on('ceph-change-disk',function(e,data){
        let diskType;
        if(data.type=="sourceList"){
            diskType = "journal";
        }else if(data.type=="dataList"){
            diskType = "data";
        }else{
            diskType = "miss";
        }
        if(data.action=="delete"){
            if(data.data.osdStatus=="missing"){
                self.delMissfunc(data.data);
            }else{
                self.delUsedfunc([data.data],diskType,data.allData);
            }
        }else{
            self.data_journalDrop([data.data],diskType,data.allData);
        }
        
    });
    //接收drag-disk组件 消息，保存drag-disk组件的上下文
    self.$on('ceph-send-context',function(e,data){
        self.childContext = data.context;
        self.isNew = self.childContext.currentPreviewDisk.isNew;
        self.$watch(function(){
            return self.childContext.currentPreviewDisk.isNew
        },function(val){
            self.isNew = val;
        })
    });
    //当元数据盘--数据盘不符合规则时，给出提示框
    self.$on("drag-force-valid",function(e,data){
        self.isDiskInvalid = false;
        if(!data.flag){return;}
        let arr = [];
        self.childContext.currentPreviewDisk.sourceList.forEach(item=>{
            let obj = _.cloneDeep(item);
            obj.attribute = 'journal';
            arr.push(obj);
        })
        self.childContext.currentPreviewDisk.dataList.forEach(item=>{
            let obj = _.cloneDeep(item);
            obj.attribute = 'data';
            arr.push(obj);
        })
        self.data_journalDrop(arr,'map',self.childContext.currentPreviewDisk);
    })
    self.saveDisksMap = function(){
        let propContext = self.childContext;
        let result = propContext.checkError(propContext.currentPreviewDisk,false,propContext.TYPECASE,propContext.data.bcache);
        let arr = [];
        //开启缓存加速时，元数据盘必填
        if(result.errorHash['sourceDiskRequire']){
            alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.dragDiskError.nosourcediskMsg"), "error", 5000);
            return;
        }
        //数据盘必填
        if(result.errorHash['nodatadisk']){
            alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.dragDiskError.nodatadiskMsg"), "error", 5000);
            return;
        }
        
        if(result.errorList.length){
            self.isDiskInvalid = true;
            return;
        }
        propContext.currentPreviewDisk.sourceList.forEach(item=>{
            let obj = _.cloneDeep(item);
            obj.attribute = 'journal';
            arr.push(obj);
        })
        propContext.currentPreviewDisk.dataList.forEach(item=>{
            let obj = _.cloneDeep(item);
            obj.attribute = 'data';
            arr.push(obj);
        })
        self.data_journalDrop(arr,'map',propContext.currentPreviewDisk);
    }

    
    self.$on("startTasking",function(e,data){
        alertSrv.set("", $translate.instant("aws.cephView.task.jobStatus.EXECUTING"), "success", 5000);
    })
    self.$on("startTaskSuccess",function(e,data){
        self.hasTask = false;
        alertSrv.set("",  $translate.instant("aws.cephView.task.jobStatus.FINISHED"), "success", 5000);
        self.getCephList();
        getCephTasks();
    })
    self.$on("startTaskFailed",function(e,data){
        self.hasTask = false;
        alertSrv.set("",  $translate.instant("aws.cephView.task.jobStatus.FAILED"), "error", 5000);
        self.getCephList();
        getCephTasks();
    })
}

function infoBalanceDataCtrl($rootScope, $scope,cephViewSrv,$uibModalInstance,item,postFunc,getCephTasks,getCephList,diskType,bcache){
    var self = $scope;
    self.balance = {
        execute:"timing",
        maxBackfills:2
    }
    self.stepOne = true;
    self.submitInValid = false;
    self.doubleClick = false;
    self.confirmBefore = function(field){
        self.showClusterStatusTip = false;
        self.stepOne = true;
        if(field.$valid){
            switch(self.balance.execute){
                case "timing":
                    self.stepOne = false;
                    break;
                case "immediately":
                    cephViewSrv.getPGstatus().then(function(res){
                        if(res && res.data){
                            self.stepOne = false;
                        }else{
                            self.showClusterStatusTip = true;
                        }
                    })
                break;
            }
            
        }else{
            self.submitInValid = true;
        }
    }
    self.balanceConfirm = function(){
        let data = {
            item,
            maxBackfills:self.balance.maxBackfills,
            action:"delete",
            diskType,
            bcache:bcache
        }
        var post = postFunc(data);
        self.balance.execute == "timing"?post.startTime = self.balance.startTime:"";
        self.doubleClick = true;
        $uibModalInstance.close();
        cephViewSrv.balanceData(post).then(function(){
            getCephTasks()
            getCephList()
        })

    }
    self.close = function(){
        self.stepOne = true;
    }

}
function infoAddBalanceDataCtrl($rootScope, $scope,cephViewSrv,$uibModalInstance,item,postFunc,diskType,getCephTasks,getCephList,$routeParams,bcache){
    var self = $scope;
    self.balance = {
        execute:"timing",
        maxBackfills:2
    }
    self.toType = diskType;
    self.poolName = $routeParams.poolName;
    self.submitInValid = false;
    self.doubleClick = false;
    self.balanceConfirm = function(field){
        if(field.$valid){
            self.showClusterStatusTip = false;
            let option = {
                item,
                maxBackfills:self.balance.maxBackfills,
                action:"add",
                diskType:self.toType,
                bcache:bcache
            }
            var post = postFunc(option);
            //添加
            post.storagePoolName = self.poolName;
            // if(self.toType=='data'||self.toType=='map'){
            //     post.storagePoolName = self.poolName;
            // }
            self.balance.execute == "timing"?post.startTime = self.balance.startTime:"";
            switch(self.balance.execute){
                case "timing":
                    self.doubleClick = true;
                    $uibModalInstance.close();
                    cephViewSrv.balanceData(post).then(function(){
                        getCephTasks()
                        getCephList()
                    })
                    break;
                case "immediately":
                    cephViewSrv.getPGstatus().then(function(res){
                        if(res && res.data){
                            self.doubleClick = true;
                            $uibModalInstance.close();
                            cephViewSrv.balanceData(post).then(function(){
                                getCephTasks()
                                getCephList()
                            })
                        }else{
                            self.showClusterStatusTip = true;
                        }
                    })
                break;
            }
        }else{
            self.submitInValid = true;
        }

    }

}
export {cephViewInfoCtrl,infoBalanceDataCtrl,infoAddBalanceDataCtrl}