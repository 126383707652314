      
import "./snapshotsSrv";
import "./volumesSrv";
import "./regularSnapSrv";
import "../instances/instancesSrv";
import "../../system/storage/storageSrv";
import './ObjectsSrv'
var bjectStorageModule = angular.module("bjectStorageModule", ["snapshotssrv","regularSnapSrvModule","volumessrv","instancesModule"])
bjectStorageModule.controller("bjectStorageCtrl",["$scope","$rootScope","NgTableParams","$uibModal","$translate","GLOBAL_CONFIG","checkedSrv","$routeParams","TableCom","newCheckedSrv","$location","ObjectsSrv",
function ($scope,$rootScope,NgTableParams,$uibModal,$translate,GLOBAL_CONFIG,
    checkedSrv,$routeParams,TableCom,newCheckedSrv,$location,ObjectsSrv) {
  var self = $scope;
  var filterName = $routeParams.resid;
  self.headers={
    "name":$translate.instant("aws.swifts.name"),
    "count":$translate.instant("aws.swifts.count"),
    "bytes":$translate.instant("aws.swifts.totalNum"),
    "r_acl":$translate.instant("aws.swifts.accessible"),
    "detail":$translate.instant("aws.swifts.detail"),
  }
  self.objectUserList = [];
  self.storageId = null;
  
  // 切换对象存储用户
  self.objectUserChange = function(id) {
    $rootScope.storageId = id;
    initTableData();
  }

  // 获取对象存储用户
  var getObjectUserData = function(){
    ObjectsSrv.getObjectUserData().then(res => {
      if(res && res.data && res.data.length){
        self.objectUserList = res.data;
        initTableData();
      }
    })
  }
  getObjectUserData();
  var initTableData = function() {
    let hasId = false;
    for (let i = 0; i < self.objectUserList.length; i++) {
      const item = self.objectUserList[i];
      if(item.id == $rootScope.storageId){
        hasId = true;
      }
    }
    if($rootScope.storageId && hasId){
      self.storageId = $rootScope.storageId;
    }else{
      self.storageId = self.objectUserList && self.objectUserList[0] ? self.objectUserList[0].id : null;
      $rootScope.storageId = self.storageId;
    }
    if(!self.storageId) return;
    ObjectsSrv.getSwiftContainerList().then(function(result){
      if(result){
        var containerdata = result.data;   
        _.forEach(containerdata, function(item){
          if (item.details.readACL){
            item.r_acl = $translate.instant("aws.swifts.public");
            item.isPublic = true;
          } else {
            item.r_acl = $translate.instant("aws.swifts.private");
            item.isPublic = false;
          };
          item.count = item.details.objectCnt;
          //item.bytes = item.details.bytesUsed ;
          if ( item.details.bytesUsed < 1024){
            item.bytes = item.details.bytesUsed + " B";
          }
          else if ( item.details.bytesUsed < 1048576 )
          {
             item.bytes = Math.ceil (item.details.bytesUsed /1024) + " KB";
          }
          else if ( item.details.bytesUsed < 1073741824 ){
             item.bytes = Math.ceil (item.details.bytesUsed /1048576) + " MB";
          }
          else {
            var temp = Math.ceil ((item.details.bytesUsed *10) /1073741824) ;
            item.bytes = temp / 10 + " GB";
          }
        })
        /*if(!filterName){
          ContainersSrv.swiftTableAllData=containerdata
        } else {
          var filterdata=[];
          _.forEach(containerdata, function(item){
            if (item.name == filterName){
              filterdata.push(item)
            }
          })
          ContainersSrv.swiftTableAllData=filterdata
        }
        console.log(ContainersSrv.swiftTableAllData);*/
        successFunc(containerdata);
      }
   });
  }
  self.refresh = function(){
    initTableData();
  }
  function successFunc(data) {
    /*_.forEach(data, function(item) {
        item.status_ori = $translate.instant("aws.host.status-c." + item.status);
        item.status_d = $translate.instant("aws.host.status-d." + item.is_host);
        item.lastTime_ori = $filter('date')(item.last_time, "yyyy-MM-dd HH:mm:ss");
    })*/

    self.clusterTableData = data;
    self.clusterListData = data;
    self.applyGlobalSearch = function() {
        var term = self.globalSearchTerm;
        self.centertableParams.filter({ searchTerm: term });
    };
    self.clusterSearchTerm(({tableData:self.clusterListData,titleData:self.titleData}));
    self.centertableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: data });
    var tableId = "name";
    newCheckedSrv.checkDo(self, data, tableId,"centertableParams"); 
    self.$watch(function() {
        return self.checkedItemscentertableParams;
    }, function(value) {
        self.canStatusGo = false;
        self.canStatusEnd = false;
        if(value.length==1){
            self.canEdit = true;
            self.canDeploy = true;
            self.canDel = true; 
            self.goCluster = true;
            if(value[0].is_host ==0){
                if(value[0].information != undefined && value[0].information!=null && value[0].information!=""){
                    self.canStatusGo = true;
                }      
            }else{ 
                if(value[0].information != undefined && value[0].information!=null && value[0].information!=""){
                    self.canStatusEnd = true;
                }      
            }
        }else if(value.length > 1){
            self.canDel = true; 
            self.goCluster = true;
        }else{
            self.goCluster = false;
            self.canDel = false; 
            self.canEdit = false;
            self.canDeploy = false;
            self.canShutOff=false;
        }
        self.swiftCanSetPrivate();
      self.swiftCanSetPublic();
     });
    }
    $scope.swiftCanSetPrivate = function() {
      var len =  self.checkedItemscentertableParams.length;
      if ( len <= 0 ) {
        self.canSetPrivate = false;
        return;
      }
      self.canSetPrivate = true;
      for (var i = 0; i < len &&  self.canSetPrivate; i++) {
        self.canSetPrivate &= self.checkedItemscentertableParams[i].isPublic;
      }
    };
  
    $scope.swiftCanSetPublic = function() {
      var len =  self.checkedItemscentertableParams.length;
      if ( len <= 0 ) {
        self.canSetPublic = false;
        return;
      }
      self.canSetPublic = true;
      for (var i = 0; i < len &&  self.canSetPublic; i++) {
        self.canSetPublic &= !self.checkedItemscentertableParams[i].isPublic;
      }
    };
    self.clusterSearchTerm=function(obj){
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        if(tableData && tableData.length){
          tableData.map(function(item){
            item.searchTerm="";
            titleData.forEach(function(showTitle){
              if(showTitle.value){
                item.searchTerm+=item[showTitle.search]+"\b";
              }
            });
          }); 
        }
    };
    self.siteTitle="objectStorageData";
    if(sessionStorage["objectStorageData"]){
       self.titleData=JSON.parse(sessionStorage["objectStorageData"]);
    }else{
       self.titleData=[
      {name:'swifts.name',value:true,disable:true,search:'name'},
      {name:'swifts.count',value:true,disable:false,search:'count'},
      {name:'swifts.totalNum',value:true,disable:false},
      {name:'swifts.accessible',value:true,disable:false},
      {name:'swifts.detail',value:true,disable:true}
       ];
    }
  $scope.swiftRefreshContainers = function() {
    initTableData(); 
  };
  $scope.swiftCreateContainer = function() {
    $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'createModel.html',
      controller: 'CreateModelCtrl',
      backdrop:'static',
      scope: self,
    });
  };

  if ($location.search().openModal) {
    $scope.swiftCreateContainer();
  }

  $scope.swiftDeleteContainers = function() {
    var content ={
      target:"delSwiftContainer",
      msg:"<span>"+$translate.instant("aws.swifts.confirmDel")+"</span></p>"
            + "<span>"+$translate.instant("aws.swifts.confirmIsNull")+"</span>"
    };
    self.$emit("delete",content);
  };
  self.$on("delSwiftContainer",function(){
      for ( var i = self.checkedItemscentertableParams.length-1; i >= 0; i-- ) {
        ObjectsSrv.deleteSwiftContainerDoc(self.checkedItemscentertableParams[i].name).then(function(res){
          if ( res.status == 0 ) {
            self.refresh();
          }
        });
      }
  });

  $scope.swiftPublicContainers = function(bePublic) {
		for (var i = 0; i < self.checkedItemscentertableParams.length; i++) {
      console.log(self.checkedItemscentertableParams[i]);
      ObjectsSrv.updateSwiftContainerDoc(self.checkedItemscentertableParams[i].name, bePublic).then(function(result){
        self.refresh();
      });
		}
  }

  $scope.$on('getDetail', function(event, value) {
    $scope.containerDetail = {
      name:value,
      objectCnt:"",
      bytesUsed:"",
      r_acl: "",
      updated:"",
      id:"",
      url:""
    };

    if ( 0 > value.indexOf("/") ) {
      //ObjectsSrv.getSwiftMetaDoc(value, null).then(function(result) {
        $scope.clusterListData.forEach(item => {
          if(value == item.name){
            $scope.containerDetail = {
              name:value,
              objectCnt:item.count,
              bytesUsed:item.bytes,
              r_acl: "",
              updated:item.details.updated,
              id:"",
              url:item.details.url,
              readACL:item.details.readACL
            };
            if (item.details.readACL) {
              $scope.containerDetail.r_acl = $translate.instant("aws.swifts.public");
            } else {
              $scope.containerDetail.r_acl = $translate.instant("aws.swifts.private");
            };
          }
            
      });
        
      //});
    }
  });

}])
bjectStorageModule.controller('CreateModelCtrl', function ($scope, $rootScope, $translate, $uibModalInstance,ObjectsSrv){
    self=$scope;
    self.ContainerForm={};
    $scope.flag = true;
    $scope.confirm =function() {
      if($scope.flag == true){
        $scope.flag = false;
        ObjectsSrv.createSwiftContainer(self.ContainerForm.name).then(function(result){
          self.swiftRefreshContainers(); 
        }); 
        $uibModalInstance.close();
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
  