import "./multipleSrv";
angular.module("multipleModule", ["multipleSrv"])
    .controller("multipleCtrl", ["$scope","$translate","multiplesrv","alertSrv",function($scope,$translate,multiplesrv,alert) {
        let self = $scope;
        self.net = {
            selected:''
        }
        self.netList = [];
        self.isClicked = false;
        self.alertText = '';

        self.init = function(){
            multiplesrv.getList().then(res=>{
                if(res&&res.data){
                    self.net = {
                        selected:''
                    }
                    let alertText = '';
                    const data = res.data;
                    if(data.length){
                        let status = false;
                        data.forEach(item => {
                            item.text = $translate.instant('aws.multiplenet.type.'+item.networkName);
                            if(item.used && !status){
                                status = true;
                            }
                            if(item.used){
                                alertText += '，' + item.text;
                            }
                        })
                        
                        self.netList = data.filter(item => !item.used);
                        console.log(self.netList,alertText)
                        self.alertText = alertText.slice(1) + $translate.instant('aws.multiplenet.networkConnected');
                        self.alertTextShow = status;
                        self.isDisabled = self.netList.length ? false : true;
                    }
                }
            })
        }
        self.init();
        self.save = function(form){
            if(form.$valid){
                self.isClicked = true;
                let data = {
                    networkRole:self.net.selected.networkName
                }
                multiplesrv.editItems(data).then(res => {
                    if(res && res.data == 'success'){
                        alert.set("", $translate.instant("aws.multiplenet.configNetworkLoading"), "building");
                    }
                }).finally(()=>{
                    self.isClicked = false;
                })
            }
        }
        self.$on("configNetworkSuccess",function(){
            self.init();
        })
    }])
