var ipSrvModule = angular.module("ipSrvModule", []);
ipSrvModule.service('ipSrv', [function() {
	let ipSrv = {};
	/**
	 * 获取子网IP地址池起止范围
	 * @param cidr
	 * @returns []
	 * @author:daijing
	 */
	ipSrv.getIPZone = function(cidr) { //'0.0.0.0/1'
		let ipDetailObj = _IP.cidrSubnet(cidr);
		let minUintIp = _IP.toLong(ipDetailObj.firstAddress) + 1; //去除第一个网关IP
		let maxUnitIp = _IP.toLong(ipDetailObj.lastAddress);
		return [minUintIp, maxUnitIp]; //[2, 2147483646]
	};

	/**
	 * 获取子网所有IP地址池起止范围
	 * @param allocationPools
	 * @returns {[]}
	 * @author:daijing
	 */
	ipSrv.getIpPoolsZone = function(allocationPools) {
		let pools = {};
		_.each(allocationPools, function(item, i) {
			pools[i] = [_IP.toLong(item.start), _IP.toLong(item.end)];
		});
		return pools; //返回所有IP池的IP范围
	};
    
    //判断子网ip个数是否满足启动dhcp(至少四个，dhcp三个，如果和路由器关联还会被占用一个)
	ipSrv.ipPoolFullForDhcp=function(allocationPools){
        let ipNotFull=false;
        let allocationPoolsTrans=ipSrv.getIpPoolsZone(allocationPools);
        let ipNum=0;
        for(let key in allocationPoolsTrans){
            ipNum+=allocationPoolsTrans[key][1]-allocationPoolsTrans[key][0]+1;
        }
        if(ipNum<4){
           ipNotFull=true;
        }
        return ipNotFull;
	};

	ipSrv.chkIpOverlapFunc = function(allocationPools) { //校验IP地址池是否交叉重叠
		let targetNetOverlap = false;
		let startArr = [];
		let endArr = [];
		allocationPools.forEach(function(item) {
			startArr.push(_IP.toLong(item.start));
			endArr.push(_IP.toLong(item.end));
		});
		let startSortArr = startArr.sort(function(a, b) {
			return a - b
		});
		let endSortArr = endArr.sort(function(a, b) {
			return a - b
		});
		for (var k = 1; k < startSortArr.length; k++) {
			if (startSortArr[k] <= endSortArr[k - 1]) {
				targetNetOverlap = true;
				break;
			}
		}
		return targetNetOverlap;
	};

	ipSrv.checkIpInPool = function(allocationPools, ip) { //校验IP地址池中是否含有网关IP(校验IP池中是否含有某个IP)
		let ipInPool = false;
		let poolsObj = ipSrv.getIpPoolsZone(allocationPools);
		for (let key in poolsObj) {
			if (_IP.toLong(ip) >= poolsObj[key][0] && _IP.toLong(ip) <= poolsObj[key][1]) {
				ipInPool = true;
				break;
			}
		}
		return ipInPool;
	};

	ipSrv.checkIpInCidr = function(subCidr, allocationPools) { //校验IP地址池中的IP是否在子网的cidr内
		let poolBoundaryIpSet = [];
		let ipNotInCidr = false;
		_.each(allocationPools, function(item) {
			poolBoundaryIpSet.push(item.start);
			poolBoundaryIpSet.push(item.end);
		});
		for (let i = 0; i < poolBoundaryIpSet.length; i++) {
			if (!_IP.cidrSubnet(subCidr).contains(poolBoundaryIpSet[i])) {
				ipNotInCidr = true;
				break;
			}
		}
		return ipNotInCidr;
	};

	ipSrv.checkGtIp = function(allocationPools) { //校验IP地址池中的结束IP必须大于起始IP
		let startIpgtEndIp = false;
		for (let i = 0; i < allocationPools.length; i++) {
			if (_IP.toLong(allocationPools[i].start) >= _IP.toLong(allocationPools[i].end)) {
				startIpgtEndIp = true;
				break;
			}
		}
		return startIpgtEndIp;
	};

	ipSrv.checkPoolRange = function(prePool, newPool) { //校验IP池范围，需要限制不能变小，以防止将已分配出去的IP地址，从IP地址池中删除
		let poolNarrow = false;
		for (let i = 0; i < prePool.length; i++) {
			if (_IP.toLong(newPool[i].start) > _IP.toLong(prePool[i].start) || _IP.toLong(newPool[i].end) < _IP.toLong(prePool[i].end)) {
				poolNarrow = true;
				break;
			}
		}
		return poolNarrow;
	};

	ipSrv.isInV6Cidr=function(cidr,range){
        var range=_IPAddr.parse(range);
        var inCidr=range.match(_IPAddr.parseCIDR(cidr));
        return inCidr;
	};

	/**
	 * ipv6 16进制转成10进制的数
	 * @param ip 1:1:2:2:ffff:ffff:ffff:ffff
	 * @returns Oject  objInfo{ ipList （16进制字符串数组） parseIntIp（10进制数的数组）}
	 * @author:guozh
	 */
	ipSrv.parseIntIpSix=function(ip) {
		let objInfo = {};
        let parseIntIp = [];
		let ipList = []
		var reg = /^([\da-fA-F]{1,4}:){6}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^::([\da-fA-F]{1,4}:){0,4}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:):([\da-fA-F]{1,4}:){0,3}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){2}:([\da-fA-F]{1,4}:){0,2}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){3}:([\da-fA-F]{1,4}:){0,1}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){4}:((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}$|^:((:[\da-fA-F]{1,4}){1,6}|:)$|^[\da-fA-F]{1,4}:((:[\da-fA-F]{1,4}){1,5}|:)$|^([\da-fA-F]{1,4}:){2}((:[\da-fA-F]{1,4}){1,4}|:)$|^([\da-fA-F]{1,4}:){3}((:[\da-fA-F]{1,4}){1,3}|:)$|^([\da-fA-F]{1,4}:){4}((:[\da-fA-F]{1,4}){1,2}|:)$|^([\da-fA-F]{1,4}:){5}:([\da-fA-F]{1,4})?$|^([\da-fA-F]{1,4}:){6}:$/;
		if(!(reg.test(ip))) {
			return {
				parseIntIp: parseIntIp,
				ipList: parseIntIp
			}
		}
		let addr = _IPAddr.parse(ip)
        ipList = addr.toNormalizedString().split(":");
        for (let j = 0; j < ipList.length; j++) {
            parseIntIp.push(parseInt(ipList[j],16))
        }
		objInfo.ipList = ipList;
		objInfo.parseIntIp = parseIntIp;
        return objInfo;
    };

	/**
	 * ipv6 比较两个值的大小
	 * @param ip1 ipv6地址的字符串
	 * @param ip2 ipv6地址的字符串
     * @returns 1表示第一个地址大于第二个地址，0表示两个地址相同，－1表示第一个地址小于第二个地址
	 * @author:guozh
    */
    ipSrv.compareIpSix=function(ip1, ip2) {
		var reg = /^([\da-fA-F]{1,4}:){6}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^::([\da-fA-F]{1,4}:){0,4}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:):([\da-fA-F]{1,4}:){0,3}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){2}:([\da-fA-F]{1,4}:){0,2}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){3}:([\da-fA-F]{1,4}:){0,1}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){4}:((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}$|^:((:[\da-fA-F]{1,4}){1,6}|:)$|^[\da-fA-F]{1,4}:((:[\da-fA-F]{1,4}){1,5}|:)$|^([\da-fA-F]{1,4}:){2}((:[\da-fA-F]{1,4}){1,4}|:)$|^([\da-fA-F]{1,4}:){3}((:[\da-fA-F]{1,4}){1,3}|:)$|^([\da-fA-F]{1,4}:){4}((:[\da-fA-F]{1,4}){1,2}|:)$|^([\da-fA-F]{1,4}:){5}:([\da-fA-F]{1,4})?$|^([\da-fA-F]{1,4}:){6}:$/
		if(!(reg.test(ip1)) || !(reg.test(ip2))) return;
		let ip1s = ipSrv.parseIntIpSix(ip1).ipList;
		let ip2s = ipSrv.parseIntIpSix(ip2).ipList;
        for (let i = 0; i < ip1s.length; i++) {
            if (ip1s[i]=="") {
                if (ip2s[i]=="") { //对应的项都为空，往下比较
                    continue;
                } else {
                    return -1;
                }
            } else {
                if (ip2s[i]=="") {
                    return 1;
                } else {   //确定对应的项不为空，将字符串转换为整数进行比较
                    let value1 = parseInt(ip1s[i], 16);
                    let value2 = parseInt(ip2s[i], 16);
                    if (value1 > value2) {
                        return 1;
                    } else if (value1 < value2) {
                        return -1;
                    } else {
                        continue;
                    }
                }
            }
        }
        //循环结束，表示两个地址相同
        return 0;
    }


	/**
	 * ipv6
	 * 获取子网所有地址池起止范围
	 * @param allocationPools
	 * @returns {[]}
	 * @author:guozh
	 */
	 ipSrv.getIpSixPoolsZone = function(allocationPools) {
		let pools = {};
		_.each(allocationPools, function(item, i) {
			pools[i] = [item.start, item.end];
		});
		return pools; //返回所有IPv6池的IP范围
	};
	
	/**
	 * ipv6
	 * 获校验IP地址池是否交叉重叠
	 * @param allocationPools
	 * @returns Boolean
	 * @author:guozh
	 */
	ipSrv.chkIpSixOverlapFunc = function(allocationPools) { 
		let targetNetOverlap = false;
		let startArr = [];
		let endArr = [];
		allocationPools.forEach(function(item) {
			startArr.push(item.start);
			endArr.push(item.end);
		});
		for (var k = 1; k < startArr.length; k++) {
			if(ipSrv.compareIpSix(startArr[k],endArr[k - 1])<=0) {
				targetNetOverlap = true;
				break;
			}
		}
		return targetNetOverlap;
	};

	/**
	 * ipv6
	 * 校验IP地址池中是否含有网关IP(校验IP池中是否含有某个IP)
	 * @param allocationPools
	 * @param ip
	 * @returns Boolean
	 * @author:guozh
	 */
	ipSrv.checkIpSixInPool = function(allocationPools, ip) {
		let ipInPool = false;
		let poolsObj = ipSrv.getIpSixPoolsZone(allocationPools);
		for (let key in poolsObj) {
			if(ipSrv.compareIpSix(ip,poolsObj[key][0])>=0 && ipSrv.compareIpSix(ip,poolsObj[key][1])<=0) {
				ipInPool = true;
				break;
			}
		}
		return ipInPool;
	};

	/**
	 * ipv6
	 * 校验IP池范围，需要限制不能变小，以防止将已分配出去的IP地址，从IP地址池中删除
	 * @param prePool
	 * @param newPool
	 * @returns Boolean
	 * @author:guozh
	 */
	ipSrv.checkPoolSixRange = function(prePool, newPool) {
		let poolNarrow = false;
		for (let i = 0; i < prePool.length; i++) {
			if(ipSrv.compareIpSix(newPool[i].start,prePool[i].start)==1 || ipSrv.compareIpSix(newPool[i].end,prePool[i].end)==-1) {
				poolNarrow = true;
				break;
			}
		}
		return poolNarrow;
	};

	/**
	 * ipv6
	 * 数组求和
	 * @param list 数组
	 * @returns Number
	 * @author:guozh
	 */
	ipSrv.reduceAdd = function(list){
		let sum = list.reduce((sum,item)=> sum + item,0)
		return sum;
	}

	/** 
	 * JSON数组去重
	 * @param: [array] json Array
	 * @param: [string] 唯一的key名，根据此键名进行去重
	 * @author:guozh
	 */
	ipSrv.uniqueArray = function(array, key){
		var result = [array[0]];
		for(var i = 1; i < array.length; i++){
		   var item = array[i];
		    var repeat = false;
		    for (var j = 0; j < result.length; j++) {
		      if (item[key] == result[j][key]) {
		        repeat = true;
		        break;
		      }
		    }
		    if (!repeat) {
		      result.push(item);
		    }
		}
		return result;
	}

	return ipSrv;
}])