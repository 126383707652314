import './secSrv';
let secModule = angular.module("secModule",['ngAnimate','ui.bootstrap','secSrvModule']);
secModule.controller("secCtrl",["$rootScope","$scope","$translate","secSrv",
    function ($rootScope,$scope,$translate,secSrv) {
        let self = $scope,rootScope = $rootScope;
        self.departmentList = [];
        self.projectList = [];
        self.form = {
            domain: {},
            project: {}
        }
        self.isSuperAdmin = localStorage.managementRole != 2 ? false : true;

        self.changeDomain = function(value) {
            self.projectList = value.projects;
            self.form.project = value.projects[0];
            self.changeProject(self.form.project);
        }

        self.changeProject = function(value) {
            self.form.project = value;
        }

        self.confirm = function(field){
            if(field.$valid) {
                let project_id = self.form.project.projectId;
                getSSOUrl(project_id);
            }
        }

        function getDomainProject() {
            secSrv.getDomainProject().then(result=>{
                if(result && result.data && angular.isArray(result.data)) {
                    self.departmentList = angular.copy(result.data)
                    self.departmentList = getName(self.departmentList);
                    self.form.domain = self.departmentList[0];
                    self.changeDomain(self.form.domain);
                }
            })
        }

        function getSSOUrl(option) {
            secSrv.getSSOUrl(option).then(result=>{
                if(result && result.data) {
                    if (result.data.indexOf('message') > -1) {
                        const data = JSON.parse(result.data);
                        rootScope.$broadcast("alert-error", data.message);
                        return;
                    }
                    window.open(result.data, '_blank');
                }
            })
        }

        function getName (list) {
            for (let i = 0; i < list.length; i++) {
                if(list[i].domainUid === 'default') {
                    list[i].domainName = $translate.instant("aws.common.defaultDepar");
                }else {
                    list[i].domainName = list[i].domainName;
                }
                if(list[i].projects) {
                    for (let j = 0; j < list[i].projects.length; j++) {
                        if(list[i].projects[j].projectName === 'admin' && list[i].domainUid === 'default') {
                            list[i].projects[j].projectName = $translate.instant("aws.common.defaultProject");
                        }else {
                            list[i].projects[j].projectName = list[i].projects[j].projectName;
                        }
                    }
                }
            }
            return list
        }

        getDomainProject();
    }
])