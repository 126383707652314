volumesQoSCtrl.$inject = ["$scope", "$translate","$uibModalInstance","$location","editData","refresh","NgTableParams",'instancesSrv'];
export function volumesQoSCtrl($scope, $translate, $uibModalInstance, $location, editData, refresh, NgTableParams, instancesSrv){
    let self = $scope;
    self.submitInValid = false;
    self.volumesQoS = {
        name:""
    }
    self.cloudHardDiskList = []
    self.recordList = {}
    self.disabledFlag = false;
    function initCount(totolInfo,num){
        self.recordList.id = totolInfo[num].id;
        self.recordList.catalogName = totolInfo[num].device.split("/dev/")[1]
        self.recordList.read_bytes_sec = totolInfo[num].limit? totolInfo[num].limit.split(",")[0]/1024/1024:"";
        self.recordList.read_iops_sec = totolInfo[num].limit? totolInfo[num].limit.split(",")[1]:"";
        self.recordList.write_bytes_sec = totolInfo[num].limit? totolInfo[num].limit.split(",")[2]/1024/1024:"";
        self.recordList.write_iops_sec = totolInfo[num].limit? totolInfo[num].limit.split(",")[3]:"";

        // self.recordList.read_bytes_sec_flag = true ;
        // self.recordList.read_iops_sec_flag = true ;
        // self.recordList.write_bytes_sec_flag = true ;
        // self.recordList.write_iops_sec_flag = true;
    }

    instancesSrv.volumesQoS(editData.uid).then(function(res){
        if(res && res.data && res.data.diskInfo && res.data.diskInfo.length>0){
            res.data.diskInfo.forEach(function(item){
                if(item.diskFormat!="iso"){
                    self.cloudHardDiskList.push(item)
                }
            })
            if(self.cloudHardDiskList.length>0){
                self.volumesQoS.name = self.cloudHardDiskList[0]
                initCount(self.cloudHardDiskList,0)
            }
            // self.volumesQoS.name = res.data.diskInfo[0]
        }
    })

    self.choiceVolumes = function(data){
        self.cloudHardDiskList.forEach(function(item,index){
            if(item.name==data.name){
                initCount(self.cloudHardDiskList,index)
            }
        })
    }
    self.volumesQoSConFirm = function(fileFrom){
        if(fileFrom.$valid){
            let postData = {
                readBytesSec:self.recordList.read_bytes_sec*1024*1024,
                readIopsSec:self.recordList.read_iops_sec,
                writeBytesSec:self.recordList.write_bytes_sec*1024*1024,
                writeIopsSec:self.recordList.write_iops_sec,
                catalogName:self.recordList.catalogName
            }
            self.disabledFlag = true;
            instancesSrv.editVolumesQoS(postData,editData.uid).then(function(){
                $uibModalInstance.close();
                refresh();
            }).finally(()=>{
                self.disabledFlag = false;
            })
        }else{
            self.submitInValid = true;
        }
    }
}