angular.module("multipleSrv", [])
    .service("multiplesrv", ["$http",function ($http) {
        return {
            editItems: function (data) {
                return $http({
                    method: "POST",
                    url: `awstack-user/v1/provider/network`,
                    params:data
                });
            },
            getList: function () {
                return $http({
                    method: "GET",
                    url: `awstack-user/v1/provider/network/support`
                });
            }
        };
    }]);
