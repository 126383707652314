
import "../volumesSrv";
import "../../instances/instancesSrv";
import "../../../system/storage/storageSrv";
import "../../../overview/overviewSrv"
import "../regularSnapSrv";
volumesTabCtrl.$inject = ['$scope', "$rootScope", "$timeout", "volumesDataSrv", "$translate", "$uibModal", "newCheckedSrv",
    'GLOBAL_CONFIG', 'storageSrv', 'instancesSrv', 'regularSnapSrv', "backupsSrv", "alertSrv", "$routeParams", "NgTableParams", "utilTool", "$location"];
DistStockTransferCtrl.$inject = ['$scope', 'volumesDataSrv', '$translate', '$uibModalInstance', 'initSystemVolumesTable', 'editData', 'detailData', 'type', 'backupsSrv', 'volumesSrv'];
function volumesTabCtrl($scope, $rootScope, $timeout, volumesDataSrv, $translate, $uibModal, newCheckedSrv,
    GLOBAL_CONFIG, storageSrv, instancesSrv, regularSnapSrv, backupsSrv, alertSrv, $routeParams, NgTableParams, utilTool, $location) {
    let self = $scope;
    self.volume = {
        curTable: "data",
        volumeTemp: "js/cvm/volumes/tmpl/dataVolumeTmpl.html",
        getDataTable() {
            this.curTable = "data";
            this.volumeTemp = "js/cvm/volumes/tmpl/dataVolumeTmpl.html"
        },
        getSystemTable() {
            this.curTable = "system";
            this.volumeTemp = "js/cvm/volumes/tmpl/systemVolumeTmpl.html"
        },
        getCacheTable() {
            this.curTable = "cache";
            this.volumeTemp = "js/cvm/volumes/tmpl/cacheVolumeTmpl.html"
        }
    }

    self.$watch(function(){
        return self.volume.curTable
    },function(value){
        $rootScope.g_volume = value
    })

    self.headers = {
        "volumeName": $translate.instant("aws.volumes.volumeName"),
        "projectName": $translate.instant("aws.volumes.projectName"),
        "domain": $translate.instant("aws.common.domain"),
        "project": $translate.instant("aws.common.project"),
        "associatedHost": $translate.instant("aws.volumes.associatedHost"),
        "config": $translate.instant("aws.volumes.config"),
        "status": $translate.instant("aws.volumes.status"),
        "hostName": $translate.instant("aws.volumes.hostName"),
        "snapshot": $translate.instant("aws.volumes.snapshot"),
        "description": $translate.instant("aws.volumes.description"),
        "sharedVolume": $translate.instant("aws.volumes.sharedVolume"),
        "type": $translate.instant("aws.volumes.type"),
        "voltype": $translate.instant("aws.volumes.voltype"),
        "snapshotNum": $translate.instant("aws.volumes.snapshotNum"),
        "imageName": $translate.instant("aws.volumes.imageName"),
        "bootable": $translate.instant("aws.volumes.bootable"),
        "sharedVolume": $translate.instant("aws.volumes.sharedVolume"),
        "snapshotName": $translate.instant("aws.snapshots.snapshotName"),
        "volume": $translate.instant("aws.volumes.volumeName"),
        "size": $translate.instant("aws.snapshots.size"),
        "created_at": $translate.instant("aws.snapshots.created_at"),
        "dataDiskName": $translate.instant("aws.volumes.dataDiskName"),
        "dataDiskDescription": $translate.instant("aws.volumes.dataDiskDescription"),
        "systemDiskName": $translate.instant("aws.volumes.systemDiskName"),
        "systemDiskDescription": $translate.instant("aws.volumes.systemDiskDescription"),
        "mirrorCacheDiskName": $translate.instant("aws.volumes.mirrorCacheDiskName"),
        "mirrorCacheDiskDescription": $translate.instant("aws.volumes.mirrorCacheDiskDescription"),
    };
    self.translate = {
        "name": $translate.instant("aws.backups.table.name"),
        "vm": $translate.instant("aws.backups.table.vm"),
        "createAt": $translate.instant("aws.backups.table.createAt"),
        "size": $translate.instant("aws.backups.table.size"),
        "backupStatus": $translate.instant("aws.backups.table.backupStatus"),
        "backupType": $translate.instant("aws.backups.table.backupType"),
    }
    self.searchList = [
        { name: "全部", key: "" },
        { name: $translate.instant('aws.volumes.table.status.available'), value: "available", key: "status" },
        { name: $translate.instant('aws.volumes.table.status.inavailable'), value: "inavailable", key: "status" },
        { name: $translate.instant('aws.volumes.table.status.creating'), value: "creating", key: "status" },
        { name: $translate.instant('aws.volumes.table.status.in-use'), value: "in-use", key: "status" },
        { name: $translate.instant('aws.volumes.table.status.error'), value: "error", key: "status" },
        //{name:$translate.instant('aws.volumes.table.status.deleting'),value:"deleting",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.attaching'),value:"attaching",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.detaching'),value:"detaching",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.unrecognized'),value:"unrecognized",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.error-deleting'),value:"error-deleting",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.downloading'),value:"downloading",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.uploading'),value:"uploading",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.retyping'),value:"retyping",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.backing-up'),value:"backing-up",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.restoring-backup'),value:"restoring-backup",key:"status"},
        // {name:$translate.instant('aws.volumes.table.status.error-restoring'),value:"error-restoring",key:"status"},
    ]

    self.tablchk = function (values) {
        //分别对不同个数的选中项进行判断是否能删除
        self.btn = {
            canNewIns: false,
            canEdit: false,
            canDel: false,
            canAttach: false,
            canUnstall: false,
            canCS: false,
            canExtend: false,
            canExtends: false,
            canUnstall2: true,
            canForeDel: false,
            isSelected: false,
            canStockTransfer: false,
            canBackup: false,
            hasTaskbackup: true,
            //CSHostDisabled:true,
            hasTasksnapshot: true,
            resetVol: false,
            delisDisabled: true
        }
        if (!values) { return }
        self.checkedItems = values;
        if (values.length > 1) {
            self.btn.delisDisabled = false;
            self.btn.canNewIns = false;
            self.btn.canBackup = false;
            //只要有一个满足不能删除，即删除按钮不可用;分别对不同个数的选中项进行判断是否能强制删除
            for (var i = 0; i < values.length; i++) {
                canDelFunc(values[i]);
                if (self.btn.canDel == false) {
                    self.btn.canDel = false;
                    break;
                }
            }
            for (var i = 0; i < values.length; i++) {
                canForceDelFunc(values[i]);
                if (self.btn.canForeDel == false) {
                    self.btn.canForeDel = false;
                    break;
                }
            }
        } else {
            self.btn.canDel = false;
            self.btn.canForeDel = false;
            self.btn.canStockTransfer = false;
            self.btn.canEdit = false;
            self.btn.canExtend = false;
            self.btn.canExtends = false;
            self.btn.canAttach = false;
            self.btn.canUnstall = false;
            self.btn.canCS = false;
            self.btn.canNewIns = false;
            self.btn.canBackup = false;

        }
        //如果只有一个被选中，则可以编辑；如果不止一个被选中，则不能编辑扩展卸载
        if (values.length == 1) {
            self.btn.delisDisabled = false;
            self.editData = values[0];
            if (values[0] && values[0].storageInfo) {
                self.btn.limit = values[0].storageInfo.storageLimitList;
                self.storageTypeTip = $translate.instant("aws.volumes.tip.tip5");
            }
            
            (async function() {
                await getSnapFromVolumeDetail(values[0].id);
                canDelFunc(values[0]);
                canForceDelFunc(values[0]);
                canEditFunc(values[0]);
                canResetStatus(values[0])
                if (self.btn.limit && self.btn.limit["os-retype"]) canStockTransferFunc(values[0])
                switch (self.volume.curTable) {
                    case "data":
                        if (self.btn.limit["os-attach"]) canAttachFunc(values[0]);
                        if (self.btn.limit["os-detach"]) canUnstallFunc(values[0]);
                        if (self.btn.limit["snapshots"]) canCSFunc(values[0]);
                        if (self.btn.limit["backups"]) canBackup(values[0]);
                        canNewIns(values[0])
                        break;
                    // case "system":
                    //     if (self.btn.limit["os-extend"]) canExtendFunc(values[0]);
                    //     break;
                }

                const { snapshotNum, storageInfo } = self.detailData;
                if(
                    snapshotNum > 0 && 
                    storageInfo && 
                    storageInfo.capabilities && 
                    storageInfo.capabilities.storage_protocol && 
                    storageInfo.capabilities.storage_protocol =="lvm"
                ){
                    self.extendTip = $translate.instant("aws.volumes.tip.tip21");
                }
                else if (
                    (values[0].status == "available" && self.btn.limit["os-extend"])||
                    (values[0].status == "in-use" && self.btn.limit["os-online-extend"])){
                        canExtendFunc(values[0]);
                } else {
                    if(!self.btn.limit["os-online-extend"]||!self.btn.limit["os-extend"]){
                        self.extendTip = $translate.instant("aws.volumes.tip.tip20");
                    }else{
                        self.extendTip = $translate.instant("aws.volumes.tip.tip6");
                    }
                }
            })()

        } else {
            self.btn.canStockTransfer = false;
            self.btn.canEdit = false;
            self.btn.canExtend = false;
            self.btn.canExtends = false;
            self.btn.canAttach = false;
            self.btn.canUnstall = false;
            self.btn.canCS = false;
            self.btn.canNewIns = false;
            self.btn.canBackup = false;
            self.storageTypeTip = $translate.instant("aws.volumes.tip.tip10");
        }
    }
    $scope.$on("volumeSocket", function (e, data) {
        //创建云硬盘和删除云硬盘弹出
        if (data.eventType == "volume.create.start" || data.eventType == "volume.delete.start" || "volume.soft_delete.start") {
            var tipMsg4 = data.resourceName + $translate.instant("aws.sockets.opLog." + data.eventType);
            alertSrv.set(data.requestId, tipMsg4, "building", 5000);
        }
        if (data.eventType == "volume.create.end") {
            var tipMsg5 = data.resourceName + $translate.instant("aws.sockets.opLog." + data.eventType);
            alertSrv.set(data.requestId, tipMsg5, "success", 5000);
            $timeout(function () {
                self.volume.initTablefunc()
            }, 300)
        }
        if (data.eventType == "volume.delete.end" || "volume.soft_delete.end") {
            var tipMsg6 = data.resourceName + $translate.instant("aws.sockets.opLog." + data.eventType);
            alertSrv.set(data.requestId, tipMsg6, "success", 5000);
            $timeout(function () {
                self.volume.initTablefunc()
            }, 300)
        }
    });
    
    $scope.$on("snapSocket", function (e, data) {
        $timeout(function () {
            self.volume.initTablefunc()
        }, 300)
    });

    self.lineDetailBack = function () {
        volumesDataSrv.clear = !volumesDataSrv.clear;
        utilTool.setLocalStorage([]);
        $location.url(`/cvm/volumes`)
    }
    $scope.$on("getDetail", function (event, value) {
        if (self.detailvalue != value) {
            self.detailvalue = value;
            self.detailData = {};
            volumesDataSrv.detailVolumeData(value).then(function (result) {
                self.detailData = {};
                if (result && result.data && angular.isArray(result.data)) {
                    if ($routeParams.id != value) { return; }
                    self.detailData = result.data[0];
                    self.detailData.description = self.detailData.description == "auto-created_from_restore_from_backup" ? self.detailData.description = "来自备份恢复" : self.detailData.description;
                    self.detailData.ori_type = angular.copy(self.detailData.type);
                    self.detailData.created = moment(new Date(self.detailData.created)).format('YYYY-MM-DD HH:mm:ss');
                    self.detailvalue = "";
                    if (self.detailData.storageInfo.name.indexOf("toyou") > -1) {
                        var postData = {
                            "volume_type_name": self.detailData.ori_type,
                        }
                        self.detailData.feature = [];
                        storageSrv.volume_type_analysis(postData).then(function (result) {
                            if (result && result.data && angular.isObject(result.data)) {
                                self.detailData.type = result.data.Storage_name;
                                self.detailData.pool = result.data.Pool_name
                                for (let key in result.data.Character_message) {
                                    result.data.Character_message[key] > 0 ? self.detailData.feature.push(key) : "";
                                }
                            }

                        })
                    }
                }

            });
            instancesSrv.getVolumeSnapshots({ volumeid: value }).then(function (res) {
                if (res && res.data && angular.isArray(res.data)) {
                    self.snapshotTable = new NgTableParams({ count: res.data.length }, { counts: [], dataset: res.data });
                }
            })
            backupsSrv.getVolumeBackups(value).then(function (res) {
                if (res && res.data && angular.isArray(res.data)) {
                    self.backupTable = new NgTableParams({ count: res.data.length }, { counts: [], dataset: res.data });
                }
            })
        }

    });
    self.$on("vmCloneSocket",function(){
        $timeout(function(){
            self.volume.initTablefunc()
        },500)
    })
    self.configSearch = function (data, tableCols) {
        var tableData = data;
        tableData.map(item => {
            editSearch(item, tableCols)
        })
        return tableData;
    }
    function editSearch(item, tableCols) {
        var searchTerm = []
        tableCols.map(search => {
            if (search.title && search.show) {
                searchTerm.push(item[search.field])
            }
        })
        item.searchTerm = searchTerm.join("\b");
        return item;
    }

    //获取云硬盘的详情
    async function getSnapFromVolumeDetail(value) {
        self.btn.isSelected = false;
        const result = await volumesDataSrv.detailVolumeData(value)
        if (result && result.data && angular.isArray(result.data)) {
            if (result.data[0].id == value) {
                self.btn.isSelected = true;
                if (result.data[0].snapshotNum > 0) { //有快照 不可卷迁移 20190123 by heliang
                    self.btn.canStockTransfer = false;
                    self.stockTransferTip = $translate.instant("aws.volumes.tip.tip18");
                }
                if (result.data[0].imageMetadata && result.data[0].imageMetadata.disk_format == "iso") {
                    self.btn.canAttach = false;
                    self.attachTip = $translate.instant("aws.volumes.tip.tip4");
                }
                self.detailData = result.data[0];
                for (var i = 0; i < result.data.length; i++) {
                    self.btn.canUnstall2 = false;
                    if (JSON.stringify(result.data[i].attachments).indexOf("/dev/hd") > -1) {
                        self.btn.canUnstall2 = true;
                        self.unistallTip = "存在'/dev/hd*'类型磁盘不能进行卸载,请到云主机列表确认关机卸载";
                        break;
                    }
                }
            }
        }
    }

    function canResetStatus(obj) {
        self.btn.resetVol = true;
        if (obj.status != "retyping") {
            self.btn.resetVol = false;
            self.resetVolumeStatusTip = $translate.instant("aws.volumes.tip.tip14");
        }
    }
    //判断是否能新建云主机
    function canNewIns(obj) {
        self.btn.canNewIns = true;
        if (obj.magrationing) {
            self.btn.canNewIns = false;
            self.newInsTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }
        if (!obj.bootable || obj.status != "available") {
            self.btn.canNewIns = false;
            self.newInsTip = $translate.instant("aws.volumes.tip.tip1");
            return;
        }
        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canNewIns = false;
            self.newInsTip = $translate.instant("aws.volumes.tip.tip4");
        }
    }
    //判断是否能备份
    function canBackup(obj) {
        self.btn.canBackup = true;
        if (obj.magrationing) {
            self.btn.canBackup = false;
            self.newBackupTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }
        if (obj.status != "available" && obj.status != "in-use") {
            self.btn.canBackup = false;
            self.newBackupTip = $translate.instant("aws.volumes.tip.tip2");
            return;
        }
        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canBackup = false;
            self.newBackupTip = $translate.instant("aws.volumes.tip.tip4");
            return;
        }
        if (obj.type.toLowerCase().indexOf("nfs") > -1 && obj.status == "in-use") {
            self.btn.canBackup = false;
            self.newBackupTip = $translate.instant("aws.volumes.tip.tip15");
            return
        }
        if (!self.btn.limit["share_volumes_backups"] && obj.multiattach && obj.attachments && obj.attachments.length > 1) {
            self.btn.canBackup = false;
            self.newBackupTip = $translate.instant("aws.volumes.tip.tip11");

        }
        if (obj.status == "available" || obj.status == "in-use") {
            self.btn.hasTaskbackup = true;
            hasTaskJob(obj.id, 1, "backup")
        }
    }

    function isVolumeHasBackupJob(obj) {
        let volumeIdArr = [obj.id], hasJob = false;
        instancesSrv.checkServerHasBackupJob({ 'volumeId': volumeIdArr }).then(function (res) {
            if (res && res.data && angular.isArray(res.data)) {
                for (var i = 0; i < res.data.length; i++) {
                    //云主机有备份任务存在，请先解绑备份任务，再进行删除操作
                    if (obj.id == res.data[i].volumeId) {
                        hasJob = true;
                        self.btn.canForeDel = false;
                        self.btn.canDel = false;
                        self.btn.canExtend = false;
                        self.btn.canExtends = false;
                        self.delTip = $translate.instant("aws.volumes.tip.hasBackupJobNotDel");
                        self.forceDelTip = $translate.instant("aws.volumes.tip.hasBackupJobNotDel");
                        self.extendTip = $translate.instant("aws.volumes.tip.hasBackupJobNotExtend");
                        break;
                    }
                }
            }
            return hasJob;
        });
    }

    //判断是否能删除 、强制删除
    function canDelFunc(obj) {
        self.btn.canDel = true;
        var cantDelArray = ["deleting", "detaching", "in-use", "attaching", "creating", "error-deleting", "retyping", "backing-up"];
        _.forEach(cantDelArray, function (item) {
            if (obj.status == item) {
                self.btn.canDel = false;
                self.delTip = $translate.instant("aws.volumes.tip.tip16");
                return;
            }
        });

        if (obj.magrationing) {
            self.btn.canDel = false;
            self.delTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }

        if (self.btn.canDel) {
            if (obj.snapshotNum > 0) {
                self.btn.canDel = false;
                self.delTip = $translate.instant("aws.volumes.tip.tip17");
            }
        }

        //云硬盘下面是否存在备份任务
        if (self.btn.canDel) {
            isVolumeHasBackupJob(obj);
        }
    }
    function canForceDelFunc(obj) {
        self.btn.canForeDel = true;
        var cantForeDelArray = ["attaching", "in-use", "detaching", "retyping", "backing-up"];
        _.forEach(cantForeDelArray, function (item) {
            if (obj.status == item) {
                self.btn.canForeDel = false;
                self.forceDelTip = $translate.instant("aws.volumes.tip.tip16");
                return;
            }
        });
        //正在迁移
        if (obj.magrationing) {
            self.btn.canForeDel = false;
            self.forceDelTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }

        if (self.btn.canForeDel) {
            if (obj.snapshotNum > 0) {
                self.btn.canForeDel = false;
                self.forceDelTip = $translate.instant("aws.volumes.tip.tip17");
            }
        }
        //云硬盘下面是否存在备份任务
        if (self.btn.canForeDel) {
            isVolumeHasBackupJob(obj);
        }
    }

    //判断是否能编辑
    function canEditFunc(obj) {
        self.btn.canEdit = true;
        if (obj.magrationing) {
            self.btn.canEdit = false;
            self.editTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }
        if (obj.status != "available" && obj.status != "in-use") {
            self.btn.canEdit = false;
            self.editTip = $translate.instant("aws.volumes.tip.tip2");
            return;
        }
        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canEdit = false;
            self.editTip = $translate.instant("aws.volumes.tip.tip4");
        }
    }

    //判断是否能够卷迁移
    function canStockTransferFunc(obj) {
        self.btn.canStockTransfer = true;
        if (obj.magrationing) {
            self.btn.canStockTransfer = false;
            self.stockTransferTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }
        if (obj.status != "available" && obj.status != "in-use") {
            self.btn.canStockTransfer = false;
            self.stockTransferTip = $translate.instant("aws.volumes.tip.tip2");
            return;
        }
        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canStockTransfer = false;
            self.stockTransferTip = $translate.instant("aws.volumes.tip.tip4");
            return;
        }
        if (obj.snapshotNum > 0) {
            self.btn.canStockTransfer = false;
            self.stockTransferTip = $translate.instant("aws.volumes.tip.tip18");
            return;
        }

        //如果没有可以迁移的存储则按钮被点击
        let multiattach = obj.multiattach;
        let transferStorageList = [];
        let volumeTypeId = obj.storageInfo.volumeTypeId;
        volumesDataSrv.stockTransferList().then(function (res) {
            if (res && res.data && res.data && res.data.length > 0) {
                res.data.forEach(function (item, index) {
                    if (item.id != volumeTypeId) {
                        if (multiattach && item.multiattach == "true") {
                            transferStorageList.push(item)
                        } else if (!multiattach) {
                            transferStorageList.push(item)
                        }
                    }
                })
                if (transferStorageList.length == 0) {
                    self.btn.canStockTransfer = false;
                    self.stockTransferTip = $translate.instant("aws.volumes.tip.noAvailableStorage");
                    return;
                }
            }
        })
    }

    //判断是否能扩展
    function canExtendFunc(obj) {
        self.btn.canExtend = true;
        self.btn.canExtends = true;
        if (obj.magrationing) {
            self.btn.canExtend = false;
            self.btn.canExtends = false;
            self.extendTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }

        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canExtend = false;
            self.btn.canExtends = false;
            self.extendTip = $translate.instant("aws.volumes.tip.tip4");
            return;
        }
        //云硬盘下面是否存在备份任务
        if (self.btn.canExtend || self.btn.canExtends) {
            isVolumeHasBackupJob(obj);
        }
        toyouCha(obj)

    }

    function toyouCha(obj) {
        if (obj.storageInfo && obj.storageInfo.name.indexOf("toyou") > -1) {
            var postData = {
                "volume_type_name": obj._type,
            }
            self.btn.canExtend = false;
            self.btn.canExtends = false;
            self.btn.canCS = false;
            storageSrv.volume_type_analysis(postData).then(function (result) {
                if (result && result.data && angular.isObject(result.data)) {
                    if (result.data.Character_message) {
                        if (result.data.Character_message.hyperswap == 1) {
                            self.CSTip = $translate.instant("aws.volumes.tip.tip7");
                        } else {
                            self.btn.canExtend = true;
                            self.btn.canExtends = true;
                            self.btn.canCS = true;
                        }
                    }
                }
            })
        }
    }

    function canAttachFunc(obj) {
        self.btn.canAttach = true;
        if (obj.magrationing) {
            self.btn.canAttach = false;
            self.attachTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }
        if (obj.multiattach) {
            if (obj.status != "available" && obj.status != "in-use") {
                self.btn.canAttach = false;
                self.attachTip = $translate.instant("aws.volumes.tip.tip2");
            }
        } else {
            if (obj.status != "available") {
                self.btn.canAttach = false;
                self.attachTip = $translate.instant("aws.volumes.tip.tip6");
            }
        }
        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canAttach = false;
            self.attachTip = $translate.instant("aws.volumes.tip.tip4");
        }
    }

    //判断是否能卸载
    function canUnstallFunc(obj) {
        self.btn.canUnstall = true;
        if (obj.magrationing) {
            self.btn.canUnstall = false;
            self.unistallTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }
        if (obj.status != "in-use") {
            self.btn.canUnstall = false;
            self.unistallTip = $translate.instant("aws.volumes.tip.tip9");
        }
        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canUnstall = false;
            self.unistallTip = $translate.instant("aws.volumes.tip.tip4");
        }
    }

    //判断是否能创建快照
    function canCSFunc(obj) {
        self.btn.canCS = true;
        if (obj.magrationing) {
            self.btn.canCS = false;
            self.CSTip = $translate.instant("aws.volumes.tip.tip19");
            return;
        }
        if (obj.status != "available" && obj.status != "in-use") {
            self.btn.canCS = false;
            self.CSTip = $translate.instant("aws.volumes.tip.tip2");
            return;
        }
        if (obj.imageMetadata && obj.imageMetadata.disk_format == "iso") {
            self.btn.canCS = false;
            self.CSTip = $translate.instant("aws.volumes.tip.tip4");
            return;
        }
        if (!self.btn.limit["share_volumes_snapshots"] && obj.multiattach && obj.attachments && obj.attachments.length > 1) {
            self.btn.canCS = false;
            self.CSTip = $translate.instant("aws.volumes.tip.tip11");

        }
        if (obj.status == "available" || obj.status == "in-use") {
            self.btn.hasTasksnapshot = true;
            hasTaskJob(obj.id, 0, "snapshot")
        }
        toyouCha(obj)
    }

    function hasTaskJob(volumeId, type, name) {
        backupsSrv.checkSheduleJob({ volumeId: volumeId, jobType: type }).then(function (res) {
            if (res && res.data && angular.isArray(res.data)) {
                self.btn["hasTask" + name] = Boolean(res.data.length);
                if (name == "backup" && self.btn.hasTaskbackup) {
                    self.newBackupTip = $translate.instant("aws.volumes.tip.tip3");
                }
                if (name == "snapshot" && self.btn.hasTasksnapshot) {
                    self.CSTip = $translate.instant("aws.volumes.tip.tip8");
                }
            }
        })
    }

    self.openVolumeModel = function (type, editData, init) {
        if (type == "new") {
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "js/cvm/volumes/tmpl/volumeModel.html",
                controller: "volumeController",
                resolve: {
                    type: function () {
                        return type;
                    },
                    editData: function () {
                        return editData;
                    },
                    initTable: function () {
                        return init;
                    }
                }
            });
        } else if (type == "edit") {
            if (!self.btn.canEdit) return;
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "volumeUpdateModel.html",
                controller: "volumeController",
                resolve: {
                    type: function () {
                        return type;
                    },
                    editData: function () {
                        return angular.copy(editData);
                    },
                    initTable: function () {
                        return init;
                    }
                }
            });
        } else if (type == "systemEdit") {
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "volumeUpdateModel.html",
                controller: "volumeController",
                resolve: {
                    type: function () {
                        return type;
                    },
                    editData: function () {
                        return angular.copy(editData);
                    },
                    initTable: function () {
                        return init;
                    }
                }
            });
        }
    };

    //向父元素传递要删除的信息和数据  数据盘使用新接口 软删除进入回收站
    self.deleteSoftVolumes = function (checkedItems) {
        if (!self.btn.canDel || self.btn.delisDisabled) return;
        var content = {
            target: "deleteSoftVolume",
            msg: "<span>" + $translate.instant("aws.volumes.dv.confirmDelete") + "</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("deleteSoftVolume", function (e, data) {
        if (data.length == 1) {
            newCheckedSrv.setChkIds([data[0].id], "volumedelete")
            volumesDataSrv.delSoftOneVolumeAction(data[0].id).then(function () {

            });
        } else if (data.length > 1) {
            //获取删除的卷id数组
            var volume_ids = [];
            _.forEach(data, function (item) {
                volume_ids.push(item.id);
            });
            var delParams = {
                ids: volume_ids
            };
            newCheckedSrv.setChkIds(volume_ids, "volumedelete")
            volumesDataSrv.delSoftVolumes(delParams).then(function () {

            });
        }
    });

    //向父元素传递要删除的信息和数据  系统盘和镜像缓存盘沿用以前的删除 不进入回收站
    self.deleteVolumes = function (checkedItems) {
        if (!self.btn.canDel || self.btn.delisDisabled) return;
        var content = {
            target: "delVolume",
            msg: "<span>" + $translate.instant("aws.volumes.dv.confirmDelete") + "</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delVolume", function (e, data) {
        if (data.length == 1) {
            newCheckedSrv.setChkIds([data[0].id], "volumedelete")
            volumesDataSrv.delOneVolumeAction(data[0].id).then(function () {

            });
        } else if (data.length > 1) {
            //获取删除的卷id数组
            var volume_ids = [];
            _.forEach(data, function (item) {
                volume_ids.push(item.id);
            });
            var delParams = {
                ids: volume_ids
            };
            newCheckedSrv.setChkIds(volume_ids, "volumedelete")
            volumesDataSrv.delVolumes(delParams).then(function () {

            });
        }
    });

    self.DistStockTransfer = function (editData, type, init) {
        if (!self.btn.canStockTransfer) return;
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "dist_stock_transfer.html",
            controller: "DistStockTransferCtrl",
            resolve: {
                initSystemVolumesTable: function () {
                    return init;
                },
                editData: function () {
                    return editData;
                },
                type: function () {
                    return type
                },
                detailData: () => self.detailData,
            }
        });
    };
}
function DistStockTransferCtrl(scope, volumesDataSrv, $translate, $uibModalInstance, initSystemVolumesTable, editData, detailData, type, backupsSrv, volumesSrv) {
    scope.submitInValid = false;
    scope.storageDevice = {
        name: ""
    }
    scope.submitInValidList = [];
    let id = editData[0].id;
    let _type = editData[0]._type;
    let volumeTypeId = editData[0].storageInfo.volumeTypeId;
    let multiattach;
    let attachedServerInfo;
    editData[0].attachments = detailData.attachments;
    //当前勾选云硬盘是否被挂载在云主机上面，若被挂载，需要判断改云主机上是否存在正在进行卷迁移
    function getVolServerVol() {
        var post = {
            ids: []
        }
        editData[0].attachments.map(item => {
            post.ids.push(item.server_id);
        })
        scope.serverHasRetyping = false;

        volumesSrv.serversVolumes(post).then(function (res) {
            if (res && res.data && angular.isArray(res.data)) {
                res.data.map(item => {
                    if (item.status == "retyping") {
                        scope.serverHasRetyping = true;
                    }
                })

            }

        })
    }
    // 系统盘被挂载 并且是 nfs存储
    function checkSysDisk() {
        if (editData[0].attachments.length > 0 && editData[0].storageInfo.capabilities.storage_protocol == 'nfs') {
            scope.sysDiskStatus = true;
        } else {
            scope.sysDiskStatus = false;
        }
    }
    // checkSysDisk();

    function stockTransferList(type) {
        switch (type) {
            //云硬盘界面暂时都是满足"fromData"情况
            case "fromData":
                multiattach = editData[0].multiattach;
                volumesDataSrv.stockTransferList().then(function (res) {
                    if (res && res.data && res.data && res.data.length > 0) {
                        res.data.forEach(function (item, index) {
                            if (item.id != volumeTypeId) {
                                if (multiattach && item.multiattach == "true") {
                                    scope.submitInValidList.push(item)
                                } else if (!multiattach) {
                                    scope.submitInValidList.push(item)
                                }
                            }
                        })
                    }
                })
                break;
            case "fromSystem":
                multiattach = editData[0].multiattach;
                volumesDataSrv.stockTransferList().then(function (res) {
                    if (res && res.data && res.data && res.data.length > 0) {
                        scope.submitInValidList = res.data;
                        scope.submitInValidList.forEach(function (item, index) {
                            if (item.id != volumeTypeId) {
                                if (multiattach && item.multiattach == "true") {
                                    scope.submitInValidList.push(item)
                                } else if (!multiattach) {
                                    scope.submitInValidList.push(item)
                                }
                            }
                        })
                    }
                })
                break;

        }

    }
    stockTransferList(type)
    getVolServerVol();

    scope.chooseStorageDevice = function (type) {
        scope.cancelMounted = false;
        scope.needShutOff = false;
        scope.needOpen = false;
        //获取该云硬盘下面所挂载的云主机
        backupsSrv.isVolumeExist(editData[0].id).then(function (res) {
            var attachedServerInfo = res;
            //系统盘
            if (angular.isArray(editData[0].attachments) && editData[0].attachments.length == 1 && editData[0].attachments[0].device && editData[0].attachments[0].device.slice(-2) == "da") {
                if (type.storageProtocol == "ceph") {
                    if (res && res.data && res.data.volume && res.data.volume.insnameAndId && res.data.volume.insnameAndId.length > 0 && (res.data.volume.insnameAndId[0].status != "shutoff" && res.data.volume.insnameAndId[0].status != "stopped" && res.data.volume.insnameAndId[0].status != "SHUTOFF")) {
                        scope.needShutOff = true;
                    }
                    // nfs系统盘并且迁移对象是ceph 不可点击并有提示信息； 20190124 by heliang
                    // if (scope.sysDiskStatus) {
                    //     scope.sysDiskInfo = true;
                    // } else {
                    //     scope.sysDiskInfo = false;
                    // }
                } else {
                    if (res && res.data && res.data.volume && res.data.volume.insnameAndId && res.data.volume.insnameAndId.length > 0 && (res.data.volume.insnameAndId[0].status != "active")) {
                        scope.needOpen = true;
                    }
                }
            } else {
                if (type.storageProtocol == "ceph") {
                    // if (editData[0].status == "in-use") {
                    //     scope.cancelMounted = true;
                    // } //6.1版本支持 云硬盘热迁移
                } else {
                    if (res && res.data && res.data.volume && res.data.volume.insnameAndId && res.data.volume.insnameAndId.length > 0 && (res.data.volume.insnameAndId[0].status != "active")) {
                        scope.needOpen = true;
                    }
                }
            }
        })
    }

    scope.stockTransferConfirm = function (fileFrom) {
        if (fileFrom.$valid && !scope.needShutOff && !scope.needOpen && !scope.cancelMounted && !scope.serverHasRetyping) {
            var postData = {
                "volumeId": id,
                "newType": scope.storageDevice.name.id
            }
            volumesDataSrv.stockTransfer(postData).then(function (res) {
                initSystemVolumesTable()
            })
            $uibModalInstance.close();
        } else {
            scope.submitInValid = true;
        }
    }
    scope.cancel = function () {
        $uibModalInstance.dismiss("cancel");
    };
}
export { volumesTabCtrl, DistStockTransferCtrl }
