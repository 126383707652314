import "./resviewSrv";
import {
	resViewBarChartDefault
} from "../../chartpanel";

var resviewCtrlModule = angular.module("resviewCtrlModule", ["resviewSrvModule"]);

resviewCtrlModule.controller('resviewCtrl', ['$scope', '$rootScope', 'resviewSrv','dataclusterSrv' ,'kbnSrv', "$translate","commonFuncSrv",
	function(self, $rootScope, resviewSrv,dataclusterSrv, kbnSrv, $translate,commonFuncSrv) {
		// let panelsList = ["cpu", "mem", "disk", "diskio_read_peak", "diskio_write_peak", "net_receive_rate_peak", "net_send_rate_peak"];
		// 平均值
		let panelsList = ["cpu", "mem", "disk", "diskio_read_avg", "diskio_write_avg", "net_receive_rate_avg", "net_send_rate_avg"];
		// 峰值
		let meanList = ["cpu_peak", "mem_peak", "disk", "diskio_read_peak", "diskio_write_peak", "net_receive_rate_peak", "net_send_rate_peak"];
		let chartTitle = {
			"cpu": $translate.instant("aws.monitor.resview.cpuRate"),
			"mem": $translate.instant("aws.monitor.resview.memoryRate"),
			"disk": $translate.instant("aws.monitor.resview.diskUseRate"),
			"diskio_read_peak": $translate.instant("aws.monitor.resview.diskIORead"),
			"diskio_write_peak": $translate.instant("aws.monitor.resview.diskIOWrite"),
			"net_receive_rate_peak": $translate.instant("aws.monitor.resview.netReciveRate"),
			"net_send_rate_peak": $translate.instant("aws.monitor.resview.netSendRate"),
			"cpu_peak": $translate.instant("aws.monitor.resview.cpuRate"),
			"mem_peak": $translate.instant("aws.monitor.resview.memoryRate"),
			"diskio_read_avg": $translate.instant("aws.monitor.resview.diskIORead"),
			"diskio_write_avg": $translate.instant("aws.monitor.resview.diskIOWrite"),
			"net_receive_rate_avg": $translate.instant("aws.monitor.resview.netReciveRate"),
			"net_send_rate_avg": $translate.instant("aws.monitor.resview.netSendRate"),
		}
		
		let chartUnit = {
			"cpu": "%",
			"mem": "%",
			"diskio_read_peak": "IOPS",
			"diskio_write_peak": "IOPS",
			"net_receive_rate_peak": "Mbps",
			"net_send_rate_peak": "Mbps",
			"cpu_peak": "%",
			"mem_peak": "%",
			"diskio_read_avg": "IOPS",
			"diskio_write_avg": "IOPS",
			"net_receive_rate_avg": "Mbps",
			"net_send_rate_avg": "Mbps"
		}
		let chartType = "line";
		self.panels = {};
		self.queryDisabled = false;
		self.options = {
			objTypeList: [{
				"name": $translate.instant("aws.monitor.resview.physical"),
				"value": "physical"
			}, {
				"name": $translate.instant("aws.monitor.resview.instance"),
				"value": "virtual"
			}],
			topTypeList: [{
				"name": $translate.instant("aws.monitor.resview.topHigh"),
				"value": "top"
			}, {
				"name": $translate.instant("aws.monitor.resview.topLow"),
				"value": "bottom"
			}],
			topLengthList: [{
				"name": "TOP10",
				"value": "10"
			}, {
				"name": "TOP5",
				"value": "5"
			}, {
				"name": "TOP3",
				"value": "3"
			}]
		};
		self.queryLimit = {
			objType: self.options.objTypeList[0].value,
			topType: self.options.topTypeList[0].value,
			topLength: self.options.topLengthList[0].value
		};
		self.filterData = {
            timeStep: '30m',
			queryType: '30m',
            from: null,
            to: null
        };

		var tickFormatterFunc = function(d, formatType, tickStep) {
			var _d;
			var axisTickFormatter = kbnSrv.valueFormats[formatType];
			var decimalPos = String(d).indexOf(".");
			var tickDecimals = decimalPos === -1 ? 0 : String(d).length - decimalPos - 1;
			var scaledDecimals = tickDecimals - Math.floor(Math.log(tickStep) / Math.LN10);
			_d = axisTickFormatter(d, tickDecimals, scaledDecimals);
			return _d;
		};

		var formatedTipValue = function(tipVal, formatType, tickStep) {
			tipVal = tickFormatterFunc(tipVal, formatType, tickStep);
			var _tipVal = Number(tipVal.split(" ")[0]);
			var tipValdecimalPos = String(_tipVal).indexOf(".");
			var tipValDecimals = tipValdecimalPos === -1 ? 0 : String(_tipVal).length - tipValdecimalPos - 1;
			if (tipValDecimals > 3) {
				if (_tipVal > 0 && _tipVal < 1) {
					tipVal = tipVal.split(" ")[1] ? _tipVal.toFixed(4) + " " + tipVal.split(" ")[1] : _tipVal.toFixed(4);
				} else if (_tipVal > 1) {
					tipVal = tipVal.split(" ")[1] ? _tipVal.toFixed(3) + " " + tipVal.split(" ")[1] : _tipVal.toFixed(3);
				}
			}
			return tipVal;
		};

		function handleDiskNodeName(data,nodePrettyNameList){
            data.forEach(function(item){
				const arr = item.name.split("(");
                let previousName = arr[0];
                let laterName="(" + arr[1];
				if(arr.length == 1){
					laterName = ''
				}
                item.name = nodePrettyNameList[previousName] + laterName;
			});
			return data;
		}

		function handleCpuNodeName(data,nodePrettyNameList){
            data.forEach(function(item){
                item.name=nodePrettyNameList[item.name];
			});
			return data;
		}

		function showPrettyNameOfNode(handleData,handleFunc,handleParams,regionUid){
            let handleResult="";
            var nodePrettyName={};
            dataclusterSrv.getNode(regionUid).then(function(res){
                if(res&&res.data&&angular.isArray(res.data)){
                    //先存localStorage.nodePrettyNameList
                    res.data.forEach(function(item){
                        nodePrettyName[item.hostName]=item.prettyName?item.prettyName:item.hostName;
                    });
                    handleResult=handleFunc(handleData,nodePrettyName,handleParams);
                }
                return handleResult;
            });
        }

		self.defaultType = 'average';
		self.showType = true;
		self.$on('main_type', function(e, value){
			self.defaultType = value;
		})

		self.query = function(type) {
			self.showType = self.defaultType == "average";
			self.queryDisabled = true;
			resviewSrv.getRegionData().then(function(res) {
				if (res && res.data) {
					self.queryDisabled = false;
					res.data = res.data.filter(item => {
						return item.status == 3;
					});
					self.options.dataRegionList = res.data;
					if (type == 'init') {
						self.queryLimit.dataRegion = self.options.dataRegionList[0];
						_.each(self.options.dataRegionList, item => {
							if (item.regionKey == localStorage.regionKey) {
								self.queryLimit.dataRegion = item;
							}
						})
					}
					// 查询总览数据
					const typeArr = self.showType ? panelsList : meanList;
					
					_.forEach(typeArr, function(key) {
						let _dList = [];
						let total = 0;
						let tickStep = 0;
						let topType = angular.copy(self.queryLimit.topType);
						self['load' + key] = true;
						// if (key == "cpu") {
						// 	if (self.queryLimit.topType == "top") {
						// 		topType = "bottom";
						// 	} else if (self.queryLimit.topType == "bottom") {
						// 		topType = "top";
						// 	}
						// }
						self.panels[key] = [];
						if(key == "disk") {
							resviewSrv.query({
								"regionCode": self.queryLimit.dataRegion.regionKey,
								"metrics": key,
								"objType": self.queryLimit.objType,
								"topType": topType,
								"topLength": Number(self.queryLimit.topLength),
								"queryType": self.filterData.queryType,
								"startTime": self.filterData.from ? moment(self.filterData.from).valueOf() : 0,
								"endTime": self.filterData.to ? moment(self.filterData.to).valueOf() : 0
							}).then(function(res) {
								res ? self['load' + key] = false : true;
								if (res && res.data && res.data.data) {
									_.each(res.data.data, function(item) {
										_dList.push(Number(item.dataValue));
										total += Number(item.dataValue);
									});
									tickStep = _.max(_dList) / Number(self.queryLimit.topLength);

									_.each(res.data.data, function(item) {
										let dataValue = angular.copy(item.dataValue);
										let hostName = angular.copy(item.hostName);
										// if (key == "cpu") {
										// 	dataValue = 100 - Number(dataValue);
										// }
										if (key != "cpu" && key != "mem") {
											hostName = hostName + "(" + item.keyName + ")";
										}
										if (key == "net_receive_rate_peak" || key == "net_send_rate_peak") {
											self.panels[key].push({
												inUsed: dataValue,
												name: hostName,
												type: "unit-adapt",
												total: total,
												val_unit: formatedTipValue(dataValue, 'bps', tickStep)
											});
										} else if (key == "diskio_read_peak" || key == "diskio_write_peak") {
											self.panels[key].push({
												inUsed: dataValue,
												name: hostName,
												type: "unit-adapt",
												total: total,
												val_unit: formatedTipValue(dataValue, 'ops', tickStep)
											});
										} else {
											self.panels[key].push({
												inUsed: dataValue,
												name: hostName,
												type: "percent",
												total: 100,
												precision: 2
											});
										}
										if (self.queryLimit.topType == "top") {
											self.panels[key] = _.sortBy(self.panels[key], 'inUsed').reverse();
										} else if (self.queryLimit.topType == "bottom") {
											self.panels[key] = _.sortBy(self.panels[key], 'inUsed');
										}
									});
									if(res.data.hostType=='physical'){
                                        showPrettyNameOfNode(self.panels[key],handleDiskNodeName,null,self.queryLimit.dataRegion.regionUid);
									}
								}
							});
						}else {
							resviewSrv.queryNew({
								"regionCode": self.queryLimit.dataRegion.regionKey,
								"metrics": key,
								"objType": self.queryLimit.objType,
								"topType": topType,
								"topLength": Number(self.queryLimit.topLength),
								"queryType": self.filterData.queryType,
								"startTime": self.filterData.from ? moment(self.filterData.from).valueOf() : 0,
								"endTime": self.filterData.to ? moment(self.filterData.to).valueOf() : 0
							}).then(function(res) {
								if (res && res.data) {
									if(!res.data.chartData) {
										self.panels[key] = {
											data: {
												xAxis: [],
												series: [],
											}
										}
										return;
									}
									var series = [];
									var xData = [];
									// 时间格式转换
									res.data.chartData.columes.map(item => {
										xData.push(moment(item).format("YYYY-MM-DD HH:mm:ss"));
									})
									if(key == "cpu" || key == "mem" || key == "diskio_read_peak" || key == "diskio_write_peak"|| key == "diskio_read_avg" || key == "diskio_write_avg") {
										for(let k in res.data.chartData.data) {
											res.data.chartData.data[k].map((item, index) => {
												res.data.chartData.data[k][index] = item?item.toFixed(2):0;
											});
										}
									} else if(key == "net_receive_rate_peak" || key == "net_send_rate_peak" || key == "net_receive_rate_avg" || key == "net_send_rate_avg") {
										for(let k in res.data.chartData.data) {
											res.data.chartData.data[k].map((item, index) => {
												res.data.chartData.data[k][index] = item?(item/(1024*1024)).toFixed(2):0;
											});
										}
									} else if(key == "diskio_read_peak" || key == "diskio_write_peak"|| key == "diskio_read_avg" || key == "diskio_write_avg") {
										for(let k in res.data.chartData.data) {
											res.data.chartData.data[k].map((item, index) => {
												res.data.chartData.data[k][index] = item?(item/1024).toFixed(2):0;
											});
										}
									}
									res.data.chartData.legend = [];
									for(var n in res.data.chartData.data){
										res.data.chartData.legend.push(n)
										var seriesItem = {
					                        name: n,
					                        type: chartType,
					                        data: res.data.chartData.data[n].map(item=>{
												if(key=="diskio_read_peak"||key=="diskio_write_peak"||key=="diskio_read_avg"||key=="diskio_write_avg"){
													return item ? Number(item).toFixed() : 0;
												}
												return item ? Number(item).toFixed(2) : 0;
											}) 
					                    }
					                    series.push(seriesItem);
									}
									//cpu,mem返回的是node-1;其他的返回的是类似node-1(sda),和diskhostname的处理一致
									if(key=='cpu'||key=='mem'){
										if(res.data.hostType=='physical'){
											showPrettyNameOfNode(series,handleCpuNodeName,null,self.queryLimit.dataRegion.regionUid);
										    //commonFuncSrv.showPrettyNameOfNode(series,handleCpuNodeName);	
										}
									}else{
										if(res.data.hostType=='physical'){
											showPrettyNameOfNode(series,handleDiskNodeName,null,self.queryLimit.dataRegion.regionUid);
                                            //commonFuncSrv.showPrettyNameOfNode(series,handleDiskNodeName);
										}
									}
									
									self.panels[key] = {
										title: chartTitle[key],
										data: {
											// legend: {
											// 	show: true,
											// 	orient: 'vertical',
											// 	top: 'middle',
											// 	left: 'auto',
											// 	right: 10
											// },
											legend: {
												show: true,
												orient: 'horizontal',
												top: 'bottom',
												left: 10,
											},
											gridBottom:'28%',
											unit: chartUnit[key],
											toolboxShow: false,
											xAxis: xData,
											series: series
										}
									};
									console.log(key, self.panels[key])
								}
							})
						}
					})

				}
			});
		};
		self.query('init');
	}
])