import "./recycleCsSrv";

var recycleCsModule = angular.module("recycleCsModule", ["recycleCsSrvModule"]);
recycleCsModule.controller("recycleCsCtrl",["$rootScope", "$scope", "NgTableParams", "recycleCsSrv", "checkedSrv", "$uibModal", "$translate","GLOBAL_CONFIG","$filter","generalSrv","$routeParams","TableCom","bandWidthSrv","utilTool","$timeout",
    function($rootScope, $scope, NgTableParams, recycleCsSrv, checkedSrv, $uibModal, $translate,GLOBAL_CONFIG,$filter,generalSrv,$routeParams,TableCom,bandWidthSrv,utilTool,$timeout) {
        var self = $scope;
        self.showalerttip = true;
        self.context = self;
        self.pageSize = 10;
        function getRecycleTime() {
            generalSrv.saasConfInfo('1111',localStorage.enterpriseUid,localStorage.regionUid).then(function(res) {
                if(res && res.data) {
                    var time = parseInt(res.data[0].paramValue * 24);
                    var unit = time > 24 ? $translate.instant("aws.recycle.day") : $translate.instant("aws.recycle.hour"); 
                    time = time > 24 ? time/24 : time;
                    self.recycle = {"time":time, "unit":unit};
                }
            });
        }

        bandWidthSrv.getLimitData().then(function(res) {
            if (res && res.data && res.data.length != 0) {
                self.limitData = JSON.parse(res.data[0].paramValue);
            }
        });

        self.headers = {
            name:$translate.instant("aws.recycle.diskName"),
            size:$translate.instant('aws.recycle.config'),
            deleted:$translate.instant('aws.recycle.updatetime'),
            domain:$translate.instant("aws.common.domain"),
            project:$translate.instant("aws.common.project"),
        }
        self.searchList = [];
        self.titleName="recyclecs";
        if(sessionStorage["recyclecs"]){
            self.tableCols=JSON.parse(sessionStorage["recyclecs"]);
        }else{
             self.tableCols = [
                 { field: "check", title: "",headerTemplateURL:"headerCheckbox.html",show: true },
                 { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                 { field: "size", title: self.headers.size,sortable: "size",show: true,disable:false},
                 { field: "deleted", title: self.headers.deleted,sortable: "deleted",show: true,disable:false},
             ];
        }

        //添加对项目名称的监听
        self.$watch(function(){
            return localStorage.selectedAllDomain
        },function(ne){
            utilTool.projectDomainTableCol(ne,2,self)
        })

        self.configSearch = function(data,tableCols){
            var tableData =  data;
            tableData.map(item => {
                editSearch(item,tableCols)
            })
            return tableData;
        }
        function editSearch(item,tableCols){
            var searchTerm = []
            tableCols.map(search => {
                if(search.title && search.show){
                    searchTerm.push(item[search.field])
                }
            })
            item.searchTerm =searchTerm.join("\b") ;
            return item;
        }
        self.formData = {
            "pageNum": "1",
            "pageSize": "10"
        }
       
        function successFunc(result) {
            //初始化表格
            var data = result.data;
            data.map(item => {
                item.deleted = $filter("date")(item.deleted,"yyyy-MM-dd HH:mm:ss");
                if(item.domainName=="default"){
                    item.domainName = $translate.instant("aws.common.defaultDepar")
                }else{
                    item.domainName = item.domainName;
                }
                if(item.projectName=="admin"){
                    item.projectName = $translate.instant("aws.common.defaultProject")
                }else{
                    item.projectName = item.projectName;
                }
                item.searchTerm = [item.name,item.size,item.deleted].join("\b");
            });
            self.tabledata = self.configSearch(data,self.tableCols);
            self.totalNum = result.total;
            self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: self.tabledata});
            var tableId = "uid";
            checkedSrv.checkDo(self, data, tableId);
        }

        getRecycleTime();

        function getrecyleList(options){
            self.loadData="";
            self.currentPage=options.pageNum;
            recycleCsSrv.getGcList(options).then(function(result){
                if(result && result.data && angular.isArray(result.data)){
                    successFunc(result); 
                }   
                result?self.loadData = true:"";
            });
        }

        var pageOption = {
            pageSize: self.pageSize,
            pageNum:1,
            pattern:null,
        };
        getrecyleList(pageOption)
        
        // 资源导出
        self.exportResource = function(){
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/volumes_gc?regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm?self.globalSearchTerm:""}`
            utilTool.downloadFile(url);
        }
        self.showExport = utilTool.isAllDomainSelect;

        // 切换页码
        self.changePage=function(options){
            options.pattern = self.globalSearchTerm?self.globalSearchTerm:null
            getrecyleList(options);
        };

        // 后端搜索
        self.searchBackend = function(options){
            var pageOption = {
                pageSize: self.pageSize,
                pageNum:1,
                pattern:self.globalSearchTerm?self.globalSearchTerm:null,
            };
            if(options.type=='refresh'){
                self.currentPage = 1;
                self.globalSearchTerm = null;
                pageOption.pattern = null;
            }
            getrecyleList(pageOption);
        }

        //恢复云云硬盘
        self.restoreRes = function() {
            var text = $translate.instant("aws.instances.tipMsg.restoreDisk")
            var content = {
                target: "restoreRes",
                msg:"<span>"+ text + "</span>",
                type: "info",
                btnType: "btn-primary"
            };
            self.$emit("delete", content);
        };
        
        self.$watch(function(){
            return self.checkedItems;
        },function(values){
            self.canClear=false;
            self.canRecovery=false;
            if(!values){
               return;
            }
            if(values.length==1){
                self.canClear=true;
                self.canRecovery=true;
            }else if(values.length>1){
               self.canClear=true;
               self.canRecovery=true;
               var projectUidList = [values[0]];
               for(var i=0;i<values.length;i++){
                    if(values[i].projectUid!==projectUidList[0].projectUid) {
                        self.canRecovery=false;
                        break;
                    }
               }
            }
        },true);
    
        self.$on("restoreRes", function() {
            var data = [{ name:"gigabytes",size:0},{name:"volumes",size:0}]
            var params = { 
                enterpriseUid : localStorage.enterpriseUid,
                domainUid: '',
                projectUid: ''
            }
            var del_ids = [];
            self.notDel = true;
            self.checkedItems.map(item => {
                del_ids.push(item.uid);
                data[0].size += item.size;
                params.domainUid = item.domainUid;
                params.projectUid = item.projectUid;
            });
            data[1].size = self.checkedItems.length;
            recycleCsSrv.quotaCheck(data,params).then(function(res) {
                //配额校验通过了才能恢复
                if(res) {
                    checkedSrv.setChkIds(del_ids,"restore")
                    recycleCsSrv.osRestore({ids:del_ids}).then(function() {
                        self.searchBackend({type:"refresh"});
                    });
                }
            })
        });
        
        //强制删除云硬盘
        self.forceDelRes = function() {
            var content = {
                target: "forceDelRes",
                msg:"<span>"+ $translate.instant("aws.instances.tipMsg.forceDelVolume") + "</span>"
            };
            self.$emit("delete", content);
        };
        self.$on("forceDelRes", function() {
            var del_ids = [];
            self.checkedItems.map(item => {del_ids.push(item.uid);});
            checkedSrv.setChkIds(del_ids,"delete")
            var httpRequest = del_ids.length > 1 ? recycleCsSrv.osForceDelMultiple({ids:del_ids}) : recycleCsSrv.osForceDelOne(del_ids[0]);
            httpRequest.then(function() {
                // 有bug注释掉
                // self.searchBackend({type:"refresh"});
            });
        });

        self.close = function(){
            self.showalerttip = false;
        };

        // 解决点击彻底删除后调查询接口，查询接口数据有延迟，有时依然能查询到已经被删除的数据
        $rootScope.$on("volumeSocket",function(e,data){
            if(data.eventType == "volume.delete.end"){
                $timeout(function(){
                    self.searchBackend({type:"refresh"});
                },500)
            }
        })
    }
]);
