let comModule = angular.module('comModule',[
    "ngTable", 
    "ui.bootstrap"
])
.service("TableCom",["NgTableParams",function(NgTableParams){
    var watchCk,ckAll,pageChange;
    this.init = function(context,tableName,data,uid,pageConfig=10,checkboxes="checkboxes"){
        let page,counts;
        context[checkboxes] = { 
            checked: false,//全选按钮
            items: {}//所有当前页数据的checkbox选中状态
        };

        context[checkboxes] = { 
            checked: false,//全选按钮
            items: {}//所有当前页数据的checkbox选中状态
        };
        if(Object.prototype.toString.call(pageConfig)=="[object Number]"){
            page = pageConfig;
            counts = [];
        }else{
            page = pageConfig.page||10;
            counts = pageConfig.counts||[];
        }
        
        context[tableName] = new NgTableParams({ count: page }, { counts: counts, dataset: data });
            if(watchCk){//清除监控       
              //watchCk()
            }
            if(ckAll){//清除监控
              //ckAll();
            }
            if(pageChange){//清除监控
              //pageChange()
            }
       //监控每条数据的checkbox
        watchCk = context.$watch(function(){
            return context[checkboxes].items
        },function(ne){
            if(!ne){
                return;
            }
            var _length = context[tableName].data.length;
            var checked = 0,uncheck;
            //var arr = Object.values(context.checkboxes.items);
            var arr=[];
            _.forEach(context[checkboxes].items,function(item){
                arr.push(item);
            });
            if(arr.length>0){
                arr.forEach((item,i)=>{
                  checked+=Number(item);
                })
            }
            if(checked==_length){
                context[checkboxes].checked = true;
            }
            if(checked==0){
                context[checkboxes].checked = false;
            }
            angular.element(".table[ng-table-dynamic=\'"+tableName+" with tableCols\']").find(".select-all").prop("indeterminate", (checked != 0 && checked != _length));
            angular.element(".table[ng-table="+tableName+"]").find(".select-all").prop("indeterminate", (checked != 0 && checked != _length));
            angular.element(".table[ng-table="+tableName+"]").find(".select-all-"+tableName).prop("indeterminate", (checked != 0 && checked != _length));
            angular.element(".table[ng-table-dynamic=\'"+tableName+" with tableCols\']").find(".select-all-"+tableName).prop("indeterminate", (checked != 0 && checked != _length));
        },true)
        //监控全选的checkbox
        ckAll = context.$watch(function(){        
            return context[checkboxes].checked
        },function(ne){
            context[tableName].data.forEach(item=>{
            context[checkboxes].items[item[uid]] = ne;
          })
        },true)
      //监控ngtable的页码
        pageChange = context.$watch(function(){
            return context[tableName].page();
        },function(ne){//翻页之后全选状态取消
            context[checkboxes] = {
                checked: false,
                items: {}
              };
          })
      }
    return this;
  }])
.service("modalCom",["$uibModal",function($uibModal){
    this.init = function(template,controller,resolve){
        var modalInstance = $uibModal.open({
            templateUrl:template,
            backdrop:'static',
            controller:controller,
            resolve:resolve?resolve:null,
          })
          return modalInstance;
      }
    return this;
}])
.service("uiAlertModal",["$translate",function($translate){
    let _this = this;
    this.initData = {
        jxTagAlert:false,
        type:'warning',
        message:$translate.instant("aws.action.ok"),
        btn: {
            ok:$translate.instant("aws.action.ok"),
            cancel:$translate.instant("aws.action.cancel"),
        }
    };
    this.show = function(obj){

        this.initData.jxTagAlert = true;
        this.initData.type = obj.type?obj.type:"warning";
        this.initData.message = obj.message?obj.message:$translate.instant("aws.action.ok");
        this.initData.data = obj.data;
        if(obj.btn){
            this.initData.btn = _.extend({},this.initData.btn,obj.btn)
        }
        if(obj.callback){
            this.initData.callback = obj.callback
        }else{
            this.initData.callback = function(){
                _this.initData.jxTagAlert = false;
            }
        }
    }
    
    return this;
}])
.directive("uiAlertTpl",["uiAlertModal",function(uiAlertModal){
    return {
        restrict: "E",
        replace: true,
        scope:{},
        template: `<div class="delete-wrap" ng-show="initData.jxTagAlert">
            <div class="delete-alert" ng-class="{'delete-show':initData.jxTagAlert}">
              <div class="alert"
                ng-class="{
                    'warning':'alert-warning',
                    'success':'alert-success',
                    'danger':'alert-danger'
                }[initData.type]"
              >
                <button type="button" class="close" ng-click="AlertClose()">
                  <span>×</span>
                  <span class="sr-only">Close</span>
                </button>
                <div class="del-cont"><span ng-bind-html="initData.message"></span></div>
                <div class="btn-item">
                  <button type='button' class='btn' ng-click='AlertConfirm()'
                    ng-class="{
                        'warning':'btn-warning',
                        'success':'btn-primary',
                        'danger':'btn-danger'
                    }[initData.type]"
                  >{{initData.btn.ok}}</button>
                  <button type='button' class='btn btn-default' ng-click="AlertClose()">{{initData.btn.cancel}}</button>
                </div>
              </div>
            </div>
        </div>`,
        link: function (scope) {
            let context, recData;
            scope.initData = uiAlertModal.initData;
            // $rootScope.$on("ui-tag-alert", function (e, data) {
            //     scope.jxTagAlert = true;
            //     scope.message = data.msg;
            //     scope.type = data.type || "success";
            //     scope.btn = {
            //         ok:data.btn&&data.btn.ok?(data.btn.ok):"确定",
            //         cancel:data.btn&&data.btn.cancel?(data.btn.cancel):"取消"
            //     };
            //     context = e.targetScope;
            //     recData = data;
            // });
            scope.AlertClose = function () {
                context = null;
                recData = null;
                scope.initData.jxTagAlert = false;
            };
            scope.AlertConfirm = function () {
                scope.initData.callback(scope.initData.data);
                scope.initData.jxTagAlert = false;
            };
        }
    };

}])

export default comModule.name;