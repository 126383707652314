import "./billingSrv";
import {
	PiePanelDefault
} from "../../chartpanel";
import { filterModal } from './directive/filter-modal';
import { filterSelectGroup } from './directive/filter-select-group';
import { filterTips } from "./directive/filter-tips";
import { loading } from "./directive/loading";
var billingCtrlModule = angular.module("billingCtrlModule", ["billingSrvModule"]);

billingCtrlModule.controller('billingCtrl', ['$scope', '$rootScope', '$translate', 'billingSrv', 'NgTableParams', 'GLOBAL_CONFIG', '$compile', '$routeParams', '$location', 'resize',
	function(self, $rootScope, $translate, billingSrv, NgTableParams, GLOBAL_CONFIG, $compile, $routeParams, $location, resize) {
		let billingStatusRes = {};
		self.billingPage = false;
		self.billingStartTime = localStorage.billingStartTime;
		$rootScope.inBillingModule = true;
		self.closeBillingText = $translate.instant("aws.bill.closeBillingText");
		self.showMessage = false;
		self.messageTitle = $translate.instant("aws.bill.closeBillMessageTitle");

		// 关闭计费
        self.handleClickCloseBilling = function() {
            self.showMessage = !self.showMessage;
        }
		
		// 关闭计费确定提交数据
		self.closeBilling = function(){
			billingStatusRes.paramValue = "0";
			self.billingActive = false;
			updateBillingStatus(billingStatusRes);
		}

		function getBillingStatus() {
			billingSrv.getBillingStatus().then(function(res) {
				if (res && res.data && res.data[0]) {
					billingStatusRes = res.data[0];
					if (res.data[0].paramValue == "1") { //1 打开 0 关闭
						self.billingActive = true;
						self.billingPage = true;
						let startTime = new Date(res.data[0].updateTime);
						let hh = startTime.getHours();
						let mm = startTime.getMinutes();
						if (mm > 0) {
							hh = hh + 1;
						}
						startTime = moment(new Date(res.data[0].updateTime)).format("YYYY-MM-DD  ") + hh + ":00";
						self.billingStartTime = startTime;
					} else {
						self.billingActive = false;
						self.billingSwitchPage = true;
					}
				}
			});
		}
		getBillingStatus();

		self.billingSwitch = function() {
			billingStatusRes.paramValue = self.billingActive ? "1" : "0";
			updateBillingStatus(billingStatusRes);
		};

		function updateBillingStatus(billingStatusRes){
			billingSrv.updateBillingStatus(billingStatusRes).then(function(res) {
				if (res.status == "0") {
					if (self.billingActive) {
						$rootScope.billingActive = true;
						self.billingPage = true;
						self.billingSwitchPage = false;
						getBillingStatus();
						billingSrv.getLoginAccountConsumeData().then(function(res) {
							if (res) {
								$rootScope.loginAccountConsumeData = res.data;
							}
						});
					} else {
						$rootScope.billingActive = false;
						self.billingPage = false;
						self.billingSwitchPage = true;
					}
				}
			});
		}

	}
]);

billingCtrlModule.controller('billingStatisticCtrl', ['$scope', '$rootScope', '$translate', 'billingSrv', 'NgTableParams', 'resize',
	function(self, $rootScope, $translate, billingSrv, NgTableParams, resize) {
		self.timeTypeOptions = [{
			"name": $translate.instant('aws.bill.timeType.threeDays'),
			"value": "3d"
		}, {
			"name": $translate.instant('aws.bill.timeType.oneWeek'),
			"value": "7d"
		}, {
			"name": $translate.instant('aws.bill.timeType.oneMonth'),
			"value": "30d"
		}];
		self.billOptions = {
			regionList: [{
				name: $translate.instant('aws.consume.pleaseChoose'),
				value: null
			}],
			domainList: [{
				name: $translate.instant('aws.consume.pleaseChoose'),
				value: null
			}],
			projectList: [{
				name: $translate.instant('aws.consume.pleaseChoose'),
				value: null
			}],
			userList: [{
				name: $translate.instant('aws.consume.pleaseChoose'),
				value: null
			}]
		}
		self.filterData = {
			timeStep: '3d',
			queryType: '3d',
			from: null,
			to: null,
			selectedMonth: moment().subtract(1, 'months').format('YYYY-MM'),
			region: null,
			domain: null,
			project: null,
			user: null,
		}
		self.definedTimeText = $translate.instant('aws.bill.chooseDate');
		self.selectedType = 'region';   //  选择类型
		self.chartHeader = $translate.instant('aws.bill.region');
		self.fileName = $translate.instant("aws.bill.regionResConsumeStatistic") + ".csv";
		self.csvHeader = [$translate.instant("aws.bill.name"), $translate.instant("aws.bill.cost")];

		$('#statisticDateRangePicker').daterangepicker({
			"language": 'zh-CN',
			"singleDatePicker": false,
			"startDate": moment().subtract(1, 'months'),//单日历下拉 开始日期设为当前日期
			"maxDate" : moment(),
			"timePicker": false,
			"timePicker24Hour": false,
			"linkedCalendars": false,
			"autoUpdateInput": false,
			"opens": "left",
			"locale": {
				format: 'YYYY-MM-DD',
				separator: ' 至 ',
				applyLabel: "确定",
				cancelLabel: "取消",
				daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
				monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
			}
		});
		$('#statisticDateRangePicker').on('apply.daterangepicker',function(ev, picker) {
			let startTime = picker.startDate.format(picker.locale.format);
			let endTime = picker.endDate.format(picker.locale.format);
			let separator = picker.locale.separator;
			self.filterData.from = startTime;
			self.filterData.to = endTime;
			if(!picker.startDate || !picker.endDate) {
				$("#statisticDateRangePicker .time-input").val('');
			}else {
				var definedTimeText = startTime + separator + endTime;
				$("#statisticDateRangePicker .time-input").val(definedTimeText);
				self.definedTimeText = definedTimeText;
			}
			self.$apply();
		});

		// 选择时间
		self.timeSelectTab = function(step) {
			self.filterData.timeStep = step;
			self.filterData.from = null;
			self.filterData.to = null;
			self.filterData.queryType = null;
			if(step != "defined"){
				self.definedTimeText = $translate.instant('aws.bill.chooseDate');
				self.filterData.queryType = step;
			}
		};
		// 切换数据中心
		self.changeRegion = function(val) {
			self.filterData.domain = null;
			self.filterData.project = null;
			self.filterData.user = null;
			self.selectedType = 'region';
			getRegionDomainProjectUser('domain');
			getRegionDomainProjectUser('project');
			getRegionDomainProjectUser('user');
		};
		// 切换部门
		self.changeDomain = function(val) {
			self.filterData.project = null;
			self.filterData.user = null;
			self.selectedType = val !== null ? 'domain' : 'region';
			getRegionDomainProjectUser('project');
			getRegionDomainProjectUser('user');
		};
		// 切换项目
		self.changeProject = function(val) {
			self.filterData.user = null;
			self.selectedType = val !== null ? 'project' : 'domain';
			getRegionDomainProjectUser('user');
		};
		// 切换用户
		self.changeUser = function(val) {
			self.selectedType = val !== null ? 'user' : 'project';
		};

		// 获取费用比例
		function getCostRatioData() {
			let [groupBy,nameStr,valueStr] = [null,'',''];
			switch (self.selectedType) {
				case 'region':
					groupBy = 'region';
					nameStr = 'region';
					valueStr = 'region';
					break;
				case 'domain':
					groupBy = 'deptId';
					nameStr = 'deptName';
					valueStr = 'deptId';
					break;
				case 'project':
					groupBy = 'projectId';
					nameStr = 'projectName';
					valueStr = 'projectId';
					break;
				case 'user':
					groupBy = 'userId';
					nameStr = 'userName';
					valueStr = 'userId';
					break;
				default:
					break;
			};
			let params = {
				"region": self.filterData.region || null,
				"deptId": self.filterData.domain || null,
				"projectId": self.filterData.project || null,
				"userId": self.filterData.user || null,
				"queryType": self.filterData.queryType,
				"startTime": self.filterData.from,
				"endTime": self.filterData.to,
				"groupBy": groupBy
			};
			billingSrv.getCostRatioData(params).then(function(res) {
				if (res && res.data) {
					self.consumeChart = new PiePanelDefault();
					self.consumeChart.panels.pieType = "category";

					let consumeData = [];
					let total = 0;
					_.each(res.data, function(item) {
						consumeData.push({
							id: item[valueStr],
							name: item[nameStr],
							value: item.consumptionAmount
						});
						item.consumptionAmount = item.consumptionAmount ? item.consumptionAmount.toFixed(2) : 0;
						total += Number(item.consumptionAmount);
					});
					_.map(consumeData, item => {
						let org_percent = Number(total) == 0 ? 0 : (Number(item.value) / Number(total)) * 100;
						item.percent = org_percent.toFixed(2) + "%";
						item.value = item.value.toFixed(2);
						return item;
					});
					self.consumeChart.panels.data = consumeData;
					self.consumeTotal = $rootScope.miliFormat(total.toFixed(2));

					self.annularData1 = {
						"id":'annularData1',
						"chartData":getChartData(self.consumeChart.panels.data)
					};
					self.annularPara1 = {
						"colorList":getColorList(self.consumeChart.panels),
						'center':['50%','50%'],
						'radius':['75%','100%'],
						'chartTitle':[
							{
								"text":self.chartHeader+$translate.instant('aws.bill.costRatio'),
								'left': '48%',
								'top': "48%",
								'textStyle': {
									'fontWeight': 'normal',
									'color': '#666666',
									'fontSize': 12
								}
							}
						]
					};
				}
			});
		}

		// 获取资源比例
		function getResRatioData() {
			let params = {
				"region": self.filterData.region || null,
				"deptId": self.filterData.domain || null,
				"projectId": self.filterData.project || null,
				"userId": self.filterData.user || null,
				"queryType": self.filterData.queryType,
				"startTime": self.filterData.from,
				"endTime": self.filterData.to
			};
			return billingSrv.getResConsumeData(params).then(function(res) {
				if (res && res.data) {
					self.resConsumeChart = new PiePanelDefault();
					self.resConsumeChart.panels.pieType = "category";
					
					let resourceData = [];
					let total = 0;
					self.csvDownloadData = [];
					_.each(res.data, function(item) {
						resourceData.push({
							name: item.resourceName,
							value: item.consumptionAmount,
							percent: item.proportion
						});
						item._resourceName = $translate.instant("aws.bill." + (item.resourceName).toLowerCase());
						total += Number(item.consumptionAmount);
						self.csvDownloadData.push([item._resourceName, $rootScope.miliFormat(item.consumptionAmount)]);
					});
					_.map(resourceData, item => {
						item.name = $translate.instant("aws.bill." + (item.name).toLowerCase());
						item.percent = (Number(item.percent) * 100).toFixed(2) + "%";
						item.value = item.value.toFixed(2);
						return item;
					});
					self.resConsumeChart.panels.data = resourceData;
					self.resConsumeTotal = $rootScope.miliFormat(total.toFixed(2));
					self.billingStatisticTable = new NgTableParams({ count: 999 }, { counts: [], dataset: res.data });

					self.annularData2 = {
						"id":'annularData2',
						"chartData":getChartData(self.resConsumeChart.panels.data)
					};
					self.annularPara2 = {
						"colorList":getColorList(self.resConsumeChart.panels),
						'center':['50%','50%'],
						'radius':['75%','100%'],
						'chartTitle':[
							{
								"text":$translate.instant('aws.bill.resource')+$translate.instant('aws.bill.costRatio'),
								'left': '48%',
								'top': "48%",
								'textStyle': {
									'fontWeight': 'normal',
									'color': '#666666',
									'fontSize': 12
								}
							}
						]
					};
				}
			});
		}
		// 获取数据中心，部门，项目，用户
		function getRegionDomainProjectUser(type) {
			let [serviceStr,listStr,nameStr,valueStr] = ['','','',''];
			let params = {
				"region": self.filterData.region || null,
				"deptId": self.filterData.domain || null,
				"projectId": self.filterData.project || null,
				"queryType": self.filterData.queryType,
				"startTime": self.filterData.from,
				"endTime": self.filterData.to
			};
			switch (type) {
				case 'region':
					serviceStr = 'getRegionConsumeData';
					listStr = 'regionList';
					nameStr = 'region';
					valueStr = 'region';
					self.fileName = $translate.instant("aws.bill.regionResConsumeStatistic") + ".csv";
					break;
				case 'domain':
					serviceStr = 'getDepConsumeData';
					listStr = 'domainList';
					nameStr = 'deptName';
					valueStr = 'deptId';
					self.fileName = $translate.instant("aws.bill.domainResConsumeStatistic") + ".csv";
					break;
				case 'project':
					serviceStr = 'getProConsumeData';
					listStr = 'projectList';
					nameStr = 'projectName';
					valueStr = 'projectId';
					self.fileName = $translate.instant("aws.bill.projectResConsumeStatistic") + ".csv";
					break;
				case 'user':
					serviceStr = 'getUserConsumeData';
					listStr = 'userList';
					nameStr = 'userName';
					valueStr = 'userId';
					self.fileName = $translate.instant("aws.bill.userResConsumeStatistic") + ".csv";
					break;
				default:
					break;
			}
			return billingSrv[serviceStr](params).then(function(res) {
				if (res && res.data) {
					self.billOptions[listStr] = [
						{
							name: $translate.instant('aws.consume.pleaseChoose'),
							value: null
						},
						{
							name: $translate.instant('aws.consume.all'+type),
							value: ''
						},
					];
					_.map(res.data, function(item) {
						self.billOptions[listStr].push({
							name: item[nameStr],
							value: item[valueStr]
						});
					});
				}
			});
		}
		function initChartData() {
			
		}
		getRegionDomainProjectUser('region');
		getRegionDomainProjectUser('domain');
		getRegionDomainProjectUser('project');
		getRegionDomainProjectUser('user');
		getCostRatioData();
		getResRatioData();

		function getChartData(source) {
			let target = [];
			source.forEach((it,i)=>{
				if(it.value!='0.00') {
					target.push({'name':it.name,'value':it.value,'percent':it.percent})
				}
			});
			return target;
		}
		function getColorList(source) {
			let target = [];
			source.data.forEach((it,i)=>{
				if(it.value!='0.00') {
					target.push(source.colors[i]);
				}
			});
			return target;
		}
		// 查询
		self.query = function() {
			self.chartHeader = $translate.instant('aws.bill.'+self.selectedType);
			getCostRatioData();
			getResRatioData();
		}

		self.downloadData = function() {
			return self.csvDownloadData;
		}

		resize().call(function() {
			if (document.body.clientWidth < 1680) {
				self.resMedia = true;
			} else {
				self.resMedia = false;
			}
		});
	}
]);

billingCtrlModule.controller('userConsumeDetailCtrl', ['$scope', '$location', '$translate', '$rootScope', 'billingSrv', 'billingHeaderSrv', 'NgTableParams', 'GLOBAL_CONFIG', '$routeParams',
	function($scope, $location, $translate, $rootScope, billingSrv, billingHeaderSrv, NgTableParams, GLOBAL_CONFIG, $routeParams) {
		var self = $scope;
		$rootScope.$on("userConsumeDetail", function(e, data) {
			self.regionName = data.regionName;
			self.deptId = data.deptId;
			self.projectId = data.projectId;
			self.userName = data.name;
			self.userId = data.id || data.consumeUserId;
			// $rootScope.billOptions.usersList = billingSrv.billOptions.usersList;
			self.filterData = {
				timeStep: "3d",
				queryType: "3d",
				from: moment().subtract(3, "d").format("YYYY-MM-DD"),
				to: moment().format("YYYY-MM-DD"),
				searchTerm: "",
				definedTime: false,
				definedTimeText: $translate.instant('aws.bill.chooseDate')
			};
			self.fileName = $translate.instant("aws.bill.userResConsumeStatistic") + ".csv";
			self.csvHeader = [$translate.instant("aws.bill.name"), $translate.instant("aws.bill.cost")];

			// self.filterData.region = billingSrv.region;
			// self.filterData.domain = billingSrv.domain;
			// self.filterData.project = billingSrv.project;
			// _.each($rootScope.billOptions.usersList, item => {
			// 	if (item.consumeUserId == self.userId) {
			// 		self.filterData.user = item;
			// 	}
			// });
			// billingSrv.user = self.filterData.user;
			if(!data.from) {
				billingHeaderSrv.getRegionList(self);
				billingHeaderSrv.getDomainList(self);
				billingHeaderSrv.getProjectList(self);
				billingHeaderSrv.getUserList(self);
			}

			self.changeBillFilter = function(data, type) {
				if(type == "region" || data.id == "backRegion") {
					$location.path($location.path()).search({
		                "id": self.filterData.region.id,
		                "type":"region"
		            });
				}else if(type == "domain" || data.id == "backDomain") {
					$location.path($location.path()).search({
		                "id": self.filterData.domain.id,
		                "name": self.filterData.domain.name,
		                "regionName": self.filterData.region.id,
		                "type":"dep"
		            });
				}else if(type == "project" || data.id == "backProject") {
					// if(data.id == "allProject"){
					// 	self.filterData.domain = {
					// 		name: "所有部门",
					// 		id: "allDomain"
					// 	}
					// }
					$location.path($location.path()).search({
		                "id": self.filterData.project.id,
		                "name": self.filterData.project.name,
		                "regionName": self.filterData.region.id,
		                "deptId": self.filterData.domain.id,
		                "type":"pro"
		            });
				}else if(type == "user") {
					self.userId = data.consumeUserId!='allUser'?data.consumeUserId:null;
					self.query();
				}
			};

			self.$watch(function() {
				return $routeParams;
			}, function(value) {
				if (value && (value.id || value.consumeUserId)) {
					self.currPage = value.type;
				}
			});

			function initConsumeStatisticTable(tableData) {
				self.resOfUserConsumeStatisticTable = new NgTableParams({
					count: GLOBAL_CONFIG.PAGESIZE
				}, {
					counts: [],
					dataset: tableData
				});
			}

			function getUserResConsumeData() {
				billingSrv.getResConsumeData({
					projectId: $location.search().projectId?$location.search().projectId:null,
					userId: self.userId,
					queryType: self.filterData.queryType,
					startTime: self.filterData.from,
					endTime: self.filterData.to
				}).then(function(res) {
					res ? self.loadResofUserConsumeTableData = true : false;
					if (res && res.data) {
						self.user_resConsumeChart = new PiePanelDefault();
						self.user_resConsumeChart.panels.pieType = "category";
						self.user_resConsumeChart.panels.data = [];
						self.user_resConsumeTotal = 0;
						self.csvDownloadData = [];
						// billingSrv.billOptions.resList = [];
						res.data = _.map(res.data, item => {
							// billingSrv.billOptions.resList.push({
							// 	name: $translate.instant("aws.bill." + (item.resourceName).toLowerCase()),
							// 	resName: item.resourceName
							// });
							self.user_resConsumeChart.panels.data.push({
								name: item.resourceName,
								value: item.consumptionAmount,
								percent: item.proportion
							});
							item.consumptionAmount = item.consumptionAmount ? item.consumptionAmount.toFixed(2) : 0;
							self.user_resConsumeTotal += Number(item.consumptionAmount);
							item._resourceName = $translate.instant("aws.bill." + (item.resourceName).toLowerCase());
							item.searchTerm = [item._resourceName, $rootScope.miliFormat(item.consumptionAmount)].join("\b");
							self.csvDownloadData.push([item._resourceName, $rootScope.miliFormat(item.consumptionAmount)]);
							return item;
						});
						self.user_resConsumeChart.panels.data = _.map(self.user_resConsumeChart.panels.data, item => {
							item.name = $translate.instant("aws.bill." + (item.name).toLowerCase());
							item.percent = (Number(item.percent) * 100).toFixed(2) + "%";
							item.value = item.value.toFixed(2);
							return item;
						});
						self.user_resConsumeTotal = self.user_resConsumeTotal.toFixed(2);

						initConsumeStatisticTable(res.data);
					}
				});
			}

			//搜索
			self.applyGlobalSearch = function() {
				self.resOfUserConsumeStatisticTable.filter({
					searchTerm: self.filterData.searchTerm
				});
			};

			self.query = function() {
				self.filterData.searchTerm = "";
				// billingSrv.getResConsumeData({
				// 	userId: self.userId,
				// 	startTime: self.filterData.from,
				// 	endTime: self.filterData.to
				// }).then(function(res) {
				// 	res ? self.loadResofUserConsumeTableData = true : false;
				// 	if (res && res.data) {
				// 		self.csvDownloadData = [];
				// 		res.data = _.map(res.data, item => {
				// 			item.consumptionAmount = item.consumptionAmount ? item.consumptionAmount.toFixed(2) : 0;
				// 			item._resourceName = $translate.instant("aws.bill." + (item.resourceName).toLowerCase());
				// 			item.searchTerm = [item._resourceName, $rootScope.miliFormat(item.consumptionAmount)].join("\b");
				// 			self.csvDownloadData.push([item._resourceName, $rootScope.miliFormat(item.consumptionAmount)]);
				// 			return item;
				// 		});
				// 		initConsumeStatisticTable(res.data);
				// 	}
				// });
				getUserResConsumeData();
			};
			if(!self.projectId){
				self.projectId = null;
				self.filterData.project = {
					name:$translate.instant("aws.bill.allProject"), // "所有项目",
					id: "allProject"
				}
				self.filterData.user = {
					consumeUserId:$location.search().consumeUserId,
					name : $location.search().name
				}
			}
			self.query();

			self.refreshConsumeStatisticTable = function() {
				self.filterData = {
					timeStep: "3d",
					queryType: "3d",
					from: moment().subtract(3, "d").format("YYYY-MM-DD"),
					to: moment().format("YYYY-MM-DD"),
					searchTerm: "",
					definedTime: false,
					definedTimeText: $translate.instant('aws.bill.chooseDate')
				};
				self.query();
			};

			self.downloadConsumeData = function() {
				return self.csvDownloadData;
			};
		});
	}
]);

billingCtrlModule.controller('userResConsumeDetailCtrl', ['$scope', '$routeParams', '$translate', '$rootScope', '$location', 'billingSrv', 'billingHeaderSrv', 'NgTableParams', 'GLOBAL_CONFIG',
	function($scope, $routeParams, $translate, $rootScope, $location, billingSrv, billingHeaderSrv, NgTableParams, GLOBAL_CONFIG) {
		var self = $scope;
		$rootScope.$on("userResConsumeDetail", function(e, data) {
			self.regionName = data.regionName;
			self.deptId = data.deptId;
			self.projectId = data.projectId;
			self.userName = data.name;
			self.userId = data.id || data.consumeUserId;
			self.resName = data.resName;
			self.filterData = {
				timeStep: "3d",
				queryType: "3d",
				from: moment().subtract(3, "d").format("YYYY-MM-DD"),
				to: moment().format("YYYY-MM-DD"),
				searchTerm: "",
				definedTime: false,
				definedTimeText: $translate.instant('aws.bill.chooseDate')
			};
			self.fileName = $translate.instant("aws.bill.userResBillDetailExport") + ".csv";
			self.csvHeader = [$translate.instant("aws.bill.productName"), $translate.instant("aws.bill.region"), $translate.instant("aws.bill.domain"), $translate.instant("aws.bill.project"), $translate.instant("aws.price.price_unit_hour"), $translate.instant("aws.bill.moneyAmount"), $translate.instant("aws.bill.billStartTime")];

			// self.filterData.region = billingSrv.region;
			// self.filterData.domain = billingSrv.domain;
			// self.filterData.project = billingSrv.project;
			// self.filterData.user = billingSrv.user;
			// _.each($rootScope.billOptions.resList, item => {
			// 	if (item.resName == data.resName) {
			// 		self.filterData.userRes = item;
			// 	}
			// });
			// billingSrv.userRes = self.filterData.userRes;
			if(!data.from) {
				billingHeaderSrv.getRegionList(self);
				billingHeaderSrv.getDomainList(self);
				billingHeaderSrv.getProjectList(self);
				billingHeaderSrv.getUserList(self);
				billingHeaderSrv.getResList(self);
			}

			self.changeBillFilter = function(data, type) {
				if(type == "region" || data.id == "backRegion") {
					$location.path($location.path()).search({
		                "id": self.filterData.region.id,
		                "type":"region"
		            });
				}else if(type == "domain" || data.id == "backDomain") {
					$location.path($location.path()).search({
		                "id": self.filterData.domain.id,
		                "name": self.filterData.domain.name,
		                "regionName": self.filterData.region.id,
		                "type":"dep"
		            });
				}else if(type == "project" || data.id == "backProject") {
					$location.path($location.path()).search({
		                "id": self.filterData.project.id,
		                "name": self.filterData.project.name,
		                "regionName": self.filterData.region.id,
		                "deptId": self.filterData.domain.id,
		                "type":"pro"
		            });
				}else if(type == "user" || data.id == "allRes") {
					$location.path($location.path()).search({
		                "consumeUserId": self.filterData.user.consumeUserId,
		                "name": self.filterData.project.name,
		                "regionName": self.filterData.region.id,
		                "deptId": self.filterData.domain.id,
		                "projectId": self.filterData.project.id,
		                "type":"user"
		            });
				}else if(type == "userRes") {
					self.resName = data.resName;
					self.query();
				}
			};

			self.$watch(function() {
				return $routeParams;
			}, function(value) {
				if (value && (value.id || value.consumeUserId)) {
					self.currPage = value.type;
				}
			});

			// self.changeBillFilter = function(){
			// 	self.resName = self.filterData.userRes.resName;
			// 	self.query();
			// };

			//搜索
			self.applyGlobalSearch = function() {
				self.resBillDetailTable.filter({
					searchTerm: self.filterData.searchTerm
				});
			};

			self.query = function() {
				self.filterData.searchTerm = "";
				var localSearchData = angular.copy($location.search())
				billingSrv.getResConsumeDataOfUser({
					userId: self.userId,
					deptId:localSearchData.deptId,
					projectId:localSearchData.projectId,
					queryType: self.filterData.queryType,
					startTime: self.filterData.from,
					endTime: self.filterData.to,
					resTypeName: self.resName
				}).then(function(res) {
					res ? self.loadResBillDetailTableData = true : false;
					if (res && res.data) {
						self.csvDownloadData = [];
						res.data = _.map(res.data, function(item) {
							item.unitPrice = item.unitPrice ? item.unitPrice.toFixed(2) : 0;
							item.amount = item.amount ? item.amount.toFixed(2) : 0;
							item._resourceName = $translate.instant("aws.bill." + (item.resourceName).toLowerCase());
							item.chargeTime = moment(new Date(item.chargeTime)).format("YYYY-MM-DD HH:mm:ss"); //1512994762000 时间戳格式化
							item.searchTerm = [item._resourceName, item.region, item.deptName, item.projectName, $rootScope.miliFormat(item.unitPrice), $rootScope.miliFormat(item.amount), item.chargeTime].join("\b");
							self.csvDownloadData.push([item._resourceName, item.region, item.deptName, item.projectName, $rootScope.miliFormat(item.unitPrice), $rootScope.miliFormat(item.amount), item.chargeTime]);
							return item;
						});
						self.resBillDetailTable = new NgTableParams({
							count: GLOBAL_CONFIG.PAGESIZE
						}, {
							counts: [],
							dataset: res.data
						});
					}
				});
			};
			self.query();

			self.refreshConsumeStatisticTable = function() {
				self.filterData.timeStep = "3d";
				self.filterData.queryType = "3d";
				self.filterData.from = moment().subtract(3, "d").format("YYYY-MM-DD");
				self.filterData.to = moment().format("YYYY-MM-DD");
				self.filterData.searchTerm = "";
				self.filterData.definedTime = false;
				self.filterData.definedTimeText = $translate.instant('aws.bill.chooseDate');
				self.query();
			};

			self.downloadConsumeData = function() {
				return self.csvDownloadData;
			};

		});
	}
]);

billingCtrlModule.controller('priceManageCtrl', ['$scope','$routeParams', '$rootScope', '$translate', 'billingSrv', 'NgTableParams', 'GLOBAL_CONFIG', 'checkedSrv', '$uibModal',
	function(self,$routeParams, $rootScope, $translate, billingSrv, NgTableParams, GLOBAL_CONFIG, checkedSrv, $uibModal) {
		if ($rootScope.ADMIN) {
			self.colsList = [{
				value: "order",
				title: $translate.instant("aws.price.serialNumber"),
				show: true,
				disable: true
			}, {
				value: "region",
				title: $translate.instant("aws.price.region"),
				show: true,
				disable: true
			}, {
				value: "productType",
				title: $translate.instant("aws.price.prodType"),
				show: true
			}, {
				value: "unit",
				title: $translate.instant("aws.price.unit"),
				show: true
			}, {
				value: "chargeType",
				title: $translate.instant("aws.price.billingType"),
				show: true
			}, {
				value: "unitPrice",
				title: $translate.instant("aws.price.price_unit"),
				show: true
			}];

			self.options = {
				regionList: [{
					name: $translate.instant("aws.price.allRegion"),
					value: null
				}],
				productTypeList: [{
					name: $translate.instant("aws.price.allType"),
					value: null
				}, {
					name: $translate.instant("aws.bill.cpu"),
					value: "cpu"
				}, {
					name: $translate.instant("aws.bill.memory"),
					value: "memory"
				}, {
					name: $translate.instant("aws.bill.volume"),
					value: "volume"
				}, {
					name: $translate.instant("aws.bill.snapshot"),
					value: "snapshot"
				}, {
					name: $translate.instant("aws.bill.router"),
					value: "router"
				}, {
					name: $translate.instant("aws.bill.floatingip"),
					value: "floatingip"
				}, {
					name: $translate.instant("aws.bill.loadbalancer"),
					value: "loadbalancer"
				},{
					name: $translate.instant("aws.bill.lb_cpu"),
					value: "lb_cpu"
				},{
					name: $translate.instant('aws.bill.lb_memory'),
					value: "lb_memory"
				}, {
					name: $translate.instant("aws.bill.bandwidth"),
					value: "bandwidth"
				}, {
					name: $translate.instant("aws.bill.pmcpu"),
					value: "pmCpu"
				}, {
					name: $translate.instant("aws.bill.pmmemory"),
					value: "pmMemory"
				}, {
					name: $translate.instant("aws.bill.pmhd"),
					value: "pmVolume"
				}, {
					name: $translate.instant("aws.bill.backupvolume"),
					value: "backupvolume"
				}],
				chargingTypeList: [{
					name: $translate.instant("aws.price.allPriceType"),
					value: null
				}, {
					name: $translate.instant("aws.price.hour"),
					value: "perHour"
				}
				// , {
				// 	name: $translate.instant("aws.price.month"),
				// 	value: "perMonth"
				// }
				],
				unitsList: []
			};

			self.globalSearchTerm = {
				"chargeItemTerm": ""
			};
			self.$watch(function() {
				return $routeParams;
			}, function(value) {
				if (value && (value.id || value.consumeUserId)) {
					self.currPage = value.type;
				}
			});

			function getRegionData() {
				billingSrv.getRegion().then(function(res) {
					if (res && res.data) {
						_.each(res.data, function(item) {
							self.options.regionList.push({
								name: item.regionName,
								value: item.regionName
							});
						})
					}
				});
			}
			getRegionData();

			self.queryParamInit = function() {
				self.queryLimit = {
					// chargingType: self.options.chargingTypeList[0].value,
					// productType: self.options.productTypeList[0].value,
					// region: self.options.regionList[0].value
				};
			}
			self.queryParamInit();

			// 初始化筛选提示的条件
			self.exitFilterInit = function() {
				self.showExitFilterTips = true;
				self.clickFilterConfirm = false;
				self.showFilteringTips = false;
				self.showFilterCompleteTips = false;
				self.searchTotal = 0;
			}
			self.exitFilterInit();

			self.query = function() {
				self.showFilteringTips = true;
				self.showFilterCompleteTips = false;
				self.searchTotal = 0;
				self.globalSearchTerm = {
					"chargeItemTerm": ""
				};
				billingSrv.getPriceManageData({
					"region": self.queryLimit.region,
					"resName": self.queryLimit.productType,
					"priceType": self.queryLimit.chargingType
				}).then(function(res) {
					res ? self.loadPriceManageTableData = true : false;
					if (res && res.data) {
						res.data = res.data.map(item => {
							item.unitPrice = item.unitPrice ? item.unitPrice.toFixed(2) : 0;
							item._resourceName = $translate.instant("aws.bill." + (item.resourceName).toLowerCase());
							item._priceType = $translate.instant("aws.bill." + (item.priceType).toLowerCase());
							item._region = item.region == "default" ? item.region : $translate.instant("aws.bill.region." + (item.region).toLowerCase());
							item.searchTerm = [item.id, item.region, item._resourceName, item.unit, item._priceType, $rootScope.miliFormat(item.unitPrice)].join('\b');
							return item;
						});
						self.priceManageTable = new NgTableParams({
							count: GLOBAL_CONFIG.PAGESIZE
						}, {
							counts: [],
							dataset: res.data
						});
						checkedSrv.checkDo(self, "", "id", "priceManageTable");
						self.showFilteringTips = false;
						self.showFilterCompleteTips = true;
						self.searchTotal = res.data.length;
					}
				});
			}
			self.query();

			// 查询负载均衡开关开启的状态
			self.querySwitchStatus = function() {
				let params = {
					parentId: 1128,
					enterpriseUid: localStorage.enterpriseUid,
					regionUid: localStorage.regionUid
				}
				billingSrv.querySwitchStatus(params).then(function(res){
					if(res && res.data) {
						let paramValue = JSON.parse(res.data[0].paramValue);
						self.switchStatus = JSON.parse(paramValue.switch);// true负载均衡开关开启 false 关闭 (paramValue.switch可能为String或者Boolean,用JSON.parse("true")转下)
						// 负载均衡关闭时过滤掉增强型的
						if(!self.switchStatus) {
							self.options.productTypeList = self.options.productTypeList.filter(item=>{
								return item.value !='lb_cpu' && item.value !='lb_memory'
							})
						}
					} 
				})
			}

			self.querySwitchStatus();

			self.refreshChargingItem = function() {
				self.globalSearchTerm = {
					"chargeItemTerm": ""
				};
				self.queryParamInit();
				self.query();
			};


			self.applyGlobalSearch = function(table, term) {
				self[table].filter({
					searchTerm: self.globalSearchTerm[term]
				});
			};

			self.priceManageModal = function(type, editData) {
				let scope = self.$new();
				let priceManageInstance = $uibModal.open({
					animation: true,
					templateUrl: type == "new" ? "createPriceManageModal.html" : "editPriceManageModal.html",
					scope: scope
				});

				switch (type) {
					case 'new':
						scope.modal_title = $translate.instant("aws.action.create");
						scope.addPriceManage = true;
						scope.priceManageForm = {};
						scope.regionList = angular.copy(scope.options.regionList).splice(1, scope.options.regionList.length - 1);
						scope.productTypeList = angular.copy(scope.options.productTypeList).splice(1, scope.options.productTypeList.length - 1);
						scope.changeProductType = function(productType) {
							scope.priceManageForm.unit = "";
							let coreList = ["cpu", "pmCpu", "lb_cpu"];
							let numlist = ["snapshot", "router", "floatingip", "loadbalancer"];
							let gbList = ["memory", "volume", "pmMemory", "pmVolume", "backupvolume", "lb_memory"];
							let mbpsList = ["bandwidth"];
							if (_.include(coreList, productType)) {
								scope.options.unitsList = [{
									name: $translate.instant("aws.bill.core"),
									value: $translate.instant("aws.bill.core"),
								}];
								scope.priceManageForm.unit=scope.options.unitsList[0].value;
							}
							if (_.include(numlist, productType)) {
								scope.options.unitsList = [{
									name: $translate.instant("aws.bill.one"),
									value: $translate.instant("aws.bill.one"),
								}];
								scope.priceManageForm.unit=scope.options.unitsList[0].value;
							}
							if (_.include(gbList, productType)) {
								scope.options.unitsList = [{
									name: "GB",
									value: "GB"
								}];
								scope.priceManageForm.unit=scope.options.unitsList[0].value;
							}
							if (_.include(mbpsList, productType)) {
								scope.options.unitsList = [{
									name: "Mbps",
									value: "Mbps"
								}];
								scope.priceManageForm.unit=scope.options.unitsList[0].value;
							}
						};
						scope.chargingTypeList = angular.copy(scope.options.chargingTypeList).splice(1, scope.options.chargingTypeList.length - 1);
						break;
					case 'edit':
						scope.modal_title = $translate.instant("aws.action.edit");
						scope.addPriceManage = false;
						scope.priceManageForm = angular.copy(editData);
						break;
				}
				priceManageInstance.rendered.then(function() {
					scope.submitted = false;
					scope.interacted = function(field) {
						return scope.submitted || field.$dirty;
					};
					scope.priceManageCfm = function(form, data) {
						if (form.$valid) {
							if (type == "new") {
								let params = {
									"region": data.region,
									"resName": data.productType,
									"unit": data.unit,
									"unitPrice": data.unitPrice,
									"priceType": data.chargingType
								};
								billingSrv.addChargingItem(params).then(function(res) {
									self.query();
								});

							} else {
								billingSrv.editChargingItem({
									"id": editData.id,
									"unitPrice": data.unitPrice
								}).then(function(res) {
									self.query();
								});
							}
							priceManageInstance.close();
						} else {
							scope.submitted = true;
						}
					};
				});
			};

			self.deleteChargingItem = function(checkedItems) {
				var content = {
					target: "delChargingItem",
					msg: "<span>" + $translate.instant("aws.bill.deleteTip") + "</span>",
					data: checkedItems
				};
				self.$emit("delete", content);
			};
			self.$on("delChargingItem", function(e, data) {
				var del_obj_ids = [];
				_.forEach(data, function(item) {
					del_obj_ids.push(item.id);
				});
				billingSrv.deleteChargingItem(
					del_obj_ids
				).then(function() {
					self.query();
				});
			});
		}
	}
]);
billingCtrlModule.controller('consumeQueryCtrl', ['$scope', '$routeParams', '$rootScope', '$translate', 'billingSrv', 'TableCom', '$timeout',
	function(self, $routeParams, $rootScope, $translate, billingSrv, TableCom, $timeout) {
		self.colList = [{
			value: "resourceName",
			title: $translate.instant("aws.consume.resourceName"),
			type: '',
			show: true
		}, {
			value: "resourceTypeName",
			title: $translate.instant("aws.consume.resourceType"),
			type: '1',
			show: true,
			disable: true
		}, {
			value: "region",
			title: $translate.instant("aws.consume.region"),
			type: '1',
			show: true
		}, {
			value: "deptName",
			title: $translate.instant("aws.consume.domain"),
			type: '1',
			show: true
		}, {
			value: "projectName",
			title: $translate.instant("aws.consume.project"),
			type: '1',
			show: true
		}, {
			value: "unitPrice",
			title: $translate.instant("aws.consume.price"),
			type: '',
			show: true
		}, {
			value: "amount",
			title: $translate.instant("aws.consume.amount"),
			type: '1',
			show: true
		}, {
			value: "chargeTime",
			title: $translate.instant("aws.consume.startTime"),
			type: '1',
			show: true
		}];
		self.tableCols = self.colList;

		self.billTypeOptions = [{
			name: $translate.instant("aws.consume.detailQuery"),
			value: '0'
		}, {
			name: $translate.instant("aws.consume.billQuery"),
			value: "1"
		}];

		self.timeTypeOptions = [{
			"name": $translate.instant('aws.bill.timeType.threeDays'),
			"value": "3d"
		}, {
			"name": $translate.instant('aws.bill.timeType.oneWeek'),
			"value": "7d"
		}, {
			"name": $translate.instant('aws.bill.timeType.oneMonth'),
			"value": "30d"
		}];

		self.billOptions = {
			resourceList: [{
				name: $translate.instant('aws.consume.all'),
				value: null
			}],
			regionList: [{
				name: $translate.instant('aws.consume.all'),
				value: null
			}],
			domainList: [{
				name: $translate.instant('aws.consume.all'),
				value: null
			}],
			projectList: [{
				name: $translate.instant('aws.consume.all'),
				value: null
			}],
			userList: [{
				name: $translate.instant('aws.consume.all'),
				value: null
			}]
		}

		self.filterData = {
			billType: '0',
			timeStep: '3d',
			queryType: '3d',
			from: null,
			to: null,
			selectedMonth: moment().subtract(1, 'months').format('YYYY-MM'),
			resourceType: null,
			region: null,
			domain: null,
			project: null,
			user: null,
		}
		self.definedTimeText = $translate.instant('aws.bill.chooseDate');
		self.pageSize = 10;
		self.globalSearchTerm = '';
		self.fileName = $translate.instant("aws.bill.regionResConsumeStatistic") + ".csv";
		self.csvHeader = [$translate.instant("aws.bill.name"), $translate.instant("aws.bill.cost")];

		$('#queryDateRangePicker').daterangepicker({
			"language": 'zh-CN',
			"singleDatePicker": false,
			"startDate": moment().subtract(1, 'months'),//单日历下拉 开始日期设为当前日期
			"maxDate" : moment(),
			"timePicker": false,
			"timePicker24Hour": false,
			"linkedCalendars": false,
			"autoUpdateInput": false,
			"opens": "left",
			"locale": {
				format: 'YYYY-MM-DD',
				separator: ' 至 ',
				applyLabel: "确定",
				cancelLabel: "取消",
				daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
				monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
			}
		});

		$('#monthPicker').datetimepicker({
			"format":'yyyy-mm',
            "startView":'year',
            "maxView":'year',
            "minView":'year',
            "autoclose":true,
            "pickerPosition": "bottom-left",
			"language":'zh-CN',
			"locale": {
				monthNames: ["1月", "2月", "3月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
			}
		});

		$('#queryDateRangePicker').on('apply.daterangepicker',function(ev, picker) {
			let startTime = picker.startDate.format(picker.locale.format);
			let endTime = picker.endDate.format(picker.locale.format);
			let separator = picker.locale.separator;
			self.filterData.from = startTime;
			self.filterData.to = endTime;
			if(!picker.startDate || !picker.endDate) {
				$("#queryDateRangePicker .time-input").val('');
			}else {
				var definedTimeText = startTime + separator + endTime;
				$("#queryDateRangePicker .time-input").val(definedTimeText);
				self.definedTimeText = definedTimeText;
			}
			self.$apply();
		});
		// 选择账单类型
		self.billTypeSelect = function(val) {
			if(self.filterData.billType === val) return;
			for (const key in self.filterData) {
				if (self.filterData.hasOwnProperty(key)) {
					self.filterData[key] = null;
				}
			}
			self.filterData.billType = val;
			self.filterData.timeStep = '3d';
			self.filterData.queryType = '3d';
			self.filterData.selectedMonth = moment().subtract(1, 'months').format('YYYY-MM');
			self.globalSearchTerm = '';
			if(val === '1'){
				self.tableCols = self.colList.filter(item => item.type === '1');
			}else{
				self.tableCols = self.colList;
			}
			self.initTableData();
		}
		// 选择时间
		self.timeSelectTab = function(step) {
			self.filterData.timeStep = step;
			self.filterData.from = null;
			self.filterData.to = null;
			self.filterData.queryType = null;
			if(step != "defined"){
				self.definedTimeText = $translate.instant('aws.bill.chooseDate');
				self.filterData.queryType = step;
			}
		};
		// 获取资源类型
		self.getResTypeData = function() {
			let params = {
				"region": self.filterData.region || null,
			};
			self.billOptions.resourceList = [
				{
					name: $translate.instant('aws.consume.all'),
					value: null
				}
			]
			billingSrv.getResConsumeData(params).then(function(res) {
				if (res && res.data) {
					_.each(res.data, function(item) {
						self.billOptions.resourceList.push({
							name: $translate.instant('aws.bill.'+item.resourceName),
							value: item.resourceName
						})
					});
					let num = 0;
					for (let i = 0; i < self.billOptions.resourceList.length; i++) {
						if(self.billOptions.resourceList[i].value == self.filterData.resourceType) {
							num ++;
							return;
						}
					}
					// 切换数据中心后 资源类型中存在该类型就显示当前值 没有就显示全部
					self.filterData.resourceType = num > 0 ? self.filterData.resourceType : null;
				}
			});
		}
		// 获取数据中心
		self.getRegionData = function() {
			billingSrv.getAllRegions().then(function(res) {
				if (res && res.data) {
					_.each(res.data, function(item) {
						self.billOptions.regionList.push({
							name: item,
							value: item
						})
					});
				}
			});
		}
		// 获取部门
		self.getDomainData = function() {
			let params = {};
			if (self.filterData.region) {
				params.region = self.filterData.region;
			}
			billingSrv.getDomainsList(params).then(function(res) {
				if (res && res.data) {
					_.each(res.data, function(item) {
						if (item.deptName == 'default') {
							item.disDomainName = '默认部门';
						} else {
							item.disDomainName = item.deptName;
						}
						self.billOptions.domainList.push({
							name: item.disDomainName,
							value: item.deptId
						})
					});
				}
			});
		}
		// 获取项目
		self.getProjectData = function() {
			let params = {};
			if (self.filterData.region) {
				params.region = self.filterData.region;
			}
			if (self.filterData.domain) {
				params.deptId = self.filterData.domain;
			}
			billingSrv.getProjectsList(params).then(function(res) {
				if (res && res.data) {
					_.each(res.data, function(item) {
						if (item.projectName == 'admin') {
							item.disProjectName = '默认项目';
						} else {
							item.disProjectName = item.projectName;
						}
						self.billOptions.projectList.push({
							name: item.disProjectName,
							value: item.projectId
						});
					});
				}
			});
		}
		// 获取用户
		self.getUserData = function() {
			let params = {};
			if (self.filterData.region) {
				params.region = self.filterData.region;
			}
			if (self.filterData.domain) {
				params.deptId = self.filterData.domain;
			}
			if (self.filterData.project) {
				params.projectId = self.filterData.project;
			}
			billingSrv.getUsersList(params).then(function(res) {
				if (res && res.data) {
					_.each(res.data, function(item) {
						self.billOptions.userList.push({
							name: item.userName,
							value: item.userId
						});
					});
				}
			});
		}
		// 切换数据中心
		self.changeRegion = function() {
			self.filterData.domain = null;
			self.filterData.project = null;
			self.filterData.user = null;
			self.billOptions.domainList.splice(1);
			self.billOptions.projectList.splice(1);
			self.billOptions.userList.splice(1);
			self.getDomainData();
			self.getResTypeData();
		};
		// 切换部门
		self.changeDomain = function() {
			self.filterData.project = null;
			self.filterData.user = null;
			self.billOptions.projectList.splice(1);
			self.billOptions.userList.splice(1);
			self.getProjectData();
		};
		// 切换项目
		self.changeProject = function() {
			self.filterData.user = null;
			self.billOptions.userList.splice(1);
			self.getUserData();
		};
		// 查询列表
		self.initTableData = function(options,type) {
			let pageDefaultOption = {
				startTime: self.filterData.billType === '0' ? self.filterData.from : self.filterData.selectedMonth,
				endTime: self.filterData.billType === '0' ? self.filterData.to : self.filterData.selectedMonth,
				resTypeName: self.filterData.resourceType,
				region: self.filterData.region,
				deptId: self.filterData.domain,
				projectId: self.filterData.project,
				userId: self.filterData.user,
                pageSize: self.pageSize,
                pageNum: 1,
                pattern: self.globalSearchTerm
			};
			let service = '';
			if(self.filterData.billType === '0'){
				pageDefaultOption.queryType = self.filterData.queryType;
				service = 'getDetailConsumeList';
				self.fileName = $translate.instant("aws.consume.detailQuery") + ".csv";
			}else {
				service = 'getConsumeDataList';
				self.fileName = $translate.instant("aws.consume.billQuery") + ".csv";
			}
			self.csvHeader = self.tableCols.map(item => item.title);
            if(!type){
                self.currentPage = 1;
            }
            let pageOption = angular.extend(pageDefaultOption,options);
			return billingSrv[service](pageOption).then(function(res) {
				res ? self.loadConsume = true : false;
				self.csvDownloadData = [];
				if (res && res.data) {
					let tableData = res.data;
					tableData = tableData.map(item => {
						item.resourceTypeName = $translate.instant('aws.bill.'+item.resourceTypeName);
						item.deptName = item.deptName == 'default' ? '默认部门' : item.deptName;
						item.projectName = item.projectName == 'admin' ? '默认项目' : item.projectName;
						item.chargeTime = moment(item.chargeTime).format("YYYY-MM-DD HH:mm:ss");
						if(type == 'download'){
							if(self.filterData.billType === '0'){
								self.csvDownloadData.push([item.resourceName,item.resourceTypeName,item.region,item.deptName,item.projectName,item.unitPrice,item.amount," "+item.chargeTime]);
							}else{
								self.csvDownloadData.push([item.resourceTypeName,item.region,item.deptName,item.projectName,item.amount," "+item.chargeTime]);
							}
						}
						return item;
					});
					if(type == 'download') return self.csvDownloadData;
					TableCom.init(self,'consumeTable',tableData,'id',999);
					self.totalNum = res.total;
				}else{
					return self.csvDownloadData;
				}
			});
		}

		self.getResTypeData();
		self.getRegionData();
		self.initTableData();

		//切换页码
        self.changePage = function(options){
            self.initTableData(options,"changePage");
        };
		// 刷新
		self.refresh = function() {
			self.globalSearchTerm = '';
			self.initTableData();
		};
		// 搜索
		self.applyGlobalSearch = function() {
			$timeout.cancel(self.sendSearch);
			self.sendSearch = $timeout(()=>{
				self.initTableData();
			},1000)
		};

		self.downloadData = function() {
			return self.initTableData({pageSize:null,pageNum:null},'download').then(() => {
				return self.csvDownloadData;
			})
		}
	}
]);

billingCtrlModule.directive('filterModal',filterModal)
billingCtrlModule.directive('filterSelectGroup',filterSelectGroup)
billingCtrlModule.directive('filterTips',filterTips)
billingCtrlModule.directive('filterLoading',loading)