import "./mysqlSrv";
import {
    AreaPanelDefault
} from "../../chartpanel";
import {
    mysqlAreaChartDefault
} from "../../chartpanel";

var mysqlModule = angular.module("mysqlModule", ["ngTable", "ngAnimate", "ui.bootstrap", "ngSanitize", "mysqlSrv", "usersrv"]);
mysqlModule.controller("mysqlCtrl", ["$scope", "$rootScope", "NgTableParams", "$translate", "mysqlSrv", "userDataSrv","commonFuncSrv",
    function($scope, $rootScope, NgTableParams, $translate, mysqlSrv, userDataSrv,commonFuncSrv) {
        var self = $scope;
        $rootScope.loading = true;
        self.queryLimit = {};
        self.filterData = {
            timeStep: '30m',
			queryType: '30m',
            from: null,
            to: null
        };

        function panelsDataFunc(item, key, color) {
            self.panelsData[key] = (self.panelsData[key]).slice((self.panelsData[key]).length, 0);
            var areaChart = new AreaPanelDefault();
            areaChart.panels.title = $translate.instant("aws.monitor." + item.chartPerm.title);
            areaChart.panels.unit = item.chartPerm.unit;
            areaChart.panels.priority = item.chartPerm.priority;
            if (color % 2 == 0) {
                areaChart.panels.colors = ["#51a3ff"];
            } else {
                areaChart.panels.colors = ["#1bbc9d"];
            }
            let params = {
				"resource": 'mysql',
				"chart": item.chartPerm.title,
				"region": self.queryLimit.regionKey,
				"hostId": self.queryLimit.selectedMysql.host,
				"queryType": self.filterData.queryType,
				"startTimeMillis": self.filterData.from ? moment(self.filterData.from).valueOf() : null,
				"endTimeMillis": self.filterData.to ? moment(self.filterData.to).valueOf() : null
            };
            mysqlSrv.getStatistics(params).then(function(res) {
                if (res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                    areaChart.panels.data.push(res.data.results[0].series[0]);
                } else {
                    var defaultChartData = {
                        "columns": ["time", item.chartPerm.title],
                        "values": [
                            [moment().subtract(30, "m"), 0],
                            [moment(), 0]
                        ],
                        "default": true
                    };
                    areaChart.panels.data.push(defaultChartData);
                }
                self.panelsData[key].push(areaChart.panels);
                self.panels = self.panelsData;
                $rootScope.loading = true;
            })
        }

        function mysqlChart(regionKey, timeRange, target) {
            self.panelsData = {};
            var areaChartPermas = new mysqlAreaChartDefault(regionKey, timeRange, target);
            $rootScope.lazyOnType = {};
            var count = 0;
            for (let key in areaChartPermas.chartSqls) {
                self.panelsData[key] = [];
                if (key == "visit") {
                    $rootScope.lazyOnType[key] = true;
                } else {
                    count++;
                    _.each(areaChartPermas.chartSqls[key], function(item) {
                        panelsDataFunc(item, key, count);
                    })
                }
            }

            /*var mysqlVisitListener = self.$on("visit", function(e) {
                $rootScope.loading = false;
                _.each(areaChartPermas.chartSqls.visit, function(item) {
                    panelsDataFunc(item, "visit", "3");
                })
                mysqlVisitListener();
            });*/
            _.each(areaChartPermas.chartSqls.visit, function(item) {
                panelsDataFunc(item, "visit", "3");
            })
        }

        self.query = function() {
            mysqlChart(self.queryLimit.regionKey, self.queryLimit.timeRange, self.queryLimit.selectedMysql.host);
        };

        function handleNodeName(data,nodePrettyNameList){
            data.forEach(function(item){
                item.prettyName=nodePrettyNameList[item.host];
            });
            self.queryLimit.selectedMysql = data[0];
            mysqlChart(self.queryLimit.regionKey, self.queryLimit.timeRange, self.queryLimit.selectedMysql.host);
            return data;
        }

        userDataSrv.getRegionData().then(function(res) {
            if (res && res.data && res.data[0] && res.data[0].regionKey) { 
                res.data = res.data.filter(item => {
                    return item.status == 3;
                });
                self.queryLimit.regionKey = res.data[0].regionKey;
                _.each(res.data, item => {
                    if (item.regionKey == localStorage.regionKey) {
                        self.queryLimit.regionKey = item.regionKey;
                    }
                });
                mysqlSrv.getMysqlBasicData(self.queryLimit.regionKey).then(function(res) {
                    if (res && res.data.length > 0) {
                        self.mysqls = {
                            options: res.data
                        };
                        commonFuncSrv.showPrettyNameOfNode(self.mysqls.options,handleNodeName);
                    }
                });
            }
        });
    }
]);