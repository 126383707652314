editPswCtrl.$inject = ["$scope", "$translate","$uibModalInstance","$location","context","data","NgTableParams", 'instancesSrv'];
export function editPswCtrl($scope, $translate, $uibModalInstance, $location, context, data, NgTableParams, instancesSrv){
    let self = $scope;
    var hostId = data.uid;
    var name = data.name;
    self.edited_Psw = "";
    self.editPswSubmit = false;
    self.doubleClick = false;
    self.editPswCfm = function(editPswForm,psw){
        if(context.canEditPsw == false){
            if(editPswForm.$valid){
                var postData = {
                    name:name,
                    password:psw
                }
                self.doubleClick = true;
                instancesSrv.editPsw(hostId,postData).then(function(){
                    $uibModalInstance.close();
                }).finally(()=>{
                    self.doubleClick = false;
                })
            }else{
                self.editPswSubmit = true;
            }
        }
    }
}