
bindInstanceJobCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","$location","$routeParams","$uibModalInstance","initBackupJobTable","TableCom","$timeout","editData","alertSrv"]
function bindInstanceJobCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,$location,$routeParams,$uibModalInstance,initBackupJobTable,TableCom, $timeout,editData,alert) {
	var self = $scope;
    // 初始化table;
    self.dataPageSize=10;
    self.dataCurrentPage=1;
    self.swithCheckBox = false;
    self.commitBtn = true;
    self.headers={
        instanceName:$translate.instant("aws.backupService.backupJob.bindInstance.instanceName"),
        status:$translate.instant("aws.backupService.backupJob.bindInstance.status"),
		ips:$translate.instant("aws.backupService.backupJob.bindInstance.ips"),
    };
    // 未绑定云主机
    self.tableCols=[
        { field: "check", title: "",headerTemplateURL:"bingInstanceCheckbox.html",show: true ,width:"5%"},
        { field: "instanceName", title: self.headers.instanceName, show: true,disable:true ,width:"35%"},//实例名称
        { field: "status", title: self.headers.status, show: true,disable:false,width:"30%"},//状态
        { field: "ips", title: self.headers.ips, show: true,disable:false ,width:"30%"},// 私网ip
    ];
    
    // 以绑定云主机
    self.tableCols_B=[
        { field: "instanceName", title: self.headers.instanceName, show: true,disable:true },//实例名称
        { field: "status", title: self.headers.status, show: true,disable:false},//状态
        { field: "ips", title: self.headers.ips, show: true,disable:false },// 私网ip
        { field: "operate", title: "", show: true,disable:false },//操作
    ];
    initTables_b();
    // 未选择云主机 列表
	function initTables() {
        self.dataLoadData_a  = [];
        TableCom.init(self,"tableParams_a",[],"uid",GLOBAL_CONFIG.PAGESIZE,'bingInstanceCheckToCount');
        var params = {
            pageNum: self.dataCurrentPage,
            pageSize: self.dataPageSize
        }
        if (self.globalSearchTerm) {
            params.pattern = self.globalSearchTerm.item;
        }

        // headers
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            const {projectUid,domainUid} = editData;
            headers = {
                project_id: projectUid,
                domain_id: domainUid,
            }
        }
        backupJobSrv.getInstanceList(params, headers).then(function (result) {
            if (result && result.data) {
                self.dataTotalNum = result.total;
                self.dataLoadData_a = result.data;
                TableCom.init(self,"tableParams_a",self.dataLoadData_a,"uid",GLOBAL_CONFIG.PAGESIZE,'bingInstanceCheckToCount');
                // 延迟加载
                var timer = $timeout(function(){
                    echoCheckBox();
                    $timeout.cancel(timer);
                },500)
            }
        });
	}
    self.applyGlobalSearch = function() {
        initTables();
    };
    function echoCheckBox(){
        self.bingInstanceCheckToCount.items={};
        self.dataLoadData_a.forEach(item => {
            self.dataLoadData_b.forEach(it => {
                if(item.uid == it.uid) {
                    self.bingInstanceCheckToCount.items[item.uid] = true;
                }
            });
        });
    }
    // 翻页
    self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
        initTables();
    };
    //监听按钮
    self.$watch(function() {
        return self.bingInstanceCheckToCount;
    }, function(val) {
        if(val == undefined){
            return;
        }
        self.checkedItems = [];
		var arr=[];
        for(var i in self.bingInstanceCheckToCount.items){
            arr.push(self.bingInstanceCheckToCount.items[i])
        }
		if(val.items && arr.length>=0){
            for(var key in val.items){
                if(val.items[key]){
                    self.dataLoadData_a.forEach(item=>{
                        if(item.uid==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
		if(self.checkedItems.length==1){  

		}
        if(self.swithCheckBox){
            optionFlowControl('add', self.checkedItems);
        }
        self.swithCheckBox = true;
    },true);
    // 已选择云主机 列表
    function initTables_b() {
        self.dataLoadData_b  = [];
        TableCom.init(self, "tableParams_b", [], "uid", 10000, '');
        if(editData.jobId != null && editData.jobId != "") {
            // headers
            var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                const {projectUid,domainUid} = editData;
                headers = {
                    project_id: projectUid,
                    domain_id: domainUid,
                }
            }
            backupJobSrv.getBackupJobInfo(editData.id, headers).then(function (result) {
                if (result && result.data) {
                    self.dataLoadData_b = result.data.jobDataSource;
                    initInstanceStatus();
                }
            });
        }else{
            initTables();
        }
	}
    function initInstanceStatus(){
        // 根据虚拟机id查询虚拟机状态
        let ids = [];
        self.dataLoadData_b.forEach(x => {
            ids.push(x.uuid);
        });
        if(ids.length == 0) {
            return;
        }
        backupJobSrv.getInstanceStatusList({ids:ids}).then(function (result) {
            if (result && result.data) {
                self.dataLoadData_b = result.data;
                TableCom.init(self, "tableParams_b", self.dataLoadData_b, "uid", 10000, '');
                // 延迟加载
                var timer = $timeout(function(){
                    echoCheckBox();
                    $timeout.cancel(timer);
                },500)
            }
            initTables();
        });
    }
    // 操作列 删除添加
    self.optionFlowControl = optionFlowControl;
    function optionFlowControl(type, data){
        if(type == 'add'){
            var unCheckList = [];
            self.dataLoadData_a.forEach(x => {
                var unc = false;
                data.forEach(y => {
                    if(x.uid == y.uid){
                        unc = true
                    }
                });
                if(!unc) {
                    unCheckList.push(x);
                }
            });
            //add 选中状态，添加右侧列表。。如果右侧略表没有的情况下
            data.forEach(x => {
                var being = false;
                self.dataLoadData_b.forEach(y => {
                    if(x.uid == y.uid) {
                        being = true;
                    }
                });
                if(!being){
                    self.dataLoadData_b.push(x);
                }
            });
            //delete 未选择状态，判断右侧是否有数据，如果有数据就delete掉
            unCheckList.forEach(x => {
                var being = false;
                self.dataLoadData_b.forEach(y => {
                    if(x.uid == y.uid) {
                        being = true;
                    }
                });
                if(being){
                    self.dataLoadData_b.splice(self.dataLoadData_b.findIndex(item => item.uid === x.uid), 1);
                    
                }
            });
            TableCom.init(self, 'tableParams_b', self.dataLoadData_b, "id", 10000, '');
        }else if (type == 'delete'){
            self.dataLoadData_b.splice(self.dataLoadData_b.findIndex(item => item.uid === data.uid), 1);
            TableCom.init(self, 'tableParams_b', self.dataLoadData_b, "id", 10000, '');
            echoCheckBox();
        }
    }
    //确定按钮
    self.confirmCreate = function(){
        // 封装参数
        var jobDataSource = [];
        if(self.dataLoadData_b.length==0){
            // 直接调用删除任务接口
            let ids = [];
            ids.push(editData.id);
            var params = {
                ids: ids
            }
            // headers
            var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                const {projectUid,domainUid} = editData;
                headers = {
                    project_id: projectUid,
                    domain_id: domainUid,
                }
            }
            self.commitBtn = false;
            backupJobSrv.deleteBackupJob(params, headers).then(function () {
                self.commitBtn = true;
                //关闭模态框
                $uibModalInstance.close();
                //刷新父页面列表
                initBackupJobTable("reload");
                
            });
            return;
        }
        self.dataLoadData_b.forEach(x => {
            var customer = x.uid + "@";
            if(x.privateIpAddress != null) {
                customer += x.privateIpAddress.replace(",",";");
            }
            var data = {
                uuid: x.uid,//云主机id
                dispPath: x.name,//云主机名称
                customer: customer
            }
            jobDataSource.push(data);
        });
        var params = {
            id: editData.id,
            jobDataSource: jobDataSource,
        }
        // header 
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            const {projectUid,domainUid} = editData;
            headers = {
                project_id: projectUid,
                domain_id: domainUid,
            }
        }
        self.commitBtn = false;
        backupJobSrv.bindInstance(params, headers).then(function () {
            self.commitBtn = true;
            //关闭模态框
            $uibModalInstance.close();
            //刷新父页面列表
            initBackupJobTable();
        });
       
    }
};
export {bindInstanceJobCtrl}