import "./nettypeSrv";
angular.module("nettypeModule", ["nettypeSrv"])
    .controller("nettypeCtrl", ["$scope", "$uibModal","$translate","nettypesrv",function ($scope, $uibModal,$translate,nettypesrv) {
        let self = $scope;
        //常量
        let __Data = {
            dataList:[],
            reg:{
                vlan:{
                    min:1,
                    max:4095
                },
                vxlan:{
                    min:1,
                    max:167772156
                }
            },
            submitInValid:false,
            instanDisabled:false
        }
        self = _.extend(self,__Data);
        self.initData = function(){
            nettypesrv.getListApi().then(res=>{
                if(res&&res.data){
                    console.log(res);
                    self.dataList = res.data;
                    self.dataList.forEach(item=>{
                        item.webUse = {
                            reg:self.reg[item.neutronType.toLowerCase()],
                            rangeError:false
                        }
                        item.isOpen = false;
                    })
                }
            })
        }

        self.addVlan = function(item,it){
            item.rangeNums.push({
                start:"",
                end:""
            })
        }

        self.removeVlan = function(item,$index){
            item.rangeNums.splice($index,1);
        }

        self.changeStatus = function(item,checked){
            if(item.status==0&&checked&&!item.rangeNums){
                item.rangeNums = [];
                item.rangeNums.push({
                    start:"",
                    end:""
                })
            }
        }
        self.saveVlan = function(field){
            if(field.$valid){
                self.submitInValid = false;
                let flag = true;
                self.dataList.forEach(item=>{
                    if(item.rangeNums){
                        item.rangeNums.forEach(it=>{
                            it.rangeError = false;
                            if(Number(it.start)>Number(it.end)){
                                it.rangeError = true;
                                flag = false;
                            }
                        })
                    }
                })
                if(flag){
                    self.instanDisabled = true;
                    let data = self.dataList.map(item=>{
                        return {
                            id:item.id,
                            status:(item.isOpen||item.status!=0)?1:item.status,
                            neutronType:item.neutronType,
                            rangeNums:(item.isOpen||item.status!=0)?item.rangeNums:null
                        };
                    })
                    nettypesrv.saveRangeApi(data).then((res)=>{
                        if(res&&res.data){
                            self.initData();
                        }
                    }).finally(()=>{
                        self.instanDisabled = false;
                    })
                }
            }else{
                self.submitInValid = true;
            }
        }
        self.initData();
        self.$on("config.tenant.type",function(){
            self.initData();
        })
    }])