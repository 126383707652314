export function enhanceDetailListenSrv($http) {
    return {
        // 获取监听器列表
        soundMonitorList(uid, params) {
            return $http({
                method: "get",
                url: `/awstack-manage/v2/elb/loadbalancers/${uid}/listeners`,
                params
            })
        },
        // 删除监听器
        soundMonitorDelete(params) {
            return $http({
                method:"DELETE",
                url:"/awstack-manage/v2/elb/loadbalancers/listeners",
                params
            })
        },
        // 获取成员列表
        getMemberList(id, params, headers){
            return $http({
                method:"get",
                url:`/awstack-manage/v2/elb/loadbalancers/${id}/members`,
                params,
                headers
            })
        },
        // 绑定云主机
        bindHost(id, data){
            return $http({
                method:"POST",
                url:`/awstack-manage/v2/elb/loadbalancers/${id}/members/bind`,
                data
            })
        },
        // 解绑云主机
        unbindHost(id, data){
            return $http({
                method:"post",
                url:`/awstack-manage/v2/elb/loadbalancers/${id}/members/unbind`,
                data
            })
        },
        // 启用禁用成员
        startforhost(id, type){
            return $http({
                method:"put",
                url:`/awstack-manage/v2/elb/loadbalancers/listeners/members/${id}/${type}`,
            })
        },
        // 恢复成员
        recover(data){
            return $http({
                method:"put",
                url:`/awstack-manage/v2/elb/loadbalancers/listeners/members/recovery`,
                data
            })
        },
        // 启用禁用监听器
        startformonitor(id,type){
            return $http({
                method:"put",
                url:`/awstack-manage/v2/elb/loadbalancers/listeners/${id}/${type}`,
            })
        },
        // 查询本地磁盘挂载的云主机
        getLocalDiskMountList: function(params, headers) {
            // headers = headers.projectUid || null;
            return $http({
                method: "GET",
                url: "/awstack-resource/v1/servers/list",
                params,
                headers
            })
        },
    }
}

enhanceDetailListenSrv.$inject = ["$http"]
