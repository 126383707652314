backupRecoverJobCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupRecoverJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","TableCom","utilTool","$location"]
function backupRecoverJobCtrl($scope, $uibModal,$translate, alertSrv, backupRecoverJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,TableCom,utilTool,$location) {
	var self = $scope;
	
	self.dataPageSize=10;
    self.dataCurrentPage=1;
	self.backupServiceConfig = true;
	self.desc = localStorage.managementRole == 2 ? $translate.instant("aws.backupService.tip.desc0") : $translate.instant("aws.backupService.tip.desc1");
	self.btn = {
		canStop: false,
		canDel: false
	}

	//设置项的初始化
	self.headers={
        jobName:$translate.instant("aws.backupService.backupJobRecover.jobName"),
        jobStatus:$translate.instant("aws.backupService.backupJobRecover.jobStatus"),
		jobRunTime:$translate.instant("aws.backupService.backupJobRecover.jobRunTime"),
		jobCompletedData:$translate.instant("aws.backupService.backupJobRecover.jobCompletedData"),
		jobSpeed:$translate.instant("aws.backupService.backupJobRecover.jobSpeed"),
		project:$translate.instant("aws.backupService.variable.table.projectName"),
		domain:$translate.instant("aws.backupService.variable.table.domainName"),
    };
	self.backupJobtitleName="backupService_backupRecoverJob";
	if(sessionStorage["backupService_backupRecoverJob"]){
		self.tableCols = JSON.parse(sessionStorage["backupService_backupRecoverJob"]);
	}else {
		self.tableCols=[
			{ field: "check", title: "",headerTemplateURL:"backupRecoverJobManage.html",show: true ,width:"6%"},
			{ field: "jobName", title: self.headers.jobName, show: true,disable:true },//任务名称
			{ field: "jobStatus", title: self.headers.jobStatus, show: true,disable:false },//任务状态
			{ field: "jobSpeed", title: self.headers.jobSpeed, show: true,disable:false },//传输速度
			{ field: "jobCompletedData", title: self.headers.jobCompletedData, show: true,disable:false },//传输数据量
			{ field: "jobRunTime", title: self.headers.jobRunTime, show: true,disable:false },//运行时间
		];
	}
	// 监听是否选中全部部门
    self.$watch(function(){
        return localStorage.selectedAllDomain
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self)
    })
	// 刷新
	self.refresh = refresh;
	function refresh(){
		self.globalSearchTerm = "";
		self.dataPageSize=10;
    	self.dataCurrentPage=1;
		initTabData();
	}
	initTabData();
	function initTabData() {
		if(!self.backupServiceConfig){
            return;
        }
        self.dataLoadData  = [];
		TableCom.init(self,'tableParams',[],"id",GLOBAL_CONFIG.PAGESIZE,'checkBackupRecoverJobToCount');
		let params = {
			pageNum: self.dataCurrentPage,
			pageSize: self.dataPageSize
		}
		if (self.globalSearchTerm) {
			params.search = self.globalSearchTerm.item;
		}
		backupRecoverJobSrv.getBackupRecoverList(params).then(function (result) {
			if (result && result.data) {
                self.dataTotalNum = result.total;
		        self.dataLoadData = result.data;
		        self.dataLoadData = operateData(self.dataLoadData);
                TableCom.init(self, "tableParams", self.dataLoadData, "id", GLOBAL_CONFIG.PAGESIZE, 'checkBackupRecoverJobToCount');
			}
		});
	}
	self.applyGlobalSearch = function() {
		initTabData();
	};
	function operateData(data) {
        data.forEach(x => {
			// 已完成数据量
			x.jobCompletedData = unitData(x.jobCompletedData)
			// 已运行时间
			x.jobRunTime = x.jobRunTime != null ? x.jobRunTime!=0 ? formatSeconds(x.jobRunTime) : "00:00:00" : "00:00:00";
        });
        return data;
    }
	// 转换数据量单位
	function unitData(data){
		if(data == 0) {
			return 0 + "B";
		}
		var kb = data / 1024;
		if( kb > 1 || kb == 1){
			var mb = kb / 1024;
			if(mb > 1 || mb == 1) {
                var gb = mb / 1024;
                if(gb > 1 || gb == 1) {
                    return gb.toFixed(2) + " GB";
                }else{
                    return mb.toFixed(2) + " MB";
                }
			}else {
				return kb.toFixed(2) + " KB";
			}
		}
	}
	function formatSeconds(value) {
		let result = parseInt(value/1000)
		let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
		let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
		let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
	 
		let res = '';
		// if(h !== '00') res += `${h}:`;
		// if(m !== '00') res += `${m}:`;
		res += `${h}:`;
		res += `${m}:`;
		res += `${s}`;
		return res;
	}
	self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
		initTabData();
    };
	//监听按钮
    self.$watch(function() {
        return self.checkBackupRecoverJobToCount;
    }, function(val) {
		if(val == undefined) {
			return ;
		}
        self.checkedItems = [];
		var arr=[];
        for(var i in self.checkBackupRecoverJobToCount.items){
            arr.push(self.checkBackupRecoverJobToCount.items[i])
        }
		if(val && arr.length>=0){
            for(var key in val.items){
                if(val.items[key]){
                    self.dataLoadData.forEach(item=>{
                        if(item.id==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
		self.btn = {
			canStop: false,
			canDel: false
		}
		if(self.checkedItems.length > 0){
			self.btn.canStop = true;
			self.btn.canDel = true;
			self.checkedItems.forEach(x => {
				// 状态是  1 2 4 的可以停止
				var runing = [1, 2, 4];
				var runing_del = [1, 2, 4, 512];
				if(runing.indexOf(x.jobStatus) == -1) {
					self.btn.canStop = false;
				}else if(runing_del.indexOf(x.jobStatus) > 0){
					self.btn.canDel = false;
				}
			});
		}
    },true);


	self.openBackupRecoverJobModel = openBackupRecoverJobModel;
	// 创建 弹出框
	function openBackupRecoverJobModel() {
        var editData = angular.copy(self.editData);
		$uibModal.open({
			animation: true,
			templateUrl: "js/advanced-function/backup/backup-recover/tmpl/add-backup-recover-job.html",
			controller: "addBackupRecoverJobCtrl",
			resolve: {
				editData: function() {
					return editData;
				},
				initBackupRecoverJobTable: function() {
					//刷新table;
				    return initTabData;
				},
			}
		});
    }

	//详情页面 任务
    self.$on("getDetail", function(event, value) {
        self.detailData = {
            backupFlag:"info"
        };
    });
	//关闭详情页
	self.closeDetailModal=function(){
        // 置空。会重新刷新指令页面
        self.detailData.backupFlag = "";
        $location.url('/advanced/backup-recover-job');
    };

	//停止操作
	self.stopJob = stopJob;
	function stopJob() {
		if(!self.btn.canStop) {
            return;
        }
		self.checkedItems.forEach(x => {
			let ids = [];
			ids.push(x.id);
			// headers 
			var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                const {projectUid,domainUid} = x;
				headers = {
					project_id: projectUid,
					domain_id: domainUid,
				}
            }
			backupRecoverJobSrv.stopRecoverJob(ids, headers).then(function () {
				initTabData();
			});
		});
		
	};

	//删除操作
    self.delJob = delJob;
    function delJob(checkedItems) {
        if(!self.btn.canDel) {
            return;
        }
        var content = {
            target: "delJob",
            msg: "<span>" + $translate.instant("aws.system.storagement.deleteTip") + "</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delJob", function(e, data) {
		data.forEach(x => {
			let ids = [];
			ids.push(x.id);
			var params = {
				ids: ids,
			}
			// headers 
			var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                const {projectUid,domainUid} = x;
				headers = {
					project_id: projectUid,
					domain_id: domainUid,
				}
            }
			backupRecoverJobSrv.deleteRecoverJob(params, headers).then(function () {
				initTabData();
			});
		});
    })
	self.$on("backupServiceConfig", function(e, data) {
        // 展示为配置列表
        self.backupServiceConfig = data;
    });
	//server socket
	 self.$on("backupRecoverJob", function(e, data) {
        if(data.jobServiceType == "backup-recover-job"
        ){  
			// 更新列表
			self.dataLoadData.forEach(x => {
				var webData = data.resultData.recoverJob;
				if(x.jobId == webData.jobId) {
					x.jobStatus = webData.jobStatus;
					x.jobSpeed = webData.jobSpeed;
					// 已完成数据量
					x.jobCompletedData = unitData(webData.jobCompletedData)
					// 已运行时间
					x.jobRunTime = webData.jobRunTime != null ? webData.jobRunTime!=0 ? formatSeconds(webData.jobRunTime) : "00:00:00" : "00:00:00";
				}
			});
        }
    });

	self.linkTo = function(item){
        utilTool.setLocalStorage([item]);
		// ng-href="#/advanced/backup-recover-job?id={{item.jobId}}"
        $location.url('/advanced/backup-recover-job?id='+item.id);
    }
};
export {backupRecoverJobCtrl}