import './affinitySrv';
angular.module("affinityModule", ["ngSanitize", "ngTable", "ui.bootstrap.tpls", "ui.select", "affinitySrv", "affinitySrv"])
    .controller("affinityCtrl", ["$routeParams", "$scope", "$rootScope", "NgTableParams", "$uibModal", "$translate", "checkedSrv", "GLOBAL_CONFIG", "$filter", "$window", "affinitySrv","utilTool","$location",
        function ($routeParams, $scope, $rootScope, NgTableParams, $uibModal, $translate, checkedSrv, GLOBAL_CONFIG, $filter, $window, affinitySrv,utilTool,$location) {
            var self = $scope;
            self.search={};
            self.pageSize = 10;
            initAffinityTable();
            self.createAffinity = function () {
                utilTool.newResSetLocalStorage(self,"checkboxes","items")
                var $modalAffinity = $uibModal.open({
                    animation: self.animationsEnabled,
                    backdrop: 'static',
                    templateUrl: "newAffinity.html",
                    controller:  "createAffinityCtrl",
                    resolve: {
                        initAffinityTable: function() {
                            return initAffinityTable;
                        },
                        checkName:function(){
                            return checkName;
                        },
                        closeModal:function(){
                            return function(){
             
                                $modalAffinity.close()
                            }
                        }
                    }
                });
            }
            self.refreshTable = function () {
                initAffinityTable();
            }
            self.deleteAffinity = function () {

                var contents = {
                    target: "deleteAffinityGroups",
                    msg:  "<span>"+$translate.instant('aws.instances.delAnnitify')+"</span>",
                };
                self.$emit("delete", contents);
            }
            self.linkTo = function(item){
                utilTool.setLocalStorage([item]);
                $location.url('/cvm/affinity?id='+item.groupId)
            }
            self.linkClose = function(){
                self.checkboxes.items = {};
                utilTool.setLocalStorage([]);
                $location.url('/cvm/affinity');
            }
            self.$on("deleteAffinityGroups", function () {
               var ids = [];
               var names = [] ;
               self.checkedItems.map(function(item){
                    ids.push(item.groupId);
                    names.push(item.name)
               })
               affinitySrv.delAffinityGroups(ids,names).then(function (data) {
                    initAffinityTable();
                })
            });
            //检查是否同名
            function checkName(name) {
                var flag = false;
                if (self.tableData) {
                    self.tableData.map(function (item) {
                        if (name === item.name) {
                            flag = true;
                        }
                    })
                } else {
                    flag = false;
                }
                return flag;
            }
            self.$watch(function() {
                return self.checkedItems;
            }, function(value,old) {
                if(value&&old&&value.length!=old.length){
                    utilTool.setLocalStorage(value);
                }
            })
            
            function initAffinityTable(options,type) {
                //self.globalSearchTerm ="";
                var pageDefaultOption = {
                    pageSize:self.pageSize,
                    pageNum:1,
                    pattern:''
                };
                if(!type){
                    self.currentPage = 1;
                }
                var pageOption = angular.extend(pageDefaultOption,options)
                affinitySrv.getList(pageOption).then(function (data) {
                        data ? self.loadData = true : "";
                    if (data && data.data) {
                        var tableData = data.data;
                        self.tableData = tableData;
                        tableData.map(function (item) {
                            //
                            if(item.domainName=="default"){
                                item.domainName_TR = $translate.instant("aws.common.defaultDepar")
                            }else{
                                item.domainName_TR = item.domainName
                            }
                            //
                            if(item.projectName=="admin"){
                                item.projectName_TR = $translate.instant("aws.common.defaultProject")
                            }else{
                                item.projectName_TR = item.projectName
                            }
                            item.beAffinity = item.policies[0];
                            item.searchTerm = [item.name, $translate.instant("aws.instances." + item.beAffinity)].join("\b");
                        })
                        self.tableParams = new NgTableParams({
                            count: GLOBAL_CONFIG.PAGESIZE
                        }, {
                            counts: [],
                            dataset: tableData
                        });
                        self.applyGlobalSearch = function () {
                            var term = self.globalSearchTerm;
                            self.tableParams.filter({
                                searchTerm: term
                            });
                        };
                        var tableId = "uid";
                        self.totalNum = data.total;
                        checkedSrv.checkDo(self, tableData, "id");
                    }
                });
            }
            function getMemberList(){
                self.search.globalSearchTerm_="";
                var id = self.groupId
                affinitySrv.getAffinityGroupMembers(id).then(function(data){
                    data ? self.loadData_ = true : "";
                    if(data && data.data && data.data.instances){
                        var memberData = data.data.instances;
                        memberData.map(function(item){
                             item.status = item.status.toLowerCase();
                             item.searchTerm = [item.name,item.imageName,$translate.instant("aws.instances.table.status." + item.status),item.fixedIps,item.floatingIps].join("\b");
                        })
                        self.memeberTable = new NgTableParams({
                            count: 9999
                        }, {
                            counts: [],
                            dataset: memberData
                        });
                        // 搜索功能
                        self.applyGlobalSearch = function (globalSearchTerm_) {
                            self.search.globalSearchTerm_ = globalSearchTerm_;
                            self.memeberTable.filter({
                                searchTerm: self.search.globalSearchTerm_
                            });
                        };
                        
                        
                    }
                })
            }
            self.$on("getDetail", function (event, id) {
                self.groupId = id;
                self.loadData_ = false
                getMemberList()
                self.refreshMemberTable = function(){
                    self.loadData_ = false
                    getMemberList(); 
                }
            })
            //切换页码
            self.changePage=function(options){
                options.pattern = self.globalSearchTerm?self.globalSearchTerm:""
                initAffinityTable(options,"changePage");
            };
            //后端搜索
            self.searchBackend = function(options){
                self.currentPage = 1;
                var pageOption = {
                    pageSize:self.pageSize,
                    pageNum:1,
                    pattern:self.globalSearchTerm?self.globalSearchTerm:""
                };
                if(options.type=='refresh'){
                    self.globalSearchTerm = "";
                    pageOption.pattern = "";
                }
                initAffinityTable(pageOption);
            }
            //导出
        self.exportResource = function(){
            var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/instance_groups?regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm}`
            utilTool.downloadFile(url);
        }
        //控制导出按钮是否显示，当选中全部部门时显示
        self.showExport = utilTool.isAllDomainSelect;
            self.isAllDomainSelect = function(){
                return utilTool.isAllDomainSelect();
            }

        }
    ])
.controller("createAffinityCtrl", ["$routeParams", "$scope", "$rootScope", "NgTableParams", "$uibModal", "$translate", "checkedSrv", "GLOBAL_CONFIG", "$filter", "$window", "affinitySrv","initAffinityTable","checkName","closeModal",
        function ($routeParams, $scope, $rootScope, NgTableParams, $uibModal, $translate, checkedSrv, GLOBAL_CONFIG, $filter, $window, affinitySrv,initAffinityTable,checkName,closeModal) {
             $scope.submitInValid = false;
                $scope.createData = {
                    name: "",
                    beAffinity: "1",
                }
                $scope.repeatName = false;
                $scope.canSubmit = false ;
                $scope.confirm = function (createForm) {
                   
                    if (createForm.$valid && !$scope.canSubmit) {
                        $scope.canSubmit = true;
                       $scope.repeatName = checkName($scope.createData.name);
                        if ($scope.repeatName) {
                            $scope.canSubmit = false;
                        } else {
                            var policie = $scope.createData.beAffinity === "1" ? "affinity" : "anti-affinity";
                            var postData = {
                                name: $scope.createData.name,
                                policies: [policie]
                            }
                            if(localStorage.selectedAllDomain==1&&localStorage.managementRole=='2'){
                                var header = {
                                    domain_id:localStorage.defaultdomainUid,
                                    domain_name:encodeURI(localStorage.defaultdomainName),
                                    project_id:localStorage.defaultProjectUid,
                                    project_name:encodeURI(localStorage.defaultProjectName)
                                }
                                affinitySrv.creatAffinityGroup(postData,header).then(function () {
                                    initAffinityTable();
                                    closeModal();
                                    setTimeout(function(){
                                        $scope.canSubmit = false;
                                    },1000)
                                })
                            }else{
                                affinitySrv.creatAffinityGroup(postData).then(function () {
                                    initAffinityTable();
                                    closeModal();
                                    setTimeout(function(){
                                        $scope.canSubmit = false;
                                    },1000)
                                })
                            }
                        }

                    } else {
                        $scope.submitInValid = false;
                        return;
                    }

                }
                        
        }])