var cephpgSrvModule = angular.module("cephpgSrvModule",[]);

cephpgSrvModule.service("cephpgSrv",["$http",function($http){
    return{
        getCephData:function(){
            return $http({
                method:"GET",
                url: "/awstack-user/v1/ceph/pg/num"
            });
        },
        saveCephpg:function(data){
            return $http({
                method:"POST",
                url: "/awstack-user/v1/ceph/pg/num",
                data:data
            });
        }
    };
}]);