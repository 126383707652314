filterModal.$inject = [];
function filterModal () {
    return {
        restrict: "E",
        scope: {
            options:"=",
            queryLimit:"=",
            clickFilterConfirm:"=",
            showExitFilterTips:"=",
            query:"&",
            queryParamInit:"&"
        },
        templateUrl:'js/system/billingManagement/tmpl/filter-modal.html',
        link:function(scope, elem, attr,ngModel){
            let self = scope;
            // 筛选弹窗初始状态隐藏
            self.showSelectGroupModal = false;
            // 点击筛选按钮显示和隐藏弹框
            self.clickFilter = function(){
                // 初始化弹窗中下拉列表的搜索条件
                self.queryParamInit();
                // 点击筛选按钮，筛选弹窗切换显示和隐藏
                self.showSelectGroupModal = !self.showSelectGroupModal;
            }

            // 确定
            self.confirm = function(){
                // 点击了筛选弹框中确定按钮 搜索提示结果显示的条件之一 
                self.clickFilterConfirm = true;
                // 点击退出筛选 筛选加载中提示隐藏的条件之一 
                self.showExitFilterTips = true;
                // 请求分页列表
                self.query();
                // 初始化弹窗中的下拉列表的搜索条件
                self.queryParamInit();
                self.showSelectGroupModal = false;
            }

            // 关闭
            self.close = function(){
                // 初始化弹窗中的下拉列表的搜索条件
                self.queryParamInit();
                self.showSelectGroupModal = false;
            }
        }
    }
}

export { filterModal }