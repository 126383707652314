var generalService = angular.module("generalSrv", []);
generalService.service("generalSrv", ["$http", function($http){
    var static_user_url = "/awstack-user/v1";
    return{
        
        editTimeoutData:function(option) {
            return $http({
                method: "PUT",
                url: static_user_url + "/params",
                data: option
            });
        },
        getRecycleTimeData: function(regionKey) {
            return $http({
                method: "GET",
                url: static_user_url + "/enterprises/regions/" + regionKey + "/configs",
                params: {
                    "configName": "reclaim_instance_interval"
                }
            });
        },
        editRecycleTimeData: function(enterpriseUid, regionUid, option) {
            return $http({
                method: "POST",
                url: static_user_url + "/enterprises/" + enterpriseUid + "/region/"+ regionUid + "/confVersion",
                data: option
            });
        },
        getUpsData: function(regionKey) {
            return $http({
                method: "GET",
                url: static_user_url + "/enterprises/regions/" + regionKey + "/configs",
                params: {
                    "configName": "ups_type"
                }
            });
        },
        editUpsData: function(enterpriseUid, regionUid, option) {
            return $http({
                method: "POST",
                url: static_user_url + "/enterprises/" + enterpriseUid + "/region/"+ regionUid + "/confVersion",
                data: option
            });
        },
        saasConfInfo:function(parentId,enterpriseUid,regionUid) {
            return $http({
                method: "GET",
                url: static_user_url + "/params",
                params: {
                    "parentId": parentId,
                    "enterpriseUid": enterpriseUid?enterpriseUid:localStorage.enterpriseUid,
                    "regionUid": regionUid?regionUid:0
                }
            });
        },
        getRegionConfigs: function() {
            return $http({
                method: "GET",
                url: static_user_url + "/enterprises/" + localStorage.enterpriseUid + "/regions"
            });
        },
        confVersion:function(data,regionUid){
            return $http({
                method: "POST",
                url: "awstack-user/v1/enterprises/" + localStorage.enterpriseUid + "/region/" + regionUid +"/confVersion",
                data: data

            });
        },
        saasCofSet:function(option){
            return $http({
                method: "PUT",
                url: static_user_url + "/params",
                data: option
            });
        },
        getConfStatus:function(){
            return $http({
                method: "GET",
                url: static_user_url + "/enterprises/" + localStorage.enterpriseUid + "/region/" + localStorage.regionKey + "/confVersion/status",
            });
        },
        setSyncTimingTask:function(data){
            return $http({
                method: "POST",
                url: "awstack-schedule/v1/job/sync/recources",
                data: data
            });
        },
        getSyncTimingTask:function(data){
            return $http({
                method: "GET",
                url: "awstack-schedule/v1/job/sync/recources?name=syncResourcesDataJobName&group=syncResourcesDataJobGroup",
            });
        },
        nowSetTimeTask:function(){
            return $http({
                method: "GET",
                url: "awstack-manage/v2/sync/data/immediate",
            });
        },
        //同步物理GPU
        getPhyGpu:function(){
            return $http({
                method: "GET",
                url: "awstack-resource/v1/gpu/synchGpus",
            });
        },
        // ceph硬盘故障自愈
        cephDiskFault:function() {
            return $http({
                method: "GET",
                url: static_user_url + "/params",
                params: {
                    "parentId": '995',
                    "enterpriseUid": localStorage.enterpriseUid,
                    "regionUid": localStorage.regionUid
                }
            });
        },
        // 克隆链设置
        cloneChainSet:function(data) {
            return $http({
                method: "POST",
                url: `${static_user_url}/bob/tasks/modify_rbd_flatten`,
                data: data
            })
        },

        //克隆链查询
        getCloneChainInfo:function() {
            return $http({
                method: "GET",
                url: `${static_user_url}/bob/tasks/modify_rbd_flatten`
            })
        }
    };
}]);
