var componentModule = angular.module("componentModule", []);
componentModule.directive("helpInformation", ["$rootScope", "$location", "$http", "$route", "helpSrv", "$translate", function(rootScope, $location, $http, $route, helpSrv, translate) {
    return {
        restrict: "EA",
        scope: {
            modular: '='
        },
        replace: true,
        template: `<div class ="detail-row">
                    <div class="detail-title">
                        <div class="help-title clearfix">
                          <a class = "an-close" ng-href="{{InformationData.route}}"><i class="icon-aw-mail-reply"></i></a>
                          <span>{{'aws.common.helpInformation'|translate}}</span>
                        </div>
                    </div>                        
                    <div class="detail-info">
                        <div ng-repeat="item in InformationData.data">
                            <label class="help-title" ng-show="item.title!=''">{{item.title}}</label>
                            <div ng-class="{'subdes':item.title!=''}" ng-bind-html="item.describe"></div>
                        </div>
                    </div>
                    <div class="more">
                        <a ng-href="{{InformationData.detailRoute}}"  target="_blank" ng-show="InformationData.detailRoute!=''">{{'aws.common.viewDetailedManual'|translate}}</a>
                    </div>
                </div>`,
        replace: true,
        link: function(scope, elem, attr) {
            var self = scope;
            var modular = self.modular;
            self.InformationData = helpSrv.helpInformation[modular];
        }
    }
}]);
componentModule.directive("intoInformation", ["$rootScope","$location","$http","$route","$translate",function(rootScope,$location,$http,$route,$translate) {
    return {
        restrict: "EA",
        scope:{
            modular:'='
        },
        replace: true,
        template: `<div type="button" uib-popover="{{'aws.popover.btn.helpInfo'|translate}}" popover-enable="true" popover-trigger="mouseenter" popover-placement="top" class="btn btn-renovat right-ports help-tips" ng-click="systemCheckHelp()">
            <i class="icon-aw-question-mark1"></i>
        </div>`,
        replace: true,
        link: function(scope, elem, attr) {
            var self = scope;
            self.systemCheckHelp = function(){
                $location.search('helpmodular='+scope.modular)
            }
        }
    }
}]); 
componentModule.directive("pageComponent",["$translate",function($translate){
    function countPage(totalNum,pageStep,currentPage){
        var pages = [];
        var _length = Math.ceil(totalNum / pageStep);
        if (_length > 1) {
            pages.push({
                type: "first",
                number: 1
            })
            var maxPivotPages = 2; //5
            var minPage = Math.max(2, currentPage - maxPivotPages); //
            var maxPage = Math.min(_length - 1, currentPage + maxPivotPages * 2 - (currentPage - minPage));
            minPage = Math.max(2, minPage - (maxPivotPages * 2 - (maxPage - minPage)));
            for (let i = minPage; i <= maxPage; i++) {
                if ((i == minPage && i != 2) || (i == maxPage && i != _length - 1)) {
                    pages.push({
                        type: 'more'
                    })
                } else {
                    pages.push({
                        number: i,
                        type: "page"
                    });
                }

            }
            pages.push({
                type: 'last',
                number: _length
            });
        }

        return pages;
    }
    return {
        restrict: "E",
        scope: {
            total: "=",
            changePage: "=",
            currentPage:"=",
            step:"=",
            likes:"="
        },
        templateUrl: "/tmpl/page.html",
        replace: true,
        link: function(scope, ele) {
            scope.currentPage = scope.currentPage?scope.currentPage:1;
            scope.step = scope.step?scope.step:10;
            scope.$watch(function() {
                if (scope.total) {
                    return scope.total + ',' + scope.step + ','+ scope.currentPage;
                }
            }, function(ne) {
                if (scope.total > 0) { 
                    scope.stepModel=scope.step;
                    scope.totalNum = ne.split(",")[0];
                    scope.pageStep = ne.split(",")[1];
                    scope.numPages = Math.ceil(scope.totalNum / scope.stepModel);
                    scope.currentPage = scope.currentPage;
                    if (scope.totalNum && scope.totalNum > 0) {
                        scope.pages = countPage(scope.totalNum, scope.stepModel, scope.currentPage);
                    }
                } else {
                    scope.pages = [];
                }
            });
            scope.goPage = function(item) {
                if (item.number) {
                    if (scope.currentPage == item.number) {
                        return;
                    };
                    scope.currentPage = item.number;
                    scope.prevDisabled = scope.currentPage == 1;
                    scope.nextDisabled = scope.currentPage == scope.numPages;
                    var postData = {
                        pageSize: scope.stepModel,
                        pageNum:Number(scope.currentPage),
                        likes:scope.likes
                    };
                    scope.changePage(postData);
                    scope.pages = countPage(scope.totalNum, scope.stepModel, scope.currentPage)
                }
            };
            scope.prevNext = function(type) {
                if (type == 'prev') {
                    if (scope.currentPage == 1) { return; }
                    scope.currentPage = scope.currentPage - 1 > 0 ? scope.currentPage - 1 : 1;
                    scope.nextDisabled = scope.currentPage == scope.numPages;
                    scope.prevDisabled = scope.currentPage == 1;
                } else if (type == 'next') {
                    if (scope.currentPage == scope.numPages) { return; }
                    scope.currentPage = scope.currentPage + 1 < scope.numPages ? scope.currentPage + 1 : scope.numPages
                    scope.nextDisabled = scope.currentPage == scope.numPages;
                    scope.prevDisabled = scope.currentPage == 1;
                }
                var postData = {
                    pageSize: scope.stepModel,
                    pageNum:Number(scope.currentPage),
                    likes:scope.likes,
                    pattern:scope.likes
                };
                scope.changePage(postData);
                scope.pages = countPage(scope.totalNum, scope.stepModel, scope.currentPage)
            };
            //切换每页展示的数据条数，则相当于重新刷新页面
            scope.changeStep = function(step){
                scope.currentPage = 1;
                scope.pageSize = step
                scope.stepModel = step;
                scope.step=step;
                var postData = {
                    pageSize: scope.stepModel,
                    pageNum: 1,
                    likes: scope.likes,
                    pattern: scope.likes
                };
                scope.changePage(postData);
            };
            
            scope.stepList = [
                {id: 10, name: $translate.instant("aws.overview.pageMsg",{page: 10})},
                {id: 20, name: $translate.instant("aws.overview.pageMsg",{page: 20})},
                {id: 50, name: $translate.instant("aws.overview.pageMsg",{page: 50})}
            ];//分页条数可选择列表
            scope.stepModel = scope.step;//下拉框可以选择一页展示的条数（stepModel）
            scope.pageSize = scope.stepList[0].id
        }
    };
}]);
componentModule.directive("consumeChartBar", function($timeout, $translate, resize) {
    return {
        restrict: "A",
        replace: true,
        transclude: true,
        scope: {
            bardata: "="
        },
        template: function(elem) {
            return "<div>\
                        <div class=\"left\">{{bardata.name}}</div>\
                        <div class=\"right\">{{'aws.bill.consumption'|translate}}：  <span class=\"value progress-bar-{{barTypeFunc(inUsepercent)}}\" >{{bardata.inUsed}}</span>/{{bardata.total}}</div>\
                        <div class=\"progress active width pull\">\
                            <div class=\"progress-bar progress-bar-{{barTypeFunc(inUsepercent)}}\" aria-valuenow=\"used\" aria-valuemin=\"0\" aria-valuemax=\"bardata.total\" ng-style=\"{width: (inUsepercent?(inUsepercent < 100 ? inUsepercent : 100):0) + \'%\'}\"></div>\
                        </div>\
                    </div>";
        },
        link: function(scope, elem, attr) {
            scope.used = 0;
            scope.inUsepercent = 0;
            scope.$watch(function() {
                return scope.bardata;
            }, function(bardata) {
                if (bardata) {
                    $timeout(function() {
                        scope.used = scope.bardata.inUsed || 0;
                        scope.inUsepercent = scope.bardata.total > 0 ? (scope.bardata.inUsed / scope.bardata.total * 100).toFixed(1) : 0 || 0;
                    }, 10);
                }
            });

            scope.barTypeFunc = function(percent) {
                if (percent <= 30) {
                    return "green";
                } else if (percent > 30 && percent <= 50) {
                    return "blue";
                } else if (percent > 50 && percent <= 65) {
                    return "blueDark";
                } else if (percent > 65 && percent <= 75) {
                    return "orange";
                } else if (percent > 75 && percent <= 85) {
                    return "orangeDark";
                } else if (percent > 85 && percent <= 95) {
                    return "red";
                } else if (percent > 95) {
                    return "redDark";
                } else {
                    return "default";
                }
            };

        }
    };
});
componentModule.directive("tableAction", function() {
    return {
        restrict: "A",
        scope: {
            filterData: "=",
            csvHeader: "=",
            fileName: "=",
            query: "&",
            downloadData: "&",
            definedDownloadData: "&",
            search: "&",
            refresh: "&",
            colsConfig: "=",
            selectCol: "&",
            timePrecision: "="
        },
        templateUrl: "/tmpl/timeFilterTableAction.html",
        replace: true,
        transclude: true,
        link: function(scope, elem, attr) {
            if (!attr.search) {
                scope.search = false;
            }
            if (!attr.refresh) {
                scope.refresh = false;
            }
            if (!attr.downloadData) {
                scope.downloadData = false;
            }
            if (!attr.definedDownloadData) {
                scope.definedDownloadData = false;
            }

            scope.timeFormat = "yyyy-mm-dd";
            scope.timePattern = /^\d{4}-([0][1-9]|[1][0-2])-([0-2][1-9]|[1-2][0]|[3][0-1])$/;

            if (scope.timePrecision == "sec") {
                scope.timeFormat = "yyyy-mm-dd hh:mm:ss";
                scope.timePattern = /^\d{4}-([0][1-9]|[1][0-2])-([0-2][1-9]|[1-2][0]|[3][0-1]) ([0-1][0-9]|[2][0-4]):([0-6][0-9]):([0-6][0-9])$/;
            }

            function init_dateTimepicker() {
                elem.find(".form_date").datetimepicker({
                    language: "zh-CN",
                    weekStart: 1,
                    todayBtn: 1,
                    autoclose: 1,
                    todayHighlight: 1,
                    minView: "month",
                    forceParse: 0,
                    format: scope.timeFormat,
                    pickerPosition: "bottom-left"
                });
            }

            scope.$watch(function() {
                return elem.find(".form_date").length;
            }, function(elem) {
                if (elem) {
                    init_dateTimepicker();
                }
            });

            scope.timeType = {
                options: [{
                    "name": "未选择",
                    "value": ""
                }, {
                    "name": "自定义",
                    "value": "defined"
                }, {
                    "name": "最近三天",
                    "value": "3d"
                }, {
                    "name": "最近一周",
                    "value": "7d"
                }, {
                    "name": "最近一个月",
                    "value": "30d"
                }, {
                    "name": "最近三个月",
                    "value": "90d"
                }]
            };

            scope.timeSelectTab = function(step) {
                scope.filterData.from = null;
                scope.filterData.to = null;
                if (step != "" && step != "defined") {
                    let start = moment().subtract(Number(step.substring(0, step.length - 1)) - 1, step.substring(step.length - 1, step.length)).format("YYYY-MM-DD");
                    let end = moment().format("YYYY-MM-DD");
                    if (scope.timePrecision == "sec") {
                        start = moment().subtract(Number(step.substring(0, step.length - 1)) - 1, step.substring(step.length - 1, step.length)).format("YYYY-MM-DD 00:00:00");
                        end = moment().format("YYYY-MM-DD 24:00:00");
                    }
                    scope.filterData.from = start;
                    scope.filterData.to = end;
                }
            };
        }
    }
});
componentModule.directive("myDaterangepicker", ["$translate","$timeout",function($translate,$timeout) {
    return {
        restrict: "A",
        scope: {
            timeType: "=?",
            csvHeader: "=",
            fileName: "=",
            query: "&",
            downloadData: "&",
            definedDownloadData: "&",
            search: "&",
            refresh: "&",
            colsConfig: "=",
            selectCol: "&",
            timePrecision: "=",
            singleDatePicker:"@",
            key:"@?",
            isshow: "<",
            collectCycle: "=?",
            collectCycleList: "=?",
            filterData: "=",
        },
        templateUrl: "/tmpl/timeRangeFilterAction.html",
        replace: true,
        transclude: true,
        link: function(scope, elem, attr) {
            scope.isShowSelect = false;
            if(typeof scope.isshow == 'boolean'&& scope.isshow){
                scope.isShowSelect = true;
            }
            if(!scope.key){
                scope.lisenceBtn = 'other'
            }else{
                scope.lisenceBtn = 'btn'
            }
            if (!attr.query) {
                scope.query = false;
            }
            if (!attr.search) {
                scope.search = false;
            }
            if (!attr.refresh) {
                scope.refresh = false;
            }
            if (!attr.downloadData) {
                scope.downloadData = false;
            }
            if (!attr.definedDownloadData) {
                scope.definedDownloadData = false;
            }
            if (!attr.collectCycle) {
                scope.collectCycle = true;
            }
            // 设置显示一个datePicker 默认是两个
            if(!attr.singleDatePicker){
                scope.singleDatePicker = false;
            }else{
                scope.singleDatePicker = true;
            }
            // scope.filterData.definedTime = false;
            // scope.filterData.definedTimeText = "选择日期";

            if(!attr.timeType) {
                scope.timeType = {
                    options: [{
                        "name": $translate.instant('aws.bill.timeType.threeDays'),
                        "value": "3d"
                    }, {
                        "name": $translate.instant('aws.bill.timeType.oneWeek'),
                        "value": "7d"
                    }, {
                        "name": $translate.instant('aws.bill.timeType.oneMonth'),
                        "value": "30d"
                    }]
                };
            }
            
            elem.find('#config-demo').daterangepicker({
                "language": 'zh-CN',
                "singleDatePicker":scope.singleDatePicker,
                "startDate": scope.singleDatePicker?moment():moment().subtract(1, "months"),//单日历下拉 开始日期设为当前日期
                "maxDate" : moment(),
                "timePicker": false,
                "timePicker24Hour": false,
                "linkedCalendars": false,
                "autoUpdateInput": false,
                "opens": "left",
                "locale": {
                    format: 'YYYY-MM-DD',
                    separator: ' 至 ',
                    applyLabel: "确定",
                    cancelLabel: "取消",
                    daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
                    monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
                }
            });

            if(!attr.collectCycleList) {
                scope.collectCycleList = [
                    {name: $translate.instant("aws.monitor.physical.notRefresh"), value: null},
                    {name: $translate.instant("aws.monitor.physical.5sec"), value: 5},
                    {name: $translate.instant("aws.monitor.physical.10sec"), value: 10},
                    {name: $translate.instant("aws.monitor.physical.30sec"), value: 30},
                    {name: $translate.instant("aws.monitor.physical.1min"), value: 60}
                ];
            }
            scope.filterData = scope.filterData || {}
            // 默认值
            scope.filterData.precision = scope.collectCycleList[0].value;

            scope.precisionChange = async function(value){
                if(value){
                    setTimer(value);
                }else{
                    $timeout.cancel(scope.timer);
                }
            }
            scope.$on('$destroy', function () {
                $timeout.cancel(scope.timer);
            });

            scope.$on('clearTimeout', function (e,data) {
                if(scope.timer){
                    data.precision = null;
                    $timeout.cancel(scope.timer);
                }
            });

            elem.find('#config-demo').on('apply.daterangepicker',function(ev, picker) {
                let startTime = picker.startDate.format(picker.locale.format);
                let endTime = picker.endDate.format(picker.locale.format);
                let separator = picker.locale.separator;
                scope.filterData.from = startTime;
                scope.filterData.to = endTime;
                if(scope.timePrecision == "sec") {
                    scope.filterData.from = picker.startDate.format("YYYY-MM-DD 00:00:00");
                    scope.filterData.to = picker.endDate.format("YYYY-MM-DD 23:59:59");
                }
                if(!picker.startDate || !picker.endDate) {
                    elem.find(".time-input").val('');
                } else {
                    // 判断单个的datepicker
                    if(scope.singleDatePicker){
                        var definedTimeText = startTime;
                    } else {
                        var definedTimeText = startTime + separator + endTime;
                    }
                    elem.find(".time-input").val(definedTimeText);
                    scope.filterData.definedTime = true;
                    scope.filterData.definedTimeText = definedTimeText;
                }
                scope.$apply();
            });
            
            scope.MainTypeValue = [
                { name: $translate.instant('aws.indextran.average'), value: "average" },
                { name: $translate.instant('aws.indextran.peak'), value: "peak" },
            ]
            // 默认值
            scope.defaultValue = scope.MainTypeValue[0].value;

            scope.$emit('cloud_main_type', scope.defaultValue)
            scope.changeMainType = function(value){
                scope.$emit('cloud_main_type', value)
            }
            
            scope.timeSelectTab = function(step) {
                //采集周期选择的1小时，时间筛选不可以选择实时
                if(scope.filterData.precision==3600&&!step){
                   return;
                }
                //时间筛选的高亮
                scope.filterData.timeStep = step;
                scope.filterData.from = null;
                scope.filterData.to = null;
                scope.filterData.queryType = null;
                if (step != "" && step != "defined") {
                    let start = moment().subtract(Number(step.substring(0, step.length - 1)) - 1, step.substring(step.length - 1, step.length)).format("YYYY-MM-DD");
                    let end = moment().format("YYYY-MM-DD");
                    if (scope.timePrecision == "sec") {
                        start = moment().subtract(Number(step.substring(0, step.length - 1)) - 1, step.substring(step.length - 1, step.length)).format("YYYY-MM-DD 00:00:00");
                        end = moment().format("YYYY-MM-DD 23:59:59");
                    }
                    if(step.substring(step.length - 1, step.length) == "h") {
                        start = moment().subtract(Number(step.substring(0, step.length - 1)) - 1, step.substring(step.length - 1, step.length)).format("YYYY-MM-DD HH:mm:ss");
                        end = moment().format("YYYY-MM-DD HH:mm:ss");
                    }
                    scope.filterData.from = start;
                    scope.filterData.to = end;
                    scope.filterData.queryType = step;
                }
                if(step != "defined"){
                    scope.filterData.definedTime = false;
                    scope.filterData.definedTimeText = $translate.instant('aws.bill.chooseDate');
                    scope.filterData.queryType = step === '' ? '30m' : step;
                }
            };
            
            if(scope.filterData&&scope.filterData.precision){
                scope.$watch(function(){
                   return scope.filterData.precision;
                },function(value){
                   if(value==3600&&!scope.filterData.timeStep){
                      scope.timeSelectTab("6h");
                   }
                });
            }

            if(scope.filterData && scope.timeType) {
                scope.filterData.timeStep = scope.timeType.options[0].value;
                scope.timeSelectTab(scope.filterData.timeStep);
            }
        }
    }
}]);
componentModule.directive("billingDetailSelect",function($translate){
    return {
        restrict:"A",
        scope:{
            billOptions:"=",
            filterData:"=",
            currPage:"=",
            inBillingModule:"=",
            changeBillFilter:"&"
        },
        template:`
            <div>
                <div class="control-group" ng-show="inBillingModule">
                    <div class="control-label">数据中心：</div>
                    <div class="controls">
                        <ui-select ng-model="filterData.region" ng-change="changeBillFilter({data: filterData.region,type: 'region'})" >
                            <ui-select-match title="{{$select.selected.name}}">{{$select.selected.name}}</ui-select-match>
                            <ui-select-choices repeat="item in billOptions.regionsList">
                                <div ng-bind-html="item.name" titlt="{{item.name}}" class="bill-select"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                </div>
                <div class="control-group" ng-show="(currPage=='dep' || currPage =='pro' || currPage=='user') && inBillingModule">
                    <div class="control-label">部门：</div>
                    <div class="controls">
                        <ui-select ng-model="filterData.domain" ng-change="changeBillFilter({data: filterData.domain,type: 'domain'})" >
                            <ui-select-match title="{{$select.selected.name}}">{{$select.selected.name}}</ui-select-match>
                            <ui-select-choices repeat="item in billOptions.domainsList">
                                <div ng-bind-html="item.name" title="{{item.name}}" class="bill-select"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                </div>
                <div class="control-group" ng-show="(currPage =='pro' || currPage=='user') && inBillingModule">
                    <div class="control-label">项目：</div>
                    <div class="controls">
                        <ui-select ng-model="filterData.project" ng-change="changeBillFilter({data: filterData.project,type: 'project'})" >
                            <ui-select-match title="{{$select.selected.name}}">{{$select.selected.name}}</ui-select-match>
                            <ui-select-choices repeat="item in billOptions.projectsList">
                                <div ng-bind-html="item.name" title="{{item.name}}" class="bill-select"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                </div>
                <div class="control-group" ng-show="currPage=='user' && inBillingModule">
                    <div class="control-label">用户：</div>
                    <div class="controls">
                        <ui-select ng-model="filterData.user" ng-change="changeBillFilter({data: filterData.user,type: 'user'})" >
                            <ui-select-match title="{{$select.selected.name}}">{{$select.selected.name}}</ui-select-match>
                            <ui-select-choices repeat="item in billOptions.usersList">
                                <div ng-bind-html="item.name" title="{{item.name}}" class="bill-select"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                </div>
                <div class="control-group" ng-show="currPage=='user' && inBillingModule && filterData.userRes">
                    <div class="control-label">资源：</div>
                    <div class="controls">
                        <ui-select ng-model="filterData.userRes"ng-change="changeBillFilter({data: filterData.userRes,type: 'userRes'})" >
                            <ui-select-match title="{{$select.selected.name}}">{{$select.selected.name}}</ui-select-match>
                            <ui-select-choices repeat="item in billOptions.resList">
                                <div ng-bind-html="item.name" title="{{item.name}}" class="bill-select"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                </div>
            </div>
        `,
        replace:true,
        link:function(scope,elem,attr){
            
        }
    }
});

componentModule.directive("domainProjects", ["$http", "$route", "$translate","$timeout", 
    function($http, $route,translate,$timeout) {
        return {
            restrict: "EA",
            scope: true,
            templateUrl: "/tmpl/domain_project.html",
            replace: true,
            link: function(scope, elem, attr) {
                
            },
            controller:function($scope){
                let self = $scope;
                self.isAllDomainShow = false,
                self.showListTips = true,
                self.tops = {
                    deSelected:{},
                    deparList: [],
                    prSelected: {},
                    projectsList: []
                },
                self.domainH = "";
                function dataOpt(res){
                    // let self = this;
                    if (res&&res.data) {
                        self.tops.deSelected = {};
                        self.tops.prSelected = {};
                        let text = translate.instant('aws.common.all');
                        self.tops.deparList = [{
                            domainName:'all',
                            domainUid:'all',
                            disDomainName:text,
                            projects:[],
                            isShow:false
                        }];
                        self.tops.projectsList = [];
        
                        self.tops.deparList = self.tops.deparList.concat(res.data);
                        for (var i = 0; i < self.tops.deparList.length; i++) {
                            //非<全部> 部门下 isShow默认为true,<全部>部门下 通过路由上的isAllDomain来判断是否为true
                            self.tops.deparList[i].isShow = true;
                            self.tops.deparList[i].disDomainName = self.tops.deparList[i].domainName;
                            if (self.tops.deparList[i].domainUid == 'default') {
                                self.tops.deparList[i].disDomainName = translate.instant('aws.common.defaultDepar');
                            }
                            if(self.tops.deparList[i].domainUid == 'all'){
                                self.tops.deparList[i].disDomainName = translate.instant('aws.common.all');
                            }
                            if (self.tops.deparList[i].projects && self.tops.deparList[i].projects.length > 0) {
                                for (var j = 0; j < self.tops.deparList[i].projects.length; j++) {
                                    if (self.tops.deparList[i].projects[j].projectName == 'admin' && self.tops.deparList[i].domainUid == 'default') {
                                        self.tops.deparList[i].projects[j].disProjectName = translate.instant('aws.common.defaultProject');
                                    } else {
                                        self.tops.deparList[i].projects[j].disProjectName = self.tops.deparList[i].projects[j].projectName;
                                    }
                                }
                            }
                        }
                    }
                }
                function setDefaultProject(){
                    //先判断当前页面是否显示全部
                    // let self = this;
                    let domainFlag = 1;
                    let projectFlag = 1;
                    self.tops.deparList[0].isShow = self.isAllDomainShow;
                    //默认选中当前用户的默认部门和项目；
                    for(let ii=0;ii<self.tops.deparList.length;ii++){
                        if (self.tops.deparList[ii].domainUid == localStorage.defaultdomainUid) {
                            self.tops.deSelected = self.tops.deparList[ii];
                            break;
                        }
                    }
        
                    for(let jj=0;jj<self.tops.deSelected.projects.length;jj++){
                        if(self.tops.deSelected.projects[jj].projectId==localStorage.defaultProjectUid){
                            self.tops.prSelected = self.tops.deSelected.projects[jj];
                            break;
                        }
                    }
                    
                    // 根据本地localstorage设置部门项目默认值
                    for(let ii=0;ii<self.tops.deparList.length;ii++){
                        if (self.tops.deparList[ii].domainUid == localStorage.domainUid) {
                            domainFlag++
                            self.tops.deSelected = self.tops.deparList[ii];
                            self.tops.prSelected = self.tops.deSelected.projects[0];
                            break;
                        }
                    }
        
                    for(let jj=0;jj<self.tops.deSelected.projects.length;jj++){
                        if(self.tops.deSelected.projects[jj].projectId==localStorage.projectUid){
                            projectFlag++
                            self.tops.prSelected = self.tops.deSelected.projects[jj];
                            break;
                        }
                    }

                    //删除当前所在部门时，localStorage.domainUid的值
                    if(domainFlag==1){
                        localStorage.domainName = self.tops.deSelected.domainName;
                        localStorage.domainUid = self.tops.deSelected.domainUid;
                    }
                    //删除当前所在项目时，localStorage.projectUid所存项目已失效，需要重新注册localStorage.projectUid的值
                    if(projectFlag==1){
                        localStorage.projectName = self.tops.prSelected.projectName;
                        localStorage.projectUid = self.tops.prSelected.projectId;
                    }
        
                    //如果当前页面需要显示<全部>且本地有记录，此时默认值设为<全部>
                    if(self.isAllDomainShow&&localStorage.selectedAllDomain==1){
                        self.tops.deSelected = self.tops.deparList[0];
                        self.tops.prSelected = {};
                    }
                }
                async function getProjectDomainData(del) {
                    // let self = this;
                    const result = await $http({
                        method: "GET",
                        url: "/awstack-user/v1/user/domains/projects"
                    }).then(res=>res)
                    // const result = await allAPI.getProjectList().then(res=>res);
                    dataOpt(result);
                    if(self.tops.deparList.length>0){
                        setDefaultProject();
                    }
                }
                function getRoleFormUserInProject() {
                    $http({
                        method: "GET",
                        url: "/awstack-user/v1/user/project/" + localStorage.projectUid + "/roles"
                    }).success(function(res) {
                        if (res) {
                            localStorage.rolename = res.roleName;
                        }
                    })
                }
                function init() {
                    self.isDomainProjectShow = $route.current.domainProject;
                    self.isAllDomainShow = $route.current.isAllDomain;
                    getProjectDomainData(); //初始
                }
                self.changeDomain = function(dom){
                    let self = this;
                    if(dom.domainUid!='all'){
                        return;
                    }
                    self.tops.prSelected = {};
                    self.tops.deSelected = dom;
                    //选择全部，将本地localStorage.domainUid，localStorage.projectUid存为默认部门和默认项目
                    localStorage.domainName =localStorage.defaultdomainName;
                    localStorage.domainUid = localStorage.defaultdomainUid;
                    localStorage.projectName = localStorage.defaultProjectName;
                    localStorage.projectUid = localStorage.defaultProjectUid;
                    localStorage.selectedAllDomain=1;
                    getRoleFormUserInProject();
                    //重新加载当前路由 
                    $route.reload();
                }
                self.changeproject = function(n) {
                    // let self = this;
                    localStorage.selectedAllDomain=2;
                    self.showListTips = false;
                    $timeout(function(){
                        self.showListTips = true;
                    },300)
                    self.tops.prSelected = n;
                    self.tops.deSelected = self.domainH;
                    localStorage.domainName = self.domainH.domainName;
                    localStorage.domainUid = self.domainH.domainUid;
                    self.tops.projectsList = self.domainH.projects;
                    localStorage.projectName = n.projectName;
                    localStorage.projectUid = n.projectId;
                    getRoleFormUserInProject();
                    $route.reload();
                }
                init();
                self.$watch(function(){
                    return $route.current.originalPath;
                },function(ne,old){
                    console.log(ne);
                    self.isDomainProjectShow = $route.current.domainProject;
                    if(localStorage.managementRole=='2'){
                        self.isAllDomainShow = $route.current.isAllDomain ? true : false; //是否展示部门列表中的全部展示项
                    }else{
                        self.isAllDomainShow = false;
                    }
                    if(!self.isAllDomainShow&&localStorage.selectedAllDomain==1){
                        localStorage.selectedAllDomain = 2;
                    }
                    if(self.tops.deparList.length>0){
                        setDefaultProject();
                    }
                });
                self.domainMouse = function(m){
                    self.domainH = m;
                }

                //<创建，编辑，删除项目>和<编辑，删除部门> 重新获取部门项目列表
                self.$on("send-create-pd", function(e, val) { 
                    if (val.type == "domain" || val.type == "project") {
                        getProjectDomainData();
                    }
                })
            }
        }
    }
]);
componentModule.directive("regionChange", ["$rootScope", "$location", "$http", "$route", "bobMesSrv", "$translate", "localInit",function(rootScope, $location, $http, $route, bobMesSrv, translate,localInit) {
    return {
        restrict: "EA",
        scope: true,
        templateUrl: "/tmpl/region_change.html",
        replace: true,
        link: function(scope, elem, attr) {
            var self = scope;
            self.isLoginLog = bobMesSrv;
            self.isRegionShow = false;
            // var parentRule = ['Resource',"Monitoring"];//根据菜单判定是否显示
            // var unActiveRule = ['cephview',"topology"];//根据菜单判定是否显
            function getRegionList() {
                self.regionModel = {
                    selected: ""
                };
                $http({
                    method: "GET",
                    url: "awstack-user/v1/enterprises/" + localStorage.enterpriseUid + "/regions"
                }).then(function(res) {
                    if (res && res.data) {
                        self.regionList = res.data.filter(item => {
                            return item.status == 3;
                        })
                        self.regionModel.selected = self.regionList[0];
                        self.regionList.forEach(item => {
                            if (item.regionKey == localStorage.regionKey) {
                                self.regionModel.selected = item;
                            }
                        })
                    }
                })
            }

            const expireObj = JSON.parse(localStorage.getItem('expireIsShow'));
            if(expireObj){
                rootScope.close_alert = expireObj.close_alert;
                rootScope.expireMsg = expireObj.expireMsg;
            }
            function init() {
                if ($route.current) { //初始化项目部门是否可见
                    self.isRegionShow = $route.current.region ? true : false; //region状态的显示隐藏
                }
                getRegionList();
            }
            function getTotalData() {
                localStorage.TotalListName = "";
                $http({
                    method: "GET",
                    url: "/awstack-user/v1/" + localStorage.regionKey + '/' + localStorage.enterpriseUid + "/getTotalResourcesStatistics"
                }).then(function(res) {
                    if (res && res.data) {
                        let str = '';
                        if(Array.isArray(res.data) && res.data.length>0){
                            for (let i = 0; i < res.data.length; i++) {
                                const item = res.data[i];
                                let keyName = '';
                                if(item.name == 'ceph'){
                                    keyName = translate.instant('aws.indextran.ceph');
                                }else if(item.name == 'memory'){
                                    keyName = translate.instant('aws.indextran.memory');
                                }else{
                                    keyName = item.name;
                                }
                                str += keyName + translate.instant('aws.indextran.resourceOverflow') + item.value + '%，';
                            }
                            str += translate.instant('aws.indextran.pleaseExpand');
                        }
                        localStorage.TotalListName = str;
                        rootScope.ListName= str;
                        if (localStorage.TotalListName != "" && rootScope.isLogined) {
                            rootScope.totalResShow = true;
                        } else {
                            rootScope.totalResShow = false;
                        }
                    }

                });
            }
            self.changeRegion = function(n) {
                function regionCb(res){
                    if (res && res.data && res.data.authToken) {
                        // 重置菜单
                        localInit.menuInit(self,res.data);
                        localInit.localstorageInit(res.data);
                        bobMesSrv.closed();
                        bobMesSrv.opened();
                        bobMesSrv.lickFuc();
                        if (localStorage.managementRole == "2") {
                            getTotalData();
                            if (localStorage.TotalListName != "") {
                                rootScope.totalResShow = true;
                            }
                        } else {
                            rootScope.totalResShow = false;
                        }
                    } else {
                        self.regionList.forEach(item => {
                            if (item.regionKey == localStorage.regionKey) {
                                self.regionModel.selected = item;
                            }
                        })
                    }
                }
                function prettyNameCb(res){
                    var nodePrettyName={};
                    if(res&&res.data&&angular.isArray(res.data)){
                        res.data.forEach(function(item){
                            nodePrettyName[item.hostName]=item.prettyName?item.prettyName:item.hostName;
                        });
                        localStorage.nodePrettyNameList=JSON.stringify(nodePrettyName);
                    }
                }
                function licenseBtnCb(res){
                    if (res && res.status == 0) {
                        localStorage.setItem("LicenseList", JSON.stringify(res.data));
                    }
                }

                let neRegionInstan = $http({
                    method: "post",
                    url: "/awstack-user/v1/newregion",
                    data: { 'regionKey': n.regionKey }
                })
                
                //需要更新存的节点别名列表
                let prettyNameFunc = $http({
                    method: "get",
                    url: "awstack-user/v1/enterprises/"+localStorage.enterpriseUid+"/regions/"+n.regionUid+"/nodes/list"
                })
                
                //重新获取按钮权限
                let licenseBtnFunc = $http({
                    method: "GET",
                    url: window.GLOBALCONFIG.APIHOST.BASE + "/v1/region/" + n.regionKey + "/menus/btn/" + localStorage.$USERID
                })
                Promise.all([neRegionInstan,prettyNameFunc,licenseBtnFunc]).then(res=>{
                    console.log(res);
                    // let link = $location.path();
                    regionCb(res[0]);
                    if(res[0] && res[0].data && res[0].data.authToken){
                        prettyNameCb(res[1]);
                        licenseBtnCb(res[2]);
                        location.reload();
                    }
                })
                
            }
            self.$on("send-create-pd", function(e, val) { //快捷创建项目或者部门时接收消息，同时更新部门列表
                if (val.type == "region") {
                    getRegionList()
                }
                if(val.type == "changeRegion"){
                    self.regionModel.selected = val.data;
                }
            })
            self.$on('$routeChangeSuccess', function(event, current, prev) {
                if (current) {
                    self.isRegionShow = current.region ? true : false; //region的显示影藏
                }
            })
            init();
        }
    }
}]);
componentModule.directive("ipv6Tpl",[function(){
    return {
        restrict: "EA",
        scope: {
            data: '=',
            subNet: '=',
            form: '=',
            submitted:'=',
            formName:"="
        },
        templateUrl:'/tmpl/ipv6-tpl.html',
        link:function(scope, elem, attr,ngModel){
            scope.fName = scope.formName || ''
            scope.iplist = [];
            let range = scope.subNet;
            let startIp = _IPAddr.parse(range.start).toNormalizedString().split(":");
            let endIp = _IPAddr.parse(range.end).toNormalizedString().split(":");
            let num = 0,flag = false;
            startIp.forEach((item,index)=>{
                let obj = {}
                if(item!=endIp[index]&&!flag){
                    num = index;
                    flag = true;
                }
                obj.value = item==endIp[index]?item:"";
                obj.range = index>num?"0000~ffff":`${item}~${endIp[index]}`;
                obj.placeholder = obj.range;
                obj.disabled = item==endIp[index]?true:false;
                scope.iplist.push(obj);
            })
            scope.ipchange = function(value){
                let curIp = scope.iplist.map(item=>item.value).join(":");
                if(_IPAddr.IPv6.isValid(curIp)){
                    scope.data.fixedIp = _IPAddr.parse(scope.iplist.map(item=>item.value).join(":")).toString();
                    console.log(scope.data.fixedIp);
                }
            }
            scope.interacted = function(field) {
                return scope.submitted || field.$dirty;
            };
        }
    }
}])
componentModule.directive("ipv4Tpl",[function(){
    return {
        restrict: "EA",
        scope: {
            data: '=',
            subNet: '=',
            form: '=',
            submitted:'=',
            formName:"="
        },
        templateUrl:'/tmpl/ipv4-tpl.html',
        link:function(scope, elem, attr,ngModel){
            scope.fName = scope.formName || ''
            scope.iplist = [];
            let range = scope.subNet;
            let startIp = range.start.split(".");
            let endIp = range.end.split(".");
            let num = 0,flag = false;
            startIp.forEach((item,index)=>{
                let obj = {}
                if(item!=endIp[index]&&!flag){
                    num = index;
                    flag = true;
                }
                obj.value = item==endIp[index]?item:"";
                obj.range = index>num?"0~255":`${item}~${endIp[index]}`;
                obj.placeholder = obj.range;
                obj.disabled = item==endIp[index]?true:false;
                scope.iplist.push(obj);
            })
            scope.ipchange = function(value){
                let curIp = scope.iplist.map(item=>item.value).join(".");
                if(_IP.isV4Format(curIp)){
                    scope.data.fixedIp = scope.iplist.map(item=>item.value).join(".");
                    console.log(scope.data.fixedIp);
                }
            }
            scope.interacted = function(field) {
                return scope.submitted || field.$dirty;
            };
        }
    }
}])




componentModule.directive("domainProjectSelect", ["$rootScope", "$location", "$http", "$route", "bobMesSrv", "checkQuotaSrv", "$translate", function(rootScope, $location, $http, $route, bobMesSrv, checkQuotaSrv, translate) {
    return {
        restrict: "EA",
        scope: {
            domainproject: '='
        },
        template: `<div>
                        <div class="control-group">
                            <label class="control-label">部门：</label>
                            <div class="controls">
                                <ui-select ng-model="singleRegion.depart.selected" ng-change="changedepart(singleRegion.depart.selected)">
                                    <ui-select-match placeholder="部门名称" title="{{$select.selected.domainName}}">{{$select.selected.disDomainName}}</ui-select-match>
                                    <ui-select-choices repeat=" depart in singleRegion.deparList | filter:{domainName:$select.search}">
                                        <div ng-bind-html="depart.disDomainName | highlight: $select.search" class="ui-select-item-ellips"  title="{{depart.disDomainName}}"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>
                        <div class="control-group">
                            <label class="control-label">项目：</label>
                            <div class="controls">
                                <ui-select ng-model="singleRegion.pro.selected" ng-change="changeproject(singleRegion.pro.selected)">
                                    <ui-select-match placeholder="项目名称" title="{{$select.selected.projectName}}">{{$select.selected.disProjectName}}</ui-select-match>
                                    <ui-select-choices repeat="pro in singleRegion.projectsList | filter:{projectName:$select.search}">
                                        <div ng-bind-html="pro.disProjectName | highlight: $select.search" class="ui-select-item-ellips"  title="{{pro.disProjectName}}"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div> 
                    </div>`,
        replace: true,
        link: function(scope, elem, attr) {
            var self = scope;

            function getProjectDomainData() {
                self.singleRegion = {
                    depart: { selected: "" },
                    deparList: [],
                    pro: { selected: "" },
                    projectsList: []
                };
                scope.domainproject = self.singleRegion;
                $http({
                    method: "GET",
                    url: "/awstack-user/v1/user/domains/projects"
                }).success(function(res) {
                    if (res) {
                        self.singleRegion.deparList = res;
                        for (var i = 0; i < self.singleRegion.deparList.length; i++) {
                            if (self.singleRegion.deparList[i].domainUid == 'default') {
                                self.singleRegion.deparList[i].disDomainName = translate.instant('aws.common.defaultDepar');
                            } else {
                                self.singleRegion.deparList[i].disDomainName = self.singleRegion.deparList[i].domainName;
                            }
                            if (self.singleRegion.deparList[i].projects && self.singleRegion.deparList[i].projects.length > 0) {
                                for (var j = 0; j < self.singleRegion.deparList[i].projects.length; j++) {
                                    if (self.singleRegion.deparList[i].projects[j].projectName == 'admin' && self.singleRegion.deparList[i].domainUid == 'default') {
                                        self.singleRegion.deparList[i].projects[j].disProjectName = translate.instant('aws.common.defaultProject');
                                    } else {
                                        self.singleRegion.deparList[i].projects[j].disProjectName = self.singleRegion.deparList[i].projects[j].projectName;
                                    }
                                }
                            }
                        }
                        self.singleRegion.deparList.map(function(item) {
                            if (item.domainName == localStorage.domainName) {
                                self.singleRegion.depart.selected = item;
                            }
                        });
                        if (!localStorage.domainName) {
                            self.singleRegion.depart.selected = res[0];
                        }
                    }
                    self.singleRegion.projectsList = self.singleRegion.depart.selected.projects
                    if (self.singleRegion.projectsList) {
                        self.singleRegion.pro.selected = self.singleRegion.projectsList[0];
                        self.singleRegion.projectsList.map(function(item) {
                            if (item.projectId == localStorage.projectUid) {
                                self.singleRegion.pro.selected = item;
                            }
                        });
                    }
                });
            }
            self.changedepart = function(m) {
                rootScope.depart_pro=m;
                // self.$emit('departHasChange');
                if (!m.projects || m.projects.length == 0) {
                    self.singleRegion.pro.selected = '';
                    self.singleRegion.deparList.forEach(item => {
                        if (item.domainUid == localStorage.defaultdomainUid) {
                            self.singleRegion.depart.selected = item
                            self.changedepart(item);
                        }
                    })
                    return;
                } else {
                    self.singleRegion.projectsList = m.projects;
                    // rootScope.project_pro = m.projects[0];
                    self.changeproject(m.projects[0]);
                }
            };

            self.changeproject = function(n) {
                self.singleRegion.pro.selected = n;
                rootScope.project_pro = n;
                self.$emit('projectHasChange');
            };
            getProjectDomainData()
        }
    }
}]);
componentModule.directive("serviceTip", ["$translate","$rootScope", function($translate,$rootScope) {
    return {
        restrict: "EA",
        scope: {
            service: "=",
            menus: "=",
            menuHref: "="
        },
        template: `
                <div class="service-tip" ng-show="obj[service]">
                    <span ng-show="_ADMIN">{{"aws.serviceTip.begin" | translate}}</span>
                    <span ng-show="!_ADMIN">{{"aws.serviceTip.beginManage" | translate}}</span>
                    <span ng-repeat="menu in menus track by $index">
                        <a  ng-show="_ADMIN&&$index == menus.length-1" ng-href="{{menuHref}}" >{{menu}}</a>
                        <span ng-show="!_ADMIN&&$index == menus.length-1">{{menu}}</span>
                        <span ng-show="$index != menus.length-1">{{menu}} > </span>
                    </span>
                    {{"aws.serviceTip." + service | translate}} 
                </div>
            `,
        replace: true,
        link: function(scope, elem, attr) {
            scope._ADMIN = $rootScope.ADMIN;
            scope.obj = {};
            // scope.obj[scope.service] = false;
            
            if (scope.$root.services[scope.service] == scope.service){
                scope.obj[scope.service] = false;
            }else{
                scope.obj[scope.service] = true;
            }
            //if(localStorage[scope.service + "Service"]) scope.obj[scope.service] = true;

        }
    }
}]);
componentModule.directive("cephSsd", [function() {
    return {
        restrict: "EA",
        scope: {
            bardata: "=",
            delfn: "&",
            showDel:"="//是否显示删除X按钮,默认不显示
        },
        templateUrl: "js/system/cephView/tmpl/ceph-ssd.html",
        replace: true,
        link: function(scope, elem, attr) {
            $(elem).find(".disk-box").on("click", function(e) {
                $(".disk-box").removeClass("checked");
                $(this).addClass("checked")
                e.stopPropagation();
                e.preventDefault();
                scope.osdBtn ? scope.osdBtn(scope.bardata) : ""
            });
            $(".ceph-grid").on("click", function(e) {
                $(".disk-box").removeClass("checked");
            });
            scope.showInfo = false;
            $(elem).hover(function(e) {
                //scope.showInfo = true;
                $(this).find(".disk-info").show();
                scope.$apply();
                let winWidth = $(window).width();
                let diskInfoOuterWidth = $(this).find(".disk-info").outerWidth();
                let diskBoxOuterWidth = $(this).find(".disk-box").outerWidth(true);
                let outerHeight = $(this).find(".disk-box").outerHeight(true);
                let offsetLeft = $(this).find(".disk-box").offset().left;
                let top = $(this).find(".disk-box").position().top + outerHeight;
                let left = $(this).find(".disk-box").position().left;
                if(winWidth - offsetLeft < diskInfoOuterWidth){
                    $(this).find(".disk-info").css("top",top+'px').css("left",(left - diskInfoOuterWidth + diskBoxOuterWidth) +'px');
                    $(this).find(".angles").css("right",'10px');
                }else{
                    $(this).find(".disk-info").css("top",top+'px').css("left",left+'px');
                }
            }, function(e) {
                //scope.showInfo = true;
                $(this).find(".disk-info").hide();
                scope.$apply();
            });
            elem.on('mouseup', function(e) {
                //scope.showInfo = true;
                $(this).find(".disk-info").show();
                scope.$apply();
            });
            elem.on('mousedown', function(e) {
                //scope.showInfo = false;
                $(this).find(".disk-info").hide();
                scope.$apply();
            });
            scope.del = function() {
                scope.delfn ? scope.delfn() : "";
            }
        }
    }
}]);
componentModule.directive("cephHdd", [function() {
    return {
        restrict: "EA",
        scope: {
            bardata: "=",
            osdBtn: "=", //选中后支持其他操作
            delfn: "&", //是否可以删除
            showDel:"="//是否显示删除X按钮,默认不显示
        },
        templateUrl: "js/system/cephView/tmpl/ceph-hdd.html",
        replace: true,
        link: function(scope, elem, attr) {
            $(elem).find(".disk-box").on("click", function(e) {
                $(".disk-box").removeClass("checked");
                $(this).addClass("checked")
                e.stopPropagation();
                e.preventDefault();
                scope.osdBtn ? scope.osdBtn(scope.bardata) : ""
            });
            $(".ceph-grid").on("click", function(e) {
                $(".disk-box").removeClass("checked");
            });
            $(elem).hover(function(e) {
                $(this).find(".disk-info").show();
                scope.$apply();
                let winWidth = $(window).width();
                let diskInfoOuterWidth = $(this).find(".disk-info").outerWidth();
                let diskBoxOuterWidth = $(this).find(".disk-box").outerWidth(true);
                let outerHeight = $(this).find(".disk-box").outerHeight(true);
                let offsetLeft = $(this).find(".disk-box").offset().left;
                let top = $(this).find(".disk-box").position().top + outerHeight;
                let left = $(this).find(".disk-box").position().left;
                if(winWidth - offsetLeft < diskInfoOuterWidth){
                    $(this).find(".disk-info").css("top",top+'px').css("left",(left - diskInfoOuterWidth + diskBoxOuterWidth) +'px');
                    $(this).find(".angles").css("right",'10px');
                }else{
                    $(this).find(".disk-info").css("top",top+'px').css("left",left+'px');
                }
            }, function(e) {
                $(this).find(".disk-info").hide();
                scope.$apply();
            });
            elem.on('mouseup', function(e) {
                $(this).find(".disk-info").show();
                scope.$apply();
            });
            elem.on('mousedown', function(e) {
                $(this).find(".disk-info").hide();
                scope.$apply();
            });
            scope.del = function() {
                scope.delfn ? scope.delfn() : "";
            }

        }
    }
}])

componentModule.directive("cephBox", [function() {
    return {
        restrict: "EA",
        scope: {
            cephlist: "=",
            osdBtn: "=",
            poolName: "=",
        },
        templateUrl: "js/system/cephView/tmpl/ceph-box.html",
        replace: true,
        link: function(scope, elem, attr) {
            
        }
    }
}])

componentModule.directive("iframeHeight", ["$timeout",function($timeout) {
    return {
        restrict: "A",
        link: function(scope, elem, attr) {
            function setIframe(){
                let height = $(window).innerHeight();
                let headerH = $("header").innerHeight();
                headerH = headerH?headerH:70;
                $(elem).css({'height':(height - headerH - 6)+"px"});
            }
            function windowResizeEvent(callback) {
                var firstFire = null;
                window.onresize = function () {
                    if(firstFire === null) {
                        firstFire = $timeout(function() {
                            callback();
                            firstFire = null;
                        }, 100);
                    }
                }
            }
            windowResizeEvent(setIframe);
            setIframe();
        }
    }
}])


componentModule.directive("cephdiskHover", [function() {
    return {
        restrict: "A",
        link: function(scope, elem, attr) {
            $(elem).hover(function(e) {
                let winWidth = $(window).width();
                let diskInfoOuterWidth = $(elem).find(".drag-disk-info").outerWidth();
                let diskBoxOuterWidth = $(this).outerWidth(true);
                let outerHeight = $(this).outerHeight(true);
                let offsetLeft = $(this).offset().left;
                let top = $(this).position().top + outerHeight;
                let left = $(this).position().left;
                if(winWidth - offsetLeft < diskInfoOuterWidth){
                    $(elem).find(".drag-disk-info").css("top",top+'px').css("left",(left - diskInfoOuterWidth + diskBoxOuterWidth) +'px');
                    $(elem).find(".angles").css({"left": 'unset',"right": "100px"});
                }else{
                    $(elem).find(".drag-disk-info").css("top",top+'px').css("left",left+'px');
                }
                $(elem).find(".drag-disk-info").show();
                scope.$apply();
            }, function(e) {
                $(elem).find(".drag-disk-info").hide();
                scope.$apply();
            });
        }
    }
}])

componentModule.directive("searchRefresh", ["NgTableParams", function(NgTableParams) {
    return {
        restrict: "EA",
        scope: {
            refreshFuc: "=", //刷新函数  必填
            tableContent: "=", //table名称 必填
            context: "=", //上下文    必填
            tableData: "=", //table数据 
            btnType: "@", //选择button type
            changeSelect: "=", //切换选项框回调函数
            statusList: "=" //状态搜索
        },
        template: `<div>
                    <div class="search-list" ng-show="statusList.length">
                        <ui-select ng-model="x.searchItem"  ng-change="applyGlobalSearch()">
                            <ui-select-match placeholder='请选择'>
                                {{$select.selected.name}}
                            </ui-select-match>
                            <ui-select-choices repeat="search in statusList | propsFilter:{name:$select.search}">
                                <div ng-bind-html="search.name | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                    <div class="search-box">
                        <form name="searchForm" novalidate>
                            <button class="btn" type="submit"><i class="icon-aw-search"></i></button>
                            <input type="text" class="form-control" ng-change="applyGlobalSearch()" autocomplete="off" maxlength="128" name="searchTerm" ng-model="globalSearchTerm" placeholder="{{\'aws.action.search\' |translate}}" />
                        </form>
                    </div>
                    <div type="button" class="btn btn-renovat" uib-popover="{{'aws.popover.btn.refresh'|translate}}" popover-enable="true" popover-trigger="mouseenter" popover-placement="top" ng-click="refresh()">
                        <i class="icon-aw-refresh"></i>
                    </div>
                </div>`,
        replace: true,
        link: function(scope, elem, attr) {
            elem.find(".icon-aw-refresh").bind('mouseenter mouseleave', function() {
                $(this).toggleClass('aw-spin-l');
              });
            var searchItem;
            scope.x = {};

            function globalSearchFunc(data) {
                data = data.filter(item => {
                    if (scope.globalSearchTerm) {
                        return item.searchTerm.indexOf(scope.globalSearchTerm) > -1
                    } else {
                        return true
                    }
                })
                return data;
            }

            function statusFunc(data) {
                searchItem = scope.x.searchItem;
                data = data.filter(item => {
                    if (searchItem && searchItem.key) {
                        return item[searchItem.key] && item[searchItem.key].indexOf(searchItem.value) > -1
                    } else {
                        return true
                    }
                })
                return data
            }
            scope.applyGlobalSearch = function() {
                var table = scope.context[scope.tableContent];
                var data = angular.copy(scope.tableData);
                if (!data) return;
                data = statusFunc(data);
                data = globalSearchFunc(data);
                table.data = data; //设置过滤后的数据
                table.settings().dataset = data;
                table.reload()
                if (scope.btnType == "radio") {
                    //scope.changeSelect(filterData[0] || {});  filterData没有定义
                }
            }
            scope.refresh = function() {
                scope.globalSearchTerm = "";
                if (scope.statusList && scope.statusList.length) {
                    scope.x.searchItem = scope.statusList[0];
                }
                scope.refreshFuc()
            }
            scope.$watch(function() {
                return sessionStorage[scope.context.titleName]
            }, function(val) {
                if (val) {
                    clearFunc()
                }

            }, true)

            function clearFunc() {
                if (scope.tableData) {
                    scope.globalSearchTerm = "";
                    if (scope.statusList && scope.statusList.length) {
                        scope.x.searchItem = scope.statusList[0];
                    }
                    scope.applyGlobalSearch()
                }
            }
            scope.$watch(function(){
                return scope.tableData
            },function(val){
                if(val){
                    scope.applyGlobalSearch()
                }

            },true)
        }
    }
}])
componentModule.directive("searchRefreshBackendRich", ["$timeout",function($timeout) {
    return {
        restrict: "EA",
        scope: {
            searchCb: "&", //搜索函数 必填
            context: "=", //上下文    必填
            statusList: "=",// 状态搜索列
            otherList: "=",// 其它功能搜索列
            typeList: "=",// 类型
            globalSearchTerm:"=",
            curSelect:"=",
            isShowRefresh:"="
        },
          // <div class="search-list" ng-show="statusList.length">
                    //     <ui-select ng-model="curSelect.searchItem"  ng-change="searchFunc('select',curSelect.searchItem, '')">
                    //         <ui-select-match placeholder='请选择' title="{{$select.selected.name}}">
                    //             {{$select.selected.name}}
                    //         </ui-select-match>
                    //         <ui-select-choices repeat="search in statusList | propsFilter:{name:$select.search}">
                    //             <div ng-bind-html="search.name | highlight: $select.search"></div>
                    //         </ui-select-choices>
                    //     </ui-select>
                    // </div>
        template: `<div>
                    <div>
                        <div class="search-list" ng-show="otherList.length">
                            <ui-select ng-model="curSelect.searchOtherItem"  ng-change="searchFunc('select','',curSelect.searchOtherItem)">
                                <ui-select-match placeholder='请选择' title="{{$select.selected.name}}">
                                    {{$select.selected.name}}
                                </ui-select-match>
                                <ui-select-choices repeat="search in otherList | propsFilter:{name:$select.search}">
                                    <div ng-bind-html="search.name | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                    
                        <div class="search-list" ng-show="typeList.length">
                            <ui-select ng-model="curSelect.searchTypeItem"   style="width: 110px">
                                <ui-select-match placeholder='请选择' title="{{$select.selected.name}}">
                                    {{$select.selected.name}}
                                </ui-select-match>
                                <ui-select-choices repeat="search in typeList | propsFilter:{name:$select.search}" ng-click="typeFunc(curSelect.searchTypeItem)">
                                    <div ng-bind-html="search.name | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                        <div class="search-list-div" ng-show="isShowDiv" ng-mouseleave="isShowDiv = false">
                            <ul>
                                <li ng-repeat="item in statusList" ng-click="searchValuesFunc(item)">
                                    <i class="{{item.icon}}"></i> <span style="padding-left:5px">{{item.name}}</span>
                                </li>
                            </ul>
                        </div>
                        <!-- 关键字查询 -->
                        <div class="search-box">
                            <form name="searchForm" novalidate>
                                <button class="btn" type="submit"><i class="icon-aw-search"></i></button>
                                <input type="text" class="form-control" ng-change="searchFunc('search')" autocomplete="off" maxlength="128" name="searchTerm" ng-model="globalSearchTerm" placeholder="{{\'aws.action.search\' |translate}}" />
                            </form>
                        </div>
                        <div ng-if="refreshBtn" type="button" class="btn btn-renovat" uib-popover="{{'aws.popover.btn.refresh'|translate}}" popover-enable="true" popover-trigger="mouseenter" popover-placement="top" ng-click="searchFunc('refresh')">
                            <i class="icon-aw-refresh"></i>
                        </div>
                    </div>
                </div>`,
        replace: true,
        link: function(scope, elem, attr) {
            // scope.searchInput = true;
            // 是否显示刷新按钮
            scope.refreshBtn = true;
            // 弹出框标志
            scope.isShowDiv = false;
            if(typeof scope.isShowRefresh == 'boolean'){
                scope.refreshBtn = scope.isShowRefresh
            }
            elem.find(".icon-aw-refresh").bind('mouseenter mouseleave', function() {
                $(this).toggleClass('aw-spin-l');
              });
            var searchItem;
            var searchOtherItem;
            var searchTypeItem;
            scope.curSelect = {
                searchItem:scope.statusList?scope.statusList[0]:null,
                searchOtherItem:scope.otherList?scope.otherList[0]:null,
                searchTypeItem:scope.typeList?scope.typeList[0]:null,
            };
            // if (scope.curSelect.searchTypeItem.value != "keywords"){
            //     scope.searchInput = false;
            // }
            scope.globalSearchTerm = "";
            scope.searchFunc = function(type,item, otherItem){
                var options = {
                    search:scope.globalSearchTerm,
                    select:item?item:scope.curSelect.searchItem,
                    selectOther:otherItem?otherItem:scope.curSelect.searchOtherItem,
                    typeSearch: scope.curSelect.searchTypeItem,// 查询类型
                    type:type//['select','search','refresh']
                }
                var time = 100;
                if(type=='search'){
                    time = 1000
                }
                $timeout.cancel(scope.sendSearch);
                scope.sendSearch = $timeout(function(){
                    scope.searchCb({options:options});
                },time);
            }
            scope.typeFunc = function(item){
                // 如果切换为关键字，则置空搜索框
                console.log(item);
                scope.isShowDiv = false;
                // 切换筛选key时，将不需要search刷选框隐藏，将自定义输入框显示。  如果不需要隐藏可以再 if (item.value == "keywords") 增加判断  例如： if (item.value == "keywords" || item.value == "vmName")
                // scope.searchInput = false;
                if (item.value == "keywords") {
                    scope.globalSearchTerm = ""; 
                    // scope.searchInput = true;
                }
                if (item.value == "status") scope.isShowDiv = true;
                // 如果为状态 弹出状态选择框
            }
            scope.searchValuesFunc = function(item){
                console.log("searchValuesFunc", item);
                // 关闭选择框
                scope.isShowDiv = false;
                // 搜索框中置为改key
                scope.globalSearchTerm = item.name;
                scope.searchFunc('select', item);
                
            }
        }
    }
}])

// 查询插件
componentModule.directive("searchRefreshBackend", ["$timeout",function($timeout) {
    return {
        restrict: "EA",
        scope: {
            searchCb: "&", //搜索函数 必填
            context: "=", //上下文    必填
            statusList: "=",
            globalSearchTerm:"=",
            curSelect:"=",
            isShowRefresh:"="
        },
        template: `<div>
                    <div class="search-list" ng-show="statusList.length">
                        <ui-select ng-model="curSelect.searchItem"  ng-change="searchFunc('select',curSelect.searchItem)">
                            <ui-select-match placeholder='请选择' title="{{$select.selected.name}}">
                                {{$select.selected.name}}
                            </ui-select-match>
                            <ui-select-choices repeat="search in statusList | propsFilter:{name:$select.search}">
                                <div ng-bind-html="search.name | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                    <div class="search-box">
                        <form name="searchForm" novalidate>
                            <button class="btn" type="submit"><i class="icon-aw-search"></i></button>
                            <input type="text" class="form-control" ng-change="searchFunc('search')" autocomplete="off" maxlength="128" name="searchTerm" ng-model="globalSearchTerm" placeholder="{{\'aws.action.search\' |translate}}" />
                        </form>
                    </div>
                    <div ng-if="refreshBtn" type="button" class="btn btn-renovat" uib-popover="{{'aws.popover.btn.refresh'|translate}}" popover-enable="true" popover-trigger="mouseenter" popover-placement="top" ng-click="searchFunc('refresh')">
                        <i class="icon-aw-refresh"></i>
                    </div>
                </div>`,
        replace: true,
        link: function(scope, elem, attr) {
            // 是否显示刷新按钮
            scope.refreshBtn = true;
            if(typeof scope.isShowRefresh == 'boolean'){
                scope.refreshBtn = scope.isShowRefresh
            }
            elem.find(".icon-aw-refresh").bind('mouseenter mouseleave', function() {
                $(this).toggleClass('aw-spin-l');
              });
            var searchItem;
            scope.curSelect = {
                searchItem:scope.statusList?scope.statusList[0]:null
            };
            scope.globalSearchTerm = "";
            scope.searchFunc = function(type,item){
                var options = {
                    search:scope.globalSearchTerm,
                    select:item?item:scope.curSelect.searchItem,
                    type:type//['select','search','refresh']
                }
                var time = 100;
                if(type=='search'){
                    time = 1000
                }
                $timeout.cancel(scope.sendSearch);
                scope.sendSearch = $timeout(function(){
                    scope.searchCb({options:options});
                },time);
            }

            // self.changeSelect = function(type,item){
            //     var data = {
            //         search:self.globalSearchTerm,
            //         select:item,
            //         type:'select'
            //     }
            //     self.search({option:data});
            // };

            // scope.applyGlobalSearch = function(type) {
            //     var data = {
            //         search:self.globalSearchTerm,
            //         select:self.x.searchItem,
            //         type:'search'
            //     }
            //     self.search({option:data});
            // };

            // scope.refresh = function(type) {
            //     var data = {
            //         search:self.globalSearchTerm,
            //         select:self.x.searchItem
            //     }
            //     self.search({option:data});
            // }
            
        }
    }
}])
componentModule.directive("servicePermit", [function() {
    return {
        restrict: "EA",
        scope: {
            serviceName: "=",
        },
        replace: true,
        link: function(scope, elem, attr) {
            var services = angular.fromJson(scope.serviceName);
            for (let i = 0; i < services.length; i++) {
                if (localStorage[services[i] + "Service"] != services[i]) {
                    $(elem).css('display', 'none');
                    break;
                } else {
                    $(elem).css('display', 'block')
                }
            }
            services.map(item => {

            })

        }
    }
}])


componentModule.directive("exportFile", [function() {
    return {
        restrict: "EA",
        scope: {
            export: "&",
        },
        replace:true,
        template:`
            <div type="button" uib-popover="{{'aws.popover.btn.export'|translate}}" popover-enable="true" popover-trigger="mouseenter" popover-placement="top" class="btn btn-renovat right-ports" ng-click="export()">
                <i class="icon-aw-dc"></i>
            </div>
        `,
        replace: true,
        link: function(scope, elem, attr) {
            

        }
    }
}])

componentModule.directive("sourcePrice", [function() {
    return {
        restrict: "EA",
        scope: true,
        replace: true,
        template: '<div class="des-info" ng-if = "billingActive">\
                <span>{{"aws.networks.price.totalPrice"|translate}}：</span>\
                <strong>¥{{priceHour}}</strong>\
                <span>{{"aws.networks.price.perHour"|translate}} × 1 =</span>\
                <strong>¥{{priceHourAbout}}</strong>\
                <span>{{"aws.networks.price.perHour"|translate}} ({{"aws.networks.price.count"|translate}} ¥{{priceMonth}} {{"aws.networks.price.perMonth"|translate}})</span>\
            </div>',
        link: function(scope, elem, attr) {

        }
    }
}])

// 普通按钮 licenceType = "btn"
// 下拉框 licenceType = "btn-group"
// 详情 licenceType = "btn-details" 
// ui-table licenceType = "btn-tab"
// 列表中测option栏 licenceType = "btn-list"

componentModule.directive("licenceType", function($rootScope) {
    return {
        restrict: "A",
        scope: {
            licenceType: "@",
            id: "@",
            licenceIf: "@"
        },
        link: function(scope, elem, attr) {
            let LicenceList = JSON.parse(localStorage.getItem("LicenseList"))
            if (attr.licenceIf) {
                if ($rootScope.ADMIN) {
                    if (attr.licenceIf == "!ADMIN") {
                        $(elem).remove()
                    }
                } else {
                    if (attr.licenceIf == "ADMIN") {
                        $(elem).remove()
                    }
                }
            }
            switch (attr.licenceType) {
                case "btn-group":
                    let count = 0
                    let liCount = $(elem).find("ul").find("li").length

                    if (!liCount) {
                        $(elem).remove()
                    }
                    break;
                case "btn":
                    if (LicenceList) {
                        for (let i = 0; i <= LicenceList.length - 1; i++) {
                            let licenceInfo = LicenceList[i]
                            if (attr.id == licenceInfo.keywords && !licenceInfo.selected) {
                                $(elem).remove()
                            }
                        }
                    }
                    break;
                case "stand":
                    if (LicenceList) {
                        for (let i = 0; i <= LicenceList.length - 1; i++) {
                            let licenceInfo = LicenceList[i]
                            if (attr.id == licenceInfo.keywords && !licenceInfo.selected) {
                                $(elem).remove()
                            }
                        }
                    }
                    break;
                case "btn-list":
                    let uid = attr.id.split("_")[0]
                    if (LicenceList) {
                        LicenceList.forEach(item => {
                            if (item.keywords == uid && !item.selected) {
                                $(elem).remove()
                            }
                        });
                    }
                    break;
            }
        }
    };
});
//下拉控制table栏的指令
componentModule.directive("setting", ["$translate", function(translate) {
    return {
        restrict: "E",
        scope: {
            titleData: "=",
            siteTitle: "=",
            searchTearm: "&",
            tableData: "=",
            placement: "@?"
        },
        template: `<div class='dropdown show-title' uib-popover="{{'aws.popover.btn.listSetting'|translate}}" popover-enable="true" popover-trigger="mouseenter" popover-placement="{{placement}}">
                    <button type='button' class='btn btn-renovat dropdown-toggle'>
                      <i class='icon-aw-list'></i>
                    </button>
                    <ul class='dropdown-menu'>
                        <li ng-repeat='title in titleData track by $index' ng-if="!title.isShow">
                          <div class='checkbox'>
                            <label>
                               <input type='checkbox' ng-model='title.value' ng-change='isShowTitle($index,title)' ng-disabled='title.disable'/>
                               <i class='iconfont'></i>
                               <span>{{'aws.'+title.name|translate}}</span>
                            </label>
                          </div>
                        </li>
                    </ul>
                  </div>`,
        link: function(scope, ele, attr, ctrl) {
            scope.placement = scope.placement ? scope.placement: "top";
            $(ele).find(".dropdown-toggle").on("click", function(e) {
                $(this).parent().toggleClass("open");
                e.stopPropagation();
                e.preventDefault();
            });
            $(ele).find(".dropdown-menu").on("click", function(e) {
                if (e.target.className == "checkbox" || $(e.target).closest(".checkbox").length > 0) { e.stopPropagation(); }
            });
            $("html").on("click", function(e) {
                $(ele).find(".dropdown").removeClass("open");
            });
            //第一次进来没有数据
            if (!sessionStorage[scope.siteTitle] && scope.siteTitle != undefined) {
                sessionStorage.setItem(scope.siteTitle, JSON.stringify(scope.titleData));
            }
            scope.isShowTitle = function(index, title) {
                var postData = {
                    tableData: scope.tableData,
                    titleData: scope.titleData
                };
                var currentSession = JSON.parse(sessionStorage[scope.siteTitle]);
                currentSession[index].value = title.value;
                sessionStorage.setItem(scope.siteTitle, JSON.stringify(currentSession));
                if (scope.tableData) {
                    scope.searchTearm({ obj: postData });
                }
            };
        }
    };
}]);

componentModule.directive("settingCol", ["$translate", function(translate) {
    return {
        restrict: "E",
        scope: {
            titleData: "=",
            siteTitle: "=",
            configSearch: "=",
            tableData: "="
        },
        template: `<div class='dropdown show-title' uib-popover="{{'aws.popover.btn.listSetting'|translate}}" popover-enable="true" popover-trigger="mouseenter" popover-placement="top">
                    <button type='button' class='btn btn-renovat dropdown-toggle'>
                      <i class='icon-aw-list'></i>
                    </button>
                    <ul class='dropdown-menu'>
                        <li ng-repeat='title in titleData track by $index' ng-if="title.title">
                          <div class='checkbox'>
                            <label>
                               <input type='checkbox' ng-model='title.show' ng-change='isShowTitle($index,title)' ng-disabled='title.disable'/>
                               <i class='iconfont'></i>
                               <span>{{title.title}}</span>
                            </label>
                          </div>
                        </li>
                    </ul>
                  </div>`,
        link: function(scope, ele, attr, ctrl) {
            $(ele).find(".dropdown-toggle").on("click", function(e) {
                $(this).parent().toggleClass("open");
                e.stopPropagation();
                e.preventDefault();
            });
            $(ele).find(".dropdown-menu").on("click", function(e) {
                if (e.target.className == "checkbox" || $(e.target).closest(".checkbox").length > 0) { e.stopPropagation(); }
            });
            $("html").on("click", function(e) {
                $(ele).find(".dropdown").removeClass("open");
            });
            //第一次进来没有数据
            if (!sessionStorage[scope.siteTitle] && scope.siteTitle != undefined) {
                sessionStorage.setItem(scope.siteTitle, JSON.stringify(scope.titleData));
            }
            scope.isShowTitle = function(index, title) {
                var postData = {
                    titleData: scope.titleData
                };
                var currentSession = JSON.parse(sessionStorage[scope.siteTitle]);
                currentSession[index].show = title.show;
                sessionStorage.setItem(scope.siteTitle, JSON.stringify(currentSession));
                (scope.configSearch && scope.tableData) ? scope.configSearch(scope.tableData, currentSession): ""
            };
        }
    };
}]);

componentModule.directive("loading", ["$http", "APILOADING", "$timeout", function($http, APILOADING, $timeout) {
    return {
        restrict: "EA",
        template: `<div class="global-loading" ng-class="{'shows':apiLoading}">
            <i class="loading-icon"></i>
            <span>{{'aws.instances.dataLoading' | translate}}</span>
        </div>`,
        scope: {},
        link: function(scope, ele, attr, ctrl) {
            var time = null;
            scope.$watch(function() {
                return $http.pendingRequests;
            }, function(ne, old) {
                if (ne.length > 0) {
                    if (time) { $timeout.cancel(time) };
                    time = $timeout(function() {
                        var pendingLists = angular.copy(ne);
                        var num = 0;
                        pendingLists.forEach(item => {
                            for (var i = 0; i < APILOADING.exclude.length; i++) {
                                var cur = new RegExp(APILOADING.exclude[i]);
                                if (cur.test(item.url)) {
                                    num += 1;
                                    break;
                                }
                            }
                        })
                        if (pendingLists.length > num) {
                            scope.apiLoading = true;
                            let _height = $(window).height();
                            $(ele).find(".global-loading").css('minHeight', _height);
                        } else {
                            scope.apiLoading = false;
                        }
                    }, 0)
                } else {
                    scope.apiLoading = false;
                }
            }, true)
            scope.$on("$routeChangeStart", function() {
                if ($http.pendingRequests.length > 0) {
                    $http.pendingRequests = [];
                }
            })

        }
    }
}])
componentModule.directive("pluginTips", ["$translate", function($translate) {
    return {
        restrict: "EA",
        scope: {
            type: "="
        },
        template: `<div class="" ng-if="pluginContext.show==1">
            <span>
            {{pluginContext.message}}
            </span>
        </div>`,
        link: function(scope, elem, attr) {
            scope.pluginContext = {
                type: attr.type,
                show: 2,
                message: $translate.instant("aws.indextran." + attr.type)
            }
            switch (scope.pluginContext.type) {
                case 'installK8s':
                    scope.pluginContext.show = localStorage.installK8s ? localStorage.installK8s : '2';
                    break;
                case 'installIronic':
                    scope.pluginContext.show = localStorage.installIronic ? localStorage.installIronic : '2';
                    break;
                default:
                    scope.pluginContext.show = 2;
                    break;
            }
        }
    }
}])
componentModule.directive("pluginCollapse", ['$timeout', function($timeout) {
    return {
        restrict: "EA",
        link: function(scope, elem, attr) {
            $(elem).find('.collapse-title').on("click", function() {
                $(this).siblings(".collapse-on").toggleClass("hide");
                $timeout(function() {
                    scope.$apply();
                }, 50);
            })
        }
    }
}])
componentModule.directive("btnRightInfo", ['$timeout', function($timeout) {
    return {
        restrict: "EA",
        link: function(scope, elem, attr) {
            let infoElem = $(elem).next().find('.btn-info');
            infoElem.hide();
            scope.$watch(function() {
                return $(elem).hasClass('disabled')
            }, function(value) {
                infoElem.hide();
                $(document).ready(function() {
                    if (value) {
                        //勾选个数为1
                        $(elem).hover(function() {
                            infoElem.show();
                            let infoHeight = (infoElem.height() + 20) / 2;
                            let parentHeight = $(elem).parent().height() / 2;
                            let topHeight = infoHeight - parentHeight;
                            //调整信息上下显示的高度
                            infoElem.css("top", -topHeight + "px")
                            //调整箭头上下显示的高度
                            infoElem.find('.arrow').css("top", topHeight + "px")
                        }, function() {
                            infoElem.hide();
                        });
                    } else {
                        $(elem).hover(function() {
                            infoElem.hide();
                        });
                    }
                });
            });
        }
    }
}])

componentModule.directive("tbdsWidth", ['$timeout','resize', function($timeout,resize) {
    return {
        restrict: "EA",
        link: function(scope, elem, attr) {
            function widthFunc(){
                var width =$(".page-inner").width()-240;
                var width1 =$(".page-inner").width();
                if(width1<1035){
                    width =$(".page-inner").width()-180;
                    $(".TBDS-header-right.bottom").css("height",150)
                }else if(width1<1210){
                    $(".TBDS-header-right.bottom").css("height",100)
                } else{
                    $(".TBDS-header-right.bottom").css("height",50)
                }
                $(".TBDS-header-right").css("width",width)
            }
            resize().call(function() {
                widthFunc();
            });
        }
    }
}])
componentModule.directive("stepControl", ['$timeout','resize', function($timeout,resize) {
    return {
        restrict: "E",
        scope:{
            step:"=",
            value:"=",
            min:"=",
            max:"=",
            changeValueFunc:"=",
            unit:"="
        },
        template: `<div class="step-control-component">
            <button ng-class="reduce" ng-click="reduceStep()">-</button>
            <input class="bind-value" ng-model="value" ng-change="changeInputValue(value)" min="min" max="max" ng-model-options="{ updateOn: 'blur' }" num-range>
            <span class="unit">{{unit}}</span>
            <button ng-class="increase" ng-click="increaseStep()">+</button>
        </div>`,
        link: function(scope, elem, attr,ctrl) {
             scope.reduceStep=function(){
                 if(!scope.value){
                    return;
                 }
                 if(scope.value-scope.step<scope.min){
                    scope.value=scope.min;
                    scope.changeValueFunc(scope.min);
                 }else{
                    scope.value=scope.value-scope.step;
                    scope.changeValueFunc(scope.value);
                 }
             }
             scope.increaseStep=function(){
                 if(!scope.value&&scope.value!==0){
                    return;
                 }
                 if(scope.value+scope.step>scope.max){
                    scope.value=scope.max;
                    scope.changeValueFunc(scope.max);
                 }else{
                    scope.value=scope.value+scope.step;
                    scope.changeValueFunc(scope.value);
                 }
             }
             scope.changeInputValue=function(){
                 if(scope.changeValueFunc){
                    scope.changeValueFunc(scope.value);
                 }
             }
        }
    }
}])
componentModule.directive("ipWrite", [function() {
    return {
        restrict: "ECMA",
        link: function(scope, elem, attr) {
            let inputs = $(elem).find("input");
            scope.keyDownEvent = function(e,i){
                if(e.keyCode == 37 && inputs[i-1]){
                    inputs[i-1].focus();
                    return false;
                }
                if(e.keyCode == 39 && inputs[i+1]){
                    inputs[i+1].focus();
                    return false;
                }
            }
            
            scope.keyUpEvent = function(e,i){
                if((e.keyCode >= 96 && e.keyCode <= 105) ||(e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 8 || e.keyCode == 46|| e.keyCode == 116 || e.keyCode == 16 || e.keyCode == 190 || e.keyCode == 110 || e.keyCode == 37 || e.keyCode == 39)
                {
                    if(e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57){
                        if(e.keyCode != 56)
                        return false;
                    }
                    if(e.keyCode == 37 || e.keyCode == 39){  //左右方向键
                        return false;
                    }
                    if(e.keyCode == 190 || e.keyCode == 110){  //输入的是点
                        // inputs[i+1].value = '';
                        inputs[i+1].focus();
                        return false;
                    }
                    if(e.keyCode == 8){
                        if(!inputs[i].value && inputs[i-1]) inputs[i-1].focus();
                        return false;
                    }
                    if(inputs[i].value.length >= 3 && inputs[i+1]){
                        inputs[i+1].focus();
                        return false;
                    }
                }else{
                    return false;
                }
            }
        }
    }
}]);
componentModule.directive("timeFilter", function($translate,$timeout,$rootScope) {
    return {
        restrict: "E",
        scope: {
            filterData: "=",
            collectCycle: "=?",
            collectCycleList: "=?",
            timeType: "=?",
            timePrecision: "=",
            query: "&",
            singleDatePicker:"@",
            isshow:'<'
        },
        templateUrl: "/tmpl/time-filter.html",
        replace: true,
        link: function(scope, elem, attr) {
            scope.isShowSelect = false;
            if(typeof scope.isshow == 'boolean'&& scope.isshow){
                scope.isShowSelect = true;
            }
            if (!attr.collectCycle) {
                scope.collectCycle = true;
            }
            if (attr.query) {
                scope.queryShow = true;
            }
            // 设置显示一个datePicker 默认是两个
            if(!attr.singleDatePicker){
                scope.singleDatePicker = false;
            }else{
                scope.singleDatePicker = true;
            }
            scope.filterData.definedTimeText = $translate.instant('aws.bill.chooseDate');
            if(!attr.timeType){
                scope.timeType = {
                    options: [{
                        "name": $translate.instant('aws.bill.timeType.realTime'),
                        "value": "30m"
                    }, {
                        "name": $translate.instant('aws.bill.timeType.sixHours'),
                        "value": "6h"
                    }, 
                    // {
                    //     "name": $translate.instant('aws.bill.timeType.twelveHours'),
                    //     "value": "12h"
                    // }, 
                    {
                        "name": $translate.instant('aws.bill.timeType.oneDay'),
                        "value": "1d"
                    }, 
                    {
                        "name": $translate.instant('aws.bill.timeType.threeDays'),
                        "value": "3d"
                    }, {
                        "name": $translate.instant('aws.bill.timeType.oneWeek'),
                        "value": "7d"
                    }]
                };
            }

            scope.inquire = function(){
                // const {definedTime, to, from} = scope.filterData
                // if(definedTime){
                //     const start = +new Date(to);
                //     const over = +new Date(from);
                //     const interval = start - over;
                //     const day = Math.round(interval / (1000*3600*24));
                //     if(day > 45){
                //         $rootScope.$broadcast("alert-error", '00500');
                //         return
                //     }
                // }
                scope.query()
            }

            scope.MainTypeValue = [
                { name: $translate.instant('aws.indextran.average'), value: "average" },
                { name: $translate.instant('aws.indextran.peak'), value: "peak" },
            ]
            // 默认值
            scope.defaultValue = scope.MainTypeValue[0].value;

            if(!attr.collectCycleList) {
                scope.collectCycleList = [
                    {name: $translate.instant("aws.monitor.physical.notRefresh"), value: null},
                    {name: $translate.instant("aws.monitor.physical.5sec"), value: 5},
                    {name: $translate.instant("aws.monitor.physical.10sec"), value: 10},
                    {name: $translate.instant("aws.monitor.physical.30sec"), value: 30},
                    {name: $translate.instant("aws.monitor.physical.1min"), value: 60}
                ];
            }
            // 默认值
            scope.filterData.precision = scope.collectCycleList[0].value;
            
            elem.find('#config-demo').daterangepicker({
                "language": 'zh-CN',
                "singleDatePicker":scope.singleDatePicker,
                "startDate": scope.singleDatePicker?moment():moment().subtract(1, "months"),//单日历下拉 开始日期设为当前日期
                "maxDate" : moment(),
                "timePicker": false,
                "timePicker24Hour": false,
                "linkedCalendars": false,
                "autoUpdateInput": false,
                "opens": "left",
                "locale": {
                    format: 'YYYY-MM-DD',
                    separator: ' 至 ',
                    applyLabel: "确定",
                    cancelLabel: "取消",
                    daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
                    monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
                }
            });

            elem.find('#config-demo').on('apply.daterangepicker',function(ev, picker) {
                let startTime = picker.startDate.format(picker.locale.format);
                let endTime = picker.endDate.format(picker.locale.format);
                let separator = picker.locale.separator;
                scope.filterData.from = startTime;
                scope.filterData.to = endTime;
                if(scope.timePrecision == "sec") {
                    scope.filterData.from = picker.startDate.format("YYYY-MM-DD 00:00:00");
                    scope.filterData.to = picker.endDate.format("YYYY-MM-DD 23:59:59");
                }
                if(!picker.startDate || !picker.endDate) {
                    elem.find(".time-input").val('');
                }else {
                    // 判断单个的datepicker
                    if(scope.singleDatePicker){
                        var definedTimeText = startTime;
                    }else{
                        var definedTimeText = startTime + separator + endTime;
                    }
                    elem.find(".time-input").val(definedTimeText);
                    scope.filterData.definedTime = true;
                    scope.filterData.definedTimeText = definedTimeText;
                }
                scope.$apply();
            });
            
            scope.timeSelectTab = function(step) {
                //时间筛选的高亮
                scope.filterData.timeStep = step;
                if(step == "defined"){
                    scope.filterData.queryType = null;
                }else{
                    scope.filterData.definedTime = false;
                    scope.filterData.definedTimeText = $translate.instant('aws.bill.chooseDate');
                    scope.filterData.from = null;
                    scope.filterData.to = null;
                    scope.filterData.queryType = step;
                }
                if(step == "30m"){
                    scope.filterData.precision = null;
                }else{
                    $timeout.cancel(scope.timer);
                }
            };


            scope.$emit('main_type', scope.defaultValue)
            scope.changeMainType = function(value){
                scope.$emit('main_type', value)
            }

            scope.precisionChange = async function(value){
                if(value){
                    setTimer(value);
                }else{
                    $timeout.cancel(scope.timer);
                }
            }

            async function setTimer(value){
                $timeout.cancel(scope.timer);
                const result = await scope.query();
                scope.timer = $timeout(function() {
                    setTimer(value);
                }, value*1000);
            }

            scope.$on('$destroy', function () {
                $timeout.cancel(scope.timer);
            });

            scope.$on('clearTimeout', function (e,data) {
                if(scope.timer){
                    data.precision = null;
                    $timeout.cancel(scope.timer);
                }
            });

            if(scope.filterData && scope.timeType){
                scope.filterData.timeStep = scope.timeType.options[0].value;
                scope.timeSelectTab(scope.filterData.timeStep);
            }
        }
    }
});

componentModule.directive("unloadSwitch",["$translate",function($translate){
    return {
        restrict: "EA",
        scope: {
            haSwitch:'=',
            switchText: '=',
            instanceStatus: '='
        },
        templateUrl:'/tmpl/unload-switch.html',
        link:function(scope, elem, attr,ngModel){
            scope.switchChange = function(value){
                scope.haSwitch = value;
                scope.switchText = switchText(scope.haSwitch);
            }
            function switchText(value){
                let haSwitch = value ? $translate.instant('aws.localDisk.open') : $translate.instant('aws.localDisk.close');
                return haSwitch;
            }
        }
    }
}])

componentModule.directive('labelSelect', ['labelMSrv',function(labelMSrv){
    return {
        restrict: 'E',
        scope:{
            formobj:'=',
            labelobj:'='
        },
        templateUrl:'/tmpl/labelTemplabel.html',
        link:function(scope, elem, attr, ngModel){
            const self = scope
            label()
            self.$on('getLabelList',function(){
                label()
            })
            function label(){
                self.labelArr = [{ value: '', key:1, isShow: false }];
                self.labelobj.labelList = [];
                self.labelobj.labelArr = self.labelArr;
                const config = {
                    pageNum: 1,
                    pageSize: 1000,
                    patten:''
                }
                labelMSrv.getList(config, true).then((res)=>{
                    if(res.data){
                        self.labelobj.labelList = res.data.map(item=>{
                            const { name: value ,id} = item
                            return {value, id}
                        })
                    }
                })
                
                let globalIndex = null
                let dom = null;
                // 聚焦负责开启
                self.ngFocus = function(e,index) {
                    globalIndex = index;
                    self.labelArr[index].isShow = true;
                }
                
                self.ngBlur = function(e, index) {
                    if(!dom){
                        self.labelArr[index].isShow = false;
                    }
                }

                self.getLiValue = function(activeValue, name, key) {
                    self.labelArr[globalIndex].value = activeValue.value;
                    self.labelArr[globalIndex].isShow = false;
                    
                    const repeatList = self.labelArr.filter(item => activeValue.value != '' && item.value == activeValue.value)
                    const label = self.formobj[`${name}${key}`]
                    if(repeatList.length > 1){
                        label.$dirty = true;
                        label.$invalid = true;
                        label.$error.isrepeat = true;
                    } else {
                        label.$dirty = false;
                        label.$invalid = false;
                        label.$error.isrepeat = false;
                    }
                }

                $('html').on('mousemove', function(e){
                    dom = e.target.getAttribute('key');
                })

                bindWatch(self.labelArr, function() {
                    self.labelArr.forEach((item, index)=>{
                        item.Watchs = bindWatch(item, function(newvalue, oldvalue) {
                            // 最后一个文本框输入
                            if((self.labelArr.length - 1 == index && newvalue != '' && oldvalue == '')) {
                                if(self.labelArr.length == 10){
                                    return
                                }
                                addItem()
                            } else if(self.labelArr.length > 1 && newvalue == '' && oldvalue != ''){
                                // 输入框为空时
                                self.labelArr.splice(index, 1);
                                if(self.labelArr[self.labelArr.length-1].value != ''){
                                    addItem()
                                }
                            }
                            function addItem(){
                                self.labelArr.push({ 
                                    value: '',
                                    key: self.labelArr[self.labelArr.length-1].key + 1,
                                    isShow: false
                                });
                            }
                        }, 'value', item.Watchs)
                    })
                }, 'length');
                // 绑定观察者
                function bindWatch(obj, callback,prop, Watchs){
                    // 移除上一次观察
                    typeof Watchs == 'function' && Watchs()
                    return self.$watch(()=> obj[prop], callback)
                }
                // 注销事件
                self.$on("$destroy", function() {
                    // 移除文档点击事件
                    $('html').on('mousemove', null)
                })
                self.restrictLength = function(value,length){
                    if(value && value.length > length){
                        return value.slice(0, length) + '...'
                    }
                    return value
                }
            }
        }
    }
}])

componentModule.directive('sliderModule',['$timeout',function($timeout){
    return {
        restrict: "E",
        scope:{
            sideparam:"=",
            sliderprogress:"=",
            // checkjxslider:"=",
        },
        templateUrl:'/tmpl/slider.html',
        link: function(scope, element) {
            scope.showcountnumber = scope.sideparam.showcountnumber==false||scope.sideparam.showcountnumber?scope.sideparam.showcountnumber:true;         //判断是否显示数据输入框
            scope.checkjxslider = true;                         //输入框内的值是否符合要求判断
            scope.reqNum = true;                                //判断是否为空字段
            scope.trueNum = true;                               //判断是否显示输入错误字段
            scope.sliderprogress = scope.sliderprogress || scope.sideparam.start;               
            scope.showTip = false;                              //判断是否显示数字提示框字段
            var lineX = element.find(".jx-value-line").offset().left;           //进度条距离左边的距离
            var lineLength = element.find(".jx-value-line").width();          //进度条的长度
            var ballLength = element.find(".jx-ball").width();                //进度球的宽度
            
            var everyLength = lineLength/(scope.sideparam.end - scope.sideparam.start);                                 //单位1的长度
            var stepCount = Math.floor((scope.sideparam.end - scope.sideparam.start)/scope.sideparam.step);                 //step单位长度的总个数，向下取整    
            var stepLength =  (lineLength-everyLength*((scope.sideparam.end - scope.sideparam.start)%scope.sideparam.step))/stepCount;       //step单位的长度，总长度减去不足step单位长度的 
            
            //显示坐标轴
            $timeout(()=>{                                  
                scope.sideparam.coorList.forEach((coor,index)=>{
                    element.find(".jx-coor a").eq(index).css({  
                        "left":((coor-scope.sideparam.start)*everyLength-1)+"px",  
                    });
                    element.find(".jx-coor span").eq(index).css({  
                        // "left":((coor-scope.sideparam.start)*everyLength-6)+"px",  
                        "left":((coor-scope.sideparam.start)*everyLength-10)+"px",  
                    });
                });
            },0);

            element.find(".jx-ball").bind("mousedown",start);               //鼠标点击函数        
            function start(event){  
                if(event.button==0){//判断是否点击鼠标左键  
                    scope.showTip = true;
                    element.find(".jx-tip-box").css({
                        // "left":(element.find(".jx-tip-box").width()/2*(-1)+6)+"px"
                        "left":(element.find(".jx-tip-box").width()/2*(-1)+10)+"px"
                    });
                    $(document).bind("mousemove",move);                     //鼠标移动函数
                    $(document).bind("mouseup",stop);                       //鼠标放开函数
                }  
                return false;  
            }   
            function move(event){
                scope.reqNum = true;
                scope.trueNum = true;
                var mouseLength = event.clientX - lineX;                //鼠标拖拽的距离
                if(mouseLength>=0&&mouseLength<=lineLength){            //在坐标轴范围内移动，如果在起始点，输入值等于start的值，在终点，输入值end的值，在中间的
                    if(Math.floor(mouseLength/stepLength)==stepCount&&mouseLength%stepLength>0){
                        element.find(".jx-ball").css({  
                            // "left":(lineLength-6)+"px",  
                            "left":(lineLength-10)+"px",  
                        });
                        element.find(".jx-value-choice").css({
                            // "width":(lineLength-6)+"px",
                            "width":(lineLength-10)+"px",
                        });
                        if(scope.sideparam&&(scope.sideparam.start||scope.sideparam.start==0)&&scope.sideparam.end){
                            scope.sliderprogress = scope.sideparam.end;
                        };
                    }else{
                        element.find(".jx-value-choice").css({
                            "width":(Math.round(mouseLength/stepLength)*stepLength-ballLength/2)+"px",
                        });
                        if(scope.sideparam&&(scope.sideparam.start||scope.sideparam.start==0)&&scope.sideparam.end){
                            scope.sliderprogress = Math.round(mouseLength/stepLength)*scope.sideparam.step+scope.sideparam.start;
                        }
                        element.find(".jx-ball").css({  
                            "left":(Math.round(mouseLength/stepLength)*stepLength-ballLength/2)+"px",  
                        });
                    }
                    scope.sliderprogress = String(scope.sliderprogress).indexOf(".")>-1?scope.sliderprogress.toFixed(1):scope.sliderprogress;
                    scope.showTip = true;
                    element.find(".jx-rectangle a").html(scope.sliderprogress);
                    $timeout(function () {
                        element.find(".jx-tip-box").css({
                            // "left":(element.find(".jx-tip-box").width()/2*(-1)+6)+"px"
                            "left":(element.find(".jx-tip-box").width()/2*(-1)+10)+"px"
                        });
                    },0);
                    
                    scope.$apply();
                    return false;
                }
            }  
            function stop(){  
                $(document).unbind("mousemove",move);  
                $(document).unbind("mouseup",stop);  
                scope.showTip = false;
                scope.$apply();
            }  

            // $(document).on("click",".jx-value-line",move);
            // 点击事件
            element.find(".jx-value-line").click(move);
            
            // 鼠标滑到滑块上显示数值
            element.find(".jx-ball").hover(function(){
                scope.showTip = true;
                element.find(".jx-rectangle a").html(scope.sliderprogress);
                scope.$apply();
                element.find(".jx-tip-box").css({
                    // "left":(element.find(".jx-tip-box").width()/2*(-1)+6)+"px"
                    "left":(element.find(".jx-tip-box").width()/2*(-1)+10)+"px"
                });
            },function(){
                scope.showTip = false;
                scope.$apply();
            });
            // 数值输入框输数值手动修改事件，校验和滑块移动的判断
            scope.changeValue = ()=>{
                if(scope.sliderprogress){
                    scope.reqNum = true;
                    if(scope.sliderprogress==scope.sideparam.start){
                        scope.trueNum = true;
                        element.find(".jx-value-choice").css({
                            "width":(0)+"px",
                        });
                        element.find(".jx-ball").css({  
                            // "left":(-6)+"px",  
                            "left":(-10)+"px",  
                        });
                        element.find(".jx-rectangle a").html(scope.sliderprogress);
                    }else if(scope.sliderprogress==scope.sideparam.end){
                        scope.trueNum = true;
                        element.find(".jx-value-choice").css({
                            "width":(lineLength)+"px",
                        });
                        element.find(".jx-ball").css({  
                            // "left":(lineLength-6)+"px",  
                            "left":(lineLength-10)+"px",  
                        });
                        element.find(".jx-rectangle a").html(scope.sliderprogress);
                    }else if(scope.sliderprogress>scope.sideparam.start&&scope.sliderprogress<scope.sideparam.end){
                        scope.trueNum = true;
                        if(Math.round((scope.sliderprogress-scope.sideparam.start)/scope.sideparam.step)*scope.sideparam.step+scope.sideparam.start>scope.sideparam.end){
                            element.find(".jx-value-choice").css({
                                "width":(lineLength)+"px",
                            });
                            element.find(".jx-ball").css({  
                                // "left":(lineLength-6)+"px",  
                                "left":(lineLength-10)+"px",  
                            });
                            scope.sliderprogress = scope.sideparam.end;
                        }else{
                            element.find(".jx-value-choice").css({
                                "width":(Math.round((scope.sliderprogress-scope.sideparam.start)/scope.sideparam.step)*stepLength)+"px",
                            });
                            element.find(".jx-ball").css({  
                                // "left":(Math.round((scope.sliderprogress-scope.sideparam.start)/scope.sideparam.step)*stepLength-6)+"px",  
                                "left":(Math.round((scope.sliderprogress-scope.sideparam.start)/scope.sideparam.step)*stepLength-10)+"px",  
                            });
                            scope.sliderprogress = Math.round((scope.sliderprogress-scope.sideparam.start)/scope.sideparam.step)*scope.sideparam.step+scope.sideparam.start;
                        }
                        console.log(scope.sliderprogress)

                        element.find(".jx-rectangle a").html(scope.sliderprogress);

                    }else{
                        scope.trueNum = false;
                    }
                }else{
                    scope.reqNum = false;
                    scope.trueNum = true;
                }
                scope.checkjxslider = scope.reqNum && scope.trueNum;
            };

            scope.changeValue()

        }
    }
}])

componentModule.directive('shiftInHint',["$timeout",function($timeout){
    return {
        restrict: "E",
        scope:{
            openObj:"=",
            query:"=",
            openfn:"="
        },
        templateUrl:'/tmpl/shift-in-hint.html',
        link: function(scope) {
            const self = scope
            // 是否显示弹窗
            self.showAlert = false 
            // 记录开关初始状态
            self.oldValue = scope.openObj.isOpen || false
            self.obj = {
                // 得到默认状态
                isOpen : self.oldValue
            }

            // 弹窗切换
            self.toggle = function(){
                // 开关是否开启
                if(self.obj.isOpen){
                    // 开关初始状态为开启并且开关值为开启时进入弹窗显示
                    if(self.oldValue && self.obj.isOpen){
                        self.showAlert = !self.showAlert
                    } else if(!self.oldValue && self.obj.isOpen){
                        self.openfn()
                    }
                } 
            }

            // 弹窗-确认关闭
            self.confirmTheShutDown = function(){
                // self.query()
                self.obj.isOpen = false
                self.openObj.isOpen = self.obj.isOpen
                if(self.oldValue && !self.obj.isOpen){
                    self.openObj.isOpenSelect = true
                }
                // 下拉框状态未改变时进入
                if(!self.openObj.selectIsChange){
                    // 开关状态改变时保存按钮状态
                    if(self.oldValue != self.obj.isOpen){
                        self.openObj.isInitiated = false
                    }else{
                        self.openObj.isInitiated = true
                    }
                }
                
                self.close()
            }
            // 关闭弹窗
            self.close = function(){
                self.showAlert = false
            }

            self.NgMouseleave = function(){
                $timeout(()=>{
                    self.close()
                }, 300)
            }

            /**
             * isInitiated: 改变保存按钮状态
             * isOpenSelect: 改变下拉框状态
             */
            self.changeFn = function(){
                const status = self.obj.isOpen;
                self.openObj.isInitiated = !status;
                self.openObj.isOpenSelect = !status
                if(!self.openObj.selectIsChange){
                    if(self.oldValue != self.obj.isOpen){
                        self.openObj.isInitiated = false
                    }else{
                        self.openObj.isInitiated = true
                    }
                }
                assignment()
            }

            // 改变原始状态
            function assignment(){
                self.openObj.isOpen = self.obj.isOpen
            }
        }
    }
}])

componentModule.directive("titleTpl",["$translate",function($translate){
    return {
        restrict: "E",
        scope: {
            data:'=',// 字符
            length: '='// 字符的长度
        },
        templateUrl:'/tmpl/title-tpl.html',
        link:function(scope, elem, attr,ngModel){
            let self = scope;
            self.text = '';
            function splitStr(value,length){
                if(value.length > length){
                    return value.substring(0, length)+'...'
                }
                return value
            }
            self.text = splitStr(self.data,self.length);
        }
    }
}])

/**
 *  form: form表单name,
    submitted: form校验,
    formname: 自定义IP4/6名称,
    dataset: 下拉框数组,
    defaultname:下拉框默认值
    objdata:指定IP对象
    styleConfig: 下拉框样式配置
 */
componentModule.directive("networkSelect", ["$timeout", function ($timeout) {
    return {
        restrict: "E",
        scope: {
            form:"=",
            submitted:"=",
            formname:"=",
            dataset: "=",
            defaultname:"=",
            objdata:"=",
            styleConfig:"="
        },
        templateUrl: '/tmpl/network_select.html',
        link: function (scope) {
            const self = scope;
            self.appointIP = false;
            self.loading = true
            self.subnet = {}
            // 网络切换
            self.changeAppointIPValue = function (value) {
                self.defaultname = value;
                networkToggle(self.defaultname, self.subnet)
            }

            // IP指定
            self.checkboxChangeOuter = function (value) {
                self.appointIP = value;
                assignIP(self.subnet, self.defaultname)
            }

            // 网络切换
            function networkToggle(listObj, list) {
                self.loading = false;
                const keyObj = listObj.allocationPools
                list.start = keyObj ? keyObj[0].start : "";
                list.end = keyObj ? keyObj[keyObj.length - 1].end : "";
                $timeout(()=>{
                    self.loading = true;
                }, 0)
            }

            // 指定IP
            function assignIP(List, listKey) {
                const keyObj = listKey.allocationPools
                List.start = keyObj ? keyObj[0].start : "";
                List.end = keyObj ? keyObj[keyObj.length - 1].end : "";
            }
        }
    }
}])

/**
 *  
    show Boolean 消息框的显示和隐藏
    message:  Strring 消息框中的提示语,
    top: Number 消息框定位上,
    right: Number 消息框定位右, 
    left: Number 消息框定位左, 
    bottom: Number 消息框定位下, 
    showTopArrow: 上三角的显示和隐藏  true/false  根据显示需要显示和隐藏
    showBottomArrow： 下三角的显示和隐藏 true/false  根据显示需要显示和隐藏
    arrowTop:  Number 三角定位上,       
    arrowRight:  Number 三角定位右,
    arrowLeft:  Number 三角定位左,
    arrowBottom:  Number 三角定位下,
    func：Function  调取接口的函数
    消息框的位置例子 top:30 right:0
    上右方三角位置例子  top:-16 right:20
    下左三角位置例子  top:153 left:20
*/
componentModule.directive("confirmMsg",["$translate",function($translate){
    return {
        restrict: "E",
        scope: {
            show:"=",
            message:"=",
            top:"=",
            right:"=",
            left:"=",
            bottom:"=",
            showTopArrow:"=",
            showBottomArrow:"=",
            arrowTop:"=",
            arrowRight:"=",
            arrowLeft:"=",
            arrowBottom:"=",
            func:"&"
        },
        templateUrl:'/tmpl/confirm-msg.html',
        link:function(scope, elem, attr,ngModel){
            let self = scope;
            // 获取当前消息提示框的dom节点
            let firstChild = elem[0].firstChild;
            dealPosition(firstChild,self.top,self.right,self.left,self.bottom);
            // 上三角显示时
            if(self.showTopArrow){
                // 获取上三角气泡的dom节点
                let firstChildTopArrow = firstChild.children[2];
                dealPosition(firstChildTopArrow,self.arrowTop,self.arrowRight,self.arrowLeft,self.arrowBottom);
            } 
            // 下三角显示时
            if(self.showBottomArrow){
                // 获取下三角气泡的dom节点
                let firstChildBottmArrow = firstChild.children[3];
                dealPosition(firstChildBottmArrow,self.arrowTop,self.arrowRight,self.arrowLeft,self.arrowBottom);
            }
            // 确定
            self.confirm = function() {
                self.func();
                self.show = false;
            }
            // 关闭
            self.close = function() {
                self.show = false;
            }
            // 处理dom节点定位的位置
            function dealPosition(ele,top,right,left,bottom) {
                ele.style.top = top + 'px';// 设置定位上
                ele.style.right = right + 'px';// 设置定位右
                ele.style.left = left + 'px';// 设置定位左
                ele.style.bottom = bottom + 'px';// 设置定位下
            }
        }
    }
}])

/**
 *   
    icon String  icon小图标
*/
componentModule.directive("iconChange", ["$translate","$route",function($translate,$route) {
    return {
        restrict: "E",
        scope: {
            icon: "="
        },
        templateUrl: "/tmpl/icon-change.html",
        replace: true,
        link: function(scope, elem, attr) {
            var self = scope;
            self.languageList = [
                {
                    text: "中文",
                    value: "zh"
                },
                {
                    text: "English",
                    value: "en"
                }
            ];
            self.activeValue = localStorage.language ? localStorage.language : self.languageList[0].value;
            $translate.use(self.activeValue);
            self.change = function(data){
                sessionStorage.clear()
                localStorage.setItem('language', data.value);
                // $translate.use(data.value);
                window.location.reload();
            } 
        }
    }
}])

componentModule.directive("notServiceTip", ["$translate","$rootScope", function($translate,$rootScope) {
    return {
        restrict: "EA",
        scope: {
            resourceName:"=",
            moduleName: "=",
            menus: "=",
            menuHref: "="
        },
        template: `
                <div>
                    <span ng-show="_ADMIN">{{"aws.serviceTip.used" | translate}}{{"aws.serviceTip." + resourceName | translate}}{{"aws.serviceTip.pleaseOpen" | translate}}</span>
                    <span ng-show="!_ADMIN">{{"aws.serviceTip.used" | translate}}{{"aws.serviceTip." + resourceName | translate}}{{"aws.serviceTip.concatManage" | translate}}</span>
                    <span ng-repeat="menu in menus track by $index">
                        <a  ng-show="_ADMIN&&$index == menus.length-1" ng-href="{{menuHref}}" >{{menu}}</a>
                        <span ng-show="!_ADMIN&&$index == menus.length-1">{{menu}}</span>
                        <span ng-show="$index != menus.length-1">{{menu}} > </span>
                    </span>
                    {{"aws.serviceTip.interfaceAdd" | translate}}{{"aws.serviceTip." + moduleName | translate}}{{"aws.serviceTip.module" | translate}}
                </div>
            `,
        replace: true,
        link: function(scope, elem, attr) {
            scope._ADMIN = $rootScope.ADMIN;
        }
    }
}]);
    