syncStrategyCtrl.$inject = ['$scope','$uibModal',"initConfig",'serviceConfigSrv','$translate','$rootScope','$timeout','$http','NgTableParams','$route']
function syncStrategyCtrl($scope,$uibModal,initConfig,serviceConfigSrv,$translate,$rootScope,$timeout,$http,NgTableParams,route) {

    var self = $scope;

    // 全量同步间隔时间
    self.lowId = ""
    self.fullSynInterval= ""
    self.fullSynIntervals =[
        {title:$translate.instant("aws.backupService.serviceConfig.time.tenMinute"),data:10},
        {title:$translate.instant("aws.backupService.serviceConfig.time.twentyMinute"),data:20},
        {title:$translate.instant("aws.backupService.serviceConfig.time.thirtyMinute"),data:30},
    ]
    // 备份任务间隔时间
    self.highId = ""
    self.backupTaskInterva= ""
    self.backupTaskIntervas = [
        {title: $translate.instant("aws.backupService.serviceConfig.time.oneMinute"),data:1},
        {title:$translate.instant("aws.backupService.serviceConfig.time.fiveMinute"),data:5},
        {title:$translate.instant("aws.backupService.serviceConfig.time.tenMinute"),data:10},
    ]

    self.lowFrequencySyncBackupJobName = ""
    self.lowFrequencySyncBackupJobGroup =  ""
    self.highFrequencySyncBackupJobName  = ""
    self.highFrequencySyncBackupJobGroup = ""

    // 数据同步策略
    self.syncStrategyWay = function(param) {
        var lowData = {
            "scheduleJobId": self.lowId,
            "jobName":self.lowFrequencySyncBackupJobName, 
            "jobGroup":self.lowFrequencySyncBackupJobGroup,
            "cron": param.fullSynInterval.$viewValue.data
        }
        syncLow(lowData)
        var highData = {
            "scheduleJobId": self.highId,
            "jobName":self.highFrequencySyncBackupJobName, 
            "jobGroup":self.highFrequencySyncBackupJobGroup,
            "cron": param.backupTaskInterva.$viewValue.data
        }
        syncHigh(highData)
        
    }
    function syncLow(data) {
        serviceConfigSrv.lowFrequencySync(data).then(function (result) {
            if(result && result.status == 0 && result.data!=null){
                self.$dismiss();
                initConfig()
            }else{
                self.$dismiss();
                initConfig()
            }
        })
    }
    function syncHigh(data) {
        serviceConfigSrv.highFrequencySync(data).then(function (result) {
            if(result && result.status == 0 && result.data!=null){
                self.$dismiss();
                initConfig()
            }else{
                self.$dismiss();
                initConfig()
            }
        })
    }
    //初始化选项
    function initLowData() {
        var lowData = {
            "name": "lowFrequencySyncBackupJobName_" + self.$parent.backupData.id,
            "group":"lowFrequencySyncBackupJobGroup_" + self.$parent.backupData.id
        }
        serviceConfigSrv.detailFrequencySync(lowData).then(function (result) {
            if(result && result.status == 0 && result.data.length != 0){
                self.lowId = result.data[0].scheduleJobId
                self.fullSynInterval=  {title:result.data[0].cron + $translate.instant("aws.backupService.serviceConfig.time.minute"),data:result.data[0].cron}
                self.lowFrequencySyncBackupJobName = result.data[0].jobName
                self.lowFrequencySyncBackupJobGroup = result.data[0].jobGroup
            }
        })
    }
    function initHighData() {
        var highData = {
            "name": "highFrequencySyncBackupJobName_" + self.$parent.backupData.id,
            "group":"highFrequencySyncBackupJobGroup_" + self.$parent.backupData.id
        }
        serviceConfigSrv.detailFrequencySync(highData).then(function (result) {
            if(result && result.status == 0 && result.data.length != 0){
                self.highId = result.data[0].scheduleJobId
                self.backupTaskInterva = {title:result.data[0].cron + $translate.instant("aws.backupService.serviceConfig.time.minute"),data:result.data[0].cron}
                self.highFrequencySyncBackupJobName = result.data[0].jobName
                self.highFrequencySyncBackupJobGroup = result.data[0].jobGroup
            }
        })
    }
    initLowData()
    initHighData()
}
export{syncStrategyCtrl};