storageLocalTpl.$inject = [];
function storageLocalTpl() {
    return {
        restrict:"E",
        replace:true,
        scope:{
            storageUseList:"=",
            initStorageTable:"="
        },
        templateUrl:"js/system/storageManagement/tmpl/storage-local-tpl.html",
        link: function(self, elem, attrs, $ngModel) {

        },
        controller:function($scope,storageInitList,$translate,storageManagementSrv,TableCom,utilTool,$timeout){
            let self = $scope;
            self.nodeList = [];
            let storageInitInstan = storageInitList.init();
            self.submitValid = false;
            self.storageFirm = "local_disk";
            self.modalTitle = $translate.instant("aws.system.storagement.addTitle");
            self.storageLocalInit = {
                deviceList:angular.copy(storageInitInstan.storageDeviceList.object),
                current:'local',
            }
            self.modalHeaderCheckbox = {
                checked: false,
                items: {}
            }
            self.checkedItems = [];
            self.saveDisabled = false;
            self.checkNotEmptyShow = true;
            self.changeTableShow = false;
            self.headers = {
                host:$translate.instant("aws.system.storagement.nodeName"),
                name:$translate.instant("aws.system.storagement.diskName"),
                type:$translate.instant('aws.system.storagement.diskType'),
                capacity:$translate.instant('aws.system.storagement.diskCapacity'),
            }
            self.localTableCols = [
                { field: "check", title: "",headerTemplateURL:"modalHeaderCheckbox.html",show: true },
                { field: "host", title: self.headers.host,sortable: "host",show: true,disable:false },
                { field: "name", title: self.headers.name,sortable: "name",show: true,disable:false },
                { field: "type", title: self.headers.type,sortable: "type",show: true,disable:false },
                { field: "size", title: self.headers.capacity,sortable: "size",show: true,disable:false },
            ];

            // 查询所有健康的节点
            function getNormaNodeList() {
                self.nodeList = [];
                storageManagementSrv.getNormaNodeList(localStorage.enterpriseUid,localStorage.regionUid).then(function(res){
                    if(res && res.data) {
                        res.data.forEach((item,index)=>{
                            self.nodeList.push({hostName:item,id:index});
                        })
                    }
                })
            }

            // 通过节点名称查询硬盘
            function getNodeByName(hostName) {
                TableCom.init(self, 'localTable', [], 'id', 9999 , 'modalHeaderCheckbox');
                let params = {
                    hostname: hostName
                };
                self.localTableData = [];
                storageManagementSrv.getNodeByName(params).then(function(res){
                    if(res && res.data) {
                        res.data.forEach(item=>{
                            item.type = item.type.toLowerCase();
                            item.size = `${utilTool.setUnit(item.size*1).total}${utilTool.setUnit(item.size*1).unit}`;
                        })
                        self.localTableData = res.data;
                        TableCom.init(self, 'localTable', res.data ,'id', 9999, 'modalHeaderCheckbox');
                    }
                })
            }
            
            function init(){
                let curUse = self.storageUseList.filter(item=>item.id==4);
                return {
                    use:curUse[0],
                    description:"",
                    name:"",
                    selectNode: ""
                }
            }
            
            self.localInit = init();

            // 处理新建提交的节点硬盘数据
            function dealLocalDiskInfo(checkedItems) {
                let diskInfo = {};
                for (let i = 0; i< checkedItems.length; i++) {
                    let hostObj = checkedItems[i];
                    if(diskInfo[hostObj.host]) {
                    diskInfo[hostObj.host].push(hostObj.uuid);
                    }else {
                    let restList = [];
                    restList.push(hostObj.uuid);
                    diskInfo[hostObj.host] = restList;
                    }
                }
                // 后端传参格式修改 
                // for (const key in diskInfo) {
                //    diskInfo[key] = JSON.stringify(diskInfo[key]);
                // }
                return diskInfo;
            }

            // 用途选择下拉列表
            self.changeUse = function(obj) {
                self.$emit('storage-use-change',obj)
            }

            // 存储用途下拉列表
            self.changeDevice = function(cur){
                self.localInit = init();
                self.submitValid = false;
            }

            // 选择节点下拉列表
            self.changeNode = function(value) {
                let hostName = [];
                value.forEach(item=>{
                    hostName.push(item.hostName);
                })
                hostName = hostName.join(',');
                getNodeByName(hostName);
            }

            // 表格勾选
            self.changeTableNode = function() {
                self.changeTableShow = true;
            }

            // 校验是否勾选了硬盘
            self.checkIsItem = function() {
                return self.checkNotEmptyShow = self.checkedItems.length > 0 ? true : false;
            }

            // 本地磁盘新建提交
            self.storageConfirm = function(objectForm){
                self.saveDisabled = true;
                self.changeTableShow = true;
                if(objectForm.$valid && self.checkIsItem()){
                    let diskInfo = dealLocalDiskInfo(self.checkedItems);
                    let data = {
                        regionUid: localStorage.regionUid,
                        enterpriseUid: localStorage.enterpriseUid,
                        regionKey: localStorage.regionKey,
                        name: self.localInit.name,             
                        storageUse: self.localInit.use.id,
                        storageFirm: self.storageFirm,   
                        disk: diskInfo, 
                        description: self.localInit.description,
                    }
                    storageManagementSrv.addLocalStorage(data).then(function(res){
                        if(res){
                            self.initStorageTable();
                            self.$emit('storage-modal-close',{});
                            self.saveDisabled = false;
                            self.changeTableShow = false;
                        }
                    })
                }else{
                    self.submitValid = true;
                    $timeout(()=>{
                        self.saveDisabled = false;
                    },2000)
                }
            };
            
            self.$dismiss = function(){
                self.$emit('storage-modal-close',{})
            }

            self.$watch(function(){
                return self.modalHeaderCheckbox.items;
            },function(values){
                if(Object.keys(values).length == 0) return;
                self.checkedItems = [];
                var arr = [];
                for (let i in self.modalHeaderCheckbox.items) {
                    arr.push(self.modalHeaderCheckbox.items[i]);
                }
                if(values && arr.length >=0) {
                    for (let key in values) {
                        if(values[key]) {
                            self.localTableData.forEach(item=>{
                                if(item.id == key) {
                                    self.checkedItems.push(item);
                                }
                            })
                        }
                    }
                }
                self.checkIsItem();
            },true)

            getNormaNodeList();
            getNodeByName('');
        }
    };
}
export {storageLocalTpl}