accountManageCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','$filter','$location','$routeParams']
createAccountCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','context']
copyAccountCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','context']
modifyAuthorityCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','context']
resetPasswordCtrl.$inject=['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','context']
function accountManageCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,$filter,$location,$routeParams) {
    var self = $scope;
    self.account_search={};
    self.accountCheckbox={
        checked:false,
        items:{}
    };
    self.copyParam=$location.search();
    self.installParam=angular.copy(self.copyParam);
    self.installParam.groupid=self.installParam.instanceType==0?self.installParam.id:"";
    self.installParam.id=self.installParam.instanceType==0?"":self.installParam.id;
    
    //设置项的初始化
    self.titleName="tdsqlAccountManage";
    if(sessionStorage["tdsqlAccountManage"]){
       self.titleData=JSON.parse(sessionStorage["tdsqlAccountManage"]);
    }else{
       self.titleData=[
          {name:'tdsql.accountName',value:true,disable:true,search:'user'},
          {name:'tdsql.hosts',value:true,disable:false,search:'host'},
          {name:'tdsql.accountType',value:true,disable:false,search:'readonly'},
          {name:'tdsql.createTime',value:true,disable:false,search:'create_time'}, 
          {name:'tdsql.updateTime',value:true,disable:false,search:'update_time'},
          {name:'tdsql.remarks',value:true,disable:false,search:'remark'}
       ];
    }
    
    self.refreshAccount=function(){
    	self.getAccount();
    }
    self.accountSearchTearm=function(obj){
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function(item){
           item.searchTerm="";
           titleData.forEach(function(showTitle){
                 if(showTitle.value){
                     item.searchTerm+=item[showTitle.search]+"\b";
                 }
           });
        });
    };
    
    self.applyGlobalSearch=function(aaa){
        self.accountTable.filter({          
          searchTerm: aaa
        });
    };
    self.getAccount=function(){
        self.account_search.searchTearm="";
        self.loadAcconutData= [];
        self.accountTable = new NgTableParams({
           count: GLOBAL_CONFIG.PAGESIZE
        }, {
           counts: [],
           dataset: []
        });
        var getAccountParam={};
        getAccountParam.groupid=angular.copy(self.installParam.groupid);
        getAccountParam.id=angular.copy(self.installParam.id);
        tdsqlSrv.getAccount(getAccountParam,self.installParam.clusterKey).then(function(res){
            if(res&&res.data&&res.data.returnData&&angular.isArray(res.data.returnData.users)){
                self.loadAcconutData = res.data.returnData.user;
                successFunc(res.data.returnData.users); 
            }
        });
    }
    self.refreshAccount();

    function successFunc(data){
        self.accountData=data;
        self.accountSearchTearm({tableData:self.accountData,titleData:self.titleData});
        self.accountData.forEach(function(opt,index){
        	opt.create_time=$filter("date")(opt.create_time, "yyyy-MM-dd HH:mm:ss");  
        	opt.update_time=$filter("date")(opt.update_time, "yyyy-MM-dd HH:mm:ss");
        	if(opt.create_time==null){
        		opt.create_time="无"
        	}
        	if(opt.update_time==null){
        		opt.update_time="无"
        	}
        	opt.remark==""?opt.remark='无':opt.remark=opt.remark;
        	opt.id=index;
        	opt.readonly==0?opt.readonly='普通账户':opt.readonly='只读账户';
        	
        })
        self.accountSearchTearm({tableData:self.accountData,titleData:self.titleData});
        TableCom.init(self,'accountTable',self.accountData,'id',10,'accountCheckbox');
    }

    //监听页面勾选框变化
    self.$watch(function() {
        return self.accountCheckbox.items;//监控checkbox
    }, function(val) {
        self.accountCheckedItems = [];
        var arr=[];
        for(var i in self.accountCheckbox.items){
            arr.push(self.accountCheckbox.items[i]);
        }
        if(val && arr.length>=0){
            for(var key in val){
                if(val[key]){
                    self.accountData.forEach(item=>{
                        if(item.id==key){
                            self.accountCheckedItems.push(item);
                        }
                    });
                }
            }
        }
        //判断各个按钮是否可以点击
        if(!self.accountCheckedItems||self.accountCheckedItems.length!=1){
        	self.canClone=true;
        }else{
        	self.canClone=false;
        }
        
        if(!self.accountCheckedItems||self.accountCheckedItems.length==0){
        	self.canDel=true;
        }else{
        	self.canDel=false;
        }
        
    },true);

    self.createAccount=function(createAccountForm){
        self.createAccountModal=$uibModal.open({
            animation: true,
            templateUrl: "createAccount.html",
            controller: "createAccountCtrl",
            resolve: {
                context: function() {
                    return self;
                }
            }
        });
    };


    self.copyAccount=function(){
        self.copyAccountModal=$uibModal.open({
            animation: true,
            templateUrl: "copyAccount.html",
            controller: "copyAccountCtrl",
            resolve: {
                context: function() {
                    return self;
                }
            }
        });
    };

    self.deleteAccount= function() {
    	var str="";
    	for(var i=0;i<self.accountCheckedItems.length;i++){
    		if(i<self.accountCheckedItems.length-1){
    			str+=self.accountCheckedItems[i].user+",";
    		}else{
    			str+=self.accountCheckedItems[i].user;
    		}
    	}
        var content = {
            target: "delAccount",
            msg: "<div>" + $translate.instant("aws.tdsql.confirmDelAccount") + "</div><div class='tdsql-delAccount'>"+$translate.instant("aws.tdsql.delAccount")+"["+str+"]</div>",
            data: self.accountCheckedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delAccount", function(e,data) {
    	var content=[];
    	for(var i=0;i<data.length;i++){
    		var content1={id:self.installParam.id,groupid:self.installParam.groupid,user:"",host:""};
    		content1.user=data[i].user;
    		content1.host=data[i].host;
    		content.push(content1);
    	}
		tdsqlSrv.delAccount(content,self.installParam.clusterKey).then(function(res){
			self.getAccount();
		})
    });

    self.modifyAuthority=function(){
        self.modifyAuthorityModal=$uibModal.open({
            animation: true,
            templateUrl: "modifyAuthority.html",
            controller: "modifyAuthorityCtrl",
            resolve: {
                context: function() {
                    return self;
                }
            }
        });
    };

    self.resetPassword=function(){
         self.resetPasswordModal=$uibModal.open({
            animation: true,
            templateUrl: "resetPassword.html",
            controller: "resetPasswordCtrl",
            resolve: {
                context: function() {
                    return self;
                }
            }
        });
    };
}
function createAccountCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,context) {
    var self = $scope;
    self.minnum=2;
    self.createNameMsg="只能输入字母、数字、横线以及下划线";
    self.hostsErrorMsg=$translate.instant("aws.tdsql.placeholder.hostsMsg");
    self.pwdErrorMsg=$translate.instant("aws.tdsql.placeholder.passwordMsg");
    self.accountSubmintted=false;
    self.account={
        user:"",
        readonly:"0",
        host:"",
        pwd:"",
        confirmPwd:"",
        remark:"",
        id:context.installParam.id,
        groupid:context.installParam.groupid,
        expiretime:0,
    };
    self.interactedAccount = function(field) {
        if (field) {
            return self.accountSubmintted || field.$dirty;
        }
    };
    self.createAccountConfirm=function(createAccountForm){
        if(createAccountForm.$valid){
        	delete self.account.confirmPwd;
        	self.account.readonly=parseInt(self.account.readonly);
        	if(self.account.host==""){
        		self.account.host="%";
        	}
        	tdsqlSrv.createAccountConfirm(self.account,context.installParam.clusterKey).then(function(res){
        		//查询数据
        		context.createAccountModal.close();
        		context.getAccount();
        	})
        }else{
            self.accountSubmintted=true;
        }
    };
}

function copyAccountCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,context) {
    var self = $scope;
    self.minnum=2;
    self.copyNameMsg="只能输入字母、数字、横线以及下划线";
    self.hostsErrorMsg=$translate.instant("aws.tdsql.placeholder.hostsMsg");
    self.pwdErrorMsg=$translate.instant("aws.tdsql.placeholder.passwordMsg");
    self.accountSubmintted=false;
    self.copyAccount=context.accountCheckedItems[0];
    self.account={
        user:"",
        readonly:"0",
        host:"",
        pwd:"",
        confirmPwd:"",
        remark:"",
        id:context.installParam.id,
        groupid:context.installParam.groupid,
        expiretime:0,
        src:{"user":"","host":""},
        dst:{"user":"","host":""}
    };
    self.interactedAccount = function(field) {
        if (field) {
            return self.accountSubmintted || field.$dirty;
        }
    };
    
    self.copyAccountConfirm=function(copyAccountForm){
        if(copyAccountForm.$valid){
        	delete self.account.confirmPwd;
        	self.account.readonly=parseInt(self.account.readonly);
        	if(self.account.host==""){
        		self.account.host="%";
        	}
        	self.account.src.user=self.copyAccount.user;
        	self.account.src.host=self.copyAccount.host;
        	self.account.dst.user=self.account.user;
        	self.account.dst.host=self.account.host;
        	tdsqlSrv.copyAccountConfirm(self.account,context.installParam.clusterKey).then(function(res){
        		//查询数据
        		context.copyAccountModal.close();
        		context.getAccount();
        	})
        }else{
            self.accountSubmintted=true;
        }
    };
}
function modifyAuthorityCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,context) {
    var self = $scope;
    self.modifySubmintted=false;
    self.interactedModify = function(field) {
        if (field) {
            return self.modifySubmintted || field.$dirty;
        }
    };
    self.modifyData={
       authorizedObject:{
          library:"",
          object:"",
          owner:"",
       },
       selectedAll:false
    };
    self.libraryList=[];
    self.objectList=[
    	{name:"所有对象",value:"*"},
        {name:"数据表(table)",value:"table"},
        {name:"数据视图(view)",value:"view"},
        {name:"存储过程(procedure)",value:"proc"},
        {name:"函数(function)",value:"func"}
    ];
    self.owner=[
        {name:"所有",value:"*"}
    ];
	self.globalAuthority=[
		{name:'SELECT',value:false},
        {name:'INSERT',value:false},
        {name:'UPDATE',value:false},
        {name:'DELETE',value:false},
        {name:'CREATE',value:false},
        {name:'DROP',value:false},
        {name:'REFERENCES',value:false},
        {name:'INDEX',value:false},
        {name:'ALTER',value:false},
        {name:'CREATE TEMPORARY TABLES',value:false},
        {name:'LOCK TABLES',value:false},
        {name:'EXECUTE',value:false},
        {name:'CREATE VIEW',value:false},
        {name:'SHOW VIEW',value:false},
        {name:'CREATE ROUTINE',value:false},
        {name:'ALTER ROUTINE',value:false},
        {name:'EVENT',value:false},
        {name:'TRIGGER',value:false},
        {name:'SHOW DATABASES',value:false},
		{name:'REPLICATION SLAVE',value:false},
		{name:'REPLICATION CLIENT',value:false},
		{name:'RELOAD',value:false}]
	
	self.selectItem=self.globalAuthority;
	
	self.dataBaseAuthority=[
		{name:'SELECT',value:false},
        {name:'INSERT',value:false},
        {name:'UPDATE',value:false},
        {name:'DELETE',value:false},
        {name:'CREATE',value:false},
        {name:'DROP',value:false},
        {name:'REFERENCES',value:false},
        {name:'INDEX',value:false},
        {name:'ALTER',value:false},
        {name:'CREATE TEMPORARY TABLES',value:false},
        {name:'LOCK TABLES',value:false},
        {name:'EXECUTE',value:false},
        {name:'CREATE VIEW',value:false},
        {name:'SHOW VIEW',value:false},
        {name:'CREATE ROUTINE',value:false},
        {name:'ALTER ROUTINE',value:false},
        {name:'EVENT',value:false},
        {name:'TRIGGER',value:false}
	]
	
	self.table_view_authority=[
		{name:'SELECT',value:false},
        {name:'INSERT',value:false},
        {name:'UPDATE',value:false},
        {name:'DELETE',value:false},
        {name:'CREATE',value:false},
        {name:'DROP',value:false},
        {name:'REFERENCES',value:false},
        {name:'INDEX',value:false},
        {name:'ALTER',value:false},
        {name:'CREATE VIEW',value:false},
        {name:'SHOW VIEW',value:false},
        {name:'TRIGGER',value:false}
	]
	
	self.save_func_authority=[
		{name:'ALTER ROUTINE',value:false},
		{name:'EXECUTE',value:false}
	]
	
	self.field_authority=[
		{name:'INSERT',value:false},
		{name:'REFERENCES',value:false},
		{name:'SELECT',value:false},
		{name:'UPDATE',value:false}
	]
	
	self.checkAuthority=function(res,name){
		if(self.modifyData.authorizedObject.library.db=='所有库'){
			self.selectItem=self.globalAuthority;
		}else{
			if(name=='dataBaseChange'){
				self.selectItem=self.dataBaseAuthority;
			}
			if(name=='table'||name=='view'){
				self.selectItem=self.table_view_authority;
			}
			if(name=='proc'||name=='func'){
				self.selectItem=self.save_func_authority;
			}
		}
	}
	
    self.$watch(function(){
        return self.modifyData.selectedAll;
    },function(selectedAll){
        if(selectedAll){
           self.selectItem.forEach(function(item){
               item.value=true;
           }); 
        }else{
           self.selectItem.forEach(function(item){
               item.value=false;
           });  
        }
    });
    
    self.$watch(function(){
        return self.selectItem;
    },function(values){
        if(values){
           self.selectedArr=[];
           values.forEach(function(item){
               if(item.value){
                  self.selectedArr.push(item);
               }
           });
           if(self.selectedArr.length==0){
               self.modifyData.selectedAll=false;
           }
        }
    },true);
    
    //修改权限
    self.modifyAuthority=function(){
    	var modifyAuthorityData="";
    	modifyAuthorityData=angular.copy(self.par);
    	modifyAuthorityData.user=context.accountCheckedItems[0].user;
    	modifyAuthorityData.host=context.accountCheckedItems[0].host;
    	modifyAuthorityData.db=self.modifyData.authorizedObject.library.db=='所有库'?'*':self.modifyData.authorizedObject.library.db;
    	modifyAuthorityData.type=self.modifyData.authorizedObject.object.value;
    	modifyAuthorityData.type=self.modifyData.authorizedObject.owner.value;
    	modifyAuthorityData.col="*";
    	modifyAuthorityData.op="set";
    	modifyAuthorityData.object="*";
    	var str="";
    	for(var i=0;i<self.selectedArr.length;i++){
    		if(i<self.selectedArr.length-1){
    			str=str+self.selectedArr[i].name+",";
    		}else{
    			str=str+self.selectedArr[i].name;
    		}
    	}
    	modifyAuthorityData.rights=str;
    	tdsqlSrv.modifyAuthority(modifyAuthorityData,context.installParam.clusterKey).then(function(res){
    		context.modifyAuthorityModal.close();
    		context.getAccount();
    	})
    }

    
    self.par={groupid:context.installParam.groupid,id:context.installParam.id};
    
    self.checkedAccount=context.accountCheckedItems[0];
    //查询所有数据库和权限拼接授权sql
    self.initAuthority=function(obj){
    	tdsqlSrv.findAllDataBase(obj,context.installParam.clusterKey).then(function(res){
    		if(res&&res.data&&res.data.returnData&&res.data.returnData.dbs&&angular.isArray(res.data.returnData.dbs)){
    			self.libraryList=res.data.returnData.dbs;
    			var arr=[];
    			//将所有数据库放入到下拉列表中
    			var allDatabase={db:"所有库"};
    			self.libraryList.splice(0,0,allDatabase);
    			//下拉列表默认显示数据
    			self.modifyData.authorizedObject.library=self.libraryList[0];
    			self.modifyData.authorizedObject.object=self.objectList[0];
    			self.modifyData.authorizedObject.owner=self.owner[0];
    			var findDataBaseParam={};
    			findDataBaseParam=angular.copy(self.par);
	    		findDataBaseParam.user=context.accountCheckedItems[0].user;
	    		findDataBaseParam.host=context.accountCheckedItems[0].host;
    			//查询数据库权限
    			self.sqlStatement=[];
    			tdsqlSrv.findDataBaseAuthority(findDataBaseParam,context.installParam.clusterKey).then(function(resp){
				if(resp.data.returnData.sql_result&&angular.isArray(resp.data.returnData.sql_result)){
					resp.data.returnData.sql_result.forEach(function(sqlR,index){
						for(var key in sqlR){
							var aa = key;
							var value = sqlR[key];
							self.sqlStatement.push((self.sqlStatement.length+1)+". "+value);
						}
					})
				}
    			})
    		}
    	})
    };
    self.initAuthority(self.par);
}
function resetPasswordCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,context) {
    var self = $scope;
    self.resetSubmintted=false;
    self.verificationErrorMsg=$translate.instant("aws.tdsql.placeholder.verificationErrorMsg");
    self.phoneErrorMsg=$translate.instant("aws.tdsql.placeholder.phoneErrorMsg");
    self.pwdErrorMsg=$translate.instant("aws.tdsql.placeholder.passwordMsg");
    self.account={
    	groupid:context.installParam.groupid,
    	id:context.installParam.id,
    	user:context.accountCheckedItems[0].user,
    	host:context.accountCheckedItems[0].host,
    	pwd:"",
    	confirmPwd:""
    }
    self.installName=self.account.groupid==""?self.account.id:self.account.groupid;
    self.interactedReset = function(field) {
        if (field) {
            return self.resetSubmintted || field.$dirty;
        }
    };
    self.resetPwd=function(resetPasswordForm){
    	if(resetPasswordForm.$valid){
    		delete self.account.confirmPwd;
			  tdsqlSrv.resetPwd(self.account,context.installParam.clusterKey).then(function(res){
			  	//查询数据
        		context.resetPasswordModal.close();
        		context.getAccount();
			  })
    	}else{
            self.resetSubmintted = true;
        }
    }
    
}
export{accountManageCtrl,createAccountCtrl,modifyAuthorityCtrl,copyAccountCtrl,resetPasswordCtrl};