"use strict";
export default function routeConfig($routeProvider,$locationProvider){
        
        $locationProvider.html5Mode(false);
        $routeProvider
            .when("/", {
                templateUrl: "js/auth/tmpl/login.html",
                controller: "loginCtrl",
                region:false,
                domainProject:false
            })
            .when("/view", {
                templateUrl: "js/overview/tmpl/overviewa.html",
                controller: "viewCtrl",
                parent:"SOverview",
                active:"view",
                region:false,
                domainProject:false
            })
            .when("/single", {
                templateUrl: "js/overview/tmpl/overview.html",
                controller: "viewaCtrl",
                reloadOnSearch:false,
                region:false,
                domainProject:false
            })
            .when("/quickconfig/createins", {
                templateUrl: "js/shortcuts/instances/tmpl/createIns.html",
                controller: "createInsCtrl",
                reloadOnSearch:false,
                region:true,
                domainProject:true
            })
            .when("/permit/overview",{
                templateUrl:"js/easyReform/tmpl/userOverview.html",
                controller:"userOverviewCtrl",
                reloadOnSearch:false,
                parent:"User",
                active:"overview",
                region:false,
                domainProject:false
            })
            .when("/permit/user", {
                templateUrl: "js/user/tmpl/user.html",
                controller: "UserCtrl",
                parent:"User",
                active:"user",
                region:false,
                domainProject:false,
                reloadOnSearch: false,
            })
            .when("/permit/role", {
                templateUrl: "js/roles/tmpl/role.html",
                controller: "RoleCtrl",
                parent:"User",
                active:"role",
                region:false,
                domainProject:false
            })
            // 保密员角色
            .when("/permit/secrecyRole", {
                templateUrl: "js/roles/secrecyRole/tmpl/secrecyRole.html",
                controller: "secrecyRoleCtrl",
                parent:"User",
                active:"secrecyRole",
                region:true,
                domainProject:false
            })
            // 审计员角色
            .when("/permit/auditRole", {
                templateUrl: "js/roles/auditRole/tmpl/auditRole.html",
                controller: "auditRoleCtrl",
                parent:"User",
                active:"auditRole",
                region:true,
                domainProject:false
            })
            /*.when("/permit/newRole", {
                templateUrl: "js/roles/tmpl/newRole.html",
                controller: "NewRoleCtrl"
            })*/
            .when("/permit/org", {
                templateUrl: "js/org/tmpl/org.html",
                controller: "OrgCtrl",
                parent:"User",
                active:"org",
                region:false,
                domainProject:false
            })
            .when("/permit/department", {
                templateUrl: "js/department/tmpl/department.html",
                controller: "DepartmentCtrl",
                reloadOnSearch: false,
                parent:"User",
                active:"department",
                region:false,
                domainProject:false
            })
            .when("/permit/project", {
                templateUrl: "js/project/tmpl/project.html",
                controller: "ProjectCtrl",
                reloadOnSearch: false,
                parent:"User",
                active:"project",
                region:false,
                domainProject:false
            })
            .when("/configure/node", {
                templateUrl: "js/configure/node/tmpl/node.html",
                controller: "NodeCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"node",
                region:true,
                domainProject:false
            })
            // .when("/configure/cluster", {
            //     templateUrl: "js/configure/cluster/tmpl/cluster.html",
            //     controller: "clusterCtrl",
            //     reloadOnSearch: false,
            //     parent:"System",
            //     active:"cluster",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/configure/cluster/:stepId", {
            //     templateUrl: function($routeParams) {
            //         var url = "js/configure/cluster/tmpl/";
            //         var path = 'stepone.html';
            //         var params = $routeParams.stepId;
            //         if (params) {
            //             switch (params) {
            //                 case 'stepone':
            //                     path = 'stepone.html';
            //                     break;
            //                 case 'steptwo':
            //                     path = 'steptwo.html';
            //                     break;
            //                 case 'stepthree':
            //                     path = 'stepfour.html';
            //                     break;
            //                     /*case 'stepfour':
            //                         path = 'stepfour.html';
            //                         break;*/
            //                 case 'complete':
            //                     path = 'complete.html';
            //                     break;
            //             }
            //             return url + path;
            //         }
            //     },
            //     controller: "clusterDeployCtrl",
            //     reloadOnSearch: false,
            //     region:false,
            //     domainProject:false
            // })
            .when("/cvm/cvmview", {
                // templateUrl: function() {
                //     return choosenTemp("js/cvm/overview/tmpl/cvmView.html", "js/cvm/overview/tmpl/cvmView_sv.html")
                // },
                templateUrl: "js/cvm/overview/tmpl/cvmView.html",
                controller: "cvmViewCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"cvmview",
                region:true,
                domainProject:true
            })
            .when("/cvm/label", {
                templateUrl: "js/cvm/instances/tmpl/label.html",
                controller: "LabelCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"label",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            // 负载均衡-增强型
            .when("/cvm/enhance", {
                templateUrl: "js/cvm/loadbalancersmodile/tmpl/enhance.html",
                controller: "enhanceCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"enhance",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            .when("/cvm/instances", {
                // templateUrl: function() {
                //     return choosenTemp("js/cvm/instances/tmpl/instances.html", "js/cvm/instances/tmpl/instances_sv.html")
                // },
                templateUrl: "js/cvm/instances/tmpl/instances.html",
                controller: "InstancesCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"instances",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            .when("/cvm/affinity", {
                templateUrl:"js/cvm/instances/tmpl/affinity.html",
                controller: "affinityCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"affinity",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/rbackups", {
                templateUrl:"js/cvm/backupRecovery/backups/tmpl/rbackups.html",
                controller: "rbackupsCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"rbackups",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            .when("/cvm/backupStrategy", {
                templateUrl:"js/cvm/backupRecovery/backupStrategy/tmpl/backupStrategy.html",
                controller: "backupStrategyCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"backupStrategy",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            .when("/cvm/recovery", {
                templateUrl:"js/cvm/backupRecovery/recovery/tmpl/recovery.html",
                controller: "recoveryCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"recovery",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            .when("/cvm/ports", {
                templateUrl: "js/cvm/instances/tmpl/ports.html",
                controller: "portsCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"ports",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/elasticexpansion", {
                templateUrl: "js/cvm/instances/tmpl/elasticExpansion.html",
                controller: "elasticExpansionCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"elasticexpansion",
                region:true,
                domainProject:true 
            })
            .when("/cvm/images", {
                // templateUrl: function() {
                //     return choosenTemp("js/cvm/images/tmpl/images.html", "js/cvm/images/tmpl/images_sv.html")
                // },
                templateUrl: "js/cvm/images/tmpl/images.html",
                controller: "ImagesCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"images",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/makeimages", {
                templateUrl: "js/cvm/images/tmpl/makeImage.html",
                controller: "makeImageCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"makeimages",
                region:true,
                domainProject:true 
            })
            .when("/cvm/extension", {
                templateUrl: "js/cvm/extension/tmpl/extension.html",
                controller: "extensionCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"extension",
                region:true,
                domainProject:true 
            })
            .when("/cvm/elasticExtension", {
                templateUrl: "js/cvm/elasticExtension/tmpl/eelist.html",
                controller: "eeListCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"elasticExtension",
                region:true,
                domainProject:true 
            })
            .when("/cvm/alarm", {
                templateUrl: "js/cvm/alarm/tmpl/alarmlist.html",
                controller: "alarmListCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"alarm",
                region:true,
                domainProject:true 
            })
            // .when("/cvm/extensionins/:id", {
            //     templateUrl: "js/cvm/extension/tmpl/extensionins.html",
            //     controller: "extensionInsInfoCtrl",
            //     reloadOnSearch: false,
            //     parent:"Resource",
            //     active:"extensionins",
            //     region:true,
            //     domainProject:true 
            // })
            .when("/cvm/scalpolicy", {
                templateUrl: "js/cvm/extension/tmpl/scalpolicy.html",
                controller: "scalpolicyCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"scalpolicy",
                region:true,
                domainProject:true 
            })
            .when("/logs/operatelogs", {
                templateUrl: "js/logs/operatelog/tmpl/operatelog.html",
                // templateUrl: function(){
                //     return choosenTemp("js/logs/operatelog/tmpl/operatelog.html", "js/logs/operatelog/tmpl/operatelog_sv.html")
                // },
                controller: "operatelogCtrl",
                reloadOnSearch: false,
                parent:"Log",
                active:"operatelogs",
                region:true,
                domainProject:false
            })
            .when("/logs/audit", {
                templateUrl: "js/logs/operatelog/tmpl/operatelog.html",
                // templateUrl: function(){
                //     return choosenTemp("js/logs/operatelog/tmpl/operatelog.html", "js/logs/operatelog/tmpl/operatelog_sv.html")
                // },
                controller: "operatelogCtrl",
                reloadOnSearch: false,
                parent:"Log",
                active:"audit",
                region:true,
                domainProject:false
            })
            .when("/logs/loginlogs", {
                // templateUrl: function(){
                //     return choosenTemp("js/logs/loginlog/tmpl/loginlog.html", "js/logs/loginlog/tmpl/loginlog_sv.html")
                // },
                templateUrl: "js/logs/loginlog/tmpl/loginlog.html",
                controller: "loginlogCtrl",
                reloadOnSearch: false,
                parent:"Log",
                active:"loginlogs",
                region:true,
                domainProject:false
            })
            .when("/cvm/volumes", {
                templateUrl: "js/cvm/volumes/tmpl/volumesNew.html",
                controller: "volumesTabCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"volumes",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/snapshots", {
                templateUrl: "js/cvm/volumes/tmpl/snapshots.html",
                // templateUrl: function() {
                //     return choosenTemp("js/cvm/volumes/tmpl/snapshots.html", "js/cvm/volumes/tmpl/CopySnapshots.html")
                // },
                controller: "SnapshotsCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"snapshots",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/system/regularSnap", {
                templateUrl: "js/cvm/volumes/tmpl/regularSnap.html",
                controller: "regularSnapCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"regularSnap",
                region:true,
                domainProject:true 
            })
            // .when("/cvm/backups", {
            //     templateUrl: "js/cvm/volumes/tmpl/backups.html",
            //     controller: "backupsChainCtrl",
            //     reloadOnSearch: false,
            //     parent:"Resource",
            //     active:"backups",
            //     region:true,
            //     domainProject:true 
            // })
            .when("/cvm/networks", {
                templateUrl: "js/cvm/networks/tmpl/networks.html",
                // templateUrl: function() {
                //     return choosenTemp("js/cvm/networks/tmpl/networks.html", "js/cvm/networks/tmpl/networks_sv.html")
                // },
                controller: "networksCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"networks",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/physicalnetworks", {
                templateUrl: "js/cvm/networks/tmpl/physicalNetworks.html",
                controller: "physicalNetworksCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"physicalnetworks",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/routers", {
                templateUrl: "js/cvm/networks/tmpl/routers.html",
                controller: "routersCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"routers",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/vpn", {
                templateUrl: "js/cvm/networks/tmpl/vpn.html",
                controller: "vpnCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"vpn",
                region:true,
                domainProject:true 
            })
            // 负载均衡-增强型
            .when("/cvm/enhance", {
                templateUrl: "js/cvm/loadbalancersmodile/tmpl/enhance.html",
                controller: "enhanceCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"enhance",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            .when("/cvm/loadbalancers", {
                templateUrl: "js/cvm/loadbalancers/tmpl/balance.html",
                controller: "balanceCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"loadbalancers",
                region:true,
                domainProject:true 
            })
            .when("/cvm/loadbalancing", {
                templateUrl: "js/cvm/loadbalancersNew/tmpl/balance.html",
                controller: "balanceNewCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"loadbalancing",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/loadbalancingcertificate", {
                templateUrl: "js/cvm/networks/tmpl/loadbalancingcertificate.html",
                controller: "loadbalancingcertificateCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"loadbalancingcertificate",
                region:true,
                domainProject:true,
                isAllDomain:true
            })
            // .when("/cvm/loadbalancers/:balanceId", {
            //     templateUrl: "js/cvm/loadbalancers/tmpl/balanceDetail.html",
            //     controller: "balanceDetailCtrl",
            //     reloadOnSearch: false,
            //     parent:"Resource",
            //     region:true,
            //     domainProject:true 
            // })
            // .when("/cvm/loadbalancers/:balanceId/:poolId", {
            //     templateUrl: "js/cvm/loadbalancers/tmpl/poolsDetail.html",
            //     controller: "poolsDetailCtrl",
            //     reloadOnSearch: false,
            //     parent:"Resource",
            //     active:"loadbalancers",
            //     region:true,
            //     domainProject:true 
            // })
            .when("/cvm/monitors", {
                templateUrl: "js/cvm/loadbalancers/tmpl/monitors.html",
                controller: "monitorsCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"monitors",
                region:true,
                domainProject:true 
            })
            .when("/cvm/bandwidth", {
                templateUrl: "js/cvm/networks/tmpl/bandwidth.html",
                controller: "bandwidthCtrl",
                parent:"Resource",
                active:"bandwidth",
                region:true,
                domainProject:true 
            })
            .when("/cvm/floating_ips", {
                templateUrl: "js/cvm/networks/tmpl/floating_ips.html",
                controller: "floatipsCtrl",
                parent:"Resource",
                active:"floating_ips",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/firewall", {
                templateUrl: "js/cvm/networks/tmpl/netfirewall.html",
                controller: "netfirewallCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"firewall",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/qos", {
                templateUrl: "js/cvm/networks/tmpl/qos.html",
                controller: "qosCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"qos",
                region:true,
                domainProject:true 
            })
            .when("/cvm/security_groups", {
                templateUrl: "js/cvm/security/tmpl/firewall.html",
                // templateUrl: function() {
                //     return choosenTemp("js/cvm/security/tmpl/firewall.html", "js/cvm/security/tmpl/firewall_sv.html")
                // },
                controller: "firewallCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"security_groups",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/security_template", {
                templateUrl: "js/cvm/security/tmpl/securityTemplate.html",
                controller: "securityTemplateCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"security_template",
                region:true,
                domainProject:true
            })
            .when("/cvm/keypairs", {
                templateUrl: "js/cvm/security/tmpl/keyPairs.html",
                // templateUrl: function() {
                //     return choosenTemp("js/cvm/security/tmpl/keyPairs.html", "js/cvm/security/tmpl/keyPairs_sv.html")
                // },
                controller: "keyPairsCtrl",
                parent:"Resource",
                active:"keypairs",
                region:true,
            })
            .when("/cvm/recycle", {
                templateUrl: "js/cvm/recycle/tmpl/recycle.html",
                controller: "recycleCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"recycle",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/recyclecs", {
                templateUrl: "js/cvm/recycle/tmpl/recycleCs.html",
                controller: "recycleCsCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"recyclecs",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/recycleDb", {
                templateUrl: "js/cvm/recycle/tmpl/recycleDb.html",
                controller: "recycleDbCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"recycleDb",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/netTopo", {
                templateUrl: "js/zrenderTopo/tmpl/topo.html",
                controller: "zrenderTopoCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"netTopo",
                region:true,
                domainProject:true 
            })
            .when("/cvm/cloudDatabase",{
                templateUrl: "js/cvm/database/tmpl/cloud-database.html",
                controller: "cloudDatabaseCtrl",
                reloadOnSearch: false,
                parent: "Resource",
                active: "cloudDatabase",
                region: true,
                domainProject: true,
                isAllDomain: true
            })
            /*.when("/topology", {
                templateUrl: "js/topology/tmpl/topo.html",
                controller: "TopologyCtrl"
            })*/
            .when("/monitor/topology", {
                templateUrl: "js/zrenderTopo/tmpl/topo.html",
                controller: "zrenderTopoCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"topology",
                region:true,
                domainProject:true
            })
            /*.when("/monitor/topology", {
                templateUrl: "js/monitor/graph/tmpl/topo.html",
                controller: "theGraphCtrl",
                parent:"Monitoring",
                active:"topology",
                region:true,
                domainProject:false
            })*/
            .when("/system/quotas", {
                templateUrl: "js/settings/quotas/tmpl/quotas.html",
                controller: "quotaCtrl",
                parent:"System",
                active:"quotas",
                region:false,
                domainProject:false
            })
            .when("/system/logoSetting", {
                templateUrl: "js/system/logoSetting/tmpl/logoSetting.html",
                controller: "logoSettingCtrl",
                parent:"System",
                active:"logoSetting",
                region:false,
                domainProject:false
            })
            .when("/system/systeminspection", {
                templateUrl: "js/system/tmpl/systemInspection.html",
                controller: "systemInspectionCtrl",
                parent:localStorage.permission == 'stand'?'Monitoring':'System',
                active:"systeminspection",
                reloadOnSearch: false,
                region:true,
                domainProject:false
            })
            // 增强型负载均衡
            .when("/system/enhanceconfig", {
                templateUrl: "js/system/enhanceconfig/tmpl/enhance-config.html",
                controller: "enhanceConfigCtrl",
                parent:'System',
                active:"enhanceconfig",
                reloadOnSearch: false,
                region:true,
                domainProject:false
            })
            // .when("/system/systeminspection", {
            //      templateUrl: "js/system/tmpl/systemInspection.html",
            //      controller: "systemInspectionCtrl",
            //      parent:"System",
            //      active:"systeminspection",
            //      reloadOnSearch: false,
            //      region:true,
            //      domainProject:false
            //  })
            .when("/system/networksmanage", {
                templateUrl: "js/system/tmpl/networksManage.html",
                controller: "networksManageCtrl",
                parent:"System",
                active:"networksmanage",
                region:true,
                domainProject:false
            })
            .when("/system/maxpcshow", {//大屏展示
                templateUrl: "js/system/maxpcshow/tmpl/maxpcshow.html",
                controller: "maxpcshowCtrl",
                parent:"System",
                active:"maxpcshow",
                region:false,
                domainProject:false
            })
            .when("/system/grade", {//云平台升级
                templateUrl: "js/system/tmpl/grade.html",
                controller: "gradeCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"grade",
                region:false,
                domainProject:false
            })
            .when("/system/about", {
                templateUrl: "js/system/tmpl/about.html",
                controller: "aboutCtrl",
                parent:"System",
                active:"about",
                region:false,
                domainProject:false
            })
            .when("/system/cloud", {
                templateUrl: "js/settings/cloud/tmpl/cloud.html",
                controller: "cloudCtrl",
                parent:"System",
                active:"cloud",
                region:false,
                domainProject:false
            })
            .when("/monitor/monitorview", {
                templateUrl: "js/monitor/monitorview/tmpl/view.html",
                controller: "monitorviewCtrl",
                parent:"Monitoring",
                active:"monitorview",
                region:true,
                domainProject:false
            })
            .when("/monitor/resview", {
                templateUrl: "js/monitor/resource/tmpl/resview.html",
                controller: "resviewCtrl",
                parent:"Monitoring",
                active:"resview",
                region:false,
                domainProject:false
            })
            .when("/monitor/physical", {
                templateUrl: "js/monitor/resource/tmpl/physical_host.html",
                controller: "phyHostCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"physical",
                region:true,
                domainProject:false
            })
            .when("/monitor/sqlDatabase", {
                templateUrl: "js/monitor/resource/tmpl/sqlDatabase.html",
                controller: "sqlDatabaseCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"physical",
                region:true,
                domainProject:false
            })
            .when("/monitor/vmhost", {
                templateUrl: "js/monitor/resource/tmpl/vm_host.html",
                controller: "vmHostCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"vmhost",
                region:true,
                domainProject:true
            })
            .when("/monitor/monceph", {
                templateUrl: "js/monitor/resource/tmpl/monceph.html",
                parent:"Monitoring",
                active:"monceph",
                region:false,
                domainProject:false
            })
            .when("/monitor/cephview", {
                templateUrl: "js/monitor/resource/tmpl/ceph.html",
                controller: "cephCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"cephview",
                region:true,
                domainProject:false
            })
            /*.when("/monitor/service", {
                templateUrl: "js/monitor/service/tmpl/service.html",
                controller: "ServiceCtrl"
            })*/
            .when("/monitor/openstackService", {
                templateUrl: "js/monitor/service/tmpl/openstackService.html",
                controller: "openstackServiceCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"openstackService",
                region:true,
                domainProject:false
            })
            .when("/monitor/mysql", {
                templateUrl: "js/monitor/service/tmpl/mysql.html",
                controller: "mysqlCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"mysql",
                region:true,
                domainProject:false
            })
            .when("/monitor/rabbitmq", {
                templateUrl: "js/monitor/service/tmpl/rabbitmq.html",
                controller: "rabbitmqCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"rabbitmq",
                region:true,
                domainProject:false
            })
            .when("/monitor/memcached", {
                templateUrl: "js/monitor/service/tmpl/memcached.html",
                controller: "memcachedCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"memcached",
                region:true,
                domainProject:false
            })
            .when("/monitor/routermonitor", {
                templateUrl: "js/monitor/service/tmpl/routerMonitor.html",
                controller: "routerMonitorCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"routermonitor",
                region:true,
                domainProject:true
            })
            .when("/monitor/alarmevent", {
                templateUrl: "js/monitor/alarmManagement/tmpl/alarmEvent.html",
                controller: "alarmEventCtrl",
                reloadOnSearch: false,
                parent:"Monitoring",
                active:"alarmevent",
                region:true,
                domainProject:true
            })
            .when("/monitor/alarmsetting", {
                templateUrl: "js/monitor/alarmManagement/tmpl/alarmSetting.html",
                controller: "alarmSettingCtrl",
                parent:"Monitoring",
                active:"alarmsetting",
                region:true,
                domainProject:true
            })
            .when("/monitor/alarmtemplate", {
                templateUrl: "js/monitor/alarmManagement/tmpl/alarmTemplate.html",
                controller: "alarmTemplateCtrl",
                parent:"Monitoring",
                active:"alarmtemplate",
                region:false,
                domainProject:false
            })
            .when("/monitor/contractgroup", {
                templateUrl: "js/monitor/alarmManagement/tmpl/contractGroup.html",
                controller: "contactGroupCtrl",
                parent:"Monitoring",
                active:"contractgroup",
                region:false,
                domainProject:false
            })
            .when("/monitor/strategy", {
                templateUrl: "js/monitor/strategy/tmpl/strategy.html",
                controller: "strategyCtrl",
                parent:"Monitoring",
                active:"strategy",
                region:true,
                domainProject:true
            })
            /*.when("/monitor/bosstask", {
                templateUrl: "js/monitor/boss/tmpl/bosstask.html",
                controller: "bosstaskCtrl"
            })
            .when("/monitor/fileManagement", {
                templateUrl: "js/monitor/boss/tmpl/fileManagement.html",
                controller: "fileManagementCtrl"
            })*/
            .when("/system/flavors", {
                templateUrl: "js/system/tmpl/flavors.html",
                // templateUrl: function() {
                //     return choosenTemp("js/system/tmpl/flavors.html", "js/system/tmpl/flavors_sv.html")
                // },
                controller: "FlavorsCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"flavors",
                region:false,
                domainProject:false
            })
            .when("/system/bandlimit", {
                templateUrl: "js/system/limitband/tmpl/bandwidth.html",
                controller: "bandWidthCtrl",
                parent:"System",
                active:"bandlimit",
                region:false,
                domainProject:false
            })
            .when("/system/flowManage", {
                templateUrl: "js/system/flowManage/tmpl/flowManage.html",
                controller: "flowCtrl",
                parent:"System",
                reloadOnSearch: false,
                active:"flowManage",
                region:false,
                domainProject:false
            })
            .when("/system/resMetering", {
                templateUrl: "js/system/resMetering/tmpl/resMetering.html",
                controller: "resMeteringCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"resMetering",
                region:false,
                domainProject:false
            })
            .when("/system/billingManagement", {
                templateUrl: "js/system/billingManagement/tmpl/billingManagement.html",
                controller: "billingCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"billingManagement",
                region:false,
                domainProject:false
            })
            .when("/system/switch", {
                templateUrl: "js/system/switch/tmpl/switch.html",
                controller: "switchCtrl",
                parent:"System",
                active:"switch",
                region:false,
                domainProject:false
            })
            .when("/system/aggregates", {
                templateUrl: "js/system/tmpl/aggregates.html",
                controller: "AggregatesCtrl",
                parent:"System",
                active:"aggregates",
                region:true,
                domainProject:false
            })
            .when("/system/storageManagement", {
                templateUrl: "js/system/storageManagement/tmpl/storageManagement.html",
                controller: "storageManagementCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"storageManagement",
                region:true,
                domainProject:false
            })
            .when("/system/imageManagement", {
                templateUrl: "js/system/imageManagement/tmpl/imageManage.html",
                controller: "imageManageCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"imageManagement",
                region:true,
                domainProject:false
            })
            .when("/system/hypervisors", {
                templateUrl: "js/system/tmpl/hypervisors.html",
                controller: "HypervisorsCtrl",
                parent:"System",
                active:"hypervisors",
                region:false,
                domainProject:false
            })
            .when("/system/sysbackup", {
                templateUrl: "js/system/sysBackup/tmpl/sysBackup.html",
                controller: "sysBackupCtrl",
                parent:"System",
                active:"sysbackup",
                region:false,
                domainProject:false
            })
            .when("/system/accesspolicy", {
                templateUrl: "js/system/accesspolicy/tmpl/accesspolicy.html",
                controller: "accessPolicyCtrl",
                parent:"System",
                active:"accesspolicy",
                region:false,
                domainProject:false
            })
            .when("/system/plugin", {
                templateUrl:"js/system/pluginManage/tmpl/plugin.html",
                controller: "pluginCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"plugin",
                region:true,
                domainProject:false
            })
            .when("/system/paas", {
                templateUrl:"js/settings/paasService/tmpl/paas.html",
                controller: "paasCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"paas",
                region:true,
                domainProject:false
            })
            .when("/system/license", {
                templateUrl: "js/system/license/tmpl/license.html",
                controller: "licenseCtrl",
                parent:"System",
                active:"license",
                region:true,
                domainProject:false
            })
            .when("/system/mailserver", {
                templateUrl: "js/system/mailServer/tmpl/mailServer.html",
                controller: "mailServerCtrl",
                parent:"System",
                active:"mailserver",
                region:false,
                domainProject:false
            })
            .when("/system/cephpg", {
                templateUrl: "js/system/cephpg/tmpl/cephpg.html",
                controller: "cephpgCtrl",
                parent:"System",
                active:"cephpg",
                region:true,
                domainProject:false
            })
            .when("/system/wechatserver", {
                templateUrl: "js/system/weChatAlarm/tmpl/weChatAlarm.html",
                controller: "weChatAlarmCtrl",
                parent:"System",
                active:"wechatserver",
                region:false,
                domainProject:false
            })
            .when("/system/general", {
                templateUrl: "js/settings/general/tmpl/general.html",
                controller: "generalCtrl",
                parent:"System",
                active:"general",
                region:true,
                domainProject:false
            })
            .when("/system/storagemanage", {
                templateUrl: "js/system/storage/tmpl/storageManage.html",
                controller: "storageManageCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"storagemanage",
                region:false,
                domainProject:false
            })
            // .when("/system/storagedevice", {
            //     templateUrl: "js/system/storage/tmpl/storageDevice.html",
            //     controller: "storageDeviceCtrl",
            //     reloadOnSearch: false,
            //     parent:"System",
            //     active:"storagedevice",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/system/storagedevice/:id/pool", {
            //     templateUrl: "js/system/storage/tmpl/storagePool.html",
            //     controller: "storagePoolCtrl",
            //     parent:"System",
            //     active:"storagedevice",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/system/storagefeatures", {
            //     templateUrl: "js/system/storage/tmpl/storageFeatures.html",
            //     controller: "storageFeaturesCtrl",
            //     parent:"System",
            //     active:"storagefeatures",
            //     region:false,
            //     domainProject:false
            // })
            .when("/system/volumetype", {
                templateUrl: "js/system/storage/tmpl/volumeType.html",
                controller: "volumeTypeCtrl",
                parent:"System",
                active:"volumetype",
                region:false,
                domainProject:false
            })
            .when("/system/datacluster", {
                templateUrl: "js/system/datacluster/tmpl/datacluster.html",
                controller: "dataclusterCtrl",
                parent:"System",
                active:"datacluster",
                reloadOnSearch: false,
                region:false,
                domainProject:false
            })
            .when("/system/bigmem", {
                templateUrl: "js/system/bigmem/tmpl/mem.html",
                controller: "memCtrl",
                parent:"System",
                active:"bigmem",
                reloadOnSearch: false,
                region:true,
                domainProject:false
            })
            .when("/system/sriov", {
                templateUrl: "js/system/sr-iov/tmpl/sriov.html",
                controller: "sriovCtrl",
                parent:"System",
                active:"sriov",
                reloadOnSearch: false,
                region:true,
                domainProject:false
            })
            .when("/system/dpdk", {
                templateUrl: "js/system/dpdk/tmpl/dpdk.html",
                controller: "dpdkCtrl",
                parent:"System",
                active:"dpdk",
                reloadOnSearch: false,
                region:true,
                domainProject:false
            })
            .when("/system/nettype", {
                templateUrl: "js/system/nettype/tmpl/nettype.html",
                controller: "nettypeCtrl",
                parent:"System",
                active:"nettype",
                reloadOnSearch: false,
                region:true,
                domainProject:false
            })
            .when("/view/qcloud", {
                templateUrl: "js/qcloud/instances/tmpl/instances.html",
                controller: "qcloudInstancesCtrl",
                active:"qcloud",
                region:false,
                domainProject:false
            })
            .when("/menu", {
                templateUrl: "js/menuManage/tmpl/menuManage.html",
                controller: "menuCtrl",
                active:"menu",
                region:false,
                domainProject:false
            })
            // .when("/workflow/flowtask", {
            //     templateUrl: "js/workflow/tmpl/flowtask.html",
            //     controller: "flowtaskCtrl",
            //     // resolve: {
            //     //     userGroup: function($q, workflowsrv) {
            //     //         var enterpriseUid = localStorage.enterpriseUid ? localStorage.enterpriseUid : "";
            //     //         var deferred = $q.defer();
            //     //         $q.all([workflowsrv.getUserList(enterpriseUid), workflowsrv.getGroupList(enterpriseUid)]).then(function(result) {
            //     //             if (result && result[0] && result[1]) {
            //     //                 var options = [];
            //     //                 listAllOrg(result[1].data, options);
            //     //                 options.map(item => {
            //     //                     item.levname = item.name;
            //     //                     for (let i = 0; i < item.deptLevel; i++) {
            //     //                         item.levname = "|__" + item.levname;
            //     //                     }
            //     //                 });
            //     //                 deferred.resolve({
            //     //                     user: result[0].data,
            //     //                     group: options
            //     //                 });
            //     //             }

            //     //         });
            //     //         return deferred.promise;
            //     //     }
            //     // },
            //     parent:"Flowing",
            //     active:"flowtask",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/workflow/flowapply", {
            //     templateUrl: "js/workflow/tmpl/flowapply.html",
            //     controller: "flowapplyCtrl",
            //     parent:"Flowing",
            //     active:"flowapply",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/workflow/createflow", {
            //     templateUrl: "js/workflow/tmpl/createflow.html",
            //     controller: "createflowCtrl",
            //     parent:"Flowing",
            //     active:"createflow",
            //     region:false,
            //     domainProject:false
            // })
            .when("/workflow/createflow/:modelId", {
                templateUrl: "js/workflow/tmpl/createflow.html",
                controller: "createflowCtrl",
                parent:"Flowing",
                active:"createflow",
                region:false,
                domainProject:false
            })
            // .when("/workflow/createCoustomflow", {
            //     templateUrl: "js/workflow/tmpl/createflow.html",
            //     controller: "createflowCtrl",
            //     parent:"Flowing",
            //     active:"createflow",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/workflow/createCoustomflow/createflow:modelId", {
            //     templateUrl: "js/workflow/tmpl/createflow.html",
            //     controller: "createflowCtrl",
            //     parent:"Flowing",
            //     active:"createflow",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/workflow/flowprogress", {
            //     templateUrl: "js/workflow/tmpl/flowprogress.html",
            //     controller: "flowprogressCtrl",
            //     parent:"Flowing",
            //     active:"flowprogress",
            //     region:false,
            //     domainProject:false
            // })
            // .when("/ticket/lists", {
            //     templateUrl: "js/ticket/tmpl/list.html",
            //     controller: "ticketListCtrl",
            //     reloadOnSearch: false,
            //     parent:"List",
            //     active:"lists",
            //     region:true,
            //     domainProject:false
            // })
            .when("/ticket/apply", {
                templateUrl: "js/ticket/tmpl/apply.html",
                controller: "ticketApplyCtrl",
                parent:"List",
                active:"apply",
                region:true,
                domainProject:false
            })
            .when("/ticket/create", {
                templateUrl: "js/ticket/tmpl/create.html",
                controller: "ticketCreateCtrl",
                resolve: {
                    ticketListData: function($q, workflowsrv) {
                        var enterpriseUid = localStorage.enterpriseUid ? localStorage.enterpriseUid : "";
                        return workflowsrv.getJobList(enterpriseUid);
                    }
                },
                reloadOnSearch: false,
                parent:"List",
                active:"create",
                region:true,
                domainProject:false
            })
            .when("/demotpl", {
                templateUrl: "js/demotpl/tmpl/index.html",
                controller: "demotplCtrl",
                region:false,
                domainProject:false
            })
            .when("/bill/consumeview",{
                templateUrl:"js/consumeStatistics/consumeView/tmpl/consumeview.html",
                controller:"consumeViewCtrl",
                reloadOnSearch:false,
                parent:"Bill_Management",
                active:"consumeview",
                region:false,
                domainProject:false
            })
            .when("/bill/resourceprice",{
                templateUrl:"js/resourceprice/tmpl/resoucepricelist.html",
                controller:"resoucepriceCtrl",
                parent:"Bill_Management",
                active:"resourceprice",
                region:false,
                domainProject:false
            })
            .when("/pvm/pvmview",{
                templateUrl:"js/pvm/physicalResource/tmpl/pOverview.html",
                controller:"pOverviewCtrl",
                reloadOnSearch:false,
                parent:"PhysicalResource",
                active:"pvmview",
                region:true,
                domainProject:true
            })
            .when("/pvm/pvminstances",{
                templateUrl:"js/pvm/physicalResource/tmpl/pInstances.html",
                controller:"pInstancesCtrl",
                reloadOnSearch:false,
                parent:"PhysicalResource",
                active:"pvminstances",
                region:true,
                domainProject:true
            })
            .when("/pvm/pvmimages",{
                templateUrl:"js/pvm/physicalResource/tmpl/pImages.html",
                controller:"pImagesCtrl",
                reloadOnSearch:false,
                parent:"PhysicalResource",
                active:"pvmimages",
                region:true,
                domainProject:true
            })
            .when("/pvm/pvmnetworks",{
                templateUrl:"js/pvm/physicalResource/tmpl/pNetwork.html",
                controller:"pNetworkCtrl",
                reloadOnSearch:false,
                parent:"PhysicalResource",
                active:"pvmnetworks",
                region:true,
                domainProject:true
            })
            .when("/system/physicalConductor",{
                templateUrl:"js/pvm/physicalConductor/tmpl/physicalConductor.html",
                controller:"physicalConductorCtrl",
                reloadOnSearch:false,
                parent:"System",
                active:"physicalConductor",
                region:true,
                domainProject:false
            })
            // .when("/first",{
            //     templateUrl:"js/easyReform/tmpl/first.html",
            //     controller:"easyCtrl",
            //     region:false,
            //     domainProject:false
            // })
            .when("/cvm/volumesQoS",{
                templateUrl:"js/cvm/volumes/tmpl/volumesQoS.html",
                controller:"VolumesQoSCtrl",
                parent:"Resource",
                active:"volumesQoS",
                region:true,
                domainProject:false
            })
            .when("/system/labelManagement",{
                templateUrl: "js/system/labelManagement/temp/labelManagement.html",
                controller: "labelCtrl",
                parent:"System",
                active:"labelManagement",
                region:false,
                domainProject:false
            })
            .when("/system/transmissionMag",{
                templateUrl: "js/system/transmissionMag/temp/transmissionMag.html",
                controller: "TransMagaCtrl",
                reloadOnSearch:false,
                parent:"System",
                active:"transmissionMag",
                region:true,
                domainProject:false
            })
            .when("/system/cephView",{
                templateUrl: "js/system/cephView/tmpl/cephView.html",
                controller: "cephViewCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"cephView",
                region:true,
                domainProject:false
            })
            .when("/system/vgpu",{
                templateUrl: "js/system/vgpu/tmpl/vgpu.html",
                controller: "vgpuCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"vgpu",
                region:true,
                domainProject:false
            })
            .when("/system/gpu",{
                templateUrl: "js/system/tmpl/gpu.html",
                controller: "gpuCtrl",
                parent:"System",
                active:"gpu",
                region:true,
                domainProject:false
            })
            .when("/system/cephViewInfo",{
                templateUrl: "js/system/cephView/tmpl/cephViewInfo.html",
                controller: "cephViewInfoCtrl",
                parent:"System",
                active:"cephViewInfo",
                region:false,
                domainProject:false
            })
            //待处理工单
            .when("/ticket/pendingTickets", {
                templateUrl: "js/ticket/tmpl/pendingTicket.html",
                controller: "pendingTicketCtrl",
                reloadOnSearch: false,
                parent:"List",
                active:"pendingTickets",
                region:true,
                domainProject:false
            })
            //工单申请
            .when("/ticket/ticketApply", {
                templateUrl: "js/ticket/tmpl/ticketApply.html",
                controller: "ticketsApplyCtrl",
                reloadOnSearch: false,
                parent:"List",
                active:"ticketApply",
                region:true,
                domainProject:false
            })
            //我的申请
            .when("/ticket/myApply", {
                templateUrl: "js/ticket/tmpl/myApply.html",
                controller: "myApplyCtrl",
                reloadOnSearch: false,
                parent:"List",
                active:"myApply",
                region:true,
                domainProject:false
            })
            //所有工单
            .when("/ticket/allTickets", {
                templateUrl: "js/ticket/tmpl/allTickets.html",
                controller: "allTicketsCtrl",
                reloadOnSearch: false,
                parent:"List",
                active:"allTickets",
                region:true,
                domainProject:false
            })
            //工单报表
            .when("/ticket/ticketReports", {
                templateUrl: "js/ticket/tmpl/ticketReports.html",
                controller: "ticketReportsCtrl",
                reloadOnSearch: false,
                parent:"List",
                active:"ticketReports",
                region:false,
                domainProject:false
            })
            // 容器管理
            // .when("/k8s/clusters", {
            //     templateUrl: "js/kubernetes/dockerClusters/tmpl/dockerClusters.html",
            //     controller: "dockerClustersCtrl",
            // })


            .when("/k8s/clusters", {
                templateUrl: "js/kubernetes/dockerClusters/tmpl/dockerClusters.html",
                controller: "dockerClustersCtrl",
                reloadOnSearch:false,
                parent:"K8s",
                active:"clusters",
                region:true,
                domainProject:true
            })
            // .when("/k8s/clusters/:clusterName", {
            //     templateUrl: "js/kubernetes/dockerClusters/tmpl/dockerClusterDetails.html",
            //     controller: "dockerClusterDetailCtrl",
            //     parent:"K8s",
            //     active:"clusters",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/clusters/:clusterName/replicas", {
            //     templateUrl: "js/kubernetes/dockerClusters/tmpl/dockerClusterReplicaDetails.html",
            //     controller: "dockerClusterReplicaDetailCtrl",
            //     parent:"K8s",
            //     active:"clusters",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/clusters/:clusterName/replicas/:replicaName", {
            //     templateUrl: "js/kubernetes/dockerClusters/tmpl/dockerClusterReplicaDetails.html",
            //     controller: "dockerClusterReplicaDetailCtrl",
            //     parent:"K8s",
            //     active:"clusters",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/clusters/:clusterName/replicas/:replicaName/containers", {
            //     templateUrl: "js/kubernetes/dockerClusters/tmpl/dockerContainerDetails.html",
            //     controller: "dockerContainerDetailCtrl",
            //     parent:"K8s",
            //     active:"clusters",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/clusters/:clusterName/replicas/:replicaName/containers/:containerName", {
            //     templateUrl: "js/kubernetes/dockerClusters/tmpl/dockerContainerDetails.html",
            //     controller: "dockerContainerDetailCtrl",
            //     parent:"K8s",
            //     active:"clusters",
            //     region:true,
            //     domainProject:true
            // })
            .when("/k8s/services", {
                templateUrl: "js/kubernetes/dockerServices/tmpl/dockerServices.html",
                controller: "dockerServicesCtrl",
                reloadOnSearch:false,
                parent:"K8s",
                active:"services",
                region:true,
                domainProject:true
            })
            // .when("/k8s/services/:serviceName", {
            //     templateUrl: "js/kubernetes/dockerServices/tmpl/dockerServiceDetails.html",
            //     controller: "dockerServiceDetailsCtrl",
            //     parent:"K8s",
            //     active:"services",
            //     region:true,
            //     domainProject:true
            // })
            .when("/k8s/projects", {
                templateUrl: "js/kubernetes/dockerImages/tmpl/harborProjects.html",
                controller: "harborProjectsCtrl",
                reloadOnSearch:false,
                parent:"K8s",
                active:"projects",
                region:true,
                domainProject:true
            })
            // .when("/k8s/projects/:projectId", {
            //     templateUrl: "js/kubernetes/dockerImages/tmpl/harborProjectDetails.html",
            //     controller: "harborProjectDetailsCtrl",
            //     parent:"K8s",
            //     active:"projects",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/projects/:projectId/repositories/:repoName", {
            //     templateUrl: "js/kubernetes/dockerImages/tmpl/dockerImages.html",
            //     controller: "dockerImagesCtrl",
            //     parent:"K8s",
            //     active:"projects",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/projects/:projectId/repositories/:repoName/tags/:tag", {
            //     templateUrl: "js/kubernetes/dockerImages/tmpl/dockerImageManifests.html",
            //     controller: "dockerImageManifestsCtrl",
            //     parent:"K8s",
            //     active:"projects",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/nodes", {
            //     templateUrl: "js/kubernetes/dockerNodes/tmpl/dockerNodes.html",
            //     controller: "dockerNodesCtrl",
            //     reloadOnSearch: false,
            //     parent:"K8s",
            //     active:"projects",
            //     region:true,
            //     domainProject:true
            // })
            // .when("/k8s/nodes/:nodeName", {
            //     templateUrl: "js/kubernetes/dockerNodes/tmpl/dockerNodes.html",
            //     controller: "dockerNodeDetailsCtrl"
            // })
            // .when("/console", {
            //     templateUrl:  "js/cvm/console/tmpl/index.html",
            //     controller: "consoleCtrl",
            // })
            /*灵雀*/
            .when("/alauda/overview", {
                templateUrl: "js/paas/tmpl/alauda_1.html",
                controller: "alauda_1Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/overview",
                active:"overview",
                region:false,
                domainProject:false
            })
            .when("/alauda/deploy", {
                templateUrl: "js/paas/tmpl/alauda_2.html",
                controller: "alauda_2Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/deployment/list",
                active:"deploy",
                region:false,
                domainProject:false
            })
            .when("/alauda/daemon", {
                templateUrl: "js/paas/tmpl/alauda_3.html",
                controller: "alauda_3Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/daemonset/list",
                active:"daemon",
                region:false,
                domainProject:false
            })
            .when("/alauda/stateful", {
                templateUrl: "js/paas/tmpl/alauda_4.html",
                controller: "alauda_4Ctrl",
                rtype:"PaaS",
                jump:"/statefulset/list",
                parent:"Alauda",
                active:"stateful",
                region:false,
                domainProject:false
            })
            .when("/alauda/replica", {
                templateUrl: "js/paas/tmpl/alauda_5.html",
                controller: "alauda_5Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/replicaset/list",
                active:"replica",
                region:false,
                domainProject:false
            })
            .when("/alauda/pod", {
                templateUrl: "js/paas/tmpl/alauda_6.html",
                controller: "alauda_6Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/pod/list",
                active:"pod",
                region:false,
                domainProject:false
            })
            .when("/alauda/configmap", {
                templateUrl: "js/paas/tmpl/alauda_7.html",
                controller: "alauda_7Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/configmap/list",
                active:"configmap",
                region:false,
                domainProject:false
            })
            .when("/alauda/secret", {
                templateUrl: "js/paas/tmpl/alauda_8.html",
                controller: "alauda_8Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/secret/list",
                active:"secret",
                region:false,
                domainProject:false
            })
            .when("/alauda/service", {
                templateUrl: "js/paas/tmpl/alauda_9.html",
                controller: "alauda_9Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/service/list",
                active:"service",
                region:false,
                domainProject:false
            })
            .when("/alauda/ingress", {
                templateUrl: "js/paas/tmpl/alauda_10.html",
                controller: "alauda_10Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/ingress/list",
                active:"ingress",
                region:false,
                domainProject:false
            })
            .when("/alauda/networkpolicy", {
                templateUrl: "js/paas/tmpl/alauda_11.html",
                controller: "alauda_11Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/networkpolicy/list",
                active:"networkpolicy",
                region:false,
                domainProject:false
            })
            .when("/alauda/persistentvolumeclaim", {
                templateUrl: "js/paas/tmpl/alauda_12.html",
                controller: "alauda_12Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/persistentvolumeclaim/list",
                active:"persistentvolumeclaim",
                region:false,
                domainProject:false
            })
            .when("/alauda/persistentvolume", {
                templateUrl: "js/paas/tmpl/alauda_13.html",
                controller: "alauda_13Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/persistentvolume/list",
                active:"persistentvolume",
                region:false,
                domainProject:false
            })
            .when("/alauda/storageclass", {
                templateUrl: "js/paas/tmpl/alauda_14.html",
                controller: "alauda_14Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/storageclass/list",
                active:"storageclass",
                region:false,
                domainProject:false
            })
            .when("/alauda/node", {
                templateUrl: "js/paas/tmpl/alauda_15.html",
                controller: "alauda_15Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/node/list",
                active:"node",
                region:false,
                domainProject:false
            })
            .when("/alauda/namespace", {
                templateUrl: "js/paas/tmpl/alauda_16.html",
                controller: "alauda_16Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/namespace/list",
                active:"namespace",
                region:false,
                domainProject:false
            })
            .when("/alauda/othersspaced", {
                templateUrl: "js/paas/tmpl/alauda_17.html",
                controller: "alauda_17Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/others/namespaced/list",
                active:"othersspaced",
                region:false,
                domainProject:false
            })
            .when("/alauda/clustered", {
                templateUrl: "js/paas/tmpl/alauda_18.html",
                controller: "alauda_18Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/others/clustered/list",
                active:"clustered",
                region:false,
                domainProject:false
            })
            .when("/alauda/roles", {
                templateUrl: "js/paas/tmpl/alauda_19.html",
                controller: "alauda_19Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/administrator/roles/list",
                active:"roles",
                region:false,
                domainProject:false
            })
            .when("/alauda/bindings", {
                templateUrl: "js/paas/tmpl/alauda_20.html",
                controller: "alauda_20Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/administrator/role-bindings/list",
                active:"bindings",
                region:false,
                domainProject:false
            })
            
            /*TBase*/
            .when("/TBase/tbClusterManage", {
                templateUrl: "js/paas/tmpl/alauda_21.html",
                controller: "alauda_21Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/operation/clusterManage",
                active:"tbClusterManage",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbNodeManage", {
                templateUrl: "js/paas/tmpl/alauda_22.html",
                controller: "alauda_22Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/operation/nodeManage",
                active:"tbNodeManage",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbNodeGroup", {
                templateUrl: "js/paas/tmpl/alauda_23.html",
                controller: "alauda_23Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/operation/nodeGroup",
                active:"tbNodeGroup",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbConfigManagement", {
                templateUrl: "js/paas/tmpl/alauda_24.html",
                controller: "alauda_24Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/operation/configManagement#!/system",
                active:"tbConfigManagement",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbBackupSetting", {
                templateUrl: "js/paas/tmpl/alauda_25.html",
                controller: "alauda_25Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/operation/backupSetting",
                active:"tbBackupSetting",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbBackupCheck", {
                templateUrl: "js/paas/tmpl/alauda_26.html",
                controller: "alauda_26Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/operation/backupCheck#!/backup",
                active:"tbBackupCheck",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbClusterMonitor", {
                templateUrl: "js/paas/tmpl/alauda_27.html",
                controller: "alauda_27Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/monitor/clusterMonitor",
                active:"tbClusterMonitor",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbNodeMonitor", {
                templateUrl: "js/paas/tmpl/alauda_28.html",
                controller: "alauda_28Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/monitor/nodeMonitor",
                active:"tbNodeMonitor",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbVolumeMonitor", {
                templateUrl: "js/paas/tmpl/alauda_29.html",
                controller: "alauda_29Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/monitor/volumeMonitor",
                active:"tbVolumeMonitor",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbAlarmLists", {
                templateUrl: "js/paas/tmpl/alauda_30.html",
                controller: "alauda_30Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/alert_middleware/lists",
                active:"tbAlarmLists",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbAlarmRecords", {
                templateUrl: "js/paas/tmpl/alauda_31.html",
                controller: "alauda_31Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/alert_middleware/records",
                active:"tbAlarmRecords",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbAlarmUsers", {
                templateUrl: "js/paas/tmpl/alauda_32.html",
                controller: "alauda_32Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/alert_middleware/users",
                active:"tbAlarmUsers",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbAlarmMethods", {
                templateUrl: "js/paas/tmpl/alauda_33.html",
                controller: "alauda_33Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/alert_middleware/methods",
                active:"tbAlarmMethods",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbSystemInfo", {
                templateUrl: "js/paas/tmpl/alauda_34.html",
                controller: "alauda_34Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/sys/systemInfo",
                active:"tbSystemInfo",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbLicenseInfo", {
                templateUrl: "js/paas/tmpl/alauda_35.html",
                controller: "alauda_35Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/sys/licenseInfo",
                active:"tbLicenseInfo",
                region:false,
                domainProject:false
            })
            .when("/TBase/tbUserList", {
                templateUrl: "js/paas/tmpl/alauda_36.html",
                controller: "alauda_36Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"/user/userList",
                active:"tbUserList",
                region:false,
                domainProject:false
            })
            /*TDsql*/
            .when("/TDsql/tdHome", {
                templateUrl: "js/paas/tmpl/alauda_37.html",
                controller: "alauda_37Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=home",
                active:"tdHome",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdInstanceManage", {
                templateUrl: "js/paas/tmpl/alauda_38.html",
                controller: "alauda_38Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=instance/manage",
                active:"tdInstanceManage",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdResourceDevice", {
                templateUrl: "js/paas/tmpl/alauda_39.html",
                controller: "alauda_39Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=resource/device",
                active:"tdResourceDevice",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdResourceSpec", {
                templateUrl: "js/paas/tmpl/alauda_40.html",
                controller: "alauda_40Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=resource/spec",
                active:"tdResourceSpec",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdResourceIdc", {
                templateUrl: "js/paas/tmpl/alauda_41.html",
                controller: "alauda_41Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=resource/idc",
                active:"tdResourceIdc",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorMysql", {
                templateUrl: "js/paas/tmpl/alauda_42.html",
                controller: "alauda_42Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/mysql",
                active:"tdMonitorMysql",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdProxyBase", {
                templateUrl: "js/paas/tmpl/alauda_43.html",
                controller: "alauda_43Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=proxy/base",
                active:"tdProxyBase",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdVipLvsgroup", {
                templateUrl: "js/paas/tmpl/alauda_44.html",
                controller: "alauda_44Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=lvs/vip",
                active:"tdVipLvsgroup",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdVipLvsmachine", {
                templateUrl: "js/paas/tmpl/alauda_45.html",
                controller: "alauda_45Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=lvs/machine",
                active:"tdVipLvsmachine",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorZookeeper", {
                templateUrl: "js/paas/tmpl/alauda_46.html",
                controller: "alauda_46Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/zookeeper",
                active:"tdMonitorZookeeper",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorScheduler", {
                templateUrl: "js/paas/tmpl/alauda_47.html",
                controller: "alauda_47Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/scheduler",
                active:"tdMonitorScheduler",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorManager", {
                templateUrl: "js/paas/tmpl/alauda_48.html",
                controller: "alauda_48Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/manager",
                active:"tdMonitorManager",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorOssserver", {
                templateUrl: "js/paas/tmpl/alauda_49.html",
                controller: "alauda_49Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/ossserver",
                active:"tdMonitorOssserver",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorOssjob", {
                templateUrl: "js/paas/tmpl/alauda_50.html",
                controller: "alauda_50Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/ossjob",
                active:"tdMonitorOssjob",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorOsslog", {
                templateUrl: "js/paas/tmpl/alauda_51.html",
                controller: "alauda_51Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/osslog",
                active:"tdMonitorOsslog",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorHdfs", {
                templateUrl: "js/paas/tmpl/alauda_52.html",
                controller: "alauda_52Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/hdfs",
                active:"tdMonitorHdfs",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorSyncjob", {
                templateUrl: "js/paas/tmpl/alauda_53.html",
                controller: "alauda_53Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/syncjob",
                active:"tdMonitorSyncjob",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitorKafka", {
                templateUrl: "js/paas/tmpl/alauda_54.html",
                controller: "alauda_54Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/kafka",
                active:"tdMonitorKafka",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdOsswf", {
                templateUrl: "js/paas/tmpl/alauda_55.html",
                controller: "alauda_55Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=osswf",
                active:"tdOsswf",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdAlarmSearch", {
                templateUrl: "js/paas/tmpl/alauda_56.html",
                controller: "alauda_56Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=alarm_search",
                active:"tdAlarmSearch",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdAlarmConfig", {
                templateUrl: "js/paas/tmpl/alauda_57.html",
                controller: "alauda_57Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=alarm_config",
                active:"tdAlarmConfig",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdAlarmExcept", {
                templateUrl: "js/paas/tmpl/alauda_58.html",
                controller: "alauda_58Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=except",
                active:"tdAlarmExcept",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdAlarmGlobal", {
                templateUrl: "js/paas/tmpl/alauda_59.html",
                controller: "alauda_59Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=alarm_global",
                active:"tdAlarmGlobal",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdClusterManage", {
                templateUrl: "js/paas/tmpl/alauda_60.html",
                controller: "alauda_60Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=cluster/manage",
                active:"tdClusterManage",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdBusinessUserDatabaseApply", {
                templateUrl: "js/paas/tmpl/alauda_61.html",
                controller: "alauda_61Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=business/user_database_apply",
                active:"tdBusinessUserDatabaseApply",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdBusinessUserDbclusterGrant", {
                templateUrl: "js/paas/tmpl/alauda_62.html",
                controller: "alauda_62Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=business/user_dbcluster_grant",
                active:"tdBusinessUserDbclusterGrant",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdSysUser", {
                templateUrl: "js/paas/tmpl/alauda_63.html",
                controller: "alauda_63Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=sys/user",
                active:"tdSysUser",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdSysRole", {
                templateUrl: "js/paas/tmpl/alauda_64.html",
                controller: "alauda_64Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=sys/role",
                active:"tdSysRole",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdSysLog", {
                templateUrl: "js/paas/tmpl/alauda_65.html",
                controller: "alauda_65Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=sys/log",
                active:"tdSysLog",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdSysVersion", {
                templateUrl: "js/paas/tmpl/alauda_66.html",
                controller: "alauda_66Ctrl",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=sys/version",
                active:"tdSysVersion",
                region:false,
                domainProject:false
            })
            //tdsql 新增功能
            .when("/TDsql/tdCapacity", {
                templateUrl: "js/paas/tmpl/tdCapacity.html",
                controller: "tdCapacity",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=resource/capacity",
                active:"tdCapacity",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdVipgroup", {
                templateUrl: "js/paas/tmpl/tdVipgroup.html",
                controller: "tdVipgroup",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=lvs/vipgroup",
                active:"tdVipgroup",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdVipdns", {
                templateUrl: "js/paas/tmpl/tdVipdns.html",
                controller: "tdVipdns",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=dns",
                active:"tdVipdns",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdMonitordns", {
                templateUrl: "js/paas/tmpl/tdMonitordns.html",
                controller: "tdMonitordns",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=monitor/dns",
                active:"tdMonitordns",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdDcnjob", {
                templateUrl: "js/paas/tmpl/tdDcnjob.html",
                controller: "tdDcnjob",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=city_sync/dcnjob",
                active:"tdDcnjob",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdSyncjob", {
                templateUrl: "js/paas/tmpl/tdSyncjob.html",
                controller: "tdSyncjob",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=city_sync/syncjob",
                active:"tdSyncjob",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdClusteradmin", {
                templateUrl: "js/paas/tmpl/tdClusteradmin.html",
                controller: "tdClusteradmin",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=city_sync/syncjob",
                active:"tdClusteradmin",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdUpgrade", {
                templateUrl: "js/paas/tmpl/tdUpgrade.html",
                controller: "tdUpgrade",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=sys/upgrade",
                active:"tdUpgrade",
                region:false,
                domainProject:false
            })
            .when("/TDsql/tdAutotest", {
                templateUrl: "js/paas/tmpl/tdAutotest.html",
                controller: "tdAutotest",
                parent:"TDSQL",
                rtype:"PaaS",
                jump:"/tdsqlpcloud/?ctrl=autotest",
                active:"tdAutotest",
                region:false,
                domainProject:false
            })

            //关系型数据库TDSQL
            .when("/database/relationalTdsql", {
                templateUrl: "js/paas/tdsql/tmpl/tdsql.html",
                controller: "tdsqlCtrl",
                reloadOnSearch: false,
                parent:"DatabaseManage",
                active:"relationalTdsql",
                region:true,
                domainProject:true
            })
            .when("/database/relationalTdsqls", {
                templateUrl: "js/paas/tdsql/tmpl/tdsql.html",
                controller: "tdsqlCtrl",
                reloadOnSearch: false,
                rtype:"PaaS",
                parent:"TDSQL",
                active:"relationalTdsqls",
                region:true,
                domainProject:true
            })

            /*TSF*/
            .when("/tsf/Overview", {
                templateUrl: "js/paas/tmpl/tsf_overview.html",
                controller: "tsf_Overview",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf",
                active:"tsfOverview",
                region:false,
                domainProject:false
            })

            .when("/tsf/Cluster", {
                templateUrl: "js/paas/tmpl/tsf_cluster.html",
                controller: "tsf_Cluster",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/cluster",
                active:"tsfCluster",
                region:false,
                domainProject:false
            })

            .when("/tsf/Namespace", {
                templateUrl: "js/paas/tmpl/tsf_namespace.html",
                controller: "tsf_Namesapce",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/namespace",
                active:"tsfNamespace",
                region:false,
                domainProject:false
            })
            .when("/tsf/Deploy", {
                templateUrl: "js/paas/tmpl/tsf_deploy.html",
                controller: "TSF_Deploy",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/group",
                active:"tsfDeploy",
                region:false,
                domainProject:false
            })
            .when("/tsf/AppManagement", {
                templateUrl: "js/paas/tmpl/tsf_appmanagement.html",
                controller: "TSF_AppManagement",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/app",
                active:"tsfAppManagement",
                region:false,
                domainProject:false
            })
            .when("/tsf/Service", {
                templateUrl: "js/paas/tmpl/tsf_service.html",
                controller: "TSF_Service",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/service",
                active:"tsfService",
                region:false,
                domainProject:false
            })
            .when("/tsf/AppConfig", {
                templateUrl: "js/paas/tmpl/tsf_appconfig.html",
                controller: "TSF_AppConfig",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/config-app",
                active:"tsfAppConfig",
                region:false,
                domainProject:false
            })
            .when("/tsf/GlobalConfig", {
                templateUrl: "js/paas/tmpl/tsf_globalconfig.html",
                controller: "TSF_GlobalConfig",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/config",
                active:"tsfGlobalConfig",
                region:false,
                domainProject:false
            })
            .when("/tsf/Template", {
                templateUrl: "js/paas/tmpl/tsf_template.html",
                controller: "TSF_Template",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/config-template",
                active:"tsfTemplate",
                region:false,
                domainProject:false
            })
            .when("/tsf/ConfigFile", {
                templateUrl: "js/paas/tmpl/tsf_configfile.html",
                controller: "TSF_ConfigFile",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/config-file",
                active:"tsfConfigFile",
                region:false,
                domainProject:false
            })
            .when("/tsf/LogConfig", {
                templateUrl: "js/paas/tmpl/tsf_logconfig.html",
                controller: "TSF_LogConfig",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/log-config",
                active:"tsfLogConfig",
                region:false,
                domainProject:false
            })
            .when("/tsf/GatewayList", {
                templateUrl: "js/paas/tmpl/tsf_gatewaylist.html",
                controller: "TSF_GatewayList",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/gateway-list",
                active:"tsfGatewayList",
                region:false,
                domainProject:false
            })
           .when("/tsf/GatewayGroup", {
                templateUrl: "js/paas/tmpl/tsf_gatewaygroup.html",
                controller: "TSF_GatewayGroup",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/api-group-list",
                active:"tsfGatewayGroup",
                region:false,
                domainProject:false
            })
           .when("/tsf/LogSearch", {
                templateUrl: "js/paas/tmpl/tsf_logsearch.html",
                controller: "TSF_LogSearch",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/log-search",
                active:"tsfLogSearch",
                region:false,
                domainProject:false
            })
           .when("/tsf/Transaction", {
                templateUrl: "js/paas/tmpl/tsf_transaction.html",
                controller: "TSF_Transaction",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/transaction",
                active:"tsfTransaction",
                region:false,
                domainProject:false
            })
            .when("/tsf/Topology", {
                templateUrl: "js/paas/tmpl/tsf_topology.html",
                controller: "TSF_Topology",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/topology",
                active:"tsfTopology",
                region:false,
                domainProject:false
            })
            .when("/tsf/Trace", {
                templateUrl: "js/paas/tmpl/tsf_trace.html",
                controller: "TSF_Trace",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/trace",
                active:"tsfTrace",
                region:false,
                domainProject:false
            })
            .when("/tsf/Alarm", {
                templateUrl: "js/paas/tmpl/tsf_alarm.html",
                controller: "TSF_Alarm",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/alarm",
                active:"tsfAlarm",
                region:false,
                domainProject:false
            })
            .when("/tsf/Scalable", {
                templateUrl: "js/paas/tmpl/tsf_scalable.html",
                controller: "TSF_Scalable",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/scalable",
                active:"tsfScalable",
                region:false,
                domainProject:false
            })
            .when("/tsf/Image", {
                templateUrl: "js/paas/tmpl/tsf_image.html",
                controller: "TSF_Image",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/image",
                active:"tsfImage",
                region:false,
                domainProject:false
            })
            .when("/tsf/Package", {
                templateUrl: "js/paas/tmpl/tsf_software.html",
                controller: "TSF_Package",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/package",
                active:"tsfPackage",
                region:false,
                domainProject:false
            })
            .when("/tsf/ServiceTemplate", {
                templateUrl: "js/paas/tmpl/tsf_servicemanager.html",
                controller: "TSF_ServiceTemplate",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf/template",
                active:"tsfServiceTemplate",
                region:false,
                domainProject:false
            })
            .when("/tsf/ManagerBase", {
                templateUrl: "js/paas/tmpl/tsf_basemoudle.html",
                controller: "TSF_ManagerBase",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf-manager/basic",
                active:"tsfManagerBase",
                region:false,
                domainProject:false
            })
            .when("/tsf/ManagerZone", {
                templateUrl: "js/paas/tmpl/tsf_zone.html",
                controller: "TSF_ManagerZone",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf-manager/zone",
                active:"tsfManagerZone",
                region:false,
                domainProject:false
            })
            .when("/tsf/OpsServer", {
                templateUrl: "js/paas/tmpl/tsf_opsserver.html",
                controller: "TSF_OPSServer",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf-manager/ops-server",
                active:"tsfOpsServer",
                region:false,
                domainProject:false
            })
            .when("/tsf/ManagerParam", {
                templateUrl: "js/paas/tmpl/tsf_param.html",
                controller: "TSF_ManagerParam",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf-manager/config-params",
                active:"tsfManagerParam",
                region:false,
                domainProject:false
            })
            .when("/tsf/ManagerGuide", {
                templateUrl: "js/paas/tmpl/tsf_guide.html",
                controller: "TSF_ManagerGuide",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf-manager/guide",
                active:"tsfManagerGuide",
                region:false,
                domainProject:false
            })
            .when("/tsf/ManagerLicense", {
                templateUrl: "js/paas/tmpl/tsf_license.html",
                controller: "TSF_License",
                parent:"TSF",
                rtype:"PaaS",
                jump:"/tsf-manager/license",
                active:"tsfManagerLicense",
                region:false,
                domainProject:false
            })


            /*CTsdb*/
            .when("/CTsdb/ctInstancesList", {
                templateUrl: "js/paas/tmpl/alauda_67.html",
                controller: "alauda_67Ctrl",
                parent:"CTSDB",
                rtype:"PaaS",
                jump:"/",
                active:"ctInstancesList",
                region:false,
                domainProject:false
            })
            .when("/CTsdb/ctOperationInsManage", {
                templateUrl: "js/paas/tmpl/alauda_68.html",
                controller: "alauda_68Ctrl",
                parent:"CTSDB",
                rtype:"PaaS",
                jump:"/#/admin/instance",
                active:"ctOperationInsManage",
                region:false,
                domainProject:false
            })
            .when("/CTsdb/ctProcessManage", {
                templateUrl: "js/paas/tmpl/alauda_69.html",
                controller: "alauda_69Ctrl",
                parent:"CTSDB",
                rtype:"PaaS",
                jump:"/#/task",
                active:"ctProcessManage",
                region:false,
                domainProject:false
            })
            .when("/CTsdb/ctEquipmentManage", {
                templateUrl: "js/paas/tmpl/alauda_70.html",
                controller: "alauda_70Ctrl",
                parent:"CTSDB",
                rtype:"PaaS",
                jump:"/#/admin/machine",
                active:"ctEquipmentManage",
                region:false,
                domainProject:false
            })
            .when("/CTsdb/ctInstancesMonitor", {
                templateUrl: "js/monitor/resource/tmpl/sqlDatabase.html",
                controller: "sqlDatabaseCtrl",
                reloadOnSearch: false,
                parent:"CTSDB",
                rtype:"PaaS",
                active:"ctInstancesMonitor",
                region:true,
                domainProject:false
            })
            .when("/TBase/TStudio", {
                templateUrl: "js/paas/tmpl/alauda_71.html",
                controller: "alauda_71Ctrl",
                parent:"TBase",
                rtype:"PaaS",
                jump:"",
                active:"TStudio",
                region:false,
                domainProject:false
            })

            /*消息队列*/
            .when("/CMQ/Server", {
                templateUrl: "js/paas/tmpl/cmq_server.html",
                controller: "cmqServer",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq/index",
                active:"cmqServer",
                region:false,
                domainProject:false
            })
            .when("/CMQ/Topic", {
                templateUrl: "js/paas/tmpl/cmq_topic.html",
                controller: "cmqTopic",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq/topic",
                active:"cmqTopic",
                region:false,
                domainProject:false
            })
            .when("/CMQ/Zone", {
                templateUrl: "js/paas/tmpl/cmq_zone.html",
                controller: "cmqZone",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq-manager/zone",
                active:"cmqZone",
                region:false,
                domainProject:false
            })
            .when("/CMQ/CMQCluster", {
                templateUrl: "js/paas/tmpl/cmq_cluster.html",
                controller: "cmqCluster",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq-manager/index",
                active:"cmqCluster",
                region:false,
                domainProject:false
            })
            .when("/CMQ/Signaling", {
                templateUrl: "js/paas/tmpl/cmq_signaling.html",
                controller: "cmqSignaling",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq-manager/signaling",
                active:"cmqSignaling",
                region:false,
                domainProject:false
            })
            .when("/CMQ/Access", {
                templateUrl: "js/paas/tmpl/cmq_access.html",
                controller: "cmqAccess",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq-manager/access",
                active:"cmqAccess",
                region:false,
                domainProject:false
            })
            .when("/CMQ/Publisher", {
                templateUrl: "js/paas/tmpl/cmq_publisher.html",
                controller: "cmqPublisher",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq-manager/publisher",
                active:"cmqPublisher",
                region:false,
                domainProject:false
            })
            .when("/CMQ/Queue", {
                templateUrl: "js/paas/tmpl/cmq_queue.html",
                controller: "cmqQueue",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq-manager/queue",
                active:"cmqQueue",
                region:false,
                domainProject:false
            })
            .when("/CMQ/ManagerTopic", {
                templateUrl: "js/paas/tmpl/cmq_managerTopic.html",
                controller: "cmqManagerTopic",
                parent:"CMQ",
                rtype:"PaaS",
                jump:"/cmq-manager/topic",
                active:"cmqManagerTopic",
                region:false,
                domainProject:false
            })

            /*COC*/
            .when("/COC/cocCmdbServer", {
                templateUrl: "js/paas/tmpl/alauda_82.html",
                controller: "alauda_82Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cmdb/server",
                active:"cocCmdbServer",
                region:false,
                domainProject:false
            })
            .when("/COC/cocCmdbNetDevice", {
                templateUrl: "js/paas/tmpl/alauda_83.html",
                controller: "alauda_83Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cmdb/netdevice",
                active:"cocCmdbNetDevice",
                region:false,
                domainProject:false
            })
            .when("/COC/cocCmdbIdc", {
                templateUrl: "js/paas/tmpl/alauda_84.html",
                controller: "alauda_84Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cmdb/idc",
                active:"cocCmdbIdc",
                region:false,
                domainProject:false
            })
            .when("/COC/cocCmdbIdcExport", {
                templateUrl: "js/paas/tmpl/alauda_85.html",
                controller: "alauda_85Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cmdb/idcexport",
                active:"cocCmdbIdcExport",
                region:false,
                domainProject:false
            })
            // .when("/COC/cocCmdbIdcExport", {
            //     templateUrl: "js/paas/tmpl/alauda_86.html",
            //     controller: "alauda_86Ctrl",
            //     parent:"COC",
            //     rtype:"PaaS",
            //     jump:"/cmdb/idcexport",
            //     active:"cocCmdbIdcExport",
            //     region:false,
            //     domainProject:false
            // })
            .when("/COC/cocCmdbIdcLine", {
                templateUrl: "js/paas/tmpl/alauda_87.html",
                controller: "alauda_87Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cmdb/idcline",
                active:"cocCmdbIdcLine",
                region:false,
                domainProject:false
            })
            .when("/COC/cocCmdbCustomCategory", {
                templateUrl: "js/paas/tmpl/alauda_88.html",
                controller: "alauda_88Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cmdb/custom/category",
                active:"cocCmdbCustomCategory",
                region:false,
                domainProject:false
            })
            .when("/COC/cocModuleManage", {
                templateUrl: "js/paas/tmpl/alauda_89.html",
                controller: "alauda_89Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/module/manage",
                active:"cocModuleManage",
                region:false,
                domainProject:false
            })
            .when("/COC/cocMonitorServer", {
                templateUrl: "js/paas/tmpl/alauda_90.html",
                controller: "alauda_90Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/basemonitor/serverlist",
                active:"cocMonitorServer",
                region:false,
                domainProject:false
            })
            .when("/COC/cocMonitorNetdevice", {
                templateUrl: "js/paas/tmpl/alauda_91.html",
                controller: "alauda_91Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/basemonitor/netdevicelist",
                active:"cocMonitorNetdevice",
                region:false,
                domainProject:false
            })
            .when("/COC/cocMonitorNetoutpoint", {
                templateUrl: "js/paas/tmpl/alauda_92.html",
                controller: "alauda_92Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/basemonitor/netoutpointlist",
                active:"cocMonitorNetoutpoint",
                region:false,
                domainProject:false
            })
            .when("/COC/cocMonitorNetspecialLine", {
                templateUrl: "js/paas/tmpl/alauda_93.html",
                controller: "alauda_93Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/basemonitor/netspeciallinelist",
                active:"cocMonitorNetspecialLine",
                region:false,
                domainProject:false
            })
            .when("/COC/cocMonitorMiddleware", {
                templateUrl: "js/paas/tmpl/alauda_94.html",
                controller: "alauda_94Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/basemonitor/middleware",
                active:"cocMonitorMiddleware",
                region:false,
                domainProject:false
            })
            .when("/COC/cocMonitorSelfdefineMonitor", {
                templateUrl: "js/paas/tmpl/alauda_95.html",
                controller: "alauda_95Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/basemonitor/selfdefinemonitor",
                active:"cocMonitorSelfdefineMonitor",
                region:false,
                domainProject:false
            })
            .when("/COC/cocMonitorCustomBoard", {
                templateUrl: "js/paas/tmpl/alauda_96.html",
                controller: "alauda_96Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/basemonitor/customboard/board",
                active:"cocMonitorCustomBoard",
                region:false,
                domainProject:false
            })
            .when("/COC/cocAlarmOverview", {
                templateUrl: "js/paas/tmpl/alauda_97.html",
                controller: "alauda_97Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/alarm/overview",
                active:"cocAlarmOverview",
                region:false,
                domainProject:false
            })
            .when("/COC/cocAlarmSearch", {
                templateUrl: "js/paas/tmpl/alauda_98.html",
                controller: "alauda_98Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/alarm/searchlist",
                active:"cocAlarmSearch",
                region:false,
                domainProject:false
            })
            .when("/COC/cocAlarmStrategy", {
                templateUrl: "js/paas/tmpl/alauda_99.html",
                controller: "alauda_99Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/alarm/strategylist",
                active:"cocAlarmStrategy",
                region:false,
                domainProject:false
            })
            .when("/COC/cocAlarmShield", {
                templateUrl: "js/paas/tmpl/alauda_100.html",
                controller: "alauda_100Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/alarm/shield",
                active:"cocAlarmShield",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketToolist", {
                templateUrl: "js/paas/tmpl/alauda_101.html",
                controller: "alauda_101Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/toollist",
                active:"cocToolmarketToolist",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketPublicAll", {
                templateUrl: "js/paas/tmpl/alauda_102.html",
                controller: "alauda_102Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/public?tag=所有",
                active:"cocToolmarketPublicAll",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketPublicInit", {
                templateUrl: "js/paas/tmpl/alauda_103.html",
                controller: "alauda_103Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/public?tag=初始化",
                active:"cocToolmarketPublicInit",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketPublicDiagnose", {
                templateUrl: "js/paas/tmpl/alauda_104.html",
                controller: "alauda_104Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/public?tag=诊断",
                active:"cocToolmarketPublicDiagnose",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketPublicRepaire", {
                templateUrl: "js/paas/tmpl/alauda_105.html",
                controller: "alauda_105Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/public?tag=维护",
                active:"cocToolmarketPublicRepaire",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketPublicDown", {
                templateUrl: "js/paas/tmpl/alauda_106.html",
                controller: "alauda_106Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/public?tag=下架",
                active:"cocToolmarketPublicDown",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketFlowlist", {
                templateUrl: "js/paas/tmpl/alauda_107.html",
                controller: "alauda_107Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/flowlist",
                active:"cocToolmarketFlowlist",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketSchedulelist", {
                templateUrl: "js/paas/tmpl/alauda_108.html",
                controller: "alauda_108Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/schedulelist",
                active:"cocToolmarketSchedulelist",
                region:false,
                domainProject:false
            })
            .when("/COC/cocToolmarketLoglist", {
                templateUrl: "js/paas/tmpl/alauda_109.html",
                controller: "alauda_109Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toolmarket/loglist",
                active:"cocToolmarketLoglist",
                region:false,
                domainProject:false
            })
            .when("/COC/cocPackageList", {
                templateUrl: "js/paas/tmpl/alauda_110.html",
                controller: "alauda_110Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/package/list",
                active:"cocPackageList",
                region:false,
                domainProject:false
            })
            .when("/COC/cocCloudServer", {
                templateUrl: "js/paas/tmpl/alauda_111.html",
                controller: "alauda_111Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cloud/server",
                active:"cocCloudServer",
                region:false,
                domainProject:false
            })
            .when("/COC/cocCloudUsers", {
                templateUrl: "js/paas/tmpl/alauda_112.html",
                controller: "alauda_112Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/cloud/users",
                active:"cocCloudUsers",
                region:false,
                domainProject:false
            })
            .when("/COC/cocTenantuserUserlist", {
                templateUrl: "js/paas/tmpl/alauda_113.html",
                controller: "alauda_113Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/tenancy/userlist2",
                active:"cocTenantuserUserlist",
                region:false,
                domainProject:false
            })
            .when("/COC/cocTenantuserGrouplist", {
                templateUrl: "js/paas/tmpl/alauda_114.html",
                controller: "alauda_114Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/tenantuser/grouplist",
                active:"cocTenantuserGrouplist",
                region:false,
                domainProject:false
            })
            .when("/COC/cocTenantuserAuthlist", {
                templateUrl: "js/paas/tmpl/alauda_115.html",
                controller: "alauda_115Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/tenantuser/authlist",
                active:"cocTenantuserAuthlist",
                region:false,
                domainProject:false
            })
            .when("/COC/cocPasswordlib", {
                templateUrl: "js/paas/tmpl/alauda_116.html",
                controller: "alauda_116Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/passwordlib",
                active:"cocPasswordlib",
                region:false,
                domainProject:false
            })
            .when("/COC/cocOperationlog", {
                templateUrl: "js/paas/tmpl/alauda_117.html",
                controller: "alauda_117Ctrl",
                parent:"COC",
                rtype:"PaaS",
                jump:"/toollib/operationlog",
                active:"cocOperationlog",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMyApply", {
                templateUrl: "js/paas/tmpl/alauda_118.html",
                controller: "alauda_118Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/tdAccess/myApply",
                active:"tbMyApply",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbHippoAccess", {
                templateUrl: "js/paas/tmpl/alauda_119.html",
                controller: "alauda_119Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/hippo/access",
                active:"tbHippoAccess",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbTdMertrics", {
                templateUrl: "js/paas/tmpl/alauda_120.html",
                controller: "alauda_120Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/tdMertrics",
                active:"tbTdMertrics",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbWherehows", {
                templateUrl: "js/paas/tmpl/alauda_121.html",
                controller: "alauda_121Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/tdAccess/wherehows",
                active:"tbWherehows",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbEasycountTaskmanage", {
                templateUrl: "js/paas/tmpl/alauda_122.html",
                controller: "alauda_122Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/easycount/taskmanage",
                active:"tbEasycountTaskmanage",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbEasycountDbmanage", {
                templateUrl: "js/paas/tmpl/alauda_123.html",
                controller: "alauda_123Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/easycount/dbmanage",
                active:"tbEasycountDbmanage",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbWorkflowCanvas", {
                templateUrl: "js/paas/tmpl/alauda_124.html",
                controller: "alauda_124Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/workflow/canvas",
                active:"tbWorkflowCanvas",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbWorkflowList", {
                templateUrl: "js/paas/tmpl/alauda_125.html",
                controller: "alauda_125Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/workflow/list",
                active:"tbWorkflowList",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbWorkflowServer", {
                templateUrl: "js/paas/tmpl/alauda_126.html",
                controller: "alauda_126Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/workflow/server",
                active:"tbWorkflowServer",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbReportList", {
                templateUrl: "js/paas/tmpl/alauda_127.html",
                controller: "alauda_127Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/report",
                active:"tbReportList",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbSupersqlHive", {
                templateUrl: "js/paas/tmpl/alauda_128.html",
                controller: "alauda_128Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/supersql/hive",
                active:"tbSupersqlHive",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbDataDbmanage", {
                templateUrl: "js/paas/tmpl/alauda_129.html",
                controller: "alauda_129Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/dbmanage",
                active:"tbDataDbmanage",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbDataMeta", {
                templateUrl: "js/paas/tmpl/alauda_130.html",
                controller: "alauda_130Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/meta",
                active:"tbDataMeta",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbDataMap", {
                templateUrl: "js/paas/tmpl/alauda_131.html",
                controller: "alauda_131Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/dataMap",
                active:"tbDataMap",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbOperationOverview", {
                templateUrl: "js/paas/tmpl/alauda_132.html",
                controller: "alauda_132Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/overview",
                active:"tbOperationOverview",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbOperationComponents", {
                templateUrl: "js/paas/tmpl/alauda_133.html",
                controller: "alauda_133Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/components",
                active:"tbOperationComponents",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbOperationAccesscontrol", {
                templateUrl: "js/paas/tmpl/alauda_134.html",
                controller: "alauda_134Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/accesscontrol?type=0",
                active:"tbOperationAccesscontrol",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbOperationBasefs", {
                templateUrl: "js/paas/tmpl/alauda_135.html",
                controller: "alauda_135Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/basefs/file",
                active:"tbOperationBasefs",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbOperationMonitor", {
                templateUrl: "js/paas/tmpl/alauda_136.html",
                controller: "alauda_136Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/monitor",
                active:"tbOperationMonitor",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbPlatformProject", {
                templateUrl: "js/paas/tmpl/alauda_137.html",
                controller: "alauda_137Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/project",
                active:"tbPlatformProject",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbPlatformResource", {
                templateUrl: "js/paas/tmpl/alauda_138.html",
                controller: "alauda_138Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/resource",
                active:"tbPlatformResource",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbPlatformUsermanage", {
                templateUrl: "js/paas/tmpl/alauda_139.html",
                controller: "alauda_139Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/usermanage",
                active:"tbPlatformUsermanage",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbPlatformSystem", {
                templateUrl: "js/paas/tmpl/alauda_140.html",
                controller: "alauda_140Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/system",
                active:"tbPlatformSystem",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMlEngineer", {
                templateUrl: "js/paas/tmpl/alauda_141.html",
                controller: "alauda_141Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/ml",
                active:"tbMlEngineer",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMlOthers", {
                templateUrl: "js/paas/tmpl/alauda_142.html",
                controller: "alauda_142Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/ml/others",
                active:"tbMlOthers",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMlModelService", {
                templateUrl: "js/paas/tmpl/alauda_143.html",
                controller: "alauda_143Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/ml/modelService",
                active:"tbMlModelService",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMlModelManage", {
                templateUrl: "js/paas/tmpl/alauda_144.html",
                controller: "alauda_144Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/ml/modelManager",
                active:"tbMlModelManage",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMlResourceManage", {
                templateUrl: "js/paas/tmpl/alauda_145.html",
                controller: "alauda_145Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/ml/resourceManager",
                active:"resourceManager",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMlModuleManage", {
                templateUrl: "js/paas/tmpl/alauda_146.html",
                controller: "alauda_146Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/ml/moduleManager",
                active:"tbMlModuleManage",
                region:false,
                domainProject:false
            })
            .when("/TBDS/tbMlAdminControl", {
                templateUrl: "js/paas/tmpl/alauda_147.html",
                controller: "alauda_147Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/index.html#/ml/adminControl",
                active:"tbMlAdminControl",
                region:false,
                domainProject:false
            })
            .when("/TBDS/wellCome", {
                templateUrl: "js/paas/tmpl/alauda_148.html",
                controller: "alauda_148Ctrl",
                parent:"TBDS",
                rtype:"PaaS",
                jump:"/zhiyun/entrance",
                active:"TBDS_entrance",
                region:false,
                domainProject:false
            })
            .when("/alauda/alRegister", {
                templateUrl: "js/paas/tmpl/alauda_149.html",
                controller: "alauda_149Ctrl",
                parent:"Alauda",
                rtype:"PaaS",
                jump:"/devops/#/home/projects",
                active:"alRegister",
                region:false,
                domainProject:false
            })
            .when("/cvm/objectStorage", {
                templateUrl: "js/cvm/volumes/tmpl/bjectStorage.html",
                controller: "bjectStorageCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"objectStorage",
                region:true,
                domainProject:true,
                isAllDomain:false 
            })
            .when("/cvm/objectStorage/objects", {
                templateUrl: "js/cvm/volumes/tmpl/objects.html",
                controller: "ObjectsCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"objectStorage",
                region:false,
                domainProject:false,
                isAllDomain:false 
            })
            .when("/cvm/objectStorage/objects/:container*", {
                templateUrl: "js/cvm/volumes/tmpl/objects.html",
                controller: "ObjectsCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"objectStorage",
                region:false,
                domainProject:false,
                isAllDomain:false 
            })
            .when("/system/objectStorageSetting", {
                templateUrl: "js/system/objectStorageSetting/tmpl/objectStorageSetting.html",
                // controller: "objectStorageSettingCtrl",
                controller: "objectStorageSettingCtrlNew",
                parent:"System",
                active:"objectStorageSetting",
                region:false,
                domainProject:false
            })
            .when("/system/objectStorageUser", {
                templateUrl: "js/system/objectStorageUser/tmpl/objectStorageUser.html",
                controller: "objectStorageUserCtrl",
                reloadOnSearch: false,
                parent:"System",
                active:"objectStorageUser",
                region:false,
                domainProject:false
            })
            .when("/cvm/shares", {
                templateUrl: "js/cvm/volumes/tmpl/shares.html",
                controller: "sharesCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"shares",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            // 文件存储
            .when("/cvm/fileStorage", {
                templateUrl: "js/cvm/volumes/tmpl/fileStorage.html",
                controller: "fileStorageCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"fileStorage",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/cvm/localDisk", {
                templateUrl: "js/cvm/volumes/tmpl/localDisk.html",
                controller: "localDiskCtrl",
                reloadOnSearch: false,
                parent:"Resource",
                active:"localDisk",
                region:true,
                domainProject:true,
                isAllDomain:true 
            })
            .when("/system/multiplenet", {
                templateUrl: "js/system/multiplenet/tmpl/index.html",
                controller: "multipleCtrl",
                parent:"System",
                active:"multiplenet",
                region:true,
                domainProject:false
            })
            .when("/system/adserver", {
                templateUrl: "js/system/ladp/tmpl/ladp.html",
                controller: "ladpCtrl",
                parent:"System",
                active:"adserver",
                region:false,
                domainProject:false
            })
            //TCS 总览
            .when("/tcs/overview-tcs/overview/overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_overview_v",
                active:"overview",
                region:false,
                domainProject:false
            })
            //平台服务--集群概览
            .when("/tcs/overviewcluster-tcs/overviewcluster/cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_platform_management",
                active:"TCS_platform_cluster_overview",
                region:false,
                domainProject:false
            })
            //平台服务--集群管理
            .when("/tcs/cluster-tcs/infrastructure/cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_platform_management",
                active:"TCS_platform_cluster_management",
                region:false,
                domainProject:false
            })
            //平台服务--节点管理
            .when("/tcs/cluster-node-tcs/infrastructure/node", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_platform_management",
                active:"TCS_platform_node_management",
                region:false,
                domainProject:false
            })
            //平台服务--Pod管理
            .when("/tcs/cluster-pod-tcs/infrastructure/pod", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_platform_management",
                active:"TCS_platform_pod_management",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--运营数据--概览
            .when("/tcs/over_view-tcs/hcbs/over-view", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_operation",
                active:"TCS_cbs_operation_overview",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--运营数据--运营趋势
            .when("/tcs/selling-trend-tcs/hcbs/selling-trend", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_operation",
                active:"TCS_cbs_operation_trend",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--运营数据--用户资源统计
            .when("/tcs/cbsuser-message-tcs/hcbs/user-message", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_operation",
                active:"TCS_cbs_operation_user_resource",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--云硬盘管理--规格定义
            .when("/tcs/cbs-spec-tcs/hcbs/cbs-spec-define", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_management",
                active:"TCS_cbs_management_format",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--云硬盘管理--云盘管理
            .when("/tcs/cbs-cdisk-tcs/hcbs/cdisk-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_management",
                active:"TCS_cbs_management_sub",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--快照管理--快照列表
            .when("/tcs/snapshot-list-tcs/hcbs/snapshot-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_snapshot_manage",
                active:"TCS_cbs_snapshot_manage_list",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--快照管理--定期快照策略
            .when("/tcs/snapshot-policy-tcs/hcbs/fixed-snapshot-policy", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_snapshot_manage",
                active:"TCS_cbs_snapshot_manage_policy",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--容器管理
            .when("/tcs/container-management-tcs/hcbs/container-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_container_management",
                active:"TCS_cbs_container_management",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--集群管理--存储机型管理
            .when("/tcs/storage-model-tcs/hcbs/storage-model", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_cluster_management",
                active:"TCS_cbs_storage_model_management",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--集群管理--存储池集群
            .when("/tcs/storage-cluster-tcs/hcbs/storage-cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_cluster_management",
                active:"TCS_cbs_storage_pool_management",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--集群管理--快照集群
            .when("/tcs/snapshot-cluster-tcs/hcbs/snapshot-cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_cluster_management",
                active:"TCS_cbs_snapshot_cluster",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--集群管理--Zookeeper集群
            .when("/tcs/zk-cluster-tcs/hcbs/zk-cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_cluster_management",
                active:"TCS_cbs_zookeeper_cluster",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--集群管理--用户资源池
            .when("/tcs/resource-cluster-tcs/hcbs/resource-cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_cluster_management",
                active:"TCS_cbs_user_resource_pool",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--性能监控--存储池性能监控
            .when("/tcs/cdepot-monitor-overview-tcs/hcbs/cdepot-monitor-overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_pool_monitor",
                active:"TCS_cbs_performance_monitor",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--性能监控--存储节点性能监控
            .when("/tcs/cdepot-cell-monitor-overview-tcs/hcbs/cdepot-cell-monitor-overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_pool_monitor",
                active:"TCS_cbs_node_xm_monitor",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--性能监控--云硬盘性能监控
            .when("/tcs/cdisk-moniter-overview-tcs/hcbs/cdisk-moniter-overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_pool_monitor",
                active:"TCS_cbs_cloud_disk_monitor",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--性能监控--物理硬盘性能监控
            .when("/tcs/cphy-disk-monitor-overview-tcs/hcbs/cphy-disk-monitor-overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_pool_monitor",
                active:"TCS_cbs_wlyp_monitor",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--任务管理--云硬盘任务
            .when("/tcs/task-list-tcs/hcbs/task-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_task_management",
                active:"TCS_cbs_disk_task",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--任务管理--快照任务
            .when("/tcs/snapshot-task-list-tcs/hcbs/snapshot-task-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_task_management",
                active:"TCS_cbs_snapshot_task",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--任务管理--云硬盘迁移任务
            .when("/tcs/cdisk-migrate-task-list-tcs/hcbs/cdisk-migrate-task-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_task_management",
                active:"TCS_cbs_cloud_disk_qy_disk",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--任务管理--存储池小表任务
            .when("/tcs/storage-pool-task-list-tcs/hcbs/storage-pool-task-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_task_management",
                active:"TCS_cbs_cccb_task",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--任务管理--物理硬盘更换任务
            .when("/tcs/replace-disk-task-list-tcs/hcbs/replace-disk-task-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_task_management",
                active:"TCS_cbs_wldisk_edit_task",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--任务管理--集群运维任务
            .when("/tcs/op-task-list-tcs/hcbs/op-task-list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_task_management",
                active:"TCS_cbs_cluster_yw_task",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--事件查询--告警事件
            .when("/tcs/event-query-tcs/hcbs/event-query", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_event_alert",
                active:"TCS_cbs_event_alert_sub",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--事件查询--集群事件
            .when("/tcs/cluster-query-tcs/hcbs/cluster-query", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_event_alert",
                active:"TCS_cbs_event_cluster",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--升级管理--存储池集群升级
            .when("/tcs/storages-cluster-upgrade-tcs/hcbs/storages-cluster-upgrade", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_sj_management",
                active:"TCS_cbs_pool_cluster_sj",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--升级管理--快照集群升级
            .when("/tcs/snapshots-cluster-upgrade-tcs/hcbs/snapshots-cluster-upgrade", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_sj_management",
                active:"TCS_cbs_snapshot_cluster_sj",
                region:false,
                domainProject:false
            })
            //云硬盘(CBS)--升级管理--升级任务
            .when("/tcs/upgrade-task-tcs/hcbs/upgrade-task", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cbs_sj_management",
                active:"TCS_cbs_task_cluster_sj",
                region:false,
                domainProject:false
            })

            //监控中心--监控概览
            .when("/tcs/cwhistle-overview-tcs/cwhistle/overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_overview",
                region:false,
                domainProject:false
            })
            //监控中心--告警查询
            .when("/tcs/warning-search-tcs/cwhistle/warning-search", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_selete",
                region:false,
                domainProject:false
            })
            //监控中心--告警策略
            .when("/tcs/tactics-management-tcs/cwhistle/tactics-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_srategy",
                region:false,
                domainProject:false
            })
            //监控中心--事件查询
            .when("/tcs/cwhistle-event-tcs/cwhistle/event", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_even_selete",
                region:false,
                domainProject:false
            })
            //监控中心--告警屏蔽
            .when("/tcs/warning-block-tcs/cwhistle/warning-block", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_shield",
                region:false,
                domainProject:false
            })
            //监控中心--策略模板
            .when("/tcs/tactics-template-tcs/cwhistle/tactics-template", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_srategy_model",
                region:false,
                domainProject:false
            })
            //监控中心--通知规则
            .when("/tcs/notice-rules-tcs/cwhistle/notice-rules", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_message_rule",
                region:false,
                domainProject:false
            })
            //监控中心--云产品指标
            .when("/tcs/product-index-tcs/cwhistle/product-index", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_cloud_index",
                region:false,
                domainProject:false
            })
            //监控中心--TCNS监控面板
            .when("/tcs/tcs-monitor-tcs/cwhistle/tcs-monitor", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_tcns_index",
                region:false,
                domainProject:false
            })
            //监控中心--监控设置
            .when("/tcs/setting-tcs/cwhistle/setting", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_monitor_center",
                active:"TCS_monitor_set",
                region:false,
                domainProject:false
            })

            //镜像仓库--公共仓库
            .when("/tcs/image-image-tcs/image/image", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_image_house",
                active:"TCS_image_public",
                region:false,
                domainProject:false
            })
            //镜像仓库--私有仓库
            .when("/tcs/image-private-tcs/image/private", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_image_house",
                active:"TCS_image_private",
                region:false,
                domainProject:false
            })

            //业务CMDB--概览
            .when("/tcs/cmdb-cloud-tcs/cmdb-cloud", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cmdb",
                active:"TCS_cmdb_overview",
                region:false,
                domainProject:false
            })
            //业务CMDB--全局搜索
            .when("/tcs/cmdb-cloud-search-tcs/cmdb-cloud/search", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cmdb",
                active:"TCS_cmdb_select",
                region:false,
                domainProject:false
            })
            //业务CMDB--主机资源
            .when("/tcs/cmdb-cloud-host-resource-tcs/cmdb-cloud/host-resource", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cmdb",
                active:"TCS_cmdb_host_resource",
                region:false,
                domainProject:false
            })
            //业务CMDB--业务资源
            .when("/tcs/cmdb-cloud-firm-tcs/cmdb-cloud/firm", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cmdb",
                active:"TCS_cmdb_business_resource",
                region:false,
                domainProject:false
            })
            //业务CMDB--业务管理
            .when("/tcs/cmdb-cloud-biz-tcs/cmdb-cloud/biz", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cmdb",
                active:"TCS_cmdb_business_management",
                region:false,
                domainProject:false
            })
            //业务CMDB--模型管理
            .when("/tcs/cmdb-cloud-model-tcs/cmdb-cloud/model", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cmdb",
                active:"TCS_cmdb_model_management",
                region:false,
                domainProject:false
            })

            //消息查询--邮件查询
            .when("/tcs/info-email-query-tcs/info/email-query", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_message_management",
                active:"TCS_message_query",
                region:false,
                domainProject:false
            })
            //消息查询--短信查询
            .when("/tcs/info-sms-query-tcs/info/sms-query", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_message_management",
                active:"TCS_message_short_query",
                region:false,
                domainProject:false
            })

            //日志中心--日志搜索
            .when("/tcs/logs-overview-searchy-tcs/logs/overview/searchy", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_log_center",
                active:"TCS_log_query",
                region:false,
                domainProject:false
            })
            //日志中心--日志接入
            .when("/tcs/logs-integration-tcs/logs/integration", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_log_center",
                active:"TCS_log_access",
                region:false,
                domainProject:false
            })
            //日志中心--日志事件
            .when("/tcs/logs-incident-record-tcs/logs/incident_record", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_log_center",
                active:"TCS_log_check",
                region:false,
                domainProject:false,
                cache:false
            })
            //日志中心--事件策略
            .when("/tcs/logs-incident-tcs/logs/incident", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_log_center",
                active:"TCS_log_policy",
                region:false,
                domainProject:false,
            })

            //用户中心--用户
            .when("/tcs/usercenter-admin-tcs/usercenter/admin", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_user",
                active:"TCS_user_management",
                region:false,
                domainProject:false,
            })
            //用户中心--用户组
            .when("/tcs/usercenter-admin-group-tcs/usercenter/admin-group", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_user",
                active:"TCS_user_group",
                region:false,
                domainProject:false,
            })
            //用户中心--角色权限
            .when("/tcs/usercenter-role-tcs/usercenter/role", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_user",
                active:"TCS_user_role",
                region:false,
                domainProject:false,
            })
            //用户中心--权限策略
            .when("/tcs/usercenter-policy-tcs/usercenter/policy", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_user",
                active:"TCS_user_role_policy",
                region:false,
                domainProject:false,
            })
            //用户中心--用户审核
            .when("/tcs/usercenter-qualification-manager-tcs/usercenter/qualification-manager", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_user",
                active:"TCS_user_check",
                region:false,
                domainProject:false,
            })
            //用户中心--操作日志
            .when("/tcs/usercenter-operation-log-tcs/usercenter/operation-log", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_user",
                active:"TCS_user_log",
                region:false,
                domainProject:false,
            })
            //PaaS产品 开发者工具 CodingDevOps
            .when("/tcs/coding-tcs/coding", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_codingDevOps_managemnet",
                active:"TCS_codingDevOps",
                region:false,
                domainProject:false,
            })
            //组件管理-- 组件列表
            .when("/tcs/components-list-tcs/components/component_list", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_component_management",
                active:"TCS_component_list",
                region:false,
                domainProject:false,
                reload: true
            })
            //组件管理-- 组件规格
            .when("/tcs/components-specifications-tcs/components/component_specifications", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_component_management",
                active:"TCS_component_from",
                region:false,
                domainProject:false,
                reload: true
            })

            //对象存储(COS) --概览
            .when("/tcs/cos5-tcs/cos5", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_overview",
                active:"TCS_cos_overview",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储桶列表
            .when("/tcs/cos5-bucket-tcs/cos5/bucket", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_bucket",
                active:"TCS_cos_bucket",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --监控报表--基础数据统计
            .when("/tcs/cos5-report-basic-tcs/cos5/report-basic", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_monitor",
                active:"TCS_cos_monitor_report_basic",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --监控报表--返回码统计
            .when("/tcs/cos5-report-httpcode-tcs/cos5/report-httpcode", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_monitor",
                active:"TCS_cos_monitor_report_code",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --接入层运维--集群管理
            .when("/tcs/access-cluster-management-tcs/cos-web/access-cluster-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_access",
                active:"TCS_cos_access_cluster",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --接入层运维--机器管理
            .when("/tcs/access-machine-management-tcs/cos-web/access-machine-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_access",
                active:"TCS_cos_access_machine",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --接入层运维--服务管理
            .when("/tcs/access-service-management-tcs/cos-web/access-service-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_access",
                active:"TCS_cos_access_service",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --接入层运维--任务管理
            .when("/tcs/access-task-management-tcs/access-task-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_access",
                active:"TCS_cos_access_task",
                region:false,
                domainProject:false,
                reload: true
            })

            //对象存储(COS) --索引运维--集群详情
            .when("/tcs/index-cluster-info-tcs/cos-web/index-cluster-info", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_index",
                active:"TCS_cos_index_cluster_info",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --索引运维--集群诊断
            .when("/tcs/index-cluster-diagnose-tcs/cos-web/index-cluster-diagnose", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_index",
                active:"TCS_cos_index_diagnose",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --索引运维--日志搜索
            .when("/tcs/index-log-search-tcs/cos-web/index-log-search", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_index",
                active:"TCS_cos_index_log",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --索引运维--实例性能分析
            .when("/tcs/index-instance-profilling-tcs/cos-web/index-instance-profilling", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_index",
                active:"TCS_cos_index_instance_profilling",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--集群概览
            .when("/tcs/cluster-overview-tcs/cos-web/cluster-overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_cluster_overview",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--集群管理
            .when("/tcs/cluster-management-tcs/cos-web/cluster-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_cluster_search",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--机器查询
            .when("/tcs/machine-management-tcs/cos-web/machine-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_machine_search",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--服务查询
            .when("/tcs/service-management-tcs/cos-web/service-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_service_search",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--任务管理
            .when("/tcs/task-management-tcs/cos-web/task-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_task_manage",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--Libra任务
            .when("/tcs/libra-task-tcs/cos-web/libra-task", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_libra_task",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--版本管理
            .when("/tcs/version-management-tcs/version-management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_version_manage",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --存储运维--硬盘更换任务
            .when("/tcs/disk-replace-tcs/cos-web/disk-replace", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_store",
                active:"TCS_cos_store_disk_replace",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --监控告警--总体视图
            .when("/tcs/monitor-alarm-tcs/cos-web/graph-main", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_monitor_alarm",
                active:"TCS_cos_monitor_graph_main",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --监控告警--详细视图
            .when("/tcs/graph-details-tcs/cos-web/graph-details", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_monitor_alarm",
                active:"TCS_cos_monitor_graph_detail",
                region:false,
                domainProject:false,
                reload: true
            })
            //对象存储(COS) --监控告警--告警配置
            .when("/tcs/alert-configure-tcs/cos-web/alert-configure", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_monitor_alarm",
                active:"TCS_cos_monitor_alert_config",
                region:false,
                domainProject:false,
                reload: true
            })
            // 对象存储(COS) --运营数据--运营总览
            .when("/tcs/operation_overview-tcs/cos-web/operation_overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_monitor_alarm_sj",
                active:"TCS_cos_operate_overview",
                region:false,
                domainProject:false,
                reload: true
            })
             // 对象存储(COS) --运营数据--用户数据
             .when("/tcs/user_data-tcs/cos-web/user_data", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_monitor_alarm_sj",
                active:"TCS_cos_operate_user",
                region:false,
                domainProject:false,
                reload: true
            })
            // 对象存储(COS) --用户管理--Buket管理
            .when("/tcs/bucket_management-tcs/cos-web/bucket_management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_user_manage",
                active:"TCS_cos_user_bucket_manage",
                region:false,
                domainProject:false,
                reload: true
            })
            // 对象存储(COS) --用户管理--频控管理
            .when("/tcs/frequenc_management-tcs/cos-web/frequenc_management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_user_manage",
                active:"TCS_cos_user_frequenc_manage",
                region:false,
                domainProject:false,
                reload: true
            })
            // 对象存储(COS) --用户管理--流控管理
            .when("/tcs/flow_management-tcs/cos-web/flow_management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_user_manage",
                active:"TCS_cos_user_flow_manage",
                region:false,
                domainProject:false,
                reload: true
            })
            // 对象存储(COS) --用户管理--配额管理
            .when("/tcs/quota_management-tcs/cos-web/quota_management", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_cos_user_manage",
                active:"TCS_cos_user_quota_manage",
                region:false,
                domainProject:false,
                reload: true
            })
            //数据库 Redis --设备管理
            .when("/tcs/equipment-tcs/redis/equipment", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_db_redis",
                active:"TCS_db_redis_equipment",
                region:false,
                domainProject:false,
                reload: true
            })
            //数据库 Redis --实例列表
            .when("/tcs/instance-tcs/redis/instance", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_db_redis",
                active:"TCS_db_redis_instacne",
                region:false,
                domainProject:false,
                reload: true
            })
            //数据库 Redis --流程列表
            .when("/tcs/process-tcs/redis/process", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_db_redis",
                active:"TCS_db_redis_process",
                region:false,
                domainProject:false,
                reload: true
            })
            //数据库 Redis --告警列表
            .when("/tcs/alarm-tcs/redis/alarm", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_db_redis",
                active:"TCS_db_redis_alarm",
                region:false,
                domainProject:false,
                reload: true
            })
            // TBSQL(赤兔) --TBSQL
            .when("/tcs/chitu-tcs/chitu", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_db_sql",
                active:"TCS_db_sql_chitu",
                region:false,
                domainProject:false,
                reload: true
            })
            //微服务平台(TSF) --资源运营--基础模块管理
            .when("/tcs/tsf-manager-basic-tcs/tsf-manager/basic", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf",
                active:"TCS_tsf_resource_basic",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --资源运营--可用区管理
            .when("/tcs/basic-tcs/zone-manager/zone", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf",
                active:"TCS_tsf_resource_zone",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --资源运营--参数配置
            .when("/tcs/config-params-tcs/tsf-manager/config-params", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf",
                active:"TCS_tsf_resource_config",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --资源运营--部署使用指引
            .when("/tcs/guide-tcs/tsf-manager/guide", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf",
                active:"TCS_tsf_resource_guide",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --运营工具--日志检索
            .when("/tcs/logsearch-tcs/tsf-manager/logsearch", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf_tool",
                active:"TCS_tsf_tool_log_search",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --运营工具--服务依赖拓扑
            .when("/tcs/topology-tcs/tsf-manager/topology", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf_tool",
                active:"TCS_tsf_tool_topology",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --运营工具--调用链查询
            .when("/tcs/trace-tcs/tsf-manager/trace", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf_tool",
                active:"TCS_tsf_tool_trace",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --监控--模块监控
            .when("/tcs/monitor-module-tcs/tsf-manager/monitor-module", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf_monitor",
                active:"TCS_tsf_monitor_module",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --监控--资源监控
            .when("/tcs/monitor-resource-tcs/tsf-manager/monitor-resource", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf_monitor",
                active:"TCS_tsf_monitor_resource",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --许可管理
            .when("/tcs/license-tcs/tsf-manager/license", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf_manage_liccense",
                active:"TCS_tsf_manage_liccense",
                region:false,
                domainProject:false
            })
            //微服务平台(TSF) --版本信息
            .when("/tcs/version-tcs/tsf-manager/version", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tsf_version_manage",
                active:"TCS_tsf_version_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --概览
            .when("/tcs/tfs-tenant-index-tcs/tsf/index", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_overview",
                active:"TCS_tenant_overview",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --集群
            .when("/tcs/tfs-tenant-cluster-tcs/tsf/cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_cluster",
                active:"TCS_tenant_cluster",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --命名空间
            .when("/tcs/tfs-tenant-namespace-tcs/tsf/namespace", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_namespace",
                active:"TCS_tenant_namespace",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --部署组
            .when("/tcs/tfs-tenant-group-tcs/tsf/group", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_group",
                active:"TCS_tenant_group",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --应用管理
            .when("/tcs/tfs-tenant-app-tcs/tsf/app", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_apply_manage",
                active:"TCS_tenant_apply_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --服务治理
            .when("/tcs/tfs-tenant-service-tcs/tsf/service", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_service_manage",
                active:"TCS_tenant_service_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --配置管理 --应用配置
            .when("/tcs/config-app-tcs/tsf/config-app", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_config",
                active:"TCS_tenant_config_app",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --配置管理 --全局配置
            .when("/tcs/config-tcs/tsf/config", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_config",
                active:"TCS_tenant_config_global",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --配置管理 --配置模板
            .when("/tcs/config-template-tcs/tsf/config-template", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_config",
                active:"TCS_tenant_config_template",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --配置管理 --文件配置
            .when("/tcs/config-file-tcs/tsf/config-file", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_config",
                active:"TCS_tenant_config_file",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --日志服务 --日志配置
            .when("/tcs/log-config-tcs/tsf/log-config", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_log",
                active:"TCS_tenant_log_config",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --日志服务 --日志检索
            .when("/tcs/log-search-tcs/tsf/log-search", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_log",
                active:"TCS_tenant_log_retrieve",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --日志服务 --日志告警
            .when("/tcs/log-alarm-tcs/tsf/log-alarm", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_log",
                active:"TCS_tenant_log_alert",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --依赖分析 --服务依赖拓展
            .when("/tcs/tsf-topology-tcs/tsf/topology", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_depend_on",
                active:"TCS_tenant_depend_on_service",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --依赖分析 --调用链查询
            .when("/tcs/topology-trace-tcs/tsf/trace", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_depend_on",
                active:"TCS_tenant_depend_on_trace",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --监控 --服务监控
            .when("/tcs/monitor-service-tcs/tsf/monitor-service", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_monitor",
                active:"TCS_tenant_monitor_service",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --监控 --jvm监控
            .when("/tcs/monitor-jvm-tcs/tsf/monitor-jvm", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_monitor",
                active:"TCS_tenant_monitor_jvm",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --全链路灰度发布
            .when("/tcs/grayscale-tcs/tsf/grayscale", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_gray_scale",
                active:"TCS_tenant_gray_scale",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --弹性伸缩
            .when("/tcs/scalable-tcs/tsf/scalable", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_scalable",
                active:"TCS_tenant_scalable",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --微服务网关 -- 网关监控
            .when("/tcs/gateway-overview-tcs/tsf/gateway-overview", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_micro_service",
                active:"TCS_tenant_micro_service_gateway",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --微服务网关 -- 分组管理
            .when("/tcs/api-group-tcs/tsf/api-group", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_micro_service",
                active:"TCS_tenant_micro_service_group",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --微服务网关 -- 插件管理
            .when("/tcs/plugin-tcs/tsf/plugin", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_micro_service",
                active:"TCS_tenant_micro_service_plugin",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --微服务网关 -- 重定向配置
            .when("/tcs/gateway-rewrite-tcs/tsf/gateway-rewrite", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_micro_service",
                active:"TCS_tenant_micro_service_redirect",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --分布式任务调度 -- 任务管理
            .when("/tcs/tct-tas-tcs/tsf-tct/tct-task", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_distributed",
                active:"TCS_tenant_distributed_task",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --分布式任务调度 -- 执行记录
            .when("/tcs/tct-exec-tcs/tsf-tct/tct-exec", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_distributed",
                active:"TCS_tenant_distributed_execute_records",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --分布式任务调度 -- 工作流管理
            .when("/tcs/tct-workflow-tcs/tsf-tct/tct-workflow", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_distributed",
                active:"TCS_tenant_distributed_workflow_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --镜像仓库
            .when("/tcs/image-tcs/tsf/image", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_image_manage",
                active:"TCS_tenant_image_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --服务编排
            .when("/tcs/template-tcs/tsf/template", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_template_manage",
                active:"TCS_tenant_template_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --角色管理
            .when("/tcs/role-tcs/tsf/role", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_role_manage",
                active:"TCS_tenant_role_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --数据集管理
            .when("/tcs/program-tcs/tsf/program", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_data_manage",
                active:"TCS_tenant_data_manage",
                region:false,
                domainProject:false
            })
            //微服务平台(租户tenant) --数据集管理
            .when("/tcs/operation-log-tcs/tsf/operation-log", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tenant_operate_log",
                active:"TCS_tenant_operate_log",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --集群管理
            .when("/tcs/cluster-tcs/tdmq/cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq",
                active:"TCS_tdmq_cluster_manage",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --命名空间
            .when("/tcs/env-tcs/tdmq/env", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq",
                active:"TCS_tdmq_namespace",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --命名空间
            .when("/tcs/topic-tcs/tdmq/topic", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq",
                active:"TCS_tdmq_topic_manage",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --消息查询
            .when("/tcs/message-tcs/tdmq/message", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq",
                active:"TCS_tdmq_message_search",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --角色管理
            .when("/tcs/role-tcs/tdmq/role", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq",
                active:"TCS_tdmq_role_manage",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --CMQ --队列服务
            .when("/tcs/cmq-queue-tcs/tdmq/cmq-queue", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_cmq",
                active:"TCS_tdmq_cmq_queue",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --CMQ --主体订阅
            .when("/tcs/cmq-topic-tcs/tdmq/cmq-topic", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_cmq",
                active:"TCS_tdmq_cmq_topic",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --集群管理
            .when("/tcs/cluster-tcs-operate/tdmq-manager/cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_cluster",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --Broker
            .when("/tcs/cluster-broker-tcs/tdmq-manager/cluster-broker", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_cluster_broker",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --Bookie
            .when("/tcs/cluster-bookie-tcs/tdmq-manager/cluster-bookie", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_cluster_bookie",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --命名空间
            .when("/tcs/environment-tcs/tdmq-manager/environment", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_enviroment",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --Topic管理
            .when("/tcs/topic-tcs/tdmq-manager/topic", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_topic",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --订阅管理
            .when("/tcs/subscription-tcs/tdmq-manager/subscription", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_subsciption",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --生产关系
            .when("/tcs/producer-tcs/tdmq-manager/producer", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_producer",
                region:false,
                domainProject:false
            })
            //消息队列(TDMQ) --运维管理 --消费管理
            .when("/tcs/consumer-tcs/tdmq-manager/consumer", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_tdmq_operate",
                active:"TCS_tdmq_operate_consumer",
                region:false,
                domainProject:false
            })
            //秘钥管理服务(KM8) -- 用户密匙
            .when("/tcs/kms-tcs/kms", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_km8",
                active:"TCS_km8_kms_key",
                region:false,
                domainProject:false
            })
            //API官网 -- 概览
            .when("/tcs/index-tcs/apigateway/index", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api",
                active:"TCS_api_gateway_overview",
                region:false,
                domainProject:false
            })
            //API官网 -- 服务
            .when("/tcs/service-tcs/apigateway/service", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api",
                active:"TCS_api_gateway_service",
                region:false,
                domainProject:false
            })
            //API官网 -- 插件
            .when("/tcs/api-plugin-tcs/apigateway/plugin", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api",
                active:"TCS_api_gateway_plugin",
                region:false,
                domainProject:false
            })
            //API官网 -- 使用计划   
            .when("/tcs/plan-tcs/apigateway/plan", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api",
                active:"TCS_api_gateway_use_plan",
                region:false,
                domainProject:false
            })
            //API官网 -- 应用   
            .when("/tcs/app-tcs/apigateway/app", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api",
                active:"TCS_api_gateway_app",
                region:false,
                domainProject:false
            })
            //API官网 -- 密匙   
            .when("/tcs/key-tcs/apigateway/key", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api",
                active:"TCS_api_gateway_key",
                region:false,
                domainProject:false
            })
            //API官网 -- 资源管理 -- Master管理   
            .when("/tcs/apigateway-manager-index-tcs/apigateway-manager/index", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api_resource",
                active:"TCS_api_resource_index",
                region:false,
                domainProject:false
            })
            //API官网 -- 资源管理 -- 集群管理   
            .when("/tcs/cluster-tcs/apigateway-manager/cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api_resource",
                active:"TCS_api_resource_cluster",
                region:false,
                domainProject:false
            })
            //API官网 -- 资源管理 -- LD管理   
            .when("/tcs/ld-tcs/apigateway-manager/ld", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api_resource",
                active:"TCS_api_resource_ld",
                region:false,
                domainProject:false
            })
            //API官网 -- 资源管理 -- LD管理   
            .when("/tcs/vip-tcs/apigateway-manager/vip", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api_resource",
                active:"TCS_api_resource_vip",
                region:false,
                domainProject:false
            })
            //API官网 -- 资源管理 -- VIP组管理   
            .when("/tcs/vip-group-tcs/apigateway-manager/vip-group", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api_resource",
                active:"TCS_api_resource_vip_group",
                region:false,
                domainProject:false
            })
            //API官网 -- 资源管理 -- 证书管理   
            .when("/tcs/certificate-tcs/apigateway-manager/certificate", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api_resource",
                active:"TCS_api_resource_service_statistics",
                region:false,
                domainProject:false
            })
            //API官网 -- 服务管理 -- 服务统计   
            .when("/tcs/service-statistics-tcs/apigateway-manager/service-statistics", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_api_service",
                active:"TCS_api_service_statistics",
                region:false,
                domainProject:false
            })
            //消息队列(CKafka) -- 实例列表 
            .when("/tcs/ckafka-index-tcs/ckafka/index", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_ckafka",
                active:"TCS_ckafka_instance_list",
                region:false,
                domainProject:false
            })
            //消息队列(CKafka) -- 运维管理 -- 实例管理 
            .when("/tcs/ckafka-manager-index-tcs/ckafka-manager/index", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_ckafka_operate",
                active:"TCS_ckafka_operate_instance",
                region:false,
                domainProject:false
            })
            //消息队列(CKafka) -- 运维管理 -- 集群管理 
            .when("/tcs/cluster-tcs/ckafka-manager/cluster", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_ckafka_operate",
                active:"TCS_ckafka_operate_cluster",
                region:false,
                domainProject:false
            })
            //消息队列(CKafka) -- 运维管理 -- Broker管理 
            .when("/tcs/broker-tcs/ckafka-manager/broker", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_ckafka_operate",
                active:"TCS_ckafka_operate_broker",
                region:false,
                domainProject:false
            })
            //消息队列(CKafka) -- 运维管理 -- Master管理 
            .when("/tcs/master-tcs/ckafka-manager/master", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_ckafka_operate",
                active:"TCS_ckafka_operate_master",
                region:false,
                domainProject:false
            })
            //应用中心 -- 应用概览 
            .when("/tcs/application-tcs/overview/application", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_apply_center",
                active:"TCS_apply_center_application",
                region:false,
                domainProject:false
            })
            //应用中心 -- 应用列表 
            .when("/tcs/app-deploy-tcs/appcenter/app_deploy", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_apply_center",
                active:"TCS_apply_center_app_list",
                region:false,
                domainProject:false
            })
            //应用中心 -- 应用市场 
            .when("/tcs/app-market-tcs/appcenter/app_market", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_apply_center",
                active:"TCS_apply_center_app_market",
                region:false,
                domainProject:false
            })
            //应用中心 -- 模板发布 
            .when("/tcs/app-template-tcs/appcenter/app_template", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_apply_center",
                active:"TCS_apply_center_app_template",
                region:false,
                domainProject:false
            })
            //应用中心 -- 项目管理 
            .when("/tcs/project-tcs/appcenter/project", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_apply_center",
                active:"TCS_apply_center_project_manage",
                region:false,
                domainProject:false
            })
            //应用中心 -- 配置管理 
            .when("/tcs/apply-config-tcs/appcenter/config", {
                templateUrl: "js/tcs/tmpl/tcs.html",
                controller: "TcsCtrl",
                parent:"TCS_apply_center",
                active:"TCS_apply_center_config_manage",
                region:false,
                domainProject:false
            })
            //腾讯云安全 -- 御界 
            .when("/tsafe/yujie", {
                templateUrl: "js/t-safe-paas/tmpl/tsafe.html",
                controller: "tsafeCtrl",
                parent:"Tsafe",
                active:"yujie",
                region:false,
                domainProject:false
            })
            //腾讯云安全 -- 天幕 
            .when("/tsafe/tianmu", {
                templateUrl: "js/t-safe-paas/tmpl/tsafe.html",
                controller: "tsafeCtrl",
                parent:"Tsafe",
                active:"tianmu",
                region:false,
                domainProject:false
            })
            //腾讯云安全 -- 御见 
            .when("/tsafe/yujian", {
                templateUrl: "js/t-safe-paas/tmpl/tsafe.html",
                controller: "tsafeCtrl",
                parent:"Tsafe",
                active:"yujian",
                region:false,
                domainProject:false
            })
            .when("/tsafe/sec", {
                templateUrl: "js/t-safe-paas/tmpl/sec.html",
                controller: "secCtrl",
                parent:"Tsafe",
                active:"sec",
                region:false,
                domainProject:false
            })
            //高级功能 -- 备份服务 -- 服务设置
            .when("/advanced/backup-service-config", {
                templateUrl: "js/advanced-function/backup/service-config/tmpl/index.html",
                controller: "backupServiceConfigCtrl",
                parent:"AdvFunction",
                active:"backupServiceConfig",
                region:true,
                // domainProject:true,
                reloadOnSearch:false,
                // isAllDomain:true
            })
            //高级功能 -- 备份服务 -- 数据备份
            .when("/advanced/backup-job", {
                templateUrl: "js/advanced-function/backup/backup-job/tmpl/index.html",
                controller: "backupJobManageCtrl",
                parent:"AdvFunction",
                active:"backupJob",
                region:true,
                domainProject:true,
                reloadOnSearch:false,
                isAllDomain:true
            })
            //高级功能 -- 备份服务 -- 数据恢复
            .when("/advanced/backup-recover-job", {
                templateUrl: "js/advanced-function/backup/backup-recover/tmpl/backup-recover-manage.html",
                controller: "backupRecoverJobCtrl",
                parent:"AdvFunction",
                active:"backupRecoverJob",
                region:true,
                domainProject:true,
                reloadOnSearch:false,
                isAllDomain:true
            })
            //高级功能 -- 备份服务 -- 数据清理
            .when("/advanced/backup-clean-job", {
                templateUrl: "js/advanced-function/backup/data-clean/tmpl/data-clean.html",
                controller: "backupDataCleanCtrl",
                parent:"AdvFunction",
                active:"backupCleanJob",
                region:true,
                domainProject:true,
                reloadOnSearch:false,
                isAllDomain:true
            })
            // .when("/cvm/abc", {
            //     template: "",
            //     controller: "iframeCtrl",
            //     parent:"Resource",
            //     active:"abc",
            //     // isAllDomain:true,
            //     region:false,
            //     // domainProject:true,
            //     isIframe:true,
            //     iframeUrl:"/asset/ecs"
            // })
            
            .when("/cvm/eee", {
                template: "",
                controller: "iframeCtrl",
                parent:"Resource",
                active:"demo",
                region:false,
                // domainProject:true,
                isIframe:true,
                iframeUrl:"/demo"
            })
            .otherwise({ redirectTo: "/view" })
        }
routeConfig.$inject = ["$routeProvider","$locationProvider"];
