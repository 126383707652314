insMonitorCtrl.$inject = ['$scope','$rootScope','$location','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','$translate']
function insMonitorCtrl($scope,$rootScope,$location,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,$translate) {
    var self = $scope;
    self.select={
        timeList:[{
            name:"今天",
            value:"today"
        },{
            name:"昨天",
            value:"yesterday"
        },{
            name:"自定义",
            value:"custom"
        }],
        year:"",
        nodeList:[],
        selectNode:{}
    };
    self.activeMenu = {
        parentIndex:0,
        childIndex:0
    }

    self.activeTime=self.select.timeList[0].value;

    //获取监控项菜单
    self.getMenu = function(menuType){
        tdsqlSrv.getMenus(menuType).then(function(res){
            if(res&&res.data){
                self.monitorList = res.data;
                self.selectMonitorType(self.monitorList[self.activeMenu.parentIndex],self.activeMenu.parentIndex);
            }
        })
    }
    //获取主备节点
    function chooseMasterNode(data){
        data.forEach((item,i)=>{
            item.name = item.master == 1?'主节点':"备节点";
            item.id = item.master == 1?1:i+2;
        })
        data.unshift({name:"汇总",master:0,id:0})
        self.select.nodeList = data;
        self.activeNode = self.select.nodeList[0].id;
        self.select.selectNode = data;
    }

    if(self.fromPiece){
        self.fromPieceManage = false;
        var data ={
            clusterKey:$location.search().clusterKey,
            interfacename:'GetGWGroup',
            setId:self.pieceItem.id,
            params:{
                "groups":[
                    {
                        "id": $location.search().id
                    }
                ]
            }
        }
        tdsqlSrv.getGWGroup(data).then(function(res){
            if(res&&res.data){
                chooseMasterNode(res.data.returnData);
                self.getMenu(0);
            }
        })

    }else{
        if($location.search().instanceType==0){
            self.fromPieceManage = true;
            self.getMenu(0);
        }else{
            self.fromPieceManage = false;
            var data ={
                clusterKey:$location.search().clusterKey,
                interfacename:'QueryGWInstance',
                params:{
                    "instance":[
                        {
                            "id" : $location.search().id
                        }
                    ]
                }
            }
            tdsqlSrv.getQueryGWGroup(data).then(function(res){
                if(res&&res.data){
                    chooseMasterNode(res.data.returnData);
                    self.getMenu(0);
                }
            })
        }    
    }

    function getNowFormatDate(date) {
        var seperator1 = "-";
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }

    var date = new Date();
    self.select.year = getNowFormatDate(date);
    $timeout(function(){
        $('.form_date').datetimepicker({
            language: "zh-CN",
            weekStart: 1,
            todayBtn: 1,
            autoclose: 1,
            todayHighlight: 1,
            minView:"month",
            //startView: 2, 
            minuteStep:5,
            forceParse: 0,
            format: "yyyy-mm-dd",
            pickerPosition: "bottom-left",
        }).on('changeDate', function(ev){
            self.activeTime='custom';
            getMoniter()
        });
        var date = new Date();
        $('.form_date').datetimepicker('setEndDate', getNowFormatDate(date));

    },300);
    
    self.selectTime=function(time){
        self.activeTime=time.value;
        if(time.value=='yesterday'){
            var day1 = new Date();
            day1.setTime(day1.getTime()-24*60*60*1000);
            self.select.year =getNowFormatDate(day1);
        }else if(time.value=='today'){
            var date = new Date();
            self.select.year = getNowFormatDate(date);
        }
        getMoniter();
    };

    self.selectNode=function(v){
        self.activeNode = v.id;
        self.select.selectNode = v;
        if(v.id==0){
            self.getMenu(0);
        }else{
            self.getMenu(1);
        }
    };

    self.clickToPieceManage=function(){
        self.activeTab('piecewiseManage');
    };

    function getMoniter(){
        if(self.activeNode==undefined){
            self.activeNode=0;
        }
        var dataParams = {
            "queryTime":self.select.year, // 时间
            "fmid":self.activeNode==0?(self.fromPiece?$location.search().id+"/"+self.pieceItem.id:$location.search().id):self.select.selectNode.ip,  //节点信息时传节点IP ，汇总时传实例ID
            "fkey":self.selectMenu.value,   //菜单value
            "fpmid":self.activeNode==0?'':(self.fromPiece?$location.search().id+"/"+self.pieceItem.id:$location.search().id) // 节点信息时传实例ID ，汇总时不传
        }
        tdsqlSrv.getMonitorData(dataParams).then(function(res){
            if(res&&res.data){
                self.panels = {}
                self.panels.monitordata = {
                    data: {
                        grid: {
                            left: '1%',
                            top:'5%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        legend: {
                            show: false,
                            orient: 'horizontal',
                            top: 'bottom',
                            left: 10,
                        },
                        unit: self.selectMenu.unit,
                        toolboxShow: false,
                        xAxis:res.data.times,
                        series: [{
                            'name':self.activeMonitor,
                            "type":"line",
                            "data":res.data.values
                        }]
                    }
                }
            }
        })
    }

    self.selectMonitorItem=function(childMonitor,i){
        self.activeMenu.childIndex = i;
        self.selectMenu = childMonitor;
        self.activeMonitor = childMonitor.name;
        getMoniter();
    };

    self.selectMonitorType=function(monitor,i){
        if(self.activeMenu.parentIndex != i){
            self.activeMenu.childIndex = 0;
        }
        self.activeMenu.parentIndex = i;
        if(monitor.open){
           monitor.open=false;
        }else{
           self.monitorList.forEach(function(item){
               if(item.menuId!=monitor.menuId){
                  item.open=false;
               }else{
               	    item.open=true;
                    if(!(item.childMenus[self.activeMenu.childIndex])){
                        self.activeMenu.childIndex = 0;
                    }
                    if(item.childMenus[self.activeMenu.childIndex]&&item.childMenus[self.activeMenu.childIndex].name!=self.activeMonitor){
                        self.activeMenu.childIndex = 0;
                    }
                    self.selectMonitorItem(item.childMenus[self.activeMenu.childIndex],self.activeMenu.childIndex)
               }
           });
        }
    };
}
export{insMonitorCtrl}
