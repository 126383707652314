dragDisk.$inject = ["$translate","$timeout"];

function dragDisk($translate,$timeout) {
    return {
        restrict:"E",
        scope:{
            data:"="
        },
        templateUrl:"js/system/storageManagement/tmpl/drag-disk.html",
        link: function(self, elem, attrs, $ngModel) {
            let sourceElem = $(elem).find(".drag-disk-source ul")[0];
            let dataElem = $(elem).find(".drag-disk-data ul")[0];
            let localElem = $(elem).find(".drag-disk-local ul")[0];
            
            
            sourceElem.ondrop = function(event){
                console.log('source disk drop start')
                if(self.TYPECASE=="CEPHMANAGE"&&!self.currentPreviewDisk.isNew){
                    self.$emit('ceph-change-disk',{data:self.currentDisk.data,type:'sourceList',allData:self.currentPreviewDisk,action:'add'});
                    return;
                }
                self.currentPreviewDisk.sourceList.push(self.currentDisk.data);
                self.nouseList.splice(self.currentDisk.index,1);
                //磁盘规则校验
                self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache).errorList;
                $timeout(function(){
                    self.$apply();
                },50)
                console.log('source disk drop success')
            }
            sourceElem.ondragenter = function(event){
                event.preventDefault();
            }
            sourceElem.ondragover = function(event){
                event.preventDefault();
            }

            dataElem.ondrop = function(event){
                console.log('data disk drop start')
                if(self.TYPECASE=="CEPHMANAGE"&&!self.currentPreviewDisk.isNew){
                    self.$emit('ceph-change-disk',{data:self.currentDisk.data,type:'dataList',allData:self.currentPreviewDisk,action:'add'});
                    return;
                }
                self.currentPreviewDisk.dataList.push(self.currentDisk.data);
                self.nouseList.splice(self.currentDisk.index,1);
                //磁盘规则校验
                self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache).errorList;
                $timeout(function(){
                    self.$apply();
                },50)
                console.log('data disk drop success')
            }
            dataElem.ondragenter = function(event){
                event.preventDefault();
            }
            dataElem.ondragover = function(event){
                event.preventDefault();
            }

            localElem.ondrop = function(event){
                console.log('local')
                self.localList.push(self.currentDisk.data);
                self.nouseList.splice(self.currentDisk.index,1);
                $timeout(function(){
                    self.$apply();
                },50)
                //event.dataTransfer.setData("text/html",self.data);
            }
            localElem.ondragenter = function(event){
                event.preventDefault();
            }
            localElem.ondragover = function(event){
                event.preventDefault();
            }
        },
        controller:function($scope,dragDiskCache,alertSrv,$translate){
            let self = $scope;
            //可用磁盘列表
            function init(self,dragDiskCache){
                self.sourceBcacheTitle = self.data.bcache?$translate.instant('aws.system.storagement.storageTranslate.bcacheSourceDisk'):$translate.instant('aws.system.storagement.storageTranslate.sourceDisk')
                self.showAdd = true;
                self.errorMsgMap = {};
                self.errorList = [];
                self.TYPECASE = self.data.type||'INCEPH';
                self.curPreIndex = 0;
                self.nouseList = [];
                self.nodeUidMap = {};
                self.noDataDisk = false;//数据盘不能为空-提示控制
                self.tabChange = {
                    isCeph:true,//默认展示ceph映射
                    isLocal:false//默认本地盘不展示
                }
                //保存所有元数据盘-数据盘 映射
                self.sourceDataList = [];

                self.data.checkedItems.forEach((item,index)=>{
                    self.nodeUidMap[item.nodeUid] = {
                        hostName:item.hostName
                    };
                    if(index==0){
                        //缓存选中的第一个节点Uid
                        self.currentId = item.nodeUid;
                    }
                })

                self.data.availableDiskList.forEach(item=>{
                    if(self.nodeUidMap[item.nodeUid]!=undefined){
                        self.nodeUidMap[item.nodeUid].pathMap = {};
                        item.diskAttributesList.forEach(it=>{
                            self.nodeUidMap[item.nodeUid].pathMap[it.name] = it.byId;
                            //it.capacity = it.capacity.replace(/\s+/g,'')
                        })
                        //记录所有节点的可用磁盘
                        self.nodeUidMap[item.nodeUid].nouseList = _.cloneDeep(item.diskAttributesList);
                    }
                });

                let currentNodeData = dragDiskCache[self.TYPECASE].nodeUidMap[self.currentId];
                //初始化所选节点的所有<元数据盘-数据盘>-映射
                if(currentNodeData&&currentNodeData.diskMap){
                    self.sourceDataList = _.cloneDeep(currentNodeData.diskMap);
                }
                //初始化当前<元数据盘-数据盘>-映射,默认取第一组映射
                if(self.sourceDataList.length==0){
                    self.currentPreviewDisk = {
                        sourceList:[],
                        dataList:[],
                        isNew:true
                    }
                    self.sourceDataList.push(self.currentPreviewDisk);
                    self.showAdd = false;
                }else{
                    self.currentPreviewDisk = self.sourceDataList[0];
                }

                //初始化可用磁盘列表，获取第一个节点可用磁盘
                self.nouseList = currentNodeData&&currentNodeData.nouseList?_.cloneDeep(currentNodeData.nouseList):_.cloneDeep(self.nodeUidMap[self.currentId].nouseList);
                //初始化本地磁盘列表，获取第一个节点可用磁盘
                self.localList = currentNodeData&&currentNodeData.localList?_.cloneDeep(currentNodeData.localList):[];
                //初始化已丢失硬盘列表，获取第一个节点可用磁盘
                self.missList = currentNodeData&&currentNodeData.missList?_.cloneDeep(currentNodeData.missList):[];
                self.$emit('ceph-send-context',{context:self});
            };
            init(self,dragDiskCache);
            function transportData(self){
                for(let key in self.nodeUidMap){
                    self.sourceDataList.forEach(item=>{
                        item.sourceList.forEach(it=>{
                            it.byId = self.nodeUidMap[key].pathMap[it.name];
                        })
                        item.dataList.forEach(it=>{
                            it.byId = self.nodeUidMap[key].pathMap[it.name];
                        })
                    })
                    self.nodeUidMap[key].valid = dragDiskCache[self.TYPECASE].valid;
                    self.nodeUidMap[key].diskMap = _.cloneDeep(self.sourceDataList);
                    self.nodeUidMap[key].nouseList = self.nouseList;
                    self.nodeUidMap[key].localList = self.localList;
                    self.nodeUidMap[key].missList = self.missList;
                }
                return self.nodeUidMap;
            }
            function changeUnit(v){
                if(v.search(/TB$/i)>-1){
                    return Number(v.replace(/TB$/i,""))*1024;
                }else if(v.search(/PB$/i)>-1){
                    return Number(v.replace(/PB$/i,""))*1024*1024;
                }else if(v.search(/GB$/i)>-1){
                    return Number(v.replace(/GB$/,""));
                }else if(v.search(/MB$/i)>-1){
                    return parseInt(Number(v.replace(/MB$/i,""))/1024);
                }else if(v.search(/KB$/i)>-1){
                    return parseInt(Number(v.replace(/KB$/i,""))/1024/1024);
                }
                return 0;
            }
            self.checkError = function(data,rgw,fromType,bcache){
                let sourceSsdNum = 0,dataSsdNum = 0;
                let sourceTotalSize = 0,dataTotalSize = 0
                let sourceLength = data.sourceList.length;
                let dataLength = data.dataList.length;
                let errorMsgMap = {};
                let resultObj = {
                    valid:true,
                    errorList:[],
                    errorHash:{}
                };
                let checkType = ["POOLS","NODEMANAGE","CEPHMANAGE"];
                
                resultObj.errorHash['nodatadisk'] = sourceLength&&!dataLength?true:false;

                //当开启bcache时，必须配置元数据盘
                errorMsgMap['sourceDiskRequire'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.sourceDiskRequire");
                //元数据盘必须为ssd
                errorMsgMap['diskinvalidedMsg'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.sourceSsd");
                //元数据盘只能有一块
                errorMsgMap['sourceOnly'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.sourceOnly");
                //当数据盘有ssd盘时 元数据盘不可用
                errorMsgMap['datahasSsd'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.datahasSsd");
                //数据盘不能为空
                errorMsgMap['nodatadisk'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.nodatadisk");
                //数据盘只能全部配置为ssd盘或者hdd盘
                errorMsgMap['dataSddHdd'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.dataSddHdd");
                //数据盘和元数据盘数量配置比超过4:1，元数据盘容量小于总数据盘容量1%
                errorMsgMap['invalidMaxDiskNumSize'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.invalidMaxDiskNumSize");
                //数据盘和元数据盘数量配置比超过4:1
                errorMsgMap['invalidMaxDiskNum'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.invalidMaxDiskNum");
                //元数据盘容量小于总数据盘容量1%
                errorMsgMap['invalidMaxDiskSize'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.invalidMaxDiskSize");
                //数据盘和元数据盘数量配置比超过4:1，元数据盘容量小于总数据盘容量4%
                errorMsgMap['invalidMaxDiskNumSizeRgw'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.invalidMaxDiskNumSizeRgw");
                //元数据盘容量小于总数据盘容量4%
                errorMsgMap['invalidMaxDiskSizeRgw'] = $translate.instant("aws.system.storagement.storageTranslate.dragDiskError.invalidMaxDiskSizeRgw");
                
                
                data.sourceList.forEach(item=>{
                    if(item.ssd){
                        sourceSsdNum++
                    }
                    sourceTotalSize+=changeUnit(item.capacity);
                })

                data.dataList.forEach(item=>{
                    if(item.ssd){
                        dataSsdNum++
                    }
                    dataTotalSize+=changeUnit(item.capacity);
                })
                let s_1 = sourceSsdNum?1:0;
                let s_2 = (sourceLength - sourceSsdNum)?1:0;
                let d_1 = dataSsdNum?1:0;
                let d_2 = (dataLength - dataSsdNum)?1:0;
                let s_d_map = s_1+"-"+s_2+"-"+d_1+"-"+d_2;

                let errorType = {
                    // "0-0-0-0"
                    // "0-0-0-1"
                    // "0-0-1-0"
                    "0-0-1-1":errorMsgMap['dataSddHdd'],
                    "0-1-0-0":errorMsgMap['nodatadisk'],
                    "0-1-0-1":errorMsgMap['diskinvalidedMsg'],
                    "0-1-1-0":errorMsgMap['datahasSsd'],
                    "0-1-1-1":errorMsgMap['datahasSsd'],
                    "1-0-0-0":errorMsgMap['nodatadisk'],
                    // "1-0-0-1"
                    "1-0-1-0":errorMsgMap['datahasSsd'],
                    "1-0-1-1":errorMsgMap['datahasSsd'],
                    "1-1-0-0":errorMsgMap['diskinvalidedMsg'],
                    "1-1-0-1":errorMsgMap['diskinvalidedMsg'],
                    "1-1-1-0":errorMsgMap['diskinvalidedMsg'],
                    "1-1-1-1":errorMsgMap['diskinvalidedMsg'],
                }
                if(errorType[s_d_map]){
                    resultObj.errorList = [errorType[s_d_map]];
                    resultObj.valid = false;
                }
                if(checkType.indexOf(fromType)>-1){
                    /* 
                    fromType 代表对应模块：
                        "POOLS" 存储池
                        "INCEPH" 存储管理-块存储-超融合ceph
                        "NODEMANAGE",节点管理
                        "CEPHMANAGE" ceph管理
                        "INITPAGE" 初始化弹出层-存储管理-块存储-超融合ceph
                        "OBJECTCEPH" 存储管理-对象存储-超融合ceph
                    */
                    // 对接块存储或者对象存储时，元数据盘数量可以大于1,否则元数据盘数量<=1;
                    if(sourceLength>1){
                        resultObj.errorList = [errorMsgMap['sourceOnly']];
                        resultObj.valid = false;
                    }
                }
                if(bcache){
                    if(sourceLength==0&&dataLength>0){
                        resultObj.errorList = [errorMsgMap['sourceDiskRequire']];
                        resultObj.errorHash['sourceDiskRequire'] = errorMsgMap['sourceDiskRequire'];
                        resultObj.valid = false;
                    }
                }
                
                
                if(resultObj.errorList.length==0){
                    if(dataLength&&sourceLength){
                        if((dataLength/sourceLength)>4){
                            if(rgw){
                                if(dataTotalSize*0.04>sourceTotalSize){
                                    resultObj.errorList = [errorMsgMap['invalidMaxDiskNumSizeRgw']];
                                    resultObj.valid = false;
                                    return resultObj;
                                }else{
                                    //数据盘和元数据盘数量配置比超过4:1
                                    resultObj.errorList = [errorMsgMap['invalidMaxDiskNum']];
                                    resultObj.valid = false;
                                    return resultObj;
                                }
                            }else{
                                //数据盘和元数据盘数量配置比超过4:1，元数据盘容量小于总数据盘容量1%
                                if(dataTotalSize*0.01>sourceTotalSize){
                                    resultObj.errorList = [errorMsgMap['invalidMaxDiskNumSize']];
                                    resultObj.valid = false;
                                    return resultObj;
                                }else{
                                    //数据盘和元数据盘数量配置比超过4:1
                                    resultObj.errorList = [errorMsgMap['invalidMaxDiskNum']];
                                    resultObj.valid = false;
                                    return resultObj;
                                }
                            }
                        }else{
                            if(rgw){
                                if(dataTotalSize*0.04>sourceTotalSize){
                                    resultObj.errorList = [errorMsgMap['invalidMaxDiskSizeRgw']];
                                    resultObj.valid = false;
                                    return resultObj;
                                }
                            }else{
                                //元数据盘容量小于总数据盘容量1%
                                if(dataTotalSize*0.01>sourceTotalSize){
                                    resultObj.errorList = [errorMsgMap['invalidMaxDiskSize']];
                                    resultObj.valid = false;
                                    return resultObj;
                                }
                            }
                        }
                        
                    }
                }
                return resultObj;
            }

            if(dragDiskCache[self.TYPECASE].nodeUidMap[self.currentId]&&!dragDiskCache[self.TYPECASE].nodeUidMap[self.currentId].valid){
                self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache).errorList;
            }
            self.$on('drag-start',function(e,data){
                self.currentDisk = data;
            })

            //保存磁盘配置
            self.closeDisks = function(type){
                if(type=='save'){
                    let nodatadisk = false;
                    let nosourcedisk = false;
                    //校验当前<元数据盘-数据盘>-映射
                    self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache).errorList;
                    //对每个<元数据盘-数据盘>-映射 进行校验
                    dragDiskCache[self.TYPECASE].valid = true;
                    self.sourceDataList.forEach(item=>{
                        let result = self.checkError(item,self.data.rgw,self.TYPECASE,self.data.bcache);
                        if(!result.valid){
                            dragDiskCache[self.TYPECASE].valid = false;
                        }
                        if(result.errorHash['nodatadisk']){
                            nodatadisk = true;
                        }
                        if(result.errorHash['sourceDiskRequire']){
                            nosourcedisk = true;
                        }
                    })
                    //开启缓存加速时，元数据盘必填
                    if(nosourcedisk){
                        alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.dragDiskError.nosourcediskMsg"), "error", 5000);
                        return;
                    }
                    //数据盘必填
                    if(nodatadisk){
                        alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.dragDiskError.nodatadiskMsg"), "error", 5000);
                        return;
                    }
                    //无论磁盘是否通过校验 都需要缓存保存数据
                    dragDiskCache[self.TYPECASE].nodeUidMap = _.extend({},dragDiskCache[self.TYPECASE].nodeUidMap,transportData(self));
                }
                self.$emit('drag-disk-end',{type:self.TYPECASE});
            }

            
            
            //从节点管理-硬盘配置-切换存储类型-tab
            self.$on('disk-tab-change',function(e,data){
                self.tabChange = data;
            })

            //从节点管理-硬盘配置-保存
            self.$on("save-from-NODEMANAGE",function(){
                self.closeDisks('save');
            })
            
            
            //删除磁盘
            self.diskDel = function(item,index,type){
                //ceph管理页面，删除磁盘调用api 不从前端删除
                if(self.TYPECASE=="CEPHMANAGE"&&!self.currentPreviewDisk.isNew){
                    self.$emit('ceph-change-disk',{data:item,type:type,allData:self.currentPreviewDisk,action:'delete'});
                    return;
                }

                if(type=="localList"){
                    self.localList.splice(index,1);
                }else{
                    self.currentPreviewDisk[type].splice(index,1);
                }
                self.nouseList.push(item);
                self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache).errorList;
            }
            //切换不同<元数据盘-数据盘>-映射
            self.choosePre = function(index){
                self.curPreIndex = index;
                self.currentPreviewDisk = self.sourceDataList[index];
                self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache);
            }
            //删除<元数据盘-数据盘>-映射
            self.delPreItem = function(item,index,data){
                //当只有一组映射关系且无磁盘时，不可删除
                if(item.sourceList.length==0&&item.dataList.length==0&&data.length==1){
                    return;
                }
                self.nouseList.push(...item.sourceList);
                self.nouseList.push(...item.dataList);
                data.splice(index,1);
                if(data.length==0){
                    data.push({
                        sourceList:[],
                        dataList:[],
                        isNew:true
                    });
                    self.choosePre(0);
                }else{
                    if(self.curPreIndex==index){
                        self.choosePre(0)
                    }else if(self.curPreIndex>index){
                        self.choosePre(Number(self.curPreIndex) - 1);
                    }
                }
                self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache).errorList;
                if(!(data.length==1&&data[0].isNew)){
                    self.showAdd = true;
                }
            }
            //添加<元数据盘-数据盘>-映射
            self.addMapItem = function(){
                self.currentPreviewDisk = {
                    sourceList:[],
                    dataList:[],
                    isNew:true
                };
                self.sourceDataList.unshift(self.currentPreviewDisk);
                self.errorList = self.checkError(self.currentPreviewDisk,self.data.rgw,self.TYPECASE,self.data.bcache).errorList;
                self.showAdd = false;
            }
        }
    };
}
export {dragDisk}