export function loginlogSrv($http) {
    var static_url = "awstack-log/v1/enterprises/" + localStorage.enterpriseUid;
    return {
        getloginlogData: function(options) {
            return $http({
                method: "POST",
                url: static_url + "/login/sqls",
                data: options
            });
        },
        getloginlogDataNew: function(options) {
            return $http({
                method: "POST",
                url: static_url + "/login/region/" + localStorage.regionUid+"/sqls/audit",
                data: options
            });
        }
    };
}
loginlogSrv.$inject = ["$http"];