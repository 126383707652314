
import "../volumesSrv";
cacheVolumesCtrl.$inject=['$scope', "$rootScope","$location","volumesDataSrv", "$translate","NgTableParams","newCheckedSrv","utilTool"];
function cacheVolumesCtrl($scope, $rootScope,$location, volumesDataSrv,$translate,NgTableParams,newCheckedSrv,utilTool){
    let self = $scope;
    self.context = self;
    self.pageSize = 10;
    self.pageCount = 10;

    self.table = {};
    self.btn.delisDisabled = true;
    // self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')];
    if(localStorage.permission == 'enterprise'){
        self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')]
    }else{
        self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_ConfigManagement'),$translate.instant('aws.menu.System_Storage')]
    }
    self.titleName="cacheVolumes";
    if(sessionStorage["cacheVolumes"]){
       self.tableCols=JSON.parse(sessionStorage["cacheVolumes"]);
    }else{
        self.tableCols = [
            { field: "check", title: "",headerTemplateURL:"cacheheaderCheckbox.html",show: true },
            { field: "name", title: self.headers.mirrorCacheDiskName,sortable: "name",show: true,disable:true},
            { field: "size", title: self.headers.config,sortable: "size",show: true,disable:false},
            { field: "statusCopy", title: self.headers.status,sortable: "status",show: true,disable:true },
            { field: "voltype", title: self.headers.voltype,sortable: "voltype",show: true,disable:false },
            { field: "description", title: self.headers.mirrorCacheDiskDescription,sortable: "description",show: true,disable:false },
        ];
    }
    self.searchList = [
        {name:$translate.instant('aws.volumes.table.status.all'),id:null,key:""},
        {name:$translate.instant('aws.volumes.table.status.available'),id:"available",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.inavailable'),id:"inavailable",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.creating'),id:"creating",value:$translate.instant('aws.instances.table.status.error'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.deleting'),id:"deleting",value:$translate.instant('aws.instances.table.status.paused'),key:"status_ori"},
        {name:$translate.instant('aws.volumes.table.status.in-use'),id:"in-use",value:$translate.instant('aws.instances.table.status.suspended'),key:"status_ori"}
    ];
    self.lineDetailIn = function (item) {
        utilTool.setLocalStorage([item]);
        $location.url(`/cvm/volumes?id=${item.id}`)
    }
    //退出详情页清空勾选
    self.$watch(function () {
        return volumesDataSrv.clear;
    }, function(value, old) {
        if (value !== old) {
            self.checkboxescacheTableParams.items = {};
        }
    },true)
    //资源导出
    self.exportResource = function(){
        var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/volumes?regionKey=${localStorage.regionKey}&type=2&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm?self.globalSearchTerm:""}&status=${self.statusSelected.searchItem.id?self.statusSelected.searchItem.id:""}`
        utilTool.downloadFile(url);
    }
    self.showExport = utilTool.isAllDomainSelect;
    //项目切换的监听函数
    self.$watch(function(){
        return localStorage.selectedAllDomain;
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self)
    })
    
    //切换页码
    self.changePage=function(options){
        options.pattern = self.globalSearchTerm?self.globalSearchTerm:null;
        options.status = self.statusSelected.searchItem?self.statusSelected.searchItem.id:null;
        options.type = 2;
        self.pageCount = options.pageSize
        getVolumes(options);
    };
    //后端搜索
    self.searchBackend = function(options){
        var pageOption = {
            pageSize: self.pageSize,
            pageNum:1,
            pattern:self.globalSearchTerm?self.globalSearchTerm:null,
            status:self.statusSelected.searchItem?self.statusSelected.searchItem.id:null,
            type: 2
        };
        if(options.type=='refresh'){
            self.currentPage = 1;
            self.globalSearchTerm = null;
            pageOption.pattern = null;
            pageOption.status = null;
            self.statusSelected.searchItem = self.searchList[0]
        }
        getVolumes(pageOption);
    }

    function getVolumes(options) {
        if(!localStorage.cinderService) return;
        volumesDataSrv.getVolumeList(options).then(function(result) {
            if(result && result.data && angular.isArray(result.data)){
                //过滤掉result.data中vol.instance="fixedImageInstanceName"的元素
                //result.data=result.data.filter(vol=>vol.instance[0]!="fixedImageInstanceName");
                result.data.map(item => {
                    item.statusCopy= $translate.instant("aws.volumes.table.status."+item.status);
                    item.multiattachCopy = $translate.instant('aws.volumes.table.multiattach.'+item.multiattach);
                    item.bootableCopy = $translate.instant('aws.volumes.table.bootable.'+item.bootable);
                    item.voltype =  item.storageInfo?item.storageInfo.disPlayName:"";
                    item._type=angular.copy(item.type);
                    if(item.domainName=="default"){
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    }else{
                        item.domainName = item.domainName;
                    }
                    if(item.projectName=="admin"){
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    }else{
                        item.projectName = item.projectName;
                    }
                    if (item.description == null) {
                       item.description = '';
                    }else if(item.description == "auto-created_from_restore_from_backup"){
                        item.description = $translate.instant("aws.volumes.fromBackup");
                    }
                });
                // self.cache_data = result.data.filter(item => ( item.metaData && (item.metaData.image_cache_name || item.metaData.glance_image_id)));
                // self.cache_data.map(item => item.image_cache_name = item.metaData.image_cache_name);
                self.cache_data = result.data;
                successFunc(self.cache_data);
            }
            self.totalNum = result.total;
        });
    }

    function successFunc(res){
		self.cache_data = self.configSearch(self.cache_data,self.tableCols);
        self.cacheTableParams = new NgTableParams({ count: self.pageCount }, { counts: [], dataset: self.cache_data });
        newCheckedSrv.checkDo(self,"","id","cacheTableParams");
    };

    self.getData = function(){
        var options = {
            "pageNum": 1,
            "pageSize": 10,
            "type": 2
        }
        self.currentPage = 1;
        self.globalSearchTerm = "";
        if(!localStorage.cinderService) return;
        volumesDataSrv.getVolumeList(options).then(function(result) {
            if(result && result.data && angular.isArray(result.data)){
                //过滤掉result.data中vol.instance="fixedImageInstanceName"的元素
                //result.data=result.data.filter(vol=>vol.instance[0]!="fixedImageInstanceName");
                result.data.map(item => {
                    item.statusCopy= $translate.instant("aws.volumes.table.status."+item.status);
                    item.multiattachCopy = $translate.instant('aws.volumes.table.multiattach.'+item.multiattach);
                    item.bootableCopy = $translate.instant('aws.volumes.table.bootable.'+item.bootable);
                    item.voltype =  item.storageInfo?item.storageInfo.disPlayName:"";
                    item._type=angular.copy(item.type);
                    if(item.domainName=="default"){
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    }else{
                        item.domainName = item.domainName;
                    }
                    if(item.projectName=="admin"){
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    }else{
                        item.projectName = item.projectName;
                    }
                    if (item.description == null) {
                       item.description = '';
                    }else if(item.description == "auto-created_from_restore_from_backup"){
                        item.description = $translate.instant("aws.volumes.fromBackup");
                    }
                });
                // self.cache_data = result.data.filter(item => ( item.metaData && (item.metaData.image_cache_name || item.metaData.glance_image_id)));
                self.cache_data = result.data;
                // self.cache_data.map(item => item.image_cache_name = item.metaData.image_cache_name);
                successFunc(self.cache_data);
            }
            self.totalNum = result.total;
        });
    }
    self.checkboxescacheTableParams = {
        checked:"",
        items:{}
    }
    
    self.$watch(function(){
        return self.checkboxescacheTableParams.items;
    },function(cacheData){
        if(!cacheData||!self.cache_data) return
        self.checkoutArry = []
        self.cache_data.forEach(function(item){
            for(let key in cacheData){
                if(key==item.id&&cacheData[key]){
                    self.checkoutArry.push(item)
                }
            }
        })
    },true)

    self.$watch(function(){
        return self.checkoutArry;
    },function(checkoutArry){
        if(!checkoutArry) return
        self.canDeleCache = true;
        self.btn.delisDisabled = false;
        self.btn.canDel = true;
        if(checkoutArry.length>0){
            self.canDeleCache = checkoutArry.some(function(item){
                return item.metaData&&item.metaData.glance_image_id
            })
        }
    },true)

    self.getData()
    self.volume.initTablefunc = function(){
        self.getData()
    }
    
}
export {cacheVolumesCtrl}
