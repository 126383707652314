storageObjectTpl.$inject = [];
function storageObjectTpl() {
    return {
        restrict:"E",
        replace:true,
        scope:{
            storageUseList:"=",
            initStorageTable:"="
        },
        templateUrl:"js/system/storageManagement/tmpl/storage-object-tpl.html",
        link: function(self, elem, attrs, $ngModel) {
            
        },
        controller:function($scope,storageInitList,$translate,storageManagementSrv,objectStorageSettingSrv,dragDiskCache,alertSrv,accessSrv,storageUtil){
            let self = $scope;
            let storageInitInstan = storageInitList.init();
            self.modalTitle = $translate.instant("aws.system.storagement.addTitle");
            self.storageObjectInit = {
                deviceList:angular.copy(storageInitInstan.storageDeviceList.object),
                current:angular.copy(storageInitInstan.storageDeviceList.object)[0].id,
                signerVersion:[],
                typeList:[
                    {
                        value:'inner',
                        id:2,
                        name:$translate.instant('aws.system.storagement.objectInner')
                    },
                    {
                        value:'external',
                        id:3,
                        name:$translate.instant('aws.system.storagement.objectExternal')
                    }
                ],
                
            }
            self.submitValid = false;
            function init(old){
                let curUse = self.storageUseList.filter(item=>item.id==2);
                let name = old?old.name:"";
                let description = old?old.description:"";
                return {
                    use:curUse[0],
                    description:description,
                    name:name,
                    csp:{
                        url:"", 
                        username:"", 
                        password:"", 
                        port:"", 
                        ApiAccessKey:"", 
                        ApiSecretKey:"", 
                    },
                    ceph:{
                        selected:'',
                        paramObj:{},
                        type:self.storageObjectInit.typeList[0],
                        tableData:[]
                    },
                    fieldList:{
                        // user: '',
                        accessKey: '',
                        secretKey: '',
                        endpoint: '',
                    },
                    version: {
                        selected: ''
                    },
                    currentType:'1',
                    poolTypeList:[
                        {
                            value:'1',
                            id:'1',
                            disabled:false,
                            name:$translate.instant('aws.system.storagement.objectPoolShare'),
                        },
                        {
                            value:'2',
                            id:'2',
                            disabled:false,
                            name:$translate.instant('aws.system.storagement.objectPoolNotShare'),
                        }
                    ],
                    checkedItems:[],
                    diskDisabled:true,
                    disksConfigShow:false
                }
            }

            function checkRgwCode(diskList,typeList){
                let num = 0;
                let keyList = Object.keys(typeList).sort();
                let obj = {};
                for(let key in diskList){
                    diskList[key].diskMap.forEach(item=>{
                        if(item.dataList.length){
                            num++;
                        }
                    })
                }
                
                for(let ii=0;ii<typeList.length;ii++){
                    if(num<=typeList[ii].num){
                        obj = typeList[ii];
                        break;
                    }
                }
                return {num:num,obj:obj};
            }

            //计算参数的下拉列表可选值
            function computeParam(diskMap,data,params){
                let cur = 0;
                params.paramObj = checkRgwCode(diskMap,data).obj;
                params.paramObj.data.forEach((item,index)=>{
                    item.text = `${item.name} (${$translate.instant('aws.system.storagement.'+item.type)})`;
                    if(params.selected.type&&params.selected.name===item.name){
                        cur = index;
                    }
                })
                params.selected = params.paramObj.data[cur];
            }
            //对象存储-ceph 获取参数字段
            function getObjectStorageConf(item){
                if(item.value==1){
                    accessSrv.getObjectStorageConf().then(res => {
                        if(res && res.data && res.data.data){
                            res.data.data.map(item => {
                                item.text = `${item.name} (${$translate.instant('aws.system.storagement.'+item.type)})`;
                            })
                            self.objectInit.ceph.paramObj.data = res.data.data;
                            self.objectInit.ceph.selected = self.objectInit.ceph.paramObj.data[0];
                        }
                    })
                }else{
                    storageManagementSrv.getRgwType().then(res=>{
                        if(res&&res.data){
                            self.rgwTypeList = JSON.parse(res.data[0].paramValue);
                            computeParam({},self.rgwTypeList,self.objectInit.ceph)
                        }
                    })
                }
                
            }
            //对象存储-swift 获取版本
            function getSwiftVersion(){
                objectStorageSettingSrv.getSelectOptions().then(function (res) {
                    if (res && res.data && res.data.length) {
                        res.data.forEach((v, i) => {
                            if (v.paramName == 'SINGER_VERSION') {
                                v.paramValue.split(',').forEach((value, index) => {
                                    self.storageObjectInit.signerVersion.push({
                                        value: value,
                                        text: value
                                    })
                                });
                            }
                        });
                    }
                });
            }
            
            getSwiftVersion();
            self.objectInit = init();

            //对象存储-ceph-磁盘配置，判断按钮是否可点击 
            self.$watch(function(){
                return self.objectInit.checkedItems.length;
            },function(ne){
                console.log(ne);
                if(ne==1){
                    self.objectInit.diskDisabled = false;
                }else if(ne>1){
                    self.objectInit.diskDisabled = storageUtil.checkDiskConfig(self.objectInit.checkedItems);
                }else{
                    self.objectInit.diskDisabled = true;
                }
            })
            self.$on("drag-disk-end",function(e,data){
                if(data&&data.type=="OBJECTCEPH"){
                    self.objectInit.disksConfigShow = false;
                    computeParam(dragDiskCache.OBJECTCEPH.nodeUidMap,self.rgwTypeList,self.objectInit.ceph);
                }
                console.log(dragDiskCache)
                // self.$broadcast("clear-checkedbox",{type:"OBJECTCEPH"})
            })
            //磁盘不符合规则，强制提交
            self.$on("drag-force-valid",function(e,data){
                if(data.type=="OBJECTCEPH"){
                    self.forceValid = data.flag;
                    self.isDiskInvalid = false;
                }
            })
            //对象存储-ceph-磁盘配置
            self.disksConfig = function(){
                self.objectInit.disksConfigShow = true;
                self.cephProps = {
                    checkedItems:self.objectInit.checkedItems,
                    availableDiskList:self.objectInit.checkedItems,
                    type:"OBJECTCEPH",//对象存储-pool,
                    rgw:true
                }
                self.nodesNameList = self.objectInit.checkedItems.map(item=>item.prettyName);
            }
            //切换存储用途
            self.changeUse = function(obj) {
                self.$emit('storage-use-change',obj)
            }
            //切换类型(ceph,csp,swift)
            self.changeDevice = function(cur){
                self.objectInit = init(self.objectInit);
                self.submitValid = false;
            }
            //对象存储-ceph,是否共用块存储磁盘
            self.changePoolType = function(item){
                self.objectInit.currentType = item.value;
                getObjectStorageConf(item);
            }
            self.changePoolType(self.objectInit.poolTypeList[0])
            //
            self.storageConfirm = function(objectForm){
                if(objectForm.$valid){
                    let data = {
                        regionUid: localStorage.regionUid,
                        enterpriseUid: localStorage.enterpriseUid,
                        regionKey: localStorage.regionKey,
                        name: self.objectInit.name,             //名称
                        storageUse: self.objectInit.use.id,    //存储用途
                        //storageType: "2",                  //存储类型
                        storageFirm:self.storageObjectInit.current,
                        description:self.objectInit.description,
                        //storageConfiguration:JSON.stringify(self.objectInit.csp)
                    }
                    self.storageObjectInit.deviceList.forEach(item=>{
                        if(item.id==self.storageObjectInit.current){
                            data.storageType = item.value;
                        }
                    })
                    if(self.storageObjectInit.current=='object_CSP'){
                        data.storageConfiguration = JSON.stringify(self.objectInit.csp);
                    }
                    if(self.storageObjectInit.current=='object_ceph'){
                        
                        if(self.objectInit.ceph.type.id==2){
                            let meta = {};
                            let poolDiskInfo = [];
                            if(self.objectInit.ceph.selected.type == 'replicated'){
                                meta = {
                                    ceph_rgw_pool_size:self.objectInit.ceph.selected.name
                                }
                            }else{
                                meta = {
                                    ceph_erasure_data_chunks:self.objectInit.ceph.selected.k,
                                    ceph_erasure_coding_chunks:self.objectInit.ceph.selected.m,
                                }
                            }
                            meta.ceph_rgw_pool_type = self.objectInit.ceph.selected.type;
                            meta.source = self.objectInit.ceph.type.id;
                            data.shareOsd = self.objectInit.currentType==1?true:false;
                            data.storageFirm = "object_ceph_inner";
                            if(self.objectInit.currentType==1){
                                poolDiskInfo = [];
                            }else{
                                let acg = storageUtil.compDiskMap({disks:dragDiskCache.OBJECTCEPH,tableData:self.objectInit.ceph.tableData,type:"byId"});
                                poolDiskInfo = acg.data;
                                let validObj = storageUtil.checkDiskMsg(poolDiskInfo,dragDiskCache.OBJECTCEPH);
                                
                                if(self.objectInit.ceph.selected.name>validObj.num){
                                    alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.numcopy",{num:self.objectInit.ceph.selected.name}), "error", 5000);
                                    return;
                                }
                                if(validObj.arr.length>1){
                                    self.invalidMessage = $translate.instant('aws.system.storagement.storageTranslate.diffNodeSetting') 
                                    if(!self.forceValid){
                                        self.isDiskInvalid = true;
                                        return;
                                    }
                                }else{
                                    self.invalidMessage = $translate.instant('aws.system.storagement.storageTranslate.invalidMessage')
                                }
                            }
                            data.poolDiskInfo = poolDiskInfo;
                            data.storageConfiguration = JSON.stringify(meta);
                        }else{
                            data.storageFirm = "object_ceph_out";
                            self.objectInit.fieldList.source = self.objectInit.ceph.type.id;
                            self.objectInit.fieldList.signerVersion = self.objectInit.version.selected.text;
                            data.storageConfiguration = JSON.stringify(self.objectInit.fieldList);
                        }
                    }
                    if(self.storageObjectInit.current=='object_swift'){
                        self.objectInit.fieldList.signerVersion = self.objectInit.version.selected.text;
                        data.storageConfiguration = JSON.stringify(self.objectInit.fieldList);
                    }
                    storageManagementSrv.addCspStorage(data).then(function(res){
                        if(res){
                            self.initStorageTable();
                            self.$emit('storage-modal-close',{from:data.storageFirm})
                            if(self.objectInit.currentType==2){
                                dragDiskCache.OBJECTCEPH.nodeUidMap = {}
                                dragDiskCache.OBJECTCEPH.valid = true;
                            }
                        }
                    })
                    
                }else{
                    self.submitValid = true;
                }
            };
            self.$dismiss = function(){
                self.$emit('storage-modal-close',{})
            }
        }
    };
}
export {storageObjectTpl}