storageObjectSetting.$inject = [];
function storageObjectSetting() {
    return {
        restrict:"E",
        replace:true,
        scope:{},
        templateUrl:"js/system/objectStorageSetting/tmpl/objectStorageSetting.html",
        link: function(self, elem, attrs, $ngModel) {
            
        },
        controller:'objectStorageSettingCtrlNew'
    };
}
export {storageObjectSetting}