storageObjectUser.$inject = [];
function storageObjectUser() {
    return {
        restrict:"E",
        replace:true,
        scope:{},
        templateUrl:"js/system/objectStorageUser/tmpl/objectStorageUser.html",
        link: function(self, elem, attrs, $ngModel) {
            
        },
        controller:'objectStorageUserCtrl'
    };
}
export {storageObjectUser}