import './fileStorageSrv'
import './volumesQoSSrv'
angular.module('fileStorageModel', ['fileStorageSrvModel']).controller('fileStorageCtrl', ['$scope', '$rootScope', 'fileStorageSrv', 'TableCom', 'modalCom',"$translate","$http","utilTool",
    function ($scope, $rootScope, fileStorageSrv, TableCom, modalCom,$translate,$http,utilTool) {
        const self = $scope;
        self.updatedisabled = true;
        self.quotadisabled = true;
        self.deldisabled = true;
        self.pageNum = 1;
        self.pageSize = 10;
        self.total = 0;
        self.ttEquipmentData = [];
        self.globalSearchTerm = '';

        self.checkboxes = {
            checked: false,
            items: {},
            arr: []
        };
        self.msg = $translate.instant("aws.metaMsg.hint")
        self.mountMsg = $translate.instant("aws.transmissionMag.certificateUse")

        self.translate = {
            path:$translate.instant("aws.transmissionMag.nfsPath"),
            ip:$translate.instant("aws.fileStorage.clientIP"),
            permission:$translate.instant("aws.users.cu.privilege"),
            capacity:$translate.instant("aws.action.quota"),
            dssid:$translate.instant("aws.volumes.stpool"),
            protocol:$translate.instant("aws.transmissionMag.protocol"),
            noCommand:$translate.instant("aws.fileStorage.noCommand"),
            selectFuse:$translate.instant("aws.action.selectFuse"),
            mount:$translate.instant("aws.fileStorage.mount"),
            
        }

        self.downdisabled = false
        // 下载证书
        self.downcertificate = function(){
            if(self.ttEquipmentData.length == 0){
                return
            }
            var url = `${GLOBALCONFIG.APIHOST.BASE}/v2/csp/storage/downFuseKeyringFile?X-Register-Code=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}&project_id=${localStorage.projectUid}`
            utilTool.downloadFile(url);
        }

        self.snapshot_search = {
            globalSearchTerm: '',
        };
        self.statusConfig = {
            "statusSelected": {}
        }
        self.searchBackend = function (options) {
            if(options.type == 'refresh'){
                self.snapshot_search.globalSearchTerm = '';
                self.pageNum = 1;
            }
            self.initTable(self.snapshot_search.globalSearchTerm)
        }

        self.mountorder = function(value){
            if(value.mountPath){
                utilTool.copyToClip(value.mountPath, function(){
                    $rootScope.$broadcast("alert-success", '00500');
                })
            }
        }

        self.$watch(function () {
            return self.checkboxes.items;//监控checkbox
        }, function (val, old) {
            self.checkedItems = [];
            var arr = [];
            for (var i in self.checkboxes.items) {
                arr.push(self.checkboxes.items[i])
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.ttEquipmentData.forEach(item => {
                            if (item.id == key) {
                                self.checkedItems.push(item);
                            }
                        })
                    }
                }
            }
            const length = self.checkedItems.length
            if (length != 0) {
                if (length == 1) {
                    self.updatedisabled = false;
                    if(self.checkedItems[0].protocol == 'fuse'){
                        self.quotadisabled = false;
                    }
                } else {
                    self.updatedisabled = true;
                    self.quotadisabled = true;
                }
                self.deldisabled = false;
            } else {
                self.quotadisabled = true;
                self.updatedisabled = true;
                self.deldisabled = true;
            }
        }, true);

        self.initTable = function (value) {
            const config = {
                pageNum: self.pageNum,
                // pageSize: self.pageSize,
                pattern: value || ''
            }
            fileStorageSrv.getFileStorageQuota(config).then(res => {
                if (res.data&&typeof res.data == 'object') {
                    self.ttEquipmentData = res.data;
                    self.total = res.total;

                    self.ttEquipmentData.forEach(item=>{
                        const pathStrArr = item.path.split('/')
                        pathStrArr.shift()
                        pathStrArr.shift()
                        item.newpath = `/${pathStrArr.join('/')}`

                        item.capacity = item.capacity/1024/1024/1024;
                    })

                    TableCom.init(self, 'fileStorageTable', self.ttEquipmentData, 'id', self.pageSize, 'checkboxes');
                    // 关闭所有checkbox
                    self.checkboxes.items = {}
                }
                self.downdisabled = self.ttEquipmentData.length == 0
            })
        }
        self.initTable()

        self.operateFile = function (value = '') {
            if(self.quotadisabled){
                return
            }
            value = self.checkedItems[0].capacity ? 'update': 'create';
            modalCom.init('operateFileAlert.html', 'operateFileAlertCtrl', {
                context: () => self,
                type: () => value,
                checkboxes:()=> self.checkedItems
            })
        }

        self.allot = function (value = '') {
            modalCom.init('mountAlert.html', 'mountAlertCtrl', {
                context: () => self,
                type: () => value,
                checkboxes:()=> self.checkedItems
            })
        }

        self.deletemount = function () {
            var content = {
                target: "deletemountFunc",
                msg: "<span>" + $translate.instant("aws.errors.isDelete") + "</span>",
                data: self.checkedItems
            };
            self.$emit("delete", content);
        }
        self.$on("deletemountFunc", function (e, data) {
            const idList = data.map(item => item.id)
            const idStr = idList.join(',')
            console.log(idStr)
            fileStorageSrv.deleteFileStorage(idStr).then(res=>{
                self.pageNum = 1
                self.initTable()
            })
        })
    }]).controller('operateFileAlertCtrl', ['$scope', '$rootScope', 'type',"fileStorageSrv","checkboxes","context","$uibModalInstance","$translate", function ($scope, $rootScope, type,fileStorageSrv,checkboxes,context,$uibModalInstance,$translate) {
        // 新增存储配额
        const self = $scope;
        self.type = type == 'create';
        self.title = self.type ? $translate.instant("aws.action.add") : $translate.instant("aws.action.edit");
        self.quotaObj = {
            path: '',
            capacity: checkboxes[0].capacity,
            filecapacity:''
        }

        self.sumit = function(){
            const {path} = checkboxes[0]
            const {capacity} = self.quotaObj
            const config = {
                path,
                capacity: capacity * 1024 * 1024* 1024 || 0,
                files:0,
                id: checkboxes[0].id
            }
            if(self.type){// 新增
                // 配额存储
                fileStorageSrv.addFileStorage(config).then(res=>{
                    context.initTable()
                    $uibModalInstance.close();
                })
            }else{
                fileStorageSrv.addFileStorageQuota(config).then(res=>{
                    context.initTable()
                    $uibModalInstance.close();
                })
            }
        }

    }]).controller('mountAlertCtrl', ['$scope', '$rootScope', 'type', 'volumesQoSSrv',"fileStorageSrv","context","$uibModalInstance","checkboxes","$translate", function ($scope, $rootScope, type, volumesQoSSrv,fileStorageSrv,context,$uibModalInstance,checkboxes,$translate) {
        const self = $scope;
        self.type = type == 'create';
        self.title = self.type ? $translate.instant("aws.action.add") : $translate.instant("aws.action.edit");
        // self.title = ''
        self.selectList = [];
        self.isvalid = false;
        self.formMount = {
            path: '',
            IP: '',
            limits: 'rw',
            defaultValue: ''
        }

        self.sumit = function (obj, form) {
            self.isvalid = false;
            if (form.$valid) {
                const {path, IP, limits, defaultValue} = self.formMount
                const iplist = IP.split('\n')
                const project_id = localStorage.getItem('projectUid')
                const config = {
                    path: `/${project_id}${path}`,
                    permission: limits,
                    iplist,
                    protocol: self.selectValue,
                    dssid: defaultValue,
                    placementName:self.selectList.find(item=>{
                        return item.value == defaultValue
                    }).name
                }

                // 创建
                if(self.type){
                    fileStorageSrv.getFileList(config).then(res => {
                        context.initTable()
                        $uibModalInstance.close();
                    })
                }else{
                    config.id = checkboxes[0].id;
                    // 修改
                    fileStorageSrv.updateFileStorage(config).then(res => {
                        context.initTable()
                        $uibModalInstance.close();
                    })
                }
            } else {
                self.isvalid = true;
            }
        }

        const p1 = fileStorageSrv.getFileStorageDSS();
        const p2 = fileStorageSrv.getFileAgreement();
        Promise.all([p1,p2]).then(res=>{
            self.selectList = res[0].data.map(item => {
                return {
                    name: item.placementName,
                    value: item.dssid
                }
            })
            self.formMount.defaultValue = self.selectList.length == 0?'' : self.selectList[0].value;

            self.agreement = res[1].data.map(item=>{
                return {
                    name:item,
                    value:item.toLocaleLowerCase()
                }
            })
            
            self.selectValue = self.agreement.length==0 ?'':self.agreement[0].value;

            // 编辑
            if(!self.type){
                const {iplist,protocol,permission,dssid,newpath} = checkboxes[0]
                self.formMount.limits = permission
                self.formMount.defaultValue = dssid
                self.formMount.path = newpath
                self.selectValue = protocol
                const reg = /\[|\]/g
                const newArr = iplist.map(item => item.replace(reg,'').trim())
                self.formMount.IP = newArr.join('\n')
            }
        })

        self.changeValue = function(value){
            self.selectValue = value
        }

    }])
    .directive('pathfile', function () {
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                const reg = (/^\/(?:[a-zA-Z0-9-._\s*]+\/?)*(?:[a-zA-Z0-9-._]+)$/)
                ctrl.$parsers.unshift(function (value) {
                    if(!value){
                        ctrl.$setValidity('pathfile', true);
                        return value
                    }
                    if(reg.test(value) && !value.match('/ ')){
                        ctrl.$setValidity('pathfile', true);
                        return value
                    }
                    ctrl.$setValidity('pathfile', false);
                    return undefined
                })
            }
        }
    })
