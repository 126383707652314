var objectStorageSettingSrvModule = angular.module("objectStorageSettingSrvModule", []);

objectStorageSettingSrvModule.service("objectStorageSettingSrv", ["$http", function ($http) {
    return {
        saveSettingData: function (data) {
            return $http({
                method: "POST",
                url: "/awstack-user/v1/enterprise/" + localStorage.enterpriseUid + "/region/" + localStorage.regionUid + "/object/storage/conf",
                data: data
            });
        },
        getSettingData: function (data) {
            return $http({
                method: "GET",
                url: "/awstack-user/v1/enterprise/" + localStorage.enterpriseUid + "/region/" + localStorage.regionUid + "/object/storage/conf",
                params:data
            });
        },
        getSelectOptions: function () {
            return $http({
                method: "GET",
                url: "/awstack-user/v1/params?parentId=1001",
            });
        },
        getUsers: function () {
            return $http({
                method: "GET",
                url: "/awstack-resource/v1/rgw/user/unbind"
            });
        },
        getDepPro: function () {
            return $http({
                method: "GET",
                url: "/awstack-user/v1/user/domains/projects"
            })
        },
        delSettingData: function (data) {
            return $http({
                method: "DELETE",
                url: "/awstack-user/v1/enterprise/" + localStorage.enterpriseUid + "/region/" + localStorage.regionUid + "/object/storage/conf",
                params: data
            });
        },
        editSettingData: function (data) {
            return $http({
                method: "PUT",
                url: "/awstack-user/v1/enterprise/" + localStorage.enterpriseUid + "/region/" + localStorage.regionUid + "/object/storage/conf",
                data: data
            });
        }
    };
}]);