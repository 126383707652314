const zh_CN = {
    "aws": {
        "menu": {
            "resource-enhance-host-add":"绑定云主机",
            "resource-enhance-host-start":"启用云主机",
            "resource-enhance-host-forbid":"禁用云主机",
            "resource-enhance-host-unbind":"解绑云主机",
            "resource-enhance-host-recover":"恢复云主机",
            "resource-enhance-monitor-add":"新建监听器",
            "resource-enhance-monitor-update":"编辑监听器",
            "resource-enhance-monitor-start":"启用监听器",
            "resource-enhance-monitor-forbid":"禁用监听器",
            "resource-enhance-monitor-delete":"删除监听器",
            "resource-enhance-page-add":"新建负载均衡",
            "resource-enhance-page-update":"编辑负载均衡",
            "resource-enhance-page-delete":"删除负载均衡",
            "enhanceconfig":"增强型负载均衡",
            "Resource_enhance":"增强型",
            "Resource_loadBalancing":"负载均衡",
            "resource-fileStorage-addfileStorage":"添加",
            "resource-fileStorage-updatefileStorage":"编辑",
            "resource-fileStorage-quotafileStorage":"配额",
            "resource-fileStorage-delfileStorage":"删除",
            "Resource_Storage_CSP": "文件存储",
            "label-vm-add":"新建",
            "label-vm-bind":"绑定",
            "label-vm-unbind":"解绑",
            "label-vm-update":"编辑",
            "label-vm-delete":"删除",
            "resource-vm-settingHa":"设置高可用",
            "resource-vm-changeLabel":"修改标签",
            "labelModule":"标签管理",
            "certificate-add":"新建",
            "certificate-update":"编辑",
            "certificate-del":"删除",
            "loadbalancingcertificate":"证书管理",
            "resource-objectStorage-create":"新建",
            "resource-objectStorage-public":"公有化",
            "resource-objectStorage-private":"私有化",
            "resource-objectStorage-del":"删除",
            "resource-shares-create":"新建",
            "resource-shares-edit":"修改",
            "resource-shares-del":"删除",
            "resource-mkImg-uploadImage":"注册镜像",
            "resource-mkImg-openConsole":"控制台",
            "resource-security-template-del":"删除",
            "resource-security-template-edit":"编辑",
            "resource-security-template-create":"新建",
            "resource-images-download":"下载",
            "Resource_Storage_Shares": "文件存储",
            "System_SystemSetting_ObjectStorageSetting": "对象存储设置",
            "System_SystemSetting_ObjectStorageUsers": "对象存储用户",
            "Resource_Object_Storage": "对象存储",
            "System_Cephpg": "Ceph存储池PG比例管理",
            "Resource_Storage_RegularSnap": "计划任务",
            "Elastic_Expansion": "弹性扩展",
            "System_Multiplenet":"云平台物理网络互通",
            "Cvm_Scalpolicy": "扩展策略",
            "Cvm_Extension": "集群管理",
            "privatecloud": "私有云",
            "tencentcloud": "腾讯云",
            "alicloud": "阿里云",
            "aliyun":"阿里云",
            "aliyun_overview":"概况",
            "aliyun_asset":"云服务器",
            "aliyun_ecs":"云服务器",
            "aliyun_images":"镜像",
            "aliyun_resourceGroup":"资源组",
            "aliyun_deploymentSet":"部署集",
            "aliyun_volumes":"存储与快照",
            "aliyun_disk":"云盘",
            "aliyun_snapshot":"快照",
            "aliyun_network":"网络与安全",
            "aliyun_keyPair":"SSH密钥",
            "aliyun_securityGroup":"安全组",
            "aliyun_eips":"弹性公网IP",
            "aliyun_vpc":"VPC",
            "aliyun_routerTable":"路由表",
            "maas":"物理机",
            "maas_manage":"裸金属管理",
            "maas_overview":"概况",
            "maas_images":"物理机镜像",
            "maas_network":"网络",
            "maas_reginstan":"注册物理机",
            "maas_apply":"申请物理机",
            "aws":"AWS云服务",
            "aws_asset":"EC2",
            "aws_ecs":"EC2实例",
            "aws_images":"镜像",
            "aws_disk":"卷",
            "aws_subnet":"子网",
            "aws_gateway":"Internet 网关",
            "aws_snapshot":"快照",
            "aws_network":"网络与安全",
            "aws_keyPair":"密钥对",
            "aws_securityGroup":"安全组",
            "aws_eips":"弹性公网IP",
            "aws_vpc":"VPC",
            "aws_router":"路由表",
            "Resource": "虚拟资源管理",
            "Monitoring": "监控管理",
            "User": "身份管理",
            "System": "系统管理",
            "System_reportForms": "统计报表",
            "Log": "日志管理",
            "System_SystemSetting": "系统设置",
            "System_Configuration":"系统配置",
            "System_ConfigManagement":"配置管理",
            "List": "工单管理",
            "Flowing": "流程管理",
            "bigmem": "大页内存管理",
            "sriov": "SR-IOV网络管理",
            "dpdk": "DPDK管理",
            "nettype": "租户网络类型管理",
            "statisticsReport": "统计报表",
            "Resource_backupRecovery": "备份恢复",
            "Resource_backupRecovery_Strategy": "备份策略",
            "Resource_backupRecovery_Rbackups": "备份",
            "Resource_backupRecovery_Recovery": "恢复",
            "Resource_Instance": "云主机",
            "Resource_Image": "镜像",
            "Resource_Storage": "存储",
            "Resource_physicalMac": "裸金属",
            "Resource_Storage_Snapshot": "快照",
            "Resource_Storage_Backups":"备份",
            "Resource_Network": "网络",
            "Resource_Security": "安全",
            "Resource_Network_Switch": "私有网络",
            "Resource_Network_Router": "路由器",
            "Resource_Network_Physicalnetworks": "外部网络",
            "Resource_Network_Bandwidth": "总带宽",
            "Resource_Network_Floatingip": "公网IP",
            "Resource_Security_Firewall": "安全组",
            "Resource_Security_Template": "安全组模板",
            "Resource_Security_Keypair": "密钥对",
            "Resource_compute": "计算",
            "Resource_VPN": "IPSec VPN",
            "Resource_CloudDrive_Qos": "存储池QoS",
            "Resource_compute_affinity": "云主机组",
            "Resource_compute_ports": "虚拟网卡",
            "Monitoring_MOverview": "概况",
            "Monitoring_ResourceMonitor": "资源监控",
            "Monitoring_ServiceMonitor": "服务监控",
            "Monitoring_MonitorStrategy": "监控策略",
            "Monitoring_Alarm": "告警管理",
            "Resource_Network_netTopo": "网络拓扑",
            "Monitoring_NetworkTopology": "网络拓扑",
            "Monitoring_Report": "报表",
            "Resource_Network_Loader":"基础型",
            "Monitoring_ResourceMonitor_PhysicalMachine": "物理主机",
            "Monitoring_ResourceMonitor_VirtualMachine": "云主机",
            "Monitoring_ResourceMonitor_CephStorage": "Ceph存储",
            "Monitoring_ResourceMonitor_SqlDatabase": "时序数据库",
            "Monitoring_Alarm_AlarmEvent": "告警事件",
            "Monitoring_Alarm_AlarmSetting": "告警设置",
            "Monitoring_Alarm_AlarmTemplate": "告警模板",
            "Monitoring_Alarm_ContactGroup": "联系人组",
            "resource-vm-settingHa":"高可用",
            // vgpu
            "vgpu":"vGPU",
            "gpu":"物理GPU",
            //增加硬件监控菜单
            "Monitoring_Hardware":"硬件监控",
            "Monitoring_Hardware_server":"服务器",
            "Monitoring_Hardware_Switch":"交换机",
            "Monitoring_Hardware_UPS":"UPS",

            "User_PermitUser": "用户管理",
            "User_Privilege": "角色管理",
            "User_Department": "部门管理",
            "User_Organization": "组织结构",
            "User_Project": "项目管理",
            "System_Flavor_Flavor": "云主机类型",
            "System_Flavor_Aggregate": "主机集合",
            "System_Hypervisor": "虚拟机管理器",
            "System_Quota": "默认配额管理",
            "System_Node_NodeConfig": "节点管理",
            "System_Node_Cluster": "集群配置",
            "Log_OperationLog": "操作日志",
            "Log_login": "登录日志",
            "Log_SystemLog": "系统日志",
            "Log_LogAnalysis": "日志分析",
            "System_SystemSetting_CloudSetting": "混合云设置",
            "System_SystemSetting_MailServerSetting": "邮件服务器",
            "System_SystemSetting_WechatSetting": "微信设置",
            "System_SystemSetting_backup": "系统备份",
            "general": "常规设置",
            "Resource_COverview": "概况",
            "Resource_Network_Loadbalancer": "负载均衡",
            "loadbalancing": "负载均衡",
            "Flowing_FlowTask": "流程部署",
            "Flowing_FlowApply": "流程绑定",
            "Flowing_FlowProgress": "待办事宜",
            "List_Lists": "待处理工单",
            "List_ListApplication": "工单申请",
            "PublicCloud": "公有云",
            "List_Applied": "我的申请",
            "List_MyAll":"我的审批",
            "Resource_Storage_Volume": "云硬盘",
            "SOverview": "系统概况",
            "System_SystemSetting_AccessPolicy": "安全设置",
            "Resource_Image_UploadImage": "镜像管理",
            "Resource_Image_MakeImage": "制作镜像",
            "System_SystemSetting_License": "软件授权",
            "System_logoSetting": "logo设置",
            "Resource_Recycle": "回收站",
            "Resource_Recycle_vm": "云主机回收站",
            "Resource_Recycle_cs": "数据盘回收站",
            "System_Flavor": "参数管理",
            "System_About": "系统版本",
            "System_Node": "集群管理",
            "Resource_Network_NetFirewall": "防火墙",
            "Resource_Loader": "负载均衡",
            "openstackService": "云系统服务",
            "mysql": "数据库",
            "rabbitmq": "消息队列",
            "memcached": "缓存",
            "routermonitor": "路由器",
            "K8s_Clusters":"容器集群",
            "K8s_Services":"容器服务",
            "K8s":"容器管理",
            "K8s_Projects":"镜像服务",
            "Global_Setting": "设置",
            "Password_Modify": "基本设置",
            "Storage_Management": "存储管理",
            "Storage_Device": "存储设备",
            "Storage_Features": "存储特性",
            "Volume_Type": "卷类型",
            "monitor_MonCeph": "存储监控",
            "Bill_Management": "计量管理",
            "Bill_Consumeview": "消费统计",
            "Bill_Resourceprice": "价格管理",
            "System_Operation": "运维管理",
            "System_Operation_Preferences": "参数设置",
            "System_Operation_Aggregate": "主机集合",
            "System_Operation_Node": "节点管理",
            "System_Operation_CloseSaas": "关闭云平台",
            "System_Operational": "运营管理",
            "System_Operational_Flavor": "规格",
            "System_Operational_Quota": "配额管理",
            "System_SystemSetting_grade": "云平台升级",
            "System_Storage": "存储管理",
            "System_ Operational_StorageManager": "存储管理",
            "System_ Operational_Datacluster": "数据中心管理",
            "System_ Operational_Networksmanage": "多外网管理",
            "System_SystemSetting_About": "系统版本",
            "System_Operational_Bandlimit": "带宽限速",
            "System_Bandlimit": "带宽限制",
            "Monitoring_ResourceMonitor_Virtualnetwork": "虚拟网络",
            "System_Physical_Conductor": "裸金属资源管理",
            "System_Transmission_Mag": "透传设备管理",
            "System_Function_Manage": "功能管理",
            "System_SystemSetting_Systeminspection":"系统巡检",
            "qcloud": "腾讯云",
            "qcloud_Server": "云服务器",
            "qcloud_Survey": "概况",
            "qcloud_Host": "云主机",
            "qcloud_Image": "镜像",
            "qcloud_Disk": "云硬盘",
            "qcloud_Snapshot": "快照",
            "qcloud_SHHkey": "SSH密钥",
            "qcloud_Securitygroup": "安全组",
            "qcloud_Elasticip": "弹性公网IP",
            "qcloud_Specialhost": "专用宿主机",
            "qcloud_loadBalance": "负载均衡",
            "qcloud_LBInstanceList": "LB实例列表",
            "qcloud_Database": "数据库",
            "qcloud_Instancelist": "实例列表",
            "qcloud_Tasklist": "任务列表",
            "qcloud_Parametertemplate": "参数模板",
            "qcloud_cacheRedis": "弹性缓存Redis",
            "qcloud_Taskmanagement": "任务管理",
            "qcloud_VPC": "VPC",
            "qcloud_Privatenetwork": "私有网络",
            "qcloud_Subnet": "子网",
            "qcloud_Routingtable": "路由表",
            "qcloud_NATgateway": "NAT网关",
            "qcloud_dc":"专线接入",
            "qcloud_dc_dc":"物理专线",
            "qcloud_dc_passageway":"专用通道",
            "qcloud_dc_gateway":"专线网关",
            "vMware": "VMware",
            "vMware_menu": "菜单",
            "vMware_Datacenter": "数据中心",
            "vMware_Host": "主机",
            "vMware_colony": "集群",
            "vMware_virtualmachine": "虚拟机",
            "User_overView": "概况",
            "allMenu": "全部",
            "openStack": "私有云",
            "openStack_view": "私有云系统概况",
            "PhysicalResource":"裸金属管理",
            "Physical_overview":"概况",
            "Physical_Instance":"裸金属",
            "Physical_image":"裸金属镜像",
            "Physical_network":"裸金属网络",
            "System_ Operational_StorageManager": "存储管理",
//          "Resource_CloudDrive_Qos": "存储池QoS",
            "Monitoring_ResourceMonitor_Resview":"总览",
            "System_Operational_ResMetering":"资源计量",
            "System_Operational_BillingManagement":"计费管理",
            "cephView":"Ceph管理",
            "ticketReports":"工单报表",
            "K8s":"容器管理",
            "K8s_Clusters":"容器集群",
            "K8s_Services":"容器服务",
            "K8s_Projects":"镜像服务",
            "Image_Management":"镜像库迁移",
            "Resource_Network_netTopo":"网络拓扑",
            "System_SystemSetting_Maxscreen":"大屏展示",
            "PaaS":"PaaS服务",
            "Alauda_alRegister":"部署",
            //"Alauda_overview":"概况",
            "Alauda_overview":"TKE企业版控制台",
            "Alauda_calculation":"计算",
            "Alauda_configure":"配置",
            "Alauda_networks":"网络",
            "Alauda_storage":"存储",
            "Alauda_colony":"集群",
            "Alauda_others":"其他资源",
            "Alauda_administrator":"管理员设置",
            "Alauda_deploy":"部署",
            "Alauda_daemon":"守护进程集",
            "Alauda_stateful":"有状态副本集",
            "Alauda_replica":"副本集",
            "Alauda_pod":"容器组",
            "Alauda_configmap":"配置字段",
            "Alauda_secret":"保密字典",
            "Alauda_service":"服务",
            "Alauda_ingress":"访问权",
            "Alauda_networkpolicy":"网络策略",
            "Alauda_persistentvolumeclaim":"持久卷声明",
            "Alauda_persistentvolume":"持久卷",
            "Alauda_storageclass":"存储类",
            "Alauda_node":"节点",
            "Alauda_namespace":"命名空间",
            "Alauda_othersspaced":"命名空间相关资源",
            "Alauda_clustered":"集群相关资源",
            "Alauda_roles":"角色",
            "Alauda_bindings":"角色绑定",
            "Alauda":"TKE企业版",
            "ZhiYun":"织云",
            "ZY_entrance":"登录入口",
            //TBase
            "TBase":"Tbase",
            "TBase_tbClusterManage":"集群管理",
            "TBase_tbNodeManages":"节点管理",
            "TBase_tbNodeManage":"节点列表",
            "TBase_tbNodeGroup":"节点组列表",
            "TBase_tbConfigManagement":"配置管理",
            "TBase_tbBackupSettings":"备份管理",
            "TBase_tbBackupSetting":"备份设置",
            "TBase_tbBackupCheck":"备份检查",
            "TBase_tbClusterMonitor":"指标监控",
            "TBase_tbClusterMonitor":"集群监控",
            "TBase_tbNodeMonitor":"节点监控",
            "TBase_tbVolumeMonitor":"容量监控",
            "TBase_tbAlarmLists":"告警列表",
            "TBase_tbAlarmRecords":"告警记录",
            "TBase_tbAlarmUserss":"告警管理",
            "TBase_tbAlarmUsers":"告警对象",
            "TBase_tbAlarmMethods":"告警渠道",
            "TBase_tbSystemInfos":"系统设置",
            "TBase_tbSystemInfo":"系统信息",
            "TBase_tbLicenseInfo":"证书信息",
            "TBase_TStudio":"TStudio",
            //"TBase_tbUserList":"用户管理",
            //tdsql
            "relationaManagelTdsql":"关系型数据库TDSQL",
            "DatabaseManage":"数据库管理",
            "relationalTdsql":"关系型数据库TDSQL",
            "DatabaseManage":"数据库管理",
            "CTSDB_instances":"时序数据库 CTSDB",
            "TDSQL":"TDSQL",
            "TDsql_home":"集群总览",
            "TDsql_instance_Manage":"实例管理",
            "TDsql_resource":"资源管理",
            "TDsql_resource_device":"设备资源",
            "TDsql_resource_spec":"机型规格",
            "TDsql_resource_idc":"IDC信息",
            "TDsql_monitor_mysql":"DB汇总监控",
            "TDsql_proxy_base":"网关汇总监控",
            "TDsql_vip":"接入层管理",
            "TDsql_vip_lvsgroup":"VIP管理",
            "TDsql_vip_lvsmachine":"LVS资源监控",
            "TDsql_monitor":"调度与管理系统",
            "TDsql_monitor_zookeeper":"Zookeeper监控",
            "TDsql_monitor_scheduler":"Scheduler监控",
            "TDsql_monitor_manager":"Manager监控",
            "TDsql_monitor_ossserver":"OSS-服务监控",
            "TDsql_monitor_ossjob":"OSS-任务流程",
            "TDsql_monitor_osslog":"OSS-操作审计",
            "TDsql_monitor_hdfs":"HDFS监控",
            "TDsql_monitor_syncjob":"多源同步监控",
            "TDsql_monitor_kafka":"Kafka监控",
            "TDsql_osswf":"运维操作日志",
            "TDsql_alarm":"告警分析",
            "TDsql_alarm_search":"告警查询",
            "TDsql_alarm_config":"告警策略",
            "TDsql_alarm_except":"异常查询",
            "TDsql_alarm_global":"告警管理-跨集群",
            "TDsql_cluster_manage":"集群管理",
            "TDsql_business":"业务运营",
            "TDsql_business_user_database_apply":"数据库权限申请",
            "TDsql_business_user_dbcluster_grant":"实例负责人管理",
            "TDsql_sys":"系统管理",
            "TDsql_sys_user":"用户管理",
            "TDsql_sys_role":"角色权限",
            "TDsql_sys_log":"系统操作日志",
            "TDsql_sys_version":"系统版本",

            "TDsql_capacity":"容量统计",
            "TDsql_vipgroup":"VIP分组管理",
            "TDsql_vipdns":"DNS查询",
            "TDsql_monitordns":"DNS监控",
            "TDsql_sync":"数据同步",
            "TDsql_dcnjob":"DCN同步",
            "TDsql_syncjob":"多源同步",
            "TDsql_clusteradmin":"集群负责人管理",
            "TDsql_upgrade":"系统升级",
            "TDsql_autotest":"自动化测试",


            "CTSDB":"CTSDB",
            "CTSDB_instancesList":"实例列表",
            "CTSDB_operation":"运维管理",
            "CTSDB_operation_instancesManage":"实例管理",
            "CTSDB_operation_processManage":"流程管理",
            "CTSDB_equipmentManage":"设备管理",
            "CTSDB_instancesMonitor":"实例监控",
            "TBDS_entrance":"登录入口",
            "TBds":"TBDS大数据套件",
            "System_Paas_Services":"Paas授权",


            /*CMQ消息队列中间件 */
            "CMQ":"消息队列CMQ",
            "CMQ_Server":"队列服务",
            "CMQ_Topic":"主题订阅",
            "CMQ_Zone":"仓库管理",
            "CMQ_Operate":"运营中心",
            "CMQ_Cluster":"集群管理",
            "CMQ_Signaling":"信令服务管理",
            "CMQ_Access":"访问管理",
            "CMQ_Publisher":"PushServer管理",
            "CMQ_Queue":"队列管理",
            "CMQ_ManagerTopic":"主题管理",

            /*腾讯云安全 */ 
            "Tsafe":"腾讯云安全",
            "Tsafe_menu":"菜单",
            "Tsafe_yujie":"高级威胁检测系统",
            "Tsafe_yujian":"安全运营中心",
            "Tsafe_tianmu":"网络入侵防护系统",
            "Tsafe_waf":"Web应用防护系统",
            "Tsafe_sec":"云安全",
            "Tsafe_fm":"堡垒机",
            "Tsafe_ngfw":"下一代防火墙NGFW",
            
            /*云镜*/
            "CloudSecurity":"云镜",
            "CMirror_entrance":"登录入口",
            "CMirror_overview":"概览",
            "CMirror_hostManage":"主机管理",
            "CMirror_hostList":"主机列表",
            "CMirror_push":"命令推送",
            "CMirror_IntrusionCheck":"入侵检测",
            "CMirror_malware":"木马文件",
            "CMirror_login":"登录审计",
            "CMirror_bruteforce":"密码破解",
            "CMirror_LoopholeManage":"漏洞管理",
            "CMirror_vul":"安全漏洞",
            "CMirror_components":"知识库配置",
            "CMirror_vul_scan_task":"任务配置",
            "CMirror_sysManage":"系统管理",
            "CMirror_upgrade":"升级管理",
            /*coc织云*/
            "COC":"织云",
            "COC_manage":"业务入口",
            "COC_CMDB":"CMDB",
            "COC_CMDB_server":"服务器",
            "COC_CMDB_networkDevice":"网络设备",
            "COC_CMDB_idc":"IDC",
            "COC_CMDB_idcExport":"IDC出口",
            "COC_CMDB_idcLine":"IDC专线",
            "COC_CMDB_customCategory":"自定义CI",
            "COC_moduleManage":"业务",
            "COC_monitor":"监控",
            "COC_monitor_server":"服务器性能监控",
            "COC_monitor_netdevice":"网络设备",
            "COC_monitor_netoutpoint":"IDC出口",
            "COC_monitor_netspecialLine":"IDC专线",
            "COC_monitor_middleware":"中间件监控",
            "COC_monitor_selfdefineMonitor":"自定义监控",
            "COC_monitor_customBoard":"自定义看板",
            "COC_alarm":"告警",
            "COC_alarm_overview":"告警总览",
            "COC_alarm_search":"告警查询",
            "COC_alarm_startegy":"告警策略",
            "COC_alarm_shield":"告警屏蔽",
            "COC_toolmarket":"作业平台",
            "COC_toolmarket_toollist":"我的工具",
            "COC_toolmarket_publicAll":"所有工具",
            "COC_toolmarket_publicInit":"初始化工具",
            "COC_toolmarket_publicDiagnose":"诊断工具",
            "COC_toolmarket_publicRepaire":"维护工具",
            "COC_toolmarket_publicDown":"下架工具",
            "COC_toolmarket_flowlist":"编排管理",
            "COC_toolmarket_schedulelist":"定时任务",
            "COC_toolmarket_loglist":"执行记录",
            "COC_packageList":"包列表",
            "COC_cloud":"云管理平台",
            "COC_cloud_server":"主机资源池",
            "COC_cloud_users":"云账户管理",
            "COC_tenantuser":"访问管理",
            "COC_tenantuser_userlist":"用户管理",
            "COC_tenantuser_grouplist":"用户组管理",
            "COC_tenantuser_authlist":"权限管理",
            "COC_passwordlib":"密码库",
            "COC_operationlog":"操作日志",
            /*tbds*/
            "TBDS":"TBDS",
            "TBDS_tdAccess":"数据连入",
            "TBDS_tdAccess_myApply":"数据连入",
            "TBDS_hippo_access":"hippo管理",
            "TBDS_tdMertrics":"进度监控",
            "TBDS_tdAccess_wherehows":"血缘分析",
            "TBDS_easycount":"实时计算",
            "TBDS_easycount_taskmanage":"任务管理",
            "TBDS_easycount_dbmanage":"库表模板管理",
            "TBDS_workflow":"工作流",
            "TBDS_workflow_canvas":"工作流",
            "TBDS_workflow_list":"任务管理",
            "TBDS_workflow_server":"服务器配置",
            "TBDS_report":"数据展现",
            "TBDS_report_list":"自助报表",
            "TBDS_supersql":"数据分析",
            "TBDS_supersql_hive":"数据交互",
            "TBDS_data":"数据资产",
            "TBDS_data_dbmanage":"库表管理",
            "TBDS_data_meta":"数据血缘",
            "TBDS_data_map":"数据提取",
            "TBDS_operation":"运维中心",
            "TBDS_operation_overview":"全局概览",
            "TBDS_operation_components":"系统运维",
            "TBDS_operation_accesscontrol":"访问管理",
            "TBDS_operation_basefs":"文件管理",
            "TBDS_operation_moitor":"监控告警",
            "TBDS_platform":"平台管理",
            "TBDS_platform_project":"项目管理",
            "TBDS_platform_resource":"资源管理",
            "TBDS_platform_usermanage":"用户管理",
            "TBDS_platform_system":"系统设置",
            "TBDS_ml":"机器学习",
            "TBDS_ml_engineer":"我的工程",
            "TBDS_ml_others":"协作工程",
            "TBDS_ml_modelService":"模型服务",
            "TBDS_ml_modelManage":"模型管理",
            "TBDS_ml_resourceManage":"资源管理",
            "TBDS_ml_moduleManage":"模块管理",
            "TBDS_ml_adminControl":"控制台",
            //tsf 微服务框架
            "TSF":"微服务框架TSF",
            "TSF_Overview":"概况",
            "TSF_Resource":"资源中心",
            "TSF_Cluster":"集群",
            "TSF_Namespace":"命名空间",
            "TSF_Deploy":"部署组",
            "TSF_Application":"应用中心",
            "TSF_AppManagement":"应用管理",
            "TSF_Service":"服务治理",
            "TSF_Configure":"配置管理",
            "TSF_AppConfig":"应用配置",
            "TSF_GlobalConfig":"全局配置",
            "TSF_Template":"配置模板",
            "TSF_ConfigFile":"配置文件",
            "TSF_LogConfig":"日志配置",
            "TSF_Gateway":"微服务网关",
            "TSF_GatewayList":"网关配置",
            "TSF_GatewayGroup":"网关分组",
            "TSF_Maintenance":"运维中心",
            "TSF_LogSearch":"日志检索",
            "TSF_Transaction":"事务管理",
            "TSF_Topology":"服务依赖拓扑",
            "TSF_Trace":"调用链查询",
            "TSF_Alarm":"监控告警",
            "TSF_Scalable":"弹性伸缩",
            "TSF_Delivery":"交付中心",
            "TSF_Image":"镜像仓库",
            "TSF_Package":"软件仓库",
            "TSF_ServiceTemplate":"服务编排",
            "TSF_Operate":"运营管理",
            "TSF_ManagerBase":"基础模块管理",
            "TSF_ManagerZone":"可用域管理",
            "TSF_OPSServer":"应用服务管理",
            "TSF_ManagerParam":"参数配置",
            "TSF_ManagerGuide":"部署使用指南",
            "TSF_License":"授权管理",
            "MEMResource":"云资源管理",

            //天眼云镜
            "SkyCloudSecurity":"天眼云镜",
            "Sky_entrance":"租户控制台",
            "Sky_entrance_tenant":"天眼云镜",
            "Sky_entrance_manage":"管理员控制台",
            "Security":"系统保密员",
            "Security_survey":"概况",
            "Security_System_SystemSetting_AccessPolicy":"安全管理",
            "Audit":"系统审计员",
            "Audit_survey":"概况",
            "Audit_Log_OperationLog":"审计日志",
	        "System_SystemSetting_AD":"AD域服务设置",
            //CSP存储
            "CSP":"CSP存储",
            "CSP_overview":"概况",
            "CSP_object_storage":"对象存储",
            "CSP_object_storage_data_access":"存储网关",
            "CSP_object_storage_bucket":"存储桶管理",
            "CSP_object_storage_task":"上传任务管理",
            "CSP_object_storage_elasticsearch":"对象全文检索",
            "CSP_object_storage_settings":"对象存储配置",
            "CSP_file_storage":"文件存储",
            "CSP_file_storage_meta":"元数据节点",
            "CSP_file_storage_mount_fuse":"挂载管理",
            "CSP_file_storage_quota":"配额管理",
            "CSP_file_storage_meta_load_balance":"元数据负载管理",
            "CSP_file_storage_mds_performance":"元数据性能查询",
            "CSP_cluster":"资源管理",
            "CSP_cluster_ClusterMonitor":"集群监控管理",
            "CSP_cluster_storage_pool":"存储池管理",
            "CSP_cluster_host":"节点管理",
            "CSP_cluster_controller":"控制节点管理",
            "CSP_monitor":"监控告警",
            "CSP_mail":"邮件告警",
            "CSP_logs":"日志管理",
            "CSP_users":"用户管理",
            //告警管理
            "monitor-alarmSetting-create":"新建",
            "monitor-alarmSetting-edit":"编辑",
            "monitor-alarmSetting-copy":"复制",
            "monitor-alarmSetting-disable":"禁用",
            "monitor-alarmSetting-enable":"启用",
            "monitor-alarmSetting-del":"删除",
            "monitor-alarmTemp-create":"新建",
            "monitor-alarmTemp-set":"设置规则",
            "monitor-alarmTemp-edit":"编辑",
            "monitor-alarmTemp-del":"删除",
            "monitor-alarmGroup-create":"新建",
            "monitor-alarmGroup-edit":"编辑",
            "monitor-alarmGroup-del":"删除",
            //计算
            "resource-vm-create":"新建",
            "resource-vm-startServer":"启动",
            "resource-vm-stopSerever":"关机",
            "resource-vm-conIsEnable":"控制台",
            "resource-vm-addNetwork":"添加网卡",
            "resource-vm-removeNetwork":"移除网卡",
            "resource-vm-modifyFixdIp":"修改私网IP",
            "resource-vm-bindfloatingIp":"绑定公网IP",
            "resource-vm-removefloatIpDis":"解除公网IP",
            "resource-vm-changeMac":"修改MAC地址",
            "resource-vm-loadingDisk":"加载云硬盘",
            "resource-vm-detachDisk":"卸载云硬盘",
            "resource-vm-createSnap":"快照",
            "resource-vm-snapRollBack":"快照回滚",
            "resource-vm-reboot":"重启",
            "resource-vm-delSever":"删除",
            "resource-vm-editSever":"编辑主机名",
            "resource-vm-mkImg":"制作镜像",
            "resource-vm-osHang":"挂起",
            "resource-vm-osUpHang":"恢复挂起",
            "resource-vm-osSuspend":"暂停",
            "resource-vm-osUpPause":"恢复暂停",
            "resource-vm-rebuild":"一键还原",
            "resource-vm-shutdown":"强制关机",
            // "resource-vm-osUpgrade":"系统升级",
            "resource-vm-osUpgrade":"调整配置",
            "resource-vm-osHotgrade":"热升级",
            "resource-vm-addAlarm":"添加告警",
            "resource-vm-manageCD":"光盘管理",
            "resource-vm-editPsw":"修改密码",
            "resource-vm-startOrder":"启动顺序管理",
            "resource-vm-details":"云主机详情",
            "resource-vm-errorstart":"虚机异常管理",
            "resource-vmgroup-create":"新建",
            "resource-vmgroup-del":"删除",
            "resource-vmgports-create":"新建",
            "resource-vmgports-edit":"编辑",
            "resource-vmgports-del":"删除",
            "resource-vmgports-load":"加载",
            "resource-vmgports-unload":"卸载并删除",
            "resource-vm-reset":"重置状态",
            "resource-vm-modifyBandwidth":"调整带宽",
            "resource-vm-stockTransfer":"云硬盘迁移",
            "resource-vm-volumesQoS":"磁盘QoS",
            "resource-vm-networkCardConfig":"网卡安全配置",
            "resource-vm-osMigrate":"热迁移",
            "resource-vm-coldMigrate":"冷迁移",
            "resource-vm-ttEquiment":"透传设备",
            "resource-vm-bindVgpu":"绑定vGPU",
            "resource-vm-unbindVgpu":"解除绑定vGPU",
            "resource-vm-shelveServer":"搁置",
            "resource-vm-unShelveServerr":"取消搁置",
            //镜像
            "resource-images-addImages":"上传镜像",
            "resource-images-newInstance":"新建云主机",
            "resource-images-editImages":"编辑",
            "resource-images-delImages":"删除",
            "resource-mkImg-newImage":"新建镜像",
            "resource-mkImg-delImage":"删除",
            //存储
            "resource-dataVol-create":"新建",
            "resource-dataVol-newInstance":"新建云主机",
            "resource-dataVol-del":"数据盘-删除",
            "resource-dataVol-forceDelVol":"强制删除",
            "resource-dataVol-edit":"数据盘-编辑",
            "resource-dataVol-backups":"备份",
            "resource-dataVol-loadVol":"加载云硬盘",
            "resource-dataVol-uninstallVol":"卸载云硬盘",
            "resource-dataVol-createSnap":"创建快照",
            "resource-dataVol-expandVolSize":"数据盘扩容",
            "resource-sysVol-expandVolSize":"系统盘扩容",
            "resource-sysVol-details":"云硬盘详情",
            "resource-sysVol-delSever":"系统盘-删除",
            "resource-sysVol-edit":"系统盘-编辑",
            "resource-catchVol-delSever":"缓存盘-删除",
            "resource-dataVolSnap-createVol":"创建云硬盘",
            "resource-dataVolSnap-del":"删除",
            "resource-dataVolSnap-datails":"云硬盘详情",
            "resource-dataVolSnap-compile":"数据盘编辑",
            "resource-sysVolSnap-createVm":"新建云主机",
            "resource-sysVolSnap-createVol":"创建云硬盘",
            "resource-sysVolSnap-del":"删除",
            "resource-sysVolSnap-details":"云硬盘详情",
            "resource-sysVolSnap-compile":"系统盘编辑",
            "resource-sysVol-stockTransfer":"系统盘-迁移",
            "resource-dataVol-stockTransfer":"数据盘-迁移",
            "resource-volQoS-create":"新建",
            "resource-volQoS-relation":"关联存储池",
            "resource-volQoS-relieve":"解除关联",
            "resource-volQoS-del":"删除",
            
            //网络
            "resource-topp-edit":"编辑",
            "resource-privateNet-create":"新建",
            "resource-privateNet-del":"编辑",
            "resource-privateNet-edit":"删除",
            "resource-privateNet-details":"详情",
            "resource-privateNet-createSubnet":"新建子网",
            "resource-privateNet-editSubnet":"编辑子网",
            "resource-privateNet-delSubnet":"删除子网",

            "resource-outerNet-create":"新建",
            "resource-outerNet-edit":"编辑",
            "resource-outerNet-del":"删除",
            "resource-outerNet-subnet-create":"新建子网",
            "resource-outerNet-subnet-edit":"编辑子网",
            "resource-outerNet-subnet-del":"删除子网",

            "resource-router-create":"新建",
            "resource-router-edit":"编辑",
            "resource-router-gateway":"设置网关",
            "resource-router-delgateway":"删除网关",
            "resource-router-del":"删除",
            "resource-router-details":"详情",
            "resource-router-subnet-relateSubnet":"关联子网",
            "resource-router-subnet-edit":"编辑",
            "resource-router-subnet-relieveSubnet":"解除关联子网",
            "resource-router-staticRoute-addRouter":"添加路由表",
            "resource-router-staticRoute-delRouter":"删除路由表",
            "resource-router-portFor-create":"新建",
            "resource-router-portFor-del":"删除",
            "resource-publicNet-apply":"申请公网IP",
            "resource-publicNet-bind":"绑定",
            "resource-publicNet-unbind":"解绑",
            "resource-publicNet-del":"删除",
            "resource-cbd-create":"新建负载均衡",
            "resource-cbd-edit":"编辑负载均衡",
            "resource-cbd-bindPublicIp":"绑定公网IP",
            "resource-cbd-unbindPublicIp":"解绑公网IP",
            "resource-cbd-del":"删除负载均衡",
            "resource-cbd-routerDetails":"路由器详情",
            "resource-cbd-createMonitor":"新建监听器",
            "resource-cbd-editMonitor":"编辑监听器",
            "resource-cbd-enableMonitor":"启用监听器",
            "resource-cbd-disenableMonitor":"禁用监听器",
            "resource-cbd-delMonitor":"删除监听器",
            "resource-cbd-details":"详情",
            "resource-cbd-monitor-createEnd":"绑定云主机",
            "resource-cbd-monitor-edit":"编辑云主机",
            "resource-cbd-monitor-enable":"启用云主机",
            "resource-cbd-monitor-disabled":"禁用云主机",
            "resource-cbd-monitor-del":"删除云主机",
            "resource-vpn-create":"新建",
            "resource-vpn-edit":"编辑",
            "resource-vpn-enable":"启用",
            "resource-vpn-disable":"禁用",
            "resource-vpn-del":"删除",
            "resource-vpn-details":"详情",
            "resource-vpn-localSubnet-add":"添加本地子网",
            "resource-vpn-localSubnet-del":"删除本地子网",
            "resource-vpn-pointNet-add":"添加目标网络",
            "resource-vpn-pointNet-del":"删除目标网络",
            //备份恢复
            "Resource-backupRecovery-Strategy-add":"新建备份策略",
            "Resource-backupRecovery-Strategy-delete":"删除",
            "Resource-backupRecovery-Rbackups-addBackup":"新建备份",
            "Resource-backupRecovery-Rbackups-delBackup":"解除备份",
            "Resource-backupRecovery-Rbackups-createServer":"新建云主机",
            "Resource-backupRecovery-Rbackups-createVol":"新建云硬盘",
            "Resource-backupRecovery-Rbackups-del":"删除",
            "Resource-backupRecovery-Recovery":"恢复",
            //安全
            "resource-firewall-create":"新建",
            "resource-firewall-edit":"编辑",
            "resource-firewall-del":"删除",
            "associateRouter-add-btn":"关联路由器",
            "associateRouter-del-btn":"解除路由器",
            "resource-firewall-details":"详情",
            "resource-firewall-rules-create":"新建",
            "resource-firewall-rules-enable":"启用",
            "resource-firewall-rules-disable":"禁用",
            "resource-firewall-rules-del":"删除",
            "resource-keypairs-create":"新建",
            "resource-keypairs-import":"导出",
            "resource-keypairs-del":"删除",
            "resource-security-create":"新建",
            "resource-security-edit":"编辑",
            "resource-security-details":"安全组详情",
            "resource-security-del":"删除",
            "resource-security-details-create":"新建规则",
            "resource-security-details-del":"删除",
            //弹性扩展
            "resource-scalpolicy-create":"新建扩展策略",
            "resource-scalpolicy-del":"删除",
            "resource-extension-create":"新建弹性扩展",
            "resource-extension-createcvm":"添加云主机",
            "resource-extension-del":"删除",
            //备份恢复
            "resource-backupStrategy-create":"新建备份策略",
            "resource-backupStrategy-del":"删除",

            "resource-systemBackup-addBackup":"系统盘备份-新建",
            "resource-systemBackup-releasebackup":"系统盘备份-解除",
            "resource-systemBackup-addvm":"系统盘备份-新建云主机",
            "resource-systemBackup-addVolume":"系统盘备份-新建云硬盘",
            "resource-systemBackup-deletebackup":"系统盘备份-删除",

            "resource-dataBackup-addBackup":"数据盘备份-新建",
            "resource-dataBackup-releasebackup":"数据盘备份-解除",
            "resource-dataBackup-addVolume":"数据盘备份-新建云硬盘",
            "resource-dataBackup-deletebackup":"数据盘备份-删除",

            "resource-systemDisk-recovery":"系统盘恢复",
            "resource-dataDisk-recovery":"数据盘恢复",
            "monitor-alarmEvent-deal":"处理",
            "monitor-alarmEvent-ignore":"忽略",

            //回收站 云主机
            "resource-recycle-force":"强制删除",
            "resource-recycle-clean":"清除",
            "resource-recycle-reset":"恢复",
            //回收站 云硬盘
            "resource-recyclecs-clean":"彻底删除",
            "resource-recyclecs-reset":"恢复",

            //计划任务
            "system-planTask-addTask":"新建任务",
            "system-planTask-edit":"编辑任务",
            "system-planTask-del":"删除任务",
            //身份管理
            "identity-project-create":"新建项目",
            "identity-project-edit":"编辑",
            "identity-project-del":"删除",
            "identity-project-allow":"分配用户",
            "identity-users-create":"新建用户",
            "identity-users-bind":"绑定",
            "identity-users-untie":"解绑",
            "identity-users-sync":"同步",
            "identity-users-edit":"编辑",
            "identity-users-del":"删除",
            "identity-users-resetPassword":"重置密码",
            "identity-users-option":"选项",
            "identity-department-create":"新建部门",
            "identity-department-edit":"编辑",
            "identity-department-del":"删除",
            
            "identity-role-create":"新建",
            "identity-role-edit":"编辑",
            "identity-role-del":"删除",
            //操作日志
            "log-operate-exportAll":"导出全部",
            "log-login-exportAll":"导出全部",
            //资源计量
            "system-resBilling-view-leadout":"计量视图-导出",
            "system-resBilling-count-leadout":"计量统计-导出",
            "system-resBilling-UsageRate-leadout":"资源使用率-导出",
            "resource-vmgroup-search":"搜索",
            "bill-amount-satistics":"统计",
            "bill-amount-satistics1":"统计",
            "bill-charge-search":"查询",
            "bill-charge-edit":"编辑",
            "bill-charge-add":"新增",
            "bill-charge-delete":"删除",
            "bill-resource-search":"消费查询",
            "resource-vmgports-search":"搜索",
            "identity-users-details":"列表",
            "identity-project-details":"详情",
            "Report":"报表统计",
            "Report_Form":"报表统计",
            

            //TCS
            "TCS":"TCS",
            "TCS_overview_v":"总览",
            "TCS_overview":"总览",
            "TCS_platform_management":"平台服务",
            "TCS_platform_cluster_overview":"集群概览",
            "TCS_platform_cluster_management":"集群管理",
            "TCS_platform_node_management":"节点管理",
            "TCS_platform_pod_management":"Pod管理",
            "TCS_cbs":"云硬盘(CBS)",
            "TCS_cbs_operation":"运营数据",
            "TCS_cbs_operation_overview":"概览",
            "TCS_cbs_operation_trend":"运营趋势",
            "TCS_cbs_operation_user_resource":"用户资源统计",
            "TCS_cbs_management":"云硬盘管理",
            "TCS_cbs_snapshot_manage":"快照管理",
            "TCS_cbs_container_management":"容器管理",
            "TCS_cbs_management_format":"规格定义",
            "TCS_cbs_management_sub":"云盘管理",
            "TCS_cbs_snapshot_manage_list":"快照列表",
            "TCS_cbs_snapshot_manage_policy":"定期快照策略",
            "TCS_cbs_cluster_management":"集群管理",//-
            "TCS_cbs_pool_monitor":"性能监控",//-
            "TCS_cbs_storage_model_management":"存储机型管理",
            "TCS_cbs_storage_pool_management":"存储池集群",
            "TCS_cbs_snapshot_cluster":"快照集群",
            "TCS_cbs_zookeeper_cluster":"Zookeeper集群",
            "TCS_cbs_user_resource_pool":"用户资源池",
            "TCS_cbs_performance_monitor":"存储池性能监控",
            "TCS_cbs_node_xm_monitor":"存储节点性能监控",
            "TCS_cbs_cloud_disk_monitor":"云硬盘性能监控",
            "TCS_cbs_wlyp_monitor":"物理硬盘性能监控",
            "TCS_cbs_task_management":"任务管理",//-
            "TCS_cbs_disk_task":"云硬盘任务",
            "TCS_cbs_snapshot_task":"快照任务",
            "TCS_cbs_cloud_disk_qy_disk":"云硬盘迁移任务",
            "TCS_cbs_cccb_task":"存储池小表任务",
            "TCS_cbs_wldisk_edit_task":"物理硬盘更换任务",
            "TCS_cbs_cluster_yw_task":"集群运维任务",
            "TCS_cbs_event_alert":"事件查询",//-
            "TCS_cbs_sj_management":"升级管理",//-
            "TCS_cbs_event_alert_sub":"告警事件",
            "TCS_cbs_event_cluster":"集群事件",
            "TCS_cbs_pool_cluster_sj":"存储池集群升级",
            "TCS_cbs_snapshot_cluster_sj":"快照集群升级",
            "TCS_cbs_task_cluster_sj":"升级任务",
            "TCS_monitor_center":"监控中心",//-
            "TCS_monitor_overview":"监控概览",//-
            "TCS_monitor_selete":"告警查询",//-
            "TCS_monitor_srategy":"告警策略",//-
            "TCS_monitor_even_selete":"事件查询",//-
            "TCS_monitor_shield":"告警屏蔽",//-
            "TCS_monitor_srategy_model":"策略模板",//-
            "TCS_monitor_message_rule":"通知规则",//-
            "TCS_monitor_cloud_index":"云产品指标",//-
            "TCS_monitor_tcns_index":"TCNS监控面板",//-
            "TCS_monitor_set":"监控设置",//-
            "TCS_image_house":"镜像仓库",
            "TCS_image_public":"公共仓库",//-
            "TCS_image_private":"私有仓库",//-
            "TCS_cmdb":"业务CMDB",//-
            "TCS_cmdb_overview":"概览",//-
            "TCS_cmdb_select":"全局搜索",//-
            "TCS_cmdb_host_resource":"主机资源",//-
            "TCS_cmdb_business_resource":"业务资源",//-
            "TCS_cmdb_business_management":"业务管理",//-
            "TCS_cmdb_model_management":"模型管理",//-

            "TCS_message_management":"消息查询",//*
            "TCS_message_query":"邮件查询",//*
            "TCS_message_short_query":"短信查询",//*

            "TCS_log_center":"日志中心",//*
            "TCS_log_query":"日志搜索",//*
            "TCS_log_access":"日志接入",//*
            "TCS_log_check":"日志事件",//*
            "TCS_log_policy":"事件策略",//*

            "TCS_user":"用户中心",//*
            "TCS_user_management":"用户",//*
            "TCS_user_group":"用户组",//*
            "TCS_user_role":"角色权限",//*
            "TCS_user_role_policy":"权限策略",//*
            "TCS_user_check":"用户审核",//*
            "TCS_user_log":"操作日志",//*

            "TCS_codingDevOps_managemnet":"开发者工具",//*
            "TCS_codingDevOps":"CodingDevOps",//*

            "TCS_component_management":"组件管理",//*
            "TCS_component_list":"组件列表",//*
            "TCS_component_from":"组件规格",//*
            "TCS_cos":"对象存储(COS)",//-
            "TCS_cos_overview":"概览",//-
            "TCS_cos_bucket":"存储桶列表",//-
            "TCS_cos_monitor":"监控报表",//-
            "TCS_cos_monitor_report_basic":"基础数据统计",
            "TCS_cos_monitor_report_code":"返回码统计",
            "TCS_cos_access":"接入层运维",//-
            "TCS_cos_access_cluster":"集群管理",
            "TCS_cos_access_machine":"机器管理",
            "TCS_cos_access_service":"服务管理",
            "TCS_cos_access_task":"任务管理",
            'TCS_cos_index':'索引运维',//-
            'TCS_cos_index_cluster_info': '集群详情',
            'TCS_cos_index_diagnose': '集群诊断', 
            'TCS_cos_index_log':'日志搜索',
            'TCS_cos_index_instance_profilling':'实例性能分析',
            "TCS_cos_store":"存储运维",//-
            'TCS_cos_store_cluster_overview': '集群概览',
            'TCS_cos_store_cluster_search':'集群管理',
            'TCS_cos_store_machine_search':'机器查询',
            'TCS_cos_store_service_search':'服务查询',
            'TCS_cos_store_task_manage':'任务管理',
            'TCS_cos_store_libra_task':'Libra任务',
            'TCS_cos_store_version_manage':'版本管理',
            'TCS_cos_store_disk_replace':'硬盘更换任务',
            "TCS_cos_monitor_alarm":"监控告警",//*
            'TCS_cos_monitor_graph_main':'总体视图', 
            'TCS_cos_monitor_graph_detail':'详细视图',
            'TCS_cos_monitor_alert_config':'告警配置',
            'TCS_cos_monitor_alarm_sj':'运营数据',//-
            'TCS_cos_operate_overview':'运营总览',
            'TCS_cos_operate_user':'用户数据',
            'TCS_cos_user_manage':'用户管理',//-
            'TCS_cos_user_bucket_manage':'Buket管理',
            'TCS_cos_user_frequenc_manage':'频控管理',
            'TCS_cos_user_flow_manage':'流控管理',
            'TCS_cos_user_quota_manage':'配额管理',
            "TCS_db_redis":"数据库Redis",//-
            "TCS_db_redis_equipment":"设备管理",//-
            "TCS_db_redis_instacne":"实例列表",//-
            "TCS_db_redis_process":"流程列表",//-
            "TCS_db_redis_alarm":"告警列表",//-
            "TCS_db_sql":"TBSQL(赤兔)",//-
            "TCS_db_sql_chitu":"TBSQL",//--
            "TCS_tsf":"微服务平台(TSF)",
            "TCS_tsf_resource":"资源运营",//--
            "TCS_tsf_resource_basic":"基础模块管理",
            "TCS_tsf_resource_zone":"可用区管理",
            "TCS_tsf_resource_config":"参数配置",
            "TCS_tsf_resource_guide":"部署使用指引",
            "TCS_tsf_tool_log_search":"日志检索",
            "TCS_tsf_tool_topology":"服务依赖拓扑",
            "TCS_tsf_tool_trace":"调用链查询",
            "TCS_tsf_tool":"运营工具",
            "TCS_tsf_monitor":"监控",
            "TCS_tsf_monitor_module":"模块监控",
            "TCS_tsf_monitor_resource":"资源监控",
            "TCS_tsf_manage_liccense":"许可管理",
            "TCS_tsf_version_manage":"版本信息",
            "TCS_tenant":"微服务平台(租户)",
            "TCS_tenant_overview":"概览",
            "TCS_tenant_cluster":"集群",
            "TCS_tenant_namespace":"命名空间",
            "TCS_tenant_group":"部署组",
            "TCS_tenant_apply_manage":"应用管理",
            "TCS_tenant_service_manage":"服务治理",
            "TCS_tenant_config":"配置管理",
            "TCS_tenant_config_app":"应用配置",
            "TCS_tenant_config_global":"全局配置",
            "TCS_tenant_config_template":"配置模板",
            "TCS_tenant_config_file":"文件配置",
            "TCS_tenant_log":"日志服务",
            "TCS_tenant_log_config":"日志配置",
            "TCS_tenant_log_retrieve":"日志检索", 
            "TCS_tenant_log_alert":"日志告警", 
            "TCS_tenant_depend_on":"依赖分析", 
            "TCS_tenant_depend_on_service":"服务依赖拓展", 
            "TCS_tenant_depend_on_trace":"调用链查询", 
            "TCS_tenant_monitor":"监控", 
            "TCS_tenant_monitor_service":"服务监控", 
            "TCS_tenant_monitor_jvm":"JVM监控", 
            "TCS_tenant_gray_scale":"全链路灰度发布", 
            "TCS_tenant_scalable":"弹性伸缩", 
            "TCS_tenant_micro_service":"微服务网关", 
            "TCS_tenant_micro_service_gateway":"网关监控", 
            "TCS_tenant_micro_service_group":"分组管理", 
            "TCS_tenant_micro_service_plugin":"插件管理", 
            "TCS_tenant_micro_service_redirect":"重定向配置", 
            "TCS_tenant_distributed":"分布式任务调度", 
            "TCS_tenant_distributed_task":"任务管理", 
            "TCS_tenant_distributed_execute_records":"执行记录", 
            "TCS_tenant_distributed_workflow_manage":"工作流管理", 
            "TCS_tenant_image_manage":"镜像仓库", 
            "TCS_tenant_template_manage":"服务编排", 
            "TCS_tenant_role_manage":"角色管理", 
            "TCS_tenant_data_manage":"数据集管理", 
            "TCS_tenant_operate_log":"操作记录", 
            "TCS_tdmq":"消息队列(TDMQ)", 
            "TCS_tdmq_cluster_manage":"集群管理", 
            "TCS_tdmq_namespace":"命名空间", 
            "TCS_tdmq_topic_manage":"Topic管理", 
            "TCS_tdmq_message_search":"消息查询", 
            "TCS_tdmq_role_manage":"角色管理", 
            "TCS_tdmq_cmq":"CMQ", 
            "TCS_tdmq_cmq_queue":"队列服务", 
            "TCS_tdmq_cmq_topic":"主体订阅", 
            "TCS_tdmq_operate":"运维管理", 
            "TCS_tdmq_operate_cluster":"集群管理", 
            "TCS_tdmq_operate_cluster_broker":"Broker", 
            "TCS_tdmq_operate_cluster_bookie":"Bookie", 
            "TCS_tdmq_operate_enviroment":"命名空间", 
            "TCS_tdmq_operate_topic":"Topic管理", 
            "TCS_tdmq_operate_subsciption":"订阅管理", 
            "TCS_tdmq_operate_producer":"生产关系", 
            "TCS_tdmq_operate_consumer":"消费管理", 
            "TCS_km8":"秘钥管理服务(KM8)", 
            "TCS_km8_kms_key":"用户密匙", 
            "TCS_api":"API官网", 
            "TCS_api_gateway_overview":"概览", 
            "TCS_api_gateway_service":"服务", 
            "TCS_api_gateway_plugin":"插件", 
            "TCS_api_gateway_use_plan":"使用计划", 
            "TCS_api_gateway_app":"应用", 
            "TCS_api_gateway_key":"密匙", 
            "TCS_api_resource":"资源管理", 
            "TCS_api_resource_index":"Master管理", 
            "TCS_api_resource_cluster":"集群管理", 
            "TCS_api_resource_ld":"LD管理", 
            "TCS_api_resource_vip":"VIP管理", 
            "TCS_api_resource_vip_group":"VIP组管理", 
            "TCS_api_service":"服务管理", 
            "TCS_api_service_statistics":"服务统计", 
            "TCS_api_resource_service_statistics":"证书管理", 
            "TCS_ckafka":"消息队列(CKafka)", 
            "TCS_ckafka_instance_list":"实例列表", 
            "TCS_ckafka_operate":"运维管理", 
            "TCS_ckafka_operate_instance":"实例管理", 
            "TCS_ckafka_operate_cluster":"集群管理", 
            "TCS_ckafka_operate_broker":"Broker管理", 
            "TCS_ckafka_operate_master":"Master管理", 
            "TCS_apply_center":"应用中心", 
            "TCS_apply_center_application":"应用概览", 
            "TCS_apply_center_app_list":"应用列表", 
            "TCS_apply_center_app_market":"应用市场", 
            "TCS_apply_center_app_template":"模板发布", 
            "TCS_apply_center_project_manage":"项目管理", 
            "TCS_apply_center_config_manage":"配置管理", 

            "Resource_Local_Disk":"本地磁盘",
            "resource-vm-clone":"云主机克隆",
            "local-disk-add":"新建",
            "local-disk-edit":"编辑",
            "local-disk-attach":"挂载",
            "local-disk-detach":"卸载",
            "local-disk-delete":"删除",
            "resource-vm-attachLocalDisk":"加载本地磁盘",
            "resource-vm-detachLocalDisk":"卸载本地磁盘",
            "cloudDatabase":"云数据库",
            "Resource_Recycle_Db":"云数据库回收站",
            "cloudDatabase-create":"新建",
            "cloudDatabase-startServer":"启动",
            "cloudDatabase-stopSerever":"关机",
            "cloudDatabase-conIsEnable":"控制台",
            "cloudDatabase-modifyFixdIp":"修改私网ip",
            "cloudDatabase-bindfloatingIp":"绑定公网ip",
            "cloudDatabase-removefloatIpDis":"解绑公网ip",
            "cloudDatabase-createSnap":"快照",
            "cloudDatabase-snapRollBack":"快照回滚",
            "cloudDatabase-volumesQos":"磁盘Qos",
            "cloudDatabase-networkCardConfig":"网卡安全配置",
            "cloudDatabase-stockTransfer":"云硬盘迁移",
            "cloudDatabase-reboot":"重启",
            "cloudDatabase-editSever":"编辑主机名",
            "cloudDatabase-delSever":"删除",
            "cloudDatabase-reset":"重置状态",
            "cloudDatabase-osMigrate":"热迁移",
            "cloudDatabase-coldMigrate":"冷迁移",
            "cloudDatabase-addAlarm":"添加告警",
            "cloudDatabase-editPsw":"修改密码",
            "cloudDatabase-osHotgrade":"热升级",
            "cloudDatabase-osUpgrade":"调整配置",
            "cloudDatabase-changeLabel":"修改标签",
            "cloudDatabase-errorstart":"虚拟机异常管理",
            "Resource-Recycle-Db-Clean":"清除",
            "Resource-Recycle-Db-Reset":"恢复",

            "AdvFunction":"高级功能",
            "AdvFunction_Backup":"备份服务",
            "AdvFunction_Backup_Service":"备份配置",
            "AdvFunction_Backup_Job":"数据备份",
            "AdvFunction_Backup_Recover_Job":"数据恢复",
            "AdvFunction_Backup_Clean_Job":"数据清理",
            "AdvFunction_Backup_Strategy":"备份策略",
            "adv-backup-job-create":"任务-创建",
            "adv-backup-job-edit":"任务-修改",
            "adv-backup-job-delete":"任务-删除",
            "adv-backup-job-bind-instance":"任务-关联云主机",
            "adv-backup-job-unbind-instance":"任务-删除云主机",
            "adv-backup-job-stop":"监控-停止",
            "adv-backup-job-inst-delete":"历史记录-删除",
            "adv-backup-service-create":"保存配置",
            "adv-backup-service-delete":"删除配置",
            "adv-backup-service-sync":"数据同步",
            "adv-backup-recover-job-create":"数据恢复-新建",
            "adv-backup-recover-job-stop":"数据恢复-停止",
            "adv-backup-recover-job-delete":"数据恢复-删除",
            "adv-backup-clean-job-create":"数据清理-新建",
            "adv-backup-service-sync-strategy":"数据同步策略",
        },
        "menuTitle":{
            "Tsafe_yujie":"高级威胁检测系统-NTA（御界）",
            "Tsafe_yujian":"安全运营中心-SOC（御见）",
            "Tsafe_tianmu":"网络入侵防护系统-NIPS（天幕）"
        },
        "recovery":{
            "header":{
                  "name":"备份名称",
                  "type":"备份类型",
                  "state":"状态",
                  "examine":"健康检查",
                  "port":"端口",
                  "instanceName":"云主机名称",
                  "systemDiskSize":"系统盘大小",
                  "ipaddress":"IP地址",
                  "backupMode":"备份方式",
                  "status":"恢复状态",
                  "recoveryTime":"恢复时间",
                  "backupTime":"备份时间",
                  "createTime":"创建时间",
                  "size":"大小",
                  "backupSize":"备份大小",
                  "diskSize":"云硬盘大小",
                  "backupStatus":"状态",
                  "diskName":"云硬盘名称",
                  "instance":"云主机",
                  "systemDisk":"系统盘",
                  "dataDisk":"数据盘",
                  "executionTime":"执行时间",
                  "executionResult":"执行结果",
                  "currentBackupTime":"当前备份时间",
                  "nextBackupTime":"下一次备份时间",
                  "Recoverydetails":"详情",
                  "Resumerecord":"恢复记录",
            },
            "backupType":{
                  "recovery":"恢复",
                  "backup":"备份",
                  "recoverDetail":"恢复详情",
                  "system":"系统盘",
                  "data":"数据盘",
                  "backupDetail":"备份详情",
                  "backupRecord":"恢复记录",
                  "systemAddmainframe":"使用系统盘备份新建云主机"
            },
            "unit":{
                  "once":"",
                  "hour":"小时",
                  "day":"天",
                  "week":"周",
                  "month":"月",
                  "minute":"分钟"
            }
        },
        "tdsql":{
            "header":{
                  "id_name":"ID/实例名",
                  "status":"运行状态",
                  "type":"实例类型",
                  "edition":"实例版本",
                  "specifications":"规格",
                  "address":"内网地址",
            },
            "alarmsType":{
                  "0":"正常",
                  "1":"短信告警",
                  "2":"NOC告警",
            },
            "instatus":{
                  "0":"未初始化",
                  "1":"运行",
                  "2":"隔离",
                  "3":"创建中",
                  "4":"删除中",
                  "5":"初始化中",
                  "9":"初始化中"
            },
            "instanceType":{
                  "0":"分布式实例",
                  "1":"非分布式实例"
            },
            "edition":{
                  "2":"标准版（一主一从）",
                  "3":"标准版（一主二从）"
            },
            "syncType":{
                  "0":"异步",
                  "1":"强同步（可退化）"
            },
            "placeholder":{
                  "accountName":"请输入账号名称",
                  "hosts":"请输入主机名",
                  "password":"请输入密码",
                  "updateTime":"更新时间",
                  "remarks":"请输入备注",
                  "readOnlyAccountMsg":"如果选是，您可以在点击确定后，设置只读账号的参数",
                  "hostsMsg":"IP形式，IP段以%结尾；支持填入%，127.0.0.1；为空默认等于%",
                  "passwordMsg":"密码长度为8-16个字符且至少包含一个大写字母，一个小写字母，一个数字和一个特殊字符。特殊字符包括! - @ . ^ _ % , : /",
                  "verificationErrorMsg":"请输入6位有效数字",
                  "phoneErrorMsg":"请输入正确的手机号",
                  "selectPieceSpec":"请选择分片规格",
                  "hostsWaring":"只有%代表所有IP均可访问，可能存在安全问题，请谨慎选择"
            },
            "alert":{
                  "addPiece":"新增分片意味着您的分片数量和逻辑实例配置都会增加，新增分片时将自动再均衡数据。",
                  "extensionPiece":"扩容单个分片将提升分片性能和容量，但不会再均衡数据."
            },
            "detail":"详情",
            "alarms":"告警查询",
            "insDetail":"实例详情",
            "piecewiseManage":"分片管理",
            "insMonitor":"实例监控",
            "paramConfig":"参数设置",
            "acconutconfig":"账号设置",
            "baseMsg":"基础信息",
            "configMsg":"配置信息",
            "loadBalancingConfig":"负载均衡配置",
            "availableMsg":"可用性信息",
            "insName":"实例名称",
            //新增
            "pieceinsName":"分片名称",
            "pieceinsVersion":"分片版本",
            "piecenewPieceSpec":"扩容分片规格",
            "piecenewPieceDisk":"扩容分片硬盘",
            //新增结束
            "insId":"实例ID",
            "status":"状态",
            "insType":"实例类型",
            "insVersion":"实例版本",
            "area":"地域",
            "innerNetAddress":"内网地址",
            "port":"端口",
            "belongNet":"所属网络",
            "outerBetAddress":"外网地址",
            "belongProject":"所属项目",
            "character":"字符集",
            "sqlVersion":"数据库版本",
            "configuration":"配置",
            "backupsAndLog":"备份与日志空间",
            "usedTotal":"已使用/总容量",
            "nodeNum":"节点数量",
            "createTime":"创建时间",
            "billMode":"计费模式",
            "deadline":"到期时间",
            "copytype":"数据复制方式",
            "clusterName":"所属集群",
            "mainLibrary":"主库可用区",
            "secondLibrary":"从库可用区",
            "modifyAuthority":"修改权限",
            "copyAccount":"克隆账号",
            "resetPwd":"重置密码",
            "accountName":"账号名称",
            "hosts":"主机",
            "accountType":"账号类型",
            "updateTime":"更新时间",
            "remarks":"备注",
            "createAccount":"新建账号",
            "openReadonlyAccount":"创建为只读账号",
            "readonlyAccount":"开启强同步",
            "hosts":"主机",
            "password":"设置密码",
            "confirmPwd":"确认密码",
            "remarks":"备注",
            "authorizedObject":"授权对象",
            "allSelected":"全选",
            "phone":"手机号码",
            "getVerification":"获取验证码",
            "verification":"验证码",
            "confirmDelAccount":"您确定删除所选账号吗？",
            "delAccount":"删除账号",
            "saveModifyValue":"保存修改值",
            "modifyValue":"修改运行值",
            "templateImport":"从模板导入",
            "paramName":"参数名称",
            "needRestart":"是否需要重启",
            "paramDefault":"参数默认值",
            "currentValue":"当前运行值",
            "availableModifyValue":"可修改参数值",
            "modifyDataSyncType":"修改数据同步方式",
            "addPiece":"新增分片",
            "dilatationSlice":"扩容分片",
            //新增
            "deleteSlice":"删除分片",
            "0":"运行中",
            "100":"运行中",
            "102":"运行中",
            "1":"隔离",
            "2":"隔离",
            "cpu":"CPU",
            "memery":"内存",
            "version":{
                  "2":"一主一从",
                  "3":"一主二从"
            },
            //新增结束
            "pieceId":"分片ID",
            "sqlTransmissionId":"SQL透传ID",
            "monitor":"监控",
            "pieceVersion":"分片版本",
            "specifications":"规格",
            "diskUsageRate":"磁盘空间使用率",
            "currentPieceSpec":"当前分片规格",
            "newPieceSpec":"新增分片规格",
            "newPieceDisk":"新增分片硬盘",
            "dataRebalancingRatio":"数据再均衡比例",
            "updateWasteTime":"升级耗时",
            "extensionPiece":"扩容分片",
            "addPiece":"新增分片"
        },
        "serviceTip":{
            "begin":"请到",
            "ceph":"对接超融合ceph后再使用此模块",
            "backups":"对接备份存储后再使用此模块",
            "cinder":"对接共享存储后再使用此模块",
            "k8s": "安装容器服务后再使用此模块",
            "localDisk":"对接本地磁盘资源池后再使用此模块",
            "beginManage":"请联系管理员到",
            "used":"如需使用",
            "pleaseOpen":"模块功能，请打开",
            "concatManage":"模块功能，请联系管理员到",
            "interfaceAdd":"界面添加",
            "module":"模块",
            "pvmResourceManage":"裸金属管理",
            "pvmInstances":"裸金属管理",
            "k8sClusters":"容器管理",
            "pvmInstancesMenu":{
                "systemManage":"系统管理",
                "systemSetting":"系统设置",
                "functionManage":"功能管理",
            },  
        },
        "guide":{
            "please":"请到”",
            "backups":"对接备份存储后再使用此功能。",
            "cinder":"对接共享存储后再使用此功能。"
        },
        "datacluster":{
            "virtualNode":"虚拟资源节点",
            "containerNode":"容器节点",
            "detail":"详情",
            "clusterName":"数据中心名称",
            "cluster":"数据中心",
            "clusterNameHolder":"请输入数据中心名称",
            "deployedTips":"此数据中心还未部署，是否立即部署？",
            "ImmediateDeployment":"立即部署",
            "regionAre":"数据中心",
        },
        'vgpu':{
            'name':"规格名称",
            "info":"规格信息",
            "physicsGpu":"物理GPU",
            "nodeInfo":"节点信息",
            "instance":"云主机",
            "createTime":"创建时间",
            "newVgpu":"新建vGPU规格",
            "chooseNode":"选择节点",
            "chooseNodeHolder":"请选择节点",
            "physiclGpu":"物理GPU设备",
            "standar":"分片规格",
            "vgpuInstance":"vGPU实例数",
            "del":"您确定删除所选vGPU规格吗？",
            "vgpuTips":"将根据所选择的的规格，对NVIDIA物理GPU进行分片虚拟化",
            "detail":{
                "vgpuType":"vGPU类型",
                "video":"Virtual Display Heads",
                "dpi":"最大分辨率",
                'number':"最大分片数",
                'frame':"显存大小(MB)"
            }
        },
        "gpu":{
            "type":"GPU型号",
            "vgpuType":"vGPU类型",
            "pci":"PCI地址",
            "videoMemory":"显存大小(M)",
            "video":"Virtual Display Heads",
            "dpi":"最大分辨率",
            "instNum":"最大实例数",
            "node":"物理节点"
        },
        "indextran": {
            "selectpolysalt":"请选择聚合盘",
            "polysalt":"聚合盘",
            "average":"平均值",
            "peak":"峰值",
            "ceph": "存储",
            "memory": "内存",
            "platform": "当前云平台",
            "warning": "为了更方便地使用云管理平台，建议立即进行推荐提示中的相关操作，请点击",
            "set": "设置",
            "detail": "查看详情",
            "installK8s":"请先安装容器服务",
            "installIronic":"请先安装物理机服务",
            "resource_70": "资源使用率超过70%，请及时扩容。",
            "resourceOverflow": "资源使用率超过",
            "pleaseExpand": "请及时扩容。",
            "expansions": "否则将会影响节点宕机时的云主机自恢复能力。如有疑问请联系技术服务人员。",
            "expansion": "请及时扩充节点，否则将会影响节点宕机时的云主机自恢复能力。如有疑问请联系技术服务人员。"
        },
        "function":{
            "functiontype":{
                "MaaS":"MaaS裸金属管理",
                "phy":"裸金属管理",
                "con":"容器管理",
                "log":"安装日志",
                "detail":"详情",
                "add":"添加选项",
                "type":"请选择添加类型",
                "useceph":"是否使用ceph集群",
                "masterip":"K8S master节点IP地址",
                "masteripholder":"请输入K8S  master节点IP",
                "masterippwd":"K8S master节点root密码",
                "password":"请输入密码",
                "masterNodeName":"master节点数据盘名称",
                "masterNodeNameHolder":"请输入node节点数据盘名称",
                "yes":"是",
                "no":"否",
                "gateway":"网关",
                "endip":"结束IP",
                "startip":"起始IP",
                "endipholder":"请输入结束IP",
                "startipholder":"请输入起始IP",
                "nodeip":"K8S node节点IP地址",
                "nodeipholder":"请输入K8S node节点IP",
                "nodepwd":"K8S node节点root密码",
                "nodepwdholder":"请输入密码",
                "nodeName":"node节点数据盘名称",
                "nodeNameholder":"请输入node节点数据盘名称",
                "floatip":"裸金属业务网络",
                "RGW":"对象存储",
                "param":"参数",
                "replicated":"存储副本数",
                "url":"URL",
                "port":"端口",
                "password":"密码",
                "ip":"IP",
                "yujie":"高级威胁检测系统",
                "yujian":"安全运营中心",
                "tianmu":"网络入侵防护系统",
                "waf":"Web应用防护系统",
                "sec":"云安全",
                "fm":"堡垒机",
                "ngfw":"下一代防火墙NGFW",
                "account":"账号",
                "apikey":"API访问密钥",
                "apivalue":"API访问私钥",
                "popover":{
                    "account":"请使用管理员账号进行对接"
                },
                "erasure":"纠删码",
                "erasureTip":"k表示数据块，m表示校验块",
                "objectStorageTip":"对象存储对接完成之后，您可以去运维管理-Ceph PG管理 修改存储池比例，自动调整PG",
                "placeholder":{
                    "param":"请选择参数",
                    "port":"请输入端口",
                    "url":"请输入URL",
                    "ip":"请输入IP",
                    "account":"请输入账号",
                    "password":"请输入密码",
                    "apikey":"请输入API访问密钥",
                    "apivalue":"API访问私钥",
                }
            },
            "tips":{
                "hardware":"该网络将dhcp广播发现并收集裸金属硬件配置，该网络和集群网络复用一个裸金属网口，此网段IP范围中建议有不少于5个IP地址。",
                "business":"此网络用于给物理机分配可以对外提供业务的IP地址，所以该网络地址范围不能与云主机外部网络重叠。",
                "contips":"清理操作将清除您的容器管理服务及其环境，请确认",
                "phytips":"清理操作将清除您的裸机管理服务及其环境，请确认",
                "cephtips":"该功能需要ceph存储的支持，请确保ceph存储对接完成，再操作！",
                "addphytips":"裸金属业务网络、云主机外部网络存在冲突，请确认填写的IP地址段",
                "addphytips1":"网关与裸金属业务网络、云主机外部网络存在冲突，请确认填写的网关地址",
                "cidrtips":"IP地址不在CIDR范围之内",
                "gateway":"请输入裸金属管理网关",
                "iptips":"结束IP地址必须比起始IP地址大",
                "masteriptips":"格式错误，单个节点地址192.168.1.1，多个节点地址192.168.1.1-192.168.1.111或192.168.1.1,192.168.2.111可组合用逗号分隔",
                "noEmpty":"ip地址不能为空",
                "disktips":"正确格式例如：/dev/sdc",
                "ciderNoEmpty":"CIDR不能为空",
                "cidrholder":"格式类似：10.0.1.0/24",
                "phyGuide":{
                    "1":"裸金属管理功能需要注册裸金属才能使用，请到 产品和服务 > 系统管理 > 运维管理 > ",
                    "2":"纳管裸金属",
                    "3":" > 资源池裸金属模块 注册资源池裸金属。",
                }
            },
            "floating":{
                "cidr":"业务网CIDR",
                "gateway":"业务网网关",
                "startip":"业务网起始IP",
                "endip":"业务网结束IP"
            },
            "operations":{
                'del':"删除记录",
                "add":"安装",
                "clean":"清除",
                "cleanEnvironment":"清除环境",
                "create":"添加"
            },
            "header":{
                "name":"功能名称",
                "progress":"进度",
                "status":"操作状态",
                "addtime":"添加时间",
                "handle":"操作"
            },
            "status":{
                // "11":"准备",
                // "12":"添加中",
                // "13":"失败",
                // "4":"成功"
                "1":"就绪",
                "2":"安装中",
                "3":"安装成功",
                "4":"安装失败",
                "5":"清除中",
                "6":"清除成功",
                "7":"清除失败"
            },
            //pluginStatus
            "contentStatus":{
                "4":"失败",
                "3":"成功",
                "2":"处理中",
                "1":"开始",
                "0":"就绪",
                "8":"初始化中",
                "9":"初始化失败"
            },
            //statusType
            "contentStatusType":{
                "clean":"清除",
                "install":"安装",
                "ready":""
            },
            "paas":{
                "0":"安装成功",
                "1":"安装失败",
                "2":"自动导入失败",
                "TDSQL":"TDSQL分布式数据库",
                "CTSDB":"CTSDB时序数据库",
                "TSF":"TSF",
                "CloudSecurity":"云镜",
                "TBase":"Tbase海量事务处理平台",
                "COC":"织云",
                "TBDS":"TBDS大数据套件",
                "CMQ":"消息队列中间件",
                "APIGateway":"API网关",
                "BlueKing":"蓝鲸",
                "Alauda":"TKE企业版",
                "SkyCloudSecurity":"天眼云镜",
                "maas":"MaaS裸金属管理",
                "CSP":"CSP存储",
                "sec":"云安全",
                "TCS_API_KEY":"TCS",
                "yujie":"高级威胁检测系统-NTA（御界）",
                "yujian":"安全运营中心-SOC（御见）",
                "tianmu":"网络入侵防护系统-NIPS（天幕）",
                "waf":"Web应用防护系统-WAF",
                "fm":"堡垒机",
                "ngfw":"下一代防火墙-NGFW",
            },
            "objectStorage":{
                "0":"就绪状态",
                "2":"安装中",
                "3":"安装成功",
                "4":"安装失败",
            },
        },
        "common": {
            "bindHostTitleMsg":"绑定后，请确认云主机已放通对应安全组规则。",
            "assignIP":"指定IP",
            "IPsite":"IP地址",
            "certificatetype":"证书类型",
            "SSL":"SSL解析方式",
            "SSLdescribe":"单向认证，仅进行服务器端认证，如需认证客户端身份，请选择双向认证。",
            "onecertificate":"单向认证",
            "twocertificate":"双向认证",
            "nocertificate":"无证书",
            "caCertificate":"CA证书",
            "serverCertificate":"服务器证书",
            "resetTheState":"重置状态",
            "warmPrompt":"温馨提示：在物理服务器上，通过手动添加外部网络后，在此页面点击“＋添加”完成此外部网络的对接。完成对接后可点击“保存”按钮，保存此次设置，保存后可以在“虚拟资源管理→网络→外部网络”中添加此外部网络。",
            "setTimingExamine":"设置定期巡检",
            "receiveEmail":"接收人邮箱",
            "executionTime":"执行时间",
            "timingExamine":"定期健康巡检",
            "timingExamineStatus":"定期健康巡检状态",
            "userContinuous":"：当用户连续",
            "noLogin":"不能尝试登录平台",
            "pwdIntroduce":"密码验证介绍",
            "pwdHint":"用户连续输入错误登录信息超过规定次数后，该用户将被锁定。",
            "selectDimensionality":"查询维度",
            "yes": "是",
            "no": "否",
            "active": "运行",
            "down": "停止",
            "loading": "数据加载中......",
            "empty": "暂无数据",
            "prompts": "提示信息",
            "inUsed": "已使用",
            "available": "可使用",
            "unreasonable": "数据不合理",
            "noData": "暂无数据",
            "noHistoryData": "暂无历史数据",
            "dataError": "数据错误",
            "seeAll": "查看全部",
            "progress": "完成百分比",
            "progressrate": "速率",
            "added": "已添加",
            "noMonitorData": "暂无可监控虚拟机的数据",
            "defaultDepar": "默认部门",
            "defaultProject": "默认项目",
            "have":"有",
            "none":"无",
            "chooseSrc":"选择源",
            "blank":"空白盘",
            "volume":"云硬盘",
            "image":"镜像",
            "img":"镜像",
            "snapshot":"快照",
            "snap":"快照",
            "backup":"备份",
            "quotaInfo":"配额信息",
            "backupQuota":"备份个数配额",
            "backupGigQuota":"备份容量配额",
            "name":"名称",
            "domain":"部门",
            "project":"项目",
            "size":"大小",
            "storage":"存储",
            "systemDisk":"系统盘",
            "dataDisk":"数据盘",
            "noImage":"没有可用镜像",
            "noVolume":"没有可用云硬盘",
            "noSnapshot":"没有可用快照",
            "noBackup":"没有可用备份",
            "noCapacity":"资源池容量不足",
            "advancedSet":"高级设置",
            "pool":"资源池",
            "startStyle":"启动方式",
            "configType":"配置类型",
            "allocate":"已分配",
            "recommendFlavor":"推荐规格",
            "moreFlavor":"更多规格",
            "chooseFlavor":"选择规格",
            "unselect":"未选择",
            "countprev":"共",
            "countnext":"条",
            "defaultType":"默认类型",
            "all":"全部",
            "localDisk":"本地磁盘",
            "jump":"跳转",
            "secDomainTip":"您当前角色为超级管理员，点击跳转按钮将跳转至管理页面",
            "helpInformation":"帮助信息",
            "viewDetailedManual":"查看详细使用手册",
            "adjustConfigError":"调整配置失败",
            "enhanceMsg":"增强型负载均衡与基础负载均衡采用不同的实现方式，每一个负载均衡服务由一台对于用户不可见的云主机单独提供服务，虚拟机内部通过Cilium技术，大大减少负载均衡对于整体网络性能的损耗，可以提供更好的性能。由于增强型负载均衡在创建后会在后台同步创建云主机，为了保证系统配额的准确性，所以使用增强型负载均衡会占用用户的CPU及内存配额。",
            "instancesDescribe":"云主机是云计算在基础设施应用上的重要组成部分，具有完整硬件、操作系统、网络功能，并且运行在一个完全隔离环境中的计算机系统。",
            "affinityDescribe":"当期望创建的云主机与某些特定的云主机分布在同一个物理节点上，以便这些云主机之间的通讯更快速方便时，可以把它们都放进同一个组；当期望创建的云主机与某些特定的云主机分布在不同的物理节点上，以便这些云主机之间实现一定的隔离，并且平衡各物理节点的资源使用率时，也可以把它们都放进同一个组。这些场景下所说的同一个组，即云主机组。",
            "portsOneDescribe":"云管平台支持关闭虚拟网卡的安全策略，允许用户手动在云主机的一个网卡上配置多个IP地址。为了防止手动分配的IP地址被其他用户分配到，设置虚拟网卡占用指定的IP地址。",
            "portsTwoDescribe":"虚拟网卡可以加载到云主机，一个主机最多可以加载26个网卡。",
            "imagesOneDescribe":"镜像是一个包含操作系统及应用程序和服务的云主机模板。用户创建云主机时需要选定一个镜像，该镜像包含的操作系统及应用都会安装在云主机中。",
            "imagesTwoDescribe":"云管平台安装部署完成后，需要将工具盘ISO中的cloudtools和其他操作系统镜像文件（raw、qcow2或者iso）上传到镜像库。其中raw和qcow2格式可以直接创建云主机，iso格式需要通过“制作镜像”功能才可制作成创建云主机可用的镜像。",
            "makeimagesDescribe":"iso格式镜像文件上传到云管平台后，需要通过“制作镜像”功能才可制作成创建云主机可用的镜像。",
            "volumesOneDescribe":"云硬盘是一种采用网络存储架构，支持弹性扩展，并且由云主机进行管理和使用的虚拟块存储设备。",
            "volumesTwoDescribe":"云硬盘类似于物理世界中的U盘或者移动硬盘,用户可以挂载到运行的云主机上，为主机提供持久化的存储。",
            "volumesThreeDescribe":"云硬盘适用于文件系统、数据库或其他对容量、IO有需求的系统软件或应用。",
            "labelDescribe":"标签用于标识云资源，当您拥有相同类型的许多云资源时，可以使用标签按不同维度对云资源进行分类。使用标签功能，可以根据为云资源添加的标签快速搜索和筛选特定的云资源。可以为帐户中的云资源定义一组标签，以跟踪每个云资源的所有者和用途，使资源管理变得更加轻松。",
            "snapshotsOneDescribe":"云硬盘快照是某一时间点云硬盘状态的全貌，通过创建快照可以保留云硬盘的状态，以便后续能返回相同的状态。快照属于增量备份，这意味着仅保存设备上在最新快照之后有更改的数据，这将尽可能缩短创建快照所需的时间，且可以节省存储成本。",
            "snapshotsTwoDescribe":"可以在云硬盘的任何状态下创建快照，但是，快照只能保存创建时间点时已经写入云硬盘的数据，若应用程序或其他进程在该时刻正在向该云硬盘写入数据，可能无法被保存下来。如果可以将所有文件写入暂停一段时间并创建快照，则该快照应该是完整的。如果无法暂停，则建议将该云硬盘从云主机中卸载、创建快照然后重新挂在到云主机上。",
            "localDiskOneDescribe":"本地磁盘是虚拟机直接使用物理机的磁盘，相比于使用云硬盘，使用本地磁盘除了功能保持一致以外，会获得更高的性能，适用于有特殊需求的应用或场景。",
            "localDiskTwoDescribe":"由于本地磁盘是直接创建在虚拟机所在的物理机上，迁移虚拟机并不会同步迁移本地磁盘，所以挂载本地磁盘的虚拟机不支持迁移，无法保证虚拟机的高可用。",
            "backupStrategyDescribe":"备份策略是定时备份的基础，备份策略定义了备份频率、备份开始时间、保留文件数量。新建定时备份需要指定备份策略，系统可自动按照备份策略执行备份任务。",
            "physicalNodeOneDescribe":"监控物理主机的各种信息。",
            "physicalNodeTwoDescribe":"正在运行云管平台的物理主机。",
            "physicalNodeTitle":"虚拟化物理机：",
            "physicalResPhyOneDescribe":"监控物理主机的各种信息。",
            "physicalResPhyTwoDescribe":"供用户申请使用的闲置物理机。",
            "physicalResPhyTitle":"资源池物理机：",
            "physicalIpmiOneDescribe":"监控物理主机的各种信息。",
            "physicalIpmiTwoDescribe":"已安装操作系统并运行业务的物理主机。",
            "physicalIpmiTitle":"纳管物理机：",
            "newbackupDescribe":"备份是一种便捷高效的数据保护服务手段。数据盘的日常备份，可以定期备份重要业务数据，以应对误操作、攻击或病毒等导致的数据丢失风险。应用软件升级或业务数据迁移等重大操作前，可以创建一份或多份数据备份。一旦升级或迁移过程中出现任何问题，即可以通过数据备份及时将业务恢复到正常的系统数据状态。",
            "sysbackupDescribe":"通过创建备份，对云主机系统盘文件进行全量或增量备份，存储到云管平台对接的备份存储设备中。",
            "databackupDescribe":"通过创建备份，对云主机数据盘文件进行全量或增量备份，存储到云管平台对接的备份存储设备中。",
            "sysbackupRecoveryDescribe":"当云主机出现错误或物理机出现严重故障时，使用系统盘备份可以恢复对应系统盘的数据。",
            "databackupRecoveryDescribe":"当云主机出现错误或物理机出现严重故障时，使用数据盘备份可以恢复对应数据盘的数据。",
            "volumesQoSDescribe":"为防止部分云硬盘过多占用资源，影响整个存储效率，设置云硬盘的QoS来限制云硬盘读写吞吐量和操作数。存储池的QoS是基于这个存储池创建云主机时，都具有这个QoS的属性。对于单个的云主机，如果需要修改云硬盘QoS，可以在云主机磁盘操作中修改当前的这个QoS的属性值。QoS是前端（nova）实现，不是通过后端存储驱动实现。",
            "netTopoDescribe":"网络拓扑支持自动生成整个云管资源的网络连接关系，展示包括云主机、私有网络、虚拟路由器和云主机在内的层级连接关系。",
            "networksDescribe":"私有网络是一个用户自定义的逻辑隔离网络空间，托管在私有网络内的是云主机、负载均衡资源。不同私有网络间完全逻辑隔离。用户可以自定义网段划分、IP地址和路由策略等，并通过防火墙和安全组等实现多层安全防护。",
            "routersDescribe":"路由器用于连接多个私有网络，使之互通。云主机可以通过路由器访问公网。",
            "physicalnetworksDescribe":"外部网络是外部物理网络的映射，一般用于给虚拟路由器分配网关地址，或者给云主机分配公网IP地址。云主机使用外部网络对外提供业务能力。",
            "floatingIpsDescribe":"公网IP地址即互联网IP地址，若需要从外部网络访问云主机，则需要为云主机分配公网IP。先申请公网IP，然后将公网IP绑定到云主机或负载均衡等资源即可。（在私有云中，这里可以是公司内部业务的私网IP地址）",
            "loadbalancingDescribe":"负载均衡可以将访问流量按照一定的均衡算法动态地分发到多台提供相同服务的云主机上，每台云主机都可以单独响应请求，从而提高业务的服务能力和可用性。同时，还可以随时通过添加或删减云主机来调整负载均衡的服务能力，而且这些操作不会影响业务的正常访问。目前支持HTTP/HTTPS/TCP三种监听模式。",
            "loadbalancingcertificateOneDescribe":"负载均衡器支持两种类型的证书，服务器证书和CA证书。配置HTTPS监听器时，需要为监听器绑定服务器证书，如果开启双向认证功能，还需要绑定CA证书。",
            "loadbalancingcertificateTwoDescribe":"服务器证书：在使用HTTPS协议时，服务器证书用于SSL握手协商，需提供证书内容和私钥。",
            "loadbalancingcertificateThreeDescribe":"CA证书：又称客户端CA公钥证书，用于验证客户端证书的签发者；在开启HTTPS双向认证功能时，只有当客户端能够出具指定CA签发的证书时，HTTPS连接才能成功。",
            "vpnDescribe":"IPSec VPN指采用IPSec协议来实现远程接入的一种VPN技术，IPSec全称为Internet Protocol Security，是由Internet Engineering Task Force(IETF)定义的安全标准框架，用以提供公用和专用网络的端对端加密和验证服务。IPSec VPN用于连接两个私有云环境或者连接私有云和公有云环境，将两个不同私有网络环境整合成为一个统一的私有网络环境。",
            "firewallDescribe":"防火墙作用于路由器，保护路由器以及与路由器连通的整个内部网络免受外部网络的非法侵入。",
            "keypairsDescribe":"即常见的ssh-key的密钥对。可用于无密码无用户名登录Linux类型的云主机。仅支持Linux类型的云主机。在云管平台上生成密钥对并将私钥下载到用户本地，在创建云主机时选择对应的密钥对名称即可实现通过密钥方式登录云主机，从而保证云主机被访问的高度可控性。",
            "securityGroupsDescribe":"安全组是一个逻辑上的分组，可以实现相互信任的云主机之间的通信。可以将同一地域内具有相同网络安全隔离需求的云主机加到同一个安全组内，从而这些云主机使用相同的安全策略。",
            "scalpolicyDescribe":"根据用户的业务需求和策略，经济地自动调整弹性计算资源的管理服务。弹性扩展不仅适合业务量不断波动的应用程序，同时也适合业务量稳定的应用程序。",
            "extensionDescribe":"通过弹性扩展管理集群，在高峰期自动增加云主机，在业务回落时自动减少云主机，节省基础设施成本。",
            "recycleDescribe":"云主机回收站用于存放被删除的云主机，并在设置期限内提供云主机的恢复功能。当云平台管理员由于误操作删除云主机之后，可以在云主机回收站中找到云主机并将云主机恢复回去。",
            "recyclecsDescribe":"数据盘回收站用于存放被删除的数据盘，并在设置期限内提供数据盘的恢复功能。当云平台管理员由于误操作删除数据盘之后，可以在数据盘回收站中找到数据盘并将数据盘恢复回去。",
            "recycleDbDescribe":"云数据库回收站用于存放被删除的云数据库，并在设置期限内提供云数据库的恢复功能。当云平台管理员由于误操作删除云数据库之后，可以在云数据库回收站中找到云数据库并将云数据库恢复回去。",
            "pvminstancesDescribe":"裸金属模块提供了裸金属管理中最基础的功能，即裸金属从申请到删除的全生命周期维护。",
            "pvmimagesDescribe":"镜像功能模块用于为裸金属提供镜像，通常包含了操作系统、应用程序及相关服务，通过此模块可以创建一台裸金属为用户提供服务。",
            "pvmnetworksDescribe":"网络功能模块基于先进的SDN技术，为用户提供按需构建网络的功能。",
            "k8sclustersDescribe":"容器集群使用帮助引导使用帮助引导使用使用帮助引导使助引导",
            "k8sservicesDescribe":"容器服务基于原生Kubernetes 提供以容器为核心的、高度可扩展的高性能容器管理服务。容器服务完全兼容原生KubernetesAPI，为容器化的应用提供高效部署、资源调度、服务发现和动态伸缩等一系列完整功能，解决用户开发、测试及运维过程的环境一致性问题，提高了大规模容器集群管理的便捷性，帮助用户降低成本，提高效率。",
            "k8sprojectsDescribe":"镜像服务使用帮助引导使用帮助引导使用使用帮助引导使助引导",
            "alarmeventDescribe":"告警事件分为告警事件和历史告警。没有处理过的告警即为告警事件；已处理的告警为历史告警。",
            "wechatAlarmeventDescribe":"告警事件分为告警事件和历史告警。没有处理过的告警即为告警事件；已处理的告警为历史告警。",
            "hisAlarmeventDescribe":"历史告警事件使用帮助引导使用帮助引导使用使用帮助引导使助引导",
            "wechatHisAlarmeventDescribe":"历史告警事件使用帮助引导使用帮助引导使用使用帮助引导使助引导",
            "alarmsettingDescribe":"系统已预置常用告警设置。如需创建告警设置，请确保已创建告警模板。",
            "wechatAlarmsettingDescribe":"系统已预置常用告警设置。如需创建告警设置，请确保已创建告警模板。",
            "alarmtemplateDescribe":"用户可以根据实际需要对某个特定的监控指标设置自定义告警规则，同时也支持使用告警模板为多个资源或者服务创建告警。",
            "wechatAlarmtemplateDescribe":"用户可以根据实际需要对某个特定的监控指标设置自定义告警规则，同时也支持使用告警模板为多个资源或者服务创建告警。",
            "contractgroupDescribe":"接收告警通知的邮箱或微信。",
            "wechatContractgroupDescribe":"接收告警通知的邮箱或微信。",
            "pendingTicketsDescribe":"在待处理工单页面对需要由当前登录用户审批的工单进行操作。",
            "myApplyDescribe":"在我的申请页面，可以查看当前登录用户提交的工单申请",
            "allTicketsDescribe":"在我的审批页面，可以查看当前登录用户已经审批的工单。",
            "ticketReportsDescribe":"按照不同的过滤条件，查看工单信息。",
            "operatelogsDescribe":"操作日志完整记录各用户在云管平台的操作行为信息。支持按照用户名、操作源IP、操作源MAC、资源类型进行查询。",
            "auditDescribe":"审计日志记录系统管理员和系统保密员的操作行为信息，供系统审计员查看。",
            "loginlogsDescribe":"登录日志记录用户登录云管平台的信息。按照权限查看和导出用户的登录信息。支持按照用户名、登录源IP、登录源MAC进行查询。",
            "departmentDescribe":"每个部门有自己独立的资源池，部门管理员可自主管理和部署名下的资源。部门之间的资源是相互隔离的。此页面可以创建和管理云平台中的部门，并管理每个部门的配额。",
            "projectDescribe":"项目是云平台中人或服务所拥有的资源集合，一个项目可以包括多个用户，每个用户会根据权限来使用项目中的资源。这里可以对项目进行配额管理，但是项目的配额不能高于其所属的部门配额。",
            "userDescribe":"通过为用户设定角色，赋予用户一定的权限，以满足不同用户对系统的访问控制需求。用户角色分为部门管理员、项目管理员和普通用户。部门管理员可以管理本部门的所有项目管理员和普通用户，项目管理员可以管理本项目内的所有普通用户。",
            "roleDescribe":"支持基于部门、项目及项目成员划分角色（部门管理员、项目管理员和普通用户），并基于角色分配云管平台的功能权限。<div class='img-role'></div>",
            "aggregatesDescribe":"主机集合即具有共同属性的裸金属组成的一个逻辑的小集群。它需要和可用域配合使用；可用域更多表现为地理概念，如当一个集群跨域两个机房时，则每个机房作为一个可用域。而主机集合将把可用域中具有相同属性或者相同用途的物理主机归类到同一主机集合中。让用户在创建云主机时可以自动调度到想要的物理主机上。",
            "dataclusterDescribe":"当云管平台采用公共云管部署模式时，支持多数据中心统一管理和部署。",
            "nodeDescribe":"通过对节点进行管理，实现集群、高可用等功能特性。请注意新增节点如果超过30分钟还没初始化完成，请排查节点是否有故障。",
            "physicalConductorDescribe":"纳管裸金属分为闲置裸金属和运行中的裸金属两类。将处于运行状态的裸金属纳入管理，监控裸金属状态；将闲置的裸金属组成资源池，提供租用服务。对已纳管的裸金属进行开机、关机、重启、控制台、查看硬件配置信息等操作。",
            "physicalConductorResDescribe":"纳管裸金属分为闲置裸金属和运行中的裸金属两类。将处于运行状态的裸金属纳入管理，监控裸金属状态；将闲置的裸金属组成资源池，提供租用服务。对已纳管的裸金属进行开机、关机、重启、控制台、查看硬件配置信息等操作。",
            "storageManagementOneDescribe":"配置云主机备份资源池或云主机存储资源池对接的外部存储设备信息。",
            "storageManagementTwoDescribe":"对存储进行增加、删除等操作时，请确认平台没有进行其它运维操作，如果有其它运维操作，请等待其它运维操作完成后，再进行存储管理操作。",
            "imageManagementDescribe":"云管平台支持多次迁移镜像库到存储设备。后续创建的镜像文件会直接保存至共享存储，操作可以进行多次，可以将本地存储迁到共享存储，但不能从共享存储迁回本地 。",
            "regularSnapDescribe":"创建计划任务，按照制定的定时任务执行策略定期执行备份、快照任务。",
            "transmissionMagDescribe":"将挂载在裸金属上的U盘或硬盘等设备透传给云主机，云主机可以使用该透传设备。",
            "flavorsinstanceDescribe":"云主机规格，即表示创建云主机所需要的相关配置，包括云主机的CPU个数、内存，以及相关的其他一些信息",
            "flavorsphysicalDescribe":"裸金属规格即创建裸金属所需要的相关配置，包括CPU个数、内存，以及相关的其他一些信息。",
            "flowManageDescribe":"工作流管理用于设置工单审批全流程所涉及到的工单流转、各环节审批人及适用范围等。用户只有在此新建工单的审批流程后，才能在\"工单申请\"中看到相应的申请入口。",
            "flowManageCustomDescribe":"自定义流程用于定义流程级数及流程图等基本信息。",
            "licenseDescribe":"单击\"获取机器码\"，将机器码按照要求发送给厂商，待收到厂商关于激活文件的回复邮件后，点击\"导入激活文件\"将激活文件导入即可。",
            "pluginDescribe":"功能管理用于安装云平台扩展功能模块。注：只可安装license授权范围内的功能模块；若在添加中没有找到您要安装的模块，请检查license授权范围是否涵盖该模块。",
            "gradeDescribe":"请通过SFTP地址上传软件升级包。",
            "accesspolicyDescribe":"通过设置访问的黑白名单，来过滤云管平台的访问权限，以保障云管平台的使用安全。",
            "openstackServiceDescribe":"监控集群当前计算、存储、网络各服务的使用情况和运行状态。",
            "cephManageDescribe":"云管平台对当前Ceph存储中各服务器的硬盘使用情况、硬盘更换、硬盘使用率等进行监控，并通过图形化或者列表式页面展示。",
            "databasetdsqlDescribe":"分布式数据库（Tencent Distributed MySQL，TDSQL） 是支持自动水平拆分的高性能数据库服务——即业务感受完整的逻辑表，而数据却均匀的拆分到多个物理分片中，可以有效解决超大并发、超高性能、超大容量的 OLTP 类场景；TDSQL 的每个分片默认采用主从高可用架构，提供弹性扩展、备专注于业务发展。TDSql具有如下功能：高度兼容 MySQL、更高性能、高可用性、企业级安全、企业级监控、可扩展性、易用性",
            "inspectionOneDescribe":"系统巡检支持对整个云管平台进行全面的检查，以便及时发现问题。",
            "inspectionTwoDescribe":"在巡检过程中，会有如下操作:",
            "inspectionThreeDescribe":"1、系统在Web界面上，通过自动创建/删除云主机、镜像、密钥对、云硬盘、用户等操作，来验证相关功能是否正常，其中自动创建/删除期间，Web界面会弹出相关功能验证的提示框。",
            "inspectionFourDescribe":"2、系统通过对底层命令的调用，进行各个节点的CPU、内存分区等使用情况的巡检。",
            "inspectionFiveDescribe":"3、系统采用超融合ceph时，会对存储的状态和使用情况进行巡检。",
            "inspectionSixDescribe":"4、系统通过调用OpenStack API接口，对各个服务状态进行巡检",
            "databaseDescribe":"云数据库是云计算在基础设施应用上的重要组成部分，具有完整硬件、操作系统、网络功能，并且运行在一个完全隔离环境中的计算机系统。",
            "downloadTemplate":"下载模板",
            "registrateFile":"注册文件",
            "searchFilter":"正在搜索，筛选条件...",
            "exitFilter":"退出筛选",
            "totalSearch":"共搜索到",
            "serachPcs":"条结果",
            "domain_quota":{
                "instances": "部门云主机配额不足",
                "cores": "部门CPU配额不足",
                "ram": "部门内存配额不足",
                "floatingip": "部门公网IP配额不足",
                "snapshots": "部门快照配额不足",
                "volumes": "部门云硬盘配额不足",
                "gigabytes": "部门硬盘配额不足",
                "phy_instances":"部门物理云主机配额不足"
            },
            "project_quota": {
                "instances": "项目云主机配额不足",
                "cores": "项目CPU配额不足",
                "ram": "项目内存配额不足",
                "floatingip": "项目公网IP配额不足",
                "snapshots": "项目快照配额不足",
                "volumes": "项目云硬盘配额不足",
                "gigabytes": "项目硬盘配额不足",
                "phy_instances":"项目物理云主机配额不足"
            }
        },
        "popover": {
            "image": {
                "tip0": "请选择一个镜像",
                "tip1": "非admin用户无法编辑公有镜像",
                "tip2": "所选镜像不可用",
                "tip3": "所选镜像无法删除",
                "tip4": "SystemCheck为系统默认镜像无法编辑",
                "tip5": "SystemCheck为系统默认镜像无法删除",
                "tip6": "物理节点系统盘空间不足无法编辑",
                "imageNotOperateTip":"负载均衡增强型的镜像不能做任何操作"
            },
            "btn": {
                "helpInfo": "帮助信息",
                "refresh": "刷新列表",
                "export": "导出资源列表",
                "listSetting": "列表设置"
            }
        },
        "maxpc":{
            "table":{
                "status":{
                    "true":"启用",
                    "false":"禁用"
                }
            }
        },
        "cephView":{
            "poolName":"存储池名称",
            "poolsName":"存储池",
            "osdName":"硬盘OSD编号",
            "name":"硬盘系统名称",
            "osdStatusCopy":"状态",
            "typeCopy":"硬盘类型",
            "total":"硬盘容量(GB)",
            "volumeGB":"硬盘容量(GB)",
            "nodeName":"所属服务器",
            "percent":"磁盘使用率",
            "reweight":"数据权重",
            "checkTime":"健康检查时间",
            "clustbalance":"集群数据平衡",
            "excuteType":"执行方式",
            "immExcute":"立即执行",
            "timedBalance":"定时平衡数据",
            "timeSet":"时间设置",
            "unknown":"未知",
            "excuteAction":"执行动作",
            "excuteTime":"执行时间",
            "volumeName":"硬盘名称",
            "osdNum":"osd编号",
            "IOused":"IO利用率",
            "attribute":"硬盘属性",
            "cephTask":"Ceph任务管理",
            "rebootOsd":"重启OSD",
            "avaliableDisk":"可用硬盘",
            "journalDisk": "日志盘",
            "cacheDisk": "缓存盘",
            "dataDisk":"数据盘",
            "missDisk":"已丢失的盘",
            "maxBackfills":"最大回填数",
            "rebootSuccess":"重启OSD成功",
            "rebootFail":"重启OSD失败",
            "relationshipPreview":"关系预览",
            "tips":{
                "tip1":"绿色代表IO使用率小于30%，黄色代表IO使用率在30%到70%之间，红色代表IO使用率大于70%",
                "tip2":"该集群存在未执行完成的任务，无法新建任务",
                "tip3":"当前集群正在平衡中(所有 pg 状态不是 active+clean 状态),请稍后操作。",
                "tip4":"请选择一个任务",
                "tip5":"请选择执行成功或执行失败的任务",
                "tip6":"云管平台对当前Ceph存储中各服务器的硬盘使用情况、硬盘更换、硬盘使用率等进行监控，并通过图形化或者列表式页面展示。",
                "tip7":"请选择一个OSD",
                "tip8":"请选择下线或离线状态的OSD",
                "tip9":"该集群存在未执行完成的任务，无法新建任务",
                "tip10":"该节点已经存在元数据盘，无法继续添加元数据盘",
                "tip11":"请确认是否删除该磁盘。",
                "tip12":"确认添加硬盘并设置数据平衡策略",
                "tip13":"删除之前，您需要先设置定时同步数据.当删除最后一块数据盘时，会同步删除对应元数据盘",
                "tip14":"设置定时同步数据，请确保迁移已经失败状态且无法改变，方可使用该按钮重置状态，如果对正常迁移的云硬盘重置状态，会导致迁移中断",
                "tip15":"确定要重启OSD？",
                "tip16":"是否要删除选中的硬盘？",
                "tip17":"需要删除对应的数据盘后才能删除元数据盘",
                "tip18":"元数据盘无可用分区",
                "tip19":"元数据盘不可编辑",
                "tip20":"当已配置元数据盘时，数据盘只能选择HDD类型磁盘",
                "tip21":"配置数据盘时，只能选择同一类型磁盘",
                "tip22":"数据盘不能为空",
                "tip23":"添加新的元数据盘与数据盘映射关系时，请确保磁盘配置与已有磁盘映射关系保持一致"
            },
            "chart":{
                "status":{
                    "up":"在线",
                    "down":"下线",
                    "out":"离线",
                    "miss":"未知",
                    "missing":"未知",
                    "rebooting":"重启中",
                    "null":"N/A"
                },
                "diskType":{
                    "1":"HDD",
                    "0":"SSD"
                },
                "attribute":{
                    "data":"数据盘",
                    "journal":"元数据盘",//todo 应该是日志盘
                    "available":"可用盘"
                },
                "actionTo":{
                    "add_data":"添加至数据盘",
                    "add_journal":"添加至元数据盘",
                    "delete_available":"删除硬盘",
                }
            },
            "task":{
                "jobTypes":"任务类型",
                "jobStatuss":"任务执行状态",
                "nodeName": "主机名称",
                "nodeIp":"主机IP地址",
                "startTime":"平衡数据时间",
                "createTime":"创建任务时间",
                "jobType":{
                    "0":"集群平衡",
                    "1":"新硬盘数据平衡",
                    "2":"自愈任务"
                },
                "jobStatus":{
                    "all":"全部",
                    "NORMAL": "未开始",
                    "EXECUTING": "执行中",
                    "FINISHED": "执行成功",
                    "FAILED": "执行失败",
                    "PAUSED":"暂停",
                    "ABNORMAL":"执行异常"
                }
            },
        },
        "backups":{
            "volumeNum":"云硬盘数量",
            "chooseBackup":"选择备份",
            "Createinformation":"创建信息",
            "curBackup":"当前备份",
            "addBackup":"新建备份",
            "backupName":"备份名称",
            "backupTime":"备份时间",
            "dataBackup":"数据盘备份",
            "systemBackup":"系统盘备份",
            "import":"导入记录",
            "export":"导出记录",
            "importTitle":"将备份文件记录导入到云平台",
            "backupFile":"备份文件",
            "chooseFile":"选择文件",
            "resetStatus":"重置状态",
            "selectStatus":"状态选择",
            "type":"备份方式",
            "size":"大小（GB）",
            "backupInfo":"备份信息",
            "timingTaskBackup":"定时备份",
            "timingBackup":"实时备份",
            "statusNotDeleted":"未开始，备份中，删除中或者恢复中的备份不可删除",
            "backupDetail":{
                "detail":"备份详情",
                "addBackup":"新建备份",
                "addVolume":"新建云硬盘",
                "addvm":"新建云主机",
                "restore":"还原",
                "Releasebackup":"解除备份",
                "Deletebackup":"删除",
            },
            "table":{
                "name":"备份名称",
                "vm":"云主机名称",
                "createAt":"创建时间",
                "size":"大小",
                "backupStatus":"备份状态",
                "backupType":"备份类型",
                "chainName":"备份链名称",
                "volumeName":"云硬盘名称",
                "chainStatus":"备份链状态",
                "backupPoint":"备份点",
                "status":{
                    "creating":"创建中",
                    "available":"可用",
                    "deleting":"删除中",
                    "error":"错误",
                    "restoring":"恢复中",
                    "error_restoring":"恢复错误",
                    "restoring-backup":"备份恢复中",
                    "chain":{
                        "normal":"正常",
                        "warning":"警告",
                        "error":"错误"
                    }
                },
                "status_new":{//新的备份恢复中的备份状态
                    "waiting":"未开始",
                    "creating":"备份中",
                    "end":"已终止",
                    "success":"成功",
                    "failed":"失败",
                    "available":"可用",
                    "error":"错误",
                    "deleting":"删除中",
                    "restoring":"恢复中"
                },
                "status_new_mainRecovery_table":{//新的备份恢复中的备份状态,主列表中的available翻译为成功，error翻译为失败，
                    "waiting":"未开始",
                    "creating":"备份中",
                    "end":"已终止",
                    "success":"成功",
                    "failed":"失败",
                    "available":"成功",
                    "error":"失败",
                    "deleting":"删除中",
                    "restoring":"恢复中"
                },
                "isIncremental":{
                    "true":"增量备份",
                    "false":"全量备份"
                },
                "bootable":{
                    "true":"是",
                    "false":"否",
                    "null":"否"
                },
                "placeholder":{
                    "plsSelItem":"请选择备份",
                    "plsSelSigItem":"请选择一个备份",
                    "multipleChoice":"备份不支持批量删除",
                    "createInstanceMultipleChoice": "备份不支持批量新建云硬盘",
                    "createVolumeMultipleChoice": "备份不支持批量新建云硬盘",
                    "restoreMultipleChoice": "备份不支持批量还原",
                    "resetMultipleChoice": "备份不支持批量重置状态"
                }
            },
            "Backupdetails":{
                    "Backupdetails":"备份详情",
                    "Executionrecord":"执行记录",
            },
            "upwindow":{
                    "Pleaseselectthecloudhost":"请选择要备份的云主机",
                    "volumeName": "云硬盘名称",
                    "Pleaseselecttheclouddrive":"请选择要备份的云硬盘",
                    "Pleaseselectabackupstrategy":"请选择备份策略",
                    "Pleaseselectabackupstrategymore":"请选择要备份的云硬盘，可多选",
            },
            "tips":{
                "del_msg":"您确定删除所选备份？",
                "exp_msg": "导出当前备份文件的记录，用于数据容灾。",
                "restore_msg":"您确定要进行备份还原？",
                "reset_msg":"您确定要重置备份状态？",
                "importsuccess": "备份文件导入成功",
                "importerror": "备份文件导入失败",
                "fileCheck": "文件格式必须为.pem且不超过2M",
                "expTip": "备份点总数不能超过2000",
                "tip1":"请选择可用、错误状态的备份",
                "tip2":"该备份被依赖，请先删除被依赖备份",
                "tip3":"暂未获取到该备份链的信息",
                "tip4":"请选择可用状态的备份",
                "tip5":"在备份文件为可用和错误状态下，不能使用此功能",
                "tip6":"请选择可用状态的备份",
                "tip7":"请先将源卷所挂载的云主机关机或者将源卷从云主机上卸载",
                "tip8":"请选择可用状态的备份",
                "tip9":"该备份链的源卷须为可用、使用中状态",
                "tip10":"该备份链中存在备份中的增量备份",
                "tip11":"该备份链中存在错误状态的增量备份",
                "tip12":"源卷存在定时备份任务时无法新建备份",
                "chain_uibpopover":"该备份链中存在备份为不可删除的状态，请检查备份链详情或先等待",
                "tip13":"该备份所在存储不支持此操作",
                "tip14":"请选择一个备份",
                "tip15":"在您第一次创建云硬盘备份时，系统会默认进行全量备份，之后您可以选择全量或者增量备份来保存您的数据。"
            },
            "backupChainName":"备份链名称",
            "volumeName":"云硬盘名称",
            "backupChainStatus":"备份链状态",
            "size":"大小",
            "backupPoint":"备份点",
            "createTime":"创建时间",
            "delBackup":"删除备份",
            "deltip1":"您当前要删除的文件",
            "deltip2":"在备份依赖链中，若您确认要删除该备份，请先按照备份拓扑由下往上删除该文件所依赖的备份文件。",
        },
        "tasks":{
            "taskId":"任务编号",
            "previousFireTime":"上次运行",
            "nextFireTime":"下次运行",
            "holdNumber":"保留份数",
            "jobType":"任务类型",
            "operate":"操作",
            "delTaskInfo":"你确定要删除定时任务吗？",
            "once":"一次",
            "hour":"按小时",
            "day":"按天",
            "week":"按周",
            "month":"按月",
            "snapshotTask":"定时快照任务",
            "backupTask":"定时02281201",
            "excuteRes":"任务执行结果",
            "basicInfo":"基本信息",
            "excuteLog":"执行任务日志",
            "userName":"用户名",
            "volume":"云硬盘",
            "excuteTime":"执行时间",
            "result":"执行结果",
            "newTask":"新建任务",
            "editTask":"编辑任务",
            "delTask":"删除任务",
            "unlockTime":"解锁时间",
            "detection":"检测到当前已纳管PaaS服务，为了让云主机正常访问PaaS服务，请",
            "createANetwork":"创建Paas共享网络"
        },
        "pvm": {
            "servers":{
                "table":{
                    "locked":{
                        "false":"未锁定",
                        "true":"锁定"
                    }
                },
                "placeholder": {
                    "plsSelServer": "请选择一个裸金属"
                },
            },
            "pvmGuide":{
                "text":"安装操作系统前，请首先在顶部导航-裸金属管理菜单中配置裸金属使用的镜像及网络。",
                "1":"上传裸金属镜像后才能安装操作系统，请到 裸金属管理 > ",
                "2":"镜像",
                "3":" “上传镜像”功能上传镜像。"
            },
            "register": "注册",
            "edit": "编辑",
            "pvmName": "裸金属名称",
            "name":"名称",
            "IPMI_add":"IPMI地址",
            "init":"初始化",
            "power_status":"电源状态",
            "deploy_status":"部署状态",
            "Maintain_status":"维护状态",
            "use_status":"使用情况",
            "canuse":"是否可用",
            "register_time":"注册时间",
            "options_lock":"操作锁定",
            "desc":"描述",
            "addDescription":"添加描述",
            "viewExecutionResult":"查看执行结果",
            "registIng":"正在注册中",
            "importList":"请导入列表",
            "pleaseClick":"请点击执行",
            "importFileErrors":"导入文件错误或者注册失败",
            "implement":"执行",
            "exportResult":"导出执行结果",
            "notCanexport":"暂时没有文件可导出",
            "ipmiAddress":"ipmi地址",
            "registrationResult":"注册结果",
            "verificationSuccess":"验证通过",
            "verificationFailed":"验证失败",
            "verifyIpmiResult":"验证ipmi信息结果",
            "startPhysicalMachine":"启动物理机",
            "closePhysicalMachine":"关闭物理机",
            "restartPhysicalMachine":"重启物理机",
            "terminateInit":"终止初始化",
            "physicalMachineDetail":"裸金属资源池详情",
            "associatedUser":"所属用户",
            "cpuThread":"cpu线程",
            "cpuArchitecture":"CPU架构",
            "hardDiskSize":"硬盘大小",
            "otherProperties":"其他属性",
            "novaResourcePool":"纳管物理机同步到了nova资源池里才可用",
            "table": {
                "status": {
                    "null": "",
                    "power on": "开机",
                    "power off": "关机",
                    "powering on": "开机中",
                    "powering off": "关机中",
                    "rebooting": "重启中",
                    "maintenance": {
                        "true": "开启维护模式",
                        "false": "关闭维护模式",
                        "null": "N/A"
                    },
                    "deploy": {
                        "enroll": "注册",
                        "None": "无",
                        "null": "",
                        "verifying": "确认中",
                        "available": "可用",
                        "initializing": "初始化中",
                        "active": "运行",
                        "building": "创建中",
                        "wait call-back": "等待响应",
                        "deploying": "部署中",
                        "deploy failed": "部署失败",
                        "deploy complete": "部署完成",
                        "deleting": "删除中",
                        "deleted": "删除",
                        "error": "错误",
                        "manageable": "可管理",
                        "cleaning": "清理中",
                        "clean wait": "等待清理",
                        "clean failed": "清理失败",
                        "rebuild": "重建",
                        "inspecting": "检查中",
                        "inspect failed": "检查失败",
                        "adopting": "接受中",
                        "adopt failed": "接受失败"
                    },
                    "limitAttribute": {
                        "true": "受限",
                        "false": "非受限"
                    },
                    "used": {
                        "true": "已使用",
                        "false": "未使用"
                    }
                }
            },
            "placeholder": {
                "cpu": "请输入CPU",
                "ram": "请输入内存",
                "disk": "请输入磁盘大小",
                "plsSelNode": "请选择一个裸金属",
                "choiceStatus":"选择类型",
                "enterplsName":"请输入裸金属名称"
            },
            "tip": {
                "tip1": "关机状态且非系统重装中才可以开机",
                "tip2": "运行状态且非系统重装中才可以关机",
                "tip3": "运行状态且非系统重装中才可以重启",
                "tip4": "请选择同种状态的裸金属",
                "tip5": "请选择关闭维护模式的裸金属",
                "tip6": "请选择开启维护模式的裸金属",
                "tip7": "请选择同种维护模式的裸金属",
                "tip8": "请选择非受限状态的裸金属",
                "tip9": "请选择未初始化状态的裸金属",
                "tip10": "请选择未锁定状态且非系统重装中的云主机",
                "tip11": "请选择锁定状态且非系统重装中的云主机",
                "tip12": "请选择运行且非系统重装中未锁定状态的云主机",
                "tip13":"请选择未使用的云主机",
                "tip14" : "请选择未初始化的裸金属",
                "tip15" : "请选择电源状态存在的裸金属",
                "tip16" : "请选择正在初始化状态的裸金属",
                "tip17":"初始化中的裸金属不支持此操作",
                "tip18":"关闭维护模式下该部署状态不允许删除",
                "tip19":"该裸金属处于维护模式、系统重装、锁定或非开关机状态中，不允许此操作",
                "tip20":"该裸金属已使用，不允许此操作"
            },
            "tipMsg": {
                "bootServer": "您确定要开启裸金属吗？",
                "shutdownServer": "您确定要关闭裸金属吗？",
                "rebootServer": "您确定要重启裸金属吗？",
                "deleteServer": "您确定要删除裸金属吗？",
                "openMaintenance": "您确定要开启裸金属维护模式吗？",
                "closeMaintenance": "您确定要关闭裸金属维护模式吗？",
                "initPvm": "您确定要初始化吗？",
                "registerStetus": "您确定要重置状态吗？",


                "lockSever":"您确定要锁定虚拟机吗？",
                "lockPhySever":"您确定要锁定裸金属吗？",
                "unlockSever":"您确定要解锁虚拟机吗？",
                "unlockPhySever":"您确定要解锁裸金属吗？",
                "resetServer":"您确定要系统重装吗？",

                "stopInit": "您确定要终止初始化吗？"

            }
        },
        "cluster": {
            "chooseRegion":"选择域",
            "status": {
                "0": "未部署",
                "1": "开始安装",
                "2": "安装中",
                "3": "安装完成",
                "4":"升级中",
                "5":"升级失败",
                "null": "无"
            },
            "simJetSoft":"一键关闭",
            "new": "新建集群",
            "edit": "编辑集群",
            "newRegion": "新建数据中心",
            "editRegion": "编辑",
            "ok": "立即部署",
            "cancel": "稍后部署",
            "tips": "提示",
            "createTime": "创建时间",
            "regionName": "名称",
            "pleaseInputName": "请输入集群名称",
            "confirmDel": "确定要删除集群吗？",
            "confirmDelNode": "该集群下存在节点，确定要删除该集群和其下面的节点吗？",
            "delCluster": "请确认是否删除该数据中心？",
            "delClusternode": "请先删除该数据中心所有虚拟资源，并删除所有节点，再删除该数据中心!",
            "configure": "集群配置",
            "name": "名称",
            "regionKey": "注册码",
            "mapping":"底层映射",
            "virtualNodeNum": "虚拟化节点数量",
            "containerNodeNum": "容器节点数量",
            "description": "描述信息",
            "lastTime": "最近更新时间",
            "detailMessage": " 详情信息",
            "registeredHosts": "已注册物理主机",
            "iprange": "IP范围",
            "vlanrange": "VLAN范围",
            "vxlanrange": "VXLAN范围",
            "nova_api_instances": "nova-api容器的个数",
            "nova_scheduler_instances": "nova_scheduler容器的个数",
            "cinder_scheduler_instances": "cinder_scheduler容器的个数",
            "nova_consoleauth_instances": "nova_consoleauth容器的个数",
            "nova_novncproxy_instances": "nova_novncproxy容器的个数",
            "nova_conductor_instances": "nova_conductor容器的个数",
            "cinder_api_instances": "cinder_service容器的个数",
            "glance_api_instances": "glance_service容器的个数",
            "ceilometer_service_instances": "ceilometer_service容器的个数",
            "keystone_service_instances": "keystone_service容器的个数",
            "neutron_server_instances": "neutron_server容器的个数",
            "rabbitmq_service_instances": "rabbitmq_service容器的个数",
            "haproxy_instances": "haproxy容器的个数",
            "glance_registry_instances": "glance_registry_instances",
            "cinder_volume_instances": "cinder_volume_instances",
            "haproxy_galera_instances": "haproxy_galera_instances",
            "computeha_monitor_instances": "computeha_monitor_instances",
            "nova_metadata_instances": "nova_metadata_instances",
            "nova_api_workers": "nova_api起的workers进程数",
            "nova_metadata_workers": "nova_metadata起的workers进程数",
            "nova_conductor_workers": "nova_conductor起的workers进程数",
            "neutron_api_workers": "neutron_api起的workers进程数",
            "neutron_rpc_workers": "neutron_rpc起的workers进程数",
            "cinder_api_workers": "cinder_api起的workers进程数",
            "glance_api_workers": "glance_api起的workers进程数",
            "glance_registry_workers": "glance_registry起的workers进程数",
            "keystone_workers": "keystone起的workers进程数",
            "ceilometer_notification_workers": "ceilometer_notification起的workers进程数",
            "database_password": "mysql的root用户密码",
            "keystone_database_password": "mysql的keystone用户密码",
            "glance_database_password": "mysql的glance用户密码",
            "nova_database_password": "mysql的nova用户密码",
            "nova_api_database_password": "mysql的nova_api用户密码",
            "neutron_database_password": "mysql的neutron用户密码",
            "cinder_database_password": "mysql的cinder用户密码",
            "keystone_admin_user": "OpenStack的管理员的用户名",
            "keystone_admin_password": "OpenStack的管理员的密码",
            "glance_keystone_password": "OpenStack的glance服务用户的密码",
            "nova_keystone_password": "OpenStack的nova服务用户的密码",
            "neutron_keystone_password": "OpenStack的neutron服务用户的密码",
            "cinder_keystone_password": "OpenStack的cinder服务用户的密码",
            "metadata_secret": "metadata_proxy的密码",
            "horizon_secret_key": "Horizon的SECRET_KEY",
            "haproxy_password": "haproxy的stats页面的密码",
            "memcache_secret_key": "所有服务访问memcached时的密码",
            "rabbitmq_password": "所有服务访问rabbitmq时的密码",
            "rabbitmq_cluster_cookie": "rabbitmq集群内部互相认证的密码",
            "new_tenant_vlan_range": "租户网Vlan范围",
            "floating_start": "外网的起始IP",
            "floating_end": "外网的终止IP",
            "floating_cidr": "外网的CIDR",
            "floating_gateway": "外网的网关",
            "tunnel_ssh_port": "云端的ssh服务的端口",
            "tunnel_remote_port": "隧道的云端入口点的端口",
            "tunnel_remote_address": "隧道的云端入口点的IPv4地址/网关域名",
            "cloud_event_uri": "Ceilometer的通知URI",
            "confirmShutOffMsg":"请确认是否已经将云主机等计算资源安全关机，否则强制关闭环境将可能会出现虚拟机数据丢失的情况。",
            "shutDownMsg":"该功能将关闭被选中数据中心云平台所管理的所有物理宿主机",
            "shutOffMsg1":"1. 请确认是否已经将云主机等计算资源安全关机，否则强制关闭环境将可能会出现虚拟机数据丢失的情况。",
            "shutOffMsg2":"2.集群下次开机且 ceph 集群恢复正常之后，请手动取消以下四个维护标签",
            "shutOffOrder":"noout，norebalance，nobackfill，norecover",
            "shutOffOrderStartMsg":"在任意节点执行以下命令",
            "orderStart1":"ceph  osd unset noout",
            "orderStart2":"ceph  osd unset norebalance",
            "orderStart3":"ceph  osd unset norecover",
            "orderStart4":"ceph  osd unset nobackfill"
        },
        "region": {
            "detail": {
                "floating": "外网",
                "cluster": "集群网",
                "storage": "存储网",
                "tenant": "租户网"
            }
        },
        "property": {
            "side": {
                "title": "权限管理",
                "menu": {
                    "role": "权限管理",
                    "user": "用户管理",
                    "org": "组织结构",
                    "depart": "部门管理",
                    "project": "项目管理"
                }
            }
        },
        "action": {
            "recover":"恢复",
            "selectFuse":"fuse协议才能进行配额",
            "noSelectHost":"未选择云主机",
            "rw":"读写",
            "ro":"只读",
            "quota":"配额",
            "customConfig":"自定义配置",
            "fastConfig":"快捷配置",
            "ISOinstall":"ISO安装",
            "createStorage": "添加存储",
            "create": "新建",
            "add": "添加",
            "edit": "编辑",
            "modify": "修改",
            "del": "删除",
            "forceDel":"强制删除",
            "download": "下载",
            "bind": "绑定",
            "unbind": "解绑",
            "start": "部署",
            "shutdown": "关机",
            "restart": "重启",
            "moreAction": "更多操作",
            "search": "搜索",
            "ok": "确定",
            "save": "保存",
            "complete": "完成",
            "cancel": "取消",
            "reset": "重置",
            "reset_status": "重置状态",
            "status": "状态",
            "operation": "操作",
            "update_patch": "上传升级包",
            "checking_saasStatus": "检测更新",
            "copy": "复制",
            "deployed": "部署",
            "forbidden": "禁用",
            "enabled": "启用",
            "close": "关闭",
            "open":"开启",
            "accessKey":"密钥管理",
            "return": "返回",
            "returnModify":"返回修改",
            "editPass": "修改密码",
            "resetPass": "重置密码",
            "editOrg": "分配组织",
            "processing": "处理",
            "nextStep": "下一步",
            "lastStep": "上一步",
            "storageSetting": "共享存储",
            "pleaseInput": "请输入",
            "pleaseSelect":"请选择",
            "int": "的整数",
            "refresh": "刷新",
            "load":"加载",
            "uninstall":"卸载",
            "search":"搜索",
            "selectExistVol":"选择已有数据盘",
            "empty":"空",
            "user":"用户",
            "hasData":"有数据",
            "retry":"重试",
            "reset":"重置",
            "working":"进行中",
            "reversed":"已撤销",
            "ended":"已结束",
            "closed":"已关闭",
            "abnormal":"异常",
            "normal":"正常",
            "reopen": "重新打开",
            "visit_way":"访问策略",
            "login_lose_way":"登录失败策略",
            "pwd_user_way":"密码及用户策略",
            "login_lose_lock":"登录失败锁定策略",
            "pwd_grade_set":"密码强度设置",
            "grade_select":"强度选择",
            "weak":"弱：无要求",
            "centre":"中：密码必须包括数字和字母、或特殊字符（!@._:,/-^%）",
            "strong":"强：密码必须包括大写字母（A-Z）、小写字母（a-z）、数字（0-9）、特殊字符（!@._:,/-^%）四种字符中的三种。",
            "min_height":"最小长度极限值",
            "max_height":"最大长度极限值",
            "prompt_message_one":"内容不能为空",
            "prompt_message_two":"这是无效的数字",
            "prompt_message_three":"值必需在4到64之间",
            "prompt_message_four":"值必需在20到64之间",
            "loginSet":"登录设置",
            "multi_user_login":"多人同时登录",
            "default_hint_text":"默认允许多人使用同一用户名称同时登录系统。",
            "locking_strategies":"锁定策略",
            "login_failure":"登陆失败",
            "set":"设置",
            "continuous":"连续",
            "frequency":"次数",
            "loadInstance":"挂载",
            "unloadInstance":"卸载",
            "revoke":"撤销",
            "apply":"申请",
            "systemReload":"系统重装",
            "unlock":"解锁",
            "getMonitoringClients":"获取监控客户端",
            "filter":"筛选"
        },
        "topheader":{
            "services":"产品和服务",
            "operation":"快捷操作",
            "newInstance":"新建云主机",
            "newDepart":"新建部门",
            "newPro":"新建项目",
            "newUser":"新建用户",
            "newVpc":"新建VPC网络",
            "consumption":"消费记录",
            "amount":"消费金额",
            "yuan":"元",
            "record":"查看消费记录",
            "work":"工单",
            "application":"资源申请",
            "untreated":"条未处理",
            "information":"信息查询",
            "unread":"条未读",
            "help":"帮助",
            "guidance":"新手指导",
            "document":"帮助文档",
            "configuration":"初始化配置"
        },
        "helpTip":{
            "instances":{
                "tip1":"云主机组根据亲和性/反亲和性规则创建。属于同一云主机组里的所有云主机会按照规则依次分布在同一个物理节点或完全分布在不同的物理节点上。",
                "tip2":"云主机在云管平台后台的标识,即登录控制台显示host-name。如果未输入数据，则主机名是镜像的默认数据。",
                "tip3":"选择“镜像”、“云硬盘”、“快照”或“备份”其中一种作为创建云主机系统盘的方式。",
                "tip4":"当镜像中没有安装virtio磁盘驱动时，这里应选择ide或scsi。若这里不选择将默认使用镜像元数据中设置的磁盘控制器类型",
                "tip5":"若勾选“指定子网”，则人为指定子网和IP地址；若不勾选“指定子网”，系统默认分配",
                "tip6":"区域即主机集合，指定云主机对应的宿主机范围，即“运维管理 > 主机集合”中设置的可用域",
                "tip7":"指定云主机对应的宿主机",
                "tip8":"需要满足云硬盘无定时任务，状态为使用中、可用，且所在存储支持云硬盘创建快照",
                "tip9":"需要满足快照状态为使用中、可用，且所在存储支持快照回滚",
                "tip10":"需要满足云硬盘无定时任务，状态为使用中、可用，且所在存储支持云硬盘创建备份",
                "tip11":"需要满足备份状态为使用中、可用，且所在存储支持备份还原",
                "tip12":"您的系统盘大小不能小于",
                "tip13":"您已挂载",
                "tip14":"块盘，您还可以挂载",
                "tip15":"块磁盘（云硬盘）",
                "tip16":"批量创建不支持挂载已有盘",
                "tip17":"没有可用的数据盘",
                "tip18":"云主机在云管平台界面上的标识。",
                "tip19":"登录云主机操作系统的密码。如不设置采用镜像初始密码",
                "tip20":"每次创建的数量，最多支持同时创建10个",
                "tip21":"云主机所属数据中心。",
                "tip22":"云主机CPU和内存。选择规格类型时，CPU和内存应至少满足小于某一物理节点的可用CPU和可用内存。",
                "tip23":"选择云主机从本地磁盘或云硬盘启动。系统会自动创建一个本地盘或云硬盘。本地磁盘：云主机将启动在宿主机本地磁盘上，存在单点故障风险，且云主机不能在宿主机间迁移，没有快照和备份的能力。云硬盘：云主机将启动在共享存储中，将获得更高的可靠性和存储性能。（推荐使用这种启动方式）。",
                "tip24":"创建云主机系统盘的方式。",
                "tip25":"私有网络的名称。",
                "tip26":"若勾选“指定子网”，则用户指定子网和IP地址；若不勾选“指定子网”，则由系统默认分配。",
                "tip27":"指定子网的网段，IP地址必须在该网段中。",
                "tip28":"网卡的IP地址。",
                "tip29":"指定子网的网段，IP地址必须在该网段中。",
                "tip30":"网卡的IP地址。",
                "tip31":"对于Linux云主机，建议使用密钥对(Key Pair)进行远程登录身份验证。用户可以新建一个密钥对，并下载私钥，私钥用于远程登录身份认证，为保证云主机安全，私钥只能下载一次，请妥善保管。也可以把已有的密钥对(Key Pair)的公钥导入系统，使用对应的私钥进行远程登录身份验证。",
                "tip32":"安全组用来实现安全组内和组间云主机的访问控制，加强云主机的安全保护。用户可以在安全组中定义各种访问规则，当云主机加入该安全组后，即受到这些访问规则的保护。",
                "tip33":"选择由系统自动选择云主机要调度到的可用域或由用户指定的可用域。",
                "tip34":"选择由系统自动选择云主机要调度到的节点或由用户指定的物理节点。",
                "tip35":"镜像文件对应操作系统的架构，32位或64位。",
                "tip36":"公有：常见的标准操作系统镜像，所有用户可见。包含操作系统以及预装的公共应用。私有：用户基于云主机或者外部镜像文件创建的个人镜像，仅用户自己可见。包含操作系统、预装的公共应用以及用户的私有应用。",
                "tip37":"操作系统类型。",
                "tip38":"安装云主机磁盘驱动和监控等工具。",
                "tip39":"从公网IP 地址池中申请一个公有 IP 地址，云主机绑定公网IP后可以访问外网，同时外网也可以可以通过该IP访问云主机。",
                "tip40":"当用户在云主机上部署了很多网络层面的应用，这些应用的数据包在流出和流入安全组的过程中，会受到安全策略的阻挡。开启网卡安全策略，必须选择一个安全组（默认为default）。关闭网卡安全策略，不在该网卡上加载安全组，允许任何IP地址通过该虚拟网卡访问云主机。",
                "tip41":"将存储卷从当前存储迁移到其他存储设备。",
                "tip42":"系统按照设定的执行快照的频率自动创建快照。",
                "tip43":"执行定时快照任务的频率。",
                "tip44":"表示多长时间，执行一次定时快照。",
                "tip45":"设定该定时任务执行快照的时间，时间需要指定年、月、日、时、分。",
                "tip46":"定时快照任务保留的快照份数，可选择1～5，5个正整数。规则如下：以选择5份为例，定时任务会在规定的周期连续地创建出5个快照，当快照执行到第6次时，会在执行完后，将最早一份快照自动删除，以保证整个定时快照任务保留的快照份数是5份。",
                "tip47":"系统按照设定的执行备份的频率自动创建备份。",
                "tip48":"对云主机进行备份时，选择的卷首次备份时必须是全量备份，只有在有了全量备份时才能进行增量备份的创建。",
                "tip49":"执行定时备份任务的频率。",
                "tip50":"表示多长时间，执行一次定时备份。",
                "tip51":"设定该定时任务执行备份的时间，时间需要指定年、月、日、时、分。",
                "tip52":"定时备份任务保留的备份份数。备份规则如下：备份份数设定为N，在定时任务开启后的第一次备份，进行全量备份，之后N-1次备份为增量备份。如果“保留最新备份份数”设定为4，则开始定时任务后，第一次为全量，第2，3，4次为增量，完成一个周期为4的备份链；第二次周期开始，即第五次创建为全量备份，第6，7，8次为增量。此时有两个备份链，一共8个备份。当第三个备份周期开始时，会删除最开始的一个备份链。所以，最多保留的备份数为2N，备份链数为2。当达到最新备份份数时，前面相应的备份文件将被删除。保留的备份文件必须至少有一份为全量备份。",
                "tip53":"磁盘可以支持的最大读吞吐量。",
                "tip54":"磁盘可以支持的最大写吞吐量。",
                "tip55":"磁盘可以支持的每秒 I/O 读操作的请求数。",
                "tip56":"磁盘可以支持的每秒I/O写操作的请求数。",
                "tip57":"云主机CPU和内存。选择规格类型时，CPU和内存应至少满足小于某一物理节点的可用CPU和可用内存。",
                "tip58":"由系统自动选择云主机要迁移到的节点或由用户指定的物理节点。",
                "tip59":"在当前节点所在区域选择可用节点供云主机迁移。",
                "tip60":"设置CPU和内存的数值，不能超过云主机规格中CPU和内存最大值。",
                "tip61":"用于标识告警设置。",
                "tip62":"告警对应的模板。",
                "tip63":"告警需要通知的联系人组邮箱或微信。",
                "tip64":"勾选则在创建成功后立即启动，否则可以后续根据需要手工启动。",
                "tip65":"系统按照设定的执行任务的频率自动创建任务。",
                "tip66":"执行定时任务的频率。",
                "tip67":"表示多长时间，执行一次定时任务。",
                "tip68":"设定该定时任务执行任务的时间，时间需要指定年、月、日、时、分。",
                "tip69":"安全加固免费开通DDoS防护和云镜主机防护，基于腾讯安全积累的海量威胁数据，主要包括密码破解拦截、异地登录提醒、木马文件查杀、高危漏洞检测等安全功能，解决当前云服务器面临的网络安全风险，帮助企业构建服务器安全防护体系，防止数据泄露。",
                "tip70":"自动化运维免费托管到织云自动化运维平台中，织云是源自于腾讯的企业级运维管理平台，传承标准化运维方法论与经验，支持一键式运维操作与智能监控，零接入成本，与腾讯云组件无缝整合，轻松实现一站式运维管理。自动化运维免费托管到织云自动化运维平台中，织云是源自于腾讯的企业级运维管理平台，传承标准化运维方法论与经验，支持一键式运维操作与智能监控，零接入成本，与腾讯云组件无缝整合，轻松实现一站式运维管理。",
                "tip71":"存在没有系统盘信息的云主机，无法勾选",
                "tip72":"提示：1.添加多网卡后，需要在虚拟机中添加该网卡的网卡信息和配置相应静态路由信息；",
                "tip73":"2.每次增加或删除PaaS资源以后，需要更新安全组规则。",
                "tip74":"PaaS网络为安全隔离网络，不支持指定IP地址和调整安全组。",
                "tip76":"不支持对本地盘文件进行云硬盘迁移",
                "tip76_1":"云硬盘迁移过程请不要重启虚机",
                "tip77":"系统盘迁移到ceph存储,请先关闭云主机",
                "tip78":"云硬盘迁移到非ceph存储,请先启动云主机",
                "tip79":"请先取消挂载,并在云硬盘页面进行迁移操作。",
                "tip80":"云主机开机状态下只能查询，不能进行加载、卸载FPGA操作",
                "tip81":"该功能仅针对于linux系统云主机有效，当linux系统停止响应或者内核crash时，将自动重启该虚拟机。该功能需要云主机中安装并运行watchdog服务。",
                "tip82":"针对Windows系统云主机，可在windows系统中“控制面板——>系统——>高级——>启动和故障恢复”中勾选系统失败时“自动重新启动”来实现该功能效果。",
                "tip83":"当选择的子网含有默认网关，添加后可能会影响该云主机其他网卡的连通性，请确认是否添加该子网的虚拟网卡？",
                "tip84":"针对于linux云主机，请在添加网卡后，在云主机系统中添加新网卡的配置文件，并启动网卡，才能真正使用所添加的虚拟网卡。",
                "tip85": " 本地盘不支持此功能",
                "tip86": "系统盘上有快照，无法进行一键还原",
                "tip87": "展示24小时内统计数据时一个点以10秒为间隔显示，展示一周内统计数据时一个点以15分钟间隔显示，展示大于一周的统计数据时每个点以30分钟为间隔显示。",
                "tip88":"云主机开机状态下只能查询，不能进行加载、卸载GPU操作",
                "tip89":"节点设置功能需要指定启动节点或者选择直连网卡，并手动设置NUMA节点的值",
                "tip90":"若勾选”指定子网“，则人为指定子网和ip地址；若不勾选”指定子网“，系统默认分配",
                "tip91":"您还没有私有网络，点击新建私有网络",
                "tip92":"搁置状态的云主机默认勾选删除系统盘"
            },
            "images":{
                "tip1":"上传镜像的方式，选择“从http上传”和“从文件上传”。",
                "tip2":"上传镜像文件的地址。",
                "tip3":"镜像文件的格式。",
                "tip4":"镜像文件对应操作系统。",
                "tip5":"云主机的系统盘的容量。最小不能低于镜像文件，最大不能超过200G。",
                "tip6":"不勾选表示此镜像为私有镜像，不能被其它项目使用（也不可见）；勾选表示此镜像为公有镜像，可以被其它所有部门或项目使用。",
                "tip7":"镜像文件对应的操作系统类型。",
                "tip8":"镜像文件对应具体的操作系统。",
                "tip9":"安装云主机的镜像文件。",
                "tip10":"制作镜像创建的云主机使用的网络。",
                "tip11":"云主机CPU和内存。",
                "tip12":"勾选表示此镜像为私有镜像，不能被其它项目使用（也不可见）；不勾选表示此镜像为公有镜像，可以被其它所有部门或项目使用。",
                "tip13":"安装云主机所需的磁盘驱动和监控等工具。",
                "tip14":"硬盘控制器即磁盘驱动器适配器，是计算机与磁盘驱动器的接口设备。检测磁盘驱动器状态，按照规定的磁盘数据格式，把数据写入磁盘和从磁盘读出数据。设定 Virtio 硬控制接口能够取得更高的硬盘存取效率。",
                "tip15":"单核CPU处理网络中断存在瓶颈，不能完全满足网卡的需求，通过开启网卡多队列功能，可以将云主机中的网卡中断分散给不同的CPU处理，以满足网卡的需求，从而提升网络PPS和带宽性能。",
                "tip16":"选择云主机系统启动时使用的显卡驱动。",
                "tip17":"如果使用该镜像创建的云主机需要进行热升级或修改密码等操作，则需要开启虚拟机qemu代理。",
                "tip18":"当硬盘控制器选择virtio时，需要选择高性能硬盘控制器。开启表示virtio-scsi控制器，关闭则为virtio-blk控制器。"
            },
            "volumes":{
                "tip1":"选择创建云硬盘的模板。",
                "tip2":"该云硬盘关联的存储设备。",
                "tip3":"云硬盘的大小，最大不能超出剩余配额。",
                "tip4":"勾选此参数，云硬盘可以同时加载到多个云主机，支持多个云主机并发读写访问。",
                "tip5":"设置云硬盘为“可启动”，则该云硬盘可用于创建云主机。",
                "tip6":"扩容后数据盘的大小，最小值为不能低于之前的数据盘容量，最大值不能大于数据盘的剩余配额。",
                "tip7":"对云硬盘进行备份时，选择的卷首次备份时必须是全量备份，只有在有了全量备份时才能进行增量备份的创建。",
                "tip8":"将云硬盘从当前存储迁移到其他存储设备。",
                "tip9":"存储池的QoS是基于这个存储池创建云主机时，都具有这个QoS的属性。对于单个的云主机，如果需要修改云硬盘QoS，可以在云主机磁盘操作中修改当前的这个QoS的属性值。QoS是前端（nova）实现，不是通过后端存储驱动实现。",
                "tip10":"云硬盘QoS关联的存储池。解除关联后，该存储池上的云硬盘将不受QoS约束。",
                "tip11":"扩容后系统盘的大小，最小值为不能低于之前的系统盘容量，最大值不能大于云硬盘的剩余配额。",

            },
            "cephview":{
                "tip1":"较小的值，业务影响最小，但平衡时间很长。较大的值，业务卡顿明显，但平衡时间较快。",
                "tip2":"设置平衡方式，即定时平衡或者立即平衡数据。"
            },
            "backup": {
                "tips1":"对云硬盘进行备份时，选择的卷首次备份时必须是全量备份，只有在有了全量备份时才能进行增量备份的创建。"
            },
            "backupStrategy": {
                "tips1":"备份任务中最大保留的备份份数。当引用这个策略的备份为全量备份时，这个字段有效，如果是增量备份，这个字段无效。如果“执行频率”为一次时，则该字段可不填。",
                "tips2":"表示多长时间，执行一次备份。",
                "tips3":"设定该定时任务执行开始时间，时间需要指定年、月、日、时、分。"
            }
        },
        "message": {
            "required": "必填项",
            "textLength": "不能超过32位",
            "textLength_8": "不能少于8位",
            "textLength_16": "不能超过64位",
            "noZhSpecial": "8-64个字符，必须包含大小写英文与数字，特殊符号只包含：'-'、'_'、'.'、'@'。",
            "noIpAddress": "AnyBackup管理节点URL格式错误",
            "inputnum":"请填入1-10之间数字",
            "inputCpuNum":"请填入大于1小于16的数字，可以保留两位小数",
            "inputRamNum":"请填入大于1小于2的数字，可以保留两位小数"
        },
        "img": {
            "bulkRegistration":"批量注册",
            "uploadhttp":"从http上传",
            "uploadfile":"从文件上传",
            "progress":"上传进度",
            "uploadfail":"镜像上传失败",
            "uploadsuccess":"镜像上传成功",
            "uploadtips":"上传镜像耗时较长，且当前页面不能进行其他操作。可打开新页面进行其他操作。确定要打开新页面？",
            "subnetip":"子网网段",
            "subnetipholder":"请选择子网网段",
            "imgSizeTips":"文件大小不能超过10G且小于镜像缓存区空间容量",
            "img_updata_tips" : "请不要同时上传多个镜像,否则会导致镜像上传失败",
            "int1_200": "请输入1~200之间的整数",
            "confirmDelete": "确定删除镜像吗？",
            "system_type": "系统类型",
            "imageType": "镜像类型",
            "custom_value":"自定义",
            "download": "下载",
            "imageSourceType": "镜像源类型",
            "imageFile": "镜像文件",
            "chooseFile": "选择文件",
            "image": "镜像",
            "cancelCustom":"取消自定义输入",
            "buffer_space": "镜像缓存区空间",
            "buffer_space2": "清空镜像缓存区空间",
            "clear_space": "清空缓存空间",
            "clear_space_msg": "是否清空镜像缓存区空间",
            "create_image": "新建镜像",
            "upload_image": "上传镜像",
            "register_image": "注册镜像",
            "custom_image": "自定义镜像",
            "make_image": "制作镜像",
            "edit_image": "编辑镜像",
            "DIYscripts": "自定义脚本",
            "openConsole": "打开控制台",
            "console": "控制台",
            "chooseISO": "请选择ISO",
            "alearyHave": "已有",
            "alearyHaveImage": "已有镜像",
            "upload": "上传",
            "new_instance": "新建云主机",
            "modify": "编辑",
            "del": "删除",
            "issue_version":"发行版本",
            'system_type':"系统类型",
            "del_msg": "是否删除选中的镜像",
            "search": "搜索",
            "img_name": "镜像名称",
            "img_tips": "在上传镜像过程请勿关闭此窗口，否则将导致镜像上传失败！（再次点击可关闭）",
            "project_name": "项目名称",
            "type": "镜像类型",
            "public": "公有",
            "private": "私有",
            "img-alarm":'选择网卡模式为"e1000"或者"rtl8139"时，需要手动修改网卡配置文件ifcfg-eth0为ifcfg-ens3，并修改配置文件里"DEVICE=ens3"，再重启network服务',
            "is_public":{
                "true":"公有",
                "false":"私有"
            },
            "ISprivate": "是否私有",
            "OS": "操作系统",
            "chooseOS": "请选择操作系统",
            "chooseOSType": "请选择操作系统类型",
            "os_type": "系统类型",
            "version": "版本",
            "chooseVersion": "请输入版本",
            "architecture": "系统架构",
            "protected": "受保护",
            "status": "状态",
            "created_at": "创建时间",
            "img_detail": "镜像详情",
            "update_at": "更新时间",
            "username": "用户名",
            "required": "必填项",
            "less_than_32_bit": "不能超过32位",
            "copy_addr": "拷贝地址",
            "disk_format": "磁盘格式",
            "disk_size": "磁盘大小(GB)",
            "input_disk_size": "请输入磁盘大小",
            "img_size": "系统盘(GB)",
            "ok": "确定",
            "cancel": "取消",
            "detail": "基本信息",
            "instanceName": "云主机名",
            "imgChoice": "选择镜像",
            "num": "数量",
            "name": "镜像名称",
            "conf_option": "配置类型",
            "conf_type": "配置类型",
            "chooseConf": "请选择配置类型",
            "net_setting": "网络设置",
            "network": "网络",
            "chooseNet": "请选择网络",
            "config_IP": "配置IP",
            "repeatIP": "IP在该网络下已被使用，请重新输入",
            "portPolicyForbidden": "没有权限修改此私网IP",
            "netChoice": "选择网络",
            "advOption": "高级选项",
            "selfConfig": "云主机规格",
            "choiceConfig": "选择配置",
            "passwd": "密码",
            "pswdRepeat": "重复密码",
            "unmatchedPasswd": "密码不一致",
            "keypair": "密钥对",
            "keypairChoice": "选择密钥对",
            "firewall": "防火墙",
            "launchArea": "启动区域",
            "launchAreaChoice": "选择启动区域",
            "launchNodeChoice": "选择启动节点",
            "localStorage": "本地存储",
            "conf_detail": "配置详情",
            "launchNode": "启动节点",
            "core": "核",
            "memory": "内存",
            "vol_size": "磁盘(GB)",
            "imgtype":"镜像类型",
            "min_disk":"最小磁盘",
            "vol_sizeChoose": "请输入镜像磁盘大小",
            "trueSize": "镜像大小",
            "isenabled": "是否启用",
            "canUse": "是否可用",
            "uefiMod": "UEFI模块",
            "scsiModel": "SCSI控制器",
            "seniorAttr": "定义高级属性",
            "busType": "硬盘控制器",
            "selectBusType": "请选择硬盘总线类型",
            "videoModel": "网卡模式",
            "selectVideoModel": "请选择网卡模式",
            "videoQueue": "网卡多队列",
            "vifModel": "显卡模式",
            "selectVifModel": "请选择显卡模式",
            "biosMenu": "BIOS启动菜单",
            "qemu": "虚拟机qemu代理",
            "diskCtrl": "高性能硬盘控制器",
            "tip_message": "请先编辑镜像的系统类型或者系统盘大小或者系统架构。",
            "iso_message": "如果ISO为空，请到“镜像管理”模块，使用“上传镜像”功能添加ISO格式镜像",
            "repeatDefaultImg":"SystemCheck为系统默认镜像名称，请重新输入",
            "all":"全部",
            "type":"类型",
            "curImg":"当前镜像",
            "imageUploadFailedTip":"在上传镜像过程请勿关闭此窗口，否则可能导致镜像上传失败！（再次点击可关闭）",
            "dialog":{
                "download":"下载镜像",
                "register_image": "注册镜像",
            },
            "label":{
                "type":"镜像类型",
                "format":"镜像格式",
                "regFormat":"注册镜像的格式"
            },
            "placeholder":{
                "name":"请输入镜像名称",
                "type":"请选择镜像类型",
            },
            "msg":{
                "tips":"注意，镜像文件大小超过{{size}}G时不建议通过在线方式下载。"
            },
            "table": {
                "status": {
                    "unrecognized": "未识别",
                    "active": "正常",
                    "saving": "保存中",
                    "queued": "创建中",
                    "killed": "已关闭",
                    "pending_delete": "删除中",
                    "deleted": "删除中",
                    "resized": "运行"
                },
                "is_public": {
                    "true": "是",
                    "false": "否"
                },
                "is_protected": {
                    "true": "是",
                    "false": "否"
                },
                "canUse": {
                    "true": "是",
                    "false": "否"
                }
            },
            "osType": {
                "windows": "Windows",
                "linux": "Linux",
                "null": "",
                "unknown": ""
            },
            "arch":{
                "x86_64":"x86_64",
                "i686":"i686",
                "x86":"x86",
                "aarch64":"aarch64",
            },
            "imageDownload":{
                "imageName":"镜像名称：",
                "imageFormat":"镜像格式：",
                "imageId":"镜像Id：",
                "illustrate":"说明：",
                "illustrateRemark":"镜像下载仅支持命令行方式，并支持转化镜像格式，支持的格式包括'raw','qcow2','vmdk'.具体操作如下:",
                "imageDownloadCommand":"1.镜像下载命令：",
                "imageFormatCommand":"2.镜像格式转化命令：",
                "sourceImageFormat":"<源镜像的格式>",
                "destinationImageFormat":"<目的镜像的格式>",
                "sourceImageName":"<源镜像的名字>",
                "destinationImageName":"<目的镜像的名字>"
            }
        },
        "makeImage": {
            "osStatus": {
                "1": "创建中",
                "2": "创建成功",
                "3": "上传中",
                "4": "上传成功",
                "5": "已完成",
                "6": "创建失败",
                "7": "上传失败"
            },
            "isPrivate": {
                "true": "是",
                "false": "否"
            },
            "uefi":"引导启动",
            "useUefi":"通过UEFI进行引导",
            "confirmUpoad": "注意：注册镜像时，对镜像做的任何操作都将无效。",
            "other_disk": "附加工具盘",
            "other_soft_disk": "附加工具软盘",
            "disk": "工具盘",
            "soft_disk": "工具软盘",
            "makeImage": "点击选择",
            "inputImage": "请输入镜像名称",
            "int1_150": "请输入1~150之间的整数",
            "addMakeImgTip": "制作镜像过程中会占用一个虚机一个云硬盘的配额，请镜像创建成功后及时上传镜像释放配额"
        },
        "instances": {
            "nowNoNetwork":"当前无网络",
            "ethernetType":"以太网类型",
            "source":"来源",
            "migration_time":"迁移时间",
            "source_host":"迁移原主机",
            "target_host":"迁移目标主机",
            "migration_type":"迁移类型",
            "migration_status":"迁移状态",
            "evacuation":"疏散",
            "liveMigration":"热迁移",
            "adjustConfig":"调整配置",
            "coldMigration":"冷迁移",
            "migrationOK":"迁移完成",
            "migrationError":"迁移错误",
            "done":"虚拟机驱散到目标节点",
            "failed":"迁移失败",
            "label":"标签",
            "useSnaptip": "采用快照制作出来的镜像，只能在源虚拟机所在存储中创建新的虚拟机",
            "useSnap": "快照制作镜像",
            "instance": "云主机",
            "new": "新建",
            "boot": "启动",
            "stop": "关机",
            "vnc": "控制台",
            "moreNet": "网络操作",
            "moreVol": "磁盘操作",
            "moreOps": "更多操作",
            "restart": "重启",
            "forceShupdown": "强制关机",
            'bindVgpu':"绑定vGPU",
            "unbindVgpu":"解除绑定vGPU",
            "del_instance": "删除",
            "force_del_instance": "强制删除",
            "attachVolume": "加载云硬盘",
            "detatchVolume": "卸载云硬盘",
            "edit": "编辑主机名",
            "addNIC": "添加网卡",
            "clockSynchronization":"时钟同步",
            "closeClockSync":"关闭时钟同步",
            "modifyFixedIp": "修改私网IP",
            "modifyFixedIp_2": "修改IP地址",
            "addNICAlertInfo": "提示：添加多网卡后，需要在虚拟机中添加该网卡的网卡信息和配置相应静态路由信息",
            "modifyFixedIpAlertInfo": "提示：修改后的私网IP地址必须与之前的IP地址在同一个网段，同时不能与其他云主机的IP地址冲突。对于DHCP获得地址的云主机，修改私网IP后需要重启云主机或在云主机中重启网卡。",
            "portPolicyForbidden": "提示：此私网IP属于其他项目共享网络，没有权限修改",
            "moveNIC": "移除网卡",
            "bindingPublicIP": "绑定公网IP",
            "releasingPublicIPBindings": "解除公网IP",
            "setBandwidth": "调整带宽",
            "modifyIpAddr": "修改IP地址",
            "changeMac": "修改MAC地址",
            "makeImg": "制作镜像",
            "delAnnitify": "您确定要删除所选云主机组吗？",
            "loadIptables": "加载安全组",
            "networkCardConfig":"网卡安全配置",
            "networkCardPolicy":"网卡安全策略",
            "addAddressPair":"添加地址对",
            "backup": "备份",
            "migrate": "热迁移",
            "upgrade": "热升级",
            "suspend": "暂停",
            "unPause": "恢复暂停",
            "hang": "挂起",
            "uphang": "恢复挂起",
            "snapshot": "创建快照",
            "snapshotName": "快照名称",
            "resize": "调整配置",
            "search": "搜索",
            "projectName": "项目名称",
            "hostName": "物理主机",
            "imgName": "镜像名称",
            "privateIP": "私网IP",
            "chooseIP": "选择IP",
            "IPaddress": "IP地址",
            "bandwidth": "带宽",
            "inputNIC_bandwidth": "请输入网卡带宽",
            "publicIP": "公网IP",
            "status": "状态",
            "create_at": "创建时间",
            "createtime": "创建时间",
            "deletetime": "删除时间",
            "bootMethod": "启动方式",
            "cloudInstanceDetail": "云主机详情",
            "cloudInstanceName": "云主机名称",
            "instancesErrorStart":"云主机异常重启",
            "image": "镜像",
            "configration": "配置类型",
            "volume": "云硬盘",
            "securegroup": "安全组",
            "editinstanceName": "云主机名称",
            "chooseVgpu":"选择vGPU",
            "mac_addr": "MAC地址",
            "mac_addr_tip": "请确认mac地址未被使用，且虚拟机重启后配置生效，请谨慎修改",
            "configration_tip": "调整配置过程中请不要关闭浏览器窗口",
            "currentSetting": "当前配置",
            "evacuate": "云主机救援",
            "mountISO": "挂载ISO",
            "unmountISO": "卸载ISO",
            "card": "网卡",
            "selectNIC": "请选择网卡",
            "nic_bandwidth": "网卡带宽",
            "none": "无",
            "headerCheckbox": {},
            "editPsw":"修改密码",
            "ttEquipment":"透传设备",
            "deviceType": "设备类型",
            "hostSyncwithIns":"操作系统与宿主机时间同步状态",
            "rebuild":"一键还原",
            "affinityRole":"亲和性规则",
            "affinity":"亲和性",
            "notAffinity":"反亲和性",
            "affinityMembers":"亲和组成员",
            "uhostName":"云主机名称",
            "imageName":"镜像名称",
            "status":"状态",
            "withinIP":"内网IP",
            "outerIP":"外网IP",
            "privateIP":"私网IP",
            "publicIP":"公网IP",
            "anti-affinity":"反亲和性",
            "cdManage": "光盘管理",
            "settingHa": "设置高可用",
            "coldMigration": "冷迁移",
            "cdLoad": "加载",
            "cdUninstall": "卸载",
            "cdStatus": "已加载",
            "cdName": "光盘名称",
            "cdSize": "容量大小(GB)",
            "cdLoadInfo": "加载信息",
            "cdDescribe": "设备描述",
            "compatibility":"USB兼容性",
            "compatible":"兼容性",
            "cdConfrimISO": "您确定要卸载该ISO设备吗？",
            "stockTransfer":"云硬盘迁移",
            "volumesQoS":"磁盘QoS",
            "choiceVolumesQoS":"选择云硬盘",
            "newVmGroup":"新建云主机组",
            "diskInfoDetail":"磁盘信息",
            "diskSpace":"磁盘空间：",
            "storageEquipment":"对应存储设备：",
            "fromBackupRecover": "来自备份恢复",
            "5min": "5分钟",
            "1hour": "1小时",
            "1day": "1天",
            "notAvailable": "暂无",
            "notAvailableLog": "暂无日志信息",
            "networkCard": "网卡",
            "publicNetwork": "公网IP",
            "paasNet": "PaaS网络",
            "paasSubNet": "PaaS子网",
            "startManage": "启动顺序管理",
            "vmErrorManage": "虚机异常管理",
            "monitorDetail": "监控详情",
            "enabled": "已启用",
            "notEnabled": "未启用",
            "isSync": "已同步",
            "calculate":"计算",
            "volumeDetail":"云硬盘详情",
            "noAvailableNet":"当前无可用公网IP",
            "ipRepeat": "IP地址重复，请重新输入",
            "dataLoading": "数据加载中",
            "nfsInfo":"nfs系统盘若被挂载不支持迁移到ceph",
            "servicemonitor":"服务监控",
            "vmClone":"云主机克隆",
            "diskSelection":"磁盘选择",
            "onlyCloneSystem":"仅克隆系统",
            "bothClone":"克隆系统盘和数据盘",
            "specifyDepartment":"指定部门",
            "specifyProject":"指定项目",
            "number":"数量",
            "network":"网络",
            "specifiedSubnet":"指定子网",
            "specification":"规格",
            "basicInfo":"基础信息",
            "networkConfig":"网络配置",
            "hostBind":"高可用",
            "loadingLocalDisk":"加载本地磁盘",
            "detachLocalDisk":"卸载本地磁盘",
            "shelved":"搁置",
            "cancelShelving":"取消搁置",
            "settingHaModal":{
                "title":"设置高可用",
                "on":"开",
                "off":"关",
                "tips1":"挂载本地磁盘、挂载GPU、开启numa的虚拟机、大页内存、使用SR-IOV网络、开启DPDK功能、开启设备透传的虚拟机，强制开启后也无法实现高可用",
                "tips2":"关闭高可用后，云主机无法迁移及驱散。",
                "settingHaTips":"云主机开机、关机、挂机、暂停状态可以设置高可用"
            },
            "unit_list":{
                "onice": "一次",
                "hour": "按小时",
                "day": "按天",
                "week": "按周",
                "month": "按月",
            },
            "equipmentList": {
                "usb": "USB设备",
                "gpu": "GPU设备",
                "fpga": "FPGA设备"
            },
            "detailtable": {
                "disktype": "磁盘类型",
                "diskname": "磁盘名称",
                "disksize": "磁盘大小（GB）",
                "diskequip": "存储设备",
                "diskpool": "存储池",
            },
            "addinstances": {
                "tipes":"不支持从云硬盘、备份批量创建云主机",
                "capacity":"磁盘容量",
                "addDataDisk":"添加数据盘",
                "disk_buses":"硬盘控制器",
                "noFlavor":"没有可用的云主机规格",
                "addFlavor":"添加自定义规格",
                "chooseBoot":"云主机启动源",
                "chooseStorge":"选择源存储",
                "confirmInfo":"确认信息",
                "unmatchedFalvorTip":"没有找到可匹配的规格",
                "batchNoCephTip":"非ceph存储且镜像无缓存盘，不能进行批量创建",
                "snapshot": "快照",
                "newInstance": "新建云主机",
                "vmgroup":"云主机组",
                "vmgroupPlaceholder": "云主机组功能可能导致云主机高可用或者迁移失败",
                "memberEnough":"该组成员已满",
                "theMemberEnough":"，成员已满",
                "canCreate":"，可创建",
                "unitMember":"个成员)",
                "detail": "基本信息",
                "instanceName": "云主机名称",
                "instanceNameInput": "请输入云主机名称",
                "hostName": "主机名称",
                "hostNamePlaceholder": "请输入虚拟机操作系统名称指定的主机名",
                "hostName2": "Hostname",
                "hostNameInput": "请输入主机名称",
                "required": "必填项",
                "num": "数量",
                "imgChoice": "选择镜像",
                "os": "类型",
                "arch": "系统架构",
                "imgNameChoice": "选择镜像名称",
                "oxChoice": "选择操作系统",
                "type": "镜像类型",
                "private": "私有",
                "public": "公有",
                "conf_type": "配置类型",
                "net_setting": "网络设置",
                "name": "名称",
                "netChoice": "选择网络",
                "net": "网络名称",
                "config_ip": "配置IP",
                "advOption": "高级选项",
                "selfConfig": "云主机规格",
                "choiceConfig": "选择配置",
                "pass_setting": "密码设置",
                "passwd": "密码",
                "repeatPasswd": "重复密码",
                "notSamePasswd": "密码不一致",
                "password": "请输入密码",
                "comfirmpassword": "确认密码",
                "keypair": "密钥对",
                "keypairChoice": "选择密钥对",
                "securityGroupChoice": "选择安全组",
                "launchArea": "启动区域",
                "launchAreaChoice": "系统调度",
                "launchNode": "启动节点",
                "launchNodeChoice": "系统调度",
                "localStorage": "本地存储",
                "conf_detail": "配置详情",
                "memory": "内存",
                "ok": "确定",
                "cancel": "取消",
                "core": "核",
                "enter": "请输入",
                "integer": "的正整数",
                "ip_exist": "IP地址已存在，请重新输入",
                "ipAddr": "指定IP地址",
                "modifyAs": "修改为",
                "isUseLocal": "启动方式",
                "local": "本地盘",
                "useLocal": "分布式存储",
                "ebs": "逻辑卷",
                "toyou": "同友",
                "else": "其他",
                "voltype": "卷类型",
                "securityGroup": "安全组",
                "dataDisk": "数据盘",
                "localDisk": "系统盘",
                "local_sysytem":"本地系统盘",
                "local_data":"本地数据盘",
                "cputopu":"CPU拓扑",
                "unset":"未设置",
                "volume_set": "存储设置",
                "batchTip": "该qcow2格式的镜像还没有缓存卷,不允许进行批量创建",
                "volumsTip": "存储池资源不足，没有容量可供使用",
                "volumsErrorTip":"存储池数据获取异常",
                "x86_64":"64位",
                "i686":"32位",
                "aarch64":"aarch64",
                "vgpuType":"vGPU规格",
                "vgpuName":"vGPU名称",
                "vgpuTypePlaceholder":"请选择",
                "vgpuNamePlaceholder":"请选择",
                "isUseLocal":{
                    "0":"无",
                    "1":"本地盘",
                    "2":"云硬盘",
                },
                "scriTips":"此种磁盘控制器类型的虚拟机发生迁移后，需要调整磁盘启动顺序。",
                "noKeywords":"不能以backup-vol,fixedImageInstanceName开头",
                "nokey":"SystemCheck是关键字",
                "numaNode":"NUMA节点",
                "numaOc":"NUMA策略",
                "numaOcTips":{
                    "preferred":"preferred：优先在指定节点上分配NUMA资源，如果分配失败则继续在其他节点上进行分配。",
                    "strict":"strict：必须在指定节点上分配NUMA资源，如果分配失败则会导致使用该规格创建云主机失败。",
                },
                "vcardc":"虚拟网卡",
                "bigmemVal":"大页内存大小",
                "bigmemTips":{
                    "m1":"注意设置该参数之前需要调整好配置信息；",
                    "m2":"2.设置完成后会关闭物理机。"
                },
                "isLink1":"直连",
                "isLink2":"非直连",
                "nodeSetting":"NUMA节点设置",
                "cpuError":"CPU总和不等于所选规格最大CPU核数",
                "memError":"内存总和不等于所选规格最大内存数",
                "numaError":"配置的节点CPU或者内存总和必须等于所选规格的最大CPU核数或者最大内存，当前规格为：{{cpu}}核 {{memCtr}}G，最大CPU核数为{{vcpus_max}}核，最大内存为{{ram_maxCtr}}G",
                "nodeAdv":"节点设置",
                "nodeLabel":{
                    "detail":"详情",
                    "clear":"清除",
                    "node":"节点选择",
                    "cpu":"numa {{value}}的cpu",
                    "mem":"numa {{value}}的内存",
                    "addTips":"（添加数量不能大于节点数量）"
                },
                "nodePlaceholder":{
                    "node":"请选择节点",
                    "numaNode":"请选择NUMA节点",
                    "numaOc":"请选择NUMA策略",
                    "vcardc":"请选择虚拟网卡",
                    "bigmemVal":"请选择大页内存",
                    "cpu":"请输入cpu个数",
                    "cpu1":"请选择cpu",
                    "mem":"请输入内存大小（MB）"
                },
                "randomPassword":"随机密码",
                "copyPassword":"复制密码",
                "copySuccess":"复制成功",
                "hostNameSuffix":"的克隆",
                "stockTransferNotImageTip":"有快照的虚拟机不支持云硬盘迁移"
            },
            "info": {
                "configureInfo": "配置信息",
                "logInfo": "日志信息",
                "localDisk": "磁盘",
                "memory": "内存",
                "core": "核",
                "sriov": "（无效）",
            },
            "serviceMonitor": {
                "addService": "添加服务",
                "addServiceTips": "使用该功能需要虚拟机支持监控，可使用CloudToolsMonitor.iso制作的镜像创建。",
                "serviceName": "服务名称",
                "status": "状态",
                "description": "描述",
                "delTips": "您确定要删除选择的内容吗？",
                "states": {
                    "normal": "正常",
                    "abnormal": "异常"
                },
                "serviceNm": "服务名",
                "serviceUrl": "服务url",
                "port": "端口",
                "userName": "用户名",
                "password": "密码",
                "placeholder": {
                    "serviceNm": "请选择服务",
                    "serviceUrl": "请输入服务url",
                    "port": "请输入端口",
                    "userName": "请输入用户名",
                    "password": "请输入密码"
                }
            },
            "net": {
                "addNet": "添加网卡",
                "removeNet": "移除网卡"
            },
            "removeInsNetCard": "* 移除云主机自带网卡，将会导致云主机网络中断",
            "osedit": {
                "editInsName": "编辑主机名"
            },
            "vgpuModel":{
                "bind":"绑定vGPU",
                "unbind":"解除绑定vGPU"
            },
            "FloatingIp": {
                "ipAddr": "IP地址",
                "bindIP": "绑定公网IP",
                "unbindIP": "解除公网IP",
                "noFloatingIp": "无可用的公网IP",
                "ipSelect": "请选择公网IP"
            },
            "disk": {
                "volumeChoice": "选择硬盘"
            },
            "mkImg": {
                "makeImg": "制作镜像"
            },
            "backups": {
                "title":"备份",
                "day": "天",
                "week": "周",
                "name": "备份名称",
                "backups_status":"备份状态",
                "backups_type":"备份类型",
                "cycle": "备份周期",
                "rotation": "保留份数",
                "systemDisk": "选择系统盘",
                "dataDisk": "选择数据盘",
                "systemDiskPlace": "点击选择系统盘",
                "nosystemDiskPlace": "暂未获取到可用的系统盘",
                "localsystemDiskPlace":"暂不支持对本地盘创建备份",
                "dataDiskPlace": "点击选择数据盘",
                "nodataDiskPlace": "暂未获取到可用的数据盘",
                "leastchooseDisk": "请至少选择一块数据盘或系统盘",
                "cannotIncrementalTip":"当前无法创建增量备份，需要关联存在可用全量备份"
            },
            "migrates": {
                "node": "目标节点",
                "disk": "迁移本地磁盘",
                "quota": "允许磁盘超额分配",
                "tips": "当前虚拟机配置",
                "tips2": "此节点资源不足，无法迁移，请重新选择节点！"
            },
            "snapshot": {
                "title": "快照",
                "systemDisk": "云主机系统盘",
                "dataDisk": "云主机数据盘",
                "systemDiskPlace": "点击选择云主机系统盘",
                "nosystemDiskPlace": "暂未获取到可用的系统盘",
                "localsystemDiskPlace":"暂不支持对本地盘创建快照",
                "dataDiskPlace": "点击选择云主机数据盘",
                "nodataDiskPlace": "暂未获取到可用的数据盘",
                "leastchooseDisk": "请至少选择一块数据盘或系统盘",
                "timedSnap": "定时快照",
                "firstTimedSnap": "首次执行时间",
                "frequency": "执行频率",
                "timeUint": "小时",
                "holdNumber": "快照保留份数",
                "addtimeSnapTip": "当定时快照生成的快照份数超出最大保留份数时，将删除时间最早的快照文件，系统将只保留设置的最大保留份数的快照数量",
                "timeInterval": "时间间隔",
                "frequencyUnit": {
                    "hour": "小时",
                    "minute": "分钟",
                    "day": "天",
                    "week": "周",
                    "month": "月"
                },
                "task": {
                    "type":"任务类型",
                    "taskPlace":"请选择任务类型",
                    "new": "新建任务",
                    "edit": "编辑任务",
                    "1":"定时备份任务",
                    "0":"定时快照任务",
                    "null":"定时快照任务",
                    "keepBackupsNum":"保留最新备份份数",
                    "backupsTip":"当达到最新备份份数时，前面相应的备份文件将被删除。最少保留备份份数将由1个全量备份和n-1个增量备份组成的一组，当满足所设置的删除条件时，程序会将这个组的备份文件删除。",

                },
                "taskStatus": {
                    "PAUSED": "未激活",
                    "NORMAL": "激活",
                    "onetaskStatus": "单次任务已结束，无法激活"
                }
            },
            "rollback": {
                "title": "快照回滚",
                "systemDisk": "系统盘快照",
                "dataDisk": "数据盘快照",
                "systemDiskPlace": "点击选择系统盘快照",
                "nosystemDiskPlace": "没有支持快照回滚的系统盘快照",
                "dataDiskPlace": "点击选择数据盘快照",
                "nodataDiskPlace": "没有支持快照回滚的数据盘快照",
                "leastchooseDisk": "请至少选择一块数据盘快照或系统盘快照",
            },
            "backupRestore": {
                "title": "还原-磁盘操作",
                "systemDisk": "系统盘备份",
                "dataDisk": "数据盘备份",
                "systemDiskPlace": "点击选择系统盘备份",
                "nosystemDiskPlace": "暂未获取到可支持还原的系统盘备份",
                "dataDiskPlace": "点击选择数据盘备份",
                "nodataDiskPlace": "暂未获取到可支持还原的数据盘备份",
                "leastchooseDisk": "请至少选择一块数据盘备份或系统盘备份",
            },
            "mount": {
                "image": "镜像",
                "size": "大小"
            },
            "quota": {
                "domain_check": "参数应不小于项目最大配额",
                "proCpu": "项目CPU配额不足",
                "proRam": "项目内存配额不足",
                "proIns": "项目云主机配额不足",
                "proPhyIns": "项目物理云主机配额不足",
                "proVolumes": "项目云硬盘配额不足",
                "proGigabytes":"项目硬盘配额不足",
                "domCpu": "部门CPU配额不足 ",
                "domRam": "部门内存配额不足",
                "domIns": "部门云主机配额不足",
                "domPhyIns": "部门物理云主机配额不足",
                "domVolumes": "部门云硬盘配额不足",
                "domGigabytes":"部门硬盘配额不足",
                "proInfo": "项目配额详情",
                "proInfoIns": "云主机数量",
                "proInfoRam": "内存数量",
                "proInfoCpu": "CPU数量",
                "proInfoVolumes": "云硬盘数量",
                "domInfo": "部门配额详情",
                "domInfoIns": "云主机数量",
                "domInfoRam": "内存数量",
                "domInfoCpu": "CPU数量",
                "domInfoVolumes": "云硬盘数量",
                "used": "已使用",
                "coreMaxText": "CPU数量不能超过",
                "ramMaxText": "内存数量不能超过"
            },
            "table": {
                "status": {
                    "all": "全部",
                    "active": "运行",
                    "stopped": "关机",
                    "shutoff": "关机",
                    "error": "错误",
                    "building": "创建中",
                    "build": "创建中",
                    "powering-off": "关机中",
                    "powering-on": "开机中",
                    "deleting": "删除中",
                    "deleted": "删除中",
                    "soft-deleting": "删除中",
                    "soft-delete": "删除中",
                    "reboot_pending": "重启中",
                    "reboot": "重启中",
                    "paused": "暂停",
                    "pausing": "暂停中",
                    "unpausing": "恢复暂停中",
                    "suspended": "挂起",
                    "suspending": "挂起中",
                    "resuming": "恢复挂起中",
                    "verify_resize": "准备调整配置完成",
                    "resize": "准备调整配置中",
                    "resize_prep": "准备调整配置中",
                    "resize_finish": "准备调整配置完成",
                    "resize_migrating": "准备调整配置中",
                    "resized": "准备调整配置完成",
                    "revert_resize": "取消热升级",
                    "image_backup": "备份中",
                    "image_snapshot": "制作镜像中",
                    "spawning": "创建中",
                    "up": "健康",
                    "down": "异常",
                    "migrating": "热迁移中",
                    "rebuilding": "重建中",
                    "rebuild": "重建中",
                    "unknow": "错误",
                    "unrecognized": "无法识别",
                    "block_device_mapping":"创建中",
                    "image_snapshot_pending":"准备镜像快照",
                    "image_pending_upload":"镜像等待上传",
                    "image_uploading":"镜像上传中",
                    "snapshot_revert":"快照回滚中",
                    "snapshot_revert_penging":"快照等待回滚",
                    "updating_password":"更新密码中",
                    "resize_migrated":"调整配置中",
                    "resize_reverting":"调整配置取消中",
                    "resize_confirming":"调整配置确认中",
                    "rebooting":"重启中",
                    "reboot_pending":"等待重启",
                    "reboot_started":"重启中",
                    "rebooting_hard":"强制重启中",
                    "reboot_pending_hard":"等待强制重启",
                    "reboot_started_hard":"强制重启中",
                    "rescuing":"救援中",
                    "unrescuing":"取消救援中",
                    "rebuild_block_device_mapping":"重建中",
                    "rebuild_spawning":"重建中",
                    "restoring":"恢复中",
                    "shelving":"",
                    "shelving_image_pending_upload":"",
                    "shelving_image_uploading":"",
                    "shelving_offloading":"",
                    "unshelving":"",
                    "cloning":"克隆中",
                    "networking":"创建中",
                    "scheduling":"创建中",
                    "soft_deleted":"软删除",
                    "shelved_offloaded":"搁置",
                    "shelving":"搁置中",
                    "unshelving":"取消搁置中",
                    "lock":{
                        "true":"锁定",
                        "false":"未锁定"
                    },
                    "hostBind":{
                        "open":"开启",
                        "close":"关闭"
                    }

                },
                "Local": "本地",
                "msg":"原始镜像不存在，无法重装"
            },
            "confName": {
                "m1.tiny": "标准配置",
                "m1.small": "中级配置",
                "m1.medium": "高级配置"
            },
            "conf": {
                "m1.tiny": "标准配置: CPU 1核 内存 512M 支持云硬盘",
                "m1.small": "中级配置: CPU 1核 内存 2G 支持云硬盘",
                "m1.medium": "高级配置: CPU 2核 内存 4G 支持云硬盘",
                "pretip": "高级配置: CPU ",
                "memtip": "核 内存 ",
                "endtip": "GB 支持云硬盘",
                "core": "核",
                "dataDisk": "GB 数据盘 "
            },
            "tipMsg": {
                "startServer": "您确定要启动虚拟机吗？",
                "startIronicServer": "您确定要启动物理机吗？",
                "stopServer": "您确定要关机吗？",
                "delSever": "您确定要删除吗？",
                "reboot": "您确定要重启吗？",
                "shutdown": "您确定要强制关机吗？",
                "osSuspend": "您确定要暂停云主机吗？",
                "osUpPause": "您确定要恢复暂停云主机吗？",
                "osHang": "您确定要挂起云主机吗？",
                "osUpHang": "您确定要恢复挂起云主机吗？",
                "restoreSever": "您确定要恢复云主机吗？",
                "forcedelSever": "您确定要清除云主机吗？",
                "forcedelvm": "您确定要强制删除云主机吗？",
                "evacuate": "您确定要进行云主机救援吗？",
                "delsanpJob": "将同时删除云主机所有云硬盘的计划任务",
                "rmVolumeSnapJob": "卸载云硬盘会同时将该盘从计划任务中移除",
                "delUserJob": "将同时删除该用户下所有的计划任务",
                "unloadTtEquipment":"您确定要卸载该透传设备吗？",
                "bindFloatingIpVpnInfo":"绑定公网IP会与IPSec VPN功能冲突，请检查是否已建立IPSec 站点连接",
                "uninstalltTip":"云硬盘在使用过程中执行卸载操作会存在风险，请先在该云硬盘挂载的云主机的操作系统中完成卸载，再执行本操作。",
                "stock_transferTip":"云硬盘迁移需要满足所挂载的云主机为开机状态或者关机状态",
                "stock_transferTip1":"云主机正在进行云硬盘迁移，请稍后操作",
                "oneStoragePoolCanNotTransfer":"当前只有一个存储池，不能进行云硬盘迁移操作",
                "clockSync":"您确定开启所选云主机与宿主机时钟同步吗？",
                "closeClockSync":"您确定关闭所选云主机与宿主机时钟同步吗？",
                "opsOperation":"该操作仅限于运维操作，请在重置前确认虚拟机状态",
                "currentIp":"*请输入正确的IP地址",
                "currentMac":"*请输入正确的MAC地址",
                "isomsg":"在使用iso方式创建虚拟机时，请将操作系统安装在第一块磁盘上",
                "fpgaOperate":"云主机开机状态下只能查询，不能进行加载、卸载FPGA操作",
                "connectPaas":"若需连通PaaS资源，请给云主机添加网卡（PaaS网络）。",
                "unbindVgpu":"您确定要解除绑定vGPU吗？",
                "editIso":"通过iso创建的虚拟机制作镜像后，需要在镜像管理页面手动编辑镜像属性",
                "restoreDisk": "您确定要恢复数据盘吗？",
                "fpgaOrGpuOperate":"云主机开机状态下只能查询，不能进行卸载GPU和FPGA操作",
                "forceDelVolume": "您确定要彻底删除数据盘吗？",
                "shelveServer":"您确定要搁置云主机吗？",
                "unShelveServer":"您确定要取消云主机搁置吗？",
                "unInsShelve":"搁置状态下的云主机无法卸载云硬盘。"
            },
            "placeholder": {
                "resize_n": "没有可用的配置类型",
                "resize_c": "请选择配置类型",
                "no_canLoad_drvice":"暂无可加载的设备",
                "limit_device_USB":"同一个云主机上USB设备最多只能同时加载3个",
                "limit_device_GPU":"同一个云主机上GPU设备最多只同时加载1个",
                "limit_device_FPGA":"同一个云主机上FPGA设备最多只同时加载1个"
            },
            "tip": {
                "sigtip": "请选择一个云主机",
                "choose_tip":"请选择云主机",
                "choose_tip_more":"不支持批量操作",
                "change_Mac_tip": "修改mac地址需要在关机状态下进行",
                "resize_tip": "调整配置需要在关机并且没有加载透传设备的状态下进行",
                "snapshot_rollback_tip": "云主机快照回滚需要在关机状态下进行",
                "not_get_vmVolume": "暂未获取到该云主机下的云硬盘",
                "editPswTip":"修改密码需在开机状态下进行",
                "rebuildTip":"一键还原需要具有镜像信息,且在开机、关机并且没有任务的状态下进行",
                "rebuildTip1":"一键还原需要满足系统盘为使用状态",
                "sure_rebuild":"一键还原将会使云主机系统盘中的用户数据全部被清除！并还原到云主机初始创建的状态，请确认是否进行该操作！",
                "repeatNameTip":"该名称已被使用,无法创建",
                "coldMigrate": "冷迁移需要在关机并且没有加载透传设备的状态下进行",
                "coldMigrate1": "冷迁移需要在没有绑定vGPU的虚拟机下进行",
                "migrateTip": "热迁移需要在开机并且没有加载透传设备的状态下进行",
                "bootOrderTip":"启动顺序管理需在关机状态下进行",
                "backupRestoreTip":"还原需在关机状态下进行",
                "ttEquimentTip":"设备透传需要在开机或者关机状态下进行",
                "normalStatusTip":"该状态下不支持此操作",
                "normalStartLoadFpga":"开机状态下且加载FPGA卡不支持此操作",
                "makImgTip":"制作镜像需满足系统盘为使用状态",
                "migrateTip1":"热迁移需满足系统contentStatusType盘为使用状态",
                "coldMigrateTip1":"冷迁移需满足系统盘为使用状态",
                "storageTypeTip":"该系统盘所在存储不支持此操作",
                "migrateTip2":"热迁移需在开机状态下进行",
                "migrateTip3":"热迁移需在没有绑定vGPU的虚拟机下进行",
                "coldMigrateTip2":"冷迁移需在关机状态下进行",
                "resize_tip2":"调整配置需在关机状态下进行",
                "resize_tip3":"绑定有vGPU的虚拟机不可调整配置",
                "manageCDTip":"光盘管理需在关机状态下进行",
                "resetTip":"云主机在错误状态下，通过运维确认云主机正常时，可以通过重置状态。",
                "localTip":"本地盘的云主机不支持此功能",
                "forceStopTip":"强制关机需满足错误状态，且物理主机有分配值时",
                "oshotgradeTip":"热升级需要在开机状态下进行",
                "oshotgradeTip1":"该虚拟机的配置暂不支持此功能",
                "not_get_info":"暂未获取到镜像信息",
                "shutdownTip":"请选择关机状态的虚拟机",
                "maxSizeTip":"物理节点系统盘空间不足无法制作镜像",
                "poolSize_rebuild":"云主机所在存储设备资源池容量不足，无法进行还原",
                "loading_Disk_Tip":"请在开机、关机条件下进行",
                "loading_Disk_Tip1":"云主机正在进行云硬盘迁移，请稍后操作",
                "cpuExceedTip":"目标CPU核数不能超过单个物理节点的CPU核数 ",
                "cpuExceedTip1":"物理节点CPU核数不足，请清理资源 ",
                "ramExceedTip":"物理节点内存不足，请清理资源",
                "stock_transferTip":"",
                "insHasBackupJob":"所删除的云主机有备份任务存在，请先解除备份任务，再进行删除操作",
                "bindVgpu":"绑定vGPU需在关机条件下进行",
                "bindVgpu1":"已绑定VGPU",
                "unbindVgpu":"创建中和错误状态下云主机不可解除绑定vgpu",
                "unbindVgpu1":"云主机未绑定vgpu",
                "mainHostClone":"云主机克隆",
                "vmCloneTip":"云主机克隆需要在关机状态下进行;通过本地盘创建、通过iso启动的、挂载FPGA或GPU、开机状态且有加载fpga卡、透传了usb设备、绑定vgpu、挂载了本地磁盘、设置了大页内存、numa拓扑、sr-iov网络时，不可以克隆",
                "hangUpTip":"云主机挂载FPGA或GPU，不可挂起",
                "shelveServerTip":"云主机搁置需要在运行、关机、暂停或者挂起状态下进行;挂载了本地磁盘、挂载了GPU、开启numa、大页内存、使用SR-IOV网络、开启DPDK功能、开启设备透传的云主机不可以搁置",
                "unShelveServerTip":"云主机搁置状态可以取消搁置"
            }
        },
        "loadbalance": {
            "enhanceListStatus":"状态",
            "checkUp":"健康检查",
            "advancedSetup":"高级设置",
            "specifyTheNetwork":"指定外网IP",
            "theSpecifiedNetwork":"指定内网IP",
            "outerNetConfig":"配置外网",
            "intranetNetConfig":"配置内网",
            "IPMI":"IPMI地址",
            "powerState":"电源状态",
            "registrationDate":"注册时间",
            "bindPublicIP": "绑定公网IP",
            "unbindPublicIP": "解绑公网IP",
            "selectStatus": "请选择状态",
            "name": "名称",
            "privateIP": "私网IP",
            "publicIP": "公网IP",
            "withinIP": "内网IP",
            "outerIP": "外网IP",
            "status": "状态",
            "maxConnections": "最大连接数",
            "inputMaxConnections": "请输入数字，不输入默认为无限制",
            "noLimit": "无限制",
            "description": "描述信息",
            "inputName": "请输入名称",
            "inputDescription": "请输入描述信息",
            "subnet":"子网",
            "withinsubnet":"内网子网",
            "selectSubnet": "请选择子网",
            "inputNumber": "请输入数字",
            "addTitle": "新建负载均衡",
            "editTitle": "编辑负载均衡",
            "createBalanceSuccess": "负载均衡创建成功",
            "createBalanceSucTips": "负载均衡必须配置监听器和后端云主机才能生效，是否立刻创建配置？",
            "createConf": "新建配置",
            "ceeateLater": "稍后新建",
            "enableLisTip":"您确定启用所选内容吗？",
            "forbiddenTip": "您确定禁用所选内容吗？",
            "deleteTip": "您确定删除所选内容吗？",
            "unbindIpTip": "您确定解除公网IP的绑定吗？",
            "applyIp": "申请公网IP",
            "publicIpPool": "外部网络",
            "selectIpPool": "请选择外部网络",
            "specifiedIp": "指定外部IP地址",
            "subSegment": "子网网段",
            "selectSubSegment": "请选择子网网段",
            "noSubSegment": "无可用的子网网段",
            "ipAddr": "IP地址",
            "publicIpQuota": "公网IP配额",
            "detail": "详情",
            "basicInfo": "基本信息",
            "listener": "监听器",
            "createListener": "新建监听器",
            "editListener": "编辑监听器",
            "listenerDetail": "监听器详情",
            "listenerManage": "监听器管理",
            "monitor": "监控",
            "lbId": "ID",
            "configStatus": "配置状态",
            "provider": "提供者",
            "tenantId": "租户ID",
            "createTime": "创建时间",
            "listenerProtocol": "监听协议",
            "port": "端口",
            "balanceAlgorithm": "均衡算法",
            "healthcheck": "健康检查",
            "healthcheckTips": "检查后端云主机的健康状态。当后端某台云主机健康检查出现异常时，负载均衡会自动将新的请求分发到其它健康检查正常的云主机上；而当该云主机恢复正常运行时，负载均衡会将其自动恢复到负载均衡服务中",
            "protocolPort": "监听协议[端口]",
            "selectProtocol": "请选择协议类型",
            "selectAlgorithm": "请选择均衡算法",
            "inputPort": "请输入端口号",
            "portRange": "端口范围为1~65535",
            "port1_65500": "端口范围为1~65500",
            "healthcheckWay": "健康检查方式",
            "selectHealthcheckWay": "请选择健康检查方式",
            "delay": "延迟(秒)",
            "overtime": "超时(秒)",
            "maxRetries": "最大重试次数",
            "url": "URL",
            "inputUrl": "请输入URL地址",
            "httpStatus": "HTTP状态码",
            "inputHttpStatus": "请输入HTTP状态码",
            "keepSession": "会话保持",
            "selectKeepSession": "请选择会话保持方式",
            "cookieName": "cookie名称",
            "checkItem": "检查选项",
            "createBackend": "添加后端",
            "editBackend": "编辑成员",
            "bindIns": "绑定云主机",
            "modifyWeight": "修改权重",
            "notChooseLisTips": "请在上面列表中选择一个监听器。",
            "insName": "云主机名称",
            "weight": "权重",
            "input_1_65535": "请输入1-65535",
            "input_1_100": "请输入1-100",
            "addServer": "添加后端服务器",
            "editServer": "编辑后端服务器",
            "chooseIns": "选择云主机",
            "setPortWeight": "设置端口和权重",
            "writePortWeight": "填写端口和权重",
            "port_1_65535": "1-65535",
            "weight_1_100": "1-100",
            "memberName":"成员名称",
            "hostName":"云主机名称",
            "table": {
                "admin_state_up": {
                    "offline":"离线",
                    "true": "启用",
                    "false": "禁用",
                    "enable": "启用",
                    "disabled":"禁用",
                    "disable":"禁用",
                },
                "provisioning_status": {
                    "PENDING_UPDATE": "正在更新",
                    "PENDING_CREATE": "正在创建",
                    "INACTIVE": "不可用",
                    "CREATING":"创建中",
                    "INITIALIZING":"初始化中",
                    "ACTIVE":"可用 ",
                    "ERROR":"错误",
                    "DELETING":"删除中",
                    "SETTING":"调整配置中",
                }
            },
            "lisTab": {
                "lb_algorithm": {
                    "ROUND_ROBIN": "轮询",
                    "LEAST_CONNECTIONS": "最小连接数",
                    "SOURCE_IP": "源IP"
                },
                "provisioning_status": {
                    "ACTIVE": "正常",
                    "DELETED": "已删除",
                    "ERROR": "错误",
                    "PENDING_CREATE": "创建中",
                    "PENDING_UPDATE": "修改中",
                    "PENDING_DELETE": "删除中"
                }
            },
            "insTab": {
                "operating_status": {
                    "ONLINE": "在线",
                    "OFFLINE": "离线",
                    "DEGRADED": "降级",
                    "DISABLED": "不可用",
                    "NO_MONITOR": "未监听"
                }
            },
            'info':{
                'subnet':"负载均衡器在网络中安装的位置，服务于该子网下的所有云主机。",
                'bindIP':"绑定公网IP的负载均衡服务可以被外部网络访问。",
                'protocolPort':"负载均衡对接的服务端口。",
                'keepSession':"会话保持将一定时间内来自同一用户的访问请求转发到同一服务器上处理，实现用户访问连续性。",
                'balanceAlgorithm':"轮询算法：按顺序把每个新的连接请求分配给下一个服务器，最终把所有请求平分给所有的服务器。最少连接：系统把新的连接请求分配给当前连接数目最少的服务器。源IP算法：将请求的源IP地址作为散列键（HashKey），从静态分配的散列表找出对应的服务器。",
                'maxConnections':"定义负载均衡器的最大连接数，默认为2000。",
                'delay':"心跳的延迟最大值。",
                'overtime':"心跳的超时最大值。",
                'maxRetries':"心跳超时后的最大重试次数。",
                'url':"HTTP/HTTPS的状态监控目标，通过自动访问该URL，判断状态码来确定服务是否可用。",
                'httpStatus':"通过HTTP/HTTPS请求访问URL反馈的状态码，确认HTTP/HTTPS服务状态是否正常。默认是200。",
                'healthcheck':"检查后端云主机的健康状态。当后端某台云主机健康检查出现异常时，负载均衡会自动将新的请求分发到其它健康检查正常的云主机上；而当该云主机恢复正常运行时，负载均衡会将其自动恢复到负载均衡服务中。",
                'weight':"定义负载均衡算法中各成员的权重比例。",
                "alarmThreshold":"当监控指标最大连接数触发设定的阈值时会产生告警，例如“最大连接数>=80%”就是一个告警条件。",
                "alarmLevel":"根据告警的严重程度选择不同等级",
            },
            "clientTimeout":"客户端超时时间",
            "serverTimeout":"服务端超时时间",
            "clientFinTimeout":"客户端TCP半关闭连接超时时间",
            "serverFinTimeout":"服务端TCP半关闭连接超时时间",
            "httpRequestTimeout":"HTTP请求超时时间",
            "httpKeepAliveTimeout":"HTTP会话保持超时时间",
            "timeUnit":{
                "ms":"ms(毫秒)",
                "s":"s(秒)",
                "min":"m(分钟)",
                "h":"h(小时)",
                "d":"d(天)"
            },
            "maxLen10":"请最多输入10位数",
            "monitorEchart":"监控",
            "monitorItem":{
                "item0":"最大连接数",
            },
            "monitorLabel":"监控项",
            "enableAlarm":"启用告警",
            "alarmThreshold":"告警阈值",
            "alarmLevel":"告警级别",
            "pleaseSelect":"请选择",
            "pcs":"个",
            "virtualIP":"虚IP",
            "virtualIpPort":"虚IP端口",
            "virtualIpSubnet":"虚IP子网",
            "inputNumber_default_50":"请输入数字,不填默认为50s",
            "inputNumber_default_30":"请输入数字,不填默认为30s",
            "pool":"资源池",
        },
        "ports":{
            "load":"加载",
            "unload":"卸载并删除",
            "name":"名称",
            "insName":"云主机名称",
            "network":"网络",
            "subnet":"子网",
            "ipAdress":"IP地址",
            "status":"状态",
            "MACadress":"MAC地址",
            "associatedHost":"关联云主机",
            "associatedResource":"关联资源",
            "createtime":"创建时间",
            "mounted":"已挂载",
            "unmounted":"未挂载",
            "islinked":"是否直连",
            "newPorts":"新建虚拟网卡",
            "updatePorts":"编辑虚拟网卡",
            "specifySubnet":"指定子网",
            "selectSubnet":"选择子网",
            "specifyIPAdress":"指定IP地址",
            "subnetSegment":"子网网段",
            "description":"描述",
            "insName":"云主机名称",
            "privateIP":"私网IP地址",
            "unloadPorts":"您确定要卸载并删除该网卡吗？",
            "notUnloadPorts":"当前选择网卡为云主机的最后一块网卡，不能被卸载。",
            "delPorts":"您确定要删除吗？",
            "notDelPorts":"所选网卡已被云主机使用，不可进行删除操作",
            "portNotOperate":"负载均衡增强型的虚拟网卡不能做任何操作",
            "tip0":"请选择一个虚拟网卡",
            "placeholder":{
                "name":"请输入名称",
                "description":"请输入描述",
                "network":"选择网络",
                "noNetwork":"没有可选的网络",
                "subnet":"选择子网",
                "noSubnet":"没有可选的子网",
                "subnetSegment":"选择子网网段",
                "noSubnetSegment":"没有可选的子网网段",
            },
            "helpMsg":{
                "title":"提示信息",
                "one":"当前选择子网含有默认网关，添加后可能会影响该云主机其他网卡的连通性，请确认是否添加该子网的虚拟网卡？",
                "two":"针对于linux云主机，请在添加网卡后，在云主机系统中添加新网卡的配置文件，并启动网卡，才能真正使用所添加的虚拟网卡。"
            }
        },
        "elasticExpansion":{
            "name":"名称",
            "extensionalStrategy":"伸展策略",
            "contractileStrategy":"收缩策略",
            "telescopicCluster":"伸缩集群",
            "status":"状态",
            "loadbalancer":"负载均衡器",
            "listener":"监听器",
            "expansionSpecification":"伸缩规格",
            "imageName":"镜像名称",
            "currentInstanceNum":"当前云主机数量",
            "maxInstanceNum":"最小云主机数量",
            "currentInstanceNum":"最大云主机数量",
            "baseMsg":"基础信息",
            "instancesList":"云主机列表",
            "retractableActivityLog":"伸缩活动日志",
            "name":"名称",
            "createdTime":"创建时间",
            "contactGroup":"联系人组",
            "removeClusterDelete":"移除伸缩集群并删除",
            "triggerCondition":"触发条件",
            "triggerAction":"触发动作",
            "triggerLogic":"触发逻辑",
            "monitoringCycle":"监控周期",
            "monitoringRules":"监控规则",
            "singleIncrease":"单次增加",
            "maximumQuantity":"最大数量",
            "instanceConfiguration":"云主机配置",
            "instanceName":"云主机名称",
            "settingPort":"设置端口",
            "settingWeight":"设置权重",
            "coolingTime":"冷却时间",
            "singleReduction":"单次减少",
            "minimumQuantity":"最小数量",
            "specification":"规格",
            "image":"镜像",
            "entryMode":"加入方式",
            "joinTime":"加入时间",
            "activity":"活动",
            "insNumPreExpansion":"伸缩前云主机数量",
            "insNumAfterExpansion":"伸缩后云主机数量",
            "activityStartTime":"活动开始时间",
            "activityEndTime":"活动结束时间",
            "activityResult":"活动结果",
            "newExpansion":"新建弹性伸缩",
            "baseConfig":"基础配置",
            "inputMonitorName":"请输入监听器名称",
            "triggerRules": "触发规则",
            "placeholder":{
                    "name":"请输入名称",
                    "loadbalancer":"请选择负载均衡器",
                    "noLoadbalancer":"没有可选的负载均衡器",
                    "listener":"请选择监听器",
                    "noListener":"没有可选的监听器",
                    "contactGroup":"请选择联系人组",
                    "noContactGroup":"没有可选的联系人组",
            }
        },
        "sockets": {
            "opLog": {
                "compute.instance.create.start": "创建中",
                "compute.instance.create.end": "创建成功",
                "compute.instance.power_off.start": "关机中",
                "compute.instance.power_off.end": "关机成功",
                "compute.instance.power_on.start": "开机中",
                "compute.instance.power_on.end": "开机成功",
                "compute.instance.vgpu_device.attach.end":"绑定vGPU成功",
                "compute.instance.vgpu_device.attach.start":"正在绑定vGPU",
                "compute.instance.vgpu_device.attach.error":"绑定vGPU失败",
                "compute.instance.vgpu_device.detach.end":"解绑vGPU成功",
                "compute.instance.vgpu_device.detach.start":"解绑vGPU成功",
                "compute.instance.vgpu_device.detach.error":"解绑vGPU成功",
                "compute.instance.delete.start": "删除中",
                "compute.instance.delete.end": "删除成功",
                "compute.instance.soft_delete.start": "删除中",
                "compute.instance.soft_delete.end": "删除成功",
                "compute.instance.shutdown.start": "强制关机中",
                "compute.instance.shutdown.end": "强制关机成功中",
                "compute.instance.restore.start": "恢复中",
                "compute.instance.restore.end": "恢复成功",
                "compute.instance.reboot.start": "重启中",
                "compute.instance.reboot.end": "重启成功",
                "compute.instance.pause.start": "暂停中",
                "compute.instance.pause.end": "暂停成功",
                "compute.instance.unpause.start": "恢复暂停中",
                "compute.instance.unpause.end": "恢复暂停成功",
                "compute.instance.suspend.start": "挂起中",
                "compute.instance.suspend.end": "挂起成功",
                "compute.instance.resume.start": "恢复挂起中",
                "compute.instance.resume.end": "恢复挂起成功",
                "compute.instance.resize.prep.star": "调整配置中",
                "compute.instance.resize.prep.start": "准备调整配置中",
                "compute.instance.resize.prep.end": "准备调整配置中",
                "compute.instance.exists": "准备调整配置中",
                "compute.instance.resize.start": "准备调整配置中",
                "cold_migrate.compute.instance.resize.start": "准备冷迁移中",
                "compute.instance.resize.end": "准备调整配置中",
                "compute.instance.finish_resize.start": "准备调整配置中",
                "compute.instance.finish_resize.end": "准备调整配置完成",
                "cold_migrate.compute.instance.finish_resize.end": "准备冷迁移完成",
                "compute.instance.resize.confirm.start": "确认调整配置中",
                "compute.instance.resize.confirm.end": "调整配置完成",
                "cold_migrate.compute.instance.resize.confirm.end": "冷迁移完成",
                "resize_instance": "调整配置失败",
                "cold_migrate.resize_instance": "冷迁移失败",
                "compute.instance.upgrade.confirm.start": "热升级中",
                "compute.instance.upgrade.confirm.end": "热升级成功",
                "compute.instance.rebuild.error": "重建虚拟机错误",
                "compute.instance.rebuild.scheduled": "准备重建虚拟机",
                "compute.instance.rebuild.start": "开始重建虚拟机",
                "compute.instance.rebuild.end": "重建虚拟机成功",
                "compute.instance.snapshot.start": "创建快照中",
                "compute.instance.snapshot.end": "快照创建成功",
                "compute.instance.evacuate": "云主机撤离中",
                "compute.instance.live_migration.pre.start": "准备热迁移中",
                "compute.instance.live_migration.pre.end": "准备热迁移完成",
                "compute.instance.live_migration._rollback.start": "热迁移回滚开始",
                "compute.instance.live_migration._post.start": "热迁移回滚开始",
                "compute.instance.live_migration._rollback.end": "热迁移回滚完成",
                "compute.instance.live_migration._post.end": "热迁移回滚完成",
                "compute.instance.live_migration.rollback.dest.start": "目标节点回滚（清理目标节点）",
                "compute.instance.live_migration.rollback.dest.end": "目标节点回滚完成",
                "compute.instance.live_migration.post.dest.start": "开始热迁移",
                "compute.instance.live_migration.post.dest.end": "热迁移成功",
                "compute.phy_instance.create.start":"物理云主机创建中",
                "compute.phy_instance.create.end":"物理云主机创建成功",
                "compute.phy_instance.create.error":"物理云主机创建时失败",
                "compute.phy_instance.power_on.start":"物理云主机开机中",
                "compute.phy_instance.power_on.end":"物理云主机开机成功",
                "compute.phy_instance.power_off.start":"物理云主机关机中",
                "compute.phy_instance.power_off.end":"物理云主机关机成功",
                "compute.phy_instance.reboot.start":"物理云主机重启中",
                "compute.phy_instance.reboot.end":"物理云主机重启完成",
                "compute.phy_instance.delete.start":"物理云主机删除中",
                "compute.phy_instance.delete.end":"物理云主机删除完成",
                "compute.phy_instance.nohost":"没有可用的物理主机资源",
                "snapshot.create.start": "创建快照中",
                "snapshot.create.end": "快照创建成功",
                "snapshot.delete.start": "删除快照中",
                "snapshot.delete.end": "删除快照",
                "snapshot.rollback.start": "快照回滚中",
                "snapshot.rollback.end": "快照回滚成功",
                "compute.instance.volume.attach": "云主机加载云硬盘成功",
                "compute.instance.volume.detach": "云主机卸载云硬盘成功",
                "volume.create.start": "云硬盘创建中",
                "volume.create.end": "云硬盘创建成功",
                "volume.delete.start": "云硬盘删除中",
                "volume.delete.end": "云硬盘删除成功",
                "volume.soft_delete.start": "云硬盘软删除中",
                "volume.soft_delete.end": "云硬盘软删除成功",
                "volume.resize.start": "云硬盘扩容中",
                "volume.resize.end": "云硬盘扩容成功",
                "volume.attach.start": "云硬盘加载中",
                "volume.attach.end": "云硬盘加载成功",
                "volume.detach.start": "云硬盘卸载中",
                "volume.detach.end": "云硬盘卸载成功",
                "volume.update.start": "云硬盘更新中",
                "volume.update.end": "云硬盘更新成功",
                "volume.retype.end":"云硬盘迁移成功",
                "scheduler.retype":"云硬盘迁移失败",
                "network.create.end": "网络创建成功",
                "network.delete.end": "网络删除成功",
                "network.update.end": "网络编辑成功",
                "subnet.create.start": "子网删除中",
                "subnet.create.end": "子网创建成功",
                "subnet.delete.start": "子网删除中",
                "subnet.delete.end": "子网删除成功",
                "subnet.update.end": "子网编辑成功",
                "router.create.start": "路由器创建中",
                "router.create.end": "路由器创建成功",
                "router.delete.start": "路由器删除中",
                "router.delete.end": "路由器删除成功",
                "router.update.end": "路由器更新成功",
                "router.interface.create": "路由器关联子网成功",
                "router.interface.delete": "路由器解除关联子网成功",
                "port.update.start": "端口配置更新中",
                "port.update.end": "端口配置更新成功",
                "floatingip.create.start": "申请公网IP中",
                "floatingip.create.end": "申请公网IP成功",
                "floatingip.delete.start": "删除公网IP中",
                "floatingip.delete.end": "删除公网IP成功",
                "floatingip.association.start": "绑定公网IP中",
                "floatingip.association.end": "绑定公网IP成功",
                "floatingip.disassociation.start": "解绑公网IP中",
                "floatingip.disassociation.end": "解绑公网IP成功",
                "floatingip.update.end": "公网IP更新成功",
                "port.create.end": "添加网卡成功",
                "port.delete.end": "移除网卡成功",
                "fixedip.update.end": "私网IP修改成功",
                "keypair.create.end": "密钥对创建成功",
                "keypair.delete.end": "密钥对删除成功",
                "keypair.import.end": "密钥对导入成功",
                "security_group.create.end": "安全组创建成功",
                "security_group.update.end": "安全组编辑成功",
                "security_group.delete.end": "安全组删除成功",
                "security_group_rule.create.end": "安全组规则创建成功",
                "security_group_rule.delete.end": "安全组规则删除成功",
                "aggregate.create.end": "云主机集合创建成功",
                "aggregate.updateprop.end": "云主机集合编辑成功",
                "aggregate.updatemetadata.end": "云主机集合设置元数据成功",
                "aggregate.addhost.end": "管理主机操作成功",
                "aggregate.removehost.end": "管理主机操作成功",
                "aggregate.delete.end": "云主机集合删除成功",
                "scheduler.create_volume": "云硬盘准备创建",
                "compute.instance.mkimg.start": "制作镜像中",
                "compute.instance.mkimg.end": "制作镜像成功",
                "compute.instance.backup.start": "备份中",
                "compute.instance.backup.end": "备份成功",
                "firewall_rule.update.end": "防火墙规则修改成功",
                "firewall_rule.create.end": "防火墙规则创建成功",
                "firewall_rule.delete.end": "防火墙规则删除成功",
                "firewall_policy.create.end": "防护墙策略创建成功",
                "firewall_policy.update.end": "防火墙策略编辑成功",
                "firewall_policy.delete.end": "防火墙策略删除成功",
                "firewall.create.end": "防火墙创建成功",
                "firewall.update.end": "防火墙编辑成功",
                "firewall.delete.end": "防火墙删除成功",

                "loadbalancer.delete.end": "删除负载均衡成功",
                "loadbalancer.create.end": "创建负载均衡成功",
                "loadbalancer.update.end": "编辑负载均衡成功",
                "listener.create.end": "创建监听器成功",
                "listener.update.end": "编辑监听器成功",
                "listener.delete.end": "删除监听器成功",
                "pool.create.end": "创建资源池成功",
                "pool.update.end": "编辑资源池成功",
                "pool.delete.end": "删除资源池成功",
                "member.create.end": "创建资源池成员成功",
                "member.update.end": "编辑资源池成员成功",
                "member.delete.end": "删除资源池成员成功",
                "healthmonitor.create.end": "创建负载均衡检查器成功",
                "healthmonitor.update.end": "编辑负载均衡检查器成功",
                "healthmonitor.delete.end": "删除负载均衡检查器成功",

                "loadbalancer.create.start": "loadbalancer 创建中",
                "listener.create.start": "监听器创建中",
                "listener.update.start": "监听器编辑中",
                "healthmonitor.delete.start":"监听器删除中",

                "pool.create.start": "pool 创建中",
                "member.create.start": "member 创建中",
                "member.delete.start": "member 删除中",
                "pool.delete.start": "pool 删除中",
                "listener.delete.start": "listener 删除中",
                "loadbalancer.delete.start": "loadbalancer 删除中",
                "backup.delete.start":"备份删除中",
                "backup.delete.end":"备份删除成功",
                "backup.create.start":"备份创建中",
                "backup.create.end":"备份创建成功",
                "backup.restore.start":"备份正在恢复到云硬盘中",
                "backup.restore.end":"备份恢复到云硬盘成功",
                "backup.reset_status.start":"备份重置状态中",
                "backup.reset_status.end":"备份重置状态完成",
                "update_nova_success":"更新nova配置成功",
                "update_cinder_success":"更新cinder配置成功",
                "update_computeha_success":"更新高可用配置成功",
                "update_ups_success":"更新UPS配置成功",
                "update_platform_success":"更新平台规模配置成功",
                "update_nova_fail":"更新nova配置失败",
                "update_cinder_fail":"更新cinder配置失败",
                "update_computeha_fail":"更新高可用配置失败",
                "update_ups_fail":"更新UPS配置成功",
                "update_platform_fail":"更新平台规模配置失败",
                "update_flatten_ing":"克隆链优化配置下发中",
                "update_flatten_success":"克隆链优化配置下发成功",
                "update_flatten_failed":"克隆链优化配置下发失败",
                "clone.server.volume.create.start":"克隆云硬盘中",
                "clone.server.volume.create.end":"克隆云硬盘成功",
                "clone.server.volume.create.error":"克隆云硬盘失败",
                "clone.server.compute.instance.create.start":"克隆云主机中",
                "clone.server.compute.instance.create.end":"克隆云主机成功",
                "clone.server.compute.instance.create.error":"克隆云主机失败",
                "clone.server.compute.instance.delete.start":"回滚删除克隆云主机中",
                "clone.server.compute.instance.delete.end":"回滚删除克隆云主机成功",
                "clone.server.compute.instance.delete.error":"回滚删除克隆云主机失败",
                "clone.server.compute.instance.force_delete.start":"回滚删除克隆云主机中",
                "clone.server.compute.instance.force_delete.end":"回滚删除克隆云主机成功",
                "clone.server.compute.instance.force_delete.error":"回滚删除克隆云主机失败",
                "clone.server.compute_task.build_instances":"创建失败，资源不足请重新选择云主机规格",
                "attach_volume":"盘加载失败",
                "compute.instance.volume.attach.error":"云主机挂载本地磁盘失败",
                "compute.instance.volume.attach.localDisk":"云主机挂载本地磁盘成功",
                "compute.instance.volume.detach.localDisk":"云主机卸载本地磁盘成功",
                "compute.instance.volume.attach.error.localDisk":"云主机挂载本地磁盘失败",
                "compute.instance.volume.detach.error.localDisk":"云主机卸载本地磁盘失败",
                "compute.instance.shelve_offload.start":"云主机搁置中",
                "compute.instance.unshelve.start":"云主机取消搁置中",
                "compute.instance.shelve_offload.end":"云主机搁置成功",
                "compute.instance.unshelve.end":"云主机取消搁置成功",
                "compute.instance.unshelve.error":"资源不足，取消搁置失败"
            }
        },
        "loadbalancers": {
            "ui": {
                "selectAgreement":"请选择监听协议",
                "selectArithmetic":"请选择算法",
                "newLb": "新建负载均衡",
                "editLb": "编辑负载均衡",
                "lbDetail": "负载均衡详情",
                "lbDelMsg": "确定删除所选负载均衡？",
                "newListener": "新建监听器",
                "editListener": "编辑监听器",
                "listenerDetail": "监听器详情",
                "newPool": "新建资源池",
                "editPool": "编辑资源池",
                "poolDetail": "资源池详情",
                "newMember": "新建成员",
                "editMember": "编辑成员",
                "memberDetail": "成员详情",
                "operating_status": "操作状态",
                "provisioning_status": "配置状态",
                "table": {
                    "ONLINE": "在线",
                    "OFFLINE": "不在线",
                    "ACTIVE": "可用",
                    "ERROR": "错误",
                    "PENDING_UPDATE": "正在更新",
                    "PENDING_CREATE": "正在创建",
                    "INACTIVE": "不可用",
                    "true": "是",
                    "false": "否"
                }
            },
            "lbAlgorithm": {
                "ROUND_ROBIN": "轮询算法",
                "LEAST_CONNECTIONS": "最少连接算法",
                "SOURCE_IP": "源IP"
            },
            "lb": {
                "name": "名称",
                "name_placeholder": "请输入名称",
                "description": "描述信息",
                "description_placeholder": "请输入描述信息",
                "vip_address": "虚拟地址",
                "Vlan": "子网",
                "Vlan_placeholder": "请选择子网",
                "admin_state_up": "管理员权限",
                "overview": "概述",
                "subnet": "子网区间",
                "emptyOrAddrField": "为空，或者相应的地址段",
                "provisioning_status": "配置状态",
                "tenant_id": "租户ID",
                "provider": "提供者"
            },
            "ls": {
                "listener": "监听器",
                "back": "返回",
                "name": "名称",
                "name_placeholder": "请输入名称",
                "description": "描述信息",
                "description_placeholder": "请输入描述信息",
                "protocol": "协议类型",
                "protocol_placeholder": "请选择协议类型",
                "port_placeholder": "请输入端口",
                "connection_limit_ph": "请输入链接限制",
                "admin_state_up": "管理员权限",
                "lsDelMsg": "确定删除所选监听器？",
                "connection_limit": "连接限制",
                "protocol_port": "协议端口",
                "delListenerTip": "已被资源池绑定的监听器不能删除"
            },
            "pl": {
                "ipRangeCheck": "ip不在负载均衡子网范围内",
                "pool": "资源池",
                "back": "返回",
                "name": "名称",
                "name_placeholder": "请输入名称",
                "description": "描述信息",
                "description_placeholder": "请输入描述信息",
                "protocol": "协议类型",
                "listener": "监听器",
                "listener_placeholder": "请选择监听器",
                "protocol_placeholder": "请选择协议",
                "admin_state_up": "管理员权限",
                "plDelMsg": "确定删除所选资源池？",
                "overview": "概述",
                "lbAlgorithm": "均衡算法",
                "lbAlgorithm_placeholder": "请选择均衡算法"
            },

            "mb": {
                "controlMonitor":"监控器",
                "member": "成员",
                "back": "返回",
                "name": "名称",
                "name_placeholder": "请输入名称",
                "description": "描述信息",
                "description_placeholder": "请输入描述信息",
                "address": "IP地址",
                "address_placeholder": "请输入IP地址",
                "protocol_port": "端口",
                "protocol_port_placeholder": "请输入端口",
                "admin_state_up": "管理员权限",
                "subnet": "子网",
                "subnet_placeholder": "请选择子网",
                "weight": "权重",
                "weight_placeholder": "请输入权重",
                "mbDelMsg": "确定删除所选成员？"
            }
        },
        "h_monitor": {
            "monitor": "检查器",
            "name": "名称",
            "name_placeholder": "名称",
            "type": "类型",
            "delay": "延迟(秒)",
            "delay_placeholder": "延迟(秒)",
            "timeout": "超时(秒)",
            "timeout_placeholder": "超时(秒)",
            "max_retries": "最大重试次数",
            "max_retries_placeholder": "请输入最大重试次数",
            "mnDelMsg": "确定删除所选检查器？",
            "newtitle": "新建检查器",
            "edittitle": "编辑检查器",
            "monitor_detail": "检查器详情",
            "expected_codes": "HTTP状态码",
            "codes_placeholder": "请输入http状态码",
            "url_placeholder": "请输入url地址"
        },
        "volumes": {
            "curVolume":"当前云硬盘",
            "multiattach": "共享卷",
            "gigQuota": "硬盘配额",
            "volumesQuota": "云硬盘个数配额",
            "snapQuota": "快照配额",
            "snapshotNum": "快照数量",
            "volume": "云硬盘",
            "undefined": "无",
            "volumesManage": "云硬盘管理",
            "attachVolume": "加载云硬盘",
            "unstallVolume": "卸载云硬盘",
            "createSnapshot": "创建快照",
            "extendSize": "扩容",
            "onlyRead": "设置只读",
            "readAndWrite": "设置读写",
            "forceDelete": "强制删除",
            "stockTransfer":"云硬盘迁移",
            "backup":"备份",
            "volumeName": "云硬盘名称",
            "snapname": "快照名称",
            "createtime": "创建时间",
            "projectName": "项目名称",
            "associatedHost": "关联主机",
            "config": "配置(GB)",
            "status": "状态",
            "describe":"描述",
            "hostName": "云主机名称",
            "snapshot": "快照",
            "sharedVolume": "共享卷",
            "type": "类型",
            "voltype": "存储设备",
            "stpool": "存储池",
            "stfeature": "存储特性",
            "description": "云硬盘描述",
            "unsetting": "未设置",
            "detail": "云硬盘详情",
            "created": "创建时间",
            "zone": "可用域",
            "metaData": "元数据",
            "dataDisk": "数据盘",
            "systemDisk": "系统盘",
            "cacheDisk": "镜像缓存盘",
            "imageName": "镜像名称",
            "bootable":"可启动",
            "fromBackup":"来自备份恢复",
            "allocated":"已分配",
            "capacity":"存储池容量（TB）",
            "errorMessage": {
                "atLeastSize": "不能小于原来云硬盘的大小",
                "cantCreate": "硬盘剩余容量不足，不能新建云硬盘",
                "cantExpandDep": "部门硬盘配额数不足",
                "cantExpandPro": "项目硬盘配额数不足",
                "depSnapGig": "部门硬盘配额数不足",
                "proSanpGig": "项目硬盘配额数不足",
                "depSnapNum": "部门快照配额数不足",
                "proSnapNum": "项目快照配额数不足",
                "cantCVolume": "硬盘剩余容量不足",
                "snapshotDep": "部门硬盘配额数不足",
                "snapshotPro": "项目硬盘配额数不足",
                "moreThanDepGig": "部门硬盘配额数不足",
                "moreThanProGig": "项目硬盘配额数不足",
                "moreThanDepVolumes": "部门云硬盘个数配额不足",
                "moreThanProVolumes": "项目云硬盘个数配额不足",
                "proBackupNum":"项目备份配额数不足",
                "depBackupNum":"部门备份配额数不足",
                "proBackupGig":"项目备份容量配额数不足",
                "depBackupGig":"部门备份容量配额数不足"
            },
            "placeholder":{
                "volume":"请选择云硬盘",
                "noVolume":"没有可选择的云硬盘",
                "voltype":"请选择存储设备",
                "volumeSig":"请选择一个云硬盘"
            },
            "tip":{
                "tip1":"请选择可用状态且可启动的云硬盘",
                "tip2":"请选择可用状态或已使用状态的云硬盘",
                "tip3":"云硬盘存在定时备份任务时无法新建备份",
                "tip4":"iso格式云硬盘不支持此操作",
                "tip5":"该云硬盘所在存储不支持此操作",
                "tip6":"请选择可用或已使用状态的云硬盘",
                "tip7":"双活特性的云硬盘不支持此操作",
                "tip8":"云硬盘存在定时快照任务时无法新建快照",
                "tip9":"请选择已使用状态的云硬盘",
                "tip10":"请选择一个云硬盘",
                "tip11": "该云硬盘存在多点挂载，请卸载至非共享状态再进行操作",
                "tip12":"请关机虚拟机再进行迁移",
                "tip13":"请取消挂载再进行迁移",
                "tip14":"请选择迁移中的云硬盘",
                "tip15":"不支持已使用状态的nfs卷备份，请卸载云硬盘后备份",
                "tip16":"所选云硬盘状态（加载中，已使用，卸载中，迁移中，备份中）下不允许删除",
                "tip17":"所选云硬盘下存在快照，不允许删除",
                "tip18":"云硬盘下存在快照，无法进行云硬盘迁移",
                "tip19":"云硬盘正在迁移中，无法操作",
                "tip20":"该存储状态不支持扩容",
                "tip21":"存储协议为lvm且已创建快照的云硬盘不支持扩容",
                "noAvailableStorage":"没有可迁移的存储设备，无法进行操作",
                "hasBackupJobNotDel":"所选云硬盘下存在备份任务，请先解除再进行删除",
                "hasBackupJobNotExtend":"所选云硬盘下存在备份任务，请先解除再进行扩容"
            },
            "cv": {
                "name": "名称",
                "describe":"描述",
                "pInputName": "请输入云硬盘名称",
                "config": "配置（GB）",
                "pInputSize": "请输入云硬盘大小",
                "pInputNum": "请输入非零正整数",
                "descMessage": "描述信息",
                "pDescMessage": "请输入描述信息",
                "newVolume": "新建云硬盘",
                "updateVolume": "编辑云硬盘",
                "defaultSize": "若为空，则为原来云硬盘的大小",
                "nomore_volumetype": "未找到匹配的卷类型",
                "storagetype": "存储方式",
                "storagedevice": "存储设备",
                "pool": "资源池",
                "storagePool": "存储池",
                "cephpool": "标准池",
                "ceph_storage": "分布式存储",
                "Centralized_storage": "集中式存储",
                "pool_capacity": "存储池容量",
                "centralizedtip": "暂不支持集中式存储",
                "storagefeatures": "存储特性",
                "compression": "压缩",
                "rsize": "精简配置",
                "easytier": "分层",
                "hyperswap": "双活"
            },
            "dv": {
                "deleteVolume": "删除云硬盘",
                "forceDelete": "强制删除云硬盘",
                "confirmDelete": "确定删除云硬盘吗?",
                "confirmForce": "确定强制删除云硬盘吗?",
                "confirmResetStatus":"迁移中的云硬盘可以执行重置状态操作，把迁移状态修改为正常状态（可以或已使用，和迁移前的状态相同），但执行这个操作时，需要确认当前云硬盘的迁移操作已经中断（即实际的迁移操作已经失败，但云硬盘状态没有恢复），否则会导致当前的迁移操作失败。"
            },
            "lv": {
                "chooseHost": "选择一台云主机",
                "loadVolume": "加载云硬盘",
                "selectServer": "选择云主机",
                "pleaseSelectServer": "请选择一台云主机",
                "selectDriver": "选择磁盘驱动",
                "pleaseSelectDriver": "请选择磁盘驱动"
            },
            "unv": {
                "unstallVolume": "卸载云硬盘",
                "confirmUnstall": "选择所要卸载的云主机"
            },
            "ev": {
                "extendVolume": "扩容云硬盘",
                "size": "扩容后大小(GB)"
            },
            "cs": {
                "createSnapshot": "创建快照",
                "pInputName": "请输入快照名称",
                "prompt_message": "该快照已经启动了云硬盘，请先删除相关的云硬盘。"
            },
            "table": {
                "status": {
                    "all": "全部",
                    "available": "可用",
                    "inavailable": "不可用",
                    "creating": "创建中",
                    "deleting": "删除中",
                    "attaching": "加载中",
                    "detaching": "卸载中",
                    "in-use": "已使用",
                    "error": "错误",
                    "unrecognized": "不可识别",
                    "extending": "扩容中",
                    "error-deleting": "删除错误",
                    "downloading": "下载中",
                    "uploading": "上传中",
                    "retyping":"迁移中",
                    "backing-up":"备份中",
                    "restoring-backup":"备份恢复中",
                    "error-restoring":"恢复错误",
                    "rollbacking":"回滚中",
                    "cloning":"克隆中"
                },
                "multiattach": {
                    "true": "是",
                    "false": "否"
                },
                "bootable":{
                    "true":"是",
                    "false":"否"
                }
            },
            "dataDiskName":"数据盘名称",
            "dataDiskDescription":"数据盘描述",
            "systemDiskName":"系统盘名称",
            "systemDiskDescription":"系统盘描述",
            "mirrorCacheDiskName":"镜像缓存盘名称",
            "mirrorCacheDiskDescription":"镜像缓存盘描述"
        },
        "volumesQoS":{
            "name":"名称",
            "associatedStorage":"关联存储池",
            "rescissionAssociation":"解除关联",
            "QoSName":"QoS名称",
            "QoSNum":"QoS值",
            "relateVolumeEquip":"关联存储池",
            "associatedStorageTitle":"关联存储设备",
            "hasAssociatedStorage":"关联存储池",
            "delAssociatedStorage":"是否删除选中的备份？",
            "read_bytes_sec":"读吞吐量(MB/s)",
            "write_bytes_sec":"写吞吐量(MB/s)",
            "read_iops_sec":"读IOPS(个)",
            "write_iops_sec":"写IOPS(个)",
                "delVolumesQoS":"确认删除选中的QoS？",
                "max-read_bytes_sec":"最大读吞吐量(MB/s)",
                "max-write_bytes_sec":"最大写吞吐量(MB/s)",
                "max-read_iops_sec":"最大读IOPS(个)",
                "max-write_iops_sec":"最大写IOPS(个)",
                "placeholder":{
                    "choiceStorageDevice":"请选择需要对接的存储池",
                    "noStorageDevice":"没有可对接的存储设备",
                    "input_volumesQoSName":"请输入新建QoS的名称",
                    "input_read_bytes_sec":"请输入该QoS的读吞吐量（0为不限制）",
                    "input_write_bytes_sec":"请输入该QoS的写吞吐量（0为不限制）",
                    "input_read_iops_sec":"请输入该QoS的读IOPS个数（0为不限制）",
                    "input_write_iops_sec":"请输入该QoS的写IOPS个数（0为不限制）",
                    "choice_relative_volumes":"请选择需要关联的存储池",
                    "no_relative_volumes":"没有可关联的存储池",
                    "choice_relieve_volumes":"请选择需要解除关联的存储池",
                    "no_relieve_volumes":"没有可关联的存储池",
                    "mustOne":"必须输入一项",
                }
        },
        "localDisk":{
            "localDiskName":"本地磁盘名称",
            "config":"配置(GB)",
            "status":"状态",
            "location":"所在位置",
            "start":"可启动",
            "remark":"描述",
            "size":"大小(GB)",
            "table": {
                "status": {
                    "all": "全部",
                    "available": "可用",
                    "attaching":"挂载中",
                    "attached": "已挂载",
                    "detaching":"卸载中",
                    "error": "错误",
                    "in-use": "已使用"
                }
            },
            "name":"名称",
            "remark":"描述",
            "node":"选择节点",
            "placeholder":{
                "input_name":"请输入名称",
                "input_remark":"请输入描述",
                "select_node":"请选择节点",
                "input_size":"请输入大小"
            },
            "tipMsg":{
                "deleteLocalDisk":"确定要删除本地磁盘吗?",
                "joinLocalDisk":"确定要加入本地磁盘吗?",
                "removeLocalDisk":"确定要移除本地磁盘吗?"
            },
            "diskName":"硬盘名",
            "diskType":"硬盘类型",
            "diskCapacity":"容量(GB)",
            "cloudHostName":"云主机名称",
            "cloudHostStatus":"状态",
            "image":"镜像",
            "nodeName":"物理主机",
            "detail":"本地磁盘详情",
            "locationNode":"所在节点",
            "physicalDisk":"物理磁盘",
            "cloudHostName":"云主机名称",
            "createTime":"创建时间",
            "joinLocalDisk":"加入本地磁盘",
            "removeLocalDisk":"移除本地磁盘",
            "surplus":"剩余",
            "open":"开",
            "close":"关",
            "openHightUse":"强制开启高可用",
            "openHightUseTitle":"如果强制开启，虚拟机迁移后可能出现问题，请谨慎操作",
            "uninstallTip":"本地磁盘在使用过程中执行卸载操作会存在风险，请先在该本地磁盘挂载的云主机的操作系统中完成卸载，再执行本操作。",
            "loadingTip1":"1.加载本地磁盘的虚拟机不支持迁移操作",
            "loadingTip2":"2.加载本地磁盘的虚拟机不支持节点故障疏散",
            "sizeTip": "请输入1~{{size}}的整数",
            "checkNotEmptyTip": "请选择硬盘",
            "checkNotEmptyLocalDiskTip": "请选择本地磁盘",
            "checkNotEmptyVmTip":"至少选择一个云主机",
            "totalSize":"总容量",
            "useSize":"已分配容量",
            "systemManage":"系统管理",
            "configManage":"配置管理",
            "operateManage":"运维管理",
            "storageManage":"存储管理",
            "localDiskService":"对接本地磁盘资源池后再使用此模块",
            "localDiskJoinSuccess":"加入本地磁盘成功",
            "localDiskJoinInitIng":"本地磁盘对接加入中",
            "localDiskJoinFail":"加入本地磁盘失败"
        },
        "transmissionMag":{
            "downcertificate":"下载证书",
            "certificateUse":"证书只在fuse协议中可使用",
            "protocol":"协议",
            "tMload":"加载",
            "tMloadMsg":"加载信息",
            "uninstall":"卸载",
            "instanceName":"云主机名称",
            "mainName":"主机名",
            "equipmentType":"设备类型",
            "status":"状态",
            "equipmentLog":"设备标识",
            "residualCapacity":"剩余容量",
            "totalCapacity":"总容量",
            "nfsAddr":"NFS服务器地址",
            "nfsPath":"路径",
            "createTime":"创建时间",
            "equipmentDesc":"设备描述",
            "confirm-uninstall-equipment":"您确定要卸载该透传设备吗？",
            "datails":"详情",
            "name":"名称",
            "hostName":"物理主机名",
            "loadTime":"加载时间",
            "belong_user":"所属用户名称",
            "belong_project":"所属项目名称",
            "belong_department":"所属部门名称",
            "hasLoad":"已加载",
            "unLoad":"未加载",
        },
        "labelManagement":{
            "inputLabel":"请输入标签",
            "input127chart":"可输入127个字符",
            "labelNohttp":"标签名称不能包含http://和https://",
            "noSpecialCharacter":"仅支持英文字母、数字或汉字， +、-、=、.、_、:、/、@、()、[]、（）、【】",
            "labelrepetition":"标签重复",
            "updateLabel":"修改标签",
            "name":"名称",
            "label":"标签",
            "nolabelrepetition":"请不要选择重复的标签",
            "selected":"已选择标签",
            "bindObj":"绑定对象",
            "resourcesNum":"绑定资源数",
            "creater":"创建者",
            "createTime":"创建时间",
            "createLabel":"新建标签",
            "editLabel":"编辑标签",
            "lablename":"标签名称",
            "labelDescribe":"标签描述",
            "color":"颜色",
            "desc":"描述",
            "UnbundLabel":"解绑标签",
            "resourcesType":"资源类型",
            "resourcesId":"资源ID",
            "confirmDelLable":"您确定要删除该标签吗？"
        },
        "extension": {
            "ui": {
                "newScaleCluster": "新建弹性扩展",
                "scaleClusterName": "集群名称",
                "currentSize": "当前大小",
                "scaleClusterNameInput": "新建弹性扩展集群的名称",
                "currentSizeInput": "集群初始化的云主机个数，限定为100以内的正整数",
                "maxSizeInput": "集群的云主机最大个数，限定为100以内的不小于初始值的正整数",
                "minSizeInput": "集群的云主机最小个数，限定为不大于初始值的正整数",
                "clusterStatus": "状态",
                "description": "描述信息",
                "createTime": "创建时间",
                "updateTime": "最近更新时间",
                "AlarmTemplate_placeholder": "请选择告警模板",
                "Scalpolicy_placeholder": "请选择告警策略",
                "basicInformation": "基础设置",
                "serverInformation": "云主机设置",
                "loadBalanceInformation": "负载均衡",
                "scaleRuleInformation": "扩展规则",
                "extensionName":"弹性扩展名称",
                "imageName":"镜像名称",
                "networks":"网络",
                "configureType":"配置类型",
                "keypaireName":"密钥对名称",
                "instancesName":"云主机名称",
                "password":"密码",
                "timingStart":"定时启动",
                "canNotCreateExtension":"当前不支持本地盘创建云主机，故不可新建弹性伸缩",
            },
            "detail": {
                "cpu.usage_system": "CPU使用率",
                "mem.used_percent": "内存使用率",
                "disk.used_percent": "磁盘分区使用率",
                "swap.used_percent": "交换空间使用率",
                "net.bytes_recv": "网络流入速率",
                "net.bytes_sent": "网络流出速率",
                "mean": "平均值",
                "max": "最大值",
                "min": "最小值",
                "eq":"等于",
                "ge": "大于等于",
                "le": "小于等于",
                "gt": "大于",
                "lt": "小于",
                "ScaleClusterDetail": "弹性扩展详情",
                "basicInformation": "基础信息",
                "scaleClusterName": "集群名称",
                "currentSize": "当前大小",
                "initialSize": "初始大小",
                "status": "状态",
                "maxSize": "最大值",
                "minSize": "最小值",
                "createTime": "创建时间",
                "updateTime": "最近更新时间",
                "serverInfor": "云主机信息",
                "LoadBalanceInfor": "负载均衡",
                "ScaleRuleInfor": "扩展规则",
                "ScaleRecord": "扩展记录",
                "ScaleRecordAlarmEventID": "告警事件ID"
            },
            "status": {
                "active": "正常",
                "normal": "正常",
                "success": "成功",
                "delete-server-failed": "删除失败",
                "creating": "创建中",
                "create-failed": "创建失败",
                "deleting": "删除中",
                "delete-failed": "删除失败",
                "scaling": "扩展中",
                "scale-failed": "扩展失败",
                "failed":"失败"
            },
            "tipMsg": {
                "delScaleCluster": "确定删除所选弹性扩展吗？",
                "instancelimit": "弹性扩展集群云主机个数不受部门配额和部门云主机个数限定",
                "addInstances":"添加云主机",
                "alertInfo":"向弹性扩展中添加云主机，云主机网络和镜像及规格由创建弹性扩展的初始网络、镜像和规格决定。",
                "hourMsg":"00~23中的数",
                "minuteMsg":"00~59中的数",
                "seconsMsg":"00~59中的数",
                "extensionNamePattern":"只能输入字母、数字、横线以及下划线",
                "insNamePattern":"只能输入数字、字母或横线，且只能以字母开头"
            },
            "placeholder":{
               "extensionName":"请输入弹性扩展名称",
               "insName":"请输入云主机名称",
               "password":"请输入密码",
               "imageName":"请选择镜像",
               "networks":"请选择网络",
               "configureType":"请选择配置类型",
               "keypaireName":"请选择密钥对",
               "noKeypaire":"暂时无可用的密钥对",
               "date":"选择日期",
               "hour":"时",
               "minute":"分",
               "second":"秒",
            },
            "info":{
                "extensionName":"提示信息",
                "instancesName":"创建的云主机名称。",
                "password":"登录云主机操作系统的密码。",
                "imageName":"创建云主机使用的镜像。",
                "networks":"弹性集群使用的网络。",
                "configureType":"云主机的规格。",
                "keypaireName":"创建云主机使用的密钥对。如果选择对应的密钥对名称即可实现通过密钥方式登录云主机。也可不使用密钥对登录云主机。",
                'initialSize':"集群初始化后云主机的个数,弹性扩展集群创建完成后会自动创建相应数量的云主机。",
                'maxSize':"弹性扩展集群中云主机允许的最大数量。",
                'minSize':"弹性扩展集群中云主机允许的最小数量。",
                'instanceName':"弹性扩展集群初始化后创建的云主机名称。",
                'passwd':"登录云主机操作系统的密码。",
                'imgName':"创建云主机使用的镜像。",
                'network':"弹性集群使用的网络。",
                'conf_option':"弹性扩展创建的云主机的规格。",
                'keypair':"创建云主机使用的密钥对。如果选择对应的密钥对名称即可实现通过密钥方式登录云主机。也可不使用密钥对登录云主机。",
                'Vlan':"弹性集群关联的子网。",
                'protocol':"负载均衡器对外的服务协议类型。",
                'protocol_port':"负载均衡器对外的服务端口。",
                'lbAlgorithm':"支持轮询算法、最少连接算法、源IP三种算法。",
                'Monitoring_Alarm_AlarmTemplate':"当告警模板里监控规则达到阈值时，系统触发弹性扩展。",
                'Cvm_Scalpolicy':"弹性集群使用的扩展策略。"
            }
        },
        "scalePolicy": {
            "ui": {
                "HORIZONTAL_ONLY": "横向扩展",
                "newScalePolicy": "新建扩展策略",
                "policyName": "策略名称",
                "policyNameInput": "请输入扩展策略名称",
                "scaleSteps": "扩展步长",
                "scaleStepsInput": "请输入每次扩展的云主机个数,如需缩容请输入负数",
                "scaleStepsPatternInput": "请输入非零整数",
                "scaleType": "扩展类型",
                "scaleTypeChoice": "请选择扩展类型",
                "intervalTime": "间隔时间",
                "intervalTimePerSecond": "间隔时间(s)",
                "intervalTimeInput": "请输入扩展间隔周期",
                "scaleTypeIncrease": "增加",
                "scaleTypeDecrease": "减少"

            },
            "tipMsg": {
                "delScalePolicy": "确定删除所选策略吗？"
            },
            "info":{
                'scaleSteps':"触发弹性扩展时，每次增加/减少云主机的个数。减少的云主机数填写为负数，如“-1”表示减少一个云主机。",
                'scaleType':"目前仅支持横向扩展。",
                'intervalTimePerSecond':"两次弹性扩展操作的时间间隔。在此期间不允许进行任何操作。",
            }
        },
        "snapshots": {
            "chooseSnap":"选择云硬盘",
            "curSnapshot":"所选快照",
            "snapshotName": "快照名称",
            "snapshotManage": "快照管理",
            "deleteSnapshot": "删除快照",
            "confirmDelete": "确定删除所选快照吗?",
            "startVolume": "创建云硬盘",
            "snapshots_startVolume": "快照创建云硬盘",
            "compile":"编辑",
            "name":"名称",
            "size": "大小(GB)",
            "detailSnapshot": "快照详情",
            "snapshotInfo":"快照信息",
            "description": "快照盘描述",
            "inputname":"请输入名称",
            "inputdescribe":"请输入描述",
            "created_at":"创建时间",
            "table": {
                "status": {
                    "available": "可用",
                    "AVAILABLE": "可用",
                    "inavailable": "不可用",
                    "INAVAILABLE": "不可用",
                    "creating": "创建中",
                    "CREATING": "创建中",
                    "deleting": "删除中",
                    "DELETING": "删除中",
                    "attaching": "加载中",
                    "ATTACHING": "加载中",
                    "detaching": "卸载中",
                    "DETACHING": "卸载中",
                    "in-use": "已使用",
                    "IN-USE": "已使用",
                    "error": "错误",
                    "ERROR": "错误",
                    "error-deleting": "删除错误",
                    "ERROR-DELETING": "删除错误",
                }
            },
            "tip":{
                "tip1":"该快照所在存储不支持此操作",
                "tip2":"请选择可用状态的快照",
                "tip3":"请选择一个快照",
            }
        },
        "permit":{
            "domain":"部门",
            "project":"项目",
            "instance":"云主机",
            "instanceNum":"云主机数",
            "cpuNum":"CPU核数",
            "cpuCore":"CPU核（个）",
            "memb":"内存（GB）",
            "mem":"内存",
            "externalIp":"公网IP（个）",
            "projectList":"项目列表",
            "user":"用户",
            "domainDetail":"部门资源使用详情",
            "totalQuota":"(已使用量/配额总量)",
            "domainUser":"部门用户",
            "projectUser":"项目用户"
        },
        "users": {
            "pageTitle": "用户管理",
            "adduser": "新建用户",
            "edituser": "编辑用户",
            "allocatePrivilege": "分配权限",
            "changePass": "修改密码",
            "allocateOrg": "分配组织",
            "userName": "登录名称",
            "role": "角色",
            "baseRole":"基础角色",
            "name": "显示名称",
            "phone": "手机",
            "email": "Email",
            "status": "状态",
            "createTime": "创建时间",
            "operate": "操作",
            "domainName": "所属部门",
            "active": "激活",
            "locked": "锁定",
            "addUserItem":{
                "slectRole":"选择角色",
                "selectdp":"选择部门/选择项目",
                "eInformation":"基本信息",
                "selectdomain":"选择部门",
                "addDomain":"新建部门",
                "selectProject":"选择项目",
                "noProject":"暂无项目，请新建项目",
                "addProject":"新建项目"
            },
            "roles": {
                "1": "Admin",
                "2": "超级管理员",
                "3": "部门管理员",
                "4": "项目管理员",
                "5": "普通用户",
                "8": "系统保密员",
                "9": "系统审计员",
                "menuText":"菜单权限",
                "menuTips":"操作按钮权限",
                "10":"全部"
            },
            "cu": {
                "name": "显示名称",
                "org": "组织",
                "privilege": "权限",
                "department": "部门",
                "project": "项目",
                "role": "角色",
                "username": "登录名称",
                "rolename":"角色名称",
                "password": "密码",
                "confirmPwd": "确认密码",
                "roles": {
                    "admin": "超级管理员",
                    "domain_admin": "部门管理员",
                    "project_admin": "项目管理员",
                    "member": "普通用户",
                    "guest":"普通用户"
                },
                "passwordrank_one":"密码请输入数字（0-9）、字母（A-Z）（a-z）或特殊字符（!@._:,/-^%）中的一种。",
                "passwordrank_two":"密码必须包括大写字母（A-Z）、小写字母（a-z）、数字（0-9）、特殊字符（!@._:,/-^%）三种字符中的两种。",
                "passwordrank_three":"密码必须包括大写字母（A-Z）、小写字母（a-z）、数字（0-9）、特殊字符（!@._:,/-^%）四种字符。",
            },
            "tip": {
                "notDelTip": "不能删除同级角色用户",
                "allocate_admin": "不能为admin用户分配权限",
                "edit_admin": "不能编辑admin用户",
                "edit_admin_pass": "不能修改admin用户的密码",
                "allocate_user": "不能为同级角色用户分配权限",
                "edit_user": "不能编辑同级角色用户",
                "edit_user_pass": "不能修改同级角色用户的密码"
            },
            "placeholder": {
                "name": "请输入显示名称",
                "org": "请选择组织",
                "privilege": "请选择权限",
                "department": "请选择部门",
                "project": "请选择项目",
                "role": "请选择角色",
                "username": "请输入登录名称",
                "password": "请输入密码",
                "confirmPwd": "请再次输入密码",
                "phone": "请输入手机号码",
                "email": "请输入电子邮箱"
            },
            "un_allocatePrivilege": "部门权限",
            "have_allocated": "已分配权限",
            "delMsg": "您确定删除该用户吗？",
            "canNotDel": "admin账户不允许删除！",
            "canNotEdit": "admin账户不可编辑！",
            "oneUser_oneRole": "一个用户只能分配一个权限",
            "bindRoleBtn":"绑定角色",
            "untieRoleBtn":"解绑角色",
            "ad":{
                "name":"员工姓名",
                "loginName":"登录名",
                "role":"角色",
                "position":"职位",
                "description":"描述",
                "createTime":"创建日期",
                "lastLogin":"上次登录",
                "lastModifyPwd":"上次修改密码",
                "enabled":"账户禁用",
                "true":"是",
                "false":"否",
                "bindADRole":"绑定角色",
                "selectRole":"选择角色",
                "selectDomain":"选择部门",
                "selectProject":"选择项目",
                "managementRole":"绑定角色",
                "bind":"已绑定",
                "unbind":"未绑定",
                "untieConfirm":"确定解绑吗?"
            },
            "adBindRoleTip":"含有绑定角色的用户,不可进行操作",
            "adUntieRoleTip":"含有未绑定角色的用户,不可进行操作",
            "adUntieRoleTip1":"超级管理员不可解绑",
            "edit":"编辑",
            "bindRole":"绑定角色",
            "syncTip":"您需要同步用户管理的内容吗?",
            "bound":"已绑定",
            "unbound":"未绑定",
            "sync":"同步",
            "syncTip1":"正在同步中",
            "syncTip2":"同步完成",
        },
        "loader": {
            "createVIP": "创建VIP",
            "editVIP": "修改VIP",
            "deleteVIP": "删除VIP",
            "bindIP": "绑定公网IP",
            "unbindIP": "解除公网IP",
            "bindMonitor": "关联监听器",
            "unbindMonitor": "解除关联监听",
            "newloadbalance": "新建负载均衡",
            "editloadbalance": "更新负载均衡",
            "loadBalanceName": "负载均衡名称",
            "subnet": "子网",
            "proto": "协议",
            "method": "负载均衡方法",
            "desc": "描述信息",
            "type": "类型",
            "delay": "延迟",
            "timeout": "超时",
            "maxrepeat": "最大重试次数",
            "projectName": "项目名称",
            "loadername": "负载均衡器名称",
            "status": "状态",
            "VIP": "VIP",
            "detail": "负载均衡详情",
            "confirmDelete": "确定删除负载均衡吗?"
        },
        "networks": {
            "routerConfig":"路由配置",
            "bindWidth":"带宽(Mbps)",
            "bindWidthNoUnit":"带宽",
            "network":"网络",
            "name":"名称",
            "identifier":"标识符",
            "networkName":"网络名称",
            "newNetwork": "新建网络",
            "availableIP":"可用IP数量",
            "subnetDetail": "子网详情",
            "baseMsg": "基础信息",
            "portMsg": "端口信息",
            "subnetManager": "子网管理",
            "detail": "详情",
            "VLANID": "VLAN ID",
            "allocateAddress": "分配地址池",
            "availableAddressRange": "可用地址范围",
            "subnet": "子网",
            "editNetwork": "编辑网络",
            "newSubnet": "新建子网",
            "editSubnet": "编辑子网",
            "delSubnet":"删除子网",
            "selectSub":"选择要编辑的子网",
            "netHasNoSub":"所选网络下没有子网",
            "selectOneNetwork":"请选择一个网络",
            "selectNet":"请选择网络",
            "subnetName": "子网名称",
            "bindSubnet": "绑定的子网",
            "external": "外部网络",
            "projectName": "项目名称",
            "providerNet": "网络详情",
            "netType": "网络类型",
            "choseNetType": "请选择网络类型",
            "chosePhyNet": "选择物理网络",
            "phyNet": "物理网络",
            "segmentation_id": "段ID",
            "netDetail": "网络详情",
            "portName": "端口名称",
            "ipAddr": "IP地址",
            "subnetID": "子网ID",
            "deviceOwner": "设备拥有者",
            "IPvision": "IP版本",
            "subnetType":"子网类型",
            "gateway": "网关",
            "enableDhcp": "使用DHCP",
            "useDhcp": "启用DHCP",
            "hostRoutes": "附加路由",
            "dnsNameserver": "DNS域名服务器",
            "IP_pools": "IP池",
            "start": "开始",
            "end": "结束",
            "run": "运行",
            "stop": "停止",
            "gatewayIp": "路由器网关",
            "enableSnat": "使用SNAT",
            "routerName": "路由器名称",
            "VPCName":"VPC名称",
            "basicsInfo":"基础信息",
            "ruleManage":"规则管理",
            "createFirewallRule":" 新建防火墙规则",
            "createRule": "新建规则",
            "ruleList":"规则列表",
            "choseNetwork": "选择网络",
            "noNetToChose": "没有多余的网络，请先建网络",
            "user_noNetToChose": "没有可选网络",
            "ip_auto_to_allocate": "网关IP地址将自动分配",
            "allocateIp_pool": "分配地址池",
            "canNotDelSharedNet": "共享网络,没有权限删除",
            "canNotEditSharedNet": "共享网络,没有权限编辑",
            "canNotDelSharedSub": "共享子网,没有权限删除",
            "canNotEditSharedSub": "共享子网,没有权限编辑",
            "canNotAddSharedSub": "共享子网,没有权限新建",
            "noPrivilegeDelExternalNet": "没有权限删除外部网络",
            "noPrivilegeEditExternalNet": "没有权限编辑外部网络",
            "canNotDelExternalNet": "外部网络不允许删除",
            "noPrivilegeDelExternalSub": "没有权限删除外部子网",
            "noPrivilegeEditExternalSub": "没有权限编辑外部子网",
            "canNotDelExternalSub": "外部子网不允许删除",
            "firewall": "防火墙",
            "createFirewall": "新建防火墙",
            "editFirewall": "编辑防火墙",
            "moreAction": "更多操作",
            "associationRouter": "关联路由器",
            "disassociationRouter": "解除关联路由器",
            "up": "上",
            "todown": "下",
            "created": "已创建",
            "active": "运行",
            "inactive": "未运行",
            "error": "错误",
            "down": "停止",
            "pending_create": "创建中",
            "pending_update": "更新中",
            "pending_delete": "删除中",
            "name": "名称",
            "policy": "策略",
            "firewallName": "防火墙名称",
            "policyName": "策略名称",
            "ruleName": "规则名称",
            "createFirewallPolicy": "新建防火墙策略",
            "editFirewallPolicy": "编辑防火墙策略",
            "status": "状态",
            "adminState": "管理员状态",
            "description": "描述信息",
            "firewallDetail": "防火墙详情",
            "tenant_id": "租户ID",
            "firewallPolicyName": "策略名称",
            "firewallPolicyId": "策略ID",
            "router": "路由器",
            "inputFirewallName": "请输入防火墙名称",
            "addRouter": "添加路由器",
            "chosePolicy": "选择策略",
            "choseAdminStatus": "请选择管理员状态",
            "inputFirewallDes": "请输入防火墙描述",
            "choseRouter": "请选择路由器",
            "choseAssociationRouter": "请选择关联路由器",
            "choseDisassociationRouter": "请选择将要解除关联的路由器",
            "noRouterToChose": "没有可选的路由器，请先建路由器",
            "firewallPolicy": "防火墙策略",
            "insertRule": "插入规则",
            "choseInsertRule": "请选择插入的规则",
            "noRuleToInsert_shared": "没有可插入的规则，请先建共享规则",
            "noRuleToInsert_noneshared": "没有可以插入的规则，请先建非共享的规则",
            "removeRule": "移除规则",
            "choseRemoveRule": "请选择移除的规则",
            "rule": "规则",
            "shared": "共享",
            "audit": "审计",
            "audited": "已审计",
            "addPolicyTip": "提示：所选的规则不共享，策略也不能选择共享",
            "editPolicyTip": "提示：该策略绑定有非共享规则，共享属性值不能修改",
            "firewallPolicyDetail": "防火墙策略详情",
            "inputFirewallPolicyName": "请输入防火墙策略名称",
            "addRule": "添加规则",
            "choseFirewallRule": "请选择防火墙规则",
            "noRuleToChose": "没有可选的防火墙规则，请先建防火墙规则",
            "choseFirewallPolicy": "请选择防火墙策略",
            "noPolicyToChose": "没有可选的策略，请先建策略",
            "inputFirewallPolicyDes": "请输入防火墙策略描述",
            "firewallRule": "防火墙规则",
            "createFirewallRule": "新建防火墙规则",
            "editFirewallRule": "编辑防火墙规则",
            "inPolicy": "在策略",
            "source_ip_address": "源IP",
            "subnet": "子网",
            "port": "端口",
            "portLimit":"端口范围",
            "operate": "操作",
            "source_port": "源端口",
            "destination_ip_address": "目的IP",
            "destination_port": "目的端口",
            "protocol": "协议",
            "action": "动作",
            "enabled": "激活",
            "firewallRuleDetail": "防火墙规则详情",
            "usedPolicy": "已使用在策略",
            "inPolicyPosition": "在策略中的位置",
            "inputFirewallRuleName": "请输入防火墙规则名称",
            "choseProtocol": "请选择协议",
            "choseAction": "请选择动作",
            "source_ip_subnet": "源IP地址/子网",
            "source_ip_subnet_title": "源IP地址/子网\n[端口]",
            "input_source_ip": "请输入源IP地址，例如：192.168.0.1",
            "input_source_subnet": "请输入子网地址，例如：192.168.0.1/24",
            "inputPatternError": "输入格式错误",
            "destination_ip_subnet": "目的IP地址/子网",
            "destination_ip_subnet_title": "目的IP地址/子网\n[端口]",
            "input_destination_ip":  "请输入目的IP地址，例如：192.168.0.1",
            "input_source_port": "请输入源端口号",
            "input_source_port_start": "请输入起始端口号",
            "input_source_port_end": "请输入结束端口号",
            "input_destination_port": "请输入目的端口号",
            "input_destination_port_start": "请输入起始端口号",
            "input_destination_port_end": "请输入结束端口号",
            "input_firewallRuleDes": "请输入防火墙规则描述",
            "project": "项目",
            "domain": "部门",
            "all":"全部",
            "any": "任意",
            "allow": "允许",
            "refuse": "拒绝",
            "deny": "丢弃",
            "anyPosition": "任意",
            "beUsedPolicy": "被防火墙使用的策略，不能删除！",
            "beUsedRule": "被防火墙策略使用的规则，不能删除！",
            "newAdd":"新增",
            "enableGateway":"启动网关",
            "patternError":"格式错误",
            "inter1_128":"输入1~128之间的整数",
            "subInter1_128":"1~128之间的整数",
            "cidrErrorInfo":"IPv6地址长度为128位，使用冒号分隔十六进制格式表示，输入框中请输入正确的十六进制，例如21DA",
            "ipType":"子网类型",
            "inter64_126":"输入64~126之间的整数",
            "subInter64_126":"64~126之间的整数",
            "v6":{
                "enabledV6":"启动IPv6",
                "getV6Type":"获取IPv6方式",
                "v6CIDR":"IPv6 CIDR",
                "v6range":"IPv6地址范围",
                "start":"开始",
                "end":"结束",
                "notInRange":"该地址不在ipv6 CIDR地址范围内,请重新输入",
                "v6ipValidate":"CIDR的第一个16位地址不可输入组播地址(以双f(F)开头)",
                "selectV6Type":"请选择获取ipv6方式"
            },
            "placeholder": {
                "networkName": "请输入网络名称",
                "ipInput": "请输入IP地址(例如: 192.168.1.0/16)",
                "segmentation_id": "请输入VLAN ID",
                "segmentation_id1": "若置空，系统将自动分配VLAN ID",
                "segmentation_vxlanId": "若置空，系统将自动分配VXLAN ID",
                "allocateIp_pool": "IP地址池记录(例如: 192.168.1.2,192.168.1.254)两个IP为一条，换行输入下一条",
                "dnsNameserver": "该子网的DNS服务器IP列表(例如:114.114.114.114)每条记录一行，换行输入下一条",
                "dnsNameserverv6": "该子网的DNS服务器IP列表(例如:AD80:0000:0000:0000:ABAA:0000:00C2:0002)每条记录一行，换行输入下一条",
                "hostRoutes": "主机增加额外的路由(例如: 192.168.200.0/24,192.168.200.254)此为一条，换行输入下一条",
                "hostRoutesv6": "主机增加额外的路由(例如: AD80:0000:0000:0000:ABAA:0000:00C2:0002/24,AD80:0000:0000:0000:ABAA:0000:00C2:0002)此为一条，换行输入下一条",
                "networkBandwidth":"网络带宽，不设置则默认不限速",
                "gatewayIp":"若置空，系统将分配该网段的第一个IP地址作为网关",
                "input_source_ip_subnet":"请输入源IP地址/子网 ",
                "input_destination_ip_subnet":"请输入目的IP地址/子网",
                "selectNetworks":"请选择网络"
            },
            "del": {
                "netMsg": "您确定删除所选网络吗？",
                "subMsg": "您确定删除所选子网吗？",
                "delFirewallMsg": "您确定删除所选防火墙吗？",
                "delFirewallRuleMsg": "您确定删除所选防火墙规则吗？",
                "forbiddenFirewallRuleMsg": "您确定禁用所选防火墙规则?",
                "enabledFirewallRuleMsg": "您确定启用所选防火墙规则？",
            },
            "info":{
                "mainInfo":"私有网络是一个用户自定义的逻辑隔离网络空间，托管在私有网络内的是云主机、负载均衡资源。不同私有网络间完全逻辑隔离。用户可以自定义网段划分、IP地址和路由策略等，并通过防火墙和安全组等实现多层安全防护。",
                "subnetInfo":"可在此管理私有网络下子网的生命周期和子网范围",
                "bindSubnetInfo":"一个私有网络下可以有多个子网，请在“详情 > 子网管理”页面添加子网",
                "sharedInfo":"“是”表示此网络可以被其它部门或项目使用；“否”表示此网络不能被其它项目使用（也不可见）",
                "availableIPInfo":"网络下各子网可使用的IP地址的数量总和",
                "networkBandwidth":"网络带宽限速，请指定允许通过的最大带宽值，关闭或开启带宽限速请前往“系统管理”-“运营管理”-“带宽限速”设置",
                "createNetSharedInfo":"该网络是否设置为共享网络。勾选则表示该网络可以被其他部门或项目使用。如果不希望该网络被其他部门或项目使用，则不勾选此项。",
                "availableInfo":"如果整个子网地址都可用，无需勾选；如果子网有些网段不能使用，请勾选，并设置可用地址范围。",
                "dhcpInfo":"建议勾选“启动DHCP”。启动DHCP，私有网络中的云主机可以动态获取IP地址、网关地址、DNS服务器地址等信息，并能够提升地址的使用率。",
                "physicalNet":"私有网络关联的物理网络信息",
                "phyMainInfo":"外部网络是外部物理网络的映射，一般用于给虚拟路由器分配网关地址，或者给云主机分配公网IP地址。",
                "subnetInfo":"可在此管理外部网络下子网的生命周期和子网范围",
                "networkName":"私有网络的名称",
                "netType":"支持VLAN、VXLAN、FLAT",
                "vlan":"设置私有网络所属的VLAN ID。VLAN ID的取值范围是在部署阶段设置的。每个网络的VLAN ID需要唯一。",
                "vxlan":"VXLAN ID",
                "subnetName":"私有网络子网的名称。",
                "subnetCidr":"子网的IP网段。",
                "enableGateway":"默认勾选“启用网关”。",
                "gatewayIp":"子网网关的IP地址。如为空，则由系统自动分配。",
                "enabledIpv6":"勾选“启动IPv6”，则子网的IP地址采用IPv6地址。",
                "ipType":"指定创建的子网类型，支持IPv4和IPv6",
                "typeOfIpv6":"指定云主机获取IPv6地址的方式。",
                "adressRangeOfIpv6":"如果整个子网地址都可用，无需勾选；如果子网有些网段不能使用，请勾选，并设置可用地址范围。",
                "dnsNameserver":"默认情况下使用网络外部DNS服务器地址，如果需要修改DNS服务器地址，请确保配置的DNS服务器地址可用。",
                "hostRoutes":"主机增加额外的路由。",
                "choseNetwork":"选择网络",
                "availableAddressRange":"可用地址范围",
                "subnetQuota":"子网配额",
                "physicalNetName":"外部网络名称",
                "physicalNet":"物理网络",
                "slaac01":"无状态地址自动配置SLAAC：地址从云平台路由器中发现",
                // "slaac02":"无状态地址自动配置SLAAC：地址通过一个外部路由器发现",
                "slaac02":"无状态地址自动配置SLAAC：地址通过一个物理路由器发现",
                "DHCPv601":"有状态DHCPv6：地址从云平台DHCP中获取",
                "DHCPv602":"无状态DHCPv6：地址从云平台路由器中获得，扩展信息从云平台DHCP中获得",
                "DHCPv603":"有状态DHCPv6：地址从外部DHCP中获取",
                "DHCPv604":"无状态DHCPv6：地址从物理路由器中获得，扩展信息从外部DHCP中获得",
                "ipv6Cidr":"如果启动IPv6，填写子网的IPv6网段。当“获取IPv6方式”配置为slaac和dhcpv6-stateless时，仅支持EUI-64地址，即网络前缀为64；同时需要IPv6子网和路由器关联。",
            },
            "phyInfo":{
                "netType":"外部网络类型，VLAN或FLAT。",
                "physicalNet":"外部网络关联的物理网络信息。",
                "vlan":"设置外部网络所属的VLAN ID。VLAN ID的取值范围是在部署阶段设置的，每个网络的VLAN ID需要唯一。",
                "createNetSharedInfo":"该网络是否设置为共享网络。勾选则表示该网络可以被其他部门或项目使用。如果不希望该网络被其他部门或项目使用，则不勾选此项。",
                "subnetName":"外部网络子网的名称。",
                "subnetCidr":"子网的IP网段。",
                "availableAddressRange":"如果整个子网地址都可用，无需勾选；如果子网有些网段不能使用，请勾选，并设置可用地址范围。",
                "gatewayIp":"网关的IP地址。如为空，则由系统自动分配。",
                "useDhcp":"建议勾选“启动DHCP”。启动DHCP，外部网络中的云主机可以动态获取IP地址、网关地址、DNS服务器地址等信息，并能够提升地址的使用率。",
                "dnsNameserver":"默认情况下使用网络外部DNS服务器地址，如果需要修改DNS服务器地址，请确保配置的DNS服务器地址可用。",
                "hostRoutes":"主机增加额外的路由。",
            },
            "price":{
                "totalPrice":"总价格",
                "perHour":"每小时",
                "perMonth":"每月",
                "count":"合"
            },
            "vpcNetWork":"VPC网络",
            "netBeyondProQuota": "项目网络配额数不足",
            "netBeyondDepQuota": "部门网络配额数不足",
            "subnetBeyondProQuota": "项目子网数配额不足",
            "subnetBeyondDepQuota": "部门子网数配额不足",
            "externalNetwork": "外网",
            "intranet": "内网",
            "exchangeQuota": "网络配额",
            "subnetQuota": "子网配额",
            "subnetCidr": "子网CIDR",
            "autoAllocateGateway": "自动分配网关",
            "gatewayIp": "网关IP",
            "inputGatewayIp": "请输入网关IP",
            "ipPatternError": "IP地址输入格式错误",
            "gatewayIpNotinSubCidr": "网关IP地址不在子网的CIDR内",
            "gatewayIpRange": "网关IP可输入的范围是",
            "ipPoolPattern": "正确的输入格式为:192.168.1.2,192.168.1.254,两个IP为一条，换行输入下一条",
            "ipPoolIsOverlap": "IP地址交叉重叠",
            "ipInPoolEqToGateway": "IP地址池中含有被网关占用的IP",
            "ipInPoolNotInSubCidr": "IP地址池中的IP不在子网的CIDR内",
            "ipPoolStartIpgtEndIp": "IP地址池的结束IP必须大于起始IP",
            "ipNotFullForDhcp":"启动DHCP,至少有四个可用IP",
            "dnsNameserverPattern": "输入格式不正确，请重新输入正确的IP地址。如：114.114.114.114,每条记录一行，换行输入下一条",
            "dnsNameserverPatternv6": "输入格式不正确，请重新输入正确的IP地址。如：AD80:0000:0000:0000:ABAA:0000:00C2:0002,每条记录一行，换行输入下一条",
            "additionalRouterPattern": "请按照正确格式输入，正确的输入格式为:192.168.200.0/24,192.168.200.254,此为一条，换行输入下一条",
            "additionalRouterPatternv6": "请按照正确格式输入，正确的输入格式为:AD80:0000:0000:0000:ABAA:0000:00C2:0002/24,AD80:0000:0000:0000:ABAA:0000:00C2:0002,此为一条，换行输入下一条",
            "repeatRouter":"附加路由重复",
            "repeatipinput":"DNS域名服务器地址重复",
            "ipinputmax":"当前支持最多输入5条dns记录，第6条及之后的dns将不会生效",
            "routermax":"当前支持最多输入20条附加路由记录，第21条及之后的将不会生效",
            "inputSubnetName": "请输入子网名称",
            "ipPool": "IP地址池",
            "start": "开始",
            "end": "结束",
            "ipNotInSubCidr": "IP地址不在子网的CIDR内",
            "ipIsNetworkAdress": "可用地址范围中不能填写子网的网络地址",
            "ipIsBroadcastAdress": "可用地址范围中不能填写子网的广播地址",
            "poolNarrow": "此IP地址池的范围只能扩大，请重新输入",
            "assignedIpNotInPool": "修改后的IP地址池中未含已经分配出去的IP，请重新输入",
            "assignVlan": "指定VLAN",
            "networkBandwidth": "云主机网卡带宽",
            "noLimit": "无限制",
            "except_external_net": "external_net为外部网络名称，请重新输入",
            "except_external_subnet": "external_subnet为外部子网名称，请重新输入",
            "address_range":"可用地址范围",
            "startIp":"请输入起始IP",
            "endIp":"请输入结束IP",
            "gatewayTips":"若置空,系统将自动分配网管IP",
            "bandWidthChangeMsg":"修改带宽后该限速只对使用这个网络新创建的云主机有效，之前的创建的云主机的限速带宽，请自行在云主机网络设置中进行调整。",
            "createPhyMsg":"当前没有可以选择的外部网络，请先在'系统管理-运维管理-多外网管理'中增加物理网络后，再进行新建外部网络操作"
        },
        "firewall":{
            "newFireWall":"新建防火墙",
            "editFireWall":"编辑防火墙",
            "newFireWallSuccess":"防火墙新建成功",
            "createThen":"稍后新建",
            "description":"描述",
            "allow":"允许",
            "deny":"丢弃",
            "reject":"拒绝",
            "false":"关闭",
            "true":"开启",
            "routeGateway":"路由器网关",
            "endPortBiggerStartPort":"结束端口须大于起始端口",
            "selectStatus":"选择状态",
            "detail":"详情",
            "setSourceIP":"源IP地址/子网 端口",
            "setDestinationIP":"目的IP地址/子网 端口",
            "firewallDefaultRule":"防火墙默认规则",
            "moveUp":"上移",
            "moveDown":"下移",
            "defaultRuleCanNotDel":"防火墙默认规则不可删除",
            "effectConfigureRule":"防火墙必须配置规则才能生效，是否立刻新建防火墙规则？",
            "create":"创建",
            "source_ip":"源IP地址/子网",
            "destination_ip":"目的IP地址/子网",
            "port":"[端口]",
            "info":{
                "mainInfo":"防火墙作用于路由器，保护路由器以及与路由器连通的整个内部网络免受外部网络的非法侵入",
                "protocol":"指定过滤的数据包的协议类型。任意表示所有数据包。",
                "action":"对从外部网络进入的数据包设置允许、拒绝、丢弃等动作。如果用于诊断和调试，请选择“拒绝”；如果为了保障防火墙的高安全性和高效率，请选择“丢弃”。",
            }
        },
        "physicalNetworks":{
            "externalNet":"外部网络",
            "physicalNet":"物理网络",
            "shared":"共享网络",
            "physicalNetName":"外部网络名称",
            "newPhysicalNet":"新建外部网络",
            "editPhysicalNet":"编辑",
            "vlanIdInteger":"请输入正整数",
            "placeholder":{
                "physicalNetName":"请输入外部网络名称",
                "noPhyNetworks":"没有可选的物理网络"
            }
        },
        "loadbalancingcertificate":{
            "certificate":"证书",
            "name":"名称",
            "type":"类型",
            "server":"服务器",
            "content":"内容",
            "formaterror":"证书格式不正确，请参考样例参考",
            "reference":"样例参考",
            "privatekey":"私钥",
            "describe":"描述",
            "specificsymbol":"不允许存在特殊字符",
            "keyplaceholder":"请输入私钥",
            "caplaceholder":"- 证书包含证书的公钥和签名等信息，您可直接输入证书内容。- 证书内容格式为：以'-----BEGIN CERTIFICATE-----'作为开头，'-----END CERTIFICATE-----'作为结尾，每行64字符，最后一行不超过64字符，不能有空行。",
        },
        "routers": {
            "router": "路由器",
            "routerName": "路由器名称",
            "newRouter": "新建路由器",
            "editRouter": "编辑路由器",
            "state": "状态",
            "extNetwork": "外部网络",
            "adminState": "管理员状态",
            "enableSnat": "使用SNAT",
            "projectName": "项目名称",
            "routerDetail": "路由器详情",
            "linkSubnet": "关联子网",
            "unLinkSubnet": "解除关联子网",
            "portName": "端口名称",
            "portIp": "端口IP",
            "deviceOwner": "设备拥有者",
            "routerExtNet": "路由器公网",
            "subnet": "子网",
            "subSegment": "子网网段",
            "choseSubnet": "选择子网",
            "choseSubSegment": "选择子网网段",
            "choseLinkSubnetHolder": "请选择关联子网",
            "editPort": "修改端口",
            "gatewayIp": "路由器网关",
            "portForwardingInfo":"内网地址绑定公网IP后，其端口转发功能会失效",
            "portMoreInfoTips":"端口转发数据较多时，可能会导致该路由器下对应的网络响应比较慢",
            "ProposedRules":"建议端口转发规则数量不超过50条",
            "placeholder": {
                "routerExtNet": "选择路由器公网",
                "noRouterExtNet": "没有可用路由器公网",
                "noSubToChose": "没有可用子网，请新建子网",
                "ipExample": "例如：10.10.10.0/24",
                "routerName":"请输入路由器名称",
                "outerPort":"请输入外网端口",
                "innerPort":"请输入内网端口",
                "description":"请输入描述信息",
                "ipAddress":"请输入IP地址",
                "ipTitle":"*IP地址格式错误",
                "portLimit":"请输入1-65535之间的数字",
                "noPortForwarding":"路由器未添加网关，无法新增端口转发规则。请先添加路由器网关，再添加端口转发规则。",
                "subnetLimit":"正确的ip地址格式和范围是：",
                "noPortLimit":"路由器下没有关联子网",
                "choiceVm":"请选择云主机",
                "noVm":"没有可关联的云主机"
            },
            "del": {
                "delRouterMsg": "您确定删除所选路由器吗？",
                "unLinkSubnetMsg": "您确定解除关联子网吗？",
                "delRouterGateway": "您确定删除该路由器网关？ ",
                "delStaticRoutingTable": "您确定删除所选静态路由表？",
                "delPortForwarding":"您确定删除所选端口转发吗？"
            },
            "status": {
                "active": "运行",
                "down": "停止",
                "build": "已创建",
                "error": "错误",
                "pending_create": "创建中",
                "pending_update": "更新中",
                "pending_delete": "删除中",
                "unrecognized": "未识别"
            },
            "setGateway": "设置网关",
            "delGateway": "删除网关",
            "subnetList": "子网列表",
            "staticRouteTable": "静态路由表",
            "addRouteTable": "添加路由表",
            "delRouteTable": "删除路由表",
            "destinationCIDR": "目的CIDR",
            "nexthop": "下一跳",
            "beyondQuota_dep": "部门路由器配额数不足",
            "beyondQuota_pro": "项目路由器配额数不足",
            "routeQuota": "路由器配额",
            "setRouteGateway": "设置路由网关",
            "noSubnet": "没有多余子网，请新建子网",
            "addRouteTableInstruction": "说明：为路由器添加静态路由表.下一跳的IP必须是路由器接口已连接的子网的一个IP",
            "unusedIp": "子网段未被使用的IP地址",
            "assignExternalGatewayIp": "指定外部网关IP",
            "routerGatewayIp": "路由器网关IP",
            "assignExternalSubnet": "指定外部子网",
            "portForwarding":"端口转发",
            "name":"名称",
            "agreement":"协议",
            "outerAddress":"外网地址",
            "outerPort":"外网端口",
            "innerAddress":"内网地址",
            "innerPort":"内网端口",
            "ipAddress":"云主机IP地址",
            "condition":"状态",
            "desc":"描述",
            "custom":"自定义",
            "innerlimite":"填写范围：1-65535",
            "customIpAddress":"自定义IP地址",
            "enter":"请输入",
            "integer":"的整数",
            "keymsg":"该key用于SaaS云管调用MaaS API时的认证，默认存放位置为MaaS容器内的/etc/maas/api_key文件",
            "synchronizationData":"同步数据",
            "synchronizationUser":"同步用户",
            'info':{
                'routerName':"",
                'routerExtNet':"路由器关联的外部网络。",
                'assignExternalSubnet':"未勾选“指定外部子网”时，系统随机分配子网。勾选“指定外部子网”后，在下拉列表中选择子网名称。",
                'choseSubnet':"选择该路由器关联的子网。",
                'assignExternalGatewayIp':"未勾选“指定外部网关IP”时，系统随机分配IP地址。勾选“指定外部网关IP”后，IP地址必须手工设置。",
                'subSegment':"指定子网的网段，IP地址必须在该网段中。",
                'routerGatewayIp':"",
                'linkSubnet':"将子网与路由器关联后，子网才可以通过此路由器访问其他网络。",
                'portName':"路由器端口名称。",
                'destinationCIDR':'目的子网的网段。',
                'nexthop':"与当前路由器连接的另一台路由器的接口的IP地址，必须与目的子网在同一网段。",
                'agreement':"数据传输使用的协议类型。",
                'outerAddress':"路由器的网关地址。该地址为固定值，不能输入。如果该路由器未设置网关，则无法新建。",
                'outerPort':"路由器网关端口不能复用。",
                'customIpAddress':"IP地址必须绑定在该路由器下的子网。",
                'insIpAddress':"路由器关联子网下的云主机IP地址。",
                'innerPort':"内网端口可以复用。",
            }
        },
        "bandwidths": {
            "unlimited": "无限速",
            "bandwidth": "总带宽",
            "portId": "端口ID",
            "projectName": "项目名称",
            "ipAddr": "IP地址",
            "phyNetWork": "物理网络",
            "device": "设备",
            "editBandwidth": "修改带宽"
        },
        "floatingIps": {
            "editFloatingIps": "编辑公网IP",
            "floatIp": "公网IP",
            "addFloatIp": "申请公网IP",
            "bind": "绑定",
            "unbind": "解绑",
            "bindFloatIp": "绑定公网IP",
            "assignSub": "指定外部子网",
            "inputIP": "指定外部IP地址",
            "ipAddr": "IP地址",
            "instanceName": "云主机名称",
            "resourceName": "资源名称",
            "resourceType": "资源类型",
            "resource": "资源",
            "fixedIP": "私网IP",
            "status": "状态",
            "projectName": "项目名称",
            "routerId": "路由ID",
            "portId": "端口ID",
            "resourcePool": "资源池",
            "floatIpPool": "外部网络",
            "subnet": "子网",
            "subSegment": "子网网段",
            "choseSubSegment": "选择子网网段",
            "floatingIpQuota": "公网IP配额",
            "loadBalance": "负载均衡",
            "placeholder": {
                "choseFloatIpPool": "选择外部网络",
                "choseInstance": "选择云主机",
                "choseSubnet": "选择子网",
                "choseResource": "选择资源"
            },
            "del": {
                "unBindFloatIpMsg": "您确定解除公网IP的绑定吗？",
                "delFloatingIpMsg": "您确定删除所选公网IP吗？",
                "confirmDelFloatingIpMsg": "所选公网IP中,存在已绑定资源的公网IP"
            },
            "beyondQuota_pro": "项目公网IP配额数不足",
            "beyondQuota_dep": "部门公网IP配额数不足",
            "noInsChose": "没有云主机可以绑定，请",
            "createInstance": "新建云主机",
            "ports":"虚拟网卡",
            "enter":"请输入",
            "integer":"的整数",
            "floatingipQuota":"公网IP配额",
            "info":{
                'assignSub':"未勾选“指定外部子网”时，系统随机分配子网。勾选“指定外部子网”后，在下拉列表中选择子网名称。",
                'choseSubnet':"选择该外部网络关联的子网。",
                'inputIP':"未勾选“指定外部IP地址”时，系统随机分配IP地址。勾选“指定外部IP地址”后，IP地址必须手工设置。",
                'subSegment':"指定子网的网段，IP地址必须在该网段中。"
            }
        },
        "vpn":{
            "name":"名称",
            "description":"描述信息",
            "state":"站点连接状态",
            "server_state":"服务状态",
            "newVpn":"新建VPN",
            "editVpn":"编辑VPN",
            "seconds":"秒",
            "basicConfig":"基础配置",
            "delVPN":"您确定删除所选VPN吗？",
            "delSubnet":"您确定删除所选本地子网吗？",
            "delTargetNet":"您确定删除所选目标网络吗？",
            "configSiteConnect":"配置站点连接",
            "IKEAdvancedOptions":"IKE策略高级选项",
            "IpsecAdvancedOptions":"Ipsec策略高级选项",
            "modifyIKEPolicy":"修改IKE策略",
            "modifyIPSecPolicy":"修改IPSec策略",
            "modifySiteConnection":"修改站点连接",
            "enabled":"启用",
            "disabled":"禁用",
            "authenAlgorithm":"认证算法",
            "encryptionAlgorithm":"加密算法",
            "IKEversion":"IKE版本",
            "IKEperiod":"IKE周期",
            "IKEConsultativePeriod":"IKE协商期",
            "addLocalSubnet":"添加本地子网",
            "oneNegotiationModel":"阶段1协商模式",
            "perfectSecrecy":"完美向前保密",
            "transProtocol":"传输协议",
            "EncapsulationMode":"封装模式",
            "IPSecProtocolCycle":"IPSec协议周期",
            "localRouter":"本地路由器",
            "localNetwork":"本地网络",
            "localGateway":"本地网关",
            "destinationGateway":"目标网关",
            "targetGateway":"目标网关地址",
            "targetNetwork":"目标网络",
            "addTargetNetwork":"添加目标网络",
            "limit_1_255":"非1~255整数范围",
            "limit_0_255":"非0~255整数范围",
            "limit_1_29":"非1~29整数范围",
            "limit_lifetime_60_86400":"请输入60~86400范围内的正整数",
            "limit_lifetime_68_1500":"请输入68~1500范围内的正整数",
            "limit_lifetime_10_3600":"请输入10~3600范围内的正整数",
            "targetRoute":"目标路由标识符",
            "sharedPassword":"预共享密码",
            "advancedOptions":"高级选项",
            "connectionMode":"连接方式",
            "maxTransUnit":"最大传输元",
            "anomalyDetection":"目标端异常检测",
            "DPDaction":"DPD动作",
            "heartInterval":"心跳间隔(秒)",
            "overtimeInterval":"超时间隔(秒)",
            "baseConfig":"基础配置",
            "localSubnet":"本地子网",
            "targetNet":"目标网络",
            "noLocalSubnet":"没有可添加的本地子网",
            "noLocalRouter":"没有可添加的本地路由器",
            "permitInteger":"请输入正整数",
            "overtimeBiggerInterval":"输入值必须大于心跳间隔的数字",
            "minmtu":"输入值必须大于等于68",
            "maxlengthPwd":"密码长度至多255位",
            "targetNetOverlap":"当前目标网络地址有重叠，请重新确认地址范围",
            "bindFloatingIpVpnInfo":"IPSec VPN功能会与公网IP功能冲突，建立站点连接之后请检查站点双方私有子网内云主机是否绑定公网IP,如果有请解除。",
            "modifyLocalSubnetMsg":"修改本地子网后，请相应的修改对端的目标网络",
            "modifyNetworkMsg":"修改目标网络后，请相应的修改对端的本地子网",
            "selectOneVpn":"请选择一个IPSec VPN",
            "oneLocalSubnte":"当前IPSec VPN仅有一个本地子网，不可删除",
            "oneTargetNet":"当前IPSec VPN仅有一个目标网络，不可删除",
            "delLocalSubnet":"删除本地子网",
            "delTargetNet":"删除目标网络",
            "selectOneLocalSubnet":"请勾选一个本地子网",
            "atLeastCheckedOneLocalSub":"IPSec VPN至少含有一个本地子网，不可全部删除",
            "selectOneTargetNet":"请勾选一个目标网络",
            "atLeastCheckedOneTargetNet":"IPSec VPN至少含有一个目标网络，不可全部删除",
            "placeholder": {
                "name":"请输入名称",
                "enter":"请输入",
                "select":"请选择",
                "description":"请输入描述信息",
                "localRouter":"请选择本地路由器",
                "DPDaction":"请选择DPD动作",
                "authenAlgorithm": "请选择认证算法",
                "secretAlgorithm": "请选择加密算法",
                "IKEversion": "请选择IKE版本",
                "IKEperiod": "请输入IKE周期",
                "periodUnit": "周期单位",
                "oneNegotiationModel":"请选择阶段1协商模式",
                "perfectSecrecy":"请选择完美向前保密",
                "transProtocol":"请选择传输协议",
                "EncapsulationMode":"请选择封装模式",
                "IPSecProtocolCycle":"请选择IPSec协议周期",
                "selLocalSubnet":"请选择本地子网",
                "noLocalSubnet":"没有可选择的本地子网",
                "noLocalRouter":"没有可选择的本地路由器",
                "localRouter":"请选择本地路由器",
                "localNetwork":"请选择本地网络",
                "targetRoute":"请输入目标路由标识符",
                "sharedPassword":"请输入预共享密码",
            },
            "status":{
                "active":"运行",
                "down":"关闭",
                "build":"创建中",
                "error":"错误",
                "pending_create":"创建中",
                "pending_update":"更新中",
                "pending_delete":"删除中",
                "server_active":"运行",
                "server_stop":"停止"
            },
            "action":{
                "hold":"保持",
                "clear":"清除",
                "restart":"重启",
                "disabled":"禁用",
                "restart-by-peer":"被目标端重启",
                "bi-directional":"双向",
                "response-only":"仅应答",
            },
            "info":{
                "mainInfo":"IPSec VPN指采用IPSec协议来实现远程接入的一种VPN技术。IPSec全称为Internet Protocol Security，用以提供公用和专用网络的端对端加密和验证服务。IPSec VPN用于连接两个私有云环境或者连接私有云和公有云环境，将两个不同私有网络环境连接成为一个统一的私有网络环境。",
                "name":"vpn名称",
                "description":"描述",
                "authenAlgorithm":"认证算法",
                "encryptionAlgorithm":"加密算法",
                "IKEversion":"IKE版本",
                "IKEperiod":"IKE周期",
                "oneNegotiationModel":"阶段1协商模式",
                "perfectSecrecy":"完美向前保密",
                "transProtocol":"传输协议",
                "EncapsulationMode":"封装模式",
                "IPSecProtocolCycle":"IPSec协议周期",
                "localRouter":"本地路由器的网关地址。",
                "localSubnet":"加入VPN的本地子网。",
                "targetGateway":"隧道对端物理设备上的公网IP，当前不同IPSec VPN的目标网关不能重复。",
                "targetNetwork":"通过隧道可达的目标网络地址，所有去往这个网络的IP包都会通过IPSec VPN隧道发送。",
                "targetRoute":"默认是本地网关地址。",
                "sharedPassword":"使用预共享密码进行IKE协议的建立。取值范围为1～255位。",
                "connectionMode":"选择VPN是否只能响应连接或同时响应并启动连接。",
                "maxTransUnit":"最大传输单元（MTU）值来处理分段。IPv4的最小值为68，IPv6的最小值为1280。",
                "DPDaction":"默认为保持。",
                "heartInterval":"检测间隔，以秒为单位。默认值是30。",
                "overtimeInterval":"检测超时时间，以秒为单位。默认值是120。",
            }
        },
        "initSetting":{
            "netcheck":{
                '0':"未联通",
                '1':"连通成功"
            },
            "settingStatus":{
                "setuping":"设置中",
                "unSetup":"未设置",
                "setupEnd":"已设置"
            },
            "settingTranslate":{
                "vpcCompelete":"VPC网络设置已完成",
                "alarmCompelete":"添加告警设置已完成",
                "vpcNetWorkCompelete":"请先完成VPC网络设置",
                "backendCompelete":"配置云主机后端共享存储已完成",
                "completeSetting":"请先完成添加告警设置",
                "completeShare":"迁移镜像库到共享存储中已完成",
                "configHost":"请先配置云主机后端共享存储",
                "suggestSetting":"推荐设置",
                "vpcDesc":"添加VPC网络后，用户可以直接使用该网络创建虚拟机，并可访问外网",
                "alarmSetting":"添加告警设置",
                "alarmDesc":"云平台故障信息将实时发送到该联系人列表中",
                "storageSetting":"配置云主机后端共享存储",
                "storageDesc":"配置共享存储后，云主机将支持热迁移、硬盘故障转移、挂载云硬盘等操作",
                "transferSetting":"迁移镜像库到共享存储中",
                "transferDesc":"该操作需要先配置共享存储，当将镜像库迁移到共享存储中后，云平台镜像库可获得更大镜像库存储空间，并且镜像服务将可具有高可用能力",
                "saveSetting":"保存设置",
                "nextStepTips":"请先保存当前设置，再执行下一步操作",
                "transferImage":"迁移镜像库",
                "compeleteTransfer":"请先完成迁移镜像库操作",
                "alarmWay":"告警方式",
                "alarmContacts":"告警联系人",
                "weChat":"微信",
                "email":"邮件",
                "emailServer":"邮件服务器",
                "activeEmailOrWeChat":"请先完成邮件或微信的设置并激活",
            },
            "transferImage":{
                "loadingTips":"镜像库迁移中，请耐心等待。",
                "storage":"选择存储设备",
                "selectStorage":"请选择存储设备",
                "transferTo":"迁移镜像库到",
                "startTransfer":"开始迁移",
                "transferTips1":"迁移过程中，将会影响虚拟机的创建，",
                "transferTips2":"请确认是否现在开始迁移？",
                "transferTips3":"迁移过程中，将会影响创建云主机、上传镜像和删除镜像等与镜像相关的操作，请确认是否现在开始迁移？",
                "transferTips4":"镜像库后端从“ 文件系统系统 ”迁移走后将不可再次变更后端，请确认是否将该存储设备作为镜像库后端？",
                "transferInitTips":"迁移初始化中，需要重启存储和镜像服务，请稍候",
                "transferSuccess":"迁移成功",
                "transferFailed":"迁移失败"
            },
            "welcomeToUsePlatform":"欢迎使用云管理平台",
            "finishedInitNetwork":"为了更好的方便您使用，请完成如下初始化网络配置：",
            "datacener":"数据中心",
            "placeholder":{
                "selectDataCenter":"请选择数据中心",
                "enterPort":"请输入端口",
                "selectStorageCopy":"请选择存储副本数",
                "enterStorageUser":"请输入存储用户",
            },
            "addStorageWaitInfo":"添加存储需要重启存储服务，预计时长5-10分钟，请耐心等待。",
            "loading":"数据加载中",
            "configInsBackendSharedStorage":"配置云主机后端共享存储",
            "buttStorage":"对接存储",
            "configureHost":"配置主机",
            "connectIscsi":"连接iscsi存储",
            "Verification":"验证中",
            "ISCSIServerIp":"ISCSI服务器IP",
            "ISCSIPort":"ISCSI Port",
            "configureAuthorizationInfo":"配置授权信息",
            "ISCSIServerValidateComputeServer":"ISCSI服务器验证云计算服务器（CHAP）",
            "ISCSIServerValidateInfo":"以下用户名和密码为该台设备连接ISCSI服务器时使用的认证账号信息。由ISCSI服务器提供账号，本地仅连接即可。",
            "ComputeServerValidateISCSIServer":"云计算服务器验证ISCSI服务器（双向CHAP）",
            "ComputeServerValidateInfo":"以下用户名和密码为ISCSI服务器连接本台设备时使用的认证账号信息。由本台设备提供账号信息，ISCSI仅连接即可。",
            "storageCounterpart":"存储副本数",
            "cephEditionCompatibility":"您当前的ceph客户端版本为10.2.7，请确认ceph服务端版本是否与该版本兼容",
            "storageUser":"存储用户",
            "twoNodeNotModifyStorageNet":"两节点数据中心不允许修改存储网",
            "buttedIpStorageNotModifyStorageNet":"已对接过IP类存储，不允许修改存储网",
            "downNetworkConfig":"下发网络配置",
            "resetIP":"重置IP",
            "clusterNet":"集群网",
            "storageNet":"存储网",
            "tenantNet":"租户网",
            "manageNet":"管理网",
            "publicNet":"业务网",
            "noNetCard":"暂无网卡",
            "and":"与",
            "conflict":"冲突",
            "range":"范围为",
            "IPNotInCIDR":"IP地址不在CIDR范围之内",
            "startIPBiggerThanEndIP":"结束IP地址必须比起始IP地址大",
            "disksConfig":"磁盘配置",
            "netConfig":"网卡配置",
            "verifyStorageConnect":"验证存储连通性",
            "hostName":"主机名",
            "datacener":"数据中心",
            "core":"CPU(核)",
            "memory":"内存(G)",
            "mountedFCStorage":"已挂载FC存储",
            "findTarget":"发现目标（target）",
            "lodinConfirmISCSIDevice":"登录并确认ISCSI存储设备",
            "findTargetStatus":"发现目标状态",
            "targetIQNinfo":"目标iqn信息",
            "success":"成功",
            "failure":"失败",
            "notFindTarget":"未发现目标",
            "selectISCSIdevice":"选择ISCSI存储设备",
            "close":"关闭",
            "waitCheckNetSpendOneMinute":"请稍后，存储网络联通性检测预计耗时1分钟",
            "checkNetFailed":"存储联通性检测任务未执行成功！",
            "startCheck":"开始检测",
            "disks":{
                "noFCDisks":"请确保FC存储池不为空，再操作！",
                "noDataDisks":"请确保数据盘不为空，再操作！",
                "disksConfigNode":"硬盘配置节点",
                "availableDisks":"可用硬盘",
                "diskName":"硬盘名称",
                "diskCapicty":"硬盘容量",
                "diskType":"硬盘类型",
                "journalDisk":"日志盘",
                "draftingArea":"可拖拽区域",
                "dataDisk":"数据盘",
                "FCstoragePool":"FC存储池",
                "configurationProgress":"下发配置进度"
            }
        },
        "security": {
            "whole":"整点",
            "period":"周期",
            "month":"月份",
            "clone":"克隆",
            "seeRule":"查看规则列表",
            "securityGroup": "安全组",
            "securityGroupName": "安全组名称",
            "template": "模板",
            "securityGroupType": "安全组类型",
            "blackList": "黑名单",
            "whiteList": "白名单",
            "null": "空",
            "inputFirewallName": "请输入安全组名称",
            "description": "描述信息",
            "securityGroupDetail": "安全组详情",
            "createRule": "新建规则",
            "editRule": "编辑规则",
            "direction": "方向",
            "ethertype": "输入类型",
            "_ipProtocol":"类型/编码值",
            "inputType": "输入类型",
            "ip_protocol": "IP协议",
            "status": "状态",
            "enabled": "启用",
            "disabled": "禁用",
            "portRange": "端口范围",
            "target": "目标",
            "name": "安全组名称",
            "selectRule": "选择规则",
            "selectDirection": "选择方向",
            "openPort": "打开端口",
            "portWay": "端口方式",
            "port": "端口",
            "protocol": "IP 协议",
            "assignPort": "指定端口",
            "fromPort": "起始端口",
            "endPort": "结束端口",
            "type": "类型",
            "coding": "编码",
            "rule": "规则",
            "way": "方式",
            "sourceType": "源类型",
            "typeCodeValue": "类型值/编码值",
            "any": "任意",
            "createSecurityGroup": "新建安全组",
            "editSecurityGroup": "编辑安全组",
            "delSecurityGroup": "您确定删除所选安全组？",
            "securityGroupInUse": "安全组已绑定云主机，无法删除",
            "ingress": "入口",
            "egress": "出口",
            "deleteSecurityGroupRule": "您确定删除所选安全组规则?",
            "deleteTemplateRule": "您确定删除所选规则?",
            "quotas": "配额",
            "project_securityGroupQuotas": "项目安全组配额数不足",
            "domain_securityGroupQuotas": "部门安全组配额数不足",
            "project_securityGroupRulesQuotas": "项目安全组规则配额数不足",
            "domain_securityGroupRulesQuotas": "部门安全组规则配额数不足",
            "wayIn": "入口方向",
            "wayOut": "出口方向",
            "authorizationObject": "授权对象",
            "protocolType": "协议类型",
            "portRange": "端口范围",
            "authorizationStrategy": "授权策略",
            "cloneSecurityGroup": "克隆安全组",
            "dataCentre": "目标数据中心",
            "department": "目标部门",
            "project": "目标项目",
            "import": "导入",
            "export": "导出",
            "selectFile":"选择文件",
            "fileCheck": "文件格式必须为.xls或.xlsx",
            "importTips": "您需要先下载模板手动执行再上传导入。",
            "templateFile": "安全组模板文件",
            "importsuccess": "文件导入成功",
            "importerror": "文件导入失败",
            "templateName": "模板名称",
            "createTemplate": "新建模板",
            "editTemplate": "编辑模板",
            "deleteTemplate": "您确定删除所选安全组模板？",
            "templateDetail": "模板详情",
            "allowed": "允许",
            "notAllowed": "不允许",
            "placeholder": {
                "type": "请输入ICMP类型范围(-1~255)",
                "coding": "请输入ICMP编码范围(-1~255)",
                "selectTemplate": "请选择模板",
                "dataCentre": "请选择目标数据中心",
                "department": "请选择目标部门",
                "project": "请选择目标项目",
                "templateName": "模板名称",
            },
            'info':{
                'allowedRule':"指定允许通过的数据包的协议类型。",
                'forbiddenRule':"指定禁止通过的数据包的协议类型。",
                'rule':"指定允许通过的数据包的协议类型。",
                'direction':"入口表示从云主机以外的设备访问云主机，出口表示从云主机访问云主机以外的设备。",
                'portWay':"可指定端口和端口范围。如果指定端口，则需要在下面填入端口号（范围1～65535）；如果指定端口范围，则填入起始端口和结束端口。",
                'port':"指定允许访问的云主机的服务端口号。",
                'cidr':"指定该规则允许的IP地址范围。",
		        'allowedPort':"指定允许访问的云主机的服务端口号。",
                'forbiddenPort':"指定禁止访问的云主机的服务端口号。",
                'allowedCidr':"指定该规则允许的IP地址范围。",
                'forbiddenCidr':"指定该规则禁止的IP地址范围。"
            }
        },
        "keypair": {
            "keypair": "密钥对",
            "keypaireName": "密钥对名称",
            "fingerprint": "指纹",
            "import": "导入",
            "importKeypair": "导入密钥对",
            "importPublicKey": "请输入公钥",
            "createKeypair": "新建密钥对",
            "inputKeypairName": "请输入密钥对名称",
            "download": "下载",
            "downloadPrivateKey": "下载私钥",
            "publicKey": "公钥",
            "downloadTips": "提示: 此私钥只提供本次下载，请及时下载并妥善保管",
            "deleteKeyPair": "您确定要删除密钥对吗？"
        },
        "project": {
            "del": {
                "resource": {
                    "volumes": "云硬盘",
                    "instances": "云主机",
                    "security_group": "安全组",
                    "router": "路由器",
                    "network": "网络",
                    "floatingip": "浮动IP",
                    "snapshots": "快照",
                    "images":"镜像"
                },
                "unit": "个",
                "error_info_prefix": "项目无法删除，因为该项目有以下资源",
                "error_info_suffix": "。请删除该项目下的资源之后，再删除此项目。"
            },
            "quotafail":"获取配额失败,请",
            "retry":"重试",
            "parametersTips":"参数应不小于该项目对应资源已使用量",
            "domaindetail":" 部门详情",
            "projectdetail":" 项目详情",
            "parametersDomainTips":"参数应不小于该部门对应资源已使用量",
            "parametersprojectLess":"参数应不小于项目最大配额",
            "parametersDomainLess":"参数应不小于该部门下属项目对应资源的最大分配量",
            "parameterLargerThanAllProject":"参数应不小于该部门下属项目对应资源配额总和",
            "title": "项目管理",
            "quota": "项目配额",
            "quota_GB":"项目配额（GB）",
            "newproject": "新建项目",
            "editproject": "编辑项目",
            "delproject": "是否删除选中的项目",
            "proName": "请输入项目名称",
            "table": {
                "pro_name": "项目名称",
                "pro_desc": "描述信息",
                "pro_id": "项目ID",
                "pro_active": "激活",
                "createTime": "创建时间",
                "pro_region": "域ID"
            },
            "newProject": {
                "dep_name": "部门名称",
                "pro_name": "项目名称",
                "pro_desc": "描述信息",
                "pro_use": "可用"
            },
            "projectQuotaTip":"以下配额信息仅供参考，可根据实际情况进行修改。注意：项目配额不能超过部门配额。"
        },
        "depart": {
            "title": "部门管理",
            "quota": "部门配额",
            "quota_GB": "部门配额（GB）",
            "new_depart": "新建部门",
            "edit_depart": "编辑部门",
            "del_depart": "是否删除选中的部门",
            "instanceDetail": "部门云主机详情",
            "quotaDetail": "部门配额详情",
            "allocateUser": "分配用户",
            "noAllocate": "未分配的用户",
            "allocatedUser": "已分配的用户",
            "table": {
                "dep_name": "部门名称",
                "dep_desc": "描述信息",
                "dep_region": "域ID",
                "dep_use": "可用",
                "createTime": "创建时间",
                "dep_not_edit": "default部门不能编辑",
                "dep_not_del": "default部门不能删除",
                "pro_not_edit": "admin项目不能编辑",
                "pro_not_del": "admin项目不能删除"
            },
            "newDepart": {
                "dep_name": "部门名称",
                "pro_desc": "描述信息"
            },
            "editDefaultQuota":"可编辑下面默认配额"
        },
        "multiplenet": {
            "title":"云平台物理网络互通",
            "type":{
                "cluster":"集群网",
                "storage":"存储网"
            },
            "configNetworkLoading":"网络正在对接中",
            "configNetworkSuccess":"网络对接成功",
            "configNetworkFailed":"网络对接失败",
            "configNetworkCompleted":"云平台物理网络互通已经对接完成",
            "networkConnected":"已对接",
            "placeholder": {
                "selectNetwork":"请选择网络"
            }
        },
        "org": {
            "title": "组织结构",
            "new_org": "新建根部门",
            "table": {
                "dep_name": "组织名称",
                "org_handle": "操作",
                "org_desc": "描述信息"
            },
            "upOrg": {
                "direct_org": "直属部门",
                "direct_child_org": "新建子部门",
                "edit_org": "编辑部门",
                "org_name": "部门名称",
                "dep_desc": "描述信息",
                "move_org": "移动部门",
                "move_tip": "确定将部门",
                "move_from": "移动到部门",
                "move_to": "下",
                "del": "删除部门",
                "del_org": "确定删除部门",
                "not_del_org": "该部门存在下属部门，无法删除",
                "name_tip": "请输入部门名称",
                "desc_tip": "请输入描述信息"
            }
        },
        "role": {
            "title": "权限管理",
            "new_role": "新建权限",
            "edit_role": "编辑权限",
            "table": {
                "role_name": "角色名称",
                "role_desc": "描述信息",
                "role_basicName":"基础角色",
                "role_createTime": "创建时间",
                "role_del": "是否删除选中的角色",
                "role_tip": "请输入权限名称",
                "role_tip_desc": "描述信息",
                "role_domain": "部门名称",
                "ok": "确定",
                "privilege_not_edit": "admin权限不能编辑",
                "privilege_not_del": "admin权限不能删除"
            },
            "role_name":"角色名称",
            "role_desc": "角色描述",
            "role_tip": "请输入角色名称",
            "role_tip_desc": "请输入角色描述",
            "new_user":"新建角色",
            "overview":{
                "user_top5":"用户操作日志TOP5",
                "source_top5":"资源操作日志TOP5",
                "logAmount":"日志数量",
                "today":"今天",
                "yestoday":"昨天"
            }
        },
        "node": {
            "role":{
                "storage":"存储节点",
                "message":"消息队列节点",
                "compute":"计算节点",
                "network":"网络节点",
                "control":"控制节点",
            },
            "region": "区域",
            "nodeMessage": "节点详情",
            "hostNamePatternMsg":"别名由字母、数字或横线组成，长度为4-64字符，且只能以字母或数字开头。",
            "setHostPrettyName":"设置主机别名",
            "hostPrettyName":"主机别名",
            "prettyName":"别名",
            "node_detail": {
                "public": "公网",
                "cluster": "集群网",
                "storage": "存储网",
                "tenant": "租户网"
            },
            "clusterNode":{
                "virtualNode":"虚拟资源节点",
                "containerNode":"容器节点",
                "accessStorage":"在使用通用驱动场景下，需要在存储中授权该主机访问，并在主机中挂载相应的存储卷。",
                "addCluster":"加入集群",
                "nodePromote":"节点晋升",
                "twoNodePromote":"两节点晋升",
                "promotionRetry":"晋升重试",
                "thirdNodePromotion":"第三节点晋升",
                "enableHa":"启用多节点高可用架构",
                "addClusterTip":"当前节点未加入集群，请先加入集群，再查看该节点的详情。",
                "physicalHostName":"物理主机名称",
                "ipmiAddress":"IPMI地址",
                "ipmiTips":"验证IPMI信息时，请确保 IPMI LAN服务保持为开启状态",
                "virtualNumber":"虚拟机个数（运行）",
                "virtualType":"虚拟化类型",
                "usedPhysicalCPU":"已用物理CPU",
                "totalPhysicalCPU":"物理CPU总数",
                "usedMemory":"已用内存（GB）",
                "totalMemory":"总内存（GB）",
                "totalDisk":"磁盘总容量（GB）",
                "freeMemory":"空闲内存（GB）",
                "usedDisk":"已用磁盘容量（GB）",
                "currentTask":"当前任务量",
                "minAvailableDisk":"最小可用磁盘（GB）",
                "freeDisk":"空闲磁盘容量（GB）",
                "detail":"详情",
                "falutTips":"故障域每级有效数为1~99，故障域的分级格式为：1, 3-1, 2-1-1，每个集群节点的故障域级别设置应该保持一致，例如:2-1-1三级故障域格式。",
                "nodeTips1":"该节点不能被删除，可能有以下原因：",
                "nodeTips2":"1.当前集群中正常节点数小于4",
                "nodeTips3":"2.该节点状态为过程状态",
                "nodeTips4":"1.当前集群中存在过程状态的节点",
                "nodeTips5":"3.当前集群中存在不健康的节点",
                "nodeTips6":"2.当前集群中存在不健康的节点",
                "currentDelivery":"当前为软件交付模式,",
                "buttedStorage":"未对接存储，",
                "mirror":"对接存储后未进行镜像迁移，",
                "promotionNode":"晋升新节点",
                "twoNodePTips":"两节点晋升操作尚未完成，待两节点晋升完成再进行下一步操作",
                "nodePTips":"请选择需要加入集群的节点，且该节点加入集群成功后，再点击'下一步'进行晋升新节点操作",
                "twoNodePSTips":"节点晋升中，不可点击完成按钮",
                "twoNodesP":"两个节点晋升",
                "compelete":"完成",
                "canNotDel":"不可删除"
            },
            "networkt": "网络",
            "netmask": "子网掩码",
            "vlan": "VLAN范围",
            "status": {
                "1": "未配置",
                "2": "配置中",
                "3": "节点配置异常",
                "4": "节点配置成功",
                "11": "节点初始化",
                "12": "新增节点初始化完成",
                "13": "新增节点失败",
                "21": "开始删除节点",
                "23": "删除节点失败",
                "31": "开始晋升节点",
                "41": "开始维护节点",
                "42": "节点维护中",
                "43": "维护节点失败",
                "51": "开始激活节点",
                "53": "激活节点失败",
                "33": "晋升节点失败",
                //新增状态
                "61": "两节点晋升",
                "null": "未配置",
                "nodeMng.ing": "开始新增节点",
                "nodeMng.del.ing": "开始删除节点",
                "nodeMng.promote.ing": "开始晋升节点",
                "nodeMng.manit.ing": "开始维护节点",
                "nodeMng.active.ing": "开始激活节点",
                "nodeMng.add.ing": "开始新增节点",
                "nodeMng.add.fail": "新增节点失败",
                "nodeMng.del.ing": "开始删除节点",
                "nodeMng.del.fail": "删除节点失败",
                "nodeMng.promote.fail": "晋升节点失败",
                "nodeMng.manit.fail": "维护节点失败",
                "nodeMng.active.fail": "激活节点失败",
                "nodeMng.del.success": "删除节点完成",
                "nodeMng.promote.success": "晋升节点完成",
                "nodeMng.manit.success": "维护节点完成",
                "nodeMng.active.success": "激活节点完成",
                "nodeMng.del.autoretry": "自动重试删除节点",
                "nodeMng.add.success": "新增节点成功",
                "nodeMng.manit.autoretry": "自动重试"
            },
            "repair": "维护",
            "add": "添加",
            "import": "导入硬件信息",
            "importPretty":"导入主机别名",
            "importPrettyFile":"导入主机别名需要特定的模板文件",
            "downloadPrettyFile":"点击下载",
            "prettyFile":"主机别名文件",
            "selectPrettyFile":"选择文件",
            "prettyFileModel":"文件格式必须为.xls或者.xlsx",
            "activation": "激活",
            "retry": "重试",
            "delNodes":"删除节点",
            "roles":"角色",
            "forceDelNodes":"强制删除节点",
            "bcacheErrorTips":"节点{{name}}内核版本不支持配置磁盘，如要配置磁盘请升级内核版本至4.x及以上",
            "confirmMaintenance": "维护时会将当前节点的所有虚机关机",
            "confirmDel": "1. 删除节点之后当前节点上这些云资源都将被清除,请您确认当前节点上的所有资源已经全部迁移完毕？",
            "confirmDel2": "2. 确保分布式存储的健康状态是health_ok。",
            "confirmDel3": "3. 确保分布式存储有足够的剩余空间恢复所删除节点的数据。",
            "confirmDel4": "4. 当存储池中容量使用较多时，可能会导致删除节点的操作耗时较长。",
            "confirmDel5": "如有疑问，请与我司相关技术人员联系！",
            "confirmDel6": "删除节点为高危操作，请输入管理员账号及密码",
            "confirmDel7": "强制删除节点为高危操作，请输入管理员账号及密码",
            "confirmDel8": "强制删除操作针对于底层数据中心设备已经被移除场景下的运维操作，它只会删除云管数据库中该节点信息且为不可逆操作，请谨慎执行！",
            "successShutDown":"正在关闭所有服务器，请查看服务器电源状态，确认执行结果",
            "failedShutDown":"集群网络异常，请先检查集群网的联通性，再执行此功能",
            "startShutDown":"开始关闭",
            "useStatusValues": {
                "true": "是",
                "false": "否",
                "null": ""
            },
            "buttonTips":{
                "activeNode":"条件：当前节点的状态为维护中",
                "maintenance":"条件：",
                "maintenance_1":"1.此节点处于使用中，状态为配置成功且健康状态为健康",
                "maintenance_2":"2.当前集群中不存在过程状态的节点",
                "maintenance_3":"3.除去勾选节点的剩余节点中，处于使用中，状态为配置成功且健康的节点的个数需大于或者等于3",
                "forceDelete":"条件：当前处于多数据中心，数据中心个数大于1，且当前数据中心集群中的节点数小于或者等于3",
            },
            "healthStaus": {
                "status": "健康状态",
                "true": "健康",
                "false": "N/A",
                "repair": "维护",
                "NULL": "N/A",
                "null": "N/A",
                "undefined": "N/A",
                "UNDEFINED": "N/A",
                "": "N/A"
            },
            "hypervisorHostname": "物理主机名",
            "hostIPMI":"物理机IPMI信息",
            "ipmiUser":"用户名",
            "ipmiPassword":"密码",
            "checkIPIM":"验证IPMI信息",
            "regionName": "数据中心",
            "regionName2":"Region名称",
            "management_IP": "管理网IP",
            "lastTime": "创建时间",
            "useStatus": "是否使用",
            "errorArea":"故障域",
            "Status": "状态",
            "useNodesComputeha":"启用多节点高可用架构",
            "initializationConfiguration":"初始化配置",
            "nodePromotion":"节点晋升",
            "virtualResourceNode":"虚拟资源节点",
            "kubernetsNode":"容器节点",
            "sureTwoNodePromote" :"节点晋升过程中两节点高可用功能将不可用，且晋升过程不可逆。",//1
            "sureThirdNodePromote":"您确认晋升该节点吗？",
            "init":{
                "titleTips":"提示信息",
                "tipsInfo_1":"1. 添加节点时会重启平台相关服务，会影响虚拟机、镜像或硬盘的创建、删除和云主机的迁移等功能， 请确保当前没有相关操作。",
                "tipsInfo_2":"2. 确保分布式存储的健康状态为HEALTH_OK",
                "tipsInfo_3":"3. 添加节点会触发ceph平衡，可能导致操作耗时较长。",
                "tipsInfo_4":"4. 如果对接了通用存储，请确保通用存储的磁盘已经挂载到新增节点。",
                "tipsInfo_5":"5. 新增节点如果超过30分钟还没初始化完成，请排查节点是否有故障。",
                "initConfig":"初始化配置",
                "cartConfig":"网卡配置",
                "setted":"已设置",
                "notSet":"未设置",
                "netCheck":"网络检查",
                "diskConfig":"硬盘配置",
                "bindAndSaveCard":"请确保所有网络都被绑定网卡并保存网卡配置!",
                "cardSaveSuccess":"网卡配置保存成功!",
                "in":"在",
                "toCardConfig":"上网卡配置",
                "toDiskConfig":"上硬盘配置",
                "bondCardHadUsed":"网卡已被使用,若要做bond操作请先解除使用!",
                "sameCardRate":"绑定的网卡速率必须一致!",
                "cardHadUsed":"网卡已被使用!",
                "bondMustHadTwoCard":"bond至少包含2张网卡才能被使用!",
                "cardNumber":"网卡数量",
                "dragHereRecombine":"将网卡拖动至此处创建新组合",
                "clusterNet":"集群网",
                "useDragToCombine":"使用拖拽移动组合",
                "manageNet":"管理网",
                "storageNet":"存储网",
                "tanentNet":"租户网",
                "publicNet":"业务网",
                "finishAndSaveCardConfig":"请先完成并保存网卡配置",
                "laterNetCheckWasteThreeMinute":"请稍后，网络检测预计耗时3分钟",
                "startCheck":"开始检查",
                "hostName":"主机名",
                "cardConfigFail":"网络配置失败！",
                "diskSaveSuccess":"硬盘配置保存成功",
                "finishAndSaveCardAndDiskConfig":"请先完成并保存网卡配置和磁盘配置",
                "finishAndSaveCardConfig":"请先完成并保存网卡配置",
                "finishAndSaveDiskConfig":"请先完成并保存磁盘配置",
                "confirmLocalVolumeSpaceFull":"请确保云主机本地盘空间不为空，再操作！",
                "confirmSharedVolumeSpaceFull":"请确保通用共享存储池不为空，再操作！",
                "confirmCephVolumeSpaceFull":"请确保ceph磁盘管理数据盘不为空，再操作！",
                "confirmInsAndCephVolumeSpaceFull":"请确保云主机本地盘空间和ceph磁盘管理数据盘不为空，再操作！",
                "availableDisks":"可用硬盘",
                "diskName":"硬盘名称",
                "status":"状态",
                "healthy":"健康",
                "unhealthy":"不健康",
                "diskType":"硬盘类型",
                "diskCapacity":"硬盘容量",
                "insLocalVolSpace":"云主机本地盘空间",
                "cephDisk":"ceph磁盘管理",
                "journalDisk":"日志盘",
                "cacheDisk": "缓存盘",
                "dataDisk":"数据盘",
                "save":"保存",
                "nextStep":"下一步",
                "finished":"完成",
                "multiRole":{
                    "title1":"提示信息及角色选择",
                    "title2":"提示信息",
                    "tips1":"请为节点选择一个角色",
                    "tips2":"您必须对新添加的节点赋予一个合适的角色",
                    "tips3":"请保证集群中控制节点的个数为奇数个",
                    "tips4":"首次添加网络节点,需要停用原来控制节点的网络服务,请联系运维人员进行操作",
                    "tips5":"请先选择角色",
                    "role1":"控制角色",
                    "role2":"网络角色",
                    "role3":"存储角色",
                    "role4":"消息队列角色",
                    "role5":"计算角色",
                }
            }
        },
        "recycle": {
            "updatetime": "删除时间",
            "name": "资源名称",
            "eliminate": "清除",
            "restore": "恢复",
            "forceDelete": "强制删除",
            "thoroughlyDelete": "彻底删除",
            "deltip": "回收站的云主机保存时间为{{time}}小时，过期将会自动删除",
            "deltcs": "回收站的数据盘保存时间为{{time}}{{unit}}，过期将会自动删除",
            "deltdb": "回收站的云数据库保存时间为{{time}}小时，过期将会自动删除",
            "table": {
                "status": {
                    "unrecognized": "软删除",
                    "soft_deleted": "软删除",
                    "soft-delete": "软删除",
                    "restoring": "恢复中",
                    "deleting": "清除中",
                    "active": "运行",
                    "deleted": "删除",
                    "error":"错误",
                    "soft-deleting":"删除中"
                }
            },
            "diskName":"数据盘名称",
            "config":"配置(GB)",
            "day":"天",
            "hour":"小时"
        },
        "storage": {
            "device": {
                "caching":"缓存盘",
                "logDisk":"日志盘",
                "stName": "存储名称",
                "createtime": "创建时间",
                "operation": "操作",
                "topool": "查看资源池",
                "pool": "资源池",
                "deviceDetail": "存储详情",
                "ava_st_feature": "支持存储特性",
                "pool_capacity": "资源池容量",
                "available_capacity": "可用容量",
                "newTitle": "添加存储",
                "storagefeatures": "存储特性",
                "compression": "压缩",
                "rsize": "精简配置",
                "easytier": "分层",
                "hyperswap": "双活",
                "stIp_ph": "请输入IP地址",
                "userName_ph": "请输入用户名",
                "password_ph": "请输入密码",
                "storage_device_ph": "请选择存储设备",
                "storage_device_ph2": "请输入存储设备名称",
                "storage_pool_ph": "请输入存储池名称",
                "stIp": "管理IP地址",
                "userName": "用户名",
                "password": "密码",
                "storage_device": "存储设备",
                "storage_pool": "存储池",
                "interface_ph": "请输入存储接口",
                "interface": "存储接口",
                "Predefined": "预定义存储",
                "custom": "自定义存储",
                "editTitle": "编辑存储"

            },
            "features": {
                "ftname": "特性名称",
                "description": "描述信息",
                "ftname_ph": "请输入特性名称",
                "description_ph": "请输入描述信息",
                "creattime": "创建时间",
                "newTitle": "新建特性",
                "editTitle": "编辑特性"
            },
            "voltype": {
                "vtname": "卷类型",
                "vtname_ph": "请选择特性名称",
                "description": "描述信息",
                "creattime": "创建时间",
                "newTitle": "新建卷类型",
                "devicename": "选择存储设备",
                "choosestpool": "选择存储池",
                "devicename_ph": "请选择存储设备",
                "choosestpool_ph": "请选择存储池",
                "choosetype": "选择卷类型"
            },
            "pool": {
                "spname": "存储资源池名称",
                "capacity": "容量",
                "free_capacity": "可用容量",
                "status": "状态",
                "online": "在线",
                "offline": "不在线",
                "vdisk_count": "数量",
                "state":{
                        "0":"创建中",
                        "1":"创建失败",
                        "2":"可用",
                        "3":"不可用",
                        "4":"删除中",
                        "5":"删除失败",
                        "6":"删除成功",
                        "7":"全部"
                  }
            }
        },
        "cvmview": {
            "pro_ins": "项目云主机",
            "pro_quota_info": "项目配额详情",
            "alarm_event": "告警事件",
            "view_all": "查看全部",
            "event_no": "该项目下暂无告警事件",
            "pro_res_info": "项目资源详情",
            "user_list": "用户列表",
            "ins_num": "云主机数量：",
            "net_num": "网络数量：",
            "router_num": "路由器数量：",
            "volume_num": "云硬盘数量：",
            "used": "已使用",
            "normal": "正常：",
            "abnormal": "异常：",
            "cpu_unit": "CPU核(个)",
            "ram_unit": "内存(GB)",
            "ram_unitTb": "内存(TB)",
            "snap_unit": "快照(个)",
            "fip_unit": "公网IP(个)",
            "view_more_quotas": "查看更多配额",
            "quota_details": "配额详情",
            "quota_name": "配额名称",
            "total_quota": "总配额",
            "used_title": "已使用",
        },
        "fileStorage":{
            "mount":"Liunx挂载命令",
            "noCommand":"暂无命令",
            "theMountCommand":"挂载命令",
            "moreIp":"多个IP地址换行隔离",
            "inputPath":"请输入路径",
            "CapacityQuotaplaceholder":"如不限制容量请留空",
            "totalCapacityQuota":"总容量配额",
            "inputNumber":"请输入正整数",
            "section1~10000":"输入数值不在1~10000之间",
            "clientIP":"客户端IP",
            "selectStoragePool":"请选择存储池",
            "pathfile":"路径必须以/开头，支持a-z,A-Z,0-9,_,-,.,空格等字符,空格不能在/后以及末尾,末尾不以/结束",
        },
        "cloudDatabase": {
            "databaseName":"数据库名称",
            "databaseType":"数据库类型",
            "status":"状态",
            "imageName":"镜像名称",
            "privateIp":"私网IP",
            "clusterIp":"集群IP",
            "publicIp":"公网IP",
            "operateSystem":"操作系统",
            "hostName":"物理主机",
            "hostBind":"高可用状态",
            "createTime":"创建时间",
            "flavor":"数据库规格",
            "systemDisk":"系统盘",
            "dataDisk":"数据盘",
            "ipAddress":"IP地址",
            "cloudDatabaseDetail":"云数据库详情",
            "table": {
                "status": {
                    "all":"全部",
                    "active":"运行",
                    "shutoff":"关机",
                    "error":"错误",
                    "paused":"暂停",
                    "suspended":"挂起"
                }
            },
            "tipMsg":{ 
                "start":"您确定要启动云数据库吗？",
                "stop":"您确定要对云数据库实施关机操作吗？",
                "reboot":"您确定要重启云数据库吗？",
                "reset":"该操作仅限于运维操作，请在重置前确认云数据库状态",
                "forcedelSever":"您确定要清除云数据库吗？",
                "restoreSever":"您确定要恢复云数据库吗？",
                "forceDel":"您确定要强制删除云数据库吗？",
                "startServerTip":"云数据库存在正在备份还原或快照回滚的云硬盘，无法开机"
            },
            "createDatabase":{
                "create":"新建数据库",
                "sureCreate":"确认新建",
                "basicInfo":"基本信息",
                "networkSet":"网络设置",
                "storeSet":"存储设置",
                "type":"数据库类型",
                "version":"数据库版本",
                "name":"数据库名称",
                "operateSystem":"操作系统",
                "image":"镜像",
                "selectSpecific":"选择规格",
                "cpu":"cpu",
                "ram":"内存",
                "flavorTable":{
                    "sockets":"插槽数",
                    "cores":"核心数",
                    "threads":"线程数"
                },
                "placeholder":{
                    "databaseNameInput":"请输入数据库名称"
                },
            }
        },
        "errors": {
            "selectEthernetType":"请选择以太网类型",
            "lableMax10":"云主机最多绑定10个标签",
            "isDelete":"您是否删除选中的内容?",
            "IPillegal":"IP 不合法",
            "IPrepetition":"IP 重复输入",
            "maxLength":"描述信息最多255个字符",
            "apiSecretKey255":"密钥长度超过255",
            "apiPrivateKey255":"私钥长度超过255",
            "portsection65535":"端口号不在1-65535之间",
            "maxLength1024":"最大长度不超过1024",
            "pathError":"路径格式不正确",
            "portUse":"该端口已被占用",
            "input1_10":"请输入1-10的正整数",
            "http":"不能包含http://和https://",
            "specificsymbol": "仅支持英文字母、数字或汉字， +、-、=、.、_、:、/、@、()、[]、（）、【】",
            "quota_domain": "参数应不小于项目配额",
            "quota_domain2": "参数应小于或等于6位数",
            "quota_project": "参数应不大于部门配额",
            "required": "*必填项",
            "nameExist": "名称已存在",
            "httpCheck": "请以 / 开头",
            "urlCheck": "请输入正确的URL地址",
            "integer": "请输入正整数",
            "atleast": "请输入大于等于1的正整数",
            "atleast_0": "请输入大于等于0的整数",
            "atleast_-1": "请输入大于等于-1的非零整数",
            "maxinteger": "请输入小于等于10的正整数",
            "integerAndZero": "请输入0或者正整数",
            "noRepeatDepName": "该部门名称已经被占用，请重新输入",
            "correctUrl":"请输入正确的url",
            "rightRange":"正确的数值范围为：",
            "limitM":"请至多输入64位",
            "mailboxLayout":"请输入正确的邮箱格式，多个邮箱以英文分号分隔",
            "mailboxMax10":"邮箱最多支持10个",
            "maxlength200":"文本框最多200字符",
            "maxlength": "请至多输入32位",
            "maxlength30": "请至多输入30位",
            "maxlength20": "请至多输入20位",
            "maxlength16": "请至多输入16位",
            "maxlength255": "请至多输入255位",
            "maxlength1024": "请至多输入1024位",
            "maxlength256": "请至多输入256位",
            "maxlength128": "请至多输入128位",
            "maxlength100": "请至多输入100位",
            "maxlength127": "请至多输入127位",
            "maxLength_64":"最大长度64位",
            "minlength": "请至少输入8位",
            "maxlengthMid": "请至多输入100位",
            "sizelimit": "请输入1~100000的数,小数需保留两位",
            "sizelimitZero": "请输入0~100000的数,小数需保留两位",
            "maxlengthbig": "请至多输入",
            "minlengthsmall": "请至少输入",
            "lenghtUnit": "位",
            "noSpecial": "只能输入字母、数字、汉字、横线以及下划线",
            "noZhSpecial": "只能输入字母、数字、横线以及下划线",
            "nameLimit": "只能输入字母、数字、汉字、横线、下划线以及点",
            "imgcheck": "请至多输入255位",
            "toInput": "请输入",
            "toNum": "的正整数",
            "cpuMem":"不可以只选择CPU或者只输入内存",
            "limit_1000": "请输入小于或等于1000的值",
            "pswdNotEqual": "两次输入的密码不同",
            "phone": "请输入正确的手机号",
            "email": "请输入正确的邮箱",
            "description28": "描述信息不能超过28个字符",
            "limit_volume": "请输入不大于1000的正整数",
            "description": "描述信息不能超过80个字符",
            "menuRequire": "请至少选择一项菜单",
            "limit_0_100": "请输入0~100的整数",
            "limit_1_10$": "请输入1~10的整数",
            "limit_1_100": "请输入1~100的整数",
            "limit_10_1000":"请输入10-1000的整数",
            "limit_10_10000":"请输入10-10000的整数",
            "limit_1_10000": "请输入1~10000的整数",
            "limit_1_9999": "请输入1~9999的整数",
            "limit_1_99": "请输入1~99的整数",
            "limit_0_9": "请输入0~9位的正整数",
            "limit_1_9": "请输入1~9位的正整数",
            "limit_1_1": "请输入小于等于1的正整数",
            "limit_1_10": "请输入小于等于10的正整数",
            "limit_200_204": "请输入200~204的整数",
            "limit_65_95": "请输入65~95的整数",
            "limit_betys": "请输入0~102400的整数",
            "emailPatternError": "邮箱格式错误",
            "phonePatternError": "手机号码格式错误",
            "limit_1_65535": "请输入1-65535的正整数",
            "limit_1_65500": "请输入1-65500的正整数",
            "start-biger-end":"端口终止值必须大于起始值",
            "limit_1_65522": "请输入大于1小于65522的正整数",
            "limit_-1_255": "请输入-1到255之间的整数",
            "limit_-100_100": "请输入-100到100的整数",
            "limit_-10_10": "请输入-10到10的整数",
            "maxlength_255": "请至多输入255个字符",
            "maxlength_200": "请至多输入200个字符",
            "noChinese": "只能输入数字、字母或下划线，且只能以字母开头",
            "noInputCN": "不能输入汉字",
            "input_0_f":"输入0~ffff",
            "ip_inputError": "IP地址输入不正确",
            "limit_1_255": "输入1~255的整数",
            "limit_0_255": "输入0~255的整数",
            "limit_1_29": "输入1~29的整数",
            "limit_8_29": "输入8~29的整数",
            "limit_8_32":"输入8~32的整数",
            "limit_1_30": "请输入1~30的整数",
            "limit_1_32": "请输入1~32的整数",
            "limit_65535": "请输入1-65535的整数",
            "limit_morethan_1": "请输入1~最大cpu配额的整数",
            "limit_1_131072": "请输入1~131072的整数",
            "limit_ram_morethan_1": "请输入1~最大内存配额的整数",
            "limit_1_500": "请输入1~500的整数",
            "limit_1_720": "请输入1~720的整数",
            "snap_1_31": "取值范围为1~31的整数",
            "snap_0_59": "取值范围为1~59之间整数",
            "snap_1_99": "取值范围为1~99之间整数",
            "snap_0_168": "取值范围为1~168之间整数",
            "ram_min_max": "内存容量应为{{min}}~{{max}}之间的整数(单位:GB)",
            "ram_min": "内存容量范围为{{min}}~{{max}}(单位:GB)",
            "vcpus_min_max": "CPU核数应该介于{{min}}~{{max}}之间(单位:核)",
            "vcpus_min": "CPU核数范围为{{min}}~{{max}}(单位:核)",
            "ipAddrPatternError": "请输入正确格式的ip地址,如:10.10.1.0",
            "ipAddrPatternError1_254": "请输入正确格式的ip地址,如:10.10.1.1且末位组范围为1-254",
            "gtIpError": "结束IP地址必须大于起始IP地址",
            "mac_addr": "请输入正确的mac地址",
            "gtTime": "结束时间必须大于或等于起始时间",
            "timePattern": "请输入正确的时间格式,形如:yyyy-mm-dd",
            "timeSecondPattern": "请输入正确的时间格式,形如:yyyy-mm-dd hh:mm",
            "repeatDefault": "安全组default已存在，同一个项目下只能有一个default安全组",
            "existedVlanId": "该VLAN ID已被使用,请重新输入",
            "passmaxlength": "请至多输入30位",
            "emailmaxlength": "请至多输入50位",
            "input_gtorequit": "请输入大于或等于",
            "less_65535_integer": "小于65535的整数",
            "noRepeatProName": "该项目名称已经被占用，请重新输入",
            "repeatContractGroupName": "同名联系人组已存在，请重新输入",
            "integer_negative_one": "请输入大于等于-1的整数",
            "zerototen": "请输入1~10的整数",
            "ram_min_max_beyond": "所选配置必须高于之前的配置",
            "evenGt_1": "请输入1或大于1的偶数",
            "integerGt_1": "请输入大于0的数，可带一位小数",
            "extra_hw_tip": "选择hw:cpu_cores 、hw:cpu_threads、hw:cpu_sockets时，三个值相乘的结果应大于等于1小于等于云主机规格的CPU核数",
            "cidrPatternError": "CIDR输入格式错误",
            "emailServer": "请输入正确的邮箱服务器",
            "hostnameValidate": "只能输入数字、字母或横线，且只能以字母开头",
            "vmNamevalidate": "只能输入字母、数字、汉字、横线",
            "snapNamevalidate": "只能输入字母、数字、汉字、横线以及点",
            "securityGroupName_check": "*安全组名已存在",
            "balanceName_check": "*负载均衡名已存在",
            "storageName_check": "存储名称已存在",
            "limit_1_4000": "请输入1~4000的整数",
            "ramGtMax": "内存值应小于或等于最大内存",
            "addressValidate": "地址不能以 / 结尾",
            "ltCurrentTimeError": "首次执行时间应大于等于当前时间",
            "source_subnet_address":"请输入子网地址，例如：192.168.0.1/24",
            "source_ip_address":"请输入源IP地址，例如：192.168.0.1",
            "direction_iP_address":"请输入目的IP地址，例如：192.168.0.1",
            "limit_1_max": "输入1~{{maxSize}}的整数",
            "valLetterNum": "名称只能为小写字母、数字、中划线",
            "ipscope":"格式不正确",
            "limit_min_max":"请输入{{min}}~{{max}}的整数",
            "limit_special_character":"特殊字符只允许'-''_'，其它字符无限制",
            "limit_least_2":"最少输入两个字符",
            "maxlengthzf128":"请至多输入128个字符",
            "role_require":"角色名称为必填项",
            "port":"请输入0-65535的正整数",
            "ip":"ip格式不正确，例:192.168.1.2",
            "limit_1_100":"请输入1-100的正整数",
            "limit_1_1000":"请输入1-1000的正整数",
            "fileSizeless_10g":"文件大小不能超过10G",
            "lim_lowercase_number":"只能输入小写字母、数字、中划线"
        },
        "metaMsg": {
            "hint":"注：挂载命令中 localdir 为本地要挂载的路径名称",
            "noFixedIP": "操作失败：所选的网络没有可用私网IP",
            "noHostAvail": "操作失败：未找到拥有足够资源的节点",
            "Bindingportfail": "操作失败：绑定端口失败",
            "noharddisk": "操作失败：没有找到可用存储资源",
            "buildInstancefail": "操作失败：创建云硬盘失败",
            "buildInstancefail_2": "操作失败：系统盘小于所请求虚拟机镜像大小",
            "InsufficientMemory": "操作失败：目标节点内存不足",
            "InsufficientVcpu": "操作失败：目标节点CPU不足",
            "internalError": "操作失败：服务器错误",
            "volumeError_1": "操作失败：没有目标设备",
            "volumeError_2": "操作失败：不能从瞬态云主机卸载云硬盘",
            "volumeError_3": " 请检查虚拟机系统状态是否异常",
            "hasQuickError":"系统盘上有快照,无法还原",
            "backupError1":"创建备份失败，当前卷状态不符合预期状态",
            "backupError2":"创建备份失败，当前备份状态不符合预期状态",
            "backupError3":"备份创建失败，旧块设备哈希值与当前块设备哈希值不符，在上一次备份中块大小哈希值发生改变，请进行全量备份。",
            "backupError4":"备份创建失败，源卷大小同最近的一次备份时相比发生了改变。请进行全量备份",
            "backupError5":"备份创建失败，备份卷元数据失败",
            "domainInvalid":"主机未运行"
        },
        "bandlimit": {
            "limitType": "限制类型",
            "customBand": "用户自定义带宽",
            "SpeedLimited": "限速模式",
            "fixBand": "固定带宽",
            "IP_bandwidth": "公网IP带宽",
            "Switchboard": "云主机网卡最大带宽",
            "Router_bandwidth": "路由器带宽",
            "tips": "请在关闭限速模式前，将已创建的云主机列表中网卡的带宽值改为无限制。",
            "tipsBtn": "对已创建好的带宽无影响。",
            "paramDesc":"带宽限制"
        },
        "statusCode": {
            "00500":"复制成功",
            "01300401":"csp服务对接失败，该服务已存在",
            "01300501":"csp服务删除失败，该服务正在使用中",
            "01300101":"csp访问禁止，该密钥没有操作权限",
            "01300201":"csp访问禁止，该用户或者路径已存在",
            "01170504":"负载均衡资源在使用中，无法切换",
            "01280101":"邮件发送失败",
            "02130901500": "证书不可用",
            "15010103":"用增强型负载均衡安全组开始名称命名，创建失败",
            "15050301":"监听器已不存在",
            "15040101":"上传证书失败",
            "15040102":"上传容器证书失败",
            "15040103":"删除容器证书失败",
            "15040104":"删除证书失败",
            "15040105":"证书使用中",
            "15040106":"更新证书失败",
            "15040107":"证书名字重复",
            "15050201":"存在负载均衡，关闭开关失败",
            "login0": "登录成功",
            "login1": "登录失败",
            "0": "操作成功",
            "1": "操作失败",
            "-1": "服务器异常",
            "500": "服务器错误",
            "404": "请求地址错误",
            "010": "验证IPMI信息成功",
            "011": "验证IPMI信息失败",
            "02340203":"用户名称已存在",
            "02072003":"创建负载均衡失败",
            "02072001":"负载均衡存在监听器,删除增强型负载均衡失败",
            "02072002":"删除负载均衡失败",
            "02330111":"文件存储路径已存在",
            "02320101":"正在创建网桥中，请勿重复操作",
            "01230219": "存在ceph对象存储，不允许删除ceph块存储",
            "11010101": "Qcloud无法登录",
            "02072004": "未找到增强型负载均衡镜像，请联系管理员开启增强型负载均衡",
            "01100218": "当前集群状态不可操作",
            "01300401": "服务对接失败，该服务已存在",
            "01100223": "数据中心名称重复",
            "01290103": "ceph对象存储已对接，无法重复对接",
            "01290104": "ceph块存储开启了缓存加速，ceph对象存储无法共用块存储",
            "01290105": "对象存储存在桶，无法删除，请先清除所有桶",
            "01230212":"该存储迁移过镜像无法删除",
            "02054101":"快速创建安全组失败",
            "02054102":"快速创建安全组失败:安全组规则创建失败",
            "00010101": "获取资源认证失败,请重试",
            "00010107": "获取资源认证失败,请重试",
            "00010105": "登录过期，请重新登录",
            "00010102": "请求未带token",
            "01100119": "授权不通过，更新授权后重试",
            "01030706": "登录失败（错误登录次数超过限制次数）",
            "01030707": "登录失败（账号已登录）",
            "01030710": "平台正在两节点升级，请稍后登陆使用",
            "02110301": "查询自定义镜像列表失败",
            "01080306": "当前所输入密码与原密码一致",
            "01080304": "修改用户密码失败,重置keystone密码失败",
            "01080303": "原密码输入错误",
            "01080302": "密码修改失败",
            "01180401": "修改密码过于频繁",
            "9001008":  "请勿重复操作",
            "01300601": "csp服务访问失败，API访问密钥不存在或未启用",
            "02340101": "rgw服务未安装",
            "02340201": "用户创建失败",
            "02340301": "获取用户列表失败",
            "02340401": "更新配额失败",
            "02340501": "获取用户失败",
            "02340601": "删除用户失败",
            "02340602": "默认账号不允许删除",
            "02340701": "创建秘钥失败",
            "02340801": "删除秘钥失败",
            "01270101": "调整存储池比例失败",
            "01270102": "存储池比例总和不为1",
            "01100119": "授权不通过，更新授权后重试",
            "01270103": "存在rgw的存储池",
            "01270104": "不存在rgw的存储池",
            "01270105": "存储池比例总和不为1",
            "01270201": "获取存储池比例失败",
            "01260501": "修改对象存储配置失败",
            "01260701": "对象存储swift配置错误",
            "01260601": "删除对象存储配置失败",
            "01260101": "保存对象存储配置失败",
            "01260201": "获取对象存储配置列表失败",
            "01020102": "部门名称重复",
            "01260301": "服务安装失败",
            "01260401": "未对接ceph",
            "01250101": "范围冲突",
            "01100108": "找不到节点",
            "02040802": "qos绑定卷类型失败",
            "01100110": "当前集群中存在异常节点",
            "01100214": "no log server ip",
            "01100215": "no kapacitor ip",
            "01100202": "集群配置信息不存在",
            "02251801": "编辑物理机描述失败",
            "01150201": "集群中有其他节点正在操作，请等待操作完成",
            "01150202": "更新节点状态失败",
            "01150302": "集群分布式控制平面处于不健康状态",
            "01150301": "新增节点失败",
            "01150401": "节点删除失败",
            "01150402": "集群剩余节点数不足",
            "01150403": "节点还有未删除的虚拟机",
            "01150405": "获取集群ceph信息失败",
            "01150406": "不是所有pg都处于active+clean状态",
            "01150407": "删除节点将导致ceph可用量不足",
            "01150408": "删除节点将导致剩余在线节点的数量低于ceph pool副本数最大值",
            "01150410": "用户名密码错误",
            "01150501": "节点维护失败",
            "01150601": "所选节点不在维护中，无需激活",
            "01150602": "节点激活失败",
            "01150701": "所选节点无法重试上一次操作",
            "01150702": "重试节点操作失败",
            "01150801": "获取节点健康状态失败",
            "01100401": "节点删除失败",
            "02010101": "获取列表失败",
            "2010101": "网络检测参数错误",
            "2010102": "网络检测失败",
            "02010201": "获取云主机详情失败",
            "02010301": "创建失败",
            "02010302": "所选配置云硬盘大小不足",
            "02010401": "关机失败",
            "02010402": "云主机状态不正确",
            "02010501": "强制关机失败",
            "02010601": "启动云主机失败",
            "02010701": "重启云主机失败",
            "02010801": "修改云主机失败",
            "02010901": "删除云主机失败",
            "02011001": "更改失败",
            "0125010": "下发配置失败",
            "02011101": "制作失败",
            "02011201": "加载防火墙失败",
            "02011301": "移除防火墙失败",
            "02011401": "创建失败",
            "02011501": "回滚失败",
            "02011601": "显示失败",
            "02011701": "添加网卡失败",
            "02011801": "移除网卡失败",
            "02011901": "热迁移失败",
            "02012001": "获取云主机类型失败",
            "02012101": "获取密钥对列表失败",
            "02012201": "获取失败",
            "02012301": "获取云主机所有网卡失败",
            "02012401": "创建密钥对失败",
            "02012402": "密钥对已存在",
            "02012501": "获取失败",
            "02012601": "获取flavor特别设置列表失败",
            "02012701": "创建flavor特别设置列表失败",
            "02012801": "删除flavor特别设置列表失败",
            "02012901": "获取flavor访问列表失败",
            "02013001": "添加project到flavor失败",
            "02013101": "从flavor中移除project失败",
            "02013201": "创建云主机类型失败",
            "02013301": "删除云主机类型失败",
            "02013401": "删除密钥对失败",
            "02013501": "查询失败",
            "02013901": "热迁移失败",
            "02014001": "备份失败",
            "02014401": "调整云主机大小失败",
            "02014501": "确认调整云主机大小失败",
            "02014601": "取消调整云主机大小失败",
            "02014701": "暂停云主机失败",
            "02014801": "挂起云主机失败",
            "02014901": "暂停恢复云主机失败",
            "02015001": "挂起恢复云主机失败",
            "02015101": "获取云主机启动log失败",
            "02015201": "修改mac地址失败",
            "02015202": "修改云主机mac地址失败,mac地址冲突",
            "02015301": "恢复云主机失败",
            "02015401": "强制删除云主机失败",
            "02015501": "回收站列表失败",
            "02015601": "疏散云主机失败",
            "02015701": "获取当前云主机的配置失败",
            "02016001": "强制删除回收站中云主机失败",
            "02200400": "创建弹性伸缩集群失败",
            "02020101": "获取列表失败",
            "02020201": "创建失败",
            "02020301": "修改镜像失败",
            "02020401": "镜像删除失败",
            "02020402": "镜像使用中无法删除",
            "02020501": "获取镜像详情失败",
            "02020601": "镜像文件不能为空",
            "02020602": "镜像文件上传失败",
            "02030101": "获取列表失败",
            "04030104": "编辑工单失败",
            "04030105": "重新打开工单失败",
            "02030201": "操作的云硬盘不存在",
            "02030202": "获取云硬盘详情失败",
            "02030301": "删除云硬盘失败",
            "01150404": "禁用节点上的计算服务失败",
            "02030302": "云硬盘有快照无法删除",
            "02030401": "创建云硬盘失败",
            "02030501": "更新云硬盘失败",
            "02030601": "强制删除云硬盘失败",
            "02030701": "云硬盘扩容失败",
            "02030702": "云硬盘扩容时设置的容量必须大于原先的容量",
            "02030703": "没有足够的资源池容量来扩容",
            "02030801": "提供的设备路径或者云硬盘无效",
            "02030802": "云主机无效",
            "02030803": "加载云硬盘失败",
            "02030901": "根设备在该硬盘，禁止卸载",
            "02030902": "卸载硬盘失败",
            "02031201": "云硬盘上传到镜像失败",
            "02031301": "获取云硬盘类型列表失败",
            "02031401": "重置云硬盘状态失败",
            "02040101": "获取快照列表失败",
            "02052603": "删除端口失败",
            "01150409": "节点中还有回收站中未清空的虚拟机",
            "03018001": "获取节点列表失败",
            "01150303": "集群IP已用尽，请联系管理员",
            "02040201": "删除快照失败",
            "02040301": "操作的快照不存在",
            "02040302": "查看快照详情失败",
            "02040401": "创建快照失败",
            "02040501": "从快照创建云硬盘失败",
            "02040601": "获取存储池详情列表失败",
            "02040602": "云硬盘快照回滚失败",
            "02040603": "云硬盘快照更新失败",
            "02050101": "获取列表失败",
            "02050102": "获取外部网络列表失败",
            "02050103": "获取物理网络列表失败",
            "02050201": "发生冲突",
            "02050202": "其他原因创建失败",
            "02050204": "创建网络失败。在物理网络：tenant上，该VLAN ID被使用。",
            "02050301": "编辑网络失败",
            "02050302": "修改网络共享设置失败，有其他租户正在使用该网络",
            "02050401": "该网络正在被使用，无法删除",
            "02050501": "获取网络详情列表失败",
            "02050601": "获取列表失败",
            "02050602": "获取网络子网列表失败",
            "02050701": "创建子网失败",
            "02050702": "创建子网时地址池格式有误",
            "02050703": "创建子网失败，网关IP和分配地址池中的IP发生了冲突",
            "02050704": "创建子网失败，创建的子网与所选网络上已绑定的子网网段有重叠",
            "02050705": "无法完成该操作，主机路由数超过限制",
            "02050706": "无法完成该操作，DNS域名服务器数量超过限制",
            "02050707": "IP池无效",
            "02050708": "子网的IP池有重叠",
            "02050709": "子网的IP池超出了子网的cidr",
            "02050710": "网关IP与IP分配池冲突",
            "02050711": "网关IP已经被端口使用，无法进行创建",
            "02050712": "CIDR是无效的",
            "02050713": "属于同一网络的子网必须从相同的子网池中分配",
            "02050714": "创建该子网的信息提交失败，删除该子网",
            "02050715": "无法分配子网",
            "02050716": "非法子网池关联",
            "02050717": "非法子网池关联",
            "02050718": "未指定最小的网络子网池前缀",
            "02050719": "无法完成对该子网的操作，该子网上有一个或多个端口正在被使用",
            "02050720": "环回IP类型的子网不支持开启dhcp",
            "02050721": "多播IP类型的子网不支持开启dhcp",
            "02050722": "子网的前缀长度不允许开启dhcp",
            "02050723": "网关在该子网上无效",
            "02050724": "dns地址解析失败",
            "02019502": "请先清空系统盘快照",
            "02050725": "VLAN ID被占用,创建网络失败",
            "02050801": "编辑子网失败",
            "02050901": "删除子网失败",
            "02051001": "获取列表失败",
            "02051101": "发生冲突",
            "02051102": "其他原因创建失败",
            "02051103": "外部网络已创建路由器",
            "02051201": "获取路由器详情失败",
            "02051301": "解除关联失败",
            "02051302": "路由器解除关联子网失败，该子网正在被一个或多个公网IP使用中",
            "02051401": "关联子网失败",
            "02051402": "路由器关联子网失败，子网网段有重叠。(同一租户的不同网络内,有IP地址重叠的两个子网不能使用同一个路由器)",
            "02051403": "不允许路由器子网地址与外部网关地址有重叠",
            "02051501": "删除路由失败",
            "02051502": "删除失败，路由器上存在端口",
            "02052202": "无状态自动配置子网不能修改或指定IP",
            "02059016": "部分防火墙规则启用成功",
            "02059017": "部分防火墙规则禁用成功",
            "02059401": "获取拓扑图信息失败",
            "02059404": "添加云主机可用外部物理网络失败",
            "02059407": "物理网络不存在",
            "02059601": "路由器网关删除失败",
            "02059602": "网关上的公网IP在使用中", //路由器上的云主机绑定有网关上的公网IP
            "02059603": "路由器存在端口转发规则，不能删除",
            "02059701": "路由器设置网关失败",
            "02051601": "获取公网IP列表失败",
            "02051701": "固定IP不在子网地址池内",
            "02051702": "其他原因失败",
            "02051703": "IP已被占用",
            "02051704": "网络可用IP地址不足",
            "02051705": "修改云主机密码失败",
            "02051801": "子网没有绑定路由或路由没有绑定外网",
            "02051802": "子网没有创建路由",
            "02090101": "获取虚机管理器信息失败",
            "02090201": "获取管理器数据失败",
            "02053201": "创建安全组规则失败",
            "02051901": "解绑失败",
            "02052001": "获取可用主机列表失败",
            "02052101": "删除公网IP失败",
            "02052201": "修改端口名失败",
            "02052212": "操作失败，IP地址在使用中",
            "02052301": "获取总带宽列表失败 ",
            "02052401": "获取端口详情失败",
            "02052601": "新建虚拟网卡失败",
            "02052701": "获取安全组失败",
            "01150304": "所选节点的系统镜像版本与集群不一致",
            "02052801": "创建路由器时无外部网络可用",
            "02052901": "创建安全组失败",
            "02053001": "更新安全组失败",
            "02053101": "删除安全组失败",
            "02053102": "安全组在使用中，不能删除",
            "02053301": "获取安全组规则失败",
            "02053201": "创建安全组规则失败",
            "02053401": "删除安全组规则失败",
            "02053501": "加载安全组失败",
            "02020801": "获取镜像上传缓存空间使用状况失败",
            "02020901": "镜像正在上传，清空缓存文件失败",
            "02021001": "用户退出上传中断",
            "02060001": "制作镜像中",
            "02060002": "制作镜像成功",
            "02060003": "备份中",
            "02060004": "备份成功",
            "02060005": "云主机强制关机中",
            "02060006": "云主机强制关机成功",
            "02100201": "创建主机集合失败",
            "02100301": "删除主机集合失败",
            "02100501": "设置主机集合元数据失败",
            "02100601": "获取可用域失败",
            "02100801": "从集合中移除主机失败",
            "02100802": "列出云主机安全组失败",
            "02110401": "该记录无法删除",
            "02110402": "自定义镜像删除失败",
            "02130101": "获取负载均衡器的监听器列表失败",
            "02130201": "获取负载均衡器的监听器详情失败",
            "02130301": "获取负载均衡列表失败",
            "02130401": "获取负载均衡器详情失败",
            "02130501": "获取负载均衡器状态失败",
            "02130601": "新建负载均衡器失败",
            "02130701": "修改负载均衡器失败",
            "02130801": "负载均衡器中存在其他资源，删除失败",
            "02130802": "删除负载均衡器失败",
            "02130901": "新建负载均衡器监听器失败",
            "02130901409": "监听器的端口已被占用",
            "02130902": "新建负载均衡器监听器失败",
            "02131001": "修改负载均衡器监听器失败",
            "02131101": "删除负载均衡器监听器失败",
            "02131201": "获取负载均衡器的资源池列表失败",
            "02131301": "获取负载均衡器的资源池详情失败",
            "02131401": "新建负载均衡器资源池冲突失败",
            "02131402": "新建负载均衡器资源池失败",
            "02131501": "修改负载均衡器资源池失败",
            "02131601": "删除负载均衡器资源池失败",
            "02131602": "删除资源池失败，请先清空资源池",
            "02131701": "获取负载均衡器的资源池的成员列表失败",
            "02131801": "获取负载均衡器的资源池的成员详情失败",
            "02131901": "新建负载均衡器资源池的成员失败",
            "02132001": "修改负载均衡器资源池的成员失败",
            "02132101": "删除负载均衡器资源池的成员失败",
            "02132201": "获取负载均衡检查器列表失败",
            "02132301": "获取负载均衡检查器详情失败",
            "02132401": "新建负载均衡检查器失败",
            "02132501": "修改负载均衡检查器失败",
            "02132601": "删除负载均衡检查器失败",

            "02150101": "该项目的namespace不存在, 并且自动创建它时失败",
            "02150201": "获取集群副本日志失败",
            "02150301": "获取集群副本失败",
            "02150302": "删除集群副本失败",
            "02150401": "创建新的rbd云硬盘失败：镜像名字已经存在！",
            "02150402": "创建新的rbd云硬盘失败：类型错误！",
            "02150403": "创建新的rbd云硬盘失败：参数错误！",
            "02150404": "创建新的rbd云硬盘失败：内部错误！",
            "02150405": "删除rbd云硬盘失败：内部错误！",
            "02150501": "获取容器集群失败！",
            "02150502": "删除容器集群失败！",
            "02150503": "容器集群动态伸缩失败！",
            "02150504": "创建容器集群失败！",
            "02150505": "获取容器集群详情失败！",
            "02150506": "容器集群滚动升级失败！",
            "02150507": "获取ceph集群信息失败！",
            "02150601": "获取容器服务列表失败！",
            "02150602": "删除容器服务失败！",
            "02150603": "获取已使用的端口失败！",
            "02150604": "创建容器服务失败！",
            "02150701": "获取k8s处于ready状态的node节点列表失败！",
            "02150702": "获取k8s的node节点列表失败！",
            "02150703": "增加k8s的node节点标签失败！",
            "02150704": "修改k8s的node节点标签失败！",
            "02150705": "删除k8s的node节点标签失败！",
            "02150706": "根据标签获取k8s的node节点失败！",
            "02150801": "harbor内部错误！",
            "02150802": "初始化harbor客户端失败！",
            "02150803": "获取harbor的project失败！",
            "02150804": "新建harbor的project失败！",
            "02150805": "修改harbor的project访问权限失败！",
            "02150806": "删除harbor的project失败！",
            "02150807": "获取harbor用户的密码失败！",
            "02150808": "获取镜像仓库列表失败！",
            "02150809": "删除镜像仓库失败！",
            "02150810": "获取镜像仓库的tag列表失败！",
            "02150811": "删除镜像仓库的tag失败！",
            "02150812": "获取镜像Manifests失败！",
            "02150813": "获取当前用户的id失败！",
            "02150814": "获取当前的项目详情失败！",
            "02150901": "请求错误：上传的不是文件！",
            "02150902": "上传 Dockerfile 失败！",
            "02150903": "上传 Dockerfile IO错误！",
            "02151001": "无法登陆 harbor！",
            "02151002": "请求中没有 Dockerfile！",
            "02151003": "请求中没有 repository 或者 tag！",
            "02151004": "上传的 Dockerfile 格式不对！",
            "02151005": "无法解压 Dockerfile 压缩包！",
            "02151006": "上传的 Dockerfile 的压缩包格式不对！",
            "02151007": "无法为镜像增加 label！",
            "02151008": "无法根据 Dockerfile build 镜像！",
            "02151009": "build 镜像时出现未知的错误！",
            "02151010": "build 镜像的参数错误！",
            "02151011": "无法 push 已经 build 完成的镜像！",
            "02151101": "该容器不存在！",
            "02151102": "cadvisor内部错误！",
            "02151103": "转换结果出错！",

            "01010101": "组织结构新增失败",
            "01010102": "部门名称已存在",
            "01010201": "组织结构删除失败",
            "01010301": "组织结构编辑失败",
            "01010401": "组织结构列表查询失败",
            "01010501": "组织结构明细查询失败",
            "01020101": "部门新增失败",
            "01020201": "部门删除失败",
            "01010202": "部门已绑定用户，无法删除",
            "01020203": "部门下存在项目，无法删除",
            "01020204": "部门下存在用户，无法删除",
            "01020301": "部门编辑失败",
            "01020401": "部门列表查询失败",
            "01020501": "部门明细查询失败",
            "01030701": "登录失败",
            "01030702": "登录失败（用户名或密码错误）",
            "01030709": "用户已被锁定",
            "01030710": "用户不存在或未绑定角色",
            "01060702": "切换数据中心失败",
            "01040101": "菜单列表查询失败",
            "01050101": "角色新增失败",
            "01050102": "角色名称重复，失败",
            "01050201": "角色删除失败",
            "01050202": "角色已绑定用户，删除失败",
            "01050301": "角色编辑失败",
            "01050401": "角色列表查询失败",
            "01050501": "角色明细查询失败",
            "01050601": "分配角色失败",
            "01050701": "查询角色与用户关系失败",
            "01050801": "分配菜单到角色失败",
            "01060101": "项目新增失败",
            "9001002": "启动节点与numa节点数不一致",
            "01060201": "项目删除失败",
            "01060202": "项目下包含资源删除失败",
            "01060203": "项目删除失败，回收站中包含软删除的云主机",
            "01060301": "项目编辑失败",
            "01060401": "项目列表查询失败",
            "01060501": "项目明细查询失败",
            "01070101": "角色列表查询失败",
            "01080101": "用户新增失败",
            "01080102": "授予domain中user某个角色失败",
            "01080103": "授予project中user某个角色失败",
            "01080104": "获取用户所在项目权限失败",
            "01080201": "用户删除失败",
            "01080301": "用户编辑失败",
            "01080401": "用户列表查询失败",
            "01080501": "用户明细查询失败",
            "01080601": "该用户已存在",
            "01080701": "编辑用户状态失败",
            "01080801": "多个权限赋予用户失败",
            "01080901": "获取用户的项目列表失败",
            "010801001": "登录失败",
            "010801101": "用户名为空",
            "01090101": "企业注册失败",
            "01090201": "账号已存在",
            "01090401": "登录失败（密码为空）",
            "01090301": "登录失败（验证码已失效，请重新获取）",
            "01090501": "邮箱为空",
            "01090601": "登录失败（登录名为空）",
            "01090701": "企业登录失败",
            "01090801": "登录失败（企业不存在）",
            "01090302": "登录失败（验证码错误）",
            "01030708": "登录失败（平台正在升级，请稍后登陆使用）",
            "01030708": "登录失败（用户已被锁定）",
            "01100101": "节点MAC不存在",
            "01100102": "节点已经注册",
            "01100103": "获取节点注册列表失败",
            "01100104": "修改节点主机名称失败",
            "01100105": "获取节点配置失败",
            "01100106": "更新节点配置状态",
            "01100107": "保存节点配置失败",
            "01100109": "修改节点使用状态失败",
            "01100201": "获取集群配置失败",
            "01100203": "修改集群名称失败",
            "01100204": "保存注册记录失败",
            "01100205": "查询注册记录失败",
            "01100206": "找不到region",
            "01100207": "获取企业region失败",
            "01100208": "添加企业region失败",
            "01100209": "tunnel错误",
            "01170102": "新增失败",
            "01170101": "新增参数名称重复",
            "01170201": "修改参数名称重复",
            "01170501": "编辑失败",
            "01190101": "获取快照列表失败",
            "01190102": "删除保留快照失败",
            "01190103": "获取硬盘失败",
            "01190104": "创建快照失败",
            "01190106": "从用户信息获取token失败",
            "01190107": "部门配额不足",
            "01190108": "项目配额不足",
            "01200101": "发送升级请求失败",
            "01200102": "平台正在升级中",
            "01200103": "集群正在升级中",
            "02120101": "获取集群健康状态失败",
            "02120201": "获取告警信息失败",
            "02120301": "获取monitor信息失败",
            "02120401": "获取PG状态失败",
            "02120501": "获取磁盘使用失败",
            "02120601": "pools信息获取失败",
            "02120701": "获取osd tree信息失败",
            "02120801": "超融合ceph存储服务异常或未安装超融合ceph存储",
            "04030102": "流程启动失败",
            "011002010": "获取集群配置失败，有节点未配置",
            "011002011": "修改集群状态失败",
            "011002012": "获取tunnel地址失败",
            "00010110": "权限不足，请联系管理员",
            "01020202": "部门已绑定用户，无法删除",
            "01140101": "黑白名单列表名称重复",
            "01140102": "黑白名单新增失败",
            "01140301": "黑白名单编辑失败",
            "04010101": "流程创建失败",
            "04010201": "流程部署失败",
            "04010301": "流程删除失败",
            "04010401": "流程处理人查询失败",
            "01120301": "描述文字过长,请重新输入",
            "01080202": "用户已关联流程,请先重新部署该流程",
            "01140401": "黑白名单列表获取失败",
            "04020201": "删除流程失败",
            "011404501": "黑白名单状态编辑失败",
            "01030703": "登录失败（用户所在IP地址被拒绝访问）",
            "03013601": "告警模板被使用，不允许删除",
            "03013701": "告警联系人组被使用，不允许删除",
            "03017101": "策略项重复",
            "03013801": "告警相关的header里面没有企业id",
            "07010101": "鉴权失败",
            "04010601": "复制模型失败",
            "04030101": "请先绑定工作流再进行操作",
            "04010202": "未绘制流程图或流程图格式错误",
            "03015101": "虚拟机CPU使用率Top5获取失败",
            "02110101": "创建自定义镜像失败",
            "01160101": "获取授权信息失败",
            "01160201": "发送授权更新请求失败",
            "9001001": "大页内存大小与配置不匹配",
            "00010106": "授权已过期",
            "02015301": "恢复云主机失败",
            "02015401": "强制删除云主机失败",
            "02015501": "获取回收站列表失败",
            "03010701": "同名告警已经存在",
            "03011301": "同名告警模版已经存在",
            "03012901": "同名联系人组已经存在",
            "03012902": "联系人组中联系人不可拥有两个及以上相同的邮箱",
            "06010201": "下载链接已失效,请重新获取",
            "01150502": "节点维护开始，正在关闭节点上的虚拟机，请稍候",
            "00010201": "只有超级管理员有权限操作",
            "02180101": "擴展集群的虛機、告警模板或者擴展策略不存在",
            "02180201": "扩展策略的名称已存在",
            "02180301": "扩展策略已被使用不允许删除",
            "02200100": "创建扩展集群必须信息为空",
            "02200101": "扩展集群获取镜像详情失败",
            "02200102": "扩展集群保存镜像详情失败",
            "02200103": "保存扩展集群基础信息失败",
            "02200104": "保存扩展集群云主机配置信息失败",
            "02200105": "创建扩展集群云主机失败",
            "02200106": "创建扩展集群云主机为0",
            "02200107": "保存扩展集群云主机详情失败",
            "02200108": "扩展集群云主机均未激活",
            "02200109": "创建扩展集群的Balancer失败",
            "02200110": "保存扩展集群的Balancer失败",
            "02200111": "扩展集群的Balancer未激活",
            "02200112": "创建扩展集群的Listener失败",
            "02200113": "扩展集群的Listener未激活",
            "02200114": "创建扩展集群的Pool失败",
            "02200115": "扩展集群的Pool未激活",
            "02200116": "创建扩展集群的Member失败",
            "02200117": "扩展集群的Member未激活",
            "02200118": "创建扩展集群的ALARMID失败",
            "02200200": "该扩展集群不存在",
            "02200201": "该扩展集群正在被删除",
            "02200202": "删除扩展集群的告警ID失败",
            "02200203": "删除扩展集群的MEMBER失败",
            "02200204": "删除扩展集群的POOL失败",
            "02200205": "删除扩展集群的Listener失败",
            "02200206": "删除扩展集群的Balancer失败",
            "02200207": "删除扩展集群的云主机失败",
            "02200301": "批量删除扩展集群失败",
            "02230101": "获取卷的host信息失败",
            "02290101": "创建IPSec策略失败",
            "02290102": "删除IPSec策略失败",
            "02290103": "禁用IPSec策略失败",
            "02290104": "启用IPSec策略失败",
            "02290105": "获取IPSec策略详情失败",
            "02290106": "获取IPSec策略列表失败",
            "02290107": "修改IPSec策略失败",
            "02290108": "修改Endpoint-groups失败",
            "02290109": "轮询IPSecSite状态失败",
            "02310101": "项目配额不足",
            "02310102": "部门配额不足",
            "02059001": "创建防火墙失败",
            "02059000": "获取防火墙列表失败",
            "02059002": "更新防火墙失败",
            "02059003": "删除防火墙失败",
            "02059004": "创建防火墙规则失败",
            "02059005": "更新防火墙规则失败",
            "02059006": "删除防火墙规则失败",
            "02059007": "获取防火墙规则列表失败",
            "02059008": "获取防火墙策略列表失败",
            "02059009": "创建防火墙策略失败",
            "02059010": "编辑防火墙策略失败",
            "02059011": "删除防火墙策略失败",
            "02059012": "获取静态路由表详情失败",
            "02059013": "编辑静态路由表失败",
            "02059014": "启用firewallrule失败",
            "02059015": "禁用firewallrule失败",
            "02059408": "获取网络类型失败",
            "02059409": "添加物理网络失败",
            "03016101": "查询历史统计数据失败",
            "03016201": "查询rabbitmq信息失败",
            "02059801": "路由器更新失败",
            "02059802": "更新路由冲突",
            "02059803": "下一跳地址不与路由相关",
            "08010101": "连接VCenter失败",
            "08010103": "VCenter url 格式错误",
            "08010104": "获取SSL Thumbprint失败",
            "04040201": "未找到对应的任务信息",
            "04040301": "未找到对应的任务信息",
            "09010101": "创建快照计划任务失败",
            "09010201": "更新快照定时任物失败",
            "09010301": "删除快照定时任物失败",
            "09010401": "暂停快照定时任物失败",
            "09010501": "恢复快照定时任物失败",
            "09010601": "获取计划任务列表错误",
            "09010602": "获取云主机名称失败",
            "09010603": "获取云硬盘名称失败",
            "09010701": "获取计划任务信息错误",
            "09010801": "获取计划任务执行结果错误",
            "09010901": "检查计划任务信息错误",
            "09011001": "解绑硬盘时更新任务失败",
            "09011101": "获取任务创建快照数量失败",
            "02050204": "创建网络时添加带宽限制失败",
            "02015801": "调整云主机带宽失败",
            "02015901": "获取云主机带宽失败",
            "03030101": "查询物理机进程cpu使用率排序失败",
            "03030201": "查询纳管物理机进程cpu使用率排序失败",
            "03030301": "查询物理机进程内存使用率top5排序失败",
            "03030401": "查询纳管物理机进程内存使用率top5排序失败",
            "03030501": "查询物理机进程详情失败",
            "03030601": "查询纳管物理机进程详情失败",
            "01030705": "获取数据中心信息失败",
            "01081101": "用户名为空",
            "01090802": "编辑企业公有云信息失败",
            "01100100": "节点更新成功",
            "01100211": "修改集群状态失败",
            "01100212": "获取tunnel地址失败",
            "01100213": "清空集群配置失败",
            "01130201": "获取部门项目下资源最大使用量",
            "01140201": "黑白名单删除失败",
            "01150901": "修改节点配置失败",
            "01160301": "上传license文件更新请求失败",
            "01160302": "上传license文件格式错误",
            "01160303": "上传license文件过大",
            "01170302": "查询参数失败",
            "01170401": "删除失败",
            "01170301": "查询下级参数失败",
            "01180101": "查询参数版本列表失败",
            "01180201": "查询参数明细失败",
            "01180301": "保存参数设置失败",
            "01190105": "用户已删除",
            "01200101": "发送升级请求失败",
            "01200102": "平台正在升级中",
            "01200103": "集群正在升级中",
            "01190109": "快照保留份数全部创建了硬盘",
            "02020701": "检查镜像的缓存卷失败",
            "02100101": "获取主机集合失败",
            "02100401": "重置主机集合失败",
            "02100701": "添加主机到集合失败",
            "02281201": "备份策略创建失败",
            "02281202": "备份策略名称已存在",
            "02281401": "获取备份策略列表失败",
            "02281301": "备份策略使用中",
            "02281302": "备份策略已删除",
            "02281303": "删除备份策略失败",
            "02110102": "保存自定义镜像创建结果失败",
            "02110201": "更新自定义镜像失败",
            "02210101": "卷类型初始化失败",
            "02220101": "获取已使用vlanId失败",
            "04010102": "文件名为空",
            "04010103": "文件最大为1M",
            "04010104": "文件类型不符合要求，需要为bpmn类型",
            "04010105": "文件已存在",
            "04010106": "保存上传的文件失败",
            "04010501": "初始化模型",
            "04020107": "部署流程失败",
            "04040101": "未找到对应的任务或用户",
            "04040302": "该任务已被其他用户签收",
            "8004012":"获取logo失败",
            "03010101": "增加告警失败",
            "03010201": "编辑告警失败",
            "03010301": "获取告警列表失败",
            "03010401": "获取单个告警失败",
            "03010501": "删除告警失败",
            "03010601": "服务类型告警已经存在",
            "03010801": "增加告警模版失败",
            "03010901": "编辑告警模版失败",
            "03011001": "获取告警模版列表失败",
            "03011101": "获取单个告警模版失败",
            "03011201": "删除告警模版失败",
            "03013901": "在资源管理获取告警事件的时候没有企业或者项目id",
            "03011401": "增加告警规则失败",
            "03011501": "编辑告警规则失败",
            "03011601": "获取告警规则列表失败",
            "03011701": "获取单个告警规则失败",
            "03011801": "删除告警规则失败",
            "03011901": "增加告警事件失败",
            "03012001": "编辑告警事件失败",
            "03012101": "获取单个告警事件列表失败",
            "03012201": "获取单个告警事件失败",
            "03012301": "删除告警事件失败",
            "03012401": "增加联系人列表失败",
            "03012501": "编辑联系人列表失败",
            "03012601": "获取联系人列表列表失败",
            "03012701": "获取单个联系人列表失败",
            "03012801": "删除联系人列表失败",
            "03013001": "增加联系人失败",
            "03013101": "编辑联系人失败",
            "03013201": "获取联系人列表失败",
            "03013301": "获取单个联系人失败",
            "03013401": "删除联系人失败",
            "03016401": "增加标签失败",
            "03016501": "编辑标签失败",
            "03016601": "获取标签列表失败",
            "03016701": "获取单个标签失败",
            "03013501": "删除标签失败",
            "03014101": "上传历史数据失败",
            "03016301": "获取mysql基本信息失败",
            "03018101": "获取纳管节点详情失败",
            "03020101": "告警初始化失败",
            "00010103": "未获取到用户信息",
            "00010104": "keystoneurl为空",
            "02050203": "网络资源超出配额",
            "02058102": "不可以创建相同端口的监听器",
            "02058701": "获取子网详情失败",
            "02057102": "当前监听器下端口不一致",
            "02058702": "子网不存在",
            "02055501": "获取qos策略失败",
            "02055601": "修改qos策略失败",
            "02055701": "创建qos策略失败",
            "02055801": "删除qos策略失败",
            "02055901": "获取带宽规则失败",
            "02056001": "修改带宽规则失败",
            "02056101": "创建带宽规则失败",
            "02056201": "删除带宽规则失败",
            "02056501": "获取负载均衡器列表失败",
            "02056601": "创建负载均衡器失败",
            "02056701": "获取负载均衡器详情失败",
            "02056801": "修改负载均衡器失败",
            "02056901": "删除负载均衡器失败",
            "02057001": "获取成员列表失败",
            "02057101": "创建成员失败",
            "02057201": "获取成员详情失败",
            "02057301": "修改成员失败",
            "02057401": "删除成员失败",
            "02057501": "获取vip列表失败",
            "02057601": "创建vip失败",
            "02057701": "获取vip详情失败",
            "02057801": "修改vip失败",
            "02057901": "删除vip失败",
            "02058001": "获取监视器列表失败",
            "02058101": "创建监视器失败",
            "02058201": "获取监视器详情失败",
            "02058301": "修改监视器失败",
            "02058401": "删除监视器失败",
            "02058501": "绑定监视器失败",
            "02058601": "解绑监视器失败",
            "02058703": "清除网关与vpn冲突",
            "02058704": "获取可用ip数量失败",
            "02058801": "获取qos策略详情失败",
            "02058901": "获取某个带宽规则详情失败",
            "02059301": "给虚拟机添加带宽失败",
            "02059101": "给租户网添加带宽失败",
            "01100210": "删除region失败",
            "01100216": "有节点未配置",
            "04010107": "文件为空",
            "01100217": "获取quotausage失败",
            "01140601": "黑白名单明细获取失败",
            "02010303": "创建云主机所选规格不足",
            "77000111": "磁盘空间不足，镜像上传失败",
            "02053601": "快速创建失败：网络创建失败",
            "02053701": "快速创建失败：设置带宽属性失败",
            "02053801": "快速创建失败：子网创建失败",
            "02053901": "快速创建失败：路由创建失败",
            "02054001": "快速创建失败：绑定路由端口失败",
            "9001004": "SR-IOV网络名称重复",

            "02270101":"创建裸金属网络失败",

            "02016701":"云主机一键还原失败",
            "02016702":"原始镜像不存在，无法一键还原",
            "02016703":"系统盘上有快照，请删除快照后继续操作",
            "02016601":"删除云主机组失败",
            "02016301":"创建云主机组失败",
            "02016401":"获取云主机列表失败",
            "02016501":"获取云主机组详情失败",
            "02016201":"修改云主机密码失败",
            "02280101":"云硬盘备份失败",
            "02280201":"获取备份列表失败",
            "02280301":"删除备份失败",
            "02280401":"获取云硬盘的备份失败",
            "02280501":"获取备份详情失败",
            "02280601":"备份还原失败",
            "02280701":"备份创建云硬盘失败",
            "02280801":"重置备份状态失败",
            "02280901":"删除备份超时",
            "02013801":"冷迁移失败",
            //导入内容为空或格式错误
            "31415926":"导入内容格式错误",
            //物理机
            "02240101": "获取资源物理主机列表失败",
            "02240201":"获取资源物理主机详情失败",
            "02240301":"注册物理主机失败",
            "02240401":"编辑物理主机失败",
            "02240501":"设置物理主机设备状态失败",
            "02240601":"删除物理主机失败",
            "02240701":"设置物理主机电源状态失败",
            "02240801":"开启物理主机维护模式失败",
            "02240901":"关闭物理主机维护模式失败",
            "02241001":"批量注册物理机失败",
            "02241002":"批量注册文件格式错误",
            "02241003":"批量注册数据错误",
            "02241004":"节点认证信息错误，无法连接到节点",
            "02241101":"中断初始化失败",

            "02250101":"获取物理机列表失败",
            "02250201":"获取物理机详情失败",
            "02250301":"申请物理机失败",
            "02250401":"关闭物理机失败",
            "02250402":"物理机状态不正确",
            "02250501":"启动物理机失败",
            "02250601":"重启物理机失败",
            "02250701":"重命名物理机失败",
            "02250801":"删除物理机失败",
            "02250901":"锁定物理机失败",
            "02251001":"解锁物理机失败",
            "02251101":"获取物理机类型失败",
            "02251201":"创建物理机类型失败",
            "02251301":"删除物理机类型失败",
            "02251401":"获取物理机类型特别设置失败",
            "02251501":"创建物理机类型特别设置失败",
            "02251601":"删除物理机类型特别设置失败",
            "02251701":"编辑物理机类型特别设置失败",

            //ipmi 获取sdr信息
            "02260101":"获取sdr信息失败",
            "02260201":"获取电源状态失败",
            "02260301":"获取检验ipmi信息失败",
            "02260401":"注册ipmi地址重复",

            "02270101":"创建物理机网络失败",
            "02270201":"删除物理机网络失败",
            "02270301":"获取物理机网络端口列表失败",
            "02270401":"获取物理机网络详情失败",
            "02270501":"物理机子网创建失败",
            "02270601":"物理机子网编辑失败",
            "02270701":"物理机子网删除失败",
            "03060101":"系统巡检中",

            "02210102":"云硬盘迁移失败",
            "02210103":"该云硬盘所在的存储设备已关联qos，不支持在线迁移",
            "02052501":"获取端口列表失败",
            "02051511":"新建端口转发失败",
            "02051512":"新建端口转发失败,外部端口被占用",
            "02051503":"获取端口转发列表失败",
            "02016802":"网卡安全设置失败",
            "02052703":"获取安全组详情失败",

            "02030804":"加载已达到上限",
            "02040703":"创建ISO卷失败",
            "02040701":"卸载ISO卷失败",
            "02040808":"没有可用的存储",
            "03040101":"开发者ID或密码错误，微信设置失败",

            "02281001": "备份记录导出失败",

            "02150102":"计费项已存在，不能重复添加",
            "02150103":"计费资源项不存在,请在价格管理中添加此计费项",
            "03050101":"获取资源统计数据失败",

            "02017001":"获取云主机启动顺序失败",
            "02017101":"调整云主机启动顺序失败",

            "09010101":"创建计划任务失败",
            "09010201":"更新计划任务失败",
            "09010301":"删除计划任务失败",
            "09010401":"暂停计划任务失败",
            "09010501":"恢复计划任务失败",
            "09010601":"获取计划任务列表错误",
            "09010602":"获取云主机名称失败",
            "09010603":"获取云硬盘名称失败",
            "09010701":"获取计划任务信息错误",
            "09010801":"获取计划任务执行结果错误",
            "09010901":"检查计划任务信息错误",
            "09011001":"解绑硬盘时更新任务失败",
            "09011101":"获取任务创建快照数量失败",
            "09011201":"获取任务创建备份数量失败",
            "01190201":"获取云主机信息失败",
            "01190202":"云硬盘状态不允许创建备份",
            "01190203":"获取云硬盘的备份列表失败",
            "01190204":"删除首个定时备份链失败",
            "01190205":"创建备份失败",
            "01190206":"云硬盘备份中，跳过此次备份",
            "01190207":"部门备份数量配额不足",
            "01190208":"项目备份数量配额不足",
            "01190209":"部门备份内存配额不足",
            "01190210":"项目备份内存配额不足",
            //透传设备
            "02018201":"云主机加载usb设备失败",
            "02018801":"云主机加载usb设备达到上限",
            "02019001":"获取云主机所在宿主机上所有GPU设备失败",
            "02019101":"加载GPU设备失败",
            "02019002":"获取云主机所在宿主机上所有FPGA设备失败",
            "02019102":"加载FPGA设备失败",
            "02019202":"云主机卸载FPGA设备失败",
            "02018301":"云主机卸载usb设备失败",
            "02018401":"获取云主机已加载usb设备列表失败",
            "02018501":"获取所有云主机已加载usb设备列表失败",
            "02018601":"获取云主机可加载的usb设备列表失败",
            "02018701":"获取云主机可加载的usb设备详情失败",
            "02019201":"卸载GPU设备失败",
            "02019401":"获取云主机已加载透传设备列表失败",
            "02019111":"当前FPGA设备已被加载",
            "02019112":"当前GPU设备已被加载",
            "02019113":"当前USB设备已被加载",
            "02019601":"设置云主机异常重启开关失败",
            //云主机备份
            "01210101": "备份存储已存在",
            "01210102": "文件格式不对",
            "01210103": "文件大小超出限制",
            "01210104": "ceph配置文件上传失败",
            "01210201": "查询备份是否已使用失败",
            "01210202": "备份存储已使用",
            "01210301": "获取备份存储容量失败",
            "01230101": "获取FC磁盘信息超时",
            "01230213": "该存储对应的存储在创建中或者删除中，无法删除",
            "01230102": "获取FC磁盘信息失败",
            "01230103": "获取FC磁盘任务失败，请重试",
            "01230201": "获取NFS路径超时",
            "9001001": "大页内存匹配失败",
            "9001002": "sriov匹配失败",
            "9001003": "numa匹配失败",
            "02040202": "此类型快照为系统自动生成，不允许手动删除",
            "01230218": "迁移失败，镜像库中存在快照类型的镜像",
            "9001004": "SRIOV网络名称重复",
            "02059410": "该网络被使用中，无法删除",
            "9001005": "该物理网络正在使用中，删除失败",
            "9001006": "底层网络服务未启动完成，请稍后再试",
            "9001007": "大页内存配置中",
            "9001009": "DPDK重复操作",
            "9001010": "DPDK节点没有配置大页内存",
            "01230205": "裸机使用了该存储池，不允许删除ceph存储",
            "01230206": "容器使用了该存储池，不允许删除ceph存储",
            "01230207": "存储池名称不可用",
            "01230208": "默认存储池不允许删除",
            "01230209": "已使用的存储池不允许删除",
            "01230210": "存储池中间状态不允许删除存储",
            "01230211":"已使用的存储不允许删除",
            "01230212":"该存储迁移过镜像无法删除",
            "01230213":"该存储对应的存储在创建中或者删除中，无法删除",
            "01230214":"ceph存储管理中获取的状态为error，不能添加和删除存储池",
            "01230215":"正在进行镜像迁移，不允许再进行镜像迁移",
            "01230216":"存储池中间状态不允许添加删除存储",

            "01230202": "获取NFS路径信息失败",
            "01230203": "获取NFS路径任务失败，请重试",
            "01230204": "最后一个存储不允许删除",

            "02132701": "该负载均衡状态错误不允许进行操作",
            "02280102": "备份存储容量不足",
            "01190211": "备份存储容量不足",
            "02280103": "备份存储容量校验失败",
            "01220101": "获取集群ceph信息超时",
            "01220102": "获取集群ceph信息失败",
            "09020101": "创建ceph任务失败",
            "09020201": "获取ceph任务列表失败",
            "09020301": "删除ceph任务列表失败",
            "09020401": "ceph任务冲突，存在等待或执行中的任务",
            "09020102": "任务起始时间不能早于任务创建时间",
            "09020302": "任务执行中不能删除",
            "02019501": "删除云主机系统盘失败",
            "02280103": "备份存储容量校验失败",
            "02280104": "备份存储容量校验失败,请重试",
            "01220103": "获取集群ceph信息失败，请重试",
            "01190213": "校验备份存储容量失败，跳过此次任务",
            "02054201":"创建IPv6子网失败",
            "04040202": "工单结束任务无法操作",
            "02241201":"获取物理机初始化配置失败",
            "02241301":"设置物理机系统盘失败",
            "05010101":"资源申请配额不足",
            "02251901":"检查物理机是否允许重装失败",
            "01130202": "获取部门项目下资源最大限制数",
            "02040901":"云硬盘不在迁移中",
            "02040902":"云硬盘重置状态失败",
            "02031002":"项目配额超过部门配额",
            "02031003":"部门配额低于项目配额",
            "300401":"配额不足",
            "8001008":"当前平台处于运维模式，操作已被锁定",
            "0213409":"不能创建负载均衡器，指定网络上的可用IP地址不足",
            "8003010":"添加失败",
            "8003011":"编辑失败",
            "8003012":"删除失败",
            "8003013":"查询失败",
            "8003009":"PaaS服务已存在",
            "01240101":"同步设备到织云失败",
            "01240102":"尚未对接PaaS服务，请先对接",

            //tdsql
            "18010107":"新增用户失败",
            "18010108":"修改用户权限失败",
            "18010109":"查询用户列表失败",
            "18010110":"删除用户失败",
            "18010111":"重置密码失败",
            "18010112":"克隆权限失败",
            "18010118":"获取权限失败",
            "18010113":"获取数据库列表失败",
            "18010114":"获取对象列表失败",
            "18010115":"获取字段列表失败",
            "18010116":"获取实例列表失败",
            "18010117":"设置实例同步/异步模式失败",
            "18010118":"存在已退化的实例，更改同步状态失败",
            "18010119":"获取实例详情失败",
            "18010120":"查询详情失败，请确认实例是否存在于TDSQL平台",
            "02310100":"部门或项目配额不足",
            "02310103":"配额校验失败",
            "02016201": "修改云主机密码失败",
            "01060204":"项目删除失败,项目下存在用户",
            "01020205":"默认部门无法删除",
            "01060205":"默认项目不能删除",
            "02013902":"热迁移失败:空间不足",
            "01100113":"导入文件格式错误",
            "01100114":"表格信息格式不符合规范，批量导入别名失败",
            "01100115":"导入失败，表格信息为空",
            "01100116":"导入失败，IP地址格式不合法",
            "01100117":"别名由字母、数字或横线组成，长度为4-64字符，且只能以字母或数字开头。",
            "01100118":"导入失败，节点之间别名不能重复",
            "01100111":"各主机别名之间不能重复",
            "01100112":"更新主机别名失败",
            "09020501":"同步资源数据任务新建失败",
            "09020502":"同步资源数据任务修改失败",
            "09020503":"同步资源数据任务查询失败",
            "80088001":"安全组分页列表查询失败",
            "80088002":"密钥对分页列表查询失败",
            "80088003":"防火墙分页列表查询失败",
            "01080305":"当前集群状态不允许此操作",
            "02281201":"备份策略创建失败",
            "02281202":"备份策略名称已存在",
            "02281301":"备份策略使用中",
            "02281302":"备份策略已删除",
            "02281303":"删除备份策略失败",
            "02281401":"获取备份策略列表失败",
            "02281801":'创建备份调度失败',
            "02281802":"备份策略不存在",
            "02281803":"创建备份失败",
            "02281804":"云硬盘存在运行中的备份",
            "02281805":"备份名称已存在",
            "02281901":"删除备份调度失败",
            "02281902":"备份状态不是未开始",
            "02282001":"备份状态不允许删除",
            "02282002":"备份删除失败",
            "02282101":"获取备份调度时间失败",
            "02282201":"云主机状态只有处于关机状态才可恢复",
            "02282202":"备份状态不是可用",
            "80018001":"云主机创建失败",
            "80018002":"云主机更新失败",
            "80018003":"云主机删除失败",
            "80018004":"获取云主机列表失败",
            "80028001":"云硬盘创建失败",
            "80028002":"云硬盘更新失败",
            "80028003":"获取云硬盘列表失败",
            "80028004":"云硬盘删除失败",
            "80038001":"备份创建失败",
            "80048001":"快照创建失败",
            "80048002":"快照删除失败",
            "80048003":"快照查询失败",
            "80058001":"云主机组创建失败",
            "80058002":"云主机组更新失败",
            "80058003":"云主机组删除失败",
            "80058004":"获取云主机组列表失败",
            "02280105":"项目备份配额不足",
            "02280106":"部门备份配额不足",
            "02280107":"项目备份容量配额不足",
            "02280108":"部门备份容量配额不足",
            "01140103":"黑白名单IP重复",
            "02030203":"云硬盘状态不正常",
            "80008001":"同步中，请稍候！",
            // 角色管理
            "01080602":"操作失败,所属角色不存在或已被删除",
            "01230215":"正在进行镜像迁移，不允许再进行镜像迁移",
            "01230217":"当前集群中存在异常节点",
            "02018101":"修改磁盘Qos失败",
            "02016101":"重置云主机失败",
            //对象存储
            "02070901":"创建容器名称重复",
            "02070900":"创建容器失败",
            "02070902":"创建目录失败",
            "02070905":"文件上传失败",
            "02071100":"删除失败",
            "02071201":"权限修改失败",
            "02053602":"IP地址冲突",
            "02070500":"获取存储桶列表失败",
            "02070801":"存储桶配额不足",
            "02070901":"存储桶名称重复",
            "02070903":"存储对象名称重复",
            "02070904":"配额不足",
            "02071801":"获取客户端失败,检查对象存储设置",
            "02071802":"访问密钥无效",
            "02071803":"签名不匹配",
            "02071902":"监听器未删除，负载均衡删除失败",
            //对象存储
            "02330101":"获取文件存储失败",
            "02330102":"获取文件存储类型失败",
            "02330103":"文件存储创建失败",
            "02330104":"文件存储修改失败",
            "02330105":"文件存储删除失败",
            "02330106":"获取文件存储规则失败",
            "02330107":"规则创建失败",
            "02330108":"文件存储详情获取失败",
            "02330109":"文件存储规则删除失败",
            "02330110":"IP或CIDR地址已经存在",
            "02340602":"默认账号不允许删除",
            "200704007":"校验失败，accessKeyId或accessSecret 不存在/输入有误",
            "13050001":"校验失败，访问密钥 ID或私有访问密钥 不存在/输入有误",
            "01301001":"csp访问禁止，指定的用户不存在",
            //安全组
            "15010101":"创建安全组失败",
            "15010102":"安全组名称重复",
            "15010201":"更新安全组失败",
            "15010301":"删除安全组失败",
            "15010401":"创建安全组规则失败",
            "15010501":"安全组不存在",
            "15010601":"安全组规则冲突",
            "15010701":"黑名单规则参数错误",
            "15050102":"更新增强型负载均衡镜像失败",
            "04060101":"添加服务失败",
            "04060102":"添加服务存在",
            "04060103":"删除服务失败",
            "01081302":"AD域用户解绑角色失败",
            "01081303":"校验AD配置信息失败",
            "01081304":"关闭AD对接校验,确实需要关闭,请联系运维人员",
            "02340202":"用户已存在",
            "02340603":"用户已在对象存储设置使用",
            "02340802":"秘钥已在对象存储设置使用",
            "01170502":"未纳管TCS",
            "01170503":"未开启TCS服务",
            "01130203":"项目资源配额不足",
            "15020102":"标签创建失败",
            "15020101":"标签名称已存在",
            "15020103":"修改标签名称失败，该名称已存在",
            "15020201":"云主机与标签绑定关系已存在",
            "15020202":"云主机与标签绑定关系不存在",
            "15020301":"云主机与标签绑定超过十个",
            "02019701":"校验云主机状态失败",
            "02019702":"校验存储池失败",
            "02019703":"克隆云硬盘失败",
            "02019704":"校验ip地址失败",
            "01290101":"下发任务失败，请重试",
            "02019706":"获取硬盘控制器类型失败",
            "01290102":"集群未安装ceph存储，无法进行克隆链优化",
            "01290106":"集群未安装ceph存储，无法对接ceph对象存储",
            "01300801":"csp访问禁止，请检查配置",
            "01300701":"csp获取文件存储池信息失败，请联系管理员",
            "15030201":"创建本地磁盘失败",
            "15030202":"本地磁盘名称已存在",
            "15030301":"获取本地磁盘列表失败",
            "15030401":"删除本地磁盘失败",
            "15030402":"本地数据盘已使用，无法删除本地磁盘",
            "15030501":"挂载本地磁盘失败",
            "15030601":"卸载本地磁盘失败",
            "15030701":"本地磁盘不存在",
            "01300901":"密钥使用中,操作失败",
            "02040903":"云硬盘存在快照，无法迁移",      
            // TODO backupService
            "16010101":"新增配置服务异常",
            "16010102":"删除配置服务异常",
            "16010106":"用户名或密码错误",
            "16010108":"修改密码失败",
            "16010109":"参数异常",
            "16010110":"配置不存在",
            "16010104":"备份配置部署中",
            "16010105":"请先进行备份配置",
            "16040101":"选中的云主机必须在同一任务、同一时间节点、同一个任务类型下",
            "16040102":"任务名称重复",
            "16040103":"新增失败",
            "16040104":"查询云主机的执行详情失败",
            "16040105":"获取任务执行进度失败",
            "16040106":"删除失败",
            "16040107":"停止失败",
            "16040108":"查询任务失败",
            "16050101":"新建失败",
            "16050102":"获取备份任务失败",
            "16050103":"分页获取数据清理列表失败",
            "16050104":"分页获取备份数据时间节点列表失败",
            "16050105":"任务名称不能为空",
            "16020401":"查询数据备份监控列表失败",
            "16020402":"停止备份服务失败",
            "16020403":"删除备份历史数据失败",
            "16020404":"获取执行备份任务详情失败",
            "16020405":"获取执行备份任务日志失败",
            "16020406":"获取执行备份任务日志失败",
            "16020407":"获取任务执行记录详情失败",
            "16020101":"参数异常",
            "16020102":"名称重复",
            "16020103":"查询备份任务",
            "16020104":"任务不存在",
            "16020201":"创建策略异常",
            "16020202":"创建任务异常",
            "16020203":"绑定策略异常",
            "16020204":"查询策略异常",
            "16020205":"查询客户端异常",
            "16020206":"查询组异常",
            "16020207":"查询组下租户",
            "16020208":"编辑备份任务异常",
            "16020301":"删除备份任务异常",
            "16020302":"删除策略异常",
            
            "02010304":"创建云主机时获取云主机类型失败",
            "02010305":"创建云主机时保存云主机类型失败",
            "02010202":"获取云主机详情的云主机类型失败",
            "02019801":"云主机搁置错误",
            "02019802":"云主机状态错误不允许搁置",
            "02019803":"取消云主机搁置错误",
            "02019804":"云主机状态错误不允许取消搁置",
            "02019805":"云主机绑定资源不可以搁置",
            "02019806":"配额不足不可以取消搁置",
            "02052401":"通过端口获取云主机类型失败",
            "02030805":"搁置的云主机无法加载云硬盘",
            "02030904":"搁置的云主机无法卸载云硬盘",
            "15050202":"负载均衡虚拟机状态异常",
            "02040904":"云主机是搁置状态不可迁移",
            "02010306":"创建失败：目标卷存储为NFS时，不支持批量创建，当前资源正在使用中，请稍后再试。",
            "02010307":"创建失败：目标卷存储为NFS时，不支持批量创建。"
        },
        "tmpl": {
            "header": {
                "cacheMonitoring":"缓存监控",
                "cacheIO":"缓存的IO命中比率",
                "dirtydata":"脏数据",
                "overview": "概况",
                "productsandservices": "产品与服务",
                "ResourceManagement": "资源管理",
                "Compute": "计算",
                "storage": "存储",
                "network": "网络",
                "Safety": "安全",
                "depart": "部门",
                "project": "项目",
                "LogManagement": "日志管理",
                "SystemLog": "系统日志",
                "OperationLog": "操作日志",
                "LogAnalysis": "日志分析",
                "system": "系统管理",
                "user": "用户管理",
                "Permissionsettings": "权限设置",
                "personalinformation": "个人资料",
                "quit": "退出",
                "TicketTips": "工单提示",
                "TicketTip": "工单提示：当前0条",
                "MassageTips": "信息咨询",
                "MassageTip": "信息咨询：当前0条未读",
                "resApplyTips": "资源申请：当前0条未处理",
                "passwordModify": "修改密码",
                "resetModify": "重置密码",
                "Tips001": "您在4月22日发出的工单，管理员已回复，请注意查收。",
                "white":"白色",
                "black":"黑色",
                "all":"全部",
                "privateCloud":"私有云"
            },
            "index": {
                "depart": "部门",
                "project": "项目",
                "imgChoice": "选择镜像",
                "ok": "确定",
                "cancel": "取消"
            }
        },
        "system": {
            "imageSelect":"镜像选择",
            "imageSelectMsg":"负载均衡镜像为创建负载均衡时使用，选择后无法更改，且在镜像管理页面无法编辑该镜像，镜像必须开启网卡多队列功能。",
            "enhancefunction":"增强型功能",
            "enhancefunctionMsg":"启用后负载均衡栏目开启增强型入口，可以创建增强型负载均衡（增强型拥有更好的性能但会占用CPU和内存资源及配额）",
            "closeOK":"确认关闭",
            "msgAlert":"请删除所有增强型负载均衡后执行该操作。",
            "enterprise":"企业版",
            "stand":"标准版",
            "plugin":{
                "del_tips":"您确定删除所选内容吗？",
                "sync_tips":"您确定同步数据吗？"
            },
            "index": {
                "flavors": "云主机类型",
                "aggregates": "云主机集合",

                "hypervisors": "虚拟机管理器",
                "services": "服务管理",
                "availZoneList": "可用域集合"
            },

            "flavor": {
                "instance_type": "虚拟机类型",
                "diskPlaceholder": "此处数值不应超过单个物理主机可用数据盘的最大容量",
                "cupkernel": "CPU核数最大为240",
                "ramcheck": "内存最大为4000GB",
                "name": "名称",
                "vcpus": "CPU核数",
                "vcpuArch":"CPU类型",
                "vcpus_max": "CPU核数(max)",
                "vcpusmaxtips": "创建云主机时,配额以此值来计算.云主机热升级时CPU核数不能超过该值.",
                "ram": "内存(GB)",
                "rammax": "最大内存(GB)",
                "rammaxtip": "创建云主机时,配额以此值来计算",
                "disk": "系统盘(GB)",
                "disk_": "根磁盘(GB)",
                "diskPartition":"磁盘分区格式",
                "ephemeral": "数据盘(GB)",
                "_public": "是否公用",
                "modifyFlavorAccess": "修改项目使用权限",
                "specialSetting": "特别设置",
                "createInsConfig": "新建云主机规格",
                "creatSpec": "新建裸金属规格",
                "chosePublic": "选择是否设为公用",
                "choseKey": "选择键名",
                "detail": "flavor详情",
                "key": "键",
                "choseProject": "选择项目",
                "value": "值",
                "createFlavorExtra": "新建云主机特别设置",
                "createPhyMacFlavorExtra": "新建裸金属特别设置",
                "modifyFlavorExtra": "修改云主机特别设置",
                "modifyPhyMacFlavorExtra": "修改裸金属特别设置",
                "choseAvaiableProjects": "选择可用的项目",
                "inputFlavorName": "请输入云主机规格名称",
                "inputPhymacName": "请输入裸金属类型名称",
                "disk_read_bytes_sec": "磁盘读取速度限制(字节/秒)",
                "disk_read_iops_sec": "磁盘读取io数目限制(个)",
                "disk_write_bytes_sec": " 磁盘写入速度限制(字节/秒)",
                "disk_write_iops_sec": "磁盘写入io数目限制(个)",
                "vif_inbound_average": "入口平均网速限制(字节/秒)",
                "vif_inbound_burst": "入口峰值网速限制(字节/秒)",
                "vif_outbound_average": "出口平均网速限制(字节/秒)",
                "vif_outbound_burst": "出口峰值网速限制(字节/秒)",
                "cpu_sockets": "hw:cpu_sockets",
                "cpu_cores": "hw:cpu_cores",
                "cpu_threads": "hw:cpu_threads",
                "mem_max": "最大内存（GB）",
                "cpu_max": "最大CPU核数（个）",
                "currentInputValue": "当前可输入的值是",
                "sockets":"CPU 颗数",
                "cores":"CPU 核数",
                "threads":"CPU 线程",
                "physicalReq_1":"如需使用裸金属管理块功能，请打开”系统管理 > 系统设置 >",
                "physicalReq_2":"“界面添加”裸金属管理”模块",
                "functionManagement":"功能管理",
                "noSpecSetting":"无可添加的特别设置",
                "maxLength":"最大长度32位",
                "cpuTopoTips":"CPU颗数、CPU核数、CPU线程，三个值相乘的结果应大于等于1小于等于云主机规格的CPU核数",
                "cpuPolicy":"CPU策略",
                "policyShare":"共享",
                "policyPrivate":"专用",
                "noSpecSetting":"无可添加的特别设置",
                "prefer":"每个VCPU将尽可能的绑定到同一个物理CPU核的不同线程中。",
                "isolate":"每个VCPU将到绑定到不同的物理CPU核（core）上，并且独占这个CPU核。其他VCPU不能再放置到该物理CPU核上。",
                "require":"每个VCPU绑定到同一CPU的不同线程上。",
                "deletePhyFlavor": "您确定要删除所选裸金属配置吗？",
                "cpuTopu":"CPU 拓扑",
                "src":{
                    "true":"本地盘",
                    "false":"云硬盘",
                    "public":"通用"
                },
                "ipmiAccessInfo":"IPMI接入信息"
            },
            "aggregate": {
                "name": "主机集合名称",
                "availability_zone": "可用域名称",
                "availZone": "可用域",
                "hosts": "主机列表",
                "metadata": "元数据",
                "createAggregate": "新建主机集合",
                "inputAggregateName": "请输入主机集合名称",
                "inputAvailabilityzoneName": "请输入可用域名称",
                "editAggregate": "编辑云主机集合",
                "setMetadata": "设置元数据",
                "resetMetadata": "重置",
                "manageHost": "管理主机",
                "key": "键",
                "value": "值",
                "choseKeys": "请选择键",
                "choseProject":"请选择项目",
                "enterValues": "请输入项目ID",
                "input_instanceType": "请输入云主机规格名称",
                "input_cpu_pen": "请输入CPU超分比",
                "input_mem_pen": "请输入内存超分比",
                "input_number": "请输入数字",
                "metadataInfo": "此主机集合只能为此项目使用，其他项目不会在该主机集合上创建云主机，可选的值为用户的项目id",
                "explain":"说明：",
                "manageHostInfoOne": "1.选择主机添加到这个集合，或者从这个集合中删除。一个主机可以属于多个集合。",
                "manageHostInfoTwo": "2.不同的主机集合可以绑定相同的可用域,不同可用域之间的主机不能重复",
                "noHostsTips": "没有主机供当前集合的可用域选择",
                "choseHosts": "选择可用主机",
                "chosenHosts": "选中的主机",
                "deleteAggregate": "您确定要删除所选云主机集合吗？",
                "deleteFlavor": "您确定要删除所选云主机规格吗？",
                "deleteFlavorExtra": "您确定要删除所选特殊设置吗？",
                "resetAggregate": "您确定要重置所选云主机集合的元数据吗？",
                "cannotDelAggregate": "主机集合的主机列表不为空，不能删除",
                "CPUBinding":"CPU绑定",
                "selfDefined":"自定义",
                "selfDefinedKey":"自定义键",
                "selfLengthErrorMsg":"不能超过128位",
                "selfKeyPlaceholder":"请输入自定义键",
                "selfValuePlaceholder":"请输入自定义值",
                "true":"启用",
                "false":"禁用",
                "noData":"没有符合搜索条件的数据",
                "metaDataInfo":"配置该参数后，并在主机集合中同步设置元数据，使用该规格创建的云主机将被系统自动调度到满足要求的主机集合下的物理服务器上。",
                "metaDataInfo1":"在云主机规格中设置虚拟机类型，符合该规格要求的云主机可以被系统自动调度到该主机集合下面的物理服务器上。"
            },
            "storagement": {
                "checkCephUrl":{
                    "success":"验证成功",
                    "fail":"校验失败"
                },
                "inputapiPrivateKey":"请输入API访问私钥",
                "inputapiSecretKey":"请输入API访问秘钥",
                "apiSecretKey":"API访问密钥",
                "apiPrivateKey":"API访问私钥",
                "pwd":"密码",
                "accountNumber":"账号",
                "portNumber":"端口号",
                "URLSite":"URL地址",
                "leastSelect":"至少选择一项协议",
                "agreementSelect":"协议选择",
                "name": "名称",
                "action": "操作",
                "use": "存储用途",
                "deviceType": "设备类型",
                "allotCapacity": "已分配容量",
                "usedCapacity": "已使用容量",
                "totalCapacity": "总容量",
                "status": "状态",
                "createTime": "创建时间",
                "description": "描述",
                "objectType": "类型",
                "objectPoolType": "是否共用块存储磁盘",
                "objectPoolShare": "共用",
                "objectPoolNotShare": "不共用",
                "residualCapacity": "剩余容量",
                "selectOne": "请选择一条记录",
                "addTitle": "添加存储",
                "editTitle": "编辑",
                "inputName": "请输入存储名称",
                "selectUse": "请选择存储用途",
                "manufacturer": "存储厂商",
                "selectManufacturer": "请选择存储厂商",
                "device": "存储设备",
                "selectDevice": "请选择存储设备",
                "storageType": "存储类型",
                "selectType": "请选择存储类型",
                "protocol": "存储协议",
                "objectSetting": "存储设置",
                "objectUser": "存储用户",
                "selectProtocol": "请选择存储协议",
                "ipAddr": "管理IP地址",
                "inputIpAddr": "请输入管理IP地址",
                "ctrl1": "控制器1",
                "inputCtrl1": "请输入控制器1的IP地址",
                "ctrl2": "控制器2",
                "inputCtrl2": "请输入控制器2的IP地址",
                "userName": "用户名",
                "inputUserName": "请输入用户名",
                "password": "密码",
                "inputPassword": "请输入密码",
                "pool": "存储池",
                "poolExist": "存储池名称已存在",
                "inputPool": "请填写存储设备中用于放置云平台数据的池的名称",
                "ctrl1Business": "控制器1业务口",
                "inputCtrl1Business": "请输入控制器1业务口",
                "ctrl2Business": "控制器2业务口",
                "inputCtrl2Business": "请输入控制器2业务口",
                "checkManageAddr": "验证管理地址",
                "interface": "存储接口",
                "nfsAddr": "NFS服务器地址",
                "inputNfsAddr": "请输入NFS服务器地址",
                "checkNfsAddr": "验证NFS服务器",
                "nfsPath": "路径",
                "selectNfsPath": "请选择路径",
                "noNfsPath": "暂无可选路径",
                "loadmodule":"启用功能",
                "erasure":"纠删码",
                "rgwParameter":"参数",
                "kmTips":"K表示数据块，M表示校验块",
                "copyTips":"可以选择副本数或者纠删码的形式部署对象存储，其中K表示数据块，M表示校验块",
                "objRgwHelp":"对象存储对接完成之后，您可以去运维管理-Ceph PG管理 修改存储池比例，自动调整PG",
                "replicated":"副本数",
                "rgwObject":"对象存储",
                "inputInterface": "请输入底层存储卷名称",
                "inputDescription": "请输入描述信息",
                "spaceRatio": "存储空间比例",
                "inputSpaceRatio": "适用范围65-95",
                "configFile": "ceph配置文件",
                "configFileCheck": "文件格式必须为.conf且不超过1M且文件名不能包含中文和空格",
                "privilegeFile": "ceph权限文件",
                "privilegeFileCheck": "文件格式必须为.keyring且不超过1M且文件名不能包含中文和空格",
                "inputConfigFile": "请上传ceph配置文件",
                "inputPrivilegefile": "请上传ceph权限文件",
                "backupUser": "备份用户",
                "inputBackupUser": "请输入备份用户名称",
                "monitorUrlTips": "输入格式为:ip或域名:http://IP:Port,例如：'http://127.0.0.1:1001'",
                "monitorTips1": "1.验证成功，将在监控管理展示ceph监控页面，可以正常对接存储。",
                "monitorTips2": "2.验证失败，将无法展示ceph监控页面，可以正常对接存储。",
                "monitorTips3": "3.如输入内容后不点击验证，则不开启监控。",
                "backupPool": "备份池",
                "inputBackupPool": "请输入备份池",
                "addBackupSuccess": "添加存储成功",
                "addBackupFailed": "添加存储失败",
                "deleteTip": "是否删除选中的内容？",
                "storageAddr": "存储地址",
                "addstorageMessage":"添加存储需要重启存储服务，预计时长5-10分钟，会影响云平台存储相关的操作，但不影响已有云主机正常对外提供业务，请耐心等待。",
                "delstorageMessage":"删除存储需要重启存储服务，会影响云平台存储相关的操作，但不影响已有云主机正常对外提供业务，请耐心等待。",
                "detail": "存储详情",
                "notSupportMonitor": "对接J版外部ceph时，不支持监控",
                "objectInner": "内部",
                "objectExternal": "外部",
                "selectNode":"选择节点",
                "nodeName":"节点名",
                "diskName":"硬盘名",
                "diskType":"硬盘类型",
                "diskCapacity":"容量",
                "localVolume":"本地卷",
                "placeholder":{
                    "erasureCode":"请选择纠删码",
                    "rgwCopy":"请选择副本数",
                    "rgwParameter":"请选择参数",
                    "objectType":"请选择类型"
                },
                "table": {
                    "state": {
                        "up": "可用",
                        "down": "不可用"
                    }
                },
                "storageEnumeration":{
                    "ruijie": "锐捷",
                    "toyou": "同有",
                    "nfs": "NFS",
                    "iscsi": "通用ISCSI",
                    "fc": "通用FC",
                    "ceph": "超融合ceph",
                    "out_ceph": "外部ceph",
                    "default": "自定义存储",
                    "insideCeph":"超融合ceph存储",
                    "outsideCeph":"外部ceph存储",
                    "file_CSP":"csp文件存储",
                    "object_CSP":"csp对象存储",
                    "object_swift":"swift对象存储",
                    "object_ceph_inner":"ceph对象存储",
                    "local_disk":"本地磁盘",
                },
                "storagePool":{
                    "add":"添加存储池",
                    "name":"存储池名称",
                    "enter":"请输入",
                    "required":"必填",
                    "required_1":"名称以字母开头,由数字和字母组成,长度为4-32位",
                    "copies":"副本数",
                    "selectCopies":"请选择副本数",
                    "select":"请选择",
                },
                "storageTranslate":{
                    "iscisServerIp":"ISCSI服务器IP",
                    "iscisPort":"ISCSI端口",
                    "enterIscisPort":"请输入端口",
                    "authorization":"配置授权信息",
                    "iscisChap":"ISCSI服务器验证云计算服务器（CHAP）",
                    "iscisInformation":"以下用户名和密码为该台设备连接ISCSI服务器时使用的认证账号信息。由ISCSI服务器提供账号，本地仅连接即可。",
                    "iscisServerChap":"云计算服务器验证ISCSI服务器（双向CHAP）",
                    "iscisConnect":"以下用户名和密码为ISCSI服务器连接本台设备时使用的认证账号信息。由本台设备提供账号信息，ISCSI仅连接即可。",
                    "replicas":"存储副本数",
                    "selectReplicas":"请选择存储副本数",
                    "compatible":"您当前的ceph客户端版本为10.2.7，请确认ceph服务端版本是否与该版本兼容",
                    "storageUser":"存储用户",
                    "invalidMessage":"磁盘配置不符合规则，您确定要继续进行此操作？",
                    "diffNodeSetting":"现有节点的元数据盘与数据盘映射关系不一致，你确定要继续进行此操作？",
                    "enterStorageUser":"请输入存储用户名称",
                    "limitModifyNetwork":"两节点数据中心不允许修改存储网",
                    "enterNetworkConfig":"下发网络配置",
                    "resetIp":"重置IP",
                    "bcacheMsg":{
                        "tips1":"1.缓存加速仅对单存储资源池生效。",
                        "tips2":"2.为保证性能，建议4块或4块以内数据盘对应1块SSD缓存加速盘， 并且缓存加速盘的容量不少于总容量的25%。",
                        "tips3":"3.为保证性能，建议8块或8块以内数据盘对应1块NVME缓存加速盘。",
                        "tips4":"4.为保证性能，建议每个OSD（包括扩容后）的PG number不超过200。",
                    },
                    "editArea":"编辑区域",
                    "nouseDisk":"可用磁盘",
                    "sourceDisk":"元数据盘",
                    "dataDisk":"数据盘",
                    "localDisk":"本地盘",
                    "missDisk":"已丢失硬盘",
                    "bcacheSourceDisk":"缓存盘/元数据盘",
                    "openbcache":"开启缓存加速",
                    "openbcacheTip":"开启后，缓存加速与元数据盘共用一个磁盘",
                    "storageVlan":"存储网vlan与租户网vlan",
                    "conflict":"冲突",
                    "threecopy":"请至少完成三个主机的磁盘配置",
                    "numcopy":"请至少完成{{num}}个节点的磁盘配置",
                    "storageNet":"存储网",
                    "checkKernel":"节点{{node}}内核版本不支持缓存加速",
                    "setMap":"开启缓存加速时，节点{{node}}需要配置缓存盘/元数据盘",
                    "vlanRange":"vlan范围为2-4094",
                    "notWithin":"IP地址不在CIDR范围之内",
                    "ipRange":"结束IP地址必须比起始IP地址大",
                    "buttAllowed":"已对接过IP类存储，不允许修改存储网",
                    "dragDiskError":{
                        "sourceSsd":"请选择ssd类型磁盘作为元数据盘",
                        "sourceOnly":"元数据盘只能有一块",
                        "datahasSsd":"当数据盘有ssd盘时，元数据盘不可配置",
                        "nodatadisk":"数据盘不能为空",
                        "dataSddHdd":"数据盘只能全部配置为ssd盘或者hdd盘",
                        "invalidMaxDiskNumSize":"数据盘和元数据盘数量配置比超过4:1，元数据盘容量小于总数据盘容量1%",
                        "invalidMaxDiskNumSizeRgw":"数据盘和元数据盘数量配置比超过4:1，元数据盘容量小于总数据盘容量4%",
                        "invalidMaxDiskNum":"数据盘和元数据盘数量配置比超过4:1",
                        "invalidMaxDiskSize":"元数据盘容量小于总数据盘容量1%",
                        "invalidMaxDiskSizeRgw":"元数据盘容量小于总数据盘容量4%",
                        "nodatadiskMsg":"请确保数据盘不为空",
                        "sourceDiskRequire":"当前存储已开启bcache,请配置缓存盘/元数据盘",
                        "nosourcediskMsg":"请确保缓存盘/元数据盘不为空"
                    },
                    "netConfig":{
                        "diskcon":"磁盘配置",
                        "verifyStorage":"验证存储连通性",
                        "datacenter":"数据中心",
                        "cardcon":"网卡配置",
                        "host":"主机名",
                        "manage":"管理网",
                        "cpu":"CPU(核)",
                        "mem":"内存(G)",
                        "mountFc":"已挂载FC存储",
                        "findTarget":"发现目标（target）",
                        "iscisiDevice":"登录并确认ISCSI存储设备",
                        "targetIqn":"目标iqn信息",
                        "targetStatus":"发现目标状态",
                        "success":"成功",
                        "fail":"失败",
                        "unfindTarget":"未发现目标",
                        "selectStoDevice":"选择ISCSI存储设备",
                        "cluster":"集群网",
                        "manage":"管理网",
                        "storage":"存储网",
                        "tenant":"租户网",
                        "business":"业务网",
                        "waitThree":"请稍后，存储网络联通性检测预计耗时3分钟",
                        "deletection":"存储联通性检测任务未执行成功！",
                        "startDetection":"开始检测",
                        "nonetCard":"暂无网卡",
                        "close":"关闭",
                        "fcOperate":"请确保FC存储池不为空，再操作！",
                        "emptyOperate":"请确保数据盘不为空，再操作！",
                        "hardConfig":"硬盘配置节点",
                        "hardDisk":"可用硬盘",
                        "diskName":"硬盘名称",
                        "status":"状态",
                        "health":"健康",
                        "unHealth":"不健康",
                        "diskType":"硬盘类型",
                        "diskCapicty":"硬盘容量",
                        "logDisk":"日志盘",
                        "drop":"可拖拽区域",
                        "dataDisk":"数据盘",
                        "storagePool":"FC存储池",
                        "save":"保存",
                        "cancel":"取消"
                    },
                    "kernelNotSupport":"节点内核版本不支持bcache",
                    "object_ceph":"Ceph超融合",
                    "poolStatusMap":{
                        "1":"备份资源池",
                        "3":"文件存储资源池",
                        "0":"块存储资源池",
                        "2":"对象存储资源池",
                        "4":"本地磁盘资源池",
                    },
                    "cephVersionTips":"您当前的ceph客户端版本为10.2.7，请确认ceph服务端版本是否与该版本兼容",
                    "verification":"验证中",
                    "limitSize":"为了数据安全限制实际可使用的容量为总容量的90%。",
                    "linkIscis":"连接iscsi存储",
                    "configHost":"配置主机",
                    "butt":"对接存储",
                    "loading":"数据加载中",
                    "available":"实际可用",
                    "sendProgress":"下发配置进度",
                    "instancePool":"云主机存储资源池",
                    "instanceBackupsPool":"云主机备份资源池",
                    "deleteSuccess":"删除存储成功",
                    "addSuccess":"添加存储成功",
                    "deleteFail":"删除存储失败",
                    "addFail":"添加存储失败",
                    "storageSuccessEnd":"操作成功",
                    "storageFailEnd":"操作失败",
                    "usingLimitEdit":"存储正在使用中，不允许编辑",
                    "usingLimitDelete":"存储正在使用中，不允许删除",
                    "imageLimitDelete":"该存储作为镜像库后端，不允许删除",
                    "transferImage":"该存储正在进行进行镜像迁移，不允许删除",
                    "transferFailed":"该存储镜像迁移失败，暂时无法删除",
                    "customDelete":"自定义存储请根据文档在后台删除",
                    "clusterLimitOperation":"当前集群状态不允许此操作",
                    "manangeAddressConnected":"管理地址已连通",
                    "manangeAddressUnconnected":"管理地址未连通",
                    "nfsTips":"请先完成NFS服务器验证，并选择路径",
                    "manageIpCheck":"请先完成管理IP地址验证",
                    "sendNetworkConfig":"请下发网络配置",
                    "findTargetFail":"发现目标失败",
                    "iscsiTips":"登录并确认ISCSI存储设备失败，请稍后再试",
                    "completeDiskConfig":"请先完成各个主机的磁盘配置",
                    "iscisEquipment":"请先完成ISCSI存储设备确认",
                    "iscisReselect":"当前所选ISCSI存储不一致，请重新选择",
                    "selectSameSize":"请选择大小相同的存储设备",
                    "fcTips":"当前所选FC存储不一致，请重新选择",
                    "uploadConfigFail":"上传配置文件失败",
                    "configSuccess":"配置成功",
                    "configFail":"配置失败",
                    "sendingNetConfig":"网络配置下发中，请勿关闭窗口",
                },
                "localDiskDetailTable":{
                    "status":{
                        "all":"全部",
                        "available":"已加入本地磁盘",
                        "nov":"未加入本地磁盘",
                        "failed":"加入本地磁盘失败",
                        "creating":"正在加入本地磁盘"
                    },
                    "isExist":{
                        "1":"有",
                        "0":"无"
                    }
                }
            },
            "hypervisor": {
                "netName": "网络名称",
                "subnetMask": "子网掩码",
                "vlanRange": "VLAN范围",
                "vxlanRange": "VXLAN范围",
                "vmHA": "虚拟机HA",
                "hypervisorHostname": "物理主机名称",
                "type": "虚拟化类型",
                "virtualCPU": "物理CPU总数",
                "virtualUsedCPU": "已用物理CPU",
                "localMemory": "总内存（GB）",
                "localMemoryUsed": "已用内存（GB）",
                "freeRam": "空闲内存（GB）",
                "localDisk": "磁盘总容量（GB）",
                "localDiskUsed": "已用磁盘容量（GB）",
                "freeDisk": "空闲磁盘容量（GB）",
                "currentWorkload": "当前任务量",
                "runningVM": "虚拟机个数（运行）",
                "leastDiskAvailable": "最小可用磁盘（GB）"
            },
            "availZone": {
                "zoneName": "可用域名称",
                "hosts": "主机",
                "available": "可用"
            },
            "accesspolicy": {
                "name": "名称",
                "type": "类型",
                "ipAddr": "IP地址",
                "ipInputType": "IP输入方式",
                "range": "范围",
                "startIP": "起始IP",
                "endIP": "结束IP",
                "enabled": "激活",
                "createTime": "创建时间",
                "description": "描述信息",
                "inputPolicyname": "请输入策略名称",
                "inputWhiteListName": "请输入白名单名称",
                "inputBlackListName": "请输入黑名单名称",
                "inputStartIp": "请输入起始IP",
                "inputEndIp": "请输入结束IP",
                "ipAddrPlaceholder": "可以输入多个IP,多个IP间以逗号隔开",
                "moreIpTip": "多个IP以逗号隔开",
                "policyType": "策略类型",
                "whiteList": "白名单",
                "blackList": "黑名单",
                "createAccessPolicy": "新建访问策略",
                "editAccessPolicy": "修改访问策略",
                "delWbListIps": "确定删除所选访问策略？",
                "true": "是",
                "false": "否",
                "ipRepeat": "IP地址有重复，请重新输入"
            },
            "mailServer": {
                "name": "名称",
                "paramValue": "参数值输入/操作",
                "desc": "描述",
                "server": "服务器",
                "port": "端口",
                "account": "账号",
                "password": "密码",
                "safeConnection": "安全连接",
                "safeConnectionTips": "发送邮件时将采用SSL协议来进行加密传输，可确保邮件信息在网络上传输中不会被截取及窃听。",
                "sender": "发件人",
                "inputServer": "请输入邮箱服务器",
                "inputAccount": "请输入帐号",
                "inputEmeit": "请输入邮箱",
                "inputPassword": "请输入邮箱密码",
                "inputSender": "请输入发件人",
                "tips": "配置邮件服务器后，云管平台可通过该服务器发送告警等系统信息邮件给用户",
                "verifyServer": "是否验证邮件服务器",
                "sendVerifyInfo": "点击发送验证信息",
                "verifySuccess": "验证成功",
                "verifyFail": "验证失败",
            },
            "weChatAlarm": {
                "appId": "开发者ID",
                "inputAppId": "请输入开发者ID",
                "appIdTips": "管理员注册公众号时使用的开发者ID和开发者密码，可以登录微信公众号后台查看",
                "appSecret": "开发者密码",
                "inputAppSecret": "请输入开发者密码",
                "url": "服务器地址",
                "inputUrl": "请输入服务器地址",
                "token": "令牌",
                "inputToken": "请输入令牌",
                "key": "消息加解密密钥",
                "inputKey": "请输入消息加解密密钥",
                "way": "消息加解密方式",
                "inputWay": "请选择加解密方式",
                "QRCode": "公众号二维码",
                "plain": "明文模式",
                "safe": "安全模式",
                "compatible": "兼容模式",
                "active": "激活",
                "activeTips": '打开 “激活” 开关后，在设置联系人组时，绑定微信即可弹出公众号的二维码提供给用户扫描并关注',
                "getQRCode": "获取当前微信二维码",
                "tips": "设置微信后，云管平台可通过该端口在微信中发送告警等系统信息给用户",
                "desc1": "1、该功能支持填写微信 “服务号” 和微信 “测试号” 的 “开发者ID” 和 “开发者密码”。",
                "desc2": "2、如果填写的是测试号的ID和密码，收到的告警信息会在微信 “订阅号” 中显示，并且会有部分手机出现收到消息时没有提示声的情况。",
                "saveSuccess": "微信设置成功",
            },
            "networksManage":{
                "interfaceName": "接口名称",
                "add": "添加",
                "delete": "删除",
                "insTenantPhyNet": "云主机可用租户物理网络",
                "insExternalPhyNet": "云主机可用外部物理网络",
                "phyHostPhyNet": "裸金属可用物理网络",
                "netInUse": "使用中的网络不可删除",
                "noAvailableNet": "没有可添加的网络",
                "placeholder":{
                    "interface":"请选择外部物理网络",
                    "noInsExternalPhyNet":"没有可用的外部物理网络"
                }
            },
            "modifyPassword": {
                "username": "用户名",
                "oldPassword": "原密码",
                "newPassword": "新密码",
                "confirmNewPassword": "确认新密码",
                "oldPasswordEmpty": "原密码不可为空",
                "oldPasswordError": "原密码输入错误",
                "newPasswordEmpty": "新密码不可为空",
                "newPasswordDiffer": "新密码输入不一致",
                "modifyPasswordFail": "密码修改失败",
                "special_8_16": "密码长度为8-16个字符且至少包含一个大写字母，一个小写字母，一个数字和一个特殊字符。特殊字符包括! - @ . ^ _ % , : /",
                "pleaseInputOldPassword": "请输入原密码",
                "pleaseInputNewPassword": "请输入新密码",
                "pleaseInputNewPasswordAgain": "请再次输入新密码",
                "modifyFrequent": "该操作过于频繁，请两分钟后再试",
                "modifying": "正在验证和修改",
                "modifySuccess": "修改成功，请重新登录",
                "firstStep": "请先填写原密码",
                "equalCheck": "新密码与原密码相同",
                "newUserModifyPwd":"新用户修改密码",
                "pwdExpiredModifyPwd":"密码过期修改密码",
                "newUserToModifyPwd":"新用户需修改密码后才能使用云管",
                "modifyPwdSuccess":"修改密码成功，请重新登录",
            },
            "systemInspection":{
                    "term":"项",
                    "startExamination":"开始巡检",
                    "pauseExamination":"暂停巡检",
                    "stop":"终止",
                    "examinationing":"巡检中",
                    "stopExaminationing":"终止巡检中",
                    "exportReport":"导出巡检报告",
                    "inspectionItem":"检查项",
                    "expectedResults":"预期结果",
                    "actualResults":"实际结果",
                    "status":"状态",
                    "inspectionResultStatistics":"巡检结果统计",
                    "inspectionTime":"巡检用时",
                    "inspectionItem":"巡检项",
                    "normal":"正常",
                    "abnormal":"异常",
                    "abnormalInspectionResults":"巡检结果异常统计",
                    "inspectionResults":"巡检结果",
                    "NORMAL":"正常",
                    "EXCEPTION":"异常",
                    "FAILURE":"失败",
                    "INAPPLICABLE":"不适用",
                    "millisecond":'毫秒',
                    "second":'秒',
                    "minute":"分钟",
                    "hour":"小时",
                    "day":"天",
                    "checkFail":"巡检失败",
                    "inspectionType":"巡检类型",
                    "errorDetails":"异常详情"
            },
            "objectSetting":{
                "name":"名称",
                "accessKey":"访问密钥",
                "secretKey":"私密秘钥",
                "endpoint":"端点",
                "signerVersion":"签名版本",
                "type":"类型",
                "user":"用户",
                "tips":"配置对象存储后,可对对象存储进行操作",
                "source":"用户类型",
                "departmentName":"部门",
                "projectName":"项目",
                "innerUser":"内部用户",
                "externalUser":"外部用户",
                "placeholder": {
                    "name":"请输入名称",
                    "department":"请选择部门",
                    "project":"请选择项目",
                    "userSelect":"请选择用户",
                    "user":"请输入用户",
                    "accessKey":"请输入访问密钥",
                    "secretKey":"请输入私密秘钥",
                    "endpoint":"请输入端点",
                    "signerVersion":"请选择签名版本",
                    "accessKeySelect":"请选择访问密钥",
                },
                "delTip":"确定删除所选内容吗？",
                "userSelectTip":"请先到对象存储用户中新建用户"
            },
            "adServer":{
                "serverPath":"LDAP服务器访问路径",
                "adminAccount":"管理员账号",
                "passwd":"账号密码",
                "enable":"激活",
                "header":"未激活时不会验证当前所填的配置信息",
                "tips1":"请输入LADP服务器访问路径",
                "tips2":"请至多输入100位",
                "tips3":"请输入管理员账号",
                "tips4":"请输入账号密码",
                "tips5":"请至多输入50位",
                "tips6":"连接AD域成功",
                "tips7":"连接AD域失败,请检查当前配置",
                "tips8":"关闭AD会导致用户丢失和用户无法登录问题,确定关闭吗?",
                "tips9":"开启AD是不可逆操作，请确认是否开启?",
                "tips10":"请输入组织单位/容器",
                "orgUnit":"组织单位/容器",
            },
            "objectUser":{
                "userName":"用户名称",
                "sizeQuota":"总容量配额(kb)",
                "bucketQuota":"总存储桶配额(个)",
                "objectQuota":"总对象数配额(个)",
                "noLimit":"不限制",
                "sizeQuotaTip":"用户最大使用容量，-1不限制",
                "bucketQuotaTip":"用户最大桶创建个数，0表示不限制",
                "objectQuotaTip":"用户最大存储对象个数，-1不限制",
                "adminDeleteTip":"默认用户不能删除",
                "createUser":"新建用户",
                "editUser":"编辑用户",
                "deleteTip": "确定删除所选内容吗？",
                "userDetail": "用户详情",
                "accountInfo": "账户信息",
                "accessKeyManage": "对象存储密钥管理",
                "userDetail": "用户详情",
                "accessKey": "访问密钥",
                "secretKey": "访问私钥",
            },
            "sysBackup":{
                "onekeyBackup":"一键备份",
                "backupName":"备份名称",
                "backupType":"备份类型",
                "status":"状态",
                "currentBackupTime":"当前备份时间",
                "nextBackupTime":"下次备份时间",
                "issuing":"下发中",
                "issued":"已下发",
                "received":"已接收",
                "backuping":"备份中",
                "success":"成功",
                "fail":"失败",
                "sendFailed":"发送失败"
            },
            "cephpg":{
                "tips":"存储池的比例值总和必须为100%",
                "poolName":"存储池名称",
                "ratioValue":"比例值",
                "desc":"描述",
                "rgw_root":"对象存储系统池",
                "rgw_root_des":"用于存放对象存储元数据、存储桶索引信息",
                "vms":"云主机",
                "vms_des":"用于存放云主机config drive信息",
                "volumes":"云硬盘",
                "volumes_des":"用于存放云硬盘",
                "images":"镜像",
                "images_des":"用于存放创建云主机所需的镜像",
                "cloud_backups":"云平台资源备份",
                "cloud_backups_des":"用于存放云平台备份",
                "rgw":"对象存储池",
                "rgw_des":"用于存放对象存储数据",
                "placeholder":{
                    "rgw_root":"请输入对象存储系统池比例值",
                    "vms":"请输入云主机比例值",
                    "volumes":"请输入云硬盘比例值",
                    "images":"请输入镜像比例值",
                    "cloud_backups":"请输入云平台资源备份比例值",
                    "rgw":"请输入对象存储池比例值"
                },
                "cephpgLoading":"存储池比例值调整中",
                "cephpgSuccess":"存储池比例值调整成功",
                "cephpgFailed":"存储池比例值调整失败"
            }
        },
        "register": {
            "reg": {
                "haveAccount": "我有登录账号",
                "backlogin": "返回登录",
                "register": "用户注册",
                "EAccount": "企业账号",
                "inputaccount": "请输入企业账号",
                "required": "必填项",
                "char4_16": "请输入4-16个字符，允许有英文字母、数字和下划线",
                "char4_16_cn": "请输入4-16个字符，允许有数字，字母，下划线，中文",
                "accountexit": "账号已存在",
                "EName": "企业名称",
                "ENamesetting": "设置企业名称",
                "email": "电子邮箱",
                "emailSet": "设置邮箱",
                "emailtest": "请输入正确的邮箱格式,例如example@mycompany.com",
                "tel": "联系电话",
                "telinput": "+86 请输入手机号码",
                "formaterror": "格式不正确",
                "passwd": "登录密码",
                "passwdSet": "设置密码",
                "passwd6": "密码位数至少6位",
                "passwd16": "密码位数不能超过16位",
                "confirmPasswd": "确认密码",
                "inputpasswdagain": "再次输入密码",
                "passwddiff": "两次输入密码不一致",
                "confirm": "确定",
                "servererror": "服务器错误",
                "succeed": "恭喜您注册成功",
                "loginname": "您的登录名为",
                "regionKey": "您的regionKey为",
                "backloginpage": "返回登录页"
            },
            "comp": {
                "progress": "30% Complete",
                "ceph": "分布式存储",
                "keystone": "身份认证",
                "glance": "镜像",
                "cinder": "块存储",
                "nova": "计算",
                "neutron": "网络",
                "failed": "部署失败",
                "playing": "正在安装中",
                "restart": "重新部署",
                "detail": "查看配置详情",
                "reallyRestart": "您确定要重新部署吗?",
                "tosass": "前往控制中心",
                "success": "云环境部署成功",
                "successAll": "您的私有云环境已经部署完毕，现在可以前往控制中心,进行操作.",
                "start": "您的云环境已经开始创建",
                "decr": "该安装过程可能会受到您的网络环境、设备环境的影响出现安装缓慢的情况，请您耐心等待！"
            },
            "info": {
                "core":"核",
                "hostChoice": "选择主机",
                "netSetting": "网络设置",
                "accSetting": "账号设置",
                "infoSummary": "信息汇总",
                "hostName": "主机名",
                "area": "区域",
                "ManagementNetwork": "管理网",
                "cpu": "CPU(核)",
                "mem": "内存（GB）",
                "next": "下一步",
                "iprange": "IP范围",
                "clusternetwork": "集群网",
                "start": "起始",
                "iperrorformat": "ip格式不正确",
                "ipnotnone": "ip不能为空",
                "end": "结束",
                "vlan": "VLAN",
                "vlanstart": "起始VLAN",
                "vlanend": "结束VLAN",
                "addRangeIp": "增加IP范围",
                "netmask": "子网掩码",
                "inputint": "请输入正整数",
                "Storagenetwork": "存储网",
                "TenantNetwork": "租户网",
                "required": "必填项",
                "extNetwork": "外网",
                "last": "上一步",
                "Administrator": "管理员账号",
                "char4_16": "请输入4-16个字符，允许有英文字母、数字和下划线",
                "special_8_16": "密码长度为8-16个字符且至少包含一个大写字母，一个小写字母，一个数字和一个特殊字符。特殊字符包括! - @ . ^ _ % , : /",
                "accountexit": "账号已存在",
                "passwd": "密码",
                "passwd6": "密码位数至少6位",
                "passwd16": "密码位数不能超过16位",
                "confirm": "确认密码",
                "passwddiff": "两次输入密码不一致",
                "type": "类型",
                "IPaddresses": "IP地址段",
                "vlantag": "VLAN标签",
                "areaConf": "区域配置",
                "EAdministrator": "企业管理员账号",
                "account": "账号",
                "deployment": "开始部署",
                "floatingip": "浮动ip",
                "floatinggateway": "网关",
                "cidr": "CIDR",
                "cluster": "集群网",
                "storage": "存储网",
                "tenant": "租户网",
                "floating": "外网",
                "rangeTotal": "IP地址数量不够",
                "rangeSame": "IP地址范围有重复"
                    /*20170223*/
                    //"copyright":window.SETTING.COPYRIGHT
            },
            "login": {
                "Eguestlogin": "企业客户登录",
                "account": "企业云账号",
                "user": "企业名称",
                "required": "必填项",
                "passwd": "密码",
                "login": "登录",
                "userorpasswderror": "用户名或密码错误",
                "servererror": "服务器错误",
                "forgetpasswd": "忘记密码",
                "aws_account": "还没有AWSTACK账号？",
                "register": "立即注册"
            }
        },
        "overview": {
            "pageMsg":"{{page}}条/页",
            "expireMsg":"云平台（数据中心：{{DC}}）离激活有效期截止还剩{{sky}}天（License的到期时间为最后一天的下午4：00）",
            "cluster":"集群网",
            "tenant":"租户网",
            "storage":"存储网",
            "floating":"管理网",
            "datacluster":"数据中心",
            "dataclusterOverview":"数据中心概况",
            "situation":"全局",
            "virtualServer":"虚拟化服务器",
            "physicalHost":"物理裸机",
            "getdatafail":"无法获取该数据中心数据",
            "normal":"正常",
            "localdisk":"本地磁盘",
            "error":"异常",
            "run":"运行",
            "poweroff":"关机",
            "falut":"故障",
            "other":"其他",
            "unit":"单位",
            "name":"名称",
            "total":"总数",
            "label":"标签",
            "memrate":"内存使用量(GB)",
            "storagerate":"存储使用量",
            "iprate":"公网IP使用量(个)",
            "externalip":"公网IP",
            "cpurate":"CPU使用量(核)",
            "nodata":"暂无数据",
            "totalCore":"总核数",
            "alarmNews": "告警信息",
            "individual":"个",
            "welcome": "欢迎",
            "admin": "admin",
            "domain": "部门",
            "project": "项目",
            "network": "网络",
            "phyHost": "物理主机",
            "totalNumber": "总数量",
            "hostName": "主机名",
            "vmHostNumber": "云主机数量",
            "vmHost": "云主机",
            "domainVmhostQuota": "部门云主机配额",
            "cpuUsageRate": "CPU使用量",
            "totalCores": "总核数",
            "memoryUsageRate": "内存使用量",
            "storagesUsageRate": "存储使用量",
            "statedistribution":"云主机状态",
            "createinstance":"新建云主机",
            "createdisk":"新建云硬盘",
            "createuser":"新建用户",
            "createnetwork":"新建私有网络",
            "createvpn":"VPN",
            "totalMemory": "总容量",
            "storageUsageRate": "硬盘使用量",
            "alarmEvent": "告警事件",
            "viewMore": "查看全部",
            "noAlarmEvent": "暂无告警事件",
            "ticketsInfo": "工单信息",
            "noTickets": "暂无工单",
            "noLogs": "暂无日志",
            "logsInfo": "日志信息",
            "departList": "部门列表",
            "RDdepart": "研发部门",
            "allPros": "所有项目",
            "todo": "待办事项",
            "Alarms": "云监控告警",
            "privateAlarms": "私有云监控告警",
            "PublicAlarms": "公有云监控告警",
            "physicalNum": "裸金属数量",
            "Tai": "(台)",
            "physicalMem": "裸金属的内存",
            "memOverview": "内存概况",
            "cpu": "CPU概况",
            "publicRes": "公有云资源",
            "addpublicRes": "添加公有云资源",
            "please": "您还可以",
            "overview": "概况",
            "registed": "已注册",
            "unRegisted": "未注册",
            "allocated": "已分配",
            "unAllocated": "未分配",
            "inUsed": "已使用",
            "unUsed": "未使用",
            "remind_front": "平台离服务有效期截止还剩",
            "remind_rear": "天，请尽快续期。",
            "recycleBin":"回收站",
            "includesRecycle":"含回收站内",
            "networkNumber":"网络数量",
            "routerNumber":"路由器数量"
        },
        "log": {
            "daily": "操作日志",
            "keyword": "关键字",
            "start_at": "开始时间",
            "end_at": "结束时间",
            "search": "查询",
            "type": "操作类型",
            "oprator": "操作人",
            "detail": "操作详情",
            "status": "操作结果",
            "success": "成功",
            "others": "其他",
            "time": "操作时间",
            "fontPage": "首页",
            "endPage": "尾页",
            "export": "导出",
            "logLevel": "日志级别",
            "exportCurrentPage": "导出当前",
            "exportAllPage": "导出全部",
            "loginIp":"操作源IP",
            "selectStatus": "请选择状态",
            "selectKeywordtype": "选择搜索类型",
            "mac": "操作源MAC",
            "sourcetype": "资源类型"
        },
        "loginlogs": {
            "user": "登录用户",
            "username": "操作人",
            "ip": "登录源IP",
            "option": "操作类型",
            "result": "操作结果",
            "detail": "操作详情",
            "mac": "登录源MAC",
            "time": "操作时间",
            "hostname": "登录源主机名",
            "loginresult": "登录结果",
            "loginintime": "登录时间",
            "loginouttime": "退出时间",
            "logintime": "登录时长",
            "loginoutway": "退出方式",
            "originIP": "源IP",
            "originMac": "MAC地址",
            "host": "主机地址",
            "allstatus": "所有状态",
            "success": "成功",
            "fail": "失败",
            "activelyExit":"主动退出",
            "sessionExpire":"会话到期，强制退出",
            "userIsLocked":"用户被锁定，强制退出",
            "singleTerminalLogin":"单终端登录（第二终端上线），当前终端被强制退出",
            "browserClose":"浏览器关闭，强制退出",
            "second":"秒",
            "minute":"分",
            "hour":"小时"
        },
        "operatelogs":{
            "ip": " 操作源IP",
            "mac": "操作源MAC"
        },
        "auth": {
            "noPermissions": "没有访问权限",
            "account": "企业云帐号登录",
            "user": "用户名",
            "inputusername": "请输入用户名",
            "passwd": "密 码",
            "inputpasswd": "请输入密码",
            "userorpasswderror": "用户名或密码错误",
            "servererror": "服务器错误，请与管理员联系。",
            "ipOut": "用户所在IP地址被拒绝访问",
            "noProject": "权限不足，请联系管理员为该用户分配项目",
            "login": "登录",
            "forgetpasswd": "忘记密码",
            "aws_account": "还没有AWSTACK账号？",
            "register": "立即注册",
            "admin": "管理员帐号",
            "enterpriseName": "企业名称",
            "inputEnterpriseName":"请输入企业名称",
            "enterpriseNameRequired": "企业名称不能为空",
            "verificationCode":"验证码",
            "inputVerificationCode":"请输入验证码",
            "loginOverTimes": "登录错误超过",
            "pleaseWait": "次，请等待",
            "minuteTryAgain": "重试",
            "tryAgin": "请重新登录",
            "repeatLogin": "该用户已经登录",
            "sysUpdate": "平台正在升级，请稍后登录使用",
            "codeInvalid": "验证码已失效，请重新输入",
            "codeError": "验证码错误，请重新输入",
            "userNoRole": "用户不存在或未绑定角色",
            "userForbidden": "用户被禁用",
            "hadLoginFailed": "您已错误登录",
            "times": "次",
            "left":"还有",
            "userWillLocked":"用户将被锁定",
            "second":"秒",
            "minute":"分钟",
            "pwdExpired":"您的密码使用已到达",
            "day":"天，",
            "updatePwd":"请修改密码后再使用云管",
            "loginAgain":"该用户在其他地方登录，您已退出登录！",
            "userLocked":"该用户已被锁定",
            "email":"邮箱",
            "inputemail": "请输入邮箱",
            "getEmailLoginCode": "获取验证码",
            "accountLogin": "账号登录",
            "emailLogin": "邮箱登录",
            "enterpriseNameOrEmailError": "企业名称或邮箱不正确",
            "emailCodeError": "验证码错误",
            "switchAccountLogin": "切换账号登录",
            "switchEmailLogin": "切换邮箱登录",
            "registraInit":"注册后初始化"
        },
        /*20170223*/
        "reg": {
            //"title": "AWSTACK",
            //"copyright": "Copyright © 2012-2017 北京海云捷迅科技有限公司京ICP备14031291号 《中华人民共和国增值电信业务经营许可证》编号: 京B2-20140382"
        },
        "qcloud": {
            "instances": {
                "updateConfigMsg":"修改负载均衡的配置会造成流量暂时的中断",
                "specificationMsg":"负载均衡资源会占用用户的资源及配额，默认的磁盘大小为20G",
                "networkMsg":"外部网络使用此网络与云平台通信，必须保证外部网络与云平台的网络连通性，支持指定IP地址。",
                "intranetmsg":"请选择私有网络",
                "assign":"指定",
                "recordMigration":"迁移记录",
                "instance": "腾讯云主机",
                "new": "新建",
                "boot": "启动",
                "stop": "关机",
                "moreOps": "更多操作",
                "restart": "重启",
                "forceShupdown": "强制关机",
                "del_instance": "删除云主机",
                "attachVolume": "加载云硬盘",
                "edit": "编辑",
                "addNIC": "添加网卡",
                "moveNIC": "移除网卡",
                "bindingPublicIP": "绑定公网IP",
                "releasingPublicIPBindings": "解除公网IP",
                "makeImg": "制作镜像",
                "search": "搜索",
                "projectName": "项目名称",
                "instanceName": "主机名",
                "imgName": "镜像名称",
                "privateIP": "私网IP",
                "publicIP": "公网IP",
                "status": "状态",
                "create_at": "创建时间",
                "bootMethod": "启动方式",
                "cloudInstanceDetail": "云主机详情",
                "cloudInstanceName": "云主机名称",
                "image": "镜像",
                "configration": "配置",
                "NIC": "网卡",
                "volume": "云硬盘",
                "firewall": "防火墙",
                "headerCheckbox": {},
                "os": "操作系统",
                "cvmPayMode": "计费方式",
                "diskInfo": "磁盘信息",
                "deviceClass": "设备类型",
                "addinstances": {
                    "snapshot": "快照",
                    "newInstance": "新建云主机",
                    "detail": "基本信息",
                    "instanceName": "主机名称",
                    "instanceNameInput": "请输入主机名称",
                    "required": "必填项",
                    "num": "数量",
                    "imgChoice": "选择镜像",
                    "os": "操作系统",
                    "arch": "架构",
                    "imgNameChoice": "选择镜像名称",
                    "type": "类型",
                    "conf_option": "配置类型",
                    "conf_type": "配置类型",
                    "net_setting": "网络设置",
                    "name": "名称",
                    "netChoice": "选择网络",
                    "advOption": "高级选项",
                    "selfConfig": "云主机规格",
                    "choiceConfig": "选择配置",
                    "passwd": "密码",
                    "repeatPasswd": "重复密码",
                    "notSamePasswd": "密码不一致",
                    "keypair": "密钥对",
                    "keypairChoice": "选择密钥对",
                    "firewallChoice": "选择防火墙",
                    "launchArea": "启动区域",
                    "launchAreaChoice": "选择启动区域",
                    "launchNode": "启动节点",
                    "launchNodeChoice": "选择启动节点",
                    "localStorage": "本地存储",
                    "conf_detail": "配置详情",
                    "memory": "内存",
                    "ok": "确定",
                    "cancel": "取消",
                    "core": "核"
                },
                "net": {},
                "osedit": {
                    "editInsName": "编辑主机名"
                },
                "FloatingIp": {
                    "ipAddr": "IP地址"
                },
                "disk": {
                    "volumeChoice": "选择硬盘"
                },
                "mkImg": {
                    "makeImg": "制作成镜像"
                },
                "table": {
                    "status": {
                        "1": "故障",
                        "2": "运行",
                        "3": "创建中...",
                        "4": "关机",
                        "5": "已退还",
                        "6": "退还中",
                        "7": "重启中",
                        "8": "开机中",
                        "9": "关机中",
                        "10": "密码重置中",
                        "11": "格式化中",
                        "12": "镜像制作中",
                        "13": "带宽设置中",
                        "14": "重装系统中",
                        "15": "域名绑定中",
                        "16": "域名解绑中",
                        "17": "负载均衡绑定中",
                        "18": "负载均衡解绑中",
                        "19": "升级中",
                        "20": "密钥下发中",
                        "21": "热迁移中"
                    },
                    "c_status": {
                        "1": "error",
                        "2": "active",
                        "3": "building",
                        "4": "stopped",
                        "8": "powering-on",
                        "9": "powering-off"
                    },
                    "Local": "本地",
                    "cvmPayMode": {
                        0: "按月结算的后付费",
                        1: "包年/包月",
                        2: "按量计费"
                    },
                    "diskInfo": {
                        "storageType": {
                            1: "本地盘",
                            2: "云盘"
                        }
                    }
                },
                "confName": {
                    "m1.tiny": "标准配置",
                    "m1.small": "中级配置",
                    "m1.medium": "高级配置"
                },
                "conf": {
                    "m1.tiny": "标准配置: CPU 1核 内存 512M 支持云硬盘11111",
                    "m1.small": "中级配置: CPU 1核 内存 2G 支持云硬盘2222",
                    "m1.medium": "高级配置: CPU 2核 内存 4G 支持云硬盘3333",
                    "pretip": "高级配置: CPU ",
                    "memtip": "核 内存 ",
                    "endtip": "G 支持云硬盘"
                }
            }
        },
        "workflow": {
            "addressee": "收件人",
            "title": "标题",
            "informationTitle": "请输入信息咨询标题",
            "informationDescription": "请输入描述信息",
            "resourceApply": "资源申请",
            "handleTitle": "工单处理",
            "applyDetail": "申请详情",
            "applytype": "类型",
            "name": "流程名称",
            "statusName": "名称",
            "transactor": "办理人",
            "dueTime": "处理时间",
            "createTime": "创建时间",
            "accomplish": "完成",
            "ticketName": "工单类型",
            "edit": "编辑",
            "tiName": "名称",
            "value": "值",
            "bind": "绑定",
            "bindwork": "绑定工作流",
            "description": "描述信息",
            "deployed": "已部署",
            "workDep": "部署流程",
            "model": "待部署",
            "version": "版本",
            "type": "任务类型",
            "handling": "待处理",
            "handled": "已处理",
            "start": "部署",
            "lastUpdateTime": "编辑时间",
            "depart": "选择组织",
            "department": "选择部门",
            "curStep": "当前环节 / 回复数",
            "handlecurStep": "当前环节",
            "user": "选择成员",
            "modelName": "模型名称",
            "approval": "是否批准",
            "apply": "申请",
            "applied": "已申请",
            "action": "操作",
            "status": "审批状态",
            "detail": "审批详情",
            "result": "审批结果",
            "seeticket": "查看",
            "ticket": "工单类型",
            "ticketApply": "工单申请",
            "initialAssigneeName": "申请人",
            "applyTime": "申请时间",
            "proTime": "处理时间",
            "tickDec": "换绑手机/邮箱、实名认证、找回用户名/密码等相关问题",
            "resDec": "虚拟机,内存,CPU等虚拟资源申请",
            "tickTitle": "信息咨询",
            "resTitle": "资源申请",
            "resButton": "由此申请",
            "tickButton": "由此提问",
            "workTitC": "新建流程",
            "true": "已结束",
            "false": "审批中",
            "0": "一级审批",
            "1": "二级审批",
            "2": "三级审批",
            "3": "四级审批",
            "project": "选择项目",
            "projectName": "项目名称",
            "departName": "部门名称",
            "qutaError": "不能超过项目上限",
            "variables": {
                "name": "名称",
                "type": "类型",
                "applyUserId": "申请人",
                "description": "审批描述",
                "ticketApply": "工单申请",
                "resourceApply": "资源申请",
                "quota": {
                    "instances": "云主机(个)",
                    "cores": "CPU核(个)",
                    "ram": "内存(MB)",
                    "ramGb": "内存(GB)",
                    "snapshots": "快照(个)",
                    "floatingip": "公网IP(个)",
                    "volumes": "云硬盘(个)",
                    "gigabytes": "硬盘(GB)",
                    "cpuCore":"CPU（核数）",
                    "memoryGb":"内存（GB）",
                    "diskCapacityGb":"云硬盘容量(GB)"
                }
            },
            "deleteReason": {
                "wait": "等待审批",
                "deleted": "删除审批",
                "completed": "完成审批"
            },
            "operations": {
                "close": "关闭",
                "closed": "已关闭",
                "approving": "审批中"
            },
            "wfName":"工作流名称",
            "ticketType":"工单类型",
            "range":"适用范围",
            "email":"邮件通知",
            "wfDescription":"描述",
            "other":"其他",
            "applyResourceDetails":"申请资源详情",
            "routeSubNetwork":"路由器子网",
            "comment":"评论",
            "commentTip":"回复评论不能为空，请重新输入",
            "rejectAndClose":"拒绝并关闭",
            "createManuallyLater":"稍后手动创建",
            "agree":"同意",
            "agreeAndCreate":"同意并创建",
            "processProgress":"处理进度",
            "launching":"发起工单",
            "selectWorkOrderType":"选择工单类型"
        },
        "ticket": {
            "deleteTicketMsg":"您确定要删除所选工单吗？",
            "openTicketMsg":"您确定要重新打开所选工单吗？",
            "resourceDetail": "资源申请详情",
            "approval": "已批准",
            "refuse": "已拒绝",
            "domainUid": "部门名称",
            "projectname": "项目名称",
            "refuseReason": "拒绝原因",
            "approvalResult": "审批结果",
            "reply": "回复",
            "allReply": "全部回复",
            "replyPlaceholder": "请填写回复内容",
            "curStep": "当前环节 / 回复数",
            "startTime": "开始时间",
            "endTime": "结束时间",
            "status": "状态",
            "statusAction": "状态 / 操作",
            "description": "描述",
            "ok": "确定",
            "cancel": "取消",
            "instanceApply": "云主机申请",
            "informationType": "信息咨询",
            "resourceType": "资源申请",
            "approvalStyle": {
                "notpass": "不通过",
                "pass": "通过",
                "unapproved": "审批中",
                "yes": "是",
                "no": "否",
                "close": "已关闭",
                "0":"审批中",
                "1":"已同意",
                "2":"已拒绝",
                "3":"已撤销"

            },
            "applyTypes":{
                "1":"配额申请",
                "2":"云主机",
                "3":"云硬盘",
                "4":"网络",
                "5":"路由器",
                "6":"vpn",
                "7":"负载均衡",
                "8":"弹性伸缩",
                "9":"物理机",
                "10":"云主机续期",
                "11":"信息咨询"
            },
            "applyName":{
                "1":"配额申请",
                "2":"云主机申请",
                "3":"云硬盘申请",
                "4":"网络申请",
                "5":"路由器申请",
                "6":"vpn申请",
                "7":"负载均衡申请",
                "8":"弹性伸缩申请",
                "9":"裸金属申请",
                "10":"云主机续期申请",
                "11":"信息咨询"
            },
            "resApply":{
                "network":{
                    "0":"网络",
                    "1":"子网"
                }
            },
            "domain":"部门",
            "applyUserName":"申请用户",
            "ticketName":"工单名称",
            "applyType":"工单类型",
            "actionStatus":"处理状态",
            "ticketNum":"工单编号",
            "servename":"服务名称",
            "ticketTitle":"标题",
            "approver":"申请人",
            "approveTime":"申请时间",
            "ticketAction":"操作",
            "endTime":"结束时间",
            "status_action":"状态/操作",
            "ticketStatus":"工单状态",
            "type":"类型：",
            "remarkInformation":"备注信息",
            "otherInformation":"其它-信息咨询",
            "examineAndApprove":"审批",
            "resourceApply":"资源申请-",
            "pending":"待审批",
            "applyCancel":"撤销申请",
            "isApplyCancel":"是否撤销该资源申请？",
            "approvedAgree":"审批通过",
            "alreadyApproved":"已审批",
            "enterWorkName":"输入工单名称",
            "handler":"处理人",
            "selectHandler":"选择处理人",
            "selectProcessingStatus":"选择处理状态",
            "workOrderReport":"工单报表.csv",
            "undergoing":"正在进行"
        },
        "monitor": {
            "alarmModule": {
                "ruleName": "规则名称",
                "warnRange": "警告范围",
                "vmstatetitle": "云主机状态变更监控",
                "isVmopen": "是否开启",
                "vmerrortitle": "云主机异常监控",
                "lowMin": "5分钟",
                "midMin": "10分钟",
                "heightMin": "15分钟",
                "cycle": "周期",
                "level": "级别",
                "alarm": "告警",
                "alarmEvent": "告警事件",
                "addalarm": "添加告警",
                "newAlarmEvent": "新告警事件",
                "oldAlarmEvent": "历史告警事件",
                "hostName": "主机名",
                "alarmType": "告警类型",
                "alarmReason": "告警原因",
                "alarmTime": "告警时间",
                "severity": "严重等级",
                "handleStatus": "处理状态",
                "operate": "操作",
                "handle": "处理",
                "ignore": "忽略",
                "handleTime": "处理时间",
                "handleDescription": "处理描述",
                "alarmHandleDes": "告警处理描述",
                "unprocessed": "未处理",
                "processed": "已处理",
                "critical": "严重",
                "moderate": "适中",
                "low": "低",
                "threshold": "阈值",
                "vmstate": "状态",
                "service": "服务",
                "healthcheck": "健康检查",
                "computeha": "高可用",
                "planwork": "计划任务",
                "hardware": "硬件故障",
                "cephCheck": "ceph健康检查",
                "ignoreAlarm": "确定忽略此条告警？",
                "ignoreAlarms": "确定忽略这些告警？",
                "alarmSetting": "告警设置",
                "alarmName": "告警名称",
                "resourceType": "资源类型",
                "template": "告警模板",
                "label": "标签",
                "resource": "资源",
                "contact": "联系人",
                "enabled": "启用",
                "choseResType": "选择资源类型",
                "choseResource": "选择资源",
                "choseSubResource": "选择子资源",
                "choseTemplate": "选择模板",
                "choseContactGroup": "选择联系人组",
                "triggerWay": "触发方式",
                "normalToAlarm": "正常到告警",
                "alarmToNormal": "告警到正常",
                "alarmAction": "告警动作",
                "mail": "邮件",
                "email": "邮箱",
                "weChat": "微信",
                "wechatName": "微信昵称",
                "weChatBind": "绑定微信",
                "addWeChatContact": "添加微信联系人",
                "notBandTipsAdmin1": "当前您没有绑定微信服务端，暂时不能使用此功能，请先到系统管理 > 系统设置 > ",
                "notBandTipsAdmin2": "绑定微信服务端，开启激活。",
                "notBandTipsAdmin3": "当前您没有绑定微信服务端，暂时不能使用此功能，请先到系统管理 > 配置管理 > ",
                "notBandTipsUser": "当前您没有绑定微信服务端，暂时不能使用此功能，请联系管理员绑定微信服务端，开启激活。",
                "prompts": "温馨提示：",
                "promptsMsg1": "1、请用微信扫描上方二维码",
                "promptsMsg2": "2、您需关注我们的微信服务号才能收取系统消息",
                "promptsMsg3": "3、如果二维码显示有误，请确认微信设置是否正确",
                "checkNickName": "验证昵称",
                "cancelCheck": "取消验证",
                "weChatCheckTitle": "微信昵称验证",
                "nickName": "昵称",
                "location": "地区",
                "message": "短信",
                "phone": "手机",
                "physicalHost": "物理主机",
                "vmHost": "云主机",
                "yes": "是",
                "no": "否",
                "creatNewAlarm": "新建告警",
                "editAlarmSetting": "编辑告警设置",
                "copyAlarm": "复制告警",
                "enableAlarm": "您确定要启用该告警吗？",
                "forbiddenAlarm": "您确定要禁用该告警吗？",
                "deleteAlarm": "您确定要删除所选告警吗？",
                "alarmTemplate": "告警模板",
                "setRule": "设置规则",
                "templateName": "模板名称",
                "setAlarmTmplRule": "设置告警模板规则",
                "addThresholdRule": "添加阈值规则",
                "newCreatAlarmTmpl": "新建告警模板",
                "editAlarmTmpl": "编辑告警模板",
                "mean": "平均值",
                "max": "最大值",
                "min": "最小值",
                "deleteTmpls": "您确定删除所选告警模板？",
                "delThresholdRule": "您确定删除该告警模板规则？",
                "contactTips": "邮箱、手机、微信均不能超过10条记录",
                "contactGroup": "联系人组",
                "editContact": "编辑联系人",
                "newCreatContactGroup": "新建联系人组",
                "editContactGroup": "编辑联系人组",
                "delContactGroup": "您确定删除所选联系人组？",
                "delContact": "您确定删除所选联系人？",
                "inputSameEmail": "不可输入相同的邮箱",
                "addNewRule":"添加新规则",
                "alarmConflict":"不同告警级别之间告警值有冲突,请重新设值",
                "alertInfo":"以下每条告警规则都将独立存在，触发任意一条都会产生相应告警。",
                "alertInfo2":"云主机状态变更监控和云主机异常监控不会在弹性扩展中生效",
                "gt":">",
                "ge":">=",
                "eq":"=",
                "le":"<=",
                "lt":"<",
                "monitorItem":"监控项",
                "threshold":"阈值",
                "triggerAlarm":"超过阈值后触发告警",
                "temperatureRange":"温度范围在50到200之间",
                "alarmAction":"告警方式",
                "tsdb":"CTSDB时序数据库",
                "snmp":"SNMP告警",
                "switchSnmp":"交换机SNMP",
                "security":"云镜",
                "tdsql":"TDSQL分布式数据库",
                "alauda":"TKE企业版",
                "coc":"织云",
                "AlaudaAlarm":"TKE企业版",
                "SecurityAlarm":"云镜",
                "TDSqlAlarm":"TDSQL分布式数据库",
                "TSDBAlarm":"CTSDB时序数据库",
                "COCAlarm":"织云",
                "alarmRule":"告警规则",
                "placeholder": {
                    "handleDescription": "请输入告警处理描述",
                    "requiredName": "请输入名称",
                    "choseResType": "选择资源类型",
                    "choseResMultiple": "选择资源(可多选)",
                    "choseRes": "选择资源",
                    "chooseRegion":"选择域(可多选)",
                    "noResToChose": "没有资源可以选择",
                    "chosePort": "选择子资源(可多选)",
                    "noPortToChose": "没有子资源可以选择",
                    "choseTmpl": "选择模板(可多选)",
                    "noTmplToChose": "没有模板可以选择",
                    "choseContactGroup": "选择联系人组(可多选)",
                    "noContactToChose": "没有联系人组可以选择",
                    "requiredTmplName": "请输入告警模板名称",
                    "requiredContactGroup": "请输入联系人组名称",
                    "phoneLength_11": "请输入11位的手机号码",
                    "wechatName": "请输入微信昵称",
                    "ipAdress":"请输入正确的IP地址",
                    "macAdress":"请输入正确的MAC地址"
                },
                "info":{
                    "macAdress":"请输入正确的MAC地址",
                    "enterTemplate":"请输入温度",
                    "selectAlarmAction":"请选择告警方式",
                    "alarmName":"用于标识告警设置。",
                    "choseResType":"选择资源类型",
                    "choseResource":"配置告警监控的资源名称。",
                    "choseTemplate":"选择模板",
                    "monitorItem":"选择模板",
                    "choseContactGroup":"告警需要通知的联系人组邮箱或微信。",
                    "alarmAction":"接收告警通知信息方式。",
                    "enabled":"选中则在创建成功后立即启动，否则可以后续根据需要手工启动。",
                    "templateName":"",
                    "resourceType":"该告警模板针对的具体资源。",
                    "threshold":"当监控指标触发设定的阈值时会产生告警。由数据类型（原始值、平均值、最大值、最小值、求和值和方差值）、判断条件（>、≥、<、≤、=）、临界值三部分组成，用来配置告警条件，例如“平均值>80”就是一个告警条件。",
                    "cycle":"告警规则刷新告警状态的周期。",
                    "level":"根据告警的严重程度选择不同等级。",
                    "wechatName":"绑定微信前，请确认公众号相关信息已在微信后台配置完成。",
                },
            },
            "overview": {
                "phyalarm": "物理主机告警",
                "virtualalarm": "云主机告警",
                "phyTop5": "物理主机CPU使用率Top5",
                "virtualTop10": "云主机CPU使用率Top10",
                "resdistribute": "资源分配",
                "cpu": "CPU(个)",
                "mem": "内存(GB)",
                "storage": "存储(GB)",
                "virtual": "云主机(个)",
                "project": "项目(个)",
                "floatingip": "公网IP(个)",
                "alarm": "告警",
                "alarmTip":"(告警数量/主机数量)",
                "compare": {
                    "instances": "云主机统计对比",
                    "cpu": "CPU统计对比",
                    "mem": "内存统计对比",
                    "disk": "存储统计对比",
                    "project":"项目统计对比",
                }
            },

            "resview": {
                "regoin": "数据中心",
                "objType": "对象类型",
                "topType": "TOP类型",
                "search": "查询",
                "physical": "物理机",
                "virtual": "虚拟机",
                "instance": "云主机",
                "topHigh": "最高",
                "topLow": "最低",
                "cpuRate": "CPU使用率",
                "memoryRate": "内存使用率",
                "netReciveRate": "网络流入速率",
                "netSendRate": "网络流出速率",
                "diskIOWrite": "磁盘I/O写入",
                "diskIORead": "磁盘I/O读出",
                "diskUseRate": "磁盘使用率"
            },

            "physical": {
                "selectIP":"请选择IP",
                "phylist": "主机列表：",
                "os": "操作系统",
                "cpuModel": "处理器",
                "dataSearch": "数据查询",
                "kernel": "内核版本",
                "ip": "IP地址",
                "netband": "网络带宽",
                "netpacket": "网络数据包收发",
                "swapmem": "交换内存",
                "systemLoad": "系统负载",
                "memBand": "存储带宽",
                "noDataTips": "无法获取数据，请确认是否安装了监控客户端。",
                "physicalHost": "物理主机",
                "hostName": "主机名称",
                "durationTime": "持续时间",
                "diskTotal": "磁盘总量",
                "disketteDetail": "磁盘详情",
                "processMemoryFootprint": "进程占用内存",
                "processDetail": "进程详情",
                "processCPUfootprint": "进程占用CPU",
                "userName": "用户名",
                "processName": "进程名称",
                "processId": "进程ID",
                "mem": "内存(%)",
                "memory": "内存",
                "processUseCPUtime": "进程使用CPU时间",
                "disk": "磁盘",
                "diskPartition": "磁盘分区",
                "diskSpace": "磁盘性能",
                "netCard": "网卡",
                "day": "天",
                "days": "天",
                "hour": "小时",
                "hours": "小时",
                "minite": "分钟",
                "minites": "分钟",
                "collectCycle": "采集周期",
                "collectCycleSelect": "请选择采集周期",
                "instancesSelect":"请选择实例",
                "physicalConductor": "纳管裸金属",
                "resConductor": "裸金属资源池",
                "vmConductor": "虚拟化物理机",
                "guide1": "如需使用裸金属管理块功能，请打开“ 系统管理 > 系统设置 > ",
                "guide2": "功能管理",
                "guide3": " ”界面添加“ 裸金属管理 ”模块",
                "calc": "计算",
                "storage": "存储",
                "refreshRate": "刷新频率",
                "refreshRateSelect": "请选择刷新频率",
                "mainType": "请选择查询维度",
                "notRefresh": "不刷新",
                "5sec": "间隔5秒",
                "10sec": "间隔10秒",
                "30sec": "间隔30秒",
                "1min": "间隔1分钟",
            },

            "virtual": {
                "getClient":"获取监控客户端",
                "statedistribution": "云主机状态分布",
                "sum": "云主机总数",
                "virtualTop5": "云主机CPU使用率Top5",
                "name": "云主机名称",
                "hostname": "物理主机",
                "status": "状态",
                "createtime": "创建时间",
                "netband": "网络带宽",
                "netpacket": "网络数据包收发",
                "mem": "内存",
                "swapmem": "交换内存",
                "systemLoad": "系统负载",
                "memBand": "存储带宽",
                "vmhostStatus": {
                    "ACTIVE": "运行",
                    "POWERING-ON": "开机",
                    "STOPPED": "关机",
                    "SHUTOFF": "关机",
                    "POWERING-OFF": "关机",
                    "ERROR": "错误",
                    "BUILDING": "创建",
                    "DELETING": "删除",
                    "DELETED": "删除",
                    "REBOOT_PENDING": "重启",
                    "PAUSED": "暂停",
                    "PAUSING": "暂停",
                    "UNPAUSING": "恢复暂停",
                    "SUSPENDED": "挂起",
                    "SUSPENDING": "挂起",
                    "RESUMING": "恢复挂起",
                    "RESIZE_MIGRATING": "热升级",
                    "RESIZE_PREP": "热升级",
                    "RESIZE_FINISH": "热升级",
                    "RESIZED": "热升级",
                    "VERIFY_RESIZE": "调整配置中",
                    "IMAGE_BACKUP": "备份",
                    "IMAGE_SNAPSHOT": "制作镜像",
                    "SPAWNING": "创建",
                    "UP": "健康",
                    "DOWN": "异常",
                    "BUILD": "创建",
                    "REBOOT": "重启",
                    "MIGRATING": "热迁移",
                    "REBUILD": "重建",
                    "REBUILDING": "重建",
                    "UNKNOW": "错误",
                    "UNRECOGNIZED": "无法识别",
                    "EMPTY": "",
                    "RESIZE": "重置",
                    "OTHER": "其他",
                    "other": "其他"
                },
                "insTotal": "主机总数",
                "insDistribution": "云主机分布",
                "system": "系统"
            },

            "ceph": {
                "status": {
                    "HEALTH_OK": "良好",
                    "HEALTH_WARN": "警告",
                    "HEALTH_ERR": "错误"
                },
                "StatusCeph": "状态",
                "hostName": "物理主机名",
                "diskStorage": "磁盘存储",
                "poolsList": "Pools列表",
                "osdList": "OSD列表",
                "poolName": "Pool名称",
                "pgNumber": "PG数量",
                "objNumber": "对象数量",
                "copyNumber": "副本数",
                "totalStorage": "最大可用容量(GB)",
                "usage": "使用率",
                "currentServer": "所在服务器"

            },
            "pool_IOPS_peak":"Ceph池-IOPS(峰值)",
            "pool_IOPS_avg":"Ceph池-IOPS(平均)",
            "pool_rBandwidth_peak":"Ceph池-读带宽(峰值)",
            "pool_rBandwidth_avg":"Ceph池-读带宽(平均)",
            "pool_wBandwidth_peak":"Ceph池-写带宽(峰值)",
            "pool_wBandwidth_avg":"Ceph池-写带宽(平均)",
            "wuhan":"武汉",
            "beijing":"北京",
            "nodata": "暂无数据",

            "Nusage_idle": "CPU使用率",
            "usage_user": "用户CPU使用率",
            "cpu_usage_user": "用户CPU使用率",
            "usage_system": "系统CPU使用率",
            "cpu_usage_system": "系统CPU使用率",
            "usage_iowait": "iowait",

            "Percent_Idle_Time": "空闲率",
            "Percent_Processor_Time": "系统",
            "Percent_User_Time": "用户",

            "cpu_usage_percent": "CPU使用率",
            "cpu_usage_idle_percent":"idle",
            "cpu_usage_iowait_percent":"iowait",
            "cpu_usage_iowait_percent_max":"iowait",
            "activeConnections":"当前会话数",
            "responsesDenied":"拒绝响应数",
            "requestErrors":"错误请求数",
            "requestsDenied":"拒绝请求数",
            "maxConnections":"最大会话数",
            "maxQueuedRequests":"最大队列请求数",
            
            "responseErrors":"错误回复数",
            "connectionErrors":"错误连接数 ",
            "currentQueuedRequests":"当前队列请求数",
            "cpu_usage_system_percent":"系统",
            "cpu_usage_system_percent_max":"系统",
            "cpu_usage_user_percent":"用户",
            "cpu_usage_user_percent_max":"用户",
            "memory_used_percent":"内存使用率",
            "memory_used_percent_max":"内存使用率",
            "memory_available_percent":"空闲内存",
            "memory_available_percent_max":"空闲内存",
            "memory_buff_or_cache_percent":"缓存（cached）",
            "memory_buff_or_cache_percent_max":"缓存（cached）",

            "net_bits_recv_persecond":"流入速率",
            "net_bits_sent_persecond":"流出速率",

            "net_bits_recv_persecond_peak":"流入速率",
            "net_bits_sent_persecond_peak":"流出速率",

            "net_packets_recv_persecond_peak":"包接收速率",
            "net_packets_sent_persecond_peak":"包发送速率",

            "net_packets_recv_persecond":"包接收速率",
            "net_packets_sent_persecond":"包发送速率",

            "net_packets_recv_persecond_avg":"包接收速率",
            "net_packets_sent_persecond_avg":"包发送速率",
            
            "net_bytes_recv":"累计流入量",
            "net_bytes_sent":"累计流出量",
            "disk_used_percent":"使用率",
            "disk_read_write_bytes_persecond":"读写字节数",
            "disk_read_write_bytes_persecond_peak":"读写字节数",
            "disk_read_write_bytes_persecond_avg":"读写字节数",
            "disk_write_bytes_persecond":"写入字节数",
            "disk_write_bytes_persecond_peak":"写入字节数",
            "disk_write_bytes_persecond_avg":"写入字节数",


            "disk_read_bytes_persecond":"读取字节数",
            "disk_read_bytes_persecond_peak":"读取字节数",
            "disk_read_bytes_persecond_avg":"读取字节数",


            "disk_io_persecond":"读写次数",
            "disk_io_persecond_peak":"读写次数",
            "disk_io_persecond_avg":"读写次数",
            "disk_reads_persecond":"读次数",
            "disk_reads_persecond_peak":"读次数",
            "disk_reads_persecond_avg":"读次数",
            "disk_writes_persecond":"写次数",
            "disk_writes_persecond_peak":"写次数",
            "disk_writes_persecond_avg":"写次数",
            "time":"时间",
            "csvTitle":{
                "cpu":"CPU",
                "mem":"内存",
                "diskPath":"磁盘分区",
                "diskio":"磁盘性能",
                "netcard":"网卡"
            },

            "Context_Switches_Per_Period": "上下文切换",
            "System_Calls_Per_Period": "系统调用",

            "context_switches_persec":"上下文切换次数",
            "system_calls_persec":"系统调用次数",

            "Percent_Disk_Write_Time": "写时间占比",
            "Percent_Disk_Read_Time": "读时间占比",
            "Percent_Disk_Time": "使用率",

            "disk_usage":"使用率",
            "percent_disk_read_time":"读取时间",
            "percent_disk_write_time":"写入时间",
            "percent_disk_time":"读写时间",
            "percent_idle_time":"空闲时间",

            "disk_read_write_percent":"读写时间占比",
            "current_disk_queue_length":"磁盘队列长度",

            "Available_Bytes": "剩余字节数",
            "Pages_persec": "每秒请求页数",

            "mem_used_percent": "内存使用率",
            "mem_total_percent": "内存总量",
            "disk_used_percent": "磁盘空间使用率",
            "mem_free": "空闲内存",
            "mem_cached": "缓存(Cached)",
            "mem_buffered": "缓存(Buffers)",
            "read_bytes_per_second": "磁盘读IOPS",
            "write_bytes_per_time": "磁盘写IOPS",
            "read_time": "磁盘读IOPS",
            "write_time": "磁盘写IOPS",

            "load1": "最近1分钟",
            "load5": "最近5分钟",
            "load15": "最近15分钟",

            "swap_used_percent": "交换空间使用率",
            // "inodes_used":"已使用量",
            // "inodes_total":"总量",
            "gross":"总量",
            "inodes_used_inodes_total": "inodes使用率",
            "read_bytes": "磁盘读流量",
            "write_bytes": "磁盘写流量",
            "bytes_recv": "网络流入带宽",
            "bytes_received": "下载流量",
            "bytes_sent": "网络流出带宽",
            "packets_recv": "网络流入包速率",
            "packets_sent": "网络流出包速率",
            "total_bytes_recv": "网络累计流入流量",
            "total_bytes_sent": "网络累计流出流量",

            "bits_received_persec":"流入速率",
            "bits_sent_persec":"流出速率",
            "packets_received_persec":"包接收速率",
            "packets_sent_persec":"包发送速率",

            "region1": "default",
            "IOPS": "IOPS",
            "read_flow": "读流量",
            "write_flow": "写流量",
            "rabbitmq_connections": "连接数",
            "cluster_available": "Ceph集群剩余空间",
            "cluster_used": "Ceph集群已用空间",
            
            "cluster_IOPS": "IOPS",
            "cluster_IOPS_peak": "IOPS（峰值）",
            "cluster_IOPS_avg": "IOPS（平均）",
            "cluster_ReadOPS": "IOPS（读）",
            "cluster_WriteOPS": "IOPS（写）",

            "max_cluster_IOPS": "IOPS",
            "max_cluster_ReadOPS": "IOPS（读）",
            "max_cluster_WriteOPS": "IOPS（写）",

            "pool_IOPS": "Ceph池-IOPS",

            "cluster_rBandwidth": "BW 读带宽",
            "max_cluster_rBandwidth": "BW 读带宽",

            "cluster_rBandwidth_peak": "BW 读带宽",
            "cluster_rBandwidth_avg": "BW 读带宽",

            "cluster_wBandwidth": "BW 写带宽",
            "max_cluster_wBandwidth": "BW 写带宽",

            "cluster_wBandwidth_peak": "BW 写带宽",
            "cluster_wBandwidth_avg": "BW 写带宽",

            "pool_rBandwidth": "Ceph池-读带宽",
            "pool_wBandwidth": "Ceph池-写带宽",

            //mysql
            "max_connections": "数据库最大连接数",
            "mysql_cachehits_percent": "查询语句-缓存命中率",
            "mysql_cache_ratio": "缓存利用率",
            "open_tables": "当前打开表总数量",
            "qcache_total_blocks": "查询缓存块数",
            "slow_queries": "慢查询速率",
            "queries": "语句执行速率",
            "commands_select": "查询语句执行速率",
            "commands_update": "更新语句执行速率",
            "commands_delete": "删除语句执行速率",
            "commands_insert": "插入语句执行速率",
            "threads_connected": "当前连接数",
            "aborted_connects": "连接中断速率",

            //rabbitmq
            "messages_ready": "准备",
            "messages_unacked": "未确认",
            "rabbitQueueTotal": "总数量",
            "messages_published_rate": "发布",
            "messages_delivered_rate": "交付",
            "messages_acked_rate": "确认",
            //memcached
            "bytes_read": "读流量速率",
            "bytes_written": "写流量速率",
            "curr_connections": "当前连接数",
            "disk_usage_avg":"平均磁盘使用率",
            "index_speed":"写入速度",
            "index_total":"数据当前写入总次数",

            "openstackService": {
                "compute": "计算",
                "serviceName": "服务名称",
                "node": "节点",
                "statusTitle": "状态",
                "cpuUsage": "CPU使用率",
                "memUsage": "内存使用率",
                "storage": "存储",
                "network": "网络",
                "normal": "正常",
                "abnormal": "异常",
                "description":"描述",
                "selectNode":"选择节点",
                "nodeSelect":"节点选择",
                "active":"运行",
                "status":{
                    "passing":'正常',
                    "warning":'警告',
                    "critical":'异常',
                },
                "serverDescription":{
                    "nomad":"Nomad集群调度服务",
                    "nomad-client":"Nomad集群调度服务",
                    "consul":"Consul集群调度服务",
                    "mysql":"Mysql数据库服务",
                    "neutron-vpn-agent":"网络VPN代理服务",
                    "ceph-mon":"ceph监控服务",
                    "ceph-osd":"ceph osd管理服务",
                    "awbob":"平台bob任务服务",
                    "awmonitor":"硬件监控服务",
                    "computeha-monitor":"计算节点高可用监控服务",
                    "computeha-slave":"计算节点高可用从属服务",
                    "influxdb":"Influxdb数据库服务",
                    "docker":"docker容器服务",
                    "libvirt":"虚拟化平台平台管理服务",
                    "ovs":"OpenvSwitch服务",
                    "nova-compute":"nova 计算服务",
                    "neutron-openvswitch-agent":"OpenvSwitch网络代理服务",
                    "agent-proxy":"虚拟化平台代理服务",
                    "rabbitmq-common":"消息队列服务",
                    "rabbitmq-common-post-start":"消息队列服务",
                    "rabbitmq-common-health-check":"消息队列服务",
                    "rabbitmq-cinder":"消息队列服务",
                    "rabbitmq-cinder-post-start":"消息队列服务",
                    "rabbitmq-cinder-health-check":"消息队列服务",
                    "rabbitmq-neutron":"消息队列服务",
                    "rabbitmq-neutron-post-start":"消息队列服务",
                    "rabbitmq-neutron-health-check":"消息队列服务",
                    "rabbitmq-nova":"消息队列服务",
                    "rabbitmq-nova-post-start":"消息队列服务",
                    "rabbitmq-nova-health-check":"消息队列服务",
                    "rabbitmq-management":"消息队列服务",
                    "rabbitmq-managementmanagement-post-start":"消息队列服务",
                    "rabbitmq-management-health-check":"消息队列服务",
                    "ceilometer-notification-common":"监控计费服务",
                    "ceilometer-notification-cinder":"监控计费服务",
                    "ceilometer-notification-neutron":"监控计费服务",
                    "ceilometer-notification-nova":"监控计费服务",
                    "keystone":"认证服务",
                    "keystone-admin":"认证服务",
                    "keystone-public":"认证服务",
                    "neutron-dhcp-agent":"网络 dhcp代理服务",
                    "neutron-server":"网络服务",
                    "nova-api":"计算API接口服务",
                    "nova-consoleauth":"计算控制台认证服务",
                    "nova-novncproxy":"计算novnc代理服务",
                    "nova-scheduler":"计算调度服务",
                    "ceph-api":"ceph API接口服务",
                    "cinder-api":"存储API服务",
                    "cinder-scheduler":"存储调度服务",
                    "cinder-volume":"存储卷管理服务",
                    "glance-api":"镜像API接口服务",
                    "glance-registry":"镜像注册服务",
                    "haproxy":"负载均衡服务",
                    "haproxy-galera":"负载均衡服务",
                    "memcached":"Memcached内存数据库",
                    "telegraf":"集群状态收集服务",
                    "tunnel-connector":"通道连接器服务",
                    "clustercheck":"集群检测服务",
                    "kapacitor":"告警容器服务",
                    "saas":"容器",
                    "saas-container":"容器",
                    "cinder-backup":"备份",
                    "nova-quota-sync":"计算配额同步服务",
                    "nova-metadata":"计算元数据服务",
                    "topo":"网络拓扑和大屏展示",
                    "influxdb-relay":"高可用服务",
                    "nova-conductor":"nova数据库服务",
                }
            },
            "mysql": {
                "phyHost": "物理主机",
                "chosePhyHost": "选择物理主机",
                "port": "端口",
                "version": "版本",
                "mysqlConnection": "数据库连接",
                "mysqlTableSpaceInfo": "数据库表空间信息",
                "mysqlVisitInfo": "数据库访问信息"
            },
            "rabbitmq": {
                "connectionNumber": "连接数",
                "channel": "通道",
                "switchNumber": "交换机数量",
                "queue": "队列",
                "consumerNumber": "消费者数量",
                "msgQueue": "消息队列",
                "msgRate": "消息速率",
                "nodeName": "节点名称",
                "fileDescriptor": "文件描述符",
                "socketDescriptor": "Socket描述符",
                "process": "进程",
                "mem": "内存(MB)",
                "diskSpace": "磁盘空间(GB)",
                "durationTime": "持续时间",
                "type": "类型",
                "nodeDetail": "节点详情"
            },
            "cache": {
                "nodeName": "节点名称",
                "keyTotal": "所有key总数"
            }
        },
        "siteTitle": {
            "enhanceconfig":"增强型负载均衡",
            "enhance":"负载均衡-增强型",
            "fileStorage":"文件存储",
            "label":"标签管理",
            "loadbalancingcertificate":"证书管理",
            "rbackups":"备份",
            "backupStrategy":"备份策略",
            "recovery":"恢复",
            "relationalTdsqls":"关系型数据库",
            "alRegister":"部署",
            "wellCome":"大数据登录",
            "multiplenet":"云平台物理网络互通",
            "deploy":"部署",
            "daemon":"守护进程集",
            "stateful":"有状态副本集",
            "replica":"副本集",
            "pod":"容器组",
            "configmap":"配置字典",
            "secret":"保密字典",
            "service":"服务",
            "ingress":"访问权",
            "bigmem": "大页内存管理",
            "sriov": "SR-IOV网络管理",
            "dpdk": "DPDK管理",
            "nettype": "租户网络类型管理",
            "networkpolicy":"网络策略",
            "persistentvolumeclaim":"持久卷声明",
            "persistentvolume":"持久卷",
            "storageclass":"存储类",
            "nodes":"节点",
            "namespace":"命名空间",
            "othersspaced":"命名空间相关资源",
            "clustered":"集群相关资源",
            "roles":"角色",
            "bindings":"角色绑定",
            "sqlDatabase":"时序数据库",

            "tbClusterManage":"集群管理",
            "tbNodeManage":"节点列表",
            "tbNodeGroup":"节点组列表",
            "tbConfigManagement":"配置管理",
            "tbBackupSetting":"备份设置",
            "tbBackupCheck":"备份检查",
            "tbClusterMonitor":"集群监控",
            "tbNodeMonitor":"节点监控",
            "tbVolumeMonitor":"容量监控",
            "tbAlarmLists":"告警列表",
            "tbAlarmRecords":"告警记录",
            "tbAlarmUsers":"告警对象",
            "tbAlarmMethods":"告警渠道",
            "tbSystemInfo":"系统信息",
            "tbLicenseInfo":"证书信息",

            "ctInstancesList":"实例列表",
            "ctOperationInsManage":"实例管理",
            "ctProcessManage":"流程管理",
            "ctEquipmentManage":"设备管理",
            "ctInstancesMonitor":"实例监控",
            "entrance":"启动入口",

            "single":"单数据中心",
            "first":"欢迎页面",
            "createins":"新建云主机",
            "regularSnap": "计划任务",
            "extensionins": "弹性扩展云主机详情",
            "extension": "集群管理",
            "scalpolicy": "扩展策略",
            "plugin":"功能管理",
            "pendingTickets":"待处理工单",
            "ticketApply":"工单申请",
            //"projectname": window.SETTING.SITETITLE,
            "home": "登录",
            "demotpl": "模板示例",
            "view": "概况",
            "depview": "部门概况",
            "cvmview": "资源概况",
            "instances": "云主机",
            "elasticexpansion":"弹性伸缩",
            "createCoustomflow":"自定义流程",
            "volumesQoS":"云硬盘QoS",
            "images": "镜像",
            "makeimages": "制作镜像",
            "volumes": "云硬盘",
            "snapshots": "快照",
            "networks": "私有网络",
            "physicalnetworks": "外部网络",
            "vpn":"IPSec VPN",
            "routers": "路由器",
            "floating_ips": "公网IP",
            "firewall": "防火墙",
            "security_groups": "安全组",
            "security_template": "安全组模板",
            "keypairs": "密钥对",
            "monitorview": "监控概况",
            "physical": "物理主机",
            "vmhost": "云主机",
            "clusters": "容器集群",
            "services": "容器服务",
            "projects": "镜像服务",
            "netTopo": "网络拓扑",
            "loadbalancing": "负载均衡",
            "mailserver": "邮件服务器设置",
            "wechatserver": "微信设置",
            "networksmanage":"多外网管理",
            "cephview": "Ceph存储",
            "VGPU":"vGPU",
            "GPU":"物理GPU",
            "vgpu":"vGPU",
            "gpu":"物理GPU",
            "service": "服务监控",
            "strategy": "监控策略",
            "alarmevent": "告警事件",
            "alarmsetting": "告警设置",
            "alarmtemplate": "告警模板",
            "contractgroup": "联系人组",
            "topology": "网络拓扑",
            "flowtask": "流程列表",
            "createflow": "自定义流程",
            "flowapply": "工单类型",
            "lists": "我的工单",
            "create": "提交工单",
            "operatelogs": "操作日志",
            "loginlogs": "登录日志",
            "auditLogs":"审计日志",
            "audit":"审计日志",
            "user": "用户管理",
            "role": "角色管理",
            "department": "部门管理",
            "org": "组织结构",
            "flavors": "规格",
            "aggregates": "主机集合",
            "storageManagement": "存储管理",
            "imageManagement": "镜像库迁移",
            "hypervisors": "虚拟机管理器",
            "quotas": "默认配额管理",
            "node": "节点管理",
            "cluster": "集群配置",
            "cloud": "混合云设置",
            "general": "常规设置",
            "apply": "已申请的工单",
            "about": "系统版本",
            "logoSetting":"logo设置",
            "customRole":"新建角色",
            "secrecyRole":"概况",
            "auditRole":"概况",
            "qcloud": "腾讯云",
            "sysbackup": "系统备份",
            "accesspolicy": "安全设置",
            "license": "软件激活",
            "recycle": "云主机回收站",
            "recyclecs": "数据盘回收站",
            "loadbalancers": "负载均衡",
            "loadbalancing": "负载均衡-基础型",
            "openstackService": "云系统服务",
            "mysql": "数据库",
            "grade": "云平台升级",
            "rabbitmq": "消息队列",
            "memcached": "缓存",
            "ports": "虚拟网卡",
            "routermonitor": "路由器",
            "project": "项目管理",
            "storagedevice": "存储设备",
            "storagefeatures": "存储特性",
            "volumetype": "卷类型",
            "datacluster": "数据中心管理",
            "monceph": "存储监控",
            "consumeview": "消费统计",
            "resourceprice": "价格管理",
            "storagemanage": "存储管理",
            "bandlimit": "带宽限速",
            "switch": "网络配置",
            "physicalConductor": "裸金属资源管理",
            "overview": "概况",
            "affinity":"云主机组",
            "transmissionMag":"透传设备管理",
            "pvmview":"概况",
            "pvminstances":"裸金属",
            "pvmimages":"镜像",
            "pvmnetworks":"网络",
            "backups":"备份",
            "resview":"总览",
            "resMetering":"资源计量",
            "volumesQoS": "存储池QoS",
            "billingManagement":"计费管理",
            "flowManage":"流程管理",
            "cephView":"Ceph管理",
            "cephViewInfo":"Ceph管理",
            "systeminspection":"系统巡检",
            "transmissionMag":"透传设备管理",
            "myApply":"我的申请",
            "allTickets":"我的审批",
            "ticketReports":"工单报表",
            "netTopo":"网络拓扑",
            "K8sclusters":"容器集群",
            "K8sServices":"容器服务",
            "imgServer":"镜像服务",
            "maxpcshow":"大屏管理",
            "menu":"菜单",
            //腾讯云安全
            "Tsafe":"腾讯云安全",
            "yujie":"高级威胁检测系统",
            "tianmu":"网络入侵防护系统",
            "waf":"Web应用防护系统",
            "yujian":"安全运营中心",
            "sec":"云安全",
            "fm":"堡垒机",
            "ngfw":"下一代防火墙NGFW",
            //tdsql
            "relationalTdsql":"关系型数据库 TDSQL",
            "tdHome":"集群总览",
            "tdInstanceManage":"实例管理",
            "tdResourceDevice":"设备资源",
            "tdResourceSpec":"机型规格",
            "tdResourceIdc":"IDC信息",
            "tdMonitorMysql":"DB汇总监控",
            "tdProxyBase":"网关汇总监控",
            "tdVipLvsgroup":"VIP管理",
            "tdVipLvsmachine":"LVS资源监控",
            "tdMonitorZookeeper":"Zookeeper监控",
            "tdMonitorScheduler":"Scheduler监控",
            "tdMonitorManager":"Manager监控",
            "tdMonitorOssserver":"OSS-服务监控",
            "tdMonitorOssjob":"OSS-任务流程",
            "tdMonitorOsslog":"OSS-操作审计",
            "tdMonitorHdfs":"HDFS监控",
            "tdMonitorSyncjob":"多源同步监控",
            "tdMonitorKafka":"Kafka监控",
            "tdOsswf":"运维操作日志",
            "tdAlarmSearch":"告警查询",
            "tdAlarmConfig":"告警策略",
            "tdAlarmExcept":"异常查询",
            "tdAlarmGlobal":"告警管理-跨集群",
            "tdClusterManage":"集群管理",
            "tdBusinessUserDatabaseApply":"数据库权限申请",
            "tdBusinessUserDbclusterGrant":"实例负责人管理",
            "tdSysUser":"用户管理",
            "tdSysRole":"角色权限",
            "tdSysLog":"系统操作日志",
            "tdSysVersion":"系统版本",

            "tdCapacity":"容量统计",
            "tdVipgroup":"VIP分组管理",
            "tdVipdns":"DNS查询",
            "tdMonitordns":"DNS监控",
            "tdDcnjob":"DCN同步",
            "tdSyncjob":"多源同步",
            "tdClusteradmin":"集群负责人管理",
            "tdUpgrade":"系统升级",
            "tdAutotest":"自动化测试",

            //tsf 微服务框架
            "Overview":"概况",
            "Cluster":"集群",
            "Namespace":"命名空间",
            "Deploy":"部署组",
            "AppManagement":"应用管理",
            "Service":"服务治理",
            "AppConfig":"应用配置",
            "GlobalConfig":"全局配置",
            "Template":"配置模板",
            "ConfigFile":"配置文件",
            "LogConfig":"日志配置",
            "GatewayList":"网关配置",
            "GatewayGroup":"网关分组",
            "LogSearch":"日志检索",
            "Transaction":"事务管理",
            "Topology":"服务依赖拓扑",
            "Trace":"调用链查询",
            "Alarm":"监控告警",
            "Scalable":"弹性伸缩",
            "Image":"镜像仓库",
            "Package":"软件仓库",
            "ServiceTemplate":"服务编排",
            "ManagerBase":"基础模块管理",
            "ManagerZone":"可用域管理",
            "OpsServer":"应用服务管理",
            "ManagerParam":"参数配置",
            "ManagerGuide":"部署使用指南",
            "ManagerLicense":"授权管理",

            //CMQ消息队列中间件
            "Server":"队列服务",
            "Topic":"主题订阅",
            "Zone":"仓库管理",
            "CMQCluster":"集群管理",
            "Signaling":"信令服务管理",
            "Access":"访问管理",
            "Publisher":"PushServer管理",
            "Queue": "队列管理",
            "ManagerTopic":"主题管理",

            /*织云*/
            "cocCmdbServer":"服务器",
            "cocCmdbNetDevice":"网络设备",
            "cocCmdbIdc":"IDC",
            "cocCmdbIdcExport":"IDC出口",
            "cocCmdbIdcLine":"IDC专线",
            "cocCmdbCustomCategory":"自定义CI",
            "cocModuleManage":"业务",
            "cocMonitorServer":"服务器性能监控",
            "cocMonitorNetdevice":"网络设备",
            "cocMonitorNetoutpoint":"IDC出口",
            "cocMonitorNetspecialLine":"IDC专线",
            "cocMonitorMiddleware":"中间件监控",
            "cocMonitorSelfdefineMonitor":"自定义监控",
            "cocMonitorCustomBoard":"自定义看板",
            "cocAlarmOverview":"告警总览",
            "cocAlarmSearch":"告警查询",
            "cocAlarmStrategy":"告警策略",
            "cocAlarmShield":"告警屏蔽",
            "cocToolmarketToolist":"我的工具",
            "cocToolmarketPublicAll":"所有工具",
            "cocToolmarketPublicInit":"初始化工具",
            "cocToolmarketPublicDiagnose":"诊断工具",
            "cocToolmarketPublicRepaire":"维护工具",
            "cocToolmarketPublicDown":"下架工具",
            "cocToolmarketFlowlist":"编排管理",
            "cocToolmarketSchedulelist":"定时任务",
            "cocToolmarketLoglist":"执行记录",
            "cocPackageList":"包列表",
            "cocCloudServer":"主机资源池",
            "cocCloudUsers":"云账户管理",
            "cocTenantuserUserlist":"用户管理",
            "cocTenantuserGrouplist":"用户组管理",
            "cocTenantuserAuthlist":"权限管理",
            "cocPasswordlib":"密码库",
            "cocOperationlog":"操作日志",
            /*tbds*/
            "tbMyApply":"数据连入",
            "tbHippoAccess":"hippo管理",
            "tbTdMertrics":"进度监控",
            "tbWherehows":"血缘分析",
            "tbEasycountTaskmanage":"任务管理",
            "tbEasycountDbmanage":"库表模板管理",
            "tbWorkflowCanvas":"工作流",
            "tbWorkflowList":"任务管理",
            "tbWorkflowServer":"服务器配置",
            "tbReportList":"自助报表",
            "tbSupersqlHive":"数据交互",
            "tbDataDbmanage":"库表管理",
            "tbDataMeta":"数据血缘",
            "tbDataMap":"数据提取",
            "tbOperationOverview":"全局概览",
            "tbOperationComponents":"系统运维",
            "tbOperationAccesscontrol":"访问管理",
            "tbOperationBasefs":"文件管理",
            "tbOperationMonitor":"监控告警",
            "tbPlatformProject":"项目管理",
            "tbPlatformResource":"资源管理",
            "tbPlatformUsermanage":"用户管理",
            "tbPlatformSystem":"系统设置",
            "tbMlEngineer":"我的工程",
            "tbMlOthers":"协作工程",
            "tbMlModelService":"模型服务",
            "tbMlModelManage":"模型管理",
            "tbMlResourceManage":"资源管理",
            "tbMlModuleManage":"模块管理",
            "tbMlAdminControl":"控制台",
            "paas":"Paass授权",
            "objectStorage":"对象存储",
            "objectStorageSetting":"对象存储设置",
            "objectStorageUser":"对象存储用户",
            "shares":"文件存储",
            "cephpg":"Ceph存储池PG比例管理",
            "adserver":"AD域服务设置",
            
            /*TCS*/
            "TCS":"TCS",
            "TCS_overview_v":"总览",
            "TCS_overview":"总览",
            "TCS_platform_management":"平台服务",
            "TCS_platform_cluster_overview":"集群概览",
            "TCS_platform_cluster_management":"集群管理",
            "TCS_platform_node_management":"节点管理",
            "TCS_platform_pod_management":"Pod管理",
            "TCS_cbs":"云硬盘(CBS)",
            "TCS_cbs_operation":"运营数据",
            "TCS_cbs_operation_overview":"概览",
            "TCS_cbs_operation_trend":"运营趋势",
            "TCS_cbs_operation_user_resource":"用户资源统计",
            "TCS_cbs_management":"云硬盘管理",
            "TCS_cbs_snapshot_manage":"快照管理",
            "TCS_cbs_container_management":"容器管理",
            "TCS_cbs_management_format":"规格定义",
            "TCS_cbs_management_sub":"云盘管理",
            "TCS_cbs_snapshot_manage_list":"快照列表",
            "TCS_cbs_snapshot_manage_policy":"定期快照策略",
            "TCS_cbs_cluster_management":"集群管理",//-
            "TCS_cbs_pool_monitor":"性能监控",//-
            "TCS_cbs_storage_model_management":"存储机型管理",
            "TCS_cbs_storage_pool_management":"存储池集群",
            "TCS_cbs_snapshot_cluster":"快照集群",
            "TCS_cbs_zookeeper_cluster":"Zookeeper集群",
            "TCS_cbs_user_resource_pool":"用户资源池",
            "TCS_cbs_performance_monitor":"存储池性能监控",
            "TCS_cbs_node_xm_monitor":"存储节点性能监控",
            "TCS_cbs_cloud_disk_monitor":"云硬盘性能监控",
            "TCS_cbs_wlyp_monitor":"物理硬盘性能监控",
            "TCS_cbs_task_management":"任务管理",//-
            "TCS_cbs_disk_task":"云硬盘任务",
            "TCS_cbs_snapshot_task":"快照任务",
            "TCS_cbs_cloud_disk_qy_disk":"云硬盘迁移任务",
            "TCS_cbs_cccb_task":"存储池小表任务",
            "TCS_cbs_wldisk_edit_task":"物理硬盘更换任务",
            "TCS_cbs_cluster_yw_task":"集群运维任务",
            "TCS_cbs_event_alert":"事件查询",//-
            "TCS_cbs_sj_management":"升级管理",//-
            "TCS_cbs_event_alert_sub":"告警事件",
            "TCS_cbs_event_cluster":"集群事件",
            "TCS_cbs_pool_cluster_sj":"存储池集群升级",
            "TCS_cbs_snapshot_cluster_sj":"快照集群升级",
            "TCS_cbs_task_cluster_sj":"升级任务",
            "TCS_monitor_center":"监控中心",//-
            "TCS_monitor_overview":"监控概览",//-
            "TCS_monitor_selete":"告警查询",//-
            "TCS_monitor_srategy":"告警策略",//-
            "TCS_monitor_even_selete":"事件查询",//-
            "TCS_monitor_shield":"告警屏蔽",//-
            "TCS_monitor_srategy_model":"策略模板",//-
            "TCS_monitor_message_rule":"通知规则",//-
            "TCS_monitor_cloud_index":"云产品指标",//-
            "TCS_monitor_tcns_index":"TCNS监控面板",//-
            "TCS_monitor_set":"监控设置",//-
            "TCS_image_house":"镜像仓库",
            "TCS_image_public":"公共仓库",//-
            "TCS_image_private":"私有仓库",//-
            "TCS_cmdb":"业务CMDB",//-
            "TCS_cmdb_overview":"概览",//-
            "TCS_cmdb_select":"全局搜索",//-
            "TCS_cmdb_host_resource":"主机资源",//-
            "TCS_cmdb_business_resource":"业务资源",//-
            "TCS_cmdb_business_management":"业务管理",//-
            "TCS_cmdb_model_management":"模型管理",//-

            "TCS_message_management":"消息查询",//*
            "TCS_message_query":"邮件查询",//*
            "TCS_message_short_query":"短信查询",//*

            "TCS_log_center":"日志中心",//*
            "TCS_log_query":"日志搜索",//*
            "TCS_log_access":"日志接入",//*
            "TCS_log_check":"日志事件",//*
            "TCS_log_policy":"事件策略",//*

            "TCS_user":"用户中心",//*
            "TCS_user_management":"用户",//*
            "TCS_user_group":"用户组",//*
            "TCS_user_role":"角色权限",//*
            "TCS_user_role_policy":"权限策略",//*
            "TCS_user_check":"用户审核",//*
            "TCS_user_log":"操作日志",//*

            "TCS_codingDevOps_managemnet":"开发者工具",//*
            "TCS_codingDevOps":"CodingDevOps",//*

            "TCS_component_management":"组件管理",//*
            "TCS_component_list":"组件列表",//*
            "TCS_component_from":"组件规格",//*
            "TCS_cos":"对象存储(COS)",//-
            "TCS_cos_overview":"概览",//-
            "TCS_cos_bucket":"存储桶列表",//-
            "TCS_cos_monitor":"监控报表",//-
            "TCS_cos_monitor_report_basic":"基础数据统计",
            "TCS_cos_monitor_report_code":"返回码统计",
            "TCS_cos_access":"接入层运维",//-
            "TCS_cos_access_cluster":"集群管理",
            "TCS_cos_access_machine":"机器管理",
            "TCS_cos_access_service":"服务管理",
            "TCS_cos_access_task":"任务管理",
            'TCS_cos_index':'索引运维',//-
            'TCS_cos_index_cluster_info': '集群详情',
            'TCS_cos_index_diagnose': '集群诊断', 
            'TCS_cos_index_log':'日志搜索',
            'TCS_cos_index_instance_profilling':'实例性能分析',
            "TCS_cos_store":"存储运维",//-
            'TCS_cos_store_cluster_overview': '集群概览',
            'TCS_cos_store_cluster_search':'集群管理',
            'TCS_cos_store_machine_search':'机器查询',
            'TCS_cos_store_service_search':'服务查询',
            'TCS_cos_store_task_manage':'任务管理',
            'TCS_cos_store_libra_task':'Libra任务',
            'TCS_cos_store_version_manage':'版本管理',
            'TCS_cos_store_disk_replace':'硬盘更换任务',
            "TCS_cos_monitor_alarm":"监控告警",//*
            'TCS_cos_monitor_graph_main':'总体视图', 
            'TCS_cos_monitor_graph_detail':'详细视图',
            'TCS_cos_monitor_alert_config':'告警配置',
            'TCS_cos_monitor_alarm_sj':'运营数据',//-
            'TCS_cos_operate_overview':'运营总览',
            'TCS_cos_operate_user':'用户数据',
            'TCS_cos_user_manage':'用户管理',//-
            'TCS_cos_user_bucket_manage':'Buket管理',
            'TCS_cos_user_frequenc_manage':'频控管理',
            'TCS_cos_user_flow_manage':'流控管理',
            'TCS_cos_user_quota_manage':'配额管理',
            "TCS_db_redis":"数据库Redis",//-
            "TCS_db_redis_equipment":"设备管理",//-
            "TCS_db_redis_instacne":"实例列表",//-
            "TCS_db_redis_process":"流程列表",//-
            "TCS_db_redis_alarm":"告警列表",//-
            "TCS_db_sql":"TBSQL(赤兔)",//-
            "TCS_db_sql_chitu":"TBSQL",//--
            "TCS_tsf":"微服务平台(TSF)",
            "TCS_tsf_resource":"资源运营",//--
            "TCS_tsf_resource_basic":"基础模块管理",
            "TCS_tsf_resource_zone":"可用区管理",
            "TCS_tsf_resource_config":"参数配置",
            "TCS_tsf_resource_guide":"部署使用指引",
            "TCS_tsf_tool_log_search":"日志检索",
            "TCS_tsf_tool_topology":"服务依赖拓扑",
            "TCS_tsf_tool_trace":"调用链查询",
            "TCS_tsf_tool":"运营工具",
            "TCS_tsf_monitor":"监控",
            "TCS_tsf_monitor_module":"模块监控",
            "TCS_tsf_monitor_resource":"资源监控",
            "TCS_tsf_manage_liccense":"许可管理",
            "TCS_tsf_version_manage":"版本信息",
            "TCS_tenant":"微服务平台(租户)",
            "TCS_tenant_overview":"概览",
            "TCS_tenant_cluster":"集群",
            "TCS_tenant_namespace":"命名空间",
            "TCS_tenant_group":"部署组",
            "TCS_tenant_apply_manage":"应用管理",
            "TCS_tenant_service_manage":"服务治理",
            "TCS_tenant_config":"配置管理",
            "TCS_tenant_config_app":"应用配置",
            "TCS_tenant_config_global":"全局配置",
            "TCS_tenant_config_template":"配置模板",
            "TCS_tenant_config_file":"文件配置",
            "TCS_tenant_log":"日志服务",
            "TCS_tenant_log_config":"日志配置",
            "TCS_tenant_log_retrieve":"日志检索", 
            "TCS_tenant_log_alert":"日志告警", 
            "TCS_tenant_depend_on":"依赖分析", 
            "TCS_tenant_depend_on_service":"服务依赖拓展", 
            "TCS_tenant_depend_on_trace":"调用链查询", 
            "TCS_tenant_monitor":"监控", 
            "TCS_tenant_monitor_service":"服务监控", 
            "TCS_tenant_monitor_jvm":"JVM监控", 
            "TCS_tenant_gray_scale":"全链路灰度发布", 
            "TCS_tenant_scalable":"弹性伸缩", 
            "TCS_tenant_micro_service":"微服务网关", 
            "TCS_tenant_micro_service_gateway":"网关监控", 
            "TCS_tenant_micro_service_group":"分组管理", 
            "TCS_tenant_micro_service_plugin":"插件管理", 
            "TCS_tenant_micro_service_redirect":"重定向配置", 
            "TCS_tenant_distributed":"分布式任务调度", 
            "TCS_tenant_distributed_task":"任务管理", 
            "TCS_tenant_distributed_execute_records":"执行记录", 
            "TCS_tenant_distributed_workflow_manage":"工作流管理", 
            "TCS_tenant_image_manage":"镜像仓库", 
            "TCS_tenant_template_manage":"服务编排", 
            "TCS_tenant_role_manage":"角色管理", 
            "TCS_tenant_data_manage":"数据集管理", 
            "TCS_tenant_operate_log":"操作记录", 
            "TCS_tdmq":"消息队列(TDMQ)", 
            "TCS_tdmq_cluster_manage":"集群管理", 
            "TCS_tdmq_namespace":"命名空间", 
            "TCS_tdmq_topic_manage":"Topic管理", 
            "TCS_tdmq_message_search":"消息查询", 
            "TCS_tdmq_role_manage":"角色管理", 
            "TCS_tdmq_cmq":"CMQ", 
            "TCS_tdmq_cmq_queue":"队列服务", 
            "TCS_tdmq_cmq_topic":"主体订阅", 
            "TCS_tdmq_operate":"运维管理", 
            "TCS_tdmq_operate_cluster":"集群管理", 
            "TCS_tdmq_operate_cluster_broker":"Broker", 
            "TCS_tdmq_operate_cluster_bookie":"Bookie", 
            "TCS_tdmq_operate_enviroment":"命名空间", 
            "TCS_tdmq_operate_topic":"Topic管理", 
            "TCS_tdmq_operate_subsciption":"订阅管理", 
            "TCS_tdmq_operate_producer":"生产关系", 
            "TCS_tdmq_operate_consumer":"消费管理", 
            "TCS_km8":"秘钥管理服务(KM8)", 
            "TCS_km8_kms_key":"用户密匙", 
            "TCS_api":"API官网", 
            "TCS_api_gateway_overview":"概览", 
            "TCS_api_gateway_service":"服务", 
            "TCS_api_gateway_plugin":"插件", 
            "TCS_api_gateway_use_plan":"使用计划", 
            "TCS_api_gateway_app":"应用", 
            "TCS_api_gateway_key":"密匙", 
            "TCS_api_resource":"资源管理", 
            "TCS_api_resource_index":"Master管理", 
            "TCS_api_resource_cluster":"集群管理", 
            "TCS_api_resource_ld":"LD管理", 
            "TCS_api_resource_vip":"VIP管理", 
            "TCS_api_resource_vip_group":"VIP组管理", 
            "TCS_api_service":"服务管理", 
            "TCS_api_service_statistics":"服务统计", 
            "TCS_api_resource_service_statistics":"证书管理", 
            "TCS_ckafka":"消息队列(CKafka)", 
            "TCS_ckafka_instance_list":"实例列表", 
            "TCS_ckafka_operate":"运维管理", 
            "TCS_ckafka_operate_instance":"实例管理", 
            "TCS_ckafka_operate_cluster":"集群管理", 
            "TCS_ckafka_operate_broker":"Broker管理", 
            "TCS_ckafka_operate_master":"Master管理", 
            "TCS_apply_center":"应用中心", 
            "TCS_apply_center_application":"应用概览", 
            "TCS_apply_center_app_list":"应用列表", 
            "TCS_apply_center_app_market":"应用市场", 
            "TCS_apply_center_app_template":"模板发布", 
            "TCS_apply_center_project_manage":"项目管理", 
            "TCS_apply_center_config_manage":"配置管理", 
            "overview-tcs":"总览",
            "overviewcluster-tcs":"集群概览",
            "cluster-tcs":"集群管理",
            "cluster-node-tcs":"节点管理",
            "cluster-pod-tcs":"Pod管理",
            "cwhistle-overview-tcs":"监控概览",
            "warning-search-tcs":"告警查询",
            "tactics-management-tcs":"告警策略",
            "cwhistle-event-tcs":"事件查询",
            "warning-block-tcs":"告警屏蔽",
            "tactics-template-tcs":"策略模板",
            "notice-rules-tcs":"通知规则",
            "setting-tcs":"监控设置",
            "tcs-monitor-tcs":"TCNS监控面板",
            "product-index-tcs":"云产品指标",
            "over_view-tcs":"概览",
            "selling-trend-tcs":"运营趋势",
            "cbsuser-message-tcs":"用户资源统计",
            "cbs-spec-tcs":"规格定义",
            "cbs-cdisk-tcs":"云盘管理",
            "snapshot-list-tcs":"快照列表",
            "snapshot-policy-tcs":"定期快照策略",
            "container-management-tcs":"容器管理",
            "storage-model-tcs":"存储机型管理",
            "storage-cluster-tcs":"存储机型管理",
            "snapshot-cluster-tcs":"快照集群",
            "zk-cluster-tcs":"Zookeeper集群",
            "resource-cluster-tcs":"用户资源池",
            "cdepot-monitor-overview-tcs":"存储池性能监控",
            "cdepot-cell-monitor-overview-tcs":"存储节点性能监控",
            "cdisk-moniter-overview-tcs":"云硬盘性能监控",
            "cphy-disk-monitor-overview-tcs":"物理硬盘性能监控",
            "op-task-list-tcs":"集群运维任务",
            "replace-disk-task-list-tcs":"物理硬盘更换任务",
            "storage-pool-task-list-tcs":"存储池小表任务",
            "cdisk-migrate-task-list-tcs":"云硬盘迁移任务",
            "snapshot-task-list-tcs":"快照任务",
            "task-list-tcs":"云硬盘任务",
            "event-query-tcs":"告警事件",
            "cluster-query-tcs":"集群事件",
            "storages-cluster-upgrade-tcs":"存储池集群升级",
            "snapshots-cluster-upgrade-tcs":"快照集群升级",
            "upgrade-task-tcs":"升级任务",
            "image-image-tcs":"公共仓库",
            "image-private-tcs":"私有仓库",
            "cmdb-cloud-tcs":"概览",
            "cmdb-cloud-search-tcs":"全局搜索",
            "cmdb-cloud-host-resource-tcs":"主机资源",
            "cmdb-cloud-firm-tcs":"业务资源",
            "cmdb-cloud-biz-tcs":"业务管理",
            "cmdb-cloud-model-tcs":"模型管理",
            "info-email-query-tcs":"邮件查询",
            "info-sms-query-tcs":"短信查询",
            "logs-overview-searchy-tcs":"日志搜索",
            "logs-integration-tcs":"日志接入",
            "logs-incident-record-tcs":"日志事件",
            "logs-incident-tcs":"事件策略",
            "usercenter-admin-tcs":"用户",
            "usercenter-admin-group-tcs":"用户组",
            "usercenter-role-tcs":"角色权限",
            "usercenter-policy-tcs":"权限策略",
            "usercenter-qualification-manager-tcs":"用户审核",
            "usercenter-operation-log-tcs":"操作日志",
            "coding-tcs":"CodingDevOps",
            "components-list-tcs":"组件列表",
            "components-specifications-tcs":"组件规格",
            "cos5-tcs":"概览",
            "cos5-bucket-tcs":"存储桶列表",
            "access-cluster-management-tcs":"集群管理",
            "access-machine-management-tcs":"机器管理",
            "access-service-management-tcs":"服务管理",
            "access-task-management-tcs":"任务管理",
            "cos5-report-basic-tcs":"基础数据统计",
            "cos5-report-httpcode-tcs":"返回码统计",
            "index-cluster-info-tcs":"集群详情",
            "index-cluster-diagnose-tcs":"集群诊断",
            "index-log-search-tcs":"日志搜索",
            "index-instance-profilling-tcs":"实例性能分析",
            "cluster-overview-tcs":"集群概览",
            "cluster-management-tcs":"集群管理",
            "machine-management-tcs":"机器查询",
            "service-management-tcs":"服务查询",
            "task-management-tcs":"任务管理",
            "libra-task-tcs":"Libra任务",
            "disk-replace-tcs":"硬盘更换任务",
            "version-management-tcs":"版本管理",
            "monitor-alarm-tcs":"总体视图",
            "graph-details-tcs":"详细视图",
            "operation_overview-tcs":"运营总览",
            "user_data-tcs":"用户数据",
            "operation_overview-tcs":"Buket管理",
            "frequenc_management-tcs":"频控管理",
            "flow_management-tcs":"流控管理",
            "quota_management-tcs":"配额管理",
            "equipment-tcs":"设备管理",
            "instance-tcs":"实例列表",
            "process-tcs":"流程列表",
            "alarm-tcs":"告警列表",
            "chitu-tcs":"TBSQL",
            "tsf-manager-basic-tcs":"基础模块管理",
            "zone-manager-tcs":"可用区管理",
            "config-params-tcs":"参数配置",
            "guide-tcs":"部署使用指引",
            "logsearch-tcs":"日志检索",
            "topology-tcs":"服务依赖拓扑",
            "trace-tcs":"调用链查询",
            "monitor-module-tcs":"模块监控",
            "monitor-resource-tcs":"资源监控",
            "license-tcs":"许可管理",
            "version-tcs":"版本信息",
            "tfs-tenant-index-tcs":"概览",
            "tfs-tenant-cluster-tcs":"集群",
            "tfs-tenant-namespace-tcs":"命名空间",
            "tfs-tenant-group-tcs":"部署组",
            "tfs-tenant-app-tcs":"应用管理",
            "tfs-tenant-service-tcs":"服务治理",
            "config-app-tcs":"应用配置",
            "config-tcs":"全局配置",
            "config-template-tcs":"配置模板",
            "config-file-tcs":"文件配置",
            "log-config-tcs":"日志配置",
            "log-search-tcs":"日志检索",
            "log-alarm-tcs":"日志告警",
            "tsf-topology-tcs":"服务依赖拓展",
            "topology-trace-tcs":"调用链查询",
            "monitor-service-tcs":"服务监控",
            "monitor-jvm-tcs":"JVM监控",
            "grayscale-tcs":"全链路灰度发布",
            "scalable-tcs":"弹性伸缩",
            "gateway-overview-tcs":"网关监控",
            "api-group-tcs":"分组管理",
            "plugin-tcs":"插件管理",
            "gateway-rewrite-tcs":"重定向配置",
            "tct-tas-tcs":"任务管理",
            "tct-exec-tcs":"执行记录",
            "tct-workflow-tcs":"工作流管理",
            "image-tcs":"镜像仓库",
            "template-tcs":"服务编排",
            "role-tcs":"角色管理",
            "program-tcs":"数据集管理",
            "operation-log-tcs":"操作记录",
            "env-tcs":"命名空间",
            "topic-tcs":"Topic管理",
            "message-tcs":"消息查询",
            "cmq-queue-tcs":"队列服务",
            "cmq-topic-tcs":"主体订阅",
            "cluster-tcs-operate":"集群管理",
            "cluster-broker-tcs":"Broker",
            "cluster-bookie-tcs":"Bookie",
            "environment-tcs":"命名空间",
            "subscription-tcs":"订阅管理",
            "producer-tcs":"生产关系",
            "consumer-tcs":"消费管理",
            "kms-tcs":"用户密匙",
            "index-tcs":"概览",
            "service-tcs":"服务",
            "api-plugin-tcs":"插件",
            "plan-tcs":"使用计划",
            "app-tcs":"应用",
            "key-tcs":"密匙",
            "apigateway-manager-index-tcs":"Master管理",
            "ld-tcs":"LD管理",
            "vip-tcs":"VIP管理",
            "vip-group-tcs":"VIP组管理",
            "certificate-tcs":"证书管理",
            "service-statistics-tcs":"服务统计",
            "ckafka-index-tcs":"实例列表",
            "ckafka-manager-index-tcs":"实例管理",
            "broker-tcs":"Broker管理",
            "master-tcs":"Master管理",
            "application-tcs":"应用概览",
            "app-deploy-tcs":"应用列表",
            "app-market-tcs":"应用市场",
            "app-template-tcs":"模板发布",
            "project-tcs":"项目管理",
            "alert-configure-tcs":"告警配置",
            "bucket_management-tcs":"Buket管理",
            
            "localDisk":"本地磁盘",
            "cloudDatabase":"云数据库",
            "recycleDb":"云数据库回收站",
            // backupService
            "backupServiceConfig":"备份配置",
            "backup-service-config":"备份配置",
            "backupJob":"数据备份",
            "backup-job":"数据备份",
            "backupRecoverJob":"数据恢复",
            "backup-recover-job":"数据恢复",
            "backupCleanJob":"数据清理",
            "backup-clean-job":"数据清理",
            "backupStrategy":"备份策略",
            "backup-strategy":"备份策略",
        },
        "quota": {
            "sort": "排序",
            "type": "工单类型",
            "name": "标题",
            "labelTitle":"名称",
            "description": "描述信息",
            "domainUid": "部门名称",
            "projectname": "项目名称",
            "defaultNum": "默认配额数",
            "quotaName": "资源名称",
            "network": "网络(个)",
            "subnet": "子网(个)",
            "port": "端口(个)",
            "key_pairs": "密钥对(个)",
            "securityGroups": "安全组(个)",
            "security_group": "安全组(个)",
            "volumes": "云硬盘(个)",
            "securityGroupRules": "安全组规则(个)",
            "security_group_rule": "安全组规则(个)",
            "server_groups": "虚拟机组",
            "server_group_members": "虚拟机组成员",
            "router": "路由器(个)",
            "instances": "云主机(个)",
            "cores": "CPU核(个)",
            "ram": "内存(GB)",
            "ramGb": "内存(GB)",
            "snapshots": "快照(个)",
            "floatingip": "公网IP(个)",
            "injectedFiles": "注入的文件(个)",
            "injected_files": "注入的文件(个)",
            "injectedFileContentBytes": "已注入文件内容(B)",
            "injected_file_content_bytes": "已注入文件内容大小(B)",
            "injected_file_path_bytes": "已注入文件路径大小(B)",
            "gigabytes": "硬盘(GB)",
            "metadataItems": "元数据条目(个)",
            "metadata_items": "元数据条目(个)",
            "hdd": "性能型硬盘(GB)",
            "phy_instances":"物理机(个)",
            "totalQuota": "总配额",
            "alearyAllocate": "已使用",
            "errorMessage": {
                "insufficient": "参数不能大于其部门配额",
                "quotaNum": "该部门已经分配的数额为",
                "atleast": "修改时配额不能小于该值",
                "proUsed": "该项目已经使用的数额为",
                "largerThanLeftQuota":"参数不能大于其部门对应资源剩余可用配额数"
            },
            "phy_instances":"物理云主机(个)",
            "backups":"备份(个)",
            "backup_gigabytes":"备份容量(GB)",
            "backupsNum":"备份数量(个)",
            "projectPhysicalMachine":"项目裸金属",
            "physicalMachinesNumber":"裸金属数量",
            "hardwareConfig":"硬件配置",
            "operatingSystemVersion":"支持的操作系统版本",
            "packageDownload":"安装包下载",
            "browserAccessUrl":"在被纳管裸金属(请先配置网络，使得本机可以访问云管)，浏览器访问URL",
            "excutingOrder":"在被纳管裸金属(请先配置网络，使得本机可以访问云管)，执行命令：",
            "packageCompression":"安装包解压缩",
            "compressedDirectory":"将安装包解压缩至目录C:\telegraf\下",
            "executeCommandDirectory":"在存放安装包目录下,执行命令：",
            "AutoinstallStart":"自动安装与启动",
            "runsCmd":"管理员身份运行cmd，执行以下命令：",
            "decompressionDirectory":"在解压缩目录下，执行以下命令：",
            "physicalMachineApply":"裸金属申请",
            "hardDisk":"硬盘"
        },
        "config": {
            "isCanConfig": "是否可配置",
            "tallConfig":"高配（16核16G）",
            "centreConfig":"中配（8核8G）",
            "lowConfig":"低配（4核4G）"
        },
        "cloud": {
            "cloudType": "云类型",
            "selectCloudType": "选择云类型",
            "Active": "激活",
            "SecretId": "SecretId",
            "SecretKey": "SecretKey",
            "ChooseCloud": "选择云类型",
            "AccessKeyID": "AccessKeyID",
            "AccessKeySecret": "AccessKeySecret",
            "vCenterUrl": "vCenter IP",
            "vCenterName": "vCenter名称",
            "vCenterUserName": "vCenter用户名",
            "vCenterPassword": "密码",
            "vmConsoleUrl": "控制台链接格式",
            "vmConsoleTip_1": "控制台链接示例",
            "vmConsoleTip_2": "请参考vSphere Web Client中虚拟机控制台的链接格式进行选择，默认为https",
            "accessKeyId":"accessKeyId",
            "accessKeySecret":"accessKeySecret",
            "version":"版本",
            "awsAccessKeyId":"访问密钥 ID",
            "awsSecretAccessKey":"私有访问密钥"
        },
        "logo": {
            "name":"名称",
            "params":"参数值输入/操作",
            "des":"描述",
            "loginBg":"登录界面",
            "browserLabelLogo": "浏览器标签页logo",
            "SystemPageLogo": "系统页面logo",
            "platformName": "平台名称",
            "CopyrightInformation": "版权信息内容",
            "tips_1": "logo尺寸为16*16像素",
            "tips_2": "logo格式为.ico图片",
            "tips_3": "logo文件大小不超过20KB",
            "tips_4": "logo尺寸最大为185*60像素",
            "tips_5":"logo格式为透明的.svg矢量文件",
            "tips_6":"logo文件大小不超过30KB",
            "tips_7":"点击图片上传",
            "tips_8":"格式要求",
            "login_bg_tips1":"登录背景尺寸为1920*1080像素；",
            "login_bg_tips2":"登录背景格式为.jpg,.PNG文件",
            "login_bg_tips3":"登录背景文件大小不超过1MB",
            "showButton": "显示",
            "hideButton": "隐藏",
            "showVersion":"显示版权信息",
            "error_tips":"图片格式不正确",
            "platform_tips":"请输入平台名称",
            "copyright_tips":"请输入版权信息",
            "logo_top_tips":"若图片内容未做改动（上传新logo），则维持默认logo不变"
        },
        "general": {
            "timeout": "系统超时时间",
            "selecTimeout": "请选择系统超时时间",
            "timeoutDesc": "若用户登录后，在配置的超时时间内无操作，系统会自动注销，再次使用时需要重新登录，设置时间较短，可以有效保证系统安全性，设置时间较长，可以节约用户多次登录系统的时间，用户可以根据自己的需求进行设置。",
            "expirationTimeLabel": "回收站资源过期时间(小时)",
            "expirationTime": "回收站资源过期时间",
            "expirationTimeDesc": "用户删除的资源，暂时保存在回收站中，系统默认的时间过后会删除回收站的内容，用户可根据需要设定资源在回收站中存放的时间。",
            "dataCenter": "数据中心",
            "selectDataCenter": "请选择数据中心",
            "upsType": "UPS类型选择",
            "selectUpsType": "请选择UPS类型",
            "modifying": "更新配置中",
            "modifySuccess": "修改成功",
            "modifyFailed": "修改失败",
            "time": {
                "5min": "5分钟",
                "10min": "10分钟",
                "15min": "15分钟",
                "30min": "30分钟",
                "1hour": "1小时",
                "2hour": "2小时",
                "3hour": "3小时",
                "6hour": "6小时"
            },
            "day": {
                "10day": "10天",
                "30day": "30天",
                "60day": "60天",
                "90day": "90天"
            },
            "ups": {
                "apc": "施耐德",
                "gcc": "联方云天"
            },
            "faultSelfhealing":"是否开启ceph硬盘故障自愈",
            "faultSelfhealingDesc":"启用时，系统会在检测ceph硬盘发生故障时自动将其踢出ceph集群，并在同槽位更换了健康硬盘后将其加入ceph集群",
            "cpuAlarmThreshold":"CPU告警阈值",
            "cpuAlarmThresholdDesc":"设置CPU告警等级和阈值，当CPU使用率超过该值时，触发告警提示",
            "memAlarmThreshold":"内存告警阈值",
            "memAlarmThresholdDesc":"设置内存告警等级和阈值，当内存使用率超过该值时，触发告警提示",
            "cephAlarmThreshold":"Ceph告警阈值",
            "cephAlarmThresholdDesc":"设置Ceph告警等级和阈值，当Ceph使用率超过该值时，触发告警提示",
            "criticalAlarm":"严重告警",
            "normalAlarm":"一般告警",
            "enableVerificationCode":"是否启用验证码",
            "enableVerificationCodeDesc":"登录时是否需要输入验证码",
            "enableEmailLogin":"是否启用邮箱登录",
            "enableEmailLoginDesc":"启用时，可通过邮箱登录",
            "enableCasLogin":"是否启用CAS登录",
            "enableCasLoginDesc":"启用时，可通过CAS登录",
            "cloneChain":"克隆链优化",
            "cloneChainTitle":"• 优化所有Ceph存储池的链接克隆功能的磁盘空间占用，避免创建主机失败。如系统Ceph存储池中有大量链接克隆的虚拟机，建议开启此功能。",
            "cloneChainTitleTwo":"• 此功能默认开启，执行时会占用部分磁盘IO，建议设置至少每周执行一次，每次执行50台。",
            "functionSwitch":"功能开关",
            "periodSelectionBox":"周期选择框",
            "vmNumber":"虚拟机数量",
            "vmNumberPlacehold":"请输入1-1000的整数",
            "cloneChainWeek":"周",
            "cloneChainHour":"时",
            "cloneChainMinute":"分",
            "monday":"周一",
            "tuesday":"周二",
            "wednesday":"周三",
            "thursday":"周四",
            "friday":"周五",
            "saturday":"周六",
            "sunday":"周日",
            "everyday":"每天",
            "everyWeek":"每周",
            "everymonth":"每月",
            "point":"点",
            "mark":"号",
            "cloudParameters":"云管参数",
            "cloudServiceParameters":"云服务参数",
            "setItem":"设置项",
            "describe":"描述",
            "autoLogout":"若用户登录后，在配置的超时时间内无操作，系统会自动注销",
            "quotaConfigSwitch":"配额配置开关",
            "quotaConfigSwitchTip":"控制部门和项目配额的关系，关闭时单个项目配额不能大于部门，开时，所有项目的配额和不能大于其部门的配额",
            "editPassword":"新用户是否强制修改密码",
            "editPasswordTip":"启用时，针对非admin新用户强制修改密码",
            "passwordTimeout":"是否启用用户密码超时",
            "passwordTimeoutTip1":"启用时，非admin用户密码过期时强制修改密码",
            "passwordTimeoutTip2":"(自用户创建时刻起，60天后过期)",
            "resourceSync":"资源数据同步",
            "resourceSyncTip":"用户可以选择一个时间间隔进行定时资源数据同步(若选择10分钟,点击保存，表示每隔10分钟定时进行资源数据同步)，也可以点击立即同步按钮即刻进行资源数据同步",
            "immeSync":"立即同步",
            "notSyncOperate":"请勿频繁进行立即资源同步操作",
            "syncPhysicalNode":"同步物理节点GPU信息",
            "syncPhysicalNodeTip":"启用时，获取云平台所有节点物理GPU配置信息",
            "allowUsbRedirection":"允许USB重定向",
            "allowUsbRedirectionTip":"启用时，新建虚拟机自动分配USB重定向端口",
            "loadBalancing":"高级负载均衡特性",
            "loadBalancingtTip":"启用该设置使用高级负载均衡以获得更好的性能及更多高级特性（需要License支持并提前在后端进行相关的对接配置）",
            "hostRecycle":"云主机回收站过期时间（天）",
            "hostRecycleTip":"云主机回收站资源过期时间（天），默认7天，可以在1天和30天之间配置",
            "nodeSaveMemory":"节点保存内存（GB）",
            "nodeSaveMemoryTip":"每个节点保留的最小内存数",
            "cpuException":"CPU超分比",
            "cpuExceptionTip":"正常配置是1-4，超过4有风险，超过8有可能平台中的云主机无法使用",
            "retryTimes":"创建云主机失败重试次数（次）",
            "retrySecond":"创建云主机失败重试间隔（秒）",
            "expirationDay":"数据盘回收站过期时间（天）",
            "expirationDayTip":"数据盘回收站资源过期时间（天），默认7天，可以在1天和30天之间配置",
            "createDiskretestTimes":"创建云硬盘重试次数（次）",
            "highlyAvailableTimes":"高可用触发次数（次）",
            "highAvailabilitySecond":"高可用检测间隔（秒）",
            "upsTypeSelection":"UPS类型选择"
        },
        "aboutVersion": {
            "versionEnterprise": "企业版",
            "versionStand": "标准版",
            "systemVersion": "系统版本"
        },
        "license": {
            "describe":"描述",
            "importsuccess": "激活文件导入成功",
            "importerror": "激活文件导入失败",
            "importFileSuccess":"导入文件成功",
            "importFileError":"批量导入的文件不符合要求",
            "importerror":"导入文件失败",
            "fileCheck": "文件格式必须为.pem且不超过1M",
            "sn": "SN号",
            "detail": "详情",
            "SNdetail": "SN号详情",
            "perpetual": "永久",
            "title": "软件授权",
            "versionType": "版本类型",
            "versionEnterprise": "企业版",
            "versionStand": "标准版",
            "applyActivationCode": "获取机器码",
            "importActivationCode": "导入激活文件",
            "machinecode": "机器码",
            "activationCode": "激活文件",
            "valid": {
                "true": "有效",
                "false": "无效"
            },
            "is_valid": "是否有效",
            "now": "当前时间",
            "end_time": "激活有效期限",
            "service_time": "服务有效期限",
            "available_node": "支持节点数",
            "current_node": "当前节点数",
            "checked_node": "已通过验证的节点",
            "format_error": "更新授权失败：授权参数解析失败",
            "check_error": "更新授权失败：授权参数检查未通过",
            "error": "更新授权失败",
            "success": "更新授权成功",
            "explain":"说明信息",
            "selectFile":"选择文件"
        },
        "alert": {
            "floatingipDisassociationSuccess": "公网IP解绑成功",
            "floatingipAssociationSuccess": "公网IP绑定成功"
        },
        "bill": {
            "startTime": "起始时间",
            "endTime": "结束时间",
            "search": "查询",
            "adminCount": "admin统计",
            "regionCount": "Region统计",
            "regionAmoutTotal": "总消费(元)",
            "regionAmout": "消费",
            "domainCount": "部门统计",
            "domainCountDataNull": "暂无数据",
            "projectCount": "项目统计",
            "projectCountDataNull": "暂无数据",
            "region": "数据中心",
            "regionName":"数据中心名称",
            "allRegion":"所有数据中心",
            "choiceRegion": "选择数据中心",
            "domainName": "部门名称",
            "choiceDomain": "选择部门",
            "projectName": "项目名称",
            "choiceProject": "选择项目",
            "userName": "用户名称",
            "choiceUser": "选择用户",
            "prodType": "产品类型",
            "choiceProdType": "选择产品类型",
            "enterprise": "企业名称",
            "totalAmount": "金额(元)",
            "billStartAt": "计费开始时间",
            "operate": "操作",
            "detail": "详情",
            "resDetail": "资源详情",
            "productStatus": "实例状态",
            "productPrice": "单价(元/小时)",
            "download": "下载",
            "consumption": "消费(元)",
            "vm":"云主机",
            "vmMemory":"内存",
            "cpu":"云主机CPU",
            "memory":"云主机内存",
            "volume":"云硬盘",
            "vmVolume":"云主机硬盘",
            "floatingip":"公网IP",
            "router":"路由器",
            "snapshot":"快照",
            "loadbalancer":"负载均衡（基础型）",
            "bandwidth":"带宽",
            "pm":"裸金属",
            "pmcpu":"裸金属CPU",
            "pmCpu":"裸金属CPU",
            "pmmemory":"裸金属内存",
            "pmMemory":"裸金属内存",
            "pmhd":"裸金属硬盘",
            "pmHd":"裸金属硬盘",
            "pmvolume":"裸金属硬盘",
            "pmVolume":"裸金属硬盘",
            "backupvolume":"备份",
            "backupVolume":"备份",
            "perhour":"小时",
            "permonth":"月",
            "chooseDate":"选择日期",
            "resTopUser":"各用户资源排行（前",
            "resRank":"名）",
            "resTopProject":"各项目资源排行（前",
            "resTopDomain":"各部门资源排行（前",
            "resTopRegion":"各数据中心资源排行（前",
            "lb_cpu":"负载均衡（增强型）CPU",
            "lb_memory":"负载均衡（增强型）内存",
            "priceType":"价格类型",
            "topRes":{
                "configPublicNetworkIP":"配置公网IP",
                "unloadPublicNetworkIP":"卸载公网IP",
                "hardDisk":"硬盘参数",
                "queue":"网卡多队列数",
                "vmRankList":"云主机",
                "cpuRankList":"CPU",
                "hardDisk":"硬盘参数",
                "queue":"网卡多队列数",
                "memoryRankList":"内存",
                "chdRankList":"云硬盘",
                "snapshotRankList":"快照",
                "routerRankList":"路由器",
                "floatingipRankList":"公网IP",
                "loadbalancerRankList":"负载均衡（基础型）",
                "pmRankList":"裸金属",
                "pmCpuRankList":"裸金属CPU",
                "pmMemoryRankList":"裸金属内存",
                "pmChdRankList":"裸金属硬盘",
                "backupRankList":"备份",
                "lbCpuRankList":"负载均衡（增强型）CPU",
                "lbMemoryRankList":"负载均衡（增强型）内存",
                "lbRankList":"负载均衡（增强型）"
            },
            "error": {
                "pattern": "请输入正确的时间格式,形如:yyyy-mm-dd",
                "gtTime": "结束时间必须大于或等于起始时间",
                "gtCurrTime": "选择的时间必须大于当前日期"
            },
            "timeType": {
                "realTime": "实时",
                "sixHours": "6小时",
                "oneDay": "一天",
                "threeDays": "近三天",
                "oneWeek": "近一周",
                "oneMonth": "近一月",
                "threeMonths": "近三月",
                "twelveHours":"12小时"
            },
            "billAction":"计费功能",
            "billActionDescription":" 计费开关控制是否对数据中心被占用的云主机、云硬盘、快照、路由器公网IP、负载均衡、带宽、备份、裸金属等资源进行计费",
            "billStartTip_1":"云管平台于",
            "billStartTip_2":"开始计费",
            "consumeStatistic":"消费统计",
            "priceManage":"价格管理",
            "consumeQuery":"消费查询",
            "regionCost":"数据中心费用",
            "totalCost":"总费用（元）",
            "costRatio":"费用比例",
            "resourceRatio":"资源比例",
            "cost":"费用（元）",
            "ratio":"比例",
            "resourceCost":"资源费用",
            "resource":"资源",
            "resourceType":"资源类型",
            "domainCost":"部门费用",
            "domain":"部门",
            "allDomain":"所有部门",
            "backDomain":"返回部门",
            "returnDatacenter":"返回数据中心",
            "resCostDistribution":"资源费用分布",
            "resDistribution":"云平台资源分布",
            "name":"名称",
            "projectCost":"项目费用",
            "project":"项目",
            "allProject":"所有项目",
            "backProject":"返回项目",
            "userCost":"用户费用",
            "cvsDatacenter":"数据中心,费用（元）,比例",
            "cvsDomain":"所属部门,数据中心,费用（元）",
            "cvsProject":"所属项目,数据中心,所属部门,费用（元）",
            "cvsUser":"用户,数据中心,所属部门,所属项目,费用（元）",
            "billFileExport":"消费统计数据导出.csv",
            "user":"用户",
            "allUser":"所有用户",
            "allRes":"所有资源",
	        "fixedIps":"私网IP",
            "floatingIps":"公网IP",
            "cpuPeakMap":"CPU峰值",
            "cpuPeakTimeMap":"CPU峰值时间",
            "memPeakMap":"内存峰值",
            "memPeakTimeMap":"内存峰值时间",
            "iopsPeakMap":"磁盘IOPS峰值(写)",
            "iopsPeakTimeMap":"磁盘IOPS峰值时间(写)",
            "iopsReadPeakMap":"磁盘IOPS峰值(读)",
            "iopsReadPeakTimeMap":"磁盘IOPS峰值时间(读)",
            "iopsPeakMap":"磁盘IOPS峰值",
            "iopsPeakTimeMap":"磁盘IOPS峰值时间",
            "networkCardEnterMap":"网卡入流量均值",
            "networkCardEnterPeakMap":"网卡入流量峰值",
            "networkCardEnterPeakTimeMap":"网卡入流量峰值时间",
            "networkCardOutMap":"网卡出流量均值",
            "networkCardOutPeakMap":"网卡出流量峰值",
            "networkCardOutPeakTimeMap":"网卡出流量峰值时间",

            "productName":"产品名称",
            "moneyAmount_yuan":"金额（元）",
            "moneyAmount":"金额",
            "billStartTime":"计费开始时间",
            "belong_project":"所属项目",
            "belong_domain":"所属部门",
            "regionConsumeStatistic":"数据中心消费统计",
            "domainConsumeStatistic":"部门消费统计",
            "projectConsumeStatistic":"项目消费统计",
            "userConsumeStatistic":"用户消费统计",
            "regionResConsumeStatistic":"数据中心资源消费统计",
            "domainResConsumeStatistic":"部门资源消费统计",
            "projectResConsumeStatistic":"项目资源消费统计",
            "userResConsumeStatistic":"用户资源消费统计",
            "core":"核",
            "one":"个",
            "deleteTip":"您确定删除所选内容吗？",
            "userResBillDetailExport":"用户资源账单详情导出",

            "meteringView":"计量视图",
            "meteringStatistic":"计量统计",
            "resUsageStatistic":"资源使用率统计",
            "statistic":"统计",
            "pleaseSelect":"请选择",
            "type":"类型",
            "query":"查询",
            "export":"导出",
            "hour":"小时",
            "month":"月",
            "amount":"数量",
            "capacity":"容量",
            "userAmount":"用户数量",
            "currentUsingAmonut":"当前使用量",
            "alreadyUsedAmount":"累计使用量",
            "resObj":"资源对象",
            "statisticDimension":"统计维度",
            "timeFilter":"时间筛选",
            "pleaseSelectTime":"请选择时间",
            "unSelect":"未选择",
            "highest":"最高",
            "lowest":"最低",
            "top":"前",
            "top_name":"名",
            "unit":"单位",
            "custom":"自定义",
            "recent_3d":"最近三天",
            "recent_week":"最近一周",
            "recent_month":"最近一个月",
            "recent_3month":"最近三个月",
            "meteringViewDataExport":"计量视图数据导出",
            "cols":{
                "vm_unit":"云主机（个）",
                "vm_unit_hour":"云主机（个-小时）",
                "cpu_unit":"CPU（个）",
                "cpu_unit_hour":"CPU（个-小时）",
                "memory_unit":"内存（GB）",
                "memory_unit_hour":"内存（GB-小时）",
                "volume_unit":"云硬盘（GB）",
                "volume_unit_hour":"云硬盘（GB-小时）",
                "snapshot_unit":"快照（个）",
                "snapshot_unit_hour":"快照（个-小时）",
                "floationgip_unit":"公网IP（个）",
                "floationgip_unit_hour":"公网IP（个-小时）",
                "router_unit":"路由器（个）",
                "router_unit_hour":"路由器（个-小时）",
                "loadBalance_unit":"负载均衡（个）",
                "loadBalance_unit_hour":"负载均衡（个-小时）",
                "pm_unit":"物理机（个）",
                "pm_unit_hour":"物理机（个-小时）",
                "pmcpu_unit":"物理机CPU（个）",
                "pmcpu_unit_hour":"物理机CPU（个-小时）",
                "pmmemory_unit":"物理机内存（GB）",
                "pmmemory_unit_hour":"物理机内存（GB-小时）",
                "pmvolume_unit":"物理机硬盘（GB）",
                "pmvolume_unit_hour":"物理机硬盘（GB-小时）",
                "backup_unit":"备份（GB）",
                "backup_unit_hour":"备份（GB-小时）"
            },
            "regionResMeteringStatistic":"数据中心计量统计",
            "domainResMeteringStatistic":"部门计量统计",
            "projectResMeteringStatistic":"项目计量统计",
            "userResMeteringStatistic":"用户计量统计",
            "regionResUsageDetail":"数据中心资源使用量详情",
            "domainResUsageDetail":"部门资源使用量详情",
            "projectResUsageDetail":"项目资源使用量详情",
            "userResUsageDetail":"用户资源使用量详情",
            "cpuUsage":"CPU使用率",
            "memoryUsage":"内存使用率",
            "volumeUsage":"硬盘使用率",
            "resUsageRankChartExport":"资源使用率排行图导出",
            "domianResUsageStatisticExport":"部门资源使用率统计导出",
            "projectResUsageStatisticExport":"项目资源使用率统计导出",
            "vmResUsageStatisticExport":"虚拟机资源使用率统计导出",
            "pmResUsageStatisticExport":"物理机资源使用率统计导出",
            "vmCpuUsageStatistic":"虚拟机CPU使用率统计",
            "vmMemoryUsageStatistic":"虚拟机内存使用率统计",
            "vmVolumeUsageStatistic":"虚拟机硬盘使用率统计",
            "pmCpuUsageStatistic":"物理机CPU使用率统计",
            "pmMemoryUsageStatistic":"物理机内存使用率统计",
            "pmVolumeUsageStatistic":"物理机硬盘使用率统计",
            "userDetail":"用户详情",
            "billDetail":"账单详情",
            "closeBillingText":"关闭计费",
            "closeBillMessageTitle":"关闭功能后,计费功能会停止计费,当前计费数据会保留"
        },
        "price": {
            "prodType": "产品类型",
            "choiceProdType": "请选择产品类型",
            "choicePriceType": "请选择单价类型",
            "choiceRegion":"请选择数据中心",
            "serialNumber": "序号",
            "enterpriseName": "企业名称",
            "region": "数据中心",
            "allRegion":"全部数据中心",
            "allType":"全部类型",
            "unit": "单位",
            "choiceUnit":"请选择单位",
            "priceType": "单价类型",
            "allPriceType":"全部单价类型",
            "billingType":"计费类型",
            "choiceBillingType":"请选择计费类型",
            "price": "单价",
            "price_unit":"单价（元）",
            "price_unit_hour":"单价（元/小时）",
            "operate": "操作",
            "edit": "修改",
            "editProductPrice": "修改产品单价",
            "determine": "确定",
            "cancel": "取消",
            "PleaseEnterPrice": "请输入单价",
            "perHour": "小时",
            "perMonth": "月",
            "all": "全部",
            "error": {
                "pattern": "请输入大于0的数(小数位不超过两位)",
                "gtZeroNumTip":"请输入大于等于0小于等于999999.99的数,若输入小数,小数点后请最多输入两位",
            },
            "hour":"小时",
            "month":"月",
        },
        "consume": {
            "selectType": "选择类型",
            "detailQuery": "详单查询",
            "billQuery": "账单查询",
            "selectTime": "选择时间",
            "selectMonth": "选择月份",
            "resourceType": "资源类型",
            "choiceResource": "请选择资源类型",
            "region": "数据中心",
            "choiceRegion": "请选择数据中心",
            "domain": "部门",
            "choiceDomain": "请选择部门",
            "project": "项目",
            "choiceProject": "请选择项目",
            "user": "用户",
            "choiceUser": "请选择用户",
            "resourceName": "资源名称",
            "price": "单价(元/小时)",
            "amount": "金额(元)",
            "startTime": "计费开始时间",
            "pleaseChoose": "请选择",
            "all": "全部",
            "allregion": "全部数据中心",
            "alldomain": "全部部门",
            "allproject": "全部项目",
            "alluser": "全部用户",
            "searchResource": "搜索资源名称",
        },
        "grade": {
            "table": {
                "status": {
                    1: "成功",
                    2: "失败",
                    3: "升级中",
                }
            },
	      "sysVersionChanges":"底层版本变更",
            "saasVersionChanges":"云管版本变更",
            "patchInfo":"升级包描述",
            "gradeTime":"更新时间",
            "status":"状态",
            "operation":"操作",
            "retry":"重试",
            "view":"查看",
            "checkUpgrating":"检查更新中....",
            "uploadPatch":"上传升级包",
            "uploadTips":"通过下面SFTP地址上传软件升级包，如需Windows SFTP客户端请",
            "downloadSoft":"点击下载",
            "fileTips32":"FILEZILLA 32位 免安装版",
            "fileTips64":"FILEZILLA 64位 免安装版",
            "download":"下载",
            "sftpAddr":"SFTP地址：",
            "tips":"提示",
            "user":"用户名：",
            "port":"端口：",
            "expireTime":"过期时间：",
            "password":"密码：",
            "randomPassword":"获取随机密码",
            "retryRandomPassword":"获取随机密码失败,请再试一次",
            "check":"检测到",
            "numOfPatch":"个升级包",
            "upgradingTips":"升级时请勿手动关闭窗口或刷新页面(升级过程中由于系统版本和网络差异，升级页面可能会自动刷新！)",
            "noPatch":"暂无可用升级包",
            "moreInfo":"更多详情",
            "versionError":"升级包版本不匹配，无法升级",
            "installedVersion":"已装版本",
            "lastVersion":"最新版本",
            "upgraded":"升级已完成",
            "upgrade":"升级",
            "saasInstalledVersion":"云管已装版本",
            "saasLastVersion":"云管最新版本",
            "sysInstalledVersion":"底层已装版本",
            "sysLastVersion":"底层最新版本",
            "updateContent":"更新内容",
        },
        "k8s": {
            "dockerClusters": {
                "createCluster": "新建集群",
                "deleteCluster": "删除集群",
                "confirmDeleteDockerClusters": "您确认删除所选集群？",
                "scaleCluster": "动态伸缩",
                "rollingUpdateCluster": "滚动升级",
                "name": "集群名称",
                "labels": "标签",
                "replicas": "期望的副本数",
                "currentTotal": "当前的副本总数",
                "currentRunning": "运行中的副本数",
                "observedGeneration": "",
                "creationTimestamp": "创建的时间",
                "status": "集群的状态",
                "returnToCluster": "返回集群页面",
                "clusterDetails": "容器集群详情",
                "returnToClusterDetails": "返回集群详情页面",
                "clusterReplicaDetails": "容器集群副本详情",
                "clusterReplicaName": "集群副本名称",
                "clusterReplicaStatus": "集群副本状态",
                "clusterReplicaIp": "集群副本ip",
                "clusterReplicaHostIp": "集群副本所在物理机ip",
                "clusterReplicaHostName": "集群副本所在物理机主机名",
                "clusterReplicaCreationTimestamp": "副本创建的时间",
                "clusterReplicaTerminationGracePeriodSeconds": "优雅关闭集群的等待时间",
                "clusterReplicaStartTime": "副本开始运行的时间",
                "clusterReplicaOverview": "概况",
                "clusterReplicaContainers": "容器",
                "clusterReplicaVolumes": "云硬盘",
                "clusterReplicaLogs": "日志",
                "clusterReplicaLogsAutowrap": "日志自动换行",
                "clusterReplicaRestartPolicy": "集群重启策略",
                "clusterReplicaDNSPolicy": "DNS策略",
                "clusterReplicaVolumeName": "云硬盘（集群内所有容器共享）名称",
                "clusterReplicaVolumefsType": "云硬盘的文件系统",
                "clusterReplicaVolumeImage": "云硬盘使用的镜像",
                "clusterReplicaVolumePool": "存储池",
                "clusterReplicaVolumeUser": "用户",
                "clusterReplicaVolumeMonitors": "Ceph Monitors",
                "returnToClusterReplicaDetails": "返回集群副本详情页面",
                "restartClusterReplica": "重启",
                "deleteClusterReplica": "删除集群副本",
                "confirmDeleteClusterReplica": "确认删除选中的集群副本？",
                "deleteClusterReplicaSuccess": "删除集群副本成功",
                "containerName": "容器的名称",
                "containerImage": "容器使用的镜像",
                "containerImagePullPolicy": "镜像拉取策略",
                "containerStatus": "容器的状态",
                "containerRestartCounts": "容器重启次数",
                "containerStartedAt": "容器启动的时间",
                "containerDetails": "容器详情",
                "containerEnvs": "环境变量",
                "containerPorts": "端口映射",
                "containerVolumes": "云硬盘映射",
                "containerLogs": "容器日志",
                "containerArgs": "启动参数",
                "containerCommands": "启动命令",
                "containerAdvisors": "容器监控",
                "containerEnvName": "环境变量的名称",
                "containerEnvValue": "环境变量的值",
                "containerPortName": "端口的名称",
                "containerContainerPort": "容器集群内部的端口号",
                "containerHostPort": "物理机暴露的端口号",
                "containerPortProtocol": "端口使用的协议",
                "containerVolumeName": "容器（集群内所有的容器共享）使用的云硬盘名称",
                "containerVolumeMountPath": "容器内部的挂载点",
                "containerVolumeReadOnly": "云硬盘的读写权限",
                "dockerClustersPluginOneHref":"如需使用容器管理块功能，请打开”系统管理 > 系统设置 >",
                "dockerClustersPluginTwoHref":"“界面添加”容器管理”模块"
            },
            "createCluster": {
                "tital": "新建Docker容器集群",
                "basic": "基本选项",
                "advanced": "高级选项",
                "clusterName": "集群名称：",
                "clusterNameRequired": "必填项，不能是已存在的名字",
                "clusterNameNotEmpty": "集群名字不能为空！",
                "clusterNameRepeated": "集群名字已经存在，请换一个！",
                "clusterNameTooLong": "至多输入32位",
                "portNameLimit":"不能超过32位",
                "clusterNameMismatchPattern": "集群名字必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-",
                "clusterReplicas": "集群副本：",
                "clusterReplicasRequired": "期望集群运行时的副本数目",
                "clusterReplicasNotEmpty": "必填项！",
                "dnsPolicy": "DNS策略：",
                "dnsPolicyTips": "推荐使用ClusterFirst，这样可以直接使用服务的名字（dns自动解析）来访问",
                "restartPolicy": "重启策略：",
                "restartPolicyTips": "集群副本内所有容器的重启策略，默认为Always",
                "nodeSelectors": "节点选择器：",
                "nodeSelect": "节点选择",
                "addNodeSelectors": "添加选择器",
                "hideNodeSelectors": "隐藏选择器",
                "showNodeSelectors": "显示选择器",
                "deleteSelector": "删除这个选择器",
                "selectZeroNode": "【选择器匹配错误】：没有节点能同时满足上面所有选择器的条件，请重新选择！",
                "selector": "选择器",
                "clusterLabels": "集群的标签：",
                "addClusterLabels": "添加标签",
                "hideClusterLabels": "隐藏标签",
                "showClusterLabels": "显示标签",
                "clusterLabelsOptional": "可选项",
                "labelKey": "填写新标签的key",
                "labelKeyRequired": "key不能为空！",
                "labelKeyMismatch": "key分成两部分（prefix/name）：prefix（可选的）是以点.分割的域名（域名的每部分为字母或数字）；" +
                "name必须以字母或数字开头、结束，中间为字母、数字、点.、下划线_、横线-；两个部分使用/分割。",
                "labelKeyDomainToLong": "key的prefix总长不能超过253字符！",
                "labelKeyNameToLong": "key的name总长不能超过63字符！",
                "labelValueRequired": "value不能为空！",
                "labelValueMismatch": "value必须以字母或数字开头、结束，中间为字母、数字、点.、下划线_、横线-",
                "labelValueMinLength": "value不能少于2个字符！",
                "labelValueMaxLength": "value不能超过63个字符！",
                "deleteLabel": "删除这个label",
                "clusterVolumes": "集群云硬盘：",
                "hideClusterVolumes": "隐藏云硬盘",
                "showClusterVolumes": "显示云硬盘",
                "addClusterVolumes": "添加云硬盘",
                "volumeNameRequired": "必填项",
                "volumeNameMismatch": "云硬盘的名字必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-",
                "volumeNameTooLong": "云硬盘的名字不能超过16个字符",
                "volumeNameRepeated": "云硬盘的名字重复，请换个名字！",
                "volumeSizeRequired": "必填项",
                "deleteVolume": "删除这个云硬盘",
                "containerList": "容器列表：",
                "addClusterContainers": "添加容器",
                "deleteContainer": "删除这个容器",
                "container": "容器#",
                "containerName": "容器名称：",
                "containerNameRequired": "必填项",
                "containerNameMismatch": "容器的名字必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-",
                "containerNameTooLong": "容器的名字不能超过32个字符",
                "imageName": "镜像名称：",
                "imageNameRequired": "必填项",
                "selectDockerImage": "选择镜像：",
                "projectType": "仓库类型：",
                "projectTypeTips": "选择私有/公有镜像仓库",
                "projectName": "项目名称：",
                "repoName": "仓库名称：",
                "tagName": "标签名称：",
                "workingDir": "工作目录：",
                "workingDirOptional": "选填项：默认的工作目录在制作镜像时设定",
                "workingDirMismatch": "工作目录格式不符合linux的目录",
                "commands": "启动命令：",
                "showContainerCmds": "显示启动命令",
                "hideContainerCmds": "隐藏启动命令",
                "addContainerCmds": "添加启动命令",
                "deleteContainerCmd": "删除这条启动命令",
                "commandsTips": "启动命令一般在制作镜像时设定，不需要时请删除！",
                "containerCmdRequired": "必填项：请填写正确的启动命令或者将这条记录删除！",
                "envs": "环境变量：",
                "hideContainerEnvs": "隐藏环境变量",
                "showContainerEnvs": "显示环境变量",
                "addContainerEnvs": "添加环境变量",
                "deleteContainerEnv": "删除这个环境变量",
                "envsKeyTips": "必填项",
                "envsValueTips": "必填项",
                "containerEnvNameRequired": "环境变量的名称一般在制作docker镜像时使用的`docker-entrypoint.sh`脚本中设定",
                "containerEnvValueRequired": "环境变量的值和类型一般在制作docker镜像时使用的`docker-entrypoint.sh`脚本中设定",
                "ports": "端口映射：",
                "portLabel": "端口映射#",
                "hideContainerPorts": "隐藏端口映射",
                "showContainerPorts": "显示端口映射",
                "addContainerPorts": "添加端口映射",
                "deleteContainerPort": "删除端口映射",
                "portsOptional": "选填项",
                "portName": "端口名称：",
                "portNameMismatch": "端口的名字必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-；且不能超过32个字符",
                "portProtocol": "端口协议：",
                "containerPort": "容器端口：",
                "containerPortTips": "必填项",
                "containerPortRequired": "容器监听的端口号是必填项！",
                "portMismatch": "端口号必须是数字，处于1-65535之间",
                "hostPort": "主机端口：",
                "hostPortTips": "选填项：最好不填！",
                "volumeMounts": "持久存储：",
                "addContainerVolumeMounts": "添加存储卷",
                "hideContainerVolumeMounts": "隐藏存储卷",
                "showContainerVolumeMounts": "显示存储卷",
                "volumeMountsLabel": "存储卷#",
                "deleteContainerVolumeMount": "删除这个存储卷",
                "podVolumeName": "存储卷名称：",
                "podVolumeNameTips": "容器使用的集群的云硬盘",
                "volumeMountNameRequired": "必填项，必须是上页创建的云硬盘！",
                "rwMode": "读写模式：",
                "mountPath": "容器内路径：",
                "mountPathTips": "必填项：云硬盘挂载到容器里的绝对路径",
                "mountPathRequired": "必填项",
                "resources": "资源配额：",
                "hideContainerResources": "隐藏资源配额",
                "showContainerResources": "显示资源配额",
                "requestsCPU": "启动的CPU：",
                "requestsCPUTips": "必填项",
                "requestsCPURequired": "启动时的CPU配额是必填项！",
                "requestsCPUMismatch": "可以为整数，小数或者整数加上m。0.5等价于500m，单位：毫cpu（millicpu）",
                "requestsMemory": "启动的内存：",
                "requestsMemoryRequired": "启动的内存配额是必填项！",
                "requestsMemoryMisMatch": "必须是整数加上内存的单位，内存的单位:Ki, Mi, Gi, Ti, Pi, Ei",
                "requestsMemoryTips": "必填项",
                "limitsCPU": "最大的CPU：",
                "limitsCPURequired": "容器运行时的最大CPU配额是必填项！",
                "limitsCPUMismatch": "可以为整数，小数或者整数加上m。0.5等价于500m，单位：毫cpu（millicpu）",
                "limitsMemory": "最大的内存：",
                "limitsMemoryTips": "必填项",
                "limitsMemoryRequired": "容器运行时的最大内存配额是必填项！",
                "limitsMemoryMisMatch": "必须是整数加上内存的单位，内存的单位:Ki, Mi, Gi, Ti, Pi, Ei",
            },
            "scaleCluster": {
                "RCUsedRBD": "【本集群不能动态伸缩】集群使用了RBD块设备作为持久化存储，一个RBD块设备只能同时被一个集群使用！",
                "RCNOTReady": "【本集群不能动态伸缩】集群期望的副本数，集群当前的副本数，集群当前运行的副本数不一致！",
                "clusterName": "集群的名称：",
                "replicas": "集群副本数：",
                "replicasRequired": "集群副本数是必填项！",
                "replicasMustBeDifferent": "新的集群副本数不能与原有值相同！",
                "replicasMustBePositive": "集群副本数必须是正整数！",
                "scaleDockerClusterSuccess": "动态伸缩集群副本数成功！",
                "limit_1000":"请输入1-999之间的正整数",
            },
            "rollingUpdate": {
                "oldClusterName": "集群原始名称：",
                "newClusterName": "集群新的名称：",
                "newClusterNameNotRequired": "【可选项】：滚动升级之后集群将会使用的新名称；否则将使用原有的名称",
                "chooseContainer": "升级的容器：",
                "chooseContainerTips": "【必选项】：选择需要升级镜像的容器名称",
                "oldImageName": "旧镜像名称：",
                "newImageName": "新镜像名称：",
                "projectType": "仓库类型：",
                "projectName": "项目名称：",
                "repoName": "仓库名称：",
                "tagName": "标签名称：",
                "pollInterval": "轮询间隔：",
                "pollIntervalTips": "滚动升级时，新旧版本容器交替更新的轮询间隔（默认是：3s）",
                "timeMismatchPattern": "必须是“xxhyymzzs”的组合模式（xx，yy，zz是整数，其中yy和zz在[0,59)范围内）",
                "updatePeriod": "更新周期：",
                "updatePeriodTips": "滚动升级时，交替增加新版本容器并删除旧版本容器的时间间隔（默认是：1m0s）",
                "timeout": "超时时间：",
                "timeoutTips": "滚动升级操作用时超过多长时间将被强行终止（默认是：5m0s）",
            },
            "dockerServices": {
                "createService": "新建容器服务",
                "deleteServices": "删除容器服务",
                "confirmDeleteDockerServices": "【警告】确认删除所选的容器服务？",
                "name": "服务的名称",
                "clusterIP": "服务的虚IP（VIP）",
                "serverClusterIP": "服务的虚IP",
                "type": "服务的类型",
                "sessionAffinity": "保持会话",
                "ports": "服务的端口",
                "labels": "服务的标签",
                "creationTimestamp": "服务创建时间",
                "details": "容器服务详情",
                "selectors": "容器服务选择的集群",
                "portName": "服务端口的名字",
                "protocol": "端口使用的协议",
                "port": "服务监听的端口",
                "targetPort": "转发到后端集群副本的端口",
                "nodePort": "物理节点暴露的端口",
                "useServiceWithinCluster": "k8s集群内部访问容器服务示例",
                "useServiceOutOfCluster": "k8s集群外部访问容器服务示例",
            },
            "createService": {
                "serviceName": "服务的名称：",
                "serviceNameTips": "必填项：不能是已存在的名字",
                "serviceNameNotEmpty": "服务的名称不能为空！",
                "serviceNameTooLong": "服务的名称不能超过32个字符！",
                "serviceNameRepeated": "该服务名称已经存在，请换一个名字！",
                "serviceNameMismatchPattern": "服务名字必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-",
                "dockerClusters": "使用服务的集群：",
                "dockerClustersTips": "必选项：请选择使用该服务的集群",
                "dockerClusterLabels": "容器集群选择器：",
                "serviceType": "容器服务类型：",
                "sessionAffinity": "session会话保持：",
                "sessionAffinityTips": "建议tcp的服务选择ClientIP支持session会话保持；选择None将不支持",
                "serviceTypeTips": "默认ClusterIP只能在k8s集群内访问；NodePort允许在k8s集群外部访问",
                "portsLabel": "服务的端口#",
                "portName": "端口名称：",
                "portNameOptional": "必填项",
                "portNameNotEmpty": "服务的端口的名称不能为空",
                "portNameMismatch": "端口名字必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-",
                "portNameTooLong": "端口的名称不能超过32个字符！",
                "protocol": "端口协议：",
                "port": "服务端口：",
                "portTips": "选填项：默认等于目标端口",
                "portMismatch": "端口号必须是数字，处于1-65535之间",
                "targetPort": "目标端口：",
                "nodePort": "物理端口：",
                "nodePortTips": "必选项：系统随机选择未使用的node节点端口，对外提供服务",
                "nodePortsRepeated": "不同的服务端口映射的物理端口有重复，请重新选择！",
            },
            "dockerImages": {
                "myProjects": "我的项目",
                "publicProjects": "公开项目",
                "createProject": "新建项目",
                "deleteProjects": "删除项目",
                "projectName": "项目名称",
                "imageRepoCount": "镜像仓库",
                "roles": "角色",
                "createTime": "创建时间",
                "isPublic": "是否公开",
                "isPublicTips": "当项目设为公开后，任何人都有此项目下镜像的读权限！命令行用户不需要“docker login”就可以拉取此项目下的镜像",
                "actions": "操作",
                "projectNameTips": "必填项：不能是已存在的名字",
                "projectNameNotEmpty": "项目名字不能为空",
                "projectNameRepeated": "项目名字已存在，请换一个！",
                "projectNameTooLong": "项目名字不能超过32个字符",
                "projectNameMismatchPattern": "项目名称必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-",
                "confirmDeleteDockerImages": "确定要删除所选的项目？",
                "returnToProjects": "返回项目列表",
                "projectDetails": "项目详情",
                "deleteImageRepos": "删除镜像仓库",
                "createRepository": "新建镜像/仓库",
                "imageRepoName": "镜像仓库的完整名称",
                "imageRepoDeleteWarn": "【警告】：删除镜像仓库将删除镜像仓库下的所有标签！<br>镜像空间将在垃圾回收过程中释放",
                "returnToProjectDetails": "返回项目详情",
                "repositoryTagsDetails": "镜像仓库详情",
                "imageRepoTags": "镜像标签",
                "deleteImageRepoTags": "删除镜像标签",
                "pullImageCmd": "拉取镜像命令",
                "imageRepoTagDeleteWarn": "【警告】：此镜像仓库下所有指向该镜像的标签将会被删除！",
                "returnToImageTags": "返回镜像仓库",
                "dockerImageManifests": "镜像详情",
                "dockerImagesOverview": "镜像概况",
                "dockerImageHistory": "镜像构建历史",
                "dockerImageLsyers": "镜像的层次",
                "imageName": "镜像的完整名称",
                "creationTimestamp": "镜像创建时间",
                "imageAuthor": "镜像维护者",
                "imageOS": "镜像操作系统",
                "imageDockerVersion": "制作镜像使用的Docker版本",
                "imageArchitecture": "镜像操作系统架构",
                "createInstruct": "构建本层镜像使用的指令",
                "imageBuiltAuthor": "本层镜像的维护者",
                "isEmptyLayer": "是否为空层",
                "createdTime": "本层镜像的构建时间",
                "layerDigest": "本层镜像的SHA256摘要",
                "layerMediaType": "本层镜像的文件类型",
                "layerSize": "本层镜像的大小（gzip压缩后）",
                "newRepoName": "新建仓库的名称",
                "newRepoNameTips": "选填项：可以新建镜像仓库，或者选择已有的镜像仓库；必须为二选一！",
                "newRepoNameTooLong": "仓库的名称不能超过10个字符！",
                "newRepoNameMismatchPattern": "仓库的名称必须以小写字母开始，以小写字母或数字结束；中间可以为小写字母，数字，横线-",
                "choseRepository": "选择已有的仓库",
                "oldRepoName": "已有仓库的名称",
                "oldRepoNameTips": "请选择一个属于本项目的镜像仓库名称",
                "tagName": "镜像的标签",
                "tagNameTips": "必填项",
                "tagNameNotEmpty": "镜像的标签不能为空！",
                "tagNameTooLong": "镜像的标签不能超过10个字符！",
                "tagNameMismatchPattern": "镜像的标签必须以小写字母或数字开始和结束；中间可以为字母，数字，横线-，点号.，下划线_",
                "tagDuplicated": "tag 重复，请重新指定一个！",
                "choseUploadDockerfile": "上传 Dockerfile",
                "choseDockerfile": "选择本地文件",
                "localDockerfile": "本地 Dockerfile 文件",
            },
            "dockerNodes": {
                "name": "节点名称",
                "details": "节点详情",
                "LegacyHostIP": "外部IP",
                "InternalIP": "节点IP",
                "KubeletReady": "kubelet状态",
                "operatingSystem": "操作系统",
                "kernelVersion": "内核版本",
                "osImage": "发行版本",
                "architecture": "系统架构",
                "kubeletVersion": "kubelet版本",
                "kubeProxyVersion": "kubeProxy版本",
                "containerRuntimeVersion": "docker版本",
                "creationTimestamp": "节点创建时间",
                "KubeletHasSufficientMemory": "内存空间",
                "KubeletHasSufficientDisk": "磁盘空间",
                "KubeletHasNoDiskPressure": "磁盘压力",
                "podSchedulable": "可调度POD",
                "cpu": "CPU个数",
                "memory": "系统内存",
                "pods": "可运行POD数",
                "nodesDetails": "节点概况",
                "nodesLabels": "标签列表",
                "labelKey": "标签名称",
                "labelValue": "标签的值",
                "addNodeLabels": "增加节点标签",
                "modifyNodeLabel": "修改节点标签",
                "deleteNodeLabels": "删除节点标签",
                "addNewNodeLabel": "增加标签对",
                "confirmDeleteNodeLabels": "【警告】删除标签可能导致POD不可调度到此节点！<br>确定？",
            }
        },
        "backupStrategy":{
            "backupStrategyDetail":"备份策略详情",
            "baseMsg":"基础信息",
            "Newbackupstrategy":"新建备份策略",
            "BackupstrategyName":"备份策略名称",
            "Backupexecutionfrequency":"备份的执行频率",
            "Firstexecutiontime":"首次执行时间",
            "Keepbackups":"保留备份份数",
            "Creationtime":"创建时间",
            "Backupstrategydetails":"备份策略详情",
            "Basicinformation":"基础信息",
            "by":"按",
            "every":",每隔",
            "Executeonce":"执行一次",
            "once":"次",
            "hour":"小时",
            "day":"天",
            "week":"周",
            "month":"月",
            "minute":"分钟",
            "one":"一",
            "part":" 份",
            "Deletecontent":"您确定要删除所选内容吗？",
            "Resourcesname":"资源名称",
            "Cloudhost":"云主机",
            "Firstexecutiontime":"首次执行时间",
            "Nextexecutiontime":"下次执行时间",
            "backupstrategyCloudhost":"使用该备份策略的云主机",
            "backupstrategyClouddrive":"使用该备份策略的云硬盘",
            "backuppolicyname":"请输入备份策略名称",
            "Executionfrequency":"执行频率",
            "32characters":"至多输入32个字符",
            "timeinterval":"时间间隔",
            "Pleasetimeinterval":"请输入时间间隔",
            "pleaseenter":"请输入",
            "inputAccount":"请输入账号",
            "Positiveinteger":"的正整数",
            "Theminimumnumber":"保留份数最小值为1，最大值为5。",
            "Timeformat":"时间格式不正确，例",
        },
        "BackupRecoveryBackup":{
            "Backuptitle":"备份",
            "Systemdiskbackup":"系统盘备份",
            "Datadiskbackup":"数据盘备份",

        },
        "shutDownDatacenter":{
            "01030709":"用户已被锁定",
            "01030702":"用户名或密码错误",
            "01030703":"用户所在IP地址被拒绝访问",
            "01030708":"平台正在升级，请稍后进行操作。",
        },
        "topo":{
            "editTopo":"编辑",
            "scaleTopo":"放大",
            "reduceTopo":"缩小",
            "externalNet":"外网",
            "firewall":"防火墙",
            "router":"路由器",
            "network":"网络",
            "subnet":"子网",
            "instance":"云主机",
            "exNetName":"外网名称",
            "exNetCidr":"外网网段",
            "firewallName":"防火墙名称",
            "status":"状态",
            "routerName":"路由器名称",
            "networkName":"网络名称",
            "networkType":"网络类型",
            "networkBandwidth":"网络带宽",
            "shared":"共享",
            "status":"状态",
            "subnetName":"子网名称",
            "gatewayIp":"网关IP",
            "gateway":"网关",
            "allocationPool":"分配IP地址池",
            "enableDhcp":"启用DHCP",
            "dnsNameserver":"DNS域名服务器",
            "hostRoutes":"附加路由",
            "instanceName":"云主机名称",
            "image":"镜像",
            "specification":"规格",
            "security_group":"安全组",
            "volume":"云硬盘",
            "startZoon":"启动区域",
            "keypairs":"密钥对",
            "linkedSubnetName":"连接的子网名称",
            "portIp":"端口IP",
            "netCard":"网卡",
            "newAdd":"新增",
            "createFirewall":"新建防火墙",
            "linkedRouter":"关联路由器",
            "pleaseSelectLinkRouter":"请选择关联路由器",
            "parts":"部件",
            "refresh":"刷 新",
            "return":"返 回",
            "instanceStatus1":"暂停,热升级,调整配置中,取消热升级,异常,无法识别状态的云主机",
            "instanceStatus2":"运行或健康状态的云主机",
            "instanceStatus3":"挂起状态的云主机",
            "instanceStatus4":"关机状态的云主机",
            "instanceStatus5":"错误状态的云主机",
            "instanceStatus6":"其他状态的云主机"
        },
        "bigmem":{
            "msg":{
                "warning":"用户添加大页内存配置后，需要重启节点，该物理节点上原有虚拟机会受到影响，建议提前进行对应节点的虚拟机迁移。"
            },
            "btn":{
                "restart":"节点重启"
            },
            "dataTitles":{
                "node":"节点",
                "defaultMem":"默认内存页大小",
                "mem":"内存页大小",
                "num":"页数",
                "action":"操作",
            },
            "dialog":{
                "bigRestartNode":"重启节点",
                "sriovdel":"删除"
            },
            "placeholder":{
                "node":"请选择节点",
                "defaultMem":"请选择默认内存页",
                "mem":"请选择内存页",
                "num":"请输入页数"
            }
        },
        "sriov":{
            "dataTitles":{
                "node":"节点",
                "networkName":"网卡名称",
                "status":"状态",
                "number":"VF个数",
                "srName":"SR-IOV网络名称",
                "srType":"SR-IOV网络类型",
            },
            "dialog":{
                "create":"添加",
                "flat":"flat",
                "vlan":"vlan",
                "retry":"您确定要重新配置选中的网卡的SR-IOV功能吗？"
            },
            "msg":{
                "0": "创建失败",
                "1": "创建成功",
                "2": "创建中",
                "3": "删除失败",
                "4": "删除中",
                "tips":"使用SR-IOV物理网络创建的私有网络，不允许直接创建虚拟机；必须在虚拟网卡界面，创建一个直连模式的虚拟网卡，再到新增云主机界面，使用该虚拟网卡创建虚拟机"
            },
            "label":{
                "node":"选择节点",
                "network":"选择网卡",
                "vfnum":"VF个数",
                "srname":"SR-IOV网络名称",
                "srtype":"SR-IOV网络类型",
                "vlanStart":"起始VLAN",
                "vlanEnd":"结束VLAN"
            },
            "placeholder":{
                "node":"请选择节点",
                "network":"请选择网卡",
                "vfnum":"请输入VF个数",
                "srname":"请输入SR-IOV网络名称",
                "vlanStart":"请输入起始VLAN",
                "vlanEnd":"请输入结束VLAN"
            }
        },
        "dpdk":{
            "dataTitles":{
                "node":"节点",
                "networkName":"网卡名称",
                "status":"状态",
                "desc":"网卡描述"
            },
            "msg":{
                "allData":"全部",
                "tips":"DPDK功能用于加速云主机和物理主机处理网络数据包的速度。配合Hugepage和CPU Affinity等一系列技术，绕过系统对网络数据包处理的繁琐过程，提升网络性能。服务器需要开启Hugepage才能启用DPDK功能。"
            },
            "usable":{
                "1":"启用",
                "2":"禁用",
                "3":"配置中",
                "4":"启用失败",
                "5":"禁用失败"
            },
            "status":{

            },
            "dialog":{
                "start":"您确定要启用选中的网卡的DPDK功能吗？",
                "shutdown":"您确定要禁用选中的网卡的DPDK功能吗？"
            }
        },
        "nettype":{
            "dataTitles":{
                "type":"租户网络类型",
                "status":"状态",
                "range":"网卡名称",
                "opt":"操作"
            },
            "msg":{
                "0":"未启用",
                "1":"已启用",
                "2":"配置中",
                "3":"配置失败",
                "tips":""
            },
            "vlan":{
                "start":"起始VLAN",
                "end":"结束VLAN"
            },
            "vxlan":{
                "start":"起始VNI",
                "end":"结束VNI"
            },
            "error":{
                "vlan":"起始值不能大于结束值"
            },
            "dialog":{
                "start":"您确定要启用选中的网卡的DPDK功能吗？",
                "shutdown":"您确定要禁用选中的网卡的DPDK功能吗？"
            }
        },
        "shares":{
            "name":"名称",
            "size":"大小",
            "status":"状态",
            "isPublic":"可访问性",
            "shareProto":"协议",
            "createDate":"创建时间",
            "public":"公有",
            "private":"私有",
            "label":"标识",
            "location":"位置",
            "type":"类型",
            "host":"主机",
            "desc":"描述",
            "metadata":"元数据",
            "detail":"文件存储详情",
            "addRule":"设置规则",
            "allowRule":"允许访问规则",
            "newRule":"新建规则",
            "delete" :"删除规则",
            "basicsInfo":"基础信息",
            "ruleManage":"规则管理",
            "valueLimit":"元数据不能包含中文",
            "status_c":{
                "available":"可用",
                "creating":"创建中",
                "deleting":"删除中",
                "error":"错误",
                "error_deleting":"删除错误",
            },
            "ruleStatus":{
                "error":"错误",
                "active":"可用",
                "new":"新建",
            }
        },
        "swifts": {
            "true": "是",
            "false": "否",
            "yes": "是",
            "no": "否",
            "shared": "公有化",
            "exclusive": "私有化",
            "container": "桶",
            "count": "对象计数(个)",
            "size": "大小",
            "totalNum": "大小",
            "accessible": "可访问性",
            "detail": "详情",
            "containerDetail": "桶详情",
            "objectDetail": "对象详情",
            "lookDetail": "查看详情",
            "detailMessage": "详细信息",
            "containerName": "桶名",
            "createcontainerName": "新建桶",
            "name": "桶名称",
            "objectName": "对象名称",
            "fileExtension": "文件扩展名",
            "type": "类型",
            "public": "公有",
            "private": "私有",
            "modifyTime": "修改时间",
            "usedSpace": "已用空间",
            "pInputName": "请输入桶名称",
            "pInputObjName": "请输入对象名称",
            "confirmDel": "确定删除所选桶吗?",
            'confirmIsNull': "删除前请确认所选桶为空桶",
            "prepareDownload": "正在准备下载，请稍候",
            "dir": "目录",
            "object":"对象",
            "hash": "哈希值",
            "parentDir": "上级目录",
            "createVirDir": "创建虚拟目录",
            "detailPath": "文件名",
            "filePath": "文件保存路径",
            "pInputFilePath": "请输入文件保存路径名",
            "upload": "上传",
            "uploadFile": "上传文件",
            "pSelectFile": "请选择文件",
            "download": "下载",
            "downloadObj": "下载对象",
            "pInputVirDir": "请输入虚拟目录",
            "pConfirmNull": "删除前请确认所选虚拟目录中无内容",
            "confirDelDir": "确定删除所选虚拟目录或对象吗?",
            "application/directory": "虚拟目录",
            "url": "访问路径",
            "objectUserPlaceholder": "请选择对象存储设置",
            "fileName": "文件名称",
            "directoryName": "目录名称",
            "uploadSuccess": "上传完成",
            "uploadFail": "上传失败",
            "createSuccess": "创建成功",
            "createFail": "创建失败",
            "deleteSuccess": "删除容器成功",
        },
        "backupService":{
            "btn":{
                "stop" : "停止",
                "filter":"筛选",
                "backupStatus":"备份结果",
                "backupType":"备份方式",
                "cx":"查询",
                "qx":"取消",
            },
            "tip":{
                "instThreadNum": "开启后，任务运行时支持同时备份多个云主机。",
                "permanentIncrementalBackup": "开启了永久增量备份，每一次增量备份都会进行一次时间点合成，形成新的一个永久增量时间点，等效于完全备份时间点。",
                "backupAutoRetry": "开启备份自动重试后，若云主机备份失败，会在设置的重试等待时间后再次尝试发起，直到达到自动重试最大次数。注意：参数缺失，默认关闭自动备份重试",
                "flowControl":"限制备份服务器和保护对象之间数据传输的最大速度。",
                "createRecover1":"1.恢复成功后，原备份任务中的云主机需要被添加到备份任务中。",
                "createRecover2":"2.恢复成功后，仅会将原云主机的镜像数据、云硬盘数据、安全组、网络ID、MAC地址、固定IP等数据恢复到指定的时间节点。",
                "createRecover3":"3.请确认恢复源云主机ip、MAC 地址未被其他云主机占用，若占用请释放后在创建恢复任务。",
                "desc0":"请到 \"高级功能 > 备份服务 > 服务配置\" 对接备份服务后再使用此模块",
                "desc1":"请联系管理员到 \"高级功能 > 备份服务 > 服务配置\" 对接备份服务后再使用此模块"
            },
            "messageInfo":{
                "inputnum1-365":"请填入1-365之间数字",
                "inputnum2-10":"请填入2-10之间数字",
                "inputnum1-5":"请填入1-5之间数字",
                "inputnum1-30":"请填入1-30之间数字",
                "required1": "中文、 大小写字母、 数字、 “-”、 “_”、 “.”、 “@”组成，长度为3-256个字符，全局不可重复。",
                "required": "必填项",
                "inputnum1-255": "不能超过255位",
                "inputnum0-50": "中文、 大小写字母、 数字、 “-”、 “_”、 “.”、 “@”组成，长度不超过50个字符。",
                "deleteInfo":"如果不保留数据源，则会删除此任务，是否继续删除选中的内容？"
            },
            "placeholder":{
                "maxNumRetry":"请输入重试最大次数",
                "retryWaitTime":"请输入重试等待时间",
                "backupJobName":"请输入任务名称",
                "select":"请选择",
                "only":"只能选择相同时间节点下面的实例",
            },
            //变量
            "variable":{
                "every":"每",
                "year":"年",
                "month":"月",
                "days":"日",
                "day":"天",
                "week":{
                    "1":"周一",
                    "2":"周二",
                    "3":"周三",
                    "4":"周四",
                    "5":"周五",
                    "6":"周六",
                    "7":"周日",
                    "0":"周日",
                },
                "down":"未开启",
                "up":"开启",
                "16004":"虚拟机",
                "16005":"云硬盘",
                "status" :{
                    "1":"正在启动",
                    "2":"正在分析",
                    "4":"正在执行",
                    "512":"正在停止",
                    "256":"异常",
                    "32":"成功",
                    "64":"失败",
                    "128":"部分成功",
                    "129":"成功但有告警",
                    "16":"已停止",
                },
                "status_targets" :{
                    "1":"正在启动",
                    "2":"正在分析",
                    "4":"备份中",
                    "512":"正在停止",
                    "256":"异常",
                    "32":"成功",
                    "64":"失败",
                    "128":"部分成功",
                    "129":"成功但有告警",
                    "16":"已停止",
                },
                "logLevel":{
                    "1":"信息",
                    "2":"警告",
                    "3":"错误",
                    "4":"关键",
                },
                "table":{
                    "domainName":"部门",  
                    "projectName":"项目",  
                }
            },
            //备份任务
            "backupJob":{
                "status":{
                    "deleted":"已删除",
                    "soft-delete":"已删除",
                },
                "maxNumTry":"自动重试最大次数",
                "maxSeed":"最大速度为",
                "tab_job":"任务",
                "tab_monitor":"监控",
                "tab_instance":"历史记录",
                "detail":"任务详情",

                "jobName":"任务名称",
                "jobStatus":"任务状态",
                "bindInst":"关联云主机",
                "hasSches":"备份策略",
                "lastLaunchTime":"上次发起时间",
                "nextLaunchTime":"下次发起时间",
                "createDate":"创建时间",
                "strategyInfo":"策略详情",
                "concurrentBackup":"并发备份",
                "permanentIncrementalBackup":"永久增量备份",
                "backupAutoRetry":"备份自动重试",
                "flowControl":"流量控制",
                "basicInformation":"基本信息",
                "dataSource":"数据源",
                "type":"类型",
                "name":"名称",
                "delDataSource":"删除数据源",
                "backupType": {
                    "1": "完全备份",
                    "2": "增量备份"
                },
                "policyRules":{
                    "2":"天",
                    "3":"周",
                    "4":"月",
                    "5":"年",
                },
                
                "dialog":{
                    "advParam":"高级设置",
                    "perMonth":"每月",
                    "day":"日",
                    "days":"若当月无选定日期则在最后一天触发",
                    "perYears":"每年",
                    "month":"月",
                    "runTime":"执行时间",
                    "add": "新建任务策略",
                    "edit": "编辑任务策略",
                    "jobName": "任务名称",
                    "backupType": "备份方式",
                    "policyRules": "策略规则",
                    "startTime":"开始日",
                    "endTime":"结束日",
                    "speedLimitTime":"限速时间段",
                    "maxSpeed":"最大速度",
                    "instThreadNum":"并发备份数",
                    "permanentIncrementalBackup":"永久增量备份",
                    "retryWaitTime":"重试等待时间",
                    "maxNumRetry":"重试最大次数",
                    "bout":"次",
                    "minute":"分钟",
                    "message":{
                        "error1":"限速时间段不允许有交叠",
                        "error2":"开始日必须早于结束日",
                        "error3":"开始时间必须早于结束时间",
                        "error4":"输入1~1024MiB/s",
                    },
                    "addFlowControl":"新增流量控制",
                    "addInst":"关联云主机",
                    "delInst":"删除云主机",
                    "zdcj":"自动创建",
                    "yu":"于",
                },
                // 绑定实例弹出框
                "bindInstance":{
                    "instanceName": "云主机名称",
                    "status": "状态",
                    "ips": "私网IP",
                    "title": "关联云主机",
                    "unCheckInst": "未选择云主机",
                    "checkInst": "已选择云主机",
                }
            },
            //备份实例(监控和历史)
            "backupJobInstancer":{
                "instJobName":"任务名称",
                "instStatus":"状态",
                "instBackupType":"备份方式",
                "instStartTime":"开始时间",
                "instEndTime":"结束时间",
                "instUsedTime":"已运行时间",
                "progress":"已完成数据量/总数据量",
                "instSpeed":"传输速度",
                "instSendSize":"传输数据量",
                "runProgress":"执行进度",
                "runLogInfo":"执行详情",
                "instanceName":"实例名称",
                "progress_i":"进度",
                "time":"时间",
                "level":"级别",
                "logInfo":"执行信息",
            },
            //数据恢复
            "backupJobRecover":{
                "jobName":"任务名称",
                "jobStatus":"任务状态",
                "jobRunTime":"已运行时间",
                "jobCompletedData":"已完成数据量",
                "jobSpeed":"传输速度",
                "dialog":{
                    "add":"新建恢复任务",
                    "instanceName": "云主机名称",
                    "status": "状态",
                    "ips": "私网IP",
                    "backupTime": "备份时间",
                    "selectBackupJob": "选择备份任务",
                    "selectTimeNode": "选择时间节点",
                    "selectDataSource": "选择恢复源",
                }
            },
            // 数据清理
            "backupDataClean": {
                "table": {
                    "jobName": "任务名称",
                    "jobStatus": "状态",
                    "jobDesc": "备注",
                    "jobStartTime": "执行时间",
                    "success": "成功",
                    "failed": "失败",
                    "error": "异常",
                    "partSuccess": "部分成功",
                    "successAndWarning": "成功但有告警",
                    "stopped": "已停止",
                    "running": "正在执行"
                },
                "btn": {
                    "create": "新建"
                },
                "details": {
                    "title": "任务详情",
                    "timepoints": "备份数据时间节点"
                },
                "create": {
                    "title": "新建清理任务",
                    "jobName": "任务名称",
                    "inputJobName": "请输入任务名称",
                    "jobDesc": "任务备注",
                    "inputJobDesc": "请输入备注",
                    "selectBackupJob": "选择备份任务",
                    "pleaseSelectBackupJob": "请选择备份任务",
                    "selectBackupData": "选择备份数据",
                    "timepoints": "时间节点",
                    "tips": "在清理指定副本方式下，最新时间点副本无法被删除。您可以通过清理全部副本的方式删除最近时间点副本。",
                    "allSelect": "全选",
                    "currentPage": "当前列表",
                    "pleaseSelectTimepoints": "请选择备份数据时间节点！",
                    "checkJobName": "任务名称重复！"
                } 
            },
            //服务配置
            "serviceConfig":{
                "config_top_tips":"请先确认备份服务已准备完毕",
                "name":"名称",
                "pvio":"参数值输入/操作",
                "describe":"描述",
                "connection":"连接状态",
                "testConnection":"连接测试",
                "saveConfig":"保存配置",
                "deleteConfig":"删除配置",
                "deleteDisc":"您确定要删除配置内容吗？",
                "syncData":"数据同步",
                "syncStrategy":"数据同步策略",
                "dataSyncSetting":"数据同步策略设置",
                "ibfs":"执行全量数据同步间隔时间",
                "dataStatusSync":"执行备份任务数据状态同步间隔时间",
                "changePassword":"修改密码",
                "syncSuccess":"数据同步成功",
                "title":{
                    "pleaseInt":"请输入",
                    "abAddress":"AnyBackup管理节点URL",
                    "accountNumber":"AnyBackup管理员账号",
                    "accountPassword":"AnyBackup管理员密码",
                    "securityNumber":"AnyBackup安全员账号",
                    "securityPassword":"AnyBackup安全员密码",
                    "passwordDescOne":"密码有效期：剩余",
                    "passwordDescTwo":"天（有效期截止将会强制断开连接，需要修改密码重新连接。）",
                    "connectionDesc":"请先进行连接测试，连接成功后再部署服务。",
                    "pleaseChange":"请选择",
                    "passwordReturn":"密码再次重置时间",
                    "day":"天",
                    "originalPassword":"原密码",
                    "newPassword":"新密码",
                    "confirmPassword":"确认新密码",
                    "originalPasswordError":"原密码不正确",
                    "samePassword":"原密码与新密码相同",
                    "confirmPasswordError":"新密码与确认新密码不一致",
                },
                "icon":{
                    "connectionLoad":"连接中",
                    "connectionYes":"连接成功", 
                    "connectionWrong":"连接失败",
                    "stoppedWrong":"断开连接",
                },
                "time":{
                    "minute":"分钟",
                    "oneMinute":"1分钟",
                    "fiveMinute":"5分钟",
                    "tenMinute":"10分钟",
                    "twentyMinute":"20分钟",
                    "thirtyMinute":"30分钟",
                },
                "index":{
                    "accountShow":"密码有效期提醒：备份服务AnyBackup管理员密码有效期剩余",
                    "securityShow":"密码有效期提醒：备份服务AnyBackup安全员密码有效期剩余",
                    "allShow":"天，有效期截止将会强制断开连接请尽快修改密码延长有效期。",
                    "btn":"备份配置",
                    "syncStrategy":"数据正在同步中，稍后重试",
                    "syncData":"备份服务正在部署中，稍后重试",
                    "accountPassword":"管理员密码已过期,请修改密码！",
                    "securityPassword":"安全员密码已过期,请修改密码！"
                },
                "deleteConfigTitle":{
                    "deleteTitleOne":"删除服务",
                    "deleteTitleTwo":"将导致备份任务全部暂停",
                    "deleteTitleThree":"，已备份的时间点数据不会被删除。",
                }
            }
        }
    }
};
const topMenu = zh_CN.aws.menu
export {zh_CN,topMenu}
