paramSetCtrl.$inject = ['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','$routeParams']
importTemplateCtrl.$inject = ['$scope','$rootScope','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate','context','alertSrv','$routeParams']
function paramSetCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,$routeParams) {
    var self = $scope;
    self.paramModify={
        modifying:false
    };
    self.modifyBtnTitle='修改运行值';
    self.paramSubmitInValid=false;
    self.paramList=[
        {name:"auto_increment_increment",restart:0,default:"1",currentValue:"1",range:"[1-65535]"},
        {name:"autocommlt",restart:0,default:"ON",currentValue:"ON",range:"[ON|OFF]"},
    ];
    self.modifyValue=function(){
        if(self.paramModify.modifying){
           self.saveModifyParams(self.tdsqlParamForm);
        }else{
           self.paramModify.modifying = !self.paramModify.modifying;
           self.modifyBtnTitle='保存运行值';
        }
        
    };
    self.getParam=function(){
        self.paramTable = new NgTableParams({
           count: GLOBAL_CONFIG.PAGESIZE
        }, {
           counts: [],
           dataset: []
        });
        tdsqlSrv.getParamList($routeParams.clusterKey,$routeParams.id,$routeParams.instanceType).then(function(res){
             res ? self.paramData  = true : "";
             if(res&&res.data&&res.data.returnData&&res.data.returnData.config&&angular.isArray(res.data.returnData.config)){
               successFunc(res.data.returnData.config); 
             }
         });
    }

    self.interactedParam = function(field) {
        if (field) {
            return self.paramSubmitInValid || field.$dirty;
        }
    }
    function successFunc(data){
        data.forEach(function(item){
            if(item.constraint&&item.constraint.type){
                item.type=item.constraint.type;
                if(item.constraint.type=='section'){//输入框
                    //新加
                    if(item.param == 'innodb_max_dirty_pages_pct'){
                        item.value = item.value?Number(item.value):0;
                        item.value = item.value+"";
                    }
                    if(item.param == 'innodb_thread_sleep_delay' && item.value == '0'){
                        item.value = 1+"";
                    }
                    //新加结束
                   item.available=item.constraint.range.min+"-"+item.constraint.range.max;
                }else if(item.constraint.type=='enum'){//下拉列表
                   item.available=item.constraint.enum;
                   item.availableList=item.constraint.enum.split(",");
                   item.availableList.forEach(function(available){
                      let markAvailable="'"+item.value+"'";
                      if(available==item.value){
                         item.value=available;
                      }else if(available==markAvailable){
                         item.value=markAvailable;
                      }
                   });
                }else if(item.constraint.type=='string'){//文本框
                   item.available=item.constraint.str;
                }
            }else{
                //返回值异常处理为输入框
                item.type="section";
            }
        });
        self.paramsData=data;
        TableCom.init(self,'paramTable',self.paramsData,'id',10000,'paramCheckbox');
    }
    self.getParam();

    self.saveModifyParams=function(tdsqlParamForm){
         if(tdsqlParamForm.$valid){
         	if($routeParams.instanceType == '1'){
         		var options={
	              "id": $routeParams.id,
	              "config":[]
	            };
         	}else{
         		var options={
	              "groupid": $routeParams.id,
	              "config":[]
	            };
         	}
            self.paramsData.forEach(function(item){
                if(item.param!='tx_isolation'&&item.param!='long_query_time'&&item.param!='join_buffer_size'&&item.param!='group_concat_max_len'&&item.param!='slave_parallel_threads'&&item.param!='optimizer_switch'&&item.param!='innodb_page_size'&&item.param!='lower_case_table_names'&& item.param!='character_set_server'&& item.param!='binlog_format'){
                    let obj={
                        "param":item.param,
                        "value":item.value
                    };
                    options.config.push(obj);
                }
            });
            self.paramModify.modifying = !self.paramModify.modifying;
            self.modifyBtnTitle='修改运行值';
            tdsqlSrv.modifyParamsList(options,$routeParams.clusterKey,$routeParams.instanceType).then(function(){
                self.submittedParams=true;
                $timeout(function(){
                    self.getParam();
                },300);
            });
         }else{
            self.paramSubmitInValid=true;
         }
    };

    self.importTemplate=function(){
        self.importTemplateModal=$uibModal.open({
            animation: true,
            templateUrl: "importTemplate.html",
            controller: "importTemplateCtrl",
            resolve: {
                context: function() {
                    return self;
                }
            }
        });
    };
}
function importTemplateCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate,context,alertSrv,$routeParams) {
    var self = $scope; 
    self.selected_file="";
    self.fileNameChanged=function(){
        self.fileCheck=false;
        self.selected_file = document.getElementById("upload").value;
        var dom = document.getElementById("upload");
        var file = dom.files[0];
        let fileSize = 0;
         var fileType ="";
        file ? fileSize = file.size:self.selected_file=""; 
        file ? fileType=file.name.substr(-5,5):fileType=".xlsx";
        if(fileType==".xlsx"){
            self.fileCheck=false;
        }else{
            self.fileCheck=true; 
        }
        self.$apply();
　　}
    self.confirm = function(filed){
        if(filed.$valid&&!self.fileCheck){
            var scope = self;
            var form = document.forms.namedItem("templateForm");
            var oData = new FormData(form);
            var oReq = new XMLHttpRequest();
            var importsuccess=$translate.instant("aws.license.importsuccess");
            var importerror='导入文件失败';
            oData.append("clusterkey",$routeParams.clusterKey);
     		oData.append("groupid",$routeParams.id);
        	oData.append("id",$routeParams.id);
        	oData.append("type",$routeParams.instanceType);
            
            oReq.onerror = function(e) { 
                if(e.type=="error"){
                    alertSrv.set("",importerror , "error",5000);        
                }
            };
            oReq.onload = function(){
               var responseObj=JSON.parse(oReq.responseText);
               if(responseObj){
                    if( responseObj.code==0){
                        context.getParam();        
                    }else{
                        alertSrv.set("",$translate.instant("aws.statusCode."+responseObj.code) , "error",5000);
                    }
               }
            }
            oReq.open("POST", window.GLOBALCONFIG.APIHOST.BASE+"/v1/tdsql/batchmodifyconfig", true);
            let auth_token = localStorage.$AUTH_TOKEN;
            oReq.setRequestHeader("X-Auth-Token",auth_token); 
            oReq.send(oData);
            context.importTemplateModal.close();
        }else{
            self.submitInValid = true;
        }
    };
}
export{paramSetCtrl,importTemplateCtrl}