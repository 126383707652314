
backupJobManageCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","$location","$routeParams","$rootScope"]
function backupJobManageCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,$location,$routeParams,rootScope) {
	var self = $scope;
    //详情页面 任务
    self.$on("getDetail", function(event, value) {
        self.detailData = {
            backupFlag:$routeParams.tab
        };
    });
	//关闭详情页
	self.closeDetailModal=function(){
        // 置空。会重新刷新指令页面
        if(self.detailData.backupFlag == "job") {
            rootScope.$broadcast("backupJob", "reload");
        }
        self.detailData.backupFlag = "";
        $location.url('/advanced/backup-job');
    };
};
export {backupJobManageCtrl}