import './ObjectsSrv'
var ObjectsModule=angular.module("ObjectsModule", ['ngTable','ngAnimate','ui.bootstrap','ObjectsSrv']);
ObjectsModule.controller("ObjectsCtrl",function($scope, $location, $rootScope, $translate,NgTableParams,ObjectsSrv,$uibModal,$routeParams,checkedSrv,newCheckedSrv){
  var self=$scope;
  self.headers={
    "name":$translate.instant("aws.swifts.fileName"),
    "type":$translate.instant("aws.swifts.type"),
    "totalNum":$translate.instant("aws.swifts.totalNum"),
    "detail":$translate.instant("aws.swifts.detail"),
  }

  $scope.$on('getDetail', function(event, value) {
    var path = $routeParams.container;
    ObjectsSrv.getSwiftMeta(path + value).then(function(result){
      $scope.objectDetail={};
      if(result.data){
        //$scope.objectDetail=angular.fromJson(result.data);
       
        if ( result.data.bytes < 1024){
            result.data.bytes = result.data.bytes + " B";
        }
        else if ( result.data.bytes < 1048576 )
        {
           result.data.bytes = Math.ceil (result.data.bytes /1024) + " KB";
        }
        else if ( result.data.bytes < 1073741824 ){
           result.data.bytes = Math.ceil (result.data.bytes /1048576) + " MB";
        }
        else {
          var temp = Math.ceil ((result.data.bytes *10) /1073741824) ;
          result.data.bytes = temp / 10 + " GB";
        }
        $scope.objectDetail=result.data;
        if ( $scope.objectDetail.directory ) {
          $scope.objectDetail.sizeInBytes = "";
        }
        $scope.objectDetail.name = value;
        $scope.objectDetail.directory = $translate.instant('aws.swifts.' + $scope.objectDetail.directory);

      }
    });
  });

  self.containerName = "";
  self.curObjPathName = "";

  function getObjUrl(objUrl){
    var brul = objUrl.split("/");
    brul.pop();
    if(brul.length==2){
      self.backurl = "#/cvm/objectStorage/objects/"+ brul[0] +"/";
      self.backurl_data ="/cvm/objectStorage/objects/"+ brul[0] +"/";　
    }else if(brul.length>2){
      brul.pop()
      self.backurl = "#/cvm/objectStorage/objects/"+ brul.join("/") + "/";
      self.backurl_data="/cvm/objectStorage/objects/"+ brul.join("/") + "/";
    }else{
      self.backurl = "#/cvm/objectStorage";
      self.backurl_data="/cvm/objectStorage";
    }
  }

  self.getListObj = function(){
    if($routeParams.container){
      self.objUrl = $routeParams.container;
      getObjUrl(self.objUrl);
      ObjectsSrv.listObject($routeParams.container).then(function(result){
        if(result && result.data) {
          var objData = angular.fromJson(result.data);
          objData.map(function(item){
            if ( !item.subdir ) {
              if ( item.bytes < 1024){
                item.bytes = item.bytes + " B";
              }
              else if ( item.bytes < 1048576 )
              {
                item.bytes = Math.ceil (item.bytes /1024) + " KB";
              }
              else if ( item.bytes < 1073741824 ){
                item.bytes = Math.ceil (item.bytes /1048576) + " MB";
              }
              else {
                var temp = Math.ceil ((item.bytes *10) /1073741824) ;
                item.bytes = temp / 10 + " GB";
              }
              item.name = item.name.split("/").pop();
              item.type = $translate.instant("aws.swifts.object");
            } else if ( item.name != null ) {
              item.name = item.name.split("/");
              item.name.pop();
              item.name = item.name.pop() + "/";
              item.sizeInBytes = "";
              item.type = $translate.instant("aws.swifts.object");
            } else {
              item.name = item.subdir.split("/").slice(-2,-1)[0]+"/";
              item.content_type = "application/directory";
              item.sizeInBytes = "";
              item.type = $translate.instant("aws.swifts.dir");
            }
          })
          successFunc(objData);
        }
      });
    }
  }

  function successFunc(data) {
    self.clusterTableData = data;
    self.clusterListData = data;
    self.applyGlobalSearch = function() {
        var term = self.globalSearchTerm;
        self.centertableParams.filter({ searchTerm: term });
    };
    self.clusterSearchTerm(({tableData:self.clusterListData,titleData:self.titleData}));
    self.centertableParams = new NgTableParams({ count: 10 }, { counts: [], dataset: data });
    var tableId = "name";
    newCheckedSrv.checkDo(self, data, tableId,"centertableParams"); 
    self.$watch(function() {
        return self.checkedItemscentertableParams;
    }, function(values) {
      if ( values.length != 1 ) {
        self.isDisabled = true;
      } else if ( values[0].directory ) {
        self.isDisabled = true;
      } else if (values[0].subdir) {
        self.isDisabled = true;
      } else {
        self.isDisabled = false;
        self.singleObjectPath = GLOBALCONFIG.APIHOST + '/v1/objectstorage/objectdownlaod/' + $scope.objUrl + '/' + values[0].name;
      }
     });
    }
    self.siteTitle="directoryData";
    if(sessionStorage["directoryData"]){
       self.titleData=JSON.parse(sessionStorage["directoryData"]);
    }else{
      self.titleData=[
        {name:'swifts.fileName',value:true,disable:true,search:'name'},
        {name:'swifts.type',value:true,disable:false,search:'type'},
        {name:'swifts.totalNum',value:true,disable:false},
        {name:'swifts.detail',value:true,disable:true}
      ];
    }
    self.clusterSearchTerm=function(obj){
      var tableData = obj.tableData;
      var titleData = obj.titleData;
      if(tableData && tableData.length){
        tableData.map(function(item){
          item.searchTerm="";
          titleData.forEach(function(showTitle){
            if(showTitle.value){
              item.searchTerm+=item[showTitle.search]+"\b";
            }
          });
        }); 
      }
  };
  $scope.refreshObject = function(){
    self.getListObj();
  }
  $scope.refresh = function(){
    self.getListObj();
  }

  $scope.create = function() {
    var scope = $rootScope.$new();
    var modalObj = $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'createModel.html',
      scope:scope,
    });
    return modalObj.result.then(function(obj) {
        ObjectsSrv.createSwiftPesudePath(self.objUrl,obj).then(function(res) {
           self.getListObj();
        })
    });
  };

  $scope.delete = function() {
    var content ={
      target:"delSwiftObject",
      msg:"<span>"+$translate.instant("aws.swifts.confirDelDir")+"</span>"
 //         + "<span>"+$translate.instant("aws.swifts.pConfirmNull")+"</span>"
    };
    self.$emit("delete",content);
  };
  self.$on("delSwiftObject",function(){
      for ( var i = 0; i < self.checkedItems.length; i++ ) {
        ObjectsSrv.deleteSwiftObject(self.objUrl,self.checkedItems[i].name).then(function(res){
          self.getListObj();
        });
      }
  });

  $scope.upload = function() {
    $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'uploadModel.html',
      controller: 'ObjectUploadModelCtrl',
      backdrop:'static',
      scope:$scope,
    });
  };

  $scope.download = function() {
    ObjectsSrv.downloadSwiftObject($scope.objUrl, $scope.checkedItems[0].name).then(function(result){
        if ( result.status == 0 ) {
          var a = document.getElementById('downloadConfirm');
          //a.download = result.data;
          //a.download = "down";
          var fileName = $scope.checkedItems[0].name;
         /* while (true){
            if(fileName.indexOf(".") != -1){
             fileName = fileName.replace(".","point");
            }else {
              break;
            }
          }*/
          a.href = result.data + "&filePath=" + $scope.objUrl + "&fileName=" + fileName;
          //a.download = $scope.checkedItems[0].name;
          a.click();
        }
    });
  };
  self.getListObj();
})
.controller('ObjectUploadModelCtrl', function ($scope, $rootScope, $translate, ObjectsSrv, $uibModalInstance){
  $scope.actionUrl = GLOBALCONFIG.APIHOST.RESOURCE + "/v1/objectstorage/object/" + $scope.containerName + $scope.objUrl;
  $scope.showContent = function($fileContent){
    $scope.objectData = $fileContent;
  };
  $scope.ok = function () {
    $scope.getListObj();
    // 弹泡:成功
    $rootScope.$broadcast("alert-success",$translate.instant('aws.swifts.uploadSuccess'));
    $uibModalInstance.close();
  };

  $scope.error = function() {
    // 弹泡:失败
    $scope.getListObj();
    $rootScope.$broadcast("alert-error",$translate.instant('aws.swifts.uploadFail'));
    $uibModalInstance.close();
  }
  $scope.file={
    name:"",
    selectedApp:"",
    singletonApp:""
  };
  $scope.cancel = function () {
    $uibModalInstance.close();
    $scope.continueUpload = false;
  };
  $scope.canupl = true;
  $scope.oks = function () {
    $scope.canupl = false;
    var appFile = document.getElementById("uploadApp").files[0];
    var fileChooser  = document.getElementById('uploadApp');
    $scope.continueUpload = true;
    //判断小于多少进行分片上传 
    if(appFile.size < 524288000){     
      //普通上传
      var form = document.forms.namedItem("createForm");
      var importData = new FormData();
      var bo = {
        objectPathName:appFile.name,
        bucketName:$scope.objUrl
      }
      var js =  JSON.stringify(bo);
      var mailData=JSON.parse(js);
      importData.append("bucketName",$scope.objUrl);
      importData.append("objectPathName",$scope.file.name);
      importData.append("bo",mailData);
      importData.append("filename",appFile);
      var oReq = new XMLHttpRequest();
      oReq.open("POST", $scope.actionUrl + 'u?storageId='+$rootScope.storageId, true);
      let auth_token = localStorage.$AUTH_TOKEN;
      oReq.setRequestHeader("X-Auth-Token",auth_token);
      oReq.send(importData);
      oReq.onerror = function(e) {
        $rootScope.$apply(function() {
            if(e.type=="error"){
              $rootScope.$broadcast("alert-error",$translate.instant('aws.swifts.uploadFail'));
              $uibModalInstance.close();
            }
        })
      };
      oReq.onload = function(){
        var responseObj=JSON.parse(oReq.responseText);
        if(responseObj){
          $rootScope.$apply(function() {
              if(responseObj.code == 0){
                $rootScope.$broadcast("alert-success",$translate.instant('aws.swifts.uploadSuccess'));
              }else if(responseObj.code != '00010105'){
                $rootScope.$broadcast("alert-error", $translate.instant('aws.statusCode.' + responseObj.code));
              }
          })
        }
        $scope.getListObj();
        $uibModalInstance.close();
      };
    }else{
        var file = fileChooser.files[0];
        if (file) {
            //分片上传到java begin
            var start = 0;
            var totalPieces;
            var end;
            var index = 0;
            var filesize = file.size;
            var filename = file.name;
            var partNum = 0;
            var bytesPerPiece = 1024 * 1024 * 16; // 每个文件切片大小定为16MB 
            var endPerpieceSize = 0;
            //如果大于 5 * 1000 = 5000MB  就按1000片来切割 5242880000
            if(file.size > 5242880000){
              //判断分片的大小取整数，最后一片为余数
              // bytesPerPiece =  parseInt(file.size/999);
              if(file.size - (bytesPerPiece * 999) > 0){
                endPerpieceSize = file.size - (bytesPerPiece * 999);
                //计算文件切片总数
                totalPieces = Math.ceil(filesize / bytesPerPiece);
                totalPieces = totalPieces + 1;
              }else{
                totalPieces = Math.ceil(filesize / bytesPerPiece); 
              }
              
            }else{
              //计算文件切片总数
              totalPieces = Math.ceil(filesize / bytesPerPiece);
            }
            var bos = {
              objectPathName:$scope.file.name,
              bucketName:$scope.objUrl
            }
            var isbbba = 0;
            ObjectsSrv.uploadInit(bos,$scope.objUrl,$scope.file.name).then(function(res) {
              if(res){
                uploads(res,file,filename,partNum,totalPieces,start,end,bytesPerPiece,filesize,isbbba,null,endPerpieceSize);
              }else{
                $scope.canupl = true;
              }
            })
            //分片上传到java end    
        }else{
          $scope.canupl = true;
        }
    };
  }
  //每次一次分片请求一次
  function uploads(res,file,filename,partNum,totalPieces,start,end,bytesPerPiece,filesize,isbbba,partEgg,endPerpieceSize){
                
                if(endPerpieceSize!=0){
                  //执行第1000个切片的时候执行 最后一个切片大小 endPerpieceSize
                  if(partNum == 999){
                    end = start + endPerpieceSize;
                  }else{
                    end = start + bytesPerPiece;
                    if(end > filesize) {
                      end = filesize;
                    }
                  }
                }else{
                  end = start + bytesPerPiece;
                  if(end > filesize) {
                    end = filesize;
                  }
                }
                var chunk = file.slice(start,end);//切割文件    
                var formData = new FormData();
                formData.append("file", chunk, filename);
                var bo = {
                  objectPathName:$scope.file.name,
                  bucketName:$scope.objUrl,
                  uploadId:res.data,
                  partNumber:partNum+1,
                  partETag:partEgg
                }
                partNum =  partNum+1;
                var oReq = new XMLHttpRequest();
                oReq.open("POST", GLOBALCONFIG.APIHOST.RESOURCE + "/v1/objectstorage" + '/object/multipart/upload?storageId='+$rootScope.storageId, true);
                let auth_token = localStorage.$AUTH_TOKEN;
                oReq.setRequestHeader("X-Auth-Token",auth_token);
                var importData = new FormData();
                 var js =  JSON.stringify(bo);
                // var mailData=JSON.parse(js);
                importData.append("bo",js);
                importData.append("filename", chunk, filename);
                oReq.send(importData);
                oReq.onerror = function(e) {
                  $rootScope.$apply(function() {
                      if(e.type=="error"){
                        $rootScope.$broadcast("alert-error",$translate.instant('aws.swifts.uploadFail'));
                      }
                      $scope.getListObj();
                      $uibModalInstance.close();
                  })
                };
                oReq.onload = function(){
                  var responseObj=JSON.parse(oReq.responseText);
                  if(responseObj){
                    if(responseObj.code != '0'){
                      if(responseObj.code != '00010105'){
                        $rootScope.$broadcast("alert-error",$translate.instant("aws.statusCode."+ responseObj.code));
                      }
                      $scope.getListObj();
                      $uibModalInstance.close();
                      return;
                    }
                    var partETagObj=JSON.parse(responseObj.data.data);
                    isbbba = isbbba+1;
                    var eagg = [];
                    var bos = {
                      objectPathName:$scope.file.name,
                      bucketName:$scope.objUrl,
                      uploadId:res.data,
                      partNumber:partNum+1,
                      partETag:null
                    }
                    if(partETagObj && partETagObj.partETag !=null){
                      bos = {
                        objectPathName:$scope.file.name,
                        bucketName:$scope.objUrl,
                        uploadId:res.data,
                        partNumber:partNum+1,
                        partETag:partETagObj.partETag
                      }
                      eagg = partETagObj.partETag
                    }

                    //如果相等 说明最后一个也上传完了 调用合并接口
                    if($scope.continueUpload){
                      if(partNum == totalPieces){
                          var oReqall = new XMLHttpRequest();
                          oReqall.open("POST", GLOBALCONFIG.APIHOST.RESOURCE + "/v1/objectstorage" + '/object/multipart/upload/ALL?storageId='+$rootScope.storageId, true);
                          let auth_token = localStorage.$AUTH_TOKEN;
                          oReqall.setRequestHeader("X-Auth-Token",auth_token);
                          var importDataall = new FormData();
                          var jsb =  JSON.stringify(bos);
                          importDataall.append("bo",jsb);
                          oReqall.send(importDataall);
                          oReqall.onerror = function(e) {
                            $rootScope.$apply(function() {
                                if(e.type=="error"){
                                  $rootScope.$broadcast("alert-error",$translate.instant('aws.swifts.uploadFail'));
                                }
                                $scope.getListObj();
                                $uibModalInstance.close();
                            })
                          };
                          oReqall.onload = function(){
                            $scope.canupl = true;
                            var a=JSON.parse(oReqall.responseText);
                            if(a){
                              if(a.code == '0'){
                                $rootScope.$broadcast("alert-success",$translate.instant('aws.swifts.uploadSuccess'));
                              }else if(a.code != '00010105'){
                                $rootScope.$broadcast("alert-error",$translate.instant('aws.statusCode.' + a.code));
                              }
                            }
                            $scope.getListObj();
                            $uibModalInstance.close();
                          }
                      }else{
                        uploads(res,file,filename,partNum,totalPieces,start,end,bytesPerPiece,filesize,isbbba,eagg,endPerpieceSize);
                      }
                    }
                  }   
                }
                start = end;
  }
})
.directive('myDownload', function ($compile, ObjectsSrv) {
    return {
        restrict:'E',
        scope:{ getUrlData:'&getData'},
        link:function (scope, elm, attrs) {
            var url = URL.createObjectURL(scope.getUrlData());
            elm.append($compile(
                '<a class="btn btn-primary" id="myDownloadBtn" download="backup.json"' +
                    'href="' + url + '">' +
                    '确认' +
                    '</a>'
            )(scope));
        }
    };
})