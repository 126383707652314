loading.$inject = [];
function loading () {
    return {
        restrict: "E",
        scope: {},
        templateUrl:'js/system/billingManagement/tmpl/loading.html',
        link:function(scope, elem, attr,ngModel){

        }
    }
}

export { loading }