var objectStorageUserSrvModule = angular.module("objectStorageUserSrvModule",[]);

objectStorageUserSrvModule.service("objectStorageUserSrv",["$http",function($http){
    return{
        getUserList:function(){
            return $http({
                method:"GET",
                url: "/awstack-resource/v1/rgw/user"
            });
        },
        addItem: function(options) {
            return $http({
                method: "POST",
                url: "/awstack-resource/v1/rgw/user",
                data: options
            });
        },
        editItem: function(options) {
            return $http({
                method: "PUT",
                url: "/awstack-resource/v1/rgw/user/quota",
                data: options
            });
        },
        deleteItem: function(options) {
            return $http({
                method: "DELETE",
                url: "/awstack-resource/v1/rgw/user",
                params: options
            });
        },
        getDetailData:function(id, params){
            return $http({
                method:"GET",
                url: "/awstack-resource/v1/rgw/user/" + id,
                params
            });
        },
        createKey: function(id) {
            return $http({
                method: "POST",
                url: "/awstack-resource/v1/rgw/user/" + id +"/key"
            });
        },
        deleteKey: function(id, key) {
            return $http({
                method: "DELETE",
                url: "/awstack-resource/v1/rgw/user/" + id + "/key/" + key,
            });
        },
    };
}]);