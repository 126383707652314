import "./ladpSrv";

var ldapModule = angular.module("ladpModule",["ngMessages","ladpSrvModule"]);
ldapModule.controller('ladpCtrl',['$scope','ladpSrv','alertSrv','$translate','$route',
	function($scope,ladpSrv,alertSrv,$translate,$route){
		$scope.ladp={
			providerUrl:"",
			securityPrincipal:"",
			securityCredentials:"",
			enable: false,
			securityOrgUnit:""
		};
		$scope.canSubmit = false;
		
        //初始化获取数据
		function init(){
            ladpSrv.getLadpData().then(function(res){
				if(res && res.data&&angular.isObject(res.data)){
					if(res.data.id){
	                    $scope.ladp.id=res.data.id;
					}
					$scope.ladp.providerUrl=res.data.providerUrl;
					$scope.ladp.securityPrincipal=res.data.securityPrincipal;
					$scope.ladp.securityCredentials=res.data.securityCredentials;
					$scope.ladp.enable=res.data.enable;
					$scope.ladp.securityOrgUnit=res.data.securityOrgUnit;
				}
			});
		}
		init();
		$scope.saveLadp= function(ladp,LADPForm){
			$scope.submitValid =false;
			if(LADPForm.$valid){
				let tips = $scope.ladp.enable?$translate.instant("aws.system.adServer.tips9"):$translate.instant("aws.system.adServer.tips8");
				var content = {
					target: "enable-system-adserver",
					msg: "<span>" + tips + "</span>",
				};
				$scope.$emit("delete", content);
			}else{
				$scope.submitValid =true;
			}
		};
		$scope.$on("enable-system-adserver", function (e) {
			saveData();
		});
		function saveData() {
			$scope.canSubmit = true;
			ladpSrv.addData($scope.ladp).then(function(res){
				//res.data=false res.data=true
				if(res){
					if(res.data){
						alertSrv.set("", $translate.instant("aws.system.adServer.tips6"), "success", 5000);
						$route.reload();
					}else {
						//设置失败，进行提示
						alertSrv.set("",$translate.instant("aws.system.adServer.tips7"), "error", 5000);
					}
				}

			}).finally(function(){
				$scope.canSubmit=false;
			});
		};
	}
]);