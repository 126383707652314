
var storageManagementSrvModule = angular.module("storageManagementSrvModule", [])
 storageManagementSrvModule.service("storageManagementSrv", ["$http", function($http){
    var static_user_url = "/awstack-user/v1";
    return {
        /*获取ceph存储池列表*/
        getCephPool: function(params) {
            return $http({
                method: "GET",
                url: static_user_url + "/storage/pool",
                params:params
            });
        },
        getRgwType:function(){
            return $http({
                method:"GET",
                url:"awstack-user/v1/params?parentId=1004"
            })
        },
        /*新增存储池*/
        addCephPool: function(params) {
            return $http({
                method: "POST",
                url: static_user_url + "/storage/pool",
                data:params
            });
        },
        /*删除存储池*/
        deleteCephPool: function(params) {
            return $http({
                method: "put",
                url: static_user_url + "/storage/pool",
                data:params
            });
        },
        editOutCephUrl: function(params) {
            return $http({
                method: "post",
                url: static_user_url + "/ceph/url",
                data:params
            });
        },
        changeMonitorStatus: function(params) {
            return $http({
                method: "put",
                url: static_user_url + "/ceph/status",
                params:params
            });
        },
        getStorageTableData: function(option) {
            return $http({
                method: "GET",
                url: static_user_url + "/storage"
            });
        },
        getNodeDisk: function(option) {
            return $http({
                method: "GET",
                url: static_user_url + "/region/"+localStorage.regionKey+"/fetch/fc",
                params:option
            });
        },
        getStorageDetail: function(id) {
            return $http({
                method: "GET",
                url: static_user_url + "/storage/detail/" + id
            });
        },
        initVolumeType: function(headers) {
            return $http({
                method: "POST",
                url: "/awstack-resource/v1/volume_type_initialize_all_in",
                data: {},
                headers:headers
            });
        },
        checkCephUrl: function(params) {
            return $http({
                method: "POST",
                url: "/awstack-user/v1/ip/check",
                data: params
            });
        },
        
        createStorageData: function(option,headers) {
            return $http({
                method: "POST",
                url: static_user_url + "/storage",
                data: option,
                headers:headers
            });
        },
        editStorageData: function(option) {
            return $http({
                method: "PUT",
                url: static_user_url + "/storage",
                data: option
            });
        },
        deleteStorageData: function(enterpriseUid, regionUid, option) {
            return $http({
                method: "PUT",
                url: static_user_url + "/enterprises/" + enterpriseUid + "/region/" + regionUid + "/storage",
                data: option
            });
        },
        getStoragePoolList: function() {
            return $http({
                method: "GET",
                url: static_user_url + "/storage/" + localStorage.regionKey + "/pool"
            });
        },
        checkNfsAddr: function(option) {
            return $http({
                method: "GET",
                url: static_user_url + "/nfs/sharedir",
                params: option
            });
        },
        checkManageAddr: function(option) {
            return $http({
                method: "GET",
                url: static_user_url + "/storage/checkout/Ips",
                params: option
            });
        },
        restartIscsi: function(option,headers) {
            return $http({
                method: "PUT",
                url: static_user_url + "/region/" + localStorage.regionKey + "/restart/iscsi",
                data: option,
                headers:headers
            });
        },
        discoveryIscsi: function(option) {
            return $http({
                method: "GET",
                url: static_user_url + "/region/" + localStorage.regionKey + "/discovery/iscsi",
                params: option
            });
        },
        loginIscsi: function(regionKey, option,headers) {
            return $http({
                method: "POST",
                url: static_user_url + "/region/" + regionKey + "/fetch/iscsi",
                data: option,
                headers:headers
            });
        },
        getAllNode: function(regionUides) {
            return $http({
                method: "get",
                url: "awstack-user/v1/enterprises/"+localStorage.enterpriseUid+"/regions/"+regionUides+"/nodes/list"
            });
        },
        configStorageData: function(option,headers) {
            return $http({
                method: "POST",
                url: static_user_url + "/network/config/storage",
                data:option,
                headers:headers
            });
        },
        checkStorageData: function(option,headers) {
            return $http({
                method: "POST",
                url: static_user_url + "/network/check/storage",
                data:option,
                headers:headers
            });
        },
        checkResultData: function(params) {
            return $http({
                method: "GET",
                url: static_user_url + "/network/check/result/storage",
                params: params
            });
        },
        getStorageIp: function(regionKey){
            return $http({
                method:"GET",
                url: "awstack-user/v1/region/"+regionKey+"/iprange",
            });
        },
        getStorage: function(headers){
            return $http({
                method: "GET",
                url: static_user_url + "/storage/list"
            })
        },
        getAvailableDisks:function(regionKey,obj){
            let params = obj||{};
            return $http({
                method:"GET",
                url: "awstack-user/v1/region/"+regionKey+"/disk/ceph",
                params:params
            });
        },
        isHealthAction: function (options,regionUid) {
            return $http({
                method: "GET",
                url: "awstack-user/v1/enterprises/" + localStorage.enterpriseUid+"/regions/"+regionUid+"/nodes/health",
                params: options
            });
        },
        getNodeConfig: function (options,regionUid) {
            return $http({
                method: "GET",
                url: "/awstack-user/v1/region/disk/allocation",
                params: options
            });
        },
        addCspStorage: function (data) {
            return $http({
                method: "POST",
                url: "awstack-user/v2/csp/storage",
                data: data
            });
        },
        // 创建本地磁盘
        addLocalStorage: function (data) {
            return $http({
                method: "POST",
                url: "awstack-user/v1/storage",
                data: data
            })
        },
        // 获取正常节点列表
        getNormaNodeList(enterpriseUid,regionUid) {
            return $http({
                method: "GET",
                url: `awstack-user/v1/enterprises/${enterpriseUid}/regions/${regionUid}/nodes/normalList`,
            })
        },
        // 通过节点名称查询该节点下的硬盘
        getNodeByName(params) {
            return $http({
                method: "GET",
                url: "awstack-manage/v2/disk",
                params: params
            })
        },
        // 获取本地磁盘详情列表
        getDetailLocalDiskList(params) {
            return $http({
                method: "GET",
                url: "awstack-manage/v2/disk/get",
                params: params
            })
        },
        // 加入本地磁盘
        joinInLocalDisk(data) {
            return $http({
                method: "POST",
                url: "awstack-manage/v2/disk/create",
                data: data
            })
        },
        // 移除本地磁盘
        removeLocalDisk(uuids) {
            return $http({
                method: "DELETE",
                url: `awstack-manage/v2/disk?uuids=${uuids}`,
            })
        },
    };
}]);
