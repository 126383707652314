import "ip";
import "./functionSrv";



var functionModule = angular.module("functionModule",["ngTable", "ngAnimate", "ui.bootstrap","ngMessages","accessFunctionSrvModule","app"]);

functionModule.controller("pluginCtrl",["$scope","$rootScope","$timeout","$location","$translate","accessSrv","NgTableParams","newCheckedSrv", "$uibModal","$filter","utilTool",
    function($scope,$rootScope,$timeout,$location,$translate,accessSrv,NgTableParams,newCheckedSrv,$uibModal,filter,utilTool){
        var self = $scope;
        self.delDisable = true;
        self.conCanAdd = true;
        self.phyCanAdd = true;
        var listPhy_data = [];
        var listContent_data = []; 
		self.showpaastip=false;

        let conPhy = [
            {name:$translate.instant('aws.function.functiontype.con'),type:"1",text:$translate.instant('aws.function.functiontype.con')},
            {name:'phy',type:"2",text:$translate.instant('aws.function.functiontype.phy')}
        ]

		//检查是否存在paas网络安全组	
		function paastip(){
			accessSrv.checkNetwork().then(function(res){
				//true为已存在paas网络，隐藏提示
				if(res&&res.data){
					self.showpaastip=false;
				}else{
					self.showpaastip=true;
				}
			})
		}
		paastip();
        self.buildBridge = {
            bridgeModal:null,
            tips:"创建网桥大约耗时2分钟，请勿进行其他操作！",
            show:false
        }
        function showBuilding(){
            self.buildBridge = {
                bridgeModal:null,
                tips:"创建网桥大约耗时2分钟，请勿进行其他操作！",
                show:false
            }
            self.buildBridge.bridgeModal = $uibModal.open({
                animation: true,
                templateUrl: "buildingBridge.html",
                scope:self                
            })
        }

        self.jumpToNet = function(){
        	//1.检查是否已创建网桥
        	accessSrv.checkBridgeNetwork().then(function(res){
        		//1.接口返回已创建  2.接收到websocket网桥创建成功的消息  'create.paas.network.bridge.success' 
        		if(res&&res.data){
        			//1.创建安全组
		            //2.创建成功进行跳转  
		            $rootScope.pluginPaasNet = true;
		            $location.path('/cvm/networks').replace()
        		}
        	})
        }
        self.$on('paasNetBright',function(e,v){
            if(v=='create.paas.network.bridge.success'){
                if(self.buildBridge.bridgeModal){
                    self.buildBridge.bridgeModal.close();
                }
                $rootScope.pluginPaasNet = true;
                $location.path('/cvm/networks').replace();
            }
            if(v=='create.paas.network.bridge.ing'){
                showBuilding();
            }
            if(v=='create.paas.network.bridge.fail'){
                self.buildBridge.tips = "创建网桥失败！";
                self.buildBridge.show = true;
            }
        })

        self.headers = {
            'name':$translate.instant('aws.function.header.name'),
            'progress':$translate.instant('aws.function.header.progress'),
            'status':$translate.instant('aws.function.header.status'),
            'addtime':$translate.instant('aws.function.header.addtime'),
            'handle':$translate.instant('aws.function.header.handle')
        }
        let paasServiceTypes = [
            {name:"TDSQL分布式数据库",type:"TDSQL",authId:10},
            {name:"CTSDB时序数据库",type:"CTSDB",authId:11},
            {name:"TSF",type:"TSF",authId:21},
            {name:"云镜",type:"CloudSecurity",authId:13},
            {name:"Tbase海量事务处理平台",type:"TBase",authId:14},
            {name:"织云",type:"COC",authId:15},
            {name:"TBDS大数据套件",type:"TBDS",authId:12},
            {name:"CMQ",type:"CMQ",authId:23},
            {name:"MaaS物理机管理",type:"maas",authId:24},
           // {name:"API网关",type:"APIGateway",authId:},
           // {name:"蓝鲸",type:"BlueKing",authId:},
            {name:"TKE企业版",type:"Alauda",authId:16},
            // {name:"CSP存储",type:"CSP",authId:25},
            {name:"天眼云镜",type:"SkyCloudSecurity",authId:17},
            {name:"云安全",type:"sec",authId:28},
            {name:"TCS",type:"TCS_API_KEY",authId:27},
            {name:"御见",type:"yujian",authId:29},
            {name:"御界",type:"yujie",authId:31},
            {name:"天幕",type:"tianmu",authId:30},
            {name:"waf",type:"waf",authId:32},
            {name:"堡垒机",type:"fm",authId:36 },
            {name:"下一代防火墙NGFW",type:"ngfw",authId:35},
        ];
        paasServiceTypes.forEach(item=>{
            item.text = $translate.instant('aws.function.paas.'+item.type);
        })
        // if(localStorage.cephService == 'ceph'){
        //     paasServiceTypes.push({name:"对象存储",type:"RGW",authId:20});
        // }
        /*功能类型*/
        var netCidr = {}
        accessSrv.getCluster().then(function(res){
            if(res&&res.data){
                netCidr = JSON.parse(res.data);
            }
        })

        self.pluginData={
            type:'con'
        }
        self.checkedItems = {
            item:[]
        }

        function setHanle(install,clean){
            self.canHandle = {
                install:install,
                clean:clean
            }
        }
        setHanle(true,true)

        initContentTable()
        self.globalSearchTerm = {};
        self.applyGlobalSearch = function() {
            var term = self.globalSearchTerm.item;
            self.tableContentParams.filter({ searchTerm: term });
        };

        /*容器*/
        var contentListData = []
        function initContentTable(){
            accessSrv.getContentList().then(function(result){
                result?self.contentLoadData = true:"";
                if(result && result.data){
                    successConFunc(result.data)
                }
            });
        }

        function successConFunc(condata,isWs){
            contentListData = condata;
            let paasList = ["TDSQL","CTSDB","TSF","CloudSecurity","TBase","COC","TBDS",
                "CMQ","APIGateway","BlueKing","Alauda","SkyCloudSecurity","CSP"
            ];
            let tsafeList = ["yujie","tianmu","waf","yujian","sec","fm","ngfw"];
            var supportPaas = {
                "Resource":{isLinked:true},
                "PhysicalResource":{isLinked:true},
                "K8s":{isLinked:true},
                "Monitoring":{isLinked:true},
                "List":{isLinked:true},
                "Log":{isLinked:true},
                "User":{isLinked:true},
                "System":{isLinked:true},
                "AdvFunction":{isLinked:true}
            }
            condata.forEach((item)=>{
                let supportList = [...paasList,...tsafeList];
                if(supportList.indexOf(item.pluginName)>-1){
                    var paasName = item.pluginName;
                    var supportPaasData = JSON.parse(item.meta);
                    supportPaas[paasName] = supportPaasData;
                    supportPaas[paasName].isLinked = item.pluginStatus==0?true:false;
                }
            })
            localStorage.supportPaas = JSON.stringify(supportPaas);

            let tsafeLinked = tsafeList.filter(item=>supportPaas[item]);
            if(tsafeLinked.length==0){
                utilTool.updateSupportOtherClouds("Tsafe_API_KEY","del");
            }else{
                utilTool.updateSupportOtherClouds("Tsafe_API_KEY","add");
            }

            listContent_data = _.map(condata, function(item) {
                item.createTimes = filter("date")(item.createTime,"yyyy-MM-dd HH:mm:ss");
                item.statuText = $translate.instant('aws.function.status.'+item.pluginStatus);
                if(!isWs && (item.pluginName==conPhy[0].name)){
                    item.type="con";
                    item.pluginNameCopy = item.pluginName;
                    item.metaData = JSON.parse(item.meta);
                    item.statusType = item.metaData.status.task_queue_status?item.metaData.status.task_queue_status.task_queue_name:"ready";
                    item.Ratio = {
                        total:item.statusType=='install'?21:item.pluginStatus=='4'?1:1,
                        inUsed:item.metaData.status.subtask_status?item.metaData.status.subtask_status.length:0
                    }
                }else if(!isWs && (item.pluginName=='phy')){
                    item.type="phy";
                    item.pluginNameCopy = $translate.instant('aws.function.functiontype.phy');
                    item.statuText = $translate.instant('aws.function.status.'+item.pluginStatus);
                    var meta = JSON.parse(item.meta);
                    var installProgress = meta.process?meta.process.taskProcess:0;
                    var total = meta.process?meta.process.taskCount:35;
                    item.Ratio = {
                        total:total,
                        inUsed:installProgress
                    }
                }else if(paasList.indexOf(item.pluginName)>-1){
                    item.type = "paas";
                    item.pluginNameCopy = $translate.instant("aws.function.paas."+item.pluginName)
                    item.meta = JSON.parse(item.meta);
                    if(item.pluginStatus == 0){
                        item.Ratio = {total:100,inUsed:100}
                    }else if(item.pluginStatus == 1){
                        item.Ratio = {total:100,inUsed:0}
                    }
                    if(item.pluginName=="COC" && item.pluginStatus == 1){
                        item.pluginStatus = 2
                    }
                    /*paas判断*/ 
                    if(item.pluginStatus==1||item.pluginStatus==2){
                        if(item.pluginName =='COC'||item.pluginName =='SkyCloudSecurity'){
                           item.canSync=false;
                        }
                    }else if(item.pluginStatus==0){
                        if(item.pluginName =='COC'||item.pluginName =='SkyCloudSecurity'){
                           item.canSync=true;
                        }
                    }
                }else if(item.pluginName=="maas"){
                    item.type = "maas";
                    item.meta = JSON.parse(item.meta);
                    item.pluginNameCopy = $translate.instant("aws.function.paas."+item.pluginName);
                    if(item.pluginStatus == 0){
                        item.Ratio = {total:100,inUsed:100}
                    }else if(item.pluginStatus == 1){
                        item.Ratio = {total:100,inUsed:0}
                    }
                }else if(item.pluginName=="RGW"){
                    item.type = "RGW";
                    item.pluginNameCopy = $translate.instant("aws.function.functiontype."+item.pluginName);
                    if(item.pluginStatus == 3){
                        item.Ratio = {total:100,inUsed:100};
                    }else if(item.pluginStatus == 4){
                        item.Ratio = {total:100,inUsed:0};
                    }else if(item.pluginStatus == 0||item.pluginStatus == 2){
                        item.Ratio = {total:100,inUsed:70};
                    }
                }else if(item.pluginName=="TCS_API_KEY"){
                    // add by wuwt on 2021.09.01 pluginStatus:4失败
                    item.type = "TCS_API_KEY";
                    item.pluginNameCopy = "TCS";
                    item.meta = JSON.parse(item.meta);
                    if(item.pluginStatus == 4 ){
                        item.Ratio = {total:100,inUsed:0};
                    }else{
                        item.pluginStatus = 3;
                        item.Ratio = {total:100,inUsed:100};
                    }
                }else if(tsafeList.indexOf(item.pluginName)>-1){
                    item.meta = JSON.parse(item.meta);
                    if(item.pluginStatus == 0){
                        item.Ratio = {total:100,inUsed:100}
                    }else if(item.pluginStatus == 1){
                        item.Ratio = {total:100,inUsed:0}
                    }
                    item.type = "T_cloud_safe";
                    item.pluginNameCopy = $translate.instant("aws.function.paas."+item.pluginName);
                }
                if(item.pluginName == 'phy' && item.statuText==$translate.instant('aws.function.status.3')){
                    self.showalerttip = true;
                }

                item.searchTerm = [item.pluginNameCopy ,item.createTimes ,item.statuText].join('\b');
                if(item.pluginName == 'phy' && item.statuText==$translate.instant('aws.function.status.1') ){
                    item.canClear =true ;
                }else{
                    item.canClear =false ;
                }
                /*按钮状态机（容器判断）*/
                if(item.statusType=='ready'){
                    setHanle(false,false)
                    if(item.pluginStatus==8||item.pluginStatus==9){
                        setHanle(true,true)
                    }
                }else if(item.statusType=='clean'){
                    switch (item.pluginStatus){
                        case 0 :
                            setHanle(false,false);
                        break;
                        case 1 :
                        case 2 :                        
                            setHanle(true,true);
                        break;
                        case 3 :
                            setHanle(false,true);
                        break;
                        case 4 :
                            setHanle(true,false);
                        break;
                    }
                }else if(item.statusType=='install'){
                    switch (item.pluginStatus){
                        case 0 :
                            setHanle(false,false);
                        break;
                        case 1 :
                        case 2 :                        
                            setHanle(true,true);
                        break;
                        case 3 :
                            setHanle(true,false);
                        break;
                        case 4 :
                            setHanle(true,false);
                        break;
                    }
                }
                

                return item;
            });
            self.tabledata = angular.copy(listContent_data);
            self.tableContentParams = new NgTableParams({ count: 10 }, { counts: [], dataset: listContent_data })
            var tableId = "pluginId";
            newCheckedSrv.checkDo(self, listContent_data, tableId,"tableContentParams"); 
            self.listPluginData={
                con:true,
                phy:true
            }
            if(condata.length>0){
                var constent = condata.filter(item=>{
                    return item.type=='con'
                })
                var physic = condata.filter(item=>{
                    return item.type=='phy'
                })
                if(constent.length>0){
                    self.listPluginData.con = false;
                }
                if(physic.length>0){
                    self.listPluginData.phy = false;
                }
                if(constent.length>0&&physic.length>0){
                    self.conCanAdd = true;
                }else{
                    self.conCanAdd = false;
                }
            }else{
                self.conCanAdd = false;
            }
            //判断云管平台是否可以添加物理机和容器
            let regionBusiAuth=localStorage.regionBusiAuth!=2?JSON.parse(localStorage.regionBusiAuth):[];
            regionBusiAuth=regionBusiAuth.map(function(item){
                item=Number(item);
                return item;
            });
            
            self.typeList = setTypeListFunc(regionBusiAuth,self.listPluginData,conPhy);
            
            function setTypeListFunc(regionBusiAuth,listPluginData,conPhy){
                let arr = [];
                if(regionBusiAuth.indexOf(4)>-1&&listPluginData.phy){
                // if(regionBusiAuth.indexOf(4)>-1){
                    arr.push(conPhy[1]);
                }
                if(regionBusiAuth.indexOf(5)>-1&&listPluginData.con){
                    arr.push(conPhy[0]);
                }
                return arr;
            }

            // if(phyIndex>-1&&conIndex>-1){
            //     setTypeListFunc();
            // }else if(phyIndex>-1&&conIndex==-1){
            //     if(self.listPluginData.con&&self.listPluginData.phy){
            //         self.typeList = [
            //             conPhy[1]
            //         ]
            //     }else if(self.listPluginData.con&&!self.listPluginData.phy){
            //         self.typeList=[]; 
            //     }
            // }else if(phyIndex==-1&&conIndex>-1){
            //     if(self.listPluginData.con&&self.listPluginData.phy){
            //         self.typeList = [
            //             conPhy[0]
            //         ]
            //     }else if(!self.listPluginData.con&&self.listPluginData.phy){
            //         self.typeList=[]; 
            //     }
            // }else if(phyIndex==-1&&conIndex==-1){
            //     self.typeList=[];
            // }
            var filtePaasType = paasServiceTypes.filter(obj => {
                var hasType = false;
                self.tabledata.map(item => {
                    if(item.pluginName == obj.type){
                        hasType = true
                    }
                })
                return !hasType
            })
        
            filtePaasType = filtePaasType.filter( obj => {
                var flag = false;
                regionBusiAuth.map(item => {
                    if(item == obj.authId){
                        flag = true;
                    }
                })
                return flag;
            })
            
            self.typeList.push(...filtePaasType);
            
            if(self.typeList.length==0){
               self.conCanAdd = true;
            }else{
               self.conCanAdd = false; 
            }
        }

        self.syncCOCSky = function(pluginName){
            self.clearPluginName=pluginName;
            var content = {
                target: "syncCOCSky",
                msg:"<span>"+ $translate.instant("aws.system.plugin.sync_tips") + "</span>",
                type: "info",
                btnType: "btn-primary",
                pluginName:pluginName
            };
            self.$emit("delete", content);
        }

        self.$on("syncCOCSky", function(e,data) {
            if(self.clearPluginName=='COC'){
               accessSrv.syncCOC().then(function(res){});
            }else if(self.clearPluginName=='SkyCloudSecurity'){
               accessSrv.syncSkyCloudSecurity().then(function(res){});
            }
        });

        self.$on("functionSocket", function(e, data) {
            if(data.indexOf('plugin.ironic')>-1){
                var data=angular.fromJson(data);
                self.phyLogMessages.unshift(JSON.stringify(data));
                if(self.phyLogMessages.length>1500){
                    self.phyLogMessages = self.phyLogMessages.slice(0,1001);
                    self.logMessages =  self.phyLogMessages.join("\n"); 
                }else{
                    self.logMessages =  self.phyLogMessages.join("\n");
                }
                if(data.event_type=='plugin.ironic.install.log'||data.event_type=='plugin.ironic.remove.log'){
                    var phyItem = data;
                    contentListData.forEach(function(item){
                        if(item.type=='phy'){
                            item.pluginStatus = phyItem.process.taskStatus;
                            if(item.pluginStatus=='6'){
                                item.canPlug = false;
                            }
                            if(item.pluginStatus==3){
                                item.Ratio = {
                                    total:35,
                                    inUsed:35
                                }
                            }else{
                                item.Ratio = {
                                    total:(phyItem.process.taskStatus!=4&&phyItem.process.taskStatus!=7)?phyItem.process.taskCount:35,
                                    inUsed:(phyItem.process.taskStatus!=4&&phyItem.process.taskStatus!=7)?phyItem.process.taskProcess:0
                                }
                            }
                        }
                    })
                }
                successConFunc(contentListData,true);
            }else if(
                    data.indexOf('plugin.k8s.install.kube.kit')>-1||
                    data.indexOf('plugin.k8s.manager')>-1||
                    data.indexOf('task_queue_status')>-1
                ){
                if(JSON.parse(data).log||data.indexOf('plugin.k8s.install.kube.kit')){
                    JSON.parse(data).log?self.conLogMessages.unshift(JSON.parse(data).log):self.conLogMessages.unshift(data);
                    if(self.conLogMessages.length>1500){
                        self.conLogMessages = self.conLogMessages.slice(0,1001)
                        self.logMessages =  self.conLogMessages.join("\n");
                    }else{
                        self.logMessages =  self.conLogMessages.join("\n");
                    }
                }
                if(data.indexOf('task_queue_status')>-1){
                    var conItem = JSON.parse(data)
                    contentListData.forEach(function(item){
                        if(item.type=='con'){
                            item.statusType = conItem.task_queue_status?conItem.task_queue_status.task_queue_name:"ready";
                            item.pluginStatus = conItem.task_queue_status?conItem.task_queue_status.task_queue_status:"0";
                            item.Ratio = {
                                total:item.statusType=='install'?21:item.pluginStatus=='4'?1:1,
                                inUsed:conItem.subtask_status?conItem.subtask_status.length:0
                            }
                        }
                    })
                    successConFunc(contentListData,true);
                }
                if(data.indexOf('plugin.k8s.install.kube.kit')>-1){
                    var phyItem = JSON.parse(data);
                    contentListData.forEach(function(item){
                        if(item.type=='con'){
                            if(data.indexOf('taskStatus')>-1){
                                item.pluginStatus = phyItem.process.taskStatus;    
                            }
                            item.Ratio = {
                                total:21,
                                inUsed:0
                            }
                        }
                    })
                    successConFunc(contentListData,true);
                }
            }else if(data.indexOf("cinder.joint.success") > -1 || data.data.indexOf("cinder.joint.fail") > -1){
                initContentTable();
            }
            self.$apply()
        });

        self.refreshCon = function(){
            initContentTable()
        };

        self.delFun = function(item,left){
            self.left = left;
            if(left){
                self.currentPlugin = item.type;
                self.currentPluginId = item.pluginId;
            }else{
                self.currentPlugin = item[0].type;
            }
            var content = {
                target: "DelRes",
                msg:"<span>"+ $translate.instant("aws.system.plugin.del_tips") + "</span>",
                data:item
            };
            self.$emit("delete", content);
        };

        self.$on("DelRes", function(e,obj) {
            if(self.currentPlugin=='phy'){
                var del_ids = '';
                self.checkedItems.forEach(item => {
                    del_ids = item.pluginId;
                });
                self.delDisable  = true;
                accessSrv.deleteFunData(del_ids).then(function(res){
                    initContentTable()
                })
            }else if(self.currentPlugin=='con'){
                var regionUid = JSON.parse(localStorage.$LOGINDATA).regionUid;
                var data = {
                    "enterpriseUid":localStorage.enterpriseUid,
                    "regionUid":regionUid,
                    "pluginId":self.checkedItems[0].pluginId
                }
                accessSrv.deleteContent(data).then(function(res){
                    initContentTable()
                })
            }else{
                var id;
                if(self.left){
                    id = self.currentPluginId;
                }else{
                    id = self.checkedItems[0].pluginId;
                }
                accessSrv.deletePaas(id).then(function(res){
                    initContentTable();
                    if(res){
                        if(self.currentPlugin=='maas'){
                            utilTool.updateSupportOtherClouds("MAAS_API_KEY","del");
                        }
                        if(self.currentPlugin=='TCS_API_KEY'){
                            utilTool.updateSupportOtherClouds("TCS_API_KEY","del");
                        }
                    }
                })
            }
        });

        self.$watch(function(){
            return self.checkedItems
        },function(value){
            let arrL = ["T_cloud_safe","paas","TCS_API_KEY"]
            if(value&&value.length>0){
                if(value.length==1){
                    if(value[0].type=='con'){
                        if(value[0].pluginStatus=='4'||value[0].pluginStatus=='3'||value[0].pluginStatus=='0'||value[0].pluginStatus=='9 '){
                            self.delDisable  = false;    
                        }else{
                            self.delDisable  = true;    
                        }
                    }else if(value[0].type=='phy'){
                        if(value[0].pluginStatus == 1||
                            value[0].pluginStatus == 6
                            ){
                            self.delDisable  = false;
                        }else{
                            self.delDisable  = true;   
                        }
                    }else if(value[0].type=='RGW'){
                        if(value[0].pluginStatus == 4){
                            self.delDisable  = false;
                        }else{
                            self.delDisable  = true;
                        }  
                    }else if(arrL.indexOf(value[0].type)>-1){
                        self.delDisable  = false;  
                    }
                }else{
                    self.delDisable  = true;
                }
            }else{
               self.delDisable  = true; 
            }  
        })
        /*容器*/

        self.installPlugin = function(item){
            if(item.type=='con'){
                var regionUid = JSON.parse(localStorage.$LOGINDATA).regionUid;
                var data = {
                    "enterpriseUid":localStorage.enterpriseUid,
                    "regionUid":regionUid,
                    "pluginId":item.pluginId
                }
                accessSrv.installContent(data).then(function(res){
                })  
            }else{
                accessSrv.installPhy(item.pluginId).then(function(res){
                    item.canPlug =true;
                }) 
            } 
        }


        /*清除功能*/
        self.cleanPlugin = function(item){
            if(item.type=='con'){
                self.clearPluginId = item.pluginId;
                var content = {
                    target: "clearConInfo",
                    msg:"<span>"+$translate.instant('aws.function.tips.contips')+"</span>",
                };
                self.$emit("delete", content);
            }else{
                self.clearPluginId = item.pluginId;
                var content = {
                    target: "clearPhyInfo",
                    msg:"<span>"+$translate.instant('aws.function.tips.phytips')+"</span>",
                };
                self.$emit("delete", content);
            }
        }

        self.$on("clearPhyInfo", function() {
            accessSrv.clearPhy(self.clearPluginId).then(function(res){
            })        
        });

        self.$on("clearConInfo", function() {
            var regionUid = JSON.parse(localStorage.$LOGINDATA).regionUid;
            var data = {
                "enterpriseUid":localStorage.enterpriseUid,
                "regionUid":regionUid,
                "pluginId":self.clearPluginId
            }
            accessSrv.clearContent(data).then(function(res){
            })
        });

        
        /*查看详情*/
        self.phyLogMessages=[];
        self.conLogMessages=[];
        function getPluginLog(type,pluginId,status){
            var methodType = ["_install_kube_kit","install_ironic","remove_install"]
            if(type=='con'){
                var dataParams = {
                    "method": methodType[0],
                    "meta": pluginId,
                    "regionKey":localStorage.regionKey
                }
                accessSrv.getPLuginLog(dataParams).then(function(res){
                    if(res&&res.data){
                        if(res.data.logs&&res.data.logs.length>0){
                            res.data.logs.forEach(function(item){
                                self.conLogMessages.unshift(item.customMsg)       
                            })
                            self.logMessages =  self.conLogMessages.join("\n");
                        }
                    }
                })
            }else if(type=='phy'){
                if(
                    status==5||
                    status==6||
                    status==7
                    ){
                    var dataParams = {
                        "method": methodType[2],
                        "meta": pluginId,
                        "regionKey":localStorage.regionKey
                    }
                }else{
                    var dataParams = {
                        "method": methodType[1],
                        "meta": pluginId,
                        "regionKey":localStorage.regionKey
                    }
                }
                accessSrv.getPLuginLog(dataParams).then(function(res){
                    if(res&&res.data){
                        if(res.data.logs&&res.data.logs.length>0){
                            res.data.logs.forEach(function(item){
                                self.phyLogMessages.unshift(item.customMsg);    
                                self.logMessages =  self.phyLogMessages.join("\n");   
                            })
                        }
                    }
                })
            }
        }

        if ($location.search().plugintype) {
            self.currentPlugin = $location.search().plugintype
            getPluginLog(self.currentPlugin,$location.search().id,$location.search().pluginStatus)
        }
        self.enterDetail = function(item){
            let noDetailList = ['paas','maas','RGW','TCS_API_KEY','T_cloud_safe'];
            if(noDetailList.indexOf(item.type)>-1){
                return;
            }
            self.phyLogMessages=[];
            self.conLogMessages=[];
            self.currentPlugin = item.type
            $location.search('id='+item.pluginId+"&plugintype="+item.type+"&pluginStatus="+item.pluginStatus);

            getPluginLog(item.type,item.pluginId,item.pluginStatus)
        }

        self.createFun = function(type){
            var scope = self.$new();
            $uibModal.open({
                animation: true,
                templateUrl: "js/system/pluginManage/tmpl/option-plugin-create.html",
                controller:  "createFunCtrl",
                scope: scope,
                resolve:{
                    initConTable:function(){
                        return initContentTable;
                    },
                    netCidr:function(){
                        return netCidr;
                    }
                }
            })
        };
        self.editMaas = function(data){
            var scope = self.$new();
            var editMaas = $uibModal.open({
                animation: true,
                templateUrl: "editMaas.html",
                scope: scope
            })
            var item = angular.copy(data);
            scope.formData = {};
            scope.formData.url = item.meta.site;
            scope.formData.key = item.meta.maasKey;
            scope.submittedvalid = false;
            scope.editMaasConfirm = function(field){
                if(field.$valid){
                    let data = {
                        enterpriseUid:item.enterpriseUid,
                        regionUid:item.regionUid,
                        pluginName:"maas",
                        meta:{
                            site:scope.formData.url,
                            maasKey:scope.formData.key
                        }
                    }
                    data.meta = JSON.stringify(data.meta);
                    accessSrv.editMaas(data,item.pluginId).then(res=>{
                        if(res&&res.data){
                            let c = localStorage.supportOtherClouds.split("MAAS_API_KEY");
                            if(res.data.pluginStatus==0){
                                utilTool.updateSupportOtherClouds("MAAS_API_KEY","add");
                                
                            }else{
                                utilTool.updateSupportOtherClouds("MAAS_API_KEY","del");
                            }
                        }
                    }).finally(function(){
                        initContentTable()
                    });
                    editMaas.dismiss('cancel');
                }else{
                    scope.submittedvalid = true;
                }
            }
        }
        self.editPaas = function(data){
            var scope = self.$new();
            var wbListIpsModal = $uibModal.open({
                animation: true,
                templateUrl: "js/system/pluginManage/tmpl/option-plugin-edit.html",
                scope: scope
            })
            var item = angular.copy(data);
            // add by wuwt on 2021.09.08
            scope.paasEdit = item.meta;
            scope.paasEditType = item.pluginName;
            scope.submittedvalid = false;
            scope.editFuncConfirm = function(field){
                if(field.$valid){
                    var data = {
                        "regionUid":JSON.parse(localStorage.$LOGINDATA).regionUid,
                        "enterpriseUid":localStorage.enterpriseUid,
                        "pluginName":item.pluginName,
                        "pluginId":item.pluginId,
                        "meta":angular.toJson(scope.paasEdit)
                    }
                    accessSrv.editPaas(data).finally(function(){
                        initContentTable()
                    });
                    wbListIpsModal.dismiss('cancel');

                }else{
                    scope.submittedvalid = true;
                }
            }
        }
    }])
.controller("createFunCtrl",["$scope","$timeout","$location","$translate","accessSrv","NgTableParams","initConTable","netCidr", "$uibModalInstance","$filter","utilTool",
    function($scope,$timeout,$location,$translate,accessSrv,NgTableParams,initConTable,netCidr,$uibModalInstance,filter,utilTool){
        var scope = $scope;
        scope.submitted = false;
        scope.interacted = function(field){
            return scope.submitted || field.$dirty;
        };
        scope.paas = {
            TDSQL:{},
            CTSDB:{},
            TSF:{},
            CloudSecurity:{},
            TBase:{},
            COC:{},
            TBDS:{},
            CMQ:{},
            APIGateway:{},
            BlueKing:{},
            Alauda:{},
            CSP:{},
            SkyCloudSecurity:{},
            sec:{},
            TCS_API_KEY:{}
        }
        scope.paramsData = {};
        scope.paramsData.selected = scope.typeList[0];
        scope.objectStorageResData = [];
        function getObjectStorageConf(){
            accessSrv.getObjectStorageConf().then(res => {
                if(res && res.data && res.data.data){
                    res.data.data.map(item => {
                        item.name = item.name + '（' + $translate.instant('aws.function.functiontype.'+item.type) + '）';
                    })
                    scope.objectStorageResData = res.data.data;
                }
            })
        }
        function changePluginType(){
            switch (scope.paramsData.selected.type){
                case '2': 
                    scope.ironic={
                        cidr:"",
                        Gateway:"",
                        start:"",
                        end:"",
                        includeCheck:false
                    }
                    
                    scope.ironicFloatingCheck={
                        includeCheck:false
                    }
                    
                    // 所有外部网络子网
                    scope.floatingList = [];

                    // 所有外部网络子网网段
                    scope.floatingRanage = [];

                    scope.rangeSame = false;
                    function checkSames(ironic,floatingRanage){
                        scope.rangeSame = false;
                        let ironicStart = _IP.toLong(ironic.start);
                        let ironicEnd = _IP.toLong(ironic.end);
                        floatingRanage.forEach(item=>{
                            if(scope.rangeSame){
                                return;
                            }
                            let curStart =  _IP.toLong(item.start);
                            let curEnd =  _IP.toLong(item.end);

                            if(!(ironicStart>curEnd||ironicEnd<curStart)){
                                scope.rangeSame = true;
                            }
                        
                        });
                    }
                    function checkGatewayIronic(range,gateway){
                        scope.ironic.includeCheck = false;
                        let gatewayLong = _IP.toLong(gateway);
                        let rangeStart =  _IP.toLong(range.start);
                        let rangeEnd =  _IP.toLong(range.end);
                        if(rangeStart<=gatewayLong&&gatewayLong<=rangeEnd){
                            scope.ironic.includeCheck = true;
                        }
                    }
                    // accessSrv.getIps().then(function(res){
                    accessSrv.newGetNetworksTableData().then(function(res){
                        if(res&&res.data&&res.data.length>0){
                            res.data.forEach(item=>{
                                scope.floatingList.push(...item.subnets);
                            });
                            scope.floatingList.forEach(item=>{
                                scope.floatingRanage.push(...item.allocationPools);
                            })
                        }
                    })

                    scope.functionConfirm = function(form_field){
                        if(form_field.$valid){
                            // 校验ironic网络 是否与外部网络重复
                            checkSames(scope.ironic,scope.floatingRanage);

                            // 校验ironic网关 是否与ironic网络重复
                            checkGatewayIronic(scope.ironic,scope.ironic.Gateway,'ironic');
                            
                            if(!scope.rangeSame&&!scope.ironic.includeCheck){
                                var data = {
                                    "pluginName":scope.paramsData.selected.name,
                                    "ironicFloatingCidr":scope.ironic.cidr,
                                    "ironicFloatingGateway":scope.ironic.Gateway,
                                    "ironicStartip":scope.ironic.start,
                                    "ironicEndip":scope.ironic.end,
                                    // "inspectorVmMgmtNetmask":scope.inspectorDhcp.Netmask,
                                    // "inspectorVmMgmtGateway":scope.inspectorDhcp.Gateway,
                                    // "inspectorDhcpStartip":scope.inspectorDhcp.start,
                                    // "inspectorDhcpEndip":scope.inspectorDhcp.end
                                    "inspectorVmMgmtNetmask":"",
                                    "inspectorVmMgmtGateway":"",
                                    "inspectorDhcpStartip":"",
                                    "inspectorDhcpEndip":""
                                }
                                accessSrv.checkedFunStatus().then(function(res){
                                    if(res&&res.data){
                                        accessSrv.addFunData(data).finally(function(){
                                            initConTable();
                                        });
                                    }
                                })
                                $uibModalInstance.dismiss('cancel');
                            }
                        
                        }else{
                            scope.submitted = true;
                        }
                    };
                    break;
                case '1': 
                    // scope.container = {
                    //     "useCeph":0,
                    //     "serverIpAddressAndPort":"",
                    //     "masters":"192.168.138.140",
                    //     "nodes":"192.168.138.141-192.168.138.143",
                    //     "masterPassword":"awcloud",
                    //     "nodePassword":"awcloud",
                    //     "diskName":"/dev/sdb"
                    // }
                    
                    scope.container = {
                        "useCeph":0,
                        "serverIpAddressAndPort":"",
                        "masters":"",
                        "nodes":"",
                        "masterPassword":"",
                        "nodePassword":"",
                        "masterDiskName":"",
                        "nodeDiskName":""
                    }


                    scope.psDispaly = false;
                    scope.mpsDispaly = false;
                    scope.passwordDisplay = function(m){
                        if(m){
                            scope.mpsDispaly = !scope.mpsDispaly;
                            if(scope.mpsDispaly){
                                $("#ksmpsw").attr('type','text')   
                            }else{
                                $("#ksmpsw").attr('type','password')   
                            } 
                        }else{
                            scope.psDispaly = !scope.psDispaly;
                            if(scope.psDispaly){
                                $("#kspsw").attr('type','text')   
                            }else{
                                $("#kspsw").attr('type','password')   
                            } 
                        }
                    } 

                    scope.functionConfirm = function(form_field){
                        if(form_field.$valid){ 
                            var regionUid = JSON.parse(localStorage.$LOGINDATA).regionUid;
                            var data = {
                                "useCeph":scope.container.useCeph==1?true:false,
                                "serverIpAddressAndPort":scope.container.serverIpAddressAndPort,
                                "masters":scope.container.masters,
                                "nodes":scope.container.nodes,
                                "masterPassword":scope.container.masterPassword,
                                "nodePassword":scope.container.nodePassword,
                                "masterDiskName":scope.container.masterDiskName,
                                "nodeDiskName":scope.container.nodeDiskName,
                                "enterpriseUid":localStorage.enterpriseUid,
                                "regionUid":regionUid
                            }
                            accessSrv.addContent(data).finally(function(){
                                initConTable()
                            });
                            $uibModalInstance.dismiss('cancel');
                        }else{
                            scope.submitted = true;
                        }
                    };

                break;
                case "sec":
                case "TDSQL":
                case "CTSDB":
                case "TSF":
                case "CloudSecurity":
                case "TBase":
                case "COC":
                case "TBDS":
                case "CMQ":
                case "APIGateway":
                case "BlueKing":
                case "Alauda":
                case "SkyCloudSecurity":
                case "CSP":
                case "TCS_API_KEY":
                    scope.functionConfirm = function(form_field){
                        if(form_field.$valid){ 
                            // add by wuwt on 2021.09.10 active激活 start---
                            if(scope.paramsData.selected.type=='TCS_API_KEY'){
                                let active = scope.paas[scope.paramsData.selected.type].Active;
                                if(active){
                                    scope.paramsData.selected.Active = true;
                                }else{
                                    scope.paramsData.selected.Active = false;
                                }
                            }
                            let meta = JSON.parse(angular.toJson(scope.paas[scope.paramsData.selected.type]));
                            meta.Active = scope.paramsData.selected.Active;
                            // add by wuwt on 2021.09.10 active激活 end---
                            var data = {
                                "regionUid":JSON.parse(localStorage.$LOGINDATA).regionUid,
                                "enterpriseUid":localStorage.enterpriseUid,
                                "pluginName":scope.paramsData.selected.type,
                                "meta":JSON.stringify(meta)  
                            }
                            
                            accessSrv.addPaas(data).then(res=>{
                            }).finally(function(){
                                initConTable()
                            });
                            $uibModalInstance.dismiss('cancel');
                        }else{
                            scope.submitted = true;
                        }
                    };
                    break;
                case "maas":
                    scope.formData = {
                        url:"",
                        key:""
                    }
                    scope.functionConfirm = function(form_field){
                        if(form_field.$valid){ 
                            let data = {
                                enterpriseUid:localStorage.enterpriseUid,
                                regionUid:JSON.parse(localStorage.$LOGINDATA).regionUid,
                                pluginName:"maas",
                                meta:{
                                    site:scope.formData.url,
                                    maasKey:scope.formData.key
                                }
                            }
                            data.meta = JSON.stringify(data.meta);
                            accessSrv.addMaas(data).then(res=>{
                                if(res&&res.data){
                                    if(res.data.pluginStatus==0){
                                        utilTool.updateSupportOtherClouds("MAAS_API_KEY","add");
                                    }else{
                                        utilTool.updateSupportOtherClouds("MAAS_API_KEY","del");
                                    }
                                }
                            }).finally(function(){
                                initConTable()
                            });
                            $uibModalInstance.dismiss('cancel');
                        }else{
                            scope.submitted = true;
                        }
                    };
                break;
                case "RGW":
                    getObjectStorageConf();
                    scope.formData = {
                        param:null,
                    }
                    scope.functionConfirm = function(form_field){
                        if(form_field.$valid){
                            let meta;
                            if(scope.formData.param.type == 'replicated'){
                                meta = {
                                    ceph_rgw_pool_type:'replicated',
                                    ceph_rgw_pool_size:scope.formData.param.id
                                }
                            }else{
                                meta = {
                                    ceph_rgw_pool_type:'erasure',
                                    ceph_erasure_data_chunks:scope.formData.param.k,
                                    ceph_erasure_coding_chunks:scope.formData.param.m,
                                }
                            }
                            let data = {
                                pluginName: "RGW",
                                meta: JSON.stringify(meta)
                            }
                            accessSrv.addObjectStorage(data).finally(function(){
                                initConTable();
                            });
                            $uibModalInstance.dismiss('cancel');
                        }else{
                            scope.submitted = true;
                        }
                    };
                break;
                case "yujie":
                case "tianmu":
                case "yujian":
                case "waf":
                case "ngfw":
                case "fm":
                    scope.formData = {};
                    scope.functionConfirm = function(form_field){
                        if(form_field.$valid){ 
                            let meta = scope.formData;
                            var data = {
                                "regionUid":JSON.parse(localStorage.$LOGINDATA).regionUid,
                                "enterpriseUid":localStorage.enterpriseUid,
                                "pluginName":scope.paramsData.selected.type,
                                "meta":JSON.stringify(meta)  
                            }
                            accessSrv.addPaas(data).then(res=>{
                            }).finally(function(){
                                initConTable()
                            });
                            $uibModalInstance.dismiss('cancel');
                        }else{
                            scope.submitted = true;
                        }
                    }
                    break;
            }
        }
        changePluginType();
        scope.changeType = function(v){
            scope.submitted = false;
            scope.functionForm.$setPristine();
            scope.functionForm.$setUntouched();
            if(v.type==2){
                scope.canHandles={
                    enableCeph:false,
                    enableDisable:true
                }
                accessSrv.getCephStatus().then(function(res){
                    var cephData = [];
                    if(res&&res.data&&res.data.length>0){
                        cephData = res.data.filter(function(i){
                            return i.storageFirm =='ceph'||i.storageFirm =='out_ceph'
                        })
                    }
                    if(cephData.length>0){
                        scope.canHandles={
                            enableCeph:false,
                            enableDisable:false
                        }
                    }else{
                        scope.canHandles={
                            enableCeph:true,
                            enableDisable:true
                        }
                    }
                })
            }else{
                scope.canHandles = {
                    enableCeph:false,
                }
            }
            changePluginType()
        }
 
    }])
.directive("gtips", [function() {
    return {
        require: "ngModel",
        link: function(scope, elem, attrs, $ngModel) {
            $ngModel.$parsers.push(function(value){
                var startValue = elem.parent().parent().siblings(".control-group").find(".form-control").val();
                if(_IP.isV4Format(value)){
                    if(_IP.toLong(value) <= _IP.toLong(startValue)){
                        $ngModel.$setValidity("gtips",false);
                        return value;
                    }
                    $ngModel.$setValidity("gtips",true);
                    return value;
                }
                return value;
            });
            scope.$watch(function(){
                return elem.parent().parent().siblings(".control-group").find(".form-control").val();
            },function(val){
                if(_IP.isV4Format(val)){
                    if(_IP.toLong($ngModel.$viewValue) <= _IP.toLong(val)){
                        $ngModel.$setValidity("gtips",false);
                        return;
                    }
                    $ngModel.$setValidity("gtips",true);
                }
            });
            $ngModel.$formatters.push(function(value){
                var startValue = elem.parent().parent().siblings(".control-group").find(".form-control").val();
                if(_IP.isV4Format(value)){
                    if(_IP.toLong(value) <= _IP.toLong(startValue)){
                        $ngModel.$setValidity("gtips",false);
                        return value;
                    }
                    $ngModel.$setValidity("gtips",true);
                    return value;
                }
                return value;
            });
        }
    };
}])
.directive("incidrs", [function() {
    return {
        require: "ngModel",
        link: function(scope, elem, attrs, $ngModel) {
            var cidr = "#" + attrs.incidrs;
            var cidrReg = /^((?:(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))))[\/]([1-9]|[1-2][0-9]|[3][0]|[3][1])$/;
            $ngModel.$parsers.push(function(value){
                if(cidrReg.test($(cidr).val())){
                    var min = _IP.cidrSubnet($(cidr).val()).networkAddress;
                    var max = _IP.cidrSubnet($(cidr).val()).broadcastAddress;
                    if(_IP.isV4Format(value)){
                        if(!_IP.cidrSubnet($(cidr).val()).contains(value) || (_IP.cidrSubnet($(cidr).val()).contains(value) && (_IP.toLong(min) >= _IP.toLong(value) || _IP.toLong(max)<= _IP.toLong(value)))){
                            $ngModel.$setValidity("incidrs", false);
                            return value;
                        }
                    }
                }    
                $ngModel.$setValidity("incidrs", true);
                return value;
            });
            $ngModel.$formatters.push(function(value){
                if(cidrReg.test($(cidr).val())){
                    var min = _IP.cidrSubnet($(cidr).val()).networkAddress;
                    var max = _IP.cidrSubnet($(cidr).val()).broadcastAddress;
                    if(_IP.isV4Format(value)){
                        if(!_IP.cidrSubnet($(cidr).val()).contains(value) || (_IP.cidrSubnet($(cidr).val()).contains(value) && (_IP.toLong(min) >= _IP.toLong(value) || _IP.toLong(max)<= _IP.toLong(value)))){
                            $ngModel.$setValidity("incidrs", false);
                            return value;
                        }
                    }
                }
                $ngModel.$setValidity("incidrs", true);
                return value;
            });
            scope.$watch(function(){
                return $(cidr).val();
            },function(val){
                if(cidrReg.test($(cidr).val())&&_IP.cidrSubnet(val)){
                    var min = _IP.cidrSubnet(val).networkAddress;
                    var max = _IP.cidrSubnet(val).broadcastAddress;
                    if(!_IP.cidrSubnet(val).contains($ngModel.$viewValue) || (_IP.cidrSubnet(val).contains($ngModel.$viewValue) && (_IP.toLong(min) >= _IP.toLong($ngModel.$viewValue) || _IP.toLong(max)<= _IP.toLong($ngModel.$viewValue)))){
                        $ngModel.$setValidity("incidrs", false);
                        return;
                    }
                    $ngModel.$setValidity("incidrs", true);
                }
            });
        }
    };
}])