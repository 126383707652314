osMigrateCtrl.$inject = ["$scope","$rootScope","$translate","TableCom","instancesSrv","commonFuncSrv","editDataCopy","$uibModalInstance","overviewSrv","refresh"];
export function osMigrateCtrl($scope, $rootScope, $translate,TableCom,instancesSrv,commonFuncSrv,editDataCopy,$uibModalInstance,overviewSrv,refresh){
    let self = $scope;
    self.editDataCopy = editDataCopy;
    self.migrate = {
        "blockMigration": false,
        "diskOverCommit": false
    };
    self.migrate.flavor = `${self.editDataCopy.curCpus}C${parseInt(self.editDataCopy.ram/1024)}G`
    self.migrateHosts = [];
    self.submitInValid = false;
    self.checkedError = false;
    self.osMigrateDisabled = false;
    let handleNodeName= async function(data,nodePrettyNameList){
        var cpuConfigvalue = 4;
        var ramConfigValue = 1;
        await overviewSrv.getConfigValues().then(function(res){
            if (res && res.data) {
                cpuConfigvalue = res.data.cpu_allocation_ratio ? Number(res.data.cpu_allocation_ratio) : 4; 
                ramConfigValue = res.data.ram_allocation_ratio ? Number(res.data.ram_allocation_ratio) : 1;
            }
        })
        await instancesSrv.getMigrateNode().then(function(result) {
            if (result && result.data && result.data[self.editDataCopy.availabilityZone]) {
                self.migrateHosts = result.data[self.editDataCopy.availabilityZone].filter(item=>{
                    item.prettyName = nodePrettyNameList[item.hypervisorHostname];
                    //item.cpuCompute = item.virtualCPU *cpuConfigvalue;
                    item.ramCompute = item.freeRam *cpuConfigvalue;
                    item.freeCpu = item.virtualCPU *cpuConfigvalue - item.virtualUsedCPU;
                    item.freeRam = parseInt(item.freeRam/1024);
                    return item.hypervisorHostname!=self.editDataCopy.hostName;
                })
                TableCom.init(self, 'osMigrateTable',self.migrateHosts,'id');
                if(self.migrateHosts.length>0){
                    self.migrate.id = self.migrateHosts[0].id;
                    self.changeNode(self.migrateHosts[0]);
                }
            }
        });
    }
    if (self.ADMIN && (self.editDataCopy.startStyle == 'EBS'||self.editDataCopy.startStyle == 'Local')) {
        commonFuncSrv.showPrettyNameOfNode("",handleNodeName);
    }
    function checkFlavor(data,id,instance){
        let node = data.filter(item=>item.id==id);
        let flag;
        flag = Number(node[0].freeCpu)>instance.curCpus && Number(node[0].freeRam)>parseInt(instance.ram/1024);
        return {flag,node};
    }
    self.changeNode = function(item){
        let val = checkFlavor(self.migrateHosts,item.id,self.editDataCopy);
        self.checkedError = !val.flag;
        self.migrate.node = val.node;
    }
    self.confirm = function(migrate, osmigrateForm) {
        if (osmigrateForm.$valid) {
            if(self.checkedError || self.migrateHosts.length==0){return;}
            var postData = {
                "host": self.migrate.node[0].hypervisorHostname,
                "blockMigration": migrate.blockMigration,
                "diskOverCommit": migrate.diskOverCommit,
                "serverName":self.editDataCopy.name
            };
            if(self.editDataCopy.startStyle == 'Local'){
            postData.blockMigration=true;
            }
            self.osMigrateDisabled = true;
            instancesSrv.os_migrate(self.editDataCopy.uid, postData).then(function(){
                refresh();
            }).finally(function(){
                self.osMigrateDisabled = false;
                $uibModalInstance.close();
            });
        } else {
            self.submitInValid = true;
        }
    };
}