volumeBackupCtrl.$inject=['$scope', 'volumesDataSrv', '$translate', '$uibModalInstance', 'initTable','checkQuotaSrv','vmFuncSrv','backupsSrv','$routeParams','editData','$location'];
export function volumeBackupCtrl(scope, volumesDataSrv, $translate, $uibModalInstance, initTable,checkQuotaSrv,vmFuncSrv,backupsSrv,$routeParams,editData,$location){
    scope.submitInValid = false;
    scope.newObj = {};
    scope.newObj.type = "true";
    //editData.isComplete == "true"?scope.newObj.canIncremental = true:scope.newObj.canIncremental=false;
    // checkQuotaSrv.checkQuota(scope, "backups");
    // checkQuotaSrv.checkQuota(scope, "backup_gigabytes","","",scope.editData.size);
    // getBackupAmount(scope.editData.size);
    //如果是从备份页面进来重新获取备份的硬盘AWSTACK261-4824
    if($location.path()=='/cvm/backups'){
        backupsSrv.isVolumeExist($routeParams.id).then(function(result){
            if(result && result.data){
                if(result.data.id == $routeParams.id){
                    if(result.data.exist){
                        scope.editData = result.data.volume; 
                        scope.editData.isComplete == "true"?scope.newObj.canIncremental = true:scope.newObj.canIncremental=false;
                        checkQuotaSrv.checkQuota(scope, "backups");
                        checkQuotaSrv.checkQuota(scope, "backup_gigabytes","","",scope.editData.size);
                        getBackupAmount(scope.editData.size);
                    }else{
                        
                    }
                }
            }else{
            
            }
        });
    //云硬盘进来可以直接使用editdata
    }else if($location.path()=='/cvm/volumes'){
        scope.editData = editData; 
        scope.editData.isComplete == "true"?scope.newObj.canIncremental = true:scope.newObj.canIncremental=false;
        checkQuotaSrv.checkQuota(scope, "backups");
        checkQuotaSrv.checkQuota(scope, "backup_gigabytes","","",scope.editData.size);
        getBackupAmount(scope.editData.size);
    }
    
    function getBackupAmount(value) {
        var option = {
            backupVolumeSize: value,
            region: localStorage.regionName || "default"
        }
        vmFuncSrv.bossSourceFunc(scope,value,option,"queryBackupChargingAmount")
    }
    scope.createBackupsConfirm = function(fileFrom){
        if(fileFrom.$valid){
            var postData = {
                name:scope.newObj.name,
                serverIds:[],
                serverNames:[],
                volumeIds:[scope.editData.id],
                volumeNames:[scope.editData.name],
                incremental:scope.newObj.type == "true"?false:true,
            }
            scope.editData.insnameAndId.map(item=>{
                postData.serverIds.push(item.id)
                postData.serverNames.push(item.name)
            })
            volumesDataSrv.createBackups(postData).then(function(res){
                initTable()
            })
            $uibModalInstance.close();
        }else{
            scope.submitInValid = true;
        }
    }
}