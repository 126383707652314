 storageLocalDetailTpl.$inject = [];
 function storageLocalDetailTpl() {
    return {
        restrict:"E",
        replace:true,
        templateUrl:"js/system/storageManagement/tmpl/storage-local-detail-tpl.html",
        link: function(self, elem, attrs, $ngModel) {
        },
        controller:function($scope,$translate,storageManagementSrv,TableCom,utilTool,alertSrv,$timeout){
            let self = $scope;
            self.currentPage = 1;
            self.pageSize = 10;
            self.canJoin = true;
            self.canRemove = true;
            self.localDiskDetailCheckbox = {
                checked: false,
                items: {}
            }
            self.search = {
                globalSearchTerm: ""
            }
            self.statusSelected = {};
            self.searchList = [
                {name:$translate.instant('aws.system.storagement.localDiskDetailTable.status.all'),id:"all",key:""},
                {name:$translate.instant('aws.system.storagement.localDiskDetailTable.status.available'),id:"available",value:$translate.instant('aws.instances.table.status.active'),key:"status_ori"},
                {name:$translate.instant('aws.system.storagement.localDiskDetailTable.status.nov'),id:"nov",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
                {name:$translate.instant('aws.system.storagement.localDiskDetailTable.status.failed'),id:"failed",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"},
                {name:$translate.instant('aws.system.storagement.localDiskDetailTable.status.creating'),id:"creating",value:$translate.instant('aws.instances.table.status.shutoff'),key:"status_ori"}
            ]
            self.headers = {
                host:$translate.instant("aws.system.storagement.nodeName"),
                name:$translate.instant("aws.system.storagement.diskName"),
                type:$translate.instant('aws.system.storagement.diskType'),
                size:$translate.instant('aws.system.storagement.diskCapacity'),
                state:$translate.instant('aws.system.storagement.status'),
                isExist:$translate.instant('aws.system.storagement.localVolume')
            }
            self.tableCols = [
                { field: "check", title: "",headerTemplateURL:"localDiskDetailCheckbox.html",show: true },
                { field: "host", title: self.headers.host,show: true,disable:false},
                { field: "name", title: self.headers.name,show: true,disable:false},
                { field: "type", title: self.headers.type,show: true,disable:false},
                { field: "size", title: self.headers.size,show: true,disable:false},
                { field: "state", title: self.headers.state,show: true,disable:false},
                { field: "isExist", title: self.headers.isExist,show: true,disable:false},
            ];

            // 查询详情表格
            function getDetailLocalDiskList(option) {
                self.localDiskDetailCheckbox.checked = false;
                self.localDiskDetailCheckbox.items = {};
                TableCom.init(self, 'localDiskDetailTable', [], 'id', self.pageSize , 'localDiskDetailCheckbox');
                let defaultParams = {
                    pageNum: 1,
                    pageSize: self.pageSize,
                    pattern: self.search.globalSearchTerm,
                    state: "all"
                }
                let params = option ? option : defaultParams;
                self.localDiskDetailTableData = [];
                storageManagementSrv.getDetailLocalDiskList(params).then(function(res){
                    if(res && res.data) {
                        localDiskDetailDataInfo(res);
                    }
                })
            }
           
            getDetailLocalDiskList();

            // 处理详情表格
            function localDiskDetailDataInfo(res) {
                self.totalNum = res.total;
                self.localDiskDetailTableData = res.data;
                self.localDiskDetailTableData.forEach(item=>{
                    item.type = item.type.toLowerCase();
                    item.size = `${utilTool.setUnit(item.size*1).total}${utilTool.setUnit(item.size*1).unit}`;
                })
                TableCom.init(self, 'localDiskDetailTable', res.data, 'id', self.pageSize , 'localDiskDetailCheckbox');
            }

            // 切换分页
            self.changePage = function(options){
                self.currentPage = options.pageNum;
                self.pageSize = options.pageSize;
                options.pattern = self.search.globalSearchTerm;
                options.state = self.statusSelected.searchItem.id;
                getDetailLocalDiskList(options);
            }
            
            // 搜索
            self.searchBackend = function(){
                self.currentPage = 1;
                let pageOption = {
                    pageSize: self.pageSize,
                    pageNum: 1,
                    pattern: self.search.globalSearchTerm ? self.search.globalSearchTerm : "",
                    state: self.statusSelected.searchItem ? self.statusSelected.searchItem.id : ""
                }
                getDetailLocalDiskList(pageOption);
            }

            // 刷新 刷新图标单独写解决提示文字在top被弹框遮住的问题
            self.refresh = function() {
                self.currentPage = 1;
                self.search.globalSearchTerm = "";
                self.statusSelected.searchItem = self.searchList[0];
                let pageOption = {
                    pageNum: 1,
                    pageSize: self.pageSize,
                    pattern: "",
                    state: "all",  
                }
                getDetailLocalDiskList(pageOption);
            }

            self.$watch(function(){
                return self.localDiskDetailCheckbox.items;
            },function(values){
                if(Object.keys(values).length == 0) return;
                self.checkedItems = [];
                var arr = [];
                for (let i in self.localDiskDetailCheckbox.items) {
                    arr.push(self.localDiskDetailCheckbox.items[i]);
                }
                if(values && arr.length >=0) {
                    for (let key in values) {
                        if(values[key]) {
                            self.localDiskDetailTableData.forEach(item=>{
                                if(item.id == key) {
                                    self.checkedItems.push(item);
                                }
                            })
                        }
                    }
                }
                utilTool.setLocalStorage(self.checkedItems);
                if(self.checkedItems.length >= 1) {
                    for(var i=0; i < self.checkedItems.length; i++){
                        if(self.checkedItems[i].state == 'available'){
                            self.canJoin = true;
                            self.canRemove = false;
                            // isExist  1代表本地盘下有本地卷  不能删除   0可以删
                            if(self.checkedItems[i].isExist == 1) {
                                self.canRemove = true;
                                return;
                            }
                        }else {
                            self.canJoin = false;
                            self.canRemove = true;
                        }
                    }
                }else {
                    self.canJoin = true;
                    self.canRemove = true;
                }
            },true)

            // 处理移入本地磁盘提交的数据
            function dealJoinLocalDisks(checkedItems){
                let list = [];
                checkedItems.forEach(item=>{
                    list.push({host: item.host,uuid:item.uuid})
                })
                return list;
            }

            self.joinLocalDisk = function() {
                var text = $translate.instant("aws.localDisk.tipMsg.joinLocalDisk")
                var content = {
                    target: "joinLocalDisk",
                    msg:"<span>"+ text + "</span>",
                    type: "info",
                    btnType: "btn-primary"
                };
                self.$emit("delete", content);
            };

            // 加入本地磁盘
            self.$on("joinLocalDisk", function() {
                let data = [];
                data = dealJoinLocalDisks(self.checkedItems);
                storageManagementSrv.joinInLocalDisk(data).then(function(res){
                    if(res) {
                        // getDetailLocalDiskList();
                    }
                })
            });

            self.removeLocalDisk = function() {
                var text = $translate.instant("aws.localDisk.tipMsg.removeLocalDisk")
                var content = {
                    target: "removeLocalDisk",
                    msg:"<span>"+ text + "</span>",
                };
                self.$emit("delete", content);
            }

            // 移除本地磁盘
            self.$on("removeLocalDisk", function() {
                let uuids = [];
                self.checkedItems.map(item => {
                    uuids.push(item.uuid);
                });
                storageManagementSrv.removeLocalDisk(uuids).then(function(res){
                    if(res) {
                        getDetailLocalDiskList();
                    }
                })
            });

            // 磁盘加入成功刷新列表
            self.$on("localDiskDetail", function(e,data) {
                refreshData(data);
            })
            
            // 刷新数据
            function refreshData(data) {
                if(data == 'create.local.disk.success') {
                    alertSrv.set("", $translate.instant("aws.localDisk.localDiskJoinSuccess"), "success", 5000);
                }else if(data.indexOf('create.local.disk.fail') > -1) {
                    alertSrv.set("", $translate.instant("aws.localDisk.localDiskJoinFail"), "error", 5000);
                }else if(data == 'create.local.disk.') {
                    alertSrv.set("", $translate.instant("aws.localDisk.localDiskJoinInitIng"), "building", 5000);
                }
                $timeout(()=>{
                    self.refresh();
                },2000)
            }
        }
    };
}
export {storageLocalDetailTpl}
