import * as controllers from "./controllers";
import * as services from "./services";

let auditRoleModule = angular.module("auditRoleModule", ["rzModule"]);

auditRoleModule.config(["$controllerProvider", function($controllerProvider){
	$controllerProvider.register(controllers);
}]);

auditRoleModule.config(["$provide", function($provide){
	$provide.service(services);
}]);