
backupJobMonitorCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","TableCom","utilTool","$location","$routeParams","$filter"]
function backupJobMonitorCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,TableCom,utilTool,$location,$routeParams,$filter) {
	var self = $scope;
	self.dataPageSize=10;
    self.dataCurrentPage=1;
	self.canStop = false;
	self.backupServiceConfig = true;
	self.desc = localStorage.managementRole == 2 ? $translate.instant("aws.backupService.tip.desc0") : $translate.instant("aws.backupService.tip.desc1");
	//设置项的初始化
	self.headers={
        instJobName:$translate.instant("aws.backupService.backupJobInstancer.instJobName"),
        instStatus:$translate.instant("aws.backupService.backupJobInstancer.instStatus"),
		instBackupType:$translate.instant("aws.backupService.backupJobInstancer.instBackupType"),
		instStartTime:$translate.instant("aws.backupService.backupJobInstancer.instStartTime"),
		instUsedTime:$translate.instant("aws.backupService.backupJobInstancer.instUsedTime"),
		instSendSize:$translate.instant("aws.backupService.backupJobInstancer.instSendSize"),
		instSpeed:$translate.instant("aws.backupService.backupJobInstancer.instSpeed"),
		project:$translate.instant("aws.backupService.variable.table.projectName"),
		domain:$translate.instant("aws.backupService.variable.table.domainName"),
    };
	self.backupJobtitleName="backupService_backupJobMonitor";
	if(sessionStorage["backupService_backupJobMonitor"]){
		self.tableCols = JSON.parse(sessionStorage["backupService_backupJobMonitor"]);
	}else {
		self.tableCols=[
			{ field: "check", title: "",headerTemplateURL:"backupJobMonitorManage.html",show: true ,width:"6%"},
			{ field: "instJobName", title: self.headers.instJobName, show: true,disable:true },//任务名称
			{ field: "instStatus", title: self.headers.instStatus, show: true,disable:false },//任务状态
			{ field: "instBackupType", title: self.headers.instBackupType, show: true,disable:false },//备份方式
			{ field: "instStartTime", title: self.headers.instStartTime, show: true,disable:false },//开始时间
			{ field: "instUsedTime", title: self.headers.instUsedTime, show: true,disable:false },//已运行时间
			{ field: "instSendSize", title: self.headers.instSendSize, show: true,disable:false },//传输数据量
			{ field: "instSpeed", title: self.headers.instSpeed, show: true,disable:false },//传输速度
		];
	}
	// 帮助按钮
	self.backupJobSearchTearm = function(obj){
		var tableData = obj.tableData;
		var tableCols = obj.tableCols;
		tableData.map(function(item){
			item.searchTerm = "";
			tableCols.forEach(function(showTitle){
				if(showTitle.value){
					item.searchTerm += item[showTitle.search] + "\b";
				}
			});
		});
	};
	// 监听是否选中全部部门
    self.$watch(function(){
        return localStorage.selectedAllDomain
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self)
    })
	// 刷新
	self.refres = refres;
	function refres(){
		self.globalSearchTerm = "";
		self.dataPageSize=10;
    	self.dataCurrentPage=1;
		initTables();
	}
	initTables();
	function initTables() {
		if(!self.backupServiceConfig){
            return;
        }
        self.dataLoadData  = [];
		let params = {
			pageNum: self.dataCurrentPage,
			pageSize: self.dataPageSize
		}
		if (self.globalSearchTerm) {
			params.search = self.globalSearchTerm.item;
		}
		TableCom.init(self, "tableParams", [], "id", GLOBAL_CONFIG.PAGESIZE, 'checkBackupJobMonitorToCount');
		backupJobSrv.getBackupJobMonitorList(params).then(function (result) {
			if (result && result.data) {
                self.dataTotalNum = result.total;
		        self.dataLoadData = result.data;
		        self.dataLoadData = operateData(self.dataLoadData);
                TableCom.init(self, "tableParams", self.dataLoadData, "id", GLOBAL_CONFIG.PAGESIZE, 'checkBackupJobMonitorToCount');
			}
		});
	}
	function operateData(data) {
		data.forEach(x => {
			// 备份方式
			x.instBackupType =  $translate.instant("aws.backupService.backupJob.backupType." + x.instBackupType);
			// 开始时间
			x.instStartTime = x.instStartTime!=null ? x.instStartTime = $filter("date")(x.instStartTime, "yyyy-MM-dd HH:mm:ss"):"-";
			// 已运行时间
			x.instUsedTime = formatSeconds(x.instUsedTime)
			// 已完成数据量/总数据量 
			// if(x.instCompletedData == 0 || x.instTotalSize == 0) {
			// 	x.progress_a = "0%";
			// }else{
			// 	x.progress_a = ((x.instCompletedData/x.instTotalSize)*100) > 1 ? "100%": (((x.instCompletedData/x.instTotalSize).toFixed(2))*100) + "%";
			// }
			// instCompletedData 已完成数据量
			x.instCompletedData = unitData(x.instCompletedData);
			// instTotalSize 总数据量
			x.instTotalSize_unit = unitData(x.instTotalSize);
		});
		return data;
	}
	// 转换数据量单位
	function unitData(data){
		if(data == 0) {
			return 0 + "B";
		}
		var kb = data / 1024;
		if( kb > 1 || kb == 1){
			var mb = kb / 1024;
			if(mb > 1 || mb == 1) {
                var gb = mb / 1024;
                if(gb > 1 || gb == 1) {
                    return gb.toFixed(2) + " GB";
                }else{
                    return mb.toFixed(2) + " MB";
                }
			}else {
				return kb.toFixed(2) + " KB";
			}
		}
	}
	function formatSeconds(value) {
		let result = parseInt(value/1000)
		let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
		let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
		let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
	 
		let res = '';
		// if(h !== '00') res += `${h}:`;
		// if(m !== '00') res += `${m}:`;
		res += `${h}:`;
		res += `${m}:`;
		res += `${s}`;
		return res;
	}
	self.applyGlobalSearch = function() {
		self.dataPageSize=10;
    	self.dataCurrentPage=1;
		initTables();
	};
	self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
		initTables();
    };
	//监听按钮
    self.$watch(function() {
        return self.checkBackupJobMonitorToCount;
    }, function(val) {
		if(val == undefined) {
			return;
		}
        self.checkedItems = [];
		var arr=[];
        for(var i in self.checkBackupJobMonitorToCount.items){
            arr.push(self.checkBackupJobMonitorToCount.items[i])
        }
		if(val && arr.length>=0){
            for(var key in val.items){
                if(val.items[key]){
                    self.dataLoadData.forEach(item=>{
                        if(item.id==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
		self.canStop = false;
		// 判断正在运行中的状态才可以停止 1：正在启动，2：正在分析，4：正在运行
		var instStatus = [1,2,4]
		if(self.checkedItems.length > 0){ 
			self.canStop = true;
			self.checkedItems.forEach(x => {
				if(instStatus.indexOf(x.instStatus) ==-1) {
					self.canStop = false;
				}
			});
		}
    },true);
	//停止操作
    self.stopBackJob = stopBackJob;
    function stopBackJob(checkedItems) {
		if(!self.canStop){
			return;
		}
		self.checkedItems.forEach(x => {
			let ids = [];
			ids.push(x.id);
			// headers
			var headers = {};
			if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
				const {projectUid,domainUid} = x;
				headers = {
					project_id: projectUid,
					domain_id: domainUid,
				}
			}
			backupJobSrv.stopBackupjob(ids, headers).then(function () {
				initTables();
			});
		})
		
		
    };
	self.$on("backupServiceConfig", function(e, data) {
        // 展示为配置列表
        self.backupServiceConfig = data;
    });
    //server socket
    self.$on("backupJobMonitor", function(e, data) {
        if(data.jobServiceType == "backup-job-monitor"
        ){   
              // 更新列表
			self.dataLoadData.forEach(x => {
				if(x.jobId == data.resultData.jobId) {
					x.instStatus = data.resultData.instStatus;
					x.instSpeed = data.resultData.instSpeed;
					// 已完成数据量
					x.instCompletedData = unitData(data.resultData.instCompletedData)
					// 已运行时间
					x.instUsedTime = data.resultData.instUsedTime != null ? data.resultData.instUsedTime!=0 ? formatSeconds(data.resultData.instUsedTime) : "00:00:00" : "00:00:00";
				}
			});
        }
    });

	self.linkTo = function(item){
        utilTool.setLocalStorage([item]);
        //  ng-href="#/advanced/backup-job?id={{item.instId}}&tab=monitor"
        $location.url('/advanced/backup-job?id=' + item.id + '&instId=' + item.instId +'&tab=monitor');
    }
};
export {backupJobMonitorCtrl}