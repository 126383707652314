import "./objectStorageSettingSrv";

var objectStorageSettingModule = angular.module("objectStorageSettingModule", ["ngMessages", "objectStorageSettingSrvModule"]);
objectStorageSettingModule.controller('objectStorageSettingCtrl', ['$scope', 'objectStorageSettingSrv',
    function (scope, objectStorageSettingSrv) {
        scope.settingList = [];

        function initData() {
            objectStorageSettingSrv.getSettingData().then(function (res) {
                if (res && res.data && res.data.length) {
                    scope.settingList = res.data;
                } else {
                    scope.addItem();
                }
            });
        }
        initData();

        scope.saveSettingData = function (form) {
            scope.submitValid = false;
            if (form.$valid) {
                objectStorageSettingSrv.saveSettingData(scope.settingList).then(() => {
                    initData();
                })
            } else {
                scope.submitValid = true;
            }
        };

        scope.addItem = function () {
            const item = {
                "name": "",
                "accessKey": "",
                "secretKey": "",
                "endpoint": "",
                "signerVersion": "",
                "type": "",
                "user": ""
            };
            scope.settingList.push(item);
        }
        scope.shutItem = function (i) {
            scope.settingList.splice(i, 1);
        }
    }
]).controller('objectStorageSettingCtrlNew', ['$scope', '$translate', 'TableCom', 'objectStorageSettingSrv', 'modalCom',
    function ($scope, $translate, TableCom, objectStorageSettingSrv, modalCom) {
        var self = $scope;
        // 复选框
        self.checkedItems = [];
        self.checkboxes = {
            checked: false, //全选按钮
            items: {},
        };
        // 新建编辑按钮显示隐藏控制
        self.canEdit = false;
        self.canDel = false;
        // 列表页分页搜索参数 
        self.listPageOptions = {
            totalNum:null,
            pageSize:10,
            currentPage:1,
            globalSearchTerm:''
        }
        // 列表显示与隐藏
        self.titleName="objectStorageSettingTitleName";
        // if(sessionStorage["objectStorageSettingTitleName"]){
        //     self.titleData=JSON.parse(sessionStorage["objectStorageSettingTitleName"]);
        // }else{
        //     self.titleData = [
        //         {name:'system.objectSetting.name',value:true,disable:true,search:'name'},
        //         {name:'system.objectSetting.source',value:true,disable:false,search:'source_format'},
        //         {name:'system.objectSetting.user',value:true,disable:false,search:'user'},
        //         {name:'system.objectSetting.departmentName',value:true,disable:false,search:'domainName'},
        //         {name:'system.objectSetting.projectName',value:true,disable:false,search:'projectName'},
        //         {name:'system.objectSetting.signerVersion',value:true,disable:false,search:'signerVersion'},
        //         {name:'system.objectSetting.endpoint',value:true,disable:false,search:'endpoint'},
        //     ];
        // }
        self.titleData = [
            {name:'system.objectSetting.name',value:true,disable:true,search:'name'},
            {name:'system.objectSetting.source',value:true,disable:false,search:'source_format'},
            {name:'system.objectSetting.user',value:true,disable:false,search:'user'},
            {name:'system.objectSetting.departmentName',value:true,disable:false,search:'domainName'},
            {name:'system.objectSetting.projectName',value:true,disable:false,search:'projectName'},
            {name:'system.objectSetting.signerVersion',value:true,disable:false,search:'signerVersion'},
            {name:'system.objectSetting.endpoint',value:true,disable:false,search:'endpoint'},
        ];
        // 表头项
        self.dataTitles = {
            name: $translate.instant("aws.system.objectSetting.name"),
            source_format: $translate.instant("aws.system.objectSetting.source"),
            user: $translate.instant("aws.system.objectSetting.user"),
            domainName: $translate.instant("aws.system.objectSetting.departmentName"),
            projectName: $translate.instant("aws.system.objectSetting.projectName"),
            signerVersion: $translate.instant("aws.system.objectSetting.signerVersion"),
            endpoint: $translate.instant("aws.system.objectSetting.endpoint")
        }
        // 列表页初始化
        function initList() {
            const storageId = location.hash.split('?')[1].split('&')[0].split('=')[1]
            let params = {
                pageNum:self.listPageOptions.currentPage,
                pageSize:self.listPageOptions.pageSize,
                pattern:self.listPageOptions.globalSearchTerm,
                storageId
            };
            objectStorageSettingSrv.getSettingData(params).then(function (res) {
                if (res) {
                    self.tableData = res.data;
                    self.listPageOptions.totalNum = res.total;
                    let _data = _.map(res.data,function(it){
                        it.source_format = it.source==2?$translate.instant("aws.system.objectSetting.innerUser"):$translate.instant("aws.system.objectSetting.externalUser");
                        return it;
                    });
                    TableCom.init(self, "tableParamss", _data, "id", 10, 'checkboxes');
                }
            });
        };
        initList();

        // 监听 self.checkboxes.items
        self.$watch(function () {
            return self.checkboxes.items;
        }, function (ne) {
            self.checkedItems = [];
            if (!ne) {
                return;
            }
            for (var i in ne) {
                self.tableParamss.data.forEach(it => {
                    if (it.id == i && ne[i]) {
                        self.checkedItems.push(it);
                    }
                })
            }
        }, true);
        // 监听 self.checkedItems
        self.$watch(function () {
            return self.checkedItems; 
        }, function (ne, old) {
            self.canEdit = ne.length == 1 ? true : false;
            self.canDel = ne.length >= 1 ? true : false
        }, true)

        // 页面刷新方法
        self.refresh = function () {
            self.listPageOptions.globalSearchTerm = '';
            self.listPageOptions.currentPage = 1;
            initList();
        };
        // 新建编辑方法
        self.update = function (type) {
            modalCom.init('createTemplateModal.html', 'updateRgwCtrl', {
                //使用弹出层实例创建某个资源成功后执行的回调函数
                refresh: function () {
                    return self.refresh;
                },
                //弹出层实例的上下文
                context: function () {
                    return self;
                },
                //弹出层类型，(可选)
                type: function () {
                    return type;
                },
                editItem: function () {
                    return self.checkedItems
                }
            });
        };
        // 删除方法
        self.delete = function () {
            var content = {
                target: "delete-object-rgw-setting",
                msg: "<span>" + $translate.instant("aws.system.objectSetting.delTip") + "</span>",
                data: self.checkedItems
            };
            self.$emit("delete", content);
        };
        self.$on("delete-object-rgw-setting", function (e, checkedItems) {
            let delIds = [];
            checkedItems.forEach(it => {
                delIds.push(it.id)
            });
            objectStorageSettingSrv.delSettingData({
                id: delIds
            }).then(res => {
                self.listPageOptions.currentPage = 1;
                self.listPageOptions.globalSearchTerm = '';
                initList();
            });
        });
        // 搜索方法
        self.searchBackend = function(options) {
            self.listPageOptions.currentPage = 1;
            if(options.type=='refresh') {
                self.listPageOptions.globalSearchTerm = '';
            }
            initList();
        }
        // 分页方法
        self.changePage = function(options) {
            self.listPageOptions.currentPage = options.pageNum;
            initList();
        }

    }
]).controller('updateRgwCtrl', ["$scope", "objectStorageSettingSrv", "$translate", "type", "refresh", "editItem", "$uibModalInstance", function ($scope, objectStorageSettingSrv, $translate, type, refresh, editItem, $uibModalInstance) {
    var self = $scope;
    self.templateModal_title = type=='new'?$translate.instant("aws.action.create"):$translate.instant("aws.action.edit");
    self.depProData = [];
    self.optionList = {
        signerVersion: [],
        type: [],
        user: [],
        department: [],
        project: [],
        accessKey: [],
    };

    function initFieldList(params) {
        if (params) {
            self.fieldList = {
                name: params.name,
                userType: params.source == 2 ? 'inner' : 'external',
                department: {
                    selected: {
                        value: params.domainUid,
                        text: params.domainName
                    }
                },
                project: {
                    selected: {
                        value: params.projectUid,
                        text: params.projectName
                    }
                },
                user: params.user,
                user_select: {
                    selected: {
                        userId: params.user
                    }
                },
                accessKey: params.accessKey,
                accessKey_select: {
                    selected: {
                        value: params.accessKey,
                        text: params.accessKey,
                        secretKey: params.secretKey
                    }
                },
                secretKey: params.secretKey,
                endpoint: params.endpoint,
                signerVersion: {
                    selected: {
                        value: params.signerVersion,
                        text: params.signerVersion
                    }
                },
                type: params.type
            };
        } else {
            const name = self.fieldList ? self.fieldList.name : '';
            self.fieldList = {
                name: name,
                userType: 'inner',
                department: {
                    selected: ''
                },
                project: {
                    selected: ''
                },
                user: '',
                user_select: {
                    selected: ''
                },
                accessKey: '',
                accessKey_select: {
                    selected: ''
                },
                secretKey: '',
                endpoint: '',
                signerVersion: {
                    selected: ''
                },
                type: ""
            };
        }
    }
    if (type=='edit'&&editItem.length == 1) {
        initFieldList(editItem[0]);
        self.translateUserType = self.fieldList.userType=='inner'?$translate.instant("aws.system.objectSetting.innerUser"):$translate.instant("aws.system.objectSetting.externalUser")
    } else {
        initFieldList();
    }
    self.innerBtnActive = 'active-1';
    self.externalBtnActive = '';
    self.depProAreShow = true;
    self.depProDisabled = false;
    self.fieldDisabled = true;
    self.showUserSelect = true;
    self.tagChange = true
    if (type == 'edit') {
        self.tagChange = false
        if (editItem[0].source == 2) { //内部
            self.showUserSelect = true;
            self.depProAreShow = true;
            self.depProDisabled = true;
            self.fieldDisabled = true
        } else if (editItem[0].source == 3) { //外部
            self.showUserSelect = false;
            self.depProAreShow = false;
            self.fieldDisabled = false;
        }
    }
    self.userBtnClick = function (btn) {
        if (btn == 'inner') {
            self.innerBtnActive = 'active-1';
            self.externalBtnActive = '';
            self.depProAreShow = true;
            self.fieldDisabled = true;
            self.showUserSelect = true;
            self.innerAssignment();
        } else if (btn == 'external') {
            self.innerBtnActive = '';
            self.externalBtnActive = 'active-1';
            self.depProAreShow = false;
            self.fieldDisabled = false;
            self.showUserSelect = false;
            self.externalClear();
        }
    };
    self.externalClear = function () {
        initFieldList();
        self.fieldList.userType = 'external';
        self.fieldList.signerVersion.selected = self.optionList.signerVersion[0];
        self.fieldList.type = self.optionList.type[0].value;
    };
    self.innerAssignment = function () {
        initFieldList();
        if(self.optionList.user && self.optionList.user.length){
            self.fieldList.user_select.selected = self.optionList.user[0];
            self.userChange(self.optionList.user[0]);
        }
        if(self.optionList.department && self.optionList.department.length){
            self.fieldList.department.selected = self.optionList.department[0];
            self.departmentChange(self.optionList.department[0]);
        }
    };
    self.departmentChange = function (selected) {
        if(type=='new') {
            self.fieldList.project = {
                selected: ''
            };
        }
        self.depProData.forEach((v, i) => {
            if (v.domainUid == selected.value) {
                let temp = [];
                v.projects.forEach((value, index) => {
                    if(v.domainUid=="default"&&value.projectName=="admin"){
                        value.pName = $translate.instant("aws.common.defaultProject")
                    }else{
                        value.pName = value.projectName
                    }
                    temp.push({
                        value: value.projectId,
                        text: value.pName
                    })
                });
                self.optionList.project = temp;
                if(type=='new') {
                    self.fieldList.project.selected = self.optionList.project[0]
                }
            }
        });
    };
    self.accessKeyChange = function(selected) {
        self.fieldList.secretKey = selected.secretKey;
    };
    self.userChange = function (selected) {
        self.optionList.accessKey = [];
        selected.rgwCredentials.forEach(it=>{
            self.optionList.accessKey.push({
                value:it.accessKey,
                text:it.accessKey,
                secretKey:it.secretKey
            });
        });
        self.fieldList.accessKey_select.selected = self.optionList.accessKey[0];
        self.fieldList.secretKey = self.fieldList.accessKey_select.selected.secretKey;
        self.fieldList.endpoint = selected.endpoint;
        self.fieldList.signerVersion = {
            selected: {
                value: selected.singerVersion,
                text: selected.singerVersion
            }
        };
        self.fieldList.type = selected.type;
    };
    objectStorageSettingSrv.getSelectOptions().then(function (res) {
        if (res && res.data && res.data.length) {
            res.data.forEach((v, i) => {
                if (v.paramName == 'SINGER_VERSION') {
                    v.paramValue.split(',').forEach((value, index) => {
                        self.optionList.signerVersion.push({
                            value: value,
                            text: value
                        })
                    });
                } else if (v.paramName == 'TYPE') {
                    v.paramValue.split(',').forEach((value, index) => {
                        self.optionList.type.push({
                            value: value,
                            text: value
                        })
                    });
                }
            });
        }
    });
    objectStorageSettingSrv.getUsers().then(res => {
        if (res.data.length) {
            self.optionList.user = res.data
            // 点击新建select默认选中第一项
            if (type == 'new') {
                self.fieldList.user_select.selected = self.optionList.user[0];
                self.userChange(self.optionList.user[0]);
            }
            if (type == 'edit' && editItem[0].source==2) {
                res.data.forEach(it=>{
                    if(it.userId==editItem[0].user) {
                        it.rgwCredentials.forEach(item=>{
                            self.optionList.accessKey.push({
                                value:item.accessKey,
                                text:item.accessKey,
                                secretKey:item.secretKey
                            });
                        });
                    }
                });
                self.optionList.accessKey.push({
                    value:editItem[0].accessKey,
                    text:editItem[0].accessKey,
                    secretKey:editItem[0].secretKey
                });
            }
        }
    });
    objectStorageSettingSrv.getDepPro().then(res => {
        if (res && res.data && res.data.length) {
            self.depProData = res.data;
            res.data.forEach((v, i) => {
                if(v.domainUid=="default"){
                    v.domainName = $translate.instant("aws.common.defaultDepar")
                }
                self.optionList.department.push({
                    value: v.domainUid,
                    text: v.domainName
                })
            });
            // 点击新建select默认选中第一项
            if (type == 'new') {
                self.fieldList.department.selected = self.optionList.department[0];
            }
            self.departmentChange(self.fieldList.department.selected)
        }
    });
    
    self.modalConfirm = function (form) {
        self.submitValid = false;
        if (form.$valid) {
            let userType = self.fieldList.userType == "inner" ? 2 : 3;
            let fieldList = self.fieldList
            let params = {
                name: fieldList.name,
                accessKey: userType==2?fieldList.accessKey_select.selected.value:fieldList.accessKey,
                secretKey: fieldList.secretKey,
                endpoint: fieldList.endpoint,
                signerVersion: fieldList.signerVersion.selected.text,
                source: userType,
                type: fieldList.type,
                user: userType == 2 ? fieldList.user_select.selected.userId : fieldList.user
            };
            params['projectUid'] = userType==2?fieldList.project.selected.value:null;
            if (type == 'edit') {
                params['id'] = editItem[0].id;
                params['enterpriseUid'] = localStorage.enterpriseUid;
                params['regionUid'] = localStorage.regionUid;
                params['createTime'] = new Date().getTime();
                objectStorageSettingSrv.editSettingData(params).then(res => {
                    refresh();
                });
            }else {
                objectStorageSettingSrv.saveSettingData(params).then(res => {
                    refresh();
                });
            }
            // 关闭模态框
            $uibModalInstance.close();
        } else {
            self.submitValid = true;
        }
    }
}]);
export default objectStorageSettingModule.name;