import "./sysBackupSrv";

var sysBackupModule = angular.module("sysBackupModule", ["sysBackupSrvModule"]);

sysBackupModule.controller("sysBackupCtrl", ["$scope","sysBackupSrv",'TableCom',"$translate",
    function($scope,sysBackupSrv,TableCom,$translate){
        let self = $scope;

        // 获取列表
        self.initData = function() {
            sysBackupSrv.getDataList().then(function(res){
                if(res && res.data){
                    self.dataList = res.data;
                    self.dataList.map(item => {
                        item.currentBackupTime  = item.bakTime ? moment(item.bakTime).format('YYYY-MM-DD HH:mm:ss') : '';
                        item.nextBackupTime  = item.nextBakTime ? moment(item.nextBakTime).format('YYYY-MM-DD HH:mm:ss') : '';
                    })
                    TableCom.init(self, "tableParams", self.dataList, "id", 999);
                }
            });
        };

        self.globalSearchFunc = function(data) {
            data = data.filter(item => {
                if (self.globalSearchTerm) {
                    return item.bakName.indexOf(self.globalSearchTerm) > -1
                } else {
                    return true
                }
            })
            return data;
        }

        // 前端搜索
        self.applyGlobalSearch = function() {
            let data = angular.copy(self.dataList);
            if (!data) return;
            data = self.globalSearchFunc(data);
            self.tableParams.data = data; //设置过滤后的数据
            self.tableParams.settings().dataset = data;
            self.tableParams.reload()
        }

        // 刷新列表
        self.refresh = function() {
            self.globalSearchTerm = '';
            self.initData();
        }

        // 一键备份
        self.onekeyBackup = function() {
            sysBackupSrv.onekeyBackup().then(()=>{
                self.initData();
            })
        }
        // 状态翻译
        self.statusTrans = function(val){
            let result = '';
            switch (val) {
                case 1:
                    result = $translate.instant("aws.system.sysBackup.issuing");
                    break;
                case 2:
                    result = $translate.instant("aws.system.sysBackup.issued");
                    break;
                case 3:
                    result = $translate.instant("aws.system.sysBackup.received");
                    break;
                case 4:
                    result = $translate.instant("aws.system.sysBackup.backuping");
                    break;
                case 5:
                    result = $translate.instant("aws.system.sysBackup.success");
                    break;
                case 6:
                    result = $translate.instant("aws.system.sysBackup.fail");
                    break;
                case 11:
                    result = $translate.instant("aws.system.sysBackup.sendFailed");
                    break;
                default:
                    break;
            }
            return result;
        }

        self.initData();
    }
]);
export default sysBackupModule.name;