import "./createInsSrv";
import "../../cvm/instances/labelSrv"
import {
    easyCtrl
} from "../../easyReform/controllers/easyCtrl";
import { viewSecRuleCtrl } from './viewSecRuleCtrl';
let createInsModule = angular.module("definedInsModule", ["commonFuncModule", "createInsSrvModule", "ngFileSaver","labelMSrv"]);
function defineCreateInsCtrl(self, $rootScope, NgTableParams, $translate, alertSrv, utilTool, $location, $routeParams, 
    $uibModal, flavorsSrv, imagesSrv, snapshotsDataSrv, checkQuotaSrv,securityGroupSrv, networksSrv, instancesSrv, 
    commonFuncSrv, keyPairsSrv, FileSaver, Blob,createInsSrv,$timeout,labelMSrv) {
    self.currStep = 1;
    self.submitInValid = false;
    self.showPrice = false;
    self.regionName = localStorage.regionName;
    self.createInsForm.ephemeral = "";
    //PaaS服务
    self.supportCloudSecurity?self.cloud_security = true:self.cloud_security = false;
    self.supportCOC?self.COC = true:self.COC = false;
    self.interacted = function(field) {
        if(field && field.ip_0 && field.ip_1 && field.ip_2 && field.ip_3 && self.field_form){
            self.field_form.step3InsForm = field;
            return self.submitInValid || field.ip_0.$dirty || field.ip_1.$dirty || field.ip_2.$dirty || field.ip_3.$dirty;
        }
    };
    if($rootScope.billingActive){
        self.showPrice = true;
    }
    self.maxDataDisk = {
        default:5,
        ide:2
    }


    self.curDataDiskMax = self.maxDataDisk.default;
    self.numaOcList = [
        // {
        //     text:$translate.instant("aws.instances.addinstances.nodePlaceholder.numaOc"),
        //     value:"",
        //     id:"cc"
        // },
        // {
        //     id:1,
        //     value:"preferred",
        //     text:"preferred"
        // },
        {
            id:2,
            value:"strict",
            text:"strict"
        }
    ]
    self.numaOc = {
        selected:self.numaOcList[0]
    }
    self.vcardc = {
        selected:""
    }
    self.numaNodeList = [];
    self.numaNode = {
        selected:{
            text:$translate.instant("aws.instances.addinstances.nodePlaceholder.numaNode"),
            value:"",
            id:"cc"
        }
    }
    self.bigmem = {
        selected:{
            text:$translate.instant("aws.instances.addinstances.nodePlaceholder.bigmemVal"),
            value:"",
            id:99
        },
    };
    self.bigmemList = [
        {
            text:$translate.instant("aws.instances.addinstances.nodePlaceholder.bigmemVal"),
            value:"",
            id:99
        },
        {
            text:"2MB",
            value:"2MB",
            id:1
        },
        {
            text:"1GB",
            value:"1GB",
            id:2
        },
        {
            text:"small",
            value:"small",
            id:3
        },
        {
            text:"large",
            value:"large",
            id:4
        },
        {
            text:"any",
            value:"any",
            id:5
        }
    ],
    self.setNamaList = [];
    self.numaSetCache = {};
    self.allCardList = [];
    self.cupMapList = [];
    self.canNumaCard = false;
    self.canNuma = false;
    self.canChangeNode = false;
    //切换虚拟网卡
    self.changeVcardc = function(item){
        if(item.bindingVnicType=="direct"){
            self.canNumaCard = true;
            self.canChangeNode = true;
            self.node.selected = self.node.list[0];
            //切换节点，清除之前节点缓存的numa高级设置
            self.numaSetCache = {};
            self.numaNodeList = [];
            self.numaNode.selected = ""
            let data = {
                enterpriseUid:localStorage.enterpriseUid,
                regionUid:localStorage.regionUid,
                data:{
                    netWorkId:item.id
                }
            }
            instancesSrv.getNumaMaxCard(data).then(res=>{
                if(res&&res.data){
                    let num = res.data.numa_node;
                    self.cupMapList = res.data.numa_cpu;
                    //let num = 5;
                    for(let i=1;i<num+1;i++){
                        self.numaNodeList.push({
                            id:i,
                            value:i,
                            text:i
                        })
                    }
                    self.numaNodeList.unshift({
                        text:$translate.instant("aws.instances.addinstances.nodePlaceholder.numaNode"),
                        value:"",
                        id:"cc"
                    })
                    self.numaNode.selected = self.numaNodeList[0]
                }
            })




        }else{
            self.canNumaCard = false;
            self.canChangeNode = false;//当选择直连时，不能指定节点
        }
    }
    //获取虚拟网卡
    self.getPortData = function(){
        instancesSrv.getPortList().then(res=>{
            if(res&&res.data){
                //self.vcardcList = res.data;
                self.vcardcList = res.data.filter(item=>{
                    item.bindingVncCtr = item.bindingVnicType=="direct"?$translate.instant('aws.instances.addinstances.isLink1'):$translate.instant('aws.instances.addinstances.isLink2')
                    item.macType = item.mac_address+"("+item.bindingVncCtr+")";
                    return !item.device_name
                })
                self.vcardcList.unshift({
                    macType:$translate.instant('aws.instances.addinstances.nodePlaceholder.vcardc'),
                    id:"",
                    bindingVnicType:""
                })
                self.allCardList = _.cloneDeep(self.vcardcList);
                self.vcardc.selected = self.vcardcList[0];
                self.changeVcardc(self.vcardcList[0]);
            }
        })
    }
    //切换节点
    self.changeCardNode = function(item){
        if(item.name){
            self.canNuma = true;
            //当指定节点时，只能选择非直连网卡
            self.vcardcList = self.allCardList.filter(it=>{
                return it.bindingVnicType!="direct"
            })
            self.vcardc.selected = self.vcardcList[0];

            //切换节点，清除之前节点缓存的numa高级设置
            self.numaSetCache = {};
            self.numaNodeList = [];
            self.numaNode.selected = ""
            let data = {
                enterpriseUid:localStorage.enterpriseUid,
                regionUid:localStorage.regionUid,
                hostName:item.name
            }
            instancesSrv.getNumaMax(data).then(res=>{
                if(res&&res.data){
                    let num = res.data.numa_node;
                    self.cupMapList = res.data.numa_cpu;
                    //let num = 5;
                    for(let i=1;i<num+1;i++){
                        self.numaNodeList.push({
                            id:i,
                            value:i,
                            text:i
                        })
                    }
                    self.numaNodeList.unshift({
                        text:$translate.instant("aws.instances.addinstances.nodePlaceholder.numaNode"),
                        value:"",
                        id:"cc"
                    })
                    self.numaNode.selected = self.numaNodeList[0]
                }
            })
        }else{
            self.canNuma = false;
            //当不指定节点时，可以选择所有可以使用的网卡
            self.vcardcList = _.cloneDeep(self.allCardList);
            //self.numaNodeList = [];
        }
    }
    //切换numa节点
    self.changeNumaNode = function(item){
        self.numaSetCache = {};
        self.setNamaList = [];
        // if(self.numaSetCache[item.value]&&self.numaSetCache[item.value].length>0){
        //     self.setNamaList = _.cloneDeep(self.numaSetCache[item.value]);
        // }else{
        //     self.setNamaList = [];
        // }
    }
    //numa高级设置 
    self.setNumConfig = function(item,isFalse){
        if(!isFalse&&item&&item.value){
            item.value = Number(item.value);
            //self.changeNumaNode(item);
            $uibModal.open({
                animation: true,
                templateUrl: "js/shortcuts/instances/tmpl/setnumconfig.html",
                controller: "setNumConfigCtrl",
                resolve: {
                    context:function(){
                        return self;
                    },
                    editData:function(){
                        return item
                    }
                }
            });
        }
    }
    //清除numa高级设置
    self.clearNumaSet = function(){
        self.numaSetCache = {};
        self.setNamaList = [];
    }

    //切换numa策略
    self.changenumaOc = function(data){
        self.setNamaList.forEach(item=>{
            
        })
    }
    //获取创建私有网络func
    easyCtrl(self, $rootScope, $translate, $uibModal, $location);
    
    //新建私有网络后，重新获取网络数据
    self.$watch(function() {
        return commonFuncSrv.reGetNetList;
    }, function(reGetNetList) {
        if (reGetNetList) {
            self = commonFuncSrv.setAssignIpFun(self, "definedInsNet", "step3InsForm","private");
            commonFuncSrv.reGetNetList = false;
        }
    }, true);
    
    self.$on("showPrice",function(f1,flag){
        if($rootScope.billingActive){
            self.showPrice = flag
        }
    })
    //获取flavor规格列表
    self.getInsFlavors = function() {
        flavorsSrv.getFlavors().then(function(result) {
            result ? self.loadFlavorData = true : "";
            if (result && result.data && angular.isArray(result.data)) {
                result.data.forEach(function(item) {
                    item.ram_gb = (item.ram / 1024).toFixed(1);
                    if (item.ram_gb.split(".")[1] == "0") {
                        item.ram_gb = item.ram_gb.split(".")[0];
                    }
                    item.ram_gb = Number(item.ram_gb);
                });
                
                // 过滤负载均衡增强型创建的规格
                self.flavorSpecific.flavorCopy = result.data.filter(item=>!(/^elb-flavor[0-9]+$/.test(item.name)));
                initFlavorSpecificCpu(result.data)
            }
        });
    };
    // 获取VGPU规格
    self.getVgpuType = function() {
        instancesSrv.getInstancesVgpuType().then(function(result) {
            if (result && result.data && angular.isArray(result.data)) {
                self.vgpuTypeList = result.data
            }
        });
    };
    self.changeVgpuType = function() {
        self.getVGpuByGpuType(self.createInsForm.vgpuType.vgpuType)
    }
    self.getVgpuType();
    // 获取GPU
    self.getVGpuByGpuType = function(vgpuType) {
        let data = {
            vgpuType:vgpuType
        }
        instancesSrv.getVGpuByGpuType(data).then(function(result) {
            if (result && result.data && angular.isArray(result.data)) {
                self.vgpuList = result.data
            }
        });
    };
    // self.getVGpuByGpuType();
    function initFlavorSpecificCpu(data){
        //data = self.filterFlavorFromBoot(data);
        self.flavorSpecific.cpu = [];
        data.map(item=>{
            if( self.flavorSpecific.cpu.indexOf(item.vcpus) == -1){
                self.flavorSpecific.cpu.push(item.vcpus)
            }
            return item
        })
        self.flavorSpecific.cpu.sort(function (x,y) {
            return x-y;
        })
        self.selectCpu(self.flavorSpecific.cpu[0])
    }

    function initFlavorSpecificRam(cpu){
        var dataset =[];
        //dataset = self.filterFlavorFromBoot(self.flavorSpecific.flavorCopy);
        dataset = self.flavorSpecific.flavorCopy
        self.flavorSpecific.ram = [];
        dataset.map(item => {
            if(item.vcpus == cpu){
                if( self.flavorSpecific.ram.indexOf(item.ram_gb) == -1){
                    self.flavorSpecific.ram.push(item.ram_gb)
                }
            }
        })
        self.flavorSpecific.ram.sort(function (x,y) {
            return x-y;
        })
        self.selectRam(self.flavorSpecific.ram[0])
    }

    function chooseCpuMemValidate(data,flag){
        self.getPrice(data.vcpus,data.ram_gb,self.currStep)
        self.testProQuotaFunc(data, self.insForm.hostNum);
        self.testDomQuotaFunc(data, self.insForm.hostNum);
    }
    function initFlavorsTable(){
        var data = self.flavorSpecific.flavorCopy.filter(item => (item.vcpus == self.flavorSpecific.cpuSelect && item.ram_gb == self.flavorSpecific.ramSelect));
        self.initFlavorTable(data)
        chooseCpuMemValidate(self.createInsForm.selectedFlavor);
    }
    
    self.changeFlavor = function(val) {
        val.text = val.name + "：" + val.vcpus + $translate.instant("aws.instances.conf.core") + val.ram + "GB ";
        self.createInsForm.selectedFlavor = val;
        chooseCpuMemValidate(self.createInsForm.selectedFlavor);
    };
    self.selectCpu = function(cpu){
        self.flavorSpecific.cpuSelect = cpu;
        initFlavorSpecificRam(cpu)
    }
    self.selectRam = function(ram){
        self.flavorSpecific.ramSelect = ram;
        initFlavorsTable();
       
    }
    //重写函数
    self.changeStorage = function(item){
        self.nomore_voltype = false;
        if(self.storage.storageDeviceSelected.name.indexOf("toyou")>-1){
            self.getToyouDevice(self.getToyouVolumeCharacter)
       }
        self.poolInfo(item,self.pollInfoCallBackFunc);
        self.changesysVolumeSize(self.createInsForm.sysVolumeSize);
    };

    self.checkAllVolumPoolFun = function() {
        self.getPrice(self.flavorSpecific.cpuSelect,self.flavorSpecific.ramSelect,2); //更新价格
        self.exceedPools = false;
        self.exceedPoolsText = "";
        let size_name = {};
        let array = self.createInsForm.dataDiskArray.filter(item => item.device.id != "has");
        array.map(val => {
            var data = self.poolDataTranslate(val.device);
            if(!size_name[val.device.volumeTypeId]){
                size_name[val.device.volumeTypeId] = {
                    size:0,
                    inUsed:Number(data[0]),
                    total:Number(data[1]),
                    name:val.device.disPlayName
                }
            } 
        })
        if(self.storage.isUseLocal ==2 && self.storage.storageDeviceSelected && size_name[self.storage.storageDeviceSelected.volumeTypeId]){
            switch(self.options.imgSrc){
                case "img":
                    size_name[self.storage.storageDeviceSelected.volumeTypeId].size += Number(self.createInsForm.sysVolumeSize||0)
                    break;
                case "snap":
                    size_name[self.storage.storageDeviceSelected.volumeTypeId].size += Number(self.createInsForm.selectedSnap.size||0)
                    break;
                case "backup":
                    size_name[self.storage.storageDeviceSelected.volumeTypeId].size += Number(self.createInsForm.selectedBackup.size||0)
                    break;
            }
        }
        array.map(item => {
            size_name[item.device.volumeTypeId].size += Number(item.size || 0);
        })
        for (var value in size_name){
            if(size_name[value].size*self.insForm.hostNum + size_name[value].inUsed >  size_name[value].total){
                self.exceedPools = true;
                self.exceedPoolsText = size_name[value].name
                break;
            }
        }

        // for (let value of Object.values(size_name)) {
        //     if(value.size*self.insForm.hostNum + value.inUsed >  value.total){
        //         self.exceedPools = true;
        //         self.exceedPoolsText = value.name
        //         break;
        //     }
        // }
        
    };
    self.$on("initFlavor",function(e){
        if(self.flavorSpecific.flavorCopy){
            self.chooseBoot(self.storage.isUseLocal)
        }
    })
    
    self.chooseBoot = function(uselocal){
        if(self.source.fromSnapshot || self.source.fromVolume || self.source.fromBackup) return;
        self.storage.isUseLocal = uselocal;
        self.options.imgSrc = "img";
        if(self.storage.isUseLocal == 2){
            self.storage.storageDeviceSelected = self.storageDeviceList[0];
            self.poolInfo(self.storage.storageDeviceSelected,self.pollInfoCallBackFunc);
            //self.changesysVolumeSize(self.arch.selected.size) 
            self.changesysVolumeSize(self.createInsForm.sysVolumeSize); //校验资源是否足够
        }else if(self.storage.isUseLocal == 1){
            if($location.$$search.from&&$location.$$search.from!='image'){
                self.storage.storageDeviceSelected = "";
            }else if($location.$$search.from&&$location.$$search.from =='image'){
                self.createInsForm.sysVolumeSize = self.arch.selected.size;    
            }
            
            
            self.getCacheVolume(self.arch.selected,""); //校验缓存盘
            self.getBatchVolum(self.arch.selected,"");
            self.createInsForm.dataDiskArray = [];
            self.changesysVolumeSize(0);
            self.choseImgSrc(self.options.imgSrc);
        }
        //initFlavorSpecificCpu(self.flavorSpecific.flavorCopy)
    }

    self.clickStep = function(nextstep, ...form) {
        return;
        let validFormCount = 0;
        self.submitInValid = false;
        if ((nextstep - self.currStep) > 0) {
            if (self.insProText || self.coreProText || self.ramProText || self.volumesProText || self.gigabytesProText
                || self.insDomText || self.coreDomText || self.ramDomText || self.volumesDomText || self.gigabytesDomText
                || !self.canVolum  || self.exceedPoolsText || !self.canBatchNoCeph || !self.canBatch || self.storage.isUseLocal == 0) {
                return;
            }
            if(self.currStep == 2 && ((self.options.imgSrc=='img' && !self.arch.selected) 
                || (self.options.imgSrc=='snap' && !self.createInsForm.selectedSnap) 
                || (self.options.imgSrc=='backup' && !self.createInsForm.selectedBackup) 
                || (self.options.imgSrc=='volume' &&  !self.createInsForm.selectedVolume)
                || (self.options.imgSrc=='img' && self.nomore_voltype)
                || (self.options.imgSrc=='volume' && self.insForm.hostNum>1)
                || (self.options.imgSrc=='backup' && self.insForm.hostNum>1)
            )){
                return;
            }
            for (let i = 0; i < form.length; i++) {
                if (!form[i].$valid) {
                    self.submitInValid = true;
                    return;
                    break;
                } else {
                    validFormCount++;
                }
            }
            if (validFormCount == form.length) {
                self.currStep = nextstep;
            }
        } else {
            self.currStep = nextstep;
            self.submitInValid = false;
        }
        if (self.insForm.hostNum > 1) {
            self.definedInsNet.assignIP = false;
        }
        if(self.currStep == 2){
            self.testVolumes()
        }
    };

    //点击下一步
    self.clickNextStep = function(step, currform) {
        //self.currStep = step;
        self.submitInValid = false;
        switch (step) {
            case 1:
                self.currStep = step;
                // 回到第一步重新计算价格 更新价格
                self.getPrice(self.flavorSpecific.cpuSelect,self.flavorSpecific.ramSelect,1);
                break;
            case 2:
                if (currform) {
                    if (currform.$valid) {
                        self.getVgpuNode();
                        self.currStep = step;
                        if (self.options.canJoinNode) {
                            return;
                        }
                        if(self.storage.isUseLocal==1){
                            self.checkVolumFun(0)
                            self.getCacheVolume(self.arch.selected,"");
                            self.getBatchVolum(self.arch.selected,"");
                        }else if(self.storage.isUseLocal==2){
                            if(self.source.new){
                                // 注释掉  有bug, 点到第二步选择了其他镜像之后 回到上一步 ，再回到下一步；默认回显第一个镜像 ，和系统盘回显的size不一致;应该回显之前选中的镜像   
                                // self.arch.selected = self.InsImagesList[0];
                                // self.createInsForm.chkImageId = self.arch.selected.imageUid;
                            }
                            self.poolInfo(self.storage.storageDeviceSelected,self.pollInfoCallBackFunc);  
                        }
                        self.checkAllVolumPoolFun();
                        
                    } else {
                        self.submitInValid = true;
                    }
                } else {
                    self.currStep = step;
                }
            break;
                
            case 3:
                if (currform) {
                    if (currform.$valid) {
                        self.currStep = step;
                        if (step == 3) {
                            if(self.insForm.hostNum >1){
                                self.definedInsNet.assignIP = false;
                            }
                            if((self.options.imgSrc=='img'&& self.arch.selected.os_type == 'windows')
                            || (self.options.imgSrc=='volume'&&self.createInsForm.selectedVolume.osType == 'windows')
                            || (self.options.imgSrc=='snap'&& self.createInsForm.selectedSnap.osType == 'windows')
                            || (self.options.imgSrc=='backup'&&self.createInsForm.selectedBackup.osType == 'windows')){
                                self.cloud_security = false;
                                self.COC = false;
                            }else{
                                self.supportCloudSecurity?self.cloud_security = true:self.cloud_security = false;
                                self.supportCOC?self.COC = true:self.COC = false;
                            }
                            
                            
                        }
                        
                        
                        
                    } else {
                        self.currStep = step - 1;
                        self.submitInValid = true;
                    }
                } else {
                    self.currStep = step;
                }
                break;
            case 4:
                if (currform) {
                    if(self.node.selected.name||self.vcardc.selected.bindingVnicType=="direct"){
                        if(self.numaNode.selected.value||self.bigmem.selected.value){
                            let data = {
                                numaNode:self.numaNode.selected.value,
                                hugePage:self.bigmem.selected.value,
                                hostName:self.node.selected.name,
                                enterpriseUid:localStorage.enterpriseUid,
                                regionUid:localStorage.regionUid,
                                netWorkId:self.vcardc.selected.id
                            }
                            instancesSrv.checkNumaMem(data).then(res=>{
                                if(res&&res.data){
                                    return res.data.flag;
                                }else{
                                    return false;
                                }
                            }).then(res=>{
                                if(res){
                                    nextStepFour(step, currform)
                                }
                            })
                            // .finally(()=>{
                            //     nextStepFour(step, currform)
                            // })
                        }else{
                            nextStepFour(step, currform)
                        }
                    }else{
                        nextStepFour(step, currform)
                    }
                    function nextStepFour(step, currform){
                        if(!self.definedInsNet.assignSub){
                            self.definedInsNet.assignIP  = false;
                        }
                        if (self.definedInsNet.assignIP) {
                            self.setCheckValueFunc();
                            if (currform.$valid) {
                                if(self.definedInsNet.ipVersion==4){
                                    self.createInsForm.fixed_ip = self.definedInsNet.init_cidr.ip_0 + "." + self.definedInsNet.init_cidr.ip_1 + "." + self.definedInsNet.init_cidr.ip_2 + "." + self.definedInsNet.init_cidr.ip_3;
                                }else{
                                    self.createInsForm.fixed_ip = self.definedInsNet.v6List.fixedIp;
                                }
                                self.definedInsNet.repeatIp = false;
                                let existedIps = [];
                                networksSrv.getNetworksDetailNew(self.definedInsNet.selectedNet.id).then(function(res) {
                                    if (res && res.data) {
                                        _.each(res.data, function(item) {
                                            _.each(item.subnetIps, function(sub) {
                                                existedIps.push(sub.ip_address);
                                            })
                                        })
                                        if (_.include(existedIps, self.createInsForm.fixed_ip)) {
                                            self.definedInsNet.repeatIp = true;
                                        }
                                        if (currform.$valid && !self.definedInsNet.repeatIp) {
                                            self.currStep = step;
                                        } else {
                                            self.currStep = step - 1;
                                            self.submitInValid = true;
                                        }
                                    }
                                });                                                     
                            } else {
                                self.submitInValid = true;
                            }
                        } else {
                            if (currform.$valid) {
                                self.currStep = step;
                            } else {
                                self.currStep = step - 1;
                                self.submitInValid = true;
                            }
                        }
                    }
                    
                } else {
                    self.currStep = step;
                }
                break;
        }
    };
    self.getInsFlavors();
    
    //选择镜像源
    self.choseImgSrc = function(src) {
        if((src=='volume' || src=='backup') && self.insForm.hostNum>1) return;
        if((src=='volume' || src=='snap' || src=='backup') && self.storage.isUseLocal==1) return;
        self.options.imgSrc = src;
        if($location.$$search.from&&$location.$$search.from!='image'){
            self.createInsForm.sysVolumeSize = "";
            self.storage.storageDeviceSelected = {};
            self.createInsForm.sysVolumeMinSize = "";
        }
        
        self.submitInValid = false;
        self.createInsForm.noAvalDisk = false;
        switch(src){
            case "img":
                if (self.insImgTable && self.insImgTable.data && self.insImgTable.data[0]) {
                    //self.createInsForm.image_id = self.insImgTable.data[0].imageUid;
                    self.storage.storageDeviceSelected = self.storageDeviceList[0];
                    self.arch.selected = self.insImgTable.data[0];
                    self.poolInfo(self.storage.storageDeviceSelected,self.pollInfoCallBackFunc);
                    self.changeImg(self.arch.selected)
                }
            break;
            case "snap":
                self.snapshotsSearchFunc('refresh');
                // if (self.insSysSnapTable && self.insSysSnapTable.data && self.insSysSnapTable.data[0]) {
                //     self.createInsForm.snap_id = self.insSysSnapTable.data[0].id;
                //     self.createInsForm.selectedSnap = self.insSysSnapTable.data[0];
                //     self.changeSnap(self.createInsForm.selectedSnap)
                // }
            break;
            case "volume":
                //点击选择源需要重新刷新列表
                self.volumeSearchFunc('refresh');
                // if(self.insSysVolumeTable && self.insSysVolumeTable.data && self.insSysVolumeTable.data[0]){
                //     self.createInsForm.volume_id = self.insSysVolumeTable.data[0].id;
                //     self.createInsForm.selectedVolume = self.insSysVolumeTable.data[0];
                //     self.changeVolume(self.createInsForm.selectedVolume)
                // }
            break;
            case "backup":
                self.storage.storageDeviceSelected = self.storageDeviceList[0];
                self.poolInfo(self.storage.storageDeviceSelected,self.pollInfoCallBackFunc);
                if(self.insSysBackupTable && self.insSysBackupTable.data && self.insSysBackupTable.data[0]){
                    self.createInsForm.backup_id = self.insSysBackupTable.data[0].id;
                    self.createInsForm.selectedBackup = self.insSysBackupTable.data[0];
                    self.changeBackup(self.createInsForm.selectedBackup )
                }
            break;

        }
    };
   
    
    self.changeImg = function(image) {
        if(self.options.imgSrc != "img") return;
        var imageCopy = angular.copy(image);
        if((image.hw_disk_bus=='ide'&&!self.createInsForm.disk_bus.id)||(self.createInsForm.disk_bus.id=='ide')){
            self.curDataDiskMax = self.maxDataDisk.ide;
            if(self.createInsForm.dataDiskArray.length>2){
                self.createInsForm.dataDiskArray = self.createInsForm.dataDiskArray.filter((item,index)=>{return index<2})
            }
        }else{
            self.curDataDiskMax = self.maxDataDisk.default;
        }
        self.createInsForm.chkImageId = image.imageUid;
        self.arch.selected = imageCopy;
        self.createInsForm.sysVolumeMinSize = self.arch.selected.size || 0;
        self.createInsForm.sysVolumeSize = self.arch.selected.size || 0;
        if(self.storage.isUseLocal==2){
            self.getCacheVolume(image,self.storage.storageDeviceSelected);
            self.getBatchVolum(image,self.storage.storageDeviceSelected);
            self.checkVolumFun(self.arch.selected.size * self.insForm.hostNum || 0);
        }
        self.allDataVolumeSize()
    };

    self.changeDiskBus = function(data){
        if(data.id=='ide'||(self.arch&&self.arch.selected&&self.arch.selected.hw_disk_bus=='ide'&&!data.id)){
            self.curDataDiskMax = self.maxDataDisk.ide;
            if(self.createInsForm.dataDiskArray.length>2){
                self.createInsForm.dataDiskArray = self.createInsForm.dataDiskArray.filter((item,index)=>{return index<2})
            }
        }else{
            self.curDataDiskMax = self.maxDataDisk.default;
        }
    }

    self.changeSnap = function(snap) {
        if(self.options.imgSrc != "snap") return;
        self.createInsForm.selectedSnap = snap;
        self.storage.storageDeviceSelected = snap.storageInfo;
        self.createInsForm.sysVolumeMinSize = self.createInsForm.selectedSnap.size;
        self.poolInfo(self.storage.storageDeviceSelected,self.pollInfoCallBackFunc);
        self.checkVolumFun(self.createInsForm.selectedSnap.size * self.insForm.hostNum);
        self.allDataVolumeSize()
    };

    self.changeBackup = function(backup){
        if(self.options.imgSrc != "backup") return;
        self.createInsForm.selectedBackup = backup;
        self.checkVolumFun(self.createInsForm.selectedBackup.size * self.insForm.hostNum);
        self.allDataVolumeSize()
    }

    //过滤掉数据盘选择中的Image所选的卷
    self.changeVolume = function(volume){
        if(self.options.imgSrc != "volume") return;
        self.createInsForm.selectedVolume = volume;
        self.storage.storageDeviceSelected = volume.storageInfo;
        self.createInsForm.dataDiskArray = [];
        self.poolInfo(self.storage.storageDeviceSelected,self.pollInfoCallBackFunc);
        self.checkVolumFun(0);
        self.allDataVolumeSize()
    }

    //添加数据盘
    self.addDataVolumes = function(){
        if(self.createInsForm.dataDiskArray.length >= self.curDataDiskMax || self.createInsForm.avalDiskCopy==undefined || !self.createInsForm.getAvalDisk) return;
        self.createInsForm.avalDisk = self.filterVolumeSelects()
        self.createInsForm.dataDiskArray.push({device:self.storageDeviceListCopy[0],volume:self.createInsForm.avalDisk[0]})
        self.allDataVolumeSize();
        
    }
    //删除数据盘
    self.delDataVolumes = function(ind){
        self.createInsForm.dataDiskArray = self.createInsForm.dataDiskArray.filter((item,index) => index != ind);
        self.createInsForm.avalDisk = self.filterVolumeSelects();
        self.allDataVolumeSize();
    }

    //切换数据盘存储类型
    self.changeDataVolumeType = function(val,index){
        if((self.insForm.hostNum>1 && val.id=="has") || (self.createInsForm.avalDisk.length == 0 && val.id=="has")){
            self.createInsForm.dataDiskArray[index]={device:self.storageDeviceListCopy[0],volume:self.createInsForm.avalDisk[0],size:self.createInsForm.dataDiskArray[index].size};
        }
        if(self.insForm.hostNum ==1 && val.id=="has" && self.createInsForm.avalDisk.length){
            self.createInsForm.dataDiskArray[index].size = self.createInsForm.dataDiskArray[index].volume.size;
        }
        self.createInsForm.avalDisk = self.filterVolumeSelects()
        self.allDataVolumeSize();
    }
    self.changeDataVolume = function(volume,index){
        self.createInsForm.dataDiskArray[index].size = volume.size;
        self.createInsForm.avalDisk = self.filterVolumeSelects()
        self.allDataVolumeSize(self.createInsForm.sysVolumeSize);
    }
    //切换数据盘已有盘,更新所有下拉框可选数据
    self.filterVolumeSelects = function(){
        self.createInsForm.noAvalDisk = false;
        var avalDisk = angular.copy(self.createInsForm.avalDiskCopy);
        if(self.options.imgSrc == "volume" && self.createInsForm.selectedVolume && self.createInsForm.selectedVolume.uid){
            avalDisk = self.createInsForm.avalDiskCopy.filter(item => item.id != self.createInsForm.selectedVolume.uid)
        }
        self.createInsForm.dataDiskArray.map(chk => {
            avalDisk = avalDisk.filter(item=>{
                return ((chk.device.id == "has" && item.id != chk.volume.id) || chk.device.id != "has")
            })
        })
        if(avalDisk.length==0){
            self.createInsForm.noAvalDisk = true;
        }
        self.createInsForm.dataDiskArray.map(item =>{
            if(!item.device.id) item.volume = avalDisk[0];
        })
        return avalDisk;
    }

    //获取所有数据盘的总大小
    self.allDataVolumeSize = function(){
        var size = 0;
        self.createInsForm.dataDiskArray.map((item,index) => {
            item.size?size+=Number(item.size):"";
            self.createInsForm["avalDisk"+index] = angular.copy(self.createInsForm.avalDisk);
            var ishasvolume = false 
            self.createInsForm["avalDisk"+index].map(vol =>{
                if (vol.id == item.volume.id) ishasvolume = true;
            })
            if(!ishasvolume && item.device.id == 'has') self.createInsForm["avalDisk"+index].unshift(item.volume);
        });
        self.createInsForm.dataVolumeSize = size;
        self.testVolumes();
        self.checkAllVolumPoolFun()
    }
    self.changesysVolumeSize = function(size){ //size为系统盘的大小
        self.checkVolumFun(size * self.insForm.hostNum); //校验资源池
        self.testVolumes();   //校验云硬盘相关的配额
        self.checkAllVolumPoolFun()  //系统盘+数据盘校验资源池
    }

    // 生成随机密码
    // 密码长度为8-16个字符且至少包含一个大写字母，一个小写字母，一个数字和一个特殊字符。特殊字符包括! - @ . ^ _ % , : /
    self.randomPwd = function() {
        const pwd = utilTool.randomPwd(8,16);
        self.createInsForm.admin_pass = pwd;
        self.createInsForm.admin_comfirmpass = pwd;
    }

    // 复制到剪切板
    self.copyToClip = function(copyTxt){
        utilTool.copyToClip(copyTxt);
        alertSrv.set("", $translate.instant("aws.instances.addinstances.copySuccess") ,"success",3000);
    }
    
    //创建自定义规格
    self.myEasyFlavor = function(){
        //self.easyFlavor(self.getInsFlavors)
        var newFlavorModel = $uibModal.open({
            animation: true,
            templateUrl: "js/system/tmpl/newFlavor.html",
            controller: "newFlavorCtrl",
            resolve:{
                type:function(){
                    return "vmHost"
                },
                refreshFlavors:function(){
                    return self.getInsFlavors
                },
                fromIns:function(){
                    return self.storage.isUseLocal
                }
            }
        });
    }
    //创建密钥对
    self.createKeyPair = function() {
        var scope = self.$new();
        scope.newKeyPairData = {};

        var newKeyPairModal = $uibModal.open({
            animation: true,
            templateUrl: "newKeyPair.html",
            scope: scope
        });

        scope.submitted = false;
        scope.interacted = function(field) {
            scope.field_form = field;
            return scope.submitted || field.$dirty;
        };
        scope.confirmNewKeypair = function(kpData) {
            if (scope.field_form.$valid) {
                scope.formSubmitted = true;
                keyPairsSrv.createKeyPair(kpData).then(function(result) {
                    if (result && result.data) {
                        self.doDownload(result.data);
                        self.getKeypairs();
                    }
                });
                newKeyPairModal.close();
            } else {
                scope.submitted = true;
            }
        };
    };
    //下载密钥对
    self.doDownload = function(data) {
        var scope = self.$new();
        var downloadInstance = $uibModal.open({
            animation: true,
            templateUrl: "downloadPem.html",
            scope: scope
        });
        scope.download = function () {
            var pemData = new Blob(
                [data.private_key],
                { type: "application/x-pem-file"}
            );
            FileSaver.saveAs(pemData, data.name + ".pem");
            downloadInstance.close();
        };

    };
    //创建安全组
    self.createSecurityGroup = function(){
        self.easySecurityGroup(self)
    }
    //查看安全组规则详情
    self.viewSecRule = function(securityGroup) {
        self.viewSecRuleModal = $uibModal.open({
            animation: self.animationsEnabled,
            templateUrl: "js/shortcuts/instances/tmpl/secRuleDetail.html",
            controller: "viewSecRuleCtrl",
            resolve: {
                context: () => self,
                securityGroup: () => securityGroup
            }
        })
    }
    
    //创建自定义云主机
    self.createDefinedInsCfm = function() {
        let newLableList = [];
        if(self.labelobj.labelArr.length == 10){
            newLableList = self.labelobj.labelArr
        }else {
            newLableList = self.labelobj.labelArr.filter((item,index)=>{
                return index != self.labelobj.labelArr.length - 1 
            })
        }
        var dataVolumes = [];
        if(self.storage.isUseLocal==1){
            dataVolumes = [{dataVolumeSize:Number(self.createInsForm.ephemeral || 0 )}]
        }else if(self.storage.isUseLocal==2){
            self.createInsForm.dataDiskArray.map(item => {
                if(item.device.id == "has"){
                    dataVolumes.push({dataVolumeId:item.volume.id})
                }else{
                    dataVolumes.push({dataVolumeType:item.device.volumeTypeId,dataVolumeSize:item.size})
                }
            })
        }
        
        let postData = {
            name: self.createInsForm.name,
            count: self.insForm.hostNum,
            admin_pass: self.createInsForm.admin_pass || "",
            network_id: self.definedInsNet.selectedNet.id,
            keypair_id: self.keypairs.selected ? self.keypairs.selected.name : "",
            use_local: self.storage.isUseLocal == 2? false : true,
            hostname: self.createInsForm.hostname,
            flavor: self.createInsForm.selectedFlavor.id,
            security_id: self.securities.selected ? self.securities.selected.id : "",
            dataVolumes:dataVolumes,
            block_device_mapping:{"disk_bus":self.createInsForm.disk_bus.id},
            cloud_security:self.cloud_security,
            coc:self.COC,
            port: self.vcardc.selected.id?self.vcardc.selected.id:"",
            // 选择VGPU
            vgpuType:self.createInsForm.vgpuType?self.createInsForm.vgpuType.vgpuType:"",
            vgpuId:self.createInsForm.vgpuName?self.createInsForm.vgpuName.id :""

        };
        if(self.cloud_security){
            if(self.supportPass&&self.supportPass.CloudSecurity && self.supportPass.CloudSecurity.isLinked){
                postData.agent_url = self.supportPass.CloudSecurity.agentUrl;
            }
        }
        switch(self.options.imgSrc){
            case "img":
                postData.os_type = self.arch.selected.os_type;
                postData.image_id = self.arch.selected.imageUid;
                postData.volumeSize =  Number(self.createInsForm.sysVolumeSize);
                if(self.cloud_security){
                    postData.arch = self.arch.selected.arch;
                }
                if(self.storage.isUseLocal==2){
                    postData.volume_type = self.storage.storageDeviceSelected.volumeTypeId;
                }
                break;
            case "snap":
                postData.snapshot_id = self.createInsForm.selectedSnap.uid;
                postData.block_device_mapping.deleteOnTermination = true;
                if(self.cloud_security){
                    postData.arch = self.createInsForm.selectedSnap.architecture;
                }
                break;
            case "volume":
                postData.volumeId = self.createInsForm.selectedVolume.uid;
                if(self.cloud_security){
                    if(self.createInsForm.selectedVolume){
                        postData.arch = self.createInsForm.selectedVolume.architecture
                    }
                }
                break;
            case "backup":
                postData.backupId = self.createInsForm.selectedBackup.id;
                if(self.cloud_security){
                    postData.arch = self.createInsForm.selectedBackup.architecture;
                }
                if(self.storage.isUseLocal==2){
                    postData.volume_type = self.storage.storageDeviceSelected.volumeTypeId;
                }
                break;

        }
        if(self.createInsForm.vmGroupSelected && self.createInsForm.vmGroupSelected.id){
            postData.groupId = self.createInsForm.vmGroupSelected.id;
        }
        
        if(self.definedInsNet.assignSub){
            postData.subnet_id = self.definedInsNet.selectedSubnet.id;
        }
        if (self.definedInsNet.assignIP) {
            postData.fixed_ip = self.createInsForm.fixed_ip;
        }
        self.zone.selected ? postData.availability_zone = self.zone.selected.zoneName : "";
        self.node.selected ? postData.node = self.node.selected.name : "";
        self.checkVolumFun();
        //高级设置 numa 大页内存 虚拟网卡
        if(self.vcardc.selected.bindingVnicType=="direct"||self.node.selected.name){
            let list = _.cloneDeep(self.numaSetCache[self.numaNode.selected.value]);
            let arr = [];
            if(!list){
                list = [];
            }
            arr = list.map(item=>{
                let cpu = item.cpu.map(ii=>{
                    return Number(ii.value)-1;
                })
                return {
                    "hw:numa_nodes":item.node.selected.value - 1,
                    "hw:numa_mempolicy":self.numaOc.selected.value,
                    "hw:numa_cpus":cpu.join(","),
                    "hw:numa_mem":item.mem,
                }
            })
            
            postData.numaParam = {
                paramMapList:arr,
                hugePage:self.bigmem.selected.value,
                numaMempolicy:self.numaOc.selected.value,
                nodeNum:self.numaNode.selected.value
            }

        }
        if(!self.bigmem.selected.value&&!self.numaNode.selected.value){
            delete postData.numaParam;
        }
        if (self.canVolum) {
            self.validForm = true;
            self.doubleClick = true;
            (async function(){
                const res = await instancesSrv.createServer(postData)
                if(res.msg == 'OK'){
                    if(newLableList.length != 0){
                        // 标签
                        const valueArr = newLableList.map(item=>{
                            const arr = self.labelobj.labelList.find(items=>items.value == item.value);
                            arr && (item.id = arr.id)
                            return {
                                id: item.id || '',
                                name: item.value,
                                description: ''
                            }
                        })
                        const config = {}
                        res.data.forEach(key => {
                            config[key] = valueArr;
                        })

                        await labelMSrv.instancesLabelUpdate(config)
                    }
                }
                if ($location.path() == "/cvm/netTopo") {
                    $timeout(function() {
                        $rootScope.initEditedTopo();
                    }, 1000);
                } else {
                    $location.url("/cvm/instances");
                    self.$emit("newIns")
                }
            })()


            // instancesSrv.createServer(postData).then(function(res){
            //     console.log(res)
            //     //判断是否是在网络拓扑页面新建云主机
            //     if ($location.path() == "/cvm/netTopo") {
            //         $timeout(function() {
            //             $rootScope.initEditedTopo();
            //         }, 1000);
            //     }else{
            //         $location.url("/cvm/instances");
            //         self.$emit("newIns")
            //     }
            // });
        } else {
            self.validForm = false;
        }

    };
    //自定义创建云主机配置详情
    self.$watch(function() {
        return $routeParams.id;
    }, function(value) {
        value ? self.animation = "animateIn" : self.animation = "animateOut";
    });
    
    function formatStep4DetailInfo() {
        self.createInsForm.fixed_ip = "自动分配";
        if (self.definedInsNet.assignIP) {
            if(self.definedInsNet.ipVersion==4){
                self.createInsForm.fixed_ip = self.definedInsNet.init_cidr.ip_0 + "." + self.definedInsNet.init_cidr.ip_1 + "." + self.definedInsNet.init_cidr.ip_2 + "." + self.definedInsNet.init_cidr.ip_3;
            }else{
                self.createInsForm.fixed_ip = self.definedInsNet.v6List.fixedIp;
            }
            //self.createInsForm.fixed_ip = self.definedInsNet.init_cidr.ip_0 + "." + self.definedInsNet.init_cidr.ip_1 + "." + self.definedInsNet.init_cidr.ip_2 + "." + self.definedInsNet.init_cidr.ip_3;
        }
    }

    self.setCheckValueFunc1 = function(){
        formatStep4DetailInfo()
    }

    self.changeAssignIP = function(value){
        if(!value){
            self.definedInsNet.init_cidr.ip_3 = ""  
            formatStep4DetailInfo()
        }
    }
    self.labelobj = {};

    //ngtable 头部filter
    self.tableFilterList = {
            image:{
                name:"镜像类型",
                filter:[
                    {name:"全部",type:{is_public:""}},
                    {name:"公有",type:{is_public:true}},
                    {name:"私有",type:{is_public:false}}
                ]
            },
            arch:{
                name:"系统架构",
                filter:[
                    {name:$translate.instant("aws.img.all"),type:{arch:""}},
                    // {name:"64位",type:{arch:"x86_64"}},
                    // {name:"32位",type:{arch:"i686"}}
                ]
            },
            os:{
                name:"操作系统",
                filter:[
                    {name:"全部",type:{os_type:""}},
                    {name:"Windows",type:{os_type:"windows"}},
                    {name:"Linux",type:{os_type:"linux"}}
                ]
            }
    }
    const archList =  __OPTION__CLOUD__.imageArch.map(item=>{
        return {
            name:$translate.instant("aws.img.arch."+item),
            type:{
                arch:item
            }
        }
    })
    self.tableFilterList.arch.filter.push(...archList);
    self.tableContent = self;

    if(self.ADMIN) {
        self.getPortData();
    };
}
// 是否重复
createInsModule.directive("isrepeat", function(){
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$parsers.unshift(function (viewValue) {
                const repeatList = scope.labelArr.filter(item => viewValue != '' && item.value == viewValue)
                if(repeatList.length > 0){
                    ctrl.$setValidity('isrepeat', false);
                    return undefined;
                }
                ctrl.$setValidity('isrepeat', true);
                return viewValue;
            });
        }
    }
})

defineCreateInsCtrl.$inject = ['$scope', '$rootScope', 'NgTableParams', '$translate', 'alertSrv', 'utilTool','$location', '$routeParams', '$uibModal', 
'flavorsSrv', 'imagesSrv', 'snapshotsDataSrv', 'checkQuotaSrv', 'securityGroupSrv', 'networksSrv', 'instancesSrv', 'commonFuncSrv', 
'keyPairsSrv', 'FileSaver', 'Blob',"createInsSrv","$timeout","labelMSrv"];
createInsModule.controller('defineCreateInsCtrl', defineCreateInsCtrl);
createInsModule.controller("setNumConfigCtrl",['$scope', '$translate',"$uibModalInstance","context","editData",function($scope,$translate,$uibModalInstance,context,editData){
    let self = $scope;
    //context.setNamaList = [];
    let flavorList = _.cloneDeep(context.options.insFlavorTable.data);
    let flavorId = context.createInsForm.flavor;
    self.regMax = {
        cpu:0,
        vcpus_max:0,
        mem:0,
        memCtr:0,
        ram_max:0
    }
    self.cpuList = [];
    flavorList.forEach(item=>{
        if(item.id==flavorId){
            self.regMax.cpu = item.vcpus;
            self.regMax.vcpus_max = item.vcpus_max?item.vcpus_max:0;
            self.regMax.mem = item.ram;
            self.regMax.memCtr = item.ram/1024;
            self.regMax.ram_max = item.ram_max?item.ram_max:0;
            self.regMax.ram_maxCtr = item.ram_max/1024;
        }
    })
    self.dataList = [];
    self.nodeList = [];
    // self.cpuList = _.cloneDeep(context.cupMapList);
    // for(let i in self.cpuList){
    //     self.cpuList[i] = self.cpuList[i].map(item=>{
    //         return {
    //             value:item,
    //             id:item
    //         }
    //     })
    // }
    //初始化cpulist
    function cpuInit(data){
        let arr = [];
        for(let i=1;i<data+1;i++){
            arr.push({
                value:i,
                id:i
            })
        }
        return arr;
    }
    //cpu数据变化
    function cpuChange(self){
        self.allCpuList = cpuInit(self.regMax.vcpus_max);
        self.cpuList = [];
        let arr = [];
        self.dataList.forEach(item=>{
            arr.push(...item.cpu);
        })
        for(let i=0;i<self.allCpuList.length;i++){
            let flag = true;
            arr.forEach(item=>{
                if(item.value==self.allCpuList[i].value){
                    flag = false;
                }
            })
            if(flag){
                self.cpuList.push(self.allCpuList[i]);
            }
        }
        //self.cpuList = _.differenceBy(self.allCpuList,arr,'value');
    }
    //节点数据变化
    function change(item,type){
        self.dataList.forEach((item,index)=>{
            if(self.dataList.length==(index+1)){
                item.type = 2;
            }else{
                item.type = 1;
            }
        });
        if(type=="remove"){
            self.nodeList.push({
                id:item.node.selected.value,
                value:item.node.selected.value
            })
        }else{
            self.nodeList = self.nodeList.filter(it=>{
                return it.value!=item.node.selected.value
            })
        }
        self.nodeList.sort(function(a,b){
            return Number(a.value) - Number(b.value)
        })
        if(type=="add"){
            self.dataList[self.dataList.length-1].node.selected = self.nodeList[0];
        };

        //self.formData.node.selected = self.nodeList[0];
    }
    
    self.maxLength = editData.value;
    self.submitInValid = false;
    self.cpuChange = function(){
        cpuChange(self);
    }
    self.initData = function(){
        if(context.setNamaList.length>0){
            self.dataList = _.cloneDeep(context.setNamaList);
        }else{
            self.dataList = [
                {
                    node:{
                        selected:""
                    },
                    cpu:[],
                    mem:"",
                    type:2//默认为2 可以选择节点，显示添加按钮，为1，disabled选择节点，显示删除按钮
                }
            ];
        }
        for(let i=1;i<editData.value+1;i++){
            let flag = false;
            if(self.dataList.length>1){
                self.dataList.map((item,index)=>{
                    if(item.node.selected.value==i&&index!=self.dataList.length-1){
                        flag=true;
                    }
                })
            }
            if(!flag){
                self.nodeList.push({
                    id:i,
                    value:i
                })
            }
        }
        self.dataList[self.dataList.length-1].node.selected = self.nodeList[0];
        self.cpuChange();
        //self.formData.node.selected = self.nodeList[0];
        //console.log(self.nodeList)
    }
    self.addItem = function(item){
        self.dataList.push({
            node:{
                selected:""
            },
            cpu:[],
            mem:"",
            type:2
        });
        change(item,'add')
        //self.formData.addCpu = "";
        //self.formData.addMem = "";
    }

    self.removeItem = function(item,index){
        self.dataList.splice(index,1);
        change(item,'remove')
        self.cpuChange();
    }
    

    self.confirm = function(field){
        if(field.$valid){
            self.submitInValid = false;
            self.ErrorFlag = false;
            let cpu = 0;
            let mem = 0;
            self.dataList.forEach(item=>{
                cpu+=item.cpu.length;
                mem+=Number(item.mem);
                if((item.cpu.length&&!item.mem)||(!item.cpu.length&&item.mem)){
                    item.ErrorFlag = true;
                    self.ErrorFlag = true;
                }else{
                    item.ErrorFlag = false;
                }
            })
            //cpu = cpu+Number(self.formData.addCpu);
            //mem = cpu+Number(self.formData.addMem);
            if(self.ErrorFlag){
                return;
            }
            if(cpu&&cpu!=self.regMax.vcpus_max){
                self.cpuError = true;
            }else{
                self.cpuError = false;
            }
            if(mem&&mem!=self.regMax.ram_max){
                self.memError = true;
            }else{
                self.memError = false;
            }
            if(self.cpuError||self.memError){
                return;
            }
            
            context.setNamaList = _.cloneDeep(self.dataList);
            context.numaSetCache[editData.value] = _.cloneDeep(self.dataList);
            $uibModalInstance.close();
        }else{
            self.submitInValid = true;
        }
    }
    self.initData();
    
}])

createInsModule.controller("viewSecRuleCtrl",viewSecRuleCtrl);

