
addBackupRecoverJobCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupRecoverJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","$location","$routeParams","$uibModalInstance","initBackupRecoverJobTable","TableCom","$filter", "networksSrv"]
function addBackupRecoverJobCtrl($scope, $uibModal,$translate, alertSrv, backupRecoverJobSrv ,NgTableParams, checkedSrv,GLOBAL_CONFIG,$location,$routeParams,$uibModalInstance,initBackupRecoverJobTable,TableCom,$filter,networksSrv) {
	var self = $scope;
    self.dataPageSize=10;
    self.dataCurrentPage=1;
    self.FormValid = false;
    self.commitBtn = true;
    self.quotasData = {
        domain_quota:{
            ram: 0,//内存
            volumes: 0,//硬盘
            cores: 0,// cpu
            instances:0,//云主机数量
            gigabytes:0,//硬盘(GB)
        },
        inUse_domain_quota:{
            ram: 0,//内存
            volumes: 0,//硬盘
            cores: 0,// cpu
            instances:0,//云主机数量
            gigabytes:0,//硬盘(GB)
        },
        project_quota:{
            ram: 0,//内存
            volumes: 0,//硬盘
            cores: 0,// cpu
            instances:0,//云主机数量
            gigabytes:0,//硬盘(GB)
        },
        inUse_project_quota:{
            ram: 0,//内存
            volumes: 0,//硬盘
            cores: 0,// cpu
            instances:0,//云主机数量
            gigabytes:0,//硬盘(GB)
        },
    }
    self.message = "";
    // 初始化总参数
    self.fromData = {
        // 任务名称
        jobName:"",
        backupJob:"",
        backupJobTime:"",
    }
    //切换策略周期
    self.selectStrategyCycle = function(data) {
        self.fromData.strategy.type = data;
    }
    
    // 任务列表下拉
    backupTypeListFun();
    function backupTypeListFun() {
        let params = {};
        self.backupTypeList = [];
        // headers
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            headers = {
                project_id: localStorage.defaultProjectUid,
                domain_id: localStorage.defaultdomainUid,
            }
        }
        backupRecoverJobSrv.getBackupJobList(params, headers).then(function (result) {
			if (result && result.data && result.data) {
                self.backupTypeList = result.data;
			}
		});
    }

    initQuota();
    function initQuota() {
        var project_id = "";
        var domain_id = "";
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            project_id = localStorage.defaultProjectUid;
            domain_id = localStorage.defaultdomainUid;
        }else{
            project_id = localStorage.projectUid;
            domain_id = localStorage.domainUid;
        }
        //部门配额总数
        var typeName = ["ram", "volumes", "cores","instances", "gigabytes"];
        networksSrv.getDomianQuota({ 
            type:"domain_quota",
            targetId: domain_id,
            enterpriseUid:localStorage.enterpriseUid,
        }).then(function(res1){
            console.log("部门配额总数", res1)
            res1.data.forEach(x => {
                if(typeName.indexOf(x.name) > -1) {
                    self.quotasData.domain_quota[x.name] = x.hardLimit
                }
            });
            // 部门使用的
            networksSrv.getQuotaUsed({
                enterpriseUid:localStorage.enterpriseUid,
                domainUid: domain_id
            }).then(function(result1){
                console.log("部门使用的", result1)
                result1.data.forEach(x => {
                    if(typeName.indexOf(x.name) > -1) {
                        self.quotasData.inUse_domain_quota[x.name] = x.inUse
                    }
                });
                //项目配额总数
                networksSrv.getDomianQuota({ 
                    type:"project_quota",
                    targetId: project_id,
                    enterpriseUid:localStorage.enterpriseUid,
                }).then(function(res2){
                    console.log("项目配额总数", res2);
                    res2.data.forEach(x => {
                        if(typeName.indexOf(x.name) > -1) {
                            self.quotasData.project_quota[x.name] = x.hardLimit
                        }
                    });
                    networksSrv.getProQuotasUsages({
                        type:"project_quota",
                        projectUid:project_id,
                        domainUid: domain_id,
                        enterpriseUid:localStorage.enterpriseUid,
                    }).then(function(result2){
                        console.log("项目使用的", result2);
                        result2.data.forEach(x => {
                            if(typeName.indexOf(x.name) > -1) {
                                self.quotasData.inUse_project_quota[x.name] = x.inUse
                            }
                        });
                        console.log("配额最终", self.quotasData);
                    });
                });
            });
        });
    };
    self.backupTimeListFun = backupTimeListFun;
    function backupTimeListFun(){
        self.fromData.backupJobTime = "";
        refresh()
        var params = {
            jobId: self.fromData.backupJob.jobId
        }
        self.backupJobTimeList = [];
        // headers
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            headers = {
                project_id: localStorage.defaultProjectUid,
                domain_id: localStorage.defaultdomainUid,
            }
        }
        backupRecoverJobSrv.getBackupJobTimeList(params, headers).then(function (result) {
			if (result && result.data && result.data) {
                self.backupJobTimeList = result.data;
                self.backupJobTimeList = operateData_TimeList(self.backupJobTimeList);
			}
		});
    }
    function operateData_TimeList(data){
        data.forEach(x => {
            x.timepoints = $filter("date")(x.timepoints, "yyyy-MM-dd HH:mm:ss");
        });
        return data;
    }
    // 初始化table
    self.headers={
        instanceName:$translate.instant("aws.backupService.backupJobRecover.dialog.instanceName"),
        status:$translate.instant("aws.backupService.backupJobRecover.dialog.status"),
        ips:$translate.instant("aws.backupService.backupJobRecover.dialog.ips"),
        backupTime:$translate.instant("aws.backupService.backupJobRecover.dialog.backupTime"),
    };
    self.tableCols=[
        { field: "check", title: "",headerTemplateURL:"addRecoverJobCheckbox.html",show: true ,width:"5%"},
        { field: "instanceName", title: self.headers.instanceName, show: true,disable:true},//云主机名称
        // { field: "status", title: self.headers.status, show: true,disable:true},//状态
        { field: "ips", title: self.headers.ips, show: true,disable:true},//私网IP
        { field: "backupTime", title: self.headers.backupTime, show: true,disable:true},//备份时间
    ];
    function refresh(){
        self.dataPageSize=10;
        self.dataCurrentPage=1;
        initTables();
    }
    initTables();
    self.initTables = initTables;
    function initTables() {
        self.dataLoadData  = [];
        var params = {
            pageNum: self.dataCurrentPage,
            pageSize: self.dataPageSize
        }
        if (self.globalSearchTerm) {
			params.search = self.globalSearchTerm.item;
		}
        //任务id
        if (self.fromData.backupJob) {
            params.jobId = self.fromData.backupJob.jobId;
        }
        //时间节点  yyyy-mm-dd
        if (self.fromData.backupJobTime) {
            params.timePoints = self.fromData.backupJobTime.id;
        }
        var headers = {};
        if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
            headers = {
                project_id: localStorage.defaultProjectUid,
                domain_id: localStorage.defaultdomainUid,
            }
        }
        TableCom.init(self,"tableParams_a",[],"id",GLOBAL_CONFIG.PAGESIZE,'addRecoverJobCheckToCount');
        backupRecoverJobSrv.getInstList(params, headers).then(function (result) {
            if(result && result.data) {
                self.dataTotalNum = result.total;
                self.dataLoadData = result.data;
                self.dataLoadData = operateData(self.dataLoadData);
            }
            TableCom.init(self,"tableParams_a",self.dataLoadData,"id",GLOBAL_CONFIG.PAGESIZE,'addRecoverJobCheckToCount');
		});
	}
    self.applyGlobalSearch = function() {
        refresh()
    };
    // 翻页
    self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
        initTables();
    };
    function operateData(data) {
        data.forEach(x => {
            x.canClick = true;
            // 备份时间
            x.backupTime = $filter("date")(x.timepoints, "yyyy-MM-dd HH:mm:ss");
            x.metadata = JSON.parse(x.metadata);
        });
        return data;
    }
    //监听按钮
    self.$watch(function() {
        return self.addRecoverJobCheckToCount;
    }, function(val) {
        if(val == undefined) {
            return;
        }
        self.checkedItems = [];
		var arr=[];
        for(var i in self.addRecoverJobCheckToCount.items){
            arr.push(self.addRecoverJobCheckToCount.items[i])
        }
		if(val && arr.length>=0){
            for(var key in val.items){
                if(val.items[key]){
                    self.dataLoadData.forEach(item=>{
                        if(item.id==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
        self.dataLoadData.forEach(x => {
            x.canClick = true;
        });
        // 只能选择相同的 timepointGnspath 时间节点
        self.message = "";
		if(self.checkedItems.length > 0){
            self.dataLoadData.forEach(x => {
                x.canClick = false;
                if(x.timepointGnspath == self.checkedItems[0].timepointGnspath) {
                    x.canClick = true;
                }
            });
            checkQuota();
		}
    },true);

    //确定按钮
    self.confirmCreate = function(m,v){
        if(m.$valid){
            // 校验配额
            checkQuota();
            if(self.checkedItems.length == 0 || self.message) {
                return;
            }
            self.checkedItems.forEach(x => {
                delete x.metadata
            });
            var params = {
                jobName: self.fromData.jobName,
                instanceList: self.checkedItems
            }
            // headers
            var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                headers = {
                    project_id: localStorage.defaultProjectUid,
                    domain_id: localStorage.defaultdomainUid,
                }
            }
            self.commitBtn = false;
            backupRecoverJobSrv.createRecoverJob(params, headers).then(function () {
                self.commitBtn = true;
                //关闭模态框
                $uibModalInstance.close();
                //刷新父页面列表
                initBackupRecoverJobTable();
            });
        }else{
            self.FormValid=true;
        }
    }
    // 错误信息 项目CPU配额不足 项目内存配额不足 项目内存配额不足
    function checkQuota(){
        // 校验
        self.message = "";
       
        var ram = 0;
        var volumes = 0;
        var cores = 0;
        var instances = 0;
        var gigabytes = 0;
        self.checkedItems.forEach(x => {
            // 计算总数量
            if(x.metadata == null) {
                return;
            }
            volumes = volumes + x.metadata.disks.length;
            ram = ram + x.metadata.flavor.ram;
            cores = cores + x.metadata.flavor.vcpus;
            instances = self.checkedItems.length;
            x.metadata.disks.forEach(y => {
                gigabytes = gigabytes + y.size;
            });
        });
        // 判断部门配额
        var domain_ram = self.quotasData.domain_quota.ram;
        var domain_volumes = self.quotasData.domain_quota.volumes;
        var domain_cores = self.quotasData.domain_quota.cores;
        var domain_instances = self.quotasData.domain_quota.instances;
        var domain_gigabytes = self.quotasData.domain_quota.gigabytes;
        var inUse_domain_ram = self.quotasData.inUse_domain_quota.ram;
        var inUse_domain_volumes = self.quotasData.inUse_domain_quota.volumes;
        var inUse_domain_cores = self.quotasData.inUse_domain_quota.cores;
        var inUse_domain_instances = self.quotasData.inUse_domain_quota.instances;
        var inUse_domain_gigabytes = self.quotasData.inUse_domain_quota.gigabytes;
        var checkDomain = true;
        // 校验部门cpu配额
        if((inUse_domain_cores + cores) > domain_cores){
            checkDomain = false;
            self.message += $translate.instant("aws.instances.quota.domCpu") + ",";
        }
        // 校验部门内存配额
        if((inUse_domain_ram + ram) > domain_ram){
            checkDomain = false;
            self.message += $translate.instant("aws.instances.quota.domRam") + ",";
        }
        // 校验部门云硬盘配额
        if((inUse_domain_volumes + volumes) > domain_volumes){
            checkDomain = false;
            self.message += $translate.instant("aws.instances.quota.domVolumes") + ",";
        }
        // 校验部门云主机配额
        if((inUse_domain_instances + instances) > domain_instances){
            checkDomain = false;
            self.message += $translate.instant("aws.instances.quota.domIns") + ",";
        }
        // 校验部门 硬盘(GB) 配额
        if((inUse_domain_gigabytes + gigabytes) > domain_gigabytes){
            checkDomain = false;
            self.message += $translate.instant("aws.instances.quota.domGigabytes") + ",";
        }
        // 如果部门检验通过则校验项目配额
        if(checkDomain){
            var project_ram = self.quotasData.project_quota.ram;
            var project_volumes = self.quotasData.project_quota.volumes;
            var project_cores = self.quotasData.project_quota.cores;
            var project_instances = self.quotasData.project_quota.instances;
            var project_gigabytes = self.quotasData.project_quota.gigabytes;
            var inUse_project_ram = self.quotasData.inUse_project_quota.ram;
            var inUse_project_volumes = self.quotasData.inUse_project_quota.volumes;
            var inUse_project_cores = self.quotasData.inUse_project_quota.cores;
            var inUse_project_instances = self.quotasData.inUse_project_quota.instances;
            var inUse_project_gigabytes = self.quotasData.inUse_project_quota.gigabytes;
            // 校验项目cpu配额
            if((inUse_project_cores + cores) > project_cores){
                self.message += $translate.instant("aws.instances.quota.proCpu") + ",";
            }
            // 校验项目内存配额
            if((inUse_project_ram + ram) > project_ram){
                self.message += $translate.instant("aws.instances.quota.proRam") + ",";
            }
            // 校验项目云硬盘配额
            if((inUse_project_volumes + volumes) > project_volumes){
                self.message += $translate.instant("aws.instances.quota.proVolumes") + ",";
            }
            // 校验项目云主机配额
            if((inUse_project_instances + instances) > project_instances){
                self.message += $translate.instant("aws.instances.quota.proIns") + ",";
            }
            // 校验项目 硬盘(GB) 配额
            if((inUse_project_gigabytes + gigabytes) > project_gigabytes){
                self.message += $translate.instant("aws.instances.quota.proGigabytes") + ",";
            }
        }
        self.message = self.message.length > 0 ? self.message.substr(0, self.message.length-1) : self.message;
    }
};
export {addBackupRecoverJobCtrl}