
backupJobInstanceCtrl.$inject = ["$scope", "$uibModal", "$translate", "alertSrv", "backupJobSrv","NgTableParams", "checkedSrv","GLOBAL_CONFIG","TableCom","utilTool","$filter","$location"]
function backupJobInstanceCtrl($scope, $uibModal,$translate, alertSrv, backupJobSrv,NgTableParams, checkedSrv,GLOBAL_CONFIG,TableCom,utilTool,$filter,$location) {
	var self = $scope;
	
	self.dataPageSize=10;
    self.dataCurrentPage=1;
	self.canDelete = false;
	self.isShow1 = false;
	self.isShow2 = false;
	self.isShowAll = false;
	self.backupServiceConfig = true;
	self.desc = localStorage.managementRole == 2 ? $translate.instant("aws.backupService.tip.desc0") : $translate.instant("aws.backupService.tip.desc1");
	self.filters = {
		backupStatus:"全部",
		backupType:"全部",
	}
	// 备份结果
	self.statusList = [
		{"label":"全部","value":"","checked":true, "disabled": false, "selectAll": true},
		{"label":"已停止","value":"16","checked":true, "disabled": false},
		{"label":"成功","value":"32","checked":true, "disabled": false},
		{"label":"失败","value":"64","checked":true, "disabled": false},
		{"label":"部分成功","value":"128","checked":true, "disabled": false},
		{"label":"成功(有告警)","value":"129","checked":true, "disabled": false}
	]
	// 备份方式
	self.backupTypeList = [
		{"label":"全部","value":"","checked":true, "disabled": false, "selectAll": true},
		{"label":"完全备份","value":"1","checked":true, "disabled": false},
		{"label":"增量备份","value":"2","checked":true, "disabled": false}
	]
	//设置项的初始化
	self.headers={
        instJobName:$translate.instant("aws.backupService.backupJobInstancer.instJobName"),
        instStatus:$translate.instant("aws.backupService.backupJobInstancer.instStatus"),
		instBackupType:$translate.instant("aws.backupService.backupJobInstancer.instBackupType"),
		instStartTime:$translate.instant("aws.backupService.backupJobInstancer.instStartTime"),
		instEndTime:$translate.instant("aws.backupService.backupJobInstancer.instEndTime"),
		instUsedTime:$translate.instant("aws.backupService.backupJobInstancer.instUsedTime"),
		instSendSize:$translate.instant("aws.backupService.backupJobInstancer.instSendSize"),
		instSpeed:$translate.instant("aws.backupService.backupJobInstancer.instSpeed"),
		project:$translate.instant("aws.backupService.variable.table.projectName"),
		domain:$translate.instant("aws.backupService.variable.table.domainName"),
    };
	self.backupJobtitleName="backupService_backupJobInstance";
	if(sessionStorage["backupService_backupJobInstance"]){
		self.tableCols = JSON.parse(sessionStorage["backupService_backupJobInstance"]);
	}else {
		self.tableCols=[
			{ field: "check", title: "",headerTemplateURL:"backupJobInstanceManage.html",show: true ,width:"6%"},
			{ field: "instJobName", title: self.headers.instJobName, show: true,disable:true },//任务名称
			{ field: "instStatus", title: self.headers.instStatus, show: true,disable:false },//任务状态
			{ field: "instBackupType", title: self.headers.instBackupType, show: true,disable:false },//备份方式
			{ field: "instStartTime", title: self.headers.instStartTime, show: true,disable:false },//开始时间
			{ field: "instEndTime", title: self.headers.instEndTime, show: true,disable:false },//开始时间
			{ field: "instUsedTime", title: self.headers.instUsedTime, show: true,disable:false },//已运行时间
			{ field: "instSendSize", title: self.headers.instSendSize, show: true,disable:false },//传输数据量
			{ field: "instSpeed", title: self.headers.instSpeed, show: true,disable:false },//传输速度
		];
	}
	// 监听是否选中全部部门
    self.$watch(function(){
        return localStorage.selectedAllDomain
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self)
    })
	// 帮助按钮
	self.backupJobSearchTearm = function(obj){
		var tableData = obj.tableData;
		var tableCols = obj.tableCols;
		tableData.map(function(item){
			item.searchTerm = "";
			tableCols.forEach(function(showTitle){
				if(showTitle.value){
					item.searchTerm += item[showTitle.search] + "\b";
				}
			});
		});
	};
	// 刷新
	self.refresh =refresh;
	function refresh(){
		self.globalSearchTerm = "";
		self.dataPageSize=10;
    	self.dataCurrentPage=1;
		self.statusList.forEach(x => {
			x.checked = true;
		});
		// 过滤 备份方式
		self.backupTypeList.forEach(x => {
			x.checked = true;
		});
		self.filters.backupStatus = "全部";
		self.filters.backupType = "全部";
		initTables();
	}
	initTables();
	self.initTables = initTables; 
	function initTables() {
		if(!self.backupServiceConfig){
            return;
        }
		TableCom.init(self,'tableParams',[],"id",GLOBAL_CONFIG.PAGESIZE,'checkBackupJobInstanceToCount');
        self.dataLoadData  = [];
		let params = {
			pageNum: self.dataCurrentPage,
			pageSize: self.dataPageSize
		}
		if (self.globalSearchTerm) {
			params.search = self.globalSearchTerm.item;
		}
		// 过滤 备份结果
		let instStatusList = [];
		self.statusList.forEach(x => {
			if(!x.checked || x.value==""){
				return;
			}
			if(x.checked){
				instStatusList.push(x.value);
			}
		});
		if(instStatusList.length > 0) {
			params.instStatusList = instStatusList;
		}
		let backupTypeList = [];
		// 过滤 备份方式
		self.backupTypeList.forEach(x => {
			if(!x.checked || x.value==""){
				return;
			}
			if(x.checked){
				backupTypeList.push(x.value);
			}
		});
		if(backupTypeList.length > 0) {
			params.backupTypeList = backupTypeList;
		}
		backupJobSrv.getBackupJobHistoryList(params).then(function (result) {
			if (result && result.data) {
                self.dataTotalNum = result.total;
		        self.dataLoadData = result.data;
		        self.dataLoadData = operateData(self.dataLoadData);
                TableCom.init(self,'tableParams',self.dataLoadData,"id",GLOBAL_CONFIG.PAGESIZE,'checkBackupJobInstanceToCount');
			}
		});
	}
	function operateData(data) {
		data.forEach(x => {
			// 备份方式
			x.instBackupType =  $translate.instant("aws.backupService.backupJob.backupType." + x.instBackupType);
			// 开始时间
			x.instStartTime = x.instStartTime!=null ? x.instStartTime = $filter("date")(x.instStartTime, "yyyy-MM-dd HH:mm:ss"):"-";
			// 结束时间
			x.instEndTime = x.instEndTime!=null ? x.instEndTime = $filter("date")(x.instEndTime, "yyyy-MM-dd HH:mm:ss"):"-";
			// 已运行时间
			x.instUsedTime = formatSeconds(x.instUsedTime)
			// instCompletedData 完成数据量
			x.instSendSize_unit = unitData(x.instCompletedData);
		});
		return data;
	}
	// 转换数据量单位
	function unitData(data){
		if(data == 0) {
			return 0 + "B";
		}
		var kb = data / 1024;
		if( kb > 1 || kb == 1){
			var mb = kb / 1024;
			if(mb > 1 || mb == 1) {
                var gb = mb / 1024;
                if(gb > 1 || gb == 1) {
                    return gb.toFixed(2) + " GB";
                }else{
                    return mb.toFixed(2) + " MB";
                }
			}else {
				return kb.toFixed(2) + " KB";
			}
		}
	}
	function formatSeconds(value) {
		let result = parseInt(value/1000)
		let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
		let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
		let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
	 
		let res = '';
		// if(h !== '00') res += `${h}:`;
		// if(m !== '00') res += `${m}:`;
		res += `${h}:`;
		res += `${m}:`;
		res += `${s}`;
		return res;
	}
	self.applyGlobalSearch = function() {
		initTables();
	};
	self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
		initTables();
    };
	//监听按钮
    self.$watch(function() {
        return self.checkBackupJobInstanceToCount;
    }, function(val) {
		if(val == undefined) {
			return;
		}
        self.checkedItems = [];
		var arr=[];
        for(var i in self.checkBackupJobInstanceToCount.items){
            arr.push(self.checkBackupJobInstanceToCount.items[i])
        }
		if(val && arr.length>=0){
            for(var key in val.items){
                if(val.items[key]){
                    self.dataLoadData.forEach(item=>{
                        if(item.id==key){
                            self.checkedItems.push(item);
                        }
                    })
                }
            }
        }
		self.canDelete = false;
		if(self.checkedItems.length > 0){  
			self.canDelete = true;
		}
    },true);

	//删除操作
	self.deleteInstance = deleteInstance;
	function deleteInstance(checkedItems) {
		if(!self.canDelete) {
			return;
		}
		var content = {
			target: "deleteInstance",
			msg: "<span>" + $translate.instant("aws.system.storagement.deleteTip") + "</span>",
			data: checkedItems
		};
		self.$emit("delete", content);
	};
	self.$on("deleteInstance", function(e, data) {
		data.forEach(x => {
			let ids = [];
			ids.push(x.id);
			// headers
            var headers = {};
            if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
                const {projectUid,domainUid} = x;
                headers = {
                    project_id: projectUid,
                    domain_id: domainUid,
                }
            }
			var params = {
				ids: ids
			}
			backupJobSrv.deleteInstance(params, headers).then(function () {
				initTables();
			});
		});
	})
	self.$on("backupServiceConfig", function(e, data) {
        // 展示为配置列表
        self.backupServiceConfig = data;
    });
	self.backupStatus = function(data){
		checkDisabled(self.statusList, false);
		// 如果是全部按钮进行全选
		var label = "全部";
		self.filters.backupStatus = "";
		if(data.value == ""){
			self.statusList[0].selectAll = true;
			self.statusList.forEach(x => {
				x.checked = true
			});
			self.filters.backupStatus = label;
		}else {
			data.checked = !data.checked;
			// 1、判断是否剩一个 如果剩一个就置灰，但不包括全部选项
			if(checkOther(self.statusList)) {
				checkDisabled(self.statusList, true);
			}
			var b = "";
			self.statusList.forEach(x => {
				if(x.value == data.value) {
					x.checked = data.checked
				}
				if(x.checked && x.value!="") {
					b += x.label + ","
				}
			});
			if(checkAll(self.statusList)) {
				b = label;
			}
			self.filters.backupStatus = b;
		}
	}
	self.backupType = function(data){
		checkDisabled(self.backupTypeList, false);
		// 如果是全部按钮进行全选
		var label = "全部";
		self.filters.backupType = "";
		if(data.value == ""){
			self.backupTypeList[0].selectAll = true;
			self.backupTypeList.forEach(x => {
				x.checked = true
			});
			self.filters.backupType = label;
		}else {
			data.checked = !data.checked;
			// 1、判断是否剩一个 如果剩一个就置灰，但不包括全部选项
			if(checkOther(self.backupTypeList)) {
				checkDisabled(self.backupTypeList, true);
			}
			var b = "";
			self.backupTypeList.forEach(x => {
				if(x.value == data.value) {
					x.checked = data.checked
				}
				if(x.checked && x.value!="") {
					b += x.label + ","
				}
			});
			if(checkAll(self.backupTypeList)) {
				b = label;
			}
			self.filters.backupType = b;
		}
	}
	// 1、判断是否剩一个 如果剩一个就置灰，但不包括全部选项
	function checkOther(list) {
		var num = 0;
		list.forEach(x => {
			if(x.checked && x.value!="") {
				num = num + 1;
			}
		});
		if(num == 1) {
			return true;
		}
		return false;
	}
	function checkDisabled(list, type) {
		list.forEach(x => {
			if(x.checked && x.value!="" && type) {
				x.disabled = true;
			}else{
				x.disabled = false
			}
		});
	}
	function checkAll(list) {
		var isAll = true;
		list[0].selectAll = true;
		list.forEach(x => {
			if(!x.checked) {
				isAll = false;
				list[0].selectAll = false;
			}
		});
		return isAll;
	}
	self.filterFun = filterFun;
	function filterFun(){
		self.isShowAll = !self.isShowAll;
	}
	self.linkTo = function(item){
        utilTool.setLocalStorage([item]);
		//  ng-href="#/advanced/backup-job?id={{item.instId}}&tab=instance"
        $location.url('/advanced/backup-job?id='+item.id + '&tab=instance');
    }
	
};
export {backupJobInstanceCtrl}