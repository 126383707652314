var tableService = angular.module("cephSrv", []);
tableService.service("cephSrv", function($rootScope, $http) {
    var static_url = "http://192.168.138.134:9080/awstack-resource/v1/ceph";
    return {
        sqlQuery: function(options,params) {
            return $http({
                method: "POST",
                url: "awstack-monitor/v1/current/statistics/out/query/"+params.id+"/"+params.storageFirm,
                data: options
            });
        },
        /*获取监控ceph列表*/
        getCephList: function() {
            return $http({
                method: "GET",
                url: "awstack-user/v1/storage/monitor"
            });
        },
        //获取PG状态
        getPgStatus: function(params) {
            return $http({
                method: "GET",
                url: static_url + "/out/pgStatus",
                params:params
            });
        },
        //获取磁盘使用状态
        getDiskUsage: function(params) {
            return $http({
                method: "GET",
                url: static_url + "/out/diskUsage",  
                params:params
            });
        },
        //获取monitor状态
        getMonitorStatus: function(params) {
            return $http({
                method: "GET",
                url: static_url + "/out/monitors",
                params:params
            });
        },
        //获取monitor状态
        getCephStatus: function(params) {
            return $http({
                method: "GET",
                url: static_url + "/out/health",
                params:params
            });
        },
        getCephWarnig: function(params) {
            return $http({
                method: "GET",
                url: static_url + "/out/warning",
                params:params
            });
        },
        //获取pools表格数据
        getData: function(params) {
            return $http({
                method: "GET",
                url: static_url + "/out/pools",
                params:params
            });
        },
        //获取osd表格数据
        getOsdData: function(params) {
            return $http({
                method: "GET",
                url: static_url + "/out/osd",
                params:params
            });
        },
        // 获取节点主机
        getNodeHost:()=>{
            return $http({
                method: "GET",
                url: '/awstack-monitor/v1/statistics/regions/FFFFF/bcache/hostinfos'
            })
        },
        // 获取聚合盘
        getPolymerplate:()=>{
            return $http({
                menubar:'GET',
                url:'/awstack-monitor/v1/statistics/regions/FFFFF/bcache/bcachedevs'
            })
        },
        // 获取缓存监控
        getCephMonitoring:(data)=>{
            return $http({
                method:'post',
                url:'/awstack-monitor/v1/current/statistics/out/query/19/bcache',
                data
            })
        }
    };
});
