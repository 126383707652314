backupStrategyCtrl.$inject = ['$scope','$rootScope','NgTableParams','$uibModal','$location',"GLOBAL_CONFIG","$translate","$filter",'newCheckedSrv','strategylSrv','TableCom','utilTool']
createBackupsStrategyCtrl.$inject = ['$scope','$rootScope','NgTableParams','$uibModal',"GLOBAL_CONFIG","$translate",'newCheckedSrv','strategylSrv','TableCom','context','$timeout']
function backupStrategyCtrl($scope,$rootScope,NgTableParams,$uibModal,$location,GLOBAL_CONFIG,$translate,$filter,newCheckedSrv,Srv,TableCom,utilTool) {
    let self = $scope;
    self.pageSize=10;
    self.currentPage=1;
    self.handleBtn = {
        cancel:false
    };
    self.search={
        globalSearchTerm:""
    };

    //资源导出
    self.exportResource = function(){
        var option = {
            "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/policys/export?X-Register-Code=${localStorage.regionKey}&likes=${encodeURI(self.search.globalSearchTerm)}`
        }
        Srv.getExportUrl(option).then(res => {
            if (res && res.data) {
                var url = res.data;
                utilTool.downloadFile(url);
            }
        })
    }
    self.showExport = utilTool.isAllDomainSelect;

    //选择全部时，列表中部门和项目的处理
    self.$watch(function(){
        return localStorage.selectedAllDomain;
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self);
    });

    self.headers={
        name:$translate.instant('aws.backupStrategy.BackupstrategyName'),
        frequency_des:$translate.instant('aws.backupStrategy.Backupexecutionfrequency'),
        startTime:$translate.instant('aws.backupStrategy.Firstexecutiontime'),
        holdNumber:$translate.instant('aws.backupStrategy.Keepbackups'),
        createTime:$translate.instant('aws.backupStrategy.Creationtime'),
        domain:'部门',
        project:'项目'
    };
    self.titleName="backupsStrategy";
    if(sessionStorage["backupsStrategy"]){
       self.tableCols=JSON.parse(sessionStorage["backupsStrategy"]);
    }else{
       self.tableCols=[
             { field: "check", title: "",headerTemplateURL:"strategyHeaderCheckbox.html",show: true },
             { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
             { field: "frequency_des", title: self.headers.frequency_des,sortable: "frequency_des",show: true,disable:false},
             { field: "startTime", title: self.headers.startTime,sortable: "startTime",show: true,disable:false},
             { field: "holdNumber", title: self.headers.holdNumber,sortable: "holdNumber",show: true,disable:false},
             { field: "createTime", title: self.headers.createTime,sortable: "createTime",show: true,disable:false}
       ];
    }
    var initTable = function(options){
        TableCom.init(self,'strategyTable',[],"id",self.pageSize,'strategyHeaderCheckbox');
        let defaultParams = {
            likes:self.search.globalSearchTerm,
            pageNum:1,
            pageSize:self.pageSize
        };
        var params=options?options:defaultParams;
        self.strategyTableData =[];
        Srv.getlist(params).then(function(res){
            if(res&&res.data){
                self.totalNum=res.total;
                self.strategyTableData = res.data;
                self.strategyTableData.forEach(item => {
                    item.createTime = $filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
                    item.startTime = $filter("date")(item.startTime, "yyyy-MM-dd HH:mm:ss");
                    item.searchTerm = [item.name,item.frequency,item.holdNumber,item.startTime,item.createTime].join('\b');
                    if(item.domainName=="default"){
                        item.domainName_TR = $translate.instant("aws.common.defaultDepar");
                    }else{
                        item.domainName_TR = item.domainName;
                    }
                    if(item.projectName=="admin"){
                        item.projectName_TR = $translate.instant("aws.common.defaultProject");
                    }else{
                        item.projectName_TR = item.projectName;
                    }
                    if(item.unit=='once'){
                        item.frequency_des=$translate.instant('aws.backupStrategy.one')+$translate.instant("aws.backupStrategy."+item.unit);
                    }else{
                        item.frequency_des=$translate.instant('aws.backupStrategy.by')+$translate.instant("aws.backupStrategy."+item.unit)+$translate.instant('aws.backupStrategy.every')+item.frequency+$translate.instant("aws.backupStrategy."+item.unit)+$translate.instant('aws.backupStrategy.Executeonce');   
                    }
                    
                });
                TableCom.init(self,'strategyTable',res.data,"id",self.pageSize,'strategyHeaderCheckbox');
            }
        });
    };
    initTable();
    
    self.changePage=function(options){
        self.currentPage=options.pageNum;
        self.pageSize=options.pageSize;
        initTable(options);
    };

    self.refresh=function(){
       //刷新按钮清空输入框的数据
       self.search.globalSearchTerm="";
       self.currentPage=1;
       self.pageSize=10;
       initTable();
    };

    self.watched = '';
    function watchCheck(self) {
        var watch = self.$watch(function() {
            return self.strategyHeaderCheckbox.items;
        }, function(val) {
            self.checkedItems = [];
            var arr = [];
            for (var i in self.strategyHeaderCheckbox.items) {
                arr.push(self.strategyHeaderCheckbox.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.strategyTableData.forEach(item => {
                            if (item.id == key) {
                                self.checkedItems.push(item);
                            }
                        });
                    }
                }
            }
            utilTool.setLocalStorage(self.checkedItems);
            if (self.checkedItems.length>0){
                self.handleBtn = {
                    canDel:true
                };
            }else{
                self.handleBtn = {
                    canDel:false
                };
            }
        }, true);
        return watch;
    }
    if (self.watched) {
        self.watched();
    }
    self.watched = watchCheck(self);

    //后端搜索
    self.searchBackend = function(options){
        self.currentPage = 1;
        var pageOption = {
          pageSize:self.pageSize,
          pageNum:1,
          likes:self.search.globalSearchTerm?self.search.globalSearchTerm:""
        };
        if(options.type=='refresh'){
          self.search.globalSearchTerm = "";
          pageOption.likes = "";
        }
        initTable(pageOption);
    };

    /*新建备份策略弹出框*/
    self.createStrategy  = function(){
        self.backupModel = $uibModal.open({
            animation: self.animationsEnabled,
            templateUrl: "js/cvm/backupRecovery/backupStrategy/tmpl/createBackupStrategyModel.html",
            controller:  "createBackupsStrategyCtrl",
            resolve:{
                context:self
            }
        });
    };

    self.deleteStrategy  = function(checkedItems){
        var content = {
            target: "deleteStrategy",
            msg: $translate.instant('aws.backupStrategy.Deletecontent')
        };
        self.$emit("delete", content);
    };
    self.$on("deleteStrategy", ()=> {
        var params = [];
        self.checkedItems.forEach(item=>{
            params.push(item.id)
        });
        Srv.deletes({"id":params}).then(res=>{
            self.refresh();
        });
    });

    self.$on('getDetail',function(e,value){
        self.policyDetailInfo={};
        self.RouterParams = $location.search();
        TableCom.init(self,'sourceTable',[],"id",100000);
        Srv.getBackupPolicyDetail(self.RouterParams.id).then(function(res){
            if(res.data&&angular.isObject(res.data)){
                self.policyDetailInfo=res.data;
                if(self.policyDetailInfo.unit=='once'){
                    self.policyDetailInfo.frequency_des=$translate.instant('aws.backupStrategy.one')+$translate.instant("aws.backupStrategy."+self.policyDetailInfo.unit);
                }else{
                    self.policyDetailInfo.frequency_des=$translate.instant('aws.backupStrategy.by')+$translate.instant("aws.backupStrategy."+self.policyDetailInfo.unit)+$translate.instant('aws.backupStrategy.every')+self.policyDetailInfo.frequency+$translate.instant("aws.backupStrategy."+self.policyDetailInfo.unit)+$translate.instant('aws.backupStrategy.Executeonce');   
                }
                self.policyDetailInfo.startTime = $filter("date")(self.policyDetailInfo.startTime, "yyyy-MM-dd HH:mm:ss");
                self.policyDetailInfo.createTime = $filter("date")(self.policyDetailInfo.createTime, "yyyy-MM-dd HH:mm:ss");
                if(self.policyDetailInfo.backupJobs&&angular.isArray(self.policyDetailInfo.backupJobs)){
                    self.policyDetailInfo.backupJobs.forEach(function(job){
                        job.startTime=$filter("date")(job.startTime, "yyyy-MM-dd HH:mm:ss");
                        job.nextFireTime=$filter("date")(job.nextFireTime, "yyyy-MM-dd HH:mm:ss");
                        job.insAndIp=job.instanceName+"("+job.ips+")";
                    });
                    TableCom.init(self,'sourceTable',self.policyDetailInfo.backupJobs,"id",100000);
                }
                
            }
        });
    });

}
function createBackupsStrategyCtrl($scope,$rootScope,NgTableParams,$uibModal,GLOBAL_CONFIG,$translate,newCheckedSrv,Srv,TableCom,context,$timeout) {
    let self = $scope;
    self.submitInValid=false;
    self.canDoIt = true;
    self.data={
	    "unit":"once",
	    "frequency":"",
	    "startTime":"",
	    "holdNumber":1,
	    "name":"",
        "maxNum":""
	};
    self.frequencyList=[
        {
            name:$translate.instant('aws.backupStrategy.one')+$translate.instant('aws.backupStrategy.once'),
            value:"once",
            maxNum:""
        },
        {
            name:$translate.instant('aws.backupStrategy.by')+$translate.instant('aws.backupStrategy.hour'),
            value:"hour",
            maxNum:23
        },
        {
            name:$translate.instant('aws.backupStrategy.by')+$translate.instant('aws.backupStrategy.day'),
            value:"day",
            maxNum:30
        },
        {
            name:$translate.instant('aws.backupStrategy.by')+$translate.instant('aws.backupStrategy.week'),
            value:"week",
            maxNum:4
        },
        {
            name:$translate.instant('aws.backupStrategy.by')+$translate.instant('aws.backupStrategy.month'),
            value:"month",
            maxNum:12
        },
        // {
        //     name:"按分钟",
        //     value:"minute",
        //     maxNum:12
        // }
    ];
    self.changeFragmentNum=function(type){
        if(type=='subtraction'){
           if(self.data.holdNumber<=1){
               return;
           }
           self.data.holdNumber--;
        }else{
           if(self.data.holdNumber>=5){
               return;
           }
           self.data.holdNumber++; 
        }
    };
    self.selectFrequency=function(frequency){
        self.data.unit=frequency.value;
        self.data.maxNum=frequency.maxNum;
        self.data.frequency="";       
    };
    function chkCurrentTime(val){
        var curTime = new Date().getTime();
        var chkTime = new Date(val).getTime()
        if(chkTime<curTime){
            return false;
        }else{
            return true;
        }
    }
    self.changeTime=function(){
        self.chkCurrentTimeTip = false;
    };
    self.confirm=function(strategyForm){
        if(chkCurrentTime(self.data.startTime)){
           if(strategyForm.$valid){
                var options={
                    "unit":self.data.unit,
                    "startTime":self.data.startTime,
                    "holdNumber":self.data.holdNumber,
                    "name":self.data.name
                };
                if(self.data.unit!='once'){
                    options.frequency=self.data.frequency;
                }

                Srv.create(options).then(res=>{
                    if(res){
                        context.refresh();
                    }
                    context.backupModel.close();
                })
            }else{
                self.submitInValid=true;
            }
        }else{
           self.chkCurrentTimeTip = true; 
        }
    };
}
export{backupStrategyCtrl,createBackupsStrategyCtrl};
