import * as controllers from "./controllers";
import * as services from "./services";

let loginlogModule = angular.module("loginlogModule", []);

loginlogModule.config(["$controllerProvider", function($controllerProvider){
	$controllerProvider.register(controllers);
}]);

loginlogModule.config(["$provide", function($provide){
	$provide.service(services);
}]);
