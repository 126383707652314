import "./backup-recover-job-srv";
import * as controllers from './controller';
import * as directives from './directive';
import "../../../cvm/networks/networksSrv";
var backupRecoverJobModule = angular.module("backupRecoverJobModule", ["backupRecoverJobSrvModule", "networksSrvModule"]);
backupRecoverJobModule.config(['$controllerProvider', '$compileProvider',"$provide",
    function($controllerProvider,$compileProvider,$provide){
		$controllerProvider.register(controllers);
        $compileProvider.directive(directives);
    }
])