var tableService = angular.module("affinitySrv", []);
tableService.service('affinitySrv', function ($rootScope, $http) {
    var static_url = '/awstack-resource/v1/';
    return {
      getAffinityGroupList: function () {
        return $http({
          method: 'get',
          url: static_url + 'server/groups'
        })
      },
      getList:function(params){
            return $http({
                method: "GET",
                url: "awstack-manage/v2/instance/condition/group",
                params:params
            });
       },
      creatAffinityGroup: function (data,header) {
        return $http({
          method: 'POST',
          url: static_url + 'server/groups',
          data: data,
          headers:header?header:null
        })
      },
      delAffinityGroups:function(ids,names){
         return $http({
          method: 'DELETE',
          url: static_url + 'server/groups',
          params: {
                    ids: ids,
                    names: names
                },
        })
      },
      getAffinityGroupMembers:function(id){
        return $http({
          method: 'get',
          url: static_url + 'server/groups/'+id
        })
      }

    }

  })