import "./snapshotsSrv";
import "./volumesSrv";

var snapshotsModule = angular.module("snapshotsModule", ["snapshotssrv"])
    .controller("SnapshotsCtrl", ['$routeParams', '$scope', '$rootScope', '$translate', 'NgTableParams', 'alertSrv', '$location', '$uibModal', 'checkedSrv', 'snapshotsDataSrv', 'GLOBAL_CONFIG', 'volumesDataSrv', '$filter', 'utilTool', 'newCheckedSrv', "modalCom",
        function ($routeParams, $scope, $rootScope, $translate, NgTableParams, alertSrv, $location, $uibModal, checkedSrv, snapshotsDataSrv, GLOBAL_CONFIG, volumesDataSrv, $filter, utilTool, newCheckedSrv, modalCom) {
            var self = $scope;
            self.context = self;
            self.pageSize = 10;
            self.canSnapShot = false;
            self.canNewInstance = false;
            self.snap = {
                currentPage: "1"
            }
            self.statusConfig = {
                "statusSelected": {}
            }
            self.isEnterprise = true
            // if(localStorage.permission == "enterprise"){
            //     self.isEnterprise = true
            // }
            // self.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')];
            if (localStorage.permission == 'enterprise') {
                self.menuGuide = [$translate.instant('aws.menu.System'), $translate.instant('aws.menu.System_Operation'), $translate.instant('aws.menu.System_Storage')]
            } else {
                self.menuGuide = [$translate.instant('aws.menu.System'), $translate.instant('aws.menu.System_ConfigManagement'), $translate.instant('aws.menu.System_Storage')]
            }
            self.headers = {
                "snapshotName": $translate.instant("aws.snapshots.snapshotName"),
                "projectName": $translate.instant("aws.volumes.projectName"),
                "volume": $translate.instant("aws.volumes.volumeName"),
                "size": $translate.instant("aws.snapshots.size"),
                "status": $translate.instant("aws.volumes.status"),
                "description": $translate.instant("aws.snapshots.description"),
                "type": $translate.instant("aws.volumes.type"),
                "name": $translate.instant("aws.volumes.snapname"),
                "project": $translate.instant("aws.common.project"),
                "domain": $translate.instant("aws.common.domain"),
                "createtime": $translate.instant("aws.volumes.createtime")
            };

            

            //tab
            self.active = 0;
            $rootScope.now_uib_tab = 0;
            self.changeActive = function (num) {
                $rootScope.now_uib_tab = num
                if (!self.canSnapShot) {
                    return;
                }
                self.loadData1 = undefined;
                self.loadData2 = undefined;
                self.dataTable = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: [] });
                self.sysTable = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: [] });
                self.active = num;

                initSnapShotForm();
                if (num == 1) {
                    self.titleName = "sysSnapshots";
                    utilTool.projectDomainTableCol(localStorage.selectedAllDomain, 2, self, "systableCols");
                } else {
                    self.titleName = "dataSnapshots";
                    utilTool.projectDomainTableCol(localStorage.selectedAllDomain, 2, self);
                }
            }

            self.titleName = "dataSnapshots";
            self.sysTitleName = "sysSnapshots";
            if (sessionStorage["dataSnapshots"]) {
                self.tableCols = JSON.parse(sessionStorage["dataSnapshots"]);
            } else {
                self.tableCols = [
                    { field: "check", title: "", headerTemplateURL: "dataSnapshotsCheckboxes.html", show: true },
                    { field: "name", title: self.headers.name, sortable: "name", show: true, disable: true },
                    { field: "volumeName", title: self.headers.volume, sortable: "volumeName", show: true, disable: false },
                    { field: "size", title: self.headers.size, sortable: "size", show: true, disable: true },
                    { field: "statusCopy", title: self.headers.status, sortable: "status", show: true, disable: false },
                    { field: "createtime", title: self.headers.createtime, sortable: "createTime", show: true, disable: false },
                    { field: "description", title: self.headers.description, sortable: "description", show: true, disable: false }
                ];
            }

            if (sessionStorage["sysSnapshots"]) {
                self.systableCols = JSON.parse(sessionStorage["sysSnapshots"]);
            } else {
                self.systableCols = [
                    { field: "check", title: "", headerTemplateURL: "sysSnapshotsCheckboxes.html", show: true },
                    { field: "name", title: self.headers.name, sortable: "name", show: true, disable: true },
                    { field: "volumeName", title: self.headers.volume, sortable: "volumeName", show: true, disable: false },
                    { field: "size", title: self.headers.size, sortable: "size", show: true, disable: true },
                    { field: "statusCopy", title: self.headers.status, sortable: "status", show: true, disable: false },
                    { field: "createtime", title: self.headers.createtime, sortable: "createTime", show: true, disable: false },
                    { field: "description", title: self.headers.description, sortable: "description", show: true, disable: false },
                ];
            }
            // self.systableCols.push({ field: "description", title: self.headers.description, sortable: "description", show: true, disable: false })

            self.searchList = [
                { name: $translate.instant('aws.volumes.table.status.all'), id: null, key: "" },
                { name: $translate.instant('aws.volumes.table.status.available'), id: "available", value: $translate.instant('aws.instances.table.status.active'), key: "status_ori" },
                { name: $translate.instant('aws.volumes.table.status.inavailable'), id: "inavailable", value: $translate.instant('aws.instances.table.status.shutoff'), key: "status_ori" },
                { name: $translate.instant('aws.volumes.table.status.creating'), id: "creating", value: $translate.instant('aws.instances.table.status.error'), key: "status_ori" },
                { name: $translate.instant('aws.volumes.table.status.in-use'), id: "in-use", value: $translate.instant('aws.instances.table.status.suspended'), key: "status_ori" },
                { name: $translate.instant('aws.volumes.table.status.error'), id: "error", value: $translate.instant('aws.instances.table.status.error'), key: "status_ori" },
            ];

            self.$watch(function () {
                return snapshotsDataSrv.clear;
            }, function (value, old) {
                if (value !== old) {
                    if (self.checkboxessnapshotsDataTableParams) {
                        self.checkboxessnapshotsDataTableParams.items = {};
                    }
                    if (self.checkboxessnapshotsSysTableParams) {
                        self.checkboxessnapshotsSysTableParams.items = {};
                    }
                }
            }, true)

            self.lineDetailIn = function (item) {
                utilTool.setLocalStorage([item]);
                $location.url(`/cvm/snapshots?id=${item.uid}`)
            }

            self.lineDetailBack = function () {
                snapshotsDataSrv.clear = !snapshotsDataSrv.clear;
                utilTool.setLocalStorage([]);
                $location.url(`/cvm/snapshots`)
            }
 
            //资源导出
            self.exportResource = function (volume) {
                if (volume == "data") {
                    var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/snapshots?regionKey=${localStorage.regionKey}&type=0&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm ? self.globalSearchTerm : ""}&status=${self.statusConfig.statusSelected.searchItem.id ? self.statusConfig.statusSelected.searchItem.id : ""}`
                } else if (volume == "system") {
                    var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/snapshots?regionKey=${localStorage.regionKey}&type=1&token=${localStorage.$AUTH_TOKEN}&pattern=${self.globalSearchTerm ? self.globalSearchTerm : ""}&status=${self.statusConfig.statusSelected.searchItem.id ? self.statusConfig.statusSelected.searchItem.id : ""}`
                }
                utilTool.downloadFile(url);
            }
            self.showExport = utilTool.isAllDomainSelect;
            //项目切换的监听函数
            self.$watch(function () {
                return localStorage.selectedAllDomain;
            }, function (ne) {
                if (self.active == 1) {
                    utilTool.projectDomainTableCol(ne, 2, self, "systableCols");
                } else {
                    utilTool.projectDomainTableCol(ne, 2, self);
                }
            });

            //切换页码
            self.changePage = function (options) {
                options.pattern = self.snapshot_search.globalSearchTerm ? self.snapshot_search.globalSearchTerm : null;
                options.status = self.statusConfig.statusSelected.searchItem ? self.statusConfig.statusSelected.searchItem.id : null;
                options.type = 0;
                getSnapshots(options);
            };
            //后端搜索
            self.searchBackend = function (options) {
                self.snap.currentPage = 1;
                var pageOption = {
                    pageSize: self.pageSize,
                    pageNum: 1,
                    pattern: self.snapshot_search.globalSearchTerm ? self.snapshot_search.globalSearchTerm : null,
                    status: self.statusConfig.statusSelected.searchItem ? self.statusConfig.statusSelected.searchItem.id : null,
                    type: 0
                };
                if (options.type == 'refresh') {
                    self.currentPage = 1;
                    self.snapshot_search.globalSearchTerm = null;
                    pageOption.pattern = null;
                    pageOption.status = null;
                    self.statusConfig.statusSelected.searchItem = self.searchList[0]
                }
                getSnapshots(pageOption);
            }

            // 编辑弹窗
            self.updateAlert = function () {
                self.createItemModal = modalCom.init('updateAlert.html', 'updateAlertCtrl', {
                    context: function () {
                        return self;
                    },
                })
            }

            // 系统盘编辑弹窗
            // self.changeAlert = function(){

            // }

            function getSnapshots(options) {
                self.canSnapShot = false;
                snapshotsDataSrv.getSnapshotsList(options).then(function (result) {
                    // var dataSnap=[];
                    if (result && result.data) {
                        // result.data.forEach((x) => {
                        //     if(!x.bootTable){
                        //         dataSnap.push(x);
                        //     }
                        // })
                        successFuncData(result.data);
                    }
                    self.loadData1 = result.data;
                    self.dataTotalNum = result.total;
                }).finally(function () {
                    self.canSnapShot = true;
                })
            }

            //切换页码
            self.sysChangePage = function (options) {
                options.pattern = self.snapshot_search.globalSearchTerm ? self.snapshot_search.globalSearchTerm : null;
                options.status = self.statusConfig.statusSelected.searchItem ? self.statusConfig.statusSelected.searchItem.id : null;
                options.type = 1;
                getSysSnapshots(options);
            };
            //后端搜索
            self.sysSearchBackend = function (options) {
                self.snap.currentPage = 1;
                var pageOption = {
                    pageSize: self.pageSize,
                    pageNum: 1,
                    pattern: self.snapshot_search.globalSearchTerm ? self.snapshot_search.globalSearchTerm : null,
                    status: self.statusConfig.statusSelected.searchItem ? self.statusConfig.statusSelected.searchItem.id : null,
                    type: 1
                };
                if (options.type == 'refresh') {
                    self.currentPage = 1;
                    self.snapshot_search.globalSearchTerm = null;
                    pageOption.pattern = null;
                    pageOption.status = null;
                    self.statusConfig.statusSelected.searchItem = self.searchList[0]
                }
                getSysSnapshots(pageOption);
            }
            function getSysSnapshots(options) {
                self.canSnapShot = false;
                snapshotsDataSrv.getSnapshotsList(options).then(function (result) {
                    if (result && result.data) {
                        successFuncSys(result.data);
                    }
                    self.loadData2 = result.data;
                    self.sysTotalNum = result.total;
                }).finally(function () {
                    self.canSnapShot = true;
                })
            }


            self.snapshot_search = {};

            self.newInstance = function (editData) {
                self.insAnimation = "animateOut";
                var path = "/cvm/snapshots?from=snapshot&snapshotId=" + editData[0].uid
                $location.url(path);
                self.insAnimation = "animateIn";
                $("body").addClass("animate-open")
                // var path = "/quickconfig/createins?from=snapshot&snapshotId="+editData.id
                // $location.url(path);
            };
            self.closeNewIns = function () {
                self.insAnimation = "animateOut";
                $("body").removeClass("animate-open");
                $location.url("/cvm/snapshots");
            }
            function successFuncData(data) {
                data.map(function (item) {
                    item.status_ori = $translate.instant("aws.snapshots.table.status." + item.status);
                    // var date = $filter("date")(item.created,"MMddHHmm");
                    // if (item.name.indexOf("_")<0) {
                    //     item.name = item.name + "  (" + date + ")";
                    // }
                    if (item.domainName == "default") {
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    } else {
                        item.domainName = item.domainName;
                    }
                    if (item.projectName == "admin") {
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    } else {
                        item.projectName = item.projectName;
                    }
                    item.searchTerm = item.name + item.volumeName + item.size + item.status_ori;
                });
                self.tabledata = data;
                self.snapshotsDataTableParams = new NgTableParams({ count: 10 }, { counts: [], dataset: self.tabledata });
                newCheckedSrv.checkDo(self, "", "uid", "snapshotsDataTableParams");
                self.applyGlobalSearch = function () {
                    var term = self.snapshot_search.globalSearchTerm;
                    self.dataTable.filter({ searchTerm: term });
                };
                self.checkboxessnapshotsDataTableParams = {
                    checked: "",
                    items: {}
                }

                if (watch) {
                    watch();
                }

                watch = self.$watch(function () {
                    return self.checkboxessnapshotsDataTableParams.items;
                }, function (values, olds) {
                    self.dataCheckedItems = [];
                    var oldsList = [];
                    self.tabledata.forEach(item => {
                        if (values && values[item.uid]) {
                            self.dataCheckedItems.push(item);
                        }
                        if (olds && olds[item.uid]) {
                            oldsList.push(item);
                        }
                    });

                    if (self.dataCheckedItems && oldsList && self.dataCheckedItems.length != oldsList.length) {
                        utilTool.setLocalStorage(self.dataCheckedItems);
                    }
                    self.canCV = false;
                    self.btn = {};
                    if (!self.dataCheckedItems) return;
                    if (self.dataCheckedItems.length > 1) {
                        for (var i = 0; i < self.dataCheckedItems.length; i++) {
                            canDelFunc(self.dataCheckedItems[i]);
                            if (self.canDel == false) {
                                self.canDel = false;
                                break;
                            }
                        }
                    }
                    if (self.dataCheckedItems.length == 1) {
                        self.canUpdate = true
                        self.btn.limit = self.dataCheckedItems[0].storageLimit;
                        self.storageTypeTip = $translate.instant("aws.snapshots.tip.tip1");
                        if (self.btn.limit["volumes_snapshots"]) { canCVFunc(self.dataCheckedItems[0]) };
                        canDelFunc(self.dataCheckedItems[0]);
                    } else {
                        self.storageTypeTip = $translate.instant("aws.snapshots.tip.tip3");
                        self.canUpdate = false
                    }

                    if (self.dataCheckedItems.length == 0) {
                        self.canDel = false;
                    }

                    $rootScope.itemData = JSON.parse(JSON.stringify(self.dataCheckedItems))

                }, true);
            }
            function canDelFunc(obj) {
                self.canDel = true;
                var cantDelArray = ["error_deleting"];
                _.forEach(cantDelArray, function (item) {
                    if (obj.status == item) {
                        self.canDel = false;
                    }
                });
            }

            function canCVFunc(obj) {
                self.canCV = true;
                if (obj.status != "available") {
                    self.canCV = false;
                    self.CVTip = $translate.instant("aws.snapshots.tip.tip2");
                    return
                }
            }
            var watch = null;
            function successFuncSys(data) {
                data.map(function (item) {
                    item.status_ori = $translate.instant("aws.snapshots.table.status." + item.status);
                    // var date = $filter("date")(item.created,"MMddHHmm");
                    // if (item.name.indexOf("_")<0) {
                    //     item.name = item.name + "  (" + date + ")";
                    // }
                    if (item.domainName == "default") {
                        item.domainName = $translate.instant("aws.common.defaultDepar")
                    } else {
                        item.domainName = item.domainName;
                    }
                    if (item.projectName == "admin") {
                        item.projectName = $translate.instant("aws.common.defaultProject")
                    } else {
                        item.projectName = item.projectName;
                    }
                    item.searchTerm = item.name + item.volumeName + item.size + item.status_ori;
                });
                self.tabledata = data;
                self.snapshotsSysTableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: self.tabledata });


                newCheckedSrv.checkDo(self, "", "uid", "snapshotsSysTableParams");

                self.applyGlobalSearch = function () {
                    var term = self.snapshot_search.globalSearchTerm;
                    self.sysTable.filter({ searchTerm: term });
                };
                self.checkboxessnapshotsSysTableParams = {
                    checked: "",
                    items: {}
                }

                if (watch) {
                    watch();
                }

                watch = self.$watch(function () {
                    return self.checkboxessnapshotsSysTableParams.items;
                }, function (values, olds) {
                    self.sysCheckedItems = [];
                    var oldsList = [];
                    self.tabledata.forEach(item => {
                        if (values && values[item.uid]) {
                            self.sysCheckedItems.push(item);
                        }
                        if (olds && olds[item.uid]) {
                            oldsList.push(item);
                        }
                    });
                    if (self.sysCheckedItems && oldsList && self.sysCheckedItems.length != oldsList.length) {
                        utilTool.setLocalStorage(self.sysCheckedItems);
                    }
                    self.canCV = false;
                    self.canNewInstance = false;
                    self.btn = {};
                    if (!self.sysCheckedItems) return;
                    if (self.sysCheckedItems.length > 1) {
                        for (var i = 0; i < self.sysCheckedItems.length; i++) {
                            canDelFunc(self.sysCheckedItems[i]);
                            if (self.canDel == false) {
                                self.canDel = false;
                                break;
                            }
                        }
                    }

                    if (self.sysCheckedItems.length == 1) {
                        self.canChange = true
                        self.canNewInstance = true;
                        self.btn.limit = self.sysCheckedItems[0].storageLimit;
                        self.storageTypeTip = $translate.instant("aws.snapshots.tip.tip1");
                        if (self.btn.limit["volumes_snapshots"]) { canCVFunc(self.sysCheckedItems[0]) };
                        canDelFunc(self.sysCheckedItems[0]);
                    } else {
                        self.canChange = false
                        self.storageTypeTip = $translate.instant("aws.snapshots.tip.tip3");
                    }
                    if (self.sysCheckedItems.length == 0) {
                        self.canDel = false;
                    }

                    $rootScope.itemData = JSON.parse(JSON.stringify(self.sysCheckedItems))

                }, true);
            }
            var dataSnapShotForm = function () {
                var options = {
                    "pageNum": 1,
                    "pageSize": 10,
                    "type": 0
                }

                //self.statusConfig.statusSelected.searchItem?self.statusConfig.statusSelected.searchItem.id:null;
                self.statusConfig.statusSelected.searchItem = self.searchList[0];
                self.snapshot_search.globalSearchTerm = '';
                self.snap.currentPage = 1;
                self.canSnapShot = false;
                snapshotsDataSrv.getSnapshotsList(options).then(function (result) {
                    if (result && result.data) {
                        successFuncData(result.data);
                    }
                    self.loadData1 = result.data;
                    self.dataTotalNum = result.total;
                }).finally(function () {
                    self.canSnapShot = true;
                })
            };
            var sysSnapShotForm = function () {
                var options = {
                    "pageNum": 1,
                    "pageSize": 10,
                    "type": 1
                }
                self.statusConfig.statusSelected.searchItem = self.searchList[0];
                self.snapshot_search.globalSearchTerm = '';
                self.snap.currentPage = 1;
                self.canSnapShot = false;
                snapshotsDataSrv.getSnapshotsList(options).then(function (result) {
                    // var sysSnap=[];
                    if (result && result.data) {
                        // result.data.forEach((x) => {
                        //     if(x.bootTable){
                        //         sysSnap.push(x);
                        //     }
                        // })
                        successFuncSys(result.data);

                    }
                    self.loadData2 = result.data;
                    self.sysTotalNum = result.total;
                }).finally(function () {
                    self.canSnapShot = true;
                })
            };



            function initSnapShotForm() {
                if (!self.services.cinder) return;
                if (self.active == 0) {
                    if (self.canSnapShot) {
                        dataSnapShotForm();
                    }
                } else {
                    if (self.canSnapShot) {
                        sysSnapShotForm();
                    }
                }
            }

            localStorage.cinderService ? dataSnapShotForm() : "";

            $scope.refreshSnap = function () {
                initSnapShotForm();

            };

            $scope.$on("getDetail", function (event, value) {
                snapshotsDataSrv.snapShotDetailAction(value).then(function (result) {
                    //result.data[0].description=decodeURI(result.data[0].description);
                    if (result && result.data && result.data.length) {
                        if ($routeParams.id != value) { return; }
                        $scope.detailData = result.data[0];
                    } else {
                        $scope.detailData = {};
                    }

                });
            });

            // 更新表格
            $scope.$on("refreshTable", function () {
                // getSnapshots({
                //     "pageNum": 1,
                //     "pageSize": 10,
                //     "type": self.active
                // })
                initSnapShotForm();

            })

            $scope.$on("snapSocket", function (e, data) {
                if (self.tabledata) {
                    self.tabledata.map(function (obj) {
                        if (obj.uid === data.eventMata.snapshot_id) {
                            obj.status = data.eventMata.status;
                            if (obj.status == "deleted") {
                                _.remove(self.tabledata, function (val) {
                                    return val.status == "deleted";
                                });
                            }
                        }
                    });
                }

                //启动云硬盘
                if (data.eventType == "volume.create.start") {  //开始卸载云硬盘或开始加载云硬盘
                    var tipMsg4 = data.resourceName + $translate.instant("aws.sockets.opLog." + data.eventType);
                    alertSrv.set(data.requestId, tipMsg4, "building", 5000);
                }
                if (data.eventType == "volume.create.end") {
                    var tipMsg5 = data.resourceName + $translate.instant("aws.sockets.opLog." + data.eventType);
                    alertSrv.set(data.requestId, tipMsg5, "success", 5000);
                }
                if (self.active == 0 && self.dataTable) {
                    self.dataTable.reload();
                } else if (self.active == 1 && self.sysTable) {
                    self.sysTable.reload();
                }

            });
            self.deleteSnapshots = function (checkedItems) {

                var content = {
                    target: "deleteSnapshots",
                    msg: "<span>" + $translate.instant("aws.snapshots.confirmDelete") + "</span>",
                    data: checkedItems
                };
                self.$emit("delete", content);
            };
            self.$on("deleteSnapshots", function (e, data) {
                if (data.length == 1) {
                    checkedSrv.setChkIds([data[0].uid], "snapshotdelete");
                    snapshotsDataSrv.delOneSnapshotAction(data[0].uid).then(function () {
                        initSnapShotForm();
                    });
                } else {
                    var snapshot_ids = [];
                    //获取删除的快照ids
                    _.forEach(data, function (item) {
                        snapshot_ids.push(item.uid);
                    });
                    var del_params = {
                        ids: snapshot_ids
                    };
                    checkedSrv.setChkIds(del_params.ids, "snapshotdelete");
                    snapshotsDataSrv.delSnapshots(del_params).then(function () {
                        initSnapShotForm();
                    });
                }
                initSnapShotForm();
            });
            $scope.enabledVolume = function (editData) {
                if (!self.canCV) return;
                $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "enabledVolumeModel.html",
                    controller: "enabledVolumeCtrl",
                    resolve: {
                        initSnapShotForm: function () {
                            return initSnapShotForm;
                        },
                        editData: function () {
                            return editData;
                        },
                        selectedItems: function () {
                            return self.checkboxessnapshotsSysTableParams;
                        }
                    }
                });
            };
        }]);

snapshotsModule.controller("updateAlertCtrl", ['$scope', '$rootScope', '$translate', "$uibModalInstance", "snapshotsDataSrv",
    function ($scope, $rootScope, $translate, $uibModalInstance, snapshotsDataSrv) {
        const self = $scope;
        const activeNum = $rootScope.now_uib_tab;
        const { description, name, id, uid } = $rootScope.itemData[0]
        self.kzUpdate = {
            name,
            describe: description
        }

        self.confirmCreate = function (kz_update) {
            if (!kz_update.$valid) {
                return
            }
            const config = {
                id,
                uid,
                name: self.kzUpdate.name,
                description: self.kzUpdate.describe
            }
            requestData(activeNum)
            async function requestData(Num){
                if(Num == 0){
                    await snapshotsDataSrv.hardDiskKZUpdate(config)
                }else if(Num == 1){
                    await snapshotsDataSrv.sysHardDiskKZUpdate(config)
                }
                $uibModalInstance.close();
                $rootScope.$broadcast('refreshTable')
            }
        }
    }]);

snapshotsModule.controller("enabledVolumeCtrl", ['$scope', '$rootScope', '$translate', 'snapshotsDataSrv', 'volumesDataSrv', '$uibModalInstance', 'initSnapShotForm', 'editData', 'selectedItems', function ($scope, $rootScope, $translate, snapshotsDataSrv, volumesDataSrv, $uibModalInstance, initSnapShotForm, editData, selectedItems) {
    var self = $scope;
    editData = editData[0];
    var proTotalGig, proUsedGig, proRemainderGig;
    var depTotalGig, depUsedGig, depRemainderGig;
    var proTotalVolumes, proUsedVolumes, proRemainderVolumes;
    var depTotalVolumes, depUsedVolumes, depRemainderVolumes;
    self.isShowDep = false;
    self.isShowPro = false;
    self.isShowDepVolumes = false;
    self.isShowProVolumes = false;
    self.showPriceTip = $rootScope.billingActive;
    self.priceHour = 0;
    self.priceHourAbout = 0;
    self.priceMonth = 0;
    //用系统盘创建云硬盘时，不能选择共享卷。
    if (editData.status == "available" && editData.bootTable == true) {
        self.root_device_not_shared = false
    } else {
        if (editData.volumeType.indexOf('ceph') == -1) {
            self.root_device_not_shared = false;
        } else {
            self.root_device_not_shared = true;
        }
    }
    //self.canConfirm=true;
    self.page = {
        "newVolume": $translate.instant("aws.volumes.cv.newVolume"),
        "updateVolume": $translate.instant("aws.volumes.cv.updateVolume"),
        "cantCreate": $translate.instant("aws.volumes.errorMessage.cantCreate"),
        "moreThanDepGig": $translate.instant("aws.volumes.errorMessage.moreThanDepGig"),
        "moreThanProGig": $translate.instant("aws.volumes.errorMessage.moreThanProGig"),
        "moreThanDepVolumes": $translate.instant("aws.volumes.errorMessage.moreThanDepVolumes"),
        "moreThanProVolumes": $translate.instant("aws.volumes.errorMessage.moreThanProVolumes")
    };
    self.errorMess = {
        "snapshotDep": $translate.instant("aws.volumes.errorMessage.snapshotDep"),
        "snapshotPro": $translate.instant("aws.volumes.errorMessage.snapshotPro")
    };
    self.submitted = false;
    self.interacted = function (field) {
        return self.submitted || field.$dirty;
    };
    //判断项目volumes总配额减去部门已使用的配额的函数
    var proVolumesFunc = function (value) {
        volumesDataSrv.getProHave("volumes").then(function (result) {
            if (result && result.data && result.data.length) {
                proTotalVolumes = result.data[0].hardLimit; //当前项目配额总数
            }
            volumesDataSrv.getProUsed("volumes").then(function (result) {
                if (result && result.data && result.data.length) {
                    proUsedVolumes = result.data[0].inUse; //当前项目已使用的 
                } else {
                    proUsedVolumes = 0;
                }
                return proUsedVolumes;
            }).then(function (proUsedVolumes) {
                proRemainderVolumes = parseInt(proTotalVolumes) - parseInt(proUsedVolumes); //当前项目剩余的配额数
                if (proRemainderVolumes < value) {
                    self.isShowProVolumes = true;
                    self.errorMessageProVolumes = self.page.moreThanProVolumes;
                } else {
                    self.isShowProVolumes = false;
                }
                self.project_data_volumes = {
                    title: $translate.instant("aws.project.quota"),
                    inUsed: parseInt(proUsedVolumes),
                    beAdded: parseInt(value),
                    total: proTotalVolumes
                };
            });
        });
    };

    self.insForm = {
        hostNum: 1,
        networkList: []
    };
    self.setInsNum = function(type) {
        if ((self.insForm.hostNum <= 1 && type == "minus") || (self.insForm.hostNum >= 10 && type == "add")) {
            return;
        }
        if (type == "minus") {
            self.insForm.hostNum--;
        } else {
            self.insForm.hostNum++;
        }
        self.isShowError(self.volumeForm.size)
    };


    //判断部门volumes总配额减去部门已使用的配额的函数
    var depVolumesFunc = function (value) {
        volumesDataSrv.getQuotaTotal("volumes").then(function (result) {
            if (result && result.data && result.data.length) {
                depTotalVolumes = result.data[0].hardLimit; //当前部门配额总数
            } else {
                proVolumesFunc(value);
            }
            volumesDataSrv.getQuotaUsed("volumes").then(function (result) {
                if (result && result.data && result.data.length) {
                    depUsedVolumes = result.data[0].inUse;  //当前部门已使用的配额数
                } else {
                    depUsedVolumes = 0;
                }
                return depUsedVolumes;
            }).then(function (depUsedVolumes) {
                depRemainderVolumes = parseInt(depTotalVolumes) - parseInt(depUsedVolumes);
                if (depRemainderVolumes < value) {
                    self.isShowDepVolumes = true;
                    self.errorMessageDepVolumes = self.page.moreThanDepVolumes;
                    /*self.project_data={
                        title:$translate.instant("aws.project.quota"),
                        inUsed:parseInt(self.project_data.inUsed)+parseInt(value),
                        total:proTotalGig
                    };*/
                    proVolumesFunc(value);
                } else {
                    self.isShowDepVolumes = false;
                    proVolumesFunc(value);
                }
                self.domain_data_volumes = {
                    title: $translate.instant("aws.depart.quota"),
                    inUsed: parseInt(depUsedVolumes),
                    beAdded: parseInt(value),
                    total: depTotalVolumes
                };
            });
        });
    };
    //判断项目gigabytes总配额减去部门已使用的配额的函数
    var proGigFunc = function (value) {
        volumesDataSrv.getProHave("gigabytes").then(function (result) {
            if (result && result.data && result.data.length) {
                proTotalGig = result.data[0].hardLimit;
            }
            volumesDataSrv.getProUsed("gigabytes").then(function (result) {
                if (result && result.data && result.data.length) {
                    proUsedGig = result.data[0].inUse;
                } else {
                    proUsedGig = 0;
                }
                return proUsedGig;
            }).then(function (proUsedGig) {
                proRemainderGig = parseInt(proTotalGig) - parseInt(proUsedGig);
                if (proRemainderGig < value) {
                    self.isShowPro = true;
                    self.errorMessagePro = self.errorMess.snapshotPro;
                    // self.canConfirm=false;
                }
                self.project_data = {
                    title: $translate.instant("aws.project.quota") + "（GB）",
                    inUsed: parseInt(proUsedGig),
                    beAdded: parseInt(value),
                    total: proTotalGig
                };
            });

        });
    };
    //判断部门总配额gigabytes减去部门已使用的配额的函数
    var depGigFunc = function (value) {
        volumesDataSrv.getQuotaTotal("gigabytes").then(function (result) {
            if (result && result.data && result.data.length) {
                depTotalGig = result.data[0].hardLimit;
            } else {
                proGigFunc(value);
            }
            volumesDataSrv.getQuotaUsed("gigabytes").then(function (result) {
                if (result && result.data && result.data.length) {
                    depUsedGig = result.data[0].inUse;
                } else {
                    depUsedGig = 0;
                }
                return depUsedGig;
            }).then(function (depUsedGig) {
                depRemainderGig = parseInt(depTotalGig) - parseInt(depUsedGig);
                if (depRemainderGig < value) {
                    self.isShowDep = true;
                    self.errorMessageDep = self.errorMess.snapshotDep;
                    //self.canConfirm=false;
                    proGigFunc(value);
                } else {
                    proGigFunc(value);
                }
                self.domain_data = {
                    title: $translate.instant("aws.depart.quota") + "（GB）",
                    inUsed: parseInt(depUsedGig),
                    beAdded: parseInt(value),
                    total: depTotalGig
                };
            });
        });
    };
    depGigFunc(editData.size);
    depVolumesFunc(1);
    // if(localStorage.permission=="enterprise"){
    //     depGigFunc(editData.size);
    //     depVolumesFunc(1);
    // }

    // 计费信息
    getVolumeAmount(editData.size);
    function getVolumeAmount(value) {
        if (!self.showPriceTip) {
            return;
        }
        var option = {
            volumeSize: value,
            region: localStorage.regionName || "default"
        }
        volumesDataSrv.getVolumeAmount(option).then(function (result) {
            if (result && result.data && !isNaN(result.data)) {
                self.priceHour = result.data;
                self.priceHourAbout = result.data.toFixed(2);
                self.priceMonth = (result.data * 24 * 30).toFixed(2);
            }
        });
    }

    self.isShowError = function (value) {
        value = self.insForm.hostNum * value
        self.isShowDep = false;
        self.isShowPro = false;
        // self.canConfirm=true;
        if (value) {
            depGigFunc(value);
            getVolumeAmount(value);
        } else {
            depGigFunc(editData.size);
            getVolumeAmount(editData.size);
        }
        depVolumesFunc(self.insForm.hostNum);
    };
    var init_createData = function () {
        return {
            "name": "",
            "description": "",
            "size": editData.size
        };
    };
    self.volumeForm = init_createData();
    self.volumeForm.multiattach = false;
    self.canConfirm = function () {
        return !self.isShowDep && !self.isShowPro && !self.isShowDepVolumes && !self.isShowProVolumes && !self.cantSubmit
    };
    self.volumeConfirm = function () {
        /*if(!self.volumeForm.size){
            self.volumeForm.size=parseInt(editData.size);
        }*/
        var postVolumeParams = {
            snapshotId: editData.uid,
            name: self.volumeForm.name,
            description: self.volumeForm.description,
            size: parseInt(self.volumeForm.size),
            multiattach: self.volumeForm.multiattach,
            count: self.insForm.hostNum
        };
        if (!postVolumeParams.size) {
            postVolumeParams.size = parseInt(editData.size);
        }
        var enableVolume = function () {
            $uibModalInstance.close();
            //self.canConfirm=true;
            snapshotsDataSrv.enabledVolumeAction(postVolumeParams).then(function () {
                initSnapShotForm();
            });
        };
        if (self.createVolumeForm.$valid) {
            self.cantSubmit = true;
            // self.canConfirm=false;   
            enableVolume();
        } else {
            self.submitted = true;
            self.cantSubmit = false;
        }
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss("cancel");
        // selectedItems.items = {};
    };
}]);

snapshotsModule.directive("oldsizesnap", [function () {
    return {
        restrict: "A",
        require: "ngModel",
        link: function (scope, ele, attrs, expandVolumeSizeCtrl) {
            var tempSize = scope.volumeForm.size;
            function judgeSize(viewValue) {
                if (!viewValue) {
                    expandVolumeSizeCtrl.$setValidity("oldsizesnap", true);
                    return viewValue;
                }

                if (viewValue < tempSize) {
                    expandVolumeSizeCtrl.$setValidity("oldsizesnap", false);
                } else {
                    expandVolumeSizeCtrl.$setValidity("oldsizesnap", true);
                }
                return viewValue;
            }
            judgeSize();
            expandVolumeSizeCtrl.$parsers.push(judgeSize);
        }
    };
}]);
