import "./sriovSrv";
angular.module("sriovModule", ["sriovSrv"])
    .controller("sriovCtrl", ["$scope", "$uibModal","$translate","sriovsrv",function ($scope, $uibModal,$translate,sriovsrv) {
        let self = $scope;
        //常量
        let __Data = {
            checkboxes:{
                items:{}
            },
            canDel:false,
            canRetry:false,
            checkItems:[],
        }
        self = _.extend(self,__Data);
        //多选框勾选事件
        self.handleChange = function(item){
            self.checkItems = [];
            self.dataList.forEach(it=>{
                if(self.checkboxes.items[it.id]){
                    self.checkItems.push(it);
                }
            })
        }
        //监听选中列表
        self.$watch(function(){return self.checkItems},function(val,old){
            if(val&&val.length>0){
                self.canDel = true;
                if(self.checkItems[0].status==0){
                    self.canRetry = true;
                }else{
                    self.canRetry = false;
                }
            }else{
                self.canDel = false;
                self.canRetry = false;
            }
        },true)
        //添加
        self.createItem = function(){
            $uibModal.open({
                animation: true,
                templateUrl: "js/system/sr-iov/tmpl/option-sriov.html",
                controller: "createSriovCtrl",
                resolve: {
                    refresh: function() {
                        return self.initData;
                    }
                }
            });
        }
        //列表初始化
        self.initData = function(){
            self.checkItems = [];
            self.checkboxes.items = {};
            sriovsrv.getListApi().then(res=>{
                if(res&&res.data){
                    self.dataList = res.data;
                }
            })
        }

        self.delItem = function(){
            $uibModal.open({
                animation: true,
                templateUrl: "js/system/bigmem/tmpl/checkUser.html",
                controller: "delsriovCtrl",
                resolve: {
                    refresh: function() {
                        return self.initData;
                    },
                    editData:function(){
                        return self.checkItems
                    },
                    type:function(){
                        return "sriovdel"
                    }
                }
            });
        }

        self.refresh = function(){
            self.initData();
        }

        self.initData();
        self.$on("config.sriov.type",function(){
            self.initData();
        })


        //配置失败状态，重试
        self.reTryItem = function() {
            var content = {
                target: "sriovRetry",
                msg: "<span>" + $translate.instant("aws.sriov.dialog.retry") + "</span>",
                data: _.cloneDeep(self.checkItems),
                type: "warning",
                btnType: "btn-warning"
            };
            self.$emit("delete", content);
        };
        self.$on("sriovRetry", function(e,data) {
            sriovsrv.reTryApi(data[0].id).then(()=>{
                self.initData();
            });
        });
    }]).controller("createSriovCtrl",["$scope","refresh","$uibModalInstance","sriovsrv","$translate",function($scope,refresh,$uibModalInstance,sriovsrv,$translate){
        let self = $scope;
        //常量
        let __Data = {
            typeList:[
                {
                    id:1,
                    value:"flat",
                    text:$translate.instant("aws.sriov.dialog.flat"),
                },
                {
                    id:2,
                    value:"vlan",
                    text:$translate.instant("aws.sriov.dialog.vlan"),
                }
            ],
            instanDisabled:false,
            networkList:[],
            curType:"",
            submitInValid:false,
            isRangeShow:false,
            vfNumRange:{
                min:1,
                max:63
            },
            vlanRange:{
                min:2,
                max:4094
            },
            formData:{
                node:{
                    selected:""
                },
                network:{
                    selected:""
                },
                vfNum:"",
                sriovnetName:"",
                sriovnetType:""
            }
        }
        self = _.extend(self,__Data);
        
        
        //切换网络类型
        self.changeType = function(item){
            self.formData.sriovnetType = item.value;
            if(item.value=="vlan"){
                self.isRangeShow = true;
            }else{
                self.isRangeShow = false;
            }
        }
        //初始化
        self.initData = function(){
            sriovsrv.getNodeList().then(res=>{
                if(res&&res.data&&_.isArray(res.data)){
                    self.nodeList = res.data;
                    self.formData.node.selected = self.nodeList[0];
                    self.changeNode(self.nodeList[0]);
                }
            })
        }
        //切换节点
        self.changeNode = function(item){
            let data = {
                enterpriseUid:localStorage.enterpriseUid,
                regionUid:localStorage.regionUid,
                hostName:item.hostName
            }
            sriovsrv.getNodeCardAPi(data).then(res=>{
                if(res&&res.data&&_.isArray(res.data)){
                    console.log();
                    self.networkList = res.data.map((item,index)=>{
                        let data = {
                            id:index,
                            value:item
                        }
                        return data;
                    });
                    self.formData.network.selected = self.networkList[0]
                }
            })
        }
        self.confirm = function(field){
            if (field.$valid) {
                var postData = {
                    "regionKey":localStorage.regionKey,
                    "hostName":self.formData.node.selected.hostName,
                    "nicName":self.formData.network.selected.value,
                    "vfNum":self.formData.vfNum,
                    "sriovnetName":self.formData.sriovnetName,
                    "sriovnetType":self.formData.sriovnetType
                }
                if(self.formData.sriovnetType=="vlan"){
                    postData.vlanStart = self.formData.vlanStart
                    postData.vlanEnd = self.formData.vlanEnd
                    if(Number(postData.vlanStart)>Number(postData.vlanEnd)){
                        self.rangeError = true;
                        return;
                    }else{
                        self.rangeError = false;
                    }
                }

                self.instanDisabled = true;
                self.submitInValid = false;

                $uibModalInstance.close();
                sriovsrv.createApi(postData).then(function() {
                    refresh();
                }).finally(()=>{
                    self.instanDisabled = false;
                });
            } else {
                self.submitInValid = true;
            }
        }
        self.changeType(self.typeList[0]);
        self.initData();
    }]).controller("delsriovCtrl",["$scope","refresh","type","editData","$uibModalInstance","sriovsrv",function(scope,refresh,type,editData,$uibModalInstance,sriovsrv){
        let self = scope;
        self.submitInValid = false;
        self.instanDisabled = false;
        self.node = {
            username:"",
            password:"",
        }
        self.modalType = type;
        self.confirm = function(field){
            if (field.$valid) {
                self.instanDisabled = true;
                let ids = editData.map(item=>{
                    return item.id
                })
                var postData = {
                    "userName": self.node.username,
                    "password": self.node.password,
                    "enterpriseLoginName": localStorage.enterpriseLoginName,
                    "sriovIds": ids
                }
                $uibModalInstance.close();
                sriovsrv.delSriovApi(postData).then(function() {
                    refresh();
                }).finally(()=>{
                    self.instanDisabled = false;
                });
            } else {
                scope.submitInValid = true;
            }
        }
    }])
