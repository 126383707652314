import "./cephpgSrv";

var cephpgModule = angular.module("cephpgModule",["ngMessages","cephpgSrvModule"]);
cephpgModule.controller('cephpgCtrl',['$scope','cephpgSrv',"$http","$translate","alertSrv",
	function(scope,cephpgSrv,$http,$translate,alert){
		scope.dataList = [];
		if(localStorage.permission == 'enterprise'){
			scope.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')]
		}else{
			scope.menuGuide = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_ConfigManagement'),$translate.instant('aws.menu.System_Storage')]
		}
		function getCephData() {
			if(!scope.services.ceph) return;
			cephpgSrv.getCephData().then(res => {
				if(res && res.data && res.data.length){
					for (let i = 0; i < res.data.length; i++) {
						const item = res.data[i];
						item.ratio = Math.round(item.ratio*10000)/100;
					}
					scope.dataList = res.data;
					scope.dataList_origin = angular.copy(scope.dataList);
				}
			})
		}
		getCephData();

		scope.$watch(function(){
			return scope.dataList;
		},function(value){
			if(scope.dataList_origin){
				scope.conf_value_change = value.some((item,i) => item.ratio != scope.dataList_origin[i].ratio);
			}
		},true)

		scope.saveCephpg = function(form){
			scope.submitValid = false;
			if(form.$valid){
				clearTimeout(timer);
				let list = angular.copy(scope.dataList);
				let sum = 0;
				let timer = null;
				for (let i = 0; i < list.length; i++) {
					const item = list[i];
					sum += Number(item.ratio);
					item.ratio = Number(item.ratio)/100;
				}
				if(sum == 100){
					scope.isNotTotal100 = false;
				}else{
					scope.isNotTotal100 = true;
					timer = setTimeout(() => {
						scope.isNotTotal100 = false;
					}, 3000);
					return;
				}
				cephpgSrv.saveCephpg(list).then(() => {
					alert.set("", $translate.instant("aws.system.cephpg.cephpgLoading"), "building");
				})
			}else{
				scope.submitValid = true;
			}
		};

		scope.$on("cephpgConfig", function(e, data){
			if(data.indexOf('adjust.ceph.pg.number.success')>-1){
				alert.set("", $translate.instant("aws.system.cephpg.cephpgSuccess"), "success", 5000);
			}else if(data.indexOf("adjust.ceph.pg.number.fail") > -1){
				alert.set("", $translate.instant("aws.system.cephpg.cephpgFailed"), "error", 5000);
			}
			getCephData();
		})
	}
]);