import "./overviewSrv";
import "../department/departmentSrv";
import "../monitor/alarmManagement/alarmEventSrv";
import "../ticket/ticketSrv";
import "../department/depviewSrv"
import "../project/projectSrv";
import { PiePanelDefault } from "../chartpanel";
import "../cvm/networks/networksSrv"
import "../cvm/instances/instancesSrv"
import "../cvm/recycle/recycleSrv"

var singleModule = angular.module("viewaModules", ["ngTable", "ngAnimate", "ui.bootstrap", "overviewSrvModule", "depviewsrv", "ngSanitize", "operatelogSrv", "alarmEventSrvModule", "peojectsrv", "networksSrvModule","instancesSrv","recycleSrvModule"]);
singleModule.controller("viewaCtrl", ["$scope", "$rootScope", "$uibModal", "$location", "checkedSrv", "NgTableParams", "overviewSrv", "depviewsrv", "$translate", "operatelogSrv", "departmentDataSrv", "alarmEventSrv", "ticketsrv", "projectDataSrv",
 "$route", "GLOBAL_CONFIG", "networksSrv","instancesSrv","recycleSrv", "vmFuncSrv","localInit","commonFuncSrv",
 function($scope, rootScope, $uibModal, $location, checkedSrv, NgTableParams, overviewSrv, depviewsrv, $translate, operatelogSrv, 
    departmentDataSrv, alarmEventSrv, ticketsrv, projectDataSrv, $route, GLOBAL_CONFIG, networksSrv,instancesSrv,
    recycleSrv,vmFuncSrv,localInit,commonFuncSrv) {
    var self = $scope;
    self = vmFuncSrv.storageFunc(self,instancesSrv);
    localStorage.vmware_flag=1;
    var enterpriseUid = localStorage.enterpriseUid ? localStorage.enterpriseUid : "";
    var userId = localStorage.$USERID ? localStorage.$USERID : "";
    //localStorage.$QCLOUD_AUTH_TOKEN = qcloudUid?qcloudUid:"";
    //rootScope.qcloudUrl = qcloudUid ? "/qcloud/#/?token=" + qcloudUid : '';
    //rootScope.vmwareUrl = "/vmware/#/?url=datacenter&token=" + localStorage.$AUTH_TOKEN;
    self.userName = localStorage.userName;
    /*单数据中心*/
    self.activeRegionKey = localStorage.regionKey;
    rootScope.vmwareLoadding = false;
    self.intoSingle = function(item){
        //rootScope.vmwareLoadding = true;
        var data ={
            regionKey:item.regionKey
        }
        overviewSrv.changeRegion(data).then(function(res){
            if(res&&res.data&&res.data.token){
                var result = {
                    data:angular.copy(res)
                }
                //localStorage.$AUTH_TOKEN =  res.data.token;
                //localStorage.regionName = item.regionName;  
                //localStorage.regionKey = item.regionKey;
                self.activeRegionKey = item.regionKey;

                localInit.localstorageInit(result.data.data);
                self.$emit('region-refresh',{type:'changeRegion',data:result.data.data});
                rootScope.services = {
                    "backups":localStorage.backupsService,
                    "cinder":localStorage.cinderService,
                    "ceph":localStorage.cephService,
                    "localDisk":localStorage.localDiskService
                }
                $route.reload();
            }
        })
        // .finally(function(){
        //     rootScope.vmwareLoadding = false;
        // }) 
    }

    //存储使用量 已使用显示的颜色 根据使用占比显示不同的颜色
    function overviewBackground(piepercent) {
        var backgroundColor = ''
        piepercent= Number(piepercent);
        if(piepercent>=0 && piepercent<=30) {
            backgroundColor = "#1abc9c"
        }else if(piepercent>30 && piepercent<=50) {
            backgroundColor = "#51a3ff"
        }else if(piepercent>50 && piepercent<=65){
            backgroundColor = "#4e80f5"
        }else if(piepercent>65 && piepercent<=75) {
            backgroundColor = "#f39c12"
        }else if(piepercent>75 && piepercent<=85) {
            backgroundColor = "#e67e22"
        }else if(piepercent>85 && piepercent<=95) {
            backgroundColor = "#e74c3c"
        }else {
            backgroundColor = "#c0392b"
        }
        return backgroundColor;
    }
    
    overviewSrv.getVmStatus().then(function(res) {
        if (res && res.data) {
            self.vmStatusData = {
                "colors":["#1abc9c","#f39c12","#e74c3c","#666666","#c7c5c5"],
                "type":"pie",
                "width":200,
                "height":200,
                "outerRadius":75,
                "innerRadius":50,
                "total":res.data.total,
                "data":[
                    {"name":$translate.instant("aws.overview.run"),"value":res.data.active,"status":"active"},
                    {"name":$translate.instant("aws.overview.poweroff"),"value":res.data.shutoff,"status":"shutoff"},
                    {"name":$translate.instant("aws.overview.falut"),"value":res.data.error,"status":"error"},
                    {"name":$translate.instant("aws.overview.other"),"value":res.data.others,"status":"other"},
                    {"name":$translate.instant("aws.overview.recycleBin"),"value":res.data.softDeleted,"status":"softDeleted"}
                ],
                "title":"",
                "id":"",
                "pieType":"category",
                "progressRate":true
            }

            self.annularPara2 = {
                "colorList": self.vmStatusData.colors,
                "center": ["50%","50%"],
                "radius": ["75%","100%"],
            }
            
            self.annularData2 = {
                "id": "annularData2",
                "chartData": self.vmStatusData.data.map(item => {
                    if(item.status == 'other'){
                        item.percent = (res.data['others'] / res.data.total * 100).toFixed(1) + '%'
                    }else{
                        item.percent = (res.data[item.status] / res.data.total * 100).toFixed(1) + '%'
                    }
                    return JSON.parse(JSON.stringify(item))
                })
            }
        }
    });
    
    /*公网ip当只有一个时显示饼图，多个时显示条形图*/
    overviewSrv.getExternalNetworks().then(function(res) {
        if (res && res.data){
            self.externalType = res.data.length;
            if(self.externalType<2){
                self.ipDetail = {
                    total:res.data[0].totalNetworkIpAvailability,
                    name:res.data[0].title
                };
                self.ipUseChart = new PiePanelDefault();
                self.ipUseChart.panels.data = [
                    { name: $translate.instant("aws.overview.inUsed"), value: res.data[0].usedNetworkIpAvailability },
                    { name: $translate.instant("aws.overview.unUsed"), value: (res.data[0].totalNetworkIpAvailability-res.data[0].usedNetworkIpAvailability)}
                ];
                self.ipUseChart.panels.pieType = "percent";
                self.ipUseChart.panels.colors = ["#51a3ff", "#e5e5e5"];

                self.annularPara6 = {
                    "colorList": self.ipUseChart.panels.colors,
                    "center": ["50%","50%"],
                    "radius": ["75%","100%"],
                }
                self.annularData6 = {
                    "id": "annularData6",
                    "chartData": self.ipUseChart.panels.data.map(item => {
                        item.percent = (item.value / self.ipDetail.total * 100).toFixed(1) + '%'
                        return JSON.parse(JSON.stringify(item))
                    })
                }
            }else{
                self.netWorkType =[];
                res.data.forEach(function(item){
                    var netItem = {"title":item.title,"inUsed":item.usedNetworkIpAvailability,"beAdded":1,"total":item.totalNetworkIpAvailability}
                    self.netWorkType.push(netItem)
                })
            }
        }
    })

    function handleChartData(title,chartData,unit,ints){
        var xAxis = [];
        var series = [];
        for(var i=1;i<chartData.columns.length;i++){
            var centername = chartData.columns[i];
            var seriesItem = {
                name :centername,
                areaStyle: {normal: {}},
                type:'line',
                data:[]
            }
            for(var j=0;j<chartData.values.length;j++){
                var columnsItem = chartData.values[j];
                var timeDate = new Date(columnsItem[0])
                var timeStr = (timeDate.getMonth()+1)+'/'+timeDate.getDate();
                xAxis.push(timeStr);
                seriesItem.data.push(columnsItem[i]?columnsItem[i]:0) 
            }
            series.push(seriesItem)
        }
        return {
                legend: {
                    show: false,
                    orient: 'vertical',
                    top: 'middle',
                    left: 'auto',
                    right: 10
                },
                color:[
                    "#1abc9c",
                    "#51a3ff",
                    "#1bbc9d",
                    "#b6a2dd",
                    "#e67f23",
                    "#c0392b",
                    "#ff754a"
                ],
                unit:unit,
                title:title,
                xAxis:xAxis,
                toolboxShow: false,
                series:series,
                ints:ints
            }
    }
    /*获取vlan/vxlan资源总量*/
    overviewSrv.getVlanData().then(function(res){
        if(res&&res.data){
            self.VlanData = res.data;
            /*获取Vlan使用数据详情*/
            overviewSrv.getVlanType().then(function(res){
                if(res&&res.data){
                    self.vlanType = res.data.type.toUpperCase();
                    overviewSrv.getVlanFold(res.data.type).then(function(res){
                        var vlanHistory = [];
                        if(res&&res.data){
                            var columns = res.data.columns;
                            var values = res.data.values;
                            for(var k=1;k<columns.length;k++){
                                var regionNamea = columns[k];
                                for (var h = 0; h < values.length; h++){
                                    if(res.data.values[h][k]){
                                        //res.data.values[h][k] = ((res.data.values[h][k])/self.VlanData.totleVlanId)*100
                                    }
                                }
                            }
                            //vlanHistory.push(res.data)
                            /*VLAN标签*/
                            // self.vlanData = {
                            //     "colors":["#1bbc9d"],
                            //     "type":"area",
                            //     "width":550,
                            //     "height":220,
                            //     "margin":{
                            //         "left":60,
                            //         "right":15,
                            //         "top":15,
                            //         "bottom":30},
                            //     "unit":"%",
                            //     "data":vlanHistory,
                            //     "title":"",
                            //     "subTitle":"",
                            //     "priority":"a0",
                            //     "chartName":"",
                            //     "xAxisType":"date"}
                            self.vlanData =  handleChartData('',res.data,'')
                        }
                    })
                }
            })
        }
    })

    /*公网类型*/
    function getRegionList(){
        self.regionModel = {
            selected: "" 
        };
        overviewSrv.getRegions().then(function(res) {
            if(res&&res.data){
                self.regionList = res.data.filter(item=>{
                    return item.status==3;
                })
                localStorage.regionNum = self.regionList.length;
                self.regionModel.selected = self.regionList[0];
            }
            
        })
    }
    getRegionList();
    function addMenuByAD() {
        overviewSrv.getAdConfig().then(res=>{
            if(res&&res.data) {
                if(res.data.enable) {
                    // 对接AD去掉createuser,createvpn默认选中显示
                    self.addMenu =[
                        {"name":"createinstance","value":true,"able":false,"icon":"icon-aw-cloud-server"},
                        {"name":"createnetwork","value":true,"able":false,"icon":"icon-aw-network1"},
                        {"name":"createdisk","value":true,"able":false,"icon":"icon-aw-hard-disk"},
                        {"name":"createvpn","value":true,"able":false,"icon":"icon-aw-router2"}
                    ];
                }else {
                    self.addMenu =[
                        {"name":"createinstance","value":true,"able":false,"icon":"icon-aw-cloud-server"},
                        {"name":"createnetwork","value":true,"able":false,"icon":"icon-aw-network1"},
                        {"name":"createdisk","value":true,"able":false,"icon":"icon-aw-hard-disk"},
                        {"name":"createuser","value":true,"able":false,"icon":"icon-aw-sfgl"},
                        {"name":"createvpn","value":false,"able":true,"icon":"icon-aw-router2"}
                    ];
                }
            }else {
                self.addMenu =[
                    {"name":"createinstance","value":true,"able":false,"icon":"icon-aw-cloud-server"},
                    {"name":"createnetwork","value":true,"able":false,"icon":"icon-aw-network1"},
                    {"name":"createdisk","value":true,"able":false,"icon":"icon-aw-hard-disk"},
                    {"name":"createuser","value":true,"able":false,"icon":"icon-aw-sfgl"},
                    {"name":"createvpn","value":false,"able":true,"icon":"icon-aw-router2"}
                ];
            }
        });
    }
function initAddMenu(){
    addMenuByAD();
    let regionBusiAuth=localStorage.regionBusiAuth!=2?JSON.parse(localStorage.regionBusiAuth):[];
    let netIndex=regionBusiAuth.indexOf("3");
    let storageIndex=regionBusiAuth.indexOf("2");
    if(netIndex==-1){
        self.addMenu.splice(4,1);
    }
    if(storageIndex==-1){
        //此时vpn可点击
        self.addMenu.filter(function(item){
            if(item.name=='createvpn'){
               item.able=false;
               item.value=true;
            }
        });  
        self.addMenu.splice(2,1);
    }
}
initAddMenu();


self.seeAllIp = function(){
    $uibModal.open({
        animation: true,
        templateUrl:"ipRegion.html",
        controller: "ipCtrl",
        scope:self
    })
}

self.seeAllStorage = function(){
    $uibModal.open({
        animation: true,
        templateUrl:"storageRegion.html",
        scope:self
    })
}

self.checkBoxFun = function(){
    var checkNum = 0;
    self.addMenu.forEach(function(item){
        if(item.value){
            checkNum++;
        }
    })
    if(checkNum>=4){
        self.addMenu.forEach(function(item){
            if(!item.value){
                item.able = true;
            }
        })
    }else{
        self.addMenu.forEach(function(item){
            item.able = false;
        })
    }
}
//
    function bytesToSize(bytes) {
        var k = 1024, // or 1000
            sizes = ["GB", "TB", "PB", "EB", "ZB", "YB"], // sizes = ['Bytes', 'KB', 'MB',"GB", "TB", "PB", "EB", "ZB", "YB"],
            i;
        if (bytes < 0) i = Math.floor(Math.log(-bytes) / Math.log(k));
        if (bytes >= 0 && bytes < 1) return { a: 0, b: "0 GB" };
        if (bytes >= 1)
            i = Math.floor(Math.log(bytes) / Math.log(k));
        //var gtZero = ((bytes / Math.pow(k, i)).toPrecision(3)) >= 0 ? ((bytes / Math.pow(k, i)).toPrecision(3)) : 0;
        //解决页面使用科学计算法展示数据的bug
        var gtZero = (bytes / Math.pow(k, i)).toPrecision(3) >= 0 ? (bytes / Math.pow(k, i)): 0;
        if(gtZero.toString().indexOf(".")>-1) {
            if(gtZero.toString().split(".")[1].length>2){
                gtZero = gtZero.toFixed(2)
            }
        }
        if(i<0) i = 0;
        return {
            // a: (bytes).toPrecision(3),
            a: gtZero,
            b: gtZero + " " + sizes[i]
        };
    }
    //标准版不显示项目，改为显示网络
    overviewSrv.getDomainProTotal().then(function(result) {
        self.domainTotal = 0;
        self.projectTotal = 0;
        if (result && result.data) {
            self.domainTotal = result.data.domain_total;
            self.projectTotal = result.data.project_total;
        }
    });
    // if (localStorage.permission == "stand") {
    //     networksSrv.getNetworksTableData().then(function(result) {
    //         if (result && result.data && angular.isArray(result.data)) {
    //             self.netTotal = result.data.length
    //         }
    //     })
    // } else {
    //     overviewSrv.getDomainProTotal().then(function(result) {
    //         self.domainTotal = 0;
    //         self.projectTotal = 0;
    //         if (result && result.data) {
    //             self.domainTotal = result.data.domain_total;
    //             self.projectTotal = result.data.project_total;
    //         }
    //     });
    // }

    overviewSrv.getalarmEvents().then(function(result) {
        self.alarmEventsTotal = 0;
        if (result && result.total) {
            self.alarmEventsTotal = result.total;
        }
    });
    overviewSrv.getHostInfo().then(function(result) {
        if (result && result.data) {
            self.hostTotal = result.data.host_total ? result.data.host_total : 0;
            //self.hostCPUTotal = result.data.host_cpu_total ? result.data.host_cpu_total : 0;
            //self.hostMemoryTotal = result.data.host_memory_total?(result.data.host_memory_total/1024).toFixed(0):0;
            self.registedHosts = result.data.host_registered_total ? result.data.host_registered_total : 0;
            self.unregistedHosts = self.hostTotal - self.registedHosts;
            self.hddTotal = result.data.host_disk_hdd_total ? result.data.host_disk_hdd_total : 0;
            self.ssdTotal = result.data.host_disk_ssd_total ? result.data.host_disk_ssd_total : 0;
            //self.storageTotal = bytesToSize(self.hddTotal + self.ssdTotal); //GB

            self.insRegisterChart = new PiePanelDefault();
            self.insRegisterChart.panels.data = [
                { name: $translate.instant("aws.overview.registed"), value: self.registedHosts },
                { name: $translate.instant("aws.overview.unRegisted"), value: self.unregistedHosts }
            ];
            self.insRegisterChart.panels.pieType = "percent";
            self.insRegisterChart.panels.progressRate = false;
            self.dynamicPopover = {
                templateUrl: "registedInsTable.html"
            };

            overviewSrv.getOshypervisorsStatistics().then(function(res) {
                if (res && res.data) {
                    return res.data;
                }
            }).then(function(statisticsData) {
                overviewSrv.getConfigValues().then(function(res) {
                    if (res && res.data) {
                        var cpuConfigvalue = res.data.cpu_allocation_ratio ? Number(res.data.cpu_allocation_ratio) : 1; 
                        var ramConfigValue = res.data.ram_allocation_ratio ? Number(res.data.ram_allocation_ratio) : 1;
                        var diskConfigValue = res.data.disk_allocation_ratio ? Number(res.data.disk_allocation_ratio) : 1; 

                        if (statisticsData) {
                            // vcpus_used-- 原cpu使用数量==>变更为云主机的cpu数量  vcpus_lb--负载均衡的数量
                            // memory_mb_used-- 原因内存使用数量==>变更为云主机的内存数量  memory_mb_lb--负载均衡的数量
                            statisticsData.vcpus = statisticsData.vcpus ? statisticsData.vcpus : 0;
                            statisticsData.vcpus_used = statisticsData.vcpus_used ? statisticsData.vcpus_used : 0;
                            statisticsData.vcpus_gc = statisticsData.vcpus_gc ? statisticsData.vcpus_gc : 0;
                            statisticsData.vcpus_lb = statisticsData.vcpus_lb ? statisticsData.vcpus_lb : 0;
                            statisticsData.memory_mb = statisticsData.memory_mb ? statisticsData.memory_mb : 0;
                            statisticsData.reserved_host_memory_mb = statisticsData.reserved_host_memory_mb ? statisticsData.reserved_host_memory_mb : 0;
                            statisticsData.memory_mb_used = statisticsData.memory_mb_used ? statisticsData.memory_mb_used : 0;
                            statisticsData.memory_mb_lb = statisticsData.memory_mb_lb ? statisticsData.memory_mb_lb : 0;
                        }
                        //cpu 
                        self.hostCPUTotal = statisticsData.vcpus * cpuConfigvalue;
                        self.allocatedCpu = statisticsData.vcpus_used + statisticsData.vcpus_lb;// 已使用的cpu数量(云主机和负载均衡的和)
                        self.insCpu = statisticsData.vcpus_used// 云主机的cpu数量
                        self.lbCpu = statisticsData.vcpus_lb;// 负载均衡的cpu数量
                        self.recycleBinCpu = statisticsData.vcpus_gc;
                        self.unAlloactedCpu = self.hostCPUTotal - self.allocatedCpu - self.recycleBinCpu;
                        self.cpuUseChart = new PiePanelDefault();
                        
                        self.cpuUseChart.panels.data = [
                            { name: $translate.instant("aws.overview.inUsed"), value: self.allocatedCpu },
                            { name: $translate.instant("aws.overview.unUsed"), value: self.unAlloactedCpu },
                            { name: $translate.instant("aws.overview.recycleBin"),value: self.recycleBinCpu }
                        ];
                        // 用来处理tooltip的hover的提示展示的是数据
                        if(statisticsData.vcpus_lb) {
                            // vcpus_lb不为null 增强型负载均衡开关开启时 echart的toolTips需要展示云主机和负载均衡的cpu
                            self.cpuUseChart.panels.toolTipData = [
                                { name: $translate.instant("aws.instances.instance"), value: self.insCpu },
                                { name: $translate.instant("aws.floatingIps.loadBalance"), value: self.lbCpu },
                            ];
                        }else{
                            // vcpus_lb为null 增强型负载均衡开关关闭时 eChart的toolTips不需要展示负载均衡的cpu
                            self.cpuUseChart.panels.toolTipData = [
                                { name: $translate.instant("aws.instances.instance"), value: self.insCpu },
                            ];
                        }
                        // self.cpuUseChart.panels.pieType = "percent";
                        self.cpuUseChart.panels.pieType = "category";
                        self.cpuUseChart.panels.colors = ["#1ABC9C", "#ddd","#c7c5c5"];
                        let _cpuPercent = Number(self.allocatedCpu) / Number(self.hostCPUTotal) *100;
                        self.cpuUseChart.panels.cpuPercent = Math.ceil(_cpuPercent) === _cpuPercent ? _cpuPercent + "%":(_cpuPercent).toFixed(1) + "%";

                        self.annularPara3 = {
                            "colorList": self.cpuUseChart.panels.colors,
                            "center": ["50%","50%"],
                            "radius": ["75%","100%"],
                        }
                        const tempArr = self.cpuUseChart.panels.toolTipData
                        self.annularData3 = {
                            "id": "annularData3",
                            "chartData": self.cpuUseChart.panels.data.map(item => {
                                item.percent = (item.value / self.hostCPUTotal * 100).toFixed(1) + '%'
                                // 携带参数
                                item.arrList = tempArr.map(item=>{
                                     return`${item.name}：${item.value}` 
                                })
                                return JSON.parse(JSON.stringify(item))
                            })
                        }

                        //内存
                        //flavor中内存有0.5G的选项，内存展示需保留一位小数
                        let org_hostMemoryTotal = (Number(statisticsData.memory_mb)*ramConfigValue - Number(statisticsData.reserved_host_memory_mb))/1024;
                        // let org_allocatedRam = (Number(statisticsData.memory_mb_used) - Number(statisticsData.reserved_host_memory_mb)) / 1024;
                        //已使用的内存数量= （云主机数量 - 预留的内存）/ 1024 变更为 已使用的内存数量= （负载均衡数量 + 云主机数量 - 预留的内存）/ 1024
                        let org_allocatedRam = (Number(statisticsData.memory_mb_lb) + Number(statisticsData.memory_mb_used) - Number(statisticsData.reserved_host_memory_mb)) / 1024;
                        let org_recycleBinRam = Number(statisticsData.memory_mb_gc) / 1024;
                        self.hostMemoryTotal = Math.ceil(org_hostMemoryTotal) === org_hostMemoryTotal ? org_hostMemoryTotal:org_hostMemoryTotal.toFixed(1);
                        self.allocatedRam = Math.ceil(org_allocatedRam) === org_allocatedRam ? org_allocatedRam:org_allocatedRam.toFixed(1);
                        self.recycleBinRam = Math.ceil(org_recycleBinRam) === org_recycleBinRam ? org_recycleBinRam:org_recycleBinRam.toFixed(1);
                        let _unAllocatedRam = Number(self.hostMemoryTotal) - Number(self.allocatedRam) - Number(self.recycleBinRam);
                        self.unAllocatedRam = Math.ceil(_unAllocatedRam) === _unAllocatedRam?_unAllocatedRam:_unAllocatedRam.toFixed(1);
                        let _ramPercent = Number(self.allocatedRam) / Number(self.hostMemoryTotal);
                        self.ramPercent = Math.ceil(_ramPercent) === _ramPercent ? _ramPercent + "%":(_ramPercent).toFixed(1) + "%";
                        let memory_mb_used = (Number(statisticsData.memory_mb_used) - Number(statisticsData.reserved_host_memory_mb)) / 1024;
                        self.memory_mb_used = Math.ceil(memory_mb_used) === memory_mb_used ? memory_mb_used : memory_mb_used.toFixed(1);
                        let memory_mb_lb = Number(statisticsData.memory_mb_lb) / 1024;
                        self.memory_mb_lb = Math.ceil(memory_mb_lb) === memory_mb_lb ? memory_mb_lb : memory_mb_lb.toFixed(1);
                        self.memoryUseChart = new PiePanelDefault();
                        self.memoryUseChart.panels.data = [
                            { name: $translate.instant("aws.overview.inUsed"), value: self.allocatedRam },
                            { name: $translate.instant("aws.overview.unUsed"), value: self.unAllocatedRam },
                            { name: $translate.instant("aws.overview.recycleBin"), value: self.recycleBinRam }
                        ];
                        if(statisticsData.memory_mb_lb) {
                            // memory_mb_lb不为null 增强型负载均衡开关开启时 echart的toolTips需要展示云主机和负载均衡的内存
                            self.memoryUseChart.panels.toolTipData = [
                                { name: $translate.instant("aws.instances.instance"), value: self.memory_mb_used },
                                { name: $translate.instant("aws.floatingIps.loadBalance"), value: self.memory_mb_lb },
                            ];
                        }else{
                            // memory_mb_lb为null 增强型负载均衡开关关闭时 eChart的toolTips不需要展示负载均衡的内存
                            self.memoryUseChart.panels.toolTipData = [
                                { name: $translate.instant("aws.instances.instance"), value: self.memory_mb_used },
                            ];
                        }
                        // self.memoryUseChart.panels.pieType = "percent";
                        self.memoryUseChart.panels.pieType = "category";
                        self.memoryUseChart.panels.colors = ["#1ABC9C", "#ddd","#c7c5c5"];
                        let _ramTwoPercent = Number(self.allocatedRam) / Number(self.hostMemoryTotal) *100;
                        self.memoryUseChart.panels.ramTwoPercent = Math.ceil(_ramTwoPercent) === _ramTwoPercent ? _ramTwoPercent + "%":(_ramTwoPercent).toFixed(1) + "%";

                        self.annularPara5 = {
                            "colorList": self.memoryUseChart.panels.colors,
                            "center": ["50%","50%"],
                            "radius": ["75%","100%"],
                        }
                        const memoryTempArr = self.memoryUseChart.panels.toolTipData;
                        self.annularData5 = {
                            "id": "annularData5",
                            "chartData": self.memoryUseChart.panels.data.map(item => {
                                item.percent = (item.value / self.hostMemoryTotal * 100).toFixed(1) + '%'
                                // 携带参数
                                item.arrList = memoryTempArr.map(item=>{
                                    return `${item.name}：${item.value}`
                                })
                                return JSON.parse(JSON.stringify(item))
                            })
                        }

                        
                        function storageChartFunc(used, total, recycle,type,lbUsed) {
                            self.type = 'isLocal';
                            // used包含云主机+负载均衡+回收站；sdata[1]总共的(云主机+负载均衡+回收站+未使用的)；recycle回收站的；lbUsed负载均衡的
                            self.storageChart = new PiePanelDefault();
                            // isLocal本地盘没有回收站 需要区分
                            if(type === 'isLocal') {
                                self.storageChart.panels.data = [
                                    { name: $translate.instant("aws.overview.inUsed"), value: bytesToSize(used - recycle).a, valueB: bytesToSize(used - recycle).b }, 
                                    { name: $translate.instant("aws.overview.unUsed"), value: bytesToSize(total - used).a, valueB: bytesToSize(total - used).b },
                                ];
                            }else {
                                self.storageChart.panels.data = [
                                    // { name: $translate.instant("aws.overview.inUsed"), value: bytesToSize(used + lbUsed).a, valueB: bytesToSize(used + lbUsed).b },
                                    // { name: $translate.instant("aws.overview.unUsed"), value: bytesToSize(total - used - recycle - lbUsed).a, valueB: bytesToSize(total - used- recycle - lbUsed).b },
                                    // { name: $translate.instant("aws.overview.recycleBin"), value: bytesToSize(recycle).a, valueB: bytesToSize(recycle).b }
                                    { name: $translate.instant("aws.overview.inUsed"), value: (used - recycle).toFixed(1), valueB: bytesToSize(used - recycle).b },
                                    { name: $translate.instant("aws.overview.unUsed"), value: (total - used).toFixed(1), valueB: bytesToSize(total - used).b },
                                    { name: $translate.instant("aws.overview.recycleBin"), value: recycle.toFixed(1), valueB: bytesToSize(recycle).b }
                                ];
                                if(lbUsed > 0) {
                                    // 负载均衡开关开启
                                    self.storageChart.panels.toolTipData = [
                                        { name: $translate.instant("aws.instances.instance"), value: (used - lbUsed - recycle).toFixed(1), valueB: bytesToSize(used - lbUsed - recycle).b },
                                        { name: $translate.instant("aws.floatingIps.loadBalance"), value: lbUsed.toFixed(1), valueB: bytesToSize(lbUsed).b },
                                    ]
                                }else {
                                    // 负载均衡开关关闭
                                    self.storageChart.panels.toolTipData = [
                                        { name: $translate.instant("aws.instances.instance"), value: (used - lbUsed - recycle).toFixed(1), valueB: bytesToSize(used - recycle).b },
                                    ]
                                }
                            }
                            self.storageChart.panels.colors = ["#1ABC9C", "#ddd","#c7c5c5"];
                            // self.storageChart.panels.pieType = "percent";
                            self.storageChart.panels.pieType = "category";
                            let _storagePercent = Number(used - recycle) / Number(total) *100;
                            self.storageChart.panels.storagePercent = Math.ceil(_storagePercent) === _storagePercent ? _storagePercent + "%":(_storagePercent).toFixed(1) + "%";

                            
                            self.annularPara4 = {
                                "colorList": self.storageChart.panels.colors,
                                "center": ["50%","50%"],
                                "radius": ["75%","100%"],
                            }
                            if(type === 'isLocal') {
                                self.annularData4 = {
                                    "id": "annularData4",
                                    "chartData": self.storageChart.panels.data.map(item => {
                                        item.percent = (item.value / total * 100).toFixed(1) + '%'
                                        return JSON.parse(JSON.stringify(item))
                                    })
                                }
                            }else{
                                let storageTempArr = self.storageChart.panels.toolTipData;
                                self.annularData4 = {
                                    "id": "annularData4",
                                    "chartData": self.storageChart.panels.data.map(item => {
                                        item.percent = (item.value / total * 100).toFixed(1) + '%'
                                        // 携带参数
                                        item.arrList = storageTempArr.map(item=>{
                                            return`${item.name}：${item.value}` 
                                        })
                                        return JSON.parse(JSON.stringify(item))
                                    })
                                }
                            }
                            
                        }

                        // 处理多个存储池的数据 sdata[0]包含云主机+负载均衡+回收站；sdata[1]总共的(云主机+负载均衡+回收站+未使用的)；sdata[3]回收站的；sdata[4]负载均衡的
                        function dealMultipleStorageChartData(sdata,item){
                            let storageAllocateChart = [];
                            storageAllocateChart.push({
                                domainName: item.disPlayName,
                                lb: Number(sdata[4]),// 负载均衡
                                inUsed: Number(sdata[0]),// 云主机+负载均衡+回收站
                                total: Number(sdata[1]),// 总共的(云主机+负载均衡+回收站+ 未使用)
                                recycle: Number(sdata[3]),// 回收站的
                                noUsed: Number(sdata[1]) - Number(sdata[0]),// 未使用的
                                inUsedUnit:bytesToSize(Number(sdata[0])).b,// 已使用的带单位
                                totalUnit:bytesToSize(sdata[1]).b,// 总共的(云主机+负载均衡+回收站+ 未使用)带单位
                                recycleUnit:bytesToSize(sdata[3]).b,// 回收站的带单位
                                noUsedUnit:bytesToSize(Number(sdata[1]) - Number(sdata[0])).b,// 未使用的带单位
                                insAndLbUnit:bytesToSize(sdata[0] - sdata[3]).b,// tips展示的云主机和负载均衡之和带单位
                                insUnit:bytesToSize(sdata[0] - sdata[3] - sdata[4]).b,// tips中展示的云主机带单位
                                lbUnit:bytesToSize(sdata[4]).b,// tips中展示的负载均衡带单位
                                percent:((Number(sdata[0]))/Number(sdata[1])*100).toFixed(1) + "%",// 已使用的百分比
                                recycleColor: "#c7c5c5",// 回收站占比的颜色
                                inUsedColor:overviewBackground(((Number(sdata[0])-Number(sdata[3]))/Number(sdata[1])*100).toFixed(1)),// 云主机和负载均衡之和的占比颜色
                                noUsedColor:"#e5e5e5",// 未使用占比的颜色
                                recycleWidth: (Number(sdata[3])/Number(sdata[1])*100).toFixed(1) + "%",// 回收站的占比宽度
                                inUsedWidth: (Number(sdata[0] - Number(sdata[3]))/Number(sdata[1])*100).toFixed(1) + "%",// 云主机和负载均衡之和的占比宽度
                                noUsedWidth: (100 - (Number(sdata[0])/Number(sdata[1])*100).toFixed(1)) + '%' // 未使用的占比宽度
                            });
                            return storageAllocateChart;
                        }

                        //ssd
                        /*if (localStorage.permission == "enterprise") { //企业版用的是ceph或存储
                            self.storageTotal = bytesToSize(self.hddTotal + self.ssdTotal); //GB
                            overviewSrv.getAllocatedQuatas({
                                name: "gigabytes",
                                type: "domain_quota"
                            }).then(function(res) {
                                if (res && res.data) {
                                    self.ssdValue = res.data.inUse; //GB
                                    let storageUsed = Number(self.ssdValue);
                                    let storageTotal = Number(self.hddTotal + self.ssdTotal);
                                    storageChartFunc(storageUsed, storageTotal);
                                }
                            });
                        }*/ 
                        instancesSrv.getNewStorage().then(function(result){
                            if(result && result.data && angular.isArray(result.data)){
                                if(localStorage.isCustom=='false'){
                                    let storageTotal = statisticsData && statisticsData.local_gb ? Number(statisticsData.local_gb) : 0; //标准版磁盘总量不乘超分
                                    let storageUsed = statisticsData && statisticsData.local_gb_used ? Number(statisticsData.local_gb_used) : 0;
                                    self.storageTotal = bytesToSize(storageTotal); //GB
                                    if(result.data.length<1){
                                        self.showStorageType = 1;
                                        self.storageName = $translate.instant("aws.overview.localdisk");
                                        storageChartFunc(storageUsed, storageTotal,0,"isLocal",0);
                                    }else{
                                        self.storageAllocateChart = [];
                                        self.showStorageType = result.data.length;
                                        if (result && result.data) {
                                            // self.storageAllocateChart.push({
                                            //     domainName: $translate.instant("aws.overview.localdisk") ,
                                            //     inUsed:  Number(storageUsed),
                                            //     total: Number(storageTotal),
                                            //     inUsedUnit:bytesToSize(storageUsed).b,
                                            //     totalUnit:bytesToSize(storageTotal).b
                                            // });
                                            if(result.data.length == 1){
                                                self.storageTotal = bytesToSize(result.data[0].capabilities.total_capacity_gb);
                                                var sdata = self.poolDataTranslate(result.data[0]);
                                                self.storageName = result.data[0].disPlayName;
                                                storageChartFunc(Number(sdata[0]), Number(sdata[1]),Number(sdata[3]),"notLocal",Number(sdata[4]));
                                            }else{
                                                _.map(_.sortBy(result.data, "fresh_allocated_capabilities").reverse(), function(item, i) {
                                                    if (i > 4) {
                                                        return;
                                                    }
                                                    var sdata = self.poolDataTranslate(item);
                                                    // 获取多个存储池的数据
                                                    self.storageAllocateChart.push(...dealMultipleStorageChartData(sdata,item));
                                                });    
                                            }
                                        }
                                    }
                                }else{
                                    if(result&&result.data&&result.data.length < 2 ){
                                        self.showStorageType = result.data.length;
                                        var sdata = self.poolDataTranslate(result.data[0]);
                                        if(sdata){
                                            self.storageTotal = bytesToSize(sdata[1]);
                                            let storageUsed = Number(sdata[0]);
                                            let storageTotal = Number(sdata[1]);
                                            let storageRecycle = Number(sdata[3]);
                                            let storageLb = Number(sdata[4]);
                                            storageChartFunc(storageUsed, storageTotal, storageRecycle, "notLocal",storageLb);
                                        }
                                    }else if(result&&result.data&&result.data.length >1 ){
                                        self.showStorageType = result.data.length;
                                        self.storageAllocateChart = [];
                                        if (result && result.data) {
                                            _.map(_.sortBy(result.data, "fresh_allocated_capabilities").reverse(), function(item, i) {
                                                // if (i > 4) {
                                                //     return;
                                                // }
                                                var sdata = self.poolDataTranslate(item);
                                                // 获取多个存储池的数据
                                                self.storageAllocateChart.push(...dealMultipleStorageChartData(sdata,item));
                                            });
                                        }
                                        
                                    }
                                }  
                            }
                        })
                        // if (localStorage.permission == "enterprise") { //企业版用的是ceph或存储
                        //     instancesSrv.getStorage().then(function(result){
                        //         if(result && result.data && angular.isArray(result.data)){
                        //             if(localStorage.isCustom=='false'){
                        //                 let storageTotal = statisticsData && statisticsData.local_gb ? Number(statisticsData.local_gb) : 0; //标准版磁盘总量不乘超分
                        //                 let storageUsed = statisticsData && statisticsData.local_gb_used ? Number(statisticsData.local_gb_used) : 0;
                        //                 self.storageTotal = bytesToSize(storageTotal); //GB
                        //                 if(result.data.length<1){
                        //                     self.showStorageType = 1;
                        //                     self.storageName = $translate.instant("aws.overview.localdisk");
                        //                     storageChartFunc(storageUsed, storageTotal);
                        //                 }else{
                        //                     self.storageAllocateChart = [];
                        //                     self.showStorageType = result.data.length;
                        //                     if (result && result.data) {
                        //                         // self.storageAllocateChart.push({
                        //                         //     domainName: $translate.instant("aws.overview.localdisk") ,
                        //                         //     inUsed:  Number(storageUsed),
                        //                         //     total: Number(storageTotal),
                        //                         //     inUsedUnit:bytesToSize(storageUsed).b,
                        //                         //     totalUnit:bytesToSize(storageTotal).b
                        //                         // });
                        //                         if(result.data.length == 1){
                        //                             self.storageTotal = bytesToSize(result.data[0].capabilities.total_capacity_gb);
                        //                             var sdata = self.poolDataTranslate(result.data[0]);
                        //                             self.storageName = result.data[0].disPlayName;
                        //                             storageChartFunc(Number(sdata[0]), Number(sdata[1]));
                        //                         }else{
                        //                             _.map(_.sortBy(result.data, "fresh_allocated_capabilities").reverse(), function(item, i) {
                        //                                 if (i > 4) {
                        //                                     return;
                        //                                 }
                        //                                 var sdata = self.poolDataTranslate(item);
                        //                                 self.storageAllocateChart.push({
                        //                                     domainName: item.disPlayName ,
                        //                                     inUsed:  Number(sdata[0]),
                        //                                     total: Number(sdata[1]),
                        //                                     inUsedUnit:bytesToSize(sdata[0]).b,
                        //                                     totalUnit:bytesToSize(sdata[1]).b
                        //                                 });
                        //                             });    
                        //                         }
                        //                     }
                        //                 }
                        //             }else{
                        //                 if(result&&result.data&&result.data.length < 2 ){
                        //                     self.showStorageType = result.data.length;
                        //                     var sdata = self.poolDataTranslate(result.data[0]);
                        //                     if(sdata){
                        //                         self.storageTotal = bytesToSize(sdata[1]);
                        //                         let storageUsed = Number(sdata[0]);
                        //                         let storageTotal = Number(sdata[1]);
                        //                         storageChartFunc(storageUsed, storageTotal);
                        //                     }
                        //                 }else if(result&&result.data&&result.data.length >1 ){
                        //                     self.showStorageType = result.data.length;
                        //                     self.storageAllocateChart = [];
                        //                     if (result && result.data) {
                        //                         _.map(_.sortBy(result.data, "fresh_allocated_capabilities").reverse(), function(item, i) {
                        //                             // if (i > 4) {
                        //                             //     return;
                        //                             // }
                        //                             var sdata = self.poolDataTranslate(item);;
                        //                             self.storageAllocateChart.push({
                        //                                 domainName: item.disPlayName ,
                        //                                 inUsed:  Number(sdata[0]),
                        //                                 total: Number(sdata[1]),
                        //                                 inUsedUnit:bytesToSize(sdata[0]).b,
                        //                                 totalUnit:bytesToSize(sdata[1]).b
                        //                             });
                        //                         });
                        //                     }
                                            
                        //                 }
                        //             }  
                        //         }
                        //     })
                        // }else { //基础版是本地盘
                        //     let storageTotal = statisticsData && statisticsData.local_gb ? Number(statisticsData.local_gb) : 0; //标准版磁盘总量不乘超分
                        //     let storageUsed = statisticsData && statisticsData.local_gb_used ? Number(statisticsData.local_gb_used) : 0;
                        //     self.storageTotal = bytesToSize(storageTotal); //GB
                        //     storageChartFunc(storageUsed, storageTotal);
                        // }
                    }
                });
            })
        }
    });

    overviewSrv.getUsageOfDomainQuotas({
        type: "domain_quota",
        name: "instances"
    }).then(function(result) {
        self.insResAllocateChart = [];
        if (result && result.data) {
            _.map(_.sortBy(result.data, "usage").reverse(), function(item, i) {
                if (i > 4) {
                    return;
                }
                self.insResAllocateChart.push({
                    domainName: item.domainName,
                    inUsed: Number(item.usage),
                    total: Number(item.total)
                });

            });
        }
    });

    /*overviewSrv.getNodes().then(function(result) {
        if (result && result.data) {
            return result.data;
        }
    }).then(function(data) {
        self.insRegistedInfo = [];
        if (data) {
            data = data.filter(item => item.status == 4 || item.status == 41)
            _.each(data, function(item) {
                overviewSrv.getNodeInsNum({ host: item.hostName }).then(function(result) {
                    if (result && result.data) {
                        self.insRegistedInfo.push({
                            phyInsName: item.hostName,
                            vmNum: result.data.total
                        });
                        self.insRegistedInfo = _.sortBy(self.insRegistedInfo, "vmNum").reverse();
                    }
                });
            });
        }
    });*/

    function formateTableData(item) {
        // "computeha": "高可用",
        // "planwork": "计划任务",
        // "hardware": "硬件故障",
        if (item.alarmType == "threshold") {
            item._alarmType = $translate.instant("aws.monitor.alarmModule.threshold");
        } else if (item.alarmType == "healthcheck") {
            item._alarmType = $translate.instant("aws.monitor.alarmModule.healthcheck");
        } else if (item.alarmType == "computeha") {
            item._alarmType = $translate.instant("aws.monitor.alarmModule.computeha");
        } else if (item.alarmType == "planwork") {
            item._alarmType = $translate.instant("aws.monitor.alarmModule.planwork");
        } else if (item.alarmType == "hardware") {
            item._alarmType = $translate.instant("aws.monitor.alarmModule.hardware");
        }

        if(item.severity == "critical"){
            item.severity_status="critical";
            item.severity = $translate.instant("aws.monitor.alarmModule.critical");
        }else if(item.severity == "moderate"){
            item.severity_status="moderate";
            item.severity = $translate.instant("aws.monitor.alarmModule.moderate");
        }else if(item.severity == "low"){
            item.severity_status="low";
            item.severity = $translate.instant("aws.monitor.alarmModule.low");
        }
    }

    function handleNodeName(data,nodePrettyNameList){
        if (data.length > 5) {
            _.map(data, function(item, i) {
                formateTableData(item);
                if (i > 4) { return; }
                self.newAlarms_data.push({
                    hostname: item.hostname,
                    prettyName:nodePrettyNameList[item.hostname],
                    _alarmType: item._alarmType,
                    alarmType: item.alarmType,
                    severity: item.severity,
                    severity_status:item.severity_status,
                    createtime: item.createtime
                });
            });
        } else {
            self.newAlarms_data = _.map(data, function(item) {
                item.prettyName=nodePrettyNameList[item.hostname];
                formateTableData(item);
                return item;
            });
        }
    }

    alarmEventSrv.getNewAlarm({
        status: "new",
        enterpriseId: localStorage.enterpriseUid,
        pageNum:1,
        pageSize:5,
    }).then(function(result) {
        self.newAlarms_data = [];
        if (result && result.data) {
            commonFuncSrv.showPrettyNameOfNode(result.data,handleNodeName)
        }
    });

    operatelogSrv.getoperateLogsData({
        pageNum: "1",
        pageSize: "5",
        businessName: "",
        dateStart: "",
        dateEnd: ""
    }).then(function(result) {
        if (result && result.data) {
            self.logsTable = _.map(result.data, function(item) {
                item.state = $translate.instant("aws.statusCode." + item.responseCode);
                item.createTime = new Date(item.createTime);
                return item;
            });
        }
    });

    ticketsrv.getNoSignList(enterpriseUid, userId).then(function(result) {
        self.workInfosTable = [];
        if (result && result.data) {
            if (result.data.length > 5) {
                _.map(result.data, function(item, i) {
                    if (i > 4) { return; }
                    self.workInfosTable.push(item);
                });
            } else {
                self.workInfosTable = result.data;
            }
        }
    });
    //awstack单机版
    self.gotoCvm = function(m, n, v, s) {
        if (s == "project") {
            $location.url("/permit/project");
        } else if (s == "instances") {
            $location.url("/cvm/instances");
        } else if (s == "alarmEvents") {
            $location.url("/monitor/alarmevent");
        } else if (s == "network") {
            $location.url("/cvm/networks");
        }
        localStorage.domainName = "default";
        localStorage.domainUid = v;
        localStorage.projectName = m;
        localStorage.projectUid = n;
    };

    function getProject() {
        depviewsrv.getProjectData(self.defaultDep.domainUid).then(function(data) {
            data ? self.loadData = true : "";
            if (data && data.data) {
                data.data.map(function(item) {
                    if (item.name == "admin") {
                        self.defaultPro = item;
                    }
                })
                successFunc(data.data);
            }
        });
    }

    function getDepartment() {
        departmentDataSrv.getDepart().then(function(result) {
            if (result && result.data) {
                result.data.map(function(item) {
                    if (item.name == "default") {
                        self.defaultDep = item;
                    }
                })
                // if (localStorage.permission == "stand") {
                //     self.defaultDep = result.data[0] //todo //暂时解决，当list列表里面有多个部门时，可能就挂了
                // }
                getProject();
            }
        });
    }
}]);
singleModule.controller("ipCtrl", ["$scope", "$rootScope", "overviewSrv",
 function($scope, rootScope, overviewSrv) {
    var self = $scope;
    overviewSrv.getExternalNetworks().then(function(res) {
        if (res && res.data){
            self.netWorkType =[];
            res.data.forEach(function(item){
                var netItem = {"title":item.title,"inUsed":item.usedNetworkIpAvailability,"beAdded":1,"total":item.totalNetworkIpAvailability}
                self.netWorkType.push(netItem)
            })
        }
    })
}])