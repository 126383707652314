import {
    volumeController
} from "../../../volumes/controllers/volumeCtrl";
rbackupsCtrl.$inject = ["$scope", "$rootScope", "$translate","$uibModal","$location","rbackupsSrv","TableCom","$filter","$routeParams","GLOBAL_CONFIG","utilTool","newCheckedSrv","$timeout"];
backupCreateInstanceCtrl.$inject = ["$scope", "$rootScope", "$translate","$uibModal","$location","rbackupsSrv","TableCom","$filter","GLOBAL_CONFIG"];
backupDiskCtrl.$inject = ["$scope", "$rootScope", "$translate","$location","rbackupsSrv","TableCom","$filter","GLOBAL_CONFIG","volumesDataSrv","instancesSrv","storageSrv","$timeout","overviewSrv","newCheckedSrv","vmFuncSrv"];
createBackupsCtrl.$inject = ["$scope", "$rootScope", "$translate","$uibModal","$location","rbackupsSrv","$filter","GLOBAL_CONFIG","context","$uibModalInstance","vmFuncSrv"];
function rbackupsCtrl($scope, $rootScope, $translate,$uibModal,$location,Srv,TableCom,$filter,$routeParams,GLOBAL_CONFIG,utilTool,newCheckedSrv,$timeout){
    var self = $scope;
    self.showBackupsType = 'system';
    self.systemPageSize=10;
    self.systemCurrentPage=1;
    self.dataPageSize=10;
    self.dataCurrentPage=1;
    self.search={};

    self.systemBackupCheckboxes={
        items:{},
        checked:false
    };
    self.dataBackupCheckboxes={
        items:{},
        checked:false
    };
    self.systemHandleBtn = {
        createBackups:false,
        relieveBackup:false,
        createInstance:false,
        createDisk:false,
        deleteBtn:false
    };
    self.systeminfo={
        deleteMsg:""
    };
    self.dataHandleBtn = {
        createBackups:false,
        relieveBackup:false,
        createInstance:false,
        createDisk:false,
        deleteBtn:false
    };
    self.datainfo={
        deleteMsg:""
    };

    if(localStorage.cinderService&&localStorage.backupsService){
        self.systemHandleBtn.createBackups=true;
        self.dataHandleBtn.createBackups=true;
    }
    if(localStorage.permission == 'enterprise'){
        self.serviceMenus = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_Operation'),$translate.instant('aws.menu.System_Storage')]
    }else{
        self.serviceMenus = [$translate.instant('aws.menu.System'),$translate.instant('aws.menu.System_ConfigManagement'),$translate.instant('aws.menu.System_Storage')]
    }
    //判断磁盘是本地盘还是数据盘
    switch($routeParams.type){
        case "system":
            self.showBackupsType = "system";
            break;
        case "data":
            self.showBackupsType="data";
            break;
    }
    switch(self.showBackupsType){
        case "system":
            self.showBackupsTypeWords = "systemAddmainframe";
            break;
        case "data":
            self.showBackupsTypeWords="data";
            break;
    }
    Srv.backupType = self.showBackupsType;

    self.headers={
        name:$translate.instant("aws.recovery.header.name"),
        backupType:$translate.instant("aws.recovery.header.type"),
        instanceName:$translate.instant("aws.recovery.header.instanceName"),
        ipsArr:$translate.instant("aws.recovery.header.ipaddress"),
        volumeName:$translate.instant("aws.recovery.header.diskName"),
        volumeSize:$translate.instant("aws.recovery.header.diskSize"),
        backupMode:$translate.instant("aws.recovery.header.backupMode"),
        status_tr:$translate.instant("aws.recovery.header.backupStatus"),
        previousFireTime:$translate.instant("aws.recovery.header.currentBackupTime"),
        nextFireTime:$translate.instant("aws.recovery.header.nextBackupTime"),
        systemDiskSize:$translate.instant("aws.recovery.header.systemDiskSize"),
        domain:'部门',
        project:'项目'
    };

    //资源导出
    self.exportResource = function(){
        if (self.search.systemGlobalSearchTerm) {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&likes=${encodeURI(self.search.systemGlobalSearchTerm)}&job=true&system=true`
            }
        } else {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&job=true&system=true`
            }
        }
        Srv.getExportUrl(option).then(res => {
            if (res && res.data) {
                var url = res.data;
                utilTool.downloadFile(url);
            }
        })
    }
    //数据盘备份资源导出
    self.dataExportResource = function(){
        if (self.search.dataGlobalSearchTerm) {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&likes=${encodeURI(self.search.dataGlobalSearchTerm)}&job=true&system=false`
            }
        } else {
            var option = {
                "url": `${GLOBALCONFIG.APIHOST.RESOURCE}/v2/backup/jobs/export?X-Register-Code=${localStorage.regionKey}&job=true&system=false`
            }
        }
        Srv.getExportUrl(option).then(res => {
            if (res && res.data) {
                var url = res.data;
                utilTool.downloadFile(url);
            }
        })
    }
    self.showExport = utilTool.isAllDomainSelect;
    
    //选择全部时，列表中部门和项目的处理
    self.$watch(function(){
        return localStorage.selectedAllDomain;
    },function(ne){
        utilTool.projectDomainTableCol(ne,2,self);
    });

    self.$on("backupSocket", function(e, data) {
        if(self.showBackupsType=='system'){
            if(self.systemBackupData&&self.systemBackupData.length){
                self.systemBackupData.map(function(obj) {
                    if (data.eventMata&&data.eventMata.backup_job_id) {//backup_id
                        if (obj.id === data.eventMata.backup_job_id) {
                            if (data.eventType == "backup.delete.end") { //删除
                                self.refreshSystemTable();
                            }
                        }
                        if(data.eventType == "backup.create.end"){
                            $timeout(function(){
                                self.refreshSystemTable();
                            },1000);
                        }
                        if(data.eventType == "backup.restore.end"
                        ||data.eventType == "backup.reset_status.end"
                        ||data.eventType == "backup.restore.start"
                         ){
                            self.refreshSystemTable();
                        }
                    }
                });
            }
        }else{
            if(self.dataBackupData&&self.dataBackupData.length){
                self.dataBackupData.map(function(obj) {
                    if (data.eventMata&&data.eventMata.backup_job_id) {//backup_id
                        if (obj.id === data.eventMata.backup_job_id) {
                            if (data.eventType == "backup.delete.end") { //删除
                                self.refreshDataTable();
                            }
                        }
                        if(data.eventType == "backup.create.end"){
                            $timeout(function(){
                                self.refreshDataTable();
                            },1000);
                        }
                        if(data.eventType == "backup.restore.end"
                        ||data.eventType == "backup.reset_status.end"
                        ||data.eventType == "backup.restore.start"
                         ){
                            self.refreshDataTable();
                        }
                    }
                });
            }
        }
        
    });

    self.getBackupsType = function(type){
        self.showBackupsType = type;
        Srv.backupType = type;
        if(type=='data'){
            self.search.dataGlobalSearchTerm="";
            self.titleName="backupsData";
            if(sessionStorage["backupsData"]){
               self.tableCols=JSON.parse(sessionStorage["backupsData"]);
            }else{
               self.tableCols=[
                     { field: "check", title: "",headerTemplateURL:"dataHeaderCheckbox.html",show: true },
                     { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                     { field: "backupType", title: self.headers.backupType,sortable: "backupType",show: true,disable:false},
                     { field: "instanceName", title: self.headers.instanceName,sortable: "instanceName",show: true,disable:false},
                     { field: "ipsArr", title: self.headers.ipsArr,sortable: "ipsArr",show: true,disable:false},
                     { field: "volumeName", title: self.headers.volumeName,sortable: "volumeName",show: true,disable:false},
                     { field: "volumeSize", title: self.headers.volumeSize,sortable: "volumeSize",show: true,disable:false},
                     { field: "backupMode", title: self.headers.backupMode,sortable: "backupMode",show: true,disable:false},
                     { field: "status_tr", title: self.headers.status_tr,sortable: "status_tr",show: true,disable:false},
                     { field: "previousFireTime", title: self.headers.previousFireTime,sortable: "previousFireTime",show: true,disable:false},
                     { field: "nextFireTime", title: self.headers.nextFireTime,sortable: "nextFireTime",show: true,disable:false},
               ];
            }
            initDataTable();
        }else if(type=='system'){
            self.search.systemGlobalSearchTerm="";
            self.titleName="backupsSystem";
            if(sessionStorage["backupsSystem"]){
               self.tableCols=JSON.parse(sessionStorage["backupsSystem"]);
            }else{
                self.tableCols=[
                     { field: "check", title: "",headerTemplateURL:"systemHeaderCheckbox.html",show: true },
                     { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
                     { field: "backupType", title: self.headers.backupType,sortable: "backupType",show: true,disable:false},
                     { field: "instanceName", title: self.headers.instanceName,sortable: "instanceName",show: true,disable:false},
                     { field: "volumeSize", title: self.headers.systemDiskSize,sortable: "volumeSize",show: true,disable:false},
                     { field: "ipsArr", title: self.headers.ipsArr,sortable: "ipsArr",show: true,disable:false},
                     { field: "backupMode", title: self.headers.backupMode,sortable: "backupMode",show: true,disable:false},
                     { field: "status_tr", title: self.headers.status_tr,sortable: "status_tr",show: true,disable:false},
                     { field: "previousFireTime", title: self.headers.previousFireTime,sortable: "previousFireTime",show: true,disable:false},
                     { field: "nextFireTime", title: self.headers.nextFireTime,sortable: "nextFireTime",show: true,disable:false},
               ];
            }
            initSystemTable();
        }
        utilTool.projectDomainTableCol(localStorage.selectedAllDomain,2,self);
    };

    //数据盘备份
    var initDataTable=function(options){
        if(!localStorage.cinderService ||  !localStorage.backupsService) return;
        TableCom.init(self,'dataBackTable',[],"id",GLOBAL_CONFIG.PAGESIZE,'dataBackupCheckboxes');
        let defaultParams = {
            likes:self.search.dataGlobalSearchTerm,
            pageNum:1,
            pageSize:self.dataPageSize
        };
        if(!options){
            self.dataCurrentPage=1;
        }
        var params=options?options:defaultParams;
        params.system=false;
        self.dataLoadData  = '';
        Srv.getBackupsList(params).then(function(result){
            if(result&&result.data&&angular.isArray(result.data)){
                self.dataTotalNum=result.total;
                self.dataLoadData= result.data;
                successFunc(result.data);
            }
        });
    };
    
    //系统盘备份
    var initSystemTable=function(options){
        if(!localStorage.cinderService ||  !localStorage.backupsService) return;
        TableCom.init(self,'systemBackTable',[],"id",GLOBAL_CONFIG.PAGESIZE,'systemBackupCheckboxes');
        let defaultParams = {
            likes:self.search.systemGlobalSearchTerm,
            pageNum:1,
            pageSize:self.systemPageSize
        };
        if(!options){
            self.systemCurrentPage=1;
        }
        var params=options?options:defaultParams;
        params.system=true;
        self.systemLoadData  = '';
        Srv.getBackupsList(params).then(function(result){
            if(result&&result.data&&angular.isArray(result.data)){
                self.systemTotalNum=result.total;
                self.systemLoadData= result.data;
                successFunc(result.data);
            }
        });
    };


    //初始化系统盘备份列表
    self.getBackupsType('system');
    
    //数据处理
    function successFunc(data){
        self[self.showBackupsType+'BackupData']=data.map(function(item){
            item.backupMode=item.incremental?$translate.instant("aws.backups.table.isIncremental.true"):$translate.instant("aws.backups.table.isIncremental.false");
            item.backupType=item.jobType?$translate.instant("aws.backups.timingBackup"):$translate.instant("aws.backups.timingTaskBackup");
            item.status_tr=$translate.instant("aws.backups.table.status_new_mainRecovery_table."+item.status);
            item.previousFireTime=$filter("date")(item.previousFireTime, "yyyy-MM-dd HH:mm:ss");
            item.nextFireTime=$filter("date")(item.nextFireTime, "yyyy-MM-dd HH:mm:ss");
            item.ipsArr=item.ips?item.ips.split(","):[];
            if(item.domainName=="default"){
                item.domainName_TR = $translate.instant("aws.common.defaultDepar");
            }else{
                item.domainName_TR = item.domainName;
            }
            if(item.projectName=="admin"){
                item.projectName_TR = $translate.instant("aws.common.defaultProject");
            }else{
                item.projectName_TR = item.projectName;
            }
            if(!item.jobType&&angular.isObject(item.backupPolicy)){
                item.backupPolicy.createTime = $filter("date")(item.backupPolicy.createTime, "yyyy-MM-dd HH:mm:ss");
                if(item.backupPolicy.unit=='once'){
                    item.backupPolicy.frequency_des=$translate.instant('aws.backupStrategy.one')+$translate.instant("aws.backupStrategy."+item.backupPolicy.unit);
                }else{
                    item.backupPolicy.frequency_des=$translate.instant('aws.backupStrategy.by')+$translate.instant("aws.backupStrategy."+item.backupPolicy.unit)+$translate.instant('aws.backupStrategy.every')+item.backupPolicy.frequency+$translate.instant("aws.backupStrategy."+item.backupPolicy.unit)+$translate.instant('aws.backupStrategy.Executeonce');   
                }
            }
            return item;
        });
        if(self.showBackupsType=='system'){
            TableCom.init(self,'systemBackTable',self[self.showBackupsType+'BackupData'],"id",GLOBAL_CONFIG.PAGESIZE,'systemBackupCheckboxes');
        }else{
            TableCom.init(self,'dataBackTable',self[self.showBackupsType+'BackupData'],"id",GLOBAL_CONFIG.PAGESIZE,'dataBackupCheckboxes');
        }
    }
    
    self.dataChangePage=function(options){
        self.dataCurrentPage=options.pageNum;
        self.dataPageSize=options.pageSize;
        initDataTable(options);
    };

    self.systemChangePage=function(options){
        self.systemCurrentPage=options.pageNum;
        self.systemPageSize=options.pageSize;
        initSystemTable(options);
    };
    
    //系统盘备份刷新
    self.refreshSystemTable=function(){
       self.search.systemGlobalSearchTerm="";
       self.systemPageSize=10;
       self.systemCurrentPage=1;
       initSystemTable();
    };
    
    //数据盘备份刷新
    self.refreshDataTable=function(){
       self.search.dataGlobalSearchTerm="";
       self.dataPageSize=10;
       self.dataCurrentPage=1;
       initDataTable();
    };


    //后端搜索
    self.systemSearchBackend = function(options){
        self.systemCurrentPage = 1;
        var pageOption = {
          pageSize:self.systemPageSize,
          pageNum:1,
          likes:self.search.systemGlobalSearchTerm?self.search.systemGlobalSearchTerm:""
        };
        if(options.type=='refresh'){
          self.search.systemGlobalSearchTerm = "";
          pageOption.likes = "";
        }
        initSystemTable(pageOption);
    };

    //后端搜索
    self.dataSearchBackend = function(options){
        self.dataCurrentPage = 1;
        var pageOption = {
          pageSize:self.dataPageSize,
          pageNum:1,
          likes:self.search.dataGlobalSearchTerm?self.search.dataGlobalSearchTerm:""
        };
        if(options.type=='refresh'){
          self.search.dataGlobalSearchTerm = "";
          pageOption.likes = "";
        }
        initDataTable(pageOption);
    };
    
    //未开始，备份中的备份不可以删
    function canDeleteBack(checkedItems,type){
        let canDel=true;
        self[type+"info"].deleteMsg="";
        let arr=["waiting","creating","deleting","restoring"];
        for(var i=0;i<checkedItems.length;i++){
            if(_.include(arr,checkedItems[i].status)){
                canDel=false;
                self[type+"info"].deleteMsg=$translate.instant("aws.backups.statusNotDeleted");
                break;
            }
        }
        return canDel;
    }
    
    //定时且未开始的备份可以进行解绑
    function canUnbindBackup(checkedItems){
        let canUnbind=false;
        if(checkedItems[0].status=='waiting'&&!checkedItems[0].jobType){
            canUnbind=true;
        }
        return canUnbind;
    }

    //系统盘备份监控
    self.systemWatched = '';
    function systemWatchCheck(self) {
        var watch = self.$watch(function() {
            return self.systemBackupCheckboxes.items;
        }, function(val) {
            self.systemHandleBtn.relieveBackup=false;
            self.systemHandleBtn.createInstance=false;
            self.systemHandleBtn.createDisk=false;
            self.systemHandleBtn.deleteBtn=false;
            self.systemCheckedItems = [];
            var arr = [];
            for (var i in self.systemBackupCheckboxes.items) {
                arr.push(self.systemBackupCheckboxes.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.systemBackupData.forEach(item => {
                            if (item.id == key) {
                                self.systemCheckedItems.push(item);
                            }
                        });
                    }
                }
            }
            utilTool.setLocalStorage(self.systemCheckedItems);
            if (self.systemCheckedItems.length == 1) {
                self.systemHandleBtn.relieveBackup=canUnbindBackup(self.systemCheckedItems);
                self.systemHandleBtn.deleteBtn=canDeleteBack(self.systemCheckedItems,'system');
                self.systemHandleBtn.createInstance=true;
                self.systemHandleBtn.createDisk=true;
            } else if (self.systemCheckedItems.length > 1) {
                self.systemHandleBtn.deleteBtn=canDeleteBack(self.systemCheckedItems,'system');
            }
        }, true);  
        return watch;
    }
    if (self.systemWatched) {
        self.systemWatched();
    }
    self.systemWatched = systemWatchCheck(self);
    

    //数据盘备份监控
    self.dataWatched = '';
    function dataWatchCheck(self) {
        var watch = self.$watch(function() {
            return self.dataBackupCheckboxes.items;
        }, function(val) {
            self.dataHandleBtn.relieveBackup=false;
            self.dataHandleBtn.createInstance=false;
            self.dataHandleBtn.createDisk=false;
            self.dataHandleBtn.deleteBtn=false;
            self.dataCheckedItems = [];
            var arr = [];
            for (var i in self.dataBackupCheckboxes.items) {
                arr.push(self.dataBackupCheckboxes.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.dataBackupData.forEach(item => {
                            if (item.id == key) {
                                self.dataCheckedItems.push(item);
                            }
                        });
                    }
                }
            }
            utilTool.setLocalStorage(self.dataCheckedItems);
            if (self.dataCheckedItems.length == 1) {
                self.dataHandleBtn.relieveBackup=canUnbindBackup(self.dataCheckedItems);
                self.dataHandleBtn.deleteBtn=canDeleteBack(self.dataCheckedItems,'data');
                self.dataHandleBtn.createInstance=true;
                self.dataHandleBtn.createDisk=true;
            } else if (self.dataCheckedItems.length > 1) {
                self.dataHandleBtn.deleteBtn=canDeleteBack(self.dataCheckedItems,'data');
            }
        }, true);  
        return watch;
    }
    if (self.dataWatched) {
        self.dataWatched();
    }
    self.dataWatched = dataWatchCheck(self);
    
    self.applyGlobalSearch = function() {
        self.currentPage=1;
        self.pageSize=10;
        initTable();
        self.recoveryTable.filter({
            searchTerm: self.search.globalSearchTerm
        });
    };

    /*新建备份弹出框*/
    self.createBackup  = function(checkedItems){
        self.systemBackupCheckboxes={
            items:{},
            checked:false
        };
        self.dataBackupCheckboxes={
            items:{},
            checked:false
        };
        let backupModel = $uibModal.open({
            animation: self.animationsEnabled,
            templateUrl: "js/cvm/backupRecovery/backups/tmpl/createBackupModel.html",
            controller:  "createBackupsCtrl",
            resolve:{
                context:function(){
                    return self;
                }
            }
        });
    }

    /*解除备份*/
    self.relieveBackup  = function(){
        var content = {
            target: "relieveBackup",
            msg:$translate.instant('aws.backupStrategy.Deletecontent'),
        };
        self.$emit("delete", content);
    };
    self.$on("relieveBackup", function() {
        let id=self[self.showBackupsType+"CheckedItems"][0].id;
        let initFunc="";
        if(self.showBackupsType=='system'){
           initFunc=self.refreshSystemTable;
        }else{
           initFunc=self.refreshDataTable;
        }
        Srv.relieveBackup(id).then(function(){
           initFunc();
        });
    });

    /*删除备份*/
    self.delete  = function(checkedItems,type){
        if(self[type+'HandleBtn'].deleteBtn){
                var content = {
                target: "deleteBackup",
                msg:$translate.instant('aws.backupStrategy.Deletecontent'),
            };
            self.$emit("delete", content);
        }
    };
    self.$on("deleteBackup", function() {
        let params = [],initFunc="";
        self[self.showBackupsType+"CheckedItems"].forEach(item=>{
            params.push(item.id)
        });
        if(self.showBackupsType=='system'){
           initFunc=self.refreshSystemTable;
        }else{
           initFunc=self.refreshDataTable;
        }
        newCheckedSrv.setChkIds(params,"backupdelete");
        Srv.delete({"id":params}).then(res=>{
            initFunc();
        }); 
    });

    /*新建云主机弹出框*/
    self.createInstance = function(type) {
        // if(localStorage.selectedAllDomain==1&&localStorage.managementRole=='2'){
        //   localStorage.domainName =localStorage.defaultdomainName;
        //   localStorage.domainUid = localStorage.defaultdomainUid;
        //   localStorage.projectName = localStorage.defaultProjectName;
        //   localStorage.projectUid = localStorage.defaultProjectUid;
        // }
        self.createType = type;
        self.backupInsAnimation = "animateIn";
        $("body").addClass("animate-open")
    }

    self.backupInsAnimation = "animateOut";

    /*关闭弹出框*/
    self.closeSelectBackupOfIns = function(){
        self[self.showBackupsType+'BackupCheckboxes'].items={};
        self.backupInsAnimation = "animateOut";
        $("body").removeClass("animate-open");
    }

    /*新建云硬盘弹出框*/
    self.createDisk = function(type) {
        // if(localStorage.selectedAllDomain==1&&localStorage.managementRole=='2'){
        //   localStorage.domainName =localStorage.defaultdomainName;
        //   localStorage.domainUid = localStorage.defaultdomainUid;
        //   localStorage.projectName = localStorage.defaultProjectName;
        //   localStorage.projectUid = localStorage.defaultProjectUid;
        // }
        self.createType = type;
        self.backupDiskAnimation = "animateIn";
        $("body").addClass("animate-open")
    }

    self.backupDiskAnimation = "animateOut";

    /*关闭弹出框*/
    self.closeDisk = function(){
        self[self.showBackupsType+'BackupCheckboxes'].items={};
        self.backupDiskAnimation = "animateOut";
        $("body").removeClass("animate-open");
    }

    self.$on('closeDiskModel',function(v,data){
        self.closeDisk();
    });

    self.$on('createInsOfBackup',function(v,data){
        self.backupInsAnimation = "animateOut";
        $("body").removeClass("animate-open");
        var path = "/cvm/rbackups?from=rbackup&backupId="+data[0].uid;
        self.insAnimation = "animateIn";
        $location.url(path);
        $("body").addClass("animate-open");
    });

    self.closeNewIns = function(){
        self.insAnimation = "animateOut";
        $("body").removeClass("animate-open");
    };

    self.changeDetailTab = function(type){
        self.detailType = type;
    };
    
    $scope.$on("getDetail", function(event, value) {
        self.changeDetailTab('backup');
    });

    self.returnFromBackDetail=function(){
        //$location.path('/cvm/rbackups');
        $location.url('/cvm/rbackups');
        self.detailType="";
    };
}
function backupCreateInstanceCtrl($scope, $rootScope, $translate,$uibModal,$location,Srv,TableCom,$filter,GLOBAL_CONFIG){
    let self = $scope;
    self.showBackupsType = Srv.backupType;
    self.pageSize=10;
    self.currentPage=1;
    self.handleBtn={};
    var initTable=function(options){
        var pageDefaultOption = {
            pageSize: self.pageSize,
            pageNum:1,
            likes:''
        };
        if(!options){
            self.currentPage=1;
        }
        var pageOption = angular.extend(pageDefaultOption,options);
        TableCom.init(self,'backupsTable',[],"uid",self.pageSize,'backupsDetailCheckboxes');
        self.backupsTableData  = [];
        Srv.getBackupsContainList(self[self.showBackupsType+"CheckedItems"][0].id,pageOption).then(function(result){
            if(result&&result.data){
                self.totalNum = result.total;
                result.data.map(function(item,index){
                    item.type=item.incremental?$translate.instant("aws.backups.table.isIncremental.true"):$translate.instant("aws.backups.table.isIncremental.false");
                    item.status_tr=$translate.instant("aws.backups.table.status_new."+item.status);
                    item.createTime=$filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
                    if(index==0){
                        item.isFirst=true;
                    }
                    if(index==result.data.length-1){
                        item.isLast=true;
                    }
                });
                self.backupsTableData= result.data;
                TableCom.init(self,'backupsTable',self.backupsTableData,"uid",self.pageSize,'backupsDetailCheckboxes');
            }
        });
    };
    initTable();
    self.changePage=function(options){
        self.currentPage=options.pageNum;
        self.pageSize=options.pageSize;
        initTable(options);
    };

    self.watched = '';
    function watchCheck(self) {
        var watch = self.$watch(function() {
            return self.backupsDetailCheckboxes.items;
        }, function(val) {
            self.checkedItems = [];
            var arr = [];
            for (var i in self.backupsDetailCheckboxes.items) {
                arr.push(self.backupsDetailCheckboxes.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.backupsTableData.forEach(item => {
                            if (item.uid == key) {
                                self.checkedItems.push(item);
                            }
                        });
                    }
                }
            }
            console.log(self.checkedItems)
            if (self.checkedItems.length == 1&&self.checkedItems[0].status=="available") {
                self.handleBtn.canCreateIns=true;
            } else{
                self.handleBtn.canCreateIns=false;
            }
        }, true);
        return watch;
    }
    if (self.watched) {
        self.watched();
    }
    self.watched = watchCheck(self);

    self.clickToCreateIns = function(){
        self.checkedItems[0].id=self.checkedItems[0].uid;
        self.$emit('createInsOfBackup',self.checkedItems);
    };

    //选择备份列表的状态更新
    self.$on("backupSocket", function(e, data) {
        if(self.backupInsAnimation == "animateIn"&&self.backupsTableData&&angular.isArray(self.backupsTableData)&&self.backupsTableData.length){
           self.backupsTableData.map(function(obj){
                if(data.eventMata&&data.eventMata.backup_job_id&&data.eventMata.backup_job_id==obj.jobId&&data.eventMata.backup_id==obj.uid){
                    if(data.eventType == "backup.restore.end"
                    ||data.eventType == "backup.restore.start"
                    ||data.eventType == "backup.delete.start"
                    ||data.eventType == "backup.delete.end"
                    ||data.eventType == "backup.create.start"
                    ||data.eventType == "backup.create.end"
                     ){   
                        initTable();
                        console.log("新建云主机刷新");
                    }
                }
           });
        }
    });
}        
function backupDiskCtrl($scope, $rootScope, $translate,$location,Srv,TableCom,$filter,GLOBAL_CONFIG,volumesDataSrv,instancesSrv,storageSrv,$timeout,overviewSrv,newCheckedSrv,vmFuncSrv){
    let self = $scope;
    self.pageSize=10;
    self.currentPage=1;
    self.handleBtn={};
    /*下一步*/
    self.nextStep = function(){
        self.checkedItems[0].id=self.checkedItems[0].uid;
        volumeController($scope, $rootScope, volumesDataSrv, "", 'backup', initTable, self.checkedItems[0], $translate,instancesSrv,storageSrv,$timeout,overviewSrv,newCheckedSrv,vmFuncSrv,$location);
        self.inStepOneBar = true;
        self.inStepTwoBar = true;
        self.inStepOne = false;
        self.inStepTwo = true;
    }
    /*上一步*/
    self.preStep = function(){
        self.inStepOneBar = true;
        self.inStepTwoBar = false;
        self.inStepOne = true;
        self.inStepTwo = false;
    }
    self.preStep();

    /*取消*/
    self.cancelModel = function(){
        self.$emit('closeDiskModel',true);
    };

    self.showBackupsType = Srv.backupType;
    var initTable=function(options){
        var pageDefaultOption = {
            pageSize: self.pageSize,
            pageNum:1,
            likes:''
        };
        if(!options){
            self.currentPage=1;
        }
        var pageOption = angular.extend(pageDefaultOption,options);
        TableCom.init(self,'backupsTable',[],"uid",self.pageSize,'backupsDetailCheckboxes');
        self.backupsTableData  = [];
        Srv.getBackupsContainList(self[self.showBackupsType+"CheckedItems"][0].id,pageOption).then(function(result){
            if(result&&result.data){
                self.totalNum = result.total;
                result.data.map(function(item,index){
                    item.type=item.incremental?$translate.instant("aws.backups.table.isIncremental.true"):$translate.instant("aws.backups.table.isIncremental.false");
                    item.status_tr=$translate.instant("aws.backups.table.status_new."+item.status);
                    item.createTime=$filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
                    if(index==0){
                        item.isFirst=true;
                    }
                    if(index==result.data.length-1){
                        item.isLast=true;
                    }
                });
                self.backupsTableData= result.data;
                 //根据session初始化searchterm
                TableCom.init(self,'backupsTable',self.backupsTableData,"uid",self.pageSize,'backupsDetailCheckboxes');
            }
        });
    };
    initTable();

    self.changePage=function(options){
        self.currentPage=options.pageNum;
        self.pageSize=options.pageSize;
        initTable(options);
    };

    self.watched = '';
    function watchCheck(self) {
        var watch = self.$watch(function() {
            return self.backupsDetailCheckboxes.items;
        }, function(val) {
            self.checkedItems = [];
            var arr = [];
            for (var i in self.backupsDetailCheckboxes.items) {
                arr.push(self.backupsDetailCheckboxes.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.backupsTableData.forEach(item => {
                            if (item.uid == key) {
                                self.checkedItems.push(item);
                            }
                        });
                    }
                }
            }
            if (self.checkedItems.length == 1&&self.checkedItems[0].status=="available") {
                self.handleBtn.canCreateDisk=true;
            } else{
                self.handleBtn.canCreateDisk=false;
            }
        }, true);
        return watch;
    }
    if (self.watched) {
        self.watched();
    }
    self.watched = watchCheck(self);
    self.$on("backupSocket", function(e, data) {
        if(self.backupDiskAnimation == "animateIn"&&self.backupsTableData&&angular.isArray(self.backupsTableData)&&self.backupsTableData.length){
           self.backupsTableData.map(function(obj){
                if(data.eventMata&&data.eventMata.backup_job_id&&data.eventMata.backup_job_id==obj.jobId&&data.eventMata.backup_id==obj.uid){
                    if(data.eventType == "backup.restore.end"
                    ||data.eventType == "backup.restore.start"
                    ||data.eventType == "backup.delete.start"
                    ||data.eventType == "backup.delete.end"
                    ||data.eventType == "backup.create.start"
                    ||data.eventType == "backup.create.end"
                     ){  
                        initTable();
                        console.log("新建云主机刷新");
                    }
                }
           });
        }
    });

}
function createBackupsCtrl($scope, $rootScope, $translate,$uibModal,$location,Srv,$filter,GLOBAL_CONFIG,context,$uibModalInstance,vmFuncSrv){
    let self = $scope;
    self.FormValid = false;
    self.modalTitle = Srv.backupType;
    self.params = {
        backupTypeSelect:{},
        backupName:'',
        instancesSelect:{},
        dataDiskSelect:[],
        systemDiskSelect:"",
        backupStrategySelect:{},
        incremental:"false"
    }
    self.backupTypeList = [
        {
            id:1,
            name:$translate.instant('aws.backups.timingTaskBackup'),
            type:"timeout"
        },
        {
            id:2,
            name:$translate.instant('aws.backups.timingBackup'),
            type:"realtime"
        }
    ]
    self.params.backupTypeSelect = self.backupTypeList[0];
    
    //云主机要通过列表获取
    self.instancesList=[];
    function getInsList(){
        Srv.getCreateBackupInsList().then(function(res){
            if(res&&res.data&&angular.isArray(res.data)){
               self.instancesList= res.data;
               self.params.instancesSelect = self.instancesList[0];
               if(res.data.length){
                   self.changeIns(self.params.instancesSelect);
               }
            }
        });
    }
    getInsList()

    self.changeBackupType=function(backupType){
        if(backupType.type=='realtime'){
           self.params.incremental='false';
        }
    }
    
    //云硬盘列表，系统盘备份只能选择该云主机下的系统盘，数据盘备份选择该云主机下的数据盘
    self.changeIns=function(ins){
        self.diskList=[];
        self.params.systemDiskSelect="";
        self.params.dataDiskSelect=[];
        var params={
            system:context.showBackupsType=='system'?true:false
        }
        Srv.getCreateBackupVolumeOfIns(ins.uid,params).then(function(res){
            if(res&&res.data&&angular.isArray(res.data)){
                self.diskList=res.data;
                if(context.showBackupsType=='system'&&self.diskList.length>0){
                   self.params.systemDiskSelect= res.data[0];
                }
            }
        });
    }
    
    //获取所有备份策略列表
    self.backupStrategyList=[];
    function getStrategyList(){
        var params= {
        };
        Srv.getStrategyList(params).then(function(res){
            if(res&&res.data&&angular.isArray(res.data)){
               self.backupStrategyList=res.data;
               self.params.backupStrategySelect = self.backupStrategyList[0];
            }
        });
    }
    getStrategyList();

    // 获取备份计费价格
    function getBackupAmount(value) {
        var option = {
            backupVolumeSize: value,
            region: localStorage.regionName || "default"
        }
        vmFuncSrv.bossSourceFunc(self,value,option,"queryBackupChargingAmount");
    }

    self.$watch(function() {
        return self.params.systemDiskSelect;
    }, function(val) {
        if(val){
            getBackupAmount(val.size);
        }else{
            getBackupAmount(0);
        }
    }); 

    self.$watch(function() {
        return self.params.dataDiskSelect;
    }, function(val) {
        if(val && val.length){
            const sum = val.reduce((prev, cur) => prev + cur.size, 0);
            getBackupAmount(sum);
        }else{
            getBackupAmount(0);
        }
    },true);

    self.confirmCreate = function(m){
        if(m.$valid){
           let data={
               jobType:self.params.backupTypeSelect.type=="realtime"?true:false,
               name:self.params.backupName,
               system:context.showBackupsType=='system'?true:false,
               incremental:self.params.incremental=='true'?true:false,
               instanceId:self.params.instancesSelect.uid 
           }
           let params={volumeId:[]};
           if(self.params.backupTypeSelect.type=="timeout"){
               data.policyId=self.params.backupStrategySelect.id;
           }
           if(context.showBackupsType=='system'){
               params.volumeId.push(self.params.systemDiskSelect.id);
           }else{
               self.params.dataDiskSelect.map(function(item){
                   params.volumeId.push(item.id);
               });
           }
           Srv.createBackups(data,params).then(function(){
               if(context.showBackupsType=='system'){
                  context.refreshSystemTable();
               }else{
                  context.refreshDataTable();
               }
               $uibModalInstance.close();
           });
        }else{
            self.FormValid = true;
        }
    }  
}
export{rbackupsCtrl,backupCreateInstanceCtrl,backupDiskCtrl,createBackupsCtrl}