addCephPoolCtrl.$inject = ["$scope","context","$translate","storageManagementSrv","NgTableParams","newCheckedSrv","$routeParams","dragDiskCache","alertSrv","storageUtil"]
function addCephPoolCtrl($scope,context,$translate,storageManagementSrv,NgTableParams,newCheckedSrv,$routeParams,dragDiskCache,alertSrv,storageUtil) {
    var self = $scope;
    self.FormValid = false;
    self.isDiskInvalid = false;
    self.forceValid = false;
    self.invalidMessage = $translate.instant('aws.system.storagement.storageTranslate.invalidMessage')
    /*清空本地存储选盘所有内容*/
    localStorage.removeItem("diskCheckedItems");
    localStorage.removeItem("cephDiskConfig");
    self.copyList = [
        {id: "2", name: "2"},
        {id: "3", name: "3"}
    ]
    self.params = {
        name:"",
        copies:self.copyList[1],
        bcache:false
    }
    //是否国产化环境
    self.isLocalization = localStorage.isLocalization==1?true:false;
    self.canConfigBtn = true;
    self.showType = "one";
    var nodeMapList = {};
    
    function getNode(){
        self.stepOneData = [];
        var stepOneTemp = [];
        self.prettyNameListOfAddStorage={};//记住当前主机的主机别名
        storageManagementSrv.getAvailableDisks(localStorage.regionKey).then(function(result){
            if(result&&result.data){
                result.data.forEach(item=>{
                    item.hostName = item.nodeName;
                })
                self.availableDiskList = result.data;
                stepOneTemp = result.data;
                stepOneTemp = stepOneTemp.sort(storageUtil.sortNumber);
                
                stepOneTemp.forEach(v=>{
                    v.hostName = v.nodeName;
                    self.prettyNameListOfAddStorage[v.hostName]=v.prettyName;
                    v.hostInfoMap.ssdAll = new Number();
                    v.hostInfoMap.sataAll = new Number();
                    v.hostInfoMap.disks = v.diskAttributesList?v.diskAttributesList:[];
                    let data = v.hostInfoMap.disks;
                    var ssdAll=0;
                    var sataAll=0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].ssd) {
                            ssdAll += storageUtil.changeUnit(data[i].capacity);
                        } else {
                            sataAll += storageUtil.changeUnit(data[i].capacity);
                        }
                    }
                    if(ssdAll>0){
                        v.hostInfoMap.ssdAll = storageUtil.setUnit(ssdAll).total;
                        v.hostInfoMap.ssdUnit = storageUtil.setUnit(ssdAll).unit;
                    }
                    if(sataAll>0){
                        v.hostInfoMap.sataAll = storageUtil.setUnit(sataAll).total;
                        v.hostInfoMap.sataUnit = storageUtil.setUnit(sataAll).unit;
                    }
                })
                
                self.stepOneData = stepOneTemp;
                self.stepOneData = self.stepOneData.sort(storageUtil.sortNumber); 
                
                self.manageStorageNodes = new NgTableParams({ count: 4 }, { counts: [4,20,50], dataset: self.stepOneData });
                var tableId = "nodeUid";
                newCheckedSrv.checkDo(self,self.stepOneData,tableId,'manageStorageNodes');

                // diskData = angular.copy(self.stepOneData);
                // initDiskTable(diskData);
                // replaceAvailable(result.data,regionUid)
            }
        })
    }
    var loginData = angular.fromJson(localStorage.$LOGINDATA);
    getNode(loginData.regionUid);
    self.$watch(function(){
        return self.checkedItems
    },function(val){
        if(!val){
            return;
        }
        if(val.length==1){
            self.canConfigBtn = false;
        }else if(val.length>1){
            self.canConfigBtn = storageUtil.checkDiskConfig(val);
        }else{
            self.canConfigBtn = true;
        }

        // //判断内核版本是否支持bcache，前提条件：第一步开启了bcache
        // if(self.params.bcache&&val.length>0){
        //     self.kernelNotSupport = "";
        //     let checkedResult = storageUtil.checkKernel(val);
        //     self.kernelNotSupport = checkedResult.errorNode;
        //     self.canConfigBtn = checkedResult.flag;
        // }
        
    })

    self.getback = function(){
        self.checkboxesmanageStorageNodes.items={};
        self.showType = "one";
    }
    self.$on("drag-disk-end",function(e,data){
        self.getback();
        self.forceValid = false;
    })
    self.$on("drag-force-valid",function(e,data){
        self.forceValid = data.flag;
        self.isDiskInvalid = false;
    })
    self.disksconfigure = function(checkedItems,type){
        // self.cephDiskNumLimit = false;
        // var parameter = {
        //     checkedItems:checkedItems,
        //     type:type,
        //     diskData:diskData,
        //     initDiskTable:initDiskTable,
        //     cephPool:true
        // }
        // storageDiskCtrl($scope,$timeout, $location,NgTableParams,parameter,storageManagementSrv,$translate,nodeMapList,self.stepOneData)

        self.showType ='disk';
        self.cephProps = {
            checkedItems:self.checkedItems,
            availableDiskList:self.availableDiskList,
            type:"POOLS",//存储池,
            bcache:self.params.bcache
        }
        self.nodesNameList = checkedItems.map(item=>item.prettyName);
    }

    self.changeCopySize = function(){
        self.cephDiskNumLimit = false;
    }
    
    self.confirmCreate = function(m,v){
        self.FormValid = false;
        if(m.$valid){
            var time = new Date().getTime();
            var storageConfiguration = {
                "storage_name": "ceph_"+v.name+"_"+time,
                "storage_type": "ceph_pool",
                "volume_backend_name": "ceph_"+v.name+"_"+time,
                "pool_name": v.name
            }
            var poolDiskInfo = {};
            
            
            //处理数据格式
            let acg = storageUtil.compDiskMap({disks:dragDiskCache.POOLS,tableData:self.stepOneData,type:"byId"});
            poolDiskInfo = acg.data;
            //校验数据格式
            let validObj = storageUtil.checkDiskMsg(poolDiskInfo,dragDiskCache.POOLS);
            
            //判断开启bcache后，内核版本是否支持，缓存盘是否配置;
            if(v.bcache){
                let checkedResult = storageUtil.checkKernel(acg.kernelList);
                if(checkedResult.errorNode){
                    alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.checkKernel",{node:checkedResult.errorNode}), "error", 5000);
                    return;
                }
                
                if(validObj.setMap['0-1']){
                    let text = validObj.setMap['0-1'].join(",");
                    alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.setMap",{node:text}), "error", 5000);
                    return;
                }
                
            }

            //完成磁盘配置的节点数要大于等于副本数
            if(Number(v.copies.name)>validObj.num){
                alertSrv.set("",$translate.instant("aws.system.storagement.storageTranslate.numcopy",{num:v.copies.name}), "error", 5000);
                return;
            }

            //判断不同节点间配置的元数据盘和数据盘的映射关系是否一致，如果不一致，抛错提示，可以强制提交
            if(validObj.arr.length>1){
                self.invalidMessage = $translate.instant('aws.system.storagement.storageTranslate.diffNodeSetting') 
                if(!self.forceValid){
                    self.isDiskInvalid = true;
                    return;
                }
            }else{
                self.invalidMessage = $translate.instant('aws.system.storagement.storageTranslate.invalidMessage')
            }

            var params = {
                "name":v.name,
                "storageId":$routeParams.id,
                "storageConfiguration":angular.toJson(storageConfiguration),
                "volumeBackendName":"ceph_"+v.name+"_"+time,
                "regionKey":localStorage.regionKey,
                "enterpriseUid":localStorage.enterpriseUid,
                "regionUid":localStorage.regionUid,
                "poolDiskInfo":poolDiskInfo,
                "cephUseBcache":v.bcache,
                "poolSize":v.copies.name
            }
            storageManagementSrv.addCephPool(params).then(res=>{
                if(res){
                   context.refresh(); 
                }
            })
            dragDiskCache.POOLS.nodeUidMap = {};
            dragDiskCache.POOLS.valid = true;
            context.poolModel.close()
        }else{
            self.FormValid = true;
        }
    }

};
export {addCephPoolCtrl}