vgpuCtrl.$inject = ['$scope','$rootScope','$timeout','vgpuSrv','$http','NgTableParams','$route','$filter','newCheckedSrv','$translate','$uibModal','GLOBAL_CONFIG','$location']
createVgpuCtrl.$inject = ['$scope','vgpuSrv','initData','dataclusterSrv','$uibModalInstance']
function vgpuCtrl($scope,$rootScope,$timeout,vgpuSrv,$http,NgTableParams,route,$filter,newCheckedSrv,$translate,$uibModal,GLOBAL_CONFIG,$location) {
    var self = $scope;
    self.headers = {
        "name": $translate.instant("aws.vgpu.name"),
        "info": $translate.instant("aws.vgpu.info"),
        "physicsGpu": $translate.instant("aws.vgpu.physicsGpu"),
        "nodeInfo": $translate.instant("aws.vgpu.nodeInfo"),
        "instance": $translate.instant("aws.vgpu.instance"),
        "createTime":$translate.instant("aws.vgpu.createTime")
    };
    
    function initData(options){
        var pageDefaultOption = {
            pageSize:10,
            pageNum:1,
            pattern:null,
            status:null
        };  
        var pageOption = angular.extend(pageDefaultOption,options)
        vgpuSrv.getVGpuList(pageOption).then(function(result) {
            result.data?self.loadData = true:"";
            if (result && result.data) {
                var deliverData = result.data;
                successFunc(deliverData);
                //tableResize(deliverData);
                // self.totalNum = result.total;
            }
        })
    }
    self.createVgpu = function(){
        var scope = self.$new();
        var regionModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "createVgpu.html",
            controller:"createVgpuCtrl",
            resolve:{
                context:self,
                initData:function(){
                    return initData;
                }
            }
        });
    };
    self.refresh = function(){
        self.globalSearchTerm="";
        initData();
    }
    function successFunc(data) {
        self.applyGlobalSearch = function() {
            var term = self.globalSearchTerm;
            self.centertableParams.filter({ searchTerm: term });
        };
        _.forEach(data, function(item) {
            item.status_ori = $translate.instant("aws.cluster.status." + item.status);
            item.lastTime_ori = $filter('date')(item.createTime, "yyyy-MM-dd HH:mm:ss");
            //item.searchTerm = [item.regionName, item.regionKey, item.status_ori, item.description, item.lastTime_ori].join('\b');
        })
        self.clusterTableData = data;
        self.clusterListData = data;
        self.clusterSearchTerm(({tableData:self.clusterListData,titleData:self.clusterTitleData}))
        var tableId = "vgpuId";
        self.centertableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: data });
        newCheckedSrv.checkDo(self, data, tableId,"centertableParams"); 
        self.$watch(function() {
            return self.checkedItemscentertableParams;
        }, function(value) {
            
            if(value.length ==1){
                if(value[0].instanceId){//绑定了虚拟机不可删除
                    self.canDel = false
                }else{
                    self.canDel = true
                }
                
            }else if(value.length >1){
                let status = value.some(i =>{
                    return i.instanceId
                })
                if(status){
                    self.canDel = false
                }else{
                    self.canDel = true
                }
            }else{
                self.canDel = false
            }
        });
    }
    initData();
    self.clusterTitleData=[
        {name:'cluster.name',value:true,disable:true,search:'vgpuName'},
        {name:'cluster.info',value:true,disable:false,search:'framebuffer'},
        {name:'cluster.physicsGpu',value:true,disable:false,search:'gpuName'},
        {name:'cluster.nodeInfo',value:true,disable:false,search:'node'},
        {name:'cluster.instance',value:true,disable:false,search:'instanceName'},
        {name:'cluster.createTime',value:true,disable:false,search:'lastTime_ori'},
    ];
    self.clusterSearchTerm=function(obj){
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function(item){
            item.searchTerm="";
            titleData.forEach(function(showTitle){
                if(showTitle.value){
                    // if(showTitle.search=='source'){
                    //    item.searchTerm+=[item.source_ip_address,item._source_port].join("\b");
                    // }else if(showTitle.search=='destination'){
                    //    item.searchTerm+=[item.destination_ip_address,item._destination_port].join("\b");  
                    // }else{
                   item.searchTerm+=item[showTitle.search]+"\b";
                    // }
                 }
            });
        });     
    };
    self.del = function(checkedItems) {
        console.log(checkedItems);
        var content = {
            target: "delVgpu",
            msg: "<span>" + $translate.instant("aws.vgpu.del") + "</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };
    self.$on("delVgpu", function (e,data) {
        let ids = [];
        data.map(i =>{
            ids.push(i.vgpuId.toString())
        })
        let del_data = {
            vgpuIds:ids
        }
        vgpuSrv.deleteVGpu(del_data).then(function () {
            initData()
        });

    });
    
    //详情事件
    self.handleDetail = function(item){
        $location.url('/system/vgpu?id='+item.vgpuId)
    }
    self.back = function(){
        history.back();
    }
    //详情页面
    $scope.$on("getDetail", function(event, value) {
        console.log(value);
        let detailData = {
            vgpuId:value
        }
        vgpuSrv.getVGpuDetail(detailData).then(function(result) {
            console.log(result);
            if (result && result.data) {
                self.detailData = result.data;
            }
        })
    })
}
function createVgpuCtrl(scope,vgpuSrv,initData,dataclusterSrv,$uibModalInstance){
    var self = scope;
    self.submitValid = false;
    self.createVgpu = {
        // node:""
    }
    // 获取节点信息
    var regionUid=JSON.parse(localStorage.$LOGINDATA).regionUid;
    self.getNode = function(regionUid) {
        dataclusterSrv.getAllNode(regionUid).then(function(data) {
            if (data && data.data) {
                self.nodeData = data.data;
                self.createVgpu = {
                    node:self.nodeData[0]
                }

                getgpuByNode(self.createVgpu.node)
            }
        });
    }
    self.getNode(regionUid);
    self.changeNode = function(node){
        // 获取物理GPU
        getgpuByNode(node);
    }
    // 获取物理GPU
    function getgpuByNode(node){
        let oData = {
            node:node.hostName
        }
        vgpuSrv.getGpuByNode(oData).then(function(result) {
            console.log(result);
            if (result && result.data) {
                self.phyGpuList = result.data;
                self.createVgpu.gpu=self.phyGpuList[0]
                if(self.phyGpuList.length>0){
                    getgpuType(node,self.createVgpu.gpu)
                }
            }
        })
    }
    
    self.changePhyGpu = function(pci){
        // 获取vgpu规格
        getgpuType(createVgpu.node,pci);
    }
    function getgpuType(node,pci){
        // 获取vgpu规格
        let oData1 = {
            node:node.hostName,
            pci:pci.pci
        }
        vgpuSrv.getGpuSpecsByGpu(oData1).then(function(result) {
            console.log(result);
            if (result && result.data) {
                self.gpuTypeList = result.data;
                self.createVgpu.gpuType=self.gpuTypeList[0]
                self.createVgpu.maxInstance = self.createVgpu.gpuType?self.createVgpu.gpuType.maxInstance:""
            }
        })
    }
    self.changeGpuType = function(phygpu){
        self.createVgpu.maxInstance = phygpu.maxInstance
    }
    scope.confirm = function() {
        if (scope.createVgpu1.$valid) {
            let opeData = {
                id:self.createVgpu.gpu.id,
                pci:self.createVgpu.gpu.pci,
                vgpuType:self.createVgpu.gpuType.vgpuType
            }
            vgpuSrv.addNewVgpu(opeData).then(function(res) {
                $uibModalInstance.dismiss("cancel");
                initData();
                // if(res&&res.data){
                //     initData();
                // }
                
            });
        }else{
            scope.submitValid = true;
        }
    };
}
export{vgpuCtrl,createVgpuCtrl};