dragDiskInfo.$inject = [];
function dragDiskInfo() {
    return {
        restrict:"E",
        replace:true,
        scope:{
            data:"=",
            type:"="
        },
        templateUrl:"js/system/storageManagement/tmpl/drag-disk-info.html",
        link: function(scope, elem, attrs, $ngModel) {
            scope.data.type = scope.data.ssd?0:1;
            scope.abc = scope.type;
        }
    };
}
export {dragDiskInfo}