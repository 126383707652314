alarmQueryCtrl.$inject = ['$scope','$rootScope','$location','$timeout','NgTableParams','$uibModal',"GLOBAL_CONFIG",'checkedSrv','tdsqlSrv','TableCom','$translate']
function alarmQueryCtrl($scope,$rootScope,$location,$timeout,NgTableParams,$uibModal,GLOBAL_CONFIG,checkedSrv,tdsqlSrv,TableCom,$translate) {
  	var self = $scope;
  	self.filterData = {
        pageSize: 10,
        pageNumber: 1,
        pmid:$location.search().id
    };

    function init_data(result) {
        self.alarmData = result.alarmInfo;
        self.alarmTable = new NgTableParams({
            count: 1000
        }, {
            counts: [],
            dataset: self.alarmData
        });
        var e = $("#pageNum")[0];
        if (result.totalNum && self.filterData.pageSize) {
            self.pages = Math.ceil(result.totalNum / self.filterData.pageSize);
            self.showPage = true;
            setPage(e, self.pages, self.filterData.pageNumber);
        } else {
            self.showPage = false;
        }
    }

    function setPage(container, count, pageindex) { //总页数少于10 全部显示,大于10 显示前3 后3 中间3 其余....
        var a = [];

        if (pageindex == 1) {
            a[a.length] = "<li><a href=\"#\" class=\"prev unclick\"><i class=\"icon-aw-angle-double-left\"></i></a></li>";
        } else {
            a[a.length] = "<li><a href=\"#\" class=\"prev\"><i class=\"icon-aw-angle-double-left\"></i></a></li>";
        }

        function setPageList() {
            if (pageindex == i) {
                a[a.length] = "<li><a href=\"#\" class=\"on\">" + i + "</a></li>";
            } else {
                a[a.length] = "<li><a href=\"#\">" + i + "</a></li>";
            }
        }

        if (count <= 10) { //总页数小于10
            for (var i = 1; i <= count; i++) {
                setPageList();
            }
        } else { //总页数大于10页
            if (pageindex <= 4) {
                for (var i = 1; i <= 5; i++) {
                    setPageList();
                }
                a[a.length] = "<li><span>...</span></li><li><a href=\"#\">" + count + "</a></li>";
            } else if (pageindex >= count - 3) {
                a[a.length] = "<li><a href=\"#\">1</a></li><li><span>...</span></li>";
                for (var i = count - 4; i <= count; i++) {
                    setPageList();
                }
            } else { //当前页在中间部分
                a[a.length] = "<li><a href=\"#\">1</a></li><li><span>...</span></li>";
                for (var i = pageindex - 2; i <= pageindex + 2; i++) {
                    setPageList();
                }
                a[a.length] = "<li><span>...</span></li><li><a href=\"#\">" + count + "</a></li>";
            }
        }
        if (pageindex == count) {
            a[a.length] = "<li><a href=\"#\" class=\"next unclick\"><i class=\"icon-aw-angle-double-right\"></i></a></li>";
        } else {
            a[a.length] = "<li><a href=\"#\" class=\"next\"><i class=\"icon-aw-angle-double-right\"></i></a></li>";
        }
        container.innerHTML = a.join("");

        (function pageClick() { //事件点击
            var oAlink = container.getElementsByTagName("a");
            var inx = pageindex; //初始的页码
            var clickPageFunc = function(inx) {
                self.filterData.pageNumber = inx;
                setPage(container, count, inx);
                self.clickPageToquery = function() {
                    initAlarmTable(self.filterData);
                }();
            };
            oAlink[0].onclick = function() { //点击上一页
                if (inx == 1) {
                    return false;
                }
                inx--;
                clickPageFunc(inx);
                return false;
            };
            for (var i = 1; i < oAlink.length - 1; i++) { //点击页码
                oAlink[i].onclick = function() {
                    inx = parseInt(this.innerHTML);
                    clickPageFunc(inx);
                    return false;
                };
            }
            oAlink[oAlink.length - 1].onclick = function() { //点击下一页
                if (inx == count) {
                    return false;
                }
                inx++;
                clickPageFunc(inx);
                return false;
            };
        })();
    }

    function initAlarmTable(params) {
        self.alarmData = [];
        var getAlarmData = function() {
            tdsqlSrv.getListAlarmInfo(params).then(function(result) {
                if (result && result.data) {
                    init_data(result.data);
                }
            });
        };
        getAlarmData();
    }
    initAlarmTable(self.filterData);

    self.goToPage = function(num) {
        self.filterData.pageNumber = num;
        initAlarmTable(self.filterData);
    };
}
export{alarmQueryCtrl}