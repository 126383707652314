angular.module("recycleCsSrvModule", [])
    .service("recycleCsSrv", function($http) {
        return {
            getGcList: function (data) {
                return $http({
                    method: "GET",
                    url: "awstack-manage/v2/volume/gc",
                    params: data
                });
            },
            osRestore: function(option) {
                return $http({
                    method: "PUT",
                    url: "awstack-manage/v2/volume/restore",
                    params:option
                });
            },
            osForceDelOne: function(option){
                return $http({
                    method: "DELETE",
                    url: "awstack-resource/v1/volumes/" + option,
                });
            },
            osForceDelMultiple: function(option){
                return $http({
                    method: "DELETE",
                    url: "awstack-resource/v1/volumes",
                    params:option
                });
            },
            quotaCheck: function(data,params) {
                return $http({
                    method: "post",
                    url: "awstack-user/v1/common/quota/check",
                    data: data,
                    params: params
                })
            }
        };
    });
