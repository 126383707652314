stockTransferCtrl.$inject = ["$scope", "$translate","$uibModalInstance","$location","editData","refresh","NgTableParams",'instancesSrv'];
export function stockTransferCtrl($scope, $translate, $uibModalInstance, $location, editData, refresh, NgTableParams, instancesSrv){
    let self = $scope;
    self.submitInValid = false;
    self.cloudHardDisk = {
        name:""
    }
    self.storageDevice = {
        name:""
    }
    self.cloudHardDiskList = [];
    self.isLocal = editData.startStyle == 'Local'?true:false;
    let id=editData.uid

    function stockTransferList(){
        instancesSrv.stockTransferList(id).then(function(res){                          
            if(res&&res.data&&res.data.types){
                res.data.volumes.forEach(function(item){
                    if(item.diskInfo!="iso"&&item.status=="in-use" && item.storageLimit && item.storageLimit["os-retype"]){
                        self.cloudHardDiskList.push(item)
                    }
                })
                self.submitInValidListAll = res.data.types;
            }
        })
    }
    stockTransferList()

    self.canChooseStordType = true   //只能选择云硬盘才可以选择存储设备

    self.choiceVolumes=function(value){
        // value.type="sdsdsd";
        
        self.submitInValidList = [];
        self.canChooseStordType = false
        self.submitInValidListAll.forEach(function(item,index){
            if(item.extra.volume_backend_name!=value.type){
                if(value.multiattach == "true" &&item.multiattach=="true"){
                    self.submitInValidList.push(item)
                }else if(value.multiattach == "false"){
                    self.submitInValidList.push(item)
                }
            }
        })
        if(self.submitInValidList.length){
           self.storageDevice.name=self.submitInValidList[0];
        }
        

        // if(value.type.split('_')[0] == 'nfs'){//nfs存储向ceph迁移 不可点击；
        //     self.hradDiskStatus = true;
        // }else{
        //     self.hradDiskStatus = false;
        // }
        // if(self.hradDiskStatus && self.storageDevice.name.storageProtocol=="ceph"){
        //     self.sysDiskStatus = true
        // }else{
        //     self.sysDiskStatus = false;
        // }
        self.cancelMounted = false;
        self.needShutOff=false; 
        self.needOpen=false;
        //系统盘 迁移到ceph需要关机，
        //       不是迁移到ceph,云主机需要开机
        if(value.device=="/dev/vda"){
            if(self.storageDevice.name.storageProtocol=="ceph"){
                if(editData.status!="SHUTOFF"&&editData.status!="shutoff"&&editData.status!="stopped"){
                    self.needShutOff=true;
                }
                // if(self.hradDiskStatus){
                //     self.sysDiskStatus = true;
                // }else{
                //     self.sysDiskStatus = false;
                // }
            }else if(self.storageDevice.name&&self.storageDevice.name.storageProtocol!="ceph"){
                if(editData.status!="active"){
                    self.needOpen=true;
                }
            }
        }else{
        //数据盘  迁移到ceph需要取消挂载，云主机开机或者关机无所谓
        //        不是迁移到ceph,云主机需要开机
            if(self.storageDevice.name.storageProtocol=="ceph"){
                // if(value.status=="in-use"){
                //     self.cancelMounted = true
                // }//6.1版本支持 云硬盘热迁移
            }else if(self.storageDevice.name&&self.storageDevice.name.storageProtocol!="ceph"){
                if(editData.status!="active"){
                  self.needOpen=true; 
                }
            }
        }
    }

    self.changeStordType = function(volumes,type){
        self.cancelMounted = false;
        self.needShutOff=false; 
        self.needOpen=false;
        //系统盘 迁移到ceph需要关机，
        //       不是迁移到ceph,云主机需要开机
        if(volumes.device=="/dev/vda"){
            if(type.storageProtocol=="ceph"){
               if(editData.status!="SHUTOFF"&&editData.status!="shutoff"&&editData.status!="stopped"){
                  self.needShutOff=true; 
               }
            }else{
               if(editData.status!="active"){
                  self.needOpen=true; 
               }
            }
        }else{
        //数据盘  迁移到ceph需要取消挂载，云主机开机或者关机无所谓
        //        不是迁移到ceph,云主机需要开机
            if(type.storageProtocol=="ceph"){
                // if(volumes.status=="in-use"){
                //     self.cancelMounted = true
                // }//6.1版本支持 云硬盘热迁移
            }else{
                if(editData.status!="active"){
                  self.needOpen=true; 
                }
            }
        }
    }

    self.stockTransferConfirm = function(fileFrom){
        if(fileFrom.$valid&&!self.needShutOff&&!self.needOpen&&!self.cancelMounted){
            var postData = {
                "volumeId":self.cloudHardDisk.name.id,
                "newType":self.storageDevice.name.id
            }
            instancesSrv.stockTransfer(postData).then(function(res){
                refresh();
            })
            $uibModalInstance.close();
        }else{
            self.submitInValid = true;
        }
    }
}
