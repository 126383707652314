osUpgradeCtrl.$inject = ["$scope","$rootScope","$translate","$uibModalInstance","$location","editData","refresh","NgTableParams",'instancesSrv','getProused','getDomused','context'];
export function osUpgradeCtrl($scope, $rootScope, $translate, $uibModalInstance, $location, editData, refresh, NgTableParams, instancesSrv,getProused,getDomused,context){
    let self = $scope;
    if (!context.upgrade) {
        getProused();
        getDomused();
        instancesSrv.getServerDetail(editData.uid).then(function(res) {
            self.curconf = res.data;
            instancesSrv.getFlavors().then(function(result) {
                if (result && result.data && result.data.length) {
                    // _.forEach(result.data, function(val) {
                    //     val.text = val.name + "：" + val.vcpus + $translate.instant("aws.instances.conf.memtip") + (val.ram / 1024).toFixed(1)+"GB";
                    // });
                    
                    // result.data.map(function(item) {
                    //     if (item.ram == self.curconf.ram && item.vcpus == self.curconf.vcpus) {
                    //         return
                    //     } else {
                    //         self.upgradeLists.push(item);
                    //     }
                    // })
                    self.upgradeLists = [];
                    self.upgradeLists = result.data.filter(item=>{
                        item.text = item.name + "：" + item.vcpus + $translate.instant("aws.instances.conf.memtip") + (item.ram / 1024).toFixed(1)+"GB";
                        let flag = (item.ram == self.curconf.ram && item.vcpus == self.curconf.vcpus);
                        return !flag&&item.id!=self.curconf.flavorId;
                    })
                }
            });
        });

        self.upgrade = {};
        self.submitInValid = false;
        self.chooseCpuMem = function(data) {
            (data.vcpus - self.curconf.vcpus > context.coresDomquota.total - context.coresDomquota.used) ? self.coreDomText = $translate.instant("aws.instances.quota.domCpu"): self.coreDomText = "";
            (data.ram - self.curconf.ram > context.ramDomquota.total - context.ramDomquota.used) ? self.ramDomText = $translate.instant("aws.instances.quota.domRam"): self.ramDomText = "";
            (data.vcpus - self.curconf.vcpus > context.coresquota.total - context.coresquota.used) ? self.coreProText = $translate.instant("aws.instances.quota.proCpu"): self.coreProText = "";
            (data.ram - self.curconf.ram > context.ramquota.total - context.ramquota.used) ? self.ramProText = $translate.instant("aws.instances.quota.proRam"): self.ramProText = "";
            //(data.vcpus - context.hotgradeData.current_cpus > context.hotgradeData.maximum_cpus) ? self.coreMaxText = $translate.instant("aws.instances.quota.coreMaxText") + self.hotgradeData.maximum_cpus: self.coreMaxText = "";
            //(data.ram -context.hotgradeData.current_mem_mb> context.hotgradeData.maximum_mem_mb) ? self.ramMaxText = $translate.instant("aws.instances.quota.ramMaxText")+(self.hotgradeData.maximum_mem_mb/1024).toFixed(2)+"G": self.ramMaxText = "";
        };
        self.confirm = function(upgradeForm) {
            if (upgradeForm.$valid) {
                var postData = {
                    "flavorId": self.upgrade.flavor.id
                };
                $uibModalInstance.close();
                instancesSrv.os_upgrade(editData.uid, postData).then(function(result) {
                    //var resizeIds = angular.fromJson(sessionStorage.ResizeIds);
                    //resizeIds ? "" : resizeIds = [];
                    //resizeIds.push(editData.uid)
                    //sessionStorage.ResizeIds = angular.toJson(resizeIds)
                });
            } else {
                self.submitInValid = true;
            }
        };
    }
}