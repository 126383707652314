networkCardSecConfigCtrl.$inject=["$scope", "$translate","$uibModalInstance","$location","editData","refresh","NgTableParams",'instancesSrv','ipSrv'];
export function networkCardSecConfigCtrl($scope, $translate,$uibModalInstance,$location,editData,refresh,NgTableParams,instancesSrv,ipSrv){
    var self = $scope;
    self.sec = {};
    self.netWorkCard = {};
    self.addressPort = {
        list: []
    };
    self.addressPortInitial = {
        list: []
    }
    self.openOrClose = {
        flag:"0"
    };

    // 私网ip的change事件
    self.changePort = (value)=>{
        self.getInitialIpData(value);
    }

    // 获取当前私网ip是ipv4还是ipv6
    self.getIpVersion = (value)=> {
        let ipVersion = value.name.split(':')[1].indexOf('.')!=-1 ? 'ipv4' : 'ipv6';
        return ipVersion
    }

    // 获取私网ip的下拉列表和初始值 
    self.getOsNet = ()=>{
        instancesSrv.getOsNet(editData.uid).then((result)=> {
            self.osNetList = [];
            if ( result && result.data && result.data.length) {
                result.data.map((item)=> {
                    if(item.fixed_ips&&item.fixed_ips.length>0){
                        item.fixed_ips.forEach(it=>{
                            self.osNetList.push({
                                id: item.port_id,
                                name: editData.name + ":" + it.ip_address,
                                netName:item.net_name
                            });
                        })
                    }
                });
                self.sec.port_id  = self.osNetList[0];
                self.getIpVersion(self.sec.port_id);
                self.changePort(self.sec.port_id);
            }
        });
    }

    // 获取ip输入框的初始值
    self.getInitialIpData = (value) =>{
        self.ipVersion = self.getIpVersion(value);
        if(value.netName==$translate.instant("aws.instances.paasNet")){
            self.isPaasNetSecurity = true;
        }else{
            self.isPaasNetSecurity = false;
        }
        self.addressPort.list = [];
        instancesSrv.openOrCloseNetwork(value.id).then((res)=> {
            if(res&&res.data&&res.data.port_security_enabled){
                if(res.data.port_security_enabled=="false"){       
                    self.openOrClose.flag="0";
                    self.iptablesList = []
                    self.netWorkCard.name = {}
                }else{
                    self.openOrClose.flag="1" ;
                    self.iptablesList = res.data.securityGroupsList;
                    self.iptablesList.forEach((item)=> {
                        if(item.id==res.data.securityGroupsMap.id){
                            self.netWorkCard.name = item
                        }
                    })
                }
                // 初始数据源
                self.addressPortInitial.list = res.data.allowed_address_pairs;
                // 过滤之后ip输入框的数据
                self.addressPort.list = res.data.allowed_address_pairs.filter(item=>{
                    if(self.ipVersion == 'ipv4') {
                        return item.ip_address.indexOf('.')!=-1;
                    }else {
                        return item.ip_address.indexOf(':')!=-1;
                    }
                })
            }
        })
    }

    // 获取初始的ipv4或者ipv6的值 
    self.dealIpData = (value)=> {
        let addressPortList = [];
        self.addressPortInitial.list.forEach(item=>{
            // 当前为ipv4时就取ipv6的初始值,否则就取ipv4的初始值
            if(value == 'ipv4' && item.ip_address.indexOf(':')!=-1) {
                addressPortList.push(item);
            }else if(value == 'ipv6' && item.ip_address.indexOf('.')!=-1){
                addressPortList.push(item);
            }
        })
        return addressPortList;
    }

    self.ipPattern = $translate.instant("aws.instances.tipMsg.currentIp");
    self.macPattern = $translate.instant("aws.instances.tipMsg.currentMac");

    // ip地址对输入框的新增
    self.addAddressPair = ()=>{
        if(self.addressPort.list.length <= 9){
            self.addressPort.list.push({
                "ip_address":"",
                "mac_address":""
            })
        }
    }

    // ip地址对输入框的删除
    self.deleteAddressPair = (num)=>{
        self.addressPort.list.splice(num,1)
    }       

    // 网络安全策略的change事件
    self.changeNetwork = (title,port)=>{
        if(title=="open"){
            instancesSrv.openOrCloseNetwork(port.id).then((result)=> {
                if(result&&result.data){   
                    self.iptablesList = result.data.securityGroupsList;
                    self.iptablesList.forEach((item)=> {
                        if(result.data.securityGroupsMap.id){
                            if(item.id==result.data.securityGroupsMap.id){
                                self.netWorkCard.name = item
                            }
                        }else{
                            self.netWorkCard.name=result.data.securityGroupsList[0] 
                        }
                    })
                }
            })
        }else{
            instancesSrv.openOrCloseNetwork(port.id).then((result)=> {
                if(result&&result.data){  
                    self.iptablesList = result.data.securityGroupsList;
                    self.netWorkCard.name = result.data.securityGroupsList[0]    
                }
            })
        }
    }

    // 校验输入的ip是否存在重复项
    self.isRepeatFunc = (arr)=> {
        var hash = {};
        var isTrue;
        for(let i=0;i<arr.length;i++){
            if(hash[arr[i]]==1){
                isTrue = true;
                break;
            }
            hash[arr[i]] = 1;
        }
        return isTrue;
    }

    // 地址对ip的失点事件
    self.resetIsIPRepeat = ()=> {
        self.isIpRepeat = false;
    }

    // 提交数据
    self.confirmSec = (part,group,bool,feild)=> {
        let id=""
        let postData={
            port:{
                "port_security_enabled":false,
                "security_groups":[],
                "allowed_address_pairs":[]
            }
        }
        if(bool=="0"&&part){
            postData.port.port_security_enabled=false;
            id=part.id
            instancesSrv.setNetworkConfig(id,postData).then((res)=> {
                refresh();
            })
            $uibModalInstance.close()
        }else if(bool=="1"){
            //ip格式校验通过
            if(feild.$valid){
                //判断是否有重复的ip
                let ipArr=[];
                // 提交数据时拼接ipv4和ipv6的数据
                let concatIpArr = [];
                let dealIpDataList =  self.dealIpData(self.ipVersion);
                concatIpArr = self.addressPort.list.concat(dealIpDataList)
                // 数据源去重
                if(concatIpArr.length > 0) {
                    concatIpArr = ipSrv.uniqueArray(concatIpArr,'ip_address');
                    for(var i=0;i<concatIpArr.length;i++){
                        ipArr.push(concatIpArr[i].ip_address);
                    }
                }
                self.isIpRepeat=self.isRepeatFunc(ipArr);
                if(!self.isIpRepeat){
                    id=part.id
                    postData.port.port_security_enabled=true; 
                    postData.port.security_groups.push(group.id)
                    concatIpArr.map((item)=> {
                        delete (item.$$hashKey)
                    })
                    postData.port.allowed_address_pairs=concatIpArr
                    instancesSrv.setNetworkConfig(id,postData).then((res)=> {
                        refresh();
                    })
                    $uibModalInstance.close()
                }else{
                    $timeout(()=> {
                        self.isIpRepeat=false;
                    }, 3000);
                }  
            }else{
                self.submitInValid = true; 
            }
        }
    }

    self.getOsNet();
}
