import "./memSrv";
angular.module("memModule", ["memSrv"])
    .controller("memCtrl", ["$scope", "$uibModal","$translate","memsrv",function ($scope, $uibModal,$translate,memsrv) {
        let self = $scope;
        //常量
        let __Data = {
            submitInValid:{},
            InValid:false,
            node:{
                selected:""
            },
            defaultMem:{
                selected:{
                    value:"2M",
                }
            },
            mem:{
                selected:{
                    value:"2M",
                }
            },
            memList:[
                {
                    value:"2M",
                },
                {
                    value:"1G",
                },
                // {
                //     value:"small",
                // },
                // {
                //     value:"large",
                // },
                // {
                //     value:"any",
                // }
            ],
            isSelect:false,
            memSetList:[],
            num:{
                value:""
            }
        }
        self = _.extend(self,__Data);
        // self.setNumConfig = function(item){
        //     $uibModal.open({
        //         animation: true,
        //         templateUrl: "js/shortcuts/instances/tmpl/setnumconfig.html",
        //         controller: "setNumConfigCtrl",
        //         resolve: {
        //             context:function(){
        //                 return self;
        //             },
        //             editData:function(){
        //                 return {value:6}
        //             }
        //         }
        //     });
        // }
        self.initData = function(){
            memsrv.getMemApi().then(res=>{
                if(res&&res.data){
                    self.memSetList = res.data;
                    if(self.memSetList.length>0){
                        
                        self.memSetList.forEach((item,index)=>{
                            self.submitInValid[item.id] = false;
                           item.formName = 'editForm'+item.id;
                            //赋值默认内存
                            item.defaultMem = {
                                selected:{
                                    value:item.defaultHugepagesz,
                                }
                            }
                            //赋值内存
                            item.mem = {
                                selected:{
                                    value:item.hugepagesz,
                                }
                            }
                            //赋值页数
                            item.num = {
                                value:item.hugepages
                            }
                        })
                    }
                }
            }).then(()=>{
                memsrv.getNodeList().then(res=>{
                    if(res&&res.data&&_.isArray(res.data)){
                        self.nodeList = res.data.filter(item=>{
                            let flag = false;
                            self.memSetList.forEach(it=>{
                                if(item.hostName==it.hostName){
                                    flag = true;
                                }
                            })
                            return !flag;
                        })
                        if(self.nodeList.length>0){
                            self.node.selected = self.nodeList[0];
                            self.isSelect = true;
                        }else{
                            self.isSelect = false;
                        }
                        
                    }
                })
            })
            
        }

        self.addNode = function(field){
            let data = {
                "hostName":self.node.selected.hostName,
                "defaultHugepagesz":self.defaultMem.selected.value,
                "hugepagesz":self.mem.selected.value,
                "hugepages":self.num.value, 
                "regionKey":localStorage.regionKey
            }
            if(field.$valid){
                self.InValid = false;
                memsrv.createMemApi(data).then(()=>{
                    self.initData();
                    self.num.value = "";
                })
            }else{
                self.InValid = true;
            }
        }

        self.editNode = function(field,item){
            let data = {
                "hostName":item.hostName,
                "defaultHugepagesz":item.defaultMem.selected.value,
                "hugepagesz":item.mem.selected.value,
                "hugepages":item.num.value, 
                "regionKey":localStorage.regionKey
            }
            if(field.$valid){
                self.submitInValid[item.id] = false;
                memsrv.createMemApi(data).then(()=>{
                    self.initData();
                })
            }else{
                self.submitInValid[item.id] = true;
            }
            
        }

        self.restartNode = function(item){
            var createPortsModal=$uibModal.open({
                animation: true,
                templateUrl: "js/system/bigmem/tmpl/checkUser.html",
                controller: "checkUserCtrl",
                resolve: {
                    refresh: function() {
                        return self.initData;
                    },
                    editData:function(){
                        return item
                    },
                    type:function(){
                        return "bigRestartNode"
                    }
                }
            });
        }
        self.initData();
    }]).controller("checkUserCtrl",["$scope","refresh","type","editData","$uibModalInstance","memsrv",function(scope,refresh,type,editData,$uibModalInstance,memsrv){
        let self = scope;
        self.submitInValid = false;
        self.node = {
            username:"",
            password:"",
        }
        self.modalType = type;
        
        self.confirm = function(field){
            if (field.$valid) {
                var postData = {
                    "enterpriseLoginName":localStorage.enterpriseLoginName,
                    "userName":self.node.username,
                    "password":self.node.password
                }
                var params = {
                    "hostName":editData.hostName,
                    "regionKey":localStorage.regionKey
                }
                $uibModalInstance.close();
                memsrv.restartNode(postData,params).then(function() {
                    refresh();
                });
            } else {
                scope.submitInValid = true;
            }
        }
    }])

    
