import "./userDataSrv";
import "../department/depviewSrv";
import "../roles/roleDataSrv";
import "../cvm/instances/instancesSrv"
import "../cvm/volumes/regularSnapSrv"

var userModule = angular.module("userModule", ["ngTable", "ngAnimate", "ui.bootstrap", "usersrv", "ngSanitize", "ui.select", "depviewsrv", "rolesrv", "instancesSrv"]);
userModule.controller("UserCtrl", function ($scope, $rootScope, NgTableParams, userDataSrv, checkedSrv, $uibModal, $translate, depviewsrv, GLOBAL_CONFIG, roleDataSrv, instancesSrv, regularSnapSrv, accessPolicySrv) {
    var self = $scope;
    self.patternMsg = $translate.instant('aws.register.info.special_8_16');
    self.userName = localStorage.userName;
    self.user_managementRole = localStorage.managementRole;
    self.headers = {
        "realName": $translate.instant("aws.users.name"),
        "role": $translate.instant("aws.users.role"),
        "baseRole": $translate.instant("aws.users.baseRole"),
        "name": $translate.instant("aws.users.userName"),
        "phone": $translate.instant("aws.users.phone"),
        "status": $translate.instant("aws.users.status"),
        "createTime": $translate.instant("aws.users.createTime"),
        "operate": $translate.instant("aws.users.operate"),
        "domainName": $translate.instant("aws.users.domainName")
    };
    self.globalSearch = {
        searchTerm: ''
    };

    //列表搜索框右侧设置按钮配置
    self.userTitleName = "userRule";
    if (sessionStorage["userRule"]) {
        self.userTitleData = JSON.parse(sessionStorage["userRule"]);
    } else {
        self.userTitleData = [
            { name: 'users.userName', value: true, disable: true, search: "name" },
            { name: 'users.role', value: true, disable: false, search: "managementRoleName" },
            { name: 'users.domainName', value: true, disable: false, search: "domainName" },
            { name: 'users.email', value: true, disable: false, search: "email" },
            { name: 'users.phone', value: true, disable: false, search: "phone" },
            { name: 'users.status', value: true, disable: false, search: "status" },
            { name: 'users.createTime', value: true, disable: false, search: "createTime" },
            { name: 'users.operate', value: true, disable: false, search: "operate" },
        ]
    }

    self.userSearchTearm = function (obj) {
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function (item) {
            item.searchTerm = [];
            titleData.forEach(function (value) {
                if (value.value) {
                    item.searchTerm.push(item[value.search]);
                }
            })
            item.searchTerm = item.searchTerm.join('\b');
        })
        // self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: tableData });
    }
    var _userData = [];
    self.$watch(function () {
        return self.checkedItems;
    }, function (value) {
        self.notDelTip = "";
        self.notAllocateTip = "";
        self.notEditTip = "";
        self.notEditPassTip = "";
        self.isDisabled = false;
        self.delisDisabled = false;
        self.sigDisabled = false;
        self.iseditDisabled = false;
        self.hideadminActive = false;
        if (value && value.length == 1) {
            // if(value[0].managementRole == localStorage.managementRole){

            // }
            /**
             * 4中主要角色
             * admin security audit others
             * 
             * admin :
             * 可以编辑所有用户包括自己；
             * 可以重置密码所有 除了自己；
             * 可以删除所有 除了 admin security audit;
             * 
             * security:
             * 可以编辑所有用户包括自己；
             * 可以重置密码所有 除了security admin;
             * 可以删除所有 除了security admin audit;
             * 
             * others:
             * (拥有用户管理权限的)
             * 沿用原来逻辑
             */
            if (localStorage.managementRole == '2') { //admin
                //编辑
                // 重置
                if (value[0].managementRole == '2') {
                    self.isDisabled = true;
                }
                // 删除
                if (value[0].managementRole == '2' || value[0].managementRole == '8' || value[0].managementRole == '9') {
                    self.delisDisabled = true;
                }
            } else if (localStorage.managementRole == '8') { //security
                //编辑
                // 重置
                if (value[0].managementRole == '2' || value[0].managementRole == '8') {
                    self.isDisabled = true;
                }
                // 删除
                if (value[0].managementRole == '2' || value[0].managementRole == '8' || value[0].managementRole == '9') {
                    self.delisDisabled = true;
                }
            } else if (value[0].managementRole == localStorage.managementRole) {// others
                self.delisDisabled = true;
                self.notDelTip = $translate.instant("aws.users.tip.notDelTip");
                if (value[0].name == "admin") { //不能操作admin用户------2017.5.16
                    self.notAllocateDis = true;
                    self.isDisabled = true;
                    self.iseditDisabled = false;
                    self.hideadminActive = true;
                    self.notAllocateTip = $translate.instant("aws.users.tip.allocate_admin");
                    self.notEditTip = $translate.instant("aws.users.tip.edit_admin");
                    self.notEditPassTip = $translate.instant("aws.users.tip.edit_admin_pass");
                } else if (value[0].name == localStorage.userName) { //用户登录后不能操作同级用户
                    self.notAllocateDis = false;
                    self.isDisabled = false;
                    self.iseditDisabled = false;
                    self.hideActive = true;
                } else {
                    self.notAllocateDis = true;
                    self.isDisabled = true;
                    self.iseditDisabled = true;
                    self.notAllocateTip = $translate.instant("aws.users.tip.allocate_user");
                    self.notEditTip = $translate.instant("aws.users.tip.edit_user");
                    self.notEditPassTip = $translate.instant("aws.users.tip.edit_user_pass");
                }
            }


        } else if (value && value.length > 1) {
            self.isDisabled = true;
            self.sigDisabled = true;
            self.iseditDisabled = true;
            value.map(function (item) {
                if (item.managementRole == localStorage.managementRole) {
                    self.delisDisabled = true;
                    // self.notDelTip =  $translate.instant("aws.users.tip.notDelTip");                        
                }
                // 删除操作 多选 security audit不可删除
                if (item.managementRole == '8' || item.managementRole == '9') {
                    self.delisDisabled = true;
                }
            });
        }
        else {  //check为空的时候
            self.isDisabled = true;
            self.delisDisabled = true;
            self.sigDisabled = true;
            self.iseditDisabled = true;
        }
    });

    //获取user table的数据 初始化userTable
    var initUserTable = function () {
        if (localStorage.managementRole == '3') {
            var params = {
                domainUid: localStorage.domainUid
            }
        } else {
            var params = {}
        }
        userDataSrv.getUserTableData(params).then(function (result) {
            if (result && result.data && result.data.length) {
                if (self.ADMIN) {
                    userDataSrv.userTableAllData = result.data;
                } else if (self.DOMAIN_ADMIN) {
                    userDataSrv.userTableAllData = result.data.filter(item => item.domainUid == localStorage.domainUid && item.name != "admin");
                } else if (self.SECURITY) {
                    userDataSrv.userTableAllData = result.data;
                }
                self.init_data();
            }
            result ? self.loadData = true : "";
        });
    };
    initUserTable();

    self.init_data = function () {
        self.userTableData = userDataSrv.userTableAllData;
        self.$watch(function () {
            return userDataSrv.userTableAllData;
        }, function (value) {
            var user_data = _.map(value, function (item) {
                if (item.enabled == true) {
                    item.status = $translate.instant("aws.users.active");
                    item.operate = $translate.instant("aws.users.locked");
                } else {
                    item.status = $translate.instant("aws.users.locked");
                    item.operate = $translate.instant("aws.users.active");
                }
                if (item.managementRole == "2") {
                    item.realName = "admin";
                    // item.operate="";
                }
                // 不是保密员 审计员 系统管理员
                if (localStorage.managementRole == '2') {
                    if (item.managementRole != '8' && item.managementRole != '9' && item.managementRole != '2') {
                        item.user_manage = true;
                    }
                } else if (localStorage.managementRole == '8') {
                    if (item.managementRole != '8') {
                        item.user_manage = true;
                    }
                }

                if (item.domainUid == 'default') {
                    item.domainName = $translate.instant("aws.common.defaultDepar");
                }
                self.DOMAIN_ADMIN ? item.domainName = localStorage.domainName : "";
                item.managementRoleName = $translate.instant("aws.users.roles." + item.managementRole);
                if (item.privilegeName == '超级管理员') {
                    item.privilegeName = $translate.instant("aws.users.roles.2");
                }
                else if (item.privilegeName == '部门管理员') {
                    item.privilegeName = $translate.instant("aws.users.roles.3");
                }
                else if (item.privilegeName == '项目管理员') {
                    item.privilegeName = $translate.instant("aws.users.roles.4");
                }
                else if (item.privilegeName == '普通用户') {
                    item.privilegeName = $translate.instant("aws.users.roles.5");
                }
                else if (item.privilegeName == '系统保密员') {
                    item.privilegeName = $translate.instant("aws.users.roles.8");
                }
                else if (item.privilegeName == '系统审计员') {
                    item.privilegeName = $translate.instant("aws.users.roles.9");
                }
                item.email == null ? item.email = "" : "";
                item.phone == null ? item.phone = "" : "";

                item.searchTerm = [item.name, item.managementRoleName, item.domainName, item.email, item.phone, item.status, item.createTime, item.operate].join('\b');
                return item;
            });
            _userData = angular.copy(user_data);
            self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: user_data });
            self.userSearchTearm({ tableData: self.userTableData, titleData: self.userTitleData });
            checkedSrv.checkDo(self, user_data, "id");
        }, true);
    };
    //刷新列表操作
    self.refreshUser = function () {
        self.globalSearch.searchTerm = '';
        initUserTable();
    };

    self.applyGlobalSearch = function () {
        var term = self.globalSearch.searchTerm;
        self.tableParams.filter({ searchTerm: term });
    };

    //激活操作
    self.operateUser = function (user) {
        var operateParams = {
            enterpriseUid: user.enterpriseUid,
            id: user.id,
            userUid: user.userUid
        };
        if (user.enabled == true) {
            operateParams.state = 0;
        } else {
            operateParams.state = 1;
        }
        userDataSrv.operateUserAction(operateParams).then(function () {
            initUserTable();
        });
    };
    //新建and编辑
    self.updateUser = function (type, editData) {
        var scope = self.$new(); // 子 scope 原型 继承父 scope ..



        scope.inStep = 1;
        scope.inStepOneBar = 0;
        scope.inStepTwoBar = 1;
        scope.inStepThreeBar = 2;
        scope.inStepFourBar = 3;
        scope.projectCheck = false;
        scope.stepTo = function (m, check) {
            /*可能项目没有*/
            if (check) {
                if (self.userForm.selectedProject == '') {
                    scope.projectCheck = true;
                } else {
                    scope.inStep = m;
                    scope.projectCheck = false;
                }
            } else {
                scope.inStep = m;
                scope.projectCheck = false;
            }
        }

        var userModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: type == 'new' ? "js/user/tmpl/usermodal.html" : "userModel.html",
            scope: scope
        });
        scope.submitted = false;
        scope.interacted = function (field) {
            return scope.submitted || field.$dirty;
        };
        userModal.opened.then(function () {
            self.userForm = {
                realName: "",
                selectedOrg: "",
                selectedPrivilege: "",
                selectedDepartment: "",
                selectedProject: "",
                selectedRole: "",
                name: "",
                password: "",
                cfmPassword: "",
                enabled: "true",
                phone: "",
                email: ""
            };
            var allPrivileges;
            function getAllPrivilegesFunc() {
                roleDataSrv.getRoledata().then(function (res) {
                    if (res && res.data && res.data.list) {
                        allPrivileges = res.data.list;
                    }
                }).finally(function () {
                    getRolesFunc()
                })
            };
            getAllPrivilegesFunc();
            self.changeRoleFun = function (item) {
                for (var i = 0; i < allPrivileges.length; i++) {
                    if (item.id == allPrivileges[i].id) {
                        self.privilegesItems = allPrivileges[i].id;
                    }
                }
            }
            //获取组织
            // function getOrgsFunc() {
            //     userDataSrv.getOrgsData().then(function(res) {
            //         self.orgs = {};
            //         scope.orgs.options = [];
            //         scope.orgs.options.push({"id":null,"levname":"请选择","name":"请选择",type:0});
            //         self.listAllOrg(res.data);
            //         scope.orgs.options.map(item=>{
            //             item.levname = item.name;
            //             for(let i=0;i<item.deptLevel;i++){
            //                 item.levname = "|__"+item.levname;
            //             }
            //         });
            //         if(type == "edit"){
            //             _.forEach(self.orgs.options,function(item){
            //                 if(editData.departmentId==item.id){
            //                     self.userForm.selectedOrg=item;
            //                 }
            //             });
            //         }
            //     });
            // }
            self.listAllOrg = function (arr) {
                _.forEach(arr, function (item) {
                    if (item.children.length > 0) {
                        scope.orgs.options.push(item);
                        self.listAllOrg(item.children);
                    } else {
                        scope.orgs.options.push(item);
                    }

                });
            };
            //获取权限
            var getPrivilegesFunc = function (item) {
                var postData = { domainUid: item.domainUid };
                roleDataSrv.getRoledata(postData).then(function (res) {
                    self.privileges = {
                        options: res.data.list,
                        selected: res.data.list[0] ? res.data.list[0] : ""
                    };
                    self.userForm.selectedPrivilege = self.privileges.selected;
                });
            };
            self.changeDpm = function (item) {
                var params = { domainUid: item ? item.domainUid : "" };
                userDataSrv.getProjectData(params).then(function (res) {
                    self.projects = {
                        options: res.data,
                        selected: res.data[0] ? res.data[0] : ""
                    };
                    self.userForm.selectedProject = self.projects.selected;
                });
                getPrivilegesFunc(item);
            };
            //获取部门、项目;项目与部门联动，项目随部门的变化而改变
            var getDepartmentsFunc = function () {
                //获取部门
                if ($rootScope.ADMIN) {
                    userDataSrv.getDepartmentData().then(function (res) {
                        self.dpms = {
                            options: res.data,
                            selected: res.data[0] ? res.data[0] : ""
                        };
                        self.userForm.selectedDepartment = self.dpms.selected;
                        return res.data[0];
                    }).then(function (item) {
                        //根据部门获取相应的项目和权限
                        self.changeDpm(item);
                    });
                } else if ($rootScope.DOMAIN_ADMIN) {
                    var curDomain = [{ name: localStorage.domainName, domainUid: localStorage.domainUid }];
                    self.dpms = {
                        options: curDomain,
                        selected: curDomain[0]
                    };
                    self.domain_dis = true;
                    self.userForm.selectedDepartment = self.dpms.selected;
                    self.changeDpm(curDomain[0]);
                }

            };
            //获取角色
            var getRolesFunc = function () {
                userDataSrv.getRolesDataNew().then(function (res) {
                    // var roles = res.data.filter(function(item){
                    //     if(localStorage.permission == "enterprise"){
                    //         if($rootScope.ADMIN){
                    //             return (item.name != "service" && item.name != "guest" && item.name != "admin");
                    //         }else{
                    //             return (item.name != "service" && item.name != "guest" && item.name != "admin" && item.name != "domain_admin");
                    //         }
                    //     }else if(localStorage.permission == "stand"){
                    //          return item.name == "member";
                    //     }

                    // });
                    if (res && res.data) {
                        var roles = res.data;
                        roles.map(function (item) {
                            item.roleName = item.name;
                            if (item.name == '部门管理员') {
                                item.name = $translate.instant("aws.users.roles.3");
                            }
                            else if (item.name == '项目管理员') {
                                item.name = $translate.instant("aws.users.roles.4");
                            }
                            else if (item.name == '普通用户') {
                                item.name = $translate.instant("aws.users.roles.5");
                            }
                            // item.name = $translate.instant("aws.users.cu.roles."+item.name);
                        });
                        self.roles = {
                            options: roles,
                            selected: roles[0] ? roles[0] : ""
                        };
                        self.userForm.selectedRole = self.roles.selected;
                        // if(localStorage.permission=="stand"){
                        //     self.userForm.selectedRole = roles[roles.length-1];
                        // }
                        self.changeRoleFun(self.roles.selected)
                    }
                });
            };
            // getOrgsFunc();
            //getPrivilegesFunc();
            getDepartmentsFunc();


            switch (type) {
                case "new":
                    self.userModal_title = $translate.instant("aws.users.adduser");
                    self.editUser = false;
                    scope.userConfirm = function (data, formField) {
                        if (formField.$valid) {
                            var params = {
                                "name": data.name,
                                // "realName": data.name, //可以为空
                                "email": data.email, //可以为空
                                "phone": data.phone, //可以为空
                                "enabled": data.enabled,
                                "password": data.password,
                                "enterpriseUid": localStorage.enterpriseUid,
                                "domainUid": data.selectedDepartment.domainUid, //部门
                                "privilegeId": self.privilegesItems,  //data.selectedPrivilege.id, //权限
                                "defaultProjectUid": data.selectedProject.projectUid, //项目
                                // "roleUid": data.selectedRole.id, //角色
                                // "roleName": data.selectedRole.roleName
                            };
                            data.selectedOrg.id ? params.departmentId = data.selectedOrg.id : params.departmentId = "";
                            userDataSrv.addUserAction(params).then(function () {
                                initUserTable();
                            });
                            userModal.close();
                        } else {
                            scope.submitValid = true;
                        }
                    };
                    break;
                case "edit":
                    self.userModal_title = $translate.instant("aws.users.edituser");
                    self.editUser = true;
                    self.submitValid = false;
                    editData.cfmPassword = editData.password;
                    if (editData.enabled == true) {
                        editData.enabled = "true";
                    } else {
                        editData.enabled = "false";
                    }
                    self.userForm = editData;
                    self.userForm.managementRoleName = $translate.instant("aws.users.roles." + self.userForm.managementRole + "");
                    if (localStorage.managementRole == '8') {
                        self.securityStatus = true;
                    }
                    scope.userConfirm = function (data, formField) {
                        if (formField.$valid) {
                            var params = {
                                "name": data.name,
                                "realName": data.name, //可以为空
                                "email": data.email, //可以为空
                                "phone": data.phone, //可以为空
                                "enabled": data.enabled,
                                //"password": data.password,
                                "description": "",
                                "enterpriseUid": localStorage.enterpriseUid,
                            };
                            params.id = editData.id; //编辑用户时参数增加了一个id
                            params.userUid = editData.userUid;
                            userDataSrv.editUserAction(params).then(function () {
                                initUserTable();
                            });
                            userModal.close();
                        } else {
                            scope.submitValid = true;
                        }
                    };
                    break;
            }
        });
        userModal.closed.then(function () {
            checkedSrv.checkDo(self, _userData, "id");
        });

    };
    //删除
    self.deleteUser = function (checkedItems) {
        var uids = [], jobIds = [];
        _.forEach(checkedItems, function (item) {
            uids.push(item.userUid);
        });
        var checkData = {
            userIds: uids
        }
        //判断该用户下是否有定时快照
        instancesSrv.checkSheduleJob(checkData).then(function (result) {
            var content = {
                target: "delUser",
                msg: "<span>" + $translate.instant("aws.users.delMsg") + "</span>",
                data: { checkedItems, jobIds }
            };
            if (result && result.data && result.data.length) {
                content.data.jobIds = result.data;
                content.msg = "<span>" + $translate.instant("aws.instances.tipMsg.delSever") + "</span>" + "</br>" +
                    "<span>" + $translate.instant("aws.instances.tipMsg.delUserJob") + "</span>";
            }
            self.$emit("delete", content);
        })


    };
    self.$on("delUser", function (e, data) {
        var ids = [], uids = [];
        var checkedItems = data.checkedItems;
        var jobIds = data.jobIds;
        _.forEach(checkedItems, function (item) {
            ids.push(item.id);
            uids.push(item.userUid);
        });
        var delParams = {
            ids: ids,
            uids: uids
        };
        userDataSrv.delUserAction(delParams).then(function (res) {
            return res;
        }).then(function () {
            initUserTable();
        });
        //删除该用户下的所有定时快照
        if (jobIds.length) {
            regularSnapSrv.delTaskBatch({ ids: jobIds }).then(function () {
            })
        }
    });

    //分配权限  目前只能分配一个权限，所以权限分配做成互斥的
    self.allocatePrivilege = function (type, editData) {
        var scope = $rootScope.$new();
        var userModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "allocatePrivilege.html",
            scope: scope
        });
        userModal.opened.then(function () {
            var params = {
                user_id: editData.id
            };
            var postData = {
                domainUid: editData.domainUid
            };
            var checkedRoles = [],
                checkedUserRoles = [],
                user_roles = [],
                initUserRoleId = "";
            //获取角色，初始化两个table的数据
            roleDataSrv.getRoledata(postData).then(function (result) {
                if (result && result.data && result.data.list) {
                    //self.roles=result.data;
                    return result.data.list;
                }
            }).then(function (data) {
                userDataSrv.getUserPrivileges(params).then(function (result) {
                    if (result && result.data) {
                        //self.userRoles=result.data;
                        initUserRoleId = angular.copy(result.data);
                        _.forEach(result.data, function (id) {
                            _.remove(data, function (item) {
                                if (item.id == id) {
                                    user_roles.push(item);
                                }
                                return item.id == id;
                            });
                        });
                        scope.roles = data;
                        scope.userRoles = user_roles;
                        scope.init_Privileges_table();
                        scope.init_userPrivs_table();
                    }

                });
            });

            //监听table数据变化
            scope.init_Privileges_table = function () {
                scope.checkboxes = {
                    checked: false,
                    roles: {}
                };
                scope.$watch(function () {
                    return scope.checkboxes.roles;
                }, function () {
                    if (scope.roles === 0) return;
                    checkedRoles = [];
                    _.forEach(scope.roles, function (item) {
                        if (scope.checkboxes.roles[item.id]) {
                            checkedRoles.push(item.id);
                        }
                    });
                }, true);
            };
            scope.init_userPrivs_table = function () {
                scope.chkboxes = {
                    checked: false,
                    userRoles: {}
                };
                scope.$watch(function () {
                    return scope.chkboxes.userRoles;
                }, function () {
                    if (scope.userRoles === 0) return;
                    checkedUserRoles = [];
                    _.forEach(scope.userRoles, function (item) {
                        if (scope.chkboxes.userRoles[item.id]) {
                            checkedUserRoles.push(item.id);
                        }
                    });
                }, true);
            };

            scope.$watch(function () {
                return checkedRoles;
            }, function (checkedRoles) {
                checkedRoles.length == 1 ? scope.canSelect = true : scope.canSelect = false;
                if (checkedRoles.length > 1) {
                    scope.showSelectTip = true;
                    scope.selectips = $translate.instant("aws.users.oneUser_oneRole");
                } else {
                    scope.showSelectTip = false;
                    scope.selectips = "";
                }
            }, true);

            scope.selectRolesToUser = function () {
                scope.roles = scope.roles.concat(scope.userRoles);
                scope.userRoles = [];
                _.forEach(checkedRoles, function (id) {
                    _.remove(scope.roles, function (item) {
                        if (item.id == id) {
                            scope.userRoles.push(item);
                            scope.chkboxes.userRoles = false;
                        }
                        return item.id == id;
                    });
                });
                scope.init_Privileges_table();
                scope.init_userPrivs_table();
            };
            scope.removeRolesFromUser = function () {
                _.forEach(checkedUserRoles, function (id) {
                    _.remove(scope.userRoles, function (item) {
                        if (item.id == id) {
                            var roles = scope.roles;
                            roles.push(item);
                            scope.checkboxes.roles = false;
                        }
                        return item.id == id;
                    });
                });
            };
            return userModal.result.then(function () {
                var put_ids = [];
                _.forEach(scope.userRoles, function (item) {
                    put_ids.push(item.id);
                });
                var params = {
                    id: editData.id,
                    role_ids: {
                        ids: put_ids
                    }
                };
                if (params.role_ids.ids[0] != initUserRoleId) {
                    userDataSrv.putUserRoles(params).then(function () {
                        initUserTable();
                    });
                }
            });
        });
    };

    //修改用户密码
    self.changePass = async function (editData) {
        const res = await accessPolicySrv.getLoginConfig({ parentId: 2 })

        const pwd_config = JSON.parse(res.data.find(item=>item.paramName == 'LOGIN_PASSWORD_RANK').paramValue)

        // "登密码级别，1代表弱，2代表中，3代表强"
        const { passwordrank, lengthmin, lengthmax } = pwd_config

        self.up_pwd_config = {
            reg: null,
            passwordrank,
            lengthmin,
            lengthmax
        };
        if (passwordrank == 1) {
            self.up_pwd_config.reg = /^[\w|\d|!@._:,/\-\^\%]+$/;
        } else if (passwordrank == 2) {
            self.up_pwd_config.reg = /^((?!\D+$)(?![^a-zA-Z]+$))(?![!@._:,/\-\^\%]+$)[\w|\d|!@._:,/\-\^\%]+$/;
        } else if (passwordrank == 3) {
            self.up_pwd_config.reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W!@._:,/\-\^\%]+$)(?![a-z0-9]+$)(?![a-z\W!@._:,/\-\^\%]+$)(?![0-9\W!@._:,/\-\^\%]+$)[a-zA-Z0-9\W!@._:,/\-\^\%]{3,}$/;
        }

        var scope = $scope;
        var userModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "userPassModel.html",
            scope: scope
        });
        scope.userForm = {};
        scope.userPassConfirm = function (formField) {
            if (formField.$valid) {
                const pwd_length = scope.userForm.password.length
                if (pwd_length < lengthmin || pwd_length > lengthmax) {
                    scope.ispwdlengthshow = true
                    scope.n_timer = setTimeout(() => {
                        scope.ispwdlengthshow = false;
                        clearTimeout(scope.n_timer)
                    }, 1000)
                    return;
                }
                var params = {
                    "name": editData.name,
                    "realName": editData.realName, //可以为空
                    "email": editData.email, //可以为空
                    "phone": editData.phone, //可以为空
                    "enabled": editData.enabled,
                    "password": scope.userForm.password,
                    "description": editData.description,
                    "enterpriseUid": localStorage.enterpriseUid,
                    "departmentId": editData.departmentId,
                    "id": editData.id,
                    "userUid": editData.userUid
                };
                userModal.close();
                userDataSrv.editUserAction(params).then(function(res) {
                    // add by wwt on 2021.09.08 同步tcs用户修改密码
                    userDataSrv.updateTcsPass(params).then(itemRes =>{
                        console.log(itemRes);
                        console.log(itemRes);
                    }); 
                    initUserTable();
                });
            } else {
                scope.submitValid = true;
            }
        };
    };
    self.changeOrg = function (editData) {
        var scope = $scope;
        var userOrgModal = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: "allocateOrg.html",
            scope: scope
        });
        scope.userOrgForm = {};
        // function getOrgsFunc() {
        //     userDataSrv.getOrgsData().then(function(res) {
        //         scope.orgs = {};
        //         scope.orgs.options = [];
        //         scope.orgs.options.push({"id":null,"name":"请选择",type:0});
        //         listAllOrg(res.data);
        //         scope.orgs.options.map(item=>{
        //             item.levname = item.name;
        //             for(let i=0;i<item.deptLevel;i++){
        //                 item.levname = "|__"+item.levname;
        //             }
        //         });
        //         _.forEach(scope.orgs.options,function(item){
        //             if(editData.departmentId==item.id){
        //                 scope.userOrgForm.selectedOrg=item;
        //             }
        //         });
        //     });
        // }
        function listAllOrg(arr) {
            _.forEach(arr, function (item) {
                if (item.children.length > 0) {
                    scope.orgs.options.push(item);
                    listAllOrg(item.children);
                } else {
                    scope.orgs.options.push(item);
                }

            });
        }
        // getOrgsFunc();
        scope.userOrgConfirm = function (org, formField) {
            if (formField.$valid) {
                var params = {
                    "name": editData.name,
                    "realName": editData.realName, //可以为空
                    "email": editData.email, //可以为空
                    "phone": editData.phone, //可以为空
                    "enabled": editData.enabled,
                    "password": editData.password,
                    "description": editData.description,
                    "enterpriseUid": localStorage.enterpriseUid,
                    "departmentId": org.selectedOrg.id,
                    "id": editData.id,
                    "userUid": editData.userUid
                };
                userOrgModal.close();
                userDataSrv.editUserAction(params).then(function () {
                    initUserTable();
                });
            } else {
                scope.submitValid = true;
            }
        };
    };
});
userModule.controller("ADUserCtrl", function ($scope, $rootScope, NgTableParams, userDataSrv, checkedSrv, $uibModal, $translate, depviewsrv, GLOBAL_CONFIG, roleDataSrv, instancesSrv, regularSnapSrv, TableCom, $filter, $timeout) {
    var self = $scope;
    self.currentTab = 'bound';
    self.tabClick = function (tab) {
        self.currentTab = tab;
    };
});
userModule.controller("ADUserCtrlBound", function ($scope, $rootScope, NgTableParams, userDataSrv, checkedSrv, $uibModal, $translate, depviewsrv, GLOBAL_CONFIG, roleDataSrv, instancesSrv, regularSnapSrv, TableCom, $filter, $timeout) {
    var self = $scope;
    self.canUntieRoleBtn = false;
    self.roleSelect = { selected: { text: $translate.instant('aws.users.roles.10'), value: 0 } };
    self.roleSelectList = [
        { text: $translate.instant('aws.users.roles.10'), value: 0 },
        { text: $translate.instant('aws.users.roles.2'), value: 2 },
        { text: $translate.instant('aws.users.roles.3'), value: 3 },
        { text: $translate.instant('aws.users.roles.4'), value: 4 },
        { text: $translate.instant('aws.users.roles.5'), value: 5 },
    ];
    self.modalTitle = $translate.instant('aws.users.edit');
    self.search = {};
    self.ADPageSize = 10;
    self.ADCurrentPage = 1;
    self.ADCheckboxes = {
        items: {},
        checked: false
    };
    self.patternMsg = $translate.instant('aws.register.info.special_8_16');
    self.userName = localStorage.userName;
    self.user_managementRole = localStorage.managementRole;
    self.headers = {
        "name": $translate.instant("aws.users.ad.name"),
        "loginName": $translate.instant("aws.users.ad.loginName"),
        "role": $translate.instant("aws.users.ad.role"),
        "position": $translate.instant("aws.users.ad.position"),
        "description": $translate.instant("aws.users.ad.description"),
        "createTime": $translate.instant("aws.users.ad.createTime"),
        "lastLogin": $translate.instant("aws.users.ad.lastLogin"),
        "lastModifyPwd": $translate.instant("aws.users.ad.lastModifyPwd"),
        "enabled": $translate.instant("aws.users.ad.enabled"),
    };

    //列表搜索框右侧设置按钮配置
    self.ADUserTitleName = "ADUserRule";
    // if (sessionStorage["ADUserRule"]) {
    //     self.ADUserTitleData = JSON.parse(sessionStorage["ADUserRule"]);
    // } else {
    self.ADUserTitleData = [
        { name: 'users.ad.name', value: true, disable: true, search: "name" },
        { name: 'users.ad.loginName', value: true, disable: false, search: "managementRoleName" },
        { name: 'users.ad.role', value: true, disable: false, search: "domainName" },
        { name: 'users.ad.position', value: true, disable: false, search: "domainName" },
        { name: 'users.ad.description', value: true, disable: false, search: "email" },
        { name: 'users.ad.createTime', value: true, disable: false, search: "createTime" },
        { name: 'users.ad.lastLogin', value: true, disable: false, search: "status" },
        { name: 'users.ad.lastModifyPwd', value: true, disable: false, search: "operate" },
        { name: 'users.ad.enabled', value: true, disable: false, search: "operate" },
    ]
    // }
    self.userSearchTearm = function (obj) {
        self.globalSearchTerm = "";
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function (item) {
            item.searchTerm = [];
            titleData.forEach(function (value) {
                if (value.value) {
                    item.searchTerm.push(item[value.search]);
                }
            })
            item.searchTerm = item.searchTerm.join('\b');
        })
        // self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: tableData });
    }


    self.ADChangePage = function (options) {
        self.ADCurrentPage = options.pageNum;
        self.ADPageSize = options.pageSize;
        options.pattern = self.search.adTerm;
        initUserTable(options);
    };
    self.applyGlobalSearch = function (term) {
        let searchOption = {
            pattern: term,
        }
        $timeout.cancel(self.sendSearch)
        self.sendSearch = $timeout(() => {
            initUserTable(searchOption)
        }, 1000)

    };

    self.applyGlobalSearch = function (options) {
        self.ADCurrentPage = 1;
        var pageOption = {
            pageSize: self.ADPageSize,
            pageNum: 1,
            pattern: self.search.adTerm ? self.search.adTerm : ""
        };
        if (options.type == 'refresh') {
            self.roleSelect = { selected: { text: $translate.instant('aws.users.roles.10'), value: 0 } };
            self.search.adTerm = "";
            pageOption.pattern = "";
        }
        $timeout.cancel(self.sendSearch)
        self.sendSearch = $timeout(() => {
            initUserTable(pageOption)
        }, 1000)
    };

    //获取user table的数据 初始化userTable
    var initUserTable = function (options) {
        var params = {}, data = [];
        if (localStorage.managementRole == '3') {
            params = {
                domainUid: localStorage.domainUid
            }
        }
        let pageDefaultOption = {
            pattern: "",
            pageNum: 1,
            pageSize: self.ADPageSize
        };
        if (self.roleSelect.selected.value != 0) {
            pageDefaultOption.managementRole = self.roleSelect.selected.value;
        }
        if (!options) {
            self.ADCurrentPage = 1;
        }
        var pageOption = angular.extend(pageDefaultOption, options)
        TableCom.init(self, 'ADTable', [], "name", GLOBAL_CONFIG.PAGESIZE, 'ADCheckboxes');
        userDataSrv.getBoundADUserTableData(pageDefaultOption).then(function (result) {
            self.ADTotalNum = result.total;
            if (result && result.data && result.data.length) {
                // if(self.ADMIN){
                //     data= result.data;
                // }else if(self.DOMAIN_ADMIN){
                //     data = result.data.filter(item.name != "admin");
                // }else if(self.SECURITY){
                //     data = result.data;
                // }
                data = result.data;
                self.init_data(data);
            }
            result ? self.loadData = true : "";
        });
    };
    self.initUserTable = initUserTable;
    initUserTable();
    if (watch) {
        watch();
    }
    var watch = self.$watch(function () {
        return self.ADCheckboxes.items;
    }, function (val) {
        self.canBindRole = false;
        self.adCheckedItems = [];
        var arr = [];
        for (var i in self.ADCheckboxes.items) {
            arr.push(self.ADCheckboxes.items[i]);
        }
        if (val && arr.length >= 0) {
            for (var key in val) {
                if (val[key]) {
                    self.AD_data.forEach(item => {
                        if (item.name == key) {
                            self.adCheckedItems.push(item);
                        }
                    });
                }
            }
        }
        self.canUntieRoleBtn = canUntieRole(self.adCheckedItems);
        if (self.adCheckedItems.length == 1 && [2, 8, 9].indexOf(self.adCheckedItems[0].managementRole) == -1) {
            if ($rootScope.ADMIN) {
                self.canBindRole = true;
            } else {
                if (self.adCheckedItems[0].managementRole == 3) {
                    self.canBindRole = false;
                } else {
                    self.canBindRole = true;
                }
            }
        }
    }, true);

    function canUntieRole(data) {
        if (data.length >= 1) {
            let canUntie = true;
            data.forEach((it, i) => {
                if (!it.managementRole || [2, 8, 9].indexOf(it.managementRole) > -1 || (!$rootScope.ADMIN && it.managementRole == 3)) {
                    canUntie = false;
                    return;
                }
            })
            return canUntie;
        } else {
            return false;
        }
    };

    self.init_data = function (data) {
        self.AD_data = _.map(data, function (item) {
            if (item.enable == true) {
                item.enable_ts = $translate.instant("aws.users.ad.true");
            } else {
                item.enable_ts = $translate.instant("aws.users.ad.false");
            }
            item.managementRole_des = item.managementRole ? $translate.instant("aws.users.roles." + item.managementRole) : "";
            return item;
        });
        var _ADData = angular.copy(self.AD_data);
        TableCom.init(self, 'ADTable', self.AD_data, "name", GLOBAL_CONFIG.PAGESIZE, 'ADCheckboxes');
    };
    //刷新列表操作
    self.refreshUser = function () {
        initUserTable();
    };

    //绑定角色
    self.bindRole = function (editData) {
        if (self.canBindRole) {
            var scope = $scope;
            var userModal = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "bindADRole.html",
                controller: "bindRoleCtrl",
                resolve: {
                    context: self,
                }
            });
        }

    };
    // 解绑角色
    self.untieADRole = function () {
        if (self.canUntieRoleBtn) {
            var content = {
                target: "untieRole-confirm-popup",
                msg: "<span>" + $translate.instant("aws.users.ad.untieConfirm") + "</span>",
                data: self.adCheckedItems
            };
            self.$emit("delete", content);
        }
    };
    self.$on("untieRole-confirm-popup", function (e, adCheckedItems) {
        let ids = [];
        adCheckedItems.forEach(it => {
            ids.push(it.userUid)
        });
        userDataSrv.untieADRole(ids).then(res => {
            initUserTable();
        });
    });
    self.roleSelectChange = function () {
        initUserTable();
    }
});
userModule.controller("ADUserCtrlUnbound", function ($scope, $rootScope, NgTableParams, userDataSrv, checkedSrv, $uibModal, $translate, depviewsrv, GLOBAL_CONFIG, roleDataSrv, instancesSrv, regularSnapSrv, TableCom, $filter, $timeout, alertSrv) {
    var self = $scope;
    self.modalTitle = $translate.instant('aws.users.bindRole');
    self.search = {};
    self.ADPageSize = 10;
    self.ADCurrentPage = 1;
    self.ADCheckboxes = {
        items: {},
        checked: false
    };
    self.patternMsg = $translate.instant('aws.register.info.special_8_16');
    self.userName = localStorage.userName;
    self.user_managementRole = localStorage.managementRole;
    self.headers = {
        "name": $translate.instant("aws.users.ad.name"),
        "loginName": $translate.instant("aws.users.ad.loginName"),
        // "role":$translate.instant("aws.users.ad.role"),
        "position": $translate.instant("aws.users.ad.position"),
        "description": $translate.instant("aws.users.ad.description"),
        "createTime": $translate.instant("aws.users.ad.createTime"),
        "lastLogin": $translate.instant("aws.users.ad.lastLogin"),
        "lastModifyPwd": $translate.instant("aws.users.ad.lastModifyPwd"),
        "enabled": $translate.instant("aws.users.ad.enabled"),
    };

    //列表搜索框右侧设置按钮配置
    self.ADUserTitleName = "ADUserRule";
    // if (sessionStorage["ADUserRule"]) {
    //     self.ADUserTitleData = JSON.parse(sessionStorage["ADUserRule"]);
    // } else {
    self.ADUserTitleData = [
        { name: 'users.ad.name', value: true, disable: true, search: "name" },
        { name: 'users.ad.loginName', value: true, disable: false, search: "managementRoleName" },
        // {name: 'users.ad.role', value: true, disable: false, search: "domainName"},
        { name: 'users.ad.position', value: true, disable: false, search: "domainName" },
        { name: 'users.ad.description', value: true, disable: false, search: "email" },
        { name: 'users.ad.createTime', value: true, disable: false, search: "createTime" },
        { name: 'users.ad.lastLogin', value: true, disable: false, search: "status" },
        { name: 'users.ad.lastModifyPwd', value: true, disable: false, search: "operate" },
        { name: 'users.ad.enabled', value: true, disable: false, search: "operate" },
    ]
    // }
    self.userSearchTearm = function (obj) {
        self.globalSearchTerm = "";
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function (item) {
            item.searchTerm = [];
            titleData.forEach(function (value) {
                if (value.value) {
                    item.searchTerm.push(item[value.search]);
                }
            })
            item.searchTerm = item.searchTerm.join('\b');
        })
        // self.tableParams = new NgTableParams({ count: GLOBAL_CONFIG.PAGESIZE }, { counts: [], dataset: tableData });
    }


    self.ADChangePage = function (options) {
        self.ADCurrentPage = options.pageNum;
        self.ADPageSize = options.pageSize;
        options.pattern = self.search.adTerm;
        initUserTable(options);
    };
    self.applyGlobalSearch = function (term) {
        let searchOption = {
            pattern: term,
        }
        $timeout.cancel(self.sendSearch)
        self.sendSearch = $timeout(() => {
            initUserTable(searchOption)
        }, 1000)

    };

    self.applyGlobalSearch = function (options) {
        self.ADCurrentPage = 1;
        var pageOption = {
            pageSize: self.ADPageSize,
            pageNum: 1,
            pattern: self.search.adTerm ? self.search.adTerm : ""
        };
        if (options.type == 'refresh') {
            self.search.adTerm = "";
            pageOption.pattern = "";
        }
        $timeout.cancel(self.sendSearch)
        self.sendSearch = $timeout(() => {
            initUserTable(pageOption)
        }, 1000)
    };

    //获取user table的数据 初始化userTable
    var initUserTable = function (options) {
        var params = {}, data = [];
        if (localStorage.managementRole == '3') {
            params = {
                domainUid: localStorage.domainUid
            }
        }
        let pageDefaultOption = {
            pattern: "",
            pageNum: 1,
            pageSize: self.ADPageSize
        };
        if (!options) {
            self.ADCurrentPage = 1;
        }
        var pageOption = angular.extend(pageDefaultOption, options)
        TableCom.init(self, 'ADTable', [], "uSNCreated", GLOBAL_CONFIG.PAGESIZE, 'ADCheckboxes');
        userDataSrv.getUnboundADUserTableData(pageDefaultOption).then(function (result) {
            self.ADTotalNum = result.total;
            if (result && result.data && result.data.length) {
                // if(self.ADMIN){
                //     data= result.data;
                // }else if(self.DOMAIN_ADMIN){
                //     data = result.data.filter(item.name != "admin");
                // }else if(self.SECURITY){
                //     data = result.data;
                // }
                data = result.data;
                self.init_data(data);
            }
            result ? self.loadData = true : "";
        });
    };
    self.initUserTable = initUserTable;
    initUserTable();
    if (watch) {
        watch();
    }
    var watch = self.$watch(function () {
        return self.ADCheckboxes.items;
    }, function (val) {
        self.canBindRole = false;
        self.adCheckedItems = [];
        var arr = [];
        for (var i in self.ADCheckboxes.items) {
            arr.push(self.ADCheckboxes.items[i]);
        }
        if (val && arr.length >= 0) {
            for (var key in val) {
                if (val[key]) {
                    self.AD_data.forEach(item => {
                        if (item.uSNCreated == key) {
                            self.adCheckedItems.push(item);
                        }
                    });
                }
            }
        }
        if (self.adCheckedItems.length >= 1) {
            self.canBindRole = canBindRole(self.adCheckedItems);
        }
    }, true);

    function canBindRole(data) {
        var canBind = true;
        for (var i = 0; i < data.length; i++) {
            if (data[i].managementRole) {
                canBind = false;
                break
            }
        }
        return canBind
    }

    self.init_data = function (data) {
        self.AD_data = _.map(data, function (item) {
            if (item.enable == true) {
                item.enable_ts = $translate.instant("aws.users.ad.true");
            } else {
                item.enable_ts = $translate.instant("aws.users.ad.false");
            }
            item.managementRole_des = item.managementRole ? $translate.instant("aws.users.roles." + item.managementRole) : "";
            return item;
        });
        var _ADData = angular.copy(self.AD_data);
        TableCom.init(self, 'ADTable', self.AD_data, "uSNCreated", GLOBAL_CONFIG.PAGESIZE, 'ADCheckboxes');
    };
    //刷新列表操作
    self.refreshUser = function () {
        initUserTable();
    };

    //绑定角色
    self.bindRole = function (editData) {
        if (self.canBindRole) {
            var scope = $scope;
            var userModal = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "bindADRole.html",
                controller: "bindRoleCtrl",
                resolve: {
                    context: self,
                }
            });
        }

    };
    // 同步
    self.adSync = function () {
        var content = {
            target: "adSync-confirm-popup",
            msg: "<span>" + $translate.instant("aws.users.syncTip") + "</span>",
            data: self.adCheckedItems
        };
        self.$emit("delete", content);
    };
    self.$on("adSync-confirm-popup", function (e, adCheckedItems) {
        alertSrv.set("", $translate.instant("aws.users.syncTip1"), "building", 5000);
        self.search.adTerm = "";
        userDataSrv.adSync().then(res => {

        });
    });
    self.$on("syncAdUserSuccess", function (e, data) {
        alertSrv.set("", $translate.instant("aws.users.syncTip2"), "success", 5000);
        initUserTable();
    });
});
userModule.controller("bindRoleCtrl", function ($scope, $rootScope, NgTableParams, userDataSrv, checkedSrv, $uibModal, $translate, depviewsrv, GLOBAL_CONFIG, roleDataSrv, instancesSrv, regularSnapSrv, TableCom, $filter, $timeout, context, $location, $uibModalInstance) {
    var scope = $scope;
    var allPrivileges;
    scope.modalTitle = context.modalTitle;
    scope.submitted = false;//表单校验
    scope.userForm = {
        selectedPrivilege: "",
        selectedDepartment: "",
        selectedProject: "",
        selectedRole: "",
        name: "",
    };

    //获取所有的权限
    function getAllPrivilegesFunc() {
        roleDataSrv.getRoledata().then(function (res) {
            if (res && res.data && res.data.list) {
                allPrivileges = res.data.list;
            }
        }).finally(function () {
            getRolesFunc()
        })
    };
    getAllPrivilegesFunc();

    //切换部门
    scope.changeDpm = function (item, type) {
        var params = { domainUid: item ? item.domainUid : "" };
        userDataSrv.getProjectData(params).then(function (res) {
            if (res && res.data && res.data.length != 0) {
                scope.projectCheck = false;
                res.data.forEach(function (item) {
                    if (item.domainUid == 'default' && item.name == "admin") {
                        item.name = $translate.instant("aws.common.defaultProject");
                    }
                })
            }
            scope.projects = {
                options: res.data,
                selected: res.data[0] ? res.data[0] : ""
            };
            scope.userForm.selectedProject = scope.projects.selected;
            if (type == 'editAssign' && scope.modalTitle == $translate.instant('aws.users.edit')) {
                scope.projects.options.forEach(it => {
                    if (it.projectUid == context.adCheckedItems[0].defaultProjectUid) {
                        scope.userForm.selectedProject = it;
                    }
                });
            }
        });
    };

    //表单校验
    scope.interacted = function (field) {
        return scope.submitted || field.$dirty;
    };

    //获取部门、项目;项目与部门联动，项目随部门的变化而改变
    var getDepartmentsFunc = function () {
        //获取部门
        if ($rootScope.ADMIN || $rootScope.SECURITY) {
            userDataSrv.getDepartmentData().then(function (res) {
                if (res && res.data) {
                    res.data.forEach(function (item) {
                        if (item.domainUid == 'default') {
                            item.name = $translate.instant("aws.common.defaultDepar");
                        }
                    })
                    scope.dpms = {
                        options: res.data,
                        selected: res.data[0] ? res.data[0] : ""
                    };
                }
                scope.userForm.selectedDepartment = scope.dpms.selected;
                // 编辑--部门回显
                if (scope.modalTitle == $translate.instant('aws.users.edit')) {
                    scope.dpms.options.forEach(it => {
                        if (it.domainUid == context.adCheckedItems[0].domainUid) {
                            scope.userForm.selectedDepartment = it;
                        }
                    });
                }
                return scope.userForm.selectedDepartment;
            }).then(function (item) {
                //根据部门获取相应的项目和权限
                scope.changeDpm(item, 'editAssign');
            });
        } else if ($rootScope.DOMAIN_ADMIN) {
            var curDomain = [{ name: localStorage.domainUid == 'default' ? $translate.instant("aws.common.defaultDepar") : localStorage.domainName, domainUid: localStorage.domainUid }];
            scope.dpms = {
                options: curDomain,
                selected: curDomain[0]
            };
            scope.domain_dis = true;
            scope.userForm.selectedDepartment = scope.dpms.selected;
            scope.changeDpm(curDomain[0], 'editAssign');
        }

    };

    //获取角色下拉列表
    var getRolesFunc = function () {
        userDataSrv.getRolesDataNew().then(function (res) {
            // var roles = res.data.filter(function(item){
            //     if(localStorage.permission == "enterprise"){
            //         if($rootScope.ADMIN || $rootScope.SECURITY){
            //             return (item.name != "service" && item.name != "guest" && item.name != "admin");
            //         }else{
            //             return (item.name != "service" && item.name != "guest" && item.name != "admin" && item.name != "domain_admin");
            //         }
            //     }else if(localStorage.permission == "stand"){
            //          return item.name == "member";
            //     }

            // });
            if (res && res.data) {
                var roles = res.data;
                roles.map(function (item) {
                    if (item.name == '部门管理员') {
                        item.name = $translate.instant("aws.users.roles.3");
                    }
                    else if (item.name == '项目管理员') {
                        item.name = $translate.instant("aws.users.roles.4");
                    }
                    else if (item.name == '普通用户') {
                        item.name = $translate.instant("aws.users.roles.5");
                    }

                    item.roleName = item.name;
                    // item.name = $translate.instant("aws.users.cu.roles."+item.name);
                });
                scope.roles = {
                    options: roles,
                    selected: roles[0] ? roles[0] : ""
                };
                scope.userForm.selectedRole = scope.roles.selected;
                if (localStorage.permission == "stand") {
                    scope.userForm.selectedRole = roles[roles.length - 1];
                }
                // 编辑--角色回显
                if (scope.modalTitle == $translate.instant('aws.users.edit')) {
                    scope.roles.options.forEach(it => {
                        if (Number(it.managementRole) == context.adCheckedItems[0].managementRole) {
                            scope.userForm.selectedRole = it;
                        }
                    });
                }
                scope.changeRoleFun(scope.userForm.selectedRole)
            }

        });
    };

    getDepartmentsFunc();

    //确定函数
    scope.userConfirm = function (data, formField) {
        if (formField.$valid) {
            var params = [];
            context.adCheckedItems.forEach(function (item) {
                var options = {
                    "sAMAccountName": item.sAMAccountName,
                    "name": item.name,
                    "email": item.mail,
                    "phone": item.mobile,
                    "enabled": item.enable,
                    "enterpriseUid": localStorage.enterpriseUid,
                    "domainUid": data.selectedDepartment.domainUid, //部门
                    "privilegeId": scope.privilegesItems,  //data.selectedPrivilege.id, //权限
                    "defaultProjectUid": data.selectedProject.projectUid, //项目
                    "managementRole": data.selectedRole.managementRole,
                    "cn": item.cn,
                    "id": item.id,
                    "userUid": item.userUid,
                    "displayName": item.displayName
                };
                params.push(options);

            })

            userDataSrv.bindADRole(params).then(function () {
                /*当在当前的页面时刷新列表*/
                if ($location.path() == '/permit/user') {
                    context.initUserTable();
                }
                if ($location.path() == '/permit/overview') {
                    context.$route.reload();
                }
            });
            $uibModalInstance.close();
        } else {
            scope.submitValid = true;
        }
    };
    //切换角色
    scope.changeRoleFun = function (item) {
        for (var i = 0; i < allPrivileges.length; i++) {
            if (item.id == allPrivileges[i].id) {
                scope.privilegesItems = allPrivileges[i].id;
            }
        }
    };

});
