angular.module("securityTemplateSrvModule", [])
    .service("securityTemplateSrv", ["$http",function($http){
        const static_url = '/awstack-manage/v2/template/security-groups';
        return {
            getTemplateList:function(data){
                return $http({
                    method:"GET",
                    url:static_url,
                    params:data
                })
            },
            getGroupList:function(){
                return $http({
                    method:"GET",
                    url:'/awstack-manage/v2/security-groups'
                })
            },
            createTemplate: function(options) {
                return $http({
                    method: "POST",
                    url: static_url,
                    data: options
                });
            },
            editTemplate: function(options) {
                return $http({
                    method: "PUT",
                    url: static_url,
                    data: options
                });
            },
            deleteTemplate: function(options) {
                return $http({
                    method: "DELETE",
                    url: static_url,
                    params: options
                });
            },
            getTemplateRuleDetail:function(data,id){
                return $http({
                    method:"GET",
                    url: static_url+`/${id}?pageSize=${data.pageSize}&pageNum=${data.pageNum}&pattern=${data.likes||""}&direction=${data.direction}`
                })
            },
            createTemplateRule: function(options) {
                return $http({
                    method: "POST",
                    url: '/awstack-manage/v2/template/security-group/rules',
                    data: options
                });
            },
            editTemplateRule: function(options) {
                return $http({
                    method: "PUT",
                    url: '/awstack-manage/v2/template/security-group/rules',
                    data: options
                });
            },
            deleteTemplateRule: function(options) {
                return $http({
                    method: "DELETE",
                    url: '/awstack-manage/v2/template/security-group/rules',
                    params: options
                });
            },
        };
    }]);