detailConfigInfoTpl.$inject = [];
function detailConfigInfoTpl () {
    return {
        restrict:"E",
        replace:true,
        scope:{
            insUid: "=",
            fromCloudDatabase: "="
        },
        templateUrl: "js/cvm/instances/tmpl/detail-config-info-tpl.html",
        link:function(self, elem, attrs, $ngModel) {

        },
        controller: function($routeParams, $rootScope, $scope, $translate, $location, NgTableParams, flavorsSrv, commonFuncSrv, vmFuncSrv, instancesSrv,vmhostSrv,userDataSrv,TableCom) {
            var self = $scope
            self.L3 = $rootScope.L3;
            self.ADMIN = $rootScope.ADMIN;
            $scope.insConfigDatas = { status: "empty", cpuConfigData: 0, ramConfigData: 0 };
            $scope.fixedIpPool=[];
            $scope.floatingIpPool=[];
            $scope.fixedIpList = [];
            $scope.floatIpList = [];
            function getServerDetail(insUid){
                instancesSrv.getServerDetail(insUid).then(function(result) {
                    $scope.insConfigDatas = { status: "empty", cpuConfigData: 0, ramConfigData: 0 };
                    let v4FixedArr=[];
                    let v6FixedArr=[];
                    $scope.devicesType = "";
                    if (result && result.data) {
                        if($routeParams.id!=insUid){return;}
                        /*让私网ip和公网ip关系对应*/
                        var ipData = [];
                        var floatingIps=[];
                        var netA = result.data.netAddresses;
                        //$scope.fixedIpList = result.data.fixedIps;
                        //$scope.floatIpList = result.data.floatingIps;
                        $scope.fixedIpList = result.data.fixedIps.map(item=>{
                            let obj = {};
                            if(item.indexOf(".")>-1){
                                obj.addr = item+"(ipv4)"
                            }else{
                                obj.addr = item+"(ipv6)"
                            }
                            return obj
                        })
                        $scope.floatIpList = result.data.floatingIps.map(item=>{
                            let obj = {};
                            if(item.indexOf(".")>-1){
                                obj.addr = item+"(ipv4)"
                            }else{
                                obj.addr = item+"(ipv6)"
                            }
                            return obj
                        })
                        
                        if(result.data.passthroughDevices.length>0){
                            if( result.data.passthroughDevices){
                                var res = result.data.passthroughDevices.some(function(item){
                                    return JSON.stringify(item).indexOf("USB")>-1
                                })
                                var res1 = result.data.passthroughDevices.some(function(item){
                                    return JSON.stringify(item).indexOf("PCI")>-1
                                })
                                var res2 = result.data.passthroughDevices.some(function(item){
                                    return JSON.stringify(item).indexOf("gpu")>-1
                                })
                                var res3 = result.data.passthroughDevices.some(function(item){
                                    return JSON.stringify(item).indexOf("fpga")>-1
                                })
                                // if(res&&!res1){
                                //     $scope.devicesType = "USB"
                                // }else if(!res&&res1){
                                //     $scope.devicesType = "PCI"
                                // }else if(res&&res1){
                                //     $scope.devicesType = "USB/PCI"
                                // }else{
                                //     $scope.devicesType = "暂无"
                                // }
                                var arr=[];
                                if(res){
                                    arr.push("USB")
                                }
                                if(res1){
                                    arr.push("PCI")
                                }
                                if(res2){
                                    arr.push("GPU")
                                }
                                if(res3){
                                    arr.push("FPGA")
                                }
                                for(var i=0;i<arr.length;i++){
                                    if(i<arr.length-1){
                                        $scope.devicesType=$scope.devicesType+(arr[i]+"/")
                                    }else{
                                        $scope.devicesType=$scope.devicesType+arr[i]
                                    }
                                }
                                if(!res&&!res1&&!res2&&!res3){
                                    $scope.devicesType=$translate.instant('aws.instances.notAvailable');
                                }
                            }
                        }else{
                            $scope.devicesType = $translate.instant('aws.instances.notAvailable');
                        }
                        
                        if(netA.length){
                            for(var i=0;i<netA.length;i++){
                                ipData = ipData.concat(netA[i].novaAddressList)
                            }
                        }
                        //
                        // $scope.fixedIpPool = ipData.filter(item=>{
                        //     return item['OS-EXT-IPS:type']=='fixed'
                        // });
                        //过滤出私网ip
                        let fixedIpArr=ipData.filter(item=>{
                            return item['OS-EXT-IPS:type']=='fixed'
                        });
                        fixedIpArr.forEach(function(item){
                            if(item['version']==4){
                               item.addr=item.addr+"(ipv4)";
                               v4FixedArr.push(item); 
                            }else if(item['version']==6){
                               item.addr=item.addr+"(ipv6)";
                               v6FixedArr.push(item);
                            }
                        });
                        v4FixedArr.forEach(function(v4Item){
                            v6FixedArr.forEach(function(v6Item){
                                if(v4Item['OS-EXT-IPS-MAC:mac_addr']==v6Item['OS-EXT-IPS-MAC:mac_addr']){
                                    v4Item.addr=v4Item.addr+"</br>"+v6Item.addr;
                                }
                            });
                        });
                        $scope.fixedIpPool=v4FixedArr.concat(v6FixedArr);
                        floatingIps = ipData.filter(item=>{
                            return item['OS-EXT-IPS:type']=='floating'
                        });
                        $scope.fixedIpPool.forEach(function(item,i){
                            item.addr = $translate.instant('aws.instances.networkCard') + ":"+item.addr;
                            var floatIp=floatingIps.filter(floatIp=>{
                                return floatIp['OS-EXT-IPS-MAC:mac_addr']==item['OS-EXT-IPS-MAC:mac_addr']
                            });
                            if(floatIp.length==1){
                                floatIp[0].addr = $translate.instant('aws.instances.publicNetwork') + ":"+floatIp[0].addr;
                            }else if(floatIp.length==0){
                                var obj={
                                    'addr': $translate.instant('aws.instances.networkCard') + ":"+$translate.instant("aws.instances.none")
                                }
                                floatIp.push(obj);
                            }
                            $scope.floatingIpPool=$scope.floatingIpPool.concat(floatIp);
                        })
                        
                        result.data.diskInfo.map( item=> {
                            // root_device 不存在时是本地磁盘 否则root_device为"true"是系统盘,为"false"是数据盘
                            if(item.root_device) {
                                if(item.bootable == "true" && item.root_device == "true" && item.diskFormat !="iso" ) {
                                    item.isbootable = $translate.instant("aws.common.systemDisk");
                                }else {
                                    item.isbootable = $translate.instant("aws.common.dataDisk");
                                }
                            }
                        } )
        
                        result.data.status = result.data.status.toLowerCase();
                        result.data.statusTr = $translate.instant('aws.instances.table.status.'+result.data.status);
                        
                        // result.data.diskNewName = "";
                        // for (var i = 0; i < result.data.diskInfo.length; i++) {
                        //     if (i != result.data.diskInfo.length - 1) {
                        //         result.data.diskNewName = result.data.diskNewName + result.data.diskInfo[i].name +"(" +result.data.diskInfo[i].type + ")" + "," + " ";
                        //     } else {
                        //         result.data.diskNewName = result.data.diskNewName + result.data.diskInfo[i].name +"(" +result.data.diskInfo[i].type + ")";
                        //     }
                        // }
                        $scope.insConfigDatas = result.data;
                        // 本地磁盘添加磁盘类型
                        $scope.insConfigDatas.localDiskInfo.forEach(item=>{
                            item.isbootable = $translate.instant("aws.common.localDisk"); 
                        })
                        // 将系统盘、数据盘与本地磁盘数据拼接成一个数组
                        let insConfigDatasList = $scope.insConfigDatas.diskInfo.concat($scope.insConfigDatas.localDiskInfo);

                        //多了以后页面用滚动实现
                        TableCom.init(self,'diskInfoTable',insConfigDatasList,'id',100,'insDiskinfoCheckbox');
                        var ram = 0;
                        $scope.insConfigDatas.cpuConfigData = Number(result.data.vcpus);
                        ram = Number(result.data.ram/1024);
                        $scope.insConfigDatas.ramConfigData = Math.ceil(ram) == ram?ram:ram.toFixed(1);
                        
                        //获取云主机网卡对应的带宽
                        $scope.insConfigDatas.nic_bandwidth = [];
                        for(let key in result.data.bandwidth){
                            if(key.indexOf("quota:vif_inbound_average") > -1){
                                delete result.data.bandwidth[key];
                            }
                        }
                        instancesSrv.getServerNetwork(insUid).then(function(res) {
                            if (res && res.data && res.data.length) {
                                let allNic_ip = [];
                                let existBand_nicIp = [];
                                _.each(res.data,function(item){
                                    allNic_ip.push(item.fixed_ips[0].ip_address);
                                    for(let key in result.data.bandwidth){
                                        let nic_bandwidth = Number(result.data.bandwidth[key])/128;
                                        if(key.indexOf(item.mac_addr) > -1){
                                            existBand_nicIp.push(item.fixed_ips[0].ip_address);
                                            if(nic_bandwidth == 0){
                                                $scope.insConfigDatas.nic_bandwidth.push(item.fixed_ips[0].ip_address + " : " + $translate.instant("aws.networks.noLimit"));
                                            }else{
                                                $scope.insConfigDatas.nic_bandwidth.push(item.fixed_ips[0].ip_address + " : " + Number(result.data.bandwidth[key])/128 + "  Mbps " );
                                            }
                                        }
                                    }
                                })
                                _.each(_.difference(allNic_ip,existBand_nicIp),function(nic_ip){
                                     $scope.insConfigDatas.nic_bandwidth.push(nic_ip + " : " + $translate.instant("aws.networks.noLimit"));
                                })
                                $scope.insConfigDatas.nic_bandwidth = _.uniq($scope.insConfigDatas.nic_bandwidth);
                            }
                        });
                    }
                });
            }
            getServerDetail(self.insUid);
        }
    }
}

export { detailConfigInfoTpl };