import * as loginlogSrv from "../services";

function loginlogCtrl($scope, Srv, $translate, $http, $location) {
    var self = $scope;
    self.titleName="loginlogs";
    if(sessionStorage["loginlogs"]){
        self.titleData=JSON.parse(sessionStorage["loginlogs"]);
    }else{
        self.titleData=[
            {name:'loginlogs.username',value:true,disable:true,search:"ticketNumber"},
            {name:'loginlogs.ip',value:true,disable:false,search:"serverName"},
            {name:'loginlogs.mac',value:true,disable:false,search:"serverName"},
            {name:'loginlogs.loginresult',value:true,disable:true,search:"title"},
            {name:'loginlogs.loginintime',value:true,disable:false,search:"createTime"},
            {name:'loginlogs.loginouttime',value:true,disable:false,search:"endTime"},
            {name:'loginlogs.logintime',value:true,disable:false,search:"endTime"},
            {name:'loginlogs.loginoutway',value:true,disable:false,search:"_status"}
        ];
    }
    self.loginlogsSearchTearm = loginlogsSearchTearm;
    function loginlogsSearchTearm(obj) {
        var tableData = obj.tableData;
        var titleData = obj.titleData;
    }
    self.keywordList = [
        {name: $translate.instant("aws.loginlogs.username"), value: "0"},
        {name: $translate.instant("aws.loginlogs.ip"), value: "1"},
        {name: $translate.instant("aws.loginlogs.mac"), value: "2"}
    ]
    self.statusList = [
        {name: $translate.instant("aws.loginlogs.allstatus"), value: ""},
        {name: $translate.instant("aws.loginlogs.success"), value: "1"},
        {name: $translate.instant("aws.loginlogs.fail"), value: "2"}
    ];
    self.statusConfig = {
        "status" : self.statusList[0]
    }

    function init_dateTimepicker() {
        $(".form_date").datetimepicker({
            language: "zh-CN",
            weekStart: 1,
            todayBtn: 1,
            autoclose: 1,
            todayHighlight: 1,
            //startView: 2,
            minView: "month",
            //minuteStep:5,
            forceParse: 0,
            format: "yyyy-mm-dd",
            pickerPosition: "bottom-left"
        });
    }
    init_dateTimepicker();
    function initFilterForm() {
        return self.filterData = {
            pageNum: "1",
            pageSize: "10",
            keyWord: "",
            from: "",
            to: "",
            remoteIp: "",
            logLevel: ""
        };
    }
    initFilterForm();
    saveCheckConfig();

    self.statusChange = function (state) {
        self.filterData.state = state;
        self.queryFun("form");
        // self.filterData = {
        //     pageNum: "1",
        //     pageSize: "10",
        //     keyWord: "",
        //     from: "",
        //     to: "",
        //     remoteIp: "",
        //     logLevel: "",
        //     state: state
        // };
        // saveCheckConfig();
        // initLogsTable(formatPerams(self.filterData));
    }
    //格式化时间格式
    function formatPerams(filterData) {
        var permas = angular.copy(filterData);
        if (filterData.from) {
            permas.from = permas.from + " 00:00:00";
        }
        if (filterData.to) {
            permas.to = permas.to + " 00:00:00";
        }
        return permas;
    }

    function init_data(result) {
        self.logsTable = _.map(result.data, function(item) {
            var itemResCode = item.responseCode.split("#"); //多数据中心responseCode
            if (itemResCode.length > 1) {
                itemResCode = itemResCode.slice(1, itemResCode.length - 1)
            }
            item.state = itemResCode;
            //item.state = $translate.instant("aws.statusCode." + item.responseCode);
            item.createTime = new Date(item.createTime);
            return item;
        });
        var e = $("#pageNum")[0];
        if (result.total && self.filterData.pageSize) {
            self.pages = Math.ceil(result.total / self.filterData.pageSize);
            self.showPage = true;
            setPage(e, self.pages, self.filterData.pageNum);
        } else {
            self.showPage = false;
        }
    }

    function setPage(container, count, pageindex) { //总页数少于10 全部显示,大于10 显示前3 后3 中间3 其余....
        var a = [];

        if (pageindex == 1) {
            a[a.length] = "<li><a href=\"#\" class=\"prev unclick\"><i class=\"icon-aw-angle-double-left\"></i></a></li>";
        } else {
            a[a.length] = "<li><a href=\"#\" class=\"prev\"><i class=\"icon-aw-angle-double-left\"></i></a></li>";
        }

        function setPageList() {
            if (pageindex == i) {
                a[a.length] = "<li><a href=\"#\" class=\"on\">" + i + "</a></li>";
            } else {
                a[a.length] = "<li><a href=\"#\">" + i + "</a></li>";
            }
        }

        if (count <= 10) { //总页数小于10
            for (var i = 1; i <= count; i++) {
                setPageList();
            }
        } else { //总页数大于10页
            if (pageindex <= 4) {
                for (var i = 1; i <= 5; i++) {
                    setPageList();
                }
                a[a.length] = "<li><span>...</span></li><li><a href=\"#\">" + count + "</a></li>";
            } else if (pageindex >= count - 3) {
                a[a.length] = "<li><a href=\"#\">1</a></li><li><span>...</span></li>";
                for (var i = count - 4; i <= count; i++) {
                    setPageList();
                }
            } else { //当前页在中间部分
                a[a.length] = "<li><a href=\"#\">1</a></li><li><span>...</span></li>";
                for (var i = pageindex - 2; i <= pageindex + 2; i++) {
                    setPageList();
                }
                a[a.length] = "<li><span>...</span></li><li><a href=\"#\">" + count + "</a></li>";
            }
        }
        if (pageindex == count) {
            a[a.length] = "<li><a href=\"#\" class=\"next unclick\"><i class=\"icon-aw-angle-double-right\"></i></a></li>";
        } else {
            a[a.length] = "<li><a href=\"#\" class=\"next\"><i class=\"icon-aw-angle-double-right\"></i></a></li>";
        }
        container.innerHTML = a.join("");

        (function pageClick() { //事件点击
            var oAlink = container.getElementsByTagName("a");
            var inx = pageindex; //初始的页码
            var clickPageFunc = function(inx) {
                self.filterData.pageNum = inx;
                setPage(container, count, inx);
                self.clickPageToquery = function() {
                    var formData = JSON.parse(sessionStorage.logginSerchConfig);
                    var params = angular.copy(self.filterData);
                    params.keywordtype = formData.keywordtype;
                    params.keyWord = formData.keyWord;
                    params.from = formData.from;
                    params.to = formData.to;
                    initLogsTable(formatPerams(params));
                }();
            };
            oAlink[0].onclick = function() { //点击上一页
                if (inx == 1) {
                    return false;
                }
                inx--;
                clickPageFunc(inx);
                return false;
            };
            for (var i = 1; i < oAlink.length - 1; i++) { //点击页码
                oAlink[i].onclick = function() {
                    inx = parseInt(this.innerHTML);
                    clickPageFunc(inx);
                    return false;
                };
            }
            oAlink[oAlink.length - 1].onclick = function() { //点击下一页
                if (inx == count) {
                    return false;
                }
                inx++;
                clickPageFunc(inx);
                return false;
            };
        })();
    }

    function initLogsTable(params) {
        self.loadData = false;
        self.logsTable = [];
        var getlogsData = function() {
            if(localStorage.managementRole == '8' || localStorage.managementRole == '9'){
                Srv.getloginlogDataNew(params).then(function(result) {
                    result ? self.loadData = true : false;
                    if (result && result.data) {
                        result.data.forEach(element => {
                            element.timeOnline_ori = formatSeconds(element.timeOnline);
                            switch (element.logoutType) {
                                case 0:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.activelyExit');
                                    break;
                                case 1:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.sessionExpire');
                                    break;
                                case 2:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.userIsLocked');
                                    break;
                                case 3:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.singleTerminalLogin');
                                    break;
                                case 4:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.browserClose');
                                    break;
                                default: 
                                    element.logoutType_ori = "";
                                    break;
                            }
                        });
                        init_data(result);
                    }
                });
            }else{
                Srv.getloginlogData(params).then(function(result) {
                    result ? self.loadData = true : false;
                    if (result && result.data) {
                        result.data.forEach(element => {
                            element.timeOnline_ori = formatSeconds(element.timeOnline);
                            switch (element.logoutType) {
                                case 0:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.activelyExit');
                                    break;
                                case 1:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.sessionExpire');
                                    break;
                                case 2:
                                    element.logoutType_ori =  $translate.instant('aws.loginlogs.userIsLocked');
                                    break;
                                case 3:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.singleTerminalLogin');
                                    break;
                                case 4:
                                    element.logoutType_ori = $translate.instant('aws.loginlogs.browserClose');
                                    break;
                                default: 
                                    element.logoutType_ori = "";
                                    break;
                            }
                        });
                        init_data(result);
                    }
                });
            }
            
        };
            getlogsData();
        
    }
    initLogsTable(formatPerams(self.filterData));
    function formatSeconds(value) {
        if (!value) {
            return "";
        }
        var secondTime = parseInt(value);// 秒
        var minuteTime = 0;// 分
        var hourTime = 0;// 小时
        if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
            minuteTime = parseInt(secondTime / 60);
            //获取秒数，秒数取佘，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            //如果分钟大于60，将分钟转换成小时
            if(minuteTime > 60) {
                //获取小时，获取分钟除以60，得到整数小时
                hourTime = parseInt(minuteTime / 60);
                //获取小时后取佘的分，获取分钟除以60取佘的分
                minuteTime = parseInt(minuteTime % 60);
            }
        }
        var result = "" + parseInt(secondTime) + $translate.instant('aws.loginlogs.second');

        if(minuteTime > 0) {
            result = "" + parseInt(minuteTime) + $translate.instant('aws.loginlogs.minute') + result;
        }
        if(hourTime > 0) {
            result = "" + parseInt(hourTime) + $translate.instant('aws.loginlogs.hour') + result;
        }
        return result;
    }

    function saveCheckConfig(){
        var logginSerchConfig = {
            "keywordtype": self.filterData.keywordtype,
            "keyWord": self.filterData.keyWord,
            "from": self.filterData.from,
            "to": self.filterData.to
        }
        if (self.filterData.timeOrder) {
            logginSerchConfig.timeOrder = self.filterData.timeOrder;
        }
        if (self.filterData.timeoutOrder) {
            logginSerchConfig.timeoutOrder = self.filterData.timeoutOrder;
        }
        sessionStorage.logginSerchConfig = JSON.stringify(logginSerchConfig);
    }

    self.queryFun = function(type) {
        if (type == "refresh") {
            self.statusConfig.status = self.statusList[0];
            initFilterForm();
        }
        if (type == "form") {
             if (!self.logFilter_form.$valid) {
                 return ;
             }
             self.filterData.state = self.statusConfig.status.value;
            self.filterData.pageNum = "1";
        }
        saveCheckConfig();
        initLogsTable(formatPerams(self.filterData));
    };

    self.goToPage = function(num) {
        self.filterData.pageNum = num;
        initLogsTable(formatPerams(self.filterData));
    };
    self.sortBy = function (ordername, order) {
        self.filterData.pageNum = 1;
        if (ordername == "login") {
            if (order == "1") {
                self.filterData.timeOrder = "1";
            } else if (order == "0") {
                self.filterData.timeOrder = "0";
            }
            delete self.filterData.timeoutOrder;
        } else if (ordername == "loginout") {
            if (order == "1") {
                self.filterData.timeoutOrder = "1";
            } else if (order == "0") {
                self.filterData.timeoutOrder = "0";
            }
            delete self.filterData.timeOrder;
        }
        initLogsTable(formatPerams(self.filterData));
    }

    self.exportlogs = function(filterData, page) {
        var urlPermas = angular.copy(filterData);
        if (page == "all") {
            urlPermas.pageSize = "10000";
        }
        $("#export" + page).removeAttr("href");
        /*$("#export"+page).attr("href",GLOBALCONFIG.APIHOST.LOG + "/v1/enterprises/"+ localStorage.enterpriseUid + "/logs/sqls/export?"
            +"pageNum="+urlPermas.pageNum+"&"
            +"pageSize="+urlPermas.pageSize+"&"
            +"from="+urlPermas.from+"&"
            +"to="+urlPermas.to+"&"
            +"keyWord="+encodeURI(urlPermas.keyWord)); */

        $http({
            method: "POST",
            url: GLOBALCONFIG.APIHOST.LOG + "/v1/enterprises/" + localStorage.enterpriseUid + "/logs/export/url",
            data:{
                url:GLOBALCONFIG.APIHOST.LOG + "/v1/enterprises/"+ localStorage.enterpriseUid + "/logs/sqls/export?"
                    +"pageNum="+urlPermas.pageNum+"&"
                    +"pageSize="+urlPermas.pageSize+"&"
                    +"from="+urlPermas.from+"&"
                    +"to="+urlPermas.to+"&"
                    +"keyWord="+encodeURI(urlPermas.keyWord?urlPermas.keyWord:"")+"&"
					+"keywordtype="+encodeURI(urlPermas.keywordtype)+"&"
                    +"type=login&"
                    +"remoteIp="+urlPermas.remoteIp+"&"
                    +"logLevel="+encodeURI(urlPermas.logLevel)+"&"
                    +"state="+urlPermas.state+"&"
                    +"responseCode="
            }
        }).then(function(res) {
            if (res.data) {
                var link = document.createElement('a');
                link.setAttribute('href', res.data);
                var event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                link.dispatchEvent(event);
            }
        })

    };
}

export { loginlogCtrl }
loginlogCtrl.$inject = ["$scope", "loginlogSrv", "$translate", "$http", "$location"];