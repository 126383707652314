import "./securityTemplateSrv";

angular.module("securityTemplateModule", ["securityTemplateSrvModule"])
    .controller("securityTemplateCtrl", ["$scope", "NgTableParams", "securityTemplateSrv","$translate","utilTool","$timeout","$location","$uibModal","$routeParams","checkedSrv","alertSrv",
        function($scope,NgTableParams,securityTemplateSrv,$translate,utilTool,$timeout,$location,$uibModal,$routeParams,checkedSrv,alertSrv) {
            var self = $scope;
            self.detailSearchGroup={};
            self.activeTab = 0;
            self.detailTab = 'ingress';
            self.securityTemplate_chkboxs = {
                checked: false,
                items: {}
            };
            self.detailCheckboxes = {
                checked: false,
                items: {}
            };
            self.canEdit = false;
            self.canDel = false;

            self.detailCanEdit = false;
            self.detailCanDel = false;

            self.pageSize = 10;
            self.pageNum = 1;
            self.globalSearchTerm = '';

            self.rulePageSize = 10;
            self.rulePageNum = 1;

            self.headers = {
                "templateName": $translate.instant("aws.security.templateName"),
                "securityGroupType": $translate.instant("aws.security.securityGroupType"),
                "description": $translate.instant("aws.security.description"),
                "inputType": $translate.instant("aws.security.inputType"),
                "ip_protocol": $translate.instant("aws.security.ip_protocol"),
                "authorizationStrategy": $translate.instant("aws.security.authorizationStrategy"),
                "portRange": $translate.instant("aws.security.portRange"),
                "typeCodeValue": $translate.instant("aws.security.typeCodeValue"),
                "sourceType": $translate.instant("aws.security.sourceType")
            };

            //安全组模板列表
            let initTemplateTable = function(options,changePage){
                if(!changePage) self.currentPage = 1;
                let pageDefaultOption = {
                    pageSize:self.pageSize,
                    pageNum:self.pageNum,
                    pattern:self.globalSearchTerm
                }
                let pageOption = angular.extend(pageDefaultOption,options);
                self.template_name = [];
                securityTemplateSrv.getTemplateList(pageOption).then(function(res) {
                    self.loadData = res ? true : '';
                    if (res && res.data) {
                        let data = res.data;
                        data.forEach(function(item) {
                            if(item.domainName=="default"){
                                item.domainName_TR = $translate.instant("aws.common.defaultDepar")
                            }else{
                                item.domainName_TR = item.domainName
                            }
                            if(item.projectName=="admin"){
                                item.projectName_TR = $translate.instant("aws.common.defaultProject")
                            }else{
                                item.projectName_TR = item.projectName
                            }
                            self.template_name.push(item.name)
                        })
                        self.securityTemplateTable = new NgTableParams({
                            page: 1,
                            count: 10,
                        }, {
                            counts: [],
                            dataset: data
                        });
                        self.totalNum = res.total;
                        self.securityTemplate_chkboxs = {
                            checked: false,
                            items: {}
                        };
            
                        self.$watch(function() {
                            return self.securityTemplateTable.page();
                        }, function() {
                            self.securityTemplate_chkboxs = {
                                checked: false,
                                items: {}
                            };
                        });
            
                        self.$watch(function() {
                            return self.securityTemplate_chkboxs.checked;
                        }, function(value) {
                            angular.forEach(self.securityTemplateTable.data.filter(item => item.deleted == 0), function(item) {
                                self.securityTemplate_chkboxs.items[item.id] = value;
                            });
                        });
            
                        self.$watch(function() {
                            return self.securityTemplate_chkboxs.items;
                        }, function() {
                            self.selectedItemsData = [];
                            var checked = 0,
                                unchecked = 0;
                            self.isPaasSecuritys = false;
                            angular.forEach(self.securityTemplateTable.data.filter(item => item.deleted == 0), function(item) {
                                checked += (self.securityTemplate_chkboxs.items[item.id]) || 0;
                                unchecked += (!self.securityTemplate_chkboxs.items[item.id]) || 0;
                                if (self.securityTemplate_chkboxs.items[item.id]) {
                                    if(item.name=='PaaS security pair'){
                                        self.isPaasSecuritys = true;
                                    }
                                    self.selectedItemsData.push(item);
                                    self.editData = angular.copy(item);
                                }
                            });
                            self.canEdit = false;
                            self.canDel = false;
                            if (checked === 1) {
                                if (self.editData.deleted != 0) {
                                    self.canEdit = false;
                                    self.canDel = false;
                                } else {
                                    self.canEdit = true;
                                    self.canDel = true;
                                }
                            } else if (checked > 1) {
                                self.canEdit = false;
                                self.canDel = true;
                            } else {
                                self.canEdit = false;
                                self.canDel = false;
                            }
            
                            angular.element(".select-all-securityGroupTable").prop("indeterminate", (checked != 0 && unchecked != 0));
                            if(checked>0&&unchecked==0){
                                self.securityTemplate_chkboxs.checked = true;
                            }else if(checked==0&&unchecked>0){
                                self.securityTemplate_chkboxs.checked = false;
                            }
                        }, true);
                        self.$watch(function(){
                            return self.selectedItemsData
                        },function(value,old){
                            if(value&&old&&value.length!=old.length){
                                utilTool.setLocalStorage(value);
                            }
                        })
                    }
                });
            };
            initTemplateTable();

            self.refreshTemplateTable = function() {
                self.globalSearchTerm = '';
                initTemplateTable();
            };
    
            self.applyGlobalSearch = function() {
                $timeout.cancel(self.sendSearch)
                self.sendSearch = $timeout(()=>{
                    initTemplateTable()
                },1000)
            };

            //详情事件
            self.linkTo = function(item){
                utilTool.setLocalStorage([item]);
                $location.url("/cvm/security_template?id="+item.id+"&securityType="+item.type+"&deleted="+item.deleted);
            }
            self.linkClose = function(){
                self.securityTemplate_chkboxs.items = {};
                $location.url('/cvm/security_template');
            }
            self.changePage = function(options){
                initTemplateTable(options,'changePage');
            };
            //资源导出
            self.exportResource = function(){
                var url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/export/security_template?pageSize=&pageNum=&pattern=${self.globalSearchTerm||""}&regionKey=${localStorage.regionKey}&token=${localStorage.$AUTH_TOKEN}`
                utilTool.downloadFile(url);
            }
            //控制导出按钮是否显示，当选中全部部门时显示
            self.showExport = utilTool.isAllDomainSelect;

            // 新建，编辑模板
            self.updateTemplate = function(type){
                var scope = self.$new();
                var templateModal = $uibModal.open({
                    animation: true,
                    templateUrl: "createTemplateModal.html",
                    scope: scope
                });
                scope.submitted = false;
                scope.interacted = function(field){
                    scope.field_form = field;
                    return scope.submitted || field.description.$dirty;
                };
                scope.editType = false;
                scope.createTemplateForm = {
                    name: "",
                    type: "0",  // '0'是白名单，'1'是黑名单
                    description: ""
                };
                switch (type) {
                    case "new":
                        scope.templateModal_title = $translate.instant("aws.security.createTemplate");
                        scope.securityTemplateConfirm = function(form){
                            if (form.$valid){
                                let params = {
                                    name: scope.createTemplateForm.name,
                                    type: scope.createTemplateForm.type,
                                    description: scope.createTemplateForm.description || ''
                                };
                                securityTemplateSrv.createTemplate(params).then(function() {
                                    initTemplateTable();
                                });
                                templateModal.close();
                            }else {
                                scope.submitted = true;
                            }
                        };
                        break;
    
                    case "edit":
                        scope.editType = true;
                        scope.templateModal_title = $translate.instant("aws.security.editTemplate");
                        scope.createTemplateForm = angular.copy(self.editData);
                        scope.securityTemplateConfirm = function(form) {
                            if (form.$valid){
                                let params = {
                                    name: scope.createTemplateForm.name,
                                    description: scope.createTemplateForm.description || '',
                                    id: self.editData.id
                                };
                                securityTemplateSrv.editTemplate(params).then(function() {
                                    initTemplateTable();
                                });
                                templateModal.close();
                            }else {
                                scope.submitted = true;
                            }
                        };
                        break;
                }
            }

            //删除安全组模板
            self.deleteTemplate = function() {
                let content = {
                    target: "deleteTemplate",
                    msg: "<span>" + $translate.instant("aws.security.deleteTemplate") + "</span>"
                };
                self.$emit("delete", content);
            };
            self.$on("deleteTemplate", function() {
                let del_obj_ids = [];
                _.forEach(self.selectedItemsData, function(item) {
                    if (item.deleted == 0) {
                        del_obj_ids.push(item.id);
                    }
                });
                securityTemplateSrv.deleteTemplate({id:del_obj_ids}).then(function() {
                    initTemplateTable();
                });
            });

            //模板详情列表
            let initTemplateRuleTable = function(options,changePage){
                if(!changePage) self.ruleCurrentPage = 1;
                let pageDefaultOption = {
                    pageSize:self.rulePageSize,
                    pageNum:self.rulePageNum,
                    likes:self.detailSearchGroup.ruleSearch,
                    direction:self.detailTab
                }
                let pageOption = angular.extend(pageDefaultOption,options);
                securityTemplateSrv.getTemplateRuleDetail(pageOption,$routeParams.id).then(function(res) {
                    res ? self.securityDetailData = true : "";
                    if (res && res.data) {
                        let data = _.map(res.data, function(item) {
                            item.typeCode = item.protocol == "icmp"&&(item.portRangeMin||item.portRangeMin==0)&&(item.portRangeMax||item.portRangeMax==0) ? item.portRangeMin + " / " + item.portRangeMax : " - ";
                            item.sourceType = item.remoteIpPrefix || item.remoteGroupName || $translate.instant("aws.security.any");
                            item.strategy = $routeParams.securityType == '0'?$translate.instant("aws.security.allowed"):$routeParams.securityType == '1'?$translate.instant("aws.security.notAllowed"):'';
                            return item;
                        });
                        self.secDetailSearchTearm({"titleData":self.secDetailTitleData,"tableData":data});
                        self.tableParams = new NgTableParams({
                            page: 1,
                            count: 10
                        }, {
                            counts: [],
                            dataset: data
                        });
                        checkedSrv.checkDo(self, data, "id");
                        self.detailTotalNum = res.total;
                    }
                });
            };
            
            $scope.$on("getDetail", function(event, value) {
                self.securityDetailData = false;
                self.showBtn = $routeParams.deleted == 0 ? true : false;
                self.tableParams = new NgTableParams({
                    page: 1,
                    count: 10
                }, {
                    counts: [],
                    dataset: []
                });
                self.securityType = $routeParams.securityType;
                self.secDetailTitleName = "tempDetail";
                if (sessionStorage["tempDetail"]) {
                    self.secDetailTitleData = JSON.parse(sessionStorage["tempDetail"]);
                } else {
                    self.secDetailTitleData = [{
                        name: 'security.inputType',
                        value: true,
                        disable: false,
                        search: 'ethertype'
                    }, {
                        name: 'security.ip_protocol',
                        value: true,
                        disable: false,
                        search: 'protocol'
                    }, {
                        name: 'security.authorizationStrategy',
                        value: true,
                        disable: false,
                        search: 'strategy'
                    }, {
                        name: 'security.portRange',
                        value: true,
                        disable: false,
                        search: 'portRange'
                    }, {
                        name: 'security.typeCodeValue',
                        value: true,
                        disable: false,
                        search: 'typeCode'
                    }, {
                        name: 'security.sourceType',
                        value: true,
                        disable: false,
                        search: 'sourceType'
                    }];
                    sessionStorage.setItem(self.secDetailTitleName,JSON.stringify(self.secDetailTitleData));
                };

                self.secDetailSearchTearm = function(obj) {
                    var tableData = obj.tableData;
                    var titleData = obj.titleData;
                    tableData.map(function(item) {
                        item.searchTerm = "";
                        titleData.forEach(function(showTitle) {
                            if (showTitle.value) {
                                if (showTitle.search == 'portRange') {
                                    item.searchTerm += (item.portRangeMin + "-" + item.portRangeMax +"\b");
                                } else {
                                    item.searchTerm += item[showTitle.search] +"\b";
                                }
                            }
                        });
                    });
                };
                self.tabClick('ingress');
            });

            // tab切换
            self.tabClick = function(type) {
                self.detailTab = type;
                initTemplateRuleTable();
            }

            // 安全组模板 详情搜索
            self.templateDetailSearch = function(likes) {
                $timeout.cancel(self.searchDetail)
                self.searchDetail = $timeout(()=>{
                    initTemplateRuleTable({likes});
                },1000)
            }

            self.changeDetailPage = function(options){
                initTemplateRuleTable(options,'changePage');
            };

            //模板详情页刷新
            self.refreshTemplateDetail = function(){
                self.detailSearchGroup.ruleSearch = '';
                initTemplateRuleTable();
            };

            self.selectItem = function(list,val,key) {
                if(list && list.length){
                    for (let i = 0; i < list.length; i++) {
                        const item = list[i];
                        if(item[key] === val) return item;
                    }
                    return null;
                }else {
                    return null;
                }
            }

            //新建，编辑模板规则
            self.updateTemplateRule = function(type) {
                let scope = self.$new();
                let templateRuleModal = $uibModal.open({
                    animation: true,
                    templateUrl: "createTemplateRule.html",
                    scope: scope
                });

                scope.securityGroupRule = {
                    EthernetType: 1
                };
                scope.ipv6Value = {
                    value:'0:0:0:0:0:0:0:0/0'
                };
                scope.MainTypeValue = [
                    { name: "IPV4", value: 1 },
                    { name: "IPV6", value: 2 },
                ]
                // 默认值
                scope.securityGroupRule.EthernetType = scope.MainTypeValue[0].value;
                scope.changeMainType = function(value){
                    scope.securityGroupRule.EthernetType = value
                }


                scope.ruleSubmitted = false;
                scope.interactedPort = function(field) {
                    scope.field_form = field;
                    return scope.ruleSubmitted || field.port.$dirty;
                };
                scope.interactedPortRange = function(field) {
                    scope.field_form = field;
                    return scope.ruleSubmitted || field.fromPort.$dirty || field.toPort.$dirty;
                };
                scope.interactedProtocol = function(field) {
                    scope.field_form = field;
                    return scope.ruleSubmitted || field.protocol.$dirty;
                };
                scope.templateRule = {};
                templateRuleModal.opened.then(function() {
                    scope.types = ["0", "3", "4", "5", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "30"];
                    scope.allIcmpShow = true;
                    if($routeParams.securityType == '1'){
                        scope.templateRules = [{
                            name: "TCP",
                            value: "tcp"
                        }, {
                            name: "UDP",
                            value: "udp"
                        }];
                        scope.isBlackList = true;
                    }else {
                        scope.templateRules = [{
                            name: "TCP",
                            value: "tcp"
                        }, {
                            name: "UDP",
                            value: "udp"
                        }, {
                            name: "ICMP",
                            value: "icmp"
                        }, {
                            name: "ALL ICMP",
                            value: "icmp"
                        }, {
                            name: "其他协议",
                            value: "else"
                        }];
                        scope.isBlackList = false;
                    }

                    scope.directions = [{
                            name: $translate.instant("aws.security.ingress"),
                            value: "ingress"
                        }, {
                            name: $translate.instant("aws.security.egress"),
                            value: "egress"
                        }];

                    scope.portTypes = [{
                            name: $translate.instant("aws.security.assignPort"),
                            value: "port"
                        }, {
                            name: $translate.instant("aws.security.portRange"),
                            value: "portRange"
                        }];
                    
                    switch (type) {
                        case 'new':
                            scope.updateTemplateRule_title = $translate.instant("aws.security.createRule");
                            scope.templateRule.selectedRule = scope.templateRules[0];
                            scope.templateRule.direction = scope.directions.filter(item => item.value == self.detailTab)[0];
                            scope.templateRule.portType = scope.portTypes[0];
                            scope.templateRule.cidr = "0.0.0.0/0";
                            scope.templateRule.way = "cidr";
                            securityTemplateSrv.getGroupList().then(res=>{
                                if (res && res.data){
                                    scope.groupList = res.data;
                                    scope.templateRule.firewall = scope.groupList[0];
                                }
                            })
                            break;
                        case 'edit':
                            scope.updateTemplateRule_title = $translate.instant("aws.security.editRule");
                            scope.templateRule.selectedRule = scope.selectItem(scope.templateRules,scope.editData.protocol,'value');
                            scope.templateRule.direction = scope.selectItem(scope.directions,scope.editData.direction,'value');
                            
                            if(scope.editData.protocol == 'icmp'){
                                if(scope.editData.portRangeMin || scope.editData.portRangeMin == 0 || scope.editData.portRangeMax || scope.editData.portRangeMax == 0){
                                    scope.allIcmpShow = true;
                                }else{
                                    scope.allIcmpShow = false;
                                    scope.templateRule.selectedRule = scope.templateRules[3] ? scope.templateRules[3] : null;
                                }
                                scope.isIcmp = true;
                                scope.isPort = false;
                                scope.isPortRange = false;
                                scope.templateRule.from_port = scope.editData.portRangeMin;
                                scope.templateRule.to_port = scope.editData.portRangeMax;
                            }else {
                                if(scope.templateRule.selectedRule == null){
                                    scope.allIcmpShow = false;
                                    scope.elseProtocol = true;
                                    scope.templateRule.selectedRule = scope.templateRules[4] ? scope.templateRules[4] : null;
                                    scope.templateRule.protocol = scope.editData.protocol;
                                }
                                scope.templateRule.portType = scope.editData.portRangeMin == scope.editData.portRangeMax ? scope.portTypes[0] : scope.portTypes[1];
                                if(scope.templateRule.portType.value == 'port'){
                                    scope.templateRule.port = scope.editData.portRangeMin;
                                }else {
                                    scope.templateRule.from_port = scope.editData.portRangeMin;
                                    scope.templateRule.to_port = scope.editData.portRangeMax;
                                }
                            }
                            if(scope.editData.remoteIpPrefix){
                                scope.templateRule.way = "cidr";
                                if(scope.editData.ethertype == 'IPv4'){
                                    scope.templateRule.cidr = scope.editData.remoteIpPrefix;
                                } else {
                                    scope.ipv6Value.value = scope.editData.remoteIpPrefix;
                                }
                                // scope.templateRule.cidr = scope.editData.remoteIpPrefix;
                            }

                            if(scope.editData.ethertype == 'IPv4'){
                                scope.securityGroupRule.EthernetType = 1;
                            }else{
                                scope.securityGroupRule.EthernetType = 2;
                            }
                            securityTemplateSrv.getGroupList().then(res=>{
                                if (res && res.data){
                                    scope.groupList = res.data;
                                    if(scope.editData.remoteGroupName){
                                        scope.templateRule.way = "firewall";
                                        scope.templateRule.firewall = scope.selectItem(scope.groupList,scope.editData.remoteGroupName,'name');
                                    }else {
                                        scope.templateRule.firewall = scope.groupList[0];
                                    }
                                }
                            })
                            break;
                        default:
                            break;
                    }

                    scope.changeRule = function(rule,form) {
                        if (form.fromPort && form.toPort) {
                            form.fromPort.$dirty = false;
                            form.toPort.$dirty = false;
                        }
                        if (rule.name == "ALL ICMP" && rule.value == "icmp") {
                            scope.allIcmpShow = false;
                        } else {
                            scope.allIcmpShow = true;
                        }
                        if (rule.name == "ICMP" && rule.value == "icmp") {
                            scope.isIcmp = true;
                            scope.isPort = false;
                            scope.isPortRange = false;
                            
                            scope.templateRule.from_port = scope.types[0];

                            scope.codings = ["0"]
                            scope.templateRule.to_port = scope.codings[0];
                        } else {
                            scope.isIcmp = false;
                            scope.isPort = true;
                            scope.templateRule.portType = scope.portTypes[0];
                        }
                        if (rule.value == "else") {
                            scope.elseProtocol = true;
                            scope.allIcmpShow = false;
                        } else {
                            scope.elseProtocol = false;
                        }
                    };

                    scope.changeType = function(t) {
                        scope.codings = [];
                        switch (t) {
                            case "3":
                                scope.codings = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];
                                break;
                            case "5":
                                scope.codings = ["0", "1", "2", "3"];
                                break;
                            case "11":
                                scope.codings = ["0", "1"];
                                break;
                            case "12":
                                scope.codings = ["0", "1", "2"];
                                break;
                            default:
                                scope.codings = ["0"];
                                break;
                        }
                        scope.templateRule.to_port = scope.codings[0];
                    };

                    // scope.isPort = true;
                    scope.$watch(function() {
                        return scope.templateRule.portType;
                    }, function(portType) {
                        if (portType && portType.value == "port") {
                            scope.isPort = true;
                            scope.isPortRange = false;
                        }
                        if (portType && portType.value == "portRange") {
                            scope.isPort = false;
                            scope.isPortRange = true;
                        }
                    });

                    // scope.isCidrWay = true;
                    scope.$watch(function() {
                        return scope.templateRule.way;
                    }, function(value) {
                        switch (value) {
                            case "cidr":
                                scope.isCidrWay = true;
                                scope.isFirewallWay = false;
                                if(!scope.templateRule.cidr){
                                    scope.templateRule.cidr = "0.0.0.0/0";
                                }
                                break;
                            case "firewall":
                                scope.isCidrWay = false;
                                scope.isFirewallWay = true;
                                break;
                        }
                    });

                    scope.$watch(function() {
                        return scope.templateRule.port || scope.templateRule.from_port;
                    }, function(value) {
                        if (scope.templateRule.selectedRule&&scope.templateRule.selectedRule.value !== "icmp") {
                            if (value && (value < 1 || value > 65535)) {
                                scope.input_invalid = true;
                            } else {
                                scope.input_invalid = false;
                            }
                            if (!value && scope.field_form && scope.field_form.$dirty) {
                                scope.input_invalid = false;
                            }
                        }
                    });
                    scope.$watch(function() {
                        return scope.templateRule.to_port;
                    }, function(value) {
                        if (scope.templateRule.selectedRule&&scope.templateRule.selectedRule.value !== "icmp") {
                            if (scope.templateRule.from_port) {
                                if (value && (value < Number(scope.templateRule.from_port) || value > 65535)) {
                                    scope.toPort_invalid = true;
                                } else {
                                    scope.toPort_invalid = false;
                                }
                                if (!value && scope.field_form && scope.field_form.$dirty) {
                                    scope.toPort_invalid = false;
                                }
                            }
                        }
                    });
                });

                scope.templateRuleConfirm = function(data,form) {
                    
                    if (form.$valid) {
                        const ethertype = scope.securityGroupRule.EthernetType
                        var params = {
                            "templateId": $routeParams.id,
                            "direction": data.direction.value, //入口或者出口 Ingress 或 egress
                            // "ethertype": "IPv4", //类型 默认传iPv4?
                            "ethertype": ethertype == 1? "IPv4":"IPv6", //类型 默认传iPv4?
                            "protocol": data.selectedRule.value //规则 null, tcp, udp, and icmp
                        };

                        if (data.selectedRule.name == "ALL ICMP") {
                            params.portRangeMin = null;
                            params.portRangeMax = null;
                        } else {
                            if (scope.isPort) {
                                params.portRangeMin = data.port;
                                params.portRangeMax = data.port;
                            } else {
                                params.portRangeMin = data.from_port;
                                params.portRangeMax = data.to_port;
                            }
                        }
                        if (data.way == "cidr") {
                            // params.remoteIpPrefix = data.cidr; //IP地址
                            params.remoteIpPrefix = ethertype == 1?scope.templateRule.cidr:scope.ipv6Value.value;

                        } else {
                            // params.remoteIpPrefix = "";
                            params.remoteGroupName = data.firewall.name;
                        }

                        if (data.selectedRule.value == "else") {
                            params.protocol = data.protocol;
                            params.portRangeMin = null;
                            params.portRangeMax = null;
                        }
                        switch (type) {
                            case 'new':
                                securityTemplateSrv.createTemplateRule(params).then(function() {
                                    initTemplateRuleTable();
                                });
                                break;
                            case 'edit':
                                params.id = self.editData.id;
                                delete params.templateId;
                                securityTemplateSrv.editTemplateRule(params).then(function() {
                                    initTemplateRuleTable();
                                });
                                break;
                            default:
                                break;
                        }
                        
                        templateRuleModal.close();
                    }else {
                        scope.ruleSubmitted = true;
                    }
                };
            };

            // 安全组模板规则导出
            self.exportRules = function() {
                let url = `${GLOBALCONFIG.APIHOST.MANAGE}/v2/template/security-group/${$routeParams.id}/rules/export?direction=${self.detailTab}&token=${localStorage.$AUTH_TOKEN}`;
                utilTool.downloadFile(url);
            }

            // 安全组模板规则导入
            self.importRules = function() {
                self.submitInValid = false;
                self.fileCheck=false;
                var modal_import_rules = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "importRules.html",
                    scope: self
                });
                self.selected_file="";
                self.fileNameChanged = function(){
                    self.fileCheck=false;
                    self.selected_file = document.getElementById("upload").value;
                    var dom = document.getElementById("upload");
                    var file = dom.files[0];
                    var fileType ="";
                    file ? fileType=file.name.substr(-4,4):fileType=".xls";
                    if(file&&(file.name.substr(-4,4)==".xls"||file.name.substr(-5,5)=='.xlsx')){
                        self.fileCheck=false;
                    }else{
                        self.fileCheck=true; 
                    }
                    self.$apply();
        　　　　}
                self.confirm = function(filed){
                    if(filed.$valid&&!self.fileCheck){
                        var form = document.forms.namedItem("importRulesForm");
                        var oData = new FormData(form);
                        var oReq = new XMLHttpRequest();
                        var importsuccess=$translate.instant("aws.security.importsuccess");
                        var importerror=$translate.instant("aws.security.importerror");
                        oReq.onerror = function(e) { 
                            if(e.type=="error"){
                                alertSrv.set("",importerror , "error",5000);        
                            }
                        };
                        oReq.onload = function(){
                            var responseObj = JSON.parse(oReq.responseText);
                            if(responseObj){
                                    if( responseObj.code==0){
                                        alertSrv.set("",importsuccess , "success",5000);
                                        $timeout(function() {
                                            initTemplateRuleTable();
                                        },1000);
                                    }else{
                                        alertSrv.set("",$translate.instant("aws.statusCode."+responseObj.code) , "error",5000);
                                    }
                            }
                        }
                        oReq.open("POST", `${GLOBALCONFIG.APIHOST.MANAGE}/v2/template/security-group/${$routeParams.id}/rules/${self.detailTab}/import`, true);
                        //导入文件
                        // sessionStorage.setItem("SecurityGroupRule", "SecurityGroupRule");
                        let auth_token = localStorage.$AUTH_TOKEN;
                        oReq.setRequestHeader("X-Auth-Token",auth_token);
                        oReq.setRequestHeader("project_id",localStorage.projectUid);
                        oReq.send(oData);

                        modal_import_rules.close();
                    }else{
                        self.submitInValid = true;
                    }
                };
            }

            //模板详情页面删除规则
            self.deleteRule = function() {
                var content = {
                    target: "deleteTemplateRule",
                    msg: "<span>" + $translate.instant("aws.security.deleteTemplateRule") + "</span>"
                };
                self.$emit("delete", content);
            };
            self.$on("deleteTemplateRule", function() {
                var del_ids = [];
                _.forEach(self.checkedItems, function(item) {
                    del_ids.push(item.id);
                });
                securityTemplateSrv.deleteTemplateRule({id:del_ids}).then(function() {
                    initTemplateRuleTable();
                });
            });
}]);