addLoadbalancingCtrl.$inject = ["$scope", "$translate", "$uibModalInstance", "type", "initBalanceTable", "enhanceSrv","networksSrv","checkedItems","$q"]
function addLoadbalancingCtrl($scope, $translate, $uibModalInstance, type,initBalanceTable, enhanceSrv,networksSrv,checkedItems,$q) {
    var self = $scope;
    self.priceHour = 0;
    self.priceMonth = 0;
    self.priceHourAbout = 0;

    self.submitValid = false;
    self.getOuterNet = []
    self.subnetList = []
    self.obj = {
        isUseIp:false
    }
    self.objData = {
        isUseIp:false
    }
    self.subnet = {}
    self.balance = { 
        name:"",
        vip_id:"",
        description:"",
        defaultIndex: 1,
        defaultOuterNet:{},
        subnet:{},
    };
    // 是否选择全部
    self.choiceAll = localStorage.selectedAllDomain;

    self.isnew = type == 'new';
    self.title = self.isnew ? $translate.instant("aws.loadbalance.addTitle") : $translate.instant("aws.loadbalance.editTitle")
    self.w_config = styleConfig($translate.instant("aws.topo.externalNet") ,true , $translate.instant("aws.qcloud.instances.networkMsg")) 
    self.n_config = styleConfig($translate.instant("aws.networks.intranet"), true, $translate.instant("aws.qcloud.instances.intranetmsg") )
    
    function styleConfig(name, isIcon = false, iconContent=""){
        return {
            name,
            isIcon,
            iconContent
        }
    }

    self.framework = [
        { text: $translate.instant("aws.config.tallConfig"), value: 0 , cpu: 16, ram: 16}, 
        { text: $translate.instant("aws.config.centreConfig"), value: 1, cpu: 8, ram: 8}, 
        { text: $translate.instant("aws.config.lowConfig"), value: 2, cpu: 4, ram: 4}
    ]
    
    self.toggle = function (index) {
        self.balance.defaultIndex = index;
        billing()
    }


    const reqObj = [{
        promise: enhanceSrv.getFlavors,
        callback(res){
            const vcpus = [4, 8, 16]
            const specification = res.data.filter(item => vcpus.find(value=> value == item.vcpus))
            self.newSpecification = vcpus.map(item=> specification.filter(value=> (value.ram / 1024 == item) && (value.vcpus == item))).map(item=>item[0])
        }
    }, {
        promise: networksSrv.projectNetworks, 
        callback(res) {
            const outer_net_list = []       // 外网
            const intranet_list = []        // 内网
            // 获取所有网络
            res.data.forEach(item => {
                // 筛选子网
                const subnets = item.subnets.filter(value => {
                    value._id = item.id
                    value.name = value.name + "--" + value.cidr
                    return value.ipVersion == '4'
                })

                if(item.external){
                    outer_net_list.push(...subnets)
                }else{
                    intranet_list.push(...subnets)
                }
            })
            
            // 内网
            self.subnetList = intranet_list;
            self.balance.subnet = intranet_list[0];
            self.n_config.isShow = intranet_list.length == 0
            // 外网
            self.getOuterNet = outer_net_list;
            self.balance.defaultOuterNet = outer_net_list[0];
            self.w_config.isShow = outer_net_list.length == 0
        }
    }]

    
    // p1获取规格，p2获取网络
    const [p1, p2] = reqObj
    const pArr = [p1.promise({retainElbFlavor: true})]
    self.isnew && pArr.push(p2.promise())
    $q.all(pArr).then(res => {
        const [r1, r2] = res
        p1.callback(r1)
        r2 && p2.callback(r2)

        // 编辑
        if(!self.isnew){
            const { name, description, vcpus } = checkedItems[0]
            const balance = self.balance

            balance.name = name;
            balance.description = description;
            balance.defaultIndex = self.framework.find(item=>item.cpu == vcpus).value ;
        }
        billing()
    })
    
    /**
     * 计费
     */
    function billing(){
        const nowValue = self.framework.find(item=>item.value == self.balance.defaultIndex);
        const config = {
            "region": localStorage.getItem("regionName"),
            "cpuCount": nowValue.cpu,
            "memorySize": nowValue.cpu,
            "volumeSize": 20
        }
        enhanceSrv.billing(config).then(result=>{
            if(result && result.data){
                // 计费
                self.priceHour = result.data;
                self.priceHourAbout = result.data.toFixed(2);
                self.priceMonth = (result.data * 24 * 30).toFixed(2);
            }
        })
    }

    self.balanceConfirm = function (balanceForm) {
        if (balanceForm.$valid) {
            const {defaultOuterNet, subnet} = self.balance 
            // 校验指定IP是否被分配
            const neiIp = self.obj.fixedIp
            const waiIp = self.objData.fixedIp
            let isAllUse = !!(neiIp && waiIp)     //是否内网外网全部被指定IP

            // 是否打开编辑
            if(!self.isnew || (self.isnew && (!neiIp && !waiIp))){
                savaRequest()
                return
            } 

            const reqObj = {
                arr: [],
                fn: []
            }

            // 是否指定内网外网
            if(neiIp){
                reqObj.arr.push(enhanceSrv.getNetworksDetailNew(subnet._id))
                reqObj.fn.push(function(data){
                    self.obj.isUseIp = data.findIndex(item=>item.ip[0] == neiIp) != -1
                })
            }
            if(waiIp){
                reqObj.arr.push(enhanceSrv.getNetworksDetailNew(defaultOuterNet._id))
                reqObj.fn.push(function(data){
                    self.objData.isUseIp = data.findIndex(item=>item.ip[0] == waiIp) != -1
                })
            }
            $q.all(reqObj.arr).then(res => {
                const [res1, res2] = res
                const [fn1, fn2] = reqObj.fn
                fn1 && fn1(res1.data)
                fn2 && fn2(res2.data)

                // 校验指定IP是否被使用
                if(isAllUse){
                    (!self.obj.isUseIp && !self.objData.isUseIp) && savaRequest()
                } else if((neiIp && !self.obj.isUseIp) || (waiIp && !self.objData.isUseIp)){
                    savaRequest()
                }
            })
        } else {
            self.submitValid = true;
        }
    }

    function savaRequest(){
        let ram = "";
        let vcpus = "";
        let flavor = "";
        const { name, defaultIndex, defaultOuterNet, description, subnet } = self.balance 
        // 获取cpu和内存
        const cpuObj = self.framework.find(item => item.value == defaultIndex);
        const vcpusArr = self.newSpecification.filter(item => {
            if(item){
                return item.vcpus == cpuObj.cpu
            }
        })
        if(vcpusArr.length != 0) {
            flavor = vcpusArr[0].id
        } else {
            ram = cpuObj.ram;
            vcpus = cpuObj.cpu;
        }
        
        const config = {
            "count": 1,
            "use_local": false,
            "os_type": "linux",
            "volumeSize": 20,
            "instanceType":"lb_inst",
            "instanceTypeCategory":"elb",
            flavor,
            ram, 
            vcpus,
            name,
            description,
            vip_address: self.obj.fixedIp || "",
            vip_id: subnet._id,
            vip_subnet_id:subnet.id,
            fixed_ip: self.objData.fixedIp || "",
            subnet_id:defaultOuterNet.id,
            network_id: defaultOuterNet._id,
        }

        // 全部时得到登录角色默认部门默认项目
        const headers = {}
        if(self.choiceAll == 1){
            headers.project_id= localStorage.getItem('defaultProjectUid'),
            headers.project_name= encodeURI(localStorage.getItem('defaultProjectName')),
            headers.domain_id= localStorage.getItem('defaultdomainUid'),
            headers.domain_name= encodeURI(localStorage.getItem('defaultdomainName'))
        }

        if(self.isnew){
            enhanceSrv.addLoadbalancing(config, headers).then(requestCallback)
        } else {
            const { uid } = checkedItems[0]
            // 更新接口
            enhanceSrv.updateEnhance(uid, config, headers).then(requestCallback)
        }
    }
    
    function requestCallback(res){
        if(res && res.msg=="OK"){
            initBalanceTable()
        }
        $uibModalInstance.close();
    }
}
export { addLoadbalancingCtrl }