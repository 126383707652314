
var backupJobSrvModule = angular.module("backupJobSrvModule",[]);

backupJobSrvModule.service("backupJobSrv",["$http", function($http){
    return{
        // 备份任务列表
        getBackupJobList:function(params){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-job/list",
                params: params,
            });           
        },
        // 创建备份任务
        createBackupJob:function(data, headers){
            return $http({
                method: "POST",
                url: "/awstack-backup/v1/backup/backup-job/create",
                data: data,
                headers
            });           
        },
        // 修改备份任务
        editBackupJob:function(data, headers){
            return $http({
                method: "PUT",
                url: "/awstack-backup/v1/backup/backup-job/update",
                data: data,
                headers
            });           
        },
        // 删除备份任务
        deleteBackupJob:function(data, headers){
            return $http({
                method: "DELETE",
                url: "/awstack-backup/v1/backup/backup-job/delete",
                params: data,
                headers
            });           
        },
        // 备份任务详情
        getBackupJobInfo:function(id, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-job/" + id,
                headers
            });
        },
        // 未绑定云主机列表
        getInstanceList:function(params,headers ){
            return $http({
                method: "GET",
                url: "/awstack-manage/v1/back/backup/instance",
                params: params,
                headers
            });
        },
        // 未绑定云主机列表--云主机状态
        getInstanceStatusList:function(params){
            return $http({
                method: "GET",
                url: "/awstack-manage/v1/back/backup/instance/status",
                params: params,
            });
        },
        // 未绑定云主机列表
        bindInstance:function(data, headers){
            return $http({
                method: "POST",
                url: "/awstack-backup/v1/backup/backup-job/bind-instance",
                data: data,
                headers
            });
        },
        // 备份任务 监控 列表
        getBackupJobMonitorList:function(params){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-job-instance/list-monitor",
                params: params,
            });           
        },
        
        // 备份任务 监控 列表
        stopBackupjob:function(data, headers){
            return $http({
                method: "POST",
                url: "/awstack-backup/v1/backup/backup-job-instance/stop",
                data: data,
                headers
            });           
        },

        // 备份任务 历史记录 列表
        getBackupJobHistoryList:function(params){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-job-instance/list-history",
                params: params,
            });           
        },
        // 备份任务 实例详情 执行进度 列表
        getBackupJobInfoTargetsList:function(data, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-job-instance/targets",
                params:data,
                headers
            });           
        },
        // 备份任务 实例详情 日志 列表
        getBackupJobInfoLogList:function(data, headers){
            return $http({
                method: "GET",
                url: "/awstack-backup/v1/backup/backup-job-instance/logs",
                params:data,
                headers
            });           
        },
        //  历史任务 删除
        deleteInstance:function(data, headers){
            return $http({
                method: "DELETE",
                url: "/awstack-backup/v1/backup/backup-job-instance/delete-history",
                params: data,
                headers
            });           
        },
    };
}]);