tdsqlCtrl.inject = ['$scope','$rootScope','$timeout',"$location",'NgTableParams','$uibModal',"GLOBAL_CONFIG","$translate",'newCheckedSrv','tdsqlSrv']
createTdsqlCtrl.inject =  ['$scope','$rootScope','$timeout','NgTableParams',"GLOBAL_CONFIG",'newCheckedSrv','tdsqlSrv','$routeParams','$uibModal']
initTdsqlCtrl.inject = ['$scope','$rootScope','$timeout','NgTableParams',"$uibModal",'$uibModalInstance',"GLOBAL_CONFIG",'refreshTdsqlTable','initData','newCheckedSrv','tdsqlSrv']
upgradeTdsqlCtrl.inject = ['$scope','$rootScope','context','$timeout','NgTableParams',"$uibModal",'$uibModalInstance',"GLOBAL_CONFIG",'refreshTdsqlTable','upgradeData','newCheckedSrv','tdsqlSrv']
modifyDataSyncCtrl.inject = ['$scope','$rootScope','$timeout','NgTableParams','$uibModalInstance',"GLOBAL_CONFIG",'refreshTdsqlTable','checkedSrv','tdsqlSrv','context','$location']
function tdsqlCtrl($scope,$rootScope,$timeout,$location,NgTableParams,$uibModal,GLOBAL_CONFIG,$translate,newCheckedSrv,tdsqlSrv) {
    let self = $scope;
    self.tdsql_search={};
    self.tab={};
    self.handleBtn = {
        upgradeEnable:true,
        delEnable:true,
    	isolation:true,
    	canInit:true,
        cancelIsolation:true
    }
    //进度条模态框
    let progessModal = null;
    //设置项的初始化
    self.titleName="TDSQL";
    if(sessionStorage["TDSQL"]){
       self.titleData=JSON.parse(sessionStorage["TDSQL"]);
    }else{
		self.titleData=[
			{name:'tdsql.header.id_name',value:true,disable:true,search:"instanceId"},
			{name:'tdsql.header.status',value:true,disable:true,search:"statusDisplay"},
			{name:'tdsql.header.type',value:true,disable:false,search:"instanceTypeDisplay"},
			{name:'tdsql.header.edition',value:true,disable:false,search:"editionDisplay"},
			{name:'tdsql.header.specifications',value:true,disable:false,search:"specifications"},
			{name:'tdsql.header.address',value:true,disable:false,search:"hosts"}
		];
    }
    self.tdsqlSearchTerm=function(obj){
        var tableData = obj.tableData;
        var titleData = obj.titleData;
        tableData.map(function(item){
            item.searchTerm="";
            titleData.forEach(function(showTitle){
                if(showTitle.value){
                    item.searchTerm+=item[showTitle.search]+"\b";
                }
            });
        });  
    };
    var initTdsqlTable=function(){
        self.tdsqlTable = new NgTableParams({
           count: GLOBAL_CONFIG.PAGESIZE
        }, {
           counts: [],
           dataset: []
        });
        self.tdsql_search.globalSearchTerm ="";
        self.loadTdsqlData  = '';
        tdsqlSrv.getInstances().then(function(result){
            if(result&&result.data){
                self.loadTdsqlData= result.data;
                successFunc(result.data);
            }
        });
    };
    initTdsqlTable();

    function successFunc(data){
        self.tdsqlData=data.map(function(item){
            item.statusDisplay = $translate.instant("aws.tdsql.instatus." + item.instanceStatus);
            item.instanceTypeDisplay = $translate.instant("aws.tdsql.instanceType." + item.instanceType);
            item.editionDisplay = $translate.instant("aws.tdsql.edition." + item.nodeNum);
            item.specifications = "CPU:"+item.cpu+"核 内存:"+item.memory+"GB 存储空间:"+(item.logDisk*1+item.dataDisk*1)+"GB"
            return item;
        });
        //根据session初始化searchterm
        self.tdsqlSearchTerm({tableData:self.tdsqlData,titleData:self.titleData});
        self.tdsqlTable = new NgTableParams({
            count: GLOBAL_CONFIG.PAGESIZE
        }, {
         counts: [],
         dataset: self.tdsqlData
        });
        newCheckedSrv.checkDo(self, "", "id", "tdsqlTable");
    }

    self.refreshTdsql=function(){
       initTdsqlTable();
    };

    self.$watch(function() {
        return self.checkedItemstdsqlTable;
    }, function(value) {
        if(value&&value.length==1){
            self.handleBtn = {
                upgradeEnable:true,
                delEnable:true,
                isolation:true,
                canInit:true,
                cancelIsolation:true
            }
            if(value[0].instanceStatus==1){
                if(value[0].instanceType==1){
                    self.handleBtn.upgradeEnable = false;
                }
                self.handleBtn.isolation = false;
            }
            if(value[0].instanceStatus==0){
                self.handleBtn.canInit = false;
                self.handleBtn.delEnable = false;
            }
            if(value[0].instanceStatus==2){
                self.handleBtn.delEnable = false;
                self.handleBtn.cancelIsolation = false;
            }
        }else{
            self.handleBtn = {
                upgradeEnable:true,
                delEnable:true,
                isolation:true,
                canInit:true,
                cancelIsolation:true
            }
        }
    });
    
    self.applyGlobalSearch = function() {
        self.tdsqlTable.filter({
            searchTerm: self.tdsql_search.globalSearchTerm
        });
    };

    //新建TDSQL
    self.createTdsql = function() {
        self.tdsqlAnimation = "animateIn";
        $("body").addClass("animate-open")
    };
    self.tdsqlAnimation = "animateOut";

    //关闭弹出框
    self.closeTdsql = function(){
        self.tdsqlAnimation = "animateOut";
        $("body").removeClass("animate-open");
    }
  
    //初始化
    self.initTdsql = function(initData) {
        var editPortsModal=$uibModal.open({
            animation: true,
            templateUrl: "initTdsql.html",
            controller: "initTdsqlCtrl",
            resolve: {
                refreshTdsqlTable: function(){
                    return initTdsqlTable;
                },
                initData:function(){
                    return initData;
                },
                context:function(){
                    return self;
                }
            }
        });
    };

    //升级
    self.upgradeTdsql = function(upgradeData) {
        var editPortsModal=$uibModal.open({
            animation: true,
            templateUrl: "upgradeTdsql.html",
            controller: "upgradeTdsqlCtrl",
            resolve: {
                refreshTdsqlTable: function() {
                    return initTdsqlTable;
                },
                upgradeData:function(){
                    return upgradeData;
                },
                context:function(){
                    return self;
                }
            }
        });
    };

    //实例隔离
    self.isolationDestruction= function(checkedItems){
        var content = {
            target: "isolationDes",
            msg: "<span class='isolation'>您正在隔离（"+checkedItems[0].instanceId+"），实例隔离后将不可被访问，进一步可删除或恢复运行，隔离后资源空间不会被释放且保留最基本的数据副本。 </span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };

    self.$on("isolationDes", function(e,data) {
        if(data[0].instanceType==1){
            var dataParams = {
                type:"DeactiveInstance",
                clusterKey:data[0].clusterKey,
                params:{
                    "instance":[
                        {
                            "id": data[0].instanceId
                        }
                    ]
                }
            }
        }else{
            var dataParams = {
                type:"DeactiveGroup",
                clusterKey:data[0].clusterKey,
                params:{
                    "groupid": data[0].instanceId
                }
            }
        }
        tdsqlSrv.deactiveInstance(dataParams).then(function(result){
            if(result&&result.data){
                initTdsqlTable(); 
            }
        });
    });

    //实例取消隔离
    self.cancelDestruction= function(checkedItems) {
        var content = {
            target: "cancelDes",
            msg: "<span class='cancelDes'>" + '您确定取消隔离？' + "</span>",
            data: checkedItems,
            btnType:'btn-primary'
        };
        self.$emit("delete", content);
    };

    self.$on("cancelDes", function(e,data) {
        if(data[0].instanceType==1){
            var dataParams = {
                type: "ActiveInstance",
                clusterKey:data[0].clusterKey,
                params:{
                    "instance":[
                        {
                            "id": data[0].instanceId
                        }
                    ]
                }
            }
        }else{
            var dataParams = {
                type: "ActiveGroup",
                clusterKey:data[0].clusterKey,
                params:{
                    "groupid": data[0].instanceId
                }
            }
        }
        tdsqlSrv.deactiveInstance(dataParams).then(function(result){
            if(result&&result.data){
                initTdsqlTable(); 
            }
        });
    });

    //实例销毁
    self.delDestruction= function(checkedItems) {
        var content = {
            target: "delDes",
            msg: "<span class='isolation'>您正在销毁（"+checkedItems[0].instanceId+"），销毁实例将删除该实例所有数据且不可恢复，请谨慎操作，确定后，销毁实例</span>",
            data: checkedItems
        };
        self.$emit("delete", content);
    };

    self.$on("delDes", function(e,data) {
        if(data[0].instanceType==1){
            var dataParams = {
                type:"DeleteGWInstance",
                clusterKey:data[0].clusterKey,
                params:{
                    "id":data[0].instanceId,
                    "check_coldbackup":false,// 直接传false
                    "check_gateway":false    // 直接传false
                }
            }
        }else{
            var dataParams = {
                type:"DeleteGWGroup",
                clusterKey:data[0].clusterKey,
                params:{
                    "groupid":data[0].instanceId,
                    "check_coldbackup":false,// 直接传false
                    "check_gateway":false    // 直接传false
                }
            }
        }
        tdsqlSrv.deactiveInstance(dataParams).then(function(result){
            if(result&&result.data){
                if(data[0].instanceType==1){
                    var dataParams = {
                        type:"Deldouble",
                        params:{
                            type:"QueryGWDelInstance",
                            clusterKey:data[0].clusterKey,
                            params:{
                                "taskid":result.data.returnData.taskid
                            }
                        }
                    }
                    
                }else{
                    var dataParams = {
                        type:"Deldouble",
                        params:{
                            type:"QueryDelGWGroup",
                            clusterKey:data[0].clusterKey,
                            params:{
                                "taskid":result.data.returnData.taskid
                            }
                        }
                    }
                }

                self.progess={
                    number:'0%',
                    status:true
                }
                self.progressTitle = '实例销毁进度';
                progessModal = $uibModal.open({
                    animation: true,
                    templateUrl: "toprogress.html",
                    scope:self
                })
                pollingProgress(dataParams);
            }
        });
    });

    self.activeTab=function(activeTab){
       self.tab.active = activeTab;
       self.activeDetailTmpl = 'js/paas/tdsql/tmpl/'+activeTab+"Tmpl.html"
    };

    //升级
    self.modifyDataSyncType = function() {
        var modifyDataSyncModal=$uibModal.open({
            animation: true,
            templateUrl: "modifyDataSync.html",
            controller: "modifyDataSyncCtrl",
            resolve: {
                refreshTdsqlTable: function() {
                    return initTdsqlTable;
                },
                context:function() {
                    return self;
                }
            }
        });
    };
   
   //获取实例详情
    var res;
	self.getDetail = function (){
		self.instanceParam=$location.search();
		tdsqlSrv.getDetail(self.instanceParam.clusterKey,self.instanceParam.id).then(function(opt){
			if (opt){
				self.accountData=opt.data;
				delSync_type(self.accountData);
			}
		});
	};
	function delSync_type(opt){
		opt.instance.logDisk = opt.instance.logDisk + "GB";
		self.accountData.allocation=opt.instance.memory+"GB内存，"+opt.instance.dataDisk+"GB容量";
		if (opt.dbVersion.indexOf("10.1.9")>=0) {
			opt.dbVersion = "兼容MySQL 5.6(基于MariaDB 10.1.9分支, 不支持分布式事务)"
		} else if(opt.dbVersion.indexOf("10.0.10")>=0){
			opt.dbVersion = "兼容MySQL 5.6(基于MairaDB 10.0.10分支, 较老版本，不建议使用)"
		} else if(opt.dbVersion.indexOf("5.7.17")>=0){
			opt.dbVersion = "MySQL 5.7.17(基于MySQL 5.7.17分支, 支持分布式事务)"
		}
	}
    //tdsql详情
    $scope.$on("getDetail", function(event, value) {
    	self.getDetail();
        if($location.search().instanceType==0){
            self.canPiecewiseManage = true;
        }else{
            self.canPiecewiseManage = false;
        }
        self.activeTab('insDetail');
    });

    //进度条
    let pollingProgress = function (data){
        let type = data.type;
        let progessData = {};
        var apiType = "";
        $timeout(function(){
            if(type=='nonDistributed'){
                var params = {
                    clusterKey:data.clusterKey,
                    taskId:data.taskId
                }
                apiType = "addGWInsanceProcess";

            }else if(type=='distributed'){
                var params =data.dataParams;
                params.taskId = data.taskId;
                apiType = "addInsToGroupProcess";

            }else if(type=="Deldouble"){
                var params = data.params;
                apiType = "deactiveInstance";

            }
            tdsqlSrv[apiType](params).then(function(result){
                if(result&&result.data){
                    progessData.step = result.data.returnData.process;        
                    progessData.status = result.data.returnData.status;
                    self.progess={
                        tips:"执行失败！ "+result.data.returnData.description,
                        number:progessData.step+'%',
                        status:progessData.status==1?false:true
                    }
                }
            }).finally(function(){
                if(progessData.step!=100&&progessData.status!=1){
                    pollingProgress(data);
                }
                if(progessData.step==100&&progessData.status!=1){
                    $timeout(function(){
                        progessModal.close();
                        initTdsqlTable();
                    },1000)
                }
            })
        },5000)
    }

    //新建成功
    self.$on("newTDSQL",function(e,data){
        self.progess={
            number:'0%',
            status:true
        }
        self.progressTitle = '新建进度';
        progessModal = $uibModal.open({
            animation: true,
            templateUrl: "toprogress.html",
            scope:self
        })
        pollingProgress(data);
        self.closeTdsql();
    });
}
function createTdsqlCtrl($scope,$rootScope,$timeout,NgTableParams,GLOBAL_CONFIG,newCheckedSrv,tdsqlSrv,$routeParams,$uibModal) {   
	var self = $scope;
    self.options = {
        'instanceType':0,
        'edition':2,
        'disksize':30,
        "mode":1,
        'fragmentnum':2,
    }


    self.synType = [
        {name:'强同步（可退化）',type:1},
        {name:'异步',type:0},
    ]

    self.instanceType = [
        {
            text:'分布式实例',
            value:0,
        },
        {
            text:'非分布式实例',
            value:1,
        }
    ]

    self.editionType = [
        {
            text:'标准版（一主一从）',
            value:2,
        },
        {
            text:'标准版（一主二从）',
            value:3,
        }
    ]

    self.availableDataSet ={
        clusterList:[],
        databaseList:[
            {
                name:"MySQL 5.7.17(基于MySQL 5.7.17分支, 支持分布式事务)",
                key:"5.7.17"
            },
            {
                name:"兼容MySQL 5.6(基于MairaDB 10.0.10分支, 较老版本，不建议使用)",
                key:"10.0.10"
            }
        ],
        specificationList:[],
        instanceSpecList:[]
    }
    self.availableDataSet.databaseSelect = self.availableDataSet.databaseList[0];
    self.changeInstanceType = function(v){
        if(v==1){
            self.availableDataSet.databaseList = [
                {
                    name:"MySQL 5.7.17(基于MySQL 5.7.17分支, 支持分布式事务)",
                    key:"5.7.17"
                },
                {
                    name:"兼容MySQL 5.6(基于MariaDB 10.1.9分支, 不支持分布式事务)",
                    key:"10.1.9"
                },
                {
                    name:"兼容MySQL 5.6(基于MairaDB 10.0.10分支, 较老版本，不建议使用)",
                    key:"10.0.10"
                }
            ]
            self.availableDataSet.databaseSelect = self.availableDataSet.databaseList[0];
        }else{
            self.availableDataSet.databaseList = [
                {
                    name:"MySQL 5.7.17(基于MySQL 5.7.17分支, 支持分布式事务)",
                    key:"5.7.17"
                },
                {
                    name:"兼容MySQL 5.6(基于MairaDB 10.0.10分支, 较老版本，不建议使用)",
                    key:"10.0.10"
                }
            ]
            self.availableDataSet.databaseSelect = self.availableDataSet.databaseList[0];
        }
    }

    function getQueryInstanceSpecList(){
        tdsqlSrv.getQueryInstanceSpecList().then(function(res){
            if(res&&res.data){
                self.availableDataSet.instanceSpecList = angular.copy(res.data);
                self.availableDataSet.instanceSpecSelect = self.availableDataSet.instanceSpecList[0];
            }
        })  
    }
    getQueryInstanceSpecList();
    self.getSpecificationList = function (clusterKey){
        tdsqlSrv.getSpecificationList(clusterKey).then(function(res){
            if(res&&res.data){
                let listData = angular.copy(res.data.returnData.spec);
                let specificationData = [];
                listData.forEach(item=>{
                    let str = item.machine+" （cpu:"+item.cpu/100+"核 内存:"+(item.mem/1024).toFixed(2)+"GB 数据磁盘:"+(item.data_disk/1024).toFixed(2)+"GB 日志磁盘:"+(item.log_disk/1024).toFixed(2)+"GB）";
                    specificationData.push({
                        name:str,
                        cpu:item.cpu/100,
                        mem:(item.mem/1024).toFixed(2),
                        value:item.machine,
                        data_disk:(item.data_disk/1024).toFixed(2),
                        log_disk:(item.log_disk/1024).toFixed(2)
                    })
                })
                self.availableDataSet.specificationList = specificationData;
                self.availableDataSet.specificationSelect = self.availableDataSet.specificationList[0];
                
            }
        })
    }

    tdsqlSrv.getCluster().then(function(res){
        if(res&&res.data){
            self.availableDataSet.clusterList = res.data;
            self.availableDataSet.clusterSelect = self.availableDataSet.clusterList[0];
            self.getSpecificationList(self.availableDataSet.clusterSelect.clusterKey)
        }
    })
    
    self.chooseNetType = function(item){
        self.options.netType = item.value;
    }
    self.chooseEdition = function(item){
        self.options.edition = item.value;
    }

    $timeout(function(){
       $("#bindBar").ionRangeSlider({
            min: 30,
            max: 3000,
            type: 'single',//设置类型
            from:self.options.disksize,
            step: 5,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
            onChange:function(data){
                self.options.disksize=data.from;
                //校验是否数据盘或者日志盘超出规格
                if (self.availableDataSet.specificationSelect.data_disk<data.from*0.9||
                self.availableDataSet.specificationSelect.log_disk<data.from*0.1){
                    self.disksizeTips = true;
                }else{
                    self.disksizeTips = false;
                }
                self.$apply();
            }
        }); 
    },500)
    
    //input框改变磁盘的值
    self.changeDiskSize=function(value){
        var bindBar=$("#bindBar").data("ionRangeSlider");
        bindBar.update({
            min: 30,
            max: 3000,
            type: 'single',//设置类型
            from:value,
            step: 1,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
        })
        
        //校验是否数据盘或者日志盘超出规格
        if (self.availableDataSet.specificationSelect.data_disk<value*0.9||
        self.availableDataSet.specificationSelect.log_disk<value*0.1){
            self.disksizeTips = true;
        }else{
            self.disksizeTips = false;
        }
    };

    self.changeSize = function(type){
        if(type=='add'){
            if(self.options.disksize>2995){return;}
            self.options.disksize = self.options.disksize + 5;
            self.changeDiskSize(self.options.disksize);
        }else{
            if(self.options.disksize<35){return;}
            self.options.disksize = self.options.disksize - 5;
            self.changeDiskSize(self.options.disksize);
        }
    }

    self.changeFragmentNum = function(type){
        if(type=='add'){
            if(self.options.fragmentnum>7){return;}
            self.options.fragmentnum++;
        }else{
            if(self.options.fragmentnum<3){return;}
            self.options.fragmentnum--;
        }
    }

    self.confirmCreate = function(m){
        if(m.$valid&&!self.disksizeTips){
            var data = {
                "projectUid":localStorage.projectUid, //项目ID
                "clusterKey":self.availableDataSet.clusterSelect.clusterKey,  //集群KEY
                "instanceType":self.options.instanceType,     //实例类型(0分布式，1非分布式)
                "dbversion":self.availableDataSet.databaseSelect.key,  //数据库版本
                "nodeNum":self.options.edition,           //set的机器数目，2：一主一备；3：一主两备；默认是3
                "cpu":self.availableDataSet.instanceSpecSelect.cpu,           //cpu数量；"100"：代表一个cpu
                "memory":self.availableDataSet.instanceSpecSelect.memory,       //内存的大小；单位是M
                "dataDisk":parseInt(self.options.disksize*1024*0.9),    //数据盘的大小；单位是M
                "logDisk":parseInt(self.options.disksize*1024*0.1),     //日志盘的大小；单位是M
                "syncType":self.options.mode,           //主机和备机的模式。0：异步模式；1：强同步
                "machine":self.availableDataSet.specificationSelect.value,        //机型规格
                "machineDesc":self.availableDataSet.specificationSelect.name
            }    
            if(self.options.instanceType==0){
                data.instanceNum = self.options.fragmentnum;
                tdsqlSrv.addInstance(data).then(function(res){
                    if(res&&res.data){
                        self.$emit("newTDSQL",{dataParams:data,type:'distributed',taskId:res.data.returnData.taskid,clusterKey:self.availableDataSet.clusterSelect.clusterKey})
                    }
                })
            }else{
                tdsqlSrv.addInstance(data).then(function(res){
                    if(res&&res.data){
                        self.$emit("newTDSQL",{type:'nonDistributed',taskId:res.data.returnData.taskid,clusterKey:self.availableDataSet.clusterSelect.clusterKey})
                    }
                })
            }
        }else{
            self.submitInValid = true;
        }
    }
}
function initTdsqlCtrl($scope,$rootScope,context,$timeout,NgTableParams,$uibModal,$uibModalInstance,GLOBAL_CONFIG,refreshTdsqlTable,initData,newCheckedSrv,tdsqlSrv) {
	var self = $scope;
    self.initParams = {
        'character':'utf8',
        'case':false
    }
    self.supportCharacter = [
        {name:'UTF8',type:'utf8'},
        {name:'LATIN1',type:'latin1'},
        {name:'GBK',type:'gbk'},
        {name:'UTF8MB4',type:'utf8mb4'},
    ]
    //进度条模态框
    var  progessModal = null;
    let pollingProgress = function (data){
        let type = data.type;
        let progessData = {};
        $timeout(function(){
            tdsqlSrv.initInstance(data).then(function(result){
                if(result&&result.data){
                    progessData.step = result.data.returnData.process;        
                    progessData.status = result.data.returnData.status;
                    context.progess={
                        tips:"执行失败！ "+result.data.returnData.description,
                        number:progessData.step+'%',
                        status:progessData.status==1?false:true
                    }
                }
            }).finally(function(){
                if(progessData.step!=100&&progessData.status!=1){
                    pollingProgress(data);
                }
                if(progessData.step==100&&progessData.status!=1){
                    $timeout(function(){
                        progessModal.close();
                        refreshTdsqlTable();
                    },1000)
                }
            })
        },5000)
    }

    self.initConfirm = function(){
        if(initData[0].instanceType==1){
            var params = {
                clusterKey:initData[0].clusterKey,
                interfacename:"InitInstance",
                params:{
                    "id": initData[0].instanceId,
                    "config": [                               
                        {
                            "param": "lower_case_table_names",//tables name是否大小写敏感。0：敏感；1：不敏感；默认是1
                            "value": self.initParams.case?"0":"1" 
                        },
                        {
                            "param": "character_set_server",//字符编码
                            "value": self.initParams.character
                        }
                    ]
                }
            }
        }else{
            var params = {
                clusterKey:initData[0].clusterKey,
                interfacename:"InitGroupIns",
                params:{
                    "groupid": initData[0].instanceId,
                    "config": [                               
                        {
                            "param": "lower_case_table_names",//tables name是否大小写敏感。0：敏感；1：不敏感；默认是1
                            "value": self.initParams.case?"0":"1" 
                        },
                        {
                            "param": "character_set_server",//字符编码
                            "value": self.initParams.character
                        }
                    ]
                }
            }
        }
        tdsqlSrv.initInstance(params).then(function(result){
            if(result&&result.data){
                $uibModalInstance.close();
                if(initData[0].instanceType==1){
                    var paramsData = {
                        type:initData[0].instanceType,
                        clusterKey:initData[0].clusterKey,
                        interfacename:"QueryInitInstance",
                        params:{
                            taskid:result.data.returnData.taskid
                        }
                    }
                }else{
                    var paramsData = {
                        type:initData[0].instanceType,
                        clusterKey:initData[0].clusterKey,
                        interfacename:"QueryInitGroupIns",
                        params:{
                            groupid:initData[0].instanceId
                        }
                    }
                }
                context.progess = {
                    number:'0%',
                    status:true
                }
                context.progressTitle = '初始化进度';
                progessModal = $uibModal.open({
                    animation: true,
                    templateUrl: "toprogress.html",
                    scope:context
                })
                pollingProgress(paramsData);
            }
        }) 
    }
}
function upgradeTdsqlCtrl($scope,$rootScope,context,$timeout,NgTableParams,$uibModal,$uibModalInstance,GLOBAL_CONFIG,refreshTdsqlTable,upgradeData,newCheckedSrv,tdsqlSrv) {
    var self = $scope;
    self.upgradeData = {
        name:upgradeData[0].instanceId,
        edition:upgradeData[0].editionDisplay,
        specifications:"CPU"+upgradeData[0].cpu+"核 内存"+upgradeData[0].memory+"GB",
        currentspecifications:upgradeData[0].machineDesc,
        memSize:upgradeData[0].memory,
        cpuCore:upgradeData[0].cpu,
        disksize:(upgradeData[0].logDisk*1+upgradeData[0].dataDisk*1),
        suit:'日独立用户数上百人的小型应用',
        useTime:'预计10分钟',
        change:false,
        year:"",
    }
    self.minDisk = upgradeData[0].logDisk*1+upgradeData[0].dataDisk*1;
    self.startTime = {
        hour:"",
        minute:"",
        second:""
    }

    $timeout(function(){
        function getNowFormatDate(date) {
            var seperator1 = "-";
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
            return currentdate;
        }
        $('.form_date').datetimepicker({
            language: "zh-CN",
            weekStart: 1,
            todayBtn: 1,
            autoclose: 1,
            todayHighlight: 1,
            minView:"month",
            //startView: 2, 
            minuteStep:5,
            forceParse: 0,
            format: "yyyy-mm-dd",
            pickerPosition: "bottom-left",
            
        });
        var date = new Date();
        self.upgradeData.year = getNowFormatDate(date);
        $('.form_date').datetimepicker('setStartDate', getNowFormatDate(date));
    },1000)

    $timeout(function(){
       $("#upgradeBar").ionRangeSlider({
            min: (upgradeData[0].logDisk*1+upgradeData[0].dataDisk*1),
            max: 3000,
            type: 'single',//设置类型
            from:self.upgradeData.disksize,
            step: 5,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
            onChange:function(data){
                self.upgradeData.disksize=data.from;
                //校验是否数据盘或者日志盘超出规格
                if (self.availableDataSet.specificationSelect.data_disk<data.from*0.9||
                self.availableDataSet.specificationSelect.log_disk<data.from*0.1||
                upgradeData[0].data_disk>data.from*0.9||
                upgradeData[0].log_disk>data.from*0.1
                ){
                    self.disksizeTips = true;
                }else{
                    self.disksizeTips = false;
                }
                self.$apply();
            }
        }); 
    },300)

    self.availableDataSet ={
        specificationList:[],
        instanceSpecList:[]
    }


    self.appointTime = function(v){
        self.startTime = {
            hour:"",
            minute:"",
            second:""
        }
    }

    function getQueryInstanceSpecList(params){
        tdsqlSrv.getQueryInstanceSpecList(params).then(function(res){
            if(res&&res.data){
                self.availableDataSet.instanceSpecList = angular.copy(res.data);
                self.availableDataSet.instanceSpecSelect = self.availableDataSet.instanceSpecList[0];
            }
        })  
    }

    self.changeQueryspec = function(v){
       var params = {
            cpu:v.cpu*100,
            memory:v.memory*1024
        }
        getQueryInstanceSpecList(params);
    }
    var params = {
        cpu:upgradeData[0].cpu,
        memory:upgradeData[0].memory
    }
    self.changeQueryspec(params)
    //获取规格
    self.getSpecificationList = function (clusterKey){
        tdsqlSrv.getSpecificationList(clusterKey).then(function(res){
            if(res&&res.data){
                let listData = angular.copy(res.data.returnData.spec);
                let specificationData = [];
                listData.forEach(item=>{
                    let str = item.machine+" （cpu:"+item.cpu/100+"核 内存:"+(item.mem/1024).toFixed(2)+"GB 数据磁盘:"+(item.data_disk/1024).toFixed(2)+"GB 日志磁盘:"+(item.log_disk/1024).toFixed(2)+"GB）";
                    specificationData.push({
                        name:str,
                        cpu:item.cpu/100,
                        mem:(item.mem/1024).toFixed(2),
                        value:item.machine,
                        data_disk:(item.data_disk/1024).toFixed(2),
                        log_disk:(item.log_disk/1024).toFixed(2)
                    })
                })
                self.availableDataSet.specificationList = specificationData;
                self.availableDataSet.specificationSelect = self.availableDataSet.specificationList[0];
            }
        })
    }
    self.getSpecificationList(upgradeData[0].clusterKey)

    //input框改变磁盘的值
    self.changeDiskSize=function(value){
        var bindBar=$("#upgradeBar").data("ionRangeSlider");
        bindBar.update({
            min: (upgradeData[0].logDisk*1+upgradeData[0].dataDisk*1),
            max: 3000,
            type: 'single',//设置类型
            from:value,
            step: 5,
            prefix: "",//设置数值前缀
            postfix: "GB",//设置数值后缀
            prettify: true,
            hasGrid: true,
            grid:true,
        });
        
        //校验是否数据盘或者日志盘超出规格
        if (self.availableDataSet.specificationSelect.data_disk<value*0.9||
            self.availableDataSet.specificationSelect.log_disk<value*0.1
            ){
            self.disksizeTips = true;
        }else{
            self.disksizeTips = false;
        }
    };

    self.changeSize = function(type){
        if(type=='add'){
            if(self.upgradeData.disksize>2995){return;}
            self.upgradeData.disksize=self.upgradeData.disksize+5;
            self.changeDiskSize(self.upgradeData.disksize);
        }else{
            if(self.upgradeData.disksize<(upgradeData[0].logDisk*1+upgradeData[0].dataDisk*1+5)){return;}
            self.upgradeData.disksize=self.upgradeData.disksize-5;
            self.changeDiskSize(self.upgradeData.disksize);
        }
    }
    //进度条模态框
    var  progessModal = null;
    var pollingProgress = function (data){
        let progessData = {};
        $timeout(function(){
            tdsqlSrv.initInstance(data).then(function(result){
                if(result&&result.data){
                    progessData.step = result.data.returnData.process;        
                    progessData.status = result.data.returnData.status;
                    context.progess = {
                        tips:"执行失败！ "+result.data.returnData.description,
                        number:progessData.step+'%',
                        status:progessData.status==1?false:true
                    }
                }
            }).finally(function(){
                if(progessData.step!=100&&progessData.status!=1){
                    pollingProgress(data);
                }
                if(progessData.step==100&&progessData.status!=1){
                    $timeout(function(){
                        progessModal.close();
                        refreshTdsqlTable();
                    },1000)
                }
            })
        },5000)
    }

    function setTimeFormat(v){
        return Number(v)<10?"0"+v:v;
    }

    self.upgradeConfirm = function(m){
        if(m.$valid&&!self.disksizeTips){
            var data ={
                clusterKey:upgradeData[0].clusterKey,
                params:{
                    "instanceId":upgradeData[0].instanceId,
                    "instanceType":upgradeData[0].instanceType,
                    "nodeNum":upgradeData[0].nodeNum,
                    "cpu":self.availableDataSet.instanceSpecSelect.cpu,
                    "memory":self.availableDataSet.instanceSpecSelect.memory,
                    "dataDisk":Math.round(self.upgradeData.disksize*1024*0.9),
                    "logDisk":Math.round(self.upgradeData.disksize*1024*0.1),
                    "machine":upgradeData[0].machine,
                    "startTime":self.upgradeData.change?(self.upgradeData.year+" "+setTimeFormat(self.startTime.hour)+":"+setTimeFormat(self.startTime.minute)+":"+setTimeFormat(self.startTime.second)):'0'
                }
            }

            if(data.params.memory/1024==upgradeData[0].memory&&
                self.upgradeData.disksize==(upgradeData[0].logDisk*1+upgradeData[0].dataDisk*1)&&
                data.params.cpu/100==upgradeData[0].cpu){
                self.sameGradeTips = true;
                return;
            }else{
                self.sameGradeTips = false;
            }
            
            if(self.upgradeData.change){
                var submitTime = (new Date(data.params.startTime)).getTime();
                if(submitTime<(new Date()).getTime()){
                    self.timeTips = true;
                    return;
                }else{
                    self.timeTips = false;
                }
            }
            tdsqlSrv.expandGWInstance(data).then(function(res){
                if(res&&res.data){
                    $uibModalInstance.close();
                    let paramsData = {
                        clusterKey:upgradeData[0].clusterKey,
                        interfacename:"QueryExpandGWInstance",
                        params:{
                            id:upgradeData[0].instanceId
                        }
                    }
                    context.progess = {
                        number:'0%',
                        status:true
                    }
                    context.progressTitle = '升级进度';
                    progessModal = $uibModal.open({
                        animation: true,
                        templateUrl: "toprogress.html",
                        scope:context
                    })
                    pollingProgress(paramsData);
                }
            })
        }else{
            self.submitInValid = true;
        }
    }
}
function modifyDataSyncCtrl($scope,$rootScope,$timeout,NgTableParams,$uibModalInstance,GLOBAL_CONFIG,refreshTdsqlTable,checkedSrv,tdsqlSrv,context,$location) {
    var self = $scope;
    if (context.accountData.syncType == 1) {
    	var sy = 'Synchronous'
    } else{
    	var sy = 'asynchronous'
    }
    self.type={
        syncType:sy
    };
    self.sycnParam=$location.search();
    self.modifyDataSyncConfirm=function(modifyDataSyncForm){
    	var clusterkey;
    	if (self.type.syncType == 'Synchronous') {
    		var value = 1
    	} else{
    		var value = 0
    	}
    	tdsqlSrv.modifySyncForm(self.sycnParam.clusterKey,self.sycnParam.id,value).then(function(){
    		context.getDetail(); 
    	});
        console.log(self.type.syncType,"self.type.syncType");
        $uibModalInstance.close();
    };
}
export{tdsqlCtrl,createTdsqlCtrl,initTdsqlCtrl,upgradeTdsqlCtrl,modifyDataSyncCtrl}
