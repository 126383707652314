backupJobMonitor.$inject = [];
function backupJobMonitor() {
    return {
        restrict:"E",
        scope:{
            data:"=",
            draggable:"=",
            index:"="
        },
        replace:true,
        templateUrl:"js/advanced-function/backup/backup-job/tmpl/backup-job-monitor.html",
        controller:'backupJobMonitorCtrl',
        link: function(self, elem, attrs, $ngModel) {
            // self.data.capacity = self.data.capacity.replace(/\s+/g,'');
            let elema = $(elem)[0];
            elema.ondragstart = function(event){
                self.$emit('drag-start',{data:self.data,index:self.index});
                event.dataTransfer.setData("text/html",self.data);
            }
            elema.ondragenter = function(event){
                event.preventDefault();
            }
            elema.ondragover = function(event){
                event.preventDefault();
            }
        }
    };
}
export {backupJobMonitor}