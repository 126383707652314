var ladpSrvModule = angular.module("ladpSrvModule",[]);

ladpSrvModule.service("ladpSrv",["$http",function($http){
    return{
        addData:function(data){
            return $http({
                method:"POST",
                url: "/awstack-user/v1/ldap/config",
                data:data
            });          
        },
        getLadpData:function(){
            return $http({
                method:"GET",
                url: "/awstack-user/v1/ldap/config",
            });
        }
    };
}]);