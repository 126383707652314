import "./cloudDatabaseSrv";
import * as controllers from './controllers';
import * as directives from './directive';
import * as services from './services';
var cloudDatabaseModule = angular.module("cloudDatabaseModule", ["cloudDatabaseSrvModule"]);

cloudDatabaseModule.config(['$controllerProvider', '$compileProvider',"$provide",
    function($controllerProvider,$compileProvider,$provide){
        $controllerProvider.register(controllers);
        $compileProvider.directive(directives);
        $provide.service(services);
    }
])

