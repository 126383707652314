detailLogTpl.$inject = [];
function detailLogTpl () {
    return {
        restrict:"E",
        replace:true,
        scope:{
            insUid: "=",
        },
        templateUrl: "js/cvm/instances/tmpl/detail-log-tpl.html",
        link:function(self, elem, attrs, $ngModel) {

        },
        controller: function($routeParams, $rootScope, $scope, $translate, $location, NgTableParams, flavorsSrv, commonFuncSrv, vmFuncSrv, instancesSrv,vmhostSrv,userDataSrv,TableCom) {
            var self = $scope;
            // 日志信息
            self.insConfigDatas = { status: "empty" };
            self.logContent = "";
            function getServerDetail(insUid,insStatus){
                // 搁置状态和错误状态的云主机不调接口
                if(insStatus=="shelved_offloaded" || insStatus=="error"){
                    return self.logContent = '';
                }
                instancesSrv.getServerDetail(insUid).then(function(result) {
                    if (result.data.status != "error" &&  result.data.status != "build") {
                        instancesSrv.getServerLog(insUid).then(function(result) {
                            if (result) {
                                if(result.data ==""){
                                    $scope.logContent =  $translate.instant("aws.instances.notAvailableLog");
                                }else{
                                    $scope.logContent = result.data.replace(/\n/g, "<br\/>");
                                }
                            }
                        });
                    }
                    self.insConfigDatas = result.data;
                })
            }
            getServerDetail(self.insUid,$routeParams.insStatus);
        }
    }
}

export { detailLogTpl };