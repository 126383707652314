addAlarmCtrl.$inject = ["$scope", "$translate","$uibModalInstance","$location","context","data","NgTableParams", 'alarmTemplateSrv','contactGroupSrv','alarmSettingSrv'];
export function addAlarmCtrl($scope, $translate, $uibModalInstance, $location, context, data, NgTableParams, alarmTemplateSrv, contactGroupSrv, alarmSettingSrv){
    let self = $scope;
    self.alarmForm = initAlarmForm();
    self.alarmForm.hostname = data.name;
    self.alarmForm.labelList = data.uid;
    self.disabledConfirmBtn = false;
    //self.contactsDisabled = true;
    //self.templateDisabled = true;


    //获取告警模板
    alarmTemplateSrv.getAlarmTmpls().then(function(result) {
        if (result && result.data && angular.isArray(result.data)) {
            var data = result.data;
            var vmTpl = [];
            self.templateDisabled = false;
            data.map((item) => {
                if (item.resourceType == "virtual") {
                    vmTpl.push(item);
                }
            });
            self.tmpls = {
                options: vmTpl
            };
        }
    });

    //获取联系人组
    contactGroupSrv.getContactGroup().then(function(result) {
        if (result && result.data && result.data.data && angular.isArray(result.data.data)) {
            //self.contactsDisabled = false;
            self.contacts = {
                options: result.data.data
            };
        }
    });

    self.submitted = {};
    self.alarmConfirm = function(formField, data) {
        if (formField.$valid) {
            var params = {
                name: data.name,
                labels: data.labelList,
                contactLists: data.contactlists.join(","),
                templates: (data.alarmtemps).join(","),
                resourceType: "virtual",
                alarmAction: "email",
                enabled: data.enabled,
                domainId: localStorage.domainUid,
                projectId: localStorage.projectUid
            };
            self.disabledConfirmBtn = true;
            alarmSettingSrv.createAlarm(params).then(function() {
                $uibModalInstance.close();
                context.checkboxes.items = {};
            }).finally(()=>{
                self.disabledConfirmBtn = false;
            });
            
        } else {
            if (formField.alarmName.$invalid) {
                self.submitted.alarmName = true;
            }
            if (formField.template.$invalid) {
                self.submitted.template = true;
            }
            if (formField.contactGroup.$invalid) {
                self.submitted.contactGroup = true;
            }
        }
    };

    function initAlarmForm() {
        return {
            name: "",
            labelList: "",
            alarmtemps: [],
            contactlists: [],
            normalToAlarm: "",
            alarmToNormal: "",
            alarmAction: "email",
            enabled: true
        };
    }
}