angular.module("sriovSrv", [])
    .service("sriovsrv", ["$http",function ($http) {
        return {
            delSriovApi: function (data) {
                return $http({
                    method: "POST",
                    url: `awstack-user/v1/sriov/delete`,
                    data:data
                });
            },
            getNodeList: function () {
                return $http({
                    method: "GET",
                    url: `awstack-user/v1/enterprises/${localStorage.enterpriseUid}/regions/${localStorage.regionUid}/usable/nodes/list`
                });
            },
            createApi:function(data){
                return $http({
                    method:"POST",
                    url:`awstack-user/v1/sriov`,
                    data:data
                })
            },
            reTryApi:function(data){
                return $http({
                    method:"PUT",
                    url:`awstack-user/v1/sriov?sriovId=${data}`
                })
            },
            getListApi:function(){
                return $http({
                    method:"GET",
                    url:`awstack-user/v1/sriov`
                })
            },
            getNodeCardAPi:function(data){
                return $http({
                    method:"GET",
                    url:`awstack-user/v1/sriov/${data.enterpriseUid}/getNic/${data.regionUid}/nodes/${data.hostName}`,
                })
            }
        };
    }]);
