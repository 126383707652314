
import { AreaPanelDefault } from "../../../chartpanel";
import {  hostAreaChartSqlParams } from "../../../chartpanel";
enhanceDetailMonitoring.$inject = ["$location"];
function enhanceDetailMonitoring ($location) {
    return {
        restrict:"E",
        replace:true,
        scope:{},
        templateUrl: "js/cvm/loadbalancersmodile/tmpl/enhance-detail-monitoring.html",
        controller: function($scope,$translate,vmFuncSrv,vmhostSrv,userDataSrv) {
            let self = $scope;
            const { uid } = $location.search()
            self.insUid = uid
            self.insDataFlag = false;
            self.filterData = {
                insUid:self.insUid,
                timeStep: "",
                from: null,
                to: null,
                definedTimeText: $translate.instant('aws.bill.chooseDate'),
                rangeHours:""
            };
            self.timeType = {
                options: [{
                    "name": $translate.instant('aws.bill.timeType.realTime'),
                    "value": ""
                }, {
                    "name": $translate.instant('aws.bill.timeType.sixHours'),
                    "value": "6h"
                }, {
                    "name": $translate.instant('aws.bill.timeType.oneDay'),
                    "value": "1d"
                }, {
                    "name": $translate.instant('aws.bill.timeType.threeDays'),
                    "value": "3d"
                }, {
                    "name": $translate.instant('aws.bill.timeType.oneWeek'),
                    "value": "7d"
                }]
            };
            self.fliterCard = function(data) {
                if(data && data.length) {
                    var netCard = data.filter(item => item.priority != "c0");
                    return netCard;
                }
            };
            function getRegionData(){
                $("#vmDetailLoadingChart").html("");
                vmFuncSrv.getHostAreaChartFunc(self, hostAreaChartSqlParams, AreaPanelDefault); //cpu、内存、磁盘各指标的监控
                userDataSrv.getRegionData().then(function(res) {
                    if (res && res.data) {
                        self.insDataFlag = true;
                        self.filterData.regionOptions = res.data.filter(item => item.status == 3);

                        self.filterData.region_key = self.filterData.regionOptions[0].regionKey;

                        self.filterData.regionOptions.forEach(item => {
                            if (item.regionKey == localStorage.regionKey) {
                                self.filterData.region_key = item.regionKey;
                            }
                        });
                       
                        // libvirt监控没有mem这个字段
                        const sqlconfig = {
                            "sql": "select * from mem where time > now() - 1m AND code = '" + self.filterData.region_key + "' AND host_type = 'elb' AND vm_id = '" + self.insUid + "'"
                        }
                        vmhostSrv.sqlQuery(sqlconfig).then(function(res) {
                            res ? self.loadedQueryData = true : false;
                            if (res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                                self.queryMemNull = false;
                                const sqlconfig_2 = {
                                    "sql": "SELECT MAX(used_percent) AS used_percent FROM swap WHERE time > now() - 30m AND code = '" + self.filterData.region_key + "' AND host_type = 'elb' AND vm_id = '" + self.insUid + "' GROUP BY time(10s)"
                                }
                                vmhostSrv.sqlQuery(sqlconfig_2).then(function(res) {
                                    const type = self.defaultType == 'average'
                                    //先查看Linux的是否有数据
                                    if (res && res.data && res.data.results && res.data.results[0] && res.data.results[0].series) {
                                        //有数据就用Linux数据渲染
                                        self.linuxSysVmchart = true;
                                        self.winSysVmchart = false;
                                        self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData,'', type);
                                    } else {
                                        self.linuxSysVmchart = false;
                                        self.winSysVmchart = true;
                                        self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData, "win", type);
                                    }
                                    console.log("linuxSysVmchart:", self.linuxSysVmchart, "winSysVmchart:", self.winSysVmchart);
                                });
                            } else {
                                self.queryMemNull = true;
                            }
                        })
                    }
                });
            }
    
            self.$on('cloud_main_type',function(e,value){
                self.defaultType = value;
            })
            self.query = function() {
                const dimensionality = self.defaultType == 'average';
                if (self.linuxSysVmchart) {
                    self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData,'',dimensionality);
                } else {
                    self.getHostAreaChart(self.filterData.region_key, self.insUid, self.filterData, "win", dimensionality);
                }
            };
            
            // 刷新页面数据会丢失 重新调api请求数据
            self.$watch(()=>self.insDataFlag, function(value){
                if(!value && self.insUid){
                    getRegionData();
                    return
                }
            },true)

            // 第一次进来的时候加载  切换tabs时insUid相同就取缓存  当insUid发生变化后才调api刷新数据
            self.$watch(()=>self.insUid, function(value,old){
                if(value && value != old){
                    getRegionData();
                    return
                }
            },true)
        }
    }
}

export {enhanceDetailMonitoring};