tdsqlSrv.inject = ["$rootScope", "$http"]
function tdsqlSrv($rootScope, $http) {
    var static_url2="awstack-user/v1/";
    return {
        getListAlarmInfo:function(params) {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/listAlarmInfo",
                params:params
            });
        },
        getMenus:function(menuType) {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/listMonitorMenus?menuType="+menuType
            });
        },
        getQueryInstanceSpecList:function(params) {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/QueryInstanceSpecList",
                params:params
            });
        },
        //获取分布式实例分片主备信息
        getGWGroup:function(data) {
            return $http({
                method: "POST",
                url: static_url2 + "tdsql/"+data.clusterKey+"/instancetype/"+data.interfacename+"?setId="+data.setId,
                data:data.params
            });
        },
        //获取非分布式实例分片主备信息
        getQueryGWGroup:function(data) {
            return $http({
                method: "POST",
                url: static_url2 + "tdsql/"+data.clusterKey+"/instancetype/"+data.interfacename,
                data:data.params
            });
        },
        //获取监控数据
        getMonitorData:function(params) {
            return $http({
                method: "POST",
                url: static_url2 + "tdsql/listMonitorInfo",
                data:params
            });
        },
        getInstances:function() {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/instances?projectId="+localStorage.projectUid
            });
        },
        //分片管理
        //创建分片
        createSet: function(addPieceForm,key) {
        	console.log(addPieceForm)
            return $http({
                method: "POST",
                url: static_url2+"tdsql/addinstogroup/"+key,
                data:addPieceForm
            });
        },
        //查询创建分片任务进度
        queryaddinstogroup: function(formatdata) {
        	console.log(formatdata,"formatdata");
            return $http({
                method: "POST",
                url: static_url2+"tdsql/queryaddinstogroup/"+formatdata.keys,
                data:formatdata
            });
        },
        //扩容分片
        expandgroupinstance: function(addPieceForm,key) {
            return $http({
                method: "POST",
                url: static_url2+"tdsql/expandgroupinstance/"+key,
                data:addPieceForm
            });
        },
        //查询扩容分片任务进度
        queryexpandgroupins: function(data) {
            return $http({
                method: "POST",
                url: static_url2+"tdsql/queryexpandgroupins/"+data.keys,
                data:data
            });
        },
        //强制删除分片
        deleteexpandinstance: function(setid,groupid,key) {
        	console.log(setid,"setid")
        	console.log(groupid,"groupid")
        	console.log(key,"key")
        	return $http({
                method: "POST",
                url: static_url2+"tdsql/deleteexpandinstance/"+key,
                data:{"id":setid,"groupid":groupid,"check_coldbackup":false,"check_gateway":false}
            });
        },
        //查询删除分片任务
        querydelexpandins: function(formatdata) {
            return $http({
                method: "POST",
                url: static_url2+"tdsql/querydelexpandins/"+formatdata.keys,
                data:formatdata
            });
        },
        //分片列表
        querygwinstance: function(key,id) {
            return $http({
                method: "GET",
                url: static_url2+"tdsql/getgwgroup/"+key+"?groupid="+id
            });
        },
        //查询磁盘剩余空间
        querydiskspace: function(groupid,setid) {
            return $http({
                method: "POST",
                url: static_url2+"tdsql/querydiskspace",
                data:{"groupspaceid":groupid+"/"+setid}
            });
        },
        //查询实例详情
        queryexampleeetails: function(key,groupid) {
            return $http({
                method: "POST",
                url: static_url2+"tdsql/queryexampledetails",
                data:{"key":key,"groupid":groupid}
            });
        },
        //获取参数列表
        getParamList:function(key,groupid,type) {
            return  $http({
                method: "GET",
                url: static_url2+"tdsql/listconfig/"+key+"?groupid="+groupid+"&id=&type="+type
            });
        },
        //修改参数列表
        modifyParamsList:function(options,key,type){
            return  $http({
                method: "POST",
                url: static_url2+"tdsql/modifyconfig/"+key,
                data:options
            });
        },
        //分片结束
        getCluster:function() {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/querycluster"
            });
        },
        addInstance:function(data) {
            return $http({
                method: "POST",
                url: static_url2 + "tdsql/addinstance",
                data:data
            });
        },
        expandGWInstance:function(data) {
            return $http({
                method: "POST",
                url: static_url2 + "tdsql/"+data.clusterKey+"/expandGWInstance",
                data:data.params
            });
        },
        addGWInsanceProcess:function(data) {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/"+data.clusterKey+"/QueryAddGWInsance?taskId="+data.taskId
            });
        },
        addInsToGroupProcess:function(data) {
            return $http({
                method: "post",
                url: static_url2 + "tdsql/AddInsToGroup",
                data:data
            });
        },
        initInstance:function(data) {
            return $http({
                method: "post",
                url: static_url2 + "tdsql/"+data.clusterKey+"/instancetype/"+data.interfacename,
                data:data.params
            });
        },
        getSpecificationList:function(clusterKey) {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/"+clusterKey+"/queryspec",
            });
        },
        deactiveInstance:function(data) {
            return $http({
                method: "post",
                url: static_url2 + "tdsql/"+data.clusterKey+"/instancetype/"+data.type,
                data:data.params
            });
        },
          getAccount:function(opt,clusterKey) {
            return $http({
                method: "get",
                url: static_url2 + "tdsql/"+clusterKey+"/listuser",
                params:opt
            });
        },
        createAccountConfirm:function(opt,clusterKey){
            return $http({
                method: "post",
                url: static_url2 + "tdsql/"+clusterKey+"/adduser",
                data:opt
            });
        },
        copyAccountConfirm:function(opt,clusterKey){
        	return $http({
                method: "post",
                url: static_url2 + "tdsql/"+clusterKey+"/cloneaccount",
                data:opt
            });
        },
        resetPwd:function(opt,clusterKey){
        	return $http({
                method: "post",
                url: static_url2 + "tdsql/"+clusterKey+"/resetpwd",
                data:opt
            });
        },
        delAccount:function(opt,clusterKey){
        	return $http({
                method: "post",
                url: static_url2 + "tdsql/"+clusterKey+"/deluser",
                data:opt
            });
        },
        findAllDataBase:function(opt,clusterKey){
        	return $http({
                method: "get",
                url: static_url2 + "tdsql/"+clusterKey+"/listdb",
                params:opt
            });
        },
        findDataBaseAuthority:function(opt,clusterKey){
        	return $http({
                method: "post",
                url: static_url2 + "tdsql/"+clusterKey+"/getright",
                data:opt
            });
        },
        modifyAuthority:function(opt,clusterKey){
        	return $http({
                method: "post",
                url: static_url2 + "tdsql/"+clusterKey+"/changeright",
                data:opt
            });
        },
        getDetail: function(clusterkey,id) {
			return $http({
				method: "GET",
				url: static_url2 + "tdsql/" + clusterkey + "/getinstance",
				params:{"id":id}
			});
		},
		modifySyncForm: function(clusterkey,id,value) {
			var opt = {
					"groupid":"",
					"id":id,
					"sqlasyn":value
				};
			return $http({
				method: "POST",
				url: static_url2 + "tdsql/" + clusterkey + "/setsyncmode",
				data: opt
			});
		}
    }
}
export {tdsqlSrv}