editPortCardCtrl.$inject = ["$scope","editData","$uibModalInstance","instancesSrv","refresh","$translate"];
export function editPortCardCtrl(scope,editData,$uibModalInstance,instancesSrv,refresh,$translate){
    let self = scope;
    self.networkList = editData.ports;
    self.netTitle = $translate.instant("aws.instances.modifyFixedIp");
    self.ipLabel = $translate.instant("aws.instances.addinstances.modifyAs");
    self.netWorkCard = {};
    self.submitInValid = false;
    self.disabledFlag = false;
    self.loading = false;
    self.network = {
        ipVersion:"4",
        v6List:{},
        v4List:{},
        range:{
            start:'172.16.22.66',
            end:'172.16.22.88'
        },
        tenantId:''
    };
    self.network.selected = self.networkList[0];
    self.submitInValid = false;
    function chkPortPolicyFun(){
        if(self.network.tenantId == localStorage.projectUid || localStorage.managementRole == "2"){
            self.portPolicyForbidden = false;
            self.rebootInsTip = true;
        }else{
            self.portPolicyForbidden = true;
            self.rebootInsTip = false;
        }
    };
    self.changeNet = function(item){
        self.loading = false;
        if(item.ipAddress.indexOf(":")>-1){
            self.network.ipVersion = 6;
            self.network.range.start = '0:0:0:0:0:0:0:0';
            self.network.range.end = 'ffff:ffff:ffff:ffff:ffff:ffff:ffff:ffff';
        }else{
            self.network.ipVersion = 4;
            self.network.range.start = '0.0.0.0';
            self.network.range.end = '0.0.0.0';
        }
        instancesSrv.getSubnetDetail(item.subnetId).then(res=>{
            if(res&&res.data){
                let pools = res.data.allocation_pools
                self.network.tenantId = res.data.tenant_id;
                if(pools&&pools.length>0){
                    self.network.range.start = pools[0].start;
                    self.network.range.end = pools[pools.length-1].end;
                }
                chkPortPolicyFun()
            }
        }).finally(()=>{
            self.loading = true;
        })
    }
    self.changeNet(self.network.selected);
    self.confirm = function(netForm){
        if(netForm.$valid){
            self.disabledFlag=true;
            let postData = {
                "port_id": self.network.selected.portId,
                "subnet_id": self.network.selected.subnetId,
                "floating_ip": self.network.selected.publicIpAddress ? self.network.selected.publicIpAddress : "" //获取内网IP所关联的外网IP
            };
            if(self.network.ipVersion==4){
                postData.ip_address = self.network.v4List.fixedIp
            }else{
                postData.ip_address = self.network.v6List.fixedIp
            }
            instancesSrv.modifyInterface(postData).then(res=>{
                $uibModalInstance.close()
                refresh();
            }).finally(function(){
                self.disabledFlag=false;
            });
        }else{
            self.submitInValid = true;
        }
        
    }
}
