
editStorageCtrl.$inject = ["$scope", "$translate","$uibModalInstance","editData", "initStorageTable","storageManagementSrv"];

function editStorageCtrl($scope, $translate,$uibModalInstance,editData, initStorageTable,storageManagementSrv) {
    var self = $scope;
    self.submitValid = false;
    self.modalTitle = $translate.instant("aws.system.storagement.editTitle");
    self.storage = {
        name: editData.name || "",
        description: editData.description || ""
    }
    self.editConfirm = function(updateStorageForm) {
        if(updateStorageForm.$valid){
            var option  =  {
                id: editData.id,
                name: self.storage.name,
                description: self.storage.description,
                enterpriseUid: localStorage.enterpriseUid,
                regionUid: localStorage.regionUid,
                regionKey: localStorage.regionKey
            }
            storageManagementSrv.editStorageData(option).then(function(result) {
                $uibModalInstance.close();
                initStorageTable();
            });
        } else {
            self.submitValid = true;
        }
    }
}
export {editStorageCtrl}