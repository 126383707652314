
angular.module("gpusrvModule", [])
    .service("gpuSrv", function ($http) {
        // var aggregateUrl = "http://192.168.138.134:9080/awstack-resource/v1";

        return {
            getPhyGpu: function () {
                return $http({
                    method: "GET",
                    url: "/awstack-resource/v1/gpu/getGpuList"
                });
            },
        };
    });
