newMonitorCtrl.$inject = ["$scope", "type", "addListenSrv", "initListenerTab", "$uibModalInstance", '$translate', 'editData',"uid","$location","$q"]

function newMonitorCtrl($scope, type, addListenSrv, initListenerTab, $uibModalInstance, $translate, editData,uid,$location,$q) {
    var self = $scope;
    self.submitValid = false;
    self.type = type == 'new';
    self.modalTitle = self.type ? $translate.instant('aws.loadbalancers.ui.newListener') : $translate.instant('aws.loadbalancers.ui.editListener')
    self.protocolList = [];
    self.algorithmList = [];
    self.listener = {
        name: '',
        protocol: '',
        port: '',
        algorithm: ''
    }
    // 是否选择全部
    self.choiceAll = localStorage.selectedAllDomain;

    self.changeValue = function (prop, value) {
        self.listener[prop] = value
    }

    // 页面初始接口调用
    const p1 = addListenSrv.getPropAgreement()
    const p2 = addListenSrv.getArithmetic()
    $q.all([p1, p2]).then(res => {
        self.protocolList = transitionObj(res[0].data)
        self.listener.protocol = self.protocolList[0].id

        self.algorithmList = transitionObj(res[1].data)
        self.listener.algorithm = self.algorithmList[0].id

        if (!self.type) {
            const { name, protocol, protocolPort, lbAlgorithm } = editData
            const listener = self.listener
            listener.name = name
            listener.protocol = protocol
            listener.port = protocolPort
            listener.algorithm = lbAlgorithm
        }
    })

    // 新增-编辑监听器
    self.listenerConfirm = function (listenerForm) {
        if (listenerForm.$valid) {
            const { name, protocol, port, algorithm } = self.listener
            const config = {
                elbUid: uid,
                name,
                protocol,
                protocolPort: port,
                lbAlgorithm: algorithm
            }
            const headers = {}
            if(self.choiceAll == 1){
                const { projectUid, projectName, domainUid, domainName } = $location.search()
                headers.project_id= projectUid;
                headers.project_name= encodeURI(projectName);
                headers.domain_id= domainUid;
                headers.domain_name= encodeURI(domainName);
            }
            if (self.type) {
                addListenSrv.addSoundMonitor(config,headers).then(resCallback)
            } else {
                config.uid = editData.uid
                addListenSrv.updateSoundMonito(config,headers).then(resCallback)
            }
        } else {
            self.submitValid = true;
        }
    }

    // 转换对象
    function transitionObj(data) {
        return data.map(item => {
            return {
                name: item,
                id: item
            }
        })
    }

    function resCallback(res){
        if(res && res.msg == "OK"){
            initListenerTab()
        }
        $uibModalInstance.close();
    }
}

export { newMonitorCtrl }