storageIpconfigRgw.$inject = ["storageManagementSrv","dragDiskCache"];
function storageIpconfigRgw(storageManagementSrv,dragDiskCache) {
    return {
        restrict:"E",
        replace:true,
        templateUrl:"js/system/storageManagement/tmpl/storage-ipconfig-rgw.html",
        link: function(self, elem, attrs, $ngModel) {
            
        },
        controller:function($scope,storageManagementSrv,dragDiskCache){
            /**
                * @description: 
                * @param {array} diskList
                * @param {object} typeList
                * @return {object}
                */
        }
    };
}
export {storageIpconfigRgw}