loadingLocalDiskCtrl.$inject=["$scope", "$rootScope", "$translate","$uibModalInstance","$location","$timeout","editData","TableCom","localDiskSrv","refresh","context"];
export function loadingLocalDiskCtrl($scope, $rootScope, $translate,$uibModalInstance,$location,$timeout,editData,TableCom,localDiskSrv,refresh,context){
    var self = $scope;
    self.pageSize = 8;
    self.globalSearchTerm = "";
    self.headers = {
        name:$translate.instant("aws.localDisk.localDiskName"),
        state:$translate.instant("aws.localDisk.status"),
        size:$translate.instant("aws.localDisk.config"),
        path:$translate.instant("aws.localDisk.location")
    };
    self.localDiskCheckbox = {
        checked: false,
        items: {}
    }
    self.checkedItem = [];
    self.saveDisabled = false;
    self.checkNotEmptyShow = true;
    self.changeTableShow = false;

    self.tableCols = [
        { field: "check", title: "",headerTemplateURL:"localDiskCheckbox.html",show: true },
        { field: "name", title: self.headers.name,sortable: "name",show: true,disable:true},
        { field: "state", title: self.headers.state,sortable: "state",show: true,disable:false},
        { field: "size", title: self.headers.size,sortable: "size",show: true,disable:false},
        { field: "path", title: self.headers.path,sortable: "path",show: true,disable:false}
    ];

    function initHaSwitch(value) {
        self.status = value.hostBind == true ? false : true;
        self.switchText = dealSwitchText(self.status);
    }
    initHaSwitch(editData);

    self.switchChange = (value)=> {
        self.status = value;
        self.switchText = dealSwitchText(value);
    }

    self.changeTableNode = function() {
        self.changeTableShow = true;
    }

    self.checkIsItem = function() {
        return self.checkNotEmptyShow = self.checkedItem.length > 0 ? true : false;
    }

    function dealSwitchText(value) {
        let hasSwitch = value ? $translate.instant('aws.localDisk.open') : $translate.instant('aws.localDisk.close');
        return hasSwitch;
    }

    function dealDomainProject(value) {
        self.domainProject = {
            domain_name: value.domainName,
            domain_id: value.domainUid,
            project_name: value.projectName,
            project_id: value.projectUid
        }
    }
    dealDomainProject(editData);

    // 提交
    self.confirm = ()=> {
        let list = self.checkedItem.map(item=>{
            return { uuid:item.uuid, diskUuid:item.diskUuid };
        });
        let vmId = editData.uid;
        let haSwitch = self.status == true ? 1 : 0;
        self.saveDisabled = true;
        self.changeTableShow = true;
        if(self.checkIsItem()) {
            $rootScope.$emit("loadingLocalDisk",{});
            localDiskSrv.mountLocalDisk(list,vmId,haSwitch).then(result=>{
                if(result) {
                    refresh();
                    $uibModalInstance.close();
                    self.saveDisabled = false;
                    self.changeTableShow = false;
                    context.isClickLocalDiskConfirm = true;
                }
            })
        }else {
            $timeout(()=>{
                self.saveDisabled = false;
            },2000)
        }
    }

    // 获取状态为可用的本地磁盘
    self.initTable = (option,headers)=> {
        TableCom.init(self, "localDiskTable", [], "id", self.pageSize, "localDiskCheckbox");
        let defaultParams = {
            pageNum: 1,
            pageSize: self.pageSize,
            pattern: self.globalSearchTerm,
            hostname: editData.hostName
        }
        let params = option ? option : defaultParams;
        headers.domain_name = headers.project_name == $translate.instant("aws.common.defaultDepar") ? 'default' : headers.domain_name;
        headers.project_name = headers.project_name == $translate.instant("aws.common.defaultProject") ? 'admin' : headers.project_name;
        self.localDiskTableData = [];
        localDiskSrv.getInstancesLoadingLocalDiskList(params,headers).then((result)=>{
            if(result && result.data){
                self.totalNum = result.total;
                self.localDiskTableData = result.data;
                TableCom.init(self, "localDiskTable", result.data, "id", self.pageSize, "localDiskCheckbox");
            }
        })
    }
    
    // 切换页面
    self.changePage = (options)=>{
        self.currentPage = options.pageNum;
        self.pageSize = options.pageSize;
        self.initTable(options,self.domainProject);
    }

    // 搜索
    self.searchBackend = ()=>{
        self.currentPage = 1;
        let pageOption = {
            pageSize: self.pageSize,
            pageNum: 1,
            pattern: self.globalSearchTerm ? self.globalSearchTerm : "",
            hostname: editData.hostName
        }
        self.initTable(pageOption,self.domainProject);
    }

    // 监听
    self.$watch(()=>{
        return self.localDiskCheckbox.items;
    },(val)=>{
        if(Object.keys(val).length == 0) return;
        self.checkedItem = [];
        var arr = [];
        for (let i in self.localDiskCheckbox.items) {
            arr.push(self.localDiskCheckbox.items[i]);
        }
        if(val && arr.length >= 0) {
            for (let key in val) {
                if(val[key]) {
                    self.localDiskTableData.forEach(item=>{
                        if(item.id == key) {
                            self.checkedItem.push(item);
                        }
                    })
                }
            }
        }
        self.checkIsItem();
    },true)

    self.initTable(null,self.domainProject);
}