
import "./create-data-clean-srv";

var createDataCleanModule = angular.module("createDataCleanModule",["ngMessages","createDataCleanSrvModule"]);
createDataCleanModule.controller('createBackupDataCleanCtrl', ["$scope", "$translate", "alertSrv", 
						"createDataCleanSrv", 'GLOBAL_CONFIG', "TableCom", "$timeout", "$route",
	function($scope, $translate, alertSrv, dataCleanSrv, GLOBAL_CONFIG, TableCom, $timeout, $route) {
	var self = $scope;

	self.ruleList = [
		{
			isDeleteAll: true,
			name: $translate.instant("aws.backupService.backupDataClean.create.allSelect")
		},
		{
			isDeleteAll: false,
			name: $translate.instant("aws.backupService.backupDataClean.create.currentPage")
		}
	]
	self.rule = self.ruleList[0];
	self.gnsPath = null;
	self.allDisabled = true;
	self.singleDisabled = true;
	self.currentPageCheckbox = false;
	self.submitted = false;
	self.backupJobList = [];
	self.timepointsList = [];
	self.timepointsLoadData = [];
	self.formData = {
		jobName: null,
		jobDesc: null,
		backupJob: null,
		copies: [],
		jobStorageId: "1",
		isDeleteAll: true
	}

	getBackupJobs();
	initTimepointsPage();

	// 获取备份任务下拉
	function getBackupJobs () {
		var headers = {};
		if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
			headers = {
				project_id: localStorage.defaultProjectUid,
				domain_id: localStorage.defaultdomainUid,
			}
		}
		dataCleanSrv.getBackupJobList(headers).then(function (result) {
			if (result && result.data.length > 0) {
				self.backupJobList = result.data;
				self.formData.backupJob = self.backupJobList[0];
				self.gnsPath = self.backupJobList[0].gnsPath;
				initTimepointsData();
			}
		});
	}

	// 初始化副本列表分页查询参数
	function initTimepointsPage() {
		self.timepointsCurrentPage = 1;
		self.timepointsPageSize = GLOBAL_CONFIG.PAGESIZE;
		self.timepointsSearchTerm = "";
	}

	// 初始化副本分页列表数据
	function initTimepointsData () {
		self.formData.copies = [];
		let params = {
			index: (self.timepointsCurrentPage - 1) * self.timepointsPageSize,
			count: self.timepointsPageSize,
			isClear: 1,
			gnsPath: self.gnsPath,
			jobStorageId: "1",
			startTime: 0,
			endTime: 0
		}
		var headers = {};
		if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
			headers = {
				project_id: localStorage.defaultProjectUid,
				domain_id: localStorage.defaultdomainUid,
			}
		}
		dataCleanSrv.getJobTimepointsList(params, headers).then(function (result) {
			if (result && result.data.length > 0) {
				TableCom.init(self, 'timepointsTableParams', result.data, "gnsPath", GLOBAL_CONFIG.PAGESIZE, 'checkTimepointsToCount');
				self.timepointsLoadData = result.data;
				self.timepointsTotalNum = result.total;
				self.timepointsList = result.data;

				// 延迟加载
				timeoutFun1();
				timeoutFun(result.data, self.formData.isDeleteAll);
			}
		});
	}

	// 复选框
	function echoCheckBox (data, flag) {
		if (data.length > 0) {
			data.forEach(item => {
				self.checkTimepointsToCount.items[item.gnsPath] = flag;
				if (!self.formData.isDeleteAll && !item.displayFlag) {
					self.checkTimepointsToCount.items[item.gnsPath] = false;
				}
			});
		}
	}

	// 分页查询副本列表
	self.changeTimepointsPage = function(options) {
        self.timepointsCurrentPage = options.pageNum;
        self.timepointsPageSize = options.pageSize;
		initTimepointsData();
    };

	// 切换备份任务下拉
	self.changeBackupJob = function(item) {
		self.gnsPath = item.gnsPath;
		self.formData.backupJob = item;
		initTimepointsData();
    }

	// 切换是否全选
	self.changerRule = function(item) {
		self.formData.copies = [];
		self.formData.isDeleteAll = item.isDeleteAll;
		self.singleDisabled = self.formData.isDeleteAll;
		if (self.timepointsTotalNum == 1) {
			self.allDisabled = true
		}else {
			self.allDisabled = self.formData.isDeleteAll;
		}
		// 延迟加载
		timeoutFun1();
		timeoutFun(self.timepointsList, self.formData.isDeleteAll);
	}

	// 选择当前列表
	self.selectCurrentPage = function(value) {
		// 延迟加载
		timeoutFun(self.timepointsList, value);
		self.formData.copies = [];
		self.timepointsList.forEach(item => {
			if (item.displayFlag) {
				self.formData.copies.push(item.gnsPath);
			}
		});
	}

	// 选择时间节点列表数据
	self.selecTimepointsTable = function(data, value) {
		if (value) {
			self.formData.copies.push(data.gnsPath);
		}else {
			if (self.formData.copies.indexOf(data.gnsPath) > -1) {
				self.formData.copies.splice(self.formData.copies.indexOf(data.gnsPath), 1); 
				self.currentPageCheckbox = false;
			}
		}
	}

	// 延迟加载
	function timeoutFun(data, value) {
		var timer = $timeout(function() {
			echoCheckBox(data, value);
			$timeout.cancel(timer);
		}, 500);
	}

    // 延时加载
	function timeoutFun1() {
		var timer = $timeout(function() {
			self.currentPageCheckbox = self.formData.isDeleteAll;
			$timeout.cancel(timer);
		}, 500);
	}

	// 确定创建
    self.confirmCreate = function(m) {
		if (m.$valid) {
			dataCleanSrv.checkDataCleanName({name: self.formData.jobName}).then(function (res) {
				if (res.data == 0) {
					let oData = {
						jobName: self.formData.jobName,
						jobDesc: self.formData.jobDesc,
						jobType: self.formData.jobType,
						jobStorageId: self.formData.jobStorageId,
						isDeleteAll: self.formData.isDeleteAll,
						jobRelatedId: self.formData.backupJob.jobId,
						jobType: self.formData.backupJob.jobType,
						jobClientId: self.formData.backupJob.clientId,
						gnsPath: self.formData.backupJob.gnsPath
					}
					if (self.formData.isDeleteAll) {
						oData.copies = [];
					}else {
						if (self.formData.copies.length == 0) {
							alertSrv.set("", $translate.instant('aws.backupService.backupDataClean.create.pleaseSelectTimepoints'), "error", 3000);
							return;
						}
						oData.copies = self.formData.copies;
					}
					var headers = {};
					if(localStorage.managementRole=='2' && localStorage.selectedAllDomain==1){
						headers = {
							project_id: localStorage.defaultProjectUid,
							domain_id: localStorage.defaultdomainUid,
						}
					}
					dataCleanSrv.createBackupDataClean(oData, headers).then(function () {
						self.$dismiss();
						$route.reload();
					});
				}else {
					alertSrv.set("", $translate.instant('aws.backupService.backupDataClean.create.checkJobName'), "error", 3000);
					return;
				}
			});
		}else {
			self.submitted = true;
		}
    }

}]);
