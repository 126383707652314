var tableService = angular.module("sharesSrv",[]);
tableService.service('sharesSrv',  function($rootScope, $http){
 return {
    /******文件存储 API 定义******/
    getSharesList: function() {
      return $http({
          method: "get",
          url: "awstack-resource/v1/shares/list"
      });
    },
    getSharesTypeList: function() {
      return $http({
          method: "get",
          url: "awstack-resource/v1/shares/type/list"
      });
    },
    getZone:function(){
        //return backendSrv.get("awstack-resource/v1/os-availability-zone/");
        return $http({
          method:"GET",
          url:"awstack-resource/v1/os-availability-zone/"
      })
    },
    createShares: function(data) {
      return $http({
          method: "post",
          url: "awstack-resource/v1/shares/create",
          data:data
      });
    },
    updateShares: function(data) {
      return $http({
          method: "put",
          url: "awstack-resource/v1/shares/update",
          data:data
      });
    },
    delete:function(fsName) {
    	return $http({
    		method:'delete',
    		url:"awstack-resource/v1/shares" + '/delete/' + fsName,
    		headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
    	});
    },
    shareRuleList:function(shareId){
      return $http({
        method:"GET",
        url:"awstack-resource/v1/shares/shareRule/list/" + shareId
     })
    },
    addShareRules: function(data) {
      return $http({
          method: "post",
          url: "awstack-resource/v1/shares/shareRule/create",
          data:data
      });
    },
    getSharesInfo:function(shareId){
      return $http({
        method:"GET",
        url:"awstack-resource/v1/shares/info/" + shareId
     })
    },
    deleteRule:function(ruleId,shareId) {
    	return $http({
    		method:'delete',
    		url:"awstack-resource/v1/shares" + '/deleteRule/' + ruleId + "/" + shareId,
    		headers:{ 'Content-Type': 'application/json;charset=UTF-8'},
    	});
    },
  };
});
