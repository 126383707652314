
export function vmCloneSrv($http){
    return {
        getProject:function() {
            return $http({
                method: "GET",
                url: "/awstack-user/v1/user/domains/projects"
            })
        },
        getSecurityGroups:function(data) {
            return $http({
                method: "get",
                url: `/awstack-manage/v2/security-groups?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
                headers: {
                    "domain_name":encodeURI(data.domain_name),
                    "domain_id":data.domain_id,
                    "project_name":encodeURI(data.project_name),
                    "project_id":data.project_id
                }
            })
        },
        getExtNets: function(data) {
            return $http({
                method: "get",
                url: "/awstack-resource/v1/extnets",
                headers: {
                    "domain_name":encodeURI(data.domain_name),
                    "domain_id":data.domain_id,
                    "project_name":encodeURI(data.project_name),
                    "project_id":data.project_id
                }
            });
        },
        getProjectNetwork: function(data) {
            return $http({
                method: "get",
                url: "/awstack-resource/v1/projectNetworks",
                headers: {
                    "domain_name":encodeURI(data.domain_name),
                    "domain_id":data.domain_id,
                    "project_name":encodeURI(data.project_name),
                    "project_id":data.project_id
                }
            })
        },
        cloneVmService:function(data) {
            return $http({
                method: "post",
                url: "/awstack-resource/v1/clone/server",
                data: data
            })
        }
    }
}
vmCloneSrv.$inject = ["$http"];