const en_US = {
    "aws": {
        "menu": {
            "resource-enhance-host-add":"Binding a Cloud Host",
            "resource-enhance-host-start":"start",
            "resource-enhance-host-forbid":"forbidden",
            "resource-enhance-host-unbind":"unbundle",
            "resource-enhance-host-recover":"recover",
            "resource-enhance-monitor-add":"add",
            "resource-enhance-monitor-update":"edit",
            "resource-enhance-monitor-start":"start",
            "resource-enhance-monitor-forbid":"forbidden",
            "resource-enhance-monitor-delete":"delete",
            "resource-enhance-page-add":"add",
            "resource-enhance-page-update":"edit",
            "resource-enhance-page-delete":"delete",
            "enhanceconfig":"Enhanced load balancing",
            "Resource_loadBalancing":"load balancing",
            "Resource_enhance":"Enhanced load balancing",
            "resource-fileStorage-addfileStorage": "New",
            "resource-fileStorage-updatefileStorage": "Edit",
            "resource-fileStorage-quotafileStorage": "Quota",
            "resource-fileStorage-delfileStorage": "Delete",
            "Resource_Storage_CSP": "file storage",
            "label-vm-add": "New",
            "label-vm-bind": "Bind",
            "label-vm-unbind": "Unbind",
            "label-vm-update": "Edit",
            "label-vm-delete": "Delete",
            "resource-vm-settingHa": "Set high availability",
            "resource-vm-changeLabel": "Edit tags",
            "labelModule": "Tag management",
            "certificate-add": "New",
            "certificate-update": "Edit",
            "certificate-del": "Delete",
            "loadbalancingcertificate": "load balancing certificate",
            "resource-objectStorage-create": "New",
            "resource-objectStorage-public": "Publicization",
            "resource-objectStorage-private": "Private",
            "resource-objectStorage-del": "Delete",
            "resource-shares-create": "New",
            "resource-shares-edit": "Edit",
            "resource-shares-del": "Delete",
            "resource-mkImg-uploadImage": "Registered Image",
            "resource-mkImg-openConsole": "Console",
            "resource-security-template-del": "Delete",
            "resource-security-template-edit": "Edit",
            "resource-security-template-create": "New",
            "resource-images-download": "Download",
            "Resource_Storage_Shares": "Resource Storage Shares",
            "System_SystemSetting_ObjectStorageSetting": "Object storage Settings",
            "System_SystemSetting_ObjectStorageUsers": "Object Storage User",
            "Resource_Object_Storage": "Object Storage",
            "System_Cephpg": "Ceph storage pool PG ratio management",
            "Resource_Storage_RegularSnap": "Planning Tasks",
            "Elastic_Expansion": "Elastic Expansion",
            "System_Multiplenet": "Cloud Platform Physical Network Interoperability",
            "Cvm_Scalpolicy": "Expansion Policy",
            "Cvm_Extension": "Cluster Management",
            "privatecloud": "PrivateCloud",
            "tencentcloud": "TencentCloud",
            "alicloud": "AliCloud",
            "aliyun": "Aliyun",
            "aliyun_overview": "General",
            "aliyun_asset": "Cloud Server",
            "aliyun_ecs": "Cloud Server",
            "aliyun_images": "Mirror image",
            "aliyun_resourceGroup": "Resource Group",
            "aliyun_deploymentSet": "Deployment set",
            "aliyun_volumes": "Storage and Snapshots",
            "aliyun_disk": "Cloud disk",
            "aliyun_snapshot": "Snapshot",
            "aliyun_network": "Network and Security",
            "aliyun_keyPair": "SSH key",
            "aliyun_securityGroup": "Safety Section",
            "aliyun_eips": "Flexible Public Network IP",
            "aliyun_vpc": "VPC",
            "aliyun_routerTable": "Routing table",
            "maas": "Physical machine",
            "maas_manage": "Physical Resource Management",
            "maas_overview": "General",
            "maas_images": "Physical machine image",
            "maas_network": "Network",
            "maas_reginstan": "Registered Physical Machine",
            "maas_apply": "Application for physical Machine",
            "aws": "AWS Cloud Services",
            "aws_asset": "EC2",
            "aws_ecs": "EC2 instance",
            "aws_images": "Mirror image",
            "aws_disk": "Roll",
            "aws_subnet": "Sub",
            "aws_gateway": "Internet gateway",
            "aws_snapshot": "Snapshot",
            "aws_network": "Network and Security",
            "aws_keyPair": "Key pair",
            "aws_securityGroup": "Safety Section",
            "aws_eips": "Flexible Public Network IP",
            "aws_vpc": "VPC",
            "aws_router": "Routing table",
            "Resource": "Resource",
            "Monitoring": "Monitoring",
            "User": "User",
            "System": "System",
            "System_reportForms": "Statistical report",
            "Log": "Log",
            "System_SystemSetting": "System Setting",
            "System_Configuration": "System Configuration",
            "System_ConfigManagement": "Configuration Management",
            "List": "List",
            "Flowing": "Flowing",
            "bigmem": "Big Memory Management",
            "sriov": "SR-IOV Network Management",
            "dpdk": "DPDK Management",
            "nettype": "Net Type Management",
            "statisticsReport": "statisticsReport",
            "Resource_backupRecovery": "Backup Recovery",
            "Resource_backupRecovery_Strategy": "Backup Strategy",
            "Resource_backupRecovery_Rbackups": "Backup",
            "Resource_backupRecovery_Recovery": "Backup Recovery",
            "Resource_Instance": "Instance",
            "Resource_Image": "Image",
            "Resource_Storage": "Storage",
            "Resource_physicalMac": "Physical machine",
            "Resource_Storage_Snapshot": "Snapshot",
            "Resource_Storage_Backups": "Back up",
            "Resource_Network": "Network",
            "Resource_Security": "Security",
            "Resource_Network_Switch": "Private Network",
            "Resource_Network_Router": "Router",
            "Resource_Network_Physicalnetworks": "Physical Networks",
            "Resource_Network_Bandwidth": "Bandwidth",
            "Resource_Network_Floatingip": "Floatingip",
            "Resource_Security_Firewall": "Firewall",
            "Resource_Security_Template": "Security Template",
            "Resource_Security_Keypair": "Keypair",
            "Resource_compute": "Computing",
            "Resource_VPN": "IPSec VPN",
            "Resource_CloudDrive_Qos": "Cloud Drive Qos",
            "Resource_compute_affinity": "Instance Group",
            "Resource_compute_ports": "Interface",
            "Monitoring_MOverview": "Overview",
            "Monitoring_ResourceMonitor": "ResourceMonitor",
            "Monitoring_ServiceMonitor": "ServiceMonitor",
            "Monitoring_MonitorStrategy": "MonitorStrategy",
            "Monitoring_Alarm": "Alarm",
            "Resource_Network_netTopo": "Network Topo",
            "Monitoring_NetworkTopology": "NetworkTopology",
            "Monitoring_Report": "Report",
            "Resource_Network_Loader": "Loadbalance",
            "Monitoring_ResourceMonitor_PhysicalMachine": "PhysicalMachine",
            "Monitoring_ResourceMonitor_VirtualMachine": "VirtualMachine",
            "Monitoring_ResourceMonitor_CephStorage": "CephStorage",
            "Monitoring_ResourceMonitor_SqlDatabase": "Temporal database",
            "Monitoring_Alarm_AlarmEvent": "AlarmEvent",
            "Monitoring_Alarm_AlarmSetting": "AlarmSetting",
            "Monitoring_Alarm_AlarmTemplate": "AlarmTemplate",
            "Monitoring_Alarm_ContactGroup": "ContactGroup",
            "resource-vm-settingHa":"高可用",
            // vgpu
            "vgpu": "vGPU",
            "gpu": "Physical GPU",
            //增加硬件监控菜单
            "Monitoring_Hardware": "Hardware Monitoring",
            "Monitoring_Hardware_server": "Server",
            "Monitoring_Hardware_Switch": "Switch",
            "Monitoring_Hardware_UPS": "UPS",

            "User_PermitUser": "PermitUser",
            "User_Privilege": "Privilege",
            "User_Department": "Department",
            "User_Organization": "Organization",
            "User_Project": "Project",
            "System_Flavor_Flavor": "Flavor",
            "System_Flavor_Aggregate": "Aggregate",
            "System_Hypervisor": "Hypervisor",
            "System_Quota": "Quota",
            "System_Node_NodeConfig": "NodeConfig",
            "System_Node_Cluster": "Cluster",
            "Log_OperationLog": "OperationLog",
            "Log_login": "LoginLog",
            "Log_SystemLog": "SystemLog",
            "Log_LogAnalysis": "LogAnalysis",
            "System_SystemSetting_CloudSetting": "CloudSetting",
            "System_SystemSetting_MailServerSetting": "MailServer",
            "System_SystemSetting_WechatSetting": "WeChat",
            "System_SystemSetting_backup": "System Backup",
            "general": "General Setting",
            "Resource_COverview": "Overview",
            "Resource_Network_Loadbalancer": "Loadbalancer",
            "loadbalancing": "Load balancing",
            "Flowing_FlowTask": "FlowTask",
            "Flowing_FlowApply": "FlowApply",
            "Flowing_FlowProgress": "FlowProgress",
            "List_Lists": "Lists",
            "List_ListApplication": "ListApplication",
            "PublicCloud": "PublicCloud",
            "List_Applied": "Applied",
            "List_MyAll": "My Approval",
            "Resource_Storage_Volume": "Volume",
            "SOverview": "Overview",
            "System_SystemSetting_AccessPolicy": "AccessPolicy",
            "Resource_Image_UploadImage": "Image Management",
            "Resource_Image_MakeImage": "MakeImage",
            "System_SystemSetting_License": "Software Activation",
            "System_logoSetting": "Logo Setting",
            "Resource_Recycle": "Recycle",
            "Resource_Recycle_vm": "Cloud hosting",
            "Resource_Recycle_cs": "Cloud drive",
            "System_Flavor": "Flavor",
            "System_About": "About",
            "System_Node": "Node",
            "Resource_Network_NetFirewall": "NetFirewall",
            "Resource_Loader": "Loadbalancers",
            "openstackService": "Openstack Service",
            "mysql": "Mysql",
            "rabbitmq": "RabbitMQ",
            "memcached": "Cache",
            "routermonitor": "router",
            "K8s_Clusters": "Container cluster",
            "K8s_Services": "Container service",
            "K8s": "Container",
            "K8s_Projects": "Mirror service",
            "Global_Setting": "setting",
            "Password_Modify": "password setting",
            "Storage_Management": "Storage Management",
            "Storage_Device": "Storage Device",
            "Storage_Features": "Storage Features",
            "Volume_Type": "Volume Type",
            "monitor_MonCeph": "Monitor Ceph",
            "Bill_Management": "Bill",
            "Bill_Consumeview": "Bill Consumeview",
            "Bill_Resourceprice": "Bill Resourceprice",
            "System_Operation": "Maintenance Operation",
            "System_Operation_Preferences": "Preferences Setting",
            "System_Operation_Aggregate": "Instance Collection",
            "System_Operation_Node": "Node Management",
            "System_Operation_CloseSaas": "Close Saas",
            "System_Operational": "Operation Management",
            "System_Operational_Flavor": "Flavor",
            "System_Operational_Quota": "Quotas",
            "System_SystemSetting_grade": "saas update",
            "System_Storage": "Storage Management",
            "System_ Operational_StorageManager": "Storage Management",
            "System_ Operational_Datacluster": "Datacluster Management",
            "System_ Operational_Networksmanage": "Networks Management",
            "System_SystemSetting_About": "About",
            "System_Operational_Bandlimit": "Limit Band",
            "System_Bandlimit": "Bandwidth Limit",
            "Monitoring_ResourceMonitor_Virtualnetwork": "Virtual Network",
            "System_Physical_Conductor": "Nanotubes physical Machine",
            "System_Transmission_Mag": "Transmission Management",
            "System_Function_Manage": "Functiona Manage",
            "System_SystemSetting_Systeminspection": "System Inspection",
            "qcloud": "Qcloud",
            "qcloud_Server": "Qcloud Server",
            "qcloud_Survey": "Qcloud Survey",
            "qcloud_Host": "Qcloud Host",
            "qcloud_Image": "Qcloud Image",
            "qcloud_Disk": "Qcloud Disk",
            "qcloud_Snapshot": "Snapshot",
            "qcloud_SHHkey": "SHH key",
            "qcloud_Securitygroup": "Security Group",
            "qcloud_Elasticip": "Elastic IP",
            "qcloud_Specialhost": "Dedicated Host",
            "qcloud_loadBalance": "Load Balance",
            "qcloud_LBInstanceList": "LB Instance List",
            "qcloud_Database": "Data Base",
            "qcloud_Instancelist": "Instance List",
            "qcloud_Tasklist": "Task List",
            "qcloud_Parametertemplate": "Parameter Template",
            "qcloud_cacheRedis": "Cache Redis",
            "qcloud_Taskmanagement": "Task Management",
            "qcloud_VPC": "VPC",
            "qcloud_Privatenetwork": "Private Network",
            "qcloud_Subnet": "Subnet",
            "qcloud_Routingtable": "Routing Table",
            "qcloud_NATgateway": "NAT Gateway",
            "qcloud_dc": "Dedicated Line access",
            "qcloud_dc_dc": "Physical dedicated line",
            "qcloud_dc_passageway": "Dedicated Channel",
            "qcloud_dc_gateway": "Dedicated Line Gateway",
            "vMware": "VMware",
            "vMware_menu": "Menu",
            "vMware_Datacenter": "Data Center",
            "vMware_Host": "Host",
            "vMware_colony": "Colony",
            "vMware_virtualmachine": "Virtual Machine",
            "User_overView": "Overview",
            "allMenu": "All Menu",
            "openStack": "openStack",
            "openStack_view": "openStack Overview",
            "PhysicalResource": "Bare metal resource management",
            "Physical_overview": "Overview",
            "Physical_Instance": "bare metal",
            "Physical_image": "image",
            "Physical_network": "network",
            "System_ Operational_StorageManager": "Storage Management",
//          "Resource_CloudDrive_Qos": "Storage Pool QoS",
            "Monitoring_ResourceMonitor_Resview": "Overview",
            "System_Operational_ResMetering": "Resource Metering",
            "System_Operational_BillingManagement": "Billing Management",
            "cephView": "Ceph Management",
            "ticketReports": "Ticket Reports",
            "K8s":"container management",
            "K8s_Clusters":"container cluster",
            "K8s_Services":"container service",
            "K8s_Projects":"mirror service",
            "Image_Management": "Mirror Library Migration",
            "Resource_Network_netTopo":"Network topology",
            "System_SystemSetting_Maxscreen": "Max Screen",
            "PaaS": "PaaS services",
            "Alauda_alRegister": "Deployment",
            //"Alauda_overview":"Overview",
            "Alauda_overview": "TKE Enterprise Console",
            "Alauda_calculation": "Computing",
            "Alauda_configure": "Configuration",
            "Alauda_networks": "Network",
            "Alauda_storage": "Storage",
            "Alauda_colony": "Cluster",
            "Alauda_others": "Other resources",
            "Alauda_administrator": "Administrator Settings",
            "Alauda_deploy": "Deployment",
            "Alauda_daemon": "Daemon set",
            "Alauda_stateful": "Stateful copy set",
            "Alauda_replica": "Copy set",
            "Alauda_pod": "Container group",
            "Alauda_configmap": "Configuration Fields",
            "Alauda_secret": "Secret Dictionary",
            "Alauda_service": "Service",
            "Alauda_ingress": "Access rights",
            "Alauda_networkpolicy": "Network Strategy",
            "Alauda_persistentvolumeclaim": "Persistent Volume Declaration",
            "Alauda_persistentvolume": "Persistent volume",
            "Alauda_storageclass": "Storage Class",
            "Alauda_node": "Node",
            "Alauda_namespace": "Namespace",
            "Alauda_othersspaced": "Namespace related Resources",
            "Alauda_clustered": "Cluster related Resources",
            "Alauda_roles": "Role",
            "Alauda_bindings": "Role binding",
            "Alauda": "TKE Enterprise edition",
            "ZhiYun": "Weave cloud",
            "ZY_entrance": "Login entry",
            //TBase
            "TBase": "Tbase",
            "TBase_tbClusterManage": "Cluster Management",
            "TBase_tbNodeManages": "Node Management",
            "TBase_tbNodeManage": "Node List",
            "TBase_tbNodeGroup": "Node Group List",
            "TBase_tbConfigManagement": "Configuration Management",
            "TBase_tbBackupSettings": "Backup Management",
            "TBase_tbBackupSetting": "Backup Settings",
            "TBase_tbBackupCheck": "Backup Check",
            "TBase_tbClusterMonitor":"Indicator monitoring",
            "TBase_tbClusterMonitor": "Cluster Monitoring",
            "TBase_tbNodeMonitor": "Node Monitoring",
            "TBase_tbVolumeMonitor": "Capacity Monitoring",
            "TBase_tbAlarmLists": "Alarm List",
            "TBase_tbAlarmRecords": "Alarm Record",
            "TBase_tbAlarmUserss": "Alarm Management",
            "TBase_tbAlarmUsers": "Alarm object",
            "TBase_tbAlarmMethods": "Alarm Channel",
            "TBase_tbSystemInfos": "System Settings",
            "TBase_tbSystemInfo": "System Information",
            "TBase_tbLicenseInfo": "Certificate Information",
            "TBase_TStudio": "TStudio",
            //腾讯云安全
            "Tsafe":"Tencent Cloud Security",
            "Tsafe_yujie":"Royal World",
            "Tsafe_tianmu":"Canopy",
            "Tsafe_waf":"WAF",
            "Tsafe_yujian":"soc",
            "Tsafe_sec":"Cloud security",
            "Tsafe_fm":"Fortress machine",
            "Tsafe_ngfw":"Next generation firewall",
            //"TBase_tbUserList":"User Management",
            //tdsql
            "relationaManagelTdsql": "Relational Database TDSQL",
            "DatabaseManage": "Database Management",
            "relationalTdsql": "Relational Database TDSQL",
            "DatabaseManage":"Database Management",
            "CTSDB_instances": "CTSDB time Series Database",
            "TDSQL": "TDSQL",
            "TDsql_home": "Cluster Overview",
            "TDsql_instance_Manage": "Instance management",
            "TDsql_resource": "Resource management",
            "TDsql_resource_device": "Equipment Resources",
            "TDsql_resource_spec": "Model Specifications",
            "TDsql_resource_idc": "IDC information",
            "TDsql_monitor_mysql": "DB Summary Monitoring",
            "TDsql_proxy_base": "Gateway Summary Monitoring",
            "TDsql_vip": "Access Layer Management",
            "TDsql_vip_lvsgroup": "VIP management",
            "TDsql_vip_lvsmachine": "LVS resource monitoring",
            "TDsql_monitor": "Scheduling and Management System",
            "TDsql_monitor_zookeeper": "They are monitoring",
            "TDsql_monitor_scheduler": "The Scheduler monitoring",
            "TDsql_monitor_manager": "Manager control",
            "TDsql_monitor_ossserver": "OSS- Service Monitoring",
            "TDsql_monitor_ossjob": "OSS- Mission Flow",
            "TDsql_monitor_osslog": "OSS- Operational Audit",
            "TDsql_monitor_hdfs": "HDFS monitoring",
            "TDsql_monitor_syncjob": "Multi-source synchronous Monitoring",
            "TDsql_monitor_kafka": "Kafka monitoring",
            "TDsql_osswf": "Operation and Maintenance Operation Log",
            "TDsql_alarm": "Alarm analysis",
            "TDsql_alarm_search": "Alarm Enquiry",
            "TDsql_alarm_config": "Warning Strategy",
            "TDsql_alarm_except": "Exception Query",
            "TDsql_alarm_global": "Alarm Management across Clusters",
            "TDsql_cluster_manage": "Cluster Management",
            "TDsql_business": "Business Operations",
            "TDsql_business_user_database_apply": "Database Permission Application",
            "TDsql_business_user_dbcluster_grant": "Case Manager Management",
            "TDsql_sys": "System Management",
            "TDsql_sys_user": "User Management",
            "TDsql_sys_role": "Role permissions",
            "TDsql_sys_log": "System Operation Log",
            "TDsql_sys_version": "System Version",

            "TDsql_capacity": "Capacity statistics",
            "TDsql_vipgroup": "VIP Group Management",
            "TDsql_vipdns": "The DNS query",
            "TDsql_monitordns": "DNS monitoring",
            "TDsql_sync": "Data synchronization",
            "TDsql_dcnjob": "DCN synchronization",
            "TDsql_syncjob": "Multi-source synchronization",
            "TDsql_clusteradmin": "Cluster Leader Management",
            "TDsql_upgrade": "System Upgrade",
            "TDsql_autotest": "Automated testing",


            "CTSDB": "CTSDB",
            "CTSDB_instancesList": "Instance list",
            "CTSDB_operation": "Operation and Maintenance Management",
            "CTSDB_operation_instancesManage": "Instance management",
            "CTSDB_operation_processManage": "Process Management",
            "CTSDB_equipmentManage": "Equipment Management",
            "CTSDB_instancesMonitor": "Instance monitoring",
            "TBDS_entrance": "Login entry",
            "TBds": "TBDS Big Data Suite",
            "System_Paas_Services": "Paas authorization",


            /*CMQ消息队列中间件 */
            "CMQ": "Message queue CMQ",
            "CMQ_Server": "Queue service",
            "CMQ_Topic": "Topic Subscription",
            "CMQ_Zone": "Warehouse Management",
            "CMQ_Operate": "Operation Center",
            "CMQ_Cluster": "Cluster Management",
            "CMQ_Signaling": "Signalling Service Management",
            "CMQ_Access": "Access Management",
            "CMQ_Publisher": "PushServer management",
            "CMQ_Queue": "Queue Management",
            "CMQ_ManagerTopic": "Subject management",

            /*腾讯云安全 */ 
            "Tsafe":"Tencent Cloud Security",
            "Tsafe_menu":"Menu",
            "Tsafe_yujie":"Royal World",
            "Tsafe_tianmu":"Canopy",
            "Tsafe_yujian":"soc",
            "Tsafe_waf":"WAF",
            "Tsafe_sec":"Cloud security",
            "Tsafe_fm":"Fortress machine",
            "Tsafe_ngfw":"Next generation firewall",

            /*云镜*/
            "CloudSecurity": "Cloud mirror",
            "CMirror_entrance": "Login entry",
            "CMirror_overview": "Overview",
            "CMirror_hostManage": "Host Management",
            "CMirror_hostList": "Host List",
            "CMirror_push": "Command push",
            "CMirror_IntrusionCheck": "Intrusion Detection",
            "CMirror_malware": "Trojan horse File",
            "CMirror_login": "Login Audit",
            "CMirror_bruteforce": "Password cracking",
            "CMirror_LoopholeManage": "Vulnerability Management",
            "CMirror_vul": "Security breach",
            "CMirror_components": "Knowledge base configuration",
            "CMirror_vul_scan_task": "Task configuration",
            "CMirror_sysManage": "System Management",
            "CMirror_upgrade": "Upgrade Management",
            /*coc织云*/
            "COC": "Weave cloud",
            "COC_manage": "Business Entry",
            "COC_CMDB": "CMDB",
            "COC_CMDB_server": "Server",
            "COC_CMDB_networkDevice": "Network Equipment",
            "COC_CMDB_idc": "IDC",
            "COC_CMDB_idcExport": "IDC export",
            "COC_CMDB_idcLine": "IDC line",
            "COC_CMDB_customCategory": "Custom CI",
            "COC_moduleManage": "Business",
            "COC_monitor": "Monitor",
            "COC_monitor_server": "Server Performance Monitoring",
            "COC_monitor_netdevice": "Network Equipment",
            "COC_monitor_netoutpoint": "IDC export",
            "COC_monitor_netspecialLine": "IDC line",
            "COC_monitor_middleware": "Middleware Monitoring",
            "COC_monitor_selfdefineMonitor": "Custom Monitoring",
            "COC_monitor_customBoard": "Custom Kanban",
            "COC_alarm": "Warning",
            "COC_alarm_overview": "Alarm Overview",
            "COC_alarm_search": "Alarm Enquiry",
            "COC_alarm_startegy": "Warning Strategy",
            "COC_alarm_shield": "Alarm shield",
            "COC_toolmarket": "Operating Platform",
            "COC_toolmarket_toollist": "My tools",
            "COC_toolmarket_publicAll": "All tools",
            "COC_toolmarket_publicInit": "Initial chemical tool",
            "COC_toolmarket_publicDiagnose": "Diagnostic tools",
            "COC_toolmarket_publicRepaire": "Maintenance tools",
            "COC_toolmarket_publicDown": "Off the shelf Tool",
            "COC_toolmarket_flowlist": "Arrangement management",
            "COC_toolmarket_schedulelist": "Timed task",
            "COC_toolmarket_loglist": "Execution Record",
            "COC_packageList": "Package List",
            "COC_cloud": "Cloud Management Platform",
            "COC_cloud_server": "Host Resource Pool",
            "COC_cloud_users": "Cloud Account Management",
            "COC_tenantuser": "Access Management",
            "COC_tenantuser_userlist": "User Management",
            "COC_tenantuser_grouplist": "User Group Management",
            "COC_tenantuser_authlist": "Authority Management",
            "COC_passwordlib": "Password base",
            "COC_operationlog": "Operation Log",
            /*tbds*/
            "TBDS": "TBDS",
            "TBDS_tdAccess": "Data in",
            "TBDS_tdAccess_myApply": "Data in",
            "TBDS_hippo_access": "The hippo management",
            "TBDS_tdMertrics": "Progress Monitoring",
            "TBDS_tdAccess_wherehows": "Blood analysis",
            "TBDS_easycount": "Real-time computing",
            "TBDS_easycount_taskmanage": "Task management",
            "TBDS_easycount_dbmanage": "Library Table Template Management",
            "TBDS_workflow": "Workflow",
            "TBDS_workflow_canvas": "Workflow",
            "TBDS_workflow_list": "Task management",
            "TBDS_workflow_server": "Server Configuration",
            "TBDS_report": "Data presentation",
            "TBDS_report_list": "Self-report",
            "TBDS_supersql": "Data analysis",
            "TBDS_supersql_hive": "Data Interaction",
            "TBDS_data": "Data Assets",
            "TBDS_data_dbmanage": "Library Table Management",
            "TBDS_data_meta": "Data affinity",
            "TBDS_data_map": "Data Extraction",
            "TBDS_operation": "Operation and Maintenance Center",
            "TBDS_operation_overview": "Overall Overview",
            "TBDS_operation_components": "System Operation and Maintenance",
            "TBDS_operation_accesscontrol": "Access Management",
            "TBDS_operation_basefs": "Document Management",
            "TBDS_operation_moitor": "Monitoring alarm",
            "TBDS_platform": "Platform Management",
            "TBDS_platform_project": "Project Management",
            "TBDS_platform_resource": "Resource management",
            "TBDS_platform_usermanage": "User Management",
            "TBDS_platform_system": "System Settings",
            "TBDS_ml": "Machine learning",
            "TBDS_ml_engineer": "My Project",
            "TBDS_ml_others": "Collaborative engineering",
            "TBDS_ml_modelService": "Model Service",
            "TBDS_ml_modelManage": "Model management",
            "TBDS_ml_resourceManage": "Resource management",
            "TBDS_ml_moduleManage": "Module Management",
            "TBDS_ml_adminControl": "Control console",
            //tsf 微服务框架
            "TSF": "TSF",
            "TSF_Overview": "Overview",
            "TSF_Resource": "Resource",
            "TSF_Cluster": "Cluster",
            "TSF_Namespace": "Namesapce",
            "TSF_Deploy": "Deploy Group",
            "TSF_Application": "App Center",
            "TSF_AppManagement": "App Manager",
            "TSF_Service": "Service Manager",
            "TSF_Configure": "Setting",
            "TSF_AppConfig": "App Setting",
            "TSF_GlobalConfig": "Global Setting",
            "TSF_Template": "Template",
            "TSF_ConfigFile": "Setting File",
            "TSF_LogConfig": "Log Setting",
            "TSF_Gateway": "TSF Gateway",
            "TSF_GatewayList": "Gateway Setting",
            "TSF_GatewayGroup": "Gateway Groups",
            "TSF_Maintenance": "Maintenance Center",
            "TSF_LogSearch": "Log Search",
            "TSF_Transaction": "Transaction",
            "TSF_Topology": "Service Topology",
            "TSF_Trace": "Trace Search",
            "TSF_Alarm": "Alarming",
            "TSF_Scalable": "Elastic Expansion",
            "TSF_Delivery": "Delivery Center",
            "TSF_Image": "Images",
            "TSF_Package": "Software",
            "TSF_ServiceTemplate": "Service Orchestration",
            "TSF_Operate": "Operation Management",
            "TSF_ManagerBase": "Basic Module",
            "TSF_ManagerZone": "Available Zone",
            "TSF_OPSServer": "Application Service",
            "TSF_ManagerParam": "Parameter Settings",
            "TSF_ManagerGuide": "Deployment Guidelines",
            "TSF_License": "License Management",
            "MEMResource": "Resource",

            //天眼云镜
            "SkyCloudSecurity": "Sky eye cloud mirror",
            "Sky_entrance": "Tenant Console",
            "Sky_entrance_tenant": "Sky eye cloud mirror",
            "Sky_entrance_manage": "Administrator Console",
            "Security": "system Security",
            "Security_survey": "overview",
            "Security_System_SystemSetting_AccessPolicy": "security management",
            "Audit": "system audit",
            "Audit_survey": "overview",
            "Audit_Log_OperationLog": "audit log operation",
            "System_SystemSetting_AD": "AD Service Setting",
            //CSP存储
            "CSP": "CSP storage",
            "CSP_overview": "General",
            "CSP_object_storage": "Object storage",
            "CSP_object_storage_data_access": "Storage Gateway",
            "CSP_object_storage_bucket": "Bucket Management",
            "CSP_object_storage_task": "Upload Task Management",
            "CSP_object_storage_elasticsearch": "Object Full Text Search",
            "CSP_object_storage_settings": "Object Storage Configuration",
            "CSP_file_storage": "File Storage",
            "CSP_file_storage_meta": "Metadata node",
            "CSP_file_storage_mount_fuse": "Mount Management",
            "CSP_file_storage_quota": "Quota Management",
            "CSP_file_storage_meta_load_balance": "Metadata Load Management",
            "CSP_file_storage_mds_performance": "Metadata Performance Query",
            "CSP_cluster": "Resource management",
            "CSP_cluster_ClusterMonitor": "Cluster Monitoring management",
            "CSP_cluster_storage_pool": "Storage Pool Management",
            "CSP_cluster_host": "Node Management",
            "CSP_cluster_controller": "Control Node Management",
            "CSP_monitor": "Monitoring alarm",
            "CSP_mail": "Mail Alert",
            "CSP_logs": "Log management",
            "CSP_users": "User Management",
            //告警管理
            "monitor-alarmSetting-create": "New",
            "monitor-alarmSetting-edit": "Edit",
            "monitor-alarmSetting-copy": "Copy",
            "monitor-alarmSetting-disable": "Disabled",
            "monitor-alarmSetting-enable": "Enable",
            "monitor-alarmSetting-del": "Delete",
            "monitor-alarmTemp-create": "New",
            "monitor-alarmTemp-set": "Set rules",
            "monitor-alarmTemp-edit": "Edit",
            "monitor-alarmTemp-del": "Delete",
            "monitor-alarmGroup-create": "New",
            "monitor-alarmGroup-edit": "Edit",
            "monitor-alarmGroup-del": "Delete",
            //计算
            "resource-vm-create": "New",
            "resource-vm-startServer": "Start",
            "resource-vm-stopSerever": "Turn off",
            "resource-vm-conIsEnable": "Control console",
            "resource-vm-addNetwork": "Add network Card",
            "resource-vm-removeNetwork": "Remove network Card",
            "resource-vm-modifyFixdIp": "Modify private NETWORK IP",
            "resource-vm-bindfloatingIp": "Bind public IP",
            "resource-vm-removefloatIpDis": "Unplug public IP",
            "resource-vm-changeMac": "Modify MAC Address",
            "resource-vm-loadingDisk": "Load the cloud hard drive",
            "resource-vm-detachDisk": "Uninstall the Cloud Hard Drive",
            "resource-vm-createSnap": "Snapshot",
            "resource-vm-snapRollBack": "Snapshot rollback",
            "resource-vm-reboot": "Reboot",
            "resource-vm-delSever": "Delete",
            "resource-vm-editSever": "Edit hostname",
            "resource-vm-mkImg": "Make a mirror",
            "resource-vm-osHang": "Hang",
            "resource-vm-osUpHang": "Resume suspension",
            "resource-vm-osSuspend": "Pause",
            "resource-vm-osUpPause": "Resume suspension",
            "resource-vm-rebuild": "Reset state",
            "resource-vm-shutdown": "Forced shutdown",
            // "resource-vm-osUpgrade":"System Upgrade",
            "resource-vm-osUpgrade": "Adjust configuration",
            "resource-vm-osHotgrade": "Hot upgrade",
            "resource-vm-addAlarm": "Add an alarm",
            "resource-vm-manageCD": "Optical Disc Management",
            "resource-vm-editPsw": "Change password",
            "resource-vm-startOrder": "Startup Sequence Management",
            "resource-vm-details": "Cloud Host Details",
            "resource-vm-errorstart": "Virtual Machine Exception Management",
            "resource-vmgroup-create": "New",
            "resource-vmgroup-del": "Delete",
            "resource-vmgports-create": "New",
            "resource-vmgports-edit": "Edit",
            "resource-vmgports-del": "Delete",
            "resource-vmgports-load": "Load",
            "resource-vmgports-unload": "Uninstall and delete",
            "resource-vm-reset": "Reset state",
            "resource-vm-modifyBandwidth": "Adjust bandwidth",
            "resource-vm-stockTransfer": "Cloud Hard Drive Migration",
            "resource-vm-volumesQoS": "Disk QoS",
            "resource-vm-networkCardConfig": "Network Card Security Configuration",
            "resource-vm-osMigrate": "Heat transfer",
            "resource-vm-coldMigrate": "Cold migration",
            "resource-vm-ttEquiment": "Pass-through Device",
            "resource-vm-bindVgpu": "Binding vGPU",
            "resource-vm-unbindVgpu": "Unbound vGPU",
            "resource-vm-shelveServer":"shelve",
            "resource-vm-unShelveServerr":"unShelve",
            //镜像
            "resource-images-addImages": "Upload image",
            "resource-images-newInstance": "New Cloud Host",
            "resource-images-editImages": "Edit",
            "resource-images-delImages": "Delete",
            "resource-mkImg-newImage": "New image",
            "resource-mkImg-delImage": "Delete",
            //存储
            "resource-dataVol-create": "New",
            "resource-dataVol-newInstance": "New Cloud Host",
            "resource-dataVol-del": "Data Disk - Delete",
            "resource-dataVol-forceDelVol": "Forced deletion",
            "resource-dataVol-edit": "Data Disk - Edit",
            "resource-dataVol-backups": "Back up",
            "resource-dataVol-loadVol": "Load the cloud hard drive",
            "resource-dataVol-uninstallVol": "Uninstall the Cloud Hard Drive",
            "resource-dataVol-createSnap": "Create a snapshot",
            "resource-dataVol-expandVolSize": "Capacity",
            "resource-sysVol-expandVolSize": "System disk expansion",
            "resource-sysVol-details": "Cloud Hard Drive Details",
            "resource-sysVol-delSever": "System Disk - Delete",
            "resource-sysVol-edit": "System Disk - Edit",
            "resource-catchVol-delSever": "Cache disk - Delete",
            "resource-dataVolSnap-createVol": "Create a Cloud hard drive",
            "resource-dataVolSnap-del": "Delete",
            "resource-dataVolSnap-datails": "Cloud Hard Drive Details",
            "resource-dataVolSnap-compile": "Data disk editing",
            "resource-sysVolSnap-createVm": "New Cloud Host",
            "resource-sysVolSnap-createVol": "Create a Cloud hard drive",
            "resource-sysVolSnap-del": "Delete",
            "resource-sysVolSnap-details": "Cloud Hard Drive Details",
            "resource-sysVolSnap-compile": "system disk edit",
            "resource-sysVol-stockTransfer": "System Disk - Migration",
            "resource-dataVol-stockTransfer": "Data Disk - Migration",
            "resource-volQoS-create": "New",
            "resource-volQoS-relation": "Associated Storage Pool",
            "resource-volQoS-relieve": "Uncorrelating",
            "resource-volQoS-del": "Delete",

            //网络
            "resource-topp-edit": "Edit",
            "resource-privateNet-create": "New",
            "resource-privateNet-del": "Edit",
            "resource-privateNet-edit": "Delete",
            "resource-privateNet-details": "Details",
            "resource-privateNet-createSubnet": "New Subnet",
            "resource-privateNet-editSubnet": "Edit subnet",
            "resource-privateNet-delSubnet": "Delete subnet",

            "resource-outerNet-create": "New",
            "resource-outerNet-edit": "Edit",
            "resource-outerNet-del": "Delete",
            "resource-outerNet-subnet-create": "New Subnet",
            "resource-outerNet-subnet-edit": "Edit subnet",
            "resource-outerNet-subnet-del": "Delete subnet",

            "resource-router-create": "New",
            "resource-router-edit": "Edit",
            "resource-router-gateway": "Set up gateway",
            "resource-router-delgateway": "Remove gateway",
            "resource-router-del": "Delete",
            "resource-router-details": "Details",
            "resource-router-subnet-relateSubnet": "Association subnet",
            "resource-router-subnet-edit": "Edit",
            "resource-router-subnet-relieveSubnet": "Uncorrelated subnet",
            "resource-router-staticRoute-addRouter": "Add routing table",
            "resource-router-staticRoute-delRouter": "Delete routing Table",
            "resource-router-portFor-create": "New",
            "resource-router-portFor-del": "Delete",
            "resource-publicNet-apply": "Apply for Public IP",
            "resource-publicNet-bind": "Binding",
            "resource-publicNet-unbind": "Unbundling",
            "resource-publicNet-del": "Delete",
            "resource-cbd-create": "New",
            "resource-cbd-edit": "Edit",
            "resource-cbd-bindPublicIp": "Bind public IP",
            "resource-cbd-unbindPublicIp": "Unbind public IP",
            "resource-cbd-del": "Delete",
            "resource-cbd-routerDetails": "Router Details",
            "resource-cbd-createMonitor": "New listener",
            "resource-cbd-editMonitor": "Edit",
            "resource-cbd-enableMonitor": "Enable",
            "resource-cbd-disenableMonitor": "Disabled",
            "resource-cbd-delMonitor": "Delete",
            "resource-cbd-details": "Details",
            "resource-cbd-monitor-createEnd": "Sure",
            "resource-cbd-monitor-edit": "Edit",
            "resource-cbd-monitor-enable": "Enable",
            "resource-cbd-monitor-disabled": "Disabled",
            "resource-cbd-monitor-del": "Delete",
            "resource-vpn-create": "New",
            "resource-vpn-edit": "Edit",
            "resource-vpn-enable": "Enable",
            "resource-vpn-disable": "Disabled",
            "resource-vpn-del": "Delete",
            "resource-vpn-details": "Details",
            "resource-vpn-localSubnet-add": "Add local Subnet",
            "resource-vpn-localSubnet-del": "Delete local Subnet",
            "resource-vpn-pointNet-add": "Add target network",
            "resource-vpn-pointNet-del": "Delete target network",
            //备份恢复
            "Resource-backupRecovery-Strategy-add": "New Backup Strategy",
            "Resource-backupRecovery-Strategy-delete": "Delete",
            "Resource-backupRecovery-Rbackups-addBackup": "New Backup",
            "Resource-backupRecovery-Rbackups-delBackup": "Unbackup",
            "Resource-backupRecovery-Rbackups-createServer": "New Cloud Host",
            "Resource-backupRecovery-Rbackups-createVol": "New Cloud Drive",
            "Resource-backupRecovery-Rbackups-del": "Delete",
            "Resource-backupRecovery-Recovery": "Recovery",
            //安全
            "resource-firewall-create": "New",
            "resource-firewall-edit": "Edit",
            "resource-firewall-del": "Delete",
            "associateRouter-add-btn": "Associated Router",
            "associateRouter-del-btn": "Disable router",
            "resource-firewall-details": "Details",
            "resource-firewall-rules-create": "New",
            "resource-firewall-rules-enable": "Enable",
            "resource-firewall-rules-disable": "Disabled",
            "resource-firewall-rules-del": "Delete",
            "resource-keypairs-create": "New",
            "resource-keypairs-import": "Export",
            "resource-keypairs-del": "Delete",
            "resource-security-create": "New",
            "resource-security-edit": "Edit",
            "resource-security-details": "Safety Unit Details",
            "resource-security-del": "Delete",
            "resource-security-details-create": "New rule",
            "resource-security-details-del": "Delete",
            //弹性扩展
            "resource-scalpolicy-create": "New Extension Strategy",
            "resource-scalpolicy-del": "Delete",
            "resource-extension-create": "New Elastic Extension",
            "resource-extension-createcvm": "Add a Cloud Host",
            "resource-extension-del": "Delete",
            //备份恢复
            "resource-backupStrategy-create": "New Backup Strategy",
            "resource-backupStrategy-del": "Delete",

            "resource-systemBackup-addBackup": "System Disk Backup - New",
            "resource-systemBackup-releasebackup": "System Disk Backup - Abort",
            "resource-systemBackup-addvm": "System Disk Backup - New Cloud Host",
            "resource-systemBackup-addVolume": "System Disk Backup - New Cloud Disk",
            "resource-systemBackup-deletebackup": "System Disk Backup - Delete",

            "resource-dataBackup-addBackup": "Data Disk Backup - New",
            "resource-dataBackup-releasebackup": "Data Disk Backup - Undo",
            "resource-dataBackup-addVolume": "Data Disk Backup - New Cloud Hard Disk",
            "resource-dataBackup-deletebackup": "Data Disk Backup - Delete",

            "resource-systemDisk-recovery": "System Disk Recovery",
            "resource-dataDisk-recovery": "Data Disk Recovery",
            "monitor-alarmEvent-deal": "Processing",
            "monitor-alarmEvent-ignore": "Ignore",

            //回收站 云主机
            "resource-recycle-force": "Force virtual Machine deletion",
            "resource-recycle-clean": "Delete virtual Machine",
            "resource-recycle-reset": "Recovery",
            //回收站 云硬盘
            "resource-recyclecs-clean": "Delete cloud Disk",
            "resource-recyclecs-reset": "Recovery",

            //计划任务
            "system-planTask-addTask": "New Task",
            "system-planTask-edit": "Edit task",
            "system-planTask-del": "Delete task",
            //身份管理
            "identity-project-create": "New Project",
            "identity-project-edit": "Edit",
            "identity-project-del": "Delete",
            "identity-project-allow": "Assign users",
            "identity-users-create": "New User",
            "identity-users-bind": "Bind",
            "identity-users-untie": "Unbind",
            "identity-users-sync": "sync",
            "identity-users-edit": "Edit",
            "identity-users-del": "Delete",
            "identity-users-resetPassword": "Reset password",
            "identity-users-option": "Options",
            "identity-department-create": "New Department",
            "identity-department-edit": "Edit",
            "identity-department-del": "Delete",

            "identity-role-create": "New",
            "identity-role-edit": "Edit",
            "identity-role-del": "Delete",
            //操作日志
            "log-operate-exportAll": "Export all",
            "log-login-exportAll": "Export all",
            //资源计量
            "system-resBilling-view-leadout": "Metering View - Export",
            "system-resBilling-count-leadout": "Metrological Statistics - Export",
            "system-resBilling-UsageRate-leadout": "Resource Utilization - Export",
            "resource-vmgroup-search": "Search",
            "bill-amount-satistics": "Statistics",
            "bill-amount-satistics1": "Statistics",
            "bill-charge-search": "Query",
            "bill-charge-edit": "Edit",
            "bill-charge-add": "Add",
            "bill-charge-delete": "Delete",
            "bill-resource-search": "Consumption Search",
            "resource-vmgports-search": "Search",
            "identity-users-details": "List",
            "identity-project-details": "Details",
            "Report": "Report Statistics",
            "Report_Form": "Report Statistics",


            //TCS
            "TCS": "TCS",
            "TCS_overview_v": "Overview",
            "TCS_overview": "Overview",
            "TCS_platform_management": "Platform service",
            "TCS_platform_cluster_overview": "Cluster overview",
            "TCS_platform_cluster_management": "Cluster management",
            "TCS_platform_node_management": "Node management",
            "TCS_platform_pod_management": "Pod management",
            "TCS_cbs": "Cloud Drive (CBS)",
            "TCS_cbs_operation": "operational data",
            "TCS_cbs_operation_overview": "Overview",
            "TCS_cbs_operation_trend": "Operational Trends",
            "TCS_cbs_operation_user_resource": "User resource statistics",
            "TCS_cbs_management": "Cloud Disk Management",
            "TCS_cbs_snapshot_manage": "Snapshot management",
            "TCS_cbs_container_management": "container management",
            "TCS_cbs_management_format": "Specification Definition",
            "TCS_cbs_management_sub": "Cloud disk management",
            "TCS_cbs_snapshot_manage_list": "Snapshot list",
            "TCS_cbs_snapshot_manage_policy": "定期快照Periodic snapshot policy策略",
            "TCS_cbs_cluster_management": "Cluster management",
            "TCS_cbs_pool_monitor": "performance monitoring",
            "TCS_cbs_storage_model_management": "Storage model management",
            "TCS_cbs_storage_pool_management": "storage pool cluster",
            "TCS_cbs_snapshot_cluster": "Snapshot cluster",
            "TCS_cbs_zookeeper_cluster": "Zookeeper cluster",
            "TCS_cbs_user_resource_pool": "User resource pool",
            "TCS_cbs_performance_monitor": "Storage Pool Performance Monitoring",
            "TCS_cbs_node_xm_monitor": "Storage Node Performance Monitoring",
            "TCS_cbs_cloud_disk_monitor": "Cloud hard disk performance monitoring",
            "TCS_cbs_wlyp_monitor": "Physical hard disk performance monitoring",
            "TCS_cbs_task_management": "task management",
            "TCS_cbs_disk_task": "Cloud Disk Tasks",
            "TCS_cbs_snapshot_task": "snapshot task",
            "TCS_cbs_cloud_disk_qy_disk": "Cloud Disk Migration Tasks",
            "TCS_cbs_cccb_task": "Storage Pool Small Table Task",
            "TCS_cbs_wldisk_edit_task": "Physical Hard Disk Replacement Tasks",
            "TCS_cbs_cluster_yw_task": "Cluster operation and maintenance tasks",
            "TCS_cbs_event_alert": "event query",
            "TCS_cbs_sj_management": "Upgrade management",
            "TCS_cbs_event_alert_sub": "Alarm event",
            "TCS_cbs_event_cluster": "Cluster events",
            "TCS_cbs_pool_cluster_sj": "Storage Pool Cluster Upgrade",
            "TCS_cbs_snapshot_cluster_sj": "Snapshot cluster upgrade",
            "TCS_cbs_task_cluster_sj": "upgrade task",
            "TCS_monitor_center": "monitoring Center",
            "TCS_monitor_overview": "Monitoring overview",
            "TCS_monitor_selete": "Alarm query",
            "TCS_monitor_srategy": "Alert Policy",
            "TCS_monitor_even_selete": "event query",
            "TCS_monitor_shield": "Alarm masking",
            "TCS_monitor_srategy_model": "Policy Template",
            "TCS_monitor_message_rule": "Notification Rules",
            "TCS_monitor_cloud_index": "Cloud Product Metrics",
            "TCS_monitor_tcns_index": "TCNS Monitoring Panel",
            "TCS_monitor_set": "Monitoring settings",
            "TCS_image_house": "mirror repository",
            "TCS_image_public": "public warehouse",
            "TCS_image_private": "private repository",
            "TCS_cmdb": "Business CMDB",
            "TCS_cmdb_overview": "Overview",
            "TCS_cmdb_select": "Global search",
            "TCS_cmdb_host_resource": "Host resources",
            "TCS_cmdb_business_resource": "business resources",
            "TCS_cmdb_business_management": "business management",
            "TCS_cmdb_model_management": "Model management",

            "TCS_message_management": "message query",
            "TCS_message_query": "Mail inquiry",
            "TCS_message_short_query": "SMS query",

            "TCS_log_center": "log center",
            "TCS_log_query": "log search",
            "TCS_log_access": "log access",
            "TCS_log_check": "log events",
            "TCS_log_policy": "event strategy",

            "TCS_user": "User Center",
            "TCS_user_management": "user",
            "TCS_user_group": "user group",
            "TCS_user_role": "Role Permissions",
            "TCS_user_role_policy": "Permission Policy",
            "TCS_user_check": "User audit",
            "TCS_user_log": "Operation log",

            "TCS_codingDevOps_managemnet": "developer tools",
            "TCS_codingDevOps": "CodingDevOps",

            "TCS_component_management": "Component management",
            "TCS_component_list": "component list",
            "TCS_component_from": "Component Specifications",
            "TCS_cos": "Object Storage (COS)",
            "TCS_cos_overview": "Overview",
            "TCS_cos_bucket": "bucket list",
            "TCS_cos_monitor": "Monitoring report",
            "TCS_cos_monitor_report_basic": "Basic data statistics",
            "TCS_cos_monitor_report_code": "Return code statistics",
            "TCS_cos_access": "Access layer operation and maintenance",
            "TCS_cos_access_cluster": "Cluster management",
            "TCS_cos_access_machine": "Machine management",
            "TCS_cos_access_service": "Service management",
            "TCS_cos_access_task": "task management",
            "TCS_cos_index": "Index operation and maintenance",
            "TCS_cos_index_cluster_info": "Cluster details",
            "TCS_cos_index_diagnose": "Cluster Diagnostics",
            "TCS_cos_index_log": "log search",
            "TCS_cos_index_instance_profilling": "Instance performance analysis",
            "TCS_cos_store": "Storage operation and maintenance",
            "TCS_cos_store_cluster_overview": "Cluster overview",
            "TCS_cos_store_cluster_search": "Cluster management",
            "TCS_cos_store_machine_search": "machine query",
            "TCS_cos_store_service_search": "Service Inquiry",
            "TCS_cos_store_task_manage": "task management",
            "TCS_cos_store_libra_task": "Libra mission",
            "TCS_cos_store_version_manage": "Version management",
            "TCS_cos_store_disk_replace": "Hard drive replacement tasks",
            "TCS_cos_monitor_alarm": "Monitoring alarms",
            "TCS_cos_monitor_graph_main": "general view",
            "TCS_cos_monitor_graph_detail": "Detailed view",
            "TCS_cos_monitor_alert_config": "Alarm configuration",
            "TCS_cos_monitor_alarm_sj": "operational data",
            "TCS_cos_operate_overview": "Operational Overview",
            "TCS_cos_operate_user": "User data",
            "TCS_cos_user_manage": "User Management",
            "TCS_cos_user_bucket_manage": "Buket management",
            "TCS_cos_user_frequenc_manage": "Frequency control management",
            "TCS_cos_user_flow_manage": "Flow control management",
            "TCS_cos_user_quota_manage": "Quota management",
            "TCS_db_redis": "DatabaseRedis",
            "TCS_db_redis_equipment": "Device management",
            "TCS_db_redis_instacne": "instance list",
            "TCS_db_redis_process": "Process list",
            "TCS_db_redis_alarm": "Alarm list",
            "TCS_db_sql": "TBSQL (Red Rabbit)",
            "TCS_db_sql_chitu": "TBSQL",
            "TCS_tsf": "Microservice Platform (TSF)",
            "TCS_tsf_resource": "Resource operation",
            "TCS_tsf_resource_basic": "Basic module management",
            "TCS_tsf_resource_zone": "Availability Zone Management",
            "TCS_tsf_resource_config": "parameter configuration",
            "TCS_tsf_resource_guide": "Deployment Instructions",
            "TCS_tsf_tool_log_search": "log retrieval",
            "TCS_tsf_tool_topology": "Service Dependence Topology",
            "TCS_tsf_tool_trace": "call chain query",
            "TCS_tsf_tool": "Operational tools",
            "TCS_tsf_monitor": "monitor",
            "TCS_tsf_monitor_module": "Module monitoring",
            "TCS_tsf_monitor_resource": "Resource monitoring",
            "TCS_tsf_manage_liccense": "License management",
            "TCS_tsf_version_manage": "Version Information",
            "TCS_tenant": "Microservice Platform (Tenant)",
            "TCS_tenant_overview": "Overview",
            "TCS_tenant_cluster": "cluster",
            "TCS_tenant_namespace": "Namespaces",
            "TCS_tenant_group": "Deployment group",
            "TCS_tenant_apply_manage": "Application management",
            "TCS_tenant_service_manage": "Service Governance",
            "TCS_tenant_config": "Configuration management",
            "TCS_tenant_config_app": "Application configuration",
            "TCS_tenant_config_global": "Global configuration",
            "TCS_tenant_config_template": "configuration template",
            "TCS_tenant_config_file": "file configuration",
            "TCS_tenant_log": "log service",
            "TCS_tenant_log_config": "log configuration",
            "TCS_tenant_log_retrieve": "log retrieval",
            "TCS_tenant_log_alert": "log alert",
            "TCS_tenant_depend_on": "Dependency Analysis",
            "TCS_tenant_depend_on_service": "Service Dependency Expansion",
            "TCS_tenant_depend_on_trace": "call chain query",
            "TCS_tenant_monitor": "monitor",
            "TCS_tenant_monitor_service": "service monitoring",
            "TCS_tenant_monitor_jvm": "JVM monitoring",
            "TCS_tenant_gray_scale": "Full link grayscale release",
            "TCS_tenant_scalable": "Elastic scaling",
            "TCS_tenant_micro_service": "Microservice Gateway",
            "TCS_tenant_micro_service_gateway": "Gateway monitoring",
            "TCS_tenant_micro_service_group": "Group management",
            "TCS_tenant_micro_service_plugin": "Plugin management",
            "TCS_tenant_micro_service_redirect": "redirect configuration",
            "TCS_tenant_distributed": "Distributed Task Scheduling",
            "TCS_tenant_distributed_task": "task management",
            "TCS_tenant_distributed_execute_records": "execution record",
            "TCS_tenant_distributed_workflow_manage": "Workflow Management",
            "TCS_tenant_image_manage": "mirror repository",
            "TCS_tenant_template_manage": "Service Orchestration",
            "TCS_tenant_role_manage": "role management",
            "TCS_tenant_data_manage": "Dataset management",
            "TCS_tenant_operate_log": "Operation record",
            "TCS_tdmq": "Message Queuing (TDMQ)",
            "TCS_tdmq_cluster_manage": "Cluster management",
            "TCS_tdmq_namespace": "Namespaces",
            "TCS_tdmq_topic_manage": "Topic management",
            "TCS_tdmq_message_search": "Message query",
            "TCS_tdmq_role_manage": "role management",
            "TCS_tdmq_cmq": "CMQ",
            "TCS_tdmq_cmq_queue": "queue service",
            "TCS_tdmq_cmq_topic": "Subject subscription",
            "TCS_tdmq_operate": "Operation and maintenance management",
            "TCS_tdmq_operate_cluster": "Cluster management",
            "TCS_tdmq_operate_cluster_broker": "Broker",
            "TCS_tdmq_operate_cluster_bookie": "Bookie",
            "TCS_tdmq_operate_enviroment": "Namespaces",
            "TCS_tdmq_operate_topic": "Topic management",
            "TCS_tdmq_operate_subsciption": "Subscription management",
            "TCS_tdmq_operate_producer": "Relations of production",
            "TCS_tdmq_operate_consumer": "consumption management",
            "TCS_km8": "Key Management Service (KM8)",
            "TCS_km8_kms_key": "user key",
            "TCS_api": "API official website",
            "TCS_api_gateway_overview": "Overview",
            "TCS_api_gateway_service": "Serve",
            "TCS_api_gateway_plugin": "plugin",
            "TCS_api_gateway_use_plan": "use plan",
            "TCS_api_gateway_app": "application",
            "TCS_api_gateway_key": "key",
            "TCS_api_resource": "Resource management",
            "TCS_api_resource_index": "MasterManagement",
            "TCS_api_resource_cluster": "Cluster management",
            "TCS_api_resource_ld": "LD management",
            "TCS_api_resource_vip": "VIP management",
            "TCS_api_resource_vip_group": "VIP group management",
            "TCS_api_service": "Service management",
            "TCS_api_service_statistics": "Service Statistics",
            "TCS_api_resource_service_statistics": "Certificate management",
            "TCS_ckafka": "Message queue (CKafka)",
            "TCS_ckafka_instance_list": "instance list",
            "TCS_ckafka_operate": "Operation and maintenance management",
            "TCS_ckafka_operate_instance": "instance management",
            "TCS_ckafka_operate_cluster": "Cluster management",
            "TCS_ckafka_operate_broker": "Broker management",
            "TCS_ckafka_operate_master": "MasterManagement",
            "TCS_apply_center": "application Center",
            "TCS_apply_center_application": "Application overview",
            "TCS_apply_center_app_list": "Application List",
            "TCS_apply_center_app_market": "application market",
            "TCS_apply_center_app_template": "Template release",
            "TCS_apply_center_project_manage": "project management",
            "TCS_apply_center_config_manage": "Configuration management",

            "Resource_Local_Disk": "Local Disk",
            "resource-vm-clone": "Cloud host clone",
            "local-disk-add": "New",
            "local-disk-edit": "Edit",
            "local-disk-attach": "Attach",
            "local-disk-detach": "Detach",
            "local-disk-delete": "Delete",
            "resource-vm-attachLocalDisk": "Load local disk",
            "resource-vm-detachLocalDisk": "Unmount local disk",
            "cloudDatabase":"Cloud database",
            "Resource_Recycle_Db":"Cloud database recycle bin",
            "cloudDatabase-create":"New",
            "cloudDatabase-startServer":"Start",
            "cloudDatabase-stopSerever":"Stop",
            "cloudDatabase-conIsEnable":"Console",
            "cloudDatabase-modifyFixdIp":"modifyFixdIp",
            "cloudDatabase-bindfloatingIp":"bindfloatingIp",
            "cloudDatabase-removefloatIpDis":"removefloatIpDis",
            "cloudDatabase-createSnap":"createSnap",
            "cloudDatabase-snapRollBack":"snapRollBack",
            "cloudDatabase-volumesQos":"volumesQos",
            "cloudDatabase-networkCardConfig":"networkCardConfig",
            "cloudDatabase-stockTransfer":"stockTransfer",
            "cloudDatabase-reboot":"Reboot",
            "cloudDatabase-editSever":"editSever",
            "cloudDatabase-delSever":"delSever",
            "cloudDatabase-reset":"reset",
            "cloudDatabase-osMigrate":"osMigrate",
            "cloudDatabase-coldMigrate":"coldMigrate",
            "cloudDatabase-addAlarm":"addAlarm",
            "cloudDatabase-editPsw":"editPsw",
            "cloudDatabase-osHotgrade":"osHotgrade",
            "cloudDatabase-osUpgrade":"osUpgrade",
            "cloudDatabase-changeLabel":"changeLabel",
            "cloudDatabase-errorstart":"errorstart",
            "Resource-Recycle-Db-Clean":"clean",
            "Resource-Recycle-Db-Reset":"reset",

            "AdvFunction":"Advanced Function",
            "AdvFunction_Backup":"Backup Service",
            "AdvFunction_Backup_Service":"Backup Configuration",
            "AdvFunction_Backup_Job":"Data Backup",
            "AdvFunction_Backup_Recover_Job":"Data Recovery",
            "AdvFunction_Backup_Clean_Job":"Data Cleanup",
            "AdvFunction_Backup_Strategy":"Backup Strategy",
            "adv-backup-job-create":"job-create",
            "adv-backup-job-edit":"job-edit",
            "adv-backup-job-delete":"job-delete",
            "adv-backup-job-bind-instance":"Task-Associated Cloud Host",
            "adv-backup-job-unbind-instance":"Task-delete cloud host",
            "adv-backup-job-stop":"Monitor-Stop",
            "adv-backup-job-inst-delete":"History-Delete",
            "adv-backup-service-create":"Save configuration",
            "adv-backup-service-delete":"Delete configuration",
            "adv-backup-service-sync":"Data synchronization",
            "adv-backup-recover-job-create":"Data Recovery-New",
            "adv-backup-recover-job-stop":"Data Recovery-Stop",
            "adv-backup-recover-job-delete":"Data Recovery-Delete",
            "adv-backup-clean-job-create":"Data Cleanup-New",
            "adv-backup-service-sync-strategy":"Data synchronization strategy",
        },
        "menuTitle":{
            "Tsafe_yujie":"Advanced threat detection system-NTA（yujie）",
            "Tsafe_yujian":"Security operation center-SOC（yujian）",
            "Tsafe_tianmu":"Network intrusion prevention system-NIPS（tianmu）"
        },
        "recovery": {
            "header": {
                "name": "Backup Name",
                "type": "Backup Type",
                "instanceName": "Instance Name",
                "systemDiskSize": "System disk size",
                "ipaddress": "IP Address",
                "backupMode": "Backup method",
                "status": "Recovery Status",
                "recoveryTime": "Recovery Time",
                "backupTime": "Backup Time",
                "createTime": "Create Time",
                "size": "Size",
                "backupSize": "Backup size",
                "diskSize": "Cloud hard drive size",
                "backupStatus": "Status",
                "diskName": "Cloud drive name",
                "instance": "Cloud host",
                "systemDisk": "system disk",
                "dataDisk": "Data disk",
                "executionTime": "Execution time",
                "executionResult": "Results of the",
                "currentBackupTime": "Current backup time",
                "nextBackupTime": "Next backup time",
                "Recoverydetails": "details",
                "Resumerecord": "Resume record"
            },
            "backupType": {
                "recovery": "Recovery",
                "backup": "Backup",
                "recoverDetail": "Recover Detail",
                "system": "system disk recovery",
                "data": "data disk recovery",
                "backupDetail": "Backup Detail",
                "backupRecord": "Backup Record",
                "systemAddmainframe": "system add new mainframe"
            },
            "unit": {
                "once": "",
                "hour": "Hour",
                "day": "Day",
                "week": "Week",
                "month": "Month",
                "minute": "Minutes"
            }
        },
        "tdsql": {
            "header": {
                "id_name": "ID/ instance name",
                "status": "Running state",
                "type": "Instance Type",
                "edition": "Instance version",
                "specifications": "Specifications",
                "address": "Intranet Address"
            },
            "alarmsType": {
                "0": "Normal",
                "1": "SMS alert",
                "2": "The NOC alarm"
            },
            "instatus": {
                "0": "Uninitialized",
                "1": "Run",
                "2": "Isolation",
                "3": "Creation in progress",
                "4": "Delete in progress",
                "5": "Initializing",
                "9": "Initializing"
            },
            "instanceType": {
                "0": "Distributed instance",
                "1": "Non-distributed instances"
            },
            "edition": {
                "2": "Standard Edition (one master one Slave)",
                "3": "Standard Edition (one master and two Slave)"
            },
            "syncType": {
                "0": "Asynchronous",
                "1": "Strong synchronization (degradability)"
            },
            "placeholder": {
                "accountName": "Please enter your account name",
                "hosts": "Please enter hostname",
                "password": "Please enter your password",
                "updateTime": "Update time",
                "remarks": "Please enter remarks",
                "readOnlyAccountMsg": "If yes, you can set the parameters of the read-only account after clicking OK.",
                "hostsMsg": "IP form, IP segment ends in %;Support to fill %, 127.0.0.1;Null = % by default",
                "passwordMsg": "Password length is 8-16 characters and contains at least one uppercase letter, one lowercase letter, one number and one special character.Special characters include!- @. ^ _ %, : /",
                "verificationErrorMsg": "Please enter 6 significant digits",
                "phoneErrorMsg": "Please enter the correct cell phone number",
                "selectPieceSpec": "Please select the shard specification",
                "hostsWaring": "Only % means that all IP addresses are accessible. There may be security issues. Please select carefully."
            },
            "alert": {
                "addPiece": "Adding sharding means that your sharding number and logical instance configuration will increase, and the data will be automatically rebalanced when you add sharding.",
                "extensionPiece": "Scaling up a single shard will improve shard performance and capacity, but will not rebalance data."
            },
            "loadBalancingConfig":"Load Balancing Configuration",
            "detail": "Details",
            "alarms": "Alarm Enquiry",
            "insDetail": "Instance Details",
            "piecewiseManage": "Sharding management",
            "insMonitor": "Instance monitoring",
            "paramConfig": "Parameter Settings",
            "acconutconfig": "Account Settings",
            "baseMsg": "Basic information",
            "configMsg": "Configuration Information",
            "availableMsg": "Availability information",
            "insName": "Instance Name",
            //新增
            "pieceinsName": "Sharding Name",
            "pieceinsVersion": "Shard version",
            "piecenewPieceSpec": "Expanded partition Size",
            "piecenewPieceDisk": "Expanded and segmented Hard disk",
            //新增结束
            "insId": "Instance ID",
            "status": "State",
            "insType": "Instance Type",
            "insVersion": "Instance version",
            "area": "Territory",
            "innerNetAddress": "Intranet Address",
            "port": "Port",
            "belongNet": "Network of affiliation",
            "outerBetAddress": "External Network Address",
            "belongProject": "Project",
            "character": "Character set",
            "sqlVersion": "Database Version",
            "configuration": "Configuration",
            "backupsAndLog": "Backup and Log Space",
            "usedTotal": "Used/Total Capacity",
            "nodeNum": "Number of nodes",
            "createTime": "Creation time",
            "billMode": "Billing model",
            "deadline": "Due time",
            "copytype": "Data replication mode",
            "clusterName": "Belonging cluster",
            "mainLibrary": "Main Library Available Area",
            "secondLibrary": "Slave library Available area",
            "modifyAuthority": "Modify permissions",
            "copyAccount": "Clone account",
            "resetPwd": "Reset password",
            "accountName": "Account Name",
            "hosts": "The host",
            "accountType": "Account Type",
            "updateTime": "Update time",
            "remarks": "Note",
            "createAccount": "New Account",
            "openReadonlyAccount": "Create a read-only account",
            "readonlyAccount": "Enable strong synchronization",
            "hosts":"Host",
            "password": "Set a password",
            "confirmPwd": "Confirm password",
            "remarks":"Note",
            "authorizedObject": "Authorized Object",
            "allSelected": "Future generations",
            "phone": "Cell Phone Number",
            "getVerification": "Get captcha",
            "verification": "Captcha",
            "confirmDelAccount": "Are you sure you want to delete your account?",
            "delAccount": "Delete account",
            "saveModifyValue": "Save modified values",
            "modifyValue": "Modify run Values",
            "templateImport": "Import from Template",
            "paramName": "Parameter name",
            "needRestart": "Do I need to restart?",
            "paramDefault": "Parameter Default value",
            "currentValue": "Current Running Value",
            "availableModifyValue": "Modifiable parameter values",
            "modifyDataSyncType": "Modify data Synchronization Mode",
            "addPiece": "New sharding",
            "dilatationSlice": "Expanded partition",
            //新增
            "deleteSlice": "Delete sharding",
            "0":"Running",
            "100":"Running",
            "102":"Running",
            "1":"Isolate",
            "2":"Isolate",
            "cpu": "CPU",
            "memery": "Memory",
            "version": {
                "2": "One master and one subordinate",
                "3": "One principal and two subordinate"
            },
            //新增结束
            "pieceId": "The shard ID",
            "sqlTransmissionId": "SQL passthrough ID",
            "monitor": "Monitor",
            "pieceVersion": "Shard version",
            "specifications": "Specifications",
            "diskUsageRate": "Disk Space Utilization",
            "currentPieceSpec": "Current Shard Specification",
            "newPieceSpec": "New Sharding Specification",
            "newPieceDisk": "New Sharding Hard Drive",
            "dataRebalancingRatio": "Data rebalancing",
            "updateWasteTime": "Upgrade time consuming",
            "extensionPiece": "Expanded partition",
            "addPiece":"New sharding"
        },
        "serviceTip": {
            "begin": "Please go to",
            "ceph": "Use this module after docking with superfused Ceph",
            "backups": "Use this module after docking backup storage",
            "cinder": "Use this module after docking Shared storage",
            "k8s": "Use this module after installing the container service",
            "localDisk":"Use this module after matching the local disk resource pool",
            "beginManage":"Please contact the administrator",
            "used":"To use",
            "pleaseOpen":"Module function, please open",
            "concatManage":"Module function, please contact the administrator",
            "interfaceAdd":"Interface add",
            "module":"Module",
            "pvmResourceManage":"Physical resource management",
            "pvmInstances":"Physical machine management",
            "k8sClusters":"Container management",
            "pvmInstancesMenu":{
                "systemManage":"System Management",
                "systemSetting":"System settings",
                "functionManage":"Functional management",
            },  
        },
        "guide": {
            "please": "Please go to",
            "backups": "Use this feature after docking backup storage.",
            "cinder": "Use this feature after docking Shared storage."
        },
        "datacluster": {
            "virtualNode": "Virtual resource node",
            "containerNode": "Container node",
            "detail": "details",
            "clusterName": "Data center name",
            "cluster": "Data center",
            "clusterNameHolder": "Please enter the name of the data center",
            "deployedTips": "The data center is not deployed yet. Is it deployed immediately?",
            "ImmediateDeployment": "Immediate deployment",
            "regionAre": "area"
        },
        "vgpu": {
            "name": "Specification Name",
            "info": "Specification Info",
            "physicsGpu": "Physics Gpu",
            "nodeInfo": "Node Info",
            "instance": "Instance",
            "createTime": "Create Time",
            "newVgpu": "New vGPU Specifications",
            "chooseNode": "Select node",
            "chooseNodeHolder": "Please select node",
            "physiclGpu": "Physical GPU Device",
            "standar": "Shard specification",
            "vgpuInstance": "Number of vGPU instances",
            "del": "Are you sure to delete the selected vGPU specification?",
            "vgpuTips": "Shard virtualization of NVIDIA physical Gpus will be performed according to the selected specifications.",
            "detail": {
                "vgpuType": "VGPU type",
                "video": "Virtual Display Heads",
                "dpi": "Maximum resolution",
                "number": "Maximum number of slices",
                "frame": "Video Memory Size (MB)"
            }
        },
        "gpu": {
            "type": "The GPU model",
            "vgpuType": "VGPU type",
            "pci": "PCI address",
            "videoMemory": "Video memory size (M)",
            "video": "Virtual Display Heads",
            "dpi": "Maximum resolution",
            "instNum": "Maximum number of instances",
            "node": "Physical node."
        },
        "indextran": {
            "selectpolysalt": "Please select an aggregate disk",
            "polysalt": "Aggregate disk",
            "average": "Average value",
            "peak": "Peak",
            "ceph": "storage",
            "memory": "memory",
            "platform": "Your current cloud platform",
            "warning": "To facilitate the use of the cloud management platform, it is recommended to do the relevant operations in the recommendation prompt immediately, please click",
            "set": "Settings",
            "detail": "View Details",
            "installK8s": "Please install container Service first",
            "installIronic": "Please install physical machine Service first",
            "resource_70": "resource utilization rate exceeds 70%, please expand the node in time.",
            "resourceOverflow": "Resource utilization exceeds",
            "pleaseExpand": "Please expand in time.",
            "expansions": "Otherwise the cloud host node downtime will affect the ability of self recovery. If you have any questions, please contact technical service staff.",
            "expansion": "Please extend the node, otherwise the cloud host node downtime will affect the ability of self recovery. If you have any questions, please contact technical service staff."
        },
        "function": {
            "functiontype": {
                "MaaS": "MaaS physical machine management",
                "phy": "Physical machine management",
                "con": "Container management",
                "log": "Install log",
                "detail": " details",
                "add": "Add options",
                "type": "Please select the add type",
                "useceph": "Whether or not the CEPH cluster is used",
                "masterip": "K8S master node IP address",
                "masteripholder": "Please enter K8S master node IP address",
                "masterippwd": "K8S master node root password",
                "password": "Please input a password",
                "masterNodeName": "master node data disk name",
                "masterNodeNameHolder": "Please enter the name of the node node data disk",
                "yes": "Yes",
                "no": "No",
                "gateway": "Gateway",
                "endip": "End IP",
                "startip": "Start IP",
                "endipholder": "Please enter the end IP",
                "startipholder": "Please enter the start IP",
                "nodeip": "K8S node IP address",
                "nodeipholder": "Please enter K8S node IP address",
                "nodepwd": "K8S node root password",
                "nodepwdholder": "Please enter password",
                "nodeName": "node data disk name",
                "nodeNameholder": "Please enter the name of the node node data disk",
                "floatip": "Physical machine business network",
                "RGW": "Object storage",
                "param": "parameter",
                "replicated": "Storage copy number",
                "url": "URL",
                "port": "Port",
                "password":"Please input a password",
                "ip": "IP",
                "YUJIE": "Royal World",
                "SOC": "Mimie",
                "TIANMU": "canopy",
                "WAF": "WAF",
                "account": "account",
                "apikey": "API access key",
                "apivalue": "API access private key",
                "popover": {
                    "account": "Please use the administrator account to connect"
                },
                "erasure": "Erasure code",
                "erasureTip": "K for data block, m for check block",
                "objectStorageTip": "After object storage docking is completed, you can go to system Settings -Ceph PG management to modify the storage pool ratio, and automatically adjust PG.",
                "placeholder": {
                    "param": "Please select a parameter",
                    "port": "Please enter the port",
                    "url": "Please enter URL",
                    "ip": "Please enter IP",
                    "account": "Please input Username",
                    "password": "Please enter password",
                    "apikey": "Please enter API access key",
                    "apivalue": "API access private key"
                }
            },
            "tips": {
                "hardware": "The network discovers and collects the hardware configuration of the DHCP radio, which reuses a physical network port, which suggests that there are not less than 5 IP addresses in the segment IP.",
                "business": "This network is used to allocate IP addresses that can provide external services to the physical machine, so the network address range can not overlap with the external network of the cloud host.",
                "contips": "The cleanup operation will clear your container management service and its environment. Please confirm.",
                "phytips": "The cleanup operation will clear your bare machine management service and its environment. Please confirm.",
                "cephtips": "This function needs the support of CEPH storage. Please ensure that CEPH storage is completed and re operated.",
                "addphytips": "Physical machine business network, cloud host external network conflict, please confirm the completed IP address segment.",
                "addphytips1":"The gateway conflicts with the physical machine business network and the virtual machine external network. Please confirm the filled gateway address",
                "cidrtips": "The IP address is not within the CIDR range",
                "gateway": "Please enter the physical machine management gateway",
                "iptips": "The end of the IP address must be larger than the starting IP address",
                "masteriptips": "Format error, single node address 192.168.1.1, multiple node addresses 192.168.1.1-192.168.1.111 or 192.168.1.1192.168.2.111 can be combined by comma separation.",
                "noEmpty": "IP address can not be empty",
                "disktips": "The correct format, for example, /dev/sdc",
                "ciderNoEmpty": "CIDR can't be empty",
                "cidrholder": "The format is similar: 10.0.1.0/24",
                "phyGuide": {
                    "1": "The function of physical machine needs to be registered by physical machine. Please go to product and Service > System Management > operation and Maintenance Management > ",
                    "2": "Nanofilm physical machine ",
                    "3": "The resource pool physical machine module registers the resource pool physical machine."
                }
            },
            "floating": {
                "cidr": "Business network CIDR",
                "gateway": "Business network gateway",
                "startip": "Business network starting IP",
                "endip": "Business network ends IP"
            },
            "operations": {
                "del": "Delete record",
                "add": "install",
                "clean": "clean",
                "cleanEnvironment": "clean environment",
                "create": "add"
            },
            "header": {
                "name": "Plugin Name",
                "progress": "Progress",
                "status": "Operating State",
                "addtime": "Add Time",
                "handle": "Operation"
            },
            "status": {
                // "11":"Prepare",
                // "12":"adding",
                // "13":"fail",
                // "4":"success"
                "1": "ready",
                "2": "installing",
                "3": "install success",
                "4": "install failure",
                "5": "cleaning",
                "6": "clean success",
                "7": "clean failure"
            },
            //pluginStatus
            "contentStatus": {
                "0": "ready",
                "1": "start",
                "2": "processing",
                "3": "success",
                "4": "fail",
                "8": "install",
                "9": "install failed"
            },
            //statusType
            "contentStatusType": {
                "clean": "clean",
                "install": "install",
                "ready": ""
            },
            "paas": {
                "0": "Installation successful",
                "1": "Installation failed",
                "2": "Auto import failed",
                "TDSQL": "TDSQL Distributed Database",
                "CTSDB": "CTSDB Timing Database",
                "TSF": "TSF",
                "CloudSecurity": "Cloud mirror",
                "TBase": "Tbase Massive Transaction Processing Platform",
                "COC": "Weave cloud",
                "TBDS": "TBDS Big Data Suite",
                "CMQ": "Message queue Middleware",
                "APIGateway": "API gateway",
                "BlueKing": "Blue",
                "Alauda": "TKE Enterprise edition",
                "SkyCloudSecurity": "Sky eye cloud mirror",
                "maas": "MaaS Physical Machine Management",
                "CSP": "CSP storage",
                "SEC": "cloud security",
                "TCS_API_KEY": "TCS",
                "YUJIE": "Royal World",
                "SOC": "Mimie",
                "TIANMU": "Canopy",
                "WAF": "WAF"
            },
            "objectStorage": {
                "0": "Ready state",
                "2": "Under installation",
                "3": "Installation successful",
                "4": "Installation failed"
            }
        },
        "common": {
            "adjustConfigError":"Configuration adjustment failed. Procedure",
            "bindHostTitleMsg":"After binding, ensure that the cloud host has enabled the corresponding security group rules.",
            "assignIP": "Specify IP",
            "IPsite": "IP address",
            "certificatetype": "Certificate type",
            "SSL": "SSL parsing method",
            "SSLdescribe": "One-way authentication, only server-side authentication is performed. If you need to authenticate the identity of the client, please select two-way authentication.",
            "onecertificate": "One-way authentication",
            "twocertificate": "Two-way authentication",
            "nocertificate": "no certificate",
            "caCertificate": "CA certificate",
            "serverCertificate": "server certificate",
            "resetTheState": "reset state",
            "warmPrompt": "Reminder: On the physical server, after manually adding an external network, click Add on this page to complete the connection of the external network. After the connection is completed, you can click the Save button to save the settings. After saving, you can add this external network in Virtual Resource Management→Network→External Network.",
            "setTimingExamine": "Set up regular inspections",
            "receiveEmail": "recipient email",
            "executionTime": "execution time",
            "timingExamine": "Regular health inspection",
            "timingExamineStatus": "Regular health inspection status",
            "userContinuous": ": When the user continuously",
            "noLogin": "Can't try to log in to the platform",
            "pwdIntroduce": "Introduction to Password Authentication",
            "pwdHint": "After a user enters incorrect login information continuously for more than a specified number of times, the user will be locked out.",
            "selectDimensionality": "query dimension",
            "yes": "Yes",
            "no": "No",
            "active": "Running",
            "down": "Stop",
            "loading": "Data is loading...",
            "empty": "Data is empty ",
            "prompts": "Prompt Message",
            "inUsed": "Already used",
            "available": "available",
            "unreasonable": "Data is unreasonale",
            "noData": "No Data",
            "noHistoryData": "No History Data",
            "dataError": "Data Error",
            "seeAll": "View More",
            "progress": "Upload Percent",
            "progressrate": "rate",
            "added": "already added",
            "noMonitorData": "The monitored VM has no data",
            "defaultDepar": "default Domain",
            "defaultProject": "default Project",
            "have": "True",
            "none": "False",
            "chooseSrc": "Select the source",
            "blank": "Blank disk",
            "volume": "Cloud Hard Drive",
            "image": "Mirror image",
            "img": "Mirror image",
            "snapshot": "Snapshot",
            "snap": "Snapshot",
            "backup": "Back up",
            "quotaInfo": "Quota Information",
            "backupQuota": "Number of backups quota",
            "backupGigQuota": "Backup Capacity Quota",
            "name": "Name",
            "domain": "Department",
            "project": "Project",
            "size": "Size",
            "storage": "Storage",
            "systemDisk": "System Panel",
            "dataDisk": "Data Disk",
            "noImage": "No mirrors available.",
            "noVolume": "No cloud drive available",
            "noSnapshot": "No snapshots available",
            "noBackup": "No backup available.",
            "noCapacity": "Resource pool capacity is insufficient",
            "advancedSet": "Advanced Settings",
            "pool": "Resource Pool",
            "startStyle": "Startup mode",
            "configType": "Configuration Type",
            "allocate": "Allocated",
            "recommendFlavor": "Recommended Specifications",
            "moreFlavor": "More Specifications",
            "chooseFlavor": "Select Specifications",
            "unselect": "Unselected",
            "countprev": "common",
            "countnext": "strip",
            "defaultType": "Default Type",
            "all": "All",
            "localDisk": "Local Disk",
            "jump":"Jump",
            "secDomainTip":"Your current role is a super administrator, click the jump button to jump to the management page.",
            "helpInformation":"help information",
            "viewDetailedManual":"View detailed manual",
            "instancesDescribe":"Cloud hosting is an important part of cloud computing in infrastructure applications. It has a complete hardware, operating system, network function, and a computer system running in a complete isolation environment.",
            "affinityDescribe":"When the cloud main machine with the desired creation is distributed on the same physical node in the same physical node, so that the communication between these cloud hosts can put them in the same group; when the desired creation cloud host Distributing some of the specific cloud main machines on different physical nodes so that these cloud hosts achieve certain isolation, and balance the resource usage of each physical node, they can also put them in the same group. The same group of these scenes, that is, the cloud main unit.",
            "portsOneDescribe":"The cloud tube platform supports the security policy of turning off the virtual network card, allowing users to manually configure multiple IP addresses on a NIC of the cloud host. In order to prevent manually assigning IP addresses from allocated by other users, set the virtual network card to occupy the specified IP address.",
            "portsTwoDescribe":"The virtual network card can be loaded to the cloud main machine, and one host can load up to 26 network cards.",
            "imagesOneDescribe":"The image is a cloud main machine template that includes operating system and applications and services. When the user creates a cloud host, you need to select a mirror, which is installed in the cloud main machine.",
            "imagesTwoDescribe":"After the cloud tube platform is installed, it is necessary to upload the CloudTools and other operating system mirror files (RAW, QCOW2 or ISO) in the tool disc ISO to the mirror library. The RAW and QCOW2 format can create a cloud host directly, and the ISO format needs to create a mirror available to create a cloud host through a Mirror feature.",
            "makeimagesDescribe":"After the ISO format mirror file is uploaded to the cloud tube platform, it is necessary to create a mirror that creates the cloud host through the Mirror function.",
            "volumesOneDescribe":"The cloud hard drive is a virtual block storage device that uses a network storage architecture that supports elastic extension and manages and uses cloud hosts.",
            "volumesTwoDescribe":"The cloud hard drive is similar to the U disk or mobile hard disk in the physical world, and the user can mount to the running cloud main machine, providing persistent storage for the host.",
            "volumesThreeDescribe":"The cloud hard drive is suitable for system software or applications for file systems, databases, or other pairs of capacity, IO.",
            "labelDescribe":"The label is used to identify cloud resources. When you have a number of cloud resources, you can use the tag to classify the cloud resources by different dimensions. With tag features, you can quickly search and filter specific cloud resources based on labels added to cloud resources. A set of labels can be defined for cloud resources in the account to track owners and purposes of each cloud resource, making resource management easier.",
            "snapshotsOneDescribe":"Cloud hard disk snapshot is a full picture of a time point cloud hard disk, and the status of the cloud hard disk can be returned by creating a snapshot so that the same state can be returned. Snapshots belong to incremental backups, which means that only data on the latest snapshots is saved, which will save the time required to create snapshots and save storage costs.",
            "snapshotsTwoDescribe":"You can create a snapshot in any state of the cloud hard drive, however, the snapshot can only save data that has been written when creating a time point. If the application or other process is written to the cloud hard disk at this time, it may not be Save. If all files can be written to pause and create a snapshot, the snapshot should be complete. If you are unable to suspend, it is recommended to uninstall the cloud hard drive from the cloud host, create a snaps and then reinstall it to the cloud host.",
            "localDiskOneDescribe":"Local disks are virtual machines directly using the physical machine, compared to the use of cloud hard drives, using local disks, in addition to functionality, it will achieve higher performance, suitable for applications or scenes with special needs.",
            "localDiskTwoDescribe":"Since the local disk is directly created on the physical machine where the virtual machine is located, the migration virtual machine does not synchronize the local disk, so the virtual machine mounted on the local disk does not support migration, and it is impossible to ensure the high availability of the virtual machine.",
            "backupStrategyDescribe":"The backup policy is the basis for timing backups. The backup policy defines the backup frequency, backup start time, and the number of files remains. When the new setup, the backup needs to specify the backup policy, and the system automatically performs backup tasks by backup policies.",
            "physicalNodeOneDescribe":"Monitor the various information of the physical host.",
            "physicalNodeTwoDescribe":"Physical host is running on the cloud tube platform.",
            "physicalNodeTitle":"Virtualization physics:",
            "physicalResPhyOneDescribe":"Monitor the various information of the physical host.",
            "physicalResPhyTwoDescribe":"Idle physical machines for users to apply.",
            "physicalResPhyTitle":"Resource pool physics:",
            "physicalIpmiOneDescribe":"Monitor the various information of the physical host.",
            "physicalIpmiTwoDescribe":"The operating system is installed and the physical host of the business.",
            "physicalIpmiTitle":"Natshe physical machine:",
            "newbackupDescribe":"Backup is a convenient and efficient data protection service. The daily backup of the data disk can regularly back up important business data to deal with data loss risks caused by mistakes, attacks, or viruses. You can create one or more data backups before using major operations such as application software upgrades or business data migration. Once any problem occurs during the upgrade or migration, you can restore the service to the normal system data status through data backup.",
            "sysbackupDescribe":"By creating a backup, a full amount or incremental backup of the cloud host system disk file is stored in the backup storage device of the cloud tube platform.",
            "databackupDescribe":"By creating a backup, a full amount or incremental backup of the cloud host data disk file is stored in the backup storage device of the cloud tube platform.",
            "sysbackupRecoveryDescribe":"When the cloud host is incorrect or when the physical machine is seriously faulty, the data of the corresponding system disk can be restored using the system disk backup.",
            "databackupRecoveryDescribe":"When the cloud host is incorrect or when the physical machine has a serious failure, the data disk backup can restore the data of the corresponding data disk.",
            "volumesQoSDescribe":"To prevent excessive resources of some cloud hard drives, affect the entire storage efficiency, set the QoS of the cloud hard disk to limit the read and write throughput and operands of the cloud hard disk. The QoS of the storage pool is based on this storage pool to create a cloud host, which has this QoS properties. For a single cloud main machine, if you need to modify the cloud hard disk QoS, you can modify the current QoS attribute value in the cloud main machine disk operation. QoS is a front-end (NOVA) implementation, not through the rear end storage drive implementation.",
            "netTopoDescribe":"Network topology supports the network connection relationship of the entire cloud tube resource, showing hierarchical connection relationships including cloud host, private network, virtual router, and cloud main machine.",
            "networksDescribe":"A private network is a user-defined logical isolation network space, which is hosted in private networks, and is a cloud main machine and load balancing resources. Complete logic isolation between different private networks. Users can customize network segment division, IP address, and routing policies, and implement multi-layer security protection through firewalls and security groups.",
            "routersDescribe":"Routers are used to connect multiple private networks to enable them. The cloud host can access the public network through the router.",
            "physicalnetworksDescribe":"The external network is a mapping of external physical networks, typically used to assign a gateway address to a virtual router, or assign a public IP address to the cloud host. The cloud host provides business capabilities outside the external network.",
            "floatingIpsDescribe":"The public network IP address is the Internet IP address. If you need to access the cloud host from the external network, you need to assign public IP for cloud main machine. Apply for public IP first, then bind the public IP to the cloud host or load balancing. (In private cloud, here can be the private network IP address of the company's internal business)",
            "loadbalancingDescribe":"Load balancing can dynamically distribute access traffic to multiple sets of cloud main machines that provide the same service, each cloud host can respond to requests, thereby increasing service capabilities and availability. At the same time, you can also adjust the service capabilities of load balancing at any time by adding or deleting cloud hosts, and these operations do not affect the normal access of the business. Currently support HTTP/HTTPS/TCP三种监听模式。",
            "loadbalancingcertificateOneDescribe":"The load balancer supports two types of certificates, server certificates, and CA certificates. When configuring an HTTPS listener, you need to bind the server certificate for the listener. If you turn on the two-way authentication function, you also need to bind the CA certificate.",
            "loadbalancingcertificateTwoDescribe":"Server Certificate: When using the HTTPS protocol, server certificates are used for SSL handshake negotiation, requiring certificates and private keys.",
            "loadbalancingcertificateThreeDescribe":"CA certificate: Also known as the client CA public key certificate, used to verify the issuer of the client certificate; when the HTTPS bidirectional authentication function is turned on, the HTTPS connection can only be successful only when the client can issue a certificate issued by the client.",
            "vpnDescribe":"IPsec VPN refers to an IPSec protocol to implement a VPN technology for remote access, and IPSec is a security standard framework defined by Internet Engineering Task Force (ITF) to provide a common and dedicated network end-to-end Encryption and verification services. IPsec VPN is used to connect two private cloud environments or to private clouds and public cloud environments, integrating two private network environments a unified private network environment.",
            "firewallDescribe":"The firewall acts on the router, protects the router, and the entire internal network connected to the router from illegal intrusion of external networks.",
            "keypairsDescribe":"即常见的ssh-Key's key pair. Can be used to log in to the Linux type cloud host without a username. Only the Linux type cloud main machine is supported. Generate a key pair on the cloud tube platform and download the private key to the user. When you create a cloud host, select the corresponding key to log in to the cloud host through the key mode, thereby ensuring the height of the cloud host accessed Control.",
            "securityGroupsDescribe":"The security group is a logically packet that enables communication between mutual trust. The cloud main machine with the same network security isolation needs can be added to the same security group in the same area, so that these cloud host use the same security policy.",
            "scalpolicyDescribe":"According to the user's business needs and strategy, economically automatically adjust the management services of the elastic computing resource. Elastic extension is not only suitable for applications that continue to fluctuate, but also applications that are suitable for business volume.",
            "extensionDescribe":"By elastic expansion management clusters, the cloud host is automatically increased at the peak period, and the cloud host is automatically reduced when the business is dropped, and the infrastructure cost is saved.",
            "recycleDescribe":"The cloud host recycle station is used to store the deleted cloud main machine and provide the recovery function of the cloud host within the set period. When the cloud platform administrator removes the cloud host due to false operation, the cloud main machine can be found in the cloud main machine recycling station and restore the cloud main machine.",
            "recyclecsDescribe":"The data disc recovery station is used to store the deleted data disc and provide the recovery function of the data disk within the set period. When the cloud platform administrator deletes the data disk due to false operation, you can find the data disk in the Data Recycle Station and return the data disk back.",
            "recycleDbDescribe":"Cloud Database Recycling Station is used to store the deleted cloud database and provide the recovery function of the cloud database within the setup period. When the cloud platform administrator deletes the cloud database due to false operation, you can find the cloud database in the cloud database recycle bin and restore the cloud database back.",
            "pvminstancesDescribe":"The physical machine module provides the most basic function in physical resource management, that is, physical machines from the application to delete full lifecycle maintenance.",
            "pvmimagesDescribe":"The mirror function module is used to provide a mirroring for the physical machine, which typically contains operating systems, applications, and related services. This module can create a physical machine to serve users.",
            "pvmnetworksDescribe":"The network function module is based on advanced SDN technology to provide users with the function of building a network on demand.",
            "k8sclustersDescribe":"Container Cluster Using Help Boot Use Help Boot Use Help Boot to Guide",
            "k8sservicesDescribe":"The container service is based on native Kubernetes, providing high-performance container management services with containers-as core, highly scalable. The container service is fully compatible with the original kuberneeteSapi, providing high-efficiency deployment, resource scheduling, service discovery, and dynamic telescopic, to solve the environmental consistency problem of user development, test and operation, and improves large-scale containers. The convenience of cluster management helps users reduce costs and improve efficiency.",
            "k8sprojectsDescribe":"Mirroring Service Use Help Boot Use Help Boot Use Help Boot to help boot",
            "alarmeventDescribe":"The alarm event is divided into alarm events and historical alarms. The alarm that has not been processed is a alert event; the processed alarm is a history alarm.",
            "wechatAlarmeventDescribe":"The alarm event is divided into alarm events and historical alarms. The alarm that has not been processed is a alert event; the processed alarm is a history alarm.",
            "hisAlarmeventDescribe":"Historical alarm event Use help boot to help boot to use help boot to help boot",
            "wechatHisAlarmeventDescribe":"Historical alarm event Use help boot to help boot to use help boot to help boot",
            "alarmsettingDescribe":"The system has preset common alarm settings. To create alarm settings, make sure the alarm template is created.",
            "wechatAlarmsettingDescribe":"The system has preset common alarm settings. To create alarm settings, make sure the alarm template is created.",
            "alarmtemplateDescribe":"The user can set a custom alarm rule on a particular monitoring index according to actual needs, and also support the use of alarm templates to create alarms for multiple resources or services.",
            "wechatAlarmtemplateDescribe":"The user can set a custom alarm rule on a particular monitoring index according to actual needs, and also support the use of alarm templates to create alarms for multiple resources or services.",
            "contractgroupDescribe":"Receive a mailbox or WeChat notified by alarm.",
            "wechatContractgroupDescribe":"Receive a mailbox or WeChat notified by alarm.",
            "pendingTicketsDescribe":"Work orders that need to be approved by the currently logged in user in a single page to be processed.",
            "myApplyDescribe":"In my application page, you can check the work order application that is currently submitted by the login user.",
            "allTicketsDescribe":"In my approval page, you can check the work orders that the current login user has been approved.",
            "ticketReportsDescribe":"View the work order information according to different filtration conditions.",
            "operatelogsDescribe":"Operation log Complete record the operation behavior information of each user in the cloud tube platform. Supports queries according to user name, operating source IP, operating source Mac, resource type.",
            "auditDescribe":"The audit log records the operational behavior information of system administrators and system-confidentialists, and is available for system auditors.",
            "loginlogsDescribe":"Log in logging to log in to the cloud tube platform. View and export user login information according to permissions. Support in accordance with user name, login source IP, login source MAC for query.",
            "departmentDescribe":"Each department has its own independent resource pool, department administrator to manage and deploy resources from the deployment. Resources between sectors are isolated from each other. This page can create and manage departments in the cloud platform and manage quotas for each department.",
            "projectDescribe":"The project is a collection of resources owned by people or services in Yunbai, one project can include multiple users, each user uses the resources in the project according to permissions. Here you can quota management, but the quota of the project cannot be higher than the department quota they belong.",
            "userDescribe":"By setting a role to the user, the user must give the user to meet the access control requirements of the system for the system. User roles are divided into department administrators, project administrators, and ordinary users. Department administrators can manage all project administrators and ordinary users of this department, and project administrators can manage all ordinary users in this project.",
            "roleDescribe":"Support for departmental, project and project members divided role (department administrator, project administrator, and ordinary users), and allocate functional permissions for the role allocation of the cloud tube platform. <div class = 'img-role'> </ div>",
            "aggregatesDescribe":"The host set is a logical small cluster that has a physical machine with a common property. It needs to be used with available domains; available domains more manifestations as geographic concepts, such as when a cluster cross-domain, each machine room is used as an available domain. The host set will classify the physical host having the same properties or the same use in the available domain. Let the user can automatically dispatch to the desired physical host when creating a cloud host.",
            "dataclusterDescribe":"When the cloud tube platform uses a public cloud tube deployment mode, it supports unified management and deployment of the center.",
            "nodeDescribe":"By managing nodes, a cluster, high availability and other functional characteristics are realized. Please note that the new node is not initialized more than 30 minutes, please check if the node is faulty.",
            "physicalConductorDescribe":"The tube physical machine is divided into two categories of physical machines and operations in the running physics. The physical machine that will be in operation is included in the management, monitors physical machine status; the idle physical unit is set into a resource pool, and rental services are provided. Operations for booting, shutting down, restarting, console, and checking hardware configuration information.",
            "physicalConductorResDescribe":"The tube physical machine is divided into two categories of physical machines and operations in the running physics. The physical machine that will be in operation is included in the management, monitors physical machine status; the idle physical unit is set into a resource pool, and rental services are provided. Operations for booting, shutting down, restarting, console, and checking hardware configuration information.",
            "storageManagementOneDescribe":"Configure the external storage device information of the cloud host backup resource pool or cloud main machine storage resource pool.",
            "storageManagementTwoDescribe":"When the storage is added, deleted, etc., confirm that the platform does not perform other operation and maintenance operations. If there is other operation and maintenance operation, wait for other operation and maintenance operations, then store management operations.",
            "imageManagementDescribe":"The cloud tube platform supports multiple migration mirror libraries to storage devices. Subsequently created image files are saved directly to shared storage, and the operation can be made multiple times, you can move local storage to shared storage, but you cannot move back from shared storage to your local 。",
            "regularSnapDescribe":"Create a planned task, perform a backup, snapshot task, performing a strategy, performing a strategy.",
            "transmissionMagDescribe":"The U disk or hard disk mounted on the physical machine is transmitted to the cloud main machine, and the cloud host can use the transparent transmission device.",
            "flavorsinstanceDescribe":"Cloud host specification, that is, the relevant configuration required to create a cloud host, including the number of CPUs, memory, and related information of the cloud host.",
            "flavorsphysicalDescribe":"The physical machine specifications are created related configurations required by the physical machine, including CPU numbers, memory, and related information.",
            "flowManageDescribe":"Workflow management is used to set the work order throughout the process of the work order approval, and the approval and scope of application of each link. The user can only see the corresponding application entry in \ Work Order Application after the new work order.",
            "flowManageCustomDescribe":"Custom processes are used to define basic information such as process levels and flowcharts.",
            "licenseDescribe":"Click \ 'Get Machine Code \', send the machine code as required to the vendor, and wait until the vendor's reply message on the activation file, click \ 'Import Activation File \' to import the activation file.",
            "pluginDescribe":"Function management is used to install the cloud platform extension function module. Note: You can only install the functional modules within the license license; if you do not find the module you want to install in the Add, check whether the license authorization covers the module.",
            "gradeDescribe":"Please upload the software upgrade package via the SFTP address.",
            "accesspolicyDescribe":"By setting the black and white list of access to filter the access rights of the cloud tube platform to protect the use of the cloud tube platform.",
            "openstackServiceDescribe":"Monitor the current calculation, storage, network services of the cluster, and run status.",
            "cephManageDescribe":"The cloud tube platform monitors the hard disk usage, hard disk replacement, hard disk usage, etc. of each server in the current CEPH storage, and is displayed by graphical or list page.",
            "databasetdsqlDescribe":"Distributed DISTRIBUTED MYSQL, TDSQL is a high-performance database service that supports automatic horizontal splitting - that is, the business experience is a complete logic table, but the data is uniformly split into multiple physical shards, which can effectively solve the oversized Concurrency, ultra-high performance, ultra-large capacity OLTP scenarios; each shard of TDSQL default uses the master-slave high available architecture, providing elastic extensions, and focuses on business development. TDSQL has the following functions: highly compatible with Mysql, higher performance, high availability, enterprise security, enterprise-class monitoring, scalability, ease of use",
            "inspectionOneDescribe":"The system inspection supports a comprehensive examination of the entire cloud tube platform to find problems in time.",
            "inspectionTwoDescribe":"During the inspection process, there will be followed:",
            "inspectionThreeDescribe":"1. System on the web interface, verify that the relevant function is normal by automatically created / delete cloud host, mirror, key pair, cloud hard drive, user, etc., where automatic creation / delete, the Web interface pops up The prompt box.",
            "inspectionFourDescribe":"2. The system is patrolling the use of the CPU, memory partition and other use cases of each node through the call of the underlying command.",
            "inspectionFiveDescribe":"3. When the system uses a super mean CEPH, the storage status and usage are patrolled.",
            "inspectionSixDescribe":"4, the system is inspected for each service status by calling the OpenStack API interface.",
            "databaseDescribe":"Cloud database is an important part of cloud computing in infrastructure applications. It has a complete hardware, operating system, network function, and a computer system running in a complete isolation environment.",
            "downloadTemplate":"Download template",
            "registrateFile":"Registration documents",
            "searchFilter":"Search, screening conditions...",
            "exitFilter":"totalSearch",
            "totalSearch":"totalSearch",
            "serachPcs":"serachPcs",
            "domain_quota":{
                "instances": "The department cloud host quota is insufficient",
                "cores": "Department CPU quotas insufficient quotas",
                "ram": "Inadequate memory quota for the department",
                "floatingip": "Department of Public Network IP quotas insufficient",
                "snapshots": "Lost express quotas of the department",
                "volumes": "The sector cloud hard disk quota is insufficient",
                "gigabytes": "Insufficient hard disk quotas for the department",
                "phy_instances":"Insufficient quotas of the departmental physics cloud console"
            },
            "project_quota": {
                "instances": "Project cloud host quota insufficient quota",
                "cores": "Project CPU quotas Insufficient",
                "ram": "Insufficient memory quota of the project",
                "floatingip": "Insufficient IP quotas of the project public network",
                "snapshots": "Project express quotas insufficient",
                "volumes": "The project cloud hard disk quota is insufficient",
                "gigabytes": "Project hard disk quotas insufficient",
                "phy_instances":"Insufficient quotas of the project physical cloud console"
            }
        },
        "popover": {
            "image": {
                "tip0": "Please select a image",
                "tip1": "The user not admin can't edit public image",
                "tip2": "The image is Unavailable",
                "tip3": "The image can not be deleted",
                "tip4": "SystemCheck that is systematic default image can't be edited",
                "tip5": "SystemCheck that is systematic default image can't be deleted",
                "tip6": "The physical node that has insufficient space can't be edited",
                "imageNotOperateTip":"Load balancing enhanced mirroring can not do any operation"
            },
            "btn": {
                "helpInfo": "HelpInfo",
                "refresh": "Refresh",
                "export": "Export",
                "listSetting": "List Setting"
            }
        },
        "maxpc": {
            "table": {
                "status": {
                    "true": "Enable",
                    "false": "Disabled"
                }
            }
        },
        "cephView": {
            "poolName": "Storage Pool Name",
            "poolsName": "Storage Pool",
            "osdName": "Hard Disk OSD Number",
            "name": "Hard Disk System Name",
            "osdStatusCopy": "State",
            "typeCopy": "Hard Disk Type",
            "total": "Hard Disk Capacity (GB)",
            "volumeGB": "Hard Disk Capacity (GB)",
            "nodeName": "Owned server",
            "percent": "Disk utilization",
            "reweight": "Data weights",
            "checkTime": "Health check time",
            "clustbalance": "Clust Balance",
            "excuteType": "Mode of Execution",
            "immExcute": "Execute immediately",
            "timedBalance": "Timing balance data",
            "timeSet": "Time Settings",
            "unknown": "Unknown",
            "excuteAction": "Executive action",
            "excuteTime": "Execution time",
            "volumeName": "Hard Disk Name",
            "osdNum": "Osd number",
            "IOused": "IO Usage rate",
            "attribute": "Hard drive properties",
            "cephTask": "Ceph Task",
            "rebootOsd": "Reboot Osd",
            "avaliableDisk": "Hard Disk available",
            "journalDisk": "Log Disk",
            "cacheDisk": "Cache disk",
            "dataDisk": "Data Disk",
            "missDisk": "Lost dish",
            "maxBackfills": "Maximum backfill",
            "rebootSuccess": "OSD restarted successfully",
            "rebootFail": "Restart OSD failed",
            "relationshipPreview":"Relationship preview",
            "tips": {
                "tip1": "Green means IO utilization is less than 30%, yellow means IO utilization is between 30% and 70%, and red means IO utilization is greater than 70%.",
                "tip2": "There are unfinished tasks in this cluster, new tasks cannot be created",
                "tip3": "The cluster is currently balanced (all PG states are not active+ Clean), please operate later.",
                "tip4": "Please select a task",
                "tip5": "Please select successful or failed tasks",
                "tip6": "The cloud platform monitors disk usage, disk replacement, disk usage, and so on for each server in the current Ceph storage, and displays them through graphical or tabular pages.",
                "tip7": "Please select an OSD",
                "tip8": "Please select offline or offline OSD",
                "tip9": "There are unfinished tasks in this cluster, new tasks cannot be created",
                "tip10": "This node already has a log disk. Cannot add a log disk.",
                "tip11": "Please confirm whether to delete this disk.",
                "tip12": "Confirm to add hard disk and set data balancing policy",
                "tip13": "Before deleting, you need to set the timing synchronization data first",
                "tip14": "Set timing synchronization data. Please make sure that the migration has failed and cannot be changed before using this button to reset the state. If you reset the state of the cloud hard disk in normal migration, the migration will be interrupted.",
                "tip15": "Sure you want to restart OSD?",
                "tip16": "Do you want to delete the selected hard drive?",
                "tip17": "You need to delete the log disk before you can delete the last data disk",
                "tip18": "Metadata Disk not available for partitioning",
                "tip19": "Metadata Disk not editable",
                "tip20": "When a metadata disk has been configured, the disk can only select disks of HDD type.",
                "tip21": "When configuring a data disk, only one disk type can be selected.",
                "tip22": "Data disk cannot be empty",
                "tip23": "When adding a new mapping relationship between the metadata disk and the data disk, make sure that the disk configuration is consistent with the existing disk mapping relationship"
            },
            "chart": {
                "status": {
                    "up": "Online",
                    "down": "Offline",
                    "out": "Outline",
                    "miss": "Unknown",
                    "missing": "Unknown",
                    "rebooting": "Reboot in progress",
                    "null": "N/A"
                },
                "diskType": {
                    "0": "SSD",
                    "1": "HDD"
                },
                "attribute": {
                    "data": "Data Disk",
                    "journal": "Log Disk",
                    "available": "Available Disk"
                },
                "actionTo": {
                    "add_data": "Add to Data Disk",
                    "add_journal": "Add to log Disk",
                    "delete_available": "Delete hard Drive"
                }
            },
            "task": {
                "jobTypes": "Task Type",
                "jobStatuss": "Task Execution Status",
                "nodeName": "Host Name",
                "nodeIp": "Host IP Address",
                "startTime": "Balanced data time",
                "createTime": "Create task time",
                "jobType": {
                    "0": "Cluster balancing",
                    "1": "New Hard Disk Data Balancing",
                    "2": "Self-healing mission"
                },
                "jobStatus": {
                    "all": "All",
                    "NORMAL": "Not yet started",
                    "EXECUTING": "In progress",
                    "FINISHED": "Successful execution",
                    "FAILED": "Failed execution",
                    "PAUSED": "Pause",
                    "ABNORMAL": "Execution exception"
                }
            }
        },
        "backups": {
            "volumeNum": "Number of cloud disks",
            "chooseBackup": "Select backup",
            "Createinformation": "Create information",
            "curBackup": "Current backup",
            "addBackup": "New backup",
            "backupName": "Backup name",
            "backupTime": "Backup time",
            "dataBackup": "Data disk backup",
            "systemBackup": "System disk backup",
            "import": "Import record",
            "export": "Export record",
            "importTitle": "Import backup file records to the cloud platform",
            "backupFile": "backup file",
            "chooseFile": "Select the file",
            "resetStatus": "Reset status",
            "selectStatus": "State selection",
            "type": "Backup method",
            "size": "Size（GB）",
            "backupInfo": "Backup information",
            "timingTaskBackup": "Scheduled backup",
            "timingBackup": "Real-time backup",
            "statusNotDeleted": "Unstarted, backups in backup, deleted or restored cannot be deleted",
            "backupDetail": {
                "detail": "Backup details",
                "addBackup": "New backup",
                "addVolume": "New cloud drive",
                "addvm": "New cloud host",
                "restore": "reduction",
                "Releasebackup": "Release backup",
                "Deletebackup": "Delete"
            },
            "table": {
                "name": "name",
                "vm": "Cloud host name",
                "createAt": "Creation time",
                "size": "size",
                "backupStatus": "Backup status",
                "backupType": "Backup Type",
                "chainName": "Chain name",
                "volumeName": "Volume Name",
                "chainStatus": "Chain Status",
                "backupPoint": "Backup Point",
                "status": {
                    "creating": "Creation in progress",
                    "available": "Available",
                    "deleting": "Delete in progress",
                    "error": "Error",
                    "restoring": "In recovery",
                    "error_restoring": "Recovery error",
                    "restoring-backup": "Backup in recovery",
                    "chain": {
                        "normal": "Normal",
                        "warning": "Warning",
                        "error": "Error"
                    }
                },
                "status_new": {//新的备份恢复中的备份状态
                    "waiting": "Waiting",
                    "creating": "Creating",
                    "end": "End",
                    "success": "Success",
                    "failed": "Failed",
                    "available": "Available",
                    "error": "Error",
                    "deleting": "Deleting",
                    "restoring": "Restoring"
                },
                "status_new_mainRecovery_table": {//新的备份恢复中的备份状态,主列表中的available翻译为成功，error翻译为失败，
                    "waiting": "Waiting",
                    "creating": "Creating",
                    "end": "End",
                    "success": "Success",
                    "failed": "Failed",
                    "available": "Available",
                    "error": "Error",
                    "deleting": "Deleting",
                    "restoring": "Restoring"
                },
                "isIncremental": {
                    "true": "Incremental backup",
                    "false": "Full backup"
                },
                "bootable": {
                    "true": "true",
                    "false": "false",
                    "null": "null"
                },
                "placeholder": {
                    "plsSelItem": "Please select Backup",
                    "plsSelSigItem": "Please select a backup",
                    "multipleChoice": "Backup does not support batch deletion",
                    "createInstanceMultipleChoice": "Backup does not support batch creation of cloud hard disks",
                    "createVolumeMultipleChoice": "Backup does not support batch creation of cloud hard disks",
                    "restoreMultipleChoice": "Backup does not support batch restore",
                    "resetMultipleChoice": "Backup does not support batch reset status"
                }
            },
            "Backupdetails": {
                "Backupdetails": "Backup details",
                "Executionrecord": "Execution record"
            },
            "upwindow": {
                "Pleaseselectthecloudhost": "Please select the cloud host to be backed up",
                "volumeName": "Volume Name",
                "Pleaseselecttheclouddrive": "Please select the cloud drive to be backed up",
                "Pleaseselectabackupstrategy": "Please select a backup strategy",
                "Pleaseselectabackupstrategymore": "Please select the cloud drive to be backed up, you can choose more"
            },
            "tips": {
                "del_msg": "Are you sure you want to delete the selected backup?",
                "exp_msg": "Export the record of the current backup file for data disaster recovery.",
                "restore_msg": "Are you sure you want a backup restore?",
                "reset_msg": "Are you sure you want to reset the backup state?",
                "importsuccess": "Backup file imported successfully",
                "importerror": "Backup file import failed",
                "fileCheck": "File format must be.PEM and no more than 2M",
                "expTip": "The total number of backup points cannot exceed 2000",
                "tip1": "Please select a backup that is available and in error status",
                "tip2": "This backup is dependent, please delete the dependent backup first",
                "tip3": "Information about this backup chain has not been obtained yet",
                "tip4": "Please select a backup in the available state",
                "tip5": "Cannot use this feature while backup files are available and in an error state",
                "tip6": "Please select a backup in the available state",
                "tip7": "Please first shut down the cloud host on which the source volume is mounted or uninstall the source volume from the cloud host",
                "tip8": "Please select a backup in the available state",
                "tip9": "The source tendril of the backup chain is available and in use",
                "tip10": "Incremental backup in which a backup exists in the backup chain",
                "tip11": "Incremental backup with error status in this backup chain",
                "tip12": "Cannot create a backup while the source volume has a scheduled backup task",
                "chain_uibpopover": "There is a state in the backup chain that the backup is not removable. Please check the details of the backup chain or wait.",
                "tip13": "The backup location store does not support this operation",
                "tip14": "Please select a backup",
                "tip15": "When you first create a cloud hard disk backup, the system defaults to full backup, after which you can choose full or incremental backups to save your data."
            },
            "backupChainName": "Backup chain name",
            "volumeName": "Cloud Hard Drive Name",
            "backupChainStatus": "Backup chain status",
            "size":"Size",
            "backupPoint": "Backup point",
            "createTime": "Creation time",
            "delBackup": "Delete backup",
            "deltip1": "The file you are currently deleting",
            "deltip2": "In the backup dependency chain, if you confirm that you want to delete the backup, first remove the backup files that the file depends on from the bottom up according to the backup topology"
        },
        "tasks": {
            "taskId": "TaskId",
            "previousFireTime": "Previous Fire Time",
            "nextFireTime": "Next Fire Time",
            "holdNumber": "Hold Number",
            "jobType": "Job Type",
            "operate": "Operate",
            "delTaskInfo": "Are you sure you want to delete the timer task?",
            "once": "A",
            "hour": "By the hour",
            "day": "By the day",
            "week": "Weekly",
            "month": "Monthly",
            "snapshotTask": "Timed Snapshot Task",
            "backupTask": "Time 02281201",
            "excuteRes": "Task Performance Results",
            "basicInfo": "Basic information",
            "excuteLog": "Task Log",
            "userName": "User Name",
            "volume": "Cloud Hard Drive",
            "excuteTime": "Execution time",
            "result": "Results of Execution",
            "newTask": "New Task",
            "editTask": "Edit Task",
            "delTask": "Delete Task",
            "unlockTime": "Unlock time",
            "detection": "It is detected that the PaaS service is currently managed. In order for the cloud host to access the PaaS service normally, please",
            "createANetwork": "Create Paas Share Network"
        },
        "pvm": {
            "servers": {
                "table": {
                    "locked": {
                        "false": "Not locked",
                        "true": "Lock"
                    }
                },
                "placeholder": {
                    "plsSelServer": "Please select a physical machine"
                }
            },
            "pvmGuide": {
                "1": "You can install the operating system after uploading physical machine mirrors, please go to physical resource management>",
                "2": "Mirror image",
                "3": "Upload image function upload image."
            },
            "register": "Registered",
            "edit": "Edit",
            "pvmName": "Name of bare Metal",
            "name": "Name",
            "IPMI_add": "IPMI address",
            "init": "Initialize",
            "power_status": "Power state",
            "deploy_status": "Deployment Status",
            "Maintain_status": "Maintenance status",
            "use_status": "Usage",
            "canuse": "Is it available?",
            "register_time": "Registration Time",
            "options_lock": "Operation lock",
            "desc": "Description",
            "addDescription":"Add a description",
            "viewExecutionResult":"View execution results",
            "registIng":"Register",
            "importList":"Please import a list",
            "pleaseClick":"Please click",
            "importFileErrors":"Import file errors or registration failed",
            "implement":"implement",
            "exportResult":"Export execution result",
            "notCanexport":"Temporarily no file can be exported",
            "ipmiAddress":"IPMI address",
            "registrationResult":"Registration result",
            "verificationSuccess":"Verification",
            "verificationFailed":"verification failed",
            "verifyIpmiResult":"Verify IPMI information results",
            "startPhysicalMachine":"Start physical machine",
            "closePhysicalMachine":"Close the physical machine",
            "restartPhysicalMachine":"Restart the physical machine",
            "terminateInit":"Terminate initialization",
            "physicalMachineDetail":"Physical machine details",
            "associatedUser":"Associated user",
            "cpuThread":"CPU thread",
            "cpuArchitecture":"CPU architecture",
            "hardDiskSize":"Hard disk size",
            "otherProperties":"Other properties",
            "novaResourcePool":"The tube physical machine is synchronized to the NOVA resource pool. ",
            "table": {
                "status": {
                    "null": "",
                    "power on": "Boot",
                    "power off": "Turn off",
                    "powering on": "On",
                    "powering off": "On shutdown",
                    "rebooting": "Reboot in progress",
                    "maintenance": {
                        "true": "Open maintenance mode",
                        "false": "Turn off maintenance Mode",
                        "null": "N/A"
                    },
                    "deploy": {
                        "enroll": "Registered",
                        "None": "No",
                        "null": "",
                        "verifying": "Under confirmation",
                        "available": "Available",
                        "initializing": "Initializing",
                        "active": "Run",
                        "building": "Creation in progress",
                        "wait call-back": "Waiting for response",
                        "deploying": "Under deployment",
                        "deploy failed": "Failed deployment",
                        "deploy complete": "Deployment complete",
                        "deleting": "Delete in progress",
                        "deleted": "Delete",
                        "error": "Error",
                        "manageable": "Manageable",
                        "cleaning": "Clearing up",
                        "clean wait": "Waiting to clean up",
                        "clean failed": "Failure to clean up",
                        "rebuild": "Reconstruction",
                        "inspecting": "Under examination",
                        "inspect failed": "Check failed",
                        "adopting": "In acceptance",
                        "adopt failed": "Accept failure"
                    },
                    "limitAttribute": {
                        "true": "Limited",
                        "false": "Unrestricted"
                    },
                    "used": {
                        "true": "In use",
                        "false": "Unused"
                    }
                }
            },
            "placeholder": {
                "cpu": "Please enter CPU",
                "ram": "Please enter memory",
                "disk": "Please enter disk size",
                "plsSelNode": "Please select a physical machine",
                "choiceStatus": "Select type",
                "enterplsName":"Please enter the name of the physical machine"
            },
            "tip": {
                "tip1": "Power on can only be started when the system is shut down and not reinstalled.",
                "tip2": "Shutdown is only allowed when the system is in running state and not under reinstallation.",
                "tip3": "Restart is not allowed until the system is reinstalled.",
                "tip4": "Please select a physical machine of the same state",
                "tip5": "Please select physical machine to turn off maintenance mode",
                "tip6": "Please select the physical machine with maintenance mode on",
                "tip7": "Please select a physical machine of the same maintenance mode",
                "tip8": "Please select a physical machine in unrestricted state",
                "tip9": "Please select uninitialized physical machine",
                "tip10": "Please select cloud hosts that are not locked and are not system reinstalled",
                "tip11": "Please select a cloud host in a locked state that is not a system reinstalled",
                "tip12": "Please select a cloud host that is running and is not locked in the system reinstall state",
                "tip13": "Please select an unused cloud host",
                "tip14": "Please select an uninitialized physical machine",
                "tip15": "Please select the physical machine whose power state exists",
                "tip16": "Select the physical machine in the initialization state",
                "tip17": "The physical machine in initialization does not support this operation",
                "tip18": "This deployment state is not allowed to be deleted with maintenance mode turned off",
                "tip19": "The physical machine is in maintenance mode or system reload or non-on-off state, and this operation is not allowed.",
                "tip20": "This physical machine is in use. This operation is not allowed."
            },
            "tipMsg": {
                "bootServer": "Are you sure you want to turn on the physics machine?",
                "shutdownServer": "Are you sure you want to turn off the physical machine?",
                "rebootServer": "Are you sure you want to restart the physical machine?",
                "deleteServer": "Are you sure you want to delete the physical machine?",
                "openMaintenance": "Are you sure you want to start physical machine maintenance mode?",
                "closeMaintenance": "Are you sure you want to turn off physical machine maintenance mode?",
                "initPvm": "Are you sure you want to initialize?",
                "registerStetus": "Are you sure you want to reset the state?",


                "lockSever": "Are you sure you want to lock the virtual machine?",
                "lockPhySever": "Are you sure you want to lock the physical machine?",
                "unlockSever": "Are you sure you want to unlock the virtual machine?",
                "unlockPhySever": "Are you sure you want to unlock the physical machine?",
                "resetServer": "Are you sure you want to reinstall the system?",

                "stopInit": "Are you sure you want to abort initialization?"

            }
        },
        "cluster": {
            "chooseRegion": "Select field",
            "status": {
                "0": "no install",
                "1": "Start installing",
                "2": "installing",
                "3": "installed",
                "4": "upgrades",
                "5": "Failure to upgrade",
                "null": "null"
            },
            "simJetSoft": "SimJet Soft",
            "new": "Create cluster",
            "edit": "Edit cluster",
            "newRegion": "New Data Center",
            "editRegion": "Edit",
            "ok": "Immediate deployment",
            "cancel": "Later deployment",
            "tips": "Tips",
            "createTime": "Create Time",
            "regionName": "Cluster name",
            "pleaseInputName": "Please enter the cluster name",
            "confirmDel": "Confirm cluster delete？",
            "confirmDelNode": "The cluster is not empty, confirm to delete it and its nodes?",
            "delCluster": "Please confirm whether to delete the data center?",
            "delClusternode": "Please delete all virtual resources of the data center and all nodes before deleting the data center!",
            "configure": "Cluster configuration",
            "name": "Name",
            "regionKey": "Region Key",
            "mapping": "Bottom mapping",
            "virtualNodeNum": "Virtualized Nodes",
            "containerNodeNum": "Container nodes",
            "description": "Description",
            "lastTime": "Last update time",
            "detailMessage": "Detail message",
            "registeredHosts": "Registered Hosts ",
            "iprange": "IP Range",
            "vlanrange": "VLAN Range",
            "vxlanrange": "VXLAN range",
            "nova_api_instances": "nova-api container number",
            "nova_scheduler_instances": "nova_scheduler container number",
            "cinder_scheduler_instances": "cinder_scheduler container number",
            "nova_consoleauth_instances": "nova_consoleauth container number",
            "nova_novncproxy_instances": "nova_novncproxy container number",
            "nova_conductor_instances": "nova_conductor container number",
            "cinder_api_instances": "cinder_service container number",
            "glance_api_instances": "glance_service container number",
            "ceilometer_service_instances": "ceilometer_service container number",
            "keystone_service_instances": "keystone_service container number",
            "neutron_server_instances": "neutron_server container number",
            "rabbitmq_service_instances": "rabbitmq_service container number",
            "haproxy_instances": "haproxy container number",
            "glance_registry_instances": "glance_registry_instances",
            "cinder_volume_instances": "cinder_volume_instances",
            "haproxy_galera_instances": "haproxy_galera_instances",
            "computeha_monitor_instances": "computeha_monitor_instances",
            "nova_metadata_instances": "nova_metadata_instances",
            "nova_api_workers": "Workers process number of nova_api",
            "nova_metadata_workers": "Workers process number of nova_metadata",
            "nova_conductor_workers": "Workers process number of nova_conductor",
            "neutron_api_workers": "Workers process number of neutron_api",
            "neutron_rpc_workers": "Workers process number of neutron_rpc",
            "cinder_api_workers": "Workers process number of cinder_api",
            "glance_api_workers": "Workers process number of glance_api",
            "glance_registry_workers": "Workers process number of glance_registry",
            "keystone_workers": "Workers process number of keystone",
            "ceilometer_notification_workers": "Workers process number of ceilometer_notification",
            "database_password": "The password of root in mysql",
            "keystone_database_password": "The password of keystone in mysql",
            "glance_database_password": "The password of glance in mysql",
            "nova_database_password": "The password of nova in mysql",
            "nova_api_database_password": "The password of nova_api in mysql",
            "neutron_database_password": "The password of neutron in mysql",
            "cinder_database_password": "The password of cinder in mysql",
            "keystone_admin_user": "Keystone admin name in OpenStack",
            "keystone_admin_password": "Keystone admin password in OpenStack",
            "glance_keystone_password": "Glance password in OpenStacknce",
            "nova_keystone_password": "Nova passwordin OpenStacka",
            "neutron_keystone_password": "Neutron password in OpenStacktron",
            "cinder_keystone_password": "Cinder password in OpenStackder",
            "metadata_secret": "Password of metadata_proxy",
            "horizon_secret_key": "Horizon SECRET_KEY",
            "haproxy_password": "Haproxy password",
            "memcache_secret_key": "Memcached password",
            "rabbitmq_password": "Rabbitmq password",
            "rabbitmq_cluster_cookie": "rabbitmq password between cluster",
            "new_tenant_vlan_range": "Tenat Vlan Range",
            "floating_start": "Floating IP start",
            "floating_end": "Floating IP end",
            "floating_cidr": "Floating IP cidr",
            "floating_gateway": "Floating IP gateway",
            "tunnel_ssh_port": "Tunnel of ssh port",
            "tunnel_remote_port": "Tunnel of remote port",
            "tunnel_remote_address": "Tunnel of remote IPv4 address/gateway",
            "cloud_event_uri": "Ceilometer Event URI",
            "confirmShutOffMsg": "Please confirm that the cloud host and other computing resources have been safely shut down, otherwise the forced shutdown environment may result in the loss of virtual machine data.",
            "shutDownMsg": "This function will close all the physical hosts managed by the cloud platform of the selected data center",
            "shutOffMsg1": "1.Please confirm whether the computing resources such as the cloud host have been safely shut down, otherwise the virtual machine data may be lost if the environment is forced to shut down.",
            "shutOffMsg2": "2.After the cluster is powered on next time and the ceph cluster returns to normal, please manually cancel the following four maintenance tags",
            "shutOffOrder": "noout，norebalance，nobackfill，norecover",
            "shutOffOrderStartMsg": "Execute the following command on any node",
            "orderStart1": "ceph  osd unset noout",
            "orderStart2": "ceph  osd unset norebalance",
            "orderStart3": "ceph  osd unset norecover",
            "orderStart4": "ceph  osd unset nobackfill"
        },
        "region": {
            "detail": {
                "floating": "Floating Network",
                "cluster": "Cluster Network",
                "storage": "Storage Network",
                "tenant": "Tenant Network"
            }
        },
        "property": {
            "side": {
                "title": "Access Control",
                "menu": {
                    "role": "Role",
                    "user": "User",
                    "org": "Organization",
                    "depart": "Department",
                    "project": "Project"
                }
            }
        },
        "action": {
            "recover":"recover",
            "selectFuse": "The fuse protocol is required for quota",
            "noSelectHost": "Cloud host not selected",
            "rw": "read and write",
            "ro": "Read only",
            "quota": "Quota",
            "customConfig": "Custom configuration",
            "fastConfig": "Quick configuration",
            "ISOinstall": "ISO installation",
            "createStorage": "Create Storage",
            "create": "Create",
            "add": "Add",
            "edit": "Edit",
            "modify": "Modify",
            "del": "Delete",
            "forceDel": "Force Delete",
            "download": "Download",
            "bind": "Binding",
            "unbind": "Unbind",
            "start": "Start",
            "shutdown": "Shutdown",
            "restart": "Restart",
            "moreAction": "More action",
            "search": "Search",
            "ok": "Ok",
            "save": "Save",
            "complete": "Complete",
            "cancel": "Cancel",
            "reset": "Reset",
            "reset_status": "Reset status",
            "status": "Status",
            "operation": "Operate",
            "update_patch": "update patch",
            "checking_saasStatus": "checking saas Status",
            "copy": "Copy",
            "deployed": "deployed",
            "forbidden": "forbidden",
            "enabled": "enabled",
            "close": "Close",
            "open": "Open",
            "accessKey": "Key management",
            "return": "return",
            "returnModify": "Return modification",
            "editPass": "Password Reset",
            "resetPass": "Password Reset",
            "editOrg": "Organization Edit",
            "processing": "Processing",
            "nextStep": "Next Step",
            "lastStep": "Last Step",
            "storageSetting": "Shared storage",
            "pleaseInput": "Please enter",
            "pleaseSelect": "Please choose",
            "int": "integer",
            "refresh": "refresh",
            "load": "load",
            "uninstall": "uninstall",
            "search":"Search",
            "selectExistVol": "Select an existing data disk",
            "empty": "empty",
            "user": "User",
            "hasData": "Have data",
            "retry": "Retry",
            "reset":"Reset",
            "working": "In progress",
            "reversed": "Revoked",
            "ended": "Over",
            "closed": "Closed",
            "abnormal": "Abnormal",
            "normal": "Normal",
            "reopen": "Reopen",
            "visit_way": "Access policy",
            "login_lose_way": "Login Failure Policy",
            "pwd_user_way": "Password and User Policy",
            "login_lose_lock": "Login Failure Lockout Policy",
            "pwd_grade_set": "Password Strength Settings",
            "grade_select": "Intensity selection",
            "weak": "Weak: no requirement",
            "centre": "Medium: Password must contain numbers and letters, or special characters (!@._:,/-^%)",
            "strong": "Strong: The password must contain three of four characters: uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), and special characters (!@._:,/-^%).",
            "min_height": "Minimum length limit",
            "max_height": "Maximum length limit",
            "prompt_message_one": "The content can not be blank",
            "prompt_message_two": "This is an invalid number",
            "prompt_message_three": "Value must be between 4 and 64",
            "prompt_message_four": "Value must be between 20 and 64",
            "loginSet": "Login settings",
            "multi_user_login": "Multiple logins at the same time",
            "default_hint_text": "By default, multiple users with the same user name are allowed to log in to the system at the same time.",
            "locking_strategies": "Locking strategy",
            "login_failure": "failed to login",
            "set": "Set up",
            "continuous": "Continuous",
            "frequency": "Frequency",
            "loadInstance": "LoadInstance",
            "unloadInstance": "UnloadInstance",
            "revoke":"Revoke",
            "apply":"Apply",
            "systemReload":"System reload",
            "unlock":"Unlock",
            "getMonitoringClients":"Get monitoring clients",
            "filter":"filter"
        },
        "topheader": {
            "services": "Products and services",
            "operation": "Quick operation",
            "newInstance": "New Instance",
            "newDepart": "New Department",
            "newPro": "New project",
            "newUser": "New user",
            "newVpc": "New VPC",
            "consumption": "Records of consumption",
            "amount": "Consumption amount",
            "yuan": "yuan",
            "record": "View the consumption record",
            "work": "Work list",
            "application": "Resource application",
            "untreated": "Untreated",
            "information": "Information Service",
            "unread": "unread",
            "help": "Help",
            "guidance": "Novice guidance",
            "document": "Help document",
            "configuration": "Initialization configuration"
        },
        "helpTip": {
            "instances": {
                "tip1": "Cloud hosting groups are created according to affinity/anti-affinity rules. All cloud hosts belonging to the same cloud host group will be distributed on the same physical node or completely on different physical nodes according to the rules.",
                "tip2": "The identity of the cloud host in the background of the cloud management platform, that is, the login console displays host-name. If no data is entered, the host name is the default data for the mirror.",
                "tip3": "Choose 'Mirror', 'Cloud Hard Disk', 'Snapshot' or 'Backup' as one of the ways to create a cloud host system disk.",
                "tip4": "When the virtio disk driver is not installed in the image, ide or scsi should be selected here. If not selected here, the disk controller type set in the image metadata will be used by default",
                "tip5": "If you check 'designated subnet', you will manually specify the subnet and IP address; if you don't check 'designated subnet', the system will assign it by default",
                "tip6": "Area is a host collection, specify the host range corresponding to the cloud host, that is, the available domain set in 'Operation and Maintenance Management> Host Collection'",
                "tip7": "Specify the host corresponding to the cloud host",
                "tip8": "Need to meet the cloud hard disk's untimed tasks, the status is in use, available, and the storage supports the cloud hard disk to create snapshots",
                "tip9": "It is necessary to satisfy that the snapshot status is in use and available, and the storage where it is located supports snapshot rollback",
                "tip10": "Need to meet the cloud hard disk untimed task, the status is in use, available, and the storage supports cloud hard disk to create backup",
                "tip11": "The backup status needs to be in use, available, and the storage where it is located supports backup and restore",
                "tip12": "Your system disk size cannot be smaller than",
                "tip13": "You have mounted",
                "tip14": "Block disk, you can also mount",
                "tip15": "Block Disk (Cloud Disk)",
                "tip16": "Batch creation does not support mounting existing disks",
                "tip17": "No data disk available",
                "tip18": "The logo of the cloud host on the interface of the cloud management platform.",
                "tip19": "The password for logging in to the cloud host operating system. If not set, the initial password for mirroring is used",
                "tip20": "The number of creations each time, up to 10 can be created simultaneously",
                "tip21": "The data center to which the cloud host belongs.",
                "tip22": "Cloud host CPU and memory. When selecting the specification type, the CPU and memory should at least meet the available CPU and available memory less than a certain physical node.",
                "tip23": "Choose the cloud host to boot from a local disk or cloud hard drive. The system will automatically create a local disk or cloud hard drive. Local disk: The cloud host will start on the host's local disk, there is a single point of failure risk, and the cloud host It cannot be migrated between hosts, and has no snapshot and backup capabilities. Cloud hard disk: The cloud host will start in the shared storage, which will obtain higher reliability and storage performance. (This startup method is recommended).",
                "tip24": "The way to create a cloud host system disk.",
                "tip25": "The name of the private network.",
                "tip26": "If you check 'Specify Subnet', the user specifies the subnet and IP address; if you don't check 'Specify Subnet', the system will assign it by default.",
                "tip27": "Specify the network segment of the subnet. The IP address must be in this network segment.",
                "tip28": "The IP address of the network card.",
                "tip29": "Specify the network segment of the subnet, the IP address must be in the network segment.",
                "tip30": "The IP address of the network card.",
                "tip31": "For Linux cloud hosts, it is recommended to use a key pair for remote login authentication. Users can create a new key pair and download the private key. The private key is used for remote login authentication to ensure the cloud The host is safe. The private key can only be downloaded once. Please keep it safe. You can also import the public key of an existing key pair into the system and use the corresponding private key for remote login authentication.",
                "tip32": "Security groups are used to implement access control of cloud hosts within and between security groups, and strengthen the security protection of cloud hosts. Users can define various access rules in the security group. When the cloud host joins the security group, It is protected by these access rules.",
                "tip33": "Select the available domain that the system will automatically select the cloud host to schedule to or the available domain specified by the user.",
                "tip34": "Select the node that the system will automatically select the cloud host to schedule to or the physical node designated by the user.",
                "tip35": "The image file corresponds to the architecture of the operating system, 32-bit or 64-bit.",
                "tip36": "Public: Common standard operating system image, visible to all users. Including operating system and pre-installed public applications. Private: User's personal image created based on cloud host or external image file, only visible to user. Contains operations System, pre-installed public applications and user private applications.",
                "tip37": "Operating system type.",
                "tip38": "Install cloud host disk driver and monitoring tools.",
                "tip39": "Apply for a public IP address from the public IP address pool. The cloud host can access the external network after binding the public IP, and the external network can also access the cloud host through this IP.",
                "tip40": "When users deploy many network-level applications on the cloud host, the data packets of these applications will be blocked by security policies when they flow out and into the security group. To enable the network card security policy, you must select a security Group (default is default). Close the network card security policy, do not load the security group on the network card, and allow any IP address to access the cloud host through the virtual network card.",
                "tip41": "Migrate the storage volume from the current storage to another storage device.",
                "tip42": "The system automatically creates snapshots according to the set frequency of snapshots.",
                "tip43": "Frequency of executing timing snapshot tasks.",
                "tip44": "Indicates how long to perform a timed snapshot.",
                "tip45": "Set the time for the scheduled task to execute the snapshot. The time needs to specify the year, month, day, hour, and minute.",
                "tip46": "The number of snapshots retained by the timed snapshot task, which can be selected from 1 to 5 and 5 positive integers. The rules are as follows: taking 5 copies as an example, the timed task will continuously create 5 snapshots in the specified period. When the snapshot is executed for the sixth time, the earliest snapshot will be automatically deleted after execution to ensure that the number of snapshots reserved for the entire periodic snapshot task is 5.",
                "tip47": "The system automatically creates backups at the set frequency of performing backups.",
                "tip48": "When backing up the cloud host, the selected volume must be a full backup for the first time. Only when there is a full backup can an incremental backup be created.",
                "tip49": "How often to perform scheduled backup tasks.",
                "tip50": "Indicates how long it takes to perform a scheduled backup.",
                "tip51": "Set the time for the scheduled task to perform the backup. The time needs to specify the year, month, day, hour, and minute.",
                "tip52": "The number of backup copies retained by the scheduled backup task. The backup rules are as follows: the number of backup copies is set to N, the first backup after the scheduled task is started, a full backup is performed, and the subsequent N-1 backups are incremental Backup. If the 'Keep the latest number of backup copies' is set to 4, after starting the scheduled task, the first time is full, the second, third, and fourth times are incremental, and a backup chain with a cycle of 4 is completed; the second time The cycle starts, that is, the fifth time is created as a full backup, and the sixth, seventh, and eighth times are incremental. At this time, there are two backup chains, a total of 8 backups. When the third backup cycle starts, the first one will be deleted A backup chain. Therefore, the maximum number of backups retained is 2N, and the number of backup chains is 2. When the number of the latest backup copies is reached, the previous corresponding backup files will be deleted. At least one of the retained backup files must be a full backup.",
                "tip53": "The maximum read throughput that the disk can support.",
                "tip54": "The maximum write throughput that the disk can support.",
                "tip55": "The number of I/O read requests per second that the disk can support.",
                "tip56": "The number of I/O write operations per second that the disk can support.",
                "tip57": "Cloud host CPU and memory. When selecting the specification type, the CPU and memory should at least meet the available CPU and available memory less than a certain physical node.",
                "tip58": "The system automatically selects the node to which the cloud host is to be migrated or the physical node designated by the user.",
                "tip59": "Select an available node in the area where the current node is located for cloud host migration.",
                "tip60": "Set the value of CPU and memory, which cannot exceed the maximum value of CPU and memory in the cloud host specification.",
                "tip61": "Used to identify alarm settings.",
                "tip62": "The template corresponding to the alert.",
                "tip63": "The email or WeChat of the contact group to be notified of the alarm.",
                "tip64": "Check to start immediately after the creation is successful, otherwise you can manually start it as needed.",
                "tip65": "The system automatically creates tasks according to the set frequency of task execution.",
                "tip66": "How often to perform scheduled tasks.",
                "tip67": "Indicates how long it takes to execute a timing task.",
                "tip68": "Set the time for the scheduled task to execute the task. The time needs to specify the year, month, day, hour, and minute.",
                "tip69": "Security reinforcement opens DDoS protection and Cloud Mirror host protection for free, based on the massive threat data accumulated by Tencent Security, including password cracking and interception, remote login reminder, Trojan file killing, high-risk vulnerability detection and other security functions to solve current The network security risks faced by cloud servers help companies build server security protection systems to prevent data leakage.",
                "tip70": "Automated operation and maintenance are hosted on Zhiyun automated operation and maintenance platform for free. Zhiyun is an enterprise-level operation and maintenance management platform derived from Tencent. It inherits standardized operation and maintenance methodology and experience, and supports one-click operation and maintenance. Intelligent monitoring, zero access costs, seamless integration with Tencent Cloud components, easy to achieve one-stop operation and maintenance management. Automated operation and maintenance are hosted on Zhiyun's automated operation and maintenance platform for free. Zhiyun is an enterprise-level operation from Tencent. The maintenance management platform inherits standardized operation and maintenance methodology and experience, supports one-click operation and maintenance operations and intelligent monitoring, zero access costs, seamless integration with Tencent Cloud components, and easy one-stop operation and maintenance management.",
                "tip71": "There is a cloud host without system disk information and cannot be checked",
                "tip72": "Tips: 1. After adding multiple network cards, you need to add the network card information of the network card and configure the corresponding static routing information in the virtual machine;",
                "tip73": "2. Every time PaaS resources are added or deleted, the security group rules need to be updated.",
                "tip74": "The PaaS network is a safe and isolated network. It does not support specifying IP addresses and adjusting security groups.",
                "tip76": "Does not support cloud disk migration to local disk files",
                "tip76_1": "Please do not restart the virtual machine during cloud hard disk migration",
                "tip77": "Migrate the system disk to ceph storage, please close the cloud host first",
                "tip78": "Migrate cloud hard drives to non-ceph storage, please start the cloud host first",
                "tip79": "Please cancel the mount and proceed with the migration",
                "tip80": "When the cloud host is powered on, it can only be queried, not loaded",
                "tip81": "This function is only valid for the linux system cloud host. When the linux system stops responding or the kernel crashes, the virtual machine will be automatically restarted. This function requires the watchdog service to be installed and running on the cloud host.",
                "tip82": "For the Windows system cloud host, this function can be realized by checking 'Automatically restart' when the system fails in the 'Control Panel -> System -> Advanced -> Startup and Recovery' in the windows system effect.",
                "tip83": "The currently selected subnet contains a default gateway. Adding it may affect the connectivity of other network cards of the cloud host. Please confirm whether to add a virtual network card for this subnet?",
                "tip84": "For linux cloud host, please add the configuration file of the new network card in the cloud host system after adding the network card, and start the network card, in order to really use the added virtual network card.",
                "tip85": "The local disk does not support this function",
                "tip86": "There is a snapshot on the system disk, so one-click restore is not possible",
                "tip87": "When the collection period is 5 minutes and the selection time exceeds 3 days and less than 7 days, a point of 15 minutes will be displayed on the curve, and a point of 30 minutes will be displayed on the curve when the selection time is greater than 7 days.",
                "tip88": "When the cloud host is turned on, it can only be queried, and GPU loading and unloading operations cannot be performed",
                "tip89": "Node setup needs to specify the startup node or select a directly connected network card and manually set the NUMA node value.",
                "tip90": "If Specify Subnet is checked, the subnet and ip address will be manually specified; if Specify Subnet is not checked, the system will assign it by default",
                "tip91":"You have no private network, click on the new private network",
                "tip92":"Cloud host of the shelving state checks the deletion system disk"
            },
            "images": {
                "tip1": "The way to upload the image, select 'upload from http' and 'upload from file'.",
                "tip2": "The address of the upload image file.",
                "tip3": "The format of the image file.",
                "tip4": "The image file corresponds to the operating system.",
                "tip5": "The capacity of the system disk of the cloud host. The minimum cannot be lower than the image file, and the maximum cannot exceed 200G.",
                "tip6": "Unchecked means this mirror is a private mirror and cannot be used by other projects (not visible); checked means this mirror is a public mirror and can be used by all other departments or projects.",
                "tip7": "The operating system type corresponding to the image file.",
                "tip8": "The image file corresponds to a specific operating system.",
                "tip9": "Install the image file of the cloud host.",
                "tip10": "The network used by the cloud host created by the mirror.",
                "tip11": "Cloud host CPU and memory.",
                "tip12": "Checked means that this mirror is a private mirror and cannot be used by other projects (not visible); unchecked means this mirror is a public mirror and can be used by all other departments or projects.",
                "tip13": "Install the disk driver and monitoring tools required by the cloud host.",
                "tip14": "The hard disk controller is the disk drive adapter, which is the interface device between the computer and the disk drive. It detects the state of the disk drive and writes data to and reads data from the disk in accordance with the specified disk data format. Set the Virtio hard drive The control interface can achieve higher hard disk access efficiency.",
                "tip15": "The single-core CPU has a bottleneck in processing network interrupts, which cannot fully meet the needs of the network card. By enabling the multi-queue function of the network card, the network card interrupts in the cloud host can be distributed to different CPUs for processing to meet the needs of the network card. Improve network PPS and bandwidth performance.",
                "tip16": "Select the graphics driver used when the cloud host system starts.",
                "tip17": "If the cloud host created using this image requires hot upgrade or password modification, you need to enable the virtual machine qemu proxy.",
                "tip18": "When virtio is selected as the hard disk controller, you need to select a high-performance hard disk controller. Turn on means the virtio-scsi controller, and turn it off means the virtio-blk controller."
            },
            "volumes": {
                "tip1": "Choose a template for creating cloud hard drives.",
                "tip2": "The storage device associated with this cloud hard drive.",
                "tip3": "The size of the cloud disk must not exceed the remaining quota.",
                "tip4": "Check this parameter, cloud hard disks can be loaded to multiple cloud hosts at the same time, supporting concurrent read and write access by multiple cloud hosts.",
                "tip5": "Set the cloud disk to 'bootable', then the cloud disk can be used to create a cloud host.",
                "tip6": "The size of the data disk after expansion. The minimum value cannot be lower than the previous data disk capacity, and the maximum value cannot be greater than the remaining quota of the data disk.",
                "tip7": "When backing up a cloud hard drive, the selected volume must be a full backup for the first time. Only when a full backup is available can the incremental backup be created.",
                "tip8": "Migrate cloud hard drives from current storage to other storage devices.",
                "tip9": "The QoS of the storage pool is based on this storage pool when the cloud host is created, all have this QoS attribute. For a single cloud host, if you need to modify the cloud hard disk QoS, you can modify the current one in the cloud host disk operation The attribute value of QoS. QoS is realized by the front-end (nova), not by the back-end storage driver.",
                "tip10": "The storage pool associated with the cloud hard disk QoS. After disassociating, the cloud hard disks in the storage pool will not be restricted by QoS.",
                "tip11": "The size of the system disk after expansion, the minimum value cannot be lower than the previous system disk capacity, and the maximum value cannot be larger than the remaining quota of the cloud hard disk."
            
            },
            "cephview": {
                "tip1": "The smaller the value, the business impact is minimal, but the balancing time is very long. The larger the value, the business stalls are obvious, but the balancing time is faster.",
                "tip2": "Set the balance method, namely timing balance or immediate balance of data."
            },
            "backup": {
                "tips1": "When backing up a cloud hard drive, the selected volume must be a full backup for the first time. Only when there is a full backup can an incremental backup be created."
            },
            "backupStrategy": {
                "tips1": "Maximum number of backups retained in backup tasks. This field is valid when a backup referring to this policy is a full backup, and if it is an incremental backup, this field is invalid. If the 'execution frequency' is one time, the field can not be filled in.",
                "tips2": "Represents how long it takes to perform a backup.",
                "tips3": "Setting the start time of the timed task requires a specified year, month, day, hour and minute."
            }
        },
        "message": {
            "required": "Required",
            "textLength": "Max Length 32 Characters",
            "inputnum": "Please fill in a number between 1-10",
            "inputCpuNum": "Please fill in Numbers greater than 1 and less than 16. You can keep two decimal places.",
            "inputRamNum": "Please fill in Numbers greater than 1 and less than 2. You can keep two decimal places.",
            "textLength_8": "Min Length 8 Characters",
            "textLength_16": "Max Length 64 Characters",
            "noZhSpecial": "8-64 characters, which must contain upper and lower case English and numbers. Special symbols only include: '-'、'_'、'.'、'@'.",
            "noIpAddress": "Anybackup management node URL format error",
        },
        "img": {
            "bulkRegistration": "Bulk registration",
            "uploadhttp": "Upload from http",
            "uploadfile": "Uploading from files",
            "progress": "Upload progress",
            "uploadfail": "Mirror upload failure",
            "uploadsuccess": "Image upload success",
            "uploadtips": "Uploading mirrors takes a long time and the current page can not perform other operations. The new page can be opened for other operations. Are you sure you want to open a new page?",
            "subnetip": "Subnet segment",
            "subnetipholder": "Please select the subnet segment",
            "imgSizeTips": "The file size should not exceed 10G and less than the mirror buffer space capacity.",
            "img_updata_tips": "Please do not upload multiple mirrors at the same time, otherwise image upload failure will fail.",
            "int1_200": "The number must between one and two hundred",
            "confirmDelete": "Confirm to delete the image?",
            "system_type": "The Type Of Operation System",
            "imageType": "Image Type",
            "custom_value": "customize",
            "download": "Download",
            "imageSourceType": "Image Source Type",
            "imageFile": "Image File",
            "chooseFile": "Choose File",
            "image": "Image",
            "cancelCustom": "Cancel custom input",
            "buffer_space": "Mirrored buffer space",
            "buffer_space2": "Empty mirrored buffer space",
            "clear_space": "Empty cache space",
            "clear_space_msg": "empty the cache space?",
            "create_image": "Create Images",
            "upload_image": "Upload Image",
            "register_image": "Register Image",
            "custom_image": "Custom Images",
            "make_image": "Make a mirror",
            "edit_image": "Edit Images",
            "DIYscripts": "Custom Script",
            "openConsole": "Open The Console",
            "console": "console",
            "chooseISO": "Choose A ISO",
            "alearyHave": "Already",
            "alearyHaveImage": "Existing mirror image",
            "upload": "Upload",
            "new_instance": "Create Instances",
            "modify": "Edit",
            "del": "Delete",
            "issue_version": "Distribution",
            'system_type':"System type",
            "del_msg": "Confirm delete selected images",
            "search": "Search",
            "img_name": "Images Name",
            "img_tips": "Please do not close this window when uploading mirror, otherwise it will fail to upload mirror! (click again to close)",
            "project_name": "Project Name",
            "type": "Type",
            "public": "Public",
            "private": "Private",
            "img-alarm": "If the network card mode is selected as E1000 or RTL8139, you need to manually modify the network card configuration file ifCFG-eth0 to IFCFG-ENS3, and modify the configuration file DEVICE = ENS3, and then restart the Network service.",
            "is_public": {
                "true": "The public",
                "false": "Private"
            },
            "ISprivate": "private or not",
            "OS": "Type",
            "chooseOS": "Please select Operation System",
            "chooseOSType": "Please select The Type Of Operation System",
            "os_type": "Operation System Type",
            "version": " Version",
            "chooseVersion": "Please enter version",
            "architecture": "Architecture",
            "protected": "Protected",
            "status": "Status",
            "created_at": "Create Time",
            "img_detail": "Images Detail",
            "update_at": "Update time",
            "username": "User name",
            "required": "Required",
            "less_than_32_bit": "Max Length 32 Characters",
            "copy_addr": "Copy Address",
            "disk_format": "Disk Format",
            "disk_size": "Disk Size(GB)",
            "input_disk_size": "Please enter disk size",
            "img_size": "System Disk(GB)",
            "ok": "Ok",
            "cancel": "Cancel",
            "detail": "Detail",
            "instanceName": "Instance Name",
            "imgChoice": "Select Images",
            "num": " Number",
            "name": "name",
            "conf_option": "Config Option",
            "conf_type": "Config Type",
            "chooseConf": "Please select configuration Type",
            "net_setting": "Network Configuration",
            "network": "Network",
            "chooseNet": "Please select Network",
            "config_IP": "Config IP",
            "repeatIP": "This IP is in use,please enter others",
            "portPolicyForbidden": "no privilege to edit this fixed IP",
            "netChoice": "Select Network",
            "advOption": "Advanced Option",
            "selfConfig": "Optional Config",
            "choiceConfig": "Select Config",
            "passwd": "Password",
            "pswdRepeat": "Repeat Password",
            "unmatchedPasswd": "Password Inconsistency",
            "keypair": "Keypair",
            "keypairChoice": "Select Keypair",
            "firewall": "Firewall",
            "launchArea": "Launch Area",
            "launchAreaChoice": "Select Launch Area",
            "launchNodeChoice": "Select Launch Node",
            "localStorage": "Local Storage",
            "conf_detail": "Config Detail",
            "launchNode": "Launch Node",
            "core": "Core",
            "memory": "Memory",
            "vol_size": "Disk Size(GB)",
            "imgtype": "Mirror Type",
            "min_disk": "Minimum disk",
            "vol_sizeChoose": "Please Enter Image Disk Size",
            "trueSize": "Image Size",
            "isenabled": "Enabled",
            "canUse": "Available",
            "uefiMod": "UEFI module",
            "scsiModel": "SCSI控制器",
            "seniorAttr": "Define high-level attributes",
            "busType": "Hard Disk Controller",
            "selectBusType": "Please select hard Disk Bus Type",
            "videoModel": "Network Card Mode",
            "selectVideoModel": "Please select network Card mode",
            "videoQueue": "Network Card multi queue",
            "vifModel": "Graphics Card Mode",
            "selectVifModel": "Please select graphics Mode",
            "biosMenu": "BIOS Startup Menu",
            "qemu": "Virtual Machine QEMU Agent",
            "diskCtrl": "High Performance Hard Disk Controller",
            "tip_message": "Please edit Operation System or System disk size of the image first or arch .",
            "iso_message": "If ISO is empty, please add the operating system to the mirror management edit module",
            "repeatDefaultImg": "SystemCheck is the default mirror name of the system. Please re-enter.",
            "all": "All",
            "type":"Type",
            "curImg": "Current mirror",
            "imageUploadFailedTip":"Do not close this window in the upload mirroring process, otherwise it may cause image upload failure! (Click again to turn off)",
            "dialog": {
                "download": "Download Image",
                "register_image": "Registered image"
            },
            "label": {
                "type": "Mirror type",
                "format": "Mirror format",
                "regFormat": "Format of registration image"
            },
            "placeholder": {
                "name": "Please enter the image name",
                "type": "Please select the mirror type"
            },
            "msg": {
                "tips": "Note that it is not recommended to download the mirror files over {{size}}G online."
            },
            "table": {
                "status": {
                    "unrecognized": "Unrecognized",
                    "active": "Active",
                    "saving": "Saving",
                    "queued": "Queued",
                    "killed": "Killed",
                    "pending_delete": "Pending Delete",
                    "deleted": "Deleting",
                    "resized": "Resized"
                },
                "is_public": {
                    "true": "true",
                    "false": "false"
                },
                "is_protected": {
                    "true": "true",
                    "false": "false"
                },
                "canUse": {
                    "true": "true",
                    "false": "false"
                }
            },
            "osType": {
                "windows": "Windows",
                "linux": "Linux",
                "null": "",
                "unknown": ""
            },
            "arch": {
                "x86_64": "64",
                "i686": "32 bit",
                "x86": "32 bit",
                "aarch64": "aarch64"
            },
            "imageDownload":{
                "imageName":"Mirror name:",
                "imageFormat":"Mirror format:",
                "imageId":"Mirror ID:",
                "illustrate":"illustrate:",
                "illustrateRemark":"Mirror download only supports command line mode, and supports transformation of mirror formats, supported formats include 'Raw', 'qcow2', 'VMDK'. The specific operation is as follows:",
                "imageDownloadCommand":"1. Mirror download command:",
                "imageFormatCommand":"2. Mirror format conversion command:",
                "sourceImageFormat":"<Source mirror format>",
                "destinationImageFormat":"<Purpose Mirror Format>",
                "sourceImageName":"<The name of the source mirror>",
                "destinationImageName":"<Destination Mirror Name>"
            }
        },
        "makeImage": {
            "osStatus": {
                "1": "Creating",
                "2": "Created",
                "3": "Uploading",
                "4": "Uploaded",
                "5": "Finished",
                "6": "Failed",
                "7": "Upload failed"
            },
            "isPrivate": {
                "true": "true",
                "false": "false"
            },
            "uefi": "Boot boot",
            "useUefi": "Boot through UEFI",
            "confirmUpoad": "Note: image upload, the mirror to do any operation will be invalid",
            "other_disk": "Additional Tool Disk",
            "other_soft_disk": "Additional Tool Soft Disk",
            "disk": "Tool Disk",
            "soft_disk": "Tool Soft Disk",
            "makeImage": "Click And Select",
            "inputImage": "Please Input Image Name",
            "int1_150": "The number must between one and one hundred and fifty",
            "addMakeImgTip": "Making image will Occupy one instance quota and one volume quota,Please upload the image in time and release the quota after image creation successfully"
        },
        "instances": {
            "nowNoNetwork":"No network at present",
            "ethernetType": "Ethernet type",
            "source": "source",
            "migration_time": "Migration time",
            "source_host": "Migrate the original host",
            "target_host": "Migrate the target host",
            "migration_type": "Migration type",
            "migration_status": "Migration status",
            "evacuation": "evacuation",
            "liveMigration": "Thermal migration",
            "adjustConfig": "Adjust configuration",
            "coldMigration": "cold migration",
            "migrationOK": "Migration complete",
            "migrationError": "Migration error",
            "done": "The virtual machine is scattered to the target node",
            "failed":"The migration failed",
            "label": "Label",
            "useSnaptip": "if Use snapshot to make image ,you can only create instance in original storage",
            "useSnap": "Use snapshot to make image",
            "instance": "Instance",
            "new": "new",
            "boot": "boot",
            "stop": "stop",
            "vnc": "vnc",
            "moreNet": "Network Operation",
            "moreVol": "Volume Operation",
            "moreOps": "More Operations",
            "restart": "restart",
            "forceShupdown": "Force Shutdown",
            "bindVgpu": "Binding vGPU",
            "unbindVgpu": "Unbound vGPU",
            "del_instance": "Delete Instance",
            "force_del_instance": "Force Delete Instance",
            "attachVolume": "Volume Attach",
            "detatchVolume": "Volume Detach ",
            "edit": "Edit instance name",
            "addNIC": "Add NIC",
            "clockSynchronization": "Clock synchronization",
            "closeClockSync": "Turn off clock synchronization",
            "modifyFixedIp": "Modify Fixed Ip",
            "modifyFixedIp_2": "Modify Ip Address",
            "addNICAlertInfo": "You need to edit the new NIC's configuration and configure policy route in VM after adding a new NIC.",
            "modifyFixedIpAlertInfo": "You need reboot the VM to make modified fixed Ip to take effect",
            "portPolicyForbidden": "This Fixed IP is belong to the Network that created by other tenant,you have no privilege to modify",
            "moveNIC": "Move NIC",
            "bindingPublicIP": " Associate Floating IP",
            "releasingPublicIPBindings": "Disassociate Floating IP",
            "setBandwidth": "Set Bandwidth",
            "modifyIpAddr": "Modify IP address",
            "changeMac": "Edit MAC address",
            "makeImg": "Edit images",
            "delAnnitify": "Confirm to delete the select annitify?",
            "loadIptables": "Load SecurityGroup",
            "networkCardConfig": "Network Card Security Configuration",
            "networkCardPolicy": "Network Card Security Policy",
            "addAddressPair": "Add address pairs",
            "backup": "Backup",
            "migrate": "Migrate",
            "upgrade": "upgrade",
            "suspend": "suspend",
            "unPause": "Recover Pause Instance",
            "hang": "Suspend Instance",
            "uphang": "Recover Suspend Instance",
            "snapshot": "Create snapshot",
            "snapshotName": " Snapshots Name",
            "resize": "Resize",
            "search": "Search",
            "projectName": "Project Name",
            "hostName": "Host Name",
            "imgName": "Images Name",
            "privateIP": "private IP",
            "chooseIP": "select IP",
            "IPaddress": "IP Address",
            "bandwidth": "Bandwidth",
            "inputNIC_bandwidth": "Please input Netcard bandwidth",
            "publicIP": "public IP",
            "status": "status",
            "create_at": "Create Time",
            "createtime": "Create time",
            "deletetime": "Delete Time",
            "bootMethod": "Boot Method",
            "cloudInstanceDetail": "Instance Detail",
            "cloudInstanceName": "Instance Name",
            "instancesErrorStart": "Cloud Host Abnormal Restart",
            "image": "Images",
            "configration": "configuration",
            "volume": " Volume",
            "securegroup": "Secure Group",
            "editinstanceName": "Instance ",
            "chooseVgpu": "Choose vGPU",
            "mac_addr": "Mac Address",
            "mac_addr_tip": "The configuration will be effective after rebooting the Instance",
            "configration_tip": "Please do not close the browser window during the resizeing",
            "currentSetting": "Current Setting",
            "evacuate": "Instance Evacuate",
            "mountISO": "mount ISO",
            "unmountISO": "unmount ISO",
            "card": "Network card",
            "selectNIC": "Please  select Netcard",
            "nic_bandwidth": "Netcard Bandwidth",
            "none": "none",
            "headerCheckbox": {},
            "editPsw": "Change Password",
            "ttEquipment": "Pass-through Device",
            "deviceType": "Device Type",
            "hostSyncwithIns": "Operating system and host time synchronization state",
            "rebuild": "one-key reset",
            "affinityRole": "Affinity rules",
            "affinity": "Affinity",
            "notAffinity": "The affinity",
            "affinityMembers": "Members of the affinity group",
            "uhostName": "Cloud host name",
            "imageName": "Name of the mirror",
            "status":"Status",
            "privateIP":"PrivateIP",
            "publicIP":"PublicIP",
            "anti-affinity": "anti-affinity",
            "cdManage": "Optical Disc Management",
            "settingHa": "Set high availability",
            "coldMigration": "ColdMigration",
            "cdLoad": "Load",
            "cdUninstall": "Discharge",
            "cdStatus": "Loaded",
            "cdName": "CD name",
            "cdSize": "Capacity Size (GB)",
            "cdLoadInfo": "Loading information",
            "cdDescribe": "Equipment Description",
            "compatibility": "USB Compatibility",
            "compatible": "Compatible",
            "cdConfrimISO": "Are you sure you want to uninstall the ISO device?",
            "stockTransfer": "Cloud Hard Drive Migration",
            "volumesQoS": "Disk QoS",
            "choiceVolumesQoS": "Select cloud Drive",
            "newVmGroup": "New Vm Group",
            "diskInfoDetail": "Disk Information",
            "diskSpace": "Disk Space:",
            "storageEquipment": "Corresponding storage device:",
            "fromBackupRecover": "From backup restore",
            "5min": "Five minutes",
            "1hour": "1 hour",
            "1day": "One day",
            "notAvailable": "No.",
            "notAvailableLog": "No log information yet",
            "networkCard": "Card",
            "publicNetwork": "Public IP",
            "paasNet": "PaaS network",
            "paasSubNet": "PaaS subnet",
            "startManage": "Startup Sequence Management",
            "vmErrorManage": "Virtual Machine Exception Management",
            "monitorDetail": "Monitoring Details",
            "enabled": "Enabled",
            "notEnabled": "Not enabled",
            "isSync": "Synchronized",
            "calculate": "Computing",
            "volumeDetail": "Cloud Hard Drive Details",
            "noAvailableNet": "No public IP currently available",
            "ipRepeat": "IP address repeated, please re-enter",
            "dataLoading": "Data under load",
            "nfsInfo": "Migrating to ceph is not supported if NFS system disks are mounted",
            "servicemonitor": "Service Monitoring",
            "vmClone": "Cloud Host Cloning",
            "diskSelection": " disk Selection",
            "onlyCloneSystem": "Clone System only",
            "bothClone": "Clone system disks and data disks",
            "specifyDepartment": "Specify the department",
            "specifyProject": "Specify a project",
            "number": "number",
            "network": "network",
            "specifiedSubnet": "The specified subnet",
            "specification": "Specification",
            "basicInfo": "Basic information",
            "networkConfig": "Network Configuration",
            "hostBind": "High availability",
            "loadingLocalDisk": "Load local disk",
            "detachLocalDisk": "Unmount local disk",
            "shelved":"shelved",
            "cancelShelving":"cancelShelving",
            "settingHaModal": {
                "title": "Set high availability",
                "on": "on",
                "off": "off",
                "tips1": "Mounting local disks, mounting GPUs, enabling virtual machines with numa, large page memory, using SR-IOV network, enabling DPDK functions, and enabling virtual machines with device transparent transmission, high availability cannot be achieved even after forced opening.",
                "tips2": "After high availability is turned off, cloud hosts cannot be migrated and dispersed.",
                "settingHaTips": "High availability can be set for cloud host startup, shutdown, on-hook, and pause states"
            },
            "unit_list": {
                "onice": "A",
                "hour": "By the hour",
                "day": "By the day",
                "week": "Weekly",
                "month": "Monthly"
            },
            "equipmentList": {
                "usb": "USB device",
                "gpu": "The GPU device",
                "fpga": "The FPGA device"
            },
            "detailtable": {
                "disktype": "Disk Type",
                "diskname": "Disk Name",
                "disksize": "Disk Size (GB)",
                "diskequip": "Storage Device",
                "diskpool": "Storage Pool"
            },
            "addinstances": {
                "tipes": "Batch creation of cloud hosts from cloud hard drives and backups is not supported",
                "capacity": "Disk Capacity",
                "addDataDisk": "Add Data Disk",
                "disk_buses": "Hard Disk Controller",
                "noFlavor": "No cloud host specifications available",
                "addFlavor": "Add Custom Specifications",
                "chooseBoot": "Cloud Host Startup Source",
                "chooseStorge": "Select the source store",
                "confirmInfo": "Confirmation message",
                "unmatchedFalvorTip": "No matching specifications found",
                "batchNoCephTip": "No ceph storage and mirror image can not be saved. Batch creation is not available",
                "snapshot": " Snapshots",
                "newInstance": "Create Instance",
                "vmgroup": "Cloud master Unit",
                "vmgroupPlaceholder": "Cloud host function may cause cloud host high availability or migration failure",
                "memberEnough": "The group is full",
                "theMemberEnough": ", the membership is full",
                "canCreate": ", can be created",
                "unitMember": "Members",
                "detail": "Detail",
                "instanceName": "Instance Name",
                "instanceNameInput": "Please enter instance name",
                "hostName": "hostname",
                "hostNamePlaceholder": "Please enter the hostname specified by the virtual machine operating system name",
                "hostName2": "Hostname",
                "hostNameInput": "Please enter hostname",
                "required": "Required",
                "num": " Number",
                "imgChoice": "Select Images",
                "os": "Type",
                "arch": "Architecture",
                "imgNameChoice": "Select Images Name",
                "oxChoice": "Select Operation System",
                "type": "Type",
                "private": "Private",
                "public": "Public",
                "conf_type": "Config Type",
                "net_setting": "Network Config",
                "name": " Name",
                "netChoice": "Select Network",
                "net": "Network",
                "config_ip": "Configure IP",
                "advOption": "Advanced Option",
                "selfConfig": "Optional Config",
                "choiceConfig": "Select Config",
                "pass_setting": "Password Setting",
                "passwd": "Password",
                "repeatPasswd": "Repeat Password",
                "notSamePasswd": "Password Inconsistency",
                "password": "Please enter Password",
                "comfirmpassword": "Confirm Password",
                "keypair": "Keypairs",
                "keypairChoice": "Select Keypairs",
                "securityGroupChoice": "Select SecurityGroup",
                "launchArea": "Launch Area",
                "launchAreaChoice": "Select Launch Area",
                "launchNode": "Launch Node",
                "launchNodeChoice": "Select launch Node",
                "localStorage": "Local Storage",
                "conf_detail": "Config Detail",
                "memory": "Memory",
                "ok": "Ok",
                "cancel": "Cancel",
                "core": " Core",
                "enter": "Please Enter",
                "integer": "Of Positive Integer",
                "ip_exist": "IP Address Already Exists, Please Re-enter",
                "ipAddr": "IP Address",
                "modifyAs": "Modify as",
                "isUseLocal": "Start method",
                "local": "Use Local",
                "useLocal": "Use Local",
                "ebs": "EBS",
                "toyou": "Toyou",
                "else": "Else",
                "voltype": "Volume Type",
                "securityGroup": "securityGroup",
                "dataDisk": "Data Disk",
                "localDisk": "Local Disk",
                "local_sysytem": "Local System Disk",
                "local_data": "Local Data Disk",
                "cputopu": "CPU topology",
                "unset": "Not set",
                "volume_set": "volume setting",
                "batchTip": "There is no cache volume for the mirrored version of the qcow2 format, so batch creation is not recommended",
                "volumsTip": "Insufficient storage pool capacity",
                "volumsErrorTip": "Storage Pool Data Acquisition Exception",
                "x86_64": "x86_64",
                "i686": "x86_64",
                "aarch64": "aarch64",
                "vgpuType": "VGPU specifications",
                "vgpuName": "VGPU name",
                "vgpuTypePlaceholder": "Please select",
                "vgpuNamePlaceholder": "Please select",
                "isUseLocal": {
                    "0": "No",
                    "1": "The Site",
                    "2": "Cloud Hard Drive"
                },
                "scriTips": "After the virtual machines of this disk controller type are migrated, the disk startup order needs to be adjusted",
                "noKeywords": "Not to a backup - vol, fixedImageInstanceName beginning",
                "nokey": "SystemCheck is the keyword",
                "numaNode": "NUMA node",
                "numaOc": "NUMA strategy",
                "numaOcTips": {
                    "preferred": "Preferred: NUMA resources allocated on the specified node, if the allocation fails, continue to allocate on other nodes.",
                    "strict": "Strict: NUMA resources must be allocated on specified nodes. Failure to allocate causes strict cloud hosting to be created using this specification."
                },
                "vcardc": "Virtual network Card",
                "bigmemVal": "Large page memory size",
                "bigmemTips": {
                    "m1": "Note that the configuration information needs to be adjusted before setting this parameter;",
                    "m2": "2. Physical machine will be turned off when setup is complete."
                },
                "isLink1": "Direct",
                "isLink2": "Nondirect connection",
                "nodeSetting": "NUMA node setup",
                "cpuError": "The total NUMBER of Cpus does not equal the maximum number of CPU cores in the selected specification",
                "memError": "The total amount of memory does not equal the maximum amount of memory in the selected specification",
                "numaError": "The node CPU or memory total configured must be equal to the maximum CPU or memory of the selected specification, the current specification is: {{CPU}} core {{memCtr} G, the maximum CPU core is {{vcPUS_max}} core, the maximum memory is {{{ram_maxCtr} G",
                "nodeAdv": "Node Setup",
                "nodeLabel": {
                    "detail": "Details",
                    "clear": "Clear",
                    "node": "Node selection",
                    "cpu": "Numa {{value}} CPU",
                    "mem": "Numa {{value}} memory",
                    "addTips": "(The number of added nodes cannot be greater than the number of nodes)"
                },
                "nodePlaceholder": {
                    "node": "Please select node",
                    "numaNode": "Please select NUMA node",
                    "numaOc": "Please select NUMA Policy",
                    "vcardc": "Please select virtual Network Card",
                    "bigmemVal": "Please select Large page memory",
                    "cpu": "Please enter the number of Cpus",
                    "cpu1": "Please select CPU",
                    "mem": "Please enter memory size (MB)"
                },
                "randomPassword": "Random password",
                "copyPassword": "Copy password",
                "copySuccess": "Copy successful",
                "hostNameSuffix": "clone of",
                "stockTransferNotImageTip":"Snapshot virtual machine does not support cloud hard disk migration"
            },
            "info": {
                "configureInfo": "Configuration Information",
                "logInfo": "Log Information",
                "localDisk": "Disk",
                "memory": "Ram",
                "core": "Core",
                "sriov": "(invalid)"
            },
            "serviceMonitor": {
                "addService": "Add Service",
                "addServiceTips": "To enable this function, VMS must support monitoring. You can create an image created by cloudToolsMonitor. iso.  ",
                "serviceName": "Service Name",
                "status": "State",
                "description": "Description",
                "delTips": "Are you sure you want to delete your selection?",
                "states": {
                    "normal": "Normal",
                    "abnormal": "Abnormal"
                },
                "serviceNm": "Service Name",
                "serviceUrl": "Service url",
                "port": "Port",
                "userName": "User Name",
                "password": "Password",
                "placeholder": {
                    "serviceNm": "Please select Service",
                    "serviceUrl": "Please enter the service URL",
                    "port": "Please enter port",
                    "userName": "Please enter user name",
                    "password": "Please enter your password"
                }
            },
            "net": {
                "addNet": "Add NIC",
                "removeNet": "Remove NIC"
            },
            "removeInsNetCard": "* If remove the default NIC of instance, the network would be cut down",
            "osedit": {
                "editInsName": "Edit Instance Name"
            },
            "vgpuModel": {
                "bind": "Binding vGPU",
                "unbind": "Unbound vGPU"
            },
            "FloatingIp": {
                "ipAddr": "IP Address",
                "bindIP": " Associate floating IP",
                "unbindIP": "Disassociate floating IP",
                "noFloatingIp": "No more floating IP",
                "ipSelect": "Please select public IP"
            },
            "disk": {
                "volumeChoice": "Select Volume"
            },
            "mkImg": {
                "makeImg": "Create Images"
            },
            "backups": {
                "title": "backup",
                "day": "day",
                "week": "week",
                "name": "backup Name",
                "backups_status": "Backup status",
                "backups_type": "Backup Type",
                "cycle": "cycle",
                "rotation": "rotation",
                "systemDisk": "Select system Panel",
                "dataDisk": "Select Data Disk",
                "systemDiskPlace": "Click select System Panel",
                "nosystemDiskPlace": "Not yet available system disk",
                "localsystemDiskPlace": "Backup of this site is not supported",
                "dataDiskPlace": "Click select Data Disk",
                "nodataDiskPlace": "No available data disk has been retrieved.",
                "leastchooseDisk": "Please select at least one data disk or system disk",
                "cannotIncrementalTip": "Incremental backups cannot be created at this time; full available backups need to be associated with them"
            },
            "migrates": {
                "node": "Node Destination",
                "disk": "Migrate local disk",
                "quota": "Allowd Over Quota",
                "tips": "Current virtual machine configuration",
                "tips2": "This node has insufficient resources and cannot be migrated, please select a new node!"
            },
            "snapshot": {
                "title": "Instance Snap",
                "systemDisk": "Instance SystemDisk",
                "dataDisk": "Instance DataDisk",
                "systemDiskPlace": "Clicck to choose Instance SystemDisk",
                "nosystemDiskPlace": "Not yet available system disk",
                "localsystemDiskPlace": "Snapshot of this site is not supported",
                "dataDiskPlace": "Clicck to choose Instance DataDisk",
                "nodataDiskPlace": "No available data disk has been retrieved.",
                "leastchooseDisk": "Please choose Disk",
                "timedSnap": "Timed Snap",
                "firstTimedSnap": "Snapshot time first performed",
                "frequency": "snapshot frequency",
                "timeUint": "Hour",
                "holdNumber": "Snapshot retention number",
                "addtimeSnapTip": "When the snapshot generated by the time snapshot exceeds the maximum number of reserved copies, the earliest snapshot file will be deleted, and the system will reserve only the number of snapshots of the maximum reserved number",
                "timeInterval": "time Interval",
                "frequencyUnit": {
                    "hour": "Hour",
                    "minute": "Minute",
                    "day": "Day",
                    "week": "Week",
                    "month": "Month"
                },
                "task": {
                    "0": "Timed Snapshot Task",
                    "1": "Timed Backup Task",
                    "type": "Task Type",
                    "taskPlace": "Please select task Type",
                    "new": "New Task",
                    "edit": "Edit task",
                    "null": "Timed Snapshot Task",
                    "keepBackupsNum": "Keep up to date backup copies",
                    "backupsTip": "When the last backup copy is reached, the previous corresponding backup file will be deleted.A set of 1 full backup and n-1 incremental backups that will be deleted by the program when the set deletion condition is met."
                
                },
                "taskStatus": {
                    "PAUSED": "Inactive",
                    "NORMAL": "Activate",
                    "onetaskStatus": "Single mission has expired and cannot be activated"
                }
            },
            "rollback": {
                "title": "Instance Snapshot RollBack",
                "systemDisk": "SystemDisk Snapshot",
                "dataDisk": "DataDisk Snapshot",
                "systemDiskPlace": "Clicck to choose SystemDisk Snapshot",
                "nosystemDiskPlace": "No system disk snapshot that supports snapshot rollback",
                "dataDiskPlace": "Clicck to choose DataDisk Snapshot",
                "nodataDiskPlace": "No disk snapshot that supports snapshot rollback",
                "leastchooseDisk": "Please choose Snapshot"
            },
            "backupRestore": {
                "title": "Restore - Disk Operations",
                "systemDisk": "System Disk Backup",
                "dataDisk": "Data Disk Backup",
                "systemDiskPlace": "Select System Disk backup",
                "nosystemDiskPlace": "System disk backup that supports restoration has not been obtained yet",
                "dataDiskPlace": "Click to select Data Disk Backup",
                "nodataDiskPlace": "Not yet retrievable disk backup",
                "leastchooseDisk": "Please select at least one data disk backup or system disk backup"
            },
            "mount": {
                "image": "image",
                "size": "size"
            },
            "quota": {
                "domain_check": "The parameter no less than the maximum quota for the project",
                "proCpu": "Project quota of CPU is insufficient",
                "proRam": "Project quota of Ram is insufficient",
                "proIns": "Project quota of Instance is insufficient",
                "proPhyIns": "Project Physical Cloud Host Quota insufficient",
                "proVolumes": "Project quota of Volume is insufficient",
                "proGigabytes": "Project hard disk quota insufficient",
                "domCpu": "Domain quota of CPU is insufficient ",
                "domRam": "Domain quota of Ram is insufficient",
                "domIns": "Domain quota of Instance is insufficient",
                "domPhyIns": "Department physical Cloud host Quota insufficient",
                "domVolumes": "Domain quota of Volume is insufficient",
                "domGigabytes": "Department hard disk quota insufficient",
                "proInfo": "Project quota Detail",
                "proInfoIns": "Instance Number",
                "proInfoRam": "Ram Number",
                "proInfoCpu": "CPU Number",
                "proInfoVolumes": "Volume Number",
                "domInfo": "Domain Quota Detail",
                "domInfoIns": "Instance Number",
                "domInfoRam": "Ram Number",
                "domInfoCpu": "CPU Number",
                "domInfoVolumes": "Volume Number",
                "used": "used",
                "coreMaxText": "CPU Can Not Exceed",
                "ramMaxText": "Ram Can Not Exceed"
            },
            "table": {
                "status": {
                    "all": "all",
                    "active": "active",
                    "stopped": "stopped",
                    "shutoff": "shutoff",
                    "error": "error",
                    "building": "building",
                    "build": "build",
                    "powering-off": "Powering Off",
                    "powering-on": "Powering On",
                    "deleting": "deleting",
                    "deleted": "deleted",
                    "soft-deleting": "deleting",
                    "soft-delete": "deleting",
                    "reboot_pending": "rebooting",
                    "reboot": "reboot",
                    "paused": "paused",
                    "pausing": "pausing",
                    "unpausing": "unpausing",
                    "suspended": "suspended",
                    "suspending": "suspending",
                    "resuming": "resuming",
                    "verify_resize": "resizing",
                    "resize": "resize",
                    "resize_prep": "resizing",
                    "resize_finish": "resizing",
                    "resize_migrating": "resizing",
                    "resized": "resized",
                    "revert_resize": "Cancelresize",
                    "image_backup": "Image backup",
                    "image_snapshot": "Creating Snapshot",
                    "spawning": "spawning",
                    "up": "up",
                    "down": "down",
                    "migrating": "migrating",
                    "rebuilding": "rebuilding",
                    "rebuild": "rebuild",
                    "unknow": "unknow",
                    "unrecognized": "unrecognized",
                    "block_device_mapping": "Creation in progress",
                    "image_snapshot_pending": "Prepare a mirror snapshot",
                    "image_pending_upload": "Image waiting to be uploaded",
                    "image_uploading": "Superimposed on mirror image",
                    "snapshot_revert": "Snapshot rollback",
                    "snapshot_revert_penging": "Snapshot waiting for rollback",
                    "updating_password": "Update password in",
                    "resize_migrated": "Adjusting the configuration",
                    "resize_reverting": "Adjust configuration cancel in progress",
                    "resize_confirming": "Configuration adjustment is confirmed",
                    "rebooting": "Reboot in progress",
                    "reboot_pending":"wait for restart",
                    "reboot_started": "Reboot in progress",
                    "rebooting_hard": "Forced restart in progress",
                    "reboot_pending_hard": "Waiting for a forced restart",
                    "reboot_started_hard": "Forced restart in progress",
                    "rescuing": "In rescue",
                    "unrescuing": "Call off the rescue.",
                    "rebuild_block_device_mapping": "Under reconstruction",
                    "rebuild_spawning": "Under reconstruction",
                    "restoring": "Restoring",
                    "shelving": "",
                    "shelving_image_pending_upload": "",
                    "shelving_image_uploading": "",
                    "shelving_offloading": "",
                    "unshelving": "",
                    "cloning": "In the clone",
                    "networking": "Creation in progress",
                    "scheduling": "Creation in progress",
                    "soft_deleted": "Soft delete",
                    "shelved_offloaded":"Put aside",
                    "shelving":"shelving",
                    "unshelving":"unshelving",
                    "lock": {
                        "true": "Lock",
                        "false": "Not locked"
                    },
                    "hostBind": {
                        "open": "Open",
                        "close": "Close"
                    }

                },
                "Local": "Local",
                "msg":"The original image does not exist and cannot be reinstalled"
            },
            "confName": {
                "m1.tiny": "Tiny Config",
                "m1.small": "Small Config",
                "m1.medium": "Medium Config"
            },
            "conf": {
                "m1.tiny": "Tiny Config: CPU 1 Core Ram  512M support Volume",
                "m1.small": "Small Config: CPU 1 Core Ram  2G support Volume",
                "m1.medium": "Medium Config: CPU 2 Core Ram  4G support Volume",
                "pretip": "Medium Config: CPU ",
                "memtip": " Core Ram ",
                "endtip": "G support Volume",
                "core": " Core",
                "dataDisk": "GB Data Disk "
            },
            "tipMsg": {
                "startServer": "Confirm Start Instance",
                "startIronicServer": "Are you sure you want to start the physical machine?",
                "stopServer": "Confirm Stop Instance",
                "delSever": "Confirm Delete Instance",
                "reboot": "Confirm Reboot Instance",
                "shutdown": "Confirm Shutdown Instance",
                "osSuspend": "Confirm Suspend Instance",
                "osUpPause": "Confirm Pause Suspend Instance",
                "osHang": "Confirm Hang Instance",
                "osUpHang": "Confirm Pause Hang Instance",
                "restoreSever": "Confirm Restore Instance",
                "forcedelSever": "Confirm Delete Instance",
                "forcedelvm": "Confirm Force Delete Instance",
                "evacuate": "Are you sure you want a cloud host rescue?",
                "delsanpJob": "Scheduled tasks that will simultaneously delete all cloud hard disks from the cloud host",
                "rmVolumeSnapJob": "Uninstalling the cloud drive will also remove the drive from the scheduled task.",
                "delUserJob": "All scheduled tasks under this user will be deleted simultaneously.",
                "unloadTtEquipment": "Are you sure you want to uninstall the passthrough device?",
                "bindFloatingIpVpnInfo": "Binding floating IP will conflict with the IPSec VPN function,please check whether the IPSec site connection has been established.",
                "uninstalltTip": "It is risky to uninstall the cloud hard disk during use. Please uninstall it in the operating system of the cloud host mounted on the cloud hard disk before performing this operation.",
                "stock_transferTip": "The migration of cloud hard disk needs to meet the requirement that the mounted cloud host is in the boot state or shutdown state",
                "stock_transferTip1": "Cloud host is migrating cloud hard disk, please operate later",
                "oneStoragePoolCanNotTransfer": "Currently there is only one storage pool, so cloud hard disk migration is not possible.",
                "clockSync": "Are you sure to turn on the selected cloud host to synchronize with the host clock?",
                "closeClockSync": "Are you sure you turn off the selected cloud host to synchronize with the host clock?",
                "opsOperation": "This operation is limited to operation and maintenance operation, please confirm the virtual machine state before resetting",
                "currentIp": "* Please enter the correct IP address",
                "currentMac": "* Please enter the correct MAC address",
                "isomsg": "When using iso to create a virtual machine, please install the operating system on the first disk",
                "fpgaOperate": "When the cloud host is turned on, it can only query, not load and unload FPGA operations.",
                "connectPaas": "To access PaaS resources, add a network card (PaaS network) to the cloud host.",
                "unbindVgpu": "Are you sure you want to unbind the vGPU?",
                "editIso": "After creating an image of the virtual machine created by iso, you need to manually edit the image properties on the image management page",
                "restoreDisk": "Are you sure you want to restore the cloud hard disk?",
                "fpgaOrGpuOperate": "When the cloud host is powered on, it can only be queried, but cannot uninstall GPU and FPGA.",
                "forceDelVolume": "Are you sure you want to completely delete the data disk?",
                "shelveServer":"Do you determine that the host is going to stand?",
                "unShelveServer":"Do you sure you want to cancel the cloud host?",
                "unInsShelve":"Cloud hosts in the state cannot uninstall the cloud hard disk."
            },
            "placeholder": {
                "resize_n": "No configuration types available",
                "resize_c": "Please select configuration Type",
                "no_canLoad_drvice": "Devices temporarily unloaded",
                "limit_device_USB": "No more than 3 USB devices can be loaded simultaneously on the same cloud host",
                "limit_device_GPU": "Only one GPU device can be loaded at most on the same cloud host",
                "limit_device_FPGA": "No more than one FPGA device can be loaded simultaneously on the same cloud host"
            },
            "tip": {
                "sigtip": "Batch operation not allowed",
                "choose_tip": "Please select Cloud Host",
                "choose_tip_more": "Batch operation not supported",
                "change_Mac_tip": "Instance must be in shutdown state",
                "resize_tip": "Instance must be in shutdown state",
                "snapshot_rollback_tip": "Cloud host snapshot rollback requires shutdown",
                "not_get_vmVolume": "The cloud hard disk under the cloud host has not been obtained yet",
                "editPswTip": "The password must be changed on the boot",
                "rebuildTip": "A button reduction requires mirroring information and is performed on the boot, shutdown or error condition",
                "rebuildTip1": "One-key restore needs to meet the system disk for the state of use",
                "sure_rebuild": "The one-button reduction will restore the data of the cloud host system disk to the state of the initial creation of the cloud host, please confirm whether to do this operation!",
                "repeatNameTip": "The name has been used and cannot be created",
                "coldMigrate": "Cold migration requires shutdown and no pass-through device is loaded.",
                "coldMigrate1": "Cold migration needs to take place on virtual machines without vGPU binding",
                "migrateTip": "Hot migration needs to take place when the device is switched on and no pass-through device is loaded.",
                "bootOrderTip": "Startup sequence management to be done in shutdown state",
                "backupRestoreTip": "Restore must be done in shutdown state",
                "ttEquimentTip": "Device passthrough shall be performed in the state of power on or power off",
                "normalStatusTip": "This operation is not supported in this state",
                "normalStartLoadFpga": "This operation is not supported when the FPGA card is loaded on power up",
                "makImgTip": "To make the image, the system disk should be in use",
                "migrateTip1": "Hot migration should satisfy the use state of the contentStatusType disk of the system",
                "coldMigrateTip1": "Cold migration shall meet the service state of the system panel",
                "storageTypeTip": "This operation is not supported in the storage where the system resides.",
                "migrateTip2": "Hot transfer to be done at boot state",
                "migrateTip3": "Hot migration should take place on virtual machines without vGPU binding",
                "coldMigrateTip2": "Cold migration should be carried out in shutdown state",
                "resize_tip2": "Configuration adjustment should be done in shutdown state",
                "resize_tip3": "Vgpu-bound Virtual machines are not configurable",
                "manageCDTip": "Optical disk management shall be conducted in the shutdown state",
                "resetTip": "When the cloud host is in the wrong state and the cloud host is confirmed to be normal through operation and maintenance, the state can be reset.",
                "localTip": "This feature is not supported by hosts in this site",
                "forceStopTip": "Forced shutdown must meet the error state, and the physical host has an assigned value.",
                "oshotgradeTip": "Hot upgrades need to be done on boot",
                "oshotgradeTip1": "The configuration of this virtual machine does not support this feature.",
                "not_get_info": "No mirroring information yet.",
                "shutdownTip": "Please select the virtual machine in shutdown state",
                "maxSizeTip": "Physical node system disk space is insufficient to mirror",
                "poolSize_rebuild": "The storage device where the cloud host is located has insufficient resource pool capacity and cannot be restored",
                "loading_Disk_Tip": "Please do this under the condition of power on and power off",
                "loading_Disk_Tip1": "Cloud host is migrating cloud hard disk, please operate later",
                "cpuExceedTip": "Target CPU cores must not exceed the number of CPU cores on a single physical node",
                "cpuExceedTip1": "Insufficient CPU cores on physical nodes, please clean up resources",
                "ramExceedTip": "Physical node out of memory, please clean up resources",
                "stock_transferTip": "",
                "insHasBackupJob": "The deleted cloud host has a backup task, please remove the backup task first, and then delete the operation",
                "bindVgpu": "Binding vGPU should be done under shutdown condition",
                "bindVgpu1": "Bound VGPU",
                "unbindVgpu": "Cloud host cannot be unbound vGPU under creation and error state",
                "unbindVgpu1": "Cloud Host unbound vGPU",
                "mainHostClone": "Cloud host clone",
                "vmCloneTip": "Cloud host cloning must be performed in the shutdown state",
                "hangUpTip": "The cloud host mounts FPGA or GPU and cannot be suspended",
                "shelveServerTip":"The cloud host is put on hold in operation, shutdown, pause, or suspended state; mount the local disk, mount the GPU, open NUMA, large page memory, use the SR-IOV network, turn on the DPDK function, turn on the device The cloud host cannot be put on hold",
                "unShelveServerTip":"Cloud main machine shelved can cancel the shelving"
            }
        },
        "loadbalance": {
            "hostName":"Cloud Host Name",
            "enhanceListStatus":"Status",
            "withinsubnet":"Network subnet",
            "withinIP": "intranet IP",
            "outerIP": "outer net IP",
            "advancedSetup":"advanced setup",
            "checkUp":"health examination",
            "specifyTheNetwork":"Specify an external IP address",
            "theSpecifiedNetwork":"Specifying an Intranet IP Address",
            "outerNetConfig":"Configure the network",
            "intranetNetConfig":"Configure network",
            "IPMI": "IPMI address",
            "powerState": "Power state",
            "registrationDate": "Registration time",
            "bindPublicIP": "Bind public IP",
            "unbindPublicIP": "Unbind public network IP",
            "selectStatus": "Please select a status",
            "name": "Name",
            "privateIP": "Private network IP",
            "publicIP": "Public network IP",
            "status": "Status",
            "maxConnections": "Maximum number of connections",
            "inputMaxConnections": "Please enter a number, do not enter the default as unlimited",
            "noLimit": "Unlimited",
            "description": "Description",
            "inputName": "Please enter a name",
            "inputDescription": "Please enter a description",
            "subnet": "Subnet",
            "selectSubnet": "Please select a subnet",
            "inputNumber": "Please enter the number",
            "addTitle": "New load balancing",
            "editTitle": "Edit load balancing",
            "createBalanceSuccess": "Load balancing was created successfully.",
            "createBalanceSucTips": "Load balancing must be configured with listeners and back-end cloud hosts to take effect. Is the configuration created immediately?",
            "createConf": "New configuration",
            "ceeateLater": "New later",
            "enableLisTip": "Are you sure you want to enable the selection?",
            "forbiddenTip": "Are you sure you want to disable the selection?",
            "deleteTip": "Are you sure to delete the selection?",
            "unbindIpTip": "Are you sure to unbind the public IP?",
            "applyIp": "Apply for public IP",
            "publicIpPool": "External network",
            "selectIpPool": "Please select an external network",
            "specifiedIp": "Specify external IP address",
            "subSegment": "Subnet segment",
            "selectSubSegment": "Please select a subnet segment",
            "noSubSegment": "No subnet segments available",
            "ipAddr": "IP address",
            "publicIpQuota": "Public network IP quota",
            "detail": "Details",
            "basicInfo": "Basic Information",
            "listener": "Listener",
            "createListener": "New listener",
            "editListener": "Edit listener",
            "listenerDetail": "Listener details",
            "listenerManage": "Listener management",
            "monitor": "Monitor",
            "lbId": "ID",
            "configStatus": "Configuration status",
            "provider": "Provider",
            "tenantId": "Tenant ID",
            "createTime": "Creation time",
            "listenerProtocol": "Listening protocol",
            "port": "port",
            "balanceAlgorithm": "Equalization algorithm",
            "healthcheck": "Health examination",
            "healthcheckTips": "Check the health status of the backend cloud host. When the health check of a cloud host on the back end is abnormal, load balancing automatically distributes the new request to other cloud hosts with normal health check. When the cloud host resumes normal operation, load balancing automatically restores it to Load balancing service",
            "protocolPort": "Listening protocol [port]",
            "selectProtocol": "Please select the type of agreement",
            "selectAlgorithm": "Please choose an equalization algorithm",
            "inputPort": "Please enter the port number",
            "portRange": "Port range is 1~65535",
            "port1_65500":"Port range is 1~65500",
            "healthcheckWay": "Health check method",
            "selectHealthcheckWay": "Please choose the health check method",
            "delay": "Delay (seconds)",
            "overtime": "Timeout (seconds)",
            "maxRetries": "Maximum number of retries",
            "url": "URL",
            "inputUrl": "Please enter the URL address",
            "httpStatus": "HTTP status code",
            "inputHttpStatus": "Please enter the HTTP status code",
            "keepSession": "Session retention",
            "selectKeepSession": "Please choose a session hold method",
            "cookieName": "Cookie name",
            "checkItem": "Check option",
            "createBackend": "Add backend",
            "editBackend": "Edit member",
            "bindIns": "Binding cloud host",
            "modifyWeight": "Modify weight",
            "notChooseLisTips": "Please select a listener in the list above.",
            "insName": "Cloud host name",
            "weight": "Weights",
            "input_1_65535": "Please enter 1-65535",
            "input_1_100": "Please enter 1-100",
            "addServer": "Add a backend server",
            "editServer": "Edit the backend server",
            "chooseIns": "Select cloud host",
            "setPortWeight": "Set port and weight",
            "writePortWeight": "Fill in the port and weight",
            "port_1_65535": "1-65535",
            "weight_1_100": "1-100",
            "memberName": "Member name",
            "table": {
                "admin_state_up": {
                    "offline":"offline",
                    "enable": "usable",
                    "true": "Enable",
                    "false": "Disable",
                    "disabled":"disabled",
                    "disable":"disabled",
                },
                "provisioning_status": {
                    "ACTIVE": "Available",
                    "ERROR": "Error",
                    "PENDING_UPDATE": "Updating",
                    "PENDING_CREATE": "Creating",
                    "INACTIVE": "Unavailable",
                    "BUILD": "In the create"
                }
            },
            "lisTab": {
                "lb_algorithm": {
                    "ROUND_ROBIN": "Polling",
                    "LEAST_CONNECTIONS": "Minimum number of connections",
                    "SOURCE_IP": "Source IP"
                },
                "provisioning_status": {
                    "ACTIVE": "Normal",
                    "DELETED": "Deleted",
                    "ERROR": "Error",
                    "PENDING_CREATE": "Creating",
                    "PENDING_UPDATE": "Revised",
                    "PENDING_DELETE": "Deleting"
                }
            },
            "insTab": {
                "operating_status": {
                    "ONLINE": "Online",
                    "OFFLINE": "Offline",
                    "DEGRADED": "Downgrade",
                    "DISABLED": "Unavailable",
                    "NO_MONITOR": "Not listening"
                }
            },
            "info": {
                "subnet": "The location where the load balancer is installed on the network to serve all cloud hosts under the subnet.",
                "bindIP": "The load balancing service bound to the public IP can be accessed by the external network.",
                "protocolPort": "Load balancing the service port of the docking.",
                "keepSession": "The session keeps the access request from the same user forwarded to the same server for a certain period of time to achieve user access continuity.",
                "balanceAlgorithm": "Polling algorithm: Each new connection request is assigned to the next server in order, and all requests are evenly distributed to all servers. Least connection: The system assigns new connection requests to the server with the fewest connections. Source IP algorithm: Use the source IP address of the request as a hash key (HashKey) to find the corresponding server from the statically allocated hash table.",
                "maxConnections": "Define the maximum number of connections for the load balancer. The default is Unlimited (-1).",
                "delay": "The maximum delay of the heartbeat.",
                "overtime": "The maximum timeout for the heartbeat.",
                "maxRetries": "The maximum number of retries after a heartbeat timeout.",
                "url": "The HTTP/HTTPS status monitoring target determines whether the service is available by automatically accessing the URL and judging the status code.",
                "httpStatus": "The status code of the URL feedback is requested by HTTP/HTTPS request to confirm whether the HTTP/HTTPS service status is normal. The default is 200.",
                "healthcheck": "Check the health status of the backend cloud host. When the health check of a cloud host on the back end is abnormal, load balancing automatically distributes the new request to other cloud hosts with normal health check. When the cloud host resumes normal operation, load balancing automatically restores it to In the load balancing service.",
                "weight": "Define the weighting ratio of each member in the load balancing algorithm.",
                "alarmThreshold": "When the maximum number of connections of the monitoring indicator triggers the set threshold, an alarm will be generated. For example, \"Maximum number of connections>=80%\" is an alarm condition.",
                "alarmLevel": "Choose different levels according to the severity of the alarm"
            },
            "clientTimeout": "Client timeout",
            "serverTimeout": "Server timeout",
            "clientFinTimeout": "Client TCP half-close connection timeout time",
            "serverFinTimeout": "Server TCP half-closed connection timeout time",
            "httpRequestTimeout": "HTTP request timeout",
            "httpKeepAliveTimeout": "HTTP session keep timeout",
            "timeUnit": {
                "ms": "ms (milliseconds)",
                "s": "s (seconds)",
                "min": "m (minutes)",
                "h": "h (hour)",
                "d": "d (day)"
            },
            "maxLen10": "Please enter up to 10 digits",
            "monitorEchart": "monitor",
            "monitorItem": {
                "item0": "Maximum number of connections"
            },
            "monitorLabel": "Monitoring item",
            "enableAlarm": "Enable alert",
            "alarmThreshold": "Alarm threshold",
            "alarmLevel": "Alert level",
            "pleaseSelect": "please choose",
            "pcs": "pcs",
            "virtualIP": "Virtual IP",
            "virtualIpPort": "Virtual IP port",
            "virtualIpSubnet": "Virtual IP subnet",
            "inputNumber_default_50": "Please enter a number, leave it blank, the default is 50s",
            "inputNumber_default_30": "Please enter a number, leave it blank, the default is 30s",
            "pool": "Resource pool"
        },
        "ports": {
            "load": "Load",
            "unload": "Unload",
            "name": "Name",
            "insName": "Instance Name",
            "network": "Network",
            "subnet": "Subnet",
            "ipAdress": "IP Adress",
            "status": "Status",
            "MACadress": "Mac Address",
            "associatedHost": "Associated Host",
            "associatedResource": "Associated resources",
            "createtime": "Create Time",
            "mounted": "Mounted",
            "unmounted": "Unmounted",
            "islinked": "Is it directly connected?",
            "newPorts": "New Ports",
            "updatePorts": "Edit Ports",
            "specifySubnet": "Specify Subnet",
            "selectSubnet": "Select Subnet",
            "specifyIPAdress": "Specify IP Adress",
            "subnetSegment": "Subnet Segment",
            "description": "Description",
            "insName": "Instance Name",
            "privateIP": "Private IP",
            "unloadPorts": "Confirm Uninstall And Delete The Port？",
            "notUnloadPorts": "The selected port is the last port of the host, which can not be uninstall.",
            "delPorts": "Confirm Delete?",
            "notDelPorts":"The selected network card has been used by cloud main machine, and cannot be deleted",
            "portNotOperate":"Load balancing enhanced virtual network cards can not do anything",
            "tip0":"Please select a virtual network card",
            "placeholder": {
                "name": "Please enter ports name",
                "description": "Please enter description",
                "network": "Select network",
                "noNetwork": "No available network",
                "subnet": "Select subnet",
                "noSubnet": "No available subnet",
                "subnetSegment": "Select subnet segment",
                "noSubnetSegment": "No available subnet segment"
            },
            "helpMsg": {
                "title": "Tips",
                "one": "The currently selected subnet contains a default gateway, which may affect the connectivity of other NICs of the cloud host after adding it. Please confirm whether to add the virtual NIC of this subnet?",
                "two": "For the Linux cloud host, after adding a network card, add the configuration file of the new network card in the cloud host system, and start the network card to actually use the added virtual network card."
            }
        },
        "elasticExpansion": {
            "inputMonitorName":"Please enter a listener name",
            "name": "Name",
            "extensionalStrategy": "Extensional Strategy",
            "contractileStrategy": "Contractile Strategy",
            "telescopicCluster": "Telescopic Cluster",
            "status": "Status",
            "loadbalancer": "Load Balancer",
            "listener": "Listener",
            "expansionSpecification": "Expansion Specification",
            "imageName": "Image Name",
            "currentInstanceNum": "The Maximum Number of Instance",
            "maxInstanceNum": "The Minimum Number of Instance",
            "currentInstanceNum":"Maximum number of cloud hosts",
            "baseMsg": "Base Message",
            "instancesList": "Instances List",
            "retractableActivityLog": "Retractable Activity Log",
            "name": "Name",
            "createdTime": "Created Time",
            "contactGroup": "Contact Group",
            "removeClusterDelete": "Remove and delete the telescopic cluster",
            "triggerCondition": "Trigger Condition",
            "triggerAction": "Trigger Action",
            "triggerLogic": "Trigger Logic",
            "monitoringCycle": "Monitoring Cycle",
            "monitoringRules": "Monitoring Rules",
            "singleIncrease": "Single Increase",
            "maximumQuantity": "Maximum Quantity",
            "instanceConfiguration": "Instance Configuration",
            "instanceName": "Instance Name",
            "settingPort": "Setting Port",
            "settingWeight": "Setting Weight",
            "coolingTime": "Cooling Time",
            "singleReduction": "Single Reduction",
            "minimumQuantity": "Minimum Quantity",
            "specification": "Specification",
            "image": "Image",
            "entryMode": "Entry Mode",
            "joinTime": "Join Time",
            "activity": "Activity",
            "insNumPreExpansion": "Instance Number of PreExpansion",
            "insNumAfterExpansion": "Instance Number of AfterExpansion",
            "activityStartTime": "Activity Start Time",
            "activityEndTime": "Activity End Time",
            "activityResult": "Activity Result",
            "newExpansion": "Create Elastic Expansion",
            "baseConfig": "Base Configure",
            "triggerRules": "Trigger Rules",
            "placeholder": {
                "name": "Please enter name",
                "loadbalancer": "Please select load Balancer",
                "noLoadbalancer": "No optional load balancer.",
                "listener": "Please select listener",
                "noListener": "There are no optional listeners",
                "contactGroup": "Please select the Contact group",
                "noContactGroup": "There is no optional contact group."
            }
        },
        "sockets": {
            "opLog": {
                "compute.instance.create.start": "creating",
                "compute.instance.create.end": "created",
                "compute.instance.power_off.start": "shutdown",
                "compute.instance.power_off.end": "shutdown",
                "compute.instance.power_on.start": "start",
                "compute.instance.power_on.end": "start",
                "compute.instance.vgpu_device.attach.end": "Bind vGPU successfully",
                "compute.instance.vgpu_device.attach.start": "Binding vGPU",
                "compute.instance.vgpu_device.attach.error": "Binding vGPU failed",
                "compute.instance.vgpu_device.detach.end": "Unbind vGPU successfully",
                "compute.instance.vgpu_device.detach.start": "Unbind vGPU successfully",
                "compute.instance.vgpu_device.detach.error": "Unbind vGPU successfully",
                "compute.instance.delete.start": "deleting",
                "compute.instance.delete.end": "deleted",
                "compute.instance.soft_delete.start": "deleting",
                "compute.instance.soft_delete.end": "deleted",
                "compute.instance.shutdown.start": "shutdown",
                "compute.instance.shutdown.end": "shutdown",
                "compute.instance.restore.start": "restoring",
                "compute.instance.restore.end": "restore",
                "compute.instance.reboot.start": "rebooting",
                "compute.instance.reboot.end": "reboot",
                "compute.instance.pause.start": "pause",
                "compute.instance.pause.end": "paused",
                "compute.instance.unpause.start": "unpause",
                "compute.instance.unpause.end": "unpaused",
                "compute.instance.suspend.start": "suspending",
                "compute.instance.suspend.end": "suspended",
                "compute.instance.resume.start": "resuming",
                "compute.instance.resume.end": "resumed",
                "compute.instance.resize.prep.star": "resizing",
                "compute.instance.resize.prep.start": "resized",
                "compute.instance.resize.prep.end": "resizing",
                "compute.instance.exists": "resizing",
                "compute.instance.resize.start": "resizing",
                "cold_migrate.compute.instance.resize.start": "Prepare for cold migration.",
                "compute.instance.resize.end": "resizing",
                "compute.instance.finish_resize.start": "resizing",
                "compute.instance.finish_resize.end": "resized",
                "cold_migrate.compute.instance.finish_resize.end": "Prepare for completion of cold migration",
                "compute.instance.resize.confirm.start": "resizing",
                "compute.instance.resize.confirm.end": "resized",
                "cold_migrate.compute.instance.resize.confirm.end": "Cold migration is complete",
                "resize_instance": "resize failed",
                "cold_migrate.resize_instance": "Cold transfer failure",
                "compute.instance.upgrade.confirm.start": "upgrading",
                "compute.instance.upgrade.confirm.end": "upgrade success",
                "compute.instance.rebuild.error": "rebuilding error",
                "compute.instance.rebuild.scheduled": "rebuilding",
                "compute.instance.rebuild.start": "rebuilding",
                "compute.instance.rebuild.end": "rebuilt",
                "compute.instance.snapshot.start": "Creating Snapshots",
                "compute.instance.snapshot.end": " Snapshot screated",
                "compute.instance.evacuate": "Evacuating",
                "compute.instance.live_migration.pre.start": "live_migration pre start",
                "compute.instance.live_migration.pre.end": "live_migration pre end",
                "compute.instance.live_migration._rollback.start": "live_migration rollback start",
                "compute.instance.live_migration._post.start": "live_migration post start",
                "compute.instance.live_migration._rollback.end": "live_migration rollback end",
                "compute.instance.live_migration._post.end": "live_migration post end",
                "compute.instance.live_migration.rollback.dest.start": "live_migration rollback dest start",
                "compute.instance.live_migration.rollback.dest.end": "live_migration rollbakc dest end",
                "compute.instance.live_migration.post.dest.start": "live_migration post dest start",
                "compute.instance.live_migration.post.dest.end": "live_migration post dest end",
                "compute.phy_instance.create.start": "Physical Cloud Host creation",
                "compute.phy_instance.create.end": "Physical Cloud host created successfully",
                "compute.phy_instance.create.error": "Physical cloud host creation failed",
                "compute.phy_instance.power_on.start": "Physical Cloud Host on startup",
                "compute.phy_instance.power_on.end": "Physical Cloud Host booted successfully",
                "compute.phy_instance.power_off.start": "Physical Cloud Host in Shutdown",
                "compute.phy_instance.power_off.end": "Physical Cloud host shutdown successful",
                "compute.phy_instance.reboot.start": "Physical Cloud Host in restart",
                "compute.phy_instance.reboot.end": "Physical Cloud host restart complete",
                "compute.phy_instance.delete.start": "Physical Cloud Host deleted",
                "compute.phy_instance.delete.end": "Physical Cloud host removal complete",
                "compute.phy_instance.nohost": "No physical host resources available",
                "snapshot.create.start": "Creating Snapshots",
                "snapshot.create.end": " Snapshots created",
                "snapshot.delete.start": "Deleting Snapshots",
                "snapshot.delete.end": "Snapshots Deleted",
                "snapshot.rollback.start": "Snapshot rollback.start",
                "snapshot.rollback.end": "Snapshot rollback.start",
                "compute.instance.volume.attach": "Attach Volume Successed",
                "compute.instance.volume.detach": "Detach Volume Successed",
                "volume.create.start": " Volume creating",
                "volume.create.end": " Volume Created",
                "volume.delete.start": " Volume deleting",
                "volume.delete.end": " Volume deleted",
                "volume.soft_delete.start": "Volume soft deleting",
                "volume.soft_delete.end": " Volume soft deleted",
                "volume.resize.start": " Volume resizing",
                "volume.resize.end": " Volume resized",
                "volume.attach.start": " Volume Attaching",
                "volume.attach.end": " Volume Attached",
                "volume.detach.start": " Volume Detaching",
                "volume.detach.end": " Volume Detached",
                "volume.update.start": " Volume Updating",
                "volume.update.end": " VolumedUpdated",
                "volume.retype.end": "Cloud Hard Disk Migration successful",
                "scheduler.retype": "Cloud hard drive migration failed",
                "network.create.end": "Network Created",
                "network.delete.end": "Network deleted",
                "network.update.end": "Network Updated",
                "subnet.create.start": "Subnet Creating",
                "subnet.create.end": "Subnet Created",
                "subnet.delete.start": "Subnet Deleting",
                "subnet.delete.end": "Subnet Deleted",
                "subnet.update.end": "Subnet Updated",
                "router.create.start": "Router creating",
                "router.create.end": "Router Created",
                "router.delete.start": "Router Deleting",
                "router.delete.end": "Router deleted",
                "router.update.end": "Router Network Updated",
                "router.interface.create": "Router Associated",
                "router.interface.delete": "Router Disassociated",
                "port.update.start": "Port under modification",
                "port.update.end": "Port modified successfully",
                "floatingip.create.start": "Creating Float IP",
                "floatingip.create.end": "Created Float IP",
                "floatingip.delete.start": "Deleting Floating IP",
                "floatingip.delete.end": "Deleted Floating IP",
                "floatingip.association.start": "Associating Floating IP",
                "floatingip.association.end": "Associated Floating IP",
                "floatingip.disassociation.start": "Disassociating Floating IP",
                "floatingip.disassociation.end": "Disassociated Floating IP",
                "floatingip.update.end": "Floating IP Updated",
                "port.create.end": "Port Created",
                "port.delete.end": "Port Deleted",
                "fixedip.update.end": "FixedIP updated",
                "keypair.create.end": "Keypairs Created",
                "keypair.delete.end": "Keypairs Deleted",
                "keypair.import.end": "Keypairs Imported",
                "security_group.create.end": "Firewall Created",
                "security_group.update.end": "Firewall Updated",
                "security_group.delete.end": "Firewalldeleted",
                "security_group_rule.create.end": "Firewall rule Created",
                "security_group_rule.delete.end": "Firewall rule Deleted",
                "aggregate.create.end": "Aggregate Created",
                "aggregate.updateprop.end": "Aggregate Updated",
                "aggregate.updatemetadata.end": "successfully set the metadata of aggregate ",
                "aggregate.addhost.end": "management Host operation is successfull",
                "aggregate.removehost.end": "management Host operation is successfull",
                "aggregate.delete.end": "Aggregate Deleted",
                "scheduler.create_volume": "Create Volume",
                "compute.instance.mkimg.start": "Creating images",
                "compute.instance.mkimg.end": "Created images",
                "compute.instance.backup.start": "Backup",
                "compute.instance.backup.end": "Backup Successed",
                "firewall_rule.update.end": "Firewall rules modified successfully",
                "firewall_rule.create.end": "Firewall rules created successfully",
                "firewall_rule.delete.end": "Firewall rule removed successfully",
                "firewall_policy.create.end": "Protective Wall Policy created successfully",
                "firewall_policy.update.end": "Firewall Policy edited successfully",
                "firewall_policy.delete.end": "Firewall policy removed successfully",
                "firewall.create.end": "Firewall created successfully",
                "firewall.update.end": "Firewall edited successfully",
                "firewall.delete.end": "Firewall removed successfully",

                "loadbalancer.delete.end": "Delete Loadbalancer Successed",
                "loadbalancer.create.end": "Create Loadbalancer Successed",
                "loadbalancer.update.end": "Update Loadbalancer Successed",
                "listener.create.end": "Create Listener Successed",
                "listener.update.end": "Update Listener Successed",
                "listener.delete.end": "Delete Listener Successed",
                "pool.create.end": "Create Pool Successed",
                "pool.update.end": "Update Pool Successed",
                "pool.delete.end": "Delete Pool Successed",
                "member.create.end": "Create Member Successed",
                "member.update.end": "Update Member Successed",
                "member.delete.end": "Delete Member Successed",
                "healthmonitor.create.end": "Create Healthmonitor Successed",
                "healthmonitor.update.end": "Update Healthmonitor Successed",
                "healthmonitor.delete.end": "Delete Healthmonitor Successed",

                "loadbalancer.create.start": "loadbalancer creating",
                "listener.create.start": "listener creating",
                "listener.update.start": "监听器编辑中",
                "healthmonitor.delete.start": "监听器删除中",

                "pool.create.start": "pool creating",
                "member.create.start": "member creating",
                "member.delete.start": "member deleting",
                "pool.delete.start": "pool deleting",
                "listener.delete.start": "listener deleting",
                "loadbalancer.delete.start": "loadbalancer deleting",
                "backup.delete.start": "Backup deleted",
                "backup.delete.end": "Backup deleted successfully",
                "backup.create.start": "Backup creation in progress",
                "backup.create.end": "Backup created successfully",
                "backup.restore.start": "Backup is being restored to the cloud hard drive",
                "backup.restore.end": "Backup restored to cloud hard drive successfully",
                "backup.reset_status.start": "Backup reset in state",
                "backup.reset_status.end": "Backup reset status complete",
                "update_nova_success": "Update NOVA configuration successfully",
                "update_cinder_success": "Update cinder configuration successfully",
                "update_computeha_success": "Updated high Availability configuration successfully",
                "update_ups_success": "Update UPS configuration successfully",
                "update_platform_success": "Updated platform scale configuration successfully",
                "update_nova_fail": "Update NOVA configuration failed",
                "update_cinder_fail": "Update cinder configuration failed",
                "update_computeha_fail": "Update high availability configuration failed",
                "update_ups_fail": "Update UPS configuration successfully",
                "update_platform_fail": "Update platform size configuration failed",
                "update_flatten_ing": "The optimized configuration of the clone chain is being released",
                "update_flatten_success": "The optimized configuration of the clone chain was successfully issued",
                "update_flatten_failed": "Failed to deliver the optimized configuration of the clone chain",
                "clone.server.volume.create.start": "Clone cloud disk",
                "clone.server.volume.create.end": "Clone the cloud disk successfully",
                "clone.server.volume.create.error": "Clone the cloud disk Failed",
                "clone.server.compute.instance.create.start": "clone cloud host",
                "clone.server.compute.instance.create.end": "Clone Cloud Host Successfully",
                "clone.server.compute.instance.create.error": "Failed to clone cloud host",
                "clone.server.compute.instance.delete.start": "Rollback and delete the cloned cloud host",
                "clone.server.compute.instance.delete.end": "The rollback and deletion of the cloned cloud host succeeded",
                "clone.server.compute.instance.delete.error": "Failed to rollback and delete the cloned cloud host",
                "clone.server.compute.instance.force_delete.start": "Rollback and delete the cloned cloud host",
                "clone.server.compute.instance.force_delete.end": "The rollback and deletion of the cloned cloud host succeeded",
                "clone.server.compute.instance.force_delete.error": "Failed to rollback and delete the cloned cloud host",
                "clone.server.compute_task.build_instances": "Failed to create, insufficient resources, please re-select the cloud host specification",
                "attach_volume":"Disk loading failed",
                "compute.instance.volume.attach.error":"Cloud host mount local disk failed",
                "compute.instance.volume.attach.localDisk":"The cloud host mounts the local disk success",
                "compute.instance.volume.detach.localDisk":"Cloud host uninstall local disk success",
                "compute.instance.volume.attach.error.localDisk":"Cloud host mount local disk failed",
                "compute.instance.volume.detach.error.localDisk":"Cloud host uninstall local disk failure",
                "compute.instance.shelve_offload.start":"Cloud host",
                "compute.instance.unshelve.start":"Cloud main machine cancels the shelving",
                "compute.instance.shelve_offload.end":"Cloud host holds success",
                "compute.instance.unshelve.end":"Cloud main machine cancels the rest",
                "compute.instance.unshelve.error":"Insufficient resources, the cancellation of the shelter failure"
            }
        },
        "loadbalancers": {
            "ui": {
                "selectAgreement":"Select a listening protocol",
                "selectArithmetic":"Please select an algorithm",
                "newLb": "Create Loadbalancer",
                "editLb": "Update Loadbalancer",
                "lbDetail": "Loadbalancer Detail",
                "lbDelMsg": "Confirm Delete Loadbalancer",
                "newListener": "Create Listener",
                "editListener": "Update Listener",
                "listenerDetail": "Listener Detail",
                "newPool": "Create Pool",
                "editPool": "Update Pool",
                "poolDetail": "Pool Detail",
                "newMember": "Create Member",
                "editMember": "Update Member",
                "memberDetail": "Member Detail",
                "operating_status": "Operating Status",
                "provisioning_status": "Provisioning Status",
                "table": {
                    "ONLINE": "online",
                    "OFFLINE": "offline",
                    "ACTIVE": "active",
                    "ERROR": "error",
                    "PENDING_UPDATE": "pending update",
                    "PENDING_CREATE": "pending create",
                    "INACTIVE": "inactive",
                    "true": "true",
                    "false": "false"
                }
            },
            "lbAlgorithm": {
                "ROUND_ROBIN": "ROUND_ROBIN",
                "LEAST_CONNECTIONS": "LEAST_CONNECTIONS",
                "SOURCE_IP": "SOURCE_IP"
            },
            "lb": {
                "name": "Name",
                "name_placeholder": "Please Enter Name",
                "description": "Description",
                "description_placeholder": "Please Enter Description",
                "vip_address": "Vip Address",
                "Vlan": "Vlan",
                "Vlan_placeholder": "Please Enter Vlan",
                "admin_state_up": "Admin Authority",
                "overview": "Overview",
                "subnet": "Subnet Arrange",
                "emptyOrAddrField": "Empty or corresponding address field",
                "provisioning_status": "Configuration State",
                "tenant_id": "Tenant ID",
                "provider": "Provider"
            },
            "ls": {
                "listener": "Listener",
                "back": "Back",
                "name": "Name",
                "name_placeholder": "Please Enter Name",
                "description": "Description",
                "description_placeholder": "Please Enter Description",
                "protocol": "Protocol",
                "protocol_placeholder": "Please Enter Protocol",
                "port_placeholder": "Please Enter Port",
                "connection_limit_ph": "Please Enter Connection Limit",
                "admin_state_up": "Admin Authority",
                "lsDelMsg": "Confirm Delete Listener",
                "connection_limit": "Connection Limit",
                "protocol_port": "Protocol Port",
                "delListenerTip": "The Listener is binded by Pool,cannot delete"
            },
            "pl": {
                "ipRangeCheck": "IP is not within the load balancing subnet range",
                "pool": "Pool",
                "back": "Back",
                "name": "Name",
                "name_placeholder": "Please Enter Name",
                "description": "Description",
                "description_placeholder": "Please Enter Description",
                "protocol": "Protocol",
                "listener": "Listener",
                "listener_placeholder": "Please Enter Listener",
                "protocol_placeholder": "Please Enter Protocol",
                "admin_state_up": "Admin Authority",
                "plDelMsg": "Confirm Delete Pool",
                "overview": "Overview",
                "lbAlgorithm": "lbAlgorithm",
                "lbAlgorithm_placeholder": "Please Select lbAlgorithm"
            },

            "mb": {
                "controlMonitor": "Monitor",
                "member": "Member",
                "back": "Back",
                "name": "Name",
                "name_placeholder": "Please Enter Name",
                "description": "Description",
                "description_placeholder": "Please Enter Description",
                "address": "IP Address",
                "address_placeholder": "Please Enter IP Address",
                "protocol_port": "Port",
                "protocol_port_placeholder": "Please Enter Port",
                "admin_state_up": "Admin Authority",
                "subnet": "Subnet",
                "subnet_placeholder": "Please Enter Subnet",
                "weight": "Weight",
                "weight_placeholder": "Please Enter Weight",
                "mbDelMsg": "Confirm Delete Member"
            }
        },
        "h_monitor": {
            "monitor": "Monitor",
            "name": "Name",
            "name_placeholder": "Please Enter Name",
            "type": "Type",
            "delay": "Delay(s)",
            "delay_placeholder": "Please Enter Delay Seconds",
            "timeout": "Timeout(s)",
            "timeout_placeholder": "Please Enter Timeout Seconds",
            "max_retries": "Max Retries",
            "max_retries_placeholder": "Please Enter Max Retries",
            "mnDelMsg": "Confirm Delete Monitor",
            "newtitle": "Create Monitor",
            "edittitle": "Update Monitor",
            "monitor_detail": "Monitor Detail",
            "expected_codes": "HTTP Codes",
            "codes_placeholder": "Please Enter HTTP Codes",
            "url_placeholder": "Please Enter URL Path"
        },
        "volumes": {
            "curVolume": "Current Cloud Hard Drive",
            "multiattach": "Shared Volume",
            "gigQuota": "Disk Quota",
            "volumesQuota": " Volume Quota",
            "snapQuota": " Snapshots Quota",
            "snapshotNum": " Snapshots Number",
            "volume": " Volume",
            "undefined": "undefined",
            "volumesManage": " Volume Manage",
            "attachVolume": "Attach Volume",
            "unstallVolume": "Detach Volume",
            "createSnapshot": "Create Snapshots",
            "extendSize": "Resize",
            "onlyRead": "Read Only",
            "readAndWrite": "Read and Write",
            "forceDelete": "Forece Delete",
            "stockTransfer": "Stock Transfer",
            "backup": "Back up",
            "volumeName": " Volume Name",
            "snapname": "Snap Name",
            "createtime": "Create Time",
            "projectName": "Project Name",
            "associatedHost": "Associated Host",
            "config": "config(GB)",
            "status": "Status",
            "describe": "Describe",
            "hostName": "Host Name",
            "snapshot": " Snapshots",
            "sharedVolume": "Shared Volume",
            "type": "Type",
            "voltype": "Storage Device",
            "stpool": "Storage Pool",
            "stfeature": "Storage Features",
            "description": " Volume Description",
            "unsetting": "unsetting",
            "detail": " Volume Detail",
            "created": "Create time",
            "zone": "zone",
            "metaData": "metaData",
            "dataDisk": "data Disk",
            "systemDisk": "system Disk",
            "cacheDisk": "cache Disk",
            "imageName": "Image Name",
            "bootable": "Bootable",
            "fromBackup": "From backup restore",
            "allocated": "Allocated",
            "capacity": "Storage Pool capacity (TB)",
            "errorMessage": {
                "atLeastSize": "Less than default Volume size",
                "cantCreate": "Disk room is insufficient，can't create Volume",
                "cantExpandDep": "Department disk quota is insufficient",
                "cantExpandPro": "Project disk quota is insufficient",
                "depSnapGig": "Department disk quota is insufficient",
                "proSanpGig": "Project disk quota is insufficient",
                "depSnapNum": "Department Snapshots quota is insufficient",
                "proSnapNum": "Project Snapshots quota is insufficient",
                "cantCVolume": "Volume quota is insufficient",
                "snapshotDep": "Department hard disk quota insufficient",
                "snapshotPro": "Project hard disk quota insufficient",
                "moreThanDepGig": "Department disk quota is insufficient",
                "moreThanProGig": "Project disk quota is insufficient",
                "moreThanDepVolumes": "Department Volume quota is insufficient",
                "moreThanProVolumes": "Project Volume quota is insufficient",
                "proBackupNum": "Insufficient Project Backup quota",
                "depBackupNum": "Department backup quota insufficient",
                "proBackupGig": "Insufficient Project Backup Capacity quota",
                "depBackupGig": "Department backup Capacity quota insufficient"
            },
            "placeholder": {
                "volume": "Please select cloud Drive",
                "noVolume": "No alternative cloud hard drive",
                "voltype": "Please select A storage device",
                "volumeSig": "Please select a cloud hard drive"
            },
            "tip": {
                "tip1": "Please select cloud hard disk in available and bootable state",
                "tip2": "Please select cloud hard disk in available or used state",
                "tip3": "Cloud hard disk cannot create a new backup when there is a scheduled backup task",
                "tip4": "Iso format cloud Disk does not support this operation",
                "tip5": "The storage where the cloud hard drive is located does not support this operation",
                "tip6": "Please select cloud hard disk in available state",
                "tip7": "Dual live cloud drives do not support this operation",
                "tip8": "Cannot create a snapshot when there is a timed snapshot task on the cloud hard disk",
                "tip9": "Please select cloud hard disk in use state",
                "tip10": "Please select a cloud hard drive",
                "tip11": "There is a multi-mount on the cloud hard disk, please unmount it to the non-shared state before operating.",
                "tip12": "Please shut down the virtual machine before migrating",
                "tip13": "Please unmount and migrate",
                "tip14": "Please select the cloud hard drive in the migration",
                "tip15": "Used NFS volume backup is not supported, please unmount the cloud hard disk backup",
                "tip16": "No deletion is allowed under the selected cloud hard disk state (loading, used, uninstalling, migrating, backup).",
                "tip17": "Snapshot exists under selected cloud hard drive, deletion is not allowed",
                "tip18": "Snapshot exists under cloud hard drive, cloud hard drive migration cannot be carried out",
                "tip19": "Cloud hard drive in migration, unable to operate",
                "tip20": "This storage state does not support expansion",
                "tip21": "A cloud disk whose storage protocol is lvm and a snapshot has been created does not support capacity expansion",
                "noAvailableStorage": "Cannot operate without a removable storage device",
                "hasBackupJobNotDel": "There is a backup task under the selected cloud hard disk, please remove it before deleting it.",
                "hasBackupJobNotExtend": "There is a backup task under the selected cloud hard disk, please remove it first and then expand it"
            },
            "cv": {
                "name": " Volume Name",
                "describe": "Describe",
                "pInputName": "Please enter Volume Name",
                "config": "config（GB）",
                "pInputSize": "Please enter Volume size",
                "pInputNum": "Please enter nonzero integer",
                "descMessage": "Description",
                "pDescMessage": "Please enter description",
                "newVolume": "Create Volume",
                "updateVolume": "Update Volume",
                "defaultSize": "If it is empty, it is the size of the original cloud hard disk.",
                "nomore_volumetype": "Volume types matching failure",
                "storagetype": "Storage Type",
                "storagedevice": "Storage Device",
                "pool": "Storage Pools",
                "storagePool": "Storage Pool",
                "cephpool": "Standard pool",
                "ceph_storage": "Ceph Storage",
                "Centralized_storage": "Centralized Storage",
                "pool_capacity": "Pool Capacity",
                "centralizedtip": "Centralized Storage is not supported now",
                "storagefeatures": "Storage Features",
                "compression": "Compression",
                "rsize": "Rsize",
                "easytier": "EasyTier",
                "hyperswap": "Hyperwap"
            },
            "dv": {
                "deleteVolume": "Delete Volume",
                "forceDelete": "Force Delete  Volume",
                "confirmDelete": "Confirm Delete Volume",
                "confirmForce": "Confirm Force Delete Volume?",
                "confirmResetStatus": "Make sure that the volume in the migration has actually been migrated failed, in general, in the process of the volume migration, the system butted other stores, and in this case the reset state operation is allowed."
            },
            "lv": {
                "chooseHost": "Select an Instance",
                "loadVolume": "Load Volume",
                "selectServer": "Select Instance",
                "pleaseSelectServer": "Select an Instance",
                "selectDriver": "Select Disk Driver",
                "pleaseSelectDriver": "Select Disk Driver"
            },
            "unv": {
                "unstallVolume": "Detach Volume",
                "confirmUnstall": "Select an Instance"
            },
            "ev": {
                "extendVolume": "Extend Volume",
                "size": "size(GB)"
            },
            "cs": {
                "createSnapshot": "Create Snapshots",
                "pInputName": "Please enter Snapshots Name",
                "prompt_message": "This volume has already enabled volumes,please first delete the volume. "
            },
            "table": {
                "status": {
                    "all": "All",
                    "available": "vailable",
                    "inavailable": "inavailable",
                    "creating": "creating",
                    "deleting": "deleting",
                    "attaching": "attaching",
                    "detaching": "detaching",
                    "in-use": "in-use",
                    "error": "error",
                    "unrecognized": "unrecognized",
                    "extending": "extending",
                    "error-deleting": "error-deleting",
                    "downloading": "downloading",
                    "uploading": "uploading",
                    "retyping": "In transit",
                    "backing-up": "In backup",
                    "restoring-backup": "Backup in recovery",
                    "error-restoring": "Recovery error",
                    "rollbacking": "Rolling back",
                    "cloning": "cloning"
                },
                "multiattach": {
                    "true": "support",
                    "false": "not support"
                },
                "bootable": {
                    "true": "Yes",
                    "false": "No."
                }
            },
            "dataDiskName": "Data Disk Name",
            "dataDiskDescription": "Data Disk Description",
            "systemDiskName": "System Disk Name",
            "systemDiskDescription": "System Disk Description",
            "mirrorCacheDiskName": "Mirror Cache Disk Name",
            "mirrorCacheDiskDescription": "Mirror Cache Disk Description"
        },
        "volumesQoS": {
            "name": "Name",
            "associatedStorage": "Associated Storage Pool",
            "rescissionAssociation": "Uncorrelating",
            "QoSName": "The name of the QoS",
            "QoSNum": "QoS value",
            "relateVolumeEquip": "Associated Storage Pool",
            "associatedStorageTitle": "Associated Storage Devices",
            "hasAssociatedStorage": "Associated Storage Pool",
            "delAssociatedStorage": "Do you want to delete the selected backup?",
            "read_bytes_sec": "Read throughput (MB/s)",
            "write_bytes_sec": "Write throughput (MB/s)",
            "read_iops_sec": "Read IOPS",
            "write_iops_sec": "Write IOPS.",
            "delVolumesQoS": "Confirm deletion of selected QoS?",
            "max-read_bytes_sec": "Maximum read Throughput (MB/s)",
            "max-write_bytes_sec": "Maximum throughput (MB/s)",
            "max-read_iops_sec": "Maximum READ IOPS",
            "max-write_iops_sec": "Most capital IOPS",
            "placeholder": {
                "choiceStorageDevice": "Please select the storage pool you want to dock with",
                "noStorageDevice": "No docking storage device.",
                "input_volumesQoSName": "Please enter the name of the new QoS",
                "input_read_bytes_sec": "Please enter the read throughput for this QoS (0 is unlimited)",
                "input_write_bytes_sec": "Please enter the write throughput for this QoS (0 is unlimited)",
                "input_read_iops_sec": "Please enter the number of read IOPS for this QoS (0 is unlimited)",
                "input_write_iops_sec": "Please enter the number of write IOPS for this QoS (0 is unlimited)",
                "choice_relative_volumes": "Please select the storage pool you want associated with",
                "no_relative_volumes": "No storage pool to associate with.",
                "choice_relieve_volumes": "Please select the storage pool that you want to de-associate",
                "no_relieve_volumes": "No storage pool to associate with.",
                "mustOne": "You must enter an item."
            }
        },
        "localDisk": {
            "localDiskName": "Local disk name",
            "config": "Configuration (GB)",
            "status": "Status",
            "location": "Location",
            "start": "Start",
            "remark": "Describe",
            "size": "Size (GB)",
            "table": {
                "status": {
                    "all": "All",
                    "available": "Available",
                    "attached": "Attached",
                    "error": "Error",
                    "in-use": "Used"
                }
            },
            "name": "Name",
            "remark":"Describe",
            "node": "SelectNode",
            "placeholder": {
                "input_name": "Please enter a name",
                "input_remark": "Please enter a description",
                "select_node": "Please select a node",
                "input_size": "Please enter size"
            },
            "tipMsg": {
                "deleteLocalDisk": "Are you sure you want to delete the local disk?",
                "joinLocalDisk": "Are you sure you want to join the local disk?",
                "removeLocalDisk": "Are you sure you want to remove the local disk?"
            },
            "diskName": "Hard disk name",
            "diskType": "Hard drive type",
            "diskCapacity": "Capacity(GB)",
            "cloudHostName": "Cloud host name",
            "cloudHostStatus": "Status",
            "image": "mirror",
            "nodeName": "physicalHost",
            "detail": "Local Disk Details",
            "locationNode": "The node",
            "physicalDisk": "physicalDisk",
            "cloudHostName":"Cloud host name",
            "createTime": "CreateTime",
            "joinLocalDisk": "Join local disk",
            "removeLocalDisk": "Remove local disk",
            "surplus": "remaining",
            "open": "Open",
            "close": "Close",
            "openHightUse": "Force high availability",
            "openHightUseTitle": "If it is forcibly turned on, there may be problems after the virtual machine is migrated, please operate with caution",
            "uninstallTip": "Uninstalling a local disk during use may pose risks. Please complete the uninstallation in the operating system of the cloud host to which the local disk is mounted before performing this operation.",
            "loadingTip1": "1.Migration operations are not supported for virtual machines mounted with local disks.",
            "loadingTip2": "2.Node failure evacuation is not supported for virtual machines mounted with local disks",
            "sizeTip": "Please enter an integer from 1 to {{size}}",
            "checkNotEmptyTip": "Please select a hard drive",
            "checkNotEmptyLocalDiskTip": "Please select a local disk",
            "checkNotEmptyVmTip": "Select at least one cloud host",
            "totalSize": "Total capacity",
            "useSize": "Allocated capacity",
            "systemManage": "System Management",
            "configManage": "Configuration management",
            "operateManage": "Operation and maintenance management",
            "storageManage": "Storage management",
            "localDiskService": "Use this module after connecting to the local disk resource pool",
            "localDiskJoinSuccess":"Add local disk success",
            "localDiskJoinInitIng":"Local disk docking",
            "localDiskJoinFail":"Add local disk failure"
        },
        "transmissionMag": {
            "downcertificate": "Download certificate",
            "certificateUse": "Certificates are only available in the fuse protocol",
            "protocol": "protocol",
            "tMload": "Load",
            "tMloadMsg": "Loading information",
            "uninstall": "Discharge",
            "instanceName": "Instance Name",
            "mainName": "Main Name",
            "equipmentType": "Equipment Type",
            "status": "Status",
            "equipmentLog": "Equipment Log",
            "residualCapacity": "Surplus capacity",
            "totalCapacity": "Total capacity",
            "nfsAddr": "NFS Server Address",
            "nfsPath": "Path",
            "createTime": "Creation time",
            "equipmentDesc": "Equipment Description",
            "confirm-uninstall-equipment": "Are you sure you want to uninstall the passthrough device?",
            "datails": "Details",
            "name": "Name",
            "hostName": "Physical Host Name",
            "loadTime": "Load time",
            "belong_user": "Owned User Name",
            "belong_project": "Project Name",
            "belong_department": "Department Name",
            "hasLoad": "Loaded",
            "unLoad": "Unloaded"
        },
        "labelManagement": {
            "inputLabel": "Please enter a label",
            "input127chart": "127 characters can be entered",
            "labelNohttp": "Tag names cannot contain http:// and https://",
            "noSpecialCharacter": "Only supports English letters, numbers or Chinese characters, +, -, =, ., _, :, /, @, (), [], (), []",
            "labelrepetition": "Duplicate tags",
            "updateLabel": "Edit tags",
            "name": "Name",
            "label": "Label",
            "nolabelrepetition": "Please do not select duplicate tags",
            "selected": "Label selected",
            "bindObj": "binding object",
            "resourcesNum": "Number of bound resources",
            "creater": "founder",
            "createTime": "Creation time",
            "createLabel": "New TAB",
            "editLabel": "Edit TAB",
            "lablename": "Label name",
            "labelDescribe": "Label description",
            "color": "Color",
            "desc": "Description",
            "UnbundLabel": "Unbind label",
            "resourcesType": "Resource Type",
            "resourcesId": "Resource ID",
            "confirmDelLable": "Are you sure you want to remove the label?"
        },
        "extension": {
            "ui": {
                "newScaleCluster": "Create ScaleCluster",
                "scaleClusterName": "Cluster Name",
                "currentSize": "Current Size",
                "scaleClusterNameInput": "Please Enter Cluster Name",
                "currentSizeInput": "Please Enter Initial Size",
                "maxSizeInput": "Please enter positive integer less than 100 and more than Current Size",
                "minSizeInput": "Please enter positive integer less than Current Size",
                "clusterStatus": "Status",
                "description": "Description",
                "createTime": "Create Time",
                "updateTime": "Update Time",
                "AlarmTemplate_placeholder": "Please Select Alarm Template ",
                "Scalpolicy_placeholder": "Please Select Scale Policy",
                "basicInformation": "Basic Information",
                "serverInformation": "Server",
                "loadBalanceInformation": "LoadBalance",
                "scaleRuleInformation": "ScaleRule",
                "extensionName": "Extension Name",
                "imageName": "Image Name",
                "networks": "Networks",
                "configureType": "Configure Type",
                "keypaireName": "Keypaire Name",
                "instancesName": "Instances Name",
                "password": "Password",
                "timingStart": "Timing Start",
                "canNotCreateExtension": "At present, the local disk is not supported to create a cloud host,so elastic expansion can not be built"
            },
            "detail": {
                "cpu.usage_system": "CPU Utilization",
                "mem.used_percent": "Memory usage",
                "disk.used_percent": "Disk Partition Utilization",
                "swap.used_percent": "Swap space utilization",
                "net.bytes_recv": "Network Inflow rate",
                "net.bytes_sent": "Network Outflow rate",
                "mean": "average value",
                "max": "max value",
                "min": "min value",
                "eq": "Equal",
                "ge": "Greater than or equal to",
                "le": "Less than or equal to",
                "gt": "Greater than",
                "lt": "Less than",
                "ScaleClusterDetail": "ScaleCluster Detail",
                "basicInformation": "Basic Information",
                "scaleClusterName": "Cluster Name",
                "currentSize": "Current Size",
                "initialSize": "Current Size",
                "status": "Status",
                "maxSize": "Max Size",
                "minSize": "Min Size",
                "createTime": "Create Time",
                "updateTime": "Update Time",
                "serverInfor": "Server Information",
                "LoadBalanceInfor": "LoadBalance Information",
                "ScaleRuleInfor": "ScaleRule Information",
                "ScaleRecord": "Scale Record",
                "ScaleRecordAlarmEventID": "AlarmEventID"
            },
            "status": {
                "active": "normal",
                "normal": "normal",
                "success": "success",
                "delete-server-failed": "delete failed",
                "creating": "creating",
                "create-failed": "create-failed",
                "deleting": "deleting",
                "delete-failed": "delete failed",
                "scaling": "scaling",
                "scale-failed": "scale failed",
                "failed": "failed"
            },
            "tipMsg": {
                "delScaleCluster": "Confirm Delete Scale Cluster?",
                "instancelimit": "The Quota of scaleluster is not restricted by department Quota",
                "addInstances": "Add Instances",
                "alertInfo": "The cloud host is added to the elastic expansion, and the cloud host network and mirroring and specification are determined by the initial network, mirroring and specification of the elastic expansion.",
                "hourMsg": "Integers in 0~23",
                "minuteMsg": "Integers in 0~59",
                "seconsMsg": "Integers in 0~59",
                "extensionNamePattern": "You can only input letters, numbers, crosses, and underlines.",
                "insNamePattern": "Only inputting numbers,letters and crosses,begin with letters"
            },
            "placeholder": {
                "extensionName": "Please enter Extension Name",
                "insName": "Please enter Instance Name",
                "password": "Please enter password",
                "imageName": "Please select image",
                "networks": "Please select network",
                "configureType": "Please select flavor",
                "keypaireName": "Please select keypaire",
                "noKeypaire": "No available keypaires",
                "date": "Select Date",
                "hour": "hour",
                "minute": "minute",
                "second": "second"
            },
            "info": {
                "extensionName": "Prompt Information",
                "instancesName": "The name of the created cloud host.",
                "password": "The password for logging in to the cloud host operating system.",
                "imageName": "Create the image used by the cloud host.",
                "networks": "Networks used by the elastic cluster.",
                "configureType": "Specifications of the cloud host.",
                "keypaireName": "Create the key pair used by the cloud host. If you select the corresponding key pair name, you can log in to the cloud host by key. You can also log in to the cloud host without using a key pair.",
                "initialSize": "The number of cloud hosts after the cluster is initialized, and the corresponding number of cloud hosts will be automatically created after the elastic extended cluster is created.",
                "maxSize": "Maximum number of cloud hosts allowed in a resilient extended cluster.",
                "minSize": "The minimum number of cloud hosts allowed in a resilient extended cluster.",
                "instanceName": "Name of the cloud host created after the Elastic Extended cluster is initialized.",
                "passwd": "Password to log in to the cloud host operating system.",
                "imgName": "Create an image used by the cloud host.",
                "network": "Network used by resilient clusters.",
                "conf_option": "Elastic extensions create cloud host specifications.",
                "keypair": "Create the key pair used by the cloud host.If you choose the name of the corresponding key pair, you can log in to the cloud host by key.Login to the cloud host without using a key pair.",
                "Vlan": "Subnets associated with elastic clusters.",
                "protocol": "The type of service protocol the load balancer is sending out.",
                "protocol_port": "Load Balancer service port outside.",
                "lbAlgorithm": "Support polling algorithm, least connection algorithm, source IP three algorithms.",
                "Monitoring_Alarm_AlarmTemplate": "When the monitoring rule in the alarm template reaches a threshold, the system triggers elastic expansion.",
                "Cvm_Scalpolicy": "The extended strategy used by the elastic cluster."
            }
        },
        "scalePolicy": {
            "ui": {
                "HORIZONTAL_ONLY": "HORIZONTAL_ONLY",
                "newScalePolicy": "Create ScalePolicy",
                "policyName": "Policy Name",
                "policyNameInput": "Please enter Policy Name",
                "scaleSteps": "Scale Steps",
                "scaleStepsInput": "Please enter scale steps,input negative when lessen",
                "scaleStepsPatternInput": "Please enter nonzero integer",
                "scaleType": "Scale Type",
                "scaleTypeChoice": "Please select scale type",
                "intervalTime": "Interval Time",
                "intervalTimePerSecond": "Interval Time(s)",
                "intervalTimeInput": "Please enter Interval Time",
                "scaleTypeIncrease": "increase",
                "scaleTypeDecrease": "decrease"

            },
            "tipMsg": {
                "delScalePolicy": "Confirm Delete Scale Policy?"
            },
            "info": {
                "scaleSteps": "The number of cloud hosts is increased/decreased each time elastic expansion is triggered.The number of reduced cloud hosts is filled with a negative number, such as -1 for one less cloud host.",
                "scaleType": "Currently only horizontal scaling is supported.",
                "intervalTimePerSecond": "The time interval between two elastic extension operations.No operation is allowed during this period."
            }
        },
        "snapshots": {
            "chooseSnap": "Select cloud Drive",
            "curSnapshot": "Selected snapshot",
            "snapshotName": " Snapshots Name",
            "snapshotManage": " Snapshots Manage",
            "deleteSnapshot": "Delete Snapshots",
            "confirmDelete": "Confirm Delete Snapshots",
            "startVolume": "Create Volume",
            "snapshots_startVolume": "Snapshot Creation cloud Hard Drive",
            "compile": "Edit",
            "name": "Name",
            "size": "size(GB)",
            "detailSnapshot": " Snapshots Detail",
            "snapshotInfo": "Snapshot Information",
            "description": " Snapshots Description",
            "inputname": "Please enter a name",
            "inputdescribe": "Please enter a description",
            "created_at": "Creation time",
            "table": {
                "status": {
                    "available": "available",
                    "AVAILABLE": "Available",
                    "inavailable": "inavailable",
                    "INAVAILABLE": "inavailable",
                    "creating": "creating",
                    "CREATING": "creating",
                    "deleting": "deleting",
                    "DELETING": "deleting",
                    "attaching": "attaching",
                    "ATTACHING": "attaching",
                    "detaching": "detaching",
                    "DETACHING": "detaching",
                    "in-use": "used",
                    "IN-USE": "used",
                    "error": "error",
                    "ERROR": "error",
                    "error-deleting": "error-deleting",
                    "ERROR-DELETING": "error-deleting"
                }
            },
            "tip": {
                "tip1": "This operation is not supported in the store where the snapshot is located",
                "tip2": "Please select a snapshot of the available state",
                "tip3": "Please select a snapshot"
            }
        },
        "permit": {
            "domain": "domain",
            "project": "project",
            "instance": "instance",
            "instanceNum": "instances number",
            "cpuNum": "CPU core",
            "cpuCore": "CPU core（individual）",
            "memb": "Memory（GB）",
            "mem": "Memory",
            "externalIp": "Public network IP（individual）",
            "projectList": "project List",
            "user": "user",
            "domainDetail": "Details of the use of departmental resources",
            "totalQuota": "(total amount of use / quota)",
            "domainUser": "domain user",
            "projectUser": "project user"
        },
        "users": {
            "pageTitle": "User Manage",
            "adduser": "Create User",
            "edituser": "Edit User",
            "allocatePrivilege": "Allocate privilege",
            "changePass": "Reset Password",
            "allocateOrg": "Allocate Organization",
            "userName": "User Name",
            "role": "Role",
            "baseRole": "Base Role",
            "name": "Name",
            "phone": "Phone Number",
            "email": "Email",
            "status": "Status",
            "createTime": "Create time",
            "operate": "Operate",
            "domainName": "Domain",
            "active": "active",
            "locked": "locked",
            "addUserItem": {
                "slectRole": "Choice of roles",
                "selectdp": "Select Department / select project",
                "eInformation": "Essential information",
                "selectdomain": "Selection Department",
                "addDomain": "New Department",
                "selectProject": "Selection project",
                "noProject": "There is no project. Please build a new project",
                "addProject": "New project"
            },
            "roles": {
                "1": "Admin",
                "2": "Admin",
                "3": "Domain Admin",
                "4": "Project Admin",
                "5": "Member",
                "8": "security",
                "9": "audit",
                "menuText": "Menu permission",
                "menuTips": "Operation Button permission",
                "10": "All",
            },
            "cu": {
                "name": "Name",
                "org": "Organization",
                "privilege": "privilege",
                "department": "Department",
                "project": "Project",
                "role": "Role",
                "username": "Name",
                "rolename": "role name",
                "password": "Password",
                "confirmPwd": "Confirm Password",
                "roles": {
                    "admin": "Admin",
                    "domain_admin": "Domain Admin",
                    "project_admin": "Project Admin",
                    "member": "member",
                    "guest": "member"
                },
                "passwordrank_one": "Please enter one of numbers (0-9), letters (A-Z) (a-z) or special characters (!@._:,/-^%) for the password.",
                "passwordrank_two": "The password must contain two of three characters: uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), and special characters (!@._:,/-^%).",
                "passwordrank_three": "The password must contain four characters: uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), and special characters (!@._:,/-^%)."
            },
            "tip": {
                "notDelTip": "No permission to delete role",
                "allocate_admin": "No permission of admin assignment",
                "edit_admin": "No permission to edit admin",
                "edit_admin_pass": "No permission to edit admin's Password",
                "allocate_user": "No permission of assignment",
                "edit_user": "No permission to edit user",
                "edit_user_pass": "No permission to edit Password"
            },
            "placeholder": {
                "name": "Please enter Name",
                "org": "Please choose Organization",
                "privilege": "Please select privilege",
                "department": "Please select Department",
                "project": "Please select Project",
                "role": "Please select Role",
                "username": "Please enter Name",
                "password": "Please enter Password",
                "confirmPwd": "Confirm Password",
                "phone": "Please enter phone number",
                "email": "Please enter email"
            },
            "un_allocatePrivilege": "Domain privilege",
            "have_allocated": "privilege allocated",
            "delMsg": "Confirm user delete",
            "canNotDel": "admin can not delete！",
            "canNotEdit": "admin can not edit！",
            "oneUser_oneRole": "Each user can only have one role",
            "bindRoleBtn": "Bind Role",
            "untieRoleBtn": "Untie Role",
            "ad": {
                "name": "Employee Name",
                "loginName": "Login Name",
                "role": "Role",
                "position": "Position",
                "description": "Description",
                "createTime": "Create Time",
                "lastLogin": "Last Login Time",
                "lastModifyPwd": "Last Modify Password",
                "enabled": "Account Disable",
                "true": "Yes",
                "false": "No",
                "bindADRole": "Bind Role",
                "selectRole": "Select Role",
                "selectDomain": "Select Domain",
                "selectProject": "Select Project",
                "managementRole": "Bind Role",
                "bind": "Bound",
                "unbind": "Unbound",
                "untieConfirm": "Are you sure to unbind?"
            },
            "adBindRoleTip": "Users with bound roles cannot perform operations",
            "adUntieRoleTip": "Users with unbound roles cannot be operated",
            "adUntieRoleTip1": "Super administrator cannot unbind",
            "edit": "Edit",
            "bindRole": "Bind role",
            "syncTip": "Do you need to sync user-managed content?",
            "bound": "Bound",
            "unbound": "Unbound",
            "sync": "Sync",
            "syncTip1": "syncing",
            "syncTip2": "Sync complete"
        },
        "loader": {
            "createVIP": "Create VIP",
            "editVIP": "Edit VIP",
            "deleteVIP": "Delete VIP",
            "bindIP": "Associate Floating IP",
            "unbindIP": "Disassociate floating IP",
            "bindMonitor": "Associate monitor",
            "unbindMonitor": "Disassociate monitor",
            "newloadbalance": "Create Loadbalance",
            "editloadbalance": "UpdateLoadbalance",
            "loadBalanceName": "Loadbalance Name",
            "subnet": "Subnet",
            "proto": "Portocol",
            "method": "Loadbalance Method",
            "desc": "Description",
            "type": "Type",
            "delay": "Delay",
            "timeout": "timeout",
            "maxrepeat": "Max repeat time",
            "projectName": "Project Name",
            "loadername": "Loadbalance Name",
            "status": "Status",
            "VIP": "VIP",
            "detail": "Loadbalance Detail",
            "confirmDelete": "Confirm delete Loadbalance"
        },
        "networks": {
            "intranet": "intranet",
            "routerConfig": "Router Config",
            "bindWidth": "Bind Width(Mbps)",
            "bindWidthNoUnit": "Bind Width",
            "network": "network",
            "name": "Name",
            "identifier": "Identifier",
            "networkName": "Name",
            "newNetwork": "Create Network",
            "availableIP": "Available IP Numbers",
            "subnetDetail": "Subnet Detail",
            "baseMsg": "Base Message",
            "portMsg": "Port Message",
            "subnetManager": "Subnet Manager",
            "detail": "detail",
            "VLANID": "VLAN ID",
            "allocateAddress": "Allocating Address Pool",
            "availableAddressRange": "Address Range",
            "subnet": "Subnet",
            "editNetwork": "Edit networks",
            "newSubnet": "Create Subnet",
            "editSubnet": "Edit Subnet",
            "delSubnet": "Delete Subnet",
            "selectSub": "Select the subnet which you want to edit",
            "netHasNoSub": "The selected network has no subnet",
            "selectOneNetwork": "Please select one network",
            "selectNet": "Please select network",
            "subnetName": "Subnet Name",
            "bindSubnet": "Associate Subnet",
            "external": "external",
            "projectName": "Project Name",
            "providerNet": "Provider Network",
            "netType": "Network Type",
            "choseNetType": "Select Network Type",
            "chosePhyNet": "Select Physical Network",
            "phyNet": "Physical Network",
            "segmentation_id": "Segmentation ID",
            "netDetail": "networks Detail",
            "portName": "Port Name",
            "ipAddr": "IP Address",
            "subnetID": "Subnet ID",
            "deviceOwner": "Device Owner",
            "IPvision": "IP Version",
            "subnetType": "Subnet Type",
            "gateway": "Gateway",
            "enableDhcp": "Enable DHCP",
            "useDhcp": "Use DHCP",
            "hostRoutes": "Host Routes",
            "dnsNameserver": "DNS Nameserver",
            "IP_pools": "IP pool",
            "start": "Start",
            "end": "End",
            "run": "run",
            "stop": "stop",
            "gatewayIp": "Gateway Ip",
            "enableSnat": "Use SNAT",
            "routerName": "Router Name",
            "VPCName": "VPC Name",
            "basicsInfo": "Basic Info",
            "ruleManage": "Rule Manage",
            "createFirewallRule": "Create Firewall Rule",
            "createRule": "Create Rule",
            "ruleList": "RuleList",
            "choseNetwork": "Select Networks",
            "noNetToChose": "Not available Networks，Please create",
            "user_noNetToChose": "Not available Networks",
            "ip_auto_to_allocate": "GatewayIP Address will be allocated",
            "allocateIp_pool": "Allocate Address pool",
            "canNotDelSharedNet": "Shared Network, no right to delete",
            "canNotEditSharedNet": "Shared Network, no right to edit",
            "canNotDelSharedSub": "Shared Subnet, no right to share",
            "canNotEditSharedSub": "Shared Subnet, no right to edit",
            "canNotAddSharedSub": "Shared Subnet,no right to add",
            "noPrivilegeDelExternalNet": "External Network,no right to delete",
            "noPrivilegeEditExternalNet": "External Network,no right to edit",
            "canNotDelExternalNet": "External Network,cannot to delete",
            "noPrivilegeDelExternalSub": "External Subnet,no right to delete",
            "noPrivilegeEditExternalSub": "External Subnet,no right to edit",
            "canNotDelExternalSub": "External Subnet,cannot to delete",
            "firewall": "Firewall",
            "createFirewall": "Create Firewall",
            "editFirewall": "Edit Firewall",
            "moreAction": "More Actions",
            "associationRouter": "Associate Router",
            "disassociationRouter": "Disassociate Router",
            "up": "UP",
            "todown": "DOWN",
            "created": "CREATED",
            "active": "ACTIVE",
            "inactive": "INACTIVE",
            "error": "ERROR",
            "down": "DOWN",
            "pending_create": "PENDING_CREATE",
            "pending_update": "PENDING_UPDATE",
            "pending_delete": "PENDING_DELETE",
            "name": "Name",
            "policy": "Policy",
            "firewallName": "Firewall Name",
            "policyName": "Policy Name",
            "ruleName": "Rule Name",
            "createFirewallPolicy": "Create Firewall Policy",
            "editFirewallPolicy": "Edit Firewall Policy",
            "status": "Status",
            "adminState": "Administrator State",
            "description": "Description",
            "firewallDetail": "Firewall Detail",
            "tenant_id": "Tenant ID",
            "firewallPolicyName": "Firewall Policy Name",
            "firewallPolicyId": "Firewall Policy ID",
            "router": "Route",
            "inputFirewallName": "Please enter Firewall name",
            "addRouter": "Add Route",
            "chosePolicy": "Select Policy",
            "choseAdminStatus": "please select Administrator State",
            "inputFirewallDes": "please enter Firewall description",
            "choseRouter": "please select route",
            "choseAssociationRouter": "please select associated Routes",
            "choseDisassociationRouter": "please select disassociated Routes",
            "noRouterToChose": "Not available Route, Please create",
            "firewallPolicy": "Firewall Policy",
            "insertRule": "Add Rule",
            "choseInsertRule": "Please select the Rules you want to add",
            "noRuleToInsert_shared": "Not available Rules to add,Please create Shared Rules",
            "noRuleToInsert_noneshared": "Not available Rules to add,Please create Unshared Rules",
            "removeRule": "Delete Rules",
            "choseRemoveRule": "Please select the rules you want to delete",
            "rule": "Rule",
            "shared": "Shared",
            "audit": "Audit",
            "audited": "Audited",
            "addPolicyTip": "Tip: Rule chosed is not shared,so the Policy cannot be shared.",
            "editPolicyTip": "Tip:The Policy has unshared rules,so the shared attribute cannot be edit.",
            "firewallPolicyDetail": "Firewall Policy Detail",
            "inputFirewallPolicyName": "Please enter the Firewall Policy name",
            "addRule": "Create Rule",
            "choseFirewallRule": "Please select the Firewall Rule",
            "noRuleToChose": "Not available Firewall Rule ,Please Create",
            "choseFirewallPolicy": "Please select the Firewall Policy",
            "noPolicyToChose": "Not available policy,Please Create",
            "inputFirewallPolicyDes": "Please enter the description of the Firewall Policy",
            "firewallRule": "Firewall Rule",
            "createFirewallRule": "New Firewall Rule",
            "editFirewallRule": "Edit Firewall Rule",
            "inPolicy": "Policy",
            "source_ip_address": "Source IP Address",
            "subnet": "Subnet",
            "port": "Port",
            "portLimit": "PortLimit",
            "operate": "Operate",
            "source_port": "Source Port",
            "destination_ip_address": "Destination IP Address",
            "destination_port": "Destination Port",
            "protocol": "Protocol",
            "action": "Action",
            "enabled": "Enabled",
            "firewallRuleDetail": "Firewall Rule Detail",
            "usedPolicy": "Used Policy",
            "inPolicyPosition": "In Policy Position",
            "inputFirewallRuleName": "Please enetr Firewall Rule name",
            "choseProtocol": "Please select the Protocol",
            "choseAction": "Please select Action",
            "source_ip_subnet": "Source IP Address/Subnet",
            "source_ip_subnet_title": "Source IP Adress/subnet\n[port]",
            "input_source_ip": "Please enter Source IP Adress，For example：192.168.0.1",
            "input_source_subnet": "Please enter the subnet address, for example: 192.168.0.1/24",
            "inputPatternError": "Input format error",
            "destination_ip_subnet": "Destination IP Address/subnet",
            "destination_ip_subnet_title": "Destination IP Address/subnet\n[port]",
            "input_destination_ip": "Please eneter Destination IP Address，For example：192.168.0.1",
            "input_source_port": "Please enter Source Port",
            "input_source_port_start": "Please enter start Port",
            "input_source_port_end": "Please enter end Port",
            "input_destination_port": "Please enter Destination Port",
            "input_destination_port_start": "Please enter start Port",
            "input_destination_port_end": "Please enter end Port",
            "input_firewallRuleDes": "Please enter description of the Firewall Rule",
            "project": "Project",
            "domain": "Domain",
            "all": "ALL",
            "any": "Any",
            "allow": "Allow",
            "refuse": "Refuse",
            "deny": "Deny",
            "anyPosition": "Any Position",
            "beUsedPolicy": "Be used Policy by Firewall,cannot delete!",
            "beUsedRule": "Be used Rule by Firewall Policy,connot delete!",
            "newAdd": "Add",
            "enableGateway": "Startup gateway",
            "patternError": "Formatting error",
            "inter1_128": "Please enter inter between 1~128",
            "subInter1_128": "Please enter inter between 1~128",
            "cidrErrorInfo": "The length of the IPv6 address is 128 bits. The colon is separated by sixteen binary format. Please enter the correct sixteen number in the input box, such as 21DA.",
            "ipType": "Subnet type",
            "inter64_126": "Enter an integer between 64 and 126",
            "subInter64_126": "Integer between 64 and 126",
            "v6": {
                "enabledV6": "Enabled IPv6",
                "getV6Type": "Get IPv6 Type",
                "v6CIDR": "IPv6 CIDR",
                "v6range": "Adress Range",
                "start": "Start",
                "end": "End",
                "notInRange": "The IP is not in the range of IPv6 CIDR",
                "v6ipValidate": "The first 16-bit address of CIDR cannot be entered into a multicast address (starting with double f (F))",
                "selectV6Type": "Please select type of getting IPv6"
            },
            "placeholder": {
                "networkName": "Please enter networks Name",
                "ipInput": "Please enter IP Address(eg: 192.168.1.0/16)",
                "segmentation_id": "Please enter VLAN ID",
                "segmentation_id1": "The system will distribute VLAN ID automatically if empty",
                "segmentation_vxlanId": "The system will distribute VXLAN ID automatically if empty",
                "allocateIp_pool": "IP Address pool record (eg: 192.168.1.2,192.168.1.254)",
                "dnsNameserver": "DNS Nameserver of Subnet(eg:114.114.114.114)",
                "dnsNameserverv6": "The DNS server IP list of this subnet (for example: AD80:0000:0000:0000:ABAA:0000:00C2:0002) is one line per record, enter the next one in a new line",
                "hostRoutes": "Host Routers(eg: 192.168.200.0/24,192.168.200.254)",
                "hostRoutesv6": "The host adds additional routes (for example: AD80:0000:0000:0000:ABAA:0000:00C2:0002/24,AD80:0000:0000:0000:ABAA:0000:00C2:0002) This is one line, enter the next line in a new line",
                "networkBandwidth": "Network Bandwidth,no setting represents speed limit",
                "gatewayIp": "The system will assign the first IP address of the segment as a gateway if empty",
                "input_source_ip_subnet": "Please enter Source IP Address/Subnet ",
                "input_destination_ip_subnet": "Please enter Destination IP Address/Subnet",
                "selectNetworks": "Please select network"
            },
            "del": {
                "netMsg": "Confirm Delete Selected networks",
                "subMsg": "Confirm Delete Selected Subnet",
                "delFirewallMsg": "Confirm Delete Selected Firewall",
                "delFirewallRuleMsg": "Confir  Delete Selected Firewall Rule",
                "forbiddenFirewallRuleMsg": "Are you sure to disable the selected firewall rules?",
                "enabledFirewallRuleMsg": "Are you sure to enable the selected firewall rules?"
            },
            "info": {
                "mainInfo": "Private network is a user defined logic isolation network space, which is hosted in the private network by cloud host and load balancing resources. Complete logical isolation between different private networks. Users can customize network partition, IP address and routing strategy, and achieve multi-layer security through firewalls and security groups.",
                "subnetInfo": "You can manage the life cycle and subnet scope of external network subnet.",
                "bindSubnetInfo": "A private network can have multiple subnets. Please add a subnet on the details > subnet management page.",
                "sharedInfo": "'Yes' means that the network can be used by other departments or projects; 'no' indicates that the network can not be used by other projects (nor can it be seen).",
                "availableIPInfo": "The sum of the number of IP addresses that each subnet can use under the network",
                "networkBandwidth": "Network bandwidth limit, specify the maximum bandwidth allowed.",
                "createNetSharedInfo": "If you want the network to be used by other departments or projects, please check it; if you don't want the network to be used by other projects, you do not need to check it.",
                "availableInfo": "If the entire subnet address is available, there is no need to check; if there are some segments of the subnet that can not be used, please check and set the available address range.",
                "dhcpInfo": "It is recommended to check 'start DHCP'. Starting DHCP, the cloud host in the private network can dynamically acquire IP address, gateway address, DNS server address and other information, and can enhance the utilization rate of the address",
                "physicalNet": "physicalNet",
                "phyMainInfo": "The external network is the mapping of external physical network, which is generally used to allocate the gateway address to the virtual router, or to allocate the public network IP address to the instance.",
                "subnetInfo":"You can manage the life cycle and subnet scope of external network subnet.",
                "networkName": "networkName",
                "netType": "netType",
                "vlan": "vlan",
                "vxlan": "vxlan",
                "subnetName": "subnetName",
                "subnetCidr": "subnetCidr",
                "enableGateway": "enableGateway",
                "gatewayIp": "gatewayIp",
                "enabledIpv6": "Check 'Start IPv6', then the IP address of the subnet is IPv6 address.",
                "ipType": "Specifies the type of subnet to create, supports IPv4 and IPv6",
                "typeOfIpv6": "Specify how the cloud host will get the IPv6 address.",
                "adressRangeOfIpv6": "If the entire subnet address is available, there is no need to check it; if some segments of the subnet are not available, check it and set the range of available addresses.",
                "dnsNameserver": "dnsNameserver",
                "hostRoutes": "hostRoutes",
                "choseNetwork": "choseNetwork",
                "availableAddressRange": "availableAddressRange",
                "subnetQuota": "subnetQuota",
                "physicalNetName": "physicalNetName",
                "physicalNet":"physical network",
                "slaac01": "Stateless address auto-configuration SLAAC: addresses are discovered from cloud platform routers",
                // "slaac02":"Stateless address autoconfiguration SLAAC: addresses are discovered via an external router",
                "slaac02": "Stateless address autoconfiguration SLAAC: addresses are discovered through a physical router",
                "DHCPv601": "Stateful DHCPv6: address is obtained from cloud platform DHCP",
                "DHCPv602": "Stateless DHCPv6: The address is obtained from the cloud platform router, and the extended information is obtained from the cloud platform DHCP",
                "DHCPv603": "Stateful DHCPv6: address is obtained from external DHCP",
                "DHCPv604": "Stateless DHCPv6: address is obtained from physical router, extended information is obtained from external DHCP",
                "ipv6Cidr": "If you start IPv6, fill in the IPv6 segment of the subnet. When 'Get IPv6' is configured as SLAAC and dhcpv6-stateless, only EUI-64 address, i.e. network prefix 64, is supported, and IPv6 subnet and router association are required."
            },
            "phyInfo": {
                "netType": "External network type, VLAN or FLAT.",
                "physicalNet": "Physical network information associated with external networks.",
                "vlan": "Set the VLAN ID to which the external network belongs. The range of VLAN IDs is set in the deployment phase, and the VLAN IDs of each network need to be unique.",
                "createNetSharedInfo": "Is the network set to a shared network? Checking indicates that the network can be used by other departments or projects. If you do not want the network to be used by other departments or projects, do not check this item.",
                "subnetName": "The name of the external network subnet.",
                "subnetCidr": "IP segment of subnet.",
                "availableAddressRange": "If the entire subnet address is available, there is no need to check it; if some segments of the subnet are not available, check it and set the range of available addresses.",
                "gatewayIp": "The IP address of the gateway. If it is empty, it will be allocated automatically by the system.",
                "useDhcp": "It is suggested to check 'Start DHCP'. Starting DHCP, cloud hosts in external networks can dynamically acquire IP address, gateway address, DNS server address and other information, and can improve the utilization of address.",
                "dnsNameserver": "By default, the DNS server address outside the network is used. If you need to modify the DNS server address, make sure that the DNS server address is available.",
                "hostRoutes": "Host adds additional routing."
            },
            "price": {
                "totalPrice": "Total price",
                "perHour": "per hour",
                "perMonth": "per month",
                "count": "In total"
            },
            "vpcNetWork": "VPC Networks",
            "netBeyondProQuota": "The project Networks's qouta is insufficient",
            "netBeyondDepQuota": "The domain Networks's quota is insufficient",
            "subnetBeyondProQuota": "The project Subnet's quota is insufficient",
            "subnetBeyondDepQuota": "The domain Subnet's quota is insufficient",
            "externalNetwork": "External Net",
            "exchangeQuota": "Network Quota",
            "subnetQuota": "Subnet Quota",
            "subnetCidr": "Subnet CIDR",
            "autoAllocateGateway": "Auto allocation gateway",
            "gatewayIp": "Gateway IP",
            "inputGatewayIp": "Please input gateway IP",
            "ipPatternError": "IP pattern is error",
            "gatewayIpNotinSubCidr": "The gateway IP is not in subnet CIDR",
            "gatewayIpRange": "The gateway Ip can be entered range",
            "ipPoolPattern": "The right pattern is:192.168.1.2,192.168.1.254,the two IP as one item，please enter one item per line",
            "ipPoolIsOverlap": "The IP address is overlapped",
            "ipInPoolEqToGateway": "Gateway Ip is in the Ip pool,please enter again",
            "ipInPoolNotInSubCidr": "IP in the ip pool is not in subnet cidr",
            "ipPoolStartIpgtEndIp": "The end IP of ip pool must gt the start IP",
            "ipNotFullForDhcp": "Start DHCP with at least four IPs available.",
            "dnsNameserverPattern": "The right input pattern is:114.114.114.114,please enter one item per line",
            "dnsNameserverPatternv6": "The input format is incorrect, please re-enter the correct IP address. Such as: AD80:0000:0000:0000:ABAA:0000:00C2:0002, one line for each record, enter the next one in a new line",
            "additionalRouterPattern": "The right input pattern is:192.168.200.0/24,192.168.200.254,this is one item，please enter one item per line",
            "additionalRouterPatternv6": "Please input in the correct format, the correct input format is: AD80:0000:0000:0000:ABAA:0000:00C2:0002/24,AD80:0000:0000:0000:ABAA:0000:00C2:0002, this is one, Enter the next line",
            "repeatRouter": "Repeat router",
            "repeatipinput": "DNS domain name server adresss repeat",
            "ipinputmax": "Up to 5 DNS records are currently supported, and the sixth and later DNS will not come into effect",
            "routermax": "Up to 20 additional routing records are currently supported, and twenty-first will not come into effect",
            "inputSubnetName": "Please input subnet name",
            "ipPool": "IP Pool",
            "start": "Start",
            "end": "End",
            "ipNotInSubCidr": "The ip address is not in subnet cidr",
            "ipIsNetworkAdress": "Can not enter networkAdress of the subnet in the available address",
            "ipIsBroadcastAdress": "Can not enter broadcastAdress of the subnet in the available address",
            "poolNarrow": "This IP pool cannot narrow down,please enter again",
            "assignedIpNotInPool": "The pool does not contain IP that has been allocated,please enter again.",
            "assignVlan": "Assign VLAN",
            "networkBandwidth": "Instance Network Card Bandwidth",
            "noLimit": "No Limit",
            "except_external_net": "Please enter again,except 'external_net' ",
            "except_external_subnet": "Please enter again,except 'external_subnet' ",
            "address_range": "Available address range",
            "startIp": "Please enter start IP",
            "endIp": "Please enter an end IP",
            "gatewayTips": "If the system is empty, the system will automatically assign the network pipe IP",
            "bandWidthChangeMsg": "After modifying bandwidth, the speed limit is only valid for the newly built instances using the network. The speed limit of the previously created instances is adjusted by itself in the instances network settings.",
            "createPhyMsg":"There is no external network that can be selected, please add new external network operations after the product management-operation management-multi-external network management"
        },
        "firewall": {
            "newFireWall": "Create FireWall",
            "editFireWall": "Edit FireWall",
            "newFireWallSuccess": "Add Firewall success",
            "createThen": "Newly-built later",
            "description": "Description",
            "allow": "Allow",
            "deny": "Deny",
            "reject": "Reject",
            "false": "False",
            "true": "True",
            "routeGateway": "Route GatewayIp",
            "endPortBiggerStartPort": "End port must bigger than start port",
            "selectStatus": "Select Status",
            "detail": "Detail",
            "setSourceIP": "Source Ip Adress/Subnet Port",
            "setDestinationIP": "Destination Ip Adress/Subnet Port",
            "firewallDefaultRule": "Firewall Default Rule",
            "moveUp": "Move Up",
            "moveDown": "Move Down",
            "defaultRuleCanNotDel": "The default rule of firewall can not be deleted",
            "effectConfigureRule": "Firewall must configure rules to take effect. Do you want to set up firewall rules immediately?",
            "create": "Create",
            "source_ip": "Source Ip Adress/Subnet",
            "destination_ip": "Destination Ip Adress/Subnet",
            "port": "[port]",
            "info": {
                "mainInfo": "The firewall acts on the router, protecting the router and the entire internal network connected with the router from the illegal intrusion of the external network.",
                "protocol": "Specifies the protocol type of the filtered packet.Any representation of all packets.",
                "action": "Set the actions of allowing, rejecting, discarding, etc. For incoming packets from the external network.If used for diagnostics and debugging, select Reject;If you want to ensure the high security and efficiency of the firewall, please select Discard."
            }
        },
        "physicalNetworks": {
            "externalNet": "External Networks",
            "physicalNet": "Physical Networks",
            "shared": "Shared Networks",
            "physicalNetName": "Physical Networks Name",
            "newPhysicalNet": "Create Physical Networks",
            "editPhysicalNet": "Edit",
            "vlanIdInteger": "Please enter integer",
            "placeholder": {
                "physicalNetName": "Please enter physical networks name",
                "noPhyNetworks": "No available physical networks"
            }
        },
        "loadbalancingcertificate": {
            "certificate": "Certificate",
            "name": "Name",
            "type": "Type",
            "server": "Server",
            "content": "Content",
            "formaterror": "The certificate format is incorrect, please refer to the sample reference",
            "reference": "Sample reference",
            "privatekey": "Private key",
            "describe": "Describe",
            "specificsymbol": "special characters are not allowed",
            "keyplaceholder": "Please enter private key",
            "caplaceholder": "- The certificate contains information such as the public key and signature of the certificate, and you can directly enter the content of the certificate.- The format of the certificate content is: start with '-----BEGIN CERTIFICATE-----', end with '-----END CERTIFICATE-----', each line has 64 characters, and the last line does not exceed 64 characters characters, no blank lines."
        },
        "routers": {
            "router": "Router",
            "routerName": "Router Name",
            "newRouter": "Create Router",
            "editRouter": "Update Router",
            "state": "state",
            "extNetwork": "External Network",
            "adminState": "Admin State",
            "enableSnat": "Enable SNAT",
            "projectName": "Project Name",
            "routerDetail": "Router Detail",
            "linkSubnet": "Associate Subnet",
            "unLinkSubnet": "DiassociateSubnet",
            "portName": "Port Name",
            "portIp": "PortIP",
            "deviceOwner": "Device Owner",
            "routerExtNet": "Router External Network",
            "subnet": "Subnet",
            "subSegment": "Subnet Segment",
            "choseSubnet": "Select Subnet",
            "choseSubSegment": "Chose subnet segment",
            "choseLinkSubnetHolder": "please chose subnet to link",
            "editPort": "Edit Port",
            "gatewayIp": "Gateway IP",
            "portForwardingInfo": "After the inner network address is bound to the floating IP, its port forwarding function will fail.",
            "portMoreInfoTips": "When there is a lot of port forwarding data, the corresponding network response under the router may be slow.",
            "ProposedRules": "It is recommended that the number of port forwarding rules not exceed 50",
            "placeholder": {
                "routerExtNet": "Select Router External Network",
                "noRouterExtNet": "No available Router External Network",
                "noSubToChose": "No available Subnet, Please Create",
                "ipExample": "Example：10.10.10.0/24",
                "routerName": "Please enter router name",
                "outerPort": "Please enter Extranet port",
                "innerPort": "Please enter Intranet port",
                "description": "Please enter description",
                "ipAddress": "Please enter ip address",
                "ipTitle": "*IP address format error",
                "portLimit": "Please enter number between 1~65535",
                "noPortForwarding": "The router does not add a gateway, and the port forwarding rules cannot be added. Please add router gateway first and add port forwarding rule.",
                "subnetLimit": "The correct IP address format and scope are：",
                "noPortLimit": "There is no associated subnet under the router",
                "choiceVm": "Please select instance",
                "noVm": "No associated instances"
            },
            "del": {
                "delRouterMsg": "Confirm Delete Selected Router",
                "unLinkSubnetMsg": "Confirm disassociate Subnet",
                "delRouterGateway": "Confirm Delete Selected Router Gateway ",
                "delStaticRoutingTable": "Confirm Delete Selected Static Routing Table",
                "delPortForwarding": "Confirm Delete Selected Port forwarding?"
            },
            "status": {
                "active": "active",
                "down": "down",
                "build": "build",
                "error": "error",
                "pending_create": "pending_create",
                "pending_update": "pending_update",
                "pending_delete": "pending_delete",
                "unrecognized": "unrecognized"
            },
            "setGateway": "Set Gateway",
            "delGateway": "Delete Gateway",
            "subnetList": "Subnet List",
            "staticRouteTable": "Static Routing Table",
            "addRouteTable": "Add Routing Table",
            "delRouteTable": "Delete Routing Table",
            "destinationCIDR": "Destinate CIDR",
            "nexthop": "Nexthop",
            "beyondQuota_dep": "Domain Router quota is insufficient",
            "beyondQuota_pro": "Project Router quota is insufficient",
            "routeQuota": "Router Quota",
            "setRouteGateway": "Set Router Gateway",
            "noSubnet": "no subnet available,please create",
            "addRouteTableInstruction": "Instruction:Add Routing Table to the Router.The nexthop IP must be an IP of subnet that Router Interface has connected",
            "unusedIp": "IP address is unused by Subnet segment",
            "assignExternalGatewayIp": "Assign External Gateway",
            "routerGatewayIp": "Router Gateway",
            "assignExternalSubnet": "Assign External Subnet",
            "portForwarding": "PortForwarding",
            "name": "Name",
            "agreement": "Agreement",
            "outerAddress": "OuterAddress",
            "outerPort": "OuterPort",
            "innerAddress": "innerAddress",
            "innerPort": "InnerPort",
            "ipAddress": "Instance Ip",
            "condition": "condition",
            "desc": "description",
            "custom": "custom",
            "innerlimite": "Fill in the scope：1-65535",
            "customIpAddress": "Custom Ip",
            "enter": "Please enter",
            "integer": "Integer",
            "keymsg": "This key is used for authentication when SaaS cloud management calls MaaS API. The default storage location is the /etc/maas/api_key file in the MaaS container.",
            "synchronizationData": "Synchronous Data",
            "synchronizationUser": "Sync users",
            "info": {
                "routerName": "",
                "routerExtNet": "External networks associated with routers.",
                "assignExternalSubnet": "When 'Designated External Subnet' is not checked, the system randomly allocates subnets. After checking 'Specify an External Subnet', select the subnet name in the drop-down list.",
                "choseSubnet": "Select the subnet associated with the router.",
                "assignExternalGatewayIp": "When 'Designated External Gateway IP' is not checked, the system randomly assigns IP addresses. After checking 'Specify External Gateway IP', the IP address must be set manually.",
                "subSegment": "Specify a subnet segment in which the IP address must be.",
                "routerGatewayIp": "",
                "linkSubnet": "After associating the subnet with the router, the subnet can access other networks through this router.",
                "portName": "Router port name.",
                "destinationCIDR": "The segment of the destination subnet.",
                "nexthop": "The IP address of the interface of another router connected to the current router must be in the same network segment as the destination subnet.",
                "agreement": "The type of protocol used for data transmission.",
                "outerAddress": "The gateway address of the router. The address is fixed and cannot be entered. If the router does not have a gateway, you cannot create a new one.",
                "outerPort": "Router gateway ports cannot be reused.",
                "customIpAddress": "The IP address must be bound to the subnet under the router.",
                "insIpAddress": "Routers associate IP addresses of cloud hosts under subnets.",
                "innerPort": "Intranet ports can be reused."
            }
        },
        "bandwidths": {
            "unlimited": "unlimited",
            "bandwidth": "bandwidth",
            "portId": "PortID",
            "projectName": "Project Name",
            "ipAddr": "IP Address",
            "phyNetWork": "Physical Network",
            "device": "device",
            "editBandwidth": "Edit bandwidth"
        },
        "floatingIps": {
            "editFloatingIps": "Edit FloatingIps",
            "floatIp": "Float IP",
            "addFloatIp": "Create Float IP",
            "bind": "Associate",
            "unbind": "Disassociate",
            "bindFloatIp": "Associate Float IP",
            "assignSub": "Specify Subnet",
            "inputIP": "Specify IP",
            "ipAddr": "IP Address",
            "instanceName": "Instance Name",
            "resourceName": "Resource Name",
            "resourceType": "Resource Type",
            "resource": "Resource",
            "fixedIP": "Fixed IP",
            "status": "Status",
            "projectName": "Project Name",
            "routerId": "Router ID",
            "portId": "Port ID",
            "resourcePool": "Resource Pool",
            "floatIpPool": "Float IP Pool",
            "subnet": "Subnet",
            "subSegment": "Subnet Segment",
            "choseSubSegment": "Chose Subnet Segment",
            "floatingIpQuota": "FloatingIp Quota",
            "loadBalance": "Loadbalance",
            "placeholder": {
                "choseFloatIpPool": "Select Float IP pools",
                "choseInstance": "Select Instance",
                "choseSubnet": "Select Subnet",
                "choseResource": "Select Resource"
            },
            "del": {
                "unBindFloatIpMsg": "Confirm Disassociate floating IP",
                "delFloatingIpMsg": "Confirm Delete Selected Floating IP",
                "confirmDelFloatingIpMsg": "Some Floating IPs Have Bound to Resource Consist In Your Choice"
            },
            "beyondQuota_pro": "Project Floating IP quota is insufficient",
            "beyondQuota_dep": "Domain Floating IP quota is insufficient",
            "noInsChose": "No instance available, please create",
            "createInstance": "Create Instance",
            "ports": "Ports",
            "enter": "Please enter",
            "integer": "integer",
            "floatingipQuota": "floatingIp quota",
            "info": {
                "assignSub": "When 'Designated External Subnet' is not checked, the system randomly allocates subnets. After checking 'Specify an External Subnet', select the subnet name in the drop-down list.",
                "choseSubnet": "Select the subnet associated with the external network.",
                "inputIP": "When 'Specify External IP Address' is not checked, the system randomly assigns IP addresses. After checking 'Specify an External IP Address', the IP address must be set manually.",
                "subSegment": "Specify a subnet segment in which the IP address must be."
            }
        },
        "vpn": {
            "name": "Name",
            "description": "Description",
            "state": "Status",
            "server_state": "server status",
            "newVpn": "Create VPN",
            "editVpn": "Edit VPN",
            "seconds": "Seconds",
            "basicConfig": "Basic Configuration",
            "delVPN": "Confirm Delete Selected VPN",
            "delSubnet": "Confirm Delete Selected Local Subnets",
            "delTargetNet": "delete target network",
            "configSiteConnect": "Configuring Site Connections",
            "IKEAdvancedOptions": "IKE Advanced Options",
            "IpsecAdvancedOptions": "Ipsec Advanced Options",
            "modifyIKEPolicy": "Modify IKE Policy",
            "modifyIPSecPolicy": "Modify IPSec Policy",
            "modifySiteConnection": "Modify Site Connection",
            "enabled": "Enabled",
            "disabled": "Disabled",
            "authenAlgorithm": "Authentication Algorithm",
            "encryptionAlgorithm": "Encryption Algorithm",
            "IKEversion": "IKE version",
            "IKEperiod": "IKE period",
            "IKEConsultativePeriod": "IKE Consultative Period",
            "addLocalSubnet": "Add Local Subnet",
            "oneNegotiationModel": "Stage One Negotiation Model",
            "perfectSecrecy": "Perfect forward secrecy",
            "transProtocol": "Transport Protocol",
            "EncapsulationMode": "Encapsulation Mode",
            "IPSecProtocolCycle": "IPSec Protocol Cycle",
            "localRouter": "Local Router",
            "localNetwork": "Local Network",
            "localGateway": "Local Gateway",
            "destinationGateway": "Target Gateway",
            "targetGateway": "Target Gateway Address",
            "targetNetwork": "Target Network",
            "addTargetNetwork": "Add Target Network",
            "limit_1_255": "Not in the integer range of 1~255",
            "limit_0_255": "Not in the integer range of 0~255",
            "limit_1_29": "Not in the integer range of 1~29",
            "limit_lifetime_60_86400": "Please enter integer in the range of 60~86400",
            "limit_lifetime_68_1500": "Please enter integer in the range of 68~1500",
            "limit_lifetime_10_3600": "Please enter integer in the range of 10~3600",
            "targetRoute": "Target Route Identifier",
            "sharedPassword": "Prior Shared Password",
            "advancedOptions": "Advanced Options",
            "connectionMode": "Connection Mode",
            "maxTransUnit": "Maximum Transmission Element",
            "anomalyDetection": "Target Side Anomaly Detection",
            "DPDaction": "DPD Action",
            "heartInterval": "Heartbeat Interval(seconds)",
            "overtimeInterval": "Overtime Interval(seconds)",
            "baseConfig": "Base Config",
            "localSubnet": "Local Subnet",
            "targetNet": "Target Networks",
            "noLocalSubnet": "No available Local Subnet",
            "noLocalRouter": "No local router to add",
            "permitInteger": "Please enter integer",
            "overtimeBiggerInterval": "The overtime interval must bigger than heart interval",
            "minmtu": "Permit the integer bigger than 68 or equal to 68",
            "maxlengthPwd": "Please enter less than 255 Characters",
            "targetNetOverlap": "The target network address is overlapped, please reconfirm the address range",
            "bindFloatingIpVpnInfo": "The IPSec VPN function will conflict with the Binding Floating IP function. After setting up the site connection, please check whether the instance in the private subnet of both sides of the IPSec is bound to the Floating IP, if there is a request to dissolve.",
            "modifyLocalSubnetMsg": "After modifying the local subnet, please modify the target network of opposition end.",
            "modifyNetworkMsg": "After modifying the target network, please modify the local subnet of opposition end.",
            "selectOneVpn": "Please select an IPSec VPN",
            "oneLocalSubnte": "VPN has only one local subnet and cannot be deleted",
            "oneTargetNet": "The current IPSec VPN has only one target network and cannot be deleted",
            "delLocalSubnet": "Delete local subnet",
            "delTargetNet":"Delete target network",
            "selectOneLocalSubnet": "Please tick a local subnet",
            "atLeastCheckedOneLocalSub": "IPSec VPN contains at least one local subnet and cannot be deleted",
            "selectOneTargetNet": "Please tick a target network",
            "atLeastCheckedOneTargetNet": "IPSec VPN contains at least one target network and cannot be deleted all",
            "placeholder": {
                "name": "Please enter name",
                "enter": "Please enter",
                "select": "Please select",
                "description": "Please enter description",
                "localRouter": "Please select local router",
                "DPDaction": "Please select DPD action",
                "authenAlgorithm": "Please select Authentication Algorithm",
                "secretAlgorithm": "Please select Encryption Algorithm",
                "IKEversion": "Please select IKE version",
                "IKEperiod": "Please enter IKE period",
                "periodUnit": "Please select unit",
                "oneNegotiationModel": "Please select Negotiation Model",
                "perfectSecrecy": "Please select Perfect forward secrecy",
                "transProtocol": "Please select Transport Protocol",
                "EncapsulationMode": "Please select Encapsulation Mode",
                "IPSecProtocolCycle": "Please select IPSec Protocol Cycle",
                "selLocalSubnet": "Please select Local Subnet",
                "noLocalSubnet": "No available Local Subnet",
                "noLocalRouter": "No available Local Router",
                "localRouter":"Please select local router",
                "localNetwork": "Please select local network",
                "targetRoute": "Please enter target route",
                "sharedPassword": "Please enter shared password"
            },
            "status": {
                "active": "Active",
                "down": "Down",
                "build": "Build",
                "error": "Error",
                "pending_create": "Creating",
                "pending_update": "Update",
                "pending_delete": "Deleting",
                "server_active": "Active",
                "server_stop": "Stop"
            },
            "action": {
                "hold": "Hold",
                "clear": "Clear",
                "restart": "Restart",
                "disabled": "Disabled",
                "restart-by-peer": "Restart-by-peer",
                "bi-directional": "Bi-directional",
                "response-only": "Response-only"
            },
            "info": {
                "mainInfo": "IPSec VPN refers to a VPN technology using IPSec protocol to achieve remote access. IPSec is known as Internet Protocol Security, which provides end to end encryption and authentication services for public and private networks. IPSec VPN is used to connect two private cloud environments or to connect private and public cloud environments, connecting two different private network environments into a unified private network environment.",
                "name": "Vpn name",
                "description": "Describe",
                "authenAlgorithm": "认证Authentication algorithm算法",
                "encryptionAlgorithm": "Encryption Algorithm",
                "IKEversion": "IKE version book",
                "IKEperiod": "IKE cycle",
                "oneNegotiationModel": "Phase 1 Negotiation Mode",
                "perfectSecrecy": "perfect forward secrecy",
                "transProtocol": "Transfer Protocol",
                "EncapsulationMode": "Package mode",
                "IPSecProtocolCycle": "IPSec protocol cycle",
                "localRouter": "The gateway address of the local router.",
                "localSubnet": "Join the local subnet of the VPN.",
                "targetGateway": "The public IP address of the physical device at the opposite end of the tunnel. The target gateways of different IPSec VPNs cannot be the same.",
                "targetNetwork": "The destination network address reachable through the tunnel, all IP packets destined for this network will be sent through the IPSec VPN tunnel.",
                "targetRoute": "The default is the local gateway address.",
                "sharedPassword": "Use the pre-shared password to establish the IKE protocol. The value ranges from 1 to 255 bits.",
                "connectionMode": "Choose whether the VPN can only respond to connections or both respond and initiate connections.",
                "maxTransUnit": "Maximum transmission unit (MTU) value to handle fragmentation. The minimum value for IPv4 is 68, and the minimum value for IPv6 is 1280.",
                "DPDaction": "Default is hold.",
                "heartInterval": "Detection interval, in seconds. The default value is 30.",
                "overtimeInterval": "Detection timeout, in seconds. The default value is 120."
            }
        },
        "initSetting": {
            "netcheck": {
                "0": "Not connected",
                "1": "Connectivity success"
            },
            "settingStatus": {
                "setuping": "Setting",
                "unSetup": "Not set",
                "setupEnd": "Set up"
            },
            "settingTranslate": {
                "vpcCompelete": "The VPC network setup has been completed",
                "alarmCompelete": "Add alarm set has been completed",
                "vpcNetWorkCompelete": "Please complete the VPC network setup first",
                "backendCompelete": "Configuring the backend shared storage of the cloud host has been completed",
                "completeSetting": "Please complete the alarm setting first",
                "completeShare": "Migrated mirror library has been completed in shared storage",
                "configHost": "Please configure the back - end shared storage of the cloud host",
                "suggestSetting": "Recommended Settings",
                "vpcDesc": "After adding VPC network, users can directly use the network to create virtual machines and access the external network.",
                "alarmSetting": "Add Alarm Settings",
                "alarmDesc": "Cloud platform failure information will be sent to this contact list in real time",
                "storageSetting": "Configure cloud Host Back-end Shared Storage",
                "storageDesc": "After configuring Shared storage, the cloud host will support hot migration, hard disk failover, cloud hard disk mount, and other operations.",
                "transferSetting": "Migrate image libraries to Shared storage",
                "transferDesc": "This operation needs to first configure the Shared storage. After the image library is migrated to the Shared storage, the cloud platform image library can obtain more image library storage space and the image service will have high availability capability.",
                "saveSetting": "Save Settings",
                "nextStepTips": "Please save the current Settings before proceeding to the next step",
                "transferImage": "Migrate the Mirror Library",
                "compeleteTransfer": "Please complete the migration of the mirror library first",
                "alarmWay": "Alarm mode",
                "alarmContacts": "Alarm Contact",
                "weChat": "WeChat",
                "email": "Email",
                "emailServer": "Mail Server",
                "activeEmailOrWeChat": "Please complete the email or WeChat Settings and activate them first"
            },
            "transferImage": {
                "loadingTips": "In the mirror library migration, please wait patiently.",
                "storage": "Select storage Device",
                "selectStorage": "Please select A storage device",
                "transferTo": "Migrate the mirror library to",
                "startTransfer": "Start the migration",
                "transferTips1": "The creation of virtual machines will be affected during the migration,",
                "transferTips2": "Please confirm whether to start the migration now?",
                "transferTips3": "During the migration, mirror-related operations such as cloud host creation, image upload and image removal will be affected. Please confirm whether to start the migration now?",
                "transferTips4": "The mirror library backend cannot be changed again after being migrated from the File system. Do you want to use this storage device as the mirror library backend?",
                "transferInitTips": "Please wait while storage and mirroring services are restarted during migration initialization",
                "transferSuccess": "Migration successful",
                "transferFailed": "Migration failed"
            },
            "welcomeToUsePlatform": "Welcome to use cloud management platform",
            "finishedInitNetwork": "To better facilitate your use, please complete the following initialization network configuration:",
            "datacener": "datacener",
            "placeholder": {
                "selectDataCenter": "Please select datacener",
                "enterPort": "Please enter port",
                "selectStorageCopy": "Please select Storage Counterpart",
                "enterStorageUser": "Please enter StorageUser"
            },
            "addStorageWaitInfo": "Adding storage requires restarting the storage service. It is expected to last 5-10 minutes. Please be patient.",
            "loading": "Loading...",
            "configInsBackendSharedStorage": "Configuring the back - end shared storage of the instance",
            "buttStorage": "Butt Storage",
            "configureHost": "Configure Host",
            "connectIscsi": "Connect Iscsi",
            "Verification": "Verificating",
            "ISCSIServerIp": "ISCSI Server Ip",
            "ISCSIPort": "ISCSI Port",
            "configureAuthorizationInfo": "Configure Authorization Infomation",
            "ISCSIServerValidateComputeServer": "ISCSI server validates the cloud computing server（CHAP）",
            "ISCSIServerValidateInfo": "The user name and password below are used to authenticated account information when connecting the ISCSI server to the device. The account is provided by the ISCSI server, and only local connections are available.",
            "ComputeServerValidateISCSIServer": "The cloud computing server validates the ISCSI server（two-way CHAP）",
            "ComputeServerValidateInfo": "The following user name and password are used to authenticated account information when the ISCSI server connects to this device. The account information provided by this device can be connected to ISCSI only.",
            "storageCounterpart": "Storage Counterpart",
            "cephEditionCompatibility": "Your current CEPH client version is 10.2.7. Please confirm that the CEPH server version is compatible with this version.",
            "storageUser": "Storage User",
            "twoNodeNotModifyStorageNet": "Two node data center is not allowed to modify the storage network",
            "buttedIpStorageNotModifyStorageNet": "IP storage has been butted, and the modified storage network is not allowed",
            "downNetworkConfig": "Down network configuration",
            "resetIP": "Reset IP",
            "clusterNet": "Cluster Networks",
            "storageNet": "Storage Networks",
            "tenantNet": "Tenant Networks",
            "manageNet": "Manage Networks",
            "publicNet": "Public Networks",
            "noNetCard": "No Network Card",
            "and": "And",
            "conflict": "conflict",
            "range": "range is",
            "IPNotInCIDR": "The IP address is not within the CIDR range",
            "startIPBiggerThanEndIP": "The end of the IP address must be larger than the starting IP address",
            "disksConfig": "Disks Config",
            "netConfig": "Networks Config",
            "verifyStorageConnect": "Verifying storage connectivity",
            "hostName": "hostName",
            "datacener":"Data cener",
            "core": "CPU(core)",
            "memory": "memory(G)",
            "mountedFCStorage": "Mounted FC Storage",
            "findTarget": "Find Target（target）",
            "lodinConfirmISCSIDevice": "Login and confirm the ISCSI storage device",
            "findTargetStatus": "find Target Status",
            "targetIQNinfo": "Target IQN Infomation",
            "success": "success",
            "failure": "failure",
            "notFindTarget": "Undetected target",
            "selectISCSIdevice": "select ISCSI device",
            "close": "close",
            "waitCheckNetSpendOneMinute": "Please wait later, the storage network connectivity test is expected to take 1 minutes.",
            "checkNetFailed": "Storage Unicom detection task failed to execute!",
            "startCheck": "Start Check",
            "disks": {
                "noFCDisks": "Please make sure that the FC storage pool is not empty, and then operate it again!",
                "noDataDisks": "Please make sure that the data disk is not empty, and then operate it again!",
                "disksConfigNode": "Disks Config Node",
                "availableDisks": "Available Disks",
                "diskName": "Disk Name",
                "diskCapicty": "Disk Capicty",
                "diskType": "Disk Type",
                "journalDisk": "Journal Disk",
                "draftingArea": "Drafting Area",
                "dataDisk": "Data Disk",
                "FCstoragePool": "FC Storage Pool",
                "configurationProgress": "Configuration Progress"
            }
        },
        "security": {
            "whole": "The whole point",
            "period": "Period",
            "month": "Month",
            "clone": "Clone",
            "seeRule": "View the List of Rules",
            "securityGroup": "Safety Section",
            "securityGroupName": "Security Group Name",
            "template": "Template",
            "securityGroupType": "Security Group Type",
            "blackList": "Blacklist",
            "whiteList": "White List",
            "null": "Empty",
            "inputFirewallName": "Please enter Firewall Name",
            "description": "Description",
            "securityGroupDetail": "Safety Unit Details",
            "createRule": "Create Rule",
            "editRule": "Editing rules",
            "direction": "direction",
            "ethertype": "Input type",
            "_ipProtocol": "Type/Code value",
            "inputType": "inputType",
            "ip_protocol": "IPPortocol",
            "status": "State",
            "enabled": "Enable",
            "disabled": "Disabled",
            "portRange": "Port Range",
            "target": "target",
            "name": " Name",
            "selectRule": "Select Rule",
            "selectDirection": "Select direction",
            "openPort": "Open Port",
            "portWay": "Port Way",
            "port": "Port",
            "protocol": "IP protocol",
            "assignPort": "Assign Port",
            "fromPort": "Start Port",
            "endPort": "End Port",
            "type": "Type",
            "coding": "coding",
            "rule": "rule",
            "way": "way",
            "sourceType": "Source Type",
            "typeCodeValue": "Type/Code",
            "any": "Any",
            "createSecurityGroup": "New Security Group",
            "editSecurityGroup": "Edit Security Group",
            "delSecurityGroup": "Are you sure to delete the selected security group?",
            "securityGroupInUse": "The security group is bound to the cloud host and cannot be deleted",
            "ingress": "enter",
            "egress": "exit",
            "deleteSecurityGroupRule": "Are you sure to delete the selected security group rule?",
            "deleteTemplateRule": "Are you sure to delete the selected rule?",
            "quotas": "Quotas",
            "project_securityGroupQuotas": "Insufficient number of Project Safety Allocation",
            "domain_securityGroupQuotas": "Insufficient number of department safety allocation",
            "project_securityGroupRulesQuotas": "Insufficient Project Safety Group Rule Quota",
            "domain_securityGroupRulesQuotas": "Insufficient Number of Departmental Safety Group Rules quota",
            "wayIn": "Entrance direction",
            "wayOut": "Exit direction",
            "authorizationObject": "Authorized Object",
            "protocolType": "Protocol Type",
            "portRange": "Port Range",
            "authorizationStrategy": "Authorization Policy",
            "cloneSecurityGroup": "Clone Safety Unit",
            "dataCentre": "Target Data Center",
            "department": "Target department",
            "project": "Target project",
            "import": "Import",
            "export": "Export",
            "selectFile": "Select file",
            "fileCheck": "File format must be.xls or.xlsx",
            "importTips": "You need to download the template and execute it manually before uploading and importing it.",
            "templateFile": "Security Group Template File",
            "importsuccess": "File imported successfully",
            "importerror": "File import failed",
            "templateName": "Template Name",
            "createTemplate": "New Template",
            "editTemplate": "Edit Template",
            "deleteTemplate": "Are you sure to delete the selected security group template?",
            "templateDetail": "Template Details",
            "allowed": "Allow",
            "notAllowed": "Not allowed",
            "placeholder": {
                "type": "Please enter ICMP type range(-1~255)",
                "coding": "Please enter ICMP coding range(-1~255)",
                "selectTemplate": "Please select template",
                "dataCentre": "Please select the target data Center",
                "department": "Please select the target department",
                "project": "Please select the target project",
                "templateName": "Template Name"
            },
            "info": {
                "allowedRule": "Specifies the protocol type of packet that is allowed to pass through.",
                "forbiddenRule": "Specifies the protocol type of packet that is disabled.",
                "rule": "Specifies the protocol type of packet that is allowed to pass through.",
                "direction": "An entry indicates access to a cloud host from a device other than the cloud host, and an exit indicates access to a device other than the cloud host.",
                "portWay": "Ports and port ranges can be specified.If you specify a port, fill in the port number (range 1 to 65535) below;If you specify a port range, fill in the start and end ports.",
                "port": "Specifies the service port number of the cloud host to which access is allowed.",
                "cidr": "Specify the range of IP addresses allowed by this rule.",
                "allowedPort": "Specifies the service port number of the cloud host to which access is allowed.",
                "forbiddenPort": "Specifies the service port number of the cloud host that is inaccessible.",
                "allowedCidr": "Specify the range of IP addresses allowed by this rule.",
                "forbiddenCidr": "Specify the range of IP addresses prohibited by this rule."
            }
        },
        "keypair": {
            "keypair": "Keypairs",
            "keypaireName": "Keypairs Name",
            "fingerprint": "fingerprint",
            "import": "Import",
            "importKeypair": "Import Keypairs",
            "importPublicKey": "Please enter PublicKey",
            "createKeypair": "Create Keypairs",
            "inputKeypairName": "Please enter Keypairs Name",
            "download": "Download",
            "downloadPrivateKey": "Download PrivateKey",
            "publicKey": "PublicKey",
            "downloadTips": "Warning: The PrivateKey used for download once.",
            "deleteKeyPair": "Confirm Delete Keypairs"
        },
        "project": {
            "del": {
                "resource": {
                    "volumes": "Volumes",
                    "instances": "Instances",
                    "security_group": "Security Groups",
                    "router": "Routers",
                    "network": "Networks",
                    "floatingip": "Floating IP",
                    "snapshots": "Snapshots",
                    "images": "mirror"
                },
                "unit": " ",
                "error_info_prefix": " project cannot be deleted,Beause this project has some resources.such as:",
                "error_info_suffix": ". Before deleting this project,please delete those resources."
            },
            "quotafail": "To get a quota failure, please ",
            "retry": "retry",
            "parametersTips": "The parameters should not be less than the amount of project use",
            "domaindetail": "Department details",
            "projectdetail": "Project detail",
            "parametersDomainTips": "The parameters should not be less than the use of the Department",
            "parametersprojectLess": "The parameters should not be less than the maximum quota for the project",
            "parametersDomainLess": "The parameters should not be less than the maximum distribution of their subordinates",
            "parameterLargerThanAllProject": "The parameter shall not be less than the sum of the corresponding resource quota of the projects under the department",
            "title": "Project Management",
            "quota": "Project Quota",
            "quota_GB": "Project Quota (GB)",
            "newproject": "Create  Project",
            "editproject": "Edit Project",
            "delproject": "Confirm Delete Selected Project",
            "proName": "Please enter  Project Name",
            "table": {
                "pro_name": " Project Name",
                "pro_desc": "Description",
                "pro_id": " ProjectID",
                "pro_active": "Active",
                "createTime": "Creation time",
                "pro_region": "Region ID"
            },
            "newProject": {
                "dep_name": "Department Name",
                "pro_name": " Project Name",
                "pro_desc": "Description",
                "pro_use": "Useable"
            },
            "projectQuotaTip":"The following quota information is for reference only and can be modified according to the actual situation. Note: The project quota cannot exceed the department quota."
        },
        "depart": {
            "title": "Department Management",
            "quota": "Department Quota",
            "quota_GB": "Sector Quota (GB)",
            "new_depart": "Create Department ",
            "edit_depart": "Edit Department ",
            "del_depart": "Confirm delete delected department ",
            "instanceDetail": "Department Instance Detail",
            "quotaDetail": "Department Quota Detail",
            "allocateUser": "Allocate User",
            "noAllocate": "Unallocated User",
            "allocatedUser": "Allocated User",
            "table": {
                "dep_name": "Department Name",
                "dep_desc": "Description",
                "dep_region": "Region ID",
                "dep_use": "Useable",
                "createTime": "Creation time",
                "dep_not_edit": "Can't edit default department",
                "dep_not_del": "Can't delete default department",
                "pro_not_edit": "Can't edit admin project",
                "pro_not_del": "Can't delete admin project"
            },
            "newDepart": {
                "dep_name": "Department  Name",
                "pro_desc": "Description"
            },
            "editDefaultQuota":"Edit the default quota below"
        },
        "multiplenet": {
            "title": "Cloud Platform Physical Network Interoperability",
            "type": {
                "cluster": "Cluster network",
                "storage": "Storage network"
            },
            "configNetworkLoading": "The network is connecting.",
            "configNetworkSuccess": "Successful Network connection",
            "configNetworkFailed": "Network connection failure",
            "configNetworkCompleted": "Physical network connectivity of cloud platform has been completed.",
            "networkConnected": "Physical network connectivity of cloud Platform has been connected",
            "placeholder": {
                "selectNetwork": "Please select Network"
            }
        },
        "org": {
            "title": "Organization Overview",
            "new_org": "Create Organization",
            "table": {
                "dep_name": "Organization Name",
                "org_handle": "Operation",
                "org_desc": "Description"
            },
            "upOrg": {
                "direct_org": "Direct Organization",
                "direct_child_org": "Create Sub-Organization",
                "edit_org": "Edit Organization",
                "org_name": "Organization Name",
                "dep_desc": "Description ",
                "move_org": "Move Organization",
                "move_tip": "Confirm move Organization",
                "move_from": "Confirm move toOrganization",
                "move_to": "Under",
                "del": "Delete Organization",
                "del_org": "Confirm delete Organization",
                "not_del_org": "This Organization exist Sub-Organization, can't delete",
                "name_tip": "Please enter Department Name",
                "desc_tip": "Please enter Description "
            }
        },
        "role": {
            "title": "Access Control",
            "new_role": "Create Role",
            "edit_role": "Edit Role",
            "table": {
                "role_name": "Role Name",
                "role_desc": "Description",
                "role_basicName": "Basic role",
                "role_createTime": "Create time",
                "role_del": "Confirm Delete SelectedRole",
                "role_tip": "Please enter Role Name",
                "role_tip_desc": "Description",
                "role_domain": "Domain Name",
                "ok": "Ok",
                "privilege_not_edit": "Can't edit admin role",
                "privilege_not_del": "Can't delete admin role"
            },
            "role_name": "Role Name",
            "role_desc": "Role Description",
            "role_tip": "Please enter Role Name",
            "role_tip_desc": "Description",
            "new_user": "New User",
            "overview": {
                "user_top5": "User action log TOP5",
                "source_top5": "Resource operation log TOP5",
                "logAmount": "The log number",
                "today": "today",
                "yestoday": "yesterday"
            }
        },
        "node": {
            "role": {
                "storage": "Storage node",
                "message": "Message queue node",
                "compute": "Calculate node",
                "network": "Network node",
                "control": "Control node"
            },
            "region": "Region",
            "nodeMessage": "Node Detail",
            "hostNamePatternMsg": "Pretty Name consist of letters, numbers, or crosslines, ranging in length from 4 to 64 characters, and can only begin with letters or numbers.",
            "setHostPrettyName": "Setting Host Pretty Name",
            "hostPrettyName": "Host Pretty Name",
            "prettyName": "Pretty Name",
            "node_detail": {
                "public": "Floating ",
                "cluster": "Cluster Network",
                "storage": "Storage Network",
                "tenant": "Tenant Network"
            },
            "clusterNode": {
                "virtualNode": "Virtual resource node",
                "containerNode": "Container node",
                "accessStorage": "Under the general driver scenario, it is necessary to authorize the host access in storage and mount the corresponding storage volume in the host.",
                "addCluster": "Join the cluster",
                "nodePromote": "Node Promote",
                "twoNodePromote": "Two node promotion",
                "promotionRetry": "Promotion retry",
                "thirdNodePromotion": "Third node promotion",
                "enableHa": "Enabling multi node high availability architecture",
                "addClusterTip": "If the current node does not join the cluster, please join the cluster first, and then check the details of the node.",
                "physicalHostName": "Name of the physical host",
                "ipmiAddress": "IPMI address",
                "ipmiTips": "When verifying the IPMI information, make sure that the IPMI LAN service remains on",
                "virtualNumber": "Virtual machine number (run)",
                "virtualType": "Virtualization type",
                "usedPhysicalCPU": "Physical CPU has been used",
                "totalPhysicalCPU": "Total physical CPU",
                "usedMemory": "Already used memory (GB)",
                "totalMemory": "Total memory (GB)",
                "totalDisk": "Total disk capacity (GB)",
                "freeMemory": "Free memory (GB)",
                "usedDisk": "Used disk capacity (GB)",
                "currentTask": "Current task volume",
                "minAvailableDisk": "Minimum available disk (GB)",
                "freeDisk": "Free disk capacity (GB)",
                "detail": "details",
                "falutTips": "The effective number of each level in the fault domain is 1~99, and the hierarchical format of the fault domain is 1, 3-1, and 2-1-1. The fault domain level setting for each cluster node should be consistent, for example, the 2-1-1 three level fault domain format.",
                "nodeTips1": "The node can not be deleted, for the following reasons:",
                "nodeTips2": "1. The number of normal nodes in the current cluster is less than 4",
                "nodeTips3": "2. The state of the node is a process state",
                "nodeTips4": "1. Nodes with process state in the current cluster",
                "nodeTips5": "3. There is Unhealthy Node in the current cluster",
                "nodeTips6": "2. There is Unhealthy Node in the current cluster",
                "currentDelivery": "Current software delivery mode ",
                "buttedStorage": "No mirror image migration after butting storage, ",
                "mirror": "Node-1 is not deleted after storage.",
                "promotionNode": "Promotion of new nodes",
                "twoNodePTips": "In the two node promotion, the next step is not to be clicked",
                "nodePTips": "The nodes are joined in the cluster, and the next step is not to be clicked",
                "twoNodePSTips": "No clickable Finish button in node promotion",
                "twoNodesP": "Two points promotion",
                "compelete": "complete",
                "canNotDel": "can not be deleted"
            },
            "networkt": "Network",
            "netmask": "Netmask",
            "vlan": "VLAN Range",
            "status": {
                "1": "not configure",
                "2": "configuring",
                "3": "node config abnormal",
                "4": "node config success",
                "11": "start create node ",
                "12": "create node initiate",
                "13": "create node fail",
                "21": "start delete node ",
                "23": "delete node fail",
                "31": "start promote node ",
                "33": "promote node fail",
                "41": "start maintain node ",
                "42": "node maintaining",
                "43": "maintain node fail",
                "51": "start activate node ",
                "53": "acctive node fail",
                //新增状态
                "61": "Two-node promotion",
                "null": "null",
                "nodeMng.ing": "Start new node",
                "nodeMng.del.ing": "start delete node",
                "nodeMng.promote.ing": "start promote node",
                "nodeMng.manit.ing": "start maintain node",
                "nodeMng.active.ing": "start activate node",
                "nodeMng.add.ing": "start create node",
                "nodeMng.add.fail": "create node fail",
                "nodeMng.del.ing": "start delete node",
                "nodeMng.del.fail": "delete node fail",
                "nodeMng.promote.fail": "promote node fail",
                "nodeMng.manit.fail": "maintain node fail",
                "nodeMng.active.fail": "acctive node fail",
                "nodeMng.del.success": "delete node success",
                "nodeMng.promote.success": "promote node success",
                "nodeMng.manit.success": "maintain node success",
                "nodeMng.active.success": "acctive node success",
                "nodeMng.del.autoretry": "start delete node automatically",
                "nodeMng.add.success": "add node success",
                "nodeMng.manit.autoretry": "node manits autoretry"
            },
            "repair": "repair",
            "add": "add",
            "import": "Import Hardware",
            "importPretty": "Import Pretty Name",
            "importPrettyFile": "Importing host pretty name requires specific template files",
            "downloadPrettyFile": "Download",
            "prettyFile": "Pretty Name File",
            "selectPrettyFile": "Select File",
            "prettyFileModel": "The file format must be .xls or .xlsx",
            "activation": "activation",
            "retry": "retry",
            "delNodes": "Delete Nodes",
            "roles": "Role",
            "forceDelNodes": "Force Delete Nodes",
            "bcacheErrorTips": "The kernel version of node {{name}} does not support configuring disks. If you want to configure disks, please upgrade the kernel version to 4.x or above",
            "confirmMaintenance": "The instances of this node will shutdown when maintaining",
            "confirmDel": "1. All the resource will be deleted after the node deletion, please confirm migrate。",
            "confirmDel2": "2. Ensure the healthy state of distributed storage is 'health_ok'。",
            "confirmDel3": "3. Ensure that the distributed storage has sufficient residual space to recover the data of the deleted nodes。",
            "confirmDel4": "4. When there is more capacity in the storage pool, it may be that the removal of the nodes takes longer。",
            "confirmDel5": "If you have any questions, please contact the relevant technical personnel！",
            "confirmDel6": "Delete the node for high-risk operations, please enter the administrator account number and password",
            "confirmDel7": "Mandatory node deletion is a high-risk operation, please enter the administrator account and password",
            "confirmDel8": "The mandatory delete operation is for the operation operation under the scenario that the underlying data center device has been removed. It will only delete the node information in the cloud management database and is an irreversible operation. Please be careful!",
            "successShutDown": "All servers are being closed. Check the power status of the server to confirm the execution results!",
            "failedShutDown": "Cluster network exceptions, please check the interconnectedness of the cluster first, and then perform this function",
            "startShutDown": "Start shutting down",
            "useStatusValues": {
                "true": "true",
                "false": "false",
                "null": ""
            },
            "buttonTips": {
                "activeNode": "Conditions: The current node's state is in maintenance",
                "maintenance": "Conditions",
                "maintenance_1": "1. This node is in use, in a successful configuration and in a healthy state.",
                "maintenance_2": "2. Nodes that do not have process state in the current cluster",
                "maintenance_3": "3. Except for the remaining nodes of the checked node, in use, the number of nodes with successful and healthy configuration needs to be greater than or equal to 3.",
                "forceDelete": "Conditions: Currently in a multi-data center, the number of data centers is greater than 1, and the number of nodes in the current data center cluster is less than or equal to 3."
            },
            "healthStaus": {
                "status": "Health Status",
                "true": "Health ",
                "false": "N/A",
                "repair": "repair",
                "NULL": "N/A",
                "null": "N/A",
                "undefined": "N/A",
                "UNDEFINED": "N/A",
                "": "N/A"
            },
            "hypervisorHostname": "Host Name",
            "hostIPMI": "Host IPMI Information",
            "ipmiUser": "IPMI User",
            "ipmiPassword": "IPMI Password",
            "checkIPIM": "Check IPMI Information",
            "regionName": "region Name",
            "regionName2": "region Name",
            "management_IP": "Network IP",
            "lastTime": "Create time",
            "useStatus": "Use Status",
            "errorArea": "Fault zone",
            "Status": "Status",
            "useNodesComputeha": "Enabling multi node high availability architecture",
            "initializationConfiguration": "Initialization Configuration",
            "nodePromotion": "Node Promotion",
            "virtualResourceNode": "Virtual resource node",
            "kubernetsNode": "Container node",
            "sureTwoNodePromote": "In the process of node promotion, the high availability function of the two nodes will not be available, and the promotion process is irreversible.",
            "sureThirdNodePromote": "Confirm to promote the node?",
            "init": {
                "titleTips": "Prompt Information",
                "tipsInfo_1": "1. Platform-related services will be restarted when adding nodes, which will affect the creation, deletion and migration of virtual machines, mirrors or hard disks. Make sure there are no relevant operations at present.",
                "tipsInfo_2": "2. Ensure that the health status of distributed storage is HEALTH_OK",
                "tipsInfo_3": "3. Adding nodes will trigger CEPH balance, which may lead to long operation time.",
                "tipsInfo_4": "4. If general storage is docked, make sure that the disk of general storage is mounted on the new node.",
                "tipsInfo_5": "5. If the new node has not been initialized for more than 30 minutes, please check whether the node is faulty.",
                "initConfig": "Initialization Configuration",
                "cartConfig": "Cart Config",
                "setted": "Setted",
                "notSet": "Unsetted",
                "netCheck": "Network Check",
                "diskConfig": "Disk Config",
                "bindAndSaveCard": "Please ensure that all networks are bound to network cards and save network adapter configuration!",
                "cardSaveSuccess": "Network card configuration save success!",
                "in": "In the",
                "toCardConfig": "to card Config",
                "toDiskConfig": "to Disk Config",
                "bondCardHadUsed": "Nic has been used. If you want to do bond operation, please relieve it first!",
                "sameCardRate": "Bound network card rates must be consistent!",
                "cardHadUsed": "The network card has been used!",
                "bondMustHadTwoCard": "Bond contains at least 2 network cards to be used!",
                "cardNumber": "Card Number",
                "dragHereRecombine": "Drag the network card to this place to create a new combination",
                "clusterNet": "Cluster Network",
                "useDragToCombine": "Use drag and drop mobile combinations",
                "manageNet": "Manage Network",
                "storageNet": "Storage Network",
                "tanentNet": "TanentNet Network",
                "publicNet": "Public Network",
                "finishAndSaveCardConfig": "Please complete and save the network card configuration first",
                "laterNetCheckWasteThreeMinute": "Wait a minute, network detection is expected to take 3 minutes",
                "startCheck": "Start Check",
                "hostName": "Host Name",
                "cardConfigFail": "Card Config Fail！",
                "diskSaveSuccess": "Disk Save Success",
                "finishAndSaveCardAndDiskConfig": "Please complete and save the network card configuration and disk configuration first",
                "finishAndSaveCardConfig":"Please complete and save the network card configuration first",
                "finishAndSaveDiskConfig": "Please complete and save the disk configuration first",
                "confirmLocalVolumeSpaceFull": "Please ensure that the cloud host's local disk space is not empty！",
                "confirmSharedVolumeSpaceFull": "Please ensure that the universal shared storage pool is not empty！",
                "confirmCephVolumeSpaceFull": "Make sure that the Ceph disk management data disk is not empty！",
                "confirmInsAndCephVolumeSpaceFull": "Make sure that the Ceph disk management data disk and cloud host's local disk space is not empty",
                "availableDisks": "Available Disks",
                "diskName": "Disk Name",
                "status": "Status",
                "healthy": "Healthy",
                "unhealthy": "Unhealthy",
                "diskType": "Disk Type",
                "diskCapacity": "Disk Capacity",
                "insLocalVolSpace": "Instance local disk space",
                "cephDisk": "Ceph Disk Manage",
                "journalDisk": "Journal Disk",
                "cacheDisk": "Cache disk",
                "dataDisk": "Data Disk",
                "save": "Save",
                "nextStep": "Next Step",
                "finished": "Finished",
                "multiRole": {
                    "title1": "Prompt information and role selection",
                    "title2": "Tips",
                    "tips1": "Please select a role for the node",
                    "tips2": "You must assign an appropriate role to the newly added node",
                    "tips3": "Please ensure that the number of control nodes in the cluster is an odd number",
                    "tips4": "When adding a network node for the first time, the network service of the original control node needs to be deactivated. Please contact the operation and maintenance personnel for operation.",
                    "tips5": "Please select a role first",
                    "role1": "Control role",
                    "role2": "Network role",
                    "role3": "Storage role",
                    "role4": "message queue role",
                    "role5": "computing role"
                }
            }
        },
        "recycle": {
            "updatetime": "delete time",
            "name": "Resource Name",
            "eliminate": "Delete",
            "restore": "Restore",
            "forceDelete": "Force Delete",
            "thoroughlyDelete": "Thoroughly Delete",
            "deltip": "Instance will be safe in 2 hour, and then auto-delete",
            "deltcs": "Instance will be safe in 2 hour, and then auto-delete",
            "deltdb": "The cloud database of the recovery station saves time is {{time}} hour, the expiration will be automatically deleted",
            "table": {
                "status": {
                    "unrecognized": "Soft delete ",
                    "soft_deleted": "Soft delete ",
                    "soft-delete": "Soft delete ",
                    "restoring": "restoring",
                    "deleting": "deleting",
                    "active": "active",
                    "deleted": "delete ",
                    "error": "Error",
                    "soft-deleting": "Delete in progress"
                }
            },
            "diskName": "diskName",
            "config": "configuration",
            "day": "Day",
            "hour": "Hour"
        },
        "storage": {
            "device": {
                "caching": "Caching disk",
                "logDisk": "Log disk",
                "stName": "Storage Name",
                "createtime": "Create Time",
                "operation": "Operation",
                "topool": "To Pool",
                "pool": "Pool",
                "deviceDetail": "device Detail",
                "ava_st_feature": "available Storage Feature",
                "pool_capacity": "Pool Capacity",
                "available_capacity": "Available Capacity",
                "newTitle": "Add Storage",
                "storagefeatures": "Storage Features",
                "compression": "Compression",
                "rsize": "Rsize",
                "easytier": "EasyTier",
                "hyperswap": "Hyperwap",
                "stIp_ph": "Please enter the IP address",
                "userName_ph": "please enter username",
                "password_ph": "Please enter password",
                "storage_device_ph": "Please select the storage device",
                "storage_device_ph2": "Please enter the name of the storage device",
                "storage_pool_ph": "Please enter the name of the storage pool",
                "stIp": "Manage IP addresses",
                "userName": "Username",
                "password": "Password",
                "storage_device": "Storage device",
                "storage_pool": "Storage pool",
                "interface_ph": "Please enter the storage interface",
                "interface": "Memory interface",
                "Predefined": "Predefined Storage",
                "custom": "Custom Storage",
                "editTitle": "Edit store"

            },
            "features": {
                "ftname": "Feature Name",
                "description": "Description",
                "ftname_ph": "Please Enter Feature Name",
                "description_ph": "Please Enter Description ",
                "creattime": "Create Time",
                "newTitle": "New Feature",
                "editTitle": "Edit Feature"
            },
            "voltype": {
                "vtname": "Volume Type",
                "vtname_ph": "Please Enter Volume Type Name",
                "description": "Description",
                "creattime": "Create Time",
                "newTitle": "New Volume Type",
                "devicename": "Choose Storage",
                "choosestpool": "Choose Pool",
                "devicename_ph": "Please Choose Storage",
                "choosestpool_ph": "Please Choose Pool",
                "choosetype": "Please Choose Volume Type"
            },
            "pool": {
                "spname": "Storage Pool Name",
                "capacity": "Capacity",
                "free_capacity": "Free Capacity",
                "status": "State",
                "online": "Online",
                "offline": "Offline",
                "vdisk_count": "vdisk count",
                "state": {
                    "0": "Creating",
                    "1": "Created Fail",
                    "2": "Available",
                    "3": "No Available",
                    "4": "Deleting",
                    "5": "Deleted Fail",
                    "6": "Deleted Success",
                    "7": "All"
                }
            }
        },
        "cvmview": {
            "pro_ins": "Project Instance Detail",
            "pro_quota_info": "Project Quota Detail",
            "alarm_event": "Alarm Event",
            "view_all": "View More",
            "event_no": "No Alarm Event In This Project",
            "pro_res_info": "Project Resource Detail",
            "user_list": "User List",
            "ins_num": "Instance Number",
            "net_num": "Network Number",
            "router_num": "Router Number",
            "volume_num": "Volume Number",
            "used": "Used",
            "normal": "Normal：",
            "abnormal": "Abnormal：",
            "cpu_unit": "CPU Core",
            "ram_unit": "Ram(GB)",
            "ram_unitTb": "Memory (TB)",
            "snap_unit": "snapshots",
            "fip_unit": "Floating IP",
            "view_more_quotas": "View More Quotas",
            "quota_details": "Quota Details",
            "quota_name": "Quota Name",
            "total_quota": "Total Quota",
            "used_title": "Used"
        },
        "fileStorage": {
            "mount": "Linux mount command",
            "noCommand": "No order yet",
            "theMountCommand": "mount command",
            "moreIp": "Line feed isolation for multiple IP addresses",
            "inputPath": "Please enter the path",
            "CapacityQuotaplaceholder": "Please leave blank if there is no capacity limit",
            "totalCapacityQuota": "total capacity quota",
            "inputNumber": "Please enter a positive integer",
            "section1~10000": "The input value is not between 1~10000",
            "clientIP": "Client IP",
            "selectStoragePool": "Please select a storage pool",
            "pathfile": "Support a-z, A-Z, 0-9, _, -, . and other characters, spaces cannot be after / and at the end, at least enter a first-level directory, and do not end with / at the end"
        },
        "cloudDatabase": {
            "databaseName":"Name database",
            "databaseType":"Database type",
            "status":"Status",
            "imageName":"Images Name",
            "privateIp":"PrivateIP",
            "clusterIp":"ClusterIP",
            "publicIp":"PublicIP",
            "operateSystem":"OperatingSystem",
            "hostName":"Host Name",
            "hostBind":"High Availability",
            "createTime":"Create Time",
            "flavor":"flavor",
            "systemDisk":"systemDisk",
            "dataDisk":"dataDsik",
            "ipAddress":"IPAddress",
            "table": {
                 "status": {
                     "all":"all",
                     "active":"active",
                     "shutoff":"shutoff",
                     "error":"error",
                     "paused":"paused",
                     "suspended":"suspended"
                 }
             },
             "tipMsg":{ 
                "start":"Do you sure you want to launch a cloud database?",
                "stop":"Do you sure you want to turn off your cloud database?",
                "reboot":"Do you sure you want to restart the cloud database?",
                "reset":"This operation is limited to operation and maintenance operation, please confirm the cloud database status before reset",
                "forcedelSever":"Do you sure you want to clear the cloud database?",
                "restoreSever":"Do you determine if you want to restore the cloud database?",
                "forceDel":"Do you sure you want to force the cloud database?"
            },
            "createDatabase":{
                "create":"New database",
                "sureCreate":"Confirm new",
                "basicInfo":"Basic Information",
                "networkSet":"Network settings",
                "storeSet":"Storage settings",
                "type":"Database type",
                "version":"Database version",
                "name":"Name database",
                "operateSystem":"operating system",
                "image":"Mirror",
                "selectSpecific":"Select specification",
                "cpu":"cpu",
                "ram":"RAM",
                "flavorTable":{
                    "sockets":"Number of slots",
                    "cores":"Core number",
                    "threads":"Threads"
                },
                "placeholder":{
                    "databaseNameInput":"Please enter the name of the database"
                },
            }
        },
        "errors": {
            "selectEthernetType": "Please select an ethernet type",
            "lableMax10": "A cloud host can bind up to 10 tags",
            "isDelete": "Do you want to delete the selected content?",
            "IPillegal": "IP is invalid",
            "IPrepetition": "IP repeat input",
            "maxLength": "Description information up to 255 characters",
            "apiSecretKey255": "Key length exceeds 255",
            "apiPrivateKey255": "Private key length exceeds 255",
            "portsection65535": "The port number is not between 1-65535",
            "maxLength1024": "Maximum length does not exceed 1024",
            "pathError": "Incorrect path format",
            "portUse": "The port is already occupied",
            "input1_10": "Please enter a positive integer from 1-10",
            "http": "Cannot contain http:// and https://",
            "specificsymbol": "Only supports English letters, numbers or Chinese characters, +, -, =, ., _, :, /, @, (), [], (), []",
            "quota_domain": "Parameter should be greater than the project quota",
            "quota_domain2": "Arguments should be less than or equal to 6 digits",
            "quota_project": "Parameters should be smaller than departmental quotas",
            "required": "*Required",
            "nameExist": "Name already exists",
            "httpCheck": "Please start with /",
            "urlCheck": "Please enter the correct URL address",
            "integer": "Please enter positive integer",
            "atleast": "Please enter positive integer greater than or equal to 1",
            "atleast_0": "Please enter an integer greater than or equal to 0",
            "atleast_-1": "Please enter an integer greater than or equal to -1",
            "maxinteger": "Please enter positive integer no more than 10",
            "integerAndZero": "Please enter 0 or positive integer",
            "noRepeatDepName": "This Name is duplication, please try again",
            "correctUrl": "Please enter the correct URL.",
            "rightRange": "The correct value range is:",
            "limitM": "Please enter at most 64 bits",
            "mailboxLayout": "Please enter the correct email format, multiple emails are separated by English semicolons",
            "mailboxMax10": "Mailbox supports up to 10",
            "maxlength200": "Text box up to 200 characters",
            "maxlength": "Please enter less than 32 Characters",
            "maxlength30": "Please enter no more than 30 digits",
            "maxlength20": "Please enter less than 20 Characters",
            "maxlength16": "Please enter less than 16 Characters",
            "maxlength255": "Please enter less than 255 Characters",
            "maxlength1024": "Please enter less than 1024 Characters",
            "maxlength256": "Please enter less than 256 Characters",
            "maxlength128": "Please enter up to 128 bits",
            "maxlength100": "Please enter less than 100 Characters",
            "maxlength127": "Please enter at most 127 digits",
            "maxLength_64": "Maximum length 64 bits",
            "minlength": "Please enter more than 8 Characters",
            "maxlengthMid": "Please enter less than 100 Characters",
            "sizelimit": "Please enter number between 1~100000, decimal point retention 2 Characters",
            "sizelimitZero": "Please enter number between 0~100000, decimal point retention 2 Characters",
            "maxlengthbig": "Please enter less than",
            "minlengthsmall": "Please enter more than",
            "lenghtUnit": " Characters",
            "noSpecial": "Can only enter letters, numbers, and the underscore Chinese characters, line",
            "noZhSpecial": "Can only enter letters, numbers, and the underscore line",
            "nameLimit": "Can only enter letters, numbers, Chinese characters, lines, and point",
            "imgcheck": "The name is 255 bytes maximum",
            "toInput": "Please enter ",
            "toNum": "Positive integer",
            "cpuMem": "You can't just select CPU or you can only input memory.",
            "limit_1000": "Please enter less than or equal to 1000",
            "pswdNotEqual": "The two input password is different",
            "phone": "Please enter valid phone number",
            "email": "Please enter valid email",
            "description28": "Description no more than 28 characters",
            "limit_volume": "Please enter ess than or equal to 1000 positive integer",
            "description": "Description no more than 80 characters",
            "menuRequire": "Please select at least one menu",
            "limit_0_100": "Please enter an integer between 0 and 100",
            "limit_1_10$": "Please enter an integer from 1 to 10",
            "limit_1_100": "Please enter integer between 1~100",
            "limit_10_1000": "Please enter an integer of 10-1000",
            "limit_10_10000": "Please enter an integer between 10 and 10,000.",
            "limit_1_10000": "Please enter integer between 1~10000",
            "limit_1_9999": "Please enter integer between 1~9999",
            "limit_1_99": "Please enter an integer from 1 to 99",
            "limit_0_9": "Please enter a positive integer between 0 and 9 bits",
            "limit_1_9": "Please enter 1~9 character positive integer",
            "limit_1_1": "Please enter  character positive integer less than 2",
            "limit_1_10": "Please enter  character positive integer less than 11",
            "limit_200_204": "Please enter integers from 200 to 204",
            "limit_65_95": "Please enter an integer between 65 and 95.",
            "limit_betys": "Please enter a  integer for 0~102400",
            "emailPatternError": "Email format wrong",
            "phonePatternError": "Phone number format wrong",
            "limit_1_65535": "Please enter 1nteger between 1 to 65535",
            "start-biger-end": "The port termination value must be greater than the starting value",
            "limit_1_65522": "Please enter integer between 1 to 65522",
            "limit_-1_255": "Please enter integer between -1 to 255",
            "limit_-100_100": "Please enter integer between -100 to 100",
            "limit_-10_10": "Please enter integer between -10~10",
            "maxlength_255": "Please enter less than 255 characters",
            "maxlength_200": "Please enter up to 200 characters",
            "noChinese": "You can only enter numbers, letters, or underscores, and start with letters only",
            "noInputCN": "Cannot input Chinese characters",
            "input_0_f": "Enter 0~ffff",
            "ip_inputError": "IP Address is invalid",
            "limit_1_255": "Please enter integer between 1~255",
            "limit_0_255": "Please enter integer between 0~255",
            "limit_1_29": "Please enterinteger between 1~29",
            "limit_8_29": "Please enterinteger between 8~29",
            "limit_8_32": "Please enterinteger between 8~32",
            "limit_1_30": "Please enterinteger between 1~30",
            "limit_1_32": "Please enterinteger between 1~32",
            "limit_65535": "Please enter the integer of 1-65535.",
            "limit_morethan_1": "Please enterinteger between 1~largest qouta of CPU",
            "limit_1_131072": "Please enter integer between 1~131072",
            "limit_ram_morethan_1": "Please enter integer between 1~largest qouta of RAM ",
            "limit_1_500": "Please enter integer between1~500",
            "limit_1_720": "Please enter an integer between 1 and 720.",
            "snap_1_31": "Please enter integer between1~31",
            "snap_0_59": "Please enter integer between1~59",
            "snap_1_99": "Please enter integer between1~99",
            "snap_0_168": "Please enter integer between1~168",
            "ram_min_max": "Ram between {{min}}~{{max}}(GB)",
            "ram_min": "Ram between {{min}}~{{max}}(GB)",
            "vcpus_min_max": "CPU Core number between {{min}}~{{max}}(Core)",
            "vcpus_min": "CPU Core number between {{min}}~{{max}}(Core)",
            "ipAddrPatternError": "Please enter correct ip Address format, eg:10.10.1.0",
            "ipAddrPatternError1_254": "Please enter correct ip Address format, eg:10.10.1.1 and the last group in the range of 1-254",
            "gtIpError": "endIP Address must be great than IP Address",
            "mac_addr": "Please enter correct mac Address",
            "gtTime": "end time must laater than start time",
            "timePattern": "Please enter correct time format, eg:yyyy-mm-dd",
            "timeSecondPattern": "Please enter correct time format, eg:yyyy-mm-dd hh:mm",
            "repeatDefault": "Firewall default is duplication",
            "existedVlanId": "VLAN ID is duplication, please try again",
            "passmaxlength": "Please enter less than 30 characters",
            "emailmaxlength": "Please enter less than 50 characters",
            "input_gtorequit": "Please enter greater than or equal to",
            "less_65535_integer": "Integers less than 65535.",
            "noRepeatProName": "This Name is duplication, please try again",
            "repeatContractGroupName": "This Name is duplication, please try again",
            "integer_negative_one": "Please enter integer more than -1",
            "zerototen": "Please enter integer between 1~10",
            "ram_min_max_beyond": "the selected configuration must be higher than the previous configuration",
            "evenGt_1": "Please enter 1 or an even greater then 1",
            "integerGt_1": "Please enetr number greater then 0 and allowed with a decimal",
            "extra_hw_tip": "When selecting hw:cpu_cores 、hw:cpu_threads and hw:cpu_sockets，the product of those values must equal or greater than 1, or less than or equal to CPU cores of instance",
            "cidrPatternError": "CIDR pattern error,please try again",
            "emailServer": "Please enter the correct email server",
            "hostnameValidate": "You can only enter numbers, letters, or strikethrough, and start with letters only",
            "vmNamevalidate": "Can only enter letters, numbers, and the Chinese characters, line",
            "snapNamevalidate": "Can only enter letters, numbers, and the Chinese characters, line,point",
            "securityGroupName_check": "The security group name already exists",
            "balanceName_check": "*The balance name already exists",
            "storageName_check": "Storage name already exists",
            "limit_1_4000": "Please enter integer between 1~4000",
            "ramGtMax": "The ram value should be less than or equal to the max ram value",
            "addressValidate": "The address cannot be ended with  / ",
            "ltCurrentTimeError": "The first execution time should be greater than or equal to the current time",
            "source_subnet_address": "Please enter the subnet address, for example: 192.168.0.1/24",
            "source_ip_address": "Please enter the source IP address, for example: 192.168.0.1",
            "direction_iP_address": "Please enter destination IP address, for example: 192.168.0.1",
            "limit_1_max": "Enter 1~{{maxSize}} integer",
            "valLetterNum": "Names can only be lowercase letters, Numbers and underscores.",
            "ipscope": "incorrect format",
            "limit_min_max": "Please enter {{min}}~{{Max}} integer",
            "limit_special_character": "Special characters are allowed only'-'', and other characters are unrestricted",
            "limit_least_2": "Please enter at least two characters",
            "maxlengthzf128": "Please enter at moast 128 characters",
            "role_require": "role name require",
            "port": "Please enter a positive integer of 0-65535",
            "ip": "IP format incorrect, example :192.168.1.2",
            "limit_1_100":"Please enter a positive integer from 1-100",
            "limit_1_1000": "Please enter a positive integer from 1-1000",
            "fileSizeless_10g":"File size cannot exceed 10g",
            "lim_lowercase_number":"Can only enter lowercase letters, numbers, and horizontal"
        },
        "metaMsg": {
            "hint": "Note: localdir in the mount command is the name of the path to be mounted locally",
            "noFixedIP": "operate failed：No fixed IP addresses available for the network",
            "noHostAvail": "operate failed：There are not enough hosts available.",
            "Bindingportfail": "operate failed: Binding failed for port",
            "noharddisk": "operate failed: No valid host was found. No weighed hosts available",
            "buildInstancefail": "operate failed: Build volume fail",
            "buildInstancefail_2": "operate failed: The system disk is smaller than the requested virtual machine mirror size",
            "InsufficientMemory": "operate failed: Insufficient memory  resources",
            "InsufficientVcpu": "operate failed: Insufficient vcpu  resources",
            "internalError": "operate failed: internal error",
            "volumeError_1": "operate failed: no target device ",
            "volumeError_2": "operate failed: Unable to detach from guest transient domain",
            "volumeError_3": "Please check whether the virtual system state is abnormal",
            "hasQuickError": "There is a snapshot on the system disk that cannot be restored",
            "backupError1": "Failed to create backup, current volume state not as expected",
            "backupError2": "Failed to create backup, current backup state is not as expected",
            "backupError3": "Backup creation failed, the hash value of the old block device does not match the hash value of the current block device, the block size hash value changed during the last backup, please take full backup.",
            "backupError4": "Backup creation failed and the size of the source volume changed compared to the last backup.Please make a full backup.",
            "backupError5": "Backup creation failed, backup volume metadata failed",
            "domainInvalid": "Host not running"
        },
        "bandlimit": {
            "limitType": "Limit Type",
            "customBand": "Custom Bandwidth",
            "SpeedLimited": "Speed Limited",
            "fixBand": "Fixed Bandwidth",
            "IP_bandwidth": "IP Bandwidth",
            "Switchboard": "Instance Network Card Max Bandwidth",
            "Router_bandwidth": "Router Bandwidth",
            "tips": "Before you turn off the speed limit mode, change the instance bandwidth value in the created list to unlimited.",
            "tipsBtn": "No effect on established bandwidth.",
            "paramDesc": "Bandwidth limitation"
        },
        "statusCode": {
            "00500":"Copy successfully",
            "01300401":"The connection of the csp service failed, the service already exists",
            "01300501":"csp service deletion failed, the service is in use",
            "01300101":"csp access is forbidden, the key has no operation permission",
            "01300201":"csp access is forbidden, the user or path already exists",
            "01170504":"The load balancing resource is in use and cannot be switched",
            "02072001":"The enhanced load balancer fails to be deleted because listeners exist in the balance. Procedure",
            "02072002":"Failed to delete load balancing. Procedure",
            "02072003":"Failed to create load balancing. Procedure",
            "01280101":"Email sending failed",
            "02130901500": "Certificate not available",
            "15040101":"Failed to upload certificate",
            "15040102":"Failed to upload container certificate",
            "15040103":"Failed to delete container certificate",
            "15040104":"Failed to delete certificate",
            "15040105":"certificate in use",
            "15040106":"Failed to update certificate",
            "15040107":"Duplicate certificate name",
            "login0": "login successful",
            "login1": "Login failed",
            "0": "Successful operation",
            "1": "operation failed",
            "-1": "Server exception",
            "500": "Server Error",
            "404": "wrong request address",
            "010": "Verifying the IPMI information is successful",
            "011": "Failed to verify IPMI information",
            "02340203":"Username already exists",
            "02330111":"File storage path already exists",
            "02320101":"The bridge is being created, please do not repeat the operation",
            "01230219": "Ceph object storage exists, deletion of ceph block storage is not allowed",
            "11010101": "Qcloud无Can't log in to Qcloud法登录",
            "01100218": "The current cluster state is not operational",
            "01300401": "Service connection failed, the service already exists",
            "01100223": "Duplicate data center name",
            "01290103": "The ceph object storage has been connected and cannot be connected repeatedly",
            "01290104": "Ceph block storage enables cache acceleration, and ceph object storage cannot share block storage",
            "01290105": "Object storage exists in a bucket and cannot be deleted. Please clear all buckets first",
            "01230212":"The storage has migrated and the image cannot be deleted",
            "02054101":"Failed to quickly create security group",
            "02054102":"Failed to quickly create security group",
            "00010101": "Failed to get resource authentication, please try again",
            "00010107": "Failed to get resource authentication, please try again",
            "00010105": "Login expired, please login again",
            "00010102": "request without token",
            "01100119": "Authorization failed, retry after updating authorization",
            "01030706": "Login failed (the number of error logins exceeds the limit)",
            "01030707": "Login failed (account has been logged in)",
            "01030710": "The platform is upgraded in two nodes, please log in again later",
            "02110301": "Query custom mirror list failed",
            "01080306": "The current input password is consistent with the original password",
            "01080304": "Modify the user password failed, reset the Keystone password failed",
            "01080303": "Original password input error",
            "01080302": "Password modification failed",
            "01180401": "Change password is too frequent",
            "9001008":  "Do not repeat",
            "01300601": "CSP service access failed, the API access key does not exist or not enabled",
            "02340101": "RGW service is not installed",
            "02340201": "User creation failed",
            "02340301": "Get a user list failed",
            "02340401": "Update quota failed",
            "02340501": "Get user failure",
            "02340601": "Delete user failed",
            "02340602": "Default account is not allowed to delete",
            "02340701": "Create a secret key failed",
            "02340801": "Delete the secret failed",
            "01270101": "Adjusting the proportion of storage pool failed",
            "01270102": "Storage pool ratio sum is not 1",
            "01100119": "Authorization does not pass, update authorization and try again",
            "01270103": "RGW storage pool",
            "01270104": "Storage pool without RGW",
            "01270105": "Storage pool ratio sum is not 1",
            "01270201": "Get storage pool ratios failed",
            "01260501": "Modify the object storage configuration failed",
            "01260701": "Object storage SWIFT configuration error",
            "01260601": "Delete object storage configuration failed",
            "01260101": "Save object storage configuration failed",
            "01260201": "Get the object storage configuration list failed",
            "01020102": "Department name repetition",
            "01260301": "Service installation failed",
            "01260401": "Unlink Ceph",
            "01250101": "Scope conflict",
            "01100108": "No node",
            "02040802": "QoS binding volume type failed",
            "01100110": "Abnormal nodes in the current cluster",
            "01100214": "no log server ip",
            "01100215": "no kapacitor ip",
            "01100202": "Cluster configuration information does not exist",
            "02251801": "Edit physical machine description failed",
            "01150201": "There are other nodes in the cluster being operation, please wait for the operation to complete",
            "01150202": "Update node status failed",
            "01150302": "Cluster distributed control plane is unhealthy",
            "01150301": "New node failed",
            "01150401": "Node delete failed",
            "01150402": "The number of remaining nodes remaining in the cluster",
            "01150403": "Node also has unselected virtual machines",
            "01150405": "Get cluster CEPH information failed",
            "01150406": "Not all PG is in Active + Clean Status",
            "01150407": "Deleting nodes will result in insufficient amounts of CEPH",
            "01150408": "The delete node will result in the number of remaining online nodes below the maximum number of copies of the Ceph pool",
            "01150410": "username or password is wrong",
            "01150501": "Node maintenance failed",
            "01150601": "The selected node is not maintained, no activation",
            "01150602": "Node activation failed",
            "01150701": "The selected node cannot retry the previous operation",
            "01150702": "Retry node operation failed",
            "01150801": "Get node health status failed",
            "01100401": "Node delete failed",
            "02010101": "Get list failed",
            "2010101": "Network detection parameter error",
            "2010102": "Network detection failed",
            "02010201": "Get cloud host details failure",
            "02010301": "Creation failure",
            "02010302": "The selected cloud hard disk size is insufficient",
            "02010401": "Shutdown failure",
            "02010402": "The cloud host status is incorrect",
            "02010501": "Forced shutdown failed",
            "02010601": "Start the cloud host failed",
            "02010701": "Re-opening the cloud host failed",
            "02010801": "Modify the cloud host failed",
            "02010901": "Delete cloud host failed",
            "02011001": "Change failure",
            "0125010": "Failure to send configuration",
            "02011101": "Production failure",
            "02011201": "Load the firewall failure",
            "02011301": "Remove firewall failure",
            "02011401": "Creation failure",
            "02011501": "Rollback failed",
            "02011601": "Show failed",
            "02011701": "Add a network card failed",
            "02011801": "Remove NIC failed",
            "02011901": "Heat migration failed",
            "02012001": "Get cloud host type failed",
            "02012101": "Get a key to the list failed",
            "02012201": "Acquisition failure",
            "02012301": "Get all network cards of the cloud host failed",
            "02012401": "Create a key pair failed",
            "02012402": "Key pair already",
            "02012501": "Acquisition failure",
            "02012601": "Get Flavor Special Setup List Failure",
            "02012701": "Create a Flavor Special Settings list failed",
            "02012801": "Delete Flavor Special Setup List Failed",
            "02012901": "Get Flavor Access List Failed",
            "02013001": "Add Project to Flavor failed",
            "02013101": "Remove Project from Flavor failed",
            "02013201": "Create a cloud host type failed",
            "02013301": "Delete cloud host type failed",
            "02013401": "Delete key pair failed",
            "02013501": "Query failed",
            "02013901": "Heat migration failed",
            "02014001": "Backup failed",
            "02014401": "Adjust the cloud host size failed",
            "02014501": "Confirm that the adjustment cloud host size failed",
            "02014601": "Cancel the adjustment cloud host size failed",
            "02014701": "Pause the cloud host failed",
            "02014801": "Hanging cloud host failed",
            "02014901": "Pause restoration cloud host failed",
            "02015001": "Hanging up the recovery cloud host failed",
            "02015101": "Get cloud host starting log failed",
            "02015201": "Modify the MAC address failed",
            "02015202": "Modify the cloud host MAC address failed,mac地址冲突",
            "02015301": "Restore the cloud host failed",
            "02015401": "Forced delete cloud host failed",
            "02015501": "Recycle list failed",
            "02015601": "Evacuation of clouds fail",
            "02015701": "Get the configuration failed to get the current cloud host",
            "02016001": "Forced deletion of recycle bin Zhongyun host failed",
            "02200400": "Create an elastic telescopic cluster",
            "02020101": "Get list failed",
            "02020201": "Creation failure",
            "02020301": "Modify mirror failure",
            "02020401": "Mirror deletion failed",
            "02020402": "Mirroring cannot be deleted",
            "02020501": "Get mirror details failed",
            "02020601": "Mirror file cannot be empty",
            "02020602": "Mirror file upload failed",
            "02030101": "Get list failed",
            "04030104": "Editable single failed",
            "04030105": "Re-opening the work list failed",
            "02030201": "Operating cloud hard drive does not exist",
            "02030202": "Get Cloud Hard Drive Details Failure",
            "02030301": "Delete cloud hard disk failed",
            "01150404": "Calculation service failed to disable the node",
            "02030302": "Cloud hard disk has a snapshot unable to delete",
            "02030401": "Create a cloud hard disk failed",
            "02030501": "Update the cloud hard disk failed",
            "02030601": "Forced delete cloud hard disk failed",
            "02030701": "Cloud hard disk expansion failed",
            "02030702": "The capacity set when the cloud hard disk expansion must be greater than the original capacity",
            "02030703": "There is no sufficient resource pool capacity to expand",
            "02030801": "Equipment path or cloud hard drive",
            "02030802": "Cloud host is invalid",
            "02030803": "Load the cloud hard disk failed",
            "02030901": "Root equipment is on the hard disk, disable uninstallation",
            "02030902": "Uninstalling hard disk failure",
            "02031201": "Cloud hard disk to mirror failure",
            "02031301": "Get a list of cloud hard drive types failed",
            "02031401": "Reset the cloud hard disk status failed",
            "02040101": "Get a quick list failed",
            "02052603": "Delete port failed",
            "01150409": "There are also virtual machines that are not emptied in the recycle bin in the node.",
            "03018001": "Get node list failed",
            "01150303": "The cluster IP has been exhausted, please contact the administrator",
            "02040201": "Delete snapshot failed",
            "02040301": "The snapshot of the operation does not exist",
            "02040302": "View snapshot details failed",
            "02040401": "Create snapshot failed",
            "02040501": "Create a cloud hard disk from snapshots",
            "02040601": "Get a list of storage pool details failed",
            "02040602": "Cloud hard disk snapshot failed",
            "02040603": "Cloud hard disk snapshot update failed",
            "02050101": "Get list failed",
            "02050102": "Get external network list failed",
            "02050103": "Get a physical network list failed",
            "02050201": "Conflict",
            "02050202": "Other reasons creation failed",
            "02050204": "Create a network failed. In physical network: Tenant, the VLAN ID is used.",
            "02050301": "Edit network failed",
            "02050302": "Modifying the network sharing settings failed, there are other tenants who are using the network.",
            "02050401": "The network is being used and cannot be deleted",
            "02050501": "Get a list of network details failed",
            "02050601": "Get list failed",
            "02050602": "Get a list of network subnet failures",
            "02050701": "Create a subnet failed",
            "02050702": "Create a subnet, the address pool format is incorrect.",
            "02050703": "Creating a subnet failed, the IP in the gateway IP and allocated address pool has conflicted",
            "02050704": "Create a subnet failed, created subnets have overlap with subnet network segments that have been bound to the selected network",
            "02050705": "Unable to complete this operation, the host route exceeds the limit",
            "02050706": "Unable to complete this operation, the number of DNS domain servers exceeds restrictions",
            "02050707": "IP pool is invalid",
            "02050708": "The IP pool of the subnet has overlapping",
            "02050709": "The IP pool of the subnet exceeds the CIDR of the subnet",
            "02050710": "Gateway IP and IP allocation pool conflict",
            "02050711": "The gateway IP has been used by port and cannot be created.",
            "02050712": "CIDR is invalid",
            "02050713": "Subnets belonging to the same network must be allocated from the same subnet pool",
            "02050714": "The information submitted by creating the subnet failed, delete the subnet",
            "02050715": "Unable to allocate subnets",
            "02050716": "Illegal subnet association",
            "02050717": "Illegal subnet association",
            "02050718": "Not specified minimal network subsystem prefix",
            "02050719": "Unable to complete the operation of the subnet, one or more ports on the subnet are being used",
            "02050720": "The loopback IP type subnet does not support opening DHCP",
            "02050721": "Multicast IP type subnet does not support open DHCP",
            "02050722": "The prefix length of the subnet is not allowed to open DHCP",
            "02050723": "The gateway is invalid on the subnet",
            "02050724": "DNS address resolution failed",
            "02019502": "Please empty the system disk first",
            "02050725": "VLAN ID is occupied, create a network failure",
            "02050801": "Edit subnet failed",
            "02050901": "Delete subnet failed",
            "02051001": "Get list failed",
            "02051101": "Conflict",
            "02051102": "Other reasons creation failed",
            "02051103": "External network has created router",
            "02051201": "Get router details failed",
            "02051301": "Unlink failed",
            "02051302": "The router unfained the associated subnet failed, the subnet is being used by one or more public network IP",
            "02051401": "Related subnet failed",
            "02051402": "The router's associated subnet failed, and the subnet section has overlap. (Within the different networks of the same tenant, two subnets with IP addresses overlap can not use the same router)",
            "02051403": "The router subnet address is not allowed to overlap with the external gateway address",
            "02051501": "Delete routing failed",
            "02051502": "Delete failed, there is a port on the router",
            "02052202": "No state automatic configuration subnet can't modify or specify IP",
            "02059016": "Some firewall rules are successful",
            "02059017": "Some firewall rules are disabled",
            "02059401": "Get topological map information failed",
            "02059404": "Add cloud host available external physical network failure",
            "02059407": "Physical network does not exist",
            "02059601": "Router gateway delete failed",
            "02059602": "Public network IP on the gateway is in use", //路由器上的云主机绑定有网关上的公网IP
            "02059603": "Router exists port forwarding rules, can't delete",
            "02059701": "Router settings gateway failed",
            "02051601": "Get a public network IP list failed",
            "02051701": "Fixed IP is not in the subnet address pool",
            "02051702": "Other reasons failed",
            "02051703": "IP has been occupied",
            "02051704": "Network available IP address is insufficient",
            "02051705": "Modify the cloud host password failed",
            "02051801": "Subnet does not bind routing or route no binding network",
            "02051802": "Subnet does not create routing",
            "02090101": "Get the failure of virtual machine manager information",
            "02090201": "Get manager data failed",
            "02053201": "Create a security group rule failed",
            "02051901": "Decomposition",
            "02052001": "Get the list of available host list failed",
            "02052101": "Delete public IP failed",
            "02052201": "Modifying port name failed",
            "02052212": "The operation failed, the IP address is in use",
            "02052301": "Get the total bandwidth list failed ",
            "02052401": "Get port details failed",
            "02052601": "New virtual network card failed",
            "02052701": "Get safety group failed",
            "01150304": "The system mirror version of the selected node is inconsistent with the cluster.",
            "02052801": "There is no external network when creating a router.",
            "02052901": "Create a security group failed",
            "02053001": "Update security group failed",
            "02053101": "Delete security group failed",
            "02053102": "Safety group is in use, can not be deleted",
            "02053301": "Get the failure of security group rules",
            "02053201": "Create a security group rule failed",
            "02053401": "Delete security group rules failed",
            "02053501": "Loading the security group failed",
            "02020801": "Get a mirror upload bundle space usage",
            "02020901": "Mirror is uploading, emptying the cache file failed",
            "02021001": "Users quit upload interrupt",
            "02060001": "Making mirror",
            "02060002": "Making mirror success",
            "02060003": "Backup",
            "02060004": "Successful backup",
            "02060005": "Cloud host is mandatory",
            "02060006": "Cloud host is forced to shut down success",
            "02100201": "Creating a host collection failed",
            "02100301": "Delete host set failed",
            "02100501": "Set the host set metadata failed",
            "02100601": "Get available domain failures",
            "02100801": "Remove the host from the collection",
            "02100802": "List the failure of the cloud host security groupailure of the cloud host security group",
            "02110401": "This record cannot be deleted",
            "02110402": "Custom image deletion failed",
            "02130101": "Get a list of listeners for load balancers failed",
            "02130201": "Get a listener details of the load balancer failed",
            "02130301": "Get load balancing list failed",
            "02130401": "Get a load balancer failure",
            "02130501": "Get load balancer status failed",
            "02130601": "New load balancer failed",
            "02130701": "Modify load balancer failed",
            "02130801": "Other resources in the load balancer, delete failed",
            "02130802": "Delete load balancer failed",
            "02130901": "New load balancer listener failed",
            "02130901409": "The port of the listener has been occupied",
            "02130902": "New load balancer listener failed",
            "02131001": "Modify the load balancer listener failed",
            "02131101": "Delete load balancer listener failed",
            "02131201": "Get a resource pool list of load balancers failed",
            "02131301": "Get the resource pool details of the load balancer failed",
            "02131401": "New load balancer resource pool conflict failed",
            "02131402": "New load balancer resource pool failed",
            "02131501": "Modify load balancer resource pool failure",
            "02131601": "Delete load balancer resource pool failed",
            "02131602": "Delete resource pool failed, please empty the resource pool first",
            "02131701": "Get a list of members of the resource pool of the load balancer failed",
            "02131801": "Details of the resource pool of the load balancer failed",
            "02131901": "Member of the new load balancer resource pool failed",
            "02132001": "Change the members of the load balancer resource pool failure",
            "02132101": "Deleting members of the load balancer resource pool failure",
            "02132201": "Get load balancing inspector list failed",
            "02132301": "Get load balancing inspector details failed",
            "02132401": "New load balancing inspector failed",
            "02132501": "Modify the load balancing inspection failed",
            "02132601": "Deleting load balancing inspector failed",

            "02150101": "The project's NameSpace does not exist and failed when it is automatically created.",
            "02150201": "Get cluster copy log failed",
            "02150301": "Get a cluster copy failed",
            "02150302": "Delete a cluster copy failed",
            "02150401": "Create a new RBD cloud hard disk failure: The image name already exists!",
            "02150402": "Create a new RBD cloud hard disk failure: Type error!",
            "02150403": "Create a new RBD cloud hard disk failure: Parameter error!",
            "02150404": "Create a new RBD cloud hard disk failed: internal error!",
            "02150405": "Delete the RBD cloud hard disk failed: internal error!",
            "02150501": "Get the failed container cluster!",
            "02150502": "Delete the container cluster failed!",
            "02150503": "Container cluster dynamic retractability failure!",
            "02150504": "Create a container cluster failure!",
            "02150505": "Get the container cluster details failed!",
            "02150506": "Container cluster scrolling upgrade failed!",
            "02150507": "Get Ceph cluster information failed!",
            "02150601": "Get the list of container service failed!",
            "02150602": "Delete container service failed!",
            "02150603": "Get the portable port failed!",
            "02150604": "Create a container service failed!",
            "02150701": "Gets the list of Node nodes in the READY state failure!",
            "02150702": "Get the list of Node nodes of K8S failed!",
            "02150703": "Add K8S Node node tag failed!",
            "02150704": "Modify the K8S Node node tag failed!",
            "02150705": "Delete the K8S Node node tag failed!",
            "02150706": "Get the Node node of K8S according to the label!",
            "02150801": "Harbor internal error!",
            "02150802": "Initialize the Harbor client failed!",
            "02150803": "Get Harbor's Project Failed!",
            "02150804": "New Harbor's Project Failed!",
            "02150805": "Modify Harbor's Project Access Failure!",
            "02150806": "Delete Harbor's Project Failed!",
            "02150807": "Get the password failure of the Harbor user!",
            "02150808": "Get the missing mirror warehouse list failed!",
            "02150809": "Delete the mirror warehouse failed!",
            "02150810": "Get the tag list of mirror warehouse failed!",
            "02150811": "Remove the TAG failed in the mirror warehouse!",
            "02150812": "Get mirror manifests failed!",
            "02150813": "Get the ID failed to get the current user!",
            "02150814": "Get the current project details!",
            "02150901": "Request error: Upload not a file!",
            "02150902": "Upload Dockerfile failed!",
            "02150903": "Upload DockerFile IO errors!",
            "02151001": "Unable to log in to Harbor!",
            "02151002": "No dockerfile in the request!",
            "02151003": "No repository or tag in the request!",
            "02151004": "Uploaded Dockerfile format is wrong!",
            "02151005": "Unable to decompress the Dockerfile compression package!",
            "02151006": "Uploaded Dockerfile's compressed package format is wrong!",
            "02151007": "Unable to increase label for images!",
            "02151008": "Unable to mirror Docker Build mirror!",
            "02151009": "An unknown error occurred during the build mirror!",
            "02151010": "BUILD image of the parameters!",
            "02151011": "Unable to push already completed mirror!",
            "02151101": "This container does not exist!",
            "02151102": "CADVisor internal error!",
            "02151103": "The result of the conversion is wrong!",

            "01010101": "The new failure of organizational structure",
            "01010102": "Department name already exists",
            "01010201": "Organizational structure deletion failed",
            "01010301": "Organizational structure editing failed",
            "01010401": "Organizational structure list query failed",
            "01010501": "Organizational structure detail query failed",
            "01020101": "Add new failure",
            "01020201": "Department deletion failed",
            "01010202": "The department has bind the user and cannot be deleted",
            "01020203": "There is a project under the department, can't delete",
            "01020204": "There is a user under the department and cannot be deleted.",
            "01020301": "Department editing failed",
            "01020401": "Department list query failed",
            "01020501": "Department detailed query failed",
            "01030701": "Login failed",
            "01030702": "Login failed (username or password error)",
            "01030709": "Users have been locked",
            "01030710": "User does not exist or unbinded roles",
            "01060702": "Switching data center failed",
            "01040101": "Menu list query failed",
            "01050101": "Role new failure",
            "01050102": "Role name repetition, failed",
            "01050201": "Role delete failed",
            "01050202": "Role has bind users, delete failed",
            "01050301": "Role editing failed",
            "01050401": "Role list query failed",
            "01050501": "Role detail query failed",
            "01050601": "Assign role failure",
            "01050701": "Query role and user relationship failed",
            "01050801": "Assign menu to the role failure",
            "01060101": "Project new failure",
            "9001002": "Start nodes are inconsistent with NUMA nodes",
            "01060201": "Project deletion failed",
            "01060202": "The project contains resource deletion failed",
            "01060203": "Project deletion failed, the recycle station contains soft deleted cloud host",
            "01060301": "Project editing failed",
            "01060401": "Project list query failed",
            "01060501": "Project detail query failed",
            "01070101": "Role list query failed",
            "01080101": "User new failure",
            "01080102": "Award a role in Domain failure",
            "01080103": "Awards a role of User in Project",
            "01080104": "Get the user's project rights failure",
            "01080201": "User delete failed",
            "01080301": "User editor failed",
            "01080401": "User list query failed",
            "01080501": "User detail query failed",
            "01080601": "This user already exists",
            "01080701": "Edit user status failed",
            "01080801": "Multiple permissions give users failure",
            "01080901": "Get the user's project list failed",
            "010801001": "Login failed",
            "010801101": "User name is empty",
            "01090101": "Enterprise registration failed",
            "01090201": "Account already exists",
            "01090401": "Login failed (password is empty)",
            "01090301": "Login failed (verification code has been invalid, please re-get)",
            "01090501": "Empty mailbox",
            "01090601": "Failed to log in (empty login name)",
            "01090701": "Enterprise login failed",
            "01090801": "Login failed (business does not exist)",
            "01090302": "Login failed (verification code error)",
            "01030708": "Login failed (platform is upgrading, please log in to use)",
            "01030708": "Login failed (users have been locked)",
            "01100101": "Node Mac does not exist",
            "01100102": "Node has been registered",
            "01100103": "Get node registration list failed",
            "01100104": "Modify the node host name failed",
            "01100105": "Get node configuration failed",
            "01100106": "Update node configuration status",
            "01100107": "Save node configuration failed",
            "01100109": "Modify the node to use status failed",
            "01100201": "Get cluster configuration failed",
            "01100203": "Modify the cluster name failed",
            "01100204": "Save registration record failed",
            "01100205": "Query registration record failed",
            "01100206": "Can't find Region",
            "01100207": "Get business region failed",
            "01100208": "Adding a business region failed",
            "01100209": "Tunnel error",
            "01170102": "New failure",
            "01170101": "New parameter name duplicate",
            "01170201": "Modify parameter name repetition",
            "01170501": "Editor failure",
            "01190101": "Get a quick list failed",
            "01190102": "Delete keep snapshot failed",
            "01190103": "Get a hard disk failure",
            "01190104": "Create snapshot failed",
            "01190106": "Get token failure from user information",
            "01190107": "Insufficient department quota",
            "01190108": "Insufficient project quota",
            "01200101": "Send an upgrade request failed",
            "01200102": "The platform is upgrading",
            "01200103": "Clusters are upgrading",
            "02120101": "Get cluster health status failed",
            "02120201": "Get a warning information failed",
            "02120301": "Get Monitor information failed",
            "02120401": "Get PG status failed",
            "02120501": "Get disk use failed",
            "02120601": "Pools information failure",
            "02120701": "Get OSD Tree information failed",
            "02120801": "Over-fusion CEPH storage service abnormal or unspeakable super mean CEPH storage",
            "04030102": "Process start failed",
            "011002010": "Get cluster configuration failed, there is no node unconfigured",
            "011002011": "Modify the cluster status failed",
            "011002012": "Get tunnel address failed",
            "00010110": "Insufficient permissions, please contact the administrator",
            "01020202": "The department has bind the user and cannot be deleted",
            "01140101": "Black and white list list name repetition",
            "01140102": "Black and white list new failure",
            "01140301": "Black and white list editor failed",
            "04010101": "Process creation failed",
            "04010201": "Process deployment failed",
            "04010301": "Process deletion failed",
            "04010401": "Process handler query failed",
            "01120301": "Describe the excessive text, please re-enter",
            "01080202": "The user has been associated with the process, please re-deploy this process.",
            "01140401": "Black and white list list get failed",
            "04020201": "Delete process failed",
            "011404501": "Black and white list status editing failed",
            "01030703": "Login failed (the IP address of the user is rejected)",
            "03013601": "The alarm template is used, not allowing deletion",
            "03013701": "The alarm contact group is used, not allowed to delete",
            "03017101": "Strategy item repeat",
            "03013801": "No corporate id in the alarm related header",
            "07010101": "Authentication failure",
            "04010601": "Replication model failed",
            "04030101": "Please bind the workflow and then operate",
            "04010202": "Not drawn flow chart or flow chart format error",
            "03015101": "Virtual machine CPU usage TOP5 acquisition failed",
            "02110101": "Create a custom mirror failed",
            "01160101": "Get authorization information failed",
            "01160201": "Send an authorization update request failed",
            "9001001": "Big page memory size is not matched",
            "00010106": "Authorization has expired",
            "02015301": "Restore the cloud host failed",
            "02015401": "Forced delete cloud host failed",
            "02015501": "Get a list of recycling sites failed",
            "03010701": "The same warfare has existed",
            "03011301": "An alarm template with the same name already exists",
            "03012901": "A contact group with the same name already exist",
            "03012902": "Contacts in contact groups cannot have two or more mailboxes",
            "06010201": "Downloadings have been invalid, please re-get",
            "01150502": "The node maintenance begins, and is closing the virtual machine on the node, please wait ",
            "00010201": "Only super administrators have permission operations",
            "02180101": "Extended cluster virtual machine, alarm template or expansion policy does not exist",
            "02180201": "The name of the expansion policy already exists",
            "02180301": "Extended strategies have been used to delete",
            "02200100": "Creating an extension cluster must be empty",
            "02200101": "Extended cluster gets the image details failed",
            "02200102": "Extended cluster saves mirror details failed",
            "02200103": "Save the expansion cluster basic information failed",
            "02200104": "Save extended cluster cloud host configuration information failed",
            "02200105": "Create an extension cluster cloud host failed",
            "02200106": "Create an extension cluster cloud host is 0",
            "02200107": "Save extended cluster cloud host details failed",
            "02200108": "Extended cluster cloud hosts are not activated",
            "02200109": "Balancer failed to create an extension cluster",
            "02200110": "Save the Balancer failed to expand the cluster",
            "02200111": "Balancer in the extended cluster is not activated",
            "02200112": "Create a Listener failed to create an extension cluster",
            "02200113": "Expansion Cluster Listener is not activated",
            "02200114": "Create the Pool failure of the extension cluster",
            "02200115": "Extended cluster Pool is not activated",
            "02200116": "Member failed to create an extension cluster",
            "02200117": "MEMBER in the extended cluster is not activated",
            "02200118": "Creating an extension cluster AlarmID failed",
            "02200200": "The expansion cluster does not exist",
            "02200201": "The expansion cluster is deleted",
            "02200202": "Delete an extended cluster's alarm ID failed",
            "02200203": "Remove the Member failed to extend the cluster",
            "02200204": "Deleting the Pool failure of the extended cluster",
            "02200205": "Delete the Listener failed to extend the cluster",
            "02200206": "Delete Balancer failed to extend the cluster",
            "02200207": "Remove the extended cluster failure",
            "02200301": "Batch delete expansion cluster failed",
            "02230101": "Gets the HOST information failed",
            "02290101": "Create IPSec policy failed",
            "02290102": "Delete IPSec policy failed",
            "02290103": "Disable IPSec policy failed",
            "02290104": "Enable IPSec policy failed",
            "02290105": "Get IPSec policy details failed",
            "02290106": "Get an IPSec policy list failed",
            "02290107": "Modify IPSec policy failed",
            "02290108": "Modify EndPoint-Groups failed",
            "02290109": "Polling IPSecsite status failed",
            "02310101": "Insufficient project quota",
            "02310102": "Insufficient department quota",
            "02059001": "Create a firewall failure",
            "02059000": "Get a firewall list failed",
            "02059002": "Update firewall failed",
            "02059003": "Delete firewall failed",
            "02059004": "Create firewall rules failure",
            "02059005": "Updating firewall rules failure",
            "02059006": "Delete firewall rules failure",
            "02059007": "Get a firewall rule list failed",
            "02059008": "Get a list of firewall policies failed",
            "02059009": "Create a firewall policy failed",
            "02059010": "Edit firewall strategy failed",
            "02059011": "Delete firewall policy failed",
            "02059012": "Get static routing table details failed",
            "02059013": "Edit static routing table failed",
            "02059014": "Enable FireWallRule failed",
            "02059015": "Disable FireWallRule failed",
            "02059408": "Get network type failed",
            "02059409": "Add physical network failure",
            "03016101": "Query historical data failed",
            "03016201": "Query Rabbitmq information failed",
            "02059801": "Router update failed",
            "02059802": "Update routing conflict",
            "02059803": "The next hop address is not related to the route",
            "08010101": "Connection vCenter failed",
            "08010103": "VCenter URL format error",
            "08010104": "Get SSL ThumbPrint failed",
            "04040201": "Failed to find the corresponding task information",
            "04040301": "Failed to find the corresponding task information",
            "09010101": "Create a snapshot plan mission failed",
            "09010201": "Update the failure of the time",
            "09010301": "Delete fast-moving time failure",
            "09010401": "Suspend the failure of the time",
            "09010501": "Restore the quick time failure",
            "09010601": "Get planned task list errors",
            "09010602": "Get cloud host name failed",
            "09010603": "Get cloud hard disk name failed",
            "09010701": "Get planned task information error",
            "09010801": "Get planned task execution result error",
            "09010901": "Check plan task information error",
            "09011001": "Update the task fails when meshing the hard disk",
            "09011101": "Get task creates a snapshot number failed",
            "02050204": "Adding bandwidth limit fails when creating a network",
            "02015801": "Adjust the cloud host bandwidth failed",
            "02015901": "Get cloud host bandwidth failed",
            "03030101": "Query the physical machine process CPU usage failure",
            "03030201": "Query the tube physical machine process CPU usage sort failed",
            "03030301": "Query physical machine process memory usage TOP5 sort failed",
            "03030401": "The query tube physical machine process memory usage TOP5 sort failed",
            "03030501": "Query physical machine process details failed",
            "03030601": "Query the tube physics process details failed",
            "01030705": "Get data center information failed",
            "01081101": "User name is empty",
            "01090802": "Editor's public cloud information failed",
            "01100100": "Node update success",
            "01100211": "Modify the cluster status failed",
            "01100212": "Get tunnel address failed",
            "01100213": "Empty cluster configuration failed",
            "01130201": "Get the maximum use of resources under departmental projects",
            "01140201": "Black and white list delete failed",
            "01150901": "Modify the node configuration failed",
            "01160301": "Upload license file update request failed",
            "01160302": "Upload license file format error",
            "01160303": "Upload the license file too big",
            "01170302": "Query parameters failed",
            "01170401": "failed to delete",
            "01170301": "Query the following parameters failed",
            "01180101": "Query parameter version list failed",
            "01180201": "Query parameters failed",
            "01180301": "Save parameter setting failed",
            "01190105": "User has deleted",
            "01200101": "Send an upgrade request failed",
            "01200102": "The platform is upgrading",
            "01200103": "Clusters are upgrading",
            "01190109": "The number of snapshot retention has created a hard disk",
            "02020701": "Check the mirror caching volume failed",
            "02100101": "Get the failure of the host collection",
            "02100401": "Resets the host collection failed",
            "02100701": "Add hosts to set failed",
            "02281201": "Backup strategy creation failed",
            "02281202": "Backup strategy name already exists",
            "02281401": "Get backup policy list failed",
            "02281301": "Backup strategy",
            "02281302": "Backup strategy has been removed",
            "02281303": "Delete Backup Policy Fails",
            "02110102": "Save custom mirror creation results failed",
            "02110201": "Update custom mirror failed",
            "02210101": "Volume type initialization failed",
            "02220101": "Get the failure of using VLANID",
            "04010102": "The file name is empty",
            "04010103": "Maximum file is 1M",
            "04010104": "The file type does not meet the requirements, and it is necessary for the BPMN type.",
            "04010105": "File already exists",
            "04010106": "Save the upload file failed",
            "04010501": "Initialization model",
            "04020107": "Deployment process failed",
            "04040101": "No corresponding task or user",
            "04040302": "This task has been signed by other users",
            "8004012":"Get logo failed",
            "03010101": "Increase alarm failed",
            "03010201": "Edit alarm failed",
            "03010301": "Get a list of alarms failed",
            "03010401": "Get a single alarm failed",
            "03010501": "Delete alarm failed",
            "03010601": "Service type alarm already exists",
            "03010801": "Increase the alarm mode failed",
            "03010901": "Edit alarm template failed",
            "03011001": "Get the alarm template list failed",
            "03011101": "Get a single alarm template failed",
            "03011201": "Delete alarm template failed",
            "03013901": "There is no business or project ID when resource management acquires alarm events.",
            "03011401": "Increase the alarm rule failed",
            "03011501": "Edit the alarm rule failed",
            "03011601": "Get the list of the alarm rules failed",
            "03011701": "Get a single alarm rule failed",
            "03011801": "Delete alarm rules failed",
            "03011901": "Increase alarm event failed",
            "03012001": "Edit alarm event failed",
            "03012101": "Get a list of individual alarm events failed",
            "03012201": "Get a single alert event failed",
            "03012301": "Delete alarm event failed",
            "03012401": "Increase contact list failed",
            "03012501": "Edit contact list failed",
            "03012601": "Get a list of contact list failed",
            "03012701": "Get a single contact list failed",
            "03012801": "Delete contact list failed",
            "03013001": "Increase contact failure",
            "03013101": "Edit contact failed",
            "03013201": "Get contact list failed",
            "03013301": "Get a single contact failed",
            "03013401": "Delete contact failed",
            "03016401": "Increase label failure",
            "03016501": "Edit label failed",
            "03016601": "Get tag list failed",
            "03016701": "Get a single label failure",
            "03013501": "Delete label failed",
            "03014101": "Upload historical data failed",
            "03016301": "Get mysql basic information failed",
            "03018101": "Get the details of the tube node failure",
            "03020101": "Alarm initialization failed",
            "00010103": "User information",
            "00010104": "KeystoneURL is empty",
            "02050203": "Network resources beyond quotas",
            "02058102": "Listeners of the same protocol and port cannot be created",
            "02057102": "The bound cloud host port is inconsistent with the bound cloud host port",
            "02058701": "Get a subnet details failed",
            "02058702": "Subnet does not exist",
            "02055501": "Get QoS strategy failure",
            "02055601": "Modify the QoS policy failed",
            "02055701": "Create a QoS policy failed",
            "02055801": "Delete QoS policy failed",
            "02055901": "Get bandwidth rules failure",
            "02056001": "Modifying bandwidth rules failure",
            "02056101": "Creating bandwidth rules failure",
            "02056201": "Delete bandwidth rules failure",
            "02056501": "Get a list of load balancer failed",
            "02056601": "Create a load balancer failed",
            "02056701": "Get a load balancer failure",
            "02056801": "Modify load balancer failed",
            "02056901": "Delete load balancer failed",
            "02057001": "Get member list failed",
            "02057101": "Create a member failed",
            "02057201": "Get member details failed",
            "02057301": "Modify members fail",
            "02057401": "Delete member failed",
            "02057501": "Get the VIP list failed",
            "02057601": "Create a VIP failed",
            "02057701": "Get VIP details failed",
            "02057801": "Modify VIP failure",
            "02057901": "Delete VIP failed",
            "02058001": "Get a list of monitor list failed",
            "02058101": "Creating a monitor failed",
            "02058201": "Get a monitor details failure",
            "02058301": "Modify the monitor failed",
            "02058401": "Delete monitor failed",
            "02058501": "Binding monitor failed",
            "02058601": "Unfailed monitoring monitor",
            "02058703": "Clear gateway and VPN conflict",
            "02058704": "Get the number of available IP failed",
            "02058801": "Get QoS policy details failed",
            "02058901": "Get a bandwidth rule details failed",
            "02059301": "Add bandwidth to virtual machines",
            "02059101": "Add bandwidth to tenant nets",
            "01100210": "Delete Region failed",
            "01100216": "Node is not configured",
            "04010107": "File is empty",
            "01100217": "Get quotausage failed",
            "01140601": "Black and white list details failed",
            "02010303": "Creating a cloud host selected specification",
            "77000111": "Disk space, mirror upload failed",
            "02053601": "Quick creation failed: Network creation failed",
            "02053701": "Quick creation failure: Set the bandwidth attribute failed",
            "02053801": "Quick creation failure: Subnet creation failed",
            "02053901": "Quick creation failed: Route creation failed",
            "02054001": "Quick creation failed: Binding routing port failed",
            "9001004": "SR-IOV network name repetition",

            "02270101":"Create a physical machine network failed",

            "02016701":"Cloud host one-button restore failed",
            "02016702":"The original mirror does not exist, can not be restored one-click",
            "02016703":"There is a snapshot on the system disk, please delete the snapshot to continue",
            "02016601":"Delete cloud main unit failure",
            "02016301":"Creating a cloud host group failed",
            "02016401":"Get cloud host list failed",
            "02016501":"Get the details of the cloud main unit detail",
            "02016201":"Modify the cloud host password failed",
            "02280101":"Cloud hard disk backup failed",
            "02280201":"Get backup list failed",
            "02280301":"Delete backup failed",
            "02280401":"Get backup failed to get cloud hard drive",
            "02280501":"Get backup details failed",
            "02280601":"Backup restore failed",
            "02280701":"Backup creation cloud hard disk failed",
            "02280801":"Reset backup status failed",
            "02280901":"Delete backup timeout",
            "02013801":"Cold migration failed",
            //导入内容为空或格式错误
            "31415926":"Import content format error",
            //物理机
            "02240101": "Get a resource physical host list failed",
            "02240201":"Get resource physical host details failed",
            "02240301":"Register physical host failed",
            "02240401":"Edit physical host failed",
            "02240501":"Set the physical host device status failed",
            "02240601":"Delete physical host failed",
            "02240701":"Set the physical host power status failed",
            "02240801":"Open physical host maintenance mode failed",
            "02240901":"Close the physical host maintenance mode failed",
            "02241001":"Batch registration physical machine failed",
            "02241002":"Batch registration file format error",
            "02241003":"Batch registration data error",
            "02241004":"Node authentication information error, unable to connect to node",
            "02241101":"Interrupt initialization failed",

            "02250101":"Get list of physical machine failed",
            "02250201":"Get physical machine details failed",
            "02250301":"Apply for physical machine failure",
            "02250401":"Close the physical machine failed",
            "02250402":"Physical machine status is incorrect",
            "02250501":"Start physical machine failed",
            "02250601":"Restart the physical machine failed",
            "02250701":"Rename physical machine failed",
            "02250801":"Delete physical machine failed",
            "02250901":"Locking physical machine failed",
            "02251001":"Unlocking physical machine failed",
            "02251101":"Get physical machine type failed",
            "02251201":"Create physical machine type failed",
            "02251301":"Delete physical machine type failed",
            "02251401":"Get physical machine type special settings failed",
            "02251501":"Create a physical machine type special setting failed",
            "02251601":"Delete physical machine type special settings failed",
            "02251701":"Edit physical machine type Special settings failed",

            //ipmi 获取sdr信息
            "02260101":"Get SDR information failed",
            "02260201":"Get the power status failed",
            "02260301":"Get inspection IPMI information failed",
            "02260401":"Register IPMI address Repeat",

            "02270101":"Create a physical machine network failed",
            "02270201":"Delete physical machine network failed",
            "02270301":"Get a physical machine network port list failed",
            "02270401":"Get physical machine network details failed",
            "02270501":"Physical machine net creation failed",
            "02270601":"Physical machine net editing failed",
            "02270701":"Physical unit deletion failed",
            "03060101":"System inspection",

            "02210102":"Cloud hard disk migration failed",
            "02210103":"The storage device where the cloud hard disk is located is associated with QoS, and online migration is not supported.",
            "02052501":"Get port list failed",
            "02051511":"New port forwarding failed",
            "02051512":"New port debirs fail, external ports are occupied",
            "02051503":"Get port forwarding list failed",
            "02016802":"NIC security settings failed",
            "02052703":"Get the security group details failed",

            "02030804":"Load has reached the upper limit",
            "02040703":"Create ISO volume failed",
            "02040701":"Uninstall ISO volume failed",
            "02040808":"No storage available",
            "03040101":"Developer ID or password error, WeChat setting failed",

            "02281001": "Backup record deduction failed",

            "02150102":"The billing item already exists, can not be added repeatedly",
            "02150103":"The billing resource item does not exist, please add this billing item in price management",
            "03050101":"Get resource statistics failure",

            "02017001":"Gets the cloud host start order failed",
            "02017101":"Adjusting the cloud host start order failed",

            "09010101":"Creating a planned task failed",
            "09010201":"Update plan mission failed",
            "09010301":"Delete plan task failed",
            "09010401":"Suspension plan mission failed",
            "09010501":"Restore plan mission failed",
            "09010601":"Get planned task list errors",
            "09010602":"Get cloud host name failed",
            "09010603":"Get cloud hard disk name failed",
            "09010701":"Get planned task information error",
            "09010801":"Get planned task execution result error",
            "09010901":"Check plan task information error",
            "09011001":"Update the task fails when meshing the hard disk",
            "09011101":"Get task creates a snapshot number failed",
            "09011201":"Get task creates a backup number failed",
            "01190201":"Get cloud host information failed",
            "01190202":"Cloud hard disk status is not allowed to create a backup",
            "01190203":"Get a backup list of cloud hard drive failed",
            "01190204":"Delete the first timing backup chain failed",
            "01190205":"Create a backup failed",
            "01190206":"In the backup of the cloud, skip this backup",
            "01190207":"Sector backup quantity quota",
            "01190208":"Project backup quantity quota",
            "01190209":"Department backup memory quota is insufficient",
            "01190210":"Project backup memory quota is insufficient",
            //透传设备
            "02018201":"Cloud host loading USB device failed",
            "02018801":"Cloud host loading USB device reaches the upper limit",
            "02019001":"Get all GPU devices failure on the host where the cloud host is located",
            "02019101":"Loading GPU equipment failed",
            "02019002":"Get all FPGA devices on the host where the cloud host is located",
            "02019102":"Loading FPGA equipment failed",
            "02019202":"Cloud host uninstalling FPGA equipment failed",
            "02018301":"Cloud host uninstall USB device failed",
            "02018401":"Get cloud host has loaded USB device list failed",
            "02018501":"Get all cloud hosts that have failed USB device list",
            "02018601":"Gets the list of USB devices that the cloud host can load",
            "02018701":"Get the USB device details that the cloud host can load",
            "02019201":"Uninstall GPU device failed",
            "02019401":"Get the cloud host has loaded the transparent device list failed",
            "02019111":"The current FPGA device has been loaded",
            "02019112":"The current GPU device has been loaded",
            "02019113":"The current USB device has been loaded",
            "02019601":"Set the cloud host exception restart switch failed",
            //云主机备份
            "01210101": "Backup storage already exists",
            "01210102": "File format is wrong",
            "01210103": "File size beyond restrictions",
            "01210104": "CEPH configuration file upload failed",
            "01210201": "Query whether the backup has failed",
            "01210202": "Backup storage has been used",
            "01210301": "Get backup storage capacity failed",
            "01230101": "Get FC disk information timeout",
            "01230213": "The store corresponding to the store is in created or deleted, unable to delete",
            "01230102": "Get FC disk information failed",
            "01230103": "Get FC disk mission failed, please try again",
            "01230201": "Get NFS path timeout",
            "9001001": "Big page memory match failed",
            "9001002": "SRIOV match failed",
            "9001003": "NUMA match failed",
            "02040202": "This type of snapshot is automatically generated for the system, not allowing manual deletion.",
            "01230218": "Migration failed, mirroring mirroring mirror",
            "9001004": "SRIOV network name repetition",
            "02059410": "This network is used in use, cannot be deleted",
            "9001005": "The physical network is being used, delete failed",
            "9001006": "The bottom layer network service is not started, please try again later",
            "9001007": "Big page memory configuration",
            "9001009": "DPDK repeat operation",
            "9001010": "DPDK node does not configure a large page memory",
            "01230205": "The bare metal uses the storage pool and does not allow CEPH storage",
            "01230206": "The container uses the storage pool and does not allow CEPH storage",
            "01230207": "Storage pool name is not available",
            "01230208": "Default storage pool is not allowed to delete",
            "01230209": "The used storage pool is not allowed to delete",
            "01230210": "The middle state of the storage pool is not allowed to delete storage",
            "01230211":"The stored storage is not allowed to delete",
            "01230212":"This storage migration can not be deleted",
            "01230213":"The store corresponding to the store is in created or deleted, unable to delete",
            "01230214":"The status obtained in Ceph Storage Management is Error, and the storage pool cannot be added and deleted.",
            "01230215":"Mirror migration is being performed, no image migration is allowed",
            "01230216":"The middle state of the storage pool is not allowed to add deleted storage",

            "01230202": "Get NFS path information failed",
            "01230203": "Get NFS path tasks fail, please try again",
            "01230204": "The last storage is not allowed to delete",

            "02132701": "This load balancing state error is not allowed to operate",
            "02280102": "Backup storage capacity is insufficient",
            "01190211": "Backup storage capacity is insufficient",
            "02280103": "Backup storage capacity verification failed",
            "01220101": "Get cluster CEPH information timeout",
            "01220102": "Get cluster CEPH information failed",
            "09020101": "Create a CEPH task failed",
            "09020201": "Get a CEPH task list failed",
            "09020301": "Delete CEPH task list failed",
            "09020401": "Ceph task conflict, there is a task waiting or executed",
            "09020102": "Task start time cannot be earlier than task creation time",
            "09020302": "Can't delete in task execution",
            "02019501": "Delete cloud host system disk failed",
            "02280103": "Backup storage capacity verification failed",
            "02280104": "Backup storage capacity verification failed, please try again",
            "01220103": "Get cluster Ceph information failed, please try again",
            "01190213": "Check the backup storage capacity failed and skip this task",
            "02054201":"Create IPv6 subnet failed",
            "04040202": "Work order end task cannot be operated",
            "02241201":"Get physical machine initialization configuration failed",
            "02241301":"Set the physical machine system disk failed",
            "05010101":"Resource application quota",
            "02251901":"Check if the physical machine is allowed to reload failed",
            "01130202": "Get the maximum number of resources under departmental projects",
            "02040901":"Cloud hard disk is not migrated",
            "02040902":"Cloud hard disk reset state failed",
            "02031002":"Project quota exceeds department quotas",
            "02031003":"Department quotas less than project quotas",
            "300401":"Quota",
            "8001008":"The current platform is in the operation and maintenance mode, the operation has been locked",
            "0213409":"You can't create a load balancer, specify that the available IP address on the network is insufficient.",
            "8003010":"add failed",
            "8003011":"Editor failure",
            "8003012":"failed to delete",
            "8003013":"Query failed",
            "8003009":"PaaS service already exists",
            "01240101":"Synchronous equipment to weaving the cloud failed",
            "01240102":"No PAAS service has not been docked, please dock",

            //tdsql
            "18010107":"New user failed",
            "18010108":"Modify user rights failure",
            "18010109":"Query user list failed",
            "18010110":"Delete user failed",
            "18010111":"Reset password failed",
            "18010112":"Clone permission failure",
            "18010118":"Get permission failure",
            "18010113":"Get database list failed",
            "18010114":"Get an object list failed",
            "18010115":"Get field list failed",
            "18010116":"Get an instance list failed",
            "18010117":"Set instance synchronization / asynchronous mode failed",
            "18010118":"There is a degraded example, change the synchronization state failure",
            "18010119":"Get an instance details failed",
            "18010120":"Query details failed, please confirm if the instance is existing in the TDSQL platform",
            "02310100":"Insufficient departments or project quotas",
            "02310103":"Quota check failed",
            "02016201": "Modify the cloud host password failed",
            "01060204":"Project deletion failed, there is a user under the project",
            "01020205":"The default department cannot delete",
            "01060205":"Default project cannot be deleted",
            "02013902":"Thermal migration failed: insufficient space",
            "01100113":"Import file format errors",
            "01100114":"The form of information format does not meet the norm, the batch import alias failed",
            "01100115":"Import failed, the table information is empty",
            "01100116":"Import failed, IP address format is not legal",
            "01100117":"Alias consists of letters, numbers or horizontal lines, with a length of 4-64 characters, and can only begin with letters or numbers.",
            "01100118":"Import failure, alias between nodes cannot be repeated",
            "01100111":"No repeat between each host alias",
            "01100112":"Update host alias failed",
            "09020501":"Synchronous resource data task new failed",
            "09020502":"Synchronous resource data task modification failed",
            "09020503":"Synchronous resource data task query failed",
            "80088001":"Safety Group Pages List Query Failure",
            "80088002":"Key pags list query failed",
            "80088003":"Firewall paging list query failed",
            "01080305":"Current cluster status is not allowed to do this",
            "02281201":"Backup strategy creation failed",
            "02281202":"Backup strategy name already exists",
            "02281301":"Backup strategy",
            "02281302":"Backup strategy has been removed",
            "02281303":"Delete Backup Policy Fails",
            "02281401":"Get backup policy list failed",
            "02281801":'Creating a backup dispatch failed',
            "02281802":"Backup strategy does not exist",
            "02281803":"Create a backup failed",
            "02281804":"Cloud hard disk is running backup",
            "02281805":"Backup name already exists",
            "02281901":"Delete backup scheduling failed",
            "02281902":"Backup status is not not starting",
            "02282001":"Backup status is not allowed to delete",
            "02282002":"Backup deletion failed",
            "02282101":"Get backup scheduling time failed",
            "02282201":"The cloud host status can only be restored",
            "02282202":"Backup status is not available",
            "80018001":"Cloud host creation failed",
            "80018002":"Cloud host update failed",
            "80018003":"Cloud host delete failed",
            "80018004":"Get cloud host list failed",
            "80028001":"Cloud hard disk creation failed",
            "80028002":"Cloud hard disk update failed",
            "80028003":"Get the list of cloud hard drives failed",
            "80028004":"Cloud hard disk delete failed",
            "80038001":"Backup creation failed",
            "80048001":"Snapshot creation failed",
            "80048002":"Snapshot deletion failed",
            "80048003":"Snapshot query failed",
            "80058001":"Yun main unit creation failed",
            "80058002":"Yun main unit update failed",
            "80058003":"Cloud main unit delete failed",
            "80058004":"Get a list of cloud host groups failed",
            "02280105":"Project backup quota",
            "02280106":"Department of lack of departments",
            "02280107":"Project backup capacity quota is insufficient",
            "02280108":"Department of backup capacity quotas",
            "01140103":"Black and white list IP repeat",
            "02030203":"Cloud hard disk is not normal",
            "80008001":"In sync, please wait!",
            // 角色管理
            "01080602":"The operation failed, the role does not exist or has been deleted",
            "01230215":"Mirror migration is being performed, no image migration is allowed",
            "01230217":"Abnormal nodes in the current cluster",
            "02018101":"Modify disk QoS failed",
            "02016101":"Reset cloud host failed",
            //对象存储
            "02070901":"Create a container name duplicate",
            "02070900":"Create a container failed",
            "02070902":"Create a directory failed",
            "02070905":"File upload failed",
            "02071100":"failed to delete",
            "02071201":"Permission modification failed",
            "02053602":"IP address conflict",
            "02070500":"Get a bucket list failed",
            "02070801":"Bucket quota is insufficient",
            "02070901":"Barrel name repetition",
            "02070903":"Storage object name duplicate",
            "02070904":"Insufficient quota",
            "02071801":"Get the client failed, check the object storage settings",
            "02071802":"Access key is invalid",
            "02071803":"Signature does not match",
            "02071902":"The listener is not deleted, and load balancing fails to be deleted",
            //对象存储
            "02330101":"Get file storage failed",
            "02330102":"Get file storage type failed",
            "02330103":"File storage creation failed",
            "02330104":"File storage modification failed",
            "02330105":"File storage deletion failed",
            "02330106":"Get file storage rules failed",
            "02330107":"Rule creation failed",
            "02330108":"文件存储详情获取失败",
            "02330109":"File storage rule delete failed",
            "02330110":"IP or CIDR address already exists",
            "02340602":"Default account is not allowed to delete",
            "200704007":"Calibration, AccessKeyID or AccessSecret does not exist / input incorrectly",
            "13050001":"Calibration failure, access key ID or private access key does not exist / input is incorrect",
            "01301001":"CSP access is prohibited, the specified user does not exist",
            //安全组
            "15010101":"Create a security group failed",
            "15010102":"Security group name repetition",
            "15010201":"Update security group failed",
            "15010301":"Delete security group failed",
            "15010401":"Create a security group rule failed",
            "15010501":"Security group does not exist",
            "15010601":"Safety group rules conflict",
            "15010701":"Blacklist rule parameter error",
            "15050102":"Failed to update the enhanced load balancing image",

            "04060101":"Add service failed",
            "04060102":"Add service existence",
            "04060103":"Delete service failed",
            "01081302":"AD domain user unfailed role failed",
            "01081303":"Check the AD configuration information failed",
            "01081304":"Turn off the AD docking check, you need to turn it off, please contact the operator",
            "02340202":"User already exists",
            "02340603":"Users have used in object storage settings",
            "02340802":"The key has been used in the object storage settings",
            "01170502":"Unsettled TCS",
            "01170503":"Do not open TCS service",
            "01130203":"Project resource quota",
            "15020102":"Label creation failed",
            "15020101":"The label name already exists",
            "15020103":"Modify the tag name failed, the name already exists",
            "15020201":"Cloud host and label binding relationship already exists",
            "15020202":"Cloud host and label binding relationship do not exist",
            "15020301":"Cloud host is bound to tag more than ten",
            "02019701":"Calibration cloud host status failed",
            "02019702":"Calibration storage pool failed",
            "02019703":"Clone cloud hard disk failed",
            "02019704":"Calibration IP address failed",
            "01290101":"Failure to send tasks, please try again",
            "02019706":"Get a hard disk controller type failed",
            "01290102":"The cluster is not installed in Ceph storage, unable to perform clone chain optimization",
            "01290106":"The cluster is not installed in the CEPH storage, and the Ceph object storage cannot be docked.",
            "01300801":"CSP access is prohibited, please check the configuration",
            "01300701":"CSP acquisition file storage pool information failed, please contact the administrator",
            "15030201":"Create a local disk failed",
            "15030202":"Local disk name already exists",
            "15030301":"Get a local disk list failed",
            "15030401":"Delete local disk failed",
            "15030402":"Local data disks have been used, unable to delete local disks",
            "15030501":"Mount local disk failed",
            "15030601":"Uninstall local disk failure",
            "15030701":"Local disk does not exist",
            "01300901":"In the key use, the operation failed",
            "02040903":"There is a snapshot of the cloud hard drive and cannot be migrated.",  

            "16010101":"Added configuration service exception",
            "16010102":"Delete configuration service exception",
            "16010106":"Wrong user name or password",
            "16010108":"Change password failed",
            "16010109":"Parameter exception",
            "16010110":"Configuration does not exist",
            "16010104":"The backup configuration is being deployed",
            "16010105":"Please make a backup configuration first",
            "16040101":"The selected cloud host must be under the same task, time node, and task type",
            "16040102":"Duplicate task name",
            "16040103":"Add failed",
            "16040104":"Failed to query the execution details of the cloud host",
            "16040105":"Failed to get task execution progress",
            "16040106":"Deletion failed",
            "16040107":"Stop failed",
            "16040108":"Query task failed",
            "16050101":"New failed",
            "16050102":"Failed to get backup task",
            "16050103":"Failed to get the data cleaning list by paging",
            "16050104":"Failed to get the backup data time node list by paging",
            "16050105":"Task name cannot be empty",
            "16020401":"Failed to query data backup monitoring list",
            "16020402":"Failed to stop backup service",
            "16020403":"Failed to delete backup historical data",
            "16020404":"Failed to get details of backup task execution",
            "16020405":"Failed to get the log of executing the backup task",
            "16020406":"Failed to get the log of executing the backup task",
            "16020407":"Failed to get task execution record details",
            "16020101":"Parameter exception",
            "16020102":"Duplicate name",
            "16020103":"Query backup task",
            "16020104":"Task does not exist",
            "16020201":"Create policy exception",
            "16020202":"Create task exception",
            "16020203":"Binding policy exception",
            "16020204":"The query strategy is abnormal",
            "16020205":"Query client exception",
            "16020206":"Query group exception",
            "16020207":"Query tenants under group",
            "16020208":"The editing backup task is abnormal",
            "16020301":"Delete backup task abnormal",
            "16020302":"Delete policy exception",
            "02040903":"There is a snapshot of the cloud hard drive and cannot be migrated.",
            "02010304":"Get a cloud host type fails when creating a cloud host",
            "02010305":"Save the cloud host type failed when creating a cloud host",
            "02010202":"Cloud host type failed to get cloud host details",
            "02019801":"Cloud host holds wrong",
            "02019802":"Cloud host status error is not allowed",
            "02019803":"Remove the cloud host",
            "02019804":"Cloud host status error does not allow cancellation",
            "02019805":"Cloud host binding resources cannot be put on hold",
            "02019806":"Insufficient quota can not be canceled",
            "02052401":"Get a cloud host type failed through the port",
            "02030805":"The cloud host she holds cannot load the cloud hard disk",
            "02030904":"The shelved cloud host cannot uninstall the cloud hard disk",
            "15050202":"Load balancing virtual machine status abnormal",
            "02040904":"Cloud host is unable to relocate",
            "02010306":"Failure: When the target volume is stored in NFS, it does not support batch creation. The current resources are in use. Please try it later.",
            "02010307":"Failure: When the target volume is stored in NFS, it does not support batch creation."
        },
        "tmpl": {
            "header": {
                "cacheMonitoring": "Cache monitoring",
                "cacheIO": "Cache IO hit ratio",
                "dirtydata": "Dirty data",
                "overview": "Overview",
                "productsandservices": "Product&Service",
                "ResourceManagement": "Resource Management",
                "Compute": "Compute",
                "storage": "Storage",
                "network": "Network",
                "Safety": "Safety",
                "depart": "Department",
                "project": "Project",
                "LogManagement": "Log Management",
                "SystemLog": "System Log",
                "OperationLog": "Operation Log",
                "LogAnalysis": "Log Analysis",
                "system": "System Management",
                "user": "User Management",
                "Permissionsettings": "Permission settings",
                "personalinformation": "Personal information",
                "quit": "quit",
                "TicketTips": "Ticket warning",
                "TicketTip": "Ticket warning : 0 at present",
                "MassageTips": "Information consultation",
                "MassageTip": "Information Consultation: Currently 0 unread",
                "resApplyTips": "Resource Requests: currently 0 pending",
                "passwordModify": "Password Modify",
                "resetModify": "Password Reset",
                "Tips001": "Ticket of Apr 22th, the administrator has replied, please check",
                "white": "White",
                "black": "Black",
                "all": "All",
                "privateCloud": "Private Cloud"
            },
            "index": {
                "depart": "Department",
                "project": "Project",
                "imgChoice": "Select images",
                "ok": "Ok",
                "cancel": "Cancel"
            }
        },
        "system": {
            "imageSelect":"Mirror to choose",
            "imageSelectMsg":"A load balancing image is used to create a load balancing image. The image cannot be changed and cannot be edited on the image management page. Therefore, the nic multi-queue function must be enabled for the image.  ",
            "enhancefunction":"Enhanced function",
            "enhancefunctionMsg":"Enhanced load balancing (enhanced load balancing has better performance but consumes CPU and memory resources and quotas)  ",
            "closeOK":"Confirm the shut down",
            "msgAlert":"Delete all enhanced load balancers and perform this operation. Then, log in to the system again.  ",
            "enterprise": "Enterprise edition",
            "stand": "Standard Edition",
            "plugin": {
                "del_tips": "Are you sure you want to delete the selection?",
                "sync_tips": "Are you sure you want to synchronize data?"
            },
            "index": {
                "flavors": "flavors",
                "aggregates": "aggregates",

                "hypervisors": "hypervisors",
                "services": "services",
                "availZoneList": "availability zone"
            },

            "flavor": {
                "instance_type": "Instance Type",
                "diskPlaceholder": "This value should not exceed the maximum capacity of a single physical host's available data disk",
                "cupkernel": "The maximum number of CPU nuclei was 240",
                "ramcheck": "The maximum number of Ram was 4000GB",
                "name": "Name",
                "vcpus": "CPU Core Number",
                "vcpuArch": "CPU Type",
                "vcpus_max": "CPU Core Number(max)",
                "vcpusmaxtips": "When create Instance, Quota calculate with this value",
                "ram": "Ram(GB)",
                "rammax": "Max Ram(GB)",
                "rammaxtip": "When create Instance, Quota calculate with this value",
                "disk": "Local Disk(GB)",
                "disk_": "Root Disk(GB)",
                "diskPartition": "Disk Partition Format",
                "ephemeral": "Ephemeral Disk(GB)",
                "_public": "Public",
                "modifyFlavorAccess": "Edit flavor access",
                "specialSetting": "Special Setting",
                "createInsConfig": "Create Instance Setting",
                "creatSpec": "Create Specifications",
                "chosePublic": "Select is_public",
                "choseKey": "Select key",
                "detail": "Flavor Detail",
                "key": "key",
                "choseProject": "Select Project",
                "value": "value",
                "createFlavorExtra": "Create Instance flavor",
                "createPhyMacFlavorExtra": "New Physical Machine Special Settings",
                "modifyFlavorExtra": "Edit Instance flavor",
                "modifyPhyMacFlavorExtra": "Modify Physical Machine Special Settings",
                "choseAvaiableProjects": "Select available Project",
                "inputFlavorName": "Please enter Instance flavor Name",
                "inputPhymacName": "Please enter the name of the physical machine type",
                "disk_read_bytes_sec": "quota:disk_read_bytes_sec",
                "disk_read_iops_sec": "quota:disk_read_iops_sec",
                "disk_write_bytes_sec": "quota:disk_write_bytes_sec",
                "disk_write_iops_sec": "quota:disk_write_iops_sec",
                "vif_inbound_average": "quota:vif_inbound_average",
                "vif_inbound_burst": "quota:vif_inbound_burst",
                "vif_outbound_average": "quota:vif_outbound_average",
                "vif_outbound_burst": "qutoa:vif_outbound_burst",
                "cpu_sockets": "hw:cpu_sockets",
                "cpu_cores": "hw:cpu_cores",
                "cpu_threads": "hw:cpu_threads",
                "mem_max": "Resource:mem_max",
                "cpu_max": "Resource:cpu_max",
                "currentInputValue": "You can input the value:",
                "sockets": "CPU Sockets",
                "cores": "CPU Cores",
                "threads": "CPU Threads",
                "physicalReq_1": "If you need to use the Physical Resource Management Block function, please open System Management > System Settings >",
                "physicalReq_2": "'Interface Added' physical machine management module",
                "functionManagement": "Function Management",
                "noSpecSetting": "No special settings to add",
                "maxLength": "Max Length 32Bit",
                "cpuTopoTips": "The number of CPU sockets, CPU cores and CPU threads multiplied by three values should be greater than or equal to 1 CPU cores less than or equal to the specifications of cloud hosts",
                "cpuPolicy": "CPU Policy",
                "policyShare": "Share",
                "policyPrivate": "Special-purpose",
                "noSpecSetting":"No special settings to add",
                "prefer": "Each VCPU will be bound to different threads of the same physical CPU core as much as possible.",
                "isolate": "Each VCPU will be bound to a different physical CPU core and exclusive of the CPU core. Other VCPUs can no longer be placed on the physical CPU core.",
                "require": "Each VCPU is bound to different threads of the same CPU.",
                "deletePhyFlavor": "Are you sure you want to delete the selected physical machine configuration?",
                "cpuTopu": "CPU Topu",
                "src": {
                    "true": "Local disk",
                    "false": "Cloud hard disk(EBS)",
                    "public": "Currency"
                },
                "ipmiAccessInfo":"IPMI access information"
            },
            "aggregate": {
                "name": "Aggregate Name",
                "availability_zone": "Availability Zone Name",
                "availZone": "Availability Zone",
                "hosts": "Host List",
                "metadata": "Metadata",
                "createAggregate": "Create Instance Collection",
                "inputAggregateName": "Please enter Instance Collection Name",
                "inputAvailabilityzoneName": "Please enter Availability Zone Name",
                "editAggregate": "EditInstance Collection",
                "setMetadata": "Metadata Setting",
                "resetMetadata": "Reset Metadata",
                "manageHost": "Host Management",
                "key": "key",
                "value": "value",
                "choseKeys": "Select key",
                "choseProject": "Select Project",
                "enterValues": "Please enter project ID",
                "input_instanceType": "Please enter Instance Type Name",
                "input_cpu_pen": "Please enter CPU Super fraction ratio",
                "input_mem_pen": "Please enter memory Super fraction ratio",
                "input_number": "Please enter number",
                "metadataInfo": "This aggregates can only be used for this Project, Other Project couldn't create Instance on this, Project id is option",
                "explain": "Illustration:",
                "manageHostInfoOne": "1.Select Instance and Add to Aggregate, or delete from it.One instance can belong to various aggregates 2.Different aggregates can associate to the same Availability Zone, different Availability Zone's instance is unique",
                "manageHostInfoTwo": "2.Different host collections can bind the same available domain, and the hosts between different available domains can not duplicate them.",
                "noHostsTips": "No Availability Zone Select ",
                "choseHosts": "Select available instance",
                "chosenHosts": "Selected instance",
                "deleteAggregate": "Confirm delete selected Instance Aggregate",
                "deleteFlavor": "Confirm delete selected flavor",
                "deleteFlavorExtra": "Confirm delete selected flavor",
                "resetAggregate": "Confirm reset Aggregate Metadata",
                "cannotDelAggregate": "Aggregate is not empty, can't delete ",
                "CPUBinding": "CPU Binding",
                "selfDefined": "Self Defined",
                "selfDefinedKey": "Self Defined Key",
                "selfLengthErrorMsg": "Length less than 128 Bytes",
                "selfKeyPlaceholder": "Input Key Name",
                "selfValuePlaceholder": "Input Key Value",
                "true": "Enable",
                "false": "Disable",
                "noData": "No Data",
                "metaDataInfo": "After configuring this parameter and setting the metadata synchronously in the host set, the cloud host created with this specification will be automatically scheduled by the system to the physical server under the host set meeting the requirements",
                "metaDataInfo1": "Set the virtual machine type in the cloud host specification, and the cloud host meeting the requirements of this specification can be automatically scheduled to the physical server under the host set by the system."
            },
            "storagement": {
                "checkCephUrl": {
                    "success": "Success",
                    "fail": "Fail"
                },
                "inputapiPrivateKey": "Please enter the API access to the private key",
                "inputapiSecretKey": "Please enter the API access secret",
                "apiSecretKey": "API Access Key",
                "apiPrivateKey": "API access private key",
                "pwd": "password",
                "accountNumber": "account",
                "portNumber": "The port number",
                "URLSite": "URL address",
                "leastSelect": "At least one agreement",
                "agreementSelect": "Agreement choice",
                "name": "Name",
                "action": "operate",
                "use": "Storage use",
                "deviceType": "Device Type",
                "allotCapacity": "Allot Capacity",
                "usedCapacity": "Used Capacity",
                "totalCapacity": "Total Capacity",
                "status": "Status",
                "createTime": "CreateTime",
                "description": "Description",
                "objectType": "Types of",
                "objectPoolType": "Whether to share block storage disks",
                "objectPoolShare": "Share",
                "objectPoolNotShare": "Not shared",
                "residualCapacity": "Residual capacity",
                "selectOne": "Please choose a record",
                "addTitle": "Add storage",
                "editTitle": "edit",
                "inputName": "Please enter the storage name",
                "selectUse": "Please choose the use of storage",
                "manufacturer": "Storage vendor",
                "selectManufacturer": "Please select the storage vendor",
                "device": "storage device",
                "selectDevice": "Please select the storage device",
                "storageType": "Storage type",
                "selectType": "Please select the storage type",
                "protocol": "Storage protocol",
                "objectSetting": "Storage settings",
                "objectUser": "Store users",
                "selectProtocol": "Please select the storage protocol",
                "ipAddr": "Managing the IP address",
                "inputIpAddr": "Please enter the managed IP address",
                "ctrl1": "Controller 1",
                "inputCtrl1": "Please enter the IP address of the controller 1",
                "ctrl2": "Controller 2",
                "inputCtrl2": "Please enter the IP address of the controller 2",
                "userName": "User name",
                "inputUserName": "enter one user name",
                "password": "Password",
                "inputPassword": "Please input a password",
                "pool": "Storage pool",
                "poolExist": "The name of the storage pool has already existed",
                "inputPool": "Please fill in the name of the pool for storing cloud platform data in the storage device.",
                "ctrl1Business": "Controller 1 business port",
                "inputCtrl1Business": "Please enter the 1 business port of the controller",
                "ctrl2Business": "Controller 2 business port",
                "inputCtrl2Business": "Please enter the 2 business port of the controller",
                "checkManageAddr": "Authentication management address",
                "interface": "Storage interface",
                "nfsAddr": "NFS server address",
                "inputNfsAddr": "Please enter the NFS server address",
                "checkNfsAddr": "Verify the NFS server",
                "nfsPath": "Path",
                "selectNfsPath": "Please select the path",
                "noNfsPath": "No alternative path",
                "loadmodule": "Enable functionality",
                "erasure": "Correction code",
                "rgwParameter": "parameter",
                "kmTips": "K for data block, M for check block",
                "copyTips": "Object storage copy number",
                "objRgwHelp": "After object storage docking is completed, you can go to system Settings -Ceph PG management to modify the storage pool ratio, and automatically adjust PG.",
                "replicated": "Copy number",
                "rgwObject": "Object storage",
                "inputInterface": "Please enter the name of the underlying storage volume",
                "inputDescription": "Please enter the description information",
                "spaceRatio": "Storage space ratio",
                "inputSpaceRatio": "Scope of application 65-95",
                "configFile": "CEPH configuration file",
                "configFileCheck": "The file format must be.Conf and not more than 1M, and the name of the file cannot contain Chinese and blank space.",
                "privilegeFile": "CEPH permission file",
                "privilegeFileCheck": "The file format must be.Keyring and not more than 1M, and the name of the file cannot contain Chinese and blank space.",
                "inputConfigFile": "Please upload the CEPH configuration file",
                "inputPrivilegefile": "Please upload the CEPH permission file",
                "backupUser": "Backup user",
                "inputBackupUser": "Please enter the backup user name",
                "monitorUrlTips": "Input format is: IP or domain name: http: // ip: port, for example: 'http://127.0.0.1: 1001'",
                "monitorTips1": "1.The verification is successful, and the CEPH monitoring page will be displayed in the monitoring management.",
                "monitorTips2": "2.Verification failed, will not be able to display the Ceph monitoring page, you can store it normally.",
                "monitorTips3": "3.If you do not click on the verification, you do not open the monitor.",
                "backupPool": "Backup pool",
                "inputBackupPool": "Please enter the backup pool",
                "addBackupSuccess": "Add storage success",
                "addBackupFailed": "Add storage failure",
                "deleteTip": "Do you delete the selected content?",
                "storageAddr": "Storage address",
                "addstorageMessage": "Add storage needs to restart the storage service, which is expected to be 5-10 minutes long, affecting the cloud platform storage related operations, but does not affect the existing cloud host to provide the normal service, please wait.",
                "delstorageMessage": "Delete storage needs to restart the storage service, which affects the operation of the cloud platform storage, but does not affect the existing service of the existing cloud host. Please wait.",
                "detail": "Storage detail",
                "notSupportMonitor": "Monitoring is not supported when the J version is CEPH",
                "objectInner": "internal",
                "objectExternal": "External",
                "selectNode": "Select node",
                "nodeName": "Node name",
                "diskName": "Hard disk name",
                "diskType": "Hard disk type",
                "diskCapacity": "capacity",
                "localVolume": "Local volume",
                "placeholder": {
                    "erasureCode": "Please select the erasure code",
                    "rgwCopy": "Please select copy number",
                    "rgwParameter": "Please select parameters",
                    "objectType": "Please choose the type"
                },
                "table": {
                    "state": {
                        "up": "available",
                        "down": "Unavailable"
                    }
                },
                "storageEnumeration": {
                    "ruijie": "ruijie",
                    "toyou": "toyou",
                    "nfs": "NFS",
                    "iscsi": "ISCSI",
                    "fc": "FC",
                    "ceph": "ceph",
                    "out_ceph": "out_ceph",
                    "default": "default storage",
                    "insideCeph": "inside ceph",
                    "outsideCeph": "outside ceph",
                    "file_CSP": "CSP file storage",
                    "object_CSP": "CSP object storage",
                    "object_swift": "SWIFT object storage",
                    "object_ceph_inner": "Ceph object storage",
                    "local_disk": "Local Disk"
                },
                "storagePool": {
                    "add": "Add Storage Pool",
                    "name": "Pool Name",
                    "enter": "Please input",
                    "required": "Required",
                    "required_1": "Names begin with letters and consist of numbers and letters, ranging in length from 4 to 32 bits.",
                    "copies": "Copies",
                    "selectCopies": "Select Copies",
                    "select": "Selected"
                },
                "storageTranslate": {
                    "iscisServerIp": "ISCSI server IP",
                    "iscisPort": "ISCSI port",
                    "enterIscisPort": "Please enter the port",
                    "authorization": "Configuring authorization information",
                    "iscisChap": "ISCSI server validates the cloud computing server (CHAP)",
                    "iscisInformation": "The user name and password below are used to authenticated account information when connecting the ISCSI server to the device. The account is provided by the ISCSI server, and only local connections are available.",
                    "iscisServerChap": "Cloud computing server validates ISCSI server (bidirectional CHAP)",
                    "iscisConnect": "The following user name and password are used to authenticated account information when the ISCSI server connects to this device. The account information provided by this device can be connected to ISCSI only.",
                    "replicas": "Number of stored replicas",
                    "selectReplicas": "Please select the number of storage replicas",
                    "compatible": "Your current CEPH client version is 10.2.7. Please confirm that the CEPH server version is compatible with this version.",
                    "storageUser": "Storage user",
                    "invalidMessage": "Disk configuration is not in compliance. Are you sure you want to continue this operation?",
                    "diffNodeSetting": "The metadata disk of the existing node is inconsistent with the data disk mapping relationship. Do you determine how to do this?",
                    "enterStorageUser": "Please enter the name of the storage user",
                    "limitModifyNetwork": "Two node data center is not allowed to modify the storage network",
                    "enterNetworkConfig": "Down network configuration",
                    "resetIp": "Reset IP",
                    "bcacheMsg": {
                        "tips1": "1.Cache Acceleration only takes effect on a single storage resource pool.",
                        "tips2": "2.To ensure performance, it is recommended that 4 or 4 blocks correspond to 1 SSD cache accelerator, and the capacity of the cache accelerator is not less than 25% of the total capacity.",
                        "tips3": "3.In order to ensure performance, it is recommended that 8 or 8 pieces correspond to 1 NVME cache accelerator.",
                        "tips4": "4.To ensure performance, it is recommended that each OSD (including expansion) PG Number does not exceed 200."
                    },
                    "editArea": "Editing area",
                    "nouseDisk": "Available disk",
                    "sourceDisk": "Metadata disk",
                    "dataDisk": "Data disk",
                    "localDisk": "Local area",
                    "missDisk": "Lost hard disk",
                    "bcacheSourceDisk": "Cache disk / metadata disk",
                    "openbcache": "Open cache acceleration",
                    "openbcacheTip": "After opening, the cache acceleration is shared with the metadata disc.",
                    "storageVlan": "The storage network VLAN and the tenant network VLAN",
                    "conflict": "conflict",
                    "threecopy": "Please complete disk configuration for at least three hosts",
                    "numcopy": "Please complete disk configuration for at least {{num}} nodes",
                    "storageNet": "Storage network",
                    "checkKernel": "Node {{node}} The kernel version does not support turning cache acceleration",
                    "setMap":"When the cache acceleration is turned on, the node {node}} needs to configure the buffer disk / metadata disk.",
                    "vlanRange": "The VLAN range is 2-4094",
                    "notWithin": "The IP address is not within the CIDR range",
                    "ipRange": "The end of the IP address must be larger than the starting IP address",
                    "buttAllowed": "IP class storage has been butted, and the modified storage network is not allowed",
                    "dragDiskError": {
                        "sourceSsd": "Select SSD type disk as metadata disk",
                        "sourceOnly": "You can only have one metadata disk.",
                        "datahasSsd": "When a data disk has AN SSD disk, the metadata disk is not configurable",
                        "nodatadisk": "Data disk cannot be empty",
                        "dataSddHdd": "Data disks can only be fully configured on SSD disks or HDD disks",
                        "invalidMaxDiskNumSize": "Data disk and metadata disk quantity allocation ratio exceeds 4:1, metadata disk capacity is less than 1% of total data disk capacity.",
                        "invalidMaxDiskNumSizeRgw": "The number of data discs and metadata discs is more than 4: 1, and the metadata disk capacity is less than the total data disk capacity of 4%.",
                        "invalidMaxDiskNum": "Data disk and metadata disk number allocation ratio exceeds 4:1",
                        "invalidMaxDiskSize": "Metadata disk capacity less than 1% of total data Disk capacity",
                        "invalidMaxDiskSizeRgw": "The metadata disk capacity is less than the total data disk capacity of 4%",
                        "nodatadiskMsg": "Please make sure the data disk is not empty",
                        "sourceDiskRequire": "Current Storage has enabled BCache, configure the buffer disk / metadata disk",
                        "nosourcediskMsg": "Please make sure the buffer disk / metadata is not empty"
                    },
                    "netConfig": {
                        "diskcon": "Disk configuration",
                        "verifyStorage": "Verifying storage connectivity",
                        "datacenter": "Data center",
                        "cardcon": "Network card configuration",
                        "host": "host name",
                        "manage": "Management network",
                        "cpu": "CPU(Core)",
                        "mem": "Memory(G)",
                        "mountFc": "FC storage has been mounted",
                        "findTarget": "Discovery target (target)",
                        "iscisiDevice": "Log in and confirm the ISCSI storage device",
                        "targetIqn": "Target iqn information",
                        "targetStatus": "Finding the state of the target",
                        "success": "success",
                        "fail": "fail",
                        "unfindTarget": "Undetected target",
                        "selectStoDevice": "Select the ISCSI storage device",
                        "cluster": "Cluster network",
                        "manage":"Management network",
                        "storage": "Storage network",
                        "tenant": "Tenant network",
                        "business": "Business network",
                        "waitThree": "Please wait later, the storage network connectivity test is expected to take 3 minutes.",
                        "deletection": "Storage Unicom detection task failed to execute!",
                        "startDetection": "Start detection",
                        "nonetCard": "No network card",
                        "close": "Close",
                        "fcOperate": "Please make sure that the FC storage pool is not empty, and then operate it again!",
                        "emptyOperate": "Please make sure that the data disk is not empty, and then operate it again!",
                        "hardConfig": "Hard disk configuration node",
                        "hardDisk": "Hard disk",
                        "diskName": "Hard disk name",
                        "status": "state",
                        "health": "Healthy",
                        "unHealth": "Unhealthy",
                        "diskType": "Hard disk type",
                        "diskCapicty": "Hard disk capacity",
                        "logDisk": "Log disk",
                        "drop": "Drag and drop area",
                        "dataDisk": "Data disk",
                        "storagePool": "FC storage pool",
                        "save": "save",
                        "cancel": "cancel"
                    },
                    "kernelNotSupport": "Node kernel version does not support BCache",
                    "object_ceph": "Ceph super fusion",
                    "poolStatusMap": {
                        "0": "Block storage resource pool",
                        "1": "Backup resource pool",
                        "2": "Object storage resource pool",
                        "3": "File storage resource pool",
                        "4": "Local disk resource pool"
                    },
                    "cephVersionTips": "Your current CEPH client version is 10.2.7. Make sure that the CEPH server version is compatible with this version.",
                    "verification": "Verification",
                    "limitSize": "For data security, the actual capacity is 90% of the total capacity.",
                    "linkIscis": "Connect iSCSI storage",
                    "configHost": "Configure the host",
                    "butt": "Butt storage",
                    "loading": "Data loading",
                    "available": "Actually available",
                    "sendProgress": "Deployment schedule",
                    "instancePool": "Cloud host storage pool",
                    "instanceBackupsPool": "Cloud host backup resource pool",
                    "deleteSuccess": "Delete storage success",
                    "addSuccess": "Add storage success",
                    "deleteFail": "Delete storage failure",
                    "addFail": "Add storage failure",
                    "storageSuccessEnd": "Successful operation",
                    "storageFailEnd": "operation failed",
                    "usingLimitEdit": "Storage is in use, not allowed to edit",
                    "usingLimitDelete": "Storage is in use, and it is not allowed to delete",
                    "imageLimitDelete": "The storage is used as the back end of the mirror library and is not allowed to be deleted",
                    "transferImage": "The storage is undergoing image migration and is not allowed to be deleted",
                    "transferFailed": "The storage mirror migration failed and could not be deleted for the time being.",
                    "customDelete": "Custom storage is deleted in the background according to the document",
                    "clusterLimitOperation": "The current cluster state does not allow this operation",
                    "manangeAddressConnected": "Managed address has been connected",
                    "manangeAddressUnconnected": "Managed address is not connected",
                    "nfsTips": "Please complete the NFS server validation first and select the path",
                    "manageIpCheck": "Please complete the management IP address validation first",
                    "sendNetworkConfig": "The storage network has been modified, please send the network configuration",
                    "findTargetFail": "Finding failure of the target",
                    "iscsiTips": "Log in and confirm that the ISCSI storage device failed. Please try again later.",
                    "completeDiskConfig": "Please complete the disk configuration of each host first",
                    "iscisEquipment": "Please complete the ISCSI storage device confirmation first",
                    "iscisReselect": "The currently selected ISCSI storage is inconsistent, please reselect",
                    "selectSameSize": "Please select the same size storage device",
                    "fcTips": "The currently selected FC storage is inconsistent, please reselect",
                    "uploadConfigFail": "Upload configuration file failed",
                    "configSuccess": "Configuration success",
                    "configFail": "Configuration failure",
                    "sendingNetConfig": "Do not close the window in the network configuration"
                },
                "localDiskDetailTable": {
                    "status": {
                        "all": "all",
                        "available": "Local disk",
                        "nov": "Local disk",
                        "failed": "Add local disk failure",
                        "creating": "Add to local disks"
                    },
                    "isExist": {
                        "0": "without",
                        "1": "have"
                    }
                }
            },
            "hypervisor": {
                "netName": "Network Name",
                "subnetMask": "Subnet Mask",
                "vlanRange": "VLAN Range",
                "vxlanRange": "VXLAN Range",
                "vmHA": "Instance HA",
                "hypervisorHostname": "Physical Host Name",
                "type": "Hypervisor Type",
                "virtualCPU": "Virtual CPU sum",
                "virtualUsedCPU": "Virtual CPU in-use",
                "localMemory": "Memmory sum（GB）",
                "localMemoryUsed": "Memmory in-use（GB）",
                "freeRam": "Free Ram（GB）",
                "localDisk": "Disk Sum（GB）",
                "localDiskUsed": "Disk in-use（GB）",
                "freeDisk": "Free Disk（GB）",
                "currentWorkload": "Current Workload",
                "runningVM": "Instance (running)",
                "leastDiskAvailable": "Min available Disk（GB）"
            },
            "availZone": {
                "zoneName": "Availability Zone Name",
                "hosts": "Hosts",
                "available": "Available"
            },
            "accesspolicy": {
                "name": " Name",
                "type": "Type",
                "ipAddr": "IP Address",
                "ipInputType": "IP Input Type",
                "range": " Range",
                "startIP": "Start IP",
                "endIP": "End IP",
                "enabled": "Enabled",
                "createTime": "Create time",
                "description": "Description",
                "inputPolicyname": "Please enter Policy Name",
                "inputWhiteListName": "Please enter White List Name",
                "inputBlackListName": "Please enter Black List Name",
                "inputStartIp": "Please enter start IP",
                "inputEndIp": "Please enter end IP",
                "ipAddrPlaceholder": "Can enter multiple IP, separated by comma",
                "moreIpTip": "Multiple IPs separated by comma",
                "policyType": "Policy Type",
                "whiteList": "White List",
                "blackList": "Black List",
                "createAccessPolicy": "Create Access Policy",
                "editAccessPolicy": "Edit Access Policy",
                "delWbListIps": "Confirm delete Selected Access Policy？",
                "true": "yes",
                "false": "no",
                "ipRepeat": "Ip repeat,please try again"
            },
            "mailServer": {
                "name": "Name",
                "paramValue": "Parameter Value Input/Operation",
                "desc": "Description",
                "server": "Server",
                "port": "Port",
                "account": "Account",
                "password": "Password",
                "safeConnection": "Safe Connection",
                "safeConnectionTips": "The SSL protocol will be used to encrypt messages as they are sent to ensure that messages are not intercepted or eavesdropped as they travel over the network.",
                "sender": "Sender",
                "inputServer": "Please enter the email server",
                "inputAccount": "Please enter your email account",
                "inputEmeit": "please input your email",
                "inputPassword": "Please enter your email password",
                "inputSender": "Please enter the sender",
                "tips": "After configuring the mail server, the cloud platform can send alerts and other system information to the user through the server",
                "verifyServer": "Whether to verify the Mail server",
                "sendVerifyInfo": "Send validation information",
                "verifySuccess": "Verified successful",
                "verifyFail": "Verification failed"
            },
            "weChatAlarm": {
                "appId": "ID",
                "inputAppId": "Please enter the AppID",
                "appIdTips": "The developer ID and the developer password used by the administrator to register the public account can be viewed in the background of WeChat public account",
                "appSecret": "Password",
                "inputAppSecret": "Please enter the password",
                "url": "URL",
                "inputUrl": "Please enter the url",
                "token": "Token",
                "inputToken": "Please enter the Token",
                "key": "Key",
                "inputKey": "Please enter the key",
                "way": "Encryption Way",
                "inputWay": "Please choose Encryption way",
                "QRCode": "QR Code",
                "plain": "Plaintext",
                "safe": "Safe",
                "compatible": "Compatible",
                "active": "Active",
                "activeTips": "After turning on the activate switch, when setting the contact group, bind WeChat to pop up the qr code of the public number and provide it to the user to scan and follow.",
                "getQRCode": "Get the current WeChat QR code",
                "tips": "After setting up WeChat, cloud platform can send alarm and other system information to the user through the port in WeChat",
                "desc1": "1. This function supports the developer ID and developer password of WeChat service number and WeChat test number.",
                "desc2": "2. If you fill in the ID and password of the test number, the received alarm message will be displayed in the WeChat subscription number, and there will be some mobile phones receiving messages without a prompt sound.",
                "saveSuccess": "WeChat set successfully"
            },
            "networksManage": {
                "interfaceName": "Interface Name",
                "add": "Add",
                "delete": "Delete",
                "insTenantPhyNet": "Instance Available Tenant Physical Networks",
                "insExternalPhyNet": "Instance Available External Physical Networks",
                "phyHostPhyNet": "Physical Machine Available Physical Networks",
                "netInUse": "The used network can't be deleted",
                "noAvailableNet": "No available networks",
                "placeholder": {
                    "interface": "Please select external physical networks",
                    "noInsExternalPhyNet": "No available external physical"
                }
            },
            "modifyPassword": {
                "username": "User Name",
                "oldPassword": "Old Password",
                "newPassword": "New Password",
                "confirmNewPassword": "Confirm Newpassword",
                "oldPasswordEmpty": "Old password can't be empty",
                "oldPasswordError": "Old password input error",
                "newPasswordEmpty": "New password can't be empty",
                "newPasswordDiffer": "New password input differ",
                "modifyPasswordFail": "Modify password failed",
                "special_8_16": "Password length is between 8-16 characters,at least one capital letter,one lower letter,one number and one special character.Special character includes !-@.^_%,:/",
                "pleaseInputOldPassword": "Please input Old Password",
                "pleaseInputNewPassword": "Please input New Password",
                "pleaseInputNewPasswordAgain": "Please input New Password Again",
                "modifyFrequent": "Modify so frequently，please try again after two minutes",
                "modifying": "Validating and Modifying",
                "modifySuccess": "Modify successfully，please login again",
                "firstStep": "Please fill in the original password first",
                "equalCheck": "The new password is the same as the original one",
                "newUserModifyPwd": "New User Modify Password",
                "pwdExpiredModifyPwd": "Password Expired Modify Password",
                "newUserToModifyPwd": "New user must modify the password",
                "modifyPwdSuccess": "Modify password success,please relogin"
            },
            "systemInspection": {
                "term": "Item",
                "startExamination": "Start the inspection",
                "pauseExamination": "Stop patrol inspection",
                "stop": "Termination",
                "examinationing": "On patrol",
                "stopExaminationing": "Stop inspection in progress",
                "exportReport": "Export patrol Report",
                "inspectionItem": "Patrol Item",
                "expectedResults": "Expected results",
                "actualResults": "Actual results",
                "status": "State",
                "inspectionResultStatistics": "Statistics of Inspection Results",
                "inspectionTime": "Inspection time",
                "inspectionItem":"Inspection item",
                "normal": "Normal",
                "abnormal": "Abnormal",
                "abnormalInspectionResults": "Abnormal Statistics of Patrol Results",
                "inspectionResults": "Inspection Results",
                "NORMAL": "Normal",
                "EXCEPTION": "Abnormal",
                "FAILURE": "Failure",
                "INAPPLICABLE": "Not applicable",
                "millisecond": "Ms",
                "second": "Seconds",
                "minute": "Minutes",
                "hour": "Hour",
                "day": "Day",
                "checkFail": "Failed inspection",
                "inspectionType": "Patrol Type",
                "errorDetails": "Abnormal Particulars"
            },
            "objectSetting": {
                "name": "Name",
                "accessKey": "access Key",
                "secretKey": "secret Key",
                "endpoint": "endpoint",
                "signerVersion": "signerVersion",
                "type": "type",
                "user": "user",
                "tips": "After configuring the object store, you can manipulate the object store",
                "source": "User Type",
                "departmentName": "Department",
                "projectName": "Project",
                "innerUser": "Internal User",
                "externalUser": "External User",
                "placeholder": {
                    "name": "Please enter name",
                    "department": "Please select department",
                    "project": "Please select an item",
                    "userSelect": "Please select user",
                    "user": "Please enter user",
                    "accessKey": "Please enter the access key",
                    "secretKey": "Please enter your private key",
                    "endpoint": "Please enter the endpoint",
                    "signerVersion": "Please select the signed version",
                    "accessKeySelect": "Please select an access key"
                },
                "delTip": "Are you sure to delete the selection?",
                "userSelectTip": "Please go to the object storage user newly built users."
            },
            "adServer": {
                "serverPath": "LDAP Server Access Path",
                "adminAccount": "Administrator Account",
                "passwd": "Password",
                "enable": "Enable",
                "header": "Current configuration information is not validated when inactivated",
                "tips1": "Please enter the LADP server access path",
                "tips2": "Please enter up to 100 bits",
                "tips3": "Please enter Administrator's Account",
                "tips4": "Please Password",
                "tips5": "Please enter up to 50 bits",
                "tips6": "Successful connection to AD domain",
                "tips7": "Connection to AD domain failed. Check the current configuration",
                "tips8": "Turning off AD will cause user missing and user unable to log in, is determined to turn off?",
                "tips9": "Turn on the AD is irreversible, please confirm if it is turned on?",
                "tips10": "Please enter an organizational unit / container",
                "orgUnit": "Organizational unit / container"
            },
            "objectUser": {
                "userName": "User Name",
                "sizeQuota": "Total Capacity Quota (KB)",
                "bucketQuota": "Total Storage Bucket Quota (one)",
                "objectQuota": "Total number of Objects quota (s)",
                "noLimit": "Unrestricted",
                "sizeQuotaTip": "Maximum user capacity, -1 unlimited",
                "bucketQuotaTip": "Maximum number of user buckets created",
                "objectQuotaTip": "Maximum number of objects stored by user, -1 unlimited",
                "adminDeleteTip": "Default user cannot delete",
                "createUser": "New User",
                "editUser": "Edit User",
                "deleteTip": "Are you sure to delete the selection?",
                "userDetail": "User Details",
                "accountInfo": "Account Information",
                "accessKeyManage": "Object Storage Key Management",
                "userDetail": "User details",
                "accessKey": "Access Key",
                "secretKey": "Access private Key"
            },
            "sysBackup": {
                "onekeyBackup": "One-key backup",
                "backupName": "Backup Name",
                "backupType": "Backup Type",
                "status": "State",
                "currentBackupTime": "Current Backup Time",
                "nextBackupTime": "Next backup time",
                "issuing": "In the middle",
                "issued": "Issued",
                "received": "Received",
                "backuping": "In backup",
                "success": "Success",
                "fail": "Failure",
                "sendFailed": "Send failed"
            },
            "cephpg": {
                "tips": "The sum of the proportional values of the storage pool must be 100%",
                "poolName": "Storage Pool Name",
                "ratioValue": "Proportional value",
                "desc": "Description",
                "rgw_root": "Object storage system pool",
                "rgw_root_des": "Used to store object storage metadata and bucket index information",
                "vms": "Cloud hosting",
                "vms_des": "Used to store cloud host Config Drive information",
                "volumes": "Cloud drive",
                "volumes_des": "Used to store cloud disks",
                "images": "mirror image",
                "images_des": "Used to store images for creating cloud hosts",
                "cloud_backups": "Backing up cloud platform resources",
                "cloud_backups_des": "Used to store cloud platform backups",
                "rgw": "Object Storage Pool",
                "rgw_des": "Used to store object storage data",
                "placeholder": {
                    "rgw_root": "Enter the proportion of the object storage system pool",
                    "vms": "Enter the cloud host ratio",
                    "volumes": "Enter the ratio of cloud disks",
                    "images": "Please enter the mirror ratio value",
                    "cloud_backups": "Enter the backup ratio of cloud platform resources",
                    "rgw": "Enter the proportion of the object storage pool"
                },
                "cephpgLoading": "The storage pool proportion is being adjusted. Procedure",
                "cephpgSuccess": "The storage pool proportion is successfully adjusted. Procedure",
                "cephpgFailed": "Description Failed to adjust the storage pool proportion"
            }
        },
        "register": {
            "reg": {
                "haveAccount": "I have Login Account",
                "backlogin": "Back to Login",
                "register": "Register",
                "EAccount": "Enterprise Account",
                "inputaccount": "Please enter Enterprise Account",
                "required": "Required",
                "char4_16": "Please enter 4-16 characters, allow English letters, numbers and underscores",
                "char4_16_cn": "Please enter 4-16 characters, allow numbers, letters, underscores, Chinese",
                "accountexit": "Account existed",
                "EName": "Enterprise  Name",
                "ENamesetting": "Enterprise  Name settings",
                "email": "Email",
                "emailSet": "Email settings",
                "emailtest": "Please enter the right email format, eg:example@mycompany.com",
                "tel": "Telephone",
                "telinput": "+86 Please enter  phone number",
                "formaterror": "Format is incorrect",
                "passwd": "Login Password",
                "passwdSet": "Password Setting",
                "passwd6": "Password at least 6 characters",
                "passwd16": "Password no more than 16 characters",
                "confirmPasswd": "Confirm Password",
                "inputpasswdagain": "Enter Password again",
                "passwddiff": "Passwords are not consistent",
                "confirm": "confirm",
                "servererror": "server error",
                "succeed": "succeed",
                "loginname": "Your Login name is",
                "regionKey": "Your regionKey is",
                "backloginpage": "Back to login page"
            },
            "comp": {
                "progress": "30% Complete",
                "ceph": "Distributed storage",
                "keystone": "Identity authentication",
                "glance": "Mirror image",
                "cinder": "Block storage",
                "nova": "Computing",
                "neutron": "Network",
                "failed": "Failed deployment",
                "playing": "In process of installation",
                "restart": "Redeploy",
                "detail": "View Configuration Details",
                "reallyRestart": "Are you sure you want to redeploy?",
                "tosass": "To the control center",
                "success": "Cloud Environment Deployed Successfully",
                "successAll": "Your private cloud environment has been deployed and you are now ready to go to the control center for operations.",
                "start": "Your cloud environment is already being created",
                "decr": "The installation process may be affected by your network environment, device environment appears slow installation situation, please wait patiently!"
            },
            "info": {
                "core":"core",
                "hostChoice": "Select Host",
                "netSetting": "Network Setting",
                "accSetting": "Account Setting",
                "infoSummary": "Info Summary",
                "hostName": "Host Name",
                "area": "area",
                "ManagementNetwork": "Management Network",
                "cpu": "CPU(Core)",
                "mem": "Memory(GB）",
                "next": "Next Step",
                "iprange": "IP Range",
                "clusternetwork": "Cluster Network",
                "start": "start",
                "iperrorformat": "IP Format is incorrect",
                "ipnotnone": "IP can not be empty",
                "end": "end",
                "vlan": "VLAN",
                "vlanstart": "Starting a VLAN",
                "vlanend": "The end of the VLAN.",
                "addRangeIp": "Add IP Range",
                "netmask": "Netmask",
                "inputint": "Please enter positive integer",
                "Storagenetwork": "Storage Network",
                "TenantNetwork": "Tenant Network",
                "required": "Required",
                "extNetwork": "External Network",
                "last": "Last Step",
                "Administrator": "Administrator",
                "char4_16": "Please enter 4-16 characters, allow English letters, numbers and underscores",
                "special_8_16": "Password length is between 8-16 characters,at least one capital letter,one lower letter,one number and one special character.Special character includes !-@.^_%,:/",
                "accountexit": "Account existed",
                "passwd": "Password",
                "passwd6": "Password at least 6 characters ",
                "passwd16": "Password no more than16 characters ",
                "confirm": "Confirm Password",
                "passwddiff": "Passwords are not consistent",
                "type": "Type",
                "IPaddresses": "IP Address",
                "vlantag": "VLAN Tag",
                "areaConf": "Area Setting",
                "EAdministrator": "Enterprise Administrator",
                "account": "Account",
                "deployment": "start deployment",
                "floatingip": "Floating IP",
                "floatinggateway": "Gateway",
                "cidr": "CIDR",
                "cluster": "Cluster network",
                "storage": "Storage network",
                "tenant": "Tenant network",
                "floating": "The network",
                "rangeTotal": "Insufficient NUMBER of IP addresses",
                "rangeSame": "Duplicate IP address range"
                    /*20170223*/
                    //"copyright":window.SETTING.COPYRIGHT
            },
            "login": {
                "Eguestlogin": "Enterprise customer Login",
                "account": "Enterprise Account",
                "user": "Enterprise  Name",
                "required": "Required",
                "passwd": "Password",
                "login": "Login",
                "userorpasswderror": "User name or Password is invalid",
                "servererror": "Server error",
                "forgetpasswd": "Forget Password",
                "aws_account": "Don't have AWSTACKAccount yet？",
                "register": "Registe Now"
            }
        },
        "overview": {
            "pageMsg":"{{page}} article/page",
            "expireMsg":"Cloud platform (data center: {{DC}}) Activation validity period {{sky}} days remaining (License expiration time: 4:00pm on the last day)  ",
            "cluster": "cluster",
            "tenant": "tanent",
            "storage": "storage",
            "floating": "public",
            "datacluster": "Data center",
            "dataclusterOverview": "Data center overview",
            "situation": "situation",
            "virtualServer": "virtual Server",
            "physicalHost": "Physical machine",
            "getdatafail": "The data center data can not be obtained",
            "normal": "normal",
            "localdisk": "local disk",
            "error": "error",
            "run": "run",
            "poweroff": "power off",
            "falut": "falut",
            "other": "other",
            "unit": "unit",
            "name": "name",
            "total": "total",
            "label": "label",
            "memrate": "Memory usage(GB)",
            "storagerate": "Storage usage(GB)",
            "iprate": "Public network IP usage rate(Individual)",
            "externalip": "Public network IP",
            "cpurate": "CPU usage rate(Core)",
            "nodata": "No data",
            "totalCore": "total core",
            "alarmNews": "Alarm information",
            "individual": "individual",
            "welcome": "Welcome",
            "admin": "Admin",
            "domain": "Domain",
            "project": "Project",
            "network": "Network",
            "phyHost": "Physical Host",
            "totalNumber": "Total Number",
            "hostName": "Host Name",
            "vmHostNumber": "Instance Number",
            "vmHost": "Instance",
            "domainVmhostQuota": "Domain InstanceQuota",
            "cpuUsageRate": "CPU Usage Rate",
            "totalCores": "Total Core Number",
            "memoryUsageRate": "Memory Usage Rate",
            "storagesUsageRate": "Storage Usage",
            "statedistribution": "Cloud Host Status",
            "createinstance": "New Cloud Host",
            "createdisk": "New Cloud Drive",
            "createuser": "New User",
            "createnetwork": "Create Network",
            "createvpn": "VPN",
            "totalMemory": "Total Memory",
            "storageUsageRate": "Storage Usage Rate",
            "alarmEvent": "Alarm Event",
            "viewMore": "View More",
            "noAlarmEvent": "No Alarm Event",
            "ticketsInfo": "Ticket Info",
            "noTickets": "no Ticket",
            "noLogs": "no Logs",
            "logsInfo": "Logs Info",
            "departList": "Department List",
            "RDdepart": "R&D Department",
            "allPros": "All projects",
            "todo": "todo",
            "Alarms": "Alarms",
            "privateAlarms": "Private Alarms",
            "PublicAlarms": "Public Alarms",
            "physicalNum": "Physical Host Number",
            "Tai": "(台)",
            "physicalMem": "Physical Host Memory",
            "memOverview": "Memory Overview",
            "cpu": "CPUOverview",
            "publicRes": "Public Resource",
            "addpublicRes": "Add Public Resource",
            "please": "please",
            "overview": "Overview",
            "registed": "Registered",
            "unRegisted": "Unregistered",
            "allocated": "Allocated",
            "unAllocated": "Unallocated",
            "inUsed": "Used",
            "unUsed": "Unused",
            "remind_front": "The platform is valid from the service.",
            "remind_rear": "Day, please renew the period as soon as possible.",
            "recycleBin": "recycleBin",
            "includesRecycle": "includesRecycle",
            "networkNumber":"Network number",
            "routerNumber":"routerNumber"
        },
        "log": {
            "daily": "Option Log",
            "keyword": "keyword",
            "start_at": "start time",
            "end_at": "end time",
            "search": "Search",
            "type": "Type",
            "oprator": "Operator",
            "detail": "Operate Detail",
            "status": "Status",
            "success": "Success",
            "others": "Others",
            "time": "Operate time",
            "fontPage": "Font Page",
            "endPage": "End Page",
            "export": "Export",
            "logLevel": "Log Level",
            "exportCurrentPage": "Export Current Page",
            "exportAllPage": "Export All Page",
            "loginIp": "IP",
            "selectStatus": "Please select Status",
            "selectKeywordtype": "Select search type",
            "mac": "Operating source MAC",
            "sourcetype": "Resource Type"
        },
        "loginlogs": {
            "user": "Login User",
            "username": "User Name",
            "ip": "Login source IP",
            "option": "Operation Type",
            "result": "Operation Result",
            "detail": "Operation Details",
            "mac": "Login source MAC",
            "time": "Operating time",
            "hostname": "Login source host name",
            "loginresult": "Login Results",
            "loginintime": "Login time",
            "loginouttime": "Exit time",
            "logintime": "Login Hours",
            "loginoutway": "Exit mode",
            "originIP": "Source IP",
            "originMac": "MAC address",
            "host": "Host Address",
            "allstatus": "All states",
            "success": "Success",
            "fail": "Failure",
            "activelyExit":"Actively exit",
            "sessionExpire":"Conversation expires, forced exit",
            "userIsLocked":"Users are locked, forcibly exiting",
            "singleTerminalLogin":"Single terminal login (second terminal is online), the current terminal is forced to exit",
            "browserClose":"The browser is turned off, forced exit",
            "second":"Second",
            "minute":"Minute",
            "hour":"Hour"
        },
        "operatelogs": {
            "ip": "Operation source IP",
            "mac": "Operating source MAC"
        },
        "auth": {
            "noPermissions": "No Permissions",
            "account": "Enterprise Account",
            "user": "User name",
            "inputusername": "Please enter User name",
            "passwd": "Password",
            "inputpasswd": "Please enter Password",
            "userorpasswderror": "User name or Password error",
            "servererror": "Server error, please contact administrator",
            "ipOut": "Have no permission",
            "noProject": "Have no permission, please contact administrator",
            "login": "Login",
            "forgetpasswd": "Forget Password",
            "aws_account": "Don't have an AWSTACKAccount yet？",
            "register": "Registe Now",
            "admin": "Administrator Account",
            "enterpriseName": "Enterprise Name",
            "inputEnterpriseName": "Please enter Enterprise Name",
            "enterpriseNameRequired": "Please enter Enterprise name",
            "verificationCode": "Verification Code",
            "inputVerificationCode": "Please enter Verification Code",
            "loginOverTimes": "Login errors over",
            "pleaseWait": "times,please wait",
            "minuteTryAgain": "minutes to login again",
            "tryAgin": "Try login again",
            "repeatLogin": "Account had logined",
            "sysUpdate": "platform updating,please wait",
            "codeInvalid": "The verification code has failed. Please input it again",
            "codeError": "The Verification code is wrong. Please input it again",
            "userNoRole": "The user does not exist or is not bound to a role",
            "userForbidden": "user was disabled",
            "hadLoginFailed": "You had logined failed for ",
            "times": "times",
            "left": "left",
            "userWillLocked": "The user will be locked for",
            "second": "seconds",
            "minute": "minutes",
            "pwdExpired": "Your password is used for",
            "day": "days,",
            "updatePwd": "please update the password in time",
            "loginAgain": "The user logined in other places, and you have dropped out!",
            "userLocked": "This user is locked",
            "email": "Email",
            "inputemail": "Please enter email address",
            "getEmailLoginCode": "Get captcha",
            "accountLogin": "Account Login",
            "emailLogin": "Email Login",
            "enterpriseNameOrEmailError": "Incorrect business name or email address",
            "emailCodeError": "Verification code error",
            "switchAccountLogin": "Switch account login",
            "switchEmailLogin": "Switch Mailbox login",
            "registraInit":"Initialization after registration"
        },
        /*20170223*/
        "reg": {
            //"title": "AWSTACK",
            //"copyright": "Copyright © 2012-2017 北京海云捷迅科技有限公司京ICP备14031291号 《中华人民共和国增值电信业务经营许可证》编号: 京B2-20140382"
        },
        "qcloud": {
            "instances": {
                "intranetmsg":"Please select private network",
                "specificationMsg":"Load balancing resources occupy user resources and quotas. The default disk size is 20 GB",
                "networkMsg":"To use the external network to communicate with the cloud platform, ensure that the external network is properly connected to the cloud platform, and specify an IP address.  ",
                "assign":"assign",
                "recordMigration": "Migration record",
                "instance": "Tencent Instance",
                "new": "Create ",
                "boot": "boot ",
                "stop": "stop",
                "moreOps": "More",
                "restart": "restart",
                "forceShupdown": "Force Shutdown",
                "del_instance": "delete Instance",
                "attachVolume": "Attach Volume",
                "edit": "Edit",
                "addNIC": "Add NIC",
                "moveNIC": "Move NIC",
                "bindingPublicIP": " associateFloating IP",
                "releasingPublicIPBindings": "Disassociate floating IP",
                "makeImg": "Create Images",
                "search": "Search",
                "projectName": "Project Name",
                "instanceName": "Instance Name",
                "imgName": "images Name",
                "privateIP": "Private IP",
                "publicIP": "Floating IP",
                "status": "Status",
                "create_at": "Create  time",
                "bootMethod": "Boot Method",
                "cloudInstanceDetail": "Instance Detail",
                "cloudInstanceName": "Instance Name",
                "image": "images",
                "configration": "configration",
                "NIC": "NIC",
                "volume": "Volume",
                "firewall": "Firewall",
                "headerCheckbox": {},
                "os": "Operation System",
                "cvmPayMode": "Payment Mode",
                "diskInfo": "Disk Info",
                "deviceClass": "Device Class",
                "addinstances": {
                    "snapshot": " Snapshots",
                    "newInstance": "Create Instance",
                    "detail": "Detail",
                    "instanceName": "Instance Name",
                    "instanceNameInput": "Please enter Instance Name",
                    "required": "Required",
                    "num": " Number",
                    "imgChoice": "Select images",
                    "os": "Operation System",
                    "arch": "Architecture",
                    "imgNameChoice": "Select images Name",
                    "type": "Type",
                    "conf_option": "Config Option",
                    "conf_type": "Conflict Type",
                    "net_setting": "Network Settings",
                    "name": " Name",
                    "netChoice": "Select Network ",
                    "advOption": "Advanced Option",
                    "selfConfig": "Optional Config",
                    "choiceConfig": "Select Config",
                    "passwd": "Password",
                    "repeatPasswd": "Repeat Password",
                    "notSamePasswd": "Passwords are not consistent",
                    "keypair": "Keypairs",
                    "keypairChoice": "Select Keypairs",
                    "firewallChoice": "Select Firewall",
                    "launchArea": "Launch Area",
                    "launchAreaChoice": "Select Launch Area",
                    "launchNode": "Launch Node",
                    "launchNodeChoice": "Select Launch Node",
                    "localStorage": "Local Storage",
                    "conf_detail": " Setting Detail",
                    "memory": "memory",
                    "ok": "Ok",
                    "cancel": "Cancel",
                    "core": " Core"
                },
                "net": {},
                "osedit": {
                    "editInsName": "Edit Instance Name"
                },
                "FloatingIp": {
                    "ipAddr": "IP Address"
                },
                "disk": {
                    "volumeChoice": "Select Volume"
                },
                "mkImg": {
                    "makeImg": "Create images"
                },
                "table": {
                    "status": {
                        "1": "Breakdown",
                        "2": "Running",
                        "3": "Creating...",
                        "4": "Shutdown",
                        "5": "Returned",
                        "6": "Returning",
                        "7": "rebooting",
                        "8": "Starting",
                        "9": "Shutdown",
                        "10": "Password Resetint",
                        "11": "Formatting",
                        "12": "Create images",
                        "13": "Network Bandwidth Setting",
                        "14": "Rewriting System",
                        "15": "Domain Name associating",
                        "16": "Domain Name disassociating",
                        "17": "Loadbalance associating",
                        "18": "Loadbalance disassociating",
                        "19": "Upgrading",
                        "20": "SecretKey issue",
                        "21": "Migrating"
                    },
                    "c_status": {
                        "1": "error",
                        "2": "active",
                        "3": "building",
                        "4": "stopped",
                        "8": "powering-on",
                        "9": "powering-off"
                    },
                    "Local": "Local",
                    "cvmPayMode": {
                        "0": "Monthly settlement after payment",
                        "1": "Monthly/Yearly",
                        "2": "Billing quantity"
                    },
                    "diskInfo": {
                        "storageType": {
                            "1": "Local Disk",
                            "2": "Volume"
                        }
                    }
                },
                "confName": {
                    "m1.tiny": "Tiny Config",
                    "m1.small": "Small Config",
                    "m1.medium": "Medium Config"
                },
                "conf": {
                    "m1.tiny": "Tiny Config: CPU 1 Core Ram 512M Support Volume11111",
                    "m1.small": "Small Config: CPU 1 Core Ram 2G Support Volume2222",
                    "m1.medium": "Medium Config: CPU 2 Core Ram 4G Support Volume3333",
                    "pretip": "High Config: CPU ",
                    "memtip": " Core Memory ",
                    "endtip": "G Support Volume"
                }
            }
        },
        "workflow": {
            "0": "First level examination and approval",
            "1": "Second level examination and approval",
            "2": "Third level examination and approval",
            "3": "Fourth level examination and approval",
            "addressee": "Addressee",
            "title": "Title",
            "informationTitle": "Please enter the information to consult the title",
            "informationDescription": "Please enter the description information",
            "resourceApply": "Resource Apply",
            "handleTitle": "Ticket",
            "applyDetail": "Apply Detail",
            "applytype": "Type",
            "name": "Ticket Name",
            "statusName": "Status Name",
            "transactor": "Transactor",
            "dueTime": "Expiration Time",
            "createTime": "Create Time",
            "accomplish": "Accomplish",
            "ticketName": "Ticket Type",
            "edit": "Edit",
            "tiName": "Name",
            "value": "value",
            "bind": "Associate",
            "bindwork": "Associate workflow",
            "description": "Description",
            "deployed": "deployed",
            "workDep": "Deployed Step",
            "model": "Wait for deploy",
            "version": " Version",
            "type": "Type",
            "handling": "handling",
            "handled": "handled",
            "start": "deployment",
            "lastUpdateTime": " time",
            "depart": "Select department",
            "department": "Select Department",
            "curStep": "Current Step/Recovery number",
            "handlecurStep": "Current Step",
            "user": "Select user",
            "modelName": "Model Name",
            "approval": "approval",
            "apply": "apply",
            "applied": "applied",
            "action": "action",
            "status": "Approval Status",
            "detail": "Approval Detail",
            "result": "Approval Result",
            "seeticket": "See",
            "ticket": "Ticket Type",
            "ticketApply": "Ticket apply",
            "initialAssigneeName": "applyer",
            "applyTime": "Apply time",
            "proTime": "Handle time",
            "tickDec": "Change phone/email、real name authentication、find User name/Password and so on",
            "resDec": "Instance ,Ram,CPU and other Virtual resource Apply",
            "tickTitle": "Info Consult",
            "resTitle": "Apply Resource",
            "resButton": "Apply",
            "tickButton": "Question",
            "workTitC": "Create Wordkflow",
            "true": "End",
            "false": "Approving",
            "project": "Select Project",
            "projectName": "Project Name",
            "departName": "Department Name",
            "qutaError": "Can not exceed the upper limit of the project",
            "variables": {
                "name": "Name",
                "type": "Type",
                "applyUserId": "Apply User",
                "description": "Approval Description",
                "ticketApply": "Ticket Apply",
                "resourceApply": "Resource Apply",
                "quota": {
                    "instances": "Instance",
                    "cores": "CPU Core",
                    "ram": "Ram(MB)",
                    "ramGb": "RamGb(GB)",
                    "snapshots": "Snapshots",
                    "floatingip": "Floating IP",
                    "volumes": "Volumes",
                    "gigabytes": "Gigabytes(GB)",
                    "cpuCore":"CPU (core)",
                    "memoryGb":"Memory (GB)",
                    "diskCapacityGb":"Cloud hard disk capacity (GB)"
                }
            },
            "deleteReason": {
                "wait": "Waiting approval",
                "deleted": "Deleted approval",
                "completed": "Completed approval"
            },
            "operations": {
                "close": "close",
                "closed": "closed",
                "approving": "approving"
            },
            "wfName": "Workflow Name",
            "ticketType": "Work Order Type",
            "range": "Scope of Application",
            "email": "Mail Notification",
            "wfDescription": "Description",
            "other":"other",
            "applyResourceDetails":"Apply for resource details",
            "routeSubNetwork":"Route",
            "comment":"Comment",
            "commentTip":"Reply comment cannot be empty, please re-enter",
            "rejectAndClose":"Reject and close",
            "createManuallyLater":"Create manually later",
            "agree":"agree",
            "agreeAndCreate":"Agree and create",
            "processProgress":"Process progress",
            "launching":"Launching",
            "selectWorkOrderType":"Select work order type",
            "selectProcessingStatus":"Select processing status"
        },
        "ticket": {
            "deleteTicketMsg": "Do you sure you want to delete the selected work order?",
            "openTicketMsg": "Do you sure you want to turn your order?",
            "resourceDetail": "Resource application details",
            "approval": "Approved",
            "refuse": "Refused",
            "domainUid": "Domain Name",
            "projectname": "Project Name",
            "refuseReason": "Reasons for refusal",
            "approvalResult": "Approval result",
            "reply": "Reply",
            "allReply": "All Reply",
            "replyPlaceholder": "Please fill in the reply",
            "curStep": "Current Step/Recovery number",
            "startTime": "start time",
            "endTime": "end time",
            "status": "status",
            "statusAction": "Status/Operation",
            "description": "Description",
            "ok": "OK",
            "cancel": "Cancel",
            "instanceApply": "Instance Apply",
            "informationType": "Info Consult",
            "resourceType": "Resource Apply",
            "approvalStyle": {
                "0": "Pending approval",
                "1": "Agreed",
                "2": "Refused",
                "3": "Revoked",
                "notpass": "Not pass",
                "pass": "pass",
                "unapproved": "In approva",
                "yes": "Yes",
                "no": "No",
                "close": "closed"

            },
            "applyTypes": {
                "1": "Quota Application",
                "2": "Cloud Host",
                "3": "Cloud Hard Drive",
                "4": "Network",
                "5": "Router",
                "6": "vpn",
                "7": "Load balancing",
                "8": "Elastic stretching",
                "9": "Physical machine",
                "10": "Cloud Host Renewal",
                "11": "Information consultation"
            },
            "applyName": {
                "1": "Quota Application",
                "2": "Cloud Host Application",
                "3": "Cloud Hard Drive Application",
                "4": "Online Application",
                "5": "Router Request",
                "6": "The VPN application",
                "7": "Load Balancing Application",
                "8": "Elastic Flex Request",
                "9": "Physical Machine Application",
                "10": "Cloud Host Renewal Application",
                "11": "Information consultation"
            },
            "resApply": {
                "network": {
                    "0": "Network",
                    "1": "Sub"
                }
            },
            "domain": "Department",
            "applyUserName": "User Application",
            "ticketName": "Work Order Name",
            "applyType": "Work Order Type",
            "actionStatus": "Processing state",
            "ticketNum": "Work Order No.",
            "servename": "Service Name",
            "ticketTitle": "Title",
            "approver": "Applicant",
            "approveTime": "Application Time",
            "ticketAction": "Operation",
            "endTime":"End Time",
            "status_action": "Status/Operation",
            "ticketStatus": "Work order status",
            "type":"Type:",
            "remarkInformation":"Remark information",
            "otherInformation":"Other - Information Consulting",
            "examineAndApprove":"Approval",
            "resourceApply":"Resource application -",
            "pending":"Pending",
            "applyCancel":"application canceled",
            "isApplyCancel":"Whether to revoke the resource application?",
            "approvedAgree":"Approved",
            "alreadyApproved":"Already approved",
            "enterWorkName":"Enter a single name",
            "handler":"Handle",
            "selectHandler":"Select handler",
            "workOrderReport":"Work order report. CSV",
            "undergoing":"Undergoing"
        },
        "monitor": {
            "alarmModule": {
                "ruleName": "Rule Name",
                "warnRange": "Warn Range",
                "vmstatetitle": "Cloud Host Status Change Monitoring",
                "isVmopen": "On or off",
                "vmerrortitle": "Cloud Host Exception Monitoring",
                "lowMin": "Five minutes",
                "midMin": "Ten minutes",
                "heightMin": "15 minutes",
                "cycle": "Cycle",
                "level": "Level",
                "alarm": "Alarm ",
                "alarmEvent": "Alarm Event",
                "addalarm": "Add Alarm ",
                "newAlarmEvent": "New Alarm Event",
                "oldAlarmEvent": "History Alarm Event",
                "hostName": "Host Name",
                "alarmType": "Alarm Type",
                "alarmReason": "Alarm Reasons ",
                "alarmTime": "Alarm  time",
                "severity": "Severity",
                "handleStatus": "Handle Status",
                "operate": "operate",
                "handle": "handle",
                "ignore": "ignore",
                "handleTime": "Handle time",
                "handleDescription": "Handle Description",
                "alarmHandleDes": "Alarm Handle Description",
                "unprocessed": "unprocessed",
                "processed": "processed",
                "critical": "critical",
                "moderate": "moderate",
                "low": "low",
                "threshold": "Threshold",
                "vmstate": "State",
                "service": "service",
                "healthcheck": "Health check",
                "computeha": "Computeha",
                "planwork": "Planned mission",
                "hardware": "Hardware failure",
                "cephCheck": "Ceph Health Check",
                "ignoreAlarm": "Ignore Alarm？",
                "ignoreAlarms": "Ignore Alarms？",
                "alarmSetting": "Alarm Setting",
                "alarmName": "Alarm Name",
                "resourceType": "Resource Type",
                "template": "Alarm Template",
                "label": "label",
                "resource": "resource",
                "contact": "contact",
                "enabled": "enabled",
                "choseResType": "Select Resource Type",
                "choseResource": "Select Resource ",
                "choseSubResource": "Select subresources",
                "choseTemplate": "Select Template",
                "choseContactGroup": "Select Contact Group",
                "triggerWay": "Trigger Way",
                "normalToAlarm": "Normal To Alarm",
                "alarmToNormal": "Alarm To Normal",
                "alarmAction": "Alarm Action",
                "mail": "mail",
                "email": "email",
                "weChat": "WeChat",
                "wechatName": "WeChat nicknames",
                "weChatBind": "Bind WeChat",
                "addWeChatContact": "Add WeChat contacts",
                "notBandTipsAdmin1": "Currently you are not bound to the WeChat server, so you cannot use this function for the time being. Please go to the system management > system to set > first.",
                "notBandTipsAdmin2": "Bind WeChat server to enable activation.",
                "notBandTipsAdmin3": "Currently you are not bound to the WeChat server, so you cannot use this function for the time being. Please first go to the system management > configuration management >.",
                "notBandTipsUser": "Currently you are not bound to the WeChat server, so you cannot use this function for the time being. Please contact the administrator to bind to the WeChat server and enable it.",
                "prompts": "Warm tips:",
                "promptsMsg1": "1. Please scan the qr code above with WeChat",
                "promptsMsg2": "2. You need to pay attention to our WeChat service number to receive system messages",
                "promptsMsg3": "3. If the qr code is displayed incorrectly, please confirm whether the WeChat setting is correct.",
                "checkNickName": "Verify nicknames",
                "cancelCheck": "Unverify",
                "weChatCheckTitle": "WeChat nickname verification",
                "nickName": "Nickname",
                "location": "Area",
                "message": "message",
                "phone": "phone",
                "physicalHost": "Physical Host",
                "vmHost": "Instance ",
                "yes": "yes",
                "no": "no",
                "creatNewAlarm": "Create Alarm",
                "editAlarmSetting": "Edit Alarm setting",
                "copyAlarm": "Copy Alarm",
                "enableAlarm": "Confirm enbale Alarm",
                "forbiddenAlarm": "Confirm disable Alarm",
                "deleteAlarm": "Confirm delete selected Alarm",
                "alarmTemplate": "Alarm Template",
                "setRule": "Rule setting",
                "templateName": "Template Name",
                "setAlarmTmplRule": "Alarm Template rule setting",
                "addThresholdRule": "Add Threshold Rule",
                "newCreatAlarmTmpl": "Create AlarmTemplate",
                "editAlarmTmpl": "Edit Alarm Template",
                "mean": "Averange",
                "max": "max",
                "min": "min",
                "deleteTmpls": "Confirm Delete SelectedmAlarm Template",
                "delThresholdRule": "Confirm delete Alarm Template rule",
                "contactTips": "No more than 10 records in email, mobile phone or WeChat",
                "contactGroup": "Contact Group",
                "editContact": "Edit Contact",
                "newCreatContactGroup": "Create Contact Group",
                "editContactGroup": "Edit Contact Group",
                "delContactGroup": "Confirm Delete SelectedContact Group？",
                "delContact": "Confirm Delete Selected contact？",
                "inputSameEmail": "Can't Input the Same Email",
                "addNewRule": "Add New Rules",
                "alarmConflict": "There is a conflict between alarm values between different alarm levels,please rest the value",
                "alertInfo": "The alarm values between different alarm levels are in conflict. Each of the alarm rules will exist independently, and any one of them will generate corresponding alarm.",
                "alertInfo2": "Cloud host status change monitoring and cloud host exception monitoring will not take effect in elastic expansion",
                "gt": ">",
                "ge": ">=",
                "eq": "=",
                "le": "<=",
                "lt": "<",
                "monitorItem": "Monitor Items",
                "threshold":"Threshold",
                "triggerAlarm": "Trigger alarm after exceeding the threshold",
                "temperatureRange": "The temperature range is between 50 and 200",
                "alarmAction":"Alarm method",
                "tsdb": "CTSDB Timing Database",
                "snmp": "SNMP alarm",
                "switchSnmp": "Switch SNMP",
                "security": "Cloud mirror",
                "tdsql": "TDSQL Distributed Database",
                "alauda": "TKE Enterprise edition",
                "coc": "Weave cloud",
                "AlaudaAlarm": "TKE Enterprise edition",
                "SecurityAlarm": "Cloud mirror",
                "TDSqlAlarm": "TDSQL Distributed Database",
                "TSDBAlarm": "CTSDB Timing Database",
                "COCAlarm": "Weave cloud",
                "alarmRule":"Alarm rule",
                "placeholder": {
                    "handleDescription": "Please enter Alarm handle Description",
                    "requiredName": "Please enter  Name",
                    "choseResType": "Select Resource Type",
                    "choseResMultiple": "Select Resources (multiple options available)",
                    "choseRes": "Select Resource (Multi-choices)",
                    "chooseRegion": "Select Field (multiple choice)",
                    "noResToChose": "No Resource available ",
                    "chosePort": "Select sub-resources (multiple)",
                    "noPortToChose": "No sub-resources to choose from.",
                    "choseTmpl": "Select template(Multi-choices)",
                    "noTmplToChose": "No template available ",
                    "choseContactGroup": "Select Contact Group(Multi-choices)",
                    "noContactToChose": "No Contact Group available",
                    "requiredTmplName": "Please enter Alarm template Name",
                    "requiredContactGroup": "Please enter Contact Group Name",
                    "phoneLength_11": "Please enter 11 characters of phone number",
                    "wechatName": "Please enter the WeChat nickname",
                    "ipAdress": "Please enter the correct IP address",
                    "macAdress": "Please enter the correct MAC address"
                },
                "info": {
                    "macAdress": "Please enter the correct MAC address",
                    "enterTemplate": "Please enter temperature",
                    "selectAlarmAction": "Please select Alarm Action",
                    "alarmName": "Alarm Name",
                    "choseResType": "choseResType",
                    "choseResource": "choseResource",
                    "choseTemplate": "choseTemplate",
                    "monitorItem": "monitorItem",
                    "choseContactGroup": "choseContactGroup",
                    "alarmAction": "alarmAction",
                    "enabled": "enabled",
                    "templateName": "",
                    "resourceType": "The specific resource for which this alarm template is targeted.",
                    "threshold": "Threshold of alarm template rule",
                    "cycle": "Alarm rule refreshes the alarm state cycle.",
                    "level": "Choose different levels according to the severity of the alarm.",
                    "wechatName": "Before binding WeChat, please confirm that the relevant information of the official account has been configured in the background of WeChat."
                }
            },
            "overview": {
                "phyalarm": "Physical Host Alarm",
                "virtualalarm": "Instance Alarm",
                "phyTop5": "Physical Host CPU Utilization Top5",
                "virtualTop10": "Cloud host CPU usage TOP10",
                "resdistribute": "Resource Allocation",
                "cpu": "CPU",
                "mem": "Memory(GB)",
                "storage": "Storage(GB)",
                "virtual": "Instance",
                "project": "Project",
                "floatingip": "Public Network IP",
                "alarm": "alarm",
                "alarmTip": "(Alarm Num/ Host Num)",
                "compare": {
                    "instances": "Statistical Comparison of Cloud Hosts",
                    "cpu": "CPU Statistics comparison",
                    "mem": "Statistical comparisons of Memory",
                    "disk": "Comparison of Storage Statistics",
                    "project": "Statistical Comparison of Items"
                }
            },

            "resview": {
                "regoin": "Data Center",
                "objType": "Object Type",
                "topType": "TOP type",
                "search": "Query",
                "physical": "Physical machine",
                "virtual": "Virtual machine",
                "instance": "Cloud host",
                "topHigh": "The highest",
                "topLow": "Minimum",
                "cpuRate": "CPU utilization",
                "memoryRate": "Memory usage",
                "netReciveRate": "Network Inflow rate",
                "netSendRate": "Network Outflow rate",
                "diskIOWrite": "Disk I/O write",
                "diskIORead": "Disk I/O Readout",
                "diskUseRate": "Disk utilization"
            },

            "physical": {
                "selectIP":"Please select the IP",
                "phylist": "Physical Host:",
                "os": "OS",
                "cpuModel": "CPU",
                "dataSearch": "Data Query",
                "kernel": "kernel Version",
                "ip": "IP Address",
                "netband": "Network Bandwidth",
                "netpacket": "Network Packet",
                "swapmem": "Switch Memory",
                "systemLoad": "System Load",
                "memBand": "Memory Bandwidth",
                "noDataTips": "Unable to obtain data. Please confirm that monitoring client is installed.",
                "physicalHost": "physicalHost",
                "hostName": "hostName",
                "durationTime": "Duration",
                "diskTotal": "Disk Total",
                "disketteDetail": "Disk Detail",
                "processMemoryFootprint": "Process Memory Footprint",
                "processDetail": "Process Detail",
                "processCPUfootprint": "Process CPU Footprint",
                "userName": "User Name",
                "processName": "Process Name",
                "processId": "The process ID",
                "mem": "Memory(%)",
                "memory": "MEM",
                "processUseCPUtime": "Process Use CPU Time",
                "disk": "Disk",
                "diskPartition": "Disk Partition",
                "diskSpace": "Disk Space",
                "netCard": "NetCard",
                "day": "day ",
                "days": "days ",
                "hour": "hour ",
                "hours": "hours ",
                "minite": "minite ",
                "minites": "minites ",
                "collectCycle": "Collection period",
                "collectCycleSelect": "Please select collection period",
                "instancesSelect": "Please select an instance",
                "physicalConductor": "Manage bare metals",
                "resConductor": "Bare metal resource pool",
                "vmConductor": "Virtualized physical machine",
                "guide1": "To use the physical resource management block function, please open system management > system Settings >",
                "guide2": "Function management",
                "guide3": "Add physical Machine Management module to the interface",
                "calc": "Computing",
                "storage": "Storage",
                "refreshRate": "Refresh Rate",
                "refreshRateSelect": "Please select the refresh rate",
                "mainType": "Please select the query dimension",
                "notRefresh": "Not Refresh",
                "5sec": "An interval of 5 seconds",
                "10sec": "Interval of 10 seconds",
                "30sec": "Interval 30 seconds",
                "1min": "1 minute interval"
            },

            "virtual": {
                "getClient": "Get monitoring clients",
                "statedistribution": "Instance Status Distribution",
                "sum": "Instance Sum",
                "virtualTop5": "Instance CPU  Utilization Top5",
                "name": "Instance Name",
                "hostname": "Host Name",
                "status": "Status",
                "createtime": "Create  time",
                "netband": "Network Bandwidth",
                "netpacket": "Network data packet sending and receiving",
                "mem": "Memory",
                "swapmem": "Switch Memory",
                "systemLoad": "System Load",
                "memBand": "Network Bandwidth Memory",
                "vmhostStatus": {
                    "ACTIVE": "active",
                    "POWERING-ON": "start",
                    "STOPPED": "shutdown",
                    "SHUTOFF": "shutdown",
                    "POWERING-OFF": "shutdown",
                    "ERROR": "ERROR",
                    "BUILDING": "building",
                    "DELETING": "deleting",
                    "DELETED": "deleting",
                    "REBOOT_PENDING": "reboot",
                    "PAUSED": "paused",
                    "PAUSING": "pausing",
                    "UNPAUSING": "unpauseing",
                    "SUSPENDED": "suspended",
                    "SUSPENDING": "suspending",
                    "RESUMING": "resuming",
                    "RESIZE_MIGRATING": "resize migrating",
                    "RESIZE_PREP": "pre-resize",
                    "RESIZE_FINISH": "resized",
                    "RESIZED": "resized",
                    "VERIFY_RESIZE": "resizing",
                    "IMAGE_BACKUP": "backup",
                    "IMAGE_SNAPSHOT": "create images",
                    "SPAWNING": "spawning",
                    "UP": "UP",
                    "DOWN": "DOWN",
                    "BUILD": "BUILD",
                    "REBOOT": "REBOOT",
                    "MIGRATING": "MIGRATING",
                    "REBUILD": "REBUILD",
                    "REBUILDING": "REBUILDING",
                    "UNKNOW": "UNKNOW",
                    "UNRECOGNIZED": "UNRECOGNIZED",
                    "EMPTY": "EMPTY",
                    "RESIZE": "Reset",
                    "OTHER": "OTHER",
                    "other": "Other"
                },
                "insTotal": "Instance Total",
                "insDistribution": "Instance Distribution",
                "system": "System"
            },

            "ceph": {
                "status": {
                    "HEALTH_OK": "HEALTH_OK",
                    "HEALTH_WARN": "HEALTH_WARN",
                    "HEALTH_ERR": "HEALTH_ERR"
                },
                "StatusCeph": "Status",
                "hostName": "Host Name",
                "diskStorage": "Disk Storage",
                "poolsList": "pools List",
                "osdList": "osd List",
                "poolName": "Pool Name",
                "pgNumber": "PG Number",
                "objNumber": "Object Number",
                "copyNumber": "Copy Number",
                "totalStorage": "Max available storage(GB)",
                "usage": "Usage",
                "currentServer": "Current Server"

            },
            "pool_IOPS_peak": "Ceph pool - IOPS (peak)",
            "pool_IOPS_avg": "Ceph pool-iops (average)",
            "pool_rBandwidth_peak": "Ceph pool - read bandwidth (peak)",
            "pool_rBandwidth_avg": "Ceph pool - read bandwidth (average)",
            "pool_wBandwidth_peak": "Ceph pool - write bandwidth (peak)",
            "pool_wBandwidth_avg": "Ceph pool - write bandwidth (average)",
            "wuhan": "Wuhan",
            "beijing": "Beijing",
            "nodata": "no data",

            "Nusage_idle": "CPU Usage",
            "usage_user": "user",
            "cpu_usage_user": "user cpu usage",
            "usage_system": "system",
            "cpu_usage_system": "system",
            "usage_iowait": "iowait",

            "Percent_Idle_Time": "Utilization",
            "Percent_Processor_Time": "system",
            "Percent_User_Time": "user",

            "cpu_usage_percent": "CPU Usage",
            "cpu_usage_idle_percent": "idle",
            "cpu_usage_iowait_percent": "iowait",
            "cpu_usage_iowait_percent_max": "iowait",
            "activeConnections": "Number of current sessions",
            "responsesDenied": "Number of rejected responses",
            "requestErrors": "Number of wrong requests",
            "requestsDenied": "Number of Denied Requests",
            "maxConnections": "Maximum Number of Sessions",
            "maxQueuedRequests": "Maximum number of queued requests",

            "responseErrors": "Number of Error Responses",
            "connectionErrors": "Number of Error Connections",
            "currentQueuedRequests": "Current Queued Requests",
            "cpu_usage_system_percent": "system",
            "cpu_usage_system_percent_max": "system",
            "cpu_usage_user_percent": "user",
            "cpu_usage_user_percent_max": "user",
            "memory_used_percent": "memory usage",
            "memory_used_percent_max": "Memory usage",
            "memory_available_percent": "available memory ",
            "memory_available_percent_max": "Idle memory",
            "memory_buff_or_cache_percent": "cache",
            "memory_buff_or_cache_percent_max": "Cached",

            "net_bits_recv_persecond": "bits recv",
            "net_bits_sent_persecond": "bits sent",

            "net_bits_recv_persecond_peak": "Inflow rate",
            "net_bits_sent_persecond_peak": "Outflow rate",

            "net_packets_recv_persecond_peak": "Package receiving rate",
            "net_packets_sent_persecond_peak": "Package transmission rate",

            "net_packets_recv_persecond": "packets recv",
            "net_packets_sent_persecond": "packets sent",

            "net_packets_recv_persecond_avg": "Package receiving rate",
            "net_packets_sent_persecond_avg": "Package transmission rate",

            "net_bytes_recv": "bytes recv",
            "net_bytes_sent": "bytes sent",
            "disk_used_percent": "disk use percent",
            "disk_read_write_bytes_persecond": "Number of bytes read and written",
            "disk_read_write_bytes_persecond_peak": "Reading the number of words",
            "disk_read_write_bytes_persecond_avg": "Reading the number of words",
            "disk_write_bytes_persecond": "write bytes",
            "disk_write_bytes_persecond_peak": "Number of bytes",
            "disk_write_bytes_persecond_avg": "Number of bytes",

            
            "disk_read_bytes_persecond": "read bytes",
            "disk_read_bytes_persecond_peak": "Reading byte",
            "disk_read_bytes_persecond_avg": "Reading byte",

            
            "disk_io_persecond": "Number of reads and writes",
            "disk_io_persecond_peak": "Read and write",
            "disk_io_persecond_avg": "Read and write",
            "disk_reads_persecond": "read IOPS",
            "disk_reads_persecond_peak": "Reading",
            "disk_reads_persecond_avg": "Reading",
            "disk_writes_persecond": "write IOPS",
            "disk_writes_persecond_peak": "Write",
            "disk_writes_persecond_avg": "Write",
            "time": "time",
            "csvTitle": {
                "cpu": "CPU",
                "mem": "Memory",
                "diskPath": "DiskPath",
                "diskio": "Diskio",
                "netcard": "Netcard"
            },

            "Context_Switches_Per_Period": "context switch",
            "System_Calls_Per_Period": "system invoking",

            "context_switches_persec": "Number of context switches",
            "system_calls_persec": "Number of system calls",

            "Percent_Disk_Write_Time": "Write time Utilization",
            "Percent_Disk_Read_Time": "Read time Utilization",
            "Percent_Disk_Time": " Utilization ",

            "disk_usage": "Utilization rate",
            "percent_disk_read_time": "Read time",
            "percent_disk_write_time": "Write time",
            "percent_disk_time": "Read-write time",
            "percent_idle_time": "Free time",

            "disk_read_write_percent": "Ratio of read-write time",
            "current_disk_queue_length": "Disk queue length",

            "Available_Bytes": "Remaining bytes",
            "Pages_persec": "Number of requests per second",
            
            "mem_used_percent": "mem use percent",
            "mem_total_percent": "mem total",
            "disk_used_percent": "Disk space usage",
            "mem_free": "free",
            "mem_cached": "free cache",
            "mem_buffered": "buffered",
            "read_bytes_per_second": "read IOPS",
            "write_bytes_per_time": "write IOPS",
            "read_time": "read IOPS",
            "write_time": "write IOPS",

            "load1": "Last 1 min",
            "load5": "Last 5 min",
            "load15": "Last 15 min",

            "swap_used_percent": "swap use percent",
            // "inodes_used":"Useful",
            // "inodes_total":"Total",
            "gross": "Total",
            "inodes_used_inodes_total": "inodes Utilization ",
            "read_bytes": "Disk Read flow",
            "write_bytes": "Disk Write flow",
            "bytes_recv": "Download flow",
            "bytes_received": "Download flow",
            "bytes_sent": "Upload flow",
            "packets_recv": "receive packets",
            "packets_sent": "send packets",
            "total_bytes_recv": "Total Download flow",
            "total_bytes_sent": "Total Upload flow",

            "bits_received_persec": "Inflow rate",
            "bits_sent_persec": "Outflow rate",
            "packets_received_persec": "Packet reception rate",
            "packets_sent_persec": "Packet sending rate",

            "region1": "default",
            "IOPS": "IOPS",
            "read_flow": " Read flow",
            "write_flow": "Writeflow",
            "rabbitmq_connections": "Connections",
            "cluster_available": "ceph Cluster remaining room",
            "cluster_used": "ceph Cluster used room",

            "cluster_IOPS": "IOPS",
            "cluster_IOPS_peak": "IOPS (peak)",
            "cluster_IOPS_avg": "IOPS (average)",
            "cluster_ReadOPS": "IOPS (read)",
            "cluster_WriteOPS": "IOPS (write)",
            
            "max_cluster_IOPS": "IOPS",
            "max_cluster_ReadOPS": "IOPS (read)",
            "max_cluster_WriteOPS": "IOPS (write)",
            
            "pool_IOPS": "ceph pool-iops",

            "cluster_rBandwidth": "Read Network Bandwidth of ceph Cluster",
            "max_cluster_rBandwidth": "BW read bandwidth",

            "cluster_rBandwidth_peak": "BW read bandwidth",
            "cluster_rBandwidth_avg": "BW read bandwidth",
            
            "cluster_wBandwidth": "Write Network Bandwidth of ceph Cluster",
            "max_cluster_wBandwidth": "BW write bandwidth",

            "cluster_wBandwidth_peak": "BW write bandwidth",
            "cluster_wBandwidth_avg": "BW write bandwidth",
            
            "pool_rBandwidth": "ceph pool-read Network Bandwidth",
            "pool_wBandwidth": "ceph pool-write Network Bandwidth",

            //mysql
            "max_connections": "mysql max connections",
            "mysql_cachehits_percent": "mysql cachehits percent",
            "mysql_cache_ratio": "mysql cache ratio",
            "open_tables": "open tables",
            "qcache_total_blocks": "qcache total blocks",
            "slow_queries": "slow queries",
            "queries": "Statement execution rate",
            "commands_select": "Query statement execution rate",
            "commands_update": "Update statement execution rate",
            "commands_delete": "Delete statement execution rate",
            "commands_insert": "Insert statement execution rate",
            "threads_connected": "threads connections",
            "aborted_connects": "aborted connections",

            //rabbitmq
            "messages_ready": "ready",
            "messages_unacked": "unchecked",
            "rabbitQueueTotal": "total",
            "messages_published_rate": "published",
            "messages_delivered_rate": "delivered",
            "messages_acked_rate": "checked",
            //memcached
            "bytes_read": "read rate",
            "bytes_written": "write rate",
            "curr_connections": "current connections",
            "disk_usage_avg": "Average disk usage",
            "index_speed": "Write speed",
            "index_total": "Total number of current data writes",
            
            "openstackService": {
                "compute": "Compute",
                "serviceName": "Service Name",
                "node": "Node",
                "statusTitle": "Status",
                "cpuUsage": "CPU Usage",
                "memUsage": "Memory Usage",
                "storage": "Storage",
                "network": "Network",
                "normal": "Normal",
                "abnormal": "Abnormal",
                "description": "Description",
                "selectNode": "Select a node",
                "nodeSelect": "Select a node",
                "active": "active",
                "status": {
                    "passing": "Passing",
                    "warning": "Warning",
                    "critical": "Critical"
                },
                "serverDescription": {
                    "nomad": "NOMAD cluster scheduling service",
                    "nomad-client": "NOMAD cluster scheduling service",
                    "consul": "Consul Cluster Scheduling Service",
                    "mysql": "MySQL Database Services",
                    "neutron-vpn-agent": "Network VPN Agent Service",
                    "ceph-mon": "Ceph monitoring service",
                    "ceph-osd": "Ceph OSD Management Service",
                    "awbob": "Platform BOB Task Service",
                    "awmonitor": "Hardware Monitoring Service",
                    "computeha-monitor": "Computing node high available monitoring services",
                    "computeha-slave": "Computing node high availability service",
                    "influxdb": "InfluxDB Database Services",
                    "docker": "Docker container service",
                    "libvirt": "Virtualization platform platform management service",
                    "ovs": "OpenVSwitch service",
                    "nova-compute": "NOVA calculation services",
                    "neutron-openvswitch-agent": "OpenVSwitch Network Agent Service",
                    "agent-proxy": "Virtualization platform agent service",
                    "rabbitmq-common": "Message Queue Service",
                    "rabbitmq-common-post-start": "Message Queue Service",
                    "rabbitmq-common-health-check": "Message Queue Service",
                    "rabbitmq-cinder": "Message Queue Service",
                    "rabbitmq-cinder-post-start": "Message Queue Service",
                    "rabbitmq-cinder-health-check": "Message Queue Service",
                    "rabbitmq-neutron": "Message Queue Service",
                    "rabbitmq-neutron-post-start": "Message Queue Service",
                    "rabbitmq-neutron-health-check": "Message Queue Service",
                    "rabbitmq-nova": "Message Queue Service",
                    "rabbitmq-nova-post-start": "Message Queue Service",
                    "rabbitmq-nova-health-check": "Message Queue Service",
                    "rabbitmq-management": "Message Queue Service",
                    "rabbitmq-managementmanagement-post-start": "Message Queue Service",
                    "rabbitmq-management-health-check": "Message Queue Service",
                    "ceilometer-notification-common": "Monitoring billing service",
                    "ceilometer-notification-cinder": "Monitoring billing service",
                    "ceilometer-notification-neutron": "Monitoring billing service",
                    "ceilometer-notification-nova": "Monitoring billing service",
                    "keystone": "Certification Services",
                    "keystone-admin": "Certification Services",
                    "keystone-public": "Certification Services",
                    "neutron-dhcp-agent": "Network DHCP Agent Service",
                    "neutron-server": "Internet service",
                    "nova-api": "Calculate API interface services",
                    "nova-consoleauth": "Calculate console certification service",
                    "nova-novncproxy": "Calculate NOVNC proxy service",
                    "nova-scheduler": "Calculate dispatch service",
                    "ceph-api": "CEPH API interface service",
                    "cinder-api": "Storage API service",
                    "cinder-scheduler": "Storage dispatch service",
                    "cinder-volume": "Storage volume management service",
                    "glance-api": "Mirror API Interface Service",
                    "glance-registry": "Mirror registration service",
                    "haproxy": "Load balancing service",
                    "haproxy-galera": "Load balancing service",
                    "memcached": "Memcached memory database",
                    "telegraf": "Cluster status collection service",
                    "tunnel-connector": "Channel connector service",
                    "clustercheck": "Cluster detection service",
                    "kapacitor": "Alarm container service",
                    "saas": "container",
                    "saas-container": "container",
                    "cinder-backup": "Backup",
                    "nova-quota-sync": "Computing quota synchronization service",
                    "nova-metadata": "Calculate metadata services",
                    "topo": "Network topology and big screen display",
                    "influxdb-relay": "Highly available service",
                    "nova-conductor": "NOVA Database Services"
                }
            },
            "mysql": {
                "phyHost": "Physical Host",
                "chosePhyHost": "Please Select Physical Host",
                "port": "Port",
                "version": "Version",
                "mysqlConnection": "Mysql Connection",
                "mysqlTableSpaceInfo": "Mysql Table Space Information",
                "mysqlVisitInfo": "Mysql Query Information"
            },
            "rabbitmq": {
                "connectionNumber": "Connection Number",
                "channel": "Channel",
                "switchNumber": "Switch Number",
                "queue": "Queue",
                "consumerNumber": "Consumer Number",
                "msgQueue": "Message Queue",
                "msgRate": "Message Rate",
                "nodeName": "Node Name",
                "fileDescriptor": "File Descriptor",
                "socketDescriptor": "Socket Descriptor",
                "process": "Process",
                "mem": "Memory(MB)",
                "diskSpace": "Disk Space(GB)",
                "durationTime": "Duration",
                "type": "Type",
                "nodeDetail": "Node Detail"
            },
            "cache": {
                "nodeName": "Node Name",
                "keyTotal": "Key Total"
            }
        },
        "siteTitle": {
            "enhanceconfig":"Enhanced load balancing",
            "enhance":"Enhanced load balancing",
            "fileStorage": "File storage",
            "label": "Label management",
            "loadbalancingcertificate": "Load balancing certificate",
            "rbackups": "Rbackups",
            "backupStrategy": "Backup Strategy",
            "recovery": "Recovery",
            "relationalTdsqls": "Relational database",
            "alRegister": "Deployment",
            "wellCome": "Big Data Login",
            "multiplenet": "Cloud Platform Physical Network Interoperability",
            "deploy": "Deployment",
            "daemon": "Daemon set",
            "stateful": "Stateful copy set",
            "replica": "Copy set",
            "pod": "Container group",
            "configmap": "Configure dictionary",
            "secret": "Secret Dictionary",
            "service": "service",
            "ingress": "Access rights",
            "bigmem": "Large Page Memory Management",
            "sriov": "Sr-iov Network Management",
            "dpdk": "DPDK management",
            "nettype": "Tenant Network Type Management",
            "networkpolicy": "Network Strategy",
            "persistentvolumeclaim": "Persistent Volume Declaration",
            "persistentvolume": "Persistent volume",
            "storageclass": "Storage Class",
            "nodes": "Node",
            "namespace": "Namespace",
            "othersspaced": "Namespace related Resources",
            "clustered": "Cluster related Resources",
            "roles": "Role",
            "bindings": "Role binding",
            "sqlDatabase": "Temporal database",

            "tbClusterManage": "Cluster Management",
            "tbNodeManage": "Node List",
            "tbNodeGroup": "Node Group List",
            "tbConfigManagement": "Configuration Management",
            "tbBackupSetting": "Backup Settings",
            "tbBackupCheck": "Backup Check",
            "tbClusterMonitor": "Cluster Monitoring",
            "tbNodeMonitor": "Node Monitoring",
            "tbVolumeMonitor": "Capacity Monitoring",
            "tbAlarmLists": "Alarm List",
            "tbAlarmRecords": "Alarm Record",
            "tbAlarmUsers": "Alarm object",
            "tbAlarmMethods": "Alarm Channel",
            "tbSystemInfo": "System Information",
            "tbLicenseInfo": "Certificate Information",

            "ctInstancesList": "Instance list",
            "ctOperationInsManage": "Instance management",
            "ctProcessManage": "Process Management",
            "ctEquipmentManage": "Equipment Management",
            "ctInstancesMonitor": "Instance monitoring",
            "entrance": "Start entry",

            "single": "Single Data Center",
            "first": "Welcome Page",
            "createins": "Quick Create Instance",
            "regularSnap": "Planning Tasks",
            "extensionins": "The detail info of Elastic Expansion",
            "extension": "Elastic Expansion",
            "scalpolicy": "Expansion Policy",
            "plugin": "Plugin Management",
            "pendingTickets": "Waiting list",
            "ticketApply": "Work Order Application",
            //"projectname": window.SETTING.SITETITLE,
            "home": "Home",
            "demotpl": "Demo Template",
            "view": "View",
            "depview": "Development View",
            "cvmview": "Resource Overview",
            "instances": "Instances",
            "elasticexpansion": "Elastic Expansion",
            "createCoustomflow": "Custom Process",
            "volumesQoS": "Cloud Drive Qos",
            "images": "images",
            "makeimages": "Create images",
            "volumes": "Volume",
            "snapshots": " Snapshots",
            "networks": "Private Networks",
            "physicalnetworks": "Physical Networks",
            "vpn": "Vpn",
            "routers": "Router",
            "floating_ips": "Floating IP",
            "firewall": "Firewall",
            "security_groups": "Security Groups",
            "security_template": "Security Template",
            "keypairs": "Keypairs",
            "monitorview": "Monitor Overview",
            "physical": "Physical Host",
            "vmhost": "Instance ",
            "clusters": "Container cluster",
            "services": "Container Service",
            "projects": "Mirror service",
            "netTopo": "Net Topo",
            "loadbalancing": "Loadbalance",
            "mailserver": "Mail Server Setting",
            "wechatserver": "WeChat Setting",
            "networksmanage": "Networks Manage",
            "cephview": "Ceph storage",
            "VGPU": "vGPU",
            "GPU": "Physical GPU",
            "vgpu": "vGPU",
            "gpu": "Physical GPU",
            "service": "Service monitoring",
            "strategy": "Alarm Strategy",
            "alarmevent": "Alarm Event",
            "alarmsetting": "Alarm setting",
            "alarmtemplate": "AlarmTemplate",
            "contractgroup": "Contract group",
            "topology": "Topology",
            "flowtask": "Wordkflow List",
            "createflow": "Edit Wordkflow",
            "flowapply": "Ticket Type",
            "lists": "My Ticket",
            "create": "Create Ticket",
            "operatelogs": "Operate logs",
            "loginlogs": "Login Logs",
            "auditLogs": "audit logs",
            "audit": "Audit Log",
            "user": "User Management",
            "role": "Role Management",
            "department": "Department Management",
            "org": "Organization",
            "flavors": "flavors",
            "aggregates": "Instance Collection",
            "storageManagement": "Storage Manage",
            "imageManagement": "Mirror Library Migration",
            "hypervisors": "Instance Management",
            "quotas": "Default Quota Management",
            "node": "Node Management",
            "cluster": "Cluster Setting",
            "cloud": "Public Cloud Setting",
            "general": "General Setting",
            "apply": "Applied Ticket",
            "about": "About",
            "logoSetting": "Logo Setting",
            "customRole": "new user role",
            "secrecyRole": "Overview",
            "auditRole": "auditRole Overview",
            "qcloud": "Tencent Cloud",
            "sysbackup": "System Backup",
            "accesspolicy": "Access Policy",
            "license": "Software Activation",
            "recycle": "Cloud hosting",
            "recyclecs": "Cloud drive",
            "loadbalancers": "Loadbalancers",
            "loadbalancing": "Load balancing",
            "openstackService": "Openstack Service ",
            "mysql": "Mysql",
            "grade": "grade",
            "rabbitmq": "RabbitMQ",
            "memcached": "Cached",
            "ports": "Ports",
            "routermonitor": "Router",
            "project": "User Project",
            "storagedevice": "Storage Device",
            "storagefeatures": "Storage Features",
            "volumetype": "Volume Type",
            "datacluster": "Datacluster Management",
            "monceph": "Monitor Ceph",
            "consumeview": "Bill Consumeview",
            "resourceprice": "Bill Resourceprice",
            "storagemanage": "Storage Management",
            "bandlimit": "Virtual Network",
            "switch": "Switch",
            "physicalConductor": "Manage bare metals",
            "overview": "Overview",
            "affinity": "Instance Group",
            "transmissionMag": "Transmission Management",
            "pvmview": "Overview",
            "pvminstances": "bare metal",
            "pvmimages": "Mirror",
            "pvmnetworks": "Network",
            "backups": "Back up",
            "resview": "Overview",
            "resMetering": "Resource Metering",
            "volumesQoS": "Storage pool QoS",
            "billingManagement": "Billing Management",
            "flowManage": "Flowing",
            "cephView": "Ceph Management",
            "cephViewInfo": "Ceph management",
            "systeminspection": "system Inspection",
            "transmissionMag":"Transmission equipment management",
            "myApply": "My application",
            "allTickets": "My Approval",
            "ticketReports": "Ticket Reports",
            "netTopo":"Network topology",
            "K8sclusters": "Container cluster",
            "K8sServices": "Container Service",
            "imgServer": "Mirror service",
            "maxpcshow": "Big Screen Management",
            "menu": "Menu",
            //腾讯云安全
            "Tsafe": "Tencent cloud security",
            "yujie": "Imperial",
            "tianmu": "Sky",
            "waf": "WAF",
            "soc": "See",
            "sec":"Cloud security",
            "fm":"Fortress machine",
            "ngfw":"Next generation firewall",
            //tdsql
            "relationalTdsql": "Relational Database TDSQL",
            "tdHome": "Cluster Overview",
            "tdInstanceManage": "Instance management",
            "tdResourceDevice": "Equipment Resources",
            "tdResourceSpec": "Model Specifications",
            "tdResourceIdc": "IDC information",
            "tdMonitorMysql": "DB Summary Monitoring",
            "tdProxyBase": "Gateway Summary Monitoring",
            "tdVipLvsgroup": "VIP management",
            "tdVipLvsmachine": "LVS resource monitoring",
            "tdMonitorZookeeper": "They are monitoring",
            "tdMonitorScheduler": "The Scheduler monitoring",
            "tdMonitorManager": "Manager control",
            "tdMonitorOssserver": "OSS- Service Monitoring",
            "tdMonitorOssjob": "OSS- Mission Flow",
            "tdMonitorOsslog": "OSS- Operational Audit",
            "tdMonitorHdfs": "HDFS monitoring",
            "tdMonitorSyncjob": "Multi-source synchronous Monitoring",
            "tdMonitorKafka": "Kafka monitoring",
            "tdOsswf": "Operation and Maintenance Operation Log",
            "tdAlarmSearch": "Alarm Enquiry",
            "tdAlarmConfig": "Warning Strategy",
            "tdAlarmExcept": "Exception Query",
            "tdAlarmGlobal": "Alarm Management across Clusters",
            "tdClusterManage": "Cluster Management",
            "tdBusinessUserDatabaseApply": "Database Permission Application",
            "tdBusinessUserDbclusterGrant": "Case Manager Management",
            "tdSysUser": "User Management",
            "tdSysRole": "Role permissions",
            "tdSysLog": "System Operation Log",
            "tdSysVersion": "System Version",

            "tdCapacity": "Capacity statistics",
            "tdVipgroup": "VIP Group Management",
            "tdVipdns": "The DNS query",
            "tdMonitordns": "DNS monitoring",
            "tdDcnjob": "DCN synchronization",
            "tdSyncjob": "Multi-source synchronization",
            "tdClusteradmin": "Cluster Leader Management",
            "tdUpgrade": "System Upgrade",
            "tdAutotest": "Automated testing",

            //tsf 微服务框架
            "Overview": "Overview",
            "Cluster": "Cluster",
            "Namespace": "Namesapce",
            "Deploy": "Deploy Group",
            "AppManagement": "App Manager",
            "Service": "Service Manager",
            "AppConfig": "App Setting",
            "GlobalConfig": "Global Setting",
            "Template": "Template",
            "ConfigFile": "Setting File",
            "LogConfig": "Log Setting",
            "GatewayList": "Gateway Setting",
            "GatewayGroup": "Gateway Groups",
            "LogSearch": "Log Search",
            "Transaction": "Transaction",
            "Topology": "Service Topology",
            "Trace": "Trace Query",
            "Alarm": "Alarm Monitor",
            "Scalable": "Elastic Expansion",
            "Image": "Images",
            "Package": "Software",
            "ServiceTemplate": "Service Orchestration",
            "ManagerBase": "Basic Module",
            "ManagerZone": "Available Zone",
            "OpsServer": "Application Service",
            "ManagerParam": "Parameter Settings",
            "ManagerGuide": "Deployment Guidelines",
            "ManagerLicense": "License Management",

            //CMQ消息队列中间件
            "Server": "Queue service",
            "Topic": "Topic Subscription",
            "Zone": "Warehouse Management",
            "CMQCluster": "Cluster Management",
            "Signaling": "Signalling Service Management",
            "Access": "Access Management",
            "Publisher": "PushServer management",
            "Queue": "Queue Management",
            "ManagerTopic": "Subject management",

            /*织云*/
            "cocCmdbServer": "Server",
            "cocCmdbNetDevice": "Network Equipment",
            "cocCmdbIdc": "IDC",
            "cocCmdbIdcExport": "IDC export",
            "cocCmdbIdcLine": "IDC line",
            "cocCmdbCustomCategory": "Custom CI",
            "cocModuleManage": "Business",
            "cocMonitorServer": "Server Performance Monitoring",
            "cocMonitorNetdevice": "Network Equipment",
            "cocMonitorNetoutpoint": "IDC export",
            "cocMonitorNetspecialLine": "IDC line",
            "cocMonitorMiddleware": "Middleware Monitoring",
            "cocMonitorSelfdefineMonitor": "Custom Monitoring",
            "cocMonitorCustomBoard": "Custom Kanban",
            "cocAlarmOverview": "Alarm Overview",
            "cocAlarmSearch": "Alarm Enquiry",
            "cocAlarmStrategy": "Warning Strategy",
            "cocAlarmShield": "Alarm shield",
            "cocToolmarketToolist": "My tools",
            "cocToolmarketPublicAll": "All tools",
            "cocToolmarketPublicInit": "Initial chemical tool",
            "cocToolmarketPublicDiagnose": "Diagnostic tools",
            "cocToolmarketPublicRepaire": "Maintenance tools",
            "cocToolmarketPublicDown": "Off the shelf Tool",
            "cocToolmarketFlowlist": "Arrangement management",
            "cocToolmarketSchedulelist": "Timed task",
            "cocToolmarketLoglist": "Execution Record",
            "cocPackageList": "Package List",
            "cocCloudServer": "Host Resource Pool",
            "cocCloudUsers": "Cloud Account Management",
            "cocTenantuserUserlist": "User Management",
            "cocTenantuserGrouplist": "User Group Management",
            "cocTenantuserAuthlist": "Authority Management",
            "cocPasswordlib": "Password base",
            "cocOperationlog": "Operation Log",
            /*tbds*/
            "tbMyApply": "Data in",
            "tbHippoAccess": "The hippo management",
            "tbTdMertrics": "Progress Monitoring",
            "tbWherehows": "Blood analysis",
            "tbEasycountTaskmanage": "Task management",
            "tbEasycountDbmanage": "Library Table Template Management",
            "tbWorkflowCanvas": "Workflow",
            "tbWorkflowList": "Task management",
            "tbWorkflowServer": "Server Configuration",
            "tbReportList": "Self-report",
            "tbSupersqlHive": "Data Interaction",
            "tbDataDbmanage": "Library Table Management",
            "tbDataMeta": "Data affinity",
            "tbDataMap": "Data Extraction",
            "tbOperationOverview": "Overall Overview",
            "tbOperationComponents": "System Operation and Maintenance",
            "tbOperationAccesscontrol": "Access Management",
            "tbOperationBasefs": "Document Management",
            "tbOperationMonitor": "Monitoring alarm",
            "tbPlatformProject": "Project Management",
            "tbPlatformResource": "Resource management",
            "tbPlatformUsermanage": "User Management",
            "tbPlatformSystem": "System Settings",
            "tbMlEngineer": "My Project",
            "tbMlOthers": "Collaborative engineering",
            "tbMlModelService": "Model Service",
            "tbMlModelManage": "Model management",
            "tbMlResourceManage": "Resource management",
            "tbMlModuleManage": "Module Management",
            "tbMlAdminControl": "Control console",
            "paas": "Paass authorization",
            "objectStorage": "Object Storage",
            "objectStorageSetting": "Object storage Settings",
            "objectStorageUser": "Object Storage User",
            "shares": "File Storage",
            "cephpg": "Ceph PG Management",
            "adserver": "AD domain service settings",

            /*TCS*/
            "TCS": "TCS",
            "TCS_overview_v": "Overview",
            "TCS_overview": "Overview",
            "TCS_platform_management": "Platform service",
            "TCS_platform_cluster_overview": "Cluster overview",
            "TCS_platform_cluster_management": "Cluster management",
            "TCS_platform_node_management": "Node management",
            "TCS_platform_pod_management": "POD management",
            "TCS_cbs": "Cloud hard disk (CBS)",
            "TCS_cbs_operation": "Operational data",
            "TCS_cbs_operation_overview": "Overview",
            "TCS_cbs_operation_trend": "Operating trend",
            "TCS_cbs_operation_user_resource": "User resource statistics",
            "TCS_cbs_management": "Cloud hard disk management",
            "TCS_cbs_snapshot_manage": "Snapshot management",
            "TCS_cbs_container_management": "Container management",
            "TCS_cbs_management_format": "Specification definition",
            "TCS_cbs_management_sub": "Cloud management",
            "TCS_cbs_snapshot_manage_list": "Snapshot list",
            "TCS_cbs_snapshot_manage_policy": "Regular snapshot strategy",
            "TCS_cbs_cluster_management": "Cluster management",
            "TCS_cbs_pool_monitor": "Performance monitoring",
            "TCS_cbs_storage_model_management": "Storage model management",
            "TCS_cbs_storage_pool_management": "Storage pool cluster",
            "TCS_cbs_snapshot_cluster": "Snapshot cluster",
            "TCS_cbs_zookeeper_cluster": "ZooKeeper cluster",
            "TCS_cbs_user_resource_pool": "User resource pool",
            "TCS_cbs_performance_monitor": "Storage pool performance monitoring",
            "TCS_cbs_node_xm_monitor": "Storage node performance monitoring",
            "TCS_cbs_cloud_disk_monitor": "Cloud hard disk performance monitoring",
            "TCS_cbs_wlyp_monitor": "Physical hard disk performance monitoring",
            "TCS_cbs_task_management": "Task management",
            "TCS_cbs_disk_task": "Cloud hard disk mission",
            "TCS_cbs_snapshot_task": "Snapshot task",
            "TCS_cbs_cloud_disk_qy_disk": "Cloud hard disk migration task",
            "TCS_cbs_cccb_task": "Storage pool small surface task",
            "TCS_cbs_wldisk_edit_task": "Physical hard disk replacement task",
            "TCS_cbs_cluster_yw_task": "Cluster operation and maintenance task",
            "TCS_cbs_event_alert": "Event query",
            "TCS_cbs_sj_management": "Upgrade management",
            "TCS_cbs_event_alert_sub": "Alarm event",
            "TCS_cbs_event_cluster": "Cluster event",
            "TCS_cbs_pool_cluster_sj": "Storage pool cluster upgrade",
            "TCS_cbs_snapshot_cluster_sj": "Snapshot cluster upgrade",
            "TCS_cbs_task_cluster_sj": "Upgrade task",
            "TCS_monitor_center": "monitoring Center",
            "TCS_monitor_overview": "Monitoring overview",
            "TCS_monitor_selete": "Alarm query",
            "TCS_monitor_srategy": "Alarm strategy",
            "TCS_monitor_even_selete": "Event query",
            "TCS_monitor_shield": "Alarm shield",
            "TCS_monitor_srategy_model": "Policy template",
            "TCS_monitor_message_rule": "Notification rules",
            "TCS_monitor_cloud_index": "Cloud product indicator",
            "TCS_monitor_tcns_index": "TCNS monitor panel",
            "TCS_monitor_set": "Monitoring settings",
            "TCS_image_house": "Mirror warehouse",
            "TCS_image_public": "Public warehouse",
            "TCS_image_private": "Private warehouse",
            "TCS_cmdb": "Business cmdb",
            "TCS_cmdb_overview": "Overview",
            "TCS_cmdb_select": "Global search",
            "TCS_cmdb_host_resource": "Host resources",
            "TCS_cmdb_business_resource": "Business resources",
            "TCS_cmdb_business_management": "Business management",
            "TCS_cmdb_model_management": "Model management",

            "TCS_message_management": "Message query",
            "TCS_message_query": "Mail query",
            "TCS_message_short_query": "SMS query",

            "TCS_log_center": "Log center",
            "TCS_log_query": "Log search",
            "TCS_log_access": "Log Access",
            "TCS_log_check": "Log event",
            "TCS_log_policy": "Event strategy",

            "TCS_user": "User center",
            "TCS_user_management": "user",
            "TCS_user_group": "user group",
            "TCS_user_role": "Role Permissions",
            "TCS_user_role_policy": "Permission strategy",
            "TCS_user_check": "User audit",
            "TCS_user_log": "Operating log",

            "TCS_codingDevOps_managemnet": "Developer tool",
            "TCS_codingDevOps": "CodingDevOps",

            "TCS_component_management": "Component management",
            "TCS_component_list": "Component list",
            "TCS_component_from": "Component specification",
            "TCS_cos": "Object storage (COS)",
            "TCS_cos_overview": "Overview",
            "TCS_cos_bucket": "Memory barrel list",
            "TCS_cos_monitor": "Monitoring report",
            "TCS_cos_monitor_report_basic": "Basic data statistics",
            "TCS_cos_monitor_report_code": "Return code statistics",
            "TCS_cos_access": "Access layer operation and maintenance",
            "TCS_cos_access_cluster": "Cluster management",
            "TCS_cos_access_machine": "Machine management",
            "TCS_cos_access_service": "Service management",
            "TCS_cos_access_task": "Task management",
            "TCS_cos_index": "Index transporter",
            "TCS_cos_index_cluster_info": "Cluster details",
            "TCS_cos_index_diagnose": "Cluster diagnosis",
            "TCS_cos_index_log": "Log search",
            "TCS_cos_index_instance_profilling": "Instance performance analysis",
            "TCS_cos_store": "Storage operation and maintenance",
            "TCS_cos_store_cluster_overview": "Cluster overview",
            "TCS_cos_store_cluster_search": "Cluster managementter management",
            "TCS_cos_store_machine_search": "Machine query",
            "TCS_cos_store_service_search": "Service query",
            "TCS_cos_store_task_manage": "Task management",
            "TCS_cos_store_libra_task": "Libra task",
            "TCS_cos_store_version_manage": "Version management",
            "TCS_cos_store_disk_replace": "Hard disk replacement task",
            "TCS_cos_monitor_alarm": "Monitor alarm",
            "TCS_cos_monitor_graph_main": "Overall view",
            "TCS_cos_monitor_graph_detail": "Detailed view",
            "TCS_cos_monitor_alert_config": "Alarm configuration",
            "TCS_cos_monitor_alarm_sj": "Operational data",
            "TCS_cos_operate_overview": "Operation overview",
            "TCS_cos_operate_user": "User data",
            "TCS_cos_user_manage": "User Management",
            "TCS_cos_user_bucket_manage": "Buket Management",
            "TCS_cos_user_frequenc_manage": "Frequency control management",
            "TCS_cos_user_flow_manage": "Flow control management",
            "TCS_cos_user_quota_manage": "Quota management",
            "TCS_db_redis": "Database",
            "TCS_db_redis_equipment": "Device management",
            "TCS_db_redis_instacne": "Example list",
            "TCS_db_redis_process": "Process list",
            "TCS_db_redis_alarm": "Alarm list",
            "TCS_db_sql": "TBSQL (Red Rabbit)",
            "TCS_db_sql_chitu": "TBSQL",
            "TCS_tsf": "Micro Service Platform (TSF)",
            "TCS_tsf_resource": "Resource operation",
            "TCS_tsf_resource_basic": "Basic module management",
            "TCS_tsf_resource_zone": "Available area management",
            "TCS_tsf_resource_config": "Parameter configuration",
            "TCS_tsf_resource_guide": "Deployment",
            "TCS_tsf_tool_log_search": "Log search",
            "TCS_tsf_tool_topology": "Service dependency topology",
            "TCS_tsf_tool_trace": "Call chain query",
            "TCS_tsf_tool": "Operation tool",
            "TCS_tsf_monitor": "monitor",
            "TCS_tsf_monitor_module": "Module monitoring",
            "TCS_tsf_monitor_resource": "Resource monitoring",
            "TCS_tsf_manage_liccense": "License management",
            "TCS_tsf_version_manage": "Version Information",
            "TCS_tenant": "Micro service platform (tenant)",
            "TCS_tenant_overview": "Overview",
            "TCS_tenant_cluster": "Cluster",
            "TCS_tenant_namespace": "Namespaces",
            "TCS_tenant_group": "Deployment group",
            "TCS_tenant_apply_manage": "Application management",
            "TCS_tenant_service_manage": "Service governance",
            "TCS_tenant_config": "Configuration management",
            "TCS_tenant_config_app": "Application configuration",
            "TCS_tenant_config_global": "Global configuration",
            "TCS_tenant_config_template": "Configuration template",
            "TCS_tenant_config_file": "File configuration",
            "TCS_tenant_log": "Log service",
            "TCS_tenant_log_config": "Log configuration",
            "TCS_tenant_log_retrieve": "Log search",
            "TCS_tenant_log_alert": "Log alarm",
            "TCS_tenant_depend_on": "Dependency analysis",
            "TCS_tenant_depend_on_service": "Service dependence expansion",
            "TCS_tenant_depend_on_trace": "Call chain query",
            "TCS_tenant_monitor": "monitor",
            "TCS_tenant_monitor_service": "Service monitoring",
            "TCS_tenant_monitor_jvm": "JVM monitoring",
            "TCS_tenant_gray_scale": "All-link grayscale release",
            "TCS_tenant_scalable": "Elastic telescopic",
            "TCS_tenant_micro_service": "Micro service gateway",
            "TCS_tenant_micro_service_gateway": "Gateway monitoring",
            "TCS_tenant_micro_service_group": "Group management",
            "TCS_tenant_micro_service_plugin": "Plug-in management",
            "TCS_tenant_micro_service_redirect": "Redirect configuration",
            "TCS_tenant_distributed": "Distributed task scheduling",
            "TCS_tenant_distributed_task": "Task management",
            "TCS_tenant_distributed_execute_records": "Execute record",
            "TCS_tenant_distributed_workflow_manage": "Workflow management",
            "TCS_tenant_image_manage": "Mirror warehouse",
            "TCS_tenant_template_manage": "Service",
            "TCS_tenant_role_manage": "Role management",
            "TCS_tenant_data_manage": "Data set management",
            "TCS_tenant_operate_log": "Operation record",
            "TCS_tdmq": "Message Queue (TDMQ)",
            "TCS_tdmq_cluster_manage": "Cluster management",
            "TCS_tdmq_namespace": "Namespaces",
            "TCS_tdmq_topic_manage": "Topic Management",
            "TCS_tdmq_message_search": "Message query",
            "TCS_tdmq_role_manage": "Role management",
            "TCS_tdmq_cmq": "CMQ",
            "TCS_tdmq_cmq_queue": "Queue service",
            "TCS_tdmq_cmq_topic": "Subscription",
            "TCS_tdmq_operate": "Operation and maintenance management",
            "TCS_tdmq_operate_cluster": "Cluster management",
            "TCS_tdmq_operate_cluster_broker": "Broker",
            "TCS_tdmq_operate_cluster_bookie": "Bookie",
            "TCS_tdmq_operate_enviroment": "Namespaces",
            "TCS_tdmq_operate_topic": "Topic Management",
            "TCS_tdmq_operate_subsciption": "Subscription management",
            "TCS_tdmq_operate_producer": "Relations of production",
            "TCS_tdmq_operate_consumer": "Consumption management",
            "TCS_km8": "Secretary management service (KM8)",
            "TCS_km8_kms_key": "User's key",
            "TCS_api": "API official website",
            "TCS_api_gateway_overview": "Overview",
            "TCS_api_gateway_service": "Serve",
            "TCS_api_gateway_plugin": "Plug-in",
            "TCS_api_gateway_use_plan": "Use plan",
            "TCS_api_gateway_app": "application",
            "TCS_api_gateway_key": "Key",
            "TCS_api_resource": "Resource management",
            "TCS_api_resource_index": "Master management",
            "TCS_api_resource_cluster": "Cluster management",
            "TCS_api_resource_ld": "LD management",
            "TCS_api_resource_vip": "VIP management",
            "TCS_api_resource_vip_group": "VIP group management",
            "TCS_api_service": "Service management",
            "TCS_api_service_statistics": "Service statistics",
            "TCS_api_resource_service_statistics": "Certificate management",
            "TCS_ckafka": "Message Queue (CKAFKA)",
            "TCS_ckafka_instance_list": "Example list",
            "TCS_ckafka_operate": "Operation and maintenance management",
            "TCS_ckafka_operate_instance": "Instance management",
            "TCS_ckafka_operate_cluster": "Cluster management",
            "TCS_ckafka_operate_broker": "Broker管理",
            "TCS_ckafka_operate_master": "Master management",
            "TCS_apply_center": "application Center",
            "TCS_apply_center_application": "Application overview",
            "TCS_apply_center_app_list": "Application List",
            "TCS_apply_center_app_market": "Application market",
            "TCS_apply_center_app_template": "Template release",
            "TCS_apply_center_project_manage": "project management",
            "TCS_apply_center_config_manage": "Configuration management",
            "overview-tcs": "Overview",
            "overviewcluster-tcs": "Cluster overview",
            "cluster-tcs": "Cluster management",
            "cluster-node-tcs": "Node management",
            "cluster-pod-tcs": "POD management",
            "cwhistle-overview-tcs": "Monitoring overview",
            "warning-search-tcs": "Alarm query",
            "tactics-management-tcs": "Alarm strategy",
            "cwhistle-event-tcs": "Event query",
            "warning-block-tcs": "Alarm shield",
            "tactics-template-tcs": "Policy template",
            "notice-rules-tcs": "Notification rules",
            "setting-tcs": "Monitoring settings",
            "tcs-monitor-tcs": "TCNS monitor panel",
            "product-index-tcs": "Cloud product indicator",
            "over_view-tcs": "Overview",
            "selling-trend-tcs": "Operating trend",
            "cbsuser-message-tcs": "User resource statistics",
            "cbs-spec-tcs": "Specification definition",
            "cbs-cdisk-tcs": "Cloud management",
            "snapshot-list-tcs": "Snapshot list",
            "snapshot-policy-tcs": "Regular snapshot strategy",
            "container-management-tcs": "Container management",
            "storage-model-tcs": "Storage model management",
            "storage-cluster-tcs": "Storage model management",
            "snapshot-cluster-tcs": "Snapshot cluster",
            "zk-cluster-tcs": "ZooKeeper cluster",
            "resource-cluster-tcs": "User resource pool",
            "cdepot-monitor-overview-tcs": "Storage pool performance monitoring",
            "cdepot-cell-monitor-overview-tcs": "Storage node performance monitoring",
            "cdisk-moniter-overview-tcs": "Cloud hard disk performance monitoring",
            "cphy-disk-monitor-overview-tcs": "Physical hard disk performance monitoring",
            "op-task-list-tcs": "Cluster operation and maintenance task",
            "replace-disk-task-list-tcs": "Physical hard disk replacement task",
            "storage-pool-task-list-tcs": "Storage pool small surface task",
            "cdisk-migrate-task-list-tcs": "Cloud hard disk migration task",
            "snapshot-task-list-tcs": "Snapshot task",
            "task-list-tcs": "Cloud hard disk mission",
            "event-query-tcs": "Alarm event",
            "cluster-query-tcs": "Cluster event",
            "storages-cluster-upgrade-tcs": "Storage pool cluster upgrade",
            "snapshots-cluster-upgrade-tcs": "Snapshot cluster upgrade",
            "upgrade-task-tcs": "Upgrade task",
            "image-image-tcs": "Public warehouse",
            "image-private-tcs": "Private warehouse",
            "cmdb-cloud-tcs": "Overview",
            "cmdb-cloud-search-tcs": "Global search",
            "cmdb-cloud-host-resource-tcs": "Host resources",
            "cmdb-cloud-firm-tcs": "Business resources",
            "cmdb-cloud-biz-tcs": "Business management",
            "cmdb-cloud-model-tcs": "Model management",
            "info-email-query-tcs": "Mail query",
            "info-sms-query-tcs": "SMS query",
            "logs-overview-searchy-tcs": "Log search",
            "logs-integration-tcs": "Log Access",
            "logs-incident-record-tcs": "Log event",
            "logs-incident-tcs": "Event strategy",
            "usercenter-admin-tcs": "user",
            "usercenter-admin-group-tcs": "user group",
            "usercenter-role-tcs": "Role Permissions",
            "usercenter-policy-tcs": "Permission strategy",
            "usercenter-qualification-manager-tcs": "User audit",
            "usercenter-operation-log-tcs": "Operating log",
            "coding-tcs": "CodingDevOps",
            "components-list-tcs": "Component list",
            "components-specifications-tcs": "Component specification",
            "cos5-tcs": "Overview",
            "cos5-bucket-tcs": "Memory barrel list",
            "access-cluster-management-tcs": "Cluster management",
            "access-machine-management-tcs": "Machine management",
            "access-service-management-tcs": "Service management",
            "access-task-management-tcs": "Task management",
            "cos5-report-basic-tcs": "Basic data statistics",
            "cos5-report-httpcode-tcs": "Return code statistics",
            "index-cluster-info-tcs": "Cluster details",
            "index-cluster-diagnose-tcs": "Cluster diagnosis",
            "index-log-search-tcs": "Log search",
            "index-instance-profilling-tcs": "Instance performance analysis",
            "cluster-overview-tcs": "Cluster overview",
            "cluster-management-tcs": "Cluster management",
            "machine-management-tcs": "Machine query",
            "service-management-tcs": "Service query",
            "task-management-tcs": "Task management",
            "libra-task-tcs": "Libra task",
            "disk-replace-tcs": "Hard disk replacement task",
            "version-management-tcs": "Version management",
            "monitor-alarm-tcs": "Overall view",
            "graph-details-tcs": "Detailed view",
            "operation_overview-tcs": "Buket Management",
            "user_data-tcs": "User data",
            "operation_overview-tcs":"Buket Management",
            "frequenc_management-tcs": "Frequency control management",
            "flow_management-tcs": "Flow control management",
            "quota_management-tcs": "Quota management",
            "equipment-tcs": "Device management",
            "instance-tcs": "Example list",
            "process-tcs": "Process list",
            "alarm-tcs": "Alarm list",
            "chitu-tcs": "TBSQL",
            "tsf-manager-basic-tcs": "Basic module management",
            "zone-manager-tcs": "Available area management",
            "config-params-tcs": "Parameter configuration",
            "guide-tcs": "Deployment",
            "logsearch-tcs": "Log search",
            "topology-tcs": "Service dependency topology",
            "trace-tcs": "Call chain query",
            "monitor-module-tcs": "Module monitoring",
            "monitor-resource-tcs": "Resource monitoring",
            "license-tcs": "License management",
            "version-tcs": "Version Information",
            "tfs-tenant-index-tcs": "Overview",
            "tfs-tenant-cluster-tcs": "Cluster",
            "tfs-tenant-namespace-tcs": "Namespaces",
            "tfs-tenant-group-tcs": "Deployment group",
            "tfs-tenant-app-tcs": "Application management",
            "tfs-tenant-service-tcs": "Service governance",
            "config-app-tcs": "Application configuration",
            "config-tcs": "Global configuration",
            "config-template-tcs": "Configuration template",
            "config-file-tcs": "File configuration",
            "log-config-tcs": "Log configuration",
            "log-search-tcs": "Log search",
            "log-alarm-tcs": "Log alarm",
            "tsf-topology-tcs": "Service dependence expansion",
            "topology-trace-tcs": "Call chain query",
            "monitor-service-tcs": "Service monitoring",
            "monitor-jvm-tcs": "JVM monitoring",
            "grayscale-tcs": "All-link grayscale release",
            "scalable-tcs": "Elastic telescopic",
            "gateway-overview-tcs": "Gateway monitoring",
            "api-group-tcs": "Group management",
            "plugin-tcs": "Plug-in management",
            "gateway-rewrite-tcs": "Redirect configuration",
            "tct-tas-tcs": "Task management",
            "tct-exec-tcs": "Execute record",
            "tct-workflow-tcs": "Workflow management",
            "image-tcs": "Mirror warehouse",
            "template-tcs": "Service",
            "role-tcs": "Role management",
            "program-tcs": "Data set management",
            "operation-log-tcs": "Operation record",
            "env-tcs": "Namespaces",
            "topic-tcs": "Topic Management",
            "message-tcs": "Message query",
            "cmq-queue-tcs": "Queue service",
            "cmq-topic-tcs": "Subscription",
            "cluster-tcs-operate": "Cluster management",
            "cluster-broker-tcs": "Broker",
            "cluster-bookie-tcs": "Bookie",
            "environment-tcs": "Namespaces",
            "subscription-tcs": "Subscription management",
            "producer-tcs": "Relations of production",
            "consumer-tcs": "Consumption management",
            "kms-tcs": "User's key",
            "index-tcs": "Overview",
            "service-tcs": "Serve",
            "api-plugin-tcs": "Plug-in",
            "plan-tcs": "Use plan",
            "app-tcs": "application",
            "key-tcs": "Key",
            "apigateway-manager-index-tcs": "Master management",
            "ld-tcs": "LD management",
            "vip-tcs": "VIP management",
            "vip-group-tcs": "VIP group management",
            "certificate-tcs": "Certificate management",
            "service-statistics-tcs": "Service statistics",
            "ckafka-index-tcs": "Example list",
            "ckafka-manager-index-tcs": "Instance management",
            "broker-tcs": "Broker Management",
            "master-tcs": "Master management",
            "application-tcs": "Application overview",
            "app-deploy-tcs": "Application List",
            "app-market-tcs": "Application market",
            "app-template-tcs": "Template release",
            "project-tcs": "project management",
            "alert-configure-tcs": "Alarm configuration",
            "bucket_management-tcs": "Buket Management",

            "localDisk": "Local Disk",
            "cloudDatabase":"Cloud database",
            "recycleDb":"Cloud database recycle bin",
            // backupService
            "backupServiceConfig":"Backup Configuration",
            "backup-service-config":"Backup Configuration",
            "backupJob":"Data Backup",
            "backup-job":"Data Backup",
            "backupRecoverJob":"Data Recovery",
            "backup-recover-job":"Data Recovery",
            "backupCleanJob":"Data Cleanup",
            "backup-clean-job":"Data Cleanup",
            "backupStrategy":"Backup Strategy",
            "backup-strategy":"Backup Strategy",
        },
        "quota": {
            "sort": "Sort",
            "type": "Ticket Type",
            "name": "Name",
            "labelTitle": "name",
            "description": "Description",
            "domainUid": "Domain Name",
            "projectname": "Project Name",
            "defaultNum": "Default Quota ",
            "quotaName": "Quota Name",
            "network": "Network",
            "subnet": "Subnet",
            "port": "Port",
            "key_pairs": "Key Pairs",
            "securityGroups": "Security Groups",
            "security_group": "Security Groups",
            "volumes": "Volumes",
            "securityGroupRules": "Security Group Rule",
            "security_group_rule": "Security Group Rule",
            "server_groups": "Instance Group",
            "server_group_members": "Instance Group Member",
            "router": "Router",
            "instances": "Instance",
            "cores": "CPU Core",
            "ram": "Ram(GB)",
            "ramGb": "RamGb(GB)",
            "snapshots": "Snapshots",
            "floatingip": "Floating IP",
            "injectedFiles": "Injected Files",
            "injected_files": "Injected Files",
            "injectedFileContentBytes": "Injected Files Content(B)",
            "injected_file_content_bytes": "Injected Files Content Size(B)",
            "injected_file_path_bytes": "Injected Files Path Size(B)",
            "gigabytes": "Disk(GB)",
            "metadataItems": "Metadata Items",
            "metadata_items": "Metadata Items",
            "hdd": "Functional Disk(GB)",
            "phy_instances": "Physical Cloud Host (s)",
            "totalQuota": "Total Quota",
            "alearyAllocate": "Already Allocated",
            "errorMessage": {
                "insufficient": "Project Quota Less Than Department Quota",
                "quotaNum": "The used quota of Department is",
                "atleast": "The min number of Quota",
                "proUsed": "Project used quota",
                "largerThanLeftQuota": "The parameter must not be greater than the number of remaining available quotas for the corresponding resources of its department"
            },
            "phy_instances":"Physical cloud host (a)",
            "backups": "Backup (one)",
            "backup_gigabytes": "Backup Capacity (GB)",
            "backupsNum":"Backup quantity (one)",
            "projectPhysicalMachine":"Project physical machine",
            "physicalMachinesNumber":"Number of physical machines",
            "hardwareConfig":"Hardware Configuration",
            "operatingSystemVersion":"Supported operating system version",
            "packageDownload":"Installation package download",
            "browserAccessUrl":"At the included physical machine (please configure the network first, this machine can access the cloud tube), the browser accesses the URL",
            "excutingOrder":"At the included physical machine (please configure the network first, this machine can access the cloud tube), execute the command:",
            "packageCompression":"Install the package solution compression",
            "compressedDirectory":"Compress the installation package to the directory 'C: \ Telegraf \'",
            "executeCommandDirectory":"In the storage installation package directory, execute the command:",
            "AutoinstallStart":"Automatic installation and start",
            "runsCmd":"The administrator runs CMD as followed by:",
            "decompressionDirectory":"In the decompression directory, perform the following command:",
            "physicalMachineApply":"Physical machine application",
            "hardDisk":"hard disk"
        },
        "config": {
            "isCanConfig": "Configurable",
            "tallConfig":"high configuration（16core16G）",
            "centreConfig":"medium configuration（8core8G）",
            "lowConfig":"low-level configuration（4core4G）"
        },
        "cloud": {
            "cloudType": "Cloud Type",
            "selectCloudType": "Select Cloud Type",
            "Active": "Active",
            "SecretId": "Secret Id",
            "SecretKey": "Secret Key",
            "ChooseCloud": "Select Cloud Type",
            "AccessKeyID": "Access Key ID",
            "AccessKeySecret": "Access Key Secret",
            "vCenterUrl": "vCenterUrl",
            "vCenterName": "vCenterName",
            "vCenterUserName": "VCenter User name",
            "vCenterPassword": "vCenterPassword",
            "vmConsoleUrl": "vmConsoleUrl",
            "vmConsoleTip_1": "vmConsole tips",
            "vmConsoleTip_2": "Please refer to the link format in the virtual machine console in vSphere Web Client to select the default HTTPS",
            "accessKeyId": "accessKeyId",
            "accessKeySecret": "accessKeySecret",
            "version": "version",
            "awsAccessKeyId": "Access key ID",
            "awsSecretAccessKey": "Private Access Key"
        },
        "logo": {
            "name": "Name",
            "params": "Parameter Value Input/Operation",
            "des": "Description",
            "loginBg": "Login interface",
            "browserLabelLogo": "Browser Tab page logo",
            "SystemPageLogo": "System page logo",
            "platformName": "Platform Name",
            "CopyrightInformation": "Copyright information",
            "tips_1": "The logo size is 16*16 pixels",
            "tips_2": "Logo format is icon picture",
            "tips_3": "The size of the logo file does not exceed 20KB",
            "tips_4": "The maximum size of logo is 185*60 pixels",
            "tips_5": "Logo format is transparent. SVG vector file",
            "tips_6": "The size of the logo file should not exceed 30KB",
            "tips_7": "Click image upload",
            "tips_8": "Format requirements",
            "login_bg_tips1": "Login background size is 1920*1080 pixels;",
            "login_bg_tips2": "Login background format.jpg,.png file",
            "login_bg_tips3": "Login background file size not exceeding 1MB",
            "showButton": "display",
            "hideButton": "hide",
            "showVersion": "Display Copyright Information",
            "error_tips": "The picture format is not correct",
            "platform_tips": "Please enter the platform name",
            "copyright_tips": "Please enter copyright information",
            "logo_top_tips": "If the picture content has not been changed (upload new logo), the default logo will remain unchanged"
        },
        "general": {
            "timeout": "System Timeout",
            "selecTimeout": "Please select the system timeout time",
            "timeoutDesc": "If the user is logged in, no operation within a configured timeout, the system will automatically cancel, need to re login again, set up a relatively short time, can effectively guarantee the security of the system, set up a long time, can save the user login system, the user can according to their own needs to set.",
            "expirationTimeLabel": "Expiration Time(Hour)",
            "expirationTime": "Expiration Time",
            "expirationTimeDesc": "Users delete resources temporarily stored in the recycle bin, the default time after the system will delete the content of the recycle bin, users can set the resource in the recycle bin storage time as needed.",
            "dataCenter": "Data Center",
            "selectDataCenter": "Please select the Data Center",
            "upsType": "UPS type selection",
            "selectUpsType": "Please select the UPS type",
            "modifying": "Update configuration in",
            "modifySuccess": "Modified successfully",
            "modifyFailed": "Failed to modify",
            "time": {
                "5min": "Five minutes",
                "10min": "Ten minutes",
                "15min": "15 minutes",
                "30min": "30 minutes",
                "1hour": "1 hour",
                "2hour": "2 hours",
                "3hour": "Three hours",
                "6hour": "6 hours"
            },
            "day": {
                "10day": "Ten days",
                "30day": "30 days",
                "60day": "60 days",
                "90day": "90 days"
            },
            "ups": {
                "apc": "Schneider",
                "gcc": "United Cloud Sky"
            },
            "faultSelfhealing": "Whether to turn on Ceph for self-healing of hard disk failure",
            "faultSelfhealingDesc": "When enabled, the system will automatically kick ceph drives out of the ceph cluster when they are detected to have failed and add them to the Ceph cluster after the same slot has been replaced with a healthy hard drive.",
            "cpuAlarmThreshold": "CPU alarm Threshold",
            "cpuAlarmThresholdDesc": "Set the CPU alarm level and threshold to trigger an alarm prompt when CPU utilization exceeds this value.",
            "memAlarmThreshold": "Memory Alarm Threshold",
            "memAlarmThresholdDesc": "Set the memory alarm level and threshold to trigger an alarm prompt when memory usage exceeds this value",
            "cephAlarmThreshold": "Ceph Alarm threshold",
            "cephAlarmThresholdDesc": "Set Ceph alarm levels and thresholds to trigger alarm alerts when Ceph utilization exceeds these values.",
            "criticalAlarm": "Severe warning",
            "normalAlarm": "General alarm",
            "enableVerificationCode": "Whether to enable verification code",
            "enableVerificationCodeDesc": "Do you need to enter a verification code when logging in",
            "enableEmailLogin": "Whether to enable mailbox login",
            "enableEmailLoginDesc": "When enabled, you can log in by email",
            "enableCasLogin": "Whether to enable CAS login",
            "enableCasLoginDesc": "When enabled, you can log in with CAS.",
            "cloneChain": "Clone chain optimization",
            "cloneChainTitle": "• Optimizing the disk space occupied by the link clone of all CEPH storage pools to avoid creating a host failure. If there is a large number of links in the system CEPH storage pool, this feature is recommended.",
            "cloneChainTitleTwo": "• This feature is turned on by default, and the partial disk IO is occupied, it is recommended to set up at least once a week, 50 units per time.",
            "functionSwitch": "Function switch",
            "periodSelectionBox": "Cycle selection box",
            "vmNumber": "Number of virtual machines",
            "vmNumberPlacehold": "Please enter 1-1000 integers",
            "cloneChainWeek": "week",
            "cloneChainHour": "Time",
            "cloneChainMinute": "Minute",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday",
            "everyday": "every day",
            "everyWeek": "weekly",
            "everymonth": "per month",
            "point": "point",
            "mark": "No",
            "cloudParameters":"Cloud tube parameters",
            "cloudServiceParameters":"Cloud service parameters",
            "setItem":"setItem",
            "describe":"describe",
            "autoLogout":"If the user is logged in, there is no operation in the configuration time, the system will log out automatically.",
            "quotaConfigSwitch":"Quota configuration switch",
            "quotaConfigSwitchTip":"The relationship between the control department and the project quota is closed, and the single project quota cannot be greater than the department, on time, all project quotas and quotas that cannot be greater than their sectors.",
            "editPassword":"Whether the new user enforces the password",
            "editPasswordTip":"Enabled for non-Admin for new users to change the password",
            "passwordTimeout":"Whether to enable user password timeout",
            "passwordTimeoutTip1":"When enabled, the non-admin user password expires to change the password.",
            "passwordTimeoutTip2":"(From the user's creation, expire after 60 days)",
            "resourceSync":"Resource data synchronization",
            "resourceSyncTip":"Users can select a time interval for timing resource data synchronization (if you choose 10 minutes, click Save, indicate that resource data synchronization is performed every 10 minutes), or click the immediate synchronization button to instantly perform resource data synchronization",
            "immeSync":"Synchronize immediately",
            "notSyncOperate":"Do not follow frequent resource synchronization operations frequently",
            "syncPhysicalNode":"Synchronous physical node GPU information",
            "syncPhysicalNodeTip":"When enabled, get all node physical GPU configuration information for cloud platform",
            "allowUsbRedirection":"Allow USB redirection",
            "allowUsbRedirectionTip":"When enabled, the new virtual machine automatically assigns USB redirect port.",
            "loadBalancing":"Advanced load balancing characteristics",
            "loadBalancingtTip":"Enable this setting Use HighClass load balancing to achieve better performance and more advanced features (requires license support and related docking configurations in advance)",
            "hostRecycle":"Cloud host recycle station expired time (day)",
            "hostRecycleTip":"Yun Host Recycling Station Resource Expired (Day), default 7 days, can be configured between 1 day and 30 days",
            "nodeSaveMemory":"Node Save Memory (GB)",
            "nodeSaveMemoryTip":"Minimum memory number for each node",
            "cpuException":"CPU exception",
            "cpuExceptionTip":"Normal configuration is 1-4, more than 4 risk, more than 8 may not use cloud main machine in the platform",
            "retryTimes":"Creating a cloud host failure Test number (times)",
            "retrySecond":"Creating a cloud host failure retry interval (seconds)",
            "expirationDay":"Data drive recycle station expired time (day)",
            "expirationDayTip":"Data drive recycle bin resource expired time (days), default 7 days, can be configured between 1 day and 30 days",
            "createDiskretestTimes":"Create a cloud hard disk retest (times)",
            "highlyAvailableTimes":"Highly available trigger (times)",
            "highAvailabilitySecond":"High availability detection interval (seconds)",
            "upsTypeSelection":"UPS type selection"
        },
        "aboutVersion": {
            "versionEnterprise": "versionEnterprise",
            "versionStand": "versionStand",
            "systemVersion": "System version"
        },
        "license": {
            "describe": "describe",
            "importsuccess": "Activate file import successfully",
            "importerror": "Inport File Error",
            "importFileSuccess": "Import File Success",
            "importFileError": "File imported in batches do not meet the requirements",
            "importerror":"Import file failed",
            "fileCheck": "The file format must be .pem and no more than 1M",
            "sn": "SN Number",
            "detail": "detail",
            "SNdetail": "SN details",
            "perpetual": "Perpetual",
            "title": "License",
            "versionType": "Version Type",
            "versionEnterprise": "Enterprise",
            "versionStand": "Stand",
            "applyActivationCode": "Get Machine Code",
            "importActivationCode": "Import Activation File",
            "machinecode": "Machine Code",
            "activationCode": "Activation File",
            "valid": {
                "true": "valid",
                "false": "invalid"
            },
            "is_valid": "is_valid",
            "now": "Current time",
            "end_time": "Activation expire date",
            "service_time": "Service validity",
            "available_node": "Available node",
            "current_node": "Current node",
            "checked_node": "Checked node",
            "format_error": "Update license failed：Bad license format",
            "check_error": "Update license failed：Check failed",
            "error": "Update license failed",
            "success": "Update license success",
            "explain": "Explain",
            "selectFile": "Select File"
        },
        "alert": {
            "floatingipDisassociationSuccess": "Floating IP disassociate success",
            "floatingipAssociationSuccess": "Floating IP associate success"
        },
        "bill": {
            "startTime": "startTime",
            "endTime": "endTime",
            "search": "search",
            "adminCount": "Statistics admin",
            "regionCount": "Region Count",
            "regionAmoutTotal": "Region Amout Total",
            "regionAmout": "Region Amout",
            "domainCount": "Domain Count",
            "domainCountDataNull": "Domain Count DataNull",
            "projectCount": "Project Count",
            "projectCountDataNull": "Project Count Data Null",
            "region": "data center",
            "regionName": "Region Name",
            "allRegion": "All Region",
            "choiceRegion": "Choice Region",
            "domainName": "Domain Name",
            "choiceDomain": "Choice Domain",
            "projectName": "Project Name",
            "choiceProject": "Choice Project",
            "userName": "User Name",
            "choiceUser": "Choice User",
            "prodType": "Product Type",
            "choiceProdType": "Choice Product Type",
            "enterprise": "Enterprise Name",
            "totalAmount": "Total Amount",
            "billStartAt": "Bill Start Time",
            "operate": "Operate",
            "detail": "detail",
            "resDetail": "Resource Detail",
            "productStatus": "Product Status",
            "productPrice": "Product Price",
            "download": "download",
            "consumption": "consumption",
            "vm": "VM",
            "vmMemory": "Memory",
            "cpu": "cpu",
            "memory": "memory",
            "volume": "volume",
            "vmVolume": "Volume",
            "floatingip": "floatingip",
            "router": "router",
            "snapshot": "snapshot",
            "loadbalancer": "loadbalancing(basic type)",
            "bandwidth": "bandwidth",
            "pm": "PM",
            "pmcpu": "pmCpu",
            "pmmemory": "pmMemory",
            "pmhd": "pmHd",
            "pmvolume": "pmVolume",
            "backupvolume": "backupvolume",
            "backupVolume": "Backup",
            "perhour": "perHour",
            "permonth": "perMonth",
            "chooseDate": "Select a date",
            "resTopUser": "Ranking of User Resources (before)",
            "resRank": "Name)",
            "resTopProject": "Ranking of Project Resources (first)",
            "resTopDomain": "Resource Ranking by Department (before)",
            "resTopRegion": "Ranking of Data Centre Resources (former)",
            "lb_cpu":"Load balancing (enhanced) CPU",
            "lb_memory":"Load balancing (enhanced) memory",
            "priceType":"priceType",
            "topRes": {
                "configPublicNetworkIP":"Configuring public IP Addresses",
                "queue":"Number of queues of a NIC",
                "hardDisk":"The hard disk parameters",
                "vmRankList": "VM",
                "cpuRankList": "CPU",
                "memoryRankList": "Memory",
                "chdRankList": "Volume",
                "snapshotRankList": "Snapshot",
                "routerRankList": "Router",
                "floatingipRankList": "FloatingIp",
                "loadbalancerRankList": "LoadBalance",
                "pmRankList": "PM",
                "pmCpuRankList": "PM CPU",
                "pmMemoryRankList": "PM Memory",
                "pmChdRankList": "PM Volume",
                "backupRankList": "Backup",
                "lbCpuRankList":"Load balancing (enhanced) CPU",
                "lbMemoryRankList":"Load balancing (enhanced) memory",
                "lbRankList":"Load balancing (enhanced)"
            },
            "error": {
                "pattern": "Please enter the correct time format,such as:yyyy-mm-dd",
                "gtTime": "The end time must be greater than or equal to the start time",
                "gtCurrTime": "The selection time is greater than the current date"
            },
            "timeType": {
                "realTime": "Real time",
                "sixHours": "6 hours",
                "oneDay": "One day",
                "threeDays": "The last three days",
                "oneWeek": "Nearly a week",
                "oneMonth": "Last month",
                "threeMonths": "Last March",
                "twelveHours": "12小时"
            },
            "billAction": "Billing Function",
            "billActionDescription": " The billing switch controls whether the data center is occupied by the cloud host, cloud hard disk, snapshot, router ,public network IP, loadbalance, bandwidth, backup, physical machine and other resources for billing.",
            "billStartTip_1": "the cloud platform will start charging at ",
            "billStartTip_2": "Start charging",
            "consumeStatistic": "Consume Statistic",
            "priceManage": "Price Manage",
            "consumeQuery": "Consumption Enquiry",
            "regionCost": "Region Cost",
            "totalCost": "TotalCost（yuan）",
            "costRatio": "Cost Ratio",
            "resourceRatio": "Resource ratio",
            "cost": "Cost（yuan）",
            "ratio": "Ratio",
            "resourceCost": "Resource Cost",
            "resource": "Resource",
            "resourceType": "Resource Type",
            "domainCost": "Domain Cost",
            "domain": "Domain",
            "allDomain": "All Domain",
            "backDomain": "Back to the department",
            "returnDatacenter": "Back to the data center",
            "resCostDistribution": "Resource Cost Distribution",
            "resDistribution": "Cloud Platform Resource Distribution",
            "name": "Name",
            "projectCost": "Project Cost",
            "project": "Project",
            "allProject": "All Project",
            "backProject": "Back to project",
            "userCost": "User Cost",
            "cvsDatacenter": "DataCenter,Cost（yuan）,Ratio",
            "cvsDomain": "Owner DataCenter, Domain, Cost(yuan)",
            "cvsProject": "Owner DataCenter, Domain, Project, Cost(yuan)",
            "cvsUser": "User, DataCenter, Domain, Project, Cost(yuan)",
            "billFileExport": "BillExport.csv",
            "user": "User",
            "allUser": "All Users",
            "allRes": "All resources",
            "fixedIps": "private IP",
            "floatingIps": "public IP",
            "cpuPeakMap": "CPU peak",
            "cpuPeakTimeMap": "CPU peak time",
            "memPeakMap": "memory peak",
            "memPeakTimeMap": "memory peak time",
            "iopsPeakMap":"disk IOPs peak (write)",
            "iopsPeakTimeMap":"disk IOPs peak time (write)",
            "iopsReadPeakMap":"disk IOPs peak (read)",
            "iopsReadPeakTimeMap":"disk IOPs peak time (read)",
            "iopsPeakMap": "disk IOPs peak",
            "iopsPeakTimeMap": "disk IOPs peak time",
            "networkCardEnterMap": "average value of network card incoming traffic",
            "networkCardEnterPeakMap": "peak network card incoming traffic",
            "networkCardEnterPeakTimeMap": "peak time of network card incoming traffic",
            "networkCardOutMap": "average value of network card outgoing traffic",
            "networkCardOutPeakMap": "peak network card traffic",
            "networkCardOutPeakTimeMap": "peak time of network card traffic",

            "productName": "Product Name",
            "moneyAmount_yuan": "Amount（yuan）",
            "moneyAmount": "Amount",
            "billStartTime": "Billing Start Time",
            "belong_project": "Belong Project",
            "belong_domain": "Belong Domain",
            "regionConsumeStatistic": "Region Consume Statistic",
            "domainConsumeStatistic": "Domain Consume Statistic",
            "projectConsumeStatistic": "Project Consume Statistic",
            "userConsumeStatistic": "User Consume Statistic",
            "regionResConsumeStatistic": "Region Resource Consume Statistic",
            "domainResConsumeStatistic": "Domain Resource Consume Statistic",
            "projectResConsumeStatistic": "Project Resource Consume Statistic",
            "userResConsumeStatistic": "User Resource Consume Statistic",
            "core": "core",
            "one": "one",
            "deleteTip": "Confirm Delete?",
            "userResBillDetailExport": "user Resource Bill Detail Export",

            "meteringView": "Metering View",
            "meteringStatistic": "Metering Statistic",
            "resUsageStatistic": "Resource Usage Statistic",
            "statistic": "Statistic",
            "pleaseSelect": "Please Select",
            "type": "Type",
            "query": "Query",
            "export": "Export",
            "hour": "Hour",
            "month": "Month",
            "amount": "Amount",
            "capacity": "Capacity",
            "userAmount": "User Amount",
            "currentUsingAmonut": "Current Usage",
            "alreadyUsedAmount": "Cumulative Usage",
            "resObj": "Resource Object",
            "statisticDimension": "Statistic Dimension",
            "timeFilter": "Screening Time",
            "pleaseSelectTime": "Please Select Time",
            "unSelect": "Unselected",
            "highest": "Highest",
            "lowest": "Lowest",
            "top": "Top",
            "top_name": "name",
            "unit": "Unit",
            "custom": "Custom",
            "recent_3d": "Recent 3 days",
            "recent_week": "Recent week",
            "recent_month": "Recent month",
            "recent_3month": "Recent 3 month",
            "meteringViewDataExport": "Metering View Data Export",
            "cols": {
                "vm_unit": "VM（One）",
                "vm_unit_hour": "VM（One-Hour）",
                "cpu_unit": "CPU（One）",
                "cpu_unit_hour": "CPU（One-Hour）",
                "memory_unit": "Memory（GB）",
                "memory_unit_hour": "Memory（GB-Hour）",
                "volume_unit": "Volume（GB）",
                "volume_unit_hour": "Volume（GB-Hour）",
                "snapshot_unit": "Snapshot（One）",
                "snapshot_unit_hour": "SnapShot（One-Hour）",
                "floationgip_unit": "Public Network IP",
                "floationgip_unit_hour": "FloatingIp（One-Hour）",
                "router_unit": "Router（One）",
                "router_unit_hour": "Router（One-Hour）",
                "loadBalance_unit": "Loadbalance（One）",
                "loadBalance_unit_hour": "Loadbalance（One-Hour）",
                "pm_unit": "PM（One）",
                "pm_unit_hour": "PM（One-Hour）",
                "pmcpu_unit": "PM CPU（One）",
                "pmcpu_unit_hour": "PM CPU（One-Hour）",
                "pmmemory_unit": "PM Memory（GB）",
                "pmmemory_unit_hour": "PM Memory（GB-Hour）",
                "pmvolume_unit": "PM Volume（GB）",
                "pmvolume_unit_hour": "PM Volume（GB-Hour）",
                "backup_unit": "Backup（GB）",
                "backup_unit_hour": "Backup（GB-Hour）"
            },
            "regionResMeteringStatistic": "Region Metering Statistic",
            "domainResMeteringStatistic": "Domain Metering Statistic",
            "projectResMeteringStatistic": "Project Metering Statistic",
            "userResMeteringStatistic": "User Metering Statistic",
            "regionResUsageDetail": "Region Resource Usage Detail",
            "domainResUsageDetail": "Domain Resource Usage Detail",
            "projectResUsageDetail": "Project Resource Usage Detail",
            "userResUsageDetail": "User Resource Usage Detail",
            "cpuUsage": "CPU Usage",
            "memoryUsage": "Memory Usage",
            "volumeUsage": "Volume Usage",
            "resUsageRankChartExport": "Resource Usage Chart Rank Export",
            "domianResUsageStatisticExport": "Domian Resource Usage Statistic Export",
            "projectResUsageStatisticExport": "Project Resource Usage Statistic Export",
            "vmResUsageStatisticExport": "VM Resource Usage Statistic Export",
            "pmResUsageStatisticExport": "PM Resource Usage Statistic Export",
            "vmCpuUsageStatistic": "VM CPU Usage Statistic",
            "vmMemoryUsageStatistic": "VM Memory Usage Statistic",
            "vmVolumeUsageStatistic": "VM Volume Usage Statistic",
            "pmCpuUsageStatistic": "PM CPU Usage Statistic",
            "pmMemoryUsageStatistic": "PM Memory Usage Statistic",
            "pmVolumeUsageStatistic": "PM Volume Usage Statistic",
            "userDetail": "User Detail",
            "billDetail": "Bill Detail",
            "closeBillingText":"Close billing",
            "closeBillMessageTitle":"After the function is turned off, the billing function will stop charging, and the current billing data will remain"
        },
        "price": {
            "prodType": "Product Type",
            "choiceProdType": "Choice Product Type",
            "choicePriceType": "Choice Price Type",
            "choiceRegion": "Please select the Data Center",
            "serialNumber": "Serial number",
            "enterpriseName": "Enterprise Name",
            "region": "Region",
            "allRegion": "All Region",
            "allType": "All Type",
            "unit": "Unit",
            "choiceUnit": "Please choice unit",
            "priceType": "Price Type",
            "allPriceType": "All Price Type",
            "billingType": "Billing Type",
            "choiceBillingType": "Please choice billing type",
            "price": "Price",
            "price_unit": "Price（yuan）",
            "price_unit_hour": "Price（yuan/hour）",
            "operate": "Operate",
            "edit": "Edit",
            "editProductPrice": "Edit Product Price",
            "determine": "determine",
            "cancel": "cancel",
            "PleaseEnterPrice": "Please enter the unit price",
            "perHour": "Hour",
            "perMonth": "Month",
            "all": "all",
            "error": {
                "pattern": "Please enter a number greater than 0 (not more than two decimal places)",
                "gtZeroNumTip": "Please enter a number range 0~999999.99 (not more than two decimal places)"
            },
            "hour": "Hour",
            "month": "Month"
        },
        "consume": {
            "selectType": "Select type",
            "detailQuery": "Detail Enquiry",
            "billQuery": "Billing Enquiry",
            "selectTime": "Select time",
            "selectMonth": "Select Month",
            "resourceType": "Resource Type",
            "choiceResource": "Please select resource Type",
            "region": "Data Center",
            "choiceRegion": "Please select the Data Center",
            "domain": "Department",
            "choiceDomain": "Please select department",
            "project": "Project",
            "choiceProject": "Please select an item",
            "user": "User",
            "choiceUser": "Please select user",
            "resourceName": "Resource Name",
            "price": "Unit Price (YUAN/hour)",
            "amount": "Amount (yuan)",
            "startTime": "Billing start time",
            "pleaseChoose": "Please choose",
            "all": "All",
            "allregion": "All data centers",
            "alldomain": "All Departments",
            "allproject": "All Projects",
            "alluser": "All users",
            "searchResource": "Search Resource Name"
        },
        "grade": {
            "table": {
                "status": {
                    "1": "Success",
                    "2": "Fail",
                    "3": "Upgrading"
                }
            },
            "sysVersionChanges": "System Version Changes",
            "saasVersionChanges": "Saas Version Changes",
            "patchInfo": "Patch Information",
            "gradeTime": "Upgrade Time",
            "status": "Status",
            "operation": "Operation",
            "retry": "Retry",
            "view": "View",
            "checkUpgrating": "Check Upgrating....",
            "uploadPatch": "Upload Patch",
            "uploadTips": "Upload the software upgrade package through the following SFTP address. For Windows SFTP client, please",
            "downloadSoft": "click Download",
            "fileTips32": "FILEZILLA 32Bit No Installation version",
            "fileTips64": "FILEZILLA 64Bit No Installation version",
            "download": "Download",
            "sftpAddr": "SFTP Address：",
            "tips": "Tips",
            "user": "User：",
            "port": "Port：",
            "expireTime": "Expire Time：",
            "password": "Password：",
            "randomPassword": "Get Random Password",
            "retryRandomPassword": "Get Random Password again",
            "check": "Checked",
            "numOfPatch": "number of Patch",
            "upgradingTips": "Do not close the window or refresh the page manually when upgrading (because of system version and network differences, upgrade pages may automatically refresh! )",
            "noPatch": "No Patch",
            "moreInfo": "More Information",
            "versionError": "Upgrade Patch version does not match and cannot be upgraded",
            "installedVersion": "Installed Version",
            "lastVersion": "Last Version",
            "upgraded": "Upgrade completed",
            "upgrade": "Upgrade",
            "saasInstalledVersion": "SaaS Installed Version",
            "saasLastVersion": "Saas Last Version",
            "sysInstalledVersion": "System Installed Version",
            "sysLastVersion": "System Last Version",
            "updateContent": "Upgrade Content"
        },
        "k8s": {
            "dockerClusters": {
                "createCluster": "New Cluster",
                "deleteCluster": "Delete cluster",
                "confirmDeleteDockerClusters": "Are you sure to delete the selected cluster?",
                "scaleCluster": "Dynamic scaling",
                "rollingUpdateCluster": "Rolling Upgrade",
                "name": "Cluster name",
                "labels": "Label",
                "replicas": "Expected copy number",
                "currentTotal": "Total number of current copies",
                "currentRunning": "Number of copies in operation",
                "observedGeneration": "",
                "creationTimestamp": "Time of creation",
                "status": "The state of the cluster",
                "returnToCluster": "Back to cluster page",
                "clusterDetails": "Container Cluster Details",
                "returnToClusterDetails": "Back to the Cluster Details page",
                "clusterReplicaDetails": "Container Cluster Replica Details",
                "clusterReplicaName": "Cluster replica Name",
                "clusterReplicaStatus": "Cluster replica status",
                "clusterReplicaIp": "Cluster replica IP",
                "clusterReplicaHostIp": "IP on the physical machine where the cluster replica is located",
                "clusterReplicaHostName": "Physical machine hostname of cluster replica",
                "clusterReplicaCreationTimestamp": "When the copy was created",
                "clusterReplicaTerminationGracePeriodSeconds": "Waiting time to gracefully close the cluster",
                "clusterReplicaStartTime": "When does the copy start running?",
                "clusterReplicaOverview": "General",
                "clusterReplicaContainers": "Container",
                "clusterReplicaVolumes": "Cloud Hard Drive",
                "clusterReplicaLogs": "Log",
                "clusterReplicaLogsAutowrap": "Automatic Line Wrapping of logs",
                "clusterReplicaRestartPolicy": "Cluster Restart Policy",
                "clusterReplicaDNSPolicy": "DNS strategy",
                "clusterReplicaVolumeName": "Cloud hard disk (Shared by all containers within the cluster) Name",
                "clusterReplicaVolumefsType": "File system for cloud Drives",
                "clusterReplicaVolumeImage": "Image used by cloud hard drives",
                "clusterReplicaVolumePool": "Storage Pool",
                "clusterReplicaVolumeUser": "User",
                "clusterReplicaVolumeMonitors": "Ceph Monitors",
                "returnToClusterReplicaDetails": "Return to cluster replica Details page",
                "restartClusterReplica": "Reboot",
                "deleteClusterReplica": "Delete cluster copies",
                "confirmDeleteClusterReplica": "Confirm deletion of selected cluster copies?",
                "deleteClusterReplicaSuccess": "Cluster replica deleted successfully",
                "containerName": "Name of the container",
                "containerImage": "Mirror image used by the container",
                "containerImagePullPolicy": "Mirror pull strategy",
                "containerStatus": "The state of the container",
                "containerRestartCounts": "Container restart times",
                "containerStartedAt": "Container startup time",
                "containerDetails": "Container Details",
                "containerEnvs": "Environment variables",
                "containerPorts": "Port mapping",
                "containerVolumes": "Cloud Hard Disk Mapping",
                "containerLogs": "Container log",
                "containerArgs": "Startup Parameters",
                "containerCommands": "Start command",
                "containerAdvisors": "Container monitoring",
                "containerEnvName": "Name of environment variable",
                "containerEnvValue": "The value of the environment variable",
                "containerPortName": "Port name",
                "containerContainerPort": "Port number within container cluster",
                "containerHostPort": "Port number exposed by physical machine",
                "containerPortProtocol": "Protocol for Port Use",
                "containerVolumeName": "Name of cloud hard drive used by containers (Shared by all containers within the cluster)",
                "containerVolumeMountPath": "Hardpoint inside the container",
                "containerVolumeReadOnly": "Read and write access to cloud Hard drive",
                "dockerClustersPluginOneHref":"To use the container management block function, open System Management> System Settings>",
                "dockerClustersPluginTwoHref":"'Interface Add' Container Management 'module"
            },
            "createCluster": {
                "tital": "New Docker Container cluster",
                "basic": "Basic options",
                "advanced": "Advanced Options",
                "clusterName": "Cluster name:",
                "clusterNameRequired": "Required, not an existing name.",
                "clusterNameNotEmpty": "Cluster name cannot be empty!",
                "clusterNameRepeated": "The cluster name already exists, please change it!",
                "clusterNameTooLong": "Up to 32 bits",
                "portNameLimit": "No more than 32 bits",
                "clusterNameMismatchPattern": "Cluster names must start with a lowercase letter and end with a lowercase letter or number;You can have lowercase letters, Numbers, horizontal lines --",
                "clusterReplicas": "Cluster replica:",
                "clusterReplicasRequired": "Number of copies of expected cluster runtime",
                "clusterReplicasNotEmpty": "Required fields!",
                "dnsPolicy": "DNS Policy:",
                "dnsPolicyTips": "ClusterFirst is recommended for direct access using the name of the service (automatic DNS resolution).",
                "restartPolicy": "Restart policy:",
                "restartPolicyTips": "Restart policy for all containers in cluster replica, default is Always",
                "nodeSelectors": "Node Selector:",
                "nodeSelect": "Node selection",
                "addNodeSelectors": "Add selector",
                "hideNodeSelectors": "Hide the selector",
                "showNodeSelectors": "Display Selector",
                "deleteSelector": "Delete this selector",
                "selectZeroNode": "[Selector matching error] : No node can satisfy all the above selectors at the same time, please select again!",
                "selector": "Selector",
                "clusterLabels": "Cluster label:",
                "addClusterLabels": "Add label",
                "hideClusterLabels": "Hidden Tag",
                "showClusterLabels": "Display Label",
                "clusterLabelsOptional": "Optional",
                "labelKey": "Fill in the key for the new label",
                "labelKeyRequired": "The key cannot be empty!",
                "labelKeyMismatch": "(Prefix /name) - The prefix (optional) is provided by the prefix (prefix).A split domain name (each part of the domain name is alphabetic or numeric);" +
                "Name must begin and end with a letter or number, with a letter, number, dot in the middle.The two parts use/split.",
                "labelKeyDomainToLong": "The prefix of the key must not exceed 253 characters in length!",
                "labelKeyNameToLong": "The total name of the key must not exceed 63 characters!",
                "labelValueRequired": "Value cannot be empty!",
                "labelValueMismatch": "Value must begin and end with a letter or number with a letter, number, dot in the middle, underscore _, horizontal line -",
                "labelValueMinLength": "Value cannot be less than 2 characters!",
                "labelValueMaxLength": "Value cannot exceed 63 characters!",
                "deleteLabel": "Delete this label",
                "clusterVolumes": "Cluster cloud hard drive:",
                "hideClusterVolumes": "Hidden Cloud Hard Drive",
                "showClusterVolumes": "Display cloud Hard Drive",
                "addClusterVolumes": "Add a Cloud hard drive",
                "volumeNameRequired": "Required Fields",
                "volumeNameMismatch": "The name of the cloud hard drive must begin with a lowercase letter and end with a lowercase letter or number;You can have lowercase letters, Numbers, horizontal lines --",
                "volumeNameTooLong": "The name of the cloud hard drive should not be more than 16 characters.",
                "volumeNameRepeated": "The name of the cloud drive is repeated, please change the name!",
                "volumeSizeRequired": "Required Fields",
                "deleteVolume": "Delete this cloud drive.",
                "containerList": "Container list:",
                "addClusterContainers": "Add Container",
                "deleteContainer": "Delete this container",
                "container": "Container #",
                "containerName": "Container name:",
                "containerNameRequired": "Required Fields",
                "containerNameMismatch": "Container names must begin with a lowercase letter and end with a lowercase letter or number;You can have lowercase letters, Numbers, horizontal lines --",
                "containerNameTooLong": "Container names cannot exceed 32 characters.",
                "imageName": "Mirror name:",
                "imageNameRequired": "Required Fields",
                "selectDockerImage": "Select mirror:",
                "projectType": "Warehouse Type:",
                "projectTypeTips": "Select private/Public mirror repository",
                "projectName": "Project Name:",
                "repoName": "Warehouse Name:",
                "tagName": "Label Name:",
                "workingDir": "Working directory:",
                "workingDirOptional": "Optional: Default working directory set when mirroring",
                "workingDirMismatch": "Working directory format not compatible with Linux",
                "commands": "Start command:",
                "showContainerCmds": "Display startup command",
                "hideContainerCmds": "Hide startup command",
                "addContainerCmds": "Add startup Command",
                "deleteContainerCmd": "Delete this boot command",
                "commandsTips": "The boot command is usually set during mirroring, please delete if not needed!",
                "containerCmdRequired": "Required fields: Please fill in the correct startup command or delete this record!",
                "envs": "Environment variable:",
                "hideContainerEnvs": "Hide environment variables",
                "showContainerEnvs": "Display environment variables",
                "addContainerEnvs": "Add environment variables",
                "deleteContainerEnv": "Delete this environment variable.",
                "envsKeyTips": "Required Fields",
                "envsValueTips": "Required Fields",
                "containerEnvNameRequired": "The name of the environment variable is usually set in the 'docker-entrypoint.sh' script used to make a Docker image.",
                "containerEnvValueRequired": "The value and type of environment variables are generally set in the 'docker-entrypoint.sh' script used when making a Docker image.",
                "ports": "Port mapping:",
                "portLabel": "Port map #",
                "hideContainerPorts": "Hidden Port mapping",
                "showContainerPorts": "Display Port Mapping",
                "addContainerPorts": "Add port mapping",
                "deleteContainerPort": "Remove port mapping",
                "portsOptional": "Optional fields",
                "portName": "Port name:",
                "portNameMismatch": "Port names must begin with a lowercase letter and end with a lowercase letter or number;The middle can be lowercase letters, Numbers, horizontal lines -;And not more than 32 characters",
                "portProtocol": "Port Protocol:",
                "containerPort": "Container port:",
                "containerPortTips": "Required Fields",
                "containerPortRequired": "Container listens to the port number is required!",
                "portMismatch": "Port number must be a number, between 1-65535.",
                "hostPort": "Host port:",
                "hostPortTips": "Optional: Preferably not!",
                "volumeMounts": "Persistent storage:",
                "addContainerVolumeMounts": "Add volume",
                "hideContainerVolumeMounts": "Hidden Storage Volumes",
                "showContainerVolumeMounts": "Display storage Volume",
                "volumeMountsLabel": "Volume #",
                "deleteContainerVolumeMount": "Delete this volume",
                "podVolumeName": "Volume name:",
                "podVolumeNameTips": "Cloud hard disk of the cluster used by the container",
                "volumeMountNameRequired": "Required fields, must be cloud drive created on the previous page!",
                "rwMode": "Read-write mode:",
                "mountPath": "In-container path:",
                "mountPathTips": "Required: Absolute path to cloud hard drive mount to container",
                "mountPathRequired": "Required Fields",
                "resources": "Resource quota:",
                "hideContainerResources": "Hidden resource quotas",
                "showContainerResources": "Display resource quotas",
                "requestsCPU": "Started CPU:",
                "requestsCPUTips": "Required Fields",
                "requestsCPURequired": "CPU quota at startup is mandatory!",
                "requestsCPUMismatch": "Can be an integer, a decimal, or an integer plus m.0.5 is equivalent to 500m, unit: millicPU",
                "requestsMemory": "Started memory:",
                "requestsMemoryRequired": "Started memory quota is required!",
                "requestsMemoryMisMatch": "Must be an integer plus units of memory, units of memory :Ki, Mi, Gi, Ti, Pi, Ei.",
                "requestsMemoryTips": "Required Fields",
                "limitsCPU": "Maximum CPU:",
                "limitsCPURequired": "The maximum CPU quota for the container runtime is required!",
                "limitsCPUMismatch": "Can be an integer, a decimal, or an integer plus m.0.5 is equivalent to 500m, unit: millicPU",
                "limitsMemory": "Maximum memory:",
                "limitsMemoryTips": "Required Fields",
                "limitsMemoryRequired": "The maximum memory quota for the container runtime is required!",
                "limitsMemoryMisMatch": "Must be an integer plus units of memory, units of memory :Ki, Mi, Gi, Ti, Pi, Ei."
            },
            "scaleCluster": {
                "RCUsedRBD": "The cluster USES RBD block devices as persistent storage. One RBD block device can only be used by one cluster at the same time!",
                "RCNOTReady": "[The cluster cannot dynamically scale] the expected number of copies of the cluster, the current number of copies of the cluster, the current running number of copies of the cluster is not consistent!",
                "clusterName": "Cluster name:",
                "replicas": "Cluster copy number:",
                "replicasRequired": "Cluster copy number is required!",
                "replicasMustBeDifferent": "The new cluster copy number cannot be the same as the original value!",
                "replicasMustBePositive": "Cluster copy must be a positive integer!",
                "scaleDockerClusterSuccess": "Dynamic scaled cluster copy successful!",
                "limit_1000": "Please enter a positive integer between 1-999."
            },
            "rollingUpdate": {
                "oldClusterName": "Cluster original name:",
                "newClusterName": "New cluster name:",
                "newClusterNameNotRequired": "[Optional] : The new name that the cluster will use after rolling upgrade;Otherwise the original name will be used.",
                "chooseContainer": "Upgraded container:",
                "chooseContainerTips": "[Required] : Select the container name to upgrade the image",
                "oldImageName": "Old mirror name:",
                "newImageName": "New mirror name:",
                "projectType": "Warehouse Type:",
                "projectName": "Project Name:",
                "repoName": "Warehouse Name:",
                "tagName": "Label Name:",
                "pollInterval": "Polling interval:",
                "pollIntervalTips": "Polling interval between the old and new version containers for rolling upgrades (default: 3s)",
                "timeMismatchPattern": "Must be a combination of 'xxhyymzzs' (xx, yy, zz are integers, where yy and zz are within the range [0,59])",
                "updatePeriod": "Update cycle:",
                "updatePeriodTips": "When rolling upgrade, alternately add new version container and delete old version container interval (default: 1m0S)",
                "timeout": "Overtime:",
                "timeoutTips": "Rolling upgrade operation will be terminated if it takes longer than 5M0S by default"
            },
            "dockerServices": {
                "createService": "New Container Service",
                "deleteServices": "Remove Container Service",
                "confirmDeleteDockerServices": "[Warning] Confirm deletion of the selected container service?",
                "name": "Name of service",
                "clusterIP": "Virtual IP of Service (VIP)",
                "serverClusterIP": "Virtual IP of service",
                "type": "Types of services",
                "sessionAffinity": "Hold the conversation",
                "ports": "Port of service",
                "labels": "Service Label",
                "creationTimestamp": "Service Creation Time",
                "details": "Container Service Details",
                "selectors": "Cluster of Container Service Selection",
                "portName": "Name of service port",
                "protocol": "Protocol for Port Use",
                "port": "Ports on which services listen",
                "targetPort": "Port forwarded to backend cluster replica",
                "nodePort": "Ports exposed by physical nodes",
                "useServiceWithinCluster": "Sample K8S Cluster Internal Access Container Service",
                "useServiceOutOfCluster": "Sample K8S Cluster External Access Container Service"
            },
            "createService": {
                "serviceName": "Service Name:",
                "serviceNameTips": "Required: cannot be an existing name",
                "serviceNameNotEmpty": "Service name cannot be empty!",
                "serviceNameTooLong": "The name of the service cannot exceed 32 characters!",
                "serviceNameRepeated": "The service name already exists, please change it!",
                "serviceNameMismatchPattern": "The service name must start with a lowercase letter and end with a lowercase letter or number;You can have lowercase letters, Numbers, horizontal lines --",
                "dockerClusters": "Cluster using services:",
                "dockerClustersTips": "Required options: Please select the cluster that USES the service",
                "dockerClusterLabels": "Container cluster Selector:",
                "serviceType": "Container service Type:",
                "sessionAffinity": "Session session holds:",
                "sessionAffinityTips": "It is suggested that TCP's ClientIP support session session holding;Selecting None will not support",
                "serviceTypeTips": "Default ClusterIP can only be accessed within the K8S cluster;NodePort allows external access to the K8S cluster.",
                "portsLabel": "Port # of the service",
                "portName": "Port name:",
                "portNameOptional": "Required Fields",
                "portNameNotEmpty": "The name of the port of the service cannot be empty",
                "portNameMismatch": "Port names must begin with a lowercase letter and end with a lowercase letter or number;You can have lowercase letters, Numbers, horizontal lines --",
                "portNameTooLong": "Port names cannot exceed 32 characters!",
                "protocol": "Port Protocol:",
                "port": "Service port:",
                "portTips": "Optional: Default is equal to target port",
                "portMismatch": "Port number must be a number, between 1-65535.",
                "targetPort": "Target port:",
                "nodePort": "Physical port:",
                "nodePortTips": "Required option: The system randomly selects unused Node node ports to provide external services.",
                "nodePortsRepeated": "Duplicate physical port for different service port mappings, please select again!"
            },
            "dockerImages": {
                "myProjects": "My Project",
                "publicProjects": "Open Project",
                "createProject": "New Project",
                "deleteProjects": "Delete items",
                "projectName": "Project Name",
                "imageRepoCount": "Mirror warehouse",
                "roles": "Role",
                "createTime": "Creation time",
                "isPublic": "Open or not open?",
                "isPublicTips": "When the item is made public, anyone can read the mirror under this item!The command line user does not need Docker Login to pull the image under this item.",
                "actions": "Operation",
                "projectNameTips": "Required: cannot be an existing name",
                "projectNameNotEmpty": "Project name cannot be empty",
                "projectNameRepeated": "Project name already exists, please change it!",
                "projectNameTooLong": "Project names must not exceed 32 characters",
                "projectNameMismatchPattern": "Project names must begin with a lowercase letter and end with a lowercase letter or number;You can have lowercase letters, Numbers, horizontal lines --",
                "confirmDeleteDockerImages": "Are you sure you want to delete the selected item?",
                "returnToProjects": "Return to list of items",
                "projectDetails": "Project Details",
                "deleteImageRepos": "Delete mirror Repository",
                "createRepository": "New Image/Warehouse",
                "imageRepoName": "Full name of mirror warehouse",
                "imageRepoDeleteWarn": "[Warning] : Removing the mirror repository will remove all labels under the mirror repository!mirror space will be freed during garbage collection.",
                "returnToProjectDetails": "Return to Project Details",
                "repositoryTagsDetails": "Mirror Warehouse Details",
                "imageRepoTags": "Mirror label",
                "deleteImageRepoTags": "Remove the image tag",
                "pullImageCmd": "Pull mirror command",
                "imageRepoTagDeleteWarn": "[Warning] : all tags pointing to this image will be removed from this image repository!",
                "returnToImageTags": "Return to mirror warehouse",
                "dockerImageManifests": "Mirror Details",
                "dockerImagesOverview": "Mirror Image profile",
                "dockerImageHistory": "History of Mirror building",
                "dockerImageLsyers": "Layers of mirroring",
                "imageName": "Full name of the mirror",
                "creationTimestamp": "Image creation time",
                "imageAuthor": "Mirror maintainer",
                "imageOS": "Mirror operating System",
                "imageDockerVersion": "Docker version for image making",
                "imageArchitecture": "Mirror Operating System Architecture",
                "createInstruct": "Instructions for building this layer's image",
                "imageBuiltAuthor": "Maintainer of this layer's mirror image",
                "isEmptyLayer": "Is it empty?",
                "createdTime": "Build time of this layer mirror",
                "layerDigest": "SHA256 summary of the image of this layer",
                "layerMediaType": "File type of this layer mirror",
                "layerSize": "Size of this layer image (after GZIP compression)",
                "newRepoName": "Name of new Warehouse",
                "newRepoNameTips": "Optional: You can create a new mirror warehouse, or select an existing mirror warehouse;It has to be one or the other!",
                "newRepoNameTooLong": "The name of the warehouse cannot exceed 10 characters!",
                "newRepoNameMismatchPattern": "The name of the warehouse must begin with a lowercase letter and end with a lowercase letter or number;You can have lowercase letters, Numbers, horizontal lines --",
                "choseRepository": "Select an existing warehouse.",
                "oldRepoName": "Name of existing warehouse",
                "oldRepoNameTips": "Please select a mirror warehouse name that belongs to this project",
                "tagName": "Mirror label",
                "tagNameTips": "Required Fields",
                "tagNameNotEmpty": "Mirror tags cannot be empty!",
                "tagNameTooLong": "The mirrored label cannot be more than 10 characters long!",
                "tagNameMismatchPattern": "Mirrored labels must begin and end with a lowercase letter or number;In the middle can be letters, Numbers, horizontal lines -, dot., underscore _",
                "tagDuplicated": "Tag repeated, please specify a new one!",
                "choseUploadDockerfile": "Upload Dockerfile",
                "choseDockerfile": "Select Local File",
                "localDockerfile": "Local Dockerfile"
            },
            "dockerNodes": {
                "name": "Node name",
                "details": "Node Details",
                "LegacyHostIP": "External IP",
                "InternalIP": "Node IP",
                "KubeletReady": "Kubelet state",
                "operatingSystem": "Operating System",
                "kernelVersion": "Kernel version",
                "osImage": "Distribution",
                "architecture": "System Architecture",
                "kubeletVersion": "Kubelet version",
                "kubeProxyVersion": "KubeProxy version",
                "containerRuntimeVersion": "Docker version",
                "creationTimestamp": "Node creation time",
                "KubeletHasSufficientMemory": "Memory space",
                "KubeletHasSufficientDisk": "Disk space",
                "KubeletHasNoDiskPressure": "Disk pressure",
                "podSchedulable": "Schedulable POD",
                "cpu": "The number of CPU",
                "memory": "System Memory",
                "pods": "Number of Runnable PODS",
                "nodesDetails": "Node Profile",
                "nodesLabels": "Tag List",
                "labelKey": "Label Name",
                "labelValue": "Value of the tag",
                "addNodeLabels": "Add node label",
                "modifyNodeLabel": "Modify node Label",
                "deleteNodeLabels": "Remove node label",
                "addNewNodeLabel": "Add tag pairs",
                "confirmDeleteNodeLabels": "[Warning] Removing the tag may cause the POD to be unscheduled to this node!sure?"
            }
        },
        "backupStrategy": {
            "backupStrategyDetail": "Backup Policy Details",
            "baseMsg": "Basic information",
            "Newbackupstrategy": "New backup strategy",
            "BackupstrategyName": "Backup strategy name",
            "Backupexecutionfrequency": "Backup execution frequency",
            "Firstexecutiontime": "First execution time",
            "Keepbackups": "Keep backups",
            "Creationtime": "Creation time",
            "Backupstrategydetails": "Backup strategy details",
            "Basicinformation": "Basic information",
            "by": "By ",
            "every": "，every ",
            "Executeonce": " execute once",
            "once": " once",
            "hour": "hour",
            "day": "day",
            "week": "week",
            "month": "month",
            "minute": "minute",
            "one": "one",
            "part": " part",
            "Deletecontent": "Are you sure you want to delete the selection?",
            "Resourcesname": "Resources name",
            "Cloudhost": "Cloud host",
            "Firstexecutiontime":"First execution time",
            "Nextexecutiontime": "Next execution time",
            "backupstrategyCloudhost": "Cloud host using this backup strategy",
            "backupstrategyClouddrive": "Cloud drive using this backup strategy",
            "backuppolicyname": "Please enter a backup policy name",
            "Executionfrequency": "Execution frequency",
            "32characters": "Enter up to 32 characters",
            "timeinterval": "Time interval",
            "Pleasetimeinterval": "Please enter a time interval",
            "pleaseenter": "please enter ",
            "inputAccount": "Please input Username",
            "Positiveinteger": " Positive integer",
            "Theminimumnumber": "The minimum number of copies is 1 and the maximum is 5.",
            "Timeformat": "The time format is incorrect, for example"
        },
        "BackupRecoveryBackup": {
            "Backuptitle": "Backup",
            "Systemdiskbackup": "System disk backup",
            "Datadiskbackup": "Data disk backup"

        },
        "shutDownDatacenter": {
            "01030709": "The user is locked",
            "01030702": "Wrong username or password",
            "01030703": "User's IP address denied access",
            "01030708": "The platform is being upgraded, please operate later."
        },
        "topo": {
            "editTopo": "Edit Topo",
            "scaleTopo": "Larger",
            "reduceTopo": "Smaller",
            "externalNet": "External Net",
            "firewall": "FireWall",
            "router": "Route",
            "network": "Network",
            "subnet": "Subnet",
            "instance": "Instance",
            "exNetName": "External Name",
            "exNetCidr": "External Net Cidr",
            "firewallName": "Firewall Name",
            "status": "Status",
            "routerName": "Route Name",
            "networkName": "Network Name",
            "networkType": "Network Type",
            "networkBandwidth": "Bandwidth",
            "shared": "Shared",
            "status":"Status",
            "subnetName": "Subnet Name",
            "gatewayIp": "Gateway IP",
            "gateway": "Gateway",
            "allocationPool": "Allocation IP Pool",
            "enableDhcp": "Enable DHCP",
            "dnsNameserver": "DNS Nameserver",
            "hostRoutes": "HostRoutes",
            "instanceName": "Instance Name",
            "image": "Image",
            "specification": "Specification",
            "security_group": "Security Group",
            "volume": "Volume",
            "startZoon": "Start Zoon",
            "keypairs": "Keypairs",
            "linkedSubnetName": "linked Subnet Name",
            "portIp": "Port IP",
            "netCard": "NIC",
            "newAdd": "Add",
            "createFirewall": "Create FireWall",
            "linkedRouter": "Link Route",
            "pleaseSelectLinkRouter": "Please select route to link",
            "parts": "Parts",
            "refresh": "Refresh",
            "return": "Return",
            "instanceStatus1":"Pause, heat upgrade, adjustment configuration, remove thermal upgrades, abnormal, unrecognized cloud host",
            "instanceStatus2":"Run or healthy cloud main machine",
            "instanceStatus3":"Running the cloud main machine",
            "instanceStatus4":"Cloud host",
            "instanceStatus5":"Write main machine",
            "instanceStatus6":"Cloud main machine in other states"
        },
        "bigmem": {
            "msg": {
                "warning": "After the user adds the large page memory configuration, the node needs to be restarted. The original virtual machine on the physical node is affected. It is recommended to migrate the virtual machine of the corresponding node in advance."
            },
            "btn": {
                "restart": "Node restart"
            },
            "dataTitles": {
                "node": "Node",
                "defaultMem": "Default memory page size",
                "mem": "Memory page size",
                "num": "Number of pages",
                "action": "action"
            },
            "dialog": {
                "bigRestartNode": "Restart Node",
                "sriovdel": "Delete"
            },
            "placeholder": {
                "node": "Please select a node",
                "defaultMem": "Please select the default memory page",
                "mem": "Please select a memory page",
                "num": "Please enter the page number"
            }
        },
        "sriov": {
            "dataTitles": {
                "node": "Node",
                "networkName": "Network Card Name",
                "status": "Status",
                "number": "VF number",
                "srName": "SR-IOV network name",
                "srType": "SR-IOV network type"
            },
            "dialog": {
                "create": "Add",
                "flat": "flat",
                "vlan": "vlan",
                "retry": "Are you sure you want to reconfigure the SR-IOV function of the selected network card?"
            },
            "msg": {
                "0": "Create failed",
                "1": "Created successfully",
                "2": "Created",
                "3": "Deletion failed",
                "4": "Delete",
                "tips": "Private networks created using SR-IOV physical networks are not allowed to directly create virtual machines; you must create a virtual network card in direct connection mode on the virtual network card interface, and then go to the new cloud host interface to use the virtual network card Create a virtual machine"
            },
            "label": {
                "node": "Select Node",
                "network": "Select a network card",
                "vfnum": "Number of VF",
                "srname": "SR-IOV network name",
                "srtype": "SR-IOV network type",
                "vlanStart": "Starting VLAN",
                "vlanEnd": "End VLAN"
            },
            "placeholder": {
                "node": "Please select a node",
                "network": "Please select a network card",
                "vfnum": "Please enter the number of VF",
                "srname": "Please enter the SR-IOV network name",
                "vlanStart": "Please enter the starting VLAN",
                "vlanEnd": "Please enter the end VLAN"
            }
        },
        "dpdk": {
            "dataTitles": {
                "node": "Node",
                "networkName": "Network Card Name",
                "status": "Status",
                "desc": "Network card description"
            },
            "msg": {
                "allData": "All",
                "tips": "The DPDK function is used to speed up the processing of network packets by cloud hosts and physical hosts. With a series of technologies such as Hugepage and CPU Affinity, it bypasses the cumbersome process of network packet processing by the system and improves network performance. Server needs Open Hugepage to enable DPDK function."
            },
            "usable": {
                "1": "Enable",
                "2": "Disabled",
                "3": "In configuration",
                "4": "Enable failed",
                "5": "Disable failed"
            },
            "status": {

            },
            "dialog": {
                "start": "Are you sure you want to enable the DPDK function of the selected network card?",
                "shutdown": "Are you sure you want to disable the DPDK function of the selected network card?"
            }
        },
        "nettype": {
            "dataTitles": {
                "type": "Tenant Network Type",
                "status": "Status",
                "range": "Network card name",
                "opt": "Operation"
            },
            "msg": {
                "0": "Not enabled",
                "1": "Enabled",
                "2": "Configuring",
                "3": "Configuration failed",
                "tips": ""
            },
            "vlan": {
                "start": "Start VLAN",
                "end": "End VLAN"
            },
            "vxlan": {
                "start": "Start VNI",
                "end": "End VNI"
            },
            "error": {
                "vlan": "The start value cannot be greater than the end value"
            },
            "dialog": {
                "start": "Are you sure you want to enable the DPDK function of the selected network card?",
                "shutdown": "Are you sure you want to disable the DPDK function of the selected network card?"
            }
        },
        "shares": {
            "name": "Name",
            "size": "Size(GB)",
            "status": "Status",
            "isPublic": "ACL",
            "shareProto": "Protocol",
            "createDate": "Create Time",
            "public": "Public",
            "private": "Private",
            "label": "Lable",
            "location": "Location",
            "type": "Type",
            "host": "VM Host",
            "desc": "Description",
            "metadata": "Metadata",
            "detail": "Detail",
            "addRule": "Setting Rule",
            "allowRule": "Allowed access rule",
            "newRule": "New Rule",
            "delete": "Delete rule",
            "basicsInfo": "Basic Info",
            "ruleManage": "Rule Manage",
            "valueLimit": "value  can't be chinese ",
            "status_c": {
                "available": "Available",
                "creating": "Creating",
                "deleting": "Deleting",
                "error": "Error",
                "error_deleting": "Error deleting"
            },
            "ruleStatus": {
                "error": "Error",
                "active": "Active",
                "new": "New"
            }
        },
        "swifts": {
            "true": "true",
            "false": "false",
            "yes": "yes",
            "no": "no",
            "shared": "public",
            "exclusive": "private",
            "container": "vessel",
            "count": "Object Count",
            "size": "size(B)",
            "totalNum": "total(B)",
            "accessible": "accessibility",
            "detail": "details",
            "containerDetail": "vessel details",
            "objectDetail": "Object details",
            "lookDetail": "details",
            "detailMessage": "information",
            "containerName": "Container name",
            "createcontainerName": "new container",
            "name": "Container name",
            "objectName": "Object name",
            "fileExtension": "File Extension",
            "type": "type",
            "public": "public",
            "private": "private",
            "modifyTime": "modifyTime",
            "usedSpace": "usedSpace(B)",
            "pInputName": "Please enter a container name",
            "pInputObjName": "Please enter the object name",
            "confirmDel": "Are you sure you want to delete the selected container?",
            "confirmIsNull": "Make sure the selected container is empty before deleting it",
            "prepareDownload": "Please wait while downloading",
            "dir": "catalogue",
            "object": "Object",
            "hash": "hash",
            "parentDir": "parent directory",
            "createVirDir": "Create virtual directory",
            "detailPath": "filename",
            "filePath": "File save path",
            "pInputFilePath": "Please enter a file save pathname",
            "upload": "uploading",
            "uploadFile": "Upload File",
            "pSelectFile": "Please select file",
            "download": "download",
            "downloadObj": "Download the object",
            "pInputVirDir": "Please enter the virtual directory",
            "pConfirmNull": "Make sure there is nothing in the selected virtual directory before deleting it",
            "confirDelDir": "Are you sure you want to delete the selected virtual directory or object?",
            "application/directory": "virtual directory",
            "url": "access path",
            "objectUserPlaceholder": "Please select object to store user",
            "fileName": "File Name",
            "directoryName": "Directory Name",
            "uploadSuccess": "Upload completed",
            "uploadFail": "Upload failed",
            "createSuccess": "Created successfully",
            "createFail": "Create Failed",
            "deleteSuccess": "Delete the container successfully"
        },
        "backupService":{
            "btn":{
                "stop" : "stop",
                "filter":"Filter",
                "backupStatus":"Backup Result",
                "backupType":"Backup method",
                "cx":"Query",
                "qx":"Cancel",
            },
            "tip":{
                "instThreadNum": "When enabled, multiple cloud hosts can be backed up at the same time when the task is running.",
                "permanentIncrementalBackup": "Permanent incremental backup is enabled, each incremental backup will perform a point-in-time synthesis to form a new permanent incremental time point, which is equivalent to a full backup time point.",
                "backupAutoRetry": "After enabling automatic backup retry, if the cloud host backup fails, it will try again after the set retry waiting time until the maximum number of automatic retries is reached. Note: If the parameter is missing, automatic backup retry is disabled by default ",
                "flowControl":"Limits the maximum speed of data transfer between the backup server and the protected object.",
                "createRecover1":"1. After successful recovery, the cloud host in the original backup task needs to be added to the backup task.",
                "createRecover2":"2. After the recovery is successful, only the image data, cloud hard disk data, security group, network ID, MAC address, fixed IP and other data of the original cloud host will be restored to the specified time node.",
                "createRecover3":"3. Please confirm that the ip and MAC address of the recovery source cloud host are not occupied by other cloud hosts. If they are occupied, please create a recovery task after releasing them.",
                "desc0":"Please to go to \"Advanced Features > Backup Service > Service Configuration\" to connect to the backup service before using this module",
                "desc1":"Please contact your administrator to go to \"Advanced Features > Backup Service > Service Configuration\" to connect to the backup service before using this module"
            },
            "messageInfo":{
                "inputnum1-365":"Please fill in a number between 1-365",
                "inputnum2-10":"Please fill in the number between 2-10",
                "inputnum1-5":"Please fill in a number between 1-5",
                "inputnum1-30":"Please fill in a number between 1-30",
                "required1": "Composed of Chinese, uppercase and lowercase letters, numbers, “-”, “_”, “.”, “@”, the length is 3-256 characters, and the global cannot be repeated.",
                "required": "Required",
                "inputnum1-255": "Cannot exceed 255 digits",
                "inputnum0-50": "Composed of Chinese, uppercase and lowercase letters, numbers, “-”, “_”, “.”, “@”, the length should not exceed 50 characters.",
                "deleteInfo":"If the data source is not retained, this task will be deleted. Do you want to continue deleting the selected content?"
            },
            "placeholder":{
                "maxNumRetry":"Please enter the maximum number of retries",
                "retryWaitTime":"Please enter the retry wait time",
                "backupJobName":"Please enter a job name",
                "select":"Please select",
                "only":"Only instances under the same time node can be selected",
            },
            //variable
            "variable":{
                "every":"every",
                "year":"year",
                "month":"month",
                "days":"Days",
                "day":"day",
                "week":{
                    "1":"Monday",
                    "2":"Tuesday",
                    "3":"Wednesday",
                    "4":"Thursday",
                    "5":"Friday",
                    "6":"Saturday",
                    "7":"Sunday",
                    "0":"Sunday",
                },
                "down":"Not enabled",
                "up":"Open",
                "16004":"Virtual Machine",
                "16005":"Cloud Disk",
                "status" :{
                    "1":"Starting",
                    "2":"Analyzing",
                    "4":"Executing",
                    "512":"Stopping",
                    "256":"Exception",
                    "32":"Success",
                    "64":"Failed",
                    "128":"Partial success",
                    "129":"Success but warning",
                    "16":"Stopped",
                },
                "status_targets" :{
                    "1":"Starting",
                    "2":"Analyzing",
                    "4":"Backing up",
                    "512":"Stopping",
                    "256":"Exception",
                    "32":"Success",
                    "64":"Failed",
                    "128":"Partial success",
                    "129":"Success but warning",
                    "16":"Stopped",
                },
                "logLevel":{
                    "1":"Information",
                    "2":"Warning",
                    "3":"Error",
                    "4":"Key",
                },
                "table":{
                    "domainName":"Department",
                    "projectName":"Project",
                }
            },
            // backup task
            "backupJob":{
                "status":{
                    "deleted":"Deleted",
                    "soft-delete":"Deleted",
                },
                "maxNumTry":"Maximum number of automatic retries",
                "maxSeed":"Maximum speed is",
                "tab_job":"Task",
                "tab_monitor":"Monitor",
                "tab_instance":"History",
                "detail":"Task details",

                "jobName":"job name",
                "jobStatus":"job status",
                "bindInst":"Associate Cloud Host",
                "hasSches":"Backup Strategy",
                "lastLaunchTime":"Last launch time",
                "nextLaunchTime":"Next launch time",
                "createDate":"Create time",
                "strategyInfo":"Strategy Details",
                "concurrentBackup":"Concurrent Backup",
                "permanentIncrementalBackup":"Permanent Incremental Backup",
                "backupAutoRetry":"Backup AutoRetry",
                "flowControl":"Flow Control",
                "basicInformation":"Basic Information",
                "dataSource":"Data Source",
                "type":"Type",
                "name":"name",
                "delDataSource":"Delete DataSource",
                "backupType": {
                    "1": "Full Backup",
                    "2": "Incremental Backup"
                },
                "policyRules":{
                    "2":"days",
                    "3":"weeks",
                    "4":"April",
                    "5":"years",
                },
                "dialog":{
                    "advParam":"Advanced Settings",
                    "perMonth":"Monthly",
                    "day":"day",
                    "days":"If there is no selected day of the month, it will be triggered on the last day",
                    "perYears":"every year",
                    "month":"month",
                    "runTime":"Execution time",
                    "add": "New Task Policy",
                    "edit": "Edit Task Policy",
                    "jobName": "Job Name",
                    "backupType": "Backup method",
                    "policyRules": "Policy Rules",
                    "startTime":"Start Date",
                    "endTime":"End Date",
                    "speedLimitTime":"speed limit time period",
                    "maxSpeed":"Maximum Speed",
                    "instThreadNum":"Number of concurrent backups",
                    "permanentIncrementalBackup":"Permanent Incremental Backup",
                    "retryWaitTime":"Retry Wait Time",
                    "maxNumRetry":"Maximum number of retries",
                    "bout":"time",
                    "minute":"minute",
                    "message":{
                        "error1":"The speed limit time period is not allowed to overlap",
                        "error2":"Start date must be earlier than end date",
                        "error3":"Start time must be earlier than end time",
                        "error4":"Enter 1~1024MiB/s",
                    },
                    "addFlowControl":"Add Flow Control",
                    "addInst":"Associate Cloud Host",
                    "delInst":"Delete cloud host",
                    "zdcj":"Automatically create",
                    "yu":"yu",
                },
                // bind instance popup
                "bindInstance":{
                    "instanceName": "Cloud host name",
                    "status": "Status",
                    "ips": "Private IP",
                    "title": "Associated Cloud Host",
                    "unCheckInst": "Cloud host not selected",
                    "checkInst": "Cloud host selected",
                }
            },
            //备份实例(监控和历史)
            "backupJobInstancer":{
                "instJobName":"Job Name",
                "instStatus":"Status",
                "instBackupType":"Backup method",
                "instStartTime":"Start Time",
                "instEndTime":"End Time",
                "instUsedTime":"Elapsed time",
                "progress":"Completed data volume/total data volume",
                "instSpeed":"Transfer Speed",
                "instSendSize":"Transfer data size",
                "runProgress":"Execution progress",
                "runLogInfo":"Execution Details",
                "instanceName":"instance name",
                "progress_i":"Progress",
                "time":"time",
                "level":"level",
                "logInfo":"Execution Information",
            },
            //数据恢复
            "backupJobRecover":{
                "jobName":"job name",
                "jobStatus":"job status",
                "jobRunTime":"Run time",
                "jobCompletedData":"Completed data volume",
                "jobSpeed":"Transfer Speed",
                "dialog":{
                    "add":"New Recovery Task",
                    "instanceName": "Cloud host name",
                    "status": "Status",
                    "ips": "Private IP",
                    "backupTime": "Backup Time",
                    "selectBackupJob": "Select Backup Job",
                    "selectTimeNode": "Select Time Node",
                    "selectDataSource": "Select Recovery Source",
                }
            },
            // 数据清理
            "backupDataClean": {
                "table": {
                    "jobName": "Job Name",
                    "jobStatus": "Status",
                    "jobDesc": "Remarks",
                    "jobStartTime": "Execution Time",
                    "success": "Success",
                    "failed": "Failed",
                    "error": "Exception",
                    "partSuccess": "Partial success",
                    "successAndWarning": "Success with warning",
                    "stopped": "stopped",
                    "running": "Executing"
                },
                "btn": {
                    "create": "New"
                },
                "details": {
                    "title": "Task Details",
                    "timepoints": "Backup data time points"
                },
                "create": {
                    "title": "New Cleanup Task",
                    "jobName": "Job Name",
                    "inputJobName": "Please enter a job name",
                    "jobDesc": "Job Notes",
                    "inputJobDesc": "Please enter a note",
                    "selectBackupJob": "Select Backup Job",
                    "pleaseSelectBackupJob": "Please select a backup job",
                    "selectBackupData": "Select Backup Data",
                    "timepoints": "Timepoints",
                    "tips": "The latest point-in-time copy cannot be deleted in the way of cleaning up specified copies. You can delete the latest point-in-time copy by cleaning up all copies.",
                    "allSelect": "Select All",
                    "currentPage": "Current List",
                    "pleaseSelectTimepoints": "Please select backup data timepoints!",
                    "checkJobName": "Duplicate job name!"
                }
            },
            // 服务配置
            "serviceConfig":{
                "config_top_tips":"Please confirm that the backup service is ready",
                "name":"name",
                "pvio":"Parameter value input/operation",
                "describe":"Describe",
                "connection":"Connection Status",
                "testConnection":"Connection Test",
                "saveConfig":"Save configuration",
                "deleteConfig":"Delete Configuration",
                "deleteDisc":"Are you sure you want to delete the configuration content？",
                "syncData":"Data synchronization",
                "syncStrategy":"Data synchronization strategy",
                "dataSyncSetting":"Data synchronization policy setting",
                "ibfs":"Low frequency synchronization",
                "dataStatusSync":"High frequency synchronization",
                "changePassword":"Change Password",
                "syncSuccess":"Data synchronization succeeded",
                "title":{
                    "pleaseInt":"Please input",
                    "abAddress":"Anybackup management node URL",
                    "accountNumber":"AnyBackup administrator account",
                    "accountPassword":"AnyBackup administrator password",
                    "securityNumber":"AnyBackup security officer account number",
                    "securityPassword":"AnyBackup security officer password",
                    "passwordDescOne":"Password Validity Period: Remaining",
                    "passwordDescTwo":"Days (The expiration of the validity period will force the connection to be disconnected, and the password needs to be changed to reconnect.)",
                    "connectionDesc":"Please test the connection first, and deploy the service after the connection is successful.",
                    "pleaseChange":"Please select",
                    "passwordReturn":"Password reset time again",
                    "day":"day",
                    "originalPassword":"Original Password",
                    "newPassword":"New Password",
                    "confirmPassword":"Confirm new password",
                    "originalPasswordError":"The original password is incorrect",
                    "samePassword":"The original password is the same as the new password",
                    "confirmPasswordError": "The new password is inconsistent with the confirm new password",
                },
                "icon":{
                    "connectionLoad":"Connecting",
                    "connectionYes":"Connection succeeded",
                    "connectionWrong":"Connection failed",
                    "stoppedWrong":"Disconnected",
                },
                "time":{
                    "minute":"minutes",
                    "oneMinute":"1 minute",
                    "fiveMinute":"5 minutes",
                    "tenMinute":"10 minutes",
                    "twentyMinute":"20 minutes",
                    "thirtyMinute":"30 minutes",
                },
                "index":{
                    "accountShow":"Reminder of password validity: The backup service anybackup administrator password is valid for",
                    "securityShow":"Reminder of password validity: The password validity of anybackup security officer of backup service remains",
                    "allShow":"days. The connection will be forcibly disconnected at the end of the validity period. Please modify the password as soon as possible to extend the validity period.",
                    "btn":"Backup configuration",
                    "syncStrategy":"Data synchronization in progress, retrying later",
                    "syncData":"The backup service is being deployed. Please try again later",
                    "accountPassword":"The administrator password has expired, please modify it!",
                    "securityPassword":"The security officer password has expired, please modify it!"
                },
                "deleteConfigTitle":{
                    "deleteTitleOne":"Deleting the service",
                    "deleteTitleTwo":"will cause all backup tasks to be suspended",
                    "deleteTitleThree":", and the backed up point in time data will not be deleted.",
                }
            }
        }
    }
};
const topMenu = en_US.aws.menu
export {en_US,topMenu}
