
storageDetailCtrl.$inject = ["$scope","$timeout", "$rootScope","$translate","$uibModal","$location","storageManagementSrv","TableCom","$filter","$routeParams","GLOBAL_CONFIG"]
function storageDetailCtrl($scope,$timeout, $rootScope,$translate,$uibModal,$location,Srv,TableCom,$filter,$routeParams,GLOBAL_CONFIG) {
    var self = $scope;
    self.statusList = [
        {
            name: $translate.instant ("aws.storage.pool.state.7"), // "全部",
            type:"all"
        },
        {
            name:$translate.instant ("aws.storage.pool.state.0"), // "创建中",
            type:"0"
        },
        {
            name:$translate.instant ("aws.storage.pool.state.1"), // "创建失败",
            type:"1"
        },
        {
            name:$translate.instant ("aws.storage.pool.state.2"), // "可用",
            type:"2"
        },
        {
            name:$translate.instant ("aws.storage.pool.state.3"), // "不可用",
            type:"3"
        },
        {
            name:$translate.instant ("aws.storage.pool.state.4"), // "删除中",
            type:"4"
        },
        {
            name:$translate.instant ("aws.storage.pool.state.5"), // "删除失败",
            type:"5"
        }
    ]
    self.params = {};
    self.params.name = "";
    self.params.searchItem = self.statusList[0];
    self.changeStatus = function(v){
        initTable()
    }

    self.search={
        globalSearchTerm:""  
    }
    var initTable=function(type){
        TableCom.init(self,'poolTable',[],"id",GLOBAL_CONFIG.PAGESIZE,'storagePoolCheckbox');
        self.search.globalSearchTerm ="";
        var params = {
            storageId:$routeParams.id,
            type:type?type:self.params.searchItem.type
        }
        Srv.getCephPool(params).then(function(result){
            if(result&&result.data){
                successFunc(result.data);
            }
        });
    }

    /*存储池列表轮询*/
    var ispoll = true;
    function poolListPoll(type){
        ispoll = true;
        if($routeParams.id){
            var params = {
                storageId:$routeParams.id,
                type:type?type:self.params.searchItem.type
            }
            Srv.getCephPool(params).then(function(result){
                if(result&&result.data){
                    successFunc(result.data);
                }
            });
        }
    }

    function successFunc(data){
        self.poolTableData = data.map(function(item){
            item.createTime = $filter("date")(item.createTime, "yyyy-MM-dd HH:mm:ss");
            item.usedCapacity = item.assignCapacity?item.assignCapacity + "GB":'--';
            item.totalCapacity = item.totalCapacity ? item.totalCapacity + "GB" :'--';
            item.allocatedCapacity = item.allocatedCapacity?item.allocatedCapacity + "GB" :'--';
            item.statusDisplay = $translate.instant('aws.storage.pool.state.'+item.status)
            item.searchTerm = [item.name,item.totalCapacity,item.allocatedCapacity,item.usedCapacity,item.statusDisplay,item.createTime].join('\b');
            return item;
        });
        //根据session初始化searchterm
        TableCom.init(self,'poolTable',self.poolTableData,"id",GLOBAL_CONFIG.PAGESIZE,'storagePoolCheckbox');
        for(var i=0;i<self.poolTableData.length;i++ ){
            if(self.poolTableData[i].status==0||self.poolTableData[i].status==4){
                if( ispoll&&
                    $routeParams.storageFirm&&
                    ($routeParams.storageFirm=='out_ceph'||
                    $routeParams.storageFirm=='ceph')
                ){
                    ispoll = false;
                    $timeout(function(){
                        poolListPoll();
                    },8000)
                }
                break;
            }
        }
    }

    self.refresh=function(){
       initTable();
    }
    initTable();

    self.watched = '';
    function watchCheck(self) {
        var watch = self.$watch(function() {
            return self.storagePoolCheckbox.items;
        }, function(val) {
            self.canDel = false;
            self.checkedItems = [];
            var arr = [];
            for (var i in self.storagePoolCheckbox.items) {
                arr.push(self.storagePoolCheckbox.items[i]);
            }
            if (val && arr.length >= 0) {
                for (var key in val) {
                    if (val[key]) {
                        self.poolTableData.forEach(item => {
                            if (item.id == key) {
                                self.checkedItems.push(item);
                            }
                        });
                    }
                }
            }
            if (self.checkedItems.length == 1 &&
                self.checkedItems[0].status !=0 &&
                self.checkedItems[0].status !=4 &&
                self.checkedItems[0].isDefault !=true
                ) {
                self.canDel = true;
            }
        }, true);
        return watch;
    }
    if (self.watched) {
        self.watched();
    }
    self.watched = watchCheck(self);
    
    self.applyGlobalSearch = function() {
        self.poolTable.filter({
            searchTerm: self.search.globalSearchTerm
        });
    }

    /*新建备份弹出框*/
    self.addPool  = function(checkedItems){
        if($routeParams.storageFirm=='ceph'){
            self.poolModel = $uibModal.open({
                animation: self.animationsEnabled,
                templateUrl: "js/system/storageManagement/tmpl/addCephPoolModel.html",
                controller:"addCephPoolCtrl",
                resolve:{
                    context:self
                }
            });
        }else{
            self.FormValid = false;
            self.params.name = "";
            self.poolModel = $uibModal.open({
                animation: self.animationsEnabled,
                templateUrl: "js/system/storageManagement/tmpl/addOutCephPoolModel.html",
                scope: self
            });    
        }
        
    }

    /*外部ceph存储资源池建立*/
    self.confirmCreate = function(m,name){
        self.FormValid = false;
        if(m.$valid){
            var time = new Date().getTime();
            var storageConfiguration = {
                "storage_name": "outceph_"+name+"_"+time,
                "storage_type": "ceph_pool",
                "volume_backend_name": "outceph_"+name+"_"+time,
                "pool_name": name
            }
            storageConfiguration = angular.toJson(storageConfiguration)
            var params = {
              "name":name,
              "storageId":$routeParams.id,
              "storageConfiguration":storageConfiguration,
              "volumeBackendName":"outceph_"+name+"_"+time,
              "regionKey":localStorage.regionKey,
              "enterpriseUid":localStorage.enterpriseUid,
              "regionUid":localStorage.regionUid
            }

            Srv.addCephPool(params).then(res=>{
                if(res){
                   initTable('all') 
                }
            })
            self.poolModel.close();
        }else{
            self.FormValid = true;
        }
    }

    /*删除备份*/
    self.deletePool  = function(){
        if(!self.canDel){return}
        var content = {
            target: "deletePool",
            msg: "您确定要删除所选内容吗？"
        };
        self.$emit("delete", content);
        //监听计数防止广播队列多次发删除请求。
        self.listenerNum = 0;
        self.$on("deletePool", function() {
            self.listenerNum = self.listenerNum + 1;
            if(self.listenerNum>1){return;}
            var params = {
                "id":self.checkedItems[0].id,
                "regionKey":localStorage.regionKey,
                "enterpriseUid":localStorage.enterpriseUid,
                "regionUid":localStorage.regionUid
            }
            Srv.deleteCephPool(params).then(res=>{
                if(res){
                    initTable('all')
                }
            })
        });
    }
};

export {storageDetailCtrl}


