export function enhanceconfigSrv($http){
    return {
        // 更新负载均衡配置
        getPropAgreement(data){
            return $http({
                method: "put",
                url: "awstack-manage/v2/elbLoadbalancer/update/image",
                data
            })
        },
        // 获取登陆配置
        getLoginConfig(params) {
            params.enterpriseUid = localStorage.enterpriseUid;
            return $http({
                method: "get",
                url: `/awstack-user/v1/params`,
                params
            })
        },
        getImage(){
            return $http({
                method: "get",
                url: `/awstack-manage/v2/images/share`,
            })
        },
        // 重载菜单
        reloadMenu(){
            return $http({
                method: "get",
                url: `/awstack-user/v1/menus/reload`,
            })
        }
    }
}
enhanceconfigSrv.$inject = ["$http"];
