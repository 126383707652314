let  iframeUtilModule = angular.module("iframeUtilModule", []);
iframeUtilModule.service('iframeUtil', ["$rootScope","$timeout",function ($rootScope,$timeout) {
    function IframeTool(){
        this.routeMeta = {};
        this.iframeInstan = '';
    }
    // IframeTool.prototype.setIframeMeta = obj.setIframeMeta;
    let obj = {}
    obj.routeMeta = {}
    obj.setIframeMeta = function(current,iframeObj){
        obj.routeMeta = {
            type:'route-change',
            status:"ready",
            isIframe:current.isIframe,
            data:{
                url:current.originalPath,
                iframeUrl:current.iframeUrl
            }
        }
        if(current.isIframe){
            // isIf控制是否第一次触发iframe,一旦触发 isIf常驻为true,后继通过isShow来控制iframe是否显示
            if(iframeObj.isIf){
                iframeObj.isShow = true;
                if(obj.iframeInstan){
                    obj.iframeInstan.postMessage(obj.routeMeta,window.location.origin);
                    // obj.iframeInstan.postMessage(obj.routeMeta,"https://www.baidu.com");
                }else{
                    obj.routeMeta.status = "pending";
                }
            }else{
                iframeObj.isIf = true;
                iframeObj.isShow = true;
                if(obj.iframeInstan){
                    obj.iframeInstan.postMessage(obj.routeMeta,window.location.origin);
                }else{
                    obj.routeMeta.status = "pending";
                }
            }
        }else{
            if(current.originalPath=="/"){
                iframeObj.isIf = false;
            }else{
                iframeObj.isShow = false;
                obj.routeMeta.data = {url:"/"};
                if(obj.iframeInstan){
                    obj.iframeInstan.postMessage(obj.routeMeta,window.location.origin);
                }else{
                    obj.routeMeta.status = "pending";
                }
            }
            
        }
    }
    obj.setDataUpdate = function(action,data){
        let _this = obj;
        let postData = {
            type:"data-update",
            data:{
                action:action,
                data
            }
        }
        if(_this.iframeInstan){
            _this.iframeInstan.postMessage(postData,window.location.origin);
        }
    }
    obj.setBackground = function(data){
        let num = data.flag?1:-1;
        $rootScope.iframeObj.maskPending += num;
        $rootScope.iframeObj.outBg = $rootScope.iframeObj.maskPending>0?true:false;
        $rootScope.$apply();
    }
    obj.sendSocket = function(msg){
        let data = {
            type:"websocket-msg",
            data:msg
        }
        if(obj.iframeInstan&&obj.routeMeta.isIframe){
            obj.iframeInstan.postMessage(data,window.location.origin);
        }
    }
    obj.setLoginModal = function($rootScope){
        $rootScope.effeToken = true;
    }
    obj.parentUpdate = function($rootScope,data){
        if(data.action){
            $rootScope.$broadcast("parent-data-update-"+data.action,data.data);
        }
    }
    obj.init = function(){
        window.addEventListener("message", (event) => {
            let type = event.data.type;
            switch(type){
                case "mask-change":
                    obj.setBackground(event.data.data);
                    break;
                case "page-completed":
                    obj.setIframeInstan();
                    break;
                case "login-status-change":
                    obj.setLoginModal($rootScope)
                    break;
                case "parent-data-update":
                    obj.parentUpdate($rootScope,event.data.data)
                    break;
                default:
                    break;
            }
        }, false);
    }
    obj.setIframeInstan = function(){
        obj.iframeInstan = document.getElementById("newProPage").contentWindow;
        if(obj.routeMeta.status=="pending"){
            obj.routeMeta.status = "ready";
            obj.iframeInstan.postMessage(obj.routeMeta,window.location.origin);
        }
    }
    obj.init();
	return obj;
}])
export default iframeUtilModule.name